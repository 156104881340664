import BaseDatePicker, { DatePickerProps as BaseDatePickerProps } from '@service/datePicker';
import { DATE_FORMAT } from '@utils/constants/format';
import { cx } from '@utils/cx';
import dayjs from 'dayjs';

import { BaseProps, Wrapper } from './wrapper';

interface DatePickerProps extends BaseProps, Omit<BaseDatePickerProps, 'picker'> {
  name: string;
}

const DateRangePicker: React.FC<DatePickerProps> = ({ name, control, label, className, labelClassName, ...args }: DatePickerProps) => {
  label = label || args.placeholder;

  return (
    <Wrapper {...{ control, name, label, labelClassName }}>
      {({ value, onChange }, error) => (
        <BaseDatePicker.RangePicker
          id={name}
          name={name}
          value={value ? [dayjs(value[0], DATE_FORMAT), dayjs(value[1], DATE_FORMAT)] : undefined}
          className={cx('field', className)}
          format={DATE_FORMAT}
          onChange={value => onChange([value?.[0]?.format(DATE_FORMAT), value?.[1]?.format(DATE_FORMAT)])}
          allowClear
          status={error ? 'error' : undefined}
          {...args}
        />
      )}
    </Wrapper>
  );
};

export default DateRangePicker;
