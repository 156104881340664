import React, { useState } from 'react';
import { Button, Modal, Radio, RadioChangeEvent, Space } from 'antd';

import styles from './reasonsRefusalModal.module.scss';
import { useTranslation } from 'react-i18next';
import LeadTaskCompleteModalOneLead from '../leadTaskCompleteModal/LeadTaskCompleteModalOneLead';
import { useReasonsForRefusal } from '../../../services/queries';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  success: (reason: null | undefined | number) => void;
}

const ReasonRefusalModalOneLead: React.FC<Props> = ({ close, visible, success }) => {
  const [value, setValue] = useState<null | number>(null);
  const [leadTask, setLeadTask] = useState(false);

  const { data } = useReasonsForRefusal();

  const { t } = useTranslation();

  // handle close
  const handleClose = () => {
    setValue(null);
    close(false);
  };

  // handle save
  const handleSave = () => {
    // success(value)
    // handleClose()
    setLeadTask(true);
  };

  // change value
  const changeValue = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Modal
        title={t('reason_refusal.Rad_etish_sababi')}
        visible={visible}
        onCancel={handleClose}
        footer={false}
        width={372}
        wrapClassName={styles.container}
      >
        <Radio.Group onChange={e => changeValue(e)} value={value}>
          <Space direction="vertical">
            <Radio value={null}>{t('reason_refusal.Sababsiz')}</Radio>
            {data?.map(item => <Radio value={item.id}>{item.value}</Radio>)}
          </Space>
        </Radio.Group>
        <div className={styles.container_footer}>
          <Button onClick={handleClose}>{t('reason_refusal.Bekor_qilish')}</Button>
          <Button onClick={handleSave} type="primary">
            {t('reason_refusal.Saqlash')}
          </Button>
        </div>
      </Modal>
      <LeadTaskCompleteModalOneLead success={success} visible={leadTask} close={setLeadTask} handleCloseReason={handleClose} value={value} />
    </>
  );
};

export default ReasonRefusalModalOneLead;
