import { FileObject } from './File';

export interface TelegramBot {
  username: string;
  token: string;
  admin: string;
  active: boolean;
  contractActive?: boolean;
  houseActive?: boolean;
  faqActive?: boolean;
  appealActive?: boolean;
  bookingActive?: boolean;
  contact?: string;
  contactActive?: boolean;
  about?: string;
  aboutActive?: boolean;
  longitude?: number;
  latitude?: number;
  locationActive?: boolean;
  aboutRu?: string;
  aboutEn?: string;
  contactRu?: string;
  contactEn?: string;
  showFlatPrice?: boolean;
}

export interface Faq {
  id: number;
  question: string;
  answer: string;
  lang: LangBot;
}

export enum LangBot {
  RU = 'RUS',
  EN = 'ENG',
  UZ = 'UZB'
}

export interface BotInfoForMessage {
  name: string;
  subscribers: number;
  username: string;
  avatar: FileObject;
}

export interface SendMessageBodyBot {
  text?: string;
  fileIds?: number[];
}
