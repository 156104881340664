import { InterfaceConfigPaths } from './InterfaceConfigPaths';
import InterfaceConfig from '../../components/interfaceConfig/InterfaceConfig';
import WidgetConfig from '../../components/widgetConfig/WidgetConfig';

export const interfaceConfigRoutes = [
  {
    path: InterfaceConfigPaths.INTERFACE_CONFIG,
    component: InterfaceConfig,
    exact: true
  },
  {
    path: InterfaceConfigPaths.WIDGET_CONFIG,
    component: WidgetConfig,
    exact: true
  }
];
