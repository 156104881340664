import { CSSProperties } from 'react';
import { defaultCountries } from '@utils/constants/countryData';
import { CountryIso2, ParsedCountry, PhoneInput } from 'react-international-phone';

import 'react-international-phone/style.css';

interface IProps {
  className?: string;
  defaultCountry?: CountryIso2 | undefined;
  value?: string | undefined;
  onChange?: (
    phone: string,
    meta: {
      country: ParsedCountry;
      inputValue: string;
    }
  ) => void;
  inputStyle?: CSSProperties | undefined;
  inputClassName?: string | undefined;
}

const InternationalPhoneNumber = ({ className, defaultCountry = 'uz', value, onChange, inputStyle, inputClassName = 'ant-input' }: IProps) => {
  const changeInput = (
    phone: string,
    meta: {
      country: ParsedCountry;
      inputValue: string;
    }
  ) => {
    // console.log(phone, meta);
    if ((phone && phone?.length > 5) || phone?.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChange && onChange(phone, meta);
    }
  };

  return (
    <PhoneInput
      defaultCountry={defaultCountry}
      className={className}
      countries={defaultCountries}
      inputClassName={inputClassName}
      value={value}
      onChange={changeInput}
      inputStyle={inputStyle}
    />
  );
};

export default InternationalPhoneNumber;
