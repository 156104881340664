import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InfoCircleIconGrey from '@icons/InfoCircleIconGrey';
import { useClientId } from '@queries/queries';
import { encodeString } from '@utils/helper';
import { ClientType } from '@utils/models/ClientType';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import ClockIcon from '../../../../assets/icons/ClockIcon';
import ArrowIosDownIcon from '../../../../assets/icons/contract/ArrowIosDownIcon';
import CardTickIcon from '../../../../assets/icons/contract/CardTickIcon';
import DotIcon from '../../../../assets/icons/contract/DotIcon';
import ExportIcon from '../../../../assets/icons/contract/ExportIcon';
import FolderOpenIcon from '../../../../assets/icons/contract/FolderOpenIcon';
import NoteRemoveIcon from '../../../../assets/icons/contract/NoteRemoveIcon';
import ReceiveSquareIcon from '../../../../assets/icons/contract/ReceiveSquareIcon';
import TickSquareIcon from '../../../../assets/icons/contract/TickSquareIcon';
import GlphIcon from '../../../../assets/icons/GlphIcon';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { Permissions } from '../../../../utils/constants/permissions';
import { urlEncode } from '../../../../utils/helper/urlEncodeDecode';
import { ContractId } from '../../../../utils/models/ContractId';
import ContractDelete from '../../../contractId/ContractDelete';
import ContractInfoModal from '../../../contractId/ContractInfoModal';
import ContractIdPaymentDrawer from '../contract-id-payment-drawer/ContractIdPaymentDrawer';

import ContractIdDownload from './ContractIdDownload';
import ContractIdPaymentScheduleArchive from './ContractIdPaymentScheduleArchive';

import styles from './contractIdHeader.module.scss';

interface IProps {
  data: ContractId | undefined;
}

const ContractIdHeader: FC<IProps> = ({ data }) => {
  const [contractInfoModal, setContractInfoModal] = useState(false);
  const { t } = useTranslation();
  const [contractDelete, setContractDelete] = useState(false);
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isAccessDeleteContract = permissions?.includes(Permissions.PERMISSION_CANCEL_CONTRACT);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [openArxive, setOpenArxive] = useState(false);
  const { data: clientId } = useClientId(data?.clientId || -1);

  // handlePayment
  const handlePayment = () => {
    setVisible(true);
  };

  // goToClient
  const goToClient = () => {
    const wholeName = clientId?.type === ClientType.JURIDICAL ? clientId?.name : `${clientId?.lastname} ${clientId?.firstname} ${clientId?.fatherName}`;
    const encodedName = encodeString(wholeName);

    navigate(`${rootPaths.MAIN + MainPaths.CLIENT}/${data?.clientId}/${encodedName}`);
  };

  // handleArxive
  const handleArxive = () => {
    setOpenArxive(true);
  };

  // handleEstablish
  const handleEstablish = () => {
    navigate(`${rootPaths.MAIN + MainPaths.CONTRACT}/${data?.baseId}/${urlEncode(data?.number!)}`);
  };

  // handleContractInfo
  const handleContractInfo = () => {
    setContractInfoModal(true);
  };

  const isAccessPayment = permissions?.includes(Permissions.PERMISSION_CREATE_PAYMENT);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_left}>
          {data?.cancelText === null && isAccessPayment && (
            <div className={styles.container_left_item} onClick={handlePayment}>
              <CardTickIcon /> {t('client_balance.Tolov_qilish')}
            </div>
          )}
          {data?.clientId ? (
            <div className={styles.container_left_item} onClick={goToClient}>
              <TickSquareIcon /> {t('titles.CRM')}
            </div>
          ) : null}
          <div className={styles.container_left_item} onClick={handleArxive}>
            <FolderOpenIcon /> {t('contract_id.Arxiv')}
          </div>
          <ContractIdDownload data={data}>
            <div className={styles.container_left_item}>
              <ReceiveSquareIcon /> {t('client_balance.Yuklab_olish')} <ArrowIosDownIcon />
            </div>
          </ContractIdDownload>
          <div
            // className={`ml-[2px] text-[#464255] font-medium text-[16px] gap-2 flex items-center${styles.container_left_more}`}
            className={styles.container_left_item_no_border}
            onClick={handleContractInfo}
          >
            {/* <MoreVerticalIcon /> */}
            <InfoCircleIconGrey /> {t('home.Malumot')}
          </div>
        </div>
        <div className={styles.container_right}>
          {data?.establishId === null && data.baseId && (
            <div className={styles.container_right_history} onClick={handleEstablish}>
              <ExportIcon /> {t('contract_id.Otmishdoshi')}
            </div>
          )}
          {data?.cancelText === null && isAccessDeleteContract && (
            <div className={styles.container_right_close} onClick={() => setContractDelete(true)}>
              <NoteRemoveIcon /> {t('home.Bekor_qilish')}
            </div>
          )}
          {data?.cancelText && (
            <Dropdown
              overlay={
                <div className={styles.closedDropDown}>
                  <div className={styles.closedDropDown_title}>
                    <span>{data?.number} </span>{' '}
                    <span className={styles.closedDropDown_title_close}>
                      <DotIcon color="#2E90FA" /> {t('home.CANCELED')}
                    </span>
                  </div>
                  <h3>{data?.cancelledBy}</h3>
                  <p>{data?.cancelText}</p>
                  <p>
                    <ClockIcon /> {data?.cancelledDate}
                  </p>
                </div>
              }
              trigger={['click']}
            >
              <div className={styles.container_right_closed}>
                <GlphIcon size={16} /> {t('home.CANCELED')}
              </div>
            </Dropdown>
          )}
        </div>
      </div>
      <ContractIdPaymentDrawer data={data} setVisible={setVisible} visible={visible} />
      <ContractIdPaymentScheduleArchive setVisible={setOpenArxive} visible={openArxive} />
      <ContractDelete visible={contractDelete} setVisible={setContractDelete} />
      <ContractInfoModal
        fields={data?.fields || []}
        visible={contractInfoModal}
        onClose={setContractInfoModal}
        payNumber={data?.payNumber || ''}
        createdDate={data?.createdDate || ''}
        comment={data?.comment}
        createdBy={data?.createdBy}
        responsibleBy={data?.responsibleBy}
        broker={data?.broker}
      />
    </>
  );
};

export default ContractIdHeader;
