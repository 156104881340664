import React, { FC } from 'react';
import { Spin } from 'antd';

import { useMobileHouseContractDelay } from '../../../queries/queries';
import BossContractPeriodItem from '../bossContractPeriodItem/BossContractPeriodItem';


interface InfoProps {
  delay: number;
  count: number;
  houses: number[];
}

const ContractPeriodInfo: FC<InfoProps> = ({ delay, count, houses }) => {
  const { data, isLoading, isFetching, fetchNextPage } = useMobileHouseContractDelay(delay, count, houses);

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 1 < e.target.scrollTop + e.target.offsetHeight) {
      fetchNextPage();
    }
  };

  return (
    <>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ overflow: 'auto', maxHeight: 500 }} onScroll={onScroll}>
          {data ? data?.pages?.map(data => data?.data?.map((item, index) => <BossContractPeriodItem key={index} item={item} />)) : <></>}
        </div>
      </Spin>
    </>
  );
};

export default ContractPeriodInfo;
