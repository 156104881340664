import React from 'react';

const UpdateIcon = (props: any) => {
  return (
    <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="7" fill="#DFDFDF" />
      <path
        d="M15.168 7.6665H13.5013C9.33464 7.6665 7.66797 9.33317 7.66797 13.4998V18.4998C7.66797 22.6665 9.33464 24.3332 13.5013 24.3332H18.5013C22.668 24.3332 24.3346 22.6665 24.3346 18.4998V16.8332"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3675 8.51688L12.8009 15.0835C12.5509 15.3335 12.3009 15.8252 12.2509 16.1835L11.8925 18.6919C11.7592 19.6002 12.4009 20.2335 13.3092 20.1085L15.8175 19.7502C16.1675 19.7002 16.6592 19.4502 16.9175 19.2002L23.4842 12.6335C24.6175 11.5002 25.1509 10.1835 23.4842 8.51688C21.8175 6.85021 20.5009 7.38354 19.3675 8.51688Z"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4258 9.4585C18.9841 11.4502 20.5424 13.0085 22.5424 13.5752"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpdateIcon;
