import { baseErrorHandler } from "@queries/baseErrorHandler";
import { showMessage } from "@queries/showMessage";
import { $api } from "@service/RequestService";
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { PaymentCustomType } from "../utils/models/PaymentCustomType";

export function usePostPaymentCustomType() {
    const qc = useQueryClient();

    return useMutation({
        mutationFn: async (data: PaymentCustomType[]) => {
            const res = await $api.post(endPoints.PAYMENT_CUSTOM_TYPE, { ...data });

            return res?.data;
        },
        onSuccess: data => {
            qc.invalidateQueries({ queryKey: [queryKeys.PAYMENT_CUSTOM_TYPE] });
            showMessage(data?.message, data?.accept);
        },
        onError: baseErrorHandler
    });
}


export function useDeletePaymentCustomType(id: number) {
    const qc = useQueryClient();

    return useMutation(
        async () => {
            const res = await $api.delete(`${endPoints.PAYMENT_CUSTOM_TYPE}/${id}`);

            return res.data;
        },
        {
            onSuccess: data => {
                showMessage(data.message, data.accept);
                qc.invalidateQueries([queryKeys.PAYMENT_CUSTOM_TYPE]);
            },
            onError: baseErrorHandler
        }
    );
}
