const LocationIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 12.3079C8.22502 12.3079 6.77502 10.8662 6.77502 9.08288C6.77502 7.29954 8.22502 5.86621 10 5.86621C11.775 5.86621 13.225 7.30788 13.225 9.09121C13.225 10.8745 11.775 12.3079 10 12.3079ZM10 7.11621C8.91669 7.11621 8.02502 7.99954 8.02502 9.09121C8.02502 10.1829 8.90836 11.0662 10 11.0662C11.0917 11.0662 11.975 10.1829 11.975 9.09121C11.975 7.99954 11.0834 7.11621 10 7.11621Z"
      fill="#292D32"
    />
    <path
      d="M10 19.467C8.76669 19.467 7.52502 19.0003 6.55836 18.0753C4.10002 15.7087 1.38336 11.9337 2.40836 7.44199C3.33336 3.36699 6.89169 1.54199 10 1.54199C10 1.54199 10 1.54199 10.0084 1.54199C13.1167 1.54199 16.675 3.36699 17.6 7.45033C18.6167 11.942 15.9 15.7087 13.4417 18.0753C12.475 19.0003 11.2334 19.467 10 19.467ZM10 2.79199C7.57502 2.79199 4.45836 4.08366 3.63336 7.71699C2.73336 11.642 5.20002 15.0253 7.43336 17.167C8.87502 18.5587 11.1334 18.5587 12.575 17.167C14.8 15.0253 17.2667 11.642 16.3834 7.71699C15.55 4.08366 12.425 2.79199 10 2.79199Z"
      fill="#292D32"
    />
  </svg>
);

export default LocationIcon;
