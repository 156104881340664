import React from 'react';
import { useExpoAll } from './services/queries';
import { Table } from 'antd';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import styles from './scheduleLottotron.module.scss';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { IExpoAll } from './utils/models/IExpoAll';
import { useQueryParam } from '@hooks/useQueryParams';

const ScheduleLottotron = () => {
  const { searchParamsString, searchParams, appendMultipleDifferent } = useQueryParam<any, any>();
  const { t } = useTranslation();
  const { data, isLoading } = useExpoAll(searchParamsString);

  const columns: ColumnsType<IExpoAll> | undefined = [
    {
      title: 'Kompany',
      dataIndex: 'companyName'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'Sana',
      dataIndex: 'createdDate'
    }
  ];

  const changePage = (page: number) => {
    appendMultipleDifferent(['page'], [page]);
  };
  return (
    <div>
      <Table
        dataSource={data?.data || []}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: data?.totalElements,
          current: data?.currentPage ? data.currentPage : 1,
          //   hideOnSinglePage: true,
          pageSize: searchParams?.size,
          className: styles.pagination,
          prevIcon: (
            <div className={styles.pagination_prev}>
              <ArrowLeftIcon /> {t('my_calls.Ortga')}
            </div>
          ),
          nextIcon: (
            <div className={styles.pagination_next}>
              {t('profile.Keyingi')} <ArrowRightIcon />
            </div>
          ),
          onChange: changePage
        }}
        className={styles.container_table}
      />
    </div>
  );
};

export default ScheduleLottotron;
