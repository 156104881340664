import { Icon } from '@components/index';
import { cx } from '@utils/cx';
import { Button, Modal } from 'global';
import { useTranslation } from 'react-i18next';

import { useAction, useClientExterminate } from '../hooks';

const classNames = {
  button: 'w-[146px] h-[36px] rounded-[50px] text-[16px] border-none bg-[#f2f2f2]  text-[#23262f]  hover:bg-[#f2f2f2] hover:text-[#23262f] focus:bg-[#f2f2f2] focus:text-[#23262f]'
};

const ClientExterminateModal = () => {
  const { t } = useTranslation();
  const { exterminate, setExterminate } = useAction();

  const deleteClient = useClientExterminate();

  const handleClose = () => {
    setExterminate({ id: undefined });
  };

  const handleDelete = () => {
    if (exterminate?.id) {
      deleteClient.mutate(exterminate?.id, {
        onSuccess: () => {
          handleClose();
        }
      });
    }
  };

  return (
    <Modal visible={!!exterminate?.id} onCancel={handleClose} width={500} footer={null} centered>
      <div className="flex flex-col items-center gap-8 py-4">
        <span className="flex h-[45px] w-[45px] items-center justify-center rounded-[35px] bg-[#ffebeb] text-red-600">
          <Icon name="trash" />
        </span>
        <p className="text-center text-[20px] leading-[24px] text-[#23262f]">
          <b>
            <q>{exterminate?.name}</q> <span className="opacity-[0.5]"> {t('client.nomli_mijozni_ochirmoqchimisiz')}</span>
          </b>
        </p>
        <div className="flex gap-4">
          <Button className={cx(classNames.button)} onClick={handleClose}>
            {t('contract_id.Yopish')}
          </Button>
          <Button
            className={cx(classNames.button, 'bg-[#ff3f40]  text-[#fcfcfd]  hover:bg-[#ff3f40] hover:text-[#fcfcfd] focus:bg-[#ff3f40] focus:text-[#fcfcfd]')}
            onClick={handleDelete}
            loading={deleteClient.isLoading}
          >
            {t('arrearage.Tasdiqlash')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ClientExterminateModal;
