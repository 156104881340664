import React from 'react';
import MainHeader from '../../components/headers/MainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import ProfileRouter from '../../routing/profile/ProfileRouter';
import ProfileMenyu from './profile-menyu/ProfileMenyu';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainHeader>
        <h1 className="header_title">{t('titles.profile')}</h1>
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <ProfileMenyu />
      <ProfileRouter />
    </>
  );
};

export default Profile;
