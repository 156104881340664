import React, { useRef } from 'react';
import styles from './interfaceConfig.module.scss';
import { Avatar, Button, Spin } from 'antd';
import EditIcon from '../../assets/icons/EditIcon';
import { useCompanyLogo } from '../../queries/queries';
import { BASE_URL } from '../../service/RequestService';
import { useSaveCompanyLogo, useUploadFile } from '../../queries/mutation';
import { FileObject } from '../../utils/models/File';
import { useTranslation } from 'react-i18next';

const EditLogo = () => {
  const uploadInput = useRef<HTMLInputElement | undefined>();
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useCompanyLogo();

  const uploadFile = useUploadFile(getFile);
  const saveFile = useSaveCompanyLogo();

  function getFile(files: FileObject[]) {
    saveFile.mutate(files[0].id);
  }

  const handlePassportImage = (e: any) => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  const handleUpload = () => {
    if (uploadInput.current) {
      uploadInput.current.click();
    }
  };

  return (
    <>
      <Spin spinning={isFetching || isLoading}>
        <h2 className={styles.interfaceConfig_title}>{t('interface_config.Showroom_uchun_kompaniya_logotipi')}</h2>
        <div className={styles.interfaceConfig_logo}>
          {data ? <Avatar size={200} src={BASE_URL + '/' + data?.absolutePath} /> : <Avatar size={200}>L</Avatar>}
          <Button
            loading={uploadFile.isLoading || saveFile.isLoading}
            onClick={handleUpload}
            className={styles.interfaceConfig_logo_button}
            type={'primary'}
          >
            <EditIcon />
            {t('client.Tahrirlash')}
          </Button>
        </div>
      </Spin>
      <input
        ref={uploadInput as any}
        accept=".jpg, .jpeg, .png"
        onChange={handlePassportImage}
        type={'file'}
        style={{ display: 'none' }}
        id={'passportFile2'}
      />
    </>
  );
};

export default EditLogo;
