import 'swiper/css';

import { useCallback, useEffect, useState } from 'react';
import TableSettings from '@components/filters/TableSettings';
import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import PaginationCont from '@components/pagenation/PaginationCont';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowLineDownIcon from '@icons/ArrowLineDownIcon';
import Download from '@icons/Download';
import ExcelIcon from '@icons/ExcelIcon';
import { useDownloadFlatsToExcel, useDownloadFlatsToExcelSummaryBeta } from '@queries/mutation';
import { useTableConfig } from '@queries/queries';
import { setHomeCurrentPage } from '@reducers/HouseSlice';
import { TableKey } from '@utils/models/TableKey';
import { Layout } from 'antd';
import { Dropdown, Spin } from 'global';
import { useTranslation } from 'react-i18next';

import HomeFilters from './components/HomeFilters';
import SaleHousesList from './components/SaleHousesList';
import TableFlats from './components/tableFlats/TableFlats';
import { useSaleFlats } from './services/queries';

import styles from './home.module.scss';
// eslint-disable-next-line
import stylesButton from '@components/filters/tableSettings.module.scss';

const { Content } = Layout;

export interface SearchParamsHome {
  buildings?: string[];
  houses?: string[];
  page?: string;
  size?: string;
  minArea?: string;
  maxArea?: string;
  rooms?: string | string[];
  saleStatuses?: string | string[];
  repaired?: string;
  maxFloor?: string;
  minFloor?: string;
  flatNumber?: string;
  order?: string[];
  search?: string;
  apartment?: string;
  fields?: string[] | string;
  startTime?: number;
  endTime?: number;
}

const Home = () => {
  const dispatch = useAppDispatch();
  const { homeCurrentPage } = useAppSelector(state => state.houseReducer);
  const { searchParams, appendMultipleDifferent, params } = useQueryParam<SearchParamsHome, any>();
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);

  const memorizedParams = useCallback(() => {
    delete searchParams.search;
    delete searchParams.startTime;
    delete searchParams.endTime;
    return searchParams;
  }, [searchParams]);

  const [tableSize, setTableSize] = useState(10);

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.home);
  const { isLoading, data, isFetching } = useSaleFlats(tableSize, memorizedParams());
  const download = useDownloadFlatsToExcel(tableSize, memorizedParams());
  const downloadSummaryBeta = useDownloadFlatsToExcelSummaryBeta(tableSize, memorizedParams());

  const { t } = useTranslation();

  useEffect(() => {
    if (tableConfig?.size && params['*'].includes('home')) {
      appendMultipleDifferent(['page', 'size'], [homeCurrentPage, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
    // eslint-disable-next-line
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
    dispatch(setHomeCurrentPage(page));
  };

  useEffect(() => {
    if (!params['*'].includes('home')) {
      dispatch(setHomeCurrentPage(1));
    }
  }, [params['*']]);

  const downloadExcel = () => {
    download.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadExcelSummaryBeta = () => {
    downloadSummaryBeta.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('contract.Jadvalni_yuklash')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
          <div onClick={downloadExcelSummaryBeta} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Xonadonlar_holati')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.home}>
          <SaleHousesList />
          <div className="d-flex align-center justify-space-between">
            <HomeFilters />
            <Spin spinning={download.isLoading || downloadSummaryBeta.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.home} />
            </Spin>
          </div>
          <TableFlats isFetching={isFetching} tableConfigFetch={tableConfigFetch} tableConfig={tableConfig?.config || []} data={data?.data || []} isLoading={isLoading} />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
        </div>
      </Content>
    </>
  );
};

export default Home;
