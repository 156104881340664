import { useLocation } from 'react-router-dom';
import { setChangeDiscountCurrency, setChangeMonthCurrency } from '@reducers/BossCurrencySlice';
import { tableExamples } from '@utils/constants/tableExamples';
import { AllTjmModel } from '@utils/models/AllTjmModel';
import { BuildingAll } from '@utils/models/BuildingAll';
import { MyHomeConfigModel } from '@utils/models/MyHomeConfigModel';
import { NumerationConfigModel } from '@utils/models/NumerationConfigModel';
import { TableConfigValue } from '@utils/models/TableConfig';
import axios from 'axios';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { useQueryParam } from '../hooks/useQueryParams';
import { SearchParamsBroker } from '../pages/broker/Broker';
import { SearchParamsBuilder } from '../pages/builderSettings/BuilderSettings';
import { SearchParamsClient } from '../pages/client/Client';
import { SearchParamsContract } from '../pages/contract/Contract';
import { SearchParamsEmployee } from '../pages/settingEmployee/SettingEmployee';
import { getFlat } from '../pages/shaxmatka/helper/getFlat';
import { $api, $apiVisual } from '../service/RequestService';
import { setSelectedClient } from '../store/reducers/ChangesSlice';
import { setClientAndClientTypeAndClientPhone, setClientBySearchPassportDate } from '../store/reducers/ClientSlice';
import { setContractNumber, setFlats } from '../store/reducers/CreateContractSlice';
import { setCrmFields } from '../store/reducers/CrmFieldSlice';
import { setPlanFactId } from '../store/reducers/CrmStatisticsSlice';
import { setHouseCurrency, setMainCurrency } from '../store/reducers/CurrencySlice';
import { setFlat } from '../store/reducers/FlatSlice';
import { setFirstData } from '../store/reducers/MonthlyPaymentsSlice';
import { setPriceCalculateType, setShaxmatka } from '../store/reducers/ShaxmatkaSlice';
import { setTrigger } from '../store/reducers/TriggerSlice';
import { setLifeTime, setUser } from '../store/reducers/UserSlice';
import { setWebRTC, setWebRTCDisable } from '../store/reducers/WebRTCSlice';
import {
  accountEndpoints,
  activeSessionEndpoints,
  addressEndpoints,
  amoCrmEndpoints,
  amoCrmHouseEndpoints,
  authEndpoints,
  BIG_DATA_CLOUD,
  bookingEndpoints,
  branchEndpoints,
  builderEndpoints,
  buildingEndpoints,
  callHistoryEndpoints,
  cardEndpoints,
  cashEndpoints,
  chatMessageEndpoints,
  clientEndpoints,
  companyConfigEndpoints,
  companyEndpoints,
  contractEndpoints,
  crmEndpoints,
  crmLeadEndpoints,
  crmPipeEndpoints,
  currencyEndpoints,
  discountEndpoints,
  employeeEndpoints,
  filesEndpoints,
  flatEndpoints,
  GEO_CODE_YANDEX,
  houseEndpoints,
  kpiEndpoints,
  leadTaskEndpoints,
  leadTaskTypeEndpoints,
  marketplaceEndpoints,
  merchantEndpoints,
  mobileEndpoints,
  mobileVersionThreeEndpoints,
  mobileVersionTwoEndpoints,
  moiSmsTemplateEndpoints,
  moiZvonkiEndpoints,
  monetaryConfig,
  monetaryEndpoints,
  myHomeConfigEndPoints,
  notificationEndpoints,
  onlinePbxEndpoints,
  OPEN_CAGA_DATA,
  OPEN_CAGA_DATA_KEY,
  paymentEndpoints,
  pipeStatusEndpoints,
  playMobileEndpoints,
  realtorCongigEndpoints,
  reportConfigEndpoints,
  saleEndPoints,
  SEARCH_BY_PASSPORT_CLIENT_FOR_ONLINE_MAHALLA,
  SEARCH_BY_PASSPORT_CLIENT_FOR_STUDY_SCHOLARSHIP,
  showroomEndpoints,
  smsConfigEndpoints,
  smsTemplateEndpoints,
  staffEndpoints,
  statisticsEndpoints,
  tableEndpoints,
  tagEndpoints,
  taskEndpoints,
  telegramAndWebEndpoints,
  telegramEndpoints,
  transferDataEndpoints,
  uyqurEndpoints,
  websiteEndpoints,
  widgetEndpoints
} from '../utils/constants/apis';
import { DATE_FORMAT } from '../utils/constants/format';
import { Permissions } from '../utils/constants/permissions';
import { queryNames } from '../utils/constants/queryNames';
import { getClientsBody, getContractsBody, getStaffBody, getTaskBody } from '../utils/helper/getSearchAndDownloadBodyAllTables';
import { mathFloor } from '../utils/helper/mathFloor';
import { ActiveSessions } from '../utils/models/ActiveSessions';
import { AmoCrm } from '../utils/models/AmoCrm';
import { Apelsin } from '../utils/models/Apelsin';
import { BaseEntity } from '../utils/models/BaseEntity';
import { BaseLanguageEntity } from '../utils/models/BaseLanguageEntity';
import { BigDataCloud } from '../utils/models/BigDataCloud';
import { BlockFloorStatistic } from '../utils/models/BlockFloorStatistic';
import { ComplexCapital, ComplexTotalCapital, FlatsInfoOneHouse, ListInfoFlats, ListPrepaymentHouses, PrepaymentForPieChart } from '../utils/models/BossComplex';
import { ContractDetailProps } from '../utils/models/BossContractDetail';
import { BossHeader, BossHomeFlatsCount, BossHomePayments } from '../utils/models/BossHome';
import { PaymentDelayProps } from '../utils/models/BossPaymentDelay';
import { BossPayments, BossPaymentsClient, BossPaymentsType } from '../utils/models/BossPayments';
import { BotChat } from '../utils/models/BotChat';
import { Branch } from '../utils/models/BranchSetting';
import { BronIdOrFlatIds } from '../utils/models/BronIdOrFlatIds';
import { Builder } from '../utils/models/BuilderSettings';
import { BuildingId } from '../utils/models/Building';
import { Card } from '../utils/models/Card';
import { Cash } from '../utils/models/Cash';
import {
  ClientBalanceHistoryList,
  ClientListData,
  GetClientByType,
  JuridicalPersonCreate,
  JuridicalPersonView,
  NaturalPersonCreate,
  NaturalPersonView
} from '../utils/models/Client';
import { ClientId } from '../utils/models/ClientId';
import { ClientType } from '../utils/models/ClientType';
import { ContractAmount } from '../utils/models/ContractAmount';
import { SearchClient } from '../utils/models/ContractBooking';
import { ContractForPaymentSearch } from '../utils/models/ContractForPaymentSearch';
import { ContractId } from '../utils/models/ContractId';
import { ContractList } from '../utils/models/ContractList';
import { ContractMonthlyPayment } from '../utils/models/ContractMothlyPayment';
import { MonthlyPaymentStatus } from '../utils/models/CreateContract';
import { CreateOrderSearchClients } from '../utils/models/CreateOrder';
import { CrmField } from '../utils/models/CrmField';
import { CrmLead } from '../utils/models/CrmLeadList';
import { CurrencyItem, RemoteCurrency } from '../utils/models/Currency';
import { DeveloperNotificationType } from '../utils/models/DeveloperNotificationType';
import { DiscountList } from '../utils/models/Discount';
import { EmployeeId, EmployeeList } from '../utils/models/EmployeeList';
import { FacebookLeadForm } from '../utils/models/FacebookLeadForm';
import { FacebookPagePipe } from '../utils/models/FacebookPagePipe';
import { FacebookPages } from '../utils/models/FaceBookPages';
import { FacebookPipeStatus } from '../utils/models/FacebookPipeStatus';
import { FileObject, ImageResponseGetById } from '../utils/models/File';
import { FileTemplate, FileTemplateLanguage, FileTemplateType } from '../utils/models/FileTemplate';
import { SearchParamsTask } from '../utils/models/FilterTask';
import { Flat, FlatId } from '../utils/models/Flat';
import { FlatField } from '../utils/models/FlatField';
import { FlatInfoId } from '../utils/models/FlatInfoId';
import { FlatMore } from '../utils/models/FlatMore';
import { FlatStatistics } from '../utils/models/FlatStatistics';
import { FlatVisual } from '../utils/models/FlatVisual';
import { GeoCodeYandex } from '../utils/models/GeoCodeYandex';
import { House, HouseConfigList } from '../utils/models/House';
import { HouseAllData } from '../utils/models/HouseAllData';
import { HouseForShowroom } from '../utils/models/HouseForShowroom';
import { InformationForPdf } from '../utils/models/InformationForPdf';
import { InstagramAccount } from '../utils/models/InstagramAccount';
import { Kpi } from '../utils/models/Kpi';
import { LeadMessageBox } from '../utils/models/LeadMessageBox';
import { LeadStatus } from '../utils/models/LeadStatus';
import { LeadTaskNotClosed } from '../utils/models/LeadTaskNotClosed';
import { LeadTaskType } from '../utils/models/LeadTaskType';
import { Marketplace } from '../utils/models/Marketplace';
import { MarketplaceHouses } from '../utils/models/MarketplaceHouses';
import { MessageConfig } from '../utils/models/MessageConfig';
import { MessageTemplate } from '../utils/models/MessageTemplate';
import { MobileDebt } from '../utils/models/MobileDebt';
import { MonetaryConfig } from '../utils/models/MonetaryConfig';
import { MonetaryPaymentContract } from '../utils/models/MonetaryPaymentContract';
import { MonetaryPaymentFilter } from '../utils/models/MonetaryPaymentFilter';
import { MonetaryPaymentId } from '../utils/models/MonetaryPaymentId';
import { MyCalls, MyMessageTemplate } from '../utils/models/MyCalls';
import { NotificationMe } from '../utils/models/NotificationMe';
import { CrmEmployee, OneLid } from '../utils/models/OneLid';
import { OnlinePbx, OnlinePbxContact, OnlinePbxHistoryCallResponse, OnlinePbxPipes, OnlinePbxUser } from '../utils/models/OnlinePbx';
import { OpenCaga } from '../utils/models/OpenCagaData';
import { Order } from '../utils/models/OrderTable';
import { Pagination } from '../utils/models/Pagination';
import { Payment, PaymentConfig } from '../utils/models/Payment';
import { PerAreaAvgPrice } from '../utils/models/PerAreaAvgPrice';
import { PipeAll, PipeStatuses } from '../utils/models/PipeAll';
import { PipeStatistic, PipeStatusList } from '../utils/models/PipeStatistic';
import { PlanFactCompanyField } from '../utils/models/PlanFactCompanyField';
import { PlanCostTableItem, PlanFactLeadCost } from '../utils/models/PlanFactLeadCost';
import { PlanFactPipe } from '../utils/models/PlanFactPipe';
import { Originator, PlayMobileMutate } from '../utils/models/PlayMobile';
import { Profile } from '../utils/models/Profile';
import { Reports } from '../utils/models/Reports';
import { Role } from '../utils/models/Roles';
import { SaleStatType } from '../utils/models/SaleStateType';
import { SaleStats } from '../utils/models/SaleStats';
import { SearchClientBalance } from '../utils/models/SearchClientBalance';
import { SearchByPassportData } from '../utils/models/SearchDataByPassportClient';
import { ListStaff, OneStaff, Staff, StaffTable } from '../utils/models/Staff';
import { Surcharge } from '../utils/models/Surcharge';
import { TableKey, TableKeyCustom } from '../utils/models/TableKey';
import { TagsResponse } from '../utils/models/Tags';
import { CompletedTaskResponse, Task } from '../utils/models/Task';
import { TaskEmployee } from '../utils/models/TaskEmployee';
import { TaskFilter } from '../utils/models/TaskFilter';
import { TawType, TelegramAndWebProps } from '../utils/models/TelegramAndWebProps';
import { BotInfoForMessage, Faq, TelegramBot } from '../utils/models/TelegramBot';
import { TemplateFields } from '../utils/models/TemplateFields';
import { TransferDataList } from '../utils/models/TransferData';
import { TriggerBody, TYPE_TRIGGER } from '../utils/models/Trigger';
import { TypeSearchClientByPassport } from '../utils/models/typeSearchClientByPassport';
import { UpdateMonthlyPaymentRes } from '../utils/models/UpdateMonthlyPaymentRes';
import { Uyqur } from '../utils/models/Uyqur';
import { UyqurBuilders } from '../utils/models/UyqurBuilders';
import { Widget } from '../utils/models/Widget';

import { baseErrorHandler } from './baseErrorHandler';
import { useCurrencyCompanyId, useUserGetOnlinePbx } from './mutation';

export function useBuildingId(id?: number) {
  const dispatch = useAppDispatch();
  const { params, searchParams } = useQueryParam<any, any>();
  const activeKey = Number(searchParams?.blockId);
  const houseId = params?.id || id;

  return useQuery<BuildingId[]>(
    [queryNames.BUILDING_ID, houseId],
    async () => {
      const res = await $api.get(`${buildingEndpoints.INDEX}?houseId=${houseId}`);

      return res.data.data;
    },
    {
      enabled: !!houseId,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onSuccess(data) {
        const activeBuilding = data?.find(item => item?.id === Number(activeKey));

        dispatch(setPriceCalculateType(activeBuilding?.priceCalculateType || undefined));
        dispatch(setHouseCurrency(data[0]?.currency || undefined));
      },
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useAllBuildingBy(id: number) {
  const dispatch = useAppDispatch();

  return useQuery<BuildingId[]>(
    [queryNames.BUILDING_ID, id],
    async () => {
      const res = await $api.get(`${buildingEndpoints.INDEX}?houseID=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onSuccess(data) {
        dispatch(setHouseCurrency(data[0]?.currency || undefined));
      },
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useBuildingEdit(id: number) {
  return useQuery<any>(
    [queryNames.BUILDING_EDIT, id],
    async () => {
      const res = await $api.get(buildingEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// flat_all

export function useFlat(id: number) {
  const dispatch = useAppDispatch();

  return useQuery<FlatId[] | undefined>(
    [queryNames.FLAT_ALL, id],
    async () => {
      const res = await $api.get(`${flatEndpoints.INDEX}?buildingID=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onSuccess: data => {
        dispatch(setShaxmatka(getFlat(data) as any));
      },
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get message template
export function useMessageTemplate(search: string) {
  return useQuery<MessageTemplate[]>(
    [queryNames.MESSAGE_TEMPLATE, search],
    async () => {
      const res = await $api.get(`${smsTemplateEndpoints.INDEX}?search=${search}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useMessageTemplateFields(type: string) {
  return useQuery<TemplateFields[]>(
    [queryNames.MESSAGE_TEMPLATE, type],
    async () => {
      const res = await $api.get(`${smsTemplateEndpoints.INDEX + type}/fields`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

// get message config
export function useMessageConfig() {
  return useQuery<MessageConfig[]>(
    [queryNames.MESSAGE_CONFIG],
    async () => {
      const res = await $api.get(smsConfigEndpoints.INDEX);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

// get builder list
export function useBuilderList(searchObj: SearchParamsBuilder) {
  let url = `${builderEndpoints.INDEX}?page=${searchObj.page || 1}&size=${searchObj.size || 10}`;

  if (searchObj.search) {
    url += `&name=${searchObj.search}`;
  }

  return useQuery<Pagination<Builder>>(
    [queryNames.BUILDER_SETTINGS, JSON.stringify(searchObj)],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get builder list
export function useBranchList(searchObj: SearchParamsBuilder) {
  let url = `${branchEndpoints.INDEX}?page=${searchObj.page || 1}&size=${searchObj.size || 10}`;

  if (searchObj.search) {
    url += `&name=${searchObj.search}`;
  }

  return useQuery<Pagination<Branch>>(
    [queryNames.BRANCH_SETTING, JSON.stringify(searchObj)],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get table config
// export function useTableConfig(key: TableKey, selectedPipeId?: number) {
//   const url = tableEndpoints.INDEX + key;

//   return useQuery<TableConfigValue | any>(
//     [queryNames.TABLE, key, selectedPipeId],
//     async () => {
//       const res = await $api.get(url);

//       if (!res?.data?.data) {
//         return null;
//       }

//       const data = [...JSON.parse(res?.data?.data)];

//       console.log('data-duckk', data);

//       tableExamples[key].forEach(item => {
//         if (data?.every((e: any) => e?.id !== item?.id)) {
//           data.push(item);
//         }
//       });

//       return data;

//       // return JSON.parse(res.data.data);
//     },
//     {
//       refetchOnWindowFocus: false,
//       refetchInterval: Infinity,
//       onError: baseErrorHandler,
//       retry: false
//     }
//   );
// }

export function useTableConfig(key: TableKey, selectedPipeId?: number) {
  const url = tableEndpoints.INDEX + key;

  return useQuery<TableConfigValue | any>(
    [queryNames.TABLE, key, selectedPipeId],
    async () => {
      const res = await $api.get(url);

      if (!res?.data?.data) {
        return null;
      }

      let data;

      try {
        data = JSON.parse(res.data.data);
      } catch (error) {
        // console.error("Failed to parse JSON:", error);
        return null;
      }

      if (!Array.isArray(data?.config)) {
        // console.error("Expected 'data' to be an array, but received:", data);
        return null;
      }

      tableExamples[key].forEach(item => {
        if (data?.config?.every((e: any) => e?.id !== item?.id)) {
          data?.config?.push(item);
        }
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get houses name and id list for search
export function useHousesNameAndID(enabled: boolean | undefined = true) {
  return useQuery<BaseEntity[]>(
    [queryNames.HOUSES_ID_LIST],
    async () => {
      const res = await $api.get(houseEndpoints.COMPACT);

      return res.data?.data || [];
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get houses name and id list for search
export function useBookingClientSearch(searchUrl: string, phone?: number) {
  return useInfiniteQuery<Pagination<CreateOrderSearchClients>>(
    [queryNames.BOOKING_CLIENT_SEARCH, searchUrl],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${clientEndpoints.BOOKING + searchUrl}&page=${pageParam}`);

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: phone !== -1 && phone !== undefined,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get flat filters
export function useFlatId(id: string) {
  return useQuery<Flat>(
    [queryNames.FLAT, id],
    async () => {
      const res = await $api.get(flatEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// export function useDiscountByFlatId(id: string) {
//   const dispatch = useAppDispatch()
//   return useQuery<FlatDiscountRepairedNotRepaired>(
//     [queryNames.DISCOUNT_BY_FLAT_ID, id],
//     async () => {
//       const res = await $api.get(flatEndpoints.DISCOUNT + '/' + id)
//       return {
//         discountRepaired: res.data.data.discountRepaired,
//         discountNotRepaired: res.data.data.discountNotRepaired,
//       }
//     },
//     {
//       refetchInterval: Infinity,
//       enabled: id !== 'null',
//       onSuccess: (data) => {
//         dispatch(setHaveDiscount(data))
//       },
//       refetchOnWindowFocus: false,
//       onError: baseErrorHandler,
//       retry: false,
//     }
//   )
// }

// get one order
export function useOneOrder(id?: string) {
  const { searchParams } = useQueryParam<any, any>();
  const currencyCompanyId = useCurrencyCompanyId();

  return useQuery<Order>(
    [queryNames.BOOKED, id],
    async () => {
      const res = await $api.get(`${bookingEndpoints.INDEX}${id}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      onSuccess: () => {
        if (searchParams.company) {
          currencyCompanyId.mutate(Number(atob(searchParams.company)));
        }
      },
      enabled: !!id,
      refetchOnWindowFocus: false
    }
  );
}

// get one contract number
export function useContractNumber(id: number) {
  const dispatch = useAppDispatch();

  return useQuery<{ number: string }>(
    [queryNames.CONTRACT_NUMBER, id],
    async () => {
      const res = await $api.get(`${contractEndpoints.CURRENT_NUMBER}?flatId=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      onSuccess: data => {
        dispatch(setContractNumber({ number: data.number, isChange: false }));
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useClientByOrderIdOrClientId(orderId: string) {
  const dispatch = useAppDispatch();

  return useQuery<NaturalPersonCreate | JuridicalPersonCreate>(
    [queryNames.CLIENT, queryNames.BOOKED, orderId],
    async () => {
      const splitElement = orderId.split('=');

      if (splitElement[0] === GetClientByType.CLIENT_ID) {
        const res = await $api.get(`${clientEndpoints.INDEX}${splitElement[1]}`);

        return res.data.data;
      }
      const res = await $api.get(`${bookingEndpoints.INDEX}${splitElement[1]}/client`);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(
          setClientAndClientTypeAndClientPhone({
            type: data?.type || ClientType.NATURAL,
            client: data,
            phones: data?.phones || []
          })
        );
      },
      enabled: orderId.includes(GetClientByType.ORDER_ID) || orderId.includes(GetClientByType.CLIENT_ID),
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useClientByClientId(clientId: number) {
  const dispatch = useAppDispatch();

  return useQuery<NaturalPersonCreate | JuridicalPersonCreate>(
    [queryNames.CLIENT, clientId],
    async () => {
      const res = await $api.get(`${clientEndpoints.INDEX}${clientId}`);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(
          setClientAndClientTypeAndClientPhone({
            type: data?.type || ClientType.NATURAL,
            client: data,
            phones: data?.phones || []
          })
        );
      },
      enabled: clientId !== -1,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useRegions(countryId?: number) {
  return useQuery<BaseLanguageEntity[]>(
    [queryNames.REGION, countryId],
    async () => {
      const res = await $api.get(`${addressEndpoints.CITY}${countryId ? `?countryId=${countryId}` : ''}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler
    }
  );
}

export function useGetDistrict(id: number, type: 'district' | 'region') {
  let url = addressEndpoints.CITY_ID;

  if (type === 'district') {
    url = addressEndpoints.DISTRICT_ID;
  }
  return useQuery<BaseLanguageEntity[]>(
    [queryNames.DISTRICT, id, type],
    async () => {
      const res = await $api.get(`${url}/${id}`);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      onError: baseErrorHandler
    }
  );
}

export function useFlatByOrderIdOrFlatId(orderOrFlatId: string) {
  const dispatch = useAppDispatch();

  return useQuery<Flat>(
    [queryNames.FLAT, orderOrFlatId],
    async () => {
      const arr = orderOrFlatId.split('=');

      if (arr[0] === BronIdOrFlatIds.BRON_ID) {
        const res = await $api.get(`${flatEndpoints.BY_BOOKING}/${arr[1]}`);

        return res.data.data;
      }
      const res = await $api.get(`${flatEndpoints.INDEX}${arr[1]}`);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(
          setFlat({
            flat: data
          })
        );
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

// get contracts list
export function useContracts(size: number, searchObj: SearchParamsContract) {
  return useQuery<Pagination<ContractList>>(
    [queryNames.CONTRACT, searchObj.page, JSON.stringify(searchObj)],
    async () => {
      const res = await $api.post(contractEndpoints.FILTER, getContractsBody(size, searchObj));

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useContractId(contractId: number) {
  return useQuery<ContractId>(
    [queryNames.CONTRACT_ID, contractId],
    async () => {
      const res = await $api.get(contractEndpoints.INDEX + contractId);

      return res.data.data;
    },
    {
      enabled: !!contractId,
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useStaff(size: number, searchObj: SearchParamsBroker) {
  return useQuery<Pagination<StaffTable>>([queryNames.STAFF, searchObj.page, JSON.stringify(searchObj)], async () => {
    const res = await $api.post(staffEndpoints.FILTER, getStaffBody(size, searchObj));

    return res.data.data;
  });
}

export function useOneStaff(id: number) {
  return useQuery<Staff>(
    [queryNames.STAFF, id],
    async () => {
      const res = await $api.get(staffEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function useListStaffCreateContract() {
  return useQuery<ListStaff[]>(
    [queryNames.STAFF_LIST],
    async () => {
      const res = await $api.get(contractEndpoints.staff);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function useOneStaffInformation(id: number, date: string[]) {
  return useQuery<OneStaff>(
    [queryNames.STAFF_INFORMATION, id, date[0], date[1]],
    async () => {
      const res = await $api.get(`${staffEndpoints.INDEX + id}/info?start=01.${date[0]}&finish=01.${date[1]}`);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function useClient(size: number, searchObj: SearchParamsClient) {
  return useQuery<Pagination<ClientListData>>([queryNames.CLIENT, searchObj.page, JSON.stringify(searchObj)], async () => {
    const res = await $api.post(clientEndpoints.FILTER, getClientsBody(size, searchObj));

    return res.data.data;
  });
}

export function useClientId(clientId: number) {
  return useQuery<ClientId>(
    [queryNames.CLIENT_ID, clientId],
    async () => {
      const res = await $api.get(clientEndpoints.INDEX + clientId);

      return res.data.data;
    },
    {
      enabled: clientId !== -1,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function usePayment(paymentId: string) {
  const { user } = useAppSelector(state => state.userReducer);

  return useQuery<Payment[]>(
    [queryNames.PAYMENT, paymentId],
    async () => {
      const res = await $api.get(`${contractEndpoints.INDEX + paymentId}/payment`);

      return res.data.data;
    },
    {
      enabled: !!paymentId && user?.role?.permissions?.includes(Permissions.PERMISSION_CONTRACT_PAYMENT),
      // enabled: !!paymentId,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useFlatInfo(id?: number) {
  return useQuery<FlatInfoId>(
    [queryNames.FLAT_INFO, id],
    async () => {
      const res = await $api.get(`${flatEndpoints.INFO}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity
    }
  );
}

export function useContractAmount() {
  const { params } = useQueryParam();

  return useQuery<ContractAmount[]>(
    [queryNames.CONTRACT_AMOUNT, params.id],
    async () => {
      const res = await $api.get(`${contractEndpoints.INDEX + params.id}/amount`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function useGetImageByImageId(imageId: number) {
  return useQuery<ImageResponseGetById>(
    [queryNames.IMAGE, imageId],
    async () => {
      const res = await $api.get(filesEndpoints.INDEX + imageId);
      const imageBlob = await $api.get(res.data.data.absolutePath, { responseType: 'blob' });

      return {
        fileObject: res.data.data,
        fileBlob: imageBlob.data
      };
    },
    {
      enabled: !!imageId,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function useGetImageByImageIdDeveloper(imageId: number) {
  return useQuery<ImageResponseGetById>(
    [queryNames.IMAGE, imageId],
    async () => {
      const res = await $api.get(`${filesEndpoints.DEVELOPER}/${imageId}`);
      const imageBlob = await $api.get(res.data.data.absolutePath, {
        responseType: 'blob'
      });

      return {
        fileObject: res.data.data,
        fileBlob: imageBlob.data
      };
    },
    {
      enabled: !!imageId,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

export function useContractMonthlyPayment(id: string, status: MonthlyPaymentStatus) {
  return useQuery<ContractMonthlyPayment[]>(
    [queryNames.CONTRACT_MOTHLY_PAYMENT, id, status],
    async () => {
      const res = await $api.get(`${contractEndpoints.INDEX + id}/monthly-payment/${status}`);

      return res.data.data;
    },
    {
      // onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useSearchClientByName(name: string) {
  return useInfiniteQuery<Pagination<SearchClient>>(
    [queryNames.CLIENT_SEARCH, name],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${clientEndpoints.SEARCH}?search=${name}&page=${pageParam}`);

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: !!name,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useUpdateMonthlyPaymentData(contractId: number) {
  const url = `${contractEndpoints.INDEX + contractId}/update/monthly-payment`;
  const dispatch = useAppDispatch();

  return useQuery<UpdateMonthlyPaymentRes>(
    [queryNames.UPDATE_MONTHLY_PAYMENT, contractId],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setFirstData(data));
      },
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useContractListForPayment(search: string) {
  const url = `${contractEndpoints.FOR_PAYMENT}?search=${search}`;

  return useQuery<ContractForPaymentSearch[]>(
    [queryNames.CONTRACT_LIST_FOR_PAYMENT, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: search?.length >= 3,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useClientBalanceSearch(search: string) {
  return useInfiniteQuery<Pagination<SearchClientBalance>>(
    [queryNames.PAYMENT_CLIENT_BALANCE_LIST, search],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${clientEndpoints.BALANCE}?search=${search}&page=${pageParam}`);

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: search?.length >= 3,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useClientViewByClientId(clientId: string) {
  return useQuery<NaturalPersonView | JuridicalPersonView>(
    [queryNames.CLIENT_VIEW, clientId],
    async () => {
      const res = await $api.get(`${clientEndpoints.VIEW}/${clientId}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useClientBalanceHistoryList(clientId: string) {
  return useQuery<ClientBalanceHistoryList[]>(
    [queryNames.CLIENT_BALANCE_LIST, clientId],
    async () => {
      const res = await $api.get(`${clientEndpoints.INDEX}${clientId}/payment`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useEmployeeList(size: number, searchObj: SearchParamsEmployee) {
  const newSearchObj = { ...searchObj };
  const statuses: string[] = [];
  const roles: string[] = [];

  if (Array.isArray(newSearchObj.statuses)) {
    newSearchObj.statuses.forEach(id => {
      statuses.push(id);
    });
  } else if (newSearchObj.statuses) {
    statuses.push(newSearchObj.statuses);
  }
  if (Array.isArray(newSearchObj.roles)) {
    newSearchObj.roles.forEach(id => {
      roles.push(id);
    });
  } else if (newSearchObj.roles) {
    roles.push(newSearchObj.roles);
  }
  newSearchObj.statuses = statuses.length > 0 ? (statuses as any) : undefined;
  newSearchObj.roles = roles.length > 0 ? (roles as any) : undefined;
  if (!searchObj.page) {
    newSearchObj.page = '1';
    // newSearchObj.size = '10';
  }
  return useQuery<Pagination<EmployeeList>>(
    [queryNames.EMPLOYEE_LIST, JSON.stringify(newSearchObj)],
    async () => {
      const res = await $api.post(employeeEndpoints.FILTER, newSearchObj);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useEmployeeId(id: number) {
  return useQuery<EmployeeId>(
    [queryNames.EMPLOYEE_LIST, id],
    async () => {
      const res = await $api.get(employeeEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useBuilderId(id: number) {
  return useQuery<Builder>(
    [queryNames.BUILDERS_COMPACT_LIST, id],
    async () => {
      const res = await $api.get(builderEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useBranchId(id: number) {
  return useQuery<Branch>(
    [queryNames.SINGLE_BRANCH_SETTING, id],
    async () => {
      const res = await $api.get(branchEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler
    }
  );
}

export function useEmployeeRole() {
  return useQuery<BaseLanguageEntity[]>(
    [queryNames.EMPLOYEE_LIST],
    async () => {
      const res = await $api.get(`${employeeEndpoints.INDEX}role`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useHouseConfigList(size: number, page: number, sale: boolean, search: string) {
  let url = `${houseEndpoints.INFO}?page=${page}&size=${size}&sale=${sale}`;

  if (search) {
    url += `&name=${search}`;
  }

  return useQuery<Pagination<HouseConfigList>>(
    [queryNames.HOUSE_CONFIG_LIST, page, size, search, sale],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useBuildersCompact() {
  return useQuery<BaseEntity[]>(
    [queryNames.BUILDERS_COMPACT_LIST],
    async () => {
      const res = await $api.get(builderEndpoints.COMPACT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useOneHouse(id: number) {
  return useQuery<House>(
    [queryNames.ONE_HOUSE, id],
    async () => {
      const res = await $api.get(houseEndpoints.INDEX + id);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: id !== 0,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCompanyLogo() {
  return useQuery<FileObject>(
    [queryNames.COMPANY_LOGO],
    async () => {
      const res = await $api.get(companyConfigEndpoints.COMPANY_LOGO);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity
      // onError: baseErrorHandler,
    }
  );
}

export function useShowroomColor() {
  return useQuery<string>(
    [queryNames.SHOWROOM_COLOR],
    async () => {
      const res = await $api.get(companyConfigEndpoints.SYSTEM_COLOR);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useOriginatorHouse() {
  return useQuery<Originator[]>(
    [queryNames.ORIGINAL_HOUSE],
    async () => {
      const res = await $api.get(`${playMobileEndpoints.ORIGINATOR}/house`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function usePlayMobileConf() {
  return useQuery<PlayMobileMutate | any>(
    [queryNames.PLAY_MOBILE],
    async () => {
      const res = await $api.get(playMobileEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useDiscount() {
  return useQuery<DiscountList[]>(
    [queryNames.DISCOUNT],
    async () => {
      const res = await $api.get(discountEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCompanyConfigNumeration(type: 'contract' | 'payment') {
  return useQuery<string>(
    [queryNames.COMPANY_CONFIG_NUMERATION, type],
    async () => {
      if (type === 'contract') {
        const res = await $api.get(companyConfigEndpoints.CONTRACT_NUMBER_PATTERN);

        return res.data.data;
      }
      const res = await $api.get(companyConfigEndpoints.PAYMENT_NUMBER_PATTERN);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetNumerationConfigValues() {
  return useQuery<NumerationConfigModel[]>({
    queryKey: [queryNames.NUMERATION_CONFIG],
    queryFn: async () => {
      const res = await $api.get('/v1/company-config/contract-number-pattern-value');

      return res.data?.data;
    },

    refetchOnWindowFocus: false,
    refetchInterval: Infinity,
    retry: false,
    onError: baseErrorHandler
  });
}

export function useDiscountId() {
  const { params } = useQueryParam();

  return useQuery<DiscountList>(
    [queryNames.DISCOUNT_ID, params.id],
    async () => {
      const res = await $api.get(discountEndpoints.INDEX + params.id);

      return res.data.data;
    },
    {
      enabled: !!params.id,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCompanyConfigNumerationFields(type: 'contract' | 'payment') {
  return useQuery<TemplateFields[]>(
    [queryNames.COMPANY_CONFIG_NUMERATION_ANNOTATION, type],
    async () => {
      let data;
      const newData: TemplateFields[] = [];

      if (type === 'contract') {
        const res = await $api.get(companyConfigEndpoints.CONTRACT_NUMBER_PATTERN_ANNOTATIONS);

        data = res.data.data;
      } else {
        const res = await $api.get(companyConfigEndpoints.PAYMENT_NUMBER_PATTERN_ANNOTATIONS);

        data = res.data.data;
      }
      for (const key in data) {
        newData.push(data[key]);
      }
      return newData;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCompanyTemplates(type: FileTemplateType, tempId?: number) {
  return useQuery<FileTemplate[]>(
    [queryNames.DOCUMENT_TEMPLATE, type, tempId],
    async () => {
      if (tempId) {
        const res = await $api.get(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/${type}?customTempId=${tempId}`);

        return res.data.data;
      }
      const res = await $api.get(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}/${type}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetOneCompanyTemplate(configId: number) {
  return useQuery({
    queryKey: [queryNames.DOCUMENT_TEMPLATE, configId],
    queryFn: async () => {
      const res = await $api.get(`${companyConfigEndpoints.DOCUMENT_TEMPLATE}?confId=${configId}`);

      return res.data.data;
    },
    enabled: !!configId,
    refetchOnWindowFocus: false,
    refetchInterval: Infinity,
    retry: false,
    onError: baseErrorHandler
  });
}

export function useTransferDataList() {
  return useQuery<TransferDataList[]>(
    [queryNames.TRANSFER_DATA_LIST],
    async () => {
      const res = await $api.get(transferDataEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}



export function useCompanyCompact() {
  return useQuery<BaseEntity[]>(
    [queryNames.COMPANY_COMPACT],
    async () => {
      const res = await $api.get(companyEndpoints.COMPACT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCompanyRolePermissions(companyId: string) {
  return useQuery<{
    table: any[];
    roles: Role[];
  }>(
    [queryNames.COMPANY_ROLE_PERMISSION, companyId],
    async () => {
      const tableArray = [];
      const { data: permissionRes } = await $api.get(authEndpoints.PERMISSION);
      const { data: companyRes } = await $api.get(`${authEndpoints.ROLE_COMPANY}/${companyId}`);

      for (let i = 0; i < permissionRes.data.length; i++) {
        const roleArray = [];

        for (let j = 0; j < companyRes.data.length; j++) {
          const permission = companyRes.data[j]?.permissions?.find((item: { permission: string; active: boolean }) => item.permission === permissionRes.data[i]?.permission);

          roleArray.push({
            name: companyRes.data[j]?.name.uz,
            id: companyRes.data[j]?.id,
            checked: permission?.active
          });
        }
        tableArray.push({
          name: permissionRes.data[i]?.permission,
          label: permissionRes.data[i]?.name?.uz,
          roles: roleArray,
          type: permissionRes?.data[i]?.type?.permission,
          typeNameUz: permissionRes?.data[i]?.type?.name?.uz
        });
      }
      return {
        table: tableArray,
        roles: companyRes.data
      };
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useBranchCompact() {
  return useQuery<BaseEntity[]>(
    [queryNames.BRANCH_COMPACT],
    async () => {
      const res = await $api.get(branchEndpoints.COMPACT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}
export function useDevBranchCompact(companyId: number) {
  return useQuery<BaseEntity[]>(
    [queryNames.BRANCH_COMPACT],
    async () => {
      const res = await $api.get(`${branchEndpoints.DEV}/${companyId}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useSearchClientBuPassportData(passportSerial: string, passportNumber: string, dateBirth: string, type: TypeSearchClientByPassport) {
  const { clientPhones: phone1 } = useAppSelector(state => state.clientReducer);
  const { clientPhones: phone2 } = useAppSelector(state => state.changesReducer);

  const clientPhones = type === TypeSearchClientByPassport.CLIENT ? phone1 : phone2;
  const dispatch = useAppDispatch();

  return useQuery<SearchByPassportData>(
    [queryNames.FIND_CLIENT_SEARCH_BY_PASSPORT_DATA, passportSerial, passportNumber, dateBirth],
    async () => {
      const convertedDateBirth = dateBirth.split('.').reverse().join('-');

      try {
        const resMain = await axios.get(`${SEARCH_BY_PASSPORT_CLIENT_FOR_ONLINE_MAHALLA}?series=${passportSerial}&number=${passportNumber}&birth_date=${convertedDateBirth}`, {
          timeout: 5000
        });
        const resIds = await $api.get(`${addressEndpoints.DISTRICT}?districtId=${resMain.data.data?.info?.data?.district_id}`);

        return {
          info: resMain.data.data.info,
          cityAndDistrictId: resIds.data.data,
          mahalla: true
        };
      } catch {
        const resMain = await axios.get(`${SEARCH_BY_PASSPORT_CLIENT_FOR_STUDY_SCHOLARSHIP}?series=${passportSerial}&number=${passportNumber}&birth_date=${convertedDateBirth}`, {
          timeout: 10000
        });
        const resIds = await $api.get(`${addressEndpoints.DISTRICT}?districtId=${resMain.data.data?.passport?.district_id}`);

        return {
          info: resMain.data.data,
          cityAndDistrictId: resIds.data.data,
          mahalla: false
        };
      }
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        if (data?.mahalla) {
          const findClient: NaturalPersonCreate = {
            dateBirth,
            passportSerial,
            passportNumber,
            firstname: data?.info?.data?.name,
            lastname: data?.info?.data?.sur_name,
            fatherName: data?.info?.data?.patronymic_name,
            address: data?.info?.data?.address,
            passportPlace: data?.info?.data?.given_place,
            passportDate: data?.info?.data?.given_date.split('-').reverse().join('.'),
            passportExpiredDate: data?.info?.data?.expiration_date.split('-').reverse().join('.'),
            phones:
              clientPhones && clientPhones?.length > 0
                ? clientPhones
                : [
                  {
                    phone: '',
                    active: true,
                    code: ''
                  }
                ],
            type: ClientType.NATURAL,
            districtId: data?.cityAndDistrictId?.districtId,
            cityID: data?.cityAndDistrictId?.cityId,
            pinfl: String(data?.info?.data?.pinfl || ''),
            inn: String(data?.info?.data?.tin || '')
          };

          if (type === TypeSearchClientByPassport.CLIENT) {
            dispatch(setClientBySearchPassportDate(findClient));
          } else {
            dispatch(
              setSelectedClient({
                data: findClient as any,
                phones: findClient.phones
              })
            );
          }
        } else {
          const findClient: NaturalPersonCreate = {
            dateBirth,
            passportSerial,
            passportNumber,
            firstname: data?.info?.passport?.full_name?.split(' ')[1],
            lastname: data?.info?.passport?.full_name?.split(' ')[0],
            fatherName:
              data?.info?.passport?.full_name?.split(' ')[2] + (data?.info?.passport?.full_name?.split(' ')[3] ? ` ${data?.info?.passport?.full_name?.split(' ')[3]}` : ''),
            address: data?.info?.passport?.address,
            passportPlace: data?.info?.passport?.given_place,
            passportDate: data?.info?.passport?.given_date?.split('-').reverse().join('.'),
            passportExpiredDate: data?.info?.passport?.expiration_date?.split('-').reverse().join('.'),
            phones:
              clientPhones && clientPhones?.length > 0
                ? clientPhones
                : [
                  {
                    phone: '',
                    active: true,
                    code: ''
                  }
                ],
            type: ClientType.NATURAL,
            districtId: data?.cityAndDistrictId?.districtId,
            cityID: data?.cityAndDistrictId?.cityId
          };

          if (type === TypeSearchClientByPassport.CLIENT) {
            dispatch(setClientBySearchPassportDate(findClient));
          } else {
            dispatch(
              setSelectedClient({
                data: findClient as any,
                phones: findClient.phones
              })
            );
          }
        }
      },
      enabled: !!passportSerial && !!passportNumber && !!dateBirth,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useBuildingCompactByHouseId(id: number, props?: { onSuccess?: ((data: BaseEntity[]) => void) | undefined }) {
  return useQuery<BaseEntity[]>(
    [queryNames.BUILDING_COMPACT, id],
    async () => {
      const res = await $api.get(`${buildingEndpoints.COMPACT}?&houseId=${id}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      enabled: id !== -1,
      onSuccess(data) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props?.onSuccess && props?.onSuccess(data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useGetCash() {
  return useQuery<Cash[]>(
    [queryNames.PAYME_CASH],
    async () => {
      const res = await $api.get(cashEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetAllBuildings() {
  return useQuery<BuildingAll[]>(
    [queryNames.BUILDING_ALL],
    async () => {
      const res = await $api.get(buildingEndpoints.ALL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetCards(contractId: number) {
  return useQuery<Card[]>(
    [queryNames.CONTRACT_CARDS, contractId],
    async () => {
      const res = await $api.get(`${cardEndpoints.INDEX}?contractId=${contractId}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetSurcharge() {
  return useQuery<Surcharge>(
    [queryNames.SURCHARGE],
    async () => {
      const res = await $api.get(companyEndpoints.SURCHARGE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetTelegramBot() {
  return useQuery<TelegramBot>(
    [queryNames.TELEGRAM_BOT],
    async () => {
      const res = await $api.get(telegramEndpoints.BOT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetFaq(lang: string) {
  return useQuery<Faq[]>(
    [queryNames.TELEGRAM_FAQ, lang],
    async () => {
      const res = await $api.get(`${telegramEndpoints.BOT_FAQ}?lang=${lang}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useBotInfoForMessage() {
  return useQuery<BotInfoForMessage>(
    [queryNames.BOT_INFO_FOR_MESSAGE],
    async () => {
      const res = await $api.get(telegramEndpoints.BOT_FOR_MESSAGE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetAmoCrmConfig(isAccessAmoCrm: boolean) {
  return useQuery<AmoCrm>(
    [queryNames.AMO_CRM_CONFIG],
    async () => {
      const res = await $api.get(amoCrmEndpoints.AUTH_GET_BY);

      return res.data.data;
    },
    {
      enabled: isAccessAmoCrm,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetRemotePayment() {
  return useQuery<Apelsin[]>(
    [queryNames.MERCHANT_APELSIN],
    async () => {
      const res = await $api.get(merchantEndpoints.APELSIN);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFlatDataBy(id: number) {
  return useQuery<FlatVisual>(
    [queryNames.FLAT_DATA_BY, id],
    async () => {
      const res = await $api.get(`${amoCrmHouseEndpoints.FLAT_DATA_BY}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useBlockStatistic() {
  const filter = useAppSelector(state => state.allHouseFilter.filter);
  const token = useAppSelector(state => state.companyReducer.token);

  return useQuery<HouseAllData>(
    [queryNames.BLOCK_STATISTIC, filter?.houseId, token],
    async () => {
      const res = await $apiVisual.get(`${websiteEndpoints.BLOCK_STATISTIC}/${filter?.houseId}`, {
        headers: {
          'X-Auth': token!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!filter?.houseId && !!token,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useBlockFloorStatistic(blockId: number) {
  const token = useAppSelector(state => state.companyReducer.token);

  return useQuery<BlockFloorStatistic>(
    [queryNames.BLOCK_FLOOR_STATISTIC, blockId, token],
    async () => {
      const res = await $apiVisual.get(`${websiteEndpoints.BLOCK_FLOOR_STATISTIC}/${blockId}`, {
        headers: {
          'X-Auth': token!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!blockId && !!token,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useBlockFlatStatistic(blockId: number, floor: number) {
  const token = useAppSelector(state => state.companyReducer.token);

  return useQuery<FlatStatistics>(
    [queryNames.BLOCK_FLAT_DATA, blockId, floor, token],
    async () => {
      const res = await $apiVisual.get(`${websiteEndpoints.BLOCK_FLAT_DATA}/${blockId}?floor=${floor}`, {
        headers: {
          'X-Auth': token!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!blockId && !!token,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useCheckPermissionDelay() {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isShourum = permissions?.includes(Permissions.PERMISSION_SHOWROOM);

  return useQuery<any>(
    [queryNames.HOUSE_SALE_CRM],
    async () => {
      const res = await $api.get(amoCrmHouseEndpoints.CHECK_PERMISSION_DELAY);

      return res.data.data;
    },
    {
      enabled: isShourum
    }
  );
}

export function useKpiConfig() {
  return useQuery<Kpi>(
    [queryNames.KPI_CONFIG],
    async () => {
      const res = await $api.get(kpiEndpoints.ALL_SETTING);

      return res.data?.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

// export function useKpiConfig() {
//   return useQuery<Kpi[]>(
//     [queryNames.KPI_CONFIG],
//     async () => {
//       const res = await $api.get<{
//         data: KpiResponse[];
//       }>(kpiEndpoints.ALL_SETTING);
//       const finalData: Kpi[] = [];

//       console.log(finalData, 'finalData');

//       res.data.data.forEach(item => {
//         let constKpiPart: KpiPart = {
//           kpiId: 0,
//           id: 0,
//           amount: 0,
//           count: 0
//         };
//         const kpiParts: KpiPart[] = [];

//         item.kpiPartList.forEach(part => {
//           if (part.count === 0) {
//             constKpiPart = part;
//           } else {
//             kpiParts.push(part);
//           }
//         });
//         finalData.push({
//           ...item,
//           costKpi: constKpiPart,
//           kpiPartList: kpiParts
//         });
//       });

//       return finalData;
//     },
//     {
//       onError: baseErrorHandler,
//       refetchOnWindowFocus: false
//     }
//   );
// }

// get profile information
export function useGetProfile() {
  return useQuery<Profile>(
    [queryNames.PROFILE],
    async () => {
      const res = await $api.get(accountEndpoints.INDEX);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

// get profile staff
export function useOneEmployeeInformation(date: string[]) {
  return useQuery<OneStaff>(
    [queryNames.EMPLOYEE_STAFF, date[0], date[1]],
    async () => {
      const res = await $api.get(`${accountEndpoints.INFO}?start=01.${date[0]}&finish=01.${date[1]}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      retry: false,
      refetchInterval: Infinity
    }
  );
}

// get one payment number
export function usePaymentNumber() {
  return useQuery<{ number: string }>(
    [queryNames.CONTRACT_NUMBER],
    async () => {
      const res = await $api.get(contractEndpoints.PAYMENT_CURRENT_NUMBER);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss home page top cards
export function useBalanceCardsBoss(date: string) {
  return useQuery<BossHeader>(
    [queryNames.BOSS_HEADER_CARDS, date],
    async () => {
      const res = await Promise.all([
        $api.get(`${mobileEndpoints.INDEBTEDNESS_MONTH}?${date}`),
        $api.get(`${mobileEndpoints.INDEBTEDNESS_ALL}?${date}`),
        $api.get(mobileEndpoints.CLIENT_BALANCE)
      ]);

      return {
        balance: res[2].data.data,
        all: res[1].data.data,
        month: res[0].data.data
      };
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss home page flats count
export function useFlatsCountBoss(date: string) {
  return useQuery<BossHomeFlatsCount>(
    [queryNames.BOSS_FLATS_COUNT, date],
    async () => {
      const res = await Promise.all([
        $api.get(`${mobileEndpoints.FLAT_SOLD}?${date}`),
        $api.get(`${mobileEndpoints.CLIENT_BOOKING}?${date}`),
        $api.get(`${mobileEndpoints.FLAT_BLANK}?${date}`)
      ]);

      return {
        empty: res[2].data.data,
        booked: res[1].data.data,
        sold: res[0].data.data
      };
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useFlatsCountBossV2(date: string) {
  return useQuery<BossHomeFlatsCount>(
    [queryNames.BOSS_FLATS_COUNT, date],
    async () => {
      const res = await Promise.all([$api.get(`/v2/mobile/flat/sold?${date}`), $api.get(`/v2/mobile/client/booking?${date}`), $api.get(`/v2/mobile/flat/blank?${date}`)]);

      return {
        empty: res[2].data.data,
        booked: res[1].data.data,
        sold: res[0].data.data
      };
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss home payments
export function useBossHomePayments(date: string) {
  return useQuery<BossHomePayments>(
    [queryNames.BOSS_HOME_PAYMENTS, date],
    async () => {
      const res = await $api.get(`${mobileEndpoints.PAYMENT}?${date}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss prepayment houses
export function useBossPrepaymentHouses(startDate: string, finishDate: string) {
  let url = mobileVersionTwoEndpoints.CONTRACT_PREPAYMENT_HOUSES;

  if (startDate && finishDate) {
    url += `?startDate=${startDate}&finishDate=${finishDate}`;
  }
  return useQuery<ListPrepaymentHouses>(
    [queryNames.BOSS_COMPLEX_PREPAYMENT_HOUSES, startDate, finishDate],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss prepayment blocks of house
export function useBossPrepaymentHouseBlocks(id: number, startDate: string, finishDate: string) {
  let url = `${mobileVersionTwoEndpoints.CONTRACT_PREPAYMENT_HOUSES}/${id}`;

  if (startDate && finishDate) {
    url += `?startDate=${startDate}&finishDate=${finishDate}`;
  }

  return useQuery<PrepaymentForPieChart[]>(
    [queryNames.BOSS_COMPLEX_PREPAYMENT_HOUSES, id, startDate, finishDate],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss complex total capital
export function useBossComplexTotalCapital() {
  return useQuery<ComplexTotalCapital>(
    [queryNames.BOSS_COMPLEX_TOTAL_CAPITAL],
    async () => {
      const res = await $api.get(mobileVersionTwoEndpoints.HOUSE_TOTAL_CAPITAL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss complex total capital
export function useBossComplexCapital(startDate: string, finishDate: string) {
  let url = mobileVersionTwoEndpoints.HOUSE_CAPITAL;

  if (startDate && finishDate) {
    url += `?startDate=${startDate}&finishDate=${finishDate}`;
  }

  return useQuery<ComplexCapital[]>(
    [queryNames.BOSS_COMPLEX_CAPITAL, startDate, finishDate],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss complex total capital
export function useBossComplexCapitalBlock(id: number, startDate: string, finishDate: string) {
  let url = `${mobileVersionTwoEndpoints.HOUSE_CAPITAL_SPLIT}/${id}`;

  if (startDate && finishDate) {
    url += `?startDate=${startDate}&finishDate=${finishDate}`;
  }

  return useQuery<ComplexTotalCapital>(
    [queryNames.BOSS_COMPLEX_TOTAL_CAPITAL, id, startDate, finishDate],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss complex sale flats info
export function useBossComplexSaleFlats(startDate: string, finishDate: string) {
  let url = mobileVersionThreeEndpoints.CONTRACT_HOUSE;

  if (startDate && finishDate) {
    url += `?startDate=${startDate}&finishDate=${finishDate}`;
  }
  return useQuery<ListInfoFlats>(
    [queryNames.BOSS_COMPLEX_SALE_FLATS, startDate, finishDate],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss complex sale flats on house info
export function useBossComplexSaleFlatsOneHouse(id: number, startDate: string, finishDate: string) {
  let url = `${mobileVersionThreeEndpoints.CONTRACT_HOUSE}/${id}`;

  if (startDate && finishDate) {
    url += `?startDate=${startDate}&finishDate=${finishDate}`;
  }

  return useQuery<FlatsInfoOneHouse[]>(
    [queryNames.BOSS_COMPLEX_SALE_FLATS, id, startDate, finishDate],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get boss payments
export function useBossPayments(url: string) {
  if (!url) {
    const startDate = `01.${dayjs(new Date()).format('MM.YYYY')}`;
    const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

    url = `?type=${BossPaymentsType.DAY}&start=${startDate}&finish=${finishDate}`;
  }
  return useQuery<BossPayments[]>(
    [queryNames.BOSS_PAYMENTS, url],
    async () => {
      const res = await $api.get(mobileEndpoints.PAYMENTS + url);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// boss payments client
export function useBossPaymentsClient(page: number, date: string, type: BossPaymentsType, search: string, base: string, debit?: string) {
  let url = `${mobileVersionTwoEndpoints.PAYMENT_CLIENTS}?page=${page}&size=6&type=${type}&date=${date}&base=${base}&debit=${debit}`;

  if (search) {
    url += `&search=${search}`;
  }

  return useQuery<Pagination<BossPaymentsClient>>(
    [queryNames.BOSS_PAYMENT_CLIENTS, page, date, type, search, base],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!date,
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

// mobile debt
export function useMobileDebt(
  order: 'AMOUNT' | 'DATE',
  search: string,
  currencyId: number,
  page: number = 1,
  size: number = 10,
  houses: number[] | null,
  buildings: number[] | null
) {
  return useQuery<Pagination<MobileDebt>>(
    [queryNames.MOBILE_DEBT, order, search, page, size, currencyId, houses, buildings],
    async () => {
      const res = await $api.post(mobileEndpoints.DEBT, {
        page,
        size,
        search,
        orders: {
          [order]: order === 'AMOUNT' ? 'DESC' : 'ASC'
        },
        currencies: [currencyId],
        houses,
        buildings
      });

      return res.data.data;
    },
    {
      enabled: currencyId !== 0,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile debt graphic
export function useMobileDebtGraphic(date: string) {
  return useQuery<
    {
      debt: number;
      date: string;
    }[]
  >(
    [queryNames.MOBILE_DEBT_GRAPHIC, date],
    async () => {
      const res = await $api.get(`${mobileEndpoints.GRAPHIC}?${date}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile houses list
export function useMobileHouse() {
  return useQuery<{ id: number; name: string }[]>(
    [queryNames.MOBILE_HOUSE],
    async () => {
      const res = await $api.get(mobileEndpoints.HOUSE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile houses contracts
export function useMobileHouseContract(houses: number[]) {
  return useQuery<any>(
    [queryNames.MOBILE_HOUSE_CONTRACTS, houses],
    async () => {
      const res = await $api.get(mobileEndpoints.CONTRACT_DELAY + (houses.filter(i => i !== 0).length === 0 ? '' : `?houses=${houses.filter(i => i !== 0).join(',')}`));

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile contract client
export function useMobileContractClient(page: string) {
  return useQuery<Pagination<{ count: number; phone: string }>>(
    [queryNames.MOBILE_CLIENT_CONTRACTS, page],
    async () => {
      const res = await $api.get(`${mobileEndpoints.CONTRACT_CLIENT}?page=${page}&size=20`);

      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile contract client phone info
export function useMobileContractClientPhone(phone: string, size: number) {
  // return useQuery<MobileContractClientPhone[]>(
  //   [queryNames.MOBILE_CLIENT_CONTRACTS_PHONE, phone, size],
  //   async () => {
  //     const res = await $api.get(`${mobileEndpoints.CONTRACT_CLIENT}/${phone}?page=1&size=${size}`);

  //     return res.data.data;
  //   },
  //   {
  //     enabled: !!phone,
  //     refetchOnWindowFocus: false,
  //     onError: baseErrorHandler,
  //     retry: false
  //   }
  // );
  return useInfiniteQuery<Pagination<ContractDetailProps>>(
    [queryNames.MOBILE_CLIENT_CONTRACTS_PHONE, phone, size],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${mobileEndpoints.CONTRACT_CLIENT}/${phone}?page=${pageParam}&size=10`);

      return res.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

// mobile houses contracts payment delay
export function useMobilePaymentDelay(houses: string[]) {
  return useQuery<{ count: number; interval: number }[]>(
    [queryNames.MOBILE_CLIENT_PAYMENT_DELAY, houses],
    async () => {
      const res = await $api.get(`${mobileEndpoints.CONTRACT_PAYMENT_DELAY}?houses=${houses.filter(i => i !== '').join(',')}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile contract payment delay
export function useMobileContractPaymentDelay(interval: string, page: number = 1, houses: string[]) {
  return useQuery<Pagination<PaymentDelayProps>>(
    [queryNames.MOBILE_CLIENT_CONTRACTS, interval, page, houses],
    async () => {
      const res = await $api.get(`${mobileEndpoints.CONTRACT_PAYMENT_DELAY}/${interval}?page=${page}&houses=${houses.filter(i => i !== '').join(',')}`);

      return res.data;
    },
    {
      enabled: !!interval && interval !== String(-1),
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// mobile houses contracts delay {delay}
export function useMobileHouseContractDelay(delay: number, count: number, houses: number[]) {
  return useInfiniteQuery<Pagination<ContractDetailProps>>(
    [queryNames.MOBILE_HOUSE_CONTRACTS, delay, count, houses],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(
        `${mobileEndpoints.CONTRACT_DELAY}/${delay}?size=${10}&page=${pageParam}${houses.filter(i => i !== 0).length === 0 ? '' : `&houses=${houses.filter(i => i !== 0).join(',')}`}`
      );

      return res.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

// Employee task
export function useEmployeeTask() {
  return useQuery<TaskEmployee[]>(
    [queryNames.TASK_EMPLOYEE],
    async () => {
      const res = await $api.get(taskEndpoints.EMPLOYEE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// one task contract information
export function useOneTaskContractInformation(contractId: number) {
  return useQuery<ContractId>(
    [queryNames.TASK_CONTRACT_INFORMATION, contractId],
    async () => {
      const res = await $api.get(`${taskEndpoints.CONTRACT_INFO}?contractId=${contractId}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

// get uncompleted tasks
export function useUncompletedTask(contractId: number) {
  return useQuery<Task[]>(
    [queryNames.UNCOMPLETED_TASK, contractId],
    async () => {
      const res = await $api.get(`${taskEndpoints.CONTRACT}?contractId=${contractId}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get completed tasks
export function useCompletedTask(contractId: number) {
  return useQuery<CompletedTaskResponse[]>(
    [queryNames.COMPLETED_TASK, contractId],
    async () => {
      const res = await $api.get(`${taskEndpoints.CONTRACT_EVENT}?contractId=${contractId}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

// // Filter task
// export function useTaskFilter(
//   page: number = 1,
//   size: number = 10,
//   employee: number[] | null = null,
//   startDate: string | null = null,
//   finishDate: string | null = null,
//   types: string[] | null = null
// ) {
//   return useQuery<Pagination<TaskFilter>>(
//     [
//       queryNames.TASK_FILTER,
//       page,
//       size,
//       employee,
//       startDate,
//       finishDate,
//       types,
//     ],
//     async () => {
//       const res = await $api.post(taskEndpoints.FILTER, {
//         page: page,
//         size: size,
//         employees: employee,
//         startDate: startDate,
//         finishDate: finishDate,
//         types: types,
//       })
//       return res.data
//     },
//     {
//       refetchOnWindowFocus: true,
//       onError: baseErrorHandler,
//       retry: false,
//     }
//   )
// }

export function useTaskCRMFilter(size: number = 10, searchParams: SearchParamsTask, startDate: string | null = null, finishDate: string | null = null, enabled: boolean = true) {
  return useInfiniteQuery<Pagination<TaskFilter>>(
    [queryNames.TASK_FILTER_CRM, size, JSON.stringify(searchParams), startDate, finishDate],
    async ({ pageParam = 1 }) => {
      const res = await $api.post(leadTaskEndpoints.FILTER, {
        ...getTaskBody(searchParams),
        page: pageParam,
        size,
        ...(startDate && { startDate }),
        ...(finishDate && { finishDate })
      });

      return res.data.data?.leadTaskList;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false,
      enabled
    }
  );
}

export function useTaskCalendarFilter(searchParams: SearchParamsTask, startDate: string, finishDate: string) {
  return useQuery<TaskFilter[]>(
    [queryNames.TASK_FILTER_CALENDAR, startDate, finishDate, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(leadTaskEndpoints.FILTER_CALENDAR, {
        ...getTaskBody(searchParams),
        startDate: `${startDate} 00:00`,
        finishDate: `${finishDate} 23:59`
      });

      return res.data.data?.leadTaskList;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// Filter task count
export function useTaskFilterCount(types: string[] | null = null, startDate: string | null = null, finishDate: string | null = null, enabled: boolean) {
  return useQuery<{ count: number }>(
    [queryNames.TASK_FILTER_COUNT, types, startDate, finishDate],
    async () => {
      const res = await $api.get(leadTaskEndpoints.COUNT);

      return {
        count: res?.data?.data
      };
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}

// Pipe all
export function usePipeAll() {
  return useQuery<PipeAll[]>(
    [queryNames.PIPE_ALL],
    async () => {
      const res = await $api.get(crmPipeEndpoints.ALL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}

// crm Fields
export function useCrmFields(selectedPipeId?: number) {
  const dispatch = useAppDispatch();

  return useQuery<CrmField[]>(
    [queryNames.CRM_FIELDS, selectedPipeId],
    async () => {
      const res = await $api.get(crmEndpoints.FIELD);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setCrmFields({ data: data! }));
      },
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCrmEmployee(open?: boolean) {
  return useQuery<CrmEmployee[]>(
    [queryNames.CRM_EMPLOYEE, open],
    async () => {
      const res = await $api.get(crmLeadEndpoints.EMPLOYEE);

      return res.data.data;
    },
    {
      enabled: open,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useContractEmployee() {
  return useQuery<CrmEmployee[]>(
    [queryNames.CONTRACT_EMPLOYEE],
    async () => {
      const res = await $api.get(contractEndpoints.EMPLOYEE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useMyCallsEmployee() {
  return useQuery<CrmEmployee[]>(
    [queryNames.MY_CALLS_EMPLOYEE],
    async () => {
      const res = await $api.get(moiZvonkiEndpoints.EMPLOYEE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadTaskType() {
  return useQuery<LeadTaskType[]>(
    [queryNames.LEAD_TASK_TYPE_ALL],
    async () => {
      const res = await $api.get(leadTaskTypeEndpoints.ALL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useTags(enabled: boolean | undefined = true) {
  return useQuery<TagsResponse>(
    [queryNames.CRM_TAGS],
    async () => {
      const res = await $api.get(tagEndpoints.ALL);

      return res.data.data;
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function usePipeStatusesById(id: number) {
  return useQuery<PipeStatuses[]>(
    [queryNames.PIPE_STATUS_ONE, id],
    async () => {
      const res = await $api.get(`${pipeStatusEndpoints.ALL}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadNotClosed(id: number, type?: string) {
  return useQuery<LeadTaskNotClosed[]>(
    [queryNames.LEAD_NOT_CLOSED, id],
    async () => {
      const res = await $api.get(`${leadTaskEndpoints.NOT_CLOSED}?${type ? 'clientId=' : 'leadId='}${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadNotClosedContract(contractId: number) {
  return useQuery<LeadTaskNotClosed[]>(
    [queryNames.LEAD_NOT_CLOSED, contractId],
    async () => {
      const res = await $api.get(`${leadTaskEndpoints.NOT_CLOSED}?contractId=${contractId}`);

      return res.data.data;
    },
    {
      enabled: !!contractId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCrmFacebookAccount() {
  return useQuery<InstagramAccount>(
    [queryNames.FACEBOOK_ACCOUNT],
    async () => {
      const res = await $api.get(crmEndpoints.FACEBOOK_ACCOUNT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useCrmFacebookPagePipe(messenger: string) {
  const qc = useQueryClient();

  return useQuery<FacebookPagePipe>(
    [queryNames.FACEBOOK_PAGE_PIPE, messenger],
    async () => {
      const res = await $api.get(`${crmEndpoints.FACEBOOK_APP_PIPE}?messenger=${messenger}`);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.FACEBOOK_ACCOUNT]);
      },
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useTelegramAndWeb() {
  return useQuery<TelegramAndWebProps[]>(
    [queryNames.TELEGRAM_AND_WEB],
    async () => {
      const res = await $api.get(telegramAndWebEndpoints.INDEX);
      const arr: TelegramAndWebProps[] = [];
      let marketPlace;
      let other;

      res.data.data.forEach((item: TelegramAndWebProps) => {
        if (item.tawType === TawType.HOUSE) {
          arr.push(item);
        } else if (item.tawType === TawType.MARKET_PLACE) {
          marketPlace = item;
        } else {
          other = item;
        }
      });
      if (marketPlace && other) return [...arr, marketPlace, other];
      return arr;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFacebookPages() {
  return useQuery<FacebookPages[]>(
    [queryNames.FACEBOOK_PAGE_PAGES],
    async () => {
      const res = await $api.get(crmEndpoints.FACEBOOK_PAGES);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFacebookPipeStatus() {
  return useQuery<FacebookPipeStatus[]>(
    [queryNames.FACEBOOK_PIPE_STATUS],
    async () => {
      const res = await $api.get(crmEndpoints.FACEBOOK_LEAD_FORM_PIPE_STATUS);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFacebookLeadForm(pageId: string) {
  return useQuery<FacebookLeadForm[]>(
    [queryNames.FACEBOOK_LEAD_FORM, pageId],
    async () => {
      const res = await $api.get(`${crmEndpoints.FACEBOOK_LEAD_FORM}?pageId=${pageId}`);

      return res.data.data;
    },
    {
      enabled: !!pageId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFacebookLeadFormField() {
  return useQuery<CrmField[]>(
    [queryNames.FACEBOOK_LEAD_FORM_FIELD],
    async () => {
      const res = await $api.get(crmEndpoints.FACEBOOK_LEAD_FORM_FIELD);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFacebookLeadFormQuestion(id: string, pageId: string) {
  return useQuery<FacebookLeadForm>(
    [queryNames.FACEBOOK_LEAD_FORM_ONE, id, pageId],
    async () => {
      const res = await $api.get(`${crmEndpoints.FACEBOOK_LEAD_FORM}/${id}?pageId=${pageId}`);

      return res.data.data;
    },
    {
      enabled: !!pageId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useMyCallsConfig() {
  return useQuery<MyCalls>(
    [queryNames.MY_CALLS_CONFIG],
    async () => {
      const res = await $api.get(moiZvonkiEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useMyMessageTemplate() {
  return useQuery<MyMessageTemplate[]>(
    [queryNames.MY_MESSAGE_TEMPLATE],
    async () => {
      const res = await $api.get(moiSmsTemplateEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useNotification(page: number = 1, size: number = 10, search: string = '') {
  return useQuery<Pagination<DeveloperNotificationType>>(
    [queryNames.NOTIFICATION, page, size, search],
    async () => {
      const res = await $api.post(notificationEndpoints.FILTER, {
        page,
        size,
        search
      });

      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useNotificationId(id: number) {
  return useQuery<DeveloperNotificationType>(
    [queryNames.NOTIFICATION_ID, id],
    async () => {
      const res = await $api.get(`${notificationEndpoints.INDEX}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useNotificationMe(type?: 'ALL' | 'UNREAD' | 'CONTRACT' | 'ACTIVE_SESSION_SECURITY') {
  const qc = useQueryClient();

  return useInfiniteQuery<Pagination<NotificationMe>>(
    [queryNames.NOTIFICATION_ME, type],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${notificationEndpoints.ME}?page=${pageParam}&size=13&type=${type}`);

      return res.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      onError: baseErrorHandler,
      onSuccess: () => {
        qc.invalidateQueries([queryNames.NOTIFICATION_ME_COUNT]);
      }
    }
  );
}

export function useNotificationMeCount() {
  return useQuery<number>(
    [queryNames.NOTIFICATION_ME_COUNT],
    async () => {
      const res = await $api.get(notificationEndpoints.ME_COUNT);

      return res?.data?.data?.count ?? 0;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useTriggersOneTrigger(id: number | undefined, type: TYPE_TRIGGER) {
  const dispatch = useAppDispatch();

  return useQuery<TriggerBody>(
    [queryNames.ONE_TRIGGER, id],
    async () => {
      if (type === TYPE_TRIGGER.TASK_TRIGGER) {
        const res = await $api.get(`${crmEndpoints.TRIGGERS_TASK_TRIGGER}/${id}`);

        return res.data.data;
      }
      if (type === TYPE_TRIGGER.MANAGER_TRIGGER) {
        const res = await $api.get(`${crmEndpoints.TRIGGERS_MANAGER_TRIGGER}/${id}`);

        return res.data.data;
      }
      if (type === TYPE_TRIGGER.CALL_BROJECT_TRIGGER) {
        const res = await $api.get(`${crmEndpoints.CALL_BROJECT_TRIGGER}/${id}`);

        return res.data.data;
      }
      
      const res = await $api.get(`${crmEndpoints.TRIGGERS_PIPE_STATUS_TRIGGERS}/${id}`);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setTrigger(data));
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: baseErrorHandler
    }
  );
}

export function useOnlinePbx(enabled: boolean = true) {
  const { user } = useAppSelector(state => state.userReducer);
  const permission = user?.role?.permissions.includes(Permissions.PERMISSION_PAGE_CRM) && user?.role?.permissions.includes(Permissions.PERMISSION_SETTINGS_CRM);

  return useQuery<OnlinePbx>(
    [queryNames.ONLINE_PBX],
    async () => {
      const res = await $api.get(onlinePbxEndpoints.INDEX);

      return res.data.data;
    },
    {
      enabled: enabled && permission,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useOnlinePbxEmployee(id: number) {
  return useQuery<OnlinePbxUser[]>(
    [queryNames.ONLINE_PBX_EMPLOYEE, id],
    async () => {
      const res = await $api.get(`${onlinePbxEndpoints.EMPLOYEE}/${id}`);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useOnlinePbxPipeSettings(id: number) {
  return useQuery<OnlinePbxPipes[]>(
    [queryNames.ONLINE_PBX_PIPE_SETTINGS, id],
    async () => {
      const res = await $api.get(`${onlinePbxEndpoints.PIPE_SETTING}/${id}`);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useOnlinePbxContacts(id: number) {
  return useQuery<OnlinePbxContact[]>(
    [queryNames.ONLINE_PBX_PIPE_CONTACTS, id],
    async () => {
      const res = await $api.get(`${onlinePbxEndpoints.CONTACT}/${id}`);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useOnlinePbxListCall(props: { enabled: boolean | undefined; employeeIds?: number[] | null; callHistoryFilterType?: string; endTime?: number; startTime?: number }) {
  const { enabled = true, callHistoryFilterType, employeeIds } = props;
  const dispatch = useAppDispatch();
  const getUser = useUserGetOnlinePbx();

  const { searchParams } = useQueryParam<any, any>();

  return useInfiniteQuery<OnlinePbxHistoryCallResponse>(
    [queryNames.ONLINE_PBX_HISTORY_CALL, props, searchParams],
    async ({ pageParam = 1 }) => {
      const res = await $api.post(callHistoryEndpoints.INDEX, {
        endTime: props?.endTime && props?.startTime ? Number(props?.endTime) : undefined,
        startTime: props?.endTime && props?.startTime ? Number(props?.startTime) : undefined,
        page: pageParam,
        size: 20,
        employeeIds,
        callHistoryFilterType
      });

      return res.data.data;
    },
    {
      enabled,
      retry: false,
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.callHistoryResponses?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      onSuccess(res) {
        const data = res?.pages[0];

        if (data?.onlinePbxEmployeeDto) {
          dispatch(
            setWebRTC({
              domain: data?.onlinePbxEmployeeDto?.onlinePbxDto?.domain
            })
          );
          dispatch(setWebRTCDisable(data?.onlinePbxEmployeeDto?.allowWebCall));
          getUser.mutate({
            domain: data?.onlinePbxEmployeeDto?.onlinePbxDto?.domain,
            key: data?.onlinePbxEmployeeDto?.onlinePbxDto?.key,
            keyId: data?.onlinePbxEmployeeDto?.onlinePbxDto?.keyId,
            num: data?.onlinePbxEmployeeDto?.num
          });
        }
      },
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMarketplace() {
  return useQuery<Marketplace>(
    [queryNames.MARKETPLACE],
    async () => {
      const res = await $api.get(marketplaceEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useMarketplaceHouses() {
  return useQuery<MarketplaceHouses[]>(
    [queryNames.MARKETPLACE_HOUSES],
    async () => {
      const res = await $api.get(marketplaceEndpoints.HOUSES);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useFlatFieldGet() {
  return useQuery<FlatField[]>(
    [queryNames.FLAT_FIELD],
    async () => {
      const res = await $api.get(flatEndpoints.FIELD);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function usePaymentConfig() {
  return useQuery<PaymentConfig>(
    [queryNames.PAYMENT_CONFIG],
    async () => {
      const res = await $api.get(paymentEndpoints.TYPE_CONFIG);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function usePlanFactPipe(startDate: string, finishDate: string) {
  const dispatch = useAppDispatch();

  return useQuery<PlanFactPipe>(
    [queryNames.PLAN_FACT_PIPE, startDate, finishDate],
    async () => {
      const res = await $api.get(`${statisticsEndpoints.PLAN_FACT_PIPE}?startDate=${startDate}&finishDate=${finishDate}`);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setPlanFactId(data.id));
      },
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function usePlanFactPipePipes() {
  return useQuery<PipeAll[]>(
    [queryNames.PLAN_FACT_PIPE_PIPES],
    async () => {
      const res = await $api.get(statisticsEndpoints.PLAN_FACT_PIPE_PIPE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function usePlanFactCompanyField(startDate: string, finishDate: string, id: number, pipeId?: number | null) {
  return useQuery<PlanFactCompanyField | null>(
    [queryNames.CRM_STATISTICS_COMPANY_FIELD, startDate, finishDate, pipeId],
    async () => {
      const res = await $api.get(`${statisticsEndpoints.PLAN_FACT_PIPE_COMPANY_FIELD}/${id}?startDate=${startDate}&finishDate=${finishDate}&pipeId=${pipeId || ''}`);

      return res.data.data;
    },
    {
      enabled: id !== 0,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function usePlanFactOneLeadCost(startDate: string, finishDate: string, lead: boolean, id: number, pipeId: number | undefined) {
  return useQuery<PlanFactLeadCost | null>(
    [queryNames.CRM_STATISTICS_ONE_LEAD_COST, startDate, finishDate, lead, id, pipeId],
    async () => {
      const res = await $api.get(`${statisticsEndpoints.PLAN_FACT_PIPE_ONE_LEAD_COST}/${id}?startDate=${startDate}&finishDate=${finishDate}&lead=${lead}&pipeId=${pipeId || ''}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id !== 0,
      onError: baseErrorHandler
    }
  );
}

export function usePlanFactCompanyLeadField(id: number) {
  return useQuery<CrmField>(
    [queryNames.PLAN_FACT_COST_COMPANY_LEAD_FIELD, id],
    async () => {
      const res = await $api.get(`${statisticsEndpoints.PLAN_FACT_COST_COMPANY_LEAD_FIELD}/${id}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: id !== 0,
      onError: baseErrorHandler
    }
  );
}

export function usePlanFactCost(page: number, size: number) {
  return useQuery<Pagination<PlanCostTableItem>>(
    [queryNames.PLAN_FACT_COST, page],
    async () => {
      const res = await $api.get(`${statisticsEndpoints.PLAN_FACT_COST}?page=${page}&size=${size}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );
}

export function useSearchLeadTask(search: string) {
  return useInfiniteQuery<Pagination<CrmLead>>(
    [queryNames.SEARCH_LEAD_TASK, search],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${crmLeadEndpoints.INDEX}?page=${pageParam}&search=${search}`);

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: !!search,
      retry: false,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetSearchLeadTasks(data: { search: string; notMerge: boolean }) {
  return useInfiniteQuery<Pagination<OneLid>>(
    [queryNames.SEARCH_LEAD_TASK, data],
    async ({ pageParam = 1 }) => {
      if (data?.search?.length >= 3) {
        const res = await $api.post(`${crmLeadEndpoints.SEARCH}?page=${pageParam}`, data);

        return res.data.data;
      }
      return null;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: data?.search.length >= 3,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useContractField() {
  return useQuery<CrmField[]>(
    [queryNames.CONTRACT_FIELD],
    async () => {
      const res = await $api.get(contractEndpoints.FIELD);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useCustomerFlow(selectedPipeId: number | undefined, employeeId: number | undefined, leadStatus: LeadStatus, finishDatePipe: string, startDatePipe: string) {
  return useQuery<PipeStatistic>(
    [queryNames.CUSTOMER_FLOW, selectedPipeId, employeeId, leadStatus, finishDatePipe, startDatePipe],
    async () => {
      const res = await $api.post(statisticsEndpoints.CUSTOMER_FLOW, {
        pipeId: selectedPipeId || null,
        startDate: startDatePipe,
        finishDate: finishDatePipe,
        leadStatus,
        responsibleByIds: employeeId ? [employeeId] : null
      });

      const oldList: PipeStatusList[] = res.data.data.pipeStatusList || [];
      let percentSpace: number = 0;
      const list: PipeStatusList[] = [];
      let days = 0;

      oldList.forEach(item => {
        days += item.day;
      });

      oldList.forEach(item => {
        const percentDay = mathFloor((30 * item.day) / (days === 0 ? 1 : days), 0);
        const percentLead = mathFloor((60 * item.countLeadPercent) / 100, 0);
        const borderPercent = 100 - percentDay - percentLead - percentSpace;

        list.push({
          ...item,
          borderPercent,
          dayPercent: percentDay,
          percentLine: percentLead,
          spacePercent: percentSpace,
          countLeadPercent: mathFloor(item.countLeadPercent, 2)
        });
        percentSpace += percentDay;
      });

      return {
        pipeName: res?.data?.data?.pipeName,
        averageDay: res.data.data.averageDay,
        pipeStatusList: list
      };
    },
    {
      retry: false,
      enabled: selectedPipeId !== 0,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useFilterMessage(
  size?: number,
  search?: string,
  wasRead?: boolean | null,
  pipeStatusIds?: number[] | null,
  responsibleByIds?: number[] | null,
  types?: string[] | null
) {
  return useInfiniteQuery<Pagination<LeadMessageBox>>(
    [queryNames.LEAD_CHATBOX, size, search, wasRead, pipeStatusIds, responsibleByIds, types],
    async ({ pageParam = 1 }) => {
      const res = await $api.post(crmLeadEndpoints.CHAT_BOX, {
        page: pageParam,
        size,
        search,
        wasRead,
        pipeStatusIds,
        responsibleByIds,
        types
      });

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMessageCountNotRead(enabled: boolean) {
  return useQuery<number>(
    [queryNames.COUNT_NOT_READ],
    async () => {
      const res = await $api.get(chatMessageEndpoints.COUNT_NOT_READ);

      return res.data.data;
    },
    {
      enabled,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useFlatsByOrderIdOrFlatIds(orderOrFlatIds: string) {
  const dispatch = useAppDispatch();

  return useQuery<FlatMore[]>(
    [queryNames.FLAT, orderOrFlatIds],
    async () => {
      const arr = orderOrFlatIds.split('=');

      if (arr[0] === BronIdOrFlatIds.BRON_ID) {
        const res = await $api.get(`${flatEndpoints.BY_BOOKING}/${arr[1]}`);

        return res.data.data;
      }
      const idsList = arr[1]?.split(',') || [];

      if (idsList.length === 0) return [];
      let ids = `?flats=${idsList[0]}`;

      if (idsList.length > 1) {
        for (let i = 1; i < idsList.length; i++) {
          ids += `&flats=${idsList[i]}`;
        }
      }
      const res = await $api.get(flatEndpoints.MORE + ids);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setFlats(data));
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useUyqurBuilders() {
  return useQuery<UyqurBuilders[]>(
    [queryNames.UYQUR_BUILDERS],
    async () => {
      const res = await $api.get(uyqurEndpoints.BUILDERS);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useUyqur() {
  return useQuery<Uyqur>(
    [queryNames.UYQUR],
    async () => {
      const res = await $api.get(uyqurEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useCurrencyListGlobal() {
  return useQuery<RemoteCurrency[]>(
    [queryNames.CURRENCY],
    async () => {
      const res = await $api.get(currencyEndpoints.GLOBAL);

      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useCurrencyList() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  return useQuery<CurrencyItem[]>(
    [queryNames.CURRENCY_LIST],
    async () => {
      const res = await $api.get(currencyEndpoints.INDEX);

      return res.data.data;
    },
    {
      onSuccess: data => {
        let basic: CurrencyItem | undefined;

        data?.forEach(item => {
          if (item.basic) {
            basic = item;
          }
        });
        dispatch(setMainCurrency(basic));

        if (pathname.includes('/boss/contract/complex')) {
          dispatch(setChangeDiscountCurrency(basic?.ccy || 'USD'));
          dispatch(setChangeMonthCurrency(basic?.ccy || 'USD'));
        }
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
      // refetchInterval: Infinity,
      // retry: false,
    }
  );
}

export function useBasicCurrency() {
  return useQuery<CurrencyItem>(
    [queryNames.CURRENCY_LIST_BASIC],
    async () => {
      const res = await $api.get(currencyEndpoints.BASIC);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useCompanyInformationForPdf(idsList: string[]) {
  const currencyCompany = useCurrencyCompanyId();
  let ids = `?flatIds=${idsList[0]}`;

  if (idsList.length > 1) {
    for (let i = 1; i < idsList.length; i++) {
      ids += `&flatIds=${idsList[i]}`;
    }
  }
  return useQuery<InformationForPdf[]>(
    [queryNames.COMPANY_INFORMATION_FOR_PDF, idsList],
    async () => {
      const res = await $api.get(showroomEndpoints.FLAT_DATA + ids);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      onSuccess(data) {
        if (data && data[0]) {
          currencyCompany.mutate(data[0]?.companyId);
        }
      },
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useWidget() {
  const user = useAppSelector(state => state.userReducer.user);
  const dispatch = useAppDispatch();

  return useQuery<Widget[]>(
    [queryNames.WIDGET],
    async () => {
      const res = await $api.get(widgetEndpoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      onSuccess(data) {
        if (user) {
          dispatch(
            setUser({
              ...user,
              widgets: data
            })
          );
        }
      },
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useBotChats() {
  return useQuery<BotChat[]>(
    [queryNames.TELEGRAM_BOT_CHATS],
    async () => {
      const res = await $api.get(telegramEndpoints.BOT_CHAT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useEmployeeNotTaskList(fetch: boolean, page?: number, size?: number) {
  const searchObj = queryString.stringify({
    page,
    size
  });

  return useQuery<Pagination<CrmLead>>(
    [queryNames.EMPLOYEE_NOT_TASK_LIST, page, size],
    async () => {
      const res = await $api.get(`${crmLeadEndpoints.NOT_TASK_EMPLOYEE}?${searchObj}`);

      return res.data.data;
    },
    {
      enabled: fetch,
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

// flat_all

export function useFlats(id: number) {
  return useQuery<FlatId[] | undefined>(
    [queryNames.FLAT_ALL, id],
    async () => {
      const res = await $api.get(`${flatEndpoints.INDEX}?buildingID=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// get per-area-avg-price
export function usePerAreaAvgPrice() {
  return useQuery<PerAreaAvgPrice[]>(
    [queryNames.PER_AREA_AVG_PRICE],
    async () => {
      const res = await $api.get(mobileVersionThreeEndpoints.PER_AREA_AVG_PRICE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useCompanyExpireDate() {
  const { lifetime } = useAppSelector(state => state.userReducer);
  const dispatch = useAppDispatch();

  return useQuery<{ expiryDate: number }>(
    [queryNames.EXPIRE_DATE],
    async () => {
      const res = await $api.get(companyEndpoints.EXPIRE_DATE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: true,
      onError: baseErrorHandler,
      onSuccess: data => {
        if (lifetime < dayjs().unix()) {
          if (data?.expiryDate <= 3) {
            setTimeout(() => {
              dispatch(setLifeTime(0));
            }, 2000);
          } else {
            setTimeout(() => {
              dispatch(setLifeTime(dayjs().add(1, 'day').unix()));
            }, 2000);
          }
        }
      },
      retry: false
    }
  );
}

export function useCompanyTemplatesCompact(type: FileTemplateType) {
  return useQuery<FileTemplate[]>(
    [queryNames.DOCUMENT_TEMPLATE_COMPACT, type],
    async () => {
      const res = await $api.get(`${companyConfigEndpoints.DOCUMENT_TEMPLATE_COMPACT}/${type}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useActiveSessions() {
  const { searchParamsString } = useQueryParam();

  return useQuery<ActiveSessions>(
    [queryNames.ACTIVE_SESSION],
    async () => {
      const res = await $api.get(activeSessionEndpoints.INDEX + searchParamsString, { withCredentials: true });

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useReportConfig() {
  return useQuery<Reports[]>(
    [queryNames.REPORT_CONFIG],
    async () => {
      const res = await $api.get(reportConfigEndpoints.INDEX);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMonetaryConfig() {
  return useQuery<MonetaryConfig>(
    [queryNames.MONETARY_CONFIG],
    async () => {
      const res = await $api.get(monetaryConfig.CONFIG);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMonetaryPaymentFilter(searchParams: any) {
  const searchParamsObj = { ...searchParams };

  searchParamsObj.page = Number(searchParams.page) || 1;
  searchParamsObj.size = Number(searchParams.size) || 10;
  searchParamsObj.accountNumbers = Array.isArray(searchParams.accountNumbers) ? searchParams?.accountNumbers : [searchParams?.accountNumbers];
  return useQuery<Pagination<MonetaryPaymentFilter>>(
    [queryNames.MONETARY_PAYMENT_FILTER, searchParams],
    async () => {
      const res = await $api.post(monetaryEndpoints.PAYMENT_FILTER, searchParamsObj);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMonetaryPaymentContract(search: string) {
  return useQuery<MonetaryPaymentContract[]>(
    [queryNames.MONETARY_PAYMENT_CONTRACT, search],
    async () => {
      const res = await $api.get(`${monetaryEndpoints.PAYMENT_CONTRACT}?search=${search}`);

      return res.data.data;
    },
    {
      enabled: search?.length >= 3,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMonetaryPaymentId(id: number) {
  return useQuery<MonetaryPaymentId>(
    [queryNames.MONETARY_PAYMENT_ID, id],
    async () => {
      const res = await $api.get(`${monetaryEndpoints.PAYMENT}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMonetaryPaymentFilterCount(searchParams: any) {
  const searchParamsObj = { ...searchParams };

  delete searchParamsObj.page;
  delete searchParamsObj.size;
  delete searchParamsObj.accept;
  // console.log('Sometight', searchParamsObj.accountNumbers = Array.isArray(searchParams.accountNumbers) ? searchParams?.accountNumbers : [searchParams?.accountNumbers]);
  // searchParamsObj.accountNumbers
  searchParamsObj.accountNumbers = Array.isArray(searchParams.accountNumbers) ? searchParams?.accountNumbers : [searchParams?.accountNumbers];

  return useQuery<{
    all: 0;
    accepted: 0;
    notAccepted: 0;
  }>(
    [queryNames.MONETARY_PAYMENT_FILTER_COUNT, searchParamsObj],
    async () => {
      const res = await $api.post(monetaryEndpoints.PAYMENT_FILTER_COUNT, searchParamsObj);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useRealtorConfigCheckPermission(companyId: number | null) {
  return useQuery<boolean>(
    [queryNames.REALTOR_CONFIG_CHECH_PERMISSION, companyId],
    async () => {
      const res = await $api.get(realtorCongigEndpoints.CHECH_PERMISSION + companyId);

      return res.data.data;
    },
    {
      enabled: !!companyId,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useRealtorConfig() {
  return useQuery<any>(
    [queryNames.REALTOR_CONFIG],
    async () => {
      const res = await $api.get(realtorCongigEndpoints.INDEX);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useBigDataCloud(latitude: number, longitude: number) {
  return useQuery<BigDataCloud>(
    [queryNames.BIG_DATA_CLOUD, latitude, longitude],
    async () => {
      const res = await $api.get(`${BIG_DATA_CLOUD}?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);

      return res.data;
    },
    {
      enabled: !!(latitude && longitude),
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

// OPEN_CAGA_DATA
export function useOpenCagaData(search: string) {
  return useQuery<OpenCaga>(
    [queryNames.OPEN_CAGA_DATA, search],
    async () => {
      const res = await $api.get(`${OPEN_CAGA_DATA}?key=${OPEN_CAGA_DATA_KEY}&pretty=1&no_annotations=1&language=uz&${search}`);

      return res.data;
    },
    {
      enabled: search.length >= 3,
      refetchOnWindowFocus: false,
      retry: false
    }
  );
}

export function useHouseForShowroom() {
  return useQuery<HouseForShowroom[]>(
    [queryNames.HOUSE_FOR_SHOWROOM],
    async () => {
      const res = await $api.get(houseEndpoints.FOR_SHOWROOM);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGeoCodeYandex(search?: string) {
  return useQuery<GeoCodeYandex>(
    [queryNames.GEO_CODE_YANDEX, search],
    async () => {
      const res = await axios.get(GEO_CODE_YANDEX + search);

      return res.data;
    },
    {
      enabled: !!search,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useMobileSaleStats(data: {
  statType?: BossPaymentsType;
  saleStatType?: SaleStatType;
  start?: string;
  finish?: string;
  houses?: number[] | null;
  buildings?: number[] | null;
}) {
  return useQuery<SaleStats[]>(
    [queryNames.MOBILE_SALE_STATS, data],
    async () => {
      const res = await $api.post(mobileEndpoints.SALE_STATS, data);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useEmployeeChangePasswordDateGet() {
  return useQuery<string>(
    [queryNames.EMPLOYEE_CHANGE_PASSWORD_DATE],
    async () => {
      const res = await $api.get(companyConfigEndpoints.EMPLOYEE_CHANGE_PASSWORD_DATE);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useMobilePaymentsType() {
  return useQuery<string[]>(
    [queryNames.MOBILE_PAYMENT_TYPE],
    async () => {
      const res = await $api.get(mobileEndpoints.PAYMENT_TYPE);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

// get table config
export function useTableConfigCustom<T>(key: TableKeyCustom) {
  const url = tableEndpoints.INDEX + key;

  return useQuery<T>(
    [queryNames.TABLE, key],
    async () => {
      const res = await $api.get(url);

      if (!res.data.data) {
        return null;
      }
      return JSON.parse(res.data.data);
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useSearchLeadExtra(search: string) {
  return useQuery<OneLid[]>(
    [queryNames.SEARCH_LEAD_EXTRA, search],
    async () => {
      const res = await $api.get(`${crmLeadEndpoints.EXTRA}?search=${search}`);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetFlatPriceChangesDate(houseId?: number, buildingId?: number) {
  return useQuery(
    [queryNames.FLAT_PRICE_CHANGES_DATE, houseId, buildingId],
    async () => {
      const res = await $api.get(`${flatEndpoints.LAST_CHANGE_PRICE}?houseId=${houseId}&buildingId=${buildingId}`);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      enabled: false
    }
  );
}

export function useGetMyHomeConfig() {
  return useQuery<MyHomeConfigModel>(
    [queryNames.MY_HOME_CONFIG],
    async () => {
      const res = await $api.get(myHomeConfigEndPoints.INDEX);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetAllTjm() {
  return useQuery<AllTjmModel[]>(
    [queryNames.HOUSE_BUILDING],
    async () => {
      const res = await $api.get(saleEndPoints.HOUSE_BUILDING);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetCustomTemplateDocument() {
  return useQuery<{ name: string; id: number; docTempCompactDTO: { language: FileTemplateLanguage }[] }[]>({
    queryKey: [queryNames.DOCUMENT_TEMPLATE_CUSTOM],
    queryFn: async () => {
      const res = await $api.get(companyConfigEndpoints.DOCUMENT_TEMPLATE_CUSTOM);

      return res.data.data;
    },

    refetchOnWindowFocus: false,
    refetchInterval: Infinity,
    onError: baseErrorHandler,
    retry: false
  });
}
function onSuccess(data: BaseEntity[]) {
  throw new Error('Function not implemented.');
}
