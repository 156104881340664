import React from 'react';
import CustomPlayer from '@components/customPlayer/CustomPlayer';
import PaginationCont from '@components/pagenation/PaginationCont';
import { useQueryParam } from '@hooks/useQueryParams';
import { useGetAllRobotCalls } from '@pages/one-campagin/services/queries';
import { OneCompagingCallModel } from '@pages/one-campagin/utils/models/OneCompagingCallModel';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

export const robotoStatuses = {
  PENDING: {
    label: 'Kutilmoqda',
    color: '#B54708',
    background: '#FFFAEB'
  },
  RINGING: {
    label: 'Bog’lanmoqda',
    background: '#EFF8FF',
    color: '#175CD3'
  },
  MISSED: {
    label: 'Ko‘tarilmagan',
    background: '#FEF3F2',
    color: '#B42318'
  },
  TERMINATED: {
    label: 'O‘chirib qo‘yilgan',
    background: '#FDF2FA',
    color: '#C11574'
  },
  DROPPED: {
    label: 'Xatolik',
    background: '#F5F3FF',
    color: '#875BF7'
  },
  COMPLETED: {
    label: 'Muvaffaqiyatli',
    background: '#ECFDF3',
    color: '#027A48'
  },
  CANCELLED: {
    label: 'Bekor qilingan',
    background: '#FEF3F2',
    color: '#B42318'
  }
};
const CallsTable = () => {
  const { t } = useTranslation();
  const { params, appendMultipleDifferent, searchParams } = useQueryParam<any, any>();
  const { data, isLoading, isFetching } = useGetAllRobotCalls(Number(params?.robotoCallsId), searchParams);

  const columns = [
    {
      id: 0,
      title: '№',
      width: 62,
      render: (_: any, index: any, i: any) => <span>{i + 1}</span>
    },
    {
      id: 1,
      title: t('home.Qongiroq_boshlangan_vaqt'),
      dataIndex: 'startDate',
      width: 205
    },
    {
      id: 2,
      title: t('home.Ismi'),
      dataIndex: 'clientName',
      width: 261,
      render: (record: string) => <p className="text-left">{record?.length > 25 ? `${record?.substring(0, 25)}...` : record}</p>
    },
    {
      id: 3,
      title: t('home.Sip'),
      dataIndex: 'channelName',
      width: 261
    },
    {
      id: 4,
      dataIndex: 'clientPhone',
      title: t('home.Telefon_raqami'),
      width: 261
    },
    {
      id: 5,
      title: t('home.Qongiroq'),
      width: 388,
      // eslint-disable-next-line consistent-return
      render: (record: OneCompagingCallModel) => {
        if (record?.duration) {
          return <CustomPlayer isMini={true} url={record?.audioPath!} name={record?.audioPath!} type="wav" calls={true} callTime={record?.duration!} />;
        }
      }
    },
    {
      id: 6,
      dataIndex: 'status',
      title: 'Holati',
      width: 215,
      render: (record: string) => (
        <div
          style={{ backgroundColor: robotoStatuses[record as keyof typeof robotoStatuses]?.background, color: robotoStatuses[record as keyof typeof robotoStatuses]?.color }}
          className="flex h-6 w-[167px] items-center justify-center rounded-full font-medium"
        >
          {t(`home.${robotoStatuses[record as keyof typeof robotoStatuses]?.label}`)}
        </div>
      )
    }
  ];

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, 8]);
  };

  return (
    <div>
      <Table columns={columns} dataSource={data?.data} pagination={false} scroll={{ y: 400 }} loading={isLoading || isFetching} className="custom_table_roboto" />
      <PaginationCont
        currentPage={data?.currentPage ? data.currentPage : 1}
        defaultPageSize={8}
        total={data?.totalElements !== undefined ? data.totalElements : -1}
        totalPages={data?.totalPages || 1}
        changePage={changePage}
      />
    </div>
  );
};

export default CallsTable;
