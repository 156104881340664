import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Broom from '../../assets/icons/Broom';
import Filter from '../../assets/icons/Filter';
import FilterItemClose from '../../assets/icons/FilterItemClose';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import styles from '../filters/mainFilters.module.scss';
import EmployeeFilterDriver from './EmployeeFilterDriver';
import { SearchParamsEmployee } from '../../pages/settingEmployee/SettingEmployee';
import { useEmployeeRole } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { BaseLanguageEntity } from '../../utils/models/BaseLanguageEntity';
import { CloseOutlined } from '@ant-design/icons';

const EmployeeFilter = () => {
  const { searchParams, remove, removeAllSearchParams, appendSingle } = useQueryParam<SearchParamsEmployee, any>();

  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [countFilters, setCountFilters] = useState(0);

  const { data: roleData } = useEmployeeRole();

  useEffect(() => {
    let count = 0;
    if (searchParams.statuses) {
      if (Array.isArray(searchParams.statuses)) {
        count = searchParams.statuses.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.roles) {
      if (Array.isArray(searchParams.roles)) {
        count += searchParams.roles.length;
      } else {
        count += 1;
      }
    }
    setCountFilters(count);
  }, [searchParams]);

  const itemHas = () => {
    const newSearchParams = { ...searchParams };
    delete newSearchParams.page;
    delete newSearchParams.size;
    delete newSearchParams.search;
    return Object.keys(newSearchParams).length > 0;
  };

  const handleFilter = () => {
    setFilterDrawerVisible(true);
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const showEmployeeStatus = () => {
    if (searchParams.statuses) {
      if (!Array.isArray(searchParams.statuses)) {
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{t(`home.${searchParams.statuses as string}`)}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('statuses', searchParams.statuses as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } else {
        return searchParams.statuses.map(statuses => {
          return (
            <div className={`${styles.item} d-flex align-center`} key={statuses}>
              <span className={styles.title}>{t(`home.${statuses}`)}</span>
              <FilterItemClose onClick={() => deleteOneFilter('statuses', statuses)} className={styles.icon} />
            </div>
          );
        });
      }
    }
    return null;
  };

  const showRoles = () => {
    if (roleData) {
      if (searchParams.roles) {
        if (!Array.isArray(searchParams.roles)) {
          const role = roleData?.find(role => String(role.id) === (searchParams.roles as any));
          return (
            <div className={`${styles.item} d-flex align-center`}>
              <span className={styles.title}>{getCurrentLanguageWord(role as BaseLanguageEntity)}</span>
              <FilterItemClose
                onClick={() => {
                  deleteOneFilter('roles', searchParams.roles as any);
                }}
                className={styles.icon}
              />
            </div>
          );
        } else {
          return searchParams.roles.map(roleId => {
            const role = roleData?.find(role => String(role.id) === (roleId as any));
            return (
              <div className={`${styles.item} d-flex align-center`} key={roleId}>
                <span className={styles.title}>{getCurrentLanguageWord(role as BaseLanguageEntity)}</span>
                <FilterItemClose onClick={() => deleteOneFilter('roles', roleId)} className={styles.icon} />
              </div>
            );
          });
        }
      }
    }
    return null;
  };

  const showFilters = () => {
    const status = showEmployeeStatus();
    const roles = showRoles();
    return (
      <>
        {status}
        {roles}
      </>
    );
  };

  const deleteOneFilter = (key: string, value: string) => {
    remove(key, value);
  };

  const clearAllFilter = () => {
    removeAllSearchParams();
  };

  const { t } = useTranslation();

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.cont}>
      <EmployeeFilterDriver visible={filterDrawerVisible} setVisible={setFilterDrawerVisible} />
      <div className={'d-flex align-center'}>
        <div
          onClick={() => handleFilter()}
          className={`${countFilters > 0 ? styles.filter_button + ' ' + styles.active : styles.filter_button} d-flex align-center`}
        >
          <Filter />
          <span className={styles.title}>{t('home.Filtr')}</span>
          {countFilters > 0 && <span className={styles.count_filters}>{countFilters}</span>}
        </div>
        <Input
          value={searchParams.search}
          onChange={e => handleSearch(e.target.value)}
          suffix={showIconInput()}
          placeholder={t('home.Qidirish')}
          className={styles.search}
        />
        {itemHas() ? (
          <div className={`${styles.filtered_items_cont_right} d-flex align-center`}>
            {showFilters()}
            <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
              <Broom />
            </div>
            <div className={styles.line} />
          </div>
        ) : (
          <div className={styles.not_select_title_right}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
        )}
      </div>
      {itemHas() ? (
        <div className={`${styles.filtered_items_cont} d-flex align-center`}>
          {showFilters()}
          <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
            <Broom />
          </div>
          <div className={styles.line} />
        </div>
      ) : (
        <div className={styles.not_select_title}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
      )}
    </div>
  );
};

export default EmployeeFilter;
