import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { EmployeeDiscountModel } from '../utils/models/EmployeeDiscountModel';
import { HousesDiscountModel } from '../utils/models/HousesDiscountModel';
import { MonthDiscountModel } from '../utils/models/MonthDiscountModel';

export function useGetHouseDiscount(startDate: string, finishDate: string, currency: string) {
  return useQuery<HousesDiscountModel>({
    queryKey: [queryKeys.HOUSE, startDate, finishDate, currency],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.HOUSE}?start=${startDate}&finish=${finishDate}&ccy=${currency}`);

      return res.data.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!startDate || !!finishDate
  });
}

export function useGetMonthDiscount(start: string, finish: string, currency: string, buildings: number[], houses: number[]) {
  const formatBuildings = buildings?.map(item => `&buildings=${item}`)?.join('');
  const formatHouses = houses?.map(item => `&houses=${item}`)?.join('');

  return useQuery<MonthDiscountModel[]>({
    queryKey: [queryKeys.MONTH, start, finish, currency, buildings, houses],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.MONTH}?start=${start}&finish=${finish}&ccy=${currency}${formatBuildings}${formatHouses}`);

      return res.data.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!start || !!finish
  });
}
export function useGetEmployeeDiscount(start: string, finish: string, currency: string, buildings: number[], houses: number[]) {
  const formatBuildings = buildings?.map(item => `&buildings=${item}`)?.join('');
  const formatHouses = houses?.map(item => `&houses=${item}`)?.join('');

  return useQuery<EmployeeDiscountModel[]>({
    queryKey: [queryKeys.EMPLOYEE, start, finish, currency, houses, buildings],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.EMPLOYEE}?start=${start}&finish=${finish}&ccy=${currency}${formatBuildings}${formatHouses}`);

      return res.data.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!start || !!finish
  });
}
