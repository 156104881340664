import React, { FC, useMemo, useState } from 'react';
import styles from './leadStatisticTransactions.module.scss';
import { ResponsivePie } from '@nivo/pie';
import LineBarIcon from '../../../../../assets/icons/LineBarIcon';
import { Avatar } from 'antd';
import LineIcon from '../../../../../assets/icons/LineIcon';
import avatarDefaultClient from '../../../../../assets/img/avatarDefaultClient.jpg';
import profile2user from '../../../../../assets/img/profile-2user.png';
import { BASE_URL } from '../../../../../service/RequestService';
import { StatLead } from '../../../utils/models/StatLead';
import { useTranslation } from 'react-i18next';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';

interface IProps {
  data: StatLead | undefined;
}

const LeadStatisticTransactions: FC<IProps> = ({ data }) => {
  const [allVisibleEmployee, setAllVisibleEmployee] = useState(false);
  const [allVisiblePipe, setAllVisiblePipe] = useState(false);
  const { t } = useTranslation();

  const MR_NOBODY = data?.leadStatEmployeeResDto?.find(item => item?.employeeId === 0);

  const pipeStatusCountDtoMin = data?.pipeStatusCountDto
    ?.sort((a, b) => b?.count - a?.count)
    ?.slice(4)
    ?.reduce(
      (prev, current) => ({
        ...prev,
        count: roundFloatNumber(prev.count + current?.count),
        percentage: roundFloatNumber(prev.percentage + current?.percentage),
        balance: roundFloatNumber(prev.balance + current?.balance)
      }),
      { count: 0, percentage: 0, balance: 0 }
    );

  const leadStatEmployeeResDtoMin = data?.leadStatEmployeeResDto
    ?.sort((a, b) => b.countLead - a.countLead)
    ?.filter(item => item?.employeeId)
    ?.slice(3)
    ?.reduce(
      (prev, current) => ({
        ...prev,
        count: roundFloatNumber(prev.count + current?.countLead),
        percentage: roundFloatNumber(prev.percentage + current?.percentageLead),
        balance: roundFloatNumber(prev.balance + current?.balance)
      }),
      { count: 0, percentage: 0, balance: 0 }
    );

  const dataConvert = useMemo(() => {
    return (
      data?.pipeStatusCountDto?.map(item => ({
        id: item?.pipeStatusName,
        label: item?.pipeStatusName,
        value: item?.count,
        ...item
      })) || []
    );
  }, [data]);
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('boss_crm.Bitimlar')}</h1>
      <div className={styles.body}>
        <div className={styles.body_left}>
          <h3>{t('boss_crm.Bosqichlar_boyicha')}</h3>
          {data?.pipeStatusCountDto
            ?.sort((a, b) => b?.count - a?.count)
            ?.slice(0, allVisiblePipe ? undefined : 4)
            ?.map(item => (
              <div key={item?.pipeStatusName} className={styles.body_left_item}>
                <h1>{item?.percentage}%</h1>
                <LineBarIcon color={item?.color} />
                <div>
                  <h3>{item?.pipeStatusName}</h3>
                  <p>
                    {item?.count} {t('boss_crm.bitim')}, {item?.balance?.toLocaleString('ru')} {t('client_balance.som')}
                  </p>
                </div>
              </div>
            ))}
          {!allVisiblePipe && (
            <div className={`${styles.body_left_item} ${styles.body_left_item_all}`} onClick={() => setAllVisiblePipe(true)}>
              <h1>{pipeStatusCountDtoMin?.percentage}%</h1>
              <LineBarIcon color={'#6021A3'} />
              <div>
                <h3>{t('house_config.Barchasini_korish')}</h3>
                <p>
                  {pipeStatusCountDtoMin?.count} {t('boss_crm.bitim')}, {pipeStatusCountDtoMin?.balance?.toLocaleString('ru')}{' '}
                  {t('client_balance.som')}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className={styles.body_center}>
          <ResponsivePie
            data={dataConvert}
            colors={data => data.data.color}
            layers={['arcs', CenteredMetric]}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            innerRadius={0.7}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            enableArcLinkLabels={false}
            enableArcLabels={false}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]]
            }}
            animate={false}
          />
        </div>
        <div className={styles.body_right}>
          <h3>{t('boss_crm.Xodimlar_boyicha')}</h3>
          {data?.leadStatEmployeeResDto
            .filter(item => item?.employeeId)
            .sort((a, b) => b.countLead - a.countLead)
            .slice(0, allVisibleEmployee ? undefined : 4)
            ?.map(item => (
              <div key={item?.employeeId} className={styles.body_right_item}>
                <h1>
                  {item?.percentageLead}% <LineIcon />
                </h1>
                <Avatar size={32} src={item?.absulatePath ? BASE_URL + '/' + item?.absulatePath : avatarDefaultClient} />
                <div>
                  <h3>{item?.fullName}</h3>
                  <p>
                    {item?.countLead} {t('boss_crm.bitim')}, {item?.balance?.toLocaleString('ru')} {t('client_balance.som')}
                  </p>
                </div>
              </div>
            ))}
          {MR_NOBODY && (
            <div className={styles.body_right_item}>
              <h1>
                {MR_NOBODY?.percentageLead}% <LineIcon />
              </h1>
              <Avatar size={32} src={profile2user} />
              <div>
                <h3>{t('boss_crm.Masul_biriktirilmagan')}</h3>
                <p>
                  {MR_NOBODY?.countLead} {t('boss_crm.bitim')}, {MR_NOBODY?.balance?.toLocaleString('ru')} {t('client_balance.som')}
                </p>
              </div>
            </div>
          )}
          {!allVisibleEmployee && (
            <div className={`${styles.body_right_item} ${styles.body_right_item_all}`} onClick={() => setAllVisibleEmployee(true)}>
              <h1>
                {leadStatEmployeeResDtoMin?.percentage}% <LineIcon />
              </h1>
              <div>
                <h3>{t('house_config.Barchasini_korish')}</h3>
                <p>
                  {leadStatEmployeeResDtoMin?.count} {t('boss_crm.bitim')}, {leadStatEmployeeResDtoMin?.balance?.toLocaleString('ru')}{' '}
                  {t('client_balance.som')}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadStatisticTransactions;

const CenteredMetric = ({ dataWithArc, centerX, centerY }: any) => {
  const { t } = useTranslation();
  let total = 0;
  let balance = 0;
  dataWithArc.forEach((datum: any) => {
    balance = balance + datum.data.balance;
    total += datum.data.count;
  });

  return (
    <>
      <text
        x={centerX}
        y={centerY - 42}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '16px',
          fontWeight: 600
        }}
      >
        {balance.toLocaleString('ru')} {t('client_balance.som')}
      </text>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '52px',
          fontWeight: 600
        }}
      >
        {total}
      </text>

      <text
        x={centerX}
        y={centerY + 42}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '16px',
          fontWeight: 600
        }}
      >
        {t('boss_crm.bitim')}
      </text>
    </>
  );
};
