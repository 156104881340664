import React from 'react';

const TableSettings = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 17.1733V14.8267C2.6665 13.44 3.79984 12.2933 5.19984 12.2933C7.61317 12.2933 8.59984 10.5867 7.3865 8.49333C6.69317 7.29333 7.1065 5.73333 8.31984 5.04L10.6265 3.72C11.6798 3.09333 13.0398 3.46666 13.6665 4.52L13.8132 4.77333C15.0132 6.86666 16.9865 6.86666 18.1998 4.77333L18.3465 4.52C18.9732 3.46666 20.3332 3.09333 21.3865 3.72L23.6932 5.04C24.9065 5.73333 25.3198 7.29333 24.6265 8.49333C23.4132 10.5867 24.3998 12.2933 26.8132 12.2933C28.1998 12.2933 29.3465 13.4267 29.3465 14.8267V17.1733C29.3465 18.56 28.2132 19.7067 26.8132 19.7067C24.3998 19.7067 23.4132 21.4133 24.6265 23.5067C25.3198 24.72 24.9065 26.2667 23.6932 26.96L21.3865 28.28C20.3332 28.9067 18.9732 28.5333 18.3465 27.48L18.1998 27.2267C16.9998 25.1333 15.0265 25.1333 13.8132 27.2267L13.6665 27.48C13.0398 28.5333 11.6798 28.9067 10.6265 28.28L8.31984 26.96C7.1065 26.2667 6.69317 24.7067 7.3865 23.5067C8.59984 21.4133 7.61317 19.7067 5.19984 19.7067C3.79984 19.7067 2.6665 18.56 2.6665 17.1733Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TableSettings;
