/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
/* eslint-disable newline-after-var */
import { useState } from 'react';
import CancelBronGarbage from '@icons/CancelBronGarbage';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import i18n from '@service/I18nService';
import { Button, Dropdown, Menu, Modal, Spin, Switch, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import SecuriteControlPModal from './components/SecuriteControlPModal';
import { useCompanyDeleteRole, useUpdateAllPermissions } from './services/mutation';
import { useControlPermissions, useControlRoles } from './services/queries';
import { ControlRoles } from './utils/models/ControlRoles';
import { SecurityControlRole } from './utils/models/SecurityControlRole';

import del from './assets/del.svg';
import dotes from './assets/dotes.svg';
import edit from './assets/edit.svg';

import styles from './securityControlPermissions.module.scss';

const SecurityControlPermissions = () => {
  const { data: dataPermissions, isLoading: dataPermissionsIsloading } = useControlPermissions();
  const { data: dataRoles } = useControlRoles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roles, setRoles] = useState<SecurityControlRole>();

  const [permissionState, setPermissionState] = useState<any[]>([]);
  const [nextLevel, setNextLevel] = useState(false);

  const updateAllPermissions = useUpdateAllPermissions();

  const [dropdownVisible, setDropdownVisible] = useState(-1);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [deleteRoleId, setDeleteRoleId] = useState<number | null>(null);
  const deleteRole = useCompanyDeleteRole();
  const { t } = useTranslation();

  const outputArray: any[] = permissionState?.reduce((acc, obj) => {
    const existingObj = acc?.find((item: any) => item?.roleId === obj?.roleId);
    if (existingObj) {
      Object.assign(existingObj.permissions, {
        [Object.keys(obj)[1]]: obj[Object.keys(obj)[1]]
      });
    } else {
      const newObj = {
        roleId: obj?.roleId,
        permissions: {
          [Object.keys(obj)[1]]: obj[Object.keys(obj)[1]]
        }
      };
      acc.push(newObj);
    }
    return acc;
  }, []);

  const hasChange = (record: any, item: any) => {
    const checkedItem = outputArray?.find((role: any) => role?.roleId === item?.id);
    if (
      checkedItem &&
      // eslint-disable-next-line no-prototype-builtins
      checkedItem?.permissions?.hasOwnProperty(record?.permission) &&
      item?.permissions?.find((p: any) => p?.permission === record?.permission)?.active !== checkedItem?.permissions[record?.permission]
    ) {
      return true;
    }
    return false;
  };

  const transformedArray: any[] = outputArray.reduce((acc, curr) => {
    const permissions = Object.keys(curr.permissions);
    permissions.forEach(permission => {
      if (!acc.includes(permission)) {
        acc.push(permission);
      }
    });
    return acc;
  }, []);

  const newData = dataPermissions?.filter(item => transformedArray?.includes(item?.permission));

  const confirmDeleteRole = (roleId: number) => {
    setDeleteRoleId(roleId);
  };

  const handleDeleteConfirm = () => {
    if (deleteRoleId !== null) {
      deleteRole.mutate(deleteRoleId);
      setDeleteRoleId(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteRoleId(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setRoles({
      id: 0,
      name: ''
    });
    setDropdownVisible(0);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleMenuClick = (key: string, record: any) => {
    if (key === 'edit') {
      openModal();
      setRoles({
        id: record.id,
        name: record.name.default,
        nameUz: record.name.uz,
        nameRu: record.name.ru,
        nameKr: record.name.kr,
        nameEn: record.name.en,
        nameTr: record.name.tr
      });
      setDropdownVisible(0);
    } else if (key === 'delete') {
      confirmDeleteRole(record.id);
      setDropdownVisible(0);
    }
  };

  const handleUpdateAllPermissions = () => {
    updateAllPermissions.mutateAsync({ permissionState: outputArray }).then(() => {
      setNextLevel(false);
    });
  };

  const finalData = nextLevel ? newData : dataPermissions;

  const columns: ColumnsType<ControlRoles> = [
    {
      title: <p className={styles.title}>{t('online_pbx.Ruxsatlar')}</p>,
      dataIndex: 'name',
      key: 'name',
      width: '5%',
      render: (text: any, record) => {
        return (
          <Tooltip title={record.name[i18n.language as keyof typeof record.name]}>
            <span className={styles.text} style={{ whiteSpace: 'nowrap' }}>
              {record.name[i18n.language as keyof typeof record.name]}
            </span>
          </Tooltip>
        );
      },
      fixed: 'left'
    },
    ...(dataRoles && dataRoles?.length > 0
      ? dataRoles?.map(item => {
          return {
            title: () => {
              const name = item.name[i18n.language as keyof typeof item.name]
                ? String(item.name[i18n.language as keyof typeof item.name])
                : item?.name?.default;
              const menu = (
                <Menu key={item?.id} onClick={({ key }) => handleMenuClick(key, item)}>
                  <Menu.Item key="edit">
                    <div className={styles.drop_item}>
                      <img src={edit} alt="edit" />
                      <p>{t('online_pbx.Ruxsat_tahrirlash')}</p>
                    </div>
                  </Menu.Item>
                  <Menu.Item key="delete">
                    <div className={styles.drop_item}>
                      {deleteRole.isLoading && dropdownVisible === item.id ? (
                        <>
                          <Spin size="small" className={styles.spinner} />
                        </>
                      ) : (
                        <>
                          <img src={del} alt="delete" />
                        </>
                      )}
                      <p>{t('online_pbx.Ruxsat_ochirish')}</p>
                    </div>
                  </Menu.Item>
                </Menu>
              );

              return (
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  visible={dropdownVisible === item.id}
                  onVisibleChange={visible => setDropdownVisible(visible ? item.id : -1)}
                >
                  <Tooltip title={name}>
                    <div className={styles.btn_wrp}>
                      <button className={styles.button}>
                        <p className={styles.button_text}>{name?.length > 10 ? `${name.slice(0, 10)}...` : name}</p>
                        <img src={dotes} alt="dotes" />
                      </button>
                    </div>
                  </Tooltip>
                </Dropdown>
              );
            },
            dataIndex: 'admin',
            key: 'admin',
            width: '6%',
            render: (text: any, record: ControlRoles) => {
              return (
                <div className={styles.sw}>
                  <Switch
                    defaultChecked={item.permissions?.find(per => per?.permission === record?.permission)?.active || false}
                    onChange={checked => {
                      const sendData = {
                        roleId: item?.id,
                        [record?.permission]: checked
                      };
                      setPermissionState(props => [...props, sendData]);
                    }}
                    style={{
                      border: hasChange(record, item) ? '5px solid #FFC224' : '',
                      height: hasChange(record, item) ? 32 : '',
                      width: hasChange(record, item) ? 55 : ''
                    }}
                  />
                </div>
              );
            }
          };
        })
      : [])
  ];

  return (
    <div className={styles.containerPermissions}>
      <div className={styles.containerPermissions_header}>
        <Button
          onClick={() => {
            setNextLevel(false);
          }}
          style={{ opacity: nextLevel ? 1 : 0 }}
        >
          <ArrowLeftIcon />
        </Button>
        <div className={styles.containerPermissions_header_right}>
          <Button
            onClick={() => {
              setNextLevel(true);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              nextLevel && handleUpdateAllPermissions();
            }}
            disabled={outputArray?.length === 0}
            loading={updateAllPermissions?.isLoading}
          >
            {nextLevel ? t('integration.Saqlash') : t('changes.contract.Keyingi_bosqich')}
          </Button>
          <Button onClick={openModal}>
            <PlusCircleIcon />
            <span>{t('online_pbx.Ruxsat_qoshish')}</span>
          </Button>
        </div>
      </div>
      <div
        className={styles.scp}
        style={{ width: `calc(100vw - ${offsetLeft + 24}px)` }}
        ref={ref => {
          setOffsetLeft(ref?.offsetLeft || 0);
        }}
      >
        <Spin spinning={dataPermissionsIsloading}>
          <Table dataSource={finalData || []} columns={columns} pagination={false} className={styles.table} size="small" rowKey={'name'} />
        </Spin>
        <SecuriteControlPModal isModalOpen={isModalOpen} openModal={openModal} closeModal={closeModal} role={roles} />
        <Modal
          title={
            <div className={`${styles.icon_cont} d-flex justify-center`}>
              <span className={`${styles.icon} d-flex align-center justify-center`}>
                <CancelBronGarbage />
              </span>
            </div>
          }
          footer={
            <div className={styles.btns}>
              <Button type={'primary'} onClick={handleDeleteCancel} className={styles.btn_cancel}>
                {t(`changes.monthlyPayments.Yopish`)}
              </Button>
              <Button onClick={handleDeleteConfirm} danger loading={deleteRole.isLoading} type={'primary'} className={styles.btn_confirm}>
                {t(`changes.monthlyPayments.O'chirish`)}
              </Button>
            </div>
          }
          visible={deleteRoleId !== null}
          onCancel={handleDeleteCancel}
          className={styles.container}
        >
          <p className={styles.title}>{t('discount_setting.Ochirmoqchimisiz')}?</p>
        </Modal>
      </div>
    </div>
  );
};

export default SecurityControlPermissions;
