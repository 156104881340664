import React, { FC, useEffect } from 'react';
import { Button, Modal } from 'antd';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import styles from './deleteVoronka.module.scss';
import { PipeAll } from '../../../../../utils/models/PipeAll';
import { useTranslation } from 'react-i18next';
import { useDeletePipe } from '../../../services/mutation';

interface IProps {
  visible: boolean;
  id: number;
  setdeleteVoronkaVisible: React.Dispatch<
    React.SetStateAction<{
      visble: boolean;
      id: number;
    }>
  >;
  setVoronkaList: React.Dispatch<React.SetStateAction<PipeAll[] | undefined>>;
  voronkaList: PipeAll[] | undefined;
}

const DeleteVoronka: FC<IProps> = ({ visible, setdeleteVoronkaVisible, id, setVoronkaList, voronkaList }) => {
  const deleteVoronkaMutate = useDeletePipe();

  const { t } = useTranslation();

  const handleCancel = () => {
    setdeleteVoronkaVisible({
      visble: false,
      id: 0
    });
  };

  const handleDelete = () => {
    deleteVoronkaMutate.mutate(id);
  };

  useEffect(() => {
    setVoronkaList(voronkaList?.filter(e => e.id !== id));
    handleCancel();
    //eslint-disable-next-line
  }, [deleteVoronkaMutate.isSuccess]);

  return (
    <Modal title={false} visible={visible} footer={false} closable={false} onCancel={handleCancel} wrapClassName={styles.deleteVoronka}>
      <span>
        <TrashIcon />
      </span>
      <p>
        «{voronkaList?.find(item => item.id === id)?.name}» {t('crm.voronkasini_ochirishingizga_ishonchingiz_komilmi')}
      </p>
      <div>
        <Button onClick={handleCancel}>{t('crm.Yopish')}</Button>
        <Button onClick={handleDelete} loading={deleteVoronkaMutate.isLoading}>
          {t('crm.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteVoronka;
