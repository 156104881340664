import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Select } from 'global';
import { useTranslation } from 'react-i18next';

import { useClientFilterInfinity } from '../hooks';
import { clientModels } from '..';

const { Option } = Select;

interface IProps {
  value?: number[] | null;
  onChange?: (value: number[] | null, option: DefaultOptionType | DefaultOptionType[]) => void;
  enabled?: boolean;
}

const ClientSelect = ({ onChange, value, enabled }: IProps) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const { data, isLoading, fetchNextPage, isFetchingNextPage } = useClientFilterInfinity({ search, enabled });

  const dataConvert =
    data?.pages?.reduce<Record<number, clientModels.IClientFilter>>((prev, current) => {
      current.data?.forEach(item => {
        prev[item.id] = item;
      });

      return prev;
    }, {}) || {};

  return (
    <Select
      mode="multiple"
      value={value}
      searchValue={search}
      showSearch
      optionFilterProp="children"
      onSearch={setSearch}
      onChange={onChange}
      placeholder={t('titles.client')}
      loading={isLoading || isFetchingNextPage}
      onPopupScroll={e => {
        if (e?.currentTarget?.scrollHeight <= e?.currentTarget?.scrollTop + e?.currentTarget?.clientHeight + 10) {
          fetchNextPage();
        }
      }}
      notFoundContent={
        <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
          <Empty />
        </Spin>
      }
    >
      {Object.values(dataConvert)
        ?.sort((a, b) => b.id - a.id)
        ?.map(item => (
          <Option key={item?.id} value={item?.id}>
            {item?.name}
          </Option>
        ))}
    </Select>
  );
};

export default ClientSelect;
