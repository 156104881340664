import { setShaxmatka } from '../../../store/reducers/ShaxmatkaSlice';
import { FlatId } from '../../../utils/models/Flat';

interface IProps {
  dispatch: (e: any) => void;
  setSaveAll: React.Dispatch<React.SetStateAction<boolean>>;
  shaxmatka: any[];
  index: number;
  name: string;
}

export const entranceNameSave = (props: IProps) => {
  const shaxmatkaCopy = props.shaxmatka.map((podezd: any) =>
    podezd.map((floor: any) =>
      floor.map((room: FlatId) =>
        room.entrance === props?.index + 1
          ? {
              ...room,
              entranceName: props?.name
            }
          : {
              ...room
            }
      )
    )
  );

  // @ts-expect-error
  props.dispatch(setShaxmatka(shaxmatkaCopy));
  props.setSaveAll(false);
};
