export const queryKeys = {
  SMART_CATALOG_SHOWROOM: 'SMART_CATALOG_SHOWROOM',
  SMART_CATALOG_SHOWROOM_FILTER: 'SMART_CATALOG_SHOWROOM_FILTER',
  SMART_CATALOG_HOUSE: 'SMART_CATALOG_HOUSE',
  SMART_CATALOG_FLAT_ID: 'SMART_CATALOG_FLAT_ID',
  SMART_CATALOG_TABLE: 'SMART_CATALOG_TABLE',
  SMART_CATALOG_FILTER_PROPERTIES: 'SMART_CATALOG_FILTER_PROPERTIES',
  WEBSITE_BLOCK_STATISTIC: 'WEBSITE_BLOCK_STATISTIC',
  SMART_CATALOG_BUILDING_ID: 'SMART_CATALOG_BUILDING_ID'
};
