import React from 'react';

const JumpNextIcon: React.FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="15" transform="matrix(4.37114e-08 1 1 -4.37114e-08 -0.000244141 0)" fill="#00A389" fill-opacity="0.1" />
      <path
        d="M18.2356 14.9948C18.2346 14.8793 18.1887 14.7687 18.1077 14.6863L13.7441 10.3227C13.6618 10.2432 13.5516 10.1992 13.4372 10.2002C13.3227 10.2012 13.2133 10.2471 13.1324 10.328C13.0515 10.4089 13.0056 10.5184 13.0046 10.6328C13.0036 10.7472 13.0476 10.8574 13.1271 10.9397L17.1822 14.9948L13.1271 19.05C13.0854 19.0902 13.0522 19.1384 13.0293 19.1916C13.0064 19.2448 12.9944 19.3021 12.9939 19.36C12.9934 19.418 13.0044 19.4754 13.0264 19.5291C13.0483 19.5827 13.0807 19.6314 13.1217 19.6724C13.1626 19.7134 13.2114 19.7458 13.265 19.7677C13.3186 19.7896 13.3761 19.8007 13.434 19.8002C13.492 19.7997 13.5492 19.7876 13.6025 19.7648C13.6557 19.7419 13.7038 19.7087 13.7441 19.667L18.1077 15.3033C18.1887 15.221 18.2346 15.1104 18.2356 14.9948Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default JumpNextIcon;
