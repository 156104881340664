import { PaymentField } from '@pages/setting/payment-changes/utils/models/PaymentField';
import { getNameTrl } from '@utils/helper/getNameTrl';
import { PaymentList } from '@utils/models/PaymentList';
import { ColumnsType } from 'antd/lib/table';

export const updatedColumnsFunc = (columns: ColumnsType<PaymentList>, customFields: PaymentField[]): ColumnsType<PaymentList> => {
  let columnsKeys = new Set(columns.map(item => item.key));
  let arr = [...columns];
  for (let i = 0; i < customFields!?.length; i++) {
    if (columns?.some(item => item?.key !== customFields![i]?.id)) {
      arr.push({
        title: getNameTrl(customFields![i]),
        dataIndex: 'field_' + customFields![i].id,
        key: String(customFields![i].id)?.includes('field') ? String(customFields![i].id) : 'field_' + customFields![i].id,
        render: (prev: string) => prev
      });
    }
  }

  return arr;
};
