import React from 'react';

const ClearClientIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9987 15.1654C4.04536 15.1654 0.832031 11.952 0.832031 7.9987C0.832031 4.04536 4.04536 0.832031 7.9987 0.832031C11.952 0.832031 15.1654 4.04536 15.1654 7.9987C15.1654 11.952 11.952 15.1654 7.9987 15.1654ZM7.9987 1.83203C4.5987 1.83203 1.83203 4.5987 1.83203 7.9987C1.83203 11.3987 4.5987 14.1654 7.9987 14.1654C11.3987 14.1654 14.1654 11.3987 14.1654 7.9987C14.1654 4.5987 11.3987 1.83203 7.9987 1.83203Z"
      fill="#292D32"
    />
    <path d="M11.0613 13.5683L10.6903 13.9886L4.22988 2.79871L4.22865 2.79176L5.0959 2.29871L11.5564 13.4886L11.0613 13.5683Z" fill="#292D32" />
  </svg>
);

export default ClearClientIcon;
