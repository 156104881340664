import React from 'react';

const DefaultWidgetIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#3772FF" fill-opacity="0.1" />
      <path
        d="M15 21L33 21M21 15L21 33M19.8 15H28.2C29.8802 15 30.7202 15 31.362 15.327C31.9265 15.6146 32.3854 16.0735 32.673 16.638C33 17.2798 33 18.1198 33 19.8V28.2C33 29.8802 33 30.7202 32.673 31.362C32.3854 31.9265 31.9265 32.3854 31.362 32.673C30.7202 33 29.8802 33 28.2 33H19.8C18.1198 33 17.2798 33 16.638 32.673C16.0735 32.3854 15.6146 31.9265 15.327 31.362C15 30.7202 15 29.8802 15 28.2V19.8C15 18.1198 15 17.2798 15.327 16.638C15.6146 16.0735 16.0735 15.6146 16.638 15.327C17.2798 15 18.1198 15 19.8 15Z"
        stroke="#3772FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DefaultWidgetIcon;
