import { Button, Form, Input, Modal } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { FlatId } from '@utils/models/Flat';
import { createPodval } from './helper/createPodval';

interface CreatePadvalModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setSaveAll: (save: boolean) => void;
}

const CreatePadvalModal: FC<CreatePadvalModalProps> = ({ setSaveAll, visible, setVisible }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const shaxmatka = useAppSelector(state => state.shaxmatkaReducer.data);
  const { t } = useTranslation();

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (values: { flatCount: string; floorCount: string }) => {
    const props = {
      values,
      shaxmatka,
      dispatch,
      setSaveAll,
      handleCancel
    };
    createPodval(props);
  };

  return (
    <Modal
      title={t('shaxmatka.padval_qushish')}
      wrapClassName="entranceDoorModal"
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      width={407}
    >
      <Form layout="horizontal" onFinish={onFinish} form={form}>
        <Form.Item label={t('shaxmatka.qavatlar_soni')} name={'floorCount'} rules={[{ required: true }]}>
          <Input type={'number'} />
        </Form.Item>
        <Form.Item label={t('shaxmatka.qavatdagi_xonadonlar_soni')} name={'flatCount'} rules={[{ required: true }]}>
          <Input type={'number'} />
        </Form.Item>
        <div className="entranceDoorModalFooter">
          <Button onClick={handleCancel}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button type="primary" htmlType="submit">
            {t('shaxmatka.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreatePadvalModal;
