import CrmForce from '@pages/crm-force/CrmForce';
import CrmSettings from '@pages/crm-settings/CrmSettings';

export const crmSettingsRoutes = [
  {
    path: '',
    component: CrmSettings,
    exact: true
  },
  {
    path: '/force',
    component: CrmForce,
    exact: true
  }
];
