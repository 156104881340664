import React, { useEffect } from 'react';
import { NotifConfigModel } from '../../utils/models/NotifConfigModel';
import { useCrmEmployee } from '@queries/queries';
import { usePostNotificationConfig } from '../../services/mutations';
import { Button, Col, Form, Row, Select, Switch } from 'antd';

import styles from '../../companyNotification.module.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const contractTypes = [
  {
    id: '1',
    label: 'Shartnoma_yaratilganda',
    value: 'CREATE_CONTRACT'
  }
];

type Props = {
  item?: NotifConfigModel;
};

const NotifItem: React.FC<Props> = ({ item }) => {
  const [form] = Form.useForm();
  const { data: employees } = useCrmEmployee();
  const createUpdateNotifConfig = usePostNotificationConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (item) {
      form.setFieldsValue(item);
    }
  }, [item]);

  const onFinish = (values: NotifConfigModel) => {
    if (item) {
      createUpdateNotifConfig.mutateAsync({
        ...values,
        notifyType: 'CONTRACT',
        id: item?.id
      });
    } else {
      createUpdateNotifConfig.mutateAsync({
        ...values,
        notifyType: 'CONTRACT'
      });
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row gutter={24} style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={10}>
          <Form.Item label={t('home.Xodim')} name="employees" rules={[{ required: true, message: '' }]}>
            <Select mode="multiple" maxTagCount={'responsive'} placeholder={t('home.Tanlang')}>
              {employees?.map(item => (
                <Option key={item?.id} value={item?.id}>
                  {item?.fullName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label={t('premium_modal.Shartnoma_holati')} name="notifyStatus" rules={[{ required: true, message: '' }]}>
            <Select placeholder={t('home.Tanlang')}>
              {contractTypes?.map(item => (
                <Option key={item?.id} value={item?.value}>
                  {t(`home.${item?.label}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label={t('home_id.Holati')} name="status" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Button htmlType="submit" loading={createUpdateNotifConfig.isLoading} className={styles.saveBtn}>
            {t('one_task.Saqlash')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default NotifItem;
