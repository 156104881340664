export interface PipeAll {
  id: number;
  name: string;
  orders: number;
  pipeStatuses: PipeStatuses[];
}

export interface PipeStatuses {
  color: string;
  id?: number;
  name: string;
  orders: number;
  pipeName?: string;
  pipeId: number;
  type?: keyof typeof PipeStatusesType;
}

export enum PipeStatusesType {
  FIRST = 'FIRST',
  SIMPLE = 'SIMPLE',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}
