import React, { useEffect, useRef } from 'react';
import { LeadEventsAndTasksList } from '@utils/models/LeadEventsAndTaskList';
import { OneLid } from '@utils/models/OneLid';

import PinedNote from './pinedNote/PinedNote';
import TaskChatContainer from './taskChatContainer/TaskChatContainer';
import TaskChatFooter from './taskChatFooter/TaskChatFooter';

import styles from './taskListContainer.module.scss';

type Props = {
  type?: string;
  data: LeadEventsAndTasksList[] | undefined;
  isLoading: boolean;
  leadData?: OneLid;
};

const TaskListContainer: React.FC<Props> = ({ type, data, isLoading, leadData }) => {
  const containerRef = useRef<any>(null);

  useEffect(() => {
    containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
    window.onload = () => {
      containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
    };
  }, []);
  return (
    <div className={styles.container} ref={containerRef} style={{ width: type ? '100%' : '' }}>
      <PinedNote type={type} data={data} isLoading={isLoading} />
      <TaskChatContainer type={type} data={data} isLoading={isLoading} />
      <TaskChatFooter type={type} leadData={leadData!} />
    </div>
  );
};

export default TaskListContainer;
