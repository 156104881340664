import React from 'react';
import { TreeSelect as BaseTreeSelect, TreeSelectProps as BaseTreeSelectProps } from 'antd';

export interface TreeSelectProps extends BaseTreeSelectProps {
  children?: React.ReactNode;
}

const TreeSelect = ({ ...props }: TreeSelectProps) => <BaseTreeSelect {...props} dropdownClassName="customTree" />;

TreeSelect.TreeNode = BaseTreeSelect.TreeNode;

export default TreeSelect;
