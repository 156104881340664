import React from 'react';

const DiscountShapeIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9417 9.0584L16.6751 7.79173C16.4584 7.57507 16.2834 7.15007 16.2834 6.85007V5.05007C16.2834 4.31673 15.6834 3.71673 14.9501 3.71673H13.1584C12.8584 3.71673 12.4334 3.54173 12.2167 3.32507L10.9501 2.0584C10.4334 1.54173 9.5834 1.54173 9.06673 2.0584L7.7834 3.32507C7.57507 3.54173 7.15007 3.71673 6.84173 3.71673H5.05007C4.31673 3.71673 3.71673 4.31673 3.71673 5.05007V6.84173C3.71673 7.14173 3.54173 7.56673 3.32507 7.7834L2.0584 9.05007C1.54173 9.56673 1.54173 10.4167 2.0584 10.9334L3.32507 12.2001C3.54173 12.4167 3.71673 12.8417 3.71673 13.1417V14.9334C3.71673 15.6667 4.31673 16.2667 5.05007 16.2667H6.84173C7.14173 16.2667 7.56673 16.4417 7.7834 16.6584L9.05007 17.9251C9.56673 18.4417 10.4167 18.4417 10.9334 17.9251L12.2001 16.6584C12.4167 16.4417 12.8417 16.2667 13.1417 16.2667H14.9334C15.6667 16.2667 16.2667 15.6667 16.2667 14.9334V13.1417C16.2667 12.8417 16.4417 12.4167 16.6584 12.2001L17.9251 10.9334C18.4667 10.4251 18.4667 9.57507 17.9417 9.0584ZM6.66673 7.50007C6.66673 7.04173 7.04173 6.66673 7.50007 6.66673C7.9584 6.66673 8.3334 7.04173 8.3334 7.50007C8.3334 7.9584 7.96673 8.3334 7.50007 8.3334C7.04173 8.3334 6.66673 7.9584 6.66673 7.50007ZM7.94173 12.9417C7.81673 13.0667 7.6584 13.1251 7.50007 13.1251C7.34173 13.1251 7.1834 13.0667 7.0584 12.9417C6.81673 12.7001 6.81673 12.3001 7.0584 12.0584L12.0584 7.0584C12.3001 6.81673 12.7001 6.81673 12.9417 7.0584C13.1834 7.30007 13.1834 7.70007 12.9417 7.94173L7.94173 12.9417ZM12.5001 13.3334C12.0334 13.3334 11.6584 12.9584 11.6584 12.5001C11.6584 12.0417 12.0334 11.6667 12.4917 11.6667C12.9501 11.6667 13.3251 12.0417 13.3251 12.5001C13.3251 12.9584 12.9584 13.3334 12.5001 13.3334Z"
        fill="#FFB400"
      />
    </svg>
  );
};

export default DiscountShapeIcon;
