export const endPoints = {
  LEAD: '/v1/lead/',
  BOOKING_LEAD_FLAT: '/v1/booking-lead-flat/',
  LEAD_EMPLOYEE: '/v1/lead/employee',
  LEAD_TASK_TYPE_SAVE: '/v1/lead-task-type/save',
  LEAD_TASK_TYPE: '/v1/lead-task-type/',
  LEAD_TASK_CLOSE: '/v1/lead-task/close',
  LEAD_TASK: '/v1/lead-task/',
  LEAD_DATA: '/v1/lead/data',
  LEAD_NOTE: '/v1/lead-note/',
  LEAD_RECOVERING_DELETED: '/v1/lead/recovering-deleted',
  MOI_ZVONKI_SEND_REQUEST_SEND_SMS: '/v1/moi-zvonki-send-request/send-sms',
  LEAD_NOT_TASK_CONF: '/v1/lead-not-task-conf/',
  ONLINE_PBX_CAN_MAKE_CALL: '/v1/online-pbx/can-make-call',
  MOI_ZVONKI_SEND_REQUEST_MAKE_CALL: '/v1/moi-zvonki-send-request/make-call',
  COMPANY_CONFIG_SALE_CRM_TAG: '/v1/company-config/sale-crm-tag',
  TAG: '/v1/tag/',
  CALL_HISTORY: '/v1/call-history/',
  LEAD_DUPLICATE: '/v1/lead/duplicate/',
  IGNORE_LEAD: '/v1/lead/ignore-leads',
  IGNORE_CLIENT: '/v1/client/ignore-leads',
  INVITING_CLIENT: '/v1/lead/inviting-client/',
  ATTACH_CLIENT: '/v1/lead/attach-client',
  CANCEL_CLIENT: '/v1/lead/cancel-client',
  SUGGESTION_LEAD: '/v1/client/suggestion-lead/',
  CLIENT_VIEW: '/v1/client/view/',
  CONTRACT_BOOKING: '/v1/client/contract-booking',
  CLIENT_EVENT: '/v1/client-event',
  ONE_CLIENT: '/v1/client/',
  LEAD_CALL_ANALYSE: '/v1/lead/call-analyse'
};
