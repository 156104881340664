import React, { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { useCrmLeadBy } from '@pages/crm/services/queries';
import { useAttachClient, useCancelClient } from '@pages/oneLid/services/mutation';
import { useGetClientView } from '@pages/oneLid/services/queries';
import { setOpenDublicateModal } from '@reducers/CrmOneLidSlice';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { setClearListView } from '../../../../../../store/reducers/CrmSlice';
import { BaseEntity } from '../../../../../../utils/models/BaseEntity';
import { ContactCrm, FieldValue, OneLid } from '../../../../../../utils/models/OneLid';
import { useLeadGroup } from '../../../../services/mutation';

import DublicateControllField from './DublicateControllField';
import DublicateControllFieldAll from './DublicateControllFieldAll';

import styles from './dublicateControllModal.module.scss';

type Props = {
  clientId?: number;
  type?: string;
};

const DublicateControllModal: React.FC<Props> = ({ clientId, type }) => {
  const { dublicateClientId } = useAppSelector(state => state.crmOneLidField);
  const { params } = useQueryParam<any, any>();
  const { t } = useTranslation();
  const [dublicate, setDublicate] = useState<OneLid | undefined>();
  const [fields, setFields] = useState<FieldValue[]>([]);
  const leadGroup = useLeadGroup();
  const dispatch = useAppDispatch();
  const { openDublicateModal } = useAppSelector(state => state.assigmentSlice);
  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);
  const { data } = useCrmLeadBy(openDublicateModal ? activeIds : []);
  const { data: client } = useGetClientView(Number(dublicateClientId));
  const attachClient = useAttachClient();
  const cancelClient = useCancelClient();

  const handleCancelClient = () => {
    cancelClient
      .mutateAsync({
        clientId: Number(client?.id),
        leadId: Number(client?.lead?.id)
      })
      .then(() =>
        attachClient.mutate({
          clientId: Number(client?.id),
          leadId: Number(params?.id)
        })
      );
    // dispatch(setClearListView());
  };

  const handleCancle = () => {
    dispatch(setOpenDublicateModal(false));
    // dispatch(setClearListView());
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      const contacts: ContactCrm[][] = [];

      data?.forEach(item => {
        contacts.push(item?.contacts);
      });

      const fields: FieldValue[] = [];
      const tags: BaseEntity[] = [];

      data?.forEach(item => {
        item?.tags?.forEach(tag => {
          if (!tags.some(val => val?.id === tag?.id)) {
            tags.push(tag);
          }
        });
        item?.fields?.forEach(field => {
          if (!fields?.some(val => val?.id === field?.id)) {
            fields?.push(field);
          }
        });
      });

      setFields(fields);
      setDublicate({
        ...data[0],
        responsibleById: data?.find(item => item?.responsibleById)?.responsibleById,
        responsibleBy: data?.find(item => item?.responsibleBy)?.responsibleBy || '',
        contacts: contacts?.flat(),
        fields,
        tags
      });
    }
  }, [data]);

  const notKeys = ['id', 'isMoiZvonki', 'responsibleBy'];

  const handleSave = () => {
    leadGroup
      .mutateAsync({
        leadIds: activeIds,
        requestMethodType: 'LEAD_MERGE',
        leadMergeDto: {
          leadId: clientId || dublicate?.id,
          leadName: dublicate?.name,
          balance: dublicate?.balance,
          responsibleById: dublicate?.responsibleById,
          pipeStatusId: dublicate?.pipeStatusResponse?.id,
          contactIdList: dublicate?.contacts?.map(item => item?.id!),
          createdDate: dublicate?.createdDate,
          fields: dublicate?.fields,
          tagIdList: dublicate?.tags?.map(item => item?.id!)
        }
      })
      .then(() => {
        handleCancle();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        type && handleCancelClient();
      });
  };

  const dataKeys = () => {
    const arr = ['name', 'responsibleById', 'createdDate', 'balance', 'pipeStatusResponse', 'fields', 'tags', 'contacts'];

    if (data && data?.length! > 0) {
      Object.keys(data[0])?.forEach(item => {
        if (!arr.includes(item)) {
          arr.push(item);
        }
      });
    }
    return arr;
  };

  return (
    <Modal
      visible={openDublicateModal}
      onCancel={handleCancle}
      title={t('one_lid.Potensial_mijozlarni_birlashtirish')}
      width={1100}
      footer={false}
      wrapClassName={styles.modal}
    >
      <div className={styles.container}>
        <p className={styles.container_title}>{t('one_lid.Quyida_dublikat_yol_yoriqlar_toplami_keltirilgan')}</p>
        <div className={styles.body}>
          <div className={styles.body_content}>
            {data &&
              data?.length > 0 &&
              dataKeys()?.map(
                (key, i) =>
                  !notKeys.includes(key) && (
                    <DublicateControllField
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      {...data[0]!}
                      dublicate={dublicate}
                      setDublicate={setDublicate}
                      data={data}
                      keyObj={key}
                      index={i}
                      fieldsArr={fields}
                    />
                  )
              )}
          </div>
          <DublicateControllFieldAll dublicate={dublicate} fieldsArr={fields} />
        </div>
        <div className={styles.container_footer}>
          <Button onClick={handleCancle}>{t('home.Bekor_qilish')}</Button>
          <Button type="primary" onClick={handleSave} loading={leadGroup.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DublicateControllModal;
