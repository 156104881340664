import React, { useState } from 'react';
import { Button } from 'antd';
import styles from './montylyPayments.module.scss';
import AddItemTable from './AddItemTable';
import ChangeDateModal from './ChangeDateModal';
import ClearTableModal from './ClearTableModal';
import { useTranslation } from 'react-i18next';

const Buttons = () => {
  const [addItemModal, setAddItemModal] = useState(false);
  const [changeDateModal, setChangeDateModal] = useState(false);
  const [clearTableModal, setClearTableModal] = useState(false);

  const handleAddItemModal = (open: boolean) => {
    setAddItemModal(open);
  };

  const handleChangeDateModal = (open: boolean) => {
    setChangeDateModal(open);
  };

  const handleClearTableModal = (open: boolean) => {
    setClearTableModal(open);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.buttons}>
        <Button onClick={() => handleAddItemModal(true)} className={styles.buttons_item} type="primary" ghost>
          {t(`changes.monthlyPayments.To’lov_grafigi_qo’shish`)}
        </Button>
        <Button onClick={() => handleChangeDateModal(true)} className={styles.buttons_item} type="primary" ghost>
          {t(`changes.monthlyPayments.Sanalarni_bir_vaqtda_o’zgartirish`)}
        </Button>
        <Button onClick={() => handleClearTableModal(true)} className={styles.buttons_item} type="primary" ghost>
          {t(`changes.monthlyPayments.Jadvalni_tozalash`)}
        </Button>
      </div>
      <AddItemTable onClose={handleAddItemModal} visible={addItemModal} />
      <ChangeDateModal visible={changeDateModal} onClose={handleChangeDateModal} />
      <ClearTableModal visible={clearTableModal} onCancel={handleClearTableModal} />
    </>
  );
};

export default Buttons;
