import React, { useEffect, useState } from 'react';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { phoneValidator } from '@utils/index';
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { countryList } from 'modules/address/constants';

import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsBuilder } from '../../pages/builderSettings/BuilderSettings';
import { useBuilderEdit, useCreateBuilder } from '../../queries/mutation';
import { useBuilderId, useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';

import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface IProps {
  visible: boolean;
  id: number | null;
  setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: null }>>;
}
interface IForm {
  id: number;
  name: string;
  phone: string;
  account: string;
  mfo: string;
  inn: string;
  oked: string;
  bank: string;
  region: number;
  director: string;
  address: string;
  cityId: string;
  district: number;
  callCenterPhone: string;
  countryCode: string;
}

const CreateBuilderSettings: React.FC<IProps> = ({ visible, setVisible, id }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm<IForm>();
  const [regionId, setRegionId] = useState<number>(0);
  const [countryId, setCountryId] = useState<number>();
  const { searchParams } = useQueryParam<SearchParamsBuilder, any>();

  const builderMutation = useCreateBuilder(searchParams);
  const { data, isLoading } = useBuilderId(id as number);
  const builderEditMutation = useBuilderEdit(searchParams);
  const { data: regions, isLoading: loadingRegions } = useRegions(countryId);
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');
  const countryCode = Form.useWatch('countryCode', form) as string;

  const onFinish = (fields: IForm) => {
    if (id) {
      builderEditMutation
        .mutateAsync({
          id,
          name: fields.name,
          phone: fields?.phone,
          mfo: fields.mfo?.trim(),
          inn: fields.inn?.trim(),
          address: fields.address,
          oked: fields.oked?.trim(),
          bank: fields.bank?.trim(),
          director: fields.director,
          district: fields.district,
          account: fields.account?.trim(),
          callCenterPhone: fields.callCenterPhone ? fields?.callCenterPhone : null,
          countryCode: fields?.countryCode
        })
        .then(() => setVisible({ id: null, visible: false }));
    } else {
      const createBuilder = {
        name: fields.name,
        phone: fields?.phone,
        account: fields.account,
        mfo: fields.mfo?.trim(),
        inn: fields.inn?.trim(),
        address: fields.address,
        oked: fields.oked?.trim(),
        bank: fields.bank?.trim(),
        director: fields.director,
        district: fields.district,
        countryCode: fields?.countryCode,
        callCenterPhone: fields?.callCenterPhone ? fields?.callCenterPhone : null
      };

      builderMutation.mutateAsync(createBuilder as any).then(() => setVisible({ id: null, visible: false }));
    }
  };

  const handleCancel = () => {
    setVisible({ visible: false, id: null });

    form.setFieldsValue({
      name: '',
      phone: '',
      account: '',
      mfo: '',
      inn: '',
      oked: '',
      bank: '',
      director: '',
      region: undefined,
      district: undefined,
      address: '',
      callCenterPhone: ''
    });
  };

  const handleOk = () => {
    setVisible({ visible: false, id: null });
  };

  const changeRegion = (id: number) => {
    form.setFieldsValue({
      district: undefined
    });

    setRegionId(id);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        phone: data?.phone,
        account: data.account,
        mfo: data.mfo,
        inn: data?.inn,
        oked: data.oked,
        region: data.city.id,
        bank: data.bank,
        director: data?.director,
        district: data?.district,
        address: data.address,
        countryCode: data?.countryCode?.toLowerCase(),
        callCenterPhone: data?.callCenterPhone
      });
      setRegionId(data.city.id || -1);

      const findCountry = countryList?.find(({ code }) => code === data?.countryCode);

      setCountryId(findCountry ? findCountry?.index + 1 : undefined);
    }
  }, [data, form]);

  return (
    <Modal
      width={774}
      footer={false}
      centered={true}
      onOk={handleOk}
      visible={visible}
      onCancel={handleCancel}
      className="createBuilderModal"
      title={t("titles.Quruvchi_qo'shish")}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
          <Row>
            <Col className={styles.left} flex="1">
              <Form.Item rules={[{ required: true, message: '' }]} name="name" label={t('home.Korxona_nomi')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }, phoneValidator()]} name="phone" label={t('home.Telefon_raqam')}>
                <InternationalPhoneNumber
                  defaultCountry="uz"
                  onChange={(_, phone) => {
                    form.setFieldsValue({ phone: phone?.inputValue?.replace(/\s+/g, '') });
                  }}
                />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name="mfo" label={t('home.MFO')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name="bank" label={t('home.Bank_nomi')}>
                <Input />
              </Form.Item>
              <Form.Item initialValue="uz" name="countryCode" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
                <Select
                  className="customSelect"
                  onChange={(_: string, option: any) => {
                    setRegionId(0);
                    setCountryId(option?.props);

                    form.setFieldsValue({
                      region: undefined,
                      district: undefined
                    });
                  }}
                  placeholder={t('home.Tanlang')}
                  showSearch
                  optionFilterProp="children"
                  allowClear
                >
                  {countryList?.map(item => (
                    <Select.Option key={item?.code} value={item?.code} props={item?.index + 1}>
                      {item[`country_${i18n.language}` as keyof typeof item]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {countryCode === 'uz' || countryCode === 'tj' ? (
                <Form.Item label={t('home.Viloyat')} name="region" rules={[{ required: true, message: '' }]}>
                  <Select showSearch optionFilterProp="children" className="customSelect" placeholder={t('home.Tanlang')} loading={loadingRegions} onChange={i => changeRegion(i)}>
                    {regions?.map(region => (
                      <Select.Option key={region.id} value={region.id}>
                        {getCurrentLanguageWord(region)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item rules={[phoneValidator()]} name="callCenterPhone" label="Call Center">
                <InternationalPhoneNumber
                  defaultCountry="uz"
                  onChange={(_, phone) => {
                    form.setFieldsValue({ callCenterPhone: phone?.inputValue?.replace(/\s+/g, '') });
                  }}
                />
              </Form.Item>
            </Col>
            <Col className={styles.right} flex="1">
              <Form.Item rules={[{ required: true, message: '' }]} name="director" label={t('home.Korxona_rahbari')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name="inn" label={t('home.INN')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name="oked" label={t('home.OKED')}>
                <Input />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '' }]} name="account" label={t('home.Hisob_raqam')}>
                <Input />
              </Form.Item>
              {countryCode === 'uz' || countryCode === 'tj' ? (
                <Form.Item label={t('home.Shaxar')} name="district" rules={[{ required: true, message: '' }]}>
                  <Select disabled={!regionId} optionFilterProp="children" showSearch className="customSelect" placeholder={t('home.Tanlang')} loading={districtsLoading}>
                    {districts?.map(district => (
                      <Select.Option key={district.id} value={district.id}>
                        {getCurrentLanguageWord(district)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item rules={[{ required: true, message: '' }]} name="address" label={t('home.Manzil')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.modalFooter}>
            <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
            <Button htmlType="submit" type="primary" loading={builderEditMutation.isLoading || builderMutation.isLoading}>
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateBuilderSettings;
