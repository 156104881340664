import dayjs from 'dayjs';
import { DateFormatType } from '../../../../utils/constants/DateFormatType';
import { NextPrev } from '../../../../utils/constants/NextPrev';
import { DATE_FORMAT } from '../../../../utils/constants/format';

export const dateNextPrev = (
  type: NextPrev,
  dateType: DateFormatType,
  date: {
    startDate: string;
    finishDate: string;
  }
) => {
  if (type === 'NEXT') {
    const obj = {
      DAY: {
        startDate: dayjs(date.startDate, DATE_FORMAT).date(1).add(1, 'month').format(DATE_FORMAT),
        finishDate: dayjs(date.startDate, DATE_FORMAT)
          .add(1, 'month')
          .date(dayjs(date.startDate, DATE_FORMAT).add(1, 'month').endOf('month').date())
          .format(DATE_FORMAT)
      },
      MONTH: {
        startDate: dayjs(date.startDate, DATE_FORMAT).date(1).month(1).add(1, 'year').format(DATE_FORMAT),
        finishDate: dayjs(date.startDate, DATE_FORMAT).date(31).month(11).add(1, 'year').format(DATE_FORMAT)
      },
      YEAR: {
        startDate: dayjs(date.finishDate, DATE_FORMAT).date(1).month(1).format(DATE_FORMAT),
        finishDate: dayjs(date.finishDate, DATE_FORMAT).date(31).month(11).add(4, 'year').format(DATE_FORMAT)
      }
    };

    return obj[dateType];
  } else {
    const obj = {
      DAY: {
        startDate: dayjs(date.startDate, DATE_FORMAT).date(1).add(-1, 'month').format(DATE_FORMAT),
        finishDate: dayjs(date.startDate, DATE_FORMAT)
          .add(-1, 'month')
          .date(dayjs(date.startDate, DATE_FORMAT).add(-1, 'month').endOf('month').date())
          .format(DATE_FORMAT)
      },
      MONTH: {
        startDate: dayjs(date.startDate, DATE_FORMAT).date(1).month(1).add(-1, 'year').format(DATE_FORMAT),
        finishDate: dayjs(date.startDate, DATE_FORMAT).date(31).month(11).add(-1, 'year').format(DATE_FORMAT)
      },
      YEAR: {
        startDate: dayjs(date.startDate, DATE_FORMAT).date(1).month(1).add(-4, 'year').format(DATE_FORMAT),
        finishDate: dayjs(date.finishDate, DATE_FORMAT).date(31).month(11).add(-4, 'year').format(DATE_FORMAT)
      }
    };

    return obj[dateType];
  }
};
