import React from 'react';
import styles from './botConfig.module.scss';
import { useGetTelegramBot } from '../../../queries/queries';
import houseImage from './../../../assets/img/botImages/house.png';
import aboutUsImage from './../../../assets/img/botImages/aboutUs.png';
import faqImage from './../../../assets/img/botImages/faq.png';
import contact from './../../../assets/img/botImages/contact.png';
import appeal from './../../../assets/img/botImages/appeal.png';
import { Spin } from 'antd';
import SearchIcon from '../../../assets/icons/SearchIcon';
import ThreePoint from '../../../assets/icons/ThreePoint';
import LinearGridIcon from '../../../assets/icons/LinearGridIcon';
import MessagesList from './MessagesList';
import PaperClip from '../../../assets/icons/PaperClip';
import ArrowSquareDown from '../../../assets/icons/ArrowSquareDown';
import Send from '../../../assets/icons/Send';

const ShowButtonsPlace = () => {
  const { isLoading, data, isFetching } = useGetTelegramBot();

  const showFistRowButtons = () => {
    if (data?.houseActive && data?.aboutActive && data?.faqActive && data?.contactActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Uy tanlash</span>
            <img alt={'house'} src={houseImage} />
          </div>
          <div className={styles.showInterface_buttons_item}>
            <span>Biz haqimizda</span>
            <img alt={'about_us'} src={aboutUsImage} />
          </div>
        </div>
      );
    }
    if (data?.houseActive && data?.aboutActive && (!data?.faqActive || !data?.contactActive)) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Uy tanlash</span>
            <img alt={'house'} src={houseImage} />
          </div>
        </div>
      );
    }
    if (data?.houseActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Uy tanlash</span>
            <img alt={'house'} src={houseImage} />
          </div>
        </div>
      );
    }
    if (data?.aboutActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Biz haqimizda</span>
            <img alt={'about_us'} src={aboutUsImage} />
          </div>
        </div>
      );
    }
    return null;
  };

  const showSecondRowButtons = () => {
    if (data?.contactActive && data?.faqActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>FAQ</span>
            <img alt={'faq'} src={faqImage} />
          </div>
          <div className={styles.showInterface_buttons_item}>
            <span>Aloqa</span>
            <img alt={'contact'} src={contact} />
          </div>
        </div>
      );
    }
    if (data?.contactActive && data?.houseActive && data?.aboutActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Biz haqimizda</span>
            <img alt={'about_us'} src={aboutUsImage} />
          </div>
          <div className={styles.showInterface_buttons_item}>
            <span>Aloqa</span>
            <img alt={'contact'} src={contact} />
          </div>
        </div>
      );
    }
    if (data?.faqActive && data?.houseActive && data?.aboutActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Biz haqimizda</span>
            <img alt={'about_us'} src={aboutUsImage} />
          </div>
          <div className={styles.showInterface_buttons_item}>
            <span>FAQ</span>
            <img alt={'faq'} src={faqImage} />
          </div>
        </div>
      );
    }
    if (data?.contactActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Aloqa</span>
            <img alt={'contact'} src={contact} />
          </div>
        </div>
      );
    }

    if (data?.faqActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>FAQ</span>
            <img alt={'faq'} src={faqImage} />
          </div>
        </div>
      );
    }

    return null;
  };

  const showThirdRowButtons = () => {
    if (data?.appealActive && data?.contractActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Murojaatlar</span>
            <img alt={'appeal'} src={appeal} />
          </div>
          <div className={styles.showInterface_buttons_item}>
            <span>Menu</span>
            <img alt={'appeal'} src={appeal} />
          </div>
        </div>
      );
    }
    if (data?.appealActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Murojaatlar</span>
            <img alt={'appeal'} src={appeal} />
          </div>
        </div>
      );
    }
    if (data?.contractActive) {
      return (
        <div className={styles.showInterface_buttons}>
          <div className={styles.showInterface_buttons_item}>
            <span>Menu</span>
            <img alt={'appeal'} src={appeal} />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.showInterface}>
      <Spin spinning={isLoading || isFetching}>
        <div className={styles.showInterface_cont}>
          <div className={styles.showInterface_header}>
            <div className={styles.showInterface_header_left}>
              <h3>Telegram</h3>
              <h4>бот</h4>
            </div>
            <div className={styles.showInterface_header_right}>
              <SearchIcon />
              <LinearGridIcon />
              <ThreePoint />
            </div>
          </div>
          <div className={styles.showInterface_message_bar}>
            <MessagesList />
          </div>
          <div className={styles.showInterface_edit_message}>
            <div className={styles.showInterface_edit_message_left}>
              <PaperClip />
              <p>Написать сообщение...</p>
            </div>
            <div className={styles.showInterface_edit_message_right}>
              <ArrowSquareDown />
              <Send />
            </div>
          </div>
          <div className={styles.showInterface_buttons_cont}>
            {showFistRowButtons()}
            {showSecondRowButtons()}
            {showThirdRowButtons()}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ShowButtonsPlace;
