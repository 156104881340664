import { PipeStatuses } from './PipeAll';

export interface CreateMyCalls {
  domain: string;
  apiKey: string;
  email: string;
}

export interface MyCalls {
  id: number;
  domain: string;
  employees: MyCallsEmployee[];
}

export interface MyCallsEmployee {
  active: boolean;
  fullName: string;
  email: string;
  moiZConfigId: number;
  pipeStatusResponse: PipeStatuses &
    Partial<{
      incomingCall: boolean;
      outgoingCall: boolean;
      missingCall: boolean;
      incomingLeadName: string;
      outgoingLeadName: string;
      missingLeadName: string;
      incomingPipeStatusId: number;
      outgoingPipeStatusId: number;
      missingPipeStatusId: number;
      incomingTagsId: number[];
      outgoingTagsId: number[];
      missingTagsId: number[];
      incomingTags: { id: number; name: string }[];
      outgoingTags: { id: number; name: string }[];
      missingTags: { id: number; name: string }[];
    }>;
  employee: {
    avatar: string;
    id: number;
    fullName: string;
  };
}

export interface MyCallsSetEmployee {
  moiZId: number;
  email: string;
  employeeId: number;
  pipeDto: {
    pipeId: number;
  } & Partial<{
    incomingCall: boolean;
    outgoingCall: boolean;
    missingCall: boolean;
    incomingLeadName: string;
    outgoingLeadName: string;
    missingLeadName: string;
    incomingPipeStatusId: number;
    outgoingPipeStatusId: number;
    missingPipeStatusId: number;
    incomingTagsId: number[];
    outgoingTagsId: number[];
    missingTagsId: number[];
  }>;
}

export enum MyCallsMessageType {
  MOI_ZVONKI = 'MOI_ZVONKI',
  PLAY_MOBILE = 'PLAY_MOBILE'
}

export interface MyMessageTemplate {
  title: string;
  body: string;
  id?: number;
}

export interface SendMessageBody {
  leadId: number;
  to: string;
  text: string;
  sendSmsType: MyCallsMessageType;
  orginator: string;
}
