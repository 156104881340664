import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { ResponsiveBar } from '@nivo/bar';
import { DATE_FORMAT } from '@utils/constants/format';
import { priceSeparator } from '@utils/helper/priceSeparator';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useGetMonthDiscount } from '../../services/queries';
import { BossDiscountParam } from '../sunburst-header/SunburstHeader';

import EmployeeDiscount from './EmployeeDiscount';

import styles from './monthDiscount.module.scss';

const MonthDiscount = () => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<BossDiscountParam, any>();
  const { monthType, monthCurrency, discountBuildings, discountHouses } = useAppSelector(state => state.bossCurrency);
  const { data } = useGetMonthDiscount(searchParams?.monthStart, searchParams?.monthFinish, monthCurrency, discountBuildings!, discountHouses!);

  const currentData = data?.map(item => ({
    ...item,
    date: item?.date,
    amount: item[monthType as keyof typeof item]!,
    color: '#917EF7'
  }));

  return (
    <div className={styles.container}>
      <div className={styles.container_first}>
        <ResponsiveBar
          data={(currentData as any) || []}
          keys={['amount']}
          indexBy="date"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ datum: 'data.color' }}
          borderRadius={5}
          enableLabel={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickPadding: 10,
            format: e => {
              const date = dayjs(e, DATE_FORMAT).format('MMMM');

              return date;
            }
          }}
          axisLeft={{
            format: value => priceSeparator(value)
          }}
          tooltip={(event: any) => (
            <div
              className={styles.tooltip}
              style={{
                display: event?.data?.employees?.length > 0 ? '' : 'none'
              }}
            >
              {event?.data?.employees?.map((item: any, index: number) => (
                <div className={styles.tooltip_item} key={index}>
                  <span>{item?.fullName}</span>
                  <p>
                    {item[monthType]?.toLocaleString('ru')}
                    {monthType === 'overallAmount' ? ` ${monthCurrency}` : ''}
                  </p>
                </div>
              ))}
              <div className={styles.tooltip_item}>
                <span>{t('boss_complex.Umumiy')}</span>
                <p>
                  {event?.data[monthType]?.toLocaleString('ru')} {monthType === 'overallAmount' ? ` ${monthCurrency}` : ''}
                </p>
              </div>
            </div>
          )}
        />
      </div>
      <EmployeeDiscount />
    </div>
  );
};

export default MonthDiscount;
