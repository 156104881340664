import React from 'react';

import KanbanIcon from '../../../../../assets/icons/KanbanIcon';
import RowVerticalIcon from '../../../../../assets/icons/RowVerticalIcon';

import styles from './changeViewTypeButtons.module.scss';
import { LIST_VIEW_CRM } from '../../../../../utils/constants/localStorageKeys';
import { LocalStorage } from '../../../../../service/LocalStorage';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { setClearListView, setListView } from '../../../../../store/reducers/CrmSlice';

const ChangeViewTypeButtons = () => {
  const listView = LocalStorage.get(LIST_VIEW_CRM);

  const dispatch = useAppDispatch();

  const showListView = () => {
    dispatch(setListView(true));
    LocalStorage.set(LIST_VIEW_CRM, 'true');
    // dispatch(setClearListView());
  };

  const showKanbanView = () => {
    dispatch(setListView(false));
    LocalStorage.remove(LIST_VIEW_CRM);
    // dispatch(setClearListView());
  };

  return (
    <div className={styles.container}>
      <KanbanIcon onClick={showKanbanView} className={!listView ? styles.container_kanban_active : undefined} />
      <RowVerticalIcon onClick={showListView} className={listView ? styles.container_row_active : undefined} />
    </div>
  );
};

export default ChangeViewTypeButtons;
