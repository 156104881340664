/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ClientMiniInfo from '@components/clientMiniInfo/ClientMiniInfo';
import DownloadTemplate from '@components/download-template/DownloadTemplate';
import { useAppSelector } from '@hooks/reduxHooks';
import DocumentDownloadIcon from '@icons/DocumentDownloadIcon';
import { useDownloadBookingToWord } from '@pages/order-id-v2/services/mutation';
import { useCompanyTemplatesCompact } from '@queries/queries';
import { Permissions } from '@utils/constants/permissions';
import { FileTemplateType } from '@utils/models/FileTemplate';
import { Button, Dropdown, Popover, Spin } from 'antd';
import { t } from 'i18next';

import CommentIcon from '../../../../assets/icons/CommentIcon';
import MessageSquareIcon from '../../../../assets/icons/MessageSquareIcon';
import DiscountInfo from '../../../../components/discount-info/DiscountInfo';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { urlEncode } from '../../../../utils/helper/urlEncodeDecode';
import { GetClientByType } from '../../../../utils/models/Client';
import { OrderStatus } from '../../../../utils/models/OrderStatus';
import { Order } from '../../../../utils/models/OrderTable';
import OrderCancelModal from '../../../order/components/OrderCancelModal';
import OrderFlatInfoModal from '../../../order/components/OrderFlatInfoModal';
import { orderTypes } from '../../utils/helpers/orderType';

import styles from './orderIdV2Info.module.scss';

type Props = {
  data: Order | undefined;
  isLoading: boolean;
};

const OrderIdV2Info: React.FC<Props> = ({ data, isLoading }) => {
  const [openFlatInfo, setOpenFlatInfo] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const params = useParams<{ orderId: string }>();
  const downloadWord = useDownloadBookingToWord(data?.id!, data?.number!);
  const { data: templates } = useCompanyTemplatesCompact(FileTemplateType.BOOKING);
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const { push } = useQueryParam<any, any>();

  const handleOpenFlatInfo = () => {
    setOpenFlatInfo(true);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const handleOpenCancelModal = () => {
    setOpenCancelModal(true);
  };

  const goToCreateContractPage = () => {
    const price = btoa(`oldPriceRepaired=${data?.oldPriceRepaired}&oldPriceNotRepaired=${data?.oldPriceNotRepaired}&dealPrice=${data?.dealPrice}`);

    push(`${rootPaths.MAIN + MainPaths.CONTRACT}/${GetClientByType.ORDER_ID}=${params.orderId}${MainPaths.CLIENT_INFO}`);
  };

  const gotoContractPage = () => {
    push(`${rootPaths.MAIN + MainPaths.CONTRACT}/${data?.contractId}/${urlEncode(`№ ${data?.contractNumber}`)}`);
  };

  const gotoClientPage = (id: number, name: string) => {
    push(`${rootPaths.MAIN + MainPaths.CLIENT}/${id}/${urlEncode(name)}`);
  };

  const isAccessCreateContract = permissions?.includes(Permissions.PERMISSION_CREATE_BOOKING_CONTRACT);
  const isAccessCancelBooking = permissions?.includes(Permissions.PERMISSION_CANCEL_BOOKING);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>
            <span style={{ marginRight: 8, color: '#464255' }}>{t('titles.order')}</span>
            <span>
              <b>#</b>
              {data?.number}
            </span>
          </h1>
          <div className="d-flex">
            <span>{orderTypes[data?.status as keyof typeof orderTypes]}</span>
            {templates && templates?.length > 0 && (
              <Dropdown overlay={<DownloadTemplate mutation={downloadWord} type={FileTemplateType.BOOKING} />} trigger={['click']}>
                <Button className={styles.download} type="primary">
                  <DocumentDownloadIcon stroke="white" />
                  {t('home_id.Yuklab_olish')}
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.body_item}>
            <h3>{t('home_id.Umumiy_malumotlar')}</h3>

            <div>
              <ClientMiniInfo phones={[data?.clientPhone || '']} name={data?.name || ''} score={data?.clientScore} passportExpired={data?.passportExpired} />
            </div>

            <div className={styles.body_item_content}>
              <div>
                <h3>{t('home.Mijoz')}</h3>
                <p style={{ cursor: 'pointer' }} onClick={() => gotoClientPage(Number(data?.clientId), data?.name!)}>
                  {data?.name}
                </p>
              </div>
              <div>
                <h3>{t('contract_id.Masul_xodim')}</h3>
                <p>{data?.employee}</p>
              </div>
              <div>
                <h3>{t('order.Turar_joy_majmuasi')}</h3>
                <p>
                  {data?.houseName}, {data?.buildingName}
                </p>
              </div>
              <div>
                <h3>{t('home.Manzil')}</h3>
                <p>{data?.address}</p>
              </div>
            </div>
            <div className={styles.body_item_content}>
              <div>
                <h3>{t('client.Mijoz_telefon_raqami')}</h3>
                <p>{data?.clientPhone}</p>
              </div>
            </div>
          </div>
          <div className={styles.body_item}>
            <h3>{t('home_id.Xonadon_malumotlari')}</h3>
            <div className={styles.body_item_content}>
              <div>
                <h3>{t('order.Uy_raqami')}</h3>
                <p>{data?.flatNumber}</p>
              </div>
              <div>
                <h3>{t('home_id.Qavat')}</h3>
                <p>{data?.flatFloor}</p>
              </div>
              <div>
                <h3>{t('home.Xonalar_soni')}</h3>
                <p>{data?.flatRooms}</p>
              </div>
              <div>
                <h3>{t('order.Maydoni_m²')}</h3>
                <p>{data?.flatArea}</p>
              </div>
              <div>
                <h3>{t('order.Tamirsiz_narxi_som')}</h3>
                <p>
                  {data?.oldPriceNotRepaired?.toLocaleString('ru')}
                  <span style={{ marginLeft: 5 }}>{data?.houseCurrency?.ccy}</span>
                </p>
              </div>
              <div>
                <h3>{t('order.Tamirli_narxi_som')}</h3>
                <p>
                  {data?.oldPriceRepaired?.toLocaleString('ru')}
                  <span style={{ marginLeft: 5 }}>{data?.houseCurrency?.ccy}</span>
                </p>
              </div>
            </div>
          </div>
          <div className={styles.body_item}>
            <h3>{t('createOrder.Buyurtma_malumotlari')}</h3>
            <div className={styles.body_item_content}>
              <div>
                <h3>{t('order.Buyurtma_muddati')}</h3>
                <p>
                  {data?.delay} {t('one_lid.kun')}
                </p>
              </div>
              <div>
                <h3>{t('createOrder.Buyurtma_qilingan_sana')}</h3>
                <div style={{ display: 'flex', gap: 8 }}>
                  <p>{data?.date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.body_item}>
            <div className={styles.body_item_content}>
              <div>
                <h3>{t('createOrder.Tolov_holati')}</h3>
                <p>{data?.refundablePayment === false ? t('createOrder.Tolov_qaytarilmaydi') : t('createOrder.Tolov_qaytariladi')}</p>
              </div>
              {data?.dealPrice && (
                <div>
                  <h3>{t('home.Kelishilgan_summa')}</h3>
                  <p>
                    {data?.dealPrice?.toLocaleString('ru')} <span style={{ marginLeft: 5 }}>{data?.houseCurrency?.ccy}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.body_item}>
            <div className={styles.body_item_content}>
              <div>
                <h3>
                  {data?.status === OrderStatus.CANCELED
                    ? t('createOrder.Buyurtma_bekor_qilingan_sana')
                    : data?.status === OrderStatus.CONTRACT
                      ? t('createOrder.Shartnoma_qilingan_sana')
                      : ''}
                </h3>
                <div style={{ display: 'flex', gap: 8 }}>
                  <p>{data?.status === OrderStatus.CANCELED ? data?.cancelDate : data?.status === OrderStatus.CONTRACT ? data?.createdDateContract : ''}</p>
                  {data?.status === OrderStatus.CANCELED && (
                    <Popover content={<span>{data?.cancelNote ? data?.cancelNote : <span style={{ color: '#bbb' }}>Izoh mavjud emas</span>}</span>} trigger="hover">
                      <span style={{ cursor: 'pointer' }}>
                        <CommentIcon />
                      </span>
                    </Popover>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DiscountInfo content={<span>{data?.note}</span>} title={t('create_table_payments.Izoh')}>
          <div className={styles.footer}>
            <MessageSquareIcon />
            <span>{t('order.Izohni_korish')}</span>
          </div>
        </DiscountInfo>

        {data?.status === OrderStatus.ACTIVE ? (
          <div className={styles.actions}>
            <Button onClick={handleOpenFlatInfo}>{t('home.Uy_malumotlari')}</Button>
            <Button disabled={!isAccessCreateContract} onClick={goToCreateContractPage} type="primary">
              {t('order.Shartnoma_qilish')}
            </Button>
            <Button disabled={!isAccessCancelBooking} onClick={handleOpenCancelModal}>
              {t('home.Bekor_qilish')}
            </Button>
          </div>
        ) : (
          ''
        )}
        {data?.status === OrderStatus.CONTRACT && (
          <div className={styles.contract}>
            <Button onClick={handleOpenFlatInfo}>{t('home.Uy_malumotlari')}</Button>
            <Button onClick={() => gotoContractPage()}>{t('order.Shartnomani_korish')}</Button>
          </div>
        )}
        <OrderCancelModal visible={openCancelModal} id={params.orderId || '-1'} onCancel={handleCloseCancelModal} companyId={data?.companyId} />
        <OrderFlatInfoModal open={openFlatInfo} close={setOpenFlatInfo} id={String(data?.flatId)} />
      </div>
    </Spin>
  );
};

export default OrderIdV2Info;
