import { Modal } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CommentModalProps {
  visible: boolean;
  setVisible: (i: boolean) => void;
  comment: string | undefined;
  date: string | undefined;
  name: string | undefined;
}

const CommentModal: FC<CommentModalProps> = ({ visible, setVisible, comment, date, name }) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal title={false} visible={visible} footer={null} closable={false} width={740} centered onCancel={handleCancel} className="commentModal">
      <h1>{!date ? t('contract.Izoh') : t('contract_id.Bekor_qilinganlik_haqida_malumot')}</h1>
      {!date ? null : (
        <>
          <div>
            <h1>{t('contract_id.Ozgarish_sanasi')}</h1>
            <h1>{t('contract_id.Bekor_qildi')}</h1>
          </div>
          <div>
            <p>{date}</p>
            <p>{name}</p>
          </div>
        </>
      )}
      <p>
        {!date ? null : <h1>{t('contract.Izoh')}</h1>}
        {comment}
      </p>
    </Modal>
  );
};

export default CommentModal;
