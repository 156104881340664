import React from 'react';

const CheckIcon: React.FC = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="22.5" fill="#00A389" fill-opacity="0.1" />
      <path
        d="M19.5 32.5H25.5C30.5 32.5 32.5 30.5 32.5 25.5V19.5C32.5 14.5 30.5 12.5 25.5 12.5H19.5C14.5 12.5 12.5 14.5 12.5 19.5V25.5C12.5 30.5 14.5 32.5 19.5 32.5Z"
        stroke="#00A389"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M18.25 22.5019L21.08 25.3319L26.75 19.6719" stroke="#00A389" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default CheckIcon;
