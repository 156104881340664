import React, { ReactNode, useState } from 'react';
import { useDownloadBrokerExcel } from '@queries/mutation';
import { generateReqBody } from '@utils/helper';
import { DatePicker, DatePickerProps, Modal } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { NumberParam, NumericArrayParam, StringParam, useQueryParams } from 'use-query-params';

type Props = {
  children: ReactNode;
};

const ExcelDateModal: React.FC<Props> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<string | null>(null);
  const [queries] = useQueryParams({
    search: StringParam,
    cities: NumericArrayParam,
    size: NumberParam
  });

  const { mutateAsync, isLoading } = useDownloadBrokerExcel({ lang: i18n.language });

  const onCancel = () => {
    setOpen(false);
    setDate(null);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onDownload = () => {
    mutateAsync(
      generateReqBody({
        date,
        ...queries
      })
    ).then(onCancel);
  };

  const onDate: DatePickerProps['onChange'] = (_, dateString) => {
    setDate(dateString);
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        visible={open}
        onOk={onDownload}
        onCancel={onCancel}
        okButtonProps={{
          loading: isLoading,
          disabled: !date
        }}
        cancelButtonProps={{
          disabled: isLoading
        }}
        cancelText={t('broker.Yopish')}
        okText={t('broker.Yuklab olish')}
        title={t('broker.KPI hisobotini yuklab olish')}
      >
        <div className="flex flex-col gap-3">
          <span>{t('broker.Sana')}</span>
          <DatePicker
            picker="month"
            onChange={onDate}
            format="DD.MM.YYYY"
            placeholder={t('broker.Sanani tanlang')}
            // @ts-expect-error
            value={date ? dayjs(date, 'DD.MM.YYYY') : null}
          />
        </div>
      </Modal>
    </>
  );
};

export default ExcelDateModal;
