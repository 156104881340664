/* eslint-disable prefer-promise-reject-errors */
import { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import TelPlusIcon from '@icons/TelPlusIcon';
import { phoneValidator } from '@utils/index';
import { Form, Space } from 'antd';
import { Button, Input, Spin, Switch } from 'global';
import { useTranslation } from 'react-i18next';

import { usePostLicenseSMS } from './service/mutation';
import { useGetLicenseSMS } from './service/queries';
import { LicenseSMSType } from './utils/model/LicenseSMSType';

import 'react-international-phone/style.css';

const LicenseMessage = () => {
  const { data: dataLicenseSMS, refetch, isLoading } = useGetLicenseSMS();
  const postLicenseSMS = usePostLicenseSMS();

  const [form] = Form.useForm<any>();

  const { t } = useTranslation();

  const phones = dataLicenseSMS?.phones || [];

  useEffect(() => {
    const phoneValues = phones.map(phone => ({
      phone: phone.phone,
      code: phone.code
    }));

    form.setFieldsValue({
      ...dataLicenseSMS,
      status: dataLicenseSMS?.status,
      phones: phones?.length !== 0 ? phoneValues : [{ phone: '', code: 'UZ' }]
    });
  }, [form, phones]);

  const onFinish = (values: LicenseSMSType) => {
    const formData = {
      phones: values?.phones,
      status: values?.status
    };

    if (values?.id) {
      postLicenseSMS.mutateAsync({ id: values?.id, ...formData }).then(refetch);
    } else {
      postLicenseSMS.mutateAsync(formData as any).then(refetch);
    }
  };

  return (
    <div className="mr-auto w-[475px] rounded-[6px] bg-white p-[24px]">
      <div>
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="id" className="hidden">
            <Input />
          </Form.Item>

          <div className="mb-[16px] flex h-[44px] w-[425px] items-center justify-between rounded-[8px] bg-[#F2F4F7] p-3">
            <h3 className="mb-[-1px] text-[14px] text-[#667085]">{t('home.Holati')}</h3>
            <Form.Item name="status" valuePropName="checked">
              <Switch size="default" className="mb-[-20px]" loading={postLicenseSMS?.isLoading} />
            </Form.Item>
          </div>

          <div className="mb-[16px]">
            <div>
              <span className="text-[#8c8c8c]">{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>

              <Form.List name="phones">
                {(fields, { add, remove }) => (
                  <>
                    {isLoading ? (
                      <div className="mx-[40px]">
                        <Spin />
                      </div>
                    ) : (
                      <>
                        {fields?.map(field => (
                          <Space direction="horizontal" className="d-flex" key={field?.key}>
                            <div>
                              <Form.Item {...field} name={[field?.name, 'code']} className="d-none" />
                              <div className="flex flex-col gap-[5px]">
                                <Form.Item {...field} className="mt-[10px] w-[400px]" name={[field?.name, 'phone']} rules={[phoneValidator()]}>
                                  <InternationalPhoneNumber
                                    defaultCountry="uz"
                                    onChange={(e, phone) => {
                                      form.setFields([
                                        {
                                          name: ['phones', field.name],
                                          value: {
                                            phone: e,
                                            code: phone?.country?.iso2?.toUpperCase()
                                          }
                                        }
                                      ]);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            {fields?.length > 1 && (
                              <Button onClick={() => remove(field?.name)} shape="circle">
                                <CloseOutlined />
                              </Button>
                            )}
                          </Space>
                        ))}
                      </>
                    )}

                    {fields?.length < 5 && (
                      <div className="mb-[-10px]">
                        <div className="inline-flex cursor-pointer items-center gap-[10px] text-[#3772FF]" onClick={add}>
                          <TelPlusIcon />
                          {t('client_create_contract.Telefon_raqam_qoshish')}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Form.List>
            </div>
          </div>

          <div className="float-right mt-[20px]">
            <Button type="primary" htmlType="submit" loading={postLicenseSMS?.isLoading} className="h-[44px] w-[208px] rounded-[30px] text-[16px]">
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default LicenseMessage;
