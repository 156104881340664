import React, { FC, useState, useEffect } from 'react';
import styles from './sipuUsers.module.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import SipuUser from './SipuUser';
import { SipuiAuth } from '../utils/models/SipuiAuth';
import { useSipuniConfigEmployeeList } from '../utils/service/queries';
import { useCrmEmployee } from '../../../../../../queries/queries';
import SipuUserCreate from './SipuUserCreate';
import { useSipuniConfigEmployee } from '../utils/service/mutation';

export interface IProps {
  data: SipuiAuth | undefined;
}

const SipuUsers: FC<IProps> = ({ data }) => {
  const [create, setCreate] = useState(false);
  const { t } = useTranslation();
  const { data: employeeList } = useCrmEmployee();
  const { data: employee } = useSipuniConfigEmployeeList(data?.id);
  const employeeMutate = useSipuniConfigEmployee();
  const [sipNumber, setSipNumber] = useState('');
  const [employeeId, setEmployeeId] = useState<number | null>(null);

  useEffect(() => {
    if (!create) {
      setSipNumber('');
      setEmployeeId(null);
    }
  }, [create]);

  const handleSave = () => {
    if (employeeId) {
      employeeMutate
        .mutateAsync({
          employeeId: employeeId,
          sipNumber: sipNumber,
          sipuniConfId: data?.id
        })
        .then(() => {
          setCreate(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      {employee?.map((item, index) => <SipuUser key={item?.id} employeeList={employeeList} {...item} sipuniConfId={data?.id} index={index} />)}
      {create ? (
        <SipuUserCreate
          sipNumber={sipNumber}
          employeeId={employeeId}
          employeeList={employeeList}
          setEmployeeId={setEmployeeId}
          setSipNumber={setSipNumber}
          setCreate={setCreate}
        />
      ) : (
        <div className={styles.addUser} onClick={() => setCreate(true)}>
          + Qo'shish
        </div>
      )}
      <Button className={styles.saveBtn} disabled={!employeeId || !sipNumber} onClick={handleSave} loading={employeeMutate.isLoading}>
        {t('home.Saqlash')}
      </Button>
    </div>
  );
};

export default SipuUsers;
