import React, { FC } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import TrashIcon from '@icons/TrashIcon';
import { useBuildingDelete } from '@queries/mutation';
import { BuildingId } from '@utils/models/Building';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './deleteBuildingModal.module.scss';

interface IProps {
  setVisible: (visible: boolean) => void;
  visible: boolean;
  name: string;
  id: number;
  buildingId: BuildingId[] | undefined;
}

const DeleteBuildingModal: FC<IProps> = ({ id, name, setVisible, visible, buildingId }) => {
  const { t } = useTranslation();
  const { push } = useQueryParam();
  const deleteBuilding = useBuildingDelete();
  const handleClose = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    deleteBuilding.mutateAsync(id).then(() => {
      handleClose();
      push({
        search: `?blockId=${buildingId![0].id}&blockName=${buildingId![0].name}`
      });
    });
  };

  return (
    <Modal title={false} visible={visible} footer={false} closable={false} onCancel={handleClose} width={400} wrapClassName={styles.container}>
      <span>
        <TrashIcon />
      </span>
      <p>
        {t('home.Haqiqatdan_ham_ochirmoqchimisiz?')}({name})
      </p>
      <div>
        <Button onClick={handleClose}>{t('crm.Yopish')}</Button>
        <Button onClick={handleDelete} loading={deleteBuilding.isLoading}>
          {t('crm.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteBuildingModal;
