import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { setCrmFields } from '@reducers/CrmFieldSlice';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { CrmFieldsType } from '@utils/models/CrmFieldsType';
import { TableKey } from '@utils/models/TableKey';
import { Button, Form, Input, Select } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import ArrowDownCrmIcon from '../../../../../assets/icons/ArrowDownCrmIcon';
import RoboSettingIcon from '../../../../../assets/icons/RoboSettingIcon';
import { useLeadSave } from '../../../../../queries/mutation';
import { useCrmFields, usePipeStatusesById, useTableConfig } from '../../../../../queries/queries';
import { helper } from '../../../../../utils/helper/helper';
import { CreateLeadInterface } from '../../../../../utils/models/CreateLeadInterface';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../utils/models/GoogleAnalitks';

import CreateLeadControll from './CreateLeadControll';
import SearchLeadItem from './SearchLeadItem';

import styles from './createLead.module.scss';

interface IProps {
  pipeStatusId: number;
  balance: number;
  name: string;
}

export enum valueType {
  string = 'string',
  array = 'array'
}
const CreateLead: FC<IProps> = ({ pipeStatusId }) => {
  const [selectedExtra, setSelectedExtra] = useState<CreateLeadInterface | null>();
  const { searchParams } = useQueryParam<any, any>();
  const [form] = Form.useForm();
  const [selectedPipeStatusId, setSelectedPipeStatusId] = useState<number | null>(null);

  function createLeadEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CREATE_LEAD_FAST
    });
  }
  const leadSave = useLeadSave(createLeadEvent);
  const { led, contact } = useAppSelector(state => state.crmField);
  const { data: configData } = useTableConfig(TableKey.dragg, selectedPipeStatusId!);
  const { data } = useCrmFields(selectedPipeStatusId!);

  const [createLeadVisible, setCreateLeadVisible] = useState(false);
  const [setting, setSetting] = useState(false);
  const { data: pipeStatus } = usePipeStatusesById(Number(searchParams.voronka));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // create lead

  // func v1
  // const hanldeFields = (lead: any[]) => [
  //   ...lead
  //     .filter(i => i.value)
  //     .map(item => {
  //       const newItem = {
  //         id: item?.id as number,
  //       };

  //       if (typeof item?.value === 'object') {
  //         return {
  //           ...newItem,
  //           valueList: item?.value
  //         };
  //       }
  //       return {
  //         ...newItem,
  //         value: item?.value
  //       };
  //     })
  // ];

  // func v2
  const hanldeFields = (lead: any[]) => [
    ...lead
      .filter(item => item.value)
      .map(item => ({
        id: item?.id as number,
        ...(typeof item?.value === 'object' ? { valueList: item?.value } : { value: item?.value })
      }))
  ];

  const onFinish = (values: { contactName: string; name: string; balance: string; lead: any[]; contact: any[]; contactPhone: string; pipeStatusId: any }) => {
    if (selectedExtra?.id) {
      leadSave.mutate({
        ...selectedExtra,
        ...(helper.parseFloatStringToNumber(String(values.balance)) && {
          balance: helper.parseFloatStringToNumber(String(values.balance))
        }),
        name: values.name,
        // pipeStatusId: selectedExtra?.pipeStatusResponse?.id!,
        pipeStatusId: selectedPipeStatusId as number,
        contacts: selectedExtra?.contacts?.map((item, index) =>
          index === 0
            ? {
                ...item,
                name: values.contactName,
                phones: values.contactPhone ? [values.contactPhone] : null,
                fields: [
                  ...values.contact
                    .filter(i => i.value)
                    .map(item => ({
                      id: item.id,
                      value: item.value
                    }))
                ]
              }
            : {
                ...item
              }
        ),
        fields: [
          ...values.lead
            .filter(i => i.value)
            .flatMap(item => {
              if (Array.isArray(item.value)) {
                return item.value.map((val: any) => ({ id: item.id, value: val }));
              }
              return [{ id: item.id, value: item.value }];
            })
        ]

        // fields: [
        //   ...values.lead
        //     .filter(i => i.value)
        //     .map(item => ({
        //       id: item.id,
        //       value: item.value
        //     }))
        // ]
        // fields: hanldeFields(values?.lead)
      });
    } else {
      leadSave.mutate({
        ...(helper.parseFloatStringToNumber(String(values.balance)) && {
          balance: helper.parseFloatStringToNumber(String(values.balance))
        }),
        pipeStatusId: selectedPipeStatusId as number,
        name: values.name,
        contacts: [
          {
            name: values.contactName,
            phones: values.contactPhone ? [values.contactPhone] : null,
            fields: [
              ...values.contact
                .filter(i => i.value)
                .map(item => ({
                  id: item.id,
                  value: item.value
                }))
            ]
          }
        ],
        fields: [
          ...values.lead
            .filter(i => i.value)
            .flatMap(item => {
              if (Array.isArray(item.value)) {
                return item.value.map((val: any) => ({ id: item.id, value: val }));
              }
              return [{ id: item.id, value: item.value }];
            })
        ]
        // fields: [
        //   ...values.lead
        //     .filter(i => i.value)
        //     .map(item => ({
        //       id: item.id,
        //       value: item.value
        //     }))
        // ]
        // fields: hanldeFields(values?.lead)
      });
    }
  };

  const handleCreateVisible = () => {
    setCreateLeadVisible(!createLeadVisible);
    setSelectedExtra(null);
    form.resetFields();
    setSelectedPipeStatusId(null);
  };

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (form.__INTERNAL__.name) {
      form.resetFields();
    }
    setCreateLeadVisible(false);
    setSelectedPipeStatusId(null);
    // eslint-disable-next-line
  }, [leadSave.isSuccess]);

  const handleOpenSetting = () => {
    setSetting(true);
  };

  const handleChange = (value: number) => {
    setSelectedPipeStatusId(value);
  };

  const options = pipeStatus
    ? pipeStatus.map(status => ({
        value: status.id,
        label: (
          <div key={status.pipeId} className={styles.selectTextStyles} style={{ backgroundColor: `${status.color}` }}>
            <p>{status.name}</p>
          </div>
        ),
        disabled: status.type === 'FIRST'
      }))
    : [];

  if (!selectedPipeStatusId && options.length > 0) {
    setSelectedPipeStatusId(options[1].value!);
  }

  useEffect(() => {
    if (createLeadVisible) {
      form.setFieldsValue({
        contact: contact?.filter(item => item.checked),
        lead: led?.filter(item => item.checked)
      });
    }
  }, [led, contact, createLeadVisible]);

  useEffect(() => {
    if (configData && data && createLeadVisible) {
      dispatch(setCrmFields({ data, customdata: configData?.config }));
    }
  }, [data, configData, dispatch, createLeadVisible]);
  return setting ? (
    <CreateLeadControll setSetting={setSetting} tableKey={TableKey.dragg} selectedPipeStatusId={selectedPipeStatusId || pipeStatusId} pipeStatus={pipeStatus!} />
  ) : (
    <>
      {!createLeadVisible ? (
        <Button onClick={handleCreateVisible} className={styles.createLeadButton}>
          {t('crm.Tez_qoshish')}
        </Button>
      ) : (
        <div className={styles.createLead}>
          <Form onFinish={onFinish} autoComplete="off" form={form} name="CreateLead">
            <Form.Item name="pipeStatusId">
              <Select placeholder={t('home.Bosqich')} className="customSelect" value={selectedPipeStatusId} onChange={handleChange} options={options} />
            </Form.Item>
            <Form.Item name="name">
              <Input placeholder={t('crm.Nomi')} />
            </Form.Item>
            <Form.Item name="balance">
              <Input
                placeholder={t('crm.Budjet')}
                onChange={e => {
                  form.setFieldsValue({
                    balance: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).string
                  });
                }}
              />
            </Form.Item>
            <Form.List name="lead">
              {fields => (
                <>
                  {data &&
                    fields.map((field, index) => {
                      const type = form.getFieldValue('lead')[index]?.type;

                      if (type === CrmFieldsType.SELECT) {
                        return (
                          <Form.Item key={field.key} name={[field.name, 'value']}>
                            <Select
                              suffixIcon={<ArrowDownCrmIcon strokeColor="#bfbfcd" />}
                              placeholder={`${form.getFieldValue('lead')[index][`name${textCapitalize(i18n.language)}`] || form.getFieldValue('lead')[index].name}`}
                              className="customSelect"
                            >
                              {form.getFieldValue('lead')[index]?.options?.map((item: any) => (
                                <Select.Option key={item?.id} value={item?.name}>
                                  {item[`name${textCapitalize(i18n.language)}`] || item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }

                      if (type === CrmFieldsType.MULTI_SELECT) {
                        return (
                          <Form.Item key={field.key} name={[field.name, 'value']}>
                            <Select
                              suffixIcon={<ArrowDownCrmIcon strokeColor="#bfbfcd" />}
                              placeholder={`${form.getFieldValue('lead')[index][`name${textCapitalize(i18n.language)}`] || form.getFieldValue('lead')[index].name}`}
                              className="customSelect"
                              mode="multiple"
                            >
                              {form.getFieldValue('lead')[index]?.options?.map((item: any) => (
                                <Select.Option key={item?.id} value={item?.name}>
                                  {item[`name${textCapitalize(i18n.language)}`] || item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }

                      if (type === CrmFieldsType.COUNTER) {
                        return (
                          <SearchLeadItem
                            key={field.key}
                            name={[field.name, 'value']}
                            data={data}
                            form={form}
                            setSelectedExtra={setSelectedExtra}
                            placeholder={` ${form.getFieldValue('lead')[index].name}`}
                            inputType="number"
                          />
                        );
                      }

                      return (
                        <SearchLeadItem
                          key={field.key}
                          name={[field.name, 'value']}
                          data={data}
                          form={form}
                          setSelectedExtra={setSelectedExtra}
                          placeholder={` ${form.getFieldValue('lead')[index][`name${textCapitalize(i18n.language)}`] || form.getFieldValue('lead')[index].name}`}
                          inputType={`${form.getFieldValue('lead')[index].type.toLowerCase()}`}
                        />
                      );
                    })}
                </>
              )}
            </Form.List>

            <div className={styles.createLead_fields}>
              <SearchLeadItem
                name="contactName"
                label={t('crm.Mijoz')}
                rules={[{ required: true, message: '' }]}
                data={data}
                form={form}
                setSelectedExtra={setSelectedExtra}
                placeholder={t('one_lid.Kontakt_nomi')}
                bordered={false}
              />
              <SearchLeadItem
                name="contactPhone"
                label={t('crm.Mijoz')}
                rules={[{ required: true, message: '' }]}
                data={data}
                form={form}
                setSelectedExtra={setSelectedExtra}
                placeholder={t(`changes.client.NaturalClient.Telefon_raqam`)}
                bordered={false}
              />
              <Form.List name="contact">
                {fields => (
                  <>
                    {data &&
                      fields.map((field, index) => {
                        const type = form.getFieldValue('contact')[index]?.type;

                        if (type === CrmFieldsType.SELECT) {
                          return (
                            <Form.Item label={t('crm.Mijoz')} key={field.key} name={[field.name, 'value']}>
                              <Select
                                className={styles.createLead_fields_option_select}
                                suffixIcon={<ArrowDownCrmIcon strokeColor="#bfbfcd" />}
                                placeholder={`${form.getFieldValue('contact')[index].name}`}
                                bordered={false}
                              >
                                {form.getFieldValue('contact')[index]?.options?.map((item: any) => (
                                  <Select.Option key={item?.id} value={item?.name}>
                                    {item[`name${textCapitalize(i18n.language)}`] || item?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          );
                        }
                        if (type === CrmFieldsType.COUNTER) {
                          return (
                            <Form.Item label={t('crm.Mijoz')} key={field.key} name={[field.name, 'value']}>
                              <Input type="number" placeholder={`${form.getFieldValue('contact')[index].name}`} />
                            </Form.Item>
                          );
                        }
                        return (
                          <Form.Item label={t('crm.Mijoz')} key={field.key} name={[field.name, 'value']}>
                            <Input type={`${form.getFieldValue('contact')[index].type.toLowerCase()}`} placeholder={`${form.getFieldValue('contact')[index].name}`} />
                          </Form.Item>
                        );
                      })}
                  </>
                )}
              </Form.List>
            </div>
            <div className={styles.createLead_footer}>
              <div className={styles.createLead_footer_left}>
                <Button type="primary" htmlType="submit" loading={leadSave.isLoading}>
                  {selectedExtra?.id ? t('home.Saqlash') : t('crm.Qoshish')}
                </Button>
                <Button onClick={handleCreateVisible} className={styles.createLead_footer_left_closeButton} htmlType="reset">
                  {t('crm.Bekor_qilish')}
                </Button>
              </div>
              <div className={styles.createLead_footer_right} onClick={handleOpenSetting}>
                <RoboSettingIcon />
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default CreateLead;
// @thtflx
