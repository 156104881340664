import { DetailedHTMLProps, HTMLAttributes } from 'react';
import AIStarIcon from '@icons/AIStarIcon';
import AIStarOneIcon from '@icons/AIStarOneIcon';
import { getRandomInt } from '@utils/helper/getRandomInt';
import classNames from 'classnames';

import styles from './aIButton.module.scss';

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: number;
  length?: number;
  masshtab?: {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  };
  delay?: number;
  starSize?: number;
  loading?: boolean;
}

const AIButton = ({
  className,
  size = 24,
  length = 15,
  masshtab = {
    maxX: 30,
    maxY: 30,
    minX: -30,
    minY: -30
  },
  starSize = 6,
  loading = false,
  ...props
}: IProps) => {
  const data = Array.from({ length }).map((_, i) => i + 1);

  return (
    <div className={classNames(styles.ai, className)} {...props}>
      <AIStarIcon size={size} />
      {data?.map(item => (
        <AIStarOneIcon
          className={classNames(styles.star, loading && styles.loading)}
          key={item}
          style={{
            animationDelay: `${item / 3}s`,
            width: starSize,
            height: starSize,
            transform: `translate(${getRandomInt(masshtab.minX, masshtab.maxX)}px,${getRandomInt(masshtab?.minY, masshtab?.maxY)}px)`
          }}
        />
      ))}
    </div>
  );
};

export default AIButton;
