import React, { FC, useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { t } from 'i18next';

import TrashIcon from '../../../../../../assets/icons/contract/TrashIcon';
import EditIcon from '../../../../../../assets/icons/EditIcon';
import SuccessBoldIcon from '../../../../../../assets/icons/SuccessBoldIcon';
import ThreePoint from '../../../../../../assets/icons/ThreePoint';
import ToggleCircleIcon from '../../../../../../assets/icons/ToggleCircleIcon';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { useTableConfigCustomMutation } from '../../../../../../queries/mutation';
import { TableKeyCustom } from '../../../../../../utils/models/TableKey';

import { deleteCrmFilter } from './utils/helper/deleteCrmFilter';
import { editCrmFilter } from './utils/helper/editCrmFilter';
import { CrmFilterSaveData, CrmFilterSaveDataItem } from './utils/models/CrmFilterSaveData';

import styles from './crmFilterSave.module.scss';

interface IProps extends CrmFilterSaveDataItem {
  data: CrmFilterSaveData | undefined;
}

const CrmFilterSaveItem: FC<IProps> = ({ id, name, url, data }) => {
  const [value, setValue] = useState('');
  const [edit, setEdit] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const tableConfig = useTableConfigCustomMutation(TableKeyCustom.crm_filter);
  const { push, location } = useQueryParam();

  const handleClick = () => {
    push({ pathname: location?.pathname, search: url });
  };

  const handleDelete = () => {
    tableConfig?.mutate({
      key: TableKeyCustom.crm_filter,
      value: JSON.stringify(deleteCrmFilter(data, id))
    });
  };

  const handleEditOpen = () => {
    setEdit(true);
    setOpenDropDown(false);
    inputRef.current?.focus();
  };

  useEffect(() => {
    setValue(name);
  }, [name]);

  const saveEdit = () => {
    tableConfig
      ?.mutateAsync({
        key: TableKeyCustom.crm_filter,
        value: JSON.stringify(editCrmFilter(data, id, value))
      })
      .then(() => setEdit(false));
  };

  const permanentActive = () => {
    tableConfig
      ?.mutateAsync({
        key: TableKeyCustom.crm_filter,
        value: JSON.stringify({
          ...data,
          active: data?.active === id ? 0 : id
        })
      })
      .then(() => {
        setOpenDropDown(false);
      });
  };

  return (
    <div
      className={styles.item}
      onClick={handleClick}
      style={{
        background: openDropDown || edit ? 'rgba(229, 231, 235, 0.50)' : ''
      }}
    >
      <input
        type="text"
        ref={inputRef}
        value={value}
        readOnly={!edit}
        style={{
          pointerEvents: edit ? 'inherit' : 'none',
          color: location?.search === url ? '#00A389' : ''
        }}
        onChange={e => setValue(e?.target?.value)}
        onClick={e => {
          if (edit) {
            e.stopPropagation();
          }
        }}
        onKeyDown={e => {
          if (e?.key === 'Enter') {
            saveEdit();
          }
        }}
        onBlur={() => {
          setEdit(false);
        }}
      />
      <div>
        {tableConfig.isLoading && <LoadingOutlined />}
        {edit && value !== name && (
          <span onClick={saveEdit} className={styles.item_editSave}>
            <SuccessBoldIcon />
          </span>
        )}
        <Dropdown
          trigger={['click', 'hover']}
          mouseLeaveDelay={0.1}
          mouseEnterDelay={500}
          visible={openDropDown}
          onVisibleChange={setOpenDropDown}
          overlay={
            <div className={styles.item_editDropDown} onClick={e => e.stopPropagation()}>
              <div onClick={handleDelete}>
                <TrashIcon /> <span style={{ color: 'red' }}>{t('online_pbx.Ruxsat_ochirish')}</span>
              </div>
              <div onClick={handleEditOpen}>
                <EditIcon stroke="#292D32" /> {t('online_pbx.Ruxsat_tahrirlash')}
              </div>
              <div onClick={permanentActive}>
                <ToggleCircleIcon checked={data?.active === id} />{' '}
                {data?.active === id ? t('home.Doimiy_faolni_ochirish') : t('home.Doimiy_faol_qilish')}
              </div>
            </div>
          }
        >
          <span style={{ opacity: openDropDown || edit ? '1' : '' }} className={styles.item_editMenu} onClick={e => e.stopPropagation()}>
            <ThreePoint />
          </span>
        </Dropdown>
      </div>
    </div>
  );
};

export default CrmFilterSaveItem;
