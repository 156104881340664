import React, { FC, useRef, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Button, Select, Slider, Table } from 'global';
import { useTranslation } from 'react-i18next';

import ArrowDownCrmIcon from '../../../assets/icons/ArrowDownCrmIcon';
import ArrowLeftIcon from '../../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../../assets/icons/contract/ArrowRightIcon';
import PrinterIcon from '../../../assets/icons/PrinterIcon';
import { DATE_FORMAT } from '../../../utils/constants/format';
import { helper } from '../../../utils/helper/helper';
import UniversalModal from '../../universalModal/UniversalModal';

import styles from './kreditCalculation.module.scss';

interface IProps {
  kreditCalculatorVisible: boolean;
  setKreditCalculatorVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const maxKredit = 416500000;
const maxKredit2 = 327200000;

const KreditCalculation: FC<IProps> = ({ kreditCalculatorVisible, setKreditCalculatorVisible }) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [data, setData] = useState<
    {
      payment: number;
      main_payment: number;
      percent_payment: number;
      residue: number;
    }[]
  >([]);

  const columns: ColumnsType<{
    payment: number;
    main_payment: number;
    percent_payment: number;
    residue: number;
  }> = [
    {
      title: '№',
      render(value, record, index) {
        return index + 1 + (page - 1) * 10;
      },
      width: 70
    },
    {
      title: t('home.Sana'),
      render(value, record, index) {
        return dayjs()
          .add(index + 1 + (page - 1) * 10, 'M')
          .format(DATE_FORMAT);
      }
    },
    {
      dataIndex: 'main_payment',
      title: t('widgets.Asosiy_miqdor'),
      render: value => helper.deleteNotNumbersAndParseFloatNumber(String(value || 0)).number?.toLocaleString('ru')
    },
    {
      dataIndex: 'percent_payment',
      title: t('widgets.Foiz_tolovi'),
      render: value => helper.deleteNotNumbersAndParseFloatNumber(String(value || 0)).number?.toLocaleString('ru')
    },
    {
      dataIndex: 'payment',
      title: t('widgets.Tolov'),
      render: value => helper.deleteNotNumbersAndParseFloatNumber(String(value || 0)).number?.toLocaleString('ru')
    },
    {
      dataIndex: 'residue',
      title: t('create_payment.Qodiq'),
      render: value => helper.deleteNotNumbersAndParseFloatNumber(String(value || 0)).number?.toLocaleString('ru')
    }
  ];

  const [state, setState] = useState({
    amount: 200000000,
    amount2: maxKredit,
    delay: 20,
    percent: 20
  });

  const [state2, setState2] = useState({
    amount: 200000000,
    delay: 20,
    percent: 20
  });

  // onCancelCalculator
  const onCancelCalculator = () => {
    setKreditCalculatorVisible(false);
  };

  // onSelect
  const onSelect = (e: number, key: string) => {
    if (key === 'amount' && e >= state.amount2) {
      setState2(prev => ({
        ...prev,
        [key]: e - state.amount2
      }));
    }
    if (key === 'amount2') {
      setState(prev => ({
        ...prev,
        amount: e + state2.amount
      }));
    }
    setState(prev => ({
      ...prev,
      [key]: e
    }));
  };

  // onSelect
  const onSelect2 = (e: number, key: string) => {
    if (key === 'amount') {
      setState(prev => ({
        ...prev,
        [key]: e + state.amount2
      }));
    }
    setState2(prev => ({
      ...prev,
      [key]: e
    }));
  };

  // calcHandle
  const calcHandle = () => {
    /* prettier-ignore */
    let amount = state.amount > state.amount2 ? state.amount2 : state.amount
    let amount2 = state2.amount;
    const payment = (((state.percent / 12 / 100) * (1 + state.percent / 12 / 100) ** state.delay) / ((1 + state.percent / 12 / 100) ** state.delay - 1)) * amount;
    const payment2 = (((state2.percent / 12 / 100) * (1 + state2.percent / 12 / 100) ** state2.delay) / ((1 + state2.percent / 12 / 100) ** state2.delay - 1)) * state2.amount;

    const arr = Array.from({ length: state.delay }).map((item, index) => {
      const percent_payment1 = (amount * state.percent) / 100 / 12;
      const main_payment1 = payment - percent_payment1;
      const residue1 = amount - main_payment1;

      amount = residue1;
      return {
        payment,
        main_payment: main_payment1,
        percent_payment: percent_payment1,
        residue: residue1
      };
    });

    const arr2 = Array.from({ length: state2.delay }).map((item, index) => {
      const percent_payment1 = (amount2 * state2.percent) / 100 / 12;
      const main_payment1 = payment2 - percent_payment1;
      const residue1 = amount2 - main_payment1;

      amount2 = residue1;
      return {
        payment: payment2,
        main_payment: main_payment1,
        percent_payment: percent_payment1,
        residue: residue1
      };
    });

    if (state.amount >= state.amount2) {
      const newArr = Array.from({
        length: Math.max(arr.length, arr2.length)
      }).map((item, index) => ({
        payment: (arr[index]?.payment || 0) + (arr2[index]?.payment || 0),
        main_payment: (arr[index]?.main_payment || 0) + (arr2[index]?.main_payment || 0),
        percent_payment: (arr[index]?.percent_payment || 0) + (arr2[index]?.percent_payment || 0),
        residue: (arr[index]?.residue || 0) + (arr2[index]?.residue || 0)
      }));

      setData(newArr);
    } else {
      setData(arr);
    }
  };

  // handlePrint

  const handlePrint = () => {
    if (tableRef?.current) {
      document.body.append(tableRef?.current);
      window.print();
    }
  };

  return (
    <>
      <UniversalModal
        visible={kreditCalculatorVisible}
        onCancel={onCancelCalculator}
        title={
          <div className={styles.container_title}>
            <h1>{t('widgets.Kredit_kalkulyatori')}</h1>
            <span className={styles.container_title_close} onClick={onCancelCalculator}>
              &times;
            </span>
          </div>
        }
        contentClassName={styles.container_content}
      >
        <div className={styles.container_content_calc}>
          <div className={styles.container_content_calc_item}>
            <label htmlFor="amount">{t('widgets.Kredit_miqdori')}</label>
            <div>
              <input
                type="text"
                value={helper.deleteNotNumbersAndParseFloatNumber(String(state?.amount)).string}
                onChange={e => onSelect(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'amount')}
              />
              <Slider max={1000000000} value={state.amount} onChange={value => onSelect(value, 'amount')} step={1} />
            </div>
          </div>
          {state?.amount >= state.amount2 ? (
            <div className={styles.center}>
              <div className={styles.center_item}>
                <div className={styles.container_content_calc_item}>
                  <label htmlFor="amount2">{t('widgets.Asosiy_kredit_summasi')}</label>
                  <div>
                    <Select value={state?.amount2} suffixIcon={<ArrowDownCrmIcon />} onChange={value => onSelect(value, 'amount2')}>
                      <Select.Option value={maxKredit2}>{helper.deleteNotNumbersAndParseFloatNumber(String(maxKredit2)).string}</Select.Option>
                      <Select.Option value={maxKredit}>{helper.deleteNotNumbersAndParseFloatNumber(String(maxKredit)).string}</Select.Option>
                    </Select>
                  </div>
                </div>
                <div className={styles.container_content_calc_item}>
                  <label htmlFor="delay">{t('widgets.Kredit_muddati_oylar')}</label>
                  <div>
                    <input type="text" value={state.delay} onChange={e => onSelect(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'delay')} />
                    <Slider max={240} value={state.delay} onChange={value => onSelect(value, 'delay')} />
                  </div>
                </div>
                <div className={styles.container_content_calc_item}>
                  <label htmlFor="percent">{t('widgets.Foiz_stavkasi')}</label>
                  <div>
                    <input type="text" value={state.percent} onChange={e => onSelect(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'percent')} />
                    <Slider max={40} value={state.percent} onChange={value => onSelect(value, 'percent')} />
                  </div>
                </div>
              </div>
              <div className={styles.center_item}>
                <div className={styles.container_content_calc_item}>
                  <label htmlFor="delay">{t('widgets.Qoshimcha_kredit_summasi')}</label>
                  <div>
                    <input
                      type="text"
                      value={helper.deleteNotNumbersAndParseFloatNumber(String(state2?.amount)).string}
                      onChange={e => onSelect2(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'amount')}
                    />
                    <Slider max={1000000000} value={state2.amount} onChange={value => onSelect2(value, 'amount')} />
                  </div>
                </div>
                <div className={styles.container_content_calc_item}>
                  <label htmlFor="delay">{t('widgets.Kredit_muddati_oylar')}</label>
                  <div>
                    <input type="text" value={state2.delay} onChange={e => onSelect2(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'delay')} />
                    <Slider max={240} value={state2.delay} onChange={value => onSelect2(value, 'delay')} />
                  </div>
                </div>
                <div className={styles.container_content_calc_item}>
                  <label htmlFor="percent">{t('widgets.Foiz_stavkasi')}</label>
                  <div>
                    <input type="text" value={state2.percent} onChange={e => onSelect2(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'percent')} />
                    <Slider max={40} value={state2.percent} onChange={value => onSelect2(value, 'percent')} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.container_content_calc_item} style={{ width: '47%' }}>
                <label htmlFor="delay">{t('widgets.Kredit_muddati_oylar')}</label>
                <div>
                  <input type="text" value={state.delay} onChange={e => onSelect(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'delay')} />
                  <Slider max={240} value={state.delay} onChange={value => onSelect(value, 'delay')} />
                </div>
              </div>
              <div className={styles.container_content_calc_item} style={{ width: '47%' }}>
                <label htmlFor="percent">{t('widgets.Foiz_stavkasi')}</label>
                <div>
                  <input type="text" value={state.percent} onChange={e => onSelect(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number, 'percent')} />
                  <Slider max={40} value={state.percent} onChange={value => onSelect(value, 'percent')} />
                </div>
              </div>
            </>
          )}
        </div>
        <Button onClick={calcHandle} className={styles.container_calculation_btn}>
          {t('home_id.Hisoblash')}
        </Button>
        {data?.length > 0 ? (
          <>
            <Button className={styles.container_calculation_btn} onClick={handlePrint}>
              <PrinterIcon /> {t('contract_id.Chop_etish')}
            </Button>
            <Table
              dataSource={data}
              columns={columns}
              className={styles.container_content_table}
              pagination={{
                className: styles.container_content_table_pagination,
                prevIcon: (
                  <div className={styles.container_content_table_pagination_prev}>
                    <ArrowLeftIcon /> {t('my_calls.Ortga')}
                  </div>
                ),
                nextIcon: (
                  <div className={styles.container_content_table_pagination_next}>
                    {t('profile.Keyingi')} <ArrowRightIcon />
                  </div>
                ),
                onChange(page, pageSize) {
                  setPage(page);
                }
              }}
              scroll={{ y: '40vh' }}
            />
            <Table
              id="docPrint"
              dataSource={data}
              columns={columns}
              className={styles.container_content_table}
              ref={tableRef}
              // indentSize={data?.length}
              pagination={false}
            />
          </>
        ) : (
          ''
        )}
      </UniversalModal>
      <style>
        {kreditCalculatorVisible
          ? `
        @media print{
          #root{
            display:none;
          }
          #docPrint{
            display: initial;
          }
        }
        `
          : ``}
      </style>
    </>
  );
};

export default KreditCalculation;
