import React, { FC } from 'react';

interface IProps {
  color?: string;
}

const CardAddIcon: FC<IProps> = ({ color = '#00A389' }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.83203 5.6665H9.4987" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 11H5.83333" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11H10.1667" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.1654 8.02016V10.7402C15.1654 13.0802 14.572 13.6668 12.2054 13.6668H4.79203C2.42536 13.6668 1.83203 13.0802 1.83203 10.7402V5.26016C1.83203 2.92016 2.42536 2.3335 4.79203 2.3335H9.4987"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.5 4.16675H15.1667" stroke={color} strokeLinecap="round" />
      <path d="M13.332 6.00016V2.3335" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

export default CardAddIcon;
