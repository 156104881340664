import React, { useEffect } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { eventListInitialColumn, leadInitialColumn, taskInitialColumn } from './tableColumns';
import { useTranslation } from 'react-i18next';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { useQueryParam } from '@hooks/useQueryParams';
import { Button, Modal, Select } from 'antd';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import DostsSixVertical from '@icons/DotsSixVertical';

import styles from './tableSettingsModal.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  item?: WidgetModel;
};

const TableSettingsModal: React.FC<Props> = ({ setVisible, visible, selectedItems, setSelectedItems, item }) => {
  const { t } = useTranslation();
  const { params } = useQueryParam();

  const compParams = params['*'];

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (item) {
      if (item?.fields === null) {
        setSelectedItems(() => taskInitialColumn?.map(item => item?.column));
      } else {
        setSelectedItems(JSON.parse(item?.fields!));
      }
    }
  }, [item]);

  const filteredOptions = (
    compParams === 'task' || item?.objectType === 'TASK'
      ? taskInitialColumn
      : compParams === 'event-list' || item?.objectType === 'LOG'
        ? eventListInitialColumn
        : leadInitialColumn
  ).filter(o => !selectedItems.includes(o?.column));

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: 8,
    margin: `0 0 8px 0`,
    background: isDragging ? '#ccc' : '#fff',
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#eee' : '#fff',
    padding: '8px',
    width: '100%'
  });

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(selectedItems, result.source.index, result.destination.index);

    setSelectedItems(reorderedItems);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      width={785}
      title={t('emp_dashboard.Jadval_sozlamalari')}
      footer={false}
      className={styles.tableSettingsModal}
    >
      <div className={styles.tableSettingsModal_body} onMouseDown={e => e.stopPropagation()}>
        <div className={styles.tableSettingsModal_body_columns}>
          <h4>{t('emp_dashboard.Jadvalda_korinadigan_ustunlar')}</h4>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {selectedItems?.length > 0 ? (
                    selectedItems.map((item, index) => (
                      <Draggable key={index} draggableId={String(index)} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div className={styles.tableSettingsModal_body_columns_item}>
                              <div>
                                <span {...provided.dragHandleProps}>
                                  <DostsSixVertical />
                                </span>
                                <span>{t(`crm.${item}`)}</span>
                              </div>
                              <span onClick={() => setSelectedItems(prev => [...prev?.filter(event => event !== item)])}>
                                <TrushIcon />
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <span>{t('emp_dashboard.Jadvalga_ustun_qoshish')}</span>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {selectedItems?.length > 0 && <div className={styles.line}></div>}
        </div>
        <div className={styles.tableSettingsModal_body_select}>
          <span>{t('emp_dashboard.Jadvalga_ustun_qoshish')}</span>
          <Select
            mode="multiple"
            placeholder={`${t('triggers.Tanlang')}...`}
            value={selectedItems}
            onChange={setSelectedItems}
            options={filteredOptions.map(item => ({
              value: item?.column,
              label: t(`crm.${item?.column}`)
            }))}
          />
        </div>
      </div>
      <div className={styles.tableSettingsModal_footer}>
        <Button onClick={handleClose}>{t('home.Yopish')}</Button>
        <Button onClick={handleClose}>{t('home.Saqlash')}</Button>
      </div>
    </Modal>
  );
};

export default TableSettingsModal;
