import { CrmFilterSaveData } from '../models/CrmFilterSaveData';

export const deleteCrmFilter = (data: CrmFilterSaveData | undefined, id: number) => {
  if (data) {
    const dataConvert = {
      active: data?.active === id ? 0 : data?.active,
      filters: data?.filters.filter(item => item?.id !== id)
    };
    return dataConvert?.filters?.length === 0 ? { active: 0, filters: [] } : dataConvert;
  } else {
    return { active: 0, filters: [] };
  }
};
