import { useMutation, useQueryClient } from 'react-query';

import { useAppDispatch } from '../../../hooks/reduxHooks';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { showMessage } from '../../../queries/showMessage';
import { $api } from '../../../service/RequestService';
import { downloadFile } from '../../../utils/helper/downloadFile';
import { getCrmBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { CrmExportType } from '../../../utils/models/CrmExportType';
import { LeadGroup } from '../../../utils/models/LeadGroup';
import { GroupActions } from '../../../utils/models/ListViewCrm';
import { ReasonsRefusal } from '../../../utils/models/ReasonsRefusal';
import { TriggerBody, TriggerBodyPipe } from '../../../utils/models/Trigger';
import { SearchParamsCrm } from '../components/filter/FilterButton';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useCreateEditTrigger(pipeStatusId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { body: TriggerBody; all: boolean }) => {
      const res = await $api.post(`${endPoints.CRM_TRIGGERS_TASK_TRIGGER}?all=${data.all}`, data.body);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.TRIGGERS_LIST, pipeStatusId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateEditTriggerPipe(pipeStatusId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { body: TriggerBodyPipe; all: boolean }) => {
      const res = await $api.post(`${endPoints.CRM_TRIGGERS_PIPE_STATUS_TRIGGER}?all=${data.all}`, data.body);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.TRIGGERS_LIST, pipeStatusId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateEditTriggerManager(pipeStatusId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { body: TriggerBodyPipe; all: boolean }) => {
      const res = await $api.post(`${endPoints.CRM_TRIGGERS_MANAGER_TRIGGER}?all=${data.all}`, data.body);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.TRIGGERS_LIST, pipeStatusId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useCreateEditTriggerCall(pipeStatusId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { body: TriggerBodyPipe; all: boolean }) => {
      const res = await $api.post(`${endPoints.CRM_TRIGGERS_CALL_BROJECT_TRIGGER}?all=${data.all}`, data.body);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.TRIGGERS_LIST, pipeStatusId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteTrigger(pipeStatusId: number, triggerId: number, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${endPoints.CRM_TRIGGERS}/${triggerId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.TRIGGERS_LIST, pipeStatusId]);
        onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useReasonsForRefusalDelete(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.REASONS_FOR_REFUSAL + id);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.REASONS_FOR_REFUSAL]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useReasonsForRefusalCreate(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: ReasonsRefusal[]) => {
      const res = await $api.post(endPoints.REASONS_FOR_REFUSAL, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.REASONS_FOR_REFUSAL]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function usePipeStatusDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { pipeStatusId: number; pipeId: number }) => {
      const res = await $api.delete(`${endPoints.PIPE_STATUS + data.pipeStatusId}?pipeId=${data.pipeId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.PIPE_ALL]);
        qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER]);
      }
    }
  );
}

export function useCreatePipe() {
  const qc = useQueryClient();

  return useMutation(
    async (
      data: {
        name: string;
        orders: number;
        id?: number;
      }[]
    ) => $api.post(endPoints.PIPE_CREATE, data),
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.PIPE_ALL]);
      }
    }
  );
}

export function useDeletePipe() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.PIPE + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.PIPE_ALL]);
      }
    }
  );
}

export function useGroupActionCrmViewList(pipeId: number, searchParams: SearchParamsCrm, onSuccess?: () => void) {
  const qc = useQueryClient();
  const dispatch = useAppDispatch();

  return useMutation(
    async (data: GroupActions) => {
      const res = await $api.post(endPoints.LEAD_GROUP_ACTION, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CRM_LEAD_LIST, pipeId, JSON.stringify(searchParams)]);
        onSuccess && onSuccess();
        // dispatch(setClearListView());
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadGroup() {
  const qc = useQueryClient();

  return useMutation(
    async (data: LeadGroup) => {
      const res = await $api.post(endPoints.LEAD_GROUP_ACTION, data);

      return res.data;
    },
    {
      onSuccess(data) {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CRM_LEAD_LIST]);
        qc.invalidateQueries([queryKeys.LEAD_DUPLICATE]);
        qc.invalidateQueries([queryKeys.CLIENT_VIEW]);
        qc.invalidateQueries([queryKeys.CRM_ONE_LID]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useImportLeads(pipeId: number, searchParams: SearchParamsCrm, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { pipeId: number; fileId: number; tagIds: number[] }) => {
      const res = await $api.post(endPoints.LEAD_IMPORT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, pipeId]);
        qc.invalidateQueries([queryKeys.CRM_LEAD_LIST, pipeId, JSON.stringify(searchParams)]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadImportLeadSample() {
  return useMutation(
    async () => {
      const res = await $api.get(endPoints.LEAD_IMPORT_EXAMPLE, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `crm-sample`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useExportCrm(pipeId: number, searchParams: SearchParamsCrm, onSuccess?: () => void) {
  return useMutation(
    async (type: CrmExportType) => {
      const res = await $api.post(
        endPoints.LEAD_EXPORT,
        {
          filter: {
            ...getCrmBody(searchParams, true),
            pipeId
          },
          type
        },
        {
          responseType: 'blob'
        }
      );

      return res.data;
    },
    {
      onSuccess: data => {
        onSuccess && onSuccess();
        // downloadFile(data, `export`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}
export const useMakeEmployeeAsResPonsibleForPhoneCall = () => useMutation(
  async (data: { onlinePbxConfigId: number; leadResponsibleAutoChange: boolean }) => {
    const res = await $api.post(endPoints.MAKE_EMPLOYEE_RESPONSIBLE, data);

    return res.data;
  },
  {
    retry: false,
    onError: baseErrorHandler,
    onSuccess: success => showMessage(success?.message, success.accept)
  }
);
