import React, { useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './headerCards.module.scss';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useBalanceCardsBoss, useBasicCurrency } from '../../queries/queries';
import { currencyFlags } from '../../utils/constants/currencyFlags';
import { defaultImage } from '../../utils/constants/defaultImage';
import dayjs from 'dayjs';
import queryString from 'query-string';
import MonthleArrearsModal from './monthly-arrears-modal/MonthleArrearsModal';
import { cx } from '@utils/cx';

const HeaderCards = () => {
  const user = useAppSelector(state => state.userReducer.user);
  const { buildings, houses } = useAppSelector(state => state.bossCurrency);

  const convertSearchParam = () => {
    return queryString.stringify({
      houses: houses,
      buildings: buildings
    });
  };

  const { data: currency } = useBasicCurrency();

  const { data, isLoading, isFetching } = useBalanceCardsBoss(convertSearchParam());

  const { t } = useTranslation();

  const time = Number(dayjs().format('HH'));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        {/* First */}
        <div>
          <h2>{time >= 6 && time < 9 ? t('boss_home.Xayrli_tong') : time >= 9 ? t('boss_home.Xayrli_kun') : t('boss_home.Xayrli_kech')}</h2>
          <h1>{user?.fullName}</h1>
        </div>

        {/* Second */}
        <div className={styles.scnd}>
          <div
            className={cx('cursor-pointer', styles.container_card, styles.container_card_orange, styles.scnd_item)}
            onClick={showModal}
          >
            <span>
              {t('boss_home.Oylik_qarzdorlik')} ({currency?.ccy})
            </span>
            <p>{data?.month.toLocaleString('ru')}</p>
          </div>


          <div className={styles.container_card + ' ' + styles.container_card_red + ' ' + styles.scnd_item}>
            <span>
              {t('boss_home.Jami_qarzdorlik')} ({currency?.ccy})
            </span>
            <p>{data?.all.toLocaleString('ru')}</p>
          </div>
          <div className={styles.container_card + ' ' + styles.container_card_green + ' ' + styles.scnd_item}>
            <span>{t('boss_home.Mijozlar_balansi')}</span>
            {data?.balance?.map((item, index) => (
              <div className={styles.container_card_currency} key={index}>
                <div className={styles.container_card_currency_label}>
                  <img src={currencyFlags[`${item.currency.ccy?.toLowerCase()}`] || defaultImage} alt={item.currency.ccy} />
                  <span className={styles.container_item_currency_ccy}>{item.currency.ccy}</span>
                </div>
                <div className={styles.container_card_currency_value}>{item.amount?.toLocaleString('ru')}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <MonthleArrearsModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </Spin>
  );
};

export default HeaderCards;
