import { baseErrorHandler } from "@queries/baseErrorHandler";
import { showMessage } from "@queries/showMessage";
import { $api } from "@service/RequestService";
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { PushNotificationType } from "../utils/model/PushNotificationType";

export function usePostPushNotificationConfig() {
    const qc = useQueryClient();

    return useMutation(
        async (data: PushNotificationType) => {
            const res = await $api.post(endPoints.PUSH_NOTIFICATIONS_CONFIG, data);

            return res.data;
        },
        {
            onSuccess: data => {
                qc.invalidateQueries([queryKeys.PUSH_NOTIFICATIONS_CONFIG])
                showMessage(data.message, data.accept);
            },
            onError: baseErrorHandler
        }
    );
};

// export function usePostPushNotificationConfigClean() {
//     const qc = useQueryClient();
//     const firebaseToken = getLocalStorage('FIREBASE_TOKEN');

//     return useMutation(
//         async (data: PushNotificationType) => {
//             const res = await $api.post(`${endPoints.PUSH_NOTIFICATIONS_CONFIG_CLEAN}?firebaseToken=${firebaseToken}`, data);

//             return res.data;
//         },
//         {
//             onSuccess: data => {
//                 qc.invalidateQueries([queryKeys.PUSH_NOTIFICATIONS_CONFIG])
//                 showMessage(data.message, data.accept);
//             },
//             onError: baseErrorHandler
//         }
//     );
// };
