import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetPaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/service/queries';
import DeleteIconV2 from '@icons/areaarage/DeleteIconV2';
import FileIcon from '@icons/FileIcon';
import UploadFileIcon from '@icons/UploadFileIcon';
import { useUploadFile } from '@queries/mutation';
import { FileObject } from '@utils/models/File';
import { Button, Form, Input, Modal, Radio, RadioChangeEvent, Select, Space, Tooltip } from 'antd';
import { t } from 'i18next';

import { useAppSelector } from '../../../../hooks/reduxHooks';
import { usePaymentConfig } from '../../../../queries/queries';
import { helper } from '../../../../utils/helper/helper';
import { CurrencyItem } from '../../../../utils/models/Currency';
import { useCreatePrepayment } from '../../services/mutation';
import { BookingAdditionModel } from '../../utils/models/BookingAdditionModel';

import styles from './orderV2Modals.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  bookingId: number | undefined;
  currency: CurrencyItem | undefined;
};

interface FileNameProps {
  originalName: string;
}

const { Option } = Select;

const OrderV2LastModal: React.FC<Props> = ({ open, setOpen, bookingId, currency }) => {
  const { data: paymentTypes } = usePaymentConfig();
  const { data: dataPaymentCustomType } = useGetPaymentCustomType();
  const filteredPaymentCustomType = dataPaymentCustomType?.filter(item => item?.status === true);
  const [form] = Form.useForm();
  const { currencyCompany } = useAppSelector(state => state.createContract);
  const [paymentType, setPaymentType] = useState('NAQT');
  const [radioValue, setRadioValue] = useState(1);
  const createPayment = useCreatePrepayment();

  const selectedPaymentType = Form.useWatch('paymentType', form);

  const [files, setFiles] = useState<FileObject[]>();

  function getFile(data: FileObject[]) {
    if (files) {
      setFiles([...files, ...data]);
    } else {
      setFiles(data);
    }
  }

  const uploadFile = useUploadFile(getFile);

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 19000000) {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('files', file);
        uploadFile.mutate(formData);
        e.target.value = '';
      }
    }
  };

  const deleteFile = (id: number) => {
    setFiles(files?.filter(item => item?.id !== id));
  };

  useEffect(() => {
    const documentPaste = (e: ClipboardEvent) => {
      const clipDataArr: any = e.clipboardData?.items;
      const items: any[] = [...clipDataArr];

      items.forEach(item => {
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();

          reader.onload = function (event) {
            const file = blob;
            const formData = new FormData();

            formData.append('files', file);
            uploadFile.mutate(formData);
          }; // data url!
          reader.readAsDataURL(blob);
        }
      });
    };

    document.addEventListener('paste', documentPaste);
    return () => {
      document.removeEventListener('paste', documentPaste);
    };
  }, [uploadFile]);

  // onchange radio group
  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = (value: BookingAdditionModel) => {
    const amountNumber = helper.deleteNotNumbersAndParseFloatNumber(String(value?.amount)).number;

    const fileIds = files?.map(file => file?.id);

    let addOrderData = {
      ...value,
      bookingId: bookingId!,
      amount: amountNumber,
      currencyId: Number(value?.currencyId),
      files: fileIds?.length === 0 ? null : fileIds
    };

    if (selectedPaymentType !== 'OTHERS') {
      addOrderData = { ...addOrderData, files: null };
    }

    const selectedCustomType = filteredPaymentCustomType?.find(item => item?.id === selectedPaymentType);

    if (selectedCustomType) {
      addOrderData.paymentType = 'CUSTOM';
      addOrderData.paymentCustomTypeId = selectedCustomType.id;
    }

    createPayment?.mutateAsync(addOrderData).then(handleClose);
  };

  const toKilobytes = (bytes: number) => (bytes / 1024).toFixed(2);

  const FileName: React.FC<FileNameProps> = ({ originalName }) => {
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return `${text.slice(0, maxLength - 1)}...`;
      }
      return text;
    };

    const getTruncatedFileName = (fileName: string, maxLength: number) => {
      const extensionIndex = fileName.lastIndexOf('.');
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);

      if (name.length > maxLength) {
        return `${truncateText(name, maxLength)}(${extension})`;
      }
      return fileName;
    };

    const truncatedFileName = getTruncatedFileName(originalName, 35);

    return (
      <Tooltip title={originalName} overlayInnerStyle={{ borderRadius: '6px', border: '1px solid black' }}>
        <p className="text-[16px] font-medium text-[#344054]">{truncatedFileName}</p>
      </Tooltip>
    );
  };

  return (
    <Modal visible={open} onCancel={handleClose} title={t('client_balance.Tolov_qilish')} width={548} footer={false} className={styles.orderModal}>
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.orderForm}>
        <Form.Item
          label={
            <label
              style={{
                color: 'var(--philippine-gray)',
                fontSize: '14px'
              }}
            >
              {t('broker.Tolov_summasi')}
            </label>
          }
        >
          <Form.Item
            noStyle
            name="amount"
            rules={[
              {
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject('');
                  }
                  form.setFieldsValue({
                    amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                  });
                  return Promise.resolve();
                }
              },
              {
                required: true,
                message: t('createOrder.Boshlangich_tolov_kiritilmagan')
              }
            ]}
          >
            <Input className="customInput" />
          </Form.Item>
        </Form.Item>
        <div className={styles.balansType}>
          <Form.Item
            label={
              <label
                style={{
                  color: 'var(--philippine-gray)',
                  fontSize: '14px'
                }}
              >
                {t('contract_id.Tolov_turi')}
              </label>
            }
            name="paymentType"
            rules={[{ message: '', required: true }]}
          >
            <Select className="customSelect" value={paymentType} onChange={e => setPaymentType(e)}>
              {paymentTypes?.types?.map((item) => (
                <Option key={item} value={item}>
                  {t(`home.${item}`)}
                </Option>
              ))}
              {filteredPaymentCustomType?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <label
                style={{
                  color: 'var(--philippine-gray)',
                  fontSize: '14px'
                }}
              >
                {t('create_payment.Valyuta')}
              </label>
            }
            name="currencyId"
            rules={[{ message: '', required: true }]}
            initialValue={currency?.id}
          >
            <Select className="customSelect">
              {currencyCompany?.map((item, index) => (
                <Option value={item?.id} key={index}>
                  {item?.ccy}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item name="refundablePayment" rules={[{ message: '', required: true }]} initialValue={false}>
          <Radio.Group className={styles.radioGroup} value={radioValue} onChange={onChangeRadio}>
            <Space direction="vertical">
              <Radio value={true}>
                <div className={styles.paymentRadio}>
                  <span>{t('createOrder.Tolov_qaytariladi')}</span>
                  <span>{t('createOrder.Tolov_qaytadi')}</span>
                </div>
              </Radio>
              <Radio value={false}>
                <div className={styles.paymentRadio}>
                  <span>{t('createOrder.Tolov_qaytarilmaydi')}</span>
                  <span>{t('createOrder.Tolov_qaytmaydi')}</span>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        {selectedPaymentType === 'OTHERS' && (
          <div>
            <label
              htmlFor="chatFile"
              className="flex cursor-pointer items-center justify-center rounded-xl border-[1px] border-[#EAECF0] px-[24px] py-[16px]"
              style={{ border: '1px solid #EAECF0' }}
            >
              <div>
                <label htmlFor="chatFile" className="ml-auto mr-auto flex items-center justify-center">
                  {uploadFile.isLoading && <LoadingOutlined />}
                  <span className="cursor-pointer">
                    <UploadFileIcon />
                  </span>
                  <input type="file" id="chatFile" style={{ display: 'none' }} onChange={e => changeFile(e)} />
                </label>
                <p className="ml-auto mr-auto text-[12px] font-semibold text-[#1E90FF]">{t('home.Fayl_yuklash_uchun_bosing')}</p>
                <div className="mt-1px] *Maksimal fayl hajmi 19MB*">
                  <p className="text-[9px] font-medium text-[#2b2828bd]">*{t('home.maximal_size')} 19MB*</p>
                </div>
              </div>
            </label>

            <div>
              {files?.map(item => (
                <div key={item?.id} className="mt-3 flex items-center justify-between rounded-xl bg-[#F9FAFB] p-[16px]" style={{ border: '1px solid #E5E7EB' }}>
                  <div className="flex items-center gap-3">
                    <span className="flex items-center justify-center rounded-[10px] border-[1px] bg-[#EFF1F5] p-[8px]" style={{ border: '1px solid #E5E7EB' }}>
                      <FileIcon />
                    </span>
                    <div>
                      <FileName originalName={item?.originalName} />
                      <p className="text-[16px] font-medium text-[#98A2B3]">{item?.size ? `${toKilobytes(item?.size)} KB` : 'N/A'}</p>
                    </div>
                  </div>
                  <span className="cursor-pointer" onClick={() => deleteFile(item?.id)}>
                    <DeleteIconV2 />
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={styles.orderForm_footer}>
          <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
          <Button htmlType="submit" loading={createPayment?.isLoading}>
            {t('client_balance.Tolov_qilish')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default OrderV2LastModal;
