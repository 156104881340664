import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import { useQueryParam } from '@hooks/useQueryParams';
import TaskListContainer from '@pages/oneLid/components/taskList/TaskListContainer';
import { useClientEventData } from '@pages/oneLid/services/queries';

import ClientInfo from '../client-info/ClientInfo';

import styles from './clientPage.module.scss';

const ClientPage = () => {
  const { params } = useQueryParam<any, any>();

  const { data: event, isLoading } = useClientEventData(Number(params?.idClient));

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className={styles.container}>
        <ClientInfo />
        <div className={styles.container_events}>
          <TaskListContainer type="client" data={event} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default ClientPage;
