import React, { useState, useEffect, useRef } from 'react';
import { Collapse } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useAppSelector } from '../../../../../../hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import { useCrmFields } from '../service/queries';
import {
  addData,
  fieldDragDrop,
  mainFullWidth,
  mainHeight,
  mainLeftBackgroundColor,
  mainLeftWidth,
  mainRightBackgroundColor
} from '../../../../../../store/reducers/TextEditorSlice';
import { BASE_URL } from '../../../../../../service/RequestService';
import { FileObject } from '../../../../../../utils/models/File';
import HeaderSettings from './HeaderSettings';
import DraggingInput from '../draggingInput/DraggingInput';
import FormBtn from '../formBtm/FormBtn';
import BottomPopover from '../popover/BottomPopover';
import ResizeIcon from '../../../../../../assets/icons/webforma/ResizeIcon';
import TextEditor from '../textEditor/TextEditor';

import styles from '../dragDrop.module.scss';

const { Panel } = Collapse;

const DragAndDrop = () => {
  const { data } = useCrmFields();
  const [rightBgColor, setRightBgColor] = useState<string>('rgb(213, 236, 251)');
  const [leftBgColor, setLeftBgColor] = useState<string>('rgb(213, 236, 251)');
  const [getDx, setGetDx] = useState(0);
  const dispatch = useDispatch();
  const { leftHeader, main, fields, leftBgImgBinary, rightBgImgBinary, submitButton } = useAppSelector(state => state.textEditorReducer);
  const { header, paragraph } = leftHeader;

  const ref = useRef<HTMLDivElement | null>(null);
  const refRight = useRef<HTMLDivElement | null>(null);
  const refBottom = useRef<HTMLDivElement | null>(null);

  const leftRef = useRef<HTMLDivElement | null>(null);
  const leftRightRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (data) {
      dispatch(addData(data?.filter(item => item?.type !== 'LOCATION')));
    }
    // eslint-disable-next-line
  }, [data]);

  //function for resize element
  useEffect(() => {
    const reseziableEle = ref.current!;
    const styles = window.getComputedStyle(reseziableEle);
    let minWidth = parseInt(styles.minWidth, 10);
    let height = parseInt(styles.height, 10);
    let x = 0;
    let y = 0;

    //right resize
    const onMouseMoveRightResize = (event: any) => {
      const dx = event.clientX - x;
      x = event.clientX;
      minWidth = minWidth >= 1100 ? 1099 : minWidth + dx;
      reseziableEle.style.minWidth = `${minWidth}px`;
      dispatch(mainFullWidth(minWidth));
      setGetDx(dx);
    };

    const onMouseUpRightResize = () => {
      document.removeEventListener('mousemove', onMouseMoveRightResize);
    };

    const onMouseDownRightResize = (event: any) => {
      x = event.clientX;
      reseziableEle.style.left = styles.left;
      reseziableEle.style.right = '';
      document.addEventListener('mousemove', onMouseMoveRightResize);
      document.addEventListener('mouseup', onMouseUpRightResize);
    };

    //top resize
    const onMouseMoveTopResize = (event: any) => {
      const dy = event.clientY - y;
      y = event.clientY;
      height = height + dy;
      reseziableEle.style.height = `${height}px`;
      dispatch(mainHeight(height));
    };

    const onMouseUpTopResize = () => {
      document.removeEventListener('mousemove', onMouseMoveTopResize);
    };

    const onMouseDownTopResize = (event: any) => {
      y = event.clientY;
      const styles = window.getComputedStyle(reseziableEle);
      reseziableEle.style.bottom = styles.bottom;
      reseziableEle.style.top = '';
      document.addEventListener('mousemove', onMouseMoveTopResize);
      document.addEventListener('mouseup', onMouseUpTopResize);
    };

    const resizerRight = refRight.current!;
    resizerRight.addEventListener('mousedown', onMouseDownRightResize);

    const resizerTop = refBottom.current!;
    resizerTop.addEventListener('mousedown', onMouseDownTopResize);

    return () => {
      resizerRight.removeEventListener('mousedown', onMouseDownRightResize);
      resizerTop.removeEventListener('mousedown', onMouseDownTopResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const resezableEl = leftRef.current!;
    const styles = window.getComputedStyle(resezableEl);
    let minWidth = parseInt(styles.minWidth, 10);
    let x = 0;

    const onMouseMoveCenterResize = (event: any) => {
      const dx = event.clientX - x;
      x = event.clientX;
      minWidth = minWidth >= 551 ? 550 : minWidth + dx;

      resezableEl.style.minWidth = `${minWidth}px`;

      dispatch(mainLeftWidth(minWidth));
    };
    const onMouseUpCenterResize = () => {
      document.removeEventListener('mousemove', onMouseMoveCenterResize);
    };

    const onMouseDownCenterResize = (event: any) => {
      x = event.clientX;
      resezableEl.style.left = styles.left;
      resezableEl.style.right = '';
      document.addEventListener('mousemove', onMouseMoveCenterResize);
      document.addEventListener('mouseup', onMouseUpCenterResize);
    };

    const resizerCenter = leftRightRef.current!;
    resizerCenter.addEventListener('mousedown', onMouseDownCenterResize);
    return () => {
      resizerCenter.removeEventListener('mousedown', onMouseDownCenterResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (main.fullWidth && ref.current) {
      ref.current!.style.minWidth = main.fullWidth + 'px';
    }
    if (main.height && ref.current) {
      ref.current!.style.height = main.height + 'px';
    }
    // eslint-disable-next-line
  }, [main.fullWidth, main.height, ref.current]);

  useEffect(() => {
    if (main.leftWidth && leftRef.current) {
      leftRef.current!.style.minWidth = main.leftWidth + 'px';
    }
    // eslint-disable-next-line
  }, [main.leftWidth, leftRef.current]);

  useEffect(() => {
    dispatch(mainLeftBackgroundColor(leftBgColor));
    dispatch(mainRightBackgroundColor(rightBgColor));
    // eslint-disable-next-line
  }, [leftBgColor, rightBgColor]);

  const leftBgImg = (backImg: (File & FileObject) | undefined, key: keyof typeof main) => {
    if (backImg) {
      return backImg?.absolutePath ? BASE_URL + '/' + backImg?.absolutePath : URL.createObjectURL(backImg);
    } else {
      return main[key] ? BASE_URL + '/' + main[key] : '';
    }
  };

  //function for handle stop propagation
  const handlePropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      <HeaderSettings />
      <div className={styles.container}>
        <DragDropContext onDragEnd={result => dispatch(fieldDragDrop(result))}>
          {Object.entries(fields).map(([id, column]) => {
            const col1 = column?.name === 'board1';
            const col2 = column?.name === 'board2';
            const columnsComponent = column?.items?.map((item, index) => {
              return (
                <Draggable key={id + '_' + item.id + '_' + index} draggableId={id + '_' + item.id + '_' + index} index={index}>
                  {provided => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={col1 ? styles.element2 : styles.element}
                        style={{
                          ...provided.draggableProps.style
                        }}
                      >
                        {col1 ? (
                          <div className={styles.board1}>
                            <DraggingInput
                              type={item.type}
                              disabled={item.disabled}
                              label={item.label}
                              id={item.id}
                              placeholder={item?.placeHolder}
                              index={index}
                              required={item.required}
                              message={item.requiredMessage}
                            />
                          </div>
                        ) : (
                          item.name
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              );
            });

            return (
              <div
                key={id}
                className={col1 ? styles.wrapperLeft : styles.wrapperRight}
                style={{
                  borderRadius: col1 ? `${main.borderRadius}px` : '',
                  borderColor: main.borderColor,
                  boxShadow: col1 && main.boxShadow ? '0 4px 8px rgba(0, 0, 0, 0.5)' : ''
                }}
                ref={col1 ? ref : null}
                onMouseDown={e => {
                  col2 && handlePropagation(e);
                }}
                onMouseLeave={e => {
                  col2 && handlePropagation(e);
                }}
                onMouseMove={e => {
                  col2 && handlePropagation(e);
                }}
              >
                {col1 && (
                  <>
                    <div className={styles.wrapperLeft_resizeB} ref={refBottom}>
                      <ResizeIcon />
                    </div>
                    <div className={styles.wrapperLeft_resizeR} ref={refRight}>
                      <ResizeIcon />
                    </div>
                    <div
                      className={styles.backImage}
                      style={{
                        background: main.leftBackgroundColor,
                        borderTopLeftRadius: `${main.borderRadius}px`,
                        borderBottomLeftRadius: `${main.borderRadius}px`
                      }}
                      ref={leftRef}
                    >
                      <div className={styles.backImage_resizeWidth} ref={leftRightRef}>
                        <ResizeIcon />
                      </div>
                      {leftBgImg(leftBgImgBinary, 'leftBackgroundImage') && (
                        <img
                          src={leftBgImg(leftBgImgBinary, 'leftBackgroundImage')}
                          style={{
                            borderTopLeftRadius: `${main.borderRadius}px`,
                            borderBottomLeftRadius: `${main.borderRadius}px`
                          }}
                          alt="err"
                        />
                      )}
                      <BottomPopover bgColor={leftBgColor} setBgColor={setLeftBgColor} type="leftImgBinary" />
                      <div className={styles.wrapperLeftHeader}>
                        <TextEditor style={{ ...header }} type="header" children={header.content} />
                        <TextEditor style={{ ...paragraph }} type="paragraph" children={paragraph.content} />
                      </div>
                    </div>
                  </>
                )}
                <Droppable droppableId={id} key={id} isDropDisabled={col2 ? true : false}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={col1 ? styles.board : styles.board2}
                        style={{
                          background: snapshot.isDraggingOver ? 'lightblue' : col1 ? rightBgColor : '',
                          borderColor: main.borderColor,
                          borderTopRightRadius: `${main.borderRadius}px`,
                          borderBottomRightRadius: `${main.borderRadius}px`
                        }}
                      >
                        {col1 && (
                          <>
                            <BottomPopover bgColor={rightBgColor} setBgColor={setRightBgColor} type="rightImgBinary" />
                          </>
                        )}
                        {col1 && leftBgImg(rightBgImgBinary, 'rightBackgroundImage') && (
                          <img className={styles.board_bgImage} src={leftBgImg(rightBgImgBinary, 'rightBackgroundImage')} alt="err" />
                        )}
                        {col2 ? (
                          <>
                            <Collapse>
                              <Panel header="Lidlar" key={1}>
                                {columnsComponent}
                              </Panel>
                            </Collapse>
                          </>
                        ) : (
                          columnsComponent
                        )}
                        {col1 && (
                          <div
                            className={styles.mainButtons}
                            style={{
                              justifyContent: submitButton.justifyContent
                            }}
                          >
                            <FormBtn getDx={getDx} />
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </>
  );
};

export default DragAndDrop;
