import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';

export const usePaylovConfigDelete = () => {
  const qc = useQueryClient();

  return useMutation(
    async (id?: number | null) => {
      const res = await $api?.delete(`/v1/paylov/config/${id}`);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.PAYLOV_CONFIG]);
      }
    }
  );
};
