import React, { useRef, useState } from 'react';
import { Form, FormInstance, Input, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../assets/icons/SearchIcon';
import { useClientBalanceSearch } from '../../queries/queries';
import { helper } from '../../utils/helper/helper';
import { SearchClientBalance } from '../../utils/models/SearchClientBalance';

import { FormIPaymentCreate } from './PaymentCreateModal';
import PopoverClient from './PopoverClient';

interface Props {
  form: FormInstance<FormIPaymentCreate>;
  residue: number;
  setClientId: (id: number) => void;
}

const BalancePaymentInputs: React.FC<Props> = ({ form, setClientId, residue }) => {
  const timer = useRef<NodeJS.Timeout>();
  const [search, setSearch] = useState('');
  const balance = useRef(0);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { t } = useTranslation();
  const handlePopover = (isOpen: boolean) => {
    setPopoverOpen(isOpen);
  };

  const { isLoading, data } = useClientBalanceSearch(search);

  const selectClient = (client: SearchClientBalance) => {
    form.setFieldsValue({
      name: client.name
    });
    balance.current = client.balance;
    setClientId(client.id || -1);
    handlePopover(false);
  };

  const handleSearchClient = (e: any) => {
    setClientId(-1);
    balance.current = 0;
    if (e.target.value && e.target.value.length >= 3) {
      setPopoverOpen(true);
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setSearch(e.target.value);
      }, 300);
    }
  };

  return (
    <div>
      <Form.Item label={<p style={{ color: 'var(--philippine-gray)', fontSize: '14px' }}>{t('create_payment.Mijoz')}</p>}>
        <Popover
          content={<PopoverClient selectClient={selectClient} isLoading={isLoading} data={data?.pages?.map(data => data?.data) as any} />}
          overlayClassName="client_search_popover"
          placement="bottom"
          trigger="click"
          visible={popoverOpen}
          onVisibleChange={handlePopover}
        >
          <Form.Item name="name" noStyle>
            <Input suffix={<SearchIcon />} placeholder={t('create_payment.Mijoz_qidirish')} onChange={handleSearchClient} className="customInput" />
          </Form.Item>
        </Popover>
      </Form.Item>
      <Form.Item
        label={
          <p
            style={{
              color: 'var(--philippine-gray)',
              fontSize: '14px'
            }}
          >
            {t('create_payment.Tolov_raqami')}
          </p>
        }
      >
        <Form.Item
          noStyle
          name="number"
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <Input className="customInput" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={
          <p
            style={{
              color: 'var(--philippine-gray)',
              fontSize: '14px'
            }}
          >
            {t("create_payment.To'lov_summasi")}
          </p>
        }
      >
        <Form.Item
          noStyle
          name="amount"
          rules={[
            {
              required: true,
              validator: (rule, value) => {
                if (value === undefined || value === null) {
                  return Promise.reject(t('create_payment.sana_kiritilmagan'));
                }
                const amount = helper.deleteNotNumbersAndParseFloatNumber(value);

                if (amount && amount.number > residue) {
                  form.setFieldsValue({
                    amount: amount.string
                  });
                  return Promise.reject(t('create_payment.Summa_qoldiq_summadan_katta'));
                }
                if (amount && amount.number > balance.current) {
                  form.setFieldsValue({
                    amount: amount.string
                  });
                  return Promise.reject(t('create_payment.Summa_mijoz_balansidan_katta'));
                }
                form.setFieldsValue({
                  amount: amount.string
                });
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input className="customInput" />
        </Form.Item>
      </Form.Item>
    </div>
  );
};

export default BalancePaymentInputs;
