import React from 'react';

const DashboardVidget5 = () => {
  return (
    <svg width="88" height="111" viewBox="0 0 88 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 12C0.75 5.64873 5.89873 0.5 12.25 0.5H75.75C82.1013 0.5 87.25 5.64873 87.25 12V69C87.25 91.9198 68.6698 110.5 45.75 110.5H12.25C5.89872 110.5 0.75 105.351 0.75 99V12Z"
        stroke="#00A389"
      />
      <path
        d="M6.25 14C6.25 9.58172 9.83172 6 14.25 6H73.75C78.1683 6 81.75 9.58172 81.75 14V65C81.75 87.0914 63.8414 105 41.75 105H14.25C9.83172 105 6.25 101.418 6.25 97V14Z"
        fill="#E7F9F6"
      />
      <path d="M22.25 26H65.75" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
      <path
        d="M40.3234 52.7278L40.6271 52.3306L40.2568 52.0475L39.9484 52.397L40.3234 52.7278ZM46.3835 57.3621L46.0798 57.7592L46.4275 58.0251L46.7371 57.7156L46.3835 57.3621ZM50.8465 50.2324C50.8465 51.7052 52.0404 52.8991 53.5132 52.8991C54.9859 52.8991 56.1798 51.7052 56.1798 50.2324C56.1798 48.7597 54.9859 47.5658 53.5132 47.5658C52.0404 47.5658 50.8465 48.7597 50.8465 50.2324ZM30.0038 65.179L40.6983 53.0586L39.9484 52.397L29.254 64.5174L30.0038 65.179ZM40.0196 53.125L46.0798 57.7592L46.6873 56.9649L40.6271 52.3306L40.0196 53.125ZM46.7371 57.7156L53.8667 50.586L53.1596 49.8789L46.03 57.0085L46.7371 57.7156Z"
        fill="#00A389"
      />
      <path d="M26.7773 38.8242V72.3335" stroke="#7BBFB4" />
      <path d="M22.5 68.4121H61" stroke="#7BBFB4" />
      <path d="M22.25 85H45.25" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
    </svg>
  );
};

export default DashboardVidget5;
