import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './saveButtons.module.scss';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { useQueryClient } from 'react-query';
import { queryNames } from '../../../../../utils/constants/queryNames';
import { useFieldsSave } from '../../../../../queries/mutation';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useSaveNotTaskLeadConf } from '../../../services/mutation';

const ConfigSaveButtons = () => {
  const { params } = useQueryParam<null, { id: string }>();
  const { isChangeFields, led, contact, isChangeNotTaskConf, id, autoRemindTime, autoRemindNotTask, notTaskPipes, notChangeStepStatus } =
    useAppSelector(state => state.crmField);

  const { t } = useTranslation();

  const saveFields = useFieldsSave(Number(params.id));
  const saveNotTaskLeadConf = useSaveNotTaskLeadConf();

  const qc = useQueryClient();

  // handle click cancel
  const handleClickCancel = () => {
    qc.invalidateQueries([queryNames.CRM_FIELDS]);
    qc.invalidateQueries([queryNames.LEAD_NOT_TASK]);
  };

  // handle save
  const handleSave = () => {
    if (isChangeFields) {
      saveFields.mutate([...led, ...contact]);
    }
    if (isChangeNotTaskConf) {
      saveNotTaskLeadConf.mutate({
        id,
        notChangeStepStatus,
        notTaskPipes,
        autoRemindNotTask,
        autoRemindTime
      });
    }
  };

  if (!isChangeFields && !isChangeNotTaskConf) return null;

  return (
    <div className={styles.container}>
      <Button loading={saveFields.isLoading || saveNotTaskLeadConf.isLoading} onClick={handleSave} className={styles.container_save} type={'primary'}>
        {t('one_lid.Saqlash')}
      </Button>
      <Button onClick={handleClickCancel} className={styles.container_cancel}>
        {t('one_lid.Bekor_qilish')}
      </Button>
    </div>
  );
};

export default ConfigSaveButtons;
