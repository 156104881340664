import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  size?: number;
  strokeWidth?: number;
  stroke?: string;
}

const CloseCrmIcon: React.FC<Props> = ({ className, onClick, size = 17, strokeWidth = 1, stroke = '#FF6565' }) => {
  return (
    <svg onClick={onClick} className={className} width={size} height={size} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2812 4.21875L3.71875 13.7812" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} />
      <path d="M13.2812 13.7812L3.71875 4.21875" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} />
    </svg>
  );
};

export default CloseCrmIcon;
