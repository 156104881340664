import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const MicOffIcon: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="22.5" r="22.5" fill="#DFDEDE" />
      <g clipPath="url(#clip0_9944_45929)">
        <path
          d="M22.0354 16.7952C22.0354 16.2648 22.4694 15.8309 22.9997 15.8309C23.5301 15.8309 23.964 16.2648 23.964 16.7952L23.956 19.9372L25.4104 21.3756V16.8756C25.4104 15.5416 24.3337 14.4648 22.9997 14.4648C21.7622 14.4648 20.7578 15.397 20.6212 16.5943L22.0354 18.0086V16.7952ZM28.6247 21.697H27.2586C27.2586 22.1631 27.1783 22.605 27.0417 23.0148L28.0622 24.0354C28.4158 23.3282 28.6247 22.5327 28.6247 21.697ZM16.9006 15.1559L15.7676 16.289L20.589 21.1104V21.697C20.589 23.0309 21.6658 24.1077 22.9997 24.1077C23.1845 24.1077 23.3533 24.0836 23.522 24.0434L24.856 25.3773C24.2854 25.6425 23.6506 25.7952 22.9997 25.7952C20.7819 25.7952 18.7408 24.1077 18.7408 21.697H17.3747C17.3747 24.4372 19.5604 26.7032 22.1962 27.097V29.7327H23.8033V27.097C24.5345 26.9925 25.2256 26.7354 25.8524 26.3738L29.2274 29.7488L30.3604 28.6157L16.9006 15.1559Z"
          fill="#323232"
        />
      </g>
      <defs>
        <clipPath id="clip0_9944_45929">
          <rect width="19.2857" height="19.2857" fill="white" transform="translate(13.3569 12.8574)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicOffIcon;
