import React from 'react';

const ArrowDownIconContact = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2807 6.46655L8.93404 10.8132C8.4207 11.3266 7.5807 11.3266 7.06737 10.8132L2.7207 6.46655"
        stroke="#898F98"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownIconContact;
