import { QueryClient } from 'react-query';

import { MainPaths } from '../routing/main/mainPaths';
import { rootPaths } from '../routing/rootPaths';
import { queryNames } from '../utils/constants/queryNames';
import { BronIdOrFlatIds } from '../utils/models/BronIdOrFlatIds';

import { showMessage } from './showMessage';

export const createClientSuccess = (data: any, qc: QueryClient, orderId?: string, push?: any, pathName?: string) => {
  if (orderId?.includes(BronIdOrFlatIds.BRON_ID) || orderId?.includes(BronIdOrFlatIds.FLAT_IDS)) {
    push(`${pathName}/clientId=${data?.data?.id}${MainPaths.HOUSE_INFO}`);
    qc.invalidateQueries([queryNames.CLIENT, queryNames.BOOKED, orderId?.split('=')[1]]);
  } else {
    push(rootPaths.MAIN + MainPaths.CLIENT);
  }
  showMessage(data.message, data.accept);
};
