import React from 'react';

const LeftCheveronCircle = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="47.5" y="0.5" width="47" height="47" rx="23.5" transform="rotate(90 47.5 0.5)" fill="white" />
      <path
        d="M19.6472 23.9918C19.6487 23.8185 19.7175 23.6526 19.839 23.529L26.3844 16.9835C26.5079 16.8643 26.6732 16.7983 26.8448 16.7998C27.0164 16.8013 27.1806 16.8702 27.302 16.9915C27.4233 17.1129 27.4922 17.277 27.4937 17.4487C27.4951 17.6203 27.4292 17.7856 27.3099 17.9091L21.2272 23.9918L27.3099 30.0745C27.3725 30.1348 27.4223 30.2071 27.4566 30.2869C27.4909 30.3668 27.509 30.4527 27.5097 30.5396C27.5105 30.6265 27.4939 30.7127 27.461 30.7931C27.4281 30.8736 27.3795 30.9466 27.3181 31.0081C27.2566 31.0695 27.1835 31.1182 27.1031 31.1511C27.0226 31.184 26.9364 31.2005 26.8495 31.1998C26.7626 31.199 26.6767 31.181 26.5969 31.1467C26.517 31.1124 26.4448 31.0625 26.3844 31L19.839 24.4545C19.7175 24.331 19.6487 24.165 19.6472 23.9918Z"
        fill="#00A389"
      />
      <rect x="47.5" y="0.5" width="47" height="47" rx="23.5" transform="rotate(90 47.5 0.5)" stroke="#00A389" />
    </svg>
  );
};

export default LeftCheveronCircle;
