import { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { colors } from '@utils/constants/showRoomColors';
import { Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetEmployeeDiscount } from '../../services/queries';
import { BossDiscountParam } from '../sunburst-header/SunburstHeader';

import styles from './monthDiscount.module.scss';

const EmployeeDiscount = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const { monthType, monthCurrency, discountBuildings, discountHouses } = useAppSelector(state => state.bossCurrency);
  const { searchParams } = useQueryParam<BossDiscountParam, any>();

  const { data: employee, isLoading } = useGetEmployeeDiscount(
    searchParams?.monthStart,
    searchParams?.monthFinish,
    monthCurrency,
    discountBuildings!,
    discountHouses!
  );

  const totalCount = chartData?.reduce((acc, curr: any) => acc + curr[monthType as keyof typeof curr], 0);

  useEffect(() => {
    if (employee && employee?.length > 0) {
      const newTask: any[] = employee?.map((item, index) => ({
        ...item,
        overallAmount: item?.totalAmount,
        color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)]
      }));

      setChartData(newTask);
    } else {
      setChartData([]);
    }
  }, [employee, monthCurrency, searchParams?.monthFinish, monthType]);

  return (
    <>
      {chartData?.length > 0 && (
        <div className={styles.container_second}>
          <h3>{t('boss_complex.Xodimlar_kesimida_chegirmalar')}</h3>
          {chartData?.map((item: any, index: number) => {
            const calcWidth = (100 / totalCount!) * item[monthType as keyof typeof item];

            return (
              <Spin spinning={isLoading} key={index}>
                <div className={styles.container_second_item}>
                  <div className={styles.container_second_item_name}>{item?.fullName}</div>
                  <Tooltip
                    overlay={
                      <div>
                        {item[monthType as keyof typeof item]?.toLocaleString('ru')}
                        {monthType === 'overallAmount' ? ` ${monthCurrency}` : ''}
                      </div>
                    }
                  >
                    <div
                      style={{
                        width: `${calcWidth}%`,
                        backgroundColor: item?.color
                      }}
                      className={styles.container_second_item_chart}
                    >
                      {`${
                        calcWidth > 10
                          ? item[monthType as keyof typeof item]?.toLocaleString('ru')
                          : monthType !== 'overallAmount'
                            ? item[monthType as keyof typeof item]?.toLocaleString('ru')
                            : ''
                      } ${calcWidth > 10 && monthType === 'overallAmount' ? ` ${monthCurrency}` : ''}`}
                    </div>
                  </Tooltip>
                </div>
              </Spin>
            );
          })}
        </div>
      )}
    </>
  );
};

export default EmployeeDiscount;
