import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse, Space, Spin } from 'antd';
import { useQueryClient } from 'react-query';

import ColapseIcon from '../../../../../assets/icons/ColapseIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useBuildingCompactByHouseId } from '../../../../../queries/queries';
import { SearchParamsContract } from '../../../Contract';

interface Props {
  id: number;
  name: string;
}

const { Panel } = Collapse;

const HouseItem: React.FC<Props> = ({ name, id }) => {
  const [activeKeys, setActiveKeys] = useState<string | number | (string | number)[] | undefined>([]);
  const [buildingIds, setBuildingIds] = useState<string[]>([]);
  const qc = useQueryClient();

  const activekey = Array.isArray(activeKeys) ? activeKeys[0] : activeKeys;
  const { searchParams, remove, appendMultiple } = useQueryParam<SearchParamsContract, any>();
  const isHouseIndeterminate = Array.isArray(searchParams?.houseIndeterminate)
    ? searchParams.houseIndeterminate?.includes(String(id))
    : searchParams.houseIndeterminate === String(id);

  const { data: buildings, isLoading } = useBuildingCompactByHouseId(Number(activekey || 0), {
    onSuccess: data => {
      if (!isHouseIndeterminate) {
        data?.forEach(building => {
          remove('buildings', String(building?.id));
        });
        setBuildingIds((buildings || [])?.map(item => String(item?.id)));
      }
    }
  });

  const changeHouse = (id: string) => {
    if (!activekey) {
      setActiveKeys([id]);
    }

    if (isHouseIndeterminate) {
      buildings?.forEach(building => {
        remove('buildings', String(building?.id));
      });
      setBuildingIds((buildings || [])?.map(item => String(item?.id)));
    } else if (Array.isArray(searchParams.houses)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (searchParams.houses?.includes(id)) {
        remove('houses', id);
        buildings?.forEach(building => {
          remove('buildings', String(building?.id));
        });
        setBuildingIds([]);
      } else {
        appendMultiple('houses', id);
        setBuildingIds([...(buildings || [])?.map(item => String(item.id))]);
      }
    } else if (searchParams.houses === id) {
      remove('houses', id);
      buildings?.forEach(building => {
        remove('buildings', String(building?.id));
      });
      setBuildingIds([]);
    } else {
      appendMultiple('houses', id);
      setBuildingIds([...(buildings || [])?.map(item => String(item.id))]);
    }

    if (isHouseIndeterminate) {
      remove('houseIndeterminate', id);
    }
  };

  const changeBuilding = (buildingId: string, checked: boolean) => {
    const houseId = String(id);

    if (Array.isArray(searchParams.houses)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (searchParams.houses?.includes(houseId)) {
        // remove('houses', houseId)
      } else {
        appendMultiple('houses', houseId);
      }
    } else if (searchParams.houses === houseId) {
      // remove('houses', houseId);
    } else {
      appendMultiple('houses', houseId);
    }

    buildingIds?.forEach(id => {
      remove('buildings', id);
    });

    if (buildingIds?.includes(buildingId)) {
      setBuildingIds(prev => prev?.filter(id => id !== buildingId));

      if (!isHouseIndeterminate) {
        appendMultiple('houseIndeterminate', houseId);
      }

      buildingIds
        ?.filter(id => id !== buildingId)
        ?.forEach(id => {
          appendMultiple('buildings', id);
        });
      if (buildingIds.length === 1) {
        remove('houses', houseId);
        remove('houseIndeterminate', houseId);
      }
    } else {
      if ([...buildingIds, buildingId].length === buildings?.length) {
        remove('houseIndeterminate', houseId);
      } else {
        appendMultiple('houseIndeterminate', houseId);
      }

      setBuildingIds(prev => [...prev, buildingId]);
      [...buildingIds, buildingId]?.forEach(id => {
        appendMultiple('buildings', id);
      });
    }
  };

  useEffect(() => {
    const houseId = String(id);

    const isHouse = Array.isArray(searchParams.houses) ? searchParams.houses.includes(houseId) : searchParams?.houses === houseId;

    if (isHouse) {
      let buildingIds = (Array.isArray(searchParams.buildings) ? [...searchParams.buildings] : [searchParams.buildings]) as string[];

      buildingIds = buildingIds.filter(id => buildings?.some(v => String(v?.id) === id));

      setBuildingIds(buildingIds?.length ? buildingIds : (buildings || [])?.map(item => String(item?.id)));
    } else {
      // setBuildingIds([]);
    }
  }, [buildings]);

  useEffect(() => {
    const houseId = String(id);

    const isHouse = Array.isArray(searchParams.houses) ? searchParams.houses.includes(houseId) : searchParams?.houses === houseId;

    if (!isHouse && buildingIds.length) {
      setBuildingIds([]);
    }
  }, [searchParams.houses, buildingIds]);

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={[searchParams.houses?.includes(id.toString()) ? id : '0']}
      activeKey={activeKeys}
      expandIconPosition="right"
      onChange={setActiveKeys}
      expandIcon={({ isActive }) => (
        <span
          style={{
            transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
            transition: '0.3s'
          }}
        >
          <ColapseIcon />
        </span>
      )}
    >
      <Panel
        header={
          <div className="house_header">
            <Checkbox
              checked={Array.isArray(searchParams.houses) ? searchParams.houses.includes(String(id)) : searchParams.houses === String(id)}
              indeterminate={isHouseIndeterminate}
              onClick={e => e.stopPropagation()}
              onChange={e => {
                e.stopPropagation();
                changeHouse(String(id));
              }}
            />
            <span className="house_header_title">{name}</span>
          </div>
        }
        key={id}
      >
        <Spin spinning={isLoading}>
          <Space direction="vertical" className="w-full">
            {buildings?.map((building, index) => (
              <Checkbox
                checked={buildingIds?.includes(String(building.id))}
                onChange={e => {
                  changeBuilding(String(building.id), e.target?.checked);
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {building.name}
              </Checkbox>
            ))}
          </Space>
        </Spin>
      </Panel>
    </Collapse>
  );
};

export default HouseItem;
