import React from 'react';

const DealIcon = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#3772FF" fillOpacity="0.1" />
      <path
        d="M33.75 24C33.75 25.9778 33.1342 27.9112 31.9804 29.5557C30.8267 31.2002 29.1868 32.4819 27.2682 33.2388C25.3496 33.9957 23.2384 34.1937 21.2016 33.8079C19.1648 33.422 17.2938 32.4696 15.8254 31.0711C14.3569 29.6725 13.3569 27.8907 12.9518 25.9509C12.5466 24.0111 12.7545 22.0004 13.5493 20.1732C14.344 18.3459 15.6898 16.7841 17.4165 15.6853C19.1432 14.5865 21.1733 14 23.25 14V24H33.75Z"
        fill="#29CCB2"
      />
      <path
        d="M24.25 13C25.5632 13 26.3636 13.2587 27.5768 13.7612C28.7901 14.2638 29.8925 15.0003 30.8211 15.9289C31.7497 16.8575 32.4862 17.9599 32.9888 19.1732C33.4913 20.3864 33.75 21.6868 33.75 23L24.25 23L24.25 13Z"
        fill="#00A389"
      />
    </svg>
  );

export default DealIcon;
