import { $api, BASE_URL } from '@service/RequestService';
import { useQuery } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { Pagination } from '@utils/models/Pagination';
import { IExpoAll } from '../utils/models/IExpoAll';

export function useExpoAll(search: string) {
  return useQuery<Pagination<IExpoAll>>([queryKeys.EXPO_ALL, search], async () => {
    const res = await $api.get(BASE_URL + endPoints.EXPO_ALL + search);
    return res.data.data;
  });
}
