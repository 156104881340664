import React, { memo } from 'react';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { Dropdown } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '../../../../../assets/icons/CalendarIcon';
import CheckmarkIcon from '../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import DatePicker from '../../../../../service/datePicker';

import styles from './filterDrawer.module.scss';

interface Props {
  start: string | undefined;
  finish: string | undefined;
  startLabel: 'taskStartDate' | 'startDate';
  finishLabel: 'taskFinishDate' | 'finishDate';
  title: string;
  type?: string;
}

const { RangePicker } = DatePicker;

const DateFilter: React.FC<Props> = ({ finish, start, finishLabel, startLabel, title, type }) => {
  const WITHOUT_TASKS_DATE = type ? '01.01.1967 00:00' : '01.01.1967';
  const { appendMultipleDifferent } = useQueryParam();
  const { t } = useTranslation();

  // change date
  const handleChangeDate = (startDate: string, finishDate: string) => {
    appendMultipleDifferent([startLabel, finishLabel], [startDate, finishDate]);
  };

  // handle click without task
  const handleWithoutTask = () => {
    handleChangeDate(WITHOUT_TASKS_DATE, WITHOUT_TASKS_DATE);
  };

  // handle click today
  const handleClickToday = () => {
    const finishDate = dayjs(new Date())
      .hour(23)
      .minute(59)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    const startDate = dayjs(new Date())
      .hour(0)
      .minute(0)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);

    handleChangeDate(startDate, finishDate);
  };

  // is today
  const isWithoutTask = () => {
    if (finish && start) {
      return start === WITHOUT_TASKS_DATE && finish === WITHOUT_TASKS_DATE;
    }
    return false;
  };

  // is today
  const isToday = () => {
    if (finish && start) {
      return start === dayjs(new Date()).format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT) && finish === dayjs(new Date()).format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    }
    return false;
  };

  // handle click this week
  const handleClickThisWeek = () => {
    let finishDate = dayjs(new Date())
      .endOf('week')
      .add(1, 'd')
      .hour(23)
      .minute(59)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    let startDate = dayjs(new Date())
      .startOf('week')
      .add(1, 'd')
      .hour(0)
      .minute(0)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    const now = dayjs(new Date()).format('ddd');

    if (now === 'Sun') {
      finishDate = dayjs(new Date())
        .add(-1, 'd')
        .endOf('week')
        .add(1, 'd')
        .hour(23)
        .minute(59)
        .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
      startDate = dayjs(new Date())
        .add(-1, 'd')
        .startOf('week')
        .add(1, 'd')
        .hour(0)
        .minute(0)
        .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    }
    handleChangeDate(startDate, finishDate);
  };

  // isThisWeek
  const isThisWeek = () => {
    if (finish && start) {
      let finishDate = dayjs(new Date())
        .endOf('week')
        .add(1, 'd')
        .hour(23)
        .minute(59)
        .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
      let startDate = dayjs(new Date())
        .startOf('week')
        .add(1, 'd')
        .hour(0)
        .minute(0)
        .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
      const now = dayjs(new Date()).format('ddd');

      if (now === 'Sun') {
        finishDate = dayjs(new Date())
          .add(-1, 'd')
          .endOf('week')
          .add(1, 'd')
          .hour(23)
          .minute(59)
          .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
        startDate = dayjs(new Date())
          .add(-1, 'd')
          .startOf('week')
          .add(1, 'd')
          .hour(0)
          .minute(0)
          .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
      }
      return start === startDate && finish === finishDate;
    }
    return false;
  };

  // handle click this week
  const handleClickPreviousMonth = () => {
    const finishDate = dayjs(new Date())
      .add(-1, 'month')
      .endOf('month')
      .hour(23)
      .minute(59)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    const startDate = dayjs(new Date())
      .add(-1, 'month')
      .startOf('month')
      .hour(0)
      .minute(0)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);

    handleChangeDate(startDate, finishDate);
  };

  // isPreviousMonth
  const isPreviousMonth = () => {
    if (finish && start) {
      return (
        start ===
          dayjs(new Date())
            .add(-1, 'month')
            .startOf('month')
            .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT) &&
        finish ===
          dayjs(new Date())
            .add(-1, 'month')
            .endOf('month')
            .hour(23)
            .minute(59)
            .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT)
      );
    }
    return false;
  };

  // handle click this month
  const handleClickThisMonth = () => {
    const finishDate = dayjs(new Date())
      .endOf('month')
      .hour(23)
      .minute(59)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);
    const startDate = dayjs(new Date())
      .startOf('month')
      .hour(0)
      .minute(0)
      .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT);

    handleChangeDate(startDate, finishDate);
  };

  // isThisMonth
  const isThisMonth = () => {
    if (finish && start) {
      return (
        start ===
          dayjs(new Date())
            .startOf('month')
            .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT) &&
        finish ===
          dayjs(new Date())
            .endOf('month')
            .hour(23)
            .minute(59)
            .format(type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT)
      );
    }
    return false;
  };

  // show date label
  const showDateLabel = () => {
    if (finish && start) {
      if (isWithoutTask()) return t('crm_filter.Topshiriqsiz');
      if (isToday()) return t('crm_filter.Bugun');
      if (isThisWeek()) return t('crm_filter.Joriy_hafta');
      if (isThisMonth()) return t('crm_filter.Joriy_oy');
      if (isPreviousMonth()) return t('crm_filter.Otkan_oy');
      return t('crm_filter.Maxsus');
    }
    return t(`crm_filter.${title}`);
  };

  return (
    <Dropdown
      placement="bottom"
      overlayClassName={styles.container_date_dropdown}
      overlay={
        <>
          {type !== 'task' && (
            <div onClick={handleWithoutTask} className={styles.container_date_dropdown_one}>
              <span className={styles.container_date_dropdown_one_name}>{t('crm_filter.Topshiriqsiz')}</span>
              {isWithoutTask() && <CheckmarkIcon />}
            </div>
          )}
          <div onClick={handleClickToday} className={styles.container_date_dropdown_one}>
            <span className={styles.container_date_dropdown_one_name}>{t('crm_filter.Bugun')}</span>
            {isToday() && <CheckmarkIcon />}
          </div>
          <div onClick={handleClickThisWeek} className={styles.container_date_dropdown_one}>
            <span className={styles.container_date_dropdown_one_name}>{t('crm_filter.Joriy_hafta')}</span>
            {isThisWeek() && <CheckmarkIcon />}
          </div>
          <div onClick={handleClickThisMonth} className={styles.container_date_dropdown_one}>
            <span className={styles.container_date_dropdown_one_name}>{t('crm_filter.Joriy_oy')}</span>
            {isThisMonth() && <CheckmarkIcon />}
          </div>
          <div onClick={handleClickPreviousMonth} className={styles.container_date_dropdown_one}>
            <span className={styles.container_date_dropdown_one_name}>{t('crm_filter.Otkan_oy')}</span>
            {isPreviousMonth() && <CheckmarkIcon />}
          </div>
          <RangePicker
            allowClear={false}
            onChange={(days, strings) => handleChangeDate(strings[0], strings[1])}
            value={
              start && finish && !isWithoutTask()
                ? [dayjs(start, type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT), dayjs(finish, type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT)]
                : undefined
            }
            placeholder={[t('crm_filter.Maxsus'), '']}
            suffixIcon={<CalendarIcon />}
            format={type ? DATE_WITH_TIME_FORMAT : DATE_FORMAT}
            showTime={type === 'crm'}
          />
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_date}>
        <span className={styles.container_date_title}>{showDateLabel()}</span>
        <CalendarIcon />
      </div>
    </Dropdown>
  );
};

export default memo(DateFilter);
