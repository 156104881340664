/* eslint-disable react/no-unknown-property */
import React, { FC } from 'react';
import { Checkbox, Form, Input, Radio, Select } from 'antd';
import { TreeSelect } from 'global';
import { t } from 'i18next';

import ArrowDownIcon from '../../../../../assets/icons/ArrowDownIconCrm';
import ArrowIosDownIcon from '../../../../../assets/icons/contract/ArrowIosDownIcon';
import SidebarArrowRightIcon from '../../../../../assets/icons/SidebarArrowRightIcon';
import { usePipeAll } from '../../../../../queries/queries';
import DateFinishV2 from '../../voronkaCards/triggers/modal/dateFinish/DateFinishV2';
import TypeV2 from '../../voronkaCards/triggers/modal/type/TypeV2';

import styles from './dublikatControll.module.scss';

interface IProps {}

const PotensialClientSource: FC<IProps> = () => {
  const { data: pipeAll } = usePipeAll();

  return (
    <div className={styles.potensialClientSourrce}>
      <Form.Item name="fromFaceBook" className="hidden" />

      <div style={{ borderBottom: '1px solid #ebebeb' }}>
        <Form.Item className="mb-[10px]">
          <h1 className="mb-[10px] text-[14px]">{t('home.Dublikatni_aniqlash')}</h1>
          <p>{t('home.Ikki_nusxada_kiruvchi_mijozlarni_tekshirish')}</p>
          <Checkbox checked={true} disabled>
            {t('home.Telefon_raqami_orqali_birlashtirish')}
          </Checkbox>
        </Form.Item>
      </div>

      <div style={{ borderBottom: '1px solid #ebebeb', paddingBottom: '5px' }}>
        <h1 className="mb-[5px] text-[14px]">{t('home.Dublikatni_topish_uchun_varonkalar_va_qadamlar')}</h1>
        <Form.Item name="pipeStatusIds" className="mb-[10px]">
          <TreeSelect
            treeExpandAction="click"
            switcherIcon={<SidebarArrowRightIcon />}
            suffixIcon={<ArrowDownIcon />}
            className="customSelect"
            style={{ width: 200 }}
            placeholder={t('home.Barcha_varonkalar_barcha_qadamlar')}
            allowClear
            multiple
            maxTagCount="responsive"
            treeCheckable={true}
          >
            {pipeAll?.map(item => (
              <TreeSelect.TreeNode
                title={item.name}
                value={item.id}
                key={item.id}
                // selectable={false}
                children={item.pipeStatuses.map(pipe => (
                  <TreeSelect.TreeNode
                    key={pipe?.id}
                    value={pipe?.id!}
                    title={
                      <div
                        // @ts-ignore
                        pageName={item?.name}
                      >
                        <span
                          style={{
                            background: pipe.color,
                            color: '#FFFFFF',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            fontSize: 10
                          }}
                        >
                          {pipe.name}
                        </span>
                      </div>
                    }
                  />
                ))}
              />
            ))}
          </TreeSelect>
        </Form.Item>
      </div>

      <div style={{ borderBottom: '1px solid #ebebeb', paddingBottom: '5px' }}>
        <h1 className="mb-[5px] text-[14px]">{t('home.Dublikatlarda_mos_bolmagan_malumotlar_bolsa')}</h1>
        <Form.Item name="isNewData" className="mb-[10px]">
          <Radio.Group>
            <Radio value={true}>{t('home.Mavjud_malumotlarni_yangi_malumotlarga_almashtirish')}</Radio>
            <Radio value={false}>{t('home.Eski_malumotlari_bilan_saqlash')}</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <div style={{ borderBottom: '1px solid #ebebeb', paddingBottom: '5px' }}>
        <h1 className="mb-[5px] text-[14px]">{t('home.Yangi_lid_2_yoki_undan_kop_lidlar_bilan_mos_kelsa')}</h1>
        <Form.Item name="conflict" className="mb-[10px]">
          <Select placeholder={t('home.Yangi_lidni_toldirish')} className="customSelect" style={{ width: 200 }} suffixIcon={<ArrowIosDownIcon />}>
            <Select.Option value="MERGE_ALL_LEAD">{t('home.Barchasini_birlashtirish')}</Select.Option>
            <Select.Option value="CREATE_NEW_LEAD">{t('home.Yangi_lid_yaratish')}</Select.Option>
            <Select.Option value="MERGE_NEW_LEAD">{t('home.Yangi_lidni_toldirish')}</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <h1 className="mb-[-10px] text-[14px]">{t('one_lid.Topshiriq')}</h1>
      <Form.Item name="dublicate" className="mb-0 w-[400px]">
        <DateFinishV2 />
      </Form.Item>

      <Form.Item name="leadTaskTypeId" className="mb-0">
        <TypeV2 />
      </Form.Item>

      <Form.Item name="text">
        <Input className="customInput" style={{ width: 400, height: 50 }} placeholder={`${t('home.Izoh')}...`} />
      </Form.Item>
    </div>
  );
};

export default PotensialClientSource;
