import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import EnIcon from '@icons/enIcon';
import RuIcon from '@icons/RuIcon';
import ShablonIcon from '@icons/ShablonIcon';
import TrIcon from '@icons/trIcon';
import UzIcon from '@icons/UzIcon';
import DragAndDropIcon from '@pages/crm/assets/icons/DragAndDropIcon';
import { useCreatePipeStatus } from '@pages/voronka-shablon/services/mutation';
import { useGetCustomTemplete, useGetOnePipeTemplete } from '@pages/voronka-shablon/services/queries';
import { queryKeys } from '@pages/voronka-shablon/utils/constants/queryKeys';
import { usePipeAll } from '@queries/queries';
import { setCreateVoronka } from '@reducers/CrmSlice';
import { Button, Checkbox, Collapse, Form, Input, Modal, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import VoronkaDnd from '../voronka-dnd/VoronkaDnd';

import styles from './createVoronka.module.scss';

type Props = {
  shablonId?: number | undefined;
  setOpenShablon?: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Option } = Select;
const { Panel } = Collapse;

const CreateVoronka: React.FC<Props> = ({ shablonId, setOpenShablon }) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { createVoronka } = useAppSelector(state => state.assigmentSlice);
  const { data } = useGetOnePipeTemplete(shablonId!);
  const { data: custom } = useGetCustomTemplete();
  const { data: pipeAll } = usePipeAll();
  const createPipe = useCreatePipeStatus();
  const defLang = localStorage.getItem('i18nextLng');

  function capitalizeFirstLetter(str: string) {
    if (str.length < 2) return str.toUpperCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [defaultLang, setDefaultLang] = useState(capitalizeFirstLetter(defLang!));
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const dataWorker = shablonId ? data : custom?.find(item => item?.type === 'CUSTOM');

  const handleClose = () => {
    dispatch(setCreateVoronka(false));
    form.resetFields();
  };

  useEffect(() => {
    if (createVoronka) {
      qc.fetchQuery([queryKeys.PIPE_TEMPLATE]);
    }
  }, [createVoronka]);

  useEffect(() => {
    const defaultChecked = dataWorker?.causes
      ?.map(item =>
        item?.pipelineNames?.map(prev => ({
          ...prev,
          [`name${defaultLang}`]: prev[`name${defaultLang}` as keyof typeof prev],
          draggableId: prev[`name${defaultLang}` as keyof typeof prev]
        }))
      )
      ?.flat(Infinity);
    const simpleData = defaultChecked?.filter((prev: any) => prev?.pipeStatusType === 'SIMPLE');
    const firstData: any = defaultChecked?.find((prev: any) => prev?.pipeStatusType === 'FIRST');
    const successData: any = defaultChecked?.find((prev: any) => prev?.pipeStatusType === 'SUCCESS');
    const failedData: any = defaultChecked?.find((prev: any) => prev?.pipeStatusType === 'FAILED');

    setCheckedList(simpleData!);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    firstData && form.setFieldsValue({ first: firstData[`name${defaultLang}`] });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    firstData && form.setFieldsValue({ firstColor: firstData?.color });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    successData && form.setFieldsValue({ success: successData[`name${defaultLang}`] });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    successData && form.setFieldsValue({ successColor: successData?.color });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    failedData && form.setFieldsValue({ failed: failedData[`name${defaultLang}`] });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    failedData && form.setFieldsValue({ failedColor: failedData?.color });
  }, [dataWorker, defaultLang, createVoronka]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (form.__INTERNAL__.name) {
      form.setFieldsValue({
        causes: checkedList
      });
    }
  }, [checkedList]);

  const onFinish = (values: any) => {
    const firstValue = { name: values?.first, color: values?.firstColor, type: 'FIRST', order: 1 };
    const successValue = { name: values?.success, color: values?.successColor, type: 'SUCCESS', order: 1001 };
    const failedValue = { name: values?.failed, color: values?.failedColor, type: 'FAILED', order: 1002 };
    const simpleValue = values?.causes?.map((item: any, index: any) => ({
      name: item[`name${defaultLang}`],
      type: item?.pipeStatusType ? item?.pipeStatusType : 'SIMPLE',
      color: item?.color ? item?.color : '#302df0',
      order: index + 2
    }));

    const pipeStatuses = [firstValue, ...simpleValue, successValue, failedValue];
    const newArr = {
      pipeName: values?.name,
      orders: pipeAll?.length! + 1,
      pipeStatuses,
      missingCallResponsibleId: 0
    };

    createPipe.mutateAsync(newArr).then(handleClose);
  };

  return (
    <Modal className={styles.createVoronka} centered title={t('home.Voronka_yaratish')} visible={createVoronka} onCancel={handleClose} width={1094} footer={false}>
      <Form layout="vertical" autoComplete="off" form={form} onFinish={onFinish} name="Voronka_yaratish">
        <div className={styles.container}>
          <div className={styles.container_left}>
            <div className={styles.container_left_top}>
              <div className={styles.container_left_top_title}>
                <Form.Item name="name" label={t('home.Voronka_nomi')} rules={[{ required: true, message: '' }]}>
                  <Input placeholder={t('home.Voronka_nomi')} style={{ borderRadius: 6 }} />
                </Form.Item>
              </div>
              <Form.Item label={t('home.Til')}>
                <Select style={{ minWidth: 189 }} onChange={e => setDefaultLang(e)} value={defaultLang} className={styles.selectLang}>
                  <Option key="Uz" value="Uz">
                    <div className={styles.optionLang}>
                      <UzIcon />
                      <span>O'zbek</span>
                    </div>
                  </Option>
                  <Option key="Ru" value="Ru">
                    <div className={styles.optionLang}>
                      <RuIcon />
                      <span>Rus</span>
                    </div>
                  </Option>
                  <Option key="En" value="En">
                    <div className={styles.optionLang}>
                      <EnIcon />
                      <span>English</span>
                    </div>
                  </Option>
                  <Option key="Tr" value="Tr">
                    <div className={styles.optionLang}>
                      <TrIcon />
                      <span>Turk</span>
                    </div>
                  </Option>
                </Select>
              </Form.Item>
            </div>
            <Collapse defaultActiveKey={['0', '1', '2']} expandIconPosition="end">
              {dataWorker?.causes?.map((item, index) => (
                <Panel
                  header={
                    <div>
                      <p>{item[`name${defaultLang}` as keyof typeof item]}</p>
                      <span>
                        {t('home.Bosqichlar_soni')} {item?.pipelineNames?.length}
                      </span>
                    </div>
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <Space direction="vertical">
                    {item?.pipelineNames
                      ?.sort((a, b) => a.order - b.order)
                      ?.map(prev => (
                        <Checkbox
                          value={prev[`name${defaultLang}` as keyof typeof prev]}
                          onChange={e => {
                            if (checkedList?.some(item => item[`name${defaultLang}`] === e?.target?.value)) {
                              const newData = checkedList?.filter(item => item[`name${defaultLang}`] !== e?.target?.value);

                              setCheckedList(newData);
                            } else {
                              setCheckedList([...checkedList, prev]);
                            }
                          }}
                          disabled={prev?.pipeStatusType === 'FIRST' || prev?.pipeStatusType === 'SUCCESS' || prev?.pipeStatusType === 'FAILED'}
                          checked={
                            !!checkedList?.some(item => item[`name${defaultLang}`] === prev[`name${defaultLang}` as keyof typeof prev]) ||
                            prev?.pipeStatusType === 'FIRST' ||
                            prev?.pipeStatusType === 'SUCCESS' ||
                            prev?.pipeStatusType === 'FAILED'
                          }
                          key={prev?.nameUz}
                        >
                          {prev[`name${defaultLang}` as keyof typeof prev]}
                        </Checkbox>
                      ))}
                  </Space>
                </Panel>
              ))}
            </Collapse>
          </div>
          <div className={styles.container_right}>
            <div className={styles.container_right_item}>
              <DragAndDropIcon style={{ opacity: 0 }} />
              <Form.Item name="firstColor" className={styles.colorPicker}>
                <input type="color" />
              </Form.Item>
              <Form.Item name="first" className={styles.customInput} rules={[{ required: true, message: '' }]}>
                <Input style={{ borderRadius: 6 }} />
              </Form.Item>
            </div>
            <VoronkaDnd defaultLang={defaultLang} form={form} />
            <div className={styles.container_right_item}>
              <DragAndDropIcon style={{ opacity: 0 }} />
              <Form.Item name="successColor" className={styles.colorPicker}>
                <input type="color" />
              </Form.Item>
              <Form.Item name="success" className={styles.customInput} rules={[{ required: true, message: '' }]}>
                <Input style={{ borderRadius: 6 }} />
              </Form.Item>
            </div>
            <div className={styles.container_right_item}>
              <DragAndDropIcon style={{ opacity: 0 }} />
              <Form.Item name="failedColor" className={styles.colorPicker}>
                <input type="color" />
              </Form.Item>
              <Form.Item name="failed" className={styles.customInput} rules={[{ required: true, message: '' }]}>
                <Input style={{ borderRadius: 6 }} />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <Button
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              setOpenShablon && setOpenShablon(true);
              handleClose();
            }}
          >
            <ShablonIcon />
            <span>{t('home.Shablondan_tanlash')}</span>
          </Button>
          <div className={styles.footer_right}>
            <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
            <Button htmlType="submit" type="primary" loading={createPipe?.isLoading}>
              {t('home.Saqlash')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateVoronka;
