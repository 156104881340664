import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TaskChangetextState {
  taskChangeText?: string;
  intentWidth?: number;
  isDragAccept?: boolean;
}

const initialState: TaskChangetextState = {
  taskChangeText: 'Topshiriq',
  intentWidth: 0,
  isDragAccept: false
};

export const taskChangeTextSlice = createSlice({
  name: 'taskChangeText',
  initialState,
  reducers: {
    setTaskChangeText: (state, action: PayloadAction<TaskChangetextState>) => {
      state.taskChangeText = action.payload.taskChangeText;
    },
    setIntentWidth: (state, action: PayloadAction<TaskChangetextState>) => {
      state.intentWidth = action.payload.intentWidth;
    },
    setIsDragAccepted: state => {
      state.isDragAccept = true;
    }
  }
});

export const { setTaskChangeText, setIntentWidth, setIsDragAccepted } = taskChangeTextSlice.actions;
export default taskChangeTextSlice.reducer;
