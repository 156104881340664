import React, { useEffect, useState } from 'react';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditProfile } from '../../queries/mutation';
import { useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { Profile } from '../../utils/models/Profile';

import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  profile?: Profile;
}

interface IForm {
  address: string;
  region: number;
  districtId: number;
  countryCode: string;
}

const ChangeAddress: React.FC<Props> = ({ visible, onClose, profile }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm<IForm>();
  const [regionId, setRegionId] = useState<number>(0);
  const [countryId, setCountryId] = useState<number>();
  const countryCode = Form.useWatch('countryCode', form) as string;

  const { data: regions, isLoading: loadingRegions } = useRegions(countryId);
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');

  // handle close modal
  const handleCancel = () => {
    onClose(false);
    form.resetFields();
  };

  const editProfile = useEditProfile(handleCancel);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        districtId: profile.districtId,
        region: profile.cityId,
        address: profile.address,
        countryCode: profile?.countryCode?.toLowerCase()
      });
      setRegionId(profile?.cityId || 0);

      const country = countrCode?.find(({ code }) => code?.toLowerCase() === profile?.countryCode?.toLowerCase());

      if (country) setCountryId(country?.index + 1);
    }
  }, [profile, form, visible]);

  // handle submit form
  const onFinish = (values: IForm) => {
    if (profile) {
      editProfile.mutate({
        ...profile,
        address: values.address,
        cityId: values.region,
        districtId: values.districtId,
        countryCode: values?.countryCode
      });
    }
  };

  // handle change region
  const changeRegion = (id: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setRegionId(id);
  };

  return (
    <Modal width={546} className="createBuilderModal" title={t('profile.Manzilni_ozgartirish')} visible={visible} footer={false} onCancel={handleCancel}>
      <Form onFinish={onFinish} form={form} layout="vertical" className={styles.formBuilderModal}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              name="countryCode"
              rules={[{ required: true, message: '' }]}
              label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>}
            >
              <Select
                showSearch
                className="customSelect"
                optionFilterProp="children"
                placeholder={t('home.Tanlang')}
                onChange={(_, option: any) => {
                  setCountryId(option?.props);
                  setRegionId(0);

                  form.setFieldsValue({
                    region: undefined,
                    districtId: undefined
                  });
                }}
              >
                {countrCode?.map(item => (
                  <Select.Option key={item?.code} value={item?.code} props={item?.index + 1}>
                    {item[`country_${i18n.language}` as keyof typeof item]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {countryCode === 'uz' || countryCode === 'tj' ? (
            <>
              <Col span={24}>
                <Form.Item
                  label={t('home.Viloyat')}
                  name="region"
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <Select
                    disabled={!countrCode}
                    optionFilterProp="children"
                    showSearch
                    className="customSelect"
                    placeholder={t('home.Tanlang')}
                    loading={loadingRegions}
                    onChange={i => changeRegion(i)}
                  >
                    {regions?.map(region => (
                      <Select.Option key={region.id} value={region.id}>
                        {getCurrentLanguageWord(region)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('home.Shaxar')}
                  name="districtId"
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <Select disabled={!regionId} optionFilterProp="children" showSearch className="customSelect" placeholder={t('home.Tanlang')} loading={districtsLoading}>
                    {districts?.map(district => (
                      <Select.Option key={district.id} value={district.id}>
                        {getCurrentLanguageWord(district)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          ) : null}
          <Col span={24}>
            <Form.Item
              label={t('profile.Manzili')}
              name="address"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Input className="customInput" />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={editProfile.isLoading} htmlType="submit" type="primary">
            {t('profile.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeAddress;
