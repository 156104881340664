import { Link } from 'react-router-dom';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import { convertLocation } from '@pages/oneLid/components/information/info/Info';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { tableExamples } from '@utils/constants/tableExamples';
import { textCapitalize } from '@utils/helper/textConverter';
import { cx, encodeString } from '@utils/index';
import { TableConfig } from '@utils/models/TableConfig';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'global';
import { useTranslation } from 'react-i18next';

import { crmModels } from 'modules/crm';
import { eventListModels, useAction } from 'modules/event-list';

import DotsIcon from '../assets/DotsIcon';
import { EventElements } from '../utils/helpers/EventElements';

const classNames = {
  leadStatus: 'h-[24px] min-w-[250px] max-w-max overflow-hidden border-[1px] border-solid border-[#e5e7eb] flex justify-center items-center rounded-[31px]',
  leadStatusItem: 'flex-1 text-[12px] font-[400] h-full leading-[20px] overflow-hidden pl-16px text-center text-ellipsis whitespace-nowrap text-white'
};

const LeadTable = () => {
  const { pipes, crmFields, leadEmployeeAll, data, query, setQuery, dataIsLoading, tableConfig } = useAction();
  const { t } = useTranslation();

  const obj = {
    CHANGE_STATUS: (id: any) => {
      const elm = pipes?.find(item => item.id === Number(id));

      return (
        <div
          //   className={styles.activity_previus}
          className={classNames?.leadStatus}
          style={{
            border: elm?.pipeName && id ? '' : 'none'
          }}
        >
          <span className={cx(classNames.leadStatusItem, 'text-[#e5e7eb]')} title={elm?.pipeName}>
            {elm?.pipeName}
          </span>
          <span
            className={cx(classNames.leadStatusItem)}
            style={{
              background: elm?.color
            }}
            title={elm?.name}
          >
            {elm?.name}
          </span>
        </div>
      );
    },
    SET_VALUE: (current: any, objectId?: string) => {
      const locationType = crmFields?.find(item => item.id === Number(objectId))?.type;

      if (locationType === 'LOCATION') {
        return convertLocation(current)?.name;
      }
      return current;
    },
    CHANGE_LEAD_EMPLOYEE: (current: any) => {
      const employeeType = leadEmployeeAll?.find(item => item.id === Number(current))?.fullName;

      return employeeType;
    },
    CREATE_STATUS: (id: any) => {
      const elm = pipes?.find(item => item.id === Number(id));

      return (
        <div
          //   className={styles.activity_previus}
          className={classNames?.leadStatus}
          style={{
            border: !id || !elm ? 'none' : ''
          }}
        >
          <span className={cx(classNames.leadStatusItem, 'text-[#e5e7eb]')} title={elm?.pipeName}>
            {elm?.pipeName}
          </span>
          <span
            className={cx(classNames.leadStatusItem)}
            style={{
              background: elm?.color
            }}
            title={elm?.name}
          >
            {elm?.name}
          </span>
        </div>
      );
    },
    SET_LEAD_EMPLOYEE: (current: any) => {
      const employeeType = leadEmployeeAll?.find(item => item.id === Number(current))?.fullName;

      return employeeType;
    }
  };

  const fc = (type: string, current: any, objectId?: any) => {
    if (type in obj) {
      const getCurrent = obj[type as keyof typeof obj](current, objectId);

      return getCurrent;
    }
    return current;
  };

  const columns: ColumnsType<eventListModels.IActivityLog> | undefined = [
    {
      key: 1,
      align: 'left',
      title: t(`home.Sana`),
      render: (date: eventListModels.IActivityLog) => <span>{date?.createdDate}</span>,
      width: 200
    },
    {
      title: t(`home.Xodim`),
      key: 2,
      dataIndex: 'responsibleBy',
      width: 250
    },
    {
      title: t(`home.obyekt`),
      key: 3,
      dataIndex: 'objectLog',
      width: 120
    },
    {
      title: t(`home.Nomi`),
      key: 4,
      dataIndex: 'name',
      render: (value, record) => <Link to={`${rootPaths.MAIN + MainPaths.CRM}/${record?.leadId}/${encodeString(record?.name)}`}>{value}</Link>,
      width: 300
    },
    {
      title: t(`home.event`),
      key: 5,
      render: (event: eventListModels.IActivityLog) => (
        <span>
          <b>
            {
              // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'SET_VALUE' || event?.actionType === 'DELETE_VALUE' ? (
                <q>
                  {crmFields?.find(item => item?.id === Number(event?.objectId)) &&
                    (crmFields?.find(item => item?.id === Number(event?.objectId))![`name${textCapitalize(i18n.language)}` as keyof crmModels.ICrmField] ||
                      crmFields?.find(item => item?.id === Number(event?.objectId))?.name)}
                </q>
              ) : // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'LEAD_MERGE' ? (
                t('one_lid.Lead_birlashtirildi')
              ) : // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'REMOVE_CLIENT_FROM_LEAD' ? (
                t('one_lid.Leaddan_olib_tashlandi')
              ) : // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'ATTACH_LEAD_TO_CLIENT' ? (
                t('one_lid.Leadga_biriktirildi')
              ) : // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'TASK_TEXT_CHANGED' ? (
                t('one_task.Topshiriq_matni_ozgartirildi')
              ) : // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'CREATE_TASK' ? (
                t('one_task.Topshiriq_yaratildi')
              ) : // eslint-disable-next-line no-nested-ternary
              event?.actionType === 'TASK_COMPLETED' ? (
                'Topshiriq bajarildi'
              ) : event?.actionType === 'TASK_RESULT_ADDED' ? (
                t('one_task.Topshiriq_natijasi_qoshildi')
              ) : (
                ''
              )
            }
          </b>
          {t(EventElements[event?.actionType as keyof typeof EventElements])}
        </span>
      ),
      width: 300
    },
    {
      title: t(`home.Korsatkichdan_oldin`),
      key: 6,
      render: (previus: eventListModels.IActivityLog) =>
        fc(
          previus?.actionType,
          previus?.prevAddress
            ? `${previus?.prevAddress.country_uz}, ${previus?.prevAddress?.region_uz} ${previus?.prevAddress?.city_uz ? `,${previus?.prevAddress?.city_uz}` : ''}`
            : previus.previous,
          previus.objectId
        ),
      width: 350
    },
    {
      title: t(`home.Korsatkichdan_keyin`),
      key: 7,
      render: (current: eventListModels.IActivityLog) =>
        fc(
          current.actionType,
          current?.currentAddress
            ? `${current?.currentAddress?.country_uz}, ${current?.currentAddress?.region_uz} ${current?.currentAddress?.city_uz ? current?.currentAddress?.city_uz : ''}`
            : current?.current,
          current?.objectId
        ),
      width: 350
    },
    {
      title: '',
      key: 8,
      dataIndex: 'actions',
      render: () => (
        <span
        //  className={styles.actions}
        >
          <DotsIcon />
        </span>
      ),
      width: 80
    }
  ];

  const hasChildren = (item: TableConfig, column: any) => ({
    ...column,
    children: column?.children
      ? column?.children
          ?.map((child: any) => ({
            ...(item.values?.some(val => val?.name === child?.name && val?.checked) && child)
          }))
          .filter((item: any) => item?.name)
      : []
  });

  const columnsSort = (tableConfig?.config && tableConfig?.config?.length > 0 ? tableConfig?.config : tableExamples['activity-logs'])
    ?.map((item: TableConfig) => ({
      ...(item.checked &&
        hasChildren(
          item,
          columns?.find((col: any) => col?.key === item?.id)
        ))
    }))
    .filter((item: { title: any }) => item?.title);

  return (
    <div>
      <div
      //  className={styles.eventsList_table}
      >
        <Table
          columns={columnsSort}
          dataSource={data?.data}
          className="tableNewStyle"
          pagination={{
            className: 'tableNewStyle_pagination',
            prevIcon: (
              <div className="tableNewStyle_pagination_prev">
                <ArrowLeftIcon /> {t('my_calls.Ortga')}
              </div>
            ),
            onChange(page) {
              //   changePage(page, 18);
              setQuery({
                page
              });
            },
            pageSize: query?.size,
            total: data?.totalElements,
            current: query?.page,
            nextIcon: (
              <div className="tableNewStyle_pagination_next">
                {t('crm_ins.Keyingisi')} <ArrowRightIcon />
              </div>
            )
          }}
          scroll={{ y: `calc(100vh - 280px` }}
          loading={dataIsLoading}
        />
      </div>
    </div>
  );
};

export default LeadTable;
