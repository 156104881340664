import React, { useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';
import { useTranslation } from 'react-i18next';

import ClockCounterClockwiseIcon from '../../assets/icons/contract/ClockCounterClockwiseIcon';
import TaskIcon from '../../assets/icons/contract/TaskIcon';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useContractId } from '../../queries/queries';

import ContractIdApartmentInfo from './components/contract-id-apartment-info/ContractIdApartmentInfo';
import ContractIdClientInfo from './components/contract-id-client-info/ContractIdClientInfo';
import ContractIdHeader from './components/contract-id-header/ContractIdHeader';
import ContractIdPaymentContainer from './components/contract-id-payment-container/ContractIdPaymentContainer';
import ContractIdPaymentGraphic from './components/contract-id-payment-history/ContractIdPaymentGraphic';
import ContractIdPaymentHistory from './components/contract-id-payment-history/ContractIdPaymentHistory';

import styles from './contractIdV2.module.scss';

const ContractIdV2 = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.userReducer);
  const isContractPaymentHistory = user?.role?.permissions?.includes(Permissions.PERMISSION_CONTRACT_PAYMENT);
  const [tapleKey, setTapleKey] = useState(isContractPaymentHistory ? 'PAYMENT_HISTORY' : 'PAYMENT_GRAPHIC');
  const { params } = useQueryParam();
  const { data, isLoading } = useContractId(params.id);

  const tableKeys = {
    ...(isContractPaymentHistory && {
      PAYMENT_HISTORY: {
        text: t('contract_id.Tolov_tarixi'),
        type: 'PAYMENT_HISTORY',
        icon: <ClockCounterClockwiseIcon />,
        component: <ContractIdPaymentHistory data={data} />
      }
    }),
    PAYMENT_GRAPHIC: {
      text: t('contract_id.Tolov_grafigi'),
      type: 'PAYMENT_GRAPHIC',
      icon: <TaskIcon />,
      component: <ContractIdPaymentGraphic />
    }
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className={styles.container}>
        <ContractIdHeader data={data} />
        <div className={styles.container_body}>
          <div className={styles.container_body_left}>
            <ContractIdPaymentContainer data={data} isLoading={isLoading} />
            <div className={styles.container_body_left_tables}>
              <div className={styles.container_body_left_tables_header}>
                {Object.values(tableKeys).map(item => (
                  <div
                    key={item!.type}
                    className={`${styles.container_body_left_tables_header_item} ${
                      tapleKey === item!.type ? styles.container_body_left_tables_header_item_active : ''
                    }`}
                    onClick={() => setTapleKey(item!.type)}
                  >
                    {item?.icon} {item?.text}
                  </div>
                ))}
              </div>
              <div className={styles.container_body_left_tables_body}>{tableKeys[tapleKey as keyof typeof tableKeys]?.component}</div>
            </div>
          </div>
          <div className={styles.container_body_right}>
            <ContractIdClientInfo data={data} isLoading={isLoading} />
            <ContractIdApartmentInfo data={data} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractIdV2;
