import i18n from './../../service/I18nService';
import { RemoteCurrency } from '../models/Currency';

export const getCurrentCurrencyName = (currency: RemoteCurrency) => {
  switch (i18n.language) {
    case 'ru':
      return currency?.CcyNm_RU;
    case 'en':
      return currency?.CcyNm_EN;
    case 'kr':
      return currency?.CcyNm_UZC;
    default:
      return currency?.CcyNm_UZ;
  }
};
