import { BASE_URL } from '../../service/RequestService';

export const downloadFile = (data: Blob, name: string, type: string) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', `${name}.${type}`);
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export const downloadFileByUrl = (url: string, name: string, type?: string) => {
  const a = document.createElement('a');
  a.href = BASE_URL + '/' + url;
  a.setAttribute('download', `${name}.${type}`);
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
