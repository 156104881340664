import React, { useState } from 'react';
import { Modal } from 'antd';
import styles from './addCard.module.scss';
import CardsList from './CardsList';
import AddCardPayme from './payme/AddCardPayme';
import ActivateCardPayme from './payme/ActivateCardPayme';
import ChangeStyle from './ChangeStyle';
import { useTranslation } from 'react-i18next';
import AddCardMyUzcard from './myUzcard/AddCardMyUzcard';
import ActivateCardUzcard from './myUzcard/ActivateCardUzcard';
import { Card } from '../../../../utils/models/Card';
import { SystemTypeCash } from '../../../../utils/models/Cash';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  name: string;
}

export enum TypePage {
  LIST = 'LIST',
  PAYME = 'PAYME',
  ACTIVATE_PAYME = 'ACTIVATE_PAYME',
  ACTIVATE_UZCARD = 'ACTIVATE_UZCARD',
  MY_UZCARD = 'MY_UZCARD'
}

const AddCardCont: React.FC<Props> = ({ isOpen, onClose, id, name }) => {
  const [currentPage, setCurrentPage] = useState<TypePage>(TypePage.LIST);
  const [visibleChangeStyle, setVisibleChangeStyle] = useState(false);
  const [card, setCard] = useState<Card>();

  const { t } = useTranslation();

  const handleClose = () => {
    setCurrentPage(TypePage.LIST);
    onClose();
  };

  const handleCloseChangeStyle = () => {
    setVisibleChangeStyle(false);
    setCard(undefined);
  };

  const handleChangePage = (page: TypePage) => {
    setCurrentPage(page);
  };

  const handleActivateCard = (card: Card) => {
    setCard(card);
    if (card.systemType === SystemTypeCash.PAYME) {
      setCurrentPage(TypePage.ACTIVATE_PAYME);
    } else {
      setCurrentPage(TypePage.ACTIVATE_UZCARD);
    }
  };

  const handleChangeStyle = (card: Card) => {
    setCard(card);
    setVisibleChangeStyle(true);
  };

  const showPage = () => {
    switch (currentPage) {
      case TypePage.ACTIVATE_PAYME:
        return <ActivateCardPayme name={name} changePage={handleChangePage} card={card} id={id} />;
      case TypePage.ACTIVATE_UZCARD:
        return <ActivateCardUzcard name={name} changePage={handleChangePage} card={card} id={id} />;
      case TypePage.PAYME:
        return <AddCardPayme changePage={handleChangePage} id={id} name={name} />;
      case TypePage.MY_UZCARD:
        return <AddCardMyUzcard changePage={handleChangePage} id={id} name={name} />;
      default:
        return (
          <CardsList
            handleChangeStyle={handleChangeStyle}
            handleActivateCard={handleActivateCard}
            name={name}
            id={id}
            changePage={handleChangePage}
          />
        );
    }
  };

  const getModalTitle = () => {
    switch (currentPage) {
      case TypePage.PAYME:
      case TypePage.MY_UZCARD:
        return t('contract.Karta_qoshish');
      case TypePage.ACTIVATE_UZCARD:
      case TypePage.ACTIVATE_PAYME:
        return t('contract.Kartani_aktivlashtirish');
      default:
        return t('contract.Karta_sozlamalari');
    }
  };

  return (
    <>
      <Modal
        className={styles.cont}
        width={960}
        style={{ minHeight: 350 }}
        visible={isOpen}
        onCancel={handleClose}
        footer={null}
        title={getModalTitle()}
      >
        {showPage()}
      </Modal>
      <Modal
        className={styles.cont}
        width={546}
        visible={visibleChangeStyle}
        onCancel={handleCloseChangeStyle}
        footer={null}
        title={t('contract.Karta_sozlamalari')}
      >
        <ChangeStyle handleCancel={handleCloseChangeStyle} name={name} card={card} contractId={id} />
      </Modal>
    </>
  );
};

export default AddCardCont;
