import React from 'react';

const NoDataIcon = () => (
  <svg width="166" height="207" viewBox="0 0 166 207" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23334_72167)">
      <path
        d="M23 0.932373H142.062C153.971 0.932373 163.625 10.5341 163.625 22.3783V128.676C163.625 171.418 128.787 206.068 85.8125 206.068H23C11.0913 206.068 1.4375 196.465 1.4375 184.622V22.3783C1.4375 10.5341 11.0914 0.932373 23 0.932373Z"
        stroke="#00A389"
      />
      <path
        d="M11.75 26.1081C11.75 17.8686 18.4657 11.1892 26.75 11.1892H138.312C146.597 11.1892 153.312 17.8686 153.312 26.1081V121.216C153.312 162.414 119.734 195.811 78.3125 195.811H26.75C18.4657 195.811 11.75 189.131 11.75 180.892V26.1081Z"
        fill="#E7F9F6"
      />
      <path d="M41.75 48.4866H123.312" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
      <path
        d="M48.7813 79.2567C48.2636 79.2567 47.8438 79.6742 47.8438 80.1891C47.8438 80.704 48.2636 81.1215 48.7813 81.1215V79.2567ZM98.1562 80.1891C98.1562 82.9357 100.395 85.1621 103.156 85.1621C105.918 85.1621 108.156 82.9357 108.156 80.1891C108.156 77.4425 105.918 75.2161 103.156 75.2161C100.395 75.2161 98.1562 77.4425 98.1562 80.1891ZM48.7813 81.1215H103.156V79.2567H48.7813V81.1215Z"
        fill="#00A389"
      />
      <path
        d="M49.25 91.3785C48.7323 91.3785 48.3125 91.796 48.3125 92.3109C48.3125 92.8258 48.7323 93.2434 49.25 93.2434V91.3785ZM83.6249 92.3109C83.6249 95.0575 85.8635 97.284 88.625 97.284C91.3865 97.284 93.6251 95.0575 93.6251 92.3109C93.6251 89.5643 91.3865 87.3379 88.625 87.3379C85.8635 87.3379 83.6249 89.5643 83.6249 92.3109ZM49.25 93.2434H88.625V91.3785H49.25V93.2434Z"
        fill="#00A389"
      />
      <path
        d="M49.25 113.757C48.7323 113.757 48.3125 114.174 48.3125 114.689C48.3125 115.204 48.7323 115.622 49.25 115.622V113.757ZM68.6249 114.689C68.6249 117.436 70.8635 119.662 73.625 119.662C76.3865 119.662 78.6251 117.436 78.6251 114.689C78.6251 111.943 76.3865 109.716 73.625 109.716C70.8635 109.716 68.6249 111.943 68.6249 114.689ZM49.25 115.622H73.625V113.757H49.25V115.622Z"
        fill="#7BBFB4"
      />
      <path
        d="M49.25 124.946C48.7323 124.946 48.3125 125.363 48.3125 125.878C48.3125 126.393 48.7323 126.811 49.25 126.811V124.946ZM62.9999 125.878C62.9999 128.625 65.2385 130.851 68 130.851C70.7615 130.851 73.0001 128.625 73.0001 125.878C73.0001 123.132 70.7615 120.905 68 120.905C65.2385 120.905 62.9999 123.132 62.9999 125.878ZM49.25 126.811H68V124.946H49.25V126.811Z"
        fill="#7BBFB4"
      />
      <path
        d="M49.25 102.567C48.7323 102.567 48.3125 102.985 48.3125 103.5C48.3125 104.015 48.7323 104.432 49.25 104.432V102.567ZM92.9999 103.5C92.9999 106.246 95.2385 108.473 98 108.473C100.762 108.473 103 106.246 103 103.5C103 100.753 100.762 98.5269 98 98.5269C95.2385 98.5269 92.9999 100.753 92.9999 103.5ZM49.25 104.432H98V102.567H49.25V104.432Z"
        fill="#00A389"
      />
      <path d="M41.75 158.513H84.875" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
    </g>
    <defs>
      <clipPath id="clip0_23334_72167">
        <rect width="165" height="207" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default NoDataIcon;
