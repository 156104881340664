import React, { FC, useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import styles from './shaxmatkaEntranceHeader.module.scss';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { entranceNameSave } from '../helper/entranceNameSave';
import { useTranslation } from 'react-i18next';

interface IProps {
  setEntrance: React.Dispatch<React.SetStateAction<number | null>>;
  entrance: number | null;
  setSaveAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShaxmatkaEntranceNameModal: FC<IProps> = ({ setEntrance, entrance, setSaveAll }) => {
  const { t } = useTranslation();
  const { data } = useAppSelector(state => state.shaxmatkaReducer);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const handleClose = () => {
    setEntrance(null);
    setValue('');
  };

  useEffect(() => {
    if (entrance !== null) {
      setValue(
        data[entrance].flat()?.find((item: any) => item?.status === 'SOLD' && item?.entranceName)?.entranceName ||
          data[entrance].flat()?.find((item: any) => item?.entranceName)?.entranceName ||
          data[entrance][0][0].entrance ||
          ''
      );
    }
  }, [entrance, data]);

  const handleSave = () => {
    entranceNameSave({
      dispatch,
      index: entrance!,
      name: value,
      shaxmatka: data,
      setSaveAll
    });

    handleClose();
  };

  return (
    <Modal
      title={t('home_id.Podyezd')}
      visible={entrance !== null}
      onCancel={handleClose}
      footer={
        <Button type="primary" onClick={handleSave}>
          Saqlash
        </Button>
      }
      width={400}
      wrapClassName={styles.modal}
    >
      <p>{t('home.Nomi')}</p>
      <Input
        className="customInput"
        value={value}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleSave();
          }
        }}
        onChange={e => {
          setValue(e.target.value);
        }}
      />
    </Modal>
  );
};

export default ShaxmatkaEntranceNameModal;
