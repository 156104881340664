import React, { FC } from 'react';

interface IProps {
  style?: React.CSSProperties | undefined;
}

const SMSNotificationIcon: FC<IProps> = ({ style }) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="21" cy="21" r="20.5" stroke="#2196F3" />
      <path
        d="M26 29.5H16C13 29.5 11 28 11 24.5V17.5C11 14 13 12.5 16 12.5H26C29 12.5 31 14 31 17.5V24.5C31 28 29 29.5 26 29.5Z"
        stroke="#2196F3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 18L22.87 20.5C21.84 21.32 20.15 21.32 19.12 20.5L16 18"
        stroke="#2196F3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SMSNotificationIcon;
