import React from 'react';

const FolderOpenIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0583 11.9167L17.725 16.0834C17.6 17.3584 17.5 18.3334 15.2416 18.3334H4.7583C2.49997 18.3334 2.39997 17.3584 2.27497 16.0834L1.94163 11.9167C1.87497 11.2251 2.09163 10.5834 2.4833 10.0917C2.49163 10.0834 2.49163 10.0834 2.49997 10.0751C2.9583 9.51675 3.64997 9.16675 4.42497 9.16675H15.575C16.35 9.16675 17.0333 9.51675 17.4833 10.0584C17.4916 10.0667 17.5 10.0751 17.5 10.0834C17.9083 10.5751 18.1333 11.2167 18.0583 11.9167Z"
        stroke="#444444"
        strokeMiterlimit="10"
      />
      <path
        d="M2.91699 9.52498V5.23332C2.91699 2.39998 3.62533 1.69165 6.45866 1.69165H7.51699C8.57533 1.69165 8.81699 2.00832 9.21699 2.54165L10.2753 3.95832C10.542 4.30832 10.7003 4.52498 11.4087 4.52498H13.5337C16.367 4.52498 17.0753 5.23332 17.0753 8.06665V9.55832"
        stroke="#444444"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.8584 14.1667H12.1417" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FolderOpenIcon;
