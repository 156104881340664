/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import ArrowDown from '../../assets/icons/ArrowDown';
import Download from '../../assets/icons/Download';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useDownloadClientPaymentToWord } from '../../queries/mutation';
import { useClientBalanceHistoryList } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { getColorPaymentType } from '../../utils/helper/getColorPaymentType';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';
import { ClientBalanceHistoryList, DebitType } from '../../utils/models/Client';
import { PaymentType } from '../../utils/models/PaymentType';

import styles from './clientBalance.module.scss';

interface Props {
  clientId: string;
}

const TableBalance: React.FC<Props> = ({ clientId }) => {
  const { push } = useQueryParam();
  const { isLoading, data } = useClientBalanceHistoryList(clientId);
  const { t } = useTranslation();

  const downloadPayment = useDownloadClientPaymentToWord();

  const columns: ColumnsType<ClientBalanceHistoryList> | undefined = [
    {
      title: '№',
      dataIndex: 'number',
      render: (i: any, a: ClientBalanceHistoryList, index: number) => <>{index + 1}</>,
      width: 100
    },
    {
      title: t('client_balance.Mablag_kiritilgan_sana'),
      dataIndex: 'createdDate'
    },
    {
      title: t('client_balance.Kiritilgan_summa'),
      render: (record: ClientBalanceHistoryList) => (
        <>
          {record.amount?.toLocaleString('ru')} {record?.currency.ccy}
        </>
      )
    },
    {
      title: t('home.Tulov_turi'),
      dataIndex: 'type',
      render: (i: PaymentType, record) => (
        <>
          {i === PaymentType?.CUSTOM
            ? <span className='text-[#64748b]'>{record?.paymentCustomTypeName}</span>
            : <span style={{ color: getColorPaymentType(i) }}>{t(`home.${i}`)}</span>
          }
        </>
      )
    },
    {
      title: t('client_balance.Asos'),
      render: (record: ClientBalanceHistoryList) =>
        record.contractNumber ? (
          <span onClick={() => goToOneContractPage(record.contractId, record.contractNumber)} style={{ color: '#0078D2', cursor: 'pointer' }}>
            {t('hame.Shartnoma')} {record.contractNumber}
          </span>
        ) : (
          <span>-</span>
        )
    },
    {
      title: t('home.Masul'),
      dataIndex: 'createdBy'
    },
    {
      title: t('client_balance.Tranzaksiya_turi'),
      dataIndex: 'debit',
      render: (debit: DebitType) => (
        <div className={styles.table_debit}>
          <ArrowDown className={debit === DebitType.INCOME ? styles.debit_INCOME_icon : styles.debit_OUTCOME_icon} />
          <span className={debit === DebitType.INCOME ? styles.debit_INCOME_label : styles.debit_OUTCOME_label}>{t(`home.${debit}`)}</span>
        </div>
      )
    },
    {
      align: 'center',
      render: (item: ClientBalanceHistoryList) => (
        <div className="payment_table_download">
          <Download onClick={() => downloadPaymentWord(item.createdDate, item.id)} />
        </div>
      )
    }
  ];

  const downloadPaymentWord = (date: string, id: number) => {
    downloadPayment.mutate({
      id,
      date
    });
  };

  const goToOneContractPage = (id: number, contractNumber: string) => {
    push(`${rootPaths.MAIN}${MainPaths.CONTRACT}/${id}/${urlEncode(`№ ${contractNumber}`)}`);
  };

  return (
    <div className={styles.table}>
      <Table
        loading={isLoading}
        className="custom_table contract_table_size table_cursor_default"
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default TableBalance;
