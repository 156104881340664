import React from 'react';
import { LeadMessageBox } from '../../../../utils/models/LeadMessageBox';
import styles from './messageDrawer.module.scss';
import instagram from '../../../../assets/img/instagram.png';
import Facebook from '../../../../assets/img/Facebook.png';
import telegram from '../../../../assets/img/TelegramMini.png';
import { Badge } from 'antd';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';
import { useNavigate } from 'react-router-dom';
import { rootPaths } from '../../../../routing/rootPaths';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { urlEncode } from '../../../../utils/helper/urlEncodeDecode';
import { BASE_URL } from '../../../../service/RequestService';
import { dateDataDay } from '../../../../utils/helper/dateData';
import { useTranslation } from 'react-i18next';

const message = {
  INSTAGRAM: instagram,
  FACEBOOK: Facebook,
  TELEGRAM: telegram
};

interface IProps extends LeadMessageBox {
  you: boolean;
}

const MessageDrawerCard = (props: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  //handleClick
  const handleClick = () => {
    navigate(rootPaths.MAIN + MainPaths.CRM + '/' + props?.leadId + '/' + urlEncode(props?.leadName));
  };

  const mineText = () => {
    return props?.chatDto?.chatText?.length > 40 ? props?.chatDto?.chatText.slice(0, 40) + '...' : props?.chatDto?.chatText;
  };

  const chatDate = () => {
    const get_date = dayjs(props?.chatDto?.chatDate, DATE_WITH_TIME_FORMAT);
    const date_text = Object.values(dateDataDay).find(item => {
      const date = item?.date.format(DATE_FORMAT);
      return date === get_date.format(DATE_FORMAT);
    })?.text;

    return date_text ? t('one_lid.' + date_text) + ' ' + get_date.format('HH:mm') : props?.chatDto?.chatDate;
  };

  return (
    <div className={styles.card} onClick={handleClick}>
      <div className={styles.card_left}>
        {props?.contactChatDto?.avatar ? (
          <img src={BASE_URL + '/' + props?.contactChatDto?.avatar} alt="" className={styles.card_left_avatar} />
        ) : (
          props?.contactChatDto?.contactName?.slice(0, 2)
        )}
        <img src={message[props?.contactChatDto?.contactType]} alt="" className={styles.card_left_icon} />
      </div>
      <div className={styles.card_right}>
        <h3>
          {props?.leadName} <span>{chatDate()}</span>
        </h3>
        <p>
          {props?.you ? 'Siz: ' : (props?.chatDto?.fromName || 'Messenger') + ': '}
          {props?.chatDto?.fileType ? props?.chatDto?.fileType : mineText()}
        </p>
        <Badge className={styles.card_badge} count={props?.countNotReadChat} />
      </div>
    </div>
  );
};

export default MessageDrawerCard;
