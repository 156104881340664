import { useQuery } from 'react-query';
import { $api } from '../../../../../../../service/RequestService';
import { baseErrorHandler } from '../../../../../../../queries/baseErrorHandler';
import { queryKeys } from '../constants/queryKeys';
import { endPoints } from '../constants/endPoints';
import { SipuiAuth } from '../models/SipuiAuth';
import { SipEmployee } from '../models/SipEmployee';
import { SipContact } from '../models/SipContact';
import { SipCallHandlingEdit } from '../models/SipCallHandling';

export function useSipuniConfigGet() {
  return useQuery<SipuiAuth>(
    [queryKeys.SIPUNI_CONFIG],
    async () => {
      const res = await $api.get(endPoints.SIPUNI_CONFIG);
      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSipuniConfigEmployeeList(id: number | undefined) {
  return useQuery<SipEmployee[]>(
    [queryKeys.SIPUNI_CONFIG_EMPLOYEE, id],
    async () => {
      const res = await $api.get(endPoints.SIPUNI_CONFIG + '/' + id + '/employee');
      return res.data.data;
    },
    {
      enabled: !!id,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSipuniConfigCallHandlingGet(id: number | undefined) {
  return useQuery<SipCallHandlingEdit[]>(
    [queryKeys.SIPUNI_CONFIG_CALL_HANDLING, id],
    async () => {
      const res = await $api.get(endPoints.SIPUNI_CONFIG + '/' + id + '/call-handling');
      return res.data.data;
    },
    {
      enabled: !!id,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSipuniConfigContactGet(id: number | undefined) {
  return useQuery<SipContact[]>(
    [queryKeys.SIPUNI_CONFIG_CONTACT, id],
    async () => {
      const res = await $api.get(endPoints.SIPUNI_CONFIG + '/' + id + '/contact');
      return res.data.data;
    },
    {
      enabled: !!id,
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}
