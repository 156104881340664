import { createContext } from 'react';

export interface Methods {
  setExterminate: (props: IContext['exterminate']) => void;
}

export interface IContext extends Methods {
  exterminate: {
    id?: number | null;
    name?: string;
  };
  permissions: {
    PERMISSION_EXTERMINATE_CLIENT: boolean;
    PERMISSION_UPDATE_CLIENT: boolean;
    PERMISSION_SEND_MESSAGE_TO_CLIENT: boolean;
  };
}

const Context = createContext<IContext>({} as IContext);

export default Context;
