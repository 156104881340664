import React, { useEffect, useState } from 'react';
import styles from './socialMedias.module.scss';
import { Button, Form, Input } from 'antd';
import { useGetSocialNetworkUrls } from './services/queries';
import { useUpdateSocialNetworkUrls } from './services/mutation';
import { useTranslation } from 'react-i18next';

const SocialMedias = () => {
  const [form] = Form.useForm();
  const { data } = useGetSocialNetworkUrls();
  const updateSocialNetwork = useUpdateSocialNetworkUrls();
  const { t } = useTranslation();
  const [inputKeys, setInputKeys] = useState({
    COMPANY_TELEGRAM: '',
    COMPANY_FACEBOOK: '',
    COMPANY_INSTAGRAM: '',
    COMPANY_WEBSITE: ''
  });

  useEffect(() => {
    let obj: any = {};

    data?.urls?.forEach(item => {
      obj[item?.key] = item?.value;
    });
    if (data?.urls) {
      form.setFieldsValue(obj);
      setInputKeys(obj);
    }
  }, [data?.urls, form]);

  const onFinish = (values: { name: string; value: string }) => {
    const keys = Object.entries(values);
    const newData = keys?.map(item => (item ? { key: item[0], value: item[1] ? item[1] : '' } : item));
    updateSocialNetwork.mutateAsync(newData);
  };

  console.log(Object.entries(inputKeys));
  const inputChange = (key: string, value: string) => {
    setInputKeys(prev => ({ ...prev, [key]: value }));
  };

  const save = () => {
    return data?.urls?.length! > 0
      ? data?.urls?.some(item => inputKeys[item?.key as keyof typeof inputKeys] !== item.value)
      : Object.values(inputKeys).some(item => item.length)
        ? true
        : false;
  };

  return (
    <div className={styles.socialMedia}>
      <div className={styles.socialMedia_header}>
        <h1>{t('integration.Ijtimoiy_tarmoqlar')}</h1>
      </div>
      <div className={styles.socialMedia_form}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className={styles.socialMedia_form_top}>
            <Form.Item label={`${t('company_links.COMPANY_TELEGRAM')}`} name="COMPANY_TELEGRAM">
              <Input placeholder="uysot_uz" onChange={e => inputChange('COMPANY_TELEGRAM', e?.target.value)} />
            </Form.Item>
            <Form.Item label={`${t('company_links.COMPANY_FACEBOOK')}`} name="COMPANY_FACEBOOK">
              <Input placeholder="uysot_uz" onChange={e => inputChange('COMPANY_FACEBOOK', e?.target.value)} />
            </Form.Item>
          </div>
          <div className={styles.socialMedia_form_bottom}>
            <Form.Item label={`${t('company_links.COMPANY_INSTAGRAM')}`} name="COMPANY_INSTAGRAM">
              <Input placeholder="uysot_uz" onChange={e => inputChange('COMPANY_INSTAGRAM', e?.target.value)} />
            </Form.Item>
            <Form.Item label={`${t('company_links.COMPANY_WEBSITE')} (url)`} name="COMPANY_WEBSITE">
              <Input placeholder="https://website.com" onChange={e => inputChange('COMPANY_WEBSITE', e?.target.value)} />
            </Form.Item>
          </div>
          {save() && (
            <div className={styles.socialMedia_form_footer}>
              <Button htmlType="submit" loading={updateSocialNetwork?.isLoading}>
                {t('home.Saqlash')}
              </Button>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SocialMedias;
