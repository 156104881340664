import { useAppSelector } from '@hooks/reduxHooks';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $apiVisual } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { IWebsiteBlockFloorEmptyFlatCount } from '../utils/models/IWebsiteBlockFloorEmptyFlatCount';
import { IWebsiteBlockFloorStatistic } from '../utils/models/IWebsiteBlockFloorStatistic';
import { IWebsiteFlatStatistics } from '../utils/models/IWebsiteFlatStatistics';

export function useWebsiteBlockFloorStatistic(blockId: number) {
  const token = useAppSelector(state => state.showroomSlice.token);

  return useQuery<IWebsiteBlockFloorStatistic>(
    [queryKeys.WEBSITE_BLOCK_FLOOR_STATISTIC, blockId, token],
    async () => {
      const res = await $apiVisual.get(`${endPoints.WEBSITE_BLOCK_FLOOR_STATISTIC}/${blockId}`, {
        headers: {
          'X-Auth': token?.xAuthToken!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!blockId && !!token?.xAuthToken,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useWebsiteBlockFloorEmptyFlatCount(blockId: number) {
  const token = useAppSelector(state => state.showroomSlice.token);

  return useQuery<IWebsiteBlockFloorEmptyFlatCount[]>(
    [queryKeys.WEBSITE_BLOCK_ID_FLOOR_EMPTY_FLAT_COUNT, blockId, token],
    async () => {
      const res = await $apiVisual.get(endPoints.WEBSITE_BLOCK_ID_FLOOR_EMPTY_FLAT_COUNT(blockId), {
        headers: {
          'X-Auth': token?.xAuthToken!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!blockId && !!token?.xAuthToken,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useWebsiteBlockFlatStatistic(blockId: number, floor: number) {
  const token = useAppSelector(state => state.showroomSlice.token);

  return useQuery<IWebsiteFlatStatistics>(
    [queryKeys.WEBSITE_BLOCK_FLAT_DATA, blockId, floor, token],
    async () => {
      const res = await $apiVisual.get(`${endPoints.WEBSITE_BLOCK_FLAT_DATA}/${blockId}?floor=${floor}`, {
        headers: {
          'X-Auth': token?.xAuthToken!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!blockId && !!token?.xAuthToken,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}
