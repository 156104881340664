import React, { useEffect, useRef } from 'react';
import { Spin } from 'antd';

import styles from './completedTasks.module.scss';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useCompletedTask } from '../../../queries/queries';
import { Task } from '../../../utils/models/Task';
import { TaskType } from '../../../utils/models/TaskType';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import NoteTypeTask from './NoteTypeTask';
import PaymentTypeTask from './PaymentTypeTask';
import CompletedTask from './CompletedTask';

const CompletedTasks = () => {
  const { params } = useQueryParam();

  const bottomRef = useRef<HTMLDivElement>(null);

  const { data, isFetching, isLoading } = useCompletedTask(Number(params.contractId));

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [data]);

  const showTaskTypes = (task: Task) => {
    switch (task.type) {
      case TaskType.EVENT:
        return eventType(task);
      case TaskType.NOTE:
        return <NoteTypeTask task={task} />;
      case TaskType.PAYMENT:
        return <PaymentTypeTask task={task} />;
      default:
        return <CompletedTask event={task} />;
    }
  };

  const eventType = (event: Task) => {
    return (
      <p className={styles.information}>
        {event.date} {getCurrentLanguageWord(event.text)}
      </p>
    );
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading || isFetching}>
        {data?.map((item, index) => (
          <div key={index}>
            <div className={styles.date}>
              <span>{item.date}</span>
            </div>
            {item.events.map((event, index) => (
              <div key={index}>{showTaskTypes(event)}</div>
            ))}
          </div>
        ))}
        <div ref={bottomRef} />
      </Spin>
    </div>
  );
};

export default CompletedTasks;
