import React from 'react';
import { Select, Spin, Switch } from 'antd';

import styles from './onlinePBX.module.scss';
import { useMyCallsEmployee, useOnlinePbxEmployee } from '../../../../../queries/queries';
import { OnlinePbx, OnlinePbxUser } from '../../../../../utils/models/OnlinePbx';
import { useSetEmployeeOnlinePbx } from '../../../../../queries/mutation';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface Props {
  onlinePbx: OnlinePbx | null;
}

const UsersPBX: React.FC<Props> = ({ onlinePbx }) => {
  const { data: employee, isLoading: loadingEmployee } = useMyCallsEmployee();
  const { isLoading, data } = useOnlinePbxEmployee(onlinePbx?.id || -1);

  const { t } = useTranslation();

  const setEmployee = useSetEmployeeOnlinePbx(onlinePbx?.id || -1);

  const handleChange = (emp: OnlinePbxUser, empId: number) => {
    setEmployee.mutate({
      employeeId: empId,
      onlinePbxEmployeeId: emp.id,
      onlinePbxConfigId: emp.onlinePbxConfId
    });
  };

  const handleChangeSwitch = (emp: OnlinePbxUser, checked: boolean) => {
    setEmployee.mutate({
      employeeId: emp?.employeeId,
      onlinePbxEmployeeId: emp.id,
      onlinePbxConfigId: emp.onlinePbxConfId,
      allowWebCall: checked
    });
  };

  return (
    <Spin spinning={isLoading || setEmployee.isLoading}>
      <div className={styles.container_body_employee}>
        <h3 className={styles.container_body_employee_title}>{t('online_pbx.Xodimlarni_sozlash')}</h3>
        <p className={styles.container_body_employee_sub_title}>{t('online_pbx.OnlinePBX_platformasi_va_Uysot_platformasidagi')}</p>
        {data?.map((emp, index) => (
          <div key={index}>
            <div className={styles.container_body_employee_item}>
              <span className={styles.container_body_employee_item_name}>{emp.name}</span>
              <Select
                onChange={val => handleChange(emp, val)}
                value={emp.employeeId}
                className={styles.container_body_employee_item_select}
                loading={loadingEmployee}
              >
                {employee?.map((emp, index) => (
                  <Option value={emp.id} key={index}>
                    {emp.fullName}
                  </Option>
                ))}
              </Select>
              <Switch checked={!!emp?.allowWebCall} style={{ marginLeft: 10 }} onChange={checked => handleChangeSwitch(emp, checked)} />
            </div>
            <hr />
          </div>
        ))}
      </div>
    </Spin>
  );
};

export default UsersPBX;
