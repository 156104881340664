import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";

export function useGetCurrency() {
    return useQuery<any>(
        [queryKeys.CURRENCY_SOFT],
        async () => {
            const res = await $api.get(endPoints.CURRENCY_SOFT);

            return res.data.data;
        },
        {
            refetchOnWindowFocus: false,
            onError: baseErrorHandler,
            refetchInterval: Infinity,
            retry: false
        }
    );
}