import React, { ReactNode, useState } from 'react';
import DeleteTaskIcon from '@icons/DeleteTaskIcon';
import { Button, Modal } from 'antd';
import { t } from 'i18next';

import styles from './deleteConfirm.module.scss';

type Props = {
  children: ReactNode;
  onConfirm: () => Promise<unknown>;
  isLoading?: boolean;
  text: string;
};

const DeleteConfirm1: React.FC<Props> = ({ children, isLoading, onConfirm, text }) => {
  const [visible, setVisible] = useState(false);

  // open
  const handleOpen = () => {
    setVisible(true);
  };

  // close
  const handleClose = () => {
    setVisible(false);
  };

  // confirm
  const handleConfirm = () => {
    onConfirm().then(handleClose);
  };

  return (
    <>
      <Modal
        visible={visible}
        closable={false}
        onCancel={handleClose}
        className={styles.confirm}
        width={548}
        footer={
          <>
            <Button onClick={handleClose}>{t('crm.Bekor_qilish')}</Button>
            <Button type="primary" danger onClick={handleConfirm} loading={isLoading}>
              {t('contract.Ochirish')}
            </Button>
          </>
        }
      >
        <div className={styles.body}>
          <DeleteTaskIcon />
          <p className={styles.text}>{text}</p>
          <span>{t('home.Bu_amalni_ortga_qaytarib_bolmaydi')}</span>
        </div>
      </Modal>
      <div onClick={handleOpen}>{children}</div>
    </>
  );
};

export default DeleteConfirm1;
