import React from 'react';

interface ArrowCircleLeftProps {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const ArrowCircleLeft: React.FC<ArrowCircleLeftProps> = ({ className, onClick }) => {
  return (
    <svg onClick={onClick} className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5Z"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.7398 16.03L14.2598 12.5L10.7398 8.96997" stroke="#00A389" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowCircleLeft;
