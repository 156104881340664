import React from 'react';
import DocumentTextIcon from '../../assets/icons/DocumentTextIcon';
import { Progress } from 'antd';
import FilterItemClose from '../../assets/icons/FilterItemClose';
import { useTranslation } from 'react-i18next';

interface Props {
  file: File;
  onCancel: () => void;
  uploadProgress: number;
}

const ImportingComponent: React.FC<Props> = ({ file, uploadProgress, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="companySettingDropzoneUpload">
      <div className="companySettingDropzoneUploadContent">
        <div>
          <span>
            <DocumentTextIcon />
          </span>
          <div>
            <h1>{file?.name}</h1>
            <p>{Math.floor(file?.size / 1024)} KB</p>
          </div>
        </div>
        <div className={'companySettingDropzoneUploadContentCancel'}>
          <div onClick={onCancel}>
            <FilterItemClose />
            <p>{t('home.Bekor_qilish')}</p>
          </div>
        </div>
      </div>
      <Progress
        strokeColor={'var(--ant-primary-color)'}
        percent={uploadProgress}
        status="active"
        style={{ width: 'calc(100% - 60px)', float: 'right' }}
      />
    </div>
  );
};

export default ImportingComponent;
