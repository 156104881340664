import React from 'react';

const F4fIcon = () => {
  return (
    <svg width="760" height="313" viewBox="0 0 760 313" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 239.401V196.897L106.571 29H143.217V87.8324H121.529L54.3452 194.154V196.149H205.789V239.401H0ZM122.526 284.273V226.438L123.523 207.616V29H174.129V284.273H122.526Z"
        fill="#00A389"
      />
      <path
        d="M553.673 239.401V196.897L660.244 29H696.89V87.8324H675.202L608.018 194.154V196.149H759.462V239.401H553.673ZM676.199 284.273V226.438L677.196 207.616V29H727.802V284.273H676.199Z"
        fill="#00A389"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M253 126.5C253 56.6359 309.636 0 379.5 0C449.364 0 506 56.636 506 126.5V312.944L474.375 287.821L442.75 312.944L411.125 287.821L379.5 312.944L347.875 287.821L316.25 312.944L284.625 287.821L253 312.944V126.5ZM462.805 120.329C462.805 139.56 447.215 155.15 427.984 155.15C408.753 155.15 393.164 139.56 393.164 120.329C393.164 101.098 408.753 85.5087 427.984 85.5087C447.215 85.5087 462.805 101.098 462.805 120.329ZM331.897 155.15C351.128 155.15 366.718 139.56 366.718 120.329C366.718 101.098 351.128 85.5087 331.897 85.5087C312.666 85.5087 297.077 101.098 297.077 120.329C297.077 139.56 312.666 155.15 331.897 155.15Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default F4fIcon;
