import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {}

const AIStarOneIcon = (props: IProps) => (
  <svg width="4" height="4" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.369 6.45823C3.29684 6.45823 3.22694 6.43854 3.15929 6.39916C3.09164 6.35979 3.03977 6.30509 3.00369 6.23508L2.12423 4.4105L0.230012 3.58353C0.157852 3.54853 0.101476 3.49821 0.0608856 3.43258C0.0202952 3.36695 0 3.29912 0 3.22912C0 3.15911 0.0202952 3.09129 0.0608856 3.02566C0.101476 2.96002 0.157852 2.90971 0.230012 2.8747L2.12423 2.04773L3.00369 0.236277C3.03977 0.157518 3.09164 0.0984486 3.15929 0.0590692C3.22694 0.0196897 3.29684 0 3.369 0C3.44116 0 3.51107 0.0196897 3.57872 0.0590692C3.64637 0.0984486 3.69824 0.153142 3.73432 0.22315L4.62731 2.04773L6.508 2.8747C6.58918 2.90971 6.65006 2.96002 6.69065 3.02566C6.73124 3.09129 6.75154 3.15911 6.75154 3.22912C6.75154 3.29912 6.73124 3.36695 6.69065 3.43258C6.65006 3.49821 6.58918 3.54853 6.508 3.58353L4.62731 4.4105L3.73432 6.23508C3.69824 6.31384 3.64637 6.37072 3.57872 6.40573C3.51107 6.44073 3.44116 6.45823 3.369 6.45823Z"
      fill="#49CCF9"
    />
  </svg>
);

export default AIStarOneIcon;
