import React from 'react';

const EnIcon = () => {
  return (
    <svg width="35" height="25" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8145_43093)">
        <path
          d="M2.04375 24.5H32.9563C33.95 24.4438 34.75 23.6125 34.75 22.6062V2.39375C34.75 1.35625 33.9062 0.50625 32.8687 0.5H2.13125C1.09375 0.50625 0.25 1.35625 0.25 2.39375V22.6C0.25 23.6125 1.05 24.4438 2.04375 24.5Z"
          fill="#FEFEFE"
        />
        <path d="M15.0875 14.8938V24.5H19.8875V14.8938H34.75V10.0938H19.8875V0.5H15.0875V10.0938H0.25V14.8938H15.0875Z" fill="#C8102E" />
        <path d="M21.4875 8.26875V0.5H32.8813C33.6688 0.5125 34.3438 1.00625 34.6188 1.7L21.4875 8.26875Z" fill="#012169" />
        <path d="M21.4875 16.7305V24.4992H32.9563C33.7126 24.4555 34.35 23.968 34.6188 23.2992L21.4875 16.7305Z" fill="#012169" />
        <path d="M13.4876 16.7305V24.4992H2.04385C1.2876 24.4555 0.643848 23.968 0.381348 23.2867L13.4876 16.7305Z" fill="#012169" />
        <path d="M13.4876 8.26875V0.5H2.11885C1.33135 0.5125 0.650098 1.0125 0.381348 1.7125L13.4876 8.26875Z" fill="#012169" />
        <path d="M0.25 8.49922H5.03125L0.25 6.10547V8.49922Z" fill="#012169" />
        <path d="M34.7501 8.5H29.9438L34.7501 6.09375V8.5Z" fill="#012169" />
        <path d="M34.7501 16.5H29.9438L34.7501 18.9063V16.5Z" fill="#012169" />
        <path d="M0.25 16.5H5.03125L0.25 18.8937V16.5Z" fill="#012169" />
        <path d="M34.75 2.53125L22.8438 8.5H25.5062L34.75 3.875V2.53125Z" fill="#C8102E" />
        <path d="M12.1313 16.5H9.46875L0.25 21.1125V22.4563L12.1562 16.5H12.1313Z" fill="#C8102E" />
        <path d="M6.825 8.50586H9.4875L0.25 3.88086V5.21836L6.825 8.50586Z" fill="#C8102E" />
        <path d="M28.1437 16.4941H25.4812L34.75 21.1379V19.8004L28.1437 16.4941Z" fill="#C8102E" />
      </g>
      <defs>
        <clipPath id="clip0_8145_43093">
          <rect width="34.5" height="24" fill="white" transform="translate(0.25 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnIcon;
