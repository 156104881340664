import React, { useState } from 'react';
import styles from '../clients.module.scss';
import { Spin } from 'antd';
import TrueIcon from '@icons/TrueIcon';
import CloseIcon from '@icons/contract/CloseIcon';
import { InviteClientModel } from '@pages/oneLid/utils/models/InviteClientModel';
import { useQueryParam } from '@hooks/useQueryParams';
import { useAttachClient, useIgnoreDublicateLeads } from '@pages/oneLid/services/mutation';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import DublicateModal from './DublicateModal';
import { setChangeDublicateClientId } from '@reducers/CrmOneLidSlice';
import { Navigate, useNavigate } from 'react-router-dom';
import { rootPaths } from '@routing/rootPaths';
import { MainPaths } from '@routing/main/mainPaths';
import { urlDecode } from '@utils/helper/urlEncodeDecode';

type Props = {
  item: InviteClientModel;
};

const RecomClient: React.FC<Props> = ({ item }) => {
  const navigation = useNavigate();
  const ignoreClients = useIgnoreDublicateLeads();
  const { t } = useTranslation();
  const { params } = useQueryParam();
  const attachClient = useAttachClient();
  const dispatch = useDispatch();
  const [openDublicateModal, setOpenDublicateModal] = useState(false);

  const handleAttachClient = (clientId: number, leadId: number) => {
    attachClient.mutateAsync({ leadId, clientId });
  };

  const handleIgnoreClients = (currentObjectId: number, ignoreObjectIds: number[], ignoreType: string) => {
    ignoreClients.mutate({
      currentObjectId: currentObjectId,
      ignoreObjectIds: ignoreObjectIds,
      ignoreType: ignoreType
    });
  };

  const goToClient = (id: number, name: string) => {
    navigation(rootPaths.MAIN + MainPaths.CLIENT + `/${id}/${urlDecode(name)}`);
  };

  return (
    <>
      <div className={styles.recommendedCustomer_item}>
        <span className={styles.recommendedCustomer_item_title}>{t('home.Tavsiya_etilyotgan_mijoz')}</span>
        <div className={styles.recommendedCustomer_item_body}>
          <div className={styles.recommendedCustomer_item_body_left} style={{ cursor: 'pointer' }} onClick={() => goToClient(item?.id, item?.name)}>
            <div className={styles.circle}>{item?.name.substring(0, 1)}</div>
            <div>
              <p>{item?.name}</p>
              <span>{item?.phone}</span>
            </div>
          </div>
          <div className={styles.recommendedCustomer_item_body_right}>
            <Spin spinning={attachClient.isLoading}>
              <div
                className={styles.checkIcons}
                onClick={() => {
                  item?.lead ? setOpenDublicateModal(true) : handleAttachClient(Number(item?.id), Number(params?.id));
                  dispatch(
                    setBalanceAndIds({
                      ids: [Number(params?.id!), Number(item?.lead?.id)]
                    })
                  );
                  dispatch(setChangeDublicateClientId(item?.id));
                }}
              >
                <TrueIcon />
              </div>
            </Spin>
            <div className={styles.checkIcons} onClick={() => handleIgnoreClients(params?.id, [item?.id], 'CLIENT')}>
              <CloseIcon />
            </div>
          </div>
        </div>
      </div>
      <DublicateModal
        openDublicateModal={openDublicateModal}
        setOpenDublicateModal={setOpenDublicateModal}
        type="recom-client"
        clientId={Number(params?.id)}
      />
    </>
  );
};

export default RecomClient;
