import { useOneCrmSettingRole } from '@pages/crm-settings/services/mutation';
import { Button, Form, Input, Modal } from 'antd';
import React from 'react';

import styles from './createRole.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  openRole: boolean;
  setOpenRole: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateRole: React.FC<Props> = ({ openRole, setOpenRole }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const createRole = useOneCrmSettingRole();
  //handle cancel
  const handleCancel = () => {
    setOpenRole(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    const sendData = {
      name: values?.name,
      leadRead: 'DENY',
      leadSave: 'DENY',
      leadDelete: 'DENY',
      taskRead: 'DENY',
      taskSave: 'DENY',
      taskDelete: 'DENY'
    };
    createRole.mutateAsync(sendData).then(handleCancel);
  };

  return (
    <Modal visible={openRole} onCancel={handleCancel} title={<span>{t('crm.Role_yaratish')}</span>} footer={false} className={styles.createRole}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label={t('crm.Role_nomini_kiriting')} name="name">
          <Input />
        </Form.Item>
        <div className={styles.footer}>
          <Button onClick={handleCancel}>{t('one_lid.Bekor_qilish')}</Button>
          <Button htmlType="submit">{t('one_task.Saqlash')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateRole;
