import React, { useState } from 'react';
import ReceiveSquareIcon from '@icons/ReceiveSquareIcon';
import { ResponsivePie } from '@nivo/pie';
import { Spin, Tooltip } from 'antd';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import BossPaymentModal from './components/boss-payment-modal/BossPaymentModal';
import { useGetPaymentDelayExcel } from './services/mutation';
import { useGetPaymentDelay } from './services/queries';

import styles from './bossPaymentTerm.module.scss';

const CenteredMetric = ({ dataWithArc, centerX, centerY }: any) => {
  let total = 0;

  dataWithArc.forEach((datum: any) => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '52px',
        fontWeight: 600
      }}
    >
      {total}
    </text>
  );
};

const BossPaymentTerm = () => {
  const [visible, setVisible] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [paymentName, setPaymentName] = useState('');
  const [paymentColor, setPaymentColor] = useState('');

  const { i18n } = useTranslation();

  const { data: paymentDelay } = useGetPaymentDelay();
  const downloadPaymentDelayExcel = useGetPaymentDelayExcel();

  const data = [
    {
      id: t('boss_payments.oz_vaqtida'),
      label: t('boss_payments.oz_vaqtida'),
      value: paymentDelay?.punctual,
      color: '#0CAF61',
      type: 'PUNCTUAL'
    },
    {
      id: t('boss_payments.bir_oygacha'),
      label: t('boss_payments.bir_oygacha'),
      value: paymentDelay?.afterMonth,
      color: '#8B62FF',
      type: 'AFTER_MONTH'
    },
    {
      id: t('boss_payments.uch_oygacha'),
      label: t('boss_payments.uch_oygacha'),
      value: paymentDelay?.after3Month,
      color: '#FFD023',
      type: 'AFTER_3_MONTH'
    },
    {
      id: t('boss_payments.bir_yilgacha'),
      label: t('boss_payments.bir_yilgacha'),
      value: paymentDelay?.afterYear,
      color: '#FE9649',
      type: 'AFTER_YEAR'
    },
    {
      id: t('boss_payments.bir_yildan_oshib'),
      label: t('boss_payments.bir_yildan_oshib'),
      value: paymentDelay?.afterNYear,
      color: '#0061FF',
      type: 'AFTER_N_YEAR'
    },
    {
      id: t('boss_payments.umuman'),
      label: t('boss_payments.umuman'),
      value: paymentDelay?.nonPunctual,
      color: '#CC413A',
      type: 'NON_PUNCTUAL'
    }
  ];

  // handleOpen
  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <div className={styles.container}>
      <div className="flex items-center justify-between">
        <h1 className={styles.title}>{t('showroom.Tolov_muddati')}</h1>
        <Tooltip title="Excel" placement="top">
          <Spin spinning={downloadPaymentDelayExcel.isLoading}>
            <button onClick={() => downloadPaymentDelayExcel.mutate(i18n.language.toUpperCase() as any)} className="cursor-pointer border-none bg-transparent p-0">
              <ReceiveSquareIcon />
            </button>
          </Spin>
        </Tooltip>
      </div>
      <div className={styles.chart}>
        <ResponsivePie
          data={data}
          margin={{ top: 40, right: 60, bottom: 40, left: 0 }}
          innerRadius={0.6}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          colors={data => data?.data?.color}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]]
          }}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          layers={['arcs', CenteredMetric]}
        />
      </div>
      <div className={styles.type}>
        {data?.map(item => (
            <div
              onClick={() => {
                handleOpen();
                setPaymentType(item?.type);
                setPaymentName(item?.label);
                setPaymentColor(item?.color);
              }}
              className={styles.type_item}
              key={item?.id}
            >
              <p>
                <span className={styles.type_item_circle} style={{ borderColor: item?.color }} />
                {item?.label}
              </p>
              <b>{item?.value}</b>
            </div>
          ))}
      </div>
      <BossPaymentModal paymentType={paymentType} visible={visible} setVisible={setVisible} title={paymentName} color={paymentColor} />
    </div>
  );
};

export default BossPaymentTerm;
