import React, { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { cx } from '@utils/cx';

import { useContractVariationGroupCreate } from '../hooks';
import { IVariationGroup, variationGroup } from '../schema';

type Props = UseFormReturn<IVariationGroup> & { onSubmit?: (e?: any) => void };

interface IProps {
  children: (props: Props) => React.ReactNode;
  className?: string;
  loading?: boolean;
  defaultValues?: IVariationGroup;
  closeAction?: (data?: IVariationGroup) => void;
}

const Offer = ({ children, className, closeAction, defaultValues }: IProps) => {
  const mutation = useContractVariationGroupCreate({ onSuccess: closeAction });
  const form: Props = useForm<IVariationGroup>({
    defaultValues,
    resolver: zodResolver(variationGroup)
  });

  const onFinish = async (values: IVariationGroup) => {
    await new Promise(onSettled => {
      mutation.mutate(
        {
          data: values,
          id: values?.groupId
        },
        { onSettled }
      );
    });
  };

  form.onSubmit = e => form.handleSubmit(onFinish)(e);

  useEffect(() => {
    form.reset({
      ...defaultValues
    });
  }, [defaultValues]);

  return (
    <form onSubmit={form.onSubmit} className={cx('w-full', className)}>
      {children(form)}
    </form>
  );
};

export default Offer;
