import React, { useState } from 'react';
import { Tooltip } from 'global';
import { useTranslation } from 'react-i18next';

import ReceiveSquare from '../../assets/icons/ReceiveSquare';
import TableSettingsIcon from '../../assets/icons/TableSettings';
import { useQueryParam } from '../../hooks/useQueryParams';
import { TableKey } from '../../utils/models/TableKey';

import TableConfigDrawer from './TableConfigDrawer';

import styles from './tableSettings.module.scss';

interface Props {
  tableKey: TableKey;
  downloadButton?: React.ReactElement;
}

const TableSettings: React.FC<Props> = ({ tableKey, downloadButton }) => {
  const { appendSingle } = useQueryParam();
  const [visibleDriver, setVisibleDriver] = useState(false);

  const { t } = useTranslation();

  const handleClick = (visible: boolean) => {
    setVisibleDriver(visible);
  };

  const deleteOrderTable = () => {
    appendSingle('order', undefined);
  };

  return (
    <>
      <div className={`${styles.cont} d-flex align-center justify-space-between`}>
        <Tooltip placement="bottom" title={t('home.Saralashlarni_tozalash')}>
          <span onClick={deleteOrderTable} className={styles.icon}>
            <ReceiveSquare />
          </span>
        </Tooltip>
        {downloadButton}
        <span onClick={() => handleClick(true)} className={styles.icon}>
          <TableSettingsIcon />
        </span>
      </div>
      <TableConfigDrawer tableKey={tableKey} visible={visibleDriver} onClose={handleClick} />
    </>
  );
};

export default TableSettings;
