import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import { useGetUnsuccessfullLeads } from '@pages/bossCrm/services/mutate';
import { Leads } from '@pages/bossCrm/utils/models/UnsuccessfulLeads';
import { setUnSuccessfulLeadsModal } from '@reducers/CrmStatisticsSlice';
import { encodeString, generateReqBody } from '@utils/index';
import { Input, Modal, Pagination, Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import LidIcon from '../conversion/assets/icons/LidIcon';
import SearchMdIcon from '../conversion/assets/icons/SearchMdIcon';

import styles from './index.module.scss';

const CommonUnSuccessLeadsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [time, setTime] = useState<NodeJS.Timeout>();
  const { data, mutate, isLoading } = useGetUnsuccessfullLeads();
  const { visible, reasonsFRId, responsibleById, type } = useAppSelector(({ crmStatistic }) => crmStatistic.unSuccessfulLeadModal);
  const { startDatePipe, finishDatePipe, selectedPipe } = useAppSelector(({ crmStatistic }) => crmStatistic);

  const onCancel = () => {
    setSearch(undefined);
    setPage(1);

    dispatch(
      setUnSuccessfulLeadsModal({
        visible: false,
        reasonsFRId,
        type,
        responsibleById
      })
    );
  };

  const columns: TableColumnsType<Leads> = [
    {
      title: t('boss_crm_conversion.Lid'),
      key: 'lidName',
      render: (record: Leads) => (
        <span
          className={styles.lidName}
          style={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => {
            navigate(`/main/crm/${record?.leadId}/${encodeString(record?.leadName!)}?prev=true`);
          }}
        >
          {record.leadName}
          <LidIcon />
        </span>
      )
    },
    {
      title: t('boss_crm_conversion.Sabab'),
      key: 'reason',
      dataIndex: 'reason'
    },
    {
      title: t('boss_crm_conversion.Pipeline_va_bosqichi'),
      key: 'pipeLine',
      render: (record: Leads) => (
        <span>
          {record.pipeName} {record.pipeStatusName}
        </span>
      )
    },
    {
      title: t("boss_crm_conversion.Ma'sul"),
      key: 'masul',
      dataIndex: 'responsibleName',
      align: 'center'
    }
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearch(value);

    clearTimeout(time);

    setTime(
      setTimeout(() => {
        mutate(
          generateReqBody({
            page: 1,
            size: 10,
            search: value,
            startDate: startDatePipe,
            finishDate: finishDatePipe,
            pipeIds: [selectedPipe],
            [type === 'REASON' ? 'reasonsFRIds' : 'responsibleById']: [type === 'REASON' ? reasonsFRId : responsibleById]
          })
        );
      }, 500)
    );
  };

  useEffect(() => {
    if (visible) {
      mutate(
        generateReqBody({
          page,
          search,
          size: 10,
          startDate: startDatePipe,
          finishDate: finishDatePipe,
          pipeIds: [selectedPipe?.id],
          [type === 'REASON' ? 'reasonsFRIds' : 'responsibleById']: [type === 'REASON' ? reasonsFRId : responsibleById]
        })
      );
    }
  }, [visible, reasonsFRId]);

  return (
    <Modal centered width={1000} footer={null} visible={visible} onCancel={onCancel} className={styles.modal} title={t('boss_crm_conversion.Muvaffaqiyatsiz_Lidlar')}>
      <div className={styles.container}>
        <div className={styles.filter}>
          <Input value={search} prefix={<SearchMdIcon />} onChange={onChange} placeholder={t("boss_crm_conversion.Lid va sababi bo'yicha qidiring")} />
        </div>
        <Table pagination={false} loading={isLoading} className={styles.table} dataSource={data?.data} columns={columns} scroll={{ y: 340 }} />
        <div className={styles.footer}>
          <Pagination
            pageSize={10}
            current={page}
            total={data?.totalElements}
            onChange={e => {
              setPage(e);

              mutate(
                generateReqBody({
                  search,
                  page: e,
                  size: 10,
                  pipeIds: [selectedPipe],
                  startDate: startDatePipe,
                  finishDate: finishDatePipe,
                  [type === 'REASON' ? 'reasonsFRIds' : 'responsibleById']: [type === 'REASON' ? reasonsFRId : responsibleById]
                })
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CommonUnSuccessLeadsModal;
