import React from 'react';
import { Button, Modal } from 'antd';
import styles from './order.module.scss';
import { useTranslation } from 'react-i18next';
import HouseInformation from './HouseInformation';

interface Props {
  open: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

const OrderFlatInfoModal: React.FC<Props> = ({ open, close, id }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    close(false);
  };

  return (
    <React.Fragment>
      <Modal className={styles.flat_info_modal} footer={null} closable={false} visible={open} width={1219} onCancel={handleClose}>
        <HouseInformation id={id} />
        <div className={'d-flex justify-center'}>
          <Button type={'primary'} onClick={handleClose} className={styles.flat_info_modal_btn_cancel}>
            {t('home.Yopish')}
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OrderFlatInfoModal;
