import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Layout, Row, Spin } from 'antd';

import HeaderCreateClient from '../../components/contractCreateClient/HeaderCreateClient';
import Juridical from '../../components/contractCreateClient/juridical/Juridical';
import ListFindClients from '../../components/contractCreateClient/ListFindClients';
import Natural from '../../components/contractCreateClient/natural/Natural';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useClientByOrderIdOrClientId } from '../../queries/queries';
import { clearClientReducer } from '../../store/reducers/ClientSlice';
import { ClientType } from '../../utils/models/ClientType';

import styles from './clientCreateContract.module.scss';

const { Content } = Layout;

const ClientCreateContract = () => {
  const params = useParams<{ orderIdOrFlatId: string }>();

  const dispatch = useAppDispatch();
  const { isLoading, data } = useClientByOrderIdOrClientId(params.orderIdOrFlatId || '');
  const [countSearchClient, setCountSearchClient] = useState(0);
  const { type } = useAppSelector(state => state.clientReducer);

  useEffect(() => {
    dispatch(clearClientReducer());
  }, []);

  const getCountFindClients = (count: number) => {
    setCountSearchClient(count);
  };

  const showFindClients = () => countSearchClient > 0;

  const getActivePage = () => {
    if (type === ClientType.NATURAL) {
      return <Natural />;
    }
    return <Juridical />;
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <Spin spinning={isLoading}>
          <Row gutter={[16, 0]}>
            <Col span={showFindClients() ? 17 : 24}>
              <div className={styles.homeCondition}>
                <HeaderCreateClient setCountSearchClient={setCountSearchClient} data={data} />
                {getActivePage()}
              </div>
            </Col>
            <Col span={showFindClients() ? 7 : 0}>
              <ListFindClients getCount={getCountFindClients} />
            </Col>
          </Row>
        </Spin>
      </Content>
    </>
  );
};

export default ClientCreateContract;
