import { useState } from 'react';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import { cx } from '@utils/cx';
import { Form, Spin, Switch } from 'antd';
import { Tooltip } from 'global';
import { useTranslation } from 'react-i18next';

import { useSavePaymentConfig } from '../../../queries/mutation';
import { usePaymentConfig } from '../../../queries/queries';
import { PaymentType } from '../../../utils/models/PaymentType';

import CustomPayment from './custom-payment/CustomPayment';
import { usePostPaymentCustomType } from './custom-payment/service/mutation';
import { useGetPaymentCustomType } from './custom-payment/service/queries';

import styles from './paymentWays.module.scss';

const PaymentWays = () => {
  const { data, isLoading } = usePaymentConfig();
  const save = useSavePaymentConfig();
  const [form] = Form.useForm<any>();

  const { data: dataPaymentCustomType } = useGetPaymentCustomType();
  const postPaymentCustomType = usePostPaymentCustomType();


  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  const isActivePayment = (type: PaymentType) => {
    if (data) {
      return !!data.types.includes(type);
    }
    return false;
  };

  const handleSave = (type: PaymentType) => {
    if (data) {
      const newData = [...data.types];

      if (newData.includes(type)) {
        const index = newData.indexOf(type);

        newData.splice(index, 1);
      } else {
        newData.push(type);
      }
      save.mutate({
        types: newData
      });
    }
  };

  const handleOpenModal = () => {
    form.resetFields();
    setIsEditing(false);
    setOpenModal(true);
  };

  const handleSavePaymentCustomType = (id?: number, e?: boolean, name?: string) => {
    postPaymentCustomType.mutate({
      // @ts-expect-error
      id,
      status: e,
      name
    })
  }

  const handleEditPaymentCustomType = (item: any) => {
    form.setFieldsValue({
      id: item.id,
      name: item.name,
    });
    setIsEditing(true);
    setOpenModal(true);
  };

  return (
    <div>
      <div className={cx(styles.container)}>
        <Spin spinning={isLoading || save.isLoading}>
          <div className={styles.container_sub_cont}>
            <div className={styles.container_item}>
              <span className={styles.container_item_title}>{t(`home.${PaymentType.CASH}`)}</span>
              <Switch onChange={() => handleSave(PaymentType.CASH)} checked={isActivePayment(PaymentType.CASH)} />
            </div>
            <div className={styles.container_item}>
              <span className={styles.container_item_title}>{t(`home.${PaymentType.CARD}`)}</span>
              <Switch onChange={() => handleSave(PaymentType.CARD)} checked={isActivePayment(PaymentType.CARD)} />
            </div>
            <div className={styles.container_item}>
              <span className={styles.container_item_title}>{t(`home.${PaymentType.BANK}`)}</span>
              <Switch onChange={() => handleSave(PaymentType.BANK)} checked={isActivePayment(PaymentType.BANK)} />
            </div>
            <div className={styles.container_item}>
              <span className={styles.container_item_title}>{t(`home.${PaymentType.TRANSFER}`)}</span>
              <Switch onChange={() => handleSave(PaymentType.TRANSFER)} checked={isActivePayment(PaymentType.TRANSFER)} />
            </div>
            <div className={styles.container_item}>
              <span className={styles.container_item_title}>{t(`home.${PaymentType.OTHERS}`)}</span>
              <Switch onChange={() => handleSave(PaymentType.OTHERS)} checked={isActivePayment(PaymentType.OTHERS)} />
            </div>


            {dataPaymentCustomType?.map(item => (
              <div className={cx(styles.container_item, 'cursor-alias relative')} key={item?.id} onClick={() => handleEditPaymentCustomType(item)}>
                <span className='absolute top-[7px] left-[7px] w-2 h-2 bg-slate-400 rounded-full' />

                <Tooltip
                  title={item?.name}
                  overlayInnerStyle={{ width: '100%' }}
                >
                  <p className={styles.container_item_title}>
                    {/* @ts-expect-error */}
                    {item?.name?.length > 15 ? `${item?.name?.substring(0, 15)}...` : item?.name}
                  </p>
                </Tooltip>
                <span onClick={(e) => e.stopPropagation()}>
                  <Switch
                    onChange={(e) => handleSavePaymentCustomType(item?.id, e, item?.name)}
                    checked={item?.status}
                    loading={postPaymentCustomType?.isLoading}
                  />
                </span>
              </div>
            ))}

            {/* @ts-expect-error */}
            {dataPaymentCustomType?.length < 7 && (
              <div
                onClick={handleOpenModal}
                className={cx(styles.container_item, "!text-[#464255] !gap-1 flex !items-center w-[266px] h-[66px] !bg-transparent rounded-lg !border-dashed !border-[#464255] !border-[2px] cursor-pointer")}
              >
                <div className='flex items-center justify-center ml-auto mr-auto gap-4 text-[15px] font-light'>
                  <PlusCircleIcon stroke='#464255' size={25} strokeWidth={0.8} /> {t("home.Tolov_turi_qoshish")}
                </div>
              </div>
            )}
            

          </div>
        </Spin>
      </div>



      <CustomPayment
        setOpenModal={setOpenModal}
        openModal={openModal}
        isEditing={isEditing}
        item={dataPaymentCustomType?.find(i => i?.id === form.getFieldValue('id'))}
      />
    </div>
  );
};

export default PaymentWays;
