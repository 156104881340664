import React from 'react';

const ReceiptItemIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 6.00008V8.42008C22 10.0001 21 11.0001 19.42 11.0001H16V4.01008C16 2.90008 16.91 1.99008 18.02 2.00008C19.11 2.01008 20.11 2.45008 20.83 3.17008C21.55 3.90008 22 4.90008 22 6.00008Z"
      fill="#53389E"
    />
    <path
      d="M2 7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z"
      fill="#9E77ED"
    />
    <path
      d="M12 12.2617H9C8.59 12.2617 8.25 12.6017 8.25 13.0117C8.25 13.4217 8.59 13.7617 9 13.7617H12C12.41 13.7617 12.75 13.4217 12.75 13.0117C12.75 12.6017 12.41 12.2617 12 12.2617Z"
      fill="white"
    />
    <path
      d="M9 9.76172H12C12.41 9.76172 12.75 9.42172 12.75 9.01172C12.75 8.60172 12.41 8.26172 12 8.26172H9C8.59 8.26172 8.25 8.60172 8.25 9.01172C8.25 9.42172 8.59 9.76172 9 9.76172Z"
      fill="white"
    />
    <path
      d="M5.96875 8.01172C5.40875 8.01172 4.96875 8.46172 4.96875 9.01172C4.96875 9.56172 5.41875 10.0117 5.96875 10.0117C6.51875 10.0117 6.96875 9.56172 6.96875 9.01172C6.96875 8.46172 6.51875 8.01172 5.96875 8.01172Z"
      fill="white"
    />
    <path
      d="M5.96875 12.0117C5.40875 12.0117 4.96875 12.4617 4.96875 13.0117C4.96875 13.5617 5.41875 14.0117 5.96875 14.0117C6.51875 14.0117 6.96875 13.5617 6.96875 13.0117C6.96875 12.4617 6.51875 12.0117 5.96875 12.0117Z"
      fill="white"
    />
  </svg>
);

export default ReceiptItemIcon;
