import React, { useEffect, useRef } from 'react';
import '@fullcalendar/react/dist/vdom';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import styles from './dayView.module.scss';
import DayItem from './DayItem';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { TaskFilter } from '@utils/models/TaskFilter';
import { completeTaskVisible } from '@reducers/TaskCalendarViewSlice';
import { Spin } from 'antd';
import { ONE_DAY_UNIX_VALUE } from '@utils/constants/ONE_DAY_UNIX_VALUE';
import { useTranslation } from 'react-i18next';
import { useTaskCalendarFilter } from '@pages/task/services/queries';

const DayView = () => {
  const { searchParams } = useQueryParam<SearchParamsTask, null>();

  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const dispatch = useAppDispatch();

  const calendarRef = useRef<FullCalendar>(null);

  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useTaskCalendarFilter(searchParams, date, date);

  useEffect(() => {
    if (data) {
      const events: EventSourceInput = [];
      data.forEach(item => {
        const allDay =
          dayjs(item.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() - dayjs(item.deadlineStart, DATE_WITH_TIME_FORMAT).unix() === ONE_DAY_UNIX_VALUE;
        events.push({
          id: String(item.id),
          allDay,
          resourceId: item,
          start: new Date(dayjs(item.deadlineStart, DATE_WITH_TIME_FORMAT).format()),
          end: new Date(dayjs(item.deadlineFinish, DATE_WITH_TIME_FORMAT).format())
        });
      });
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(new Date(dayjs(date, DATE_FORMAT).format()).toISOString());
      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...events]);
    }
  }, [data, calendarRef, date]);

  // handle open complete modal
  const handleOpenCompleteModal = (task: TaskFilter) => {
    if (!task.closedDate)
      dispatch(
        completeTaskVisible({
          task,
          visible: true
        })
      );
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <FullCalendar
          ref={calendarRef}
          initialView="timeGridDay"
          height={'100%'}
          allDayText={t('calendar_view.Kun_davomida')}
          locale={'ru-Ru'}
          eventMinHeight={20}
          progressiveEventRendering
          allDaySlot={true}
          dayMaxEvents={true}
          eventClassNames={'c_TimePicker'}
          viewClassNames={'c_container'}
          dayCellClassNames={'m_fc_AllDay'}
          selectMirror={true}
          dragScroll={true}
          eventContent={e => {
            return <DayItem data={e?.event?._def?.extendedProps?.resourceId} />;
          }}
          eventClick={e => {
            handleOpenCompleteModal(e?.event?._def?.extendedProps?.resourceId);
          }}
          moreLinkContent={e => {
            return (
              <div className={styles.container_more}>
                {e.shortText} {t('calendar_view.topshiriq')}
              </div>
            );
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={false}
          dayHeaders={false}
          slotLabelFormat={[
            {
              hour: '2-digit',
              minute: '2-digit'
            }
          ]}
        />
      </div>
    </Spin>
  );
};

export default DayView;
