import { CurrencyItem } from './Currency';
import { FlatStatus } from './FlatStatus';

export interface FlatBuilding {
  id: number;
  name: string;
}

export interface Terrace {
  id: number;
  area: number;
  pricePerArea?: number;
  pricePerAreaRepaired?: number;
}

export interface Flat {
  apartment: boolean;
  area: number;
  building: FlatBuilding;
  createdBy: string;
  createdDate: string;
  delay: number;
  gallery: FlatGallery[];
  deletedBy: any;
  deletedDate: any;
  files: any;
  floor: number;
  id: number;
  number: string;
  options: any[];
  prepayment: number;
  prepaymentPercent: number;
  price: any;
  priceNotRepaired: number;
  pricePerArea: number;
  pricePerAreaRepaired: number;
  priceRepaired: number;
  repaired: boolean;
  rooms: string;
  status: FlatStatus;
  terrace: Terrace;
  xaxis: number[];
  yaxis: number[];
  discountID: number;
  houseName?: string;
  selected: boolean;
  entrance?: number;
  currency?: CurrencyItem;
  fields?: FlatFieldId[];
  entranceName?: string;
  variationGroupId?: number;
  livingArea?: number;
  nonResidentialType?: string;
}

export interface FlatFieldId {
  fieldId: number;
  value: string;
}

export interface FlatGallery {
  files: any;
  id?: number;
  position: string;
  queue: number;
}

export interface FlatId extends Flat {
  new: boolean;
  rowId: number;
  isSelected?: boolean;
  createdBy: string;
  createdDate: string;
  deletedBy: any;
  deletedDate: any;
  entrance: any;
  currency: CurrencyItem;
  fields: {
    fieldId: number;
    value: string;
    name: string;
    showPDF: boolean;
  }[];
  customId?: string;
  flatCategory: string;
  category: string;
}

export enum FlatRepairedStatus {
  REPAIRED = 'REPAIRED',
  NOT_REPAIRED = 'NOT_REPAIRED',
  BOTH = 'BOTH'
}
