import React, { useState } from 'react';
import { Dropdown } from 'antd';

import { useQueryParam } from '../../../hooks/useQueryParams';
import { useDeleteTask } from '../../../queries/mutation';
import { useTranslation } from 'react-i18next';
import { Task } from '../../../utils/models/Task';
import styles from './completedTasks.module.scss';
import i18n from '../../../service/I18nService';
import DollarGreenIcon from '../../../assets/icons/DollarGreenIcon';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import Edit2Icon from '../../../assets/icons/Edit2Icon';
import TrushSquareIcon from '../../../assets/icons/TrushSquareIcon';
import MiniMenuIcon from '../../../assets/icons/MiniMenuIcon';
import EditTaskModal from './EditTaskModal';

interface Props {
  task: Task;
}

const PaymentTypeTask: React.FC<Props> = ({ task }) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { params } = useQueryParam();

  const deleteTask = useDeleteTask(Number(params.contractId));

  const { t } = useTranslation();

  // handle drop down
  const handleDropDown = (visible: boolean) => {
    setOpenDropDown(visible);
  };

  // handle edit modal
  const handleEditModal = (visible: boolean) => {
    setOpenEditModal(visible);
    handleDropDown(false);
  };

  // handle delete
  const handleDeleteTask = () => {
    deleteTask.mutate(task?.id || -1);
    handleDropDown(false);
  };

  // translateCompleted
  const translateCompleted = (date: string, employee: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            Совершено <span>{employee}</span> {date.split(' ')[0]} в {date.split(' ')[1]}
          </>
        );
      case 'en':
        return (
          <>
            Done by <span>{employee}</span> on {date.split(' ')[0]} at {date.split(' ')[1]}
          </>
        );
      default:
        return (
          <>
            <span>{employee}</span> tomonidan {date} da bajarildi
          </>
        );
    }
  };

  // translateCreated
  const translateCreated = (date: string, employee: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            В {date} для <span>{employee}</span>
          </>
        );
      case 'en':
        return (
          <>
            {date.split(' ')[0]} at {date.split(' ')[1]} for <span>{employee}</span>
          </>
        );
      default:
        return (
          <>
            {date} da <span>{employee}</span> uchun
          </>
        );
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.item_icon}>
        <DollarGreenIcon />
      </div>
      <div>
        <p className={styles.item_created}>{translateCreated(task.date, task.employee)}</p>
        <p className={styles.item_note}>
          <span>
            {task.payment.toLocaleString('ru')} {t('one_task.som')}
          </span>{' '}
          {getCurrentLanguageWord(task.text)}
        </p>
        <p className={styles.item_done}>{translateCompleted(task.closedDate, task.closedBy)}</p>
        <p className={styles.item_closed}>{task.closedText}</p>
      </div>
      <Dropdown
        visible={openDropDown}
        placement={'bottomLeft'}
        onVisibleChange={visible => handleDropDown(visible)}
        overlayClassName={styles.dropdown}
        overlay={
          <>
            <p>{t('arrearage.Amallar')}</p>
            <div
              onClick={() => {
                handleEditModal(true);
              }}
            >
              <Edit2Icon />
              <span>{t('client.Tahrirlash')}</span>
            </div>
            <div
              onClick={e => {
                e.stopPropagation();
                handleDeleteTask();
              }}
            >
              <TrushSquareIcon />
              <span>{t('discount_setting.Ochirish')}</span>
            </div>
          </>
        }
        trigger={['click']}
      >
        <span
          onClick={e => {
            e.stopPropagation();
            handleDropDown(true);
          }}
          className={styles.item_button}
        >
          <MiniMenuIcon />
        </span>
      </Dropdown>
      <EditTaskModal visible={openEditModal} onClose={handleEditModal} isCompleted={true} task={task} />
    </div>
  );
};

export default PaymentTypeTask;
