import { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { DATE_FORMAT } from '@utils/constants/format';
import { BoosComplexSelectType } from '@utils/models/BoosComplexSelectType';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { toHHMMSS } from 'utils';

import { colors } from '../../../../utils/constants/showRoomColors';
import { useGetCallHistory } from '../../services/queries';
import { CallStatisticsParam } from '../header/Header';

import FilterButton from './filter-button/filter-button';
import FilterDrawer from './filter-drawer/filter-drawer';
import GraphStatistic from './GraphStatistic';
import { finishDateNotUtc, startDateNotUtc } from './startAndFinishDates';

import styles from './callStatistics.module.scss';

dayjs.extend(utc);

const CallStatistics = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [newData, setNewData] = useState<any[]>([]);
  const { searchParams, appendSingle } = useQueryParam<CallStatisticsParam, any>();

  const { data, isLoading } = useGetCallHistory(
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.startDate, DATE_FORMAT).startOf('day').unix()
      : startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc],
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.finishDate, DATE_FORMAT).endOf('day').unix()
      : finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc],
    searchParams?.pipeStatus,
    searchParams?.employee,
    searchParams.statuses,
    Boolean(searchParams?.unique),
    searchParams?.durationStart,
    searchParams?.durationFinish,
    searchParams?.talkTimeStart,
    searchParams?.talkTimeFinish
  );

  useEffect(() => {
    if (data) {
      const newData = data?.result?.map((res, index) => ({
        ...res,
        color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)]
      }));

      setNewData(newData);
    }
  }, [data]);

  function toTimeString(totalSeconds: number) {
    return toHHMMSS(String(totalSeconds));
  }

  const total = Number(data?.inboundTotal) + Number(data?.outboundTotal);
  const totalWidth = 1100 / total;

  const newArr: any = newData?.map(item => item?.inboundCCount + item?.outboundCCount);

  const maxValue = Math.max(...newArr);
  const calcWidth = 1100 / maxValue;

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (!searchParams?.date) {
      appendSingle('date', BoosComplexSelectType.THIS_MONTH);
      appendSingle('callType', 'ALL');
    }
  }, [searchParams]);

  return (
    <div className={styles.container}>
      <FilterButton onClick={() => setIsDrawerOpen(true)} />
      <FilterDrawer open={isDrawerOpen} onClose={onClose} />
      <Spin spinning={isLoading}>
        <div className={styles.container_elements}>
          <div className={styles.container_elements_header}>
            <h1 className={styles.title}>{t('crm.Xodimlar_kesimida_qongiroqlar')}</h1>
          </div>
          <div className={styles.container_elements_subTitle}>
            <div className={styles.container_elements_subTitle_child}>
              <span>{t(`online_pbx.Kiruvchi`)}</span>
              <div className={styles.container_back0} />
            </div>
            <div className={styles.container_elements_subTitle_child}>
              <span>{t(`online_pbx.Chiquvchi`)}</span>
              <div className={styles.container_back1} />
            </div>
          </div>
          <div className={styles.container_calls}>
            {newData?.map((res, index) => (
              <div key={res} className={styles.container_calls_child}>
                <div className={styles.container_calls_child_top}>
                  <span>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      res?.employeeName ? res?.employeeName : res?.employeeNum ? res?.employeeNum : t(`task.Boshqalar`)
                    }
                  </span>
                  <span>
                    {t('crm.Davomiylik')}:<span>{toTimeString(res.duration)}</span>
                  </span>
                </div>
                <div className={styles.container_calls_child_right}>
                  <div
                    className={styles.container_calls_child_right_in}
                    style={{
                      width: res?.inboundCCount === 0 ? 40 : res.inboundCCount * calcWidth,
                      minWidth: 40,
                      backgroundColor: res?.color
                    }}
                  >
                    <span>{res.inboundCCount}</span>
                  </div>
                  <div
                    className={styles.container_calls_child_right_out}
                    style={{
                      width: res?.outboundCCount === 0 ? 40 : res.outboundCCount * calcWidth,
                      minWidth: 40,
                      // background: `url(${back}), white 0% 0% / 50px 50px repeat`,
                      background: `repeating-linear-gradient(
                          45deg,
                          ${res?.color},
                          ${res?.color} 0,
                          white 2px,
                          white 8px
                        )`
                    }}
                  >
                    <span style={{ color: res?.color }}>{res.outboundCCount}</span>
                  </div>
                </div>
                <span
                  style={{
                    marginLeft: 14,
                    color: '#767676',
                    fontSize: 16,
                    fontWeight: 400
                  }}
                >
                  {res.outboundCCount + res.inboundCCount}
                </span>
              </div>
            ))}
            <div className={styles.container_calls_child}>
              <div className={styles.container_calls_child_top}>
                <span style={{ color: '#464255', fontWeight: 600, fontSize: 16 }}>{t(`home.Jami`)}</span>
              </div>
              <div className={styles.container_calls_child_right}>
                <div
                  className={styles.container_calls_child_right_in}
                  style={{
                    width: data?.inboundTotal === 0 ? 40 : Number(data?.inboundTotal) * totalWidth,
                    minWidth: 40,
                    backgroundColor: '#838383'
                  }}
                >
                  <span style={{ color: '#fff' }}>{data?.inboundTotal}</span>
                </div>
                <div
                  className={styles.container_calls_child_right_out}
                  style={{
                    width: data?.outboundTotal === 0 ? 40 : Number(data?.outboundTotal) * totalWidth,
                    minWidth: 40,
                    // background: `url(${back}), white 0% 0% / 50px 50px repeat`,
                    background: `repeating-linear-gradient(
                      45deg,
                      #838383,
                      #838383 0,
                      white 2px,
                      white 8px
                    )`
                  }}
                >
                  <span style={{ backgroundColor: '#fff', color: '#838383' }}>{data?.outboundTotal}</span>
                </div>
                <span
                  style={{
                    marginLeft: 14,
                    color: '#767676',
                    fontSize: 16,
                    fontWeight: 400
                  }}
                >
                  {Number(data?.inboundTotal) + Number(data?.outboundTotal)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      <GraphStatistic />
    </div>
  );
};

export default CallStatistics;
