import { NavLink } from 'react-router-dom';
import ContractIcon1 from '@icons/ContractIcon1';
import MoneyIcon from '@icons/MoneyIcon';
import { useTranslation } from 'react-i18next';

import ArrowRightMessage from '../../assets/icons/ArrowRightMessage';
import CodeIcon from '../../assets/icons/CodeIcon';
import DocumentTextIcon from '../../assets/icons/DocumentTextIcon';
import TaskSquareGrayIcon from '../../assets/icons/TaskSquareGrayIcon';
import { CompanyConfigPaths } from '../../routing/companyConfig/companyConfigPaths';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { SettingsPaths } from '../../routing/settings/settingsPaths';

import styles from './companySetting.module.scss';

const CompanyConfigNavbar = () => {
  const { t } = useTranslation();

  const data = [
    {
      icon: DocumentTextIcon,
      text: t('company_config.Shablonlar'),
      path: `${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.TEMPLATES}?tab=contract`
    },
    {
      icon: CodeIcon,
      text: t('company_config.Tartiblash_raqamlash'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.TEMPLATE_NUMERATION
    },
    {
      icon: CodeIcon,
      text: t('company_config.Peniya'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.SURCHARGE
    },
    {
      icon: TaskSquareGrayIcon,
      text: t('titles.contract-fields'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.CONTRACT_FIELDS
    },
    {
      icon: ContractIcon1,
      text: t('titles.contract'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.CONTRACT_CONFIG
    },
    {
      icon: MoneyIcon,
      text: t('titles.Shartnoma_tolov_turi'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.CONTRACT_PAYMENT_TYPE
    }
  ];

  return (
    <div className={styles.companyNavbar}>
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <NavLink key={index} to={item.path} className="navbarLink">
          <item.icon />
          {item.text}
          <ArrowRightMessage className="companyNavbar_cheveron" />
        </NavLink>
      ))}
    </div>
  );
};

export default CompanyConfigNavbar;
