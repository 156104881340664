export const emojies = [
  '😀',
  '😁',
  '😂',
  '🤣',
  '😃',
  '😄',
  '😅',
  '😆',
  '😉',
  '😊',
  '😋',
  '😎',
  '😍',
  '😘',
  '🥰',
  '😗',
  '😙',
  '😚',
  '🙂',
  '🤗',
  '🤩',
  '🤔',
  '🤨',
  '😐',
  '😑',
  '😶',
  '🙄',
  '😏',
  '😣',
  '😥',
  '😮',
  '🤐',
  '😯',
  '😪',
  '😫',
  '🥱',
  '😴',
  '😌',
  '😛',
  '😜',
  '😝',
  '🤤',
  '😒',
  '😓',
  '😔',
  '😕',
  '🙃',
  '🤑',
  '😲',
  '🙁',
  '😖',
  '😞',
  '😟',
  '😤',
  '😢',
  '😭',
  '😦',
  '😧',
  '😨',
  '😩',
  '🤯',
  '😬',
  '😰',
  '😱',
  '🥵',
  '🥶',
  '😳',
  '🤪',
  '😵',
  '🥴',
  '😠',
  '😡',
  '🤬',
  '😷',
  '🤒',
  '🤕',
  '🤢',
  '🤮',
  '🤧',
  '😇',
  '🥳',
  '🥺',
  '🤠',
  '🤡',
  '🤥',
  '🤫',
  '🤭',
  '🧐',
  '🤓',
  '😈',
  '👿',
  '👹',
  '👺',
  '💀',
  '☠',
  '👻',
  '👽',
  '👾',
  '🤖',
  '💩',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '😿',
  '😾',
  '🐱‍👤',
  '🐱‍🏍',
  '🐱‍💻',
  '🐱‍🐉',
  '🐱‍👓',
  '🐱‍🚀',
  '🙈',
  '🙉',
  '🙊',
  '🐵',
  '🐶',
  '🐺',
  '🐱',
  '🦁',
  '🐯',
  '🦒',
  '🦊',
  '🦝',
  '🐮',
  '🐷',
  '🐗',
  '🐭',
  '🐹',
  '🐰',
  '🐻',
  '🐨',
  '🐼',
  '🐸',
  '🦓',
  '🐴',
  '🦄',
  '🐔',
  '🐲',
  '🐽',
  '🐾',
  '🐒',
  '🦍',
  '🦧',
  '🦮',
  '🐕‍🦺',
  '🐩',
  '🐕',
  '🐈',
  '🐅',
  '🐆',
  '🐎',
  '🦌',
  '🦏',
  '🦛',
  '🐂',
  '🐃',
  '🐄',
  '🐖',
  '🐏',
  '🐑',
  '🐐',
  '🐪',
  '🐫',
  '🦙',
  '🦘',
  '🦥',
  '🦨',
  '🦡',
  '🐘',
  '🐁',
  '🐀',
  '🦔',
  '🐇',
  '🐿',
  '🦎',
  '🐊',
  '🐢',
  '🐍',
  '🐉',
  '🦕',
  '🦖',
  '🦦',
  '🦈',
  '🐬',
  '🐳',
  '🐋',
  '🐟',
  '🐠',
  '🐡',
  '🦐',
  '🦑',
  '🐙',
  '🦞',
  '🦀',
  '🐚',
  '🦆',
  '🐓',
  '🦃',
  '🦅',
  '🦢',
  '🦜',
  '🦩',
  '🦚',
  '🦉',
  '🐦',
  '🐧',
  '🐥',
  '🐤',
  '🐣',
  '🦇',
  '🦋',
  '🐌',
  '🐛',
  '🦟',
  '🦗',
  '🐜',
  '🐝'
];
