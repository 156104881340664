import React, { useEffect, useState } from 'react';
import styles from '../../homeId.module.scss';
import { Button, Form, Input, Radio, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { helper } from '../../../../utils/helper/helper';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { setOptionsContract } from '../../../../store/reducers/CreateContractSlice';
import ArrowDownIcon from '../../../../assets/icons/ArrowDownIcon';

interface FormI {
  prePayment: string;
  delay: number;
  discount: boolean;
}

const CalculatorInputs = () => {
  const [showDiscountInput, setShowDiscountInput] = useState(false);
  const [form] = Form.useForm<FormI>();

  const { prePayment, delay, totalAmount, flats, useDiscount, discountSum } = useAppSelector(state => state.createContract);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      delay: delay,
      prePayment: prePayment.toLocaleString('ru'),
      discount: useDiscount
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prePayment, delay, useDiscount]);

  const onFinish = (val: FormI) => {
    dispatch(
      setOptionsContract({
        delay: Number(val.delay),
        prePayment: helper.deleteNotNumbersAndParseFloatNumber(val.prePayment).number,
        useDiscount: showDiscountButton() ? val.discount : false
      })
    );
  };

  //handleDiscountInput
  const handleDiscountInput = (show: boolean) => {
    setShowDiscountInput(show);
  };

  // show use discount button
  const showDiscountButton = () => {
    if (flats.length > 1) return false;
    if (flats[0]?.repaired && flats[0]?.discountRepairedList.length === 0) return false;
    return !(!flats[0]?.repaired && flats[0]?.discountNotRepairedList.length === 0);
  };

  return (
    <div className={styles.home_id_content_calculator_inputs}>
      <Form initialValues={{ remember: true }} labelCol={{ span: 24 }} onFinish={onFinish} autoComplete="off" form={form} name={'calculator'}>
        <Form.Item
          rules={[
            {
              validator: (rule, value) => {
                if (!value) {
                  return Promise.reject('');
                }
                let discountLastAmount = totalAmount;
                if (
                  flats[0].repaired &&
                  flats[0].discountRepairedList?.length > 0 &&
                  flats[0].discountRepairedList[flats[0].discountRepairedList.length - 1].percent === 100
                ) {
                  discountLastAmount = flats[0].discountRepairedList[flats[0].discountRepairedList.length - 1].total;
                }
                if (
                  !flats[0].repaired &&
                  flats[0].discountNotRepairedList?.length > 0 &&
                  flats[0].discountNotRepairedList[flats[0].discountNotRepairedList.length - 1].percent === 100
                ) {
                  discountLastAmount = flats[0].discountNotRepairedList[flats[0].discountNotRepairedList.length - 1].total;
                }
                if (
                  flats.length === 1 &&
                  form.getFieldValue('discount') &&
                  helper.deleteNotNumbersAndParseFloatNumber(value).number > discountLastAmount
                ) {
                  form.setFieldsValue({
                    prePayment: discountLastAmount.toLocaleString('ru')
                  });
                  return Promise.resolve();
                }
                if (roundFloatNumber(totalAmount - discountSum) >= helper.deleteNotNumbersAndParseFloatNumber(value).number) {
                  form.setFieldsValue({
                    prePayment: helper.deleteNotNumbersAndParseFloatNumber(value).string
                  });
                  return Promise.resolve();
                }
                form.setFieldsValue({
                  prePayment: roundFloatNumber(totalAmount - discountSum).toLocaleString('ru')
                });
              }
            },
            {
              required: true,
              message: ''
            }
          ]}
          name={'prePayment'}
          label={<label className={styles.home_id_content_calculator_inputs_label}>{t('home_id.Boshlangich_tolov')}</label>}
        >
          <Input className="customInput" />
        </Form.Item>
        <div className={styles.home_id_content_calculator_inputs_delay_cont}>
          <Form.Item
            // style={{ width: '400px' }}
            name="delay"
            rules={[{ required: true, message: '' }]}
            label={<label className={styles.home_id_content_calculator_inputs_label}>{t('home_id.Muddati_oy')}</label>}
          >
            <div className={'order_delay_buttons'}>
              <Radio.Group>
                <Radio className="homeIdButton" value={24}>
                  24
                </Radio>
                <Radio className="homeIdButton" value={36}>
                  36
                </Radio>
                <Radio className="homeIdButton" value={48}>
                  48
                </Radio>
              </Radio.Group>
            </div>
          </Form.Item>
          <Form.Item
            name="delay"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input
              onChange={e => {
                form.setFieldsValue({
                  delay: Number(helper.deleteNotNumbers(e.target.value, null))
                });
              }}
              placeholder={t('home_id.Ixtiyoriy_kiritish')}
              className="customInput"
              // style={{ width: '30%' }}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="discount"
          valuePropName="checked"
          style={{
            marginTop: '10px',
            display: showDiscountInput ? 'flex' : 'none'
          }}
          labelCol={{ span: 18 }}
          label={<label className={styles.home_id_content_calculator_inputs_label}>{t('home_id.Chegirma_qollash')}</label>}
        >
          <Switch size={'small'} />
        </Form.Item>

        {showDiscountButton() && (
          <div onClick={() => handleDiscountInput(!showDiscountInput)} className={styles.home_id_content_calculator_inputs_extra}>
            <span>{showDiscountInput ? t('home_id.Yopish') : t('home_id.Qoshimcha')}</span>
            <ArrowDownIcon
              className={
                showDiscountInput ? styles.home_id_content_calculator_inputs_extra_open : styles.home_id_content_calculator_inputs_extra_close
              }
            />
          </div>
        )}
        <Button htmlType={'submit'} className={styles.home_id_content_calculator_inputs_button} type={'primary'}>
          {t('home_id.Hisoblash')}
        </Button>
      </Form>
    </div>
  );
};

export default CalculatorInputs;
