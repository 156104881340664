import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './../../pages/builderSettings/builderSettings.module.scss';
import { Profile } from '../../utils/models/Profile';
import { useEditProfile } from '../../queries/mutation';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  profile?: Profile;
}

interface IForm {
  fullName: string;
}

const ChangePassword: React.FC<Props> = ({ visible, onClose, profile }) => {
  const [form] = Form.useForm<IForm>();

  const { t } = useTranslation();

  const editProfile = useEditProfile(handleCancel);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        fullName: profile.fullName
      });
    }
  }, [profile, visible, form]);

  // handle close modal
  function handleCancel() {
    onClose(false);
    form.resetFields();
  }

  // handle submit form
  const onFinish = (values: IForm) => {
    if (profile) {
      editProfile.mutate({
        ...profile,
        fullName: values.fullName
      });
    }
  };

  return (
    <Modal
      width={546}
      className={'createBuilderModal'}
      title={t('profile.Toliq_ismni_ozgartirish')}
      visible={visible}
      footer={false}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish} form={form} layout="vertical" className={styles.formBuilderModal}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={t('profile.Toliq_ismi')}
              name="fullName"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Input className={'customInput'} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={editProfile.isLoading} htmlType="submit" type="primary">
            {t('profile.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
