/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined, PushpinOutlined } from '@ant-design/icons';
import { Button, Dropdown, Image, Input, Spin } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import FileIconCrm from '../../../../../../assets/FileIconCrm';
import ArchiveBookIcon from '../../../../../../assets/icons/ArchiveBookIcon';
import EditIcon from '../../../../../../assets/icons/EditIcon';
import MiniMenuIcon from '../../../../../../assets/icons/MiniMenuIcon';
import PaperClipIcon from '../../../../../../assets/icons/PaperClipIcon';
import TrashIcon from '../../../../../../assets/icons/TrashIcon';
import TrushSquareBlackStrokeIcon from '../../../../../../assets/icons/TrushSquareBlackStrokeIcon';
import CustomPlayer from '../../../../../../components/customPlayer/CustomPlayer';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { useCreateClientNote, useCreateLeadNote, useDeleteTask, useUploadFile } from '../../../../../../queries/mutation';
import { BASE_URL } from '../../../../../../service/RequestService';
import { handleOpen } from '../../../../../../store/reducers/GoogleDocVieweSlice';
import { downloadFileByUrl } from '../../../../../../utils/helper/downloadFile';
import { urlify } from '../../../../../../utils/helper/urlify';
import { FileObject } from '../../../../../../utils/models/File';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../../utils/models/GoogleAnalitks';
import { OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';
import { useDeleteLeadNote } from '../../../../services/mutation';

import styles from './typeNote.module.scss';

interface Props {
  event: OneEvent;
  type?: string;
}

const NoteType: React.FC<Props> = ({ event, type }) => {
  const [text, setText] = useState(event?.textNote ? event?.textNote : event.text);
  const [files, setFiles] = useState<FileObject[]>(Array.isArray(event.files) ? event.files : []);
  const [showEdit, setShowEdit] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const params = useParams();

  const [openDropDown, setOpenDropDown] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const uploadFile = useUploadFile(getFile);
  const noteSave = useCreateLeadNote(Number(params.id), handleSuccess);
  const clientNote = useCreateClientNote(Number(params?.idClient), handleSuccess);
  const deleteNoteMutation = useDeleteLeadNote(Number(params.id), event.id, handleSuccessDelete);

  const deleteClientNote = useDeleteTask();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // handle open googleViewer
  const handleViewer = (isOpen: boolean, url: string, name: string, format: string) => {
    dispatch(
      handleOpen({
        isOpen,
        url,
        format,
        name
      })
    );
  };

  // get files
  function getFile(data: FileObject[]) {
    if (files) {
      setFiles([...files, ...data]);
    } else {
      setFiles(data);
    }
  }

  // handle text
  const handleText: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    setText(e.target.value);
  };

  // handle show edit
  const handleShowEdit = (visible: boolean) => {
    setShowEdit(visible);
    handleDropDown(false);
    if (!visible) {
      setText(event.text);
      setFiles(Array.isArray(event.files) ? event.files : []);
    }
  };

  // handle success delete
  function handleSuccessDelete() {
    handleDropDown(false);
  }

  // handle show player
  const handleShowPlayer = () => {
    setShowPlayer(true);
  };

  // create lead note
  function handleSuccess() {
    if (showEdit) {
      setShowEdit(false);
    } else {
      ReactGA.event({
        category: TYPE_EVENTS_GA.CRM,
        action: ACTION_EVENTS_GA_CRM.PIN_NOTE
      });
      handleDropDown(false);
    }
  }

  // handle drop down
  const handleDropDown = (visible: boolean) => {
    setOpenDropDown(visible);
  };

  // download file
  const downloadFile = (url: string, name: string, type: string) => {
    downloadFileByUrl(url, name, type);
  };

  // delete note
  const deleteNote = (id: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type ? deleteClientNote.mutate(id) : deleteNoteMutation.mutate();
  };

  // handle save
  const handleSave = () => {
    const fileIds: number[] = [];

    for (let i = 0; i < files.length; i++) {
      fileIds.push(files[i].id);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type
      ? clientNote.mutate({
          clientId: Number(params?.idClient),
          text,
          id: Number(event?.id),
          fileIds
        })
      : noteSave.mutate({
          id: event.id,
          pin: event.pin,
          fileIds,
          text,
          leadId: event.leadId
        });
  };

  // pin note
  const pinNote = () => {
    const fileIds: number[] = [];

    if (Array.isArray(event.files) && event.files.length > 0) {
      for (let i = 0; i < event.files.length; i++) {
        fileIds.push(event.files[i].id);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type
      ? clientNote.mutate({
          clientId: Number(params?.idClient),
          text,
          id: event?.id,
          pin: true,
          fileIds
        })
      : noteSave.mutate({
          id: event.id,
          pin: true,
          fileIds,
          text: event.text,
          leadId: event.leadId
        });
    handleDropDown(false);
  };

  // change file
  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 10000000) {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('files', file);
        uploadFile.mutate(formData);
        e.target.value = '';
      }
    }
  };

  // delete file
  const deleteFile = (id: number) => {
    setFiles(files?.filter(item => item.id !== id));
  };

  // disabled save button
  const disabledSaveButton = !text && files.length === 0;

  // show file
  const showFile = (file: FileObject) => {
    switch (file.format) {
      case 'xlsx':
      case 'xls':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_notes_file}>
            <FileIconCrm fill="#00A389" />
            <span className={styles.container_notes_file_name}>{file.originalName}</span>
          </div>
        );
      case 'pdf':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_notes_file}>
            <FileIconCrm fill="#FF3F40" />
            <span className={styles.container_notes_file_name}>{file.originalName}</span>
          </div>
        );
      case 'docx':
      case 'pptx':
      case 'doc':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_notes_file}>
            <FileIconCrm fill="#3772FF" />
            <span className={styles.container_notes_file_name}>{file.originalName}</span>
          </div>
        );
      case 'ogg':
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ac3':
      case 'amr':
      case 'opus':
      case 'oga':
      case 'wma':
        return <CustomPlayer type={file.format} name={file.originalName} url={file.absolutePath} />;
      case 'png':
      case 'svg':
      case 'jpg':
      case 'jpeg':
        return (
          <Image
            preview={{
              src: `${BASE_URL}/${file.absolutePath}`,
              mask: t('crm_ins.Korish')
            }}
            src={`${BASE_URL}/${file.absolutePath}`}
            className={styles.container_notes_image}
          />
        );
      case 'mp4':
      case 'gif':
        if (showPlayer)
          return (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video className={styles.container_notes_video} controls>
              <source src={`${BASE_URL}/${file.absolutePath}`} type={`video/${file.format}`} />
            </video>
          );
        return (
          <div onClick={handleShowPlayer} className={styles.container_file}>
            <FileIconCrm fill="#3772FF" />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );
      default:
        return (
          <div onClick={() => downloadFile(file.absolutePath, file.originalName, file.format)} className={styles.container_notes_file}>
            <FileIconCrm fill="#3772FF" />
            <span className={styles.container_notes_file_name}>{file.originalName}</span>
          </div>
        );
    }
  };

  if (showEdit)
    return (
      <div id={`event-${event.id}`} className={styles.container}>
        <div className={styles.container_icon}>
          <ArchiveBookIcon />
        </div>
        <div className={styles.container_notes}>
          <p className={styles.container_notes_date}>
            {type ? event?.date : event.createdDate} {type ? event?.employee : event.createdBy}
          </p>
          <Input.TextArea
            className={`customInput ${styles.container_notes_textArea}`}
            placeholder={t('one_lid.Matn_kiriting')}
            autoSize={{ minRows: 1, maxRows: 4 }}
            onChange={handleText}
            value={text}
          />
          <div className={styles.container_notes_files}>
            {files?.map(item => (
              <span key={item.id}>
                <PaperClipIcon /> {item.originalName}{' '}
                <span onClick={() => deleteFile(item.id)}>
                  <TrushSquareBlackStrokeIcon />
                </span>
              </span>
            ))}
          </div>
          <div className={styles.container_notes_submit}>
            <div className={styles.container_notes_submit_left}>
              <Button disabled={disabledSaveButton} loading={noteSave.isLoading} onClick={handleSave} type="primary">
                {t('one_lid.Yuborish')}
              </Button>
              <Button onClick={() => handleShowEdit(false)}>{t('one_lid.Bekor_qilish')}</Button>
            </div>
            <div className={styles.container_notes_submit_right}>
              <label htmlFor="chatFile" className={styles.container_notes_submit_right_file}>
                {uploadFile.isLoading && <LoadingOutlined />}
                <PaperClipIcon /> {t('one_lid.Fayl_biriktirish')}
              </label>

              <input type="file" id="chatFile" style={{ display: 'none' }} onChange={e => changeFile(e)} />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div id={`event-${event.id}`} className={styles.container}>
      <div className={styles.container_icon}>
        <ArchiveBookIcon />
      </div>
      <div className={styles.container_notes}>
        <p className={styles.container_notes_date}>
          {type ? event?.date : event.createdDate} {type ? event?.employee : event.createdBy}
        </p>
        <p className={styles.container_notes_note} dangerouslySetInnerHTML={{ __html: urlify(event.text) }} />
        {Array.isArray(event.files) && event.files.map(file => showFile(file))}
      </div>
      <Dropdown
        visible={openDropDown}
        placement="bottomRight"
        onVisibleChange={visible => handleDropDown(visible)}
        overlay={
          <div className={styles.dropdown}>
            <Spin spinning={deleteNoteMutation.isLoading}>
              <div className={styles.dropdown_cont}>
                <div onClick={() => handleShowEdit(true)}>
                  <EditIcon />
                  <span>{t('client.Tahrirlash')}</span>
                </div>
                <div onClick={pinNote}>
                  <PushpinOutlined />
                  <span>{t('one_lid.Qadash')}</span>
                </div>
                <div onClick={() => deleteNote(Number(event?.id))}>
                  <TrashIcon />
                  <span>{t('discount_setting.Ochirish')}</span>
                </div>
              </div>
            </Spin>
          </div>
        }
        trigger={['click']}
      >
        <span
          onClick={e => {
            e.stopPropagation();
            handleDropDown(true);
          }}
          className={styles.container_button}
        >
          <MiniMenuIcon />
        </span>
      </Dropdown>
    </div>
  );
};

export default NoteType;
