export enum CrmFieldsType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  URL = 'URL',
  TOGGLE_SWITCH = 'TOGGLE_SWITCH',
  RADIO = 'RADIO',
  LOCATION = 'LOCATION',
  COUNTER = 'COUNTER',
  ADDRESS = 'ADDRESS'
}

export enum CrmFieldsInfoType {
  LED = 'LED',
  CONTACT = 'CONTACT'
}
