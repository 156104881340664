import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessDenied from '@components/accessDenied/AccessDenied';

import { contractStatisticsRoute } from './contractStatisticsRoutes';

const ContractStatisticsRouter = () => (
  <Routes>
    {contractStatisticsRoute?.map((route, index) => (
      <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
    ))}
    <Route path="*" element={<AccessDenied />} />
  </Routes>
);

export default ContractStatisticsRouter;
