import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import { useGetSuccessFulTableLeads } from '@pages/bossCrm/services/queries';
import { SuccessFulLeadModel } from '@pages/bossCrm/utils/models/successful-lead-model';
import { setSuccessfulLeadsModal } from '@reducers/CrmStatisticsSlice';
import { Input, Modal, Pagination, Table, TableColumnsType, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { encodeString } from 'use-query-params';

import LidIcon from '../conversion/assets/icons/LidIcon';
import SearchMdIcon from '../conversion/assets/icons/SearchMdIcon';

import styles from './index.module.scss';

const SuccessFulLeadsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [time, setTime] = useState<NodeJS.Timeout>();
  const [localSearch, setLocalSearch] = useState<string>();
  const { successfulLeadModal } = useAppSelector(({ crmStatistic }) => crmStatistic);
  const { visible, responsibleById, search, page } = successfulLeadModal;
  const { startDatePipe, finishDatePipe, selectedPipe } = useAppSelector(({ crmStatistic }) => crmStatistic);
  const { leads, isLoading, totalElements, currentPage } = useGetSuccessFulTableLeads({
    page,
    search,
    size: 10,
    startDate: startDatePipe,
    finishDate: finishDatePipe,
    pipeIds: [selectedPipe?.id],
    responsibleById: [responsibleById]
  });

  const onCancel = () => {
    dispatch(
      setSuccessfulLeadsModal({
        page: 1,
        visible: false,
        responsibleById,
        search: undefined
      })
    );

    setLocalSearch(undefined);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setLocalSearch(value);

    clearTimeout(time);

    setTime(
      setTimeout(() => {
        dispatch(
          setSuccessfulLeadsModal({
            ...successfulLeadModal,
            search: value
          })
        );
      }, 350)
    );
  };

  const sliceText = (text: string) => {
    if (text?.length > 28) {
      return (
        <Tooltip title={text} showArrow={false}>
          {text?.slice(0, 28)?.concat('...')}
        </Tooltip>
      );
    }

    return text;
  };

  const columns: TableColumnsType<SuccessFulLeadModel> = [
    {
      title: t('boss_crm_conversion.Lid'),
      key: 'lidName',
      render: (record: SuccessFulLeadModel) => (
        <span
          className={styles.lidName}
          onClick={() => {
            navigate(`/main/crm/${record?.leadId}/${encodeString(record?.leadName!)}?prev=true`);
          }}
        >
          {sliceText(record?.leadName)}
          <LidIcon />
        </span>
      )
    },
    {
      title: t('boss_crm_conversion.Pipeline_va_bosqichi'),
      key: 'pipeLine',
      render: (record: SuccessFulLeadModel) => (
        <span>
          {record.pipeName} {record.pipeStatusName}
        </span>
      )
    },
    {
      title: t("boss_crm_conversion.Ma'sul"),
      key: 'masul',
      dataIndex: 'responsibleName',
      align: 'center'
    }
  ];

  return (
    <Modal centered width={1000} footer={null} visible={visible} onCancel={onCancel} className={styles.modal} title={t('boss_crm_conversion.Muvaffaqiyatli_Lidlar')}>
      <div className={styles.container}>
        <div className={styles.filter}>
          <Input value={localSearch} prefix={<SearchMdIcon />} onChange={onChange} placeholder={t("boss_crm_conversion.Lid bo'yicha qidiring")} />
        </div>
        <Table pagination={false} loading={isLoading} className={styles.table} dataSource={leads} columns={columns} scroll={{ y: 340 }} />
        <div className={styles.footer}>
          <Pagination
            pageSize={10}
            current={currentPage}
            total={totalElements}
            onChange={e => {
              dispatch(
                setSuccessfulLeadsModal({
                  ...successfulLeadModal,
                  page: e
                })
              );
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessFulLeadsModal;
