import React from 'react';
import styles from './Contract.module.scss';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { ClientType } from '../../../../utils/models/ClientType';
import { ClientPhone } from '../../../../utils/models/Client';
import User from '../../../../assets/icons/User';
import { useOneClientChanges } from '../../services/queries';

const ClientInformationCard = () => {
  const { searchParams } = useQueryParam<ChangesQueryParams, null>();
  const { isLoading } = useOneClientChanges(searchParams.clientId);

  const selectedClient = useAppSelector(state => state.changesReducer.selectedClient as any);

  const clientName = () => {
    if (selectedClient?.type === ClientType.NATURAL) {
      return selectedClient?.lastname + ' ' + selectedClient?.firstname + ' ' + selectedClient?.fatherName;
    } else {
      return selectedClient?.name;
    }
  };

  const clientPhone = () => {
    let activeClientPhone = '';
    selectedClient?.phones?.forEach((phn: ClientPhone) => {
      if (phn.active) {
        activeClientPhone = phn.phone;
      }
    });
    return activeClientPhone;
  };

  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <div>
        <h1 style={{ marginBottom: '1rem' }}>{t("changes.contract.Mijoz_ma'lumotlari")}</h1>
        <div className={styles.clientInformationCard}>
          <div>
            <User />
          </div>
          <p>
            {t('changes.contract.Mijoz')}: <span>{clientName()}</span>
          </p>
          <p>
            {t('changes.contract.Telefon')}: <span>{clientPhone()}</span>
          </p>
        </div>
      </div>
    </Spin>
  );
};

export default ClientInformationCard;
