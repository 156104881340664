import React from 'react';

const MailAreaArageIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33398 4.66675L6.77726 8.47704C7.21804 8.78559 7.43844 8.93986 7.67816 8.99962C7.88991 9.0524 8.11139 9.0524 8.32314 8.99962C8.56287 8.93986 8.78326 8.78559 9.22404 8.47704L14.6673 4.66675M4.53398 13.3334H11.4673C12.5874 13.3334 13.1475 13.3334 13.5753 13.1154C13.9516 12.9237 14.2576 12.6177 14.4493 12.2414C14.6673 11.8136 14.6673 11.2535 14.6673 10.1334V5.86675C14.6673 4.74664 14.6673 4.18659 14.4493 3.75877C14.2576 3.38244 13.9516 3.07648 13.5753 2.88473C13.1475 2.66675 12.5874 2.66675 11.4673 2.66675H4.53398C3.41388 2.66675 2.85383 2.66675 2.426 2.88473C2.04968 3.07648 1.74372 3.38244 1.55197 3.75877C1.33398 4.18659 1.33398 4.74664 1.33398 5.86675V10.1334C1.33398 11.2535 1.33398 11.8136 1.55197 12.2414C1.74372 12.6177 2.04968 12.9237 2.426 13.1154C2.85383 13.3334 3.41388 13.3334 4.53398 13.3334Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailAreaArageIcon;
