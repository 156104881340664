import React from 'react';

interface Props {
  className?: string;
}

const ArrowDown: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0465 9.62001L7.99979 13.6667L3.95312 9.62001"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 2.33333V13.5533" stroke="#00A389" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowDown;
