export enum rootPaths {
  INDEX = '/',
  MAIN = '/main',
  SHOWROOM = '/showroom',
  DEVELOPER = '/developer',
  BOSS = '/boss',
  INTEGRATION = '/integration/dialog',
  INTEGRATION_UYQUR = '/integration/uyqur',
  RESTORE_PASSWORD = '/restore-password',
  CHANGE_PASSWORD = '/change-password',
  QR_CODE = '/qr/:token',
  SCHEDULE_LOTTOTRON = '/schedule-lottotron'
}
