/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import Edit2Icon from '../../../assets/icons/Edit2Icon';
import TrashIcon from '../../../assets/icons/TrashIcon';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useCurrencyList } from '../../../queries/queries';
import { openModal } from '../../../store/reducers/CurrencySlice';
import { currencyFlags } from '../../../utils/constants/currencyFlags';
import { defaultImage } from '../../../utils/constants/defaultImage';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../../utils/helper/helper';
import { CurrencyItem } from '../../../utils/models/Currency';
import DeleteModal from '../delete/DeleteModal';

import styles from './tableCurrency.module.scss';

const TableCurrency = () => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: -1
  });

  const { data, isFetching, isLoading } = useCurrencyList();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const initialColumn: ColumnsType<CurrencyItem> | undefined = [
    {
      title: t('currency.Valyuta_nomi'),
      align: 'left',
      render: (record: CurrencyItem) => (
        <div className={styles.container_item}>
          <img src={currencyFlags[`${record.ccy.toLowerCase()}`] || defaultImage} alt={record.ccy} />
          <span className={styles.container_item_name}>{getCurrentLanguageWord(record.name)}</span>
          {record.basic && (
            <div className={styles.container_item_main}>
              <span className={styles.container_item_main_dot} />
              <span className={styles.container_item_main_label}>{t('currency.Asosiy')}</span>
            </div>
          )}
        </div>
      )
    },
    {
      title: t('currency.Kodi'),
      align: 'left',
      dataIndex: 'ccy',
      render: (ccy: string) => {
        return <span className={styles.container_value}>{ccy}</span>;
      }
    },
    {
      title: t('currency.Qiymati'),
      align: 'left',
      dataIndex: 'value',
      render: (value: number) => {
        return <span className={styles.container_value}>{helper.localeStringSmallNumbers(value)}</span>;
      }
    },
    {
      title: t('currency.Ozgartirilgan_sana'),
      align: 'left',
      dataIndex: 'updateDate',
      render: (updateDate: string) => {
        return <span className={styles.container_value}>{updateDate}</span>;
      }
    },
    {
      title: '',
      render: (record: CurrencyItem) => {
        if (record.basic) {
          return (
            <div className={styles.container_icons_disabled}>
              <Edit2Icon className={styles.container_icons_disabled_edit} />
              <TrashIcon className={styles.container_icons_disabled_delete} />
            </div>
          );
        }
        return (
          <div className={styles.container_icons_enable}>
            <Edit2Icon onClick={() => handleClickEditModal(record)} className={styles.container_icons_enable_edit} />
            <TrashIcon onClick={() => handleDeleteModal(true, record.id)} className={styles.container_icons_enable_delete} />
          </div>
        );
      },
      width: 112
    }
  ];

  // handle click edit modal
  const handleClickEditModal = (item: CurrencyItem) => {
    dispatch(
      openModal({
        data: item,
        isOpen: true
      })
    );
  };

  // handle delete modal
  const handleDeleteModal = (open: boolean, id?: number) => {
    setDeleteModal({
      open,
      id: id || -1
    });
  };

  return (
    <div className={styles.container}>
      <Table
        loading={isLoading || isFetching}
        columns={initialColumn}
        className="custom_table_currency"
        rowKey={'id'}
        dataSource={data || []}
        size="small"
        pagination={false}
      />
      <DeleteModal visible={deleteModal.open} close={handleDeleteModal} id={deleteModal.id} />
    </div>
  );
};

export default TableCurrency;
