export enum SettingsPaths {
  HOUSES = '/houses',
  INDEX = '',
  EMPLOYEE = '/employee/employee-config',
  MESSAGE = '/message',
  HOUSE_CONFIG = '/house/house-config',
  BUILDER = '/builder/builder-config',
  INTERFACE = '/interface',
  COMPANY_CONFIG = '/company-config',
  DISCOUNT = '/discount',
  SHAXMATKA = '/:id/:name',
  BACKUP = '/backup',
  BRANCH = '/branch/branch-config',
  PAYMENT = '/payment',
  INTEGRATION = '/integration',
  KPI = '/kpi/kpi-config',
  EVET_LIST = '/event-list',
  SECURITY = '/security',
  CRM_SETTINGS = '/crm-settings',
  CALLS = "/calls"
}
