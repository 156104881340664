import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { queryKeys } from '../constants';
import { IPaylovConfig } from '../schema';

export const usePaylovConfig = () =>
  useQuery<IPaylovConfig[]>({
    queryKey: [queryKeys.PAYLOV_CONFIG],
    queryFn: async () => {
      const res = await $api.get('/v1/paylov/config');

      return res.data.data;
    }
  });
