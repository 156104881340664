import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const TableIcon: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2654 1.33398H2.73203C1.73203 1.33398 1.33203 1.76065 1.33203 2.82065V13C1.33203 14.06 1.73203 14.5 2.73203 14.5H13.2654C14.2654 14.5 14.6654 14.06 14.6654 13C14.6654 11.94 14.6654 2.82065 14.6654 2.82065C14.6654 2.82065 14.6654 2.82065 14.6654 2.82065C14.6654 1.76065 14.2654 1.33398 13.2654 1.33398Z"
        stroke="#292D32"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 5.5H14" stroke="#292D32" strokeWidth="1.2" />
      <path d="M6 2L6 14" stroke="#292D32" strokeWidth="1.2" />
      <path d="M2 10H14" stroke="#292D32" strokeWidth="1.2" />
    </svg>
  );
};

export default TableIcon;
