import { cloneElement, ReactElement, useState } from 'react';
// import RobotoCallsIconGrey from '@icons/RobotoCallsIconGrey';
import CreateRobotCallDebt from '@pages/roboto-calls/components/create-robot-call/CreateRobotCallDebt';

// import styles from '../arrearage.module.scss';

interface IProps {
  children: ReactElement;
}

const ArrearageCallPopover = ({ children }: IProps) => {
  const [visibleCall, setVisibleCall] = useState(false);

  const handleOpenRobotCall = () => {
    setVisibleCall(true);
  };

  return (
    <>
      {/* <div onClick={handleOpenRobotCall} className={styles.sendMessageButton}>
        <RobotoCallsIconGrey className={styles.sendMessageButton_icon} />
      </div> */}
      {cloneElement(children, { onClick: handleOpenRobotCall })}
      <CreateRobotCallDebt visible={visibleCall} setVisible={setVisibleCall} />
    </>
  );
};

export default ArrearageCallPopover;
