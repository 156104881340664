export enum CallsStatus {
  SCHEDULE = 'SCHEDULE',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  ERROR_OCCURRED = 'ERROR_OCCURRED'
}

export enum RobotoStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  MISSED = 'MISSED',
  RINGING = 'RINGING',
  TERMINATED = 'TERMINATED',
  DROPPED = 'DROPPED'
}

export const contractStatus = [
  {
    value: 'STARTED',
    label: "Boshlang'ich"
  },
  {
    value: 'ACTIVE',
    label: 'Faol'
  },
  {
    value: 'CANCELLED',
    label: 'Bekor qilingan'
  },
  {
    value: 'FINISHED',
    label: 'Yakunlangan'
  },
  {
    value: 'TRANSFERRED',
    label: "O'tkazilgan"
  }
];

export const collectionTypes = [
  {
    id: 1,
    title: 'Barcha mijozlarga',
    descr: 'Nimadur malumot berishimiz kerak 2 qator bulishi kerak',
    className:
      'flex cursor-pointer flex-col items-center rounded-xl border-[1px] border-solid border-[#D0D5DD] px-2.5 py-5 shadow-sm transition-all hover:border-[#00A389] hover:bg-[#E6F6F4] justify-center',
    type: 'ALL'
  },
  {
    id: 2,
    title: 'Shartnoma qilganlar',
    descr: 'Nimadur malumot berishimiz kerak 2 qator bulishi kerak',
    className:
      'flex cursor-pointer flex-col items-center rounded-xl border-[1px] border-solid border-[#D0D5DD] px-2.5 py-5 shadow-sm transition-all hover:border-[#00A389] hover:bg-[#E6F6F4] justify-center',
    type: 'CONTRACT'
  },
  {
    id: 3,
    title: 'Shartnoma qilmaganlar',
    descr: 'Nimadur malumot berishimiz kerak 2 qator bulishi kerak',
    className:
      'flex cursor-pointer flex-col items-center rounded-xl border-[1px] border-solid border-[#D0D5DD] px-2.5 py-5 shadow-sm transition-all hover:border-[#00A389] hover:bg-[#E6F6F4] justify-center',
    type: 'NO_CONTRACT'
  },
  {
    id: 4,
    title: 'Potensial mijzolarga',
    descr: 'Nimadur malumot berishimiz kerak 2 qator bulishi kerak',
    className:
      'flex cursor-pointer flex-col items-center rounded-xl border-[1px] border-solid border-[#D0D5DD] px-2.5 py-5 shadow-sm transition-all hover:border-[#00A389] hover:bg-[#E6F6F4] justify-center',
    type: 'CRM_LEADS'
  }
];

export const callBackItems = [
  {
    id: 1,
    value: 1,
    label: '1'
  },
  {
    id: 2,
    value: 2,
    label: '2'
  },
  {
    id: 3,
    value: 3,
    label: '3'
  },
  {
    id: 4,
    value: 4,
    label: '4'
  },
  {
    id: 5,
    value: 5,
    label: '5'
  }
];
