import React from 'react';

const TrashIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="8" fill="#FEE4E2" />
    <path
      d="M24 14V13.2C24 12.0799 24 11.5198 23.782 11.092C23.5903 10.7157 23.2843 10.4097 22.908 10.218C22.4802 10 21.9201 10 20.8 10H19.2C18.0799 10 17.5198 10 17.092 10.218C16.7157 10.4097 16.4097 10.7157 16.218 11.092C16 11.5198 16 12.0799 16 13.2V14M18 19.5V24.5M22 19.5V24.5M11 14H29M27 14V25.2C27 26.8802 27 27.7202 26.673 28.362C26.3854 28.9265 25.9265 29.3854 25.362 29.673C24.7202 30 23.8802 30 22.2 30H17.8C16.1198 30 15.2798 30 14.638 29.673C14.0735 29.3854 13.6146 28.9265 13.327 28.362C13 27.7202 13 26.8802 13 25.2V14"
      stroke="#F04438"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashIcon;
