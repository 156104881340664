import React from 'react';
import { CrmField } from '../../../../../utils/models/CrmField';
import { Input } from 'antd';
import styles from './filterDrawer.module.scss';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { urlDecode, urlEncode } from '../../../../../utils/helper/urlEncodeDecode';
import { textCapitalize } from '@utils/helper/textConverter';
import i18n from '@service/I18nService';

interface Props {
  data: CrmField;
  fields?: string[] | string;
}

const TextField: React.FC<Props> = ({ data, fields }) => {
  const { remove, appendMultiple } = useQueryParam();

  // changeText
  const changeText: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (Array.isArray(fields)) {
      fields.forEach(item => {
        if (item.split('_')[0] === String(data.id)) {
          remove('fields', item);
        }
      });
    } else {
      if (fields?.split('_')[0] === String(data.id)) {
        remove('fields', fields);
      }
    }
    if (e.target.value) {
      appendMultiple('fields', `${data.id}_${urlEncode(e.target.value)}`);
    }
  };

  // handle show value
  // changeText
  const handleShow = () => {
    let value: string | undefined = undefined;
    if (Array.isArray(fields)) {
      fields.forEach(item => {
        if (item.split('_')[0] === String(data.id)) {
          value = urlDecode(item.split('_')[1]);
        }
      });
    } else {
      if (fields?.split('_')[0] === String(data.id)) {
        value = urlDecode(fields?.split('_')[1]);
      }
    }
    return value;
  };

  return (
    <div className={styles.container_margin_top}>
      <Input
        value={handleShow()}
        placeholder={String(data[`name${textCapitalize(i18n.language)}` as keyof typeof data] || data?.name)}
        className={'customInput'}
        onChange={changeText}
      />
    </div>
  );
};

export default TextField;
