import { BossHomePayments } from './BossHome';
import { DebitType } from './Client';
import { CurrencyItem } from './Currency';
import { PaymentType } from './PaymentType';

export enum BossPaymentsType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export interface BossPayments {
  date: string;
  payment: BossHomePayments;
}

export interface BossPaymentsClient {
  name: string;
  type: PaymentType;
  amount: number;
  debit: DebitType;
  createdDate: string;
  canceledDate: string;
  currency: CurrencyItem;
  paymentCustomTypeName: string;
}
