import React from 'react';

const ArrowNarrowDownIcon = ({ stroke = '#464255', size = 17, rotate = 0 }: { stroke?: string; size?: number; rotate?: number }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    className="duration-200 ease-in-out"
    style={{
      transform: `rotate(${rotate}deg)`
    }}
  >
    <path
      d="M3.22003 10.5333L7.5667 6.18667C8.08003 5.67333 8.92003 5.67333 9.43336 6.18667L13.78 10.5333"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowNarrowDownIcon;
