export const phoneCleanCode = (p: string) => {
  const phone = p.replace(/\./g, '');

  if (phone?.length === 12) {
    if (phone?.slice(0, 3) === '998') {
      return phone?.slice(3);
    } 
      return phone;
    
  } if (phone?.length === 13) {
    if (phone?.slice(0, 4) === '+998') {
      return phone?.slice(4);
    } 
      return phone;
    
  } 
    return phone;
  
};
