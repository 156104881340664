import React from 'react';
type Props = {
  onClick?: () => void;
};

const PositionLeftIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M14 5H1M18 1H1M18 9H1M14 13H1" stroke="#87898E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PositionLeftIcon;
