import React from 'react';
import styles from './configInputs.module.scss';
import { CrmFieldsInfoType } from '../../../../../utils/models/CrmFieldsType';
import { Spin } from 'antd';
import { useCrmFields } from '../../../../../queries/queries';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import InputsList from './InputsList';
import { useTranslation } from 'react-i18next';

interface Props {
  type: CrmFieldsInfoType;
}

const ConfigInputs: React.FC<Props> = ({ type }) => {
  const { isLoading } = useCrmFields();

  const { led, contact } = useAppSelector(state => state.crmField);

  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        {type === CrmFieldsInfoType.LED ? (
          <div className={styles.container_budjet}>
            <div className={styles.container_budjet_name}>{t('one_lid.Budjet')}</div>
            <div className={styles.container_budjet_value}>0</div>
          </div>
        ) : (
          <>
            <div className={styles.container_budjet}>
              <div className={styles.container_budjet_name}>{t('one_lid.Mijoz')}</div>
              <div className={styles.container_budjet_value}>...</div>
            </div>
            <div className={styles.container_budjet}>
              <div className={styles.container_budjet_name}>{t('home.Telefon_raqam')}</div>
              <div className={styles.container_budjet_value}>...</div>
            </div>
          </>
        )}
      </div>
      <InputsList fields={type === CrmFieldsInfoType.LED ? led : contact} type={type} />
    </Spin>
  );
};

export default ConfigInputs;
