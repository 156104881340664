/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useState } from 'react';
import { useGetOneCompanyTemplate } from '@queries/queries';
import { Button, Form, Input, Modal, Spin } from 'antd';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import FeaturedIcon from '../../../assets/icons/FeaturedIcon';
import { useDocumentTemplate, useUpdateContractFile, useUploadFile } from '../../../queries/mutation';
import { FileObject } from '../../../utils/models/File';
import { FileTemplateLanguage, FileTemplateType } from '../../../utils/models/FileTemplate';

import styles from './uploadTemplate.module.scss';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  lang: FileTemplateLanguage;
  type: FileTemplateType;
  fileId: number | undefined;
  setFileId: React.Dispatch<React.SetStateAction<number | undefined>>;
  tempId?: number;
}

const UploadTemplate: FC<IProps> = ({ setVisible, visible, type, lang, fileId, tempId, setFileId }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileObject | undefined>();
  const documentTemplate = useDocumentTemplate();
  const updateTemplete = useUpdateContractFile();
  const uploadFile = useUploadFile(getFile);
  const [acceptedTypes, setAcceptedTypes] = useState([
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword'
  ]);

  const { data } = useGetOneCompanyTemplate(fileId!);

  function getFile(data: FileObject[]) {
    setFiles(data[0]);
  }

  const handleCancel = () => {
    setVisible(false);
    setFileId(undefined);
    form.resetFields();
    setFiles(undefined);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setFiles(data?.document);
    }
  }, [fileId, data]);

  const onFinish = (values: any) => {
    if (fileId) {
      updateTemplete
        .mutateAsync({
          fileID: files?.id!,
          language: lang,
          type,
          logic: values.logic,
          id: data?.id,
          customTempId: data?.customTempId
        })
        .then(handleCancel);
    } else {
      documentTemplate
        .mutateAsync({
          fileID: files?.id!,
          language: lang,
          type,
          logic: values.logic,
          customTempId: tempId
        })

        .then(() => {
          handleCancel();
          form.resetFields();
          setFiles(undefined);
        });
    }
  };

  const dropzoneChange = (e: any[]) => {
    const file = e[0];

    if (acceptedTypes.includes(file.type)) {
      const formData = new FormData();

      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  return (
    <Modal title={t('company_config.File_yuklash')} footer={false} visible={visible} onCancel={handleCancel} width={399} className={styles.modal}>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item label={t('home.Formula')} name="logic">
          <Input className="customInput" />
        </Form.Item>
        <Spin spinning={uploadFile.isLoading}>
          <Dropzone multiple={false} onDrop={dropzoneChange} accept={acceptedTypes.join(',')}>
            {({ getRootProps, isDragAccept, isDragReject, getInputProps }) => (
              <div {...getRootProps()} className={styles.modal_dropDownFile}>
                <input style={{ display: 'none' }} {...getInputProps()} />
                <FeaturedIcon />
                <p>
                  {files ? (
                    files.originalName
                  ) : (
                    <>
                      <span>Click to upload</span> or drag and drop
                      <p style={{ textAlign: 'center' }}>DOCX, DOC</p>
                    </>
                  )}
                </p>
              </div>
            )}
          </Dropzone>
        </Spin>
        <div className={styles.modal_footer}>
          <Button onClick={handleCancel}>{t('home.Yopish')}</Button>
          <Button type="primary" htmlType="submit" loading={documentTemplate.isLoading || updateTemplete?.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadTemplate;
