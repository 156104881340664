import { useAppSelector } from '@hooks/reduxHooks';
import { $api } from '@service/RequestService';
import { Permissions } from '@utils/constants/permissions';
import { useQuery } from 'react-query';

import { brojectConstants, brojectSchema } from '..';

export const useBrojectConfig = () => {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isBrojectPermission = permissions?.includes(Permissions.PERMISSION_BROJECT);

  return useQuery<brojectSchema.IBrojectConfig>({
    queryKey: [brojectConstants.queryKeys.BROJECT_CONFIG],
    queryFn: async () => {
      const res = await $api.get('/v1/broject-config');

      return res.data.data;
    },
    staleTime: Infinity,
    enabled: isBrojectPermission
  });
};
