import { useAppSelector } from '@hooks/reduxHooks';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import i18n from '@service/I18nService';
import { message } from '@utils/index';
import { useMutation, useQueryClient } from 'react-query';

import { $api } from '../../../service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { LeadAndClientCostPayloadModel } from '../utils/models/lead-and-client-cost-payload-model';
import { PlanFactConfigPayloadModel } from '../utils/models/plan-fact-config-payload-model';
import { SuccessfullTableData } from '../utils/models/SuccessfullTableData';
import { UnsuccessfullLeadsTableData } from '../utils/models/UnsuccessfullLeadsTableData';

export function useGetVoiceUrl(uuid: string, accountcode: string, callHistoryType: string) {
  return useMutation(async (url: string) => {
    const res = await $api.get(`${endPoints.PHONE_RECORD}?uuid=${uuid}&accountcode=${accountcode}&callHistoryType=${callHistoryType}`, { url });
    const voice = await $api.get(res.data, {
      responseType: 'blob'
    });

    return voice.data;
  });
}
export function useGetPhoneRecord(uuid: string | null, accountcode: string | null, callHistoryType: string | null) {
  return useMutation(async () => {
    const res = await $api.get(`${endPoints.PHONE_RECORD}?uuid=${uuid}&accountcode=${accountcode}&callHistoryType=${callHistoryType}`);

    return res.data;
  });
}

export function useGetCallHistoryExcel() {
  return useMutation(
    async (data: { page: number; size: number; startDate: number; finishDate: number; employeeId?: number; callType?: string }) => {
      const res = await $api.post(`${endPoints.CALL_HISTORY_EXCEL}?lang=${i18n.language.toUpperCase()}`, data, { responseType: 'blob' });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Qo'ng'iroqlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export const useGetUnsuccessfullLeads = () =>
  useMutation<
    UnsuccessfullLeadsTableData,
    any,
    {
      page: number;
      size: number;
      search?: string;
      startDate: string;
      finishDate: string;
      reasonsFRIds?: number[];
      pipeStatusIds?: number[];
      responsibleById?: number[];
    }
  >(
    [queryKeys.FAILED_TABLE_DATA],
    async data => {
      const res = await $api.post(endPoints.FAILED_TABLE_DATA, data);

      return res.data?.data;
    },
    { retry: false, onError: baseErrorHandler }
  );

export const useGetSuccesfullLeads = () =>
  useMutation<
    SuccessfullTableData,
    any,
    {
      page: number;
      size: number;
      startDate: string;
      finishDate: string;
      houses: Array<number>;
      buildings: Array<number>;
      customFieldId: number | null;
      tag?: boolean | null;
      search?: string;
    }
  >(
    [queryKeys.SUCCESS_TABLE_DATA],
    async data => {
      const res = await $api.post(endPoints.SUCCESS_TABLE_DATA, data);

      return res.data?.data;
    },
    { retry: false, onError: baseErrorHandler }
  );

export function useSavePlanFactPipe() {
  const qc = useQueryClient();
  const { startDate, finishDate } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  return useMutation<any, any, PlanFactConfigPayloadModel>(
    async payload => {
      const { data } = await $api.post('/v1/statistics/plan-fact-pipe', payload);

      return data;
    },
    {
      onError: baseErrorHandler,
      onSuccess: async res => {
        message.success(res?.message?.[i18n.language]);
        await qc.invalidateQueries([queryKeys.PLAN_FACT, startDate, finishDate]);
      }
    }
  );
}

export function useDeleteLeadAndClientCost() {
  const qc = useQueryClient();
  const { pipeId } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  return useMutation<any, any, number>(
    async id => {
      const { data } = await $api.delete(`/v1/statistics/lead-and-client-cost/${id}`);

      return data;
    },
    {
      onError: baseErrorHandler,
      onSuccess: async res => {
        message.success(res?.message?.[i18n.language]);

        await qc.invalidateQueries([queryKeys.LEAD_AND_CLIENT_COST_CONFIG]);
        await qc.invalidateQueries([queryKeys.LEAD_AND_CLIENT_COST, pipeId]);
        await qc.invalidateQueries([queryKeys.LEAD_AND_CLIENT_COST_STATISTICS, pipeId]);
      }
    }
  );
}

export function useCreateLeadAndClientCost() {
  const qc = useQueryClient();
  const { pipeId } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  return useMutation<any, any, LeadAndClientCostPayloadModel>(
    async payload => {
      const { data } = await $api.post('/v1/statistics/lead-and-client-cost', payload);

      return data;
    },
    {
      onError: baseErrorHandler,
      onSuccess: async res => {
        message.success(res?.message?.[i18n.language]);

        await qc.invalidateQueries([queryKeys.LEAD_AND_CLIENT_COST_CONFIG]);
        await qc.invalidateQueries([queryKeys.LEAD_AND_CLIENT_COST, pipeId]);
        await qc.invalidateQueries([queryKeys.LEAD_AND_CLIENT_COST_STATISTICS, pipeId]);
      }
    }
  );
}
