import { FC } from 'react';
import { useScrollView } from '@hooks/useScrollView';

import { useFilterMessage } from '../../../../queries/queries';
import { LocalStorage } from '../../../../service/LocalStorage';
import { USER_KEY } from '../../../../utils/constants/localStorageKeys';

import MessageDrawerCard from './MessageDrawerCard';

import styles from './messageDrawer.module.scss';

interface IProps {
  wasRead: boolean | null;
  search: string;
  size: number;
  filterData:
    | {
        pipeStatusIds: number[] | null;
        responsibleByIds: number[] | null;
        types: string[] | null;
      }
    | undefined;
}

const MessageDrawerData: FC<IProps> = ({ wasRead, search, filterData, size }) => {
  const fromId = LocalStorage.get(USER_KEY) ? LocalStorage.get(USER_KEY).id : 0;
  const { data, fetchNextPage, isFetching, hasNextPage } = useFilterMessage(
    size,
    search,
    wasRead,
    filterData?.pipeStatusIds && filterData?.pipeStatusIds.length > 0 ? filterData?.pipeStatusIds : undefined,
    filterData?.responsibleByIds && filterData?.responsibleByIds.length > 0 ? filterData?.responsibleByIds : undefined,
    filterData?.types && filterData?.types.length > 0 ? filterData?.types : undefined
  );
  const { ref } = useScrollView({ handleScroll: fetchNextPage, deps: [isFetching], isChange: !isFetching && hasNextPage });

  return (
    <div className={styles.data}>
      {data?.pages?.map(data => data?.data?.map(item => <MessageDrawerCard key={item?.leadId} {...item} you={fromId === item?.chatDto?.fromId} />))}
      <div ref={ref} className="p-2" />
    </div>
  );
};

export default MessageDrawerData;
