import React from 'react';
import styles from './clientBalance.module.scss';
import PhoneActiveIcon from '../../assets/icons/PhoneActiveIcon';
import { ClientPhone } from '../../utils/models/Client';
import { useTranslation } from 'react-i18next';

interface Props {
  clientName: string;
  phones: ClientPhone[];
}

const ClientInfo: React.FC<Props> = ({ clientName, phones }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.client_card}>
      <div>
        <div className={styles.client_card_title}>{t('client.Mijoz')}</div>
        <div className={styles.client_card_name}>{clientName}</div>
      </div>
      <div>
        <div className={styles.client_card_title}>{t('client.Telefon_raqami')}</div>
        <div className={styles.client_card_phone_cont}>
          {phones.map((phone, index) => (
            <span key={index}>
              {phone.phone}
              {phone.active ? <PhoneActiveIcon className={styles.client_card_phone_cont_icon} /> : ';'}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
