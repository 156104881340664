/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@icons/DownloadIcon';
import ChatReplyIcon from '@pages/oneLid/assets/icons/ChatReplyIcon';
import { setIntentWidth, setTaskChangeText } from '@reducers/TaskChangetextSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { Avatar, Image } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FileIconCrm from '../../../../../../assets/FileIconCrm';
import CustomPlayer from '../../../../../../components/customPlayer/CustomPlayer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { BASE_URL } from '../../../../../../service/RequestService';
import { handleOpen } from '../../../../../../store/reducers/GoogleDocVieweSlice';
import { DATE_WITH_TIME_FORMAT } from '../../../../../../utils/constants/format';
import { downloadFileByUrl } from '../../../../../../utils/helper/downloadFile';
import { urlify } from '../../../../../../utils/helper/urlify';
import { FileObject } from '../../../../../../utils/models/File';
import { LEAD_EVENT_TYPE, OneChatInformation, OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';

import defaultPersonAvatar from '../../../../../../assets/img/avatarDefaultClient.jpg';
import facebook from '../../../../../../assets/img/Facebook.png';
import instagram from '../../../../../../assets/img/instagram.png';
import avatar from '../../../../../../assets/img/staff_image.png';
import telegram from '../../../../../../assets/img/telegram.png';

import styles from './chat.module.scss';

interface Props {
  chat: OneEvent;
}

const Chat: React.FC<Props> = ({ chat }) => {
  const navigate = useNavigate();
  const isDragAccept = useAppSelector(state => state.taskChangeTextSlice.isDragAccept);
  const [showPlayer, setShowPlayer] = useState({
    visible: false,
    index: -1,
    fatherIndex: -1
  });
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // handle open googleViewer
  const handleViewer = (isOpen: boolean, url: string, name: string, format?: string) => {
    dispatch(
      handleOpen({
        isOpen,
        url,
        // @ts-expect-error
        format,
        name
      })
    );
  };

  // handle show player
  const handleShowPlayer = (index: number, fatherIndex: number) => {
    setShowPlayer({
      visible: true,
      index,
      fatherIndex
    });
  };
  // download file
  const downloadFile = (url: string, name: string, type?: string) => {
    downloadFileByUrl(url, name, type);
  };

  function getFileType(filePath?: string) {
    return filePath?.split('.').pop();
  }

  // show file
  const showFile = (file: FileObject, index: number, fatherIndex: number, story = false) => {
    switch (file.format) {
      case 'xlsx':
      case 'xls':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill="#00A389" />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );

      case 'pdf':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill="#FF3F40" />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );

      case 'docx':
      case 'pptx':
      case 'doc':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill="#3772FF" />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );

      case 'ogg':
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ac3':
      case 'amr':
      case 'opus':
      case 'oga':
      case 'wma':
        return (
          <div>
            <CustomPlayer type={file.format} name={file.originalName} url={file.absolutePath} />
          </div>
        );

      case 'png':
      case 'svg':
      case 'jpg':
      case 'jpeg':
        return (
          <Image
            preview={{
              src: `${BASE_URL}/${file.absolutePath}`,
              mask: t('crm_ins.Korish'),
              style: {
                borderRadius: 10
              }
            }}
            src={`${BASE_URL}/${file.absolutePath}`}
            className={story ? styles.container_image_story : styles.container_image}
          />
        );

      case 'mp4':
      case 'MP4':
      case 'gif':
        if (showPlayer.visible && index === showPlayer.index && showPlayer.fatherIndex === fatherIndex)
          return (
            <video className={story ? styles.container_video_story : styles.container_video} controls>
              <source src={`${BASE_URL}/${file.absolutePath}`} type={`video/${file.format}`} />
            </video>
          );
        return (
          <div onClick={() => handleShowPlayer(index, fatherIndex)} className={styles.container_file}>
            <FileIconCrm fill="#3772FF" />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );

      default:
        const fileFormat = getFileType(file?.absolutePath) ?? 'unknown';
        // console.log('fileFormat', fileFormat);

        const imageFormats = ['png', 'svg', 'jpg', 'jpeg'];
        const audioFormats = ['ogg', 'mp3', 'wav', 'm4a', 'ac3', 'amr', 'opus', 'oga', 'wma'];
        const fileFormats = ['xlsx', 'xls', 'pdf', 'docx', 'pptx', 'doc'];
        const mediaFormats = ['mp4', 'gif', 'MP4'];

        const renderMediaFile = (file: FileObject, index: number, fatherIndex: number) => {
          if (showPlayer.visible && index === showPlayer.index && showPlayer.fatherIndex === fatherIndex) {
            return (
              <video className={story ? styles.container_video_story : styles.container_video} controls>
                <source src={`${BASE_URL}/${file.absolutePath}`} type={`video/${fileFormat}`} />
              </video>
            );
          }
          return (
            <div onClick={() => handleShowPlayer(index, fatherIndex)} className={styles.container_file}>
              <FileIconCrm fill="#3772FF" />
              <span className={styles.container_file_name}>{file.originalName}</span>
            </div>
          );
        };

        return (
          <div>
            {imageFormats?.includes(fileFormat) && (
              <div>
                <Image
                  preview={{
                    src: `${BASE_URL}/${file?.absolutePath}`,
                    mask: t('crm_ins.Korish'),
                    style: {
                      borderRadius: 10
                    }
                  }}
                  src={`${BASE_URL}/${file?.absolutePath}`}
                  className={story ? styles.container_image_story : styles.container_image}
                />
                <div className='flex items-center justify-between'>
                  <span className={styles.container_file_name}>{file.originalName}</span>
                  <span className='mb-[-7px] cursor-pointer' onClick={() => downloadFile(file.absolutePath, file.originalName, fileFormat)}>
                    <DownloadIcon />
                  </span>
                </div>
              </div>
            )}
            {audioFormats?.includes(fileFormat) && (
              <div>
                <CustomPlayer type={fileFormat} name={file.originalName} url={file.absolutePath} />
              </div>
            )}
            {fileFormats?.includes(fileFormat) && (
              <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, fileFormat)} className={styles.container_file}>
                <FileIconCrm fill="#3772FF" />
                <span className={styles.container_file_name}>{file.originalName}</span>
              </div>
            )}
            {mediaFormats?.includes(fileFormat) && renderMediaFile(file, index, fatherIndex)}

            {!imageFormats.includes(fileFormat) &&
              !audioFormats.includes(fileFormat) &&
              !fileFormats.includes(fileFormat) &&
              !mediaFormats.includes(fileFormat) && (
                <div onClick={() => downloadFile(file.absolutePath, file.originalName, fileFormat)} className="flex items-center gap-3 border-solid border-[#d0d5de] p-4 mt-3 border-[1px] rounded-xl cursor-pointer">
                  <FileIconCrm fill="#3772FF" />
                  <span className={styles.container_notes_file_name}>{file.originalName}</span>
                </div>
              )}
          </div>
        );
    }
  };

  const getIconMessenger = (type: LEAD_EVENT_TYPE) => {
    switch (type) {
      case LEAD_EVENT_TYPE.INSTAGRAM:
        return <img src={instagram} alt="instagram" />;
      case LEAD_EVENT_TYPE.TELEGRAM:
        return <img src={telegram} alt="telegram" />;
      case LEAD_EVENT_TYPE.FACEBOOK:
        return <img src={facebook} alt="facebook" />;
      default:
        return <></>;
    }
  };

  function Chats() {
    const temp: OneChatInformation[][] = [];
    let tempObj: any[] = [];
    // let res: OneChatInformation[][] = []
    let current = chat?.chats[0].from.id;

    chat?.chats.forEach((prop) => {
      if (prop.from.id === current) {
        tempObj.push(prop);
      } else {
        temp.push(tempObj);
        current = prop.from.id;
        tempObj = [];
        tempObj.push(prop);
      }
    });
    temp.push(tempObj);

    return temp;
  }

  const goToTjm = (houseId: number, flatIds?: number, houseName?: string) => {
    if (houseId && !flatIds) {
      navigate(`${rootPaths.MAIN + MainPaths.INDEX}?houses=${houseId}`);
    } else if (flatIds && houseName) {
      navigate(`${rootPaths.MAIN + MainPaths.INDEX}/flatIds=${flatIds}/${urlEncode(houseName)}`);
    }
  };

  const onReplyClick = (e: any) => {
    e.stopPropagation();
    dispatch(setTaskChangeText({ taskChangeText: 'Chat' }));
    dispatch(
      setIntentWidth({
        intentWidth: Number(document.getElementById('container_footer_top')?.clientWidth)
      })
    );
    document.getElementById('textArea')?.focus();
    document.getElementById('big_container')?.click();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_bg}>
          {Chats().map((items, i) =>
            items.map((item, index) => (
              <>
                {item.income ? (
                  <div key={index} className={styles.container_left}>
                    {(index === 0 || item.link) && (
                      <div className={styles.container_left_header}>
                        <div className={styles.container_left_header_avatar}>
                          <Avatar src={item.from?.avatar ? `${BASE_URL}/${item.from.avatar}` : defaultPersonAvatar} size={32} />{' '}
                          {getIconMessenger(chat.type)}
                        </div>
                        <h1>
                          {item.createdDate} {item.from.name}
                          {item.link && (
                            <a rel="noreferrer" target="_blank" href={item.link}>
                              {t('one_lid.Sharhni_korish')}
                            </a>
                          )}
                        </h1>
                      </div>
                    )}
                    {item?.stories && typeof item.stories === 'object' && (
                      <div className={styles.container_left_contentReplace}>
                        {item.text ? <span>{t('one_lid.Sizning_hikoyangizga')}</span> : <span>{t('one_lid.Ularning_hikoyasida')}</span>}
                        {showFile(item.stories as FileObject, index, i, true)}
                      </div>
                    )}
                    {item.text && (
                      <div className={styles.container_left_content}>
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center'
                          }}
                        >
                          {item?.houseId && (
                            <div
                              className={styles.container_left_content_top}
                              onClick={() => goToTjm(item?.houseId!, item?.flatId, item?.houseName)}
                            >
                              <p>{item?.houseName}</p>
                              {item?.flatFloor && (
                                <span>
                                  {item?.flatRooms} xonali, {item?.flatFloor}
                                  -qavat,
                                  {item?.flatArea} m<sup>2</sup>
                                </span>
                              )}
                            </div>
                          )}
                          <ChatReplyIcon onClick={onReplyClick} />
                        </div>
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: urlify(item.text)
                            }}
                          />
                          {index !== 0 && <span className={styles.span}>{dayjs(item.createdDate, DATE_WITH_TIME_FORMAT).format('HH:mm')}</span>}
                        </div>
                      </div>
                    )}
                    {/* I RECEIVE */}
                    {item?.files && typeof item.files === 'object' && showFile(item.files as FileObject, index, i)}
                  </div>
                ) : (
                  <div key={index} className={styles.container_right}>
                    {index === 0 && (
                      <div className={styles.container_right_header}>
                        <h1>
                          {item.createdDate} {item.from.name}
                        </h1>
                        <div className={styles.container_right_header_avatar}>
                          <Avatar src={item.from?.avatar ? `${BASE_URL}/${item.from.avatar}` : avatar} size={32} /> {getIconMessenger(chat.type)}
                        </div>
                      </div>
                    )}
                    {item.text && (
                      <div className={styles.container_right_content}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: urlify(item.text)
                          }}
                        />
                        {index !== 0 && <span>{dayjs(item.createdDate, DATE_WITH_TIME_FORMAT).format('HH:mm')}</span>}
                      </div>
                    )}
                    {item?.stories && typeof item.stories === 'object' && (
                      <div className={styles.container_right_contentReplace}>
                        {item.text ? <span>{t('one_lid.Siz_hikoyangizga')}</span> : <span>{t('one_lid.Siz_ularni_hikoyasida')}</span>}
                        {showFile(item.stories as FileObject, index, i, true)}
                      </div>
                    )}
                    {/* I SEND */}
                    {item?.files && typeof item.files === 'object' && showFile(item.files as FileObject, index, i)}
                  </div>
                )}
              </>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
