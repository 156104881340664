import React from 'react';
import styles from './otherValues.module.scss';
type Props = {
  data: Array<{ name: string | undefined; value: number | undefined; color: string }> | undefined;
  isHaveMore: boolean;
};
const OtherValues: React.FC<Props> = ({ data, isHaveMore }) => {
  return (
    <div className={styles.more_container}>
      {data!?.length > 0 && isHaveMore && (
        <div className={styles.more_items_container}>
          {data?.map((item, i) => (
            <div className={styles.other_legends} key={item.name! + i}>
              <div className={styles.other_legends_item}>
                <span
                  className={styles.other_legends_item_circle}
                  style={{
                    border: `3.48px solid ${item.color}`
                  }}
                />
                {item?.name}
              </div>
              <span className={styles.other_legends_item_values}>{item.value}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OtherValues;
