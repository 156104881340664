import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useBookingClientSearch, useClientByClientId } from '../../queries/queries';
import { changeCountryCode, clearSelectedClient, setSearchPhone } from '../../store/reducers/ClientSlice';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';
import { ClientType } from '../../utils/models/ClientType';
import { CreateOrderSearchClients } from '../../utils/models/CreateOrder';

import styles from '../../pages/clientCreateContract/clientCreateContract.module.scss';

interface Props {
  getCount: (count: number) => void;
  setClientId?: Dispatch<SetStateAction<number>>;
}

const ListFindClients: React.FC<Props> = ({ getCount, setClientId: setClient }) => {
  const [scrolling, setScrolling] = useState(true);
  const [clientId, setClientId] = useState(-1);
  const { type, searchByPhone } = useAppSelector(state => state.clientReducer);
  const { searchPhone } = useAppSelector(state => state.clientReducer);

  const dispatch = useAppDispatch();
  const { isLoading: onClientLoading } = useClientByClientId(clientId);

  const { t } = useTranslation();

  const timer = useRef<NodeJS.Timeout>();

  const { isLoading, data, fetchNextPage, isFetching } = useBookingClientSearch(
    `?type=${type === ClientType.JURIDICAL ? 'JURIDICAL' : 'NATURAL'}&phone=${urlEncode(`+${searchPhone}`)}`,
    searchPhone!
  );

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 100 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  useEffect(() => {
    if (searchByPhone) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        dispatch(setSearchPhone(searchByPhone));
      }, 300);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      dispatch(setSearchPhone(-1));
    }
  }, [searchByPhone]);

  useEffect(() => {
    if (data) {
      getCount(data?.pages?.map(data => data?.data?.length)?.length);
    }
  }, [data]);

  const clientName = (client: CreateOrderSearchClients) => {
    if (type === ClientType.NATURAL) {
      if (client.fatherName) {
        return `${client.lastname} ${client.firstname} ${client.fatherName}`;
      }
      return `${client.lastname} ${client.firstname}`;
    }
    return client.name;
  };

  const selectOneClient = (id: number) => {
    setClientId(id);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setClient && setClient(id);
  };

  const clearClient = () => {
    setClientId(-1);
    dispatch(clearSelectedClient());
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.listUsers} onScroll={onScroll}>
        {data?.pages?.map(data =>
          data?.data?.map(client => {
            if (client.id === clientId && !onClientLoading) {
              return (
                <div key={client?.id} onClick={clearClient} className={styles.oneActiveUser}>
                  <div className={styles.oneActiveUser_title}>{clientName(client)}</div>
                  {client?.dateBirth && (
                    <div className={styles.oneActiveUser_item}>
                      <label>{t('client_create_contract.Tugilgan_sana')}</label>
                      <span>{client?.dateBirth}</span>
                    </div>
                  )}
                  <div className={styles.oneActiveUser_item}>
                    <label>{t('client.Telefon_raqami')}</label>
                    <span>{client.phone}</span>
                  </div>
                </div>
              );
            }
            return (
              <Spin key={client?.id} spinning={onClientLoading && client.id === clientId}>
                <div
                  onClick={() => {
                    selectOneClient(client?.id || -1);
                    dispatch(changeCountryCode(client?.code!));
                  }}
                  className={styles.oneUser}
                  key={client.id}
                >
                  <div className={styles.oneUser_title}>{clientName(client)}</div>
                  {client?.dateBirth && (
                    <div className={styles.oneUser_item}>
                      <label>{t('client_create_contract.Tugilgan_sana')}</label>
                      <span>{client?.dateBirth}</span>
                    </div>
                  )}
                  <div className={styles.oneUser_item}>
                    <label>{t('client.Telefon_raqami')}</label>
                    <span>{client.phone}</span>
                  </div>
                </div>
              </Spin>
            );
          })
        )}
      </div>
    </Spin>
  );
};

export default ListFindClients;
