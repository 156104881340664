import TrushIcon from '@icons/crmIcons/TrushIcon';
import Edit2Icon from '@icons/Edit2Icon';
import PlusIcon from '@icons/PlusIcon';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import { Button, Dropdown, Menu, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ITermDiscount, PaymentOfferProvider, useAction, useTermDiscount, useTermDiscountDelete } from 'modules/multi-payment';

const Container = () => {
  const { data, isLoading } = useTermDiscount();
  const { setAction } = useAction();
  const deleteMutation = useTermDiscountDelete();

  const columns: ColumnsType<ITermDiscount> = [
    {
      title: 'Nomi',
      dataIndex: 'name'
    },
    {
      title: 'Chegirma %',
      dataIndex: 'percentage'
    },
    {
      title: 'Boshlanish muddati',
      dataIndex: 'startDate'
    },
    {
      title: 'Tugash muddati',
      dataIndex: 'finishDate'
    },
    {
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                icon={<Edit2Icon />}
                onClick={() =>
                  setAction('TERM_DISCOUNT_UPDATE', {
                    ...record
                  })
                }
              >
                Tahrirlash
              </Menu.Item>
              <Menu.Item icon={<TrushIcon />} onClick={() => deleteMutation.mutate(record?.id)}>
                O’chirish
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <DotsIcon className="cursor-pointer" />
        </Dropdown>
      ),
      width: 50
    }
  ];

  return (
    <div className="w-full rounded-[6px] bg-[#FFFFFF]">
      <div className="flex items-center justify-between p-[24px]">
        <h1 className="text-[24px] text-[#464255]">Muddatli chegirma</h1>
        <Button className="flex h-[38px] items-center rounded-[50px] border-[#00A389] px-[16px]  text-[16px] text-[#00A389]" onClick={() => setAction('TERM_DISCOUNT_CREATE')}>
          <PlusIcon /> Yangi qo’shish
        </Button>
      </div>
      <Table columns={columns} dataSource={data || []} bordered loading={isLoading || deleteMutation.isLoading} />
    </div>
  );
};

const TermDiscount = () => (
  <PaymentOfferProvider>
    <Container />
  </PaymentOfferProvider>
);

export default TermDiscount;
