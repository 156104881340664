import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { useLeadSave } from '@queries/mutation';
import { usePipeStatusesById } from '@queries/queries';
import { setChangeResponsible } from '@reducers/CrmOneLidSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { queryNames } from '@utils/constants/queryNames';
import { encodeString } from '@utils/helper';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '@utils/models/GoogleAnalitks';
import { OneLid } from '@utils/models/OneLid';
import { PipeStatusesType } from '@utils/models/PipeAll';
import { Button, Modal } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './info.module.scss';

type Props = {
  openRespModal: boolean;
  setOpenRespModal: React.Dispatch<React.SetStateAction<boolean>>;
  pipeType: string | undefined;
};

const RespChangeModal: React.FC<Props> = ({ openRespModal, setOpenRespModal, pipeType }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const qc = useQueryClient();
  const { appendSingle, params } = useQueryParam<any, any>();
  const { t } = useTranslation();

  // save information
  function saveInformationOneLeadEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CHANGE_ONE_LEAD_INFORMATION
    });
  }

  // handleSuccess
  function handleSuccess(data: OneLid) {
    saveInformationOneLeadEvent();
    if (params.id === 'new') navigate(`${rootPaths.MAIN + MainPaths.CRM}/${data.id}/${encodeString(data.name)}`);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    else if (incoming) {
      appendSingle('incoming', undefined);
      qc.invalidateQueries([queryNames.CRM_ONE_LID, Number(params.id)]);
    } else qc.invalidateQueries([queryNames.CRM_ONE_LID, Number(params.id)]);
  }

  const saveLidMutation = useLeadSave(handleSuccess, Number(params.id));
  const saveLidMutation1 = useLeadSave(handleSuccess, Number(params.id));
  const crmLid = useAppSelector(state => state.crmOneLidField);
  const { data: pipeList } = usePipeStatusesById(crmLid.pipeId);
  const incoming = pipeType === PipeStatusesType.FIRST;

  const sendLeadFields: any = crmLid?.leadFieldValue
    ?.filter(prev => prev?.value !== '')
    ?.filter(val => val.value !== '{}')
    ?.map(item => {
      if (Array.isArray(item?.valueList)) {
        const newArr = item?.valueList?.map(val => ({ id: item?.id, value: val }));

        return newArr;
      }
      return {
        id: item?.id,
        value: item?.value ? item?.value : item?.valueList,
        valueType: item?.valueType
      };
    });

  const handleClose = () => {
    setOpenRespModal(false);
  };

  const saveLead = (changeTaskResponsible: null) => {
    const tags: number[] = [];

    for (let i = 0; i < crmLid.tags.length; i++) {
      tags.push(Number(crmLid.tags[i].id));
    }

    let { pipeStatusId } = crmLid;

    if (incoming && pipeList && pipeList[0].id === pipeStatusId) {
      pipeStatusId = pipeList[1].id as number;
    }

    const data = {
      id: Number(crmLid.id),
      contacts: crmLid.contacts as any,
      balance: crmLid.balance,
      responsibleById: crmLid.responsibleById,
      pipeStatusId,
      tagIds: tags,
      fields: sendLeadFields.flat(Infinity),
      name: crmLid.name.replaceAll(' ', '').length > 0 ? crmLid.name : null,
      reasonsId: null,
      autoClosedLeadTask: !!(pipeType === 'FAILED' || pipeType === 'SUCCESS'),
      changeTaskResponsible
    };

    saveLidMutation.mutateAsync(data).then(() => {
      setOpenRespModal(false);
      dispatch(setChangeResponsible());
    });
  };
  const saveLead1 = (changeTaskResponsible: boolean) => {
    const tags: number[] = [];

    for (let i = 0; i < crmLid.tags.length; i++) {
      tags.push(Number(crmLid.tags[i].id));
    }

    let { pipeStatusId } = crmLid;

    if (incoming && pipeList && pipeList[0].id === pipeStatusId) {
      pipeStatusId = pipeList[1].id as number;
    }

    const data = {
      id: Number(crmLid.id),
      contacts: crmLid.contacts as any,
      balance: crmLid.balance,
      responsibleById: crmLid.responsibleById,
      pipeStatusId,
      tagIds: tags,
      fields: sendLeadFields.flat(Infinity),
      name: crmLid.name.replaceAll(' ', '').length > 0 ? crmLid.name : null,
      reasonsId: null,
      autoClosedLeadTask: !!(pipeType === 'FAILED' || pipeType === 'SUCCESS'),
      changeTaskResponsible
    };

    saveLidMutation1.mutateAsync(data).then(() => {
      setOpenRespModal(false);
      dispatch(setChangeResponsible());
    });
  };

  return (
    <Modal visible={openRespModal} footer={false} onCancel={handleClose} className={styles.respChangeModal} title={t('home.Xodimga_topshiriq_biriktirish')}>
      <div className={styles.respChangeModal_body}>
        <span>{t('home.Lidga_tegishli_topshiriqlarni_yangi_masul_xodimga_biriktirasizni')}</span>
        <div className={styles.footer}>
          <Button loading={saveLidMutation?.isLoading} onClick={() => saveLead(null)}>
            {t('home.Yoq')}
          </Button>
          <Button loading={saveLidMutation1?.isLoading} onClick={() => saveLead1(true)}>
            {t('home.Ha')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RespChangeModal;
