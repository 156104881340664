export interface Cash {
  id?: number;
  cashId: string;
  cashKey: string;
  buildingIds: number[];
  systemType: SystemTypeCash;
  status?: boolean;
  type?: string;
}

export enum SystemTypeCash {
  PAYME = 'PAYME',
  MY_UZCARD = 'MY_UZCARD'
}
