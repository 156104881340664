import { useState } from 'react';
import DeleteAreaArageIcon from '@icons/areaarage/DeleteAreaArageIcon';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import { Button, Dropdown, Menu, Spin, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';

import { useDeleteSMSBot } from '../service/mutation';
import { useGetMessage } from '../service/queries';
import { DataType } from '../utils/models/DataType';

import failed from '../../../assets/failed.svg';
import pending from '../../../assets/pending.svg';
import rejected from '../../../assets/rejected.svg';
import sent from '../../../assets/sent.svg';


type AutoSMSTriggerNotificationsProps = {
  selectedId: number | null;
}

const AutoSMSTriggerNotifications: React.FC<AutoSMSTriggerNotificationsProps> = ({ selectedId }) => {
  const { data: dataMessage, isLoading, refetch: refetchDataMessage } = useGetMessage(1, 20, selectedId);
  const deleteSMSBot = useDeleteSMSBot();
  const [menuVisible, setMenuVisible] = useState(false);

  console.log('dataMessage', dataMessage?.data);

  const handleDelete = (id: number) => {
    deleteSMSBot.mutate(id);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const type: { [key: string]: { text: string; icon: string } } = {
    SENT: {
      text: t('home.Jonatilgan'),
      icon: sent
    },
    PENDING: {
      text: t('home.Jonatish_kutilmoqda'),
      icon: pending
    },
    REJECTED: {
      text: t('home.Jonatilmagan'),
      icon: rejected
    },
    FAILED_SENT: {
      text: t('home.Jonatish_rad_etilgan'),
      icon: failed
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('home.Sana'),
      dataIndex: 'date',
      key: 'date',
      render: text => (
        <Tooltip title={text}>
          <span>{text.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
        </Tooltip>
      )
    },
    {
      title: t('integration.Xabar'),
      dataIndex: 'text',
      key: 'text',
      render: text => (
        <Tooltip title={text}>
          <span>{text.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
        </Tooltip>
      )
    },
    {
      title: t('integration.Lid'),
      dataIndex: 'leadName',
      key: 'leadName',
      render: text => (
        <Tooltip title={text}>
          <span>{text.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
        </Tooltip>
      )
    },
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      render: record => (
        <Tooltip title={type[record]?.text}>
          <img src={type[record]?.icon} alt="icon" />
        </Tooltip>
      )
    },

    {
      title: '',
      dataIndex: '',
      render: record => record.type === 'PENDING' ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <DeleteAreaArageIcon />
                  <Button type="text" onClick={() => handleDelete(record.id)}>
                    {t('home.Bekor_qilish')}
                  </Button>
                </div>
              </Menu.Item>
            </Menu>
          }
          onVisibleChange={toggleMenu}
          trigger={['click']}
        >
          <button style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <DotsIcon />
          </button>
        </Dropdown>
      ) : null
    }
  ];

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px'
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Table columns={columns} dataSource={dataMessage?.data} />
      )}
    </>
  );
};

export default AutoSMSTriggerNotifications;
