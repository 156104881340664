import React from 'react';
import { Modal, Spin } from 'antd';
import styles from './chats.module.scss';
import AddChatItem from './addChatItem/AddChatItem';
import { useBotChats } from '../../../../queries/queries';
import ChatItem from './chatItem/ChatItem';
import InfoIcon from '../../../../assets/icons/InfoIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

const ChatsModal: React.FC<Props> = ({ visible, onClose }) => {
  const { data, isLoading, isFetching } = useBotChats();

  const { t } = useTranslation();

  // onCloseModal
  const onCloseModal = () => {
    onClose(false);
  };

  return (
    <Modal
      title={t('integration.Ijtimoiy_tarmoqlar')}
      className={styles.container}
      visible={visible}
      onCancel={onCloseModal}
      footer={false}
      width={948}
    >
      <Spin spinning={isLoading || isFetching}>{data?.map((item, index) => <ChatItem key={index} chat={item} />)}</Spin>
      <AddChatItem />
      <div className={styles.container_footer}>
        <InfoIcon />
        <span>{t('integration.Bot_foydalanuvchilari_telegram_kanalga_obuna')}</span>
      </div>
    </Modal>
  );
};

export default ChatsModal;
