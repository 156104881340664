import { FlatId } from '../../../utils/models/Flat';
interface IProps {
  setSelectVsible: (value: React.SetStateAction<boolean>) => void;
  selectableItems: React.MutableRefObject<any>;
}

export const handleSelection = (e: any, props: IProps) => {
  props.setSelectVsible(false);
  const elementsContainer = document.querySelectorAll('.selectableItems');
  const elementsContainerarr: any = [];
  elementsContainer.forEach((item: any) => {
    const { left, top, width, height } = item?.getBoundingClientRect();
    elementsContainerarr.push({
      left,
      top,
      width,
      height,
      item: JSON.parse(item.getAttribute('data'))
    });
  });
  const indexesToSelect: any = [];
  elementsContainerarr.forEach((t: any) => {
    if (e.left <= t.left + t.width && e.left + e.width >= t.left && e.top <= t.top + t.height && e.top + e.height >= t.top) {
      indexesToSelect.push(t.item);
    }
  });
  elementsContainer.forEach((item: any) => {
    function Selected(a: FlatId) {
      return (
        a &&
        a.floor === JSON.parse(item.getAttribute('data')).floor &&
        a.xaxis[0] === JSON.parse(item.getAttribute('data')).xaxis[0] &&
        a.entrance === JSON.parse(item.getAttribute('data')).entrance
      );
    }

    if (indexesToSelect.some(Selected)) {
      item.style.border = '1.5px solid #00A389';
      item.setAttribute(
        'data',
        JSON.stringify({
          ...JSON.parse(item.getAttribute('data')),
          isSelected: true
        })
      );
    } else {
      item.style.border = '';
      item.setAttribute(
        'data',
        JSON.stringify({
          ...JSON.parse(item.getAttribute('data')),
          isSelected: false
        })
      );
    }
  });
  props.selectableItems.current = indexesToSelect;
};
