import React from 'react';

interface Props {
  className?: string;
}

const EmptyWalletTick: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.524 7.90432C10.279 8.14349 10.139 8.48766 10.174 8.85516C10.2265 9.48516 10.804 9.94599 11.434 9.94599H12.5423V10.6402C12.5423 11.8477 11.5565 12.8335 10.349 12.8335H4.45149C4.63232 12.6818 4.78982 12.4952 4.91232 12.2852C5.12815 11.9352 5.25065 11.521 5.25065 11.0835C5.25065 9.79433 4.20648 8.75016 2.91732 8.75016C2.36898 8.75016 1.86148 8.94267 1.45898 9.2635V6.71434C1.45898 5.50684 2.44481 4.521 3.65231 4.521H10.349C11.5565 4.521 12.5423 5.50684 12.5423 6.71434V7.55434H11.364C11.0373 7.55434 10.7398 7.68266 10.524 7.90432Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.45898 7.23926V4.57346C1.45898 3.87929 1.88482 3.26093 2.53232 3.01593L7.16398 1.26593C7.88732 0.991763 8.66316 1.52845 8.66316 2.30428V4.52094"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.16 8.14917V9.35088C13.16 9.67171 12.9033 9.93418 12.5766 9.94585H11.4333C10.8033 9.94585 10.2258 9.48502 10.1733 8.85502C10.1383 8.48752 10.2783 8.14335 10.5233 7.90418C10.7391 7.68252 11.0366 7.5542 11.3633 7.5542H12.5766C12.9033 7.56587 13.16 7.82834 13.16 8.14917Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.08398 7H8.16732" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.25065 11.0833C5.25065 11.5208 5.12815 11.935 4.91232 12.285C4.78982 12.495 4.63232 12.6817 4.45149 12.8333C4.04315 13.2008 3.50648 13.4167 2.91732 13.4167C2.06565 13.4167 1.32482 12.9617 0.922319 12.285C0.706485 11.935 0.583984 11.5208 0.583984 11.0833C0.583984 10.3483 0.922318 9.68917 1.45898 9.26334C1.86148 8.9425 2.36898 8.75 2.91732 8.75C4.20648 8.75 5.25065 9.79417 5.25065 11.0833Z"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.00781 11.0831L2.58531 11.6606L3.82781 10.5115" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EmptyWalletTick;
