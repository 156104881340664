
import { useMutation } from 'react-query';

import { showMessage } from '../../../queries/showMessage';
import i18n from '../../../service/I18nService';
import { $api } from '../../../service/RequestService';
import { downloadFile } from '../../../utils/helper/downloadFile';
import { getPaymentsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { SearchParamsPayment } from '../../payment/Payment';
import { endPoints } from '../utils/constants/endPoints';

export function useDownloadPaymentsToExcel(size: number, searchObj: SearchParamsPayment) {
  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.PAYMENT_FILTER_EXCEL }?lang=${i18n.language?.toUpperCase()}`, getPaymentsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `To'lovlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadContractAct(id: number) {
  return useMutation(
    async () => {
      const res = await $api.get(`${endPoints.CONTRACT + id  }/act/template`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `${id}-akt`, '.docx');
      },
      onError: async () => {
        showMessage(
          {
            uz: 'Xatolik mavjud',
            ru: 'Есть ошибка',
            kr: 'Xatolik mavjud',
            en: 'Error'
          },
          false
        );
      }
    }
  );
}
