import React, { useEffect } from 'react';
import { Button, Checkbox, Form } from 'antd';
import logo from '../../assets/img/big_logo.png';
import styles from './rootIntegration.module.scss';
import { useUyqur, useUyqurBuilders } from '../../queries/queries';
import { rootPaths } from '../../routing/rootPaths';
import { MainPaths } from '../../routing/main/mainPaths';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useUyqurMutate } from '../../queries/mutation';
import { Uyqur } from '../../utils/models/Uyqur';
import { useTranslation } from 'react-i18next';

const UyqurIntegration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const uyqurMutate = useUyqurMutate();
  const { data: uyqurBuilders } = useUyqurBuilders();
  const { data: uyqur } = useUyqur();
  const [form] = Form.useForm();
  const query = queryString.parseUrl(location.search).query;

  useEffect(() => {
    if (uyqur?.builders) {
      form.setFieldsValue({
        builders: uyqur?.builders.map(item => item.id)
      });
    }
  }, [uyqur?.builders, form]);

  // onFinish
  const onFinish = (values: { builders: number[] }) => {
    let builders = uyqurBuilders!?.filter(item => values.builders.includes(item.id));
    uyqurMutate
      .mutateAsync({
        extra: String(query?.extra_id),
        builders: builders,
        redirectUrl: String(query?.redirect_url)
      })
      .then((res: { data: Uyqur }) => {
        window.location.href = res?.data.redirectUrl + `?extra=${res?.data?.extra}&key=${res.data?.key}`;
      });
  };

  // backHandle
  const backHandle = () => {
    navigate({ pathname: rootPaths.MAIN + MainPaths.INDEX });
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_integration}>
        <div className={styles.container_content}>
          <img src={logo} alt="" />
          <h3>
            <a href="http://uyqur.uz" target="_blank" rel="noreferrer">
              Uyqur.uz
            </a>{' '}
            platformasi bilan ulashiladigan quruvchi hisoblarini tanlang:
          </h3>
          <Form onFinish={onFinish} form={form}>
            <Form.Item name={'builders'} className={styles.container_houses}>
              <Checkbox.Group>
                {uyqurBuilders?.map(item => (
                  <Checkbox key={item.id} value={item.id}>
                    {item.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <div className={styles.container_content_footer}>
              <Button onClick={backHandle}>{t('contract.Orqaga')}</Button>
              <Button type="primary" htmlType="submit">
                {t('one_lid.Tasdiqlash')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UyqurIntegration;
