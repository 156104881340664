import { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { Segmented, Spin } from 'antd';
import { SegmentedLabeledOption } from 'antd/es/segmented';
import { SegmentedValue } from 'antd/lib/segmented';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useCurrencyList } from '../../../queries/queries';
import { setBossCurrency } from '../../../store/reducers/BossCurrencySlice';

import styles from './selectCurrency.module.scss';

const SelectCurrency = () => {
  const { appendMultipleDifferent } = useQueryParam();
  const { data, isLoading } = useCurrencyList();
  const [options, setOptions] = useState<(SegmentedValue | SegmentedLabeledOption)[]>([]);

  const currency = useAppSelector(state => state.bossCurrency.currency);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      const newOptions: (SegmentedValue | SegmentedLabeledOption)[] = [];

      data.forEach(item => {
        if (item.basic && !currency) {
          dispatch(setBossCurrency(item));
        }
        newOptions.push({
          label: <span className={styles.container_segment_item}>{item.ccy}</span>,
          value: item.ccy
        });
      });
      setOptions(newOptions);
    }
  }, [data]);

  // handleChangeCurrency
  const handleChangeCurrency = (e: SegmentedValue) => {
    const newCurrency = data?.find(item => item.ccy === e);

    appendMultipleDifferent(['currencyId'], [newCurrency?.id]);
    dispatch(setBossCurrency(newCurrency));
  };

  return data && data?.length > 1 ? (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        <Segmented onChange={handleChangeCurrency} value={currency?.ccy || ''} className={styles.container_segment} options={options} />
      </Spin>
    </div>
  ) : null;
};

export default SelectCurrency;
