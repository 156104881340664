import { ResponsivePie } from '@nivo/pie';
import { Spin } from 'antd';
import React from 'react';

type Props = {
  data: any;
  graphLoading: boolean;
};

const PieChart: React.FC<Props> = ({ data, graphLoading }) => {
  return (
    <Spin spinning={graphLoading}>
      <div style={{ height: 400 }}>
        <ResponsivePie
          data={data}
          margin={{ top: 180, right: 80, bottom: 40, left: 80 }}
          innerRadius={0.6}
          padAngle={0}
          cornerRadius={0}
          activeOuterRadiusOffset={4}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]]
          }}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['opacity', 3]]
          }}
          arcLabelsSkipAngle={10}
          enableArcLinkLabels={false}
        />
      </div>
    </Spin>
  );
};

export default PieChart;
