import React from 'react';

const ChangeTypeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.42 19.2504H8.69C6 19.2504 4.75 18.0004 4.75 15.3104V12.5804C4.75 9.90039 6 8.65039 8.69 8.65039H11.42C14.1 8.65039 15.36 9.90039 15.36 12.5904V15.3204C15.35 18.0004 14.1 19.2504 11.42 19.2504ZM8.69 10.1504C6.82 10.1504 6.25 10.7204 6.25 12.5904V15.3204C6.25 17.1904 6.82 17.7604 8.69 17.7604H11.42C13.29 17.7604 13.86 17.1904 13.86 15.3204V12.5904C13.86 10.7204 13.29 10.1504 11.42 10.1504H8.69Z"
        fill="white"
      />
      <path
        d="M15.3084 15.35H14.5984C14.1884 15.35 13.8484 15.01 13.8484 14.6V12.58C13.8484 10.71 13.2784 10.14 11.4084 10.14H9.39844C8.98844 10.14 8.64844 9.8 8.64844 9.39V8.69C8.64844 6.01 9.89844 4.75 12.5884 4.75H15.3184C17.9984 4.75 19.2584 6 19.2584 8.69V11.42C19.2484 14.1 17.9984 15.35 15.3084 15.35ZM10.1484 8.65H11.4184C14.0984 8.65 15.3584 9.9 15.3584 12.59V13.86C17.1984 13.85 17.7584 13.28 17.7584 11.42V8.69C17.7584 6.82 17.1884 6.25 15.3184 6.25H12.5884C10.7284 6.25 10.1584 6.81 10.1484 8.65Z"
        fill="white"
      />
      <path
        d="M15.0002 22.7502C14.7302 22.7502 14.4802 22.6002 14.3502 22.3702C14.2202 22.1302 14.2202 21.8502 14.3602 21.6102L15.4102 19.8602C15.6202 19.5002 16.0802 19.3902 16.4402 19.6002C16.8002 19.8102 16.9102 20.2702 16.7002 20.6302L16.4302 21.0802C19.1902 20.4302 21.2602 17.9502 21.2602 14.9902C21.2602 14.5802 21.6002 14.2402 22.0102 14.2402C22.4202 14.2402 22.7602 14.5802 22.7602 14.9902C22.7502 19.2702 19.2702 22.7502 15.0002 22.7502Z"
        fill="white"
      />
      <path
        d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9C1.25 4.73 4.73 1.25 9 1.25C9.27 1.25 9.52 1.4 9.65 1.63C9.78 1.87 9.78 2.15 9.64 2.39L8.59 4.14C8.38 4.49 7.92 4.61 7.56 4.39C7.21 4.18 7.09 3.72 7.31 3.36L7.58 2.91C4.81 3.56 2.75 6.04 2.75 9C2.75 9.41 2.41 9.75 2 9.75Z"
        fill="white"
      />
    </svg>
  );
};

export default ChangeTypeIcon;
