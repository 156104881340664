import React from 'react';
import { useGetEmbedWidget } from '@pages/oneLid/services/queries';
import { useCrmFields } from '@queries/queries';
import { OneLid } from '@utils/models/OneLid';
import { Collapse } from 'antd';
import queryString from 'query-string';

import embed from '../../../../../assets/img/embed2.png';

interface IProps {
  data: OneLid | undefined;
}

const EmberWidget: React.FC<IProps> = ({ data }) => {
  const { data: embedWidget } = useGetEmbedWidget();
  const { data: crmFields } = useCrmFields();

  const mergedArray = data?.fields.map(item2 => {
    const found = crmFields?.find(item1 => item1.id === item2.id);

    if (found) {
      return { ...item2, name: found.name };
    }
    return item2;
  });

  const url = queryString.stringifyUrl({ url: embedWidget?.code || '', query: { uuid: data?.uuid, fields: JSON.stringify(mergedArray) } });

  return (
    <>
      {embedWidget?.active ? (
        <div className="flex flex-col">
          <Collapse expandIconPosition="right" bordered={false} className="customCollapse h-full" defaultActiveKey="1">
            <Collapse.Panel
              key="1"
              header={
                <div className="flex items-center gap-1">
                  <img src={embed} alt="err" />
                  <p className="text-base font-medium text-[#444444]">{embedWidget?.name}</p>
                </div>
              }
              className="bg-white"
            >
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe width="100%" height="100%" style={{ border: 'none' }} src={url} />
            </Collapse.Panel>
          </Collapse>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EmberWidget;
