import React from 'react';

interface Props {
  className?: string;
}

const TableOrderIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.63398 0.499999C3.01888 -0.166668 3.98113 -0.166667 4.36603 0.5L6.53109 4.25C6.91599 4.91667 6.43486 5.75 5.66506 5.75H1.33493C0.565135 5.75 0.084011 4.91667 0.468911 4.25L2.63398 0.499999Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export default TableOrderIcon;
