// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks/reduxHooks';
import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import '@pages/shaxmatka/shaxmatka.module.scss';

interface EntranceDoorModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const EntranceDoorModal: FC<EntranceDoorModalProps> = ({ visible, setVisible }) => {
  const [basement, setBasement] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    dispatch(
      setShaxmatka(
        Array.from({ length: values.finishDoorNumber }, (_, i) =>
          Array.from(
            {
              length: Number(values.flatNumber) + (basement ? Number(values.basement) : 0)
            },
            (_, j) =>
              Array.from({ length: values.floorRoomsNumber }, (_, k) => ({
                new: Number(values.startDoorNumber) > i + 1,
                floor: basement ? j + 1 - Number(values.basement) : j + 1,
                isSelected: false,
                apartment: true,
                area: null,
                building: { id: 'number', name: 'string' },
                createdBy: '',
                createdDate: '',
                delay: null,
                deletedBy: null,
                deletedDate: null,
                entrance: i + 1,
                entranceName: String(i + 1),
                gallery: [],
                number: String(k + 1 + j * values.floorRoomsNumber + i * values.flatNumber * values.floorRoomsNumber),
                options: null,
                prepayment: null,
                prepaymentPercent: null,
                price: null,
                priceNotRepaired: null,
                pricePerArea: null,
                pricePerAreaRepaired: null,
                priceRepaired: null,
                repaired: false,
                rooms: null,
                status: 'SALE',
                terrace: null,
                xaxis: [k + 1],
                yaxis: [j + 1]
              }))
          )
        )
      )
    );
    handleCancel();
  };

  return (
    <Modal
      title={t('shaxmatka.Kirish_eshigi_yaratish')}
      className="entranceDoorModal"
      visible={visible}
      footer={false}
      onCancel={handleCancel}
      width={407}
    >
      <Form onFinish={onFinish} form={form}>
        <div className="entranceDoorModalCount">
          <label>{t('shaxmatka.Kirish_eshigi')}: </label>
          <Form.Item
            // label={'Kirish eshigi'}
            name="startDoorNumber"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('shaxmatka.Masalan_3')} type="number" />
          </Form.Item>
          <Form.Item
            // label={'Kirish eshigi'}
            name="finishDoorNumber"
            rules={[{ required: true }]}
          >
            <Input placeholder={t('shaxmatka.Masalan_3')} type="number" />
          </Form.Item>
        </div>
        <Form.Item label={t('shaxmatka.Qavatlar_soni')} name="flatNumber" rules={[{ required: true }]}>
          <Input placeholder={t('shaxmatka.Masalan_3')} type="number" />
        </Form.Item>
        <Form.Item label={t('shaxmatka.Qavatdagi_xonadonlar_soni')} name="floorRoomsNumber" rules={[{ required: true }]}>
          <Input placeholder={t('shaxmatka.Masalan_3')} type="number" />
        </Form.Item>
        <Checkbox onChange={(e: any) => setBasement(e.target.checked)} style={{ marginBottom: 10 }}>
          {t('shaxmatka.Yer_tola_bilan_yaratish')}
        </Checkbox>
        {basement && (
          <>
            <Form.Item label={t('shaxmatka.Necha_qavatli')} name="basement" rules={[{ required: true }]}>
              <Input placeholder={t('shaxmatka.Masalan_3')} type="number" />
            </Form.Item>
          </>
        )}
        <div className="entranceDoorModalFooter">
          <Button onClick={handleCancel}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button htmlType="submit" type="primary">
            {t('shaxmatka.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EntranceDoorModal;
