import React, { useEffect, useState } from 'react';
import { Button, Modal, notification } from 'antd';
import dayjs from 'dayjs';

import CloseCrmIcon from '../../assets/icons/CloseCrmIcon';
import AlertIcon from '../../assets/icons/expire-date/AlertIcon';
import SpeedMaterIcon from '../../assets/icons/SpeedMaterIcon';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCompanyExpireDate } from '../../queries/queries';

import ExpireDateChangeModal from './ExpireDateChangeModal';

import not_account from '../../assets/img/not_account.png';

import styles from './expireDate.module.scss';

const ExpireDate = () => {
  const [visibleChangeModal, setVisibleChangeModal] = useState(false);
  const [visible, setVisible] = useState({ container: false, child: false });
  const { lifetime } = useAppSelector(state => state.userReducer);
  const { location } = useQueryParam();
  const { data } = useCompanyExpireDate();

  function getNotification() {
    notification.open({
      className: styles.container,
      message: false,
      closeIcon: null,
      description: (
        <>
          <div className={styles.header}>
            <AlertIcon />
            <span onClick={() => notification.close('EXPDNTF')}>
              <CloseCrmIcon strokeWidth={2} stroke="#F04438" />
            </span>
          </div>
          <div className={styles.title}>Dastur litsenziyasi tugashiga {data?.expiryDate} kun qoldi!</div>
          <div className={styles.content}>Yangi litsenziya sotib olishingizni so'raymiz aks holda tizim avtomatik o'chib qoladi.</div>
        </>
      ),
      placement: 'bottomRight',
      key: 'EXPDNTF'
    });
  }

  useEffect(() => {
    if (data && data?.expiryDate < 7 && dayjs().unix() >= lifetime && data?.expiryDate > 3) {
      setTimeout(() => {
        getNotification();
      }, 900000);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data && data?.expiryDate < 3) {
      setTimeout(() => {
        getNotification();
      }, 900000);
    }
    if (!visible.child && !visibleChangeModal) {
      if (data && (data?.expiryDate || 1) < 0) {
        setVisible({ container: true, child: false });
      } else {
        setVisible({ container: false, child: false });
      }
    }
    // eslint-disable-next-line
  }, [data, location, visible.child]);

  const element = document.querySelector('.expireDateModal');

  useEffect(() => {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(cal => {
      setVisible({ container: false, child: false });
      setTimeout(() => {
        setVisible({ container: true, child: false });
      }, 0);
    });

    if (element && !visible.child && !visibleChangeModal) {
      observer.observe(element, config);
    }
  }, [element, location, visible.child, visibleChangeModal]);

  return (
    <>
      {/* {visible.container && ( */}
      <Modal
        title={false}
        footer={false}
        closable={false}
        centered
        visible={(data?.expiryDate || 1) < 0}
        className={`${styles.modal} ${styles.expireDateModal} expireDateModal`}
        width={839}
      >
        <div className={styles.expireDateModal}>
          <div className={styles.expireDateModal_left}>
            <img src={not_account} alt="" />
          </div>
          <div className={styles.expireDateModal_right}>
            <h1>Hisobingiz litsensiya muddati tugaganligi sababli bloklandi!</h1>
            <div>
              <SpeedMaterIcon /> Sizda litsenziya muddatini to’lovsiz uzaytirish imkoniyati mavjud
            </div>
            <Button
              type="primary"
              onClick={() => {
                setVisible({ container: true, child: true });
                setVisibleChangeModal(true);
                console.log('salom');
              }}
            >
              Bepul uzaytirish
            </Button>
          </div>
        </div>
      </Modal>
      {/* )} */}
      {/* {visible.child && ( */}
      <ExpireDateChangeModal
        visible={visibleChangeModal}
        setVisible={setVisibleChangeModal}
        expireDate={data?.expiryDate || 0}
        setVisibleContainer={setVisible}
      />
      {/* )} */}
    </>
  );
};

export default ExpireDate;
