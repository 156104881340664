import { useBrojectConfig, useBrojectConfigSave } from 'modules/broject';

interface IProps {
  children: (props: { submit: () => void; loading: boolean; active: boolean; isData: boolean }) => React.ReactElement;
}

const BrojectConfigActivation = ({ children }: IProps) => {
  const { data } = useBrojectConfig();
  const save = useBrojectConfigSave();

  const handleSubmit = () => {
    if (data) {
      save.mutate({ ...data, status: !data?.status });
    }
  };

  return <>{children({ submit: handleSubmit, active: !!data?.status, isData: !!data, loading: save.isLoading })}</>;
};

export default BrojectConfigActivation;
