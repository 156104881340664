import React from 'react';
import MainHeader from '../../../components/headers/MainHeader';
import NotificationButtons from '../../../components/headers/components/NotificationButtons';
import styles from './bossSale.module.scss';
import BossSaleBar from './boss-sale-bar/BossSaleBar';
import BossSaleLine from './boss-sale-line/BossSaleLine';
import { useTranslation } from 'react-i18next';

const BossSale = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader>
        <h1 className="header_title">{t('titles.Sotuv')}</h1>
        <div className={'d-flex align-center'}>
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className={styles.container}>
        <div className={styles.body}>
          <BossSaleBar />
          <BossSaleLine />
        </div>
      </div>
    </>
  );
};

export default BossSale;
