import React from 'react';
import dayjs from 'dayjs';

import styles from './dateItem.module.scss';
import { TaskFilter } from '@utils/models/TaskFilter';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { ONE_DAY_UNIX_VALUE } from '@utils/constants/ONE_DAY_UNIX_VALUE';

interface Props {
  data: TaskFilter;
}

const DateItem: React.FC<Props> = ({ data }) => {
  const calculateDate = () => {
    const allDay =
      dayjs(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() - dayjs(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() === ONE_DAY_UNIX_VALUE;
    if (allDay) return '';
    return data.deadlineStart + '-' + data.deadlineFinish;
  };

  if (dayjs(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() < dayjs(new Date()).unix() && !data.closedDate)
    return (
      <p className={styles.container_deadline}>
        {data?.leadName || data?.contractNumber} {data.leadTaskType.name} {calculateDate()}
        {data.text && ': '}
        {data.text}
      </p>
    );

  return (
    <p className={styles.container_simple}>
      {data?.leadName || data?.contractNumber}{' '}
      <span>
        {calculateDate()} {data.leadTaskType.name}
        {data.text && ': '}
      </span>
      {data.text}
    </p>
  );
};

export default DateItem;
