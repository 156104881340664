import React from 'react';

const SMSIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.334 14.1666H4.66732C2.66732 14.1666 1.33398 13.1666 1.33398 10.8333V6.16659C1.33398 3.83325 2.66732 2.83325 4.66732 2.83325H11.334C13.334 2.83325 14.6673 3.83325 14.6673 6.16659V10.8333C14.6673 13.1666 13.334 14.1666 11.334 14.1666Z"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3327 6.5L9.24601 8.16667C8.55935 8.71333 7.43268 8.71333 6.74601 8.16667L4.66602 6.5"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SMSIcon;
