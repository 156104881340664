import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TerassaButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const TerassaButton: FC<TerassaButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button onClick={onClick} className="TerassaButton">
      <span>+</span> {t('shaxmatka.qoshish')}
    </button>
  );
};

export default TerassaButton;
