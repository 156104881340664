export function isEmptyObj(object: object | undefined) {
  let result = false;

  if (object) {
    const keys = Object.keys(object);
    const isKeys = keys?.length > 0;

    if (isKeys) {
      for (const key of keys) {
        result = !object[key as keyof unknown];
      }
    } else {
      result = true;
    }
  } else {
    result = true;
  }

  return result;
}
