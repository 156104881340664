import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const PauseIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="22.5" r="22.5" fill="#D9D9D9" />
      <g clipPath="url(#clip0_9944_45935)">
        <path d="M18.1782 28.125H21.3925V16.875H18.1782V28.125ZM24.6068 16.875V28.125H27.8211V16.875H24.6068Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_9944_45935">
          <rect width="19.2857" height="19.2857" fill="white" transform="translate(13.3569 12.8574)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PauseIcon;
