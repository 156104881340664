import React from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './myCalls.module.scss';
import { CreateMyCalls } from '../../../../../utils/models/MyCalls';
import { useCreateMyCalls } from '../../../../../queries/mutation';

const CreateMyCall = () => {
  const [form] = Form.useForm<CreateMyCalls>();

  const createMyCalls = useCreateMyCalls();

  const { t } = useTranslation();

  // on finish
  const onFinish = (val: CreateMyCalls) => {
    createMyCalls.mutate({
      ...val,
      apiKey: val.apiKey?.trim(),
      domain: val.domain?.trim(),
      email: val.email?.trim()
    });
  };

  return (
    <Form
      form={form}
      name="sendMessageCrm"
      initialValues={{ remember: true }}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <div className={styles.container_body_item}>
        <span>{t('my_calls.API_manzilingiz')}</span>
        <Form.Item
          name={'domain'}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <Input className={'customInput'} placeholder={'sof.moizvonki.uz'} />
        </Form.Item>
      </div>
      <hr />
      <div className={styles.container_body_item}>
        <span>{t('my_calls.API_key')}</span>
        <Form.Item
          name={'apiKey'}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <Input className={'customInput'} placeholder={'52xmwh6ogf0wuchi5f0rj4ztxa6sez'} />
        </Form.Item>
      </div>
      <hr />
      <div className={styles.container_body_item}>
        <span>{t('my_calls.E_mail')}</span>
        <Form.Item
          name={'email'}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <Input className={'customInput'} placeholder={'sof@example.com'} />
        </Form.Item>
      </div>
      <hr />
      <div className={styles.container_body_modalFooter}>
        <Button loading={createMyCalls.isLoading} htmlType="submit" type="primary">
          {t('my_calls.Saqlash')}
        </Button>
      </div>
    </Form>
  );
};

export default CreateMyCall;
