import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import CloseIcon from '@icons/CloseIcon';
import ConfirmIcon1 from '@icons/ConfirmIcon1';
import { useCancelClient } from '@pages/oneLid/services/mutation';
import { ViewOneClient } from '@pages/oneLid/utils/models/ViewOneClientModel';
import { setOpenConfirmModal } from '@reducers/CrmOneLidSlice';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './confirmModal.module.scss';

type Props = {
  data?: ViewOneClient | undefined;
  clientName: string;
  leadName: any;
  clientId?: number | undefined;
  leadId?: number | undefined;
  type?: string;
};

const ConfirmModal: React.FC<Props> = ({ data, clientName, leadName, clientId, type, leadId }) => {
  const { t } = useTranslation();
  const { params } = useQueryParam<SearchParamsTask, any>();
  const dispatch = useDispatch();
  const cancelClient = useCancelClient();
  const openConfirmModal = useAppSelector(state => state.crmOneLidField.openConfirmModal);

  const handleClose = () => {
    dispatch(setOpenConfirmModal(false));
  };

  const handleCancelClient = (leadId: number, clientId: number) => {
    cancelClient
      .mutateAsync({
        clientId: Number(clientId),
        leadId: Number(leadId)
      })
      .then(handleClose);
  };

  return (
    <Modal visible={openConfirmModal} onCancel={handleClose} title={false} footer={false} className={styles.changeEmployee}>
      <div className={styles.changeEmployee_body}>
        <div className={styles.changeEmployee_title}>
          <ConfirmIcon1 />
          <div className={styles.changeEmployee_title_right}>
            <div className={styles.changeEmployee_title_right_top}>
              <p>{t('one_lid.Ochirish')}</p>
              <CloseIcon onClick={handleClose} />
            </div>
            <span>
              {t('one_lid.haqiqatdan')?.replace('%s', leadName)?.replace('?s', clientName)}
              {/* Siz {leadName}dan {clientName}ni o'chirmoqchimisiz ? */}
            </span>
          </div>
        </div>
        <div className={styles.changeEmployee_footer}>
          <Button onClick={handleClose}>{t('home.Yopish')}</Button>
          <Button
            loading={cancelClient.isLoading}
            onClick={() => handleCancelClient(type ? Number(params?.id) : Number(leadId), type ? Number(clientId) : Number(params?.idClient))}
          >
            {t('crm.Ochirish')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
