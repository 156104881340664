import React, { FC } from 'react';
import { Button } from 'antd';
import styles from './info.module.scss';
import LocationIcon from '../../../../../assets/icons/LocationIcon';
import { useGeoCodeYandex } from '../../../../../queries/queries';
import { t } from 'i18next';

interface IProps {
  lat?: string;
  lng?: string;
  radius?: string;
  name?: any;
}

const GetLocationAddres: FC<IProps> = ({ lng, lat, radius, name }) => {
  const { data } = useGeoCodeYandex(`&geocode=${lng},${lat}`);

  const convertText = (text: string) => {
    if (text?.length > 20) {
      return text?.slice(0, 20) + '...';
    } else {
      return text;
    }
  };
  return lat && lng ? (
    <div className={styles.locationText}>
      <LocationIcon />{' '}
      {data &&
        data?.response &&
        convertText(data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address?.formatted)}
    </div>
  ) : (
    <Button className={styles.btn} size="small">
      {t('home.Lokatsiyani_biriktirish')}
    </Button>
  );
};

export default GetLocationAddres;
