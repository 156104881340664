import React, { useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useTranslation } from 'react-i18next';

import Header from './components/header/Header';
import MyResponsiveBar from './components/my-chart-bar/MyResponsiveBar';

import styles from './bossMonthlyPayments.module.scss';

const BossMonthlyPayment: React.FC = () => {
  const { t } = useTranslation();
  const [multiple, setMultiple] = useState<number[]>();
  const { mainCurrency } = useAppSelector(({ currency }) => currency);
  const [currency, setCurrency] = useState(mainCurrency?.ccy || 'UZS');
  const [currencyId, setCurrencyId] = useState(mainCurrency?.id || 0);

  return (
    <div className={styles.monthlyPayment}>
      <Header currency={currency} multiple={multiple} setCurrencyId={setCurrencyId} setMultiple={setMultiple} setCurrency={setCurrency} />
      <div className={styles.monthlyPayment_chart}>
        <div className={styles.monthlyPayment_chart_top}>
          <div className={styles.circle} />
          <span>
            {t('boss_home.Reja')} ({t('boss_home.oylik_tolov_grafigi')})
          </span>
          <div className={styles.circle1} />
          <span>{t('boss_home.Tolovlar')}</span>
        </div>
        <MyResponsiveBar currencyId={currencyId} multiple={multiple} setMultiple={setMultiple} currency={currency} />
      </div>
    </div>
  );
};

export default BossMonthlyPayment;
