import { Route, Routes } from 'react-router-dom';
import { Layout, Row } from 'antd';

import CompanyConfigNavbar from '../../components/companyConfig/CompanyConfigNavbar';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';

import { CompanyConfigRoutes } from './companyConfigRoutes';

const { Content } = Layout;

const CompanyConfigRouter = () => (
  <>
    <MainHeader>
      <SettingsHeader />
      <div className="d-flex align-center">
        <OnlinePbxNotifications />
        <NotificationButtons />
      </div>
    </MainHeader>
    <Content
      style={{
        padding: '1rem 2.68rem',
        overflowY: 'auto',
        height: '50vh'
      }}
    >
      <Row style={{ flexFlow: 'row' }}>
        <CompanyConfigNavbar />
        <Routes>
          {CompanyConfigRoutes.map((route, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
          ))}
        </Routes>
      </Row>
    </Content>
  </>
);

export default CompanyConfigRouter;
