import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const CallCallingIcon: FC<IProps> = ({ color = '#13927D', onClick, className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M11.7468 7.16576C11.4602 7.16576 11.2335 6.93242 11.2335 6.65242C11.2335 6.40576 10.9868 5.89242 10.5735 5.44576C10.1668 5.01242 9.72016 4.75909 9.34683 4.75909C9.06016 4.75909 8.8335 4.52576 8.8335 4.24576C8.8335 3.96576 9.06683 3.73242 9.34683 3.73242C10.0135 3.73242 10.7135 4.09242 11.3268 4.73909C11.9002 5.34576 12.2668 6.09909 12.2668 6.64576C12.2668 6.93242 12.0335 7.16576 11.7468 7.16576Z"
        fill={color}
      />
      <path
        d="M14.1532 7.16732C13.8665 7.16732 13.6398 6.93398 13.6398 6.65398C13.6398 4.28732 11.7132 2.36732 9.35318 2.36732C9.06651 2.36732 8.83984 2.13398 8.83984 1.85398C8.83984 1.57398 9.06651 1.33398 9.34651 1.33398C12.2798 1.33398 14.6665 3.72065 14.6665 6.65398C14.6665 6.93398 14.4332 7.16732 14.1532 7.16732Z"
        fill={color}
      />
      <path
        d="M7.36683 9.96732L6.1335 11.2007C5.8735 11.4607 5.46016 11.4607 5.1935 11.2073C5.12016 11.134 5.04683 11.0673 4.9735 10.994C4.28683 10.3007 3.66683 9.57399 3.1135 8.81398C2.56683 8.05398 2.12683 7.29398 1.80683 6.54065C1.4935 5.78065 1.3335 5.05398 1.3335 4.36065C1.3335 3.90732 1.4135 3.47398 1.5735 3.07398C1.7335 2.66732 1.98683 2.29398 2.34016 1.96065C2.76683 1.54065 3.2335 1.33398 3.72683 1.33398C3.9135 1.33398 4.10016 1.37398 4.26683 1.45398C4.44016 1.53398 4.5935 1.65398 4.7135 1.82732L6.26016 4.00732C6.38016 4.17398 6.46683 4.32732 6.52683 4.47398C6.58683 4.61398 6.62016 4.75398 6.62016 4.88065C6.62016 5.04065 6.5735 5.20065 6.48016 5.35398C6.3935 5.50732 6.26683 5.66732 6.10683 5.82732L5.60016 6.35398C5.52683 6.42732 5.4935 6.51398 5.4935 6.62065C5.4935 6.67398 5.50016 6.72065 5.5135 6.77398C5.5335 6.82732 5.5535 6.86732 5.56683 6.90732C5.68683 7.12732 5.8935 7.41398 6.18683 7.76065C6.48683 8.10732 6.80683 8.46065 7.1535 8.81398C7.22016 8.88065 7.2935 8.94732 7.36016 9.01398C7.62683 9.27398 7.6335 9.70065 7.36683 9.96732Z"
        fill={color}
      />
      <path
        d="M14.6466 12.2194C14.6466 12.406 14.6132 12.5994 14.5466 12.786C14.5266 12.8394 14.5066 12.8927 14.4799 12.946C14.3666 13.186 14.2199 13.4127 14.0266 13.626C13.6999 13.986 13.3399 14.246 12.9332 14.4127C12.9266 14.4127 12.9199 14.4194 12.9132 14.4194C12.5199 14.5794 12.0932 14.666 11.6332 14.666C10.9532 14.666 10.2266 14.506 9.4599 14.1794C8.69324 13.8527 7.92657 13.4127 7.16657 12.8594C6.90657 12.666 6.64657 12.4727 6.3999 12.266L8.5799 10.086C8.76657 10.226 8.93324 10.3327 9.07324 10.406C9.10657 10.4194 9.14657 10.4394 9.19324 10.4594C9.24657 10.4794 9.2999 10.486 9.3599 10.486C9.47324 10.486 9.5599 10.446 9.63324 10.3727L10.1399 9.87271C10.3066 9.70604 10.4666 9.57938 10.6199 9.49938C10.7732 9.40604 10.9266 9.35938 11.0932 9.35938C11.2199 9.35938 11.3532 9.38604 11.4999 9.44604C11.6466 9.50604 11.7999 9.59271 11.9666 9.70604L14.1732 11.2727C14.3466 11.3927 14.4666 11.5327 14.5399 11.6994C14.6066 11.866 14.6466 12.0327 14.6466 12.2194Z"
        fill={color}
      />
    </svg>
  );
};

export default CallCallingIcon;
