import React from 'react';

const TableSettingsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5013 18.3327H12.5013C16.668 18.3327 18.3346 16.666 18.3346 12.4993V7.49935C18.3346 3.33268 16.668 1.66602 12.5013 1.66602H7.5013C3.33464 1.66602 1.66797 3.33268 1.66797 7.49935V12.4993C1.66797 16.666 3.33464 18.3327 7.5013 18.3327Z"
        stroke="#344054"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12.9766 15.416V12.166" stroke="#344054" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.9766 6.20898V4.58398" stroke="#344054" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M12.9753 10.5423C14.1719 10.5423 15.1419 9.57227 15.1419 8.37565C15.1419 7.17903 14.1719 6.20898 12.9753 6.20898C11.7786 6.20898 10.8086 7.17903 10.8086 8.37565C10.8086 9.57227 11.7786 10.5423 12.9753 10.5423Z"
        stroke="#344054"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M7.02344 15.416V13.791" stroke="#344054" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.02344 7.83398V4.58398" stroke="#344054" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.02604 13.7923C8.22266 13.7923 9.19271 12.8223 9.19271 11.6257C9.19271 10.429 8.22266 9.45898 7.02604 9.45898C5.82942 9.45898 4.85938 10.429 4.85938 11.6257C4.85938 12.8223 5.82942 13.7923 7.02604 13.7923Z"
        stroke="#344054"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TableSettingsIcon;
