import React, { FC, useState } from 'react';
import { ContractId } from '@utils/models/ContractId';
import { PayType } from '@utils/models/PayType';
import { AutoComplete, Input, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import CardAddIcon from '../../../../assets/icons/contract/CardAddIcon';
import CardSendIcon from '../../../../assets/icons/contract/CardSendIcon';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useContractListForPayment } from '../../../../queries/queries';
import { ContractForPaymentSearch } from '../../../../utils/models/ContractForPaymentSearch';
import { SearchParamsPayment } from '../../../payment/Payment';
import { useGetContractDataForPayment } from '../../services/queries';
import PaymentClientContract from '../Payment-client-contract/PaymentClientContract';

import PaymentClientBalance from './PaymentClientBalance';
import PaymentClientInfo from './PaymentClientInfo';

import Avatargroup from '../../../../assets/img/Avatargroup.png';

import styles from './paymentClient.module.scss';

interface IProps {
  contractIdData?: ContractId;
}

const PaymentClient: FC<IProps> = ({ contractIdData }) => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsPayment, any>();
  const [activeTab, setActiveTab] = useState<PayType>(PayType.CUSTOM);
  const [search, setSearch] = useState(searchParams?.search || '');
  const { t } = useTranslation();
  const { contractId } = searchParams;

  const contractIdNumber = Number(contractId) ? Number(contractId) : undefined;
  const { data: clientData } = useGetContractDataForPayment(String(contractIdNumber || ''));
  const { data: contractListForPayment } = useContractListForPayment(search);

  const onSelect = (obj: ContractForPaymentSearch) => {
    appendMultipleDifferent(['contractId'], [obj.id]);
    setSearch(`${obj.clientName} (${obj.number})`);
  };

  const onSearch = (e: string) => {
    setSearch(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_clientSearch}>
        <AutoComplete
          onSearch={onSearch}
          onSelect={(e: any, obj: ContractForPaymentSearch) => onSelect(obj)}
          defaultValue={search}
          value={search}
          autoFocus
          options={[
            ...(contractListForPayment?.map(item => ({
              ...item,
              value: `${item?.clientName} (${item?.number})`,
              label: (
                <div className={styles.container_clientSearch_item}>
                  <p>
                    {t('home.Shartnoma')} <span>{item?.number}</span>
                  </p>
                  <p>
                    {t('home.Mijoz')} <span>{item?.clientName}</span>
                  </p>
                  <p>
                    {t('home.Telefon')} <span>{item?.clientPhone}</span>
                  </p>
                </div>
              )
            })) || [])
          ]}
        >
          <Input prefix={<SearchIcon />} placeholder={t('home.Qidirish')} className="customInput" />
        </AutoComplete>
        {contractIdNumber ? (
          <>
            <PaymentClientInfo data={clientData} setSearch={setSearch} />
          </>
        ) : (
          <div className={styles.container_clientSearch_empty}>
            <img src={Avatargroup} alt="" />
            <h3>{t('create_payment.Mijoz_tanlanmagan')}...</h3>
            <p>{t('create_payment.Mijozni_qidirish_tanlash')}</p>
          </div>
        )}
      </div>
      {contractIdNumber && (
        <>
          <PaymentClientContract data={clientData} />
          {/* <div className={styles.container_contractMoney}>
            <div className={styles.container_contractMoney_item}>
              <span className={styles.container_contractMoney_item_line}></span>
              <p> {t('create_payment.Oylik_qarzdorlik')}</p>
              <h1>
                {clientData?.debt?.toLocaleString('ru')}{' '}
                {clientData?.currency?.ccy}
              </h1>
            </div>
            <div className={styles.container_contractMoney_item}>
              <span
                className={styles.container_contractMoney_item_line}
                style={{ background: '#BCBCBC' }}
              ></span>
              <p>{t('create_payment.Qodiq')}</p>
              <h1>
                {clientData?.residue?.toLocaleString('ru')}{' '}
                {clientData?.currency?.ccy}
              </h1>
            </div>
          </div> */}
        </>
      )}
      <Tabs className={styles.container_tabs} activeKey={activeTab} onChange={key => setActiveTab(key as PayType)}>
        <Tabs.TabPane
          key={PayType.CUSTOM}
          tab={
            <>
              <CardAddIcon /> {t("create_payment.Odatiy_to'lov")}
            </>
          }
        >
          <PaymentClientBalance contractIdData={contractIdData} contractCurrency={clientData?.currency} contractId={contractIdNumber} payType={activeTab} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key={PayType.BALANCE}
          tab={
            <>
              <CardSendIcon /> {t("create_payment.Balansdan_to'lash")}
            </>
          }
        >
          <PaymentClientBalance contractIdData={contractIdData} contractCurrency={clientData?.currency} contractId={contractIdNumber} payType={activeTab} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default PaymentClient;
