import React, { FC, useState } from 'react';
import { useTags } from '../../queries/queries';
import { Select } from 'antd';
import styles from './tagSelect.module.scss';
import { useCreateTag } from '../../queries/mutation';

interface IProps {
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  tags: string[];
}

const TagSelect: FC<IProps> = ({ setTags, tags }) => {
  const { data } = useTags();
  const createTag = useCreateTag();
  const [search, setSearch] = useState('');
  return (
    <Select
      mode="multiple"
      size="large"
      className={`customSelect ${styles.tags}`}
      value={tags}
      onChange={setTags}
      searchValue={search}
      onSearch={e => {
        setSearch(e);
      }}
      onInputKeyDown={e => {
        if (e.key === 'Enter') {
          createTag.mutateAsync(e?.currentTarget?.value).then(value => {
            setTags(prev => [...prev, value?.data?.name]);
            setSearch('');
          });
        }
      }}
    >
      {data?.tagList?.map(item => <Select.Option value={item?.name}>{item?.name}</Select.Option>)}
    </Select>
  );
};

export default TagSelect;
