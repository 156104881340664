import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { GetAssignInfoModel } from "../utils/models/GetAssignInfoModel";
import { LeadEmployeeModel } from "../utils/models/LeadEmployeeModel";

export function useGetAssignInfo(id: number) {
  return useQuery<GetAssignInfoModel>({
    queryKey: [queryKeys.GET_ASSIGN_INFO, id], queryFn: async () => {
      const res = await $api.get(`${endPoints.GET_ASSIGN_INFO + id}/get-assign-info`)

      return res.data.data
    },
    enabled: !!id
  })
}

export function useGetLeadEmployee(id: number) {
  return useQuery<LeadEmployeeModel[]>({
    queryKey: [queryKeys.LEAD_EMPLOYEE, id], queryFn: async () => {
      const res = await $api.get(endPoints.LEAD_EMPLOYEE)

      const data = res.data.data?.filter((item: LeadEmployeeModel) => item?.id !== id)

      return data
    },
    enabled: false
  })
}