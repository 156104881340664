import React, { memo } from 'react';
import styles from './filterDrawer.module.scss';
import { Input } from 'antd';
import { helper } from '../../../../../utils/helper/helper';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

interface Props {
  balanceFinish: string | undefined;
  balanceStart: string | undefined;
}

const BalanceInputsFilter: React.FC<Props> = ({ balanceFinish, balanceStart }) => {
  const { appendSingle } = useQueryParam();

  const { t } = useTranslation();

  // change balanceStart
  const changeBalanceStart = (e: string) => {
    const val = helper.deleteNotNumbers(e, null);
    appendSingle('balanceStart', val ? String(val) : undefined);
  };

  // change balanceFinish
  const changeBalanceFinish = (e: string) => {
    const val = helper.deleteNotNumbers(e, null);
    appendSingle('balanceFinish', val ? String(val) : undefined);
  };

  return (
    <div className={styles.container_balance}>
      <Input
        onChange={e => changeBalanceStart(e.target.value)}
        value={balanceStart ? Number(balanceStart).toLocaleString('ru') : (null as any)}
        placeholder={t('crm_filter.Budjet')}
        className={styles.container_balance_input}
      />
      <span className={styles.container_balance_mid}>-</span>
      <Input
        onChange={e => changeBalanceFinish(e.target.value)}
        value={balanceFinish ? Number(balanceFinish).toLocaleString('ru') : (null as any)}
        className={styles.container_balance_input}
      />
    </div>
  );
};

export default memo(BalanceInputsFilter);
