import React from 'react';
import { OnlinePbx } from '@utils/models/OnlinePbx';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import styles from './onlinePBX.module.scss';

interface Props {
  currentStep: number;
  changeStep: (step: number) => void;
  onlinePbx: OnlinePbx | null;
  refreshOnlinePBX: UseMutationResult<any, unknown, void, unknown>;
}

const Steps: React.FC<Props> = ({ currentStep, changeStep, onlinePbx, refreshOnlinePBX }) => {
  const { t } = useTranslation();

  // get class name
  const getClassName = (value: number) => {
    if (value === currentStep) {
      return `${styles.container_body_step_item} ${styles.container_body_step_item_active}`;
    }
    return styles.container_body_step_item;
  };

  // handle change step
  const handleChangeStep = (value: number) => {
    changeStep(value);
  };

  return (
    <div className={styles.container_body_step}>
      <span onClick={() => handleChangeStep(1)} className={getClassName(1)}>
        {t('online_pbx.Ulanish')}
      </span>
      <span
        onClick={() => (!onlinePbx?.connectError || !refreshOnlinePBX?.data?.connectError) && handleChangeStep(2)}
        className={`${getClassName(2)} ${onlinePbx?.connectError || refreshOnlinePBX?.data?.connectError ? '!cursor-not-allowed' : 'cursor-pointer'}`}
      >
        {t('online_pbx.Foydalanuvchilar')}
      </span>
      <span
        onClick={() => (!onlinePbx?.connectError || !refreshOnlinePBX?.data?.connectError) && handleChangeStep(3)}
        className={`${getClassName(3)} ${onlinePbx?.connectError || refreshOnlinePBX?.data?.connectError ? '!cursor-not-allowed' : 'cursor-pointer'}`}
      >
        {t('online_pbx.Sozlash')}
      </span>
    </div>
  );
};

export default Steps;
