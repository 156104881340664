import React, { useState, useRef, useEffect } from 'react';
import { Input, InputRef } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import {
  submitBUttonWidth,
  submitBackgroundColor,
  submitBorderRadius,
  submitColor,
  submitFontStyle,
  submitFontWeight,
  submitJustifyContent,
  submitTextDecoration,
  submitValue
} from '../../../../../../store/reducers/TextEditorSlice';
import styles from '../dragDrop.module.scss';
import PositionLeftIcon from '../../../../../../assets/icons/webforma/PositionLeftIcon';
import PositionCenterIcon from '../../../../../../assets/icons/webforma/PositionCenterIcon';
import PositionRightIcon from '../../../../../../assets/icons/webforma/PositionRightIcon';
import AlignLeftIcon from '../../../../../../assets/icons/webforma/AlignLeftIcon';
import SquareBorderIcon from '../../../../../../assets/icons/webforma/SquareBorderIcon';
import ResizeIcon from '../../../../../../assets/icons/webforma/ResizeIcon';

type Props = {
  getDx: number;
};

const FormBtn: React.FC<Props> = ({ getDx }) => {
  const dispatch = useAppDispatch();
  const [activeInput, setActiveInput] = useState(false);
  const inputRef = useRef<InputRef | null>(null);
  const [mouseEvent, setMouseEvent] = useState(false);
  const leftRef = useRef<HTMLDivElement | null>(null);
  const leftRightRef = useRef<HTMLDivElement | null>(null);

  const { backgroundColor, color, borderRadius, fontStyle, fontWeight, textDecoration, value, justifyContent, width } = useAppSelector(
    state => state.textEditorReducer.submitButton
  );
  const { height } = useAppSelector(state => state.textEditorReducer.fieldsStyle);
  const { fontFamily, leftWidth, fullWidth } = useAppSelector(state => state.textEditorReducer.main);

  useEffect(() => {
    const windowClick = () => {
      if (mouseEvent) {
        setActiveInput(false);
      }
    };

    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, [mouseEvent]);

  useEffect(() => {
    const resezableEl = leftRef.current!;
    const styles = window.getComputedStyle(resezableEl);
    let minWidth = parseInt(styles.minWidth, 10);
    let x = 0;
    let diffWidth = fullWidth - leftWidth;

    const onMouseMoveCenterResize = (event: any) => {
      const dx = event.clientX - x;
      x = event.clientX;
      minWidth = minWidth > diffWidth - 180 ? diffWidth - 180 : minWidth + dx;
      resezableEl.style.minWidth = `${minWidth}px`;
      dispatch(submitBUttonWidth(minWidth));
    };
    const onMouseUpCenterResize = () => {
      document.removeEventListener('mousemove', onMouseMoveCenterResize);
    };

    const onMouseDownCenterResize = (event: any) => {
      x = event.clientX;
      resezableEl.style.left = styles.left;
      resezableEl.style.right = '';
      document.addEventListener('mousemove', onMouseMoveCenterResize);
      document.addEventListener('mouseup', onMouseUpCenterResize);
    };

    const resizerCenter = leftRightRef.current!;
    resizerCenter.addEventListener('mousedown', onMouseDownCenterResize);
    return () => {
      resizerCenter.removeEventListener('mousedown', onMouseDownCenterResize);
    };
    // eslint-disable-next-line
  }, [fullWidth, leftWidth]);
  const diffWidth = fullWidth - leftWidth - 160;

  return (
    <div
      className={styles.formBtn}
      style={{ backgroundColor: activeInput ? '#fff' : '' }}
      onMouseEnter={() => setMouseEvent(false)}
      onMouseLeave={() => setMouseEvent(true)}
    >
      <div
        className={activeInput ? styles.formBtn_activeInput : styles.formBtn_deActiveInput}
        onClick={() => {
          setActiveInput(true);
          inputRef.current?.focus();
        }}
      >
        <div
          className={styles.formBtn_formInput}
          style={{
            backgroundColor: backgroundColor,
            borderRadius: borderRadius === 'ELLIPSE' ? 12 : 0,
            minWidth: width >= diffWidth ? diffWidth : width
          }}
          ref={leftRef}
        >
          <div
            className={styles.formBtn_resize}
            ref={leftRightRef}
            style={{
              opacity: activeInput ? 1 : 0,
              cursor: activeInput ? 'col-resize' : 'auto'
            }}
          >
            <ResizeIcon />
          </div>
          {activeInput && (
            <div className={styles.formBtn_formInput_color1}>
              <div className={styles.formBtn_formInput_color1_div} style={{ backgroundColor: color }}></div>
              <Input type="color" value={color} onChange={e => dispatch(submitColor(e.target.value))} />
            </div>
          )}
          <Input
            ref={inputRef}
            style={{
              pointerEvents: activeInput ? 'auto' : 'none',
              height: height,
              fontWeight: fontWeight,
              fontStyle: fontStyle,
              textDecoration: textDecoration,
              color: color,
              fontFamily: fontFamily,
              textAlign: 'center'
            }}
            value={value}
            onChange={e => dispatch(submitValue(e.target.value))}
          />
        </div>
        {activeInput && (
          <div className={styles.formBtn_formInput_mask}>
            <div className={styles.formBtn_formInput_mask_div} style={{ backgroundColor: backgroundColor }}></div>
            <Input type="color" value={backgroundColor} onChange={e => dispatch(submitBackgroundColor(e.target.value))} />
          </div>
        )}
      </div>
      <div
        style={{
          display: activeInput ? 'block' : 'none'
        }}
      >
        <div className={styles.fontsAndAligment}>
          <div className={styles.fontSettings}>
            <div
              className={styles.fontSettings_child}
              style={{ backgroundColor: fontWeight ? '#bae7ff' : '' }}
              onClick={() => dispatch(submitFontWeight('bold'))}
            >
              B
            </div>
            <div
              className={styles.fontSettings_child}
              style={{ backgroundColor: fontStyle ? '#bae7ff' : '' }}
              onClick={() => dispatch(submitFontStyle('italic'))}
            >
              I
            </div>
            <div
              className={styles.fontSettings_child}
              style={{ backgroundColor: textDecoration ? '#bae7ff' : '' }}
              onClick={() => dispatch(submitTextDecoration('underline'))}
            >
              U
            </div>
          </div>
          <div className={styles.aligment}>
            <span>Aligment</span>
            <div
              className={styles.aligment_child}
              onClick={() => dispatch(submitJustifyContent('left'))}
              style={{
                backgroundColor: justifyContent === 'left' ? '#bae7ff' : ''
              }}
            >
              <PositionLeftIcon />
            </div>
            <div
              className={styles.aligment_child}
              onClick={() => dispatch(submitJustifyContent('center'))}
              style={{
                backgroundColor: justifyContent === 'center' ? '#bae7ff' : ''
              }}
            >
              <PositionCenterIcon />
            </div>
            <div
              className={styles.aligment_child}
              onClick={() => dispatch(submitJustifyContent('right'))}
              style={{
                backgroundColor: justifyContent === 'right' ? '#bae7ff' : ''
              }}
            >
              <PositionRightIcon />
            </div>
          </div>
        </div>
        <div className={styles.formBtnBorder}>
          <div
            className={styles.formBtnBorder_child}
            style={{
              backgroundColor: borderRadius === 'SQUARE' ? '#bae7ff' : ''
            }}
            onClick={() => dispatch(submitBorderRadius('SQUARE'))}
          >
            <SquareBorderIcon />
          </div>
          <div
            className={styles.formBtnBorder_child}
            style={{
              backgroundColor: borderRadius === 'ELLIPSE' ? '#bae7ff' : ''
            }}
            onClick={() => dispatch(submitBorderRadius('ELLIPSE'))}
          >
            <AlignLeftIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormBtn;
