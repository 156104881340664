import React, { useState } from 'react';
import CustomeDraggable from '@components/custome-draggable/CustomeDraggable';
import { useAppSelector } from '@hooks/reduxHooks';
import ShowroomV2Layout from '@pages/showroom-v2/components/showroom-v2-layout/ShowroomV2Layout';
import { useSmartCatalogShowroom, useSmartCatalogShowroomFilter } from '@pages/showroom-v2/services/queries';
import { IShowroomFlat } from '@pages/showroom-v2/utils/models/IShowroomFlat';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ShowroomShaxmatkaFlat from './showroom-shaxmatka-flat/ShowroomShaxmatkaFlat';

import styles from './showroomShaxmatka.module.scss';

const ShowroomShaxmatka = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useSmartCatalogShowroom();
  const { data: filterData, isLoading: filterDataIsLoading } = useSmartCatalogShowroomFilter();
  const [offsetWidth, setOffsetWidth] = useState(0);

  const { drawer } = useAppSelector(state => state.showroomSlice);

  const floorTop = data && data?.floors?.length > 0 ? data?.floors[0] : undefined;

  function addFlatField(array: IShowroomFlat[]) {
    let flatCounter = 1;

    return array.map(item => {
      if (Object.prototype.hasOwnProperty.call(item, 'number')) {
        // eslint-disable-next-line no-plusplus
        return { ...item, XFloor: flatCounter++ };
      }
      return item;
    });
  }

  return (
    <ShowroomV2Layout>
      <CustomeDraggable>
        <div className={styles.container}>
          <div className={styles.top}>
            {floorTop?.buildings?.map(building => {
              const flatLength = building?.entrances?.reduce((prev, current) => (prev += current?.flats?.length), 0);

              const buildingWidth = flatLength * 30 + (flatLength - 1) * 8 + (building?.entrances?.length - 1) * 16;

              return (
                <div key={building?.name} style={{ minWidth: buildingWidth + 44 }} className={styles.top_name}>
                  <h3> {building?.name}</h3>
                  <div className={styles.top_name_entrances}>
                    {building?.entrances?.map(entrance => {
                      const entranceWidth = entrance?.flats?.length * 30 + (entrance?.flats?.length - 1) * 8 + 16;

                      const entranceName = `${entrance?.entranceName || entrance?.entrance} - ${t('home.podez')}`;

                      return (
                        <p key={entrance?.entranceName} style={{ width: entranceWidth }} title={entranceName}>
                          <span>{entrance?.flats?.length === 1 ? entrance?.entranceName || entrance?.entrance : entranceName}</span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {data?.floors?.map(floor => (
            <div className={`${styles.floor} ${drawer?.floor === floor?.floor ? styles.floor_active : ''}`} key={floor?.floor}>
              <div className={styles.floor_index}>{floor?.floor}</div>
              {floor?.buildings?.map(building => (
                <div key={building?.name} className={styles.floor_building}>
                  {building?.entrances?.map(entrance => (
                    // eslint-disable-next-line max-len
                    <div key={entrance?.entranceName} className={styles.floor_entrance}>
                      {addFlatField(entrance?.flats)?.map((flat, i) => <ShowroomShaxmatkaFlat {...flat} key={flat?.id} filterData={filterData} xFloor={flat?.XFloor!} />)}
                    </div>
                  ))}
                </div>
              ))}
              <div className={styles.floor_index} style={{ right: 0, marginLeft: 'auto' }}>
                {floor?.floor}
              </div>
            </div>
          ))}
          <Spin spinning={isLoading || filterDataIsLoading} className={styles.spin} size="large" />
        </div>
      </CustomeDraggable>
      <style>
        {`
        :root{
          --focusedFloorWidth: ${offsetWidth}px;
        }
        `}
      </style>
    </ShowroomV2Layout>
  );
};

export default ShowroomShaxmatka;
