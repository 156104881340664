import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { usePipeAll } from '@queries/queries';
import { setPlanFactPipeId } from '@reducers/CrmStatisticsSlice';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const PipeSelect: React.FC = () => {
  const { t } = useTranslation();
  const { data } = usePipeAll();
  const dispatch = useDispatch();
  const { pipeId } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  const onChange = (e: number) => {
    dispatch(setPlanFactPipeId(e));
  };

  return (
    <Select
      showSearch
      value={pipeId}
      defaultValue={0}
      onChange={onChange}
      className="customSelect"
      optionFilterProp="children"
      dropdownMatchSelectWidth={false}
      placeholder={t('crm_plan_fact.Barchasi')}
    >
      <Select.Option key="first" value={0}>
        {t('crm_plan_fact.Barchasi')}
      </Select.Option>
      {data?.map(({ id, name }) => (
        <Select.Option key={id} value={id}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PipeSelect;
