import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PlusIcon from '../../../assets/icons/PlusIcon';

import EditModal from './EditModal';

import styles from '../paymentSettings.module.scss';

interface Props {
  isEmpty: boolean;
}

const AddRemotePayment: React.FC<Props> = ({ isEmpty }) => {
  const [editModal, setEditModal] = useState(false);

  const { t } = useTranslation();

  const handleEditModal = (isVisible: boolean) => {
    setEditModal(isVisible);
  };

  return (
    <>
      <div className="flex min-h-[100px]">
        <div />
        <div onClick={() => handleEditModal(true)} style={{ width: '100%' }} className={isEmpty ? styles.add_button_white : styles.add_button}>
          <PlusIcon />
          <span>{t('paymeCash.Yangi_yaratish')}</span>
        </div>
      </div>
      <EditModal visible={editModal} onClose={handleEditModal} />
    </>
  );
};

export default AddRemotePayment;
