import React from 'react';
import styles from './filterDrawer.module.scss';
import CheveronBottom from '@icons/CheveronBottom';
import { Checkbox, Collapse, Dropdown } from 'antd';
import { usePipeAll } from '@queries/queries';
import { useQueryParam } from '@hooks/useQueryParams';
import { PipeStatuses } from '@utils/models/PipeAll';
import { useTranslation } from 'react-i18next';

interface Props {
  pipeStatusIds: string[] | string | undefined;
}

const { Panel } = Collapse;

const PipeStatusIds: React.FC<Props> = ({ pipeStatusIds }) => {
  const { appendMultiple, remove, appendMultipleArray } = useQueryParam();

  const { data: dataPipeAll } = usePipeAll();
  const { t } = useTranslation();

  // classNameHeader
  const classNameHeader = (index: number) => {
    let className = styles.container_menu_header;
    if (index === 0) {
      className += ` ${styles.container_menu_header_border_radius_top}`;
    }
    if (index === Number(dataPipeAll?.length) - 1) {
      className += ` ${styles.container_menu_header_border_radius_bottom}`;
    }

    if (index !== Number(dataPipeAll?.length) - 1) {
      className += ` ${styles.container_menu_header_border_bottom}`;
    }
    return className;
  };

  // is checked pipe status
  const isCheckedPipeStatus = (id: string) => {
    if (!pipeStatusIds) {
      return false;
    }
    if (Array.isArray(pipeStatusIds)) {
      const item = pipeStatusIds.find(i => i === id);
      return !!item;
    } else {
      return id === pipeStatusIds;
    }
  };

  // check one status
  const checkOneStatus = (id: string) => {
    if (Array.isArray(pipeStatusIds)) {
      pipeStatusIds.includes(id) ? remove('pipeStatusIds', id) : appendMultiple('pipeStatusIds', id);
    } else {
      if (pipeStatusIds === id) {
        remove('pipeStatusIds', id);
      } else {
        appendMultiple('pipeStatusIds', id);
      }
    }
  };

  // handle indeterminate
  const handleShowIndeterminate = (pipeStatuses: PipeStatuses[]) => {
    if (pipeStatuses.length === 0) return false;
    if (!Array.isArray(pipeStatusIds)) {
      return !!pipeStatuses.find(item => String(item.id) === pipeStatusIds);
    }
    let hasItemsCount = 0;
    pipeStatuses.forEach(item => {
      if (pipeStatusIds.includes(String(item.id))) {
        hasItemsCount++;
      }
    });
    return hasItemsCount !== 0 && hasItemsCount !== pipeStatuses.length;
  };

  // handle indeterminate
  const handleShowChecked = (pipeStatuses: PipeStatuses[]) => {
    if (pipeStatuses.length === 0) return false;
    if (!Array.isArray(pipeStatusIds)) {
      if (pipeStatuses.length > 1) return false;
      return pipeStatusIds === String(pipeStatuses[0].id);
    }
    let hasItemsCount = 0;
    pipeStatuses.forEach(item => {
      if (pipeStatusIds.includes(String(item.id))) {
        hasItemsCount++;
      }
    });
    return hasItemsCount === pipeStatuses.length;
  };

  // handle check all
  const handleCheckAll = (pipeStatuses: PipeStatuses[]) => {
    let ids: string[] = [];
    if (pipeStatusIds) {
      if (Array.isArray(pipeStatusIds)) {
        ids = [...pipeStatusIds];
      } else {
        ids.push(pipeStatusIds);
      }
    }
    if (handleShowChecked(pipeStatuses)) {
      pipeStatuses.forEach(item => {
        ids = ids.filter(id => id !== String(item.id));
      });
    } else {
      pipeStatuses.forEach(item => {
        if (!ids.includes(String(item.id))) {
          ids.push(String(item.id));
        }
      });
    }
    appendMultipleArray('pipeStatusIds', ids);
  };

  // handle collapse click check box
  const handleCollapseClickCheckBox = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <Dropdown
      overlayClassName={styles.container_menu}
      placement={'bottom'}
      overlay={
        <Collapse accordion={true} expandIcon={() => null} ghost>
          {dataPipeAll?.map((pipe, index) => (
            <Panel
              key={pipe.id}
              header={
                <div className={classNameHeader(index)}>
                  <Checkbox
                    onClick={handleCollapseClickCheckBox}
                    onChange={() => handleCheckAll(pipe.pipeStatuses || [])}
                    checked={handleShowChecked(pipe.pipeStatuses || [])}
                    indeterminate={handleShowIndeterminate(pipe.pipeStatuses || [])}
                  />
                  <p>{pipe.name}</p>
                </div>
              }
            >
              {pipe.pipeStatuses?.map((status, index) => (
                <div
                  style={{
                    // @ts-ignore
                    '--color': status.color
                  }}
                  className={styles.container_menu_item}
                  key={index}
                  onClick={() => checkOneStatus(String(status.id))}
                >
                  <Checkbox checked={isCheckedPipeStatus(String(status.id))} />
                  <p className={styles.container_menu_item_name}>{status.name}</p>
                </div>
              ))}
            </Panel>
          ))}
        </Collapse>
      }
      trigger={['click']}
    >
      <div className={styles.container_employee}>
        <span className={styles.container_employee_placeholder}>Voronka, {t('settingEmployee.Status')}</span>
        <CheveronBottom className={styles.container_employee_icon} />
      </div>
    </Dropdown>
  );
};

export default PipeStatusIds;
