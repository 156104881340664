export function separateIds(arr: any) {
  const houses = [];
  const buildings = [];

  for (let i = 0; i < arr.length; i++) {
    const dashesCount = (arr[i].match(/-/g) || []).length;

    if (dashesCount === 1) {
      const parts = arr[i].split('-');

      houses.push(parts[1]);
    } else if (dashesCount === 2) {
      const parts = arr[i].split('-');

      buildings.push(parts[2]);
    }
  }

  return { houses, buildings };
}
