import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setCurrentPage } from '@reducers/HouseSlice';
import { Dropdown, Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import Download from '../../assets/icons/Download';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import TableSettings from '../../components/filters/TableSettings';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useTableConfig } from '../../queries/queries';
import { TableKey } from '../../utils/models/TableKey';

import OrderFilters from './components/OrderFilters';
import TableOrders from './components/TableOrders';
import { useDownloadOrdersToExcel } from './services/mutation';
import { useBookedFlats } from './services/queries';

import stylesButton from '../../components/filters/tableSettings.module.scss';
import styles from './order.module.scss';

const { Content } = Layout;

export interface SearchParamsOrder {
  page?: string;
  size?: string;
  statuses?: string[];
  houses?: string[];
  startDate?: string;
  finishDate?: string;
  search?: string;
  order?: string[];
  buildings?: string[];
  currencies?: string[];
  responsibleByIds?: string[];
}

const Order = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { searchParams, appendMultipleDifferent, params } = useQueryParam<SearchParamsOrder, any>();
  const [tableSize, setTableSize] = useState(10);
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.order);
  const { isLoading, data, isFetching } = useBookedFlats(tableSize, searchParams);
  const download = useDownloadOrdersToExcel(tableSize, searchParams);
  const { currentPage } = useAppSelector(state => state.houseReducer);

  const downloadExcel = () => {
    download.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Excel')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  useEffect(() => {
    if (tableConfig?.size && params['*']?.includes('order')) {
      appendMultipleDifferent(['page', 'size'], [currentPage, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    if (!params['*'].includes('order')) {
      dispatch(setCurrentPage(1));
    }
  }, [params['*']]);

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.container}>
          <div className="d-flex align-center justify-space-between">
            <OrderFilters />
            <Spin spinning={download.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.order} />
            </Spin>
          </div>
          <TableOrders
            isFetching={isFetching}
            tableConfigFetch={tableConfigFetch}
            isLoading={isLoading}
            data={data?.data || []}
            tableConfig={tableConfig?.config || []}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
        </div>
      </Content>
    </>
  );
};

export default Order;
