import { BaseLanguageEntity } from './BaseLanguageEntity';

export interface TransferDataList {
  createdDate: string;
  status: BaseLanguageEntity;
  statusKey: StatusKey;
  createdBy: string;
  filePath: string;
}

export enum StatusKey {
  SUCCESS = 'SUCCESS',
  BUILDERS = 'BUILDERS',
  PAYMENTS = 'PAYMENTS',
  CONTRACTS = 'CONTRACTS',
  JURIDICAL_CLIENTS = 'JURIDICAL_CLIENTS',
  NATURAL_CLIENTS = 'NATURAL_CLIENTS',
  FLATS = 'FLATS',
  HOUSES = 'HOUSES'
}
