import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsivePie } from '@nivo/pie';
import { Spin } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { useBasicCurrency, useBossHomePayments } from '../../queries/queries';
import { colorPieChart } from '../../utils/constants/colorPieChart';
import { PaymentType } from '../../utils/models/PaymentType';
import { PieChartData } from '../../utils/models/PieChartData';

import styles from './chardContainer.module.scss';

const ChartContainer = () => {
  const [dataChart, setDataChart] = useState<PieChartData[]>([]);
  const { buildings, houses } = useAppSelector(state => state.bossCurrency);

  const convertSearchParam = () =>
    queryString.stringify({
      houses,
      buildings
    });

  const { isLoading, data } = useBossHomePayments(convertSearchParam());
  const { data: currency } = useBasicCurrency();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setDataChart([
        {
          id: PaymentType.CASH,
          label: t(`home.${PaymentType.CASH}`),
          value: data.cash,
          color: colorPieChart[0],
          type: ''
        },
        {
          id: PaymentType.CARD,
          label: t(`home.${PaymentType.CARD}`),
          value: data.card,
          color: colorPieChart[1],
          type: ''
        },
        {
          id: PaymentType.BANK,
          label: t(`home.${PaymentType.BANK}`),
          value: data.bank,
          color: colorPieChart[2],
          type: ''
        },
        {
          id: PaymentType.TRANSFER,
          label: t(`home.${PaymentType.TRANSFER}`),
          value: data.transfer,
          color: colorPieChart[3],
          type: ''
        },
        {
          id: PaymentType.BALANCE,
          label: t(`home.${PaymentType.BALANCE}`),
          value: data.balance,
          color: colorPieChart[4],
          type: ''
        },
        {
          id: PaymentType.MY_UZCARD,
          label: 'My Uzcard',
          value: data.myUzCard,
          color: colorPieChart[5],
          type: ''
        },
        {
          id: PaymentType.UZUM,
          label: 'Uzum',
          value: data.uzum,
          color: colorPieChart[6],
          type: ''
        },
        {
          id: PaymentType.PAYME,
          label: 'Payme',
          value: data.payme,
          color: colorPieChart[7],
          type: ''
        },
        {
          id: PaymentType.PAYLOV,
          label: 'Paylov',
          value: data.paylov,
          color: colorPieChart[8],
          type: ''
        },
        {
          id: PaymentType.OTHERS,
          label: 'Boshqa',
          value: data.others,
          color: colorPieChart[9],
          type: ''
        }
      ]);
    }
  }, [data, t]);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h4>{t('boss_home.Tolovlar')}</h4>
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_information}>
            <div className={styles.container_body_information_title}>
              <h4>{t('boss_home.Bugungi_holat')}</h4>
              <h3>
                {data?.all.toLocaleString('ru')} {currency?.ccy}
              </h3>
            </div>
            <div className={styles.container_body_information_legends}>
              {dataChart.map((item, index) => (
                <div key={index} className={styles.container_body_information_legends_item}>
                  <span
                    style={{
                      // @ts-ignore
                      '--color': colorPieChart[index]
                    }}
                  />
                  <p>{item.label}</p>
                </div>
              ))}
            </div>
          </div>
          {currency ? (
            <div className={styles.container_body_chart}>
              <ResponsivePie
                data={dataChart}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.65}
                cornerRadius={3}
                activeOuterRadiusOffset={16}
                colors={{ datum: 'data.color' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['opacity', 0.2]]
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabel="value"
                arcLabelsRadiusOffset={0.3}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                tooltip={e => {
                  const item = e.datum;

                  return (
                    <div className={styles.tooltip}>
                      <div className={styles.tooltip_label}>{item.label}</div>
                      <div className={styles.tooltip_value}>
                        {item.value.toLocaleString('ru')} {currency?.ccy}
                      </div>
                    </div>
                  );
                }}
                legends={[]}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Spin>
  );
};

export default ChartContainer;
