import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import CirclePlusIcon from '@icons/CirclePlusIcon';
import { cx, getLocalStorage } from '@utils/index';
import { message } from 'antd';
import dayjs from 'dayjs';
import { Input, Spin, Tooltip } from 'global';
import { MaskedRange } from 'imask/esm/index';
import { useTranslation } from 'react-i18next';

import { usePostPushNotificationConfig } from '../../service/mutation';
import { useGetPushNotificationConfigAll } from '../../service/queries';
import { caseTypes } from '../../utils/helpers/caseTypes';
import { PushNotificationType } from '../../utils/model/PushNotificationType';

interface IProps {
  time?: string | null;
  times?: PushNotificationType['times'];
  timesString?: (string | null | undefined)[];
  id?: number;
  created?: boolean;
}

const TaskDuringTheDayTIme = ({ time, times, id, created, timesString }: IProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(time);

  const { data: dataPushNotificationsConfigAll } = useGetPushNotificationConfigAll();
  const postPushNotificationConfig = usePostPushNotificationConfig();

  const handleSave = () => {
    if (value?.length === 5) {
      const isItem = (timesString || [])?.includes(value);

      const newTimes = [...(timesString || []), value];

      if (!isItem && newTimes?.length <= 5) {
        postPushNotificationConfig
          .mutateAsync({
            inSiteStatus: dataPushNotificationsConfigAll?.inSiteStatus,
            status: dataPushNotificationsConfigAll?.status,
            firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
            parts: [
              {
                times: newTimes,
                type: caseTypes?.DURING_THE_DAY,
              }
            ],
          })
          .then(() => setValue(''));
      } else {
        message.info(t('task.Bu_vaqt_oraligi_mavjud'));
      }
    }
  };

  // timesString?.length <= 5

  return created ? (
    <Spin spinning={postPushNotificationConfig.isLoading} indicator={<LoadingOutlined />}>
      <div
        className={cx(`flex cursor-pointer items-center justify-center gap-[10px] rounded-xl border-[1px] border-solid border-[#D0D5DD] px-[10px] py-[5px] font-semibold ${timesString?.length >= 5 ? 'pointer-events-none opacity-55' : ''}`)}
        onClick={handleSave}
      >
        <Input
          value={value || ''}
          onClick={e => e.stopPropagation()}
          onChange={setValue}
          onPressEnter={handleSave}
          placeholder="00:00"
          width={65}
          type="mask"
          maskOption={{
            mask: Date,
            pattern: 'HH:mm',
            format: (date: Date | dayjs.Dayjs | null) => dayjs(date).format('HH:mm'),
            parse: (str: string) => dayjs(str, 'HH:mm').toDate(),
            blocks: {
              HH: {
                mask: MaskedRange,
                from: 0,
                to: 23
              },
              mm: {
                mask: MaskedRange,
                from: 0,
                to: 59
              }
            }
          }}
        />
        <Tooltip title={t('task.Vaqt_qoshish')}>
          <span className="mb-[-7px]">
            <CirclePlusIcon />
          </span>
        </Tooltip>
      </div>
    </Spin>
  ) : (
    <Spin spinning={postPushNotificationConfig.isLoading} indicator={<LoadingOutlined />}>
      <div
        onClick={() => {
          postPushNotificationConfig.mutate({
            inSiteStatus: dataPushNotificationsConfigAll?.inSiteStatus,
            status: dataPushNotificationsConfigAll?.status,
            firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
            parts: [
              {
                times: times?.filter(timeItem => timeItem !== time) || [],
                type: caseTypes?.DURING_THE_DAY,
              }
            ],
          });
        }}
        className="flex cursor-pointer items-center justify-center gap-[10px] rounded-xl border-[1px] border-solid border-[#168d5b] bg-[#E6F6F4] px-[14px] py-[8px] font-semibold"
      >
        <h3 className="m-0 font-medium text-[13px]">{time}</h3>
      </div>
    </Spin>
  );
};

export default TaskDuringTheDayTIme;
