import React from 'react';

interface PlusProps {
  className?: string;
}

const Plus: React.FC<PlusProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className={className} viewBox="0 0 256 256">
    <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z" />
  </svg>
);

export default Plus;
