import React, { FC } from 'react';
import styles from './sipuConfig.module.scss';
import Trash01Icon from '../../../../../../assets/icons/Trash01Icon';
import { Select } from 'antd';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import { SipContact } from '../utils/models/SipContact';
import { PipeAll } from '../../../../../../utils/models/PipeAll';
import EditAddTagIcon from '../../../../../../assets/icons/addTag/EditAddTagIcon';
import SipuContact from './sipu-contact/SipuContact';

interface IProps {
  id: number | undefined;
  contactList: SipContact[] | undefined;
  pipeAll: PipeAll[] | undefined;
  contacts: number[];
  pipeId: number | null;
  setCreate: React.Dispatch<React.SetStateAction<boolean>>;
  setContacts: React.Dispatch<React.SetStateAction<number[]>>;
  setPipeId: React.Dispatch<React.SetStateAction<number | null>>;
}

const SipuCreateConfig: FC<IProps> = ({ contactList, pipeAll, id, contacts, setCreate, setContacts, setPipeId, pipeId }) => {
  return (
    <div className={styles.config}>
      <div>
        <SipuContact contactAll={contactList!} contacts={contacts} setContacts={setContacts} sipuniConfId={id} />
      </div>
      <div>
        <Select
          className="customSelect"
          size="large"
          onChange={value => {
            setPipeId(value);
          }}
          value={pipeId}
          suffixIcon={<ArrowDownIcon />}
          placeholder="Varonkani tanlang"
        >
          {pipeAll?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <span className={styles.config_edit} style={{ marginTop: 6 }}>
        <span style={{ visibility: 'hidden' }}>
          <EditAddTagIcon />
        </span>
        <span onClick={() => setCreate(false)}>
          <Trash01Icon />
        </span>
      </span>
    </div>
  );
};

export default SipuCreateConfig;
