import generatePicker, { PickerProps as BasePickerProps, RangePickerProps as BaseRangePickerProps } from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

const DatePicker = generatePicker(dayjsGenerateConfig);

export default DatePicker;

export type DatePickerProps = BasePickerProps<Dayjs>;
export type RangePickerProps = BaseRangePickerProps<Dayjs>;
