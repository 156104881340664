import React from 'react';

const NavigationIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.6663 8.33414C8.53964 8.33414 8.41297 8.28747 8.31297 8.18747C8.11964 7.99414 8.11964 7.67414 8.31297 7.48081L13.7796 2.01414C13.973 1.82081 14.293 1.82081 14.4863 2.01414C14.6796 2.20747 14.6796 2.52747 14.4863 2.72081L9.01964 8.18747C8.91964 8.28747 8.79297 8.33414 8.6663 8.33414Z"
        fill="#3772FF"
      />
      <path
        d="M14.6668 5.53398C14.3935 5.53398 14.1668 5.30732 14.1668 5.03398V2.33398H11.4668C11.1935 2.33398 10.9668 2.10732 10.9668 1.83398C10.9668 1.56065 11.1935 1.33398 11.4668 1.33398H14.6668C14.9401 1.33398 15.1668 1.56065 15.1668 1.83398V5.03398C15.1668 5.30732 14.9401 5.53398 14.6668 5.53398Z"
        fill="#3772FF"
      />
      <path
        d="M10.0007 15.6673H6.00065C2.38065 15.6673 0.833984 14.1207 0.833984 10.5007V6.50065C0.833984 2.88065 2.38065 1.33398 6.00065 1.33398H7.33398C7.60732 1.33398 7.83398 1.56065 7.83398 1.83398C7.83398 2.10732 7.60732 2.33398 7.33398 2.33398H6.00065C2.92732 2.33398 1.83398 3.42732 1.83398 6.50065V10.5007C1.83398 13.574 2.92732 14.6673 6.00065 14.6673H10.0007C13.074 14.6673 14.1673 13.574 14.1673 10.5007V9.16732C14.1673 8.89398 14.394 8.66732 14.6673 8.66732C14.9407 8.66732 15.1673 8.89398 15.1673 9.16732V10.5007C15.1673 14.1207 13.6207 15.6673 10.0007 15.6673Z"
        fill="#3772FF"
      />
    </svg>
  );
};

export default NavigationIcon;
