import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { PaymentCustomType } from "../utils/models/PaymentCustomType";

export function useGetPaymentCustomType() {
    return useQuery<PaymentCustomType[]>({
        queryKey: [queryKeys.PAYMENT_CUSTOM_TYPE],
        queryFn: async () => {
            const res = await $api.get(endPoints.PAYMENT_CUSTOM_TYPE);

            return res.data.data;
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false
    });
}
