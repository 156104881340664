import React, { useState } from 'react';

import { formatMoney } from '../../../../utils/helper/formatMoney';
import { CurrencyItem } from '../../../../utils/models/Currency';
import { PaymentSumItem, PaymentSumItemCurrency } from '../../../../utils/models/PaymentSum';
import PaymentSumCurrencyList from '../payment-sum-currency-list/PaymentSumCurrencyList';

import styles from './paymentMoney.module.scss';

interface Props {
  currencies: PaymentSumItemCurrency[] | undefined;
  total: number | undefined | null;
  currency: CurrencyItem | undefined;
  color: string | undefined;
  title: string;
  custom?: PaymentSumItem | null;
}

const PaymentCard: React.FC<Props> = ({ total, currencies, currency, color, title, custom }) => {
  const [visible, setVisible] = useState(false);

  // handle change visible
  const handleChangeVisible = (visible: boolean) => {
    setVisible(visible);
  };


  return (
    <div onClick={() => handleChangeVisible(!visible)} className={styles.container_item}>
      <span style={{ background: color }} className={styles.container_item_line} />
      <p>{title}</p>
      <h1>
        {currencies && currencies?.length > 1 ? (
          <PaymentSumCurrencyList visible={visible} close={handleChangeVisible} currencyItems={currencies}>
            <span>{formatMoney(total)}</span>
          </PaymentSumCurrencyList>
        ) : (
          <span>{formatMoney(total)}</span>
        )}{' '}
        {currency?.ccy}
      </h1>
    </div>
  );
};

export default PaymentCard;
