import React from "react";
import { useTags } from "@queries/queries";
import { Select } from "global"
import { useTranslation } from "react-i18next";


type MultiSelectsProps = {
    valueCurrent: string[] | null | undefined;
    valuePrevious: string[] | null | undefined;
    onChangeCurrent: (value: string[] | null | undefined) => void;
    onChangePrevious: (value: string[] | null | undefined) => void;
}

const MultiSelects: React.FC<MultiSelectsProps> = ({ valueCurrent, valuePrevious, onChangeCurrent, onChangePrevious }) => {
    const { t } = useTranslation();
    const { data: dataTags } = useTags();

    return (
        <>
            <Select
                value={valuePrevious}
                onChange={onChangePrevious}
                mode='tags'
                placeholder={`${t('home.Oldingi_qiymat')} (${t('triggers.Teg')})`}
                allowClear
            >
                {dataTags?.tagList?.map(item => (
                    <Select.Option key={item?.name}>
                        {item?.name}
                    </Select.Option>
                ))}
            </Select>

            <Select
                value={valueCurrent}
                onChange={onChangeCurrent}
                mode='tags'
                placeholder={`${t('home.Keyingi_qiymat')} (${t('triggers.Teg')})`}
                allowClear
            >
                {dataTags?.tagList?.map(item => (
                    <Select.Option key={item?.name}>
                        {item?.name}
                    </Select.Option>
                ))}
            </Select>
        </>
    )
}

export default MultiSelects
