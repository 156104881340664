import React, { useState } from 'react';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';

import styles from './sendMessageModal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { MyMessageTemplate } from '../../../../../../utils/models/MyCalls';
import { Permissions } from '../../../../../../utils/constants/permissions';
import { useMyMessageTemplate } from '../../../../../../queries/queries';
import { useMyMessageTemplateDelete, useMyMessageTemplateSave } from '../../../../../../queries/mutation';
import { useTranslation } from 'react-i18next';
import TrushSquareIcon from '../../../../../../assets/icons/TrushSquareIcon';
import SettingIcon from '../../../../../../assets/icons/SettingIcon';
import InfoIcon from '../../../../../../assets/icons/InfoIcon';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { SearchParamsCrm } from '../../../filter/FilterButton';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { usePremiumOriginator } from '../../../../../message/services/queries';
import { useGroupActionCrmViewList } from '../../../../services/mutation';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import { useQueryClient } from 'react-query';

const { Option } = Select;

interface IForm {
  title: string;
  text: string;
  originator: string;
}

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
  balance: number | undefined;
}

const SendMessageModal: React.FC<Props> = ({ visible, close, ids, balance }) => {
  const dispatch = useAppDispatch();
  const qc = useQueryClient();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();

  const [templatePage, setTemplatePage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<MyMessageTemplate>();

  const { isLoading: loadingOriginator, data } = usePremiumOriginator();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const [form] = Form.useForm<IForm>();

  // is access config
  const isAccessConfig = permissions?.includes(Permissions.PERMISSION_SETTINGS_CRM);

  const { isLoading: loadingMessageTemplate, data: messageTemplate } = useMyMessageTemplate();
  const pipeStatusIds = JSON.parse(localStorage.getItem('pipeIds')!);

  // handle cancel
  const handleCancel = () => {
    form.setFieldsValue({
      text: '',
      title: '',
      originator: undefined
    });
    close(false);
    setSelectedTemplate(undefined);
    setTemplatePage(false);
  };

  // handle template page
  const handleTemplatePage = (visible: boolean) => {
    setTemplatePage(visible);
  };

  // handle success save template
  function handleSuccessSaveTemplate() {
    form.setFieldsValue({
      text: '',
      title: '',
      originator: undefined
    });
    setSelectedTemplate(undefined);
    handleTemplatePage(false);
  }

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  const deleteMessageTemplate = useMyMessageTemplateDelete(handleSuccessSaveTemplate);
  const saveMessageTemplate = useMyMessageTemplateSave(handleSuccessSaveTemplate);
  const sendMessage = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);

  const { t } = useTranslation();

  // handle send message
  const handleSendMessage = (val: IForm) => {
    sendMessage
      .mutateAsync({
        text: val.text,
        requestMethodType: RequestMethodTypeGroupActions.SEND_SMS_USERS,
        leadIds: ids,
        originator: val.originator
      })
      .then(res => {
        dispatch(setBalanceAndIds({ ids: ids, balance: balance }));
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
      });
  };

  // handle open template page
  const handleOpenTemplatePage = () => {
    handleTemplatePage(true);
    if (selectedTemplate) {
      form.setFieldsValue({
        text: selectedTemplate.body,
        title: selectedTemplate.title
      });
    }
  };
  // handle save template
  const handleSaveTemplate = (val: IForm) => {
    saveMessageTemplate.mutate({
      title: val.title,
      body: val.text,
      id: selectedTemplate?.id
    });
  };

  // handle close template page
  const handleCloseTemplatePage: React.MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    setTemplatePage(false);
  };

  // on finish
  const onFinish = (val: IForm) => {
    if (templatePage) {
      handleSaveTemplate(val);
    } else {
      handleSendMessage(val);
    }
  };

  // handle delete template
  const handleDeleteTemplate = () => {
    if (selectedTemplate?.id) {
      deleteMessageTemplate.mutate(selectedTemplate.id);
    }
  };

  // handle template
  const handleTemplate = (id: number) => {
    if (id) {
      const template = messageTemplate?.find(temp => temp.id === id);
      if (template) {
        form.setFieldsValue({
          text: template.body
        });
        setSelectedTemplate(template);
      }
    } else {
      form.setFieldsValue({
        text: '',
        title: ''
      });
      setSelectedTemplate(undefined);
    }
  };

  return (
    <Modal
      className={styles.container}
      width={560}
      footer={false}
      visible={visible}
      title={t('my_calls.SMS_xabarnoma_yuborish')}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        name="sendMessageCrm"
        initialValues={{ remember: true }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <div className={styles.container_body}>
          {!loadingOriginator && !templatePage && data?.length !== 0 && (
            <>
              <label className={styles.container_body_label}>{t('premium_modal.Originator')}</label>
              <div>
                <Form.Item
                  className={styles.container_body_select}
                  name={'originator'}
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <Select allowClear loading={loadingOriginator} className={'customSelect'}>
                    {data?.map((org, index) => (
                      <Option value={org.name} key={index}>
                        {org.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          <label className={styles.container_body_label}>{templatePage ? t('my_calls.Shablon_nomi') : t('my_calls.Shablon')}</label>
          {templatePage ? (
            <div className={'d-flex align-center'}>
              <Form.Item
                name={'title'}
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <Input className={'customInput ' + styles.container_body_slect} placeholder={t('my_calls.Nom_kiritiriting')} />
              </Form.Item>
              {selectedTemplate && (
                <Spin spinning={deleteMessageTemplate.isLoading}>
                  <TrushSquareIcon onClick={handleDeleteTemplate} className={styles.container_body_delete_icon} />
                </Spin>
              )}
            </div>
          ) : (
            <div className={'d-flex align-center'}>
              <Select
                allowClear
                value={selectedTemplate?.id}
                onChange={handleTemplate}
                loading={loadingMessageTemplate}
                className={'customSelect ' + styles.container_body_select}
              >
                {messageTemplate?.map((temp, index) => (
                  <Option value={temp.id} key={index}>
                    {temp.title}
                  </Option>
                ))}
              </Select>
              {isAccessConfig && <SettingIcon onClick={handleOpenTemplatePage} className={styles.container_body_settings_icon} />}
            </div>
          )}
          <Form.Item
            name={'text'}
            rules={[
              {
                required: true,
                message: ''
              }
            ]}
          >
            <Input.TextArea
              className={'customInput ' + styles.container_body_text_area}
              placeholder={t('my_calls.Xabar_matnini_kiriting')}
              autoSize={{ minRows: 6, maxRows: 6 }}
            />
          </Form.Item>
          {!templatePage && (
            <p className={styles.container_information}>
              <InfoIcon />
              <span>{t('home.Barcha_kontakt_mavjud_bolgan_lidlarga_SMS_xabar_yuboriladi')}</span>
            </p>
          )}
          <div className={styles.container_modalFooter}>
            <Button
              htmlType={'button'}
              className={styles.container_modalFooter_margin + (templatePage ? '' : ' ' + styles.container_display_none)}
              onClick={handleCloseTemplatePage}
            >
              {t('my_calls.Ortga')}
            </Button>
            <Button
              loading={saveMessageTemplate.isLoading || deleteMessageTemplate.isLoading}
              className={templatePage ? '' : ' ' + styles.container_display_none}
              htmlType="submit"
              type="primary"
            >
              {t('home.Saqlash')}
            </Button>
            <Button
              className={templatePage ? ' ' + styles.container_display_none : ''}
              loading={sendMessage.isLoading}
              htmlType="submit"
              type="primary"
            >
              {t('my_calls.Yuborish')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default SendMessageModal;
