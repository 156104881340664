import React from 'react';
import styles from './messageSendModal.module.scss';
import MessageAllCustomer from './MessageAllCustomer';
import MessageContracted from './MessageContracted';
import MessageNotContracted from './MessageNotContracted';
import { useTranslation } from 'react-i18next';
import { TemplateMessage } from '../../../../utils/models/TemplateMessage';

interface Props {
  handleChangeVisibleTemplatePage: (visible: boolean, template: TemplateMessage | undefined) => void;
  customer: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  setCustomer: React.Dispatch<React.SetStateAction<string>>;
}

const MessageBody: React.FC<Props> = ({ handleChangeVisibleTemplatePage, setVisible, visible, customer, setCustomer }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.navbar}>
        <div onClick={() => setCustomer('allCustomer')} className={customer === 'allCustomer' ? 'activeNavbarDiv' : `${styles.navbarDiv}`}>
          <div className={customer === 'allCustomer' ? 'activeRadio' : `${styles.radio}`}></div>
          <p>{t('premium_modal.All_customer')}</p>
        </div>
        <div onClick={() => setCustomer('contracted')} className={customer === 'contracted' ? 'activeNavbarDiv' : `${styles.navbarDiv}`}>
          <div className={customer === 'contracted' ? 'activeRadio' : `${styles.radio}`}></div>
          <p>{t('premium_modal.Contracted')}</p>
        </div>
        <div onClick={() => setCustomer('notContracted')} className={customer === 'notContracted' ? 'activeNavbarDiv' : `${styles.navbarDiv}`}>
          <div className={customer === 'notContracted' ? 'activeRadio' : `${styles.radio}`}></div>
          <p>{t('premium_modal.Not_Contracted')}</p>
        </div>
      </div>
      {customer === 'allCustomer' ? (
        <MessageAllCustomer visible={visible} setVisible={setVisible} />
      ) : customer === 'contracted' ? (
        <MessageContracted changeVisible={handleChangeVisibleTemplatePage} visible={visible} setVisible={setVisible} />
      ) : (
        <MessageNotContracted visible={visible} setVisible={setVisible} />
      )}
    </>
  );
};

export default MessageBody;
