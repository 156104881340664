import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const SubtractIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg onClick={onClick} className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0ZM3 3.57537L5.42463 6L3 8.42463L3.57537 9L6 6.57537L8.42463 9L9 8.42463L6.57537 6L9 3.57537L8.42463 3L6 5.42463L3.57537 3L3 3.57537Z"
        fill="#464255"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default SubtractIcon;
