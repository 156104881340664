import { DateFormatType } from '../../../../utils/constants/DateFormatType';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../utils/constants/format';

export const getDateType = (type: DateFormatType, start: string, finish: string) => {
  const obj = {
    DAY: dayjs(start, DATE_FORMAT).format('MMM, YYYY'),
    MONTH: dayjs(start, DATE_FORMAT).format('YYYY'),
    YEAR: dayjs(start, DATE_FORMAT).format('YYYY') + '-' + dayjs(finish, DATE_FORMAT).format('YYYY')
  };

  return obj[type];
};
