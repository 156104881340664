import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Icon } from '@components/index';
import { useQueryParam } from '@hooks/useQueryParams';
import StikersIcon from '@icons/StikersIcon';
import { setIntentWidth, setTaskChangeText } from '@reducers/TaskChangetextSlice';
import { OneLid } from '@utils/models/OneLid';
import { Button, Dropdown, Input } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import Dropzone from 'react-dropzone';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import { useQueryClient } from 'react-query';

import ArrowDownCrmIcon from '../../../../../assets/icons/ArrowDownCrmIcon';
import CheckmarkIcon from '../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '../../../../../assets/icons/crmIcons/crmIcons';
import Edit2Icon from '../../../../../assets/icons/Edit2Icon';
import PaperClipIcon from '../../../../../assets/icons/PaperClipIcon';
import TrushSquareBlackStrokeIcon from '../../../../../assets/icons/TrushSquareBlackStrokeIcon';
import MDatePicker from '../../../../../components/mDatePicker/MDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { useCreateClientNote, useCreateLeadNote, useCreateLeadTask, useLeadChatMessage, useUploadFile } from '../../../../../queries/mutation';
import { useCrmEmployee, useLeadNotClosed, useLeadTaskType } from '../../../../../queries/queries';
import { MainPaths } from '../../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../../routing/rootPaths';
import i18n from '../../../../../service/I18nService';
import { LocalStorage } from '../../../../../service/LocalStorage';
import { emojies } from '../../../../../utils/constants/emojies';
import { USER_KEY } from '../../../../../utils/constants/localStorageKeys';
import { Permissions } from '../../../../../utils/constants/permissions';
import { queryNames } from '../../../../../utils/constants/queryNames';
import { dateData } from '../../../../../utils/helper/dateData';
import { timeValidatingString } from '../../../../../utils/helper/timeValidatingString';
import { FileObject } from '../../../../../utils/models/File';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../utils/models/GoogleAnalitks';
import { LeadTaskType } from '../../../../../utils/models/LeadTaskType';
import { useTaskTypeCreate, useTaskTypeDelete } from '../../../services/mutation';
import { useLeadEmployeeData, useLeadEventData } from '../../../services/queries';

import TaskChatFooterNotification from './taskChatFooterNotification/TaskChatFooterNotification';

import Facebook from '../../../../../assets/img/Facebook.png';
import instagram from '../../../../../assets/img/instagram.png';
import telegram from '../../../../../assets/img/TelegramMini.png';

import styles from './taskChatFooter.module.scss';

const message = {
  INSTAGRAM: instagram,
  FACEBOOK: Facebook,
  TELEGRAM: telegram
};

type Props = {
  type: string | undefined;
  leadData?: OneLid;
};

const TaskChatFooter: React.FC<Props> = ({ type, leadData }) => {
  const { appendSingle, searchParams } = useQueryParam<any, any>();

  const taskSelect = type
    ? [
        {
          text: 'Topshiriq'
        },
        {
          text: 'Eslatma'
        }
      ]
    : [
        {
          text: 'Topshiriq'
        },
        {
          text: 'Eslatma'
        },
        {
          text: 'Chat'
        }
      ];

  const inputRef = useRef<TextAreaRef>(null);
  const [activeMessage, setActiveMessage] = useState(true);
  const [selectionStart, setSelectionStart] = useState(0);
  const [startEndNew, setStarEndNew] = useState({
    start: '00:00',
    end: '23:59'
  });
  const dispatch = useAppDispatch();
  const fronId = LocalStorage.get(USER_KEY) ? LocalStorage.get(USER_KEY).id : 0;
  const qc = useQueryClient();
  const location = useLocation();
  const params = useParams();
  const { data: leadTaskTypeAll } = useLeadTaskType();
  const { data: leadEmployeeList } = useCrmEmployee();
  const { data: leadTaskNoteClosed } = useLeadNotClosed(Number(type ? params?.idClient : params?.id), type);
  const { data: leadEmployeeDataList } = useLeadEmployeeData(Number(params?.id));
  const { data: leadDataAll, isLoading: leadDataAllLoading, isFetching: leadDataAllFetching } = useLeadEventData(Number(params.id));
  const [files, setFiles] = useState<FileObject[]>();

  const { t } = useTranslation();

  function getFile(data: FileObject[]) {
    if (files) {
      setFiles([...files, ...data]);
    } else {
      setFiles(data);
    }
  }

  // begin chat
  function beginChatEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.BEGIN_CHAT
    });
  }

  // create lead task
  function createLeadTaskEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CREATE_TASK
    });
  }

  // create lead note
  function createLeadNoteEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CREATE_NOTE
    });
  }

  const leadSave = useCreateLeadTask(Number(params.id), createLeadTaskEvent);
  const noteSave = useCreateLeadNote(Number(params.id), createLeadNoteEvent);
  const clientNote = useCreateClientNote(Number(params?.idClient), createLeadNoteEvent);
  const leadChatMessage = useLeadChatMessage(Number(params.id), beginChatEvent);
  const uploadFile = useUploadFile(getFile);
  const taskTypeCreate = useTaskTypeCreate();
  const taskTypeDelete = useTaskTypeDelete();
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const [text, setText] = useState('');
  const [mPickerVisble, setMPickerVisble] = useState(false);
  const containerFooterTopRef = useRef<any>(null);
  const [taskVsible, setTaskVsible] = useState(false);
  const taskChangeText = useAppSelector(state => state.taskChangeTextSlice.taskChangeText);
  const intentWidth = useAppSelector(state => state.taskChangeTextSlice.intentWidth);
  const [taskMeetingSelectVisible, setTaskMeetingSelectVisible] = useState(false);
  const [taskMeetingCreateVisible, setTaskMeetingCreateVisible] = useState(false);
  const [taskMeetingSelectActiveId, setTaskMeetingSelectActiveId] = useState(leadTaskTypeAll ? leadTaskTypeAll![0]?.id : 0);
  const [conversationId, setConversationId] = useState(0);
  const [conversationContactId, setConversationContactId] = useState(0);
  const [activeEmployeeId, setActiveEmployeeId] = useState(fronId);
  const [activeChatMember, setActiveChatMember] = useState<{
    id: number | null;
    name: string;
    type: string;
  }>({
    id: null,
    name: 'Barcha',
    type: 'all'
  });
  const [employeeSelectVisible, setEmployeeSelectVisible] = useState(false);
  const [contactAndEmployeeSelectVisible, setContactAndEmployeeSelectVisible] = useState(false);

  const [editNotificationId, setEditNotificationId] = useState(0);
  const [dateValue, setDateValue] = useState('Bugun');
  const [createDedline, setCreateDedline] = useState({
    start: dayjs().hour(0).minute(0).format('DD.MM.YYYY HH:mm'),
    end: dayjs().hour(23).minute(59).format('DD.MM.YYYY HH:mm')
  });
  const [activeColor, setActiveColor] = useState('#000000');
  const [taskTypeText, setTaskTypeText] = useState('Boshqa');
  const [taskTypeIcon, setTaskTypeIcon] = useState('like');
  const [taskTypeEditActiveId, setTaskTypeEditActiveId] = useState(0);
  const [taskTypeEditActiveName, setTaskTypeEditActiveName] = useState('');
  const taskTypeInputRef = useRef<any>(null);
  const [timeValue, setTimeValue] = useState('00:00 00:00');
  const [visbleEmoje, setVisbleEmoje] = useState(false);

  useEffect(() => {
    const documentPaste = (e: ClipboardEvent) => {
      const clipDataArr: any = e.clipboardData?.items;
      const items: any[] = [...clipDataArr];

      items.forEach(item => {
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();

          // eslint-disable-next-line func-names
          reader.onload = function () {
            const file = blob;
            const formData = new FormData();

            formData.append('files', file);
            uploadFile.mutate(formData);
          }; // data url!
          reader.readAsDataURL(blob);
        }
      });
    };

    document.addEventListener('paste', documentPaste);
    return () => {
      document.removeEventListener('paste', documentPaste);
    };
  }, [uploadFile]);

  useEffect(() => {
    const windowLoad = () => {
      dispatch(
        setIntentWidth({
          intentWidth: Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth)
        })
      );
    };

    const windowClick = () => {
      setActiveMessage(true);
      setEditNotificationId(0);
      dispatch(
        setIntentWidth({
          intentWidth: Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth)
        })
      );
    };

    window.addEventListener('load', windowLoad);
    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('load', windowLoad);
      window.removeEventListener('click', windowClick);
    };
  }, [containerFooterTopRef, editNotificationId, activeMessage]);

  useEffect(() => {
    const messengerType = ['INSTAGRAM', 'TELEGRAM', 'FACEBOOK', 'MARKETPLACE'];

    if (leadData?.contacts[0]?.type && messengerType.includes(leadData?.contacts[0]?.type)) {
      dispatch(setTaskChangeText({ taskChangeText: 'Chat' }));
      setActiveChatMember({
        id: leadData?.contacts[0].id!,
        name: leadData?.contacts[0].name!,
        type: 'contact'
      });
    } else {
      setActiveChatMember({
        id: null,
        name: 'Barcha',
        type: 'all'
      });
    }
  }, [leadData]);

  useEffect(() => {
    if (leadDataAll && leadDataAll.length > 0) {
      const leadDataAllLastEvents = leadDataAll![leadDataAll!?.length - 1]?.events;

      if (leadDataAllLastEvents[leadDataAllLastEvents.length - 1]?.leadDataType === 'LEAD_CONVERSATION') {
        setConversationId(leadDataAllLastEvents[leadDataAllLastEvents.length - 1]?.res?.id);
        setConversationContactId(leadDataAllLastEvents[leadDataAllLastEvents.length - 1]?.res?.contactId || 0);
      } else {
        setConversationId(0);
      }
    }
  }, [leadDataAll, leadDataAllLoading, leadDataAllFetching]);

  useEffect(() => {
    if (noteSave.isSuccess || leadChatMessage.isSuccess || clientNote.isSuccess) {
      qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, Number(params.id)]);
      setFiles([]);
    }
    setText('');
    // eslint-disable-next-line
  }, [noteSave.isSuccess, leadSave.isSuccess, leadChatMessage.isSuccess, clientNote.isSuccess]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    leadEmployeeDataList?.contactList?.find(value => {
      if (value.id === conversationContactId) {
        setActiveChatMember({
          id: value?.id,
          name: value?.name,
          type: 'contact'
        });
      }
    });
  }, [conversationContactId, leadEmployeeDataList]);

  // translateFor
  const translateFor = (name: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            для <span>{name}</span>:
          </>
        );
      case 'en':
        return (
          <>
            for <span>{name}</span>:
          </>
        );
      default:
        return (
          <>
            <span>{name}</span> uchun:
          </>
        );
    }
  };

  const handleTaskSelect = () => {
    setTaskVsible(true);
  };

  const handleTaskSelectItem = (item: string) => {
    setTaskVsible(false);
    dispatch(setTaskChangeText({ taskChangeText: item }));
  };

  const handletaskMeetingSelect = () => {
    setTaskMeetingSelectVisible(true);
  };
  const handletaskMeetingSelectItem = async (id: number) => {
    setTaskMeetingSelectVisible(false);
    await setTaskMeetingSelectActiveId(id);
    inputRef.current?.focus();
  };

  const handleEmployeeSelect = () => {
    setEmployeeSelectVisible(true);
  };

  const handleEmployee = async (id: number) => {
    setEmployeeSelectVisible(false);
    await setActiveEmployeeId(id);
    inputRef.current?.focus();
  };

  const handleChatMemberSelect = () => {
    setContactAndEmployeeSelectVisible(true);
  };

  const handleChatMember = async (id: number | null, name: string, type: string) => {
    setActiveChatMember({
      id,
      name,
      type
    });
    setContactAndEmployeeSelectVisible(false);
    inputRef.current?.focus();
  };

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    dispatch(
      setIntentWidth({
        intentWidth: Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth)
      })
    );
  };

  const handleMPicker = () => {
    setMPickerVisble(true);
  };

  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setTaskMeetingSelectActiveId(leadTaskTypeAll![0]?.id);
    }
  }, [leadTaskTypeAll]);

  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 10000000) {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('files', file);
        uploadFile.mutate(formData);
        e.target.value = '';
      }
    }
  };

  const deleteFile = (id: number) => {
    setFiles(files?.filter(item => item.id !== id));
  };

  const handleLeadSave = () => {
    if (taskChangeText === 'Topshiriq') {
      appendSingle('task', 'true');
      if (type) {
        leadSave.mutate({
          assignedById: activeEmployeeId,
          deadlineStart: createDedline.start,
          deadlineFinish: createDedline.end,
          clientId: Number(params?.idClient),
          leadTaskTypeId: taskMeetingSelectActiveId,
          text
        });
      } else {
        leadSave.mutate({
          assignedById: activeEmployeeId,
          deadlineStart: createDedline.start,
          deadlineFinish: createDedline.end,
          leadId: Number(params?.id),
          leadTaskTypeId: taskMeetingSelectActiveId,
          text
        });
      }
    } else if (taskChangeText === 'Eslatma') {
      if (type) {
        clientNote.mutate({
          clientId: Number(params?.idClient),
          text,
          fileIds: files?.length! > 0 ? [...files?.map(item => item?.id)!] : null
        });
      } else {
        noteSave.mutate({
          fileIds: files?.length! > 0 ? [...files?.map(item => item?.id)!] : null,
          leadId: Number(params?.id),
          pin: false,
          text
        });
      }
    } else if (taskChangeText === 'Chat') {
      if (activeChatMember.type === 'all') {
        leadChatMessage.mutate({
          leadId: Number(params?.id),
          conversationId,
          contactId: null,
          fileIds: files?.length! > 0 ? [...files?.map(item => item?.id)!] : null,
          fromId: fronId,
          income: false,
          text,
          toId: null
        });
      } else if (activeChatMember.type === 'contact') {
        leadChatMessage.mutate({
          leadId: Number(params?.id),
          conversationId: conversationContactId === activeChatMember.id ? conversationId : 0,
          contactId: activeChatMember.id!,
          fileIds: files?.length! > 0 ? [...files?.map(item => item?.id)!] : null,
          fromId: fronId,
          income: false,
          text,
          toId: null
        });
      } else if (activeChatMember.type === 'employee') {
        leadChatMessage.mutate({
          leadId: Number(params?.id),
          conversationId,
          contactId: null,
          fileIds: files?.length! > 0 ? [...files?.map(item => item?.id)!] : null,
          fromId: fronId,
          income: false,
          text,
          toId: activeChatMember.id!
        });
      }
    }
  };

  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(Object.values(dateData)?.find(item => item?.dateFormat === `${date} ${start}`)?.text || date);
    setCreateDedline({
      start: `${date} ${start}`,
      end: `${date} ${end}`
    });
    setTimeValue(`${start} ${end}`);
    setStarEndNew({
      start,
      end
    });
  };

  const handleLeadReset = () => {
    setText('');
    setFiles([]);
    setActiveEmployeeId(fronId);
    setDateValue('Bugun');
    setCreateDedline({
      start: dayjs().hour(0).minute(0).format('DD.MM.YYYY HH:mm'),
      end: dayjs().hour(23).minute(59).format('DD.MM.YYYY HH:mm')
    });
    setStarEndNew({
      start: dayjs().hour(0).minute(0).format('HH:mm'),
      end: dayjs().hour(23).minute(59).format('HH:mm')
    });
  };

  const handleChatFooter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(
      setIntentWidth({
        intentWidth: Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth)
      })
    );
    setActiveMessage(false);
  };

  const handleTextarea = (e: any) => {
    setSelectionStart(e?.target?.selectionStart);
  };

  const keyDownText = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleTextarea(e);
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();
      handleLeadSave();
    } else if (e.key === 'Escape') {
      setActiveMessage(true);
      setText('');
      inputRef.current?.blur();
    }
  };

  // handleCreateTaskMeeting
  const handleCreateTaskMeeting = () => {
    setTaskMeetingCreateVisible(!taskMeetingCreateVisible);
  };

  // chageColor
  const chageColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveColor(e.target.value);
  };

  // changeTaskTypeText
  const changeTaskTypeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskTypeText(e.target.value);
  };

  // handleTaskTypeIcon
  const handleTaskTypeIcon = (icon: string) => {
    setTaskTypeIcon(icon);
  };

  // createTaskType
  const createTaskType = () => {
    if (taskTypeText) {
      taskTypeCreate.mutate({
        deleted: true,
        icon: taskTypeIcon + activeColor,
        name: taskTypeText
      });
      setTaskMeetingCreateVisible(false);
    }
  };

  // handleTaskTypeEdit
  const handleTaskTypeEdit = async (e: React.MouseEvent<HTMLElement, MouseEvent>, item: LeadTaskType) => {
    e.stopPropagation();
    await setTaskTypeEditActiveId(item.id);
    await setTaskTypeEditActiveName(item.name);
    taskTypeInputRef.current.focus();
  };

  // handleTaskTypeDelete
  const handleTaskTypeDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>, item: LeadTaskType) => {
    e.stopPropagation();
    taskTypeDelete.mutateAsync(item.id).then(() => {
      setTaskTypeEditActiveId(0);
    });
  };

  // changeTaskTypeEditText
  const changeTaskTypeEditText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskTypeEditActiveName(e.target.value);
  };

  // handleTaskTypeEditSubmit
  const handleTaskTypeEditSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>, item: LeadTaskType) => {
    e.stopPropagation();
    taskTypeCreate
      .mutateAsync({
        ...item,
        name: taskTypeEditActiveName
      })
      .then(() => {
        setTaskTypeEditActiveId(0);
      });
  };

  const changeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeValue(e.target.value.length < 10 ? '00:00 00:00' : timeValidatingString(e.target.value));
    setCreateDedline({
      start: `${dateData()[dateValue as keyof ReturnType<typeof dateData>]?.date.format('DD.MM.YYYY') || dateValue} ${
        e.target.value.length < 10 ? '00:00' : timeValidatingString(e.target.value).split(' ')[0]
      }`,
      end: `${dateData()[dateValue as keyof ReturnType<typeof dateData>]?.date.format('DD.MM.YYYY') || dateValue} ${
        e.target.value.length < 10 ? '00:00' : timeValidatingString(e.target.value).split(' ')[1]
      }`
    });

    setStarEndNew({
      start: e.target.value.length < 10 ? '00:00' : timeValidatingString(e.target.value).split(' ')[0],
      end: e.target.value.length < 10 ? '00:00' : timeValidatingString(e.target.value).split(' ')[1]
    });
  };

  const isAccessSettings = permissions?.includes(Permissions.PERMISSION_SETTINGS_CRM);

  const handleEmoje = (item: string) => {
    const textStart = text?.slice(0, selectionStart);
    const textEnd = text?.slice(selectionStart);

    setText(textStart + item + textEnd);
    setVisbleEmoje(false);
  };

  if (location.pathname === `${rootPaths.MAIN + MainPaths.CRM}/new/NewLid`) return <div />;

  const handleDrop = (e: any[]) => {
    const file = e[0];
    const formData = new FormData();

    formData.append('files', file);
    uploadFile.mutate(formData);
  };

  return (
    <Dropzone multiple={false} onDrop={handleDrop}>
      {({ getRootProps, isDragAccept, isDragReject }) => {
        // eslint-disable-next-line no-nested-ternary
        const additionalClass = isDragAccept ? 'accept' : isDragReject ? 'reject' : '';

        return (
          <div
            {...getRootProps({
              className: `${styles.container} ${additionalClass}`
            })}
            onClick={e => handleChatFooter(e)}
            id="big_container"
          >
            {leadTaskNoteClosed?.length! > 0 && (
              <div className={styles.container_notification}>
                <div
                  className={styles.container_notification_container}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {leadTaskNoteClosed?.map(item => (
                    <TaskChatFooterNotification {...item} key={item.id} id={item.id} editNotificationId={editNotificationId} setEditNotificationId={setEditNotificationId} />
                  ))}
                </div>
              </div>
            )}
            <div
              style={{
                background: taskChangeText === 'Eslatma' ? 'rgba(254, 237, 175, 1)' : ''
              }}
              className={styles.container_footer}
            >
              <div className={styles.container_content}>
                <div className={styles.container_footer_top} ref={containerFooterTopRef} id="container_footer_top">
                  <Dropdown
                    visible={taskVsible}
                    onVisibleChange={visible => setTaskVsible(visible)}
                    overlay={
                      <div className={styles.container_footer_top_taskSelect_content}>
                        {taskSelect.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index} onClick={() => handleTaskSelectItem(item.text)}>
                            {t(`one_lid.${item.text}`)}
                            {taskChangeText === item.text && (
                              <span>
                                <CheckmarkIcon />
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    }
                    trigger={['click']}
                    placement="topLeft"
                  >
                    <div
                      className={styles.container_footer_top_taskSelect}
                      onClick={handleTaskSelect}
                      style={{
                        background: taskChangeText === 'Eslatma' ? 'rgba(254, 237, 175, 1)' : '#fff'
                      }}
                    >
                      {t(`one_lid.${taskChangeText}`)}
                      <span>
                        <ArrowDownCrmIcon strokeColor="#3772FF" />
                      </span>
                    </div>
                  </Dropdown>
                  {taskChangeText === 'Topshiriq' && (
                    <>
                      <Dropdown
                        visible={mPickerVisble}
                        onVisibleChange={setMPickerVisble}
                        overlay={
                          <div>
                            <MDatePicker visibleTime={mPickerVisble} onChangeMPicker={onChangeMPicker} startEndNew={startEndNew} />
                          </div>
                        }
                        trigger={['click']}
                        placement="topCenter"
                      >
                        <Input
                          className={styles.container_footer_top_taskInput}
                          onClick={handleMPicker}
                          value={dateData()[dateValue as keyof ReturnType<typeof dateData>]?.text ? t(`one_lid.${dateValue}`).toString() : dateValue}
                          style={{
                            width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                          }}
                        />
                      </Dropdown>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {timeValue === '00:00 00:00' ? (
                        ''
                      ) : (
                        <div className="relative grid grid-cols-[80%_20%] items-center">
                          {timeValue === '00:00 23:59' ? (
                            <input
                              value="Kun davomida"
                              className={`ant-input ${styles.container_footer_top_taskInput}`}
                              onClick={handleMPicker}
                              style={{
                                width: 120
                              }}
                            />
                          ) : (
                            <ReactInputMask
                              mask="99:99 99:99"
                              // @ts-ignore*
                              maskChar=""
                              className={`ant-input ${styles.container_footer_top_taskInput}`}
                              onChange={e => changeDate(e)}
                              value={timeValue}
                              style={{
                                width: 110
                              }}
                            />
                          )}
                          <div
                            className="flex h-full cursor-pointer items-center justify-end rounded-r-[6px] border-[1px] border-solid border-[#e5e7eb] pr-[3px] ps-[1px]"
                            onClick={() => {
                              setDateValue('Bugun');
                              setStarEndNew({
                                start: '00:00',
                                end: '23:59'
                              });
                              setTimeValue('00:00 00:00');
                              setCreateDedline({
                                start: dayjs().hour(0).minute(0).format('DD.MM.YYYY HH:mm'),
                                end: dayjs().hour(23).minute(59).format('DD.MM.YYYY HH:mm')
                              });
                            }}
                          >
                            <Icon name="x" pathProps={{ stroke: '#757575' }} className=" h-[10px] w-[10px] " />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {taskChangeText === 'Chat' && (
                    <Dropdown
                      visible={contactAndEmployeeSelectVisible}
                      onVisibleChange={visible => setContactAndEmployeeSelectVisible(visible)}
                      overlay={
                        <div className={styles.container_footer_top_taskEmployee_content}>
                          {leadEmployeeDataList?.contactList && leadEmployeeDataList.contactList.length > 0 && <h1>{t('one_lid.Kontaktlar')}</h1>}
                          {leadEmployeeDataList?.contactList.map(item => (
                            <div key={item.id} onClick={() => handleChatMember(item.id, item.name, 'contact')}>
                              <img src={message[item.type as keyof typeof message]} alt="" /> {item.name}
                              {activeChatMember.id === item.id && (
                                <span>
                                  <CheckmarkIcon />
                                </span>
                              )}
                            </div>
                          ))}
                          <div className={styles.container_footer_top_taskEmployee_content_saleSection}>
                            {t('one_lid.Xodimlar')}
                            <button onClick={() => handleChatMember(null, 'Barcha', 'all')}>{t('one_lid.Barchaga')}</button>
                          </div>
                          {leadEmployeeDataList?.employeeList?.map(item => (
                            <div key={item.id} onClick={() => handleChatMember(item.id, item.fullName, 'employee')}>
                              {item.fullName}{' '}
                              {activeChatMember.id === item.id && (
                                <span>
                                  <CheckmarkIcon />
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      }
                      trigger={['click']}
                      placement="topLeft"
                    >
                      <div className={styles.container_footer_top_taskEmployee} onClick={handleChatMemberSelect}>
                        {translateFor(activeChatMember.name)}
                      </div>
                    </Dropdown>
                  )}
                  {taskChangeText === 'Topshiriq' && (
                    <Dropdown
                      visible={employeeSelectVisible}
                      onVisibleChange={visible => setEmployeeSelectVisible(visible)}
                      overlay={
                        <div className={styles.container_footer_top_taskEmployee_content}>
                          {leadEmployeeList!?.map(item => (
                            <div key={item.id} onClick={() => handleEmployee(item.id)}>
                              {item.fullName}{' '}
                              {activeEmployeeId === item.id && (
                                <span>
                                  <CheckmarkIcon />
                                </span>
                              )}
                            </div>
                          ))}
                        </div>
                      }
                      trigger={['click']}
                      placement="topLeft"
                    >
                      <div className={styles.container_footer_top_taskEmployee} onClick={handleEmployeeSelect}>
                        {translateFor(leadEmployeeList!?.find(item => item.id === activeEmployeeId)?.fullName || '')}
                      </div>
                    </Dropdown>
                  )}
                  {taskChangeText === 'Topshiriq' && (
                    <>
                      <Dropdown
                        trigger={['click']}
                        visible={taskMeetingSelectVisible}
                        onVisibleChange={visible => {
                          setTaskMeetingSelectVisible(visible);
                          setTaskMeetingCreateVisible(false);
                          setTaskTypeEditActiveId(0);
                        }}
                        overlay={
                          <div className={styles.container_footer_top_taskMeetingSelect_content}>
                            {leadTaskTypeAll?.map(item => (
                              <div
                                key={item.id}
                                onClick={() => handletaskMeetingSelectItem(item.id)}
                                // eslint-disable-next-line no-nested-ternary
                                className={item.deleted ? (isAccessSettings ? styles.container_footer_top_taskMeetingSelect_content_deleted : '') : ''}
                              >
                                {crmIcons[item.icon.split('#')[0]]?.icon(`#${item.icon.split('#')[1]}`)} {!item.deleted && t(`one_lid.${item.name}`)}
                                {item.deleted && (
                                  <>
                                    {taskTypeEditActiveId === item.id ? (
                                      <>
                                        <input
                                          className={styles.container_footer_top_taskMeetingSelect_content_edit_input}
                                          value={taskTypeEditActiveName}
                                          ref={taskTypeInputRef}
                                          onClick={e => e.stopPropagation()}
                                          onChange={e => changeTaskTypeEditText(e)}
                                          onKeyDown={(e: any) => {
                                            if (e.key === 'Enter') {
                                              handleTaskTypeEditSubmit(e, item);
                                            }
                                          }}
                                        />
                                      </>
                                    ) : (
                                      item.name
                                    )}
                                    <div className={styles.container_footer_top_taskMeetingSelect_content_edit}>
                                      {taskTypeEditActiveId === item.id ? (
                                        <>
                                          {taskTypeCreate.isLoading ? (
                                            <LoadingOutlined size={14} />
                                          ) : (
                                            <b onClick={e => handleTaskTypeEditSubmit(e, item)}>
                                              <CheckmarkIcon fill="#374151" />
                                            </b>
                                          )}
                                        </>
                                      ) : (
                                        <b onClick={e => handleTaskTypeEdit(e, item)}>
                                          <Edit2Icon />
                                        </b>
                                      )}
                                      {taskTypeDelete.isLoading ? (
                                        <LoadingOutlined size={14} />
                                      ) : (
                                        <b onClick={e => handleTaskTypeDelete(e, item)}>
                                          <TrushSquareBlackStrokeIcon />
                                        </b>
                                      )}
                                    </div>
                                  </>
                                )}
                                {taskMeetingSelectActiveId === item.id && (
                                  <span>
                                    <CheckmarkIcon />
                                  </span>
                                )}
                              </div>
                            ))}
                            {isAccessSettings && (
                              <div onClick={handleCreateTaskMeeting}>
                                {t('one_lid.Boshqa_yaratish')}
                                <div
                                  className={`${styles.container_footer_top_createContainer} ${taskMeetingCreateVisible ? styles.container_footer_top_createContainer_active : ''}`}
                                  onClick={e => e.stopPropagation()}
                                >
                                  <div className={styles.container_footer_top_createContainer_content}>
                                    {Object.keys(crmIcons).map((item, index) => (
                                      <span
                                        style={{
                                          ...(taskTypeIcon === item && {
                                            background: '#eeeeee'
                                          })
                                        }}
                                        onClick={() => handleTaskTypeIcon(item)}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                      >
                                        {crmIcons[item].icon(activeColor)}
                                      </span>
                                    ))}
                                  </div>
                                  <div className={styles.container_footer_top_createContainer_footer}>
                                    <Input size="small" className="customInput" value={taskTypeText} onChange={e => changeTaskTypeText(e)} />
                                    <input type="color" onChange={e => chageColor(e)} value={activeColor} />
                                    <Button type="primary" size="small" onClick={createTaskType}>
                                      {t('one_lid.OK')}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        placement="topLeft"
                      >
                        <div className={styles.container_footer_top_taskMeetingSelect} onClick={handletaskMeetingSelect}>
                          {crmIcons[leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[0]!]?.icon(
                            `#${leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[1]}`
                          )}
                          {leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.deleted
                            ? leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name
                            : t(`one_lid.${leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name}`)}
                        </div>
                      </Dropdown>
                    </>
                  )}
                </div>
                <Input.TextArea
                  id="textArea"
                  className={styles.container_footer_textArea}
                  placeholder={t('one_lid.Matn_kiriting')}
                  autoSize={{ minRows: 3 }}
                  onChange={e => changeText(e)}
                  onKeyUp={e => keyDownText(e)}
                  onFocus={e => handleFocus(e)}
                  onClick={handleTextarea}
                  ref={inputRef}
                  value={text}
                  style={{
                    textIndent: intentWidth,
                    background: taskChangeText === 'Eslatma' ? 'rgba(254, 237, 175, 1)' : '#fff'
                  }}
                />
              </div>
              {(taskChangeText === 'Eslatma' || taskChangeText === 'Chat') && (
                <div className={styles.container_files}>
                  {files?.map(item => (
                    <span key={item.id}>
                      <PaperClipIcon /> {item.originalName}{' '}
                      <span onClick={() => deleteFile(item.id)}>
                        <TrushSquareBlackStrokeIcon />
                      </span>
                    </span>
                  ))}
                </div>
              )}
              <div className={`${styles.container_submit} ${activeMessage && styles.container_submit_active}`}>
                <div className={styles.container_submit_right}>
                  {taskChangeText !== 'Topshiriq' && (
                    <>
                      <label htmlFor="chatFile" className={styles.container_submit_right_file}>
                        {uploadFile.isLoading && <LoadingOutlined />}
                        <PaperClipIcon />
                      </label>

                      <input type="file" id="chatFile" style={{ display: 'none' }} onChange={e => changeFile(e)} />
                    </>
                  )}
                  <Dropdown
                    overlay={
                      <div className="emojies">
                        <div className="emojies_content">
                          {emojies?.map(item => (
                            <span onClick={() => handleEmoje(item)} key={item}>
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>
                    }
                    visible={visbleEmoje}
                    onVisibleChange={setVisbleEmoje}
                    placement="topCenter"
                    trigger={['click']}
                  >
                    <StikersIcon className={styles.container_submit_right_item} />
                  </Dropdown>
                </div>
                <div className={styles.container_submit_left}>
                  <Button
                    type="primary"
                    onClick={handleLeadSave}
                    loading={leadSave.isLoading || noteSave.isLoading || clientNote.isLoading || leadChatMessage.isLoading}
                    disabled={!(taskChangeText === 'Topshiriq' || text || ((taskChangeText === 'Chat' || taskChangeText === 'Eslatma') && files?.length! > 0))}
                  >
                    {t('one_lid.Yuborish')}
                  </Button>
                  <Button
                    disabled={!(taskChangeText === 'Topshiriq' || text || ((taskChangeText === 'Chat' || taskChangeText === 'Eslatma') && files?.length! > 0))}
                    onClick={handleLeadReset}
                  >
                    {t('one_lid.Bekor_qilish')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default TaskChatFooter;
