import React, { FC } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setShaxmatka } from '@reducers/ShaxmatkaSlice';

interface IProps {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

const EntranceSortNumberModal: FC<IProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const shaxmatka = useAppSelector(state => state.shaxmatkaReducer.data);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    let number = Number(values.count) - 1;
    let shaxmatkaCopy = [...shaxmatka];
    shaxmatkaCopy = shaxmatkaCopy.map((podezd: any[]) => {
      return [...podezd]?.reverse();
    });
    shaxmatkaCopy = shaxmatkaCopy.map((podezd: any) => {
      return podezd.map((floor: any) => {
        return floor.map((room: any) => {
          if (!room.new) {
            number++;
            return {
              ...room,
              number: String(number)
            };
          }
          return room;
        });
      });
    });

    dispatch(setShaxmatka(shaxmatkaCopy));
    handleClose();
  };

  return (
    <Modal visible={visible} onCancel={handleClose} footer={false} title="Bino bo'yicha raqamlash" wrapClassName="entranceDoorModal" width={407}>
      <Form onFinish={onFinish}>
        <Form.Item label="Nechadan boshlansin" name={'count'} rules={[{ required: true, message: '' }]}>
          <Input type="number" />
        </Form.Item>
        <div className="entranceDoorModalFooter">
          <Button onClick={handleClose}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button htmlType="submit" type="primary">
            {t('shaxmatka.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EntranceSortNumberModal;
