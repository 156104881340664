/* eslint-disable max-len */
/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { crmIcons } from "@icons/crmIcons/crmIcons";
import { useLeadTaskType } from "@queries/queries";
import { Modal } from "antd";
import { Switch, Tooltip } from "global";
import { useTranslation } from 'react-i18next';

import { useGetChangeStatus } from "./service/mutation";
import { useGetSMSConfigAll } from "./service/queries";
import AutoSMSTrigger from "./AutoSMSTrigger";

import styles from '../../integration.module.scss';

type AutoSmsModalProps = {
    smsTrigger: boolean;
    setSmsTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    handleSettingsClick: () => void;
    handleNotificationsClick: () => void;
    contentMode: string;
};
const AutoSMSTriggerTypes: React.FC<AutoSmsModalProps> = ({ smsTrigger, setSmsTrigger, contentMode, handleSettingsClick, handleNotificationsClick }) => {
    const [smsAutoTrigger, setAutoSmsTrigger] = useState(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);


    const { data: dataLead } = useLeadTaskType();
    const { data: dataSMSBot, refetch: refetchSMSConfig } = useGetSMSConfigAll();
    const changeStatus = useGetChangeStatus();

    const { t } = useTranslation();


    const handleClickId = (id?: number) => {
        setSelectedId(id || null);
        setAutoSmsTrigger(true);
    };


    const handleToggleStatus = (id: number | null) => {
        setAutoSmsTrigger(false);
        setIsLoading(true);
        changeStatus.mutateAsync({ active: !dataSMSBot?.find((bot: { leadTaskTypeId: number | null; }) => bot.leadTaskTypeId === id)?.active, id })
            .then(() => {
                setIsLoading(false);
                refetchSMSConfig();
            });
    };

    return (
        <>
            <Modal
                visible={smsTrigger}
                onCancel={() => setSmsTrigger(false)}
                title={<h3 style={{ color: '#464255', fontSize: '20px', fontWeight: 700 }}>{t('integration.Avtomatik_sms_bildirishnomalar')}</h3>}
                className={styles.modal}
                footer={false}
            >
                <div className="flex flex-wrap mt-[-20px]">
                    {dataLead?.map((item) => {
                        const icon = item?.icon?.split('#');
                        const smsBotItem = dataSMSBot?.find((bot: { leadTaskTypeId: number | null; }) => bot.leadTaskTypeId === item.id);

                        return (
                            <div
                                onClick={() => handleClickId(item?.id)}
                                className="cursor-pointer flex items-center justify-between m-2 px-[12px] pt-[12px] pb-[10px] border-[#EAECF0] bg-[#FCFCFD] border-solid border-[1px] rounded-2xl w-[301px]"
                            >
                                <div className="flex items-start gap-3">
                                    <span className="mt-[3px]">{crmIcons[icon[0]].icon(`#${icon[1]}`)}</span>
                                    <p className="font-medium text-[#344054]">{item?.name}</p>
                                </div>

                                {!smsBotItem?.id ? (
                                    <Tooltip
                                        overlayInnerStyle={{ width: 280 }}
                                        overlay={
                                            <div>
                                                {t('home.SMS_Bot_konfiguratsiyasi_mavjud_emas')}.
                                            </div>
                                        }>
                                        <span onClick={(e) => e.stopPropagation()}>
                                            <Switch
                                                checked={smsBotItem?.active}
                                                disabled={!smsBotItem?.id}
                                                onChange={() => handleToggleStatus(item?.id)}
                                                loading={item?.id === changeStatus?.variables?.id && changeStatus?.isLoading}
                                            />
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span onClick={(e) => e.stopPropagation()}>
                                        <Switch
                                            checked={smsBotItem?.active}
                                            disabled={!smsBotItem?.id}
                                            onChange={() => handleToggleStatus(item?.id)}
                                            loading={item?.id === changeStatus?.variables?.id && changeStatus?.isLoading}
                                        />
                                    </span>
                                )}
                            </div>
                        )
                    })}
                </div>
            </Modal>

            <AutoSMSTrigger
                smsAutoTrigger={smsAutoTrigger}
                setAutoSmsTrigger={setAutoSmsTrigger}
                contentMode={contentMode}
                handleSettingsClick={handleSettingsClick}
                handleNotificationsClick={handleNotificationsClick}
                selectedId={selectedId}
            />
        </>
    )
}

export default AutoSMSTriggerTypes;
