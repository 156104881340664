export const queryKeys = {
  CRM_LEAD_FILTER: 'CRM_LEAD_FILTER',
  TRIGGERS_LIST: 'TRIGGERS_LIST',
  REASONS_FOR_REFUSAL: 'REASONS_FOR_REFUSAL',
  PIPE_ALL: 'PIPE_ALL',
  CRM_LEAD_LIST: 'CRM_LEAD_LIST',
  CRM_LEAD_LIST_IDS: 'CRM_LEAD_LIST_IDS',
  SEARCH_LEAD_EXTRA: 'SEARCH_LEAD_EXTRA',
  LEAD_DUPLICATE: 'LEAD_DUPLICATE',
  CRM_ONE_LID: 'CRM_ONE_LID',
  ONE_CLIENT: 'ONE_CLIENT',
  CLIENT_VIEW: 'CLIENT_VIEW',
  CRM_CURRENT_USER_ROLE: 'CRM_CURRENT_USER_ROLE',
  PIPE_STATUS_WITH_PIPE: 'pipe-status-with-pipe'
};
