import React, { FC } from 'react';

interface IProps {
  size?: number;
}

const FilterIcon: FC<IProps> = ({ size = 26 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4982 13.0003C18.4982 12.7278 18.39 12.4664 18.1972 12.2737C18.0045 12.0809 17.7431 11.9727 17.4705 11.9727H7.1937C6.92114 11.9727 6.65975 12.0809 6.46702 12.2737C6.27429 12.4664 6.16602 12.7278 6.16602 13.0003C6.16602 13.2729 6.27429 13.5343 6.46702 13.727C6.65975 13.9198 6.92114 14.028 7.1937 14.028H17.4705C17.7431 14.028 18.0045 13.9198 18.1972 13.727C18.39 13.5343 18.4982 13.2729 18.4982 13.0003Z"
        fill="#464255"
      />
      <path
        d="M21.5823 6.83432C21.5823 6.56177 21.474 6.30037 21.2813 6.10764C21.0886 5.91491 20.8272 5.80664 20.5546 5.80664H4.11167C3.83911 5.80664 3.57771 5.91491 3.38499 6.10764C3.19226 6.30037 3.08398 6.56177 3.08398 6.83432C3.08398 7.10688 3.19226 7.36828 3.38499 7.56101C3.57771 7.75373 3.83911 7.86201 4.11167 7.86201H20.5546C20.8272 7.86201 21.0886 7.75373 21.2813 7.56101C21.474 7.36828 21.5823 7.10688 21.5823 6.83432Z"
        fill="#464255"
      />
      <path
        d="M9.24839 18.1387C8.97583 18.1387 8.71443 18.2469 8.5217 18.4397C8.32898 18.6324 8.2207 18.8938 8.2207 19.1664C8.2207 19.4389 8.32898 19.7003 8.5217 19.893C8.71443 20.0858 8.97583 20.194 9.24839 20.194H15.4145C15.687 20.194 15.9484 20.0858 16.1412 19.893C16.3339 19.7003 16.4422 19.4389 16.4422 19.1664C16.4422 18.8938 16.3339 18.6324 16.1412 18.4397C15.9484 18.2469 15.687 18.1387 15.4145 18.1387H9.24839Z"
        fill="#464255"
      />
    </svg>
  );
};

export default FilterIcon;
