import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import AddSquareBackground from '../../assets/icons/AddSquareBackground';
import TableSettingsMine from '../../components/filters/TableSettingMine';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';
import BranchFilter from '../../components/settingBranch/BranchFilter';
import BranchModal from '../../components/settingBranch/BranchModal';
import TableBranch from '../../components/settingBranch/TableBranch';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useBranchList, useTableConfig } from '../../queries/queries';
import { TableKey } from '../../utils/models/TableKey';
import { SearchParamsBuilder } from '../builderSettings/BuilderSettings';

import styles from './SettingBranch.module.css';

const { Content } = Layout;

export interface SearchParamsBranch {
  page?: string;
  size?: string;
  search?: string;
}

const SettingBranch: React.FC = () => {
  const [modalVisible, setModalVisible] = useState<{
    visible: boolean;
    id: number | null;
  }>({ visible: false, id: null });
  const [tableSize, setTableSize] = useState(10);
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsBuilder, any>();
  const { data, isLoading, isFetching } = useBranchList(searchParams);
  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.branch);

  useEffect(() => {
    if (tableConfig?.size) {
      appendMultipleDifferent(['page', 'size'], [1, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  // changing page
  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };

  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.settingBranch_page}>
          <div className="d-flex align-center justify-space-between">
            <BranchFilter />
            <TableSettingsMine size="small" tableKey={TableKey.branch} />
            <div
              className={styles.settingBranchAdd}
              onClick={() => {
                setModalVisible({ visible: true, id: null });
              }}
            >
              <AddSquareBackground />
            </div>
          </div>
          <TableBranch
            data={data?.data || []}
            isLoading={isLoading}
            tableConfig={tableConfig?.config || []}
            tableConfigFetch={tableConfigFetch}
            isFetching={isFetching}
            setModalVisible={setModalVisible}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
            allIds={[]}
          />
          <BranchModal id={modalVisible.id} visible={modalVisible.visible} setVisible={setModalVisible} />
        </div>
      </Content>
    </>
  );
};

export default SettingBranch;
