import React from 'react';

const SMSNotification = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 11V16C22.5 19.5 20.5 21 17.5 21H7.5C4.5 21 2.5 19.5 2.5 16V9C2.5 5.5 4.5 4 7.5 4H14.5"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.5L10.63 12C11.66 12.82 13.35 12.82 14.38 12L15.56 11.06"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 8.5C21.3807 8.5 22.5 7.38071 22.5 6C22.5 4.61929 21.3807 3.5 20 3.5C18.6193 3.5 17.5 4.61929 17.5 6C17.5 7.38071 18.6193 8.5 20 8.5Z"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SMSNotification;
