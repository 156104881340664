/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-key */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import CloseIcon from '@icons/CloseIcon';
import NavigationIcon from '@icons/NavigationIcon';
import { useGetClientView } from '@pages/oneLid/services/queries';
import { setOpenConfirmModal } from '@reducers/CrmOneLidSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL } from '@service/RequestService';
import { encodeString } from '@utils/helper';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../confirm-modal/ConfirmModal';

import styles from './leadCard.module.scss';

const LeadCard = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { params } = useQueryParam();
  const { data } = useGetClientView(Number(params?.idClient));

  const handleOpenConfirm = () => {
    dispatch(setOpenConfirmModal(true));
  };

  const goToOneLead = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.CRM}/${id}/${encodeString(name)}`);
  };

  return (
    <>
      {data?.lead && (
        <div className={styles.leadInfo}>
          <div className={styles.leadInfo_top}>
            <div className={styles.topLeft}>
              <div className={styles.tags}>{data?.lead?.tags?.length! > 0 ? data?.lead?.tags?.map(item => <p>{item?.name}</p>) : '-'}</div>
              <div>
                <CloseIcon onClick={() => handleOpenConfirm()} />
              </div>
            </div>
            <div className={styles.topRight}>
              <div>{data?.lead?.pipeStatusResponse?.pipeName}</div>
              <div
                style={{
                  backgroundColor: data?.lead?.pipeStatusResponse?.color,
                  borderBottomColor: data?.lead?.pipeStatusResponse?.color,
                  borderRightColor: data?.lead?.pipeStatusResponse?.color,
                  borderTopColor: data?.lead?.pipeStatusResponse?.color
                }}
              >
                {data?.lead?.pipeStatusResponse?.name}
              </div>
            </div>
          </div>
          <div className={styles.leadInfo_line} />
          <div className={styles.leadInfo_bottom}>
            <span>
              {data?.lead?.name}({t('home.Lid_nomi')})
            </span>
            <div className={styles.leadUser}>
              <div className={styles.contacts}>
                {data?.lead?.contacts?.map(item => (
                  <div className={styles.leadUser_contact}>
                    {item?.avatar ? (
                      <img
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: '50%'
                        }}
                        src={`${BASE_URL}/${item?.avatar}`}
                      />
                    ) : (
                      <div className={styles.userAvatar}>{item?.name?.substring(0, 1)}</div>
                    )}
                    <p>{item?.name}</p>
                  </div>
                ))}
              </div>
              <div className={styles.leadUser_navigate} onClick={() => goToOneLead(Number(data?.lead?.id), String(data?.lead?.name))}>
                <span>{t('one_lid.Leadga_otish')}</span>
                <NavigationIcon />
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmModal clientName={String(data?.lead?.name)} leadName={params?.number} leadId={data?.lead?.id} />
    </>
  );
};

export default LeadCard;
