import React from 'react';

interface Props {
  className?: string;
}

const TickCircleIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00004 14.6663C11.6667 14.6663 14.6667 11.6663 14.6667 7.99967C14.6667 4.33301 11.6667 1.33301 8.00004 1.33301C4.33337 1.33301 1.33337 4.33301 1.33337 7.99967C1.33337 11.6663 4.33337 14.6663 8.00004 14.6663Z"
        stroke="#00A389"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.16663 7.99995L7.05329 9.88661L10.8333 6.11328" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TickCircleIcon;
