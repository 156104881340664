import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const LikeIcon: FC<IProps> = ({ color = '#000000', onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.39001 18.4902V8.33022C8.39001 7.93022 8.51001 7.54022 8.73001 7.21022L11.46 3.15022C11.89 2.50022 12.96 2.04022 13.87 2.38022C14.85 2.71022 15.5 3.81022 15.29 4.79022L14.77 8.06022C14.73 8.36022 14.81 8.63022 14.98 8.84022C15.15 9.03022 15.4 9.15022 15.67 9.15022H19.78C20.57 9.15022 21.25 9.47022 21.65 10.0302C22.03 10.5702 22.1 11.2702 21.85 11.9802L19.39 19.4702C19.08 20.7102 17.73 21.7202 16.39 21.7202H12.49C11.82 21.7202 10.88 21.4902 10.45 21.0602L9.17001 20.0702C8.68001 19.7002 8.39001 19.1102 8.39001 18.4902Z"
        fill={color}
      />
      <path
        d="M5.21 6.37988H4.18C2.63 6.37988 2 6.97988 2 8.45988V18.5199C2 19.9999 2.63 20.5999 4.18 20.5999H5.21C6.76 20.5999 7.39 19.9999 7.39 18.5199V8.45988C7.39 6.97988 6.76 6.37988 5.21 6.37988Z"
        fill={color}
      />
    </svg>
  );
};

export default LikeIcon;
