import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const FolderOpenIcon: React.FC<Props> = ({ onClick, className }) => (
  <svg className={className} onClick={onClick} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.29 22.75H5.70995C2.30995 22.75 2.12995 20.88 1.97995 19.37L1.57995 14.36C1.48995 13.39 1.76995 12.42 2.38995 11.64C3.12995 10.74 4.17995 10.25 5.30995 10.25H18.69C19.8 10.25 20.85 10.74 21.5599 11.59L21.7299 11.82C22.27 12.56 22.51 13.46 22.42 14.37L22.02 19.36C21.87 20.88 21.69 22.75 18.29 22.75ZM5.30995 11.75C4.63995 11.75 3.99995 12.05 3.57995 12.57L3.50995 12.64C3.18995 13.05 3.01995 13.63 3.07995 14.23L3.47995 19.24C3.61995 20.7 3.67995 21.25 5.70995 21.25H18.29C20.33 21.25 20.38 20.7 20.52 19.23L20.92 14.22C20.9799 13.63 20.8099 13.04 20.42 12.58L20.32 12.46C19.8699 11.99 19.3 11.75 18.68 11.75H5.30995Z"
      fill="white"
    />
    <path
      d="M20.5 12.2193C20.09 12.2193 19.75 11.8793 19.75 11.4693V9.6793C19.75 6.6993 19.23 6.1793 16.25 6.1793H13.7C12.57 6.1793 12.18 5.7793 11.75 5.2093L10.46 3.4993C10.02 2.9193 9.92 2.7793 9.02 2.7793H7.75C4.77 2.7793 4.25 3.2993 4.25 6.2793V11.4293C4.25 11.8393 3.91 12.1793 3.5 12.1793C3.09 12.1793 2.75 11.8393 2.75 11.4293V6.2793C2.75 2.4493 3.92 1.2793 7.75 1.2793H9.03C10.57 1.2793 11.05 1.7793 11.67 2.5993L12.95 4.2993C13.22 4.6593 13.24 4.6793 13.71 4.6793H16.26C20.09 4.6793 21.26 5.8493 21.26 9.6793V11.4693C21.25 11.8793 20.91 12.2193 20.5 12.2193Z"
      fill="white"
    />
    <path
      d="M14.5697 17.75H9.42969C9.01969 17.75 8.67969 17.41 8.67969 17C8.67969 16.59 9.01969 16.25 9.42969 16.25H14.5697C14.9797 16.25 15.3197 16.59 15.3197 17C15.3197 17.41 14.9897 17.75 14.5697 17.75Z"
      fill="white"
    />
  </svg>
);

export default FolderOpenIcon;
