import { lazy } from 'react';

import { LocalStorage } from '../../service/LocalStorage';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { User } from '../../utils/models/User';

import { MainPaths } from './mainPaths';

// import Arrearage from '../../pages/arrearage/Arrearage'
// import Client from '../../pages/client/Client'
// import ClientBalance from '../../pages/clientBalance/ClientBalance'
// import ClientBalanceSearchClient from '../../pages/clientBalanceSearchClient/ClientBalanceSearchClient'
// import ClientCreateContract from '../../pages/clientCreateContract/ClientCreateContract'
// import Contract from '../../pages/contract/Contract'
// import ContractTableUpdate from '../../pages/contractTableUpdate/ContractTableUpdate'
// import CreateOrder from '../../pages/createOrder/CreateOrder'
// import CreateTablePayments from '../../pages/createTablePayments/CreateTablePayments'
// import Home from '../../pages/home/Home'
// import HomeId from '../../pages/homeId/HomeId'
// import Order from '../../pages/order/Order'
// import OrderId from '../../pages/orderId/OrderId'
// import PaymentSearchClient from '../../pages/paymentSearchClient/PaymentSearchClient'
// import Setting from '../../pages/setting/Setting'
// import CreatePayment from '../../pages/createPayment/CreatePayment'
// import Message from '../../pages/message/Message'
// import Changes from '../../pages/changes/Changes'
// import Broker from '../../pages/broker/Broker'
// import Profile from '../../pages/profile/Profile'
// import Task from '../../pages/task/Task'
// import OneTask from '../../pages/oneTask/OneTask'
// import Crm from '../../pages/crm/Crm'
// import OneLid from '../../pages/oneLid/OneLid'
// import CreateContract from '../../pages/createContract/CreateContract'
// import ContractIdV2 from '../../pages/contractIdV2/ContractIdV2'
// import PaymentV2 from '../../pages/paymentV2/PaymentV2'
// import Monetary from '../../pages/monetary/Monetary'
// import ContractId from '../../pages/contractId/ContractId'
// import Payment from '../../pages/payment/Payment'
// import EventList from '../../pages/activityLogs/Index'
// import EventList from '../../pages/activityLogs/Index'
// import OrderIdV2 from '../../pages/order-id-v2/OrderIdV2'

const Home = lazy(() => import('../../pages/home/Home'));
const HomeId = lazy(() => import('../../pages/homeId/HomeId'));
const Order = lazy(() => import('../../pages/order/Order'));
const OrderIdV2 = lazy(() => import('../../pages/order-id-v2/OrderIdV2'));
const OneCampaign = lazy(() => import('../../pages/one-campagin/OneCampaging'));
const Crm = lazy(() => import('../../pages/crm/Crm'));
const RobotoCalls = lazy(() => import('../../pages/roboto-calls/RobotoCalls'));
const OneLid = lazy(() => import('../../pages/oneLid/OneLid'));
const Task = lazy(() => import('../../pages/task/Task'));
const OneTask = lazy(() => import('../../pages/oneTask/OneTask'));
const ContractIdV2 = lazy(() => import('../../pages/contractIdV2/ContractIdV2'));
const CreateContract = lazy(() => import('../../pages/createContract/CreateContract'));
const PaymentV2 = lazy(() => import('../../pages/paymentV2/PaymentV2'));
const Monetary = lazy(() => import('../../pages/monetary/Monetary'));
const Arrearage = lazy(() => import('../../pages/arrearage/Arrearage'));
const Client = lazy(() => import('../../pages/client/Client'));
const Contract = lazy(() => import('../../pages/contract/Contract'));
const Changes = lazy(() => import('../../pages/changes/Changes'));
const Broker = lazy(() => import('../../pages/broker/Broker'));
const Setting = lazy(() => import('../../pages/setting/Setting'));
const Profile = lazy(() => import('../../pages/profile/Profile'));
const Message = lazy(() => import('../../pages/message/Message'));
const CreatePayment = lazy(() => import('../../pages/createPayment/CreatePayment'));
const PaymentSearchClient = lazy(() => import('../../pages/paymentSearchClient/PaymentSearchClient'));
const CreateOrder = lazy(() => import('../../pages/createOrder/CreateOrder'));
const CreateTablePayments = lazy(() => import('../../pages/createTablePayments/CreateTablePayments'));
const ContractTableUpdate = lazy(() => import('../../pages/contractTableUpdate/ContractTableUpdate'));
const ClientBalance = lazy(() => import('../../pages/clientBalance/ClientBalance'));
const ClientBalanceSearchClient = lazy(() => import('../../pages/clientBalanceSearchClient/ClientBalanceSearchClient'));
const ClientCreateContract = lazy(() => import('../../pages/clientCreateContract/ClientCreateContract'));
const Dashboard = lazy(() => import('@pages/dashboard/Dashboard'));
const ClientPage = lazy(() => import('@pages/oneLid/components/information/clients/client-page/ClientPage'));
const Test = lazy(() => import('@pages/test/Test'));

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: MainPaths.INDEX,
    component: Home,
    exact: true,
    permission: [Permissions.PERMISSION_READ_FLATS]
  },
  {
    path: MainPaths.DASHBOARD,
    component: Dashboard,
    exact: true,
    permission: [Permissions.PERMISSION_PAGE_DASHBOARD, Permissions.PERMISSION_PAGE_CRM]
  },
  {
    path: MainPaths.INDEX + MainPaths.FLAT_ID + MainPaths.HOME_NAME,
    component: HomeId,
    exact: true,
    permission: [Permissions.PERMISSION_READ_FLATS]
  },
  {
    path: MainPaths.CLIENT,
    component: Client,
    exact: false,
    permission: [Permissions.PERMISSION_READ_CLIENTS]
  },
  {
    path: MainPaths.SETTING,
    component: Setting,
    exact: false
  },
  {
    path: MainPaths.CHANGES,
    component: Changes,
    exact: true,
    permission: [Permissions.PERMISSION_CHANGES]
  },
  {
    path: MainPaths.INDEX + MainPaths.FLAT_ID + MainPaths.HOME_NAME + MainPaths.ORDER + MainPaths.CREATE_ORDER,
    component: CreateOrder,
    exact: true,
    permission: [Permissions.PERMISSION_CREATE_BOOKING]
  },
  {
    path: MainPaths.CONTRACT,
    component: Contract,
    exact: true,
    permission: [Permissions.PERMISSION_READ_CONTRACTS]
  },
  {
    path: MainPaths.CONTRACT + MainPaths.ORDER_OR_FLAT_ID + MainPaths.CLIENT_INFO,
    component: ClientCreateContract,
    exact: true,
    permission: [Permissions.PERMISSION_CREATE_CONTRACT]
  },
  {
    path: MainPaths.CLIENT + MainPaths.CREATE_NEW_CLIENT,
    component: ClientCreateContract,
    exact: true,
    permission: [Permissions.PERMISSION_UPDATE_CLIENT]
  },
  {
    path: MainPaths.CONTRACT + MainPaths.ORDER_OR_FLAT_ID + MainPaths.CLIENT_INFO + MainPaths.CLIENT_ID + MainPaths.HOUSE_INFO,
    component: CreateContract,
    permission: [Permissions.PERMISSION_CREATE_CONTRACT],
    exact: true
  },
  {
    path: MainPaths.CONTRACT + MainPaths.CONTRACT_ID + MainPaths.CONTRACT_NUMBER,
    component: ContractIdV2,
    permission: [Permissions.PERMISSION_READ_CONTRACTS],
    exact: true
  },
  {
    path: MainPaths.CLIENT + MainPaths.CLIENT_ID + MainPaths.CLIENT_NUMBER,
    component: ClientPage,
    permission: [Permissions.PERMISSION_READ_CONTRACTS],
    exact: true
  },
  {
    path: `${MainPaths.CONTRACT}/:id${MainPaths.UPDATE_TABLE}`,
    component: ContractTableUpdate,
    permission: [Permissions.PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT],
    exact: true
  },
  {
    path: MainPaths.CONTRACT + MainPaths.ORDER_OR_FLAT_ID + MainPaths.CLIENT_INFO + MainPaths.CLIENT_ID + MainPaths.HOUSE_INFO + MainPaths.CREATE + MainPaths.TABLE_PAYMENT,
    component: CreateTablePayments,
    permission: [Permissions.PERMISSION_CREATE_CONTRACT],
    exact: true
  },
  {
    path: MainPaths.ORDER,
    component: Order,
    exact: true,
    permission: [Permissions.PERMISSION_READ_BOOKINGS]
  },
  {
    path: MainPaths.ORDER + MainPaths.ORDER_ID + MainPaths.ORDER_NUMBER,
    component: OrderIdV2,
    permission: [Permissions.PERMISSION_READ_BOOKINGS],
    exact: true
  },
  {
    path: MainPaths.PAYMENT,
    component: PaymentV2,
    permission: [Permissions.PERMISSION_READ_PAYMENTS],
    exact: true
  },
  {
    path: MainPaths.PAYMENT + MainPaths.CREATE_PAYMENT_SEARCH_CLIENT,
    component: PaymentSearchClient,
    permission: [Permissions.PERMISSION_CREATE_PAYMENT],
    exact: true
  },
  {
    path: MainPaths.PAYMENT + MainPaths.CLIENT_BALANCE_SEARCH_CLIENT,
    component: ClientBalanceSearchClient,
    permission: [Permissions.PERMISSION_CREATE_PAYMENT],
    exact: true
  },
  {
    path: MainPaths.PAYMENT + MainPaths.CONTRACT_ID + MainPaths.CREATE_PAYMENT,
    component: CreatePayment,
    permission: [Permissions.PERMISSION_CREATE_PAYMENT],
    exact: true
  },
  {
    path: MainPaths.PAYMENT + MainPaths.CLIENT_ID + MainPaths.CLIENT_BALANCE,
    component: ClientBalance,
    permission: [Permissions.PERMISSION_CREATE_PAYMENT],
    exact: true
  },
  {
    path: MainPaths.ARREARAGE,
    component: Arrearage,
    permission: [Permissions.PERMISSION_READ_DEBTS],
    exact: true
  },
  {
    path: MainPaths.MESSAGE,
    component: Message,
    exact: true,
    permission: [Permissions.PERMISSION_READ_MESSAGES]
  },
  {
    path: MainPaths.CLIENT + MainPaths.ORDER_OR_FLAT_ID + MainPaths.CLIENT_INFO,
    component: ClientCreateContract,
    exact: true,
    permission: [Permissions.PERMISSION_UPDATE_CLIENT]
  },
  {
    path: MainPaths.BROKER,
    component: Broker,
    exact: true,
    permission: [Permissions.PERMISSION_READ_STAFF]
  },
  {
    path: MainPaths.PROFILE,
    component: Profile,
    exact: false
  },
  {
    path: MainPaths.TASK,
    component: Task,
    exact: true,
    permission: [Permissions.PERMISSION_PAGE_CRM]
  },
  {
    path: MainPaths.ONE_TASK,
    component: OneTask,
    exact: true,
    permission: [Permissions.PERMISSION_PAGE_CRM]
  },
  {
    path: MainPaths.CRM,
    component: Crm,
    exact: true,
    permission: [Permissions.PERMISSION_PAGE_CRM]
  },
  {
    path: MainPaths.ROBOTO_CALLS,
    component: RobotoCalls,
    exact: true,
    permission: [Permissions.PERMISSION_ROBOT_CALL]
  },
  {
    path: MainPaths.ROBOTO_CALLS + MainPaths.ROBOTO_CALLS_ID + MainPaths.ROBOTO_CALLS_NAME,
    component: OneCampaign,
    permission: [Permissions.PERMISSION_READ_BOOKINGS],
    exact: true
  },
  {
    path: MainPaths.CRM_ONE_LID,
    component: OneLid,
    exact: true,
    permission: [Permissions.PERMISSION_PAGE_CRM]
  },
  {
    path: MainPaths.MONETARY,
    component: Monetary,
    exact: true
    // permission: [Permissions.PERMISSION_MONETARY_PAYMENT],
  },
  {
    path: '/test',
    component: Test,
    exact: true
  }
];

const mainRoutes: typeof routes = [];

if (typeof user === 'object' && user) {
  routes.forEach(route => {
    if (route.permission) {
      if (user?.role) {
        if (route.permission?.every(p => user.role.permissions.includes(p))) {
          mainRoutes.push(route);
        }
      } else if (user?.roleTokens && route.permission?.every(p => user.roleTokens![0]?.role?.permissions.includes(p))) {
        mainRoutes.push(route);
      }
    } else {
      mainRoutes.push(route);
    }
  });
}

export default mainRoutes;
