import React from 'react';
import { useDeleteOnlinePbx } from '@queries/mutation';
import { OnlinePbx } from '@utils/models/OnlinePbx';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onlinePbx: OnlinePbx;
}

const ConfirmModal: React.FC<IProps> = ({ visible, setVisible, onlinePbx }) => {
  const { t } = useTranslation();
  const deletePbx = useDeleteOnlinePbx(onlinePbx?.id || 0);
  const handleClose = () => {
    setVisible(false);
  };
  // handle delete online pbx
  const handleDeleteOnlinePbx = () => {
    deletePbx.mutateAsync().then(handleClose);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      okText={t('home.Chiqish')}
      cancelText={t('home.Yopish')}
      okButtonProps={{ className: '!border-danger !text-danger w-[180px] rounded-full h-10', type: 'default', loading: deletePbx.isLoading }}
      cancelButtonProps={{ className: 'rounded-full h-10', type: 'text' }}
      title={<p className="text-xl font-semibold text-[#464255]">OnlinePBX</p>}
      onOk={handleDeleteOnlinePbx}
    >
      <p className="text-center text-base font-medium text-[#667085]">{t('home.Agar_chiqishni_davom_ettirsangiz_PBX_sozlama_malumotlari_ochib_ketadi')}</p>
    </Modal>
  );
};

export default ConfirmModal;
