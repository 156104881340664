import React from 'react';
import { useDispatch } from 'react-redux';
import CustomSelect from '@components/custom-fields/components/custom-select/CustomSelect';
import { useAppSelector } from '@hooks/reduxHooks';
import ArrowDownIcon from '@icons/ArrowDownIcon';
import { changeLeadsFiledValueList, leadFieldFilterAi } from '@reducers/CrmOneLidSlice';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { CrmField } from '@utils/models/CrmField';
import { Select } from 'antd';
import { t } from 'i18next';

import styles from './info.module.scss';

const { Option } = Select;

type Props = {
  item: CrmField;
  index: number;
};

const MultiSelect: React.FC<Props> = ({ item, index }) => {
  const dispatch = useDispatch();
  const fields = useAppSelector(state => state.crmOneLidField.leadFieldValue);
  const leadFieldValueAi = useAppSelector(state => state.crmOneLidField.leadFieldValueAi);

  // handle change value fields
  const changeValueFields = (id: number, valueList: string[]) => {
    dispatch(changeLeadsFiledValueList({ id, valueList }));
  };

  const hasAi = leadFieldValueAi?.some(ai => {
    const splitAi = ai.split(':');
    const id = Number(splitAi[0]);
    const name = splitAi[1];

    return id === item?.id && fields.find(field => field.id === item.id)?.valueList?.includes(name);
  });

  return (
    <div key={index} className={styles.container_body_item} id={`field_${item?.id}`}>
      <div className={styles.container_body_item_name}>{item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}</div>
      <CustomSelect
        value={fields.find(field => field.id === item.id)?.valueList!}
        onChange={e => {
          changeValueFields(Number(item.id), e);
        }}
        bordered={false}
        suffixIcon={<ArrowDownIcon />}
        className={styles.container_body_item_value}
        mode="multiple"
        placeholder={t('one_lid.Bir_nechta_tanlang')}
        maxTagCount="responsive"
        dropdownStyle={{
          maxHeight: 400,
          minWidth: 300
        }}
        ai={hasAi}
        onStopAiTime={() => {
          const value = `${item?.id}:${fields.find(field => field.id === item.id)?.valueList![0]}`;

          dispatch(leadFieldFilterAi(value));
        }}
      >
        {item?.options?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Option key={index} value={item.name}>
            {item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}
          </Option>
        ))}
      </CustomSelect>
    </div>
  );
};

export default MultiSelect;
