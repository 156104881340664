import React from 'react';

const ThreePoint = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00033 4.66667C8.73671 4.66667 9.33366 4.06971 9.33366 3.33333C9.33366 2.59695 8.73671 2 8.00033 2C7.26395 2 6.66699 2.59695 6.66699 3.33333C6.66699 4.06971 7.26395 4.66667 8.00033 4.66667Z"
        fill="#464255"
      />
      <path
        d="M8.00033 9.33317C8.73671 9.33317 9.33366 8.73621 9.33366 7.99984C9.33366 7.26346 8.73671 6.6665 8.00033 6.6665C7.26395 6.6665 6.66699 7.26346 6.66699 7.99984C6.66699 8.73621 7.26395 9.33317 8.00033 9.33317Z"
        fill="#464255"
      />
      <path
        d="M8.00033 14.0002C8.73671 14.0002 9.33366 13.4032 9.33366 12.6668C9.33366 11.9304 8.73671 11.3335 8.00033 11.3335C7.26395 11.3335 6.66699 11.9304 6.66699 12.6668C6.66699 13.4032 7.26395 14.0002 8.00033 14.0002Z"
        fill="#464255"
      />
    </svg>
  );
};

export default ThreePoint;
