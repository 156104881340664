import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { cx } from '@utils/cx';
import { getLocalStorage } from '@utils/index';
import { Input, Spin } from 'global';
import { useTranslation } from 'react-i18next';

import { usePostPushNotificationConfig } from '../../service/mutation';
import { useGetPushNotificationConfigAll } from '../../service/queries';
import { caseTypes } from '../../utils/helpers/caseTypes';

interface IProps {
  time?: number | null | string;
  created?: boolean;
  times?: (number | string | null)[];
  id?: number;
}

const TaskAfterTime = ({ time, created, times, id }: IProps) => {
  const { data: dataPushNotificationsConfigAll } = useGetPushNotificationConfigAll();
  const postPushNotificationConfig = usePostPushNotificationConfig();
  const { t } = useTranslation();
  const [value, setValue] = useState(time);


  useEffect(() => {
    setValue(time);
  }, [time]);

  const handleSave = () => {
    if (value) {
      postPushNotificationConfig.mutate({
        inSiteStatus: dataPushNotificationsConfigAll?.inSiteStatus,
        status: dataPushNotificationsConfigAll?.status,
        firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
        parts: [
          {
            id,
            // @ts-expect-error
            timeIntervals: times?.includes(value) ? [] : [value],
            type: caseTypes?.AFTER,
          }
        ]
      });
    }
  };

  return created ? (
    <Spin spinning={postPushNotificationConfig.isLoading} indicator={<LoadingOutlined />}>
      <div
        className={cx(
          'flex cursor-pointer items-center justify-center gap-[5px] rounded-xl border-[1px] border-solid border-[#D0D5DD] px-[10px] py-[5px] font-semibold ',
          times?.includes(Number(time)) && 'border-[1px] !border-[#16835b] bg-[#E6F6F4]'
        )}
        onClick={handleSave}
      >
        <Input width={45} value={value || ''} onChange={setValue} type="number" returnType="number" onClick={e => e.stopPropagation()} onPressEnter={handleSave} />
        <h3 className="m-0 font-medium text-gray text-[12px]">{t('home.minut')}</h3>
        <h3 className="m-0 font-medium text-gray text-[12px]">({t('home.har')})</h3>
      </div>
    </Spin>
  ) : (
    <Spin spinning={postPushNotificationConfig.isLoading} indicator={<LoadingOutlined />}>
      <div
        className={cx(
          'flex cursor-pointer items-center justify-center rounded-xl border-[1px] border-solid border-[#D0D5DD] px-[20px] py-[10px] font-semibold',
          times?.includes(Number(time)) && 'border-[1px] !border-[#16835b] bg-[#E6F6F4]'
        )}
        onClick={() => {
          postPushNotificationConfig.mutate({
            inSiteStatus: dataPushNotificationsConfigAll?.inSiteStatus,
            status: dataPushNotificationsConfigAll?.status,
            firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
            parts: [
              {
                id,
                // @ts-expect-error
                timeIntervals: times?.includes(time) ? [] : [time],
                type: caseTypes?.AFTER,
              }
            ]
          });
        }}
      >
        <h3 className="m-0 font-medium text-[12px]">
          {time} {t('home.minut')} ({t('home.har')})
        </h3>
      </div>
    </Spin>
  );
};

export default TaskAfterTime;
