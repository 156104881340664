import { z } from 'zod';

export const termDiscount = z.object({
  name: z.coerce.string().min(1),
  percentage: z.coerce.number(),
  startDate: z.string().optional(),
  finishDate: z.string().optional(),
  date: z.coerce.string().array(),
  id: z.number().optional()
});

export interface ITermDiscount extends z.infer<typeof termDiscount> {}
