import React from 'react';

const SquareBorderIcon = () => {
  return (
    <svg stroke="#000" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1H1V13" strokeWidth="1.5"></path>
    </svg>
  );
};

export default SquareBorderIcon;
