import React from 'react';
import ProfileSecyurityTable from './profile-secyurity-table/ProfileSecyurityTable';
import styles from './profileSecyurity.module.scss';

const ProfileSecyurity = () => {
  return (
    <div className={styles.container}>
      <ProfileSecyurityTable />
    </div>
  );
};

export default ProfileSecyurity;
