import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const PickUpIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#2ECF56" />
      <path
        d="M31.7936 28.5042V28.4999C31.7936 28.4999 28.0224 24.7331 28.0201 24.7312C27.7211 24.4321 27.2429 24.4386 26.9488 24.7332L25.2129 26.4762C24.5381 27.1512 23.6679 26.5523 21.9976 25.1903C20.8925 24.2892 19.6211 23.0774 18.5186 21.7113C17.745 20.7527 17.0608 19.7461 17.7676 19.0395C17.7694 19.0377 19.5106 17.301 19.5103 17.2992L19.5116 17.2955C19.8241 16.9831 19.7938 16.5044 19.5084 16.2187V16.2144C19.5084 16.2144 15.6283 12.3391 15.6261 12.3367C15.3271 12.0378 14.8491 12.0444 14.5548 12.3387L12.8061 14.0874C12.0857 14.989 10.4846 19.2861 17.3486 26.322C24.5795 33.7344 29.2455 32.1488 30.0488 31.3124C30.0488 31.3124 31.7956 29.5862 31.7956 29.5845L31.7971 29.581C32.1096 29.2684 32.0788 28.7901 31.7936 28.5042Z"
        fill="white"
      />
    </svg>
  );
};

export default PickUpIcon;
