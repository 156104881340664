import React from 'react';

interface Props {
  className?: string;
}

const ArrowDownIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5249 8.95986L1.2649 5.69986C0.879902 5.31486 0.879902 4.68486 1.2649 4.29986L4.5249 1.03986"
        stroke="#E6E6E6"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownIcon;
