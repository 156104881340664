import React from 'react';

interface IProps {
  fill?: string;
  className?: string;
}

const ChevronLeft = ({ fill = 'white', className }: IProps) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M5.51885 7.99246C5.52019 7.83843 5.58131 7.69094 5.68932 7.58111L11.5075 1.76293C11.6172 1.65695 11.7642 1.59831 11.9168 1.59963C12.0693 1.60096 12.2152 1.66215 12.3231 1.77002C12.431 1.87789 12.4922 2.02382 12.4935 2.17637C12.4948 2.32892 12.4362 2.47589 12.3302 2.58562L6.92335 7.99246L12.3302 13.3993C12.3858 13.453 12.4301 13.5172 12.4606 13.5882C12.4911 13.6591 12.5071 13.7355 12.5078 13.8127C12.5085 13.89 12.4937 13.9666 12.4645 14.0381C12.4352 14.1096 12.392 14.1746 12.3374 14.2292C12.2828 14.2838 12.2178 14.327 12.1463 14.3563C12.0748 14.3855 11.9982 14.4003 11.9209 14.3996C11.8437 14.3989 11.7673 14.3829 11.6964 14.3524C11.6254 14.3219 11.5612 14.2776 11.5075 14.222L5.68932 8.4038C5.58131 8.29398 5.52019 8.14649 5.51885 7.99246Z"
      fill={fill}
    />
  </svg>
);

export default ChevronLeft;
