import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { queryKeys } from '@pages/changes/utils/constants/queryKeys';
import { Button, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import SearchIcon from '../../../../assets/icons/SearchIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { setSearchClient } from '../../../../store/reducers/ChangesSlice';
import { ChangesPageSteps, ChangesQueryParams } from '../../../../utils/models/Changes';
import { ClientPhone } from '../../../../utils/models/Client';
import { ClientType } from '../../../../utils/models/ClientType';
import { useOneClientChanges, useSearchClientChanges } from '../../services/queries';

import ActiveClient from './ActiveClient';
import FindClientItem from './FindClientItem';
import NotFoundClient from './NotFoundClient';

import styles from './client.module.scss';

const SearchAndShow = () => {
  const qc = useQueryClient();
  const [scrolling, setScrolling] = useState(true);
  const { appendMultipleDifferent, searchParams } = useQueryParam<ChangesQueryParams, null>();

  const dispatch = useAppDispatch();

  const selectedClient = useAppSelector(state => state.changesReducer.selectedClient as any);
  const searchClient = useAppSelector(state => state.changesReducer.searchClient);

  const { data, isFetching, fetchNextPage } = useSearchClientChanges(searchClient);
  const { isLoading: isLoadingActiveClient } = useOneClientChanges(searchParams.clientId);

  useEffect(
    () => () => {
      dispatch(setSearchClient(''));
    },
    []
  );

  const handleClickClear = () => {
    dispatch(setSearchClient(''));
    qc.setQueryData([queryKeys.SEARCH_CLIENT_CHANGES, searchClient], []);
  };

  const handleSearchClient = (value: string) => {
    dispatch(setSearchClient(String(value)));
  };

  const clientName = () => {
    if (selectedClient?.type === ClientType.NATURAL) {
      return `${selectedClient?.lastname} ${selectedClient?.firstname} ${selectedClient?.fatherName}`;
    }
    return selectedClient?.name;
  };

  const clientPhone = () => {
    let activeClientPhone = '';

    selectedClient?.phones?.forEach((phn: ClientPhone) => {
      if (phn.active) {
        activeClientPhone = phn.phone;
      }
    });
    return activeClientPhone;
  };

  const goToNextStep = () => {
    appendMultipleDifferent(['page', 'clientId'], [ChangesPageSteps.contract, selectedClient?.id]);
  };
  const { t } = useTranslation();
  const returnTitle = data ? `${t(`changes.client.SearchAndShow.Ushbu_mijoz_bazada_mavjud_emas`)}` : `${t(`changes.client.SearchAndShow.Mijoz_qidirish`)}`;

  const showIconInput = () => {
    if (searchClient) return <CloseOutlined onClick={handleClickClear} className="deleteSearchIcon" />;
    return <SearchIcon />;
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 100 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <Spin spinning={isLoadingActiveClient}>
      <div>
        <Input
          disabled={selectedClient?.id}
          onChange={e => handleSearchClient(e.target.value)}
          placeholder={`${t(`changes.client.SearchAndShow.Mijoz_qidirish`)}`}
          className={styles.searchInput}
          value={searchClient}
          suffix={showIconInput()}
        />
        {selectedClient?.id ? (
          <>
            <ActiveClient phone={clientPhone()} id={selectedClient.id} name={clientName()} />
            <Button onClick={goToNextStep} type="primary" className={styles.next_step_button}>
              {t(`changes.client.SearchAndShow.Keyingi_bosqich`)}
            </Button>
          </>
        ) : (
          <Spin spinning={isFetching}>
            <div className={styles.findClients} onScroll={e => onScroll(e)}>
              {data?.pages?.map(data => data?.data?.map((client, index) => <FindClientItem client={client} key={index} />))}
            </div>
            {(!data || data?.pages?.length === 0) && <NotFoundClient title={returnTitle} />}
          </Spin>
        )}
      </div>
    </Spin>
  );
};

export default SearchAndShow;
