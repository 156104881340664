import React from 'react';
import styles from './importExport.module.scss';
import { Table } from 'antd';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import BackSquare from '../../assets/icons/BackSquare';
import { useTransferDataList } from '../../queries/queries';
import { StatusKey, TransferDataList } from '../../utils/models/TransferData';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { showInfoModal } from '../../store/reducers/TransferDataSlice';
import { useTranslation } from 'react-i18next';

const ImportTable = () => {
  const { isLoading, isFetching, data } = useTransferDataList();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialColumn = [
    {
      title: '№',
      render: (text: any, record: any, index: number) => index + 1
    },
    {
      title: t('contract_id.Yaratilgan_sana'),
      dataIndex: 'createdDate'
    },
    {
      title: t('home.Xodim'),
      dataIndex: 'createdBy'
    },
    {
      title: t('home.Status'),
      render: (record: TransferDataList) => (
        <span className={record.statusKey === StatusKey.SUCCESS ? 'table_client_status_new' : 'table_status_contract_mode'}>
          {getCurrentLanguageWord(record.status)}
        </span>
      )
    },
    {
      title: '',
      render: () => (
        <div className={styles.back_button}>
          <BackSquare />
          <span>{t('home.Bekor_qilish')}</span>
        </div>
      )
    }
  ];
  return (
    <div className={styles.table_import}>
      <h2>{t('import_export.Natijalar_jadvali')}</h2>
      <Table
        loading={isLoading || isFetching}
        className="table_import"
        columns={initialColumn as any}
        dataSource={data}
        size="small"
        rowKey={(record: TransferDataList, index) => String(index)}
        pagination={false}
        onRow={(record: TransferDataList) => ({
          onClick: () => dispatch(showInfoModal(record.statusKey))
        })}
      />
    </div>
  );
};

export default ImportTable;
