import React, { FC, useEffect, useState } from 'react';
import { AutoComplete, Button, Dropdown, Form, Input, Modal, Slider } from 'antd';
import { useTranslation } from 'react-i18next';

import ExportIcon from '../../../../../assets/icons/contract/ExportIcon';
import DocumentCopyIcon from '../../../../../assets/icons/DocumentCopyIcon';
import LocationIcon from '../../../../../assets/icons/LocationIcon';
import { DEFAULT_CENTER } from '../../../../../components/houseConfig/house-location-modal/HouseLocationMap';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { useOpenCagaData } from '../../../../../queries/queries';
import { changeContactsFiledValue, changeLeadsFiledValue } from '../../../../../store/reducers/CrmOneLidSlice';

import LocationAttachmentMap from './LocationAttachmentMap';

import styles from './locationAttachment.module.scss';

const IsParsable = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return {};
  }
  return JSON.parse(data);
};

interface IProps {
  children: React.ReactNode;
  value?: string;
  id: number | null;
  contactId?: number | null;
}

const LocationAttachment: FC<IProps> = ({ children, value, id, contactId }) => {
  const [locationDropDown, setLocationDropDown] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const parseValue = IsParsable(value && value.includes('}') && value.includes('{') ? value : '{}') || {};
  const valueObj = {
    lat: parseValue?.lat || 0,
    lng: parseValue?.lng || 0,
    radius: parseValue?.radius || 100
  };
  const [select, setSelect] = useState(`${valueObj.lat},${valueObj.lng}`);
  const [changeSelect, setChangeSelect] = useState(false);
  const { data } = useOpenCagaData(`q=${select}`);
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [center, setCenter] = useState({
    lat: valueObj?.lat,
    lng: valueObj?.lng
  });
  const [radius, setRadius] = useState(valueObj?.radius);
  const onCancle = () => {
    setVisible(false);
  };

  const openModal = () => {
    setLocationDropDown(false);
    setVisible(true);
  };

  const onFinish = (values: any) => {
    const objString = JSON.stringify({
      radius,
      name: values.addres || 'Location',
      ...center
    });

    if (contactId) {
      dispatch(
        changeContactsFiledValue({
          value: objString,
          id: id || 0,
          contactId
        })
      );
    } else {
      dispatch(changeLeadsFiledValue({ value: objString, id: id || 0 }));
    }
    onCancle();
  };

  useEffect(() => {
    if (!changeSelect && data && data?.results?.length > 0) {
      form.setFieldsValue({
        addres: data?.results[0]?.formatted
      });
    }
  }, [data, changeSelect, form]);

  useEffect(() => {
    function myLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          err => {
            setCenter(DEFAULT_CENTER);
          }
        );
      } else {
        setCenter(DEFAULT_CENTER);
      }
    }
    return () => {
      myLocation();
    };
  }, []);

  const openMap = () => {
    window.open(`https://www.google.com/maps/?daddr=${valueObj.lat},${valueObj.lng}`, '_blank');
  };

  const copyMapUrl = () => {
    setLocationDropDown(false);
    navigator.clipboard.writeText(`https://www.google.com/maps/?daddr=${valueObj.lat},${valueObj.lng}`);
  };

  return (
    <>
      {valueObj?.lat && valueObj?.lng ? (
        <Dropdown
          placement="topCenter"
          visible={locationDropDown}
          onVisibleChange={setLocationDropDown}
          overlay={
            <div className={styles.dropdownLocation}>
              <div onClick={openMap}>
                <ExportIcon /> {t('widgets.Xaritada_ochish')}
              </div>
              <div onClick={copyMapUrl}>
                <DocumentCopyIcon /> {t('widgets.Urlni_nusxalash')}
              </div>
              <div onClick={openModal}>
                <LocationIcon /> {t('integration.Tahrirlash')}
              </div>
            </div>
          }
        >
          <div>{children}</div>
        </Dropdown>
      ) : (
        <div onClick={openModal}>{children}</div>
      )}
      <Modal title={t('home.Lokatsiyani_biriktirish')} onCancel={onCancle} visible={visible} footer={false} width={546} wrapClassName={styles.modal}>
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Form.Item label={t('home.Manzil')} name="addres">
            <AutoComplete
              options={data?.results
                ?.filter(item => item?.geometry?.lat && item?.geometry?.lng)
                ?.map(item => ({
                  ...item,
                  label: item?.formatted,
                  value: item?.formatted
                }))}
              onSelect={(e: any, b: any) => {
                setChangeSelect(true);
                if (b?.geometry?.lat && b?.geometry?.lng) {
                  setCenter(b?.geometry);
                }
              }}
              onSearch={e => {
                setChangeSelect(true);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                e.length >= 3 && setSelect(e);
              }}
            >
              <Input className="customInput" placeholder={t('home.Kocha_Kordinata_yoki_URL_orqali_izlang')} />
            </AutoComplete>
          </Form.Item>
          <Form.Item label="Radius">
            <Slider max={500} value={radius} onChange={setRadius} />
          </Form.Item>
          <div className={styles.map}>
            <LocationAttachmentMap center={center} setCenter={setCenter} radius={radius} setSelect={setSelect} />
          </div>
          <div className={styles.footer}>
            <Button onClick={onCancle}>{t('home.Bekor_qilish')}</Button>
            <Button type="primary" htmlType="submit">
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default LocationAttachment;
