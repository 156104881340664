import React from 'react';

const ArrowBottomIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.9533 9.61984L7.99996 13.6665L12.0466 9.61984"
      stroke="#00A389"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 2.33322L8 13.5532" stroke="#00A389" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArrowBottomIcon;
