import { createSlice } from '@reduxjs/toolkit';

interface ShowroomModalState {
  visible: boolean;
}

const initialState: ShowroomModalState = {
  visible: false
};

export const ShowroomModalSlice = createSlice({
  name: 'showroomModal',
  initialState,
  reducers: {
    setShowroomModal: (state, action) => {
      state.visible = action.payload;
    }
  }
});

export const { setShowroomModal } = ShowroomModalSlice.actions;
export default ShowroomModalSlice.reducer;
