import { useQuery } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { $api } from '@service/RequestService';
import { queryKeys } from '../utils/constants/queryKeys';
import { Pagination } from '@utils/models/Pagination';
import { CrmSettingsFilterModel } from '../utils/models/CrmSettingsFilter';
import { CrmSettingsModel } from '../utils/models/CrmSettingsModel';
import { CrmSettingsCompact } from '../utils/models/CrmSettingsCompact';

export function useCrmSettingsFilter(searchParams: any) {
  let searchParamsObj = { ...searchParams };
  searchParamsObj.page = Number(searchParams.page) || 1;
  searchParamsObj.size = Number(searchParams.size) || 15;
  return useQuery<Pagination<CrmSettingsFilterModel>>([queryKeys.CRM_SETTINGS, searchParams], async () => {
    const res = await $api.post(endPoints.CRM_SETTINGS, searchParams);
    return res.data.data;
  });
}

export function useGetCrmSettings() {
  return useQuery<CrmSettingsModel[]>([queryKeys.CRM_SETTINGS], async () => {
    const res = await $api.get(endPoints.CRM_SETTINGS_ROLE);
    return res.data.data;
  });
}

export function useGetAllRole() {
  return useQuery<CrmSettingsCompact[]>([queryKeys.CRM_SETTINGS_COMPACT], async () => {
    const res = await $api.get(endPoints.CRM_SETTINGS_COMPACT);
    return res.data.data;
  });
}
