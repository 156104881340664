/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import CheckmarkIcon from '../../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import TrushSquareIcon from '../../../../../../../assets/icons/TrushSquareIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { setFieldCondition, setFieldInputLogic, setFieldValueInput } from '../../../../../../../store/reducers/TriggerSlice';
import { CrmField } from '../../../../../../../utils/models/CrmField';
import { FieldsCondition, LogicFields } from '../../../../../../../utils/models/Trigger';

import styles from './textField.module.scss';

interface Props {
  order: number;
  field: CrmField;
  fieldValue: FieldsCondition;
}

const TextField: React.FC<Props> = ({ field, order, fieldValue }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const errorList = useAppSelector(state => state.triggerSlice.errorList);
  const dispatch = useAppDispatch();

  // console.log('field', field);
  // console.log('fieldValue', fieldValue);

  const { t } = useTranslation();

  // handle show field
  const handleDeleteField = () => {
    dispatch(
      setFieldCondition({
        add: false,
        fieldId: field.id as number,
        type: field.type
      })
    );
  };

  // handle change logic
  const handleChangeLogic = (logic: LogicFields) => {
    dispatch(
      setFieldInputLogic({
        logic,
        id: field.id as number
      })
    );
    handleOpenDropdown(false);
  };

  // show title
  const showTitleLogic = () => {
    switch (fieldValue.logic) {
      case LogicFields.NOT_CONTAINS:
        return t('triggers.NOT_CONTAINS');
      case LogicFields.CONTAINS:
        return t('triggers.CONTAINS');
      case LogicFields.NOT_MATCHES:
        return t('triggers.NOT_MATCHES');
      case LogicFields.NOT_EMPTY:
        return t('triggers.NOT_EMPTY');
      case LogicFields.EMPTY:
        return t('triggers.EMPTY');
      case LogicFields.MATCHES:
        return t('triggers.MATCHES');
      default:
        return t('triggers.Tanlang');
    }
  };

  // handle change value
  const handleChangeValue: React.ChangeEventHandler<HTMLInputElement> = e => {
    dispatch(
      setFieldValueInput({
        id: field.id as number,
        value: e.target.value
      })
    );
  };

  // show error
  const showError = () => {
    let errorValue = false;

    errorList.forEach(error => {
      if (error.fieldId === field.id) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  // handle open dropdown
  const handleOpenDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_body + (showError() ? ` ${styles.container_body_error}` : '')}>
        <span className={styles.container_body_title}>
          {order !== 0 && t('triggers.va')} {field.name}:
        </span>
        <Dropdown
          visible={openDropdown}
          onVisibleChange={handleOpenDropdown}
          placement="bottom"
          overlayClassName={styles.container_body_dropdown}
          overlay={
            <>
              <div onClick={() => handleChangeLogic(LogicFields.MATCHES)} className={styles.container_body_dropdown_item}>
                <span className={styles.container_body_dropdown_item_name}>{t('triggers.MATCHES')}</span>
                {fieldValue.logic === LogicFields.MATCHES && <CheckmarkIcon className={styles.container_body_dropdown_item_icon} />}
              </div>
              <div onClick={() => handleChangeLogic(LogicFields.NOT_MATCHES)} className={styles.container_body_dropdown_item}>
                <span className={styles.container_body_dropdown_item_name}>{t('triggers.NOT_MATCHES')}</span>
                {fieldValue.logic === LogicFields.NOT_MATCHES && <CheckmarkIcon className={styles.container_body_dropdown_item_icon} />}
              </div>
              <div onClick={() => handleChangeLogic(LogicFields.CONTAINS)} className={styles.container_body_dropdown_item}>
                <span className={styles.container_body_dropdown_item_name}>{t('triggers.CONTAINS')}</span>
                {fieldValue.logic === LogicFields.CONTAINS && <CheckmarkIcon className={styles.container_body_dropdown_item_icon} />}
              </div>
              <div onClick={() => handleChangeLogic(LogicFields.NOT_CONTAINS)} className={styles.container_body_dropdown_item}>
                <span className={styles.container_body_dropdown_item_name}>{t('triggers.NOT_CONTAINS')}</span>
                {fieldValue.logic === LogicFields.NOT_CONTAINS && <CheckmarkIcon className={styles.container_body_dropdown_item_icon} />}
              </div>
              <div onClick={() => handleChangeLogic(LogicFields.NOT_EMPTY)} className={styles.container_body_dropdown_item}>
                <span className={styles.container_body_dropdown_item_name}>{t('triggers.NOT_EMPTY')}</span>
                {fieldValue.logic === LogicFields.NOT_EMPTY && <CheckmarkIcon className={styles.container_body_dropdown_item_icon} />}
              </div>
              <div onClick={() => handleChangeLogic(LogicFields.EMPTY)} className={styles.container_body_dropdown_item}>
                <span className={styles.container_body_dropdown_item_name}>{t('triggers.EMPTY')}</span>
                {fieldValue.logic === LogicFields.EMPTY && <CheckmarkIcon className={styles.container_body_dropdown_item_icon} />}
              </div>
            </>
          }
          trigger={['click']}
        >
          <span onClick={() => handleOpenDropdown(true)} className={styles.container_body_label}>
            ({showTitleLogic()}):
          </span>
        </Dropdown>
        <input value={fieldValue.values[0] ? fieldValue.values[0].value : ''} onChange={handleChangeValue} className={styles.container_body_input} />
      </div>
      <TrushSquareIcon onClick={handleDeleteField} className={styles.container_delete} />
    </div>
  );
};

export default TextField;
