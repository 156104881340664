import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { messageRoutes } from './messageRoutes';
import MessageSettingsNavbar from '../../components/messageSettings/MessageSettingsNavbar';
import styles from './../../pages/messageSettings/MessageSettings.module.scss';
import MainHeader from '../../components/headers/MainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import { Layout } from 'antd';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';

const { Content } = Layout;

function MessageRouter() {
  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.messageSettings}>
          <MessageSettingsNavbar />
          <Routes>
            {messageRoutes.map((route, index) => (
              <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
            ))}
          </Routes>
        </div>
      </Content>
    </>
  );
}

export default MessageRouter;
