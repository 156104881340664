/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import TableOrderIcon from '../../../../assets/icons/TableOrderIcon';
import TwoCheckIcon from '../../../../assets/icons/TwoCheckIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { getClassNameOrder } from '../../../../utils/helper/getClassNameTableOrder';
import { urlEncode } from '../../../../utils/helper/urlEncodeDecode';
import { ContractList, FlatsContractList } from '../../../../utils/models/ContractList';
import { ContractStatus } from '../../../../utils/models/ContractStatus';
import { TableConfig } from '../../../../utils/models/TableConfig';
import { SearchParamsContract } from '../../Contract';
import AddCardCont from '../addCard/AddCardCont';
import FlatItem from '../flatItem/FlatItem';

import Actions from './actions/Actions';
import ContractCustomerChange from './customerChange/ContractCustomerChange';
import DownloadLogModal from './downloadLogModal/DownloadLogModal';

interface TableContractProps {
  data: ContractList[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
}

const ContractTable: React.FC<TableContractProps> = ({ tableConfig, tableConfigFetch, data, isLoading, isFetching }) => {
  const [openDownloadLogModal, setOpenDownloadLogModal] = useState({
    open: false,
    id: 0,
    number: ''
  });

  const [customerChange, setCustomerChange] = useState(false);
  const [clientId, setClientId] = useState<number>(-1);
  const [contractId, setContractId] = useState<number>(1);
  const [openAddCard, setOpenAddCard] = useState({
    isOpen: false,
    id: -1,
    name: ''
  });
  const { location, push, orderTable, searchParams } = useQueryParam<SearchParamsContract, any>();

  const { t } = useTranslation();

  // handle download log
  const handleDownloadLog = (id: number, number: string, open: boolean) => {
    setOpenDownloadLogModal({
      open,
      number,
      id
    });
  };

  const handleCloseAddCard = () => {
    setOpenAddCard({
      isOpen: false,
      id: -1,
      name: ''
    });
  };

  const initialColumn: ColumnsType<ContractList> | undefined = [
    {
      title: t('home.№'),
      dataIndex: 'number'
    },
    {
      title: t('home.Mijoz'),
      dataIndex: 'clientName',
      align: 'center'
    },
    {
      dataIndex: 'createdDate',
      render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>,
      title: (
        <div onClick={() => handleOrders('DATE')} className="orderTableCont">
          <span>{t('home.Sana')}</span>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      )
    },
    {
      title: t('home.TJM_nomi'),
      align: 'center',
      render: record => <p>{record?.flats?.map((item: FlatsContractList) => item?.houseName)[0]}</p>
    },
    {
      title: (
        <>
          <div>{t('home.Uy_malumotlari')}</div>
          {/* <div style={{ color: '#A3A3A3' }}>
            {t('contract.Uy_raqami_Qavat_Xona_soni')}
          </div> */}
          <div style={{ color: '#A3A3A3' }}>
            <Tooltip title={t('contract.Uy_raqami_Qavat_Xona_soni')}>
              <span>
                {t('contract.Uy_raqami_Qavat_Xona_soni').length > 15 ? `${t('contract.Uy_raqami_Qavat_Xona_soni').slice(0, 15)}...` : t('contract.Uy_raqami_Qavat_Xona_soni')}
              </span>
            </Tooltip>
          </div>
        </>
      ),
      align: 'center',
      render: (item: ContractList) => <FlatItem flats={item.flats} />
    },
    {
      title: (
        <div onClick={() => handleOrders('AMOUNT')} className="orderTableCont">
          <span>{t('home.Tolov_summasi_sum')}</span>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      align: 'right',
      render: (i: ContractList) => (
        <>
          {i.residue?.toLocaleString()} {i.currency?.ccy}
        </>
      )
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      render: (item: ContractStatus) => (
        <span className={item === ContractStatus.ACTIVE ? 'table_status_active' : item === ContractStatus.CANCELLED ? 'table_status_contract_mode' : 'table_status_canceled'}>
          {t(`home.${item}`)}
        </span>
      )
    },
    {
      title: t('home.Rasmiyligi'),
      dataIndex: 'formality',
      render: (e: boolean) => (
        <>
          <TwoCheckIcon color={e ? '#B9BBBD' : '#28C76F'} />
        </>
      )
    },
    {
      title: t('home.Chegirma'),
      // dataIndex: 'discountPercent',
      render: (record: any) => (
        <>
          <p>{record.discountPercent} %</p>
        </>
      )
    },
    {
      title: t('home.Masul'),
      dataIndex: 'responsibleBy'
    },
    {
      render: (record: ContractList) => (
        <Actions
          record={record}
          setCustomerChange={setCustomerChange}
          handleDownloadLog={handleDownloadLog}
          setContractId={setContractId}
          setClientId={setClientId}
          setOpenAddCard={setOpenAddCard}
        />
      )
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case '1_m_kv_narxi':
              newConfig.push({
                title: (
                  <div className="orderTableCont justify-center">
                    <span>{t('home.1_m_kv_narxi')}</span>
                  </div>
                ),
                align: 'right',
                render: (record: ContractList) => (
                  <>
                    {record.pricePerArea?.toLocaleString('ru')} {record.currency?.ccy}
                  </>
                )
              });
              break;
            case 'Sana':
              newConfig.push({
                title: (
                  <div onClick={() => handleOrders('DATE')} className="orderTableCont">
                    <span>{t('home.Sana')}</span>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'createdDate',
                align: 'right',
                render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
              });
              break;
            case 'Uy_malumotlari':
              newConfig.push({
                title: (
                  <>
                    <div>{t('home.Uy_malumotlari')}</div>
                    {/* <div style={{ color: '#A3A3A3' }}>
                      {t('contract.Uy_raqami_Qavat_Xona_soni')}
                    </div> */}
                    <div style={{ color: '#A3A3A3' }}>
                      <Tooltip title={t('contract.Uy_raqami_Qavat_Xona_soni')}>
                        <span>
                          {t('contract.Uy_raqami_Qavat_Xona_soni').length > 15
                            ? `${t('contract.Uy_raqami_Qavat_Xona_soni').slice(0, 15)}...`
                            : t('contract.Uy_raqami_Qavat_Xona_soni')}
                        </span>
                      </Tooltip>
                    </div>
                  </>
                ),
                align: 'center',
                render: (item: ContractList) => <FlatItem flats={item.flats} />
              });
              break;
            case 'Tolov_summasi':
              newConfig.push({
                align: 'right',
                title: (
                  <div onClick={() => handleOrders('AMOUNT')} className="orderTableCont">
                    <span>{t('home.Tolov_summasi_sum')}</span>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('AMOUNT', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('AMOUNT', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                render: (i: ContractList) => (
                  <>
                    {i.residue?.toLocaleString()} {i.currency?.ccy}
                  </>
                )
              });
              break;
            case 'Status':
              newConfig.push({
                title: t('home.Status'),
                dataIndex: 'status',
                render: (item: ContractStatus) => (
                  <span
                    className={item === ContractStatus.ACTIVE ? 'table_status_active' : item === ContractStatus.CANCELLED ? 'table_status_contract_mode' : 'table_status_canceled'}
                  >
                    {t(`home.${item}`)}
                  </span>
                )
              });
              break;
            case 'Rasmiyligi':
              newConfig.push({
                title: t('home.Rasmiyligi'),
                dataIndex: 'formality',
                render: (e: boolean) => (
                  <>
                    <TwoCheckIcon color={e ? '#B9BBBD' : '#28C76F'} />
                  </>
                )
              });
              break;
            case 'Chegirma':
              newConfig.push({
                title: t('home.Chegirma'),
                render: (record: any) => (
                  <>
                    <p>{record.discountPercent}%</p>
                  </>
                )
              });
              break;
            case 'Mijoz':
              newConfig.push({
                title: t('home.Mijoz'),
                dataIndex: 'clientName',
                align: 'center'
              });
              break;
            case 'TJM_nomi':
              newConfig.push({
                title: t('home.TJM_nomi'),
                align: 'center',
                render: (record: ContractList) => <p>{record?.flats?.map(item => item?.houseName)[0]}</p>
              });
              break;
            case '№':
              newConfig.push({
                title: t('home.№'),
                dataIndex: 'number'
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                dataIndex: 'responsibleBy'
              });
              break;
            case 'Qolgan_muddati':
              newConfig.push({
                title: t('home.Qolgan_muddati'),
                dataIndex: 'remained'
              });
              break;
            default:
              break;
          }
        }
      });
      newConfig.push({
        render: (record: ContractList) => (
          <Actions
            record={record}
            setCustomerChange={setCustomerChange}
            handleDownloadLog={handleDownloadLog}
            setContractId={setContractId}
            setClientId={setClientId}
            setOpenAddCard={setOpenAddCard}
          />
        )
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch, location.search]);

  useEffect(() => {
    const newConfig: any[] = [...column];

    if (tableConfig.length === 0) {
      newConfig[2] = {
        dataIndex: 'createdDate',
        render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>,
        title: (
          <div onClick={() => handleOrders('DATE')} className="orderTableCont">
            <span>{t('home.Sana')}</span>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
            </div>
          </div>
        )
      };
      newConfig[4] = {
        title: (
          <div onClick={() => handleOrders('AMOUNT')} className="orderTableCont">
            <span>{t('home.Tolov_summasi_sum')}</span>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('AMOUNT', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('AMOUNT', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        align: 'right',
        render: (i: ContractList) => (
          <>
            {i.residue?.toLocaleString()} {i.currency?.ccy}
          </>
        )
      };
      setColumn(newConfig);
    }
  }, [location.search]);

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  const goToOneContract = (e: ContractList) => {
    push(`${location.pathname}/${e.id}/${urlEncode(`№ ${e.number}`)}`);
  };

  return (
    <>
      <Table
        className="custom_table_contract custom_table_size  contract_tables"
        columns={column}
        dataSource={data}
        rowKey="id"
        loading={isLoading || tableConfigFetch || isFetching}
        size="small"
        pagination={false}
        rowClassName={(row: any) => `row_size row_size_${row.payedPercent}`}
        onRow={(e: any) => ({
          onClick: () => goToOneContract(e)
        })}
      />
      <ContractCustomerChange visible={customerChange} setVisible={setCustomerChange} id={clientId} setId={setClientId} setContractId={setContractId} contractId={contractId} />
      <AddCardCont name={openAddCard.name} id={openAddCard.id} isOpen={openAddCard.isOpen} onClose={handleCloseAddCard} />
      <DownloadLogModal number={openDownloadLogModal.number} id={openDownloadLogModal.id} visible={openDownloadLogModal.open} close={handleDownloadLog} />
    </>
  );
};

export default ContractTable;
