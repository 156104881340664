import React from 'react';

import styles from './tasksListContainer.module.scss';
import CompletedTasks from './CompletedTasks';
import TaskAdd from './TaskAdd/TaskAdd';

const TasksListContainer = () => {
  return (
    <div className={styles.container}>
      <div className={'d-flex justify-space-between'}>
        <CompletedTasks />
      </div>
      <TaskAdd />
    </div>
  );
};

export default TasksListContainer;
