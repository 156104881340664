/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
import { FC } from 'react';
import { useGetFlatFields } from '@components/showroom/Filter/services/queries';
import SelectField from '@pages/crm/components/filter/drawer/SelectField';
import TextField from '@pages/crm/components/filter/drawer/TextField';
import { CrmFieldsType } from '@utils/models/CrmFieldsType';
import { RadioChangeEvent, Space } from 'antd';
import { Checkbox, Collapse, Drawer, Radio, Slider, Spin } from 'global';
import { useTranslation } from 'react-i18next';

import ColapseIcon from '../../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../../assets/icons/FIlterColapseIcon';
import FilterReloadIcon from '../../../assets/icons/FilterReloadIcon';
import RangeSlider from '../../../components/filters/RangeSlider';
import RoomNumber from '../../../components/filters/RoomNumber';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useHousesNameAndID } from '../../../queries/queries';
import { FlatStatus } from '../../../utils/models/FlatStatus';
import HouseItem from '../../contract/components/table/houseItem/HouseItem';
import { SearchParamsHome } from '../Home';
import { useFlatTableFilters } from '../services/queries';

import '../../../components/filters/filterDrawer.module.scss';

const { Panel } = Collapse;

interface FilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const HomeFilterDrawer: FC<FilterDrawerProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, appendMultipleArray, appendSingle, appendMultiple, removeAllSearchParams, remove } = useQueryParam<SearchParamsHome, any>();
  const { t } = useTranslation();
  const { data: fieldsData } = useGetFlatFields();
  const { isLoading, data } = useFlatTableFilters();
  const { isLoading: housesLoading, data: houses } = useHousesNameAndID();

  const onClose = () => {
    setVisible(false);
  };
  const repairConditionChange = (e: RadioChangeEvent) => {
    if (e.target.value !== 'clear') {
      appendSingle('repaired', e.target.value);
    } else {
      remove('repaired', undefined);
    }
  };

  const apartmentChange = (e: RadioChangeEvent) => {
    if (e.target.value !== 'clear') {
      appendSingle('apartment', e.target.value);
    } else {
      remove('apartment', undefined);
    }
  };

  const changeArea = (value: number[]) => {
    appendMultipleDifferent(['minArea', 'maxArea'], value);
  };

  const changeRooms = (value: string[]) => {
    appendMultipleArray('rooms', value);
  };

  const changeFlatStatus = (value: FlatStatus) => {
    if (Array.isArray(searchParams.saleStatuses)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.saleStatuses.includes(value) ? remove('saleStatuses', value) : appendMultiple('saleStatuses', value);
    } else if (searchParams.saleStatuses === value) {
      remove('saleStatuses', value);
    } else {
      appendMultiple('saleStatuses', value);
    }
  };

  const changeFloor = (value: number[]) => {
    appendMultipleDifferent(['minFloor', 'maxFloor'], value);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className="filter_drawer">
      <Spin spinning={isLoading || housesLoading}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={searchParams.houses ? ['6'] : ['1']}
          expandIconPosition="right"
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Holati')}
              </>
            }
            key="1"
          >
            <Space direction="vertical">
              {data?.commerceStatuses.map((item: FlatStatus, index) => {
                if (item === FlatStatus.RESERVE) return null;
                return (
                  <Checkbox
                    checked={Array.isArray(searchParams.saleStatuses) ? searchParams.saleStatuses.includes(item) : searchParams.saleStatuses === item}
                    onChange={() => changeFlatStatus(item)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {t(`home.${item}`)}
                  </Checkbox>
                );
              })}
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Maydon_(m2)')}
              </>
            }
            key="2"
          >
            <Slider
              range
              onChange={changeArea}
              value={[searchParams.minArea ? parseInt(searchParams.minArea) : data?.areaMin || 0, searchParams.maxArea ? parseInt(searchParams.maxArea) : data?.areaMax || 100]}
              max={data?.areaMax}
              min={data?.areaMin}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 1px 0px 3px'
              }}
            >
              <span className="slider_custom">{data?.areaMin}</span>
              <span className="slider_custom">{data?.areaMax}</span>
            </div>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Qavat')}
              </>
            }
            key="3"
          >
            <RangeSlider
              defaultValue={[parseInt(searchParams.minFloor || '0'), parseInt(searchParams.maxFloor || '0')]}
              onChangeSlider={changeFloor}
              maxFloor={data?.floorMax === undefined ? 7 : data.floorMax}
              minFloor={data?.floorMin === undefined ? 1 : data.floorMin}
            />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Tamir_holati')}
              </>
            }
            key="4"
          >
            <Radio.Group onChange={repairConditionChange} value={searchParams.repaired || 'clear'}>
              <Space direction="vertical">
                <Radio value="clear">{t('home.Barchasi')}</Radio>
                <Radio value="false">{t('home.Tamirsiz')}</Radio>
                <Radio value="true">{t('home.Tamirli')}</Radio>
              </Space>
            </Radio.Group>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turi')}
              </>
            }
            key="5"
          >
            <Radio.Group onChange={apartmentChange} value={searchParams.apartment || 'clear'}>
              <Space direction="vertical">
                <Radio value="clear">{t('home.Barchasi')}</Radio>
                <Radio value="true">{t('home_id.Turarjoy')}</Radio>
                <Radio value="false">{t('home_id.Noturar_joy')}</Radio>
              </Space>
            </Radio.Group>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Xonalar_soni')}
              </>
            }
            key="6"
          >
            <RoomNumber
              // eslint-disable-next-line no-nested-ternary
              defaultValue={Array.isArray(searchParams.rooms) ? searchParams.rooms : searchParams.rooms ? [searchParams.rooms] : []}
              numbersArr={data?.rooms || []}
              onChangeSlider={changeRooms}
            />
          </Panel>
          <Panel
            className="house_item"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turar_joy_majmuasi')}
              </>
            }
            key="7"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {houses?.map((house, index) => <HouseItem key={index} id={house.id || -1} name={house.name} />)}
            </Space>
          </Panel>
          <Panel
            className="house_item"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Qoshimcha_maydonlar')}
              </>
            }
            key="8"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {fieldsData?.map((item, index) => {
                if (item.type === CrmFieldsType.TEXT) {
                  return <TextField key={index} data={item} fields={searchParams?.fields} />;
                }
                return <SelectField key={index} data={item} fields={searchParams?.fields} />;
              })}
            </Space>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default HomeFilterDrawer;
