import React from 'react';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const Download: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9998 29.3333H19.9998C26.6665 29.3333 29.3332 26.6666 29.3332 20V12C29.3332 5.33329 26.6665 2.66663 19.9998 2.66663H11.9998C5.33317 2.66663 2.6665 5.33329 2.6665 12V20C2.6665 26.6666 5.33317 29.3333 11.9998 29.3333Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 15.3467L16 19.3467L20 15.3467" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 19.3467V8.68005" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 22.0133C13.1867 23.7466 18.8133 23.7466 24 22.0133" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Download;
