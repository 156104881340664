import React, { FC, memo } from 'react';
import DragIcon from '@icons/DragIcon';
import { FlatId } from '@utils/models/Flat';

import ApartamentCard from '../components/ApartamentCard';
import { createApartment } from '../helper/createApartment';

import styles from './shaxmatkaEntrance.module.scss';

interface IProps {
  max: number;
  floor: any;
  setDeleteLoad: React.Dispatch<React.SetStateAction<boolean>>;
  repaired: boolean;
  setRoom: React.Dispatch<any>;
  activeKey: number;
  flatCopy: FlatId | undefined;
  setFlatCopy: React.Dispatch<React.SetStateAction<FlatId | undefined>>;
  setSaveAll: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateFloorModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: {};
    }>
  >;
  createApartamentProps: any;
  bottomSelectChange: (value: any, e: any, podezh: any) => void;
  index: number;
}

const ShaxmatkaEntrance: FC<IProps> = props => {
  const { max, floor, setDeleteLoad, repaired, setRoom, activeKey, flatCopy, setFlatCopy, setSaveAll, setCreateFloorModal, createApartamentProps, bottomSelectChange } = props;
  // const dispatch = useAppDispatch()
  // const shaxmatka = useAppSelector((state) => state.shaxmatkaReducer.data)
  // const name = floor[0][0]?.entranceName || index + 1
  // const [value, setValue] = useState(`${name}`)

  // const handleSaveName = (value: string) => {
  //   const valueClean = value?.replace(t('home.podez'), '').trim()
  //   const entranceNameProps = {
  //     dispatch,
  //     setSaveAll,
  //     shaxmatka,
  //     index,
  //     name: valueClean,
  //   }

  //   entranceNameSave(entranceNameProps)
  // }
  return (
    <div className={styles.podez} onContextMenu={(e: any) => e.preventDefault()}>
      {/* <div className={styles.podez_footer_label}>
        <ReactInputMask
          mask={`***-${t('home.podez')}`}
          value={value}
          onChange={(e) => setValue(e?.target?.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSaveName(e.currentTarget.value)
            }
          }}
          // @ts-ignore*
          maskChar={' '}
          type="text"
          alwaysShowMask
        />
      </div> */}
      <div
        className={styles.podez_header}
        style={{
          gridTemplateColumns: `repeat(${max}, 1fr)`
        }}
      >
        {Array.from({ length: max }, (_, i) => i + 1).map((i, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <button key={index}>
            <DragIcon />
          </button>
        ))}
      </div>
      <div
        className={styles.podez_floor}
        style={{
          gridTemplateColumns: `repeat(${max}, 1fr)`
        }}
      >
        {floor.map(
          (flat: any) =>
            floor.length > 0 && (
              <>
                {flat.map((i: FlatId, index: number) =>
                  !i?.new ? (
                    <ApartamentCard
                      setDeleteLoad={setDeleteLoad}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      floor={floor}
                      i={i}
                      repaired={repaired}
                      setRoom={setRoom}
                      activeKey={activeKey}
                      flat={flat}
                      flatCopy={flatCopy}
                      setFlatCopy={setFlatCopy}
                      setSaveAll={setSaveAll}
                      setCreateFloorModal={setCreateFloorModal}
                    />
                  ) : (
                    // eslint-disable-next-line react/jsx-key
                    <div
                      className={styles.podez_apartment_plus}
                      onClick={() => createApartment(i, createApartamentProps)}
                      style={{
                        gridRow: i?.yaxis ? i?.yaxis[0] : '',
                        gridColumn: i?.yaxis ? i?.xaxis[0] : ''
                      }}
                    >
                      {' '}
                      +
                    </div>
                  )
                )}
              </>
            )
        )}
      </div>
      <div
        className={styles.podez_footer}
        style={{
          gridTemplateColumns: `repeat(${max}, 1fr)`
        }}
      >
        {Array.from({ length: max }, (_, i) => i + 1).map(i => (
          <div key={i}>
            <label
              className="bottomCheckbox ant-checkbox-wrapper"
              id={JSON.stringify({
                id: floor[0][0].entrance * max - (i - 1),
                entrance: floor[0][0].entrance,
                i
              })}
              onClick={(e: any) => bottomSelectChange(i, e, floor[0][0].entrance)}
            >
              <span className="ant-checkbox" style={{ pointerEvents: 'none' }}>
                <span className="ant-checkbox-inner" style={{ pointerEvents: 'none' }} />
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(ShaxmatkaEntrance);
