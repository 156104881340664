import React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

const CheckmarkIcon: React.FC<Props> = ({ className, fill = '#4E73F8' }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57543 11.9999C6.39143 11.9999 6.21543 11.9239 6.08943 11.7899L2.84743 8.33725C2.59476 8.06925 2.60876 7.64725 2.87676 7.39525C3.14543 7.14325 3.56743 7.15658 3.81876 7.42458L6.56876 10.3519L12.1741 4.21725C12.4234 3.94458 12.8448 3.92658 13.1168 4.17458C13.3881 4.42258 13.4068 4.84458 13.1588 5.11591L7.06743 11.7826C6.94276 11.9199 6.76543 11.9986 6.58009 11.9999H6.57543Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckmarkIcon;
