import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import { useTranslation } from 'react-i18next';

import { useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { Profile } from '../../utils/models/Profile';

import ChangeBranchModal from './branch-modal/branch-modal';
import ChangePassword from './changePassword/ChangePassword';
import ChangeAddress from './ChangeAddress';
import ChangeName from './ChangeName';
import ChangePhone from './ChangePhone';
import ChangeUserName from './ChangeUserName';

import styles from './userInformation.module.scss';

interface Props {
  profile?: Profile;
}

const Information: React.FC<Props> = ({ profile }) => {
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openChangeName, setOpenChangeName] = useState(false);
  const [openChangePhone, setOpenChangePhone] = useState(false);
  const [openChangeUsername, setOpenChangeUsername] = useState(false);
  const [openChangeAddress, setOpenChangeAddress] = useState(false);
  const [openChangeBranch, setOpenChangeBranch] = useState(false);
  const [regionId, setRegionId] = useState<number>(0);
  const user = useAppSelector(state => state.userReducer.user);
  const { t, i18n } = useTranslation();

  const { data: districts } = useGetDistrict(regionId, 'region');
  const { data: regions } = useRegions();

  const userRole = user?.roleTokens?.find(item => item?.branch?.id === user?.branchId)?.role?.lang?.uz;

  useEffect(() => {
    if (profile) {
      setRegionId(profile.cityId);
    }
  }, [profile]);

  // handle open change password modal
  const handleChangePassword = (visible: boolean) => {
    setOpenChangePassword(visible);
  };

  // handle open change name modal
  const handleChangeName = (visible: boolean) => {
    setOpenChangeName(visible);
  };

  // handle open change phone modal
  const handleChangePhone = (visible: boolean) => {
    setOpenChangePhone(visible);
  };

  // handle open change username modal
  const handleChangeUsername = (visible: boolean) => {
    setOpenChangeUsername(visible);
  };

  // handle open change username modal
  const handleChangeAddress = (visible: boolean) => {
    setOpenChangeAddress(visible);
  };

  const handleOpenBranchModal = (visible: boolean) => {
    setOpenChangeBranch(visible);
  };

  // get district name
  const getDistrictName = () => {
    if (profile && districts) {
      const district = districts.find(district => district.id === profile.districtId);

      if (district) {
        return `${getCurrentLanguageWord(district)}${profile?.address ? ',' : ''} `;
      }
    }
    return '';
  };

  // get region name
  const getRegionName = () => {
    if (profile && regions) {
      const region = regions.find(region => region.id === profile.cityId);

      if (region) {
        return `${getCurrentLanguageWord(region)}, `;
      }
    }
    return '';
  };

  const getCountryName = () => {
    const name = countrCode?.find(({ code }) => code === profile?.countryCode)?.[`country_${i18n.language}` as keyof (typeof countrCode)[number]];

    if (name) return `${name}, `;

    return '';
  };

  const filialName = user?.roleTokens?.find(item => item?.accessToken === localStorage.getItem('token'))?.branch?.name;

  return (
    <div className={styles.container_information}>
      <div className={styles.container_title}>{t('profile.Toliq_ism')}</div>
      <div className={styles.container_value}>{profile?.fullName}</div>
      <div onClick={() => handleChangeName(true)} className={styles.container_button}>
        {t('profile.Ismni_ozgartirish')}
      </div>
      <div className={styles.container_title}>{t('profile.Telefon_raqam')}</div>
      <div className={styles.container_value}>{profile?.phone}</div>
      <div onClick={() => handleChangePhone(true)} className={styles.container_button}>
        {t('profile.Telefon_raqamni_ozgartirish')}
      </div>
      <div className={styles.container_title}>{t('profile.Lavozim')}</div>
      <div className={styles.container_value}>{profile?.role ? getCurrentLanguageWord(profile?.role.lang) : userRole}</div>
      <div className={styles.container_title}>{t('profile.Username')}</div>
      <div className={styles.container_value}>@{profile?.username}</div>
      <div onClick={() => handleChangeUsername(true)} className={styles.container_button}>
        {t('profile.Usernameni_ozgartirish')}
      </div>
      <div className={styles.container_title}>{t('profile.Parol')}</div>
      <div className={styles.container_button} onClick={() => handleChangePassword(true)}>
        {t('profile.Parolni_ozgartirish')}
      </div>
      <div className={styles.container_title}>{t('profile.Manzil')}</div>
      <div className={styles.container_value}>
        {getCountryName()} {getRegionName()} {getDistrictName()} {profile?.address}
      </div>
      <div onClick={() => handleChangeAddress(true)} className={styles.container_button}>
        {t('profile.Manzilni_ozgartirish')}
      </div>
      {user?.roleTokens && profile?.roleBranch?.length! > 1 && (
        <>
          <div className={styles.container_title}>{t('home.Filial_nomi')}</div>
          <div className={styles.container_value}>{filialName}</div>
          <div onClick={() => handleOpenBranchModal(true)} className={styles.container_button}>
            {t('home.Filialni_tahrirlash')}
          </div>
        </>
      )}
      {profile?.expiry && (
        <>
          <div className={styles.container_title}>{t('profile.Amal_qilish_muddati')}</div>
          <div className={styles.container_value}>{profile.expiry}</div>
        </>
      )}
      <ChangePassword profile={profile} visible={openChangePassword} onClose={handleChangePassword} />
      <ChangeName visible={openChangeName} onClose={handleChangeName} profile={profile} />
      <ChangePhone visible={openChangePhone} onClose={handleChangePhone} profile={profile} />
      <ChangeUserName visible={openChangeUsername} onClose={handleChangeUsername} profile={profile} />
      <ChangeAddress visible={openChangeAddress} onClose={handleChangeAddress} profile={profile} />
      {openChangeBranch && <ChangeBranchModal setOpenChangeBranch={setOpenChangeBranch} visible={openChangeBranch} />}
    </div>
  );
};

export default Information;
