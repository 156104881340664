import FilesHistory from './components/FilesHistory';
import Notification from './components/Notification';

const IOService = () => (
  <>
    <Notification />
    <FilesHistory />
  </>
);

export default IOService;
