import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import styles from './weekView.module.scss';
import '@fullcalendar/react/dist/vdom';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { Spin } from 'antd';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { addWeek, completeTaskVisible, minusWeek, setTypeAndDate } from '@reducers/TaskCalendarViewSlice';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import { TaskFilter } from '@utils/models/TaskFilter';
import { ONE_DAY_UNIX_VALUE } from '@utils/constants/ONE_DAY_UNIX_VALUE';
import DateItem from './DateItem';
import { useTranslation } from 'react-i18next';
import CaretRightIcon from '@icons/CaretRightIcon';
import CaretRightBigIcon from '@icons/CaretRightBigIcon';
import { useTaskCalendarFilter } from '@pages/task/services/queries';

const Week = () => {
  const { searchParams } = useQueryParam<SearchParamsTask, null>();

  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const dispatch = useAppDispatch();

  const calendarRef = useRef<FullCalendar>(null);

  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useTaskCalendarFilter(
    searchParams,
    dayjs(date, DATE_FORMAT).startOf('week').add(1, 'day').format(DATE_FORMAT),
    dayjs(date, DATE_FORMAT).endOf('week').add(1, 'day').format(DATE_FORMAT)
  );

  useEffect(() => {
    if (data) {
      const events: EventSourceInput = [];
      data.forEach(item => {
        const allDay =
          dayjs(item.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() - dayjs(item.deadlineStart, DATE_WITH_TIME_FORMAT).unix() === ONE_DAY_UNIX_VALUE;
        events.push({
          id: String(item.id),
          allDay,
          resourceId: item,
          start: new Date(dayjs(item.deadlineStart, DATE_WITH_TIME_FORMAT).format()),
          end: new Date(dayjs(item.deadlineFinish, DATE_WITH_TIME_FORMAT).format())
        });
      });
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(new Date(dayjs(date, DATE_FORMAT).format()).toISOString());

      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...events]);
    }
  }, [data, calendarRef, date]);

  // got to one day
  const goToOneDate: (arg: DateClickArg) => void = e => {
    dispatch(
      setTypeAndDate({
        date: dayjs(e.date).format(DATE_FORMAT),
        type: TaskCalendarViewType.DAY
      })
    );
  };

  // handle open complete modal
  const handleOpenCompleteModal = (task: TaskFilter) => {
    if (!task.closedDate)
      dispatch(
        completeTaskVisible({
          task,
          visible: true
        })
      );
  };

  // handle back
  const handleBack: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    dispatch(minusWeek());
  };

  // handle next
  const handleNext: React.MouseEventHandler<HTMLSpanElement> = e => {
    e.stopPropagation();
    dispatch(addWeek());
  };

  // handle next
  const handleGoToDay = (date: Date) => {
    dispatch(
      dispatch(
        setTypeAndDate({
          date: dayjs(date).format(DATE_FORMAT),
          type: TaskCalendarViewType.DAY
        })
      )
    );
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <FullCalendar
          ref={calendarRef}
          height={'100%'}
          locale={'Ru-ru'}
          initialView={'timeGridWeek'}
          dateClick={goToOneDate}
          eventContent={e => {
            return <DateItem data={e?.event?._def?.extendedProps?.resourceId} />;
          }}
          eventClick={e => {
            handleOpenCompleteModal(e?.event?._def?.extendedProps?.resourceId);
          }}
          moreLinkContent={e => {
            return (
              <div className={styles.container_more}>
                {e.shortText} {t('calendar_view.topshiriq')}
              </div>
            );
          }}
          dayHeaderContent={e => {
            return (
              <div onClick={() => handleGoToDay(e.date)} className={styles.container_header}>
                {e.dow === 1 && (
                  <span onClick={handleBack} className={styles.container_header_caret_left}>
                    <CaretRightIcon />
                  </span>
                )}
                <span>
                  {t(`calendar_view.${dayjs(e.date).format('ddd')}`)}, {dayjs(e.date).format('D')}
                </span>
                {e.dow === 0 && (
                  <span onClick={handleNext} className={styles.container_header_caret_right}>
                    <CaretRightBigIcon />
                  </span>
                )}
              </div>
            );
          }}
          firstDay={1}
          allDayText={t('calendar_view.Kun_davomida')}
          eventClassNames={styles.container_event}
          allDaySlot={true}
          dayMaxEvents={3}
          dayCellClassNames={'m_fc_AllDay'}
          viewClassNames={'c_container'}
          selectMirror={true}
          dragScroll={true}
          eventDisplay="block"
          eventMaxStack={2}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          progressiveEventRendering
          headerToolbar={false}
          dayHeaders={true}
          nowIndicator={false}
          slotLabelFormat={[
            {
              hour: '2-digit',
              minute: '2-digit'
            }
          ]}
        />
      </div>
    </Spin>
  );
};

export default Week;
