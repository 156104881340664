import React from 'react';
import TagSelectV2 from '@components/tagSelect/TagSelectV2';
import TextEditable from '@components/text-editable/TextEditable';
import { useGetPipeStatus } from '@pages/crm/services/queries';
import { useMyCallsSetEmployee } from '@queries/mutation';
import { MyCallsEmployee } from '@utils/models/MyCalls';
import { Button, Checkbox, Empty, Form, FormInstance, Select } from 'antd';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  index: number;
  form: FormInstance;
  emp: MyCallsEmployee;
  dataId: number;
};

const SettingsMyCall: React.FC<Props> = ({ index, form, emp, dataId }) => {
  const { t } = useTranslation();
  const pipeId = useWatch(['pipeStatusDto', index, 'pipeId'], form);
  const { statuses, isLoading } = useGetPipeStatus(pipeId);
  const setEmployeeMutation = useMyCallsSetEmployee();

  const incomingLeadName = useWatch(['pipeStatusDto', index, 'incomingLeadName'], form);
  const outgoingLeadName = useWatch(['pipeStatusDto', index, 'outgoingLeadName'], form);
  const missingLeadName = useWatch(['pipeStatusDto', index, 'missingLeadName'], form);

  const leadNameContants = {
    key: '&phone&',
    label: t('home.Mijoz_raqami')
  };

  const onSaveEmployee = () => {
    const pipeDto = form.getFieldValue(['pipeStatusDto', index]);

    setEmployeeMutation.mutate({
      employeeId: form.getFieldValue(['pipeStatusDto', index, 'employeeId']),
      email: emp?.email,
      moiZId: dataId,
      pipeDto
    });
  };

  return (
    <ConditionalRender if={pipeId} else={<Empty description={t('home.Voronka tanlanmagan')} />}>
      <div className="flex flex-col gap-2">
        <Item name={['pipeStatusDto', index, 'incomingCall']} valuePropName="checked">
          <Checkbox>{t('home.Kiruvchi_qongiroqlar_uchun_bitim_yaratish')}</Checkbox>
        </Item>
        <div className="flex items-center justify-between gap-2">
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Bosqich')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'incomingPipeStatusId']} style={{ width: 170 }}>
              <Select className="customSelect" loading={isLoading}>
                {statuses?.map(item => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Teglar')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'incomingTagsId']} style={{ width: 170 }}>
              <TagSelectV2 isId />
            </Form.Item>
          </div>
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Lid_nomi')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'incomingLeadName']} style={{ width: 170 }}>
              <TextEditable dropdown fields={[leadNameContants]} value={incomingLeadName} />
            </Form.Item>
          </div>
        </div>
        <Item name={['pipeStatusDto', index, 'outgoingCall']} valuePropName="checked">
          <Checkbox>{t('home.Chiquvchi_qongiroqlar_uchun_bitim_yaratish')}</Checkbox>
        </Item>
        <div className="flex items-center justify-between gap-2">
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Bosqich')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'outgoingPipeStatusId']} style={{ width: 170 }}>
              <Select className="customSelect" loading={isLoading}>
                {statuses?.map(item => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Teglar')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'outgoingTagsId']} style={{ width: 170 }}>
              <TagSelectV2 isId />
            </Form.Item>
          </div>
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Lid_nomi')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'outgoingLeadName']} style={{ width: 170 }}>
              <TextEditable dropdown fields={[leadNameContants]} value={outgoingLeadName} />
            </Form.Item>
          </div>
        </div>
        <Item name={['pipeStatusDto', index, 'missingCall']} valuePropName="checked">
          <Checkbox>{t('home.Otkazib_yuborilgan_qongiroqlar_uchun_bitim_yaratish')}</Checkbox>
        </Item>
        <div className="flex items-center justify-between gap-2">
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Bosqich')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'missingPipeStatusId']} style={{ width: 170 }}>
              <Select className="customSelect" loading={isLoading}>
                {statuses?.map(item => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Teglar')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'missingTagsId']} style={{ width: 170 }}>
              <TagSelectV2 isId />
            </Form.Item>
          </div>
          <div>
            <h3 className="m-0 text-sm font-normal">{t('home.Lid_nomi')}</h3>
            <Form.Item name={['pipeStatusDto', index, 'missingLeadName']} style={{ width: 170 }}>
              <TextEditable dropdown fields={[leadNameContants]} value={missingLeadName} isEmpty />
            </Form.Item>
          </div>
        </div>
        <div className="my-4 flex items-center justify-end">
          <Button onClick={onSaveEmployee} type="primary" loading={setEmployeeMutation.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </div>
    </ConditionalRender>
  );
};

export default SettingsMyCall;
