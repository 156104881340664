import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import MessageModal from './MessageModal';
import { ColumnsType } from 'antd/es/table';
import { MessageList, MessageStatus } from '../../../utils/models/MessageList';
import { TableConfig } from '../../../utils/models/TableConfig';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { SearchParamsOrder } from '../../order/Order';
import TableOrderIcon from '../../../assets/icons/TableOrderIcon';
import { getClassNameOrder } from '../../../utils/helper/getClassNameTableOrder';

interface TableMessageProps {
  data: MessageList[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  currentPage: number;
  pageSize: number;
  isFetching: boolean;
}

const MessageTable: React.FC<TableMessageProps> = ({ tableConfig, tableConfigFetch, data, isLoading, currentPage, pageSize, isFetching }) => {
  const { searchParams, location, orderTable } = useQueryParam<SearchParamsOrder, null>();
  const [openInfoModal, setOpenInfoModal] = useState<{
    data: MessageList | null;
    isOpen: boolean;
  }>({
    data: null,
    isOpen: false
  });
  const { t } = useTranslation();

  const initialColumn: ColumnsType<{}> | undefined = [
    {
      title: t('home.№'),
      width: '100px',
      render: (text: any, record, index: number) => pageSize * (currentPage - 1) + index + 1
    },
    {
      title: t('home.Mijoz'),
      dataIndex: 'clientName',
      align: 'left'
    },
    {
      title: t('home.Telefon_raqam'),
      dataIndex: 'phone',
      width: '160px',
      render: (i: string) => <span style={{ color: '#87898E' }}>{i}</span>
    },
    {
      title: t('home.Xabar_matni'),
      dataIndex: 'text',
      width: '20%',
      render: (i: string) => <div>{returnMessageText(i)}</div>
    },
    {
      title: (
        <div onClick={() => handleOrders('DATE')} className={'orderTableCont'}>
          <span>{t('home.Yuborilgan_sana')}</span>
          <div className={'orderIcons'}>
            <TableOrderIcon className={'orderTable_ASC'} />
            <TableOrderIcon className={'orderTable_DESC'} />
          </div>
        </div>
      ),
      dataIndex: 'sendDate'
    },
    {
      title: t('home.Yubordi'),
      dataIndex: 'employee'
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      align: 'center',
      render: (i: MessageStatus) => {
        return <span className={'message_' + i}>{t(`home.${i}`)}</span>;
      }
    }
  ];

  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];
      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case '№':
              newConfig.push({
                title: t('home.№'),
                width: '100px',
                render: (text: any, record: MessageList, index: number) => pageSize * (currentPage - 1) + index + 1
              });
              break;
            case 'Mijoz':
              newConfig.push({
                title: t('home.Mijoz'),
                dataIndex: 'clientName',
                align: 'left'
              });
              break;
            case 'Telefon_raqam':
              newConfig.push({
                title: t('home.Telefon_raqam'),
                dataIndex: 'phone',
                width: '160px',
                render: (i: string) => <span style={{ color: '#87898E' }}>{i}</span>
              });
              break;
            case 'Xabar_matni':
              newConfig.push({
                title: t('home.Xabar_matni'),
                dataIndex: 'text',
                width: '20%',
                render: (i: string) => <div style={{ display: 'block', textAlign: 'left' }}>{returnMessageText(i)}</div>
              });
              break;
            case 'Yuborilgan_sana':
              newConfig.push({
                title: (
                  <div onClick={() => handleOrders('DATE')} className={'orderTableCont'}>
                    <span>{t('home.Yuborilgan_sana')}</span>
                    <div className={'orderIcons'}>
                      <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'sendDate'
              });
              break;
            case 'Yubordi':
              newConfig.push({
                title: t('home.Yubordi'),
                dataIndex: 'employee'
              });
              break;
            case 'Status':
              newConfig.push({
                title: t('home.Status'),
                dataIndex: 'status',
                align: 'center',
                render: (i: MessageStatus) => {
                  return <span className={'message_' + i}>{t(`home.${i}`)}</span>;
                }
              });
              break;
            default:
              break;
          }
        }
      });
      setColumn(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConfig, tableConfigFetch, location.search]);

  useEffect(() => {
    const newConfig: any[] = [...column];
    if (tableConfig.length === 0) {
      newConfig[0] = {
        title: t('home.№'),
        width: '100px',
        render: (text: any, record: any, index: number) => pageSize * (currentPage - 1) + index + 1
      };
      newConfig[4] = {
        title: (
          <div onClick={() => handleOrders('DATE')} className={'orderTableCont'}>
            <span>{t('home.Yuborilgan_sana')}</span>
            <div className={'orderIcons'}>
              <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        dataIndex: 'sendDate'
      };
      setColumn(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentPage]);

  const handleCloseModal = () => {
    setOpenInfoModal({
      data: null,
      isOpen: false
    });
  };

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  const returnMessageText = (text: string) => {
    if (text.length > 70) {
      return text.slice(0, 70) + '...';
    }
    return text;
  };

  return (
    <>
      <Table
        loading={isLoading || isFetching || tableConfigFetch}
        className={`custom_table custom_table_size message_table`}
        columns={column}
        dataSource={data}
        rowKey={'id'}
        size="small"
        pagination={false}
        onRow={record => {
          return {
            onClick: () => {
              setOpenInfoModal({
                data: record as MessageList,
                isOpen: true
              });
            }
          };
        }}
      />
      <MessageModal close={handleCloseModal} visible={openInfoModal.isOpen} data={openInfoModal.data || null} />
    </>
  );
};

export default MessageTable;
