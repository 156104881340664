import { Checkbox, Col, Form, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './messageSendModal.module.scss';
import Editable from './Editable';
import { TemplateMessage } from '../../../../utils/models/TemplateMessage';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { SearchParamsMessage } from '../../Message';
import { SendMessageAllTypes } from '../../../../utils/models/MessageList';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { Permissions } from '../../../../utils/constants/permissions';
import { ContractStatus } from '../../../../utils/models/ContractStatus';
import SettingIcon from '../../../../assets/icons/SettingIcon';
import { usePremiumOriginator, useTemplateMessage } from '../../services/queries';
import { usePremiumSendMessage } from '../../services/mutation';
import TjmFilterV3 from '@components/tjm-filter/tjm-filter-soft/TJMFilterSoft';

const { Option } = Select;

interface Props {
  setVisible: (value: boolean) => void;
  visible: boolean;
  changeVisible: (visible: boolean, template: TemplateMessage | undefined) => void;
}

const MessageContracted: React.FC<Props> = ({ setVisible, visible, changeVisible }) => {
  const [houses, setHouses] = useState<number[]>([]);
  const [buildings, setBuildings] = useState<number[] | any>([]);
  const [template, setTemplate] = useState<TemplateMessage | undefined>();
  const [errorTextArea, setErrorTextArea] = useState(false);

  const [form] = Form.useForm();

  const { searchParams } = useQueryParam<SearchParamsMessage, any>();

  const { data: originator } = usePremiumOriginator();

  const { t } = useTranslation();

  const { isLoading: loadingTemplate, data: messageTemplate } = useTemplateMessage();

  const premiumContractMutation = usePremiumSendMessage(SendMessageAllTypes.CONTRACT, searchParams, onSuccess);

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  // onSuccess
  function onSuccess() {
    form.resetFields();
    setTemplate(undefined);
    setErrorTextArea(false);
    setVisible(false);
  }

  // hande open template page
  const handleOpenTemplatePage = () => {
    changeVisible(true, template);
  };

  // handle select template
  const handleSelectTemplate = (id: number) => {
    if (messageTemplate) {
      const findTemplate = messageTemplate.find(temp => temp.id === id);
      setTemplate(findTemplate);
    }
  };

  // is access config
  const isAccessConfig = permissions?.includes(Permissions.PERMISSION_SETTINGS_MESSAGE);

  const handleSendMessage = (value: string) => {
    if (!value) {
      setErrorTextArea(true);
    } else {
      setErrorTextArea(false);
      premiumContractMutation.mutate({
        originator: form.getFieldValue('originator'),
        text: value,
        extra: {
          statuses: form.getFieldValue('statuses'),
          repaired: form.getFieldValue('repaired'),
          houses,
          buildings
        }
      });
    }
  };

  return (
    <div className="allFormStylePremium">
      <Form form={form} name={'messageContracted'} layout="vertical">
        <Form.Item style={{ width: '100%' }} name={'houses'} label={t('home.Turar_joy_majmualari')}>
          <TjmFilterV3 onHousesChange={setHouses} onBuildingsChange={setBuildings} />
        </Form.Item>

        <Form.Item name={'repaired'} label={t('home.Tamir_holati')} className="flex-direction-column">
          <Radio.Group>
            <Row gutter={[4, 8]}>
              <Col span={16}>
                <Radio value={true}>{t('home.Tamirli')}</Radio>
              </Col>
              <Col span={16}>
                <Radio value={false}>{t('home.Tamirsiz')}</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={'statuses'} label={t('premium_modal.Shartnoma_holati')}>
          <Checkbox.Group>
            <Row gutter={[4, 8]}>
              <Col span={12}>
                <Checkbox value={ContractStatus.ACTIVE}>{t('home.ACTIVE')}</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value={ContractStatus.STARTED}> {t('home.STARTED')}</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value={ContractStatus.CANCELLED}>{t('home.CANCELLED')}</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value={ContractStatus.FINISHED}> {t('home.FINISHED')}</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        {originator?.length !== 0 && (
          <Form.Item name={'originator'} label={t('premium_modal.Originator')}>
            <Select placeholder={t('premium_modal.Select')}>
              {originator?.map((item: any) => (
                <>
                  <Option key={item.id} value={item.name}>
                    {item.name}
                  </Option>
                </>
              ))}
            </Select>
          </Form.Item>
        )}
        <label className={styles.title}>{t('my_calls.Shablon')}</label>
        <div className={styles.template_select_cont}>
          <Select onChange={handleSelectTemplate} loading={loadingTemplate} allowClear value={template?.id} className={'customSelect ' + styles.template_select_cont_select}>
            {messageTemplate?.map((temp, index) => (
              <Option value={temp.id} key={index}>
                {temp.title}
              </Option>
            ))}
          </Select>
          {isAccessConfig && <SettingIcon onClick={handleOpenTemplatePage} className={styles.template_select_cont_settings_icon} />}
        </div>
        <Editable
          isTemplatePage={false}
          text={template?.text || ''}
          success={handleSendMessage}
          error={errorTextArea}
          loading={premiumContractMutation.isLoading}
          handleCloseTemplatePage={onSuccess}
        />
      </Form>
    </div>
  );
};

export default MessageContracted;
