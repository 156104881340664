/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../hooks/reduxHooks';
import { openModal } from '../../../store/reducers/CurrencySlice';

import styles from './header.module.scss';

const Header = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // handleOpen
  const handleOpen = () => {
    dispatch(
      openModal({
        data: undefined,
        isOpen: true
      })
    );
  };

  return (
    <div className={styles.container}>
      <h2>{t('currency.Valyuta_sozlamalari')}</h2>
      <Button onClick={handleOpen} className={styles.container_button} type={'primary'}>
        {t('currency.Valyuta_qoshish')}
      </Button>
    </div>
  );
};

export default Header;
