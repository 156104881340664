import React, { FC } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { PaymentDelayProps } from '../../../utils/models/BossPaymentDelay';

import styles from './paymentDelay.module.scss';

interface IntervalProps {
  interval: string;
  dataCopy: PaymentDelayProps[];
  isLoading: boolean;
}

const PaymentDelay: FC<IntervalProps> = ({ interval, dataCopy, isLoading }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading}>
        {interval !== String(-1) && (
          <div className={styles.header}>
            <div className={styles.circle_color} />
            <h2>
              {t('boss_contract.Har')} {interval} {t('boss_contract.oyda')}
            </h2>
          </div>
        )}
        {dataCopy ? (
          dataCopy?.map(item => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.body_txt_cont}>
              <div className={styles.body_txt_main}>
                <div className={styles.line} />
                <div className={styles.body_txt}>
                  <div className={styles.txt_left}>
                    <p>{t('boss_contract.Mijoz')}</p>
                  </div>
                  <div className={styles.txt_right}>
                    <p>{item.clientName}</p>
                  </div>
                </div>
                <div className={styles.body_txt}>
                  <div className={styles.txt_left}>
                    <p>{t('boss_contract.Xodim')}</p>
                  </div>
                  <div className={styles.txt_right}>
                    <p>{item.createdByName}</p>
                  </div>
                </div>
                <div className={styles.body_txt}>
                  <div className={styles.txt_left}>
                    <p>{t('boss_contract.Shartnoma_summasi')}</p>
                  </div>
                  <div className={styles.txt_right}>
                    <p>
                      {item.amount.toLocaleString('ru')} {item?.currency?.ccy}
                    </p>
                  </div>
                </div>
                <div className={styles.body_txt}>
                  <div className={styles.txt_left}>
                    <p>{t('boss_contract.TJM')}</p>
                  </div>
                  <div className={styles.txt_right}>
                    <p>{item.houseName}</p>
                  </div>
                </div>
                <div className={styles.body_txt}>
                  <div className={styles.txt_left}>
                    <p>{t('boss_contract.Shartnoma_raqami')}</p>
                  </div>
                  <div className={styles.txt_right}>
                    <p className={styles.bold}>{item.number}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </Spin>
    </div>
  );
};

export default PaymentDelay;
