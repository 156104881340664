import React, { FC } from 'react';

interface IProps {
  className?: string;
}

const CallCustomIcon: FC<IProps> = ({ className = '' }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M3.01402 8.47028C4.72869 10.1849 6.81122 11.508 8.51402 11.508C9.2794 11.508 9.94984 11.241 10.4898 10.6477C10.8042 10.2977 11 9.88829 11 9.48484C11 9.18818 10.8873 8.90339 10.6084 8.70167L8.78695 7.40825C8.50809 7.21839 8.2767 7.12346 8.06311 7.12346C7.79612 7.12346 7.55286 7.27772 7.27994 7.54471L6.85868 7.96003C6.79342 8.02529 6.71036 8.05496 6.63323 8.05496C6.54423 8.05496 6.45523 8.01936 6.3959 7.9897C6.02805 7.7939 5.39914 7.25399 4.81176 6.67254C4.23031 6.0911 3.6904 5.46219 3.50054 5.09433C3.47087 5.02907 3.43528 4.94601 3.43528 4.85701C3.43528 4.77988 3.45901 4.70275 3.52427 4.63748L3.94552 4.20437C4.20658 3.93144 4.36677 3.69412 4.36677 3.42119C4.36677 3.2076 4.26591 2.97621 4.07012 2.69735L2.7945 0.899619C2.58684 0.61483 2.29612 0.490234 1.97573 0.490234C1.58414 0.490234 1.18069 0.668228 0.830636 1.00642C0.255124 1.5582 0 2.2405 0 2.99401C0 4.69681 1.29935 6.76154 3.01402 8.47028Z" />
    </svg>
  );
};

export default CallCustomIcon;
