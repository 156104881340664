import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AccessDeniedIcon from '../../assets/icons/AccessDeniedIcon';
import { rootPaths } from '../../routing/rootPaths';

import styles from './accessDenied.module.scss';

const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.cont}>
      <h1>{t('access_denied.Ushbu_sahifaga_sizga_ruhsat_yoq')}</h1>
      <AccessDeniedIcon />
      <div>
        <h2>{t('access_denied.Bosh_sahifaga_qaytish_uchun')}:</h2>
        <Link to={rootPaths.INDEX}>{t('access_denied.Bosh_sahifa')}</Link>
      </div>
    </div>
  );
};

export default AccessDenied;
