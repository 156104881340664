import React, { memo } from 'react';
import styles from './filterDrawer.module.scss';
import { Checkbox, Dropdown } from 'antd';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { LeadDeletedType } from '../../../../../utils/models/LeadDeletedType';
import CheveronBottom from '../../../../../assets/icons/CheveronBottom';
import { useTranslation } from 'react-i18next';

interface Props {
  leadDeletedType: string | undefined;
}

const DeletedType: React.FC<Props> = ({ leadDeletedType }) => {
  const { appendSingle } = useQueryParam();

  const { t } = useTranslation();

  // changePipeStatus
  const changeLeadDeletedType = (value: LeadDeletedType | undefined) => {
    if (leadDeletedType === value) {
      appendSingle('leadDeletedType', undefined);
    } else {
      appendSingle('leadDeletedType', value);
    }
  };

  // handle get title
  const handleTitle = () => {
    if (leadDeletedType === LeadDeletedType.ALL) return t('crm_filter.Hamma_lidlar');
    if (leadDeletedType === LeadDeletedType.DELETED) return t('crm_filter.Faqat_ochirilgan_lidlar');
    return t('crm_filter.Faqat_aktiv_lidlar');
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_reason_dropdown}
      overlay={
        <>
          <div onClick={() => changeLeadDeletedType(undefined)} className={styles.container_reason_dropdown_item}>
            <Checkbox
              checked={leadDeletedType === LeadDeletedType.ACTIVE || !leadDeletedType}
              className={styles.container_reason_dropdown_item_check}
            />
            <span className={styles.container_reason_dropdown_item_name}>{t('crm_filter.Aktivlar')}</span>
          </div>
          <div onClick={() => changeLeadDeletedType(LeadDeletedType.DELETED)} className={styles.container_reason_dropdown_item}>
            <Checkbox checked={leadDeletedType === LeadDeletedType.DELETED} className={styles.container_reason_dropdown_item_check} />
            <span className={styles.container_reason_dropdown_item_name}>{t('crm_filter.Ochirilganlar_lidlar')}</span>
          </div>
          <div onClick={() => changeLeadDeletedType(LeadDeletedType.ALL)} className={styles.container_reason_dropdown_item}>
            <Checkbox checked={leadDeletedType === LeadDeletedType.ALL} className={styles.container_reason_dropdown_item_check} />
            <span className={styles.container_reason_dropdown_item_name}>{t('crm_filter.Barchasi')}</span>
          </div>
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_employee}>
        {handleTitle()}
        <CheveronBottom className={styles.container_employee_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(DeletedType);
