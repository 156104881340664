import React, { FC } from 'react';
import { ContractStatus } from '../../utils/models/ContractStatus';

interface IProps {
  status?: ContractStatus;
}

const ShieldDoneIcon: FC<IProps> = ({ status }) => {
  const obj = {
    STARTED: (
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="49" viewBox="0 0 42 49" fill="none">
        <path
          d="M22.2698 0.738954L38.1996 6.08364C39.9828 6.67906 41.1884 8.31707 41.1981 10.1528L41.2996 26.0903C41.331 30.922 39.5696 35.6077 36.344 39.2791C34.8605 40.9641 32.959 42.4115 30.5307 43.7059L21.9678 48.2834C21.6996 48.4246 21.4048 48.4975 21.1076 48.4999C20.8105 48.5022 20.5133 48.4316 20.2475 48.2928L11.6048 43.8212C9.15001 42.548 7.23157 41.1218 5.73354 39.4603C2.4548 35.8266 0.633006 31.162 0.601596 26.3233L0.500058 10.3975C0.490452 8.55947 1.67438 6.9097 3.44785 6.29074L19.3173 0.755428C20.2596 0.421238 21.3106 0.414178 22.2698 0.738954ZM29.8875 17.8248C29.1748 17.1399 28.0271 17.1446 27.324 17.8389L19.2395 25.8077L15.9293 22.6258C15.2165 21.941 14.0713 21.948 13.3657 22.6423C12.6626 23.3366 12.6699 24.4521 13.3827 25.1369L17.9806 29.5614C18.3382 29.905 18.8021 30.0745 19.266 30.0699C19.7299 30.0674 20.1914 29.8933 20.5442 29.545L29.902 20.3194C30.6051 19.6252 30.5979 18.5096 29.8875 17.8248Z"
          fill="#B8B8B8"
        />
      </svg>
    ),
    ACTIVE: (
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="49" viewBox="0 0 42 49" fill="none">
        <path
          d="M22.2698 0.738954L38.1996 6.08364C39.9828 6.67906 41.1884 8.31707 41.1981 10.1528L41.2996 26.0903C41.331 30.922 39.5696 35.6077 36.344 39.2791C34.8605 40.9641 32.959 42.4115 30.5307 43.7059L21.9678 48.2834C21.6996 48.4246 21.4048 48.4975 21.1076 48.4999C20.8105 48.5022 20.5133 48.4316 20.2475 48.2928L11.6048 43.8212C9.15001 42.548 7.23157 41.1218 5.73354 39.4603C2.4548 35.8266 0.633006 31.162 0.601596 26.3233L0.500058 10.3975C0.490452 8.55947 1.67438 6.9097 3.44785 6.29074L19.3173 0.755428C20.2596 0.421238 21.3106 0.414178 22.2698 0.738954ZM29.8875 17.8248C29.1748 17.1399 28.0271 17.1446 27.324 17.8389L19.2395 25.8077L15.9293 22.6258C15.2165 21.941 14.0713 21.948 13.3657 22.6423C12.6626 23.3366 12.6699 24.4521 13.3827 25.1369L17.9806 29.5614C18.3382 29.905 18.8021 30.0745 19.266 30.0699C19.7299 30.0674 20.1914 29.8933 20.5442 29.545L29.902 20.3194C30.6051 19.6252 30.5979 18.5096 29.8875 17.8248Z"
          fill="#2DD26F"
        />
      </svg>
    ),
    CANCELLED: (
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="48" viewBox="0 0 41 48" fill="none">
        <path
          d="M18.8179 0.248875C19.76 -0.0829585 20.7987 -0.0829585 21.7649 0.248875L37.6834 5.58881C39.471 6.17717 40.6787 7.82693 40.6787 9.6626L40.7998 25.593C40.8237 30.4175 39.0603 35.1008 35.8235 38.7745C34.35 40.469 32.4417 41.9046 30.0261 43.199L21.4509 47.7882C21.1852 47.9294 20.8953 48 20.6054 48C20.3156 48 20.0015 47.9294 19.7358 47.7882L11.0881 43.3167C8.64841 42.0458 6.74011 40.6338 5.24247 38.9628C1.95731 35.3385 0.121479 30.6552 0.0973238 25.8307L0.000345552 9.89559C-0.0234542 8.05991 1.18433 6.41487 2.94768 5.80062L18.8179 0.248875ZM17.127 17.6619C16.4265 16.9794 15.2912 16.9794 14.5665 17.6619C13.866 18.3703 13.866 19.4764 14.5665 20.1589L17.8275 23.3337L14.5665 26.5132C13.866 27.1957 13.866 28.3253 14.5665 29.0078C14.9289 29.3608 15.3878 29.5232 15.8468 29.5232C16.3299 29.5232 16.7888 29.3608 17.127 29.0078L20.388 25.8307L23.649 29.0078C24.0114 29.3608 24.4703 29.5232 24.9293 29.5232C25.3882 29.5232 25.8714 29.3608 26.2095 29.0078C26.91 28.3253 26.91 27.1957 26.2095 26.5132L22.9485 23.3337L26.2095 20.1589C26.91 19.4764 26.91 18.3703 26.2095 17.6619C25.509 16.9794 24.3495 16.9794 23.649 17.6619L20.388 20.8414L17.127 17.6619Z"
          fill="#D42323"
        />
      </svg>
    )
  };
  return obj[status as keyof typeof obj] || null;
};

export default ShieldDoneIcon;
