import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { cx } from '@utils/cx';
import { getLocalStorage } from '@utils/index';
import { Checkbox, Input, Spin } from 'global';
import { useTranslation } from 'react-i18next';

import { usePostPushNotificationConfig } from '../../service/mutation';
import { useGetPushNotificationConfigAll } from '../../service/queries';
import { caseTypes } from '../../utils/helpers/caseTypes';
import { timeOptions } from '../../utils/helpers/timeOptions';

interface IProps {
  time?: number | null;
  created?: boolean;
  times?: (number | string | null | undefined)[];
  id?: number;
}

const TaskBeforeTime = ({ time, created, times, id }: IProps) => {
  const { data: dataPushNotificationsConfigAll } = useGetPushNotificationConfigAll();
  const postPushNotificationConfig = usePostPushNotificationConfig();
  const { t } = useTranslation();
  const [value, setValue] = useState(time);

  useEffect(() => {
    setValue(time);
  }, [time]);
  
  const handleSave = () => {
    const filteredData = [...new Set(times)]?.filter(item => timeOptions?.includes(Number(item))) || [];

    if (value && !times?.includes(value)) {
      filteredData.push(Number(value));
    }

    if (filteredData.length > 0) {
      postPushNotificationConfig.mutate({
        inSiteStatus: dataPushNotificationsConfigAll?.inSiteStatus,
        status: dataPushNotificationsConfigAll?.status,
        firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
        parts: [
          {
            id,
            // @ts-expect-error
            timeIntervals: filteredData,
            type: caseTypes?.BEFORE,
          }
        ],
      });
    }
  };

  return created ? (
    <Spin spinning={postPushNotificationConfig.isLoading} indicator={<LoadingOutlined />}>
      <div
        className={cx(
          'flex cursor-pointer items-center justify-center gap-[5px] rounded-xl border-[1px] border-solid border-[#D0D5DD] px-[10px] py-[5px] font-semibold ',
          times?.includes(time) && 'border-[1px] !border-[#16835b] bg-[#E6F6F4]'
        )}
        onClick={handleSave}
      >
        <span>
          <Checkbox checked={times?.includes(time)} />
        </span>

        <Input width={45} value={value || ''} onChange={setValue} onClick={e => e.stopPropagation()} type="number" returnType="number" onPressEnter={handleSave} />
        <h3 className="m-0 font-medium text-gray text-[12px]">{t('home.minut')}</h3>
        <h3 className="m-0 font-medium text-gray text-[12px]">({t('home.oldin')})</h3>
      </div>
    </Spin>
  ) : (
    <Spin spinning={postPushNotificationConfig.isLoading} indicator={<LoadingOutlined />}>
      <div
        className={cx(
          'flex cursor-pointer items-center justify-center gap-[10px] rounded-xl border-[1px] border-solid border-[#D0D5DD] px-[10px] py-[10px] font-semibold',
          times?.includes(time) && 'border-[1px] !border-[#16835b] bg-[#E6F6F4]'
        )}
        onClick={() => {
          const filteredData = [...new Set(times)]?.filter(item => item !== time) || [];

          if (!times?.includes(time)) {
            filteredData?.push(time);
          }

          postPushNotificationConfig.mutate({
            inSiteStatus: dataPushNotificationsConfigAll?.inSiteStatus,
            status: dataPushNotificationsConfigAll?.status,
            firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
            parts: [
              {
                id,
                // @ts-expect-error
                timeIntervals: filteredData,
                type: caseTypes?.BEFORE,
              }
            ]
          });
        }}
      >
        <span>
          <Checkbox checked={times?.includes(time)} />
        </span>
        <h3 className="m-0 font-medium text-[12px]">
          {time} {t('home.minut')} ({t('home.oldin')})
        </h3>
      </div>
    </Spin>
  );
};

export default TaskBeforeTime;
