import { baseErrorHandler } from '@queries/baseErrorHandler';
import { CrmEmployee } from '@utils/models/OneLid';
import { useQuery } from 'react-query';

import { $api } from '../../../service/RequestService';
import { Pagination } from '../../../utils/models/Pagination';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { ActionTypeModel } from '../utils/models/ActionTypeModel';
import { ActivityLogsModel } from '../utils/models/ActivityLogsModel';
import { ActivityPipesModel } from '../utils/models/ActivityPipesModel';

export function useGetActivityLogs(searchParams: any, size: number) {
  const searchParamsObj = { ...searchParams };

  searchParamsObj.page = Number(searchParams.page) || 1;
  return useQuery<Pagination<ActivityLogsModel[]>>(
    [queryKeys.ACTIVITY_LOGS, searchParams, size],
    async () => {
      const res = await $api.post(endPoints.ACTIVITY_LOGS, {
        ...searchParams,
        previousStatuses: Array.isArray(searchParams?.previousStatuses) ? searchParams?.previousStatuses : [searchParams?.previousStatuses],
        currentStatuses: Array.isArray(searchParams?.currentStatuses) ? searchParams?.currentStatuses : [searchParams?.currentStatuses],
        responsibleByIds: Array.isArray(searchParamsObj.responsibleByIds) ? searchParamsObj.responsibleByIds : [searchParamsObj.responsibleByIds],
        actionTypes: Array.isArray(searchParams?.actionTypes) ? searchParams?.actionTypes : [searchParams?.actionTypes],
        size
      });

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false,
      enabled: !!searchParams
    }
  );
}

export function useGetActivityPipes() {
  return useQuery<Pagination<ActivityPipesModel>>(
    [queryKeys.ACTIVITY_PIPES],
    async () => {
      const res = await $api.get(endPoints.ACTIVITY_PIPES);

      return res.data.data.body;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetActivityLogsEmployee(type: string) {
  return useQuery<CrmEmployee[]>(
    [queryKeys.ACTIVITY_LOGS_EMPLOYEE, type],
    async () => {
      const res = await $api.get(endPoints.ACTIVITY_LOGS_EMPLOYEE);

      return res.data.data;
    },
    {
      enabled: !!type,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetActionTypes() {
  return useQuery<ActionTypeModel>(
    [queryKeys.ACTIVITY_LOGS],
    async () => {
      const res = await $api.get(endPoints.ACTION_TYPE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}
