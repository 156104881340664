import { FC, useState } from 'react';
import SendSquare from '@icons/SendSquare';
import TrushSquareBlackStrokeIcon from '@icons/TrushSquareBlackStrokeIcon';
import { useUploadFile } from '@queries/mutation';
import { useGetImageByImageId } from '@queries/queries';
import { FileObject } from '@utils/models/File';
import { Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface UploadFIleProps {
  fileId: number;
  setFileId: (i: number | any) => void;
  name?: string;
  deleteImage: () => void;
}

const UploadFIle: FC<UploadFIleProps> = ({ setFileId, fileId, name, deleteImage }) => {
  const [showPreview, setShowPreview] = useState(false);
  const { data: ImageId, isLoading } = useGetImageByImageId(fileId);
  const uploadFile = useUploadFile((data: FileObject[]) => {
    setFileId(data[0].id);
  });

  const { t } = useTranslation();

  function onDeleteFile() {
    deleteImage();
    setFileId(null);
  }

  const handleFile = (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();

    if (file && file.size <= 10000000) {
      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  return (
    <Spin spinning={isLoading || uploadFile.isLoading}>
      {ImageId ? (
        <>
          <div className="shaxmatka_drawer_footer_home_plan_img">
            <div>
              <img style={{ cursor: 'pointer' }} onClick={() => setShowPreview(true)} src={URL.createObjectURL(ImageId?.fileBlob)} alt="" />
              <div>
                <h1 title={ImageId.fileObject.originalName}>{ImageId.fileObject.originalName.slice(0, 25)}</h1>
                <p>
                  {ImageId.fileObject.size > 1024
                    ? `${ImageId.fileObject.size / 1000} ${t('shaxmatka.KB')}`
                    : `${ImageId.fileObject.size} ${t('shaxmatka.Bayt')}`}
                </p>
              </div>
            </div>
            <span onClick={onDeleteFile}>
              <TrushSquareBlackStrokeIcon />
            </span>
          </div>
          <Image
            width={200}
            className="hidden"
            src={URL.createObjectURL(ImageId?.fileBlob)}
            preview={{
              visible: showPreview,
              src: URL.createObjectURL(ImageId?.fileBlob),
              onVisibleChange: value => {
                setShowPreview(value);
              }
            }}
          />
        </>
      ) : (
        <>
          <label htmlFor={name} className="shaxmatka_upload_file">
            <SendSquare />
            <div>
              <h1>{t('house_config.Rasm_yuklash')}</h1>
              <p>JPG, PNG</p>
            </div>
            <span>{t('shaxmatka.Yuklash')}</span>
          </label>
          <input onChange={e => handleFile(e)} id={name} type="file" style={{ display: 'none' }} />
        </>
      )}
    </Spin>
  );
};

export default UploadFIle;
