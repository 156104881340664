import React from 'react';

const MinusCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.94694 14.6673C11.6136 14.6673 14.6136 11.6673 14.6136 8.00065C14.6136 4.33398 11.6136 1.33398 7.94694 1.33398C4.28027 1.33398 1.28027 4.33398 1.28027 8.00065C1.28027 11.6673 4.28027 14.6673 7.94694 14.6673Z"
        stroke="#A2A2A2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.28027 8H10.6136" stroke="#A2A2A2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MinusCircle;
