import React from 'react';
import { cx } from '@utils/cx';
import { Collapse as BaseCollapse, CollapsePanelProps as BaseCollapsePanelProps, CollapseProps as BaseCollapseProps } from 'antd';

import cls from './collapse.module.scss';

export interface CollapseProps extends BaseCollapseProps {
  noStyle?: boolean;
}

const Collapse: React.FC<CollapseProps> & { Panel: React.FC<BaseCollapsePanelProps> } = ({ className, noStyle, ...props }) => (
  <BaseCollapse {...props} className={noStyle ? cx(cls.noStyleCollapse, className) : className} />
);

Collapse.Panel = BaseCollapse.Panel;

export default Collapse;
