/* eslint-disable react/no-array-index-key */
/* eslint-disable newline-after-var */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowRightMessage from '@icons/ArrowRightMessage';
import ForcePermissionIcon from '@icons/ForcePermissionIcon';
import ManagePermissionsIcon from '@icons/ManagePermissionsIcon';
import { CrmSettingsPath } from '@routing/crm-settings/CrmSettingsPath';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import { useTranslation } from 'react-i18next';

import styles from './crmSettingsMenu.module.scss';

const CrmSettingsMenu = () => {
  const param = useQueryParam();
  const { t } = useTranslation();

  // console.log(param.splitUrl[param.splitUrl.length - 1], 'test');
  const data = [
    {
      icon: ManagePermissionsIcon,
      text: t('crm.Ruxsatlarni_boshqarish'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.CRM_SETTINGS + CrmSettingsPath.INDEX,
      class: param.splitUrl[param.splitUrl.length - 1] === 'crm-settings' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: ForcePermissionIcon,
      text: t('titles.Majburiy_mijoz'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.CRM_SETTINGS + CrmSettingsPath.FORCE,
      class: param.splitUrl[param.splitUrl.length - 1] === 'force' ? `${styles.active}` : `${styles.navLinkClass}`
    }
  ];
  return (
    <div className={styles.navbar}>
      {data.map((item, index) => (
        <NavLink key={index} to={item.path} className={item.class}>
          <span className="d-flex align-center">
            <item.icon /> &nbsp; &nbsp; {item.text}
          </span>
          <span className={styles.arrowDiv}>
            <ArrowRightMessage />
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default CrmSettingsMenu;
