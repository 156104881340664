import { z } from 'zod';

export const brojectConfig = z.object({
  baseUrl: z.string({ message: '' }).min(1, { message: '' }),
  token: z.string({ message: '' }).min(1, { message: '' }),
  status: z.boolean().nullable().optional(),
  id: z.number().nullable().optional()
});

export interface IBrojectConfig extends z.infer<typeof brojectConfig> {}
