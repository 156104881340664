import React from 'react';

const BuildingsIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1112 17.2778H3.889C2.33344 17.2778 1.55566 16.5 1.55566 14.9445V8.72225C1.55566 7.16669 2.33344 6.38892 3.889 6.38892H7.77789V14.9445C7.77789 16.5 8.55566 17.2778 10.1112 17.2778Z"
        stroke="#006858"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.8631 3.27777C7.80088 3.5111 7.77756 3.76777 7.77756 4.05555V6.38888H3.88867V4.83333C3.88867 3.97777 4.58867 3.27777 5.44423 3.27777H7.8631Z"
        stroke="#006858"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.8887 6.38892V10.2778" stroke="#006858" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 6.38892V10.2778" stroke="#006858" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.222 13.3889H11.6664C11.2387 13.3889 10.8887 13.7389 10.8887 14.1667V17.2778H13.9998V14.1667C13.9998 13.7389 13.6498 13.3889 13.222 13.3889Z"
        stroke="#006858"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.66699 10.2778V13.3889" stroke="#006858" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.77734 14.9445V4.05556C7.77734 2.50001 8.55512 1.72223 10.1107 1.72223H14.7773C16.3329 1.72223 17.1107 2.50001 17.1107 4.05556V14.9445C17.1107 16.5 16.3329 17.2778 14.7773 17.2778H10.1107C8.55512 17.2778 7.77734 16.5 7.77734 14.9445Z"
        stroke="#006858"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BuildingsIcon;
