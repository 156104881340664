import { useMutation, useQueryClient } from 'react-query';
import { showMessage } from '../../../queries/showMessage';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { queryNames } from '../../../utils/constants/queryNames';
import { endPoints } from '../utils/constants/endPoints';

export function useBookingLeadFlatCreate() {
  const qc = useQueryClient();
  return useMutation(
    async (data: { leadId: number; flatId: number[] }) => {
      const flats = data?.flatId?.map(item => 'flatIds=' + item).join('&');

      const res = await $api.post(endPoints.BOOKING_LEAD_FLAT + `?leadId=${data.leadId}&${flats}`);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryNames.BOOKING_LEAD_FLAT]);
      },
      onError: baseErrorHandler
    }
  );
}
