import React from 'react';

const PlusCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.99967 14.6673C11.6663 14.6673 14.6663 11.6673 14.6663 8.00065C14.6663 4.33398 11.6663 1.33398 7.99967 1.33398C4.33301 1.33398 1.33301 4.33398 1.33301 8.00065C1.33301 11.6673 4.33301 14.6673 7.99967 14.6673Z"
        stroke="#A2A2A2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.33301 8H10.6663" stroke="#A2A2A2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 10.6673V5.33398" stroke="#A2A2A2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusCircle;
