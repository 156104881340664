import React from 'react';

const EllipseRightIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" transform="rotate(-90 10 10)" fill="#C2E6FE" />
      <g clipPath="url(#clip0_12656_77928)">
        <path
          d="M11.5195 6.20645L15.3133 10.0002L11.5195 13.7939"
          stroke="#3772FF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4.6875 10L15.2063 10" stroke="#3772FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_12656_77928">
          <rect width="15" height="15" fill="white" transform="translate(2.5 17.5) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EllipseRightIcon;
