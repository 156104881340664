import { BaseEntity } from './BaseEntity';
import { Pagination } from './Pagination';
import { PipeAll, PipeStatuses } from './PipeAll';

export interface OnlinePbx {
  domain: string;
  apiKey: string;
  key?: string;
  keyId?: string;
  id?: 0;
  leadResponsibleAutoChange?: boolean;
  connectError?: boolean | null;
}

export interface OnlinePbxUser {
  employeeId: number;
  employeeName?: string;
  employeePhoneNumber?: string;
  enabled: boolean;
  id: number;
  name: string;
  num: string;
  onlinePbxConfId: number;
  onlinePbxDto?: OnlinePbx;
  allowWebCall?: boolean;
}

export interface SetEmployeeOnlinePbx {
  onlinePbxEmployeeId: number;
  employeeId: number;
  onlinePbxConfigId: number;
  allowWebCall?: boolean;
}

export interface OnlinePbxPipes {
  id: number;
  addNumberTag: boolean;
  autoTask: boolean;
  callTypeTag: boolean;
  failedCall: boolean;
  incomingCall: boolean;
  onlinePbxConfigId: number;
  outComingCall: boolean;
  recordingCall: boolean;
  responsibleUserId: number | undefined;
  pipeDto: PipeAll;
  pipeStatusResponse: PipeStatuses | null;
  onlinePbxContactDtos: OnlinePbxContact[];
  incomingTags: BaseEntity[];
  missingTags: BaseEntity[];
  outgoingTags: BaseEntity[];
  incomingPipeStatus: PipeAll;
  outgoingPipeStatus: PipeAll;
  missingPipeStatus: PipeAll;
  incomingLeadName: string;
  outgoingLeadName: string;
  missingLeadName: string;
}

export interface OnlinePbxContact {
  description: string;
  destination: string;
  gateway: string;
  id: number;
  name: string;
  number: string;
  onlinePbxConfigId: number;
  status: string;
  use_for_call_forwarding: string;
}

export interface OnlinePbxPipeSettingsBody {
  id?: number;
  pipeId: number;
  pipeStatusId?: number;
  incomingCall?: boolean;
  outComingCall?: boolean;
  failedCall?: boolean;
  recordingCall?: boolean;
  callTypeTag?: boolean;
  addNumberTag?: boolean;
  autoTask?: boolean;
  onlinePbxConfigId: number;
  onlinePbxContactIds?: number[];
  responsibleUserId?: number | undefined;
  incomingLeadName?: any;
  incomingPipeStatus?: any;
  missingLeadName?: any;
  missingPipeStatus?: any;
  incomingTags?: string[];
  missingTags?: string[];
  outgoingTags?: string[];
  incomingPipeStatusId?: number;
  outgoingPipeStatusId?: number;
  missingPipeStatusId?: number;
  onlinePbxContactDtos?: string[];
  outgoingLeadName?: any;
  outgoingPipeStatus?: any;
  pipeDto?: {
    id?: number;
    name?: string;
    orders?: number;
    pipeStatuses: {
      id?: number;
      name?: string;
      orders?: number;
      color?: string;
      pipeId?: number;
      pipeName?: string;
      type?: string;
    };
  };
  pipeStatusResponse?: {
    id?: number;
    name?: string;
    orders?: number;
    color?: string;
    pipeId?: number;
    pipeName?: string;
    type?: string;
  };
}

export enum OnlinePbxPipeCheckBoxes {
  incomingCall = 'incomingCall',
  outComingCall = 'outComingCall',
  failedCall = 'failedCall',
  recordingCall = 'recordingCall',
  callTypeTag = 'callTypeTag',
  addNumberTag = 'addNumberTag',
  autoTask = 'autoTask'
}

export enum OnlinePbxHistoryCallStatus {
  inbound = 'inbound',
  outbound = 'outbound',
  local = 'local',
  missed = 'missed',
  contacted = 'contacted',
  not_answered = 'not_answered',
  not_contacted = 'not_contacted'
}

export enum OnlinePbhHistoryCallSubStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  NO_ROUTE_TRANSIT_NET = 'NO_ROUTE_TRANSIT_NET',
  CHANNEL_UNACCEPTABLE = 'CHANNEL_UNACCEPTABLE',
  CALL_AWARDED_DELIVERED = 'CALL_AWARDED_DELIVERED',
  NO_USER_RESPONSE = 'NO_USER_RESPONSE',
  NO_ANSWER = 'NO_ANSWER',
  SUBSCRIBER_ABSENT = 'SUBSCRIBER_ABSENT',
  NUMBER_CHANGED = 'NUMBER_CHANGED',
  REDIRECTION_TO_NEW_DESTINATION = 'REDIRECTION_TO_NEW_DESTINATION',
  EXCHANGE_ROUTING_ERROR = 'EXCHANGE_ROUTING_ERROR',
  DESTINATION_OUT_OF_ORDER = 'DESTINATION_OUT_OF_ORDER',
  INVALID_NUMBER_FORMAT = 'INVALID_NUMBER_FORMAT',
  FACILITY_REJECTED = 'FACILITY_REJECTED',
  RESPONSE_TO_STATUS_ENQUIRY = 'RESPONSE_TO_STATUS_ENQUIRY',
  NORMAL_UNSPECIFIED = 'NORMAL_UNSPECIFIED',
  NORMAL_CIRCUIT_CONGESTION = 'NORMAL_CIRCUIT_CONGESTION',
  NORMAL_TEMPORARY_FAILURE = 'NORMAL_TEMPORARY_FAILURE',
  ORIGINATOR_CANCEL = 'ORIGINATOR_CANCEL',
  NORMAL_CLEARING = 'NORMAL_CLEARING',
  USER_BUSY = 'USER_BUSY',
  UNALLOCATED_NUMBER = 'UNALLOCATED_NUMBER',
  REQUESTED_CHAN_UNAVAIL = 'REQUESTED_CHAN_UNAVAIL',
  CALL_REJECTED = 'CALL_REJECTED',
  NO_ROUTE_DESTINATION = 'NO_ROUTE_DESTINATION',
  NETWORK_OUT_OF_ORDER = 'NETWORK_OUT_OF_ORDER',
  SWITCH_CONGESTION = 'SWITCH_CONGESTION',
  ACCESS_INFO_DISCARDED = 'ACCESS_INFO_DISCARDED',
  PRE_EMPTED = 'PRE_EMPTED',
  FACILITY_NOT_SUBSCRIBED = 'FACILITY_NOT_SUBSCRIBED',
  OUTGOING_CALL_BARRED = 'OUTGOING_CALL_BARRED',
  INCOMING_CALL_BARRED = 'INCOMING_CALL_BARRED',
  BEARERCAPABILITY_NOTAUTH = 'BEARERCAPABILITY_NOTAUTH',
  BEARERCAPABILITY_NOTAVAIL = 'BEARERCAPABILITY_NOTAVAIL',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  BEARERCAPABILITY_NOTIMPL = 'BEARERCAPABILITY_NOTIMPL',
  CHAN_NOT_IMPLEMENTED = 'CHAN_NOT_IMPLEMENTED',
  FACILITY_NOT_IMPLEMENTED = 'FACILITY_NOT_IMPLEMENTED',
  SERVICE_NOT_IMPLEMENTED = 'SERVICE_NOT_IMPLEMENTED',
  INVALID_CALL_REFERENCE = 'INVALID_CALL_REFERENCE',
  INCOMPATIBLE_DESTINATION = 'INCOMPATIBLE_DESTINATION',
  INVALID_MSG_UNSPECIFIED = 'INVALID_MSG_UNSPECIFIED',
  MANDATORY_IE_MISSING = 'MANDATORY_IE_MISSING',
  MESSAGE_TYPE_NONEXIST = 'MESSAGE_TYPE_NONEXIST',
  WRONG_MESSAGE = 'WRONG_MESSAGE',
  IE_NONEXIST = 'IE_NONEXIST',
  INVALID_IE_CONTENTS = 'INVALID_IE_CONTENTS',
  WRONG_CALL_STATE = 'WRONG_CALL_STATE',
  RECOVERY_ON_TIMER_EXPIRE = 'RECOVERY_ON_TIMER_EXPIRE',
  MANDATORY_IE_LENGTH_ERROR = 'MANDATORY_IE_LENGTH_ERROR',
  PROTOCOL_ERROR = 'PROTOCOL_ERROR',
  INTERWORKING = 'INTERWORKING',
  CRASH = 'CRASH',
  SYSTEM_SHUTDOWN = 'SYSTEM_SHUTDOWN',
  LOSE_RACE = 'LOSE_RACE',
  MANAGER_REQUEST = 'MANAGER_REQUEST',
  BLIND_TRANSFER = 'BLIND_TRANSFER',
  ATTENDED_TRANSFER = 'ATTENDED_TRANSFER',
  ALLOTTED_TIMEOUT = 'ALLOTTED_TIMEOUT',
  USER_CHALLENGE = 'USER_CHALLENGE',
  MEDIA_TIMEOUT = 'MEDIA_TIMEOUT',
  PICKED_OFF = 'PICKED_OFF',
  USER_NOT_REGISTERED = 'USER_NOT_REGISTERED',
  PROGRESS_TIMEOUT = 'PROGRESS_TIMEOUT',
  ANSWER = 'ANSWER',
  BUSY = 'BUSY',
  NOANSWER = 'NOANSWER',
  CANCEL = 'CANCEL',
  CONGESTION = 'CONGESTION',
  CHANUNAVAIL = 'CHANUNAVAIL '
}

export interface OnlinePbxHistoryCallResponse {
  callHistoryResponses: Pagination<OnlinePbxHistoryCall>;
  onlinePbxEmployeeDto: OnlinePbxUser;
  sipuniEmployee: SipuniEmployee;
}

export interface OnlinePbxHistoryCall {
  uuid: string;
  caller_id_name: string;
  caller_id_number: number;
  destination_number: number;
  from_host: string;
  to_host: string;
  start_stamp: number;
  end_stamp: number;
  duration: number;
  user_talk_time: number;
  hangup_cause: OnlinePbhHistoryCallSubStatus;
  accountcode: OnlinePbxHistoryCallStatus;
  gateway: string;
  employeeAvatar: string;
  employeeName: string;
  employeeNum: string;
  leadName: string;
  leadId: number;
  contactName: string;
  contactNumber: string;
  callHistoryType: keyof typeof CallType;
}
export enum CallType {
  SIPUNI = 'SIPUNI',
  ONLINEPBX = 'ONLINEPBX'
}

export interface SipuniEmployee {
  employeeId: number;
  employeeName: string;
  employeePhoneNumber: string;
  id: number;
  num: string;
  sipuniConfDto: SipuniConfDto;
}

export interface SipuniConfDto {
  id: number;
  secret: string;
  token: string;
  userNumber: string;
}
