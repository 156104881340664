import React, { useState } from 'react';
import FilterIcon from '@icons/FilterIcon';
import { Input, Table } from 'antd';
import SearchIcon from '@icons/SearchIcon';
import MecanicKeyIcon from '@icons/MecanicKeyIcon';
import CrmSettingsTable from './components/crm-settings-table/CrmSettingsTable';

import styles from './crmSettings.module.scss';
import RoleModal from './components/role-modal/RoleModal';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '@hooks/useQueryParams';
import FilterDrawer from './components/filter-drawer/FilterDrawer';

const CrmSettings = () => {
  const { t } = useTranslation();
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openFiltr, setOpenFiltr] = useState(false);
  const { searchParams, appendSingle, remove } = useQueryParam<any, any>();
  const [openDeleteRole, setOpenDeleteRole] = useState(false);
  const [roleId, setRoleId] = useState<number>();

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  //handle open role modal
  const handleOpenRoleModal = () => {
    setOpenRoleModal(true);
  };

  //handle open filter drawer
  const handleOpenDrawer = () => {
    setOpenFiltr(true);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header_left}>
          {/* <div className={styles.header_left_filter} onClick={handleOpenDrawer}>
            <FilterIcon />
            <span>Filter</span>
          </div> */}
          <div className={styles.header_left_search}>
            <Input
              value={searchParams.search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('home.Qidirish')}
              addonAfter={<SearchIcon />}
            />
          </div>
        </div>
        <div className={styles.header_right} onClick={handleOpenRoleModal}>
          <MecanicKeyIcon />
          <span>{t('home.Rol_sozlamasi')}</span>
        </div>
      </div>
      <CrmSettingsTable openDeleteRole={openDeleteRole} setOpenDeleteRole={setOpenDeleteRole} roleId={roleId} setRoleId={setRoleId} />
      <RoleModal
        openRoleModal={openRoleModal}
        setOpenRoleModal={setOpenRoleModal}
        openDeleteRole={openDeleteRole}
        setOpenDeleteRole={setOpenDeleteRole}
        roleId={roleId}
        setRoleId={setRoleId}
      />
      <FilterDrawer openFiltr={openFiltr} setOpenFiltr={setOpenFiltr} />
    </div>
  );
};

export default CrmSettings;
