import React, { ReactNode, cloneElement, useRef, useState } from 'react';

interface IProps {
  children: JSX.Element;
}

const CustomeDraggable = ({ children }: IProps) => {
  const [position, setPosition] = useState({
    startY: 0,
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isDown: false
  });
  const bodyRef = useRef<any>(null);

  const bodymouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (position.isDown && bodyRef.current) {
      e.preventDefault();
      const y = e.pageY - bodyRef.current.offsetTop;
      const walkY = y - position.startY;
      bodyRef.current.scrollTop = position.scrollTop - walkY;

      const x = e.pageX - bodyRef.current.offsetLeft;
      const walkX = x - position.startX;
      bodyRef.current.scrollLeft = position.scrollLeft - walkX;
    }
  };
  const bodymouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition(prev => ({
      ...prev,
      isDown: false
    }));
  };
  const bodymouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (bodyRef.current) {
      setPosition({
        isDown: true,
        startY: e.pageY - bodyRef.current.offsetTop,
        startX: e.pageX - bodyRef.current.offsetLeft,
        scrollTop: bodyRef.current.scrollTop,
        scrollLeft: bodyRef.current.scrollLeft
      });
    }
  };
  const bodymouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setPosition(prev => ({
      ...prev,
      isDown: false
    }));
  };
  return cloneElement(children, {
    onMouseMove: bodymouseMove,
    onMouseUp: bodymouseUp,
    onMouseDown: bodymouseDown,
    onMouseLeave: bodymouseLeave,
    ref: bodyRef
  });
};

export default CustomeDraggable;
