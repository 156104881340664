import React from 'react';
import { Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import { DownloadPdf } from '../../../../../utils/models/DownloadPdf';
import { FlatMore } from '../../../../../utils/models/FlatMore';
import CloseIcon from '../assets/icons/CloseIcon';
import SaveIcon from '../assets/icons/SaveIcon';
import ViewDoubleIcon from '../assets/icons/ViewDoubleIcon';
import ViewIcon from '../assets/icons/ViewIcon';

import styles from './navbar.module.scss';

const { Option } = Select;

interface IProps {
  handleCancel: () => void;
  setTarget: React.Dispatch<React.SetStateAction<string[]>>;
  target: string[];
  contractPdf: () => void;
  currentIndex: number;
  setSendImages: React.Dispatch<React.SetStateAction<number[][]>>;
  flats: FlatMore[];
  sendImages: number[][];
  sendDownloadPdf: UseMutationResult<any, unknown, DownloadPdf[] | undefined, unknown>;
}

const Navbar: React.FC<IProps> = ({ handleCancel, target, setTarget, contractPdf, currentIndex, setSendImages, flats, sendImages, sendDownloadPdf }) => {
  const { t } = useTranslation();
  const targetSelect = target[currentIndex];

  return (
    <div className={styles.navbar}>
      <div className={styles.navbar_views}>
        <div className={styles.navbar_views_child}>
          {targetSelect === '2' || '' ? <ViewDoubleIcon /> : <ViewIcon />}
          <Select
            placeholder="Ko'rinishi"
            value={target[currentIndex]}
            onChange={e => {
              setTarget(prev => [...prev.map((item, i) => (i === currentIndex ? e : item))]);
              const arr = [...sendImages];

              arr[currentIndex] =
                e === '1'
                  ? [flats[currentIndex]?.flat?.gallery[0]?.id!]!
                  : [flats[currentIndex]?.flat?.gallery[0]?.id!, flats[currentIndex]?.flat?.gallery[1]?.id! || flats[currentIndex]?.flat?.gallery[0]?.id!]!;
              setSendImages(arr);
            }}
          >
            <Option key="1">{t(`home_id.Bitta_korinishli`)}</Option>
            <Option key="2">{t(`home_id.Ikkita_korinishli`)}</Option>
          </Select>
        </div>
        <Button loading={sendDownloadPdf.isLoading} className={styles.navbar_views_child} onClick={contractPdf}>
          <SaveIcon />
          <p>{t(`home_id.Yuklab_olish`)}</p>
        </Button>
      </div>
      <div className={styles.navbar_close}>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={handleCancel} />
      </div>
    </div>
  );
};

export default Navbar;
