import { useState } from 'react';
import { useGetAnalyticCountOwnOther } from '@pages/boss/bi-analytics/services/queries';
import { Card, Empty, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { createArrayOfMonths } from '../../utils/helpers/customArrayOfMonths';
import { DateRange } from '../../utils/models/DateRange';
import FilteringYearPicker from '../filteringYearPicker/FilteringYearPicker';

import NumberOfPurchaseBar from './components/numberOfPurchaseBar/NumberOfPurchaseBar';

import styles from './numberOfPurchase.module.scss';

const NumberOfPurchase = () => {
  const { t } = useTranslation();
  const [numberOfPurchaseBarChartDateRange, setNumberOfPurchaseBarChartDateRange] = useState<DateRange>({
    startDate: dayjs(new Date()).format('YYYY'),
    endDate: dayjs(new Date()).format('YYYY')
  });
  const { data: salesData, isFetching } = useGetAnalyticCountOwnOther(
    numberOfPurchaseBarChartDateRange.startDate,
    numberOfPurchaseBarChartDateRange.endDate
  );
  const arrayOfMonths = createArrayOfMonths(numberOfPurchaseBarChartDateRange.startDate);

  return (
    <Card
      title={t('bi_analytics.Sotuvlar_soni')}
      bodyStyle={{ padding: 0 }}
      extra={<FilteringYearPicker setChartsFilteringStartDateAndEndDate={setNumberOfPurchaseBarChartDateRange} />}
      className={styles.purchase_card}
    >
      <Spin spinning={isFetching}>
        {salesData && salesData?.length > 0 ? <NumberOfPurchaseBar sales={salesData || []} arrayOfMonths={arrayOfMonths} /> : <Empty />}
      </Spin>
    </Card>
  );
};

export default NumberOfPurchase;
