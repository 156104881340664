import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useQueryParam } from '@hooks/useQueryParams';
import AlignLeft02Icon from '@icons/AlignLeft02Icon';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import CubeIcon from '@icons/CubeIcon';
import Element3Icon from '@icons/Element3Icon';
import ListIcon from '@icons/ListIcon';
import MiniMenuIcon from '@icons/MiniMenuIcon';
import { showroomPath } from '@pages/showroom-v2/utils/constants/showroomPath';
import { FlatNumberEnum } from '@reducers/AllHouseFilter';
import { rootPaths } from '@routing/rootPaths';
import { LocalStorage } from '@service/LocalStorage';
import { Dropdown, Tabs } from 'antd';
import { tableUtils, useTable, useTableKay } from 'apis';
import { useTranslation } from 'react-i18next';
import { SHOWROOM_ACTIVE_PATH } from 'utils';

import ShowroomV2MenuList from './components/showroom-v2-menu-list/ShowroomV2MenuList';
import ShowroomV2MenuShaxmatka from './components/showroom-v2-menu-shaxmatka/ShowroomV2MenuShaxmatka';

import styles from './showroomV2Menu.module.scss';

const ShowroomV2Menu = () => {
  const { t } = useTranslation();
  const menu = [
    {
      name: t('one_lid.Royxat'),
      url: rootPaths.SHOWROOM + showroomPath.LIST,
      icon: ListIcon,
      iconName: 'list'
    },
    {
      name: t('showroom.Shaxmatka'),
      url: rootPaths.SHOWROOM + showroomPath.SHAXMATKA,
      icon: Element3Icon,
      iconName: 'shaxmatka'
    },
    {
      name: `${t('showroom.Shaxmatka')} +`,
      url: rootPaths.SHOWROOM + showroomPath.SHAXMATKA_PLUS,
      icon: AlignLeft02Icon,
      iconName: 'shaxmatka_plus'
    },
    {
      name: t('showroom.Visual_kurish'),
      url: rootPaths.SHOWROOM + showroomPath.VISUAL,
      icon: CubeIcon,
      iconName: 'visual'
    }
  ];
  const { push, searchParamsString, location } = useQueryParam();
  const activeUrl = location.pathname.split('/').slice(0, 3).join('/');

  const { data: flatView } = useTableKay(tableUtils.keys.SHOWROOM_FLAT_VIEW, 'string', activeUrl === rootPaths.SHOWROOM + showroomPath.SHAXMATKA);
  const saveFlatView = useTable(tableUtils.keys.SHOWROOM_FLAT_VIEW, 'string');

  const handleKey = (activeKey: string) => {
    LocalStorage.set(SHOWROOM_ACTIVE_PATH, activeKey);
    push(activeKey + searchParamsString);
  };

  const handleFlatNumber = (e: keyof typeof FlatNumberEnum) => {
    saveFlatView.mutate({ key: 'SHOWROOM_FLAT_VIEW', value: e });
  };

  const leftRender = {
    [rootPaths.SHOWROOM + showroomPath.LIST]: <ShowroomV2MenuList />,
    [rootPaths.SHOWROOM + showroomPath.SHAXMATKA]: (
      <>
        <ShowroomV2MenuShaxmatka />
        <Dropdown
          overlay={
            <div className={styles.miniMenu_dropdown}>
              <p onClick={() => handleFlatNumber('number')}>
                {t('home.Xonadon_raqami')} {flatView === 'number' && <CheckmarkIcon fill="#00A389" />}{' '}
                {saveFlatView.isLoading && saveFlatView.variables?.value === 'number' ? <LoadingOutlined /> : <></>}
              </p>
              <p onClick={() => handleFlatNumber('rooms')}>
                {t('showroom.Xonalar_soni')} {flatView === 'rooms' && <CheckmarkIcon fill="#00A389" />}{' '}
                {saveFlatView.isLoading && saveFlatView.variables?.value === 'rooms' ? <LoadingOutlined /> : <></>}
              </p>
              <p onClick={() => handleFlatNumber('floor')}>
                {t('showroom.Qavatdagi_orni')} {flatView === 'floor' && <CheckmarkIcon fill="#00A389" />}{' '}
                {saveFlatView.isLoading && saveFlatView.variables?.value === 'floor' ? <LoadingOutlined /> : <></>}
              </p>
            </div>
          }
          trigger={['click', 'hover']}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.5}
        >
          <div className={styles.miniMenu}>
            <MiniMenuIcon />
          </div>
        </Dropdown>
      </>
    ),
    [rootPaths.SHOWROOM + showroomPath.SHAXMATKA_PLUS]: <ShowroomV2MenuShaxmatka />
  };

  return (
    <div className={styles.container}>
      <Tabs onChange={handleKey} activeKey={activeUrl}>
        {menu.map(item => (
          <Tabs.TabPane
            tab={
              <span className={styles.item} onClick={() => handleKey(item.url)}>
                <item.icon fill="#667085" className={item.iconName} /> {item?.name}
              </span>
            }
            key={item.url}
          />
        ))}
      </Tabs>
      <div className={styles.left}>{leftRender[activeUrl as keyof typeof leftRender]}</div>
    </div>
  );
};

export default ShowroomV2Menu;
