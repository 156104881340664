import React from 'react';
import styles from './passwordValidationPopover.module.scss';
import { Dropdown } from 'antd';
import TickCircleIcon from '../../assets/icons/TickCircleIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  length: boolean;
  letters: boolean;
  beginUppercase: boolean;
  number: boolean;
}

const PasswordValidationPopover: React.FC<Props> = ({ beginUppercase, letters, number, length, children }) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      placement={'top'}
      overlay={
        <>
          <p className={styles.container_title}>{t('profile.Ishonchli_parol_uchun_talablar')}:</p>
          <div className={length ? styles.container_include : styles.container_not_include}>
            <TickCircleIcon />
            <span>{t('profile.8_va_undan_kop_belgilar')}</span>
          </div>
          <div className={letters ? styles.container_include : styles.container_not_include}>
            <TickCircleIcon />
            <span>{t('profile.Katta_kichik_harflardan_foydalanish')}</span>
          </div>
          <div className={beginUppercase ? styles.container_include : styles.container_not_include}>
            <TickCircleIcon />
            <span>{t('profile.Birinchi_belgi_katta_harfda_yozilishi')}</span>
          </div>
          <div className={number ? styles.container_include : styles.container_not_include}>
            <TickCircleIcon />
            <span>{t('profile.Raqam_yozish')}</span>
          </div>
        </>
      }
      overlayClassName={styles.container}
      trigger={['hover']}
    >
      {children}
    </Dropdown>
  );
};

export default PasswordValidationPopover;
