import { CurrencyItem } from './Currency';

export interface KpiResponse {
  id: number;
  argumentKpi: ArgumentKpi;
  status: 'BLOCK' | 'ACTIVE';
  type: TypeKpi;
  kpiPartList: KpiPart[];
}

export interface KpiItem {
  id: number;
  argumentKpi: ArgumentKpi;
  status: 'BLOCK' | 'ACTIVE';
  type: TypeKpi;
  costKpi: KpiPart;
  kpiPartList: KpiPart[];
}

export interface Kpi {
  DYNAMIC: KpiItem[];
  STATIC: KpiItem[];
}

export enum ArgumentKpi {
  CONTRACT_PAYMENT_AMOUNT = 'CONTRACT_PAYMENT_AMOUNT',
  PRE_PAYMENT_AMOUNT = 'PRE_PAYMENT_AMOUNT',
  CONSTANT_PAYMENT_AMOUNT = 'CONSTANT_PAYMENT_AMOUNT',
  CONTRACT_AMOUNT_WITH_DISCOUNT = 'CONTRACT_AMOUNT_WITH_DISCOUNT',
  FLAT_AREA = 'FLAT_AREA'
}

export enum TypeKpi {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC'
}

export enum StatusKpi {
  BLOCK = 'BLOCK',
  ACTIVE = 'ACTIVE'
}

export interface KpiPart {
  id?: number;
  count?: number;
  area?: number;
  amount: number;
  kpiId: number;
  currencyId?: number | undefined;
  currencyDto?: CurrencyItem;
}
