import React, { useState } from 'react';
import CloseIcon from '@icons/CloseIcon';
import { Button, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

import AddCircleIcon from '../../../../assets/icons/AddCircleIcon';
import CreateCostModal from '../createCostModal/CreateCostModal';
import TableCost from '../tableCost/TableCost';

import styles from './costDrawer.module.scss';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
}

const CostDrawer: React.FC<Props> = ({ onClose, visible }) => {
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState(false);

  const onOpen = (visible: boolean) => {
    setOpenAddModal(visible);
  };

  const onCancel = () => {
    onClose(false);
  };

  return (
    <>
      <Drawer width={743} headerStyle={{ display: 'none' }} placement="right" onClose={onCancel} visible={visible}>
        <div className={styles.container}>
          <div className={styles.container_header}>
            <div className={styles.container_header_left}>
              <span>{t('boss_crm.Xarajatlar')}</span>
            </div>
            <CloseIcon onClick={onCancel} />
          </div>
          <div className="d-flex align-center">
            <Button onClick={() => onOpen(true)} className={styles.container_button} type="primary">
              <AddCircleIcon />
              <span>{t('boss_crm.Xarajat_qoshish')}</span>
            </Button>
          </div>
          <TableCost />
        </div>
        <CreateCostModal visible={openAddModal} close={onOpen} />
      </Drawer>
    </>
  );
};

export default CostDrawer;
