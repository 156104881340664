import DotIcon from "@icons/contract/DotIcon";
import { cx } from "@utils/cx";
import { useTranslation } from "react-i18next";

import styles from "./paymentTypes.module.scss";

interface PaymentTypesProps {
  type: string;
}

const PaymentTypes: React.FC<PaymentTypesProps> = ({ type }) => {
  const { t } = useTranslation();

  const paymentTypesMap: Record<string, JSX.Element> = {
    CASH: (
      <span className={styles.paymentType}>
        <DotIcon color="#12B76A" /> {t("home.CASH")}
      </span>
    ),
    BANK: (
      <span className={cx(styles.paymentType, "!text-[#FFBB54] !bg-[#FFFFE0]")}>
        <DotIcon color="#FFBB54" /> {t("home.BANK")}
      </span>
    ),
    BALANCE: (
      <span className={cx(styles.paymentType, "!text-[#3772FF] !bg-[#ECFDF3]")}>
        <DotIcon color="#3772FF" /> {t("home.BALANCE")}
      </span>
    ),
    CARD: (
      <span className={cx(styles.paymentType, "!text-[#FFBB54] !bg-[#FFFFE0]")}>
        <DotIcon color="#FFBB54" /> {t("home.CARD")}
      </span>
    ),
    UZUM: (
      <span className={cx(styles.paymentType, "!text-[#FFBB54] !bg-[#FFFFE0]")}>
        <DotIcon color="#FFBB54" /> {t("home.UZUM")}
      </span>
    ),
    PAYME: (
      <span className={cx(styles.paymentType, "!text-[#3772FF] !bg-[#ECFDF3]")}>
        <DotIcon color="#3772FF" /> {t("home.PAYME")}
      </span>
    ),
    MY_UZCARD: (
      <span className={cx(styles.paymentType, "!text-[#FFBB54] !bg-[#FFFFE0]")}>
        <DotIcon color="#FFBB54" /> {t("home.MY_UZCARD")}
      </span>
    ),
    TRANSFER: (
      <span className={cx(styles.paymentType)}>
        <DotIcon color="#12B76A" /> {t("home.TRANSFER")}
      </span>
    ),
    OTHERS: (
      <span className={cx(styles.paymentType, "!text-[#6927DA] !bg-[#F5F3FF]")}>
        <DotIcon color="#6927DA" /> {t("home.OTHERS")}
      </span>
    ),
  };

  return paymentTypesMap[type] || null;
}

export default PaymentTypes
