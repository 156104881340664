import { ResponsivePie } from '@nivo/pie';
import { colors } from '@pages/bossCrm/components/conversion/constants/colors';
import { ThirdPieChartData } from '@pages/bossCrm/utils/models/ThirdPieChartData';
import { isNumber } from '@utils/helper/isNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './rightPieChart.module.scss';

type Props = {
  pieChartData: ThirdPieChartData;
};
const RightPieChart: React.FC<Props> = ({ pieChartData }) => {
  const { t } = useTranslation();

  const others =
    pieChartData?.fields!?.length > 5
      ? pieChartData?.fields?.slice(5, pieChartData?.fields?.length).map(el => ({
          name: el?.value ? el?.value : t('boss_crm_conversion.Tagsiz'),
          value: el?.count,
          color: colors[0]
        }))
      : [];
  const actualData: any =
    pieChartData?.fields!?.length > 0
      ? pieChartData?.fields!?.slice(0, 5)?.map((el, i) => ({
          id: el?.value ? el?.value : t('boss_crm_conversion.Tagsiz'),
          value: el.count,
          color: colors[i]
        }))
      : [
          {
            value: true,
            label: '',
            color: colors[0]
          }
        ];

  const dataWithOthers =
    others!?.length > 0
      ? [
          ...actualData!,
          {
            id: t('boss_crm_conversion.Others'),
            value: others?.reduce((p, c) => p! + c?.value!, 0),
            color: 'red'
          }
        ]
      : actualData;

  return (
    <div className={styles.s_row_right_chart_container}>
      <ResponsivePie
        data={dataWithOthers || []}
        margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
        innerRadius={0.66}
        padAngle={0.7}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]]
        }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={{ datum: 'data.color' }}
        tooltip={e => {
          let { datum: t } = e;
          return (
            <div className={styles.tooltip_container}>
              <span className={styles.tooltip_color_con} style={{ backgroundColor: t.color, border: `1px solid ${t.color}` }} />
              {t.label !== '' && <span>{t.label}</span>} : <span style={{ fontWeight: '700' }}>{!isNumber(`${t.value}`) ? 0 : t.value}</span>
            </div>
          );
        }}
      />
      {pieChartData?.overall! > -1 && (
        <div className={styles.chart_total_amount}>
          <h1>{pieChartData?.overall}</h1>
          <span>{t('boss_crm_conversion.Jami')}</span>
        </div>
      )}
    </div>
  );
};

export default RightPieChart;
