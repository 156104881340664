import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import dayjs from 'dayjs';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useCreateLeadTask } from '@queries/mutation';
import { useEmployeeTask } from '@queries/queries';
import { useQueryClient } from 'react-query';
import { queryNames } from '@utils/constants/queryNames';
import { TaskFilter } from '@utils/models/TaskFilter';
import { Pagination } from '@utils/models/Pagination';
import { hoverProps } from '../TaskContainer';

import styles from './dndTaskContainer.module.scss';
import DndTaskColumn from './DndTaskColumn';

type Props = {
  setDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setDraggingId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCurrentData: React.Dispatch<React.SetStateAction<TaskFilter | undefined>>;
  hower: hoverProps;
};

export const assigmentObj: any = {
  Otib_ketgan: {
    startDate: null,
    finishDate: dayjs().add(-1, 'day').hour(23).minute(59).format(DATE_WITH_TIME_FORMAT)
  },
  Bugungi: {
    startDate: dayjs().hour(0).minute(0).format(DATE_WITH_TIME_FORMAT),
    finishDate: dayjs().hour(23).minute(59).format(DATE_WITH_TIME_FORMAT)
  },
  Ertangi: {
    startDate: dayjs().add(1, 'day').hour(0).minute(0).format(DATE_WITH_TIME_FORMAT),
    finishDate: dayjs().add(1, 'day').hour(23).minute(59).format(DATE_WITH_TIME_FORMAT)
  },
  Rejada: {
    startDate: dayjs().add(2, 'day').hour(0).minute(0).format(DATE_WITH_TIME_FORMAT),
    finishDate: null
  }
};

const DndTaskContainer: React.FC<Props> = ({ setDragging, setDraggingId, setCurrentData, hower }) => {
  const { data } = useEmployeeTask();
  const { searchParams, params } = useQueryParam<SearchParamsTask, null>();
  const leadSave = useCreateLeadTask(Number(params.id));
  const qc = useQueryClient();
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    const sourceTaskData = qc.getQueryData<{
      size: number;
      pages: Pagination<TaskFilter>[];
      searchParams: SearchParamsTask;
      startDate: string | null;
      finishDate: string | null;
    }>([
      queryNames.TASK_FILTER_CRM,
      20,
      JSON.stringify(searchParams),
      assigmentObj[source.droppableId]?.startDate,
      assigmentObj[source.droppableId]?.finishDate
    ]);

    if (destination.droppableId !== source.droppableId && destination.droppableId !== 'Otib_ketgan') {
      const destinationTaskData = qc.getQueryData<{
        size: number;
        pages: Pagination<TaskFilter>[];
        searchParams: SearchParamsTask;
        startDate: string | null;
        finishDate: string | null;
      }>([
        queryNames.TASK_FILTER_CRM,
        20,
        JSON.stringify(searchParams),
        assigmentObj[destination.droppableId]?.startDate,
        assigmentObj[destination.droppableId]?.finishDate
      ]);

      const sourceTaskDataCopy = sourceTaskData?.pages?.map(item => item.data)?.flat(1);
      const destinationTaskDataCopy = destinationTaskData?.pages?.map(item => item.data)?.flat(1);
      const sourceTask = sourceTaskDataCopy?.find(item => item?.id === Number(draggableId));

      sourceTaskDataCopy?.splice(source.index, 1);

      qc.setQueryData<any>(
        [
          queryNames.TASK_FILTER_CRM,
          20,
          JSON.stringify(searchParams),
          assigmentObj[source.droppableId]?.startDate,
          assigmentObj[source.droppableId]?.finishDate
        ],
        (e: any) => {
          return {
            ...sourceTaskData,
            pages: [
              {
                currentPage: sourceTaskData?.pages[0].currentPage,
                data: sourceTaskDataCopy,
                totalElements: sourceTaskData?.pages[0].totalElements,
                totalPages: sourceTaskData?.pages[0].totalPages
              }
            ]
          };
        }
      );
      destinationTaskDataCopy?.splice(destination.index, 0, sourceTask!);
      qc.setQueryData<any>(
        [
          queryNames.TASK_FILTER_CRM,
          20,
          JSON.stringify(searchParams),
          assigmentObj[destination.droppableId]?.startDate,
          assigmentObj[destination.droppableId]?.finishDate
        ],
        (e: any) => {
          return {
            ...destinationTaskData,
            pages: [
              {
                currentPage: destinationTaskData?.pages[0].currentPage,
                data: destinationTaskDataCopy,
                totalElements: destinationTaskData?.pages[0].totalElements,
                totalPages: destinationTaskData?.pages[0].totalPages
              }
            ]
          };
        }
      );

      const assignedById = data?.find(item => item?.fullName === sourceTask?.assignedBy)?.id;

      const status = result.destination?.droppableId;
      const status1 = result.source?.droppableId;
      const startDate =
        status === 'Ertangi'
          ? String(`${dayjs().add(1, 'day').format(DATE_FORMAT)} 00:00`)
          : status === 'Bugungi'
            ? String(`${dayjs().format(DATE_FORMAT)} 00:00`)
            : status === 'Rejada'
              ? String(`${dayjs().add(2, 'day').format(DATE_FORMAT)} 00:00`)
              : '';

      const endDate =
        status === 'Ertangi'
          ? String(`${dayjs().add(1, 'day').format(DATE_FORMAT)} 23:59`)
          : status === 'Bugungi'
            ? String(`${dayjs().format(DATE_FORMAT)} 23:59`)
            : status === 'Rejada'
              ? String(`${dayjs().add(2, 'day').format(DATE_FORMAT)} 23:59`)
              : '';

      status && status !== 'Otib_ketgan' && status !== status1 && sourceTask?.leadId
        ? leadSave
            .mutateAsync({
              assignedById: assignedById!,
              deadlineStart: startDate,
              deadlineFinish: endDate,
              leadId: sourceTask?.leadId,
              leadTaskTypeId: sourceTask?.leadTaskType?.id!,
              text: sourceTask?.text!,
              id: sourceTask?.id,
              contractId: sourceTask?.contractId
            })
            .then(() => qc.invalidateQueries({ queryKey: [queryNames.TASK_FILTER_CRM] }))
        : leadSave
            .mutateAsync({
              assignedById: assignedById!,
              deadlineStart: startDate,
              deadlineFinish: endDate,
              clientId: Number(sourceTask?.clientId)!,
              leadTaskTypeId: sourceTask?.leadTaskType?.id!,
              text: sourceTask?.text!,
              id: sourceTask?.id,
              contractId: sourceTask?.contractId
            })
            .then(() => qc.invalidateQueries({ queryKey: [queryNames.TASK_FILTER_CRM] }));
    }
  };

  const onDragStart = (result: DragStart) => {
    setDragging(true);
    setDraggingId(Number(result?.draggableId));
    const sourceTaskData = qc.getQueryData<{
      size: number;
      pages: Pagination<TaskFilter>[];
      searchParams: SearchParamsTask;
      startDate: string | null;
      finishDate: string | null;
    }>([
      queryNames.TASK_FILTER_CRM,
      20,
      JSON.stringify(searchParams),
      assigmentObj[result?.source.droppableId]?.startDate,
      assigmentObj[result?.source.droppableId]?.finishDate
    ]);
    setCurrentData(sourceTaskData?.pages[0]?.data[result?.source?.index]);
  };
  const taskStatuses = ['Otib_ketgan', 'Bugungi', 'Ertangi', 'Rejada'];
  // const taskStatuses = [
  //   t("task.Otib_ketgan"),
  //   t('task.Bugungi'),
  //   t('task.Ertangi'),
  //   t('task.Rejada')
  // ];

  return (
    <div className={styles.container}>
      <DragDropContext onDragEnd={result => onDragEnd(result)} onDragStart={result => onDragStart(result)}>
        {taskStatuses?.map(item => {
          return <DndTaskColumn assignType={item} hower={hower} key={item} />;
        })}
      </DragDropContext>
    </div>
  );
};

export default DndTaskContainer;
