import styles from './securityMenu.module.scss';
import { useTranslation } from 'react-i18next';
// import ArrowRightMessage from '../../../assets/icons/ArrowRightMessage'
import { NavLink } from 'react-router-dom';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { rootPaths } from '../../../routing/rootPaths';
import { MainPaths } from '../../../routing/main/mainPaths';
import { SettingsPaths } from '../../../routing/settings/settingsPaths';
import { SecuritySettingsPath } from '../../../routing/securitySettings/SecuritySettingsPath';
import ShieldTickIcon from '../../../assets/icons/ShieldTickIcon';

// Компонент для отображения меню безопасности
const SecurityMenu = () => {
  const param = useQueryParam(); // Получение параметров запроса
  const { t } = useTranslation(); // Использование хука i18n для перевода

  // Массив с данными для отображения пунктов меню
  const data = [
    {
      icon: ShieldTickIcon, // Иконка
      text: t('navbars.Xavfsizlik_sozlamalari'), // Текст
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.SECURITY + SecuritySettingsPath.SECURITY_SETTINGS, // Путь
      class:
        param.splitUrl[param.splitUrl.length - 1] === '' // Класс, основанный на параметрах запроса
          ? `${styles.active}`
          : `${styles.navLinkClass}`
    },
    {
      icon: ShieldTickIcon, // Иконка
      text: t('online_pbx.Ruxsatlarni_boshqarish'), // Текст
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.SECURITY + SecuritySettingsPath.CONTROL_PERMISSIONS, // Путь
      class:
        param.splitUrl[param.splitUrl.length - 1] === '' // Класс, основанный на параметрах запроса
          ? `${styles.active}`
          : `${styles.navLinkClass}`
    }
  ];

  // Возвращаем меню безопасности
  return (
    <div className={styles.navbar}>
      {data.map((item, index) => (
        <NavLink key={index} to={item.path} className={item.class} end>
          <span className="d-flex align-center">
            {<item.icon />} &nbsp; &nbsp; {item.text} {/* Отображение иконки и текста */}
          </span>

          <span className={styles.arrowDiv}>{/* <ArrowRightMessage /> Иконка стрелки */}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default SecurityMenu;
