import React, { useState } from 'react';
import GlphIcon from '../../assets/icons/GlphIcon';
import { Table } from 'antd';
import { usePayment } from '../../queries/queries';
import styles from './createPayment.module.scss';

import { useQueryParam } from '../../hooks/useQueryParams';
import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import DeletePaymentModal from './DeletePaymentModal';
import { Payment } from '../../utils/models/Payment';
import CommentModal from '../../pages/contractId/CommentModal';
import { PaymentType } from '../../utils/models/PaymentType';
import { getColorPaymentType } from '../../utils/helper/getColorPaymentType';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Permissions } from '../../utils/constants/permissions';
import { useTranslation } from 'react-i18next';

const HistoryPayments = () => {
  const [deletePaymentInfo, setDeletePaymentInfo] = useState<{
    payment: Payment | null;
    visible: boolean;
  }>({
    payment: null,
    visible: false
  });
  const [deleteModal, setDeleteModal] = useState({
    visible: false,
    id: -1
  });
  const { params } = useQueryParam<{ id: string }, any>();
  const { data, isLoading } = usePayment(params.id);
  const { t } = useTranslation();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessDeletePayment = permissions?.includes(Permissions.PERMISSION_CANCEL_PAYMENT);

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      render: (i: any, a: any, b: any) => {
        return <>{b + 1}</>;
      },
      width: 100
    },
    {
      title: t('create_payment.To’lov_qilingan_sana'),
      dataIndex: 'createdDate'
    },
    {
      title: t("create_payment.To'lov_summasi"),
      dataIndex: 'amount',
      render: (i: any) => <>{i?.toLocaleString()}</>
    },
    {
      title: t("create_payment.To'lov_turi"),
      dataIndex: 'type',
      render: (type: PaymentType) => <span style={{ color: getColorPaymentType(type) }}>{t(`home.${type}`)}</span>
    },
    {
      title: t("create_payment.Ma'sul_xodim"),
      dataIndex: 'createdBy'
    },
    {
      title: '',
      width: 120,
      render: (i: any, record: Payment) => {
        return (
          <div className={'d-flex align-center justify-center'}>
            {!record.canceledDate && isAccessDeletePayment ? (
              <span onClick={() => handleOpen(record.id)} className={'delete_icon_table_item'}>
                <CancelBronGarbage />
              </span>
            ) : (
              <div onClick={() => handleOpenDeleteModal(record)} className={'delete_item_table_info d-flex align-center'}>
                <GlphIcon />
                <div className={'delete_item_table_info_label'}>{t("create_payment.Ma'lumot")}</div>
              </div>
            )}
          </div>
        );
      }
    }
  ];

  const handleOpen = (id: number) => {
    setDeleteModal({
      visible: true,
      id
    });
  };

  const handleClose = () => {
    setDeleteModal({
      visible: false,
      id: -1
    });
  };

  const handleOpenDeleteModal = (payment: Payment) => {
    setDeletePaymentInfo({
      payment,
      visible: true
    });
  };

  const handleCloseDeleteModal = (visible: boolean) => {
    setDeletePaymentInfo({
      payment: null,
      visible
    });
  };

  return (
    <div>
      <Table
        loading={isLoading}
        className={`custom_table contract_table table_cursor_default ${styles.historyPayments} `}
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        rowClassName={row => (row.canceledDate === null ? 'table_cursor_pointer' : 'rowCanceledPayment table_cursor_pointer')}
      />
      <DeletePaymentModal contractId={Number(params.id)} visible={deleteModal.visible} onCancel={handleClose} id={deleteModal.id} />
      <CommentModal
        visible={deletePaymentInfo.visible}
        setVisible={handleCloseDeleteModal}
        comment={deletePaymentInfo.payment?.cancelNote}
        date={deletePaymentInfo.payment?.canceledDate}
        name={deletePaymentInfo.payment?.canceledBy}
      />
    </div>
  );
};

export default HistoryPayments;
