import React from 'react';

const ErrorPageIcon = () => {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.7344 0.549988H19.2656L0.549988 19.2656V45.7344L19.2656 64.45H45.7344L64.45 45.7344V19.2656L45.7344 0.549988ZM36.05 50.25H28.95V43.15H36.05V50.25ZM36.05 36.05H28.95V14.75H36.05V36.05Z"
        fill="rgb(0, 163, 137)"
      />
    </svg>
  );
};

export default ErrorPageIcon;
