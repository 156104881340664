import React, { FC, useEffect, useState } from 'react';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import PlusIcon from '@icons/PlusIcon';
import { useFlatField } from '@queries/mutation';
import { FlatField } from '@utils/models/FlatField';
import { Button, Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  id?: number;
  setVisible: (visible: boolean) => void;
  setEdit: (e: number) => void;
  flatFieldOne: FlatField | undefined;
}

const CreateConstantModal: FC<IProps> = ({ setVisible, visible, id, setEdit, flatFieldOne }) => {
  const { t } = useTranslation();
  const [activeType, setActiveType] = useState('');
  const [form] = Form.useForm();
  const flatFieldMutate = useFlatField();
  const closeHandle = () => {
    form.resetFields();
    setVisible(false);
    setEdit(0);
  };

  useEffect(() => {
    setActiveType(flatFieldOne?.type!);
    form.setFieldsValue({
      name: flatFieldOne?.name,
      type: flatFieldOne?.type,
      show: flatFieldOne?.show,
      showPDF: flatFieldOne?.showPDF,
      select_list: flatFieldOne?.options.map(item => ({
        ...item,
        value: item.name
      }))
    });
  }, [id, flatFieldOne, form]);

  const onFinish = (values: { name: string; type: string; select_list: { name: string }[]; show: boolean; showPDF: boolean }) => {
    flatFieldMutate
      .mutateAsync({
        ...(id && {
          id
        }),
        name: values.name,
        type: values.type,
        show: values.show,
        showPDF: values.showPDF,
        ...(values.type === 'SELECT'
          ? {
              options: values.select_list.map((item: any) => ({
                name: item.value,
                ...(item.id && { id: item.id })
              }))
            }
          : {})
      })
      .then(() => {
        closeHandle();
      });
  };

  return (
    <Modal title={t('shaxmatka.Ozgaruvchi_qoshish')} onCancel={closeHandle} visible={visible} footer={false} wrapClassName="shaxmatka_drawer_footer_constants_modal" width={466}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item name="name" label={t('one_lid.Nomi')} rules={[{ required: true, message: '' }]}>
          <Input className="customInput" />
        </Form.Item>
        <Form.Item name="type" label={t('shaxmatka.Ozgaruvchi_turi')} rules={[{ required: true, message: '' }]}>
          <Select className="customSelect" onChange={e => setActiveType(e)}>
            <Select.Option value="TEXT">{t('shaxmatka.Matn')}</Select.Option>
            <Select.Option value="SELECT">{t('shaxmatka.Select')}</Select.Option>
          </Select>
        </Form.Item>
        {activeType === 'SELECT' && (
          <Form.List name="select_list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="baseline">
                    <Form.Item {...restField} name={[name, 'value']} rules={[{ required: true, message: '' }]}>
                      <Input className="customInput" placeholder={t('one_lid.Nomi')} />
                    </Form.Item>
                    <Button type="text" onClick={() => remove(name)}>
                      <TrushIcon color="red" />
                    </Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} icon={<PlusIcon />} className="shaxmatka_drawer_footer_constants_modal_createSelectOption">
                    {t('shaxmatka.Variant_qoshish')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}

        <Form.Item name="show" valuePropName="checked">
          <Checkbox>{t('shaxmatka.showroom_description')}</Checkbox>
        </Form.Item>
        <Form.Item name="showPDF" valuePropName="checked">
          <Checkbox>{t('contract_custom_field.Tijorat_taklifida_korsatish')}</Checkbox>
        </Form.Item>

        <div className="shaxmatka_drawer_footer_constants_modal_footer">
          <Button onClick={closeHandle} htmlType="reset">
            {t('home.Bekor_qilish')}
          </Button>
          <Button type="primary" htmlType="submit" loading={flatFieldMutate.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateConstantModal;
