import React, { FC } from 'react';
// import { useSearchLeadExtra } from '../../../../queries/queries'
import { CreateLeadInterface } from '../../../../../utils/models/CreateLeadInterface';
import { Form, FormInstance, Input } from 'antd';
import { CrmField } from '../../../../../utils/models/CrmField';
import { Rule } from 'antd/lib/form';
// import { LoadingOutlined } from '@ant-design/icons'
import { NamePath } from 'antd/lib/form/interface';
// import styles from './createLead.module.scss'
// import { BASE_URL } from '../../../../service/RequestService'
// import avatarDefaultClient from '../../../../assets/img/avatarDefaultClient.jpg'

interface IProps {
  placeholder?: string;
  setSelectedExtra: React.Dispatch<React.SetStateAction<CreateLeadInterface | null | undefined>>;
  form: FormInstance<any>;
  data: CrmField[] | undefined;
  label?: string;
  name: NamePath | undefined;
  rules?: Rule[] | undefined;
  inputType?: string | undefined;
  bordered?: boolean;
}

const SearchLeadItem: FC<IProps> = ({ placeholder, setSelectedExtra, form, data, label, name, rules, inputType, bordered = true }) => {
  // const [search, setSearch] = useState('')
  // const { data: searchLeadExtra, isLoading } = useSearchLeadExtra(search)

  // const handleSelect = (values: CreateLeadInterface) => {
  //   setSelectedExtra(values)
  //   form.setFieldsValue({
  //     name: values.name,
  //     balance: values.balance?.toLocaleString('ru') || null,
  //     contactName: values.contacts[0]?.name,
  //     contactPhone: values?.contacts[0]?.phones![0],
  //     lead: data
  //       ?.filter((item) => item.infoType !== 'CONTACT')
  //       ?.map((item) => ({
  //         ...item,
  //         value: values?.fields?.find((field) => field?.id === item?.id)?.value,
  //       })),
  //     contact: data
  //       ?.filter((item) => item.infoType !== 'LED')
  //       ?.map((item) => ({
  //         ...item,
  //         value: values?.contacts[0]?.fields?.find(
  //           (field) => field?.id === item?.id
  //         )?.value,
  //       })),
  //   })
  // }

  // const searchOption = searchLeadExtra?.map((item) => ({
  //   item: item,
  //   value: item?.id,
  //   key: item?.id,
  //   label: (
  //     <div className={styles.autoComplate_item}>
  //       <div>
  //         <h3>
  //           {item?.name?.length > 24
  //             ? item?.name?.slice(0, 24) + '...'
  //             : item?.name}
  //         </h3>
  //         <p>{item?.contacts[0]?.phones[0]}</p>
  //       </div>
  //       <Avatar
  //         src={
  //           item?.contacts[0]?.avatar
  //             ? BASE_URL + '/' + item?.contacts[0]?.avatar
  //             : avatarDefaultClient
  //         }
  //       />
  //     </div>
  //   ),
  // }))

  return (
    <Form.Item label={label} name={name} rules={rules}>
      {/* <AutoComplete
        options={searchOption}
        onSelect={(e: any, b: any) => handleSelect(b.item)}
      > */}
      <Input
        // onChange={(e) => setSearch(e.target.value)}
        type={inputType}
        placeholder={placeholder}
        // suffix={isLoading ? <LoadingOutlined /> : <div></div>}
        className="customInput"
        bordered={bordered}
        style={{ padding: !bordered ? '0px' : '' }}
      />
      {/* </AutoComplete> */}
    </Form.Item>
  );
};

export default SearchLeadItem;
