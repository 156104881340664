import ArrowDownIcon from '@icons/ArrowDownIcon';
import SidebarArrowRightIcon from '@icons/SidebarArrowRightIcon';
import { useGetAllTJMSWithBuildings } from '@pages/bossCrm/services/queries';
import { Form, Popover, Select, TreeSelect } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './secondRowHeader.module.scss';
import { getNameTrl } from '@utils/helper/getNameTrl';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useCrmFields } from '@pages/crm/components/voronkaMessengerSetting/voronkaWebForm/service/queries';
import InfoIcon from '../../../assets/icons/InfoIcon';
import { useSearchParams } from 'react-router-dom';
const SecondRowHeader: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setContext, tjmIds, buildingIds } = useContext(ConversionContext);
  const { data: tjms } = useGetAllTJMSWithBuildings();
  const { data: customs } = useCrmFields();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const fields =
    typeof Number(searchParams.get('customField')) === 'string' ? searchParams.get('customField') : Number(searchParams.get('customField'));
  const onTreeSelectChange = (data: string) => {
    if (data?.split(',')[0] === 'parent') {
      if (!tjmIds?.includes(Number(data?.split(',')[1]))) {
        setContext({ tjmIds: [...tjmIds!, Number(data?.split(',')[1])] });
      } else {
        setContext({
          tjmIds: tjmIds?.filter(el => el !== Number(data?.split(',')[1]))
        });
      }
    } else {
      if (data?.split(',')[0] !== 'Barchasi')
        if (data?.split(',').length > 0) {
          if (!buildingIds?.includes(Number(data?.split(',')[1]))) {
            setContext({
              buildingIds: [...buildingIds!, Number(data?.split(',')[1])]
            });
          } else {
            setContext({
              buildingIds: buildingIds?.filter(el => el !== Number(data?.split(',')[1]))
            });
          }
        }
    }
  };

  const onCustomFieldSelect = (e: number | string) => {
    setContext({ customFieldIds: e });
    searchParams.set('customField', e as string);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (customs && !fields) {
      form.setFieldsValue({ customFields: customs?.filter(item => item.type === 'SELECT')[0]?.id });
      setContext({ customFieldIds: customs?.filter(item => item.type === 'SELECT')[0]?.id });
    } else {
      form.setFieldsValue({ customFields: fields });
      setContext({ customFieldIds: fields! });
    }
  }, [customs, fields]);

  return (
    <div className={styles.s_row_header}>
      <Form form={form} className={styles.s_row_header_form}>
        <Form.Item name="tjmId">
          <TreeSelect
            treeExpandAction={'click'}
            defaultValue="Barchasi"
            onSelect={(e: string) => onTreeSelectChange(e)}
            onDeselect={(e: string) => onTreeSelectChange(e)}
            switcherIcon={<SidebarArrowRightIcon />}
            suffixIcon={<ArrowDownIcon />}
            dropdownClassName="FacebookFormsTreeSelect"
            className="customSelect"
            treeCheckable
            treeCheckStrictly
            placeholder={t('boss_crm_conversion.Tanlang')}
            multiple
          >
            <TreeSelect.TreeNode title={t('home.Barchasi')} value="Barchasi" />
            {tjms?.map(item => (
              <TreeSelect.TreeNode
                value={'parent' + ',' + item.id!}
                title={item.name}
                children={item?.buildings?.map(el => <TreeSelect.TreeNode title={el.name} value={'child' + ',' + el.id!} />)}
              />
            ))}
          </TreeSelect>
        </Form.Item>
        <Form.Item name="customFields">
          <Select onSelect={(e: number) => onCustomFieldSelect(e)} showArrow placeholder={t('boss_crm_conversion.Tanlang')} showSearch={false}>
            {customs?.filter(el => el.type === 'SELECT')?.map((item, i) => <Select.Option value={item?.id}>{getNameTrl(item)}</Select.Option>)}
            <Select.Option value="tag" style={{ color: 'blue', fontWeight: '500' }}>
              {t('boss_crm_conversion.Tag')}
            </Select.Option>
          </Select>
        </Form.Item>
        <Popover
          overlayInnerStyle={{
            borderRadius: '8px',
            padding: '12px'
          }}
          content={
            <div
              style={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
                width: '300px',
                textAlign: 'center'
              }}
            >
              {t('boss_crm_conversion.Info')}
            </div>
          }
        >
          <Form.Item>
            <InfoIcon />
          </Form.Item>
        </Popover>
      </Form>
    </div>
  );
};

export default SecondRowHeader;
