import React from 'react';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

const CheveronTop: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08891 6.98881C7.87412 7.2036 7.52588 7.2036 7.31109 6.98881L5.5 5.17772L3.68891 6.98881C3.47412 7.2036 3.12588 7.2036 2.91109 6.98881C2.6963 6.77402 2.6963 6.42578 2.91109 6.21099L5.11109 4.01099C5.32588 3.79621 5.67412 3.79621 5.88891 4.01099L8.08891 6.21099C8.3037 6.42578 8.3037 6.77402 8.08891 6.98881Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default CheveronTop;
