import React from 'react';
import BossContractClient from '@components/bossContract/bossContractClient/BossContractClient';
import BossContractGraphic from '@components/bossContract/bossContractGraphic/BossContractGraphic';
import BossContractPeriod from '@components/bossContract/bossContractPeriod/BossContractPeriod';

import styles from './contractCommon.module.scss';

const ContractCommon = () => (
  <div className={styles.container}>
    <BossContractPeriod />
    <BossContractGraphic />
    <BossContractClient />
  </div>
);

export default ContractCommon;
