import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import SuccessIcon from '../../assets/icons/SuccessIcon';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { TemplateFields } from '../../utils/models/TemplateFields';

import styles from '../../pages/messageSettings/MessageSettings.module.scss';

interface EditableProps {
  text: string;
  title: string;
  fields: TemplateFields[];
  updateTemplate: (value: string) => void;
  isLoading: boolean;
  index?: number;
}

const Editable: React.FC<EditableProps> = ({ text, title, fields, updateTemplate, isLoading, index }) => {
  const [value, setValue] = useState(text);
  const textReplace = text.replace(
    /&(.*?)&/g,
    `<span contenteditable='false' class='editable_span' key='&$1&'>
      &$1& 
      <span class="editable_span_delete" onClick="this.parentElement.remove()">
        &times;
      </span>
    </span>`
  );
  const editable = useRef<HTMLDivElement>(null);
  const valueRef = useRef(value);
  const positionRef = useRef(value?.length);
  const { t } = useTranslation();

  useEffect(() => {
    const elements = document.getElementsByClassName('editable_span_delete');

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', e => {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        deleteItem(Number(e.target.attributes[0].value));
      });
    }
    return () => {
      const elements = document.getElementsByClassName('editable_span_delete');

      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('click', () => {
          // console.log('remove listener');
        });
      }
    };
  }, [value]);

  const handleChange = (valueString: string) => {
    const firstValue = valueRef.current.slice(0, positionRef.current);
    const secondValue = valueRef.current.slice(positionRef.current);
    const newValue = `${firstValue} ${valueString} ${secondValue}`;

    positionRef.current = positionRef.current + valueString.length + 2;
    valueRef.current = newValue;
    setValue(newValue);
  };

  const deleteItem = (index: number) => {
    const newValue = valueRef.current.split('&');
    let newValueString = '';

    for (let i = 0; i < newValue.length; i++) {
      if (i !== index) {
        const finded = fields.find(item => item.key === `&${newValue[i]}&`);

        if (finded) {
          newValueString += `&${newValue[i]}&`;
        } else {
          newValueString += newValue[i];
        }
      }
    }
    valueRef.current = newValueString;
    setValue(newValueString);
  };

  async function pasteHtmlAtCaret(html: any) {
    let range: Range;
    const sel = await window.getSelection();
    const focusNode: any = document.querySelector(`.editor${index}`);

    await focusNode.focus();
    if (sel?.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      const el = document.createElement('div');

      el.innerHTML = `<span contenteditable="false" class='editable_span' key='${html}'>
          ${html}
          <span class="editable_span_delete" onClick="this.parentElement.remove()">
            &times;
          </span>
        </span>`;
      const frag = document.createDocumentFragment();
      let node: any;
      let lastNode: any;

      // eslint-disable-next-line no-cond-assign
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }

  const handleSave = () => {
    if (index !== undefined) {
      const focusNode: any = document.querySelector(`.editor${index}`);
      const textArr: any[] = [];
      const childrenRec = (childrens: any) => {
        childrens?.forEach((item: any) => {
          if (item.nodeName === '#text') {
            textArr.push(item.data);
          } else if (item.getAttribute('key')) {
            textArr.push(item.getAttribute('key'));
          } else if (item?.childNodes && item?.childNodes.length > 0) {
            childrenRec(item?.childNodes);
          }
        });
      };

      childrenRec(focusNode?.childNodes);

      updateTemplate(textArr.join('').replace(/\u00a0/g, ' '));
    } else {
      const stringWithoutMultiplicationSymbol = editable.current?.innerText?.replace(/ ×|\u00a0/g, '');

      updateTemplate(stringWithoutMultiplicationSymbol || text);
    }
  };

  return (
    <>
      <div className={styles.titleDiv}>
        <h5
          style={{
            border: '1px solid #E5E7EB',
            padding: '10.5px  1rem',
            borderRadius: '6px'
          }}
        >
          {title}
        </h5>
      </div>
      <div
        className={`${styles.editable} editor${index}`}
        contentEditable
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: textReplace }}
        ref={editable}
      />
      <div className={styles.save}>
        <p className={styles.aboutHome}>
          {fields.map(field => (
            <span
              key={field.key}
              onClick={() => {
                handleChange(field.key);
                pasteHtmlAtCaret(field.key);
              }}
            >
              {getCurrentLanguageWord(field as any)}
            </span>
          ))}
        </p>
        <Spin spinning={isLoading}>
          <p onClick={handleSave} className={styles.saveButton}>
            <span>
              <SuccessIcon />
            </span>
            {t('home.Saqlash')}
          </p>
        </Spin>
      </div>
    </>
  );
};

export default Editable;
