import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { LeadTaskType } from '@utils/models/LeadTaskType';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
// import { originator } from "../utils/models/Originator"

export function useGetLeadTaskType() {
  return useQuery<LeadTaskType>(
    [queryKeys.GET_LEADTASKTYPE],
    async () => {
      const res = await $api.get(endPoints.GET_LEADTASKTYPE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useGetSMSBot() {
  return useQuery<any>(
    [queryKeys.GET_SMSBOT],
    async () => {
      const res = await $api.get(endPoints.GET_SMSBOT);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useGetSMSConfigAll() {
  return useQuery<any>(
    [queryKeys.CONFIG_ALL],
    async () => {
      const res = await $api.get(endPoints.CONFIG_ALL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useGetOriginator() {
  return useQuery<any>(
    [queryKeys.GET_ORIGINATOR],
    async () => {
      const res = await $api.get(endPoints.GET_ORIGINATOR);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useGetMessage(page: number, size: number, selectedId: number | null) {
  return useQuery<any>(
    [queryKeys.GET_MESSAGE, page, size],
    async () => {
      const res = await $api.get(`${endPoints.GET_MESSAGE}?page=${page}&size=${size}&leadTaskTypeId=${selectedId}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}
