import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { SearchParamsArrearage } from '../../../Arrearage';
import CardRemove from '../../../../../assets/icons/CardRemove';
import { useDebitDelete } from '../../../services/mutation';

interface ArrearageDeleteModalProps {
  visible: boolean;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
    }>
  >;
  id: number;
}

const ArrearageDeleteModal: FC<ArrearageDeleteModalProps> = ({ visible, setVisible, id }) => {
  const { searchParams } = useQueryParam<SearchParamsArrearage, any>();
  const deleteSurcharge = useDebitDelete(id, searchParams);
  const handleCancel = () => {
    setVisible({ id: 0, visible: false });
  };

  const { t } = useTranslation();

  const handleDelete = () => {
    deleteSurcharge.mutate();
    handleCancel();
  };
  return (
    <Modal wrapClassName="arrearageDeleteModal" width={420} footer={false} closable={false} visible={visible} centered onCancel={handleCancel}>
      <span>
        <CardRemove />
      </span>
      <h1>{t('arrearage.Peniyani_ochirib_yubormoqchimisiz')}</h1>
      <p>{t('arrearage.Ochirib_yuborilgan_peniya_ustida_hech_qanday_amal_bajarib_bolmaydi')}</p>
      <div>
        <Button onClick={handleCancel}>{t('arrearage.Yopish')}</Button>
        <Button onClick={handleDelete} type="primary">
          {t('arrearage.Tasdiqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default ArrearageDeleteModal;
