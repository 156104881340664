import React, { FC } from 'react';
import SuccessIconCreateOrder from '@icons/SuccessIconCreateOrder';
import { useMonetaryAccept } from '@queries/mutation';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  id: number;
}

const MonetaryPaymentAccept: FC<IProps> = ({ id }) => {
  const monetaryAccept = useMonetaryAccept();
  return monetaryAccept.isLoading ? (
    <LoadingOutlined />
  ) : (
    <span
      onClick={() => {
        monetaryAccept.mutate(id);
      }}
    >
      <SuccessIconCreateOrder size={32} stroke="#00A389" />
    </span>
  );
};

export default MonetaryPaymentAccept;
