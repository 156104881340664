import { lazy } from 'react';

import { rootPaths } from './rootPaths';
// import Login from '../pages/login/Login'
// import MainRouter from './main/MainRouter'
// import F4f from '../components/f4f/F4f'
// import DeveloperRouter from './developer/DeveloperRouter'
// import ShowroomRouter from './showroom/ShowroomRouter'
// import BossPageRouter from './bossPage/BossRouter'
// import RootIntegration from '../pages/rootIntegration/RootIntegration'
// import UyqurIntegration from '../pages/rootIntegration/UyqurIntegration'
// import RestorePassword from '../pages/restorePassword/RestorePassword'
// import ChangePassword from '../pages/change-password/ChangePassword'
// import QrCode from '../pages/qr-code/QrCode'
const Login = lazy(() => import('../pages/login/Login'));
const MainRouter = lazy(() => import('./main/MainRouter'));
const F4f = lazy(() => import('../components/f4f/F4f'));
// const ShowroomRouter = lazy(() => import('./showroom/ShowroomRouter'));
const ShowroomV2 = lazy(() => import('@pages/showroom-v2/ShowroomV2'));
const BossPageRouter = lazy(() => import('./bossPage/BossRouter'));
const RootIntegration = lazy(() => import('../pages/rootIntegration/RootIntegration'));
const UyqurIntegration = lazy(() => import('../pages/rootIntegration/UyqurIntegration'));
const RestorePassword = lazy(() => import('../pages/restorePassword/RestorePassword'));
const ChangePassword = lazy(() => import('../pages/change-password/ChangePassword'));
const QrCode = lazy(() => import('../pages/qr-code/QrCode'));
const ScheduleLottotron = lazy(() => import('@pages/schedule-lottotron/ScheduleLottotron'));

export const rootRoutes = [
  {
    path: rootPaths.INDEX,
    component: Login,
    exact: true
  },
  {
    path: rootPaths.MAIN,
    component: MainRouter,
    exact: false
  },
  {
    path: rootPaths.SHOWROOM,
    component: ShowroomV2,
    exact: false
  },
  {
    path: rootPaths.BOSS,
    component: BossPageRouter,
    exact: false
  },
  {
    path: rootPaths.INTEGRATION,
    component: RootIntegration,
    exact: false
  },
  {
    path: rootPaths.INTEGRATION_UYQUR,
    component: UyqurIntegration,
    exact: false
  },
  {
    path: rootPaths.RESTORE_PASSWORD,
    component: RestorePassword,
    exact: true
  },
  {
    path: rootPaths.CHANGE_PASSWORD,
    component: ChangePassword,
    exact: true
  },
  {
    path: rootPaths.QR_CODE,
    component: QrCode,
    exact: true
  },
  {
    path: rootPaths.SCHEDULE_LOTTOTRON,
    component: ScheduleLottotron,
    exact: true
  },
  {
    path: '*',
    component: F4f,
    exact: true
  }
];
