import React from 'react';

const CancelBronGarbage = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 6.47998C18.17 6.14998 14.82 5.97998 11.48 5.97998C9.5 5.97998 7.52 6.07998 5.54 6.27998L3.5 6.47998"
        stroke="#E53535"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5.47L9.22 4.16C9.38 3.21 9.5 2.5 11.19 2.5H13.81C15.5 2.5 15.63 3.25 15.78 4.17L16 5.47"
        stroke="#E53535"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3499 9.63989L18.6999 19.7099C18.5899 21.2799 18.4999 22.4999 15.7099 22.4999H9.2899C6.4999 22.4999 6.4099 21.2799 6.2999 19.7099L5.6499 9.63989"
        stroke="#E53535"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.8301 17H14.1601" stroke="#E53535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 13H15" stroke="#E53535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CancelBronGarbage;
