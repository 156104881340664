import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsivePie } from '@nivo/pie';
import { setUnSuccessfulLeadsModal } from '@reducers/CrmStatisticsSlice';
import { colorPieChart } from '@utils/constants/colorPieChart';
import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useTranslation } from 'react-i18next';

import { useGetFailedLead } from '../../services/queries';
import CommonUnSuccessLeadsModal from '../unsuccess-leads-modal';

import styles from './faildFild.module.scss';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<'REASON' | 'EMPLOYEE'>('REASON');
  const { selectedPipe, unSuccessfulLeadModal, startDatePipe, finishDatePipe } = useAppSelector(state => state.crmStatistic);

  const handleChange = (e: any) => {
    dispatch(
      setUnSuccessfulLeadsModal({
        ...unSuccessfulLeadModal,
        type: e === 'Sabablar' ? 'REASON' : 'EMPLOYEE',
        visible: false
      })
    );

    setStatus(e === 'Sabablar' ? 'REASON' : 'EMPLOYEE');
  };

  const { data } = useGetFailedLead({ statType: status, pipeId: selectedPipe?.id, finishDate: finishDatePipe, startDate: startDatePipe });

  const other = {
    REASON: t('crm_filter.Sababsiz'),
    EMPLOYEE: t('task.Boshqalar')
  };
  const newArray = data?.result?.map(({ id, value, count }, index) => ({
    id,
    label: id === -1 ? other[status as keyof typeof other] : value,
    value: count,
    color: colorPieChart[index]
  }));

  const sum = data?.total;

  return (
    <div className={styles.failed}>
      <div className={styles.failed_header}>
        <h1>{t('crm.Muvafaqqiyatsiz_lidlar')}</h1>
        <Segmented
          onChange={(e: SegmentedValue) => handleChange(e)}
          options={[
            { value: 'Sabablar', label: t('crm.Sabablar') },
            { value: 'Xodimlar', label: t('home.Xodimlar') }
          ]}
        />
      </div>
      <div className={styles.failed_nivo}>
        <ResponsivePie
          data={newArray || []}
          margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
          innerRadius={0.65}
          cornerRadius={3}
          activeOuterRadiusOffset={16}
          colors={{ datum: 'data.color' }}
          borderColor={{
            from: 'color',
            modifiers: [['opacity', 0.2]]
          }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          enableArcLabels={false}
          arcLabel="value"
          arcLabelsRadiusOffset={0.3}
          onClick={item => {
            dispatch(
              setUnSuccessfulLeadsModal({
                ...unSuccessfulLeadModal,
                type: status,
                visible: true,
                [status === 'REASON' ? 'reasonsFRId' : 'responsibleById']: item?.data?.id
              })
            );
          }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]]
          }}
          tooltip={({ datum: { value, label } }) => (
            <div
              style={{
                padding: 12,
                color: '#fff',
                background: '#222222',
                borderRadius: 8
              }}
            >
              <strong>
                {label}: {value}
              </strong>
            </div>
          )}
          theme={{
            tooltip: {
              container: {
                background: '#fff'
              }
            }
          }}
        />
        <div className={styles.failed_nivo_all}>
          <h3>{sum}</h3>
          <p>{t('home.Jami')}</p>
        </div>
      </div>
      <div className={styles.container_every_x_month}>
        {newArray?.map(item => (
          <div className={styles.body} key={item.id}>
            <div className={styles.body_item}>
              <div className={styles.txt}>
                <div style={{ background: item.color }} className={styles.color}>
                  <div className={styles.circle} />
                </div>
                <h2>{item.label}</h2>
              </div>
              <div className={styles.quantity}>
                <h2>{item?.value}</h2>
              </div>
            </div>
          </div>
        ))}
      </div>
      <CommonUnSuccessLeadsModal />
    </div>
  );
};

export default Index;
