import React, { memo, useState } from 'react';
import styles from './filterDrawer.module.scss';
import SubtractIcon from '../../../../../assets/icons/SubtractIcon';
import { Input } from 'antd';
import { useTags } from '../../../../../queries/queries';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

interface Props {
  tagIds: string[] | string | undefined;
  visible: boolean;
}

const Tags: React.FC<Props> = ({ tagIds, visible }) => {
  const { appendMultiple, remove } = useQueryParam();
  const [search, setSearch] = useState('');

  const { data } = useTags(visible);

  const { t } = useTranslation();

  //handleSearch
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // filter tag
  const filterTag = () => {
    if (!tagIds && data) {
      const tags = data.tagList;
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()));
    } else if (data && tagIds) {
      if (Array.isArray(tagIds)) {
        const tags = data.tagList;
        return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()) && !tagIds.includes(String(tag.id)));
      }
      const tags = data.tagList;
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()) && tagIds !== String(tag.id));
    }
    return [];
  };

  // tags
  const tags = () => {
    if (tagIds) {
      if (Array.isArray(tagIds)) {
        return (
          <>
            {tagIds.map((id, index) => {
              const tag = data?.tagList.find(tag => tag.id === Number(id));
              if (tag) {
                return (
                  <span key={index} className={styles.container_tags_tag}>
                    {tag.name}
                    <SubtractIcon onClick={() => changeTags(String(tag.id))} />
                  </span>
                );
              }
              return null;
            })}
          </>
        );
      }
      const tag = data?.tagList.find(tag => tag.id === Number(tagIds));
      if (tag) {
        return (
          <span className={styles.container_tags_tag}>
            {tag.name}
            <SubtractIcon onClick={() => changeTags(String(tag.id))} />
          </span>
        );
      }
      return null;
    }
    return;
  };

  // changeTags
  const changeTags = (value: string) => {
    if (Array.isArray(tagIds)) {
      if (tagIds.includes(value)) {
        remove('tagIds', value);
      } else {
        appendMultiple('tagIds', value);
        handleSearch('');
      }
    } else {
      if (tagIds === value) {
        remove('tagIds', value);
      } else {
        appendMultiple('tagIds', value);
        handleSearch('');
      }
    }
  };

  return (
    <>
      <h2 className={styles.container_title + ' ' + styles.container_margin_top}>{t('crm_filter.Teglar')}</h2>
      <div className={styles.container_tags}>
        {tags()}
        <Input value={search} onChange={e => handleSearch(e.target.value)} bordered={false} placeholder={t('crm_filter.Tegni_izlash')} />
      </div>
      <div className={styles.container_tags_list}>
        {filterTag()?.map(tag => (
          <div onClick={() => changeTags(String(tag.id))} key={tag.id} className={styles.container_tags_list_tag}>
            {tag.name}
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(Tags);
