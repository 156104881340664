export interface CompanyLinks {
  id?: number;
  key: CompanyLinksKey;
  value: string;
}

export enum CompanyLinksKey {
  COUNTER_CONTRACT = 'COUNTER_CONTRACT',
  COUNTER_CONTRACT_BY_HOUSE = 'COUNTER_CONTRACT_BY_HOUSE',
  COUNTER_CONTRACT_BY_BUILDER = 'COUNTER_CONTRACT_BY_BUILDER',
  COUNTER_CONTRACT_FLAT = 'COUNTER_CONTRACT_FLAT',
  COUNTER_CONTRACT_FLAT_BY_HOUSE = 'COUNTER_CONTRACT_FLAT_BY_HOUSE',
  COUNTER_CONTRACT_FLAT_BY_BUILDER = 'COUNTER_CONTRACT_FLAT_BY_BUILDER',
  COUNTER_PAYMENT = 'COUNTER_PAYMENT',
  COUNTER_BOOKING = 'COUNTER_BOOKING',
  SYSTEM_COLOR = 'SYSTEM_COLOR',
  COMPANY_LOGO = 'COMPANY_LOGO',
  CONTRACT_NUMBER_PATTERN = 'CONTRACT_NUMBER_PATTERN',
  PAYMENT_NUMBER_PATTERN = 'PAYMENT_NUMBER_PATTERN',
  PAY_NUMBER = 'PAY_NUMBER',
  APELSIN = 'APELSIN',
  COMPANY_WEBSITE = 'COMPANY_WEBSITE',
  COMPANY_INSTAGRAM = 'COMPANY_INSTAGRAM',
  COMPANY_TELEGRAM = 'COMPANY_TELEGRAM',
  COMPANY_FACEBOOK = 'COMPANY_FACEBOOK',
  AMO_CRM = 'AMO_CRM',
  PERMISSION_DELAY = 'PERMISSION_DELAY',
  CONTRACT_TEMPLATE = 'CONTRACT_TEMPLATE',
  PAYMENT_TEMPLATE = 'PAYMENT_TEMPLATE',
  CLIENT_PAYMENT_TEMPLATE = 'CLIENT_PAYMENT_TEMPLATE',
  ACT_TEMPLATE = 'ACT_TEMPLATE',
  SALE_CRM_TAG = 'SALE_CRM_TAG'
}
