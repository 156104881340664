/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '../../../../../../../assets/icons/CalendarIcon';
import CheveronBottom from '../../../../../../../assets/icons/CheveronBottom';
import CheckmarkIcon from '../../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { setDeadLineAfter, setDeadLineAfterStatus } from '../../../../../../../store/reducers/TriggerSlice';
import { helper } from '../../../../../../../utils/helper/helper';
import { DeadLineAfterStatus } from '../../../../../../../utils/models/Trigger';

import styles from '../triggers.module.scss';

interface IFrom {
  date: number;
  hour: number;
  minute: number;
}

const DateFinish = () => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const [form] = Form.useForm<IFrom>();

  const { t } = useTranslation();

  const time = useAppSelector(state => state.triggerSlice.deadLineAfter);
  const status = useAppSelector(state => state.triggerSlice.deadLineAfterStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (time) {
      const date = Math.floor(time / (24 * 60));
      const hour = Math.floor((time - date * (24 * 60)) / 60);
      const minute = Math.floor(time - date * (24 * 60) - hour * 60);

      form.setFieldsValue({
        hour,
        date,
        minute
      });
    } else {
      form.resetFields();
    }
  }, [time, form, status]);

  // on finish
  const onFinish = (val: IFrom) => {
    const time = val.date * 24 * 60 + val.hour * 60 + val.minute;

    dispatch(setDeadLineAfter(time));
    handleOpenDropdown(false);
  };

  // handle change status
  const handleChangeStatus = (status: DeadLineAfterStatus) => {
    dispatch(setDeadLineAfterStatus(status));
    handleOpenDropdown(false);
  };

  // handle change status now
  const handleChangeStatusNow = () => {
    dispatch(setDeadLineAfter(0));
  };

  // show title
  const showTitle = () => {
    if (status) {
      switch (status) {
        case DeadLineAfterStatus.END_OF_WEEKEND:
          return t('triggers.END_OF_WEEKEND');
        case DeadLineAfterStatus.IN_1_DAY:
          return t('triggers.TOMORROW');
        case DeadLineAfterStatus.AFTER_3_DAYS:
          return t('triggers.AFTER_3_DAYS');
        case DeadLineAfterStatus.END_OF_DAY:
          return t('triggers.END_OF_DAY');
        default:
          return t('triggers.Tanlang');
      }
    }
    if (time === 0) return t('triggers.Topshiriq_yaratilishi_bilanoq');
    return t('triggers.Maxsus');
  };

  // handle open dropdown
  const handleOpenDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  return (
    <Dropdown
      visible={openDropdown}
      onVisibleChange={handleOpenDropdown}
      placement="bottom"
      overlayClassName={styles.container_body_item_dropdown}
      overlay={
        <>
          <div onClick={handleChangeStatusNow} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.Topshiriq_yaratilishi_bilanoq')}</span>
            {time === 0 && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeStatus(DeadLineAfterStatus.END_OF_DAY)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.END_OF_DAY')}</span>
            {status === DeadLineAfterStatus.END_OF_DAY && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeStatus(DeadLineAfterStatus.IN_1_DAY)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.TOMORROW')}</span>
            {status === DeadLineAfterStatus.IN_1_DAY && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeStatus(DeadLineAfterStatus.AFTER_3_DAYS)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.AFTER_3_DAYS')}</span>
            {status === DeadLineAfterStatus.AFTER_3_DAYS && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeStatus(DeadLineAfterStatus.END_OF_WEEKEND)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.END_OF_WEEKEND')}</span>
            {status === DeadLineAfterStatus.END_OF_WEEKEND && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <Form
            form={form}
            name="dateFinish"
            initialValues={{ remember: true }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <div className={styles.container_body_type_dropdown_date_finish}>
              <span className={styles.container_body_type_dropdown_date_finish_title}>{t('triggers.Maxsus')}:</span>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (typeof value === 'number') return Promise.resolve();
                      const date = Number(helper.deleteNotNumbers(value, 0));

                      form.setFieldsValue({
                        date
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input placeholder={t('triggers.kun')} className={styles.container_body_type_dropdown_date_finish_input} />
              </Form.Item>
              <Form.Item
                name="hour"
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (typeof value === 'number') return Promise.resolve();
                      const hour = Number(helper.deleteNotNumbers(value, 0));

                      form.setFieldsValue({
                        hour
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input placeholder={t('triggers.soat')} className={styles.container_body_type_dropdown_date_finish_input} />
              </Form.Item>
              <Form.Item
                name="minute"
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (typeof value === 'number') return Promise.resolve();
                      const minute = Number(helper.deleteNotNumbers(value, 0));

                      form.setFieldsValue({
                        minute
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input placeholder={t('triggers.daqiqa')} className={styles.container_body_type_dropdown_date_finish_input} />
              </Form.Item>
              <Button htmlType="submit" className={styles.container_body_type_dropdown_date_finish_button}>
                {t('triggers.OK')}
              </Button>
            </div>
          </Form>
        </>
      }
      trigger={['click']}
    >
      <div onClick={() => handleOpenDropdown(true)} className={styles.container_body_item}>
        <div className="d-flex align-center">
          <CalendarIcon className={styles.container_body_item_date_icon} />
          <div className={styles.container_body_item_title}>
            {t('triggers.Bajarish_muddati')}: {showTitle()}
          </div>
        </div>
        <CheveronBottom className={styles.container_body_item_select} />
      </div>
    </Dropdown>
  );
};

export default DateFinish;
