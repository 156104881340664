import { LocalStorage } from '../../service/LocalStorage';
import { $api } from '../../service/RequestService';
import { authEndpoints } from '../constants/apis';
import { REDIRECT_URL, TOKEN_KEY, USER_KEY } from '../constants/localStorageKeys';

export const logOut = async () => {
  LocalStorage.remove(USER_KEY);
  LocalStorage.remove(TOKEN_KEY);
  LocalStorage.remove(REDIRECT_URL);
  LocalStorage.remove('type');
  LocalStorage.remove('employees');
  LocalStorage.remove('typeName');
  LocalStorage.remove('page');
  LocalStorage.remove('scrollPosition');
  LocalStorage.remove('scrolledOne');
  try {
    await $api.get(authEndpoints.LOGOUT, { withCredentials: true });
    window.location.replace(window.location.origin);
  } catch (error) {
    window.location.replace(window.location.origin);
    console.log(error);
  }
};
