import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Tabs, TreeSelect } from 'antd';
import styles from './facebookFormsModal.module.scss';
import { useFacebookLeadForm, useFacebookLeadFormField, useFacebookLeadFormQuestion, usePipeAll, useTags } from '../../../../../../queries/queries';
import { FacebookPages } from '../../../../../../utils/models/FaceBookPages';
import { FacebookPipeStatus } from '../../../../../../utils/models/FacebookPipeStatus';
import { useCreateTag, useFacebookLeadFormPipeStatus } from '../../../../../../queries/mutation';
import { FacebookLeadForm } from '../../../../../../utils/models/FacebookLeadForm';
import SidebarArrowRightIcon from '../../../../../../assets/icons/SidebarArrowRightIcon';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import { useTranslation } from 'react-i18next';
import { BaseEntity } from '../../../../../../utils/models/BaseEntity';

interface IProps {
  pages: FacebookPages[] | undefined;
  visible: boolean;
  pageId: string;
  edit: boolean;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      pageId: string;
      edit: boolean;
      leadFormId: string;
    }>
  >;
  pageStatus: FacebookPipeStatus[] | undefined;
  leadFormId: string;
  pipeStatusOne: FacebookPipeStatus | undefined;
  visibleFacebookForms: {
    visible: boolean;
    pageId: string;
    edit: boolean;
    leadFormId: string;
  };
}

const infoTytpe: {
  [key: string]: string;
} = {
  LED: 'Lead',
  CONTACT: 'Contact'
};

const FacebookFormsModal: FC<IProps> = ({ visible, setVisible, pages, pageId, edit, leadFormId, pipeStatusOne, visibleFacebookForms }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [form] = Form.useForm();
  const facebookLeadFormPipeStatus = useFacebookLeadFormPipeStatus();
  const { data: facebookLeadFormData } = useFacebookLeadForm(pageId);
  const { data: crmFields } = useFacebookLeadFormField();
  const { data: facebookLeadForm } = useFacebookLeadFormQuestion(leadFormId, String(pageId));
  const { data: pipeAll } = usePipeAll();
  const [facebookLeadFormQuestion, setFacebookLeadFormQuestion] = useState<FacebookLeadForm>(facebookLeadForm!);
  const { data: tags } = useTags();

  function tagsOnSucces(data: BaseEntity) {
    form.setFieldsValue({
      tags: [...form.getFieldValue('tags')].concat([data.id])
    });
  }

  const createTag = useCreateTag(tagsOnSucces);
  const [newForm, setNewForm] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      form: null
    });
    //eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    setActiveStep(1);
    setNewForm(edit);
    form.setFieldsValue({
      page: Number(pageId),
      fields: facebookLeadFormQuestion?.questions?.map(item => {
        return {
          ...item,
          fieldId: pipeStatusOne?.questions.find(question => question.lfid === item.lfid)?.fieldId,
          id: pipeStatusOne?.questions.find(question => question.lfid === item.lfid)?.id,
          fieldName: pipeStatusOne?.questions.find(question => question.lfid === item.lfid)?.fieldName
        };
      }),
      tags: edit ? pipeStatusOne?.tags.map(item => item.id) : [],
      status: edit ? pipeStatusOne?.pipeStatusId : null
    });
    //eslint-disable-next-line
  }, [crmFields, pipeStatusOne, visible, visibleFacebookForms, facebookLeadFormQuestion]);
  useEffect(() => {
    if (facebookLeadForm) {
      setFacebookLeadFormQuestion(facebookLeadForm);
    }
  }, [facebookLeadForm]);

  const handleCancle = () => {
    setVisible({
      pageId: '',
      visible: false,
      edit: false,
      leadFormId: ''
    });
  };

  const changeStep = (key: string) => {
    if (!form.getFieldValue('fields').find((e: any) => e.fieldId)) {
      form.setFields([
        {
          name: 'fields',
          errors: ['']
        }
      ]);
    } else {
      form.setFields([
        {
          name: 'fields',
          errors: undefined
        }
      ]);
      setActiveStep(Number(key));
    }
  };

  const onFinish = (values: any) => {
    const questionFilter = values.fields
      .filter((question: any) => question.fieldId)
      .map((item: any) => ({
        fieldName: crmFields?.find(f => f.id === item.fieldId)?.name,
        lfkey: item.lfkey,
        lflabel: item.lflabel,
        lftype: item.lftype,
        lfid: item.lfid,
        fieldId: String(item.fieldId),
        ...(item.id && { id: item.id })
      }));
    const tagsFilter: any = tags?.tagList.filter(item => values.tags.includes(item.id));

    if (activeStep === 2) {
      facebookLeadFormPipeStatus.mutate({
        ...(edit && { id: pipeStatusOne?.id }),
        leadFormId: facebookLeadFormQuestion?.id!,
        leadFormLocale: facebookLeadFormQuestion?.local!,
        leadFormName: facebookLeadFormQuestion?.name!,
        leadFormStatus: facebookLeadFormQuestion?.status!,
        pageId: Number(visibleFacebookForms.pageId),
        pipeStatusId: values.status,
        questions: questionFilter!,
        tags: tagsFilter.length > 0 ? tagsFilter : null
      });
    }
  };

  useEffect(() => {
    handleCancle();
    //eslint-disable-next-line
  }, [facebookLeadFormPipeStatus.isSuccess]);

  const stepTwo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!edit && !form.getFieldValue('form')) {
      form.setFields([
        {
          name: 'form',
          errors: ['']
        }
      ]);
    } else {
      if (newForm) {
        if (!form.getFieldValue('fields').find((e: any) => e.fieldId)) {
          form.setFields([
            {
              name: 'fields',
              errors: ['']
            }
          ]);
        } else {
          form.setFields([
            {
              name: 'fields',
              errors: undefined
            }
          ]);
          changeStep('2');
        }
      } else {
        form.setFieldsValue({
          fields: facebookLeadFormQuestion.questions?.map(item => {
            return {
              ...item,
              value: null
            };
          })
        });
        setNewForm(true);
      }
    }
  };

  const stepPrev = () => {
    if (newForm && activeStep !== 1) {
      changeStep('1');
    } else {
      setNewForm(false);
    }
  };

  const chageFormPage = (value: any) => {
    setVisible({
      ...visibleFacebookForms,
      pageId: value
    });
    form.setFieldsValue({
      form: null
    });
  };
  const chageForm = (value: any) => {
    setFacebookLeadFormQuestion(facebookLeadFormData?.find(item => item.id === value)!);
    form.setFieldsValue({
      form: value
    });
  };

  const changeTag = async (e: any) => {
    if (e.key === 'Enter') {
      let val = e.target.value;
      await createTag.mutate(val);
      e.target.value = '';
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancle}
      title={t('crm_ins.Facebook_formasi')}
      footer={false}
      centered
      width={466}
      wrapClassName={styles.facebookFormModal}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {!newForm ? (
          <div className={styles.facebookFormModal_item}>
            <Form.Item name={'page'} label={t('crm_ins.Forma_mavjud_sahifani_tanlang')} rules={[{ required: true, message: '' }]}>
              <Select placeholder={t('crm_ins.Tanlang')} onChange={chageFormPage} suffixIcon={<ArrowDownIcon />}>
                {pages?.map(item => (
                  <Select.Option value={item.id} key={item?.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={'form'} label={t('crm_ins.Formani_tanlang')} rules={[{ required: true, message: '' }]}>
              <Select placeholder={t('crm_ins.Tanlang')} onChange={chageForm} suffixIcon={<ArrowDownIcon />}>
                {facebookLeadFormData?.map(item => (
                  <Select.Option value={item.id} key={item?.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        ) : (
          <div>
            <Tabs activeKey={`${activeStep}`} onChange={changeStep}>
              <Tabs.TabPane tab={t('crm_ins.Maydonlarni_sozlash')} key={'1'}>
                <Form.List name="fields">
                  {(fields, { add, remove }, { errors }) => {
                    return (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div className={styles.facebookFormModal_item} key={key}>
                            <Form.Item {...restField} label={key === 0 ? facebookLeadFormQuestion?.name : ''}>
                              <Input value={facebookLeadFormQuestion.questions![key]?.lflabel} />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, 'fieldId']} label={key === 0 ? t('crm_ins.UysotCRM_maydoni') : ''}>
                              <Select
                                placeholder={t('crm_ins.Tanlang')}
                                className={errors.length > 0 ? 'ant-select-status-error' : ''}
                                onChange={() => {
                                  form.setFields([
                                    {
                                      name: 'fields',
                                      errors: undefined
                                    }
                                  ]);
                                }}
                                allowClear
                                suffixIcon={<ArrowDownIcon />}
                              >
                                {crmFields?.map(item => (
                                  <Select.Option
                                    value={String(item.id)}
                                    disabled={form.getFieldValue('fields').find((i: any) => String(i.fieldId) === String(item.id))}
                                    key={item?.id}
                                  >
                                    {item.name} ({infoTytpe[item?.infoType as any]})
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ))}
                      </>
                    );
                  }}
                </Form.List>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('crm_ins.Formani_sozlash')} key={'2'}>
                <div className={styles.facebookFormModal_item}>
                  <Form.Item label={facebookLeadFormQuestion?.name}>
                    <Input value={t('crm_ins.Teg_qoshish') as string} />
                  </Form.Item>
                  <Form.Item label={t('crm_ins.UysotCRM_maydoni')} name={'tags'}>
                    <Select mode="multiple" suffixIcon={<ArrowDownIcon />} onKeyUp={(e: any) => changeTag(e)} loading={createTag.isLoading}>
                      {tags?.tagList.map(item => (
                        <Select.Option value={item.id} key={item?.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles.facebookFormModal_item}>
                  <Form.Item>
                    <Input value={t('crm_ins.Bitm_statusi') as string} />
                  </Form.Item>
                  <Form.Item name={'status'} rules={[{ required: true, message: '' }]}>
                    <TreeSelect
                      treeExpandAction={'click'}
                      switcherIcon={<SidebarArrowRightIcon />}
                      suffixIcon={<ArrowDownIcon />}
                      dropdownClassName="FacebookFormsTreeSelect"
                    >
                      {pipeAll?.map(item => (
                        <TreeSelect.TreeNode
                          title={item.name}
                          value={item.id}
                          key={item.id}
                          selectable={false}
                          children={item.pipeStatuses.map(pipe => (
                            <TreeSelect.TreeNode
                              key={pipe?.id}
                              value={pipe.id!}
                              title={
                                <div
                                  //@ts-ignore
                                  pageName={item?.name}
                                >
                                  <span
                                    style={{
                                      background: pipe.color,
                                      color: '#FFFFFF',
                                      padding: '2px 7px',
                                      borderRadius: '5px',
                                      fontSize: 10
                                    }}
                                  >
                                    {pipe.name}
                                  </span>
                                </div>
                              }
                            />
                          ))}
                        />
                      ))}
                    </TreeSelect>
                  </Form.Item>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
        <div className={styles.facebookFormModal_footer}>
          {activeStep === 1 ? (
            <>
              {!newForm || edit ? (
                <Button onClick={handleCancle}>{t('crm_ins.Bekor_qilish')}</Button>
              ) : (
                <Button onClick={() => stepPrev()}>{t('crm_ins.Orqaga')}</Button>
              )}
              <Button type="primary" htmlType="button" onClick={e => stepTwo(e)}>
                {t('crm_ins.Keyingisi')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => stepPrev()}>{t('crm_ins.Orqaga')}</Button>
              <Button type="primary" htmlType="submit" loading={facebookLeadFormPipeStatus.isLoading}>
                {t('home.Saqlash')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default FacebookFormsModal;
