import React from 'react';

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z" fill="#F1F2F3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6498 14L20.6583 8.9915C21.1145 8.53534 21.1145 7.798 20.6583 7.34184C20.2022 6.88567 19.4648 6.88567 19.0087 7.34184L14.0002 12.3503L8.99167 7.34184C8.5355 6.88567 7.79817 6.88567 7.342 7.34184C6.88584 7.798 6.88584 8.53534 7.342 8.9915L12.3505 14L7.342 19.0085C6.88584 19.4647 6.88584 20.202 7.342 20.6582C7.5695 20.8857 7.86817 21 8.16684 21C8.4655 21 8.76417 20.8857 8.99167 20.6582L14.0002 15.6497L19.0087 20.6582C19.2362 20.8857 19.5348 21 19.8335 21C20.1322 21 20.4308 20.8857 20.6583 20.6582C21.1145 20.202 21.1145 19.4647 20.6583 19.0085L15.6498 14Z"
        fill="#444444"
      />
    </svg>
  );
};

export default CloseIcon;
