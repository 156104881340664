import React, { FC, useContext } from 'react';
import Edit2Icon from '@icons/Edit2Icon';
import FlashSlash from '@icons/FlashSlash';
import { getNameTrl } from '@utils/helper/getNameTrl';
import { useTranslation } from 'react-i18next';

import { PaymentChangesContext } from '../../hooks/PaymentChangesContext';
import { PaymentField } from '../../utils/models/PaymentField';

import styles from './paymentChange.module.scss';

interface IProps {
  item: PaymentField;
}

const PaymentChange: FC<IProps> = ({ item }) => {
  const { t } = useTranslation();
  const { setContext } = useContext(PaymentChangesContext);

  const handleEdit = () => {
    setContext({ visible: true, item });
  };

  const typeText = {
    TEXT: t('one_lid.Matn'),
    SELECT: t('one_lid.Royxat'),
    DATE: t('home.Sana')
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <h3>{getNameTrl(item)}</h3>
          <p>{typeText[item?.type as keyof typeof typeText]}</p>
        </div>
        <div className={styles.right}>
          <div className={styles.text} style={{ color: item?.required ? '#FF6565' : '' }}>
            {item?.required ? t('home.Majburiy') : t('home.Ixtiyoriy')}
          </div>
          <div className={styles.status} style={{ background: item?.show ? '#00A389' : '' }}>
            <FlashSlash /> {item?.show ? t('home.ACTIVE') : t('home.PASSIVE')}
          </div>
          <div className={styles.edit} onClick={handleEdit}>
            <Edit2Icon color="#0078D2" /> {t('client.Tahrirlash')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentChange;
