import React from 'react';
import EmployeeFilter from '@pages/crm/components/filter/drawer/EmployeeFilter';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { Checkbox, Collapse, Drawer, Radio, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { ClientSelect } from 'modules/client';
import { useAction } from 'modules/event-list';

import ColapseIcon from '../../../../assets/icons/ColapseIcon';
import FilterReloadIcon from '../../../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import DatePicker from '../../../../service/datePicker';
import { DATE_FORMAT } from '../../../../utils/constants/format';

import { PreviousCurrent } from './previous-current';

import styles from './filterDrawer.module.scss';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

type Props = {
  setOpenFiltrDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openFiltrDrawer: boolean;
};

export type ActivityLogs = {
  responsibleById: string | string[] | undefined;
  responsibleByIds?: string | string[] | undefined;
  startDate: string;
  finishDate: string;
  actionTypes: string[] | string | undefined;
  previous: string;
  current: string;
  previousStatuses: string[];
  currentStatuses: string[];
  objectStartDate: string;
  objectFinishDate: string;
  currentTags: string[];
  previousTags: string[];
};

const FiltrDrawer: React.FC<Props> = ({ setOpenFiltrDrawer, openFiltrDrawer }) => {
  const { objectTypes, activityLogs, query, setQuery } = useAction();
  const { removeAllSearchParams, searchParams, appendMultipleDifferent, appendSingle, remove, appendMultiple } = useQueryParam<ActivityLogs, any>();

  const deleteAllFilters = () => {
    removeAllSearchParams();
    setQuery({ tab: query?.tab });
  };

  // handleClose
  const handleClose = () => {
    setOpenFiltrDrawer(false);
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };
  const changeDateObject = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['objectStartDate', 'objectFinishDate'], formatString);
  };

  const changeObjectType = (objectType: string) => {
    appendSingle('objectType', objectType);
  };

  const changeOrderStatus = (value: string) => {
    remove('current', '');
    remove('previous', '');
    if (Array.isArray(searchParams.actionTypes)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.actionTypes.includes(value) ? remove('actionTypes', value) : appendMultiple('actionTypes', value);

      if (searchParams?.actionTypes?.includes('CHANGE_STATUS') || searchParams?.actionTypes?.includes('CREATE_STATUS')) {
        setQuery({
          currentStatuses: undefined,
          previousStatuses: undefined
        });
      }
    } else if (searchParams.actionTypes === value) {
      remove('actionTypes', value);
      if (searchParams?.actionTypes === 'CHANGE_STATUS' || searchParams?.actionTypes === 'CREATE_STATUS') {
        setQuery({
          currentStatuses: undefined,
          previousStatuses: undefined
        });
      }
    } else {
      appendMultiple('actionTypes', value);
    }

    remove('current', undefined);
    remove('previous', undefined);
  };

  return (
    <Drawer width={339} placement="left" visible={openFiltrDrawer} onClose={handleClose} headerStyle={{ display: 'none' }}>
      <div className={styles.filter_header}>
        <p>{t('home.Filtr')}</p>
        <span className={styles.filter_clear} onClick={deleteAllFilters}>
          <FilterReloadIcon />
          {t('home.Tozalash')}
        </span>
      </div>
      <Collapse
        bordered={false}
        className={styles.filterCollapse}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s'
            }}
          >
            <ColapseIcon />
          </span>
        )}
      >
        <Panel header={<>{t('home.Sana')}</>} key="1">
          {/* @ts-expect-error */}
          <RangePicker
            format={DATE_FORMAT}
            value={searchParams.startDate && searchParams.finishDate ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)] : [null, null]}
            onChange={changeDate}
            className="CustomRangePicker"
            placeholder={[t('changes.contract.kk.oo.yyyy'), t('changes.contract.kk.oo.yyyy')]}
          />
        </Panel>
        <EmployeeFilter title="Masul_xodim" labelFilter="responsibleByIds" ids={searchParams.responsibleByIds} type="allEmployees" />
        <Panel header={t('home.Obyekt_turi')} key={2} className={styles.objectTypePanel}>
          <Radio.Group onChange={e => changeObjectType(e.target.value)}>
            {objectTypes?.map(item =>
              item?.disabled ? (
                <Tooltip key={item?.value} title={t('home.Tez_kunda')} placement="rightTop">
                  <Radio value={item?.value} disabled>
                    {item?.label}
                  </Radio>
                </Tooltip>
              ) : (
                <Radio key={item?.value} value={item?.value}>
                  {item?.label}
                </Radio>
              )
            )}
          </Radio.Group>
        </Panel>
        <Panel header={t('home.Voqea_turi')} key={3} className={styles.objectTypePanel}>
          <div className={styles.actionType}>
            {(query?.tab === 'LEAD' ? activityLogs?.actionType : activityLogs?.actionTypeClient)?.map((item, i: number) => (
              <Checkbox
                // eslint-disable-next-line react/no-array-index-key
                key={item.name + i}
                onChange={() => {
                  changeOrderStatus(item?.name);
                }}
                checked={Array.isArray(searchParams.actionTypes) ? searchParams.actionTypes.includes(item?.name) : searchParams.actionTypes === item?.name}
              >
                <p className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap" title={item[`name${textCapitalize(i18n.language)}` as keyof typeof item]?.toString()}>
                  {item[`name${textCapitalize(i18n.language)}` as keyof typeof item]?.toString()}
                </p>
              </Checkbox>
            ))}
          </div>
        </Panel>
        {query?.tab === 'LEAD' ? (
          <Panel header={t('home.Obyekt_yaratilgan_sana')} key={4}>
            {/* @ts-expect-error */}
            <RangePicker
              format={DATE_FORMAT}
              value={
                searchParams.objectStartDate && searchParams.objectFinishDate
                  ? [dayjs(searchParams.objectStartDate, DATE_FORMAT), dayjs(searchParams.objectFinishDate, DATE_FORMAT)]
                  : [null, null]
              }
              onChange={changeDateObject}
              className="CustomRangePicker"
              placeholder={[t('changes.contract.kk.oo.yyyy'), t('changes.contract.kk.oo.yyyy')]}
            />
          </Panel>
        ) : (
          ''
        )}
        <div className={styles.oldLastValue}>
          <PreviousCurrent />
        </div>
        {query?.tab === 'CLIENT' ? (
          <div className="mt-3">
            <ClientSelect value={query?.clientIds} onChange={value => setQuery({ clientIds: value })} enabled={openFiltrDrawer} />
          </div>
        ) : (
          <></>
        )}
      </Collapse>
    </Drawer>
  );
};

export default FiltrDrawer;
