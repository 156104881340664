import React from 'react';
import ExtraInformation from './ExtraInformation';
import styles from './marketPlace.module.scss';

const MarketPlace = () => {
  return (
    <div className={styles.cont}>
      <div className={styles.cont_header}>
        <div className={styles.cont_header_left}>
          <h1>Uysot Marketplace</h1>
          <h2>Xonadonlaringizni marketpleysga chiqaring va sotuv hajmini yanada oshiring</h2>
        </div>
      </div>
      <ExtraInformation />
    </div>
  );
};

export default MarketPlace;
