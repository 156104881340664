import { FC, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import PhoneActiveIcon from '../../../assets/icons/PhoneActiveIcon';
import { useClientSendMessage } from '../services/mutation';

interface ClientMessageModalProps {
  visible: boolean;
  close: () => void;
  clientId: number;
  phoneNumber: string;
  name: string;
  contractNumber?: string;
  modalWidth: number;
}

const ClientMessageModal: FC<ClientMessageModalProps> = ({ visible, close, clientId, contractNumber, phoneNumber, name, modalWidth }) => {
  const [form] = Form.useForm();
  const sendMessageClient = useClientSendMessage();
  const { t } = useTranslation();

  const handleCancel = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (form.__INTERNAL__.name) {
      form.resetFields();
    }
    close();
  };

  const onFinish = (value: any) => {
    sendMessageClient.mutate({
      clientId,
      text: value.comment
    });
  };

  useEffect(() => {
    handleCancel();
  }, [sendMessageClient.isSuccess]);

  return (
    <Modal title={t('client.Xabar_yuborish')} wrapClassName="clientMessageModal" visible={visible} onCancel={handleCancel} footer={false} width={modalWidth}>
      <div>
        <div>
          <h1>{t('client.Mijoz')}</h1>
          <p>{name}</p>
        </div>
        <div>
          <h1>{t('client.Telefon_raqami')}</h1>
          <p>
            {phoneNumber} <PhoneActiveIcon />
          </p>
        </div>
        {contractNumber && (
          <div>
            <h1>{t('home.Shartnoma')}</h1>
            <p>{contractNumber}</p>
          </div>
        )}
      </div>
      <Form onFinish={onFinish} form={form} name="ClientMessageModal">
        <Form.Item
          name="comment"
          rules={[
            {
              required: true,
              message: t('client.Xabar_matnini_kiriting')
            }
          ]}
        >
          <Input.TextArea placeholder={t('client.Xabar_matnini_kiriting')} autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button onClick={handleCancel}>{t('arrearage.Yopish')}</Button>
          <Button type="primary" htmlType="submit" loading={sendMessageClient.isLoading}>
            {t('home.Xabar_yuborish')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ClientMessageModal;
