import React, { FC, useEffect, useRef, useState } from 'react';
import LineGradientLayout from '@components/line-gradient-layout/LineGradientLayout';
import { Input, InputProps, InputRef } from 'antd';
import classNames from 'classnames';

import styles from './customInput.module.scss';

interface IProps extends InputProps {
  hasValue?: boolean;
  ai?: boolean;
  onStopAiTime?: () => void;
}

const CustomInput: FC<IProps> = ({ className, hasValue, ai, onStopAiTime, ...props }) => {
  const inputRef = useRef<InputRef | null>(null);
  const [opacity, setOpacity] = useState(0);
  const value = props?.value?.toString() || '';

  useEffect(() => {
    if (ai) {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
      if (onStopAiTime) {
        setTimeout(
          () => {
            onStopAiTime();
            setOpacity(0);
          },
          (value?.length / 6) * 1000
        );
      }
    }
  }, [ai, value]);

  const css = inputRef?.current?.input ? window?.getComputedStyle(inputRef?.current?.input) : ({} as CSSStyleDeclaration);

  const getStyles: React.CSSProperties | undefined = {
    fontSize: css?.fontSize,
    fontWeight: css?.fontWeight,
    width: css?.width?.replace(/\D/g, '') ? css?.width : 219,
    padding: css?.padding,
    fontStyle: css?.fontStyle,
    height: css?.height,
    color: css?.color,
    lineHeight: css?.lineHeight,
    borderRadius: css?.borderRadius,
    paddingTop: css?.paddingTop,
    paddingBottom: css?.paddingBottom,
    border: css?.border
  };

  return (
    <LineGradientLayout loading={ai}>
      <>
        {ai && (
          <div className={styles.input} style={{ ...getStyles }}>
            {(props?.value?.toString() || '')?.split('').map((item, i) => (
              <span key={item} style={{ transitionDelay: `${i / 8}s`, opacity }}>
                {item}
              </span>
            ))}
          </div>
        )}
        <Input
          className={classNames('customInput', className)}
          {...props}
          onChange={props?.onChange}
          value={props?.value}
          ref={inputRef}
          style={{ display: ai ? 'none' : '', ...props.style }}
        />
      </>
    </LineGradientLayout>
  );
};

export default CustomInput;
