import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { crmConstants, crmModels } from '..';

export const useLeadEmployeeAll = () =>
  useQuery<crmModels.ILeadEmployee[]>({
    queryKey: [crmConstants.queryKeys.LEAD_EMPLOYEE_ALL],
    queryFn: async () => {
      const res = await $api.get('/v1/lead/employee/all');

      return res.data.data;
    }
  });
