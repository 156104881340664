import React from 'react';

const Setting3Icon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49935 18.3333H12.4993C16.666 18.3333 18.3327 16.6666 18.3327 12.5V7.49996C18.3327 3.33329 16.666 1.66663 12.4993 1.66663H7.49935C3.33268 1.66663 1.66602 3.33329 1.66602 7.49996V12.5C1.66602 16.6666 3.33268 18.3333 7.49935 18.3333Z"
        stroke="#344054"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.9746 15.4166V12.1666" stroke="#344054" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9746 6.20837V4.58337" stroke="#344054" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.9753 10.5417C14.1719 10.5417 15.1419 9.57166 15.1419 8.37504C15.1419 7.17842 14.1719 6.20837 12.9753 6.20837C11.7786 6.20837 10.8086 7.17842 10.8086 8.37504C10.8086 9.57166 11.7786 10.5417 12.9753 10.5417Z"
        stroke="#344054"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.02539 15.4166V13.7916" stroke="#344054" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.02539 7.83337V4.58337" stroke="#344054" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.02409 13.7917C8.22071 13.7917 9.19075 12.8217 9.19075 11.625C9.19075 10.4284 8.22071 9.45837 7.02409 9.45837C5.82747 9.45837 4.85742 10.4284 4.85742 11.625C4.85742 12.8217 5.82747 13.7917 7.02409 13.7917Z"
        stroke="#344054"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Setting3Icon;
