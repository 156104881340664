import React, { useState } from 'react';
import CostDrawer from '@components/bossCrm/priceOneClient/costDrawer/CostDrawer';
import SettingsIcon from '@icons/settings-icon';
import { useGetLeadAndCLientCost, useGetLeadAndClientCostStatistics } from '@pages/bossCrm/services/queries';
import { isEmptyArr } from '@utils/index';
import { Spin } from 'antd';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

const Center: React.FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { leadAndClientCost, isLoading } = useGetLeadAndCLientCost();
  const { leadStats, clientStats, isLoading: isLoadingStats } = useGetLeadAndClientCostStatistics();

  const onOpen = () => setVisible(true);

  const onClose = () => setVisible(false);

  return (
    <div className="box-border rounded-xl border border-solid border-gray-300 p-5">
      <div className="mb-8 flex items-center justify-between">
        <h3 className="m-0 text-2xl font-bold text-gray-700">{t('crm_plan_fact.Lead va mijoz narxi')}</h3>
        <div onClick={onOpen} className="flex cursor-pointer items-center justify-center">
          <SettingsIcon />
        </div>
      </div>
      <Spin spinning={isLoading || isLoadingStats}>
        <div className="grid grid-cols-2 gap-7">
          <div className="flex flex-col gap-4">
            <div className="box-border flex flex-col gap-3 rounded-xl bg-gray-100 px-6 py-4">
              <span className="text-xl font-medium text-gray-500">{t('crm_plan_fact.Lead uchun o‘rtacha to‘lov')}</span>
              <h1 className="m-0 text-4xl font-medium text-black">{leadStats?.overallAveragePrice?.toLocaleString('ru')}</h1>
            </div>
            <ConditionalRender if={!isEmptyArr(leadAndClientCost)}>
              <div className="flex flex-col gap-8 rounded-xl border border-solid border-gray-300 p-5">
                {leadAndClientCost?.map(({ source, id }) => (
                  <div className="flex items-center justify-between" key={id}>
                    <div className="flex flex-col">
                      <span className="text-sm font-normal text-gray-400">{source}</span>
                      <h3 className="m-0 text-2xl font-semibold text-gray-700">
                        {leadStats?.sourceList?.[source]?.count || 0} {t('crm_plan_fact.ta')}
                      </h3>
                    </div>
                    <span className="text-2xl font-semibold text-gray-700">{leadStats?.sourceList?.[source]?.averageAmount?.toLocaleString('ru') || "--"}</span>
                  </div>
                ))}
              </div>
            </ConditionalRender>
          </div>
          <div className="flex flex-col gap-4">
            <div className="box-border flex flex-col gap-3 rounded-xl bg-gray-100 px-6 py-4">
              <span className="text-xl font-medium text-gray-500">{t('crm_plan_fact.Mijoz uchun o‘rtacha to‘lov')}</span>
              <h1 className="m-0 text-4xl font-medium text-black">{clientStats?.overallAveragePrice?.toLocaleString('ru')}</h1>
            </div>
            <ConditionalRender if={!isEmptyArr(leadAndClientCost)}>
              <div className="flex flex-col gap-8 rounded-xl border border-solid border-gray-300 p-5">
                {leadAndClientCost?.map(({ source, id }) => (
                  <div className="flex items-center justify-between" key={id}>
                    <div className="flex flex-col">
                      <span className="text-sm font-normal text-gray-400">{source}</span>
                      <h3 className="m-0 text-2xl font-semibold text-gray-700">
                        {clientStats?.sourceList?.[source]?.count || 0} {t('crm_plan_fact.ta')}
                      </h3>
                    </div>
                    <span className="text-2xl font-semibold text-gray-700">{clientStats?.sourceList?.[source]?.averageAmount?.toLocaleString('ru') || '--'}</span>
                  </div>
                ))}
              </div>
            </ConditionalRender>
          </div>
        </div>
      </Spin>
      <CostDrawer visible={visible} onClose={onClose} />
    </div>
  );
};

export default Center;
