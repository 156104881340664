import { useQuery } from 'react-query';

import { $api } from '../../../service/RequestService';
import { getDebitsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { DebtList } from '../../../utils/models/DebtList';
import { Pagination } from '../../../utils/models/Pagination';
import { SearchParamsArrearage } from '../Arrearage';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useDebitList(size: number, searchObj: SearchParamsArrearage) {
  return useQuery<Pagination<DebtList>>([queryKeys.DEBIT_LIST, searchObj.page, JSON.stringify(searchObj)], async () => {
    const res = await $api.post(endPoints.DEBT, getDebitsBody(size, searchObj));

    return res.data.data;
  });
}
