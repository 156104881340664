import React from 'react';
import { KpiItem, KpiPart } from '@utils/models/Kpi';
import { Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import CalculateWithPercent from '../../components/kpi/CalculateWithPercent';
import CalculateWithSum from '../../components/kpi/CalculateWithSum';
import { useKpiConfig } from '../../queries/queries';

import styles from './kpi.module.scss';

const { Content } = Layout;

const Kpi = () => {
  const { t } = useTranslation();
  const { isLoading, data, isFetching } = useKpiConfig();

  const getPercent = () => {
    const finalData: KpiItem[] = [];

    data?.DYNAMIC?.forEach(item => {
      let constKpiPart: KpiPart = {
        kpiId: 0,
        id: 0,
        amount: 0,
        count: 0
      };
      const kpiParts: KpiPart[] = [];

      item.kpiPartList.forEach(part => {
        if (part.count === 0) {
          constKpiPart = part;
        } else {
          kpiParts.push(part);
        }
      });

      finalData.push({
        ...item,
        costKpi: constKpiPart,
        kpiPartList: kpiParts
      });
    });
    return finalData;
  };

  const getSum = () => {
    const finalData: KpiItem[] = [];

    data?.STATIC?.forEach(item => {
      let constKpiPart: KpiPart = {
        kpiId: 0,
        id: 0,
        amount: 0,
        count: 0
      };
      const kpiParts: KpiPart[] = [];

      item.kpiPartList.forEach(part => {
        if (part.count === 0) {
          constKpiPart = part;
        } else {
          kpiParts.push(part);
        }
      });

      finalData.push({
        ...item,
        costKpi: constKpiPart,
        kpiPartList: kpiParts
      });
    });
    return finalData;
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.cont}>
          <Spin spinning={isLoading || isFetching}>
            <div className={styles.cont_header}>
              <h1>{t('kpi.KPI_hisob_kitob_sozlamalari')}</h1>
              <h2>{t('kpi.Ushbu_sozlama_xodim_va_vositachilarning_ish_samaradorligiga_qarab_ularni_ragbatlantirish_formulalarini_kiritish')}</h2>
            </div>
            <div className={styles.cont_body}>
              <CalculateWithPercent kpi={getPercent()?.filter(item => item?.id)} />
              <hr />
              <CalculateWithSum kpi={getSum()?.filter(item => item?.id)} />
            </div>
          </Spin>
        </div>
      </Content>
    </>
  );
};

export default Kpi;
