import React from 'react';
import { Col, Row, Spin } from 'antd';
import ClientAndContractInfo from '../ClientAndContractInfo';
import styles from './payments.module.scss';
import CardAndButton from './CardAndButton';
import TablePayments from './TablePayments';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import { usePaymentsChangePage } from '../../services/queries';

const Payments = () => {
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();
  const { isLoading, data } = usePaymentsChangePage(searchParams.contractId);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.cont}>
        <Row gutter={36}>
          <Col span={15}>
            <CardAndButton sumPayment={data?.payed || 0} />
            <TablePayments data={data?.payments || []} />
          </Col>
          <Col span={9}>
            <ClientAndContractInfo />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Payments;
