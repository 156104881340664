import React, { useState } from 'react';
import styles from './extraInformationFlat.module.scss';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlatFieldGet } from '../../../../queries/queries';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import CloseCircle from '../../../../assets/icons/CloseCircleIcon';
import { FlatId } from '../../../../utils/models/Flat';
import DropMenuIcon from '../../../../assets/icons/DropMenuIcon';

interface Props {
  flat: FlatId;
  prePayment: number;
  totalPrice: number;
  discountAmount: number;
}

const ExtraInformationFlat: React.FC<Props> = ({ flat, prePayment, discountAmount, totalPrice }) => {
  const [visibleExtraInformation, setVisibleExtraInformation] = useState(false);

  const { t } = useTranslation();

  const { data } = useFlatFieldGet();

  // handle visible extra information
  const handleExtraInformation = (visible: boolean) => {
    setVisibleExtraInformation(visible);
  };

  // get monthly payment
  const getMonthlyPayment = () => {
    return roundFloatNumber((totalPrice - prePayment - discountAmount) / flat.delay).toLocaleString('ru');
  };

  return (
    <Dropdown
      overlayClassName={styles.container_dropdown}
      visible={visibleExtraInformation}
      onVisibleChange={handleExtraInformation}
      overlay={
        <>
          <div className={styles.container_dropdown_header}>
            <p>{t('home_id.Xonadon_malumotlari')}</p>
            <CloseCircle onClick={() => handleExtraInformation(false)} />
          </div>
          <div className={styles.container_dropdown_grey}>
            <span className={styles.container_dropdown_grey_label}>{t('home_id.Bino')}</span>
            <span className={styles.container_dropdown_grey_value}>{flat.building.name}</span>
          </div>
          <div className={styles.container_dropdown_white}>
            <span className={styles.container_dropdown_white_label}>{t('home_id.Qavat')}</span>
            <span className={styles.container_dropdown_white_value}>{flat.floor}</span>
          </div>
          <div className={styles.container_dropdown_grey}>
            <span className={styles.container_dropdown_grey_label}>{t('home_id.Xonadon_raqami')}</span>
            <span className={styles.container_dropdown_grey_value}>{flat.number}</span>
          </div>
          <div className={styles.container_dropdown_white}>
            <span className={styles.container_dropdown_white_label}>{t('home_id.Xonalar_soni')}</span>
            <span className={styles.container_dropdown_white_value}>{flat.rooms}</span>
          </div>
          {flat.terrace?.area ? (
            <div className={styles.container_dropdown_grey}>
              <span className={styles.container_dropdown_grey_label}>{t('home_id.Umumiy_maydoni')}</span>
              <span className={styles.container_dropdown_grey_value}>
                {roundFloatNumber(flat.area + flat.terrace?.area || 0)} {t('create_table_payments.m2')}
              </span>
            </div>
          ) : undefined}
          <div className={styles.container_dropdown_white}>
            <span className={styles.container_dropdown_white_label}>{t('home_id.Xonadon_maydoni')}</span>
            <span className={styles.container_dropdown_white_value}>
              {flat.area} {t('create_table_payments.m2')}
            </span>
          </div>
          {flat.terrace?.area ? (
            <div className={styles.container_dropdown_grey}>
              <span className={styles.container_dropdown_grey_label}>{t('home_id.Terassa_maydoni')}</span>
              <span className={styles.container_dropdown_grey_value}>
                {flat.terrace?.area || 0} {t('create_table_payments.m2')}
              </span>
            </div>
          ) : undefined}
          <div className={styles.container_dropdown_white}>
            <span className={styles.container_dropdown_white_label}>
              {t('home_id.Boshlangich_tolov')} ({flat.prepaymentPercent}%)
            </span>
            <span className={styles.container_dropdown_white_value}>
              {prePayment?.toLocaleString('ru')} {flat?.currency?.ccy}
            </span>
          </div>
          <div className={styles.container_dropdown_grey}>
            <span className={styles.container_dropdown_grey_label}>{t('home_id.Tolov_muddati')}</span>
            <span className={styles.container_dropdown_grey_value}>
              {flat.delay} {t('home_id.oy')}
            </span>
          </div>
          <div className={styles.container_dropdown_white}>
            <span className={styles.container_dropdown_white_label}>{t('home_id.Oylik_tolov')}</span>
            <span className={styles.container_dropdown_white_value}>
              {getMonthlyPayment()} {flat?.currency?.ccy}
            </span>
          </div>
          {flat.fields?.map((item, index) => {
            if (data) {
              const findField = data.find(field => item.fieldId === field.id);
              if (index % 2 === 0)
                return (
                  <div key={index} className={styles.container_dropdown_grey}>
                    <span className={styles.container_dropdown_grey_label}>{findField?.name}</span>
                    <span className={styles.container_dropdown_grey_value}>{item.value}</span>
                  </div>
                );
              return (
                <div key={index} className={styles.container_dropdown_white}>
                  <span className={styles.container_dropdown_white_label}>{findField?.name}</span>
                  <span className={styles.container_dropdown_white_value}>{item.value}</span>
                </div>
              );
            }
            return null;
          })}
        </>
      }
      placement={'bottom'}
      trigger={['click']}
    >
      <p onClick={() => handleExtraInformation(true)} className={styles.container_name}>
        <DropMenuIcon />
        <span className={styles.container_name_house}>{flat.houseName}</span>
        <span className={styles.container_name_number}>{flat.building.name}</span>
        <span className={styles.container_name_number}>№ {flat.number}</span>
      </p>
    </Dropdown>
  );
};

export default ExtraInformationFlat;
