import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import FilterIcon from '../../../../assets/icons/FilterIcon';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useTableConfigCustom } from '../../../../queries/queries';
import { TableKeyCustom } from '../../../../utils/models/TableKey';
import CrmSearch from '../voronkaCards/crm-search/CrmSearch';

import { CrmFilterSaveData } from './drawer/crm-filter-save/utils/models/CrmFilterSaveData';
import FilterDrawer from './drawer/FilterDrawer';
import SearchModal from './search-modal/SearchModal';

import styles from './filterButton.module.scss';

export interface SearchParamsCrm {
  page?: string;
  size?: string;
  pipeStatusIds?: string[] | string;
  search?: string;
  startDate?: string;
  finishDate?: string;
  responsibleById?: string[];
  createdById?: string[];
  balanceStart?: string;
  balanceFinish?: string;
  tagIds?: string[];
  taskStartDate?: string;
  taskFinishDate?: string;
  voronka?: string;
  reasonsFRIds?: [string | null];
  leadDeletedType?: string;
  fields?: any;
  multiField?: string[];
  dashboardId?: string;
  clientIds?: string[];
}

const FilterButton = () => {
  const { t } = useTranslation();
  const { data: crmFilter } = useTableConfigCustom<CrmFilterSaveData>(TableKeyCustom.crm_filter);
  const { remove, appendSingle, searchParams, location, push } = useQueryParam<SearchParamsCrm, any>();
  const inputRef = useRef<any>(null);
  const [filterDriverOpen, setFilterDriverOpen] = useState(false);
  const [countFilters, setCountFilters] = useState(0);
  const [searchValue, setSearchValue] = useState(searchParams.search!);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (crmFilter?.filters && crmFilter?.filters?.length > 0) {
      const activeUrl = crmFilter?.filters?.find(item => item?.id === crmFilter?.active)?.url;

      if (crmFilter?.active && location?.search !== activeUrl) {
        push({ pathname: location.pathname, search: activeUrl });
      }
    }
    // eslint-disable-next-line
  }, [crmFilter, location.pathname]);

  useEffect(() => {
    // url has one no filter params
    let count = -1;

    if (searchParams.pipeStatusIds) {
      if (Array.isArray(searchParams.pipeStatusIds)) {
        count += searchParams.pipeStatusIds.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.tagIds) {
      if (Array.isArray(searchParams.tagIds)) {
        count += searchParams.tagIds.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.responsibleById) {
      if (Array.isArray(searchParams.responsibleById)) {
        count += searchParams.responsibleById.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.createdById) {
      if (Array.isArray(searchParams.createdById)) {
        count += searchParams.createdById.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.fields) {
      if (Array.isArray(searchParams.fields)) {
        count += searchParams.fields.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.reasonsFRIds) {
      if (Array.isArray(searchParams.reasonsFRIds)) {
        count += searchParams.reasonsFRIds.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.multiField) {
      if (Array.isArray(searchParams.multiField)) {
        count += searchParams.multiField.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.taskStartDate && searchParams.taskFinishDate) {
      count += 1;
    }
    if (searchParams.startDate && searchParams.finishDate) {
      count += 1;
    }
    if (searchParams.balanceFinish) {
      count += 1;
    }
    if (searchParams.balanceStart) {
      count += 1;
    }
    if (searchParams.leadDeletedType) {
      count += 1;
    }
    setCountFilters(count);
  }, [searchParams]);

  // handle filter drawer
  const handleFilterDriver = (visible: boolean) => {
    setFilterDriverOpen(visible);
  };

  // handleSearch
  const handleSearch = (value: string) => {
    setOpenSearchBar(false);
    setTimeout(() => {
      if (value.length >= 3) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const handleOpenSearchModal = () => {
    inputRef.current?.focus();
    if (window.innerWidth <= 1500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {countFilters === -1 ? (
          <Button onClick={() => handleFilterDriver(true)} className={styles.container_button}>
            <FilterIcon />
            <span>{t('crm_filter.Filtr')}</span>
          </Button>
        ) : (
          <Button onClick={() => handleFilterDriver(true)} className={styles.container_button_has_filter}>
            <FilterIcon />
            <span className={styles.container_button_has_filter_title}>{t('crm_filter.Filtr')}</span>
            <span className={styles.container_button_has_filter_count}>{countFilters + 1}</span>
          </Button>
        )}
        <Dropdown visible={openSearchBar} overlay={<CrmSearch foo={searchValue} />}>
          <div className={styles.search} onClick={handleOpenSearchModal}>
            <Input
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value);
                setOpenSearchBar(!!e.target.value);
              }}
              onPressEnter={(e: any) => {
                handleSearch(e.target.value);
              }}
              className={styles.container_input}
              placeholder={t('crm_filter.Bitimlarni_izlash')}
              suffix={<SearchIcon />}
              style={{ width: searchValue ? 524 : 'auto' }}
            />
          </div>
        </Dropdown>
      </div>
      <FilterDrawer visible={filterDriverOpen} onClose={handleFilterDriver} />
      <SearchModal setVisible={setVisible} visible={visible} inputRef={inputRef} />
    </>
  );
};

export default FilterButton;
