/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { cx } from '@utils/cx';
import { Avatar as AvatarAnt, Menu, Spin } from 'antd';
// import DeleteIcon from './assets/icons/DeleteIcon';
import { Button, Dropdown } from 'global';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useEditProfile, useUploadFile } from '../../../queries/mutation';
import { useGetImageByImageId } from '../../../queries/queries';
import { BASE_URL } from '../../../service/RequestService';
import { addAvatar, clearAvatar } from '../../../store/reducers/UserSlice';
import { FileObject } from '../../../utils/models/File';
import { Profile } from '../../../utils/models/Profile';

import GoogleIcon from './assets/icons/Googleicon';
import LogOutGoogleIcon from './assets/icons/LogOutGoogleIcon';
import { useLogOutGoogleAuth } from './service/queries/mutation';

import styles from '../userInformation.module.scss';
import { Permissions } from '@utils/constants/permissions';

interface Props {
  profile?: Profile;
  refetch?: () => void;
}

const Avatar: React.FC<Props> = ({ profile, refetch }) => {
  const inputUpload = useRef();
  const editProfile = useEditProfile();

  const { data, isLoading, isFetching } = useGetImageByImageId(profile?.avatarId || 0);
  const logOutFromGoogle = useLogOutGoogleAuth();

  const userAccessToken = useAppSelector(state => state.userReducer.user?.accessToken);
  const googleAuthUrl = `${BASE_URL}/v1/google/auth?state=${userAccessToken}`;

  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);
  const isGoogleCalendar = permissions?.includes(Permissions.PERMISSION_GOOGLE_CALENDAR);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // get uploaded file
  function getFile(data: FileObject[]) {
    if (data.length > 0 && profile) {
      dispatch(addAvatar(data[0].thumbAbsolutePath as string));
      editProfile.mutate({
        ...profile,
        avatarId: data[0].id
      });
    }
  }

  const uploadFile = useUploadFile(getFile);

  // handle remove avatar from local storage
  const handleRemoveAvatar = () => {
    dispatch(clearAvatar());
  };

  // upload image
  const handleUploadInput = (e: any) => {
    if (e.target?.files[0] && e.target.files[0].size <= 10000000) {
      const file = e.target.files[0];
      const formData = new FormData();

      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  // for handle upload
  const handleUpload = () => {
    // @ts-ignore
    inputUpload.current.click();
  };

  // delete avatar
  const handleDeleteAvatar = () => {
    if (profile && profile.avatarId) {
      editProfile.mutate({
        ...profile,
        avatarId: null
      });
      handleRemoveAvatar();
    }
  };

  return (
    <Spin spinning={isLoading || isFetching || editProfile.isLoading || uploadFile.isLoading}>
      <AvatarAnt
        src={data?.fileObject.absolutePath && `${BASE_URL}/${data?.fileObject.thumbAbsolutePath || data?.fileObject.absolutePath}`}
        className={cx(styles.container_avatar)}
        icon={<UserOutlined />}
      />

      <div className={styles.container_avatar_buttons}>
        <div className='mt-[-20px] mb-[30px]'>
          <Dropdown trigger={['click']} overlay={
            <Menu className={styles.dropdownMenu}>
              <Menu.Item className={styles.menuItem} onClick={handleUpload}>{t('profile.Yangilash')}</Menu.Item>
              <Menu.Item className={styles.menuItem} onClick={handleDeleteAvatar}>{t('profile.Ochirish')}</Menu.Item>
            </Menu>
          }>
            <Button
              className='flex items-center justify-center border-solid border-[#E5E7EB] border-[1px] py-[5px] px-[20px] gap-[8px] rounded-[8px] cursor-pointer'
            >
              {t('integration.Tahrirlash')}
            </Button>
          </Dropdown>
        </div>

        {isGoogleCalendar && (
          <>
            <h3 className='text-[#464255] text-[15px] mb-[5px] flex items-center justify-center'>{profile?.googleAccountDto?.email}</h3>

            {!profile?.googleAccountDto?.email && (
              <a
                href={googleAuthUrl}
                className='flex items-center justify-center border-solid border-[#E5E7EB] border-[1px] py-[10px] px-[30px] gap-[8px] rounded-[8px] cursor-pointer mt-[-25px]'
              >
                <GoogleIcon />
                <p className='text-[#0000008A] text-[14px] font-medium leading-4'>{t('home.Akkaunt_biriktirish')}</p>
              </a>
            )}

            {profile?.googleAccountDto?.email && (
              <div
                onClick={() => logOutFromGoogle.mutateAsync({}).then(refetch)}
                className='flex items-center justify-center border-solid border-[#E5E7EB] border-[1px] py-[10px] px-[30px] gap-[8px] rounded-[8px] cursor-pointer'
              >
                <LogOutGoogleIcon />
                <p className='text-[#0000008A] text-[14px] font-medium leading-4'>{t('home.Akkaunt_ochirish')}</p>
                {logOutFromGoogle?.isLoading && (
                  <LoadingOutlined />
                )}
              </div>
            )}
          </>
        )}
      </div>
      <input onChange={handleUploadInput} accept={'image/*'} ref={inputUpload as any} type="file" className="d-none" />
    </Spin>
  );
};

export default Avatar;
