import React, { useEffect, useState } from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useBasicCurrency, useBossComplexCapital } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { START_DATE_DEFAULT } from '../../utils/constants/startDateDefault';
import { priceSeparator } from '../../utils/helper/priceSeparator';
import { BoosComplexSelectType } from '../../utils/models/BoosComplexSelectType';

import PriceBlockModal from './PriceBlockModal';
import SpecialTimeModal from './SpecialTimeModal';

import styles from './complexBarChart.module.scss';

const { Option } = Select;

const ComplexBarChart = () => {
  const [time, setTime] = useState({
    startDate: '',
    finishDate: ''
  });
  const [selectType, setSelectType] = useState<BoosComplexSelectType | (string & {})>(BoosComplexSelectType.NOW);
  const [specialModal, setSpecialModal] = useState(false);
  const [chartData, setChartData] = useState<BarDatum[]>([]);
  const [priceBlocksModal, setPriceBlocksModal] = useState({
    visible: false,
    id: 0
  });
  const { data, isFetching, isLoading } = useBossComplexCapital(time.startDate, time.finishDate);

  const { data: currency } = useBasicCurrency();


  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const newChartData: BarDatum[] = [];

      data.forEach(item => {
        const newItem: BarDatum = {
          date: item.name,
          payment: item.totalValue,
          paymentColor: '#917EF7',
          id: item.id
        };

        newChartData.push(newItem);
      });
      setChartData(newChartData);
    }
  }, [data]);

  // handle price blocks modal
  const handlePriceBlocksModal = (id: number, visible: boolean) => {
    setPriceBlocksModal({
      id,
      visible
    });
  };

  // set now
  const setNow = () => {
    setTime({
      startDate: START_DATE_DEFAULT,
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set today
  const setToday = () => {
    setTime({
      startDate: dayjs(new Date()).format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set yesterday
  const setYesterday = () => {
    setTime({
      startDate: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set this week
  const setThisWeek = () => {
    if (dayjs(new Date()).startOf('week').format(DATE_FORMAT) === dayjs(new Date()).format(DATE_FORMAT)) {
      setTime({
        startDate: dayjs(new Date()).add(-6, 'day').format(DATE_FORMAT),
        finishDate: dayjs(new Date()).format(DATE_FORMAT)
      });
    } else {
      setTime({
        startDate: dayjs(new Date()).startOf('week').add(1, 'day').format(DATE_FORMAT),
        finishDate: dayjs(new Date()).format(DATE_FORMAT)
      });
    }
  };

  // set this month
  const setThisMonth = () => {
    setTime({
      startDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // handle special modal
  const handleSpecialModal = (visible: boolean) => {
    setSpecialModal(visible);
  };

  // set special
  const setSpecial = () => {
    handleSpecialModal(true);
  };

  // handle select date
  const handleSelectDate = (date: string[]) => {
    setSelectType(`${date[0]} - ${date[1]}`);

    setTime({
      startDate: date[0],
      finishDate: date[1]
    });
  };

  // handle select
  const handleSelect = (value: BoosComplexSelectType | (string & {})) => {
    switch (value) {
      case BoosComplexSelectType.TODAY:
        setToday();
        setSelectType(value);
        break;
      case BoosComplexSelectType.YESTERDAY:
        setYesterday();
        setSelectType(value);
        break;
      case BoosComplexSelectType.THIS_WEEK:
        setThisWeek();
        setSelectType(value);
        break;
      case BoosComplexSelectType.THIS_MONTH:
        setThisMonth();
        setSelectType(value);
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        break;
      default:
        setNow();
        setSelectType(value);
        break;
    }
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h3>{t('boss_complex.Turar_joy_majmualari_boyicha')}</h3>
          <Select
            onChange={handleSelect}
            value={selectType}
            className={`${styles.container_header_select} customSelect`}
            style={{ width: Object.keys(BoosComplexSelectType).includes(selectType) ? '' : 220 }}
          >
            <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
            <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
            <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
            <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
            <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
            <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option>
          </Select>
        </div>
        <div className={styles.container_body}>
          <ResponsiveBar
            onClick={e => handlePriceBlocksModal(Number(e?.data?.id), true)}
            data={chartData}
            keys={['payment']}
            indexBy="date"
            margin={{ top: 34, right: 100, bottom: 80, left: 100 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={() => '#917EF7'}
            axisLeft={{
              format: value => priceSeparator(value)
            }}
            borderRadius={5}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: 'fries'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'sandwich'
                },
                id: 'lines'
              }
            ]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            tooltip={e => {
              const item = e.data;

              return (
                <div className={styles.container_body_tooltip}>
                  <div className={styles.container_body_tooltip_label}>{item.date}</div>
                  <div className={styles.container_body_tooltip_value}>
                    {item?.payment.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
              );
            }}
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            // eslint-disable-next-line react/jsx-no-bind, func-names
            barAriaLabel={function (e) {
              return `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`;
            }}
          />
        </div>
      </div>
      <PriceBlockModal startDate={time.startDate} finishDate={time.finishDate} id={priceBlocksModal.id} visible={priceBlocksModal.visible} close={handlePriceBlocksModal} />
      <SpecialTimeModal visible={specialModal} close={handleSpecialModal} handleSelect={handleSelectDate} />
    </Spin>
  );
};

export default ComplexBarChart;
