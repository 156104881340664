import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { queryNames } from '@utils/constants/queryNames';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { CauseModel } from '../utils/models/CauseModel';

export function useCreateCause() {
  return useMutation({
    mutationFn: async (data: CauseModel[]) => {
      const res = await $api.post(endPoints.PIPE_CAUSE, data);

      return res.data.data;
    }
  });
}

export function useCreateVoronkaShablon() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const res = await $api.post(endPoints.PIPE_TEMPLATE, data);

      return res.data;
    },
    onSuccess: (data: any) => {
      qc.invalidateQueries({ queryKey: [queryKeys.PIPE_TEMPLATE_ALL] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}

export function useUpdatePipeTemplate() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const res = await $api.put(endPoints.PIPE_TEMPLATE, data);

      return res.data;
    },
    onSuccess: (data: any) => {
      console.log(data);
      qc.invalidateQueries({ queryKey: [queryKeys.PIPE_TEMPLATE_ALL] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}
export function useDeletePipeTemplate() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const res = await $api.delete(`${endPoints.PIPE_TEMPLATE}/${id}`);

      return res.data;
    },
    onSuccess: (data: any) => {
      qc.invalidateQueries({ queryKey: [queryKeys.PIPE_TEMPLATE_ALL] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}

export function useCreatePipeStatus() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const res = await $api.post(endPoints.PIPE, data);

      return res.data;
    },
    onSuccess: (data: any) => {
      qc.invalidateQueries({ queryKey: [queryNames.PIPE_ALL] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}
