import React, { useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import styles from '../addCard.module.scss';
import { TypePage } from '../AddCardCont';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { Card, CreateCardResponse } from '../../../../../utils/models/Card';
import { useActivateCardUzcard, useCreateCardResentCodeUzcard, useCreateCardVerifyUzcard } from '../../../../../queries/mutation';
import ChevronLeft from '../../../../../assets/icons/ChevronLeft';
import ErrorIcon from '../../../../../assets/icons/ErrorIcon';
import VuesaxCardIcon from '../../../../../assets/icons/VuesaxCardIcon';

interface Props {
  card?: Card;
  id: number;
  changePage: (page: TypePage) => void;
  name: string;
}

const ActivateCardPayme: React.FC<Props> = ({ card, id, changePage, name }) => {
  const codeInputRef = useRef(null);
  const cardResponse = useRef<CreateCardResponse>();

  const [errorCode, setErrorCode] = useState(false);
  const [time, setTime] = useState(0);
  const [showVerify, setShowVerify] = useState(false);

  const activateCard = useActivateCardUzcard(onSuccess);
  const verifyCard = useCreateCardVerifyUzcard(id, handleChangePage);
  const resentCode = useCreateCardResentCodeUzcard(onSuccess);

  const { t } = useTranslation();

  function onSuccess(data: CreateCardResponse) {
    setTime(120);
    setShowVerify(true);
    cardResponse.current = data;
    // @ts-ignore
    codeInputRef.current!.focus({
      cursor: 'start'
    });
  }

  function handleChangePage() {
    changePage(TypePage.LIST);
  }

  const handleFinishCode = (val: { code: string }) => {
    setErrorCode(false);
    verifyCard.mutate({
      code: val.code,
      cardId: cardResponse?.current?.cardId || 0,
      session: cardResponse?.current?.session || 0
    });
  };

  const onFinishFailedCode = () => {
    setErrorCode(true);
  };

  const handleActivate = () => {
    activateCard.mutate(card?.id || 0);
  };

  const resentCodeHandler = () => {
    if (cardResponse.current) resentCode.mutate(cardResponse.current?.cardId);
  };

  const getTime = () => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      const minute = Math.floor(time / 60) >= 10 ? Math.floor(time / 60) : '0' + Math.floor(time / 60);
      const second = time % 60 >= 10 ? time % 60 : '0' + (time % 60);
      return `${minute}:${second}`;
    } else {
      return (
        <div onClick={resentCodeHandler} className={styles.cont_inputs_cont_timer_cont_time_resent}>
          {t('contract.Qayta_yuborish')}
        </div>
      );
    }
  };

  const getName = () => {
    if (name.length > 17) return name.substring(0, 17) + '...';
    return name;
  };

  return (
    <div>
      <Button onClick={handleChangePage} className={styles.title_button}>
        <ChevronLeft />
        <span>{t('contract.Orqaga')}</span>
      </Button>
      <div className={styles.cont_new}>
        <div className={styles.cont_inputs_cont}>
          <div className={styles.cont_card + ' ' + styles['cont_card_' + card?.style]}>
            <div className={styles.cont_card_uzcard} />
            <div className={styles.cont_card_body}>
              <div className={styles.cont_card_body_number}>{card?.number}</div>
              <div className={styles.cont_card_body_expired}>{card?.expire}</div>
              <div className={styles.cont_card_body_name_cont}>
                <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
              </div>
            </div>
          </div>
          <div className={styles.cont_inputs_cont_activate_message}>
            <ErrorIcon />
            <span>{t('contract.Karta_aktiv_holatda_emas')}</span>
          </div>
          <div className={styles.cont_inputs_cont_label}>{t('contract.Karta_raqami')}</div>
          <div className={styles.cont_inputs_cont_disabled_input}>
            <div className={styles.cont_inputs_cont_disabled_input_number_cont}>
              <VuesaxCardIcon />
              <div className={styles.cont_inputs_cont_disabled_input_number_cont_number}>{card?.number}</div>
            </div>
            <div className={styles.cont_inputs_cont_disabled_input_expired}>{card?.expire}</div>
          </div>
          <div className={styles.cont_inputs_cont_label}>{t('uzcard.Telefon_raqami')}</div>
          <div className={styles.cont_inputs_cont_disabled_input}>
            <div className={styles.cont_inputs_cont_disabled_input_number_cont}>
              <div className={styles.cont_inputs_cont_disabled_input_number_cont_number}>+{card?.phone}</div>
            </div>
          </div>
          {showVerify ? (
            <Form onFinishFailed={onFinishFailedCode} onFinish={handleFinishCode} name={'codeForm'}>
              <div className={styles.cont_inputs_cont_timer_cont}>
                <div className={styles.cont_inputs_cont_label}>{t('contract.Sizga_SMS_xabar_yuborildi')}</div>
                <div className={styles.cont_inputs_cont_timer_cont_time}>{getTime()}</div>
              </div>
              <div className={styles.cont_inputs_cont_label}>{t('contract.Maxfiy_raqam')}</div>
              <Form.Item noStyle name={'code'} rules={[{ required: true, message: '' }]}>
                <InputMask
                  mask="999999"
                  // @ts-ignore*
                  maskChar={''}
                >
                  {(inputProps: any) => (
                    <Input
                      ref={codeInputRef}
                      className={styles.cont_inputs_cont_code_input}
                      style={{
                        borderColor: errorCode ? 'red' : undefined
                      }}
                      placeholder={'XXXXXX'}
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </Form.Item>
              <Button loading={verifyCard.isLoading} htmlType={'submit'} className={styles.cont_inputs_cont_button} type={'primary'}>
                {t('contract.Kartani_qoshish')}
              </Button>
            </Form>
          ) : (
            <Button
              style={{ marginTop: '16px' }}
              onClick={handleActivate}
              loading={activateCard.isLoading}
              className={styles.cont_inputs_cont_button}
              type={'primary'}
            >
              {t('contract.Aktivlashtirish')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivateCardPayme;
