import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';

export const useAutoTaskConfigDelete = () => {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete('/v1/auto-task-setting');

      return res.data.data;
    },
    {
      onSuccess: async () => {
        await qc.invalidateQueries([queryKeys.AUTO_TASK_CONFIG]);
      }
    }
  );
};
