import React, { useState } from 'react';
import SelectAddressFilter from '@pages/oneLid/components/information/info/SelectAddressFilter';
import DatePicker from '@service/datePicker';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants';
import { dateOptions } from '@utils/helper/dateOption';
import { Drawer, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import FilterReloadIcon from '../../../../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useCrmFields } from '../../../../../queries/queries';
import { CrmFieldsType } from '../../../../../utils/models/CrmFieldsType';
import { SearchParamsCrm } from '../FilterButton';

import CrmFilterSave from './crm-filter-save/CrmFilterSave';
import BalanceInputsFilter from './BalanceInputsFilter';
import DateFilter from './DateFilter';
import DeletedType from './DeletedType';
import EmployeeFilter from './EmployeeFilter';
import MultiSelectField from './MultiSelectField';
import PipeStatusFilter from './PipeStatusFilter';
import ReasonsFRFilter from './ReasonsFRFilter';
import SelectField from './SelectField';
import Tags from './Tags';
import TextField from './TextField';

import styles from './filterDrawer.module.scss';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
}

const { RangePicker } = DatePicker;

const FilterDrawer: React.FC<Props> = ({ visible, onClose }) => {
  const [dateOpen, setDateOpen] = useState(false);
  const { isLoading, data: fieldsData } = useCrmFields();
  const { searchParams, appendMultipleDifferent, removeMultiple } = useQueryParam<SearchParamsCrm, any>();
  const { t } = useTranslation();

  const createDedline = {
    start: searchParams?.startDate || null,
    end: searchParams?.finishDate || null
  };

  const handleRangePickerChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      const [start, end] = dates;

      appendMultipleDifferent(['startDate', 'finishDate'], [start.format(DATE_WITH_TIME_FORMAT), end.format(DATE_WITH_TIME_FORMAT)]);
    } else {
      removeMultiple(['startDate', 'finishDate'], ['', '']);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const deleteAllFilters = () => {
    appendMultipleDifferent(
      [
        'pipeStatusIds',
        'startDate',
        'finishDate',
        'responsibleById',
        'createdById',
        'balanceStart',
        'balanceFinish',
        'tagIds',
        'taskStartDate',
        'taskFinishDate',
        'reasonsFRIds',
        'leadDeletedType',
        'fields',
        'multiField'
      ],
      []
    );
  };

  return (
    <Drawer width={339} headerStyle={{ display: 'none' }} placement="left" onClose={handleClose} visible={visible} className={styles.container}>
      <div
        onMouseDown={() => {
          document.body.style.cursor = 'default';
        }}
      >
        <div className="d-flex align-center justify-space-between">
          <h2 className={styles.container_title}>{t('crm_filter.Bitim_filtrlari')}</h2>
          <span className={styles.container_clear} onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </div>
        <div className="mt-4">
          {/* @ts-expect-error */}
          <RangePicker
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:59', 'HH:mm')]
            }}
            style={{ width: 295, borderRadius: 5 }}
            className={styles.rangeInput}
            onOpenChange={setDateOpen}
            open={dateOpen}
            value={[createDedline.start ? dayjs(createDedline.start, DATE_WITH_TIME_FORMAT) : null, createDedline.end ? dayjs(createDedline.end, DATE_WITH_TIME_FORMAT) : null]}
            onChange={handleRangePickerChange}
            placeholder={[t('home.Boshlanish_vaqti'), t('home.Tugash_vaqti')]}
            format={DATE_WITH_TIME_FORMAT}
            renderExtraFooter={() => (
              <div className={styles.range_picker}>
                <div className="mx-[10px] mt-[5px]">
                  <div className={styles.custom_date}>
                    {dateOptions.map(option => (
                      <div
                        key={option.label}
                        className={createDedline.start === option.value.format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                        onClick={() => {
                          handleRangePickerChange([option.value, dayjs().add(1, 'minute')]);
                          setDateOpen(false);
                        }}
                      >
                        {t(option.label)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <PipeStatusFilter tagIds={searchParams.pipeStatusIds} voronka={searchParams.voronka} />
      <EmployeeFilter title="Menejerlar" labelFilter="responsibleById" ids={searchParams.responsibleById} />
      <EmployeeFilter title="Kim_yaratgan" labelFilter="createdById" ids={searchParams.createdById} />
      <DateFilter start={searchParams.taskStartDate} finish={searchParams.taskFinishDate} startLabel="taskStartDate" finishLabel="taskFinishDate" title="Topshiriqlar_vaqti" />
      <DeletedType leadDeletedType={searchParams.leadDeletedType} />
      <BalanceInputsFilter balanceFinish={searchParams.balanceFinish} balanceStart={searchParams.balanceStart} />
      <ReasonsFRFilter voronka={searchParams.voronka} reasonsFRIds={searchParams.reasonsFRIds} />
      <Spin spinning={isLoading}>
        {fieldsData?.map((item, index) => {
          if (item.type === CrmFieldsType.TEXT || item.type === CrmFieldsType.COUNTER) {
            return <TextField fields={searchParams.fields} key={item?.id} data={item} />;
          }
          if (item?.type === CrmFieldsType.ADDRESS) {
            return <SelectAddressFilter index={index} item={item} key={item?.id} />;
          }
          if (item?.type === CrmFieldsType.MULTI_SELECT) {
            return <MultiSelectField fields={searchParams.fields} key={item?.id} data={item} />;
          }
          return <SelectField fields={searchParams.fields} key={item?.id} data={item} />;
        })}
      </Spin>
      <Tags tagIds={searchParams.tagIds} visible={visible} />
      <CrmFilterSave />
    </Drawer>
  );
};

export default FilterDrawer;
