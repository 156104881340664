import React from 'react';

const FlowIcon = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#3772FF" fillOpacity="0.1" />
      <g clipPath="url(#clip0_23851_2933)">
        <path d="M14.25 14.0996V33.5996C14.25 33.6825 14.3172 33.7496 14.4 33.7496H33.9" stroke="#29CCB2" strokeWidth="1.5" strokeLinecap="round" />
        <path
          d="M33.418 14.8496L28.0452 20.2224C27.7809 20.4867 27.3623 20.516 27.0637 20.2912L23.2678 17.4328C22.9601 17.2011 22.5269 17.2402 22.2656 17.5232L17.7008 22.467"
          stroke="#29CCB2"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path d="M22.8008 21.4492L22.8008 31.3492" stroke="#29CCB2" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M32.6992 22.3496L32.6992 31.3496" stroke="#29CCB2" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M27.75 24.9004L27.75 31.3504" stroke="#29CCB2" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M17.8516 26.4004L17.8516 31.3504" stroke="#29CCB2" strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_23851_2933">
          <rect width="24" height="24" fill="white" transform="translate(12 12)" />
        </clipPath>
      </defs>
    </svg>
  );

export default FlowIcon;
