import ProfileClientInfo from '../../pages/profile/profile-client-info/ProfileClientInfo';
import ProfileSecyurity from '../../pages/profile/profile-secyurity/ProfileSecyurity';
import { profilePaths } from './profilePaths';

export const profileRoutes = [
  {
    path: profilePaths.INDEX,
    exact: true,
    component: ProfileClientInfo
  },
  {
    path: profilePaths.SECYURITY,
    exact: true,
    component: ProfileSecyurity
  }
];
