import { $api } from "@service/RequestService"
import { useQuery } from "react-query"

import { endPoints } from "../end-points"
import { keys } from "../keys"
import { UzumIntegration } from "../schema"

export const useUzumConfig = () => useQuery<UzumIntegration>(
  [keys.UZUM_CONFIG],
  async () => {
    const res = await $api.get(endPoints.UZUM_CONFIG)

    return res.data.data
  }
)