import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@components/index';
import { useAppDispatch } from '@hooks/reduxHooks';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  setVisibleConfirm: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: number;
      name: string;
    }>
  >;
  visibleConfirm: {
    visible: boolean;
    id: number;
    name: string;
  };
}

const ConfirmModal: React.FC<IProps> = ({ setVisibleConfirm, visibleConfirm }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setVisibleConfirm({ id: 0, name: '', visible: false });
  };

  const handleNavigate = () => {
    navigate(`${rootPaths.MAIN + MainPaths.ROBOTO_CALLS}/${visibleConfirm?.id}/${visibleConfirm?.name}`);
    dispatch(
      setBalanceAndIds({
        ids: [],
        balance: 0
      })
    );
    handleClose();
  };

  return (
    <Modal
      visible={visibleConfirm?.visible}
      okText={t('home.Otish')}
      cancelText={t('home.Yopish')}
      onCancel={handleClose}
      closeIcon={<Icon name="AllIcon" className="!hidden" />}
      okButtonProps={{
        className: 'rounded-full w-40 h-10',
        onClick: () => {
          handleNavigate();
        }
      }}
      cancelButtonProps={{
        className: 'rounded-full w-40 h-10',
        onClick: () => {
          handleClose();
        }
      }}
      centered
    >
      <div className="py-5">
        {/* eslint-disable-next-line no-nested-ternary */}
        {i18n.language === 'uz' ? (
          <p className="text-center text-lg font-medium text-[#475467]">
            Yangi yaratilgan "<span className="!text-[#344054]">{visibleConfirm?.name}</span>" campagingga o'tishni xoxlaysizmi?
          </p>
        ) : // eslint-disable-next-line no-nested-ternary
        i18n?.language === 'ru' ? (
          <p className="text-center text-lg font-medium text-[#475467]">
            Хотите переключиться на только что созданную "<span className="!text-[#344054]">{visibleConfirm?.name}</span>" кампанию?
          </p>
        ) : i18n?.language === 'en' ? (
          <p className="text-center text-lg font-medium text-[#475467]">
            Do you want to switch to the newly created "<span className="!text-[#344054]">{visibleConfirm?.name}</span>" campaign?
          </p>
        ) : (
          <p className="text-center text-lg font-medium text-[#475467]">
            Yeni oluşturulan "<span className="!text-[#344054]">{visibleConfirm?.name}</span>" kampanyaya geçmek ister misiniz?
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
