import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowLeftMainHeader from '../../../assets/icons/ArrowLeftMainHeader';
import ArrowRight from '../../../assets/icons/ArrowRight';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { pagesNameList } from '../../../utils/constants/pagesNameList';
import { urlDecode } from '../../../utils/helper/urlEncodeDecode';

import styles from '../header.module.scss';

interface IProps {
  backUrl?: string;
}

const BreadcrumbHeader: FC<IProps> = ({ backUrl }) => {
  const { splitUrl, push } = useQueryParam();

  const { t } = useTranslation();

  const globalTitle = () => {
    const findIndex = pagesNameList.indexOf(splitUrl[splitUrl.length - 1]);

    if (findIndex !== -1) {
      return t(`titles.${splitUrl[splitUrl.length - 1]}`);
    }

    return urlDecode(splitUrl[splitUrl.length - 1]);
  };

  const goTo = (index: number) => {
    let url = '';

    if (index === splitUrl.length - 1) {
      return;
    }
    for (let i = 0; i <= index; i++) {
      url += splitUrl[i];
      if (i !== index) {
        url += '/';
      }
    }
    push(url);
  };

  // history back
  const historyBack = () => {
    if (backUrl) {
      push(backUrl);
    } else {
      push(-1);
    }
  };

  return (
    <div className="d-flex align-center">
      {splitUrl.length !== 3 && (
        <span style={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}>
          <ArrowLeftMainHeader onClick={historyBack} className={styles.icon_arrow_back} />
        </span>
      )}
      <div className={styles.titleCont}>
        <div className={styles.main}>
          <span style={{ marginLeft: '0px' }}>{globalTitle()}</span>
        </div>
        {splitUrl.length !== 3 && (
          <div className="d-flex align-center">
            {splitUrl.map((item, index) => {
              if (index > 0 && index % 2 === 0) {
                const findIndex = pagesNameList.indexOf(splitUrl[index]);

                if (findIndex !== -1) {
                  return (
                    <div onClick={() => goTo(index)} className={styles.sub_title} key={item}>
                      {t(`titles.${splitUrl[index]}`)}
                      {index !== splitUrl.length - 1 && <ArrowRight className={styles.icon_arrow} />}
                    </div>
                  );
                }
                return (
                  <div onClick={() => goTo(index)} className={styles.sub_title} key={item}>
                    {urlDecode(splitUrl[index])}
                    {index !== splitUrl.length - 1 && <ArrowRight className={styles.icon_arrow} />}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BreadcrumbHeader;
