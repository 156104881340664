import { useGetAnalyticByEmployee } from '@pages/boss/bi-analytics/services/queries';
import { DATE_FORMAT } from '@utils/constants/format';
import { Empty , Modal , Slider , Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { handleFindMax } from '../../utils/helpers/findMax';
import { analyticByEmployeeModal } from '../../utils/models/AnalyticByEmployeeModal';

import styles from './analyticByEmployeeModal.module.scss';

type analyticByEmployeeModalDataTypes = {
  employeeFullName: string;
  count: number;
  employeeId: number;
};

const AnalyticByEmployeeModal = ({ open, handleOk, handleCancel, barChartColumnData, sales }: analyticByEmployeeModal) => {
  const finishDate = dayjs(barChartColumnData?.data?.date!, DATE_FORMAT).endOf('months').format('DD.MM.YYYY');
  const { t } = useTranslation();
  const { data: employeeData, isFetching } = useGetAnalyticByEmployee(barChartColumnData?.data?.date!, finishDate);
  const sliderMax = handleFindMax(employeeData!);

  return (
    <Modal
      closeIcon={
        <div className={styles.analyticByEmployeeModalTitleContainerMonth}>
          {t(`boss_payments.${dayjs(barChartColumnData?.data?.date, DATE_FORMAT).format('MMMM')}`)}
        </div>
      }
      title={<div className={styles.title}>{t('bi_analytics.Xodimlar_sotuvlar_soni')}</div>}
      visible={open}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className={styles.modal_container}>
        <Spin spinning={isFetching}>
          {employeeData &&
            employeeData?.map((employee_data: analyticByEmployeeModalDataTypes) => (
              <div key={employee_data?.employeeId} className={styles.analyticByEmployeeModalDataContainer}>
                <div className={styles.employee_name_and_number_of_sales_container}>
                  <div className={styles.employee_name}>{employee_data?.employeeFullName}</div>
                  <div className={styles.number_of_employee_sales}>{employee_data?.count}</div>
                </div>
                <Slider disabled className={styles.analytic_slider} range={true} defaultValue={[0, employee_data?.count]} max={sliderMax} />
              </div>
            ))}
          {!employeeData && <Empty />}
        </Spin>
      </div>
    </Modal>
  );
};

export default AnalyticByEmployeeModal;
