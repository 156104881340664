import React from 'react';
import { Dropdown } from 'antd';
import { Button } from 'global';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../../../../hooks/reduxHooks';
import { useCrmFields } from '../../../../../../../queries/queries';
import { setBudgetCondition, setEmployeeCondition, setFieldCondition, setTagCondition } from '../../../../../../../store/reducers/TriggerSlice';
import { CrmFieldsType } from '../../../../../../../utils/models/CrmFieldsType';
import { OrderTriggerCondition, TriggerConditionType } from '../../../../../../../utils/models/Trigger';

import styles from '../triggers.module.scss';

interface Props {
  orders: OrderTriggerCondition[];
}

const Condition: React.FC<Props> = ({ orders }) => {
  const { data } = useCrmFields();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // handle show tags
  const handleShowTags = () => {
    dispatch(
      setTagCondition({
        add: true
      })
    );
  };

  // handle show tags
  const handleShowBudget = () => {
    dispatch(
      setBudgetCondition({
        add: true
      })
    );
  };

  // handle show employee
  const handleShowEmployee = () => {
    dispatch(
      setEmployeeCondition({
        add: true
      })
    );
  };

  // handle show field
  const handleShowField = (id: number, type: CrmFieldsType) => {
    dispatch(
      setFieldCondition({
        add: true,
        fieldId: id,
        type
      })
    );
  };

  // show tag
  const showTag = () => !orders.find(item => item.type === TriggerConditionType.TAG);

  // show budget
  const showBudget = () => !orders.find(item => item.type === TriggerConditionType.BUDGET);

  // show employee
  const showEmployee = () => !orders.find(item => item.type === TriggerConditionType.EMPLOYEE);

  // show field
  const showField = (id: number) => !orders.find(item => item.fieldId === id);


  return (
    <Dropdown
      placement="bottom"
      overlayClassName={styles.container_header_dropdown}
      overlay={
        <>
          {showTag() && (
            <div onClick={handleShowTags} className={styles.container_header_dropdown_item}>
              {t('triggers.Teg')}
            </div>
          )}
          {showEmployee() && (
            <div onClick={handleShowEmployee} className={styles.container_header_dropdown_item}>
              {t('triggers.Masul_xodim')}
            </div>
          )}
          {showBudget() && (
            <div onClick={handleShowBudget} className={styles.container_header_dropdown_item}>
              {t('triggers.Byudjet')}
            </div>
          )}
          {data?.map((item, index) => {
            if (showField(item.id as number) && item.type !== "ADDRESS" && item.type !== "LOCATION") {
              return (
                <div onClick={() => handleShowField(item.id as number, item.type)} className={styles.container_header_dropdown_item} key={index}>
                  {item.name}
                </div>
              );
            }
            return null;
          })}
        </>
      }
      trigger={['click']}
    >
      <Button className='flex items-center justify-center w-full py-[10px] rounded-[8px] font-normal mt-[10px] h-[36px]'>
        <span className='text-[20px] mr-[8px]'>+</span> {t('triggers.Shartlarni_qoshing')}
      </Button>
    </Dropdown>
  );
};

export default Condition;
