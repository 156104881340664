import React, { FC, useState } from 'react';
import styles from './sipuConfig.module.scss';
import { Select } from 'antd';
import EditAddTagIcon from '../../../../../../assets/icons/addTag/EditAddTagIcon';
import Trash01Icon from '../../../../../../assets/icons/Trash01Icon';
import { SipContact } from '../utils/models/SipContact';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import { PipeAll } from '../../../../../../utils/models/PipeAll';
import { SipCallHandlingEdit } from '../utils/models/SipCallHandling';
import SipuContact from './sipu-contact/SipuContact';
import { useSipuniConfigCallHandling, useSipuniConfigCallHandlingDelete } from '../utils/service/mutation';

interface IProps {
  sipuniConfId: number;
  contactAll: SipContact[];
  pipeAll: PipeAll[] | undefined;
  index: number;
  setEditId: React.Dispatch<React.SetStateAction<number | null>>;
  item: SipCallHandlingEdit;
}

const SipuConfigItem: FC<IProps> = ({ contactAll, index, item, pipeAll, setEditId, sipuniConfId }) => {
  const callhandlingMutate = useSipuniConfigCallHandling();
  const callHandlingDelete = useSipuniConfigCallHandlingDelete();
  const [contacts, setContacts] = useState<number[]>(item.contacts?.map(item => item?.id!)!);
  const [pipeId, setPipeId] = useState<number | null>(item.pipe?.id!);

  const defaultValue = {
    sipuniConfId: sipuniConfId,
    autoTask: item?.autoTask,
    incomingCall: item?.incomingCall,
    incomingPipeStatusId: item?.incomingPipeStatusRes?.id!,
    incomingTags: item?.incomingTagList?.map(item => item?.name),
    missingCall: item?.missingCall,
    missingPipeStatusId: item?.missingPipeStatusRes?.id!,
    missingTags: item?.missingTagList?.map(item => item?.name),
    outgoingCall: item?.outgoingCall,
    outgoingPipeStatusId: item?.outgoingPipeStatusRes?.id!,
    outgoingTags: item?.outgoingTagList?.map(item => item?.name),
    recordingCall: item?.recordingCall,
    responsibleById: item?.responsibleById!
  };

  const deleteHandle = () => {
    callHandlingDelete?.mutate({
      id: sipuniConfId,
      sCallHandlingId: item?.id!
    });
  };

  return (
    <div className={styles.config}>
      <div>
        {index === 0 && <label>Telefon raqam</label>}
        <SipuContact
          contactAll={[...(contactAll || []), ...(item?.contacts ? item?.contacts : [])]}
          contacts={contacts}
          setContacts={setContacts}
          sipuniConfId={sipuniConfId}
          onChange={value => {
            callhandlingMutate.mutate({
              id: item?.id,
              contactList: value,
              pipeId: pipeId!,
              ...defaultValue
            });
          }}
        />
      </div>
      <div>
        {index === 0 && <label>Varonka</label>}
        <Select
          className="customSelect"
          size="large"
          onChange={value => {
            setPipeId(value);
            callhandlingMutate.mutate({
              id: item?.id,
              contactList: contacts,
              pipeId: value,
              ...defaultValue
            });
          }}
          value={pipeId}
          suffixIcon={<ArrowDownIcon />}
          placeholder="Varonkani tanlang"
        >
          {pipeAll?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <span className={styles.config_edit}>
        <span onClick={() => setEditId(item?.id!)}>
          <EditAddTagIcon />
        </span>
        <span onClick={deleteHandle}>
          <Trash01Icon />
        </span>
      </span>
    </div>
  );
};

export default SipuConfigItem;
