import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CompanyState {
  companyId: number | null;
  token?: string;
}

const initialState: CompanyState = {
  companyId: 0,
  token: ''
};

export const companySlice = createSlice({
  name: 'CompanySlice',
  initialState,
  reducers: {
    setCompanyId: (state, action: PayloadAction<number | null>) => {
      state.companyId = action.payload;
    },
    setVisualAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  }
});

export const { setCompanyId, setVisualAuthToken } = companySlice.actions;
export default companySlice.reducer;
