import React from 'react';

const DashboardVidjet2 = () => {
  return (
    <svg width="88" height="111" viewBox="0 0 88 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 12C0.75 5.64873 5.89873 0.5 12.25 0.5H75.75C82.1013 0.5 87.25 5.64873 87.25 12V69C87.25 91.9198 68.6698 110.5 45.75 110.5H12.25C5.89872 110.5 0.75 105.351 0.75 99V12Z"
        stroke="#00A389"
      />
      <path
        d="M6.25 14C6.25 9.58172 9.83172 6 14.25 6H73.75C78.1683 6 81.75 9.58172 81.75 14V65C81.75 87.0914 63.8414 105 41.75 105H14.25C9.83172 105 6.25 101.418 6.25 97V14Z"
        fill="#E7F9F6"
      />
      <path d="M22.25 26H65.75" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
      <path
        d="M61.25 57.5C61.25 61.3567 60.1063 65.1269 57.9637 68.3336C55.821 71.5404 52.7755 74.0397 49.2123 75.5157C45.6492 76.9916 41.7284 77.3777 37.9457 76.6253C34.1631 75.8729 30.6885 74.0157 27.9614 71.2886C25.2343 68.5615 23.3771 65.0869 22.6247 61.3043C21.8723 57.5216 22.2584 53.6008 23.7343 50.0377C25.2103 46.4745 27.7096 43.429 30.9164 41.2863C34.1231 39.1437 37.8933 38 41.75 38V57.5H61.25Z"
        fill="#29CCB2"
      />
      <path
        d="M43.75 36C46.3108 36 48.8465 36.5044 51.2123 37.4843C53.5782 38.4643 55.7278 39.9007 57.5386 41.7114C59.3493 43.5222 60.7857 45.6718 61.7657 48.0377C62.7456 50.4035 63.25 52.9392 63.25 55.5L43.75 55.5L43.75 36Z"
        fill="#00A389"
      />
      <path d="M22.25 85H45.25" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
    </svg>
  );
};

export default DashboardVidjet2;
