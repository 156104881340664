import React, { FC, useRef, useEffect, useState } from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import SwiperNextIcon from '../../assets/icons/SwiperNextIcon';
import SwiperPrevIcon from '../../assets/icons/SwiperPrevIcon';
import { FlatGallery } from '../../utils/models/Flat';
import styles from './flatGallery.module.scss';
import ZoomMinusIcon from '../../assets/icons/ZoomMinusIcon';
import ZoomPlusIcon from '../../assets/icons/ZoomPlusIcon';
import FlatGalleryItem from './FlatGalleryItem';

interface IProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  gallery: FlatGallery[] | undefined;
  defaultIndex: number;
}

const FlatGalleryModal: FC<IProps> = ({ setVisible, visible, gallery, defaultIndex }) => {
  const carouselRef = useRef<CarouselRef | null>(null);
  const [zoom, setZoom] = useState(1);
  const [number, setNumber] = useState(0);
  const handleCancle = (e: any) => {
    if (e.target.nodeName !== 'IMG') {
      setVisible(false);
    }
  };

  const handleNext = () => {
    carouselRef?.current?.next();
    if (zoom !== 1) {
      setZoom(1);
    }
  };
  const handlePrev = () => {
    carouselRef?.current?.prev();
    if (zoom !== 1) {
      setZoom(1);
    }
  };

  useEffect(() => {
    if (visible) {
      carouselRef?.current?.goTo(defaultIndex, true);
      setNumber(defaultIndex + 1);
    }
  }, [defaultIndex, visible]);

  useEffect(() => {
    const windowWheel = (e: WheelEvent) => {
      setZoom(prev => (prev <= 1 && e.deltaY > 0 ? 1 : prev - e.deltaY / 200));
    };

    window.addEventListener('wheel', windowWheel);
    return () => {
      window.removeEventListener('wheel', windowWheel);
    };
  }, []);

  return (
    <div className={`${styles.modal} ${visible ? styles.modal_active : ''}`}>
      <div className={styles.modal_mask} onClick={handleCancle}></div>
      <div className={styles.modal_header}>
        {gallery && gallery?.length > 1 ? (
          <p className={styles.modal_header_number}>
            {number}/{gallery?.length}
          </p>
        ) : (
          ''
        )}
        <span onClick={() => setZoom(zoom <= 1 ? 1 : zoom - 0.5)}>
          <ZoomMinusIcon />
        </span>
        <span onClick={() => setZoom(zoom + 0.5)}>
          <ZoomPlusIcon />
        </span>
        <span className={styles.modal_header_close} onClick={handleCancle}>
          &times;
        </span>
      </div>
      <div className={styles.modal_container} onClick={handleCancle}>
        <Carousel
          draggable={zoom === 1}
          ref={carouselRef}
          dots={false}
          afterChange={swipeDirection => {
            setNumber(swipeDirection + 1);
            if (zoom !== 1) {
              setZoom(1);
            }
          }}
        >
          {gallery?.map((item, index) => <FlatGalleryItem {...item} index={index} zoom={zoom} key={item?.id} />)}
        </Carousel>
      </div>
      {gallery && gallery?.length > 1 ? (
        <>
          <span className={styles.swiper_prev} onClick={handlePrev}>
            <SwiperPrevIcon />
          </span>
          <span className={styles.swiper_next} onClick={handleNext}>
            <SwiperNextIcon />
          </span>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default FlatGalleryModal;
