import { createSlice } from '@reduxjs/toolkit';

interface NotTaskLeadNotificationState {
  showModal: boolean;
}

const initialState: NotTaskLeadNotificationState = {
  showModal: false
};

export const notTaskLeadNotification = createSlice({
  name: 'notTaskLeadNotification',
  initialState,
  reducers: {
    showNotificationNotTaskLead: state => {
      state.showModal = true;
    },
    hideNotificationNotTaskLead: state => {
      state.showModal = false;
    }
  }
});

export const { showNotificationNotTaskLead, hideNotificationNotTaskLead } = notTaskLeadNotification.actions;
export default notTaskLeadNotification.reducer;
