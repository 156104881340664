import { useState } from 'react';
import { Mask } from '@components/fields';
import CheckCircleIcon from '@icons/CheckCircleIcon';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import Plus from '@icons/plus';
import { Spin } from 'antd';
import { InputMaskOptions } from 'antd-mask-input/build/main/lib/MaskedInput';
import { useTranslation } from 'react-i18next';

import { Form, IVariation, useAction, useContractVariationDelete } from 'modules/multi-payment';

interface IProps {
  created?: boolean;
  item?: IVariation;
}

const PaymentOfferItem = ({ created, item }: IProps) => {
  const { t } = useTranslation();
  const { query } = useAction();
  const [edit, setEdit] = useState(false);
  const deleteMutation = useContractVariationDelete({ groupId: query?.groupId });

  const handleClose = () => setEdit(false);

  return !item?.id && (!edit ?? created) ? (
    <div
      className="flex w-full cursor-pointer items-center justify-center gap-[8px] rounded-[6px] border-[1px] border-dashed border-[#838383] p-[24px] text-[16px] text-[#464255]"
      onClick={() => setEdit(true)}
    >
      <Plus className="w-[16px]" /> {t('contract_type.Tolov_turi_qoshish')}
    </div>
  ) : (
    <Form.OfferItem
      key={item?.id}
      defaultValues={{
        ...item,
        groupId: query?.groupId
      }}
      handleClose={handleClose}
    >
      {({ control, onSubmit, formState: { isSubmitting } }) => (
        <Spin spinning={isSubmitting || deleteMutation?.isLoading}>
          <div className="flex items-end gap-[24px]">
            <Mask
              errorMessage={false}
              maskType="float"
              maskOptions={{ thousandsSeparator: '', max: 100 } as InputMaskOptions}
              control={control}
              name="prepaymentPercent"
              label={<span className="text-[14px] font-[600] text-[#464255]">Boshlang‘ish to‘lov (%)</span>}
              id={`prepaymentPercent${item?.id}`}
              autoFocus={edit}
            />
            <Mask
              errorMessage={false}
              maskType="float"
              maskOptions={{ thousandsSeparator: '', max: 100 } as InputMaskOptions}
              control={control}
              name="monthlyPaymentPercent"
              label={<span className="text-[14px] font-[600] text-[#464255]">Muddatli to‘lov(%)</span>}
              id={`monthlyPaymentPercent${item?.id}`}
            />

            <Mask
              errorMessage={false}
              maskType="float"
              maskOptions={{ thousandsSeparator: '', max: 100 } as InputMaskOptions}
              control={control}
              name="mortgagePercent"
              label={<span className="text-[14px] font-[600] text-[#464255]">Ipoteka(%)</span>}
              id={`mortgagePercent${item?.id}`}
            />
            <Mask
              errorMessage={false}
              maskType="float"
              control={control}
              name="contractMonth"
              label={<span className="text-[14px] font-[600] text-[#464255]">Shartnoma muddati</span>}
              id={`contractMonth${item?.id}`}
            />
            <Mask
              errorMessage={false}
              maskType="number"
              maskOptions={{ thousandsSeparator: '', max: 100, min: -100 } as InputMaskOptions}
              control={control}
              name="changePricePercent"
              label={<span className="text-[14px] font-[600] text-[#464255]">Shartnoma summa(%)</span>}
              id={`changePricePercent${item?.id}`}
            />
            <div className="flex h-[40px] min-w-[40px] cursor-pointer items-center justify-center rounded-[8px] bg-[#FEE4E2]" onClick={onSubmit}>
              <CheckCircleIcon stroke="#12B76A" />
            </div>
            <div
              className="flex h-[40px] min-w-[40px] cursor-pointer items-center justify-center rounded-[8px] bg-[#FEE4E2]"
              onClick={() => (created ? setEdit(false) : deleteMutation?.mutate(item?.id))}
            >
              <TrushIcon color="#F04438" size={20} />
            </div>
          </div>
        </Spin>
      )}
    </Form.OfferItem>
  );
};

export default PaymentOfferItem;
