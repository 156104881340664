/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import TjmFilterV2 from '@components/tjm-filter/tjm-filter-v2/TJMFilterV2';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsivePie } from '@nivo/pie';
import { colorPieChart } from '@utils/constants/colorPieChart';
// import { colorGenerator } from '@utils/helper/colorGenerator';
import { getRandomColor } from '@utils/helper/colorGeneratorV2';
import { Select } from 'antd';
import { t } from 'i18next';

import { useGetRemainingHouses } from './service/queries';
import { filterEnum, filterLabels } from './utils/helpers/filterEnum';

// import { IRemainingHouses } from './utils/models/IRemainingHouses';
import styles from './remainingHouses.module.scss';

const RemainingHouses = () => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState('NUMBER');
  const [filter, setFilter] = useState<{ house?: number[] | null }>({ house: [] });
  const [selectedFilter, setSelectedFilter] = useState('ROOMS');
  // const { appendMultipleDifferent, appendMultipleArray, searchParams } = useQueryParam<BossPaymentsSearchParams, any>();

  const houses = useAppSelector(state => state.bossCurrency.houses);

  const { data: dataRemainingHouses } = useGetRemainingHouses(houses, selectedFilter, activeTab);
  // const { data: houses } = useMobileHouse();

  useEffect(() => {
    if (dataRemainingHouses) {
      const newData: any = [];

      dataRemainingHouses.forEach((item, index) => {
        let color = colorPieChart[index];

        if (index > 8) {
          color = `${getRandomColor()}`;
        }
        newData.push({
          id: item.number,
          label: item.name,
          value: item.number,
          color
        });
      });
      setChartData(newData);
    }
  }, [dataRemainingHouses, selectedFilter]);

  const options = Object.keys(filterEnum).map(key => {
    const value = filterEnum[key as keyof typeof filterEnum];
    const label = filterLabels[value as filterEnum];

    return {
      value,
      label: t(`home.${label}`)
    };
  });

  const handleChangeSelect = (value: string) => {
    setSelectedFilter(value);
  };

  const handleTabClick = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const changeHouse = (e: any, id: number) => {
    if (e.target.checked) {
      setFilter(prev => ({
        ...prev,
        house: [...(prev.house || []), id]
      }));
    } else {
      setFilter(prev => ({
        ...prev,
        house: prev.house?.filter(item => item !== id) || null
      }));
    }
  };

  const formatSquareMeters = (squareMeters: any) => {
    const formattedValue = new Intl.NumberFormat('ru-RU').format(squareMeters);

    return `${formattedValue}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <h3>{t('home.Sotuvdagi_uylar')}</h3>

        <div className="flex items-center gap-5">
          <TjmFilterV2 />

          {/* <Dropdown
            mouseEnterDelay={1000}
            mouseLeaveDelay={0.5}
            trigger={['click', 'hover']}
            onVisibleChange={() => {
              if (filter?.house) {
                appendMultipleArray('houses', filter?.house);
              }
            }}
            placement="bottomRight"
            overlay={
              <div className={styles.houses}>
                <Checkbox
                  style={{ padding: '0 8px' }}
                  checked={houses?.length === filter?.house?.length}
                  onChange={e => {
                    if (e.target.checked) {
                      setFilter(prev => ({
                        ...prev,
                        house: [...(houses?.map(item => item?.id) || [])]
                      }));
                    } else {
                      setFilter(prev => ({
                        ...prev,
                        house: []
                      }));
                    }
                  }}
                >
                  {t('home.Barchasi')}
                </Checkbox>
                {houses?.map(item => (
                  <div key={item?.id} title={item?.name?.length > 24 ? item?.name : ''}>
                    <Checkbox checked={filter.house?.includes(item?.id)} onChange={e => changeHouse(e, item?.id)}>
                      {item?.name?.length > 24 ? `${item?.name?.substring(0, 24)}...` : item?.name}
                    </Checkbox>
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.selects}>
              {filter?.house?.length! > 0 && <span className={styles.countHouses}>{filter?.house?.length}</span>}
              {filter?.house?.length! > 0 ? <p>{t('home.Tanlandi')}</p> : <p>{t('home.Barcha_TJM_lar')}</p>}
              <ArrowDownIcon />
            </div>
          </Dropdown> */}

          <Select
            defaultValue="ROOMS"
            placeholder={t('home.Xonadon_boyicha')}
            className="customSelect"
            style={{ width: 150 }}
            size="large"
            onChange={handleChangeSelect}
            options={options}
          />

          <div className="flex cursor-pointer items-center gap-[4px] rounded bg-[#ebebeb] p-1">
            <div
              className={`px-[10px] py-[5px] ${activeTab === 'AREA' ? 'rounded-bl rounded-tl bg-white' : 'rounded'}`}
              onClick={() => handleTabClick('AREA')}
            >
              {t('home.M²_boyicha')}
            </div>
            <div
              className={`px-[10px] py-[5px] ${activeTab === 'NUMBER' ? 'rounded-br rounded-tr bg-white' : 'rounded'}`}
              onClick={() => handleTabClick('NUMBER')}
            >
              {t('home.Soni_boyicha')}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.container_body}>
        <div
          style={{
            width: '300px',
            height: '300px'
          }}
        >
          <ResponsivePie
            // @ts-ignore
            data={chartData}
            margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
            innerRadius={0.6}
            cornerRadius={3}
            activeOuterRadiusOffset={16}
            colors={{ datum: 'data.color' }}
            borderColor={{
              from: 'color',
              modifiers: [['opacity', 0.2]]
            }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={false}
            arcLabel="value"
            arcLabelsRadiusOffset={0.3}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]]
            }}
            tooltip={e => {
              const item = e.datum;

              return (
                <div className={styles.tooltip}>
                  <div className={styles.tooltip_label}>{item.label}</div>
                  <div className={styles.tooltip_value}>
                    {
                      formatSquareMeters(item.value)
                      // item.value.toLocaleString('ru')
                    }
                  </div>
                </div>
              );
            }}
            legends={[]}
          />
        </div>

        <div style={{ width: '360px', height: '300px', overflow: 'auto' }}>
          {chartData?.map((item, index) => {
            console.log('first');

            return (
              <div key={index} className={styles.container_body_value_legneds_item}>
                <div className="flex items-center">
                  <span
                    style={{
                      // @ts-ignore
                      '--color': item.color,
                      width: 10,
                      height: 10,
                      border: '0.6px solid black'
                    }}
                    className="mr-2 h-2 w-2 rounded-full"
                  />
                  {selectedFilter === 'STATUS' ? (
                    <p className="text-[##464255]">{t(`home.${item?.label}`)}</p>
                  ) : selectedFilter === 'FLOOR' ? (
                    <p className="text-[##464255]">
                      {item?.label}-{t('home.qavat')}
                    </p>
                  ) : selectedFilter === 'ROOMS' ? (
                    <p className="text-[##464255]">
                      {item?.label} {t('home.xonali')}
                    </p>
                  ) : (
                    <p className="text-[##464255]">{item?.label}</p>
                  )}
                </div>

                {activeTab === 'AREA' ? (
                  <p className="text-base font-semibold text-[#2C2C2C]">{formatSquareMeters(item?.value)} m²</p>
                ) : (
                  // <p className="text-base font-semibold text-[#2C2C2C]">{item?.value}M²</p>
                  <p className="text-base font-semibold text-[#2C2C2C]">{item?.value}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RemainingHouses;
