import { setShaxmatka, setShaxmatkaDelete } from '@reducers/ShaxmatkaSlice';
import { FlatId } from '@utils/models/Flat';
import { UseMutationResult } from 'react-query';

interface IProps {
  shaxmatka: any;
  values: any;
  data: {
    visible: boolean;
    title: string;
  };
  flatDelete: UseMutationResult<any, any, any[], unknown>;
  dispatch: (e: any) => void;
  handleCancel: () => void;
  setSaveAll: (save: boolean) => void;
  shaxmatkaDelete: number[];
}

export const handleCreateEntrance = ({
  shaxmatka,
  values,
  data,
  // flatDelete,
  dispatch,
  handleCancel,
  setSaveAll,
  shaxmatkaDelete
}: IProps) => {
  const arr: any = [];
  let buildingId = 0;
  let buildingName = '';
  let maxEntrance = 1;
  let floorMax = Number(values.floorNumber);
  let floorMin = 1;
  let flatMax = Number(values.flatNumber);

  shaxmatka.forEach((floor: any) => {
    floor?.map((el: any) =>
      el.forEach((el: any) => {
        if (!el.new) {
          arr.push(el);
        }
      })
    );
  });

  arr.forEach((el: FlatId) => {
    buildingId = el?.building?.id;
    buildingName = el?.building?.name;
    if (floorMax < el.floor) {
      floorMax = el.floor;
    }
    if (flatMax < el.xaxis[0]) {
      [flatMax] = el.xaxis;
    }
    if (maxEntrance < el.entrance) {
      maxEntrance = el.entrance;
    }
    if (floorMin > el.floor) {
      floorMin = el.floor;
    }
  });

  const floorList: number[] = [];

  for (let i = floorMin; i <= floorMax; i++) {
    floorList.push(i);
  }

  const floorListReverse = floorList;

  function flatPosition(entrance: number, floor: number, xaxis: number) {
    return (
      arr?.find((item: any) => item.entrance === entrance && item.floor === floor && item.xaxis[0] === xaxis) || {
        floor,
        xaxis: [xaxis],
        new: true,
        isSelected: false,
        apartment: null
      }
    );
  }

  const copyEntrance = Array.from({ length: maxEntrance }).map((entrance, entranceIndex) =>
    floorListReverse.map(floor => Array.from({ length: flatMax }).map((flat, flatIndex) => flatPosition(entranceIndex + 1, floor, flatIndex + 1)))
  );

  const copyEntranceAdd = Array.from({
    length: Number(values.entranceNumber)
  }).map((entrance, entranceIndex) =>
    floorListReverse.map((floor, floorIndex) =>
      Array.from({ length: flatMax }).map((flat, flatIndex) => ({
        new: !(floor >= 1 && floor <= Number(values.floorNumber) && flatIndex + 1 <= Number(values.flatNumber)),
        isSelected: false,
        floor,
        xaxis: [flatIndex + 1],
        yaxis: [floorListReverse.length - floorIndex],
        entrance: entranceIndex + 1,
        building: { id: buildingId, name: buildingName }
      }))
    )
  );

  console.log(Number(values.floorNumber), Number(values.flatNumber));

  copyEntrance.splice(Number(values.entrances), 0, ...copyEntranceAdd);

  const res = copyEntrance.map((entrance, entranceIndex) =>
    entrance.map((floor, floorIndex) =>
      floor.map((flat, flatIndex) => ({
        ...flat,
        entrance: entranceIndex + 1
      }))
    )
  );

  if (data.title === "qo'shish") {
    // @ts-expect-error
    dispatch(setShaxmatka(res));
  } else {
    const deleted: FlatId[][][] = shaxmatka.filter((item: any, i: number) => !values.delete.includes(i + 1));

    const deletedId: number[] = [...shaxmatkaDelete];

    shaxmatka
      .filter((item: any, i: number) => values.delete.includes(i + 1))
      .forEach((entrance: any[], entranceIndex: number) => {
        entrance.forEach((floor: any[], floorIndex: number) => {
          floor.forEach((flat: FlatId, floorIndex: number) => {
            if (!deletedId.includes(flat.id) && flat.id) {
              deletedId.push(flat.id);
            }
          });
        });
      });

    const deletedRes = deleted.map((entrance, entranceIndex) =>
      entrance.map((floor, floorIndex) =>
        floor.map((flat, flatIndex) => ({
          ...flat,
          entrance: entranceIndex + 1,
          customId: `${entranceIndex + 1}-${flat?.floor}-${flat?.xaxis[0]}`
        }))
      )
    );

    // @ts-expect-error
    dispatch(setShaxmatkaDelete(deletedId));
    // @ts-expect-error
    dispatch(setShaxmatka(deletedRes));
  }
  handleCancel();
  setSaveAll(false);
};
