import React from 'react';

interface Props {
  className?: string;
}

const DollarGreenIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 21.2258H17.8667C18.7333 21.2258 19.4533 20.4524 19.4533 19.5191C19.4533 18.3591 19.04 18.1324 18.36 17.8924L17.0133 17.4258V21.2258H17Z"
        fill="#00A389"
      />
      <path
        d="M15.96 2.53323C8.60005 2.55989 2.64005 8.54656 2.66672 15.9066C2.69338 23.2666 8.68005 29.2266 16.04 29.1999C23.4 29.1732 29.36 23.1866 29.3334 15.8266C29.3067 8.46656 23.32 2.51989 15.96 2.53323ZM19.0134 15.9999C20.0534 16.3599 21.4534 17.1332 21.4534 19.5199C21.4534 21.5732 19.84 23.2266 17.8667 23.2266H17V23.9999C17 24.5466 16.5467 24.9999 16 24.9999C15.4534 24.9999 15 24.5466 15 23.9999V23.2266H14.52C12.3334 23.2266 10.56 21.3866 10.56 19.1199C10.56 18.5732 11.0134 18.1199 11.56 18.1199C12.1067 18.1199 12.56 18.5732 12.56 19.1199C12.56 20.2799 13.44 21.2266 14.52 21.2266H15V16.7199L12.9867 15.9999C11.9467 15.6399 10.5467 14.8666 10.5467 12.4799C10.5467 10.4266 12.16 8.77323 14.1334 8.77323H15V7.99989C15 7.45323 15.4534 6.99989 16 6.99989C16.5467 6.99989 17 7.45323 17 7.99989V8.77323H17.48C19.6667 8.77323 21.44 10.6132 21.44 12.8799C21.44 13.4266 20.9867 13.8799 20.44 13.8799C19.8934 13.8799 19.44 13.4266 19.44 12.8799C19.44 11.7199 18.56 10.7732 17.48 10.7732H17V15.2799L19.0134 15.9999Z"
        fill="#00A389"
      />
      <path
        d="M12.5601 12.4934C12.5601 13.6534 12.9734 13.8801 13.6534 14.1201L15.0001 14.5868V10.7734H14.1334C13.2667 10.7734 12.5601 11.5468 12.5601 12.4934Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default DollarGreenIcon;
