import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import LineGradientLayout from '@components/line-gradient-layout/LineGradientLayout';
import { cx } from '@utils/cx';
import { Input as BaseInput, InputProps as BaseInputProps, InputRef } from 'antd';
import IMask, { FactoryArg } from 'imask';
import { StringParam, useQueryParam } from 'use-query-params';

import Password from './Password';

import styles from './input.module.scss';

type InputProps = {
  type?: 'number' | 'string' | 'currency' | 'mask';
  returnType?: 'number' | 'string' | 'unmasked';
  mask?: { format?: string };
  param?: { name: string; returnType?: InputProps['returnType'] };
  autoSize?: { min?: number; max: number };
  ai?: boolean;
  onStopAiTime?: () => void;
  maskOption?: FactoryArg;
} & {
  onChange?: (value: any, e: KeyboardEvent<HTMLInputElement>) => void;
} & Omit<BaseInputProps, 'onChange'>;

const Input = ({ type = 'string', mask, returnType = 'string', param, autoSize, ai, onStopAiTime, maskOption, ...props }: InputProps) => {
  const [paramValue, setParam] = useQueryParam(param?.name || '', StringParam);
  const [opacity, setOpacity] = useState(0);
  const [val, setVal] = useState<string | undefined>();
  const [width, setWidth] = useState(autoSize?.min || 0);
  const divRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputRef | null>(null);
  const imaskRef = useRef<ReturnType<typeof IMask> | null>(null);

  const pRT = param?.returnType || returnType;

  const maskOptions: FactoryArg = {
    mask: mask?.format || '',
    lazy: false,
    placeholderChar: '_',
    // @ts-expect-error
    ...maskOption
  };

  const currencyMaskOptions: FactoryArg = {
    mask: Number,
    thousandsSeparator: ' ',
    radix: ',',
    padFractionalZeros: false,
    normalizeZeros: false,
    scale: 2,
    // @ts-expect-error
    ...maskOption
  };

  const numberMaskOptions: FactoryArg = {
    mask: Number,
    radix: ',',
    padFractionalZeros: false,
    normalizeZeros: false,
    scale: 2,
    min: -Infinity,
    // @ts-expect-error
    ...maskOption
  };

  const maskOptionsObj: Record<typeof type, FactoryArg> = {
    mask: maskOptions,
    currency: currencyMaskOptions,
    number: numberMaskOptions,
    string: {}
  };

  const returnObj = (): Record<typeof returnType, any> => ({
    number: Number(imaskRef.current?.unmaskedValue),
    string: imaskRef.current?.value,
    unmasked: imaskRef.current?.unmaskedValue
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value;

    if (autoSize?.max) {
      const clientWidth = divRef.current?.clientWidth || 0;
      const minWidth = autoSize?.min || 0;

      setWidth(clientWidth >= minWidth ? clientWidth : minWidth);
    }

    if (type !== 'string') {
      setVal(v || '');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // props.onChange && props.onChange(returnObj()[returnType]);
      if (param?.name) {
        setParam(returnObj()[pRT]);
      }
    } else {
      setVal(v);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // props.onChange && props.onChange(v);
      if (param?.name) {
        setParam(v);
      }
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const v = imaskRef.current?.value;
    const { value } = e.currentTarget;

    if (autoSize?.max) {
      const clientWidth = divRef.current?.clientWidth || 0;
      const minWidth = autoSize?.min || 0;

      setWidth(clientWidth >= minWidth ? clientWidth : minWidth);
    }

    if (type !== 'string') {
      setVal(v || '');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props.onChange && props.onChange(returnObj()[returnType], e);
      if (param?.name) {
        setParam(returnObj()[pRT]);
      }
    } else {
      setVal(value);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props.onChange && props.onChange(value, e);
      if (param?.name) {
        setParam(value);
      }
    }
  };

  const getStyles = useMemo(() => {
    const css = inputRef?.current?.input ? window?.getComputedStyle(inputRef?.current?.input) : ({} as CSSStyleDeclaration);

    const getStyles: React.CSSProperties | undefined = {
      fontSize: css?.fontSize,
      fontWeight: css?.fontWeight,
      // eslint-disable-next-line no-nested-ternary
      width: autoSize?.max ? 'auto' : css?.width?.replace(/\D/g, '') ? css?.width : 219,
      padding: css?.padding,
      fontStyle: css?.fontStyle,
      height: css?.height,
      color: css?.color,
      lineHeight: css?.lineHeight,
      borderRadius: css?.borderRadius,
      paddingTop: css?.paddingTop,
      paddingBottom: css?.paddingBottom,
      border: css?.border,
      background: css?.background
    };

    return getStyles;
  }, [type, val]);

  useEffect(() => {
    if (type !== 'string') {
      const inputElement = inputRef.current?.input!;

      imaskRef.current = IMask(inputElement, maskOptionsObj[type]);
      if (imaskRef.current?.value !== val) {
        setVal(imaskRef.current?.value);
      }
    }
    return () => {
      if (type !== 'string') {
        imaskRef.current?.destroy();
      }
    };
  }, [type, val]);

  useEffect(() => {
    if (ai) {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
      if (onStopAiTime) {
        setTimeout(
          () => {
            onStopAiTime();
            setOpacity(0);
          },
          (val?.toString()?.length! / 6) * 1000
        );
      }
    }
  }, [ai]);

  useEffect(() => {
    // if (props?.value || paramValue) {
    let value = (props.value || paramValue)?.toString();

    if (type !== 'string') {
      value = value?.replace(/\./g, ',');
    }

    if (imaskRef.current?.value === '-') {
      value = '-';
    }

    setVal(value);
    // }
  }, [props?.value, paramValue]);

  return (
    <>
      {ai ? (
        <LineGradientLayout loading>
          <div className={styles.input} style={{ ...getStyles }}>
            {(props?.value?.toString() || '')?.split('').map((item, i) => (
              <span className="duration-[0.3s]" key={item} style={{ transitionDelay: `${i / 8}s`, opacity }}>
                {item}
              </span>
            ))}
          </div>
        </LineGradientLayout>
      ) : null}

      <BaseInput
        {...props}
        ref={inputRef}
        className={cx('customInput', props.className)}
        value={val}
        onChange={onChange}
        onInput={onKeyUp}
        style={{ display: ai ? 'none' : '', ...props?.style, width: width ? width + 8 : props?.width }}
        aria-errormessage="sdff"
      />
      {autoSize?.max && (
        <div className="z-1 pointer-events-none absolute top-0" style={{ ...getStyles, opacity: 0 }} ref={divRef}>
          {val}
        </div>
      )}
    </>
  );
};

Input.Password = Password;
export default Input;
