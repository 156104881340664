import React, { FC, useState, useEffect } from 'react';
import { SipCallHandlingEdit } from '../utils/models/SipCallHandling';
import { Avatar, Button, Checkbox, Form, Select } from 'antd';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import { PipeAll } from '../../../../../../utils/models/PipeAll';
import styles from './sipuConfig.module.scss';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../../../../../service/RequestService';
import avatarDefaultClient from '../../../../../../assets/img/avatarDefaultClient.jpg';
import { useCrmEmployee } from '../../../../../../queries/queries';
import TagSelect from '../../../../../../components/tagSelect/TagSelect';
import { useSipuniConfigCallHandling } from '../utils/service/mutation';

interface IProps {
  edit: SipCallHandlingEdit | undefined;
  pipeAll: PipeAll[] | undefined;
  sipuniConfId: number;
  setEditId: React.Dispatch<React.SetStateAction<number | null>>;
}

const SipuConfigEdit: FC<IProps> = ({ edit, pipeAll, setEditId }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { data: employeeList } = useCrmEmployee();
  const callhandlingMutate = useSipuniConfigCallHandling();
  const [incomingTags, setIncomingTags] = useState<string[]>([]);
  const [outgoingTags, setOutgoingTags] = useState<string[]>([]);
  const [missingTags, setMissingTags] = useState<string[]>([]);

  const onFinish = (values: any) => {
    callhandlingMutate
      .mutateAsync({
        pipeId: edit?.pipe?.id!,
        sipuniConfId: edit?.sipuniConfId!,
        id: edit?.id!,
        autoTask: Boolean(values?.autoTask),
        missingCall: Boolean(values?.missingCall),
        incomingCall: Boolean(values?.incomingCall),
        outgoingCall: Boolean(values?.outgoingCall),
        recordingCall: Boolean(values?.recordingCall),
        contactList: edit?.contacts?.map(item => item?.id!)!,
        incomingPipeStatusId: values?.incomingPipeStatusId,
        missingPipeStatusId: values?.missingPipeStatusId,
        outgoingPipeStatusId: values?.outgoingPipeStatusId,
        responsibleById: values?.responsibleById,
        incomingTags: incomingTags?.length > 0 ? incomingTags : null,
        missingTags: missingTags?.length > 0 ? missingTags : null,
        outgoingTags: outgoingTags?.length > 0 ? outgoingTags : null
      })
      .then(() => {
        setEditId(null);
      });
  };

  const pipeSelect = pipeAll?.find(item => item?.id === edit?.pipe?.id);

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        incomingCall: edit?.incomingCall,
        incomingPipeStatusId: edit?.incomingPipeStatusRes?.id,
        outgoingCall: edit?.outgoingCall,
        outgoingPipeStatusId: edit?.outgoingPipeStatusRes?.id,
        missingCall: edit?.missingCall,
        missingPipeStatusId: edit?.missingPipeStatusRes?.id,
        recordingCall: edit?.recordingCall,
        autoTask: edit?.autoTask,
        responsibleById: edit?.responsibleById
      });
      setIncomingTags(edit?.incomingTagList?.map(item => item?.name));
      setOutgoingTags(edit?.outgoingTagList?.map(item => item?.name));
      setMissingTags(edit?.missingTagList?.map(item => item?.name));
    }
  }, [edit, form]);

  return (
    <Form layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item name={'incomingCall'} valuePropName="checked">
        <Checkbox>Kiruvchi qo’ng’iroqlar uchun bitim yaratish</Checkbox>
      </Form.Item>
      <Form.Item name={'incomingPipeStatusId'}>
        <Select className="customSelect" placeholder="Bosqichni tanlang" size="large" suffixIcon={<ArrowDownIcon />}>
          {pipeSelect?.pipeStatuses?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'incomingTags'}>
        <TagSelect setTags={setIncomingTags} tags={incomingTags} />
      </Form.Item>
      <Form.Item name={'outgoingCall'} valuePropName="checked">
        <Checkbox>Chiquvchi qo’ng’iroqlar uchun bitim yaratish</Checkbox>
      </Form.Item>
      <Form.Item name={'outgoingPipeStatusId'}>
        <Select className="customSelect" placeholder="Bosqichni tanlang" size="large" suffixIcon={<ArrowDownIcon />}>
          {pipeSelect?.pipeStatuses?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'outgoingTags'}>
        <TagSelect setTags={setOutgoingTags} tags={outgoingTags} />
      </Form.Item>
      <Form.Item name={'missingCall'} valuePropName="checked">
        <Checkbox>Qabul qilinmagan qo’ng’iroqlar uchun bitim yaratish</Checkbox>
      </Form.Item>
      <Form.Item name={'missingPipeStatusId'}>
        <Select className="customSelect" placeholder="Bosqichni tanlang" size="large" suffixIcon={<ArrowDownIcon />}>
          {pipeSelect?.pipeStatuses?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name={'missingTags'}>
        <TagSelect setTags={setMissingTags} tags={missingTags} />
      </Form.Item>
      <Form.Item name={'recordingCall'} valuePropName="checked">
        <Checkbox>Qo’ng’iroqlarni yozib olish</Checkbox>
      </Form.Item>
      <Form.Item name={'autoTask'} valuePropName="checked">
        <Checkbox>Qabul qilinmagan qo’ng’iroqlar uchun topshiriq yaratish</Checkbox>
      </Form.Item>
      <Form.Item name={'responsibleById'}>
        <Select className="customSelect" size="large" suffixIcon={<ArrowDownIcon />}>
          {employeeList?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              <Avatar
                src={item?.avatar ? BASE_URL + '/' + item?.avatar : avatarDefaultClient}
                size={24}
                icon={<img src={avatarDefaultClient} alt="" />}
              />{' '}
              {item?.fullName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Button className={styles.saveBtn} htmlType="submit" loading={callhandlingMutate.isLoading}>
        {t('home.Saqlash')}
      </Button>
    </Form>
  );
};

export default SipuConfigEdit;
