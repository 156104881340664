import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { TableConfig } from '../../utils/models/TableConfig';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { Builder } from '../../utils/models/BuilderSettings';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import './../../pages/builderSettings/builderSettings.module.scss';

interface Props {
  data: Builder[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: null }>>;
}

const TableBuilder: React.FC<Props> = ({ isLoading, tableConfigFetch, tableConfig, data, isFetching, setModalVisible }) => {
  const { t } = useTranslation();

  const initialColumn: ColumnsType<{}> | undefined = [
    {
      title: t('home.Korxona_nomi'),
      dataIndex: 'name',
      align: 'left'
    },
    {
      title: t('home.Manzil'),
      align: 'left',
      render: (builder: Builder) => {
        return (
          <>
            {getCurrentLanguageWord(builder.city)}, {builder.district}, {builder.address}
          </>
        );
      }
    },
    {
      title: t('home.Korxona_rahbari'),
      dataIndex: 'director',
      align: 'left'
    },
    {
      title: t('home.Telefon_raqam'),
      dataIndex: 'phone'
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];
      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Korxona_nomi':
              newConfig.push({
                title: t('home.Korxona_nomi'),
                dataIndex: 'name',
                align: 'left'
              });
              break;
            case 'Manzil':
              newConfig.push({
                title: t('home.Manzil'),
                align: 'left',
                render: (builder: Builder) => {
                  return (
                    <>
                      {getCurrentLanguageWord(builder.city)}, {builder.district}, {builder.address}
                    </>
                  );
                }
              });
              break;
            case 'Telefon_raqam':
              newConfig.push({
                title: t('home.Telefon_raqam'),
                dataIndex: 'phone'
              });
              break;
            case 'Korxona_rahbari':
              newConfig.push({
                title: t('home.Korxona_rahbari'),
                align: 'left',
                dataIndex: 'director'
              });
              break;
            default:
              break;
          }
        }
      });
      setColumn(newConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConfig, tableConfigFetch]);

  return (
    <Table
      className="custom_table contract_table_size builder_table"
      columns={column}
      dataSource={data}
      loading={isLoading || isFetching || tableConfigFetch}
      pagination={false}
      onRow={(record: any) => {
        return {
          onClick: () => {
            setModalVisible({ visible: true, id: record.id });
          }
        };
      }}
    />
  );
};

export default TableBuilder;
