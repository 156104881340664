import { sliceText } from '@utils/index';
import { Tooltip } from 'antd';
import { AbstractTooltipProps } from 'antd/lib/tooltip';

type Props = AbstractTooltipProps & {
  title?: string;
  length?: number;
  contentClassName?: string;
};

const TooltipShortName = ({ title = '', length = 10, contentClassName = '', ...arg }: Props) =>
  title?.length > length ? (
    <Tooltip title={title} {...arg}>
      <span className={contentClassName}>{sliceText(title, length)}</span>
    </Tooltip>
  ) : (
    <span className={contentClassName}>{title}</span>
  );

export default TooltipShortName;
