import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetFirstPieChartData } from '@pages/bossCrm/services/queries';
import { Empty, Spin } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import FirstRowPieChart from './components/pie-chart/FirstRowPieChart';
import FirstRowStats from './components/stats/FirstRowStats';
import styles from './firstRowBody.module.scss';
const FirstRowBody: React.FC = () => {
  const { startDate, endDate } = useContext(ConversionContext);
  const { t } = useTranslation();
  const { data: pieChartData, isLoading } = useGetFirstPieChartData(startDate, endDate);
  return (
    <Spin spinning={isLoading}>
      <div className={styles.body_container}>
        <FirstRowPieChart pieChartData={pieChartData!} />
        <FirstRowStats pieChartData={pieChartData!} />
      </div>
    </Spin>
  );
};

export default FirstRowBody;
