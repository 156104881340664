import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  size?: number;
  stroke?: string;
  strokeWidth?: number;
}

const ReceiveSquareIcon: React.FC<Props> = ({ className, onClick, size = 32, stroke = '#464255', strokeWidth = 1 }) => {
  return (
    <svg className={className} onClick={onClick} width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0003 29.3333H20.0003C26.667 29.3333 29.3337 26.6666 29.3337 20V12C29.3337 5.33329 26.667 2.66663 20.0003 2.66663H12.0003C5.33366 2.66663 2.66699 5.33329 2.66699 12V20C2.66699 26.6666 5.33366 29.3333 12.0003 29.3333Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
      />
      <path d="M12 15.3467L16 19.3467L20 15.3467" stroke={stroke} strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round" />
      <path d="M16 19.3467V8.68005" stroke={stroke} strokeLinecap="round" strokeWidth={strokeWidth} strokeLinejoin="round" />
      <path
        d="M8 22.0133C13.1867 23.7466 18.8133 23.7466 24 22.0133"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReceiveSquareIcon;
