import { Select } from 'antd';
import React, { FC, useState } from 'react';
import { SipContact } from '../../utils/models/SipContact';
import { useSipuniConfigContact } from '../../utils/service/mutation';
import styles from './sipuContact.module.scss';

interface IProps {
  setContacts: React.Dispatch<React.SetStateAction<number[]>>;
  onChange?: ((value: number[]) => void) | undefined;
  contacts: number[];
  contactAll: SipContact[];
  sipuniConfId?: number;
}

const SipuContact: FC<IProps> = ({ setContacts, onChange, contacts, contactAll, sipuniConfId }) => {
  const createContactMutate = useSipuniConfigContact();
  const [search, setSearch] = useState('');

  const createContact = (value: string) => {
    createContactMutate
      .mutateAsync({
        phoneNumber: value,
        sipuniConfId: sipuniConfId!
      })
      .then(res => {
        setContacts(prev => [...prev, res?.data?.id]);
        onChange && onChange([...contacts, res?.data?.id]);
        setSearch('');
      });
  };

  return (
    <Select
      className={`customSelect ${styles.tags}`}
      size="large"
      mode="multiple"
      value={contacts}
      onChange={(e, option) => {
        setContacts(e);
        onChange && onChange(e);
      }}
      onSearch={setSearch}
      searchValue={search}
      onInputKeyDown={e => {
        if (e.key === 'Enter') {
          createContact(e.currentTarget.value);
        }
      }}
      placeholder="Telefon raqam kiriting"
    >
      {contactAll?.map(item => (
        <Select.Option key={item?.id} value={item?.id}>
          {item?.phoneNumber}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SipuContact;
