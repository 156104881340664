import React from 'react';

const TaskIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16699 16.25H17.5003" stroke="#444444" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.16699 10.4167H17.5003" stroke="#444444" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.16699 4.58325H17.5003" stroke="#444444" strokeOpacity="0.8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.5 4.58341L3.33333 5.41675L5.83333 2.91675"
        stroke="#444444"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10.4167L3.33333 11.25L5.83333 8.75"
        stroke="#444444"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 16.2499L3.33333 17.0833L5.83333 14.5833"
        stroke="#444444"
        strokeOpacity="0.8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TaskIcon;
