import React from 'react';

interface Props {
  className?: string;
}

const ClientBalanceOut: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 27.5C19 29.44 20.5 31 22.34 31H26.1C27.7 31 29 29.64 29 27.94C29 26.12 28.2 25.46 27.02 25.04L21 22.94C19.82 22.52 19.02 21.88 19.02 20.04C19.02 18.36 20.32 16.98 21.92 16.98H25.68C27.52 16.98 29.02 18.54 29.02 20.48"
        stroke="#0078D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24 15V33" stroke="#0078D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M44 24C44 35.04 35.04 44 24 44C12.96 44 4 35.04 4 24C4 12.96 12.96 4 24 4"
        stroke="#0078D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M44 12V4H36" stroke="#0078D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34 14L44 4" stroke="#0078D2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ClientBalanceOut;
