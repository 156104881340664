import React, { FC } from 'react';

interface IProps {
  size?: number;
  stroke?: string;
  strokeWidth?: number;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const CloseIcon: FC<IProps> = ({ size = 24, stroke = 'black', strokeWidth = 1, onClick }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M18.75 5.25L5.25 18.75" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} />
      <path d="M18.75 18.75L5.25 5.25" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} />
    </svg>
  );
};

export default CloseIcon;
