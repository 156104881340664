import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import packageJson from '../package.json';

import App from './App';

Sentry.init({
  release: `uysot@${packageJson.version}`,
  dsn: 'https://e5884069316f75d4ba8e32b175baea4d@o4507984758833152.ingest.us.sentry.io/4507984759881728',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.contextLinesIntegration(),
    Sentry.extraErrorDataIntegration()
  ],
  beforeSend(event, hint) {
    // Agar xatolik turi TypeError bo'lsa, uni yubormang
    const error = hint?.originalException;

    if (error instanceof TypeError) {
      // TypeError'larni tashlab yuboramiz
      return null;
    }
    return event; // Boshqa xabarlar Sentry ga yuboriladi
  },
  denyUrls: [/node_modules/],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],./
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  ignoreErrors: [
    'Non-Error promise rejection captured',
    'Object captured as promise rejection with keys',
    /node_modules/,
    /Specific error message from a problematic package/,
    /Another specific error to ignore/
  ]
});

ReactDOM.render(<App />, document.getElementById('root'));
