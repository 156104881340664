import React from 'react';

const TrashIconKPI = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5 5.97998C18.17 5.64998 14.82 5.47998 11.48 5.47998C9.5 5.47998 7.52 5.57998 5.54 5.77998L3.5 5.97998"
                stroke="#E53535"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 4.97L9.22 3.66C9.38 2.71 9.5 2 11.19 2H13.81C15.5 2 15.63 2.75 15.78 3.67L16 4.97"
                stroke="#E53535"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.3504 9.14001L18.7004 19.21C18.5904 20.78 18.5004 22 15.7104 22H9.29039C6.50039 22 6.41039 20.78 6.30039 19.21L5.65039 9.14001"
                stroke="#E53535"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10.8301 16.5H14.1601" stroke="#E53535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 12.5H15" stroke="#E53535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default TrashIconKPI;
