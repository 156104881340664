import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlusCircle from '@icons/PlusCircle';
import LeadModal from '../client-modal/LeadModal';
import ClientCard from './client-card/ClientCard';
import LeadCard from './lead-card/LeadCard';
import SimilarLeads from './lead-card/SimilarLeads';
import ClientContract from './client-contract/ClientContract';

import styles from './clientInfo.module.scss';

const ClientInfo = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <div className={styles.container_info}>
      <ClientCard />
      <LeadCard />
      <ClientContract />
      <SimilarLeads />
      <div className={styles.similarLeads_add} onClick={handleOpen}>
        <PlusCircle />
        <span>{t('one_lid.lid_biriktirish')}</span>
      </div>

      <LeadModal visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default ClientInfo;
