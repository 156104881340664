import React, { useContext, useState } from 'react';
import ThreeDots from '@pages/bossCrm/components/conversion/assets/icons/ThreeDots';
import { colors } from '@pages/bossCrm/components/conversion/constants/colors';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetSuccesfullLeads } from '@pages/bossCrm/services/mutate';
import { SuccessfullTableData } from '@pages/bossCrm/utils/models/SuccessfullTableData';
import { ThirdPieChartData } from '@pages/bossCrm/utils/models/ThirdPieChartData';
import { useTranslation } from 'react-i18next';

import UnsuccessfulLeads from '../../../../modals/unsuccessful-leads/UnsuccessfulLeads';
import MoreBtn from '../more-btn/MoreBtn';
import OtherValues from '../other-values/OtherValues';

import styles from './rightStats.module.scss';

type Props = {
  pieChartData: ThirdPieChartData;
};
const RightStats: React.FC<Props> = ({ pieChartData }) => {
  const { t } = useTranslation();
  const [isHaveMore, setIsHaveMore] = useState<boolean>(false);
  const { setContext, startDate, endDate, tjmIds, customFieldIds, buildingIds, secondTablePage } = useContext(ConversionContext);
  const secondTableDataMutate = useGetSuccesfullLeads();
  const [visible, setVisible] = useState(false);

  const onCancel = () => {
    setVisible(false);
    setContext({ name: '' });
  };

  const others =
    pieChartData?.fields!?.length > 5
      ? pieChartData?.fields?.slice(5, pieChartData?.fields?.length).map(el => ({
          name: el?.value ? el?.value : t('boss_crm_conversion.Tagsiz'),
          value: el?.count,
          color: colors[0]
        }))
      : [];

  return (
    <div className={styles.s_row_stats_container}>
      <div
        className={styles.title_container}
        onClick={() => {
          setVisible(true);
          setContext({ name: 'second', isTableDataLoading: true });
          secondTableDataMutate
            .mutateAsync({
              page: secondTablePage!,
              size: 10,
              startDate: startDate!,
              finishDate: endDate!,
              houses: tjmIds!?.length > 0 ? tjmIds! : [],
              buildings: buildingIds!?.length > 0 ? buildingIds! : [],
              customFieldId: customFieldIds && customFieldIds !== 'tag' ? (customFieldIds as number) : null,
              tag: customFieldIds === 'tag' ? true : null
            })
            .then((data: SuccessfullTableData) => setContext({ secondTableData: data, isTableDataLoading: false }));
        }}
      >
        {t('boss_crm_conversion.Shartnoma_qilganlar_boyicha_statistika')}
        <ThreeDots />
      </div>
      <div
        className={styles.all_legends_container}
        style={{
          height: isHaveMore ? '320px' : ''
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}
        >
          {pieChartData?.fields!?.length > 0 &&
            pieChartData
              ?.fields!?.slice(0, 5)
              ?.map((el, i) => ({
                name: el?.value ? el?.value : t('boss_crm_conversion.Tagsiz'),
                value: el.count,
                color: colors[i]
              }))
              ?.map(item => (
                <div className={styles.s_row_legends_container} key={item.color}>
                  <div className={styles.legends_item}>
                    <span className={styles.legends_item_circle} style={{ border: `3.48px solid ${item.color}` }} />
                    {item.name}
                  </div>
                  <span className={styles.legends_item_value}>{item.value}</span>
                </div>
              ))}
        </div>
        <OtherValues data={others} isHaveMore={isHaveMore} />
      </div>
      {others!?.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <MoreBtn isHaveMore={isHaveMore} setIsHaveMore={setIsHaveMore} />
        </div>
      )}
      {visible && <UnsuccessfulLeads visible={visible} onCancel={onCancel} />}
    </div>
  );
};

export default RightStats;
