import React from 'react';

const SecurityCardBgIcon = () => {
  return (
    <svg width="46" height="39" viewBox="0 0 46 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2131 1.20378L3.37478 3.86912C2.06928 4.37372 1 5.99103 1 7.44015V17.9204C1 18.9684 1.65898 20.3528 2.46715 20.9739L9.30552 26.2916C10.5116 27.2361 12.4885 27.2361 13.6945 26.2916L20.5329 20.9739C21.341 20.3399 22 18.9684 22 17.9204V7.44015C22 5.99103 20.9307 4.37372 19.6252 3.86912L12.7869 1.20378C12.0782 0.932073 10.9219 0.932073 10.2131 1.20378Z"
        fill="url(#paint0_linear_14261_95892)"
      />
      <g filter="url(#filter0_b_14261_95892)">
        <path
          d="M13.5597 3.87992L6.22636 6.62659C4.82636 7.14659 3.67969 8.81324 3.67969 10.3066V21.1066C3.67969 22.1866 4.38636 23.6133 5.25303 24.2533L12.5864 29.7333C13.8797 30.7066 15.9997 30.7066 17.293 29.7333L24.6264 24.2533C25.493 23.5999 26.1997 22.1866 26.1997 21.1066V10.3066C26.1997 8.81324 25.053 7.14659 23.653 6.62659L16.3197 3.87992C15.5597 3.59992 14.3197 3.59992 13.5597 3.87992Z"
          fill="#78D877"
        />
        <path
          d="M13.3868 3.41075L13.3843 3.41169L6.05227 6.15787C6.05207 6.15795 6.05187 6.15802 6.05167 6.1581C5.23589 6.46127 4.52314 7.08416 4.01663 7.8174C3.50995 8.5509 3.17969 9.43815 3.17969 10.3066V21.1066C3.17969 21.7447 3.38491 22.4483 3.69389 23.068C4.00245 23.6869 4.44053 24.2744 4.95491 24.6547C4.95528 24.6549 4.95564 24.6552 4.95601 24.6555L12.2857 30.1328C12.2859 30.1329 12.2862 30.1331 12.2864 30.1333C13.0347 30.6961 13.9986 30.9633 14.9397 30.9633C15.8808 30.9633 16.8449 30.6961 17.5931 30.1332C17.5933 30.1331 17.5935 30.1329 17.5937 30.1328L24.9257 24.6538L24.9273 24.6525C25.4386 24.2671 25.8762 23.6817 26.1852 23.0635C26.4942 22.4455 26.6997 21.745 26.6997 21.1066V10.3066C26.6997 9.43815 26.3694 8.55091 25.8628 7.8174C25.3563 7.08416 24.6435 6.46127 23.8277 6.1581C23.8275 6.15802 23.8273 6.15795 23.8271 6.15787L16.4951 3.41169L16.4951 3.41168L16.4926 3.41075C16.0405 3.24419 15.4777 3.16992 14.9397 3.16992C14.4017 3.16992 13.8389 3.24419 13.3868 3.41075Z"
          stroke="url(#paint1_linear_14261_95892)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_bd_14261_95892)">
        <path
          d="M26.6808 10.0137H17.5475C17.2809 10.0137 17.0275 10.027 16.7875 10.027C14.4942 10.1604 13.8809 11.0004 13.8809 13.6137V15.1337H30.3342V13.6137C30.3342 10.7337 29.6008 10.0137 26.6808 10.0137Z"
          fill="url(#paint2_linear_14261_95892)"
        />
        <path
          d="M17.5475 10.1137H26.6808C28.1417 10.1137 29.0158 10.2962 29.5324 10.8049C30.0484 11.3131 30.2342 12.1732 30.2342 13.6137V15.0337H13.9809V13.6137C13.9809 12.3078 14.1358 11.4765 14.5525 10.9526C14.9644 10.4346 15.6524 10.1935 16.7904 10.127C16.9077 10.127 17.0279 10.1238 17.1496 10.1206L17.1602 10.1203C17.2869 10.117 17.4157 10.1137 17.5475 10.1137Z"
          stroke="url(#paint3_linear_14261_95892)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter2_bd_14261_95892)">
        <path
          d="M13.8926 16.6001V20.3868C13.8926 23.2668 14.6259 24.0001 17.5459 24.0001H26.6792C29.5459 24.0001 30.3059 23.3068 30.3326 20.5468V16.6001H13.8926ZM18.6926 21.6268H17.0259C16.6259 21.6268 16.3059 21.3068 16.3059 20.9068C16.3059 20.5068 16.6259 20.1868 17.0259 20.1868H18.6926C19.0926 20.1868 19.4126 20.5068 19.4126 20.9068C19.4126 21.3068 19.0926 21.6268 18.6926 21.6268ZM24.0926 21.6268H20.7726C20.3726 21.6268 20.0526 21.3068 20.0526 20.9068C20.0526 20.5068 20.3726 20.1868 20.7726 20.1868H24.0926C24.4926 20.1868 24.8126 20.5068 24.8126 20.9068C24.8259 21.3068 24.4926 21.6268 24.0926 21.6268Z"
          fill="url(#paint4_linear_14261_95892)"
        />
        <path
          d="M13.9926 20.3868V16.7001H30.2326V20.5464C30.2192 21.9252 30.0208 22.7485 29.5024 23.2356C28.9822 23.7244 28.1133 23.9001 26.6792 23.9001H17.5459C16.0853 23.9001 15.2113 23.7143 14.6946 23.2024C14.1783 22.6908 13.9926 21.8271 13.9926 20.3868ZM17.0259 21.7268H18.6926C19.1478 21.7268 19.5126 21.362 19.5126 20.9068C19.5126 20.4515 19.1478 20.0868 18.6926 20.0868H17.0259C16.5707 20.0868 16.2059 20.4515 16.2059 20.9068C16.2059 21.362 16.5707 21.7268 17.0259 21.7268ZM20.7726 21.7268H24.0926C24.5441 21.7268 24.927 21.3652 24.9126 20.9051C24.9117 20.4506 24.5473 20.0868 24.0926 20.0868H20.7726C20.3174 20.0868 19.9526 20.4515 19.9526 20.9068C19.9526 21.362 20.3174 21.7268 20.7726 21.7268Z"
          stroke="url(#paint5_linear_14261_95892)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_14261_95892"
          x="-21.3203"
          y="-21.3301"
          width="72.5195"
          height="76.7935"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95892" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_14261_95892" result="shape" />
        </filter>
        <filter
          id="filter1_bd_14261_95892"
          x="-1.11914"
          y="-4.98633"
          width="46.4531"
          height="35.1201"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95892" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95892" result="effect2_dropShadow_14261_95892" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95892" result="shape" />
        </filter>
        <filter
          id="filter2_bd_14261_95892"
          x="-1.10742"
          y="1.6001"
          width="46.4395"
          height="37.3999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95892" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95892" result="effect2_dropShadow_14261_95892" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95892" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_14261_95892" x1="22" y1="1" x2="3.47237" y2="18.5019" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9BF763" />
          <stop offset="1" stopColor="#26AB5B" />
        </linearGradient>
        <linearGradient id="paint1_linear_14261_95892" x1="7.2645" y1="6.79138" x2="24.7343" y2="24.9772" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_14261_95892" x1="29.1677" y1="10.9382" x2="11.02" y2="12.8397" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_14261_95892" x1="15.6532" y1="11.7312" x2="29.6204" y2="11.979" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint4_linear_14261_95892" x1="29.167" y1="17.9364" x2="10.9306" y2="19.2573" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint5_linear_14261_95892" x1="15.6635" y1="19.0824" x2="29.6217" y2="19.2536" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SecurityCardBgIcon;
