import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import ArrowDownCrmIcon from '../../../../assets/icons/ArrowDownCrmIcon';
import CheckmarkIcon from '../../../../assets/icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '../../../../assets/icons/crmIcons/crmIcons';
import { useCreateLeadTask, useCreateNoteTask, useLeadChatMessage } from '../../../../queries/mutation';
import { useCrmEmployee, useLeadNotClosedContract, useLeadTaskType } from '../../../../queries/queries';
import MDatePicker from '../../../mDatePicker/MDatePicker';
import styles from '../../../../pages/oneLid/components/taskList/taskChatFooter/taskChatFooter.module.scss';
import { FileObject } from '../../../../utils/models/File';
import dayjs from 'dayjs';
import { rootPaths } from '../../../../routing/rootPaths';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { LocalStorage } from '../../../../service/LocalStorage';
import { USER_KEY } from '../../../../utils/constants/localStorageKeys';
import { useQueryClient } from 'react-query';
import { queryNames } from '../../../../utils/constants/queryNames';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../service/I18nService';
import ReactGA from 'react-ga';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../utils/models/GoogleAnalitks';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import newStyles from './taskAdd.module.scss';
import TaskChatFooterNotification from '../../../../pages/oneLid/components/taskList/taskChatFooter/taskChatFooterNotification/TaskChatFooterNotification';

const taskSelect = [
  {
    text: 'Topshiriq'
  },
  {
    text: 'Eslatma'
  }
];

const TaskAdd = () => {
  const inputRef = useRef<TextAreaRef>(null);
  const [activeMessage, setActiveMessage] = useState(true);
  const fronId = LocalStorage.get(USER_KEY) ? LocalStorage.get(USER_KEY).id : 0;
  const qc = useQueryClient();
  const location = useLocation();
  const params = useParams();
  const { data: leadTaskTypeAll } = useLeadTaskType();
  const { data: leadEmployeeList } = useCrmEmployee();
  const { data: leadTaskNoteClosed } = useLeadNotClosedContract(Number(params?.contractId));

  const { t } = useTranslation();

  const leadSave = useCreateLeadTask(Number(params.contractId), createLeadTaskEvent);
  const noteSave = useCreateNoteTask(Number(params.contractId), createLeadNoteEvent);
  const leadChatMessage = useLeadChatMessage(Number(params.contractId), beginChatEvent);
  const [text, setText] = useState('');
  const [mPickerVisble, setMPickerVisble] = useState(false);
  const [intentWidth, setIntentWidth] = useState(0);
  const containerFooterTopRef = useRef<any>(null);
  const [taskVsible, setTaskVsible] = useState(false);
  const [taskChangeText, setTaskChangeText] = useState('Topshiriq');
  const [taskMeetingSelectVisible, setTaskMeetingSelectVisible] = useState(false);
  const [taskMeetingSelectActiveId, setTaskMeetingSelectActiveId] = useState(leadTaskTypeAll ? leadTaskTypeAll![0]?.id : 0);
  const [activeEmployeeId, setActiveEmployeeId] = useState(fronId);
  const [employeeSelectVisible, setEmployeeSelectVisible] = useState(false);

  const [editNotificationId, setEditNotificationId] = useState(0);
  const [files, setFiles] = useState<FileObject[]>();
  const [dateValue, setDateValue] = useState('Bugun');
  const [createDedline, setCreateDedline] = useState({
    start: dayjs().hour(0).minute(0).format('DD.MM.YYYY HH:mm'),
    end: dayjs().hour(23).minute(59).format('DD.MM.YYYY HH:mm')
  });

  useEffect(() => {
    setIntentWidth(Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth));

    const windowLoad = () => {
      setIntentWidth(Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth));
    };

    const windowClick = () => {
      setActiveMessage(true);
      setEditNotificationId(0);
      setIntentWidth(Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth));
    };

    window.addEventListener('load', windowLoad);
    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('load', windowLoad);
      window.removeEventListener('click', windowClick);
    };
  }, [containerFooterTopRef, editNotificationId, activeMessage, taskChangeText]);

  useEffect(() => {
    if (noteSave.isSuccess) {
      qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, Number(params.contractId)]);
      setFiles([]);
    }
    setText('');
    qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
    // eslint-disable-next-line
  }, [noteSave.isSuccess, leadSave.isSuccess]);

  // create lead task
  function createLeadTaskEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CREATE_TASK
    });
  }

  // create lead note
  function createLeadNoteEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CREATE_NOTE
    });
  }

  // begin chat
  function beginChatEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.BEGIN_CHAT
    });
  }

  // translateFor
  const translateFor = (name: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            для <span>{name}</span>:
          </>
        );
      case 'en':
        return (
          <>
            for <span>{name}</span>:
          </>
        );
      default:
        return (
          <>
            <span>{name}</span> uchun:
          </>
        );
    }
  };

  const handleTaskSelect = () => {
    setTaskVsible(true);
  };

  const handleTaskSelectItem = (item: string) => {
    setTaskVsible(false);
    setTaskChangeText(item);
  };

  const handletaskMeetingSelect = () => {
    setTaskMeetingSelectVisible(true);
  };
  const handletaskMeetingSelectItem = (id: number) => {
    setTaskMeetingSelectVisible(false);
    setTaskMeetingSelectActiveId(id);
  };

  const handleEmployeeSelect = () => {
    setEmployeeSelectVisible(true);
  };
  const handleEmployee = (id: number) => {
    setEmployeeSelectVisible(false);
    setActiveEmployeeId(id);
  };

  const handleMPicker = () => {
    setMPickerVisble(true);
  };
  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setTaskMeetingSelectActiveId(leadTaskTypeAll![0]?.id);
    }
  }, [leadTaskTypeAll]);

  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleLeadSave = () => {
    if (taskChangeText === 'Topshiriq') {
      leadSave.mutate({
        assignedById: activeEmployeeId,
        deadlineStart: createDedline.start,
        deadlineFinish: createDedline.end,
        contractId: Number(params?.contractId),
        leadTaskTypeId: taskMeetingSelectActiveId,
        text: text
      });
    } else if (taskChangeText === 'Eslatma') {
      noteSave.mutate({
        contractId: Number(params?.contractId),
        text: text
      });
    }
  };

  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(date + ' ' + start + ' ' + end);
    setCreateDedline({
      start: date + ' ' + start,
      end: date + ' ' + end
    });
  };

  const handleLeadReset = () => {
    setText('');
    setFiles([]);
  };

  const handleChatFooter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIntentWidth(Number(document.querySelector(`.${styles.container_footer_top}`)?.clientWidth));
    setActiveMessage(false);
  };

  const keyDownText = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();
      handleLeadSave();
    } else if (e.key === 'Escape') {
      setActiveMessage(true);
      setText('');
      inputRef.current?.blur();
    }
  };

  return location.pathname === rootPaths.MAIN + MainPaths.CRM + '/new/NewLid' ? (
    <></>
  ) : (
    <div className={`${styles.container} ${newStyles.container}`} onClick={e => handleChatFooter(e)}>
      {leadTaskNoteClosed?.length! > 0 && (
        <div className={styles.container_notification}>
          <div
            className={styles.container_notification_container}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {leadTaskNoteClosed?.map(item => (
              <TaskChatFooterNotification
                {...item}
                key={item.id}
                id={item.id}
                editNotificationId={editNotificationId}
                setEditNotificationId={setEditNotificationId}
              />
            ))}
          </div>
        </div>
      )}
      <div className={styles.container_footer}>
        <div className={styles.container_content}>
          <div className={styles.container_footer_top} ref={containerFooterTopRef}>
            <Dropdown
              visible={taskVsible}
              onVisibleChange={visible => setTaskVsible(visible)}
              overlay={
                <div className={styles.container_footer_top_taskSelect_content}>
                  {taskSelect.map((item, index) => (
                    <div key={index} onClick={() => handleTaskSelectItem(item.text)}>
                      {t(`one_lid.${item.text}`)}
                      {taskChangeText === item.text && (
                        <span>
                          <CheckmarkIcon />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              }
              trigger={['click']}
              placement="topLeft"
            >
              <div className={styles.container_footer_top_taskSelect} onClick={handleTaskSelect}>
                {t(`one_lid.${taskChangeText}`)}
                <span>
                  <ArrowDownCrmIcon strokeColor="#3772FF" />
                </span>
              </div>
            </Dropdown>
            {taskChangeText === 'Topshiriq' && (
              <Dropdown
                visible={mPickerVisble}
                onVisibleChange={setMPickerVisble}
                overlay={
                  <div>
                    <MDatePicker visibleTime={mPickerVisble} onChangeMPicker={onChangeMPicker} />
                  </div>
                }
                trigger={['click']}
                placement={'topCenter'}
              >
                <Input
                  className={styles.container_footer_top_taskInput}
                  onClick={handleMPicker}
                  value={dateValue}
                  style={{
                    width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                  }}
                />
              </Dropdown>
            )}
            {taskChangeText === 'Topshiriq' && (
              <Dropdown
                visible={employeeSelectVisible}
                onVisibleChange={visible => setEmployeeSelectVisible(visible)}
                overlay={
                  <div className={styles.container_footer_top_taskEmployee_content}>
                    {leadEmployeeList!?.map(item => (
                      <div key={item.id} onClick={() => handleEmployee(item.id)}>
                        {item.fullName}{' '}
                        {activeEmployeeId === item.id && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                trigger={['click']}
                placement={'topLeft'}
              >
                <div className={styles.container_footer_top_taskEmployee} onClick={handleEmployeeSelect}>
                  {translateFor(leadEmployeeList!?.find(item => item.id === activeEmployeeId)?.fullName || '')}
                </div>
              </Dropdown>
            )}
            {taskChangeText === 'Topshiriq' && (
              <Dropdown
                trigger={['click']}
                visible={taskMeetingSelectVisible}
                onVisibleChange={visible => setTaskMeetingSelectVisible(visible)}
                overlay={
                  <div className={styles.container_footer_top_taskMeetingSelect_content}>
                    {leadTaskTypeAll?.map(item => (
                      <div key={item.id} onClick={() => handletaskMeetingSelectItem(item.id)}>
                        {crmIcons[item.icon.split('#')[0]]?.icon('#' + item.icon.split('#')[1])}{' '}
                        {item.deleted ? item.name : t(`one_lid.${item.name}`)}
                        {taskMeetingSelectActiveId === item.id && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                placement="topLeft"
              >
                <div className={styles.container_footer_top_taskMeetingSelect} onClick={handletaskMeetingSelect}>
                  {crmIcons[leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[0]!]?.icon(
                    '#' + leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[1]
                  )}
                  {leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.deleted
                    ? leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name
                    : t(`one_lid.${leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name}`)}
                </div>
              </Dropdown>
            )}
          </div>
          <Input.TextArea
            className={styles.container_footer_textArea}
            placeholder={t('one_lid.Matn_kiriting')}
            autoSize={{ minRows: 3 }}
            onChange={e => changeText(e)}
            onKeyUp={e => keyDownText(e)}
            ref={inputRef}
            value={text}
            style={{
              textIndent: intentWidth
            }}
          />
        </div>
        <div className={`${styles.container_submit} ${activeMessage && styles.container_submit_active}`}>
          <div className={styles.container_submit_left}>
            <Button
              type="primary"
              onClick={handleLeadSave}
              loading={leadSave.isLoading || noteSave.isLoading || leadChatMessage.isLoading}
              disabled={
                taskChangeText === 'Topshiriq' || text || ((taskChangeText === 'Chat' || taskChangeText === 'Eslatma') && files?.length! > 0)
                  ? false
                  : true
              }
            >
              {t('one_lid.Yuborish')}
            </Button>
            <Button
              disabled={
                taskChangeText === 'Topshiriq' || text || ((taskChangeText === 'Chat' || taskChangeText === 'Eslatma') && files?.length! > 0)
                  ? false
                  : true
              }
              onClick={handleLeadReset}
            >
              {t('one_lid.Bekor_qilish')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskAdd;
