import React, { FC, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import ArrowLineDownIcon from '@icons/ArrowLineDownIcon';
import { useCompanyTemplatesCompact } from '@queries/queries';
import { FileTemplateLanguage, FileTemplateType } from '@utils/models/FileTemplate';
import { UseMutationResult } from 'react-query';
import WordIcon from '@images/wordIcon.png';
import { useTranslation } from 'react-i18next';
import styles from './downloadTemplate.module.scss';

interface IProps {
  type: FileTemplateType;
  mutation: UseMutationResult<any, any, FileTemplateLanguage | undefined, unknown>;
}

const DownloadTemplate: FC<IProps> = ({ type, mutation }) => {
  const { t } = useTranslation();
  const [activeLang, setActiveLang] = useState<number>();
  const { data } = useCompanyTemplatesCompact(type);

  const download = (lang: FileTemplateLanguage, index: number) => {
    setActiveLang(index);
    mutation.mutate(lang);
  };

  return (
    <div className={styles.dropdown}>
      {data?.map((template, index) => (
        <span key={index} onClick={() => download(template.language, index)} style={{ cursor: 'pointer' }} className={styles.dropdown_item}>
          <span>
            <img src={WordIcon} alt="WordIcon" /> {t(`contract_id.Word_${template.language}`)}
          </span>
          <span className={styles.dropdown_arrowDownload}>
            {mutation.isLoading && activeLang === index ? <LoadingOutlined /> : <ArrowLineDownIcon />}
          </span>
        </span>
      ))}
    </div>
  );
};

export default DownloadTemplate;
