import React from 'react';

interface Props {
  className?: string;
  size?: number;
  stroke?: string;
}

const SuccessIconCreateOrder: React.FC<Props> = ({ className, size = 44, stroke = '#05A660' }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 40C31.9 40 40 31.9 40 22C40 12.1 31.9 4 22 4C12.1 4 4 12.1 4 22C4 31.9 12.1 40 22 40Z"
        stroke={stroke}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.3499 22L19.4439 27.094L29.6499 16.906" stroke={stroke} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SuccessIconCreateOrder;
