import { useEffect } from 'react';
import Edit2Icon from '@icons/Edit2Icon';
import PlusAddCircleIcon from '@icons/PlusAddCircleIcon';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import { Dropdown, Menu, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { PaymentOfferProvider, useAction, useContractVariationGroup } from 'modules/multi-payment';

import PaymentOffer from './components/PaymentOffer';

const Container = () => {
  const { t } = useTranslation();
  const { data } = useContractVariationGroup();

  const { setAction, setQuery, query } = useAction();

  const active = `${query?.groupId}&${query?.name}`;

  useEffect(() => {
    if (!query?.groupId && data && data?.length > 0) {
      const [item] = data;

      setQuery({ groupId: item?.groupId, name: item?.name });
    }
  }, [data, query]);

  return (
    <>
      <div className="ml-[16px] w-full min-w-[50%] rounded-[12px] bg-white">
        <Tabs
          className="w-full"
          tabBarExtraContent={{
            right: (
              <div className="flex h-[52px] cursor-pointer items-center gap-[8px] px-[8px] text-[14px] text-[#1E90FF]" onClick={() => setAction('PAYMENT_TYPE_CREATE')}>
                <PlusAddCircleIcon /> {t("contract_type.Tolov_turi_qoshish")}
              </div>
            )
          }}
          activeKey={active}
          onChange={activeKey => {
            const keyArr = activeKey?.split('&');

            const [groupId, name] = keyArr;

            setQuery({
              groupId: Number(groupId),
              name
            });
          }}
        >
          {data?.map(item => (
            <Tabs.TabPane
              className="m-0"
              tab={
                <span className="group/item flex items-center px-[20px]">
                  {item?.name}{' '}
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          icon={<Edit2Icon />}
                          onClick={() =>
                            setAction('PAYMENT_TYPE_UPDATE', {
                              name: item?.name,
                              groupId: item?.groupId
                            })
                          }
                        >
                          {t('client.Tahrirlash')}
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <DotsIcon className="absolute right-0 opacity-0 group-hover/item:opacity-[1] " />
                  </Dropdown>
                </span>
              }
              key={`${item?.groupId}&${item?.name}`}
            />
          ))}
        </Tabs>
        {data && data?.length > 0 && active ? <PaymentOffer /> : null}
      </div>
    </>
  );
};

const ContractPaymentType = () => (
  <PaymentOfferProvider>
    <Container />
  </PaymentOfferProvider>
);

export default ContractPaymentType;
