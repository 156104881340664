import { $api } from '@service/RequestService';
import { Pagination } from '@utils/models/Pagination';
import { useQuery } from 'react-query';

import { eventListConstants, eventListModels } from '..';

export const useActivityLogsFilterClient = (
  props: {
    page?: number;
    size?: number;
    startDate?: string | null;
    finishDate?: string | null;
    responsibleByIds?: number[] | null;
    objectType?: string | null;
    actionTypes?: string[] | null;
    objectStartDate?: string | null;
    objectFinishDate?: string | null;
    previous?: string | null;
    current?: string | null;
    clientIds?: number[] | null;
    enabled?: boolean;
    search?: string;
  } = {}
) => {
  const { enabled, ...body } = props;

  return useQuery<Pagination<eventListModels.IActivityLog>>({
    queryKey: [eventListConstants.queryKeys.ACTIVITY_LOGS_FILTER_CLIENT, body],
    queryFn: async () => {
      const res = await $api?.post('/v1/activity-logs/filter/client', body);

      return res?.data?.data;
    },
    enabled,
    staleTime: Infinity
  });
};
