import React from 'react';

const SimpleThreeColorIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="16.0003" height="16.0003" rx="8" fill="#FFB400" />
      <rect y="8" width="16.0003" height="16.0003" rx="8" fill="#FF6565" />
      <rect x="4" width="16.0003" height="16.0003" rx="8" fill="#63CBA5" />
    </svg>
  );
};

export default SimpleThreeColorIcon;
