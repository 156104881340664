import React, { FC } from 'react';
interface TwoCheckIconProps {
  color: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const TwoCheckIcon: FC<TwoCheckIconProps> = ({ color, onClick }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M16.869 3.19248C16.7211 3.0283 16.5141 2.92958 16.2934 2.91801C16.0728 2.90645 15.8566 2.98299 15.6923 3.13081L7.94649 10.1016L4.33899 6.49414C4.26212 6.41455 4.17016 6.35107 4.06849 6.30739C3.96682 6.26372 3.85747 6.24073 3.74682 6.23977C3.63618 6.23881 3.52644 6.25989 3.42403 6.30179C3.32161 6.34369 3.22857 6.40557 3.15033 6.48381C3.07208 6.56206 3.01021 6.6551 2.96831 6.75751C2.9264 6.85993 2.90532 6.96966 2.90628 7.08031C2.90724 7.19096 2.93023 7.30031 2.97391 7.40198C3.01758 7.50365 3.08106 7.5956 3.16066 7.67248L7.32732 11.8391C7.47826 11.99 7.68133 12.0772 7.89467 12.0828C8.10802 12.0884 8.31537 12.0119 8.47399 11.8691L16.8073 4.36914C16.9715 4.22126 17.0702 4.01423 17.0818 3.79358C17.0934 3.57292 17.0168 3.35671 16.869 3.19248Z"
        fill={color}
      />
      <path
        d="M15.6924 8.13089L7.94652 15.1017L4.33902 11.4942C4.18185 11.3424 3.97135 11.2584 3.75285 11.2603C3.53435 11.2622 3.32534 11.3499 3.17084 11.5044C3.01633 11.6589 2.92869 11.8679 2.92679 12.0864C2.92489 12.3049 3.00889 12.5154 3.16068 12.6726L7.32735 16.8392C7.47829 16.9901 7.68136 17.0773 7.8947 17.0829C8.10805 17.0885 8.3154 17.012 8.47402 16.8692L16.8074 9.36922C16.9666 9.22007 17.0609 9.01443 17.0702 8.79646C17.0795 8.5785 17.0029 8.36559 16.8569 8.20346C16.7109 8.04133 16.5072 7.94292 16.2895 7.92935C16.0717 7.91578 15.8573 7.98814 15.6924 8.13089Z"
        fill={color}
      />
    </svg>
  );
};

export default TwoCheckIcon;
