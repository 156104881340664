import { DATE_FORMAT } from '@utils/constants/format';
import { START_DATE_DEFAULT } from '@utils/constants/startDateDefault';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const startDate = {
  NOW: dayjs(START_DATE_DEFAULT, DATE_FORMAT).utc(true).unix(),
  TODAY: dayjs().startOf('day').utc(true).unix(),
  YESTERDAY: dayjs(new Date()).startOf('day').add(-1, 'day').utc(true).unix(),
  THIS_WEEK: dayjs(new Date()).startOf('week').add(1, 'day').utc(true).unix(),
  THIS_MONTH: dayjs(new Date()).startOf('month').utc(true).unix()
};
export const finishDate = {
  NOW: dayjs().utc(true).unix(),
  TODAY: dayjs().endOf('day').utc(true).unix(),
  YESTERDAY: dayjs(new Date()).endOf('day').add(-1, 'day').utc(true).unix(),
  THIS_WEEK: dayjs().utc(true).unix(),
  THIS_MONTH: dayjs().utc(true).unix()
};

export const startDateNotUtc = {
  NOW: dayjs(START_DATE_DEFAULT, DATE_FORMAT).unix(),
  TODAY: dayjs().startOf('day').unix(),
  YESTERDAY: dayjs(new Date()).startOf('day').add(-1, 'day').unix(),
  THIS_WEEK: dayjs(new Date()).startOf('week').add(1, 'day').unix(),
  THIS_MONTH: dayjs(new Date()).startOf('month').unix()
};
export const finishDateNotUtc = {
  NOW: dayjs().unix(),
  TODAY: dayjs().endOf('day').unix(),
  YESTERDAY: dayjs(new Date()).endOf('day').add(-1, 'day').unix(),
  THIS_WEEK: dayjs().unix(),
  THIS_MONTH: dayjs().unix()
};
