import React from 'react';
import styles from './profileMenyu.module.scss';
import UserSquareIcon from '../../../assets/icons/UserSquareIcon';
import ShieldTickIcon from '../../../assets/icons/ShieldTickIcon';
import { rootPaths } from '../../../routing/rootPaths';
import { MainPaths } from '../../../routing/main/mainPaths';
import { profilePaths } from '../../../routing/profile/profilePaths';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileMenyu = () => {
  const { t } = useTranslation();

  const profileMenyuArr = [
    {
      title: t('home.shaxsiy_malumotlar'),
      icon: <UserSquareIcon />,
      url: rootPaths.MAIN + MainPaths.PROFILE + profilePaths.INDEX
    },
    {
      title: t('titles.security'),
      icon: <ShieldTickIcon />,
      url: rootPaths.MAIN + MainPaths.PROFILE + profilePaths.SECYURITY
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container_left}>
        {profileMenyuArr?.map((item, index) => (
          <NavLink to={item.url} className={styles.container_left_item} key={index}>
            <div className={styles.container_left_item_content}>
              {item?.icon} {item?.title}
            </div>
          </NavLink>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default ProfileMenyu;
