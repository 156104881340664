import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import EditBorderBottomIcon from '../../assets/icons/EditBorderBottomIcon';
import TrashIcon from '../../assets/icons/TrashIcon';
import TrushSquareGreenIcon from '../../assets/icons/TrushSquareGreenIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useDiscountDelete, useDiscountPartCreate } from '../../queries/mutation';
import { useDiscountId } from '../../queries/queries';

import DiscountSettingCreateModal from './DiscountSettingCreateModal';

// eslint-disable-next-line import/no-duplicates
import '../../pages/discountSetting/discountSetting.module.scss';
// eslint-disable-next-line import/no-duplicates
import styles from '../../pages/discountSetting/discountSetting.module.scss';

const DiscountSettingContainer = () => {
  const { params } = useQueryParam();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const { data, isLoading, isFetching } = useDiscountId();
  const createDiscountPart = useDiscountPartCreate();
  const deleteDiscountPart = useDiscountDelete(setVisibleDelete);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({ parts: data?.parts });
  }, [data, form]);

  const onFinish = (values: any) => {
    createDiscountPart.mutate(values.parts);
  };

  return (
    <div className={styles.discountSettingContainer}>
      <Spin spinning={isLoading || isFetching}>
        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} layout="vertical" autoComplete="off" className="dynamic_form_nest_item">
          <h1>
            <div>
              {data?.name}{' '}
              <Button shape="circle" onClick={() => setVisibleEdit(true)}>
                <EditBorderBottomIcon />
              </Button>
              <Button shape="circle" onClick={() => setVisibleDelete(true)}>
                <TrushSquareGreenIcon />
              </Button>
            </div>
            <Button type="primary" htmlType="submit" loading={createDiscountPart.isLoading}>
              {t('home.Saqlash')}
            </Button>
          </h1>
          <Form.List name="parts">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item {...restField} label={t('discount_setting.Tolov_foiz')} name={[name, 'paymentPercent']} rules={[{ required: true }]}>
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item {...restField} label={t('discount_setting.Chegirma_foiz')} name={[name, 'discountPercent']} rules={[{ required: true }]}>
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item {...restField} label={t('discount_setting.Muddat_oy')} name={[name, 'months']} rules={[{ required: true }]}>
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item>
                      <Button onClick={() => remove(name)} shape="circle" style={{ border: 'none' }}>
                        <TrushSquareGreenIcon />
                      </Button>
                    </Form.Item>
                  </Space>
                ))}
                <Button onClick={() => add()} type="dashed" block icon={<PlusOutlined />}>
                  {t('discount_setting.Chegirma_qoshish')}
                </Button>
              </>
            )}
          </Form.List>
        </Form>
      </Spin>
      <DiscountSettingCreateModal visible={visibleEdit} setVisible={setVisibleEdit} id={params.id} name={data?.name as string} calculateType={data?.calculateType} />
      <Modal
        visible={visibleDelete}
        closable={false}
        title={false}
        footer={false}
        onCancel={() => setVisibleDelete(false)}
        centered
        width={420}
        wrapClassName="settingPlayMobileDeleteModal"
      >
        <span>
          <TrashIcon />
        </span>
        <p>{t('discount_setting.Ochirmoqchimisiz')}</p>
        <div>
          <Button onClick={() => setVisibleDelete(false)}>{t('home.Yopish')}</Button>
          <Button
            type="primary"
            onClick={() => {
              deleteDiscountPart.mutate();
            }}
            loading={deleteDiscountPart.isLoading}
          >
            {t('discount_setting.Ochirish')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DiscountSettingContainer;
