import React from 'react';

const Square = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8359 5.08374C11.6796 4.86613 9.50346 4.75732 7.33725 4.75732C6.05137 4.75732 4.76549 4.82656 3.4895 4.95515L2.16406 5.08374"
        stroke="#374151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.73535 4.43175L5.87383 3.58109C5.97274 2.96782 6.05187 2.50293 7.14981 2.50293H8.85113C9.94907 2.50293 10.0282 2.9876 10.1271 3.58109L10.2656 4.42186"
        stroke="#374151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4412 5.16309L12.0158 11.6914C11.9466 12.7102 11.8873 13.5015 10.0771 13.5015H5.91287C4.10274 13.5015 4.04339 12.7102 3.97415 11.6914L3.54883 5.16309"
        stroke="#374151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Square;
