import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';
import { $api } from '@service/RequestService';
import { NotifConfigModel } from '../utils/models/NotifConfigModel';

export function useGetNotifConfig() {
  return useQuery<NotifConfigModel[]>({
    queryKey: [queryKeys.NOTIF_CONFIG],
    queryFn: async () => {
      const res = await $api.get(endPoints.NOTIF_CONFIG);
      return res.data.data;
    }
  });
}
