import React, { useState } from 'react';
import styles from './monetaryTable.module.scss';
import { Space, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import { useTranslation } from 'react-i18next';
import CheckAnnotationIcon from '@icons/CheckAnnotationIcon';
import CheckExpentIcon from '@icons/CheckExpentIcon';
import MonetaryTableInformation from './monetary-table-informatiion/MonetaryTableInformation';
import MonetaryTableCommentDropDown from './MonetaryTableCommentDropDown';
import { useCurrencyList, useMonetaryPaymentFilter, useMonetaryPaymentFilterCount, useTableConfig } from '@queries/queries';
import { MonetaryPaymentFilter } from '@utils/models/MonetaryPaymentFilter';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import MonetaryTableAddClient from './monetary-table-add-client/MonetaryTableAddClient';
import RefreshIcon from '@icons/RefreshIcon';
import CloseIcon from '@icons/contract/CloseIcon';
import MonetaryPaymentRollback from './MonetaryPaymentRollback';
import MonetaryPaymentAccept from './MonetaryPaymentAccept';
import { useQueryParam } from '@hooks/useQueryParams';
import { TableKey } from '@utils/models/TableKey';
import { rootPaths } from '@routing/rootPaths';
import { MainPaths } from '@routing/main/mainPaths';
import CheckCircleIcon from '@icons/monetary/CheckCircleIcon';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import { CurrencyItem } from '@utils/models/Currency';
import { useMonetaryPayment } from '@queries/mutation';
import { LoadingOutlined } from '@ant-design/icons';
import { useMonetaryPaymentDelete } from '@pages/monetary/services/mutation';

const MonetaryTable = () => {
  const { searchParams, appendMultipleDifferent, push } = useQueryParam<any, any>();
  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.monetary);
  const { data: currencyList } = useCurrencyList();

  const { data: monetaryData, isLoading } = useMonetaryPaymentFilter(searchParams);
  const { data: count } = useMonetaryPaymentFilterCount(searchParams);
  const { t } = useTranslation();
  const [openInformation, setOpenInformation] = useState<number | null>(null);
  const [openAddClient, setOpenAddClient] = useState<number | null>(null);
  const [rollbackId, setRollbackId] = useState<number | null>(null);
  const [offsetTop, setOffsetTop] = useState(0);
  const monetaryPaymentMutate = useMonetaryPayment();
  const monetaryPaymentDeleteMutate = useMonetaryPaymentDelete();

  const getCurrencyValue = (props: { amount: number; currencyAmount: number; id: number; currency: CurrencyItem }) => {
    const { id, amount, currencyAmount, currency } = props;

    if (currency?.ccy === 'UZS') {
      return `${amount?.toLocaleString('ru')} ${currency.ccy}`;
    } else {
      return `${currencyAmount?.toLocaleString('ru')} ${currency.ccy}`;
    }
  };

  const handleCurrency = (currencyId: number, record: MonetaryPaymentFilter) => {
    monetaryPaymentMutate.mutate({
      paymentId: record?.id,
      currencyId,
      note: record?.note
    });
  };

  const handleDelete = (id: number) => {
    monetaryPaymentDeleteMutate.mutate(id);
  };

  const column: ColumnsType<MonetaryPaymentFilter> | undefined = [
    {
      key: 1,
      dataIndex: 'createdDate',
      title: 'Sana'
    },
    // {
    //   key: 2,
    //   dataIndex: 'toInn',
    //   title: 'Qabul_qiluvchi_INN',
    // },
    // {
    //   key: 3,
    //   dataIndex: 'toAccount',
    //   title: 'Qabul_qiluvchi_hisob',
    // },
    {
      key: 4,
      dataIndex: 'amount',
      title: 'Summa',
      render(value, record, index) {
        return (
          <Space direction="vertical" style={{ opacity: record?.accepted ? 0.5 : 1 }}>
            {currencyList && currencyList?.length > 0 ? (
              currencyList?.map(item => (
                <p
                  className={styles.valyutaAction}
                  style={{
                    textDecoration: item?.id === record?.currencyId ? 'underline' : '',
                    color: item?.id !== record?.currencyId ? '#667085' : ''
                  }}
                  onClick={() => !record?.accepted && handleCurrency(item?.id!, record)}
                >
                  {monetaryPaymentMutate.isLoading &&
                  monetaryPaymentMutate.variables?.paymentId === record?.id &&
                  monetaryPaymentMutate.variables?.currencyId === item?.id ? (
                    <LoadingOutlined />
                  ) : (
                    <CheckCircleIcon checked={item?.id === record?.currencyId} />
                  )}{' '}
                  {getCurrencyValue({
                    currencyAmount: record?.currencyAmount,
                    amount: record?.amount,
                    id: record?.currencyId,
                    currency: item
                  })}
                </p>
              ))
            ) : (
              <p
                className={styles.valyutaAction}
                style={{
                  textDecoration: 'underline'
                }}
              >
                <CheckCircleIcon checked />
                {record?.amount?.toLocaleString('ru')} UZS{' '}
              </p>
            )}
          </Space>
        );
      }
    },
    { key: 5, dataIndex: 'description', title: 'Izoh' },
    {
      key: 6,
      title: 'Tolov_shartnomasi',
      render(value, record, index) {
        return record?.paymentContractNumber ? (
          <div>
            <h3>{record?.paymentContractFullName}</h3>
            <p>
              {t('home.Shartnoma')}: {record?.paymentContractNumber}
            </p>
            <p>
              {t('home.Sana')}: {record?.paymentContractDate}
            </p>
          </div>
        ) : (
          ''
        );
      }
    },
    {
      key: 7,
      dataIndex: 'uysotContract',
      title: 'Uysot_shartnomasi',
      width: 300,
      render(value, record, index) {
        return record?.contractNumber ? (
          <div className="d-flex">
            <div>
              <h3>{record?.contractFullName}</h3>
              <p
                style={{
                  cursor: 'pointer',
                  color: 'blue'
                }}
                onClick={() => push(rootPaths.MAIN + MainPaths.CONTRACT + '/' + record?.contractId + '/' + record?.contractFullName)}
              >
                {t('home.Shartnoma')}: {record?.contractNumber}
              </p>
              <p>
                {t('home.Sana')}: {record?.contractDate}
              </p>
            </div>
            <span className={styles.refresh} onClick={() => setOpenAddClient(record?.id)}>
              <RefreshIcon />
            </span>
          </div>
        ) : (
          <div className={styles.addContract} onClick={() => setOpenAddClient(record?.id)}>
            <PlusCircleIcon stroke="#464255" size={32} strokeWidth={1} />
          </div>
        );
      }
    },
    {
      key: 8,
      title: 'Hisob_raqam',
      dataIndex: 'toAccount'
    },
    {
      key: 9,
      title: 'Yuboruvchi',
      dataIndex: 'toName'
    }
  ];

  const columnsSort = tableConfig?.config?.map((item: any) => ({
    ...(item.checked && column?.find(col => col?.key === item?.id))
  }));

  const realCoumns = (columnsSort || column)
    .map((item: any, index: number) => ({
      ...item,
      ...(item?.title && {
        title: t(`home.${item?.title}`)
      })
    }))
    .filter(item => item?.title);

  const changeAccept = (key: string) => {
    appendMultipleDifferent(['accept'], [key]);
  };

  // changePage
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(['page', 'size'], [page, size]);
  };

  return (
    <div className={styles.container}>
      <Tabs className={styles.tabs} onChange={changeAccept} activeKey={searchParams.accept || 'null'}>
        <Tabs.TabPane
          tab={
            <>
              {t('home.Barchasi')} <span className={styles.badge}>{count?.all}</span>
            </>
          }
          key={'null'}
        />
        <Tabs.TabPane
          tab={
            <>
              {t('online_pbx.Qabul_qilinmagan')} <span className={styles.badge}>{count?.notAccepted}</span>
            </>
          }
          key={'false'}
        />
        <Tabs.TabPane
          tab={
            <>
              {t('monetary.Qabul_qilingan')} <span className={styles.badge}>{count?.accepted}</span>
            </>
          }
          key={'true'}
        />
      </Tabs>
      <Table
        loading={tableConfigFetch || isLoading}
        columns={[
          ...realCoumns,
          {
            render(value, record, index) {
              return (
                <div className={styles.editIcons}>
                  <span onClick={() => setOpenInformation(record?.id)}>
                    <CheckExpentIcon />
                  </span>
                  {record?.accepted ? (
                    <span
                      onClick={() => {
                        setRollbackId(record?.id);
                      }}
                    >
                      <CloseIcon stroke="red" />{' '}
                    </span>
                  ) : record?.contractNumber ? (
                    <MonetaryPaymentAccept id={record?.id} />
                  ) : (
                    ''
                  )}
                  {monetaryPaymentDeleteMutate.isLoading && monetaryPaymentDeleteMutate.variables === record?.id ? (
                    <LoadingOutlined />
                  ) : (
                    <span
                      className={styles.trush}
                      style={{ background: record?.accepted ? '#9F9F9F' : '' }}
                      onClick={() => !record?.accepted && handleDelete(record?.id)}
                    >
                      <TrushIcon />
                    </span>
                  )}
                </div>
              );
            },
            width: 160
          }
        ]}
        dataSource={monetaryData?.data}
        className="tableNewStyle"
        style={{ height: `calc(100vh - ${offsetTop}px)` }}
        scroll={{ y: `calc(100vh - ${offsetTop + 50}px)` }}
        ref={ref => setOffsetTop((ref?.offsetTop || 0) + 80)}
        pagination={{
          className: 'tableNewStyle_pagination',
          prevIcon: (
            <div className={'tableNewStyle_pagination_prev'}>
              <ArrowLeftIcon /> {t('my_calls.Ortga')}
            </div>
          ),
          onChange(page, pageSize) {
            changePage(page, 10);
          },
          pageSize: 10,
          total: monetaryData?.totalElements,
          current: Number(searchParams?.page) || 1,
          nextIcon: (
            <div className={'tableNewStyle_pagination_next'}>
              {t('crm_ins.Keyingisi')} <ArrowRightIcon />
            </div>
          )
        }}
      />
      <MonetaryTableInformation id={openInformation} setVisible={setOpenInformation} />
      <MonetaryTableAddClient id={openAddClient} setVisible={setOpenAddClient} />
      <MonetaryPaymentRollback id={rollbackId} setVisible={setRollbackId} />
    </div>
  );
};

export default MonetaryTable;
