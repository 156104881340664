import React from 'react';
import { Checkbox, Input } from 'antd';
import styles from '../triggers.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { setNote, setUseAll } from '../../../../../../../store/reducers/TriggerSlice';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

interface Props {
  showTextArea: boolean;
}

const Note: React.FC<Props> = ({ showTextArea = true }) => {
  const note = useAppSelector(state => state.triggerSlice.note);
  const useAll = useAppSelector(state => state.triggerSlice.useAll);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // handle use all
  const handleUseAll = (e: CheckboxChangeEvent) => {
    dispatch(setUseAll(e.target.checked));
  };

  // handle note
  const handleNote = (value: string) => {
    dispatch(setNote(value));
  };

  return (
    <>
      {showTextArea && (
        <Input.TextArea
          value={note}
          onChange={e => handleNote(e.target.value)}
          className={styles.container_body_note}
          placeholder={t('triggers.Izoh')}
          autoSize={{ minRows: 2, maxRows: 2 }}
        />
      )}
      <div className={styles.container_body_use_all}>
        <Checkbox checked={useAll} onChange={handleUseAll} />
        <div className={styles.container_body_use_all_title}>{t('triggers.Triggerni_ushbu_statusdagi_barcha_bitimlarda_qollash')}</div>
      </div>
    </>
  );
};

export default Note;
