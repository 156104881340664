import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import DiscountShapeIcon from '../../../../assets/icons/DiscountShapeIcon';
import HammerAndPickIcon from '../../../../assets/icons/HammerAndPickIcon';
import TagTwoYellowIcon from '../../../../assets/icons/TagTwoYellowIcon';
import FlatGalleryFC from '../../../../components/flat-gallery/FlatGallery';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { changePercentDiscount, changeRepairedStatus, setClearOneFlatDiscount } from '../../../../store/reducers/CreateContractSlice';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { CurrencyItem } from '../../../../utils/models/Currency';
import { FlatMore } from '../../../../utils/models/FlatMore';

import DiscountPricePerArea from './DiscountPricePerArea';
import ExtraInformationFlat from './ExtraInformationFlat';
import TotalAmount from './TotalAmount';

import styles from './flatCard.module.scss';

interface Props {
  flatMore: FlatMore;
  currency: CurrencyItem | undefined;
}

const FlatCard: React.FC<Props> = ({ flatMore, currency }) => {
  const [height, setHeight] = useState(0);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // getPricePerAreaDiscount
  const getPricePerAreaDiscount = () => {
    if (flatMore.repaired) {
      return roundFloatNumber(flatMore.flat.pricePerAreaRepaired - flatMore.pricePerArea).toLocaleString('ru');
    }
    return roundFloatNumber(flatMore.flat.pricePerArea - flatMore.pricePerArea).toLocaleString('ru');
  };

  // getPricePerAreaDiscountTerrace
  const getPricePerAreaDiscountTerrace = () => {
    if (flatMore.repaired) {
      return roundFloatNumber((flatMore.flat.terrace?.pricePerAreaRepaired || 0) - (flatMore.pricePerAreaTerrace || 0)).toLocaleString('ru');
    }
    return roundFloatNumber((flatMore.flat.terrace?.pricePerArea || 0) - (flatMore.pricePerAreaTerrace || 0)).toLocaleString('ru');
  };

  // handle change repaired type
  const handleChangeRepairedType = () => {
    if (flatMore.isCanChangeRepaired) {
      dispatch(changeRepairedStatus(flatMore.flat.id));
    }
  };

  // change discount percent
  const changeDiscountPercent = () => {
    dispatch(changePercentDiscount(flatMore.flat.id));
  };

  // calculate discount percent
  const discountPercent = () => {
    if (flatMore.discountAmount) {
      const amount = flatMore.repaired ? flatMore.flat.priceRepaired : flatMore.flat.priceNotRepaired;

      return roundFloatNumber((flatMore.discountAmount / amount) * 100);
    }
    return 0;
  };

  // discountAmountFlat
  const discountAmountTerrace = () => {
    if (flatMore.discountPercent !== 0) {
      return '0';
    }
    if (flatMore.discountAmount) {
      const pricePerArea = flatMore.repaired ? flatMore.flat?.terrace?.pricePerAreaRepaired || null : flatMore.flat?.terrace?.pricePerArea || null;

      if (pricePerArea === flatMore.pricePerAreaTerrace) return '0';

      const priceFlat = flatMore.repaired ? flatMore.flat.pricePerAreaRepaired : flatMore.flat.pricePerArea;

      return roundFloatNumber(flatMore.discountAmount - (priceFlat - flatMore.pricePerArea) * flatMore.flat.area).toLocaleString('ru');
    }
    return '0';
  };

  // discountAmountFlat
  const discountAmountFlat = () => {
    if (flatMore.discountAmount) {
      const priceTerrace = flatMore.repaired ? flatMore.flat.terrace?.pricePerAreaRepaired || 0 : flatMore.flat.terrace?.pricePerArea || 0;

      return roundFloatNumber(flatMore.discountAmount - ((priceTerrace - (flatMore.pricePerAreaTerrace || 0)) * flatMore.flat.terrace?.area || 0)).toLocaleString('ru');
    }
    return '0';
  };

  // handle clear all discount
  const handleClearAllDiscount = () => {
    dispatch(setClearOneFlatDiscount(flatMore.flat.id));
  };

  // handle price with terrace discount
  const handlePriceWithTerraceDiscount = () => {
    if (flatMore.repaired && flatMore.flat.terrace) {
      if (flatMore.pricePerAreaTerrace !== flatMore.flat.terrace.pricePerAreaRepaired) {
        return roundFloatNumber(
          flatMore.totalAmount - ((flatMore.flat.terrace?.pricePerAreaRepaired || 0) - (flatMore.pricePerAreaTerrace || 0)) * flatMore.flat.terrace.area - flatMore.firstPayment
        );
      }
    }
    if (!flatMore.repaired && flatMore.flat.terrace) {
      if (flatMore.pricePerAreaTerrace !== flatMore.flat.terrace.pricePerArea) {
        return roundFloatNumber(
          flatMore.totalAmount - ((flatMore.flat.terrace?.pricePerArea || 0) - (flatMore.pricePerAreaTerrace || 0)) * flatMore.flat.terrace.area - flatMore.firstPayment
        );
      }
    }
    return roundFloatNumber(flatMore.totalAmount - flatMore.firstPayment);
  };

  // handle price with terrace discount
  const handlePriceDiscount = () => {
    if (flatMore.flat.terrace) {
      if (flatMore.repaired) {
        if (flatMore.flat.pricePerAreaRepaired === flatMore.pricePerArea && flatMore.flat.terrace.pricePerAreaRepaired === flatMore.pricePerAreaTerrace) {
          return roundFloatNumber(flatMore.totalAmount - (flatMore.discountAmount || 0) - (flatMore.flat.terrace.pricePerAreaRepaired || 0) * flatMore.flat.terrace.area);
        }
        return roundFloatNumber(
          flatMore.totalAmount -
            (flatMore.flat.pricePerAreaRepaired - flatMore.pricePerArea) * flatMore.flat.area -
            (flatMore.flat.terrace.pricePerAreaRepaired || 0) * flatMore.flat.terrace.area
        );
      }
      if (flatMore.flat.pricePerArea === flatMore.pricePerArea && flatMore.flat.terrace.pricePerArea === flatMore.pricePerAreaTerrace) {
        return roundFloatNumber(flatMore.totalAmount - (flatMore.discountAmount || 0) - (flatMore.flat.terrace.pricePerArea || 0) * flatMore.flat.terrace.area);
      }
      return roundFloatNumber(
        flatMore.totalAmount - (flatMore.flat.pricePerArea - flatMore.pricePerArea) * flatMore.flat.area - (flatMore.flat.terrace.pricePerArea || 0) * flatMore.flat.terrace.area
      );
    }

    if (flatMore.repaired) {
      if (flatMore.flat.pricePerAreaRepaired === flatMore.pricePerArea) {
        return roundFloatNumber(flatMore.totalAmount - (flatMore.discountAmount || 0));
      }
      return roundFloatNumber(flatMore.totalAmount - (flatMore.flat.pricePerAreaRepaired - flatMore.pricePerArea) * flatMore.flat.area);
    }
    if (flatMore.flat.pricePerArea === flatMore.pricePerArea) {
      return roundFloatNumber(flatMore.totalAmount - (flatMore.discountAmount || 0));
    }
    return roundFloatNumber(flatMore.totalAmount - (flatMore.flat.pricePerArea - flatMore.pricePerArea) * flatMore.flat.area);
  };

  // handle price for terrace
  const handlePriceForTerraceDiscount = () => {
    if (flatMore.flat.terrace) {
      if (flatMore.repaired) {
        return roundFloatNumber(
          flatMore.totalAmount -
            (Number(flatMore.flat?.terrace?.pricePerAreaRepaired) - Number(flatMore?.pricePerAreaTerrace)) * flatMore.flat.terrace?.area -
            (flatMore.flat.pricePerAreaRepaired || 0) * flatMore.flat.area
        );
      }
      return roundFloatNumber(
        flatMore.totalAmount -
          (Number(flatMore.flat?.terrace?.pricePerArea) - Number(flatMore?.pricePerAreaTerrace)) * flatMore.flat.terrace?.area -
          (flatMore.flat.pricePerArea || 0) * flatMore.flat.area
      );
    }
    return 0;
  };

  // handle price for terrace
  const handlePriceForTerrace = () => {
    if (flatMore.repaired) {
      if (flatMore.pricePerArea !== flatMore.flat.pricePerAreaRepaired) {
        return roundFloatNumber(flatMore.totalAmount - ((flatMore.flat.pricePerAreaRepaired || 0) - (flatMore.pricePerArea || 0)) * flatMore.flat.area - flatMore.firstPayment);
      }
    }
    if (!flatMore.repaired) {
      // eslint-disable-next-line no-self-compare
      if (flatMore.pricePerArea !== flatMore.pricePerArea) {
        return roundFloatNumber(flatMore.totalAmount - ((flatMore.flat.pricePerArea || 0) - (flatMore.pricePerArea || 0)) * flatMore.flat.area - flatMore.firstPayment);
      }
    }
    return roundFloatNumber(flatMore.totalAmount - flatMore.firstPayment);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_image}>
        <FlatGalleryFC gallery={flatMore.flat.gallery} />
      </div>
      <div className={styles.container_body}>
        <div className={styles.container_body_icons}>
          <div className={styles.container_body_icons_left}>
            <Tooltip title={`${flatMore.flat.rooms} ${t('home_id.xonali')}`}>
              <div className={styles.container_body_icons_left_room}>
                <span>{flatMore.flat.rooms}x</span>
              </div>
            </Tooltip>
            {flatMore.repaired ? (
              <Tooltip title={t('home_id.repaired')}>
                <div onClick={handleChangeRepairedType} className={styles.container_body_icons_left_repaired}>
                  <HammerAndPickIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={t('home_id.not_repaired')}>
                <div onClick={handleChangeRepairedType} className={styles.container_body_icons_left_not_repaired}>
                  <HammerAndPickIcon />
                </div>
              </Tooltip>
            )}
          </div>
          {flatMore.discountAmount !== 0 && flatMore.discountPercent === 0 && (
            <div className={styles.container_body_icons_right}>
              <div onClick={handleClearAllDiscount} className={styles.container_body_icons_right_active_discount}>
                <TagTwoYellowIcon />
                <span>{discountPercent()}%</span>
              </div>
            </div>
          )}
          {flatMore.discountPercent !== 0 && (
            <div onClick={changeDiscountPercent} className={styles.container_body_icons_right}>
              <div className={flatMore.discountAmount ? styles.container_body_icons_right_active_discount : styles.container_body_icons_right_discount}>
                <TagTwoYellowIcon />
                <span>{flatMore.discountPercent}%</span>
              </div>
            </div>
          )}
        </div>
        <div>
          <ExtraInformationFlat discountAmount={flatMore.discountAmount || 0} totalPrice={flatMore.totalAmount} prePayment={flatMore.firstPayment} flat={flatMore.flat} />
          <TotalAmount currency={currency} flat={flatMore} />
          <DiscountPricePerArea
            priceWithoutDiscount={handlePriceDiscount()}
            currency={currency}
            id={flatMore.flat.id}
            area={flatMore.flat.area}
            firstPrice={flatMore.repaired ? flatMore.flat.pricePerAreaRepaired : flatMore.flat.pricePerArea}
            totalAmount={handlePriceWithTerraceDiscount()}
            isTerrace={false}
          />
          {flatMore.pricePerAreaTerrace !== null && (
            <DiscountPricePerArea
              priceWithoutDiscount={handlePriceForTerraceDiscount()}
              currency={currency}
              totalAmount={handlePriceForTerrace()}
              firstPrice={flatMore.repaired ? flatMore.flat.terrace?.pricePerAreaRepaired || null : flatMore.flat.terrace?.pricePerArea || null}
              id={flatMore.flat.id}
              area={flatMore.flat.terrace?.area}
              isTerrace={true}
            />
          )}
        </div>
        <div className={styles.container_body_discount_cont}>
          <div style={{ height: flatMore.discountAmount !== 0 ? height : 0 }}>
            <div
              ref={e => {
                setHeight(Number(e?.offsetHeight));
              }}
            >
              <hr className={styles.container_body_line} />
              <p className={styles.container_body_discount}>
                <TagTwoYellowIcon /> {discountPercent()}% {t('home_id.lik_chegirma_qollandi')}
              </p>
              <p className={styles.container_body_discount_price}>
                <DiscountShapeIcon />
                <span className={styles.container_body_discount_price_info}>
                  {discountAmountFlat()} {currency?.ccy}
                </span>
                {getPricePerAreaDiscount() !== '0' && (
                  <>
                    <span className={styles.container_body_discount_price_donut} />
                    <span className={styles.container_body_discount_price_info}>
                      {getPricePerAreaDiscount()} {currency?.ccy}
                    </span>
                  </>
                )}
              </p>
              {discountAmountTerrace() !== '0' && (
                <p className={styles.container_body_discount_price}>
                  <DiscountShapeIcon />
                  <span className={styles.container_body_discount_price_info}>
                    {discountAmountTerrace()} {currency?.ccy}
                  </span>
                  {getPricePerAreaDiscountTerrace() !== '0' && (
                    <>
                      <span className={styles.container_body_discount_price_donut} />
                      <span className={styles.container_body_discount_price_info}>
                        {getPricePerAreaDiscountTerrace()} {currency?.ccy}
                      </span>
                    </>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatCard;
