import React, { FC } from 'react';

interface IProps {
  color?: string;
}

const LineIcon: FC<IProps> = ({ color = '#F7BA1E' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="32" viewBox="0 0 10 32" fill="none">
      <g filter="url(#filter0_d_3581_9092)">
        <rect x="3" width="4" height="32" rx="2" fill={color} />
      </g>
    </svg>
  );
};

export default LineIcon;
