import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HouseConfigState {
  editModalOpen: boolean;
  openDeleteModal: boolean;
  id?: number;
  notSalePageSize: number;
  salePageSize: number;
  houseName: string;
}

const initialState: HouseConfigState = {
  editModalOpen: false,
  openDeleteModal: false,
  notSalePageSize: 10,
  salePageSize: 10,
  houseName: ''
};

export const houseConfigSlice = createSlice({
  name: 'houseConfig',
  initialState,
  reducers: {
    setHouseCreateModalOpen: state => {
      state.editModalOpen = true;
    },
    setHouseEditModalOpen: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
      state.editModalOpen = true;
    },
    clearHouseEditModalOpen: state => {
      state.id = undefined;
      state.editModalOpen = false;
    },
    setNotSalePageSize: (state, action: PayloadAction<number>) => {
      state.notSalePageSize = action.payload;
    },
    setSalePageSize: (state, action: PayloadAction<number>) => {
      state.salePageSize = action.payload;
    },
    setOpenDeleteModal: (
      state,
      action: PayloadAction<{
        id: number;
        houseName: string;
      }>
    ) => {
      state.openDeleteModal = true;
      state.houseName = action.payload.houseName;
      state.id = action.payload.id;
    },
    setCloseDeleteModal: state => {
      state.openDeleteModal = false;
      state.houseName = '';
      state.id = undefined;
    },
    clearHouseConfig: state => {
      state.editModalOpen = false;
      state.openDeleteModal = false;
      state.id = undefined;
      state.notSalePageSize = 10;
      state.salePageSize = 10;
      state.houseName = '';
    }
  }
});

export const {
  setHouseCreateModalOpen,
  setNotSalePageSize,
  setSalePageSize,
  setHouseEditModalOpen,
  clearHouseEditModalOpen,
  setOpenDeleteModal,
  setCloseDeleteModal,
  clearHouseConfig
} = houseConfigSlice.actions;
export default houseConfigSlice.reducer;
