import { Modal } from 'antd';

import { useAction } from '../hooks';

import { actions } from './actions';

const Action = () => {
  const { closeAction, action } = useAction();

  const activeAction = action ? actions[action] : undefined;

  return (
    <Modal
      closable={activeAction?.closable}
      title={activeAction?.title}
      visible={!!action}
      onCancel={closeAction}
      footer={false}
      width={activeAction?.width}
      className="transition-[width]"
    >
      {activeAction?.component}
    </Modal>
  );
};

export default Action;
