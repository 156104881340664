import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import styles from './showroomWidget.module.scss';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import { UseMutationResult } from 'react-query';

interface IProps {
  visible: boolean;
  setVisible: (
    value: React.SetStateAction<{
      visible: boolean;
      id: number;
      text: string;
    }>
  ) => void;
  id: number;
  text: string;
  bookingLeadFlatDelete: UseMutationResult<any, any, number, unknown>;
}

const BookingLeadFlatDeleteModal: FC<IProps> = ({ id, setVisible, text, visible, bookingLeadFlatDelete }) => {
  const handleClose = () => {
    setVisible({
      id: 0,
      text: '',
      visible: false
    });
  };

  const handleDelete = () => {
    bookingLeadFlatDelete.mutateAsync(id).then(() => {
      handleClose();
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      wrapClassName={styles.bookingLeadFlatDeleteModal}
      footer={false}
      title={false}
      closable={false}
      width={400}
    >
      <span>
        <TrashIcon />
      </span>
      <p>{text} raqamli buyurtma o'chirilsinmi?</p>
      <div>
        <Button onClick={handleClose}>Yopish</Button>
        <Button onClick={handleDelete} loading={bookingLeadFlatDelete.isLoading}>
          O'chirish
        </Button>
      </div>
    </Modal>
  );
};

export default BookingLeadFlatDeleteModal;
