import React from 'react';
import styles from './monetary.module.scss';
import MainHeader from '../../components/headers/MainHeader';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import Monetaryheader from './components/monetary-header/Monetaryheader';
import MonetaryTable from './components/monetary-table/MonetaryTable';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Permissions } from '../../utils/constants/permissions';
import MonetaryIntegration from './components/monetary-integration/MonetaryIntegration';

const Monetary = () => {
  const user = useAppSelector(state => state.userReducer.user);

  const isMonetary = user?.role.permissions?.includes(Permissions.PERMISSION_MONETARY_PAYMENT);
  const { t } = useTranslation();
  return (
    <>
      <MainHeader>
        <h1 className="header_title">{t('home.BANK')}</h1>
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      {isMonetary ? (
        <>
          <Monetaryheader />
          <div className={styles.container}>
            <MonetaryTable />
          </div>
        </>
      ) : (
        <MonetaryIntegration />
      )}
    </>
  );
};

export default Monetary;
