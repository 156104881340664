import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cx } from '@utils/cx';
import { Button, Col, Form, FormInstance, Input, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import CalendarIcon from '../../../assets/icons/CalendarIcon';
import SearchIcon from '../../../assets/icons/SearchIcon';
import { useSearchClientBuPassportData } from '../../../queries/queries';
import { hasKril } from '../../../utils/helper/hasKril';
import { validateDateString } from '../../../utils/helper/validatorDateString';
import { TypeSearchClientByPassport } from '../../../utils/models/typeSearchClientByPassport';

import { FormAddress } from './Natural';

import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

interface Props {
  form: FormInstance<FormAddress>;
  wrapperClassName?: string;
}

const SearchClientWithPassportData: React.FC<Props> = ({ form, wrapperClassName }) => {
  const [krilAlpha, setKrilAlpha] = useState(false);
  const { t } = useTranslation();
  const params = useParams<{ orderIdOrFlatId: string }>();

  const [searchData, setSearchData] = useState({
    passportSerial: '',
    passportNumber: '',
    dateBirth: ''
  });

  const { isLoading } = useSearchClientBuPassportData(searchData.passportSerial, searchData.passportNumber, searchData.dateBirth, TypeSearchClientByPassport.CLIENT);

  const onFinish = (values: FormAddress) => {
    setSearchData(values);
  };

  const checkFieldsRequired = !(!params.orderIdOrFlatId || params?.orderIdOrFlatId?.includes('clientId'));

  return (
    <div className={cx(styles.inputs_cont, { [wrapperClassName || '']: wrapperClassName })}>
      <Form form={form} labelCol={{ span: 24 }} name="searchClientWithPassportData" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="passport"
              rules={[
                {
                  required: checkFieldsRequired,
                  message: ``
                }
              ]}
              label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Passport_seriya_va_raqami')}</span>}
            >
              <Row>
                <Col span={6}>
                  <Tooltip
                    overlay="Passport seriyasi kiril alifbosida kiritildi.
                    Ma'lumot topilmasligi mumkin!"
                    visible={krilAlpha}
                  >
                    <Form.Item
                      name="passportSerial"
                      noStyle
                      rules={[
                        { required: checkFieldsRequired, message: '' },
                        {
                          validator: (rule, value) => {
                            if (value) {
                              form.setFieldsValue({
                                passportSerial: value.toUpperCase().slice(0, 4)
                              });
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                    >
                      <Input
                        className="customInput"
                        style={{
                          width: '85%',
                          textTransform: 'uppercase',
                          textAlign: 'center'
                        }}
                        onChange={e => {
                          setKrilAlpha(hasKril(e.target.value));
                        }}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col span={18}>
                  <Form.Item noStyle rules={[{ required: checkFieldsRequired, message: '' }]} name="passportNumber">
                    <InputMask
                      mask="99999999999999"
                      // @ts-ignore*
                      maskChar=""
                    >
                      {/* @ts-ignore */}
                      {(inputProps: any) => <Input className="customInput" {...inputProps} />}
                    </InputMask>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row className={styles.inputs_cont_date_birth} gutter={[8, 0]}>
              <Col span={16}>
                <Form.Item
                  name="dateBirth"
                  rules={[
                    // {
                    //   required: checkFieldsRequired,
                    //   message: '',
                    //   len: 10
                    // },
                    {
                      validator: (rule, value) => {
                        if (value) {
                          form.setFieldsValue({
                            dateBirth: validateDateString(value, false)
                          });
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Tugilgan_sana')}</span>}
                >
                  <InputMask
                    mask="99.99.9999"
                    // @ts-ignore*
                    maskChar=""
                  >
                    {/* @ts-ignore */}
                    {(inputProps: any) => <Input className="customInput" placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button loading={isLoading} className={styles.inputs_cont_search_button} type="primary" htmlType="submit">
                  <SearchIcon />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className={styles.inputs_cont_line} />
      </Form>
    </div>
  );
};

export default SearchClientWithPassportData;
