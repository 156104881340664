import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { eventListConstants, eventListModels } from '..';

export const useActivityLogsPipes = () =>
  useQuery<eventListModels.IActivityPipe[]>({
    queryKey: [eventListConstants.queryKeys.ACTIVITY_LOGS_PIPES],
    queryFn: async () => {
      const res = await $api.get('/v1/activity-logs/pipes');

      return res.data.data.body.data;
    }
  });
