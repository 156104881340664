import React, { FC } from 'react';

interface IProps {
  stroke?: string;
  size?: number;
  strokeWidth?: number;
}

const PlusCircleIcon: FC<IProps> = ({ stroke = 'white', size = 20, strokeWidth = 1.5 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
    />
    <path d="M6.875 10H13.125" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 6.875V13.125" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PlusCircleIcon;
