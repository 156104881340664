import React from 'react';

const CrmSuccessIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00016 14.6673C11.6668 14.6673 14.6668 11.6673 14.6668 8.00065C14.6668 4.33398 11.6668 1.33398 8.00016 1.33398C4.3335 1.33398 1.3335 4.33398 1.3335 8.00065C1.3335 11.6673 4.3335 14.6673 8.00016 14.6673Z"
        stroke="#63CBA5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.1665 7.99995L7.05317 9.88661L10.8332 6.11328" stroke="#63CBA5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CrmSuccessIcon;
