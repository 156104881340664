import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import ArrowDownCrmIcon from '../../assets/icons/ArrowDownCrmIcon';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { usePipeStatus } from '../../queries/mutation';
import { usePipeAll, useTableConfigCustom } from '../../queries/queries';
import { setSortableColumn } from '../../store/reducers/CrmSlice';
import { Permissions } from '../../utils/constants/permissions';
import { queryNames } from '../../utils/constants/queryNames';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../utils/models/GoogleAnalitks';
import { PipeAll } from '../../utils/models/PipeAll';
import { TableKeyCustom } from '../../utils/models/TableKey';

import { CrmFilterSaveData } from './components/filter/drawer/crm-filter-save/utils/models/CrmFilterSaveData';
import FilterButton from './components/filter/FilterButton';
import ActionsButton from './components/headerComponents/actionsButton/ActionsButton';
import ChangeViewTypeButtons from './components/headerComponents/changeViewTypeButtons/ChangeViewTypeButtons';
import Actions from './components/listView/actions/Actions';
import ListView from './components/listView/ListView';
import Voronka from './components/voronka/Voronka';
import VoronkaCards from './components/voronkaCards/VoronkaCards';
import VoronkaMessengerSetting from './components/voronkaMessengerSetting/VoronkaMessengerSetting';

import styles from './crm.module.scss';

const Crm = () => {
  const { data: crm_voronka } = useTableConfigCustom<string>(TableKeyCustom.crm_voronka);
  const { data: crmFilter } = useTableConfigCustom<CrmFilterSaveData>(TableKeyCustom.crm_filter);
  const { appendMultipleDifferent } = useQueryParam<
    {
      type: string;
    },
    null
  >();
  const location = useLocation();
  const navigate = useNavigate();
  const qc = useQueryClient();
  const sortLeadStatus = usePipeStatus();
  const { data } = usePipeAll();
  const dispatch = useAppDispatch();
  const { sortableColumn } = useAppSelector(state => state?.assigmentSlice);
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const activeIds = useAppSelector(state => state.assigmentSlice.listViewActiveIds);

  const [voronkaVisible, setVoronkaVisible] = useState(false);

  const { t } = useTranslation();

  const isAccessConfig = permissions?.includes(Permissions.PERMISSION_SETTINGS_CRM);
  const listView = useAppSelector(state => state.assigmentSlice.showListView);

  // delete all filter
  const deleteAllFilters = () => {
    appendMultipleDifferent(
      [
        'pipeStatusIds',
        'startDate',
        'finishDate',
        'responsibleById',
        'createdById',
        'balanceStart',
        'balanceFinish',
        'tagIds',
        'taskStartDate',
        'taskFinishDate',
        'reasonsFRIds',
        'type'
      ],
      []
    );
  };

  // go to create new lead
  const createNewLeadEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CLICK_CREATE_NEW_LEAD
    });
  };

  // go to config boards
  const configBoardsEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CLICK_SETTINGS_BOARD
    });
  };

  const handleVoronka = () => {
    setVoronkaVisible(true);
  };

  const handleSetting = () => {
    configBoardsEvent();
    dispatch(setSortableColumn(true));
    deleteAllFilters();
  };

  const handleCancleSetting = () => {
    if (queryString.parse(location.search).setting) {
      navigate(`${location.pathname}?voronka=${queryString.parse(location.search)?.voronka}`);
    }
    dispatch(setSortableColumn(false));
  };

  const handleSaveSetting = () => {
    const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);
    const sortEditStatus = sourceStatusData?.find(item => item.id === data?.find(item => item.id === Number(queryString.parse(location.search)?.voronka))?.id)?.pipeStatuses;

    sortLeadStatus.mutate([
      ...sortEditStatus?.map((item, index) => ({
        ...item,
        orders: item.orders > 1000 ? item.orders : index + 1
      }))!
    ]);
  };

  useEffect(() => {
    dispatch(setSortableColumn(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!location.search) {
      if (crmFilter?.active && crmFilter?.filters && crmFilter?.filters?.length > 0) {
        /* empty */
      } else if (data) {
        if (!queryString.parse(location.search).voronka) {
          if (crm_voronka) {
            const { search } = queryString.parse(location.search);

            if (search) navigate(`${location.pathname}?voronka=${crm_voronka}&search=${urlEncode(String(search))}`);
            else navigate(`${location.pathname}?voronka=${crm_voronka}`);
          } else {
            const { search } = queryString.parse(location.search);

            if (search) navigate(`${location.pathname}?voronka=${data[0]?.id}&search=${urlEncode(String(search))}`);
            else navigate(`${location.pathname}?voronka=${data[0]?.id}`);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [data, location, crmFilter, crm_voronka]);

  useEffect(() => {
    if (crmFilter?.active && crmFilter?.filters && crmFilter?.filters?.length > 0) {
      const activeUrl = crmFilter?.filters?.find(item => item?.id === crmFilter?.active)?.url;

      if (crmFilter?.active && location?.search !== activeUrl) {
        navigate({ pathname: location.pathname, search: activeUrl });
      }
    }
    // eslint-disable-next-line
  }, [crmFilter, location.pathname]);

  useEffect(() => {
    dispatch(setSortableColumn(false));
    // eslint-disable-next-line
  }, [sortLeadStatus.isSuccess]);

  const handleCreateLead = () => {
    if (queryString.parse(location.search).voronka) {
      createNewLeadEvent();
      navigate(`${location.pathname}/new/NewLid?pipe=${queryString.parse(location.search).voronka}`);
    }
  };

  useEffect(() => {
    if (queryString.parse(location.search).setting) {
      dispatch(setSortableColumn(true));
    }
  }, [data, location]);

  return (
    <>
      <div className={styles.crm_header}>
        <Voronka voronkaVisible={voronkaVisible} setVoronkaVisible={setVoronkaVisible} data={data} />

        <div className={styles.crm_left} style={{ marginLeft: '20px' }}>
          <div onClick={handleVoronka} title={data?.find(item => item.id === Number(queryString.parse(location.search)?.voronka))?.name}>
            {data?.find(item => item.id === Number(queryString.parse(location.search)?.voronka))?.name.length! < 15
              ? data?.find(item => item.id === Number(queryString.parse(location.search)?.voronka))?.name
              : `${data?.find(item => item.id === Number(queryString.parse(location.search)?.voronka))?.name.slice(0, 15)}...`}
            <ArrowDownCrmIcon />
          </div>
          {!sortableColumn && (
            <>
              <ChangeViewTypeButtons />
              <FilterButton />
            </>
          )}
        </div>
        <div className={styles.crm_right}>
          {!sortableColumn ? (
            <>
              <ActionsButton />
              <p>
                {/* {countBalance.count} {t('crm.ta_bitim')}{' '}
                {countBalance.balance.toLocaleString('ru')} */}
              </p>
              {isAccessConfig && (
                <Button className={styles.crm_right_columnSettingButton} onClick={handleSetting}>
                  {t('crm.Ustunlarni_sozlash')}
                </Button>
              )}
              <Button type="primary" className={styles.crm_right_createButton} onClick={handleCreateLead}>
                {t('crm.Yangi_yaratish')}
              </Button>
              <div className={styles.crm_right_notification}>
                <div className="d-flex align-center">
                  <OnlinePbxNotifications />
                  <NotificationButtons />
                </div>
              </div>
            </>
          ) : (
            <>
              <button className={styles.crm_right_settingCancleButton} onClick={handleCancleSetting}>
                {t('crm.Bekor_qilish')}
              </button>
              <button className={styles.crm_right_settingSaveButton} onClick={handleSaveSetting} disabled={sortLeadStatus.isLoading!}>
                {sortLeadStatus.isLoading && <LoadingOutlined />}
                {t('crm.Saqlash')}
              </button>
            </>
          )}
        </div>
      </div>
      {listView && !sortableColumn ? (
        <>
          <ListView />
        </>
      ) : (
        <div className={styles.crm}>
          <Actions />
          <div
            className={styles.crm_container}
            style={{
              height: activeIds?.length > 0 ? 'calc(100% - 75px)' : '100%',
              marginTop: activeIds?.length > 0 ? '72px' : '0px'
            }}
          >
            {sortableColumn && <VoronkaMessengerSetting />}
            <VoronkaCards data={data?.find(item => item.id === Number(queryString.parse(location.search)?.voronka))} />
          </div>
        </div>
      )}
    </>
  );
};

export default Crm;
