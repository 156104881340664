import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Popover } from 'antd';
import { Spin } from 'antd/es';
import { useTranslation } from 'react-i18next';

import { useAction } from 'modules/contract';

import CheckIcon from '../../../../../assets/icons/CheckIcon';
import EllipseIcon from '../../../../../assets/icons/EllipseIcon';
import MiniMenuIcon from '../../../../../assets/icons/MiniMenuIcon';
import SlashIcon from '../../../../../assets/icons/SlashIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useTableColor } from '../../../../../queries/mutation';
import { useContractEmployee } from '../../../../../queries/queries';
import { MainPaths } from '../../../../../routing/main/mainPaths';
import { BASE_URL } from '../../../../../service/RequestService';
import { tableStatusColor } from '../../../../../utils/constants/TableStatusColor';
import { ContractList } from '../../../../../utils/models/ContractList';
import { ContractStatus } from '../../../../../utils/models/ContractStatus';
import { SearchParamsContract } from '../../../Contract';
import { useChangeEmployeeContract } from '../../../services/mutation';

import styles from './action.module.scss';

interface Props {
  record: ContractList;
  handleDownloadLog: (id: number, number: string, open: boolean) => void;
  setCustomerChange: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAddCard: React.Dispatch<React.SetStateAction<{ isOpen: boolean; id: number; name: string }>>;
  setClientId: React.Dispatch<React.SetStateAction<number>>;
  setContractId: React.Dispatch<React.SetStateAction<number>>;
}

const Actions: React.FC<Props> = ({ record, handleDownloadLog, setCustomerChange, setOpenAddCard, setClientId, setContractId }) => {
  const { setExterminate, permissions } = useAction();
  const { location, push } = useQueryParam<SearchParamsContract, any>();

  const { data, isLoading } = useContractEmployee();

  const [activeId, setActiveId] = useState(0);
  const [activeType, setActiveType] = useState('');
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openEmployeeDropDown, setOpenEmployeeDropDown] = useState(false);

  const tableColor = useTableColor();

  // const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  // handle dropdown
  const handleDropdown = (open: boolean) => {
    setOpenDropDown(open);
  };

  // handle employee dropdown
  const handleEmployeeDropdown = (open: boolean) => {
    setOpenEmployeeDropDown(open);
  };

  // handle success
  function handleSuccess() {
    handleEmployeeDropdown(false);
    handleDropdown(false);
  }

  const changeEmployee = useChangeEmployeeContract(handleSuccess);

  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('click', () => {
      const arrearegeColorAll = document.querySelectorAll('.arrearegeColor');

      arrearegeColorAll.forEach(item => {
        item.setAttribute('class', 'arrearegeColor');
      });
    });
  }, []);

  useEffect(() => {
    const colorType = document.querySelectorAll('.colorType');
    const loadingColor = document.querySelectorAll('.loadingColor');

    if (tableColor.isLoading) {
      loadingColor.forEach(e => {
        if (Number(e.id) === activeId) {
          e.setAttribute('class', 'anticon anticon-loading anticon-spin loadingColor active');
        }
      });
    } else {
      loadingColor.forEach(e => {
        e.setAttribute('class', 'anticon anticon-loading anticon-spin loadingColor');
      });
    }

    if (tableColor.isSuccess) {
      colorType.forEach(e => {
        if (Number(e.id) === activeId) {
          e.querySelector('circle')?.setAttribute('fill', tableStatusColor[activeType].color ? tableStatusColor[activeType].color : 'transparent');
          e.querySelector('circle')?.setAttribute('stroke', tableStatusColor[activeType].color ? tableStatusColor[activeType].color : '#87898E');
        }
      });
    }

    // eslint-disable-next-line
  }, [tableColor.isLoading, tableColor.isSuccess]);

  const isAccessUpdateMonthlyPayment = permissions?.PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT;

  const isAccessChangeEmployee = permissions?.PERMISSION_CHANGE_CONTRACT_RESPONSIBLE;

  // const isAccessOneTask = permissions?.includes(Permissions.PERMISSION_PAGE_CRM)

  const isAccessContractClientChange = permissions?.PERMISSION_TRANSFER_CONTRACT;

  const isAccessAddCard = (status: ContractStatus) => {
    if (status === ContractStatus.ACTIVE) return true;
    return status === ContractStatus.STARTED;
  };

  // handleOpenAddCard
  const handleOpenAddCard = (e: React.MouseEvent<HTMLSpanElement>, id: number, name: string) => {
    e.stopPropagation();
    setOpenAddCard({
      isOpen: true,
      id,
      name
    });
  };

  // updateTable
  const updateTable = (e: React.MouseEvent<HTMLSpanElement>, id: number) => {
    e?.stopPropagation();
    push(`${location.pathname}/${id}${MainPaths.UPDATE_TABLE}`);
  };

  // handleColor
  const handleColor = (e: any, id: number, type: string) => {
    const arrearegeColorAll = document.querySelectorAll('.arrearegeColor');

    setActiveId(id);
    setActiveType(type);
    const loadingColor = e.target.parentElement.parentElement.parentElement.parentElement.querySelector('.loadingColor');

    loadingColor.querySelector('svg').setAttribute('fill', tableStatusColor[type].color);
    arrearegeColorAll.forEach(item => {
      item.setAttribute('class', 'arrearegeColor');
    });
    e.stopPropagation();
    tableColor.mutate({
      color: type,
      contractId: id,
      tableType: 'TABLE_CONTRACT'
    });
  };

  // handleTableColor
  const handleTableColor = (e: any) => {
    e.stopPropagation();
    const arrearegeColorAll = document.querySelectorAll('.arrearegeColor');

    arrearegeColorAll.forEach(item => {
      item.setAttribute('class', 'arrearegeColor');
    });

    const arrearegeColor = e.target.parentElement.querySelector('.arrearegeColor');

    arrearegeColor.setAttribute('class', 'arrearegeColor active');
  };

  // handle change employee
  const handleChangeEmployee = (id: number) => {
    changeEmployee.mutate({
      contractId: record.id,
      employeeId: id
    });
  };

  return (
    <div className="d-flex align-center arrearegeColorContainer">
      {/* {isAccessOneTask && (
       <span
         onClick={(e) => goToTaskPage(e, record)}
         className={'messageThreeIconContractPage'}
       >
         <MessageThreeIcon />
       </span>
      )} */}
      <div className="arrearegeColor" key={record.id} onClick={e => e.stopPropagation()}>
        {Object.values(tableStatusColor).map(item =>
          item.type === 'NOTHING' ? (
            <span key={item.color} onClick={e => handleColor(e, record.id, item.type)}>
              <SlashIcon />
            </span>
          ) : (
            <span key={item.color} onClick={e => handleColor(e, record.id, item.type)}>
              <EllipseIcon background={item.color} stroke={item.color} />
            </span>
          )
        )}
      </div>
      <span style={{ cursor: 'pointer', marginRight: 10 }} onClick={e => handleTableColor(e)} className="colorType" id={String(record.id)}>
        {record.color ? (
          <EllipseIcon background={tableStatusColor[record.color].color} stroke={tableStatusColor[record.color].color} style={{ pointerEvents: 'none' }} />
        ) : (
          <EllipseIcon background="transparent" style={{ pointerEvents: 'none' }} />
        )}
      </span>
      <LoadingOutlined className="loadingColor" id={String(record.id)} />
      <Dropdown
        overlayClassName="contract_dropdown"
        overlay={
          <div className="d-flex flex-direction-column">
            <p>{t('home.Shartnoma')} </p>
            {isAccessUpdateMonthlyPayment && <span onClick={e => updateTable(e, record.id)}>{t('contract.Jadvalni_yangilash')}</span>}
            {isAccessContractClientChange && (
              <span
                onClick={(e: any) => {
                  setCustomerChange(true);
                  setContractId(record.id);
                  setClientId(record.clientId);
                  e.stopPropagation();
                  handleDropdown(false);
                }}
              >
                {t('contract.Mijozni_ozgartirish')}
              </span>
            )}
            {isAccessAddCard(record.status) && (
              <span
                onClick={e => {
                  handleOpenAddCard(e, record.id, record.clientName);
                  handleDropdown(false);
                }}
              >
                {t('contract.Karta_qoshish')}
              </span>
            )}
            <span
              onClick={e => {
                e.stopPropagation();
                handleDownloadLog(record.id, record.number, true);
                handleDropdown(false);
              }}
            >
              {t('contract.Amallar_tarixini_yuklab_olish')}
            </span>
            {isAccessChangeEmployee && (
              <Popover
                overlayClassName={styles.container_employee_dropdown}
                content={
                  <Spin spinning={changeEmployee.isLoading || isLoading}>
                    {data?.map((emp, index) => (
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          handleChangeEmployee(emp.id);
                        }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={styles.container_employee_dropdown_item}
                      >
                        <Avatar className={styles.container_employee_dropdown_item_avatar} src={`${BASE_URL}/${emp.avatar}`} />
                        <span className={styles.container_employee_dropdown_item_name}>{emp.fullName}</span>
                        {record.responsibleById === emp.id && <CheckIcon />}
                      </div>
                    ))}
                  </Spin>
                }
                onVisibleChange={handleEmployeeDropdown}
                visible={openEmployeeDropDown}
                placement="leftTop"
                trigger={['click']}
              >
                <span
                  onClick={e => {
                    e.stopPropagation();
                    handleEmployeeDropdown(true);
                  }}
                >
                  {t('contract.Masul_xodimni_ozgartirish')}
                </span>
              </Popover>
            )}
            {permissions.PERMISSION_EXTERMINATE_CONTRACT ? (
              <span
                className="flex gap-2 !text-red-600"
                onClick={e => {
                  e.stopPropagation();
                  handleDropdown(false);
                  setExterminate({ id: record?.id });
                }}
              >
                {t('profile.Ochirish')}
              </span>
            ) : (
              <></>
            )}
          </div>
        }
        onVisibleChange={handleDropdown}
        visible={openDropDown}
        trigger={['click']}
      >
        <Button
          shape="circle"
          className="d-flex align-center justify-center"
          style={{ border: 'none', marginLeft: 10 }}
          onClick={e => {
            e.stopPropagation();
            handleDropdown(true);
          }}
        >
          <MiniMenuIcon />
        </Button>
      </Dropdown>
    </div>
  );
};

export default Actions;
