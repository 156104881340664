import React, { useState, FC, useRef, useEffect } from 'react';
import styles from './crmFilterSave.module.scss';
import { Button, Input, InputRef } from 'antd';
import { useTableConfigCustomMutation } from '../../../../../../queries/mutation';
import { TableKeyCustom } from '../../../../../../utils/models/TableKey';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { CrmFilterSaveData } from './utils/models/CrmFilterSaveData';
import { createCrmFilter } from './utils/helper/createCrmFilter';

export interface IProps {
  data: CrmFilterSaveData | undefined;
}

const CrmFilterSaveCreate: FC<IProps> = ({ data }) => {
  const inputRef = useRef<InputRef | null>(null);
  const tableConfig = useTableConfigCustomMutation(TableKeyCustom.crm_filter);
  const { location } = useQueryParam();
  const [value, setValue] = useState('');

  const handleSave = () => {
    const dataConvert = createCrmFilter(data, value, location?.search);
    tableConfig
      .mutateAsync({
        key: TableKeyCustom.crm_filter,
        value: JSON.stringify(dataConvert)
      })
      .then(() => {
        setValue('');
      });
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef, data]);
  return (
    <div className={styles.create}>
      <Input
        ref={inputRef}
        placeholder="Nom kiriting"
        className="customInput"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => {
          if (e?.key === 'Enter') {
            handleSave();
          }
        }}
      />
      <Button type="primary" className={styles.createBtn} onClick={handleSave} loading={tableConfig.isLoading} disabled={!value.trim()}>
        Saqlash
      </Button>
    </div>
  );
};

export default CrmFilterSaveCreate;
