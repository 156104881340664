import { useAppSelector } from '@hooks/reduxHooks';
import { showMessage } from '@queries/showMessage';
import i18n from '@service/I18nService';
import { $api } from '@service/RequestService';
import { downloadFile } from '@utils/helper/downloadFile';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';

export function useShouroomVisualXAuthToken() {
  return useMutation(async () => {
    const res = await $api.get(endPoints.SMART_CATALOG_X_AUTH_TOKEN);

    return res.data.data;
  });
}

export function useShowroomTableExcel() {
  const { filter } = useAppSelector(state => state.showroomSlice);

  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.SMART_CATALOG_TABLE_EXCEL  }?lang=${i18n.language?.toUpperCase()}`, filter, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xonadonlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useShowroomTableSummaryExcel() {
  const { filter } = useAppSelector(state => state.showroomSlice);

  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.SMART_CATALOG_TABLE_SUMMARY_EXCEL  }?lang=${i18n.language?.toUpperCase()}`, filter, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xonadonlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useShowroomTableSummaryExcelBeta() {
  const { filter } = useAppSelector(state => state.showroomSlice);

  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.SMART_CATALOG_TABLE_SUMMARY_EXCEL_BETA  }?lang=${i18n.language?.toUpperCase()}`, filter, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xonadonlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}
