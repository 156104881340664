import React, { useState } from 'react';
import PlusIcon from '@icons/PlusIcon';
import { SipModel } from '@pages/roboto-calls/utils/models/SipModel';
import { useTranslation } from 'react-i18next';

import CreateSip from '../create-skip/CreateSip';
import RobotoSip from '../roboto-sip/RobotoSip';

const SystemCalls = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState<SipModel | undefined>(undefined);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="rounded-md bg-white px-8 py-5">
        <p className="text-2xl font-semibold text-[#464255]">Robot call</p>
      </div>
      <RobotoSip setVisible={setVisible} setModalData={setModalData} />
      <div className="flex h-[92px] cursor-pointer items-center justify-center rounded-xl border-[1px] border-dashed border-[#D0D5DD] bg-white" onClick={() => setVisible(true)}>
        <PlusIcon />
        <span className="text-xl font-medium text-[#00A389]">{t('home.Sip_kanali')}</span>
      </div>

      <CreateSip visible={visible} setVisible={setVisible} modalData={modalData} setModalData={setModalData} />
    </div>
  );
};

export default SystemCalls;
