import React, { FC } from 'react';

interface StatusCardIconProps {
  borderColor?: string;
  fillColor?: string;
}

const StatusCardIcon: FC<StatusCardIconProps> = ({ borderColor, fillColor }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill={fillColor} stroke={borderColor} />
      <path d="M17 1H9L17 9V1Z" fill={borderColor} />
    </svg>
  );
};

export default StatusCardIcon;
