import React from 'react';

const JumpPrevIcon: React.FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="30.0002" width="30" height="30" rx="15" transform="rotate(90 30.0002 0)" fill="#00A389" fill-opacity="0.1" />
      <path
        d="M11.7644 14.9948C11.7654 14.8793 11.8113 14.7687 11.8923 14.6863L16.2559 10.3227C16.3382 10.2432 16.4484 10.1992 16.5628 10.2002C16.6772 10.2012 16.7867 10.2471 16.8676 10.328C16.9485 10.4089 16.9944 10.5184 16.9954 10.6328C16.9964 10.7472 16.9524 10.8574 16.8729 10.9397L12.8178 14.9948L16.8729 19.05C16.9146 19.0902 16.9478 19.1384 16.9707 19.1916C16.9936 19.2448 17.0056 19.3021 17.0061 19.36C17.0066 19.418 16.9956 19.4754 16.9736 19.5291C16.9517 19.5827 16.9193 19.6314 16.8783 19.6724C16.8373 19.7134 16.7886 19.7458 16.735 19.7677C16.6814 19.7896 16.6239 19.8007 16.566 19.8002C16.508 19.7997 16.4508 19.7876 16.3975 19.7648C16.3443 19.7419 16.2961 19.7087 16.2559 19.667L11.8923 15.3033C11.8112 15.221 11.7654 15.1104 11.7644 14.9948Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default JumpPrevIcon;
