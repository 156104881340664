import { Button, Image, Modal, Spin } from 'antd';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClientId, useGetImageByImageId } from '../../../queries/queries';
import { ClientType } from '../../../utils/models/ClientType';
import TickSquareIcon from '../../../assets/icons/TickSquareIcon';
import ImagePassport from '../../../assets/icons/ImagePassport';
import styles from '../Client.module.scss';

interface ClientModalProps {
  visible: boolean;
  close: () => void;
  clientId: number;
}

const ClientModal: FC<ClientModalProps> = ({ visible, close, clientId }) => {
  const [imgVisible, setImgVisible] = useState(false);
  const [imgVisible2, setImgVisible2] = useState(false);
  const { data } = useClientId(clientId);
  const { data: uploadFile, isLoading: fileIsLoading } = useGetImageByImageId(data?.passportImg1Id as number);
  const { data: uploadFile2, isLoading: fileIsLoading2 } = useGetImageByImageId(data?.passportImg2Id as number);
  const { t } = useTranslation();

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={t('client.Mijoz_ma’lumotlari')}
      wrapClassName="clientInformationModal"
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      width={1345}
    >
      <div className="clientInformationModalContent">
        {data?.type === ClientType.NATURAL ? (
          <>
            <h1>{t(`client.${data?.type}`)}</h1>
            <div>
              <div>
                <div>
                  <h1>{t('client.Mijoz')}</h1>
                  <p>
                    {data?.lastname} {data?.firstname} {data?.fatherName}
                  </p>
                </div>
                <div>
                  <h1>{t('client.Tugilgan_kuni')}</h1>
                  <p>{data?.dateBirth}</p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    maxHeight: 70,
                    marginRight: 70,
                    maxWidth: '150px',
                    overflowY: 'auto'
                  }}
                >
                  <h1
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: '#ffffff'
                    }}
                  >
                    {t('client.Telefon_raqami')}
                  </h1>
                  {data?.phones.map(i => <p key={i.id}>{i.phone}</p>)}
                </div>
                <div>
                  <h1>{t('client.Pasport_seriyasi_raqami')}</h1>
                  <p>
                    {data?.passportSerial} {data?.passportNumber}
                  </p>
                </div>
                <div>
                  <h1>{t('client.Yashash_manzili')}</h1>
                  <p>{data?.address}</p>
                </div>
              </div>
              <div>
                <div>
                  <h1>{t('client.Berilgan_vaqti')}</h1>
                  <p>{data?.passportDate}</p>
                </div>
                <div>
                  <h1>{t('client.Berilgan_joyi')}</h1>
                  <p>{data?.passportPlace}</p>
                </div>

                <div>
                  <h1>{t('client.Ish_joyi')}</h1>
                  <p>{data?.workPlace}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1>{t(`client.${data?.type}`)}</h1>
            <div style={{ gridTemplateColumns: 'auto auto auto auto' }}>
              <div>
                <div>
                  <h1>{t('client.Korxona_nomi')}</h1>
                  <p>{data?.name}</p>
                </div>
                <div>
                  <h1>{t('client.Hisob_raqami')}</h1>
                  <p>{data?.accountNumber}</p>
                </div>
              </div>
              <div style={{ gridTemplateColumns: 'auto' }}>
                <div>
                  <h1>{t('client.Direktor')}</h1>
                  <p>{data?.director}</p>
                </div>

                <div
                  style={{
                    width: '180px',
                    maxHeight: '70px',
                    overflowY: 'auto'
                  }}
                >
                  <h1
                    style={{
                      background: '#ffffff',
                      position: 'sticky',
                      top: 0
                    }}
                  >
                    {t('client.Telefon_raqami')}
                  </h1>
                  {data?.phones.map(i => (
                    <p>
                      {i.phone} {i.active && <TickSquareIcon />}
                    </p>
                  ))}
                </div>
              </div>
              <div style={{ gridTemplateColumns: 'auto auto' }}>
                <div style={{ gridColumn: '1 / span 2' }}>
                  <h1>{t('client.Manzil')}</h1>
                  <p>{data?.address}</p>
                </div>
                <div style={{ marginRight: '50px' }}>
                  <h1>{t('client.INN')}</h1>
                  <p>{data?.inn}</p>
                </div>
                <div>
                  <h1>{t('client.OKED')}</h1>
                  <p>{data?.oked}</p>
                </div>
              </div>
              <div style={{ gridTemplateColumns: 'auto' }}>
                <div>
                  <h1>{t('client.Bank_nomi')}</h1>
                  <p>{data?.bankName}</p>
                </div>
                <div>
                  <h1>{t('client.MFO')}</h1>
                  <p>{data?.mfo}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="clientInformationModalFooter">
        <div>
          <Spin spinning={fileIsLoading && fileIsLoading2}>
            {!!data?.passportImg1Id && (
              <div>
                <ImagePassport />
                {uploadFile?.fileObject.name}
                <span onClick={() => setImgVisible(true)}>{t('client.Korish')}</span>
              </div>
            )}
            {!!data?.passportImg2Id && (
              <div>
                <ImagePassport />
                {uploadFile2?.fileObject.name}
                <span onClick={() => setImgVisible2(true)}>{t('client.Korish')}</span>
              </div>
            )}
          </Spin>
        </div>

        <Button onClick={handleCancel}>{t('arrearage.Yopish')}</Button>
      </div>
      <Image
        width={200}
        style={{ display: 'none' }}
        preview={{
          visible: imgVisible,
          src: uploadFile?.fileBlob ? URL.createObjectURL(uploadFile?.fileBlob) : '',
          onVisibleChange: value => {
            setImgVisible(value);
          }
        }}
      />

      <Image
        width={200}
        style={{ display: 'none' }}
        preview={{
          visible: imgVisible2,
          src: uploadFile2?.fileBlob ? URL.createObjectURL(uploadFile2?.fileBlob) : '',
          onVisibleChange: value => {
            setImgVisible2(value);
          }
        }}
      />
    </Modal>
  );
};

export default ClientModal;
