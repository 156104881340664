import React from 'react';
import { Button, Modal } from 'antd';
import { useLeadTaskClosed, useLeadTaskDelete } from '@pages/oneLid/services/mutation';
import { TaskFilter } from '@utils/models/TaskFilter';
import { Interface } from './DndDeleteCompleteTask';
import TrashIcon from '@icons/TrashIcon';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useCreateLeadTask } from '@queries/mutation';
import { useEmployeeTask } from '@queries/queries';
import { useAppSelector } from '@hooks/reduxHooks';

import styles from './dndTaskContainer.module.scss';

type Props = {
  draggingId: number | undefined;
  currentData: TaskFilter | undefined;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalData: Interface;
};

const retrieveMondayDate = (date = new Date()) => {
  const clonedDate = new Date(date.getTime());

  return clonedDate.setDate(clonedDate.getDate() + ((1 + 7 - clonedDate.getDay()) % 7 || 7));
};

// Use current date without passing in
let nextMonday = new Date(retrieveMondayDate());
const nextMondayFormat = nextMonday.toLocaleString('ru').replace(',', '').substring(0, 10);

const nextMondayStart = `${nextMondayFormat} 00:00`;
const nextMondayFinish = `${nextMondayFormat} 23:59`;
export const checkMondayDate = `${nextMondayFormat} 00:00`;

const TaskCompleteModal: React.FC<Props> = ({ draggingId, currentData, openModal, setOpenModal, modalData }) => {
  const { nextMonth } = useAppSelector(state => state.crmStatistic);
  const { data } = useEmployeeTask();
  const { params } = useQueryParam<SearchParamsTask, null>();
  const leadSave = useCreateLeadTask(Number(params.id));

  const nextMonthStart = `${nextMonth} 00:00`;
  const nextMonthFinish = `${nextMonth} 23:59`;

  const deleteTask = useLeadTaskDelete(draggingId!);
  const closedTask = useLeadTaskClosed(draggingId!);
  //handleClose
  const handleClose = () => {
    setOpenModal(false);
  };

  const assignedById = data?.find(item => item?.fullName === currentData?.assignedBy)?.id;

  //handleCloseTask
  const handleCloseTask = () => {
    modalData?.name === 'done' &&
      closedTask
        .mutateAsync({
          closedText: '',
          deadlineFinish: currentData?.deadlineFinish!,
          deadlineStart: currentData?.deadlineStart!,
          id: draggingId!,
          leadTaskTypeId: currentData?.leadTaskType?.id!,
          reCreate: false
        })
        .then(handleClose);

    modalData?.name === 'delete' && deleteTask.mutateAsync(draggingId!).then(handleClose);
    modalData?.name === 'lastMonday' && currentData?.leadId
      ? leadSave
          .mutateAsync({
            assignedById: assignedById!,
            deadlineStart: nextMondayStart,
            deadlineFinish: nextMondayFinish,
            leadId: currentData?.leadId,
            leadTaskTypeId: currentData?.leadTaskType?.id!,
            text: currentData?.text!,
            id: currentData?.id,
            contractId: currentData?.contractId
          })
          .then(handleClose)
      : leadSave
          .mutateAsync({
            assignedById: assignedById!,
            deadlineStart: nextMondayStart,
            deadlineFinish: nextMondayFinish,
            clientId: Number(currentData?.clientId),
            leadTaskTypeId: currentData?.leadTaskType?.id!,
            text: currentData?.text!,
            id: currentData?.id,
            contractId: currentData?.contractId
          })
          .then(handleClose);
    modalData?.name === 'lastMonth' &&
      leadSave
        .mutateAsync({
          assignedById: assignedById!,
          deadlineStart: nextMonthStart,
          deadlineFinish: nextMonthFinish,
          leadId: currentData?.leadId,
          leadTaskTypeId: currentData?.leadTaskType?.id!,
          text: currentData?.text!,
          id: currentData?.id,
          contractId: currentData?.contractId
        })
        .then(handleClose);
  };
  return (
    <Modal visible={openModal} onCancel={handleClose} footer={false} width={400} className={styles.completeModal}>
      <div className={styles.completeBody}>
        {modalData?.name === 'delete' && (
          <div className={styles.deleteBody}>
            <TrashIcon />
            <span>O'chirilsinmi?</span>
          </div>
        )}
        {modalData?.name === 'done' && <span>Topshiriqni yakunlamoqchimisiz?</span>}
        {(modalData?.name === 'lastMonday' || modalData?.name === 'lastMonth') && (
          <span>Topshiriq vaqtini {modalData?.text} ga o'zgartirmoqchimisiz?</span>
        )}
      </div>
      <div className={styles.completeFooter}>
        <Button onClick={handleClose}>Yopish</Button>
        <Button
          loading={closedTask?.isLoading || leadSave?.isLoading || deleteTask?.isLoading}
          onClick={handleCloseTask}
          style={{
            backgroundColor: modalData?.name === 'delete' ? 'red' : 'green'
          }}
        >
          {modalData?.name === 'delete' ? "O'chirish" : 'Saqlash'}
        </Button>
      </div>
    </Modal>
  );
};

export default TaskCompleteModal;
