import React from 'react';

const CommentIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 7C2.5 5.59987 2.5 4.8998 2.77248 4.36502C3.01217 3.89462 3.39462 3.51217 3.86502 3.27248C4.3998 3 5.09987 3 6.5 3H13.5C14.9001 3 15.6002 3 16.135 3.27248C16.6054 3.51217 16.9878 3.89462 17.2275 4.36502C17.5 4.8998 17.5 5.59987 17.5 7V11.5C17.5 12.9001 17.5 13.6002 17.2275 14.135C16.9878 14.6054 16.6054 14.9878 16.135 15.2275C15.6002 15.5 14.9001 15.5 13.5 15.5H11.4031C10.8831 15.5 10.6231 15.5 10.3743 15.551C10.1537 15.5963 9.94017 15.6712 9.73957 15.7737C9.51347 15.8892 9.31043 16.0517 8.90434 16.3765L6.91646 17.9668C6.56973 18.2442 6.39636 18.3829 6.25045 18.3831C6.12356 18.3832 6.00352 18.3255 5.92436 18.2263C5.83333 18.1123 5.83333 17.8903 5.83333 17.4463V15.5C5.05836 15.5 4.67087 15.5 4.35295 15.4148C3.49022 15.1836 2.81635 14.5098 2.58519 13.647C2.5 13.3291 2.5 12.9416 2.5 12.1667V7Z"
        stroke="#464255"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CommentIcon;
