import React, { cloneElement, useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  defaultComment?: string;
  children: JSX.Element;
  onSave?: (text: string, close: () => void) => void;
}

const CommentModal = ({ children, defaultComment, onSave }: IProps) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (defaultComment) {
      setText(defaultComment);
    }
  }, [defaultComment, visible]);

  const handleSave = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onSave && onSave(text, () => setVisible(false));
  };

  return (
    <>
      {cloneElement(children, { onClick: () => setVisible(true) })}
      <Modal title={t('home.Izoh')} visible={visible} onCancel={() => setVisible(false)} footer={false}>
        <div className="flex flex-col gap-[16px]">
          <Input.TextArea className="customInput" placeholder="Matin kiriting" autoSize={{ minRows: 6, maxRows: 6 }} value={text} onChange={e => setText(e.currentTarget.value)} />
          <div className="flex justify-end gap-[8px]">
            <Button className="h-[38px] w-[160px] rounded-[50px]" onClick={() => setVisible(false)}>
              Yopish
            </Button>
            <Button type="primary" className="h-[38px] w-[160px] rounded-[50px]" onClick={handleSave}>
              Saqlash
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CommentModal;
