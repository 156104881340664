import React, { useEffect, useState } from 'react';
import styles from './../leadWithoutTaskConfig.module.scss';
import CheckmarkIcon from '../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { Button, Dropdown, Form, Input } from 'antd';
import { helper } from '../../../../../../utils/helper/helper';
import { useTranslation } from 'react-i18next';
import TimerIcon from '../../../../../../assets/icons/TimerIcon';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { setAutoRemindTime } from '../../../../../../store/reducers/CrmFieldSlice';

interface IFrom {
  second: number;
  minute: number;
}

interface Props {
  time: number | undefined;
}

const Time: React.FC<Props> = ({ time }) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const dispatch = useAppDispatch();

  const [form] = Form.useForm<IFrom>();

  const { t } = useTranslation();

  useEffect(() => {
    if (time) {
      const minute = Math.floor(time / 60);
      const second = time % 60;
      form.setFieldsValue({
        minute,
        second
      });
    } else {
      form.resetFields();
    }
  }, [time, form]);

  // on finish
  const onFinish = (val: IFrom) => {
    dispatch(setAutoRemindTime((Number(val.minute) || 0) * 60 + Number(val.second) || 0));
    handleOpenDropdown(false);
  };

  // show title
  const showTitle = () => {
    if (time === undefined) {
      return t('one_lid.Vaqt_tanlash');
    }
    if (time === 300) {
      return t('one_lid.5_minut');
    }
    if (time === 600) {
      return t('one_lid.10_minut');
    }
    if (time === 1800) {
      return t('one_lid.30_minut');
    }
    if (time === 3600) {
      return t('one_lid.60_minut');
    }

    return t('one_lid.Maxsus');
  };

  // handle open dropdown
  const handleOpenDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  // handle change time
  const handleChangeTime = (val: number) => {
    dispatch(setAutoRemindTime(val));
    handleOpenDropdown(false);
  };

  return (
    <Dropdown
      visible={openDropdown}
      onVisibleChange={handleOpenDropdown}
      placement={'bottom'}
      overlayClassName={styles.container_body_item_dropdown}
      overlay={
        <>
          <div onClick={() => handleChangeTime(300)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('one_lid.5_minut')}</span>
            {time === 300 && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeTime(600)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('one_lid.10_minut')}</span>
            {time === 600 && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeTime(1800)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('one_lid.30_minut')}</span>
            {time === 1800 && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <div onClick={() => handleChangeTime(3600)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('one_lid.60_minut')}</span>
            {time === 3600 && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          <Form
            form={form}
            name="dateFinish"
            initialValues={{ remember: true }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <div className={styles.container_body_type_dropdown_date_finish}>
              <span className={styles.container_body_type_dropdown_date_finish_title}>{t('triggers.Maxsus')}:</span>
              <div className={'d-flex align-center'}>
                <Form.Item
                  name={'minute'}
                  rules={[
                    {
                      required: true,
                      message: ''
                    },
                    {
                      validator: (rule, value) => {
                        if (typeof value === 'number') return Promise.resolve();
                        const minute = Number(helper.deleteNotNumbers(value, 0));
                        form.setFieldsValue({
                          minute: minute
                        });
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input placeholder={t('one_lid.minut')} className={styles.container_body_type_dropdown_date_finish_input} />
                </Form.Item>
                <Form.Item
                  name={'second'}
                  rules={[
                    {
                      required: true,
                      message: ''
                    },
                    {
                      validator: (rule, value) => {
                        if (typeof value === 'number') return Promise.resolve();
                        const second = Number(helper.deleteNotNumbers(value, 0));
                        form.setFieldsValue({
                          second: second
                        });
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input placeholder={t('one_lid.sekund')} className={styles.container_body_type_dropdown_date_finish_input} />
                </Form.Item>
                <Button htmlType={'submit'} className={styles.container_body_type_dropdown_date_finish_button}>
                  {t('triggers.OK')}
                </Button>
              </div>
            </div>
          </Form>
        </>
      }
      trigger={['click']}
    >
      <div onClick={() => handleOpenDropdown(true)} className={styles.container_body_item}>
        <div className={'d-flex align-center'}>
          <TimerIcon className={styles.container_body_item_date_icon} />
          <div className={styles.container_body_item_title}>{showTitle()}</div>
        </div>
        <ArrowDownIcon />
      </div>
    </Dropdown>
  );
};

export default Time;
