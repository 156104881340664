import React, { useRef, useState } from 'react';
import { Dropdown, Input } from 'antd';

import styles from '../triggers.module.scss';
import SubtractIcon from '../../../../../../../assets/icons/SubtractIcon';
import TrushSquareIcon from '../../../../../../../assets/icons/TrushSquareIcon';
import { CrmField } from '../../../../../../../utils/models/CrmField';
import { removeFieldValueSelect, setFieldCondition, setFieldValueSelect } from '../../../../../../../store/reducers/TriggerSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { FieldsCondition } from '../../../../../../../utils/models/Trigger';
import { useTranslation } from 'react-i18next';

interface Props {
  order: number;
  field: CrmField;
  fieldValue: FieldsCondition;
}

const MultiSelectField: React.FC<Props> = ({ order, field, fieldValue }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const errorList = useAppSelector(state => state.triggerSlice.errorList);

  const isFocused = useRef(false);

  const dispatch = useAppDispatch();

  // handle change visible dropdown
  const handleChangeVisible = (visible: boolean) => {
    if (isFocused.current && !visible) {
      return;
    }
    setShowDropdown(visible);
  };

  // handle search
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // handle focus
  const handleFocus = () => {
    handleChangeVisible(true);
    isFocused.current = true;
  };

  // handleBlur
  const handleBlur = () => {
    isFocused.current = false;
  };

  // filter tags
  const filterItems = () => {
    if (search && field.options) {
      return field.options?.filter(
        item => item.name.toLowerCase().includes(search.toLowerCase()) && !fieldValue.values?.find(itm => item.name === itm.value)
      );
    } else if (field.options) {
      return field.options?.filter(item => !fieldValue.values?.find(itm => item.name === itm.value));
    }
    return [];
  };

  // handle show field
  const handleDeleteField = () => {
    dispatch(
      setFieldCondition({
        add: false,
        fieldId: field.id as number,
        type: field.type
      })
    );
  };

  // handle add value
  const handleAddValue = (value: string) => {
    dispatch(
      setFieldValueSelect({
        value: value,
        id: field.id as number
      })
    );
  };

  // handle remove value
  const handleRemoveValue = (value: string) => {
    dispatch(
      removeFieldValueSelect({
        value: value,
        id: field.id as number
      })
    );
  };

  // show error
  const showError = () => {
    let errorValue = false;
    errorList.forEach(error => {
      if (error.fieldId === field.id) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  return (
    <div className={styles.container_header_tag_container}>
      <Dropdown
        visible={showDropdown}
        onVisibleChange={handleChangeVisible}
        placement={'bottom'}
        overlayClassName={styles.container_header_dropdown}
        overlay={
          <>
            {filterItems().map((item, index) => (
              <div onClick={() => handleAddValue(item.name)} key={index} className={styles.container_body_item_dropdown_item}>
                <span className={styles.container_body_item_dropdown_item_name}>{item.name}</span>
              </div>
            ))}
          </>
        }
        trigger={['click']}
      >
        <div
          onClick={e => {
            handleChangeVisible(true);
            e.stopPropagation();
          }}
          className={styles.container_header_teg + (showError() ? ' ' + styles.container_header_teg_error : '')}
        >
          <span className={styles.container_header_teg_title}>
            {order !== 0 && t('triggers.va')} {field.name}:
          </span>
          {fieldValue.values.map((value, index) => (
            <span key={index} className={styles.container_header_teg_one_teg}>
              <span className={styles.container_header_teg_one_teg_name}>{value.value}</span>
              <SubtractIcon onClick={() => handleRemoveValue(value.value)} className={styles.container_header_teg_one_teg_delete} />
            </span>
          ))}
          <Input onFocus={handleFocus} onBlur={handleBlur} onChange={e => handleSearch(e.target.value)} bordered={false} />
        </div>
      </Dropdown>
      <TrushSquareIcon onClick={handleDeleteField} className={styles.container_header_tag_container_delete} />
    </div>
  );
};

export default MultiSelectField;
