import React, { useState } from 'react';
import { ContextDataState, ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import dayjs from 'dayjs';

import FirstRow from './components/first-row/FirstRow';
import SecondRow from './components/second-row/SecondRow';

import styles from './conversion.module.scss';

const Conversion: React.FC = () => {
  const [conversionContext, setConversionContext] = useState<ContextDataState>({
    startDate: dayjs(new Date().setDate(new Date().getDate() - 31)).format('DD.MM.YYYY'),
    endDate: dayjs(new Date()).format('DD.MM.YYYY'),
    tjmIds: [],
    buildingIds: [],
    page: 1,
    secondTablePage: 1,
    isTableDataLoading: false
  });

  const setContext = (value: ContextDataState) => {
    setConversionContext(prev => ({ ...prev, ...value }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <ConversionContext.Provider
          value={{
            ...conversionContext,
            setContext
          }}
        >
          <div className={styles.main}>
            <FirstRow />
            <SecondRow />
          </div>
        </ConversionContext.Provider>
      </div>
    </div>
  );
};

export default Conversion;
