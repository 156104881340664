const DashboardIcon2 = () => (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83 1.66C2.5197 1.66 2.2221 1.78327 2.00269 2.00269C1.78327 2.2221 1.66 2.5197 1.66 2.83V14.83C1.66 15.476 2.184 16 2.83 16H8V1.66H2.83ZM9.66 1.66V16H16.83C17.1403 16 17.4379 15.8767 17.6573 15.6573C17.8767 15.4379 18 15.1403 18 14.83V2.83C18 2.5197 17.8767 2.2221 17.6573 2.00269C17.4379 1.78327 17.1403 1.66 16.83 1.66H9.66ZM0 2.83C0 2.07944 0.29816 1.35962 0.828888 0.828888C1.35962 0.29816 2.07944 0 2.83 0H16.83C17.5806 0 18.3004 0.29816 18.8311 0.828888C19.3618 1.35962 19.66 2.07944 19.66 2.83V14.83C19.66 15.5806 19.3618 16.3004 18.8311 16.8311C18.3004 17.3618 17.5806 17.66 16.83 17.66H2.83C2.07944 17.66 1.35962 17.3618 0.828888 16.8311C0.29816 16.3004 0 15.5806 0 14.83V2.83ZM3 4.83C3 4.60987 3.08745 4.39876 3.2431 4.2431C3.39876 4.08745 3.60987 4 3.83 4H5.83C6.05013 4 6.26124 4.08745 6.4169 4.2431C6.57255 4.39876 6.66 4.60987 6.66 4.83C6.66 5.05013 6.57255 5.26124 6.4169 5.4169C6.26124 5.57255 6.05013 5.66 5.83 5.66H3.83C3.60987 5.66 3.39876 5.57255 3.2431 5.4169C3.08745 5.26124 3 5.05013 3 4.83ZM3 7.33C3 7.10987 3.08745 6.89876 3.2431 6.7431C3.39876 6.58745 3.60987 6.5 3.83 6.5H5.83C5.939 6.5 6.04693 6.52147 6.14763 6.56318C6.24833 6.60489 6.33983 6.66603 6.4169 6.7431C6.49397 6.82017 6.55511 6.91167 6.59682 7.01237C6.63853 7.11307 6.66 7.221 6.66 7.33C6.66 7.439 6.63853 7.54693 6.59682 7.64763C6.55511 7.74833 6.49397 7.83983 6.4169 7.9169C6.33983 7.99397 6.24833 8.05511 6.14763 8.09682C6.04693 8.13853 5.939 8.16 5.83 8.16H3.83C3.60987 8.16 3.39876 8.07255 3.2431 7.9169C3.08745 7.76124 3 7.55013 3 7.33ZM3 9.83C3 9.60987 3.08745 9.39876 3.2431 9.2431C3.39876 9.08745 3.60987 9 3.83 9H5.83C6.05013 9 6.26124 9.08745 6.4169 9.2431C6.57255 9.39876 6.66 9.60987 6.66 9.83C6.66 10.0501 6.57255 10.2612 6.4169 10.4169C6.26124 10.5726 6.05013 10.66 5.83 10.66H3.83C3.721 10.66 3.61307 10.6385 3.51237 10.5968C3.41167 10.5551 3.32017 10.494 3.2431 10.4169C3.16603 10.3398 3.10489 10.2483 3.06318 10.1476C3.02147 10.0469 3 9.939 3 9.83Z"
        fill="#656F7D"
      />
    </svg>
  );

export default DashboardIcon2;
