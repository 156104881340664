import React from 'react';

import styles from './closedPipes.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  color: string;
  percent: number;
  percentLine: number;
  allDate: number | undefined;
  name: string;
  countLead: number;
}

const ClosedPipes: React.FC<Props> = ({ percentLine, percent, color, allDate, countLead, name }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.container_left}>
        <div className={styles.container_left_info}>
          {allDate !== undefined ? (
            <>
              <div className={styles.container_left_info_date}>
                {allDate} {t('boss_crm.kun')}
              </div>
              <div className={styles.container_left_info_label}>{t('boss_crm.ortacha_sarflangan_vaqt')}</div>
            </>
          ) : undefined}
        </div>
        <div className={styles.container_left_percent}>{percent}%</div>
        <div
          style={{
            width: `${percentLine}%`
          }}
          className={styles.container_left_line}
        >
          <div
            style={{
              backgroundColor: color
            }}
          />
        </div>
      </div>
      <div className={styles.container_right}>
        <div className={styles.container_right_name}>{name}</div>
        <div className={styles.container_right_info}>
          <span className={styles.container_right_info_number}>{countLead}</span>
          <span className={styles.container_right_info_label}>{t('boss_crm.bitim')}</span>
        </div>
      </div>
    </div>
  );
};

export default ClosedPipes;
