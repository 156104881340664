import React from 'react';

const MoneysBgIcon = () => {
  return (
    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.1193 14.8666V21.7333C31.1193 25.84 28.7727 27.5999 25.2527 27.5999H13.5327C12.9327 27.5999 12.3593 27.5467 11.826 27.4267C11.4927 27.3733 11.1727 27.28 10.8794 27.1733C8.87935 26.4267 7.66602 24.6933 7.66602 21.7333V14.8666C7.66602 10.76 10.0127 9 13.5327 9H25.2527C28.2394 9 30.386 10.2667 30.9594 13.16C31.0527 13.6933 31.1193 14.24 31.1193 14.8666Z"
        fill="url(#paint0_linear_12244_71162)"
      />
      <g filter="url(#filter0_b_12244_71162)">
        <path
          d="M26.3986 10.56V17.4267C26.3986 21.5333 24.052 23.2933 20.532 23.2933H8.81197C8.21197 23.2933 7.63864 23.24 7.10531 23.12C6.77197 23.0667 6.45198 22.9734 6.15865 22.8667C4.15865 22.12 2.94531 20.3867 2.94531 17.4267V10.56C2.94531 6.45331 5.29197 4.69336 8.81197 4.69336H20.532C23.5186 4.69336 25.6653 5.96002 26.2386 8.85335C26.332 9.38669 26.3986 9.93331 26.3986 10.56Z"
          fill="#84DE81"
        />
        <path
          d="M5.98377 23.3351L5.98778 23.3366C6.29747 23.4492 6.64359 23.5512 7.01069 23.6112C7.58282 23.7385 8.18876 23.7933 8.81197 23.7933H20.532C22.3686 23.7933 23.9755 23.3342 25.1255 22.2664C26.2822 21.1923 26.8986 19.5753 26.8986 17.4267V10.56C26.8986 9.89601 26.8278 9.31927 26.7312 8.76716L26.7313 8.76714L26.7291 8.75616C26.4204 7.19807 25.676 6.03811 24.5746 5.27701C23.484 4.52343 22.0909 4.19336 20.532 4.19336H8.81197C6.97533 4.19336 5.36841 4.65243 4.21842 5.72027C3.06172 6.79433 2.44531 8.41134 2.44531 10.56V17.4267C2.44531 18.9732 2.76229 20.244 3.37648 21.2393C3.99407 22.2401 4.89073 22.927 5.98377 23.3351Z"
          stroke="url(#paint1_linear_12244_71162)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_bd_12244_71162)">
        <mask id="path-4Inside-1_12244_71162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6665 17.52C16.6105 17.52 18.1865 15.944 18.1865 14C18.1865 12.056 16.6105 10.48 14.6665 10.48C12.7225 10.48 11.1465 12.056 11.1465 14C11.1465 15.944 12.7225 17.52 14.6665 17.52ZM7.03906 11C6.4924 11 6.03906 11.4533 6.03906 12V16C6.03906 16.5467 6.4924 17 7.03906 17C7.58573 17 8.03906 16.5467 8.03906 16V12C8.03906 11.4533 7.59906 11 7.03906 11ZM21.2793 12C21.2793 11.4533 21.7326 11 22.2793 11C22.8393 11 23.2793 11.4533 23.2793 12V16C23.2793 16.5467 22.826 17 22.2793 17C21.7326 17 21.2793 16.5467 21.2793 16V12Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6665 17.52C16.6105 17.52 18.1865 15.944 18.1865 14C18.1865 12.056 16.6105 10.48 14.6665 10.48C12.7225 10.48 11.1465 12.056 11.1465 14C11.1465 15.944 12.7225 17.52 14.6665 17.52ZM7.03906 11C6.4924 11 6.03906 11.4533 6.03906 12V16C6.03906 16.5467 6.4924 17 7.03906 17C7.58573 17 8.03906 16.5467 8.03906 16V12C8.03906 11.4533 7.59906 11 7.03906 11ZM21.2793 12C21.2793 11.4533 21.7326 11 22.2793 11C22.8393 11 23.2793 11.4533 23.2793 12V16C23.2793 16.5467 22.826 17 22.2793 17C21.7326 17 21.2793 16.5467 21.2793 16V12Z"
          fill="url(#paint2_linear_12244_71162)"
        />
        <path
          d="M17.9865 14C17.9865 15.8336 16.5001 17.32 14.6665 17.32V17.72C16.721 17.72 18.3865 16.0545 18.3865 14H17.9865ZM14.6665 10.68C16.5001 10.68 17.9865 12.1664 17.9865 14H18.3865C18.3865 11.9455 16.721 10.28 14.6665 10.28V10.68ZM11.3465 14C11.3465 12.1664 12.8329 10.68 14.6665 10.68V10.28C12.612 10.28 10.9465 11.9455 10.9465 14H11.3465ZM14.6665 17.32C12.8329 17.32 11.3465 15.8336 11.3465 14H10.9465C10.9465 16.0545 12.612 17.72 14.6665 17.72V17.32ZM6.23906 12C6.23906 11.5638 6.60285 11.2 7.03906 11.2V10.8C6.38194 10.8 5.83906 11.3429 5.83906 12H6.23906ZM6.23906 16V12H5.83906V16H6.23906ZM7.03906 16.8C6.60285 16.8 6.23906 16.4362 6.23906 16H5.83906C5.83906 16.6571 6.38194 17.2 7.03906 17.2V16.8ZM7.83906 16C7.83906 16.4362 7.47527 16.8 7.03906 16.8V17.2C7.69619 17.2 8.23906 16.6571 8.23906 16H7.83906ZM7.83906 12V16H8.23906V12H7.83906ZM7.03906 11.2C7.48687 11.2 7.83906 11.562 7.83906 12H8.23906C8.23906 11.3446 7.71125 10.8 7.03906 10.8V11.2ZM22.2793 10.8C21.6222 10.8 21.0793 11.3429 21.0793 12H21.4793C21.4793 11.5638 21.8431 11.2 22.2793 11.2V10.8ZM23.4793 12C23.4793 11.3446 22.9515 10.8 22.2793 10.8V11.2C22.7271 11.2 23.0793 11.562 23.0793 12H23.4793ZM23.4793 16V12H23.0793V16H23.4793ZM22.2793 17.2C22.9364 17.2 23.4793 16.6571 23.4793 16H23.0793C23.0793 16.4362 22.7155 16.8 22.2793 16.8V17.2ZM21.0793 16C21.0793 16.6571 21.6222 17.2 22.2793 17.2V16.8C21.8431 16.8 21.4793 16.4362 21.4793 16H21.0793ZM21.0793 12V16H21.4793V12H21.0793Z"
          fill="url(#paint3_linear_12244_71162)"
          fillOpacity="0.5"
          mask="url(#path-4Inside-1_12244_71162)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_12244_71162"
          x="-22.0547"
          y="-20.3066"
          width="73.4531"
          height="68.6001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71162" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12244_71162" result="shape" />
        </filter>
        <filter
          id="filter1_bd_12244_71162"
          x="-8.96094"
          y="-4.52002"
          width="47.2402"
          height="37.04"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71162" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71162" result="effect2_dropShadow_12244_71162" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71162" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_12244_71162" x1="31.1193" y1="9" x2="18.7859" y2="27.1885" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9BF763" />
          <stop offset="1" stopColor="#26AB5B" />
        </linearGradient>
        <linearGradient id="paint1_linear_12244_71162" x1="26.166" y1="5" x2="-0.0687756" y2="17.0419" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_12244_71162" x1="22.057" y1="11.7513" x2="2.95436" y2="13.2765" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_12244_71162" x1="7.89621" y1="12.8416" x2="22.5333" y2="13.0394" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MoneysBgIcon;
