export const endPoints = {
  FAILED_LEAD: '/v1/statistics/failed-lead-stat',
  CALL_HISTORY: '/v1/stat/call-history',
  CALL_HISTORY_CARD: '/v1/stat/call-history/card',
  CALL_HISTORY_GRAPH: '/v1/stat/call-history/graph',
  CALL_HISTORY_FILTER: '/v1/stat/call-history/filter',
  LEAD_TASK: '/v1/stat/task',
  PHONE_RECORD: '/v1/stat/call-history/call-record',
  STAT_LEAD: '/v1/stat/lead',
  STAT_LEAD_DATE: '/v1/stat/lead/date',
  CALL_HISTORY_EXCEL: 'v1/stat/call-history/filter/excel',
  FIRST_PIE_CHART_DATA: '/v1/stat/conversion',
  SECOND_PIE_CHART_DATA: '/v1/stat/conversion/failed',
  FAILED_TABLE_DATA: '/v1/stat/conversion/failed/table',
  TJMS: '/v3/mobile/sale-plan/flat/house-building',
  THIRD_PIE_CHART_DATA: '/v1/stat/conversion/success',
  SUCCESS_TABLE_DATA: '/v1/stat/conversion/success/table',
  SUCCESSFUL_LEADS: '/v1/statistics/success-lead-stat',
  SUCCESSFUL_TABLE_LEADS: '/v1/stat/conversion/success-lead/table'
};
