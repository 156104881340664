import React from 'react';

const Broom = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.75752 3.30748L3.76252 4.52082L2.85252 3.02749C2.52002 2.47915 2.69502 1.75582 3.24335 1.42332C3.79168 1.09082 4.51502 1.26581 4.84752 1.81414L5.75752 3.30748Z"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89507 5.3433L5.05174 6.46333C3.9784 7.11666 3.65173 8.43499 4.1709 9.48499L5.36673 11.9233C5.75173 12.7108 6.68507 12.985 7.43174 12.5241L11.1826 10.2433C11.9351 9.78832 12.1159 8.83749 11.5967 8.13165L9.9809 5.94999C9.2809 5.00499 7.9684 4.68997 6.89507 5.3433Z"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.27475 2.97384L3.2854 4.79419L4.49897 6.78709L7.48832 4.96674L6.27475 2.97384Z"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.34741 9.80579L9.30992 11.3866" stroke="#FF3F40" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.85425 10.7158L7.81674 12.2967" stroke="#FF3F40" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.84082 8.89587L10.8033 10.4767" stroke="#FF3F40" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Broom;
