import React, { useEffect, useState } from 'react';
import ExcelDateModal from '@components/broker/excel-date-modal';
import { useQueryParam } from '@hooks/useQueryParams';
import { useDownloadClientsToExcel } from '@queries/mutation';
import { useStaff, useTableConfig } from '@queries/queries';
import { TableKey } from '@utils/models/TableKey';
import { Dropdown, Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import Download from '../../assets/icons/Download';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import PersonCreateIcon from '../../assets/icons/PersonCreateIcon';
import BrokerFilter from '../../components/broker/BrokerFilter';
import BrokerModal from '../../components/broker/BrokerModal';
import BrokerTable from '../../components/broker/BrokerTable';
import TableSettings from '../../components/filters/TableSettings';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';

import stylesButton from '../../components/filters/tableSettings.module.scss';
import styles from './broker.module.scss';

const { Content } = Layout;

export interface SearchParamsBroker {
  page?: string;
  size?: string;
  cities?: string[];
  search?: string;
  order?: string[];
}

const Broker = () => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsBroker, any>();
  const [tableSize, setTableSize] = useState(10);
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);
  const [openBrokerModal, setOpenBrokerModal] = useState(false);

  const { t } = useTranslation();

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.broker);
  const { data, isLoading, isFetching } = useStaff(tableSize, searchParams);
  const download = useDownloadClientsToExcel(tableSize, searchParams);

  useEffect(() => {
    if (tableConfig?.size) {
      appendMultipleDifferent(['page', 'size'], [1, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };

  const downloadExcel = () => {
    download.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Excel')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
          <ExcelDateModal>
            <div className={stylesButton.downloadPopover_item} onClick={() => setVisibleDownloadMenu(false)}>
              <div className={stylesButton.downloadPopover_item_element}>
                <ExcelIcon />
                <span>{t('home.KPI hisoboti')}</span>
              </div>
              <ArrowLineDownIcon />
            </div>
          </ExcelDateModal>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  const handleBrokerModal = (visible: boolean) => {
    setOpenBrokerModal(visible);
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.client_page}>
          <div className="d-flex align-center justify-space-between">
            <BrokerFilter />
            <Spin spinning={download.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.broker} />
            </Spin>
            <div onClick={() => handleBrokerModal(true)} className={styles.settingBrokerAdd}>
              <PersonCreateIcon />
              <span>{t('home.Vositachi_qoshish')}</span>
            </div>
          </div>
          <BrokerTable
            isFetching={isFetching}
            data={data?.data || []}
            isLoading={isLoading}
            currentPage={searchParams.page ? Number(searchParams.page) : 1}
            pageSize={tableSize}
            tableConfig={tableConfig?.config || []}
            tableConfigFetch={tableConfigFetch}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
          <BrokerModal tableSize={tableSize} visible={openBrokerModal} onClose={handleBrokerModal} />
        </div>
      </Content>
    </>
  );
};

export default Broker;
