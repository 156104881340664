import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import { useTranslation } from 'react-i18next';
import styles from './deleteLeadModal.module.scss';
import { useDeleteLid } from '../../../../oneLid/services/mutation';

interface IProps {
  visible: boolean;
  id: number;
  setDeleteLead: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: number;
    }>
  >;
}

const DeleteLeadModal: FC<IProps> = ({ id, setDeleteLead, visible }) => {
  const { t } = useTranslation();
  const leadDelete = useDeleteLid(id, onSucces);
  const handleClose = () => {
    setDeleteLead({
      visible: false,
      id: 0
    });
  };

  const handleDelete = () => {
    leadDelete.mutate();
  };

  function onSucces() {
    handleClose();
  }

  return (
    <Modal visible={visible} onCancel={handleClose} footer={false} wrapClassName={styles.deleteLeadModal} closable={false} centered width={400}>
      <span>
        <TrashIcon />
      </span>
      <p>O'chirilsinmi?</p>
      <div>
        <Button onClick={handleClose}>{t('crm.Yopish')}</Button>
        <Button onClick={handleDelete} loading={leadDelete.isLoading}>
          {t('crm.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteLeadModal;
