import React from 'react';

import styles from './header.module.scss';
import MonthSlider from './MonthSlider';
import Buttons from './Buttons';

const Header = () => {
  return (
    <div className={styles.container}>
      <MonthSlider />
      <Buttons />
    </div>
  );
};

export default Header;
