import { isEmptyArr } from '..';

export const generateReqBody = <T extends object>(obj: T, requiredParams?: { [TParamKey in keyof T]?: boolean }) => {
  const newBody: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      if (Array.isArray(value) && !isEmptyArr(value)) {
        const newValue: any[] = [];

        value?.forEach(i => {
          if (i) {
            newValue.push(i);
          }
        });

        if (!isEmptyArr(newValue)) {
          newBody[key] = newValue;
        }
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        newBody[key] = generateReqBody(value);
      }

      if (typeof value !== 'object') {
        newBody[key] = value;
      }
    }

    if (requiredParams?.[key as keyof typeof requiredParams]) {
      newBody[key] = value || null;
    }
  }

  return newBody as never;
};
