import { $api } from '@service/RequestService';
import { useQuery, UseQueryResult } from 'react-query';

import { endPoints, getModel, keys, models, queryKeys } from './utils';

// @ts-expect-error
export function useTableKay(key: keyof typeof keys): UseQueryResult<string>;
export function useTableKay(key: keyof typeof keys, model: 'string', enabled?: boolean): UseQueryResult<string>;
export function useTableKay(key: keyof typeof keys, model: 'number', enabled?: boolean): UseQueryResult<number>;

export function useTableKay(key: keyof typeof keys, model: keyof typeof models, enabled: boolean = true) {
  return useQuery(
    [queryKeys.TABLE_KEY(key)],
    async () => {
      const res = await $api.get(endPoints.TABLE_KEY(key));

      return getModel(model, res.data.data, key);
    },
    {
      enabled: !!key && enabled
    }
  );
}
