import React from 'react';

const DragAndDropIcon = (svg: React.SVGProps<SVGSVGElement>) => (
  <svg {...svg} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.35312 2.33906C5.86097 2.33906 6.27265 1.92737 6.27265 1.41953C6.27265 0.911687 5.86097 0.5 5.35312 0.5C4.84528 0.5 4.43359 0.911687 4.43359 1.41953C4.43359 1.92737 4.84528 2.33906 5.35312 2.33906Z"
      fill="black"
    />
    <path
      d="M1.66465 2.33906C2.17249 2.33906 2.58418 1.92737 2.58418 1.41953C2.58418 0.911687 2.17249 0.5 1.66465 0.5C1.1568 0.5 0.745117 0.911687 0.745117 1.41953C0.745117 1.92737 1.1568 2.33906 1.66465 2.33906Z"
      fill="black"
    />
    <path
      d="M5.35312 6.84394C5.86097 6.84394 6.27265 6.43225 6.27265 5.92441C6.27265 5.41657 5.86097 5.00488 5.35312 5.00488C4.84528 5.00488 4.43359 5.41657 4.43359 5.92441C4.43359 6.43225 4.84528 6.84394 5.35312 6.84394Z"
      fill="black"
    />
    <path
      d="M1.66465 6.84394C2.17249 6.84394 2.58418 6.43225 2.58418 5.92441C2.58418 5.41657 2.17249 5.00488 1.66465 5.00488C1.1568 5.00488 0.745117 5.41657 0.745117 5.92441C0.745117 6.43225 1.1568 6.84394 1.66465 6.84394Z"
      fill="black"
    />
    <path
      d="M5.35312 11.3478C5.86097 11.3478 6.27265 10.9362 6.27265 10.4283C6.27265 9.92048 5.86097 9.50879 5.35312 9.50879C4.84528 9.50879 4.43359 9.92048 4.43359 10.4283C4.43359 10.9362 4.84528 11.3478 5.35312 11.3478Z"
      fill="black"
    />
    <path
      d="M1.66465 11.3478C2.17249 11.3478 2.58418 10.9362 2.58418 10.4283C2.58418 9.92048 2.17249 9.50879 1.66465 9.50879C1.1568 9.50879 0.745117 9.92048 0.745117 10.4283C0.745117 10.9362 1.1568 11.3478 1.66465 11.3478Z"
      fill="black"
    />
  </svg>
);

export default DragAndDropIcon;
