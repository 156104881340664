import React from 'react';

const ContactPlusIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
      <path d="M13.0054 20H26.9946" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 13.0059V26.9951" stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#B0B0B0" />
    </svg>
  );
};

export default ContactPlusIcon;
