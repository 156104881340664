import React from 'react';

interface FilterItemCloseProps {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const FilterItemClose: React.FC<FilterItemCloseProps> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.59122 2.40881L2.40869 7.59134" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.59122 7.59134L2.40869 2.40881" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FilterItemClose;
