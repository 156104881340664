import React from 'react';

interface Props {
  className?: string;
}

const DeleteCircle: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99996 18.3337C14.5833 18.3337 18.3333 14.5837 18.3333 10.0003C18.3333 5.41699 14.5833 1.66699 9.99996 1.66699C5.41663 1.66699 1.66663 5.41699 1.66663 10.0003C1.66663 14.5837 5.41663 18.3337 9.99996 18.3337Z"
        fill="#FF3F40"
      />
      <path d="M6.66663 10H13.3333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DeleteCircle;
