import { useAppSelector } from '@hooks/reduxHooks';
import PlusAddCircleIcon from '@icons/PlusAddCircleIcon';
import Trash01Icon from '@icons/Trash01Icon';
import { getCurrentLanguageWord } from '@utils/helper/getCurrentLanguageWord';
import { BaseEntity } from '@utils/models/BaseEntity';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import { Form, FormInstance, Select } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  form: FormInstance<any>;
  roleData: BaseLanguageEntity[] | undefined;
  loadingRole: boolean;
  branchData: BaseEntity[] | undefined;
  loadingBranch: boolean;
};

const EmployeeBranches: React.FC<Props> = ({ form, roleData, loadingRole, branchData, loadingBranch }) => {
  const { t } = useTranslation();

  const currentData = Form.useWatch('roleBranches', form);

  const isAll = currentData?.some((item: any) => item?.branchId === -1);
  const user = useAppSelector(state => state.userReducer.user);

  return (
    <Form.List name="roleBranches">
      {(fields, { add, remove }) => (
        <div className="mb-2 max-h-[300px] w-full overflow-y-auto overflow-x-hidden">
          {fields?.map(field => (
            <div className="flex items-center justify-between gap-[5px]" key={field.key}>
              {user?.accessToken !== null && (
                <span className="w-[50%]">
                  <Form.Item className="customItem !mb-2 w-[100%]" label={t('settingEmployee.Filial')} name={[field.name, 'branchId']}>
                    <Select
                      allowClear
                      placeholder={t('settingEmployee.Tanlang')}
                      loading={loadingBranch}
                      className="customSelect"
                      onChange={e => {
                        const newData = currentData?.filter((item: any, index: number) => index !== 0);

                        if (e === String(-1)) {
                          form.setFieldsValue({
                            ...newData,
                            roleBranches: [{ branchId: -1, roleId: null }]
                          });
                        }
                      }}
                    >
                      {field?.key === 0 && <Select.Option value={-1}>{t('crm.Hammasi')}</Select.Option>}
                      {branchData?.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {i.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </span>
              )}
              <span className="w-[50%]">
                <Form.Item label={t('settingEmployee.Lavozim')} name={[field.name, 'roleId']} rules={[{ required: true, message: '' }]} className="customItem !mb-2 w-[100%]">
                  <Select loading={loadingRole} placeholder={t('settingEmployee.Tanlang')} className="customSelect">
                    {roleData?.map(role => (
                      <Select.Option key={role.id} value={role.id}>
                        {getCurrentLanguageWord(role)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </span>
              {fields?.length > 1 && (
                <span className="mt-[25px]">
                  <Trash01Icon className="cursor-pointer" onClick={() => remove(field.name)} />
                </span>
              )}
            </div>
          ))}

          {fields?.length! <= 20 && !isAll && user?.accessToken !== null && (
            <Form.Item>
              <button
                className={`
                          mb-[10px] 
                        mt-[10px] 
                        flex  
                        cursor-pointer 
                        items-start 
                        justify-start 
                        gap-[8px] 
                        border-none
                        bg-transparent
                        text-[12px]
                        font-medium
                        leading-[20px]`}
                style={{ color: 'rgba(30, 144, 255, 1)' }}
                onClick={() => add()}
              >
                <PlusAddCircleIcon /> {t("settingEmployee.Lavozim_qo'shish")}
              </button>
            </Form.Item>
          )}
        </div>
      )}
    </Form.List>
  );
};

export default EmployeeBranches;
