import React, { FC, useState, useEffect } from 'react';
import styles from './monetaryTableAddClient.module.scss';
import { AutoComplete, Button, Input, Modal } from 'antd';
import SearchIcon from '@icons/SearchIcon';
import { useMonetaryPaymentContract } from '@queries/queries';
import { useMonetaryPayment } from '@queries/mutation';
import { useTranslation } from 'react-i18next';

interface IProps {
  id: number | null;
  setVisible: (id: number | null) => void;
}

const MonetaryTableAddClient: FC<IProps> = ({ setVisible, id }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [activeId, setActiveId] = useState(0);
  const { data } = useMonetaryPaymentContract(search);
  const monetaryPayment = useMonetaryPayment();
  const handleClose = () => {
    setVisible(null);
  };

  const options = data?.map(item => {
    return {
      label: (
        <div className={styles.client}>
          <p>
            {t('home.Mijoz')}: <b>{item?.clientName}</b>
          </p>
          <p>
            {t('home.Telefon_raqam')}: <b>{item?.clientPhone}</b>
          </p>
          <p>
            {t('home.Shartnoma')}: <b>{item?.number}</b>
          </p>
        </div>
      ),
      value: item?.id,
      key: item?.id,
      item: item
    };
  });

  const handleSubmit = () => {
    if (id) {
      monetaryPayment.mutateAsync({ contractId: activeId, paymentId: id }).then(() => {
        handleClose();
      });
    }
  };

  useEffect(() => {
    setSearch('');
  }, [id]);

  console.log(options);

  return (
    <Modal title={t('monetary.Mijoz_biriktirish')} visible={!!id} onCancel={handleClose} className={styles.modal} footer={false}>
      <div className={styles.content}>
        <h3>{t('create_payment.Mijoz_qidirish')}</h3>
        <AutoComplete
          style={{ width: '100%' }}
          options={options}
          onSelect={(e: any, b: any) => {
            setActiveId(b?.key);
            setSearch(b?.item?.clientName);
          }}
          value={search}
        >
          <Input prefix={<SearchIcon />} className="customInput" onChange={e => setSearch(e?.target?.value)} />
        </AutoComplete>
      </div>
      <div className={styles.footer}>
        <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
        <Button type="primary" loading={monetaryPayment.isLoading} onClick={handleSubmit}>
          {t('home.Saqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default MonetaryTableAddClient;
