interface IProps {
  stroke?: string;
  size?: number;
}

const CalendarTickIcon = ({ stroke = '#464255', size = 18 }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.33203 1.5V3.75" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.332 1.5V3.75" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.95703 6.82031H15.707" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.832 14.25C16.832 14.8125 16.6745 15.345 16.397 15.795C15.8795 16.665 14.927 17.25 13.832 17.25C13.0745 17.25 12.3845 16.9725 11.8595 16.5C11.627 16.305 11.4245 16.065 11.267 15.795C10.9895 15.345 10.832 14.8125 10.832 14.25C10.832 12.5925 12.1745 11.25 13.832 11.25C14.732 11.25 15.5345 11.6475 16.082 12.27C16.547 12.8025 16.832 13.4925 16.832 14.25Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.6602 14.2506L13.4027 14.9931L15.0002 13.5156" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.082 6.375V12.27C15.5345 11.6475 14.732 11.25 13.832 11.25C12.1745 11.25 10.832 12.5925 10.832 14.25C10.832 14.8125 10.9895 15.345 11.267 15.795C11.4245 16.065 11.627 16.305 11.8595 16.5H6.33203C3.70703 16.5 2.58203 15 2.58203 12.75V6.375C2.58203 4.125 3.70703 2.625 6.33203 2.625H12.332C14.957 2.625 16.082 4.125 16.082 6.375Z"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.32791 10.2734H9.33465" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.55447 10.2734H6.56121" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.55447 12.5234H6.56121" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CalendarTickIcon;
