
import LicenseMessage from '@components/messageSettings/license-message/LicenseMessage';

import MessageTemplates from '../../components/messageSettings/MessageTemplates';
import SendingMessage from '../../components/messageSettings/SendingMessage';
import SettingPlayMobile from '../../pages/settingPlayMobile/SettingPlayMobile';

import { messagePath } from './MessagePaths';

export const messageRoutes = [
  {
    path: messagePath.DEFAULT_MESSAGE,
    component: SendingMessage,
    exact: true
  },
  {
    path: messagePath.SENDING_MESSAGE,
    component: SendingMessage,
    exact: true
  },
  {
    path: messagePath.TEMPLATES_MESSAGE,
    component: MessageTemplates,
    exact: true
  },
  {
    path: messagePath.MESSAGE_SYSTEM,
    component: SettingPlayMobile,
    exact: true
  },
  {
    path: messagePath.LICENSE_MESSAGE,
    component: LicenseMessage,
    exact: true
  },

];
