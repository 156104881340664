import React from 'react';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import DotsIcon from '@icons/DotsIcon';
import Edit2Icon from '@icons/Edit2Icon';
import { useDeleteSip } from '@pages/roboto-calls/services/mutation';
import { useGetRobotoSip } from '@pages/roboto-calls/services/queries';
import { SipModel } from '@pages/roboto-calls/utils/models/SipModel';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<React.SetStateAction<SipModel | undefined>>;
}

const RobotoSip: React.FC<IProps> = ({ setVisible, setModalData }) => {
  const { t } = useTranslation();
  const { data: sipData } = useGetRobotoSip();
  const deleteSip = useDeleteSip();

  const handleDeleSip = (id: number) => {
    deleteSip.mutate(id);
  };

  return (
    <div className="flex max-h-[calc(100vh-300px)] flex-col gap-4 overflow-auto">
      {sipData?.data?.map(item => (
        <div key={item?.id} className="robotoSip flex cursor-pointer flex-col gap-y-2.5 rounded-xl bg-white p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <span className="text-xl font-medium text-[#101828]">{item?.name}</span>
              <p
                className="rounded-md px-2 py-0"
                style={{
                  backgroundColor: item?.active ? '#E6F6F4' : '#FEF3F2',
                  color: item?.active ? '#2ECD6F' : '#FF0000',
                  border: '1px solid',
                  borderColor: item?.active ? '#2ECD6F' : '#FF0000'
                }}
              >
                {item?.active ? `${t('home.Faol')}` : `${t('home.Nofaol')}`}
              </p>
            </div>
            <Dropdown
              overlay={
                <div className="flex w-60 flex-col overflow-auto rounded-xl bg-white shadow-[0px_4px_6px_2px_#10182808]">
                  <div
                    className="flex cursor-pointer items-center gap-3 px-4 py-2 hover:bg-[#F2F4F7]"
                    onClick={() => {
                      setVisible(true);
                      setModalData(item);
                    }}
                  >
                    <Edit2Icon /> <span>{t('client.Tahrirlash')}</span>
                  </div>
                  <div className="flex cursor-pointer items-center gap-3 px-4 py-2 hover:bg-[#F2F4F7]" onClick={() => handleDeleSip(item?.id!)}>
                    <TrushIcon /> <span>{t('boss_crm.Ochirish')}</span>
                  </div>
                </div>
              }
              trigger={['click', 'hover']}
              mouseEnterDelay={1000}
              mouseLeaveDelay={0.3}
            >
              <span className="dotsIcon">
                <DotsIcon />
              </span>
            </Dropdown>
          </div>
          <div className="flex gap-x-2.5">
            <div className="w-full rounded-xl bg-[#F2F4F7] px-4 py-2">
              <label className="text-[#667085]">{t('integration.Login')}</label>
              <p className="text-base font-medium text-[#344054]">{item?.username}</p>
            </div>
            <div className="w-full rounded-xl bg-[#F2F4F7] px-4 py-2">
              <label className="text-[#667085]">{t('home.Host')}</label>
              <p className="text-base font-medium text-[#344054]">{item?.endpoint}</p>
            </div>
            <div className="w-full rounded-xl bg-[#F2F4F7] px-4 py-2">
              <label className="text-[#667085]">{t('integration.Parol')}</label>
              <p className="text-base font-medium text-[#344054]">{`*`.repeat(item?.password?.length)}</p>
            </div>
            <div className="w-full rounded-xl bg-[#F2F4F7] px-4 py-2">
              <label className="text-[#667085]">{t('home.Parallel_qongiroqlar_soni')}</label>
              <p className="text-base font-medium text-[#344054]">{item?.parallelCallCount}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RobotoSip;
