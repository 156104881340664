import React, { useState } from 'react';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import { useSearchLeadTask } from '@queries/queries';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './createTask.module.scss';

interface Props {
  search: string;
  id: number;
  selectLead: (id: number, name: string) => void;
}

const FindLeadOrClient: React.FC<Props> = ({ search, id, selectLead }) => {
  const [scrolling, setScrolling] = useState(true);
  const { isLoading, data, fetchNextPage } = useSearchLeadTask(search);

  const { t } = useTranslation();

  // handle select
  const handleSelect = (id: number, name: string) => {
    selectLead(id, name);
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 100 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container_dropdown} onScroll={onScroll}>
        {data?.pages?.map(data =>
          data?.data?.map((item, index) => (
            <div key={index} onClick={() => handleSelect(item.id, item.name)} className={styles.container_dropdown_item}>
              <span className={styles.container_dropdown_item_name}>{item.name}</span>
              {item.id === id && (
                <span className={styles.container_dropdown_item_icon}>
                  <CheckmarkIcon />
                </span>
              )}
            </div>
          ))
        )}
        {data?.pages[0]?.data?.length === 0 && <Empty description={t('calendar_view.Lead_topilmadi')} />}
      </div>
    </Spin>
  );
};

export default FindLeadOrClient;
