import React from 'react';

const ViewIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16797 13.7502V6.25016C4.16797 5.7335 4.18464 5.27516 4.24297 4.86683C4.48464 2.67516 5.51797 2.0835 8.33464 2.0835H11.668C14.4846 2.0835 15.518 2.67516 15.7596 4.86683C15.818 5.27516 15.8346 5.7335 15.8346 6.25016V13.7502C15.8346 14.2668 15.818 14.7252 15.7596 15.1335C15.518 17.3252 14.4846 17.9168 11.668 17.9168H8.33464C5.51797 17.9168 4.48464 17.3252 4.24297 15.1335C4.18464 14.7252 4.16797 14.2668 4.16797 13.7502Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.66797 16.25V3.75" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.332 15.8335V3.3335" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ViewIcon;
