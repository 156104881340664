import { IntegrationPaths } from './integrationPaths';
import Integration from '../../pages/integration/Integration';
import BotConfig from '../../components/integration/bot/BotConfig';
import WebSite from '../../components/integration/webSite/WebSite';
import AmoCrm from '../../components/integration/amoCrm/AmoCrm';
import BotInterface from '../../components/integration/bot/BotInterface';
import MarketPlace from '../../components/integration/marketPlace/MarketPlace';

export const IntegrationRoutes = [
  {
    component: Integration,
    path: IntegrationPaths.INDEX,
    exact: true
  },
  {
    component: BotConfig,
    path: IntegrationPaths.TELEGRAM,
    exact: true
  },
  {
    component: WebSite,
    path: IntegrationPaths.WEB_SITE,
    exact: true
  },
  {
    component: AmoCrm,
    path: IntegrationPaths.AMO_CRM,
    exact: true
  },
  {
    component: BotInterface,
    path: IntegrationPaths.TELEGRAM + IntegrationPaths.BOT_INTERFACE,
    exact: true
  },
  {
    component: MarketPlace,
    path: IntegrationPaths.MARKET_PLACE,
    exact: true
  }
];
