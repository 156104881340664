import React from 'react';

import DateSelect from './date-select';
import PipeSelect from './pipe-select';

const Filter: React.FC = () => (
  <div className="flex justify-end gap-3">
    <DateSelect />
    <PipeSelect />
  </div>
);

export default Filter;
