import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './message.module.scss';
import i18n from '../../../../../../service/I18nService';
import SmsIcon from '../../../../../../assets/icons/SmsIcon';
import { OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';
import { urlify } from '../../../../../../utils/helper/urlify';

interface Props {
  data: OneEvent;
}

const Message: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  // translateSend
  const translateMainInformation = (time: string, incoming: boolean, to: string, fromNumber: string, fromName: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            {time} {incoming ? t('my_calls.Kiruvchi_xabar') : t('my_calls.Chiquvchi_xabar')}: от {fromName}
            {fromNumber} на {to}
          </>
        );
      case 'en':
        return (
          <>
            {time} {incoming ? t('my_calls.Kiruvchi_xabar') : t('my_calls.Chiquvchi_xabar')}: from {fromName}
            {fromNumber} to {to}
          </>
        );
      default:
        return (
          <>
            {time} {incoming ? t('my_calls.Kiruvchi_xabar') : t('my_calls.Chiquvchi_xabar')}: {fromName} {fromNumber}dan {to} raqamiga
          </>
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_icon}>
        <SmsIcon />
      </div>
      <div className={styles.container_body}>
        <p className={styles.container_body_header}>
          {translateMainInformation(data.createdDate, data.incoming, data.toPhoneNumber, data.fromPhoneNumber, data.fromName)}
        </p>
        <div className={styles.container_body_info}>
          <p
            className={styles.container_body_info_type}
            dangerouslySetInnerHTML={{
              __html: urlify(data.text)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Message;
