/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useSurchargeCreate, useSurchargeDelete } from '../../../queries/mutation';
import { useGetSurcharge } from '../../../queries/queries';
import { Surcharge as SurchargeI } from '../../../utils/models/Surcharge';

import styles from '../companySetting.module.scss';

const Surcharge = () => {
  const [form] = Form.useForm<SurchargeI>();

  const { isLoading, data, isFetching } = useGetSurcharge();

  const createSurcharge = useSurchargeCreate();
  const deleteSurcharge = useSurchargeDelete();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onFinish = (values: SurchargeI) => {
    createSurcharge.mutate({
      percent: Number(values.percent),
      limitPercent: Number(values.limitPercent),
      day: Number(values.day)
    });
  };

  const handleDeleteSurcharge = () => {
    form.resetFields();
    deleteSurcharge.mutate();
  };

  return (
    <div className={styles.surcharge_cont}>
      <Spin spinning={isLoading || isFetching}>
        <Form onFinish={onFinish} name="surcharge" form={form}>
          <h1>{t('company_config.Peniya_hisoblab_borish_tartibi')}</h1>
          <h2>{t('company_config.Qarzdorlik_kuzatilgan_mijozlardan_kunlik_peniya_hisoblash_sozlamalari')}</h2>
          <div className={styles.surcharge_cont_item}>
            <div className={styles.surcharge_cont_item_left}>
              <h3>{t('company_config.Oshib_borish_foizi')}</h3>
              <p>{t('company_config.Peniyaning_kunlik_ortib_borish_foizi_kiritilsin')}</p>
            </div>
            <div className={styles.surcharge_cont_item_right}>
              <Form.Item
                name="percent"
                label=""
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (!value || Number(value) > 100 || Number(value) < 0) {
                        return Promise.reject('');
                      }
                      form.setFieldsValue({
                        percent: Number(String(value).replace(/[-]/g, '').replace(/[e]/g, ''))
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input type="number" className="customInput" />
              </Form.Item>
              <span>%</span>
            </div>
          </div>
          <div className={styles.surcharge_cont_item}>
            <div className={styles.surcharge_cont_item_left}>
              <h3>{t('company_config.Maksimal_oshish_foizi')}</h3>
              <p>{t('company_config.Peniyaning_umumiy_qarzdorlikning_maksimal_necha_foizi_tashkil_etishi')}</p>
            </div>
            <div className={styles.surcharge_cont_item_right}>
              <Form.Item
                name="limitPercent"
                label=""
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (!value || Number(value) > 50 || Number(value) < 0) {
                        return Promise.reject('');
                      }
                      form.setFieldsValue({
                        limitPercent: Number(String(value).replace(/[-]/g, '').replace(/[e]/g, ''))
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input className="customInput" />
              </Form.Item>
              <span>%</span>
            </div>
          </div>
          <div className={styles.surcharge_cont_item}>
            <div className={styles.surcharge_cont_item_left}>
              <h3>{t('company_config.Penya_hisoblash_kuni')}</h3>
              <p>{t('company_config.Penya_necha_kundan_keyin_hisoblashi_kiritilsin')}</p>
            </div>
            <div className={styles.surcharge_cont_item_right}>
              <Form.Item
                name="day"
                label=""
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (value === undefined || value === null || isNaN(Number(value)) || Number(value) < 0) {
                        return Promise.reject('');
                      }
                      form.setFieldsValue({
                        day: Number(value)
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
                style={{ marginRight: '15px' }}
              >
                <Input className="customInput" />
              </Form.Item>
            </div>
          </div>

          <div className={styles.surcharge_cont_footer}>
            <Button
              loading={deleteSurcharge.isLoading}
              onClick={handleDeleteSurcharge}
              htmlType="button"
              className={styles.surcharge_cont_footer_clear}
            >
              {t('company_config.Tozalash')}
            </Button>
            <Button loading={createSurcharge.isLoading} htmlType="submit" className={styles.surcharge_cont_footer_save} type="primary">
              {t('company_config.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Surcharge;
