import React from 'react';

const Wallet3BgIcon = () => {
  return (
    <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.33398" y="4" width="18" height="23" rx="5" fill="url(#paint0_linear_12244_71167)" />
      <g filter="url(#filter0_b_12244_71167)">
        <path
          d="M28 22.8669V20.9335V15.4668V13.5335C28 10.6002 25.6 8.2002 22.6667 8.2002H9.33333C6.38667 8.2002 4 10.5869 4 13.5335V22.8669C4 25.8135 6.38667 28.2002 9.33333 28.2002H22.6667C25.6133 28.2002 28 25.8135 28 22.8669Z"
          fill="#FF9CBB"
        />
        <path
          d="M28.5 22.8669V20.9335V15.4668V13.5335C28.5 10.3241 25.8761 7.7002 22.6667 7.7002H9.33333C6.11052 7.7002 3.5 10.3107 3.5 13.5335V22.8669C3.5 26.0897 6.11052 28.7002 9.33333 28.7002H22.6667C25.8895 28.7002 28.5 26.0897 28.5 22.8669Z"
          stroke="url(#paint1_linear_12244_71167)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_bd_12244_71167)">
        <path
          d="M30.1728 16.36V19.1067C30.1728 19.8534 29.5594 20.4667 28.7994 20.4667H26.2261C24.7861 20.4667 23.4662 19.4134 23.3462 17.9734C23.2662 17.1334 23.5861 16.3467 24.1461 15.8C24.6395 15.2934 25.3195 15 26.0661 15H28.7994C29.5594 15 30.1728 15.6134 30.1728 16.36Z"
          fill="url(#paint2_linear_12244_71167)"
          shapeRendering="crispEdges"
        />
        <path
          d="M23.5455 17.9568L23.5455 17.9568L23.5452 17.9544C23.4713 17.1777 23.7669 16.4498 24.2858 15.9432L24.2859 15.9432L24.2894 15.9396C24.7448 15.4718 25.3731 15.2 26.0661 15.2H28.7994C29.4503 15.2 29.9728 15.7251 29.9728 16.36V19.1067C29.9728 19.7416 29.4503 20.2667 28.7994 20.2667H26.2261C24.8744 20.2667 23.6556 19.2781 23.5455 17.9568Z"
          stroke="url(#paint3_linear_12244_71167)"
          strokeWidth="0.4"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_bd_12244_71167)">
        <path
          d="M18.668 15.8667H10.668C10.1213 15.8667 9.66797 15.4134 9.66797 14.8667C9.66797 14.32 10.1213 13.8667 10.668 13.8667H18.668C19.2146 13.8667 19.668 14.32 19.668 14.8667C19.668 15.4134 19.2146 15.8667 18.668 15.8667Z"
          fill="url(#paint4_linear_12244_71167)"
        />
        <path
          d="M18.668 15.6667H10.668C10.2318 15.6667 9.86797 15.3029 9.86797 14.8667C9.86797 14.4305 10.2318 14.0667 10.668 14.0667H18.668C19.1042 14.0667 19.468 14.4305 19.468 14.8667C19.468 15.3029 19.1042 15.6667 18.668 15.6667Z"
          stroke="url(#paint5_linear_12244_71167)"
          strokeWidth="0.4"
        />
      </g>
      <defs>
        <filter id="filter0_b_12244_71167" x="-20" y="-15.7998" width="72" height="68" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71167" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12244_71167" result="shape" />
        </filter>
        <filter
          id="filter1_bd_12244_71167"
          x="8.33398"
          y="0"
          width="36.8379"
          height="35.4668"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71167" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.395833 0 0 0 0 0.395833 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71167" result="effect2_dropShadow_12244_71167" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71167" result="shape" />
        </filter>
        <filter
          id="filter2_bd_12244_71167"
          x="-5.33203"
          y="-1.1333"
          width="40"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71167" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.321569 0 0 0 0 0.54902 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71167" result="effect2_dropShadow_12244_71167" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71167" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_12244_71167" x1="12.6591" y1="17.0402" x2="-4.42303" y2="23.5403" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF759F" />
          <stop offset="1" stopColor="#FF196E" />
        </linearGradient>
        <linearGradient id="paint1_linear_12244_71167" x1="7.82041" y1="10.5302" x2="23.646" y2="30.8698" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_12244_71167" x1="29.6879" y1="15.9872" x2="22.0747" y2="16.2977" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_12244_71167" x1="24.4226" y1="15.6369" x2="27.6733" y2="20.6734" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint4_linear_12244_71167" x1="18.959" y1="14.2279" x2="8.09676" y2="15.9986" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint5_linear_12244_71167" x1="11.2598" y1="14.0997" x2="11.6706" y2="16.6435" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Wallet3BgIcon;
