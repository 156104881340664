import React, { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { CallStatisticsParam } from '@pages/bossCrm/components/header/Header';
import { BoosComplexSelectType } from '@utils/models/BoosComplexSelectType';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../service/datePicker';
import { DATE_FORMAT } from '../../utils/constants/format';

import styles from './saleOneHouseModal.module.scss';

const { RangePicker } = DatePicker;

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  handleSelect: (value: any) => void;
}

const SpecialTimeModal: React.FC<Props> = ({ handleSelect, visible, close }) => {
  const [values, setValues] = useState<string[] | null>(null);
  const { appendSingle, searchParams, remove } = useQueryParam<CallStatisticsParam, any>();

  const { t } = useTranslation();

  useEffect(() => {
    if ('startDate' in searchParams && 'finishDate' in searchParams) {
      setValues([searchParams?.startDate, searchParams?.finishDate]);
    }
  }, [searchParams.startDate, searchParams?.finishDate]);

  // handle close modal
  const handleCancel = () => {
    close(false);
    handleValues(null);
  };

  // handle select time
  const handleSelectTime = (value: string[]) => {
    if (value![0] === '' && value![1] === '') {
      handleSelect(null);
      handleValues(null);
    } else {
      handleSelect(value);
      handleValues(value);
      if ('date' in searchParams) {
        appendSingle('date', BoosComplexSelectType.SPECIAL1);
        appendSingle('startDate', value[0]);
        appendSingle('finishDate', value[1]);
      }
    }
  };

  useEffect(() => {
    if (values) {
      close(false);
    }
  }, [values]);

  // handle values
  const handleValues = (value: string[] | null) => {
    setValues(value);
  };

  return (
    <Modal
      width={300}
      closable={false}
      className={styles.container}
      title={t('boss_complex.Maxsus_sana_tanlash')}
      visible={visible}
      footer={false}
      onCancel={handleCancel}
    >
      <RangePicker
        placeholder={[t('boss_complex.Boshlanish'), t('boss_complex.Tugash')]}
        value={values ? [dayjs(values[0], DATE_FORMAT), dayjs(values[1], DATE_FORMAT)] : null}
        format={DATE_FORMAT}
        onChange={(date, dateString) => {
          handleSelectTime(dateString);
        }}
      />
    </Modal>
  );
};

export default SpecialTimeModal;
