import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';

export function useGetAccountNumbers() {
  return useQuery({
    queryKey: [queryKeys.GET_ACCOUNT_NUMBERS],
    queryFn: async () => {
      const res = await $api.get(endPoints.GET_ACCOUNT_NUMBERS);
      return res.data.data;
    },
    refetchOnWindowFocus: false
  });
}
