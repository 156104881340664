import React from 'react';
import { Segmented as BaseSegment, SegmentedProps as BaseSegmentProps } from 'antd';

export type SegmentProps = BaseSegmentProps;

const Segment = React.forwardRef<HTMLDivElement, SegmentProps>((props, ref) => (
    <BaseSegment {...props} ref={ref} />
));

Segment.displayName = 'Segment';

export default Segment;