import { CrmEmployeeSelect } from 'modules/crm';

interface IProps {
  placeholder?: string;
  onChange?: (value: any) => void;
  value?: any;
}

const EmployeeSelect = ({ onChange, placeholder, value }: IProps) => (
  <CrmEmployeeSelect onChange={onChange} placeholder={placeholder} value={value ? value?.split(',') : []} mode="multiple" />
);

export default EmployeeSelect;
