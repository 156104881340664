import React, { useRef, useState } from 'react';
import { Button, Col, Dropdown, Empty, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import CheveronDownIcon from '../../../../assets/icons/CheveronDownIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import SettingIcon from '../../../../assets/icons/SettingIcon';
import TrushSquareIcon from '../../../../assets/icons/TrushSquareIcon';
import { useChangeStepCard, useDeleteCard } from '../../../../queries/mutation';
import { useGetCards } from '../../../../queries/queries';
import { Card } from '../../../../utils/models/Card';
import { SystemTypeCash } from '../../../../utils/models/Cash';

import AddCardPopover from './addCardPopover/AddCardPopover';
import { TypePage } from './AddCardCont';

import styles from './addCard.module.scss';

interface Props {
  changePage: (page: TypePage) => void;
  handleActivateCard: (card: Card) => void;
  handleChangeStyle: (card: Card) => void;
  id: number;
  name: string;
}

const CardsList: React.FC<Props> = ({ changePage, id, name, handleActivateCard, handleChangeStyle }) => {
  const [openPopover, setOpenPopover] = useState(false);

  const { isLoading, data, isFetching } = useGetCards(id);
  const cardIdRef = useRef(0);

  const deleteCard = useDeleteCard(id);
  const changeStep = useChangeStepCard(id);

  const { t } = useTranslation();

  // handleAddPaymeCard
  const handleAddPaymeCard = () => {
    changePage(TypePage.PAYME);
  };

  // handleAddUzcardCard
  const handleAddUzcardCard = () => {
    changePage(TypePage.MY_UZCARD);
  };

  const getType = (card: Card) => {
    if (card.systemType === SystemTypeCash.MY_UZCARD) {
      return styles.cont_card_uzcard;
    }
    if (card.number.slice(0, 4) === '9860') return styles.cont_card_humo;
    return styles.cont_card_uzcard;
  };

  const getName = () => {
    if (name.length > 17) return `${name.substring(0, 17)  }...`;
    return name;
  };

  const handleDeleteCard = (card: Card) => {
    cardIdRef.current = card.id;
    deleteCard.mutate({
      cardId: card.id,
      type: card.systemType
    });
  };

  const handleChangeStep = (cardId: number, step: number) => {
    changeStep.mutate({
      cardId,
      step
    });
  };

  const handleActivate = (card: Card) => {
    handleActivateCard(card);
  };

  const getStep = (step: number) => {
    if (step === 1) return t('contract.Asosiy');
    return step + t('contract.darajali');
  };

  // handle popover
  const handlePopover = (isOpen: boolean) => {
    setOpenPopover(isOpen);
  };

  return (
    <Spin spinning={isLoading || isFetching || changeStep.isLoading}>
      <Dropdown
        placement="bottomLeft"
        overlay={<AddCardPopover addMyUzcard={handleAddUzcardCard} addPayme={handleAddPaymeCard} />}
        trigger={['click']}
        onVisibleChange={e => handlePopover(e)}
        visible={openPopover}
      >
        <Button onClick={() => handlePopover(true)} className={styles.title_button}>
          <PlusIcon className={styles.title_button_icon} />
          <span>{t('contract.Karta_qoshish')}</span>
        </Button>
      </Dropdown>
      {data?.length === 0 && (
        <>
          <Empty description={<span>{t('contract.Kartalar_mavjud_emas')}</span>} />
        </>
      )}
      <Row className={styles.cont_list_cont}>
        {data?.map((item, index) => (
          <Col key={index} className={styles.cont_list_cont_item}>
            {!item.active && (
              <div onClick={() => handleActivate(item)} className={styles.cont_card_passive_title}>
                {t('contract.Aktivlashtirish')}
              </div>
            )}
            <div className={`${styles.cont_card  } ${styles[`cont_card_${  item.style}`]} ${  item.active ? ' ' : styles.cont_card_passive}`}>
              <div className={getType(item)} />
              <div className={styles.cont_card_body}>
                <div className={styles.cont_card_body_number}>{item.number}</div>
                <div className={styles.cont_card_body_expired}>{item.expire}</div>
                <div className={styles.cont_card_body_name_cont}>
                  <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  <div className={styles.cont_card_body_name_cont_level}>
                    <span>{getStep(item.step)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cont_list_cont_item_footer}>
              {index !== 0 && (
                <>
                  <Dropdown
                    overlay={
                      <div className="cont">
                        <div className="title">{t('contract.Kartadan_pul_yechish_darajasi')}</div>
                        {data?.map((itemSelect, index) => (
                          <div onClick={() => handleChangeStep(item.id, index + 1)} className="item" key={index}>
                            {getStep(itemSelect.step)}
                          </div>
                        ))}
                      </div>
                    }
                    overlayClassName="dropdown_add_card"
                    trigger={['click']}
                  >
                    <div className={styles.cont_list_cont_item_footer_select}>
                      <span>{getStep(item.step)}</span>
                      <CheveronDownIcon />
                    </div>
                  </Dropdown>
                  <div>
                    <SettingIcon onClick={() => handleChangeStyle(item)} />
                    {deleteCard.isLoading && cardIdRef.current === item.id ? <Spin /> : <TrushSquareIcon onClick={() => handleDeleteCard(item)} />}
                  </div>
                </>
              )}
              {index === 0 && (
                <>
                  <div onClick={() => handleChangeStyle(item)} className={styles.cont_list_cont_item_footer_settings}>
                    <SettingIcon onClick={() => handleChangeStyle(item)} />
                    <span>{t('contract.Karta_sozlamalari')}</span>
                  </div>
                  {deleteCard.isLoading && cardIdRef.current === item.id ? (
                    <Spin />
                  ) : (
                    <div onClick={() => handleDeleteCard(item)} className={styles.cont_list_cont_item_footer_delete}>
                      <TrushSquareIcon />
                      <span>{t('contract.Ochirish')}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default CardsList;
