import React from 'react';

const EmptyDashboardIcon = () => (
    <svg width="359" height="221" viewBox="0 0 359 221" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M343.151 36.4492C351.408 36.4492 358.103 43.1247 358.103 51.3592C358.103 59.5938 351.408 66.2692 343.151 66.2692H257.711C265.968 66.2692 272.663 72.9447 272.663 81.1792C272.663 89.4138 265.968 96.0892 257.711 96.0892H304.703C312.96 96.0892 319.655 102.765 319.655 110.999C319.655 119.234 312.96 125.909 304.703 125.909H282.971C272.559 125.909 264.119 132.585 264.119 140.819C264.119 146.309 268.391 151.279 276.935 155.729C285.192 155.729 291.887 162.405 291.887 170.639C291.887 178.874 285.192 185.549 276.935 185.549H99.6466C91.3889 185.549 84.6946 178.874 84.6946 170.639C84.6946 162.405 91.3889 155.729 99.6466 155.729H16.3426C8.08486 155.729 1.39062 149.054 1.39062 140.819C1.39062 132.585 8.08486 125.909 16.3426 125.909H101.783C110.04 125.909 116.735 119.234 116.735 110.999C116.735 102.765 110.04 96.0892 101.783 96.0892H48.3826C40.1249 96.0892 33.4306 89.4138 33.4306 81.1792C33.4306 72.9447 40.1249 66.2692 48.3826 66.2692H133.823C125.565 66.2692 118.871 59.5938 118.871 51.3592C118.871 43.1247 125.565 36.4492 133.823 36.4492H343.151ZM343.151 96.0892C351.408 96.0892 358.103 102.765 358.103 110.999C358.103 119.234 351.408 125.909 343.151 125.909C334.893 125.909 328.199 119.234 328.199 110.999C328.199 102.765 334.893 96.0892 343.151 96.0892Z"
        fill="#00A389"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.194 34.3192L249.105 178.862L250.894 193.363C251.471 198.034 248.142 202.285 243.46 202.857L118.42 218.135C113.738 218.707 109.475 215.383 108.898 210.712L89.5949 54.2713C89.3066 51.9355 90.9707 49.8102 93.3117 49.5241C93.3265 49.5223 93.3413 49.5206 93.3561 49.5189L103.73 48.3613M112.127 47.4592L121.924 46.3654L112.127 47.4592Z"
        fill="white"
      />
      <path
        d="M231.176 34.0476C231.025 32.9534 230.016 32.1879 228.921 32.3379C227.827 32.4879 227.062 33.4965 227.213 34.5908L231.176 34.0476ZM249.105 178.862L251.09 178.618C251.089 178.609 251.087 178.599 251.086 178.59L249.105 178.862ZM250.894 193.363L252.879 193.119L250.894 193.363ZM243.46 202.857L243.704 204.842L243.46 202.857ZM118.42 218.135L118.663 220.12L118.42 218.135ZM108.898 210.712L110.884 210.468L108.898 210.712ZM89.5949 54.2713L91.58 54.0278L89.5949 54.2713ZM93.3561 49.5189L93.5792 51.5064L93.3561 49.5189ZM103.953 50.3488C105.051 50.2263 105.841 49.2372 105.718 48.1395C105.594 47.0418 104.605 46.2513 103.507 46.3738L103.953 50.3488ZM111.904 45.4717C110.806 45.5942 110.016 46.5834 110.139 47.6811C110.263 48.7787 111.252 49.5692 112.35 49.4466L111.904 45.4717ZM122.147 48.3528C123.245 48.2303 124.035 47.2411 123.912 46.1435C123.789 45.0458 122.799 44.2553 121.701 44.3779L122.147 48.3528ZM227.213 34.5908L247.123 179.133L251.086 178.59L231.176 34.0476L227.213 34.5908ZM247.12 179.105L248.909 193.606L252.879 193.119L251.09 178.618L247.12 179.105ZM248.909 193.606C249.35 197.177 246.806 200.434 243.217 200.872L243.704 204.842C249.479 204.137 253.591 198.891 252.879 193.119L248.909 193.606ZM243.217 200.872L118.176 216.15L118.663 220.12L243.704 204.842L243.217 200.872ZM118.176 216.15C114.586 216.588 111.324 214.04 110.884 210.468L106.913 210.955C107.625 216.727 112.889 220.825 118.663 220.12L118.176 216.15ZM110.884 210.468L91.58 54.0278L87.6097 54.5149L106.913 210.955L110.884 210.468ZM91.58 54.0278C91.4275 52.7925 92.3076 51.6617 93.5556 51.5092L93.0678 47.5391C89.6339 47.9586 87.1857 51.0786 87.6097 54.5149L91.58 54.0278ZM93.5556 51.5092C93.5635 51.5082 93.5713 51.5073 93.5792 51.5064L93.1329 47.5315C93.1112 47.5339 93.0895 47.5364 93.0678 47.5391L93.5556 51.5092ZM93.5792 51.5064L103.953 50.3488L103.507 46.3738L93.1329 47.5315L93.5792 51.5064ZM112.35 49.4466L122.147 48.3528L121.701 44.3779L111.904 45.4717L112.35 49.4466Z"
        fill="#00A389"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.837 43.4132L241.821 174.374L243.438 187.512C243.96 191.744 240.982 195.592 236.788 196.105L124.772 209.821C120.577 210.334 116.755 207.319 116.234 203.087L98.5491 59.4616C98.4141 58.3653 99.1935 57.3677 100.29 57.2335L116.402 55.2607"
        fill="#E6F6F4"
      />
      <path
        d="M130.852 8.50001C130.852 7.39543 131.747 6.5 132.852 6.5H236.589C237.119 6.5 237.626 6.70977 238.001 7.08336L269.375 38.35C269.752 38.7253 269.964 39.235 269.964 39.7666V179.42C269.964 180.525 269.068 181.42 267.964 181.42H132.852C131.747 181.42 130.852 180.525 130.852 179.42V8.50001Z"
        fill="white"
        stroke="#00A389"
        strokeWidth="4"
      />
      <path
        d="M235.652 9.61719V34.3192C235.652 37.8483 238.521 40.7092 242.06 40.7092H259.006"
        stroke="#00A389"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.047 149.341H208.583M153.047 40.7109H208.583H153.047ZM153.047 66.2709H244.895H153.047ZM153.047 93.9609H244.895H153.047ZM153.047 121.651H244.895H153.047Z"
        stroke="#00A389"
        strokeOpacity="0.5"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default EmptyDashboardIcon;
