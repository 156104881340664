import React from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { ResponsiveLine } from '@nivo/line';
import { dateFormatTimeLine } from '@pages/dashboard/utils/helper/DateFormatTimeLine';
import { ChartDataModel } from '@pages/dashboard/utils/models/ChartDataModel';
import { TimeLineDataModel } from '@pages/dashboard/utils/models/TimeLineDataModel';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { CrmLeadList } from '@utils/models/CrmLeadList';
import { Empty, Spin } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';

import styles from './timelineWidget.module.scss';

type Props = {
  data: (CrmLeadList & ChartDataModel & TimeLineDataModel) | undefined;
  loading: boolean;
};

const TimeLineWidget: React.FC<Props> = ({ data, loading }) => {
  const { searchParams } = useQueryParam<any, any>();
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const lineData = data?.result
    ? [
        {
          id: 'gradient',
          color: '#00A389',
          data:
            data?.result &&
            data?.result?.map((item, index) => ({
              key: index,
              x: item?.time!,
              y: Number(item?.count!)
            }))
        }
      ]
    : [];

  const diffDate = dayjs(searchParams?.finishDate, DATE_FORMAT).diff(dayjs(searchParams?.startDate, DATE_FORMAT), 'day');

  const dateFormat = diffDate && (diffDate > 30 ? 'MONTH' : 'DAY');

  const test = data?.result
    ? [
        {
          id: 'gradient',
          color: '#00A389',
          data: (
            dateFormatTimeLine(String(dateFormat), data?.result, {
              startDate: searchParams?.startDate || '',
              finishDate: searchParams?.finishDate || ''
            }) || []
          )?.map((item, index) => ({ key: index, x: item?.time, y: item?.count }))
        }
      ]
    : [];

  return (
    <Spin spinning={loading}>
      <div className={styles.timeLine} style={{ height: 350, cursor: updateWidget ? 'grab' : '' }}>
        {lineData && lineData[0]?.data?.length > 0 ? (
          <ResponsiveLine
            data={searchParams?.startDate && searchParams?.finishDate ? test || [] : lineData || []}
            // data={[]}
            margin={{ top: 50, right: 60, bottom: 30, left: 60 }}
            colors={{ datum: 'color' }}
            lineWidth={2}
            enablePoints={false}
            enableGridY={true}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 25,
              format: e => dayjs(e, DATE_WITH_TIME_FORMAT).format('DD MMM')
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0
            }}
            enableArea
            areaOpacity={1}
            curve="linear"
            defs={[
              {
                colors: [
                  {
                    color: 'inherit',
                    offset: 0,
                    opacity: 0.6
                  },
                  {
                    color: 'inherit',
                    offset: 100,
                    opacity: 0
                  }
                ],
                id: 'gradient',
                type: 'linearGradient'
              }
            ]}
            fill={[
              {
                id: 'gradient',
                match: '*'
              }
            ]}
            useMesh={true}
            tooltip={e => (
              <div className={styles.tooltip}>
                <span>
                  {t('crm.date')}: {e.point?.data?.x}
                </span>
                <span>
                  {t('home.Barchasi')}: {e.point?.data?.y}
                </span>
              </div>
            )}
          />
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Empty />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default TimeLineWidget;
