import React from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { ResponsiveLine } from '@nivo/line';
import { useGetCallsDuration } from '@pages/one-campagin/services/queries';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

const CallsDuration = () => {
  const { t } = useTranslation();
  const { params } = useQueryParam<any, any>();
  const { data } = useGetCallsDuration(Number(params?.robotoCallsId));

  const lineData = data && [
    {
      id: 'gradient',
      color: '#00A389',
      data:
        data &&
        data?.map((item, index) => ({
          key: index,
          x: item?.duration!,
          y: Number(item?.count!)
        }))
    }
  ];

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // eslint-disable-next-line no-nested-ternary
    return `${minutes > 0 && minutes < 10 ? `0${minutes}` : minutes > 10 ? minutes : '00'}:${remainingSeconds > 0 && remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds > 10 ? remainingSeconds : '00'}`;
  }

  return (
    <div className="flex flex-col rounded-xl bg-white">
      <div className="w-full border-b border-l-0 border-r-0 border-t-0 border-solid border-b-slate-200 p-4 text-lg font-medium text-[#344054]">
        {t('home.Qongroqlar_statistikasi')}
      </div>
      {data && data?.length > 0 ? (
        <div className="h-[260px] min-w-[350px]">
          {/* @ts-ignore */}
          <ResponsiveLine
            data={lineData || []}
            margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
            colors={{ datum: 'color' }}
            lineWidth={2}
            enablePoints={false}
            enableGridY={true}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              format: e => formatTime(e)
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0
            }}
            enableArea
            areaOpacity={1}
            curve="linear"
            defs={[
              {
                colors: [
                  {
                    color: 'inherit',
                    offset: 0,
                    opacity: 0.6
                  },
                  {
                    color: 'inherit',
                    offset: 100,
                    opacity: 0
                  }
                ],
                id: 'gradient',
                type: 'linearGradient'
              }
            ]}
            fill={[
              {
                id: 'gradient',
                match: '*'
              }
            ]}
            useMesh={true}
            tooltip={e => (
              <div className="flex flex-col rounded-lg bg-white p-2 text-sm shadow-md">
                <span>Vaqt: {formatTime(Number(e.point?.data?.x))}</span>
                <span>Soni: {e.point?.data?.y}</span>
              </div>
            )}
          />
        </div>
      ) : (
        <div className="flex h-full flex-col items-center justify-center py-10">
          <Empty />
        </div>
      )}
    </div>
  );
};

export default CallsDuration;
