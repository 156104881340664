import React, { useEffect, useRef } from 'react';
import Edit2Icon from '@icons/Edit2Icon';
import { cx } from '@utils/cx';
import { isEmptyArr } from '@utils/index';
import { Collapse, Form, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import LogoutIconMessengerIcon from '../../../../../assets/icons/LogoutIconMessengerIcon';
import { useDeleteMyCalls, useMyCallsSetEmployee } from '../../../../../queries/mutation';
import { useMyCallsEmployee, usePipeAll } from '../../../../../queries/queries';
import { MyCalls } from '../../../../../utils/models/MyCalls';

import SettingsMyCall from './settings-my-call';

import styles from './myCalls.module.scss';

const { Option } = Select;
const { Panel } = Collapse;

interface Props {
  data: MyCalls;
}

const EditMyCall: React.FC<Props> = ({ data }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const selectedEmployee = useRef(0);
  const { data: pipeAll } = usePipeAll();
  const deleteMyCalls = useDeleteMyCalls(data.id);
  const setEmployeeMutation = useMyCallsSetEmployee();
  const { data: employee, isLoading } = useMyCallsEmployee();

  // handle delete my calls
  const handleDeleteMyCalls = () => {
    deleteMyCalls.mutate();
  };

  // set employee
  const setEmployee = (id: number, email: string, index: number, pipeId: number) => {
    selectedEmployee.current = index;

    setEmployeeMutation.mutate({
      employeeId: id,
      email,
      moiZId: data.id,
      pipeDto: {
        pipeId
      }
    });
  };

  useEffect(() => {
    if (data.employees) {
      form.setFieldsValue({
        pipeStatusDto: data.employees?.map(({ pipeStatusResponse, employee }) => ({
          ...pipeStatusResponse,
          pipeId: pipeStatusResponse?.pipeId,
          employeeId: employee?.id,
          incomingTagsId: !isEmptyArr(pipeStatusResponse?.incomingTags) ? pipeStatusResponse?.incomingTags?.map(({ id }) => id) : undefined,
          outgoingTagsId: !isEmptyArr(pipeStatusResponse?.outgoingTags) ? pipeStatusResponse?.outgoingTags?.map(({ id }) => id) : undefined,
          missingTagsId: !isEmptyArr(pipeStatusResponse?.missingTags) ? pipeStatusResponse?.missingTags?.map(({ id }) => id) : undefined
        }))
      });
    }
  }, [data, form]);

  return (
    <Spin spinning={deleteMyCalls.isLoading || isLoading}>
      <Form layout="vertical" name="myCalls" form={form}>
        <div className={styles.container_body_item}>
          <span>{t('my_calls.API_manzilingiz')}</span>
          <div className={styles.container_body_item_name}>
            <p>{data.domain}</p>
            <div onClick={handleDeleteMyCalls} className={styles.container_body_item_name_log_out}>
              <LogoutIconMessengerIcon />
              <span>{t('my_calls.Chiqish')}</span>
            </div>
          </div>
        </div>
        <hr />
        <p className={styles.container_body_sub_title}>{t('my_calls.Xodimlarni sozlash')}</p>
        <p className={styles.container_body_information}>{t('my_calls.Moi_zvonki_platformasi_va_Uysot_platformasidagi_foydalanuvchilarni_birlashtirish')}</p>
        <Collapse
          ghost
          collapsible="header"
          expandIconPosition="right"
          expandIcon={() => (
            <span className="cursor-pointer">
              <Edit2Icon />
            </span>
          )}
        >
          {data?.employees?.map((emp, index) => (
            <Panel
              key={emp?.email}
              header={
                <Spin key={emp?.moiZConfigId} spinning={setEmployeeMutation.isLoading && selectedEmployee.current === index}>
                  <div className="w-full" onClick={e => e.stopPropagation()}>
                    <div className={cx(styles.container_body_item, 'flex items-center gap-4')}>
                      <span>{emp.fullName}</span>
                      <div className="flex items-center gap-2">
                        <Form.Item name={['pipeStatusDto', index, 'employeeId']}>
                          <Select
                            showSearch
                            showArrow={false}
                            value={emp.employee?.id}
                            optionFilterProp="children"
                            placeholder={t('home.Tanlang')}
                            className={`customSelect ${styles.container_body_item_select}`}
                            onChange={id => setEmployee(id, emp.email, index, Number(emp.pipeStatusResponse?.pipeId))}
                          >
                            {employee?.map(item => (
                              <Option value={item.id} key={item?.id}>
                                {item.fullName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item name={['pipeStatusDto', index, 'pipeId']} noStyle>
                          <Select placeholder={t('home.Tanlang')} className="customSelect w-40" onChange={e => setEmployee(emp.employee.id, emp.email, index, e)}>
                            {pipeAll?.map(({ id, name }) => (
                              <Option key={id} value={id}>
                                {name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <hr />
                  </div>
                </Spin>
              }
              children={<SettingsMyCall dataId={data?.id} emp={emp} index={index} form={form} />}
            />
          ))}
        </Collapse>
        {/* {data.employees.map((emp, index) => (
          <Spin key={emp?.moiZConfigId} spinning={setEmployeeMutation.isLoading && selectedEmployee.current === index}>
            <div>
              <div className={styles.container_body_item}>
                <span>{emp.fullName}</span>
                <Select
                  showSearch
                  showArrow={false}
                  value={emp.employee?.id}
                  optionFilterProp="children"
                  placeholder={t('home.Tanlang')}
                  className={`customSelect ${styles.container_body_item_select}`}
                  onChange={id => setEmployee(id, emp.email, index, Number(emp.pipeStatusResponse?.pipeId))}
                >
                  {employee?.map(item => (
                    <Option value={item.id} key={item?.id}>
                      {item.fullName}
                    </Option>
                  ))}
                </Select>
                <Form.Item name={`status${index}`} noStyle>
                  <Select placeholder={t('home.Tanlang')} className="customSelect w-40" onChange={e => setEmployee(emp.employee.id, emp.email, index, e)}>
                    {pipeAll?.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <span className="cursor-pointer">
                  <Edit2Icon />
                </span>
              </div>
              <hr />
            </div>
          </Spin>
        ))} */}
      </Form>
    </Spin>
  );
};

export default EditMyCall;
