import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const ArchiveTickIcon: FC<IProps> = ({ color = '#000000', onClick, className }) => {
  return (
    <svg onClick={onClick} className={className} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.93006 22.75C4.51006 22.75 4.12006 22.65 3.77006 22.45C3.00006 22 2.56006 21.09 2.56006 19.96V5.86C2.56006 3.32 4.63006 1.25 7.17006 1.25H16.8201C19.3601 1.25 21.4301 3.32 21.4301 5.86V19.95C21.4301 21.08 20.9901 21.99 20.2201 22.44C19.4501 22.89 18.4401 22.84 17.4501 22.29L12.5701 19.58C12.2801 19.42 11.7101 19.42 11.4201 19.58L6.54006 22.29C6.00006 22.59 5.45006 22.75 4.93006 22.75ZM7.18006 2.75C5.47006 2.75 4.07006 4.15 4.07006 5.86V19.95C4.07006 20.54 4.24006 20.98 4.54006 21.15C4.84006 21.32 5.31006 21.27 5.82006 20.98L10.7001 18.27C11.4401 17.86 12.5601 17.86 13.3001 18.27L18.1801 20.98C18.6901 21.27 19.1601 21.33 19.4601 21.15C19.7601 20.97 19.9301 20.53 19.9301 19.95V5.86C19.9301 4.15 18.5301 2.75 16.8201 2.75H7.18006Z"
        fill={color}
      />
      <path
        d="M11.09 13.2499C10.9 13.2499 10.71 13.1799 10.56 13.0299L9.06003 11.5299C8.77003 11.2399 8.77003 10.7599 9.06003 10.4699C9.35003 10.1799 9.83003 10.1799 10.12 10.4699L11.09 11.4399L14.56 7.96994C14.85 7.67994 15.33 7.67994 15.62 7.96994C15.91 8.25994 15.91 8.73994 15.62 9.02994L11.62 13.0299C11.47 13.1799 11.28 13.2499 11.09 13.2499Z"
        fill={color}
      />
    </svg>
  );
};

export default ArchiveTickIcon;
