import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useLeadGroup } from '@pages/crm/services/mutation';
import { useGetLeadDuplicate } from '@pages/oneLid/services/queries';
import { setClearListView } from '@reducers/CrmSlice';
import { BaseEntity } from '@utils/models/BaseEntity';
import { ContactCrm, FieldValue, OneLid } from '@utils/models/OneLid';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import DublicateControllField from '@pages/crm/components/listView/actions/dublicate-controll-modal/DublicateControllField';
import DublicateControllFieldAll from '@pages/crm/components/listView/actions/dublicate-controll-modal/DublicateControllFieldAll';

import styles from '../../../../crm/components/listView/actions/dublicate-controll-modal/dublicateControllModal.module.scss';
import { useIgnoreDublicateLeads } from '@pages/oneLid/services/mutation';
import { Permissions } from '@utils/constants/permissions';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const DuplicateModal: FC<IProps> = ({ setVisible, visible }) => {
  const { t } = useTranslation();
  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);
  const user = useAppSelector(state => state.userReducer.user);
  const params = useParams();
  const { data } = useGetLeadDuplicate(Number(params?.id));
  const [dublicate, setDublicate] = useState<OneLid | undefined>();
  const [fields, setFields] = useState<FieldValue[]>([]);
  const [propsData, setPropsData] = useState(data);
  const leadGroup = useLeadGroup();
  const dispatch = useAppDispatch();
  const ignoreLeads = useIgnoreDublicateLeads();

  const handleCancle = () => {
    setVisible(false);
    // dispatch(setClearListView());
    setPropsData(data);
  };

  useEffect(() => {
    setPropsData(data);
  }, [data]);

  useEffect(() => {
    if (propsData && propsData?.length > 0) {
      let contacts: ContactCrm[][] = [];
      propsData?.forEach((item: any) => {
        contacts.push(...item?.contacts);
      });

      let fields: FieldValue[] = [];
      let tags: BaseEntity[] = [];
      propsData?.forEach(item => {
        item?.tags?.forEach(tag => {
          if (!tags.some(val => val?.id === tag?.id)) {
            tags.push(tag);
          }
        });
        item?.fields?.forEach((field: any) => {
          if (!fields?.some(val => val?.id === field?.id)) {
            fields?.push(field);
          }
        });
      });

      setFields(fields);
      setDublicate({
        ...propsData[0],
        responsibleById: propsData?.find(item => item?.responsibleById)?.responsibleById,
        responsibleBy: propsData?.find(item => item?.responsibleBy)?.responsibleBy || '',
        contacts: contacts?.flat(),
        fields: fields,
        tags: tags
      });
    }
  }, [propsData]);

  const notKeys = ['id', 'isMoiZvonki', 'responsibleBy'];

  const newLeadIds = propsData?.map(item => {
    return item?.id;
  });

  // is access dublicate merge
  const isAccessMerge = user?.role?.permissions?.includes(Permissions.PERMISSION_CRM_LEAD_GROUP_FUNCTION);

  const handleSave = () => {
    leadGroup
      .mutateAsync({
        leadIds: activeIds?.length > 0 ? activeIds : newLeadIds,
        requestMethodType: 'LEAD_MERGE',
        leadMergeDto: {
          leadId: params?.id ? Number(params?.id) : dublicate?.id,
          leadName: dublicate?.name,
          balance: dublicate?.balance,
          responsibleById: dublicate?.responsibleById,
          pipeStatusId: dublicate?.pipeStatusResponse?.id,
          contactIdList: dublicate?.contacts?.map(item => item?.id!),
          createdDate: dublicate?.createdDate,
          fields: dublicate?.fields,
          tagIdList: dublicate?.tags?.map(item => item?.id!)
        }
      })
      .then(() => {
        handleCancle();
      });
  };

  const dataKeys = () => {
    let arr = ['name', 'responsibleById', 'createdDate', 'balance', 'pipeStatusResponse', 'fields', 'tags', 'contacts'];
    if (data && data?.length > 0) {
      Object.keys(data[0])?.forEach(item => {
        if (!arr.includes(item)) {
          arr.push(item);
        }
      });
    }
    return arr;
  };

  const ignoreLeadIds: any = propsData?.map(item => {
    return item?.id;
  });

  const handleIgnoreLeads = (id: number) => {
    ignoreLeads
      .mutateAsync({
        ignoreObjectIds: ignoreLeadIds,
        currentObjectId: id,
        ignoreType: 'LEAD'
      })
      .then(handleCancle);
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancle}
      title={t('one_lid.Potensial_mijozlarni_birlashtirish')}
      width={1100}
      footer={false}
      wrapClassName={styles.modal}
    >
      <div className={styles.container}>
        <p className={styles.container_title}>{t('one_lid.Quyida_dublikat_yol_yoriqlar_toplami_keltirilgan')}</p>
        <div className={styles.body}>
          <div className={styles.body_content}>
            {data &&
              data?.length > 0 &&
              dataKeys()?.map(
                (key, i) =>
                  !notKeys.includes(key) && (
                    <DublicateControllField
                      key={i}
                      {...data[0]!}
                      dublicate={dublicate}
                      setDublicate={setDublicate}
                      data={propsData}
                      keyObj={key}
                      index={i}
                      fieldsArr={fields}
                      from="lead"
                      setPropsData={setPropsData}
                    />
                  )
              )}
          </div>
          <DublicateControllFieldAll dublicate={dublicate} fieldsArr={fields} />
        </div>
        <div className={styles.container_footer}>
          <Button onClick={handleCancle}>{t('home.Yopish')}</Button>
          <Button
            onClick={() => {
              handleIgnoreLeads(Number(params?.id));
            }}
            loading={ignoreLeads?.isLoading}
            className={styles.ignoreBtn}
            disabled={!isAccessMerge || propsData?.length! === data?.length!}
          >
            {t('home.Takroriy_emas')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              isAccessMerge && handleSave();
            }}
            loading={leadGroup.isLoading}
            disabled={!isAccessMerge}
          >
            {t('home.Saqlash')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateModal;
