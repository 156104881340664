import React from 'react';
import { Spin } from 'antd';

import styles from './mainInformation.module.scss';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useOneTaskContractInformation } from '../../queries/queries';
import { useTranslation } from 'react-i18next';

const MainInformation = () => {
  const { params } = useQueryParam();

  const { t } = useTranslation();

  const { isLoading, isFetching, data } = useOneTaskContractInformation(Number(params.contractId));

  return (
    <div className={styles.container}>
      <Spin spinning={isLoading || isFetching}>
        <div className={styles.header}>
          <h2 className={styles.header_title}>{t('one_task.Shartnoma')}</h2>
          <p className={styles.header_value}>{data?.number}</p>
        </div>
        <div className={styles.body}>
          <h3 className={styles.body_main_title}>{t('one_task.Asosiy_malumotlar')}</h3>
          <div className={styles.body_item}>
            <div className={styles.body_item_title}>{t('one_task.Mijoz')}</div>
            <div className={styles.body_item_value_light}>{params?.clientName}</div>
          </div>
          <div className={styles.body_item}>
            <div className={styles.body_item_title}>{t('one_task.Shartnoma_summasi')}</div>
            <div className={styles.body_item_value_dark}>
              {data?.amount.toLocaleString('ru')} {data?.currency?.ccy}
            </div>
          </div>
          <div className={styles.body_item}>
            <div className={styles.body_item_title}>{t('one_task.Boshlangich_tolov')}</div>
            <div className={styles.body_item_value_dark}>
              {data?.prepayment.toLocaleString('ru')} {data?.currency?.ccy}
            </div>
          </div>
          <div className={styles.body_item}>
            <div className={styles.body_item_title}>{t('one_task.Tolangan_summa')}</div>
            <div className={styles.body_item_value_light}>
              {data?.payed.toLocaleString('ru')} {data?.currency?.ccy}
            </div>
          </div>
          <div className={styles.body_item}>
            <div className={styles.body_item_title}>{t('one_task.Oylik_qarzdorlik')}</div>
            <div className={styles.body_item_value_light}>
              {data?.debt.toLocaleString('ru')} {data?.currency?.ccy}
            </div>
          </div>
          <hr className={styles.body_line} />
          <div className={styles.body_item}>
            <div className={styles.body_item_title}>{t('one_task.Masul_hodim')}</div>
            <div className={styles.body_item_value_light}>{data?.createdBy}</div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default MainInformation;
