/* eslint-disable no-else-return */
import { LocalStorage } from "@service/LocalStorage";
import { $api } from "@service/RequestService";
import { getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyAgjBYk6NoAolmEcR9QIT_WTyRge4IeNtI",
    authDomain: "uysot-test-8aedb.firebaseapp.com",
    projectId: "uysot-test-8aedb",
    storageBucket: "uysot-test-8aedb.appspot.com",
    messagingSenderId: "1093555599833",
    appId: "1:1093555599833:web:b80249ed4ec46425ac01c8",
    measurementId: "G-ZD11P0LVZ7"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();
let updateToken = true

export const app = !getApps()?.length
    ? initializeApp(firebaseConfig)
    : getApps()[0];


export const getMessagingObj = async () => {
    const supported = await isSupported();

    // eslint-disable-next-line no-console
    console.log("is supported fcm? >>", supported);

    if (!supported || typeof window === "undefined") return null;
    return getMessaging(app);
};


export const fetchToken = async () => {
    const isPermittedNotifs = LocalStorage.get('isPermittedNotifs');

    if (!isPermittedNotifs) {
        console.log("❗The token is not requested.");
        return null;
    }

    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
        console.log("❎The user has rejected the notification permission.");
        return null;
    }

    console.log("✅Permission for notifications has been received.");

    try {
        const messaging = await getMessagingObj();

        if (messaging) {
            const token = await getToken(messaging, {
                vapidKey: "BA5308qymVj-9pNlBnAREUImPKAp6v8iGBd5U-tKZ7rGGePRFjZMGloVnN1FXPQJ-oXt6EXuIwp6-dGAXLziwFY",
            }).then((currentToken) => {
                if (currentToken) {
                    if (updateToken || LocalStorage.get('firebaseToken') !== currentToken) {
                        $api.put(`/v1/push-notification/config/update-token?newToken=${currentToken}&oldToken=${LocalStorage.get('firebaseToken')}`, {
                            newToken: currentToken,
                            oldToken: LocalStorage.get('firebaseToken')
                        })
                        updateToken = false
                    }

                    LocalStorage.set('firebaseToken', currentToken);
                } else {
                    // eslint-disable-next-line no-console
                    console.log("⚠️No registration token available!");
                }
            })
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.log("❌Error while register token!", err);
                });

            return token;
        }
        return null;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        return null;
    }
};

export const onMessageListener = () => new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        // eslint-disable-next-line no-console
        console.log("⚙️OnMessage Payload", payload);

        resolve(payload);
    })
})