import React, { useEffect, useState } from 'react';
import styles from './leadStatistic.module.scss';
import LeadStatisticHeader from './lead-statistic-header/LeadStatisticHeader';
import LeadStatisticLine from './lead-statistic-line/LeadStatisticLine';
import LeadStatisticTransactions from './lead-statistic-transactions/LeadStatisticTransactions';
import { useStatLead, useStatLeadDate } from '../../services/queries';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';
import { Spin } from 'antd';
import { DateFormatType } from '../../../../utils/constants/DateFormatType';
import { useQueryParam } from '@hooks/useQueryParams';
import { CallStatisticsParam } from '../header/Header';
import { BoosComplexSelectType } from '@utils/models/BoosComplexSelectType';
import { finishDateNotUtc, startDateNotUtc } from '../callStatistics/startAndFinishDates';

const LeadStatistic = () => {
  const { searchParams } = useQueryParam<CallStatisticsParam, any>();
  const [date, setDate] = useState({
    startDate: dayjs().date(1).format(DATE_FORMAT),
    finishDate: dayjs().endOf('month').format(DATE_FORMAT)
  });
  const [dateType, setDateType] = useState<DateFormatType>('DAY');
  const { data, isLoading } = useStatLead(
    Number(searchParams?.pipeStatus) || null,
    searchParams?.employee,
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.startDate, DATE_FORMAT).hour(0).minute(0).format(DATE_WITH_TIME_FORMAT)
      : dayjs.unix(startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc]).format(DATE_WITH_TIME_FORMAT),
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.finishDate, DATE_FORMAT).hour(23).minute(59).format(DATE_WITH_TIME_FORMAT)
      : dayjs.unix(finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc]).format(DATE_WITH_TIME_FORMAT)
  );

  const {
    data: dataDate,
    isLoading: dataDateIsloading,
    isFetching
  } = useStatLeadDate(
    Number(searchParams?.pipeStatus) || null,
    Number(searchParams?.employee),
    dayjs(date.startDate, DATE_FORMAT).format(DATE_WITH_TIME_FORMAT),
    dayjs(date.finishDate, DATE_FORMAT).format(DATE_WITH_TIME_FORMAT),
    dateType
  );

  // useEffect(() => {
  //   if (pipeAll && pipeAll?.length > 0) {
  //     dispatch(setBoss2StatsPipe(pipeAll[0]?.id))
  //   }
  // }, [pipeAll])

  console.log(searchParams);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Spin spinning={dataDateIsloading || isFetching}>
          <div className={styles.main}>
            <LeadStatisticHeader setDateType={setDateType} dateType={dateType} dataDate={dataDate} date={date} setDate={setDate} />
            <LeadStatisticLine data={dataDate} dateType={dateType} date={date} />
          </div>
        </Spin>
        <Spin spinning={isLoading}>
          <LeadStatisticTransactions data={data} />
        </Spin>
      </div>
    </div>
  );
};

export default LeadStatistic;
