import React from 'react';

const NotImageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0,0,256,256" {...props}>
    <g
      fill="#ff0000"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
    >
      <g transform="scale(10.66667,10.66667)">
        <path d="M2.66992,1.56055c-0.25325,0 -0.50572,0.09752 -0.69922,0.29102l-0.01562,0.01367c-0.387,0.387 -0.387,1.01439 0,1.40039l1.02344,1.02344c-0.58401,0.34913 -0.97852,0.98072 -0.97852,1.71094v12c0,1.105 0.895,2 2,2h14.69141l1.39063,1.39063c0.387,0.387 1.01339,0.387 1.40039,0l0.01367,-0.01367c0.387,-0.387 0.387,-1.01339 0,-1.40039l-18.12695,-18.125c-0.1935,-0.1935 -0.44597,-0.29102 -0.69922,-0.29102zM8.33789,4l13.66211,13.66211v-11.66211c0,-1.105 -0.895,-2 -2,-2zM18,8c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1zM8.50391,12.79883c0.14475,-0.00225 0.29058,0.05669 0.39258,0.17969l2.10352,2.53125l1.39844,-1.80274l3.29297,3.29297h-9.66992c-0.416,0 -0.64858,-0.47864 -0.39258,-0.80664l2.48828,-3.20313c0.098,-0.126 0.24197,-0.18916 0.38672,-0.19141z" />
      </g>
    </g>
  </svg>
);

export default NotImageIcon;
