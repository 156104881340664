import { Icon } from '@components/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, Input, Modal } from 'global';
import { useTranslation } from 'react-i18next';
import { makeid } from 'utils';

import { brojectSchema, useBrojectConfig, useBrojectConfigSave } from 'modules/broject';

interface IProps {
  children: React.ReactElement;
}

const BrojectConfig = ({ children }: IProps) => {
  const { data } = useBrojectConfig();
  const save = useBrojectConfigSave();
  const { t } = useTranslation();

  const onFinish = (values: brojectSchema.IBrojectConfig) => {
    save.mutate({
      ...values,
      status: true
    });
  };

  return (
    <Modal openChild={children} title={t('integration.broject')} footer={false}>
      {({ onCancel }) => (
        <Form
          resolver={zodResolver(brojectSchema.brojectConfig)}
          values={{
            token: makeid(40),
            ...data
          }}
          onFinish={onFinish}
        >
          {({ getValues, setValue }) => (
            <>
              <Form.Item name="baseUrl" label={t('integration.Url_manzili')}>
                <Input />
              </Form.Item>
              <div className="flex  gap-[6px]">
                <Form.Item name="token" label="X-Auth">
                  <Input
                    readOnly
                    className="[&>input]:text-[#98A2B3]"
                    suffix={<Icon name="copy" className="cursor-pointer text-[#475467]" />}
                    onClick={() => navigator.clipboard.writeText(getValues('token'))}
                  />
                </Form.Item>
                <Form.Item label="&nbsp;" className="w-auto">
                  <div
                    className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[6px] bg-[#F2F4F7]"
                    onClick={() => {
                      setValue('token', makeid(40));
                    }}
                  >
                    <Icon name="ReceiveSquare" className="text-[#475467]" pathProps={{ strokeWidth: 1.5 }} />
                  </div>
                </Form.Item>
              </div>
              <div className="flex justify-end gap-[19px]">
                <Button className="h-[32px] w-[142px] rounded-[50px]" onClick={onCancel}>
                  {t('home.Bekor_qilish')}
                </Button>
                <Button className="h-[32px] w-[142px] rounded-[50px]" type="primary" htmlType="submit" loading={save.isLoading}>
                  {t('home.Saqlash')}
                </Button>
              </div>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default BrojectConfig;
