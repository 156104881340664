import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
  taskCount: number;
  messageDrawerVisible: boolean;
}

const initialState: NotificationState = {
  taskCount: 0,
  messageDrawerVisible: false
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setTaskCount: (state, action: PayloadAction<number>) => {
      state.taskCount = action.payload;
    },
    setMessageDrawerVisible: (state, action: PayloadAction<boolean>) => {
      state.messageDrawerVisible = action.payload;
    }
  }
});

export const { setTaskCount, setMessageDrawerVisible } = notificationSlice.actions;
export default notificationSlice.reducer;
