export const endPoints = {
  SMART_CATALOG_SHOWROOM: '/v1/smart-catalog/showroom',
  SMART_CATALOG_HOUSE: '/v1/smart-catalog/house',
  SMART_CATALOG_FLAT_ID: (houseId: number) => `/v1/smart-catalog/flat/${houseId}`,
  SMART_CATALOG_TABLE: '/v1/smart-catalog/table',
  SMART_CATALOG_FILTER_PROPERTIES: (houseId: number) => `/v1/smart-catalog/filter-properties/${houseId}`,
  SMART_CATALOG_X_AUTH_TOKEN: '/v1/smart-catalog/x-auth-token',
  SMART_CATALOG_BUILDING_ID: (houseId: number) => `/v1/smart-catalog/building/${houseId}`,
  WEBSITE_BLOCK_STATISTIC: '/v1/website/block-statistic',
  SMART_CATALOG_TABLE_EXCEL: '/v1/smart-catalog/table/excel',
  SMART_CATALOG_TABLE_SUMMARY_EXCEL: '/v1/smart-catalog/table/summary/excel',
  SMART_CATALOG_TABLE_SUMMARY_EXCEL_BETA: '/v1/smart-catalog/table/summary/excel/beta'
};
