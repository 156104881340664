import React from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

import { CallStatisticsParam } from '../../header/Header';
import FIlterIcon from '../assets/icons/FIlterIcon';

export type Props = {
  onClick?: () => void;
};

const FilterButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<CallStatisticsParam, any>();

  const { callType, ...rest } = searchParams;

  const badge = () => {
    if (Object.values(rest).length === 1 && rest.date === 'THIS_MONTH') return null;
    if (Object.values(rest!).length === 1 || Object.values(rest!).length > 1)
      return (
        <span
          className="
          flex 
          items-center 
          justify-center 
          rounded-[16px] 
          px-[10px] 
          py-[1px] 
          text-[14px]
           font-medium leading-[16px] text-white"
          style={{
            backgroundColor: '#00A389',
            border: '1px solid #00A389'
          }}
        >
          {rest.date === 'THIS_MONTH' ? Object.values(rest).length - 1 : Object.values(rest!).length}
        </span>
      );
    return null;
  };

  return (
    <div className="flex cursor-pointer items-center gap-[12px] text-[14px] font-medium leading-[16px]" onClick={onClick}>
      <FIlterIcon color="red" /> {t('crm_filter.Filtr')} {badge()}
    </div>
  );
};

export default FilterButton;
