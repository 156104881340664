import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { getTaskBody } from '@utils/helper/getSearchAndDownloadBodyAllTables';
import { AutoTaskSetting, AutoTaskSettingPut } from '@utils/models/AutoTaskSetting';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { TaskActionsModel } from '../utils/models/TaskActionsModel';

export function useAutoTaskCreate(onSuccess: (e: AutoTaskSetting) => void) {
  return useMutation(
    async () => {
      const res = await $api.get(endPoints.AUTO_TASK_SETTING);

      return res.data;
    },
    {
      onSuccess: data => {
        onSuccess(data.data);
      },
      onError: baseErrorHandler
    }
  );
}

export function useAutoTaskUpdate() {
  return useMutation(
    async (data: AutoTaskSettingPut) => {
      const res = await $api.post(endPoints.AUTO_TASK_SETTING, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadTaskActions(searchParams: SearchParamsTask) {
  const qc = useQueryClient();

  return useMutation(
    async (data: TaskActionsModel) => {
      const res = await $api.post(endPoints.TASK_ACTIONS, {
        filter: getTaskBody(searchParams),
        actionType: data?.actionType,
        ignoreTasks: data?.ignoreTasks,
        tasks: data?.tasks,
        reassignId: data?.reassignId,
        taskTypeId: data?.taskTypeId,
        closedText: data?.closedText,
        rescheduleDate: data?.rescheduleDate
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.TABLE_VIEW_TASK]);
      },
      onError: baseErrorHandler
    }
  );
}
