import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { PipeStatusesType } from '@utils/models/PipeAll';
import { Spin } from 'antd';

import { useSearchLeadTask } from '../../../../../queries/queries';

import styles from './crmSearch.module.scss';

type Props = {
  foo: string | null;
  type?: string;
};

const CrmSearch: React.FC<Props> = ({ foo, type }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolling, setScrolling] = useState(true);
  const { data: search, fetchNextPage, isFetching } = useSearchLeadTask(foo?.length! >= 3 ? foo! : '');
  const sum = 0;

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 100 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <Spin spinning={isFetching}>
      <div className={styles.cont} onScroll={e => onScroll(e)}>
        {!type && (
          <div className={styles.cont_total}>
            <span>
              {search?.pages[0]?.totalElements} ta bitim: {sum?.toLocaleString('ru')}
            </span>
          </div>
        )}
        {search?.pages?.map(data =>
          data?.data?.map(item => {
            const handleLead = () => {
              if (item?.pipeStatusType === PipeStatusesType.FIRST) {
                navigate(`${location.pathname}/${item?.id}/${encodeURIComponent(item?.name)}?incoming=true&prev=true`);
              } else {
                navigate(`${location.pathname}/${item?.id}/${encodeURIComponent(item?.name)}?prev=true`);
              }
            };

            return (
              <div className={styles.cont_main_items_item} onClick={handleLead} key={item?.id}>
                <span>{item?.name?.length >= 16 && item?.responsibleBy?.fullName ? `${item?.name?.substring(0, 12)}...` : item?.name}</span>
                <div className={styles.pipes}>
                  <p>
                    {item?.pipeName?.length! > 14 && item?.name?.length + item?.responsibleBy?.fullName?.length + item?.name?.length + item?.pipeName?.length! >= 40
                      ? `${item?.pipeName?.substring(0, 12)}...`
                      : item?.pipeName}
                  </p>
                  <div style={{ backgroundColor: item?.pipeStatusColor }}>
                    {item?.name?.length >= 15 && item?.responsibleBy?.fullName ? `${item?.pipeStatusName.substring(0, 12)}...` : item?.pipeStatusName}
                  </div>
                </div>
                <p className={styles.paragraph}>
                  {item?.name?.length + item?.responsibleBy?.fullName?.length + item?.name?.length + item?.pipeName?.length! >= 40
                    ? `${item?.responsibleBy?.fullName?.substring(0, 20)}...`
                    : item?.responsibleBy?.fullName}
                </p>
              </div>
            );
          })
        )}
      </div>
      {type && search?.pages[0]?.data?.length! < 1 && (
        <div className="flex h-[132px] flex-col items-center justify-center gap-2 border-0 border-t-[1px] border-solid border-[#EAECF0] px-12">
          <p className="text-[16px] font-semibold text-[#101828]">So‘rovingiz topilmadi</p>
          <span className="text-center text-[14px]">{`Kechirasiz, biz "${foo}" so‘ravingizni topa olmadik . Iltimos, qayta urinib ko'ring.`} </span>
        </div>
      )}
    </Spin>
  );
};

export default CrmSearch;
