import React, { FC, useEffect, useState } from 'react';
import { setApartments } from '@reducers/AllHouseFilter';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'global';
import { useTranslation } from 'react-i18next';

import DotIcon from '../../../../assets/icons/contract/DotIcon';
import TableOrderIcon from '../../../../assets/icons/TableOrderIcon';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { setCompanyId } from '../../../../store/reducers/CompanySlice';
import { tableExamples } from '../../../../utils/constants/tableExamples';
import { getClassNameOrder } from '../../../../utils/helper/getClassNameTableOrder';
import { mathFloor } from '../../../../utils/helper/mathFloor';
import { urlEncode } from '../../../../utils/helper/urlEncodeDecode';
import { BronIdOrFlatIds } from '../../../../utils/models/BronIdOrFlatIds';
import { FlatStatus } from '../../../../utils/models/FlatStatus';
import { FlatTable } from '../../../../utils/models/FlatTable';
import { TableConfig } from '../../../../utils/models/TableConfig';
import { SearchParamsHome } from '../../Home';

import styles from './tableFlats.module.scss';

interface IProps {
  data: FlatTable[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
}

const TableFlats: FC<IProps> = ({ data, isLoading, isFetching, tableConfig, tableConfigFetch }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [offsetTop, setOffsetTop] = useState(0);
  const [sizeBig, setSizeBig] = useState(false);

  const category = { ECONOMICAL: t('home.Ekanom'), COMFORT: t('home.Kamfort'), BUSINESS: t('home.Biznes'), PREMIUM: t('home.Premium') };

  const { push, orderTable, location, searchParams } = useQueryParam<SearchParamsHome, any>();

  useEffect(() => {
    const loadWindows = () => {
      setSizeBig(window.innerWidth > 1366);
    };

    window.addEventListener('load', loadWindows);
    window.addEventListener('resize', loadWindows);
    return () => {
      window.removeEventListener('load', loadWindows);
      window.removeEventListener('resize', loadWindows);
    };
  }, []);

  const flatStatus = {
    BOOKED: <span className={styles.flatStatus}>{t('home.BOOKED')}</span>,
    SALE: (
      <span className={styles.flatStatus} style={{ color: '#027A48', background: '#ECFDF3' }}>
        {t('home.SALE')}
      </span>
    ),
    SOLD: (
      <span className={styles.flatStatus} style={{ color: '#B42318', background: '#FEF3F2' }}>
        {t('home.SOLD')}
      </span>
    ),
    RESERVE: (
      <span className={styles.flatStatus} style={{ color: '#FFBB54', background: '#FFFFE0' }}>
        {t('home.RESERVE')}
      </span>
    )
  };

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  const columns: ColumnsType<FlatTable> | undefined | any = [
    {
      title: t('home.TJM_nomi'),
      dataIndex: 'houseName',
      align: 'left',
      width: 202,
      key: 1
    },
    {
      title: (
        <div onClick={() => handleOrders('BUILDING')} className="orderTableCont">
          <span>{t('home.Blok')}</span>
          <div className="orderIcons">
            <TableOrderIcon className={getClassNameOrder('BUILDING', 'ASC', searchParams.order)} />
            <TableOrderIcon className={getClassNameOrder('BUILDING', 'DESC', searchParams.order)} />
          </div>
        </div>
      ),
      align: 'center',
      dataIndex: 'buildingBlock',
      width: 100,
      key: 2
    },
    {
      title: (
        <div onClick={() => handleOrders('NUMBER')} className="orderTableCont">
          <span>{t('home.Xonadon_raqami')}</span>
          <div className="orderIcons">
            <TableOrderIcon className={getClassNameOrder('NUMBER', 'ASC', searchParams.order)} />
            <TableOrderIcon className={getClassNameOrder('NUMBER', 'DESC', searchParams.order)} />
          </div>
        </div>
      ),
      align: 'center',
      dataIndex: 'number',
      width: 100,
      key: 3
    },
    {
      title: (
        <div onClick={() => handleOrders('FLOOR')} className="orderTableCont">
          <span>{t('home.Qavat')}</span>
          <div className="orderIcons">
            <TableOrderIcon className={getClassNameOrder('FLOOR', 'ASC', searchParams.order)} />
            <TableOrderIcon className={getClassNameOrder('FLOOR', 'DESC', searchParams.order)} />
          </div>
        </div>
      ),
      align: 'center',
      dataIndex: 'floor',
      width: 90,
      key: 4
    },
    {
      title: (
        <div onClick={() => handleOrders('ROOMS')} className="orderTableCont">
          <span>{t('home.Xonalar_soni')}</span>
          <div className="orderIcons">
            <TableOrderIcon className={getClassNameOrder('ROOMS', 'ASC', searchParams.order)} />
            <TableOrderIcon className={getClassNameOrder('ROOMS', 'DESC', searchParams.order)} />
          </div>
        </div>
      ),
      dataIndex: 'rooms',
      align: 'center',
      width: 120,
      render: (prev: number) => (
        <span className={styles.number}>
          <DotIcon size={12} color="#12B76A" /> {prev}
        </span>
      ),
      key: 5
    },
    {
      title: (
        <div onClick={() => handleOrders('AREA')} className="orderTableCont">
          <span>{t('home.Maydoni')}</span>
          <div className="orderIcons">
            <TableOrderIcon className={getClassNameOrder('AREA', 'ASC', searchParams.order)} />
            <TableOrderIcon className={getClassNameOrder('AREA', 'DESC', searchParams.order)} />
          </div>
        </div>
      ),
      align: 'center',
      width: 120,
      render: (item: FlatTable) => {
        if (item.terraceDTO)
          return (
            <span>
              {mathFloor(item.area + item.terraceDTO.area, 2)} {t('create_table_payments.m2')}
            </span>
          );
        return (
          <span>
            {item.area} {t('create_table_payments.m2')}
          </span>
        );
      },
      key: 6
    },
    {
      title: (
        <div className={styles.header_price}>
          <span className={styles.header_price_label}>{t('home.1_m2_uchun')}</span>{' '}
        </div>
      ),
      align: 'center',
      key: 8,
      children: [
        {
          title: (
            <div onClick={() => handleOrders('PRICE_PER_AREA')} className="orderTableCont">
              <span>{t('home.Tamirsiz')}</span>
              <div className="orderIcons">
                <TableOrderIcon className={getClassNameOrder('PRICE_PER_AREA', 'ASC', searchParams.order)} />
                <TableOrderIcon className={getClassNameOrder('PRICE_PER_AREA', 'DESC', searchParams.order)} />
              </div>
            </div>
          ),
          name: 'Tamirsiz',
          width: 150,
          render: (item: FlatTable) => (
            <div className={styles.body_price_item}>
              {item?.pricePerArea ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.pricePerArea?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        },
        {
          title: (
            <div onClick={() => handleOrders('PRICE_PER_AREA_REPAIRED')} className="orderTableCont">
              <span>{t('home.Tamirli')}</span>
              <div className="orderIcons">
                <TableOrderIcon className={getClassNameOrder('PRICE_PER_AREA_REPAIRED', 'ASC', searchParams.order)} />
                <TableOrderIcon className={getClassNameOrder('PRICE_PER_AREA_REPAIRED', 'DESC', searchParams.order)} />
              </div>
            </div>
          ),
          name: t('home.Tamirli'),
          width: 150,
          render: (item: FlatTable) => (
            <div className={styles.body_price_item}>
              {item?.pricePerAreaRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.pricePerAreaRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        }
      ]
    },
    {
      title: (
        <div className={styles.header_price}>
          <span className={styles.header_price_label}>{t('home.Umumiy_narx')}</span>{' '}
        </div>
      ),
      align: 'center',
      key: 9,
      children: [
        {
          title: (
            <div onClick={() => handleOrders('PRICE_NOT_REPAIRED')} className="orderTableCont">
              <span>{t('home.Tamirsiz')}</span>
              <div className="orderIcons">
                <TableOrderIcon className={getClassNameOrder('PRICE_NOT_REPAIRED', 'ASC', searchParams.order)} />
                <TableOrderIcon className={getClassNameOrder('PRICE_NOT_REPAIRED', 'DESC', searchParams.order)} />
              </div>
            </div>
          ),
          name: 'Tamirsiz',
          width: 150,
          render: (item: FlatTable) => (
            <div className={styles.body_price_item}>
              {item.priceNotRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.priceNotRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        },
        {
          title: (
            <div onClick={() => handleOrders('PRICE_REPAIRED')} className="orderTableCont">
              <span>{t('home.Tamirli')}</span>
              <div className="orderIcons">
                <TableOrderIcon className={getClassNameOrder('PRICE_REPAIRED', 'ASC', searchParams.order)} />
                <TableOrderIcon className={getClassNameOrder('PRICE_REPAIRED', 'DESC', searchParams.order)} />
              </div>
            </div>
          ),
          name: t('home.Tamirli'),
          width: 150,
          render: (item: FlatTable) => (
            <div className={styles.body_price_item}>
              {item?.priceRepaired ? (
                <>
                  <span className={styles.body_price_item_price}>{item?.priceRepaired?.toLocaleString('ru')}</span>
                  <span className={styles.body_price_item_currency}>{item?.currency?.ccy}</span>
                </>
              ) : (
                <span style={{ margin: '0 auto' }}>-</span>
              )}
            </div>
          )
        }
      ]
    },
    {
      title: t('home.Tamirli_holati'),
      width: 130,
      dataIndex: 'repaired',
      align: 'center',
      render: (item: boolean) => <div>{item ? <span>{t('home.Tamirli')}</span> : <span>{t('home.Tamirsiz')}</span>}</div>,
      key: 10
    },
    {
      title: t('home.Xonadon_sinfi'),
      width: 130,
      dataIndex: 'category',
      align: 'center',
      render: (item: string) => <div>{category[item as keyof typeof category]}</div>,
      key: 11
    },
    {
      title: t('home.Holati'),
      dataIndex: 'status',
      align: 'center',
      width: 129,
      render: (prev: string) => flatStatus[prev as keyof typeof FlatStatus],
      key: 12
    }
  ];

  const hasChildren = (item: TableConfig, column: any) => ({
    ...column,
    children: column?.children
      ? column?.children
          ?.map((child: any) => ({
            ...(item.values?.some(val => val?.name === child?.name && val?.checked) && child)
          }))
          .filter((item: any) => item?.name)
      : []
  });

  const columnsSort = (tableConfig?.length > 0 ? tableConfig : tableExamples.home)
    ?.map(item => ({
      ...(item.checked &&
        hasChildren(
          item,
          columns?.find((col: any) => col?.key === item?.id)
        ))
    }))
    .filter(item => item?.title);

  return (
    <div className={styles.container}>
      <Table
        dataSource={data}
        columns={columnsSort}
        pagination={false}
        style={{ height: `calc(100vh - ${offsetTop}px)` }}
        scroll={{
          y: `calc(100vh - ${offsetTop + 100}px)`
        }}
        className={styles.container_table}
        loading={isLoading || isFetching || tableConfigFetch}
        rowKey={prev => prev.id}
        ref={ref => {
          setOffsetTop((ref?.offsetTop || 0) + (sizeBig ? 150 : 100));
        }}
        onRow={e => ({
          onClick: () => {
            dispatch(
              // @ts-expect-error
              setApartments([
                {
                  id: e?.companyId,
                  number: e?.number,
                  rooms: e?.rooms,
                  status: e?.status
                }
              ])
            );
            dispatch(setCompanyId(e?.companyId));
            push(`${location.pathname}/${BronIdOrFlatIds.FLAT_IDS}=${e.id}/${urlEncode(e.houseName)}?company=${btoa(String(e?.companyId || ''))}`);
          },
          className: styles.container_row_class_name
        })}
      />
    </div>
  );
};

export default TableFlats;
