import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { useSearchClientChanges } from '@pages/changes/services/queries';
import { queryKeys } from '@pages/changes/utils/constants/queryKeys';
import DublicateControllModal from '@pages/crm/components/listView/actions/dublicate-controll-modal/DublicateControllModal';
import { useAttachClient } from '@pages/oneLid/services/mutation';
import { setSearchClient } from '@reducers/ChangesSlice';
import { setChangeDublicateClientId, setOpenDublicateModal } from '@reducers/CrmOneLidSlice';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { SearchClientChanges } from '@utils/models/Client';
import { Input, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './clientModal.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  clientId: number;
};

const ClientModal: React.FC<Props> = ({ setVisible, visible, clientId }) => {
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const { t } = useTranslation();
  const { params } = useQueryParam<any, any>();
  const [scrolling, setScrolling] = useState(true);
  const attachClient = useAttachClient();
  const searchClient = useAppSelector(state => state.changesReducer.searchClient);
  const { dublicateClientId } = useAppSelector(state => state.crmOneLidField);
  const { data, fetchNextPage, isFetching } = useSearchClientChanges(searchClient);
  const [selectedData, setSelectedData] = useState<SearchClientChanges | undefined>();

  const handleSearchClient = (value: string) => {
    dispatch(setSearchClient(String(value)));
  };

  const handleClose = () => {
    setVisible(false);
    dispatch(setSearchClient(''));
    dispatch(setChangeDublicateClientId(undefined));
    qc.setQueryData([queryKeys.SEARCH_CLIENT_CHANGES, searchClient], []);
  };

  const handleAttachClient = (clientId: number, leadId: number) => {
    selectedData?.lead ? dispatch(setOpenDublicateModal(true)) : attachClient.mutateAsync({ leadId, clientId }).then(handleClose);
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 100 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        title={t('monetary.Mijoz_biriktirish')}
        onCancel={handleClose}
        okText={t('client_create_contract.Keyingisi')}
        cancelText={t('home.Bekor_qilish')}
        className={styles.clientModal}
        {...(dublicateClientId === undefined && { footer: false })}
        onOk={() => {
          handleAttachClient(Number(dublicateClientId), Number(params?.id));
          dispatch(
            setBalanceAndIds({
              ids: [Number(params?.id), Number(selectedData?.lead?.id)]
            })
          );
          handleClose();
        }}
        okButtonProps={{ ...{ loading: attachClient.isLoading } }}
      >
        <div className={styles.clientModal_body}>
          <span>{t('one_lid.mijoz_qidiring')}</span>
          <Input value={searchClient} onChange={e => handleSearchClient(e.target.value)} placeholder={t('create_payment.Mijoz_qidirish')} />
        </div>
        <div className={styles.clientModal_searchClients}>
          <Spin spinning={isFetching}>
            {dublicateClientId === undefined ? (
              <div className={styles.clientModal_searchClients_body} onScroll={e => onScroll(e)}>
                {data?.pages?.map(data =>
                  data?.data?.map(client => (
                    <div
                      className={styles.findClient}
                      onClick={() => {
                        dispatch(setChangeDublicateClientId(Number(client?.id)));
                        setSelectedData(client);
                      }}
                      onScroll={e => onScroll(e)}
                      key={client?.id}
                    >
                      <div className={styles.findClient_item}>
                        <label>{t(`changes.client.FindClientItem.Mijoz`)}</label>
                        <span>{client.name}</span>
                      </div>
                      <div className={styles.findClient_item}>
                        <label>{t(`changes.client.FindClientItem.Telefon`)}</label>
                        <span>{client.phone}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div className={dublicateClientId ? styles.findClient_test : styles.findClient}>
                <div className={styles.findClient_item}>
                  <label>{t(`changes.client.FindClientItem.Mijoz`)}</label>
                  <span>{selectedData?.name}</span>
                </div>
                <div className={styles.findClient_item}>
                  <label>{t(`changes.client.FindClientItem.Telefon`)}</label>
                  <span>{selectedData?.phone}</span>
                </div>
              </div>
            )}
          </Spin>
        </div>
      </Modal>
      {selectedData?.lead && <DublicateControllModal type="client" clientId={Number(params?.id)} />}
    </>
  );
};

export default ClientModal;
