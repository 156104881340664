import React from 'react';
import DeleteCircle from '@icons/DeleteCircle';
import UploadExtraImageIcon from '@icons/UploadExtraImageIcon';
import { useUploadFile } from '@queries/mutation';
import { FileObject } from '@utils/models/File';
import { Spin } from 'antd';

import ExtraImageCont from './ExtraImageCont';

import styles from '../shaxmatka.module.scss';

interface Props {
  extraImages: FileObject[];
  addExtraImage: (file: FileObject) => void;
  deleteExtraImage: (id: number) => void;
}

const UploadExtraFiles: React.FC<Props> = ({ extraImages, addExtraImage, deleteExtraImage }) => {
  const uploadFile = useUploadFile((data: FileObject[]) => {
    addExtraImage(data[0]);
  });

  const deleteImage = (id: number) => {
    deleteExtraImage(id);
  };

  const handleFile = (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();

    if (file && file.size <= 10000000) {
      formData.append('files', file);
      uploadFile.mutate(formData);
      e.target.value = '';
    }
  };

  return (
    <div className={styles.extra_image}>
      {extraImages?.map((image, index) => (
        <div key={index} className={styles.extra_image_item}>
          <span
            onClick={() => {
              deleteImage(image.id);
            }}
          >
            <DeleteCircle />
          </span>
          <ExtraImageCont image={image} />
        </div>
      ))}
      <Spin spinning={uploadFile.isLoading}>
        <label
          htmlFor="extra_image_upload"
          onClick={e => {
            e.stopPropagation();
          }}
          className={styles.extra_image_upload}
        >
          <UploadExtraImageIcon />
        </label>
        <input onChange={e => handleFile(e)} id="extra_image_upload" type="file" style={{ display: 'none' }} />
      </Spin>
    </div>
  );
};

export default UploadExtraFiles;
