import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowDownIcon from '@icons/ArrowDownIconCrm';
import CheckIcon from '@icons/CheckIcon';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import DashboardEmployee from '@icons/DashboardEmployee';
import DotsIcon from '@icons/DotsIcon';
import Edit2Icon from '@icons/Edit2Icon';
import EmptyDashboardIcon from '@icons/EmptyDashboardIcon';
import ShareIcon from '@icons/ShareIcon';
import WidgetIcon from '@icons/WidgetIcon';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { useTableConfigCustomMutation } from '@queries/mutation';
import { useCrmEmployee, useTableConfigCustom } from '@queries/queries';
import { setUpdateWidgets } from '@reducers/CrmSlice';
import { DATE_FORMAT } from '@utils/constants/format';
import { Permissions } from '@utils/constants/permissions';
import { TableKeyCustom } from '@utils/models/TableKey';
import { Button, Dropdown, Switch } from 'antd';
import dayjs from 'dayjs';
import queryString from 'query-string';
import ReactGridLayout from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { DashboardProvider } from 'modules/dashboard';

import DatePicker from '../../service/datePicker';

import DeleteConfirm1 from './components/confirm-modal/DeleteConfirm1';
import DashboardDrawer from './components/dashboard-drawer/DashboardDriwer';
import DefaultWidgets from './components/default-widgets/DefaultWidgets';
import ShareDashboard from './components/share-dashboard/ShareDashboard';
import WidgetChildren from './components/widget-children/WidgetChildren';
import DashboardUpdate from './components/widget-update/DashboardUpdate';
import { useCreateDashboard, useDeleteDashboard, useUpdateWidgets } from './services/mutations';
import { useGetDashboards, useGetWidgets } from './services/queries';
import { queryKeys } from './utils/constants/queryKeys';
import { WidgetModel } from './utils/models/WidgetModel';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styles from './dashboard.module.scss';

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const updateWidgets = useUpdateWidgets();
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const { searchParams, appendMultipleDifferent, params } = useQueryParam<SearchParamsCrm, any>();
  const { data: dashboard } = useGetDashboards();
  const { data: employees } = useCrmEmployee();
  const { data: dashboardId } = useTableConfigCustom(TableKeyCustom.dashboard_id);
  const { data } = useGetWidgets(Number(dashboardId) || Number(searchParams.dashboardId));
  const createDashboard = useCreateDashboard();

  const tableConfig = useTableConfigCustomMutation(TableKeyCustom.dashboard_id);

  const [resizable, setResizable] = useState<{
    resize?: boolean;
    dragDrop?: boolean;
  }>({ dragDrop: false, resize: false });

  const [layouts, setLayouts] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [checkedItem, setCheckedItem] = useState<number[]>([]);
  const [openShare, setOpenShare] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [dashBg, setDashBg] = useState(false);
  const deleteDashboard = useDeleteDashboard();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const userId = useAppSelector(state => state.userReducer.user?.id);

  const currentDashboard = dashboard?.find(item => item?.id === Number(dashboardId));
  const isAccessEditDashboard = permissions?.includes(Permissions.PERMISSION_EDIT_MAIN_DASHBOARD);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentDashboard?.startDate &&
      currentDashboard?.finishDate &&
      appendMultipleDifferent(['startDate', 'finishDate'], [currentDashboard?.startDate!, currentDashboard?.finishDate!]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentDashboard?.startDate && currentDashboard?.finishDate && setCheckedItem(currentDashboard?.employeeIds!);
  }, [dashboardId]);

  const handleCheckItem = (employeeId: number) => {
    if (checkedItem?.includes(employeeId)) {
      const newCheckedItem = checkedItem?.filter(item => item !== employeeId);

      setCheckedItem(newCheckedItem);
    } else {
      setCheckedItem(prev => [...prev!, employeeId]);
    }
  };

  const handleOpenDrawer = () => setVisible(true);

  const handleOpenShareModal = () => setOpenShare(true);

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };

  const upDatedData: WidgetModel[] | undefined = layouts?.data?.map((item: any) => {
    const findPosition = layouts?.position?.find((el: any) => el?.i === item?.name);

    return {
      ...item,
      position: [findPosition?.x, findPosition?.y, findPosition?.w, findPosition?.h]
    };
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data &&
      setLayouts({
        position: data?.map((item, index) => ({
          h: Number(item?.position[3] || 0),
          i: String(item.name),
          isResizable: updateWidget,
          w: Number(item?.position[2] || 0),
          x: Number(item?.position[0] || 0),
          y: Number(item?.position[1] || 0),
          // eslint-disable-next-line no-nested-ternary
          minW: item?.type === 'NUMBER' ? 2 : item?.type === 'LIST' ? 5 : item?.type === 'PIE_CHART' ? 5 : 4,
          // eslint-disable-next-line no-nested-ternary
          minH: item?.type === 'NUMBER' ? 2 : item?.type === 'LIST' ? 3 : item?.type === 'PIE_CHART' ? 5 : 4,
          // eslint-disable-next-line no-nested-ternary
          maxW: item?.type === 'NUMBER' ? 4 : item?.type === 'LIST' ? 8 : 7,
          // eslint-disable-next-line no-nested-ternary
          maxH: item?.type === 'NUMBER' ? 3 : item?.type === 'LIST' ? 4 : 7
        })),
        data: data?.map(item => ({ ...item, name: item?.name }))
      });
  }, [data, updateWidget, dashboardId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    currentDashboard &&
      createDashboard
        .mutateAsync({
          ...currentDashboard,
          employeeIds: checkedItem?.length! > 0 ? checkedItem : null,
          startDate: searchParams?.startDate ? searchParams?.startDate : null,
          finishDate: searchParams?.finishDate ? searchParams?.finishDate : null
        })
        .then(() => {
          const filterWidget = qc.getQueriesData([queryKeys.WIDGET]).filter((item: any) => !item[0][1]?.filterLead?.startDate);

          filterWidget.forEach(item => {
            qc.invalidateQueries(item[0]);
          });
          qc.invalidateQueries([queryKeys.WIDGET]);
        });
  }, [searchParams?.startDate, searchParams?.finishDate, checkedItem]);

  const handleOpenDefaultWidgets = () => {
    setOpenDrawer(true);
  };

  useEffect(() => {
    const [oneDashboard] = dashboard || [];

    if (oneDashboard && !searchParams.dashboardId) {
      if (dashboardId) {
        navigate({
          pathname: window.location.pathname,
          search: queryString.stringify({
            ...searchParams,
            dashboardId
          })
        });
      } else {
        navigate({
          pathname: window.location.pathname,
          search: queryString.stringify({
            ...searchParams,
            dashboardId: oneDashboard?.id
          })
        });
      }
    }
  }, [dashboard, dashboardId, searchParams]);

  const handleDeleteDashboard = (id: number) =>
    deleteDashboard.mutateAsync(id).then(() => {
      tableConfig.mutate({ key: TableKeyCustom.dashboard_id, value: String(dashboard![0]?.id) });
      setVisible(false);
    });

  const dashPermission = currentDashboard?.dashboardUsersDto?.find(item => item?.employeeId === userId)?.permission === 'READ';



  return (
    <DashboardProvider>
      <div className={styles.header}>
        <MainHeader>
          <div className={styles.header_left}>
            <DashboardDrawer voronkaVisible={visible} setVoronkaVisible={setVisible} data={dashboard} />
            <div className={styles.header_left_name}>
              <span onClick={handleOpenDrawer}>{dashboard?.find(item => item?.id === (Number(dashboardId) || Number(searchParams.dashboardId)))?.name}</span>
              {updateWidget && !dashPermission ? (
                <Dropdown
                  overlay={
                    <div className={styles.actionsDropdown}>
                      <div className={styles.actionsDropdown_item} onClick={() => setOpenUpdateModal(true)}>
                        <Edit2Icon />
                        <span>{t('emp_dashboard.Nomini_tahrirlash')}</span>
                      </div>
                      {Number(dashboardId) !== dashboard?.find(item => item?.companyId !== null)?.id && (
                        <div className={styles.actionsDropdown_item} onClick={handleOpenShareModal}>
                          <ShareIcon />
                          <span>{t('emp_dashboard.Ulashish')}</span>
                        </div>
                      )}
                      <DeleteConfirm1
                        children={
                          <div className={styles.actionsDropdown_item}>
                            <TrushIcon />
                            <span>{t('shaxmatka.Ochirish')}</span>
                          </div>
                        }
                        onConfirm={() => handleDeleteDashboard(Number(currentDashboard?.id))}
                        text={`${currentDashboard?.name} nomli dashboardni o'chirmoqchimisiz?`}
                        isLoading={deleteDashboard.isLoading}
                      />
                    </div>
                  }
                  mouseEnterDelay={1000}
                  mouseLeaveDelay={0.3}
                  trigger={['click', 'hover']}
                >
                  <DotsIcon />
                </Dropdown>
              ) : (
                <ArrowDownIcon onClick={handleOpenDrawer} />
              )}
            </div>
          </div>
          <div className="flex items-center">
            <OnlinePbxNotifications />
            <NotificationButtons />
          </div>
        </MainHeader>
      </div>
      <div className={styles.dashboardNav}>
        <div className={styles.dashboardNav_sides}>
          <span>{t('integration.Tahrirlash')}</span>
          <Switch
            checked={updateWidget}
            onChange={e => {
              dispatch(setUpdateWidgets(e));
              setResizable({ dragDrop: false, resize: false });
            }}
            disabled={(Number(dashboardId) === dashboardId && !isAccessEditDashboard) || dashPermission}
          />
        </div>
        <div className={styles.dashboardNav_sides}>
          {(resizable?.dragDrop || resizable?.resize) && (
            <Button
              loading={updateWidgets.isLoading}
              onClick={() =>
                updateWidgets.mutateAsync(upDatedData!).then(() => {
                  dispatch(setUpdateWidgets(false));
                  setResizable({ dragDrop: false, resize: false });
                })
              }
            >
              {t('home.Saqlash')}
            </Button>
          )}
          {/* @ts-ignore */}
          <RangePicker
            format={DATE_FORMAT}
            value={searchParams.startDate && searchParams.finishDate ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)] : [null, null]}
            onChange={changeDate}
            dropdownClassName={styles.rangePicker}
            ranges={{
              [t('crm_filter.Bugun')]: [dayjs(), dayjs()],
              [t('one_lid.Kecha')]: [dayjs().add(-1, 'day').startOf('day'), dayjs()],
              [t('crm_filter.Joriy_hafta')]: [dayjs().startOf('week').add(1, 'day'), dayjs().endOf('week').add(1, 'day')],
              [t('crm_filter.Joriy_oy')]: [dayjs().startOf('month'), dayjs().endOf('month')],
              [t('crm_filter.Otkan_oy')]: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')],
              'This year': [dayjs().startOf('year'), dayjs().endOf('year')],
              [t('crm_filter.Otkan_yil')]: [dayjs().add(-1, 'year').startOf('year'), dayjs().add(-1, 'year').endOf('year')]
            }}
            renderExtraFooter={() => (
              <div className={styles.dashboardNav_sides_extraFooter_bottom}>
                <div className={styles.dashboardNav_sides_extraFooter_bottom_item} />
                <div className={styles.dashboardNav_sides_extraFooter_bottom_item}>
                  <span>{searchParams?.startDate ? searchParams?.startDate : t('settingEmployee.kk_oo_yy')}</span>
                  <span className={styles.centerLine}>-</span>
                  <span>{searchParams?.finishDate ? searchParams?.finishDate : t('settingEmployee.kk_oo_yy')}</span>
                </div>
              </div>
            )}
            // className="CustomRangePicker"
            placeholder={[t('settingEmployee.kk_oo_yy'), t('settingEmployee.kk_oo_yy')]}
          />

          <Dropdown
            trigger={['click', 'hover']}
            overlay={
              <div className={styles.dashboardNav_sides_overlay}>
                <div className={styles.dashboardNav_sides_overlay_item}>
                  <div
                    onClick={() => {
                      if (checkedItem?.length === employees?.length) {
                        setCheckedItem([]);
                      } else {
                        setCheckedItem(() => (employees ? employees?.map(item => Number(item?.id)) : []));
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    {t('contract_id.Barcha_xodimlar')}
                  </div>
                </div>
                {employees?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.dashboardNav_sides_overlay_item} onClick={() => handleCheckItem(item?.id)} key={index}>
                    <span>{item?.fullName}</span>
                    <span
                      style={{
                        opacity: checkedItem?.includes(item?.id) ? '1' : '0'
                      }}
                    >
                      <CheckIcon stroke="#1890ff" />
                    </span>
                  </div>
                ))}
              </div>
            }
            mouseEnterDelay={1000}
            mouseLeaveDelay={0.3}
          >
            <div className={styles.dashboardNav_sides_item}>
              <div className={styles.employeesCount} style={{ opacity: checkedItem?.length > 0 ? 1 : 0 }}>
                {checkedItem?.length}
              </div>
              <DashboardEmployee />
              <span>{t('home.Xodimlar')}</span>
              <ArrowDownIcon />
            </div>
          </Dropdown>

          <div className={styles.dashboardNav_sides_item} onClick={handleOpenDefaultWidgets}>
            <WidgetIcon />
            <span>{t('emp_dashboard.Vidjetlarni_boshqarish')}</span>
          </div>
        </div>
      </div>
      <div className={dashBg ? styles.gridLayoutBg : styles.gridLayout}>
        <ReactGridLayout
          className="layout"
          layout={layouts?.position}
          cols={12}
          rowHeight={100}
          maxRows={20}
          isDraggable={updateWidget}
          isResizable={updateWidget}
          width={1680}
          isDroppable={updateWidget}
          onLayoutChange={(e: any) => {
            setLayouts({
              position: e,
              data: layouts?.data
            });
            qc.setQueryData([queryKeys.WIDGETS], (data: any) =>
              data?.map((item: any) => {
                const getPosition = e?.find((el: any) => el?.i === item?.name);

                return {
                  ...item,
                  position: [getPosition?.x, getPosition?.y, getPosition?.w, getPosition?.h]
                };
              })
            );
          }}
          onDragStop={() => {
            setResizable(e => ({ ...e, dragDrop: true }));
            setDashBg(false);
          }}
          onResizeStop={() => setResizable(e => ({ ...e, resize: true }))}
          style={{
            height: 'calc(100vh - 125px)',
            overflowY: 'scroll'
          }}
          onDragStart={() => setDashBg(true)}
        >
          {layouts?.data?.map((item: WidgetModel) => (
            <div key={item?.name} style={{ minHeight: '640px !important' }}>
              <WidgetChildren item={item} />
            </div>
          ))}
        </ReactGridLayout>
      </div>
      {data && data?.length < 0 && (
        <div className={styles.emptyContent}>
          <EmptyDashboardIcon />
          <p>Dashborda widget yaratishni boshlang</p>
          <span>Loyihaga ishlar kiriting, resurslar biriktiring, ish grafigini tuzib chiqing</span>
        </div>
      )}
      <DefaultWidgets openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} dashPermission={dashPermission} />
      <ShareDashboard visible={openShare} setVisible={setOpenShare} />
      <DashboardUpdate id={Number(currentDashboard?.id)} setVisible={setOpenUpdateModal} visible={openUpdateModal} currentDashboard={currentDashboard} />
    </DashboardProvider>
  );
};

export default Dashboard;
