import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import TableOrderIcon from '../../assets/icons/TableOrderIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { SearchParamsClient } from '../../pages/client/Client';
import { getClassNameOrder } from '../../utils/helper/getClassNameTableOrder';
import { StaffTable } from '../../utils/models/Staff';
import { TableConfig } from '../../utils/models/TableConfig';

import BrokerDriver from './BrokerDriver';

interface TableClientProps {
  data: StaffTable[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  currentPage: number;
  pageSize: number;
  isFetching: boolean;
}

const BrokerTable: React.FC<TableClientProps> = ({ tableConfig, tableConfigFetch, data, isLoading, pageSize, currentPage, isFetching }) => {
  const { searchParams, location, orderTable } = useQueryParam<SearchParamsClient, null>();

  const [openDriverBroker, setOpenDriverBroker] = useState<{
    visible: boolean;
    staff: StaffTable | undefined;
  }>({
    visible: false,
    staff: undefined
  });

  const { t } = useTranslation();

  // console.log('dduck0', data)

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  const initialColumn: ColumnsType<StaffTable> | undefined = [
    {
      title: t('home.№'),
      render: (text: any, record: StaffTable, index: number) => pageSize * (currentPage - 1) + index + 1,
      width: '5%',
      align: 'left'
    },
    {
      title: (
        <div onClick={() => handleOrders('FULL_NAME')} className="orderTableCont justify-start">
          <div>{t('home.Vositachi')}</div>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      dataIndex: 'fullName',
      width: '30%',
      align: 'left'
    },
    {
      title: t('home.Manzili'),
      dataIndex: 'address',
      width: '25%',
      align: 'left'
    },
    {
      title: (
        <div onClick={() => handleOrders('BALANCE')} className="orderTableCont justify-start">
          <div>{t('home.Balans')}</div>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      align: 'left',
      width: '15%',
      render: (record: any) => (
        <div style={{ color: 'var(--ant-primary-color)' }} className="flex items-center justify-start">
          {record?.balances
            ?.sort((a: any, b: any) => (b?.currencyDto?.basic ? 1 : 0) - (a?.currencyDto?.basic ? 1 : 0))
            ?.map((item: any) => {
              const currency = item?.currencyDto?.ccy;
              const amount = item?.amount;

              return `${amount?.toLocaleString('ru')} ${currency}`;
            })
            .join(' | ')}
        </div>
      )
    },
    {
      title: t('home.Masul'),
      dataIndex: 'createdBy',
      align: 'left',
      width: '20%'
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case '№':
              newConfig.push({
                title: t('home.№'),
                width: '5%',
                align: 'left',
                render: (text: any, record: StaffTable, index: number) => pageSize * (currentPage - 1) + index + 1
              });
              break;
            case 'Manzili':
              newConfig.push({
                title: t('home.Manzili'),
                align: 'left',
                dataIndex: 'address',
                width: '25%'
              });
              break;
            case 'Vositachi':
              newConfig.push({
                title: (
                  <div onClick={() => handleOrders('FULL_NAME')} className="orderTableCont justify-start">
                    <div>{t('home.Vositachi')}</div>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('FULL_NAME', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('FULL_NAME', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'fullName',
                align: 'left',
                width: '30%',
                render: (i: number) => <>{i?.toLocaleString('ru')}</>
              });
              break;
            case 'Balans':
              newConfig.push({
                title: (
                  <div onClick={() => handleOrders('BALANCE')} className="orderTableCont justify-start">
                    <div>{t('home.Balans')}</div>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('BALANCE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('BALANCE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                align: 'left',
                width: '15%',
                render: (record: any) => (
                  <div style={{ color: 'var(--ant-primary-color)' }} className="flex items-center justify-center">
                    {record?.balances
                      ?.sort((a: any, b: any) => (b?.currencyDto?.basic ? 1 : 0) - (a?.currencyDto?.basic ? 1 : 0))
                      ?.map((item: any) => {
                        const currency = item?.currencyDto?.ccy;
                        const amount = item?.amount;

                        return `${amount?.toLocaleString('ru')} ${currency}`;
                      })
                      .join(' | ')}
                  </div>
                )
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                dataIndex: 'createdBy',
                align: 'left',
                width: '20%'
              });
              break;
            default:
              break;
          }
        }
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch, location.search]);

  useEffect(() => {
    const newConfig: any[] = [...column];

    if (tableConfig.length === 0) {
      newConfig[0] = {
        title: t('home.№'),
        render: (text: any, record: StaffTable, index: number) => pageSize * (currentPage - 1) + index + 1,
        width: '5%',
        align: 'left'
      };
      newConfig[1] = {
        title: (
          <div onClick={() => handleOrders('FULL_NAME')} className="orderTableCont justify-start">
            <div>{t('home.Vositachi')}</div>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('FULL_NAME', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('FULL_NAME', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        dataIndex: 'fullName',
        align: 'left',
        width: '30%',
        render: (i: number) => <>{i?.toLocaleString('ru')}</>
      };
      newConfig[3] = {
        title: (
          <div onClick={() => handleOrders('BALANCE')} className="orderTableCont justify-start">
            <div>{t('home.Balans')}</div>

            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('BALANCE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('BALANCE', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        align: 'left',
        width: '25%',
        render: (record: any) => (
          <div style={{ color: 'var(--ant-primary-color)' }} className="ml-[-10px] flex items-center justify-start">
            {record?.balances
              ?.sort((a: any, b: any) => (b?.currencyDto?.basic ? 1 : 0) - (a?.currencyDto?.basic ? 1 : 0))
              ?.map((item: any) => {
                const currency = item?.currencyDto?.ccy;
                const amount = item?.amount;

                return `${amount?.toLocaleString('ru')} ${currency}`;
              })
              .join(' | ')}
          </div>
        )
      };
      setColumn(newConfig);
    }
  }, [location.search, currentPage]);

  const handleOpenDriverBroker = (visible: boolean, staff?: StaffTable) => {
    setOpenDriverBroker({
      visible,
      staff: staff || undefined
    });
  };

  return (
    <>
      <Table
        className="custom_table contract_table_size"
        columns={column}
        dataSource={data}
        loading={isLoading || tableConfigFetch || isFetching}
        size="small"
        rowKey="id"
        pagination={false}
        onRow={record => ({
          onClick: () => {
            handleOpenDriverBroker(true, record);
          }
        })}
      />
      <BrokerDriver staff={openDriverBroker.staff} tableSize={pageSize} visible={openDriverBroker.visible} onClose={handleOpenDriverBroker} />
    </>
  );
};

export default BrokerTable;
