import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLeadChangeStep } from '../../../../../queries/mutation';
import { useReasonsForRefusal } from '../../../services/queries';
import LeadTaskCompleteModal from '../leadTaskCompleteModal/LeadTaskCompleteModal';

import styles from './reasonsRefusalModal.module.scss';

interface IProps {
  visible: boolean;
  leadId: number;
  statusId: number;
  setReasonsRefusal: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  >;
}

const ReasonsRefusalModal: FC<IProps> = ({ setReasonsRefusal, visible, leadId, statusId }) => {
  const leadChangeStep = useLeadChangeStep();
  const [leadTaskCompleteModal, setLeadTaskCompleteModal] = useState({
    id: 0,
    visible: false,
    leadId: 0,
    statusId: 0
  });
  const [valueRadio, setValueRadio] = useState(null);
  const { data } = useReasonsForRefusal();

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    setReasonsRefusal({
      id: 0,
      visible: false,
      leadId: 0,
      statusId: 0
    });
  }, [setReasonsRefusal]);

  const changeGroup = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };

  const handleSave = () => {
    // leadChangeStep.mutate({
    //   leadId: leadId,
    //   statusId: statusId,
    //   updateTimeDate: dayjs().format('DD.MM.YYYY HH:mm:ss.SSS'),
    //   reasonsId: valueRadio,
    // })
    setLeadTaskCompleteModal({
      visible: true,
      id: leadId,
      leadId,
      statusId
    });
  };

  useEffect(() => {
    handleClose();
  }, [handleClose, leadChangeStep.isSuccess]);

  return (
    <>
      <Modal title={t('reason_refusal.Rad_etish_sababi')} visible={visible} onCancel={handleClose} footer={false} width={372} wrapClassName={styles.container}>
        <Radio.Group onChange={e => changeGroup(e)} value={valueRadio}>
          <Space direction="vertical">
            <Radio value={null}>{t('reason_refusal.Sababsiz')}</Radio>
            {data &&
              data.length > 0 &&
              data?.map(item => (
                <Radio value={item.id} key={item.id}>
                  {item.value}
                </Radio>
              ))}
          </Space>
        </Radio.Group>
        <div className={styles.container_footer}>
          <Button onClick={handleClose}>{t('reason_refusal.Bekor_qilish')}</Button>
          <Button onClick={handleSave} type="primary" loading={leadChangeStep.isLoading}>
            {t('reason_refusal.Saqlash')}
          </Button>
        </div>
      </Modal>
      <LeadTaskCompleteModal {...leadTaskCompleteModal} reasonsId={valueRadio} setReasonsRefusal={setReasonsRefusal} setLeadTaskCompleteModal={setLeadTaskCompleteModal} />
    </>
  );
};

export default ReasonsRefusalModal;
