import React, { useState } from 'react';
import { useOnlinePBXRefresh } from '@queries/mutation';
import { Modal, Spin } from 'antd';

import { OnlinePbx } from '../../../../../utils/models/OnlinePbx';

import ConfigPBX from './ConfigPBX';
import CreatePBX from './CreatePBX';
import Steps from './Steps';
import UsersPBX from './UsersPBX';

import styles from './onlinePBX.module.scss';

interface Props {
  visible: boolean;
  onCancel: (visible: boolean) => void;
  onlinePbx: null | OnlinePbx;
}

const OnlinePBX: React.FC<Props> = ({ visible, onCancel, onlinePbx }) => {
  const [step, setStep] = useState(1);
  const refreshOnlinePBX = useOnlinePBXRefresh();

  // handle cancel
  const handleCancel = () => {
    setStep(1);
    onCancel(false);
  };
  // handle change step
  const handleChangeStep = (value: number) => {
    if (onlinePbx) setStep(value);
  };

  // handle show current step
  const handleShowCurrentStep = () => {
    switch (step) {
      case 1:
        return <CreatePBX onlinePbx={onlinePbx} refreshOnlinePBX={refreshOnlinePBX} />;
      case 2:
        return <UsersPBX onlinePbx={onlinePbx} />;
      case 3:
        return <ConfigPBX onlinePbx={onlinePbx} onCancel={onCancel} />;
      default:
        return <div />;
    }
  };

  return (
    <Modal className={styles.container} width={600} footer={false} visible={visible} title="OnlinePBX" onCancel={handleCancel}>
      <Spin spinning={false}>
        <div className={styles.container_body}>
          <Steps changeStep={handleChangeStep} currentStep={step} onlinePbx={onlinePbx} refreshOnlinePBX={refreshOnlinePBX} />
          {handleShowCurrentStep()}
        </div>
      </Spin>
    </Modal>
  );
};

export default OnlinePBX;
