import React, { FC, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import styles from './modals.module.scss';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { SearchParamsCrm } from '../../../filter/FilterButton';
import { useTags } from '../../../../../../queries/queries';
import { useTranslation } from 'react-i18next';
import SubtractIcon from '../../../../../../assets/icons/SubtractIcon';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { useGroupActionCrmViewList } from '../../../../services/mutation';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { useAppDispatch } from '@hooks/reduxHooks';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import { useQueryClient } from 'react-query';

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
  balance: number | undefined;
}

const BrickTag: FC<IProps> = ({ visible, close, ids, balance }) => {
  const dispatch = useAppDispatch();
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [search, setSearch] = useState('');
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const qc = useQueryClient();
  const pipeStatusIds = JSON.parse(localStorage.getItem('pipeIds')!);

  // handle cancel modal
  const handleCancel = () => {
    close(false);
    setTagIds([]);
    setSearch('');
  };

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  const changeEmployee = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);

  const { data } = useTags();

  const { t } = useTranslation();

  //handleSearch
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // filter tag
  const filterTag = () => {
    if (!tagIds && data) {
      const tags = data.tagList;
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()));
    } else if (data && tagIds) {
      const tags = data.tagList;
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()) && !tagIds.includes(Number(tag.id)));
    }
    return [];
  };

  // changeTags
  const changeTags = (value: number) => {
    if (tagIds.includes(value)) {
      const newIds = [...tagIds];
      newIds.splice(tagIds.indexOf(value), 1);
      setTagIds(newIds);
      return;
    } else {
      setTagIds([...tagIds, value]);
      handleSearch('');
      return;
    }
  };

  // tags
  const tags = () => {
    if (tagIds) {
      if (Array.isArray(tagIds)) {
        return (
          <>
            {tagIds?.map((id, index) => {
              const tag = data?.tagList.find(tag => tag.id === Number(id));
              if (tag) {
                return (
                  <span key={index} className={styles.container_tags_tag}>
                    {tag.name}
                    <SubtractIcon onClick={() => changeTags(Number(tag.id))} />
                  </span>
                );
              } else return null;
            })}
          </>
        );
      }
      const tag = data?.tagList.find(tag => tag.id === Number(tagIds));
      if (tag) {
        return (
          <span className={styles.container_tags_tag}>
            {tag.name}
            <SubtractIcon onClick={() => changeTags(Number(tag.id))} />
          </span>
        );
      }
      return null;
    }
    return;
  };

  // handle save
  const handleSave = () => {
    changeEmployee
      .mutateAsync({
        tagIds,
        requestMethodType: RequestMethodTypeGroupActions.ADDED_TAG_FOR_LEAD,
        leadIds: ids
      })
      .then(res => {
        dispatch(setBalanceAndIds({ ids: ids, balance: balance }));
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
      });
  };

  return (
    <Modal className={styles.container} width={560} footer={false} visible={visible} title={t('list_view.Umumiy_teglar')} onCancel={handleCancel}>
      <div className={styles.container_tags}>
        {tags()}
        <Input value={search} onChange={e => handleSearch(e.target.value)} bordered={false} placeholder={t('crm_filter.Tegni_izlash')} />
      </div>
      <div className={styles.container_tags_list}>
        {filterTag()?.map(tag => (
          <div onClick={() => changeTags(Number(tag.id))} key={tag.id} className={styles.container_tags_list_tag}>
            {tag.name}
          </div>
        ))}
      </div>
      <div className={styles.container_submit}>
        <div className={styles.container_submit_left}>
          <Button disabled={tagIds.length === 0} onClick={handleSave} loading={changeEmployee.isLoading} htmlType={'submit'} type="primary">
            {t('home.Yuborish')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BrickTag;
