import React, { memo, useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Edit from '../../assets/icons/Edit';
import { useUpdateSmsTemplate } from '../../queries/mutation';
import { useMessageTemplateFields } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { MessageTemplate } from '../../utils/models/MessageTemplate';

import Editable from './Editable';

import styles from "../../pages/messageSettings/MessageSettings.module.scss";

interface IProps {
  item: MessageTemplate;
  index: number;
}

const OneMessageTemplate: React.FC<IProps> = ({ item, index }) => {
  const [visible, setVisible] = useState(true);
  const { data, isLoading } = useMessageTemplateFields(item.typeCompactDto.type);
  const updateMessage = useUpdateSmsTemplate(() => {
    setVisible(true);
  });

  const { t } = useTranslation();

  const updateTemplate = (value: string) => {
    updateMessage.mutate({
      type: item.typeCompactDto.type,
      text: value
    });
  };

  const showFields = () => {
    let newText = item.text;

    if (data) {
      for (let i = 0; i < data?.length; i++) {
        newText = newText.replaceAll(`${data[i].key}`, `<span>${getCurrentLanguageWord(data[i] as any)}</span>`);
      }
    }
    return newText;
  };

  return (
    <div className={styles.messageSample}>
      <Spin spinning={isLoading}>
        {!visible ? (
          <>
            <Editable
              updateTemplate={updateTemplate}
              fields={data || []}
              text={item.text}
              title={getCurrentLanguageWord(item.typeCompactDto.name)}
              isLoading={updateMessage.isLoading}
              index={index}
            />
          </>
        ) : (
          <>
            <div className={styles.titleDiv}>
              <h5>{getCurrentLanguageWord(item.typeCompactDto.name)}</h5>
              <div className={styles.edit}>
                <p onClick={() => setVisible(!visible)}>
                  <span>
                    <Edit />
                  </span>
                  {t('client.Tahrirlash')}
                </p>
              </div>
            </div>
            <p
              className={styles.show}
              dangerouslySetInnerHTML={{
                __html: showFields()
              }}
            />
          </>
        )}
      </Spin>
    </div>
  );
};

const isEqual = (prevProps: IProps, nextProps: IProps) => prevProps.item.text === nextProps.item.text;

export default memo(OneMessageTemplate, isEqual);
