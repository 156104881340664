import React from 'react';

interface InterfaceArrowRightProps {
  className?: string;
}

const ArrowRight: React.FC<InterfaceArrowRightProps> = ({ className }) => {
  return (
    <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.4751 9.95992L7.7351 6.69992C8.1201 6.31492 8.1201 5.68492 7.7351 5.29992L4.4751 2.03992"
        stroke="#E6E6E6"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
