import { $api } from '@service/RequestService';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import qs from 'query-string';
import { useQuery } from 'react-query';

import { addressConstants } from '..';

export const useAddressCity = (props: { countryId?: number | null }) =>
  useQuery<BaseLanguageEntity[]>({
    queryKey: [addressConstants.queryKeys.ADDRESS_CITY, props],
    queryFn: async () => {
      const res = await $api.get(qs.stringifyUrl({ url: '/v1/address/city', query: props }));

      return res.data.data;
    },
    staleTime: Infinity
  });
