import { ReactElement } from 'react';
import { useTableConfig } from '@queries/queries';
import { TableKey } from '@utils/models/TableKey';
import { useTranslation } from 'react-i18next';
import { ArrayParam, NumberParam, NumericArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useCrmField, useLeadEmployeeAll } from 'modules/crm';

import { useActivityLogs, useActivityLogsFilter, useActivityLogsFilterClient, useActivityLogsPipes } from '../hooks';
import { eventListConstants } from '..';

import Context, { clearQuery, IContext } from './context';

interface IProps {
  children: ((props: { query: IContext['query'] }) => JSX.Element) | ReactElement;
}

const Provider = ({ children }: IProps) => {
  const [query, setQuery]: [IContext['query'], IContext['setQuery']] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 20),
    startDate: StringParam,
    finishDate: StringParam,
    responsibleByIds: NumericArrayParam,
    objectType: StringParam,
    actionTypes: ArrayParam,
    tab: withDefault(StringParam, 'LEAD'),
    objectStartDate: StringParam,
    objectFinishDate: StringParam,
    previous: StringParam,
    current: StringParam,
    currentTags: ArrayParam,
    previousTags: ArrayParam,
    clientIds: NumericArrayParam,
    search: StringParam,
    currentStatuses: ArrayParam,
    previousStatuses: ArrayParam
  });

  const { tab, ...queryParams } = query;

  const { t } = useTranslation();

  const getParam = () =>
    Object.keys(queryParams)?.reduce<typeof queryParams>(
      (p, c) => {
        if (Array.isArray(queryParams[c as keyof typeof queryParams])) {
          p[c as keyof typeof queryParams] = (queryParams[c as keyof typeof queryParams] as string[]).filter(item => item && item !== 'null' && item !== 'undefined') as any;
        } else {
          p[c as keyof typeof queryParams] = queryParams[c as keyof typeof queryParams] as any;
        }

        return p;
      },
      {} as typeof queryParams
    );

  const { data: leadData, isLoading: leadIsLoading } = useActivityLogsFilter({ ...getParam(), enabled: tab === 'LEAD' });
  const { data: clientData, isLoading: clientIsLoading } = useActivityLogsFilterClient({ ...getParam(), enabled: tab === 'CLIENT' });
  const { data: pipes } = useActivityLogsPipes();
  const { data: crmFields } = useCrmField();
  const { data: leadEmployeeAll } = useLeadEmployeeAll();
  const { data: tableConfig } = useTableConfig(TableKey.activity_logs);
  const { data: activityLogs } = useActivityLogs();

  const setQuerySingle = (values: IContext['query']) => {
    setQuery({
      ...clearQuery,
      ...values
    });
  };

  return (
    <Context.Provider
      value={{
        pipes,
        data: query.tab === 'LEAD' ? leadData : clientData,
        query,
        crmFields,
        leadEmployeeAll,
        dataIsLoading: leadIsLoading || clientIsLoading,
        tableConfig,
        objectTypes: eventListConstants.objectTypes(t)[query.tab!],
        activityLogs,
        setQuery,
        setQuerySingle
      }}
    >
      <>{typeof children === 'function' ? children({ query }) : children}</>
    </Context.Provider>
  );
};

export default Provider;
