import React, { useState } from 'react';
import { Spin } from 'antd';

import SettingIcon from '../../../assets/icons/SettingIcon';
import { useBasicCurrency, useCurrencyList } from '../../../queries/queries';
import { currencyFlags } from '../../../utils/constants/currencyFlags';
import { defaultImage } from '../../../utils/constants/defaultImage';

import ChangeCurrency from './changeCurrency/ChangeCurrency';

import styles from './headerCurrency.module.scss';

const HeaderCurrency = () => {
  const [visible, setVisible] = useState(false);

  const { data, isLoading, isFetching } = useCurrencyList();
  const { data: basic, isLoading: loadingBasic } = useBasicCurrency();

  // handle dropdown
  const handleDropdown = (visible: boolean) => {
    setVisible(visible);
  };

  // get amount not basic
  const getAmountNotBasic = () => data?.find(item => !item.basic)?.value?.toLocaleString('ru');

  return data && data?.length < 2 ? null : (
    <Spin spinning={isLoading || isFetching || loadingBasic}>
      {data?.length || 0 > 1 ? (
        <div className={styles.container}>
          <div className={styles.container_currency}>
            <img src={currencyFlags[`${basic?.ccy.toLowerCase()}`] || defaultImage} alt={basic?.ccy} />
            <span className={styles.container_currency_ccy}>{basic?.ccy}</span>
            <span className={styles.container_currency_value}>{getAmountNotBasic()}</span>
            <div className={styles.container_currency_line} />
          </div>
          {/* eslint-disable-next-line array-callback-return, consistent-return */}
          {data?.map((item, index) => {
            if (!item.basic)
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={styles.container_currency} key={index}>
                  <img src={currencyFlags[`${item.ccy.toLowerCase()}`] || defaultImage} alt={item.ccy} />
                  <span className={styles.container_currency_ccy}>{item.ccy}</span>
                  <span className={styles.container_currency_value}>1</span>
                  <div className={styles.container_currency_line} />
                </div>
              );
          })}
          <ChangeCurrency visible={visible} close={handleDropdown} currencyList={data || []}>
            <SettingIcon onClick={() => handleDropdown(true)} className={styles.container_currency_icon} />
          </ChangeCurrency>
        </div>
      ) : null}
    </Spin>
  );
};

export default HeaderCurrency;
