import React, { useEffect } from 'react';
import { useCreateLeadAndClientCost } from '@pages/bossCrm/services/mutate';
import { useGetLeadSources } from '@pages/bossCrm/services/queries';
import { LeadAndClientCostPayloadModel } from '@pages/bossCrm/utils/models/lead-and-client-cost-payload-model';
import { parseLocaleString } from '@utils/helper/parse-locale-string';
import { Button, Col, Form, Modal, Row, Select, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';
import dayjs from 'dayjs';
import { DatePicker, Input } from 'global';
import { useTranslation } from 'react-i18next';

import InfoIcon from '../../../../assets/icons/InfoIcon';
import { useCurrencyList } from '../../../../queries/queries';

import styles from './createCostModal.module.scss';

const { Option } = Select;

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
}

const CreateBuilderSettings: React.FC<IProps> = ({ visible, close }) => {
  const dateFormat = 'DD.MM.YYYY';
  const { t, i18n } = useTranslation();
  const { data: currencies } = useCurrencyList();
  const { sources, isLoading } = useGetLeadSources();
  const [form] = Form.useForm<LeadAndClientCostPayloadModel>();
  const { mutateAsync, isLoading: isLoadingCreate } = useCreateLeadAndClientCost();

  const onCancel = () => {
    close(false);
  };

  const onFinish = (fields: LeadAndClientCostPayloadModel) => {
    mutateAsync({
      ...fields,
      startDate: dayjs(fields?.startDate, dateFormat).format(dateFormat),
      finishDate: dayjs(fields?.finishDate, dateFormat).format(dateFormat),
      amount: parseLocaleString(fields?.amount)
    }).then(onCancel);
  };

  const sourceLabel = (
    <div className="d-flex align-center">
      <span>{t('boss_crm.Xarajat_turi')}</span>
      <Tooltip title={t('boss_crm.hover_info')}>
        <span className={styles.container_info_icon}>
          <InfoIcon />
        </span>
      </Tooltip>
    </div>
  );

  const formRule: Rule[] = [
    {
      required: true,
      message: ''
    }
  ];

  useEffect(() => {
    if (currencies) {
      form.setFieldsValue({
        currencyId: currencies?.find(({ basic }) => basic)?.id
      });
    }
  }, [currencies, visible]);

  return (
    <Modal
      width={546}
      footer={false}
      centered={true}
      visible={visible}
      onCancel={onCancel}
      className={styles.container}
      afterClose={() => form.resetFields()}
      title={t('boss_crm.Xarajat_qoshish')}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.container_form}>
        <Row>
          <Col className={styles.container_form_left} flex="1">
            <Form.Item label={sourceLabel} name="source" rules={formRule}>
              <Select loading={isLoading} optionFilterProp="children" showSearch className="customSelect" placeholder={t('home.Tanlang')}>
                {sources?.map(({ key, name }) => (
                  <Option key={key} value={key}>
                    {name?.[i18n.language]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('boss_crm.Sana_dan')} name="startDate" rules={formRule}>
              <DatePicker allowClear={false} format={dateFormat} className="w-full rounded-md" placeholder={t('boss_crm.Sana_dan')} />
            </Form.Item>
          </Col>
          <Col className={styles.container_form_right} flex="1">
            <Form.Item label={t('boss_crm.Xarajat_qilingan_summa')}>
              <Form.Item noStyle name="amount" rules={formRule}>
                <Input type="currency" className={styles.container_input} />
              </Form.Item>
              <Form.Item noStyle name="currencyId" rules={formRule}>
                <Select className={`customSelect ${styles.container_select}`}>
                  {currencies?.map(item => (
                    <Option key={item?.id} value={item?.id}>
                      {item?.ccy}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item name="finishDate" className="flex-1" label={t('boss_crm.Sana_gacha')} rules={formRule}>
              <DatePicker allowClear={false} format={dateFormat} className="w-full rounded-md" placeholder={t('boss_crm.Sana_gacha')} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.container_footer}>
          <Button disabled={isLoadingCreate} onClick={onCancel}>
            {t('home.Yopish')}
          </Button>
          <Button loading={isLoadingCreate} htmlType="submit" type="primary">
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateBuilderSettings;
