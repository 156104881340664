const RadiusIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 16.5V15.9C1.5 10.8595 1.5 8.33929 2.48094 6.41408C3.3438 4.72063 4.72063 3.3438 6.41409 2.48094C8.33929 1.5 10.8595 1.5 15.9 1.5H16.5"
        stroke="#464255"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RadiusIcon;
