import React, { useEffect } from 'react';
import { useCreateAndUpdateSip } from '@pages/roboto-calls/services/mutation';
import { SipModel } from '@pages/roboto-calls/utils/models/SipModel';
import { Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<React.SetStateAction<SipModel | undefined>>;
  modalData: SipModel | undefined;
}

const CreateSip: React.FC<IProps> = ({ visible, setVisible, modalData, setModalData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const createSip = useCreateAndUpdateSip();

  useEffect(() => {
    if (modalData?.id) {
      form.setFieldsValue(modalData);
    }
  }, [modalData]);

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
    setModalData(undefined);
  };

  const onFinish = (value: SipModel) => {
    if (modalData) {
      createSip.mutateAsync({ ...value, id: modalData?.id, parallelCallCount: Number(value?.parallelCallCount) }).then(handleClose);
    } else {
      createSip.mutateAsync({ ...value, parallelCallCount: Number(value?.parallelCallCount) }).then(handleClose);
    }
  };

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title={t("home.Sip_kanali_qoshish")}
      okText={t("home.Saqlash")}
      cancelText={t("home.Yopish")}
      okButtonProps={{ style: { height: 38, borderRadius: 50, width: 160 }, loading: createSip.isLoading }}
      cancelButtonProps={{ style: { height: 38, borderRadius: 50, width: 160 } }}
      className="sipCustomModal"
      onOk={onOk}
    >
      <Form layout="vertical" form={form} autoComplete="off" onFinish={onFinish}>
        <Form.Item label={t("home.Sip_nomi")} name="name" rules={[{ required: true, message: '' }]}>
          <Input className="customInput" />
        </Form.Item>
        <Form.Item label={t('integration.Login')} name="username" rules={[{ required: true, message: '' }]}>
          <Input className="customInput" />
        </Form.Item>
        <Form.Item label={t('home.Host')} name="endpoint" rules={[{ required: true, message: '' }]}>
          <Input className="customInput" />
        </Form.Item>
        <Form.Item label={t('integration.Parol')} name="password" rules={[{ required: true, message: '' }]}>
          <Input className="customInput" />
        </Form.Item>
        <Form.Item label={t('home.Parallel_qongiroqlar_soni')} name="parallelCallCount" rules={[{ required: true, message: '' }]}>
          <InputMask
            mask="99999999999999"
            className="customInput ant-input"
            // @ts-ignore*
            maskChar=""
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSip;
