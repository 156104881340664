import React from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { useGetAllCountries, useGetCountries } from '@pages/oneLid/services/queries';
import i18n from '@service/I18nService';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { CrmField } from '@utils/models/CrmField';
import { Select } from 'antd';
import { t } from 'i18next';

import styles from './info.module.scss';

type Props = {
  item: CrmField;
  index: number;
};

const { Option } = Select;

const convertAddress = (value: string) => {
  const obj = value && value.includes('{') && value.includes('}') ? JSON.parse(value) : {};

  return {
    ...obj
  };
};

const SelectAddressFilter: React.FC<Props> = ({ item }) => {
  const { searchParams, remove, appendMultiple } = useQueryParam<SearchParamsCrm, any>();
  const fields = useAppSelector(state => state.crmOneLidField.leadFieldValue);
  const fieldValue = fields?.find(prev => prev?.id === item?.id)?.value;
  const { data: allCountries } = useGetAllCountries();

  const searchParamsFields = ((Array.isArray(searchParams?.fields) ? searchParams?.fields : [searchParams?.fields]) || [])?.reduce(
    (p: Record<string, string>, c: string) => {
      p[c?.split('_')[0]] = c?.split('_')[1];
      return p;
    },
    {} as Record<string, string>
  );

  const values = decodeURIComponent(searchParamsFields[String(item.id)] || '').split(',');

  const countryValueZone = allCountries?.find(c => c?.country_uz === values[values.length - 1])?.zone || null;
  const regionValue = values[values.length - 2];
  const cityValue = values[values.length - 3];

  const { data: oneCountry } = useGetCountries(countryValueZone || '');

  const result =
    oneCountry &&
    oneCountry?.reduce((acc: any, curr: any) => {
      const key = curr.region_uz;

      if (acc[key]) {
        acc[key].push(curr);
      } else {
        acc[key] = [curr];
      }
      return acc;
    }, {});

  const result2 = oneCountry && Object.keys(result).map(key => [key, result[key]]);
  const finalData = result2?.filter((item, index) => result2?.indexOf(item) === index);

  const cityData = finalData
    ?.find(item => item[0] === regionValue)
    ?.filter(prev => prev !== convertAddress(fieldValue!)?.region)
    ?.flat()
    ?.filter(item => typeof item === 'object');

  const changeText = (e: string) => {
    if (Array.isArray(searchParams?.fields)) {
      searchParams?.fields.forEach(prev => {
        if (prev.split('_')[0] === String(item?.id)) {
          remove('fields', prev);
        }
      });
    } else if (searchParams?.fields?.split('_')[0] === String(item?.id)) {
      remove('fields', searchParams?.fields);
    }
    if (e) {
      appendMultiple('fields', urlEncode(`${item?.id}_${e}`));
    }
  };

  const formatData = (data: any) => {
    const city = data.city_uz || '';
    const region = data.region_uz || '';
    const country = data.country_uz || '';

    let formattedString = '';

    if (city) formattedString += city;
    if (region) formattedString += (formattedString ? ',' : '') + region;
    if (country) formattedString += (formattedString ? ',' : '') + country;

    return formattedString;
  };

  return (
    <div className={styles.container_body_item}>
      <div className={styles.filterSelects}>
        <Select
          value={countryValueZone}
          showSearch
          allowClear
          placeholder={t('home.Davlat')}
          onChange={e => {
            const selectedCountry = allCountries?.find(item => item?.zone === e);
            const sendData = {
              [`city_${i18n.language}`]: '',
              [`region_${i18n.language}`]: '',
              ...selectedCountry
            };
            const jsonFile = formatData(sendData);

            changeText(jsonFile);
          }}
        >
          {allCountries?.map(item => (
            <Option value={item?.zone} key={item?.zone}>
              {item[`country_${i18n.language}` as keyof typeof item]}
            </Option>
          ))}
        </Select>
        <Select
          value={regionValue}
          disabled={!countryValueZone}
          showSearch
          allowClear
          placeholder={t('home.Viloyat')}
          onChange={e => {
            if (e) {
              const sendData = finalData?.find(item => item[0] === e)![1]?.find((prev: any) => prev?.region_uz === e);
              const newData = {
                ...sendData,
                city_uz: ''
              };
              const jsonFile = formatData(newData);

              changeText(jsonFile);
            } else {
              const selectedCountry = allCountries?.find(item => item?.zone === countryValueZone);
              const jsonFile = formatData(selectedCountry);

              changeText(jsonFile);
            }
          }}
        >
          {finalData?.map((item, index) => (
            <Option
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={item[0]}
            >
              {item[1][0][`region_${i18n.language}`]}
            </Option>
          ))}
        </Select>
        <Select
          value={cityValue}
          disabled={!regionValue}
          showSearch
          allowClear
          placeholder={t('home.Shahar/tuman')}
          onChange={e => {
            if (e) {
              const sendData = cityData?.find(item => item?.city_uz === e);
              const jsonFile = formatData(sendData);

              changeText(jsonFile);
            } else {
              const selectedCountry = allCountries?.find(item => item?.zone === countryValueZone);
              const selectedRegion = finalData?.find(item => item[0] === regionValue)?.[1]?.[0];
              const newData = {
                ...selectedCountry,
                ...selectedRegion,
                city_uz: ''
              };
              const jsonFile = formatData(newData);

              changeText(jsonFile);
            }
          }}
        >
          {cityData?.map((item: any, index: number) => (
            <Option
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              value={item?.city_uz}
            >
              {item[`city_${i18n.language}`]}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SelectAddressFilter;
