import React from 'react';
import styles from './paymentSumCurrencyList.module.scss';
import { Dropdown } from 'antd';
import { currencyFlags } from '../../../../utils/constants/currencyFlags';
import { defaultImage } from '../../../../utils/constants/defaultImage';
import { PaymentSumItemCurrency } from '../../../../utils/models/PaymentSum';

interface Props {
  currencyItems: PaymentSumItemCurrency[] | undefined;
  visible: boolean;
  close: (visible: boolean) => void;
}

const PaymentSumCurrencyList: React.FC<Props> = ({ children, currencyItems, visible, close }) => {
  return (
    <Dropdown
      visible={visible}
      onVisibleChange={close}
      overlayClassName={styles.container}
      overlay={
        <>
          {currencyItems?.map((item, index) => (
            <div key={index}>
              <img
                className={styles.container_item_img}
                alt={item?.currency?.ccy}
                src={currencyFlags[`${item?.currency?.ccy.toLowerCase()}`] || defaultImage}
              />
              <span className={styles.container_item_ccy}>{item?.currency?.ccy}</span>
              <span className={styles.container_item_amount}>{item?.total?.toLocaleString('ru')}</span>
            </div>
          ))}
        </>
      }
      placement={'topLeft'}
      trigger={['click']}
    >
      {children}
    </Dropdown>
  );
};

export default PaymentSumCurrencyList;
