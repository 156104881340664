import React, { useState } from 'react';
import IncrementPrice from '@pages/shaxmatka/components/increment-price/IncrementPrice';
import { ShaxmatkaContextDataState } from '@pages/shaxmatka/hooks/ShaxmatkaContext';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import CheveronBottom from '../../assets/icons/CheveronBottom';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useHouseConfigList } from '../../queries/queries';
import { setNotSalePageSize, setSalePageSize } from '../../store/reducers/HouseConfigSlice';

import HouseCard from './HouseCard';

import styles from './houseConfig.module.scss';

interface Props {
  sale: boolean;
  title: string;
  isSales: boolean;
}

const HouseCardsCont: React.FC<Props> = ({ sale, title, isSales }) => {
  const [incrementPrice, setIncrementPrice] = useState<ShaxmatkaContextDataState>({
    houseId: 0,
    incrementPrice: ''
  });
  const { searchParams } = useQueryParam<
    {
      search: string;
    },
    any
  >();
  const [pageSize, setPageSize] = useState(10);
  const { isLoading, data } = useHouseConfigList(pageSize, 1, sale, searchParams.search);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const savePages = () => {
    const size = pageSize === 10 ? data?.totalElements || 10 : 10;

    if (sale) {
      dispatch(setSalePageSize(size));
    } else {
      dispatch(setNotSalePageSize(size));
    }
  };
  const openAll = () => {
    setPageSize(pageSize === 10 ? data?.totalElements || 10 : 10);
    savePages();
  };

  return (
    <div className={styles.cards}>
      <h1 className={styles.cards_title}>{title}</h1>
      <div className={styles.cards_cont}>
        {isLoading ? (
          <>
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
            <Skeleton.Button className={styles.cards_cont_skeleton} active />
          </>
        ) : (
          data?.data.map((house, index) => <HouseCard isSales={isSales} house={house} key={house.id} setIncrementPrice={setIncrementPrice} />)
        )}
      </div>
      {Number(data?.totalElements) > 10 && (
        <div className={`d-flex align-center justify-center ${styles.cards_show_all}`}>
          <div onClick={openAll}>
            <span>{pageSize > 10 ? t('home.Yopish') : t('house_config.Barchasini_korish')}</span>
            <span className={pageSize > 10 ? styles.icon_cont_bottom : styles.icon_cont_top}>
              <CheveronBottom />
            </span>
          </div>
        </div>
      )}
      <IncrementPrice context={incrementPrice} setContext={setIncrementPrice} />
    </div>
  );
};

export default HouseCardsCont;
