import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { Pagination } from "@utils/models/Pagination";
import { useQuery } from "react-query";

import { SearchParamsRobotCalls } from "../components/roboto-drawer/RobotoDrawer";
import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { ChannelModel } from "../utils/models/ChannelModel";
import { OneCampagingModel } from "../utils/models/OneCampagingModel";
import { SipModel } from "../utils/models/SipModel";

export function useGetRobotoSip() {
  return useQuery<Pagination<SipModel>>({
    queryKey: queryKeys.ROBOTO_SIP, queryFn: async () => {
      const res = await $api.get(`${endPoints.ROBOTO_SIP}?page=1&size=10`)

      return res.data
    },
    onError: baseErrorHandler
  })
}

export function useGetAllCampagins(searchParams: SearchParamsRobotCalls) {

  return useQuery<Pagination<OneCampagingModel>>({
    queryKey: [queryKeys.CAMPAGING_ALL, { ...searchParams }],
    queryFn: async () => {
      const res = await $api.post(endPoints.CAMPAGING_ALL,
        {
          ...searchParams,
          // eslint-disable-next-line no-nested-ternary
          statuses: searchParams?.statuses && (Array.isArray(searchParams?.statuses) ? searchParams?.statuses : [searchParams?.statuses]),
          page: searchParams?.page || 1,
          size: searchParams?.size || 15
        }
      )

      return res.data.data
    },
    onError: baseErrorHandler
  })
}

export function useGetOneCampagin(id: number) {
  return useQuery<OneCampagingModel>({
    queryKey: [queryKeys.ONE_CAMPAGING, id], queryFn: async () => {
      const res = await $api.get(`${endPoints.ONE_CAMPAGING}/${id}`)

      return res.data.data
    },
    onError: baseErrorHandler
  })
}

export function useGetAllChannelsShort() {
  return useQuery<ChannelModel[]>({
    queryKey: [queryKeys.CHANNEL_SHORT], queryFn: async () => {
      const res = await $api.get(endPoints.CHANNEL_SHORT)

      return res.data.data
    },
    onError: baseErrorHandler
  })
}