import { ResponsiveLine } from '@nivo/line';
import styles from './ledBar.module.scss';
import { useTranslation } from 'react-i18next';
import { AnalyticCountContractLead } from '@pages/boss/bi-analytics/utils/models/AnalyticCountContractLead';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@utils/constants/format';
import { modifyArray } from '@pages/boss/bi-analytics/utils/helpers/createRoundedArray';

type ContractData = {
  id: string;
  data: {
    x: string;
    y: number;
  };
};
const LedBar = ({ leadData, arrayOfMonths }: { leadData: AnalyticCountContractLead; arrayOfMonths: string[] }) => {
  const { t } = useTranslation();
  let newData: any = [];
  Object.entries(leadData || {}).map(item => {
    newData.push({
      id: item?.[0] === 'lead' ? t('bi_analytics.Tushgan_leadlar_soni') : t('bi_analytics.Sotuvlar_soni'),
      data: arrayOfMonths.map(month => ({
        x: t(`boss_payments.${dayjs(month, DATE_FORMAT).format('MMM')}`),
        y: item?.[1].find(el => el?.date === month) ? item?.[1].find(el => el?.date === month)?.count : 0
      }))
    });
  });

  return (
    <div className={styles.led_bar_container}>
      <ResponsiveLine
        data={newData || []}
        margin={{ top: 20, right: 55, bottom: 49, left: 55 }}
        xScale={{ type: 'point' }}
        colors={['#FF9364', '#00A389']}
        yScale={{
          type: 'linear',
          min: 0
        }}
        axisTop={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0
        }}
        enableSlices="x"
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: value => ''
        }}
        axisRight={{
          format: value => '',
          tickSize: 5,
          tickPadding: 5
        }}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 52,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 190,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)'
          }
        ]}
        layers={[
          'axes',
          'grid',
          prop => {
            const contract = prop?.data.find(item => item?.id === t('bi_analytics.Sotuvlar_soni'));
            // sale y axis numbers
            const arrOfSale: number[] = [];
            for (let i = 0; i < contract?.data!?.length; i++) {
              arrOfSale.push(contract!?.data[i]?.y as number);
            }

            let numberArrCopy = [...arrOfSale];

            numberArrCopy[numberArrCopy.length - 1] = 0;
            const newArrCopy = numberArrCopy.filter((value, index) => numberArrCopy.indexOf(value) === index);
            const roundedArray = modifyArray(newArrCopy);
            let maxSale = Math.max(...arrOfSale);
            let monthGap = prop?.innerWidth / 11;
            let countHeight = prop?.innerHeight / maxSale;
            const arrCopy = arrOfSale.map((item, index) => `${monthGap * index}, ${(maxSale - item) * countHeight}`);

            // leads y axis numbers
            const lead = prop?.data.find(item => item?.id === t('bi_analytics.Tushgan_leadlar_soni'));
            const arrOflead: number[] = [];
            for (let i = 0; i < lead?.data!?.length; i++) {
              arrOflead.push(lead!?.data[i]?.y as number);
            }
            let numberArrCopyLead = [...arrOflead];
            numberArrCopyLead[numberArrCopyLead.length - 1] = 0;
            const newArrCopyLead = numberArrCopyLead.filter((value, index) => numberArrCopyLead.indexOf(value) === index);
            const roundedArrayLead = modifyArray(newArrCopyLead);
            let maxLead = Math.max(...arrOflead);
            let monthGapLead = prop?.innerWidth / 11;
            let countHeightLead = prop?.innerHeight / maxLead;
            const arrCopyLead = arrOflead.map((item, index) => `${monthGapLead * index}, ${(maxLead - item) * countHeightLead}`);

            return (
              <g>
                <g>
                  {roundedArray
                    .sort((a, b) => (b as number) - (a as number))
                    .map((item, i) => (
                      <text
                        key={i}
                        x={prop?.innerWidth + 25}
                        y={(prop.innerHeight / 9) * i}
                        fontSize="11px"
                        fill="#00A389"
                        dominantBaseline="central"
                        transform="translate(-10,0) rotate(0)"
                      >
                        {item}
                      </text>
                    ))}
                  {roundedArrayLead
                    .sort((a, b) => (b as number) - (a as number))
                    .map((item, i) => (
                      <text
                        key={i}
                        x={0}
                        y={(prop.innerHeight / 9) * i}
                        fontSize="11px"
                        fill="#FF9364"
                        dominantBaseline="central"
                        transform="translate(-10,0) rotate(0)"
                        textAnchor="end"
                      >
                        {item}
                      </text>
                    ))}
                </g>
                <g>
                  <path d={'M' + arrCopy.join(' ')} stroke="#00A389" fill="transparent" strokeWidth="1.6" />
                </g>
                <g>
                  <path d={'M' + arrCopyLead.join(' ')} stroke="#FF9364" fill="transparent" strokeWidth="1.6" />
                </g>
                <g>
                  {arrCopy.map((item, i) => {
                    return <circle id={item} cx={`${item?.split(',')[0]}`} cy={`${item?.split(',')[1]}`} r="3" fill="#00A389" stroke="#00A389" />;
                  })}
                </g>
                <g>
                  {arrCopyLead?.map((item, i) => (
                    <circle id={item} cx={`${item?.split(',')[0]}`} cy={`${item?.split(',')[1]}`} r="3" fill="#FF9364" stroke="#FF9364" />
                  ))}
                </g>
              </g>
            );
          },
          'slices',
          'legends'
        ]}
        enableCrosshair={false}
      />
    </div>
  );
};

export default LedBar;
