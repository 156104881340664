import React from 'react';

const PaginationPrev = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.76413 5.99447C3.76514 5.87894 3.81098 5.76833 3.89199 5.68596L8.25562 1.32232C8.33792 1.24283 8.44815 1.19885 8.56256 1.19985C8.67698 1.20084 8.78642 1.24673 8.86733 1.32764C8.94823 1.40854 8.99412 1.51799 8.99512 1.6324C8.99611 1.74681 8.95213 1.85704 8.87264 1.93934L4.81752 5.99447L8.87264 10.0496C8.91432 10.0898 8.94756 10.138 8.97043 10.1912C8.9933 10.2445 9.00534 10.3017 9.00584 10.3597C9.00635 10.4176 8.99531 10.4751 8.97336 10.5287C8.95142 10.5823 8.91902 10.6311 8.87805 10.672C8.83708 10.713 8.78836 10.7454 8.73473 10.7673C8.6811 10.7893 8.62364 10.8003 8.5657 10.7998C8.50776 10.7993 8.4505 10.7873 8.39727 10.7644C8.34403 10.7415 8.29588 10.7083 8.25562 10.6666L3.89199 6.30298C3.81098 6.22061 3.76514 6.10999 3.76413 5.99447Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default PaginationPrev;
