import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageList } from '../../../utils/models/MessageList';
interface SmsClientModalProps {
  visible: boolean;
  close: () => void;
  data: MessageList | null;
}

const MessageModal: FC<SmsClientModalProps> = ({ visible, close, data }) => {
  const { t } = useTranslation();
  const handleCancel = () => {
    close();
  };
  return (
    <Modal title={data?.clientName} wrapClassName="smsClientModal" visible={visible} onCancel={handleCancel} footer={false} width={575}>
      <table>
        <tr>
          <th>{t('message.Yuborilgan_sana')}</th>
          <th>{data?.sendDate}</th>
        </tr>
        <tr>
          <td>{t('message.Status')}</td>
          <td>
            <span
              style={{
                backgroundColor:
                  data?.status === 'WAS_SENT'
                    ? 'rgba(0, 163, 137, 0.15)'
                    : data?.status === 'WRONG_MESSAGE'
                      ? 'rgba(89, 97, 249, 0.15)'
                      : data?.status === 'WILL_BE_SENT'
                        ? 'rgba(0, 163, 137, 0.15)'
                        : 'rgba(229, 53, 53, 0.15)',
                color:
                  data?.status === 'WAS_SENT'
                    ? '#00a389'
                    : data?.status === 'WRONG_MESSAGE'
                      ? '#5961f9'
                      : data?.status === 'WILL_BE_SENT'
                        ? '#00a389'
                        : '#e53535'
              }}
            >
              {t(`home.${data?.status}`)}
            </span>
          </td>
        </tr>
      </table>
      <h1>{t('message.Xabar_matni')}</h1>
      <p>{data?.text}</p>
      <div>
        <Button onClick={handleCancel}>{t('message.Yopish')}</Button>
      </div>
    </Modal>
  );
};

export default MessageModal;
