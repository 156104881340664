import React, { useState } from 'react';
import { Checkbox, Input, Modal, Popover, Select } from 'antd';
import { t } from 'i18next';

import DeleteAreaArageIcon from '../../../../../../assets/icons/areaarage/DeleteAreaArageIcon';
import AlignLeftIcon from '../../../../../../assets/icons/webforma/AlignLeftIcon';
import RadiusIcon from '../../../../../../assets/icons/webforma/RadiusIcon';
import SquareBorderIcon from '../../../../../../assets/icons/webforma/SquareBorderIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import {
  changeFieldBackgroundColor,
  changeFieldBorderColor,
  changeFieldBorderRadius,
  changeFieldColor,
  changeFieldHeight,
  clearForm,
  mainBorderColor,
  mainBorderRadius,
  mainBoxShadow,
  mainFontFamily,
  setFieldsFontSize,
  setOpenShablon,
  textPositionLeft
} from '../../../../../../store/reducers/TextEditorSlice';
import { useDeleteWebForm } from '../service/mutation';
import { useGetOneForm } from '../service/queries';

import styles from '../dragDrop.module.scss';

const { Option } = Select;

const HeaderSettings = () => {
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const { height, fieldBorderColor, fieldBackgroundColor, color, fieldBorderType } = useAppSelector(state => state.textEditorReducer.fieldsStyle);
  const { main } = useAppSelector(state => state.textEditorReducer);
  const deleteForm = useDeleteWebForm(main?.formId);
  const { data: currentForm } = useGetOneForm(main?.formId);

  const handleCloseModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenModal(false);
  };
  const handleDeleteForm = () =>
    deleteForm.mutateAsync().then(() => {
      setOpenModal(false);
      dispatch(setOpenShablon(false));
      dispatch(clearForm());
    });

  const fontFamilys = ['cursive', 'monospace', 'Times new roman'];
  const fontSizes = ['12', '13', '14', '15', '16', '17', '18', '19', '20'];

  const content = (
    <div
      onMouseDown={e => e.stopPropagation()}
      onMouseEnter={e => e.stopPropagation()}
      onMouseLeave={e => e.stopPropagation()}
      className={styles.content}
    >
      <input type="range" value={main.borderRadius} onChange={e => dispatch(mainBorderRadius(+e.target.value))} max={60} />
      <p className={styles.content_paragraph}>Border radius: {main.borderRadius}</p>
      <div className={styles.content_child}>
        <p>Border color:</p>
        <div className={styles.content_child_color} style={{ backgroundColor: main.borderColor }} />
        <input type="color" value={main.borderColor} onChange={e => dispatch(mainBorderColor(e.target.value))} className={styles.colorInput} />
      </div>
      <div className={styles.content_child}>
        <p>Box shadow</p>
        <Checkbox checked={main.boxShadow} onChange={e => dispatch(mainBoxShadow(e.target.checked))} />
      </div>
    </div>
  );

  const content1 = (
    <div
      className={styles.content1}
      onMouseDown={e => e.stopPropagation()}
      onMouseEnter={e => e.stopPropagation()}
      onMouseLeave={e => e.stopPropagation()}
    >
      <div className={styles.content1_left}>
        <span>Field form</span>
        <div className={styles.content1_left_aligment}>
          <div
            className={styles.content1_left_aligment_child}
            style={{
              backgroundColor: fieldBorderType === 'ELLIPSE' ? '#e4eefd' : ''
            }}
            onClick={() => {
              dispatch(changeFieldBorderRadius('ELLIPSE'));
            }}
          >
            <AlignLeftIcon />
          </div>
          <div
            className={styles.content1_left_aligment_child}
            style={{
              backgroundColor: fieldBorderType === 'SQUARE' ? '#e4eefd' : ''
            }}
            onClick={() => {
              dispatch(changeFieldBorderRadius('SQUARE'));
            }}
          >
            <SquareBorderIcon />
          </div>
        </div>
        <span>Field and submit height: {height}px</span>
        <div>
          <input type="range" value={height} max={60} min={40} onChange={e => dispatch(changeFieldHeight(Number(e.target.value)))} />
        </div>
        <div className={styles.content1_left_colors}>
          <div className={styles.content1_left_colors_border}>
            <span>Border color</span>
            <div className={styles.content1_left_colors_border_input}>
              <div style={{ backgroundColor: fieldBorderColor }} />
              <Input type="color" value={fieldBorderColor} onChange={e => dispatch(changeFieldBorderColor(e.target.value))} />
            </div>
          </div>
          <div className={styles.content1_left_colors_back}>
            <span>Background color</span>
            <div className={styles.content1_left_colors_border_input}>
              <div style={{ backgroundColor: fieldBackgroundColor }} />
              <Input type="color" value={fieldBackgroundColor} onChange={e => dispatch(changeFieldBackgroundColor(e.target.value))} />
            </div>
          </div>
        </div>
        <div>
          <span>Font size:</span>
          <Select onChange={e => dispatch(setFieldsFontSize(+e))} defaultValue="14" style={{ width: '80px' }}>
            {fontSizes?.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );

  const content2 = (
    <div className={styles.content2}>
      <span
        onClick={() => {
          dispatch(textPositionLeft('flex'));
          setOpen2(false);
        }}
      >
        Left
      </span>
      <span
        onClick={() => {
          dispatch(textPositionLeft('block'));
          setOpen2(false);
        }}
      >
        Top
      </span>
    </div>
  );

  const content3 = (
    <div className={styles.settingsFontFamily} style={{ display: 'flex', flexDirection: 'column' }}>
      {fontFamilys?.map(item => (
        <p
          style={{
            backgroundColor: main.fontFamily === item ? '#bae7ff' : '',
            cursor: 'pointer'
          }}
          key={item}
          onClick={() => {
            dispatch(mainFontFamily(item));
            setOpen3(false);
          }}
        >
          {item}
        </p>
      ))}
    </div>
  );
  const deleteContent = (
    <Modal
      visible={openModal}
      onCancel={handleCloseModal}
      onOk={handleDeleteForm}
      okButtonProps={{ loading: deleteForm?.isLoading }}
      okText={t('boss_crm.Ochirish')}
      cancelText={t('home.Bekor_qilish')}
      okType="danger"
      centered
    >
      <div>
        {t('home.Haqiqatdan_ham_ochirmoqchimisiz?')}({currentForm?.name})
      </div>
    </Modal>
  );

  const handleOpenChange2 = (newOpen: boolean) => {
    setOpen2(newOpen);
  };
  const handleOpenChange3 = (newOpen: boolean) => {
    setOpen3(newOpen);
  };

  return (
    <div className={styles.settingsHeader}>
      <div className={styles.settingsHeader_child}>
        <Popover trigger="click" content={content}>
          <div>
            <RadiusIcon />
            <span>Form radius</span>
          </div>
        </Popover>
      </div>
      <div className={styles.settingsHeader_child}>
        <div className={styles.settingsHeader_child_first}>
          <div style={{ backgroundColor: color }} className={styles.settingsHeader_child_first_mask} />
          <Input type="color" value={color} onChange={e => dispatch(changeFieldColor(e.target.value))} className={styles.colorInput} />
          <span>Text color</span>
        </div>
      </div>
      <div className={styles.settingsHeader_child}>
        <Popover trigger="click" content={content1}>
          <span>Field style</span>
        </Popover>
      </div>
      <div className={styles.settingsHeader_child}>
        <Popover trigger="click" visible={open2} content={content2} onVisibleChange={handleOpenChange2}>
          <span>Text position</span>
        </Popover>
      </div>
      <div className={styles.settingsHeader_child}>
        <Popover visible={open3} onVisibleChange={handleOpenChange3} trigger="click" content={content3} className={styles.content3_popover}>
          <span>Fonts</span>
        </Popover>
      </div>
      {currentForm && (
        <div className={styles.settingsHeader_child} onClick={() => setOpenModal(true)}>
          <span>Delete</span>
          <DeleteAreaArageIcon />
          {deleteContent}
        </div>
      )}
    </div>
  );
};

export default HeaderSettings;
