import React, { FC, memo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useQueryClient } from 'react-query';
import AddCircleBgBlueIcon from '../../../../assets/icons/AddCircleBgBlueIcon';
import DostsSixVertical from '../../../../assets/icons/DotsSixVertical';
import EllipseIcon from '../../../../assets/icons/EllipseIcon';
import TrushSquareBlackStrokeIcon from '../../../../assets/icons/TrushSquareBlackStrokeIcon';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { queryNames } from '../../../../utils/constants/queryNames';
import { PipeAll, PipeStatuses } from '../../../../utils/models/PipeAll';
import DeleteColumn from './deleteColumn/DeleteColumn';
import VoronkaCardColumnEmty from './VoronkaCardColumnEmty';
import VoronkaCardItemList from './VoronkaCardItemList';
import styles from './voronkaCards.module.scss';
import Actions from '../listView/actions/Actions';
import { CrmUserRoleModel } from '@pages/crm/utils/models/CrmUserRoleModel';

interface IProps {
  column: PipeStatuses;
  index: number;
  loading: boolean;
  pipeData: PipeAll;
  columnSecondId?: number;
  currentUserRoleData?: CrmUserRoleModel | undefined;
}

const colors = [
  '#FFA12F',
  '#FFC682',
  '#0AB4FF',
  '#75D5FF',
  '#7B68EE',
  '#A799FF',
  '#1DB954',
  '#62FF99',
  '#F900EA',
  '#FF8BF8',
  '#F42C2C',
  '#FF7E7E',
  '#2C2DA0',
  '#8D8FFF',
  '#00A389',
  '#00DDBA',
  '#FF5722',
  '#FF9C7D'
];

const VoronkaCardColumn: FC<IProps> = ({ column, index, loading, pipeData, columnSecondId, currentUserRoleData }) => {
  const qc = useQueryClient();
  const [colorContainerVisible, setColorContainerVisible] = useState(false);
  const [columnDeleteVisible, setColumnDeleteVisible] = useState(false);

  const sortableColumn = useAppSelector(state => state.assigmentSlice.sortableColumn);

  const activeIds = useAppSelector(state => state.assigmentSlice.listViewActiveIds);

  const handleAddColumn = async () => {
    const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);

    sourceStatusData
      ?.find(item => item.id === pipeData.id)
      ?.pipeStatuses.splice(index + 1, 0, {
        color: '#87898E',
        name: '',
        orders: pipeData.pipeStatuses.length + 1,
        pipeId: pipeData.id
      });

    qc.setQueryData([queryNames.PIPE_ALL], () =>
      sourceStatusData?.map(item => {
        return {
          ...item,
          pipeStatuses: item.id === pipeData.id ? sourceStatusData?.find(item => item.id === pipeData.id)?.pipeStatuses : item.pipeStatuses
        };
      })
    );

    document.getElementById(String(index + 1))?.focus();
  };

  const changeHeaderText = (e: any) => {
    const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);

    const newData = sourceStatusData
      ?.find(item => item.id === pipeData.id)
      ?.pipeStatuses.map(item => ({
        ...item,
        name: item.orders === column.orders ? e.target.value : item.name
      }));

    qc.setQueryData([queryNames.PIPE_ALL], () =>
      sourceStatusData?.map(item => {
        return {
          ...item,
          pipeStatuses: item.orders === pipeData.orders ? newData : item.pipeStatuses
        };
      })
    );
  };

  const handleDelete = () => {
    if (column.id) {
      setColumnDeleteVisible(true);
    } else {
      const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);

      sourceStatusData?.find(item => item.id === pipeData.id)?.pipeStatuses.splice(index, 1);

      qc.setQueryData([queryNames.PIPE_ALL], () =>
        sourceStatusData?.map(item => {
          return {
            ...item,
            pipeStatuses: item.id === pipeData.id ? sourceStatusData?.find(item => item.id === pipeData.id)?.pipeStatuses : item.pipeStatuses
          };
        })
      );
    }
  };

  const handleColorContainer = (e: any) => {
    e.stopPropagation();
    setColorContainerVisible(!colorContainerVisible);
  };

  const mouseLeave = () => {
    setColorContainerVisible(false);
  };

  const handleColor = (color: string) => {
    const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);

    const newData = sourceStatusData
      ?.find(item => item.id === pipeData.id)
      ?.pipeStatuses.map(item => ({
        ...item,
        color: item.orders === column.orders ? color : item.color
      }));

    qc.setQueryData([queryNames.PIPE_ALL], () =>
      sourceStatusData?.map(item => {
        return {
          ...item,
          pipeStatuses: item.orders === pipeData.orders ? newData : item.pipeStatuses
        };
      })
    );
    setColorContainerVisible(false);
  };

  const handleColumn = () => {
    setColorContainerVisible(false);
  };

  return (
    <>
      <Draggable
        draggableId={String(column?.name + column?.orders)}
        index={index}
        key={column.id}
        isDragDisabled={column.type === 'FIRST' || column.orders > 1000}
        shouldRespectForcePress={column.orders > 1000}
      >
        {provided => (
          <div
            {...(column.type === 'FIRST' ? null : provided.draggableProps)}
            ref={provided.innerRef}
            className={styles.voronkaCards_column}
            onMouseLeave={mouseLeave}
            onClick={handleColumn}
            style={{
              ...(column.type === 'FIRST' ? {} : provided.draggableProps.style),
              minHeight: activeIds ? '100%' : 'calc(100% - 100px)',
              maxHeight: activeIds ? '100%' : 'calc(100% - 100px)'
            }}
          >
            {sortableColumn ? (
              <>
                <div className={styles.voronkaCards_column_header} style={{ borderColor: column.color }}>
                  <div
                    className={styles.voronkaCards_column_header_left}
                    {...provided.dragHandleProps}
                    onMouseMove={e => e.stopPropagation()}
                    onMouseDown={e => e.stopPropagation()}
                  >
                    <DostsSixVertical />
                  </div>
                  {column.orders < 1000 && (
                    <div onClick={handleAddColumn} className={styles.voronkaCards_column_header_right_addColumn}>
                      <AddCircleBgBlueIcon />
                    </div>
                  )}
                  <input
                    onChange={changeHeaderText}
                    className={styles.voronkaCards_column_header_input}
                    defaultValue={column.name}
                    style={{ color: column.color }}
                    id={`${index}`}
                  />
                  {column.type !== 'FIRST' && index !== 1 && column.orders < 1000 && (
                    <div onClick={handleDelete} className={styles.voronkaCards_column_header_delete}>
                      <TrushSquareBlackStrokeIcon />
                    </div>
                  )}
                  <div className={styles.voronkaCards_column_header_color} onClick={handleColorContainer}>
                    <EllipseIcon background={column.color} stroke={column.color} />
                    <div
                      className={`${styles.voronkaCards_column_header_color_container} ${
                        colorContainerVisible ? styles.voronkaCards_column_header_color_container_active : ''
                      }`}
                    >
                      {colors.map((item, index) => {
                        return (
                          <span key={index} onClick={() => handleColor(item)}>
                            <EllipseIcon background={item} stroke={item} />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <VoronkaCardColumnEmty column={column} />
              </>
            ) : (
              <>
                <VoronkaCardItemList
                  column={column}
                  index={index}
                  loading={loading}
                  columnSecondId={columnSecondId}
                  currentUserRoleData={currentUserRoleData}
                />
              </>
            )}
          </div>
        )}
      </Draggable>
      <DeleteColumn id={column.id!} visible={columnDeleteVisible} setVisble={setColumnDeleteVisible} pipeId={pipeData.id} index={index} />
    </>
  );
};

export default memo(VoronkaCardColumn);
