import React from 'react';

const SliderIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 14.6693H6.00004C4.33337 14.6693 3.33337 13.4693 3.33337 12.0026V4.0026C3.33337 2.53594 4.33337 1.33594 6.00004 1.33594H10C11.6667 1.33594 12.6667 2.53594 12.6667 4.0026V12.0026C12.6667 13.4693 11.6667 14.6693 10 14.6693Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3.33337 10.6758H12.6667" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.33337 2.66992V13.3366" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.6666 2.66992V13.3366" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SliderIcon;
