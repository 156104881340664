import { useQueryParam } from '@hooks/useQueryParams';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';

import { useAppDispatch } from '../../../hooks/reduxHooks';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { setLeadNotTaskConf } from '../../../store/reducers/CrmFieldSlice';
import { BookingLeadFlat } from '../../../utils/models/BookingLeadFlat';
import { LeadEventOrTask, LeadEventsAndTasksList } from '../../../utils/models/LeadEventsAndTaskList';
import { LeaadMemberData } from '../../../utils/models/LeadMemberData';
import { LeadNotTaskConf } from '../../../utils/models/LeadNotTaskConf';
import { OneLid } from '../../../utils/models/OneLid';
import { OnlinePbxUser } from '../../../utils/models/OnlinePbx';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { getEvents } from '../utils/helper/getEvents';
import { AllCountriesModel } from '../utils/models/AllCountriesModel';
import { ContractAndBookingModel } from '../utils/models/ContractAndBookingModel';
import { InviteClientModel } from '../utils/models/InviteClientModel';
import { OneClientModel } from '../utils/models/OneClientModel';
import { OneCountryModel } from '../utils/models/OneCountryModel';
import { ViewOneClient } from '../utils/models/ViewOneClientModel';

export function useOneLid(id: number) {
  const { push } = useQueryParam();

  return useQuery<OneLid>(
    [queryKeys.CRM_ONE_LID, id],
    async () => {
      const res = await $api.get(endPoints.LEAD + id);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: async (err: any) => {
        if (err?.response?.data?.data) {
          push(rootPaths.MAIN + MainPaths.CRM);
        }
        baseErrorHandler(err);
      }
    }
  );
}

export function useBookingLeadFlat(id: number) {
  return useQuery<BookingLeadFlat[]>(
    [queryKeys.BOOKING_LEAD_FLAT, id],
    async () => {
      const res = await $api.get(`${endPoints.BOOKING_LEAD_FLAT}${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadEmployeeData(id: number) {
  return useQuery<LeaadMemberData>(
    [queryKeys.LEAD_EMPLOYEE_DATA, id],
    async () => {
      const res = await $api.get(`${endPoints.LEAD_EMPLOYEE}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadEventData(id: number) {
  const qc = useQueryClient();

  return useQuery<LeadEventsAndTasksList[]>(
    [queryKeys.LEAD_COMPLETED_DATA, id],
    async () => {
      const res = await $api.get<{
        data: LeadEventOrTask[];
      }>(`${endPoints.LEAD_DATA}/${id}`);
      const arr: LeadEventsAndTasksList[] = [];

      getEvents(res, arr);

      return arr;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.COUNT_NOT_READ]);
        qc.invalidateQueries([queryKeys.LEAD_CHATBOX]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useClientEventData(id: number) {
  const qc = useQueryClient();

  return useQuery<LeadEventsAndTasksList[]>(
    [queryKeys.CLIENT_EVENT, id],
    async () => {
      const res = await $api.get(`${endPoints.CLIENT_EVENT}/${id}`);
      const arr: LeadEventsAndTasksList[] = [];

      getEvents(res, arr);

      return arr;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.COUNT_NOT_READ]);
        qc.invalidateQueries([queryKeys.LEAD_CHATBOX]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadNotTask() {
  const dispatch = useAppDispatch();

  return useQuery<LeadNotTaskConf>(
    [queryKeys.LEAD_NOT_TASK],
    async () => {
      const res = await $api.get(endPoints.LEAD_NOT_TASK_CONF);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setLeadNotTaskConf(data));
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useOnlinePbxCanMakeCall() {
  return useQuery<OnlinePbxUser>(
    [queryKeys.CAN_MAKE_CALL_ONLINE_PBX],
    async () => {
      const res = await $api.get(endPoints.ONLINE_PBX_CAN_MAKE_CALL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetLeadDuplicate(id: number) {
  return useQuery<OneLid[]>(
    [queryKeys.LEAD_DUPLICATE, id],
    async () => {
      const res = await $api.get(endPoints.LEAD_DUPLICATE + id);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: !!id
    }
  );
}

export function useGetAllCountries() {
  const zoneSorts = ['UZ','KZ','KG','TJ','TM','AF'];

  return useQuery<AllCountriesModel[]>(
    ['ALL_COUNTRIES'],
    async () => {
      const res = await axios.get('https://addresses.uysot.aws.softex.uz/country.json');

      const dataConovert = (res.data.data as AllCountriesModel[])?.reduce<AllCountriesModel[]>((p,c) => {
        if(zoneSorts.includes(c?.zone)){
          p = [c,...p]
        }else{
          p = [...p, c]
        }

        return p
      },[])


      return dataConovert;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetCountries(zone: string) {
  const qc = useQueryClient();

  return useQuery<OneCountryModel[] | undefined>(
    [queryKeys.ONE_COUNTRY, zone],
    async () => {
      const res = await axios.get(`https://addresses.uysot.aws.softex.uz/countries/${zone}.json`);

      return res.data.data;
    },
    {
      enabled: !!zone,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetInvitingClients(id: number) {
  return useQuery<InviteClientModel[]>(
    [queryKeys.INVITING_CLIENT, id],
    async () => {
      const res = await $api.get(endPoints.INVITING_CLIENT + id);

      return res.data.data;
    },
    {
      enabled: !!id && String(id) !== 'new',
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetSuggestionLeads(clientId: number) {
  return useQuery<OneLid[]>(
    [queryKeys.SUGGESTION_LEAD, clientId],
    async () => {
      const res = await $api.get(endPoints.SUGGESTION_LEAD + clientId);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!clientId,
      onError: baseErrorHandler
    }
  );
}

export function useGetClientView(clientId: number) {
  return useQuery<ViewOneClient>(
    [queryKeys.CLIENT_VIEW, clientId],
    async () => {
      const res = await $api.get(endPoints.CLIENT_VIEW + clientId);

      return res.data.data;
    },
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetContractAndBooking(clientId: number) {
  return useQuery<ContractAndBookingModel>(
    [queryKeys.CONTRACT_BOOKING, clientId],
    async () => {
      const res = await $api.get(`${endPoints.CONTRACT_BOOKING}?clientId=${clientId}`);

      return res.data.data;
    },
    {
      enabled: !!clientId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetOneClient(clientId: number) {
  return useQuery<OneClientModel>(
    [queryKeys.ONE_CLIENT, clientId],
    async () => {
      const res = await $api.get(endPoints.ONE_CLIENT + clientId);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!clientId
    }
  );
}

export function useGetEmbedWidget() {
  return useQuery<{ active: boolean; code: string; id: number; name: string }>({
    queryKey: [queryKeys.EMBED_WIDGET],
    queryFn: async () => {
      const res = await $api.get('v1/embed-widget');

      return res.data.data;
    },

    onError: baseErrorHandler,
    retry: false,
    refetchOnWindowFocus: false
  });
}
