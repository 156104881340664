import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { addItemsTable } from '../../../../store/reducers/ChangesSlice';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { helper } from '../../../../utils/helper/helper';
import { validateDateString } from '../../../../utils/helper/validatorDateString';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import { useTablePaymentsChange } from '../../services/queries';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

interface FormValues {
  date: string;
  amount: string;
  number: string;
}

const AddItemTable: React.FC<Props> = ({ visible, onClose }) => {
  const [form] = Form.useForm<FormValues>();
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();
  const { data } = useTablePaymentsChange(searchParams.contractId);

  const dispatch = useAppDispatch();

  const { residueTablePayments, monthlyPaymentParts } = useAppSelector(state => state.changesReducer);

  useEffect(() => {
    if (visible) {
      if (data?.delay) {
        if (monthlyPaymentParts) {
          if (monthlyPaymentParts.length < data.delay / (data.interval || 0)) {
            const residueDelay = data.delay / (data.interval || 0) - monthlyPaymentParts.length;
            const lastDate = dayjs(monthlyPaymentParts[monthlyPaymentParts.length - 1]?.date, DATE_FORMAT)
              .add(1, 'month')
              .format(DATE_FORMAT);

            if (residueTablePayments > 0) {
              const oneMonthPay = Math.floor(residueTablePayments / residueDelay);

              form.setFieldsValue({
                amount: oneMonthPay.toLocaleString('ru'),
                date: lastDate,
                number: String(residueDelay)
              });
            } else {
              form.setFieldsValue({
                date: lastDate,
                number: String(residueDelay)
              });
            }
          }
        } else {
          const residueDelay = data.delay / (data.interval || 0);
          const oneMonthPay = Math.floor(residueTablePayments / residueDelay);

          form.setFieldsValue({
            amount: oneMonthPay.toLocaleString('ru'),
            date: dayjs(data.date, DATE_FORMAT).format(DATE_FORMAT),
            number: String(residueDelay)
          });
        }
      }
    }
  }, [data, form, monthlyPaymentParts, residueTablePayments, visible]);

  const onCloseModal = () => {
    onClose(false);
  };

  const onFinish = (values: FormValues) => {
    const number = Number(values.number);

    dispatch(
      addItemsTable({
        date: values.date,
        amount: values.amount,
        number,
        fullDelay: data?.delay || 1,
        delayInterval: data?.interval || 1
      })
    );
    form.setFieldsValue({
      amount: '',
      date: '',
      number: ''
    });
    onCloseModal();
  };
  const { t } = useTranslation();

  return (
    <Modal
      title={`${t(`changes.monthlyPayments.Jadvalga_element_qo’shish`)}`}
      wrapClassName="settingEmployeeModal"
      visible={visible}
      onCancel={onCloseModal}
      footer={false}
      width={546}
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={8} className="d-flex justify-space-between">
          <Col span={12}>
            <Form.Item
              name="number"
              label={`${t(`changes.monthlyPayments.Soni`)}`}
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      const number = helper.deleteNotNumbers(value, 0);

                      let amount = 0;

                      if (residueTablePayments) {
                        amount = Math.trunc(residueTablePayments / (Number(number) || 1));
                      }

                      form.setFieldsValue({
                        number: number?.toString(),
                        amount: amount.toLocaleString('ru')
                      });
                    }
                    return Promise.resolve();
                  }
                },
                { required: true, message: '' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={`${t(`changes.monthlyPayments.Sana`)}`}
              name="date"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        date: validateDateString(value, true)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
            >
              <ReactInputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar=""
              >
                {(inputProps: any) => <Input placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={`${t(`changes.monthlyPayments.To'lov_summasi`)}`}
              name="amount"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        // @ts-ignore
                        amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                      });
                    }
                    return Promise.resolve();
                  }
                },
                { required: true, message: '' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <div className="settingEmployeeModalFooter">
          <Button onClick={onCloseModal}>{t(`changes.monthlyPayments.Yopish`)}</Button>
          <Button htmlType="submit" type="primary">
            {t(`changes.monthlyPayments.Tasdiqlash`)}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddItemTable;
