import React, { useRef, useState } from 'react';
import { TypePage } from '../AddCardCont';
import { Button } from 'antd';
import styles from '../addCard.module.scss';
import 'swiper/css/pagination';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';
import MainInputsPayme from './MainInputsPayme';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '../../../../../assets/icons/ChevronLeft';
import LeftCheveronCircle from '../../../../../assets/icons/LeftCheveronCircle';
import RightCheveronCircle from '../../../../../assets/icons/RightCheveronCircle';

interface Props {
  changePage: (page: TypePage) => void;
  id: number;
  name: string;
}

const AddCardPayme: React.FC<Props> = ({ changePage, id, name }) => {
  const navigationPrevRef = useRef<HTMLSpanElement>(null);
  const navigationNextRef = useRef<HTMLSpanElement>(null);
  const [styleKey, setStyleKey] = useState(0);

  const { t } = useTranslation();

  const handleChangePage = () => {
    changePage(TypePage.LIST);
  };

  const getName = () => {
    if (name.length > 17) return name.substring(0, 17) + '...';
    return name;
  };

  return (
    <div>
      <Button onClick={handleChangePage} className={styles.title_button}>
        <ChevronLeft />
        <span>{t('contract.Orqaga')}</span>
      </Button>
      <div className={styles.cont_new}>
        <div className={styles.cont_new_slider}>
          <span className={styles.cont_new_buttons} ref={navigationPrevRef}>
            <LeftCheveronCircle />
          </span>
          <Swiper
            spaceBetween={20}
            slidesPerView={'auto'}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            modules={[Navigation, Mousewheel, Pagination]}
            mousewheel={false}
            cssMode={true}
            pagination={{
              clickable: true
            }}
            onSlideChange={swiper => {
              setStyleKey(swiper.activeIndex);
            }}
            onSwiper={(swiper: any) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
          >
            <SwiperSlide>
              <div datatype={'ddd'} className={styles.cont_new_slider_item}>
                <div className={styles.cont_card + ' ' + styles.cont_card_0}>
                  <div className={styles.cont_card_default} />
                  <div className={styles.cont_card_body}>
                    <div className={styles.cont_card_body_number}>**** **** **** ****</div>
                    <div className={styles.cont_card_body_expired}>{t('contract.oy_yil')}</div>
                    <div className={styles.cont_card_body_name_cont}>
                      <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.cont_new_slider_item}>
                <div className={styles.cont_card + ' ' + styles.cont_card_1}>
                  <div className={styles.cont_card_default} />
                  <div className={styles.cont_card_body}>
                    <div className={styles.cont_card_body_number}>**** **** **** ****</div>
                    <div className={styles.cont_card_body_expired}>{t('contract.oy_yil')}</div>
                    <div className={styles.cont_card_body_name_cont}>
                      <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.cont_new_slider_item}>
                <div className={styles.cont_card + ' ' + styles.cont_card_2}>
                  <div className={styles.cont_card_default} />
                  <div className={styles.cont_card_body}>
                    <div className={styles.cont_card_body_number}>**** **** **** ****</div>
                    <div className={styles.cont_card_body_expired}>{t('contract.oy_yil')}</div>
                    <div className={styles.cont_card_body_name_cont}>
                      <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.cont_new_slider_item}>
                <div className={styles.cont_card + ' ' + styles.cont_card_3}>
                  <div className={styles.cont_card_default} />
                  <div className={styles.cont_card_body}>
                    <div className={styles.cont_card_body_number}>**** **** **** ****</div>
                    <div className={styles.cont_card_body_expired}>{t('contract.oy_yil')}</div>
                    <div className={styles.cont_card_body_name_cont}>
                      <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.cont_card + ' ' + styles.cont_card_4}>
                <div className={styles.cont_card_default} />
                <div className={styles.cont_card_body}>
                  <div className={styles.cont_card_body_number}>**** **** **** ****</div>
                  <div className={styles.cont_card_body_expired}>{t('contract.oy_yil')}</div>
                  <div className={styles.cont_card_body_name_cont}>
                    <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.cont_card + ' ' + styles.cont_card_5}>
                <div className={styles.cont_card_default} />
                <div className={styles.cont_card_body}>
                  <div className={styles.cont_card_body_number}>**** **** **** ****</div>
                  <div className={styles.cont_card_body_expired}>{t('contract.oy_yil')}</div>
                  <div className={styles.cont_card_body_name_cont}>
                    <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <span className={styles.cont_new_buttons} ref={navigationNextRef}>
            <RightCheveronCircle />
          </span>
        </div>
        <MainInputsPayme styleKey={String(styleKey)} id={id} changePage={handleChangePage} />
      </div>
    </div>
  );
};

export default AddCardPayme;
