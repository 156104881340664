import { useEffect, useState } from 'react';
import EnIcon from '@icons/enIcon';
import RuIcon from '@icons/RuIcon';
import TrIcon from '@icons/trIcon';
import UzIcon from '@icons/UzIcon';
import { Badge, Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import BellIcon from '../../../assets/icons/BellIcon';
import MessageNTFIcon from '../../../assets/icons/MessageNTFIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useMessageCountNotRead, useNotificationMeCount, useTaskFilterCount } from '../../../queries/queries';
import { setMessageDrawerVisible, setTaskCount } from '../../../store/reducers/notificationSlice';
import { Permissions } from '../../../utils/constants/permissions';
import { queryNames } from '../../../utils/constants/queryNames';

import NotificationDrawer from './notification-drawer/NotificationDrawer';

import styles from '../header.module.scss';

const NotificationButtons = () => {
  const qc = useQueryClient();
  const [openNotification, setOpenNotification] = useState(false);
  const { data: count, isFetching, isLoading } = useNotificationMeCount();
  const [visible, setVisible] = useState(false);

  const permissions = useAppSelector(state => state.userReducer?.user?.role.permissions);

  const isAccessCrm = permissions?.includes(Permissions.PERMISSION_PAGE_CRM);

  const { data: countNotRead } = useMessageCountNotRead(!!isAccessCrm);
  const { data: taskCount } = useTaskFilterCount(null, null, null, !!isAccessCrm);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (taskCount) {
      dispatch(setTaskCount(taskCount.count));
    }
  }, [taskCount, dispatch, isFetching, isLoading]);

  const handleClickNotificationIcon = () => {
    qc.invalidateQueries([queryNames.NOTIFICATION_ME]);
    setOpenNotification(true);
  };

  const handleMessage = () => {
    dispatch(setMessageDrawerVisible(true));
  };

  const { i18n, t } = useTranslation();
  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const items = [
    {
      key: 'uz',
      label: (
        <div
          className={styles.language}
          onClick={() => {
            changeLanguageHandler('uz');
          }}
        >
          <UzIcon />
          <span>O'zbek</span>
        </div>
      )
    },
    {
      key: 'ru',
      label: (
        <div
          className={styles.language}
          onClick={() => {
            changeLanguageHandler('ru');
          }}
        >
          <RuIcon />
          <span>Русский</span>
        </div>
      )
    },
    {
      key: 'tr',
      label: (
        <div
          className={styles.language}
          onClick={() => {
            changeLanguageHandler('tr');
          }}
        >
          <TrIcon />
          <span>Türkçe</span>
        </div>
      )
    },
    {
      key: 'en',
      label: (
        <div
          className={styles.language}
          onClick={() => {
            changeLanguageHandler('en');
          }}
        >
          <EnIcon />
          <span>English</span>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="d-flex align-center">
        {!!isAccessCrm && (
          <Badge color="#FF3F40" size="default" count={countNotRead}>
            <Button className={`${styles.icon} d-flex align-center`} onClick={handleMessage}>
              <MessageNTFIcon />
            </Button>
          </Badge>
        )}
        {/* <Badge color={'#FF3F40'} size="default" count={count}>
          <Dropdown
            overlay={
              <NotificationAssign setOpenNotification={setOpenNotification} />
            }
            trigger={['click']}
            placement={'bottomRight'}
            visible={openNotification}
            onVisibleChange={(visible) => setOpenNotification(visible)}
          >
            <Button
              className={`${styles.icon} d-flex align-center`}
              onClick={handleClickNotificationIcon}
            >
              <BellIcon />
            </Button>
          </Dropdown>
        </Badge> */}
        <Badge color="#FF3F40" size="default" count={count}>
          <Button className={`${styles.icon} d-flex align-center`} onClick={handleClickNotificationIcon}>
            <BellIcon />
          </Button>
        </Badge>
        <div className={styles.menuLine} />
        <Dropdown
          visible={visible}
          onVisibleChange={() => setVisible(!visible)}
          overlay={
            <div className={styles.languageDrop}>
              {items
                ?.filter(item => item?.key !== i18n.language)
                ?.map(item => (
                  <div key={item.key} onClick={() => setVisible(false)}>
                    {item?.label}
                  </div>
                ))}
            </div>
          }
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
          trigger={['click', 'hover']}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.1}
        >
          <span className={styles.language_active}>
            {i18n.language === 'uz' ? <UzIcon /> : i18n.language === 'ru' ? <RuIcon /> : i18n.language === 'tr' ? <TrIcon /> : <EnIcon />}
          </span>
        </Dropdown>
      </div>
      <NotificationDrawer setOpenNotification={setOpenNotification} openNotification={openNotification} />
    </>
  );
};

export default NotificationButtons;
