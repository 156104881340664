import React, { useEffect, useRef, useState } from 'react';
import styles from './messageSendModal.module.scss';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguageWord } from '../../../../utils/helper/getCurrentLanguageWord';
import { useTemplateMessageFields } from '../../services/queries';

interface EditableProps {
  text: string;
  success: (value: string) => void;
  error: boolean;
  loading: boolean;
  handleCloseTemplatePage: () => void;
  isTemplatePage: boolean;
}

const Editable: React.FC<EditableProps> = ({ text, success, error, loading, handleCloseTemplatePage, isTemplatePage }) => {
  const { t } = useTranslation();

  const { data: fields, isLoading } = useTemplateMessageFields();

  const [value, setValue] = useState(text);
  const textReplace = text.replace(
    /&(.*?)&/g,
    `<span contenteditable='false' class='editable_span' key='&$1&'>
      &$1& 
      <span class="editable_span_delete" onClick="this.parentElement.remove()">
        &times;
      </span>
    </span>`
  );
  const valueRef = useRef(value);
  const positionRef = useRef(value.length);

  useEffect(() => {
    const elements = document.getElementsByClassName('editable_span_delete');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', e => {
        //@ts-ignore
        deleteItem(Number(e.target.attributes[0].value));
      });
    }
    return () => {
      const elements = document.getElementsByClassName('editable_span_delete');
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('click', () => {
          console.log('remove listener');
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (valueString: string) => {
    const firstValue = valueRef.current.slice(0, positionRef.current);
    const secondValue = valueRef.current.slice(positionRef.current);
    const newValue = `${firstValue} ${valueString} ${secondValue}`;
    positionRef.current = positionRef.current + valueString.length + 2;
    valueRef.current = newValue;
    setValue(newValue);
  };

  const deleteItem = (index: number) => {
    let newValue = valueRef.current.split('&');
    let newValueString = '';
    for (let i = 0; i < newValue.length; i++) {
      if (i !== index) {
        const finded = fields?.find(item => item.key === `&${newValue[i]}&`);
        if (finded) {
          newValueString += `&${newValue[i]}&`;
        } else {
          newValueString += newValue[i];
        }
      }
    }
    valueRef.current = newValueString;
    setValue(newValueString);
  };

  async function pasteHtmlAtCaret(html: any) {
    let range: Range;
    let sel = await window.getSelection();
    let focusNode: any = document.querySelector(`.editorDiv`);
    await focusNode.focus();
    if (sel?.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();
      let el = document.createElement('div');
      el.innerHTML = `<span contenteditable="false" class='editable_span' key='${html}'>
          ${html}
          <span class="editable_span_delete" onClick="this.parentElement.remove()">
            &times;
          </span>
        </span>`;
      let frag = document.createDocumentFragment(),
        node,
        lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range = range.cloneRange();
        range.setStartAfter(lastNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }

  const handleSave = () => {
    let focusNode: any = document.querySelector(`.editorDiv`);
    let textArr: any[] = [];

    const recurse = (item: any) => {
      if (item?.className !== 'editable_span_delete') {
        let nodeList = item.childNodes;
        if (nodeList?.length !== 0) {
          nodeList?.forEach((node: any) => {
            recurse(node);
          });
        } else if (item?.attributes?.key?.value) {
          textArr.push(item?.attributes?.key?.value);
        } else {
          textArr.push(item.textContent);
        }
      }
    };

    if (focusNode) {
      focusNode?.childNodes.forEach((item: any) => {
        recurse(item);
      });
    }
    success(textArr.join(''));
  };

  return (
    <Spin spinning={isLoading}>
      <div
        className={`${styles.editable} ${error ? styles.editable_error : styles.editable_success} editorDiv`}
        contentEditable
        dangerouslySetInnerHTML={{ __html: textReplace }}
      />
      <div className={styles.save}>
        <p className={styles.aboutHome}>
          {fields?.map(field => (
            <span
              key={field.key}
              onClick={() => {
                handleChange(field.key);
                pasteHtmlAtCaret(field.key);
              }}
            >
              {getCurrentLanguageWord(field as any)}
            </span>
          ))}
        </p>
      </div>
      {isTemplatePage ? (
        <div className={styles.modalFooter}>
          <Button htmlType={'button'} className={styles.modalFooter_margin} onClick={handleCloseTemplatePage}>
            {t('my_calls.Ortga')}
          </Button>
          <Button loading={loading} onClick={handleSave} type="primary">
            {t('home.Saqlash')}
          </Button>
        </div>
      ) : (
        <div className={styles.sendButton}>
          <Button onClick={handleCloseTemplatePage} htmlType="button">
            {t('home.Yopish')}
          </Button>
          <Button onClick={handleSave} loading={loading} type="primary" htmlType="button">
            {t('home.Yuborish')}
          </Button>
        </div>
      )}
    </Spin>
  );
};

export default Editable;
