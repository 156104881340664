import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CustomeDraggable from '@components/custome-draggable/CustomeDraggable';
import ArrowDownWhiteIcon from '@icons/ArrowDownWhiteIconShowroom';
import { showroomPath } from '@pages/showroom-v2/utils/constants/showroomPath';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL_VISUAL } from '@service/RequestService';
import { useTranslation } from 'react-i18next';

import DropDown from '../../components/drop-down/DropDown';
import { useWebsiteBlockFloorStatistic } from '../../services/queries';
import { showroomVisualPath } from '../../utils/constants/showroomVisualPath';

import styles from './showroomVisualBlock.module.scss';

const ShowroomVisualBlock = () => {
  const [imageSize, setImageSize] = useState({ width: 1920, height: 1080 });
  const params = useParams();
  const { data, isLoading } = useWebsiteBlockFloorStatistic(Number(params.block));
  const [dropChildren, setDropChildren] = useState<JSX.Element>(<></>);
  const [positionDown, setPositionDown] = useState({
    top: 0,
    left: 0,
    visible: false
  });
  const { t } = useTranslation();

  const blockMouseMove = async (e: any) => {
    setPositionDown({
      left: e?.clientX + 20,
      top: e?.clientY + 20,
      visible: true
    });
  };

  const blockMouseOut = (e: any) => {
    setDropChildren(<></>);

    setPositionDown({
      left: e?.clientX + 20,
      top: e?.clientY + 20,
      visible: false
    });
  };

  const loadImage = (e: any) => {
    setImageSize({
      width: e.naturalWidth,
      height: e.naturalHeight
    });
  };

  return (
    <>
      <span className={styles.container_back} onClick={() => window.history.back()}>
        <ArrowDownWhiteIcon /> {t('my_calls.Ortga')}
      </span>
      <CustomeDraggable>
        <div className={styles.container}>
          {' '}
          <div className={styles.container_center}>
            <img src={BASE_URL_VISUAL + data?.imagePath} alt="" className={styles.blurImage} />
            <img
              src={BASE_URL_VISUAL + data?.imagePath}
              alt=""
              onLoad={e => {
                loadImage(e.target);
              }}
            />
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1920 1080">
              {data &&
                data?.fullVisualViewFloorStatisticList.map(item => (
                  <Link
                    key={item?.floorName}
                    to={`${rootPaths.SHOWROOM + showroomPath.VISUAL + showroomVisualPath.INDEX + item?.blockId}/${
                      item?.floorName
                    }${window.location.search}`}
                    onMouseEnter={() => {
                      setDropChildren(<></>);
                      const children = (
                        <div>
                          <h1>
                            {item.floorName}-{t('home.qavat')} {t('home.Blok')}-{item.blockName}
                          </h1>
                          <div>
                            <div className="mb-[5px] flex items-center justify-between" style={{ borderBottom: '1px solid #EAEAEA' }}>
                              <h3 className="mb-[1px]">{t('house_config.Xonadonlar_soni')} </h3>
                              <h3>
                                {item.totalFlatCount} {t('kpi.dona')}
                              </h3>
                            </div>
                            <div>
                              {item.roomsCount.map(room => (
                                <p key={room.roomsCount}>
                                  {room.rooms} {t('home_id.xonali')}{' '}
                                  <b>
                                    {room.roomsCount} {t('kpi.dona')}
                                  </b>
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                      );

                      setTimeout(() => {
                        setDropChildren(children);
                      }, 0);
                    }}
                    onMouseMove={e => {
                      blockMouseMove(e);
                    }}
                    onMouseOut={blockMouseOut}
                  >
                    <polygon points={item?.floorPoint && item?.floorPoint[0]} />
                  </Link>
                ))}
            </svg>
          </div>
          <DropDown left={positionDown.left} top={positionDown.top} visible={positionDown.visible}>
            {dropChildren}
          </DropDown>
        </div>
      </CustomeDraggable>
    </>
  );
};

export default ShowroomVisualBlock;
