import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { queryKeys } from '../constants';
import { IVariationGroup } from '../schema';

export const useContractVariationGroup = () =>
  useQuery<IVariationGroup[]>({
    queryKey: [queryKeys.CONTRACT_VARIATION_GROUP],
    queryFn: async () => {
      const res = await $api.get('/v1/contract/variation-group');

      return res.data.data;
    },
    staleTime: Infinity
  });
