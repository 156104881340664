import DashboardIcon from '@icons/DashboardIcon';
import RobotoCallsIcon from '@icons/RobotoCallsIcon';

import ArrearageIcon from '../../../assets/icons/ArrearageIcon';
import ChangesIcon from '../../../assets/icons/ChangesIcon';
import ClientsIcon from '../../../assets/icons/ClientsIcon';
import ContractIcon from '../../../assets/icons/ContractIcon';
import HomIcon from '../../../assets/icons/HomIcon';
import KanbanIcon from '../../../assets/icons/KanbanIcon';
import OrderIcon from '../../../assets/icons/OrderIcon';
import PaymentIcon from '../../../assets/icons/PaymentIcon';
import PeopeleIcon from '../../../assets/icons/PeopleIcon';
import SmsIcon from '../../../assets/icons/SmsIcon';
import { MainPaths } from '../../../routing/main/mainPaths';
import { rootPaths } from '../../../routing/rootPaths';
import { LocalStorage } from '../../../service/LocalStorage';
import { USER_KEY } from '../../../utils/constants/localStorageKeys';
import { Permissions } from '../../../utils/constants/permissions';
import { User } from '../../../utils/models/User';
// import HomeWinterIcon from '../../../assets/icons/HomeWinterIcon'
// import OrderWinterIcon from '../../../assets/icons/OrderWinterIcon'
// import PaymentWinterIcon from '../../../assets/icons/PaymentWinterIcon'
// import ClientsWinterIcon from '../../../assets/icons/ClientsWinterIcon'
// import ChangesWinterIcon from '../../../assets/icons/ChangesWinterIcon'
// import SMSWinterIcon from '../../../assets/icons/SMSWinterIcon'

const user = LocalStorage.get<User>(USER_KEY);

const fullNavBarLinks = [
  {
    name: 'home',
    icon: <HomIcon />,
    path: rootPaths.MAIN + MainPaths.INDEX,
    permission: [Permissions.PERMISSION_READ_FLATS]
  },
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: rootPaths.MAIN + MainPaths.DASHBOARD,
    permission: [Permissions.PERMISSION_PAGE_DASHBOARD, Permissions.PERMISSION_PAGE_CRM]
  },
  {
    name: 'order',
    icon: <OrderIcon />,
    path: rootPaths.MAIN + MainPaths.ORDER,
    permission: [Permissions.PERMISSION_READ_BOOKINGS]
  },
  {
    name: 'contract',
    icon: <ContractIcon />,
    path: rootPaths.MAIN + MainPaths.CONTRACT,
    permission: [Permissions.PERMISSION_READ_CONTRACTS]
  },
  {
    name: 'payment',
    icon: <PaymentIcon />,
    path: rootPaths.MAIN + MainPaths.PAYMENT,
    permission: [Permissions.PERMISSION_READ_PAYMENTS]
  },
  {
    name: 'client',
    icon: <ClientsIcon />,
    path: rootPaths.MAIN + MainPaths.CLIENT,
    permission: [Permissions.PERMISSION_READ_CLIENTS]
  },
  {
    name: 'arrearage',
    icon: <ArrearageIcon />,
    path: rootPaths.MAIN + MainPaths.ARREARAGE,
    permission: [Permissions.PERMISSION_READ_DEBTS]
  },
  {
    name: 'message',
    icon: <SmsIcon />,
    path: rootPaths.MAIN + MainPaths.MESSAGE,
    permission: [Permissions.PERMISSION_READ_MESSAGES]
  },
  {
    name: 'changes',
    icon: <ChangesIcon />,
    path: rootPaths.MAIN + MainPaths.CHANGES,
    permission: [Permissions.PERMISSION_CHANGES]
  },
  {
    name: 'broker',
    icon: <PeopeleIcon />,
    path: rootPaths.MAIN + MainPaths.BROKER,
    permission: [Permissions.PERMISSION_READ_STAFF]
  },
  {
    name: 'roboto_calls',
    icon: <RobotoCallsIcon />,
    path: rootPaths.MAIN + MainPaths.ROBOTO_CALLS,
    permission: [Permissions.PERMISSION_ROBOT_CALL]
  },
  {
    name: 'crm',
    icon: <KanbanIcon />,
    path: rootPaths.MAIN + MainPaths.CRM,
    permission: [Permissions.PERMISSION_PAGE_CRM]
  }
];

const navbarLinks: typeof fullNavBarLinks = [];

if (typeof user === 'object' && user) {
  fullNavBarLinks.forEach(link => {
    if (link.permission) {
      if (user?.role) {
        if (link.permission?.every(p => user.role.permissions.includes(p))) {
          navbarLinks.push(link);
        }
      } else if (user?.roleTokens && link?.permission?.every(p => user.roleTokens![0]?.role?.permissions?.includes(p))) {
        navbarLinks.push(link);
      }
    } else {
      navbarLinks.push(link);
    }
  });
}

export default navbarLinks;
