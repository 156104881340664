import React, { FC } from 'react';
import { Modal, Select, Spin, Switch } from 'antd';
import styles from './voronkaTelegramBotModal.module.scss';
import GlphIcon from '../../../../../assets/icons/GlphIcon';
import ArrowDownCrmIcon from '../../../../../assets/icons/ArrowDownCrmIcon';
import { usePipeAll, useTelegramAndWeb } from '../../../../../queries/queries';
import { useTelegramAndWebSubmit } from '../../../../../queries/mutation';
import { useTranslation } from 'react-i18next';
import { TawType, TelegramAndWebProps } from '../../../../../utils/models/TelegramAndWebProps';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const VoronkaTelegramBotModal: FC<IProps> = ({ setVisible, visible }) => {
  const telegramAndWebSubmit = useTelegramAndWebSubmit();
  const { data: pipeAll } = usePipeAll();
  const { data: telegramAndWebSubmitData, isLoading, isFetching } = useTelegramAndWeb();

  const { t } = useTranslation();

  const handleClose = () => {
    setVisible(false);
  };

  // get name
  const getName = (item: TelegramAndWebProps) => {
    if (item.tawType === TawType.HOUSE) {
      return item.house?.name;
    }
    if (item.tawType === TawType.OTHER) return 'Boshqa murojaatlar';
    return (
      <span className={'d-flex align-center'}>
        <span style={{ marginRight: '4px' }}>Uysot Marketpleys</span>
        <GlphIcon />
      </span>
    );
  };

  return (
    <Modal
      title={t('crm_ins.Sayt_va_telegram_bot_sozlamalari')}
      wrapClassName={styles.container}
      visible={visible}
      onCancel={handleClose}
      footer={false}
    >
      <Spin spinning={isLoading || isFetching || telegramAndWebSubmit.isLoading}>
        <div className={styles.container_description}>
          <GlphIcon /> {t('crm_ins.Ushbu_sozlamalar_yordamida_vebsayt')}
        </div>
        <h1 className={styles.container_content_title}>{t('crm_ins.Tarmoqlarga_mos_varonkalarni_tanlang')}</h1>
        <div className={styles.container_content}>
          {telegramAndWebSubmitData?.map(item => (
            <div className={styles.container_content_item} key={item?.id}>
              <h1>{getName(item)}</h1>
              <div className={'d-flex align-center'}>
                <Select
                  placeholder={t('crm_ins.Tanlang')}
                  suffixIcon={<ArrowDownCrmIcon strokeColor="#9CA3AF" />}
                  defaultValue={item?.pipe?.id}
                  onChange={value => {
                    telegramAndWebSubmit.mutate({
                      houseId: item.house ? item.house.id : null,
                      id: item.id,
                      pipeId: value,
                      status: item.status,
                      tawType: item.tawType
                    });
                  }}
                >
                  {pipeAll?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <Switch
                  onChange={value => {
                    telegramAndWebSubmit.mutate({
                      houseId: item.house ? item.house.id : null,
                      id: item.id,
                      pipeId: item.pipe.id,
                      status: value,
                      tawType: item.tawType
                    });
                  }}
                  className={styles.container_content_switch}
                  size={'small'}
                  checked={item.status}
                />
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default VoronkaTelegramBotModal;
