import CustomPlayer from '@components/customPlayer/CustomPlayer';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import FeaturedIcon from '@icons/FeaturedIcon';
import { SearchParamsArrearage } from '@pages/arrearage/Arrearage';
import { useCreateRobotoDebt } from '@pages/roboto-calls/services/mutation';
import { useGetAllChannelsShort } from '@pages/roboto-calls/services/queries';
import { callBackItems } from '@pages/roboto-calls/utils/constants/callsStatus';
import { CampagingCreateModel } from '@pages/roboto-calls/utils/models/CampagingCreateModel';
import { ChannelModel } from '@pages/roboto-calls/utils/models/ChannelModel';
import { useUploadFile } from '@queries/mutation';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants';
import { FileObject } from '@utils/models/File';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone';
import ConfirmModal from '../confirm-modal/ConfirmModal';

const { Option } = Select;

type IProps = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateRobotCallDebt: React.FC<IProps> = ({ visible, setVisible, }) => {
    const { searchParams } = useQueryParam<SearchParamsArrearage, any>();
    const { debtListActiveIds: activeIds, debtListIgnoredIds } = useAppSelector(state => state.debtSlice);
    const [form] = Form.useForm();

    const [callCount, setCallCount] = useState(1);
    const [files, setFiles] = useState<FileObject | undefined>();
    const [visibleConfirm, setVisibleConfirm] = useState({ visible: false, id: 0, name: '' });


    const { data: channels } = useGetAllChannelsShort();

    const createRobotoDebt = useCreateRobotoDebt(searchParams);
    const uploadFile = useUploadFile(getFile);

    const newChannels = channels?.filter((item) => !item?.deleted);




    function decr() {
        if (callCount! > 1) {
            setCallCount(prev => prev - 1);
        }
    }

    function incr() {
        if (callCount! < 10) {
            setCallCount(prev => prev + 1);
        }
    }

    function getFile(data: FileObject[]) {
        setFiles(data[0]);
    }


    const dropzoneChange = (e: any[]) => {
        const file = e[0];

        if (file.type === 'audio/wav') {
            const formData = new FormData();

            formData.append('files', file);
            uploadFile.mutate(formData);
        }
    };

    const handleClose = () => {
        setVisible(false);
        setCallCount(1);
        setFiles(undefined);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        form.resetFields();
    };

    const onOk = () => {
        form.submit();
    };

    const onFinish = (val: CampagingCreateModel) => {
        createRobotoDebt
            .mutateAsync({
                ...val,
                fileId: files?.id!,
                channelsParallelCallCount: callCount!,
                startDate: dayjs(val?.startDate).format(DATE_WITH_TIME_FORMAT),
                contracts: activeIds,
            })
            .then(res => {
                setVisibleConfirm({ id: res?.data?.id, name: res?.data?.name, visible: true });
                handleClose();
            });
    };

    const disabledDate = (current: any) => current && current < dayjs().add(-1, 'day').endOf('day');

    function range(start: number, end: number) {
        const result = [];

        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    function disabledDateTime() {
        const currentHour = dayjs().get('hour');
        const currentMinute = dayjs().get('minute');

        return {
            disabledHours: () => range(0, 24).splice(0, currentHour),
            disabledMinutes: (a: any) => range(0, a <= currentHour ? currentMinute : 0)
        };
    }


    return (
        <>
            <Modal
                visible={visible}
                onCancel={handleClose}
                width={966}
                title={t("home.Qongiroq_toplamini_yaratish")}
                okText={t('home.Saqlash')}
                cancelText={t('home.Yopish')}
                okButtonProps={{ style: { borderRadius: 50, width: 160, height: 38 }, loading: createRobotoDebt?.isLoading }}
                cancelButtonProps={{ style: { borderRadius: 50, width: 160, height: 38 } }}
                onOk={onOk}
                centered
                className="sipCustomModal"
            >
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <div className="mb-4 rounded-2xl border border-solid border-[#D0D5DD] p-4 pb-0">
                        <Form.Item label={t("home.Toplam_nomi")} name="name" rules={[{ required: true, message: '' }]}>
                            <Input placeholder={t("home.Toplam_nomi")} className="customCallInput" />
                        </Form.Item>
                        <Row gutter={12}>
                            <Col span={19}>
                                <Form.Item label={t("home.Sip_user")} name="channelsIdList" rules={[{ required: true, message: '' }]}>
                                    <Select className="customCallSelect" mode="multiple" maxTagCount="responsive" placeholder={t("home.Tanlang")}>
                                        {newChannels?.map((item: ChannelModel) => (
                                            <Option key={item?.id} value={item?.id} disabled={item?.deleted}>
                                                <div className="flex items-center gap-3">
                                                    <span>{item?.name}</span>
                                                    <p
                                                        className="rounded-md px-2 py-0"
                                                        style={{
                                                            backgroundColor: item?.active ? '#E6F6F4' : '#FEF3F2',
                                                            color: item?.active ? '#2ECD6F' : '#FF0000',
                                                            border: '1px solid',
                                                            borderColor: item?.active ? '#2ECD6F' : '#FF0000',
                                                            opacity: item?.deleted ? '0.3' : 1
                                                        }}
                                                    >
                                                        {item?.active ? `${item?.deleted ? t('home.Ochirilgan') : t('home.Faol')}` : `${item?.deleted ? t('home.Ochirilgan') : t('home.Nofaol')}`}
                                                    </p>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label={t("home.Paraller_qongiroqlar")}>
                                    <div className="flex h-11 w-full items-center justify-between overflow-auto rounded-lg border border-solid border-[#D0D5DD]">
                                        <div
                                            onClick={decr}
                                            className="flex h-full cursor-pointer select-none items-center justify-center border-b-0 border-l-0 border-r border-t-0 border-solid border-[#D0D5DD] px-3.5 text-base text-[#1D2939]"
                                        >
                                            -
                                        </div>
                                        <div className="text-base text-[#1D2939]">{callCount}</div>
                                        <div
                                            onClick={incr}
                                            className="flex h-full cursor-pointer select-none items-center justify-center border-b-0 border-l border-r-0 border-t-0 border-solid border-[#D0D5DD] px-3.5 text-base text-[#1D2939]"
                                        >
                                            +
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item label={t('home.Boshlanish_vaqti')} name="startDate" rules={[{ required: true, message: '' }]}>
                                    {/* @ts-ignore */}
                                    <DatePicker
                                        className="h-11 w-full rounded-md"
                                        placeholder={t('home.Tanlang')}
                                        showHour
                                        showMinute
                                        showTime
                                        minuteStep={30}
                                        format={DATE_WITH_TIME_FORMAT}
                                        showNow={false}
                                        showToday={false}
                                        disabledDate={disabledDate}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        disabledTime={disabledDateTime}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={t('home.Qayta_qongiroqlar')} name="retryCount" rules={[{ required: true, message: '' }]}>
                                    <Select className="customCallSelect" placeholder={t("home.Tanlang")}>
                                        {callBackItems?.map(item => (
                                            <Option key={item?.id} value={item?.value}>
                                                {item?.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="rounded-2xl border border-solid border-[#D0D5DD] p-4 pb-0">
                        <Form.Item label={t("home.Audio_yuklash")} name="fileId">
                            {!files ? (
                                <Spin spinning={uploadFile?.isLoading}>
                                    <Dropzone multiple={false} onDrop={dropzoneChange}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="flex cursor-pointer flex-col items-center gap-1 rounded-xl border border-solid border-[#EAECF0] px-8 py-4">
                                                <input style={{ display: 'none' }} {...getInputProps()} />
                                                <p className="flex w-full flex-col items-center">
                                                    <>
                                                        <FeaturedIcon />
                                                        <span className="font-semibold text-[#6941C6]">{t("home.Yuklash_uchun_bosing")}</span> {t("home.yoki_faylni_tortib_tashlang")}
                                                        <p style={{ textAlign: 'center' }}>{t("home.audio")}</p>
                                                    </>
                                                </p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Spin>
                            ) : (
                                <div className="flex cursor-pointer flex-col items-center gap-1 rounded-xl border border-solid border-[#EAECF0] px-8 py-4">
                                    <div className="flex w-full items-center gap-1 rounded-xl border border-solid border-[#EAECF0] px-5 py-2">
                                        <CustomPlayer name={files?.originalName} type={files?.type} url={files?.absolutePath} forDownload={true} />
                                        <TrushIcon onClick={() => setFiles(undefined)} />
                                    </div>
                                </div>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
            <ConfirmModal visibleConfirm={visibleConfirm} setVisibleConfirm={setVisibleConfirm} />
        </>
    )
}

export default CreateRobotCallDebt