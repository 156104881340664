import React from 'react';
import ClientInfo from '../../components/createPayment/ClientInfo';
import Cards from '../../components/createPayment/Cards';
import { Col, Layout, Row, Spin } from 'antd';
import TableMonthlyPayments from '../../components/createPayment/TableMonthlyPayments';
import HistoryPayments from '../../components/createPayment/HistoryPayments';
import { useQueryParam } from '../../hooks/useQueryParams';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import { useGetContractDataForPayment } from '../paymentV2/services/queries';

const { Content } = Layout;

const CreatePayment = () => {
  const { params } = useQueryParam<{ id: string }, any>();
  const { isLoading, data } = useGetContractDataForPayment(params.id);
  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div>
          <Spin spinning={isLoading}>
            <ClientInfo data={data} />
            <Cards data={data} />
          </Spin>
          <Row gutter={[8, 8]}>
            <Col span={10}>
              <TableMonthlyPayments hasArchiveMonthlyPayment={data?.hasArchiveMonthlyPayment || false} />
            </Col>
            <Col span={14}>
              <HistoryPayments />
            </Col>
          </Row>
        </div>
      </Content>
    </>
  );
};

export default CreatePayment;
