import { Route, Routes } from 'react-router-dom';
import AccessDenied from '@components/accessDenied/AccessDenied';

import ShowroomV2Filter from './components/showroom-v2-filter/ShowroomV2Filter';
import ShowroomV2Header from './components/showroom-v2-header/ShowroomV2Header';
import ShowroomV2Menu from './components/showroom-v2-menu/ShowroomV2Menu';
import ShowroomV2Petition from './components/showroom-v2-petition/ShowroomV2Petition';
import { showroomRoutes } from './utils/constants/showroomRoutes';

import styles from './showroomV2.module.scss';

const ShowroomV2 = () => (
  <div className={styles.container}>
    <ShowroomV2Header />
    <ShowroomV2Filter />
    <ShowroomV2Menu />
    <ShowroomV2Petition />
    <div className={styles.body}>
      <Routes>
        {showroomRoutes.map(route => (
          <Route element={<route.component />} path={route.exact ? route.path : `${route.path}/*`} key={route.path} />
        ))}
        <Route path="*" element={<AccessDenied />} />
      </Routes>
    </div>
  </div>
);

export default ShowroomV2;
