import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import ArrowSquareRightIcon from '@icons/ArrowSquareRightIcon';
import DotIcon from '@icons/contract/DotIcon';
import CubeIcon from '@icons/CubeIcon';
import Element3Icon from '@icons/Element3Icon';
import MinusCircleIcon from '@icons/MinusCircleIcon';
import UnionShowroomIcon from '@icons/UnionShowroomIcon';
import { useSmartCatalogFlat } from '@pages/showroom-v2/services/queries';
import { flatStatusColor } from '@pages/showroom-v2/utils/constants/flatStatusColor';
import { setCompanyId } from '@reducers/CompanySlice';
import { clear } from '@reducers/CreateContractSlice';
import { setShowroomApartmentActive, setShowroomApartments, setShowroomDrawer, setShowroomPetition } from '@reducers/ShowroomSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL } from '@service/RequestService';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';
import { FlatVisualGallery } from '@utils/models/FlatVisual';
import { ACTION_EVENTS_GA, TYPE_EVENTS_GA } from '@utils/models/GoogleAnalitks';
import { Button, Image, Segmented, Spin } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import default2d from '@images/default2d.png';
import default3d from '@images/default3d.png';

import styles from './showroomV2Drawer.module.scss';
import FlatGalleryFC from '@components/flat-gallery/FlatGallery';

const ShowroomV2Drawer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [image3D, setImage3D] = useState('2D');
  const [visibleImageGroup, setVisibleImageGroup] = useState(false);
  const { drawer, apartmentActiveIndex, apartments } = useAppSelector(state => state.showroomSlice);
  const { data, isSuccess, isLoading } = useSmartCatalogFlat(drawer?.id);
  const houseCurrency = useAppSelector(state => state.currency.houseCurrency);




  const getImageUrl = (position: string, path: keyof FlatVisualGallery) => {
    if (data?.gallery) {
      const obj = data?.gallery?.find(item => item.position === position);

      if (obj) {
        return obj[path as keyof typeof obj] ? `${BASE_URL}/${obj[path as keyof typeof obj]}` : '';
      }
      return '';
    }
    return '';
  };

  const imageObj: {
    [key: string]: {
      img: string;
      smallestAbsolutePath: string;
      thumbAbsolutePath: string;
      fallback: string;
    };
  } = {
    '2D': {
      img: getImageUrl('FLAT_PLAN', 'absolutePath'),
      smallestAbsolutePath: getImageUrl('FLAT_PLAN', 'smallestAbsolutePath'),
      thumbAbsolutePath: getImageUrl('FLAT_PLAN', 'thumbAbsolutePath'),
      fallback: default2d
    },
    '3D': {
      img: getImageUrl('FLAT_EXTRA', 'absolutePath'),
      smallestAbsolutePath: getImageUrl('FLAT_EXTRA', 'smallestAbsolutePath'),
      thumbAbsolutePath: getImageUrl('FLAT_EXTRA', 'thumbAbsolutePath'),
      fallback: default3d
    }
  };

  const handleBack = () => {
    dispatch(setShowroomDrawer({ id: null, floor: null, visible: false }));
  };

  // handleDeleteApartment
  const handleDeleteApartment = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    const apartmentsCopy = [...apartments];

    apartmentsCopy?.splice(index, 1);
    dispatch(clear());
    if (apartmentsCopy.length === 1) {
      if (data?.id) {
        dispatch(
          setShowroomDrawer({
            id: apartmentsCopy[0]?.id,
            visible: true,
            floor: apartmentsCopy[0]?.floor
          })
        );
      }
      // @ts-expect-error
      dispatch(setShowroomApartmentActive(0));
      dispatch(
        // @ts-expect-error
        setShowroomApartments([
          {
            id: apartmentsCopy[0]?.id,
            number: apartmentsCopy[0]?.number,
            rooms: apartmentsCopy[0]?.rooms
          }
        ])
      );
    } else {
      // @ts-expect-error
      dispatch(setShowroomApartments([...apartmentsCopy]));
    }
  };

  // handleAddApartment
  const handleAddApartment = () => {
    if (apartments?.every(item => item.id) && apartments?.some(item => item.status !== 'BOOKED')) {
      // @ts-expect-error
      dispatch(setShowroomApartments([...apartments, {}]));
      // @ts-expect-error
      dispatch(setShowroomApartmentActive(apartments.length));
    }
  };

  // handleApartment
  const handleApartment = (id: number, index: number, floor: number | null) => {
    if (id) {
      dispatch(
        setShowroomDrawer({
          id,
          visible: true,
          floor
        })
      );
    }
    // @ts-expect-error
    dispatch(setShowroomApartmentActive(index));
  };

  const petitionHandle = () => {
    dispatch(
      // @ts-expect-error
      setShowroomApartments([
        ...apartments?.map((item, i) =>
          i === apartmentActiveIndex
            ? {
              ...item,
              bookingId: data?.bookingId,
              bookingNumber: data?.bookingNumber
            }
            : item
        )
      ])
    );
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    openFullInformationEvent();
    dispatch(setShowroomPetition({ visible: true, houseName: data?.houseName }));
  };

  // open full information modal events
  const openFullInformationEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.SHOWROOM,
      action: ACTION_EVENTS_GA.FULL_INFORMATION_SHOWROOM
    });
  };

  useEffect(() => {
    if (data) {
      dispatch(setCompanyId(data?.companyId));
    }
  }, [data]);



  const currentImageIndex = useMemo(() => {
    const selectedType = image3D === '3D' ? 'FLAT_EXTRA' : ['FLAT_PLAN', 'FLAT_FLOOR'];
    return data?.gallery?.findIndex(item =>
      Array.isArray(selectedType) ? selectedType.includes(item.position) : item.position === selectedType
    ) ?? 0;
  }, [data?.gallery, image3D]);


  return (
    <div className={styles.body}>
      <div
        className={styles.mask}
        style={{
          width: drawer?.visible ? 400 : '',
          padding: drawer?.visible ? '16px' : ''
        }}
      >
        <Spin spinning={isLoading}>
          <div className={`${styles.main} mt-[-20px]`}>
            <div className={`${styles.group}`}>
              <div className="mb-[10px] flex items-center gap-[20px]">
                {drawer?.visible && (
                  <span className={`${styles.back} mr-[-10px]`} onClick={handleBack}>
                    <ArrowSquareRightIcon />
                  </span>
                )}
                <h1 className="mb-0 text-[14px] font-medium leading-[20px] text-[#757d8a]">{t('showroom.Guruh')}</h1>
              </div>
              <div className={styles.group_apartments}>
                {apartments?.map((item, index) =>
                  item?.id ? (
                    <div
                      className={`${styles.group_apartments_item} ${apartmentActiveIndex === index ? styles.group_apartments_item_active : ''}`}
                      onClick={() => handleApartment(item?.id, index, item?.floor)}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      {item?.number}
                      {apartments.length !== 1 && (
                        <span className={styles.group_apartments_item_delete} onClick={e => handleDeleteApartment(e, index)}>
                          <MinusCircleIcon />
                        </span>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`${styles.group_apartments_item_default} ${apartmentActiveIndex === index ? styles.group_apartments_item_active : ''}`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      onClick={() => handleApartment(item?.id, index, item?.floor)}
                    >
                      {index + 1}
                    </div>
                  )
                )}

                <div className={styles.group_apartments_item_plus} onClick={handleAddApartment}>
                  +
                </div>
              </div>
              <div className={styles.group_petitionButton}>
                {!data?.existFlatPetition && data?.status === 'SALE' && (
                  <Button
                    onClick={petitionHandle}
                  // disabled={apartmentsId.includes(data?.id)}
                  >
                    {t('showroom.Batafsil')}
                  </Button>
                )}
                {!data?.existFlatPetition && data?.status === 'SOLD' && (
                  <Button
                    onClick={() => {
                      navigate(`${rootPaths.MAIN + MainPaths.CONTRACT}/${data.contract?.id}/№${encodeURIComponent(data.contract?.number)}`);
                    }}
                    disabled={!data.contract?.id}
                    style={{ background: '#FF6565' }}
                  >
                    {t('home.Shartnoma')} №{data.contract?.number}
                  </Button>
                )}
                {!data?.existFlatPetition && data?.status === 'BOOKED' && (
                  <div className={styles.bookingBtns}>
                    <Button
                      onClick={() => {
                        navigate(`${rootPaths.MAIN + MainPaths.ORDER}/${data.booking?.id}/№${encodeURIComponent(data.booking?.number)}`);
                      }}
                      type="primary"
                      disabled={!data.booking?.id}
                      style={{ background: '#ffb400' }}
                    >
                      {t('showroom.Booking')} №{data.booking?.number}
                    </Button>
                    <Button
                      onClick={petitionHandle}
                      type="primary"
                    // disabled={apartmentsId.includes(data?.id)}
                    >
                      {t('showroom.Batafsil')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.container}>
              <div className={styles.container_title}>
                <p>
                  {t('showroom.Xonadon')} №{data?.number}
                </p>
                <span>
                  <DotIcon color={data?.status ? flatStatusColor[data?.status] : ''} /> {t(`home.${data?.status}`)}
                </span>
              </div>


              <div className={styles.container_flatImage}>
                <div style={{ height: 200 }}>
                  {!imageObj[image3D].img && (
                    <p>
                      <UnionShowroomIcon /> {t('showroom.Rasm_mavjud_emas')}
                    </p>
                  )}

                  <Image
                    src={imageObj[image3D].thumbAbsolutePath || imageObj[image3D].img}
                    loading="lazy"
                    preview={{
                      mask: t('client.Korish'),
                      visible: false
                    }}
                    placeholder={
                      <Spin spinning={true}>
                        <div style={{ width: '100%', height: 200 }} />
                      </Spin>
                    }
                    height={200}
                    fallback={imageObj[image3D].fallback}
                    onClick={() => setVisibleImageGroup(true)}
                  />

                  <div style={{ display: 'none' }}>
                    <Image.PreviewGroup
                      preview={{
                        onVisibleChange: visible => {
                          setVisibleImageGroup(visible);
                        },
                        visible: visibleImageGroup,
                        current: currentImageIndex
                      }}
                    >
                      {visibleImageGroup &&
                        data?.gallery?.map(item => (
                          <Image src={`${BASE_URL}/${item.absolutePath}`} key={item.absolutePath} />
                        ))
                      }
                    </Image.PreviewGroup>
                  </div>
                </div>

                <Segmented
                  className={styles.container_flatImage_segmented}
                  value={image3D}
                  options={[
                    { icon: <Element3Icon />, value: '2D', label: '2D' },
                    { icon: <CubeIcon />, value: '3D', label: '3D' }
                  ]}
                  onChange={value => setImage3D(value as '2D' | '3D')}
                />
              </div>





              <div className={styles.container_flatPrice}>
                <h1>
                  {t('showroom.Narxi')}{' '}
                  <span>
                    {data?.price?.toLocaleString('ru')} {houseCurrency?.ccy}
                  </span>
                </h1>
                <p>
                  {data?.pricePerArea?.toLocaleString('ru')} {houseCurrency?.ccy}/{t('showroom.m')}²
                </p>
              </div>
              <div className={styles.container_flatInfo}>
                <h1>{t('showroom.Xonadon_malumotlari')}</h1>
                <div>
                  <p>
                    {t('showroom.Bino')} <span>{data?.buildingName}</span>
                  </p>
                  <p>
                    {t('showroom.Qavat')} <span>{data?.floor}</span>
                  </p>
                  <p>
                    {t('showroom.Xonadon_raqami')} <span>{data?.number}</span>
                  </p>
                  <p>
                    {t('showroom.Xonalar_soni')} <span>{data?.rooms}</span>
                  </p>

                  {data?.terrace && (
                    <>
                      <p>
                        {t('showroom.Xonadon_maydoni')}{' '}
                        <span>
                          {data?.area} {t('showroom.m')}
                          <sup>2</sup>
                        </span>
                      </p>
                      <p>
                        {t('showroom.Terassa_maydoni')}{' '}
                        <span>
                          {data?.terrace?.area} m<sup>2</sup>
                        </span>
                      </p>
                    </>
                  )}

                  <p>
                    {t('showroom.Umumiy_maydoni')}{' '}
                    <span>
                      {roundFloatNumber((data?.area || 0) + (data?.terrace?.area || 0))} m<sup>2</sup>
                    </span>
                  </p>

                  {/* {data?.terraceRTO?.area && (
                    <p>
                      {t('showroom.Xonadon_maydoni')}{' '}
                      <span>
                        {data?.area} {t('showroom.m')}
                        <sup>2</sup>
                      </span>
                    </p>
                  )} */}

                  <p>
                    <p>
                      {t('showroom.Boshlangich_tolov')}
                      {/* {data?.prepaymentPercent && <span> ({`${data?.prepaymentPercent}%`})</span>} */}
                      <span> ({`${data?.prepaymentPercent}%`})</span>
                    </p>
                    <span>
                      {data?.prepaymentAmount?.toLocaleString('ru')} {houseCurrency?.ccy}
                    </span>
                  </p>
                  <p>
                    {t('showroom.Tulov_muddati')}{' '}
                    <span>
                      {data?.delay} {t('showroom.oy')}
                    </span>
                  </p>
                  <p>
                    {t('showroom.Oylik_tolov')}{' '}
                    <span>
                      {data?.monthlyPayment?.toLocaleString('ru')} {houseCurrency?.ccy}
                    </span>
                  </p>
                  <p>
                    {t('home.Holati')} <span>{data?.repaired ? t('home.Tamirli') : t('home.Tamirsiz')}</span>
                  </p>
                  {data?.terraceRTO?.area ? (
                    <p>
                      {t('showroom.Terassa_maydoni')}{' '}
                      <span>
                        {data?.terraceRTO?.area} m<sup>2</sup>
                      </span>
                    </p>
                  ) : (
                    ''
                  )}
                  {data?.fields?.map(item => (
                    <p key={item.fieldId}>
                      {item?.name}
                      <span>{item?.value}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default ShowroomV2Drawer;
