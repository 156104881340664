import { useAppSelector } from '@hooks/reduxHooks';
import { $api } from '@service/RequestService';
import { Permissions } from '@utils/constants/permissions';
import { useQuery } from 'react-query';

import { queryKeys } from '../constants';
import { IAutoTaskConfigEmployee } from '../models';

export const useAutoTaskConfigEmployee = () => {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isAccessSettingsBotAutoTask = permissions?.includes(Permissions.PERMISSION_SETTINGS_AUTO_TASK);

  return useQuery<IAutoTaskConfigEmployee[]>({
    queryKey: [queryKeys.AUTO_TASK_CONFIG_EMPLOYEE],
    queryFn: async () => {
      const res = await $api.get('/v1/auto-task-setting/employee');

      return res.data.data;
    },
    enabled: isAccessSettingsBotAutoTask
  });
};
