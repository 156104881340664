/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate } from 'react-router';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowLineDownIcon from '@icons/ArrowLineDownIcon';
import Download from '@icons/Download';
import ExcelIcon from '@icons/ExcelIcon';
import { useGetCallHistoryExcel } from '@pages/bossCrm/services/mutate';
import { IUniqueCalls } from '@pages/bossCrm/utils/models/UniqueCalls';
import { BoosComplexSelectType } from '@utils/models/BoosComplexSelectType';
import { Dropdown, Spin, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { encodeString, toHHMMSS } from 'utils';

import ArrowBottomInboundIcon from '../../../../assets/icons/ArrowBottomInboundIcon';
import ArrowLocalIcon from '../../../../assets/icons/ArrowLocalIcon';
import ArrowTopInboundIcon from '../../../../assets/icons/ArrowTopInboundIcon';
import ArrowTopOutboundIcon from '../../../../assets/icons/ArrowTopOutboundIcon';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';
import PaymentPagination from '../../../paymentV2/components/payment-pagination/PaymentPagination';
import { useGetCallHistoryFilter } from '../../services/queries';
import { CallHistoryFilterModel } from '../../utils/models/CallHistoryFilterModel';
import { finishDateNotUtc, startDateNotUtc } from '../callStatistics/startAndFinishDates';
import { CallStatisticsParam } from '../header/Header';

import FilterActions from './FilterActions';

import styles from './filterCalls.module.scss';

function toTimeString(totalSeconds: number) {
  // const totalMs = totalSeconds * 1000;
  // const result = new Date(totalMs).toISOString().slice(11, 19);

  return toHHMMSS(String(totalSeconds));
}

const customIcons: Record<number, React.ReactNode> = {
  1: <span className={styles.circleIcon} style={{ backgroundColor: '#E74141' }} />,
  2: <span className={styles.circleIcon} style={{ backgroundColor: '#E74141' }} />,
  3: <span className={styles.circleIcon} style={{ backgroundColor: '#FFC90C' }} />,
  4: <span className={styles.circleIcon} style={{ backgroundColor: '#FFC90C' }} />,
  5: <span className={styles.circleIcon} style={{ backgroundColor: '#01C716' }} />,
  0: <span className={styles.circleIcon} style={{ backgroundColor: '#D9D9D9' }} /> // Значение 0 для пустых иконок
};

const CircleRate = ({ value }: { value: number }) => {
  const renderCircleIcons = () => {
    const circles = [];

    for (let i = 0; i < 5; i++) {
      circles.push(
        <span
          key={i}
          style={{ marginRight: '3px' }} // Добавляем отступ между круглыми элементами
        >
          {customIcons[i < value ? value : 0]}
        </span>
      );
    }
    return <Tooltip title={`${t('home.Baho')}: ${value}`}>{circles}</Tooltip>;
  };

  return <div>{renderCircleIcons()}</div>;
};

export const obj: {
  [key: string]: boolean;
} = {
  true: true,
  false: false
};

type Props = {
  callType: string | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
};

const FilterCalls: React.FC<Props> = ({ callType, currentPage, setCurrentPage }) => {
  const { searchParams, appendSingle } = useQueryParam<CallStatisticsParam, any>();

  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetCallHistoryFilter(
    currentPage,
    10,
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.startDate, DATE_FORMAT).startOf('day').unix()
      : startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc],
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.finishDate, DATE_FORMAT).endOf('day').unix()
      : finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc],
    searchParams?.employee,
    callType,
    searchParams.unique,
    searchParams.statuses,
    searchParams?.pipeStatus && Number(searchParams?.pipeStatus),
    searchParams?.durationStart,
    searchParams?.durationFinish,
    searchParams?.talkTimeStart,
    searchParams?.talkTimeFinish
  );

  const downloadExcel = useGetCallHistoryExcel();

  const handleDownloadExcel = () => {
    downloadExcel.mutate({
      page: currentPage,
      size: 10,
      finishDate:
        // eslint-disable-next-line max-len
        searchParams?.date === BoosComplexSelectType.SPECIAL1
          ? dayjs(searchParams?.finishDate, DATE_FORMAT).unix()
          : finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc],
      startDate:
        // eslint-disable-next-line max-len
        searchParams?.date === BoosComplexSelectType.SPECIAL1
          ? dayjs(searchParams?.startDate, DATE_FORMAT).unix()
          : startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc],
      callType,
      employeeId: searchParams?.employee
    });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    localStorage.setItem('page', String(page));
    appendSingle('page', page);
  };

  const downloadButton = (
    <Dropdown
      placement="bottomRight"
      overlay={
        <div className={styles.downloadPopover}>
          <div className={styles.downloadPopover_item} onClick={() => handleDownloadExcel()}>
            <div className={styles.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Excel')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click', 'hover']}
      mouseEnterDelay={1000}
      mouseLeaveDelay={0.3}
    >
      <span className={styles.icon}>
        <Download />
        <span>{t('home_id.Yuklab_olish')}</span>
      </span>
    </Dropdown>
  );
  const navigate = useNavigate();

  const columns: ColumnsType<CallHistoryFilterModel> = [
    {
      title: t('home.Turi')?.substring(0, 4),
      key: 'name',
      render: (render: CallHistoryFilterModel) =>
        // eslint-disable-next-line no-nested-ternary
        render?.accountcode === 'inbound' && render?.user_talk_time === 0 ? (
          <ArrowTopInboundIcon />
        ) : // eslint-disable-next-line no-nested-ternary
          render?.accountcode === 'inbound' ? (
            <ArrowBottomInboundIcon />
          ) : render?.accountcode === 'outbound' ? (
            <ArrowTopOutboundIcon />
          ) : (
            <ArrowLocalIcon />
          )
    },
    {
      title: t('crm.Vaqt'),
      key: 'time',
      render: (render: CallHistoryFilterModel) => <span>{dayjs.unix(render.start_stamp).format(DATE_WITH_TIME_FORMAT)}</span>
    },
    {
      title: t('crm.Kim'),
      key: 'contactNumber',
      render: (prev: CallHistoryFilterModel) => {
        const generateTitle = (prev: any) => {
          if (prev.accountcode === 'local' || prev.accountcode === 'outbound') {
            return prev?.employeeName === null ? t('home.Nomalum') : prev?.employeeName;
          }
          if (prev.accountcode === 'inbound') {
            return prev?.contactName === null && prev?.leadName === null ? t('home.Nomalum') : `${prev?.contactName} ${prev?.leadName}`;
          }
          return '';
        };
        const generatedTitle = generateTitle(prev);

        return (
          <div
            className={styles.whoStyle}
            onClick={() => {
              if (prev.accountcode === 'inbound') {
                navigate(`/main/crm/${prev.leadId}/${encodeString(prev.leadName)}?prev=true`);
              }
            }}
          >
            <span
              style={{
                textDecoration: prev.accountcode === 'inbound' ? 'underline' : '',
                cursor: prev.accountcode === 'inbound' ? 'pointer' : ''
              }}
            >
              {prev?.caller_id_number?.length > 13 ? `${prev?.caller_id_number?.substring(0, 13)}...` : prev?.caller_id_number}
            </span>
            <p style={{ textDecoration: 'none' }}>{generatedTitle}</p>
          </div>
        );
      }
    },
    {
      title: t('crm.Kimga'),
      key: 'destination_number',
      render: (prev: CallHistoryFilterModel) => {
        const generateTitle = (prev: any) => {
          if (prev.accountcode === 'local') {
            return prev?.destination_number === null ? t('home.Nomalum') : prev?.destination_number;
          }
          if (prev.accountcode === 'outbound') {
            return prev?.contactName === null && prev?.leadName === null ? t('home.Nomalum') : `${prev?.contactName} ${prev?.leadName}`;
          }
          if (prev.accountcode === 'inbound') {
            return prev?.employeeName === null ? t('home.Nomalum') : prev?.employeeName;
          }
          return '';
        };

        const generatedTitle = generateTitle(prev);

        return (
          <div
            className={styles.whoStyle}
            onClick={() => {
              if (prev.accountcode === 'outbound') {
                navigate(`/main/crm/${prev.leadId}/${encodeString(prev.leadName)}?prev=true`);
              }
            }}
          >
            <span
              style={{
                textDecoration: prev.accountcode === 'outbound' ? 'underline' : '',
                cursor: prev.accountcode === 'outbound' ? 'pointer' : ''
              }}
            >
              {prev?.destination_number}
            </span>
            <p style={{ textDecoration: 'none' }}>{generatedTitle}</p>
          </div>
        );
      }
    },
    {
      title: t('crm.Ichki_raqam'),
      key: 'employeeNum',
      render: (prev: CallHistoryFilterModel) => <span>{prev?.employeeNum?.length > 13 ? `${prev?.employeeNum?.substring(0, 13)}...` : prev?.employeeNum}</span>
    },
    {
      title: (
        <div>
          <p>Qo'ng'iroq /</p>
          <p>Suhbat vaqti</p>
        </div>
      ),
      render: (render: CallHistoryFilterModel) => (
        <div>
          <p>{toTimeString(render?.end_stamp - render?.start_stamp)}</p>
          <p>{toTimeString(render?.user_talk_time)}</p>
        </div>
      ),
      align: 'center',
      width: '10%'
    },
    {
      title: 'Natija',
      render: (render: any) => (
        <span>
          {['USER_BUSY', 'UNALLOCATED_NUMBER'].includes(render?.hangup_cause)
            ? t(`online_pbx.${render.hangup_cause}`)
            : render?.user_talk_time > 0
              ? t('online_pbx.ANSWER')
              : t('online_pbx.NO_ANSWER')}
        </span>
      )
    },
    {
      title: t('home.Baho'),
      key: 'quality_score',
      render: (render: CallHistoryFilterModel) => <CircleRate value={render?.quality_score} />
    },
    {
      title: <Spin spinning={downloadExcel?.isLoading}>{downloadButton}</Spin>,
      key: 'action',
      render: (render: CallHistoryFilterModel) => <FilterActions render={render} />
    }
  ];

  const secondColumns: ColumnsType<IUniqueCalls> = [
    {
      title: t('crm.Kim'),
      // dataIndex: 'number',
      key: 'number',
      align: 'center',
      render: (render: IUniqueCalls) => (
        <div onClick={() => navigate(`/main/crm/${render?.leadId}/${encodeString(render?.leadName)}?prev=true`)} className="cursor-pointer underline">
          {render?.number}
        </div>
      )
    },
    {
      title: t('crm.Qongiroq_davomiyligi'),
      key: 'duration',
      render: (render: IUniqueCalls) => <span>{toTimeString(render?.duration)}</span>,
      align: 'center'
    },
    {
      title: t('crm.Gaplashilgan_vaqt'),
      key: 'talkTime',
      render: (render: IUniqueCalls) => <span>{toTimeString(render?.talkTime)}</span>,
      align: 'center'
    },
    {
      title: 'Necha marta gaplashgan',
      dataIndex: 'countCall',
      width: '10%',
      align: 'center'
    }
  ];

  return (
    <div className={styles.container}>
      <Table
        columns={!obj[searchParams.unique] ? columns : (secondColumns as ColumnsType<CallHistoryFilterModel>)}
        dataSource={data?.data as CallHistoryFilterModel[]}
        pagination={false}
        loading={isLoading || isFetching}
      // style={{ maxHeight: 735 }}
      />

      <div className={styles.pagination}>
        <PaymentPagination total={data?.totalElements} pageSize={10} current={data?.currentPage ? data.currentPage : 1} onChange={handlePageChange} hideOnSinglePage={true} />
      </div>
    </div>
  );
};

export default FilterCalls;
