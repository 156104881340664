import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { contractStatusColor } from '@pages/showroom-v2/utils/constants/contractStatusColor';
import { flatStatusColor } from '@pages/showroom-v2/utils/constants/flatStatusColor';
import { IShowroomFlat } from '@pages/showroom-v2/utils/models/IShowroomFlat';
import { clear } from '@reducers/CreateContractSlice';
import { setShowroomApartments, setShowroomDrawer } from '@reducers/ShowroomSlice';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';
import { Popover } from 'antd';
import { tableUtils, useTableKay } from 'apis';
import { useTranslation } from 'react-i18next';

import styles from './showroomShaxmatkaFlat.module.scss';

interface IProps extends IShowroomFlat {
  filterData: number[] | undefined;
  xFloor: number;
}

const ShowroomShaxmatkaFlat = (flat: IProps) => {
  const { t } = useTranslation();
  const { drawer, apartments, apartmentActiveIndex, flatNumber, switchFilterStatus } = useAppSelector(state => state.showroomSlice);
  const { data: flatView } = useTableKay(tableUtils.keys.SHOWROOM_FLAT_VIEW, 'string');
  const houseCurrency = useAppSelector(state => state.currency.houseCurrency);
  const dispatch = useAppDispatch();

  const statusHeader = {
    SALE: `${flat?.repaired ? flat?.priceRepaired?.toLocaleString('ru') : flat?.priceNotRepaired?.toLocaleString('ru')} ${houseCurrency?.ccy}`,
    SOLD: t('showroom.Sotilgan'),
    BOOKED: t('showroom.Band_qilingan'),
    RESERVE: t('showroom.Sotilmaydi')
  };

  const handleClick = () => {
    if (
      ((apartments?.length === 0 || !apartments.some(item => item.id === flat?.id)) && flat?.commerceStatus === 'SALE') ||
      (!apartments.some(item => item.id === flat?.id) && apartments?.length === 1 && flat?.commerceStatus === 'BOOKED')
    ) {
      dispatch(
        // @ts-expect-error
        setShowroomApartments([
          ...apartments.map((item, index) =>
            index === apartmentActiveIndex
              ? {
                  id: flat?.id,
                  number: flat?.number,
                  rooms: flat?.rooms,
                  status: flat?.commerceStatus,
                  floor: flat?.floor
                }
              : item
          )
        ])
      );
      dispatch(clear());
    }
    dispatch(setShowroomDrawer({ id: flat?.id, visible: true, floor: flat?.floor }));
  };

  const flatViewObj = {
    number: flat?.number,
    floor: flat?.xFloor,
    rooms: flat?.rooms
  };

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {flat?.id ? (
        flat?.filterData?.includes(flat?.id) ? (
          <Popover
            overlayClassName="apartmentPopover"
            content={
              <>
                <div className={styles.tooltip}>
                  <div className={styles.tooltip_header}>
                    <span
                      style={{
                        background: switchFilterStatus ? contractStatusColor[flat?.contractStatus] || flatStatusColor[flat?.commerceStatus] : flatStatusColor[flat?.commerceStatus]
                      }}
                    >
                      {flat?.rooms}x
                    </span>
                    <p>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {switchFilterStatus ? (flat?.contractStatus ? t(`home.${flat?.contractStatus}`) : statusHeader[flat?.commerceStatus]) : statusHeader[flat?.commerceStatus]}
                    </p>
                  </div>
                  <div className={styles.tooltip_bottom}>
                    <p>№ {flat?.number}</p>
                    <p>
                      {roundFloatNumber(flat?.area + (flat?.terrace?.area || 0))} m<sup>2</sup> -{' '}
                      {flat?.repaired ? flat?.pricePeraAreaRepaired?.toLocaleString('ru') : flat?.pricePerAreaNotRepaired?.toLocaleString('ru')} {houseCurrency?.ccy}/m
                      <sup>2</sup>{' '}
                    </p>
                  </div>
                </div>
              </>
            }
          >
            <div
              id={drawer?.id === flat?.id ? 'activeFlat' : ''}
              className={`${styles.flat} ${drawer?.id === flat?.id ? styles.flat_active : ''}`}
              onClick={handleClick}
              style={{
                background: switchFilterStatus ? contractStatusColor[flat?.contractStatus] || flatStatusColor[flat?.commerceStatus] : flatStatusColor[flat?.commerceStatus],
                fontSize: 16 - Number(flat?.nLength) * 1.6
              }}
            >
              {flatViewObj[flatView as keyof typeof flatViewObj]}
            </div>
          </Popover>
        ) : (
          <div
            className={`${styles.flat} ${drawer?.id === flat?.id ? styles.flat_active : ''} ${styles.flat_disable}`}
            style={{
              background: switchFilterStatus ? contractStatusColor[flat?.contractStatus] || flatStatusColor[flat?.commerceStatus] : flatStatusColor[flat?.commerceStatus]
            }}
          >
            {flatView === 'number' ? flat?.number : flat?.rooms}
          </div>
        )
      ) : (
        <div className={styles.empty} />
      )}
    </>
  );
};

export default ShowroomShaxmatkaFlat;
