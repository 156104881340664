import React, { useState } from 'react';
import { Pagination } from '@utils/models/Pagination';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';

import { ClientType } from '../../../../utils/models/ClientType';
import { CreateOrderSearchClients } from '../../../../utils/models/CreateOrder';

import styles from './createOrderClientsList.module.scss';

interface Props {
  isLoading: boolean;
  data: CreateOrderSearchClients[] | undefined;
  type: ClientType;
  selectClient: (client: CreateOrderSearchClients) => void;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<Pagination<CreateOrderSearchClients>, unknown>>;
  isFetching: boolean;
}

const CreateOrderClientsList: React.FC<Props> = ({ data, isLoading, type, selectClient, fetchNextPage, isFetching }) => {
  const { t } = useTranslation();
  const [scrolling, setScrolling] = useState(true);

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 100 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  const clientName = (client: CreateOrderSearchClients) => {
    if (type === ClientType.NATURAL) {
      if (client.fatherName) {
        return `${client.lastname} ${client.firstname} ${client.fatherName}`;
      }
      return `${client.lastname} ${client.firstname}`;
    }
    return client.name;
  };

  if (data?.length === 0) {
    return (
      <div className={styles.searchClientsContainer}>
        <Empty description={t('order.Mijoz_topilmadi')} />
      </div>
    );
  }

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.searchClientsContainer}>
        <div className={styles.list} onScroll={onScroll}>
          {data?.map(client => (
            <div key={client?.id} onClick={() => selectClient(client)} className={styles.item}>
              <div className={styles.phone}>{client.phone}</div>
              <div className={styles.client}>{clientName(client)}</div>
              <div className={styles.line} />
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default CreateOrderClientsList;
