import React from 'react';

const ArrowElbowLeft = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 7.5H2.25V14.25" stroke="#E53535" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.75 9L12.75 18L2.25 7.5" stroke="#E53535" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowElbowLeft;
