import React from 'react';

const RuIcon = () => {
  return (
    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3520_14694)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0.5H32.5V24.5H0.5V0.5Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.5H32.5V24.5H0.5V8.5Z" fill="#0039A6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.5 16.5H32.5V24.5H0.5V16.5Z" fill="#D52B1E" />
      </g>
      <rect x="0.25" y="0.25" width="32.5" height="24.5" stroke="#DADADA" strokeWidth="0.5" />
      <defs>
        <clipPath id="clip0_3520_14694">
          <rect x="0.5" y="0.5" width="32" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RuIcon;
