import React from 'react';

const ClockCrmIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1689 12C22.1689 17.52 17.6889 22 12.1689 22C6.64895 22 2.16895 17.52 2.16895 12C2.16895 6.48 6.64895 2 12.1689 2C17.6889 2 22.1689 6.48 22.1689 12Z"
        stroke="#FF6565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8788 15.1798L12.7788 13.3298C12.2388 13.0098 11.7988 12.2398 11.7988 11.6098V7.50977"
        stroke="#FF6565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockCrmIcon;
