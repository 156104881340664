import { showMessage } from '@queries/showMessage';
import { downloadFile } from '@utils/helper/downloadFile';
import message from '@utils/helper/message';
import { FileTemplateLanguage } from '@utils/models/FileTemplate';
import { useMutation, useQueryClient } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { queryNames } from '../../../utils/constants/queryNames';
import { endPoints } from '../utils/constants/endPoints';
import { AddPaymentType } from '../utils/models/AddPaymentModel';
import { BookingAdditionModel } from '../utils/models/BookingAdditionModel';

export function useCreatePrepayment() {
  const qc = useQueryClient();

  return useMutation(
    async (data: BookingAdditionModel) => {
      const res = await $api.post(endPoints.PREPAYMENT, data);

      return res.data;
    },
    {
      onSuccess: res => {
        qc.invalidateQueries([queryNames.BOOKED]);
        message.success(res?.message.uz);
      },
      onError: baseErrorHandler
    }
  );
}

export function useAdditionPayment() {
  const qc = useQueryClient();

  return useMutation(
    async (data: AddPaymentType) => {
      const res = await $api.post(endPoints.ADDITIONAL_PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: res => {
        qc.invalidateQueries([queryNames.BOOKED]);
        message.success(res?.message.uz);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCancelBookingPayment() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { paymentIds: number[]; cancelText: string }) => {
      const res = await $api.post(endPoints.CANCEL_PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: res => {
        qc.invalidateQueries([queryNames.BOOKED]);
        message.success(res?.message.uz);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadBookingToWord(id: number | null, number: string | null, onSuccess?: () => void) {
  return useMutation(
    async (lang: FileTemplateLanguage | undefined) => {
      const res = await $api.get(`${endPoints.BOOKING}/${id}/template?lang=${lang}`, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `booking-${number}`, '.docx');
        onSuccess && onSuccess();
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}
