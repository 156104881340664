import { useQuery } from 'react-query';

import { $api } from '../../../../service/RequestService';
import { Pagination } from '../../../../utils/models/Pagination';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { BossPaymentDelayModel } from '../utils/models/BossPaymentDelayModel';
import { OneBossPaymentDelay } from '../utils/models/OneBossPaymentDelatModel';

export function useGetPaymentDelay() {
  return useQuery<BossPaymentDelayModel>(
    [queryKeys.BOSS_PAYMENT_DELAY],
    async () => {
      const res = await $api.get(endPoints.BOSS_PAYMENT_DELAY);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false
    }
  );
}

export function useGetOnePaymentDelay(delay: string, page: number, size: number) {
  return useQuery<Pagination<OneBossPaymentDelay>>(
    [queryKeys.BOSS_PAYMENT_DELAY, delay, page, size],
    async () => {
      const res = await $api.get(`${endPoints.BOSS_PAYMENT_DELAY}/${delay}?page=${page}&size=${size}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false
    }
  );
}
