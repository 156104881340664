import React, { FC } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  comment: {
    visible: boolean;
    text: string;
    employee: string;
    date: string;
  };
  setComment: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      text: string;
      employee: string;
      date: string;
    }>
  >;
}

const ContractIdCommentModal: FC<IProps> = ({ comment, setComment }) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    setComment({
      date: '',
      employee: '',
      text: '',
      visible: false
    });
  };

  return (
    <Modal
      title={false}
      visible={comment.visible}
      footer={null}
      closable={false}
      width={740}
      centered
      onCancel={handleCancel}
      className="commentModal"
    >
      <h1>{!comment.date ? t('contract.Izoh') : t('contract_id.Bekor_qilinganlik_haqida_malumot')}</h1>
      {!comment.date ? null : (
        <>
          <div>
            <h1>{t('contract_id.Ozgarish_sanasi')}</h1>
            <h1>{t('contract_id.Bekor_qildi')}</h1>
          </div>
          <div>
            <p>{comment.date}</p>
            <p>{comment.employee}</p>
          </div>
        </>
      )}
      <p>
        {!comment.date ? null : <h1>{t('contract.Izoh')}</h1>}
        {comment.text}
      </p>
    </Modal>
  );
};

export default ContractIdCommentModal;
