import React from 'react';
import { Drawer } from 'antd';

import styles from './filterDrawer.module.scss';
import { useQueryParam } from '@hooks/useQueryParams';

import EmployeeFilter from './EmployeeFilter';
import { useTranslation } from 'react-i18next';
import FilterReloadIcon from '@icons/FilterReloadIcon';
import StatusFilter from './StatusFilter';
import { SearchParamsTask } from '@utils/models/FilterTask';
import TaskTypeFilter from './TaskTypeFilter';
import TaskTypeIds from './TaskTypeIds';
import PipeStatusIds from './PipeStatusIds';
import DateFilter from '@pages/crm/components/filter/drawer/DateFilter';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
}

const FilterDrawer: React.FC<Props> = ({ visible, onClose }) => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsTask, any>();

  const { t } = useTranslation();

  // handle close
  const handleClose = () => {
    onClose(false);
  };

  // delete all filter
  const deleteAllFilters = () => {
    appendMultipleDifferent(
      ['employeeList', 'leadTaskTypeIds', 'createdByIds', 'pipeStatusIds', 'leadTaskStatusFilter', 'leadTaskTypeFilter', 'startDate', 'finishDate'],
      []
    );
  };

  return (
    <Drawer width={339} headerStyle={{ display: 'none' }} placement="left" onClose={handleClose} visible={visible} className={styles.container}>
      <div
        onMouseDown={() => {
          document.body.style.cursor = 'default';
        }}
      >
        <div className={'d-flex align-center justify-space-between'}>
          <h2 className={styles.container_title}>Filter</h2>
          <span className={styles.container_clear} onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </div>
        <DateFilter
          start={searchParams.startDate}
          finish={searchParams.finishDate}
          startLabel={'startDate'}
          finishLabel={'finishDate'}
          title={'Shu_kungacha'}
          type="task"
        />
        <TaskTypeIds leadTaskTypeIds={searchParams.leadTaskTypeIds} />
        <PipeStatusIds pipeStatusIds={searchParams.pipeStatusIds} />
        <EmployeeFilter title={'Masul_xodim'} labelFilter={'employeeList'} ids={searchParams.employeeList} />
        <StatusFilter status={searchParams.leadTaskStatusFilter} />
        <EmployeeFilter title={'Kim_yaratgan'} labelFilter={'createdByIds'} ids={searchParams.createdByIds} />
        <TaskTypeFilter type={searchParams.leadTaskTypeFilter} />
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
