import React, { useEffect } from 'react';
import TrashIconKPI from '@icons/contract/TrashIconKPI';
import { cx } from '@utils/cx';
import { Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDeleteKpiPart, useEditKpiPart } from '../../../queries/mutation';
import { helper } from '../../../utils/helper/helper';
import { KpiPart } from '../../../utils/models/Kpi';

import styles from '../../../pages/kpi/kpi.module.scss';

interface Props {
  kpiPart?: KpiPart;
}

const PercentItem: React.FC<Props> = ({ kpiPart }) => {
  const [form] = Form.useForm<{ amount: number; count: string }>();

  const editKpiPart = useEditKpiPart();

  const deleteKpiPart = useDeleteKpiPart(kpiPart?.id || 0);

  const { t } = useTranslation();

  useEffect(() => {
    if (kpiPart) {
      form.setFieldsValue({
        amount: kpiPart.amount,
        count: kpiPart.count.toLocaleString('ru')
      });
    }
  }, [kpiPart, form]);

  const handleUpdate = async () => {
    const { amount, count } = await form.validateFields();

    editKpiPart.mutate({
      amount: Number(amount),
      count: Number(helper.deleteSpace(count)),
      id: kpiPart?.id,
      kpiId: kpiPart?.kpiId || 0
    });
  };

  const handleDelete = () => {
    deleteKpiPart.mutate();
  };

  return (
    <Spin spinning={editKpiPart.isLoading || deleteKpiPart.isLoading}>
      <Form form={form} name="PercentItem">
        <div className={cx('d-flex align-center justify-space-between', styles.amongus)}>
          <div className={styles.cont_body_right_simple}>
            <div className={styles.cont_body_right_simple_left}>
              <p>{t('kpi.Ixtiyori_mukofot_foizi')}</p>
              <span>{t('kpi.Sotilgan_uylar_soniga_bogliq_ravishda_mukofot_hisoblab_boriladi')}</span>
            </div>
            <div className={styles.cont_body_right_simple_right}>
              <Form.Item
                name={'count'}
                rules={[
                  { required: true, message: '', min: 1 },
                  {
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject('');
                      }
                      const count = helper.deleteNotNumbers(value, null);

                      form.setFieldsValue({
                        count: count?.toLocaleString('ru')
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input onBlur={handleUpdate} className={styles.cont_body_right_simple_right_mini_input + ' customInput'} />
              </Form.Item>
              <span className={styles.cont_body_right_simple_right_middle_title}>{t('kpi.ta_dan_oshsa')}</span>
              <Form.Item
                name={'amount'}
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (value < 0) {
                        form.setFieldsValue({
                          amount: 0
                        });
                      } else if (value > 100) {
                        form.setFieldsValue({
                          amount: 100
                        });
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input onBlur={handleUpdate} type={'number'} className={styles.cont_body_right_simple_right_mini_input + ' customInput'} />
              </Form.Item>
              <span>%</span>
              <span onClick={handleDelete} className={`${styles.cont_body_right_delete} ${styles.trashIcon}`}>
                <TrashIconKPI />
              </span>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default PercentItem;
