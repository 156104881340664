import React, { useState } from 'react';
import CustomInput from '@components/custom-fields/components/custom-input/CustomInput';
import CustomSelect from '@components/custom-fields/components/custom-select/CustomSelect';
import { Avatar, Collapse, Dropdown, Image, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '../../../../../assets/icons/ArrowDownIconCrm';
import AvatarIcon from '../../../../../assets/icons/AvatarIcon';
import ContactPlusIcon from '../../../../../assets/icons/ContactPlusIcon';
import TrushCrmIcon from '../../../../../assets/icons/crm/TrushCrmIcon';
import FacebookLogoAndNameIcon from '../../../../../assets/icons/FacebookLogoAndNameIcon';
import InstagramLogoNameIcon from '../../../../../assets/icons/IntagramLogoNameIcon';
import MenuThreeDotHorizontalIcon from '../../../../../assets/icons/MenuThreeDotHorizontalIcon';
import TelegramLogoAndNameIcon from '../../../../../assets/icons/TelegramLogoAndNameIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { useCrmFields } from '../../../../../queries/queries';
import { BASE_URL } from '../../../../../service/RequestService';
import { addContact, changeContactName, changeContactsFiledValue, leadFieldFilterAi, removeContact } from '../../../../../store/reducers/CrmOneLidSlice';
import { CrmFieldsType } from '../../../../../utils/models/CrmFieldsType';
import { ContactCrm, TypeContact } from '../../../../../utils/models/OneLid';
import GetLocationAddres from '../info/GetLocationAddres';
import { convertLocation } from '../info/Info';
import LocationAttachment from '../location-attachment/LocationAttachment';
import SendMessageModal from '../sendMessage/SendMessageModal';

import OneNumber from './OneNumber';

import styles from './contacts.module.scss';

const { Panel } = Collapse;
const { Option } = Select;

const Contacts = () => {
  const [openActionDropdown, setOpenActionDropdown] = useState({
    visible: false,
    index: -1
  });

  const { contacts, leadFieldValueAi } = useAppSelector(state => state.crmOneLidField);

  const { isLoading: loadingFields } = useCrmFields();
  const fields = useAppSelector(state => state.crmField.contact);
  const isAddContact = useAppSelector(state => state.crmOneLidField.isAddContact);
  const isMoiZvonki = useAppSelector(state => state.crmOneLidField.isMoiZvonki);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // handle actions dropdown
  const handleActionsDropdown = (visible: boolean, index: number) => {
    setOpenActionDropdown({
      index,
      visible
    });
  };

  // change value
  const handleChangeValue = (contactId: number | null, id: number, value: string) => {
    dispatch(
      changeContactsFiledValue({
        contactId,
        value,
        id
      })
    );
  };

  // change name
  const handleChangeName = (contactId: number | null, value: string) => {
    dispatch(
      changeContactName({
        contactId,
        value
      })
    );
  };

  // handle add contact
  const handleAddContact = () => {
    dispatch(addContact());
  };

  // handle delete contact
  const handleDeleteContact = (id: null | number) => {
    dispatch(removeContact(id));
  };

  // handle click menu button
  const handleClickMenuButton = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    handleActionsDropdown(true, index);
  };

  // get link contact
  const getLinkContact = (contact: ContactCrm) => {
    if (contact.accountId) {
      switch (contact.type) {
        case TypeContact.INSTAGRAM:
          if (contact.username) {
            return (
              <a rel="noreferrer" href={`https://www.instagram.com/${contact.username}`} target="_blank">
                <InstagramLogoNameIcon />
              </a>
            );
          }
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#">
              <InstagramLogoNameIcon />
            </a>
          );
        case TypeContact.TELEGRAM:
          if (contact.username)
            return (
              <a rel="noreferrer" href={`https://t.me/${contact.username}`} target="_blank">
                <TelegramLogoAndNameIcon />
              </a>
            );
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#">
              <TelegramLogoAndNameIcon />
            </a>
          );
        case TypeContact.FACEBOOK:
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#">
              <FacebookLogoAndNameIcon />
            </a>
          );
        default:
          return null;
      }
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <Spin spinning={loadingFields}>
        <Collapse accordion={true} defaultActiveKey={[0]} expandIcon={() => null} ghost>
          {contacts.map((contact, index) => (
            <Panel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              header={
                <div className={styles.container_header}>
                  <Avatar
                    className={styles.container_header_avatar}
                    icon={contact.avatar ? <Image preview={false} src={`${BASE_URL}/${contact.avatar}`} /> : <AvatarIcon size={32} />}
                  />
                  <div className={styles.container_header_name_cont}>
                    <CustomInput
                      onClick={e => e.stopPropagation()}
                      onChange={e => handleChangeName(contact?.id, e.target.value)}
                      bordered={false}
                      placeholder={t('one_lid.Kontakt_nomi')}
                      className={styles.container_header_name}
                      value={contact.name}
                      ai={leadFieldValueAi?.includes(`${contact?.id}:AI`)}
                      onStopAiTime={() => {
                        dispatch(leadFieldFilterAi(`${contact?.id}:AI`));
                      }}
                    />
                    {getLinkContact(contact)}
                  </div>
                  <Dropdown
                    visible={openActionDropdown.visible && index === openActionDropdown.index}
                    onVisibleChange={visible => handleActionsDropdown(visible, visible ? index : -1)}
                    placement="bottomRight"
                    overlayClassName={styles.container_header_dropdown}
                    overlay={
                      <>
                        <p> {t('one_lid.Amallar')}</p>
                        <div onClick={() => handleDeleteContact(contact.id)}>
                          <TrushCrmIcon />
                          <span>{t('one_lid.Kontaktni_ochirish')}</span>
                        </div>
                      </>
                    }
                    trigger={['click']}
                  >
                    <span onClick={e => handleClickMenuButton(e, index)} className={styles.container_header_menu}>
                      <MenuThreeDotHorizontalIcon />
                    </span>
                  </Dropdown>
                </div>
              }
              id={`contact_${contact?.id}`}
            >
              <div className={styles.container_body}>
                {contact.phones.map((phn, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <OneNumber key={i} indexPhone={i} indexContact={index} phone={phn} isMoiZvonki={isMoiZvonki} contractPhoneLength={contact.phones.length} />
                ))}
                {fields.map((field, index) => {
                  if (field.type === CrmFieldsType.TEXT) {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <div className={styles.container_body_item} id={`contact_${contact?.id}_${field?.id}`}>
                        <span>{field.name}</span>
                        <CustomInput
                          onChange={e => handleChangeValue(contact.id, Number(field.id), e.target.value)}
                          value={contact?.fields?.find(item => item.id === field.id)?.value || ''}
                          bordered={false}
                          placeholder="..."
                          className={styles.input}
                          ai={leadFieldValueAi?.includes(`${field?.id}:${field?.name}:${contact?.id}`)}
                          onStopAiTime={() => {
                            dispatch(leadFieldFilterAi(`${field?.id}:${field?.name}:${contact?.id}`));
                          }}
                        />
                      </div>
                    );
                  }
                  if (field.type === CrmFieldsType.LOCATION) {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <div className={styles.container_body_item}>
                        <div className={styles.container_body_item_name}>{field?.name}</div>
                        <LocationAttachment id={field?.id} value={contact?.fields?.find(item => field?.id === item?.id)?.value || ''} contactId={contact?.id}>
                          <GetLocationAddres {...convertLocation(contact?.fields?.find(item => field?.id === item?.id)?.value || '')} />
                        </LocationAttachment>
                      </div>
                    );
                  }
                  return (
                    <div key={field?.id} className={styles.container_body_item} id={`contact_${contact?.id}_${field?.id}`}>
                      <span>{field.name}</span>
                      <CustomSelect
                        value={contact?.fields?.find(item => item.id === field.id)?.value || ''}
                        onChange={e => handleChangeValue(contact.id, Number(field.id), e)}
                        bordered={false}
                        suffixIcon={<ArrowDownIcon />}
                        className={styles.input}
                        ai={leadFieldValueAi?.includes(`${field?.id}:${field?.name}:${contact?.id}`)}
                        onStopAiTime={() => {
                          dispatch(leadFieldFilterAi(`${field?.id}:${field?.name}:${contact?.id}`));
                        }}
                      >
                        {field?.options?.map(item => (
                          <Option key={item?.id} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </CustomSelect>
                    </div>
                  );
                })}
              </div>
            </Panel>
          ))}
        </Collapse>
      </Spin>
      {!isAddContact && (
        <div onClick={handleAddContact} className={styles.container_add}>
          <ContactPlusIcon />
          <div className={styles.container_add_title}>{t('one_lid.Kontakt_qoshish')}</div>
        </div>
      )}
      <SendMessageModal />
    </div>
  );
};

export default Contacts;
