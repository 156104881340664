import React from 'react';

const ArrowDownLeft = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L6 18" stroke="#28C76F" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.75 18H6V8.25" stroke="#28C76F" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowDownLeft;
