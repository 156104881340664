import React, { useRef, useState } from 'react';
import { Dropdown, Input } from 'antd';
import styles from '../triggers.module.scss';
import SubtractIcon from '../../../../../../../assets/icons/SubtractIcon';
import { useCrmEmployee } from '../../../../../../../queries/queries';
import { removeChangeEmployee, setChangeEmployee } from '../../../../../../../store/reducers/TriggerSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { TriggerConditionErrorType } from '../../../../../../../utils/models/Trigger';
import { useTranslation } from 'react-i18next';

const ChangeResponsible = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const activeEmployees = useAppSelector(state => state.triggerSlice.changeEmployees);
  const errorList = useAppSelector(state => state.triggerSlice.errorList);

  const dispatch = useAppDispatch();

  const isFocused = useRef(false);

  const { data } = useCrmEmployee();

  // handle change visible dropdown
  const handleChangeVisible = (visible: boolean) => {
    if (isFocused.current && !visible) {
      return;
    }
    setShowDropdown(visible);
  };

  // handle search
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // handle focus
  const handleFocus = () => {
    handleChangeVisible(true);
    isFocused.current = true;
  };

  const handleBlur = () => {
    isFocused.current = false;
  };

  // and tags
  const addEmployees = (id: number) => {
    dispatch(setChangeEmployee(id));
  };

  // delete tag
  const removeEmployeeFunction = (id: number) => {
    dispatch(removeChangeEmployee(id));
  };

  // filter tags
  const filterEmployees = () => {
    if (search && data) {
      return data?.filter(emp => emp.fullName.toLowerCase().includes(search.toLowerCase()) && !activeEmployees?.find(item => item === emp.id));
    } else if (data) {
      return data.filter(emp => !activeEmployees?.find(item => item === emp.id));
    }
    return [];
  };

  // show error
  const showError = () => {
    let errorValue = false;
    errorList.forEach(error => {
      if (error.type === TriggerConditionErrorType.CHANGE_EMPLOYEE) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  return (
    <div>
      <p className={styles.container_title}>{t('triggers.Masul_xodim')}</p>
      <div className={styles.container_header_tag_container}>
        <Dropdown
          visible={showDropdown}
          onVisibleChange={handleChangeVisible}
          placement={'bottom'}
          overlayClassName={styles.container_header_dropdown}
          overlay={
            <>
              {filterEmployees()?.map((emp, index) => (
                <div onClick={() => addEmployees(Number(emp.id))} key={index} className={styles.container_body_item_dropdown_item}>
                  <span className={styles.container_body_item_dropdown_item_name}>{emp.fullName}</span>
                </div>
              ))}
            </>
          }
          trigger={['click']}
        >
          <div
            onClick={e => {
              handleChangeVisible(true);
              e.stopPropagation();
            }}
            className={
              styles.container_header_teg + ' ' + styles.container_header_teg_emp + (showError() ? ' ' + styles.container_header_teg_error : '')
            }
          >
            <span className={styles.container_header_teg_title_emp}>{t('triggers.Yangi_masul')}:</span>
            {activeEmployees?.map((activeEmployee, index) => {
              const emp = data?.find(item => item.id === activeEmployee);
              if (emp) {
                return (
                  <span key={index} className={styles.container_header_teg_one_teg}>
                    <span className={styles.container_header_teg_one_teg_name}>{emp.fullName}</span>
                    <SubtractIcon
                      onClick={e => {
                        e.stopPropagation();
                        removeEmployeeFunction(Number(emp.id));
                      }}
                      className={styles.container_header_teg_one_teg_delete}
                    />
                  </span>
                );
              }
              return null;
            })}
            <Input onFocus={handleFocus} onBlur={handleBlur} onChange={e => handleSearch(e.target.value)} bordered={false} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default ChangeResponsible;
