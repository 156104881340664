import { Button, Form, Input, Modal, Select } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { FlatId } from '@utils/models/Flat';
import { createFloorSave } from './helper/createFloor';

interface CreateFloorModalProps {
  visible: boolean;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: {};
    }>
  >;
  setSaveAll: (save: boolean) => void;
  data: FlatId | any;
}

const CreateFloorModal: FC<CreateFloorModalProps> = ({ visible, setVisible, setSaveAll, data }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const shaxmatka = useAppSelector(state => state.shaxmatkaReducer.data);
  const { t } = useTranslation();

  const handleCancel = () => {
    setVisible({ visible: false, data: {} });
    form.resetFields();
  };

  const onFinish = (values: { flatCount: string; floorCount: string; floorPosition: boolean }) => {
    const props = {
      values,
      shaxmatka,
      data,
      dispatch,
      handleCancel,
      setSaveAll
    };
    createFloorSave(props);
  };

  return (
    <Modal
      title={t('shaxmatka.qavat_yaratish')}
      wrapClassName="entranceDoorModal"
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      width={407}
    >
      <Form layout="horizontal" onFinish={onFinish} form={form}>
        <Form.Item label={t('shaxmatka.joylashuvi')} name={'floorPosition'} rules={[{ required: true }]}>
          <Select className="customSelect">
            <Select.Option value={true}>{t('shaxmatka.Tepadan')}</Select.Option>
            <Select.Option value={false}>{t('shaxmatka.Pastdan')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('shaxmatka.qavatlar_soni')} name={'floorCount'} rules={[{ required: true }]}>
          <Input type={'number'} />
        </Form.Item>
        <Form.Item label={t('shaxmatka.qavatdagi_xonadonlar_soni')} name={'flatCount'} rules={[{ required: true }]}>
          <Input type={'number'} />
        </Form.Item>
        <div className="entranceDoorModalFooter">
          <Button onClick={handleCancel}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button type="primary" htmlType="submit">
            {t('shaxmatka.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateFloorModal;
