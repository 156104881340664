import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { crmIcons } from '@icons/crmIcons/crmIcons';
import EyesIcon from '@icons/EyesIcon';
import PaymentPagination from '@pages/paymentV2/components/payment-pagination/PaymentPagination';
import { useGetTaskTableView } from '@pages/task/services/queries';
import { TaskTableViewModel } from '@pages/task/utils/models/TaskTableViewModel';
import { setChecked, setTaskBalanceAndIds, setTaskIgnoreIds } from '@reducers/CrmSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { Permissions } from '@utils/constants/permissions';
import { encodeString } from '@utils/helper';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TableRowSelection } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import TaskActions from '../task-actions/TaskActions';

import styles from './tableView.module.scss';

const TableView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [pageSize, setPageSize] = useState({ page: 1, size: 15 });
  const { searchParams, appendSingle } = useQueryParam<SearchParamsTask, null>();

  const { taskListViewActiveIds: activeIds, taskListViewIgnoreIds, checked } = useAppSelector(state => state.assigmentSlice);
  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);

  const newSearchParams = {
    ...searchParams,
    startDate: searchParams?.startDate && dayjs(searchParams?.startDate, DATE_FORMAT).hour(0).minute(0).format(DATE_WITH_TIME_FORMAT),
    finishDate: searchParams?.finishDate && dayjs(searchParams?.finishDate, DATE_FORMAT).hour(23).minute(59).format(DATE_WITH_TIME_FORMAT)
  };

  const { data, isLoading } = useGetTaskTableView(Number(searchParams?.page) || 1, 15, newSearchParams);
  // changePage
  const changePage = (page: number, size: number) => {
    appendSingle('page', page);
  };

  const isCrmLeadGroup = permissions?.includes(Permissions.PERMISSION_LEAD_TASK_ACTIONS);
  const dataIds = data?.data ? data?.data?.map(item => item?.id) : [];

  const onSelectChange = (e: CheckboxChangeEvent) => {
    dispatch(setChecked(e.target.checked));
    if (e.target.checked) {
      dispatch(setTaskIgnoreIds([]));
      dispatch(setTaskBalanceAndIds({ ids: null, balance: 0 }));
    } else {
      dispatch(setTaskBalanceAndIds({ ids: [], balance: 0 }));
    }
  };

  const sizeTable = activeIds?.length === 0 ? '230px' : '285px';

  const goToLead = (id: number, name: string) => {
    navigate(`${rootPaths.MAIN + MainPaths.CRM}/${id}/${encodeString(name)}`);
  };
  const goToClient = (id: number, name: string) => {
    navigate(`${rootPaths.MAIN + MainPaths.CLIENT}/${id}/${encodeString(name)}`);
  };

  const rowSelection: TableRowSelection<TaskTableViewModel> | undefined = {
    onSelect: (record, selected) => {
      if (selected) {
        dispatch(setTaskIgnoreIds(taskListViewIgnoreIds?.filter(item => item !== record?.id)));
        if (!checked) {
          dispatch(
            setTaskBalanceAndIds({
              ids: activeIds ? [...activeIds, record?.id] : [record?.id],
              balance: 0
            })
          );
        }
      } else {
        dispatch(setTaskIgnoreIds(taskListViewIgnoreIds ? [...taskListViewIgnoreIds, record?.id] : [record?.id]));
        if (!checked && activeIds) {
          dispatch(
            setTaskBalanceAndIds({
              ids: activeIds?.filter(item => item !== record?.id),
              balance: 0
            })
          );
        }
      }
    },
    selectedRowKeys: activeIds === null ? dataIds?.filter(item => !taskListViewIgnoreIds?.some(ignore => ignore === item)) : activeIds,
    columnTitle: (
      <Checkbox
        indeterminate={(taskListViewIgnoreIds?.length !== 0 && checked) || (Array.isArray(activeIds) && activeIds?.length > 0)}
        checked={checked}
        onChange={onSelectChange}
      />
    )
  };

  const columns: ColumnsType<TaskTableViewModel> = [
    {
      key: '1',
      title: t('home.Mijoz'),
      render: (render: TaskTableViewModel) => <span>{render?.clientName ? render?.clientName : render?.leadName}</span>,
      width: 320
    },
    {
      key: '2',
      title: t('home.Sana'),
      render: (render: TaskTableViewModel) => <span style={{ color: '#667085' }}>{render?.createdDate}</span>,
      width: 174,
      align: 'center'
    },
    {
      key: '3',
      title: t('triggers.Topshiriq_turi'),
      render: (render: TaskTableViewModel) => (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 5
          }}
        >
          {crmIcons[render?.type.icon.split('#')[0]]?.icon(`#${render?.type.icon.split('#')[1]}`)} <span>{render?.type.name}</span>
        </p>
      ),
      width: 160,
      align: 'center'
    },
    {
      key: '4',
      title: t('home.Izoh'),
      render: (render: TaskTableViewModel) => <>{render?.text}</>,
      width: 450
    },
    {
      key: '5',
      title: t('home.Holati'),
      render: (render: TaskTableViewModel) => (
        <div
          className={styles.taskType}
          style={{
            backgroundColor: render?.state === 'ACTIVE' ? '#EFF8FF' : '#ECFDF3'
          }}
        >
          <span
            style={{
              color: render?.state === 'ACTIVE' ? '#175CD3' : '#027A48',
              fontSize: '14px',
              fontWeight: 500
            }}
          >
            {render?.state === 'ACTIVE' ? t('home.ACTIVE') : t('home.FINISHED')}
          </span>
        </div>
      ),
      width: 200,
      align: 'center'
    },

    {
      key: '6',
      title: t('contract_id.Masul_xodim'),
      render: (render: TaskTableViewModel) => <>{render?.assignedBy}</>,
      width: 230,
      align: 'center'
    },
    {
      key: '7',
      title: '',
      render: render => (
        <>
          {activeIds?.length! > 0 || activeIds === null ? (
            <div
              className={styles.viewTask}
              onClick={e => {
                e.stopPropagation();
                if (render?.leadName) {
                  return goToLead(Number(render?.leadId), String(render?.leadName));
                }
                return goToClient(Number(render?.clientId), String(render?.clientName));
              }}
            >
              <EyesIcon />
              <span>{t('client.Korish')}</span>
            </div>
          ) : (
            ''
          )}
        </>
      ),

      width: 128
    }
  ];

  return (
    <>
      <TaskActions totalElements={data?.totalElements} />
      <div className={styles.container}>
        <Table
          dataSource={data?.data}
          columns={columns}
          pagination={false}
          loading={isLoading}
          rowKey="id"
          {...(isCrmLeadGroup && {
            rowSelection
          })}
          className="custom_table_size_crm"
          style={{
            // @ts-ignore
            '--size': sizeTable
          }}
          onRow={(record, selected) => ({
            onClick: () => {
              if (activeIds?.length! >= 1) {
                if (activeIds?.includes(record?.id)) {
                  dispatch(
                    setTaskBalanceAndIds({
                      balance: 0,
                      ids: activeIds?.filter(item => item !== record?.id)
                    })
                  );
                } else {
                  dispatch(
                    setTaskBalanceAndIds({
                      balance: 0,
                      ids: activeIds ? [...activeIds, record?.id] : [record?.id]
                    })
                  );
                }
              } else {
                if (record?.leadName) {
                  return goToLead(Number(record?.leadId), String(record?.leadName));
                }
                return goToClient(Number(record?.clientId), String(record?.clientName));
              }

              // if (selected) {
              //   dispatch(setTaskIgnoreIds(taskListViewIgnoreIds?.filter(item => item !== record?.id)));
              //   if (!checked) {
              //     dispatch(
              //       setTaskBalanceAndIds({
              //         ids: activeIds ? [...activeIds, record?.id] : [record?.id],
              //         balance: 0
              //       })
              //     );
              //   }
              // } else {
              //   dispatch(setTaskIgnoreIds(taskListViewIgnoreIds ? [...taskListViewIgnoreIds, record?.id] : [record?.id]));
              //   if (!checked && activeIds) {
              //     dispatch(
              //       setTaskBalanceAndIds({
              //         ids: activeIds?.filter(item => item !== record?.id),
              //         balance: 0
              //       })
              //     );
              //   }
              // }
              return '';
            }
          })}
        />
        <PaymentPagination
          current={Number(searchParams.page) || 1}
          total={data?.totalElements}
          pageSize={15}
          onChange={(page, size) => {
            changePage(page, size);
          }}
          hideOnSinglePage
        />
      </div>
    </>
  );
};

export default TableView;
