import React from 'react';

const LogoutIconMessengerIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.80859 4.41063C8.62776 2.31062 7.54859 1.45312 5.18609 1.45312H5.11026C2.50276 1.45312 1.45859 2.49729 1.45859 5.10479V8.90813C1.45859 11.5156 2.50276 12.5598 5.11026 12.5598H5.18609C7.53109 12.5598 8.61026 11.714 8.80276 9.64896"
        stroke="#FF3F40"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.25034 7H11.8887" stroke="#FF3F40" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5878 5.04492L12.542 6.99909L10.5878 8.95326" stroke="#FF3F40" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LogoutIconMessengerIcon;
