import React from 'react';

interface IProps {
  size?: number;
}

const ArrowIosDownIcon = ({ size = 16 }: IProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99961 10.6666C7.84761 10.6666 7.69628 10.6152 7.57295 10.5119L3.57295 7.17857C3.29028 6.94324 3.25161 6.52257 3.48761 6.23991C3.72295 5.95724 4.14295 5.91924 4.42628 6.15457L8.00695 9.13857L11.5816 6.26191C11.8683 6.03124 12.2883 6.07657 12.5189 6.36324C12.7496 6.64991 12.7043 7.06924 12.4176 7.30057L8.41761 10.5192C8.29561 10.6172 8.14761 10.6666 7.99961 10.6666Z"
        fill="#757D8A"
      />
    </svg>
  );
};

export default ArrowIosDownIcon;
