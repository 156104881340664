import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useScrollView } from '@hooks/useScrollView';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import { CrmUserRoleModel } from '@pages/crm/utils/models/CrmUserRoleModel';
import { Spin } from 'antd';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { setScrolledIdsAndPositions } from '../../../../store/reducers/CrmSlice';
import { PipeStatuses } from '../../../../utils/models/PipeAll';
import { useLeadFilter } from '../../services/queries';
import { SearchParamsCrm } from '../filter/FilterButton';

import CreateLead from './createLead/CreateLead';
import VoronkaCardItem from './VoronkaCardItem';

import styles from './voronkaCards.module.scss';

interface IProps {
  column: PipeStatuses;
  index: number;
  loading: boolean;
  columnSecondId?: number;
  currentUserRoleData?: CrmUserRoleModel | undefined;
}

const VoronkaCardItemList: FC<IProps> = ({
  column,
  index,
  loading,
  // setQueryData,
  // setArrId,
  columnSecondId,
  currentUserRoleData
}) => {
  const qc = useQueryClient();
  const ref = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [enabled, setEnabled] = useState(false);
  const [oneEnabled, setOneEnabled] = useState(true);
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allColumnDisable = useAppSelector(state => state.assigmentSlice.allColumnDisable);

  const location = useLocation();
  const { data: scrollData, fetchNextPage, refetch, isLoading, isFetching, hasNextPage } = useLeadFilter(column.id!, searchParams, enabled);
  const { ref: scrollRefElement } = useScrollView({ handleScroll: fetchNextPage, deps: [isFetching], isChange: !isFetching && hasNextPage });

  // scroll to exact place

  // Function to handle scroll event for the element

  // Add scroll event listener for the element when component mounts

  // Restore scroll position when component mounts

  // Store scroll position in session storage when navigating away

  useEffect(() => {
    refetch({ refetchPage: (page, index) => index === 0 });
  }, [location.search]);

  useEffect(() => {
    const scrollDataState = qc.getQueryState([queryKeys.CRM_LEAD_FILTER, column.id!, searchParams])?.data as any;

    if ((enabled && !scrollDataState?.pages[0]) || (enabled && oneEnabled)) {
      qc.fetchInfiniteQuery({
        queryKey: [queryKeys.CRM_LEAD_FILTER, column.id!, searchParams]
      });
    }
  }, [enabled, oneEnabled]);

  // useEffect(() => {
  //   // setQueryData(scrollData)
  //   // setArrId((prev) =>
  //   //   prev?.map((item) => ({
  //   //     id: item.id,
  //   //     balance:
  //   //       column.id === item.id
  //   //         ? scrollData?.pages[0]?.totalBalance!
  //   //         : item?.balance!,
  //   //     count:
  //   //       column.id === item.id
  //   //         ? scrollData?.pages[0]?.countLead!
  //   //         : item?.count!,
  //   //   }))
  //   // )
  //   //eslint-disable-next-line
  // }, [scrollData, isLoading, location, isFetching])

  useEffect(() => {
    const interSectionObserver = new IntersectionObserver(
      ([entry]) => {
        setEnabled(entry.isIntersecting);
        if (entry.isIntersecting) {
          setOneEnabled(false);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: []
      }
    );

    if (ref.current) {
      interSectionObserver.observe(ref.current);
    }

    return () => {
      interSectionObserver.disconnect();
      if (ref.current) {
        interSectionObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  const delayedExec = (callback: () => void, time: any) => {
    let timer: any;

    // eslint-disable-next-line func-names
    return function () {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      timer && clearTimeout(timer);
      timer = setTimeout(callback, time);
    };
  };

  const scrollStopper = delayedExec(() => {
    dispatch(
      setScrolledIdsAndPositions({
        position: scrollRef.current?.scrollTop,
        id: column?.id
      })
    );
  }, 500);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', scrollStopper);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', scrollStopper);
      }
    };
  }, [scrollRef.current]);

  return (
    <>
      <div className={styles.voronkaCards_column_header} style={{ borderColor: column.color }} ref={ref}>
        <h1 style={{ color: column.color }}>{column.name}</h1>
        <p>
          {scrollData?.pages[0]?.countLead} {t('crm.ta_bitim')}
          {scrollData?.pages[0]?.totalBalance ? `: ${scrollData?.pages[0]?.totalBalance?.toLocaleString('ru')}` : ''}
        </p>
      </div>
      {index === 1 && <CreateLead pipeStatusId={column.id!} balance={scrollData?.pages[0]?.totalBalance!} name={column.name} />}

      <Droppable droppableId={String(column.id)} isDropDisabled={column.type === 'FIRST' || allColumnDisable}>
        {(previded, snapshot) => (
          <>
            <div
              className={styles.voronkaCards_column_container}
              id={String(column.id)}
              {...previded.droppableProps}
              ref={ref => {
                previded.innerRef(ref);
                scrollRef.current = ref;
              }}
              style={{
                background: snapshot.isDraggingOver ? 'lightblue' : 'transparent'
              }}
            >
              <Spin spinning={isLoading} style={{ height: '50vh' }}>
                {scrollData?.pages?.map(item1 =>
                  item1?.leadList?.data?.map((item, itemIndex) => (
                    <Draggable key={item?.id} draggableId={String(item?.id)} index={itemIndex} disableInteractiveElementBlocking={loading}>
                      {(provided, snapshot) => (
                        <VoronkaCardItem
                          provided={provided}
                          isDragging={snapshot.isDragging}
                          className={styles.voronkaCards_column_container_card}
                          incomingClassName={styles.voronkaCards_column_container_card_incoming}
                          item={item}
                          index={index}
                          columnId={column?.id}
                          columnSecondId={columnSecondId}
                          column={column}
                          currentUserRoleData={currentUserRoleData}
                        />
                      )}
                    </Draggable>
                  ))
                )}
                {previded.placeholder}
              </Spin>
              <div ref={scrollRefElement} className="p-2" />
            </div>
          </>
        )}
      </Droppable>
    </>
  );
};

export default VoronkaCardItemList;
