import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const AddIcCallIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="22.5" r="22.5" fill="#DFDEDE" />
      <g clipPath="url(#clip0_9944_45926)">
        <path
          d="M29.4283 25.2732C28.4238 25.2732 27.4595 25.1124 26.5595 24.8151C26.4792 24.791 26.3908 24.7749 26.3104 24.7749C26.1015 24.7749 25.9006 24.8553 25.7399 25.008L23.972 26.7758C21.6979 25.6187 19.8336 23.7624 18.6765 21.4803L20.4444 19.7044C20.6694 19.4955 20.7336 19.1821 20.6453 18.9008C20.3479 18.0008 20.1872 17.0366 20.1872 16.0321C20.1872 15.5901 19.8256 15.2285 19.3836 15.2285H16.5711C16.1292 15.2285 15.7676 15.5901 15.7676 16.0321C15.7676 23.5776 21.8828 29.6928 29.4283 29.6928C29.8703 29.6928 30.2319 29.3312 30.2319 28.8892V26.0767C30.2319 25.6348 29.8703 25.2732 29.4283 25.2732ZM17.3988 16.8357H18.6042C18.6604 17.5428 18.781 18.2419 18.9658 18.9089L18.0015 19.8812C17.6801 18.9089 17.4711 17.8964 17.3988 16.8357ZM28.6247 28.0616C27.564 27.9892 26.5354 27.7803 25.5711 27.4508L26.5354 26.4866C27.2185 26.6794 27.9176 26.7999 28.6247 26.8482V28.0616Z"
          fill="#323232"
        />
        <path d="M30.6336 18.683L27.4194 15.4688V17.8795H24.2051V19.4866H27.4194V21.8973L30.6336 18.683Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_9944_45926">
          <rect width="19.2857" height="19.2857" fill="white" transform="translate(13.3569 12.8574)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddIcCallIcon;
