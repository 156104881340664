import React, { FC, useState } from 'react';
import InfoIcon from '@icons/InfoIcon';
import { Button, Checkbox, Dropdown, Form, Input, Modal, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import AddCircleIcon from '../../../../../assets/icons/AddCircleIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useClientId, useSearchClientByName } from '../../../../../queries/queries';
import { MainPaths } from '../../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../../routing/rootPaths';
import { ClientType } from '../../../../../utils/models/ClientType';
import { useContractTransfer } from '../../../services/mutation';

interface ContractCustomerChangeProps {
  visible: boolean;
  setVisible: (i: boolean) => void;
  id: number;
  setId: (i: number) => void;
  contractId: number;
  setContractId: (i: number) => void;
}

const ContractCustomerChange: FC<ContractCustomerChangeProps> = ({ visible, setVisible, contractId, id, setId }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { push } = useQueryParam();
  const [autoComplate, setAutoComplate] = useState(false);
  const [contractPhone, setContractPhone] = useState('');
  const [scrolling, setScrolling] = useState(true);
  const [radio, setRadio] = useState(false);

  const clientChangeMutation = useContractTransfer();
  const { data: clientBookingData, isLoading: clientBookingloading, fetchNextPage } = useSearchClientByName(contractPhone);
  const { data: clientId } = useClientId(id);

  const handleCancel = () => {
    form.resetFields();
    setContractPhone('');
    setVisible(false);
    setRadio(false);
  };

  const changeContractInput = (value: any) => {
    setAutoComplate(true);
    setContractPhone(value.target.value);
  };

  const onFinish = (value: any) => {
    clientChangeMutation.mutate({
      contractId,
      clientId: id,
      cancelText: !radio ? value.cancelText : null,
      onlyClient: radio
    });
    handleCancel();
  };

  const getClientName = () => {
    if (clientId?.type === ClientType.NATURAL) {
      return `${clientId?.lastname} ${clientId?.firstname} ${clientId?.fatherName}`;
    }
    return clientId?.name;
  };

  // goToCreateClient
  const goToCreateClient = () => {
    handleCancel();
    push(rootPaths.MAIN + MainPaths.CLIENT + MainPaths.CREATE_NEW_CLIENT);
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <Modal title={false} visible={visible} footer={null} closable={false} width={507} centered onCancel={handleCancel} className="contractCustomerChange">
      <h1>{t('contract.Mijozni_ozgartirish')}</h1>
      <Form form={form} name="change_client_form" onFinish={onFinish}>
        <h3>{t('contract.Shartnomani_boshqa_mijoz_nomiga_otkazib_berish')}</h3>
        <Form.Item name="search" noStyle>
          <Dropdown
            visible={autoComplate}
            onVisibleChange={setAutoComplate}
            overlay={
              <div className="clientChangeDropdown" onScroll={e => onScroll(e)}>
                <h1 onClick={goToCreateClient}>
                  <AddCircleIcon /> {t('contract.Yangi_mijoz_yaratish')}
                </h1>
                {clientBookingloading ? (
                  <Spin />
                ) : (
                  clientBookingData?.pages?.map(data =>
                    data?.data?.map(i => (
                      <div
                        key={i?.id}
                        onClick={() => {
                          setId(i.id);
                          setContractPhone(i.phone);
                          setAutoComplate(false);
                        }}
                      >
                        <h1>{i.phone}</h1>
                        <p>{i.name?.length > 40 ? `${i?.name?.substring(0, 40)}...` : i?.name}</p>
                      </div>
                    ))
                  )
                )}
              </div>
            }
            trigger={['click']}
          >
            <Input placeholder={t('titles.search-client')} style={{ width: 452, borderRadius: 6, marginBottom: 20 }} value={contractPhone} onChange={changeContractInput} />
          </Dropdown>
        </Form.Item>

        <div className="contractCustomerChangeContent">
          <h1>{t('client.Mijoz')}</h1>
          <p>{getClientName()}</p>
        </div>
        <div className="contractCustomerChangeContent">
          <h1>{t('client.Telefon_raqami')}</h1>
          {clientId?.phones.map(i => <p key={i.id}>{i.phone}</p>)}
        </div>
        {clientId?.type === ClientType.NATURAL && (
          <div className="contractCustomerChangeContent">
            <h1>{t('contract.Pasport_seriyasi_raqami')}</h1>
            <p>
              {clientId?.passportSerial} {clientId?.passportNumber}
            </p>
          </div>
        )}

        <div className="mt-4 flex flex-col">
          <div className="flex items-center">
            <Checkbox className="contractRadio" checked={!radio} value={false} onChange={e => setRadio(false)}>
              <span className="mb-1 select-none font-medium text-[#344054]">{t('contract.yangi_shartnoma')}</span>
            </Checkbox>
            <Tooltip overlay={t('contract.yangi_shartnoma_tooltip')}>
              <InfoIcon />
            </Tooltip>
          </div>
          <Checkbox className="!m-0" checked={radio} value={true} onChange={e => setRadio(true)}>
            <span className="select-none font-medium text-[#344054]">{t('contract.mavjud_sharnoma')}</span>
          </Checkbox>
        </div>
        <br />
        <div style={{ opacity: !radio ? 1 : 0 }} className="contractCustomerChangeContent">
          <h1>{t('contract.Izoh')}</h1>
          <Form.Item name="cancelText" rules={[{ required: !radio, message: "Ma'lumot qoldiring" }]}>
            <Input.TextArea placeholder={t('contract_id.Izoh_kiriting')} autoSize={{ minRows: 4, maxRows: 4 }} style={{ borderRadius: 6, marginBottom: 11 }} />
          </Form.Item>
        </div>

        <div className="contractCustomerChangeFooter">
          <Button onClick={handleCancel}>{t('arrearage.Yopish')}</Button>
          <Button type="primary" htmlType="submit">
            {t('arrearage.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ContractCustomerChange;
