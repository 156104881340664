import React from 'react';
import { FormProvider, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import Item from './Item';

type IProps = {
  children: ((props: UseFormReturn) => React.ReactNode) | React.ReactNode;
  onFinish?: (value: any) => void;
  form?: UseFormReturn;
  className?: string;
} & ({ form?: UseFormReturn } | ({ form?: undefined } & UseFormProps));

const Form = ({ children, form, onFinish, className, ...parametrs }: IProps) => {
  const getForm = form || useForm(parametrs);
  const { handleSubmit } = getForm as UseFormReturn;

  const onSubmit = async (data: any) => {
    if (onFinish) {
      onFinish(data);
    }
  };

  const getChildren = (childrens: React.ReactNode): React.ReactNode => {
    if (Array.isArray(childrens)) {
      return React.Children.map(childrens, getChildren);
    }
    if (React.isValidElement(childrens)) {
      // console.log(childrens);
      return {
        ...childrens,
        props: {
          ...childrens?.props,
          ...((childrens?.type as any)?.name === 'Item' && { formProps: getForm }),
          ...(childrens?.props?.children && { children: getChildren(childrens?.props?.children) })
        }
      };
    }
    return childrens;
  };

  const renderChildren = (childrens: React.ReactNode) => getChildren(childrens);

  return (
    <FormProvider {...getForm}>
      <form onSubmit={handleSubmit(onSubmit)} className={className}>
        {renderChildren(typeof children === 'function' ? children(getForm) : children)}
      </form>
    </FormProvider>
  );
};

Form.useForm = useForm;
Form.Item = Item;

export default Form;
