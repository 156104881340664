import { Route, Routes } from 'react-router-dom';
import AccessDenied from '@components/accessDenied/AccessDenied';

import bossCrmRoutes from './bossCrmRoutes';

const BossCrmRouter = () => (
  <Routes>
    {bossCrmRoutes?.map((route, index) => <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />)}
    <Route path="*" element={<AccessDenied />} />
  </Routes>
);

export default BossCrmRouter;
