import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../../../assets/icons/ArrowLineDownIcon';
import { useDownloadContractToWord } from '../../../../queries/mutation';
import { useCompanyTemplatesCompact } from '../../../../queries/queries';
import { FileTemplateLanguage, FileTemplateType } from '../../../../utils/models/FileTemplate';

import WordIcon from '../../../../assets/img/wordIcon.png';

import styles from './contractIdHeader.module.scss';

interface Props {
  contractId: number | undefined;
  contractNumber: string | undefined;
  onSuccess: () => void;
  customData?: {
    name: string;
    id: number;
    docTempCompactDTO: {
      language: FileTemplateLanguage;
    }[];
  };
}

const DownloadContract: React.FC<Props> = ({ contractNumber, contractId, onSuccess, customData }) => {
  const { data } = useCompanyTemplatesCompact(FileTemplateType.CONTRACT);
  const [activeLang, setActiveLang] = useState<number>();

  const downloadContractToWord = useDownloadContractToWord(contractId || null, contractNumber || null, onSuccess, customData?.id || null);

  const { t } = useTranslation();

  const downloadContract = (lang: FileTemplateLanguage, index: number) => {
    setActiveLang(index);
    downloadContractToWord.mutate(lang);
  };

  const newData = customData ? customData?.docTempCompactDTO : data;

  return (
    <>
      {newData?.map((template, index) => (
        <span key={index} onClick={() => downloadContract(template.language, index)} style={{ cursor: 'pointer' }} className={styles.dropdown_item}>
          <span>
            <img src={WordIcon} alt="WordIcon" /> {t(`contract_id.Word_${template.language}`)}
          </span>
          <span className={styles.dropdown_arrowDownload}>{downloadContractToWord.isLoading && activeLang === index ? <LoadingOutlined /> : <ArrowLineDownIcon />}</span>
        </span>
      ))}
    </>
  );
};

export default DownloadContract;
