/* eslint-disable react/jsx-key */
import React from 'react';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { useAppSelector } from '@hooks/reduxHooks';
import GreyInfoIcon from '@icons/GreyInfoIcon';
import { useCreateNewLead } from '@pages/createOrder/services/mutation';
import { useGetClientLead, useGetRequiredFields } from '@pages/createOrder/services/queries';
import { CreateNewLeadType } from '@pages/createOrder/utils/model/CreateNewLeadType';
import { phoneValidator } from '@utils/index';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Button, Form, Modal, Space } from 'antd';
import { Input, Select, Tooltip } from 'global';
import { t } from 'i18next';

import 'react-international-phone/style.css';
import styles from './createLead.module.scss';


type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectLead: React.Dispatch<React.SetStateAction<ClientLeadModel | undefined>>;
  setLeadId: React.Dispatch<React.SetStateAction<ClientLeadModel | number | undefined>>;
};



const CreateLeadModal: React.FC<Props> = ({ setVisible, visible, setSelectLead, setLeadId }) => {
  const [form] = Form.useForm();
  const createNewLead = useCreateNewLead();

  const { clientPhones } = useAppSelector(state => state.clientReducer);
  const { data: dataRequiredFields } = useGetRequiredFields();
  const { data: dataClientLead } = useGetClientLead();

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = () => {
    const contacts = dataRequiredFields?.flatMap((item: CreateNewLeadType) => {
      const value = form.getFieldValue(Number(item.id));

      if (item.infoType === 'CONTACT') {
        if (item.type === 'MULTI_SELECT' && Array.isArray(value)) {
          return value?.map(val => ({
            value: val,
            id: item?.id
          }));
        }

        return [{
          value,
          id: item?.id
        }];
      }

      return [];
    });

    const fields = dataRequiredFields?.flatMap((item: CreateNewLeadType) => {
      const value = form.getFieldValue(Number(item?.id));

      if (item.infoType === 'CONTACT') {
        return [];
      }

      if (item.type === 'MULTI_SELECT' && Array.isArray(value)) {
        return value.map(val => ({
          value: val,
          id: item?.id
        }));
      }

      if (item.infoType === 'LED') {
        return [{
          value,
          id: item?.id
        }];
      }

      return [];
    });

    const phoneValue = form.getFieldValue('phone');
    const nameValue = form.getFieldValue('name');

    createNewLead.mutateAsync({
      pipeStatusId: dataClientLead?.pipeStatus?.id,
      // @ts-expect-error
      contacts: [
        {
          name: nameValue,
          phones: [phoneValue],
          fields: [
            ...contacts
          ]
        }
      ],
      fields
    })
      .then(res => {
        handleClose();
        setSelectLead(res?.data);
        setLeadId(res?.data?.id)
      });
  };

  const nonContactItems = dataRequiredFields?.filter((item: CreateNewLeadType) => item.infoType !== 'CONTACT');
  const contactItems = dataRequiredFields?.filter((item: CreateNewLeadType) => item.infoType === 'CONTACT');


  const renderFormItem = (item: CreateNewLeadType) => {
    const commonProps = {
      name: Number(item?.id),
      label: item?.name,
      key: item?.id,
      className: 'w-[470px]',
      rules: [{ required: true, message: '' }],
    };

    switch (item?.type) {
      case 'TEXT':
      case 'LOCATION':
        return (
          <Form.Item {...commonProps}>
            <Input />
          </Form.Item>
        );

      case 'MULTI_SELECT':
        return (
          <Form.Item {...commonProps}>
            <Select mode="multiple">
              {item?.options?.map((opt: any) => (
                <Select.Option key={opt?.name}>
                  {opt?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'SELECT':
        return (
          <Form.Item {...commonProps}>
            <Select>
              {item?.options?.map((opt: any) => (
                <Select.Option key={opt?.name}>
                  {opt?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );

      case 'COUNTER':
        return (
          <Form.Item {...commonProps}>
            <Input type='number' />
          </Form.Item>
        );

      default:
        return null;
    }
  }


  return (
    <Modal title={t('home.Yangi_lid_yaratish')} visible={visible} onCancel={handleClose} footer={false} className={styles.createLead} >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="w-[490px] max-h-[500px] overflow-y-auto">
          <>
            <p className='flex items-center mb-[8px]'>
              <span className='text-red-500 text-[18px] mb-[-5px] opacity-7 mr-[2px]'>*</span>
              {t(`changes.client.NaturalClient.Telefon_raqam`)}
            </p>

            {clientPhones.map((phone, index) => (
              <Space direction="horizontal" className="d-flex" key={phone?.id}>
                <div>
                  <Form.Item name="code" className="d-none" />
                  <div className="flex flex-col gap-[5px]">
                    <Form.Item name="phone" rules={[phoneValidator()]}>
                      <InternationalPhoneNumber
                        defaultCountry="uz"
                        className="w-[470px]"
                        onChange={(e, phone) => {
                          form.setFieldsValue({ [`code`]: `${phone?.country?.iso2?.toUpperCase()}` });
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Space>
            ))}
          </>

          <Form.Item name='name' label={t('client.first name')} className='w-[470px]' rules={[{ required: true, message: '' }]}>
            <Input />
          </Form.Item>

          <>
            {nonContactItems?.map(renderFormItem)}

            <div className="contact-items-container">
              {contactItems?.length > 0 && (
                <Tooltip title={t('client_create_contract.Mijoz_malumotlari')} overlayInnerStyle={{ borderRadius: '10px' }} placement="topLeft">
                  <div className="flex items-center mt-[15px] mb-[10px]">
                    <GreyInfoIcon />
                    <hr className="ml-[8px] w-[430px] border-dashed border-[#adaaaa]" />
                  </div>
                </Tooltip>
              )}

              {contactItems?.map(renderFormItem)}
            </div>
          </>


        </div>

        <div className={styles.footer}>
          <Button htmlType="submit" loading={createNewLead.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateLeadModal;
