import { z } from 'zod';

export const variation = z
  .object({
    mortgagePercent: z.coerce.number().optional(),
    prepaymentPercent: z.coerce.number(),
    monthlyPaymentPercent: z.coerce.number(),
    changePricePercent: z.coerce.number(),
    contractMonth: z.coerce.number(),
    id: z.number().nullable().optional(),
    groupId: z.number().nullable().optional(),
    mortgagePaymentDate: z.string().nullable().optional()
  })
  .refine(arg => (arg?.mortgagePercent || 0) + (arg.prepaymentPercent || 0) + arg.monthlyPaymentPercent === 100, {
    message: 'Xatolik mavjud',
    path: ['prepaymentPercent']
  })
  .refine(arg => (arg?.mortgagePercent || 0) + (arg.prepaymentPercent || 0) + arg.monthlyPaymentPercent === 100, {
    message: 'Xatolik mavjud',
    path: ['monthlyPaymentPercent']
  })
  .refine(arg => (arg?.mortgagePercent || 0) + (arg.prepaymentPercent || 0) + arg.monthlyPaymentPercent === 100, {
    message: 'Xatolik mavjud',
    path: ['mortgagePercent']
  });

export interface IVariation extends z.infer<typeof variation> {}
