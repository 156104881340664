import React from 'react';

const UserSquareIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1161 18.0166C14.3828 18.2333 13.5161 18.3333 12.4995 18.3333H7.49948C6.48281 18.3333 5.61615 18.2333 4.88281 18.0166C5.06615 15.85 7.29115 14.1416 9.99948 14.1416C12.7078 14.1416 14.9328 15.85 15.1161 18.0166Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5013 1.66699H7.5013C3.33464 1.66699 1.66797 3.33366 1.66797 7.50033V12.5003C1.66797 15.6503 2.61797 17.3753 4.88464 18.017C5.06797 15.8503 7.29297 14.142 10.0013 14.142C12.7096 14.142 14.9346 15.8503 15.118 18.017C17.3846 17.3753 18.3346 15.6503 18.3346 12.5003V7.50033C18.3346 3.33366 16.668 1.66699 12.5013 1.66699ZM10.0013 11.8086C8.3513 11.8086 7.01797 10.467 7.01797 8.81701C7.01797 7.16701 8.3513 5.83366 10.0013 5.83366C11.6513 5.83366 12.9846 7.16701 12.9846 8.81701C12.9846 10.467 11.6513 11.8086 10.0013 11.8086Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9823 8.81636C12.9823 10.4664 11.649 11.808 9.99896 11.808C8.34896 11.808 7.01562 10.4664 7.01562 8.81636C7.01562 7.16636 8.34896 5.83301 9.99896 5.83301C11.649 5.83301 12.9823 7.16636 12.9823 8.81636Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserSquareIcon;
