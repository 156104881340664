import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const MicrophoneIcon: FC<IProps> = ({ color = '#000000', onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 16.25C9.38 16.25 7.25 14.12 7.25 11.5V6C7.25 3.38 9.38 1.25 12 1.25C14.62 1.25 16.75 3.38 16.75 6V11.5C16.75 14.12 14.62 16.25 12 16.25ZM12 2.75C10.21 2.75 8.75 4.21 8.75 6V11.5C8.75 13.29 10.21 14.75 12 14.75C13.79 14.75 15.25 13.29 15.25 11.5V6C15.25 4.21 13.79 2.75 12 2.75Z"
        fill={color}
      />
      <path
        d="M12 19.75C7.36998 19.75 3.59998 15.98 3.59998 11.35V9.64999C3.59998 9.23999 3.93998 8.89999 4.34998 8.89999C4.75998 8.89999 5.09998 9.23999 5.09998 9.64999V11.35C5.09998 15.15 8.19998 18.25 12 18.25C15.8 18.25 18.9 15.15 18.9 11.35V9.64999C18.9 9.23999 19.24 8.89999 19.65 8.89999C20.06 8.89999 20.4 9.23999 20.4 9.64999V11.35C20.4 15.98 16.63 19.75 12 19.75Z"
        fill={color}
      />
      <path
        d="M13.39 7.18001C13.31 7.18001 13.22 7.17001 13.13 7.14001C12.4 6.87001 11.6 6.87001 10.87 7.14001C10.48 7.28001 10.05 7.08001 9.91 6.69001C9.77 6.30001 9.97 5.87001 10.36 5.73001C11.42 5.35001 12.59 5.35001 13.65 5.73001C14.04 5.87001 14.24 6.30001 14.1 6.69001C13.98 6.99001 13.69 7.18001 13.39 7.18001Z"
        fill={color}
      />
      <path
        d="M12.8 9.30001C12.73 9.30001 12.67 9.29001 12.6 9.27001C12.2 9.16001 11.79 9.16001 11.39 9.27001C10.99 9.38001 10.58 9.14001 10.47 8.74001C10.36 8.35001 10.6 7.94001 11 7.83001C11.65 7.65001 12.35 7.65001 13 7.83001C13.4 7.94001 13.64 8.35001 13.53 8.75001C13.44 9.08001 13.13 9.30001 12.8 9.30001Z"
        fill={color}
      />
      <path
        d="M12 22.75C11.59 22.75 11.25 22.41 11.25 22V19C11.25 18.59 11.59 18.25 12 18.25C12.41 18.25 12.75 18.59 12.75 19V22C12.75 22.41 12.41 22.75 12 22.75Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default MicrophoneIcon;
