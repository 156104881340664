import React from 'react';
import { Col, Row } from 'antd';

import { KpiItem } from '../../utils/models/Kpi';

import Percent from './persent/Percent';
import KpiType from './KpiType';

import styles from '../../pages/kpi/kpi.module.scss';

interface Props {
  kpi: KpiItem[] | undefined;
}

const CalculateWithPercent: React.FC<Props> = ({ kpi }) => (
  <>
    {kpi?.map(kpi => (
      <Row gutter={[48, 0]} key={kpi?.id}>
        <Col className={styles.cont_body_left} span={8}>
          <KpiType
            title="Mukofot_hisoblash_formulasi"
            subtitle="Xodim_va_vositachilarni_ish_samaradorligini_malum_formula_asosida_shartnoma_qiymati_yoki_boshlangich_tolov_summasiga_bogliq_holatda_ozgaruvchan_boladi"
            isActive={kpi?.status === 'ACTIVE'}
            id={kpi?.id || -1}
          />
        </Col>
        <Col span={16}>
          <Percent kpi={kpi} />
        </Col>
      </Row>
    ))}
  </>
);

export default CalculateWithPercent;
