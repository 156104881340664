import React, { useEffect } from 'react';
import styles from './webRtc.module.scss';
import defaultPersonAvatar from '../../assets/img/avatarDefaultClient.jpg';
import CallCustomIcon from '../../assets/icons/CallCustomIcon';
import { useTimer } from '../../hooks/useTimer';
import ArrowDownIcon from '../../assets/icons/ArrowDownIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setPhoneDelete, setPhoneEdit, setPhones } from '../../store/reducers/WebRTCSlice';
import { useFindClientOnlinePbxDrawer } from '../../queries/mutation';
import PauseIcon from '../../assets/icons/onlinePbx/PauseIcon';
import DialPadIcon from '../../assets/icons/onlinePbx/DialPadIcon';
import AddIcCallIcon from '../../assets/icons/onlinePbx/AddIcCallIcon';
import MicOffIcon from '../../assets/icons/onlinePbx/MicOffIcon';

let soundPlayer = new Audio('/sound/ring.mp3');
let endCallSound = new Audio('/sound/endCall.mp3');

// const formatTime = (timer: number) => {
//   const getSeconds = `0${timer % 60}`.slice(-2)
//   const minutes = Math.floor(timer / 60)
//   const getMinutes = `0${minutes % 60}`.slice(-2)
//   // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

//   return `${getMinutes} : ${getSeconds}`
// }

const WebRtc = () => {
  const findLead = useFindClientOnlinePbxDrawer();
  const dispatch = useAppDispatch();
  const { webrtc, answerCalling, callerNumber, disable, vertoRef, phones } = useAppSelector(state => state.webRTCReducer);
  const { handleStart, handleReset } = useTimer(0);

  const webRtcVideo = document?.getElementById('webRtcVideo');

  useEffect(() => {
    const VertoFC = async () => {
      try {
        await vertoRef?.login();
      } catch (error) {
        console.log('Access denied');
        return;
      }
    };

    if (disable && webrtc?.domain && webrtc?.password) {
      VertoFC();
    }
    // eslint-disable-next-line
  }, [webrtc, disable, vertoRef]);

  useEffect(() => {
    if (disable) {
      vertoRef?.subscribeEvent('invite', call => {
        dispatch(
          setPhones({
            [call?.options?.caller_id_number]: {
              answerCalling: true,
              calling: true,
              callerName: call?.options?.caller_id_name,
              callerNumber: call?.options?.caller_id_number,
              call,
              hold: false,
              employeeName: call?.options?.caller_id_name,
              employeePhoneNumber: call?.options?.caller_id_number
            }
          })
        );
        soundPlayer.loop = true;
        soundPlayer.play();
        // call.subscribeEvent('answer', (track: any) => {
        // })
        call.subscribeEvent('track', (track: any) => {
          if (track.kind !== 'audio') return;
          let stream = new MediaStream();
          stream.addTrack(track);
          let el: any = webRtcVideo;
          el.srcObject = stream;

          soundPlayer.pause();
          handleStart();
        });

        call.subscribeEvent('bye', (cause: any) => {
          soundPlayer.pause();
          endCallSound.play();
          dispatch(
            setPhoneEdit({
              key: call?.options?.caller_id_number,
              params: {
                calling: false
              }
            })
          );
          // dispatch(setStartTimer(false))
          // dispatch(setResedTimer(true))
          handleReset();
        });
        call.subscribeEvent('hold', (cause: any) => {
          dispatch(
            setPhoneEdit({
              key: call?.options?.caller_id_number,
              params: {
                hold: true
              }
            })
          );
        });
        call.subscribeEvent('unhold', (cause: any) => {
          dispatch(
            setPhoneEdit({
              key: call?.options?.caller_id_number,
              params: {
                hold: false
              }
            })
          );
        });
      });
    } else {
      vertoRef?.unsubscribeEvent('invite');
    }
    // eslint-disable-next-line
  }, [vertoRef, disable]);

  useEffect(() => {
    if (disable && answerCalling) {
      soundPlayer.muted = false;
    } else {
      soundPlayer.muted = true;
    }
  }, [disable, answerCalling]);

  const handleCall = async (number: any) => {
    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    phones[number].call.answer(stream.getTracks());
    dispatch(
      setPhones({
        [number]: {
          ...phones[number],
          answerCalling: false
        }
      })
    );
  };

  const endCall = async (number: any) => {
    soundPlayer.pause();
    endCallSound.play();
    phones[number].call.hangup();
    dispatch(
      setPhones({
        [number]: {
          ...phones[number],
          answerCalling: false,
          calling: false
        }
      })
    );

    setTimeout(() => {
      dispatch(setPhoneDelete(number));
    }, 1000);
  };

  const openLead = () => {
    findLead.mutate(Number(callerNumber));
  };

  return disable ? (
    <div className={styles.body}>
      {Object.values(phones)?.map((item, index) => (
        <div className={`${styles.container} ${item?.calling ? styles.container_active : ''}`} key={index}>
          <div className={styles.call}>
            <div className={styles.call_left}>
              <img src={defaultPersonAvatar} alt="" />
              <div onClick={openLead}>
                <h3>{item?.employeeName || item?.callerName}</h3>
                <p>
                  <CallCustomIcon className={styles.miniCall} /> {item?.employeePhoneNumber || item?.callerNumber}{' '}
                  <ArrowDownIcon className={styles.call_arrowRIght} />
                  {/* {isActive && <span>{formatTime(timer)}</span>} */}
                </p>
              </div>
            </div>
            <div className={styles.call_right}>
              <div className={styles.call_right_endCall} onClick={() => endCall(item?.callerNumber)}>
                <CallCustomIcon />
              </div>
              {item?.answerCalling && (
                <div className={styles.call_right_call} onClick={() => handleCall(item?.callerNumber)}>
                  <CallCustomIcon />
                </div>
              )}
            </div>
          </div>
          {!item?.answerCalling && (
            <div className={styles.call_footer}>
              <AddIcCallIcon />
              <DialPadIcon />
              <MicOffIcon />
              <PauseIcon onClick={() => item?.call?.toggleHold()} className={item?.hold ? styles.activeCall : ''} />
            </div>
          )}
        </div>
      ))}
      <video id={'webRtcVideo'} autoPlay style={{ display: 'none' }}></video>
    </div>
  ) : null;
};

export default WebRtc;
