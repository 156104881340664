import React from 'react';

const CubeIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00004 7.87716L15.384 5.19366C15.2745 5.08191 15.1508 4.98216 15.0008 4.91241L9.60079 2.38191C9.22204 2.20566 8.77804 2.20566 8.39929 2.38191L2.99929 4.91241C2.84929 4.98216 2.72554 5.08191 2.61604 5.19366L9.00004 7.87716ZM8.43754 8.87031L2.25004 6.26931V12.0661C2.24629 12.5468 2.53429 12.9878 2.99254 13.2046L8.39254 15.7343C8.39982 15.7378 8.40751 15.74 8.41528 15.7423C8.4227 15.7445 8.43021 15.7468 8.43754 15.7501V8.87031ZM9.56254 15.7478V8.87031L15.75 6.26931V12.0728C15.75 12.5521 15.4598 12.9908 15.0008 13.2046L9.60079 15.7343C9.594 15.7377 9.58673 15.7399 9.57939 15.7421C9.57375 15.7438 9.56807 15.7455 9.56254 15.7478Z"
      fill={props?.fill ? props.fill : '#00A389'}
    />
  </svg>
);

export default CubeIcon;
