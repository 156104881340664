import React, { FC, ReactNode, useState } from 'react';
import { Modal } from 'antd';

import styles from './sipuSetting.module.scss';
import SipuConnection from './sipu-connection/SipuConnection';
import SipuUsers from './sipu-users/SipuUsers';
import SipuConfig from './sipu-config/SipuConfig';
import { SipuiAuth } from './utils/models/SipuiAuth';
import { t } from 'i18next';

interface IProps {
  children: ReactNode;
  data: SipuiAuth | undefined;
}

const SipuSetting: FC<IProps> = ({ children, data }) => {
  const [editId, setEditId] = useState<number | null>(null);
  const tabs = {
    '1': {
      title: t('home.Ulanish'),
      component: <SipuConnection data={data} />
    },
    '2': {
      title: t('home.Foydalanuvchilar'),
      component: <SipuUsers data={data} />
    },
    '3': {
      title: t('home.Sozlamalar'),
      component: <SipuConfig id={data?.id} setEditId={setEditId} editId={editId} />
    }
  };
  const [activeTab, setActiveTab] = useState('1');
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={t('home.Sipuni_integratsiyasini_sozlash')}
        wrapClassName={styles.modal}
        footer={false}
        width={700}
      >
        {data && (
          <div className={styles.tab}>
            {Object.keys(tabs)?.map(item => (
              <div
                className={`${styles.tab_item} ${item === activeTab ? styles.tab_item_active : ''}`}
                onClick={() => {
                  setActiveTab(item);
                  if (editId) {
                    setEditId(null);
                  }
                }}
              >
                {tabs[item as keyof typeof tabs]?.title}
              </div>
            ))}
          </div>
        )}
        {tabs[activeTab as keyof typeof tabs]?.component}
      </Modal>
      <div onClick={openModal}>{children}</div>
    </>
  );
};

export default SipuSetting;
