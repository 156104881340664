import { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { AnalyticCountOwnother } from '@pages/boss/bi-analytics/utils/models/AnalyticCountOwnOther';
import { BarChartColumnData } from '@pages/boss/bi-analytics/utils/models/BarChartColumnData';
import { DATE_FORMAT } from '@utils/constants/format';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import AnalyticByEmployeeModal from '../../../analyticByEmployeeModal/AnalyticByEmployeeModal';

import styles from './numberOfPurchase.module.scss';

const NumberOfPurchaseBar = ({ sales, arrayOfMonths }: { sales: AnalyticCountOwnother[] | undefined; arrayOfMonths: string[] }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [barChartColumnData, setBarChartColumnData] = useState<BarChartColumnData>({
    index: 0,
    data: {
      date: ''
    }
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const newData: any = [];

  arrayOfMonths.map(month => {
    newData.push({
      month: t(`boss_payments.${dayjs(month, DATE_FORMAT).format('MMMM')}`),
      date: month,

      [t('bi_analytics.Mening_sotuvlarim')]: sales?.find(el => el.date === month) ? sales?.find(el => el.date === month)?.count : 0,
      [t("bi_analytics.Mintaqadagi_o'rtacha_sotuvlar")]: sales?.find(el => el.date === month) ? sales?.find(el => el.date === month)?.averageCount : 0
    });
  });
  return (
    <div className={styles.number_of_purchase_bar_container}>
      <ResponsiveBar
        data={newData || []}
        groupMode="grouped"
        colors={['#10D871', '#B8F4D5']}
        keys={[t('bi_analytics.Mening_sotuvlarim'), t("bi_analytics.Mintaqadagi_o'rtacha_sotuvlar")]}
        indexBy="month"
        margin={{ top: 55, right: 30, bottom: 75, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        enableLabel={false}
        innerPadding={2}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 62,
            itemsSpacing: 2,
            itemWidth: 198,
            itemHeight: 40,
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle'
          }
        ]}
        enableGridX={false}
        layers={[
          'axes',
          'grid',
          props => {
            const { bars } = props;
            const data1 = bars.filter(item => item.data.id === t("bi_analytics.Mintaqadagi_o'rtacha_sotuvlar"));
            const data2 = bars.filter(item => item.data.id === t('bi_analytics.Mening_sotuvlarim'));

            return (
              <>
                {data1.map((bar1, index) => {
                  const bar2 = data2[index];

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Popover
                      placement="leftTop"
                      content={
                        <div className={styles.sales_bar_card}>
                          <h4 className={styles.sales_bar_card_title}>{bar1.data.data.month}</h4>
                          <div className={styles.sales_popover_data_container}>
                            <div className={styles.first_color_container} />
                            <p>{t('bi_analytics.Mening_sotuvlarim')}:</p>
                            <div>{bar2.data.data[`${t('bi_analytics.Mening_sotuvlarim')}`]}</div>
                          </div>
                          <div className={styles.sales_popover_data_container}>
                            <div className={styles.second_color_container} />
                            <p>{t("bi_analytics.Mintaqadagi_o'rtacha_sotuvlar")}:</p>
                            <div>{bar1.data.data[`${t("bi_analytics.Mintaqadagi_o'rtacha_sotuvlar")}`]}</div>
                          </div>
                        </div>
                      }
                    >
                      <g key={index} style={{ cursor: 'pointer' }}>
                        <rect
                          x={bar2?.x - 12}
                          y={-50}
                          className={styles.hover_rect}
                          width={bar1?.width + bar2?.width + 25}
                          height="250px"
                          stroke="#10d871"
                          fill="#f3fdf8"
                          rx="12"
                          ry="12"
                          opacity="0"
                          onClick={() => {
                            showModal();
                            setBarChartColumnData(bar1?.data);
                          }}
                        />
                        {bar1.data.data[`${t("bi_analytics.Mintaqadagi_o'rtacha_sotuvlar")}`] > 0 && (
                          <rect x={bar1?.x} y={bar1?.y} width={bar1?.width} height={bar1?.height} fill="#B8F4D5" pointerEvents="none" rx="3" ry="3" />
                        )}
                        {bar2.data.data[`${t('bi_analytics.Mening_sotuvlarim')}`] > 0 && (
                          <rect
                            x={bar2?.x}
                            y={bar2?.y}
                            width={bar2?.width}
                            height={bar2?.height}
                            fill="#10D871"
                            pointerEvents="none"
                            opacity={1000}
                            rx="3"
                            ry="3"
                          />
                        )}
                      </g>
                    </Popover>
                  );
                })}
              </>
            );
          },
          'legends'
        ]}
      />
      <AnalyticByEmployeeModal
        open={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        barChartColumnData={barChartColumnData}
        sales={sales || []}
      />
    </div>
  );
};

export default NumberOfPurchaseBar;
