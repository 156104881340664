import React from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useBossComplexSaleFlatsOneHouse } from '../../queries/queries';
import { FlatsInfoOneHouse } from '../../utils/models/BossComplex';
import styles from './saleOneHouseModal.module.scss';
import BuildingIcon from '../../assets/icons/BuildingIcon';
import BuildingsIcon from '../../assets/icons/BuildingsIcon';

interface Props {
  visible: boolean;
  close: (house: FlatsInfoOneHouse | undefined, visible: boolean) => void;
  house: FlatsInfoOneHouse | undefined;
  startDate: string;
  finishDate: string;
}

const SaleOneHouseModal: React.FC<Props> = ({ visible, close, house, startDate, finishDate }) => {
  const { isLoading, data, isFetching } = useBossComplexSaleFlatsOneHouse(house?.id || 0, startDate, finishDate);

  const { t } = useTranslation();

  // handel cancel
  const handleCancel = () => {
    close(undefined, false);
  };

  // house name
  const houseName = (name: string) => {
    if (name.length > 17) {
      return name.slice(0, 17) + '...';
    }
    return name;
  };

  return (
    <Modal
      width={'max-content'}
      className={styles.container}
      title={t('boss_complex.Xonadonlar_sotuvi')}
      visible={visible}
      footer={false}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading || isFetching}>
        <div className={styles.container_header}>
          <div className={styles.container_header_count}>
            <div className={styles.container_header_count_name}>
              <BuildingIcon />
              <h2 title={house?.name}>{houseName(house?.name || '')}</h2>
            </div>
            <div className={styles.container_header_count_flats}>
              <span>{t('boss_complex.Jami_uylar')}</span>
              <p>{house?.total}</p>
            </div>
          </div>
          <div className={styles.container_header_sale}>
            <span>{t('titles.order')}</span>
            <p>{house?.totalBooking}</p>
          </div>
          <div className={styles.container_header_sale}>
            <span>{t('boss_complex.Sotuvda')}</span>
            <p>{house?.totalSale}</p>
          </div>
          <div className={styles.container_header_sold_count}>
            <div className={styles.container_header_sold_count_sold}>
              <span>{t('boss_complex.Sotilgan')}</span>
              <p>{house?.totalSold}</p>
            </div>
            <div className={styles.container_header_sold_count_repaired}>
              <div className={styles.container_header_sold_count_repaired_item}>
                <span>{t('boss_complex.Tamirsiz')}</span>
                <p>{house?.unrepaired}</p>
              </div>
              <div className={styles.container_header_sold_count_repaired_item}>
                <span>{t('boss_complex.Tamirli')}</span>
                <p>{house?.repaired}</p>
              </div>
            </div>
          </div>
        </div>
        <Row gutter={[24, 0]}>
          {data?.map((building, index) => (
            <Col key={index} className={styles.container_item} span={12}>
              <div className={styles.container_item_header}>
                <div className={styles.container_item_header_name}>
                  <BuildingsIcon />
                  <p>{building.name}</p>
                </div>
                <div className={styles.container_item_header_count}>
                  <span>{t('boss_complex.Jami_uylar')}</span>
                  <p>{building.total}</p>
                </div>
              </div>
              <div className={'d-flex align-space-between'}>
                <div className={styles.container_item_sale}>
                  <span>{t('boss_complex.Sotuvda')}</span>
                  <p>{building.totalSale}</p>
                </div>
                <div className={styles.container_item_sale}>
                  <span>{t('titles.order')}</span>
                  <p>{building?.totalBooking}</p>
                </div>
                <div className={styles.container_item_sold}>
                  <div className={styles.container_item_sold_count}>
                    <span>{t('boss_complex.Sotilgan')}</span>
                    <p>{building.totalSold}</p>
                  </div>
                  <div className={styles.container_item_sold_repaired}>
                    <div className={styles.container_item_sold_repaired_item}>
                      <span>{t('boss_complex.Tamirsiz')}</span>
                      <p>{building.unrepaired}</p>
                    </div>
                    <div className={styles.container_item_sold_repaired_item}>
                      <span>{t('boss_complex.Tamirli')}</span>
                      <p>{building.repaired}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Spin>
    </Modal>
  );
};

export default SaleOneHouseModal;
