import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

import FileViewerModal from '../../../../../components/fileViewerModal/FileViewerModal';
import { LEAD_DATA_TYPE, LEAD_EVENT_TYPE, LeadEventOrTask, LeadEventsAndTasksList } from '../../../../../utils/models/LeadEventsAndTaskList';

import Chat from './chat/Chat';
import ChatEmployee from './chatEmployee/ChatEmployee';
import ClientEvent from './client-event/ClientEvent';
import CompletedTask from './completedTask/CompletedTask';
import ClientLeadEvents from './event/ClientLeadEvents';
import EventContainer from './event/EventContainer';
import Message from './message/Message';
import RecordingPhone from './recordingPhone/RecordingPhone';
import NoteType from './typeNote/TypeNote';
import UnCompletedTask from './unCompletedTask/UnCompletedTask';

import styles from './taskChatContainer.module.scss';

type Props = {
  type?: string | undefined;
  data: LeadEventsAndTasksList[] | undefined;
  isLoading: boolean;
};

const TaskChatContainer: React.FC<Props> = ({ data, isLoading, type }) => {
  const [scrollVisible, setScrollVisible] = useState(false);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && !scrollVisible) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      setScrollVisible(true);
    }
  }, [data, scrollVisible]);

  const returnTypeEvent = (item: LeadEventOrTask) => {
    switch (item.leadDataType || item?.eventType) {
      case LEAD_DATA_TYPE.LEAD_EVENT:
        if (type) {
          return (
            <>
              <ClientLeadEvents data={item?.res} />
            </>
          );
        }
        return <EventContainer events={item.events || []} />;
      case LEAD_DATA_TYPE.LEAD_NOTE:
        // eslint-disable-next-line default-case
        switch (item.res.type) {
          case LEAD_EVENT_TYPE.PHONE:
            return <RecordingPhone data={item.res} dataAll={data} />;
          case LEAD_EVENT_TYPE.SMS:
            return <Message data={item.res} />;
          case LEAD_EVENT_TYPE.SIMPLE:
            return <NoteType event={item.res} />;
        }
        return <NoteType event={item.res} />;
      case LEAD_DATA_TYPE.LEAD_MESSAGE:
        return <ChatEmployee chat={item.res} />;
      case LEAD_DATA_TYPE.LEAD_CONVERSATION:
        return <Chat chat={item.res} />;
      case LEAD_DATA_TYPE.LEAD_TASK:
        if (item.res.closedDate) return <CompletedTask event={item.res} />;
        return <UnCompletedTask event={item.res} />;
      case LEAD_DATA_TYPE.EVENT_CLIENT:
        return <ClientEvent data={item?.res} />;
      default:
        return <div />;
    }
  };

  return (
    <div style={{ marginBottom: 150 }}>
      <Spin spinning={isLoading}>
        <div id="one_lead_events">
          {data?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <div className={styles.container_date}>
                <span>{item.date}</span>
              </div>
              {item.events.map(event => returnTypeEvent(event))}
            </div>
          ))}
        </div>
        <div ref={bottomRef} />
      </Spin>
      <FileViewerModal />
    </div>
  );
};

export default TaskChatContainer;
