import React from 'react';

const LockBlackStrokeIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 5.83366V4.66699C3.5 2.73616 4.08333 1.16699 7 1.16699C9.91667 1.16699 10.5 2.73616 10.5 4.66699V5.83366"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99996 10.7917C7.80537 10.7917 8.45829 10.1387 8.45829 9.33333C8.45829 8.52792 7.80537 7.875 6.99996 7.875C6.19454 7.875 5.54163 8.52792 5.54163 9.33333C5.54163 10.1387 6.19454 10.7917 6.99996 10.7917Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.91663 12.833H4.08329C1.74996 12.833 1.16663 12.2497 1.16663 9.91634V8.74967C1.16663 6.41634 1.74996 5.83301 4.08329 5.83301H9.91663C12.25 5.83301 12.8333 6.41634 12.8333 8.74967V9.91634C12.8333 12.2497 12.25 12.833 9.91663 12.833Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockBlackStrokeIcon;
