import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';

import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import DeleteHouseModal from '../../components/houseConfig/DeleteHouseModal';
import HeaderHouseConfig from '../../components/houseConfig/HeaderHouseConfig';
import HouseCardsCont from '../../components/houseConfig/HouseCardsCont';
import HouseEditAndCreateModal from '../../components/houseConfig/HouseEditAndCreateModal';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { clearHouseConfig } from '../../store/reducers/HouseConfigSlice';

const { Content } = Layout;

const HouseConfig = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(
    () => () => {
      dispatch(clearHouseConfig());
    },
    []
  );

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div>
          <HeaderHouseConfig />
          <HouseCardsCont isSales={true} sale={true} title={t('house_config.Sotuvdagi_turar_joy_majmualari')} />
          <HouseCardsCont isSales={false} sale={false} title={t('house_config.Sotuvda_mavjud_bolmagan_turar_joy_majmualari')} />
          <HouseEditAndCreateModal />
          <DeleteHouseModal />
        </div>
      </Content>
    </>
  );
};

export default HouseConfig;
