import { $api } from '@service/RequestService';
import message from '@utils/helper/message';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { NotifConfigModel } from '../utils/models/NotifConfigModel';

export function usePostNotificationConfig() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: NotifConfigModel) => {
      const res = await $api.post(endPoints.NOTIF_CONFIG_SAVE, data);

      return res.data;
    },
    onSuccess: res => {
      message.success(res.message.uz);
      qc.invalidateQueries({ queryKey: [queryKeys.NOTIF_CONFIG] });
    }
  });
}
