import React from 'react';

type Props = {
  onClick?: () => void;
};

const PositionCenterIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path d="M16 5H4M19 1H1M19 9H1M16 13H4" stroke="#87898E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PositionCenterIcon;
