import DiscountSettingContainer from '../../components/discountSetting/DiscountSettingContainer';

import { discountSettingPaths } from './discountSettingPath';

export const discountSettingRoutes = [
  {
    component: DiscountSettingContainer,
    path: discountSettingPaths.id + discountSettingPaths.name,
    exact: false
  }
];
