/* eslint-disable react/jsx-no-bind */
import React, { FC, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetCustomTemplateDocument } from '@queries/queries';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../../../assets/icons/ArrowLineDownIcon';
import ArrowIosDownIcon from '../../../../assets/icons/contract/ArrowIosDownIcon';
import { useDownloadPaymentsOneContractToExcel, useDownloadTableMonthlyPayments } from '../../../../queries/mutation';
import { ContractId } from '../../../../utils/models/ContractId';

import DownloadContract from './DownloadContract';

import styles from './contractIdHeader.module.scss';

interface IProps {
  data: ContractId | undefined;
  children: React.ReactNode;
}

const ContractIdDownload: FC<IProps> = ({ data, children }) => {
  const { t } = useTranslation();

  const { data: contractDocs } = useGetCustomTemplateDocument();

  const [openDownloadContractPopover, setOpenDownloadContractPopover] = useState(false);

  const downloadPayments = useDownloadPaymentsOneContractToExcel(String(data?.id), data?.number || '');

  const downloadTable = useDownloadTableMonthlyPayments(Number(data?.id));

  function downloadContractSuccess() {
    setOpenDownloadContractPopover(false);
  }

  // handlePaymentHistory
  const handlePaymentHistory = () => {
    downloadPayments.mutate();
  };

  // handlePaymentGraphic
  const handlePaymentGraphic = () => {
    downloadTable.mutate();
  };

  return (
    <Dropdown
      overlay={
        <div className={styles.dropdown}>
          <div className={styles.dropdown_item}>
            {t('create_payment.Shartnoma')}
            <span className={styles.dropdown_arrowSelect}>
              <ArrowIosDownIcon />
            </span>
            <div className={styles.dropdown_item_content}>
              <DownloadContract contractId={data?.id} contractNumber={data?.number} onSuccess={downloadContractSuccess} />
            </div>
          </div>
          {contractDocs?.map(item => (
            <div className={styles.dropdown_item} key={item?.id}>
              <span>{item?.name}</span>
              <span className={styles.dropdown_arrowSelect}>
                <ArrowIosDownIcon />
              </span>
              <div className={styles.dropdown_item_content}>
                <DownloadContract customData={item} contractId={data?.id} contractNumber={data?.number} onSuccess={downloadContractSuccess} />
              </div>
            </div>
          ))}
          <div className={styles.dropdown_item} onClick={() => handlePaymentGraphic()}>
            {t('contract_id.To’lov_grafigi')} <span className={styles.dropdown_arrowDownload}>{downloadTable.isLoading ? <LoadingOutlined /> : <ArrowLineDownIcon />}</span>
          </div>
          <div className={styles.dropdown_item} onClick={handlePaymentHistory}>
            {t('contract_id.Tolov_tarixi')} <span className={styles.dropdown_arrowDownload}>{downloadPayments.isLoading ? <LoadingOutlined /> : <ArrowLineDownIcon />}</span>
          </div>
        </div>
      }
      visible={openDownloadContractPopover}
      onVisibleChange={visible => setOpenDownloadContractPopover(visible)}
      trigger={['click']}
      placement="bottom"
    >
      {children}
    </Dropdown>
  );
};

export default ContractIdDownload;
