import { TFunction } from 'i18next';

import { EventListTab } from '../context/context';

export const objectTypes: (t: TFunction) => Record<
  EventListTab,
  {
    value: string;
    label: string;
    disabled?: boolean;
  }[]
> = t => ({
  LEAD: [
    {
      label: t('integration.Lid'),
      value: 'LEAD'
    },
    {
      label: t('one_task.Topshirig'),
      value: 'TASK'
    }
    // {
    //   label: t('home.Shartnoma'),
    //   value: 'CONTRACT',
    //   disabled: true
    // },
    // {
    //   label: t('titles.order'),
    //   value: 'BOOKING',
    //   disabled: true
    // }
  ],
  CLIENT: [
    {
      label: t('integration.Lid'),
      value: 'LEAD'
    },
    {
      label: t('home.Shartnoma'),
      value: 'CONTRACT'
    },
    {
      label: t('client.Mijoz'),
      value: 'CLIENT'
    },
    {
      label: t('titles.order'),
      value: 'BOOKING'
    }
  ]
});
