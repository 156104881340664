/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { BASE_URL } from '@service/RequestService';
import { Button, Modal } from 'antd';
import { t } from 'i18next';

import { useGetTGChatBotData, useGetTGChatBotURL } from '../service/queries';

// import { useGetEmployeeNotifyConfig, useGetEmployeeNotifyConfigBot } from '../service/queries';
import styles from '../tgPremium.module.scss';

interface AddTGUserProps {
  isModalOpen: boolean;
  handleCancel: () => void;
}

const AddTGUserModal: React.FC<AddTGUserProps> = ({ isModalOpen, handleCancel }) => {
  const { data: dataTGChatBotURL, isLoading } = useGetTGChatBotURL(isModalOpen);
  const { refetch } = useGetTGChatBotData();

  const handleRefresh = () => {
    // window.location.reload();
    refetch();
    handleCancel();
  };

  return (
    <div>
      <Modal
        title={<p className="text-[18px] font-bold text-[#464255]">{t('home.Telegram_botga_ulanish')}</p>}
        visible={isModalOpen}
        onCancel={handleCancel}
        wrapClassName={styles.modal}
        footer={
          <Button className="mb-[5px] ml-auto flex h-[38px] w-[160px] items-center justify-center rounded-[50px] bg-[#00A389] text-[14px] text-[#fff]" onClick={handleRefresh}>
            {t('home.Saqlash')}
          </Button>
        }
      >
        <div className="flex items-center justify-center">
          <div>
            <p className="mt-[-12px] text-[14px] leading-5 text-[#475467]">{t('home.Bildirishnomalar_botini_ulash_uchun_telefoningizda_QR_kodni_skanerlang')}</p>
            {isLoading ? <LoadingOutlined /> : <img className="ml-auto mr-auto flex w-[200px]" src={`${BASE_URL}/${dataTGChatBotURL?.absolutePath}`} />}
            <p className="mb-[-10px] text-[14px] leading-5 text-[#475467]">
              {t('home.Kompyuter_orqali')}{' '}
              <a className="text-[#1E90FF] underline" href={dataTGChatBotURL?.redirectUrl} target="_blank" rel="noreferrer">
                {t('home.Ulanish_uchun bosing')}
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddTGUserModal;
