import React from 'react';
import styles from './webSite.module.scss';
import { Button } from 'antd';
import FlashSlash from '../../../assets/icons/FlashSlash';
import TickCircleIcon from '../../../assets/icons/TickCircleIcon';

const BotConfig = () => {
  return (
    <div className={styles.cont}>
      <div className={styles.cont_header}>
        <div className={styles.cont_header_left}>
          <h1>Veb-sayt </h1>
          <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit. At est felis nibh amet.</h2>
        </div>
        <div>
          {/*<Button className={styles.cont_header_button_active} type={'primary'}>*/}
          {/*  <FlashIcon />*/}
          {/*  Aktiv*/}
          {/*</Button>*/}
          <Button type={'primary'} danger className={styles.cont_header_button}>
            <FlashSlash />
            Aktivlashtirish
          </Button>
        </div>
      </div>
      <div className={styles.cont_body}>
        <div className={styles.cont_body_item}>
          <div className={styles.cont_body_item_left}>
            <h3>Sayt xabarlarini yuborish</h3>
            <p>Saytga yozilgan xabarlarni qaysi qaysi tizimga yuborishni tanlang</p>
          </div>
          <div className={styles.cont_body_item_right}>
            <div className={styles.cont_body_item_right_button + ' ' + styles.cont_body_item_margin}>
              <TickCircleIcon />
              <span></span>
            </div>
            <div className={styles.cont_body_item_right_active_button}>
              <TickCircleIcon />
              <span>AmoCRM</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cont_footer}>
        <Button htmlType={'submit'} className={styles.cont_footer_save} type={'primary'}>
          Saqlash
        </Button>
      </div>
    </div>
  );
};

export default BotConfig;
