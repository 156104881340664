/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import NotificationCard from '@components/notification/notificationCard/NotificationCard';
import CloseIcon from '@icons/contract/CloseIcon';
import NotifIcon from '@icons/NotifIcon';
import TwoCheckIcon from '@icons/TwoCheckIcon';
import { useNotificationMeReadAll } from '@queries/mutation';
import { useNotificationMe } from '@queries/queries';
import { queryNames } from '@utils/constants/queryNames';
import { Button, Drawer, Spin, Tabs, Tooltip } from 'global';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './notificationDrawer.module.scss';

type Props = {
  setOpenNotification: React.Dispatch<React.SetStateAction<boolean>>;
  openNotification: boolean;
};

const NotificationDrawer: React.FC<Props> = ({ setOpenNotification, openNotification }) => {
  const [offsetTop, setOffsetTop] = useState(0);
  const [notifType, setNotifType] = useState<'ALL' | 'CONTRACT' | 'UNREAD' | 'ACTIVE_SESSION_SECURITY' | undefined>('UNREAD');
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('1');

  const qc = useQueryClient();
  const allRead = useNotificationMeReadAll();

  const { data: notificationMe, isLoading, fetchNextPage } = useNotificationMe(notifType);

  useEffect(() => {
    if (openNotification) {
      qc.fetchQuery({ queryKey: [queryNames.NOTIFICATION_ME, notifType] });
    }
    return () => {
      qc.setQueryData([queryNames.NOTIFICATION_ME, notifType], {});
    };
  }, [openNotification, notifType]);

  const [activeId, setActiveId] = useState(0);

  const scrollColumn = (e: any) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight - 4 && e.target.scrollHeight > e.target.offsetHeight) {
      fetchNextPage();
    }
  };

  const handleClose = () => {
    setOpenNotification(false);
    setActiveKey('1');
    setNotifType('UNREAD');
  };

  const Notification = () => (
    <Spin style={{ width: '100%' }} spinning={isLoading}>
      {notificationMe && notificationMe.pages && notificationMe?.pages.length > 0 && notificationMe?.pages[0]?.data.length > 0 ? (
        notificationMe?.pages.map(
          item1 =>
            item1 &&
            item1.data &&
            item1.data.map((item, index) => (
              <NotificationCard
                setActiveId={setActiveId}
                activeId={activeId}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                setOpenNotification={setOpenNotification}
                {...item}
                notifType={notifType}
              />
            ))
        )
      ) : (
        <div className={styles.notNotification}>
          <NotifIcon />
          <h3>{t('notification.Bildirishnoma_mavjud_emas')}</h3>
          <p>{t('notification.Hozirgi_vaqtda_siz_uchun_yangi_bildirishnomalar_topilmadi')}</p>
        </div>
      )}
    </Spin>
  );

  const handleAllRead = () => {
    allRead.mutateAsync().then(handleClose);
  };

  return (
    <Drawer visible={openNotification} onClose={handleClose} closeIcon={null} title={null} width={532} className={styles.notifDrawer}>
      <div className={styles.notifTitle}>
        <h4>{t('notification.Bildirishnomalar')}</h4>
        <div className={styles.notifTitle_icons}>
          <Tooltip overlay={t('notification.Barchasini_oqilgan_deb_belgilash')}>
            {notifType === 'UNREAD' ? (
              <Button onClick={handleAllRead} loading={allRead.isLoading}>
                <TwoCheckIcon color="#667085" />
              </Button>
            ) : (
              ''
            )}
          </Tooltip>
          <CloseIcon onClick={handleClose} />
        </div>
      </div>
      <div
        style={{
          height: `calc(100vh - ${offsetTop + 30}px)`,
          overflow: 'auto'
        }}
        onScroll={scrollColumn}
        ref={ref => {
          setOffsetTop(ref?.offsetTop || 0);
        }}
      >
        <Tabs
          activeKey={activeKey}
          defaultActiveKey="1"
          onChange={e => {
            e === '4'
              ? setNotifType('ALL')
              : e === '3'
                ? setNotifType('CONTRACT')
                : e === '1'
                  ? setNotifType('UNREAD')
                  : e === '2'
                    ? setNotifType('ACTIVE_SESSION_SECURITY')
                    : setNotifType(undefined);
            setActiveKey(e);
          }}
        >
          <Tabs.TabPane key="1" tab={t('home.Oqilmaganlar')}>
            <Notification />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={t('setting.Xavfsizlik')}>
            <Notification />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={t('home.Shartnoma')}>
            <Notification />
          </Tabs.TabPane>
          <Tabs.TabPane key="4" tab={t('home.Barchasi')}>
            <Notification />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
