import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { useGetAllCountries, useGetCountries } from '@pages/oneLid/services/queries';
import { changeLeadsFiledValue } from '@reducers/CrmOneLidSlice';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { CrmField } from '@utils/models/CrmField';
import { Input, Select } from 'antd';
import { t } from 'i18next';

import styles from './info.module.scss';

type Props = {
  item: CrmField;
  index: number;
};

const { Option } = Select;

const convertAddress = (value: string) => {
  const obj = value && value.includes('{') && value.includes('}') ? JSON.parse(value) : {};

  return {
    ...obj
  };
};

const SelectAddress: React.FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const fields = useAppSelector(state => state.crmOneLidField.leadFieldValue);
  const { data: allCountries } = useGetAllCountries();

  const fieldValue = fields?.find(prev => prev?.valueType === 'DISTRICT')?.value;

  const [reg, setReg] = useState(convertAddress(fieldValue!)[`region_${i18n.language}`]);
  const { data: oneCountry } = useGetCountries(convertAddress(fieldValue!)?.zone);

  // handle change value fields
  const changeValueFields = (id: number, value: string, valueType?: string) => {
    dispatch(changeLeadsFiledValue({ value, id, valueType }));
  };

  const result =
    oneCountry &&
    oneCountry?.reduce((acc: any, curr: any) => {
      const key = curr[`region_${i18n.language}`];

      if (acc[key]) {
        acc[key].push(curr);
      } else {
        acc[key] = [curr];
      }
      return acc;
    }, {});

  const result2 = oneCountry && Object.keys(result).map(key => [key, result[key]]);
  const finalData = result2?.filter((item, index) => result2?.indexOf(item) === index);

  const cityData = finalData
    ?.find(item => item[0] === reg)
    ?.filter(prev => prev !== convertAddress(fieldValue!)?.region)
    ?.flat()
    ?.filter(item => typeof item === 'object');

  return (
    <div className={styles.container_body_item} style={{ alignItems: 'flex-start' }}>
      <div className={styles.container_body_item_name}>{item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}</div>
      <div className={styles.addressSelects}>
        <Select
          showSearch
          placeholder={t('home.Davlat')}
          value={convertAddress(fieldValue!)[`country_${i18n.language}`]}
          // allowClear
          onChange={e => {
            const sendData = {
              [`city_${i18n.language}`]: undefined,
              [`region_${i18n.language}`]: undefined,
              ...allCountries?.find(item => item?.zone === e)
            };

            const jsonFile = JSON.stringify(sendData);

            changeValueFields(Number(item.id), jsonFile, 'DISTRICT');
          }}
        >
          {fieldValue !== undefined && fieldValue !== '{}' && (
            <Option key="empty" value="empty">
              ...
            </Option>
          )}
          {allCountries?.map(item => (
            <Option value={item?.zone} key={item?.index}>
              {item[`country_${i18n.language}` as keyof typeof item]}
            </Option>
          ))}
        </Select>
        <Select
          showSearch
          // allowClear
          placeholder={t('home.Viloyat')}
          value={convertAddress(fieldValue!)[`region_${i18n.language}`]}
          onChange={e => {
            setReg(e);
            const sendData = finalData?.find(item => item[0] === e)![1]?.find((prev: any) => prev[`region_${i18n.language}`] === e);
            const newData = {
              ...sendData,
              city_uz: undefined,
              city_ru: undefined,
              city_en: undefined,
              city_tr: undefined,
              city_kr: undefined
            };
            const jsonFile = JSON.stringify(newData);

            changeValueFields(Number(item.id), jsonFile, 'DISTRICT');
          }}
        >
          {finalData?.map((item, index) => (
            <Option key={index} value={item[0]}>
              {item[0]}
            </Option>
          ))}
        </Select>
        <Select
          showSearch
          // allowClear
          placeholder={t('home.Shahar/tuman')}
          value={convertAddress(fieldValue!)[`city_${i18n.language}`]}
          onChange={e => {
            const sendData = cityData?.find(item => item[`city_${i18n.language}`] === e);
            const jsonFile = JSON.stringify(sendData);

            changeValueFields(Number(item.id), jsonFile, 'DISTRICT');
          }}
        >
          {cityData?.map((item: any, index: number) => (
            <Option key={index} value={item[`city_${i18n.language}`]}>
              {item[`city_${i18n.language}`]}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Address line"
          value={convertAddress(fieldValue!)?.street}
          onChange={e => {
            const sendData = { ...convertAddress(fieldValue!), street: e.target.value };

            const jsonFile = JSON.stringify(sendData);

            changeValueFields(Number(item.id), jsonFile, 'DISTRICT');
          }}
          className={styles.container_body_item_value}
        />
      </div>
    </div>
  );
};

export default SelectAddress;
