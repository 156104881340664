import React, { FC } from 'react';
interface IProps {
  width?: number;
  height?: number;
}

const MoreHorizIcon: FC<IProps> = ({ width = 4, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-8.74228e-08 14C-3.93402e-08 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12C0.9 12 -1.35505e-07 12.9 -8.74228e-08 14ZM-6.11959e-07 2C-5.63877e-07 3.1 0.899999 4 2 4C3.1 4 4 3.1 4 2C4 0.899999 3.1 -1.35505e-07 2 -8.74228e-08C0.899999 -3.93403e-08 -6.60042e-07 0.9 -6.11959e-07 2ZM-3.49691e-07 8C-3.01609e-07 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6C0.9 6 -3.97774e-07 6.9 -3.49691e-07 8Z"
        fill="#666666"
      />
    </svg>
  );
};

export default MoreHorizIcon;
