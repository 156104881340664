/* eslint-disable react/jsx-no-bind */
import '@fullcalendar/react/dist/vdom';

import React, { FC, useEffect, useRef, useState } from 'react';
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import FullCalendar, { DateSelectArg, EventClickArg, EventContentArg, EventDropArg } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
// import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Calendar from '../../service/calendar';
import { dateData } from '../../utils/helper/dateData';

import { weekDays } from './calendar-locale';

import './mDatePicker.scss';

interface IProps {
  visibleTime: boolean;
  onChangeMPicker: (date: string, start: string, end: string) => void;
  data?: any[];
  setStarEndNew?: React.Dispatch<
    React.SetStateAction<{
      start: string;
      end: string;
    }>
  >;
  startEndNew?: {
    start: string;
    end: string;
  };
}

const MDatePicker: FC<IProps> = ({ visibleTime, onChangeMPicker, data = [], setStarEndNew, startEndNew }) => {
  const calendarRef = useRef<FullCalendar>(null);
  const [dateValue, setDateValue] = useState(data[0]?.start ? dayjs(new Date(data[0]?.start)) : dayjs());
  const [dataCopy, setDataCopy] = useState<any[]>(data);
  const [startEnd, setStarEnd] = useState({
    start: '00:00',
    end: '23:59'
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (startEndNew && calendarRef?.current && startEnd.start !== startEndNew.start && startEnd.end !== startEndNew.end) {
      const newEvent = {
        id: 'new',
        title: `${t('one_lid.Topshiriq')}: ${dayjs(startEndNew?.start).format('HH:mm')} ${dayjs(startEndNew?.end).format('HH:mm')} `,
        start: dayjs(startEndNew?.start.length > 5 ? '00:00' : startEndNew?.start, 'hh:mm')?.toISOString(),
        end: dayjs(startEndNew?.end.length > 5 ? '00:00' : startEndNew?.end, 'hh:mm')?.toISOString(),
        allDay: startEndNew?.start === '00:00' && startEndNew?.end === '23:59'
      };

      setStarEnd(startEndNew);

      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...data, newEvent]);
      setDataCopy([...data, newEvent]);
    }
    // if (data && data.length > 0) {
    //   setDateValue(dayjs(new Date(data[0]?.start)));
    // }
    // eslint-disable-next-line
  }, [startEndNew, startEnd, data]);

  function handleClick(arg: EventClickArg) {
    if (arg.jsEvent?.ctrlKey) {
      const calendarApi = calendarRef?.current?.getApi();

      // console.log(arg, dataCopy, data);

      const filterData = dataCopy.filter(item => item?.id !== arg?.event?.id);

      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource(filterData);
      setDataCopy(filterData);
    }
  }

  function handleDateClick(arg: DateClickArg) {
    if (arg.allDay) {
      onChangeMPicker(dateValue.format('DD.MM.YYYY'), dayjs().hour(0).minute(0).format('HH:mm'), dayjs().hour(23).minute(59).format('HH:mm'));
      setStarEnd({
        start: dayjs().hour(0).minute(0).format('HH:mm'),
        end: dayjs().hour(23).minute(59).format('HH:mm')
      });
    }
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    if (calendarRef.current) {
      onChangeMPicker(dateValue.format('DD.MM.YYYY'), dayjs(selectInfo.start).format('HH:mm'), dayjs(selectInfo.end).format('HH:mm'));
      setStarEnd({
        start: dayjs(selectInfo?.start).format('HH:mm'),
        end: dayjs(selectInfo?.end).format('HH:mm')
      });
    }
    selectInfo.view.calendar.unselect();

    const newEvent = {
      id: 'new',
      title: `${t('one_lid.Topshiriq')}: ${dayjs(selectInfo?.startStr).format('HH:mm')} ${dayjs(selectInfo?.endStr).format('HH:mm')} `,
      start: selectInfo?.startStr,
      end: selectInfo?.endStr,
      allDay: selectInfo?.allDay
    };

    const calendarApi = calendarRef?.current?.getApi();

    const filterData = dataCopy.filter(item => item?.id !== 'new');

    calendarApi?.removeAllEventSources();
    calendarApi?.addEventSource([...filterData, newEvent]);
    setDataCopy([...filterData, newEvent]);
  }

  function renderEventContent(eventInfo: EventContentArg) {
    return eventInfo.event.allDay ? (
      <div>
        <h1>{t('one_lid.Kun_davomida')}</h1>
      </div>
    ) : (
      <div>
        <p>{`${dayjs(eventInfo?.event.start).format('HH:mm')} ${dayjs(eventInfo?.event.end).format('HH:mm')} `}</p>
        <h1>{t('one_lid.Aloqaga')}</h1>
      </div>
    );
  }

  function handleEventDrop(checkInfo: EventDropArg) {
    if (calendarRef.current) {
      onChangeMPicker(dateValue.format('DD.MM.YYYY'), dayjs(checkInfo.event.start).format('HH:mm'), dayjs(checkInfo.event.end).format('HH:mm'));
      setStarEnd({
        start: dayjs(checkInfo.event.start).format('HH:mm'),
        end: dayjs(checkInfo.event.end).format('HH:mm')
      });
    }
    // checkInfo.event.mutate({
    //   standardProps: {
    //     title: `Topshiriq: ${dayjs(checkInfo?.event.start).format('HH:mm')} ${dayjs(checkInfo?.event.end).format('HH:mm')} `
    //   }
    // });
  }

  function handleEventResize(checkInfo: EventResizeDoneArg) {
    if (calendarRef.current) {
      onChangeMPicker(dateValue.format('DD.MM.YYYY'), dayjs(checkInfo.event.start).format('HH:mm'), dayjs(checkInfo.event.end).format('HH:mm'));
      setStarEnd({
        start: dayjs(checkInfo.event.start).format('HH:mm'),
        end: dayjs(checkInfo.event.end).format('HH:mm')
      });
    }
    checkInfo.event.mutate({
      standardProps: {
        title: `${t('one_lid.Topshiriq')}: ${dayjs(checkInfo?.event.start).format('HH:mm')} ${dayjs(checkInfo?.event.end).format('HH:mm')} `
      }
    });
    // console.log('resize')
  }

  const dateChange = (date: dayjs.Dayjs) => {
    onChangeMPicker(date.format('DD.MM.YYYY'), startEnd.start, startEnd.end);
    setDateValue(date);
  };

  const handleSelectDate = (date: dayjs.Dayjs) => {
    onChangeMPicker(
      date.format('DD.MM.YYYY'),
      dayjs()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')))
        .format('HH:mm'),
      dayjs()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')) + 15)
        .format('HH:mm')
    );
    setDateValue(date);
    const calendarApi = calendarRef?.current?.getApi();
    const newEvent = {
      id: 'new',
      title: `${t('one_lid.Topshiriq')}: ${dayjs()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')))
        .format('HH:mm')} ${dayjs()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')) + 30)
        .format('HH:mm')} `,
      start: dayjs()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')))
        .toISOString(),
      end: dayjs()
        .hour(Number(date.format('HH')))
        .minute(Number(date.format('mm')) + 30)
        .toISOString()
    };

    const filterData = dataCopy.filter(item => item?.id !== 'new');

    calendarApi?.removeAllEventSources();
    calendarApi?.addEventSource([...filterData, newEvent]);
    setDataCopy([...filterData, newEvent]);
  };

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();

    if (dateValue) {
      calendarApi?.gotoDate(dateValue.toISOString());
    }
  }, [calendarRef, dateValue]);

  return (
    <div className="MDatePicker" onClick={e => e.stopPropagation()}>
      <div className="MDatePicker_customDate">
        {Object.values(dateData()).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} onClick={() => handleSelectDate(item.date)}>
            {t(`one_lid.${item.text}`)}
          </div>
        ))}
      </div>
      <div style={{ width: 302 }}>
        <Calendar
          fullscreen={false}
          mode="month"
          value={dateValue}
          locale={
            {
              lang: {
                locale: 'ru-Ru',
                shortWeekDays: weekDays?.map(item => t(`calendar_view.${item}`))
              }
            } as any
          }
          headerRender={() => (
            <div className="MDatePicker_calendar_header">
              <div>
                <DoubleLeftOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }} onClick={() => setDateValue(dateValue.add(-1, 'year'))} />
                <LeftOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }} onClick={() => setDateValue(dateValue.add(-1, 'month'))} />
              </div>
              {t(`boss_payments.${dateValue.format('MMM')}`)} {dateValue.format('YYYY')}
              <div>
                <RightOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }} onClick={() => setDateValue(dateValue.add(1, 'month'))} />
                <DoubleRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'pointer' }} onClick={() => setDateValue(dateValue.add(1, 'year'))} />
              </div>
            </div>
          )}
          onChange={dateChange}
        />
      </div>
      <div style={{ width: 302 }}>
        <FullCalendar
          height="100%"
          eventMinHeight={20}
          ref={calendarRef}
          allDayText={t('calendar_view.Kun_davomida')}
          locale="ru-Ru"
          eventClassNames="m_fc_TimePicker"
          viewClassNames="m_fc_Container"
          slotLaneClassNames="m_fc_Lane"
          slotLabelClassNames="m_fc_Label"
          dayCellClassNames="m_fc_AllDay"
          dragScroll={true}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={false}
          initialView="timeGridDay"
          editable={visibleTime}
          selectable={visibleTime}
          // selectMirror={true}
          progressiveEventRendering
          dayHeaders={false}
          dayMaxEvents={visibleTime}
          allDaySlot={visibleTime}
          slotLabelFormat={[
            {
              hour: '2-digit',
              minute: '2-digit'
            }
          ]}
          initialEvents={dataCopy}
          initialDate={dateValue?.toISOString() || ''}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dateClick={handleDateClick}
          eventClick={handleClick}
        />
      </div>
    </div>
  );
};

export default MDatePicker;
