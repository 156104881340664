import React from 'react';

import styles from './monthSlider.module.scss';
import CaretRightIcon from '@icons/CaretRightIcon';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@utils/constants/format';
import { addMonth, minusMonth } from '@reducers/TaskCalendarViewSlice';
import { useTranslation } from 'react-i18next';

const MonthSlider = () => {
  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // handle add date
  const handleAddDate = () => {
    dispatch(addMonth());
  };

  // handle delete date
  const handleDeleteDate = () => {
    dispatch(minusMonth());
  };

  return (
    <div className={styles.container}>
      <CaretRightIcon onClick={handleDeleteDate} />
      <span className={styles.container_value}>
        {t(`boss_payments.${dayjs(date, DATE_FORMAT).format('MMMM')}`)} {dayjs(date, DATE_FORMAT).format('YYYY')}
      </span>
      <CaretRightIcon onClick={handleAddDate} className={styles.container_right} />
    </div>
  );
};

export default MonthSlider;
