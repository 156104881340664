import React from 'react';

const ArrowUp: React.FC = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.22003 10.5332L7.5667 6.18654C8.08003 5.6732 8.92003 5.6732 9.43336 6.18654L13.78 10.5332"
        stroke="#464255"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowUp;
