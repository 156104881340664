import { Checkbox, Collapse, Drawer, Space } from 'antd';
import React, { FC } from 'react';
import '../../../components/filters/filterDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DatePicker from '../../../service/datePicker';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { SearchParamsMessage } from '../Message';
import { MessageStatus } from '../../../utils/models/MessageList';
import FilterReloadIcon from '../../../assets/icons/FilterReloadIcon';
import ColapseIcon from '../../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../../assets/icons/FIlterColapseIcon';
import { DATE_FORMAT } from '../../../utils/constants/format';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

interface MessageDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const MessageFilterDrawer: FC<MessageDrawerProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, appendMultiple, removeAllSearchParams, remove } = useQueryParam<SearchParamsMessage, any>();

  const { t } = useTranslation();

  const onClose = () => {
    setVisible(false);
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };

  const changeOrderStatus = (value: MessageStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else {
      if (searchParams.statuses === value) {
        remove('statuses', value);
      } else {
        appendMultiple('statuses', value);
      }
    }
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className={'filter_drawer'}>
      <h1>
        <p>{t('home.Filtr')}</p>
        <span onClick={deleteAllFilters}>
          <FilterReloadIcon />
          {t('home.Tozalash')}
        </span>
      </h1>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition={'right'}
        className="filter_drawer_colapse"
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s'
            }}
          >
            <ColapseIcon />
          </span>
        )}
      >
        <Panel
          header={
            <>
              <FIlterColapseIcon />
              {t('home.Sana')}
            </>
          }
          key="6"
        >
          <RangePicker
            format={DATE_FORMAT}
            value={
              searchParams.startDate && searchParams.finishDate
                ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)]
                : [null, null]
            }
            onChange={changeDate}
            className="CustomRangePicker"
            placeholder={['kk.oo.yyyy', 'kk.oo.yyyy']}
          />
        </Panel>
        <Panel
          header={
            <>
              <FIlterColapseIcon />
              {t('home.Status')}
            </>
          }
          key="7"
        >
          <Space direction="vertical">
            <Checkbox
              onChange={() => changeOrderStatus(MessageStatus.WAS_SENT)}
              checked={
                Array.isArray(searchParams.statuses)
                  ? searchParams.statuses.includes(MessageStatus.WAS_SENT)
                  : searchParams.statuses === MessageStatus.WAS_SENT
              }
            >
              {t(`home.${MessageStatus.WAS_SENT}`)}
            </Checkbox>
            <Checkbox
              onChange={() => changeOrderStatus(MessageStatus.NOT_SENT)}
              checked={
                Array.isArray(searchParams.statuses)
                  ? searchParams.statuses.includes(MessageStatus.NOT_SENT)
                  : searchParams.statuses === MessageStatus.NOT_SENT
              }
            >
              {t(`home.${MessageStatus.NOT_SENT}`)}
            </Checkbox>
            <Checkbox
              onChange={() => changeOrderStatus(MessageStatus.WILL_BE_SENT)}
              checked={
                Array.isArray(searchParams.statuses)
                  ? searchParams.statuses.includes(MessageStatus.WILL_BE_SENT)
                  : searchParams.statuses === MessageStatus.WILL_BE_SENT
              }
            >
              {t(`home.${MessageStatus.WILL_BE_SENT}`)}
            </Checkbox>
            <Checkbox
              onChange={() => changeOrderStatus(MessageStatus.WRONG_MESSAGE)}
              checked={
                Array.isArray(searchParams.statuses)
                  ? searchParams.statuses.includes(MessageStatus.WRONG_MESSAGE)
                  : searchParams.statuses === MessageStatus.WRONG_MESSAGE
              }
            >
              {t(`home.${MessageStatus.WRONG_MESSAGE}`)}
            </Checkbox>
            <Checkbox
              onChange={() => changeOrderStatus(MessageStatus.CANCELED)}
              checked={
                Array.isArray(searchParams.statuses)
                  ? searchParams.statuses.includes(MessageStatus.CANCELED)
                  : searchParams.statuses === MessageStatus.CANCELED
              }
            >
              {t(`home.${MessageStatus.CANCELED}`)}
            </Checkbox>
            <Checkbox
              onChange={() => changeOrderStatus(MessageStatus.IS_BEING_SENT)}
              checked={
                Array.isArray(searchParams.statuses)
                  ? searchParams.statuses.includes(MessageStatus.IS_BEING_SENT)
                  : searchParams.statuses === MessageStatus.IS_BEING_SENT
              }
            >
              {t(`home.${MessageStatus.IS_BEING_SENT}`)}
            </Checkbox>
          </Space>
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default MessageFilterDrawer;
