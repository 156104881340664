import React, { useEffect, useRef, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMobileContractPaymentDelay, useMobileHouse, useMobilePaymentDelay } from '../../../queries/queries';
import { colorPieChart } from '../../../utils/constants/colorPieChart';
import { colorGenerator } from '../../../utils/helper/colorGenerator';
import { PaymentDelayProps } from '../../../utils/models/BossPaymentDelay';
import { PieChartData } from '../../../utils/models/PieChartData';

import PaymentDelay from './PaymentDelay';

import styles from './bossContractGraphic.module.scss';

const BossContractGraphic = () => {
  const [page, setPage] = useState(1);
  const [dataCopy, setDataCopy] = useState<PaymentDelayProps[]>([]);
  const [active, setActive] = useState(['']);
  const [allHouseBtn, setAllHouseBtn] = useState(true);
  const [getIntervalId, setGetIntervalId] = useState('');
  const [dataForChart, setDataForChart] = useState<PieChartData[]>([]);

  const container = useRef<any>(null);

  const { t } = useTranslation();

  const { data } = useMobileHouse();

  const { data: paymentDelayData, isLoading, isFetching } = useMobilePaymentDelay(active);

  const { data: PaymentDeleyData, isLoading: PaymentDeleyDataLoading } = useMobileContractPaymentDelay(getIntervalId, page, active);

  // onclick houses in the list
  const handleClick = (id: number) => {
    setPage(0);
    setDataCopy([]);
    setGetIntervalId('');
    if (active.includes(String(id))) {
      setActive(e => [...e.filter(i => i !== String(id))]);
    } else {
      setActive(e => [...e, String(id)]);
    }
  };

  useEffect(() => {
    if (paymentDelayData) {
      const newChartData: PieChartData[] = [];

      paymentDelayData.forEach((item, index) => {
        let color = colorPieChart[index];

        if (index > 8) {
          color = `#${colorGenerator()}`;
        }
        newChartData.push({
          id: String(item.interval),
          label: `${t('boss_contract.Har')} ${item.interval} ${t('boss_contract.oyda')}`,
          value: item.count,
          color
        });
      });
      setDataForChart(newChartData);
    }
  }, [paymentDelayData]);

  // switch case of all btn in the house list
  useEffect(() => {
    if (data?.length === active.length || active.length <= 1) {
      setAllHouseBtn(true);
    } else {
      setAllHouseBtn(false);
    }
  }, [active]);

  // clearing chosen all list houses when all btn clicked
  const activeAll = () => {
    setActive(['']);
  };

  // send request to database to get contract list
  const getInterval = (interval: number) => {
    setGetIntervalId(String(interval));
    setPage(1);
    setDataCopy([]);
  };

  // total number of customers, header of pie chart
  const reducedCustomers = () => {
    let countAll = 0;

    paymentDelayData?.forEach(item => {
      countAll += item.count;
    });
    return countAll;
  };

  // infinite scroll, end of the contracts when loaded all
  const onScroll = () => {
    let pageCopy = page;

    if (page !== PaymentDeleyData?.totalPages || !(page >= PaymentDeleyData?.totalPages)) {
      if (
        container.current.offsetHeight + container.current.scrollTop >= container.current.scrollHeight &&
        container.current.scrollHeight > container.current.offsetHeight
      ) {
        // eslint-disable-next-line no-plusplus
        pageCopy++;
        setPage(pageCopy);
      }
    }
  };

  useEffect(() => {
    if (PaymentDeleyData) {
      setDataCopy(dataCopy.concat(PaymentDeleyData.data));
    }
  }, [PaymentDeleyData]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container} ref={container} onScroll={onScroll}>
        <div className={styles.container_header}>
          <h1>{t('boss_contract.Grafiklar_boyicha_tolov')}</h1>
          <div className={styles.line} />
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_all}>
            <button onClick={activeAll} className={allHouseBtn ? styles.active_btn : ''}>
              {t('boss_contract.Barchasi')}
            </button>
            {data?.map(item => (
              <button className={active.includes(String(item.id)) ? styles.active_btn : ''} onClick={() => handleClick(item.id)} key={item.id}>
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.container_bar_header}>
          <p>{t('boss_contract.Tolov_qiluvchilar_umumiy_soni')}</p>
          <h1>
            {reducedCustomers()} {t('boss_contract.ta_mijoz')}
          </h1>
        </div>
        <div className={styles.container_barchart}>
          <ResponsivePie
            data={dataForChart}
            margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
            innerRadius={0.65}
            cornerRadius={3}
            activeOuterRadiusOffset={16}
            colors={{ datum: 'data.color' }}
            borderColor={{
              from: 'color',
              modifiers: [['opacity', 0.2]]
            }}
            enableArcLinkLabels={false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={false}
            arcLabel="value"
            arcLabelsRadiusOffset={0.3}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]]
            }}
            tooltip={({ datum: { value, label } }) => (
              <div
                style={{
                  padding: 12,
                  color: '#fff',
                  background: '#222222',
                  borderRadius: 8
                }}
              >
                <strong>
                  {label}: {value}
                </strong>
              </div>
            )}
            theme={{
              tooltip: {
                container: {
                  background: '#fff'
                }
              }
            }}
          />
        </div>
        <div className={styles.container_every_x_month}>
          {dataForChart?.map(item => (
            <div className={styles.body} key={item.id}>
              <div
                className={getIntervalId === String(item.id) ? styles.body_cont : ''}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  getIntervalId === String(item?.id) ? getInterval(-1) : getInterval(Number(item.id));
                }}
              >
                <div className={styles.txt}>
                  <div style={{ background: item.color }} className={styles.color} />
                  <h2>{item.label}</h2>
                </div>
                <div className={styles.quantity}>
                  <h2>{item.value}</h2>
                </div>
              </div>
            </div>
          ))}
          {getIntervalId !== String(-1) && <div className={styles.liner} />}
        </div>
        {getIntervalId && <PaymentDelay dataCopy={dataCopy} interval={getIntervalId} isLoading={PaymentDeleyDataLoading} />}
      </div>
    </Spin>
  );
};

export default BossContractGraphic;
