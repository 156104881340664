import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { LicenseSMSType } from "../utils/model/LicenseSMSType";

export function useGetLicenseSMS() {
    return useQuery<LicenseSMSType>({
        queryKey: [queryKeys.GET_SMS_LICENSE],
        queryFn: async () => {
            const res = await $api.get(endPoints.GET_SMS_LICENSE);

            return res.data.data;
        },
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false
    });
}
