import { Link } from 'react-router-dom';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { tableExamples } from '@utils/constants/tableExamples';
import { textCapitalize } from '@utils/helper/textConverter';
import { encodeString } from '@utils/index';
import { TableConfig } from '@utils/models/TableConfig';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'global';
import { useTranslation } from 'react-i18next';

import { eventListModels, useAction } from 'modules/event-list';

import DotsIcon from '../assets/DotsIcon';

const ClientTable = () => {
  const { data, query, setQuery, dataIsLoading, tableConfig, activityLogs } = useAction();
  const { t } = useTranslation();

  const actionTypeClientObject = activityLogs?.actionTypeClient?.reduce<Record<string, string>>((p, c) => {
    p[c?.name] = c[`name${textCapitalize(i18n.language)}` as keyof typeof c]?.toString().replace(/%s/gi, '') || '';

    return p;
  }, {});

  const objectLog = {
    CONTRACT: (record: eventListModels.IActivityLog) => <Link to={`/main/contract/${record?.contractId}/${record?.contractNumber}`}>{record?.objectLog}</Link>,
    BOOKING: (record: eventListModels.IActivityLog) => (
      <Link to={`/main/order/${record?.bookingId}/${record?.bookingNumber}?company=${btoa(String(record?.bookingCompanyId))}`}>{record?.objectLog}</Link>
    ),
    CLIENT: (record: eventListModels.IActivityLog) => <Link to={`/main/client/${record?.clientId}/${encodeString(record?.name)}`}>{record?.objectLog}</Link>,
    LEAD: (record: eventListModels.IActivityLog) => <Link to={`/main/crm/${record?.leadId}/${encodeString(record?.leadName)}`}>{record?.objectLog}</Link>
  };

  const currentObject = {
    CREATE_PAYMENT_BOOKING: (record: eventListModels.IActivityLog) => `${Number(record?.current).toLocaleString('ru')} ${record?.previous}`,
    PREPAYMENT_BOOKING: (record: eventListModels.IActivityLog) => `${Number(record?.current).toLocaleString('ru')} ${record?.previous}`,
    INCOME_BALANCE_BOOKING: (record: eventListModels.IActivityLog) => `${Number(record?.current).toLocaleString('ru')} ${record?.previous}`,
    CANCEL_PAYMENT_BOOKING: (record: eventListModels.IActivityLog) => `${Number(record?.current).toLocaleString('ru')} ${record?.previous}`,
    CREATE_PAYMENT: (record: eventListModels.IActivityLog) => `${Number(record?.current).toLocaleString('ru')} ${record?.previous}`,
    CANCEL_PAYMENT: (record: eventListModels.IActivityLog) => `${Number(record?.current).toLocaleString('ru')} ${record?.previous}`
  };

  const previousObject = {
    CREATE_PAYMENT_BOOKING: (record: eventListModels.IActivityLog) => ``,
    PREPAYMENT_BOOKING: (record: eventListModels.IActivityLog) => ``,
    INCOME_BALANCE_BOOKING: (record: eventListModels.IActivityLog) => ``,
    CANCEL_PAYMENT_BOOKING: (record: eventListModels.IActivityLog) => ``,
    CREATE_PAYMENT: (record: eventListModels.IActivityLog) => ``,
    CANCEL_PAYMENT: (record: eventListModels.IActivityLog) => ``
  };

  const getEventObj = (type: string) => {
    if (actionTypeClientObject && type in actionTypeClientObject) {
      return actionTypeClientObject[type as keyof typeof actionTypeClientObject];
    }
    return type;
  };

  const fc = (type: string, current: any, objectId?: any) => current;

  const columns: ColumnsType<eventListModels.IActivityLog> | undefined = [
    {
      key: 1,
      align: 'left',
      title: t(`home.Sana`),
      render: (date: eventListModels.IActivityLog) => <span>{date?.createdDate}</span>,
      width: 200
    },
    {
      title: t(`home.Xodim`),
      key: 2,
      dataIndex: 'responsibleBy',
      width: 250
    },
    {
      title: t(`home.obyekt`),
      key: 3,
      dataIndex: 'objectLog',
      width: 120,
      render(value, record, index) {
        return value in objectLog ? objectLog[value as keyof typeof objectLog](record) : <span>{value}</span>;
      }
    },
    {
      title: t(`home.Nomi`),
      key: 4,
      dataIndex: 'name',
      render: (value, record) => <Link to={`${rootPaths.MAIN + MainPaths.CLIENT}/${record?.clientId}/${encodeString(record?.name)}`}>{value}</Link>,
      width: 300
    },
    {
      title: t(`home.event`),
      key: 5,
      render: (event: eventListModels.IActivityLog) => <span>{getEventObj(event?.actionType)}</span>,
      width: 300
    },
    {
      title: t(`home.Korsatkichdan_oldin`),
      key: 6,
      render: (previus: eventListModels.IActivityLog) =>
        previus?.actionType in previousObject ? previousObject[previus.actionType as keyof typeof previousObject](previus) : previus?.previous,
      width: 350
    },
    {
      title: t(`home.Korsatkichdan_keyin`),
      key: 7,
      render: (current: eventListModels.IActivityLog) =>
        current?.actionType in currentObject ? currentObject[current.actionType as keyof typeof currentObject](current) : current?.current,
      width: 350
    },
    {
      title: '',
      key: 8,
      dataIndex: 'actions',
      render: () => (
        <span
        //  className={styles.actions}
        >
          <DotsIcon />
        </span>
      ),
      width: 80
    }
  ];

  const hasChildren = (item: TableConfig, column: any) => ({
    ...column,
    children: column?.children
      ? column?.children
          ?.map((child: any) => ({
            ...(item.values?.some(val => val?.name === child?.name && val?.checked) && child)
          }))
          .filter((item: any) => item?.name)
      : []
  });

  const columnsSort = (tableConfig?.config && tableConfig?.config?.length > 0 ? tableConfig?.config : tableExamples['activity-logs'])
    ?.map((item: TableConfig) => ({
      ...(item.checked &&
        hasChildren(
          item,
          columns?.find((col: any) => col?.key === item?.id)
        ))
    }))
    .filter((item: { title: any }) => item?.title);

  return (
    <div>
      <div>
        <Table
          columns={columnsSort}
          dataSource={data?.data}
          className="tableNewStyle"
          pagination={{
            className: 'tableNewStyle_pagination',
            prevIcon: (
              <div className="tableNewStyle_pagination_prev">
                <ArrowLeftIcon /> {t('my_calls.Ortga')}
              </div>
            ),
            onChange(page) {
              //   changePage(page, 18);
              setQuery({
                page
              });
            },
            pageSize: query?.size,
            total: data?.totalElements,
            current: query?.page,
            nextIcon: (
              <div className="tableNewStyle_pagination_next">
                {t('crm_ins.Keyingisi')} <ArrowRightIcon />
              </div>
            )
          }}
          scroll={{ y: `calc(100vh - 280px` }}
          loading={dataIsLoading}
        />
      </div>
    </div>
  );
};

export default ClientTable;
