export interface TelegramAndWebProps {
  companyId: number;
  house: {
    id: number;
    name: string;
  };
  id: number;
  pipe: {
    id: number;
    name: string;
  };
  tawType: TawType;
  status: boolean;
}

export enum TawType {
  HOUSE = 'HOUSE',
  MARKET_PLACE = 'MARKET_PLACE',
  OTHER = 'OTHER'
}
