export enum filterEnum {
  STATUS = 'STATUS',
  FLOOR = 'FLOOR',
  ROOMS = 'ROOMS'
}

export const filterLabels: Record<filterEnum, string> = {
  [filterEnum.STATUS]: 'STATUS',
  [filterEnum.FLOOR]: 'FLOOR',
  [filterEnum.ROOMS]: 'ROOMS'
};
