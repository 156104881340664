import React, { useEffect,useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMobileHouse, useMobileHouseContract } from '../../../queries/queries';

import ContractPeriodInfo from './ContractPeriodInfo';

import call from '../../../assets/img/call-icon.png';
import clock from '../../../assets/img/clock.png';
import document from '../../../assets/img/document-text.png';

import styles from './bossContractPeriod.module.scss';

const { Panel } = Collapse;

const BossContractPeriod = () => {
  const { data } = useMobileHouse();
  const [active, setActive] = useState([0]);
  const [allHouseBtn, setAllHouseBtn] = useState(true);
  const [showResult, setShowResult] = useState({
    isOpen: false,
    index: 0
  });
  const { data: contractData, isFetching, isLoading } = useMobileHouseContract(active);
  const [contract, setContract] = useState({ '0': 0, '24': 0, '60': 0 });

  const { t } = useTranslation();

  // sum of all contracts 0-24, 24-60, 60<
  useEffect(() => {
    if (contractData) {
      const chunk: any = { '0': 0, '24': 0, '60': 0 };
      const array = Object.keys(contractData);

      array.forEach(e => {
        if (Number(e) <= 24) {
          chunk['0'] += contractData[String(e)];
        }
        if (Number(e) > 24 && Number(e) <= 60) {
          chunk['24'] += contractData[String(e)];
        }
        if (Number(e) > 60) {
          chunk['60'] += contractData[String(e)];
        }
      });
      setContract(chunk);
    }
  }, [contractData]);

  // onclick houses in the list
  const handleClick = (id: number) => {
    if (active.includes(id)) {
      setActive(e => [...e.filter(i => i !== id)]);
    } else {
      setActive(e => [...e, id]);
    }
  };

  // switch case of all btn in the house list
  useEffect(() => {
    if (data?.length === active.length || active.length <= 1) {
      setAllHouseBtn(true);
    } else {
      setAllHouseBtn(false);
    }
  }, [active]);

  // clearing chosen all list houses when all btn clicked
  const activeAll = () => {
    setActive([0]);
  };

  // show hide function for 3 buttons
  const showContracts = (index: number) => {
    if (showResult.index === index) {
      setShowResult({ isOpen: false, index: 0 });
    } else {
      setShowResult({ isOpen: true, index });
    }
  };

  // is open collapse
  const isOpenCollapse = (index: number) => showResult.isOpen && showResult.index === index;

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h1>{t('boss_contract.Shartnoma_muddati')}</h1>
          <div className={styles.line} />
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_all}>
            <button onClick={activeAll} className={allHouseBtn ? styles.active_btn : ''}>
              {t('boss_contract.Barchasi')}
            </button>
            {data?.map(item => (
                <button className={active.includes(item.id) ? styles.active_btn : ''} onClick={() => handleClick(item.id)} key={item.id}>
                  {item.name}
                </button>
              ))}
          </div>
          <div className={styles.container_body_contracts}>
            <div className={`${styles.red} ${styles.first}`} onClick={() => showContracts(1)}>
              <div className={`${styles.month} ${styles.first_month}`}>
                <img src={clock} alt="clock" />
                <p>0-24 {t('boss_contract.oy')}</p>
                <div className={styles.hr} />
                <img src={document} alt="document" />
                <p>
                  {contract['0']} {t('boss_contract.shartnoma')}
                </p>
              </div>
              <div className={styles.btn_right}>
                <button className={contract['0'] ? (isOpenCollapse(1) ? styles.show_btn : '') : ''}>{'>'}</button>
              </div>
            </div>
            {isOpenCollapse(1) ? (
              <Collapse
                accordion
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
                className={styles.site_collapse_custom_collapse}
              >
                {contractData &&
                  Object.keys(contractData)?.map((item: string, index: number) => Number(item) <= 24 ? (
                      <Panel
                        header={
                          <>
                            <img src={call} alt="call" />
                            <p style={{ color: '#CC413A' }}>{item}</p>
                            <p>{t('boss_contract.oylik_shartnomalar')}</p>
                            <div className={styles.header_contract}>
                              <p>{contractData && Object.values(contractData)[index]}</p>
                            </div>
                          </>
                        }
                        key={Math.random()}
                        className={styles.site_collapse_custom_panel}
                      >
                        <ContractPeriodInfo houses={active} delay={Number(item)} count={(Object.values(contractData)[index] as number) || 10} />
                      </Panel>
                    ) : null)}
              </Collapse>
            ) : (
              ''
            )}
            <div className={`${styles.red} ${styles.second}`} onClick={() => showContracts(2)}>
              <div className={`${styles.month} ${styles.second_month}`}>
                <img src={clock} alt="clock" />
                <p>24-60 {t('boss_contract.oy')}</p>
                <div className={styles.hr} />
                <img src={document} alt="document" />
                <p>
                  {contract['24']} {t('boss_contract.shartnoma')}
                </p>
              </div>
              <div className={styles.btn_right}>
                <button className={contract['24'] ? (isOpenCollapse(2) ? styles.show_btn : '') : ''}>{'>'}</button>
              </div>
            </div>
            {isOpenCollapse(2) ? (
              <Collapse
                accordion
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
                className={styles.site_collapse_custom_collapse}
              >
                {contractData &&
                  Object.keys(contractData)?.map((item: string, index: number) => Number(item) > 24 && Number(item) <= 60 ? (
                      <Panel
                        header={
                          <>
                            <img src={call} alt="call" />
                            <p style={{ color: '#CC413A' }}>{item}</p>
                            <p>{t('boss_contract.oylik_shartnomalar')}</p>
                            <div className={styles.header_contract}>
                              <p>{contractData && Object.values(contractData)[index]}</p>
                            </div>
                          </>
                        }
                        key={Math.random()}
                        className={styles.site_collapse_custom_panel}
                      >
                        <ContractPeriodInfo houses={active} delay={Number(item)} count={(Object.values(contractData)[index] as number) || 10} />
                      </Panel>
                    ) : null)}
              </Collapse>
            ) : (
              ''
            )}
            <div className={`${styles.red} ${styles.third}`} onClick={() => showContracts(3)}>
              <div className={`${styles.month} ${styles.third_month}`}>
                <img src={clock} alt="clock" />
                <p>
                  {'>60'} {t('boss_contract.oy')}
                </p>
                <div className={styles.hr} />
                <img src={document} alt="document" />
                <p>
                  {contract['60']} {t('boss_contract.shartnoma')}
                </p>
              </div>
              <div className={styles.btn_right}>
                <button className={contract['60'] ? (isOpenCollapse(3) ? styles.show_btn : '') : ''}>{'>'}</button>
              </div>
            </div>
            {isOpenCollapse(3) ? (
              <Collapse
                accordion
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
                className={styles.site_collapse_custom_collapse}
              >
                {contractData &&
                  Object.keys(contractData)?.map((item: string, index: number) => Number(item) > 60 ? (
                      <Panel
                        header={
                          <>
                            <img src={call} alt="call" />
                            <p style={{ color: '#CC413A' }}>{item}</p>
                            <p>{t('boss_contract.oylik_shartnomalar')}</p>
                            <div className={styles.header_contract}>
                              <p>{contractData && Object.values(contractData)[index]}</p>
                            </div>
                          </>
                        }
                        key={Math.random()}
                        className={styles.site_collapse_custom_panel}
                      >
                        <ContractPeriodInfo houses={active} delay={Number(item)} count={(Object.values(contractData)[index] as number) || 10} />
                      </Panel>
                    ) : null)}
              </Collapse>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default BossContractPeriod;
