import React, { FC, useState } from 'react';
import { Button, Checkbox, Modal, Select } from 'antd';
import styles from './expireDate.module.scss';
import AlertIcon from '../../assets/icons/expire-date/AlertIcon';
import { useTranslation } from 'react-i18next';
import { useExtraDay } from '../../queries/mutation';
interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setVisibleContainer: (visible: { container: boolean; child: boolean }) => void;
  expireDate: number;
}

const ExpireDateChangeModal: FC<IProps> = ({ setVisible, setVisibleContainer, visible, expireDate }) => {
  const [changeDate, setChangeDate] = useState(1);
  const [agree, setAgree] = useState(false);
  const { t } = useTranslation();
  const extraDayMutate = useExtraDay();

  const handleClose = () => {
    setVisible(false);
    setVisibleContainer({ child: false, container: true });
  };

  const handleSubmit = () => {
    extraDayMutate?.mutateAsync(changeDate)?.then(() => {
      handleClose();
    });
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleClose}
        title={false}
        wrapClassName={`${styles.modal}`}
        className="expireDateModalDay"
        width={440}
        footer={false}
      >
        <div className={styles.alert}>
          <AlertIcon />
        </div>
        <h1 className={styles.title}>Litsenziya muddatini o’zgartirish</h1>
        <p className={styles.description}>
          Siz ma’lum muddatga qarzga litsenziyani uzaytirishni so'ramoqdasiz. Eslatib o'tamiz, muddat yakunlangandan so'ng hisobingiz qayta bloklanadi
        </p>

        <div className={styles.changeDate}>
          <label htmlFor="">Uzaytirish muddati</label>
          <Select className="customSelect" value={changeDate} onChange={setChangeDate}>
            {Array.from({ length: 7 + expireDate })?.map((_, i) => (
              <Select.Option value={i + 1} key={i}>
                {i + 1} {t('one_lid.kun')}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.terms}>
          <h1>Shartlar:</h1>
          <ol>
            <li>Kreditning maksimal muddati - {changeDate} kun</li>
            <li>Muddati tugashi bilan qarz oluvchi berilgan kreditni to'lash majburiyatini oladi.</li>
            <li>Kredit berish domen yoki boshqa xizmatlar emas, balki faqat xostingni yangilash sharti bilan amalga oshiriladi.</li>
            <li>Kredit berish dastlabki xosting tartibiga emas, balki faqat uning yangilanishiga bog'liq.</li>
            <li>Xostingni yangilash uchun kredit olgan mijoz, avvalgisi to'liq qaytarilmaguncha boshqa kredit ololmaydi.</li>
          </ol>
        </div>
        <Checkbox className={styles.agreeCheckbox} checked={agree} onChange={e => setAgree(e?.target?.checked)}>
          Yuqoridagi barcha shartlarga roziman
        </Checkbox>
        <div className={styles.footer}>
          <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
          <Button type="primary" loading={extraDayMutate.isLoading} disabled={!agree} onClick={handleSubmit}>
            {t('home_id.Tasdiqlash')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ExpireDateChangeModal;
