import React from 'react';

const UysotLogo = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5858 0.880708C12.3668 0.0996597 13.6332 0.0996597 14.4142 0.880708L25.4142 11.8807C26.1953 12.6618 26.1953 13.9281 25.4142 14.7091C24.6332 15.4902 23.3668 15.4902 22.5858 14.7091L13 5.12335L3.41421 14.7091C2.63316 15.4902 1.36683 15.4902 0.585786 14.7091C-0.195262 13.9281 -0.195262 12.6618 0.585786 11.8807L11.5858 0.880708ZM3.23529 21.7067C3.23529 20.6021 4.13072 19.7067 5.23529 19.7067H20.7647C21.8693 19.7067 22.7647 20.6021 22.7647 21.7067C22.7647 22.8113 21.8693 23.7067 20.7647 23.7067H5.23529C4.13072 23.7067 3.23529 22.8113 3.23529 21.7067ZM13 16.5302C14.4294 16.5302 15.5882 15.3714 15.5882 13.942C15.5882 12.5125 14.4294 11.3537 13 11.3537C11.5706 11.3537 10.4118 12.5125 10.4118 13.942C10.4118 15.3714 11.5706 16.5302 13 16.5302Z"
        fill="white"
      />
    </svg>
  );
};

export default UysotLogo;
