import React from 'react';

import styles from './activePipe.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  color: string;
  day: number;
  percent: number;
  percentDate: number;
  percentBorder: number;
  percentLine: number;
  percentSpace: number;
  name: string;
  countLead: number;
}

const ActivePipe: React.FC<Props> = ({ percentBorder, percentLine, percent, percentDate, day, color, percentSpace, countLead, name }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.container_left}>
        <div
          style={{
            width: `${percentSpace}%`
          }}
        />
        <div
          className={styles.container_left_date_color}
          style={{
            width: `${percentDate}%`,
            borderBottomColor: color,
            borderBottomWidth: percentDate > 0 ? '4px' : 0
          }}
        />
        <div
          className={styles.container_left_border}
          style={{
            width: `calc(${percentBorder}% - 50px)`
          }}
        >
          {day} {t('triggers.kun')}
        </div>
        <div className={styles.container_left_percent}>{percent}%</div>
        <div
          style={{
            width: `${percentLine}%`
          }}
          className={styles.container_left_line}
        >
          <div
            style={{
              backgroundColor: color
            }}
          />
        </div>
      </div>
      <div className={styles.container_right}>
        <div className={styles.container_right_name}>{name}</div>
        <div className={styles.container_right_info}>
          <span className={styles.container_right_info_number}>{countLead}</span>
          <span className={styles.container_right_info_label}>{t('boss_crm.bitim')}</span>
        </div>
      </div>
    </div>
  );
};

export default ActivePipe;
