import React, { FC } from 'react';
import CloseDublicateIcon from '@icons/CloseDublicateIcon';
import i18n from '@service/I18nService';
import { encodeString } from '@utils/helper';
import { Checkbox, Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { useCrmFields } from '../../../../../../queries/queries';
import { MainPaths } from '../../../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../../../routing/rootPaths';
import { BaseEntity } from '../../../../../../utils/models/BaseEntity';
import { ContactCrm, FieldValue, OneLid } from '../../../../../../utils/models/OneLid';

import styles from './dublicateControllModal.module.scss';

interface IProps extends OneLid {
  dublicate: OneLid | undefined;
  setDublicate: React.Dispatch<React.SetStateAction<OneLid | undefined>>;
  setPropsData?: React.Dispatch<React.SetStateAction<OneLid[] | undefined>>;
  data: OneLid[] | undefined;
  fieldsArr: FieldValue[];
  keyObj: string;
  index: number;
  from?: string;
}

const DublicateControllField: FC<IProps> = props => {
  const { t } = useTranslation();
  const { push } = useQueryParam();
  const { data: crmFields } = useCrmFields();
  const crmFieldsConvert = crmFields?.filter(item => item?.infoType === 'LED');

  const changeContact = (value: ContactCrm) => {
    if (props?.dublicate?.contacts?.some(item => item?.id === value?.id)) {
      props.setDublicate(prev => ({
        ...prev!,
        contacts: prev?.contacts?.filter(c => c?.id !== value?.id)!
      }));
    } else {
      props.setDublicate(prev => ({
        ...prev!,
        contacts: [...prev?.contacts!, value]
      }));
    }
  };

  const changeTags = (value: BaseEntity) => {
    if (props?.dublicate?.tags?.some(item => item?.id === value?.id)) {
      props.setDublicate(prev => ({
        ...prev!,
        tags: prev?.tags?.filter(c => c?.id !== value?.id)!
      }));
    } else {
      props.setDublicate(prev => ({
        ...prev!,
        tags: [...prev?.tags!, value]
      }));
    }
  };

  const changeField = (value: FieldValue) => {
    const fields = props.dublicate?.fields;

    if (fields?.some(item => item?.id === value?.id)) {
      if (fields?.some(item => item?.value === value?.value)) {
        props?.setDublicate(prev => {
          const fieldsCopy = prev?.fields?.filter(item => item?.id !== value?.id)!;

          return {
            ...prev!,
            fields: fieldsCopy
          };
        });
      } else {
        props?.setDublicate(prev => ({
          ...prev!,
          fields: [
            ...prev?.fields?.map(item => ({
              ...item!,
              ...(item?.id === value.id && value)
            }))!
          ]
        }));
      }
      if (fields?.some(item => item?.valueList === value?.valueList)) {
        props?.setDublicate(prev => {
          const fieldsCopy = prev?.fields?.filter(item => item?.id !== value?.id)!;

          return {
            ...prev!,
            fields: fieldsCopy
          };
        });
      } else {
        props?.setDublicate(prev => ({
          ...prev!,
          fields: [
            ...prev?.fields?.map(item => ({
              ...item!,
              ...(item?.id === value.id && value)
            }))!
          ]
        }));
      }
    } else {
      props?.setDublicate(prev => ({
        ...prev!,
        fields: [...prev?.fields!, value]
      }));
    }
  };

  const hasItem = (key: keyof typeof props) => props?.data?.every(item => item[key as keyof typeof item] === props[key as keyof typeof props]);
  const convertLocation = (value: string) => {
    const obj = value && value.includes('}') && value.includes('{') ? JSON.parse(value) : {};

    return {
      lat: obj?.lat,
      lng: obj?.lng,
      radius: obj?.radius,
      name: obj?.name || value || ''
    };
  };
  const convertAddress = (value: string) => {
    const obj = value && value.includes('{') && value.includes('}') ? JSON.parse(value) : '';

    return {
      city: obj[`city_${i18n.language}`] ? obj[`city_${i18n.language}`] : '',
      region: obj[`region_${i18n.language}`] ? obj[`region_${i18n.language}`] : '',
      country: obj[`country_${i18n.language}`] ? obj[`country_${i18n.language}`] : ''
    };
  };

  const filedValue = (id: number, value: string, valueList: string[] | undefined) => {
    const FIELD_TYPE = crmFieldsConvert?.find(item => item?.id === id)?.type;

    if (FIELD_TYPE === 'LOCATION') {
      return convertLocation(value)?.name?.length > 30 ? `${convertLocation(value)?.name.slice(0, 30)}...` : convertLocation(value)?.name;
    }
    if (FIELD_TYPE === 'ADDRESS' && value) {
      return `${convertAddress(value)?.country}, ${convertAddress(value)?.region}, ${convertAddress(value).city}`;
    }
    if (FIELD_TYPE === 'MULTI_SELECT') {
      return valueList?.map(prev => ` ${prev},`);
    }
    if (FIELD_TYPE) {
      return value;
    }
    return null;
  };

  const objKeys = {
    name: (item: OneLid) =>
      hasItem('id') ? (
        <p className={styles.field_name}>{item.name}</p>
      ) : (
        <Radio
          className={styles.field_createBy}
          checked={props?.dublicate?.id === item?.id}
          onChange={e =>
            props?.setDublicate(prev => ({
              ...prev!,
              id: item?.id,
              name: item?.name
            }))
          }
        >
          {item?.name}
        </Radio>
      ),
    pipeStatusResponse: (item: OneLid) =>
      props?.data?.every(el => el?.pipeStatusResponse?.id === item?.pipeStatusResponse?.id) ? (
        <div className={styles.field_pipe}>
          {item?.pipeStatusResponse?.pipeName}{' '}
          <span className={styles.field_pipe_status} style={{ background: item?.pipeStatusResponse?.color }}>
            {item?.pipeStatusResponse?.name}
          </span>
        </div>
      ) : (
        <Radio
          checked={props?.dublicate?.pipeStatusResponse?.id === item?.pipeStatusResponse?.id}
          onChange={e =>
            props?.setDublicate(prev => ({
              ...prev!,
              pipeStatusResponse: item?.pipeStatusResponse
            }))
          }
        >
          <div className={styles.field_pipe}>
            {item?.pipeStatusResponse?.pipeName}{' '}
            <span className={styles.field_pipe_status} style={{ background: item?.pipeStatusResponse?.color }}>
              {item?.pipeStatusResponse?.name}
            </span>
          </div>
        </Radio>
      ),
    balance: (item: OneLid) =>
      hasItem('balance') ? (
        <p className={styles.field_createBy}>{item?.balance?.toLocaleString('ru')}</p>
      ) : (
        <Radio
          className={styles.field_createBy}
          checked={props?.dublicate?.balance === item?.balance}
          onChange={e =>
            props?.setDublicate(prev => ({
              ...prev!,
              balance: item?.balance
            }))
          }
        >
          {item?.balance?.toLocaleString('ru')}
        </Radio>
      ),
    responsibleById: (item: OneLid) =>
      // eslint-disable-next-line no-nested-ternary
      hasItem('responsibleById') ? (
        <p className={styles.field_createBy}>{item?.responsibleBy}</p>
      ) : item?.responsibleBy ? (
        <Radio
          className={styles.field_createBy}
          checked={props?.dublicate?.responsibleById === item?.responsibleById}
          onChange={e =>
            props?.setDublicate(prev => ({
              ...prev!,
              responsibleBy: item?.responsibleBy,
              responsibleById: item?.responsibleById
            }))
          }
        >
          {item?.responsibleBy}
        </Radio>
      ) : null,

    createdDate: (item: OneLid) =>
      hasItem('createdDate') ? (
        <p className={styles.field_createDate}>{item?.createdDate}</p>
      ) : (
        <Radio
          className={styles.field_createDate}
          checked={props?.dublicate?.createdDate === item?.createdDate}
          onChange={e =>
            props?.setDublicate(prev => ({
              ...prev!,
              createdDate: item?.createdDate
            }))
          }
        >
          {item?.createdDate}
        </Radio>
      ),
    contacts: (item: OneLid) =>
      item?.contacts && item?.contacts?.length > 0
        ? item?.contacts?.map(contact => (
          <Checkbox
            key={contact?.id}
            value={contact?.id}
            className={styles.field_contact}
            checked={props?.dublicate?.contacts?.some(c => c?.id === contact?.id)}
            onChange={() => changeContact(contact)}
          >
            <h3>{contact?.name}</h3> {contact?.phones?.map((phone, i) => <p key={i}>{phone}</p>)}
          </Checkbox>
        ))
        : undefined,
    tags: (item: OneLid) =>
      item?.tags && item?.tags?.length > 0 ? (
        <div className={styles.tags}>
          {item?.tags?.map(tag => (
            <Checkbox key={tag?.id} checked={props?.dublicate?.tags?.some(item => item?.id === tag?.id)} onChange={() => changeTags(tag)}>
              {tag?.name}
            </Checkbox>
          ))}
        </div>
      ) : undefined,
    fields: (item: OneLid) =>
      item?.fields && item?.fields?.length > 0
        ? item?.fields?.map(field => (
          <Checkbox
            checked={props?.dublicate?.fields?.some(item => item?.id === field?.id && item?.value === field.value && item?.valueList === field?.valueList)}
            key={field?.id}
            onClick={e => changeField(field)}
          >
            {filedValue(field?.id, field?.value!, field?.valueList!)}
          </Checkbox>
        ))
        : undefined
  };

  const objKeysFC = (item: OneLid) => Object.keys(objKeys).includes(props.keyObj) && objKeys[props.keyObj as keyof typeof objKeys](item);

  const allChecked = (item: OneLid) => {
    props.setDublicate(prev => ({
      ...prev!,
      id: item?.id,
      balance: item?.balance,
      createdDate: item?.createdDate,
      responsibleById: item?.responsibleById,
      responsibleBy: item?.responsibleBy,
      name: item?.name,
      pipeStatusResponse: item?.pipeStatusResponse
    }));
  };

  const goLead = (item: OneLid) => {
    push(`${rootPaths.MAIN + MainPaths.CRM}/${item?.id}/${encodeString(item?.name)}`);
  };

  // eslint-disable-next-line no-nested-ternary
  return props.data?.some(item => objKeysFC(item)) ? (
    props?.keyObj === 'fields' ? (
      <>
        {props?.fieldsArr?.map(
          field =>
            crmFieldsConvert?.some(item => item?.id === field?.id) && (
              // eslint-disable-next-line react/jsx-key
              <div className={styles.field}>
                {props?.data?.map(item => {
                  const fieldValue = item?.fields?.find(value => value?.id === field?.id)?.value!;
                  const fieldValueList = item?.fields?.find(value => value?.id === field?.id)?.valueList!;

                  return filedValue(field?.id, fieldValue, fieldValueList) ? (
                    <div className={styles.field_item}>
                      <Radio
                        checked={props?.dublicate?.fields?.some(item => item?.id === field?.id && item?.value === fieldValue && item?.valueList === fieldValueList)}
                        onClick={e =>
                          changeField({
                            id: field?.id,
                            value: fieldValue,
                            valueList: fieldValueList
                          })
                        }
                      >
                        {filedValue(field?.id, fieldValue, fieldValueList)}
                      </Radio>
                    </div>
                  ) : (
                    <div className={styles.field_item} />
                  );
                })}
              </div>
            )
        )}
      </>
    ) : (
      <div className={styles.field}>
        {props?.data?.map(item => (
          // eslint-disable-next-line react/jsx-key
          <div className={styles.field_item}>
            <>
              {props?.keyObj === 'name' && (
                <p className={styles.field_title} onClick={() => allChecked(item)}>
                  {t('one_lid.Barchasini_tanlash')}{' '}
                  <span
                    className={styles.field_title_leadGo}
                    onClick={e => {
                      e.stopPropagation();
                      goLead(item);
                    }}
                  >
                    {t('one_lid.Leadga_otish')}
                  </span>
                  {props?.from && (
                    <Tooltip title="Olib tashlash">
                      <span>
                        <CloseDublicateIcon
                          onClick={e => {
                            e.stopPropagation();
                            const newArr = props?.data?.filter(filter => filter?.id !== item?.id);

                            props?.setPropsData && props?.setPropsData(newArr);
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </p>
              )}
              {objKeysFC(item)}
            </>
          </div>
        ))}
      </div>
    )
  ) : null;
};

export default DublicateControllField;
