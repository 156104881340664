import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { ClientFilterParams } from '../utils/models';

export function useClientFilterParams() {
  const initialData = {
    countries: [],
    regions: []
  };

  const { data = initialData, ...arg } = useQuery<ClientFilterParams>(
    ['client-filter-params'],
    async () => {
      const { data } = await $api.get('/v1/client/filter/params');

      return { ...data?.data };
    },
    {
      select: data => {
        let newRegions: ClientFilterParams['countries'][number]['regions'] = [];

        data?.countries?.forEach(({ regions }) => (newRegions = [...newRegions, ...regions]));

        return {
          ...data,
          regions: newRegions
        };
      }
    }
  );

  return { ...data, ...arg };
}
