import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import CloseIconV2 from '@icons/CloseIconV2';
import RobotoCallsIcon from '@icons/RobotoCallsIcon';
import SmsTrackingWhiteIcon from '@icons/SmsTrackingWhiteIcon';
import TaskSquareIcon from '@icons/TaskSquareIcon';
import { setChecked, setDebtActiveIds, setDebtIgnoreIds } from '@reducers/DebtSlice';
import { Permissions } from '@utils/constants/permissions';
import { cx } from '@utils/cx';
import { Tooltip } from 'global';
import { useTranslation } from 'react-i18next';

import CreateTask from './table/createTask/CreateTaskModal';
import ArrearageCallPopover from './ArrearageCallPopover';
import ArrearageSmsPopover from './ArrearageSmsPopover';

const classNames = {
  item: 'p-[10px] cursor-pointer h-full flex items-center hover:bg-[#404349]'
};

interface IProps {
  totalElements?: number;
}

const ActionsHeader = ({ totalElements }: IProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { debtListActiveIds, debtListIgnoredIds } = useAppSelector(state => state.debtSlice);
  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);

  const isAccessSendMessage = permissions?.includes(Permissions.PERMISSION_SEND_MESSAGE_TO_DEBTS);
  const isAccessRobotCall = permissions?.includes(Permissions.PERMISSION_ROBOT_CALL);

  const isChecked = (Array.isArray(debtListActiveIds) && debtListActiveIds.length > 0) || debtListActiveIds === null;

  const elements = debtListActiveIds ? debtListActiveIds?.length : (totalElements || 0) - debtListIgnoredIds?.length;

  const onSelectClear = () => {
    dispatch(setChecked(false));
    dispatch(setDebtIgnoreIds([]));
    dispatch(setDebtActiveIds({ ids: [] }));
  };

  return (
    <div className={cx('relative flex h-[0px] items-center justify-between overflow-hidden bg-[#2a2e34] px-10 text-white duration-[0.3s]', isChecked && 'h-[40px]')}>
      {elements} {t('list_view.ta_belgilangan')}
      <div className="flex">
        <CreateTask contracts={debtListActiveIds || []} ignoreContracts={debtListIgnoredIds || []}>
          <Tooltip title={<span>{t('list_view.Topshiriq_yaratish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
            <span className={classNames.item}>
              <TaskSquareIcon />
            </span>
          </Tooltip>
        </CreateTask>
        {isAccessSendMessage ? (
          <ArrearageSmsPopover count={elements}>
            <Tooltip title={<span>{t('client.Xabar_yuborish')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
              <span className={classNames.item}>
                <SmsTrackingWhiteIcon />
              </span>
            </Tooltip>
          </ArrearageSmsPopover>
        ) : (
          <></>
        )}
        {isAccessRobotCall ? (
          <ArrearageCallPopover>
            <Tooltip title={<span>{t('titles.roboto_calls')}</span>} placement="bottom" align={{ offset: [1, -2] }}>
              <span className={classNames.item}>
                <RobotoCallsIcon size={20} />
              </span>
            </Tooltip>
          </ArrearageCallPopover>
        ) : (
          <></>
        )}
      </div>
      <span className="flex cursor-pointer items-center" onClick={onSelectClear}>
        <CloseIconV2 />
      </span>
    </div>
  );
};

export default ActionsHeader;
