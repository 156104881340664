/* eslint-disable no-nested-ternary */
import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { contractStatusColor } from '@pages/showroom-v2/utils/constants/contractStatusColor';
import { flatStatusColor } from '@pages/showroom-v2/utils/constants/flatStatusColor';
import { IShowroomFlat } from '@pages/showroom-v2/utils/models/IShowroomFlat';
import { clear } from '@reducers/CreateContractSlice';
import { setShowroomApartments, setShowroomDrawer } from '@reducers/ShowroomSlice';
import i18n from '@service/I18nService';
import { t } from 'i18next';

import styles from './showroomShaxmatkaPlusFlat.module.scss';

interface IProps extends IShowroomFlat {
  filterData: number[] | undefined;
}

const ShowroomShaxmatkaPlusFlat = (flat: IProps) => {
  const dispatch = useAppDispatch();
  const { drawer, apartments, apartmentActiveIndex, switchFilterStatus } = useAppSelector(state => state.showroomSlice);
  const houseCurrency = useAppSelector(state => state.currency.houseCurrency);
  const handleClick = () => {
    if (
      ((apartments?.length === 0 || !apartments.some(item => item.id === flat?.id)) && flat?.commerceStatus === 'SALE') ||
      (!apartments.some(item => item.id === flat?.id) && apartments?.length === 1 && flat?.commerceStatus === 'BOOKED')
    ) {
      dispatch(
        setShowroomApartments([
          ...apartments.map((item, index) =>
            index === apartmentActiveIndex
              ? {
                  id: flat?.id,
                  number: flat?.number,
                  rooms: flat?.rooms,
                  status: flat?.commerceStatus
                }
              : item
          )
        ] as any)
      );
      dispatch(clear());
    }
    dispatch(setShowroomDrawer({ id: flat?.id, visible: true, floor: flat?.floor }));
  };

  const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const focusedFloor = document.getElementById('focusedFloor');

    focusedFloor?.setAttribute(
      'style',
      `top: ${
        e.clientY - e.nativeEvent.offsetY + focusedFloor.parentElement?.scrollTop! - focusedFloor.parentElement!?.getBoundingClientRect().y
      }px; opacity:1;`
    );
  };

  const mouseOut = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const focusedFloor = document.getElementById('focusedFloor');

    focusedFloor?.setAttribute(
      'style',
      `top: ${
        e.clientY - e.nativeEvent.offsetY + focusedFloor.parentElement?.scrollTop! - focusedFloor.parentElement!?.getBoundingClientRect().y
      }px; opacity:0;`
    );
  };

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {flat?.id ? (
        flat?.filterData?.includes(flat?.id) ? (
          <div
            id={drawer?.id === flat?.id ? 'activeFlat' : ''}
            onMouseMove={mouseMove}
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            onMouseOut={mouseOut}
            className={`${styles.flat} ${drawer?.id === flat?.id ? styles.flat_active : ''}`}
            onClick={handleClick}
            style={{
              background: switchFilterStatus
                ? contractStatusColor[flat?.contractStatus] || flatStatusColor[flat?.commerceStatus]
                : flatStatusColor[flat?.commerceStatus]
            }}
          >
            <p>
              {i18n.language === 'ru' ? (
                <>
                  {flat?.rooms > 1 ? `${flat.rooms}-x` : flat.rooms} {t('home.xonali')} <span>№ {flat?.number}</span>
                </>
              ) : i18n.language === 'en' ? (
                <>
                  {flat?.rooms > 1 ? `${flat.rooms} ${t('home.xonali')}s` : `${flat.rooms} ${t('home.xonali')}`} <span>№ {flat?.number}</span>
                </>
              ) : (
                <>
                  {flat?.rooms} {t('home.xonali')} <span>№ {flat?.number}</span>
                </>
              )}
            </p>
            <h3>
              {flat?.priceNotRepaired?.toLocaleString('ru')} {houseCurrency?.ccy}{' '}
            </h3>
            <h4>
              {flat?.totalArea} {t('home_id.m2')} -{' '}
              {flat?.repaired ? flat?.pricePeraAreaRepaired?.toLocaleString('ru') : flat?.pricePerAreaNotRepaired?.toLocaleString('ru')}{' '}
              {houseCurrency?.ccy}/{t('home_id.m2')}
            </h4>
          </div>
        ) : (
          <div
            className={`${styles.flat} ${drawer?.id === flat?.id ? styles.flat_active : ''} ${styles.flat_disable}`}
            style={{
              background: switchFilterStatus
                ? contractStatusColor[flat?.contractStatus] || flatStatusColor[flat?.commerceStatus]
                : flatStatusColor[flat?.commerceStatus]
            }}
          >
            <p>
              {flat?.rooms}xonalik <span>№ {flat?.number}</span>
            </p>
            <h3>
              {flat?.priceNotRepaired?.toLocaleString('ru')} {houseCurrency?.ccy}{' '}
            </h3>
            <h4>
              {flat?.totalArea} m<sup>2</sup> -{' '}
              {flat?.repaired ? flat?.pricePeraAreaRepaired?.toLocaleString('ru') : flat?.pricePerAreaNotRepaired?.toLocaleString('ru')}{' '}
              {houseCurrency?.ccy}/m
              <sup>2</sup>
            </h4>
          </div>
        )
      ) : (
        <div className={styles.empty} />
      )}
    </>
  );
};

export default ShowroomShaxmatkaPlusFlat;
