import React, { cloneElement, ReactElement, useRef, useState } from 'react';
import { Modal as BaseModal, ModalProps as BaseModalProps } from 'antd';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

interface ModalProps extends BaseModalProps {
  isDraggable?: boolean;
  openChild?: ReactElement;
}

const DraggableModal: React.FC<ModalProps> = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef<HTMLDivElement>(null);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <>
      {props?.openChild && cloneElement(props?.openChild!, { onClick: () => setOpen(true) })}
      <BaseModal
        {...props}
        title={
          <div
            style={{ width: '100%', cursor: 'move' }}
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            {props?.title}
          </div>
        }
        onCancel={() => {
          // @ts-expect-error
          props.onCancel();
          setOpen(false);
        }}
        visible={props?.visible || open}
        modalRender={modal => (
          // @ts-expect-error
          <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      />
    </>
  );
};

export default DraggableModal;
