import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WebsiteUserState {
  editAndCreateModalOpen: boolean;
  id: number;
}

const initialState: WebsiteUserState = {
  editAndCreateModalOpen: false,
  id: 0
};

export const websiteUserSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    editAndCreateModal: (state, action: PayloadAction<{ open: boolean; id: number }>) => {
      state.editAndCreateModalOpen = action.payload.open;
      state.id = action.payload.id;
    }
  }
});

export const { editAndCreateModal } = websiteUserSlice.actions;
export default websiteUserSlice.reducer;
