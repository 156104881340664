import { helper } from '@utils/helper/helper';
import { FileObject } from '@utils/models/File';
import { FlatId } from '@utils/models/Flat';
import { GalleryPosition } from '@utils/models/Gallery';
import { FormInstance } from 'antd';

interface IProps {
  form: FormInstance<any>;
  isSaveImageNew: React.MutableRefObject<boolean>;
  setHomePlanFileImageId: (value: any) => void;
  setBuildingLocationImageId: (value: any) => void;
  setExtraImages: (value: any) => void;
  setTerassaVisible: (
    value: React.SetStateAction<{
      visible: boolean;
      isDelete: boolean;
    }>
  ) => void;
  setRoomObj: React.Dispatch<React.SetStateAction<FlatId | undefined>>;
  activeFlats?: FlatId[];
  tableMode: boolean;
}

export const getSelectableForm = (props: IProps) => {
  const rooms = document.querySelectorAll('.selectableItems');
  const arrSelect: FlatId[] = [...(props?.tableMode && props.activeFlats ? props.activeFlats : [])];

  if (!props?.tableMode) {
    rooms.forEach((item: any) => {
      const flat: FlatId = JSON.parse(item.getAttribute('data'));

      if (flat.isSelected) arrSelect.push(flat);
    });
  }

  let extraImagesList: FileObject[] = [];
  let flatPlanImageId: number | null = null;
  let buildingLocationImageId: number | null = null;
  let isEqual = true;
  let isEqualPlanId = true;
  let isEqualBuildingId = true;
  let isTerraceHave = true;

  arrSelect.forEach((flat, index) => {
    if (!flat.terrace) isTerraceHave = false;

    if (
      !arrSelect.every(
        flat =>
          flat?.terrace?.area === arrSelect[0]?.terrace?.area ||
          flat?.terrace?.pricePerArea === arrSelect[0]?.terrace?.pricePerArea ||
          flat?.terrace?.pricePerAreaRepaired === arrSelect[0]?.terrace?.pricePerAreaRepaired
      )
    ) {
      isTerraceHave = false;
    }

    if (flat?.gallery && flat?.gallery.length > 0) {
      const extraImagesNew: FileObject[] = [];
      let newFlatPlanImageId: number | null = null;
      let newBuildingLocationImageId: number | null = null;

      flat?.gallery?.forEach((image: any) => {
        if (!!image && image.position) {
          switch (image.position) {
            case GalleryPosition.FLAT_FLOOR:
              if (image?.fileID) newBuildingLocationImageId = image.fileID;
              else newBuildingLocationImageId = image.files.id;
              break;
            case GalleryPosition.FLAT_PLAN:
              if (image?.fileID) newFlatPlanImageId = image.fileID;
              else newFlatPlanImageId = image.files.id;
              break;
            case GalleryPosition.FLAT_EXTRA:
              extraImagesNew.push(image.files);
              break;
            default:
              return false;
          }
        }
        return false;
      });
      if (isEqualPlanId) {
        if (index !== 0 && newFlatPlanImageId !== flatPlanImageId) {
          isEqualBuildingId = false;
          buildingLocationImageId = null;
          isEqualPlanId = false;
          flatPlanImageId = null;
          extraImagesList = [];
          isEqual = false;
        } else {
          flatPlanImageId = newFlatPlanImageId;
        }
      }
      if (isEqualBuildingId) {
        if (index !== 0 && newBuildingLocationImageId !== buildingLocationImageId) {
          isEqualBuildingId = false;
          buildingLocationImageId = null;
          isEqualPlanId = false;
          flatPlanImageId = null;
          extraImagesList = [];
          isEqual = false;
        } else {
          buildingLocationImageId = newBuildingLocationImageId;
        }
      }
      if (isEqual) {
        if (index !== 0 && extraImagesNew.length !== extraImagesList.length) {
          isEqualBuildingId = false;
          buildingLocationImageId = null;
          isEqualPlanId = false;
          flatPlanImageId = null;
          extraImagesList = [];
          isEqual = false;
        } else if (index === 0) {
          extraImagesList = extraImagesNew;
        } else {
          extraImagesList.forEach(file => {
            if (!extraImagesNew.find(fileNew => fileNew.id === file.id)) {
              isEqual = false;
            }
          });
          if (!isEqual) {
            extraImagesList = [];
          }
        }
      }
    } else {
      buildingLocationImageId = null;
      flatPlanImageId = null;
      extraImagesList = [];
    }

    const selectsFlat = {
      variationGroupId:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.variationGroupId === arrSelect[0].variationGroupId) ? flat.variationGroupId : null) : flat.variationGroupId,
      discount:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.discountID === arrSelect[0].discountID) ? flat.discountID : null) : flat.discountID,
      // eslint-disable-next-line no-nested-ternary
      number: arrSelect.length > 1 ? (arrSelect.every(flat => flat.number === arrSelect[0].number) ? flat.number : '*') : flat.number,
      // eslint-disable-next-line no-nested-ternary
      area: arrSelect.length > 1 ? (arrSelect.every(flat => flat.area === arrSelect[0].area) ? flat.area : '*') : flat.area,
      // eslint-disable-next-line no-nested-ternary
      livingArea: arrSelect.length > 1 ? (arrSelect.every(flat => flat.livingArea === arrSelect[0].livingArea) ? flat.livingArea : '*') : flat.livingArea,
      // eslint-disable-next-line no-nested-ternary
      rooms: arrSelect.length > 1 ? (arrSelect.every(flat => flat.rooms === arrSelect[0].rooms) ? flat.rooms : '*') : flat.rooms,
      pricePerArea:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.pricePerArea === arrSelect[0].pricePerArea)
            ? flat.pricePerArea?.toLocaleString('ru')
            : '*'
          : flat.pricePerArea?.toLocaleString('ru'),
      pricePerAreaRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.pricePerAreaRepaired === arrSelect[0].pricePerAreaRepaired)
            ? flat.pricePerAreaRepaired?.toLocaleString('ru')
            : '*'
          : flat.pricePerAreaRepaired?.toLocaleString('ru'),
      // eslint-disable-next-line no-nested-ternary
      repaired: arrSelect.length > 1 ? (arrSelect.every(flat => flat.repaired === arrSelect[0].repaired) ? flat.repaired : null) : flat.repaired,
      // eslint-disable-next-line no-nested-ternary
      apartment: arrSelect.length > 1 ? (arrSelect.every(flat => flat.apartment === arrSelect[0].apartment) ? flat.apartment : null) : flat.apartment,
      // eslint-disable-next-line max-len, no-nested-ternary
      nonResidentialType: arrSelect.length > 1 ? (arrSelect.every(flat => flat.nonResidentialType === arrSelect[0].nonResidentialType) ? flat?.nonResidentialType : null) : flat?.nonResidentialType,
      // eslint-disable-next-line no-nested-ternary
      category: arrSelect.length > 1 ? (arrSelect.every(flat => flat.category === arrSelect[0].category) ? flat.category : null) : flat.category,
      prepaymentPercent:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.prepaymentPercent === arrSelect[0].prepaymentPercent) ? flat.prepaymentPercent : '*') : flat.prepaymentPercent,
      // eslint-disable-next-line no-nested-ternary
      delay: arrSelect.length > 1 ? (arrSelect.every(flat => flat.delay === arrSelect[0].delay) ? flat.delay : '*') : flat.delay,
      // terraceArea: !!flat.terrace ? flat.terrace.area : null,
      terraceArea:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.terrace?.area === arrSelect[0].terrace?.area) ? flat.terrace?.area : '*') : flat.terrace?.area,
      // terracePricePerArea: !!flat.terrace
      //   ? flat.terrace.pricePerArea?.toLocaleString('ru')
      //   : null,
      terracePricePerArea:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.terrace?.pricePerArea === arrSelect[0].terrace?.pricePerArea)
            ? flat.terrace?.pricePerArea?.toLocaleString('ru')
            : '*'
          : flat.terrace?.pricePerArea?.toLocaleString('ru'),
      // terracePricePerAreaRepaired: !!flat.terrace
      //   ? flat.terrace.pricePerAreaRepaired?.toLocaleString('ru')
      //   : null,
      terracePricePerAreaRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.terrace?.pricePerAreaRepaired === arrSelect[0].terrace?.pricePerAreaRepaired)
            ? flat.terrace?.pricePerAreaRepaired?.toLocaleString('ru')
            : '*'
          : flat.terrace?.pricePerAreaRepaired?.toLocaleString('ru'),
      priceNotRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.priceNotRepaired === arrSelect[0].priceNotRepaired)
            ? flat.priceNotRepaired?.toLocaleString('ru')
            : '*'
          : flat.priceNotRepaired?.toLocaleString('ru'),
      priceRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.priceRepaired === arrSelect[0].priceRepaired)
            ? flat.priceRepaired?.toLocaleString('ru')
            : '*'
          : flat.priceRepaired?.toLocaleString('ru')
    };

    const flatObj = {
      // eslint-disable-next-line no-nested-ternary
      area: arrSelect.length > 1 ? (arrSelect.every(flat => flat.area === arrSelect[0].area) ? flat.area : 0) : flat.area,
      pricePerArea:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.pricePerArea === arrSelect[0].pricePerArea) ? flat.pricePerArea : 0) : flat.pricePerArea,
      pricePerAreaRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1
          ? arrSelect.every(flat => flat.pricePerAreaRepaired === arrSelect[0].pricePerAreaRepaired)
            ? flat.pricePerAreaRepaired
            : 0
          : flat.pricePerAreaRepaired,
      priceNotRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.priceNotRepaired === arrSelect[0].priceNotRepaired) ? flat.priceNotRepaired : '*') : flat.priceNotRepaired,
      priceRepaired:
        // eslint-disable-next-line no-nested-ternary
        arrSelect.length > 1 ? (arrSelect.every(flat => flat.priceRepaired === arrSelect[0].priceRepaired) ? flat.priceRepaired : '*') : flat.priceRepaired
    };

    props.form.setFieldsValue({ ...selectsFlat });
    props.setRoomObj(prev => ({
      ...prev!,
      ...flatObj,
      priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(flatObj?.priceNotRepaired)).number,
      priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(flatObj?.priceRepaired)).number
    }));
  });

  props.isSaveImageNew.current = false;
  props.setHomePlanFileImageId(flatPlanImageId);
  props.setBuildingLocationImageId(buildingLocationImageId);
  props.setExtraImages(extraImagesList);
  props.setTerassaVisible({
    visible: isTerraceHave,
    isDelete: false
  });
};
