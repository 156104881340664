import { useEffect } from "react";
import { Form, Popconfirm } from "antd";
import { Button, Input, Modal } from "global"
import { useTranslation } from "react-i18next";

import { useDeletePaymentCustomType, usePostPaymentCustomType } from "./service/mutation";
import { useGetPaymentCustomType } from "./service/queries";
import { PaymentCustomType } from "./utils/models/PaymentCustomType";

import styles from '../paymentWays.module.scss';


interface IProps {
    setOpenModal: any;
    openModal: any;
    isEditing: boolean;
    item?: any;
}

const CustomPayment: React.FC<IProps> = ({ setOpenModal, openModal, isEditing, item }) => {
    const { data: dataPaymentCustomType } = useGetPaymentCustomType();
    const postPaymentCustomType = usePostPaymentCustomType();
    const deletePaymentCustomType = useDeletePaymentCustomType(item?.id);

    const [form] = Form.useForm<any>();
    const { t } = useTranslation();

    useEffect(() => {
        form.setFieldsValue({
            ...dataPaymentCustomType,
            name: dataPaymentCustomType?.name,
            id: dataPaymentCustomType?.id,
        });
    }, [form]);

    useEffect(() => {
        if (openModal) {
            form.resetFields();
            if (item) {
                form.setFieldsValue({
                    name: item.name,
                    id: item.id,
                });
            }
        }
    }, [openModal, item]);

    const onFinish = (values: PaymentCustomType) => {
        const formData = {
            ...values,
            name: values?.name
        };

        if (values?.id) {
            // @ts-expect-error
            postPaymentCustomType.mutateAsync({ id: values?.id, ...formData }).then(() => setOpenModal(false));
        } else {
            postPaymentCustomType.mutateAsync(formData as any).then(() => setOpenModal(false))
        }

    };

    const handleDelete = () => {
        deletePaymentCustomType?.mutateAsync().then(() => setOpenModal(false));
    };

    return (
        <Modal
            title={isEditing ? t("home.Tolov_turini_tahrirlash") : t("home.Tolov_turi_qoshish")}
            footer={false}
            onCancel={() => setOpenModal(false)}
            visible={openModal}
        >
            <div>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="id" className="hidden">
                        <Input />
                    </Form.Item>
                    <Form.Item name="name" rules={[{ required: true, message: '' }]}>
                        <Input placeholder={t("home.Tolov_turini_kiriting")} />
                    </Form.Item>
                    <div className="mt-[0px] flex">
                        {isEditing && (
                            <Popconfirm
                                title={
                                    <div>
                                        <p>{t("home.Bu_to'lov_usuli_to'lovlarda_ishlatilgan_bo'lishi_mumkin.")}</p>
                                        <p>{t("home.To'lov_turini_o'chirishga_ishonchingiz_komilmi?")}</p>
                                    </div>
                                }
                                placement="bottomRight"
                                onConfirm={handleDelete}
                                okText={t("profile.Tasdiqlash")}
                                cancelText={t("create_table_payments.Orqaga")}
                                overlayInnerStyle={{ borderRadius: 10 }}
                                className={styles.popConfirm}
                            >
                                <Button
                                    type="primary"
                                    loading={deletePaymentCustomType?.isLoading}
                                    className="rounded-[30px] w-[150px] h-[38px] text-[15px] ml-auto mr-[-120px] border-none !bg-red-500 hover:!bg-red-600 active:!bg-red-600"
                                >
                                    {t("profile.Ochirish")}
                                </Button>
                            </Popconfirm>
                        )}
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={postPaymentCustomType?.isLoading}
                            className="rounded-[30px] w-[150px] h-[38px] text-[15px] ml-auto mr-0"
                        >
                            {t("home.Saqlash")}
                        </Button>
                    </div>
                </Form>

            </div>
        </Modal>
    )
}

export default CustomPayment
