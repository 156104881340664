import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next'; 

import ArrowLeftMainHeader from '../../../assets/icons/ArrowLeftMainHeader'; 
import ArrowRight from '../../../assets/icons/ArrowRight';
import { useQueryParam } from '../../../hooks/useQueryParams'; 
import { MainPaths } from '../../../routing/main/mainPaths'; 
import { rootPaths } from '../../../routing/rootPaths';
import { pagesNameList } from '../../../utils/constants/pagesNameList';
import { urlDecode } from '../../../utils/helper/urlEncodeDecode';

import styles from '../../headers/header.module.scss';

const SettingsHeader = () => {
  const { splitUrl, push } = useQueryParam();

  const { t } = useTranslation();

  const globalTitle = () => {
    const findIndex = pagesNameList.indexOf(splitUrl[splitUrl.length - 1]);

    if (findIndex !== -1) {
      return t(`titles.${splitUrl[splitUrl.length - 1]}`); 
    }
    return urlDecode(splitUrl[splitUrl.length - 1]);
  };

  const goTo = (index: number) => {
    let url = '';

    if (index === splitUrl.length - 1) {
      return;
    }
    for (let i = 0; i <= index; i++) {
      url += splitUrl[i];
      if (i !== index) {
        url += '/';
      }
    }
    push(url);
  };

  // История назад
  const navigate = useNavigate();
  const historyBack = () => {
    navigate(rootPaths.MAIN + MainPaths.SETTING);
  };

  // Рендеринг компонента
  return (
    <div className="d-flex align-center">
      {splitUrl.length !== 3 && (
        <ArrowLeftMainHeader
          onClick={historyBack}
          className={styles.icon_arrow_back} // Применение стиля для стрелки назад
        />
      )}
      <div className={styles.titleCont}>
        <div className={styles.main}>{globalTitle()}</div> {/* Отображение глобального заголовка */}
        {splitUrl.length !== 3 && (
          <div className="d-flex align-center">
            {splitUrl.map((item, index) => {
              if (index > 0 && index % 2 === 0) {
                const findIndex = pagesNameList.indexOf(splitUrl[index]); // Поиск индекса текущей страницы в списке страниц

                if (findIndex !== -1) {
                  return (
                    <div
                      onClick={() => goTo(index)} // Обработчик клика для навигации
                      className={styles.sub_title} // Применение стиля для подзаголовка
                      key={index}
                    >
                      {t(`titles.${splitUrl[index]}`)} {/* Переведенный подзаголовок */}
                      {index !== splitUrl.length - 1 && (
                        <ArrowRight className={styles.icon_arrow} /> // Отображение стрелки вправо
                      )}
                    </div>
                  );
                }
                return (
                  <div
                    onClick={() => goTo(index)} // Обработчик клика для навигации
                    className={styles.sub_title} // Применение стиля для подзаголовка
                    key={index}
                  >
                    {urlDecode(splitUrl[index])} {/* Декодированный подзаголовок */}
                    {index !== splitUrl.length - 1 && (
                      <ArrowRight className={styles.icon_arrow} /> // Отображение стрелки вправо
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsHeader; // Экспорт компонента
