import React from 'react';

const FIlterColapseIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.23324 1.83325H8.16658C8.65992 1.83325 9.06657 2.23992 9.06657 2.73326V3.71991C9.06657 4.07991 8.83991 4.52659 8.61991 4.75326L6.6866 6.45992C6.41994 6.68659 6.23991 7.13325 6.23991 7.49325V9.4266C6.23991 9.69326 6.05992 10.0533 5.83325 10.1933L5.20658 10.5999C4.61992 10.9599 3.81323 10.5533 3.81323 9.83325V7.45325C3.81323 7.13991 3.63325 6.73326 3.45325 6.50659L1.74658 4.70658C1.51991 4.47992 1.33992 4.07991 1.33992 3.80658V2.77325C1.33326 2.23992 1.7399 1.83325 2.23324 1.83325Z"
        stroke="#292D32"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33325 8.50008V10.5001C1.33325 13.8334 2.66659 15.1667 5.99992 15.1667H9.99992C13.3333 15.1667 14.6666 13.8334 14.6666 10.5001V6.50008C14.6666 4.42008 14.1466 3.11341 12.9399 2.43341C12.5999 2.24008 11.9199 2.09341 11.2999 1.99341"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.66675 9.16675H12.0001" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.33325 11.8333H11.9999" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FIlterColapseIcon;
