import React from 'react';

const SaveIcon = (props: any) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5013 18.3332H12.5013C16.668 18.3332 18.3346 16.6665 18.3346 12.4998V7.49984C18.3346 3.33317 16.668 1.6665 12.5013 1.6665H7.5013C3.33464 1.6665 1.66797 3.33317 1.66797 7.49984V12.4998C1.66797 16.6665 3.33464 18.3332 7.5013 18.3332Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 9.5918L10 12.0918L12.5 9.5918" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 12.0915V5.4248" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 13.7583C8.24167 14.8416 11.7583 14.8416 15 13.7583" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SaveIcon;
