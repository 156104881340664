import React from 'react';
import { crmIcons } from '../../../assets/icons/crmIcons/crmIcons';
import TickCircleIconCrm from '../../../assets/icons/TickCircleIconCrm';
import { Task } from '../../../utils/models/Task';

import styles from '../../../pages/oneLid/components/taskList/taskChatContainer/completedTask/completedTask.module.scss';

interface Props {
  event: Task;
}

const CompletedTask: React.FC<Props> = ({ event }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_icon}>
        <TickCircleIconCrm />
      </div>
      <div className={styles.container_notes}>
        <p className={styles.container_notes_date}>
          {event.closedDate} {event.employee}
        </p>
        <p className={styles.container_notes_note}>
          {crmIcons[event.leadTaskTypeDto.icon?.split('#')[0]!]?.icon('#' + event.leadTaskTypeDto.icon?.split('#')[1])}
          <span>{event.leadTaskTypeDto.name}:</span>
          <span>{event.text.uz}</span>
        </p>
        <p className={styles.container_notes_reason}>{event.closedText}</p>
      </div>
    </div>
  );
};

export default CompletedTask;
