import { useQuery } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { getMessagesBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { MessageList } from '../../../utils/models/MessageList';
import { Pagination } from '../../../utils/models/Pagination';
import { OriginatorHouse } from '../../../utils/models/PlayMobile';
import { TemplateFields } from '../../../utils/models/TemplateFields';
import { TemplateMessage } from '../../../utils/models/TemplateMessage';
import { SearchParamsMessage } from '../Message';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useMessageList(size: number, searchObj: SearchParamsMessage) {
  return useQuery<Pagination<MessageList>>([queryKeys.MESSAGE_LIST, searchObj.page, JSON.stringify(searchObj)], async () => {
    const res = await $api.post(endPoints.CLIENT_SEND_MESSAGE_FILTER, getMessagesBody(size, searchObj));

    return res.data.data;
  });
}

// get premium originator

export function usePremiumOriginator() {
  return useQuery<OriginatorHouse[]>(
    [queryKeys.PREMIUM_ORIGINATOR],
    async () => {
      const res = await $api.get(endPoints.PLAY_MOBILE_CONF_ORGINATOR);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useTemplateMessageFields() {
  return useQuery<TemplateFields[]>(
    [queryKeys.TEMPLATE_MESSAGE_FIELDS],
    async () => {
      const res = await $api.get(endPoints.CLIENT_MESSAGE_TEMP_FIELDS);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useTemplateMessage() {
  return useQuery<TemplateMessage[]>(
    [queryKeys.TEMPLATE_MESSAGE],
    async () => {
      const res = await $api.get(endPoints.CLIENT_MESSAGE_TEMP);

      return res.data.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}
