import React from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { noResidentialTypeOptions } from '@utils/constants/noResidentialTypeOptions';
import { Spin } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { useFlatsCountBossV2 } from '../../queries/queries';

const className = {
  item: 'border-[1px] border-solid border-[#ebebeb] rounded-[12px] p-[16px]',
  title: 'text-[24px] m-[0] text-[#4d968a]',
  child: 'text-[20px] text-[#006858]'
};

const CountInformation = () => {
  const { buildings, houses } = useAppSelector(state => state.bossCurrency);

  const convertSearchParam = () =>
    queryString.stringify({
      houses,
      buildings
    });
  const { data, isLoading, isFetching } = useFlatsCountBossV2(convertSearchParam());

  const { t } = useTranslation();

  return (
    <div className="w-full">
      <Spin spinning={isLoading || isFetching}>
        <div className=" grid w-full grid-cols-1 gap-[16px]">
          <div className={className.item}>
            <h1 className={className.title}>{t('boss.Sotuvdagilar')}</h1>
            <hr className="h-[1px] border-none bg-[#ebebeb]" />
            <div className={className.child}>
              {t('showroom.Xonadon')}: <b>{data?.empty?.apartment}</b>
            </div>
            {noResidentialTypeOptions?.map(item => (
              <div key={item} className={className.child}>
                {t(`flat.${item}`)}: <b>{data?.empty?.nonResidential[item] || 0}</b>
              </div>
            ))}
          </div>
          <div className={className.item}>
            <h1 className={className.title}>{t('boss.Sotilganlar')}</h1>
            <hr className="h-[1px] border-none bg-[#ebebeb]" />
            <div className={className.child}>
              {t('showroom.Xonadon')}: <b>{data?.sold?.apartment}</b>
            </div>
            {noResidentialTypeOptions?.map(item => (
              <div key={item} className={className.child}>
                {t(`flat.${item}`)}: <b>{data?.sold?.nonResidential[item] || 0}</b>
              </div>
            ))}
          </div>
          <div className={className.item}>
            <h1 className={className.title}>{t('boss.Buyurtma_qilinganlar')}</h1>
            <hr className="h-[1px] border-none bg-[#ebebeb]" />
            <div className={className.child}>
              {t('showroom.Xonadon')}: <b>{data?.booked?.apartment}</b>
            </div>
            {noResidentialTypeOptions?.map(item => (
              <div key={item} className={className.child}>
                {t(`flat.${item}`)}: <b>{data?.booked?.nonResidential[item] || 0}</b>
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default CountInformation;
