import React from 'react';
import styles from '../changes.module.scss';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { ChangesQueryParams } from '../../../utils/models/Changes';
import { useAppSelector } from '../../../hooks/reduxHooks';
import LinearUser from '../../../assets/icons/LinearUser';
import { useContractAndClientInfoChanges } from '../services/queries';

const ClientAndContractInfo = () => {
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();

  const { t } = useTranslation();

  const { isLoading, data } = useContractAndClientInfoChanges(searchParams.contractId);

  const { currency } = useAppSelector(state => state.changesReducer);

  return (
    <Spin spinning={isLoading}>
      <div>
        <h2 className={styles.title}>{t('changes.Mijoz_malumotlari')}</h2>
        <div className={styles.info_contract}>
          <LinearUser className={styles.info_contract_icon} />
          <div className={styles.info_contract_item}>
            <label>{t('changes.Mijoz')}:</label>
            <span>{data?.clientName}</span>
          </div>
          <div className={styles.info_contract_item}>
            <label>{t('changes.Telefon')}:</label>
            <span>{data?.clientPhone}</span>
          </div>
          <div className={styles.info_contract_item}>
            <label>{t('changes.Shartnoma_raqami')}:</label>
            <span>{data?.contractNumber}</span>
          </div>
          <div className={styles.info_contract_item}>
            <label>{t('changes.Shartnoma_summasi')}:</label>
            <span>
              {data?.contractAmount?.toLocaleString('ru')} {currency?.ccy}
            </span>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ClientAndContractInfo;
