import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '../../../service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { message } from 'antd';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { OneCModel } from '../utils/models/OneCModel';

export function useGetGenerationToken(status: boolean) {
  return useQuery(
    [queryKeys.COMPANY_TOKEN, status],
    async () => {
      const res = await $api.get(`${endPoints.COMPANY_TOKEN}?new=${status}`);
      return res.data.data;
    },
    {
      // enabled: !!status,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useGetOneCIntegration() {
  return useQuery<OneCModel>(
    [queryKeys.ONE_C],
    async () => {
      const res = await $api.get(endPoints.ONE_C);
      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      retry: false,
      enabled: false
    }
  );
}
