import React, { useState } from 'react';
import { Badge, Button, Drawer, Input, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import FilterMenuMineIcon from '../../../../assets/icons/FilterMenuMineIcon';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useMessageCountNotRead } from '../../../../queries/queries';
import { setMessageDrawerVisible } from '../../../../store/reducers/notificationSlice';
import { Permissions } from '../../../../utils/constants/permissions';

import MessageDrawerData from './MessageDrawerData';
import MessageDrawerFilter from './MessageDrawerFilter';

import styles from './messageDrawer.module.scss';

const MessageDrawer = () => {
  const messageDrawerVisible = useAppSelector(state => state.notificationSlice.messageDrawerVisible);
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(state =>
    state.userReducer?.user?.role
      ? state.userReducer?.user?.role.permissions
      : state.userReducer?.user?.roleTokens && state.userReducer?.user?.roleTokens[0]?.role?.permissions
  );
  const { t } = useTranslation();

  const isAccessCrm = permissions?.includes(Permissions.PERMISSION_PAGE_CRM);
  const { data: countNotRead } = useMessageCountNotRead(!!isAccessCrm);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterData, setFilterData] = useState<{
    pipeStatusIds: number[] | null;
    responsibleByIds: number[] | null;
    types: string[] | null;
  }>();
  const [search, setSearch] = useState('');
  const onClose = () => {
    dispatch(setMessageDrawerVisible(false));
  };

  const handleFilter = () => {
    setFilterVisible(!filterVisible);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Drawer
      title={false}
      closable={false}
      placement="right"
      visible={messageDrawerVisible}
      className={styles.container}
      onClose={onClose}
      width={300}
      // maskStyle={{ background: 'transparent' }}
    >
      <div className={styles.container_header}>
        <Input placeholder={t('home.Qidirish')} prefix={<SearchIcon />} onChange={onSearch} />
        <Button onClick={handleFilter}>
          <FilterMenuMineIcon />
        </Button>
        <MessageDrawerFilter filterVisible={filterVisible} setFilterVisible={setFilterVisible} setFilterData={setFilterData} />
      </div>
      <Tabs>
        <Tabs.TabPane key="all" tab={t('home.Barchasi')}>
          <MessageDrawerData search={search} size={20} wasRead={null} filterData={filterData} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="unread"
          tab={
            <Badge count={countNotRead}>
              <span>{t('home.Oqilmaganlar')}</span>
            </Badge>
          }
        >
          <MessageDrawerData size={200} search={search} wasRead={false} filterData={filterData} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

export default MessageDrawer;
