const Notification = () => (
  <div className='bg-white p-10 m-8 min-h-screen'>
    <h1>Jinggle Bells🔔</h1>
    <p>There is nothing here.</p>
  </div>
)

export default Notification;








