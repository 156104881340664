import React, { FC } from 'react';

interface IProps {
  background?: string;
}

const TickCircleCrmIcon: FC<IProps> = ({ background = '#FFB400' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99998 1.33398C4.32665 1.33398 1.33331 4.32732 1.33331 8.00065C1.33331 11.674 4.32665 14.6673 7.99998 14.6673C11.6733 14.6673 14.6666 11.674 14.6666 8.00065C14.6666 4.32732 11.6733 1.33398 7.99998 1.33398ZM11.1866 6.46732L7.40665 10.2473C7.31331 10.3407 7.18665 10.394 7.05331 10.394C6.91998 10.394 6.79331 10.3407 6.69998 10.2473L4.81331 8.36065C4.61998 8.16732 4.61998 7.84732 4.81331 7.65398C5.00665 7.46065 5.32665 7.46065 5.51998 7.65398L7.05331 9.18732L10.48 5.76065C10.6733 5.56732 10.9933 5.56732 11.1866 5.76065C11.38 5.95398 11.38 6.26732 11.1866 6.46732Z"
        fill={background}
      />
    </svg>
  );
};

export default TickCircleCrmIcon;
