import React from 'react';

const TwoCheckIconEmployeeChatCrm = () => {
  return (
    <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.50043L4.56565 8.06608C4.6828 8.18324 4.87275 8.18324 4.98991 8.06608L12.3333 0.722656"
        stroke="#00A389"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8 7L9.23285 8.09114C9.35161 8.19625 9.53167 8.19077 9.64381 8.07862L16.9998 0.722656"
        stroke="#00A389"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TwoCheckIconEmployeeChatCrm;
