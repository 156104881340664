import { $api } from '@service/RequestService';
import { discountEndpoints } from '@utils/constants/apis';
import { useQuery } from 'react-query';

import { updateSchema } from '../forms/schema';

interface IQuery {
  maxDiscountPercent: number;
}

export const useMaxDiscount = () => {
  const { data, ...args } = useQuery<IQuery>(['max-discount'], async () => {
    const { data } = await $api.get(discountEndpoints.MAX_DISCOUNT);
    const { maxDiscountPercent } = updateSchema.parse(data.data);

    return { maxDiscountPercent };
  });

  return { ...data, ...args };
};
