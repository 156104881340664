import { BaseEntity } from './BaseEntity';
import { BaseLanguageEntity } from './BaseLanguageEntity';
import { ClientLeadModel } from './ClientLeadModel';
import { ClientType } from './ClientType';
import { CurrencyItem } from './Currency';
import { OneLid } from './OneLid';
import { PaymentType } from './PaymentType';

export enum ClientStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
  ARCHIVE = 'ARCHIVE'
}

export interface ClientPhone {
  id?: number;
  phone: string;
  active: boolean;
  code: string;
}

export interface JuridicalPersonCreate extends BaseEntity {
  inn?: string;
  rs?: string;
  accountNumber?: string;
  oked?: string;
  bankName?: string;
  mfo?: string;
  director?: string;
  districtId?: number;
  cityID?: number;
  note?: string;
  address?: string;
  phones: ClientPhone[];
  status?: ClientStatus;
  type?: ClientType;
  countryCode?: string;
  lead?: ClientLeadModel;
  leadId?: number;
  directorPassportSerial?: string;
  directorPassportNumber?: string;
}

export interface NaturalPersonCreate {
  address?: string;
  dateBirth?: string;
  districtId?: number;
  cityID?: number;
  fatherName?: string;
  firstname: string;
  id?: number;
  lastname: string;
  note?: string;
  passportDate?: string;
  passportExpiredDate?: string;
  passportImg1Id?: number;
  passportImg2Id?: number;
  passportNumber?: string;
  passportPlace?: string;
  passportSerial?: string;
  phones: ClientPhone[];
  type?: ClientType;
  workPlace?: string;
  inn?: string;
  pinfl?: string;
  countryCode?: string;
  lead?: ClientLeadModel;
  leadId?: number;
}

export interface ClientListData {
  balances: ClientBalanceItem[];
  city: BaseLanguageEntity | null;
  date: string;
  employee: string;
  id: number;
  name: string;
  phone: string;
  status: ClientStatus;
  level: string;
}

export interface NaturalPersonView extends NaturalPersonCreate {
  balances: ClientBalanceItem[];
  status: ClientStatus;
  modifyDate: string;
  city?: string;
  district?: string;
}

export interface JuridicalPersonView extends JuridicalPersonCreate {
  balances: ClientBalanceItem[];
  status: ClientStatus;
  modifyDate?: string;
  city?: string;
  district?: string;
}

export interface ClientBalanceItem {
  amount: number;
  currencyDto: CurrencyItem;
}

export interface ClientBalanceHistoryList {
  id: number;
  createdDate: string;
  amount: number;
  type: PaymentType;
  debit: DebitType;
  createdBy: string;
  contractNumber: string;
  contractId: number;
  currency: CurrencyItem;
  paymentCustomTypeName: string;
  paymentCustomTypeId: number;
}

export enum DebitType {
  INCOME = 'INCOME',
  OUTCOME = 'OUTCOME'
}

export enum GetClientByType {
  ORDER_ID = 'orderId',
  CLIENT_ID = 'clientId'
}

export interface SearchClientChanges extends BaseEntity {
  phone?: string;
  date?: string;
  type?: ClientType;
  lead?: OneLid;
  code?: string;
}

export interface ClientAndContractInfo {
  clientName: string;
  clientPhone: string;
  contractNumber: string;
  contractAmount: number;
}
