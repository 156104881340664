import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DebtState {
  debtListActiveIds: number[] | null;
  debtListIgnoredIds: number[];
  checked: boolean;
}

const initialState: DebtState = {
  debtListActiveIds: [],
  debtListIgnoredIds: [],
  checked: false
};

export const DebtSlice = createSlice({
  name: 'debt',
  initialState,
  reducers: {
    setChecked: (state, action: { payload: boolean }) => {
      state.checked = action.payload;
    },
    setDebtActiveIds: (
      state,
      action: {
        payload: {
          ids: number[] | null;
        };
      }
    ) => {
      state.debtListActiveIds = action.payload.ids;
    },
    setDebtIgnoreIds: (state, action: PayloadAction<number[]>) => {
      state.debtListIgnoredIds = action.payload;
    }
  }
});
export const { setDebtActiveIds, setDebtIgnoreIds, setChecked } = DebtSlice.actions;
export default DebtSlice.reducer;
