import React, { FC, useState, useEffect } from 'react';
import styles from './sipuConfig.module.scss';
import { useSipuniConfigCallHandlingGet, useSipuniConfigContactGet } from '../utils/service/queries';
import SipuCreateConfig from './SipuCreateConfig';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import SipuConfigItem from './SipuConfigItem';
import { usePipeAll } from '../../../../../../queries/queries';
import { useSipuniConfigCallHandling } from '../utils/service/mutation';
import SipuConfigEdit from './SipuConfigEdit';

interface IProps {
  id: number | undefined;
  setEditId: React.Dispatch<React.SetStateAction<number | null>>;
  editId: number | null;
}

const SipuConfig: FC<IProps> = ({ id, editId, setEditId }) => {
  const { t } = useTranslation();
  const { data } = useSipuniConfigCallHandlingGet(id);
  const { data: contactList } = useSipuniConfigContactGet(id);
  const { data: pipeAll } = usePipeAll();
  const callhandlingMutate = useSipuniConfigCallHandling();
  const [create, setCreate] = useState(false);
  const [contacts, setContacts] = useState<number[]>([]);
  const [pipeId, setPipeId] = useState<number | null>(null);

  const handleSave = () => {
    if (id && pipeId) {
      callhandlingMutate
        .mutateAsync({
          contactList: contacts,
          pipeId: pipeId,
          sipuniConfId: id
        })
        .then(() => {
          setCreate(false);
        });
    }
  };

  useEffect(() => {
    if (!create) {
      setContacts([]);
      setPipeId(null);
    }
  }, [create]);

  const edit = editId ? data?.find(item => item?.id === editId) : undefined;

  return editId ? (
    <SipuConfigEdit edit={edit} pipeAll={pipeAll} sipuniConfId={id!} setEditId={setEditId} />
  ) : (
    <div className={styles.container}>
      {data?.map((item, index) => (
        <SipuConfigItem
          key={item?.id}
          item={item}
          index={index}
          sipuniConfId={id!}
          contactAll={contactList!}
          pipeAll={pipeAll}
          setEditId={setEditId}
        />
      ))}
      {create ? (
        <SipuCreateConfig
          id={id}
          contactList={contactList}
          pipeAll={pipeAll}
          contacts={contacts}
          pipeId={pipeId}
          setCreate={setCreate}
          setContacts={setContacts}
          setPipeId={setPipeId}
        />
      ) : (
        <div className={styles.addConfig} onClick={() => setCreate(true)}>
          + Qo'shish
        </div>
      )}
      <Button className={styles.saveBtn} disabled={!pipeId || !(contacts?.length > 0)} onClick={handleSave} loading={callhandlingMutate.isLoading}>
        {t('home.Saqlash')}
      </Button>
    </div>
  );
};

export default SipuConfig;
