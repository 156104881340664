import { kril } from '../constants/alphabet';

export const hasKril = (text: string) => {
  let has = false;
  kril.split('').forEach(item => {
    if (text.includes(item)) {
      has = true;
    }
  });
  return has;
};
