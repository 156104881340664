import { PaymentType } from '../models/PaymentType';

export const getColorPaymentType = (type: PaymentType) => {
  switch (type) {
    case PaymentType.CASH:
      return '#00A389';
    case PaymentType.CARD:
      return '#4F1F9F';
    case PaymentType.BANK:
      return '#FFBB54';
    case PaymentType.TRANSFER:
      return '#FE6D73';
    case PaymentType.PAYME:
      return '#3CC3CC';
    case PaymentType.APELSIN:
      return '#FE8A01';
    default:
      return '#3772FF';
  }
};
