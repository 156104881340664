import React, { useEffect, useState } from 'react';
import styles from './currencyModal.module.scss';
import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { openModal } from '../../../store/reducers/CurrencySlice';
import { getCurrentCurrencyName } from '../../../utils/helper/getCurrentCurrencyName';
import { currencyFlags } from '../../../utils/constants/currencyFlags';
import { defaultImage } from '../../../utils/constants/defaultImage';
import EllipseRightIcon from '../../../assets/icons/EllipseRightIcon';
import { useTranslation } from 'react-i18next';
import { useCurrencyListGlobal } from '../../../queries/queries';
import { RemoteCurrency } from '../../../utils/models/Currency';
import { roundFloatNumberLastSevenNumber } from '../../../utils/helper/roundFloatNumber';
import { helper } from '../../../utils/helper/helper';
import { currencyUZS } from '../../../utils/constants/currencyUZS';
import { useSaveCurrency } from '../../../queries/mutation';

const { Option } = Select;

interface IForm {
  value: string;
  basic: boolean;
}

const CurrencyModal = () => {
  const [form] = Form.useForm<IForm>();

  const dispatch = useAppDispatch();
  const visible = useAppSelector(state => state.currency.isModalOpen);
  const basic = useAppSelector(state => state.currency.mainCurrency);
  const selected = useAppSelector(state => state.currency.selectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState<RemoteCurrency | undefined>(undefined);

  const { data: currencyList, isLoading } = useCurrencyListGlobal();

  const { t } = useTranslation();

  const save = useSaveCurrency(handleSuccess);

  useEffect(() => {
    if (selected) {
      if (selected.ccy === currencyUZS.Ccy) {
        setSelectedCurrency(currencyUZS);
      } else {
        const item = currencyList?.find(item => item.Ccy === selected.ccy);
        setSelectedCurrency(item);
      }
      form.setFieldsValue({
        value: helper.localeStringSmallNumbers(selected.value),
        basic: selected.basic
      });
    }
  }, [selected, form]);

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  // handle cancel
  const handleCancel = () => {
    form.resetFields();
    setSelectedCurrency(undefined);
    dispatch(
      openModal({
        data: undefined,
        isOpen: false
      })
    );
  };

  // on finish
  const onFinish = (val: IForm) => {
    if (selected) {
      save.mutate({
        ...selected,
        name: {
          uz: selectedCurrency?.CcyNm_UZ || '',
          ru: selectedCurrency?.CcyNm_RU || '',
          kr: selectedCurrency?.CcyNm_UZC || '',
          en: selectedCurrency?.CcyNm_EN || '',
          tr: selectedCurrency?.CcyNm_TR || selectedCurrency?.CcyNm_EN || ''
        },
        basic: val.basic || false,
        ccy: selectedCurrency?.Ccy || '',
        value: helper.parseFloatStringToNumber(val.value)
      });
    } else {
      save.mutate({
        name: {
          uz: selectedCurrency?.CcyNm_UZ || '',
          ru: selectedCurrency?.CcyNm_RU || '',
          kr: selectedCurrency?.CcyNm_UZC || '',
          en: selectedCurrency?.CcyNm_EN || '',
          tr: selectedCurrency?.CcyNm_TR || selectedCurrency?.CcyNm_EN || ''
        },
        basic: val.basic || false,
        ccy: selectedCurrency?.Ccy || '',
        value: helper.parseFloatStringToNumber(val.value)
      });
    }
  };

  // handle change
  const handleChangeCurrency = (ccy: string) => {
    if (ccy === currencyUZS.Ccy) {
      const basicReal = currencyList?.find(item => item?.Ccy === basic?.ccy);
      setSelectedCurrency(currencyUZS);
      form.setFieldsValue({
        value: helper.localeStringSmallNumbers(roundFloatNumberLastSevenNumber(1 / Number(basicReal?.Rate || 1)))
      });
    }
    if (currencyList) {
      const item = currencyList.find(item => item.Ccy === ccy);
      const basicReal = currencyList?.find(item => item?.Ccy === basic?.ccy);

      if (item) {
        setSelectedCurrency(item);
        form.setFieldsValue({
          value: helper.localeStringSmallNumbers(roundFloatNumberLastSevenNumber(Number(item.Rate) * (1 / Number(basicReal?.Rate || 1))))
        });
      }
    }
  };

  return (
    <Modal className={styles.container} width={530} title={false} closable={false} footer={false} visible={visible} onCancel={handleCancel}>
      <h3 className={styles.container_title}>{t('currency.CCY')}</h3>
      <Form onFinish={onFinish} form={form} name={'currencyForm'}>
        <Select
          value={selectedCurrency?.Ccy}
          onChange={handleChangeCurrency}
          loading={isLoading}
          className={'customSelectCurrency'}
          placeholder={
            <div className={styles.container_item_currency}>
              <span className={styles.container_item_currency_name}>{t('currency.Valyuta_turini_tanlang')}</span>
            </div>
          }
        >
          <Option value={currencyUZS.Ccy}>
            <div className={styles.container_item_currency}>
              <img src={currencyFlags[`${currencyUZS.Ccy.toLowerCase()}`] || defaultImage} alt={currencyUZS.Ccy} />
              <span className={styles.container_item_currency_ccy}>{currencyUZS.Ccy.toUpperCase()}</span>
              <span className={styles.container_item_currency_name}>- {getCurrentCurrencyName(currencyUZS)}</span>
            </div>
          </Option>
          {currencyList?.map((currency, index) => (
            <Option key={index} value={currency.Ccy}>
              <div className={styles.container_item_currency}>
                <img src={currencyFlags[`${currency.Ccy.toLowerCase()}`] || defaultImage} alt={currency.Ccy} />
                <span className={styles.container_item_currency_ccy}>{currency.Ccy.toUpperCase()}</span>
                <span className={styles.container_item_currency_name}>- {getCurrentCurrencyName(currency)}</span>
              </div>
            </Option>
          ))}
        </Select>
        <h3 className={styles.container_title}>{t('currency.Asosiy_valyuta')}</h3>
        <div className={'d-flex align-center justify-space-between ' + styles.container_margin_bottom}>
          <Input
            value={1}
            disabled={true}
            className={styles.container_input}
            suffix={<span className={styles.container_currency_ccy}>{selectedCurrency?.Ccy}</span>}
          />
          <EllipseRightIcon />
          <Form.Item
            label={''}
            name={'value'}
            rules={[
              { required: true, message: '' },
              {
                validator: (rule, value) => {
                  if (value === undefined || value === null) {
                    return Promise.reject('');
                  }
                  form.setFieldsValue({
                    value: helper.deleteNotNumbersAndParseFloatSmallNumber(value).string
                  });
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              disabled={!selectedCurrency}
              className={styles.container_input}
              suffix={<span className={styles.container_currency_ccy}>{basic?.ccy}</span>}
            />
          </Form.Item>
        </div>
        <div className={'d-flex align-center justify-space-between'}>
          <h3 className={styles.container_title}>{t('currency.Asosiy_valyuta')}</h3>
          <Form.Item label={''} name={'basic'}>
            <Switch />
          </Form.Item>
        </div>
        <div className={'d-flex align-center justify-end ' + styles.container_margin_top}>
          <Button className={styles.container_button} onClick={handleCancel}>
            {t('arrearage.Yopish')}
          </Button>
          <Button loading={save.isLoading} className={styles.container_button} type="primary" htmlType="submit">
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CurrencyModal;
