import dayjs from 'dayjs';

export const dateOptions = [
    { label: 'online_pbx.15_daqiqa_oldin', value: dayjs().subtract(15, 'minute') },
    { label: 'online_pbx.30_daqiqa_oldin', value: dayjs().subtract(30, 'minute') },
    { label: 'online_pbx.1_soat_oldin', value: dayjs().subtract(1, 'hour') },
    { label: 'one_lid.Bugun', value: dayjs().startOf('day') },
    { label: 'one_lid.Kecha', value: dayjs().subtract(1, 'day').startOf('day') },
    { label: 'online_pbx.Joriy_hafta', value: dayjs().startOf('week').add(1, 'day') },
    { label: 'online_pbx.Otgan_hafta', value: dayjs().subtract(1, 'week').startOf('week').add(1, 'day') },
    { label: 'online_pbx.Joriy_oy', value: dayjs().startOf('month') },
];