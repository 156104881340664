import React from 'react';
import styles from './addCardPopover.module.scss';
import { useTranslation } from 'react-i18next';
import Payme from '../../../../../assets/icons/Payme';
import Uzcard from '../../../../../assets/icons/Uzcard';

interface Props {
  addPayme: () => void;
  addMyUzcard: () => void;
}

const AddCardPopover: React.FC<Props> = ({ addMyUzcard, addPayme }) => {
  const { t } = useTranslation();

  // handle add payme
  const handleAddPayme = () => {
    addPayme();
  };

  // handle add my uzcard
  const handleAddMyUzcard = () => {
    addMyUzcard();
  };

  return (
    <div className={styles.container}>
      <div onClick={handleAddPayme} className={styles.container_payme}>
        <Payme />
        <p>
          <span>{t('uzcard.Payme')}</span>
          {t('uzcard.Payme_title')}
        </p>
      </div>
      <div onClick={handleAddMyUzcard} className={styles.container_uzcard}>
        <Uzcard />
        <p>
          <span>{t('uzcard.MyUzcard')}</span>
          {t('uzcard.MyUzcard_title')}
        </p>
      </div>
    </div>
  );
};

export default AddCardPopover;
