import React, { useState } from 'react';
import { Dropdown, Input } from 'antd';

import styles from '../triggers.module.scss';
import CheckmarkIcon from '../../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { ExecAfterStatus } from '../../../../../../../utils/models/Trigger';
import { setExecAfter, setExecAfterStatus } from '../../../../../../../store/reducers/TriggerSlice';
import { helper } from '../../../../../../../utils/helper/helper';
import { useTranslation } from 'react-i18next';

const TimeDropdown = () => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const { t } = useTranslation();

  const status = useAppSelector(state => state.triggerSlice.execAfterStatus);
  const time = useAppSelector(state => state.triggerSlice.execAfter);

  const dispatch = useAppDispatch();

  // handle change status
  const handleChangeStatus = (newStatus: ExecAfterStatus | undefined) => {
    dispatch(setExecAfterStatus(newStatus));
    handleOpenDropdown(false);
  };

  // handle open dropdown
  const handleOpenDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  // title
  const title = () => {
    switch (status) {
      case ExecAfterStatus.NOW:
        return t('triggers.NOW');
      case ExecAfterStatus.AFTER_5_MINUTES:
        return t('triggers.AFTER_5_MINUTES');
      case ExecAfterStatus.AFTER_10_MINUTES:
        return t('triggers.AFTER_10_MINUTES');
      case ExecAfterStatus.AFTER_DAY:
        return t('triggers.AFTER_DAY');
      case undefined:
        return t('triggers.keyin');
      default:
        return t('triggers.tanlang');
    }
  };

  // handle change time
  const handleChangeTime = (value: string, type: 'h' | 'm') => {
    const newValue = Number(helper.deleteNotNumbers(value, 0));
    let hour = Math.floor((time || 0) / 60);
    let minute = Math.floor((time || 0) - hour * 60);
    if (type === 'h') {
      hour = newValue;
    } else {
      minute = newValue;
    }
    dispatch(setExecAfter(hour * 60 + minute));
  };

  // get time
  const getTime = (type: 'h' | 'm') => {
    const hour = Math.floor((time || 0) / 60);
    const minute = (time || 0) % 60;
    if (type === 'h') return String(hour);
    return String(minute);
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement={'bottom'}
        visible={openDropdown}
        onVisibleChange={handleOpenDropdown}
        overlayClassName={styles.container_body_time_dropdown}
        overlay={
          <>
            <div
              onClick={e => {
                handleChangeStatus(ExecAfterStatus.NOW);
                e.stopPropagation();
              }}
              className={styles.container_body_time_dropdown_item}
            >
              <span className={styles.container_body_time_dropdown_item_name}>{t('triggers.NOW')}</span>
              {status === ExecAfterStatus.NOW && <CheckmarkIcon className={styles.container_body_time_dropdown_item_icon} />}
            </div>
            <div
              onClick={e => {
                handleChangeStatus(ExecAfterStatus.AFTER_5_MINUTES);
                e.stopPropagation();
              }}
              className={styles.container_body_time_dropdown_item}
            >
              <span className={styles.container_body_time_dropdown_item_name}>{t('triggers.AFTER_5_MINUTES')}</span>
              {status === ExecAfterStatus.AFTER_5_MINUTES && <CheckmarkIcon className={styles.container_body_time_dropdown_item_icon} />}
            </div>
            <div
              onClick={e => {
                handleChangeStatus(ExecAfterStatus.AFTER_10_MINUTES);
                e.stopPropagation();
              }}
              className={styles.container_body_time_dropdown_item}
            >
              <span className={styles.container_body_time_dropdown_item_name}>{t('triggers.AFTER_10_MINUTES')}</span>
              {status === ExecAfterStatus.AFTER_10_MINUTES && <CheckmarkIcon className={styles.container_body_time_dropdown_item_icon} />}
            </div>
            <div
              onClick={e => {
                handleChangeStatus(ExecAfterStatus.AFTER_DAY);
                e.stopPropagation();
              }}
              className={styles.container_body_time_dropdown_item}
            >
              <span className={styles.container_body_time_dropdown_item_name}>{t('triggers.AFTER_DAY')}</span>
              {status === ExecAfterStatus.AFTER_DAY && <CheckmarkIcon className={styles.container_body_time_dropdown_item_icon} />}
            </div>
            <div
              onClick={e => {
                handleChangeStatus(undefined);
                e.stopPropagation();
              }}
              className={styles.container_body_time_dropdown_item}
            >
              <span className={styles.container_body_time_dropdown_item_name}>{t('triggers.intervalni_tanlash')}</span>
              {status === undefined && <CheckmarkIcon className={styles.container_body_time_dropdown_item_icon} />}
            </div>
          </>
        }
      >
        <span onClick={() => handleOpenDropdown(true)} className={styles.container_body_item_dropdown_item_label}>
          {title()}
        </span>
      </Dropdown>
      {!status && (
        <div className={styles.container_body_item_dropdown_item_input_cont}>
          <Input
            value={getTime('h')}
            onChange={e => handleChangeTime(e.target.value, 'h')}
            className={styles.container_body_item_dropdown_item_input}
            placeholder={t('triggers.soat')}
          />
          <Input
            value={getTime('m')}
            onChange={e => handleChangeTime(e.target.value, 'm')}
            className={styles.container_body_item_dropdown_item_input}
            placeholder={t('triggers.daqiqa')}
          />
        </div>
      )}
      <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />
    </>
  );
};

export default TimeDropdown;
