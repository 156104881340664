import { FileObject } from './File';

export interface GalleryShow {
  id?: number;
  position: GalleryPosition;
  queue: number;
  files: FileObject;
}

export interface GallerySave {
  id?: number;
  position: GalleryPosition;
  queue: number;
  fileID: number;
  files?: FileObject;
}

export enum GalleryPosition {
  FLAT_PLAN = 'FLAT_PLAN',
  FLAT_FLOOR = 'FLAT_FLOOR',
  FLAT_3D = 'FLAT_3D',
  FLAT_EXTRA = 'FLAT_EXTRA',
  HOUSE_PLAN = 'HOUSE_PLAN',
  HOUSE_MAIN = 'HOUSE_MAIN',
  HOUSE_3D = 'HOUSE_3D',
  HOUSE_EXTRA = 'HOUSE_EXTRA',
  CLIENT_PASSPORT_FRONT = 'CLIENT_PASSPORT_FRONT',
  CLIENT_PASSPORT_BACK = 'CLIENT_PASSPORT_BACK',
  CLIENT_PASSPORT_EXTRA = 'CLIENT_PASSPORT_EXTRA',
  HOUSE_LOGO = 'HOUSE_LOGO'
}
