import styles from './showroomVisual.module.scss';
import ShowroomV2Layout from '@pages/showroom-v2/components/showroom-v2-layout/ShowroomV2Layout';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { showroomVisualRoutes } from './utils/constants/showroomVisualRoutes';
import AccessDenied from '@components/accessDenied/AccessDenied';
import { useEffect } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { rootPaths } from '@routing/rootPaths';
import { ShowroomPaths } from '@routing/showroom/showroomPaths';
import { showroomVisualPath } from './utils/constants/showroomVisualPath';

const ShowroomVisual = () => {
  const { filter } = useAppSelector(state => state.showroomSlice);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(rootPaths.SHOWROOM + ShowroomPaths.VISUAL + showroomVisualPath.INDEX);
  }, [filter.houseId]);
  return (
    <ShowroomV2Layout>
      <Routes>
        {showroomVisualRoutes.map(route => (
          <Route element={<route.component />} path={route.exact ? route.path : `${route.path}/*`} key={route.path} />
        ))}
        <Route path={'*'} element={<AccessDenied />} />
      </Routes>
    </ShowroomV2Layout>
  );
};

export default ShowroomVisual;
