const ClipboardTextIcon = ({ className }: { className?: string }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M4.66663 7.11621H8.74996" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.66663 9.4502H7.22163" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5.83329 3.50033H8.16663C9.33329 3.50033 9.33329 2.91699 9.33329 2.33366C9.33329 1.16699 8.74996 1.16699 8.16663 1.16699H5.83329C5.24996 1.16699 4.66663 1.16699 4.66663 2.33366C4.66663 3.50033 5.24996 3.50033 5.83329 3.50033Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33333 2.34473C11.2758 2.44973 12.25 3.16723 12.25 5.83306V9.33306C12.25 11.6664 11.6667 12.8331 8.75 12.8331H5.25C2.33333 12.8331 1.75 11.6664 1.75 9.33306V5.83306C1.75 3.17306 2.72417 2.44973 4.66667 2.34473"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClipboardTextIcon;
