import React, { useEffect, useLayoutEffect, useState } from 'react';
import { usePaymentFieldGet } from '@pages/setting/payment-changes/services/queries';
import { getNameTrl } from '@utils/helper/getNameTrl';
import { PaymentList } from '@utils/models/PaymentList';
import { Button, Checkbox, Drawer, Select, Spin, Switch } from 'global';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import CheveronTop from '../../assets/icons/CheevronTop';
import CheveronBottom from '../../assets/icons/CheveronBottom';
import CheveronRight from '../../assets/icons/CheveronRight';
import DotsSixVertical from '../../assets/icons/DotsSixVertical';
import FilterReloadIcon from '../../assets/icons/FilterReloadIcon';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useTableConfigMutation } from '../../queries/mutation';
import { useTableConfig } from '../../queries/queries';
import { Permissions } from '../../utils/constants/permissions';
import { tableExamples } from '../../utils/constants/tableExamples';
import { TableConfig, TableConfigValue } from '../../utils/models/TableConfig';
import { TableKey } from '../../utils/models/TableKey';

import styles from './tableConfig.module.scss';

const { Option } = Select;
const defaultMeArr = ['contract', 'arrearage'];

interface Props {
  tableKey: TableKey;
  visible: boolean;
  onClose: (isOpen: boolean) => void;
  onSuccess?: (size: number) => void;
  tableData?: PaymentList[] | undefined;
}

const TableConfigDrawer: React.FC<Props> = ({ onClose, visible, tableKey, onSuccess }) => {
  const { appendMultiple, removeMultiple, searchParams } = useQueryParam<any, any>();
  const [openItem, setOpenItem] = useState({
    open: false,
    id: 0
  });

  const [tableSize, setTableSize] = useState(10);
  const [allUser, setAllUser] = useState(false);
  const [defaultMe, setDefaultMe] = useState(false);
  const [tableConfig, setTableConfig] = useState<TableConfig[]>();

  const { data: customFields } = usePaymentFieldGet();

  // handle success
  function handleSuccess() {
    if (onSuccess) {
      onSuccess(tableSize);
    }
  }
  const saveTableConfigMutation = useTableConfigMutation(tableKey, handleSuccess);
  const { isLoading, isFetching, data } = useTableConfig(tableKey);
  const { t } = useTranslation();
  const user = useAppSelector(state => state.userReducer.user);

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  useEffect(() => {
    if (!isFetching && !isLoading && data) {
      setTableConfig(data?.config!);
      setTableSize(data.size);

      if (defaultMeArr.includes(tableKey)) {
        setDefaultMe(!!data?.defaultMe);
        if (data?.defaultMe && !searchParams?.responsibleByIds) {
          appendMultiple('responsibleByIds', user?.id);
        }
      }
    }

    if (tableKey === 'payment' && data) {
      // Update the show property of existing custom fields in tableConfig
      const updatedTableConfig = data?.config!?.map((el: { id: string; field: any }) => {
        const matchingCustomField = customFields?.find(item => `field_${item.id}` === el.id);

        if (matchingCustomField && el.field) {
          return {
            ...el,
            show: matchingCustomField.show
          };
        }
        return el;
      });

      // Check if there are any new custom fields added
      const newCustomFields = customFields?.filter(el => !data.config!?.some((item: { id: string }) => item.id === `field_${el.id}`));

      if (newCustomFields && newCustomFields.length > 0) {
        // Handle new custom fields addition logic here if needed
        // For example:
        const newlyAddedFields = newCustomFields.map(newField => ({
          id: `field_${newField.id}`,
          name: getNameTrl(newField),
          checked: true,
          field: true,
          show: newField.show
        }));

        const mergedConfig = [...updatedTableConfig, ...newlyAddedFields];

        // Set the merged configuration as the new tableConfig
        setTableConfig(mergedConfig);
      }
    }
  }, [isLoading, isFetching, data, tableKey, customFields, visible]);

  const handleChangeSize = (value: number) => {
    setTableSize(value);
  };

  const handleChangeAllUser = (value: boolean) => {
    setAllUser(value);
  };

  const handleChangeTableConfig = (id: any, checked: boolean) => {
    const newState = [...(tableConfig || [])];
    const index = newState.findIndex(item => item.id === id);

    newState[index].checked = checked;
    setTableConfig(newState);
  };

  useLayoutEffect(() => {
    if (tableExamples[tableKey]) {
      setTableConfig([...tableExamples[tableKey]]);
    }
  }, [tableExamples[tableKey]]);

  const handleChangeTableConfigInsider = (id: any, checked: boolean, index: number) => {
    const newState = [...(tableConfig || [])];
    const findIndex = newState.findIndex(item => item.id === id);

    if (newState[findIndex] && newState[findIndex].values) {
      // @ts-ignore
      newState[findIndex].values[index].checked = checked;
    }

    let isHaveTrue = false;

    // @ts-ignore
    newState[findIndex].values.forEach(item => {
      if (item.checked) {
        isHaveTrue = true;
      }
    });

    newState[findIndex].checked = isHaveTrue;

    setTableConfig(newState);
  };

  const handleSaveTableConfig = () => {
    const TableConfigValue: TableConfigValue = {
      config: tableConfig,
      size: tableSize,
      ...(defaultMeArr.includes(tableKey) && { defaultMe })
    };

    saveTableConfigMutation
      .mutateAsync({
        key: tableKey,
        value: JSON.stringify(TableConfigValue),
        allUser
      })
      .then(() => {
        if (!defaultMe) {
          removeMultiple(['responsibleByIds'], ['']);
        }
        onClose(false);
      });
  };

  const handleCancel = () => {
    const newConfig: TableConfig[] = [];

    const table = [...tableExamples[tableKey]];

    for (let i = 0; i < table.length; i++) {
      const value: {
        name: string;
        checked: boolean;
      }[] = [];
      const oldValues = table[i].values;

      if (oldValues) {
        oldValues.forEach(item => {
          value.push({
            name: item.name,
            checked: true
          });
        });
      }
      if (value.length > 0) {
        newConfig.push({
          ...table[i],
          checked: true,
          values: value as any
        });
      } else {
        newConfig.push({
          ...table[i],
          checked: true
        });
      }
    }

    setTableSize(10);
    setTableConfig(newConfig);
  };

  const isAccessSaveAll = permissions?.includes(Permissions.PERMISSION_TABLE_SETTINGS);

  // handle drag end
  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // const newConfig: TableConfig[] = []

    const isDestinationItemWithId1 = tableConfig![destination!.index].id === 1;

    if (isDestinationItemWithId1) {
      // If the destination is an item with ID 1, prevent the move and return
      return;
    }

    if (
      source?.index < 0 ||
      source?.index >= tableConfig!.length ||
      destination!?.index < 0 ||
      destination!?.index >= tableConfig!.length ||
      source?.index === destination!?.index
    ) {
      return;
    }

    // Remove the element from the source position
    const updatedTableConfig = [...(tableConfig || [])]; // Create a copy of the array
    const movedElement = updatedTableConfig.splice(source?.index, 1)[0];

    updatedTableConfig.splice(destination!?.index, 0, movedElement);

    // Update the state with the new tableConfig
    setTableConfig(updatedTableConfig);
  };

  const handleClear = () => {
    const newConfig: TableConfig[] = [];

    const table = [...tableExamples[tableKey]];

    for (let i = 0; i < table.length; i++) {
      const value: {
        name: string;
        checked: boolean;
      }[] = [];
      const oldValues = table[i].values;

      if (oldValues) {
        oldValues.forEach(item => {
          value.push({
            name: item.name,
            checked: true
          });
        });
      }
      if (value.length > 0) {
        newConfig.push({
          ...table[i],
          checked: true,
          values: value as any
        });
      } else {
        newConfig.push({
          ...table[i],
          checked: true
        });
      }
    }

    const TableConfigValue: TableConfigValue = {
      config: newConfig,
      size: 10,
      ...(defaultMeArr.includes(tableKey) && { defaultMe: false })
    };

    saveTableConfigMutation
      .mutateAsync({
        key: tableKey,
        value: JSON.stringify(TableConfigValue),
        allUser: false
      })
      .then(() => {
        if (!defaultMe) {
          removeMultiple(['responsibleByIds'], ['']);
        }
        onClose(false);
      });
  };

  return (
    <Drawer width={270} headerStyle={{ display: 'none' }} placement="right" className="drawer-table-config" onClose={() => onClose(false)} visible={visible}>
      <h2 className={styles.title}>
        {t('home.Jadval_sozlamalari')}{' '}
        <span className={`${styles.title_clear} ${saveTableConfigMutation.isLoading ? styles.title_clear_load : ''}`} onClick={handleClear}>
          <FilterReloadIcon />
        </span>
      </h2>
      <Spin spinning={isLoading || isFetching}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={styles.draggableCon}>
                {tableConfig?.map((item, index) => (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index} isDragDisabled={String(item?.id) === '1'}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          width: '243px',
                          paddingRight: '3px',
                          ...provided.draggableProps.style
                        }}
                      >
                        <div className={`d-flex align-center justify-space-between ${styles.item}`}>
                          <div className="d-flex align-center">
                            <span {...provided.dragHandleProps}>
                              <DotsSixVertical />
                            </span>
                            {/* Ro'yxatdagilar ko'rinadigan joy */}
                            <span className={`${styles.item__title} ${tableKey === TableKey.payment && !item?.show && item.field ? styles.item__inActive : ''}`}>
                              {!String(item?.id)?.includes('field') ? t(`home.${item.name}`) : item?.name}
                            </span>
                          </div>
                          <div
                            className="d-flex align-center"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <Switch size="small" checked={item.checked} onChange={checked => handleChangeTableConfig(item.id, checked)} />
                            {item.values && (
                              <>
                                {openItem.open && openItem.id === item.id ? (
                                  <CheveronTop
                                    className={styles.item__icon}
                                    onClick={() => {
                                      setOpenItem({ open: false, id: 0 });
                                    }}
                                  />
                                ) : (
                                  <CheveronRight
                                    onClick={e => {
                                      setOpenItem({
                                        open: true,
                                        id: item.id
                                      });
                                      e.stopPropagation();
                                    }}
                                    className={styles.item__icon}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {openItem.open && item.id === openItem.id && (
                          <div className={styles.item_inside_cont}>
                            {item.values &&
                              item.values.map((value, index) => (
                                <div className={`d-flex align-center ${styles.item_inside}`} key={value.name}>
                                  <Checkbox
                                    onChange={checked => handleChangeTableConfigInsider(item.id, checked.target.checked, index)}
                                    className={styles.item_inside_checkbox}
                                    checked={value.checked}
                                  />
                                  <span className={styles.item_inside_title}>{t(`home.${value.name}`)}</span>
                                </div>
                              ))}
                          </div>
                        )}
                        {item.id === 1 && <hr className={styles.item__hr} />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Spin>

      <div className={styles.line} />

      <div className="d-flex align-center justify-space-between">
        <h3 className={styles.page_size_title}>{t('home.Sahifa_hajmi')}</h3>
        <Select className={styles.page_size_select} suffixIcon={<CheveronBottom />} onChange={handleChangeSize} value={tableSize}>
          <Option value={10}>10</Option>
          <Option value={15}>15</Option>
          <Option value={20}>20</Option>
          <Option value={25}>25</Option>
        </Select>
      </div>

      {isAccessSaveAll && (
        <div className="d-flex" style={{ marginTop: '16px' }}>
          <Checkbox checked={allUser} onChange={e => handleChangeAllUser(e.target.checked)} />
          <p className={styles.save_all_title}>{t('home.Bu_sozlamalarni_barcha_foydalanuvchilar_uchun_qollash')}</p>
        </div>
      )}

      {defaultMeArr.includes(tableKey) ? (
        <div className="d-flex" style={{ marginTop: '16px' }}>
          <Checkbox checked={defaultMe} onChange={e => setDefaultMe(e.target.checked)} />
          <p className={styles.save_all_title}>{t('home.Birlamchi_meniki_rejimi')}</p>
        </div>
      ) : (
        ''
      )}

      <div className={`${styles.footer} d-flex align-center`}>
        <Button onClick={handleCancel} className={styles.footer_back}>
          {t('home.Bekor_qilish')}
        </Button>
        <Button onClick={handleSaveTableConfig} loading={saveTableConfigMutation.isLoading} type="primary" className={styles.footer_save}>
          {t('home.Saqlash')}
        </Button>
      </div>
    </Drawer>
  );
};

export default TableConfigDrawer;
