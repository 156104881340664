import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloseOutlined } from '@ant-design/icons';

import styles from './headerSettings.module.scss';
import { PageNameLidInformation } from '../InformationContainer';

interface Props {
  onChangePage: (page: PageNameLidInformation) => void;
}

const HeaderSetting: React.FC<Props> = ({ onChangePage }) => {
  const { t } = useTranslation();

  // go to setting
  const goToInformation = () => {
    onChangePage(PageNameLidInformation.INFORMATION);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_title_cont}>
        <div className={styles.container_title_cont_title}>
          <h2>{t('one_lid.Maydon_va_guruh_xususiyatlarini_sozlash')}</h2>
          <p>
            {t(
              'one_lid.Buyerda_siz_ozingizning_noyob_maydonlaringizni_qoshishingiz_va_ularni_har_qanday_filtr_va_hisobotlar_uchun_ishlatishingiz_mumkin'
            )}
          </p>
        </div>
        <div>
          <CloseOutlined onClick={goToInformation} className={styles.container_title_cont_close} />
        </div>
      </div>
      <div className={styles.container_main}>{t('one_lid.Asosiy_malumot')}</div>
    </div>
  );
};

export default HeaderSetting;
