import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowDownBigIcon from '@icons/ArrowDownBigIcon';
import CloseIcon from '@icons/CloseIcon';
import ArrowIosDownIcon from '@icons/contract/ArrowIosDownIcon';
import { useShouroomVisualXAuthToken } from '@pages/showroom-v2/services/mutation';
import { useSmartCatalogBuilding, useSmartCatalogHouse } from '@pages/showroom-v2/services/queries';
import { setResetActiveFlat, setShowroomFilter, setShowroomNewFilter, setShowroomVisualXAuthToken } from '@reducers/ShowroomSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { Permissions } from '@utils/constants/permissions';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import Logo from '@images/logo.png';

import styles from './showroomV2Header.module.scss';

const ShowroomV2Header = () => {
  const [houseVisibleDropdown, setHouseVisibleDropdown] = useState(false);
  const [buildingVisibleDropdown, setBuildingVisibleDropdown] = useState(false);
  const dispatch = useAppDispatch();
  const { filter, token } = useAppSelector(state => state.showroomSlice);
  const { data: houses } = useSmartCatalogHouse();
  const { data: buildings } = useSmartCatalogBuilding(filter?.houseId ? filter?.houseId[0] : 0);
  const { searchParams, push, location } = useQueryParam<any, any>();
  const xAuthToken = useShouroomVisualXAuthToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessPayment = useMemo(() => permissions?.includes(Permissions.PERMISSION_READ_FLATS), [permissions]);
  const closeShowroom = () => {
    const { prev } = searchParams;

    if (prev) {
      push(atob(String(prev)));
    } else {
      push('/');
    }
  };

  useEffect(() => {
    if (!filter.houseId && houses && houses?.length > 0) {
      dispatch(setShowroomFilter({ houseId: [houses[0]?.id] }));
    }
  }, [filter, houses]);

  useEffect(() => {
    if (houses && filter?.houseId![0]) {
      const companyId = houses?.find(item => item?.id === filter?.houseId![0])?.companyId;

      if (companyId && token?.companyId !== companyId) {
        xAuthToken.mutateAsync().then(value => {
          dispatch(
            setShowroomVisualXAuthToken({
              companyId,
              xAuthToken: value?.find((item: any) => item?.company?.id === companyId)?.token || ''
            })
          );
        });
      }
    }
  }, [location.pathname, filter?.houseId, token]);

  const gethouse = filter?.houseId && filter?.houseId?.length > 0 ? houses?.find(house => house?.id === filter?.houseId![0]) : undefined;

  const getBuilding =
    filter?.buildingIds && filter?.buildingIds?.length > 0 ? buildings?.find(building => building?.id === filter?.buildingIds![0]) : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div
          className={styles.logo}
          onClick={() => (isAccessPayment ? navigate(rootPaths.MAIN + MainPaths.INDEX) : navigate(rootPaths.MAIN))}
          style={{ cursor: 'pointer ' }}
        >
          <img src={Logo} alt="" />
        </div>
        <Dropdown
          visible={houseVisibleDropdown}
          onVisibleChange={setHouseVisibleDropdown}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.5}
          overlay={
            <div className={styles.dropdown}>
              {houses?.map(item => (
                <div
                  key={item.id}
                  className={styles.dropdown_item}
                  style={{
                    background: gethouse?.id === item?.id ? '#cccccc' : ''
                  }}
                  onClick={() => {
                    dispatch(
                      setShowroomFilter({
                        houseId: [item?.id],
                        buildingIds: undefined
                      })
                    );
                    dispatch(setShowroomNewFilter({}));
                    dispatch(setResetActiveFlat());
                    setHouseVisibleDropdown(false);
                  }}
                >
                  <h3>{item.name}</h3>
                  <p>
                    {item?.companyName && <span>{item?.companyName}</span>}
                    <span>
                      {item?.address?.full[i18n.language as keyof typeof item.address.full]?.length > (item?.companyName ? 30 : 42)
                        ? `${item?.address?.full[i18n.language as keyof typeof item.address.full]?.slice(0, item?.companyName ? 30 : 42)}...`
                        : item?.address?.full[i18n.language as keyof typeof item.address.full]}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          }
          trigger={['click', 'hover']}
        >
          <div className={styles.card}>
            <div className={styles.card_left}>
              <h3>{gethouse?.name}</h3>
              <p>
                {' '}
                {gethouse?.address?.full[i18n.language as keyof typeof gethouse.address.full]!?.length > (gethouse?.companyName ? 30 : 42)
                  ? `${gethouse?.address?.full[i18n.language as keyof typeof gethouse.address.full]?.slice(0, gethouse?.companyName ? 30 : 42)}...`
                  : gethouse?.address?.full[i18n.language as keyof typeof gethouse.address.full]}
              </p>
            </div>
            <div className={styles.card_right}>
              <ArrowIosDownIcon size={24} />
              <ArrowDownBigIcon />
            </div>
          </div>
        </Dropdown>
        <Dropdown
          visible={buildingVisibleDropdown}
          onVisibleChange={setBuildingVisibleDropdown}
          mouseEnterDelay={1000}
          mouseLeaveDelay={0.5}
          overlay={
            <div className={styles.dropdown}>
              <div
                className={styles.dropdown_item}
                style={{
                  background: !getBuilding ? '#cccccc' : ''
                }}
                onClick={() => {
                  dispatch(setShowroomFilter({ buildingIds: undefined }));
                  setBuildingVisibleDropdown(false);
                }}
              >
                <h3>{t('home.Barchasi')}</h3>
              </div>
              {buildings?.map(item => (
                <div
                  key={item.id}
                  className={styles.dropdown_item}
                  style={{
                    background: getBuilding?.id === item?.id ? '#cccccc' : ''
                  }}
                  onClick={() => {
                    dispatch(setShowroomFilter({ buildingIds: [item?.id] }));
                    setBuildingVisibleDropdown(false);
                  }}
                >
                  <h3>{item?.name}</h3>
                </div>
              ))}
            </div>
          }
          trigger={['click', 'hover']}
        >
          <div className={styles.card}>
            <div className={styles.card_left}>
              <h3>{t('home_id.Bino')} </h3>
              <p> {getBuilding?.name || t('home.Barchasi')}</p>
            </div>
            <div className={styles.card_right}>
              <ArrowIosDownIcon size={24} />
              <ArrowDownBigIcon />
            </div>
          </div>
        </Dropdown>
      </div>
      <div className={styles.right}>
        {/* <div className={styles.showroomTime}>
          {' '}
          <DangerBackgroundWhiteIcon /> {t('showroom.Sinov_muddati_tugashiga')}
          {' ' + 10 + ' '}
          {t('showroom.kun_qoldi')}
        </div> */}
        {/* <Button
          type="primary"
          className={styles.oldShowroom}
          onClick={() => {
            push(rootPaths.SHOWROOM);
            dispatch(setShowroomV2(false));
          }}
        >
          {t('home.Eski_Showroom')}
        </Button> */}
        <div className={styles.close} onClick={closeShowroom}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default ShowroomV2Header;
