import React from 'react';
import { Layout } from 'antd';

import BossLineChart from '../../components/bossArrearage/BossLineChart';
import BossTable from '../../components/bossArrearage/BossTable';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';

import styles from './bossArrearage.module.scss';

const { Content } = Layout;

const BossArrearage = () => (
  <>
    <MainHeader>
      <BreadcrumbHeader />
      <NotificationButtons />
    </MainHeader>
    <Content
      style={{
        padding: '1rem 2.68rem',
        overflowY: 'auto',
        height: '50vh'
      }}
    >
      <div className={styles.container}>
        <BossLineChart />
        <BossTable />
      </div>
    </Content>
  </>
);

export default BossArrearage;
