import React from 'react';

const SuccessBoldIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 10.1V5.9C15 2.4 13.6 1 10.1 1H5.9C2.4 1 1 2.4 1 5.9V10.1C1 13.6 2.4 15 5.9 15H10.1C13.6 15 15 13.6 15 10.1Z"
        stroke="#000000"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.08008 8.00005L7.03008 9.95005L10.9201 6.05005" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SuccessBoldIcon;
