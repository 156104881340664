import React from 'react';
import { Col, Row, Spin } from 'antd';
import ClientAndContractInfo from '../ClientAndContractInfo';
import styles from './montylyPayments.module.scss';
import Cards from './Cards';
import Buttons from './Buttons';
import TableMonthlyPayments from './TableMonthlyPayments';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import { useTablePaymentsChange } from '../../services/queries';

const MonthlyPayments = () => {
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();
  const { isLoading, isFetching } = useTablePaymentsChange(searchParams.contractId);

  return (
    <Spin spinning={isLoading && isFetching}>
      <div className={styles.cont}>
        <Row gutter={36}>
          <Col span={15}>
            <Cards />
            <Buttons />
            <TableMonthlyPayments />
          </Col>
          <Col span={9}>
            <ClientAndContractInfo />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default MonthlyPayments;
