import { createContext } from 'react';

export interface Methods {}

export interface IContext extends Methods {
  objectTypes: Record<string, string>;
}

const Context = createContext<IContext>({} as IContext);

export default Context;
