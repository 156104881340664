import React, { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { ResponsivePie } from '@nivo/pie';
import { useGetCallsStatus } from '@pages/one-campagin/services/queries';
import { colorCallsStatus } from '@utils/constants/colorPieChart';
import { colorGenerator } from '@utils/helper/colorGenerator';
import { PieChartData } from '@utils/models/PieChartData';
import { Empty } from 'antd';
import { t } from 'i18next';

import { robotoStatuses } from '../calls-table/CallsTable';

const CallsStatus = () => {
  const { params } = useQueryParam<any, any>();

  const { data } = useGetCallsStatus(Number(params?.robotoCallsId));

  const [chartData, setChartData] = useState<PieChartData[]>([]);

  useEffect(() => {
    if (data) {
      const newData: PieChartData[] = [];

      data.forEach((house, index) => {
        let color = colorCallsStatus[index];

        if (index > 8) {
          color = `#${colorGenerator()}`;
        }
        newData.push({
          id: String(house.status),
          label: house.status,
          value: house.count,
          color
        });
      });
      setChartData(newData);
    }
  }, [data]);

  return (
    <div className="flex flex-col items-center rounded-xl bg-white">
      <div className="w-full border-b border-l-0 border-r-0 border-t-0 border-solid border-b-slate-200 p-4 text-lg font-medium text-[#344054]">{t('home.Qongroqlar_holati')}</div>
      {data && data?.length > 0 ? (
        <div className="flex w-full items-center justify-between gap-10 px-8">
          <div className="relative flex h-[260px] !min-w-[350px] items-center justify-center">
            <ResponsivePie
              data={chartData || []}
              margin={{ top: 20, right: 30, bottom: 20, left: 30 }}
              innerRadius={0.5}
              padAngle={0}
              cornerRadius={0}
              activeOuterRadiusOffset={4}
              borderWidth={0}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.2]]
              }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', 2]]
              }}
              colors={{ datum: 'data.color' }}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              tooltip={e => (
                <div className="flex items-center gap-2 rounded-md bg-white p-2 shadow-sm">
                  <div className="h-3 w-3" style={{ backgroundColor: e?.datum?.color }} />
                  <p className="text-base text-[#464255]">{robotoStatuses[e?.datum?.data?.id as keyof typeof robotoStatuses]?.label}</p>
                  <p>{e?.datum?.data?.value}</p>
                </div>
              )}
            />

            <div className="absolute top-[] flex flex-col items-center justify-center">
              <p className="text-[32px] font-semibold text-[#2C2C2C]">{chartData?.reduce((acc, d) => acc + d?.value, 0)}</p>
              <p className="text-base text-[#464255]">{t('home.Jami')}</p>
            </div>
          </div>
          <div className="flex w-full flex-col items-center justify-between">
            {chartData?.map(item => (
              <div key={item?.id} className="flex w-full items-center justify-between px-4 py-2">
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 rounded-full" style={{ borderColor: item?.color, border: `4px solid ${item?.color}` }} />
                  <p className="text-base text-[#464255]">{t(`home.${robotoStatuses[item?.id as keyof typeof robotoStatuses]?.label}`)}</p>
                </div>
                <span className="text-base font-semibold text-[#2C2C2C]">{item?.value}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex h-full flex-col items-center justify-center py-10">
          <Empty />
        </div>
      )}
    </div>
  );
};

export default CallsStatus;
