import { useQueryParam } from '@hooks/useQueryParams';
import { useQuery } from 'react-query';

import { $api } from '../../../../service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { BossPaymentStatisticModel } from '../utils/models/BossPaymentStatisticModel';

export function useBossPaymentStatistic(date: string | undefined, house: number[] | null, currency: string | undefined) {
  const { generateSearchParam } = useQueryParam();
  // const houseList = house ? house?.map(item => `&houses=${item}`).join('') : '';

  return useQuery<BossPaymentStatisticModel>([queryKeys.BOSS_PAYMENT_STATISTIC, date, house, currency], async () => {
    const res = await $api.get(
      `${endPoints.BOSS_PAYMENT_STATISTIC}?${generateSearchParam({
        date,
        currencyId: currency,
        houses: house
      })}`
    );

    return res.data.data;
  });
}
