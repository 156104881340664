import React from 'react';

type Props = {
  if?: unknown;
  else?: React.ReactNode;
  children: React.ReactNode;
};

const Index: React.FC<Props> = ({ children, if: condition, else: elseRenderContent = null }) => (!condition ? elseRenderContent : <>{children}</>);

export default Index;
