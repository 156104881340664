import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '@service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { CrmField } from '@utils/models/CrmField';
import { baseErrorHandler } from '@queries/baseErrorHandler';

export function useGetFlatFields() {
  return useQuery<CrmField[]>(
    [queryKeys.FLAT_FIELD],
    async () => {
      const res = await $api.get(endPoints.FLAT_FIELDS);
      return res?.data?.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: Infinity,
      onError: baseErrorHandler,
      retry: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );
}
