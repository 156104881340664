import React from 'react';

const SMSStarIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 11.5V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H12"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9L10.13 11.5C11.16 12.32 12.85 12.32 13.88 11.5"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4802 2.82043L19.7602 3.39042C19.9002 3.67042 20.2502 3.93043 20.5602 3.99043L20.9402 4.05043C22.0802 4.24043 22.3502 5.08043 21.5302 5.91043L21.1802 6.26042C20.9502 6.50042 20.8202 6.96042 20.8902 7.28042L20.9402 7.49043C21.2502 8.87043 20.5202 9.40042 19.3202 8.68042L19.0602 8.53042C18.7502 8.35042 18.2502 8.35042 17.9402 8.53042L17.6802 8.68042C16.4702 9.41042 15.7402 8.87043 16.0602 7.49043L16.1102 7.28042C16.1802 6.96042 16.0502 6.50042 15.8202 6.26042L15.4702 5.91043C14.6502 5.08043 14.9202 4.24043 16.0602 4.05043L16.4402 3.99043C16.7402 3.94043 17.1002 3.67042 17.2402 3.39042L17.5202 2.82043C18.0602 1.73043 18.9402 1.73043 19.4802 2.82043Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default SMSStarIcon;
