import React from 'react';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../../../service/datePicker';
import { DATE_FORMAT } from '../../../../../utils/constants/format';
import { useDownloadContractLog } from '../../../services/mutation';

import styles from '../../../../arrearage/arrearage.module.scss';

const { Option } = Select;

interface Props {
  visible: boolean;
  number: string;
  id: number;
  close: (id: number, number: string, visible: boolean) => void;
}

const DownloadLogModal: React.FC<Props> = ({ close, id, number, visible }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const download = useDownloadContractLog(id, number, handleClose);

  // handleClose
  function handleClose() {
    form.resetFields();
    close(0, '', false);
  }

  // handleFinish
  const handleFinish = (val: { date: Dayjs; lang: string }) => {
    download.mutate({
      date: dayjs(val.date).format(DATE_FORMAT),
      lang: val.lang
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Modal title={t('client_balance.Yuklab_olish')} className={styles.downloadModal} footer={false} visible={visible} onCancel={handleClose}>
      <div className={styles.body}>
        <Form
          form={form}
          name="naturalClient"
          initialValues={{ remember: true }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleFinish}
          autoComplete="off"
        >
          <Row style={{ display: 'flex', alignItems: 'flex-end' }} gutter={[8, 0]}>
            <Col span={8}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true
                  }
                ]}
                label={<span className={styles.formLabel}>{t(`changes.monthlyPayments.Sana`)}</span>}
              >
                <DatePicker placeholder={t('client_create_contract.kk_oo_yyyy')} format={DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="lang" label={<span className={styles.formLabel}>{t('home.Til')}</span>}>
                <Select>
                  <Option value="UZ">{t('integration.Ozbekcha')}</Option>
                  <Option value="RU">{t('integration.Ruscha')}</Option>
                  <Option value="EN">{t('integration.Inglizcha')}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ textAlign: 'end' }} span={8}>
              <Button loading={download.isLoading} style={{ borderRadius: '16px' }} htmlType="submit" type="primary">
                {t('client_balance.Yuklab_olish')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default DownloadLogModal;
