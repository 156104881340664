import React from 'react';

interface Props {
  className?: string;
}

const PlusIconAddCircle: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12H16" stroke="#87898E" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16V8" stroke="#87898E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusIconAddCircle;
