import { NavLink } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowRightMessage from '@icons/ArrowRightMessage';
import ElementplusIcon from '@icons/ElementplusIcon';
import Monitor from '@icons/Monitor';
import ReceiptDiscount from '@icons/ReceiptDiscount';
import { discountPaths } from '@routing/discount/discountPaths';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import { useTranslation } from 'react-i18next';

import styles from '../../interfaceConfig/interfaceConfig.module.scss';

const DiscountNavbar = () => {
  const param = useQueryParam();
  const { t } = useTranslation();

  const data = [
    {
      icon: <Monitor />,
      text: t('discount.chegirmalar'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.DISCOUNT + discountPaths.DISCOUNT

      // class: param.splitUrl?.length === 4 ? `${styles.navActive}` : `${styles.navLinkClass}`
    },
    {
      icon: <ElementplusIcon />,
      text: t('discount.chegirma_sozlamalari'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.DISCOUNT + discountPaths.DISCOUNT_SETTINGS

      // class: param.splitUrl?.length > 4 ? `${styles.navActive}` : `${styles.navLinkClass}`
    },
    {
      icon: <ReceiptDiscount className="h-[24px] w-[24px] text-[#00A389]" />,
      text: 'Muddatli chegirma',
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.DISCOUNT + discountPaths.TERM_DISCOUNT

      // class: param.splitUrl?.length > 4 ? `${styles.navActive}` : `${styles.navLinkClass}`
    }
  ];

  return (
    <div className={styles.navbar}>
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <NavLink key={index} to={item.path} className={styles.navLinkClass} end>
          <span className="d-flex align-center">
            {item?.icon} &nbsp; &nbsp; {item.text}
          </span>

          <span className={styles.arrowDiv}>
            <ArrowRightMessage />
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default DiscountNavbar;
