import { Col, Layout, Row } from 'antd';

import KpiInformation from '../../../components/profile/KpiInformation';
import UserInformation from '../../../components/profile/UserInformation';

const { Content } = Layout;

const ProfileClientInfo = () => (
  <Content
    style={{
      padding: '1rem 2.68rem',
      overflowY: 'auto',
      height: '50vh'
    }}
  >
    <Row gutter={[32, 0]}>
      <Col span={13}>
        <UserInformation />
      </Col>
      <Col span={11}>
        <KpiInformation />
      </Col>
    </Row>
  </Content>
);

export default ProfileClientInfo;
