import React from 'react';

const FilterMenuMineIcon = () => {
  return (
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8342 13.0003C18.8342 12.7278 18.7259 12.4664 18.5332 12.2737C18.3404 12.0809 18.079 11.9727 17.8065 11.9727H7.52964C7.25708 11.9727 6.99568 12.0809 6.80296 12.2737C6.61023 12.4664 6.50195 12.7278 6.50195 13.0003C6.50195 13.2729 6.61023 13.5343 6.80296 13.727C6.99568 13.9198 7.25708 14.028 7.52964 14.028H17.8065C18.079 14.028 18.3404 13.9198 18.5332 13.727C18.7259 13.5343 18.8342 13.2729 18.8342 13.0003Z"
        fill="#444444"
      />
      <path
        d="M21.9182 6.83432C21.9182 6.56177 21.81 6.30037 21.6172 6.10764C21.4245 5.91491 21.1631 5.80664 20.8906 5.80664H4.44761C4.17505 5.80664 3.91365 5.91491 3.72092 6.10764C3.5282 6.30037 3.41992 6.56177 3.41992 6.83432C3.41992 7.10688 3.5282 7.36828 3.72092 7.56101C3.91365 7.75373 4.17505 7.86201 4.44761 7.86201H20.8906C21.1631 7.86201 21.4245 7.75373 21.6172 7.56101C21.81 7.36828 21.9182 7.10688 21.9182 6.83432Z"
        fill="#444444"
      />
      <path
        d="M9.58432 18.1387C9.31177 18.1387 9.05037 18.2469 8.85764 18.4397C8.66491 18.6324 8.55664 18.8938 8.55664 19.1664C8.55664 19.4389 8.66491 19.7003 8.85764 19.893C9.05037 20.0858 9.31177 20.194 9.58432 20.194H15.7504C16.023 20.194 16.2844 20.0858 16.4771 19.893C16.6698 19.7003 16.7781 19.4389 16.7781 19.1664C16.7781 18.8938 16.6698 18.6324 16.4771 18.4397C16.2844 18.2469 16.023 18.1387 15.7504 18.1387H9.58432Z"
        fill="#444444"
      />
    </svg>
  );
};

export default FilterMenuMineIcon;
