import { FlatId } from '../../../utils/models/Flat';

export const getFlat = (data: FlatId[] | undefined) => {
  let maxPodez = 0;
  let maxFloor = 1;
  let minFloor = 1;
  let maxXaxis = 1;

  data?.forEach(item => {
    if (maxPodez < item.entrance) {
      maxPodez = item.entrance;
    }
    if (maxFloor < item.floor) {
      maxFloor = item.floor;
    }
    if (minFloor > item.floor) {
      minFloor = item.floor;
    }
    if (maxXaxis < item.xaxis[0]) {
      // eslint-disable-next-line prefer-destructuring
      maxXaxis = item.xaxis[0];
    }
  });

  const createEntrance = Array.from({ length: maxPodez });
  const createFloor: any[] = [];
  const createXaxis = Array.from({ length: maxXaxis });

  for (let i = minFloor; i <= maxFloor; i++) {
    createFloor.push(i);
  }

  const createFloorReverse = createFloor.reverse();

  function flatPosition(entrance: number, floor: number, xaxis: number) {
    return (
      data?.find(item => item.entrance === entrance && item.floor === floor && item.xaxis[0] === xaxis) || {
        new: true,
        isSelected: false
      }
    );
  }

  let res = createEntrance.map((entrance, entranceIndex) => {
    return createFloorReverse.map((floor, floorIndex) => {
      return createXaxis.map((flat, flatIndex) => {
        return {
          ...flatPosition(entranceIndex + 1, floor, flatIndex + 1),
          entrance: entranceIndex + 1,
          floor: floor,
          xaxis: [flatIndex + 1],
          yaxis: [floorIndex + 1],
          customId: `${entranceIndex + 1}-${floor}-${flatIndex + 1}`,
        }
      })
    })
  })

  return res;
};
