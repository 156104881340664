/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import MDatePicker from '@components/mDatePicker/MDatePicker';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowDownIcon from '@icons/ArrowDownIconCrm';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '@icons/crmIcons/crmIcons';
import TrushSquareIcon from '@icons/TrushSquareIcon';
import { useLeadTaskClosed, useLeadTaskDelete } from '@pages/oneLid/services/mutation';
import { useLeadTaskType } from '@queries/queries';
import { completeTaskVisible } from '@reducers/TaskCalendarViewSlice';
import { DATE_FORMAT } from '@utils/constants/format';
import { queryNames } from '@utils/constants/queryNames';
import { encodeString } from '@utils/helper';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import { Button, Checkbox, Dropdown, Input, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './completeTaskModal.module.scss';

const CompleteTaskModal = () => {
  const { params, searchParams } = useQueryParam<SearchParamsTask, { id: string }>();

  const qc = useQueryClient();

  const task = useAppSelector(state => state.taskCalendarViewSlice.task);
  const visible = useAppSelector(state => state.taskCalendarViewSlice.completeTaskVisible);
  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const type = useAppSelector(state => state.taskCalendarViewSlice.type);

  const dispatch = useAppDispatch();

  const [openExtraFooter, setOpenExtraFooter] = useState(false);
  const [text, setText] = useState('');
  const [checked, setChecked] = useState(false);
  const [meetingSelectVisible, setMeetingSelectVisible] = useState(false);
  const [activeMeetingSelectId, setActiveMeetingSelectId] = useState(1);
  const [mPickerVisible, setMPickerVisible] = useState(false);
  const [dateValue, setDateValue] = useState(`${task?.deadlineStart} ${dayjs(task?.deadlineFinish, 'DD.MM.YYYY HH:mm').format('HH:mm')}`);
  const [createDeadline, setCreateDeadline] = useState({
    start: task?.deadlineStart,
    end: task?.deadlineFinish
  });

  const inputRef = useRef<any>(null);

  const { t } = useTranslation();

  const leadTaskClose = useLeadTaskClosed(Number(params.id), handleSuccess);
  const deleteTask = useLeadTaskDelete(Number(params.id), handleSuccess);

  const { data: leadTaskTypeAll } = useLeadTaskType();

  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setActiveMeetingSelectId(task?.leadTaskType.id || 0);
    }
    // eslint-disable-next-line
  }, [leadTaskTypeAll]);

  useEffect(() => {
    if (task) {
      setCreateDeadline({
        start: task.deadlineStart,
        end: task.deadlineFinish
      });
      setDateValue(`${task?.deadlineStart} ${dayjs(task?.deadlineFinish, 'DD.MM.YYYY HH:mm').format('HH:mm')}`);
      setActiveMeetingSelectId(task.leadTaskType.id);
    }
  }, [task]);

  // handle success
  function handleSuccess() {
    let startDate = date;
    let endDate = date;

    if (type === TaskCalendarViewType.MONTH) {
      startDate = dayjs(date, DATE_FORMAT).startOf('month').format(DATE_FORMAT);
      endDate = dayjs(date, DATE_FORMAT).endOf('month').format(DATE_FORMAT);
    } else if (type === TaskCalendarViewType.WEEK) {
      startDate = dayjs(date, DATE_FORMAT).startOf('week').add(1, 'day').format(DATE_FORMAT);
      endDate = dayjs(date, DATE_FORMAT).endOf('week').add(1, 'day').format(DATE_FORMAT);
    }
    qc.invalidateQueries([queryNames.TASK_FILTER_CALENDAR, startDate, endDate, JSON.stringify(searchParams)]);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleCancel();
  }

  // handleCloseLeadTask
  const handleCloseLeadTask = () => {
    leadTaskClose.mutate({
      closedText: text,
      deadlineStart: createDeadline.start || '',
      deadlineFinish: createDeadline.end || '',
      reCreate: checked,
      id: task?.id || 0,
      leadTaskTypeId: activeMeetingSelectId
    });
  };

  // handleMeetingSelect
  const handleMeetingSelect = () => {
    setMeetingSelectVisible(true);
  };

  // handleMeetingSelectItem
  const handleMeetingSelectItem = (id: number) => {
    setMeetingSelectVisible(false);
    setActiveMeetingSelectId(id);
  };

  // handle open extra footer
  const handleOpenExtraFooter = (visible: boolean) => {
    inputRef.current?.focus();
    setOpenExtraFooter(visible);
  };

  // change text
  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  // change text
  const changeChecked = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  // onChangeMPicker
  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(`${date} ${start} ${end}`);
    setCreateDeadline({
      start: `${date} ${start}`,
      end: `${date} ${end}`
    });
  };

  // handle delete task
  const handleDeleteTask = () => {
    deleteTask.mutate(task?.id || 0);
  };

  // handlePicker
  const handlePicker = () => {
    setMPickerVisible(true);
  };

  // translateFor
  const translateFor = () => {
    switch (i18n.language) {
      case 'ru':
        return <>для {task?.assignedBy}</>;
      case 'en':
        return <>for {task?.assignedBy}</>;
      default:
        return <>{task?.assignedBy} uchun</>;
    }
  };

  // handle cancel modal
  const handleCancel = () => {
    dispatch(
      completeTaskVisible({
        visible: false,
        task: undefined
      })
    );
    setText('');
  };

  return (
    <Modal
      className={styles.container}
      width={676}
      footer={false}
      visible={visible}
      title={
        <NavLink to={`/main/crm/${task?.leadId}/${encodeString(task?.leadName)}?prev=true`}>{task?.leadName || task?.contractNumber}</NavLink>
        // <NavLink to={`/${rootPaths.MAIN}/${MainPaths.CRM}/${task?.leadId}/${task?.leadName}?prev=true`}>{task?.leadName || task?.contractNumber}</NavLink>
      }
      onCancel={handleCancel}
    >
      <div
        onClick={() => {
          handleOpenExtraFooter(true);
        }}
      >
        <div className={styles.container_main}>
          <p className={styles.container_main_date}>
            <span className={styles.container_main_date_employee}>{translateFor()}</span>
            <span className={styles.container_main_date_icon}>
              {crmIcons[task?.leadTaskType?.icon.split('#')[0]!]?.icon(`#${task?.leadTaskType?.icon.split('#')[1]}`)}{' '}
              <span style={{ color: `#${task?.leadTaskType?.icon.split('#')[1]}` }}>
                {task?.leadTaskType?.name} {task?.text && ': '}
              </span>
            </span>
            <span className={styles.container_main_date_note}>{task?.text}</span>
          </p>

          <div className={styles.container_main_text_input}>
            <Input.TextArea value={text} ref={inputRef} onChange={e => changeText(e)} autoSize={{ minRows: 1, maxRows: 4 }} />
            <Button loading={leadTaskClose.isLoading} onClick={handleCloseLeadTask} type="primary">
              {t('one_lid.Bajarildi')}
            </Button>
          </div>
          {openExtraFooter && (
            <div className={styles.container_main_editable_bottom}>
              <Checkbox onChange={changeChecked} checked={checked} />
              <Dropdown
                onVisibleChange={visible => setMeetingSelectVisible(visible)}
                visible={meetingSelectVisible}
                overlay={
                  <div className={styles.container_main_editable_bottom_meetingSelect}>
                    {leadTaskTypeAll?.map(item => (
                      <div key={item.id} onClick={() => handleMeetingSelectItem(item.id)}>
                        {crmIcons[item.icon.split('#')[0]]?.icon(`#${item.icon.split('#')[1]}`)} {item.name}
                        {activeMeetingSelectId === item.id && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                trigger={['click']}
                placement="topLeft"
              >
                <button onClick={handleMeetingSelect}>
                  {crmIcons[leadTaskTypeAll?.find(item => item.id === activeMeetingSelectId)?.icon?.split('#')[0]!]?.icon(
                    `#${leadTaskTypeAll?.find(item => item.id === activeMeetingSelectId)?.icon?.split('#')[1]}`
                  )}
                  {leadTaskTypeAll?.find(item => item.id === activeMeetingSelectId)?.name}
                  <ArrowDownIcon />
                </button>
              </Dropdown>
              <Dropdown
                visible={mPickerVisible}
                onVisibleChange={setMPickerVisible}
                overlay={
                  <div>
                    <MDatePicker visibleTime={mPickerVisible} onChangeMPicker={onChangeMPicker} />
                  </div>
                }
                trigger={['click']}
                placement="topCenter"
              >
                <Input
                  onClick={handlePicker}
                  value={dateValue}
                  style={{
                    width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                  }}
                />
              </Dropdown>
              <span className={styles.container_main_editable_bottom_delete} onClick={handleDeleteTask}>
                <TrushSquareIcon /> {t('one_lid.Ochirish')}
              </span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CompleteTaskModal;
