import React from 'react';

const CardSendIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.83398 5.66675H10.1673" stroke="#464255" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 11H5.83333" stroke="#464255" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11H10.1667" stroke="#464255" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.1673 9.35325V10.7399C15.1673 13.0799 14.574 13.6666 12.2073 13.6666H4.79398C2.42732 13.6666 1.83398 13.0799 1.83398 10.7399V5.25992C1.83398 2.91992 2.42732 2.33325 4.79398 2.33325H10.1673"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.834 6.33325V2.33325L15.1673 3.66659" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.8333 2.33325L12.5 3.66659" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CardSendIcon;
