import { DependencyList, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useInView } from 'react-intersection-observer';

interface IProps {
  handleScroll: () => void;
  deps?: DependencyList | undefined;
  isChange?: boolean;
}

export const useScrollView = ({ handleScroll, deps, isChange = true }: IProps) => {
  const { inView, ...inViewProps } = useInView();

  const debouncedChangeHandler = useMemo(() => debounce(handleScroll, 300), [handleScroll]);

  useEffect(() => {
    if (inView && isChange) {
      debouncedChangeHandler();
    }
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [inView, debouncedChangeHandler, isChange, ...(deps || [])]);

  return { inView, ...inViewProps };
};
