import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { FlatId } from '@utils/models/Flat';

interface IProps {
  values: {
    flatCount: string;
    floorCount: string;
    floorPosition: boolean;
  };
  shaxmatka: any;
  data: FlatId | any;
  dispatch: (e: any) => void;
  handleCancel: () => void;
  setSaveAll: (save: boolean) => void;
}

export const createFloorSave = ({ values, shaxmatka, data, dispatch, handleCancel, setSaveAll }: IProps) => {
  const arr: FlatId[] = [];
  let maxEntrance = 1;
  let maxFloor = 1;
  let maxXaxis = Number(values.flatCount);
  let minFloor = 1;

  shaxmatka.forEach((entrance: any) => {
    entrance.forEach((floor: any) => {
      floor.forEach((flat: any) => {
        if (!flat.new) {
          arr.push(flat);
        }
      });
    });
  });

  arr.forEach(({ entrance, xaxis, floor }) => {
    if (maxEntrance < entrance) {
      maxEntrance = entrance;
    }
    if (maxFloor < floor) {
      maxFloor = floor;
    }
    if (maxXaxis < xaxis[0]) {
      [maxXaxis] = xaxis;
    }
    if (minFloor > floor) {
      minFloor = floor;
    }
  });

  const floorArr: number[] = [];

  for (let i = minFloor; i <= maxFloor; i++) {
    floorArr.push(i);
  }

  const floorArrReverse = floorArr.reverse();

  function flatPosition(entrance: number, floor: number, xaxis: number) {
    return (
      arr?.find((item: any) => item.entrance === entrance && item.floor === floor && item.xaxis[0] === xaxis) || {
        floor,
        xaxis: [xaxis],
        new: true,
        isSelected: false,
        apartment: null
      }
    );
  }

  const convertShaxmatka = Array.from({ length: maxEntrance }).map((entrance: any, entranceIndex: number) =>
    floorArrReverse.map((floor: any, floorIndex: number) =>
      Array.from({ length: maxXaxis }).map((flat: any, flatIndex: number) => flatPosition(entranceIndex + 1, floor, flatIndex + 1))
    )
  );
  const floorAdd: any[] = Array.from({ length: Number(values.floorCount) }).map((flat: any, flatIndex: number) =>
    Array.from({ length: Number(values.flatCount) }).map((flat: any, flatIndex) => ({
      new: false,
      isSelected: false,
      apartment: true,
      number: 'new'
    }))
  );

  convertShaxmatka.forEach((entrance, entranceIndex) => {
    entrance.splice(data.yaxis[0] - (values.floorPosition ? 1 : 0), 0, ...floorAdd);
  });

  const floorArrAdd: number[] = [];

  for (let i = minFloor; i <= maxFloor + Number(values.floorCount); i++) {
    floorArrAdd.push(i);
  }

  const floorArrAddReverse = floorArrAdd.reverse();

  const res = convertShaxmatka.map((entrance, entranceIndex) =>
    entrance.map((floor, floorIndex) =>
      floor.map((flat, flatIndex) => ({
        ...flat,
        entrance: entranceIndex + 1,
        floor: floorArrAddReverse[floorIndex],
        xaxis: [flatIndex + 1],
        yaxis: [floorIndex + 1],
        customId: `${entranceIndex + 1}-${floorArrAddReverse[floorIndex]}-${flatIndex + 1}`
      }))
    )
  );

  //   console.log(floorArrAddReverse, floorArr)

  // @ts-expect-error
  dispatch(setShaxmatka(res));
  handleCancel();
  setSaveAll(false);
};
