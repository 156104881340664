import React, { FC, useEffect } from 'react';
import { Button, Modal } from 'antd';
import styles from './deleteColumn.module.scss';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import { PipeAll } from '../../../../../utils/models/PipeAll';
import { queryNames } from '../../../../../utils/constants/queryNames';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../service/I18nService';
import { usePipeStatusDelete } from '../../../services/mutation';

interface IProps {
  visible: boolean;
  setVisble: (e: boolean) => void;
  id: number;
  pipeId: number;
  index: number;
}

const DeleteColumn: FC<IProps> = ({ visible, id, pipeId, setVisble, index }) => {
  const qc = useQueryClient();
  const deleteColumn = usePipeStatusDelete();
  const handleCancel = () => {
    setVisble(false);
  };

  const { t } = useTranslation();

  const handleDelete = () => {
    deleteColumn.mutate({
      pipeId: pipeId,
      pipeStatusId: id
    });
  };

  useEffect(() => {
    handleCancel();
    //eslint-disable-next-line
  }, [deleteColumn.isSuccess]);

  const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);
  const statusData = sourceStatusData?.find(item => item.id === pipeId)?.pipeStatuses;

  // translateMessage
  const translateMessage = () => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            Вы соглашаетесь с тем, что при отключении «{statusData![index]?.name}», если в нем есть лиды, они будут переведены на «
            {statusData![index - 1]?.name}».
          </>
        );
      case 'en':
        return (
          <>
            «{statusData![index]?.name}» o'chirilganda agar ichida leadlar bo'lsa «{statusData![index - 1]?.name}» ga o'tkaziladi shunga rozimisiz.
          </>
        );
      default:
        return (
          <>
            «{statusData![index]?.name}» o'chirilganda agar ichida leadlar bo'lsa «{statusData![index - 1]?.name}» ga o'tkaziladi shunga rozimisiz.
          </>
        );
    }
  };

  return (
    <Modal title={false} visible={visible} footer={false} closable={false} onCancel={handleCancel} wrapClassName={styles.deleteColumn} width={543}>
      <span>
        <TrashIcon />
      </span>
      <p>{translateMessage()}</p>
      <div>
        <Button onClick={handleCancel}>{t('crm.Yopish')}</Button>
        <Button onClick={handleDelete} loading={deleteColumn.isLoading}>
          {t('crm.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteColumn;
