import React from 'react';
import { Empty as EmptyAnt } from 'antd';
import amoCrm from './../../../assets/img/amoCrm.png';
import styles from './amoCrm.module.scss';
import { useTranslation } from 'react-i18next';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <EmptyAnt
      className={styles.cont_empty}
      image={amoCrm}
      imageStyle={{
        height: 80,
        width: 80
      }}
      description={
        <>
          <h3 className={styles.cont_empty_title}>{t('integration.Tizim_ornatilmagan')}</h3>
          <p className={styles.cont_empty_text}>{t('integration.AmoCRM_tizimini_ornatishingiz_kerak')}</p>
        </>
      }
    />
  );
};

export default Empty;
