import { $api } from '@service/RequestService';
import { Pagination } from '@utils/models/Pagination';
import { useInfiniteQuery } from 'react-query';

import { clientConstants, clientModels } from '..';

export const useClientFilterInfinity = (props?: { search?: string; enabled?: boolean }) =>
  useInfiniteQuery<Pagination<clientModels.IClientFilter>>(
    [clientConstants.queryKeys.CLIENT_FILTER_INFINITY, props],
    async ({ pageParam = 1 }) => {
      const res = await $api.post('/v1/client/filter', { page: pageParam, size: 20, search: props?.search });

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: props?.enabled,
      staleTime: 3600
    }
  );
