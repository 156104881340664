import React from 'react';

const TickCircleIconCrm = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1123 22C17.6123 22 22.1123 17.5 22.1123 12C22.1123 6.5 17.6123 2 12.1123 2C6.6123 2 2.1123 6.5 2.1123 12C2.1123 17.5 6.6123 22 12.1123 22Z"
        stroke="#63CBA5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.8623 11.9999L10.6923 14.8299L16.3623 9.16992" stroke="#63CBA5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TickCircleIconCrm;
