import React from 'react';
import styles from './header.module.scss';
import { Segmented } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { LeadStatus } from '../../../../utils/models/LeadStatus';
import { setLeadStatus } from '../../../../store/reducers/CrmStatisticsSlice';
import { useTranslation } from 'react-i18next';

const Status = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(state => state.crmStatistic.leadStatus);

  const { t } = useTranslation();

  //handle change status
  const handleChangeStatus = (val: LeadStatus) => {
    dispatch(setLeadStatus(val));
  };

  return (
    <Segmented
      className={styles.container_segment}
      value={status}
      onChange={e => handleChangeStatus(e as LeadStatus)}
      options={[
        {
          value: LeadStatus.ALL,
          label: t('boss_crm.Barchasi')
        },
        {
          value: LeadStatus.ACTIVE,
          label: t('boss_crm.Aktiv')
        },
        {
          value: LeadStatus.CLOSED,
          label: t('boss_crm.Yopilganlar')
        }
      ]}
    />
  );
};

export default Status;
