import React from 'react';

const TabCaseIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66714 12.8333H9.3338C11.6788 12.8333 12.0988 11.8942 12.2213 10.7508L12.6588 6.08417C12.8163 4.66083 12.408 3.5 9.91714 3.5H4.0838C1.59297 3.5 1.18464 4.66083 1.34214 6.08417L1.77964 10.7508C1.90214 11.8942 2.32214 12.8333 4.66714 12.8333Z"
        stroke="#00A389"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 3.50008V3.03341C4.66699 2.00091 4.66699 1.16675 6.53366 1.16675H7.46699C9.33366 1.16675 9.33366 2.00091 9.33366 3.03341V3.50008"
        stroke="#00A389"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16634 7.58333V8.16667C8.16634 8.1725 8.16634 8.1725 8.16634 8.17833C8.16634 8.81417 8.16051 9.33333 6.99967 9.33333C5.84467 9.33333 5.83301 8.82 5.83301 8.18417V7.58333C5.83301 7 5.83301 7 6.41634 7H7.58301C8.16634 7 8.16634 7 8.16634 7.58333Z"
        stroke="#00A389"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6295 6.41675C11.282 7.39675 9.74199 7.98008 8.16699 8.17842"
        stroke="#00A389"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.52832 6.57422C2.84082 7.47255 4.32249 8.01505 5.83332 8.18422"
        stroke="#00A389"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TabCaseIcon;
