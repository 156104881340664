import React from 'react';
import { Segmented } from 'antd';

import styles from './buttons.module.scss';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setType } from '@reducers/TaskCalendarViewSlice';
import { useTranslation } from 'react-i18next';

const Buttons = () => {
  const type = useAppSelector(state => state.taskCalendarViewSlice.type);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // handle change value
  const handleChangeValue = (value: TaskCalendarViewType) => {
    dispatch(setType(value));
  };

  return (
    <div className={styles.container}>
      <Segmented
        onChange={e => handleChangeValue(e as TaskCalendarViewType)}
        value={type}
        className={styles.container_segment}
        options={[
          {
            label: <span className={styles.container_segment_item}>{t('calendar_view.Kun')}</span>,
            value: TaskCalendarViewType.DAY
          },
          {
            label: <span className={styles.container_segment_item}>{t('calendar_view.Hafta')}</span>,
            value: TaskCalendarViewType.WEEK
          },
          {
            label: <span className={styles.container_segment_item}>{t('calendar_view.Oy')}</span>,
            value: TaskCalendarViewType.MONTH
          }
        ]}
      />
      <div className={styles.container_line} />
    </div>
  );
};

export default Buttons;
