import React from 'react';

const PaymentInfoCard = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.74132 8.37036H5.29688" stroke="#335C67" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1.59277 8.3704V4.94818C1.59277 3.43707 2.815 2.21484 4.32611 2.21484H8.48907C10.0002 2.21484 11.2224 3.15558 11.2224 4.66669"
        stroke="#335C67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0594 9.1481C12.6891 9.50365 12.5113 10.0518 12.6594 10.6148C12.8446 11.3036 13.5261 11.7407 14.2372 11.7407H14.9261V12.8148C14.9261 14.4518 13.6002 15.7777 11.9631 15.7777H4.55574C2.9187 15.7777 1.59277 14.4518 1.59277 12.8148V7.62959C1.59277 5.99255 2.9187 4.66663 4.55574 4.66663H11.9631C13.5928 4.66663 14.9261 5.99996 14.9261 7.62959V8.70363H14.1261C13.7113 8.70363 13.3335 8.86662 13.0594 9.1481Z"
        stroke="#335C67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4077 9.45918V10.9851C16.4077 11.3999 16.067 11.7407 15.6448 11.7407H14.2151C13.4151 11.7407 12.6818 11.1555 12.6152 10.3555C12.5707 9.88883 12.7485 9.45179 13.0596 9.14808C13.3337 8.8666 13.7114 8.70361 14.1263 8.70361H15.6448C16.067 8.70361 16.4077 9.04437 16.4077 9.45918Z"
        stroke="#335C67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaymentInfoCard;
