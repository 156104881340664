import React from 'react';

const DropMenuIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="16" height="14" rx="2" fill="#F8F8F8" />
      <path d="M3 5.5H13" stroke="#464255" strokeLinecap="round" />
      <path d="M3 9.5H11" stroke="#464255" strokeLinecap="round" />
    </svg>
  );
};

export default DropMenuIcon;
