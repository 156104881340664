import React from 'react';

const ExcelIcon = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_153_623)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.723 4.50362L14.4464 0.703816L13.7984 -0.048584H6.05717C5.01797 -0.048584 4.17737 0.793216 4.17737 1.83302V4.22402H5.34257L5.34197 2.29082C5.34317 1.70582 5.81357 1.23242 6.39677 1.23242L13.0172 1.22642V4.36321C13.0184 5.53141 13.9622 6.47702 15.1316 6.47702H17.423L17.3102 15.5256C17.3084 16.1076 16.8362 16.5774 16.2548 16.5822L6.31277 16.5774C5.78057 16.5774 5.35217 16.0554 5.34977 15.4122V14.646H4.18277V15.7884C4.18277 16.9356 4.95197 17.8692 5.89637 17.8692L16.595 17.8668C17.6342 17.8668 18.4772 17.0208 18.4772 15.9834V5.37962L17.723 4.50362Z"
          fill="#434440"
        />
        <path d="M12.6338 15.2293H0.5V3.64087H12.6338V15.2293ZM1.6658 14.0629H11.4656V4.80727H1.6658" fill="#08743B" />
        <path
          d="M9.93789 12.4932H7.89489L6.50049 10.5228L5.03649 12.4932H2.98389L5.53029 9.29163L3.47409 6.49023H5.54709L6.50949 8.01303L7.53549 6.49023H9.64509L7.48929 9.29163L9.93789 12.4932Z"
          fill="#08743B"
        />
      </g>
      <defs>
        <clipPath id="clip0_153_623">
          <rect width="18" height="18" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExcelIcon;
