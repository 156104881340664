import React from 'react';
import { useAppDispatch } from '@hooks/reduxHooks';
import { changeCountryCode } from '@reducers/ClientSlice';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '../../../../hooks/useQueryParams';
import { ChangesPageSteps } from '../../../../utils/models/Changes';
import { SearchClientChanges } from '../../../../utils/models/Client';

import styles from './client.module.scss';

interface Props {
  client: SearchClientChanges;
}

const FindClientItem: React.FC<Props> = ({ client }) => {
  const dispatch = useAppDispatch();
  const { appendMultipleDifferent } = useQueryParam();
  const handleClick = () => {
    appendMultipleDifferent(['clientId', 'page'], [String(client.id), ChangesPageSteps.client]);
    dispatch(changeCountryCode(client?.code!));
  };
  const { t } = useTranslation();

  return (
    <div onClick={handleClick} className={styles.find_client_item}>
      <div>
        <label>{t(`changes.client.FindClientItem.Mijoz`)}:</label>
        <span>{client.name}</span>
      </div>
      <div>
        <label>{t(`changes.client.FindClientItem.Telefon`)}:</label>
        <span>{client.phone}</span>
      </div>
    </div>
  );
};

export default FindClientItem;
