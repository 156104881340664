import { ResponsivePie } from '@nivo/pie';
import { FirstPieChartData } from '@pages/bossCrm/utils/models/FirstPieChartData';
import { isNumber } from '@utils/helper/isNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './firstRowPieChart.module.scss';
type Props = {
  pieChartData: FirstPieChartData;
};
const FirstRowPieChart: React.FC<Props> = ({ pieChartData }) => {
  const { t } = useTranslation();
  const data = pieChartData
    ? [
        {
          id: t('boss_crm_conversion.Shartnoma_qilingan'),
          label: t('boss_crm_conversion.Shartnoma_qilingan'),
          value: pieChartData.countSuccess,
          color: 'rgba(255, 183, 98, 1)'
        },
        {
          id: t('boss_crm_conversion.Shartnoma_qilinmagan'),
          label: t('boss_crm_conversion.Shartnoma_qilinmagan'),
          value: pieChartData.countFailed,
          color: 'rgb(212,68,241)'
        },
        {
          id: t('import_export.Jarayonda'),
          label: t('import_export.Jarayonda'),
          value: pieChartData.overall! - (pieChartData.countFailed! + pieChartData.countSuccess!),
          color: 'blue'
        }
      ]
    : [
        {
          id: '',
          label: '',
          value: true,
          color: 'rgba(255, 183, 98, 1)'
        }
      ];

  return (
    <div className={styles.f_row_chart_container}>
      <ResponsivePie
        data={
          data as {
            id: string;
            label: string;
            value: number | undefined;
            color: string;
          }[]
        }
        margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
        innerRadius={0.66}
        padAngle={0.7}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]]
        }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={{ datum: 'data.color' }}
        tooltip={e => {
          let { datum: t } = e;
          return (
            <div className={styles.tooltip_container}>
              <span className={styles.tooltip_color_con} style={{ backgroundColor: t.color, border: `1px solid ${t.color}` }} />
              {t.label !== '' && <span>{t.label}</span>} : <span style={{ fontWeight: '700' }}>{!isNumber(`${t.value}`) ? 0 : t.value}</span>
            </div>
          );
        }}
      />
      <div className={styles.chart_total_amount}>
        <h1>{pieChartData?.overall ? pieChartData?.overall : 0}</h1>
        <span>{t('boss_crm_conversion.Jami_lidlar')}</span>
      </div>
    </div>
  );
};

export default FirstRowPieChart;
