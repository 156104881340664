import React from 'react';

const HammerAndPickIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11934_57807)">
        <path
          d="M3.02861 14.7906C2.51038 15.3089 1.68594 15.3333 1.19705 14.8444C0.708161 14.3555 0.732606 13.5311 1.25083 13.0129L11.5908 2.67286C12.1095 2.1542 12.9335 2.1302 13.4224 2.61909C13.9117 3.10797 13.8873 3.93242 13.3686 4.45064L3.02861 14.7906Z"
          fill="#D67909"
        />
        <path
          d="M14.4822 3.4031C14.5648 3.32056 14.6303 3.22255 14.675 3.11468C14.7197 3.00681 14.7427 2.8912 14.7427 2.77444C14.7427 2.65768 14.7197 2.54206 14.675 2.43419C14.6303 2.32632 14.5648 2.22832 14.4822 2.14577L13.8538 1.51733C13.687 1.35065 13.4609 1.25704 13.2252 1.25708C12.9894 1.25712 12.7633 1.35081 12.5967 1.51755C12.43 1.68428 12.3364 1.91039 12.3364 2.14615C12.3365 2.3819 12.4302 2.60798 12.5969 2.77466L13.2253 3.4031C13.392 3.56974 13.6181 3.66336 13.8538 3.66336C14.0895 3.66336 14.3155 3.56974 14.4822 3.4031Z"
          fill="#D67909"
        />
        <path
          d="M14.4822 3.4031C14.5648 3.32056 14.6303 3.22255 14.675 3.11468C14.7197 3.00681 14.7427 2.8912 14.7427 2.77444C14.7427 2.65768 14.7197 2.54206 14.675 2.43419C14.6303 2.32632 14.5648 2.22832 14.4822 2.14577L13.8538 1.51733C13.687 1.35065 13.4609 1.25704 13.2252 1.25708C12.9894 1.25712 12.7633 1.35081 12.5967 1.51755C12.43 1.68428 12.3364 1.91039 12.3364 2.14615C12.3365 2.3819 12.4302 2.60798 12.5969 2.77466L13.2253 3.4031C13.392 3.56974 13.6181 3.66336 13.8538 3.66336C14.0895 3.66336 14.3155 3.56974 14.4822 3.4031Z"
          fill="#D67909"
        />
        <path
          d="M15.9061 5.96619C15.7941 5.18174 15.7394 4.03108 14.4821 2.77419L14.1679 2.45952L11.9683 0.259522C11.8016 0.0928812 11.5756 -0.000732422 11.3399 -0.000732422C11.1042 -0.000732422 10.8781 0.0928812 10.7114 0.259522L9.13987 1.83108C9.05729 1.91362 8.99178 2.01163 8.94709 2.1195C8.9024 2.22737 8.87939 2.34298 8.87939 2.45974C8.87939 2.57651 8.9024 2.69212 8.94709 2.79999C8.99178 2.90786 9.05729 3.00587 9.13987 3.08841L11.9683 5.91685L12.911 6.85952L13.5394 7.48797C13.9959 7.94441 15.0123 8.9613 15.6363 8.3373C15.9799 7.99419 16.0634 7.06619 15.9061 5.96619Z"
          fill="#66757F"
        />
        <path
          d="M12.9711 14.7906C13.4893 15.3088 14.3142 15.3332 14.8026 14.8443C15.2915 14.3555 15.2671 13.531 14.7489 13.0128L4.40931 2.67279C3.89064 2.15413 3.0662 2.12968 2.57775 2.61901C2.08842 3.1079 2.11287 3.93235 2.63153 4.45057L12.9711 14.7906Z"
          fill="#F4900C"
        />
        <path
          d="M1.5178 3.4031C1.43522 3.32056 1.36971 3.22255 1.32502 3.11468C1.28033 3.00681 1.25732 2.8912 1.25732 2.77444C1.25732 2.65768 1.28033 2.54206 1.32502 2.43419C1.36971 2.32632 1.43522 2.22832 1.5178 2.14577L2.14625 1.51733C2.31298 1.35065 2.53909 1.25704 2.77485 1.25708C3.0106 1.25712 3.23668 1.35081 3.40336 1.51755C3.57003 1.68428 3.66364 1.91039 3.6636 2.14615C3.66356 2.3819 3.56987 2.60798 3.40313 2.77466L2.77469 3.4031C2.608 3.56974 2.38195 3.66336 2.14625 3.66336C1.91054 3.66336 1.68449 3.56974 1.5178 3.4031Z"
          fill="#F4900C"
        />
        <path
          d="M1.83181 6.86054C2.17848 7.20721 2.74159 7.20721 3.0887 6.86054L6.85981 3.08943C6.94239 3.00689 7.0079 2.90888 7.05259 2.80101C7.09728 2.69314 7.12029 2.57753 7.12029 2.46076C7.12029 2.344 7.09728 2.22839 7.05259 2.12052C7.0079 2.01265 6.94239 1.91464 6.85981 1.8321L5.28825 0.260987C5.12156 0.0943461 4.89551 0.000732422 4.65981 0.000732422C4.42411 0.000732422 4.19806 0.0943461 4.03137 0.260987L0.260255 4.03165C0.0936137 4.19835 0 4.4244 0 4.6601C0 4.8958 0.0936137 5.12185 0.260255 5.28854L1.83181 6.86054Z"
          fill="#66757F"
        />
      </g>
      <defs>
        <clipPath id="clip0_11934_57807">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HammerAndPickIcon;
