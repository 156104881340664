import React from 'react';

const BlueCall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.9984 5.33333V2M13.9984 2H10.6651M13.9984 2L9.99844 6M6.81646 9.24205C6.01541 8.44099 5.38288 7.53523 4.91888 6.56882C4.87897 6.48569 4.85902 6.44413 4.84368 6.39154C4.7892 6.20463 4.82834 5.97513 4.94168 5.81684C4.97357 5.7723 5.01168 5.7342 5.08788 5.65799C5.32095 5.42492 5.43749 5.30838 5.51368 5.1912C5.80101 4.74927 5.80101 4.17955 5.51368 3.73762C5.43749 3.62044 5.32095 3.5039 5.08788 3.27083L4.95797 3.14092C4.60367 2.78662 4.42653 2.60947 4.23627 2.51324C3.8579 2.32186 3.41106 2.32186 3.03268 2.51324C2.84243 2.60947 2.66528 2.78662 2.31099 3.14092L2.2059 3.24601C1.85281 3.59909 1.67627 3.77563 1.54144 4.01565C1.39183 4.28199 1.28425 4.69565 1.28516 5.00113C1.28598 5.27643 1.33938 5.46458 1.44619 5.84087C2.02017 7.86314 3.10315 9.77138 4.69514 11.3634C6.28712 12.9554 8.19537 14.0383 10.2176 14.6123C10.5939 14.7191 10.7821 14.7725 11.0574 14.7733C11.3629 14.7743 11.7765 14.6667 12.0429 14.5171C12.2829 14.3822 12.4594 14.2057 12.8125 13.8526L12.9176 13.7475C13.2719 13.3932 13.449 13.2161 13.5453 13.0258C13.7366 12.6474 13.7366 12.2006 13.5453 11.8222C13.449 11.632 13.2719 11.4548 12.9176 11.1005L12.7877 10.9706C12.5546 10.7376 12.4381 10.621 12.3209 10.5448C11.879 10.2575 11.3092 10.2575 10.8673 10.5448C10.7501 10.621 10.6336 10.7376 10.4005 10.9706C10.3243 11.0468 10.2862 11.0849 10.2417 11.1168C10.0834 11.2302 9.85387 11.2693 9.66697 11.2148C9.61438 11.1995 9.57281 11.1795 9.48968 11.1396C8.52327 10.6756 7.61751 10.0431 6.81646 9.24205Z"
        stroke="#0172CB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BlueCall;
