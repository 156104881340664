import React, { FC } from 'react';

interface IProps {
  size?: number;
  stroke?: string;
}

const DollarSquareBorderredIcon: FC<IProps> = ({ size = 16, stroke = '#464255' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.78125 9.55319C5.78125 10.4132 6.44125 11.1065 7.26125 11.1065H8.93458C9.64792 11.1065 10.2279 10.4999 10.2279 9.75319C10.2279 8.93986 9.87458 8.65319 9.34792 8.46652L6.66125 7.53319C6.13458 7.34652 5.78125 7.05986 5.78125 6.24652C5.78125 5.49985 6.36125 4.89319 7.07458 4.89319H8.74792C9.56792 4.89319 10.2279 5.58652 10.2279 6.44652"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 4V12" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.0007 14.6667H6.00065C2.66732 14.6667 1.33398 13.3334 1.33398 10V6.00004C1.33398 2.66671 2.66732 1.33337 6.00065 1.33337H10.0007C13.334 1.33337 14.6673 2.66671 14.6673 6.00004V10C14.6673 13.3334 13.334 14.6667 10.0007 14.6667Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DollarSquareBorderredIcon;
