import React from 'react';
import FirstRowBody from './body/FirstRowBody';
import styles from './firstRow.module.scss';
import FirstRowHeader from './header/FirstRowHeader';
const FirstRow: React.FC = () => {
  return (
    <div className={styles.f_row_container}>
      <FirstRowHeader />
      <FirstRowBody />
    </div>
  );
};

export default FirstRow;
