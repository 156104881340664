import React from 'react';

const ArrowTopInboundIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5459 9.79594L13.5459 18.7959C13.4414 18.9008 13.3172 18.984 13.1805 19.0408C13.0437 19.0976 12.8971 19.1268 12.7491 19.1268C12.601 19.1268 12.4544 19.0976 12.3176 19.0408C12.1809 18.984 12.0567 18.9008 11.9522 18.7959L3.375 10.2188V14.25C3.375 14.5484 3.25647 14.8345 3.0455 15.0455C2.83452 15.2565 2.54837 15.375 2.25 15.375C1.95163 15.375 1.66548 15.2565 1.4545 15.0455C1.24353 14.8345 1.125 14.5484 1.125 14.25V7.5C1.125 7.20163 1.24353 6.91548 1.4545 6.7045C1.66548 6.49353 1.95163 6.375 2.25 6.375H9C9.29837 6.375 9.58452 6.49353 9.7955 6.7045C10.0065 6.91548 10.125 7.20163 10.125 7.5C10.125 7.79837 10.0065 8.08452 9.7955 8.2955C9.58452 8.50647 9.29837 8.625 9 8.625H4.96875L12.75 16.4062L20.9541 8.20125C21.1654 7.98991 21.4521 7.87117 21.7509 7.87117C22.0498 7.87117 22.3365 7.98991 22.5478 8.20125C22.7592 8.41259 22.8779 8.69924 22.8779 8.99813C22.8779 9.29701 22.7592 9.58366 22.5478 9.795L22.5459 9.79594Z"
        fill="#E74141"
      />
    </svg>
  );
};

export default ArrowTopInboundIcon;
