import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { setHouseCurrency } from '@reducers/CurrencySlice';
import { setShowroomFlatCount } from '@reducers/ShowroomSlice';
import { $api, $apiVisual } from '@service/RequestService';
import { HouseForShowroom } from '@utils/models/HouseForShowroom';
import { Pagination } from '@utils/models/Pagination';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { getShowroomData } from '../utils/helper/getShowroomData';
import { IShowroomBuilding } from '../utils/models/IShowroomBuilding';
import { IShowroomFilter } from '../utils/models/IShowroomFilter';
import { IShowroomFlat } from '../utils/models/IShowroomFlat';
import { IWebsiteBlockStatistic } from '../utils/models/IWebsiteBlockStatistic';

export function useSmartCatalogShowroom() {
  const { filter } = useAppSelector(state => state.showroomSlice);
  const dispatch = useAppDispatch();

  return useQuery<ReturnType<typeof getShowroomData>>(
    [queryKeys.SMART_CATALOG_SHOWROOM, filter.houseId, filter.buildingIds],
    async () => {
      const res = await $api.post(endPoints.SMART_CATALOG_SHOWROOM, {
        houseId: filter.houseId,
        buildingIds: filter.buildingIds
        // fields:
        //   filter?.fields && filter?.fields?.length > 0
        //     ? filter?.fields
        //     : null,
      });

      const { data } = res.data;

      return getShowroomData(data);
    },
    {
      enabled: !!filter.houseId,
      onSuccess(data) {
        dispatch(setHouseCurrency(data?.currency));
      },
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSmartCatalogShowroomFilter() {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(state => state.showroomSlice);

  return useQuery<number[]>(
    [queryKeys.SMART_CATALOG_SHOWROOM_FILTER, { ...filter }],
    async () => {
      const res = await $api.post(endPoints.SMART_CATALOG_SHOWROOM, {
        ...filter,
        houseId: filter.houseId,
        buildingIds: filter.buildingIds
      });

      const { data } = res.data;

      return data?.map((item: { id: string | number }) => item?.id);
    },
    {
      onSuccess(data) {
        dispatch(setShowroomFlatCount(data?.length));
      },
      enabled: !!filter.houseId,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSmartCatalogHouse() {
  return useQuery<HouseForShowroom[]>(
    [queryKeys.SMART_CATALOG_HOUSE],
    async () => {
      const res = await $api.get(endPoints.SMART_CATALOG_HOUSE);

      return res?.data?.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSmartCatalogBuilding(houseId?: number) {
  return useQuery<IShowroomBuilding[]>(
    [queryKeys.SMART_CATALOG_BUILDING_ID, houseId],
    async () => {
      const res = await $api.get(endPoints.SMART_CATALOG_BUILDING_ID(houseId || 0));

      return res.data.data;
    },
    {
      enabled: !!houseId,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSmartCatalogFlat(id: number | null) {
  return useQuery<IShowroomFlat>(
    [queryKeys.SMART_CATALOG_FLAT_ID, id],
    async () => {
      const res = await $api.get(endPoints.SMART_CATALOG_FLAT_ID(id || 0));

      return res.data.data;
    },
    {
      enabled: !!id,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSmartCatalogTable() {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector(state => state.showroomSlice);

  return useQuery<Pagination<IShowroomFlat>>(
    [queryKeys.SMART_CATALOG_TABLE, filter],
    async () => {
      const res = await $api.post(endPoints.SMART_CATALOG_TABLE, filter);

      return res.data.data;
    },
    {
      enabled: !!filter?.houseId,
      onSuccess(data) {
        dispatch(setShowroomFlatCount(data?.totalElements));
      },
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useSmartCatalogFilterProperties() {
  const { houseId } = useAppSelector(state => state.showroomSlice.filter);

  return useQuery<IShowroomFilter>(
    [queryKeys.SMART_CATALOG_FILTER_PROPERTIES, houseId],
    async () => {
      const res = await $api.get(endPoints.SMART_CATALOG_FILTER_PROPERTIES(houseId![0] || 0));

      return res.data.data;
    },
    {
      enabled: !!houseId,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useWebsiteBlockStatistic() {
  const { filter, token } = useAppSelector(state => state.showroomSlice);

  return useQuery<IWebsiteBlockStatistic>(
    [queryKeys.WEBSITE_BLOCK_STATISTIC, filter?.houseId, token],
    async () => {
      const res = await $apiVisual.get(`${endPoints.WEBSITE_BLOCK_STATISTIC}/${filter?.houseId}`, {
        headers: {
          'X-Auth': token?.xAuthToken!
        }
      });

      return res.data.data;
    },
    {
      enabled: !!filter?.houseId && !!token?.xAuthToken,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}
