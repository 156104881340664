/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import useNetworkStatus from '@hooks/useNetworkStatus';
import CallCustomIcon from '@icons/CallCustomIcon';
import CloseAllIconNotifs from '@icons/CloseAllIconNotifs';
import InfoYellowIcon from '@icons/InfoYellowIcon';
import { queryKeys } from '@pages/crm/components/voronkaMessengerSetting/tg-premium/utils/helpers/queryKeys';
import { useGetPushNotificationConfigAll } from '@pages/task/components/taskHeader/service/queries';
import { setLoadingNoteIdFilter } from '@reducers/CrmOneLidSlice';
import { cx } from '@utils/cx';
import { encodeString } from '@utils/helper';
import { Avatar, notification } from 'antd';
import { fetchToken } from 'firebase';
import { getMessaging, onMessage } from 'firebase/messaging';
import { Tooltip } from 'global';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import SockJS from 'sockjs-client';
import Stomp, { Client } from 'stompjs';

import { ioServiceConstants } from 'modules/io-service';

import { crmIcons } from '../../assets/icons/crmIcons/crmIcons';
import SMSNotificationIcon from '../../assets/icons/SMSNotificationIcon';
import warning_icon from '../../assets/img/warning_icon.webp';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { MainPaths } from '../../routing/main/mainPaths';
import { profilePaths } from '../../routing/profile/profilePaths';
import { rootPaths } from '../../routing/rootPaths';
import { LocalStorage } from '../../service/LocalStorage';
import { BASE_URL } from '../../service/RequestService';
import { showNotificationNotTaskLead } from '../../store/reducers/NotTaskLeadNotificationSlice';
import { TOKEN_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { queryNames } from '../../utils/constants/queryNames';

import callIcon from '../../assets/img/callIcon.png';
import facebook from '../../assets/img/Facebook.png';
import instagram from '../../assets/img/instagram.png';
import robot from '../../assets/img/robot.png';
import telegram from '../../assets/img/telegram.png';
// eslint-disable-next-line import/order
import avatarDefaultClient from '@images/avatarDefaultClient.jpg';

import styles from './myNotification.module.scss';
import stylesNotifs from './push-notifications/tasksPushNotification.module.scss'

const conversationType: {
  [key: string]: string;
} = {
  INSTAGRAM: instagram,
  TELEGRAM: telegram,
  FACEBOOK: facebook
};

const MyNotification = () => {
  const networkStatus = useNetworkStatus();
  const [ring, setRing] = useState({
    clientPhone: '',
    ring: false,
    name: '',
    leadId: 0,
    leadName: ''
  });
  const [time, setTime] = useState(0);
  const { t, i18n } = useTranslation();
  const qc = useQueryClient();
  const navigate = useNavigate();
  const stompClient = useRef<Client>();
  const notificationAudio = useRef<any>(null);
  const permissions = useAppSelector(state =>
    state.userReducer?.user?.role ? state.userReducer?.user?.role.permissions : state.userReducer?.user?.roleTokens && state.userReducer?.user?.roleTokens[0]?.role?.permissions
  );
  const user = useAppSelector((state) => state.userReducer.user)

  const isAccessCrm = permissions?.includes(Permissions.PERMISSION_PAGE_CRM);

  const dispatch = useAppDispatch();

  useEffect(() => {
    function Notif() {
      const socket = new SockJS(`${BASE_URL}/ws`);

      stompClient.current = Stomp.over(socket);

      const connectWS = (client: Client) =>
        client?.connect(
          {
            Authorization: `Bearer ${LocalStorage.get(TOKEN_KEY)}`
          },
          () => {
            // @ts-ignore
            // eslint-disable-next-line no-underscore-dangle
            const { url } = client.ws._transport;

            client?.subscribe(`/lead-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);
              const leadId = Number(window.location.pathname.split('/').filter(item => Number(item))[0]);

              if (props.data === leadId) {
                qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, props.data]);
                qc.invalidateQueries([queryNames.LEAD_NOT_CLOSED, props.data]);
                qc.invalidateQueries([queryNames.CRM_ONE_LID, props.data]);
                qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
              }
            });

            client?.subscribe(`/lead-not-task-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);

              if (Number(props?.data)) dispatch(showNotificationNotTaskLead());
            });

            client?.subscribe(`/new-lead-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);

              props.data.forEach((id: number) => {
                qc.invalidateQueries([queryNames.CRM_LEAD_FILTER, id]);
                qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
              });
            });

            client?.subscribe(`/messaging-user${url.split('/')[5]}`, (ntf: any) => {
              const leadId = Number(window.location.pathname.split('/').filter(item => Number(item))[0]);
              const props: any = JSON.parse(ntf.body);

              qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, leadId]);
              qc.invalidateQueries([queryNames.LEAD_NOT_CLOSED]);
              qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
              if (isAccessCrm) {
                qc.invalidateQueries([queryNames.COUNT_NOT_READ]);
                qc.invalidateQueries([queryNames.LEAD_CHATBOX]);
              }
              if (!leadId || props.data[0].leadId !== leadId) {
                if (props.entry === 'LEAD_TASK') {
                  notification.open({
                    message: '',
                    className: 'headerNotification',
                    style: { width: 438, borderRadius: 16 },
                    placement: 'bottomLeft',
                    key: `${props.data?.id}`,
                    description: (
                      <>
                        <div
                          className={styles.notificationCard}
                          onClick={() => {
                            notification.close(`${props.data?.id}`);

                            const encodedName = encodeString(props?.data?.clientName);

                            if (props?.data?.contractId) {
                              navigate(`${rootPaths.MAIN + MainPaths.TASK}/${props.data.contractId}/${encodedName}`);
                            } else {
                              navigate(`${rootPaths.MAIN + MainPaths.CRM}/${props.data?.leadId}/${encodedName}`);
                            }
                          }}
                        >
                          <Avatar size={32} icon={<UserOutlined />} src={`${BASE_URL}/${props.from.avatar}`} />
                          <div className={styles.notificationCard_content}>
                            <div className={styles.notificationCard_content_left}>
                              <p>{props.from.name}</p>
                              <h1>{props.data?.text}</h1>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  });
                } else if (props.entry === 'MOI_ZVONKI_SMS') {
                  notification.open({
                    message: '',
                    className: 'headerNotification',
                    style: { width: 438, borderRadius: 16 },
                    placement: 'bottomLeft',
                    key: `${props.data?.leadId}`,
                    description: (
                      <>
                        <div
                          className={styles.notificationCard}
                          onClick={() => {
                            navigate(`${rootPaths.MAIN + MainPaths.CRM}/${props.data.leadId}/${encodeString(props.data.leadName)}`);
                            notification.close(`${props.data?.leadId}`);
                          }}
                        >
                          <Avatar
                            size={32}
                            icon={<UserOutlined />}
                            src={
                              <SMSNotificationIcon
                                style={{
                                  width: '100%',
                                  height: '100%'
                                }}
                              />
                            }
                          />
                          <div className={styles.notificationCard_content}>
                            <div className={styles.notificationCard_content_left}>
                              <p>
                                Kiruvchi xabar <b>{props.from?.name}</b> dan
                              </p>
                              <h1>{props.data.text}</h1>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  });
                } else {
                  notificationAudio.current?.play();
                  notification.open({
                    message: '',
                    className: 'headerNotification',
                    style: { width: 438, borderRadius: 16 },
                    placement: 'bottomLeft',
                    key: `${props.data[0].id}`,
                    description: (
                      <>
                        <div
                          className={styles.notificationCard}
                          onClick={() => {
                            notification.close(`${props.data[0].id}`);

                            navigate(`${rootPaths.MAIN + MainPaths.CRM}/${props.data[0].leadId}/${encodeString(props.data[0].from.name)}`);
                          }}
                        >
                          <Avatar size={32} icon={<UserOutlined />} src={`${BASE_URL}/${props.data[0].from.avatar}`} />
                          {props.data[0].conversationType && (
                            <img
                              src={conversationType[props.data[0].conversationType]}
                              alt=""
                              width={13}
                              height={13}
                              style={{
                                marginTop: 'auto',
                                marginLeft: '-13px',
                                zIndex: 1
                              }}
                            />
                          )}
                          <div className={styles.notificationCard_content}>
                            <div className={styles.notificationCard_content_left}>
                              <p>{props.data[0].from.name}</p>
                              <h1>{props.data[0].files ? props.data[0].files.originalName : props.data[0].text}</h1>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  });
                }
              }
            });

            client?.subscribe(`/call-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);

              qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, props.data?.leadId]);
              qc.invalidateQueries([queryNames.LEAD_NOT_CLOSED, props.data?.leadId]);
              qc.invalidateQueries([queryNames.CRM_ONE_LID, props.data?.leadId]);
              qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);

              document.querySelector('audio')?.play();

              notification.open({
                message: '',
                className: 'headerNotification',
                style: { width: 438, borderRadius: 16 },
                placement: 'bottomLeft',
                key: `${props.data?.leadId}`,
                description: (
                  <>
                    <div
                      className={styles.notificationCard}
                      onClick={() => {
                        navigate(`${rootPaths.MAIN + MainPaths.CRM}/${props.data.leadId}/${encodeString(props.data.leadName)}`);
                        notification.close(`${props.data?.leadId}`);
                      }}
                    >
                      <Avatar size={32} icon={<UserOutlined />} src={callIcon} />
                      <div className={styles.notificationCard_content}>
                        <div className={styles.notificationCard_content_left}>
                          <p>Kiruvchi qo’ng’iroq</p>
                          <h1>
                            {props.data.text} {props.from?.name}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </>
                )
              });
            });

            client?.subscribe(`/system-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);

              qc.invalidateQueries([queryNames.NOTIFICATION_ME_COUNT]);
              const deviceDescription = props?.data?.description ? props?.data?.description?.split('#') : '';

              if (props?.data?.type === 'ACTIVE_SESSION_SECURITY') {
                qc.invalidateQueries([queryNames.ACTIVE_SESSION]);
                notification.open({
                  message: '',
                  className: 'headerNotification',
                  style: { width: 438, borderRadius: 16 },
                  placement: 'topRight',
                  key: `${props.data?.date}`,
                  description: (
                    <>
                      <div
                        className={styles.notificationCard}
                        onClick={() => {
                          navigate(rootPaths.MAIN + MainPaths.PROFILE + profilePaths.SECYURITY);
                          notification.close(`${props.data?.date}`);
                        }}
                      >
                        <Avatar shape="square" size={32} icon={warning_icon} src={warning_icon} />
                        <div className={styles.notificationCard_content}>
                          <div className={styles.notificationCard_content_left}>
                            <p style={{ fontWeight: 600 }}>{t('notification.Akkauntingizga_kirildi')}</p>
                            <h1>
                              {`${t('notification.Sizning_Uysot_akkauntingizga')} ${deviceDescription[0]} ${t('notification.da')} ${deviceDescription[1]
                                } ${t('notification.qurilmasidan_kirildi')}`}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                });
              }
              document.querySelector('audio')?.play();
            });

            client?.subscribe(`/deadline-task-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);

              document.querySelector('audio')?.play();
              notification.open({
                message: '',
                className: 'headerNotification',
                style: { width: 438, borderRadius: 16 },
                placement: 'bottomLeft',
                key: `${props.data?.id}`,
                description: (
                  <>
                    <div
                      className={styles.notificationCard}
                      onClick={() => {
                        notification.close(`${props.data?.id}`);
                        if (props.data.contractId) {
                          navigate(`${rootPaths.MAIN + MainPaths.TASK}/${props.data.contractId}/${encodeString(props?.data?.clientName)}`);
                        } else {
                          navigate(`${rootPaths.MAIN + MainPaths.CRM}/${props.data?.leadId}/${encodeString(props?.data?.leadName)}`);
                        }
                      }}
                    >
                      <Avatar size={32} srcSet={robot} src={`${BASE_URL}/${props.from.avatar}`} />
                      <div className={styles.notificationCard_content}>
                        <div className={styles.notificationCard_content_left}>
                          <h1 style={{ marginBottom: 4 }}>Vazifani bajarish vaqti keldi!</h1>
                          <p className="d-flex align-center">
                            {crmIcons[props.data.leadTaskType.icon.split('#')[0]].icon(`#${props.data.leadTaskType.icon.split('#')[1]}`)} {props.data.leadTaskType.name}:{' '}
                            {props.data?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )
              });
            });

            client?.subscribe(`/reboot-session-user${url.split('/')[5]}`, () => {
              qc.invalidateQueries([queryNames.ACTIVE_SESSION]);
            });

            client?.subscribe(`/call-history-user${url.split('/')[5]}`, (ntf: any) => {
              qc.invalidateQueries([queryNames.ONLINE_PBX_HISTORY_CALL]);
            });

            client?.subscribe(`/call-sipuni-user${url.split('/')[5]}`, (ntf: any) => {
              const props: any = JSON.parse(ntf.body);
              let leadId = props?.data?.leads[0]?.leadId || 0;
              const lead = props?.data?.leads?.find((item: any) => {
                leadId = leadId < item?.leadId ? item?.leadId : leadId;
                return item?.leadId >= leadId;
              });

              if (props?.data?.callState !== 'HUNG_UP') {
                setTime(3);
              }
              setRing(prev => ({
                ...prev,
                ring: props?.data?.clientPhone === prev.clientPhone ? !(props?.data?.callState === 'HUNG_UP') : prev?.ring,
                clientPhone: props?.data?.clientPhone,
                name: props?.from?.name,
                ...lead
              }));
            });

            client?.subscribe(`/ai-message-user${url.split('/')[5]}`, (ntf: any) => {
              const leadId = Number(window.location.pathname.split('/').filter(item => Number(item))[0]);
              const props: any = JSON.parse(ntf.body);

              qc.invalidateQueries([queryNames.LEAD_COMPLETED_DATA, leadId]).then(() => {
                dispatch(setLoadingNoteIdFilter(Number(props?.data)));
              });
              qc.invalidateQueries([queryNames.LEAD_NOT_CLOSED, leadId]);
              qc.invalidateQueries([queryNames.CRM_ONE_LID, leadId]);
              qc.invalidateQueries([queryNames.LEAD_NOT_CLOSED]);
              qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
            });

            client?.subscribe(`/chat-bot-new-users${url.split('/')[5]}`, (ntf: any) => {
              // const props: any = JSON.parse(ntf.body);
              qc.invalidateQueries([queryKeys.TG_CHATBOT]);
            });
            client?.subscribe(`/io-service-user${url.split('/')[5]}`, (ntf: any) => {
              // const props: any = JSON.parse(ntf.body);

              qc.invalidateQueries([ioServiceConstants.queryKeys.JOB_PROGRESS_FILES]);
              qc.invalidateQueries([ioServiceConstants.queryKeys.JOB_ALL_FILES]);
            });
          }
        );

      connectWS(stompClient.current);

      socket.onclose = e => {
        stompClient.current = Stomp.over(socket);
        connectWS(stompClient.current);
      };

      socket.onerror = e => {
        stompClient.current = Stomp.over(socket);
        connectWS(stompClient.current);
      };

      // stompClient.current.ws.onerror = (e) => {
      //   console.log(e, 'onerror')
      //   connectWS()
      // }
    }

    // window.addEventListener('load', Notif)
    Notif();

    return () => {
      // window.removeEventListener('load', Notif)
    };
    // eslint-disable-next-line
  }, [networkStatus]);

  useEffect(() => {
    let interval: any;

    // eslint-disable-next-line prefer-const
    interval = setInterval(() => {
      setTime(prev => (prev <= 0 ? 0 : prev - 1));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  fetchToken();



  interface Notification {
    title?: string;
    body?: string;
    link?: string;
    assignedById?: number | null;
  }

  const [notificationQueue, setNotificationQueue] = useState<Notification[]>([]);
  const [showAllNotifications, setShowAllNotifications] = useState<boolean>(false);

  const { data: pushNotificationData } = useGetPushNotificationConfigAll();

  // console.log('pushNotificationData', pushNotificationData);



  const splitTitle = (title?: string) => {
    const parts = title?.split('\n') || '';
    const firstPart = parts[0]?.trim();
    const dateTime = parts[1] ? parts[1]?.trim() : '';

    return { firstPart, dateTime };
  };

  useEffect(() => {
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      console.log('payload', payload);

      const newNotification: Notification = {
        title: payload?.data?.title || '',
        body: payload?.data?.body || '',
        assignedById: Number(payload?.data?.assignedById),
        link: payload?.fcmOptions?.link,
      };

      setNotificationQueue(prevQueue => [...prevQueue, newNotification]);

      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        closeNotification(newNotification);
      }, 30000);
    });
  }, []);

  const notificationQueueFilter = notificationQueue?.filter((item) => item?.assignedById === user?.id)


  const toggleShowAllNotifications = () => {
    setShowAllNotifications(!showAllNotifications);
  };

  const closeNotification = (notification?: Notification) => {
    setNotificationQueue(prevQueue => prevQueue?.filter(notif => notif !== notification));
  };

  const closeAllNotifications = () => {
    setNotificationQueue([]);
    setShowAllNotifications(false);
  };

  const translatedNumberInfo = (phone?: string) => {
    switch (i18n.language) {
      case 'ru':
        return <>{t('showroom.uchun')} {phone}. {t('home.Tel_qilib')}...</>;
      case 'en':
        return <>{t('showroom.uchun')} {phone}. {t('home.Tel_qilib')}...</>;
      case 'tr':
        return <>{phone} {t('showroom.uchun')}. {t('home.Tel_qilib')}...</>;
      default:
        return <>{phone} {t('showroom.uchun')}. {t('home.Tel_qilib')}...</>;
    }
  };

  const translatedTaskTypeInfo = (type?: string) => {
    let translatedTitle = type;

    switch (i18n.language) {
      case 'ru':
        if (type === "Uchrashuv") {
          translatedTitle = t('integration.Uchrashuv');
        } else if (type === "To’lov") {
          translatedTitle = t('integration.Tolov');
        } else if (type === "Qayta aloqa") {
          translatedTitle = t('one_lid.Qayta aloqa');
        }
        break;
      case 'en':
        if (type === "Uchrashuv") {
          translatedTitle = t('integration.Uchrashuv');
        } else if (type === "To’lov") {
          translatedTitle = t('integration.Tolov');
        } else if (type === "Qayta aloqa") {
          translatedTitle = t('one_lid.Qayta aloqa');
        }
        break;
      case 'tr':
        if (type === "Uchrashuv") {
          translatedTitle = t('integration.Uchrashuv');
        } else if (type === "To’lov") {
          translatedTitle = t('integration.Tolov');
        } else if (type === "Qayta aloqa") {
          translatedTitle = t('one_lid.Qayta aloqa');
        }
        break;
      default:
        if (type === "Uchrashuv") {
          translatedTitle = t('integration.Uchrashuv');
        } else if (type === "To’lov") {
          translatedTitle = t('integration.Tolov');
        } else if (type === "Qayta aloqa") {
          translatedTitle = t('one_lid.Qayta aloqa');
        }
        break;
    }

    return <>{translatedTitle}</>;
  };

  const translatedTitleInfo = (title?: string) => {
    let translatedTitle = title;

    switch (i18n.language) {
      case 'ru':
        if (title === "Topshiriq muddati o'tib ketdi") {
          translatedTitle = t('home.Topshiriq_muddati_otib_ketdi');
        } else if (title === "Kun davomida topshiriqlar") {
          translatedTitle = t('home.Kun_davomida_topshiriqlar');
        } else if (title === "Topshiriq muddati yaqinlashmoqda") {
          translatedTitle = t('home.Topshiriq_muddati_yaqinlashmoqda');
        }
        break;
      case 'en':
        if (title === "Topshiriq muddati o'tib ketdi") {
          translatedTitle = t('home.Topshiriq_muddati_otib_ketdi');
        } else if (title === "Kun davomida topshiriqlar") {
          translatedTitle = t('home.Kun_davomida_topshiriqlar');
        } else if (title === "Topshiriq muddati yaqinlashmoqda") {
          translatedTitle = t('home.Topshiriq_muddati_yaqinlashmoqda');
        }
        break;
      case 'tr':
        if (title === "Topshiriq muddati o'tib ketdi") {
          translatedTitle = t('home.Topshiriq_muddati_otib_ketdi');
        } else if (title === "Kun davomida topshiriqlar") {
          translatedTitle = t('home.Kun_davomida_topshiriqlar');
        } else if (title === "Topshiriq muddati yaqinlashmoqda") {
          translatedTitle = t('home.Topshiriq_muddati_yaqinlashmoqda');
        }
        break;
      default:
        if (title === "Topshiriq muddati o'tib ketdi") {
          translatedTitle = t('home.Topshiriq_muddati_otib_ketdi');
        } else if (title === "Kun davomida topshiriqlar") {
          translatedTitle = t('home.Kun_davomida_topshiriqlar');
        } else if (title === "Topshiriq muddati yaqinlashmoqda") {
          translatedTitle = t('home.Topshiriq_muddati_yaqinlashmoqda');
        }
        break;
    }

    return <>{translatedTitle}</>;
  };


  return (
    <>
      {(pushNotificationData?.status && pushNotificationData?.inSiteStatus && (
        <div className={cx(stylesNotifs.notification_container)}>
          {notificationQueueFilter?.length > 0 && (
            <div className='flex items-center justify-center mb-4'>
              <div className='hover:rotate-45 transform transition-transform'>
                <span onClick={closeAllNotifications} className='cursor-pointer bg-transparent w-[30px] h-[30px] flex items-center justify-center border-solid rounded-full border-[1px] bg-white' >
                  <CloseAllIconNotifs />
                </span>
              </div>
            </div>
          )}

          <div className="max-h-[700px] overflow-y-auto overflow-x-hidden">
            {(showAllNotifications ? notificationQueueFilter : notificationQueueFilter?.slice(0, 2))?.map((notif, index) => {
              const title = splitTitle(notif?.title || '');
              const body = JSON.parse(notif?.body || '[]');

              return (
                <div key={index} className={cx(stylesNotifs.notification_card, 'cursor-pointer !rounded-[12px] !w-[400px]')} onClick={() => window.open(notif?.link, '_self')}>
                  <button
                    className={cx(stylesNotifs.close_button, '!mb-2  !text-[15px]')}
                    onClick={(e) => {
                      e.stopPropagation();
                      closeNotification(notif);
                    }}
                  >
                    ×
                  </button>

                  <div className='flex items-center gap-[12px]'>
                    <span className='bg-[#FEF0C7] w-[30px] h-[30px] flex items-center justify-center rounded-full mb-[-5px]'>
                      <InfoYellowIcon />
                    </span>
                    <div className='mt-[5px]'>
                      <h4 className='text-[15px] m-0 font-semibold'>
                        {translatedTitleInfo(title?.firstPart)}
                      </h4>
                      <p className='text-[#475467] font-normal'>{title?.dateTime}</p>
                    </div>
                  </div>

                  {body?.map((item: any, index: number) => (
                    <div className='mt-[5px] bg-[#F9FAFB] p-[8px] rounded-[12px] flex items-center gap-2' key={index}>
                      <span className='mb-[-3px]'>
                        {crmIcons[item?.icon?.split('#')[0]!]?.icon(`#${item?.icon?.split('#')[1]}`)}
                      </span>
                      {' '} {' '}
                      <p className='text-[14px]'>
                        <b>{translatedTaskTypeInfo(item?.taskName)}</b> {' '}
                        {translatedNumberInfo(item?.phone)}
                      </p>
                    </div>
                  ))}
                </div>
              )
            }
            )}

            {notificationQueueFilter?.length > 2 && !showAllNotifications && (
              <Tooltip
                title={t('home.Barchasini_korishlik_uchun_bosing')}
                overlayInnerStyle={{ borderRadius: 10, width: 280, display: 'flex', justifyContent: 'center' }}
              >
                <div className={cx(stylesNotifs.notification_card, 'cursor-pointer !bg-slate-50 !w-[400px] !rounded-[12px] !flex')} onClick={toggleShowAllNotifications}>
                  <h4 className='m-0 font-semibold text-[15px]'>{t('home.Boshqa_bildirishnomalar')}
                  </h4>
                  <span className='!text-[11px] ml-[5px] absolute top-[5px] right-2  font-thin m-0 bg-[#00a389] text-white flex items-center justify-center w-[30px] h-[22px] p-[4px] rounded-[11px]'>{notificationQueueFilter?.length - 2}</span>
                </div>
              </Tooltip>
            )}
          </div>

          {notificationQueueFilter?.length === 0 || showAllNotifications && (
            <button className={stylesNotifs.collapse_button} onClick={toggleShowAllNotifications}>
              {t('home.Hammasini_yigish')}
            </button>
          )}
        </div>
      )
      )}



      <div
        className={styles.ringCard}
        // eslint-disable-next-line no-nested-ternary
        style={{ right: ring.ring ? (time === 0 ? '' : -20) : '-100%' }}
        onClick={() => navigate(`${rootPaths.MAIN + MainPaths.CRM}/${ring?.leadId}/${encodeString(ring?.leadName)}`)}
      >
        <Avatar src={avatarDefaultClient} size={40} />
        <div className={styles.ringCard_content}>
          <h3>{ring?.name}</h3>
          <p>
            <CallCustomIcon className={styles.miniCall} /> {ring?.clientPhone}
          </p>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio controls style={{ position: 'absolute', pointerEvents: 'none', opacity: 0 }}>
        <source src="../../google_event.mp3" type="audio/mp3" />
      </audio>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio controls style={{ position: 'absolute', pointerEvents: 'none', opacity: 0 }} preload="auto" ref={notificationAudio}>
        <source src="../../notification.mp3" type="audio/mp3" />
      </audio>
    </>
  );
};

export default MyNotification;
