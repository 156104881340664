import { Button, Image, Modal } from 'antd';
import React, { FC } from 'react';
import styles from './voronkaMessengerInstallModal.module.scss';
import CRMImage from '../../../../../assets/img/CRM.png';
import FacebookIcon from '../../../../../assets/icons/FacebookIcon';
import GlphIcon from '../../../../../assets/icons/GlphIcon';
import VoronkaMessengerInstallModalSetting from './VoronkaMessengerInstallModalSetting';
import { InstagramAccount } from '../../../../../utils/models/InstagramAccount';
import { useTranslation } from 'react-i18next';

export const messengerText: {
  [key: string]: string;
} = {
  INSTAGRAM: 'Instagram',
  FACEBOOK: 'Facebook'
};

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      settingVisible: boolean;
      messenger: string;
    }>
  >;
  settingVisible: boolean;
  url: string;
  data: InstagramAccount | undefined;
  messenger: string;
}

const VoronkaMessengerInstallModal: FC<IProps> = ({ visible, setVisible, settingVisible, url, data, messenger }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setVisible({
      visible: false,
      settingVisible: false,
      messenger: ''
    });
  };

  const handleLogin = () => {
    window.location.replace(url);
  };

  return (
    <Modal
      title={messengerText[messenger] + ' ' + t('crm_ins.sozlamalari')}
      onCancel={handleClose}
      visible={visible}
      width={529}
      wrapClassName={styles.container}
      footer={false}
    >
      {settingVisible ? (
        <VoronkaMessengerInstallModalSetting data={data} setVisible={setVisible} messenger={messenger} />
      ) : (
        <>
          <h1 className={styles.container_title}>{t('crm_ins.Instagram_hisobingizni')}</h1>
          <div className={styles.container_images}>
            <Image.PreviewGroup>
              <Image src={CRMImage} preview={{ mask: t('crm_ins.Korish') }} />
              <Image src={CRMImage} preview={{ mask: t('crm_ins.Korish') }} />
              <Image src={CRMImage} preview={{ mask: t('crm_ins.Korish') }} />
              <Image src={CRMImage} preview={{ mask: t('crm_ins.Korish') }} />
              <Image src={CRMImage} preview={{ mask: t('crm_ins.Korish') }} />
              <Image src={CRMImage} preview={{ mask: t('crm_ins.Korish') }} />
            </Image.PreviewGroup>
          </div>
          <Button type="primary" className={styles.container_loginButton} onClick={handleLogin}>
            <FacebookIcon /> Login via Facebook
          </Button>
          <div className={styles.container_description}>
            <GlphIcon />
            {t('crm_ins.Barcha_mijozlar_bilan_CRM')}
          </div>
        </>
      )}
    </Modal>
  );
};

export default VoronkaMessengerInstallModal;
