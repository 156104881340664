import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HouseSale } from '../../utils/models/House';
import { Pagination } from '../../utils/models/Pagination';

interface HouseState {
  saleHouses: HouseSale[];
  page: number;
  totalPages: number;
  currentPage: number;
  homeCurrentPage: number;
}

const initialState: HouseState = {
  saleHouses: [],
  totalPages: 2,
  page: 1,
  currentPage: 1,
  homeCurrentPage: 1
};

export const houseSlice = createSlice({
  name: 'house',
  initialState,
  reducers: {
    setSaleHouses: (state, action: PayloadAction<Pagination<HouseSale>>) => {
      state.saleHouses = state.saleHouses.concat(action.payload.data);
      state.page = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    clearSaleHouses: state => {
      state.saleHouses = [];
      state.totalPages = 2;
      state.page = 1;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setHomeCurrentPage: (state, action: PayloadAction<number>) => {
      state.homeCurrentPage = action.payload;
    }
  }
});

export const { setSaleHouses, setPageNumber, clearSaleHouses, setCurrentPage, setHomeCurrentPage } = houseSlice.actions;
export default houseSlice.reducer;
