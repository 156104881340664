import React from 'react';
import { PipeStatistic } from '@utils/models/PipeStatistic';
import { Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { CLOSED_FAIL, CLOSED_SUCCESSFULLY } from '../../../utils/constants/PipeStatusOrder';

import ActivePipe from './activePipe/ActivePipe';
import ClosedPipes from './closedPipes/ClosedPipes';

const PipesBody: React.FC<{ data: PipeStatistic | undefined; isLoading: boolean; isFetching: boolean }> = ({ data, isFetching, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading || isFetching}>
      {data?.pipeStatusList?.length === 0 && <Empty description={t('boss_crm.empty')} />}
      {data?.pipeStatusList?.map((item, index) => {
        if (item.order === CLOSED_SUCCESSFULLY) {
          return (
            <ClosedPipes
              key={index}
              name={item.name}
              color={item.color}
              allDate={undefined}
              countLead={item.countLead}
              percent={item.countLeadPercent}
              percentLine={item.percentLine}
            />
          );
        }
        if (item.order === CLOSED_FAIL) {
          return (
            <ClosedPipes
              name={item.name}
              countLead={item.countLead}
              key={index}
              percent={item.countLeadPercent}
              color={item.color}
              allDate={data?.averageDay || 0}
              percentLine={item.percentLine}
            />
          );
        }
        return (
          <ActivePipe
            countLead={item.countLead}
            name={item.name}
            key={index}
            percentSpace={item.spacePercent}
            percentLine={item.percentLine}
            percentBorder={item.borderPercent}
            color={item.color}
            day={item.day}
            percent={item.countLeadPercent}
            percentDate={item.dayPercent}
          />
        );
      })}
    </Spin>
  );
};

export default PipesBody;
