import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import message from '@utils/helper/message';
import dayjs from 'dayjs';

import { ITermDiscount, IVariation } from 'modules/multi-payment';

import { LocalStorage } from '../../service/LocalStorage';
import { DATE_FORMAT } from '../../utils/constants/format';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { convertValue } from '../../utils/helper/convertValue';
import { calculateNewTablePayments } from '../../utils/helper/createMonthlyPayments';
import { helper } from '../../utils/helper/helper';
import { roundFloatNumber } from '../../utils/helper/roundFloatNumber';
import { MonthlyPayment } from '../../utils/models/CreateContract';
import { CurrencyItem } from '../../utils/models/Currency';
import { CustomFieldData } from '../../utils/models/CustomField';
import { FlatDiscount } from '../../utils/models/Discount';
import { Terrace } from '../../utils/models/Flat';
import { FlatMore } from '../../utils/models/FlatMore';

interface IVariationProps extends IVariation {
  mortgagePrice?: number;
}

interface CreateContract {
  totalAmount: number;
  constTotalAmount: number;
  prePayment: number;
  comment?: string | null;
  delay: number;
  discountSum: number;
  flats: FlatMore[];
  contractNumber: string;
  changeContractNumber: boolean;
  brokerId: number;
  delayInterval: number;
  fields: CustomFieldData[];
  discountText: string;
  prepaymentDate: string;
  firstPaymentDate: string;
  monthlyPaymentPartDtos: MonthlyPayment[];
  sumTablePayments: number;
  residueTablePayments: number;
  employeeId: number;
  currency: CurrencyItem | undefined;
  useDiscount: boolean;
  currencyCompany: CurrencyItem[] | undefined;
  dealPrice: number;
  discountId: number | null;
  variation?: IVariationProps;
  termDiscount?: ITermDiscount;
}

const initialState: CreateContract = {
  constTotalAmount: 0,
  delay: 0,
  prePayment: 0,
  totalAmount: 0,
  flats: [],
  discountSum: 0,
  brokerId: Number(LocalStorage.get(USER_KEY)?.id),
  employeeId: Number(LocalStorage.get(USER_KEY)?.id),
  changeContractNumber: false,
  contractNumber: '',
  delayInterval: 1,
  fields: [],
  discountText: '',
  prepaymentDate: dayjs(new Date()).format(DATE_FORMAT),
  firstPaymentDate: dayjs(new Date()).add(1, 'month').format(DATE_FORMAT),
  monthlyPaymentPartDtos: [],
  sumTablePayments: 0,
  residueTablePayments: 0,
  currency: undefined,
  useDiscount: false,
  currencyCompany: undefined,
  dealPrice: 0,
  discountId: 0,
  variation: undefined,
  termDiscount: undefined
};

export const createContractSlice = createSlice({
  name: 'createContract',
  initialState,
  reducers: {
    setCurrencyCompany: (state, action: PayloadAction<CurrencyItem[] | undefined>) => {
      state.currencyCompany = action.payload;
    },
    setFlats: (state, action: PayloadAction<FlatMore[]>) => {
      if (state.flats.length !== 0) return;
      let fullAmount: number = 0;
      let delay: number = 0;
      let prePayment: number = 0;
      const flats: FlatMore[] = [];

      action.payload.forEach(item => {
        const { repaired } = item.flat;
        const isCanChangeRepaired = !!(item.flat.priceRepaired && item.flat.priceNotRepaired);
        const pricePerAreaTerrace: number | null = repaired ? item.flat.terrace?.pricePerAreaRepaired || null : item.flat.terrace?.pricePerArea || null;
        const pricePerArea: number = repaired ? item.flat.pricePerAreaRepaired : item.flat.pricePerArea;

        prePayment = roundFloatNumber(prePayment + item.flat.prepayment);
        if (delay < item.flat.delay) {
          delay = item.flat.delay;
        }
        const totalAmount = repaired ? item.flat.priceRepaired : item.flat.priceNotRepaired;

        fullAmount = roundFloatNumber(fullAmount + totalAmount);
        let discountPercent = 0;

        if (repaired && item.discountRepaired) {
          discountPercent = item.discountRepaired.discount;
        } else if (item.discountNotRepaired) {
          discountPercent = item.discountNotRepaired.discount;
        }
        flats.push({
          ...item,
          firstPayment: item.flat.prepayment,
          repaired,
          totalAmount,
          isChangePricePerArea: false,
          isCanChangeRepaired,
          discountAmount: 0,
          discountPercent,
          pricePerAreaTerrace,
          pricePerArea
        });
      });
      state.flats = flats;
      state.currency = flats[0]?.flat?.currency;
      state.prePayment = prePayment;
      state.delay = delay;
      state.totalAmount = fullAmount;
      state.delayInterval = 1;
      state.discountText = '';
      state.useDiscount = false;
      state.dealPrice = fullAmount - state.discountSum;
    },
    setOptionsContract: (
      state,
      action: PayloadAction<{
        delay: number;
        prePayment: number;
        useDiscount: boolean;
        notDiscount?: boolean;
      }>
    ) => {
      let discount = 0;
      let discountAmount = 0;
      let oneDiscountPercent = 0;
      let discountDelay = action.payload.delay;

      if (!action?.payload?.notDiscount) {
        if (state.prePayment !== action.payload.prePayment || state.flats.length === 1) {
          const flats: FlatMore[] = [];

          state.flats.forEach(item => {
            const totalAmount = item.repaired ? item.flat.priceRepaired : item.flat.priceNotRepaired;
            const prePaymentPercent = roundFloatNumber(totalAmount / state.totalAmount);
            const prePayment = roundFloatNumber(prePaymentPercent * action.payload.prePayment);
            const list = item.repaired ? item.discountRepairedList : item.discountNotRepairedList;
            let discountPercent = 0;

            if (list.length > 0) {
              for (let i = 0; i < list.length; i++) {
                if (i + 1 === list.length && list[i].month === action.payload.delay && prePayment >= list[i].firstPayment) {
                  discountPercent = list[i].discount;
                  oneDiscountPercent = list[i].discount;
                  discountAmount = list[i].discountAmount;
                  discountDelay = list[i].month;
                } else if (
                  (list[i].firstPayment <= prePayment && list[i].month === action.payload.delay && list[i + 1].firstPayment > prePayment) ||
                  (list[i].firstPayment <= prePayment && list[i].month === action.payload.delay && list[i + 1].firstPayment === list[i].firstPayment)
                ) {
                  discountPercent = list[i].discount;
                  oneDiscountPercent = list[i].discount;
                  discountAmount = list[i].discountAmount;
                  discountDelay = list[i].month;
                }
              }
            }
            if ((item.discountAmount !== 0 && item.discountPercent === 0) || (item.discountAmount !== 0 && item.discountPercent === discountPercent)) {
              flats.push({ ...item, firstPayment: prePayment });
              discount = roundFloatNumber(discount + (item.discountAmount || 0));
            } else {
              flats.push({
                ...item,
                discountAmount: 0,
                discountPercent,
                totalAmount,
                pricePerArea: item.repaired ? item.flat.pricePerAreaRepaired : item.flat.pricePerArea,
                firstPayment: prePayment,
                isChangePricePerArea: false,
                pricePerAreaTerrace: item.repaired ? item.flat.terrace?.pricePerAreaRepaired || null : item.flat.terrace?.pricePerArea || null
              });
            }
          });
          if (action.payload.useDiscount) {
            state.flats = [
              {
                ...flats[0],
                discountPercent: oneDiscountPercent,
                discountAmount
              }
            ];
            state.discountSum = discountAmount;
          } else {
            state.flats = flats;
            state.discountSum = discount;
          }
        }
      }
      state.delay = action.payload.delay;
      state.prePayment = action.payload.prePayment;
      state.useDiscount = action.payload.useDiscount;
    },
    changeRepairedStatus: (state, action: PayloadAction<number>) => {
      const flats: FlatMore[] = [];
      let { prePayment } = state;
      let { totalAmount } = state;

      state.flats.forEach(item => {
        if (item.flat.id === action.payload) {
          prePayment = roundFloatNumber(prePayment - item.firstPayment);
          totalAmount = roundFloatNumber(totalAmount - item.totalAmount);

          if (item.repaired) {
            prePayment = roundFloatNumber(prePayment + (item.flat.priceNotRepaired * item.flat.prepaymentPercent) / 100);
            totalAmount = roundFloatNumber(totalAmount + item.flat.priceNotRepaired);
            flats.push({
              ...item,
              repaired: false,
              discountAmount: 0,
              discountPercent: item?.discountNotRepaired?.discount || 0,
              totalAmount: item.flat.priceNotRepaired,
              firstPayment: roundFloatNumber((item.flat.priceNotRepaired * item.flat.prepaymentPercent) / 100),
              pricePerArea: item.flat.pricePerArea,
              pricePerAreaTerrace: item.flat?.terrace?.pricePerArea || null
            });
          } else {
            prePayment = roundFloatNumber(prePayment + (item.flat.priceRepaired * item.flat.prepaymentPercent) / 100);
            totalAmount = roundFloatNumber(totalAmount + item.flat.priceRepaired);
            flats.push({
              ...item,
              repaired: true,
              discountAmount: 0,
              discountPercent: item?.discountRepaired?.discount || 0,
              totalAmount: item.flat.priceRepaired,
              firstPayment: roundFloatNumber((item.flat.priceRepaired * item.flat.prepaymentPercent) / 100),
              pricePerArea: item.flat.pricePerAreaRepaired,
              pricePerAreaTerrace: item.flat?.terrace?.pricePerAreaRepaired || null
            });
          }
        } else {
          flats.push(item);
        }
      });

      let discount = 0;
      const newFlats: FlatMore[] = [];

      flats.forEach(item => {
        const totalAmountOne = item.repaired ? item.flat.priceRepaired : item.flat.priceNotRepaired;
        const prePaymentPercent = roundFloatNumber(totalAmountOne / totalAmount);
        const insidePrePayment = roundFloatNumber(prePaymentPercent * prePayment);
        const list = item.repaired ? item.discountRepairedList : item.discountNotRepairedList;
        let discountPercent = 0;

        if (list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            if (i + 1 === list.length && insidePrePayment >= list[i].firstPayment) {
              discountPercent = list[i].discount;
            } else if (list[i].firstPayment <= insidePrePayment && list[i + 1].firstPayment > insidePrePayment) {
              discountPercent = list[i].discount;
              break;
            }
          }
        }
        if ((item.discountAmount !== 0 && item.discountPercent === 0) || (item.discountAmount !== 0 && item.discountPercent === discountPercent)) {
          newFlats.push({ ...item, firstPayment: insidePrePayment });
          discount = roundFloatNumber(discount + (item.discountAmount || 0));
        } else {
          newFlats.push({
            ...item,
            discountAmount: 0,
            discountPercent,
            firstPayment: insidePrePayment
          });
        }
      });

      state.discountSum = discount;
      state.flats = newFlats;
      state.prePayment = prePayment;
      state.totalAmount = totalAmount;
      state.dealPrice = totalAmount - discount;
    },
    changePricePerArea: (
      state,
      action: PayloadAction<{
        id: number;
        amount: number;
      }>
    ) => {
      const flats: FlatMore[] = [];
      let discountSum = 0;

      state.flats.forEach(item => {
        if (item.flat.id === action.payload.id) {
          let discountAmount = 0;

          if (item.discountAmount !== 0) {
            if (item.repaired) {
              if (item.pricePerArea !== item.flat.pricePerAreaRepaired) {
                const discount = (item.flat.pricePerAreaRepaired - item.pricePerArea) * item.flat.area;

                discountAmount = roundFloatNumber(discount);
              }
              if (item.flat.terrace) {
                const discount = ((item.flat.terrace.pricePerAreaRepaired || 0) - (item.pricePerAreaTerrace || 0)) * item.flat.terrace.area;

                discountAmount = roundFloatNumber(discount + discountAmount);
              }
            } else {
              if (item.pricePerArea !== item.flat.pricePerArea) {
                const discount = (item.flat.pricePerArea - item.pricePerArea) * item.flat.area;

                discountAmount = roundFloatNumber(discount);
              }
              if (item.flat.terrace) {
                const discount = ((item.flat.terrace.pricePerArea || 0) - (item.pricePerAreaTerrace || 0)) * item.flat.terrace.area;

                discountAmount = roundFloatNumber(discount + discountAmount);
              }
            }
          }
          if (item.repaired) {
            discountSum = roundFloatNumber(discountAmount + discountSum + (item.flat.pricePerAreaRepaired - action.payload.amount) * item.flat.area);
            flats.push({
              ...item,
              discountAmount: roundFloatNumber(discountAmount + (item.flat.pricePerAreaRepaired - action.payload.amount) * item.flat.area),
              discountPercent: 0,
              pricePerArea: action.payload.amount
            });
          } else {
            discountSum = roundFloatNumber(discountAmount + discountSum + (item.flat.pricePerArea - action.payload.amount) * item.flat.area);
            flats.push({
              ...item,
              discountAmount: roundFloatNumber(discountAmount + (item.flat.pricePerArea - action.payload.amount) * item.flat.area),
              discountPercent: 0,
              pricePerArea: action.payload.amount
            });
          }
        } else {
          discountSum = roundFloatNumber(discountSum + (item.discountAmount || 0));
          flats.push(item);
        }
      });
      if (discountSum > 0 && !state.discountText) {
        state.discountText = '';
      }
      state.useDiscount = false;
      state.flats = flats;
      state.discountSum = discountSum;
      state.dealPrice = state.totalAmount - discountSum;
    },
    changePricePerAreaTerrace: (
      state,
      action: PayloadAction<{
        id: number;
        amount: number;
      }>
    ) => {
      const flats: FlatMore[] = [];
      let discountSum = 0;

      state.flats.forEach(item => {
        if (item.flat.id === action.payload.id && item.flat.terrace) {
          let discountAmount = 0;

          if (item.discountAmount !== 0)
            if (item.repaired) {
              if (item.pricePerAreaTerrace !== item.flat?.terrace?.pricePerAreaRepaired) {
                const discount = ((item.flat.terrace?.pricePerAreaRepaired || 0) - (item?.pricePerAreaTerrace || 0)) * item.flat.terrace.area;

                discountAmount = roundFloatNumber(discount);
              }
              const discount = (item.flat.pricePerAreaRepaired - item.pricePerArea) * item.flat.area;

              discountAmount = roundFloatNumber(discount + discountAmount);
            } else {
              if (item.pricePerAreaTerrace !== item.flat.terrace.pricePerArea) {
                const discount = ((item.flat.terrace?.pricePerArea || 0) - (item?.pricePerAreaTerrace || 0)) * item.flat.terrace.area;

                discountAmount = roundFloatNumber(discount);
              }
              const discount = (item.flat.pricePerArea - item.pricePerArea) * item.flat.area;

              discountAmount = roundFloatNumber(discount + discountAmount);
            }
          if (item.repaired) {
            discountSum = roundFloatNumber(
              discountSum + discountAmount + ((item.flat.terrace?.pricePerAreaRepaired || 0) - action.payload.amount) * (item.flat.terrace?.area || 0)
            );
            flats.push({
              ...item,
              discountAmount: roundFloatNumber(discountAmount + ((item.flat.terrace?.pricePerAreaRepaired || 0) - action.payload.amount) * (item.flat.terrace?.area || 0)),
              discountPercent: 0,
              pricePerAreaTerrace: action.payload.amount
            });
          } else {
            discountSum = roundFloatNumber(discountSum + discountAmount + ((item.flat.terrace?.pricePerArea || 0) - action.payload.amount) * (item.flat.terrace?.area || 0));
            flats.push({
              ...item,
              discountAmount: roundFloatNumber(discountAmount + ((item.flat.terrace?.pricePerArea || 0) - action.payload.amount) * (item.flat.terrace?.area || 0)),
              discountPercent: 0,
              pricePerAreaTerrace: action.payload.amount
            });
          }
        } else {
          discountSum = roundFloatNumber(discountSum + (item.discountAmount || 0));
          flats.push(item);
        }
      });
      if (discountSum > 0 && !state.discountText) {
        state.discountText = '';
      }
      state.flats = flats;
      state.discountSum = discountSum;
      state.dealPrice = state.totalAmount - discountSum;
    },
    changePercentDiscount: (state, action: PayloadAction<number>) => {
      const flats: FlatMore[] = [];
      let { discountSum } = state;

      state.flats.forEach(item => {
        if (item.flat.id === action.payload) {
          if (item.discountAmount === 0) {
            let discount;

            if (item.repaired) {
              discount = item.discountRepairedList.find(i => i.discount === item.discountPercent)?.discountAmount || 0;
            } else {
              discount = item.discountNotRepairedList.find(i => i.discount === item.discountPercent)?.discountAmount || 0;
            }
            discountSum = roundFloatNumber(discountSum + discount);
            flats.push({
              ...item,
              discountAmount: discount
            });
          } else {
            discountSum = roundFloatNumber(discountSum - (item.discountAmount || 0));
            flats.push({
              ...item,
              discountAmount: 0
            });
          }
        } else {
          flats.push(item);
        }
      });
      if (state.flats.length === 1 && !state.useDiscount) {
        state.useDiscount = true;
      }
      if (discountSum > 0 && !state.discountText) {
        state.discountText = '';
      }
      state.flats = flats;
      state.discountSum = discountSum;
      state.dealPrice = state.totalAmount - discountSum;
    },
    setContractNumber: (state, action: PayloadAction<{ number: string; isChange: boolean }>) => {
      state.contractNumber = action.payload.number;
      state.changeContractNumber = action.payload.isChange;
    },
    setBroker: (state, action: PayloadAction<number>) => {
      state.brokerId = action.payload;
    },
    setEmployee: (state, action: PayloadAction<number>) => {
      state.employeeId = action.payload;
    },
    setDelay: (state, action: PayloadAction<number>) => {
      state.delayInterval = 1;
      state.delay = action.payload;
    },
    setDelayInterval: (state, action: PayloadAction<number>) => {
      if (state.delay % (action.payload || 1) === 0) {
        state.delayInterval = action.payload;
        return;
      }
      const interval = action.payload - (state.delay % action.payload);

      message.warning(`Interval kiritishda xatolikni oldini olish maqsadida vaqt oralig'i ${state.delay} dan ${state.delay + interval} ga o'zgartirildi!`);
      state.delay += interval;
      state.delayInterval = action.payload;
    },
    setCustomField: (state, action: PayloadAction<CustomFieldData>) => {
      const fields = [...state.fields];
      const index = fields.findIndex(item => item.fieldId === action.payload.fieldId);

      if (index !== -1) {
        fields[index] = action.payload;
      } else {
        fields.push(action.payload);
      }
      state.fields = fields;
    },
    setPrePayment: (state, action: PayloadAction<number>) => {
      const flats: FlatMore[] = [];
      let discount = 0;

      state.flats.forEach(item => {
        const totalAmount = item.repaired ? item.flat.priceRepaired : item.flat.priceNotRepaired;
        const prePaymentPercent = roundFloatNumber(totalAmount / state.totalAmount);
        const prePayment = roundFloatNumber(prePaymentPercent * action.payload);
        const list = item.repaired ? item.discountRepairedList : item.discountNotRepairedList;
        let discountPercent = 0;

        if (list.length > 0) {
          for (let i = 0; i < list.length; i++) {
            if (i + 1 === list.length && prePayment >= list[i].firstPayment) {
              discountPercent = list[i].discount;
            } else if (list[i].firstPayment <= prePayment && list[i + 1].firstPayment > prePayment) {
              discountPercent = list[i].discount;
              break;
            }
          }
        }
        if ((item.discountAmount !== 0 && item.discountPercent === 0) || (item.discountAmount !== 0 && item.discountPercent === discountPercent)) {
          flats.push({ ...item, firstPayment: prePayment });
          discount = roundFloatNumber(discount + (item.discountAmount || 0));
        } else {
          flats.push({
            ...item,
            discountAmount: 0,
            discountPercent,
            totalAmount,
            pricePerArea: item.repaired ? item.flat.pricePerAreaRepaired : item.flat.pricePerArea,
            firstPayment: prePayment,
            isChangePricePerArea: false,
            pricePerAreaTerrace: item.repaired ? item.flat.terrace?.pricePerAreaRepaired || null : item.flat.terrace?.pricePerArea || null
          });
        }
      });
      state.prePayment = action.payload;
      state.discountSum = discount;
      state.flats = flats;
      state.dealPrice = state.totalAmount - discount;
    },
    changeOneFlatAmount: (
      state,
      action: PayloadAction<{
        id: number;
        amount: number;
      }>
    ) => {
      const flats: FlatMore[] = [];
      let discount = state.discountSum;

      state.flats.forEach(item => {
        if (item.flat.id === action.payload.id) {
          const prePayment = item.firstPayment;
          const list = item.repaired ? item.discountRepairedList : item.discountNotRepairedList;

          const discountSum = roundFloatNumber(item.totalAmount - action.payload.amount);

          discount = item.discountAmount || 0;
          discount = roundFloatNumber(discount + discountSum - (item.discountAmount || 0));

          let discountPercent = 0;

          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              if (i + 1 === list.length && prePayment >= list[i].firstPayment) {
                discountPercent = list[i].discount;
              } else if (list[i].firstPayment <= prePayment && list[i + 1].firstPayment > prePayment) {
                discountPercent = list[i].discount;
                break;
              }
            }
          }

          flats.push({
            ...item,
            discountAmount: discountSum,
            discountPercent: discountSum === 0 ? discountPercent : 0,
            totalAmount: item.repaired ? item.flat.priceRepaired : item.flat.priceNotRepaired,
            pricePerArea: item.repaired ? item.flat.pricePerAreaRepaired : item.flat.pricePerArea,
            firstPayment: item.flat.prepayment,
            isChangePricePerArea: false,
            pricePerAreaTerrace: item.repaired ? item.flat.terrace?.pricePerAreaRepaired || null : item.flat.terrace?.pricePerArea || null
          });
        } else {
          flats.push(item);
        }
      });
      state.flats = flats;
      state.useDiscount = false;
      state.discountSum = discount;
      state.dealPrice = state.totalAmount - discount;
    },
    setClearOneFlatDiscount: (state, action: PayloadAction<number>) => {
      const flats: FlatMore[] = [];
      let discount = 0;

      state.flats.forEach(item => {
        if (item.flat.id === action.payload) {
          const prePayment = item.firstPayment;
          const list = item.repaired ? item.discountRepairedList : item.discountNotRepairedList;
          let discountPercent = 0;

          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              if (i + 1 === list.length && list[i]?.month === state.delay && prePayment >= list[i].firstPayment) {
                discountPercent = list[i].discount;
              } else if (
                (list[i].firstPayment <= prePayment && list[i]?.month === state.delay && list[i + 1].firstPayment > prePayment) ||
                (list[i].firstPayment <= prePayment && list[i]?.month === state.delay && list[i + 1].firstPayment === list[i]?.firstPayment)
              ) {
                discountPercent = list[i].discount;
              }
            }
          }
          discount = item.discountAmount || 0;
          flats.push({
            ...item,
            discountAmount: 0,
            discountPercent,
            totalAmount: item.repaired ? item.flat.priceRepaired : item.flat.priceNotRepaired,
            pricePerArea: item.repaired ? item.flat.pricePerAreaRepaired : item.flat.pricePerArea,
            firstPayment: item.flat.prepayment,
            isChangePricePerArea: false,
            pricePerAreaTerrace: item.repaired ? item.flat.terrace?.pricePerAreaRepaired || null : item.flat.terrace?.pricePerArea || null
          });
        } else {
          flats.push(item);
        }
      });
      state.flats = flats;
      state.discountSum = roundFloatNumber(state.discountSum - discount);
      state.dealPrice = state.totalAmount - roundFloatNumber(state.discountSum - discount);
    },
    changeDiscount: (state, action: PayloadAction<{ amount: number; changeRepairedStatus: boolean }>) => {
      const flats: FlatMore[] = [];

      state.flats.forEach(item => {
        if (item.repaired) {
          flats.push({
            ...item,
            repaired: !action.payload.changeRepairedStatus,
            discountAmount: 0,
            totalAmount: item.flat?.priceRepaired,
            pricePerArea: item.flat?.pricePerAreaRepaired,
            pricePerAreaTerrace: item.flat?.terrace?.pricePerAreaRepaired || null
          });
        } else {
          flats.push({
            ...item,
            repaired: action.payload.changeRepairedStatus,
            discountAmount: 0,
            totalAmount: item.flat?.priceNotRepaired,
            pricePerArea: item.flat?.pricePerArea,
            pricePerAreaTerrace: item.flat?.terrace?.pricePerArea || null
          });
        }
      });
      state.flats = flats;
      state.discountSum = action.payload.amount || 0;
      state.dealPrice = state.totalAmount - (action.payload.amount || 0);
    },
    setDiscountText: (state, action: PayloadAction<string>) => {
      state.discountText = action.payload;
    },
    setPrepaymentDate: (state, action: PayloadAction<string>) => {
      state.prepaymentDate = action.payload;
    },
    setFirstPaymentDate: (state, action: PayloadAction<string>) => {
      const table = state.monthlyPaymentPartDtos?.slice();
      const { delayInterval } = state;
      let delay = 0;

      if (Array.isArray(table)) {
        for (let i = 0; i < table.length; i++) {
          table[i].date = dayjs(action.payload, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT);
          delay += delayInterval || 0;
        }
      }
      state.monthlyPaymentPartDtos = table;
      state.firstPaymentDate = action.payload;
    },
    changeOnePaymentDate: (
      state,
      action: PayloadAction<{
        date: string;
        index: number;
      }>
    ) => {
      const table = state.monthlyPaymentPartDtos?.slice() || [];

      if (Array.isArray(table)) {
        table[action.payload.index].date = action.payload.date;
      }
      state.monthlyPaymentPartDtos = table;
      state.firstPaymentDate = table[0].date;
    },
    setMonthlyPaymentPartDtos: (state, action: PayloadAction<{ isMultiPayment?: boolean }>) => {
      const { isMultiPayment } = action.payload;

      state.monthlyPaymentPartDtos = calculateNewTablePayments(
        roundFloatNumber(state.totalAmount - (isMultiPayment ? 0 : state.discountSum) - state.prePayment - (state?.variation?.mortgagePrice || 0)),
        state.firstPaymentDate,
        state.delay || 0,
        state.delayInterval || 1
      );
      state.sumTablePayments = roundFloatNumber(state.totalAmount - (isMultiPayment ? 0 : state.discountSum) - state.prePayment - (state?.variation?.mortgagePrice || 0));
      state.residueTablePayments = 0;
    },
    changeOnePaymentAmount: (
      state,
      action: PayloadAction<{
        amount: string;
        index: number;
        isMultiPayment?: boolean;
      }>
    ) => {
      const { monthlyPaymentPartDtos, discountSum, prePayment, totalAmount, variation } = current(state);

      let sum = 0;
      let residue = roundFloatNumber(totalAmount - (action?.payload?.isMultiPayment ? 0 : discountSum) - prePayment - (variation?.mortgagePrice || 0));

      const newTable: MonthlyPayment[] = [];

      if (Array.isArray(monthlyPaymentPartDtos)) {
        for (let i = 0; i < monthlyPaymentPartDtos.length; i++) {
          const amount = helper.parseFloatStringToNumber(action.payload.amount);

          if (i === action.payload.index) {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              amount,
              amountString: action.payload.amount,
              sum: residue
            });
            residue = roundFloatNumber(residue - amount);
            sum = roundFloatNumber(sum + amount);
          } else {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              sum: residue
            });
            residue = roundFloatNumber(residue - newTable[i].amount);
            sum = roundFloatNumber(sum + newTable[i].amount);
          }
        }
      }

      state.monthlyPaymentPartDtos = newTable;
      state.sumTablePayments = sum;
      state.residueTablePayments = residue;
    },
    addItemsTable: (
      state,
      action: PayloadAction<{
        amount: string;
        number: number;
        date: string;
        isMultiPayment?: boolean;
      }>
    ) => {
      const { monthlyPaymentPartDtos, totalAmount, discountSum, prePayment, delayInterval, delay: fullDelay, variation } = current(state);
      const table = monthlyPaymentPartDtos ? monthlyPaymentPartDtos.slice() : [];
      let delay = 0;

      for (let i = 0; i < action.payload.number; i++) {
        // @ts-expect-error
        table.push({
          amountString: action.payload.amount,
          amount: helper.parseFloatStringToNumber(action.payload.amount),
          date: dayjs(action.payload.date, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT)
        });
        delay += 1;
      }
      let sum = 0;
      let residue = roundFloatNumber(totalAmount - (action?.payload?.isMultiPayment ? 0 : discountSum) - prePayment - (variation?.mortgagePrice || 0));

      for (let i = 0; i < table.length; i++) {
        table[i] = {
          ...table[i],
          sum: residue
        };
        residue = roundFloatNumber(residue - table[i].amount);
        sum = roundFloatNumber(sum + table[i].amount);
      }
      if (residue > 0 && (fullDelay || 1) / (delayInterval || 1) === table.length) {
        const amount = roundFloatNumber(table[table.length - 1].amount + residue);

        table[table.length - 1].amount = amount;
        table[table.length - 1].amountString = amount.toLocaleString('ru');
        sum = roundFloatNumber(sum + residue);
        residue = 0;
      }
      state.firstPaymentDate = table[0].date;
      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentPartDtos = table;
    },
    deleteItemTable: (state, action: PayloadAction<{ index: number; isMultiPayment?: boolean }>) => {
      const { monthlyPaymentPartDtos, totalAmount, discountSum, prePayment, variation } = current(state);

      const newTable: MonthlyPayment[] = [];
      let sum = 0;
      let residue = roundFloatNumber(totalAmount - (action?.payload?.isMultiPayment ? 0 : discountSum) - prePayment - (variation?.mortgagePrice || 0));

      if (Array.isArray(monthlyPaymentPartDtos)) {
        for (let i = 0; i < monthlyPaymentPartDtos.length; i++) {
          if (i !== action?.payload?.index) {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              sum: residue
            });
            residue = roundFloatNumber(residue - monthlyPaymentPartDtos[i].amount);
            sum = roundFloatNumber(sum + monthlyPaymentPartDtos[i].amount);
          }
        }
      }

      state.firstPaymentDate = newTable.length > 0 ? newTable[0].date : '';
      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentPartDtos = newTable;
    },
    clearMonthlyPaymentsTable: (state, action: PayloadAction<{ isMultiPayment?: boolean }>) => {
      const { totalAmount, discountSum, prePayment, variation } = current(state);

      state.monthlyPaymentPartDtos = [];
      state.residueTablePayments = roundFloatNumber(totalAmount - (action?.payload?.isMultiPayment ? 0 : discountSum) - prePayment - (variation?.mortgagePrice || 0));
      state.sumTablePayments = 0;
      state.firstPaymentDate = '';
    },
    setCurrency: (state, action: PayloadAction<CurrencyItem | undefined>) => {
      const { currency, totalAmount, discountSum, prePayment, flats } = current(state);

      const newFlats: FlatMore[] = [];

      flats.forEach(item => {
        const terrace = item.flat.terrace
          ? {
              ...item.flat.terrace,
              pricePerArea: convertValue(currency, action.payload, item.flat.terrace?.pricePerArea || 0),
              pricePerAreaRepaired: convertValue(currency, action.payload, item.flat.terrace?.pricePerAreaRepaired || 0)
            }
          : null;
        const discountNotRepairedList: FlatDiscount[] = [];
        const discountRepairedList: FlatDiscount[] = [];
        let discountNotRepaired: FlatDiscount | null = null;
        let discountRepaired: FlatDiscount | null = null;

        if (item.discountRepairedList.length > 0) {
          item.discountRepairedList.forEach(dis => {
            discountRepairedList.push({
              ...dis,
              discountAmount: convertValue(currency, action.payload, dis.discountAmount),
              firstPayment: convertValue(currency, action.payload, dis.firstPayment),
              monthlyPayment: convertValue(currency, action.payload, dis.monthlyPayment),
              total: convertValue(currency, action.payload, dis.total)
            });
          });
        }

        if (item.discountNotRepairedList.length > 0) {
          item.discountNotRepairedList.forEach(dis => {
            discountNotRepairedList.push({
              ...dis,
              discountAmount: convertValue(currency, action.payload, dis.discountAmount),
              firstPayment: convertValue(currency, action.payload, dis.firstPayment),
              monthlyPayment: convertValue(currency, action.payload, dis.monthlyPayment),
              total: convertValue(currency, action.payload, dis.total)
            });
          });
        }

        if (item.discountRepaired) {
          discountRepaired = {
            ...item.discountRepaired,
            discountAmount: convertValue(currency, action.payload, item.discountRepaired.discountAmount),
            firstPayment: convertValue(currency, action.payload, item.discountRepaired.firstPayment),
            monthlyPayment: convertValue(currency, action.payload, item.discountRepaired.monthlyPayment),
            total: convertValue(currency, action.payload, item.discountRepaired.total)
          };
        }

        if (item.discountNotRepaired) {
          discountNotRepaired = {
            ...item.discountNotRepaired,
            discountAmount: convertValue(currency, action.payload, item.discountNotRepaired.discountAmount),
            firstPayment: convertValue(currency, action.payload, item.discountNotRepaired.firstPayment),
            monthlyPayment: convertValue(currency, action.payload, item.discountNotRepaired.monthlyPayment),
            total: convertValue(currency, action.payload, item.discountNotRepaired.total)
          };
        }

        newFlats.push({
          ...item,
          discountNotRepaired,
          discountRepaired,
          discountRepairedList,
          discountNotRepairedList,
          discountAmount: convertValue(currency, action.payload, item.discountAmount || 0),
          totalAmount: convertValue(currency, action.payload, item.totalAmount),
          pricePerAreaTerrace: convertValue(currency, action.payload, item.pricePerAreaTerrace || 0) || null,
          pricePerArea: convertValue(currency, action.payload, item.pricePerArea),
          firstPayment: convertValue(currency, action.payload, item.firstPayment),
          flat: {
            ...item.flat,
            pricePerArea: convertValue(currency, action.payload, item.flat.pricePerArea),
            terrace: terrace as Terrace,
            prepayment: convertValue(currency, action.payload, item.flat.prepayment),
            price: convertValue(currency, action.payload, item.flat.price),
            priceRepaired: convertValue(currency, action.payload, item.flat.priceRepaired),
            pricePerAreaRepaired: convertValue(currency, action.payload, item.flat.pricePerAreaRepaired),
            priceNotRepaired: convertValue(currency, action.payload, item.flat.priceNotRepaired)
          }
        });
      });

      state.flats = newFlats;
      state.totalAmount = convertValue(currency, action.payload, totalAmount);
      state.discountSum = convertValue(currency, action.payload, discountSum);
      state.prePayment = convertValue(currency, action.payload, prePayment);
      state.currency = action.payload;
      state.dealPrice = convertValue(currency, action.payload, totalAmount) - convertValue(currency, action.payload, discountSum);
    },
    setDealPrice: (state, action: PayloadAction<number>) => {
      state.dealPrice = action.payload;
    },
    setChangePartId: (state, action: PayloadAction<number | null>) => {
      state.discountId = action.payload;
    },
    clear: state => {
      state.dealPrice = state.totalAmount - state.discountSum;
      state.delay = 0;
      state.prePayment = 0;
      state.totalAmount = 0;
      state.constTotalAmount = 0;
      state.variation = undefined;
      state.flats = [];
      state.discountSum = 0;
      state.brokerId = Number(LocalStorage.get(USER_KEY)?.id);
      state.employeeId = Number(LocalStorage.get(USER_KEY)?.id);
      state.changeContractNumber = false;
      state.contractNumber = '';
      state.delayInterval = 1;
      state.fields = [];
      state.discountText = '';
      state.prepaymentDate = dayjs(new Date()).format(DATE_FORMAT);
      state.firstPaymentDate = dayjs(new Date()).add(1, 'month').format(DATE_FORMAT);
      state.monthlyPaymentPartDtos = [];
      state.sumTablePayments = 0;
      state.residueTablePayments = 0;
      state.currency = undefined;
      state.useDiscount = false;
      state.termDiscount = undefined;
    },
    setVariation: (state, action: PayloadAction<IVariationProps | undefined>) => {
      state.variation = action.payload;
    },
    setTotalAmount: (state, action: PayloadAction<{ amount: number; constAmount: number }>) => {
      state.constTotalAmount = action.payload.constAmount;
      state.totalAmount = action.payload.amount;
      state.dealPrice = action.payload.amount - state.discountSum;
    },
    setTermDIscount: (state, action: PayloadAction<ITermDiscount | undefined>) => {
      state.termDiscount = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    }
  }
});

export const {
  setOptionsContract,
  setFlats,
  changeRepairedStatus,
  changePricePerArea,
  changePricePerAreaTerrace,
  changePercentDiscount,
  setBroker,
  setDelay,
  setDelayInterval,
  setContractNumber,
  setCustomField,
  setPrePayment,
  changeDiscount,
  setDiscountText,
  setPrepaymentDate,
  setFirstPaymentDate,
  changeOnePaymentDate,
  setMonthlyPaymentPartDtos,
  changeOnePaymentAmount,
  addItemsTable,
  deleteItemTable,
  clearMonthlyPaymentsTable,
  setEmployee,
  clear,
  setClearOneFlatDiscount,
  changeOneFlatAmount,
  setCurrency,
  setCurrencyCompany,
  setChangePartId,
  setVariation,
  setTotalAmount,
  setTermDIscount,
  setComment
} = createContractSlice.actions;
export default createContractSlice.reducer;
