import React from 'react';

const ArrowDownLinearIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.666" cy="10" r="10" transform="rotate(-90 10.666 10)" fill="#C2E6FE" />
      <g clip-path="url(#clip0_16260_111657)">
        <path
          d="M12.1855 6.2062L15.9793 9.99995L12.1855 13.7937"
          stroke="#3772FF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M5.35352 10L15.8723 10" stroke="#3772FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_16260_111657">
          <rect width="15" height="15" fill="white" transform="translate(3.16602 17.5) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowDownLinearIcon;
