import { Form, FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import DatePicker from '../../service/datePicker';
import { DATE_FORMAT } from '../../utils/constants/format';

interface IProps {
  form: FormInstance;
  formItemName: string;
  onChange?: (value: Dayjs) => void;
  formClassName?: string;
  initialValue?: any;
  rules?: Rule[] | undefined;
  label?: React.ReactNode;
  noStyle?: boolean | undefined;
}

const DatePickerGlobal: FC<IProps> = ({ form, formItemName, onChange, formClassName, initialValue, rules, label, noStyle = true }) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const keyUp = (value: string) => {
    setValue(value);
  };

  const openChange = (open: boolean) => {
    if (!open && value?.length === 10) {
      form?.setFieldsValue({
        [formItemName]: dayjs(value, DATE_FORMAT)
      });
      if (onChange) {
        onChange(dayjs(value, DATE_FORMAT));
      }
    }
  };

  const onSelect = (val: Dayjs) => {
    form?.setFieldsValue({
      [formItemName]: val
    });
    if (onChange) {
      onChange(val);
    }
    setValue(val.format(DATE_FORMAT));
  };

  return (
    <Form.Item noStyle={noStyle} name={formItemName} className={formClassName || ''} initialValue={initialValue} rules={rules} label={label}>
      <DatePicker
        className="customInput"
        placeholder={t('changes.contract.kk.oo.yyyy')}
        onOpenChange={openChange}
        onSelect={onSelect}
        inputRender={props => {
          return (
            <ReactInputMask
              mask="99.99.9999"
              // @ts-ignore*
              maskChar={''}
              onKeyUp={(e: any) => keyUp(e.target.value)}
              {...props}
            >
              {(inputProps: any) => <input {...inputProps} {...props} />}
            </ReactInputMask>
          );
        }}
        format={DATE_FORMAT}
      />
    </Form.Item>
  );
};

export default DatePickerGlobal;
