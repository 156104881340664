export const queryKeys = {
  CRM_ONE_LID: 'CRM_ONE_LID',
  BOOKING_LEAD_FLAT: 'BOOKING_LEAD_FLAT',
  LEAD_EMPLOYEE_DATA: 'LEAD_EMPLOYEE_DATA',
  LEAD_TASK_TYPE_ALL: 'LEAD_TASK_TYPE_ALL',
  LEAD_NOT_CLOSED: 'LEAD_NOT_CLOSED',
  LEAD_COMPLETED_DATA: 'LEAD_COMPLETED_DATA',
  COUNT_NOT_READ: 'COUNT_NOT_READ',
  LEAD_CHATBOX: 'LEAD_CHATBOX',
  LEAD_NOT_TASK: 'LEAD_NOT_TASK',
  CAN_MAKE_CALL_ONLINE_PBX: 'CAN_MAKE_CALL_ONLINE_PBX',
  CRM_TAGS: 'CRM_TAGS',
  ONE_COUNTRY: 'ONE_COUNTRY',
  LEAD_DUPLICATE: 'LEAD_DUPLICATE',
  INVITING_CLIENT: 'INVITING_CLIENT',
  ATTACH_CLIENT: 'ATTACH_CLIENT',
  CANCEL_CLIENT: 'CANCEL_CLIENT',
  SUGGESTION_LEAD: 'SUGGESTION_LEAD',
  CLIENT_VIEW: 'CLIENT_VIEW',
  CONTRACT_BOOKING: 'CONTRACT_BOOKING',
  CLIENT_EVENT: 'CLIENT_EVENT',
  ONE_CLIENT: 'ONE_CLIENT',
  EMBED_WIDGET: 'EMBED_WIDGET'
};
