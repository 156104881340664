import { LocalStorage } from '../../service/LocalStorage';
import { REDIRECT_URL } from '../constants/localStorageKeys';
import { Permissions } from '../constants/permissions';
import { permissionURL } from '../constants/permissionUrl';
import { User } from '../models/User';

const urlConst = ['', '/', '/main', '/main/home'];

export const setUrl = (el: Permissions[]) => {
  let url = '';

  permissionURL.forEach(item => {
    if (!url && el?.includes(item.permission as any)) {
      url = item?.url;
    }
  });

  return url;
};

export const signInSuccess: (user: User) => void = user => {
  const permissions = user?.role ? user?.role?.permissions : user?.roleTokens && user?.roleTokens[0]?.role?.permissions;

  window.location.replace(
    // eslint-disable-next-line no-nested-ternary
    !urlConst.includes(String(LocalStorage.get(REDIRECT_URL)))
      ? LocalStorage.get(REDIRECT_URL)
        ? String(LocalStorage.get(REDIRECT_URL))
        : setUrl(permissions!)
      : setUrl(permissions!)
  );
};
