import React from 'react';
import { PaymentField } from '../utils/models/PaymentField';

export interface ContextDataState {
  visible: boolean;
  item?: PaymentField;
}

export interface ContextData extends ContextDataState {
  setContext: (value: ContextDataState) => void;
}

export const PaymentChangesContext = React.createContext<ContextData>({
  visible: false,
  setContext: () => {}
});
