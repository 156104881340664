/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import { useCrmEmployee, usePipeAll } from '@queries/queries';
import { Checkbox, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SpecialTimeModal from '../../../../components/bossComplex/SpecialTimeModal';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { setChangeDefaultValue } from '../../../../store/reducers/CrmStatisticsSlice';
import { BoosComplexSelectType } from '../../../../utils/models/BoosComplexSelectType';

import styles from './header.module.scss';

const { Option } = Select;

interface IProps {
  employeeVisible?: boolean;
}

export type CallStatisticsParam = {
  date: string;
  startDate: string;
  finishDate: string;
  employee: number;
  pipeStatus: number;
  callType: string;
  page: number;
  unique: string;
  statuses: string[];
  durationStart?: number;
  durationFinish?: number;
  talkTimeStart?: number;
  talkTimeFinish?: number;
};

export const checkboxValueObj: {
  [key: string]: boolean;
} = {
  true: true
};

const Header = ({ employeeVisible = true }: IProps) => {
  const { data: pipeAll } = usePipeAll();
  const { data } = useCrmEmployee();
  const location = useLocation();
  const [specialModal, setSpecialModal] = useState(false);
  const { t } = useTranslation();
  const { appendSingle, searchParams, remove } = useQueryParam<CallStatisticsParam, any>();
  const dispatch = useAppDispatch();

  const [active, setActive] = useState(Boolean(searchParams.unique));
  const [selectedKeys, setSelectedKeys] = useState<any>();

  const [duration, setDuration] = useState({
    start: '',
    end: ''
  });

  const [talkTime, setTalkTime] = useState({
    start: '',
    end: ''
  });

  const selectOptions = {
    NO_ANSWER: t('online_pbx.NO_ANSWER'),
    CONVERSATION: t('crm.Gaplashilgan_vaqt'),
    USER_BUSY: t('online_pbx.USER_BUSY'),
    UNALLOCATED_NUMBER: t('online_pbx.UNALLOCATED_NUMBER')
  };

  useEffect(() => {}, [active]);

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;

    setActive(isChecked);

    if (!isChecked) {
      remove('unique', '');
    } else {
      appendSingle('unique', String(isChecked));
    }

    appendSingle('statuses', undefined);
  };

  const handleChange = (keys: any) => {
    setSelectedKeys(keys);
    if (keys.length > 0) {
      appendSingle('statuses', keys);
    } else {
      remove('statuses', selectedKeys);
    }
  };

  const changeDate = (value: string) => {
    switch (value) {
      case BoosComplexSelectType.TODAY:
        appendSingle('date', BoosComplexSelectType.TODAY);
        remove('startDate', undefined);
        remove('finishDate', undefined);
        dispatch(setChangeDefaultValue(value));
        break;
      case BoosComplexSelectType.YESTERDAY:
        appendSingle('date', BoosComplexSelectType.YESTERDAY);
        remove('startDate', undefined);
        remove('finishDate', undefined);
        dispatch(setChangeDefaultValue(value));
        break;
      case BoosComplexSelectType.THIS_WEEK:
        appendSingle('date', BoosComplexSelectType.THIS_WEEK);
        remove('startDate', undefined);
        remove('finishDate', undefined);
        dispatch(setChangeDefaultValue(value));
        break;
      case BoosComplexSelectType.THIS_MONTH:
        remove('startDate', undefined);
        remove('finishDate', undefined);
        appendSingle('date', BoosComplexSelectType.THIS_MONTH);
        dispatch(setChangeDefaultValue(value));
        break;
      case BoosComplexSelectType.NOW:
        appendSingle('date', BoosComplexSelectType.NOW);
        remove('startDate', undefined);
        remove('finishDate', undefined);
        dispatch(setChangeDefaultValue(value));
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        break;
      default:
        break;
    }
  };

  const handleChangeEmployee = (val: number | undefined) => {
    if (val !== null) {
      appendSingle('employee', val);
    } else {
      remove('employee', undefined);
    }
  };

  // set special
  const setSpecial = () => {
    setSpecialModal(true);
  };
  // change date

  // handle special modal
  const handleSpecialModal = (visible: boolean) => {
    setSpecialModal(visible);
  };

  // handle select date
  const handleSelectDate = (date: string[]) => {
    dispatch(setChangeDefaultValue(`${date[0]}-${date[1]}`));
  };

  if (location.pathname.split('/').includes('leads')) {
    useEffect(() => {
      if (!searchParams?.date) {
        appendSingle('date', BoosComplexSelectType.THIS_MONTH);
        appendSingle('callType', 'ALL');
      }
    }, [searchParams]);
  }

  const handleInputChange = (field: string, value: string) => {
    if (value) {
      appendSingle(field, value);
    } else {
      remove(field, undefined);
    }
  };

  useEffect(() => {
    setDuration({
      start: String(searchParams.durationStart),
      end: String(searchParams.durationFinish)
    });
  }, [searchParams.durationStart, searchParams.durationFinish]);

  useEffect(() => {
    setTalkTime({
      start: String(searchParams.talkTimeStart),
      end: String(searchParams.talkTimeFinish)
    });
  }, [searchParams.talkTimeStart, searchParams.talkTimeFinish]);

  return (
    <div className={styles.container}>
      <div
        className={`d-flex align-center ${location.pathname.split('/').includes('calls') && 'w-[100%]'} ${location.pathname.split('/').includes('calls') && 'flex-col'} gap-[10px] ${!location.pathname.split('/').includes('calls') && 'justify-end'}`}
      >
        <Select
          className="customSelect w-[100%]"
          onChange={changeDate}
          value={searchParams?.date === BoosComplexSelectType?.SPECIAL1 ? `${searchParams?.startDate}-${searchParams?.finishDate}` : searchParams?.date}
        >
          <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
          <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
          <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
          <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
          <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
          <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option>
        </Select>

        {employeeVisible && (
          <Select
            optionFilterProp="children"
            showSearch
            placeholder={t('boss_crm.Xodim_barchasi')}
            value={searchParams?.employee ? Number(searchParams?.employee) : null}
            onChange={handleChangeEmployee}
            className={`customSelect ${styles.container_select_employee} w-[100%]`}
          >
            <Option value={undefined}>{t('boss_crm.Xodim_barchasi')}</Option>
            {data?.map(emp => (
              <Option key={emp.id} value={emp.id}>
                {emp.fullName}
              </Option>
            ))}
          </Select>
        )}

        <Select
          className="customSelect w-[100%]"
          value={searchParams?.pipeStatus ? Number(searchParams?.pipeStatus) : null}
          placeholder={t('crm_filter.Barcha_bosqichlar')}
          onChange={value => {
            if (value !== null) {
              appendSingle('pipeStatus', value);
            } else {
              remove('pipeStatus', undefined);
            }
          }}
          allowClear
        >
          {pipeAll?.map(item => (
            <Select.Option value={item?.id} key={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>

        {location.pathname?.split('/').includes('calls') && (
          <label
            className="flex w-[100%] cursor-pointer items-center gap-[8px] rounded-[6px] border border-solid border-gray-300 px-[10px] py-[4px] text-[14px] font-normal leading-[16px]"
            htmlFor="filter-checkbox"
            onChange={e => {
              handleCheckboxChange(e);
              // @ts-expect-error
              remove('statuses');
            }}
          >
            <Checkbox id="filter-checkbox" className="rounded-[12px]" onChange={handleCheckboxChange} checked={active} />
            <span className="mt-[2px]">{t("boss_crm.Takroriy_qo'ng'iroqlarni_yashirish")}</span>
          </label>
        )}

        <Select
          className="customSelect w-[100%]"
          onChange={handleChange}
          mode="multiple"
          maxTagCount="responsive"
          value={searchParams?.statuses}
          disabled={active}
          placeholder={t('triggers.Tanlang')}
        >
          {Object.entries(selectOptions).map(([key, value]) => (
            <Option key={key} value={key}>
              {value}
            </Option>
          ))}
        </Select>

        <div>
          <h3 className="mb-[5px] text-[15px] font-normal leading-[16px] text-[#464255]">
            {t('home.Qongiroq_davomiyligi')} <span className="text-[#beb9b9]">({t('home.sekund')})</span>
          </h3>
          <div className="flex items-center justify-between">
            <Input
              className="customInput"
              type="number"
              placeholder={t('home.dan')}
              onChange={e => {
                setDuration({ start: e.target.value, end: duration.end && Number(e.target.value) > Number(duration.end) ? e.target.value : duration.end });
              }}
              onBlur={e => {
                handleInputChange('durationStart', e.target.value);
                handleInputChange('durationFinish', Number(duration.end) ? duration.end : '');
              }}
              value={duration.start}
            />
            <span className="mx-[4px]">-</span>
            <Input
              className="customInput"
              type="number"
              placeholder={t('home.gacha')}
              onChange={e => {
                setDuration(prev => ({ ...prev, end: e.target.value }));
              }}
              onBlur={e => {
                let { value } = e.target;

                if (!value) {
                  setDuration(prev => ({ ...prev, end: '' }));
                  handleInputChange('durationFinish', '');
                  return;
                }

                if (duration?.end && Number(value) <= Number(duration.start)) {
                  value = duration.start;
                }

                setDuration(prev => ({ ...prev, end: value }));

                handleInputChange('durationFinish', value);

                if (Number(duration.start)) {
                  handleInputChange('durationStart', duration.start);
                } else {
                  handleInputChange('durationStart', '');
                }
              }}
              value={duration.end}
            />
          </div>
        </div>

        <div>
          <h3 className="mb-[5px] text-[15px] font-normal leading-[16px] text-[#464255]">
            {t('home.Suhbat_davomiyligi')} <span className="text-[#beb9b9]">({t('home.sekund')})</span>
          </h3>
          <div className="flex items-center justify-between">
            <Input
              className="customInput"
              type="number"
              placeholder={t('home.dan')}
              onChange={e => {
                setTalkTime({ start: e.target.value, end: talkTime.end && Number(e.target.value) > Number(talkTime.end) ? e.target.value : talkTime.end });
              }}
              onBlur={e => {
                handleInputChange('talkTimeStart', e.target.value);
                handleInputChange('talkTimeFinish', Number(talkTime.end) ? talkTime.end : '');
              }}
              value={talkTime.start}
            />
            <span className="mx-[4px]">-</span>
            <Input
              className="customInput"
              type="number"
              placeholder={t('home.gacha')}
              onChange={e => {
                setTalkTime(prev => ({ ...prev, end: e.target.value }));
              }}
              onBlur={e => {
                let { value } = e.target;

                if (!value) {
                  setTalkTime(prev => ({ ...prev, end: '' }));
                  handleInputChange('talkTimeFinish', '');
                  return;
                }

                if (talkTime?.end && Number(value) <= Number(talkTime?.start)) {
                  value = talkTime?.start;
                }

                setTalkTime(prev => ({ ...prev, end: value }));

                handleInputChange('talkTimeFinish', value);

                if (Number(talkTime?.start)) {
                  handleInputChange('talkTimeStart', talkTime?.start);
                } else {
                  handleInputChange('talkTimeStart', '');
                }
              }}
              value={talkTime.end}
            />
          </div>
        </div>
      </div>
      <SpecialTimeModal visible={specialModal} close={handleSpecialModal} handleSelect={handleSelectDate} />
    </div>
  );
};

export default Header;
