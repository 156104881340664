import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useLeadTaskActions } from '@pages/task/services/mutation';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useQueryParam } from '@hooks/useQueryParams';
import { useAppSelector } from '@hooks/reduxHooks';
import { setChecked, setClearTaskListView, setTaskIgnoreIds } from '@reducers/CrmSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@icons/CloseIcon';
import SuccessComplateIcon from '@icons/SuccessComplateIcon';

import styles from './actionModals.module.scss';

type Props = {
  openEmployeeChange: boolean;
  setOpenEmployeeChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const CompleteTaskModal: React.FC<Props> = ({ openEmployeeChange, setOpenEmployeeChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { searchParams } = useQueryParam<SearchParamsTask, any>();
  const changeTaskEmployee = useLeadTaskActions(searchParams);
  const { taskListViewActiveIds: activeIds, taskListViewIgnoreIds } = useAppSelector(state => state.assigmentSlice);

  const handleClose = () => {
    setOpenEmployeeChange(false);
    form.resetFields();
  };

  const handleChangeTaskEmployee = (values: any) => {
    changeTaskEmployee
      .mutateAsync({
        tasks: activeIds,
        actionType: 'COMPLETE',
        ignoreTasks: taskListViewIgnoreIds.length === 0 ? null : taskListViewIgnoreIds,
        closedText: values?.closedText
      })
      .then(() => {
        handleClose();
        dispatch(setClearTaskListView());
        dispatch(setChecked(false));
        dispatch(setTaskIgnoreIds([]));
      });
  };

  return (
    <Modal visible={openEmployeeChange} onCancel={handleClose} title={false} footer={false} className={styles.changeEmployee}>
      <div className={styles.changeEmployee_body}>
        <div className={styles.changeEmployee_title}>
          <SuccessComplateIcon />
          <div className={styles.changeEmployee_title_right}>
            <div className={styles.changeEmployee_title_right_top}>
              <p>{t('create_table_payments.Yakunlash')}</p>
              <CloseIcon onClick={handleClose} />
            </div>
            <span>{t('one_task.Siz_vazifani_yakunlamoqchimisiz')}</span>
          </div>
        </div>
        <div className={styles.changeEmployee_select}>
          <span>{t('one_task.Topshiriqni_yakunlash_sababi')}</span>
          <Form form={form} onFinish={handleChangeTaskEmployee}>
            <Form.Item name="closedText" rules={[{ required: true, message: '' }]}>
              <Input.TextArea size="large" autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            <div className={styles.changeEmployee_footer}>
              <Button onClick={handleClose}>{t('home.Yopish')}</Button>
              <Button loading={changeTaskEmployee.isLoading} htmlType="submit">
                {t('home_id.Tasdiqlash')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteTaskModal;
