import React from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import ReactInputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { changeAllDateMonthlyPaymentsTable } from '../../../../store/reducers/ChangesSlice';
import { validateDateString } from '../../../../utils/helper/validatorDateString';
import CalendarIcon from '../../../../assets/icons/CalendarIcon';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

const ChangeDateModal: React.FC<Props> = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    onClose(false);
    form.setFieldsValue({
      date: ''
    });
  };

  const onFinish = (values: { date: string }) => {
    dispatch(changeAllDateMonthlyPaymentsTable(values.date));
    onCloseModal();
  };
  const { t } = useTranslation();
  return (
    <Modal
      title={`${t(`changes.monthlyPayments.Sanalarni_bir_vaqtda_o’zgartirish`)}`}
      wrapClassName="settingEmployeeModal"
      visible={visible}
      onCancel={onCloseModal}
      footer={false}
      width={546}
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={8} className={'d-flex justify-space-between'}>
          <Col span={24}>
            <Form.Item
              label={`${t(`changes.monthlyPayments.To’lov_sanasi`)}`}
              name="date"
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        expiry: validateDateString(value, true)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
            >
              <ReactInputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar={''}
              >
                {(inputProps: any) => <Input placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
              </ReactInputMask>
            </Form.Item>
          </Col>
        </Row>
        <div className="settingEmployeeModalFooter">
          <Button onClick={onCloseModal}>{t(`changes.monthlyPayments.Yopish`)}</Button>
          <Button htmlType="submit" type="primary">
            {t(`changes.monthlyPayments.Tasdiqlash`)}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeDateModal;
