import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@hooks/reduxHooks';
import ArrowLineDownIcon from '@icons/ArrowLineDownIcon';
import Download from '@icons/Download';
import ExcelIcon from '@icons/ExcelIcon';
import SettingIcon from '@icons/SettingIcon';
import { useShowroomTableExcel, useShowroomTableSummaryExcel, useShowroomTableSummaryExcelBeta } from '@pages/showroom-v2/services/mutation';
import { setListSettingDrawer } from '@reducers/ShowroomSlice';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './showroomV2MenuList.module.scss';

const ShowroomV2MenuList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);
  const download = useShowroomTableExcel();
  const downloadSummary = useShowroomTableSummaryExcel();
  const downloadSummaryBeta = useShowroomTableSummaryExcelBeta();

  const downloadExcel = () => {
    download.mutateAsync().then(() => setVisibleDownloadMenu(false));
  };

  const downloadExcelSummary = () => {
    downloadSummary.mutateAsync().then(() => setVisibleDownloadMenu(false));
  };

  const downloadExcelSummaryBeta = () => {
    downloadSummaryBeta.mutateAsync().then(() => setVisibleDownloadMenu(false));
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={styles.downloadPopover}>
          <div onClick={downloadExcel} className={styles.downloadPopover_item}>
            <div className={styles.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('contract.Jadvalni_yuklash')}</span>
            </div>
            {download.isLoading ? <LoadingOutlined /> : <ArrowLineDownIcon />}
          </div>
          <div onClick={downloadExcelSummary} className={styles.downloadPopover_item}>
            <div className={styles.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Xonadonlar_holati')}</span>
            </div>
            {downloadSummary.isLoading ? <LoadingOutlined /> : <ArrowLineDownIcon />}
          </div>
          <div onClick={downloadExcelSummaryBeta} className={styles.downloadPopover_item}>
            <div className={styles.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Xonadonlar_holati')}</span> <b className={styles.downloadPopover_item_element_beta}>Beta</b>
            </div>
            {downloadSummaryBeta.isLoading ? <LoadingOutlined /> : <ArrowLineDownIcon />}
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={styles.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  return (
    <div className={styles.container}>
      {downloadButton}{' '}
      <span className={styles.tableSettings} onClick={() => dispatch(setListSettingDrawer(true))}>
        {' '}
        <SettingIcon />
      </span>
    </div>
  );
};

export default ShowroomV2MenuList;
