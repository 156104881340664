import React from 'react';

const ReceiptDiscount = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.48634 13.1334C5.03301 12.5467 5.86634 12.5934 6.34634 13.2334L7.01967 14.1334C7.55967 14.8467 8.43301 14.8467 8.97301 14.1334L9.64634 13.2334C10.1263 12.5934 10.9597 12.5467 11.5063 13.1334C12.693 14.4 13.6597 13.98 13.6597 12.2067V4.69337C13.6663 2.00671 13.0397 1.33337 10.5197 1.33337H5.47968C2.95967 1.33337 2.33301 2.00671 2.33301 4.69337V12.2C2.33301 13.98 3.30634 14.3934 4.48634 13.1334Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 8.66663L10 4.66663" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.99666 8.66671H10.0027" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.99666 4.99996H6.00265" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ReceiptDiscount;
