import React from 'react';
import { cx } from '@utils/cx';

import * as icons from 'assets/icons';

import cls from './icon.module.css';

export type IconName = keyof typeof icons;

interface IconProps<T extends IconName> {
  name: T;
  className?: string;
  onClick?: () => void;
}

const Icon = <T extends IconName>({ name, className, onClick, ...props }: IconProps<T> & React.ComponentProps<(typeof icons)[T]>) => {
  const IconItem = icons[name](props || {});

  if (!IconItem) {
    // eslint-disable-next-line no-console
    console.log('Icon component not found');

    return null;
  }

  return (
    <div className={cx(cls.wrapper, 'h-4 w-4', className)} onClick={onClick}>
      {IconItem}
    </div>
  );
};

export default Icon;
