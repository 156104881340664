import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setAllHouseFilter } from '@reducers/AllHouseFilter';
import { Input } from 'global';
import { useTranslation } from 'react-i18next';

import Broom from '../../../assets/icons/Broom';
import Filter from '../../../assets/icons/Filter';
import FilterItemClose from '../../../assets/icons/FilterItemClose';
import SearchIcon from '../../../assets/icons/SearchIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { SearchParamsHome } from '../Home';

import HomeFilterDrawer from './HomeFilterDrawer';

import styles from '../../../components/filters/mainFilters.module.scss';

const HomeFilters = () => {
  const { searchParams, remove, removeAllSearchParams, removeMultiple, appendSingle } = useQueryParam<SearchParamsHome, any>();
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [countFilters, setCountFilters] = useState(1);

  const { filter } = useAppSelector(state => state.allHouseFilter);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const itemHas = () => {
    const newSearchParams = { ...searchParams };
    // delete newSearchParams.fields

    delete newSearchParams.houses;
    delete newSearchParams.buildings;
    delete newSearchParams.page;
    delete newSearchParams.size;
    delete newSearchParams.flatNumber;
    delete newSearchParams.order;
    delete newSearchParams.search;
    return Object.keys(newSearchParams).length > 0;
  };

  const handleFilter = () => {
    setFilterDrawerVisible(true);
  };

  useEffect(() => {
    let count = 0;

    if (searchParams.rooms) {
      if (Array.isArray(searchParams.rooms)) {
        count = searchParams.rooms?.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.saleStatuses) {
      if (Array.isArray(searchParams.saleStatuses)) {
        count += searchParams.saleStatuses?.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.maxArea && searchParams.maxArea) {
      count += 1;
    }
    if (searchParams.repaired) {
      count += 1;
    }
    if (searchParams.minFloor && searchParams.maxFloor) {
      count += 1;
    }
    if (searchParams.fields) {
      if (Array.isArray(searchParams.fields)) {
        count += searchParams.fields?.length || 0;
      } else {
        count += 1;
      }
    }
    setCountFilters(count);
  }, [searchParams]);

  const deleteOneFilter = (key: string, value: string) => {
    remove(key, value);
  };

  const deleteMultipleFilters = (key: string[], value: string[]) => {
    removeMultiple(key, value);
  };

  const showRooms = () => {
    if (searchParams.rooms) {
      if (!Array.isArray(searchParams.rooms)) {
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>
              {searchParams.rooms} {t('home.xonali')}
            </span>
            <FilterItemClose onClick={() => deleteOneFilter('rooms', searchParams.rooms as string)} className={styles.icon} />
          </div>
        );
      }
      return searchParams.rooms.map(room => (
        <div className={`${styles.item} d-flex align-center`} key={room}>
          <span className={styles.title}>
            {room} {t('home.xonali')}
          </span>
          <FilterItemClose onClick={() => deleteOneFilter('rooms', room)} className={styles.icon} />
        </div>
      ));
    }
    return null;
  };

  const showFlatFields = () => {
    if (searchParams.fields) {
      if (!Array.isArray(searchParams.fields)) {
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{decodeURI(searchParams.fields?.split('_')[1])}</span>
            <FilterItemClose onClick={() => deleteOneFilter('fields', searchParams.fields as string)} className={styles.icon} />
          </div>
        );
      }
      return searchParams.fields.map(room => (
        <div className={`${styles.item} d-flex align-center`} key={room}>
          <span className={styles.title}>{decodeURI(room?.split('_')[1])}</span>
          <FilterItemClose onClick={() => deleteOneFilter('fields', room)} className={styles.icon} />
        </div>
      ));
    }
    return null;
  };

  const showFlatStatus = () => {
    if (searchParams.saleStatuses) {
      if (!Array.isArray(searchParams.saleStatuses)) {
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{t(`home.${searchParams.saleStatuses}`)}</span>
            <FilterItemClose onClick={() => deleteOneFilter('saleStatuses', searchParams.saleStatuses as string)} className={styles.icon} />
          </div>
        );
      }
      return searchParams.saleStatuses.map(saleStatus => (
        <div className={`${styles.item} d-flex align-center`} key={saleStatus}>
          <span className={styles.title}>{t(`home.${saleStatus}`)}</span>
          <FilterItemClose onClick={() => deleteOneFilter('saleStatuses', saleStatus)} className={styles.icon} />
        </div>
      ));
    }
    return null;
  };

  const showArea = () => {
    if (searchParams.maxArea && searchParams.maxArea) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>
            {searchParams.minArea}-{searchParams.maxArea} {t('home.m')}
            <sup>2</sup>
          </span>
          <FilterItemClose
            onClick={() => deleteMultipleFilters(['minArea', 'maxArea'], [searchParams.minArea as string, searchParams.maxArea as string])}
            className={styles.icon}
          />
        </div>
      );
    }
  };

  const showRepaired = () => {
    if (searchParams.repaired) {
      const repaired = searchParams.repaired === 'true' ? t('home.Tamirli') : t('home.Tamirsiz');

      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>{t(`home.${repaired}`)}</span>
          <FilterItemClose onClick={() => deleteOneFilter('repaired', searchParams.repaired as string)} className={styles.icon} />
        </div>
      );
    }
  };

  const showApartment = () => {
    if (searchParams.apartment) {
      const apartment = searchParams.apartment === 'true' ? 'Turarjoy' : 'Noturar_joy';

      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>{t(`home_id.${apartment}`)}</span>
          <FilterItemClose onClick={() => deleteOneFilter('repaired', searchParams.apartment as string)} className={styles.icon} />
        </div>
      );
    }
  };

  const showFloor = () => {
    if (searchParams.minFloor && searchParams.maxFloor) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>
            {searchParams.minFloor} - {searchParams.maxFloor} {t('home.qavat')}
          </span>
          <FilterItemClose
            onClick={() => deleteMultipleFilters(['minFloor', 'maxFloor'], [searchParams.minArea as string, searchParams.minArea as string])}
            className={styles.icon}
          />
        </div>
      );
    }
  };

  const showFilters = () => {
    const rooms = showRooms();
    const status = showFlatStatus();
    const area = showArea();
    const repaired = showRepaired();
    const floor = showFloor();
    const apartment = showApartment();
    const fields = showFlatFields();

    return (
      <>
        {rooms}
        {status}
        {area}
        {repaired}
        {floor}
        {apartment}
        {fields}
      </>
    );
  };

  const clearAllFilter = () => {
    removeAllSearchParams();
    dispatch(setAllHouseFilter({ ...filter, fields: [null] }));
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('flatNumber', value);
      else remove('flatNumber', undefined);
    });
  };

  const showIconInput = () => {
    if (searchParams.flatNumber) return <CloseOutlined onClick={() => handleSearch('')} className="deleteSearchIcon" />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.cont}>
      <HomeFilterDrawer visible={filterDrawerVisible} setVisible={setFilterDrawerVisible} />

      <div className="d-flex align-center">
        <div onClick={() => handleFilter()} className={`${countFilters > 0 ? `${styles.filter_button} ${styles.active}` : styles.filter_button} d-flex align-center`}>
          <Filter />
          <span className={styles.title}>{t('home.Filtr')}</span>
          {countFilters > 0 && <span className={styles.count_filters}>{countFilters}</span>}
        </div>
        <Input value={searchParams.flatNumber} onChange={handleSearch} suffix={showIconInput()} placeholder={t('home.Qidirish')} className={styles.search} />

        {itemHas() ? (
          <div className={`${styles.filtered_items_cont_right} d-flex align-center`}>
            {showFilters()}
            <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
              <Broom />
            </div>
            <div className={styles.line} />
          </div>
        ) : (
          <div className={styles.not_select_title_right}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
        )}
      </div>
      {itemHas() ? (
        <div className={`${styles.filtered_items_cont} d-flex align-center`}>
          {showFilters()}
          <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
            <Broom />
          </div>
          <div className={styles.line} />
        </div>
      ) : (
        <div className={styles.not_select_title}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')} </div>
      )}
    </div>
  );
};

export default HomeFilters;
