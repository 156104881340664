import React from 'react';

const VipClientIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8L14.2255 6.00714L14.4727 3.37143L11.8473 2.78571L10.4727 0.5L8 1.54286L5.52727 0.5L4.15273 2.77857L1.52727 3.35714L1.77455 6L0 8L1.77455 9.99286L1.52727 12.6357L4.15273 13.2214L5.52727 15.5L8 14.45L10.4727 15.4929L11.8473 13.2143L14.4727 12.6286L14.2255 9.99286L16 8Z"
      fill="#F9D047"
    />
    <path
      d="M5.86426 10.5H5.14648L3.6377 6.27246H4.34961L5.50098 9.76172H5.51855L6.67285 6.27246H7.37305L5.86426 10.5ZM8.49805 10.5H7.8418V6.27246H8.49805V10.5ZM9.27734 6.27246H10.8623C11.2725 6.27246 11.6064 6.40137 11.8643 6.65918C12.124 6.91699 12.2539 7.25098 12.2539 7.66113C12.2539 8.07324 12.1221 8.40918 11.8584 8.66895C11.5967 8.92676 11.2578 9.05566 10.8418 9.05566H9.93359V10.5H9.27734V6.27246ZM9.93359 6.82617V8.50488H10.6865C10.9717 8.50488 11.1924 8.43164 11.3486 8.28516C11.5049 8.13867 11.583 7.93164 11.583 7.66406C11.583 7.39844 11.5049 7.19238 11.3486 7.0459C11.1943 6.89941 10.9746 6.82617 10.6895 6.82617H9.93359Z"
      fill="white"
    />
  </svg>
);

export default VipClientIcon;
