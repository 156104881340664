import React from 'react';

const UnionShowroomIcon = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8334 9.83329C10.8334 10.2933 10.4601 10.6666 10.0001 10.6666C9.54009 10.6666 9.16675 10.2933 9.16675 9.83329V6.49996C9.16675 6.03996 9.54009 5.66663 10.0001 5.66663C10.4601 5.66663 10.8334 6.03996 10.8334 6.49996V9.83329ZM10.0001 13.1666C9.54009 13.1666 9.16675 12.7933 9.16675 12.3333C9.16675 11.8733 9.54009 11.5 10.0001 11.5C10.4601 11.5 10.8334 11.8733 10.8334 12.3333C10.8334 12.7933 10.4601 13.1666 10.0001 13.1666ZM18.8009 12.5858L12.4076 1.98663C11.9084 1.15996 11.0084 0.666626 10.0001 0.666626C8.99176 0.666626 8.09176 1.15996 7.59259 1.98663L1.19926 12.5858C0.725922 13.3716 0.711755 14.3166 1.16176 15.1133C1.64426 15.9691 2.58176 16.5 3.60676 16.5H16.3934C17.4184 16.5 18.3559 15.9691 18.8384 15.1133C19.2884 14.3166 19.2743 13.3716 18.8009 12.5858Z"
        fill="white"
      />
    </svg>
  );
};

export default UnionShowroomIcon;
