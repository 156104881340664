import React from 'react';

const SmileyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 7.25C6.02614 7.25 6.25 7.02614 6.25 6.75C6.25 6.47386 6.02614 6.25 5.75 6.25C5.47386 6.25 5.25 6.47386 5.25 6.75C5.25 7.02614 5.47386 7.25 5.75 7.25Z"
        fill="#444444"
      />
      <path
        d="M10.25 7.25C10.5261 7.25 10.75 7.02614 10.75 6.75C10.75 6.47386 10.5261 6.25 10.25 6.25C9.97386 6.25 9.75 6.47386 9.75 6.75C9.75 7.02614 9.97386 7.25 10.25 7.25Z"
        fill="#444444"
      />
      <path
        d="M10.6004 9.5C10.3361 9.95537 9.95679 10.3333 9.5005 10.5961C9.04421 10.8588 8.52692 10.9971 8.00039 10.9971C7.47386 10.9971 6.95657 10.8588 6.50028 10.5961C6.04399 10.3333 5.66471 9.95537 5.40039 9.5"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmileyIcon;
