import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';

export const useContractVariationDelete = (props?: { groupId?: number | null }) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id?: number | null) => {
      const res = await $api.delete(`/v1/contract/variation/${id}`);

      return res?.data?.data;
    },
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.CONTRACT_VARIATION_GROUP_ID, props?.groupId]);
    }
  });
};
