import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusKey } from '../../utils/models/TransferData';

interface TransferDataState {
  showModal: boolean;
  status?: StatusKey;
  connectSocket?: boolean;
}

const initialState: TransferDataState = {
  showModal: false
};

export const transferDataSlice = createSlice({
  name: 'transferData',
  initialState,
  reducers: {
    showInfoModal: (state, action: PayloadAction<StatusKey>) => {
      state.showModal = true;
      state.status = action.payload;
    },
    showTransferModal: state => {
      state.showModal = true;
      state.connectSocket = true;
    },
    clearTransferData: state => {
      state.showModal = false;
      state.status = undefined;
      state.connectSocket = undefined;
    },
    changeSocketStatus: (state, action: PayloadAction<boolean>) => {
      state.connectSocket = action.payload;
    }
  }
});

export const { showInfoModal, showTransferModal, clearTransferData, changeSocketStatus } = transferDataSlice.actions;
export default transferDataSlice.reducer;
