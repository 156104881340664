import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

import styles from './monthView.module.scss';
import '@fullcalendar/react/dist/vdom';
import FullCalendar, { EventSourceInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { Spin } from 'antd';
import { completeTaskVisible, setTypeAndDate } from '@reducers/TaskCalendarViewSlice';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import DateItem from './DateItem';
import { TaskFilter } from '@utils/models/TaskFilter';
import { useTranslation } from 'react-i18next';
import { useTaskCalendarFilter } from '@pages/task/services/queries';

const MonthView = () => {
  const { searchParams } = useQueryParam<SearchParamsTask, null>();

  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const dispatch = useAppDispatch();

  const calendarRef = useRef<FullCalendar>(null);

  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useTaskCalendarFilter(
    searchParams,
    dayjs(date, DATE_FORMAT).startOf('month').format(DATE_FORMAT),
    dayjs(date, DATE_FORMAT).endOf('month').format(DATE_FORMAT)
  );

  useEffect(() => {
    if (data) {
      const events: EventSourceInput = [];
      data.forEach(item => {
        const dateEvent = new Date(dayjs(item.deadlineStart, DATE_WITH_TIME_FORMAT).format());
        events.push({
          id: String(item.id),
          resourceId: item,
          start: dateEvent,
          end: dateEvent
        });
      });
      const calendarApi = calendarRef?.current?.getApi();

      calendarApi?.gotoDate(new Date(dayjs(date, DATE_FORMAT).format()).toISOString());
      calendarApi?.removeAllEventSources();
      calendarApi?.addEventSource([...events]);
    }
  }, [data, calendarRef, date]);

  // got to one day
  const goToOneDate: (arg: DateClickArg) => void = e => {
    dispatch(
      setTypeAndDate({
        date: dayjs(e.date).format(DATE_FORMAT),
        type: TaskCalendarViewType.DAY
      })
    );
  };

  // handle open complete modal
  const handleOpenCompleteModal = (task: TaskFilter) => {
    if (!task.closedDate)
      dispatch(
        completeTaskVisible({
          task,
          visible: true
        })
      );
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <FullCalendar
          dayHeaderClassNames={styles.container_header}
          eventClassNames={styles.container_event}
          ref={calendarRef}
          height={'100%'}
          dayHeaderContent={e => {
            return (
              <div className={styles.container_header}>
                <span>{t(`calendar_view.${dayjs(e.date).format('ddd')}`)}</span>
              </div>
            );
          }}
          moreLinkContent={e => {
            return (
              <div className={styles.container_more}>
                {e.shortText} {t('calendar_view.topshiriq')}
              </div>
            );
          }}
          firstDay={1}
          dateClick={goToOneDate}
          dayCellContent={e => {
            return (
              <div className={styles.container_date}>
                <div className={styles.container_date_number}>{e.dayNumberText}</div>
              </div>
            );
          }}
          dayCellClassNames={e => {
            if (e.isOther) return styles.container_date_disabled;
            return styles.container_date_enabled;
          }}
          dragScroll={true}
          eventContent={e => {
            return <DateItem data={e?.event?._def?.extendedProps?.resourceId} />;
          }}
          eventClick={e => {
            handleOpenCompleteModal(e?.event?._def?.extendedProps?.resourceId);
          }}
          dayMaxEvents={2}
          dayMaxEventRows={3}
          eventDisplay="block"
          weekends
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          progressiveEventRendering
          headerToolbar={false}
        />
      </div>
    </Spin>
  );
};

export default MonthView;
