import React, { useEffect } from 'react';
import { useGetAllBuildings } from '@queries/queries';
import { Button, Form, Input, Spin, Switch, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import TrushSquareIcon from '../../../assets/icons/TrushSquareIcon';
import { useCashChangeStatus, useCashCreate, useCashDelete } from '../../../queries/mutation';
import { Cash, SystemTypeCash } from '../../../utils/models/Cash';

import { CashModel, UpdateCashModel } from './CashModel';

import logo from '../../../assets/img/PaymeLogo.png';

import styles from '../paymentSettings.module.scss';

interface Props {
  data: Cash | undefined;
  index: number;
  setPaymeVal: React.Dispatch<React.SetStateAction<Cash[] | undefined>>;
}

const Payme: React.FC<Props> = ({ data, index, setPaymeVal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { data: buildingAll } = useGetAllBuildings();
  const createPaymeCash = useCashCreate();
  const changePaymeCashStatus = useCashChangeStatus();
  const deletePaymeCash = useCashDelete(data?.id);

  const treeValue: string[] = Form.useWatch('buildingIds', form);
  const checkSelect = data?.buildingIds?.every(item => treeValue?.includes(`1-${  item}`)) && data?.buildingIds?.length === treeValue?.length;

  const tree = buildingAll?.map(item => ({
    title: item?.houseName,
    value: `0-${item?.houseId}`,
    key: `0-${item?.houseId}`,
    children: item?.buildingCompactDto?.map((prev, i) => ({
      title: prev?.name,
      value: `1-${prev?.id}`,
      key: `1-${prev?.id}`
    }))
  }));

  const tProps = {
    treeData: tree,
    treeCheckable: true,
    placeholder: `${t('boss_crm.Manbaalarni_tanlang')}...`,
    style: {
      width: 298
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        cashId: data.cashId,
        cashKey: data.cashKey,
        buildingIds: data?.buildingIds?.map(item => `1-${item}`)
      });
    }
  }, [data, form]);

  const onFinish = (values: CashModel) => {
    createPaymeCash.mutate({
      cashId: values.cashId?.trim(),
      cashKey: values.cashKey?.trim(),
      systemType: SystemTypeCash.PAYME,
      buildingIds: values?.buildingIds?.map(item => Number(item?.toString().replace('1-', '')))
    });
  };

  const changeStatus = (data: UpdateCashModel) => {
    changePaymeCashStatus.mutate(data);
  };

  const handleDelete = () => {
    deletePaymeCash.mutate();
    form.resetFields();
  };

  return (
    <Spin spinning={deletePaymeCash.isLoading || createPaymeCash.isLoading}>
      <div className={`d-flex align-center ${  styles.margin_bottom}`}>
        <div className={styles.logo}>
          <img src={logo} alt="Payme" />
        </div>
        <div className={styles.cont}>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="d-flex align-center">
              <div className={styles.cont_inputCont}>
                <Form.Item
                  label={<label className={styles.label}>{t('paymeCash.Kassa_ID')}</label>}
                  rules={[{ required: true, message: '' }]}
                  name="cashId"
                >
                  <Input className={styles.input} disabled={!data?.type} />
                </Form.Item>
              </div>
              <div className={styles.cont_inputCont}>
                <Form.Item
                  label={<label className={styles.label}>{t('paymeCash.Kassa_kaliti')}</label>}
                  rules={[{ required: true, message: '', min: 4 }]}
                  name="cashKey"
                >
                  <Input disabled={!data?.type} className={styles.input} />
                </Form.Item>
              </div>
              <div className={styles.cont_inputCont}>
                <Form.Item
                  label={<label className={styles.label}>{t('home.obyekt')}</label>}
                  rules={[{ required: true, message: '' }]}
                  name="buildingIds"
                >
                  <TreeSelect {...tProps} maxTagCount="responsive" disabled={!data?.type} />
                </Form.Item>
              </div>

              {data?.type && (
                <div className={styles.cont_button_cont}>
                  <Button loading={createPaymeCash.isLoading} htmlType="submit" className={styles.cont_button_cont_button} type="primary">
                    {t('paymeCash.Saqlash')}
                  </Button>
                  {data?.type && (
                    <TrushSquareIcon
                      onClick={() => {
                        setPaymeVal(prev => [...prev!?.filter((item, i) => i !== index)]);
                      }}
                    />
                  )}
                </div>
              )}
              {!data?.type && checkSelect && (
                <div className="d-flex">
                  <div className={styles.switch}>
                    <label className={styles.label}>{t('paymeCash.Holati')}</label>
                    <Switch
                      loading={changePaymeCashStatus.isLoading}
                      onChange={e => changeStatus({ ...(data as any), status: e })}
                      // checked={data?.status}
                    />
                  </div>
                  <div className={styles.action}>
                    <label className={styles.label}>{t('paymeCash.Amallar')}</label>
                    <div className={styles.action_delete} onClick={handleDelete}>
                      <TrushSquareIcon />
                      <span>{t('paymeCash.Ochirish')}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default Payme;
