import React, { useState } from 'react';
import TableConfigDrawer from '@components/filters/TableConfigDrawer';
import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import FilterIcon from '@icons/FilterIcon';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import SearchIcon from '@icons/SearchIcon';
import Setting3Icon from '@icons/Setting3Icon';
import { Permissions } from '@utils/constants/permissions';
import { TableKey } from '@utils/models/TableKey';
import { Input } from 'antd';
import { Tabs } from 'global';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam as useQueryParamState, withDefault } from 'use-query-params';

import CreateRobotCall from './components/create-robot-call/CreateRobotCall';
import RobotoDrawer from './components/roboto-drawer/RobotoDrawer';
import RobotoTable from './components/roboto-table/RobotoTable';

const RobotoCalls = () => {
  const { t } = useTranslation();
  const { searchParams, appendSingle, remove } = useQueryParam<any, any>();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isBrojectPermission = permissions?.includes(Permissions.PERMISSION_BROJECT);

  const [visible, setVisible] = useState(false);
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState(false);
  const [visibleCall, setVisibleCall] = useState(false);
  const [activeTab, setActiveTab] = useQueryParamState('type', withDefault(StringParam, 'UYSOT'));

  const [modalData, setModalData] = useState<any | undefined>(undefined);

  const handleOpenDrawer = () => {
    setVisible(true);
  };

  const handleOpenSettingsDrawer = () => {
    setOpenSettingsDrawer(true);
  };

  const handleCloseSettingsDrawer = () => {
    setOpenSettingsDrawer(false);
  };

  const handleOpenRobotCall = () => {
    setModalData({ type: 'UYSOT' });
    setVisibleCall(true);
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('text', value);
      else remove('text', undefined);
    });
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className="flex h-[44px] items-center justify-between border-b border-l-0 border-r-0 border-t border-solid border-[#EAEAEA] bg-white">
        <div className="flex items-center">
          <div
            className="flex h-full cursor-pointer items-center gap-2 border-b-0 border-l-0 border-r border-t-0 border-solid border-[#EAEAEA] px-6 py-3"
            onClick={handleOpenDrawer}
          >
            <FilterIcon />
            <span className="font-medium text-[#344054]">{t('home.Filtr')}</span>
          </div>
          <div
            className="flex h-full cursor-pointer items-center gap-2 border-b-0 border-l-0 border-r border-t-0 border-solid border-[#EAEAEA] px-6 py-3"
            onClick={handleOpenSettingsDrawer}
          >
            <Setting3Icon />
            <span className="font-medium text-[#344054]">{t('home.Jadval_sozlamalari')}</span>
          </div>
          {isBrojectPermission ? (
            <Tabs className="[&>.ant-tabs-nav]:m-0 [&>.ant-tabs-nav]:ms-[12px]" onChange={setActiveTab} activeKey={activeTab}>
              <Tabs.TabPane tab={<span className="px-[12px]">{t('home.Qongiroq')}</span>} key="UYSOT" />
              <Tabs.TabPane tab={<span className="px-[12px]">{t('integration.broject')}</span>} key="BROJECT" />
            </Tabs>
          ) : (
            ''
          )}
        </div>
        <div className="flex h-full items-center gap-4">
          <div className="flex h-8 items-center rounded-lg border border-solid border-[#EAEAEA] px-3">
            <SearchIcon />
            <Input value={searchParams?.text} onChange={e => handleSearch(e.target.value)} placeholder={t('home.Qidirish')} className="!border-none !shadow-none" />
          </div>
          <div className="flex h-full cursor-pointer items-center gap-2.5 bg-[#00A389] px-4 py-2.5" onClick={handleOpenRobotCall}>
            <PlusCircleIcon /> <span className="font-semibold text-white">{t('home.toplam')}</span>
          </div>
        </div>
      </div>
      <div className="px-3 py-3">
        <RobotoTable setVisible={setVisibleCall} setModalData={setModalData} />
      </div>
      <RobotoDrawer setVisible={setVisible} visible={visible} />
      <TableConfigDrawer visible={openSettingsDrawer} tableKey={TableKey.robot_call} onClose={handleCloseSettingsDrawer} />
      <CreateRobotCall visible={visibleCall} setVisible={setVisibleCall} setModalData={setModalData} modalData={modalData} place="calls" />
    </>
  );
};

export default RobotoCalls;
