import React from 'react';

interface IProps {
  stroke?: string;
  size?: number;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const SearchIcon = ({ stroke = '#464255', size = 12, onClick }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
    <path
      d="M0.666626 5.33332C0.666626 6.571 1.15829 7.75798 2.03346 8.63315C2.90863 9.50832 4.09562 9.99999 5.33329 9.99999C6.36967 10.0013 7.37639 9.65406 8.19162 9.01416L10.7525 11.5808C10.8067 11.6355 10.8712 11.6789 10.9423 11.7085C11.0134 11.7381 11.0896 11.7534 11.1666 11.7534C11.2436 11.7534 11.3199 11.7381 11.391 11.7085C11.462 11.6789 11.5266 11.6355 11.5808 11.5808C11.6355 11.5266 11.6789 11.4621 11.7085 11.391C11.7381 11.3199 11.7533 11.2437 11.7533 11.1667C11.7533 11.0896 11.7381 11.0134 11.7085 10.9423C11.6789 10.8712 11.6355 10.8067 11.5808 10.7525L9.01412 8.19166C9.65403 7.37642 10.0012 6.3697 9.99996 5.33332C9.99996 4.09565 9.50829 2.90866 8.63312 2.03349C7.75795 1.15832 6.57097 0.666656 5.33329 0.666656C4.09562 0.666656 2.90863 1.15832 2.03346 2.03349C1.15829 2.90866 0.666626 4.09565 0.666626 5.33332ZM5.33329 1.83332C6.02553 1.83332 6.70222 2.03859 7.27779 2.42318C7.85336 2.80776 8.30196 3.35439 8.56687 3.99393C8.83178 4.63347 8.90109 5.3372 8.76604 6.01614C8.63099 6.69507 8.29765 7.31871 7.80817 7.8082C7.31868 8.29768 6.69504 8.63102 6.01611 8.76607C5.33717 8.90112 4.63344 8.83181 3.9939 8.5669C3.35436 8.30199 2.80773 7.85339 2.42315 7.27782C2.03856 6.70225 1.83329 6.02556 1.83329 5.33332C1.83329 4.40506 2.20204 3.51483 2.85842 2.85845C3.5148 2.20207 4.40503 1.83332 5.33329 1.83332Z"
      fill={stroke}
    />
  </svg>
);

export default SearchIcon;
