import CounterInput from '@components/counter-input/CounterInput';
import CustomInput from '@components/custom-fields/components/custom-input/CustomInput';
import CustomSelect from '@components/custom-fields/components/custom-select/CustomSelect';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '../../../../../assets/icons/ArrowDownIconCrm';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { useCrmEmployee, useCrmFields } from '../../../../../queries/queries';
import { changeLeadsFiledValue, leadFieldFilterAi, setBalance, setEmployeeId } from '../../../../../store/reducers/CrmOneLidSlice';
import { helper } from '../../../../../utils/helper/helper';
import { CrmFieldsType } from '../../../../../utils/models/CrmFieldsType';
import LocationAttachment from '../location-attachment/LocationAttachment';

import GetLocationAddres from './GetLocationAddres';
import MultiSelect from './MultiSelect';
import SelectAddress from './SelectAddress';

import styles from './info.module.scss';

const { Option } = Select;

export const convertLocation = (value: string) => {
  const obj = value && value.includes('}') && value.includes('{') ? JSON.parse(value) : {};

  return {
    lat: obj?.lat,
    lng: obj?.lng,
    radius: obj?.radius,
    name: obj?.name || ''
  };
};

type Props = {
  currentId: number | undefined;
  currentName: string | undefined;
};

const Info: React.FC<Props> = ({ currentId, currentName }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data, isLoading } = useCrmEmployee();
  const { isLoading: loadingFields } = useCrmFields();
  const { leadFieldValueAi } = useAppSelector(state => state.crmOneLidField);

  const led = useAppSelector(state => state.crmField.led);

  const employeeId = useAppSelector(state => state.crmOneLidField.responsibleById);

  const balanceString = useAppSelector(state => state.crmOneLidField.balanceString);
  const fields = useAppSelector(state => state.crmOneLidField.leadFieldValue);

  // handle change employee
  const handleChangeEmployee = (e: number) => {
    dispatch(setEmployeeId(e));
  };

  // handle change balance
  const handleChangeBalance = (e: string) => {
    dispatch(setBalance(helper.deleteNotNumbersAndParseFloatNumber(e).string));
  };

  // handle change value fields
  const changeValueFields = (id: number, value: string) => {
    dispatch(changeLeadsFiledValue({ value, id }));
  };

  const finalData =
    data && currentId !== null
      ? [
          ...data?.filter(item => item?.id !== currentId),
          {
            id: currentId,
            fullName: data?.some(item => item?.id === currentId) ? currentName : `${currentName} (${t('settingEmployee.Bloklangan')})`
          }
        ]
      : data;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_body}>
          <div className={styles.container_body_item}>
            <div className={styles.container_body_item_name}>{t('one_lid.Masul_hodim')}</div>
            <Select
              optionFilterProp="children"
              showSearch
              onChange={handleChangeEmployee}
              value={employeeId}
              loading={isLoading}
              bordered={false}
              suffixIcon={<ArrowDownIcon />}
              className={styles.container_body_item_value}
            >
              {finalData?.map((emp: any) => (
                <Option value={emp.id} key={emp.id}>
                  {emp.fullName}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.container_body_item}>
            <div className={styles.container_body_item_name}>{t('one_lid.Budjet')}</div>
            <CustomInput
              onChange={e => handleChangeBalance(e.target.value)}
              value={balanceString}
              bordered={false}
              className={styles.container_body_item_value}
              ai={leadFieldValueAi?.includes(`balance`)}
              onStopAiTime={() => {
                dispatch(leadFieldFilterAi(`balance`));
              }}
            />
          </div>

          <Spin spinning={loadingFields} wrapperClassName={styles.container_body_spin}>
            {led.map((item, index) => {
              if (item.type === CrmFieldsType.TEXT) {
                return (
                  <div key={item?.id} className={styles.container_body_item} id={`field_${item?.id}`}>
                    <div className={styles.container_body_item_name}>{item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}</div>
                    <CustomInput
                      onChange={e => changeValueFields(Number(item.id), e.target.value)}
                      value={fields.find(field => field.id === item.id)?.value || ''}
                      bordered={false}
                      placeholder="..."
                      className={styles.container_body_item_value}
                      ai={leadFieldValueAi?.includes(`${item?.id}:${item?.name}`)}
                      onStopAiTime={() => {
                        dispatch(leadFieldFilterAi(`${item?.id}:${item?.name}`));
                      }}
                    />
                  </div>
                );
              }
              if (item.type === CrmFieldsType.LOCATION) {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div className={styles.container_body_item}>
                    <div className={styles.container_body_item_name}>{item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}</div>
                    <LocationAttachment id={item?.id} value={fields.find(field => field.id === item.id)?.value || ''}>
                      <GetLocationAddres {...convertLocation(fields.find(field => field.id === item.id)?.value || '')} />
                    </LocationAttachment>
                  </div>
                );
              }
              if (item.type === CrmFieldsType.COUNTER) {
                return (
                  <div key={item?.id} className={styles.container_body_item}>
                    <div className={styles.container_body_item_name}>{item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}</div>
                    <div className={styles.counter}>
                      <CounterInput
                        value={fields.find(field => field.id === item.id)?.value || ''}
                        setValue={value => {
                          changeValueFields(Number(item.id), value);
                        }}
                      />
                    </div>
                  </div>
                );
              }
              if (item?.type === CrmFieldsType.ADDRESS) {
                // eslint-disable-next-line react/jsx-key
                return <SelectAddress item={item} index={index} />;
              }
              if (item?.type === CrmFieldsType.MULTI_SELECT) {
                // eslint-disable-next-line react/jsx-key
                return <MultiSelect item={item} index={index} />;
              }
              return (
                <div key={item?.id} className={styles.container_body_item} id={`field_${item?.id}`}>
                  <div className={styles.container_body_item_name}>{item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}</div>
                  <CustomSelect
                    value={fields.find(field => field.id === item.id)?.value || ''}
                    onChange={e => changeValueFields(Number(item.id), e)}
                    bordered={false}
                    suffixIcon={<ArrowDownIcon />}
                    className={styles.container_body_item_value}
                    ai={leadFieldValueAi?.includes(`${item?.id}:${item?.name}`)}
                    onStopAiTime={() => {
                      dispatch(leadFieldFilterAi(`${item?.id}:${item?.name}`));
                    }}
                  >
                    {item?.options?.map((item, index) => (
                      <Option key={item?.id} value={item.name}>
                        {item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}
                      </Option>
                    ))}
                  </CustomSelect>
                </div>
              );
            })}
          </Spin>
        </div>
      </div>
    </>
  );
};

export default Info;
