export enum BossPagePaths {
  HOME = '/home',
  PAYMENT = '/payment',
  ARREARAGE = '/arrearage',
  COMPLEX = '/complex',
  CONTRACT = '/contract',
  CRM = '/crm',
  SALE = '/sale',
  BI_ANALYTICS = '/bi-analytics'
}
