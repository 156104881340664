import React, { useState } from 'react';
import { Button, Input, Modal, Spin, Switch } from 'antd';
import styles from './controlTag.module.scss';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import { useTags } from '../../../../../queries/queries';
import { useCreateTag } from '../../../../../queries/mutation';
import { useTranslation } from 'react-i18next';
import { useDeleteTag, useSaleCrmTag } from '../../../services/mutation';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
}

const ControlTagsModal: React.FC<Props> = ({ visible, onClose }) => {
  const [search, setSearch] = useState('');

  const { isLoading, data } = useTags();
  const { t } = useTranslation();

  const createTag = useCreateTag(handleSuccess);
  const saleCrmTag = useSaleCrmTag();
  const deleteTag = useDeleteTag();

  // handle success
  function handleSuccess() {
    setSearch('');
  }

  // handle cancel
  const handleCancel = () => {
    setSearch('');
    onClose(false);
  };

  // handle search value
  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  // filter tag
  const filterTag = () => {
    if (data) {
      const tags = data.tagList;
      return tags.filter(tag => tag.name.includes(search));
    }
    return [];
  };

  // handleCreateTag
  const handleCreateTag = () => {
    createTag.mutate(search);
  };

  // change sale crm
  const changeSaleCrm = (value: boolean) => {
    saleCrmTag.mutate(value);
  };

  // delete tag
  const deleteTagHandler = (id: number) => {
    deleteTag.mutate(id);
  };

  return (
    <Modal className={styles.container} width={515} footer={false} visible={visible} title={t('one_lid.Teglarni_boshqarish')} onCancel={handleCancel}>
      <Spin spinning={isLoading || deleteTag.isLoading}>
        <Input value={search} onChange={handleSearchValue} className={'customInput'} placeholder={'Natija'} />
        <div className={styles.container_check_cont}>
          <p>{t('one_lid.Barcha_foydalanuvchilar_yangi_teg_sharatishlariga_ruxsat_berish')}</p>
          <Switch loading={saleCrmTag.isLoading} onChange={changeSaleCrm} checked={data?.isAllowed} size={'small'} />
        </div>
        <div className={styles.container_tags_cont}>
          {filterTag().map((tag, index) => (
            <div className={styles.container_tags_cont_one} key={index}>
              <span className={styles.container_tags_cont_one_tag}>{tag.name}</span>
              <TrushSquareIcon onClick={() => deleteTagHandler(Number(tag.id))} />
            </div>
          ))}
          {filterTag().length === 0 && (
            <div className={styles.container_tags_cont_one}>
              <Button loading={createTag.isLoading} onClick={handleCreateTag} className={styles.container_tags_cont_one_button} type={'primary'}>
                {t('one_lid.Teg_yaratish')}
              </Button>
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ControlTagsModal;
