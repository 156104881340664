import React, { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCrmCurrentUserRole } from '@pages/crm/services/queries';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';
import { useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useLeadChangeStep } from '../../../../queries/mutation';
import { queryNames } from '../../../../utils/constants/queryNames';
import { CrmLeadListV2 } from '../../../../utils/models/CrmLeadList';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../utils/models/GoogleAnalitks';
import { PipeAll, PipeStatuses } from '../../../../utils/models/PipeAll';
import { SearchParamsCrm } from '../filter/FilterButton';

import DeleteLeadModal from './deleteLeadModal/DeleteLeadModal';
import LeadTaskCompleteModal from './leadTaskCompleteModal/LeadTaskCompleteModal';
import ReasonsRafusalModal from './reasonsRefusalModal/ReasonsRefusalModal';
import Triggers from './triggers/modal/Triggers';
import VoronkaCardColumn from './VoronkaCardColumn';
import VoronkaCardColumnFooterContent from './VoronkaCardColumnFooterContent';

import styles from './voronkaCards.module.scss';

interface IProps {
  data: PipeAll | undefined;
}

const VoronkaCards: FC<IProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const dragRef = useRef<HTMLDivElement | null>(null);
  const qc = useQueryClient();
  const location = useLocation();
  const leadChageStep = useLeadChangeStep(() => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CHANGE_STEP_LEAD_ON_BOARD
    });
  });
  const [scrollDown, setScrollDown] = useState(false);
  const [transformOrign, setTransformOrign] = useState({ x: 0, y: 0 });
  const [scrollBy, setScrollBy] = useState<number>(0);
  const scrollRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [draggableStop, setDraggableStop] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [draggingId, setDraggingId] = useState(0);
  const sortableColumn = useAppSelector(state => state?.assigmentSlice?.sortableColumn);
  const [reasonsRefusal, setReasonsRefusal] = useState({
    id: 0,
    visible: false,
    leadId: 0,
    statusId: 0
  });

  const [leadTaskCompleteModal, setLeadTaskCompleteModal] = useState({
    id: 0,
    visible: false,
    leadId: 0,
    statusId: 0
  });

  const [deleteLead, setDeleteLead] = useState({ visible: false, id: 0 });

  const { data: currentUserRoleData } = useGetCrmCurrentUserRole();

  // change step

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!result.destination) {
      return;
    }
    const sourceStatusData = qc.getQueryData<PipeAll[]>([queryNames.PIPE_ALL]);
    const pipeId = Number(queryString.parse(location.search).voronka);

    if ((source && !destination) || Number.isNaN(Number(draggableId))) {
      const pipe = sourceStatusData?.find(item => item.id === pipeId)?.pipeStatuses;

      if (destination?.index !== 0 && pipe![destination?.index! - 1].orders < 1000) {
        const sourceLead = pipe?.find(item => item.name + item.orders === draggableId);

        pipe?.splice(source.index, 1);
        pipe?.splice(destination?.index!, 0, sourceLead!);
      }
    } else if (destination?.droppableId !== source.droppableId) {
      const sourceLead = sourceStatusData?.find(item => item.id === data?.id)?.pipeStatuses.find(item => String(item.id) === destination?.droppableId);

      if (destination?.droppableId === '-0') {
        setDeleteLead({ visible: true, id: Number(draggableId) });
      }
      if (sourceLead!.type === 'FAILED') {
        setReasonsRefusal({
          id: sourceLead?.id!,
          visible: true,
          leadId: Number(draggableId),
          statusId: Number(destination?.droppableId)
        });
      }

      if (sourceLead!.type === 'SUCCESS') {
        setLeadTaskCompleteModal({
          id: sourceLead?.id!,
          visible: true,
          leadId: Number(draggableId),
          statusId: Number(destination?.droppableId)
        });
      }

      if (sourceLead!.type !== 'FAILED' && sourceLead!.type !== 'SUCCESS') {
        const sourceLeadsData = qc.getQueryData<{
          pageParams: number[];
          pages: CrmLeadListV2[];
        }>([queryNames.CRM_LEAD_FILTER, Number(source.droppableId), searchParams]);

        const destinationLeadsData = qc.getQueryData<{
          pageParams: number[];
          pages: CrmLeadListV2[];
        }>([queryNames.CRM_LEAD_FILTER, Number(destination?.droppableId), searchParams]);

        const sourceLeadsDataCopy = sourceLeadsData?.pages?.map(item => item?.leadList.data).flat(1);
        const destinationLeadsDataCopy = destinationLeadsData?.pages?.map(item => item?.leadList.data).flat(1);

        const sourceLead = sourceLeadsDataCopy?.find(item => item?.id === Number(draggableId));

        destinationLeadsDataCopy?.splice(destination?.index!, 0, sourceLead!);
        qc.setQueryData<any>([queryNames.CRM_LEAD_FILTER, Number(destination?.droppableId), searchParams], (e: any) => ({
          ...destinationLeadsData,
          pages: [
            {
              countLead: e?.pages[0].countLead,
              totalBalance: e?.pages[0].totalBalance,
              leadList: {
                currentPage: destinationLeadsData?.pages[0].leadList.currentPage,
                data: destinationLeadsDataCopy,
                totalElements: destinationLeadsData?.pages[0].leadList.totalElements,
                totalPages: destinationLeadsData?.pages[0].leadList.totalPages
              }
            }
          ]
        }));
        sourceLeadsDataCopy?.splice(source.index, 1);
        await qc.setQueryData<any>([queryNames.CRM_LEAD_FILTER, Number(source?.droppableId), searchParams], (e: any) => ({
          ...sourceLeadsData,
          pages: [
            {
              countLead: e?.pages[0].countLead,
              totalBalance: e?.pages[0].totalBalance,
              leadList: {
                currentPage: sourceLeadsData?.pages[0].leadList.currentPage,
                data: sourceLeadsDataCopy,
                totalElements: sourceLeadsData?.pages[0].leadList.totalElements,
                totalPages: sourceLeadsData?.pages[0].leadList.totalPages
              }
            }
          ]
        }));

        await leadChageStep
          .mutateAsync({
            leadId: Number(draggableId),
            statusId: Number(destination?.droppableId),
            // eslint-disable-next-line no-nested-ternary
            updateTimeDate: destinationLeadsDataCopy![destination?.index! + 1]!
              ? dayjs(destinationLeadsDataCopy![destination?.index! + 1].updatedDate!, 'DD.MM.YYYY HH:mm:ss.SSS')
                  .add(1, 'millisecond')
                  .format('DD.MM.YYYY HH:mm:ss.SSS')
              : destinationLeadsDataCopy![destination?.index! - 1]
                ? dayjs(destinationLeadsDataCopy![destination?.index! - 1].updatedDate!, 'DD.MM.YYYY HH:mm:ss.SSS')
                    .add(-1, 'millisecond')
                    .format('DD.MM.YYYY HH:mm:ss.SSS')
                : sourceLead?.updatedDate!,
            newLead: source.index === 0
          })
          .then(() => {
            qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, Number(destination?.droppableId), searchParams]);
          });
      }
    } else if (!Number.isNaN(Number(draggableId))) {
      const sourceLeadsData = qc.getQueryData<{
        pageParams: number[];
        pages: CrmLeadListV2[];
      }>([queryNames.CRM_LEAD_FILTER, Number(source.droppableId), searchParams]);

      const sourceLeadsDataCopy = sourceLeadsData?.pages?.map(item => item?.leadList.data).flat(1);

      leadChageStep
        .mutateAsync({
          leadId: Number(draggableId),
          statusId: Number(destination?.droppableId),
          updateTimeDate: sourceLeadsDataCopy![destination?.index! + 1]
            ? dayjs(sourceLeadsDataCopy![destination?.index! + 1].updatedDate!, 'DD.MM.YYYY HH:mm:ss.SSS')
                .add(1, 'millisecond')
                .format('DD.MM.YYYY HH:mm:ss.SSS')
            : dayjs(sourceLeadsDataCopy![destination?.index! - 1].updatedDate!, 'DD.MM.YYYY HH:mm:ss.SSS')
                .add(-1, 'millisecond')
                .format('DD.MM.YYYY HH:mm:ss.SSS')
        })
        .then(() => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, Number(destination?.droppableId), searchParams]);
        });
    }
  };

  const onDragStart = (result: DropResult) => {
    const { draggableId } = result;

    setDraggingId(Number(draggableId));
    setDraggableStop(true);
    setDragging(true);
  };

  useEffect(() => {
    const windowScroll = () => {
      setScrollWidth((285.79 / 100) * ((document.body.offsetWidth * 100) / document.body.scrollWidth));
    };

    windowScroll();
    window.addEventListener('resize', windowScroll);
    window.addEventListener('click', windowScroll);

    return () => {
      window.removeEventListener('resize', windowScroll);
      window.removeEventListener('click', windowScroll);
    };
  }, [data]);

  const scrollMouseDown = (e: any) => {
    e.stopPropagation();
    setTransformOrign({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    });
    setScrollDown(true);
    document.body.style.cursor = 'grabbing';
  };

  useEffect(() => {
    const curYPos = 0;
    const curXPos = 0;
    let curDown = false;

    window.onload = () => {
      setScrollWidth((285.79 / 100) * ((document.body.offsetWidth * 100) / document.body.scrollWidth));
    };

    // const windowMouseDown = (e: MouseEvent) => {
    //   if (!draggableStop) {
    //     document.body.style.cursor = 'grabbing'
    //     curDown = true
    //     curYPos = e.pageY
    //     curXPos = e.pageX
    //   }
    // }

    const windowMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      if (!draggableStop) {
        if (curDown) {
          window.scrollBy(document.body.scrollLeft + (curXPos - e.pageX), document.body.scrollTop + (curYPos - e.pageY));
        }
      }

      const scrollWidthCopy = (285.79 / 100) * ((document.body.offsetWidth * 100) / document.body.scrollWidth);

      if (scrollDown) {
        document.body.style.cursor = 'grabbing';
        const left = e.clientX - document.body.clientWidth + 27 + 285.79 - transformOrign.x;
        // eslint-disable-next-line no-nested-ternary
        const leftScroll = left <= 0 ? 0 : left >= 285.79 - scrollWidthCopy ? 285.79 - scrollWidthCopy : left;

        setScrollBy(e.clientX - document.body.clientWidth + 27 + 285.79 - transformOrign.x);

        const scrollProtsent = (leftScroll / 285.79) * 100;
        const bodyScroll = (document.body.scrollWidth / 100) * scrollProtsent;

        window.scrollTo(bodyScroll, 0);
      }
    };

    const windowMouseUp = () => {
      curDown = false;
      setScrollDown(false);
      document.body.style.cursor = 'default';
      setDraggableStop(false);
      setDragging(false);
    };
    const windowMouseLeave = () => {
      curDown = false;
      setScrollDown(false);
      document.body.style.cursor = 'default';
      setDraggableStop(false);
      setDragging(false);
    };

    const windowScroll = () => {
      const scrollProtsent = (window.scrollX / document.body.scrollWidth) * 100;
      const scrollWidthCopy = (285.79 / 100) * scrollProtsent;

      setScrollBy(scrollWidthCopy);
    };

    // dragRef?.current!.addEventListener('mousedown', windowMouseDown)
    window.addEventListener('mousemove', windowMouseMove);
    window.addEventListener('mouseup', windowMouseUp);
    window.addEventListener('mouseleave', windowMouseLeave);
    window.addEventListener('scroll', windowScroll);
    return () => {
      // dragRef?.current!.removeEventListener('mousedown', windowMouseDown)
      window.removeEventListener('mousemove', windowMouseMove);
      window.removeEventListener('mouseup', windowMouseUp);
      window.removeEventListener('mouseleave', windowMouseLeave);
      window.removeEventListener('scroll', windowScroll);
    };

    // eslint-disable-next-line
  }, [scrollDown, draggableStop, location, dragRef]);

  useEffect(() => {
    qc.invalidateQueries([queryNames.CRM_LEAD_FILTER]);
    // eslint-disable-next-line
  }, [leadChageStep.isError]);

  // sort pipe status
  const sortPipeStatus = () => {
    if (data) {
      if (sortableColumn) {
        return data.pipeStatuses;
      }
      if (searchParams.pipeStatusIds) {
        if (Array.isArray(searchParams?.pipeStatusIds)) {
          return data.pipeStatuses.filter(pipe => searchParams.pipeStatusIds?.includes(String(pipe.id)));
        }
        return data.pipeStatuses.filter(pipe => searchParams.pipeStatusIds === String(pipe.id));
      }
      const pipes: PipeStatuses[] = [];

      data.pipeStatuses.forEach(item => {
        if (searchParams?.search) {
          pipes.push(item);
        } else if (item.type !== 'FAILED' && item.type !== 'SUCCESS') {
          pipes.push(item);
        }
      });
      return pipes;
    }
    return [];
  };
  const scrolledIdsAndPositions = useAppSelector(state => state.assigmentSlice.scrolledIdsAndPositions);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data?.pipeStatuses && localStorage.setItem('pipeIds', JSON.stringify(data?.pipeStatuses?.map(item => item?.id)));
  }, [data]);

  useEffect(() => {
    for (let i = 0; i < scrolledIdsAndPositions?.length; i++) {
      const scrollPosition = scrolledIdsAndPositions[i].position;

      if (scrollPosition !== null) {
        const element = document.getElementById(String(scrolledIdsAndPositions[i].id));

        if (element) {
          element.scrollTop = scrollPosition!;
        }
      }
    }
  }, []);
  return (
    <div ref={dragRef}>
      <DragDropContext
        onDragEnd={onDragEnd}
        // @ts-expect-error
        onDragStart={onDragStart}
      >
        <Droppable droppableId="all-droppables" direction="horizontal" type="column" isCombineEnabled>
          {provided => (
            <div className={styles.voronkaCards} {...provided.droppableProps} ref={provided.innerRef}>
              {sortPipeStatus()?.map((column, index) => (
                <VoronkaCardColumn
                  loading={leadChageStep.isLoading}
                  key={column.id}
                  column={column}
                  index={index}
                  pipeData={data as PipeAll}
                  columnSecondId={data?.pipeStatuses[1].id}
                  currentUserRoleData={currentUserRoleData}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <VoronkaCardColumnFooterContent
        data={data}
        dragging={dragging}
        draggingId={draggingId}
        sortableColumn={sortableColumn}
        setDeleteLead={setDeleteLead}
        setReasonsRefusal={setReasonsRefusal}
        setLeadTaskCompleteModal={setLeadTaskCompleteModal}
      />
      <ReasonsRafusalModal visible={reasonsRefusal.visible} setReasonsRefusal={setReasonsRefusal} leadId={reasonsRefusal.leadId!} statusId={reasonsRefusal.statusId!} />
      <LeadTaskCompleteModal {...leadTaskCompleteModal} setLeadTaskCompleteModal={setLeadTaskCompleteModal} />
      <DeleteLeadModal id={deleteLead.id} setDeleteLead={setDeleteLead} visible={deleteLead.visible} />
      <div
        className={styles.voronkaCards_scroll}
        ref={scrollRef}
        style={{
          opacity: dragging ? 0 : 1,
          pointerEvents: dragging ? 'none' : 'inherit'
        }}
      >
        {data?.pipeStatuses.map(item => <span key={item.id} />)}
        <div
          onMouseDown={scrollMouseDown}
          className={styles.voronkaCards_scroll_element}
          style={{
            width: scrollWidth,
            pointerEvents: scrollDown ? 'none' : 'inherit',
            // eslint-disable-next-line no-nested-ternary
            left: scrollBy <= 2 ? 2 : scrollBy >= 285.79 - scrollWidth - 4 ? 285.79 - scrollWidth - 4 : scrollBy
          }}
        />
      </div>
      <Triggers />
    </div>
  );
};

export default VoronkaCards;
