import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';

export const dateFormat = (key: string, date?: { startDate: string; finishDate: string }) => {
  const dateStart = dayjs(new Date()).date(1);
  const dateFinish = dayjs(new Date());

  const dataConvert = {
    DAY: () => {
      const startDate = dateStart.format(DATE_WITH_TIME_FORMAT);
      const finishDate = dayjs(startDate, DATE_WITH_TIME_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_WITH_TIME_FORMAT);
      return { startDate, finishDate };
    },
    MONTH: () => {
      const startDate = dateStart.month(0).format(DATE_WITH_TIME_FORMAT);
      const finishDate = dayjs(startDate, DATE_WITH_TIME_FORMAT).date(0).add(12, 'month').format(DATE_WITH_TIME_FORMAT);
      return { startDate, finishDate };
    },
    YEAR: () => {
      const startDate = dateStart.month(0).add(-4, 'year').format(DATE_WITH_TIME_FORMAT);
      const finishDate = dateFinish.date(31).month(11).format(DATE_WITH_TIME_FORMAT);
      return { startDate, finishDate };
    }
  };

  return dataConvert[key as keyof typeof dataConvert]();
};
