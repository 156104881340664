import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';
import { ITermDiscount } from '../schema';

export const useTermDiscountCreate = (props?: { onSuccess?: () => void }) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<ITermDiscount>) => {
      if (data?.id) {
        const res = await $api.patch('/v1/term-discount', data);

        return res.data.data;
      }
      const res = await $api.post('/v1/term-discount', data);

      return res.data.data;
    },
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.TERM_DISCOUNT]);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onSuccess && props.onSuccess();
    }
  });
};
