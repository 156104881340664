import React, { useEffect, useState } from 'react';
import { OneLid } from '@utils/models/OneLid';
import { Spin } from 'antd';

import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { setCrmOneLid, setNewLid } from '../../../../store/reducers/CrmOneLidSlice';
import { CrmFieldsInfoType } from '../../../../utils/models/CrmFieldsType';

import AddTag from './addTag/AddTag';
import Clients from './clients/Clients';
import ConfigInputs from './configInputs/ConfigInputs';
import Contacts from './contacts/Contacts';
import HeaderSetting from './headerSettings/HeaderSettings';
import Info from './info/Info';
import LeadWithoutTaskConfig from './leadWithoutTaskConfig/LeadWithoutTaskConfig';
import ConfigSaveButtons from './saveButtons/ConfigSaveButtons';
import InformationSaveButton from './saveButtons/InformationSaveButton';
import StepContainer from './step/StepContainer';
import Stats from './tabs/stats/Stats';
import Tabs from './tabs/Tabs';

import styles from './informationContainer.module.scss';

export enum PageNameLidInformation {
  INFORMATION = 'information',
  SETTINGS = 'settings',
  STATS = 'stats'
}

type Props = {
  currentId: number | undefined;
  currentName: string | undefined;
  pipeType?: string;
  data: OneLid;
  isLoading: boolean;
};

const InformationContainer: React.FC<Props> = ({ currentId, currentName, pipeType: type, data, isLoading }) => {
  const [pipeType, setPipeType] = useState<string | undefined>(type);
  const { params } = useQueryParam<any, { id: string }>();

  const [activePage, setActivePage] = useState<PageNameLidInformation>(PageNameLidInformation.INFORMATION);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setPipeType(type);
  }, [type]);

  useEffect(() => {
    if (data) {
      dispatch(setCrmOneLid(data!));
    }
  }, [data]);

  useEffect(() => {
    if (params.id === 'new') {
      dispatch(setNewLid());
    }
  }, [params.id]);

  // handle change page
  const handleChangePage = (page: PageNameLidInformation) => {
    setActivePage(page);
  };

  if (activePage === PageNameLidInformation.INFORMATION) {
    return (
      <div className={`${styles.container} information_container`}>
        {isLoading ? (
          <Spin>
            <AddTag />
            <StepContainer setPipeType={setPipeType} />
            <Tabs onChangePage={handleChangePage} activePage={activePage} />
            <Info currentId={currentId} currentName={currentName} />
            <Contacts />
            <InformationSaveButton pipeType={pipeType} crmOneLid={data!} />
          </Spin>
        ) : (
          <>
            <AddTag />
            <StepContainer setPipeType={setPipeType} />
            <Tabs onChangePage={handleChangePage} activePage={activePage} />
            <Info currentId={currentId} currentName={currentName} />
            <Contacts />
            <Clients data={data} />
            <InformationSaveButton pipeType={pipeType} crmOneLid={data!} />
          </>
        )}
      </div>
    );
  }
  if (activePage === PageNameLidInformation.STATS) {
    return (
      <div className={`${styles.container} information_container`}>
        <>
          <AddTag />
          <StepContainer setPipeType={setPipeType} />
          <Tabs onChangePage={handleChangePage} activePage={activePage} />
          <Stats />
          <InformationSaveButton pipeType={pipeType} crmOneLid={data} />
        </>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <HeaderSetting onChangePage={handleChangePage} />
      <LeadWithoutTaskConfig />
      <ConfigInputs type={CrmFieldsInfoType.LED} />
      <ConfigInputs type={CrmFieldsInfoType.CONTACT} />
      <ConfigSaveButtons />
    </div>
  );
};

export default InformationContainer;
