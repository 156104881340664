import React, { useEffect, useRef, useState } from 'react';
import { Input, InputRef, Layout } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLeftMainHeader from '../../assets/icons/ArrowLeftMainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { setChangeLidName, setChangePipeStatusClear } from '../../store/reducers/CrmOneLidSlice';
import { getInputWidth } from '../../utils/helper/getInputWidth';

import InformationContainer from './components/information/InformationContainer';
import OneLidRestore from './components/one-lid-restore/OneLidRestore';
import TaskListContainer from './components/taskList/TaskListContainer';
import Widgets from './components/widgets/Widgets';
import { useLeadEventData, useOneLid } from './services/queries';

import styles from './oneLid.module.scss';

const { Content } = Layout;

const OneLid = () => {
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef<InputRef | null>(null);
  const { params, searchParams, push } = useQueryParam<{ next: string; noteTaskModal: string; prev: string }, { id: string }>();

  const { data } = useOneLid(Number(params.id));
  const { data: lead, isLoading } = useLeadEventData(Number(params.id));
  const { t } = useTranslation();

  const name = useAppSelector(state => state.crmOneLidField.name);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.id === 'new') {
      // @ts-ignore
      inputRef.current!.focus({
        cursor: 'start'
      });
    }

    return () => {
      dispatch(setChangePipeStatusClear());
    };
  }, [params.id]);

  const handleNavigate = () => {
    if (searchParams.next === 'task') {
      push(-1);
    } else if (searchParams?.prev) {
      window?.history.back();
    } else {
      push(-1);
    }
  };

  const handleChangeValue = (value: string) => {
    setInputWidth(
      getInputWidth({
        value,
        className: styles.container_header_input
      })
    );
    dispatch(setChangeLidName(value));
  };

  const decodedName = name;

  return (
    <>
      <MainHeader>
        <div className={styles.container_header}>
          <ArrowLeftMainHeader onClick={handleNavigate} className={styles.container_header_icon} />
          <Input
            ref={ref => {
              inputRef.current = ref;
              setInputWidth(
                getInputWidth({
                  value: ref?.input?.value || '',
                  className: styles.container_header_input
                })
              );
            }}
            onChange={e => handleChangeValue(e.target.value)}
            value={decodedName}
            style={{ width: inputWidth }}
            placeholder={`${t('crm.Nomi')}...`}
            className={styles.container_header_input}
            bordered={false}
          />
          {data?.deletedDate && <OneLidRestore id={Number(params.id)} />}
        </div>
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.container}>
          <InformationContainer currentId={data?.responsibleById} currentName={data?.responsibleBy} pipeType={data?.pipeStatusResponse?.type} data={data!} isLoading={isLoading} />
          <TaskListContainer leadData={data!} data={lead} isLoading={isLoading} />
          <Widgets data={data} />
        </div>
      </Content>
    </>
  );
};

export default OneLid;
