import { useState } from 'react';

import styles from './companyNotification.module.scss';
import PlusIcon from '@icons/PlusIcon';
import { useGetNotifConfig } from './services/queries';
import NotifItem from './components/notif-item/NotifItem';
import { useTranslation } from 'react-i18next';

const CompanyNotification = () => {
  const { data } = useGetNotifConfig();
  const [activeForm, setActiveForm] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.container_top}>{data?.length! > 0 ? data?.map(item => <NotifItem item={item} />) : <NotifItem />}</div>

      <div className={styles.container_bottom} onClick={() => setActiveForm(prev => !prev)}>
        <PlusIcon />
        <span>{t('crm.Yangi_yaratish')}</span>
      </div>
    </div>
  );
};

export default CompanyNotification;
