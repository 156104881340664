import { useState } from 'react';
import { Card, Empty, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useGetAnalyticRatio } from '../../services/queries';
import { createArrayOfMonths } from '../../utils/helpers/customArrayOfMonths';
import { DateRange } from '../../utils/models/DateRange';
import FilteringYearPicker from '../filteringYearPicker/FilteringYearPicker';

import ConversionBar from './components/conversionBar/ConversionBar';

import styles from './conversion.module.scss';

const Conversion = () => {
  const { t } = useTranslation();
  const [conversionLineChartDateRange, setConverionLineChartDateRange] = useState<DateRange>({
    startDate: dayjs(new Date()).format('YYYY'),
    endDate: dayjs(new Date()).format('YYYY')
  });

  const { data: conversionData, isFetching } = useGetAnalyticRatio(conversionLineChartDateRange.startDate, conversionLineChartDateRange.endDate);
  const arrayOfMonths = createArrayOfMonths(conversionLineChartDateRange.startDate);

  return (
    <Card
      title={t('bi_analytics.Konversiya')}
      className={styles.conversion_card}
      bodyStyle={{ padding: 0 }}
      extra={<FilteringYearPicker setChartsFilteringStartDateAndEndDate={setConverionLineChartDateRange} />}
    >
      <Spin spinning={isFetching}>
        <div className={styles.conversion_card_container}>
          {conversionData && conversionData?.currentCompany.length > 0 && conversionData?.otherCompany.length > 0 ? (
            <ConversionBar conversionData={conversionData!} arrayOfMonths={arrayOfMonths} />
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </Card>
  );
};

export default Conversion;
