import TableConfigDrawer from '../../../../components/filters/TableConfigDrawer';
import PaginationCont from '../../../../components/pagenation/PaginationCont';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useTableConfig } from '../../../../queries/queries';
import { openTableSettings } from '../../../../store/reducers/CrmSlice';
import { TableKey } from '../../../../utils/models/TableKey';
import { useLeadList, useLeadListIds } from '../../services/queries';
import { SearchParamsCrm } from '../filter/FilterButton';

import Actions from './actions/Actions';
import TableListView from './table/TableListView';

import styles from './listView.module.scss';

const ListView = () => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsCrm, any>();

  const { data, isLoading, isFetching } = useLeadList(Number(searchParams.voronka), { ...searchParams });
  const { data: allIds } = useLeadListIds(Number(searchParams.voronka), {
    ...searchParams
  });

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.crm);

  const dispatch = useAppDispatch();
  const visibleDrawer = useAppSelector(state => state.assigmentSlice.isOpenTableSettings);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setClearListView());
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // handle close drawer
  const handleCloseDrawer = (isOpen: boolean) => {
    dispatch(openTableSettings(isOpen));
  };

  // handle change page
  const handleChangePage = (page: number) => {
    if (tableConfig?.size) {
      appendMultipleDifferent(['page', 'size'], [page, tableConfig.size]);
    } else {
      appendMultipleDifferent(['page', 'size'], [page, 10]);
    }
  };

  // handle success save table options
  const handleSuccessSaveTableOptions = (size: number) => {
    appendMultipleDifferent(['page', 'size'], [1, size]);
  };

  return (
    <div className={styles.container}>
      <Actions />
      <TableConfigDrawer tableKey={TableKey.crm} visible={visibleDrawer} onClose={handleCloseDrawer} onSuccess={handleSuccessSaveTableOptions} />
      <div className={styles.container_body}>
        <TableListView
          totalBalance={data?.totalBalance || 0}
          allIds={allIds?.leadIds || []}
          data={data?.leadList.data || []}
          isLoading={isLoading}
          isFetching={isFetching}
          tableConfigFetch={tableConfigFetch}
          tableConfig={tableConfig?.config || []}
        />
        <PaginationCont
          defaultPageSize={tableConfig?.size || 10}
          currentPage={data?.leadList.currentPage || 1}
          total={data?.leadList.totalElements || 0}
          totalPages={data?.leadList.totalPages || 1}
          changePage={handleChangePage}
          allIds={allIds?.leadIds || []}
          type="crm"
        />
      </div>
    </div>
  );
};

export default ListView;
