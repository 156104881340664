import React, { useState } from 'react';
import styles from './addChatItem.module.scss';
import { Form, Input, Spin, Switch } from 'antd';

import AddCircleIcon from '../../../../../assets/icons/AddCircleIcon';
import TickSquareIcon from '../../../../../assets/icons/TickSquareIcon';
import { BotChat } from '../../../../../utils/models/BotChat';
import { useEditBotChats } from '../../../../../queries/mutation';
import { useTranslation } from 'react-i18next';

const AddChatItem = () => {
  const [form] = Form.useForm<BotChat>();
  const [showAddButton, setShowAddButton] = useState(true);

  const createChat = useEditBotChats(onSuccess);

  const { t } = useTranslation();

  function onSuccess() {
    setShowAddButton(true);
    form.resetFields();
  }

  const handleShowAddButton = () => {
    setShowAddButton(!showAddButton);
  };

  const handelSubmit = async () => {
    await form.validateFields();
    createChat.mutate(form.getFieldsValue());
  };

  return (
    <Spin spinning={createChat.isLoading}>
      {showAddButton && (
        <div onClick={handleShowAddButton} className={styles.add}>
          <AddCircleIcon />
        </div>
      )}
      {!showAddButton && (
        <Form form={form} name={'faqItemCreate'}>
          <div className={styles.container}>
            <div className={styles.container_input}>
              <p className={styles.container_input_label}>{t('integration.Nomi')}</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
                name={'title'}
              >
                <Input className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.container_input}>
              <p className={styles.container_input_label}>{t('integration.Url_manzili')}</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
                name={'url'}
              >
                <Input className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.container_input}>
              <p className={styles.container_input_label} />
              <Form.Item valuePropName={'checked'} name={'status'}>
                <Switch />
              </Form.Item>
            </div>
            <div onClick={handelSubmit} className={styles.container_button}>
              <TickSquareIcon />
            </div>
          </div>
        </Form>
      )}
    </Spin>
  );
};

export default AddChatItem;
