/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import WarningIcon1 from '@icons/WarningIcon1';
import { SearchParamsEmployee } from '@pages/settingEmployee/SettingEmployee';
import { useEmployeeEdit } from '@queries/mutation';
import { BASE_URL } from '@service/RequestService';
import { Form, FormInstance, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { FormI } from '../SettingEmployeeModal';

import { useMutateAssignInfo } from './services/mutation';
import { queryKeys } from './utils/constants/queryKeys';
import { GetAssignInfoModel } from './utils/models/GetAssignInfoModel';
import { LeadEmployeeModel } from './utils/models/LeadEmployeeModel';

interface IProps {
  setOpenBlock: React.Dispatch<React.SetStateAction<boolean>>;
  openBlock: boolean;
  id: number | null;
  data: GetAssignInfoModel | undefined;
  employee: LeadEmployeeModel[] | undefined;
  formAssign: FormInstance<FormI>;
  handleCancel(): void;
  fileId: number;
}

const { Option } = Select;

const labels = {
  lidCount: 'Lidlari',
  taskCount: 'Topshiriqlari',
  contract: 'Masul shartnomalari',
  triggerConditionCount: 'A_Trigerlar shartlarida',
  triggerResultCount: 'A_Trigerlar natijasida'
};

const BlockEmployee: React.FC<IProps> = ({ setOpenBlock, openBlock, id, data, employee, formAssign, handleCancel, fileId }) => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsEmployee, any>();
  const [form] = Form.useForm();
  const qc = useQueryClient();
  const employeeEditMutation = useEmployeeEdit(id as number, handleCancel, searchParams);
  const employeeEditStatus = useMutateAssignInfo(searchParams);

  const assignInfo = data && Object.keys(data!)?.map(item => ({ label: item, value: data![item as keyof typeof data] }));

  function filterByLabels(arr: { label: string; value: number }[], labels: string[], type: boolean) {
    if (type) {
      return arr?.filter(item => !labels?.includes(item?.label));
    }
    return arr?.filter(item => labels.includes(item.label));
  }

  const sipUsers = filterByLabels(assignInfo!, ['pbx', 'sipuni', 'moz'], false);
  const defUsers = filterByLabels(assignInfo!, ['pbx', 'sipuni', 'moz'], true);

  const sipCount = sipUsers?.reduce((a, b) => a + b.value, 0);

  useEffect(() => {
    if (openBlock) {
      qc.fetchQuery({ queryKey: [queryKeys.LEAD_EMPLOYEE, id] });
    }
  }, [openBlock]);

  const handleClose = () => {
    setOpenBlock(false);
    form.resetFields();
  };

  const onFinish = (val: any) => {
    const sendData = {
      fullName: formAssign.getFieldsValue().fullName?.trim(),
      username: formAssign.getFieldsValue().username?.trim(),
      ...(formAssign.getFieldsValue()?.password && {
        password: formAssign.getFieldsValue().password !== '' ? formAssign.getFieldsValue().password : undefined
      }),
      districtId: formAssign.getFieldsValue().districtId,
      address: formAssign.getFieldsValue().address,
      roleId: formAssign.getFieldsValue().roleId,
      status: formAssign.getFieldsValue().status,
      avatarId: fileId || null,
      expiry: formAssign.getFieldsValue().expiry,
      branchId: formAssign.getFieldsValue().branchId ? formAssign.getFieldsValue().branchId : null,
      phone: formAssign.getFieldsValue().phone,
      phoneCode: formAssign.getFieldsValue().phoneCode,
      roleBranches:
        formAssign.getFieldsValue().roleBranches?.length > 0
          ? formAssign.getFieldsValue().roleBranches?.map(item => ({ ...item, branchId: item?.branchId === -1 ? null : item?.branchId }))
          : null
    };

    employeeEditStatus
      .mutateAsync({
        employeeId: id!,
        contractEmployee: val?.contract,
        leadEmployee: val?.lidCount,
        mozEmployee: val?.sip_users,
        pbxEmployee: val?.sip_users,
        sipuniEmployee: val?.sip_users,
        taskEmployee: val?.taskCount,
        triggerCondition: val?.triggerConditionCount,
        triggerResult: val?.triggerResultCount
      })
      .then(() => {
        employeeEditMutation.mutateAsync(sendData).then(handleClose);
      });
  };

  const onOk = () => {
    form.submit();
  };

  return (
    <Modal
      visible={openBlock}
      width={614}
      onCancel={handleClose}
      onOk={onOk}
      okText={t('home.Saqlash')}
      cancelText={t('home.Yopish')}
      okButtonProps={{ style: { borderRadius: 50, height: 44, width: 179 }, loading: employeeEditStatus.isLoading }}
      cancelButtonProps={{ style: { borderRadius: 50, height: 44, width: 179 } }}
      className="customModal1"
    >
      <div className="flex flex-col items-center">
        <WarningIcon1 />
        <div className="mb-5 flex flex-col items-center">
          <h4 className="text-lg text-[#101828]">{t('home.xodim_jarayon')}</h4>
          <span className="text-[#475467]">{t('home.biriktiring')}</span>
        </div>
      </div>
      <Form onFinish={onFinish} form={form} className="flex flex-col gap-3">
        {defUsers?.map(item => {
          if (item?.value > 0) {
            return (
              <div key={item?.label} className="flex cursor-pointer items-center gap-4 rounded-xl bg-[#F9FAFB] px-3 py-2">
                <div className="flex w-[308px] items-center justify-between">
                  <span className="text-[#344054]">{t(`home.${labels[item?.label as keyof typeof labels]}`)}</span>
                  <span className="text-[#98A2B3]">
                    {item?.value} {t('home.ta')}
                  </span>
                </div>
                <Form.Item className="customSelectEmployee !m-0 w-[219px]" rules={[{ required: true, message: '' }]} name={item?.label}>
                  <Select placeholder={t('triggers.Masul_xodim')}>
                    {employee?.map(item => (
                      <Option key={item?.id} value={item?.id}>
                        <div className="mt-1 flex items-center gap-3">
                          {item?.avatar ? (
                            <img src={`${BASE_URL}/${item?.avatar}`} alt="img" className="h-7 w-7 rounded-full" />
                          ) : (
                            <div className="flex h-7 w-7 items-center justify-center rounded-full bg-red-500  text-white">{item?.fullName?.substring(0, 1)}</div>
                          )}
                          {item?.fullName}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            );
          }
        })}
        {sipCount !== 0 && (
          <div className="flex cursor-pointer items-center gap-4 rounded-xl bg-[#F9FAFB] px-3 py-2">
            <div className="flex w-[308px] items-center justify-between">
              <span>{t('home.SIP_userlarda')}</span>
              <span className="text-[#98A2B3]">
                {sipCount} {t('home.ta')}
              </span>
            </div>
            <Form.Item className="customSelectEmployee !m-0 w-[219px]" rules={[{ required: true, message: '' }]} name="sip_users">
              <Select placeholder={t('triggers.Masul_xodim')}>
                {employee?.map(item => (
                  <Option key={item?.id} value={item?.id}>
                    <div className="mt-1 flex items-center gap-3">
                      {item?.avatar ? (
                        <img src={`${BASE_URL}/${item?.avatar}`} alt="img" className="h-7 w-7 rounded-full" />
                      ) : (
                        <div className="flex h-7 w-7 items-center justify-center rounded-full bg-red-500  text-white">{item?.fullName?.substring(0, 1)}</div>
                      )}
                      {item?.fullName}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default BlockEmployee;
