import React from 'react';

const CardRemoveBgIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.2005 20.04C22.2539 20.04 19.8672 22.4267 19.8672 25.3734C19.8672 28.32 22.2539 30.7067 25.2005 30.7067C28.1472 30.7067 30.5339 28.32 30.5339 25.3734C30.5339 22.4267 28.1472 20.04 25.2005 20.04ZM27.3338 27.5734C27.1338 27.7734 26.8804 27.8667 26.6271 27.8667C26.3738 27.8667 26.1205 27.7734 25.9205 27.5734L25.2138 26.8667L24.4805 27.6C24.2805 27.8 24.0272 27.8934 23.7738 27.8934C23.5205 27.8934 23.2671 27.8 23.0671 27.6C22.6805 27.2134 22.6805 26.5734 23.0671 26.1867L23.8005 25.4534L23.0938 24.7467C22.7072 24.36 22.7072 23.72 23.0938 23.3334C23.4805 22.9467 24.1205 22.9467 24.5072 23.3334L25.2138 24.04L25.8805 23.3734C26.2671 22.9867 26.9071 22.9867 27.2938 23.3734C27.6804 23.76 27.6804 24.4 27.2938 24.7867L26.6271 25.4534L27.3338 26.16C27.7205 26.5467 27.7205 27.1867 27.3338 27.5734Z"
        fill="url(#paint0_linear_11978_58786)"
      />
      <path
        d="M29.3327 10.0534C29.3327 11.1286 28.4611 12.0001 27.386 12.0001H4.61268C3.53757 12.0001 2.66602 11.1286 2.66602 10.0534C2.66602 7.00011 5.14605 4.53345 8.19938 4.53345H23.7993C26.8526 4.53345 29.3327 7.00011 29.3327 10.0534Z"
        fill="url(#paint1_linear_11978_58786)"
      />
      <g filter="url(#filter0_b_11978_58786)">
        <path
          d="M2.66602 12V21.9467C2.66602 25 5.1327 27.4667 8.18603 27.4667H16.5327C17.306 27.4667 17.9727 26.8133 17.906 26.04C17.7193 24 18.3727 21.7867 20.186 20.0267C20.9327 19.2933 21.8527 18.7333 22.8527 18.4133C24.5194 17.88 26.1327 17.9467 27.5593 18.4267C28.426 18.72 29.3327 18.0933 29.3327 17.1733V12H2.66602ZM10.666 23H7.99935C7.45268 23 6.99935 22.5467 6.99935 22C6.99935 21.4533 7.45268 21 7.99935 21H10.666C11.2127 21 11.666 21.4533 11.666 22C11.666 22.5467 11.2127 23 10.666 23Z"
          fill="#FF749F"
          fillOpacity="0.35"
        />
        <path
          d="M2.66602 11.5C2.38987 11.5 2.16602 11.7239 2.16602 12V21.9467C2.16602 25.2761 4.85656 27.9667 8.18603 27.9667H16.5327C17.5658 27.9667 18.4988 27.0946 18.4042 25.9971L18.4039 25.9944C18.2302 24.096 18.8357 22.0341 20.5343 20.3855L20.5364 20.3834C21.2282 19.704 22.0806 19.1854 23.0051 18.8895C24.5668 18.3898 26.0707 18.4534 27.3999 18.9006C28.5791 19.2991 29.8327 18.4472 29.8327 17.1733V12C29.8327 11.7239 29.6088 11.5 29.3327 11.5H2.66602ZM10.666 22.5H7.99935C7.72882 22.5 7.49935 22.2705 7.49935 22C7.49935 21.7295 7.72882 21.5 7.99935 21.5H10.666C10.9365 21.5 11.166 21.7295 11.166 22C11.166 22.2705 10.9365 22.5 10.666 22.5Z"
          stroke="url(#paint2_linear_11978_58786)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_bd_11978_58786)">
        <path
          d="M11.6667 22C11.6667 22.5467 11.2133 23 10.6667 23H8C7.45333 23 7 22.5467 7 22C7 21.4533 7.45333 21 8 21H10.6667C11.2133 21 11.6667 21.4533 11.6667 22Z"
          fill="url(#paint3_linear_11978_58786)"
          shapeRendering="crispEdges"
        />
        <path
          d="M11.4667 22C11.4667 22.4362 11.1029 22.8 10.6667 22.8H8C7.56379 22.8 7.2 22.4362 7.2 22C7.2 21.5638 7.56379 21.2 8 21.2H10.6667C11.1029 21.2 11.4667 21.5638 11.4667 22Z"
          stroke="url(#paint4_linear_11978_58786)"
          stroke-width="0.4"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_11978_58786"
          x="-21.334"
          y="-12"
          width="74.666"
          height="63.4666"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11978_58786" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11978_58786" result="shape" />
        </filter>
        <filter id="filter1_bd_11978_58786" x="-8" y="6" width="34.666" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11978_58786" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.395833 0 0 0 0 0.395833 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_11978_58786" result="effect2_dropShadow_11978_58786" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_11978_58786" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_11978_58786" x1="25.9858" y1="26.0877" x2="16.6131" y2="30.6449" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF759F" />
          <stop offset="1" stopColor="#FF196E" />
        </linearGradient>
        <linearGradient id="paint1_linear_11978_58786" x1="17.9625" y1="8.76678" x2="10.7476" y2="21.2956" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF759F" />
          <stop offset="1" stopColor="#FF196E" />
        </linearGradient>
        <linearGradient id="paint2_linear_11978_58786" x1="6.91091" y1="13.8019" x2="17.4847" y2="33.3274" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_11978_58786" x1="11.3358" y1="21.3612" x2="6.162" y2="21.7548" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint4_linear_11978_58786" x1="7.74286" y1="21.233" x2="8.54948" y2="23.5641" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CardRemoveBgIcon;
