import React, { FC } from 'react';

interface IProps {
  size?: number;
}

const AvatarIcon: FC<IProps> = ({ size = 40 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8082_37433)">
        <rect width="40" height="40" rx="20" fill="white" />
        <rect x="12" y="6" width="16" height="16" rx="8" fill="#B0B0B0" />
        <rect x="-10" y="27" width="60" height="60" rx="30" fill="#B0B0B0" />
      </g>
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#B0B0B0" />
      <defs>
        <clipPath id="clip0_8082_37433">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvatarIcon;
