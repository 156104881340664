import React from 'react';
import { CrmField } from '../../../../../utils/models/CrmField';
import { Select } from 'antd';
import styles from './filterDrawer.module.scss';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { urlDecode, urlEncode } from '../../../../../utils/helper/urlEncodeDecode';
import { textCapitalize } from '@utils/helper/textConverter';
import i18n from '@service/I18nService';

interface Props {
  data: CrmField;
  fields?: string[] | string;
}

const SelectField: React.FC<Props> = ({ data, fields }) => {
  const { remove, appendMultiple } = useQueryParam();

  // changeText
  const changeText = (e: string) => {
    if (Array.isArray(fields)) {
      fields.forEach(item => {
        if (item.split('_')[0] === String(data.id)) {
          remove('fields', item);
        }
      });
    } else {
      if (fields?.split('_')[0] === String(data.id)) {
        remove('fields', fields);
      }
    }
    if (e) {
      appendMultiple('fields', urlEncode(`${data.id}_${e}`));
    }
  };

  // handle show value
  // changeText
  const handleShow = () => {
    let value = null;
    if (Array.isArray(fields)) {
      fields.forEach(item => {
        if (item.split('_')[0] === String(data.id)) {
          value = item.split('_')[1];
        }
      });
    } else {
      if (fields?.split('_')[0] === String(data.id)) {
        value = fields?.split('_')[1];
      }
    }
    return urlDecode(value || '') || null;
  };

  return (
    <div className={styles.container_margin_top}>
      <Select
        style={{ width: '100%' }}
        value={handleShow()}
        allowClear
        placeholder={data[`name${textCapitalize(i18n.language)}` as keyof typeof data] || data?.name}
        className={'customSelect'}
        onChange={changeText}
      >
        {data.options?.map((option, index) => (
          <Select.Option key={index} value={option.name}>
            {option[`name${textCapitalize(i18n.language)}` as keyof typeof option] || option?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectField;
