const SendSquare = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 9.51001L12 6.51001L15 9.51001" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 6.51001V14.51" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 16.51C9.89 17.81 14.11 17.81 18 16.51" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SendSquare;
