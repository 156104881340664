import { Space, Spin, Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './home_information.module.scss';
import { useFlatId } from '../../../queries/queries';
import { BASE_URL } from '../../../service/RequestService';
import { GalleryPosition } from '../../../utils/models/Gallery';

interface Props {
  id: string;
}

const HouseInformation: React.FC<Props> = ({ id }) => {
  const { data, isLoading } = useFlatId(id);
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <div className={styles.home_id}>
        <h1>{t('home_id.Xonadon_malumotlari')}</h1>
        <div className={styles.home_id_content}>
          <div>
            {data?.gallery && (
              <Image.PreviewGroup>
                <Space direction="horizontal">
                  <Space direction="vertical">
                    {data?.gallery.map((item, index) => <Image key={index} width={64} src={BASE_URL + '/' + item.files?.absolutePath} />)}
                  </Space>
                  <Image width={332} src={BASE_URL + '/' + data?.gallery.find(i => i.position === GalleryPosition.FLAT_PLAN)?.files?.absolutePath} />
                </Space>
              </Image.PreviewGroup>
            )}
          </div>
          <div>
            <h1>{data?.apartment ? t('home_id.Turarjoy') : t('home_id.Noturar_joy')}</h1>
            <div>
              <div>
                <h1>{t('contract_id.Bino')}</h1>
                <p>{data?.building.name}</p>
              </div>
              <div>
                <h1>{t('contract_id.Qavat')}</h1>
                <p>{data?.floor}</p>
              </div>
              <div>
                <h1>{t('home.Xonadon_raqami')}</h1>
                <p>{data?.number}</p>
              </div>
              <div>
                <h1>{t('home.Xonalar_soni')}</h1>
                <p>{data?.rooms}</p>
              </div>
              <div>
                <h1>{t('home.Maydoni')}</h1>
                <p>
                  {data?.area} {t('create_table_payments.m2')}
                </p>
              </div>
              <div>
                <h1>{t('contract_id.Topshirish_holati')}</h1>
                <p>{data?.repaired ? t('home.Tamirli') : t('home.Tamirsiz')}</p>
              </div>
            </div>
          </div>
          <div>
            <>
              <div>
                <h1>{t('contract_id.Tolov_turi')}</h1>
                <div>
                  <div>
                    <h1>{t('home_id.Boshlangich_tolov_narxi')}</h1>
                    <p>
                      {data?.prepayment?.toLocaleString()} {data?.currency?.ccy}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h1>{t('home.Tamirli')}</h1>
                <div>
                  <div>
                    <h1>{t('home.1_m_kv_uchun_narxlar')}</h1>
                    <p>
                      {data?.pricePerAreaRepaired?.toLocaleString()} {data?.currency?.ccy}
                    </p>
                  </div>
                  <div>
                    <h1>{t('home_id.Umumiy_narxi')}</h1>
                    <p>
                      {data?.priceRepaired?.toLocaleString()} {data?.currency?.ccy}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h1>{t('home.Tamirsiz')}</h1>
                <div>
                  <div>
                    <h1>{t('home.1_m_kv_uchun_narxlar')}</h1>
                    <p>
                      {data?.pricePerArea?.toLocaleString()} {data?.currency?.ccy}
                    </p>
                  </div>
                  <div>
                    <h1>{t('home_id.Umumiy_narxi')}</h1>
                    <p>
                      {data?.priceNotRepaired?.toLocaleString()} {data?.currency?.ccy}
                    </p>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default HouseInformation;
