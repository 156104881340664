import React from 'react';

const InfoIcon = (svg: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...svg} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06683 4.86671C9.06683 4.40449 8.66238 4.00004 8.20016 4.00004C7.73794 4.00004 7.3335 4.40449 7.3335 4.86671C7.3335 5.32893 7.68016 5.73337 8.20016 5.73337C8.72016 5.73337 9.06683 5.32893 9.06683 4.86671ZM7.22238 11.3334H9.44461C9.77794 11.3334 10.0002 11.1761 10.0002 10.8615C10.0002 10.5469 9.77794 10.3371 9.44461 10.3371H9.00016C8.94461 10.3371 8.88905 10.2847 8.88905 10.2323V7.7154C8.88905 7.13862 8.38905 6.66671 7.77794 6.66671H7.22238C6.88905 6.66671 6.66683 6.87644 6.66683 7.19105C6.66683 7.50566 6.88905 7.7154 7.22238 7.7154H7.66683C7.72238 7.7154 7.77794 7.76783 7.77794 7.82026V10.1798C7.77794 10.2323 7.72238 10.2847 7.66683 10.2847H7.22238C6.88905 10.2847 6.66683 10.4944 6.66683 10.809C6.66683 11.1236 6.88905 11.3334 7.22238 11.3334ZM8.00016 1.33337C4.3335 1.33337 1.3335 4.33337 1.3335 8.00004C1.3335 11.6667 4.3335 14.6667 8.00016 14.6667C11.6668 14.6667 14.6668 11.6667 14.6668 8.00004C14.6668 4.33337 11.6668 1.33337 8.00016 1.33337Z"
        fill="#0172CB"
      />
    </svg>
  );
};

export default InfoIcon;
