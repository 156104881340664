import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const MenuThreeDotHorizontalIcon: React.FC<Props> = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66667 8.4987C4.66667 7.76232 4.06971 7.16536 3.33333 7.16536C2.59695 7.16536 2 7.76232 2 8.4987C2 9.23508 2.59695 9.83203 3.33333 9.83203C4.06971 9.83203 4.66667 9.23508 4.66667 8.4987Z"
      fill="#464255"
    />
    <path
      d="M9.33268 8.4987C9.33268 7.76232 8.73573 7.16536 7.99935 7.16536C7.26297 7.16536 6.66602 7.76232 6.66602 8.4987C6.66602 9.23508 7.26297 9.83203 7.99935 9.83203C8.73573 9.83203 9.33268 9.23508 9.33268 8.4987Z"
      fill="#464255"
    />
    <path
      d="M14.0007 8.4987C14.0007 7.76232 13.4037 7.16536 12.6673 7.16536C11.9309 7.16536 11.334 7.76232 11.334 8.4987C11.334 9.23508 11.9309 9.83203 12.6673 9.83203C13.4037 9.83203 14.0007 9.23508 14.0007 8.4987Z"
      fill="#464255"
    />
  </svg>
);

export default MenuThreeDotHorizontalIcon;
