import React, { FC } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import styles from './modals.module.scss';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { SearchParamsCrm } from '../../../filter/FilterButton';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../../../../../assets/icons/InfoIcon';
import { useGroupActionCrmViewList } from '../../../../services/mutation';
interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
}

const SendChatMessage: FC<IProps> = ({ visible, close, ids }) => {
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const changeEmployee = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);

  // handle success
  function handleSuccess() {
    handleCancel();
    form.resetFields();
  }

  // handle cancel modal
  const handleCancel = () => {
    close(false);
  };

  // on finish
  const onFinish = (val: { text: string }) => {
    changeEmployee.mutate({
      text: val.text,
      requestMethodType: RequestMethodTypeGroupActions.SEND_MESSAGE_USERS,
      leadIds: ids
    });
  };

  return (
    <Modal
      className={styles.container}
      width={560}
      footer={false}
      visible={visible}
      title={t('list_view.Chat_xabarini_yuborish')}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish} form={form} name={'changeEmployeeListViewCrm'}>
        <Form.Item label={''} name={'text'} rules={[{ required: true, message: '' }]}>
          <Input.TextArea placeholder={t('client.Xabar_matnini_kiriting')} autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <div className={`${styles.container_sub_information} d-flex align-center`}>
          <InfoIcon />
          <span>{t('list_view.Barcha_kantakt_mavjud')}</span>
        </div>
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button loading={changeEmployee.isLoading} htmlType={'submit'} type="primary">
              {t('home.Yuborish')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default SendChatMessage;
