import Edit2Icon from '@icons/Edit2Icon';
import TrushSquareBlackStrokeIcon from '@icons/TrushSquareBlackStrokeIcon';

import { useAction, useContractVariationGroupId } from 'modules/multi-payment';

import PaymentOfferItem from './PaymentOfferItem';

const PaymentOffer = () => {
  const { query, setAction } = useAction();
  const { data } = useContractVariationGroupId({ id: query?.groupId });

  return (
    <div className="flex flex-col gap-[24px] px-[28px] py-[16px]">
      <div className="flex items-center justify-between">
        <h1 className="flex items-center gap-[12px] text-[24px] font-[600] text-[#464255]">
          {query?.name}{' '}
          <Edit2Icon
            className="h-4 w-4 cursor-pointer"
            onClick={() =>
              setAction('PAYMENT_TYPE_UPDATE', {
                name: String(query?.name),
                groupId: query?.groupId
              })
            }
          />{' '}
          <TrushSquareBlackStrokeIcon className="h-4 w-4 cursor-pointer" onClick={() => setAction('PAYMENT_TYPE_DELETE', query?.groupId)} />
        </h1>
      </div>
      <div className="flex flex-col gap-[24px]">
        {data?.parts?.map(item => <PaymentOfferItem key={item?.id} item={item} />)}
        <PaymentOfferItem created />
      </div>
    </div>
  );
};

export default PaymentOffer;
