import React, { useEffect, useRef, useState } from 'react';

import styles from './pinedNote.module.scss';
import ArchiveBookIcon from '../../../../../assets/icons/ArchiveBookIcon';
import CloseCrmIcon from '../../../../../assets/icons/CloseCrmIcon';
import { LEAD_DATA_TYPE, LeadEventsAndTasksList, OneEvent } from '../../../../../utils/models/LeadEventsAndTaskList';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useCreateClientNote, useCreateLeadNote } from '../../../../../queries/mutation';

type Props = {
  type?: string | undefined;
  data: LeadEventsAndTasksList[] | undefined;
  isLoading: boolean;
};

const PinedNote: React.FC<Props> = ({ data, type }) => {
  const { params } = useQueryParam<any, { id: string }>();

  const [notes, setNotes] = useState<OneEvent[]>([]);

  const noteSave = useCreateLeadNote(Number(params.id));
  const clientNoteSave = useCreateClientNote(Number(params?.idClient));

  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data) {
      const list: OneEvent[] = [];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].events.length; j++) {
          if (
            (data[i].events[j].leadDataType === LEAD_DATA_TYPE.LEAD_NOTE || data[i].events[j].eventType === 'EVENT_CLIENT') &&
            data[i].events[j].res.pin
          ) {
            list.push(data[i].events[j].res);
          }
        }
      }
      setNotes(list);
    }
  }, [data]);

  useEffect(() => {
    if (notes.length > 0)
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
  }, [notes]);

  // pin note
  const deletePinNote = (event: OneEvent) => {
    const fileIds: number[] = [];
    if (Array.isArray(event.files) && event.files.length > 0) {
      for (let i = 0; i < event.files.length; i++) {
        fileIds.push(event.files[i].id);
      }
    }
    type
      ? clientNoteSave.mutate({
          id: event.id,
          pin: false,
          text: event.text,
          clientId: Number(params?.idClient),
          fileIds
        })
      : noteSave.mutate({
          id: event.id,
          pin: false,
          fileIds,
          text: event.text,
          leadId: event.leadId
        });
  };

  // scroll to current element
  const scrollToCurrentElement = (id: number) => {
    const myElement = document.getElementById(`event-${id}`);
    if (myElement) {
      myElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  if (notes.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {notes.map((note, index) => (
        <div onClick={() => scrollToCurrentElement(note.id)} key={index} className={styles.container_item}>
          <p className={styles.left}>
            <ArchiveBookIcon className={styles.container_item_icon} />
            <span className={styles.container_item_date_employee}>
              {note.createdDate || note?.date} {note.createdBy || note?.employee}
            </span>
            <span className={styles.container_item_note}>{note.text}</span>
          </p>
          <div>
            <CloseCrmIcon onClick={() => deletePinNote(note)} className={styles.close} />
          </div>
        </div>
      ))}
      <div ref={bottomRef} />
    </div>
  );
};

export default PinedNote;
