import React from 'react';

import styles from './oneTask.module.scss';
import MainInformation from '../../components/oneTask/MainInformation';
import TasksListContainer from '../../components/oneTask/TasksList/TasksListContainer';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import { Layout } from 'antd';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';

const { Content } = Layout;

const OneTask = () => {
  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.container}>
          <MainInformation />
          <TasksListContainer />
        </div>
      </Content>
    </>
  );
};

export default OneTask;
