import React, { useEffect, useRef, useState } from 'react';
import styles from './TaskContainer.module.scss';
import DndTaskContainer from './dnd-task-container/DndTaskContainer';
import DndDeleteCompleteTask from './dnd-task-container/DndDeleteCompleteTask';
import { TaskFilter } from '@utils/models/TaskFilter';

export type hoverProps = {
  delete?: boolean;
  done?: boolean;
  lastDay?: boolean;
  lastMonday?: boolean;
  lastMonth?: boolean;
};

const TaskContainer = () => {
  const scrollRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [draggingId, setDraggingId] = useState<number | undefined>(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollDown, setScrollDown] = useState(false);
  const [scrollBy, setScrollBy] = useState<number>(0);
  const [transformOrign, setTransformOrign] = useState({ x: 0, y: 0 });
  const [currentData, setCurrentData] = useState<TaskFilter>();
  const [openModal, setOpenModal] = useState(false);
  const [hower, setHower] = useState<hoverProps>({
    delete: false,
    done: false,
    lastDay: false,
    lastMonday: false,
    lastMonth: false
  });

  const scrollMouseDown = (e: any) => {
    e.stopPropagation();
    setTransformOrign({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    });
    setScrollDown(true);
    document.body.style.cursor = 'grabbing';
  };

  useEffect(() => {
    const windowScroll = () => {
      setScrollWidth((285.79 / 100) * ((document.body.offsetWidth * 100) / document.body.scrollWidth));
    };
    windowScroll();
    window.addEventListener('resize', windowScroll);
    window.addEventListener('click', windowScroll);

    return () => {
      window.removeEventListener('resize', windowScroll);
      window.removeEventListener('click', windowScroll);
    };
  }, []);

  useEffect(() => {
    let curXPos = 0,
      curDown = false;

    window.onload = () => {
      setScrollWidth((285.79 / 100) * ((document.body.offsetWidth * 100) / document.body.scrollWidth));
    };

    const windowMouseDown = (e: MouseEvent) => {
      document.body.style.cursor = 'grabbing';
      curDown = true;
      curXPos = e.pageX;
    };
    const windowMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      if (curDown) {
        window.scrollBy(
          document.body.scrollLeft + (curXPos - e.pageX),
          0
          // document.body.scrollTop + (curYPos - e.pageY)
        );
      }

      let scrollWidthCopy = (285.79 / 100) * ((document.body.offsetWidth * 100) / document.body.scrollWidth);
      if (scrollDown) {
        document.body.style.cursor = 'grabbing';
        let left = e.clientX - document.body.clientWidth + 27 + 285.79 - transformOrign.x;
        let leftScroll = left <= 0 ? 0 : left >= 285.79 - scrollWidthCopy ? 285.79 - scrollWidthCopy : left;
        setScrollBy(e.clientX - document.body.clientWidth + 27 + 285.79 - transformOrign.x);

        let scrollProtsent = (leftScroll / 285.79) * 100;
        let bodyScroll = (document.body.scrollWidth / 100) * scrollProtsent;

        window.scrollTo(bodyScroll, 0);
      }
    };

    const windowMouseUp = () => {
      curDown = false;
      setScrollDown(false);
      document.body.style.cursor = 'default';
      setDragging(false);
    };
    const windowMouseLeave = () => {
      curDown = false;
      setScrollDown(false);
      document.body.style.cursor = 'default';
      setDragging(false);
    };

    const windowScroll = () => {
      let scrollProtsent = (window.scrollX / document.body.scrollWidth) * 100;
      let scrollWidthCopy = (285.79 / 100) * scrollProtsent;
      setScrollBy(scrollWidthCopy);
    };

    window.addEventListener('mousedown', windowMouseDown);
    window.addEventListener('mousemove', windowMouseMove);
    window.addEventListener('mouseup', windowMouseUp);
    window.addEventListener('mouseleave', windowMouseLeave);
    window.addEventListener('scroll', windowScroll);

    return () => {
      window.removeEventListener('mousedown', windowMouseDown);
      window.removeEventListener('mousemove', windowMouseMove);
      window.removeEventListener('mouseup', windowMouseUp);
      window.removeEventListener('mouseleave', windowMouseLeave);
      window.removeEventListener('scroll', windowScroll);
    };

    // eslint-disable-next-line
  }, [scrollDown]);

  return (
    <>
      <div className={styles.crmContainer}>
        <DndTaskContainer setDragging={setDragging} setDraggingId={setDraggingId} setCurrentData={setCurrentData} hower={hower} />
        <div className={styles.voronkaCards_scroll} ref={scrollRef} style={{ opacity: dragging ? 0 : 1 }}>
          <div
            onMouseDown={scrollMouseDown}
            className={styles.voronkaCards_scroll_element}
            style={{
              width: scrollWidth,
              pointerEvents: scrollDown ? 'none' : 'inherit',
              left: scrollBy <= 2 ? 2 : scrollBy >= 285.79 - scrollWidth - 4 ? 285.79 - scrollWidth - 4 : scrollBy
            }}
          ></div>
        </div>
        <DndDeleteCompleteTask
          openModal={openModal}
          setOpenModal={setOpenModal}
          dragging={dragging}
          draggingId={draggingId}
          currentData={currentData}
          setHower={setHower}
          hower={hower}
        />
      </div>
    </>
  );
};

export default TaskContainer;
