import React from 'react';

const PlusAddCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
      fill="#1E90FF"
    />
    <path d="M10 7V10M10 10V13M10 10H7M10 10H13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default PlusAddCircleIcon;
