import React, { useEffect, useState } from 'react';
import { usePipeAll } from '@queries/queries';
import { Button, Form, Input, Modal, Select } from 'antd';
import { t } from 'i18next';

import LeaveFromTokenIcon from './assets/LeaveFromTokenIcon';
import PlusIcon from './assets/PlusIcon';
import AddTGUserModal from './components/AddTGUserModal';
import TGUserItem from './components/TGUserItem';
import { useDeleteTGChatBotConfig, usePatchTGChatBotConfigUser, usePostTGChatBotUser } from './service/mutation';
import { useGetTGChatBotData } from './service/queries';
import { TGPremiumType } from './utils/models/TGPremiumType';

import styles from './tgPremium.module.scss';

type Props = {
  openTGPremium: boolean;
  setOpenTGPremium: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Option } = Select;

const TGPremium: React.FC<Props> = ({ openTGPremium, setOpenTGPremium }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const status = Form.useWatch('status', form);
  const responsibleEmployeeId = Form.useWatch('responsibleEmployeeId', form);
  const token = Form.useWatch('token', form);
  const pipeId = Form.useWatch('pipeId', form);

  const { data: dataTGChatBot, refetch } = useGetTGChatBotData();
  const { data: pipeAll } = usePipeAll();

  const postTGChatBotUser = usePostTGChatBotUser();
  const patchTGChatBotConfigUser = usePatchTGChatBotConfigUser();

  const deleteTGChatBotConfig = useDeleteTGChatBotConfig();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    refetch();
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setOpenTGPremium(false);
  };

  const handleLeave = () => {
    deleteTGChatBotConfig
      // @ts-expect-error
      .mutateAsync()
      .then(() => {
        form.resetFields(['username', 'pipe', 'token']);
      });
  };

  const handleEmployeeChange = (props?: { s?: boolean; r?: number; id: number }) => {
    const Status = props?.s ?? status ? 'ACTIVE' : 'BLOCK';

    patchTGChatBotConfigUser.mutate({
      id: props?.id,
      responsibleEmployeeId: props?.r || responsibleEmployeeId,
      status: Status
    });
  };

  useEffect(() => {
    if (dataTGChatBot) {
      form.setFieldsValue({
        ...dataTGChatBot,
        id: dataTGChatBot?.id,
        token: dataTGChatBot?.token,

        pipe: dataTGChatBot?.pipe?.id
      });
    }
  }, [dataTGChatBot, form]);

  const onFinish = (values: TGPremiumType) => {
    const { responsibleEmployeeId, ...rest } = values;

    const formData = {
      ...rest,
      id: values?.id,
      token: values?.token,
      pipe: {
        id: values?.pipe
      }
    };

    postTGChatBotUser.mutateAsync(formData as any);
  };

  return (
    <Modal
      visible={openTGPremium}
      onCancel={handleClose}
      title={dataTGChatBot?.botName === null ? t('integration.Telegram_Premium') : dataTGChatBot?.botName}
      footer={false}
      className={styles.tgPremium}
      width={690}
    >
      <Form form={form} onFinish={onFinish}>
        <div className="flex items-center justify-between">
          <div>
            <p>Telegram username</p>
            <Form.Item name="username">
              <Input className="customInput" placeholder="..." style={{ width: 310 }} disabled />
            </Form.Item>
          </div>

          <div>
            <p>{t('integration.Voronka')}</p>
            <Form.Item name="pipe" rules={[{ required: true, message: '' }]}>
              <Select className="customSelect" placeholder={t('home.Barcha_varonkalar_barcha_qadamlar')} style={{ width: 310 }}>
                {pipeAll?.map(item => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div>
          <p>Token</p>
          <div className="flex gap-[11px]">
            <Form.Item name="token">
              <Input.Password
                className="customInput"
                placeholder="..."
                // style={{ width: 550 }}
                style={{ width: dataTGChatBot ? 550 : 640 }}
              />
            </Form.Item>
            {dataTGChatBot && (
              <div className="mt-[6px] flex cursor-pointer" onClick={handleLeave}>
                <LeaveFromTokenIcon />
                <span className="ml-[5px] mt-[-2px] text-[14px] font-medium text-[black]">{t('home.Chiqish')}</span>
              </div>
            )}
          </div>
        </div>

        {/* @ts-ignore */}
        {dataTGChatBot?.chatBotUsers?.map((item: TGPremiumType, index: number) => <TGUserItem key={index} item={item} />)}

        {dataTGChatBot && (
          <button
            type="button"
            className={`flex w-full cursor-pointer items-center justify-center gap-[17px] rounded-[12px] border-dashed border-[#D0D5DD] bg-[transparent] py-[16px] text-[16px] text-[#344054] ${styles.btn}`}
            onClick={showModal}
          >
            <PlusIcon /> {t('integration.Foydalanuvchi_qoshish')}
          </button>
        )}

        <div className={styles.footer}>
          <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={postTGChatBotUser?.isLoading}
            disabled={!token}
          >
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>

      <AddTGUserModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </Modal>
  );
};

export default TGPremium;
