/* eslint-disable react/jsx-key */
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useBasicCurrency } from '../../../../queries/queries';
import { SearchParamsPayment } from '../../../payment/Payment';
import { usePaymentsSum } from '../../services/queries';

import PaymentCard from './PaymentCard';

import styles from './paymentMoney.module.scss';

interface IProps {
  countFilters: number;
}

const PaymentMoney: FC<IProps> = ({ countFilters }) => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsPayment, any>();
  const memorizedParams = useCallback(() => {
    delete searchParams.page;
    delete searchParams.size;
    return searchParams;
  }, [searchParams]);

  const { data: currency } = useBasicCurrency();

  const { data } = usePaymentsSum(memorizedParams());


  return (
    <div className={styles.container}>
      {data?.currencies ? (
        <PaymentCard
          title={countFilters > 0 ? t('payment.Umumiy_tushumlar') : t('payment.Bugungi_tushumlar')}
          total={data?.total}
          currencies={data?.currencies}
          currency={currency}
          color={undefined}
          custom={data?.custom}
        />
      ) : (
        ''
      )}

      {data?.cash?.currencies ? <PaymentCard title={t('payment.Naqd')} total={data?.cash?.total} currencies={data?.cash?.currencies} currency={currency} color="#00A389" /> : ''}

      {data?.card?.currencies ? (
        <PaymentCard title={t('payment.Terminal')} total={data?.card?.total} currencies={data?.card?.currencies} currency={currency} color="#4F1F9F" />
      ) : (
        ''
      )}

      {data?.transfer?.currencies ? (
        <PaymentCard title={t("payment.Pul_o'tkazma")} total={data?.transfer?.total} currencies={data?.transfer?.currencies} currency={currency} color="#FE6D73" />
      ) : (
        ''
      )}

      {data?.bank?.currencies ? <PaymentCard title={t('payment.Bank')} total={data?.bank?.total} currencies={data?.bank?.currencies} currency={currency} color="#FFBB54" /> : ''}

      {data?.myUzcard?.currencies ? <PaymentCard total={data?.myUzcard?.total} currencies={data?.myUzcard?.currencies} currency={currency} color="#3772F" title="MyUzcard" /> : ''}

      {data?.balance?.currencies ? (
        <PaymentCard total={data?.balance?.total} currencies={data?.balance?.currencies} currency={currency} color="#FFB400" title={t('payment.Mijoz_balansidan')} />
      ) : (
        ''
      )}
      {data?.payme?.currencies ? <PaymentCard total={data?.payme?.total} currencies={data?.payme?.currencies} currency={currency} color="#0078D2" title="Payme" /> : ''}

      {data?.paylov?.currencies ? <PaymentCard total={data?.paylov?.total} currencies={data?.paylov?.currencies} currency={currency} color="#ff4405" title="Paylov" /> : ''}

      {data?.others?.currencies ? (
        <PaymentCard total={data?.others?.total} currencies={data?.others?.currencies} currency={currency} color="rgb(105, 39, 218)" title={t('home.Boshqa')} />
      ) : (
        ''
      )}

      {data?.apelsin ? <PaymentCard total={data?.apelsin?.total} currencies={data?.apelsin?.currencies} currency={currency} color="rgb(105, 39, 218)" title="Uzum" /> : ''}

      {data?.custom ?
        // @ts-expect-error
        data?.custom?.map((item: any) => (
          <PaymentCard total={item.total} currencies={item.currencies} currency={currency} color="#64748b" title={item?.name} />
        ))
        : ''}
    </div>
  );
};

export default PaymentMoney;
