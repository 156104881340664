import { MonthlyPayment } from '../models/CreateContract';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DATE_FORMAT } from '../constants/format';
import { mathFloor } from './mathFloor';
import { roundFloatNumber } from './roundFloatNumber';

// @ts-ignore
dayjs.extend(customParseFormat);

export const calculateNewTablePayments: (totalPrice: number, firstPaymentDate: string, delay: number, interval: number) => MonthlyPayment[] = (
  totalPrice,
  firstPaymentDate,
  delay,
  interval
) => {
  const date = dayjs(firstPaymentDate, DATE_FORMAT);
  const monthlyPaymentLength = delay / interval;
  let time = 0;
  let sum: number = 0;
  const table: MonthlyPayment[] = [];

  for (let i = 0; i < monthlyPaymentLength; i++) {
    if (i === (monthlyPaymentLength || 0) - 1) {
      table.push({
        date: dayjs(date).add(time, 'month').format(DATE_FORMAT),
        amount: roundFloatNumber(totalPrice - sum),
        sum: roundFloatNumber(totalPrice - sum),
        amountString: mathFloor(totalPrice - sum, 0).toLocaleString('ru')
      });
    } else {
      const amount = mathFloor(totalPrice / monthlyPaymentLength, 0);
      table.push({
        amount,
        sum: roundFloatNumber(totalPrice - sum),
        date: dayjs(date).add(time, 'month').format(DATE_FORMAT),
        amountString: amount.toLocaleString('ru')
      });
      sum += amount;
    }
    time += interval || 1;
  }
  return table;
};
