import { FlatId } from '../../../utils/models/Flat';

export const getSelectedCheckbox = () => {
  const rooms = document.querySelectorAll('.selectableItems');
  let leftCheckbox = document.querySelectorAll('.leftCheckbox');
  let bottomCheckbox = document.querySelectorAll('.bottomCheckbox');
  let allCheckbox: any = document.querySelector('.allCheckbox');

  let arrLeft: any = {};
  let arrBottom: any = {};
  let arrAll: any = [];

  leftCheckbox.forEach((el: any) => {
    arrLeft[Number(el.id)] = [];
  });

  bottomCheckbox.forEach((el: any) => {
    arrBottom[JSON.parse(el.id).id] = [];
  });

  rooms.forEach((item: any) => {
    let flat: FlatId = JSON.parse(item.getAttribute('data'));
    arrAll.push(flat);
    leftCheckbox.forEach((el: any) => {
      if (flat.yaxis[0] === Number(el.id)) {
        arrLeft[Number(el.id)].push(flat);
      }
    });

    bottomCheckbox.forEach((el: any) => {
      if (flat.entrance === JSON.parse(el.id).entrance && flat.xaxis[0] === JSON.parse(el.id).i) {
        arrBottom[JSON.parse(el.id).id].push(flat);
      }
    });
  });

  leftCheckbox.forEach((el: any) => {
    if (arrLeft[Number(el.id)].every((item: FlatId) => item.isSelected) && arrLeft[Number(el.id)].length > 0) {
      el.querySelector('.ant-checkbox').classList.add('ant-checkbox-checked');
    } else {
      el.querySelector('.ant-checkbox').classList.remove('ant-checkbox-checked');
    }
  });

  bottomCheckbox.forEach((el: any) => {
    if (arrBottom[JSON.parse(el.id).id].every((item: FlatId) => item.isSelected) && arrBottom[JSON.parse(el.id).id].length > 0) {
      el.querySelector('.ant-checkbox').classList.add('ant-checkbox-checked');
    } else {
      el.querySelector('.ant-checkbox').classList.remove('ant-checkbox-checked');
    }
  });

  if (arrAll.every((item: FlatId) => item.isSelected)) {
    allCheckbox?.querySelector('.ant-checkbox').classList.add('ant-checkbox-checked');
  } else {
    allCheckbox?.querySelector('.ant-checkbox').classList.remove('ant-checkbox-checked');
  }
};
