import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '@service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { StatsType } from '../utils/models/StatsType';

export function useGetStats(id: number) {
  return useQuery<StatsType>(
    [queryKeys.GET_STATS, id],
    async () => {
      const res = await $api.get(endPoints.GET_STATS + `/${id}`);
      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}
