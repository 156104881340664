import React from 'react';

const WarningIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0092 3.8987L9.04922 1.61203C8.40255 1.2387 7.60255 1.2387 6.94922 1.61203L2.99589 3.8987C2.34922 4.27203 1.94922 4.96536 1.94922 5.7187V10.2787C1.94922 11.0254 2.34922 11.7187 2.99589 12.0987L6.95589 14.3854C7.60255 14.7587 8.40255 14.7587 9.05589 14.3854L13.0159 12.0987C13.6626 11.7254 14.0626 11.032 14.0626 10.2787V5.7187C14.0559 4.96536 13.6559 4.2787 13.0092 3.8987ZM7.50255 5.16536C7.50255 4.89203 7.72922 4.66536 8.00255 4.66536C8.27589 4.66536 8.50255 4.89203 8.50255 5.16536V8.66536C8.50255 8.9387 8.27589 9.16536 8.00255 9.16536C7.72922 9.16536 7.50255 8.9387 7.50255 8.66536V5.16536ZM8.61589 11.0854C8.58255 11.1654 8.53589 11.2387 8.47589 11.3054C8.34922 11.432 8.18255 11.4987 8.00255 11.4987C7.91589 11.4987 7.82922 11.4787 7.74922 11.4454C7.66255 11.412 7.59589 11.3654 7.52922 11.3054C7.46922 11.2387 7.42255 11.1654 7.38255 11.0854C7.34922 11.0054 7.33589 10.9187 7.33589 10.832C7.33589 10.6587 7.40255 10.4854 7.52922 10.3587C7.59589 10.2987 7.66255 10.252 7.74922 10.2187C7.99589 10.112 8.28922 10.172 8.47589 10.3587C8.53589 10.4254 8.58255 10.492 8.61589 10.5787C8.64922 10.6587 8.66922 10.7454 8.66922 10.832C8.66922 10.9187 8.64922 11.0054 8.61589 11.0854Z"
        fill="#FFB400"
      />
    </svg>
  );

export default WarningIcon;
