import React from 'react';

const LoginArrovRightIcon = () => {
  return (
    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6825 12.9401L6.5725 8.05006C7.15 7.47256 7.15 6.52756 6.5725 5.95006L1.6825 1.06006"
        stroke="#3772FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoginArrovRightIcon;
