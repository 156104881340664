import React from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHousesNameAndID } from '../../../queries/queries';
import RefreshIcon from '../../../assets/icons/RefreshIcon';
import { Apelsin } from '../../../utils/models/Apelsin';
import { useCreateRemotePayment } from '../../../queries/mutation';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

const EditModal: React.FC<Props> = ({ visible, onClose }) => {
  const [form] = Form.useForm<Apelsin>();

  const { isLoading: housesLoading, data: houses } = useHousesNameAndID();

  const create = useCreateRemotePayment(onCloseModal);

  const { t } = useTranslation();

  function onCloseModal() {
    form.resetFields();
    onClose(false);
  }

  const onFinish = (values: Apelsin) => {
    create.mutate({
      ...values,
      password: values.password?.trim(),
      username: values.username?.trim(),
      status: true
    });
  };

  return (
    <Modal
      title={t('paymeCash.Masofaviy_tolov_Apelsin')}
      wrapClassName="settingEmployeeModal"
      visible={visible}
      onCancel={onCloseModal}
      footer={false}
      width={546}
    >
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={8} className={'d-flex justify-space-between'}>
          <Col span={12}>
            <Form.Item label={t('paymeCash.ID')} name={'username'} rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'password'} label={t('paymeCash.Key')} rules={[{ required: true, message: '' }]}>
              <Input suffix={<RefreshIcon />} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('paymeCash.Turar_joy_majmuasi')}
              name="houseIds"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Select mode={'multiple'} optionFilterProp={'children'} showSearch loading={housesLoading}>
                {houses?.map((house, index) => (
                  <Select.Option key={index} value={house.id}>
                    {house.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="settingEmployeeModalFooter">
          <Button onClick={onCloseModal}>{t('paymeCash.Yopish')}</Button>
          <Button loading={create.isLoading} htmlType="submit" type="primary">
            {t('paymeCash.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditModal;
