import React from 'react';
import { Link } from 'react-router-dom';
import { CommentModal } from '@components/index';
import { useQueryParam } from '@hooks/useQueryParams';
import CalendarTickIcon from '@icons/CalendarTickIcon';
import MessageText from '@icons/MessageText';
import WalletMinusIcon from '@icons/WalletMinusIcon';
import { changeDiscount, setDelay, setDiscountText, setOptionsContract, setTermDIscount, setTotalAmount, setVariation } from '@reducers/CreateContractSlice';
import { Permissions } from '@utils/constants/permissions';
import { cx } from '@utils/cx';
import { useTranslation } from 'react-i18next';

import CardPosIcon from '../../../../assets/icons/CardPosIcon';
import CardTickIcon from '../../../../assets/icons/CardTickIcon';
import ExportIcon from '../../../../assets/icons/contract/ExportIcon';
import DollarCircle from '../../../../assets/icons/DollarCircle';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { FlatMore } from '../../../../utils/models/FlatMore';
import { FlatStatus } from '../../../../utils/models/FlatStatus';

import PriceInformationChange from './PriceInformationChange';

import styles from '../../homeId.module.scss';

interface Props {
  data?: FlatMore[] | undefined;
}

const className = {
  container: 'rounded-[6px] border-[1px] border-solid border-[#e5e7eb] p-[16px]',
  icon: 'flex h-[32px] w-[32px] items-center justify-center rounded-[50%] border-[1px] border-solid border-[--gun-powder]',
  label: 'text-[14px] text-[--philippine-gray] mt-[12px] inline-block',
  price: 'text-[18px] font-[500] mt-[8px] text-[--gun-powder]',
  link: 'text-[--navy-blue] text-[14px] font-[500] flex items-center justify-center gap-[9px] mr-[24px]'
};

const PriceInformation: React.FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.userReducer);
  const { companyId } = useAppSelector(state => state.companyReducer);
  const { searchParams } = useQueryParam<
    {
      idFlat: string;
      nameHouse: string;
      company: string;
    },
    null
  >();
  const isMultiPaymentEdit = user?.role?.permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT);
  const isMultiPayment = user?.role?.permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const companyIdConverter = searchParams.company ? Number(atob(searchParams.company)) : companyId;

  const { totalAmount, delay, prePayment, discountSum, currency, variation, constTotalAmount, discountText } = useAppSelector(state => state.createContract);

  // calculate monthly payment
  const monthlyPayment = () => roundFloatNumber((totalAmount - discountSum - prePayment - (variation?.mortgagePrice || 0)) / (delay || 1)).toLocaleString('ru');

  const monthlyPayment2 = () => roundFloatNumber((totalAmount - prePayment - (variation?.mortgagePrice || 0)) / (delay || 1)).toLocaleString('ru');

  // calculate prepayment percent
  const prepaymentPercent = () => Math.round((prePayment / (totalAmount || 1)) * 100);

  const discountPercent = roundFloatNumber(discountSum / (totalAmount / 100));
  const changePrice = roundFloatNumber((constTotalAmount / 100) * (variation?.changePricePercent || 0));
  const discountPercent2 = roundFloatNumber(discountSum / (((constTotalAmount || totalAmount) + changePrice) / 100));

  const changePriceTotal = (constTotalAmount || totalAmount) + ((constTotalAmount || totalAmount) / 100) * (variation?.changePricePercent || 0);

  return (
    <div className="p-[24px]">
      <div className="flex items-center justify-between">
        <h2 className="text-[14px] font-[600] text-[#464255]">{t('home_id.Tolov_shartlari')}</h2>
        {companyIdConverter === user?.companyId && data && data[0]?.flat?.status === FlatStatus.SOLD && (
          <Link className={styles.home_id_content_header_link} to={`${rootPaths.MAIN + MainPaths.CONTRACT}/${data[0]?.contractId}/${data[0]?.contractNumber}`}>
            <ExportIcon />
            {t('home_id.Shartnoma_malumotlari')}
          </Link>
        )}
      </div>
      <div className={cx('!grid grid-cols-3 gap-[22px]')}>
        <div className={className.container}>
          <span className={className.icon}>
            <DollarCircle stroke="#464255" size={16} />
          </span>
          <span className={className.label}>{t('home_id.Umumiy_summa')}</span>
          {isMultiPaymentEdit ? (
            <PriceInformationChange
              value={String(totalAmount)}
              type="price"
              suffix={currency?.ccy}
              maxNumber={changePriceTotal}
              onChange={value => {
                dispatch(setTotalAmount({ amount: value, constAmount: constTotalAmount || totalAmount }));

                const discount = changePriceTotal - value;

                dispatch(changeDiscount({ amount: discount, changeRepairedStatus: false }));

                if (prepaymentPercent() || variation?.prepaymentPercent) {
                  dispatch(
                    setOptionsContract({
                      delay,
                      prePayment: (value / 100) * (prepaymentPercent() || variation?.prepaymentPercent || 0),
                      useDiscount: true,
                      notDiscount: true
                    })
                  );
                }

                dispatch(
                  setVariation({
                    ...variation!,
                    ...(variation?.mortgagePercent && {
                      mortgagePrice: (value / 100) * variation?.mortgagePercent
                    }),
                    prepaymentPercent: prepaymentPercent() || variation?.prepaymentPercent || 0,
                    id: undefined
                  })
                );
              }}
            />
          ) : (
            <p className={className.price}>
              {totalAmount.toLocaleString('ru')} {currency?.ccy}
            </p>
          )}
        </div>
        <div className={className.container}>
          <span className={className.icon}>
            <CardTickIcon stroke="#464255" size={16} />
          </span>
          <span className={className.label}>
            {t('home_id.Boshlangich_tolov')} ({prepaymentPercent()}%)
          </span>
          {isMultiPaymentEdit ? (
            <PriceInformationChange
              suffix={currency?.ccy}
              value={String(prePayment)}
              maxNumber={totalAmount - (variation?.mortgagePrice || 0)}
              type="price"
              onChange={value => {
                dispatch(
                  setOptionsContract({
                    delay,
                    prePayment: value,
                    useDiscount: true,
                    notDiscount: true
                  })
                );
                if (variation && !Number.isNaN(variation?.prepaymentPercent)) {
                  dispatch(
                    setVariation({
                      ...variation,
                      id: undefined
                    })
                  );
                }
              }}
            />
          ) : (
            <p className={className.price}>
              {prePayment.toLocaleString('ru')} {currency?.ccy}
            </p>
          )}
        </div>

        {discountSum || isMultiPaymentEdit ? (
          <div className={className.container}>
            <span className={className.icon}>
              <DollarCircle stroke="#464255" size={16} />
            </span>
            <span className={className.label}>
              {t('home_id.Chegirma')} ({isMultiPayment ? discountPercent2 : discountPercent}%)
            </span>
            {isMultiPaymentEdit ? (
              <div className="flex items-end gap-[8px]">
                <PriceInformationChange
                  suffix={currency?.ccy}
                  value={String(discountSum)}
                  maxNumber={changePriceTotal}
                  type="price"
                  onChange={value => {
                    dispatch(setTermDIscount(undefined));

                    const amount = changePriceTotal - value;

                    dispatch(setTotalAmount({ amount, constAmount: constTotalAmount || totalAmount }));

                    dispatch(changeDiscount({ amount: value, changeRepairedStatus: false }));

                    if (prepaymentPercent()) {
                      dispatch(
                        setOptionsContract({
                          delay,
                          prePayment: (amount / 100) * prepaymentPercent(),
                          useDiscount: true,
                          notDiscount: true
                        })
                      );
                    }

                    if (variation?.mortgagePercent) {
                      dispatch(
                        setVariation({
                          ...variation,
                          mortgagePrice: (amount / 100) * variation?.mortgagePercent,
                          id: undefined
                        })
                      );
                    }
                  }}
                />
                <CommentModal
                  defaultComment={discountText}
                  onSave={(text, closeComment) => {
                    if (text) {
                      dispatch(setDiscountText(text));
                    }

                    closeComment();
                  }}
                >
                  <span className="flex h-[32px] w-[40px] cursor-pointer items-center justify-center rounded-[12px] border-[1px] border-solid border-[#EDEDED]">
                    <MessageText stroke="#667085" className="h-[16px] w-[16px]" />
                  </span>
                </CommentModal>
              </div>
            ) : (
              <p className={className.price}>
                {discountSum.toLocaleString('ru')} {currency?.ccy}
              </p>
            )}
          </div>
        ) : undefined}

        <div className={className.container}>
          <span className={className.icon}>
            <CardPosIcon stroke="#464255" size={16} />
          </span>
          <span className={className.label}>
            {t('home_id.Oylik_tolov')} ({delay} {t('home_id.oy')})
          </span>
          <p className={className.price}>
            {isMultiPayment ? monthlyPayment2() : monthlyPayment()} {currency?.ccy}
          </p>
        </div>
        {variation?.mortgagePercent || isMultiPaymentEdit ? (
          <div className={className.container}>
            <span className={className.icon}>
              <WalletMinusIcon stroke="#464255" size={16} />
            </span>
            <span className={className.label}>
              {t('contract.Ipoteka_summasi')} ({roundFloatNumber(variation?.mortgagePercent || 0)}%)
            </span>
            {isMultiPaymentEdit ? (
              <PriceInformationChange
                suffix={currency?.ccy}
                maxNumber={totalAmount - (prePayment || 0)}
                value={String(variation?.mortgagePrice)}
                type="price"
                onChange={value => {
                  dispatch(
                    setVariation({
                      ...variation!,
                      mortgagePercent: value / (totalAmount / 100),
                      mortgagePrice: value,
                      id: undefined
                    })
                  );
                }}
              />
            ) : (
              <p className={className.price}>
                {roundFloatNumber(variation?.mortgagePrice || 0).toLocaleString('ru')} {currency?.ccy}
              </p>
            )}
          </div>
        ) : (
          ''
        )}
        {variation?.contractMonth || isMultiPaymentEdit ? (
          <div className={className.container}>
            <span className={className.icon}>
              <CalendarTickIcon stroke="#464255" size={16} />
            </span>
            <span className={className.label}>{t('boss_contract.Shartnoma_muddati')} </span>
            {isMultiPaymentEdit ? (
              <PriceInformationChange
                suffix="oy"
                value={String(delay)}
                type="number"
                onChange={value => {
                  if (variation) {
                    dispatch(
                      setVariation({
                        ...variation,
                        contractMonth: value,
                        id: undefined
                      })
                    );
                  }
                  dispatch(setDelay(value));
                }}
              />
            ) : (
              <p className={className.price}>{variation?.contractMonth} oy</p>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default PriceInformation;
