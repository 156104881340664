import { PaymentField } from '@pages/setting/payment-changes/utils/models/PaymentField';
import { getNameTrl } from '@utils/helper/getNameTrl';
import { TableConfig, TableConfigValue } from '@utils/models/TableConfig';

export function updateTableConfig(tableConfig: TableConfig[], customFields: PaymentField[]) {
  let tableConfigIds = new Set(tableConfig?.map(config => config.id));
  let arr = [...tableConfig];
  for (let i = 0; i < customFields?.length; i++) {
    if (!tableConfigIds.has('field_' + customFields[i].id)) {
      arr?.push({
        id: String(customFields![i].id)?.includes('field') ? String(customFields![i].id) : 'field_' + customFields![i].id,
        field: true,
        name: getNameTrl(customFields![i]),
        checked: true // You can set the default value for the 'checked' property
      });
    }
  }

  // console.log(tableConfig?.config, 'tableConfig')
  return arr;
}
