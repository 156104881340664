import dayjs from 'dayjs';
import { useMutation, useQueryClient } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { showMessage } from '../../../queries/showMessage';
import i18n from '../../../service/I18nService';
import { $api } from '../../../service/RequestService';
import { downloadFile } from '../../../utils/helper/downloadFile';
import { getContractsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { SearchParamsContract } from '../Contract';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useDownloadContractsToExcel(size: number, searchObj: SearchParamsContract) {
  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.CONTRACT_FILTER_EXCEL}?lang=${i18n.language?.toUpperCase()}`, getContractsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Shartnomalar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadContractsSummaryToExcel(size: number, searchObj: SearchParamsContract) {
  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.CONTRACT_FILTER_EXCEL_SUMMARY}?lang=${i18n.language?.toUpperCase()}`, getContractsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Shartnoma-tulovlari-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useDownloadContractLog(id: number, number: string, onSuccess?: () => void) {
  return useMutation(
    async (data: { date: string; lang?: string }) => {
      let url = `${endPoints.CONTRACT + id}/get-log?date=${data.date}`;

      if (data.lang) {
        url += `&lang=${data.lang}`;
      }
      const res = await $api.get(url, {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, `log-contract-${number}`, 'xlsx');
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useContractTransfer() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { contractId: number; clientId: number; cancelText: string; onlyClient: boolean }) => {
      const res = await $api.patch(endPoints.CONTRACT_TRANSFER, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CONTRACT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeEmployeeContract(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { contractId: number; employeeId: number }) => {
      const res = await $api.put(`${endPoints.CONTRACT}/${data.contractId}/responsible-employee/${data.employeeId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CONTRACT]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadDraftExcel() {
  return useMutation({
    mutationFn: async (data: { startDate: string; finishDate: string; repaired: boolean | null; houses?: number[] }) => {
      let url =
        data?.houses?.length! > 0
          ? `${endPoints.DRAFT_EXCEL}?startDate=${data.startDate}&finishDate=${data?.finishDate}&lang=${i18n.language?.toUpperCase()}&${data?.houses?.map(i => i !== undefined && `houseIds=${i}`).join('&')}`
          : `${endPoints.DRAFT_EXCEL}?startDate=${data.startDate}&finishDate=${data?.finishDate}&lang=${i18n.language?.toUpperCase()}`;

      if (data.repaired !== null) {
        url += `&repaired=${data?.repaired}`;
      }

      const res = await $api.get(url, {
        responseType: 'blob'
      });

      return res.data;
    },
    onSuccess: data => {
      downloadFile(data, `Shartnomalar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
    },
    onError: async (error: any) => {
      const errorObj = JSON.parse(await error.response.data.text());

      showMessage(errorObj.message, errorObj.accept);
    }
  });
}
