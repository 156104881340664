import React, { useEffect, useState } from 'react';
import { setCurrentPage } from '@reducers/ChangesSlice';
import { Button, Col, DatePicker, Dropdown, Form, Layout, Modal, Row, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import Download from '../../assets/icons/Download';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import TableSettings from '../../components/filters/TableSettings';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useArrearageOneDay } from '../../queries/mutation';
import { useTableConfig } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { TableKey } from '../../utils/models/TableKey';

import ArrearageFilter from './components/filter/ArrearageFilter';
import ArrearageTable from './components/table/ArrearageTable';
import { useArrearageOneDayBeta, useDownloadDebitsMonthlyToExcel, useDownloadDebitsToExcel } from './services/mutation';
import { useDebitList } from './services/queries';
import { ActionsHeader } from './components';

import stylesButton from '../../components/filters/tableSettings.module.scss';
import styles from './arrearage.module.scss';

const { Content } = Layout;

export interface SearchParamsArrearage {
  page?: string;
  size?: string;
  houses?: string[];
  search?: string;
  order?: string[];
  buildings?: string[];
  color?: string[];
  responsibleByIds?: string[];
  currencies?: string[];
  statuses?: string[];
}

const Arrearage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { searchParams, appendMultipleDifferent, params } = useQueryParam<SearchParamsArrearage, any>();
  const { currentPage } = useAppSelector(state => state.changesReducer);

  const [beta, setBeta] = useState(false);
  const [form] = Form.useForm();
  const [tableSize, setTableSize] = useState(10);
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);
  const [openModalDate, setOpenModalDate] = useState(false);
  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.arrearage);

  const { data, isLoading, isFetching } = useDebitList(tableSize, searchParams);
  const downloadTable = useDownloadDebitsToExcel(tableSize, searchParams);

  // const [allData, setAllData] = useState<AllData[]>([])

  function handleDownloadModal(beta?: boolean) {
    setBeta(!!beta);
    setOpenModalDate(!openModalDate);
    setVisibleDownloadMenu(false);
    form.resetFields();
  }

  const downloadTableOneDate = useArrearageOneDay(tableSize, searchParams, handleDownloadModal);

  const downloadTableOneDateBeta = useArrearageOneDayBeta(tableSize, searchParams, handleDownloadModal);

  const downloadTableMonthly = useDownloadDebitsMonthlyToExcel(tableSize, searchParams);

  useEffect(() => {
    if (tableConfig?.size && params['*'].includes('arrearage')) {
      appendMultipleDifferent(['page', 'size'], [currentPage, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  useEffect(() => {
    if (!params['*'].includes('arrearage')) {
      dispatch(setCurrentPage(1));
    }
  }, [params['*']]);

  const changePage = (page: number) => {
    dispatch(setCurrentPage(page));
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };
  const downloadExcel = () => {
    downloadTable.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('contract.Jadvalni_yuklash')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>

          <div onClick={() => handleDownloadModal(true)} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('contract.Kiritilgan_sanadagi_qarzdorlik')} </span>
            </div>
            <ArrowLineDownIcon />
          </div>
          <div
            onClick={() => {
              setVisibleDownloadMenu(false);
              downloadTableMonthly.mutate();
            }}
            className={stylesButton.downloadPopover_item}
          >
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Joriy_va_oldingi_qarzdorliklar')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  const handleFinish = (val: { date: Dayjs }) => {
    if (beta) {
      downloadTableOneDateBeta.mutate(
        dayjs(val.date, {
          format: DATE_FORMAT
        }).format(DATE_FORMAT)
      );
    } else {
      downloadTableOneDate.mutate(
        dayjs(val.date, {
          format: DATE_FORMAT
        }).format(DATE_FORMAT)
      );
    }
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <ActionsHeader totalElements={data?.totalElements} />
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={`${styles.arrearage_page}`}>
          <div className={`d-flex align-center justify-space-between `}>
            <ArrearageFilter />
            <Spin spinning={downloadTable.isLoading || downloadTableMonthly.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.arrearage} />
            </Spin>
          </div>
          <ArrearageTable isFetching={isFetching} data={data?.data || []} isLoading={isLoading} tableConfig={tableConfig?.config || []} tableConfigFetch={tableConfigFetch} />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
          <Modal title={t('client_balance.Yuklab_olish')} className={styles.downloadModal} footer={false} visible={openModalDate} onCancel={() => handleDownloadModal()}>
            <div className={styles.body}>
              <Form
                form={form}
                name="naturalClient"
                initialValues={{ remember: true }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={handleFinish}
                autoComplete="off"
              >
                <Row style={{ display: 'flex', alignItems: 'flex-end' }} gutter={[8, 0]}>
                  <Col span={16}>
                    <Form.Item
                      name="date"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      label={<span className={styles.formLabel}>{t(`changes.monthlyPayments.Sana`)}</span>}
                    >
                      {/* @ts-expect-error */}
                      <DatePicker placeholder={t('client_create_contract.kk_oo_yyyy')} format={DATE_FORMAT} />
                    </Form.Item>
                  </Col>
                  <Col style={{ textAlign: 'end' }} span={8}>
                    <Button loading={downloadTableOneDate.isLoading || downloadTableOneDateBeta.isLoading} style={{ borderRadius: '16px' }} htmlType="submit" type="primary">
                      {t('client_balance.Yuklab_olish')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        </div>
      </Content>
    </>
  );
};

export default Arrearage;
