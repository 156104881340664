import { Checkbox, Collapse, Drawer, Space, Spin } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ColapseIcon from '../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../assets/icons/FIlterColapseIcon';
import FilterReloadIcon from '../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { SearchParamsClient } from '../../pages/client/Client';

const { Panel } = Collapse;

interface ContractFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const BrokerFilterDrawer: FC<ContractFilterDrawerProps> = ({ visible, setVisible }) => {
  const { searchParams, removeAllSearchParams, remove, appendMultiple } = useQueryParam<SearchParamsClient, any>();
  const { t } = useTranslation();

  const { data: regions, isLoading: loadingRegions } = useRegions();

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const changeCities = (id: string) => {
    if (Array.isArray(searchParams.cities)) {
      searchParams.cities.includes(id) ? remove('cities', id) : appendMultiple('cities', id);
    } else {
      if (searchParams.cities === id) {
        remove('cities', id);
      } else {
        appendMultiple('cities', id);
      }
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className={'filter_drawer'}>
      <Spin spinning={loadingRegions}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition={'right'}
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Yashash_joyi')}
              </>
            }
            key="1"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {regions?.map((house, index) => (
                <Checkbox
                  checked={
                    Array.isArray(searchParams.cities) ? searchParams.cities.includes(String(house.id)) : searchParams.cities === String(house.id)
                  }
                  onChange={() => changeCities(String(house.id))}
                  key={index}
                >
                  {getCurrentLanguageWord(house)}
                </Checkbox>
              ))}
            </Space>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default BrokerFilterDrawer;
