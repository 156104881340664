import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditKpiPart } from '../../../queries/mutation';
import { helper } from '../../../utils/helper/helper';

import styles from '../../../pages/kpi/kpi.module.scss';

interface IProps {
  visible: boolean;
  onClose: (visible: boolean) => void;
  kpiId: number;
}

interface IForm {
  amount: number;
  count: string;
}

const AddModalPercent: React.FC<IProps> = ({ visible, onClose, kpiId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IForm>();

  const handleCancel = () => {
    onClose(false);
    form.resetFields();
  };

  function handleSuccess() {
    handleCancel();
  }
  const createKpiPart = useEditKpiPart(handleSuccess);

  const onFinish = (fields: IForm) => {
    createKpiPart.mutate({
      count: Number(helper.deleteSpace(fields.count)),
      amount: Number(fields.amount),
      kpiId
    });
  };

  const handleOk = () => {
    handleCancel();
  };

  return (
    <Modal width={639} className="addModalKpi" title={t('kpi.Mukofot_formulasi')} visible={visible} onOk={handleOk} footer={false} onCancel={handleCancel}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className={`${styles.modal_item} d-flex align-center justify-space-between`}>
          <p>{t('kpi.Sotilgan_uylar_soni_quyidagidan_yuqori_bolganda')}</p>
          <div className="d-flex align-center">
            <Form.Item
              name="count"
              label=""
              rules={[
                { required: true, message: '' },
                {
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject('');
                    }
                    form.setFieldsValue({
                      count: helper.deleteNotNumbers(value, null)?.toLocaleString('ru')
                    });
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input className={`${styles.modal_item_input} customInput`} />
            </Form.Item>
            <span className={styles.modal_item_value}>{t('kpi.dona')}</span>
          </div>
        </div>
        <div className={`${styles.modal_item} d-flex align-center justify-space-between`}>
          <p>{t('kpi.Mukofot_foizi')}</p>
          <div className="d-flex align-center">
            <Form.Item
              name="amount"
              label=""
              rules={[
                { required: true, message: '' },
                {
                  validator: (rule, value) => {
                    if (value < 0) {
                      form.setFieldsValue({
                        amount: 0
                      });
                    } else if (value > 100) {
                      form.setFieldsValue({
                        amount: 100
                      });
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input type="number" className={`${styles.modal_item_input} customInput`} />
            </Form.Item>
            <span className={styles.modal_item_value}>%</span>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}>{t('kpi.Yopish')}</Button>
          <Button loading={createKpiPart.isLoading} htmlType="submit" type="primary">
            {t('kpi.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddModalPercent;
