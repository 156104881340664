import React, { useContext } from 'react';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import UnsuccessfulLeadsTable from './components/unsuccessful-leads-table/UnsuccessfulLeadsTable';

import styles from './unsuccessfulLeads.module.scss';

type Props = {
  visible: boolean;
  onCancel: () => void;
};
const UnsuccessfulLeads: React.FC<Props> = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const { name } = useContext(ConversionContext);

  return (
    <Modal
      centered
      width={1000}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      className={styles.unsuccessfulLeads_modal}
      title={<span>{name === 'first' ? t('boss_crm_conversion.Muvaffaqiyatsiz_Lidlar') : t('boss_crm_conversion.Muvaffaqiyatli_Lidlar')}</span>}
    >
      <UnsuccessfulLeadsTable />
    </Modal>
  );
};

export default UnsuccessfulLeads;
