import React from 'react';

interface Props {
  className?: string;
}

const CardEditIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66406 7.08337H9.58073" stroke="#464255" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.99609 13.75H6.66276" stroke="#464255" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.74609 13.75H12.0794" stroke="#464255" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.3307 10.025V13.425C18.3307 16.35 17.5891 17.0833 14.6307 17.0833H5.36406C2.40573 17.0833 1.66406 16.35 1.66406 13.425V6.57496C1.66406 3.64996 2.40573 2.91663 5.36406 2.91663H12.0807"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.897 3.44172L12.8053 6.53338C12.6886 6.65005 12.572 6.88338 12.547 7.05005L12.3803 8.23338C12.322 8.65838 12.622 8.95838 13.047 8.90005L14.2303 8.73338C14.397 8.70838 14.6303 8.59172 14.747 8.47505L17.8386 5.38338C18.372 4.85005 18.622 4.23338 17.8386 3.45005C17.047 2.65838 16.4303 2.90838 15.897 3.44172Z"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4551 3.8833C15.7217 4.82497 16.4551 5.5583 17.3884 5.81663"
        stroke="#464255"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardEditIcon;
