import { useQueryParam } from '@hooks/useQueryParams';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { urlDecode, urlEncode } from '@utils/helper/urlEncodeDecode';
import { CrmField } from '@utils/models/CrmField';
import { Select } from 'antd';
import React from 'react';

interface Props {
  data: CrmField;
  fields?: string[] | string;
}

const { Option } = Select;

const MultiSelectField: React.FC<Props> = ({ data, fields }) => {
  const { remove, appendMultipleArray, searchParams, removeMultiple } = useQueryParam<any, any>();

  // changeText
  const changeText = (e: string[]) => {
    if (searchParams?.multiField) {
      if (Array.isArray(searchParams?.multiField)) {
        removeMultiple(['multiField'], [...searchParams?.multiField]);
      } else {
        remove('multiField', searchParams?.multiField);
      }
    }
    if (e) {
      appendMultipleArray(
        'multiField',
        e?.map(item => urlEncode(`${data?.id}_${item}`))
      );
    }
  };

  // handle show value
  // changeText
  const handleShow = () => {
    if (searchParams?.multiField && Array.isArray(searchParams?.multiField)) {
      if (data?.id === Number(searchParams?.multiField?.map((item: string) => item?.split('_')[0])[0])) {
        return searchParams?.multiField?.map((item: string) => item?.split('_')[1]);
      }
    } else if (searchParams?.multiField) {
      if (data?.id === Number(searchParams?.multiField?.split('_')[0])) {
        return [searchParams?.multiField?.split('_')[1]];
      }
    }
  };

  return (
    <Select
      mode="multiple"
      style={{ width: '100%', marginTop: 8 }}
      value={handleShow()}
      allowClear
      className={'customSelect'}
      onChange={changeText}
      placeholder={data?.name}
    >
      {data?.options?.map(item => (
        <Option key={item?.id} value={item?.name}>
          {item?.name}
        </Option>
      ))}
    </Select>
  );
};

export default MultiSelectField;
