import { Link } from 'react-router-dom';
import { Input, Layout } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../assets/icons/SearchIcon';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import HeaderCurrency from '../../components/setting/headerCurrency/HeaderCurrency';
import settingLink from '../../components/setting/settingLink';

import styles from './settingHome.module.scss';

const { Content } = Layout;

const SettingHome = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.settingHome_header}>
        <BreadcrumbHeader />
        <HeaderCurrency />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </div>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.settingHome}>
          <Input
            style={{ width: 321, height: 44, padding: '0 19px' }}
            suffix={<SearchIcon />}
            placeholder={t('settingHome.Sozlamani_qidirish')}
            className={styles.settingSearch}
          />
          <div>
            {settingLink.map((i, index) => (
              <Link key={index} to={i.to}>
                <div>
                  <img src={i.image} alt="settings-icon" />
                  <span>{t(`setting.${i.title}`)}</span>
                </div>
                <h1>{t(`setting.${i.name}`)}</h1>
                <p>{t(`setting.${i.description}`)}</p>
              </Link>
            ))}
          </div>
        </div>
      </Content>
    </>
  );
};

export default SettingHome;
