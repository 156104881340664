/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import DeleteAreaArageIcon from '@icons/areaarage/DeleteAreaArageIcon';
import ClearClientIcon from '@icons/ClearClientIcon';
import ClientStatus from '@icons/ClientStatus';
import PremiumClientIcon from '@icons/PremiumClientIcon';
import VipClientIcon from '@icons/VipClientIcon';
import { Avatar, Button, Dropdown, Popover, Tooltip } from 'antd';
import { Spin } from 'antd/es';
import { useTranslation } from 'react-i18next';

import MailAreaArageIcon from '../../../../../assets/icons/areaarage/MailAreaArageIcon';
import PlusCircleAreaArageIcon from '../../../../../assets/icons/areaarage/PlusCircleAreaArageIcon';
import CheckIcon from '../../../../../assets/icons/CheckIcon';
import EllipseIcon from '../../../../../assets/icons/EllipseIcon';
import MiniMenuIcon from '../../../../../assets/icons/MiniMenuIcon';
import SlashIcon from '../../../../../assets/icons/SlashIcon';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { useTableColor } from '../../../../../queries/mutation';
import { useContractEmployee } from '../../../../../queries/queries';
import { BASE_URL } from '../../../../../service/RequestService';
import { Permissions } from '../../../../../utils/constants/permissions';
import { tableStatusColor } from '../../../../../utils/constants/TableStatusColor';
import { DebtList } from '../../../../../utils/models/DebtList';
import { useChangeEmployeeArrearage, useUpdateClientLevel } from '../../../services/mutation';

import styles from './action.module.scss';

interface Props {
  record: DebtList;
  handleSendMessageModalOpen: (id: number, name: string, phoneNumber: string, contractNumber: string) => void;
  handleTaskCreateModalOpen: (visible: boolean, id?: number, arrearage?: number, clientId?: number) => void;
  setSurchargeDeleteModalVisible: React.Dispatch<React.SetStateAction<{ id: number; visible: boolean }>>;
}

const Action: React.FC<Props> = ({ record, handleTaskCreateModalOpen, handleSendMessageModalOpen, setSurchargeDeleteModalVisible }) => {
  const { t } = useTranslation();
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const [activeId, setActiveId] = useState(0);
  const [activeType, setActiveType] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openEmployeeDropDown, setOpenEmployeeDropDown] = useState(false);

  const tableColor = useTableColor();
  const { data, isLoading } = useContractEmployee();

  const changeEmployee = useChangeEmployeeArrearage(handleSuccess);
  const updateClientLevel = useUpdateClientLevel();

  const handleUpdateClientLevel = (level: string) => {
    updateClientLevel.mutate({ level, clientId: record?.clientId });
  };

  useEffect(() => {
    const colorType = document.querySelectorAll('.colorType');
    const loadingColor = document.querySelectorAll('.loadingColor');

    if (tableColor.isLoading) {
      loadingColor.forEach(e => {
        if (Number(e.id) === activeId) {
          e.setAttribute('class', 'anticon anticon-loading anticon-spin loadingColor active');
        }
      });
    } else {
      loadingColor.forEach(e => {
        e.setAttribute('class', 'anticon anticon-loading anticon-spin loadingColor');
      });
    }

    if (tableColor.isSuccess) {
      colorType.forEach(e => {
        if (Number(e.id) === activeId) {
          e.querySelector('circle')?.setAttribute('fill', tableStatusColor[activeType].color ? tableStatusColor[activeType].color : 'transparent');
          e.querySelector('circle')?.setAttribute('stroke', tableStatusColor[activeType].color ? tableStatusColor[activeType].color : '#87898E');
        }
      });
    }

    // eslint-disable-next-line
  }, [tableColor.isLoading, tableColor.isSuccess]);

  useEffect(() => {
    window.addEventListener('click', () => {
      const arrearegeColorAll = document.querySelectorAll('.arrearegeColor');

      arrearegeColorAll.forEach(item => {
        item.setAttribute('class', 'arrearegeColor');
      });
    });
  }, []);

  // handle employee dropdown
  const handleEmployeeDropdown = (open: boolean) => {
    setOpenEmployeeDropDown(open);
  };

  // handle success
  function handleSuccess() {
    handleEmployeeDropdown(false);
    handleOpenDropdown(false);
  }

  const isAccessDeleteSurcharge = permissions?.includes(Permissions.PERMISSION_CANCEL_CONTRACT_SURCHARGE);

  const isAccessSendMessage = permissions?.includes(Permissions.PERMISSION_SEND_MESSAGE_TO_DEBTS);

  const isAccessChangeEmployee = permissions?.includes(Permissions.PERMISSION_CHANGE_CONTRACT_RESPONSIBLE);

  // handleColor
  const handleColor = (e: any, id: number, type: string) => {
    const arrearegeColorAll = document.querySelectorAll('.arrearegeColor');

    setActiveId(id);
    setActiveType(type);
    const loadingColor = e.target.parentElement.parentElement.parentElement.parentElement.querySelector('.loadingColor');

    loadingColor.querySelector('svg').setAttribute('fill', tableStatusColor[type].color);
    arrearegeColorAll.forEach(item => {
      item.setAttribute('class', 'arrearegeColor');
    });

    tableColor.mutate({
      color: type,
      contractId: id,
      tableType: 'TABLE_DEBT'
    });
  };

  // handleTableColor
  const handleTableColor = (e: any) => {
    e.stopPropagation();
    const arrearegeColorAll = document.querySelectorAll('.arrearegeColor');

    arrearegeColorAll.forEach(item => {
      item.setAttribute('class', 'arrearegeColor');
    });

    const arrearegeColor = e.target.parentElement.querySelector('.arrearegeColor');

    arrearegeColor.setAttribute('class', 'arrearegeColor active');
  };

  // openDropdownFunc
  const handleOpenDropdown = (isOpen: boolean) => {
    setOpenDropdown(isOpen);
  };
  // openDropdownFunc
  const handleOpenPopover = (isOpen: boolean) => {
    setOpenPopover(isOpen);
  };

  // handle change employee
  const handleChangeEmployee = (id: number) => {
    changeEmployee.mutate({
      contractId: record.contractId,
      employeeId: id
    });
  };

  return (
    <div className="d-flex align-center arrearegeColorContainer">
      <div className="arrearegeColor" key={record.contractId} onClick={e => e.stopPropagation()}>
        {Object.values(tableStatusColor).map(item =>
          item.type === 'NOTHING' ? (
            <span key={item.color} onClick={e => handleColor(e, record.contractId, item.type)}>
              <SlashIcon />
            </span>
          ) : (
            <span key={item.color} onClick={e => handleColor(e, record.contractId, item.type)}>
              <EllipseIcon background={item.color} stroke={item.color} />
            </span>
          )
        )}
      </div>
      <span style={{ cursor: 'pointer', marginRight: 10 }} onClick={e => handleTableColor(e)} className="colorType" id={String(record.contractId)}>
        {record.color ? (
          <EllipseIcon background={tableStatusColor[record.color].color} stroke={tableStatusColor[record.color].color} style={{ pointerEvents: 'none' }} />
        ) : (
          <EllipseIcon background="transparent" style={{ pointerEvents: 'none' }} />
        )}
        <LoadingOutlined className="loadingColor" id={String(record.contractId)} />
      </span>
      <Dropdown
        visible={openDropdown}
        onVisibleChange={handleOpenDropdown}
        overlayClassName={styles.arrearage_dropdown}
        overlay={
          <>
            {isAccessDeleteSurcharge && (
              <div
                onClick={e => {
                  setSurchargeDeleteModalVisible({
                    id: record.contractId,
                    visible: true
                  });
                  e.stopPropagation();
                  handleOpenDropdown(false);
                }}
              >
                <DeleteAreaArageIcon />
                <span>{t('arrearage.Peniyani_ochirish')}</span>
              </div>
            )}
            {isAccessSendMessage && (
              <div
                onClick={e => {
                  handleSendMessageModalOpen(record.clientId, record.clientName, record.clientPhone, record.contractNumber);
                  e.stopPropagation();
                  handleOpenDropdown(false);
                }}
              >
                <MailAreaArageIcon />
                <span>{t('arrearage.SMS_yuborish')}</span>
              </div>
            )}
            {isAccessChangeEmployee && (
              <Popover
                overlayClassName={styles.container_employee_dropdown}
                content={
                  <Spin spinning={changeEmployee.isLoading || isLoading}>
                    {data?.map((emp, index) => (
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          handleChangeEmployee(emp.id);
                        }}
                        key={index}
                        className={styles.container_employee_dropdown_item}
                      >
                        <Avatar className={styles.container_employee_dropdown_item_avatar} src={`${BASE_URL}/${emp.avatar}`} />
                        <span className={styles.container_employee_dropdown_item_name}>{emp.fullName}</span>
                        {record.responsibleById === emp.id && <CheckIcon />}
                      </div>
                    ))}
                  </Spin>
                }
                onVisibleChange={handleEmployeeDropdown}
                visible={openEmployeeDropDown}
                placement="leftTop"
                trigger={['click']}
              />
            )}
            <Popover
              content={
                <Spin spinning={updateClientLevel?.isLoading}>
                  <div onClick={e => e.stopPropagation()}>
                    <div
                      className="flex cursor-pointer items-center gap-2 px-[5px] py-1.5"
                      onClick={() => {
                        handleUpdateClientLevel('VIP');
                        handleOpenDropdown(false);
                        handleOpenPopover(false);
                      }}
                    >
                      <VipClientIcon />
                      <Tooltip
                        overlayStyle={{ maxWidth: '490px' }}
                        overlay={
                          <div className="flex items-center gap-1">
                            <VipClientIcon />
                            <span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>
                          </div>
                        }
                      >
                        <span className="text-xs font-medium text-[#374151]">{t('home.Vip_mijoz')}</span>
                      </Tooltip>
                    </div>
                    <div
                      className="flex cursor-pointer items-center gap-2 px-[5px] py-1.5"
                      onClick={() => {
                        handleUpdateClientLevel('REGULAR');
                        handleOpenDropdown(false);
                        handleOpenPopover(false);
                      }}
                    >
                      <PremiumClientIcon />
                      <Tooltip
                        overlay={
                          <div className="flex items-center gap-1">
                            <PremiumClientIcon />
                            <span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>
                          </div>
                        }
                        overlayStyle={{ maxWidth: 440 }}
                      >
                        <span className="text-xs font-medium text-[#374151]">{t('home.Premium_mijoz')}</span>
                      </Tooltip>
                    </div>
                    <div
                      className="flex cursor-pointer items-center gap-2 px-[5px] py-1.5"
                      onClick={() => {
                        handleUpdateClientLevel('STANDARD');
                        handleOpenDropdown(false);
                        handleOpenPopover(false);
                      }}
                    >
                      <ClearClientIcon />
                      <span className="text-xs font-medium text-[#374151]">{t('home.Odatiy_mijoz')}</span>
                    </div>
                  </div>
                </Spin>
              }
              placement="leftTop"
              trigger={['click', 'hover']}
              mouseEnterDelay={1000}
              mouseLeaveDelay={0.3}
              visible={openPopover}
              onVisibleChange={handleOpenPopover}
            >
              <div onClick={e => e.stopPropagation()}>
                <ClientStatus />
                <span>{t('home.Mijoz_statusi')}</span>
              </div>
            </Popover>
            <div
              onClick={e => {
                handleTaskCreateModalOpen(true, record.contractId, record.amount, record?.clientId);
                e.stopPropagation();
                handleOpenDropdown(false);
              }}
            >
              <PlusCircleAreaArageIcon />
              <span>{t('one_task.Topshiriq_yaratish')}</span>
            </div>
          </>
        }
        trigger={['click']}
      >
        <Button
          shape="circle"
          className="d-flex align-center justify-center"
          style={{ border: 'none', marginLeft: 10 }}
          onClick={e => {
            e.stopPropagation();
            handleOpenDropdown(false);
          }}
        >
          <MiniMenuIcon />
        </Button>
      </Dropdown>
    </div>
  );
};

export default Action;
