import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import SaleFlatIcon from '@icons/SaleFlatIcon';
import { ShaxmatkaContextDataState } from '@pages/shaxmatka/hooks/ShaxmatkaContext';
import { Button, Dropdown, Menu, Popover, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

// import ChangesIcon from '../../assets/icons/ChangesIcon';
// import Edit from '../../assets/icons/Edit';
import EditHouseCardIcon from '../../assets/icons/houseCard/EditHouseCardIcon';
import SlashCircleHouseCardIcon from '../../assets/icons/houseCard/SlashCircleHouseCardIcon';
import TrushHouseCardIcon from '../../assets/icons/houseCard/TrushHouseCardIcon';
import MiniMenuIcon from '../../assets/icons/MiniMenuIcon';
import RefreshCCWIcon from '../../assets/icons/RefreshCCWIcon';
// import TrushSquare from '../../assets/icons/TrushSquare';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useChangeSaleStatusHouse } from '../../queries/mutation';
import { useBuildingId } from '../../queries/queries';
import { setHouseEditModalOpen, setOpenDeleteModal } from '../../store/reducers/HouseConfigSlice';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';
import { HouseConfigList } from '../../utils/models/House';

import styles from './houseConfig.module.scss';

const { SubMenu } = Menu;

interface Props {
  house: HouseConfigList;
  isSales: boolean;
  setIncrementPrice: React.Dispatch<React.SetStateAction<ShaxmatkaContextDataState>>;
}

const HouseCard: React.FC<Props> = ({ house, isSales, setIncrementPrice }) => {
  const [buildingIds, setBuildingIds] = useState<number[]>([]);
  const [sale, setSale] = useState(!isSales);
  const [houseId, setHouseId] = useState(0);
  const { data: buildingId, isLoading: buildingIdLoading } = useBuildingId(houseId);
  const { searchParams, push, location } = useQueryParam<{ search: string }, any>();
  const { t } = useTranslation();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const changeHouseSaleStatus = useChangeSaleStatusHouse(searchParams.search, !isSales, Number(house.id), sale ? buildingIds : []);

  const dispatch = useAppDispatch();

  const handleOpenEditModal = (e: any) => {
    e.stopPropagation();
    setOpenDropDown(false);
    dispatch(setHouseEditModalOpen(house.id || 0));
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    setOpenDropDown(false);
    dispatch(
      setOpenDeleteModal({
        id: house.id || 0,
        houseName: house.name
      })
    );
  };

  const handleChangeSaleStatus = (e: any) => {
    e?.stopPropagation();
    setOpenDropDown(false);
    changeHouseSaleStatus
      .mutateAsync()
      .then(() => {
        setSubmenuVisible(true);
      })
      .catch(() => {
        setSubmenuVisible(true);
      });
  };

  useEffect(() => {
    if (buildingId && !buildingIds) {
      setBuildingIds(buildingId?.filter(item => item.sale).map(item => item?.id));
    }
  }, [buildingId, buildingIds]);

  const changeSale = (value: number) => {
    if (buildingIds.includes(value)) {
      setBuildingIds(buildingIds?.filter(item => item !== value));
    } else {
      setBuildingIds([...buildingIds, value]);
    }
  };

  useEffect(() => {
    if (buildingId?.length === buildingIds?.length) {
      setSale(false);
    } else {
      setSale(true);
    }
  }, [buildingId, buildingIds]);

  const levelItem: string =
    // eslint-disable-next-line no-nested-ternary
    house.constructionStage === 'FLOOR'
      ? `${house?.constructionFloor} - ${t('home.qavat')}`
      : house?.constructionStage === 'ROOF'
        ? `${t('home.Tom')} ${house?.additionalStages?.length! > 0 ? '|' : ''} ${house?.additionalStages?.map(item => t(`home.${item}`))?.join(' | ')}`
        : t('home.Katlavan');

  return (
    <div className={styles.cards_cont_card} onClick={() => push(`${location.pathname}/${house.id}/${urlEncode(house.name)}`)}>
      <Popover overlayInnerStyle={{ borderRadius: '12px' }} placement="topLeft" content={<span style={{ fontWeight: 600 }}>{house.name}</span>}>
        <h1>{house.name?.length >= 20 ? `${house.name?.slice(0, 18)}...` : house?.name}</h1>
      </Popover>
      <Dropdown
        placement="bottomRight"
        visible={openDropDown}
        onVisibleChange={visible => {
          setOpenDropDown(visible);
          setSubmenuVisible(false);
        }}
        overlayClassName={styles.dropdown}
        overlay={
          <Menu className="dropdown_house_config">
            <Menu.Item key="item1">
              <div
                onClick={e => {
                  e.stopPropagation();
                  setIncrementPrice({
                    incrementPrice: 'HOUSE',
                    houseId: house?.id
                  });
                }}
                className="flex items-center gap-1"
              >
                <SaleFlatIcon /> <span>{t('home.Narxni_ozgartirish')}</span>
              </div>
            </Menu.Item>
            {isSales ? (
              <Menu.Item key="item2">
                <div className="flex items-center gap-1" onClick={handleChangeSaleStatus}>
                  <SlashCircleHouseCardIcon />
                  <span>{t('house_config.Sotuvdan_chiqarish')}</span>
                </div>
              </Menu.Item>
            ) : (
              <SubMenu
                key="sub1"
                popupOffset={[1, 1]}
                popupClassName={`${submenuVisible ? styles.hidden : ''}`}
                className={styles.submenu}
                title={
                  <div
                    onMouseEnter={() => {
                      setHouseId(house?.id || 0);
                    }}
                    className="flex items-center gap-1"
                  >
                    <RefreshCCWIcon />
                    <span>{t('house_config.Sotuvga_chiqarish')}</span>
                    {buildingIdLoading && <LoadingOutlined />}
                  </div>
                }
              >
                <div className="dropdown_house_config" onClick={e => e.stopPropagation()}>
                  <div className={styles.space}>
                    {t('home.Barchasi')}
                    <Switch
                      size="small"
                      checked={!sale}
                      onChange={checked => {
                        setSale(!checked);
                        if (!checked) {
                          setBuildingIds([]);
                        } else {
                          setBuildingIds(buildingId?.map(item => item?.id)!);
                        }
                      }}
                    />
                  </div>
                  {buildingId?.map(item => (
                    <div className={styles.space} key={item?.id}>
                      {item?.name}
                      <Switch
                        size="small"
                        checked={buildingIds?.includes(item?.id)}
                        onChange={e => {
                          changeSale(item?.id);
                        }}
                      />
                    </div>
                  ))}
                  <Button type="primary" size="small" className={styles.saveButton} onClick={e => handleChangeSaleStatus(e)} loading={changeHouseSaleStatus.isLoading}>
                    {t('home.Saqlash')}
                  </Button>
                </div>
              </SubMenu>
            )}
            <Menu.Item key="item3">
              <div className="flex items-center gap-1" onClick={handleOpenEditModal}>
                <EditHouseCardIcon />
                <span>{t('client.Tahrirlash')}</span>
              </div>
            </Menu.Item>
            <Menu.Item key="item4">
              <div className="flex items-center gap-1" onClick={handleDelete}>
                <TrushHouseCardIcon />
                <span>{t('discount_setting.Ochirish')}</span>
              </div>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            setOpenDropDown(!openDropDown);
          }}
          shape="circle"
          className="d-flex align-center justify-center"
          style={{ border: 'none' }}
        >
          <MiniMenuIcon />
        </Button>
      </Dropdown>
      <h2>{house.address}</h2>
      <div className={`d-flex align-center justify-space-between ${styles.cards_cont_value_cont}`}>
        <span className={styles.cards_cont_value_title}>{t('house_config.Xonadonlar_soni')}</span>
        <span className={styles.cards_cont_value}>({house.flatsCount})</span>
      </div>
      <div className={`d-flex align-center justify-space-between ${styles.cards_cont_value_cont}`}>
        <span className={styles.cards_cont_value_title}>{t('house_config.Topshirish_vaqti')}</span>
        <span className={styles.cards_cont_value}>{house.release}</span>
      </div>
      <div className={`d-flex align-center justify-space-between ${styles.cards_cont_value_cont}`}>
        <span className={styles.cards_cont_value_title}>{t('home.Masul')}</span>
        <span className={styles.cards_cont_value}>{house.employee}</span>
      </div>
      {house?.constructionStage && (
        <div className={`flex items-center justify-between ${styles.cards_cont_value_cont}`}>
          <span className={styles.cards_cont_value_title}>{t('home.Bosqich')}</span>
          <p className={`${styles.cards_cont_value} relative w-[75%] overflow-hidden text-ellipsis whitespace-nowrap`}>{levelItem}</p>
        </div>
      )}
    </div>
  );
};

export default HouseCard;
