import { Checkbox, Collapse, Drawer, Space, Spin } from 'antd';
import React, { FC } from 'react';
import '../../../components/filters/filterDrawer.module.scss';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DatePicker from '../../../service/datePicker';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { SearchParamsOrder } from '../Order';
import { useCurrencyList, useHousesNameAndID } from '../../../queries/queries';
import { OrderStatus } from '../../../utils/models/OrderStatus';
import FilterReloadIcon from '../../../assets/icons/FilterReloadIcon';
import ColapseIcon from '../../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../../assets/icons/FIlterColapseIcon';
import { DATE_FORMAT } from '../../../utils/constants/format';
import HouseItem from '../../contract/components/table/houseItem/HouseItem';
import EmployeeFilter from '@pages/contract/components/filter/employee/EmployeeFilter';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

interface OrderDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const OrderFilterDrawer: FC<OrderDrawerProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, appendMultiple, removeAllSearchParams, remove } = useQueryParam<SearchParamsOrder, any>();

  const { data: currencyList } = useCurrencyList();

  const { t } = useTranslation();

  const { isLoading, data: houses } = useHousesNameAndID();

  const onClose = () => {
    setVisible(false);
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
  };

  const changeOrderStatus = (value: OrderStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else {
      if (searchParams.statuses === value) {
        remove('statuses', value);
      } else {
        appendMultiple('statuses', value);
      }
    }
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  //changeCurrency
  const changeCurrency = (value: string) => {
    if (Array.isArray(searchParams.currencies)) {
      searchParams.currencies.includes(value) ? remove('currencies', value) : appendMultiple('currencies', value);
    } else {
      if (searchParams.currencies === value) {
        remove('currencies', value);
      } else {
        appendMultiple('currencies', value);
      }
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className={'filter_drawer'}>
      <Spin spinning={isLoading}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition={'right'}
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Sana')}
              </>
            }
            key="6"
          >
            <RangePicker
              format={DATE_FORMAT}
              value={
                searchParams.startDate && searchParams.finishDate
                  ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)]
                  : [null, null]
              }
              onChange={changeDate}
              className="CustomRangePicker"
              placeholder={[t('client_create_contract.kk_oo_yyyy'), t('client_create_contract.kk_oo_yyyy')]}
            />
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Status')}
              </>
            }
            key="7"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeOrderStatus(OrderStatus.ACTIVE)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(OrderStatus.ACTIVE)
                    : searchParams.statuses === OrderStatus.ACTIVE
                }
              >
                {t(`home.${OrderStatus.ACTIVE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeOrderStatus(OrderStatus.CANCELED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(OrderStatus.CANCELED)
                    : searchParams.statuses === OrderStatus.CANCELED
                }
              >
                {t(`home.${OrderStatus.CANCELED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeOrderStatus(OrderStatus.CONTRACT)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(OrderStatus.CONTRACT)
                    : searchParams.statuses === OrderStatus.CONTRACT
                }
              >
                {t(`home.${OrderStatus.CONTRACT}`)}
              </Checkbox>
            </Space>
          </Panel>
          {currencyList && currencyList?.length > 2 && (
            <Panel
              header={
                <>
                  <FIlterColapseIcon />
                  {t('create_payment.Valyuta')}
                </>
              }
              key="4"
            >
              <Space direction="vertical">
                {currencyList?.map(item => (
                  <Checkbox
                    key={item.id}
                    onChange={() => changeCurrency(String(item.id))}
                    checked={
                      Array.isArray(searchParams.currencies)
                        ? searchParams.currencies.includes(String(item.id))
                        : searchParams.currencies === String(item.id)
                    }
                  >
                    {item.ccy}
                  </Checkbox>
                ))}
              </Space>
            </Panel>
          )}
          <Panel
            className={'house_item'}
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turar_joy_majmuasi')}
              </>
            }
            key="8"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {houses?.map((house, index) => <HouseItem key={index} id={house.id || -1} name={house.name} />)}
            </Space>
          </Panel>
          <Panel
            className="colorPanel"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Xodimlar')}
              </>
            }
            key="10"
          >
            <EmployeeFilter ids={searchParams.responsibleByIds} />
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default OrderFilterDrawer;
