import React, { useEffect, useState } from 'react';
import TagSelectV2 from '@components/tagSelect/TagSelectV2';
import TextEditable from '@components/text-editable/TextEditable';
import ArrowDownIcon from '@icons/ArrowDownIcon';
import { useCrmEmployee } from '@queries/queries';
import { Button, Checkbox, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditPipeOnlinePbxPipeStatus } from '../../../../../queries/mutation';
import { OnlinePbx, OnlinePbxPipes } from '../../../../../utils/models/OnlinePbx';

import styles from './onlinePBX.module.scss';

interface Props {
  onlinePbx: OnlinePbx | null;
  onlinePbxPipe: OnlinePbxPipes;
  close: (pipeSettings: OnlinePbxPipes | undefined) => void;
}

const CreateAutoContract: React.FC<Props> = ({ onlinePbxPipe, close, onlinePbx }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { data: employee } = useCrmEmployee();
  const [missingLeadNameTag, setMissingLeadNameTag] = useState('');
  const [incomingLeadNameTag, setIncomingLeadNameTag] = useState('');
  const [outgoingLeadNameTag, setOutgoingtLeadNameTag] = useState('');

  // on success edit pipe config
  function handleSuccess() {
    close(undefined);
  }

  const editPipeSettings = useEditPipeOnlinePbxPipeStatus(onlinePbx?.id || -1, handleSuccess);

  useEffect(() => {
    if (onlinePbxPipe) {
      const onlinePbxContactIds: number[] = [];

      onlinePbxPipe.onlinePbxContactDtos.forEach(item => {
        onlinePbxContactIds.push(item.id);
      });

      form.setFieldsValue({
        pipeId: onlinePbxPipe.pipeDto.id,
        onlinePbxConfigId: onlinePbxPipe.onlinePbxConfigId,
        id: onlinePbxPipe.id,
        pipeStatusId: onlinePbxPipe.pipeStatusResponse?.id,
        addNumberTag: onlinePbxPipe.addNumberTag,
        autoTask: onlinePbxPipe.autoTask,
        callTypeTag: onlinePbxPipe.callTypeTag,
        failedCall: onlinePbxPipe.failedCall,
        incomingCall: onlinePbxPipe.incomingCall,
        outComingCall: onlinePbxPipe.outComingCall,
        recordingCall: onlinePbxPipe.recordingCall,
        responsibleUserId: onlinePbxPipe.responsibleUserId,
        incomingTags: onlinePbxPipe.incomingTags?.map(item => item?.name),
        outgoingTags: onlinePbxPipe.outgoingTags?.map(item => item?.name),
        missingTags: onlinePbxPipe.missingTags?.map(item => item?.name),
        onlinePbxContactIds,
        incomingPipeStatusId: onlinePbxPipe.incomingPipeStatus?.id,
        outgoingPipeStatusId: onlinePbxPipe.outgoingPipeStatus?.id,
        missingPipeStatusId: onlinePbxPipe.missingPipeStatus?.id,
        incomingLeadName: onlinePbxPipe.incomingLeadName,
        outgoingLeadName: onlinePbxPipe.outgoingLeadName,
        missingLeadName: onlinePbxPipe.missingLeadName
      });
      setIncomingLeadNameTag(onlinePbxPipe.incomingLeadName);
      setOutgoingtLeadNameTag(onlinePbxPipe.outgoingLeadName);
      setMissingLeadNameTag(onlinePbxPipe.missingLeadName);
    }
  }, [onlinePbxPipe, form]);

  const handleSaveData = (values: any) => {
    const onlinePbxContactIds: number[] = [];

    onlinePbxPipe.onlinePbxContactDtos.forEach(item => {
      onlinePbxContactIds.push(item.id);
    });

    // Объединяем теги, если они присутствуют в значениях
    const incomingTags = values?.incomingTags?.join();
    const missingTags = values?.missingTags?.join();
    const outgoingTags = values?.outgoingTags?.join();

    editPipeSettings.mutate({
      ...values,
      incomingLeadName: incomingLeadNameTag,
      outgoingLeadName: outgoingLeadNameTag,
      missingLeadName: missingLeadNameTag,
      pipeId: onlinePbxPipe.pipeDto.id,
      onlinePbxConfigId: onlinePbxPipe.onlinePbxConfigId,
      pipeStatusId: onlinePbxPipe.pipeStatusResponse?.id,
      callTypeTag: onlinePbxPipe.callTypeTag,
      responsibleUserId: values.responsibleUserId,
      id: onlinePbxPipe.id,
      onlinePbxContactIds,
      incomingTags,
      missingTags,
      outgoingTags
    });
  };

  // failedCall: onlinePbxPipe.failedCall,

  const pipeSelect = onlinePbxPipe?.pipeDto;

  const leadNameContants = {
    key: '&phone&',
    label: t('home.Mijoz_raqami')
  };

  return (
    <div>
      <Form onFinish={handleSaveData} form={form}>
        <div>
          <Form.Item name="incomingCall" valuePropName="checked" className={styles.container_body_config_check_box_cont} style={{ marginTop: '18px', marginBottom: '13px' }}>
            <Checkbox>{t('home.Kiruvchi_qongiroqlar_uchun_bitim_yaratish')}</Checkbox>
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Bosqich')}</h3>
              <Form.Item name="incomingPipeStatusId" style={{ width: 170 }}>
                <Select className="customSelect" suffixIcon={<ArrowDownIcon />}>
                  {pipeSelect?.pipeStatuses?.map(item => (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Teglar')}</h3>
              <Form.Item name="incomingTags" style={{ width: 170 }}>
                <TagSelectV2 />
              </Form.Item>
            </div>

            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Lid_nomi')}</h3>
              <Form.Item style={{ width: 170 }}>
                <TextEditable dropdown={true} fields={[leadNameContants]} onInput={setIncomingLeadNameTag} value={incomingLeadNameTag} id="1" />
              </Form.Item>
            </div>
          </div>

          <hr style={{ marginTop: '10px' }} />
        </div>

        <div>
          <Form.Item name="outComingCall" valuePropName="checked" className={styles.container_body_config_check_box_cont} style={{ marginTop: '18px', marginBottom: '13px' }}>
            <Checkbox>{t('home.Chiquvchi_qongiroqlar_uchun_bitim_yaratish')}</Checkbox>
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Bosqich')}</h3>
              <Form.Item name="outgoingPipeStatusId" style={{ width: 170 }}>
                <Select className="customSelect" suffixIcon={<ArrowDownIcon />}>
                  {pipeSelect?.pipeStatuses?.map(item => (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Teglar')}</h3>
              <Form.Item name="outgoingTags" style={{ width: 170 }}>
                <TagSelectV2 />
              </Form.Item>
            </div>

            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Lid_nomi')}</h3>
              <Form.Item style={{ width: 170 }}>
                <TextEditable dropdown={true} fields={[leadNameContants]} value={outgoingLeadNameTag} onInput={setOutgoingtLeadNameTag} id="2" />
              </Form.Item>
            </div>
          </div>

          <hr style={{ marginTop: '10px' }} />
        </div>

        <div>
          <Form.Item name="failedCall" valuePropName="checked" className={styles.container_body_config_check_box_cont} style={{ marginTop: '18px', marginBottom: '13px' }}>
            <Checkbox>{t('home.Qabul_qilinmagan_qongiroqlar_uchun_bitim_yaratish')}</Checkbox>
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Bosqich')}</h3>
              <Form.Item name="missingPipeStatusId" style={{ width: 170 }}>
                <Select className="customSelect" suffixIcon={<ArrowDownIcon />}>
                  {pipeSelect?.pipeStatuses?.map(item => (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Teglar')}</h3>
              <Form.Item name="missingTags" style={{ width: 170 }}>
                <TagSelectV2 />
              </Form.Item>
            </div>

            <div>
              <h3 className={styles.container_body_config_check_box_cont_title}>{t('home.Lid_nomi')}</h3>
              <Form.Item style={{ width: 170 }}>
                <TextEditable dropdown={true} fields={[leadNameContants]} onInput={setMissingLeadNameTag} value={missingLeadNameTag} id="3" />
              </Form.Item>
            </div>
          </div>

          <hr style={{ marginTop: '10px' }} />
        </div>

        <div>
          <Form.Item name="recordingCall" valuePropName="checked" className={styles.container_body_config_check_box_cont}>
            <Checkbox>{t('online_pbx.Qongiroqlarni_yozib_olish')}</Checkbox>
          </Form.Item>

          <Form.Item name="addNumberTag" valuePropName="checked" className={styles.container_body_config_check_box_cont}>
            <Checkbox>{t('online_pbx.Tashqi_raqam_teg_sifatida_qoshish')}</Checkbox>
          </Form.Item>

          <Form.Item name="autoTask" className={styles.container_body_config_check_box_cont} valuePropName="checked">
            <Checkbox>{t('online_pbx.Qabul_qilinmagan_qongiroq_uchun_topshiriq_yaratish')}</Checkbox>
          </Form.Item>

          <Form.Item name="responsibleUserId" style={{ marginTop: '10px', width: 350 }}>
            <Select className="customSelect" suffixIcon={<ArrowDownIcon />} allowClear>
              {employee?.map(item => (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.fullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className={styles.container_body_modalFooter}>
          <Button loading={editPipeSettings.isLoading} onClick={handleSaveData} htmlType="submit" type="primary">
            {t('online_pbx.Saqlash')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateAutoContract;
