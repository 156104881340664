import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { queryKeys } from '../utils/constants/queryKeys';

export function useDeleteMessageSMSBot() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.DELETE_MSG_SMSBOT + '/' + id);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.GET_MSG_SMSBOT]);
      },
      onError: baseErrorHandler
    }
  );
}
