import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import CalendarIcon from '@icons/CalendarIcon';
import SearchIcon from '@icons/SearchIcon';
import TelPlusIcon from '@icons/TelPlusIcon';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import ConnectLeadModal from '@pages/createOrder/components/connect-lead/ConnectLeadModal';
import CreateLeadModal from '@pages/createOrder/components/create-lead/CreateLeadModal';
import { useGetForceData } from '@pages/crm-force/services/queries';
import { useLeadSave } from '@queries/mutation';
import { useGetDistrict, useRegions, useSearchClientBuPassportData } from '@queries/queries';
import { addClientPhone, checkClientPhone, deleteClientPhones, setClientChangesPhone, setSearchClient } from '@reducers/ChangesSlice';
import { changeCountryCode } from '@reducers/ClientSlice';
import i18n from '@service/I18nService';
import { getCurrentLanguageWord } from '@utils/helper/getCurrentLanguageWord';
import { hasKril } from '@utils/helper/hasKril';
import { helper } from '@utils/helper/helper';
import { validateDateString } from '@utils/helper/validatorDateString';
import { phoneValidator } from '@utils/index';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import { ChangesQueryParams } from '@utils/models/Changes';
import { ClientPhone, NaturalPersonCreate } from '@utils/models/Client';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { TypeSearchClientByPassport } from '@utils/models/typeSearchClientByPassport';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { useCreateAndUpdateNaturalPersonChanges } from '../../services/mutation';

import ClientLead from './ClientLead';

import img from '../../../../assets/img/addLead.png';

import 'react-international-phone/style.css';
import styles from './client.module.scss';

const { Option } = Select;

export interface FormAddress {
  passportSerial: string;
  passportNumber: string;
  dateBirth: string;
  passport: string;
}

export interface FormMainData {
  phone0: string;
  phone1: string;
  phone2: string;
  phone3: string;
  phone4: string;
  code0: string;
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  districtId: number;
  address: string;
  cityID: number;
  firstname: string;
  lastname: string;
  fatherName: string;
  passportPlace: string;
  passportDate: string;
  passportExpiredDate: string;
  workPlace: string;
  inn: string;
  pinfl: string;
  countryCode: string;
  country2: string;
}

const NaturalClient = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientPhones = useAppSelector(state => state.changesReducer.clientPhones);
  const selectedClient = useAppSelector(state => state.changesReducer.selectedClient as NaturalPersonCreate);
  const [krilAlpha, setKrilAlpha] = useState(false);
  const [form] = Form.useForm<FormMainData>();
  const [formPassport] = Form.useForm<FormAddress>();
  const [country2, setCountry2] = useState<string>('uz');
  const [country2Id, setCountry2Id] = useState<number>();
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);
  const [selectLead, setSelectLead] = useState<ClientLeadModel | undefined>();
  const { leadContact, leadName, leadContactName } = useAppSelector(state => state.clientReducer);

  useEffect(() => {
    if (selectedClient?.phones[0]?.code) {
      dispatch(changeCountryCode(selectedClient?.phones[0]?.code));
    } else {
      dispatch(changeCountryCode('UZ'));
    }
  }, [selectedClient]);

  const updateNewLead = useLeadSave();

  const handleOpenLeadModal = () => {
    setOpenLeadModal(true);
  };

  const [searchData, setSearchData] = useState({
    passportSerial: '',
    passportNumber: '',
    dateBirth: ''
  });

  const [typeAddress, setTypeAddress] = useState<{
    id: number;
    type: 'district' | 'region';
  }>({
    id: -1,
    type: 'region'
  });

  const currentClientLead = (selectLead && (selectedClient?.lead === null || selectedClient?.lead === undefined)) || selectedClient === null ? selectLead : selectedClient?.lead;

  const isCliendLead = !!selectedClient?.lead;

  const { data: force } = useGetForceData();
  const { data: regions, isLoading: loadingRegions } = useRegions(country2Id);
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(typeAddress.id, typeAddress.type);

  const { isLoading } = useSearchClientBuPassportData(searchData.passportSerial, searchData.passportNumber, searchData.dateBirth, TypeSearchClientByPassport.CHANGES);

  const createAndUpdateClient = useCreateAndUpdateNaturalPersonChanges();

  useEffect(() => {
    if (searchParams?.clientId) {
      setSelectLead(prev => prev);
    } else {
      setSelectLead(undefined);
    }
  }, [searchParams?.clientId]);

  const onFinish = (values: FormAddress) => {
    setSearchData(values);
  };

  const contactsCopy = (contacts?: any[]) => {
    const arr = [...(contacts || [])];

    if (arr.length === 0) {
      arr.push({ name: leadContactName, phones: [leadContact] });
    } else {
      arr[0].name = leadContactName;
      arr[0].phones = [leadContact];
    }

    return arr;
  };

  const handleSubmit = async () => {
    Promise.all([form.validateFields(), formPassport.validateFields()]).then(values => {
      const newPhones: ClientPhone[] = [];

      for (let i = 0; i < clientPhones.length && i < 5; i++) {
        newPhones.push({
          ...clientPhones[i],
          phone: String(values[0][`phone${i + 1}` as keyof (typeof values)[0]])?.includes('+')
            ? String(`${values[0][`phone${i + 1}` as keyof (typeof values)[0]]}`)
            : String(`+${values[0][`phone${i + 1}` as keyof (typeof values)[0]]}`),
          code: String(values[0][`code${i + 1}` as keyof (typeof values)[0]])
        });
      }
      const newClientData: NaturalPersonCreate = {
        phones: newPhones,
        firstname: values[0].firstname,
        lastname: values[0].lastname,
        fatherName: values[0].fatherName,
        dateBirth: values[1].dateBirth,
        workPlace: values[0].workPlace,
        passportPlace: values[0].passportPlace,
        passportDate: values[0].passportDate,
        passportExpiredDate: values[0].passportExpiredDate,
        districtId: values[0].districtId,
        address: values[0].address,
        passportSerial: values[1].passportSerial,
        passportNumber: values[1].passportNumber,
        id: selectedClient?.id,
        inn: values[0]?.inn,
        pinfl: values[0]?.pinfl,
        countryCode: country2,
        leadId: selectLead ? selectLead?.id : currentClientLead?.id
      };

      createAndUpdateClient.mutateAsync(newClientData).then(() => {
        const updateLidData = {
          id: currentClientLead?.id,
          contacts: contactsCopy(currentClientLead?.contacts),
          balance: currentClientLead?.balance,
          responsibleById: currentClientLead?.responsibleById,
          pipeStatusId: currentClientLead?.pipeStatusResponse?.id,
          tagIds: currentClientLead?.tags?.map(item => item?.id),
          fields: currentClientLead?.fields,
          name: leadName,
          autoClosedLeadTask: false
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        currentClientLead?.isDefaultCreate && updateNewLead.mutateAsync(updateLidData as any);
      });
    });
  };

  const resetForm = () => {
    form.setFieldsValue({
      firstname: undefined,
      lastname: undefined,
      phone0: '',
      phone1: '',
      phone2: '',
      phone3: '',
      phone4: '',
      fatherName: undefined,
      passportDate: '',
      passportExpiredDate: '',
      passportPlace: undefined,
      districtId: undefined,
      cityID: undefined,
      address: undefined,
      workPlace: undefined,
      inn: '',
      pinfl: '',
      countryCode: 'uz'
    });
    formPassport.setFieldsValue({
      passportSerial: '',
      passportNumber: '',
      dateBirth: '',
      passport: ''
    });
  };

  useEffect(() => {
    if (selectedClient?.id || selectedClient?.passportNumber) {
      form.setFieldsValue({
        lastname: selectedClient?.lastname,
        firstname: selectedClient?.firstname,
        fatherName: selectedClient?.fatherName,
        address: selectedClient?.address,
        districtId: selectedClient?.districtId,
        cityID: selectedClient?.cityID,
        passportDate: selectedClient?.passportDate,
        passportExpiredDate: selectedClient?.passportExpiredDate,
        workPlace: selectedClient?.workPlace,
        passportPlace: selectedClient?.passportPlace,
        inn: selectedClient?.inn,
        pinfl: selectedClient?.pinfl,
        countryCode: selectedClient?.countryCode?.toLowerCase()
      });
      formPassport.setFieldsValue({
        passportSerial: selectedClient?.passportSerial,
        passportNumber: selectedClient?.passportNumber,
        dateBirth: selectedClient?.dateBirth,
        passport: selectedClient?.passportSerial
      });

      const country = countrCode?.find(c => c.code === selectedClient?.countryCode?.toLowerCase());

      if (country) {
        setCountry2Id(country?.index + 1);
      }

      for (let i = 0; i < selectedClient.phones.length; i++) {
        form.setFieldsValue({
          [`phone${i + 1}`]: selectedClient.phones[i].phone,
          [`code${i + 1}`]: selectedClient.phones[i]?.code ? selectedClient.phones[i]?.code : 'UZ'
        });
      }
      if (selectedClient.districtId) {
        setTypeAddress({
          id: selectedClient.districtId,
          type: 'district'
        });
      }
    } else {
      resetForm();
    }
  }, [selectedClient]);

  const changeRegion = (value: number) => {
    form.setFieldsValue({
      districtId: undefined
    });
    setTypeAddress({
      type: 'region',
      id: value
    });
  };

  const changeCountry2 = (value: string) => {
    form.setFieldsValue({
      country2: 'uz'
    });
    setCountry2(value);
  };

  const handleAddClientPhone = () => {
    dispatch(addClientPhone());
  };

  const handleRemoveClientPhone = (index: number) => {
    for (let i = 0; i < 5; i++) {
      if (index === i) {
        form.setFieldsValue({
          [`phone${i}`]: '',
          [`code${i}`]: ''
        });
      } else if (i > index) {
        form.setFieldsValue({
          [`phone${i - 1}`]: form.getFieldValue(`phone${i}`),
          [`code${i - 1}`]: form.getFieldValue(`code${i}`)
        });
      }
    }
    form.setFieldsValue({
      [`phone4`]: '',
      [`code4`]: ''
    });
    dispatch(deleteClientPhones(index));
    setSelectLead(undefined);
  };

  useEffect(() => {
    if (Object.keys(searchParams).length === 0) {
      resetForm();
    }
  }, [searchParams]);

  const handleCheckedPhone = (index: number) => {
    dispatch(checkClientPhone(index));
  };

  const translateWords = (wordObj: BaseLanguageEntity) => getCurrentLanguageWord(wordObj);

  // handleSearchClient
  const handleSearchClient = (phone: string, index: number) => {
    dispatch(setClientChangesPhone({ index, phone }));
    const phoneNumber = helper.deleteNotNumbers(phone, 1);

    if (index === 0 && String(phoneNumber).length >= 4) {
      dispatch(setSearchClient(String(phoneNumber)));
    }
  };

  return (
    <div>
      <Form form={formPassport} labelCol={{ span: 24 }} name="naturalClientPassport" initialValues={{ remember: true }} onFinish={onFinish} layout="horizontal">
        <Row className={styles.cont_row} gutter={8}>
          <Col span={12}>
            <Form.Item
              name="passport"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.NaturalClient.Passport_ma'lumotlari_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Passport_seriya_va_raqami`)}</span>}
            >
              <Space style={{ alignItems: 'flex-start' }} direction="horizontal">
                <Tooltip
                  overlay="Passport seriyasi kiril alifbosida kiritildi.
                    Ma'lumot topilmasligi mumkin!"
                  visible={krilAlpha}
                >
                  <Form.Item
                    name="passportSerial"
                    noStyle
                    rules={[
                      { required: true, message: '' },
                      {
                        validator: (_, value) => {
                          if (value) {
                            formPassport.setFieldsValue({
                              passportSerial: value.toUpperCase().slice(0, 4)
                            });
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input
                      className="customInput"
                      style={{ width: '100%', textTransform: 'uppercase' }}
                      onChange={e => {
                        setKrilAlpha(hasKril(e.target.value));
                      }}
                    />
                  </Form.Item>
                </Tooltip>
                <Form.Item noStyle rules={[{ required: true, message: '' }]} name="passportNumber">
                  <InputMask
                    mask="99999999999999"
                    // @ts-ignore*
                    maskChar=""
                  >
                    {/* @ts-ignore */}
                    {(inputProps: any) => <Input className="customInput" style={{ width: 235, marginRight: '17px' }} {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row className={styles.cont_date_birth} gutter={[8, 0]}>
              <Col span={16}>
                <Form.Item
                  name="dateBirth"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          formPassport.setFieldsValue({
                            dateBirth: validateDateString(value, false)
                          });
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Tugilgan_sana')}</span>}
                >
                  <InputMask
                    mask="99.99.9999"
                    // @ts-ignore*
                    maskChar=""
                  >
                    {/* @ts-ignore */}
                    {(inputProps: any) => <Input className="customInput" placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
              {!selectedClient && (
                <Col span={8}>
                  <Button loading={isLoading} className={styles.cont_search_button} type="primary" htmlType="submit">
                    <SearchIcon />
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
      <Form form={form} name="naturalClientMain" initialValues={{ remember: true }} labelCol={{ span: 24 }} autoComplete="off">
        <h2>{t(`client.Mijoz_ma’lumotlari`)}</h2>
        <Row className={styles.cont_row} gutter={8}>
          <Col span={12}>
            <Form.Item
              name="lastname"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.NaturalClient.Familiya_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Familiya`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="firstname"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.NaturalClient.Ism_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Ism`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="fatherName"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.NaturalClient.Sharifi_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Sharifi`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          {clientPhones.map((phone, index) => (
            <Space direction="horizontal" className="d-flex" key={phone?.id}>
              <div>
                <Form.Item name={`code${index + 1}`} className="d-none" />

                <div className="flex flex-col gap-[5px]">
                  <Form.Item label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>} name={`phone${index + 1}`} rules={[phoneValidator()]}>
                    <InternationalPhoneNumber
                      defaultCountry="uz"
                      className={styles.phoneInput2}
                      onChange={(e, phone) => {
                        form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });

                        const searchPhone = e?.replace(`${phone?.country?.dialCode}`, '');

                        handleSearchClient(searchPhone, index);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="ml-[-70px]">
                <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
                {index !== 0 && (
                  <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                    <CloseOutlined />
                  </Button>
                )}
              </div>
            </Space>
          ))}

          {clientPhones.length < 5 && (
            <Col span={12}>
              <p onClick={handleAddClientPhone} className={styles.clientPhonePlus}>
                <TelPlusIcon />
                {t(`changes.client.NaturalClient.Telefon_raqam_qo'shish`)}
              </p>
            </Col>
          )}
        </Row>
        <h2>{t(`client.Pasport_malumotlari`)}</h2>
        <Row className={styles.cont_row} gutter={8}>
          <Col span={12}>
            <Form.Item
              name="passportPlace"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.NaturalClient.Berilgan_joyi_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Berilgan_joyi`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="passportDate"
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      form.setFieldsValue({
                        passportDate: validateDateString(value)
                      });
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Berilgan_sana`)}</span>}
            >
              <InputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar=""
              >
                {/* @ts-ignore */}
                {(inputProps: any) => (
                  <Input className="customInput" placeholder={t('client_create_contract.kk_oo_yyyy')} style={{ width: '95%' }} suffix={<CalendarIcon />} {...inputProps} />
                )}
              </InputMask>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="passportExpiredDate" label={<span className={styles.formLabel}>{t(`home.Amal_qilish_muddati`)}</span>}>
              <InputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar=""
              >
                {/* @ts-ignore */}
                {(inputProps: any) => (
                  <Input className="customInput" placeholder={t('client_create_contract.kk_oo_yyyy')} style={{ width: '95%' }} suffix={<CalendarIcon />} {...inputProps} />
                )}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>
        <h2>{t(`client.Yashash_manzili_malumotlari`)}</h2>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item name="countryCode" label={<span className={styles.formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
              <Select
                showSearch
                optionFilterProp="children"
                value={country2}
                onChange={(e, option: any) => {
                  changeCountry2(e);
                  setCountry2Id(option?.props);
                }}
              >
                {countrCode?.map(item => (
                  <Option key={item.code} value={item?.code} props={item?.index + 1}>
                    {item[`country_${i18n.language}` as keyof typeof item]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {country2 === 'uz' || country2 === 'tj' ? (
            <>
              <Col span={8}>
                <Form.Item
                  name="cityID"
                  rules={[
                    {
                      required: true,
                      message: `${t(`changes.client.NaturalClient.Viloyat_kiritilmagan`)}`
                    }
                  ]}
                  label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Viloyat`)}</span>}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    className="customSelect"
                    loading={loadingRegions}
                    onChange={changeRegion}
                    placeholder={`${t(`changes.client.NaturalClient.Hududni_tanlang`)}`}
                    style={{ width: '95%' }}
                  >
                    {regions?.map(region => (
                      <Option key={region.id} value={region.id}>
                        {translateWords(region)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="districtId"
                  rules={[
                    {
                      required: true,
                      message: `${t(`changes.client.NaturalClient.Shahar/tuman_kiritilmagan`)}`
                    }
                  ]}
                  label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Shahar/tuman`)}</span>}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    className="customSelect"
                    loading={districtsLoading}
                    placeholder={`${t(`changes.client.NaturalClient.Hududni_tanlang`)}`}
                    style={{ width: '95%' }}
                  >
                    {districts?.map(district => (
                      <Option key={district.id} value={district.id}>
                        {translateWords(district)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={12} />
          )}
          <Col span={12}>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: `${t(`changes.client.NaturalClient.Manzil_kiritilmagan`)}`
                }
              ]}
              label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Uy`)}</span>}
            >
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="workPlace" label={<span className={styles.formLabel}>{t(`changes.client.NaturalClient.Ish_joyi`)}</span>}>
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="inn" label={<span className={styles.formLabel}> {t('client.INN')}</span>}>
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="pinfl" label={<span className={styles.formLabel}>PINF</span>}>
              <Input className="customInput" style={{ width: '95%' }} />
            </Form.Item>
          </Col>
        </Row>
        {force?.status && (
          <>
            <div className={styles.connectLead}>
              <span className={styles.connectLead_title}>{t('one_lid.Lead_malumotlari')}</span>
              {currentClientLead ? (
                <ClientLead currentClientLead={currentClientLead} setSelectLead={setSelectLead} isCliendLead={isCliendLead} />
              ) : (
                <Form.Item name="test" rules={[{ required: true, message: 'test' }]}>
                  <div className={styles.connectLead_body}>
                    <img src={img} alt="err" />
                    <span onClick={handleOpenLeadModal}>{t('one_lid.lid_biriktirish')}</span>
                  </div>
                </Form.Item>
              )}
            </div>
            <ConnectLeadModal
              visible={openLeadModal}
              setVisible={setOpenLeadModal}
              setSelectLead={setSelectLead}
              clientId={selectedClient?.id || 0}
              setVisibleCreate={setOpenCreateModal}
            />
            {/* @ts-expect-error */}
            <CreateLeadModal visible={openCreateModal} setVisible={setOpenCreateModal} setSelectLead={setSelectLead} />
          </>
        )}
        <Button loading={createAndUpdateClient.isLoading} htmlType="button" onClick={handleSubmit} className={styles.save_button} type="primary">
          {selectedClient?.id ? `${t(`changes.client.NaturalClient.Saqlash`)}` : `${t(`changes.client.NaturalClient.Yaratish`)}`}
        </Button>
      </Form>
    </div>
  );
};

export default NaturalClient;
