import { useMutation, useQueryClient } from 'react-query';
import { showMessage } from '../../../../../../../queries/showMessage';
import { baseErrorHandler } from '../../../../../../../queries/baseErrorHandler';
import { $api } from '../../../../../../../service/RequestService';
import { endPoints } from '../constants/endPoints';
import { queryKeys } from '../constants/queryKeys';
import { SipuiAuth } from '../models/SipuiAuth';
import { SipEmployee } from '../models/SipEmployee';
import { SipContact } from '../models/SipContact';
import { SipCallHandling } from '../models/SipCallHandling';

export function useSipuniConfigAuth() {
  const qc = useQueryClient();
  return useMutation(
    async (data: SipuiAuth) => {
      const res = await $api.post(endPoints.SIPUNI_CONFIG_AUTH, data);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSipuniConfigEmployee() {
  const qc = useQueryClient();
  return useMutation(
    async (data: SipEmployee) => {
      const res = await $api.post(endPoints.SIPUNI_CONFIG_EMPLOYEE, data);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_EMPLOYEE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSipuniConfigEmployeeDelete() {
  const qc = useQueryClient();
  return useMutation(
    async (data: { id: number; sEmployeeId: number }) => {
      const res = await $api.delete(endPoints.SIPUNI_CONFIG + '/' + data.id + '/employee/' + data.sEmployeeId);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_EMPLOYEE]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSipuniConfigContact() {
  const qc = useQueryClient();
  return useMutation(
    async (data: SipContact) => {
      const res = await $api.post(endPoints.SIPUNI_CONFIG_CONTACT, data);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_CONTACT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSipuniConfigCallHandling() {
  const qc = useQueryClient();
  return useMutation(
    async (data: SipCallHandling) => {
      const res = await $api.post(endPoints.SIPUNI_CONFIG_CALL_HANDLING, data);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_CALL_HANDLING]);
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_CONTACT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSipuniConfigCallHandlingDelete() {
  const qc = useQueryClient();
  return useMutation(
    async (data: { id: number; sCallHandlingId: number }) => {
      const res = await $api.delete(endPoints.SIPUNI_CONFIG + '/' + data.id + '/call-handling/' + data.sCallHandlingId);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_CALL_HANDLING]);
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG_CONTACT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useSipuniConfigLogOut() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.SIPUNI_CONFIG_LOGOUT + '/' + id);
      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.SIPUNI_CONFIG]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}
