import React from 'react';

interface Props {
  className?: string;
}

const TimerIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8333 8.83333C13.8333 12.0533 11.22 14.6667 7.99996 14.6667C4.77996 14.6667 2.16663 12.0533 2.16663 8.83333C2.16663 5.61333 4.77996 3 7.99996 3C11.22 3 13.8333 5.61333 13.8333 8.83333Z"
        stroke="#FFB743"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 5.33301V8.66634" stroke="#FFB743" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 1.33301H10" stroke="#FFB743" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TimerIcon;
