import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';

import AccessDenied from '../../components/accessDenied/AccessDenied';
import NavBar from '../../components/navbars/boss/NavBar';
import bossPageRoutes from './bossPageRoutes';
import Loading from '@components/loading/Loading';
import ErrorBoundary from '@components/error-boundary/ErrorBoundary';

const BossPageRouter = () => {
  return (
    <div>
      <Layout style={{ height: '100vh' }}>
        <NavBar />
        <Layout>
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Routes>
                {bossPageRoutes.map(route => (
                  <Route element={<route.component />} path={route.exact ? route.path : `${route.path}/*`} key={route.path} />
                ))}
                <Route path={'*'} element={<AccessDenied />} />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </Layout>
      </Layout>
    </div>
  );
};

export default BossPageRouter;
