import React from 'react';

import styles from './sendLogin.module.scss';
import { Button, Form, Input } from 'antd';
import { helper } from '../../../utils/helper/helper';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '../../../assets/icons/contract/ArrowLeftIcon';
import { rootPaths } from '../../../routing/rootPaths';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useSendCodeChangePassword } from '../../../queries/mutation';

interface Props {
  changePage: (page: number) => void;
  handleUsername: (val: { phone: string; username: string }) => void;
}

const SendLogin: React.FC<Props> = ({ changePage, handleUsername }) => {
  const [form] = Form.useForm<{ username: string }>();
  const { push } = useQueryParam();

  const { t } = useTranslation();

  const sendCode = useSendCodeChangePassword(handleSuccess);

  // handle success
  function handleSuccess(data: { phone: string; username: string }) {
    handleUsername(data);
    form.resetFields();
    changePage(2);
  }

  // onFinish
  const onFinish = (value: { username: string }) => {
    sendCode.mutate(value.username);
  };

  // handle click back
  const handleClickBack = () => {
    push(rootPaths.INDEX);
  };

  return (
    <div className={styles.container}>
      <h2>
        <ArrowLeftIcon onClick={handleClickBack} />
        {t('profile.Hisobni_qayta_tiklash')}
      </h2>
      <p>{t('profile.Parolni_tiklamoqchi_bolgan_hisobingizni')}</p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label={<span className={styles.container_title}>{t('login.Login')}</span>}
          name="username"
          rules={[
            { required: true, message: '' },
            {
              validator: (rule, value) => {
                form.setFieldsValue({
                  username: helper.deleteSpace(value)
                });
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button loading={sendCode.isLoading} type="primary" htmlType="submit" className={styles.container_button}>
            {t('profile.Keyingi')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SendLogin;
