const LeaveFromTokenIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0669 5.04016C9.86023 2.64016 8.62689 1.66016 5.92689 1.66016H5.84023C2.86023 1.66016 1.66689 2.85349 1.66689 5.83349V10.1802C1.66689 13.1602 2.86023 14.3535 5.84023 14.3535H5.92689C8.60689 14.3535 9.84023 13.3868 10.0602 11.0268"
        stroke="#FF3F40"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.99976 8H13.5864" stroke="#FF3F40" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.1002 5.76758L14.3335 8.00091L12.1002 10.2342" stroke="#FF3F40" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

export default LeaveFromTokenIcon;
