import React from 'react';
import SearchIcon from '../../assets/icons/SearchIcon';
import styles from './houseConfig.module.scss';
import { Button, Input } from 'antd';
import { useQueryParam } from '../../hooks/useQueryParams';
import PlusIcon from '../../assets/icons/PlusIcon';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setHouseCreateModalOpen } from '../../store/reducers/HouseConfigSlice';
import { t } from 'i18next';
import { CloseOutlined } from '@ant-design/icons';

const HeaderHouseConfig = () => {
  const { searchParams, remove, appendSingle } = useQueryParam<
    {
      search: string;
    },
    any
  >();
  const dispatch = useAppDispatch();

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const openModal = () => {
    dispatch(setHouseCreateModalOpen());
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <div className={'d-flex align-center justify-space-between'}>
      <Input
        value={searchParams.search}
        onChange={e => handleSearch(e.target.value)}
        suffix={showIconInput()}
        placeholder={t('house_config.TJM_nomi_bilan_qidirish')}
        className={styles.header_search}
      />
      <Button onClick={openModal} className={styles.header_button} type={'primary'}>
        <PlusIcon />
        <span>{t('house_config.Yangi_yaratish')}</span>
      </Button>
    </div>
  );
};

export default HeaderHouseConfig;
