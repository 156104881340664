import React, { FC } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { useAppDispatch } from '@hooks/reduxHooks';
import TelPlusIcon from '@icons/TelPlusIcon';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import { addClientPhone } from '@reducers/ClientSlice';
import { getCurrentLanguageWord } from '@utils/helper/getCurrentLanguageWord';
import { phoneValidator } from '@utils/index';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import { ClientPhone } from '@utils/models/Client';
import { Button, Checkbox, Col, Form, FormInstance, Input, Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { JuridicalClientForm } from './ClientMainData';

import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

const { Option } = Select;

interface IProps {
  form: FormInstance<JuridicalClientForm>;
  clientPhones: ClientPhone[];
  handleCheckedPhone: (index: number) => void;
  handleRemoveClientPhone: (index: number) => void;
  regions: BaseLanguageEntity[] | undefined;
  loadingRegions: boolean;
  changeRegion: (value: number) => void;
  districts: BaseLanguageEntity[] | undefined;
  districtsLoading: boolean;
  setCountryId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ClientMainDataJuridical: FC<IProps> = ({
  form,
  clientPhones,
  handleCheckedPhone,
  handleRemoveClientPhone,
  changeRegion,
  loadingRegions,
  regions,
  districts,
  districtsLoading,
  setCountryId
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const countryCode = Form.useWatch('countryCode', form) as string;
  const cityID = Form.useWatch('cityID', form) as number;

  const handleAddClientPhoneJuridical = () => {
    dispatch(addClientPhone());
  };

  return (
    <Form form={form} name="basic" layout="vertical" initialValues={{ remember: true }} autoComplete="off">
      <h2 className={styles.inputs_cont_title}>{t('client_create_contract.Boshlangich_malumotlar')}</h2>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item name="name" rules={[{ required: true, message: '' }]} label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Korxona_nomi')}</span>}>
            <Input className="customInput" />
          </Form.Item>
          {clientPhones.map((phone, index) => (
            <Space direction="horizontal" className="d-flex" key={phone?.id}>
              <div>
                <Form.Item name={`code${index + 1}`} className="d-none" />
                <div className="flex flex-col gap-[5px]">
                  <Form.Item label={<span>{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>} name={`phone${index + 1}`} rules={[phoneValidator()]}>
                    <InternationalPhoneNumber
                      defaultCountry="uz"
                      className={styles.phoneInput2}
                      onChange={(_, phone) => {
                        form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
              {index !== 0 && (
                <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                  <CloseOutlined />
                </Button>
              )}
            </Space>
          ))}
          {clientPhones.length < 5 && (
            <p onClick={handleAddClientPhoneJuridical} className={styles.conditionTelPlus}>
              <TelPlusIcon />
              {t('client_create_contract.Telefon_raqam_qoshish')}
            </p>
          )}
        </Col>
        <Col span={12}>
          <Form.Item name="countryCode" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
            <Select
              placeholder={t('client_create_contract.Mamlakat')}
              className="customSelect"
              onChange={(e, option) => {
                setCountryId(((option as any)?.props as number) + 1);

                form.setFieldsValue({
                  countryCode: e,
                  cityID: undefined,
                  districtId: undefined
                });
              }}
              showSearch
              optionFilterProp="children"
            >
              {countrCode?.map(item => (
                <Select.Option key={item?.code} props={item?.index}>
                  {item[`country_${i18n.language}` as keyof typeof item]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {countryCode === 'uz' || countryCode === 'tj' ? (
            <>
              <Form.Item name="cityID" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Viloyat')}</span>}>
                <Select
                  showSearch
                  className="customSelect"
                  onChange={changeRegion}
                  loading={loadingRegions}
                  optionFilterProp="children"
                  placeholder={t('client_create_contract.Hududni_tanlang')}
                >
                  {regions?.map(region => (
                    <Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="districtId" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Shahar_tuman')}</span>}>
                <Select loading={districtsLoading} className="customSelect" optionFilterProp="children" showSearch placeholder={t('client_create_contract.Hududni_tanlang')}>
                  {(cityID ? districts : [])?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {district.uz}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : null}
          <Form.Item name="address" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Uy')}</span>}>
            <Input className="customInput" />
          </Form.Item>
        </Col>
      </Row>
      <h2 className={styles.inputs_cont_title}>{t('client_create_contract.Yuridik_malumotlar')}</h2>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item name="inn" label={<span className={styles.inputs_cont_formLabel}>{t('client.INN')}</span>}>
            <Input className="customInput" type="number" />
          </Form.Item>
          <Form.Item name="mfo" label={<span className={styles.inputs_cont_formLabel}>{t('client.MFO')}</span>}>
            <Input className="customInput" type="number" />
          </Form.Item>
          <Form.Item name="accountNumber" label={<span className={styles.inputs_cont_formLabel}>{t('client.Hisob_raqami')}</span>}>
            <Input className="customInput" type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="oked" label={<span className={styles.inputs_cont_formLabel}>{t('client.OKED')}</span>}>
            <Input className="customInput" type="number" />
          </Form.Item>
          <Form.Item name="bankName" label={<span className={styles.inputs_cont_formLabel}>{t('client.Bank_nomi')}</span>}>
            <Input className="customInput" />
          </Form.Item>
          <Form.Item name="director" label={<span className={styles.inputs_cont_formLabel}>{t('client.Direktor')}</span>}>
            <Input className="customInput" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientMainDataJuridical;
