import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetCrmCurrentUserRole } from '@pages/crm/services/queries';
import { setCrmOneLid } from '@reducers/CrmOneLidSlice';
import { encodeString } from '@utils/helper';
import { PipeStatusesType } from '@utils/models/PipeAll';
import { Button } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import CloseCrmIcon from '../../../../../assets/icons/CloseCrmIcon';
import TickCircleIcon from '../../../../../assets/icons/TickCircleIcon';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useLeadSave } from '../../../../../queries/mutation';
import { usePipeStatusesById } from '../../../../../queries/queries';
import { MainPaths } from '../../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../../routing/rootPaths';
import { queryNames } from '../../../../../utils/constants/queryNames';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../utils/models/GoogleAnalitks';
import { OneLid } from '../../../../../utils/models/OneLid';
import LeadTaskCompleteModalOneLead from '../../../../crm/components/voronkaCards/leadTaskCompleteModal/LeadTaskCompleteModalOneLead';
import ReasonRefusalModalOneLead from '../../../../crm/components/voronkaCards/reasonsRefusalModal/ReasonRefusalModalOneLead';
import { useDeleteLid } from '../../../services/mutation';
import RespChangeModal from '../info/RespChangeModal';

import styles from './saveButtons.module.scss';

type Props = {
  pipeType: string | undefined;
  crmOneLid: OneLid;
};

const InformationSaveButton: React.FC<Props> = ({ pipeType, crmOneLid }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { data } = useGetCrmCurrentUserRole();
  const navigate = useNavigate();
  const [showReasonRefusalModal, setShowReasonRefusalModal] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [openRespModal, setOpenRespModal] = useState(false);
  const { params, appendSingle, searchParams } = useQueryParam<{ incoming: boolean; task: string }, { id: string }>();
  const crmLid = useAppSelector(state => state.crmOneLidField);
  const { data: pipeList } = usePipeStatusesById(crmLid.pipeId);
  // const { data: crmOneLid } = useOneLid(Number(params.id))
  const incoming = pipeType === PipeStatusesType.FIRST;

  function goBack() {
    navigate(-1);
  }

  // save information
  function saveInformationOneLeadEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CHANGE_ONE_LEAD_INFORMATION
    });
  }

  // handleSuccess
  function handleSuccess(data: OneLid) {
    saveInformationOneLeadEvent();
    if (params.id === 'new') navigate(`${rootPaths.MAIN + MainPaths.CRM}/${data.id}/${encodeString(data.name)}`);
    else if (incoming) {
      appendSingle('incoming', undefined);
      qc.invalidateQueries([queryNames.CRM_ONE_LID, Number(params.id)]);
    } else qc.invalidateQueries([queryNames.CRM_ONE_LID, Number(params.id)]);
  }

  const saveLidMutation = useLeadSave(handleSuccess, Number(params.id));
  const deleteLidMutation = useDeleteLid(Number(params.id), goBack);

  const sendLeadFields: any = crmLid?.leadFieldValue
    ?.filter(prev => prev?.value !== '')
    ?.filter(val => val.value !== '{}')
    ?.map(item => {
      if (Array.isArray(item?.valueList)) {
        const newArr = item?.valueList?.map(val => ({ id: item?.id, value: val }));

        return newArr;
      }
      return {
        id: item?.id,
        value: item?.value ? item?.value : item?.valueList,
        valueType: item?.valueType
      };
    });

  // delete lid
  const deleteLid = () => {
    deleteLidMutation.mutate();
  };

  // handle click cancel
  const handleClickCancel = () => {
    if (params.id === 'new') {
      navigate(-1);
    } else {
      dispatch(setCrmOneLid(crmOneLid!));
      qc.invalidateQueries([queryNames.CRM_ONE_LID, Number(params.id)]);
    }
  };

  // handle show modal
  const handleShowModal = (visible: boolean) => {
    setShowReasonRefusalModal(visible);
  };

  // handle show completeModal
  const handleShowCompleteModal = (visible: boolean) => {
    setShowComplete(visible);
  };

  // save lid
  const saveLead = (reason: null | number | undefined) => {
    const tags: number[] = [];

    for (let i = 0; i < crmLid.tags.length; i++) {
      tags.push(Number(crmLid.tags[i].id));
    }

    let { pipeStatusId } = crmLid;

    if (incoming && pipeList && pipeList[0].id === pipeStatusId) {
      pipeStatusId = pipeList[1].id as number;
    }

    const data = {
      id: Number(crmLid.id),
      contacts: crmLid.contacts as any,
      balance: crmLid.balance,
      responsibleById: crmLid.responsibleById,
      pipeStatusId,
      tagIds: tags,
      fields: sendLeadFields.flat(Infinity),
      name: crmLid.name.replaceAll(' ', '').length > 0 ? crmLid.name : null,
      reasonsId: reason,
      autoClosedLeadTask: !!(pipeType === 'FAILED' || pipeType === 'SUCCESS')
    };

    saveLidMutation.mutate(data);
  };

  // handle save lid
  const handleSave = () => {
    if (crmLid.showReasonRefusal) {
      handleShowModal(true);
    } else if (crmLid.showComplete) {
      handleShowCompleteModal(true);
    } else if (searchParams?.task === 'true' && crmLid.isResponsible) {
      setOpenRespModal(true);
    } else {
      saveLead(undefined);
    }
  };

  // save lead complete
  const saveLeadComplete = (reason: null | number | undefined) => {
    const tags: number[] = [];

    for (let i = 0; i < crmLid.tags.length; i++) {
      tags.push(Number(crmLid.tags[i].id));
    }

    let { pipeStatusId } = crmLid;

    if (incoming && pipeList && pipeList[0].id === pipeStatusId) {
      pipeStatusId = pipeList[1].id as number;
    }

    const data = {
      id: Number(crmLid.id),
      contacts: crmLid.contacts as any,
      balance: crmLid.balance,
      responsibleById: crmLid.responsibleById,
      pipeStatusId,
      tagIds: tags,
      fields: sendLeadFields.flat(Infinity),
      name: crmLid.name.replaceAll(' ', '').length > 0 ? crmLid.name : null,
      reasonsId: reason,
      autoClosedLeadTask: true
    };

    saveLidMutation.mutate(data);
  };

  if (incoming)
    return (
      <>
        <div className={styles.container}>
          <div
            className={styles.container_incoming}
            style={{
              justifyContent: data?.leadSave === 'DENY' || data?.leadDelete === 'DENY' ? 'center' : 'space-between'
            }}
          >
            <div onClick={() => handleSave()} className={styles.container_incoming_button}>
              <TickCircleIcon />
              <span className={styles.container_incoming_button_title}>{t('one_lid.Qabul_qilish')}</span>
            </div>
            <div className={styles.container_incoming_line} />
            <div onClick={deleteLid} className={styles.container_incoming_button}>
              <CloseCrmIcon />
              <span className={styles.container_incoming_button_title}>{t('one_lid.Bekor_qilish')}</span>
            </div>
          </div>
        </div>
        <ReasonRefusalModalOneLead visible={showReasonRefusalModal} close={handleShowModal} success={saveLead} />
      </>
    );

  if (!crmLid.isChange) return null;

  return (
    <>
      <div className={styles.container}>
        <Button onClick={() => handleSave()} loading={saveLidMutation.isLoading} className={styles.container_save} type="primary">
          {t('one_lid.Saqlash')}
        </Button>
        <Button onClick={handleClickCancel} className={styles.container_cancel}>
          {t('one_lid.Bekor_qilish')}
        </Button>
      </div>
      <ReasonRefusalModalOneLead visible={showReasonRefusalModal} close={handleShowModal} success={saveLead} />
      <LeadTaskCompleteModalOneLead close={handleShowCompleteModal} success={saveLeadComplete} visible={showComplete} />
      <RespChangeModal openRespModal={openRespModal} setOpenRespModal={setOpenRespModal} pipeType={pipeType} />
    </>
  );
};

export default InformationSaveButton;
