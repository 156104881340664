import FavoriteChartIcon from '@icons/FavoriteChartIcon';

import BuildingIcon from '../../../assets/icons/BuildingIcon';
import CardPosBossIcon from '../../../assets/icons/CardPosBossIcon';
import DollarSquareIcon from '../../../assets/icons/DollarSquareIcon';
import HomeBossIcon from '../../../assets/icons/HomeBossIcon';
import PersonalCardIcon from '../../../assets/icons/PersonalCardIcon';
import PresentionChartIcon from '../../../assets/icons/PresentionChartIcon';
import SaleIcon from '../../../assets/icons/SaleIcon';
import { BossPagePaths } from '../../../routing/bossPage/bossPagePaths';
import { rootPaths } from '../../../routing/rootPaths';
import { LocalStorage } from '../../../service/LocalStorage';
import { USER_KEY } from '../../../utils/constants/localStorageKeys';
import { Permissions } from '../../../utils/constants/permissions';
import { User } from '../../../utils/models/User';

const user = LocalStorage.get<User>(USER_KEY);

const fullNavBarLinks = [
  {
    name: 'home',
    icon: <HomeBossIcon />,
    path: rootPaths.BOSS + BossPagePaths.HOME,
    permission: Permissions.PERMISSION_STATS_MAIN
  },
  {
    name: 'payment',
    icon: <DollarSquareIcon />,
    path: rootPaths.BOSS + BossPagePaths.PAYMENT,
    permission: Permissions.PERMISSION_STATS_PAYMENT
  },
  {
    name: 'arrearage',
    icon: <CardPosBossIcon />,
    path: rootPaths.BOSS + BossPagePaths.ARREARAGE,
    permission: Permissions.PERMISSION_STATS_DEBT
  },
  {
    name: 'complex',
    icon: <BuildingIcon />,
    path: rootPaths.BOSS + BossPagePaths.COMPLEX,
    permission: Permissions.PERMISSION_STATS_HOUSE
  },
  {
    name: 'contract',
    icon: <PersonalCardIcon />,
    path: rootPaths.BOSS + BossPagePaths.CONTRACT,
    permission: Permissions.PERMISSION_STATS_CONTRACT
  },
  {
    name: 'CRM',
    icon: <PresentionChartIcon />,
    path: rootPaths.BOSS + BossPagePaths.CRM,
    permission: Permissions.PERMISSION_PAGE_CRM
  },
  {
    name: 'Sotuv',
    icon: <SaleIcon />,
    path: rootPaths.BOSS + BossPagePaths.SALE,
    permission: Permissions.PERMISSION_STATS_SALE
  },
  {
    name: 'BI_Analitics',
    icon: <FavoriteChartIcon />,
    path: rootPaths.BOSS + BossPagePaths.BI_ANALYTICS,
    permission: Permissions.PERMISSION_MACRO_ANALYTICS
  }
];

const navBarLinks: typeof fullNavBarLinks = [];

if (typeof user === 'object' && user) {
  fullNavBarLinks.forEach(link => {
    if (link.permission) {
      if (user.role.permissions.includes(link.permission) || user.role.permissions.includes(Permissions.PERMISSION_MOBILE)) {
        navBarLinks.push(link);
      }
    } else {
      navBarLinks.push(link);
    }
  });
}

export default navBarLinks;
