import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';

import { Permissions } from "./permissions";

export const permissionURL = [
  {
    permission: Permissions.PERMISSION_READ_FLATS,
    url: rootPaths.MAIN + MainPaths.INDEX
  },
  {
    permission: Permissions.PERMISSION_READ_BOOKINGS,
    url: rootPaths.MAIN + MainPaths.ORDER
  },
  {
    permission: Permissions.PERMISSION_READ_CONTRACTS,
    url: rootPaths.MAIN + MainPaths.CONTRACT
  },
  {
    permission: Permissions.PERMISSION_READ_PAYMENTS,
    url: rootPaths.MAIN + MainPaths.PAYMENT
  },
  {
    permission: Permissions.PERMISSION_READ_CLIENTS,
    url: rootPaths.MAIN + MainPaths.CLIENT,
  },
  {
    permission: Permissions.PERMISSION_READ_DEBTS,
    url: rootPaths.MAIN + MainPaths.ARREARAGE,
  },
  {
    permission: Permissions.PERMISSION_READ_MESSAGES,
    url: rootPaths.MAIN + MainPaths.MESSAGE,
  },
  {
    permission: Permissions.PERMISSION_CHANGES,
    url: rootPaths.MAIN + MainPaths.CHANGES,
  },
  {
    permission: Permissions.PERMISSION_READ_STAFF,
    url:  rootPaths.MAIN + MainPaths.BROKER,
  },
  {
    permission: Permissions.PERMISSION_PAGE_CRM,
    url:  rootPaths.MAIN + MainPaths.CRM,
  },
  {
    permission: Permissions.PERMISSION_CHANGE_PASSWORD_EMPLOYEE,
    url:  rootPaths.CHANGE_PASSWORD
  },
];
