import { ZodError } from 'zod';

import { showMessage } from './showMessage';

const errorMessages = {
  uz: 'Xatolik mavjud',
  ru: 'Есть ошибка',
  en: 'There is an error',
  kr: 'Хатолик мавжуд',
  tr: 'Bir hata var'
};

export const baseErrorHandler = (err: any): void => {
  if (err?.response?.status === 401) return;

  if (err.response?.data?.message && typeof err.response.data.accept === 'boolean') {
    showMessage(err.response.data.message, err.response.data.accept);
    return;
  }

  if (err instanceof ZodError) {
    // eslint-disable-next-line no-console
    console.error(err.message);
  } else showMessage(errorMessages, false);
};
