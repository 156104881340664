import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './deleteModal.module.scss';
import CancelBronGarbage from '../../../assets/icons/CancelBronGarbage';
import { useDeleteCurrency } from '../../../queries/mutation';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  id: number;
}

const DeleteModal: React.FC<Props> = ({ visible, close, id }) => {
  const { t } = useTranslation();

  const deleteCurrency = useDeleteCurrency(handleSuccess);

  // handle success
  function handleSuccess() {
    handleClose();
  }

  // handle close
  const handleClose = () => {
    close(false);
  };

  // handelDelete
  const handelDelete = () => {
    deleteCurrency.mutate(id);
  };

  return (
    <Modal className={styles.container} closable={false} footer={null} width={543} visible={visible} onCancel={handleClose}>
      <div className={`${styles.icon_cont} d-flex justify-center`}>
        <span className={`${styles.icon} d-flex align-center justify-center`}>
          <CancelBronGarbage />
        </span>
      </div>
      <h1 style={{ marginBottom: '24px' }} className={styles.title}>
        Haqiqatan ham tanlangan valyutani oʻchirib tashlamoqchimisiz?
      </h1>
      <div className={'d-flex align-center justify-center'}>
        <Button type={'primary'} onClick={handleClose} className={styles.btn_cancel}>
          {t(`changes.monthlyPayments.Yopish`)}
        </Button>
        <Button onClick={handelDelete} danger loading={deleteCurrency.isLoading} type={'primary'} className={styles.btn_confirm}>
          {t(`changes.monthlyPayments.O'chirish`)}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
