import React from 'react';

const InfoIcon: React.FC = () => (
    <svg
      style={{ cursor: 'pointer', marginTop: '7px', marginRight: '10px' }}
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58182 0 0 3.58182 0 8C0 12.4182 3.58182 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58182 12.4182 0 8 0ZM7.63636 3.63636C7.44348 3.63636 7.25849 3.71299 7.1221 3.84938C6.98571 3.98577 6.90909 4.17075 6.90909 4.36364C6.90909 4.55652 6.98571 4.74151 7.1221 4.8779C7.25849 5.01429 7.44348 5.09091 7.63636 5.09091H8C8.19289 5.09091 8.37787 5.01429 8.51426 4.8779C8.65065 4.74151 8.72727 4.55652 8.72727 4.36364C8.72727 4.17075 8.65065 3.98577 8.51426 3.84938C8.37787 3.71299 8.19289 3.63636 8 3.63636H7.63636ZM6.54545 6.54545C6.35257 6.54545 6.16759 6.62208 6.0312 6.75847C5.89481 6.89486 5.81818 7.07984 5.81818 7.27273C5.81818 7.46561 5.89481 7.6506 6.0312 7.78699C6.16759 7.92338 6.35257 8 6.54545 8H7.27273V10.1818H6.54545C6.35257 10.1818 6.16759 10.2584 6.0312 10.3948C5.89481 10.5312 5.81818 10.7162 5.81818 10.9091C5.81818 11.102 5.89481 11.287 6.0312 11.4234C6.16759 11.5597 6.35257 11.6364 6.54545 11.6364H9.45455C9.64743 11.6364 9.83241 11.5597 9.96881 11.4234C10.1052 11.287 10.1818 11.102 10.1818 10.9091C10.1818 10.7162 10.1052 10.5312 9.96881 10.3948C9.83241 10.2584 9.64743 10.1818 9.45455 10.1818H8.72727V7.27273C8.72727 7.07984 8.65065 6.89486 8.51426 6.75847C8.37787 6.62208 8.19289 6.54545 8 6.54545H6.54545Z"
        fill="#00A389"
      />
    </svg>
  );

export default InfoIcon;
