import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useBasicCurrency, useBossPrepaymentHouses } from '../../queries/queries';
import { colorPieChart } from '../../utils/constants/colorPieChart';
import { DATE_FORMAT } from '../../utils/constants/format';
import { START_DATE_DEFAULT } from '../../utils/constants/startDateDefault';
import { colorGenerator } from '../../utils/helper/colorGenerator';
import { BoosComplexSelectType } from '../../utils/models/BoosComplexSelectType';
import { PieChartData } from '../../utils/models/PieChartData';

import BlocksPrepaymentModal from './BlocksPrepaymentModal';
import SpecialTimeModal from './SpecialTimeModal';

import styles from './prepaymentHouses.module.scss';

const { Option } = Select;

const PrepaymentHouses = () => {
  const [chartData, setChartData] = useState<PieChartData[]>([]);
  const [selectType, setSelectType] = useState<BoosComplexSelectType | (string & {})>(BoosComplexSelectType.NOW);
  const [openBlockModal, setOpenBlockModal] = useState({
    id: 0,
    name: '',
    visible: false
  });
  const [time, setTime] = useState({
    startDate: '',
    finishDate: ''
  });
  const [specialModal, setSpecialModal] = useState(false);

  const { data, isFetching, isLoading } = useBossPrepaymentHouses(time.startDate, time.finishDate);

  const { data: currency } = useBasicCurrency();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      const newData: PieChartData[] = [];

      data.houses.forEach((house, index) => {
        let color = colorPieChart[index];

        if (index > 8) {
          color = `#${colorGenerator()}`;
        }
        newData.push({
          id: String(house.id),
          label: house.name,
          value: house.prepayment,
          color
        });
      });
      setChartData(newData);
    }
  }, [data]);

  // handle blocks modal
  const handleOpenBlockModal = (id: number, name: string, visible: boolean) => {
    setOpenBlockModal({
      id,
      name,
      visible
    });
  };

  // set now
  const setNow = () => {
    setTime({
      startDate: START_DATE_DEFAULT,
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set today
  const setToday = () => {
    setTime({
      startDate: dayjs(new Date()).format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set yesterday
  const setYesterday = () => {
    setTime({
      startDate: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set this week
  const setThisWeek = () => {
    if (dayjs(new Date()).startOf('week').format(DATE_FORMAT) === dayjs(new Date()).format(DATE_FORMAT)) {
      setTime({
        startDate: dayjs(new Date()).add(-6, 'day').format(DATE_FORMAT),
        finishDate: dayjs(new Date()).format(DATE_FORMAT)
      });
    } else {
      setTime({
        startDate: dayjs(new Date()).startOf('week').add(1, 'day').format(DATE_FORMAT),
        finishDate: dayjs(new Date()).format(DATE_FORMAT)
      });
    }
  };

  // set this month
  const setThisMonth = () => {
    setTime({
      startDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // handle special modal
  const handleSpecialModal = (visible: boolean) => {
    setSpecialModal(visible);
  };

  // set special
  const setSpecial = () => {
    handleSpecialModal(true);
  };

  // handle select
  const handleSelect = (value: BoosComplexSelectType | (string & {})) => {
    switch (value) {
      case BoosComplexSelectType.TODAY:
        setToday();
        setSelectType(value);
        break;
      case BoosComplexSelectType.YESTERDAY:
        setYesterday();
        setSelectType(value);
        break;
      case BoosComplexSelectType.THIS_WEEK:
        setThisWeek();
        setSelectType(value);
        break;
      case BoosComplexSelectType.THIS_MONTH:
        setThisMonth();
        setSelectType(value);
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        break;
      default:
        setNow();
        setSelectType(value);
        break;
    }
  };

  // handle select date
  const handleSelectDate = (date: string[]) => {
    setSelectType(`${date[0]} - ${date[1]}`);

    setTime({
      startDate: date[0],
      finishDate: date[1]
    });
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h3>{t('boss_complex.Boshlangich_tolovlar')}</h3>
          <Select
            onChange={handleSelect}
            className={`${styles.container_header_select} customSelect`}
            value={selectType}
            style={{ width: Object.keys(BoosComplexSelectType).includes(selectType) ? '' : 220 }}
          >
            <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
            <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
            <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
            <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
            <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
            <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option>
          </Select>
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_value}>
            <div className={styles.container_body_value_title}>
              <p>{t('boss_complex.Jami_boshlangich_tolovlar')}</p>
              <h4>
                {data?.total.toLocaleString('ru')} {currency?.ccy}
              </h4>
            </div>
            <div className={styles.container_body_value_legneds}>
              {chartData.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} onClick={() => handleOpenBlockModal(Number(item.id), item.label, true)} className={styles.container_body_value_legneds_item}>
                  <span
                    style={{
                      // @ts-ignore
                      '--color': item.color
                    }}
                  />
                  <p>{item.label}</p>
                  <div className={styles.container_body_value_legneds_item_value}>
                    {item.value.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.container_body_chart}>
            {currency ? (
              <ResponsivePie
                data={chartData}
                margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                innerRadius={0.6}
                cornerRadius={3}
                activeOuterRadiusOffset={16}
                colors={{ datum: 'data.color' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['opacity', 0.2]]
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabel="value"
                arcLabelsRadiusOffset={0.3}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                tooltip={e => {
                  const item = e.datum;

                  return (
                    <div className={styles.tooltip}>
                      <div className={styles.tooltip_label}>{item.label}</div>
                      <div className={styles.tooltip_value}>
                        {item.value.toLocaleString('ru')} {currency?.ccy}
                      </div>
                    </div>
                  );
                }}
                legends={[]}
              />
            ) : null}
          </div>
        </div>
      </div>
      <BlocksPrepaymentModal
        startDate={time.startDate}
        finishDate={time.finishDate}
        visible={openBlockModal.visible}
        close={handleOpenBlockModal}
        id={openBlockModal.id}
        name={openBlockModal.name}
      />
      <SpecialTimeModal visible={specialModal} close={handleSpecialModal} handleSelect={handleSelectDate} />
    </Spin>
  );
};

export default PrepaymentHouses;
