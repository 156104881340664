import React, { useState } from 'react';
import { Button, Form, Input, Modal, Select, Spin } from 'antd';

import styles from './sendMessageModal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { handleOpen, handleType } from '../../../../../store/reducers/SendMessageCrmSlice';
import SettingIcon from '../../../../../assets/icons/SettingIcon';
import InfoIcon from '../../../../../assets/icons/InfoIcon';
import { useTranslation } from 'react-i18next';
import { MyCallsMessageType, MyMessageTemplate } from '../../../../../utils/models/MyCalls';
import { useMyMessageTemplateDelete, useMyMessageTemplateSave } from '../../../../../queries/mutation';
import { useMyMessageTemplate } from '../../../../../queries/queries';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { Permissions } from '../../../../../utils/constants/permissions';
import { usePremiumOriginator } from '../../../../message/services/queries';
import { useMyCallsSendMessage } from '../../../services/mutation';

const { Option } = Select;

interface IForm {
  title: string;
  text: string;
  orginator: string;
}

const SendMessageModal = () => {
  const { params } = useQueryParam<any, { id: string }>();

  const [templatePage, setTemplatePage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<MyMessageTemplate>();

  const data = useAppSelector(state => state.sendMessageCrmSlice);
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const [form] = Form.useForm<IForm>();

  // is access config
  const isAccessConfig = permissions?.includes(Permissions.PERMISSION_SETTINGS_CRM);

  const { isLoading: loadingMessageTemplate, data: messageTemplate } = useMyMessageTemplate();
  const isMoiZvonki = useAppSelector(state => state.crmOneLidField.isMoiZvonki);

  const { isLoading: loadingOriginator, data: originator } = usePremiumOriginator();

  const sendMessage = useMyCallsSendMessage(Number(params.id), handleSuccessSendMessage);
  const deleteMessageTemplate = useMyMessageTemplateDelete(handleSuccessSaveTemplate);
  const saveMessageTemplate = useMyMessageTemplateSave(handleSuccessSaveTemplate);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // handle success save template
  function handleSuccessSaveTemplate() {
    form.setFieldsValue({
      text: '',
      title: ''
    });
    setSelectedTemplate(undefined);
    handleTemplatePage(false);
  }

  // handle success save template
  function handleSuccessSendMessage() {
    handleCancel();
  }

  // handle template page
  const handleTemplatePage = (visible: boolean) => {
    setTemplatePage(visible);
  };

  // handle open template page
  const handleOpenTemplatePage = () => {
    handleTemplatePage(true);
    if (selectedTemplate) {
      form.setFieldsValue({
        text: selectedTemplate.body,
        title: selectedTemplate.title
      });
    }
  };

  // handle close template page
  const handleCloseTemplatePage: React.MouseEventHandler<HTMLElement> = e => {
    e.preventDefault();
    setTemplatePage(false);
  };

  // handle active button
  const handleActiveButton = (type: MyCallsMessageType) => {
    dispatch(handleType(type));
  };

  // handle cancel
  const handleCancel = () => {
    form.setFieldsValue({
      text: '',
      title: ''
    });
    dispatch(
      handleOpen({
        visible: false,
        type: MyCallsMessageType.MOI_ZVONKI,
        phone: ''
      })
    );
    setSelectedTemplate(undefined);
    setTemplatePage(false);
  };

  // on finish
  const onFinish = (val: IForm) => {
    if (templatePage) {
      handleSaveTemplate(val);
    } else {
      handleSendMessage(val);
    }
  };

  // handle send message
  const handleSendMessage = (val: IForm) => {
    sendMessage.mutate({
      text: val.text,
      to: data.phone,
      sendSmsType: data.type,
      leadId: Number(params.id),
      orginator: val.orginator
    });
  };

  // handle save template
  const handleSaveTemplate = (val: IForm) => {
    saveMessageTemplate.mutate({
      title: val.title,
      body: val.text,
      id: selectedTemplate?.id
    });
  };

  // handle delete template
  const handleDeleteTemplate = () => {
    if (selectedTemplate?.id) {
      deleteMessageTemplate.mutate(selectedTemplate.id);
    }
  };

  // handle template
  const handleTemplate = (id: number) => {
    if (id) {
      const template = messageTemplate?.find(temp => temp.id === id);
      if (template) {
        form.setFieldsValue({
          text: template.body
        });
        setSelectedTemplate(template);
      }
    } else {
      form.setFieldsValue({
        text: '',
        title: ''
      });
      setSelectedTemplate(undefined);
    }
  };

  return (
    <Modal
      className={styles.container}
      width={560}
      footer={false}
      visible={data.visible}
      title={t('my_calls.SMS_xabarnoma_yuborish')}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        name="sendMessageCrm"
        initialValues={{ remember: true }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <div className={styles.container_body}>
          {!templatePage && (
            <div className={'d-flex align-center'}>
              {isMoiZvonki && (
                <>
                  <div
                    onClick={() => handleActiveButton(MyCallsMessageType.MOI_ZVONKI)}
                    style={{
                      marginRight: '12px'
                    }}
                    className={
                      data.type === MyCallsMessageType.MOI_ZVONKI ? styles.container_body_active_button : styles.container_body_default_button
                    }
                  >
                    <div />
                    <span>Мои Звонки</span>
                  </div>
                  <div
                    onClick={() => handleActiveButton(MyCallsMessageType.PLAY_MOBILE)}
                    className={
                      data.type === MyCallsMessageType.PLAY_MOBILE ? styles.container_body_active_button : styles.container_body_default_button
                    }
                  >
                    <div />
                    <span>{t('my_calls.my_calls')}</span>
                  </div>
                </>
              )}
            </div>
          )}
          {!loadingOriginator && !templatePage && MyCallsMessageType.PLAY_MOBILE && originator?.length !== 0 && (
            <>
              <label className={styles.container_body_label}>{t('premium_modal.Originator')}</label>
              <div>
                <Form.Item
                  className={styles.container_body_select}
                  name={'orginator'}
                  // rules={[{ required: true, message: '' }]}
                  initialValue={originator?.length === 1 ? originator[0]?.name : false}
                >
                  <Select
                    allowClear
                    loading={loadingOriginator}
                    className={'customSelect'}
                    placeholder={'Originator tanlang'}
                    // value={
                    //   originator?.length === 1 ? originator[0]?.name : false
                    // }
                    // defaultValue={
                    //   originator?.length === 1 ? originator[0]?.name : false
                    // }
                  >
                    {originator?.map((org, index) => (
                      <Option value={org.name} key={index}>
                        {org.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
          <label className={styles.container_body_label}>{templatePage ? t('my_calls.Shablon_nomi') : t('my_calls.Shablon')}</label>
          {templatePage ? (
            <div className={'d-flex align-center'}>
              <Form.Item
                name={'title'}
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
              >
                <Input className={'customInput ' + styles.container_body_slect} placeholder={t('my_calls.Nom_kiritiriting')} />
              </Form.Item>
              {selectedTemplate && (
                <Spin spinning={deleteMessageTemplate.isLoading}>
                  <TrushSquareIcon onClick={handleDeleteTemplate} className={styles.container_body_delete_icon} />
                </Spin>
              )}
            </div>
          ) : (
            <div className={'d-flex align-center'}>
              <Select
                allowClear
                value={selectedTemplate?.id}
                onChange={handleTemplate}
                loading={loadingMessageTemplate}
                className={'customSelect ' + styles.container_body_slect}
              >
                {messageTemplate?.map((temp, index) => (
                  <Option value={temp.id} key={index}>
                    {temp.title}
                  </Option>
                ))}
              </Select>
              {isAccessConfig && <SettingIcon onClick={handleOpenTemplatePage} className={styles.container_body_settings_icon} />}
            </div>
          )}
          <Form.Item
            name={'text'}
            rules={[
              {
                required: true,
                message: ''
              }
            ]}
          >
            <Input.TextArea
              className={'customInput ' + styles.container_body_text_area}
              placeholder={t('my_calls.Xabar_matnini_kiriting')}
              autoSize={{ minRows: 6, maxRows: 6 }}
            />
          </Form.Item>
          {data.type === MyCallsMessageType.MOI_ZVONKI && !templatePage && (
            <p className={styles.container_information}>
              <InfoIcon />
              <span>{t('my_calls.Yuborish_tugmasini_bosgandan_song_telefoningizdan_yuborishni_tasdiqlang')}</span>
            </p>
          )}
          <div className={styles.container_modalFooter}>
            <Button
              htmlType={'button'}
              className={styles.container_modalFooter_margin + (templatePage ? '' : ' ' + styles.container_display_none)}
              onClick={handleCloseTemplatePage}
            >
              {t('my_calls.Ortga')}
            </Button>
            <Button
              loading={saveMessageTemplate.isLoading || deleteMessageTemplate.isLoading}
              className={templatePage ? '' : ' ' + styles.container_display_none}
              htmlType="submit"
              type="primary"
            >
              {t('home.Saqlash')}
            </Button>
            <Button
              className={templatePage ? ' ' + styles.container_display_none : ''}
              loading={sendMessage.isLoading}
              htmlType="submit"
              type="primary"
            >
              {t('my_calls.Yuborish')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default SendMessageModal;
