import { useQuery } from 'react-query';

import { $api } from '../../../service/RequestService';
import { getContractsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { ContractList } from '../../../utils/models/ContractList';
import { Pagination } from '../../../utils/models/Pagination';
import { SearchParamsContract } from '../Contract';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

// get contracts list
export function useContracts(size: number, searchObj: SearchParamsContract) {
  return useQuery<Pagination<ContractList>>([queryKeys.CONTRACT, searchObj.page, JSON.stringify(searchObj), size], async () => {
    const res = await $api.post(endPoints.CONTRACT_FILTER, getContractsBody(size, searchObj));

    return res.data.data;
  });
}
