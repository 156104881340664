import React from 'react';
import { Button, Modal } from 'antd';
import { t } from 'i18next';
import queryString from 'query-string';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { rootPaths } from '../../../routing/rootPaths';
import { MainPaths } from '../../../routing/main/mainPaths';
import SuccessIconCreateOrder from '../../../assets/icons/SuccessIconCreateOrder';

interface Props {
  visible: boolean;
}

const AfterCreateOrderModal: React.FC<Props> = ({ visible }) => {
  const { push, location } = useQueryParam();

  const handleClose = () => {
    if (queryString.parse(location.search).showroom) {
      push(rootPaths.SHOWROOM);
    } else {
      push(rootPaths.MAIN + MainPaths.INDEX);
    }
  };

  const goToBronPage = () => {
    push(rootPaths.MAIN + MainPaths.ORDER);
  };

  return (
    <Modal className={'after-create-order-modal'} centered closable={false} footer={null} visible={visible} onCancel={handleClose}>
      <div className={'modal-content-header d-flex justify-center'}>{t('order.Muvaffaqiyatli_saqlandi')}</div>
      <div className={'icon-cont d-flex justify-center'}>
        <span className={'icon d-flex align-center justify-center'}>
          <SuccessIconCreateOrder />
        </span>
      </div>
      <div className={'modal-content-title d-flex justify-center'}>
        Buyurtmani ko’rish uchun
        <span onClick={goToBronPage}>buyurtma</span>
        sahifasiga o’ting
      </div>
      <div className={'d-flex align-center justify-center'}>
        <Button onClick={handleClose} className={'close-modal-after-create-order'} type={'primary'}>
          {t('home.Yopish')}
        </Button>
      </div>
    </Modal>
  );
};

export default AfterCreateOrderModal;
