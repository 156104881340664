import React from 'react';
import TrashIcon from '@icons/TrashIcon';
import { useOneCrmSettingRole } from '@pages/crm-settings/services/mutation';
import { CrmSettingsModel } from '@pages/crm-settings/utils/models/CrmSettingsModel';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './taskModal.module.scss';

type Props = {
  deleteModal: boolean;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteItemId: number | undefined;
  filterData: CrmSettingsModel | undefined;
};

const DeleteTaskModal: React.FC<Props> = ({ deleteModal, setDeleteModal, deleteItemId, filterData }) => {
  const { t } = useTranslation();
  const updateRole = useOneCrmSettingRole();
  const handleClose = () => {
    setDeleteModal(false);
  };

  const removetask = (id: number) => {
    const arr = filterData?.pipeStatuses;

    const arr2 = arr?.filter(item => item.pipeStatusId !== id);

    updateRole.mutateAsync({ ...filterData!, pipeStatuses: arr2 }).then(handleClose);
    return arr2;
  };

  return (
    <Modal
      visible={deleteModal}
      onCancel={handleClose}
      footer={
        <div className={styles.deleteFooter}>
          <Button onClick={handleClose}>{t('one_lid.Bekor_qilish')}</Button>
          <Button loading={updateRole.isLoading} onClick={() => removetask(deleteItemId!)}>
            {t('crm.Ochirish')}
          </Button>
        </div>
      }
      className={styles.deleteModal}
    >
      <div className={styles.deleteBody}>
        <TrashIcon />
        <span>{t('home.Haqiqatdan_ham_ochirmoqchimisiz?')}</span>
      </div>
    </Modal>
  );
};

export default DeleteTaskModal;
