interface IProps {
  stroke?: string;
  size?: number;
}

const CardTickIcon = ({ stroke = 'white', size = 20 }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.66699 7.08337H11.2503" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5 13.75H6.66667" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75 13.75H12.0833" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18.3337 9.19163V13.425C18.3337 16.35 17.592 17.0833 14.6337 17.0833H5.36699C2.40866 17.0833 1.66699 16.35 1.66699 13.425V6.57496C1.66699 3.64996 2.40866 2.91663 5.36699 2.91663H11.2503"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.75 4.99996L15 6.24996L18.3333 2.91663" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CardTickIcon;
