import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Edit2Icon from '../../../assets/icons/Edit2Icon';
import { useContractField } from '../../../queries/queries';
import { CrmField } from '../../../utils/models/CrmField';
import { CrmFieldsType } from '../../../utils/models/CrmFieldsType';

import CreateFieldModal from './createFieldModal/CreateFieldModal';

import styles from './contractFields.module.scss';

const ContractFields = () => {
  const [opeEditModal, setOpeEditModal] = useState<{
    visible: boolean;
    field: CrmField | undefined;
  }>({
    visible: false,
    field: undefined
  });

  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useContractField();

  // handle edit modal
  const handleEditModal = (visible: boolean, field?: any) => {
    setOpeEditModal({
      field,
      visible
    });
  };

  return (
    <div className={styles.container}>
      <Spin spinning={isFetching || isLoading}>
        <div className={styles.container_header}>
          <h2>{t('contract_custom_field.Shartnoma_ozgaruvchilari')}</h2>
          <Button onClick={() => handleEditModal(true)}>
            <span>+</span> {t('contract_custom_field.Yangi_yaratish')}
          </Button>
        </div>
        {data?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.container_item}>
            <div className={styles.container_item_header}>
              <div className="flex items-center gap-[73px]">
                <h4>{item.name}</h4>
                <p className="!mb-0 !text-sm !italic !text-[#464255]">{item?.required ? t('one_lid.majburiy') : t('one_lid.ixtiyoriy')}</p>
              </div>
              <div onClick={() => handleEditModal(true, item)} className={styles.container_item_header_edit}>
                <Edit2Icon />
                <span>{t('contract_custom_field.Tahrirlash')}</span>
              </div>
            </div>
            <p>{item.type === CrmFieldsType.TEXT ? t('one_lid.Matn') : t('one_lid.Multi_royxat')}</p>
            {item?.options?.map((option, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={styles.container_item_option}>
                <span className={styles.container_item_option_number}>
                  {i + 1}
                  {t('one_lid.variant')}
                </span>
                <span className={styles.container_item_option_name}>{option.name}</span>
              </div>
            ))}
          </div>
        ))}
      </Spin>
      <CreateFieldModal data={opeEditModal.field} visible={opeEditModal.visible} handleClose={handleEditModal} />
    </div>
  );
};

export default ContractFields;
