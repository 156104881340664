import React from 'react';

const FlashIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.55252 7.74652H5.35502V11.9465C5.35502 12.9265 5.88585 13.1249 6.53335 12.3899L10.9492 7.37319C11.4917 6.76069 11.2642 6.25319 10.4417 6.25319H8.63919V2.05319C8.63919 1.07319 8.10835 0.874859 7.46085 1.60986L3.04502 6.62652C2.50835 7.24486 2.73585 7.74652 3.55252 7.74652Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FlashIcon;
