import { SearchParamsEmployee } from "@pages/settingEmployee/SettingEmployee";
import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { queryNames } from "@utils/constants/queryNames";
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { MutateAssignInfoModel } from "../utils/models/MutateAssignInfoModel";

export function useMutateAssignInfo(searchParamsEmployee: SearchParamsEmployee) {
  const qc = useQueryClient()
  const newSearchObj = { ...searchParamsEmployee };

  return useMutation(async (data: MutateAssignInfoModel) => {
    const res = await $api.post(endPoints.ASSIGN_EMPLOYEE, data)

    return res.data
  },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.EMPLOYEE_LIST, JSON.stringify(newSearchObj)]);
      },
      onError: baseErrorHandler
    }
  )
}