import { Button, Empty, Input, Modal, Radio, RadioChangeEvent, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@icons/SearchIcon';
import PlusIcon from '@icons/PlusIcon';
import { useGetCrmSettings } from '@pages/crm-settings/services/queries';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import TaskModal from '../task-modal/TaskModal';
import CreateRole from '../create-role/CreateRole';
import TrashIcon from '@icons/TrashIcon';
import DeleteTaskModal from '../task-modal/DeleteTaskModal';
import { useDeleteRole, useOneCrmSettingRole } from '@pages/crm-settings/services/mutation';
import { CrmSettingsModel } from '@pages/crm-settings/utils/models/CrmSettingsModel';
import { useTranslation } from 'react-i18next';
import DeleteRoleModal from './DeleteRoleModal';

import styles from './roleModal.module.scss';

type Props = {
  openRoleModal: boolean;
  setOpenRoleModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDeleteRole: boolean;
  setOpenDeleteRole: React.Dispatch<React.SetStateAction<boolean>>;
  roleId: number | undefined;
  setRoleId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const RoleModal: React.FC<Props> = ({ openRoleModal, setOpenRoleModal, openDeleteRole, setOpenDeleteRole, roleId, setRoleId }) => {
  const { t } = useTranslation();
  const { data } = useGetCrmSettings();
  const updateCrmSettings = useOneCrmSettingRole();
  const [openTaskModal, setOpenTaskModal] = useState(false);

  const [openRole, setOpenRole] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [pipeStatuses, setPipeStatuses] = useState<CrmSettingsModel | undefined>(undefined);

  const [deleteItemId, setDeleteItemId] = useState<number>();

  const columns = ['', t('client.Korish'), t('client.Tahrirlash'), t('shaxmatka.Ochirish'), t('shaxmatka.Ruxsatlar')];

  const onChangeRadioName = (e: RadioChangeEvent) => {
    const dataItem = data?.find(item => item?.id === e.target.value);
    setPipeStatuses(dataItem);
  };

  //open delete modal
  const handleDeleteModal = () => {
    setDeleteModal(true);
  };

  useEffect(() => {
    if (data && data?.length > 0 && pipeStatuses === undefined) {
      setPipeStatuses(data[0]);
    }
  }, [data, pipeStatuses]);

  useEffect(() => {
    if (data && data?.length > 0 && pipeStatuses) {
      setPipeStatuses(data?.find(item => item?.id === pipeStatuses?.id));
    }
  }, [data]);

  //handle cancel
  const handleCancel = () => {
    setOpenRoleModal(false);
  };

  //handle open task modal

  const handleOpenTaskModal = () => {
    setOpenTaskModal(true);
  };

  //handle open create role modal
  const handleOpenCreateRole = () => {
    setOpenRole(true);
  };

  // handle open delete role modal
  const handleOpenDeleteRoleModal = () => {
    setOpenDeleteRole(true);
  };

  const onFinish = () => {
    updateCrmSettings
      .mutateAsync({
        ...pipeStatuses!
      })
      .then(handleCancel);
  };

  return (
    <>
      <Modal
        visible={openRoleModal}
        onCancel={handleCancel}
        title={
          <div>
            <span>{t('home.Rol_sozlamasi')}</span>
          </div>
        }
        footer={
          <div className={styles.roleFooter}>
            <Button onClick={handleCancel}>{t('one_lid.Bekor_qilish')}</Button>
            <Button loading={updateCrmSettings?.isLoading} onClick={() => onFinish()}>
              {t('one_task.Saqlash')}
            </Button>
          </div>
        }
        width={1541}
        className={styles.roleModal}
        centered
      >
        <div style={{ display: 'flex' }}>
          <div className={styles.leftSide}>
            <div className={styles.leftSide_top}>
              <Input placeholder={t('home.Qidirish')} addonAfter={<SearchIcon />} />
            </div>
            <div className={styles.leftSide_addRole} onClick={handleOpenCreateRole}>
              <PlusIcon />
              <span>{t('crm.Role_qoshish')}</span>
            </div>
            <Radio.Group value={pipeStatuses?.id} onChange={e => onChangeRadioName(e)}>
              {data?.map(item => (
                <Radio className={styles.roleRadio} value={item?.id}>
                  <span>{item?.name}</span>
                  <TrashIcon
                    onClick={e => {
                      e.preventDefault();
                      handleOpenDeleteRoleModal();
                      setRoleId(item?.id);
                    }}
                  />
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.rightSide_top}>{columns?.map(item => <span className={styles.rightSide_top_item}>{item}</span>)}</div>
            <div className={styles.rightSide_body}>
              <div>
                <div className={styles.rightSide_body_lead}>
                  <span className={styles.title}>Lead</span>
                  <div className={styles.permissions}>
                    <div className={styles.permissions_item}>
                      <Radio.Group
                        value={pipeStatuses?.leadRead}
                        onChange={e => {
                          setPipeStatuses({
                            ...pipeStatuses!,
                            leadRead: e.target?.value
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={'DENY'} />
                          <Radio value={'OWN'} />
                          <Radio value={'ALLOW'} />
                        </Space>
                      </Radio.Group>
                    </div>
                    <div className={styles.permissions_item}>
                      <Radio.Group
                        value={pipeStatuses?.leadSave}
                        onChange={e => {
                          setPipeStatuses({
                            ...pipeStatuses!,
                            leadSave: e.target?.value
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={'DENY'} />
                          <Radio value={'OWN'} />
                          <Radio value={'ALLOW'} />
                        </Space>
                      </Radio.Group>
                    </div>
                    <div className={styles.permissions_item}>
                      <Radio.Group
                        value={pipeStatuses?.leadDelete}
                        onChange={e => {
                          setPipeStatuses({
                            ...pipeStatuses!,
                            leadDelete: e.target?.value
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={'DENY'} />
                          <Radio value={'OWN'} />
                          <Radio value={'ALLOW'} />
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <span>{t('crm.rad_etish')}</span>
                    <span>{t('crm.Faqat_ozinikini')}</span>
                    <span>{t('crm.Ruxsat_berish')}</span>
                  </div>
                </div>
                <div className={styles.rightSide_body_lead}>
                  <span className={styles.title}>{t('one_task.Topshirig')}</span>
                  <div className={styles.permissions}>
                    <div className={styles.permissions_item}>
                      <Radio.Group
                        value={pipeStatuses?.taskRead}
                        onChange={e => {
                          setPipeStatuses({
                            ...pipeStatuses!,
                            taskRead: e.target?.value
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={'DENY'} />
                          <Radio value={'OWN'} />
                          <Radio value={'ALLOW'} />
                        </Space>
                      </Radio.Group>
                    </div>
                    <div className={styles.permissions_item}>
                      <Radio.Group
                        value={pipeStatuses?.taskSave}
                        onChange={e => {
                          setPipeStatuses({
                            ...pipeStatuses!,
                            taskSave: e.target?.value
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={'DENY'} />
                          <Radio value={'OWN'} />
                          <Radio value={'ALLOW'} />
                        </Space>
                      </Radio.Group>
                    </div>
                    <div className={styles.permissions_item}>
                      <Radio.Group
                        value={pipeStatuses?.taskDelete}
                        onChange={e => {
                          setPipeStatuses({
                            ...pipeStatuses!,
                            taskDelete: e.target?.value
                          });
                        }}
                      >
                        <Space direction="vertical">
                          <Radio value={'DENY'} />
                          <Radio value={'OWN'} />
                          <Radio value={'ALLOW'} />
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className={styles.actions}>
                    <span>{t('crm.rad_etish')}</span>
                    <span>{t('crm.Faqat_ozinikini')}</span>
                    <span>{t('crm.Ruxsat_berish')}</span>
                  </div>
                </div>
                {pipeStatuses ? (
                  pipeStatuses?.pipeStatuses?.map(item => (
                    <div className={styles.rightSide_body_task}>
                      <div className={styles.title}>
                        <div className={styles.title_left}>
                          <Tooltip title={item?.pipeName}>
                            <span>{item?.pipeName.length > 33 ? item?.pipeName.substring(0, 33) + '...' : item?.pipeName}</span>
                          </Tooltip>
                          <Tooltip title={item?.pipeStatusName}>
                            <span>{item?.pipeStatusName.length > 30 ? item?.pipeStatusName.substring(0, 30) + '...' : item?.pipeStatusName}</span>
                          </Tooltip>
                        </div>
                        <TrashIcon
                          onClick={() => {
                            handleDeleteModal();
                            setDeleteItemId(item?.pipeStatusId!);
                          }}
                        />
                      </div>
                      <div className={styles.permissions}>
                        <div className={styles.permissions_item}>
                          <Radio.Group
                            value={item?.read}
                            onChange={e => {
                              setPipeStatuses({
                                ...pipeStatuses,
                                pipeStatuses: pipeStatuses?.pipeStatuses?.map(values => {
                                  return {
                                    ...values,
                                    read: values?.pipeStatusId === item?.pipeStatusId ? e.target?.value : values?.read
                                  };
                                })
                              });
                            }}
                          >
                            <Space direction="vertical">
                              <Radio value={'DENY'} />
                              <Radio value={'OWN'} />
                              <Radio value={'ALLOW'} />
                            </Space>
                          </Radio.Group>
                        </div>
                        <div className={styles.permissions_item}>
                          <Radio.Group
                            value={item?.save}
                            onChange={e => {
                              setPipeStatuses({
                                ...pipeStatuses,
                                pipeStatuses: pipeStatuses?.pipeStatuses?.map(values => {
                                  return {
                                    ...values,
                                    save: values?.pipeStatusId === item?.pipeStatusId ? e.target?.value : values?.save
                                  };
                                })
                              });
                            }}
                          >
                            <Space direction="vertical">
                              <Radio value={'DENY'} />
                              <Radio value={'OWN'} />
                              <Radio value={'ALLOW'} />
                            </Space>
                          </Radio.Group>
                        </div>
                        <div className={styles.permissions_item}>
                          <Radio.Group
                            value={item?.delete}
                            onChange={e => {
                              setPipeStatuses({
                                ...pipeStatuses,
                                pipeStatuses: pipeStatuses?.pipeStatuses?.map(values => {
                                  return {
                                    ...values,
                                    delete: values?.pipeStatusId === item?.pipeStatusId ? e.target?.value : values?.delete
                                  };
                                })
                              });
                            }}
                          >
                            <Space direction="vertical">
                              <Radio value={'DENY'} />
                              <Radio value={'OWN'} />
                              <Radio value={'ALLOW'} />
                            </Space>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className={styles.actions}>
                        <span>{t('crm.rad_etish')}</span>
                        <span>{t('crm.Faqat_ozinikini')}</span>
                        <span>{t('crm.Ruxsat_berish')}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <Empty />
                )}
              </div>
              <div className={styles.addTask} onClick={handleOpenTaskModal}>
                <PlusCircleIcon />
                <span>{t('crm.Yangi_bosqich_yaratish')}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <TaskModal openTaskModal={openTaskModal} setOpenTaskModal={setOpenTaskModal} filterData={pipeStatuses} />
      <CreateRole openRole={openRole} setOpenRole={setOpenRole} />
      <DeleteTaskModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItemId={deleteItemId} filterData={pipeStatuses} />
      <DeleteRoleModal openDeleteRole={openDeleteRole} setOpenDeleteRole={setOpenDeleteRole} roleId={roleId} />
    </>
  );
};

export default RoleModal;
