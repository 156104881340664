import React, { FC, useState } from 'react';
import Edit2Icon from '@icons/Edit2Icon';
import MenuThreeDotHorizontalIcon from '@icons/MenuThreeDotHorizontalIcon';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import ShaxmatkaEntranceNameModal from './ShaxmatkaEntranceNameModal';

import styles from './shaxmatkaEntranceHeader.module.scss';

interface IProps {
  shaxmatka: any[];
  setSaveAll: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShaxmatkaEntranceHeader: FC<IProps> = ({ shaxmatka, setSaveAll }) => {
  const { t } = useTranslation();
  const [entrance, setEntrance] = useState<number | null>(null);
  const [visible, setVisible] = useState<number | null>(null);

  return (
    <>
      <div className={styles.container}>
        <h3>{t('home_id.Podyezd')}</h3>
        {shaxmatka?.map((item, index) => {
          let max = 0;

          item.forEach((i: any) => max < i.length && (max = i.length));

          const entranceName =
            item.flat()?.find((item: any) => item?.status === 'SOLD' && item?.entranceName)?.entranceName ||
            item.flat()?.find((item: any) => item?.entranceName)?.entranceName ||
            item[0][0]?.entrance ||
            index + 1;

          return (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.container_item} style={{ width: 150 * max + (max - 1) * 10 + 40 }}>
              {entranceName}-{t('home_id.Podyezd').toLowerCase()}{' '}
              <Dropdown
                overlay={
                  <div className={styles.dropdown}>
                    <div
                      className={styles.dropdown_item}
                      onClick={() => {
                        setVisible(null);
                        setEntrance(index);
                      }}
                    >
                      <Edit2Icon /> Nomini o’zgartirish
                    </div>
                  </div>
                }
                visible={visible === item[0][0]?.entrance}
                onVisibleChange={() => setVisible(null)}
                mouseLeaveDelay={1}
              >
                <span onClick={() => setVisible(item[0][0]?.entrance)}>
                  <MenuThreeDotHorizontalIcon />
                </span>
              </Dropdown>
            </div>
          );
        })}
      </div>
      <ShaxmatkaEntranceNameModal entrance={entrance} setEntrance={setEntrance} setSaveAll={setSaveAll} />
    </>
  );
};

export default ShaxmatkaEntranceHeader;
