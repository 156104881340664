import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GoogleDocViewer {
  url: string;
  isOpen: boolean;
  name: string;
  format: string;
}

const initialState: GoogleDocViewer = {
  isOpen: false,
  url: '',
  format: '',
  name: ''
};

export const googleDocViewerSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    handleOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        format: string;
        url: string;
        name: string;
      }>
    ) => {
      state.isOpen = action.payload.isOpen;
      state.format = action.payload.format;
      state.url = action.payload.url;
      state.name = action.payload.name;
    },
    handleClose: state => {
      state.isOpen = false;
      state.format = '';
      state.url = '';
      state.name = '';
    }
  }
});

export const { handleOpen, handleClose } = googleDocViewerSlice.actions;
export default googleDocViewerSlice.reducer;
