import React from 'react';

interface Props {
  className?: string;
  size?: number;
}

const TickSquareIcon: React.FC<Props> = ({ className, size = 12 }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z"
        stroke="#00A389"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.875 6L5.29 7.415L8.125 4.585" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TickSquareIcon;
