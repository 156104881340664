import React, { useState } from 'react';

import styles from './addTriggerButton.module.scss';
import AddCircleIcon from '../../../../../../assets/icons/AddCircleIcon';
import { useTranslation } from 'react-i18next';
import SelectTypeModal from './selectTypeModal/SelectTypeModal';

interface Props {
  index: number;
  pipeStatusId: number;
}

const AddTriggerButton: React.FC<Props> = ({ index, pipeStatusId }) => {
  const [openSelectTypeModal, setOpenSelectTypeModal] = useState(false);

  const { t } = useTranslation();

  // handle add
  const handleOpenTypeSelect = (isOpen: boolean) => {
    setOpenSelectTypeModal(isOpen);
  };

  return (
    <>
      <div onClick={() => handleOpenTypeSelect(true)} className={styles.container}>
        <div className={styles.button}>
          <AddCircleIcon className={styles.container_icon} />
          <span className={styles.container_title}>{t('triggers.Trigger_yaratish')}</span>
        </div>
      </div>
      <SelectTypeModal visible={openSelectTypeModal} close={handleOpenTypeSelect} index={index} pipeStatusId={pipeStatusId} />
    </>
  );
};

export default AddTriggerButton;
