import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GlphIcon from '../../../../assets/icons/GlphIcon';
import DiscountInfo from '../../../../components/discount-info/DiscountInfo';
import { CurrencyItem } from '../../../../utils/models/Currency';
import styles from './pyamentContractMoney.module.scss';

interface IProps {
  total: number | undefined | null;
  currency: CurrencyItem | undefined;
  color: string | undefined;
  title: string;
  discountDescription?: string;
}

const PaymentContractCard: FC<IProps> = ({ color, currency, title, total, discountDescription }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container_item}>
      <span style={{ background: color }} className={styles.container_item_line}></span>
      <p>
        {title}{' '}
        {discountDescription ? (
          <DiscountInfo content={<p>{discountDescription}</p>} title={t('contract.Izoh')}>
            <span className={styles.container_item_info}>
              <GlphIcon />
            </span>
          </DiscountInfo>
        ) : (
          ''
        )}
      </p>
      <h1>
        <span>{total?.toLocaleString('ru')}</span> {currency?.ccy}
      </h1>
    </div>
  );
};

export default PaymentContractCard;
