import { Modal } from 'antd';
import styles from './importExport.module.scss';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import TickCircleIcon from '../../assets/icons/TickCircleIcon';
import CloseCircle from '../../assets/icons/CloseCircleIcon';
import { StatusKey } from '../../utils/models/TransferData';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { clearTransferData } from '../../store/reducers/TransferDataSlice';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { BASE_URL } from '../../service/RequestService';
import { LocalStorage } from '../../service/LocalStorage';
import { TOKEN_KEY } from '../../utils/constants/localStorageKeys';
import { useTranslation } from 'react-i18next';

const enum Status {
  loading = 'loading',
  waiting = 'waiting',
  success = 'success',
  error = 'error'
}

const initialData = [
  {
    name: StatusKey.BUILDERS,
    status: Status.loading
  },
  {
    name: StatusKey.HOUSES,
    status: Status.waiting
  },
  {
    name: StatusKey.FLATS,
    status: Status.waiting
  },
  {
    name: StatusKey.JURIDICAL_CLIENTS,
    status: Status.waiting
  },
  {
    name: StatusKey.NATURAL_CLIENTS,
    status: Status.waiting
  },
  {
    name: StatusKey.CONTRACTS,
    status: Status.waiting
  },
  {
    name: StatusKey.PAYMENTS,
    status: Status.waiting
  }
];

const ImportModal = () => {
  const [informationData, setInformationData] = useState<typeof initialData>([]);
  const stompClient = useRef<any>();
  const { t } = useTranslation();
  const visible = useAppSelector(state => state.transferDataReducer.showModal);
  const connectSocket = useAppSelector(state => state.transferDataReducer.connectSocket);
  const statusKey = useAppSelector(state => state.transferDataReducer.status);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!visible) {
      setInformationData(initialData);
    }
  }, [visible]);

  useEffect(() => {
    if (connectSocket) {
      const socket = new SockJS(`${BASE_URL}/ws`);
      stompClient.current = Stomp.over(socket);
      stompClient.current.connect(
        {
          Authorization: `Bearer ${LocalStorage.get(TOKEN_KEY)}`
        },
        function () {
          // @ts-ignore
          const url = stompClient.current.ws._transport.url;
          setInformationData(initialData);
          stompClient.current.subscribe(
            '/user/queue/notify-user' + url.split('/')[5],
            // @ts-ignore
            function (notification: any) {
              onMessage(notification.body);
            }
          );
        }
      );
    } else {
      dispatch(clearTransferData());
      // stompClient.current?.disconnect(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectSocket]);

  useEffect(() => {
    if (statusKey) {
      let hasError = false;
      const newData = initialData.map(item => {
        if (item.name === statusKey) {
          hasError = true;
          return {
            ...item,
            status: Status.error
          };
        }
        if (hasError) {
          return {
            ...item,
            status: Status.error
          };
        }
        return {
          ...item,
          status: Status.success
        };
      });
      setInformationData(newData);
    }
  }, [statusKey]);

  const handleCancel = () => {
    if (!connectSocket) {
      dispatch(clearTransferData());
    }
  };

  const showInfo = (data: (typeof initialData)[0]) => {
    switch (data.status) {
      case Status.success:
        return (
          <div className={styles.import_success}>
            <h3>{t(`import_export.${data.name}`)}</h3>
            <div>
              <span className={styles.status}>{t('import_export.Muvaffaqiyatli')}</span>
              <TickCircleIcon className={styles.icon} />
            </div>
          </div>
        );
      case Status.loading:
        return (
          <div className={styles.import_progress}>
            <h3>{t(`import_export.${data.name}`)}</h3>
            <div>
              <span className={styles.status}>{t('import_export.Jarayonda')}</span>
              <Loading3QuartersOutlined spin={true} />
            </div>
          </div>
        );
      case Status.waiting:
        return (
          <div className={styles.import_waiting}>
            <h3>{t(`import_export.${data.name}`)}</h3>
            <div>
              <span className={styles.status}>{t('import_export.Kutmoqda')}</span>
              <Loading3QuartersOutlined />
            </div>
          </div>
        );
      case Status.error:
        return (
          <div className={styles.import_failed}>
            <h3>{t(`import_export.${data.name}`)}</h3>
            <div>
              <span className={styles.status}>{t('import_export.Muvaffaqiyatsiz')}</span>
              <CloseCircle className={styles.icon} />
            </div>
          </div>
        );
    }
  };

  const onMessage = (data: StatusKey) => {
    const newData = [...informationData];
    let index = 0;
    for (let i = 0; i < newData.length; i++) {
      newData[0].status = Status.success;
      if (newData[i].name === data) {
        newData[i].status = Status.success;
        index = i;
        if (i === newData.length - 1) {
          break;
        }
      } else if (i > 0 && newData[i - 1].name === data) {
        newData[i].status = Status.loading;
      } else if (index < i && index !== 0) {
        newData[i].status = Status.waiting;
      }
    }
    setInformationData(newData);
  };

  return (
    <Modal
      title={connectSocket ? t('import_export.Import_qilish_jarayoni') : t('import_export.Import_qilish_malumotlari')}
      visible={visible}
      footer={null}
      centered
      onCancel={handleCancel}
      className={'importStatusModal'}
      width={634}
    >
      {informationData.map(data => showInfo(data))}
    </Modal>
  );
};

export default ImportModal;
