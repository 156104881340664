import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { StateSaleModel } from '../utils/models/StateSaleModel';

export function useGetStatSales(data: { startDate: string; finishDate: string; countryId: number }) {
  return useQuery<StateSaleModel[]>({
    queryKey: ['STATE_SALES', data],
    queryFn: async () => {
      const res = await $api.post(endPoints.STATE_SALES, data);

      return res.data.data;
    },
    onError: baseErrorHandler,
    retry: false,
    refetchOnWindowFocus: false
  });
}
