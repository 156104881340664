import React from 'react';
import { CompanyLinks } from '@utils/models/CompanyLinks';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { useCompanyLogo } from '../../../../../queries/queries';
import { BASE_URL } from '../../../../../service/RequestService';
import { FlatMore } from '../../../../../utils/models/FlatMore';
import { InformationForPdf } from '../../../../../utils/models/InformationForPdf';

import logo from '../../../../../assets/img/big_logo.png';

import styles from './profile.module.scss';

interface IProps {
  flatData: InformationForPdf | undefined;
  item: FlatMore;
}

const LeftSide: React.FC<IProps> = ({ flatData, item }) => {
  const { data } = useCompanyLogo();
  const { t } = useTranslation();

  const houseLogo = flatData?.houseDTO?.gallery?.find(item => item?.position === 'HOUSE_LOGO');

  const newUrls: CompanyLinks[] | undefined = flatData?.comConfRes?.urls?.map(item => ({
    ...item,
    id: item?.id,
    key: item?.key,
    // @ts-expect-error
    value: flatData?.houseDTO[item?.key?.split('_')[1]?.toLowerCase()] || item?.value
  }));

  const urlConvert = (index: number) => {
    const keys = {
      COMPANY_INSTAGRAM: 'https://www.instagram.com/',
      COMPANY_TELEGRAM: 'https://t.me/',
      COMPANY_FACEBOOK: 'https://www.facebook.com/',
      COMPANY_WEBSITE: 'https://'
    };
    const url = newUrls?.filter(item => item?.value)[index];

    if (!url?.value?.includes('http')) {
      return keys[url?.key as keyof typeof keys] + url?.value?.replace('@', '');
    }
    return url?.value?.replace('@', '');
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.profile} flex flex-col gap-2 px-3`}>
        {
          // eslint-disable-next-line no-nested-ternary
          houseLogo ? (
            <img src={`${BASE_URL}/${houseLogo?.files?.thumbAbsolutePath || houseLogo?.files?.absolutePath}`} alt="err" className="w-full" />
          ) : data?.absolutePath ? (
            <img src={`${BASE_URL}/${data?.thumbAbsolutePath || data?.absolutePath}`} alt="err" className="w-full" />
          ) : (
            <img src={logo} alt="" />
          )
        }
        <p>{t`order.Turar_joy_majmuasi`}</p>
        <h1>{item?.flat?.houseName}</h1>
      </div>
      <div className={styles.center}>
        {(item.flat?.gallery || [])
          .filter(item => item?.position === 'FLAT_FLOOR')
          ?.map(item => (
            <>
              <h3>{t`shaxmatka.Binoda_joylashuvi`}</h3>
              <img src={`${BASE_URL}/${item?.files?.absolutePath}`} alt="" style={{ width: 132 }} />
            </>
          ))}
      </div>

      <div className={styles.footer}>
        {/* {flatData?.comConfRes?.urls?.map((item, index) => (
          <div className={styles?.footer_label} key={item?.id}>
            <p>{t(`company_links.${item?.key}`)}</p>
            <a href={urlConvert(index)} target="_blank" rel="noreferrer" title={item?.value}>
              {item?.value?.length > 20 ? `${item?.value?.slice(0, 20)}...` : item?.value}
            </a>
          </div>
        ))} */}
        {newUrls?.map((item, index) => (
          <div className={styles?.footer_label} key={item?.id}>
            <p>{t(`company_links.${item?.key}`)}</p>
            <a href={urlConvert(index)} target="_blank" rel="noreferrer" title={item?.value}>
              {item?.value?.length > 20 ? `${item?.value?.slice(0, 20)}...` : item?.value}
            </a>
          </div>
        ))}
        <QRCode style={{ height: 'auto', width: '90%' }} value={urlConvert(0) || 'https://uysot.uz'} />
      </div>
    </div>
  );
};

export default LeftSide;
