import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractId } from '../../../../utils/models/ContractId';
import PaymentContractCard from './PaymentContractCard';
import styles from './pyamentContractMoney.module.scss';

interface IProps {
  contractIdData: ContractId | undefined;
}

const PyamentContractMoney: FC<IProps> = ({ contractIdData }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <PaymentContractCard
        color="#00A389"
        currency={contractIdData?.currency}
        title={t('contract_id.Umumiy_summa')}
        total={contractIdData?.amount || 0}
      />
      <PaymentContractCard
        color="#00A389"
        currency={contractIdData?.currency}
        title={t('contract_id.Birinchi_tolov')}
        total={contractIdData?.prepayment || 0}
      />
      <PaymentContractCard
        color="#00A389"
        currency={contractIdData?.currency}
        title={t('contract_id.Tolangan_summa')}
        total={contractIdData?.payed || 0}
      />
      <PaymentContractCard
        color="#00A389"
        currency={contractIdData?.currency}
        title={t('create_table_payments.Chegirma')}
        total={contractIdData?.discount || 0}
        discountDescription={contractIdData?.discountDescription}
      />
      <PaymentContractCard
        color="#FF3F40"
        currency={contractIdData?.currency}
        title={t('contract_id.Qolgan_summa')}
        total={contractIdData?.residue || 0}
      />
      <PaymentContractCard
        color="#FF3F40"
        currency={contractIdData?.currency}
        title={t('contract_id.Oylik_qarzdorlik')}
        total={contractIdData?.debt || 0}
      />
      {contractIdData?.surcharge ? (
        <PaymentContractCard color="#FF3F40" currency={contractIdData?.currency} title={t('home.Peniya')} total={contractIdData?.surcharge || 0} />
      ) : (
        ''
      )}
    </div>
  );
};

export default PyamentContractMoney;
