import React from 'react';
import dayjs from 'dayjs';

import styles from './dateItem.module.scss';
import { TaskFilter } from '@utils/models/TaskFilter';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { ONE_DAY_UNIX_VALUE } from '@utils/constants/ONE_DAY_UNIX_VALUE';

interface Props {
  data: TaskFilter;
}

const DateItem: React.FC<Props> = ({ data }) => {
  const calculateDate = () => {
    const allDay =
      dayjs(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() - dayjs(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() === ONE_DAY_UNIX_VALUE;
    if (allDay) return '';
    return data.deadlineStart + '-' + data.deadlineFinish;
  };

  if (dayjs(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() < dayjs(new Date()).unix() && !data.closedDate)
    return (
      <div className={styles.container_deadline}>
        <div>{data?.leadName || data?.contractNumber}</div>
        <div>
          {calculateDate()} {data.leadTaskType.name}
          {data.text && ': '}
          {data.text}
        </div>
      </div>
    );

  return (
    <div className={styles.container_simple}>
      <div className={styles.container_simple_name}>{data?.leadName || data?.contractNumber}</div>
      <div className={styles.container_simple_title}>
        {calculateDate()}{' '}
        <span>
          {data.leadTaskType.name}
          {data.text && ': '}
        </span>
        {data.text}
      </div>
    </div>
  );
};

export default DateItem;
