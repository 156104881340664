import React from 'react';

const NoteAddIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.9668 15V10.8333" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.99967 12.9167H5.83301" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66699 1.66666V4.16666" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.333 1.66666V4.16666" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.1749 2.85001C15.9582 2.95001 17.3665 3.97501 17.4499 7.89167L17.5582 13.0333C17.6249 16.4667 16.8332 18.1917 12.6665 18.2833L7.66653 18.3833C3.49986 18.4667 2.6332 16.7667 2.56653 13.3417L2.44986 8.19167C2.36653 4.27501 3.74153 3.19167 6.5082 2.98334L13.1749 2.85001Z"
        stroke="#444444"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoteAddIcon;
