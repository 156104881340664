import { Switch } from 'antd';
import React from 'react';
import styles from './marketPlace.module.scss';

const ExtraInformation = () => {
  return (
    <div className={styles.cont_extra}>
      <h3>Qo’shimcha ma’lumotlar:</h3>
      <div className={styles.cont_extra_item}>
        <p>Musaffo</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>Stroy House</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>Marvarid</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>Oqsaroy</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>Iskanja TJM</p>
        <Switch checked={true} />
      </div>
    </div>
  );
};

export default ExtraInformation;
