export enum TableKey {
  home = 'home',
  order = 'order',
  contract = 'contract',
  payment = 'payment',
  arrearage = 'arrearage',
  client = 'client',
  message = 'message',
  employee = 'employee',
  builder = 'builder',
  branch = 'branch',
  broker = 'broker',
  crm = 'crm',
  monetary = 'monetary',
  to_account = 'toAccount',
  to_name = 'toName',
  activity_logs = 'activity-logs',
  showroom_list = 'showroom-list',
  dragg = 'dragg',
  robot_call = "robot_call"
}


export enum TableKeyCustom {
  crm_filter = 'crm-filter',
  crm_voronka = 'crm-voronka',
  dashboard_id = "dashboard-id"
}
