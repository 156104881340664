import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import i18n from '@service/I18nService';
import { encodeString } from '@utils/helper';
import { OneEvent } from '@utils/models/LeadEventsAndTaskList';
import { t } from 'i18next';

import styles from '../client-event/clientEvent.module.scss';

type Props = {
  data: OneEvent;
};

const ClientLeadEvents: React.FC<Props> = ({ data }) => {
  const navigation = useNavigate();
  const goToOneLead = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.CRM}/${id}/${encodeString(name)}`);
  };

  if (data?.type === 'CREATE_LED') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('one_lid.Yangi_lead_yaratildi')}:</span>
        <span>{data?.leadCurrentName}</span>
      </div>
    );
  }
  if (data?.type === 'CREATE_CONTACT') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('one_lid.Yangi_kontakt_yaratildi')}</span>
        <span>{data?.contactCurrentName}</span>
      </div>
    );
  }
  if (data?.type === 'CREATE_CONTACT_PHONE') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('client_events.Telefon_raqami_yaratildi')}</span>
        <span>{data?.contactCurrentPhone}</span>
      </div>
    );
  }
  if (data?.type === 'REMOVE_CONTACT') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('client_events.Kontakt_olib_tashlandi')}</span>
        <span>{data?.contactPreviousPhone}</span>
      </div>
    );
  }
  if (data?.type === 'REMOVE_CLIENT_FROM_LEAD') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('client_events.lead_dan_olib_tashlandi')}</span>
        <span>{data?.clientName}</span>
      </div>
    );
  }
  if (data?.type === 'SET_VALUE') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>
          {data?.fieldName} {t('client_events.qiymat_ozgartirildi')} {t('one_lid.Avvaligi_qiymati')}
          <p>
            {data?.prevAddress
              ? `${data?.prevAddress[`country_${i18n.language}` as keyof typeof data.prevAddress]}, ${data?.prevAddress[`region_${i18n.language}` as keyof typeof data.prevAddress] === null
                ? ''
                : data?.prevAddress[`region_${i18n.language}` as keyof typeof data.prevAddress]
              } ${data?.prevAddress[`region_${i18n.language}` as keyof typeof data.prevAddress] === null ? '' : ','} ${data?.prevAddress[`city_${i18n.language}` as keyof typeof data.prevAddress] === null
                ? ''
                : data?.prevAddress[`city_${i18n.language}` as keyof typeof data.prevAddress]
              } ${data?.prevAddress[`city_${i18n.language}` as keyof typeof data.prevAddress] === null ? '' : ','
              } ${data?.prevAddress?.street && data?.prevAddress?.street}`
              : data?.previous}
          </p>
          , {t('one_lid.Hozirgi_qiymati')}{' '}
          <p>
            {data?.currentAddress
              ? `${data?.currentAddress[`country_${i18n.language}` as keyof typeof data.currentAddress]}, ${data?.currentAddress[`region_${i18n.language}` as keyof typeof data.currentAddress] === null
                ? ''
                : data?.currentAddress[`region_${i18n.language}` as keyof typeof data.currentAddress]
              } ${data?.currentAddress[`region_${i18n.language}` as keyof typeof data.currentAddress] === null ? '' : ','} ${data?.currentAddress[`city_${i18n.language}` as keyof typeof data.currentAddress] === null
                ? ''
                : data?.currentAddress[`city_${i18n.language}` as keyof typeof data.currentAddress]
              }  ${data?.currentAddress?.street}`
              : data?.current}
          </p>
        </span>
        <span>{data?.clientName}</span>
      </div>
    );
  }
  if (data?.type === 'CREATE_TAG') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('client_events.Yangi_teg_qoshildi')} </span>
        <span>{data?.currentTagName}</span>
      </div>
    );
  }
  if (data?.type === 'CHANGE_LEAD_EMPLOYEE') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>
          {t('one_lid.Masul_shaxs_ozgartirildi')} {t('one_lid.Avvaligi_qiymati')} <p>{data?.previousEmployeeName}</p>,{t('one_lid.Hozirgi_qiymati')}{' '}
          <p>{data?.currentEmployeeName}</p>
        </span>
        <span>{data?.currentTagName}</span>
      </div>
    );
  }
  if (data?.type === 'LEAD_MERGE') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('one_lid.birlashtirdi')}:</span>
        <span onClick={() => goToOneLead(Number(data?.mergeLeadId), data?.mergeLeadName!)}>
          <p style={{ cursor: 'pointer' }}>{data?.mergeLeadName}</p>
        </span>
      </div>
    );
  }
  if (data?.type === 'RECOVERY_LEAD') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('client_events.Leadni_qayta_tikladi')} </span>
      </div>
    );
  }
  if (data?.type === 'SET_LEAD_EMPLOYEE') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>{t('one_lid.Masul_shaxs_biriktirildi')}</span>
        <span>{data?.currentEmployeeName}</span>
      </div>
    );
  }
  if (data?.type === 'CHANGE_STATUS') {
    return (
      <div className={styles.events}>
        <span className={styles.events_date}>{data?.date}</span>
        <span className={styles.events_date}>{data?.createdBy}</span>
        <span className={styles.events_date}>Yangi bosqichga o'tkazildi:</span>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 20,
            height: 22,
            overflow: 'hidden',
            border: '1px solid #E1E3E6'
          }}
        >
          {data?.current && (
            <span
              style={{
                padding: '4px 8px',
                backgroundColor: '#fff',
                fontSize: 12
              }}
            >
              {data?.current}
            </span>
          )}
          {data?.previous && (
            <span
              style={{
                padding: '4px 8px',
                backgroundColor: data?.previousColor,
                color: '#fff',
                fontSize: 12
              }}
            >
              {data?.previous}
            </span>
          )}
        </div>
      </div>
    );
  }

  return <div />;
};

export default ClientLeadEvents;
