import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const TaskSquareIcon: React.FC<Props> = ({ onClick, className }) => (
  <svg className={className} onClick={onClick} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6201 9.61914H12.3701C11.9601 9.61914 11.6201 9.27914 11.6201 8.86914C11.6201 8.45914 11.9601 8.11914 12.3701 8.11914H17.6201C18.0301 8.11914 18.3701 8.45914 18.3701 8.86914C18.3701 9.27914 18.0401 9.61914 17.6201 9.61914Z"
      fill="white"
    />
    <path
      d="M7.11957 10.3803C6.92957 10.3803 6.73957 10.3103 6.58957 10.1603L5.83957 9.41031C5.54957 9.12031 5.54957 8.64031 5.83957 8.35031C6.12957 8.06031 6.60957 8.06031 6.89957 8.35031L7.11957 8.57031L8.83957 6.85031C9.12957 6.56031 9.60957 6.56031 9.89957 6.85031C10.1896 7.14031 10.1896 7.62031 9.89957 7.91031L7.64957 10.1603C7.50957 10.3003 7.31957 10.3803 7.11957 10.3803Z"
      fill="white"
    />
    <path
      d="M17.6201 16.6191H12.3701C11.9601 16.6191 11.6201 16.2791 11.6201 15.8691C11.6201 15.4591 11.9601 15.1191 12.3701 15.1191H17.6201C18.0301 15.1191 18.3701 15.4591 18.3701 15.8691C18.3701 16.2791 18.0401 16.6191 17.6201 16.6191Z"
      fill="white"
    />
    <path
      d="M7.11957 17.3803C6.92957 17.3803 6.73957 17.3103 6.58957 17.1603L5.83957 16.4103C5.54957 16.1203 5.54957 15.6403 5.83957 15.3503C6.12957 15.0603 6.60957 15.0603 6.89957 15.3503L7.11957 15.5703L8.83957 13.8503C9.12957 13.5603 9.60957 13.5603 9.89957 13.8503C10.1896 14.1403 10.1896 14.6203 9.89957 14.9103L7.64957 17.1603C7.50957 17.3003 7.31957 17.3803 7.11957 17.3803Z"
      fill="white"
    />
    <path
      d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
      fill="white"
    />
  </svg>
);

export default TaskSquareIcon;
