import React from 'react';

const NoteRemoveIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83398 11.6667H10.0007" stroke="#FF3F40" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.83398 4.96655L2.70898 1.84155" stroke="#FF3F40" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.80078 1.875L2.67578 5" stroke="#FF3F40" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.83398 8.33325H12.5007" stroke="#FF3F40" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.33398 1.66675H13.334C16.109 1.81675 17.5007 2.84175 17.5007 6.65841V13.3334"
        stroke="#FF3F40"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.5083V13.3166C2.5 16.6583 3.33333 18.3333 7.5 18.3333H10C10.1417 18.3333 12.3667 18.3333 12.5 18.3333"
        stroke="#FF3F40"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 13.3333L12.5 18.3333V15.8333C12.5 14.1666 13.3333 13.3333 15 13.3333H17.5Z"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoteRemoveIcon;
