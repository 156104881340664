import React from 'react';

const ColapseIcon = () => {
  return (
    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.911091 1.01119C1.12588 0.796401 1.47412 0.796401 1.68891 1.01119L3.5 2.82228L5.31109 1.01119C5.52588 0.796401 5.87412 0.796401 6.08891 1.01119C6.3037 1.22598 6.3037 1.57422 6.08891 1.78901L3.88891 3.98901C3.67412 4.20379 3.32588 4.20379 3.11109 3.98901L0.911091 1.78901C0.696303 1.57422 0.696303 1.22598 0.911091 1.01119Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};

export default ColapseIcon;
