import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { InviteClientModel } from '@pages/oneLid/utils/models/InviteClientModel';
import { useGetSearchLeadTasks } from '@queries/queries';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { Checkbox, Input, Modal, Spin } from 'antd';
import { t } from 'i18next';

import MergeModal from '../duplicate-modal/MergeModal';

import styles from './controlTag.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  leadClient: InviteClientModel | undefined;
};

const SearchLidModal: React.FC<Props> = ({ setVisible, visible, leadClient }) => {
  const dispatch = useDispatch();
  const { remove, params } = useQueryParam<SearchParamsCrm, any>();
  const [value, setValue] = useState<string>('');
  const [ids, setIds] = useState<number[]>([Number(params?.id)]);
  const [openModal, setOpenModal] = useState(false);
  const [scrolling, setScrolling] = useState(true);

  const { data, isLoading, fetchNextPage } = useGetSearchLeadTasks({
    notMerge: !!leadClient,
    search: value?.length! >= 3 ? value! : ''
  });

  const handleClose = () => {
    setVisible(false);
    setValue('');
    remove('search', undefined);
  };

  const handleCheck = (value: number[]) => {
    setIds([Number(params?.id), ...value]);
  };

  const handleOpenMergeModal = () => {
    setOpenModal(true);
    dispatch(setBalanceAndIds({ balance: 0, ids }));
    handleClose();
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={handleClose}
        title={t('one_lid.lid_qidirish')}
        className={styles.searchModal}
        width={700}
        onOk={handleOpenMergeModal}
        okButtonProps={{ disabled: ids?.length < 2 }}
        okText={t('home.Birlashtirish')}
        cancelText={t('one_lid.Bekor_qilish')}
      >
        <div className={styles.searchInput}>
          <Input placeholder={t('one_lid.lid_qidirish')} value={value} onChange={e => setValue(e.target.value)} />
        </div>
        <Spin spinning={isLoading} style={{ width: '100%', marginTop: '10px' }}>
          {data && (
            <div className={styles.searchModal_leads} onScroll={e => onScroll(e)}>
              <Checkbox.Group onChange={(value: any) => handleCheck(value)}>
                {data?.pages?.map(data =>
                  data?.data?.map(item => (
                    <div className={styles.searchModal_leads_oneLead} key={item?.id}>
                      <Checkbox key={item?.id} value={item?.id}>
                        <p>{item?.name?.length >= 20 && item?.responsibleBy?.length! >= 20 ? `${item?.name.substring(0, 20)}...` : item?.name}</p>
                        <div className={styles.pipes}>
                          <span>{item?.pipeStatusResponse?.pipeName}</span>
                          <div
                            style={{
                              backgroundColor: item?.pipeStatusResponse?.color
                            }}
                          >
                            {item?.pipeStatusResponse?.name}
                          </div>
                        </div>
                        <span>
                          {item?.responsibleBy?.length! >= 20 && item?.name?.length >= 20
                            ? `${item?.responsibleBy?.substring(0, 20)}...`
                            : item?.responsibleBy}
                        </span>
                      </Checkbox>
                    </div>
                  ))
                )}
              </Checkbox.Group>
            </div>
          )}
        </Spin>
      </Modal>
      <MergeModal setVisible={setOpenModal} visible={openModal} leadId={Number(params?.id)} />
    </>
  );
};

export default SearchLidModal;
