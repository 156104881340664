import React from 'react';

const ArrowDownWhiteIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="white" />
      <path
        d="M18.582 23.5408L15.0412 19.9999L18.582 16.4591"
        stroke="#1D343A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.959 20L15.1415 20" stroke="#1D343A" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowDownWhiteIcon;
