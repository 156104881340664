import { Checkbox, Collapse, Drawer, Space, Spin } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ColapseIcon from '../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../assets/icons/FIlterColapseIcon';
import FilterReloadIcon from '../../assets/icons/FilterReloadIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { EmployeeStatus } from '../../utils/models/EmployeeList';
import { SearchParamsEmployee } from '../../pages/settingEmployee/SettingEmployee';
import { useEmployeeRole } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';

const { Panel } = Collapse;

interface PaymentFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const EmployeeFilterDriver: FC<PaymentFilterDrawerProps> = ({ visible, setVisible }) => {
  const { searchParams, removeAllSearchParams, remove, appendMultiple } = useQueryParam<SearchParamsEmployee, any>();
  const { t } = useTranslation();

  const { data: roleData, isLoading: loadingRole } = useEmployeeRole();

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const changeEmployeeStatus = (value: EmployeeStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else {
      if (searchParams.statuses === value) {
        remove('statuses', value);
      } else {
        appendMultiple('statuses', value);
      }
    }
  };

  const changeRole = (id: string) => {
    if (Array.isArray(searchParams.roles)) {
      searchParams.roles.includes(String(id)) ? remove('roles', id) : appendMultiple('roles', id);
    } else {
      if (searchParams.roles === id) {
        remove('roles', id);
      } else {
        appendMultiple('roles', id);
      }
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className={'filter_drawer'}>
      <Spin spinning={loadingRole}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition={'right'}
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Status')}
              </>
            }
            key="1"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeEmployeeStatus(EmployeeStatus.ENABLED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(EmployeeStatus.ENABLED)
                    : searchParams.statuses === EmployeeStatus.ENABLED
                }
              >
                {t(`home.${EmployeeStatus.ENABLED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeEmployeeStatus(EmployeeStatus.LOCKED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(EmployeeStatus.LOCKED)
                    : searchParams.statuses === EmployeeStatus.LOCKED
                }
              >
                {t(`home.${EmployeeStatus.LOCKED}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeEmployeeStatus(EmployeeStatus.EXPIRED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(EmployeeStatus.EXPIRED)
                    : searchParams.statuses === EmployeeStatus.EXPIRED
                }
              >
                {t(`home.${EmployeeStatus.EXPIRED}`)}
              </Checkbox>
            </Space>
          </Panel>
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Lavozim')}
              </>
            }
            key="3"
          >
            <Space direction="vertical" style={{ maxHeight: '40vh', overflowY: 'auto', width: '100%' }}>
              {roleData?.map((role, index) => (
                <Checkbox
                  checked={Array.isArray(searchParams.roles) ? searchParams.roles.includes(String(role.id)) : searchParams.roles === String(role.id)}
                  onChange={() => changeRole(String(role.id))}
                  key={index}
                >
                  {getCurrentLanguageWord(role)}
                </Checkbox>
              ))}
            </Space>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default EmployeeFilterDriver;
