import { $api } from "@service/RequestService"
import { useQuery } from "react-query"

import { endPoints } from "../end-points"
import { keys } from "../keys"
import { housesObjectSchema, UzumHouses } from "../schema"

interface IQUery {
  houses: UzumHouses[]
}


export const useUzumHouses = () => {

  const initialValue = { houses: [] };

  const { data = initialValue, ...args } = useQuery<IQUery>(
    [keys.UZUM_HOUSES],
    async () => {
      const { data } = await $api.get(endPoints.UZUM_HOUSES);

      const houses: UzumHouses[] = (data?.data || []).map(housesObjectSchema.parse);

      return { houses };
    }
  );

  return { ...data, ...args };
};
