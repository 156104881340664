import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setPlanFactDate, setPlanFactFormatDate } from '@reducers/CrmStatisticsSlice';
import { DATE_FORMAT } from '@utils/constants';
import { START_DATE_DEFAULT } from '@utils/constants/startDateDefault';
import { BoosComplexSelectType } from '@utils/models/BoosComplexSelectType';
import { Select } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const DateSelect: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [specialModal, setSpecialModal] = useState(false);
  const { formatDate } = useAppSelector(state => state.crmStatistic.planFact);

  const setNow = () => {
    dispatch(
      setPlanFactDate({
        finishDate: dayjs(new Date()).format(DATE_FORMAT),
        startDate: START_DATE_DEFAULT
      })
    );
  };

  const setToday = () => {
    dispatch(
      setPlanFactDate({
        finishDate: dayjs(new Date()).format(DATE_FORMAT),
        startDate: dayjs(new Date()).format(DATE_FORMAT)
      })
    );
  };

  const setYesterday = () => {
    dispatch(
      setPlanFactDate({
        finishDate: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT),
        startDate: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT)
      })
    );
  };

  const setThisWeek = () => {
    if (dayjs(new Date()).startOf('week').format(DATE_FORMAT) === dayjs(new Date()).format(DATE_FORMAT)) {
      dispatch(
        setPlanFactDate({
          finishDate: dayjs(new Date()).format(DATE_FORMAT),
          startDate: dayjs(new Date()).add(-6, 'day').format(DATE_FORMAT)
        })
      );
    } else {
      dispatch(
        setPlanFactDate({
          finishDate: dayjs(new Date()).format(DATE_FORMAT),
          startDate: dayjs(new Date()).startOf('week').add(1, 'day').format(DATE_FORMAT)
        })
      );
    }
  };

  const setThisMonth = () => {
    dispatch(
      setPlanFactDate({
        finishDate: dayjs(new Date()).format(DATE_FORMAT),
        startDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT)
      })
    );
  };

  const onSpecialModal = (visible: boolean) => {
    setSpecialModal(visible);
  };

  const setSpecial = () => {
    onSpecialModal(true);
  };

  const onChangeDate = (value: string) => {
    dispatch(setPlanFactFormatDate(value));

    switch (value) {
      case BoosComplexSelectType.TODAY:
        setToday();
        break;
      case BoosComplexSelectType.YESTERDAY:
        setYesterday();
        break;
      case BoosComplexSelectType.THIS_WEEK:
        setThisWeek();
        break;
      case BoosComplexSelectType.THIS_MONTH:
        setThisMonth();
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        break;
      default:
        setNow();
        break;
    }
  };

  return (
    <>
      <Select className="customSelect" defaultValue={BoosComplexSelectType.THIS_MONTH} placeholder={t('boss_complex.Tanlang')} value={formatDate} onChange={onChangeDate}>
        <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
        <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
        <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
        <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
        <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
        {/* <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option> */}
      </Select>
    </>
  );
};

export default DateSelect;
