import { Button, Form, Input, Modal } from 'antd';
import { t } from 'i18next';
import React, { FC } from 'react';
import TrashIcon from '../../assets/icons/TrashIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useContractDelete } from '../../queries/mutation';

interface ContractDeleteProps {
  visible: boolean;
  setVisible: (i: boolean) => void;
}

const ContractDelete: FC<ContractDeleteProps> = ({ visible, setVisible }) => {
  const contractDeleteMutation = useContractDelete();
  const { params } = useQueryParam();
  const handleCancel = () => {
    setVisible(false);
  };

  const handleDelete = (values: any) => {
    contractDeleteMutation.mutate({
      id: Number(params.id),
      cancelText: values.cancelText
    });
    setVisible(false);
  };
  return (
    <Modal title={false} visible={visible} footer={null} closable={false} width={420} centered onCancel={handleCancel} className="contractDelete">
      <Form onFinish={handleDelete}>
        <span>
          <TrashIcon />
        </span>
        <h1>{t('contract_id.Shartnomani_bekor_qilmoqchimisiz')}</h1>
        <p>{t('contract_id.Bekor_qilingan_shartnoma_ustida_hech_qanday_amal_bajarib_bolmaydi!')}</p>
        <Form.Item
          //   noStyle
          name={'cancelText'}
          rules={[{ required: true, message: t('contract_id.Izoh_kiriting') }]}
        >
          <Input.TextArea
            placeholder={t('contract_id.Izoh_matnini_kiriting')}
            autoSize={{ minRows: 4, maxRows: 4 }}
            style={{ borderRadius: '16px' }}
          />
        </Form.Item>
        <div>
          <Button onClick={handleCancel}>{t('contract_id.Yopish')}</Button>
          <Button type="primary" htmlType="submit">
            {t('arrearage.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ContractDelete;
