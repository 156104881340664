import React, { useEffect, useState } from 'react';
import styles from './securitySetting.module.scss';
import { Input } from 'antd';
import { useEmployeeChangePasswordDateGet } from '../../../queries/queries';
import { useEmployeeChangePasswordDate } from '../../../queries/mutation';
import SuccessIconCreateOrder from '../../../assets/icons/SuccessIconCreateOrder';
import { LoadingOutlined } from '@ant-design/icons';
import { helper } from '../../../utils/helper/helper';
import { useTranslation } from 'react-i18next';

const SecuritySetting = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const { data } = useEmployeeChangePasswordDateGet();
  const employeeChangePasswordDateMutate = useEmployeeChangePasswordDate();

  useEffect(() => {
    if (data) {
      setValue(data);
    }
  }, [data]);

  const changeInput = (e: string) => {
    setValue(String(helper.deleteNotNumbers(e, 0)));
  };

  const inputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.currentTarget.value !== data) {
      employeeChangePasswordDateMutate.mutate(e.currentTarget.value);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div>
          <h1>{t('security.Parolni_almashtirish_muddati')}</h1>
          <p>{t('security.security_desc')}</p>
        </div>
        <div className={styles.item_input}>
          <Input
            suffix={t('one_lid.kun')}
            value={value}
            onChange={e => changeInput(e.target.value)}
            onKeyDown={inputKeyDown}
            type="number"
            className="customInput"
          />
          {data !== value &&
            (employeeChangePasswordDateMutate.isLoading ? (
              <span>
                <LoadingOutlined />
              </span>
            ) : (
              <span onClick={() => employeeChangePasswordDateMutate.mutate(value)}>
                <SuccessIconCreateOrder size={24} />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SecuritySetting;
