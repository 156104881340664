import React, { cloneElement } from 'react';
import { Controller, ControllerProps, UseFormReturn } from 'react-hook-form';
import { cx } from '@utils/cx';

interface IProps {
  children?: ((props: UseFormReturn<any>) => JSX.Element) | JSX.Element;
  formProps?: UseFormReturn<any>;
  name?: string;
  label?: React.ReactNode;
  labelClassName?: string;
  className?: string;
  getValueProps?: string;
  rules?: ControllerProps['rules'];
  defaultValue?: any;
  labelFor?: string;
}

const Item = ({ children, formProps, name, label, labelClassName, className, getValueProps, rules, defaultValue, labelFor }: IProps) => (
  <label className={cx('mb-4 flex w-full flex-col gap-1', className)} htmlFor={labelFor}>
    <div className={cx('text-[12px] font-normal text-gray-500', labelClassName)}>{label}</div>
    <div>
      <Controller
        name={name || ''}
        defaultValue={defaultValue}
        {...(formProps?.control && {
          control: formProps?.control
        })}
        rules={rules}
        render={props => {
          const obj: Record<string, any> = {};

          if (getValueProps) {
            obj[getValueProps] = props.field.value;
          }

          return children ? (
            <>
              {cloneElement(typeof children === 'function' ? children(formProps!) : children, {
                onChange: (v: any) => {
                  const value = v?.target?.value || v;

                  props.field?.onChange(value);

                  if (typeof children === 'function') {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    children(formProps!).props?.onChange && children(formProps!).props?.onChange(value);
                  } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    children?.props?.onChange && children?.props?.onChange(value);
                  }
                },
                value: props.field.value,
                status: props?.fieldState?.error ? 'error' : '',
                error: props?.fieldState?.error,
                ...obj
              })}
              {props?.fieldState?.error?.message ? <p className="text-[12px] !text-red-600">{props?.fieldState?.error?.message}</p> : ''}
            </>
          ) : (
            <></>
          );
        }}
      />
    </div>
  </label>
);

export default Item;
