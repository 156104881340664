import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import MessageIcon from '@icons/MessageIcon';
import PremiumClientIcon from '@icons/PremiumClientIcon';
import VipClientIcon from '@icons/VipClientIcon';
import WarningIcon from '@icons/WarningIcon';
import ClientMessageModal from '@pages/client/components/ClientMessageModal';
import ClientModal from '@pages/client/components/ClientModal';
import MenuDropDown from '@pages/client/components/MenuDropDown';
import { useGetClientView } from '@pages/oneLid/services/queries';
import { useClientId } from '@queries/queries';
import { setOpenClientMessage } from '@reducers/CrmOneLidSlice';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './clientCard.module.scss';

const ClientCard = () => {
  const dispatch = useDispatch();
  const { params } = useQueryParam();
  const { t } = useTranslation();
  const { clientIsVisible } = useAppSelector(state => state.crmOneLidField);
  const { data } = useGetClientView(Number(params?.idClient));
  const { data: client } = useClientId(Number(params?.idClient));
  const [clientInformation, setClientInformation] = useState({
    id: -1,
    isVisible: false
  });

  const handleCloseMessageModal = () => {
    dispatch(
      setOpenClientMessage({
        id: params?.idClient,
        isVisible: false,
        name: params?.number,
        phone: client?.phones[0]?.phone!
      })
    );
  };

  const handleOpenMessageModal = () => {
    dispatch(
      setOpenClientMessage({
        id: params?.idClient,
        isVisible: true,
        name: params?.number,
        phone: client?.phones[0]?.phone!
      })
    );
  };

  const openClientInformation = (id: number) => {
    setClientInformation({
      id,
      isVisible: true
    });
  };

  const closeClientInformation = () => {
    setClientInformation({
      id: -1,
      isVisible: false
    });
  };

  return (
    <>
      <div className={styles.clientInfo}>
        <div className={styles.clientInfo_top}>
          <div className={styles.topLeft}>
            <div className={styles.circle}>{params?.number?.substring(0, 1)}</div>
            <div className={styles.clientName}>
              <p>
                <div className="flex items-center gap-1">
                  <span>{params?.number?.length > 28 ? `${params?.number?.substring(0, 28)}...` : params?.number}</span>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {client?.level === 'VIP' ? (
                    <Tooltip
                      overlayStyle={{ maxWidth: '490px' }}
                      overlay={
                        <div className="flex items-center gap-1">
                          <VipClientIcon />
                          <span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>
                        </div>
                      }
                    >
                      <div className="flex items-center justify-center">
                        <VipClientIcon />
                      </div>
                    </Tooltip>
                  ) : client?.level === 'REGULAR' ? (
                    <Tooltip
                      overlay={
                        <div className="flex items-center gap-1">
                          <PremiumClientIcon />
                          <span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>
                        </div>
                      }
                      overlayStyle={{ maxWidth: 440 }}
                    >
                      <div className="flex items-center justify-center">
                        <PremiumClientIcon />
                      </div>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
                {client?.passportExpired && (
                  <Tooltip overlay="Passport malumotlari eskirgan">
                    <div className={styles.warning}>
                      <WarningIcon />
                    </div>
                  </Tooltip>
                )}
              </p>
              <div className={styles.clientName_contacts}>{data?.phones?.map(item => <span key={item?.id}>{item?.phone}</span>)}</div>
            </div>
          </div>
          <div className={styles.topRight}>
            <div className={styles.circ} onClick={handleOpenMessageModal}>
              <MessageIcon />
            </div>
            <MenuDropDown id={params?.idClient} name={params?.number} phone={client?.phones[0]?.phone!} type={true} />
          </div>
        </div>
        {data?.type === 'NaturalPerson' && (
          <>
            <div className={styles.clientInfo_line} />
            <div className={styles.clientInfo_bottom}>
              <div className={styles.clientInfo_bottom_item}>
                <span>{t('home.Passport')}</span>
                <p>{data?.passportNumber && data?.passportSerial ? `${data?.passportSerial} ${data?.passportNumber}` : '-'}</p>
              </div>
              <div className={styles.clientInfo_bottom_lineB} />
              <div className={styles.clientInfo_bottom_item}>
                <span>{t('client.Tugilgan_kuni')}</span>
                <p>{data?.dateBirth ? data?.dateBirth : '-'}</p>
              </div>
              <div className={styles.clientInfo_bottom_lineB} />
              <div className={styles.clientInfo_bottom_item}>
                <span>{t('home.Manzil')}</span>
                <p>
                  {data?.city && data?.district
                    ? `${data?.city?.includes('Respublikasi') ? data?.city?.replace('Respublikasi', 'R') : data?.city} , ${data?.district?.substring(0, 8)}...`
                    : '-'}
                </p>
              </div>
            </div>
          </>
        )}
        <p className={styles.clientInfo_more} onClick={() => openClientInformation(Number(params?.idClient))}>
          {t('home.Toliq_malumot')}
        </p>
      </div>
      <ClientModal visible={clientInformation.isVisible} close={closeClientInformation} clientId={clientInformation.id} />
      <ClientMessageModal
        modalWidth={689}
        visible={clientIsVisible}
        close={handleCloseMessageModal}
        name={params?.number}
        phoneNumber={client?.phones[0]?.phone!}
        clientId={params?.idClient}
      />
    </>
  );
};

export default ClientCard;
