import React from 'react';

const DotsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.0013 10.834C10.4615 10.834 10.8346 10.4609 10.8346 10.0007C10.8346 9.54041 10.4615 9.16732 10.0013 9.16732C9.54106 9.16732 9.16797 9.54041 9.16797 10.0007C9.16797 10.4609 9.54106 10.834 10.0013 10.834Z"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0013 5.00065C10.4615 5.00065 10.8346 4.62755 10.8346 4.16732C10.8346 3.70708 10.4615 3.33398 10.0013 3.33398C9.54106 3.33398 9.16797 3.70708 9.16797 4.16732C9.16797 4.62755 9.54106 5.00065 10.0013 5.00065Z"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0013 16.6673C10.4615 16.6673 10.8346 16.2942 10.8346 15.834C10.8346 15.3737 10.4615 15.0007 10.0013 15.0007C9.54106 15.0007 9.16797 15.3737 9.16797 15.834C9.16797 16.2942 9.54106 16.6673 10.0013 16.6673Z"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DotsIcon;
