import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import ArrowLeftIcon from '../../../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../../../assets/icons/contract/ArrowRightIcon';
import { ColumnsType } from 'antd/lib/table';
import { useActiveSessions } from '../../../../queries/queries';
import TrushIcon from '../../../../assets/icons/crmIcons/TrushIcon';
import styles from './profileSecyurityTable.module.scss';
import { useActiveSessionDelete } from '../../../../queries/mutation';
import { ActiveSession } from '../../../../utils/models/ActiveSessions';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import GlphIcon from '../../../../assets/icons/GlphIcon';

const ProfileSecyurityTable = () => {
  const { t } = useTranslation();
  const { appendMultipleDifferent, searchParams } = useQueryParam<any, any>();
  const { data, isLoading } = useActiveSessions();
  const deleteSession = useActiveSessionDelete();
  const [offsetTop, setOffsetTop] = useState(0);
  const [actionWidth, setActionWidth] = useState(100);

  const columns: ColumnsType<any> | undefined = [
    {
      title: t('home.№'),
      render(value, record, index) {
        return index + 1;
      },
      width: 100
    },
    { title: t('security.Songi_faollik'), dataIndex: 'lastActiveDateTime' },
    { title: t('security.Qurilma'), dataIndex: 'device' },
    { title: t('security.Ilova'), dataIndex: 'app' },
    { title: t('security.IP_Manzil'), dataIndex: 'ipAddress' },
    {
      title: (
        <div className={styles.locationTitle}>
          <Tooltip overlayClassName={styles.locationTitle_tooltip} overlay={<div>{t('security.Bu_joylashuv_taxmini')}</div>}>
            <span>
              <GlphIcon />
            </span>{' '}
          </Tooltip>
          {t('security.Joylashuv')}
        </div>
      ),
      dataIndex: 'location'
    },
    {
      title: t('security.Active'),
      dataIndex: 'active',
      render(value, record, index) {
        return value ? t('home.ACTIVE') : 'No faol';
      }
    },
    {
      title: t('shaxmatka.Amallar'),
      render(value, record, index) {
        return record?.id === data?.activeSession?.id ? (
          <p
            className={styles.closedAllSession}
            ref={ref => setActionWidth(ref?.offsetWidth || 100)}
            onClick={() => handleDeleteSession(record, true)}
          >
            {t('home.Boshqa_barcha_sessiyalarni_tugatish')}
          </p>
        ) : (
          <span
            className={styles.closedSession}
            onClick={() => handleDeleteSession(record, false)}
            ref={ref => setActionWidth(prev => (prev < ref?.offsetWidth! ? ref?.offsetWidth! : prev))}
          >
            <TrushIcon color="#FF3F40" />
          </span>
        );
      },
      width: actionWidth + 32
    }
  ];

  const handleDeleteSession = (record: ActiveSession, active: boolean) => {
    deleteSession.mutate({
      allSession: active,
      id: record?.id
    });
  };

  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(['page', 'size'], [page, size]);
  };

  const isPages = () => {
    return data?.otherActiveSessions?.totalPages! > 1;
  };

  return (
    <Table
      columns={columns}
      loading={isLoading}
      dataSource={
        data?.activeSession ? [data?.activeSession, ...(data?.otherActiveSessions?.data || [])] : [...(data?.otherActiveSessions?.data || [])]
      }
      className={'tableNewStyle'}
      style={{ height: `calc(100vh - ${offsetTop}px)` }}
      scroll={{ y: `calc(100vh - ${offsetTop + 50}px)` }}
      ref={ref => setOffsetTop((ref?.offsetTop || 0) + (isPages() ? 80 : 15))}
      pagination={
        isPages()
          ? {
              className: 'tableNewStyle_pagination',
              prevIcon: (
                <div className={'tableNewStyle_pagination_prev'}>
                  <ArrowLeftIcon /> {t('my_calls.Ortga')}
                </div>
              ),
              onChange(page, pageSize) {
                changePage(page, 10);
              },
              pageSize: 10,
              current: Number(searchParams?.page) || 1,
              nextIcon: (
                <div className={'tableNewStyle_pagination_next'}>
                  {t('profile.Keyingi')} <ArrowRightIcon />
                </div>
              )
            }
          : false
      }
    />
  );
};

export default ProfileSecyurityTable;
