import React from 'react';

const ArrowDownBigIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="77" viewBox="0 0 17 77" fill="none">
      <path d="M1 1L16 39.1818L1.65217 76" stroke="#E1E3E6" />
    </svg>
  );
};

export default ArrowDownBigIcon;
