// Импорт необходимых зависимостей
import { FC, useEffect } from 'react';
import { Button, Form, Input, Space, Modal } from 'antd';
import { useCompanyCreateRole } from '../services/mutation';
import { SecurityControlRole } from '../utils/models/SecurityControlRole';
import { useTranslation } from 'react-i18next';
import styles from '../securityControlPermissions.module.scss';

// Определение типа для пропсов модального окна
interface SecuriteControlPModalProps {
  isModalOpen: boolean; // Флаг, указывающий, открыто ли модальное окно
  openModal: () => void; // Функция для открытия модального окна
  closeModal: () => void; // Функция для закрытия модального окна
  role: SecurityControlRole | undefined; // Роль для управления правами безопасности
}

// Компонент модального окна безопасности и контроля прав
const SecuriteControlPModal: FC<SecuriteControlPModalProps> = ({ isModalOpen, closeModal, role }) => {
  // Использование формы для компонента
  const [form] = Form.useForm<SecurityControlRole>();
  const createRole = useCompanyCreateRole();

  // Функция, вызываемая при завершении формы
  const onFinish = (values: SecurityControlRole) => {
    console.log('Success:', values);
    // role* использован метод получения id
    createRole
      .mutateAsync({
        ...(role?.id && { id: role?.id }),
        name: values?.name ? values?.name : values?.nameUz,
        nameUz: values.nameUz,
        nameRu: values.nameRu,
        nameKr: values.nameKr,
        nameEn: values.nameEn,
        nameTr: values.nameTr
      })
      .then(() => {
        closeModal();
        form.resetFields();
      });
  };

  // Использование эффекта useEffect для установки значений формы при изменении роли
  useEffect(() => {
    if (role) {
      form.setFieldsValue({
        ...role
      });
    }
  }, [role, form]);

  // Использование хука useTranslation для получения перевода
  const { t } = useTranslation();

  // Возвращение компонента Modal с формой
  return (
    <div className={styles.test}>
      <Modal
        title={role?.name === '' ? t('online_pbx.Ruxsat_qoshish') : t('online_pbx.Ruxsat_tahrirlash')} // Установка заголовка модального окна в зависимости от наличия роли
        visible={isModalOpen} // Установка видимости модального окна
        onOk={closeModal} // Обработчик для кнопки "Ок"
        className={styles.modal}
        onCancel={() => {
          closeModal();
          form.resetFields();
        }}
        footer={false}
      >
        <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Form.Item name="name" label={t('online_pbx.Ruxsat_nomi')} rules={[{ required: false, message: '' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="nameUz" label={t('online_pbx.Ruxsat_nomi_uz_l')} rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="nameKr" label={t('online_pbx.Ruxsat_nomi_uz_k')} rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="nameEn" label={t('online_pbx.Ruxsat_nomi_en')} rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="nameRu" label={t('online_pbx.Ruxsat_nomi_ru')} rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="nameTr" label={t('online_pbx.Ruxsat_nomi_tr')} rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary" style={{ borderRadius: '6px' }}>
                {t('home.Saqlash')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SecuriteControlPModal;
