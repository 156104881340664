import React, { useState } from 'react';
import { Button, Dropdown, Input, Modal, Spin } from 'antd';
import styles from './importExportModal.module.scss';
import DocumentUploadIcon from '../../../../../assets/icons/DocumentUploadIcon';
import DocumentDownloadIcon from '../../../../../assets/icons/DocumentDownloadIcon';
import InfoIcon from '../../../../../assets/icons/InfoIcon';
import SubtractIcon from '../../../../../assets/icons/SubtractIcon';
import { useTags } from '../../../../../queries/queries';
import { useTranslation } from 'react-i18next';
import { BaseEntity } from '../../../../../utils/models/BaseEntity';
import { useCreateTag, useUploadFile } from '../../../../../queries/mutation';
import { FileObject } from '../../../../../utils/models/File';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { SearchParamsCrm } from '../../filter/FilterButton';
import AktImageDelete from '../../../../contractId/AktImageDelete';
import { useDownloadImportLeadSample, useImportLeads } from '../../../services/mutation';

interface Props {
  visible: boolean;
  close: (isOpen: boolean) => void;
}

const ImportModal: React.FC<Props> = ({ visible, close }) => {
  const [file, setFile] = useState<FileObject>();

  const [tagIds, setTagIds] = useState<number[]>([]);
  const [search, setSearch] = useState('');

  const { searchParams } = useQueryParam<SearchParamsCrm, any>();

  const uploadFile = useUploadFile(getFile, false);
  const importLead = useImportLeads(Number(searchParams.voronka), searchParams, handleSuccess);
  const downloadSample = useDownloadImportLeadSample();

  const input = React.createRef<HTMLInputElement>();

  const { data } = useTags();

  const { t } = useTranslation();

  const createTag = useCreateTag(addNewTag);

  // handle download sample
  const handleDownloadSample = () => {
    downloadSample.mutate();
  };

  // get file
  function getFile(data: FileObject[]) {
    if (data[0]) {
      setFile(data[0]);
    }
  }

  // get file
  function handleSuccess() {
    handleClose();
  }

  //import lead
  const handleImportLead = () => {
    if (file) {
      importLead.mutate({
        tagIds,
        fileId: file.id,
        pipeId: Number(searchParams.voronka)
      });
    }
  };

  // handle click upload
  const handleClickUpload = () => {
    input.current?.click();
  };

  // handle close
  const handleClose = () => {
    setTagIds([]);
    close(false);
  };

  //handle file
  const handleFile: React.ChangeEventHandler<HTMLInputElement> = (e: any) => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  // filter tag
  const filterTag = () => {
    if (!tagIds && data) {
      const tags = data.tagList;
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()));
    } else if (data && tagIds) {
      const tags = data.tagList;
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()) && !tagIds.includes(Number(tag.id)));
    }
    return [];
  };

  // tags
  const tags = () => {
    if (tagIds) {
      if (Array.isArray(tagIds)) {
        return (
          <>
            {tagIds.map((id, index) => {
              const tag = data?.tagList.find(tag => tag.id === Number(id));
              if (tag) {
                return (
                  <span key={index} className={styles.container_tags_tag}>
                    {tag.name}
                    <SubtractIcon onClick={() => changeTags(Number(tag.id))} />
                  </span>
                );
              }
              return null;
            })}
          </>
        );
      }
      const tag = data?.tagList.find(tag => tag.id === Number(tagIds));
      if (tag) {
        return (
          <span className={styles.container_tags_tag}>
            {tag.name}
            <SubtractIcon onClick={() => changeTags(Number(tag.id))} />
          </span>
        );
      }
      return null;
    }
    return;
  };

  // changeTags
  const changeTags = (value: number) => {
    if (tagIds.includes(value)) {
      const newIds = [...tagIds];
      newIds.splice(tagIds.indexOf(value), 1);
      setTagIds(newIds);
    } else {
      setTagIds([...tagIds, value]);
      handleSearch('');
    }
  };

  //handleSearch
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // menu drop down tags
  const menuDropDown = () => {
    if (filterTag().length === 0) {
      return <></>;
    }
    return (
      <div className={styles.container_header_dropdown_teg}>
        {filterTag()?.map(tag => (
          <div onClick={() => changeTags(Number(tag.id))} key={tag.id} className={styles.container_header_dropdown_teg_item}>
            {tag.name}
          </div>
        ))}
      </div>
    );
  };

  // handle press key
  const handlePresKey = () => {
    if (search) {
      const tags = filterTag();
      const findTeg = tags.find(tag => tag.name === search);
      if (findTeg) {
        addNewTag(findTeg);
      } else {
        createTag.mutate(search);
      }
    }
  };

  // add new tag
  function addNewTag(tag: BaseEntity) {
    setTagIds([...tagIds, Number(tag.id)]);
    setSearch('');
  }

  // on delete file
  const handleDeleteFile = () => {
    setFile(undefined);
  };

  return (
    <Modal className={styles.container} width={560} footer={false} visible={visible} title={t('crm_import.Import')} onCancel={handleClose}>
      <Spin spinning={uploadFile.isLoading || importLead.isLoading || downloadSample.isLoading}>
        <p className={styles.container_title}>{t('crm_import.Siz_bir_vaqtning_ozida_mijozlar')}</p>
        <div className={`${styles.container_sub_information} d-flex align-center`}>
          <InfoIcon />
          <span>{t('crm_import.Teglar_orasi_vergul_bilan')}</span>
        </div>
        <div className={styles.container_import}>
          <div onClick={handleClickUpload} className={styles.container_import_import}>
            <DocumentUploadIcon />
            <span>{t('crm_import.Faylni_yuklash')}</span>
          </div>
          <div onClick={handleDownloadSample} className={styles.container_import_export}>
            <DocumentDownloadIcon />
            <span>{t('crm_import.Namunani_yuklab_olish')}</span>
          </div>
        </div>
        <div className="aktFiles">{file && <AktImageDelete id={file.id} name={file.originalName} onDeleteFile={handleDeleteFile} />}</div>
        <Dropdown overlayClassName={styles.container_header_dropdown} overlay={menuDropDown} placement="bottom">
          <div className={styles.container_tags}>
            {tags()}
            <Input
              onPressEnter={handlePresKey}
              value={search}
              onChange={e => handleSearch(e.target.value)}
              bordered={false}
              placeholder={t('crm_filter.Tegni_izlash')}
            />
          </div>
        </Dropdown>
        {!file && (
          <input
            ref={input}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleFile}
            type={'file'}
            style={{ display: 'none' }}
          />
        )}
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button disabled={!file} onClick={handleImportLead} type="primary">
              {t('crm_import.Yuklash')}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ImportModal;
