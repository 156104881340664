import React from 'react';
import styles from './clientBalance.module.scss';
import ClientBalanceIn from '../../assets/icons/ClientBalanceIn';
import ClientBalanceOut from '../../assets/icons/ClientBalanceOut';

interface Props {
  type: 'in' | 'out';
  title: string;
  openModal: (type: 'out' | 'in') => void;
}

const PaymentInOutButton: React.FC<Props> = ({ title, type, openModal }) => {
  const handleClick = () => {
    openModal(type);
  };

  return (
    <div onClick={handleClick} className={styles.out_in_button}>
      {type === 'in' ? <ClientBalanceIn className={styles.out_in_button_icon} /> : <ClientBalanceOut className={styles.out_in_button_icon} />}
      <div className={styles.out_in_button_title}>{title}</div>
    </div>
  );
};

export default PaymentInOutButton;
