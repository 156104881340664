import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { eventListConstants, eventListModels } from '..';

export const useActivityLogs = () =>
  useQuery<eventListModels.IActivityLogTypes>({
    queryKey: [eventListConstants.queryKeys.ACTIVITY_LOGS],
    queryFn: async () => {
      const res = await $api.get('/v1/activity-logs');

      return res.data.data;
    }
  });
