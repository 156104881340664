import React from 'react';
import TjmFilter from '@components/tjm-filter/TjmFilter';
import { Layout } from 'antd';

import ChartContainer from '../../components/bossHome/ChardContainer';
import CountInformation from '../../components/bossHome/CountInformation';
import HeaderCards from '../../components/bossHome/HeaderCards';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';

import styles from './bossHome.module.scss';

const { Content } = Layout;

const BossHome = () => (
  <>
    <MainHeader>
      <BreadcrumbHeader />
      <NotificationButtons />
    </MainHeader>
    <Content
      style={{
        padding: '1rem 2.68rem',
        overflowY: 'auto',
        height: '50vh'
      }}
    >
      <div className={styles.container}>
        <div className={styles.container_top}>
          <TjmFilter />
        </div>
        <HeaderCards />
        <div className={styles.wrapper}>
          <ChartContainer />
          <CountInformation />
        </div>
      </div>
    </Content>
  </>
);

export default BossHome;
