import React from 'react';
import GreenCallTriggerIcon from '@icons/GreenCallTriggerIcon';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import ProfileTwoUserIcon from '../../../../../../../assets/icons/crmIcons/ProfileTwoUserIcon';
import KanbanIcon from '../../../../../../../assets/icons/KanbanIcon';
import TickSquareIcon from '../../../../../../../assets/icons/TickSquareIcon';
import { useAppDispatch } from '../../../../../../../hooks/reduxHooks';
import { setVisible } from '../../../../../../../store/reducers/TriggerSlice';
import { TYPE_TRIGGER } from '../../../../../../../utils/models/Trigger';

import styles from './selectTypeModal.module.scss';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  index: number;
  pipeStatusId: number;
}

const SelectTypeModal: React.FC<Props> = ({ index, close, visible, pipeStatusId }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // handleCancel
  const handleCancel = () => {
    close(false);
  };

  // handle select
  const handleSelect = (type: TYPE_TRIGGER) => {
    dispatch(
      setVisible({
        visible: true,
        id: undefined,
        pipeStatusId,
        order: index + 1,
        typeTrigger: type
      })
    );
    handleCancel();
  };

  return (
    <Modal title="" closable={false} footer={false} centered visible={visible} width={231} className={styles.container} onCancel={handleCancel}>
      <div onClick={() => handleSelect(TYPE_TRIGGER.TASK_TRIGGER)} className={styles.container_item}>
        <TickSquareIcon className={styles.container_item_icon} />
        <span className={styles.container_item_title}>{t('triggers.Topshiriq_yaratish')}</span>
      </div>
      <div onClick={() => handleSelect(TYPE_TRIGGER.PIPE_STATUS_TRIGGER)} className={styles.container_item}>
        <KanbanIcon className={styles.container_item_icon} />
        <span className={styles.container_item_title}>{t('triggers.Lid_statusini_ozgartirish')}</span>
      </div>
      <div onClick={() => handleSelect(TYPE_TRIGGER.MANAGER_TRIGGER)} className={styles.container_item}>
        <ProfileTwoUserIcon className={`${styles.container_item_icon} ${styles.container_item_user_icon}`} />
        <span className={styles.container_item_title}>{t('one_task.Masul_xodimni_ozgartirish')}</span>
      </div>
      <div onClick={() => handleSelect(TYPE_TRIGGER.CALL_BROJECT_TRIGGER)} className={styles.container_item}>
        <GreenCallTriggerIcon className={`${styles.container_item_icon} ${styles.container_item_user_icon}`} />
        <span className={styles.container_item_title}>{t('home.Qongiroq_qilish')}</span>
      </div>
    </Modal>
  );
};

export default SelectTypeModal;
