import { useEffect,useState } from 'react';
import message from '@utils/helper/message';
import { Checkbox, Form, Input, Select, TreeSelect } from 'antd';

import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIcon';
import DocumentCopyIcon from '../../../../../../assets/icons/DocumentCopyIcon';
import SidebarArrowRightIcon from '../../../../../../assets/icons/SidebarArrowRightIcon';
import ChangeImageIcon from '../../../../../../assets/icons/webforma/ChangeImageIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { useUploadFile } from '../../../../../../queries/mutation';
import { usePipeAll, useTags } from '../../../../../../queries/queries';
import {
  changeFormName,
  changePipeStatusId,
  changeRedirectTime,
  changeRedirectUrl,
  changeTagsId,
  changeTextSubmission,
  setChangeBackImage,
  setChangeChecked,
  setChangePageBackgroundColor
} from '../../../../../../store/reducers/TextEditorSlice';
import { useGetOneForm } from '../service/queries';

import styles from './formSettings.module.scss';

const { Option } = Select;

type Props = {
  viewForm: boolean;
};

const FormaSettings: React.FC<Props> = ({ viewForm }) => {
  const { data } = useTags();
  const { data: pipeAll } = usePipeAll();
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();
  const { main } = useAppSelector(state => state.textEditorReducer);
  const { data: forma } = useGetOneForm(main.formId);
  const uploadImageMutate = useUploadFile(getFile);

  function getFile(data: any[]) {
    dispatch(setChangeBackImage(data[0]));
  }
  const onChangeImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 10000000) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        uploadImageMutate.mutateAsync(formData);
      }
    }
  };

  useEffect(() => {
    if (forma?.link) {
      setText(forma?.link);
    }
  }, [forma?.link]);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text);
    if (text.length > 0 || forma?.link) {
      message.success('copied');
    }
  };

  useEffect(() => {
    if (forma?.redirectUrl) {
      dispatch(setChangeChecked(true));
    }
    // eslint-disable-next-line
  }, [forma?.redirectUrl]);

  return (
    <div className={styles.formSettings}>
      <Form.Item label="Forma nomi" name="name" rules={[{ required: true, message: '' }]}>
        <Input value={main.name} onChange={e => dispatch(changeFormName(e.target.value))} />
      </Form.Item>
      <Form.Item label="Taglar" name="tagIds" rules={[{ required: true, message: '' }]}>
        <Select onChange={e => dispatch(changeTagsId(e))} mode="multiple">
          {data?.tagList?.map(item => (
            <Option value={item?.id} key={item?.id}>
              {item?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className={styles.lavel} label="Bosqich" name="pipeStatusId" rules={[{ required: true, message: '' }]}>
        <TreeSelect
          treeExpandAction={'click'}
          switcherIcon={<SidebarArrowRightIcon />}
          suffixIcon={<ArrowDownIcon />}
          className="customSelect"
          style={{ width: 200 }}
          onChange={e => {
            dispatch(changePipeStatusId(e));
          }}
        >
          {pipeAll?.map(item => (
            <TreeSelect.TreeNode
              title={item.name}
              value={item.id}
              key={item.id}
              selectable={false}
              children={item.pipeStatuses.map(pipe => (
                <TreeSelect.TreeNode
                  key={pipe?.id}
                  value={pipe.id!}
                  title={
                    <div
                      //@ts-ignore
                      pageName={item?.name}
                    >
                      <span
                        style={{
                          background: pipe.color,
                          color: '#FFFFFF',
                          padding: '2px 7px',
                          borderRadius: '5px',
                          fontSize: 10
                        }}
                      >
                        {pipe.name}
                      </span>
                    </div>
                  }
                />
              ))}
            />
          ))}
        </TreeSelect>
      </Form.Item>
      <div className={styles.formSettings_back}>
        <div className={styles.formSettings_back_label}>
          <p>Orqa fon</p>
        </div>
        <div className={styles.formSettings_back_items}>
          <div className={styles.formSettings_back_items_card}>
            <ChangeImageIcon />
            <label className={styles.imgPopover}>
              <input type="file" onChange={onChangeImg} accept="image/*" />
            </label>
          </div>
          <div className={styles.formSettings_back_items_card}>
            <div
              className={styles.formSettings_back_items_card_icon}
              style={{
                backgroundColor: main?.pageBackgroundColor
              }}
            ></div>
            <input type="color" value={main?.pageBackgroundColor} onChange={e => dispatch(setChangePageBackgroundColor(e.target.value))} />
          </div>
        </div>
      </div>
      <Form.Item label={`Shakl topshirilgandan keyin matn`} name="textSubmission">
        <Input.TextArea
          value={main?.textSubmission}
          onChange={e => dispatch(changeTextSubmission(e.target.value))}
          placeholder="Shakl muvaffaqiyatli topshirilgandan so'ng paydo bo'ladigan matnni kiriting"
        />
      </Form.Item>
      <Form.Item label="Qayta yo'naltirish" name="reset" valuePropName="checked">
        <Checkbox checked={main?.checked} onChange={e => dispatch(setChangeChecked(e.target.checked))} autoFocus={true}>
          Yuborishni bosgandan so’ng boshqa sahifaga yo’naltirish
        </Checkbox>
      </Form.Item>
      {main?.checked && (
        <div className={styles.redirects}>
          <Form.Item name="redirectUrl" label="redirectUrl">
            <Input value={main.redirectUrl} onChange={e => dispatch(changeRedirectUrl(e.target.value))} placeholder="URLni kiriting" />
          </Form.Item>
          <Form.Item name="redirectTime" label="redirectTime">
            <div className={styles.formSettings_redirect_inputs_reply}>
              <span>Qayta yo'naltirishdan keyin: </span>
              <Input
                value={main.redirectTime}
                onChange={e => {
                  e.target.value.length <= 2 && dispatch(changeRedirectTime(Number(e.target.value)));
                }}
              />
              <span>soniya</span>
            </div>
          </Form.Item>
        </div>
      )}
      {forma?.link && (
        <Form.Item label="Forma manzili" name="link">
          <Input addonAfter={<DocumentCopyIcon onClick={handleCopy} />} value={text} onChange={e => setText(e.target.value)} />
        </Form.Item>
      )}
    </div>
  );
};

export default FormaSettings;
