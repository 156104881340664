import React, { useEffect, useState } from 'react';
import { Input, Popover } from 'antd';
import { useUploadFile } from '../../../../../../queries/mutation';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { setLeftBgImgBinary } from '../../../../../../store/reducers/TextEditorSlice';
import ChangeImageIcon from '../../../../../../assets/icons/webforma/ChangeImageIcon';

import styles from '../dragDrop.module.scss';

type Props = {
  bgColor: string;
  setBgColor: (value: React.SetStateAction<string>) => void;
  type: string;
};

const BottomPopover: React.FC<Props> = ({ bgColor, setBgColor, type }) => {
  const uploadImageMutate = useUploadFile(getFile);
  const [mouseEvent, setMouseEvent] = useState(false);
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();

  function getFile(data: any[]) {
    dispatch(
      setLeftBgImgBinary({
        backImage: data[0]!,
        type: type
      })
    );
  }

  // function for upload image

  const onChangeImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 10000000) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('files', file);
        uploadImageMutate.mutateAsync(formData);
      }
    }
  };

  function content1() {
    return (
      <label className={styles.imgPopover}>
        <input type="file" onChange={onChangeImg} accept="image/*" />
      </label>
    );
  }

  useEffect(() => {
    const windowClick = () => {
      if (mouseEvent) {
        setActive(false);
      }
    };
    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, [mouseEvent]);

  return (
    <div className={styles.backImage_popover}>
      <Popover>
        <div className={styles.backImage_popover_bgColor}>
          <p>Orqa fon:</p>
          <div className={styles.backImage_popover_bgColor_mask} style={{ backgroundColor: bgColor }}></div>
          <Input className={styles.colorInput} type="color" value={bgColor} onChange={e => setBgColor(e.target.value)} />
        </div>
      </Popover>
      <div className={styles.uploadImg}>
        <ChangeImageIcon />
        <div className={styles.uploadImg_input}>{content1()}</div>
      </div>
    </div>
  );
};

export default BottomPopover;
