import React, { useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';

import { ContractExterminateModal } from '../components';

import Context, { IContext } from './context';

interface IProps {
  children: React.ReactNode;
}

const Provider = ({ children }: IProps) => {
  const [exterminate, setExterminate] = useState<IContext['exterminate']>({
    id: undefined
  });

  const permissions = useAppSelector(state => state?.userReducer?.user?.role?.permissions);

  const getPermissions = (permissions || [])?.reduce<IContext['permissions']>(
    (p, c) => {
      p.PERMISSION_CHANGE_CONTRACT_RESPONSIBLE = c === Permissions.PERMISSION_CHANGE_CONTRACT_RESPONSIBLE || !!p.PERMISSION_CHANGE_CONTRACT_RESPONSIBLE;
      p.PERMISSION_EXTERMINATE_CONTRACT = c === Permissions.PERMISSION_EXTERMINATE_CONTRACT || !!p.PERMISSION_EXTERMINATE_CONTRACT;
      p.PERMISSION_TRANSFER_CONTRACT = c === Permissions.PERMISSION_TRANSFER_CONTRACT || !!p.PERMISSION_TRANSFER_CONTRACT;
      p.PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT = c === Permissions.PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT || !!p.PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT;

      return p;
    },
    {} as IContext['permissions']
  );

  return (
    <Context.Provider
      value={{
        exterminate,
        setExterminate,
        permissions: getPermissions
      }}
    >
      {children}
      <ContractExterminateModal />
    </Context.Provider>
  );
};

export default Provider;
