import React from 'react';

const AddDashboardIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_21717_73074)">
        <path
          d="M7.9987 5.33594V10.6693M5.33203 8.0026H10.6654M14.6654 8.0026C14.6654 11.6845 11.6806 14.6693 7.9987 14.6693C4.3168 14.6693 1.33203 11.6845 1.33203 8.0026C1.33203 4.32071 4.3168 1.33594 7.9987 1.33594C11.6806 1.33594 14.6654 4.32071 14.6654 8.0026Z"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21717_73074">
          <rect width="16" height="16" fill="white" transform="translate(0 0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddDashboardIcon;
