import React, { useRef, useState } from 'react';
import { Dropdown, Input } from 'antd';

import styles from '../triggers.module.scss';
import SubtractIcon from '../../../../../../../assets/icons/SubtractIcon';
import TrushSquareIcon from '../../../../../../../assets/icons/TrushSquareIcon';
import { useTags } from '../../../../../../../queries/queries';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { removeTag, setTag, setTagCondition, setTypeTag } from '../../../../../../../store/reducers/TriggerSlice';
import { LogicTag, TriggerConditionErrorType } from '../../../../../../../utils/models/Trigger';
import { useTranslation } from 'react-i18next';

interface Props {
  order: number;
}

const Teg: React.FC<Props> = ({ order }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState('');

  const isFocused = useRef(false);

  const { t } = useTranslation();

  const activeTags = useAppSelector(state => state.triggerSlice.tag?.tags);
  const errorList = useAppSelector(state => state.triggerSlice.errorList);
  const type = useAppSelector(state => state.triggerSlice.tag?.logic);

  const dispatch = useAppDispatch();

  const { data } = useTags();

  // handle change visible dropdown
  const handleChangeVisible = (visible: boolean) => {
    if (isFocused.current && !visible) {
      return;
    }
    setShowDropdown(visible);
  };

  // handle search
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // handle focus
  const handleFocus = () => {
    handleChangeVisible(true);
    isFocused.current = true;
  };

  const handleBlur = () => {
    isFocused.current = false;
  };

  // handle click type
  const handleClickType: React.MouseEventHandler<HTMLSpanElement> = event => {
    event.stopPropagation();
    if (type === LogicTag.AND) {
      dispatch(setTypeTag(LogicTag.OR));
    } else {
      dispatch(setTypeTag(LogicTag.AND));
    }
  };

  // and tags
  const addTags = (id: number) => {
    dispatch(setTag(id));
  };

  // delete tag
  const removeTagFunction = (id: number) => {
    dispatch(removeTag(id));
  };

  // filter tags
  const filterTags = () => {
    if (search && data?.tagList) {
      return data?.tagList.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()) && !activeTags?.find(item => item.tagId === tag.id));
    } else if (data?.tagList) {
      return data?.tagList.filter(tag => !activeTags?.find(item => item.tagId === tag.id));
    }
    return [];
  };

  // handle delete all field
  const handleDelete = () => {
    dispatch(
      setTagCondition({
        add: false
      })
    );
  };

  // show error
  const showError = () => {
    let errorValue = false;
    errorList.forEach(error => {
      if (error.type === TriggerConditionErrorType.TAG) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  return (
    <div className={styles.container_header_tag_container}>
      <Dropdown
        visible={showDropdown}
        onVisibleChange={handleChangeVisible}
        placement={'bottom'}
        overlayClassName={styles.container_header_dropdown}
        overlay={
          <div className={styles.container_header_dropdown_teg}>
            {filterTags().map((tag, index) => (
              <div onClick={() => addTags(Number(tag.id))} key={index} className={styles.container_header_dropdown_teg_item}>
                {tag.name}
              </div>
            ))}
          </div>
        }
        trigger={['click']}
      >
        <div
          onClick={e => {
            handleChangeVisible(true);
            e.stopPropagation();
          }}
          className={styles.container_header_teg + (showError() ? ' ' + styles.container_header_teg_error : '')}
        >
          <span className={styles.container_header_teg_title}>
            {order !== 0 && t('triggers.va')} {t('triggers.Teg')}
          </span>
          <span onClick={handleClickType} className={styles.container_header_teg_type}>
            ({t(`triggers.${type}`)}):
          </span>
          {activeTags?.map((activeTag, index) => {
            const tag = data?.tagList.find(item => item.id === activeTag.tagId);
            if (tag) {
              return (
                <span key={index} className={styles.container_header_teg_one_teg}>
                  <span className={styles.container_header_teg_one_teg_name}>{tag.name}</span>
                  <SubtractIcon
                    onClick={e => {
                      e.stopPropagation();
                      removeTagFunction(Number(tag.id));
                    }}
                    className={styles.container_header_teg_one_teg_delete}
                  />
                </span>
              );
            }
            return null;
          })}
          <Input onFocus={handleFocus} onBlur={handleBlur} onChange={e => handleSearch(e.target.value)} bordered={false} />
        </div>
      </Dropdown>
      <TrushSquareIcon onClick={handleDelete} className={styles.container_header_tag_container_delete} />
    </div>
  );
};

export default Teg;
