import { $api } from "@service/RequestService";
import message from '@utils/helper/message';
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../end-points"
import { keys } from "../keys";
import { serviceSchema, UzumService } from "../schema";


export const useUpdateService = () => {
  const client = useQueryClient();

  return useMutation<UzumService, any, UzumService>(
    async values => {
      const { data } = await $api.patch(`${endPoints.CREATE_SERVICE}/${values?.id}`, values);
      const service = serviceSchema.parse(data.data);

      return service;
    },
    {
      onSuccess: async () => {
        client.invalidateQueries({ queryKey: [keys.UZUM_CONFIG] })
        message.success("Muvaffaqqiyatli")
      }
    }

  );
};