import React from 'react';

const ImportIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#00A389"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 13L12 16L15 13" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16V8" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ImportIcon;
