import { FC, SVGProps } from 'react';

interface IProps {
  size?: number;
  stroke?: string;
  className?: string;
  pathProps?: SVGProps<SVGPathElement>;
}

const ReceiveSquare: FC<IProps> = ({ size = 32, className, pathProps }) => (
  <svg className={className} width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 15.5C28 22.4 22.4 28 15.5 28C8.6 28 4.3875 21.05 4.3875 21.05M4.3875 21.05H10.0375M4.3875 21.05V27.3M3 15.5C3 8.6 8.55 3 15.5 3C23.8375 3 28 9.95 28 9.95M28 9.95V3.7M28 9.95H22.45"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...pathProps}
    />
  </svg>
);

export default ReceiveSquare;
