import React from 'react';

const FacebookIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8447 11.1068L15.363 7.69417H12.121V5.48058C12.121 4.54672 12.5731 3.63592 14.0251 3.63592H15.5V0.730583C15.5 0.730583 14.1621 0.5 12.8836 0.5C10.2123 0.5 8.46804 2.13483 8.46804 5.0932V7.69417H5.5V11.1068H8.46804V19.357C9.06393 19.4516 9.67352 19.5 10.2945 19.5C10.9155 19.5 11.5251 19.4516 12.121 19.357V11.1068H14.8447Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
