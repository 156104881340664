import React from 'react';

const PendingIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.0013 3.4974V6.9974L9.33464 8.16406M12.8346 6.9974C12.8346 10.2191 10.223 12.8307 7.0013 12.8307C3.77964 12.8307 1.16797 10.2191 1.16797 6.9974C1.16797 3.77573 3.77964 1.16406 7.0013 1.16406C10.223 1.16406 12.8346 3.77573 12.8346 6.9974Z"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PendingIcon;
