import React from 'react';

const AccessDeniedIcon = () => (
  <svg width="704" height="388" viewBox="0 0 704 388" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M120.483 361.759H162.695V315.787H196.021V278.531H162.695V115.152H100.488C56.9092 180.777 22.2168 235.636 0 276.48V315.787H120.483V361.759ZM40.8447 276.993C69.5557 226.407 95.1904 185.904 118.774 151.041H121.167V279.557H40.8447V276.993Z"
      fill="#00A389"
    />
    <path
      d="M578.149 252.384H609.082C639.502 252.384 658.13 267.252 658.13 291.349C658.13 314.591 638.306 330.826 610.107 330.826C581.738 330.826 562.256 316.3 560.376 293.399H518.164C520.386 338.517 556.616 367.911 609.766 367.911C663.428 367.911 703.076 336.466 703.076 294.083C703.076 261.271 682.227 238.37 648.56 234.439V231.534C675.903 225.724 693.164 203.678 693.164 174.796C693.164 137.027 657.446 109 609.595 109C558.325 109 525.171 137.198 522.949 182.486H564.136C565.845 159.244 582.764 144.889 608.228 144.889C634.033 144.889 650.61 159.073 650.61 180.948C650.61 202.994 633.35 218.204 608.228 218.204H578.149V252.384Z"
      fill="#00A389"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M366.5 40H340.5C313.162 40 291 62.1619 291 89.5C291 116.838 313.162 139 340.5 139H366.5C393.838 139 416 116.838 416 89.5C416 62.1619 393.838 40 366.5 40ZM340.5 0C291.071 0 251 40.0705 251 89.5C251 138.929 291.071 179 340.5 179H366.5C415.929 179 456 138.929 456 89.5C456 40.0705 415.929 0 366.5 0H340.5Z"
      fill="#00A389"
    />
    <path
      d="M473.063 239C473.063 304.722 419.785 358 354.063 358C288.342 358 235.063 304.722 235.063 239C235.063 173.278 288.342 120 354.063 120C419.785 120 473.063 173.278 473.063 239Z"
      fill="#F6F6F6"
      stroke="#00A389"
      strokeWidth="60"
    />
    <path
      d="M414.063 239C414.063 272.137 387.201 299 354.063 299C320.926 299 294.063 272.137 294.063 239C294.063 205.863 320.926 179 354.063 179C387.201 179 414.063 205.863 414.063 239Z"
      fill="#00A389"
    />
  </svg>
);

export default AccessDeniedIcon;
