import React, { FC, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import ReceiptItemIcon from '@icons/ReceiptItemIcon';
import { Permissions } from '@utils/constants/permissions';
import { PayType } from '@utils/models/PayType';
import { Drawer, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import CardAddIcon from '../../../../assets/icons/contract/CardAddIcon';
import CardRemoveBgIcon from '../../../../assets/icons/contract/CardRemoveBgIcon';
import CardSendIcon from '../../../../assets/icons/contract/CardSendIcon';
import CloseIcon from '../../../../assets/icons/contract/CloseIcon';
import PercentageSquareIcon from '../../../../assets/icons/contract/PercentageSquareIcon';
import TicketDiscountIcon from '../../../../assets/icons/contract/TicketDiscountIcon';
import { ContractId } from '../../../../utils/models/ContractId';

import ContractIdPaymentFromBalance from './ContractIdPaymentFromBalance';

import styles from './contractIdPaymentDrawer.module.scss';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: ContractId | undefined;
}

const ContractIdPaymentDrawer: FC<IProps> = ({ setVisible, visible, data }) => {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const [activeTab, setActiveTab] = useState<PayType>(PayType.CUSTOM);
  const { t } = useTranslation();
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer title={false} closable={false} placement="right" onClose={onClose} visible={visible} className={styles.container} width={524}>
      <div className={styles.container_header}>
        <p>{t('client_balance.Tolov_qilish')}</p>
        <span onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      <div className={styles.container_body}>
        <div className={styles.container_body_prices}>
          <div className={styles.container_body_prices_item}>
            <TicketDiscountIcon />
            <p>{t('createOrder.Boshlangich_tolov_qoldigi')}</p>
            <h3>
              {data?.prepaymentResidue?.toLocaleString('ru')} {data?.currency?.ccy}
            </h3>
          </div>
          <div className={styles.container_body_prices_item}>
            <PercentageSquareIcon />
            <p>{t('home.Peniya')}</p>
            <h3>
              {data?.surcharge?.toLocaleString('ru')} {data?.currency?.ccy}
            </h3>
          </div>
        </div>
        <div className={styles.container_body_prices}>
          <div className={styles.container_body_prices_item}>
            <CardRemoveBgIcon />
            <p>{t('contract_id.Oylik_qarzdorlik')}</p>
            <h3>
              {data?.debt?.toLocaleString('ru')} {data?.currency?.ccy}
            </h3>
          </div>
          {isMultiPayment ? (
            <div className={styles.container_body_prices_item}>
              <ReceiptItemIcon />
              <p>Ipoteka</p>
              <h3>
                {data?.mortgage?.toLocaleString('ru')} {data?.currency?.ccy}
              </h3>
            </div>
          ) : null}
        </div>
        <div className={styles.container_body_paymentTab}>
          <Tabs onChange={key => setActiveTab(key as PayType)} activeKey={activeTab}>
            <Tabs.TabPane
              key={PayType.CUSTOM}
              tab={
                <>
                  <CardAddIcon /> {t("create_payment.Odatiy_to'lov")}
                </>
              }
            >
              <ContractIdPaymentFromBalance data={data} setVisible={setVisible} payType={activeTab} />
            </Tabs.TabPane>
            <Tabs.TabPane
              key={PayType.BALANCE}
              tab={
                <>
                  <CardSendIcon /> {t("create_payment.Balansdan_to'lash")}
                </>
              }
            >
              <ContractIdPaymentFromBalance data={data} setVisible={setVisible} payType={activeTab} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </Drawer>
  );
};

export default ContractIdPaymentDrawer;
