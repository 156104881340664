import message from '@utils/helper/message';
import { useMutation, useQueryClient } from 'react-query';

import { $api } from '../../../../../../service/RequestService';
import { endpoints } from '../utils/constants/endpoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { FormaModels } from '../utils/models/FormaModels';

export function useWebForm(id?: number | undefined | null) {
  const qc = useQueryClient();

  return useMutation(
    async (data: FormaModels) => {
      if (id) {
        const res = await $api.patch(endpoints.OLD_FORM + '/' + id, data);

        return res.data;
      }
      const res = await $api.post(endpoints.FORMA, data);

      return res.data;
    },
    {
      onSuccess: res => {
        qc.invalidateQueries([queryKeys.FORMA]);
        message.success(res?.data?.textSubmission ? res?.data?.textSubmission : 'Muvafaqqiyatli');
      }
    }
  );
}

export function useDeleteWebForm(id: number | null | undefined) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(endpoints.OLD_FORM + '/' + id);
      
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.FORMA]);
        message.success('Muvafaqqiyatli');
      }
    }
  );
}
