import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const CaretRightIcon: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 4.5L7.5 12L15 19.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CaretRightIcon;
