import i18n from "@service/I18nService";

export const countrCode = [
  {
    index: 0,
    country_en: 'Afghanistan',
    country_ru: 'Афганистан',
    country_uz: 'Афғонистон',
    country_kr: 'Афғонистон',
    country_tr: 'Afganistan',
    code: 'af'
  },
  {
    index: 1,
    country_en: 'Aland Islands',
    country_ru: 'Аландские острова',
    country_uz: 'Alandiya',
    country_kr: 'Аландия',
    country_tr: 'Åland',
    code: 'ax'
  },
  {
    index: 2,
    country_en: 'Albania',
    country_ru: 'Албания',
    country_uz: 'Albaniya',
    country_kr: 'Албания',
    country_tr: 'Arnavutluk',
    code: 'al'
  },
  {
    index: 3,
    country_en: 'Algeria',
    country_ru: 'Алжир',
    country_uz: 'Jazoir',
    country_kr: 'Жазоир',
    country_tr: 'Cezayir',
    code: 'dz'
  },
  {
    index: 4,
    country_en: 'American Samoa',
    country_ru: 'Американское Самоа',
    country_uz: 'Amerika Samoasi',
    country_kr: 'Америка Самоаси',
    country_tr: 'Amerikan Samoası',
    code: 'as'
  },
  {
    index: 5,
    country_en: 'Angola',
    country_ru: 'Ангола',
    country_uz: 'Angola',
    country_kr: 'Ангола',
    country_tr: 'Angola',
    code: 'ao'
  },
  {
    index: 6,
    country_en: 'Argentina',
    country_ru: 'Аргентина',
    country_uz: 'Argentina',
    country_kr: 'Аргентина',
    country_tr: 'Arjantin',
    code: 'ar'
  },
  {
    index: 7,
    country_en: 'Armenia',
    country_ru: 'Армения',
    country_uz: 'Armaniston',
    country_kr: 'Арманистон',
    country_tr: 'Ermenistan',
    code: 'am'
  },
  {
    index: 8,
    country_en: 'Australia',
    country_ru: 'Австралия',
    country_uz: 'Avstraliya',
    country_kr: 'Австралия',
    country_tr: 'Avustralya',
    code: 'au'
  },
  {
    index: 9,
    country_en: 'Austria',
    country_ru: 'Австрия',
    country_uz: 'Avstriya',
    country_kr: 'Австрия',
    country_tr: 'Avusturya',
    code: 'at'
  },
  {
    index: 10,
    country_en: 'Azerbaijan',
    country_ru: 'Азербайджан',
    country_uz: 'Ozarbayjon',
    country_kr: 'Озарбайжон',
    country_tr: 'Azerbaycan',
    code: 'az'
  },
  {
    index: 11,
    country_en: 'Bangladesh',
    country_ru: 'Бангладеш',
    country_uz: 'Bangladesh',
    country_kr: 'Бангладеш',
    country_tr: 'Bangladeş',
    code: 'bd'
  },
  {
    index: 12,
    country_en: 'Belarus',
    country_ru: 'Беларусь',
    country_uz: 'Belarus',
    country_kr: 'Беларус',
    country_tr: 'Belarus',
    code: 'by'
  },
  {
    index: 13,
    country_en: 'Belgium',
    country_ru: 'Бельгия',
    country_uz: 'Belgiya',
    country_kr: 'Белгия',
    country_tr: 'Belçika',
    code: 'be'
  },
  {
    index: 14,
    country_en: 'Belize',
    country_ru: 'Белиз',
    country_uz: 'Beliz',
    country_kr: 'Белиз',
    country_tr: 'Belize',
    code: 'bz'
  },
  {
    index: 15,
    country_en: 'Benin',
    country_ru: 'Бенин',
    country_uz: 'Benin',
    country_kr: 'Бенин',
    country_tr: 'Benin',
    code: 'bj'
  },
  {
    index: 16,
    country_en: 'Bhutan',
    country_ru: 'Бутан',
    country_uz: 'Butan',
    country_kr: 'Бутан',
    country_tr: 'Bhutan',
    code: 'bt'
  },
  {
    index: 17,
    country_en: 'Bolivia',
    country_ru: 'Боливия',
    country_uz: 'Boliviya',
    country_kr: 'Боливия',
    country_tr: 'Bolivya',
    code: 'bo'
  },
  {
    index: 18,
    country_en: 'Bosnia and Herzegovina',
    country_ru: 'Босния',
    country_uz: 'Bosniya va Gersegovina',
    country_kr: 'Босния ва Герсеговина',
    country_tr: 'Bosna Hersek',
    code: 'ba'
  },
  {
    index: 19,
    country_en: 'Botswana',
    country_ru: 'Ботсвана',
    country_uz: 'Botsvana',
    country_kr: 'Ботсвана',
    country_tr: 'Botsvana',
    code: 'bw'
  },
  {
    index: 20,
    country_en: 'Brazil',
    country_ru: 'Бразилия',
    country_uz: 'Бразилия',
    country_kr: 'Бразилия',
    country_tr: 'Brezilya',
    code: 'br'
  },
  {
    index: 21,
    country_en: 'Brunei',
    country_ru: 'Бруней Даруссалам',
    country_uz: 'Bruney',
    country_kr: 'Бруней',
    country_tr: 'Brunei',
    code: 'bn'
  },
  {
    index: 22,
    country_en: 'Bulgaria',
    country_ru: 'Болгария',
    country_uz: 'Bolgariya',
    country_kr: 'Болгария',
    country_tr: 'Bulgaristan',
    code: 'bg'
  },
  {
    index: 23,
    country_en: 'Burkina Faso',
    country_ru: 'Буркина Фасо',
    country_uz: 'Burkina-Faso',
    country_kr: 'Буркина-Фасо',
    country_tr: 'Burkina Faso',
    code: 'bf'
  },
  {
    index: 24,
    country_en: 'Burundi',
    country_ru: 'Бурунди',
    country_uz: 'Burundi',
    country_kr: 'Бурунди',
    country_tr: 'Burundi',
    code: 'bi'
  },
  {
    index: 25,
    country_en: 'Cabo Verde',
    country_ru: 'Кабо-Верде',
    country_uz: 'Kabo-Verde',
    country_kr: 'Кабо-Верде',
    country_tr: 'Cape Verde',
    code: 'cv'
  },
  {
    index: 26,
    country_en: 'Cambodia',
    country_ru: 'Камбоджа',
    country_uz: 'Kambodja',
    country_kr: 'Камбоджа',
    country_tr: 'Kamboçya',
    code: 'kh'
  },
  {
    index: 27,
    country_en: 'Cameroon',
    country_ru: 'Камерун',
    country_uz: 'Kamerun',
    country_kr: 'Камерун',
    country_tr: 'Kamerun',
    code: 'cm'
  },
  {
    index: 28,
    country_en: 'Canada',
    country_ru: 'Канада',
    country_uz: 'Kanada',
    country_kr: 'Канада',
    country_tr: 'Kanada',
    code: 'ca'
  },
  {
    index: 29,
    country_en: 'Central African Republic',
    country_ru: 'Центрально-Африканская Республика',
    country_uz: 'Markaziy Afrika Respublikasi',
    country_kr: 'Марказий Африка Республикаси',
    country_tr: 'Orta Afrika Cumhuriyeti',
    code: 'cf'
  },
  {
    index: 30,
    country_en: 'Chad',
    country_ru: 'Чад',
    country_uz: 'Chad',
    country_kr: 'Чад',
    country_tr: 'Çad',
    code: 'td'
  },
  {
    index: 31,
    country_en: 'Chile',
    country_ru: 'Чили',
    country_uz: 'Chili',
    country_kr: 'Чили',
    country_tr: 'Şili',
    code: 'cl'
  },
  {
    index: 32,
    country_en: 'China',
    country_ru: 'Китай',
    country_uz: 'Xitoy',
    country_kr: 'Хитой',
    country_tr: 'Çin',
    code: 'cn'
  },
  {
    index: 33,
    country_en: 'Colombia',
    country_ru: 'Колумбия',
    country_uz: 'Kolumbiya',
    country_kr: 'Колумбия',
    country_tr: 'Kolombiya',
    code: 'co'
  },
  {
    index: 34,
    country_en: 'Cook Islands',
    country_ru: 'Острова Кука',
    country_uz: 'Kuk orollari',
    country_kr: 'Кук ороллари',
    country_tr: 'Cook Adaları',
    code: 'ck'
  },
  {
    index: 35,
    country_en: 'Costa Rica',
    country_ru: 'Коста-Рика',
    country_uz: 'Kosta-Rika',
    country_kr: 'Коста-Рика',
    country_tr: 'Kosta Rika',
    code: 'cr'
  },
  {
    index: 36,
    country_en: 'Croatia',
    country_ru: 'Хорватия',
    country_uz: 'Xorvatiya',
    country_kr: 'Хорватия',
    country_tr: 'Hırvatistan',
    code: 'hr'
  },
  {
    index: 37,
    country_en: 'Cuba',
    country_ru: 'Куба',
    country_uz: 'Kuba',
    country_kr: 'Куба',
    country_tr: 'Küba',
    code: 'cu'
  },
  {
    index: 38,
    country_en: 'Cyprus',
    country_ru: 'Кипр',
    country_uz: 'Kipr',
    country_kr: 'Кипр',
    country_tr: 'Kıbrıs',
    code: 'cy'
  },
  {
    index: 39,
    country_en: 'Czechia',
    country_ru: 'Чешская Республика',
    country_uz: 'Chexiya',
    country_kr: 'Чехия',
    country_tr: 'Çek Cumhuriyeti',
    code: 'cz'
  },
  {
    index: 40,
    country_en: 'Democratic Republic of the Congo',
    country_ru: 'Конго, Демократическая Республика',
    country_uz: 'Kongo Demokratik Respublikasi',
    country_kr: 'Конго Демократик Республикаси',
    country_tr: 'Kongo Demokratik Cumhuriyeti',
    code: 'cd'
  },
  {
    index: 41,
    country_en: 'Denmark',
    country_ru: 'Дания',
    country_uz: 'Daniya',
    country_kr: 'Дания',
    country_tr: 'Danimarka',
    code: 'dk'
  },
  {
    index: 42,
    country_en: 'Djibouti',
    country_ru: 'Джибути',
    country_uz: 'Jibuti',
    country_kr: 'Жибути',
    country_tr: 'Cibuti',
    code: 'dj'
  },
  {
    index: 43,
    country_en: 'Dominican Republic',
    country_ru: 'Доминиканская Республика',
    country_uz: 'Dominika Respublikasi',
    country_kr: 'Доминика Республикаси',
    country_tr: 'Dominik Cumhuriyeti',
    code: 'do'
  },
  {
    index: 44,
    country_en: 'Ecuador',
    country_ru: 'Эквадор',
    country_uz: 'Ekvador',
    country_kr: 'Эквадор',
    country_tr: 'Ekvador',
    code: 'ec'
  },
  {
    index: 45,
    country_en: 'Egypt',
    country_ru: 'Египет',
    country_uz: 'Misr',
    country_kr: 'Миср',
    country_tr: 'Mısır',
    code: 'eg'
  },
  {
    index: 46,
    country_en: 'El Salvador',
    country_ru: 'Сальвадор',
    country_uz: 'Salvador',
    country_kr: 'Салвадор',
    country_tr: 'El Salvador',
    code: 'sv'
  },
  {
    index: 47,
    country_en: 'Equatorial Guinea',
    country_ru: 'Экваториальная Гвинея',
    country_uz: 'Ekvatorial Gvineya',
    country_kr: 'Экваториал Гвинея',
    country_tr: 'Ekvator Ginesi',
    code: 'gq'
  },
  {
    index: 48,
    country_en: 'Eritrea',
    country_ru: 'Эритрея',
    country_uz: 'Eritreya',
    country_kr: 'Эритрея',
    country_tr: 'Eritre',
    code: 'er'
  },
  {
    index: 49,
    country_en: 'Estonia',
    country_ru: 'Эстония',
    country_uz: 'Estoniya',
    country_kr: 'Эстония',
    country_tr: 'Estonya',
    code: 'ee'
  },
  {
    index: 50,
    country_en: 'Eswatini',
    country_ru: 'Эсватини',
    country_uz: 'Svazilend',
    country_kr: 'Свазиленд',
    country_tr: 'Esvatini',
    code: 'sz'
  },
  {
    index: 51,
    country_en: 'Ethiopia',
    country_ru: 'Эфиопия',
    country_uz: 'Efiopiya',
    country_kr: 'Эфиопия',
    country_tr: 'Etiyopya',
    code: 'et'
  },
  {
    index: 52,
    country_en: 'Faroe Islands',
    country_ru: 'Фарерские острова',
    country_uz: 'Farer orollari',
    country_kr: 'Фарер ороллари',
    country_tr: 'Faroe Adaları',
    code: 'fo'
  },
  {
    index: 53,
    country_en: 'Fiji',
    country_ru: 'Фиджи',
    country_uz: 'Fiji',
    country_kr: 'Фижи',
    country_tr: 'Fiji',
    code: 'fj'
  },
  {
    index: 54,
    country_en: 'Finland',
    country_ru: 'Финляндия',
    country_uz: 'Finlandiya',
    country_kr: 'Финландия',
    country_tr: 'Finlandiya',
    code: 'fi'
  },
  {
    index: 55,
    country_en: 'France',
    country_ru: 'Франция',
    country_uz: 'Fransiya',
    country_kr: 'Франсия',
    country_tr: 'Fransa',
    code: 'fr'
  },
  {
    index: 56,
    country_en: 'French Guiana',
    country_ru: 'Французская Гвиана',
    country_uz: 'Fransuz Gvianasi',
    country_kr: 'Франсуз Гвианаси',
    country_tr: 'Fransız Guyanası',
    code: 'gf'
  },
  {
    index: 57,
    country_en: 'French Polynesia',
    country_ru: 'Французская Полинезия',
    country_uz: 'Fransuz Polineziyasi',
    country_kr: 'Франсуз Полинезияси',
    country_tr: 'Fransız Polinezyası',
    code: 'pf'
  },
  {
    index: 58,
    country_en: 'French Southern Territories',
    country_ru: 'Французские Южные территории',
    country_uz: 'Fransuz Janubiy hududlari',
    country_kr: 'Франсуз Жанубий ҳудудлари',
    country_tr: 'Fransız Güney Toprakları',
    code: 'tf'
  },
  {
    index: 59,
    country_en: 'Gabon',
    country_ru: 'Габон',
    country_uz: 'Gabon',
    country_kr: 'Габон',
    country_tr: 'Gabon',
    code: 'ga'
  },
  {
    index: 60,
    country_en: 'Gambia',
    country_ru: 'Гамбия',
    country_uz: 'Gambiya',
    country_kr: 'Гамбия',
    country_tr: 'Gambia',
    code: 'gm'
  },
  {
    index: 61,
    country_en: 'Georgia',
    country_ru: 'Грузия',
    country_uz: 'Gurjiston',
    country_kr: 'Гуржистон',
    country_tr: 'Gürcistan',
    code: 'ge'
  },
  {
    index: 62,
    country_en: 'Germany',
    country_ru: 'Германия',
    country_uz: 'Germaniya',
    country_kr: 'Германия',
    country_tr: 'Almanya',
    code: 'de'
  },
  {
    index: 63,
    country_en: 'Ghana',
    country_ru: 'Гана',
    country_uz: 'Gana',
    country_kr: 'Гана',
    country_tr: 'Gana',
    code: 'gh'
  },
  {
    index: 64,
    country_en: 'Greece',
    country_ru: 'Греция',
    country_uz: 'Gretsiya',
    country_kr: 'Гретсия',
    country_tr: 'Yunanistan',
    code: 'gr'
  },
  {
    index: 65,
    country_en: 'Greenland',
    country_ru: 'Гренландия',
    country_uz: 'Grenlandiya',
    country_kr: 'Гренландия',
    country_tr: 'Grönland',
    code: 'gl'
  },
  {
    index: 66,
    country_en: 'Guadeloupe',
    country_ru: 'Гваделупа',
    country_uz: 'Gvadelupe',
    country_kr: 'Гваделупе',
    country_tr: 'Guadeloupe',
    code: 'gp'
  },
  {
    index: 67,
    country_en: 'Guatemala',
    country_ru: 'Гватемала',
    country_uz: 'Gvatemala',
    country_kr: 'Гватемала',
    country_tr: 'Guatemala',
    code: 'gt'
  },
  {
    index: 68,
    country_en: 'Guinea',
    country_ru: 'Гвинея',
    country_uz: 'Gvineya',
    country_kr: 'Гвинея',
    country_tr: 'Gine',
    code: 'gn'
  },
  {
    index: 69,
    country_en: 'Guinea-Bissau',
    country_ru: 'Гвинея-Биссау',
    country_uz: 'Gvineya-Bisau',
    country_kr: 'Гвинея-Бисау',
    country_tr: 'Gine-Bissau',
    code: 'gw'
  },
  {
    index: 70,
    country_en: 'Haiti',
    country_ru: 'Гаити',
    country_uz: 'Gaiti',
    country_kr: 'Гаити',
    country_tr: 'Haiti',
    code: 'ht'
  },
  {
    index: 71,
    country_en: 'Honduras',
    country_ru: 'Гондурас',
    country_uz: 'Gonduras',
    country_kr: 'Гондурас',
    country_tr: 'Honduras',
    code: 'hn'
  },
  {
    index: 72,
    country_en: 'Hungary',
    country_ru: 'Венгрия',
    country_uz: 'Mojariston',
    country_kr: 'Можаристон',
    country_tr: 'Macaristan',
    code: 'hu'
  },
  {
    index: 73,
    country_en: 'Iceland',
    country_ru: 'Исландия',
    country_uz: 'Islandiya',
    country_kr: 'Исландия',
    country_tr: 'İzlanda',
    code: 'is'
  },
  {
    index: 74,
    country_en: 'India',
    country_ru: 'Индия',
    country_uz: 'Ҳиндистон',
    country_kr: 'Ҳиндистон',
    country_tr: 'Hindistan',
    code: 'in'
  },
  {
    index: 75,
    country_en: 'Indonesia',
    country_ru: 'Индонезия',
    country_uz: 'Indoneziya',
    country_kr: 'Индонезия',
    country_tr: 'Endonezya',
    code: 'id'
  },
  {
    index: 76,
    country_en: 'Iran',
    country_ru: 'Иран',
    country_uz: 'Eron',
    country_kr: 'Эрон',
    country_tr: 'İran',
    code: 'ir'
  },
  {
    index: 77,
    country_en: 'Iraq',
    country_ru: 'Ирак',
    country_uz: 'Iroq',
    country_kr: 'Ироқ',
    country_tr: 'Irak',
    code: 'iq'
  },
  {
    index: 78,
    country_en: 'Ireland',
    country_ru: 'Ирландия',
    country_uz: 'Irlandiya',
    country_kr: 'Ирландия',
    country_tr: 'İrlanda',
    code: 'ie'
  },
  {
    index: 79,
    country_en: 'Israel',
    country_ru: 'Израиль',
    country_uz: 'Isroil',
    country_kr: 'Исроил',
    country_tr: 'İsrail',
    code: 'il'
  },
  {
    index: 80,
    country_en: 'Italy',
    country_ru: 'Италия',
    country_uz: 'Italiya',
    country_kr: 'Италия',
    country_tr: 'İtalya',
    code: 'it'
  },
  {
    index: 81,
    country_en: 'Ivory Coast',
    country_ru: 'Кот д’Ивуар',
    country_uz: 'Kot-d’Ivuar',
    country_kr: 'Кот-д’Ивуар',
    country_tr: 'Côte d’Ivoire',
    code: 'ci'
  },
  {
    index: 82,
    country_en: 'Jamaica',
    country_ru: 'Ямайка',
    country_uz: 'Yamayka',
    country_kr: 'Ямайка',
    country_tr: 'Jamaika',
    code: 'jm'
  },
  {
    index: 83,
    country_en: 'Japan',
    country_ru: 'Япония',
    country_uz: 'Япония',
    country_kr: 'Япония',
    country_tr: 'Japonya',
    code: 'jp'
  },
  {
    index: 84,
    country_en: 'Jordan',
    country_ru: 'Иордания',
    country_uz: 'Iordaniya',
    country_kr: 'Иордания',
    country_tr: 'Ürdün',
    code: 'jo'
  },
  {
    index: 85,
    country_en: 'Kazakhstan',
    country_ru: 'Казахстан',
    country_uz: 'Qozogʻiston',
    country_kr: 'Қозоғистон',
    country_tr: 'Kazakistan',
    code: 'kz'
  },
  {
    index: 86,
    country_en: 'Kenya',
    country_ru: 'Кения',
    country_uz: 'Keniya',
    country_kr: 'Кения',
    country_tr: 'Kenya',
    code: 'ke'
  },
  {
    index: 87,
    country_en: 'Kiribati',
    country_ru: 'Кирибати',
    country_uz: 'Kiribati',
    country_kr: 'Кирибати',
    country_tr: 'Kiribati',
    code: 'ki'
  },
  {
    index: 88,
    country_en: 'Kosovo',
    country_ru: 'Косово',
    country_uz: 'Kosovo',
    country_kr: 'Косово',
    country_tr: 'Kosova',
    code: 'xk'
  },
  {
    index: 89,
    country_en: 'Kuwait',
    country_ru: 'Кувейт',
    country_uz: 'Quvayt',
    country_kr: 'Қувайт',
    country_tr: 'Kuveyt',
    code: 'kw'
  },
  {
    index: 90,
    country_en: 'Kyrgyzstan',
    country_ru: 'Кыргызстан',
    country_uz: 'Qirgʻiziston',
    country_kr: 'Қирғизистон',
    country_tr: 'Kırgızistan',
    code: 'kg'
  },
  {
    index: 91,
    country_en: 'Laos',
    country_ru: 'Лаос',
    country_uz: 'Laos',
    country_kr: 'Лаос',
    country_tr: 'Laos',
    code: 'la'
  },
  {
    index: 92,
    country_en: 'Latvia',
    country_ru: 'Латвия',
    country_uz: 'Latviya',
    country_kr: 'Латвия',
    country_tr: 'Letonya',
    code: 'lv'
  },
  {
    index: 93,
    country_en: 'Lebanon',
    country_ru: 'Ливан',
    country_uz: 'Lebanon',
    country_kr: 'Лебанон',
    country_tr: 'Lübnan',
    code: 'lb'
  },
  {
    index: 94,
    country_en: 'Lesotho',
    country_ru: 'Лесото',
    country_uz: 'Lesoto',
    country_kr: 'Лесото',
    country_tr: 'Lesotho',
    code: 'ls'
  },
  {
    index: 95,
    country_en: 'Liberia',
    country_ru: 'Либерия',
    country_uz: 'Liberiya',
    country_kr: 'Либерия',
    country_tr: 'Liberya',
    code: 'lr'
  },
  {
    index: 96,
    country_en: 'Libya',
    country_ru: 'Ливия',
    country_uz: 'Liviya',
    country_kr: 'Ливия',
    country_tr: 'Libya',
    code: 'ly'
  },
  {
    index: 97,
    country_en: 'Lithuania',
    country_ru: 'Литва',
    country_uz: 'Litva',
    country_kr: 'Литва',
    country_tr: 'Litvanya',
    code: 'lt'
  },
  {
    index: 98,
    country_en: 'Luxembourg',
    country_ru: 'Люксембург',
    country_uz: 'Lyuksemburg',
    country_kr: 'Люксембург',
    country_tr: 'Lüksemburg',
    code: 'lu'
  },
  {
    index: 99,
    country_en: 'Madagascar',
    country_ru: 'Мадагаскар',
    country_uz: 'Madagaskar',
    country_kr: 'Мадагаскар',
    country_tr: 'Madagaskar',
    code: 'mg'
  },
  {
    index: 100,
    country_en: 'Malawi',
    country_ru: 'Малави',
    country_uz: 'Malavi',
    country_kr: 'Малави',
    country_tr: 'Malavi',
    code: 'mw'
  },
  {
    index: 101,
    country_en: 'Malaysia',
    country_ru: 'Малайзия',
    country_uz: 'Malayziya',
    country_kr: 'Малайзия',
    country_tr: 'Malezya',
    code: 'my'
  },
  {
    index: 102,
    country_en: 'Mali',
    country_ru: 'Мали',
    country_uz: 'Mali',
    country_kr: 'Мали',
    country_tr: 'Mali',
    code: 'ml'
  },
  {
    index: 103,
    country_en: 'Martinique',
    country_ru: 'Мартиника',
    country_uz: 'Martinika',
    country_kr: 'Мартиника',
    country_tr: 'Martinik',
    code: 'mq'
  },
  {
    index: 104,
    country_en: 'Mauritania',
    country_ru: 'Мавритания',
    country_uz: 'Mavritaniya',
    country_kr: 'Мавритания',
    country_tr: 'Moritanya',
    code: 'mr'
  },
  {
    index: 105,
    country_en: 'Mexico',
    country_ru: 'Мексика',
    country_uz: 'Meksika',
    country_kr: 'Мексика',
    country_tr: 'Meksika',
    code: 'mx'
  },
  {
    index: 106,
    country_en: 'Micronesia',
    country_ru: 'Федеративные Штаты Микронезии',
    country_uz: 'Mikroneziya Federativ Shtatlari',
    country_kr: 'Микронезия Федератив Штатлари',
    country_tr: 'Mikronezya Federal Devletleri',
    code: 'fm'
  },
  {
    index: 107,
    country_en: 'Mongolia',
    country_ru: 'Монголия',
    country_uz: 'Mongoliya',
    country_kr: 'Монголия',
    country_tr: 'Moğolistan',
    code: 'mn'
  },
  {
    index: 108,
    country_en: 'Morocco',
    country_ru: 'Марокко',
    country_uz: 'Marokash',
    country_kr: 'Марокаш',
    country_tr: 'Fas',
    code: 'ma'
  },
  {
    index: 109,
    country_en: 'Mozambique',
    country_ru: 'Мозамбик',
    country_uz: 'Mozambik',
    country_kr: 'Мозамбик',
    country_tr: 'Mozambik',
    code: 'mz'
  },
  {
    index: 110,
    country_en: 'Myanmar',
    country_ru: 'Мьянма (Бирма)',
    country_uz: 'Myanma (Birma)',
    country_kr: 'Мянма (Бирма)',
    country_tr: 'Myanmar',
    code: 'mm'
  },
  {
    index: 111,
    country_en: 'Nepal',
    country_ru: 'Непал',
    country_uz: 'Nepal',
    country_kr: 'Непал',
    country_tr: 'Nepal',
    code: 'np'
  },
  {
    index: 112,
    country_en: 'Netherlands',
    country_ru: 'Нидерланды',
    country_uz: 'Niderlandiya',
    country_kr: 'Нидерландия',
    country_tr: 'Hollanda',
    code: 'nl'
  },
  {
    index: 113,
    country_en: 'New Caledonia',
    country_ru: 'Новая Каледония',
    country_uz: 'Yangi Kaledoniya',
    country_kr: 'Янги Каледония',
    country_tr: 'Yeni Kaledonya',
    code: 'nc'
  },
  {
    index: 114,
    country_en: 'New Zealand',
    country_ru: 'Новая Зеландия',
    country_uz: 'Yangi Zelandiya',
    country_kr: 'Янги Зеландия',
    country_tr: 'Yeni Zelanda',
    code: 'nz'
  },
  {
    index: 115,
    country_en: 'Nicaragua',
    country_ru: 'Никарагуа',
    country_uz: 'Nikaragua',
    country_kr: 'Никарагуа',
    country_tr: 'Nikaragua',
    code: 'ni'
  },
  {
    index: 116,
    country_en: 'Niger',
    country_ru: 'Нигер',
    country_uz: 'Niger',
    country_kr: 'Нигер',
    country_tr: 'Nijer',
    code: 'ne'
  },
  {
    index: 117,
    country_en: 'Nigeria',
    country_ru: 'Нигерия',
    country_uz: 'Nigeriya',
    country_kr: 'Нигерия',
    country_tr: 'Nijerya',
    code: 'ng'
  },
  {
    index: 118,
    country_en: 'North Korea',
    country_ru: 'КНДР',
    country_uz: 'Shimoliy Koreya',
    country_kr: 'Шимолий Корея',
    country_tr: 'Kuzey Kore',
    code: 'kp'
  },
  {
    index: 119,
    country_en: 'North Macedonia',
    country_ru: 'Македония',
    country_uz: 'Shimoliy Makedoniya',
    country_kr: 'Шимолий Македония',
    country_tr: 'Kuzey Makedonya',
    code: 'mk'
  },
  {
    index: 120,
    country_en: 'Northern Mariana Islands',
    country_ru: 'Северные Марианские острова',
    country_uz: 'Shimoliy Mariana orollari',
    country_kr: 'Шимолий Мариана ороллари',
    country_tr: 'Kuzey Mariana Adaları',
    code: 'mp'
  },
  {
    index: 121,
    country_en: 'Norway',
    country_ru: 'Норвегия',
    country_uz: 'Norvegiya',
    country_kr: 'Норвегия',
    country_tr: 'Norveç',
    code: 'no'
  },
  {
    index: 122,
    country_en: 'Oman',
    country_ru: 'Оман',
    country_uz: 'Ummon',
    country_kr: 'Уммон',
    country_tr: 'Umman',
    code: 'om'
  },
  {
    index: 123,
    country_en: 'Pakistan',
    country_ru: 'Пакистан',
    country_uz: 'Pokiston',
    country_kr: 'Покистон',
    country_tr: 'Pakistan',
    code: 'pk'
  },
  {
    index: 124,
    country_en: 'Palau',
    country_ru: 'Палау',
    country_uz: 'Palau',
    country_kr: 'Палау',
    country_tr: 'Palau',
    code: 'pw'
  },
  {
    index: 125,
    country_en: 'Palestinian Territory',
    country_ru: 'Палестина',
    country_uz: 'Falastin',
    country_kr: 'Фаластин',
    country_tr: 'Filistin',
    code: 'ps'
  },
  {
    index: 126,
    country_en: 'Panama',
    country_ru: 'Панама',
    country_uz: 'Panama',
    country_kr: 'Панама',
    country_tr: 'Panama',
    code: 'pa'
  },
  {
    index: 127,
    country_en: 'Papua New Guinea',
    country_ru: 'Папуа – Новая Гвинея',
    country_uz: 'Papua – Yangi Gvineya',
    country_kr: 'Папуа – Янги Гвинея',
    country_tr: 'Papua Yeni Gine',
    code: 'pg'
  },
  {
    index: 128,
    country_en: 'Paraguay',
    country_ru: 'Парагвай',
    country_uz: 'Paragvay',
    country_kr: 'Парагвай',
    country_tr: 'Paraguay',
    code: 'py'
  },
  {
    index: 129,
    country_en: 'Peru',
    country_ru: 'Перу',
    country_uz: 'Peru',
    country_kr: 'Перу',
    country_tr: 'Peru',
    code: 'pe'
  },
  {
    index: 130,
    country_en: 'Philippines',
    country_ru: 'Филиппины',
    country_uz: 'Filippin',
    country_kr: 'Филиппин',
    country_tr: 'Filipinler',
    code: 'ph'
  },
  {
    index: 131,
    country_en: 'Poland',
    country_ru: 'Польша',
    country_uz: 'Polsha',
    country_kr: 'Полша',
    country_tr: 'Polonya',
    code: 'pl'
  },
  {
    index: 132,
    country_en: 'Portugal',
    country_ru: 'Португалия',
    country_uz: 'Portugaliya',
    country_kr: 'Португалия',
    country_tr: 'Portekiz',
    code: 'pt'
  },
  {
    index: 133,
    country_en: 'Puerto Rico',
    country_ru: 'Пуэрто-Рико',
    country_uz: 'Puerto-Riko',
    country_kr: 'Пуэрто-Рико',
    country_tr: 'Porto Riko',
    code: 'pr'
  },
  {
    index: 134,
    country_en: 'Republic of the Congo',
    country_ru: 'Конго',
    country_uz: 'Kongo – Brazzavil',
    country_kr: 'Конго – Браззавил',
    country_tr: 'Kongo',
    code: 'cg'
  },
  {
    index: 135,
    country_en: 'Reunion',
    country_ru: 'Реюньон',
    country_uz: 'Reyunion',
    country_kr: 'Реюнион',
    country_tr: 'Reunion',
    code: 're'
  },
  {
    index: 136,
    country_en: 'Romania',
    country_ru: 'Румыния',
    country_uz: 'Ruminiya',
    country_kr: 'Руминия',
    country_tr: 'Romanya',
    code: 'ro'
  },
  {
    index: 137,
    country_en: 'Russia',
    country_ru: 'Россия',
    country_uz: 'Rossiya',
    country_kr: 'Россия',
    country_tr: 'Rusya Federasyonu',
    code: 'ru'
  },
  {
    index: 138,
    country_en: 'Rwanda',
    country_ru: 'Руанда',
    country_uz: 'Ruanda',
    country_kr: 'Руанда',
    country_tr: 'Ruanda',
    code: 'rw'
  },
  {
    index: 139,
    country_en: 'Saint Lucia',
    country_ru: 'Сент-Люсия',
    country_uz: 'Sent-Lyusiya',
    country_kr: 'Сент-Люсия',
    country_tr: 'Saint Lucia',
    code: 'lc'
  },
  {
    index: 140,
    country_en: 'Samoa',
    country_ru: 'Самоа',
    country_uz: 'Samoa',
    country_kr: 'Самоа',
    country_tr: 'Samoa',
    code: 'ws'
  },
  {
    index: 141,
    country_en: 'Sao Tome and Principe',
    country_ru: 'Сан-Томе и Принсипи',
    country_uz: 'San-Tome va Prinsipi',
    country_kr: 'Сан-Томе ва Принсипи',
    country_tr: 'Sao Tome ve Principe',
    code: 'st'
  },
  {
    index: 142,
    country_en: 'Saudi Arabia',
    country_ru: 'Саудовская Аравия',
    country_uz: 'Saudiya Arabistoni',
    country_kr: 'Саудия Арабистони',
    country_tr: 'Suudi Arabistan',
    code: 'sa'
  },
  {
    index: 143,
    country_en: 'Senegal',
    country_ru: 'Сенегал',
    country_uz: 'Senegal',
    country_kr: 'Сенегал',
    country_tr: 'Senegal',
    code: 'sn'
  },
  {
    index: 144,
    country_en: 'Serbia',
    country_ru: 'Сербия',
    country_uz: 'Serbiya',
    country_kr: 'Сербия',
    country_tr: 'Sırbistan',
    code: 'rs'
  },
  {
    index: 145,
    country_en: 'Sierra Leone',
    country_ru: 'Сьерра-Леоне',
    country_uz: 'Syerra-Leone',
    country_kr: 'Серра-Леоне',
    country_tr: 'Sierra Leone',
    code: 'sl'
  },
  {
    index: 146,
    country_en: 'Slovakia',
    country_ru: 'Словакия',
    country_uz: 'Slovakiya',
    country_kr: 'Словакия',
    country_tr: 'Slovakya',
    code: 'sk'
  },
  {
    index: 147,
    country_en: 'Slovenia',
    country_ru: 'Словения',
    country_uz: 'Sloveniya',
    country_kr: 'Словения',
    country_tr: 'Slovenya',
    code: 'si'
  },
  {
    index: 148,
    country_en: 'Solomon Islands',
    country_ru: 'Соломоновы Острова',
    country_uz: 'Solomon orollari',
    country_kr: 'Соломон ороллари',
    country_tr: 'Solomon Adaları',
    code: 'sb'
  },
  {
    index: 149,
    country_en: 'Somalia',
    country_ru: 'Сомали',
    country_uz: 'Somali',
    country_kr: 'Сомали',
    country_tr: 'Somali',
    code: 'so'
  },
  {
    index: 150,
    country_en: 'South Africa',
    country_ru: 'ЮАР',
    country_uz: 'Janubiy Afrika Respublikasi',
    country_kr: 'Жанубий Африка Республикаси',
    country_tr: 'Güney Afrika',
    code: 'za'
  },
  {
    index: 151,
    country_en: 'South Korea',
    country_ru: 'Южная Корея',
    country_uz: 'Janubiy Koreya',
    country_kr: 'Жанубий Корея',
    country_tr: 'Güney Kore',
    code: 'kr'
  },
  {
    index: 152,
    country_en: 'South Sudan',
    country_ru: 'Южный Судан',
    country_uz: 'Janubiy Sudan',
    country_kr: 'Жанубий Судан',
    country_tr: 'Güney Sudan',
    code: 'ss'
  },
  {
    index: 153,
    country_en: 'Spain',
    country_ru: 'Испания',
    country_uz: 'Ispaniya',
    country_kr: 'Испания',
    country_tr: 'İspanya',
    code: 'es'
  },
  {
    index: 154,
    country_en: 'Sri Lanka',
    country_ru: 'Шри-Ланка',
    country_uz: 'Shri-Lanka',
    country_kr: 'Шри-Ланка',
    country_tr: 'Sri Lanka',
    code: 'lk'
  },
  {
    index: 155,
    country_en: 'Sudan',
    country_ru: 'Судан',
    country_uz: 'Sudan',
    country_kr: 'Судан',
    country_tr: 'Sudan',
    code: 'sd'
  },
  {
    index: 156,
    country_en: 'Suriname',
    country_ru: 'Суринам',
    country_uz: 'Surinam',
    country_kr: 'Суринам',
    country_tr: 'Surinam',
    code: 'sr'
  },
  {
    index: 157,
    country_en: 'Svalbard and Jan Mayen',
    country_ru: 'Шпицберген и Ян-Майен',
    country_uz: 'Shpitsbergen va Yan-Mayen',
    country_kr: 'Шпитсберген ва Ян-Маен',
    country_tr: 'Svalbard ve Jan Mayen',
    code: 'sj'
  },
  {
    index: 158,
    country_en: 'Sweden',
    country_ru: 'Швеция',
    country_uz: 'Shvetsiya',
    country_kr: 'Шветсия',
    country_tr: 'İsveç',
    code: 'se'
  },
  {
    index: 159,
    country_en: 'Switzerland',
    country_ru: 'Швейцария',
    country_uz: 'Shveytsariya',
    country_kr: 'Швейтсария',
    country_tr: 'İsviçre',
    code: 'ch'
  },
  {
    index: 160,
    country_en: 'Syria',
    country_ru: 'Сирийская Арабская Республика',
    country_uz: 'Suriya',
    country_kr: 'Сурия',
    country_tr: 'Suriye',
    code: 'sy'
  },
  {
    index: 161,
    country_en: 'Taiwan',
    country_ru: 'Тайвань',
    country_uz: 'Tayvan',
    country_kr: 'Тайван',
    country_tr: 'Çin Cumhuriyeti',
    code: 'tw'
  },
  {
    index: 162,
    country_en: 'Tajikistan',
    country_ru: 'Таджикистан',
    country_uz: 'Tojikiston',
    country_kr: 'Тожикистон',
    country_tr: 'Tacikistan',
    code: 'tj'
  },
  {
    index: 163,
    country_en: 'Tanzania',
    country_ru: 'Танзания',
    country_uz: 'Tanzaniya',
    country_kr: 'Танзания',
    country_tr: 'Tanzanya',
    code: 'tz'
  },
  {
    index: 164,
    country_en: 'Thailand',
    country_ru: 'Таиланд',
    country_uz: 'Tailand',
    country_kr: 'Таиланд',
    country_tr: 'Tayland',
    code: 'th'
  },
  {
    index: 165,
    country_en: 'Timor Leste',
    country_ru: 'Тимор-Лесте',
    country_uz: 'Timor-Leste',
    country_kr: 'Тимор-Лесте',
    country_tr: 'Doğu Timor',
    code: 'tl'
  },
  {
    index: 166,
    country_en: 'Togo',
    country_ru: 'Того',
    country_uz: 'Togo',
    country_kr: 'Того',
    country_tr: 'Togo',
    code: 'tg'
  },
  {
    index: 167,
    country_en: 'Tonga',
    country_ru: 'Тонга',
    country_uz: 'Tonga',
    country_kr: 'Тонга',
    country_tr: 'Tonga',
    code: 'to'
  },
  {
    index: 168,
    country_en: 'Trinidad and Tobago',
    country_ru: 'Тринидад и Тобаго',
    country_uz: 'Trinidad va Tobago',
    country_kr: 'Тринидад ва Тобаго',
    country_tr: 'Trinidad ve Tobago',
    code: 'tt'
  },
  {
    index: 169,
    country_en: 'Tunisia',
    country_ru: 'Тунис',
    country_uz: 'Tunis',
    country_kr: 'Тунис',
    country_tr: 'Tunus',
    code: 'tn'
  },
  {
    index: 170,
    country_en: 'Turkey',
    country_ru: 'Турция',
    country_uz: 'Turkiya',
    country_kr: 'Туркия',
    country_tr: 'Türkiye Cumhuriyeti',
    code: 'tr'
  },
  {
    index: 171,
    country_en: 'Turkmenistan',
    country_ru: 'Туркменистан',
    country_uz: 'Turkmaniston',
    country_kr: 'Туркманистон',
    country_tr: 'Türkmenistan',
    code: 'tm'
  },
  {
    index: 172,
    country_en: 'Tuvalu',
    country_ru: 'Тувалу',
    country_uz: 'Tuvalu',
    country_kr: 'Тувалу',
    country_tr: 'Tuvalu',
    code: 'tv'
  },
  {
    index: 173,
    country_en: 'U.S. Virgin Islands',
    country_ru: 'Виргинские о-ва (США)',
    country_uz: 'AQSH Virgin orollari',
    country_kr: 'АҚШ Виргин ороллари',
    country_tr: 'ABD Virgin Adaları',
    code: 'vi'
  },
  {
    index: 174,
    country_en: 'Uganda',
    country_ru: 'Уганда',
    country_uz: 'Uganda',
    country_kr: 'Уганда',
    country_tr: 'Uganda',
    code: 'ug'
  },
  {
    index: 175,
    country_en: 'Ukraine',
    country_ru: 'Украина',
    country_uz: 'Ukraina',
    country_kr: 'Украина',
    country_tr: 'Ukrayna',
    code: 'ua'
  },
  {
    index: 176,
    country_en: 'United Arab Emirates',
    country_ru: 'Объединенные Арабские Эмираты',
    country_uz: 'Birlashgan Arab Amirliklari',
    country_kr: 'Бирлашган Араб Амирликлари',
    country_tr: 'Birleşik Arap Emirlikleri',
    code: 'ae'
  },
  {
    index: 177,
    country_en: 'United Kingdom',
    country_ru: 'Британия',
    country_uz: 'Britaniya',
    country_kr: 'Британия',
    country_tr: 'Birleşik Krallık',
    code: 'gb'
  },
  {
    index: 178,
    country_en: 'United States',
    country_ru: 'Соединенные Штаты',
    country_uz: 'AQSH',
    country_kr: 'АҚШ',
    country_tr: 'Amerika Birleşik Devletleri',
    code: 'us'
  },
  {
    index: 179,
    country_en: 'Uruguay',
    country_ru: 'Уругвай',
    country_uz: 'Urugvay',
    country_kr: 'Уругвай',
    country_tr: 'Uruguay',
    code: 'uy'
  },
  {
    index: 180,
    country_en: 'Uzbekistan',
    country_ru: 'Узбекистан',
    country_uz: "O'zbekiston Respublikasi",
    country_kr: 'Ўзбекистон Республикаси',
    country_tr: 'Özbekistan',
    code: 'uz'
  },
  {
    index: 181,
    country_en: 'Venezuela',
    country_ru: 'Венесуэла',
    country_uz: 'Venesuela',
    country_kr: 'Венесуэла',
    country_tr: 'Venezuela',
    code: 've'
  },
  {
    index: 182,
    country_en: 'Vietnam',
    country_ru: 'Вьетнам',
    country_uz: 'Vyetnam',
    country_kr: 'Ветнам',
    country_tr: 'Vietnam',
    code: 'vn'
  },
  {
    index: 183,
    country_en: 'Wallis and Futuna',
    country_ru: 'Острова Уоллис и Футуна',
    country_uz: 'Uollis va Futuna',
    country_kr: 'Уоллис ва Футуна',
    country_tr: 'Wallis ve Futuna Adaları',
    code: 'wf'
  },
  {
    index: 184,
    country_en: 'Yemen',
    country_ru: 'Йемен',
    country_uz: 'Yaman',
    country_kr: 'Яман',
    country_tr: 'Yemen',
    code: 'ye'
  },
  {
    index: 185,
    country_en: 'Zambia',
    country_ru: 'Замбия',
    country_uz: 'Zambiya',
    country_kr: 'Замбия',
    country_tr: 'Zambiya',
    code: 'zm'
  },
  {
    index: 186,
    country_en: 'Zimbabwe',
    country_ru: 'Зимбабве',
    country_uz: 'Zimbabve',
    country_kr: 'Зимбабве',
    country_tr: 'Zimbabve',
    code: 'zw'
  }
];


export function getCountryNameByCode(code: any) {
  const languageKey = `country_${i18n.language}`;

  const country = countrCode.find((country) => country.code === code);

  // @ts-ignore
  return country ? country[languageKey] : null;
}
