import React, { useState } from 'react';
import styles from './voronkaMessengerSetting.module.scss';
import instagramCardImage from '../../../../assets/img/instagramCardImage.png';
import facebookCard from '../../../../assets/img/facebookCard.png';
import myCallsCard from '../../../../assets/img/MyCallsCard.png';
import onlinePbx from '../../../../assets/img/online_pbx.png';
import VoronkaMessengerInstallModal from './voronkaMessengerInstallModal/VoronkaMessengerInstallModal';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import VoronkaTelegramBotModal from './voronkaTelegramBotModal/VoronkaTelegramBotModal';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import MyCalls from './myCalls/MyCalls';
import { useQueryClient } from 'react-query';
import OnlinePBX from './onlinePBX/OnlinePBX';
import DublikatControll from './dublikatControll/DublikatControll';
import WebFormaModal from './voronkaWebForm/WebFormaModal';
import SipuSetting from './sipu-setting/SipuSetting';
import sipuniImage from '../../../../assets/img/sipuniImage.png';
import { useCrmFacebookAccount, useMyCallsConfig, useOnlinePbx } from '../../../../queries/queries';
import { useCrmFacebookRedirectUrl } from '../../../../queries/mutation';
import { queryNames } from '../../../../utils/constants/queryNames';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../utils/models/GoogleAnalitks';
import SettingIcon from '../../../../assets/icons/SettingIcon';
import PlusIcon from '../../../../assets/icons/PlusIcon';
import tgChatBotPremium from '../../../../assets/img/tgChatBotPremium.png';
import { useSipuniConfigGet } from './sipu-setting/utils/service/queries';
import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';
import TGPremium from './tg-premium/TGPremium';

const VoronkaMessengerSetting = () => {
  const qc = useQueryClient();
  const location = useLocation();
  const { data: instagramAccountData } = useCrmFacebookAccount();
  const { data: myCalls } = useMyCallsConfig();
  const { data: onlinePbxData } = useOnlinePbx();
  const { data: sipuniConfig } = useSipuniConfigGet();

  const { t } = useTranslation();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isViewTelegramChatBot = permissions?.includes(Permissions.PERMISSION_TELEGRAM_CHAT_BOT);

  const instagramRedirectUrl = useCrmFacebookRedirectUrl(success);
  const [openMyCallsSettings, setOpenMyCallsSettings] = useState(false);
  const [onlinePbxSettings, setOnlinePbxSettings] = useState(false);
  const [dublikatControll, setDublikatControll] = useState(false);
  const [telegramBotModaltVisible, setTelegramBotModaltVisible] = useState(false);

  const [openTGPremium, setOpenTGPremium] = useState(false);

  const [webFormaVisible, setWebFormaVisible] = useState(false);
  const [visibleVoronkaMessengerInstall, setVisibleVoronkaMessengerInstall] = useState({
    visible: false,
    settingVisible: false,
    messenger: ''
  });

  const handleTGChatbot = (visible: boolean) => {
    setOpenTGPremium(visible);
  };


  // handle my calls settings
  const handleMyCallsSettings = (visible: boolean) => {
    qc.invalidateQueries([queryNames.FACEBOOK_PAGE_PIPE]);
    qc.invalidateQueries([queryNames.FACEBOOK_PIPE_STATUS]);
    setOpenMyCallsSettings(visible);
  };

  // handle my calls settings
  const handleOnlinePbx = (visible: boolean) => {
    setOnlinePbxSettings(visible);
  };

  // click ins
  function instagramEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CLICK_INSTAGRAM_CONFIG
    });
  }

  // click bot and webSite
  function botAndWebSiteEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.WEB_SITE_TELEGRAM_AND_BOT_CONFIG
    });
  }

  const handleInstall = async (settingVisible: boolean, messenger: string) => {
    if (!settingVisible) {
      await instagramRedirectUrl.mutate(Number(queryString.parse(location.search).voronka));
    }
    setVisibleVoronkaMessengerInstall({
      visible: true,
      settingVisible: settingVisible,
      messenger: messenger
    });
    instagramEvent();
  };

  function success(data: string) {
    return data;
  }

  const handleTelegramBot = () => {
    botAndWebSiteEvent();
    setTelegramBotModaltVisible(true);
  };

  const handleDublikatControll = () => {
    setDublikatControll(true);
  };

  //handleOpenWebForma
  // const handleOpenWebForma = () => {
  //   setWebFormaVisible(true)
  // }

  const configIntegration = (isConfig: boolean) => {
    return isConfig ? (
      <>
        <SettingIcon /> {t('crm_ins.Sozlash')}
      </>
    ) : (
      <>
        <PlusIcon /> {t('crm_ins.Ornatish')}
      </>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <button className={styles.container_settingButton} onClick={handleDublikatControll}>
          <SettingIcon /> {t('crm_ins.Dublikat_nazorati')}
        </button>
        <div className={styles.container_card}>
          <img src={instagramCardImage} alt="instagramCardImage" />
          <button onClick={() => handleInstall(!!instagramAccountData, 'INSTAGRAM')}>{configIntegration(!!instagramAccountData)}</button>
        </div>
        <div className={styles.container_card}>
          <img src={facebookCard} alt="facebookCard" />
          <button onClick={() => handleInstall(!!instagramAccountData, 'FACEBOOK')}>{configIntegration(!!instagramAccountData)}</button>
        </div>
        <div className={styles.container_card}>
          <img src={myCallsCard} alt={'Мои вонки'} />
          <button onClick={() => handleMyCallsSettings(true)}>{configIntegration(!!myCalls)}</button>
        </div>
        <div className={styles.container_card}>
          <img src={onlinePbx} alt={'onlinePbx'} />
          <button onClick={() => handleOnlinePbx(true)}>{configIntegration(!!onlinePbxData)}</button>
        </div>
        <div className={styles.container_card}>
          <img src={sipuniImage} alt={'sipuni'} />
          <SipuSetting data={sipuniConfig}>
            <button>{configIntegration(!!sipuniConfig)}</button>
          </SipuSetting>
        </div>

        {isViewTelegramChatBot && (
          <div className={styles.container_card}>
            <img src={tgChatBotPremium} alt="Telegram Business" />
            <button onClick={() => handleTGChatbot(true)}>
              <SettingIcon /> {t('crm_ins.Sozlash')}
            </button>
          </div>
        )}



        <button className={styles.container_settingButton} onClick={handleTelegramBot}>
          <SettingIcon /> {t('crm_ins.Sayt_telegram_sozlamasi')}
        </button>
        {/* <button
          className={styles.container_settingButton}
          onClick={handleOpenWebForma}
        >
          <SettingIcon />
          <div className={styles.container_settingButton_text}>
            <span>Veb forma</span>
            <span>
              <PlusIcon />
            </span>
          </div>
        </button> */}
      </div>

      <VoronkaMessengerInstallModal
        visible={visibleVoronkaMessengerInstall.visible}
        setVisible={setVisibleVoronkaMessengerInstall}
        settingVisible={visibleVoronkaMessengerInstall.settingVisible}
        url={success(instagramRedirectUrl.data?.data)}
        data={instagramAccountData}
        messenger={visibleVoronkaMessengerInstall.messenger}
      />
      <VoronkaTelegramBotModal visible={telegramBotModaltVisible} setVisible={setTelegramBotModaltVisible} />
      <MyCalls visible={openMyCallsSettings} onCancel={handleMyCallsSettings} />
      <OnlinePBX onlinePbx={onlinePbxData ? onlinePbxData : null} visible={onlinePbxSettings} onCancel={handleOnlinePbx} />
      <DublikatControll dublikatControll={dublikatControll} setDublikatControll={setDublikatControll} />
      <WebFormaModal visible={webFormaVisible} setVisible={setWebFormaVisible} />
      <TGPremium openTGPremium={openTGPremium} setOpenTGPremium={setOpenTGPremium} />
    </>
  );
};

export default VoronkaMessengerSetting;
