import React from 'react';

const ClockCounterClockwiseIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.25V10" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.25 11.875L10 10" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.60938 7.78906H2.48438V4.66406" stroke="#444444" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.14063 14.8594C6.10189 15.8214 7.32689 16.4768 8.66066 16.7425C9.99443 17.0082 11.3771 16.8724 12.6336 16.3522C13.8902 15.832 14.9642 14.9508 15.7199 13.8201C16.4756 12.6894 16.879 11.36 16.879 10C16.879 8.64002 16.4756 7.31058 15.7199 6.17987C14.9642 5.04917 13.8902 4.16798 12.6336 3.64779C11.3771 3.12761 9.99443 2.99179 8.66066 3.25752C7.32689 3.52324 6.10189 4.17858 5.14063 5.14063L2.48438 7.78907"
        stroke="#444444"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockCounterClockwiseIcon;
