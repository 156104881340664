export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const COLLAPSED_MENU = 'collapsed-menu';
export const MISSED_CALL_LIST_KEY = 'MISSED_CALL_LIST';
export const LIST_VIEW_CRM = 'LIST_VIEW_CRM';
export const CALENDAR_VIEW_TASK = 'CALENDAR_VIEW_TASK';
export const TYPE_VIEW_TASK = 'TYPE_VIEW_TASK';
export const REDIRECT_URL = 'REDIRECT_URL';
export const SHOWROOM_ACTIVE_PATH = 'showroom-active-path';
export const FIREBASE_TOKEN = 'firebaseToken';
