import React, { FC, useEffect } from 'react';
import { MapContainer, Popup, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useBigDataCloud } from '../../../queries/queries';

const iconPerson = new L.Icon({
  iconUrl: '/MapPin.svg',
  iconRetinaUrl: '/MapPin.svg',
  iconSize: new L.Point(25, 41)
});

export const DEFAULT_CENTER = {
  lat: 40.08535549506133,
  lng: 65.38380561484493
};

interface IProps {
  center: {
    lat: number;
    lng: number;
  };
  setCenter: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
}

const HouseLocationMap: FC<IProps> = ({ center, setCenter }) => {
  const { data } = useBigDataCloud(center.lat, center.lng);
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setCenter(e.latlng);
      },
      locationfound(e) {
        map.flyTo(center, map.getZoom());
      }
    });

    useEffect(() => {
      map.flyTo(center, map.getZoom());
      // eslint-disable-next-line
    }, [center]);

    return center === null ? null : (
      <Marker position={center} icon={iconPerson}>
        <Popup>
          {data?.principalSubdivision} {data?.locality}
        </Popup>
      </Marker>
    );
  };

  return (
    <MapContainer center={{ lat: center.lat, lng: center.lng }} zoom={13} scrollWheelZoom={true} style={{ height: '100%' }}>
      <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
      <LocationMarker />
    </MapContainer>
  );
};

export default HouseLocationMap;
