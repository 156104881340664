/* eslint-disable prefer-const */
import { useContext, useEffect, useState } from 'react';
import ArrowDownIcon from '@icons/ArrowDownIconCrm';
import DotsIcon from '@icons/DotsIcon';
import PlusIconAddCircle from '@icons/PlusIconAddCircle';
import TrushSquareIcon from '@icons/TrushSquareIcon';
import { langCounter } from '@utils/constants/langCounter';
import { CrmFieldOption } from '@utils/models/CrmField';
import { CrmFieldsType } from '@utils/models/CrmFieldsType';
import { Button, Checkbox, Dropdown, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { PaymentChangesContext } from '../../hooks/PaymentChangesContext';
import { usePaymentField } from '../../services/mutation';

import styles from './paymentCreateChange.module.scss';

const PaymentCreateChange = () => {
  const { setContext, visible, item } = useContext(PaymentChangesContext);
  const [nameLang, setNameLang] = useState<CrmFieldOption>({ name: '' });
  const [options, setOptions] = useState<CrmFieldOption[]>([]);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const createPaymentChange = usePaymentField();

  const type = Form.useWatch('type', form);

  const handleClose = () => {
    setContext({ visible: false, item: undefined });
    form.resetFields();
    setNameLang({ name: '' });
    setOptions([]);
  };
  const onFinish = (values: any) => {
    createPaymentChange
      .mutateAsync({
        id: item?.id as number,
        name: values?.name,
        nameUz: nameLang?.nameUz,
        nameEn: nameLang?.nameEn,
        nameRu: nameLang?.nameRu,
        nameTr: nameLang?.nameTr,
        options,
        type,
        required: values?.required,
        show: values?.show
      })
      .then(() => {
        handleClose();
      });
  };

  // handle change option
  const handleChangeOption = (index: number, value: string) => {
    const newOptions = [...options];

    newOptions[index].name = value;
    setOptions(newOptions);
  };

  // delete option
  const deleteOptions = (index: number) => {
    const newOptions = options.filter((item, i) => index !== i);

    let obj: any = {
      type: CrmFieldsType.SELECT,
      name: form.getFieldValue('name')
    };

    form.resetFields();

    for (let i = 0; i < newOptions.length; i++) {
      obj[`option${i}`] = newOptions[i].name;
    }

    form.setFieldsValue(obj);

    setOptions(newOptions);
  };

  // handleDeleteLang
  const handleDeleteLang = (optionKey: string, index: number) => {
    setOptions(prev => [
      ...prev.map((value, i) =>
        i === index
          ? {
              ...value,
              [optionKey]: null
            }
          : value
      )
    ]);
  };

  // add option
  const addOption = () => {
    const newOptions = [...options];

    newOptions.push({
      name: ''
    });
    setOptions(newOptions);
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        type: item?.type,
        name: item?.name,
        required: item?.required,
        show: item?.show
      });
      setNameLang({
        name: item?.name,
        nameUz: item?.nameUz,
        nameEn: item?.nameEn,
        nameRu: item?.nameRu,
        nameTr: item?.nameTr
      });
      setOptions(item?.options);
    }
  }, [item]);

  return (
    <Modal onCancel={handleClose} visible={visible} title={false} footer={false} closable={false} className={styles.container}>
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
        <Form.Item rules={[{ required: true, message: '' }]} name="type" label={<span className={styles.container_label}>{t('one_lid.Turi')}</span>}>
          <Select suffixIcon={<ArrowDownIcon />} className="customSelect">
            <Select.Option value={CrmFieldsType.SELECT}>{t('one_lid.Multi_royxat')}</Select.Option>
            <Select.Option value={CrmFieldsType.TEXT}>{t('one_lid.Matn')}</Select.Option>
            <Select.Option value={CrmFieldsType.DATE}>{t('home.Sana')}</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.formName}>
          <Form.Item
            rules={[{ required: true, message: '' }]}
            name="name"
            label={<span className={styles.container_label}>{t('one_lid.Nomi')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>
          <Dropdown
            overlay={
              <div className={styles.formName_dropDown}>
                {langCounter?.map(item => (
                  // eslint-disable-next-line react/jsx-key
                  <span
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      !nameLang[item.label as keyof typeof nameLang] &&
                        setNameLang(prev => ({
                          ...prev,
                          [item.label]: ''
                        }));
                    }}
                  >
                    {item?.name}
                  </span>
                ))}
              </div>
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <span>
              <DotsIcon />
            </span>
          </Dropdown>
        </div>
        {Object.keys(nameLang)
          ?.filter(key => key?.includes('name') && key?.length > 4 && nameLang[key as keyof typeof nameLang] !== null)
          .map(optionKey => (
            <div className={styles.nameTranslate} key={optionKey}>
              <Input
                value={nameLang[optionKey as keyof typeof nameLang]}
                addonBefore={optionKey?.substring(6, 4)}
                onChange={e =>
                  setNameLang(prev => ({
                    ...prev,
                    [optionKey]: e.target.value
                  }))
                }
              />
              <span
                onClick={() => {
                  setNameLang(prev => ({
                    ...prev,
                    [optionKey]: null
                  }));
                }}
              >
                <TrushSquareIcon />
              </span>
            </div>
          ))}
        {type === CrmFieldsType.SELECT && (
          <>
            {options.map((item, index) => (
              <>
                <Form.Item
                  //   rules={[{ required: true, message: '' }]}
                  name={`option${index}`}
                  label={
                    <span className={styles.container_label}>
                      {index + 1}
                      {t('one_lid.variant')}
                    </span>
                  }
                >
                  <div className="d-flex align-center">
                    <Input value={item.name} onChange={e => handleChangeOption(index, e.target.value)} />
                    <span className={styles.container_delete_icon}>
                      <Dropdown
                        overlay={
                          <div className={styles.formName_dropDown}>
                            <Dropdown
                              overlay={
                                <div className={styles.formName_dropDown}>
                                  {langCounter?.map(item => (
                                    // eslint-disable-next-line react/jsx-key
                                    <span
                                      onClick={() =>
                                        setOptions(prev => [
                                          ...prev.map((value, i) =>
                                            i === index && !value[item?.label as keyof typeof value]
                                              ? {
                                                  ...value,
                                                  [item?.label]: ''
                                                }
                                              : value
                                          )
                                        ])
                                      }
                                    >
                                      {item?.name}
                                    </span>
                                  ))}
                                </div>
                              }
                              placement="bottomRight"
                              trigger={['click']}
                            >
                              <span>{t('home.Tarjima_qilish')}</span>
                            </Dropdown>

                            <span onClick={() => deleteOptions(index)}>
                              <TrushSquareIcon />
                              {t('contract.Ochirish')}
                            </span>
                          </div>
                        }
                        trigger={['click']}
                      >
                        <span>
                          <DotsIcon />
                        </span>
                      </Dropdown>
                    </span>
                  </div>
                </Form.Item>
                {Object.keys(item)
                  ?.filter(key => key?.includes('name') && key?.length > 4 && item[key as keyof typeof item] !== null)
                  .map(optionKey => (
                    // eslint-disable-next-line react/jsx-key
                    <div className={styles.nameTranslate}>
                      <Input
                        value={item[optionKey as keyof typeof item]}
                        onChange={e => {
                          setOptions(prev => [
                            ...prev.map((value, i) =>
                              i === index
                                ? {
                                    ...value,
                                    [optionKey]: e.target.value
                                  }
                                : value
                            )
                          ]);
                        }}
                        addonBefore={optionKey?.substring(6, 4)}
                      />
                      <span onClick={() => handleDeleteLang(optionKey, index)}>
                        <TrushSquareIcon />
                      </span>
                    </div>
                  ))}
              </>
            ))}

            <div onClick={addOption} className={styles.container_add}>
              <PlusIconAddCircle />
              <span>{t('one_lid.Yangi_qoshish')}</span>
            </div>
          </>
        )}
        <Form.Item name="required" valuePropName="checked">
          <Checkbox>{t('home.Toldirish_majburiy')}</Checkbox>
        </Form.Item>
        <Form.Item name="show" valuePropName="checked">
          <Checkbox>{t('home.ACTIVE')}</Checkbox>
        </Form.Item>
        <div className={styles.container_footer}>
          <div className={styles.save_and_close}>
            <Button onClick={handleClose}>{t('one_lid.Yopish')}</Button>
            <Button htmlType="submit" type="primary" loading={createPaymentChange.isLoading}>
              {t('one_lid.Saqlash')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default PaymentCreateChange;
