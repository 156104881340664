import React from 'react';
import { useGetContractAndBooking } from '@pages/oneLid/services/queries';
import { useQueryParam } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { OneContractModel } from '@pages/oneLid/utils/models/ContractAndBookingModel';
import { useNavigate } from 'react-router-dom';
import { rootPaths } from '@routing/rootPaths';
import { MainPaths } from '@routing/main/mainPaths';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import NavigationIcon from '@icons/NavigationIcon';

import styles from './clientContract.module.scss';

const ClientContract = () => {
  const { t } = useTranslation();
  const { params } = useQueryParam();
  const { data: contract } = useGetContractAndBooking(Number(params?.idClient));
  const navigation = useNavigate();

  const goToOneContract = (e: OneContractModel) => {
    navigation(rootPaths.MAIN + MainPaths.CONTRACT + `/${e.id}/${urlEncode('№ ' + e?.contractNumber)}`);
  };
  return (
    <>
      {contract && (
        <div className={styles.contracts}>
          {contract?.contracts?.map(item => (
            <div className={styles.contracts_one}>
              <div className={styles.contracts_one_item}>
                <span>{t('boss_contract.Shartnoma_raqami')}</span>
                <div className={styles.contracts_one_item_navigate} onClick={() => goToOneContract(item)}>
                  <span>{item?.contractNumber}</span>
                  <NavigationIcon />
                </div>
              </div>
              {/* <div className={styles.contracts_one_item}>
                <span>{t('showroom.Xonadon')}</span>
                <p></p>
              </div> */}
              <div className={styles.contracts_one_item}>
                <span>{t('boss_contract.Shartnoma_summasi')}</span>
                <p>{`${item?.amount?.toLocaleString('ru')} ${item?.ccy}`}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ClientContract;
