import { $api } from "@service/RequestService";
import message from '@utils/helper/message';
import { useMutation, useQueryClient } from "react-query";

import { DeleteForm } from "modules/shaxmatka/forms/field/schema";

import { endPoints } from "../end-points";
import { keys } from "../keys";

export const useDeleteService = () => {
  const client = useQueryClient();

  return useMutation<void, any, DeleteForm>(
    async ({ id }) => {
      await $api.delete(`${endPoints.CREATE_SERVICE}/${id}`);
    },
    {
      onSuccess: async () => {
        client.invalidateQueries({ queryKey: [keys.UZUM_CONFIG] })
        message.success("Muvaffaqqiyatli")
      }
    }

  );
}