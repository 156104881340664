import React, { FC, memo, useEffect, useRef, useState } from 'react';
import DeleteIconTaskCreator from '@pages/oneLid/assets/icons/DeleteIconTaskCreator';
import EditIconTaskCreator from '@pages/oneLid/assets/icons/EditIconTaskCreator';
import InfoIconTaskCreator from '@pages/oneLid/assets/icons/InfoIconTaskCreator';
import { Button, Checkbox, Dropdown, Input, Menu, Modal, Popover } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import ClockNotificationIcon from '../../../../../../assets/icons/ClockNotificationIcon';
import CheckmarkIcon from '../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '../../../../../../assets/icons/crmIcons/crmIcons';
import MoreHorizIcon from '../../../../../../assets/icons/MoreHorizIcon';
import SmileyIcon from '../../../../../../assets/icons/SmileyIcon';
import TrushSquareIcon from '../../../../../../assets/icons/TrushSquareIcon';
import MDatePicker from '../../../../../../components/mDatePicker/MDatePicker';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { useCreateLeadTask } from '../../../../../../queries/mutation';
import { useCrmEmployee, useLeadTaskType } from '../../../../../../queries/queries';
import i18n from '../../../../../../service/I18nService';
import { emojies } from '../../../../../../utils/constants/emojies';
import { queryNames } from '../../../../../../utils/constants/queryNames';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../../utils/models/GoogleAnalitks';
import { LeadTaskNotClosed } from '../../../../../../utils/models/LeadTaskNotClosed';
import { useLeadTaskClosed, useLeadTaskDelete } from '../../../../services/mutation';

import Avatar from '../../../../../../assets/img/avatarDefaultClient.jpg';

import styles from './taskChatFooterNotification.module.scss';

interface IProps extends LeadTaskNotClosed {
  editNotificationId: number;
  setEditNotificationId: React.Dispatch<React.SetStateAction<number>>;
  id: number;
}

const TaskChatFooterNotification: FC<IProps> = props => {
  const qc = useQueryClient();
  const { params } = useQueryParam<any, { id: string }>();

  // close task
  function closeTaskEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.COMPLETE_TASK
    });
  }

  const leadTaskClose = useLeadTaskClosed(Number(params.id), closeTaskEvent);
  const deleteTask = useLeadTaskDelete(Number(params.id));
  const { data: leadTaskTypeAll } = useLeadTaskType();
  const inputRef = useRef<any>(null);
  const [createDedline, setCreateDedline] = useState({
    start: props.deadlineStart,
    end: props.deadlineFinish
  });
  const [meetingSelectVsible, setMeetingSelectVsible] = useState(false);
  const [activeMeetingSlectId, setActiveMeetingSlectId] = useState(1);
  const [mPickerVisble, setMPickerVisble] = useState(false);
  const [checked, setChecked] = useState(false);
  const [text, setText] = useState('');
  const [dateValue, setDateValue] = useState(`${props.deadlineStart} ${dayjs(props.deadlineFinish, 'DD.MM.YYYY HH:mm').format('HH:mm')}`);
  const [textEdit, setTextEdit] = useState('');
  const [edit, setEdit] = useState(false);
  const [activeEmployeeId, setActiveEmployeeId] = useState(0);
  const [selectionStart, setSelectionStart] = useState(0);
  const [visbleEmoje, setVisbleEmoje] = useState(false);
  const [mPickerVisible, setMPickerVisible] = useState(false);
  const [meetingSelectVisible, setMeetingSelectVisible] = useState(false);
  const { data: leadEmployeeList } = useCrmEmployee();
  const inputRefEdit = useRef<any>(null);
  const leadSave = useCreateLeadTask(Number(params.id));
  const { t } = useTranslation();

  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false); // Состояние для определения видимости модального окна с информацией

  // translateFor
  const translateFor = (name: string) => {
    switch (i18n.language) {
      case 'ru':
        return <>для {name}</>;
      case 'en':
        return <>for {name}</>;
      default:
        return <>{name} uchun</>;
    }
  };

  const handleEditable = () => {
    if (props.id === props.editNotificationId) {
      props.setEditNotificationId(0);
    } else {
      props.setEditNotificationId(props.id);
    }
  };

  const handleMeetingSelect = () => {
    setMeetingSelectVsible(true);
  };
  const handleMeetingSelectItem = (id: number) => {
    setMeetingSelectVsible(false);
    setActiveMeetingSlectId(id);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [props.editNotificationId, inputRef]);

  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setActiveMeetingSlectId(props.leadTaskTypeDto.id);
    }
    // eslint-disable-next-line
  }, [leadTaskTypeAll]);

  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(`${date} ${start} ${end}`);
    setCreateDedline({
      start: `${date} ${start}`,
      end: `${date} ${end}`
    });
  };

  const handlePicker = () => {
    setMPickerVisble(true);
  };

  const changeChecked = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  useEffect(() => {
    if (params.contractId) {
      qc.invalidateQueries([queryNames.COMPLETED_TASK, Number(params.contractId)]);
    }
    qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
    // eslint-disable-next-line
  }, [leadTaskClose.isSuccess]);

  const handleDeleteTask = () => {
    deleteTask.mutate(props.id);
  };

  useEffect(() => {
    qc.invalidateQueries([queryNames.TASK_FILTER_COUNT]);
    // eslint-disable-next-line
  }, [deleteTask.isSuccess]);

  const startTime = dayjs(props.deadlineStart, 'DD.MM.YYYY HH:mm').format('HH:mm');
  const finishTime = dayjs(props.deadlineFinish, 'DD.MM.YYYY HH:mm').format('HH:mm');

  // changeTextEdit
  const changeTextEdit = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextEdit(e.target.value);
  };

  // handleTextarea
  const handleTextarea = (e: any) => {
    setSelectionStart(e?.target?.selectionStart);
  };

  // handleEmoje
  const handleEmoje = (item: string) => {
    const textStart = textEdit?.slice(0, selectionStart);
    const textEnd = textEdit?.slice(selectionStart);

    setTextEdit(textStart + item + textEnd);
    setVisbleEmoje(false);
  };

  // handleSubmit
  const handleSubmit = () => {
    leadSave
      .mutateAsync({
        assignedById: activeEmployeeId,
        deadlineStart: createDedline.start,
        deadlineFinish: createDedline.end,
        leadId: Number(params?.id),
        leadTaskTypeId: activeMeetingSlectId,
        clientId: Number(params?.idClient),
        text: textEdit,
        id: props?.id
      })
      .then(() => {
        setEdit(!edit);
      });
  };

  const hadleEditTask = () => {
    setEdit(!edit);
    setActiveEmployeeId(leadEmployeeList?.find(item => item.fullName === props.assignedBy)?.id!);
    if (inputRefEdit) {
      inputRefEdit?.current?.focus();
    }
    setTextEdit(props.text);
  };

  const handleCloseLeadTask = () => {
    leadTaskClose.mutate({
      closedText: text,
      deadlineStart: createDedline.start,
      deadlineFinish: createDedline.end,
      reCreate: checked,
      id: props.id,
      leadTaskTypeId: activeMeetingSlectId
    });
  };

  const handleOpenTaskInfo = () => {
    setIsInfoModalVisible(!isInfoModalVisible); // Инвертируем состояние при каждом вызове функции
  };

  // menuItems
  const menuItems: ItemType[] = [
    {
      key: '1',
      label: (
        <div className={styles.flexItems}>
          <EditIconTaskCreator />
          {t('shaxmatka.Tahrirlash')}
        </div>
      ),
      onClick: () => hadleEditTask()
    },
    {
      key: '2',
      label: (
        <div className={styles.flexItems}>
          <DeleteIconTaskCreator />
          {t('one_lid.Ochirish')}
        </div>
      ),
      onClick: () => handleDeleteTask()
    },
    {
      key: '3',
      label: (
        <div className={styles.flexItems}>
          <InfoIconTaskCreator />
          {t('contract_id.Malumot')}
        </div>
      ),
      onClick: () => handleOpenTaskInfo()
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.container_top} onClick={handleEditable}>
        {!edit ? (
          <>
            <ClockNotificationIcon />

            <Popover overlayInnerStyle={{ borderRadius: '12px' }} content={<span>{props.createdBy}</span>}>
              <img style={{ width: '17px', height: '17px' }} src={Avatar} alt="error" />
            </Popover>
            <p>
              {`${dayjs(props.deadlineStart, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY')} ${`${startTime} ${finishTime}` === '00:00 23:59'
                ? t('one_task.Kun_davomida')
                : startTime === finishTime
                  ? startTime
                  : `${startTime} ${finishTime}`
                }`}{' '}
              {props.closedDate} {translateFor(props.assignedBy)}
            </p>
            {crmIcons[leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.icon?.split('#')[0]!]?.icon(
              `#${leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.icon?.split('#')[1]}`
            )}
            {leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.name === 'Uchrashuv' ||
              leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.name === 'Qayta aloqa' ||
              leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.name === 'To’lov'
              ? `${t(`one_lid.${leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.name}`)}:`
              : leadTaskTypeAll?.find(item => item.id === props.leadTaskTypeDto.id)?.name + (props?.text?.length > 0 ? ':' : '')}
            <p>{props.text}</p>
          </>
        ) : (
          <>
            <Dropdown
              onVisibleChange={visible => setMeetingSelectVisible(visible)}
              visible={meetingSelectVisible}
              overlay={
                <div className={styles.container_editable_bottom_meetingSelect}>
                  {leadTaskTypeAll?.map(item => (
                    <div key={item.id} onClick={() => handleMeetingSelectItem(item.id)}>
                      {crmIcons[item.icon.split('#')[0]]?.icon(`#${item.icon.split('#')[1]}`)} {item.name}
                      {activeMeetingSlectId === item.id && (
                        <span>
                          <CheckmarkIcon />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              }
              trigger={['click']}
              placement="topLeft"
            >
              <button onClick={handleMeetingSelect} className={styles.container_editable_bottom_meetingSelect_button}>
                {crmIcons[leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.icon?.split('#')[0]!]?.icon(
                  `#${leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.icon?.split('#')[1]}`
                )}
                {t(`one_lid.${leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.name}`)}
                <ArrowDownIcon />
              </button>
            </Dropdown>
            <Dropdown
              visible={mPickerVisible}
              onVisibleChange={setMPickerVisible}
              overlay={
                <div>
                  <MDatePicker
                    visibleTime={mPickerVisible}
                    onChangeMPicker={onChangeMPicker}
                    data={[
                      {
                        id: 'edit',
                        title: `${t('one_lid.Topshiriq')}: ${dayjs(props?.deadlineStart, 'DD.MM.YYYY HH:mm').format('HH:mm')} ${dayjs(
                          props?.deadlineFinish,
                          'DD.MM.YYYY HH:mm'
                        ).format('HH:mm')} `,
                        start: dayjs(props?.deadlineStart, 'DD.MM.YYYY HH:mm')?.toISOString(),
                        end: dayjs(props?.deadlineFinish, 'DD.MM.YYYY HH:mm')?.toISOString(),
                        allDay: false
                      }
                    ]}
                  />
                </div>
              }
              trigger={['click']}
              placement="topCenter"
            >
              <Input
                onClick={handlePicker}
                value={dateValue}
                className={styles.container_editable_bottom_meetingSelect_input}
                style={{
                  width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                }}
              />
            </Dropdown>
          </>
        )}
        <Dropdown overlay={<Menu onClick={e => e.domEvent.stopPropagation()} items={menuItems} />} placement="bottom" trigger={['click']}>
          <div className={styles.container_more} onClick={e => e.stopPropagation()}>
            <MoreHorizIcon width={3} height={16} />
          </div>
        </Dropdown>
      </div>
      {!edit && (
        <div
          className={styles.container_editable}
          style={{
            display: props.editNotificationId === props.id ? 'flex' : 'none'
          }}
        >
          <div className={styles.container_editable_top}>
            <Input.TextArea
              ref={inputRef}
              autoSize={{ minRows: 1, maxRows: 4 }}
              onChange={e => changeText(e)}
              onKeyDown={e => {
                if (e.ctrlKey && e.key === 'Enter') {
                  handleCloseLeadTask();
                }
              }}
              value={text}
            />
            <Button type="primary" loading={leadTaskClose.isLoading} onClick={handleCloseLeadTask}>
              {t('one_lid.Bajarildi')}
            </Button>
          </div>
          <div className={styles.container_editable_bottom}>
            <Checkbox onChange={changeChecked} checked={checked} />
            <Dropdown
              onVisibleChange={visble => setMeetingSelectVsible(visble)}
              visible={meetingSelectVsible}
              overlay={
                <div className={styles.container_editable_bottom_meetingSelect}>
                  {leadTaskTypeAll?.map(item => (
                    <div key={item.id} onClick={() => handleMeetingSelectItem(item.id)}>
                      {crmIcons[item.icon.split('#')[0]]?.icon(`#${item.icon.split('#')[1]}`)} {item.deleted ? item.name : t(`one_lid.${item.name}`)}
                      {activeMeetingSlectId === item.id && (
                        <span>
                          <CheckmarkIcon />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              }
              trigger={['click']}
              placement="topLeft"
            >
              <button onClick={handleMeetingSelect}>
                {crmIcons[leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.icon?.split('#')[0]!]?.icon(
                  `#${leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.icon?.split('#')[1]}`
                )}
                {leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.deleted
                  ? leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.name
                  : t(`one_lid.${leadTaskTypeAll?.find(item => item.id === activeMeetingSlectId)?.name}`)}
                <ArrowDownIcon />
              </button>
            </Dropdown>
            <Dropdown
              visible={mPickerVisble}
              onVisibleChange={setMPickerVisble}
              overlay={
                <div>
                  <MDatePicker
                    visibleTime={mPickerVisble}
                    onChangeMPicker={onChangeMPicker}
                    data={[
                      {
                        id: 'edit',
                        title: `${t('one_lid.Topshiriq')}: ${dayjs(props?.deadlineStart, 'DD.MM.YYYY HH:mm').format('HH:mm')} ${dayjs(
                          props?.deadlineFinish,
                          'DD.MM.YYYY HH:mm'
                        ).format('HH:mm')} `,
                        start: dayjs(props?.deadlineStart, 'DD.MM.YYYY HH:mm')?.toISOString(),
                        end: dayjs(props?.deadlineFinish, 'DD.MM.YYYY HH:mm')?.toISOString(),
                        allDay: false,
                        editable: false
                      }
                    ]}
                  />
                </div>
              }
              trigger={['click']}
              placement="topCenter"
            >
              <Input
                onClick={handlePicker}
                value={dateValue}
                style={{
                  width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                }}
              />
            </Dropdown>
            <span className={styles.container_editable_bottom_delete} onClick={handleDeleteTask}>
              <TrushSquareIcon /> {t('one_lid.Ochirish')}
            </span>
          </div>
        </div>
      )}
      {edit && (
        <>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            style={{ margin: '10px 0' }}
            className="customInput"
            ref={inputRefEdit}
            value={textEdit}
            onChange={changeTextEdit}
            onClick={handleTextarea}
            onKeyUp={handleTextarea}
          />
          <div className={styles.container_main_footer}>
            <Button type="primary" onClick={handleSubmit} loading={leadSave.isLoading}>
              {t('one_task.Saqlash')}
            </Button>
            <Button onClick={hadleEditTask}>{t('one_lid.Bekor_qilish')}</Button>
            <Dropdown
              overlay={
                <div className="emojies">
                  <div className="emojies_content">
                    {emojies?.map(item => (
                      <span onClick={() => handleEmoje(item)} key={item}>
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              }
              visible={visbleEmoje}
              onVisibleChange={setVisbleEmoje}
              placement="topCenter"
              trigger={['click']}
            >
              <SmileyIcon className={styles.container_submit_right_item} />
            </Dropdown>
          </div>
        </>
      )}
      <Modal className={styles.modal} visible={isInfoModalVisible} closable={false} onCancel={() => setIsInfoModalVisible(false)} footer={null}>
        <div className={styles.inform} style={{ marginBottom: '10px' }}>
          <p>{t('home.Yaratilgan_sana')}</p>
          <span>{props.createdDate}</span>
        </div>
        <div className={styles.inform}>
          <p>{t('home.Yaratdi')}</p>
          <span>{props.createdBy}</span>
        </div>
      </Modal>
    </div>
  );
};

export default memo(TaskChatFooterNotification);
