import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CurrencyItem } from '../../utils/models/Currency';

interface BossCurrencyInterface {
  currency: CurrencyItem | undefined;
  houses: number[] | null;
  buildings: number[] | null;
  discountHouses: number[] | null;
  discountBuildings: number[] | null;
  type: string;
  monthType: string;
  monthCurrency: string;
  discountCurrency: string;
}

const initialState: BossCurrencyInterface = {
  currency: undefined,
  houses: [],
  buildings: [],
  type: 'price',
  monthType: 'overallAmount',
  monthCurrency: 'USD',
  discountCurrency: 'USD',
  discountBuildings: [],
  discountHouses: []
};

export const bossCurrencySlice = createSlice({
  name: 'bossCurrency',
  initialState,
  reducers: {
    setBossCurrency: (state, action: PayloadAction<CurrencyItem | undefined>) => {
      state.currency = action.payload;
    },
    setChangeHouses: (state, action: PayloadAction<number[] | null>) => {
      state.houses = action.payload;
    },
    setChangeBuildings: (state, action: PayloadAction<number[] | null>) => {
      state.buildings = action.payload;
    },
    setChangeDiscountHouses: (state, action: PayloadAction<number[] | null>) => {
      state.discountHouses = action.payload;
    },
    setChangeDiscountBuildings: (state, action: PayloadAction<number[] | null>) => {
      state.discountBuildings = action.payload;
    },
    setChangeType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setChangeMonthType: (state, action: PayloadAction<string>) => {
      state.monthType = action.payload;
    },
    setChangeMonthCurrency: (state, action: PayloadAction<string>) => {
      state.monthCurrency = action.payload;
    },
    setChangeDiscountCurrency: (state, action: PayloadAction<string>) => {
      state.discountCurrency = action.payload;
    }
  }
});

export const {
  setBossCurrency,
  setChangeHouses,
  setChangeBuildings,
  setChangeMonthType,
  setChangeMonthCurrency,
  setChangeDiscountCurrency,
  setChangeType,
  setChangeDiscountBuildings,
  setChangeDiscountHouses
} = bossCurrencySlice.actions;
export default bossCurrencySlice.reducer;
