import React, { useEffect, useState } from 'react';
import styles from './chatItem.module.scss';
import { Form, Input, Spin, Switch } from 'antd';
import TickSquareIcon from '../../../../../assets/icons/TickSquareIcon';
import CancelBronGarbage from '../../../../../assets/icons/CancelBronGarbage';
import { BotChat } from '../../../../../utils/models/BotChat';
import { useDeleteBotChat, useEditBotChats } from '../../../../../queries/mutation';
import { useTranslation } from 'react-i18next';

interface Props {
  chat: BotChat;
}

const ChatItem: React.FC<Props> = ({ chat }) => {
  const [isChangeChat, setIsChangeChat] = useState(false);

  const [form] = Form.useForm<BotChat>();

  const { t } = useTranslation();

  const deleteChat = useDeleteBotChat(chat?.id || 0, chat.telegramBotId);
  const updateChat = useEditBotChats();

  useEffect(() => {
    if (chat) {
      form.setFieldsValue({
        title: chat.title,
        url: chat.url,
        status: chat.status
      });
    }
  }, [chat]);

  const handleDelete = () => {
    deleteChat.mutate();
  };

  const handleUpdate = () => {
    updateChat.mutate({
      ...form.getFieldsValue(),
      id: chat.id
    });
    setIsChangeChat(false);
  };

  const handleButton = () => {
    if (isChangeChat) {
      handleUpdate();
    } else {
      handleDelete();
    }
  };

  return (
    <Spin spinning={updateChat.isLoading || deleteChat.isLoading}>
      <Form form={form} name={`faqItem`}>
        <div className={styles.container}>
          <div className={styles.container_input}>
            <p className={styles.container_input_label}>{t('integration.Nomi')}</p>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
              name={'title'}
            >
              <Input onChange={() => setIsChangeChat(true)} className={'customInput'} />
            </Form.Item>
          </div>
          <div className={styles.container_input}>
            <p className={styles.container_input_label}>{t('integration.Url_manzili')}</p>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
              name={'url'}
            >
              <Input onChange={() => setIsChangeChat(true)} className={'customInput'} />
            </Form.Item>
          </div>
          <div className={styles.container_input}>
            <p className={styles.container_input_label} />
            <Form.Item valuePropName={'checked'} name={'status'}>
              <Switch onChange={() => setIsChangeChat(true)} />
            </Form.Item>
          </div>
          <div onClick={handleButton} className={styles.container_button}>
            {isChangeChat ? <TickSquareIcon /> : <CancelBronGarbage />}
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default ChatItem;
