import React from 'react';

const EyesIcon = () => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.25 3C6.72559 3 6.22267 3.21071 5.85186 3.58579C5.48105 3.96086 5.27273 4.46957 5.27273 5C5.27273 5.53043 5.48105 6.03914 5.85186 6.41421C6.22267 6.78929 6.72559 7 7.25 7C7.77441 7 8.27733 6.78929 8.64814 6.41421C9.01895 6.03914 9.22727 5.53043 9.22727 5C9.22727 4.46957 9.01895 3.96086 8.64814 3.58579C8.27733 3.21071 7.77441 3 7.25 3ZM7.25 8.33333C6.37599 8.33333 5.53778 7.98214 4.91976 7.35702C4.30174 6.7319 3.95455 5.88406 3.95455 5C3.95455 4.11595 4.30174 3.2681 4.91976 2.64298C5.53778 2.01786 6.37599 1.66667 7.25 1.66667C8.12401 1.66667 8.96222 2.01786 9.58024 2.64298C10.1983 3.2681 10.5455 4.11595 10.5455 5C10.5455 5.88406 10.1983 6.7319 9.58024 7.35702C8.96222 7.98214 8.12401 8.33333 7.25 8.33333ZM7.25 0C3.95455 0 1.14023 2.07333 0 5C1.14023 7.92667 3.95455 10 7.25 10C10.5455 10 13.3598 7.92667 14.5 5C13.3598 2.07333 10.5455 0 7.25 0Z"
      fill="#8B8B8B"
    />
  </svg>
);

export default EyesIcon;
