export const queryKeys = {
  GET_SMSBOT: 'GET_SMSBOT',
  POST_SMSBOT: 'POST_SMSBOT',
  GET_LEADTASKTYPE: 'GET_LEADTASKTYPE',
  GET_ORIGINATOR: 'GET_ORIGINATOR',
  GET_MESSAGE: 'GET_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  CHANGE_STATUS: 'CHANGE_STATUS',
  CONFIG_ALL: 'CONFIG_ALL'
};
