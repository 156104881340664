import React, { useEffect, useState } from 'react';
import { Permissions } from '@utils/constants/permissions';
import { cx } from '@utils/cx';
import message from '@utils/helper/message';
import { Button, Dropdown, Form } from 'antd';
import { Input as GInput } from 'global';
import { useTranslation } from 'react-i18next';

import DiscountShapeIcon from '../../../../assets/icons/DiscountShapeIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { changePricePerArea, changePricePerAreaTerrace, setChangePartId } from '../../../../store/reducers/CreateContractSlice';
// import { helper } from '../../../../utils/helper/helper';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { CurrencyItem } from '../../../../utils/models/Currency';

import styles from './discountPricePerArea.module.scss';

interface Props {
  area: number;
  totalAmount: number;
  firstPrice: number | null;
  isTerrace: boolean;
  currency: CurrencyItem | undefined;
  priceWithoutDiscount: number;
  id: number;
}

const DiscountPricePerArea: React.FC<Props> = ({ area, isTerrace, firstPrice, id, totalAmount, currency, priceWithoutDiscount }) => {
  const { user } = useAppSelector(state => state.userReducer);
  // const { flats } = useAppSelector(state => state.createContract);
  const isMultiPayment = user?.role?.permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const [form] = Form.useForm<{ priceString: number; percent: number; percentAmount: number }>();

  // const flatMore = flats?.find(item => item?.flat?.id === id);
  const [visibleDiscount, setVisibleDiscount] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const perAreaPercentPrice = firstPrice! / 100;

  useEffect(() => {
    const priceString = priceWithoutDiscount / area;

    form.setFieldsValue({
      priceString,
      percent: roundFloatNumber((firstPrice! - priceWithoutDiscount / area) / perAreaPercentPrice),
      percentAmount: firstPrice! - priceString
    });
  }, [form, priceWithoutDiscount, visibleDiscount]);

  // handle visible discount
  const handleVisibleDiscount = (visible: boolean) => {
    setVisibleDiscount(visible);
  };

  // handle calculate discount full
  const handleCalculateDiscountFull = () => roundFloatNumber(((firstPrice || 0) - form.getFieldValue('priceString')) * area).toLocaleString('ru');

  // handle calculate discount area
  // const handleCalculateDiscountArea = () => roundFloatNumber((firstPrice || 0) - helper.parseFloatStringToNumber(priceString)).toLocaleString('ru');

  // handle change price per area
  const handleChangePricePerArea = () => {
    const amount = form.getFieldValue('priceString');

    if (totalAmount < ((firstPrice || 0) - amount) * area) {
      message.error('Summa maksimal summadan katta');
      return;
    }
    if (isTerrace && firstPrice) {
      if (amount > firstPrice) {
        dispatch(
          changePricePerAreaTerrace({
            amount: firstPrice,
            id
          })
        );
      } else {
        dispatch(
          changePricePerAreaTerrace({
            amount,
            id
          })
        );
      }
    } else if (amount > (firstPrice || 0)) {
      dispatch(
        changePricePerArea({
          amount: firstPrice || 0,
          id
        })
      );
    } else {
      dispatch(
        changePricePerArea({
          amount,
          id
        })
      );
    }
    dispatch(setChangePartId(0));
    handleVisibleDiscount(false);
  };

  return (
    <Dropdown
      overlayClassName={styles.container_dropdown}
      {...(isMultiPayment ? { visible: false } : { visible: visibleDiscount })}
      onVisibleChange={handleVisibleDiscount}
      overlay={
        <Form form={form} name={`discountForm${isTerrace}`}>
          {/* <div className={styles.container_dropdown_header}>
            <p>{t('home_id.Chegirma')}</p>
            <CloseCircle onClick={() => handleVisibleDiscount(false)} />
          </div> */}

          <p className={styles.container_dropdown_title}>{t('home_id.Chegirmadan_keyingi_summani_kiriting')}</p>
          <Form.Item label="" name="priceString" rules={[{ required: true, message: '' }]}>
            <GInput
              onChange={value => {
                const price = value;

                form.setFieldsValue({
                  priceString: price,
                  percent: roundFloatNumber((firstPrice! - price) / perAreaPercentPrice),
                  percentAmount: firstPrice! - price
                });
              }}
              className="customInput"
              type="currency"
              returnType="number"
              maskOption={{ max: firstPrice || 0 }}
            />
          </Form.Item>
          <div className="mt-2">
            <p className={cx(styles.container_dropdown_title, 'flex items-center gap-[6px]')}>
              <DiscountShapeIcon /> {t('home_id.Chegirma')} ({handleCalculateDiscountFull()} {currency?.ccy})
            </p>
            <div className="flex w-full gap-[8px]">
              <Form.Item label="" name="percentAmount" rules={[{ required: false, message: '' }]} className="w-full">
                <GInput
                  onChange={value => {
                    const priceString = roundFloatNumber(firstPrice! - value);

                    form.setFieldsValue({
                      priceString,
                      percent: roundFloatNumber((firstPrice! - priceString) / perAreaPercentPrice)
                    });
                  }}
                  className="w-full"
                  suffix={currency?.ccy}
                  type="currency"
                  maskOption={{ max: firstPrice! }}
                  returnType="number"
                />
              </Form.Item>
              <Form.Item label="" name="percent" rules={[{ required: false, message: '' }]}>
                <GInput
                  onChange={value => {
                    const priceString = roundFloatNumber(firstPrice! - perAreaPercentPrice * (value || 0));

                    form.setFieldsValue({
                      priceString: roundFloatNumber(priceString),
                      percentAmount: roundFloatNumber(firstPrice! - priceString)
                    });
                  }}
                  className="w-[82px]"
                  suffix="%"
                  type="number"
                  maskOption={{ max: 100, min: 0 }}
                  returnType="number"
                />
              </Form.Item>
            </div>
          </div>
          <div className={styles.container_dropdown_button_cont}>
            <Button onClick={handleChangePricePerArea} className={styles.container_dropdown_button_cont_button} type="primary">
              {t('home_id.Tasdiqlash')}
            </Button>
          </div>
        </Form>
      }
      placement="bottom"
      trigger={['click']}
    >
      <p className={styles.container_area_and_price}>
        <span className={styles.container_area_and_price_area}>
          {area} {t('home_id.m2')}
        </span>
        <span className={styles.container_area_and_price_donut} />
        <span className={styles.container_area_and_price_price}>
          {roundFloatNumber(priceWithoutDiscount / area)?.toLocaleString('ru')} {currency?.ccy}
        </span>
      </p>
    </Dropdown>
  );
};

export default DiscountPricePerArea;
