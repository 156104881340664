import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTableConfigCustomMutation } from '@queries/mutation';
import { TableKeyCustom } from '@utils/models/TableKey';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import AddCircleIcon from '../../../../assets/icons/AddCircleIcon';
import DostsSixVertical from '../../../../assets/icons/DotsSixVertical';
import Edit2Icon from '../../../../assets/icons/Edit2Icon';
import SuccessIcon from '../../../../assets/icons/SuccessIcon';
import TrushSquareBlackStrokeIcon from '../../../../assets/icons/TrushSquareBlackStrokeIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { setActiveVoronkaId, setCreateVoronka } from '../../../../store/reducers/CrmSlice';
import { queryNames } from '../../../../utils/constants/queryNames';
import { PipeAll } from '../../../../utils/models/PipeAll';
import { useCreatePipe } from '../../services/mutation';

import CreateVoronka from './create-voronka/CreateVoronka';
import DeleteVoronka from './deleteVoronka/DeleteVoronka';
import ShablonModal from './shablon-modal/ShablonModal';

import styles from './voronka.module.scss';

interface IProps {
  voronkaVisible: boolean;
  setVoronkaVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: PipeAll[] | undefined;
}

const Voronka: FC<IProps> = ({ voronkaVisible, setVoronkaVisible, data }) => {
  const tableConfig = useTableConfigCustomMutation(TableKeyCustom.crm_voronka);
  const dispatch = useAppDispatch();
  const [deleteVoronkaVisible, setdeleteVoronkaVisible] = useState({
    visble: false,
    id: 0
  });
  const location = useLocation();
  const navigate = useNavigate();
  const qc = useQueryClient();

  const { t } = useTranslation();

  const createVoronka = useCreatePipe();
  const sortableColumn = useAppSelector(state => state.assigmentSlice.sortableColumn);
  const [createInputValue, setCreateInputValue] = useState('');
  const [voronkaList, setVoronkaList] = useState<PipeAll[] | undefined>(data);
  const inputRef = useRef<any>(null);
  const [createVoronkaVisible, setCreateVoronkaVisible] = useState(false);
  const [voronkaEditId, setVoronkaEditId] = useState(0);

  const [openShablon, setOpenShablon] = useState(false);

  const handleClose = () => {
    setVoronkaVisible(false);
  };

  const createVoronkaVisibled = async (e: any) => {
    e.stopPropagation();
    dispatch(setCreateVoronka(true));
  };

  const handleCreateVoronka = (e: any) => {
    e.stopPropagation();
    createVoronka.mutate([
      {
        name: createInputValue,
        orders: data?.length ? data?.length + 1 : 1
      }
    ]);
    setCreateVoronkaVisible(false);
    setCreateInputValue('');
  };

  const createInputChage = (e: any) => {
    setCreateInputValue(e.target.value);
  };
  const createInputDown = (e: any) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleCreateVoronka(e);
    }
    if (e.key === 'Escape') {
      setCreateVoronkaVisible(false);
    }
  };
  const deleteVoronka = async (e: any, id: number) => {
    e.stopPropagation();
    e.preventDefault();
    setdeleteVoronkaVisible({
      visble: true,
      id
    });
  };

  const handleVoronka = (id: number) => {
    tableConfig.mutate({ key: TableKeyCustom.crm_voronka, value: String(id) });
    dispatch(setActiveVoronkaId(id));
    navigate(`${location.pathname}?voronka=${id}`);
    setVoronkaVisible(false);
    qc.invalidateQueries([queryNames.PIPE_ALL]);
    qc.invalidateQueries([queryNames.CRM_LEAD_FILTER]);
  };

  const editVoronka = async (e: any, id: number, name: string) => {
    e.stopPropagation();
    e.preventDefault();
    await setVoronkaEditId(id);
    document.getElementById(name + id)?.focus();
  };

  const changeVoronkaName = (e: any) => {
    setVoronkaList(
      voronkaList?.map(item => ({
        ...item,
        name: item.id === voronkaEditId ? e.target.value : item.name
      }))
    );
  };

  const downVoronkaName = async (e: any) => {
    if (e.key === 'Enter') {
      await createVoronka.mutate([
        {
          name: voronkaList?.find(item => item.id === voronkaEditId)?.name!,
          orders: voronkaList?.find(item => item.id === voronkaEditId)?.orders!,
          id: voronkaEditId
        }
      ]);
      setVoronkaEditId(0);
    }
    if (e.key === 'Escape') {
      setVoronkaEditId(0);
    }
  };

  useEffect(() => {
    const windowClick = () => {
      setCreateVoronkaVisible(false);
      setVoronkaEditId(0);
    };

    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!result.destination) {
      return;
    }
    const voronkaListCopy = [...voronkaList!];
    const [removed] = voronkaListCopy.splice(source.index, 1);

    voronkaListCopy.splice(destination?.index!, 0, removed);
    setVoronkaList(voronkaListCopy);
    createVoronka.mutate([
      ...voronkaListCopy.map((item, index) => ({
        ...item,
        orders: index + 1
      }))
    ]);
  };

  useEffect(() => {
    setVoronkaList(data);
  }, [data, voronkaEditId]);

  return (
    <div
      className={styles.voronka}
      style={{
        opacity: voronkaVisible ? 1 : 0,
        pointerEvents: voronkaVisible ? 'inherit' : 'none'
      }}
      onMouseMove={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
    >
      <div className={styles.voronka_container}>
        <h1>{t('crm.BITIMLAR')}</h1>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="varonka">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {voronkaList?.map((item, index) => (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {previded => (
                      <div
                        {...previded.draggableProps}
                        ref={previded.innerRef}
                        style={{
                          ...previded.draggableProps.style
                        }}
                        key={item.id}
                      >
                        <Link className={styles.voronka_container_item} to={`${location.pathname}?voronka=${item.id}`} title={item.name} onClick={() => handleVoronka(item.id)}>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            voronkaEditId === item.id ? (
                              <input
                                value={item.name}
                                id={item.name + item.id}
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                className={styles.voronka_container_item_editable}
                                onChange={changeVoronkaName}
                                onKeyDown={downVoronkaName}
                              />
                            ) : item.name.length > 10 ? (
                              `${item.name.slice(0, 10)}...`
                            ) : (
                              item.name
                            )
                          }
                          {sortableColumn && (
                            <>
                              <div {...previded.dragHandleProps} className={styles.voronka_container_item_draggable}>
                                <DostsSixVertical />
                              </div>
                              <div className={styles.voronka_container_item_edit}>
                                <span onClick={e => editVoronka(e, item.id, item.name)}>
                                  <Edit2Icon />
                                </span>
                                <span onClick={e => deleteVoronka(e, item.id)}>
                                  <TrushSquareBlackStrokeIcon />
                                </span>
                              </div>
                            </>
                          )}
                        </Link>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {createVoronkaVisible ? (
          <div className={styles.voronka_container_create} style={{ cursor: 'default' }} onClick={e => e.stopPropagation()}>
            <input ref={inputRef} onKeyDown={createInputDown} value={createInputValue} onChange={createInputChage} />
            <span onClick={handleCreateVoronka}>
              <SuccessIcon />
            </span>
          </div>
        ) : (
          <div className={styles.voronka_container_create} onClick={createVoronkaVisibled}>
            <AddCircleIcon />
            {t('crm.Yangi_qoshish')}
          </div>
        )}
      </div>
      <div className={styles.voronka_mask} onClick={handleClose} />
      <DeleteVoronka
        visible={deleteVoronkaVisible.visble}
        id={deleteVoronkaVisible.id}
        setdeleteVoronkaVisible={setdeleteVoronkaVisible}
        setVoronkaList={setVoronkaList}
        voronkaList={voronkaList}
      />
      <ShablonModal openShablon={openShablon} setOpenShablon={setOpenShablon} />
      <CreateVoronka setOpenShablon={setOpenShablon} />
    </div>
  );
};

export default Voronka;
