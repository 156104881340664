/* eslint-disable react/jsx-key */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useGetCurrency } from '@pages/order-id-v2/services/queries';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditKpiPart } from '../../../queries/mutation';
import { helper } from '../../../utils/helper/helper';

import styles from '../../../pages/kpi/kpi.module.scss';

interface IProps {
  visible: boolean;
  onClose: (visible: boolean) => void;
  kpiId: number;
  type: string;
}

interface IForm {
  amount: string;
  count: string;
  currencyId: number;
}

const { Option } = Select;

const AddModalSum: React.FC<IProps> = ({ visible, onClose, kpiId, type }) => {
  const [form] = Form.useForm<IForm>();
  const { data: dataCurrency } = useGetCurrency();

  const createKpiPart = useEditKpiPart(handleSuccess);

  const { t } = useTranslation();

  function handleSuccess() {
    handleCancel();
  }

  const onFinish = (fields: IForm) => {
    if (type === 'FLAT_AREA') {
      createKpiPart.mutate({
        area: Number(helper.deleteSpace(fields.count)),
        amount: helper.parseFloatStringToNumber(fields.amount),
        currencyId: fields.currencyId,
        kpiId
      });
    } else {
      createKpiPart.mutate({
        count: Number(helper.deleteSpace(fields.count)),
        amount: helper.parseFloatStringToNumber(fields.amount),
        currencyId: fields.currencyId,
        kpiId
      });
    }
  };

  const handleCancel = () => {
    onClose(false);
    form.resetFields();
  };

  const handleOk = () => {
    handleCancel();
  };

  return (
    <Modal width={639} className="addModalKpi" title={t('kpi.Mukofot_formulasi')} visible={visible} onOk={handleOk} footer={false} onCancel={handleCancel}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className={`${styles.modal_item} d-flex align-center justify-space-between`}>
          <p>{type === 'FLAT_AREA' ? t('kpi.Sotilgan_uylar_maydoni_quyidagidan_yuqori_bolganda') : t('kpi.Sotilgan_uylar_soni_quyidagidan_yuqori_bolganda')}</p>
          <div className="d-flex align-center">
            <Form.Item
              name="count"
              // style={{ marginRight: 40 }}
              label=""
              rules={[
                { required: true, message: '' },
                {
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject('');
                    }
                    form.setFieldsValue({
                      count: helper.deleteNotNumbers(value, null)?.toLocaleString('ru')
                    });
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input className={`${styles.modal_item_input} customInput`} placeholder="..." />
            </Form.Item>
            <span className={styles.modal_item_value}>{type === 'FLAT_AREA' ? t('kpi.m2') : t('kpi.dona')}</span>
          </div>
        </div>
        <div className={`${styles.modal_item} d-flex align-center justify-space-between mb-[30px]`}>
          <p>{t('kpi.Mukofot_miqdori')}</p>
          <div className="d-flex align-center">
            <Form.Item
              name="amount"
              label=""
              rules={[
                { required: true, message: '' },
                {
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject('');
                    }
                    form.setFieldsValue({
                      amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                    });
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input className={`${styles.modal_item_input} customInput`} placeholder="..." />
            </Form.Item>
            <Form.Item
              name="currencyId"
              rules={[{ message: '', required: true }]}
              initialValue={dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.id}
              style={{ marginLeft: 10 }}
            >
              <Select className="customSelect" style={{ width: 80 }} placeholder={dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.ccy}>
                {dataCurrency?.map((item: any) => <Option value={item?.id}>{item?.ccy}</Option>)}
              </Select>
            </Form.Item>
            {/* <span className={styles.modal_item_value}>{t('kpi.som')}</span> */}
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}>{t('kpi.Yopish')}</Button>
          <Button loading={createKpiPart.isLoading} htmlType="submit" type="primary">
            {t('kpi.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddModalSum;
