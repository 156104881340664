import ArrowDown from '@pages/bossCrm/components/conversion/assets/icons/ArrowDown';
import ArrowUp from '@pages/bossCrm/components/conversion/assets/icons/ArrowUp';
import React from 'react';
import styles from './moreBtn.module.scss';
type Props = {
  isHaveMore: boolean;
  setIsHaveMore: (isHaveMore: boolean) => void;
};
const MoreBtn: React.FC<Props> = ({ isHaveMore, setIsHaveMore }) => {
  return (
    <div className={styles.more_btn} onClick={() => setIsHaveMore(!isHaveMore)}>
      {isHaveMore ? 'Yopish' : 'Barchasi'}
      {isHaveMore ? <ArrowUp /> : <ArrowDown />}
    </div>
  );
};

export default MoreBtn;
