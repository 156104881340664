import React, { useState } from 'react';
import PlusIcon from '@icons/PlusIcon';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import PaymentChange from './components/payment-change/PaymentChange';
import PaymentCreateChange from './components/payment-create-change/PaymentCreateChange';
import { ContextDataState, PaymentChangesContext } from './hooks/PaymentChangesContext';
import { usePaymentFieldGet } from './services/queries';

import styles from './paymentChanges.module.scss';

const PaymentChanges = () => {
  const { t } = useTranslation();
  const { data } = usePaymentFieldGet();
  const [paymentContext, setPaymentContext] = useState<ContextDataState>({
    visible: false
  });

  const setContext = (value: ContextDataState) => {
    setPaymentContext(prev => ({ ...prev, ...value }));
  };

  const createChange = () => {
    setContext({ visible: true });
  };

  return (
    <PaymentChangesContext.Provider
      value={{
        ...paymentContext,
        setContext
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>{t('home.Tolov_ozgaruvchilari')}</h1>
          <Button onClick={createChange}>
            <PlusIcon stroke="#00A389" /> {t('house_config.Yangi_yaratish')}
          </Button>
        </div>
        <div className={styles.body}>{data?.map(item => <PaymentChange key={item?.id} item={item} />)}</div>
      </div>
      <PaymentCreateChange />
    </PaymentChangesContext.Provider>
  );
};

export default PaymentChanges;
