import React from 'react';

const NewFileIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="9.5" fill="#EFF1F5" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="9.5" stroke="#EAECF0" />
      <path
        d="M22 10.2695V14.4001C22 14.9601 22 15.2401 22.109 15.4541C22.2049 15.6422 22.3578 15.7952 22.546 15.8911C22.7599 16.0001 23.0399 16.0001 23.6 16.0001H27.7305M22 25H16M24 21H16M28 17.9882V25.2C28 26.8802 28 27.7202 27.673 28.362C27.3854 28.9265 26.9265 29.3854 26.362 29.673C25.7202 30 24.8802 30 23.2 30H16.8C15.1198 30 14.2798 30 13.638 29.673C13.0735 29.3854 12.6146 28.9265 12.327 28.362C12 27.7202 12 26.8802 12 25.2V14.8C12 13.1198 12 12.2798 12.327 11.638C12.6146 11.0735 13.0735 10.6146 13.638 10.327C14.2798 10 15.1198 10 16.8 10H20.0118C20.7455 10 21.1124 10 21.4577 10.0829C21.7638 10.1564 22.0564 10.2776 22.3249 10.4421C22.6276 10.6276 22.887 10.887 23.4059 11.4059L26.5941 14.5941C27.113 15.113 27.3724 15.3724 27.5579 15.6751C27.7224 15.9436 27.8436 16.2362 27.9171 16.5423C28 16.8876 28 17.2545 28 17.9882Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewFileIcon;
