import React from 'react';

const DocumentUploadIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99964 11.833C5.7263 11.833 5.49964 11.6063 5.49964 11.333V8.53967L5.01964 9.01967C4.8263 9.213 4.5063 9.213 4.31297 9.01967C4.11964 8.82633 4.11964 8.50633 4.31297 8.313L5.6463 6.97967C5.7863 6.83967 6.0063 6.793 6.19297 6.873C6.37964 6.94633 6.49964 7.133 6.49964 7.333V11.333C6.49964 11.6063 6.27297 11.833 5.99964 11.833Z"
        fill="#00A389"
      />
      <path
        d="M7.33362 9.1676C7.20695 9.1676 7.08029 9.12094 6.98029 9.02094L5.64695 7.6876C5.45362 7.49427 5.45362 7.17427 5.64695 6.98094C5.84029 6.7876 6.16029 6.7876 6.35362 6.98094L7.68695 8.31427C7.88029 8.5076 7.88029 8.8276 7.68695 9.02094C7.58695 9.12094 7.46029 9.1676 7.33362 9.1676Z"
        fill="#00A389"
      />
      <path
        d="M9.99967 15.1673H5.99967C2.37967 15.1673 0.833008 13.6207 0.833008 10.0007V6.00065C0.833008 2.38065 2.37967 0.833984 5.99967 0.833984H9.33301C9.60634 0.833984 9.83301 1.06065 9.83301 1.33398C9.83301 1.60732 9.60634 1.83398 9.33301 1.83398H5.99967C2.92634 1.83398 1.83301 2.92732 1.83301 6.00065V10.0007C1.83301 13.074 2.92634 14.1673 5.99967 14.1673H9.99967C13.073 14.1673 14.1663 13.074 14.1663 10.0007V6.66732C14.1663 6.39398 14.393 6.16732 14.6663 6.16732C14.9397 6.16732 15.1663 6.39398 15.1663 6.66732V10.0007C15.1663 13.6207 13.6197 15.1673 9.99967 15.1673Z"
        fill="#00A389"
      />
      <path
        d="M14.6663 7.16633H11.9997C9.71967 7.16633 8.83301 6.27967 8.83301 3.99967V1.333C8.83301 1.133 8.95301 0.946334 9.13967 0.873C9.32634 0.793 9.53967 0.839667 9.68634 0.979667L15.0197 6.313C15.1597 6.453 15.2063 6.673 15.1263 6.85967C15.0463 7.04633 14.8663 7.16633 14.6663 7.16633ZM9.83301 2.53967V3.99967C9.83301 5.71967 10.2797 6.16633 11.9997 6.16633H13.4597L9.83301 2.53967Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default DocumentUploadIcon;
