import React from 'react';
import { useDeleteLeadAndClientCost } from '@pages/bossCrm/services/mutate';
import { useGetLeadAndClientCostConfig } from '@pages/bossCrm/services/queries';
import { LeadAndClientCostConfigModel } from '@pages/bossCrm/utils/models/lead-and-client-cost-config-model';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import TrushIcon from '../../../../assets/icons/crmIcons/TrushIcon';
import { PlanCostTableItem } from '../../../../utils/models/PlanFactLeadCost';

import styles from './tableCost.module.scss';

const TableCost: React.FC = () => {
  const { t } = useTranslation();
  const { mutate, isLoading: isLoadingDelete } = useDeleteLeadAndClientCost();
  const { leadAndClientCost, isLoading } = useGetLeadAndClientCostConfig();

  const onDelete = (id: number) => () => {
    mutate(id);
  };

  const initialColumn: ColumnsType<LeadAndClientCostConfigModel> = [
    {
      align: 'left',
      dataIndex: 'source',
      title: t('boss_crm.Xarajat_nomi')
    },
    {
      align: 'left',
      dataIndex: 'amount',
      title: t('boss_crm.Tolov_miqdori'),
      render: (amount: number) => amount.toLocaleString('ru')
    },
    {
      width: '35%',
      align: 'left',
      title: t('boss_crm.Amal_qilish_muddati'),
      render: (record: PlanCostTableItem) => (
        <>
          {record.startDate}-{record.finishDate}
        </>
      )
    },
    {
      title: '',
      width: '10%',
      dataIndex: 'id',
      render: (id: number) => (
        <span onClick={onDelete(id)} className="delete">
          <TrushIcon />
        </span>
      )
    }
  ];

  return (
    <div className={styles.container}>
      <Table columns={initialColumn} dataSource={leadAndClientCost} rowKey="id" pagination={false} loading={isLoading || isLoadingDelete} />
    </div>
  );
};

export default TableCost;
