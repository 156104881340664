
import React from 'react';

const AlignLeft02Icon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 7.5C11.1989 7.5 11.5484 7.5 11.824 7.38582C12.1916 7.23358 12.4836 6.94157 12.6358 6.57402C12.75 6.29837 12.75 5.94891 12.75 5.25C12.75 4.55109 12.75 4.20163 12.6358 3.92598C12.4836 3.55843 12.1916 3.26642 11.824 3.11418C11.5484 3 11.1989 3 10.5 3L4.5 3C3.80109 3 3.45163 3 3.17598 3.11418C2.80843 3.26642 2.51642 3.55843 2.36418 3.92597C2.25 4.20163 2.25 4.55109 2.25 5.25C2.25 5.94891 2.25 6.29837 2.36418 6.57402C2.51642 6.94157 2.80843 7.23358 3.17597 7.38582C3.45163 7.5 3.80109 7.5 4.5 7.5L10.5 7.5Z"
        fill="#667085"
      />
      <path
        d="M13.5 15C14.1989 15 14.5484 15 14.824 14.8858C15.1916 14.7336 15.4836 14.4416 15.6358 14.074C15.75 13.7984 15.75 13.4489 15.75 12.75C15.75 12.0511 15.75 11.7016 15.6358 11.426C15.4836 11.0584 15.1916 10.7664 14.824 10.6142C14.5484 10.5 14.1989 10.5 13.5 10.5H4.5C3.80109 10.5 3.45163 10.5 3.17598 10.6142C2.80843 10.7664 2.51642 11.0584 2.36418 11.426C2.25 11.7016 2.25 12.0511 2.25 12.75C2.25 13.4489 2.25 13.7984 2.36418 14.074C2.51642 14.4416 2.80843 14.7336 3.17597 14.8858C3.45163 15 3.80109 15 4.5 15L13.5 15Z"
        fill="#667085"
      />
      <path
        d="M10.5 7.5C11.1989 7.5 11.5484 7.5 11.824 7.38582C12.1916 7.23358 12.4836 6.94157 12.6358 6.57402C12.75 6.29837 12.75 5.94891 12.75 5.25C12.75 4.55109 12.75 4.20163 12.6358 3.92598C12.4836 3.55843 12.1916 3.26642 11.824 3.11418C11.5484 3 11.1989 3 10.5 3L4.5 3C3.80109 3 3.45163 3 3.17598 3.11418C2.80843 3.26642 2.51642 3.55843 2.36418 3.92597C2.25 4.20163 2.25 4.55109 2.25 5.25C2.25 5.94891 2.25 6.29837 2.36418 6.57402C2.51642 6.94157 2.80843 7.23358 3.17597 7.38582C3.45163 7.5 3.80109 7.5 4.5 7.5L10.5 7.5Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15C14.1989 15 14.5484 15 14.824 14.8858C15.1916 14.7336 15.4836 14.4416 15.6358 14.074C15.75 13.7984 15.75 13.4489 15.75 12.75C15.75 12.0511 15.75 11.7016 15.6358 11.426C15.4836 11.0584 15.1916 10.7664 14.824 10.6142C14.5484 10.5 14.1989 10.5 13.5 10.5H4.5C3.80109 10.5 3.45163 10.5 3.17598 10.6142C2.80843 10.7664 2.51642 11.0584 2.36418 11.426C2.25 11.7016 2.25 12.0511 2.25 12.75C2.25 13.4489 2.25 13.7984 2.36418 14.074C2.51642 14.4416 2.80843 14.7336 3.17597 14.8858C3.45163 15 3.80109 15 4.5 15L13.5 15Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default AlignLeft02Icon;
