import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const BriefCaseIcon: FC<IProps> = ({ color = '#0172CB', onClick, className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" onClick={onClick} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0597 4.65302C13.493 4.02635 12.5464 3.71302 11.173 3.71302H11.013V3.68635C11.013 2.56635 11.013 1.17969 8.50637 1.17969H7.49303C4.98637 1.17969 4.98637 2.57302 4.98637 3.68635V3.71969H4.82637C3.44637 3.71969 2.50637 4.03302 1.9397 4.65969C1.2797 5.39302 1.2997 6.37969 1.36637 7.05302L1.37303 7.09969L1.41715 7.56297C1.43142 7.71274 1.51217 7.84819 1.63833 7.93016C1.79869 8.03436 2.01991 8.17536 2.1597 8.25302C2.25303 8.31302 2.35303 8.36635 2.45303 8.41969C3.59303 9.04635 4.84637 9.46635 6.1197 9.67302C6.1797 10.2997 6.45303 11.033 7.91303 11.033C9.37303 11.033 9.6597 10.3064 9.70637 9.65969C11.0664 9.43969 12.3797 8.96635 13.5664 8.27302C13.6064 8.25302 13.633 8.23302 13.6664 8.21302C13.9101 8.07525 14.1623 7.90799 14.3961 7.74138C14.5096 7.66051 14.5818 7.53427 14.5972 7.39576L14.5997 7.37302L14.633 7.05969C14.6397 7.01969 14.6397 6.98635 14.6464 6.93969C14.6997 6.26635 14.6864 5.34635 14.0597 4.65302ZM8.72637 9.21969C8.72637 9.92635 8.72637 10.033 7.90637 10.033C7.08637 10.033 7.08637 9.90635 7.08637 9.22635V8.38635H8.72637V9.21969ZM5.9397 3.71302V3.68635C5.9397 2.55302 5.9397 2.13302 7.49303 2.13302H8.50637C10.0597 2.13302 10.0597 2.55969 10.0597 3.68635V3.71969H5.9397V3.71302Z"
        fill={color}
      />
      <path
        d="M13.6452 9.2827C13.9999 9.11678 14.4072 9.39767 14.3718 9.78766L14.1596 12.1258C14.0196 13.4592 13.4729 14.8192 10.5396 14.8192H5.45957C2.52624 14.8192 1.97957 13.4592 1.83957 12.1325L1.63886 9.92467C1.60382 9.53921 2.00175 9.25866 2.35537 9.416C3.1151 9.75402 4.2556 10.237 5.02927 10.4531C5.19286 10.4988 5.3254 10.6173 5.4035 10.7681C5.82435 11.5809 6.68602 12.0125 7.9129 12.0125C9.12775 12.0125 9.99947 11.5642 10.4221 10.7483C10.5003 10.5974 10.6333 10.4789 10.797 10.4328C11.6218 10.2004 12.8446 9.65717 13.6452 9.2827Z"
        fill={color}
      />
    </svg>
  );
};

export default BriefCaseIcon;
