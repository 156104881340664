import React from 'react';

const DashboardVidjet1 = () => {
  return (
    <svg width="88" height="111" viewBox="0 0 88 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 12C0.75 5.64873 5.89873 0.5 12.25 0.5H75.75C82.1013 0.5 87.25 5.64873 87.25 12V69C87.25 91.9198 68.6698 110.5 45.75 110.5H12.25C5.89872 110.5 0.75 105.351 0.75 99V12Z"
        stroke="#00A389"
      />
      <path
        d="M6.25 14C6.25 9.58172 9.83172 6 14.25 6H73.75C78.1683 6 81.75 9.58172 81.75 14V65C81.75 87.0914 63.8414 105 41.75 105H14.25C9.83172 105 6.25 101.418 6.25 97V14Z"
        fill="#E7F9F6"
      />
      <path d="M22.25 26H65.75" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
      <path
        d="M33.0611 67.4432C31.1899 67.4432 29.5838 66.9697 28.2429 66.0227C26.9096 65.0682 25.883 63.6932 25.1634 61.8977C24.4512 60.0947 24.0952 57.9242 24.0952 55.3864C24.1027 52.8485 24.4626 50.6894 25.1747 48.9091C25.8944 47.1212 26.9209 45.7576 28.2543 44.8182C29.5952 43.8788 31.1974 43.4091 33.0611 43.4091C34.9247 43.4091 36.527 43.8788 37.8679 44.8182C39.2088 45.7576 40.2353 47.1212 40.9474 48.9091C41.6671 50.697 42.027 52.8561 42.027 55.3864C42.027 57.9318 41.6671 60.1061 40.9474 61.9091C40.2353 63.7045 39.2088 65.0758 37.8679 66.0227C36.5346 66.9697 34.9323 67.4432 33.0611 67.4432ZM33.0611 63.8864C34.5156 63.8864 35.6634 63.1705 36.5043 61.7386C37.3527 60.2992 37.777 58.1818 37.777 55.3864C37.777 53.5379 37.5838 51.9848 37.1974 50.7273C36.8111 49.4697 36.2656 48.5227 35.5611 47.8864C34.8565 47.2424 34.0232 46.9205 33.0611 46.9205C31.6141 46.9205 30.4702 47.6402 29.6293 49.0795C28.7884 50.5114 28.3641 52.6136 28.3565 55.3864C28.349 57.2424 28.5346 58.803 28.9134 60.0682C29.2997 61.3333 29.8452 62.2879 30.5497 62.9318C31.2543 63.5682 32.0914 63.8864 33.0611 63.8864ZM55.2685 43.7273V67H51.0526V47.8295H50.9162L45.473 51.3068V47.4432L51.2571 43.7273H55.2685Z"
        fill="#00A389"
      />
      <path d="M22.25 85H45.25" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
    </svg>
  );
};

export default DashboardVidjet1;
