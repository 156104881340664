/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-template */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable arrow-body-style */
/* eslint-disable newline-after-var */
import React, { FC, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardIcon2 from '@icons/DashboardIcon2';
import { statistikGetUrl } from '@utils/helper/statistikGetUrl';
import { Avatar, Badge, Dropdown, Grid, Layout, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLocalStorage } from 'utils';

import ExportMainIcon from '../../../assets/icons/ExportMainIcon';
import LoginArrovRightIcon from '../../../assets/icons/LoginArrovRightIcon';
import LogOutIcon from '../../../assets/icons/LogOutIcon';
import SettingIcon from '../../../assets/icons/SettingIcon';
import ShowroomIcon from '../../../assets/icons/ShowroomIcon';
import SidebarArrowRightIcon from '../../../assets/icons/SidebarArrowRightIcon';
import TickSquareIcon from '../../../assets/icons/TickSquareIcon';
import UnionIcon from '../../../assets/icons/UnionIcon';
import UserAvatarIcon from '../../../assets/icons/UserAvatarIcon';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { BossPagePaths } from '../../../routing/bossPage/bossPagePaths';
import { MainPaths } from '../../../routing/main/mainPaths';
import { profilePaths } from '../../../routing/profile/profilePaths';
import { rootPaths } from '../../../routing/rootPaths';
import { LocalStorage } from '../../../service/LocalStorage';
import { BASE_URL } from '../../../service/RequestService';
import { COLLAPSED_MENU } from '../../../utils/constants/localStorageKeys';
import { Permissions } from '../../../utils/constants/permissions';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { logOut } from '../../../utils/helper/logOut';

import navBarlinks from './navBarLinks';

import logo from '../../../assets/img/logo.png';
import LoginImage from '../../../assets/img/unsplash_3TLl_97HNJo.png';

import styles from './navbar.module.scss';

const accesBossPermissions = [
  Permissions.PERMISSION_STATS_MAIN,
  Permissions.PERMISSION_STATS_PAYMENT,
  Permissions.PERMISSION_STATS_DEBT,
  Permissions.PERMISSION_STATS_HOUSE,
  Permissions.PERMISSION_STATS_CONTRACT,
  Permissions.PERMISSION_STATS_CRM_GENERAL,
  Permissions.PERMISSION_STATS_CRM_CALLS,
  Permissions.PERMISSION_STATS_CRM_TASKS,
  Permissions.PERMISSION_STATS_CRM_LEADS,
  Permissions.PERMISSION_STATS_SALE
];

const { Sider } = Layout;

interface IProps {
  style?: React.CSSProperties | undefined;
  colapsed: string | boolean;
  setColapsed: React.Dispatch<React.SetStateAction<string | boolean>>;
}

const NavBar: FC<IProps> = ({ style, colapsed, setColapsed }) => {
  const { push, location } = useQueryParam();
  const { xxl } = Grid.useBreakpoint();

  const user = useAppSelector(state => state.userReducer.user);
  const countTask = useAppSelector(state => state.notificationSlice.taskCount);

  const { t } = useTranslation();

  const logOutHandler = async () => {
    await logOut();
  };

  const permissionsMain = user?.role ? user?.role.permissions : user?.roleTokens && user?.roleTokens[0]?.role?.permissions;

  const isAccessBossPage = () => {
    let isAccess = false;
    if (user) {
      const permissions = [...(permissionsMain || [])];
      isAccess = permissions.some(item => accesBossPermissions.includes(item));
    }
    return isAccess;
  };

  // is access task page
  const isAccessTasks = permissionsMain?.includes(Permissions.PERMISSION_PAGE_CRM);

  const LogOut = () => {
    return (
      <div className="LoginUser">
        <div>
          <Avatar
            style={{
              width: '60px',
              height: '60px'
            }}
            src={`${BASE_URL}/${user?.avatar}`}
            alt={LoginImage}
          />
          <div onClick={goToProfile}>
            <p>{user?.fullName}</p>
            <span>
              {t('navbars.Profilni_korish')} <LoginArrovRightIcon />
            </span>
          </div>
        </div>
        <span onClick={logOutHandler}>
          <LogOutIcon />
          {t('navbars.Chiqish')}
        </span>
      </div>
    );
  };

  const goToProfile = () => {
    push(rootPaths.MAIN + MainPaths.PROFILE + profilePaths.INDEX);
  };

  // change collapsed
  const changeCollapsed = () => {
    LocalStorage.set(COLLAPSED_MENU, String(!colapsed));
    setColapsed(!colapsed);
  };

  useEffect(() => {
    if (!colapsed) {
      document.documentElement.style.setProperty('--navbarWidth', (!xxl ? 235 * 0.7 : 235) + 'px');
    } else {
      document.documentElement.style.setProperty('--navbarWidth', (!xxl ? 88 * 0.7 : 88) + 'px');
    }
  });

  const prevShowroom = btoa(location.pathname + location.search);

  const isShowroom = user?.role
    ? user?.role.permissions?.includes(Permissions.PERMISSION_SHOWROOM)
    : user?.roleTokens && user?.roleTokens[0]?.role?.permissions?.includes(Permissions.PERMISSION_SHOWROOM);

  const showroomLink = getLocalStorage('SHOWROOM_ACTIVE_PATH') || rootPaths.SHOWROOM;

  return (
    <Sider
      collapsedWidth={!xxl ? 88 * 0.7 : 88}
      width={!xxl ? 235 * 0.7 : 235}
      className={'navbar'}
      theme="light"
      trigger={null}
      collapsible
      collapsed={colapsed as boolean}
      style={style}
    >
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
        <span style={{ marginRight: '12px' }}>{!colapsed && 'UYSOT'}</span>
        <button onClick={changeCollapsed} className={styles.dashboardIconStyle}>
          <DashboardIcon2 />
        </button>
        <div className={styles.logoArrow} />
      </div>

      {/* <div className="SideBarArrow" onClick={changeCollapsed}>
        <UnionIcon />
        <span
          style={{
            transform: colapsed ? 'rotateY(0deg)' : 'rotateY(180deg)',
          }}
        >
          <SidebarArrowRightIcon />
        </span>
      </div>

      </div> */}

      <div className={styles.navbar_direction}>
        {navBarlinks.map((navlink: any) =>
          !colapsed ? (
            <NavLink key={navlink.path || navlink?.name} to={navlink.path}>
              {navlink.icon}
              <span>{t(`titles.${navlink.name}`)}</span>
              {navlink.name === 'roboto_calls' && (
                <Tooltip overlay={t('emp_dashboard.Test_rejimida_ishlamoqda')}>
                  <p className={styles.beta}>Beta</p>
                </Tooltip>
              )}
            </NavLink>
          ) : (
            <Tooltip placement="right" title={t(`titles.${navlink.name}`)}>
              <NavLink key={navlink.path} to={navlink.path} className={styles.navbar_direction_mini}>
                {navlink.icon}
              </NavLink>
            </Tooltip>
          )
        )}
        {isAccessTasks &&
          (!colapsed ? (
            <NavLink to={rootPaths.MAIN + MainPaths.TASK}>
              <TickSquareIcon size={24} />
              <span>
                {t(`titles.task`)}
                <Badge
                  style={{
                    marginLeft: '6px',
                    borderWidth: '0px',
                    borderStyle: 'none'
                  }}
                  count={countTask}
                />
              </span>
            </NavLink>
          ) : (
            <Tooltip placement="right" title={t(`titles.task`)}>
              <div className={styles.badgeContainer}>
                <NavLink to={rootPaths.MAIN + MainPaths.TASK} className={styles.navbar_direction_mini}>
                  <TickSquareIcon size={24} />
                </NavLink>
                <Badge
                  style={{
                    marginLeft: '6px',
                    borderWidth: '0px',
                    borderStyle: 'none'
                  }}
                  count={countTask}
                />
              </div>
            </Tooltip>
          ))}
      </div>
      <div className={styles.navbar_footer}>
        {!colapsed ? (
          <>
            {isAccessBossPage() && (
              <NavLink to={statistikGetUrl(user?.role?.permissions)}>
                <ExportMainIcon />
                <span>{t('navbars.Statistikalar')}</span>
              </NavLink>
            )}
            {isShowroom && (
              <NavLink
                to={{
                  pathname: showroomLink,
                  search: `prev=${prevShowroom}`
                }}
              >
                <ShowroomIcon />
                <span>{t('navbars.Showroom')}</span>
              </NavLink>
            )}
            <NavLink to={rootPaths.MAIN + '/setting'}>
              <SettingIcon />
              <span>{t('navbars.Sozlamalar')}</span>
            </NavLink>
            <Dropdown overlay={LogOut} trigger={['click']}>
              <div>
                <UserAvatarIcon />
                <div>
                  <h3>{user?.fullName}</h3>
                  <h4>{user?.role && getCurrentLanguageWord(user.role.lang)}</h4>
                </div>
              </div>
            </Dropdown>
          </>
        ) : (
          <>
            {isAccessBossPage() && (
              <Tooltip placement="right" title={t('navbars.Statistikalar')}>
                <NavLink to={statistikGetUrl(user?.role?.permissions)}>
                  <ExportMainIcon />
                </NavLink>
              </Tooltip>
            )}
            {isShowroom && (
              <Tooltip placement="right" title={t('navbars.Showroom')}>
                <NavLink
                  to={{
                    pathname: showroomLink,
                    search: `prev=${prevShowroom}`
                  }}
                >
                  <ShowroomIcon />
                </NavLink>
              </Tooltip>
            )}
            <Tooltip placement="right" title={t('navbars.Sozlamalar')}>
              <NavLink to={rootPaths.MAIN + '/setting'}>
                <SettingIcon />
              </NavLink>
            </Tooltip>
            <Dropdown overlay={LogOut} trigger={['click']}>
              <Tooltip placement="right" title={user?.role && getCurrentLanguageWord(user.role.lang)}>
                <div>
                  <UserAvatarIcon />
                </div>
              </Tooltip>
            </Dropdown>
          </>
        )}
      </div>
    </Sider>
  );
};

export default NavBar;
