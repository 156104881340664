import React, { useEffect } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { useGetFlatPriceChangesDate } from '@queries/queries';
import { Modal } from 'antd';
import { useFlatUndoPrice } from '@queries/mutation';
import { useTranslation } from 'react-i18next';
import ChangesUndoIcon from '@icons/ChangesUndoIcon';

import styles from './undoModal.module.scss';
import { useQueryClient } from 'react-query';
import { queryNames } from '@utils/constants/queryNames';

type Props = {
  openUndo: boolean;
  setOpenUndo: React.Dispatch<React.SetStateAction<boolean>>;
};

const UndoModal: React.FC<Props> = ({ openUndo, setOpenUndo }) => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const { params, searchParams } = useQueryParam<any, any>();
  const { data } = useGetFlatPriceChangesDate(params?.id, searchParams?.blockId);
  const refreshUndo = useFlatUndoPrice();

  //handle close
  const handleClose = () => {
    setOpenUndo(false);
  };

  const handleRefreshUndoPrice = () => {
    refreshUndo
      .mutateAsync({
        buildingId: searchParams?.blockId,
        houseId: params?.id
      })
      .then(handleClose);
  };

  useEffect(() => {
    if (openUndo) {
      qc.fetchQuery([queryNames.FLAT_PRICE_CHANGES_DATE, params?.id, searchParams?.blockId]);
    }
  }, [openUndo]);

  return (
    <Modal
      visible={openUndo}
      onCancel={handleClose}
      title={t('shaxmatka.Ozgarishlar_tarixi')}
      footer={false}
      className={styles.undoModal}
      zIndex={1051}
    >
      {data ? (
        <div className={styles.undoModal_content}>
          <div className={styles.undoModal_content_child}>
            <span>{t('changes.Sana')}</span>
          </div>
          <div className={styles.undoModal_content_child}>
            <span>{data}</span>
            <ChangesUndoIcon onClick={handleRefreshUndoPrice} />
          </div>
        </div>
      ) : (
        <span>{t("shaxmatka.O'zgarish_mavjud_emas")}</span>
      )}
    </Modal>
  );
};

export default UndoModal;
