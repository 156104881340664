import { useTranslation } from 'react-i18next';
import StatusCircleIcon from '@icons/StatusCircleIcon';
import styles from './showroomV2MenuShaxmatka.module.scss';
import { Button } from 'antd';
import SimpleThreeColorIcon from '@icons/SimpleThreeColorIcon';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setShowroomFilter, setSwitchFilterStatus } from '@reducers/ShowroomSlice';
import ComplexFourColorIcon from '@icons/ComplexFourColorIcon';
import { ContractStatus } from '@utils/models/ContractStatus';
import { contractStatusColor } from '@pages/showroom-v2/utils/constants/contractStatusColor';
import { FlatStatus } from '@utils/models/FlatStatus';
import { flatStatusColor } from '@pages/showroom-v2/utils/constants/flatStatusColor';

const contractStatuses = [ContractStatus.ACTIVE, ContractStatus.STARTED, ContractStatus.FINISHED];

const flatStatuses = [FlatStatus.SALE, FlatStatus.BOOKED, FlatStatus.SOLD, FlatStatus.RESERVE];

const ShowroomV2MenuShaxmatka = () => {
  const { switchFilterStatus, filter } = useAppSelector(state => state.showroomSlice);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleContractStatus = (status: ContractStatus) => {
    let statuses = filter?.contractStatuses ? [...filter?.contractStatuses] : [];
    if (statuses?.includes(status)) {
      statuses = statuses.filter(item => item !== status);
    } else {
      statuses.push(status);
    }

    dispatch(
      setShowroomFilter({
        contractStatuses: statuses?.length > 0 ? statuses : undefined
      })
    );
  };

  const handleFLatStatus = (status: FlatStatus) => {
    let statuses = filter?.commerceStatuses ? [...filter?.commerceStatuses] : [];
    if (statuses?.includes(status)) {
      statuses = statuses.filter(item => item !== status);
    } else {
      statuses.push(status);
    }

    dispatch(
      setShowroomFilter({
        commerceStatuses: statuses?.length > 0 ? statuses : undefined
      })
    );
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.btn}
        onClick={() => {
          dispatch(setSwitchFilterStatus(!switchFilterStatus));
          dispatch(
            setShowroomFilter({
              contractStatuses: undefined,
              commerceStatuses: undefined
            })
          );
        }}
      >
        {!switchFilterStatus ? (
          <span>
            <ComplexFourColorIcon /> {t('home.Murakkab')}
          </span>
        ) : (
          <span>
            <SimpleThreeColorIcon /> {t('home.Oddiy')}
          </span>
        )}
      </Button>
      {t('home_id.Xonadon')}:
      {flatStatuses?.map(status => (
        <p
          key={status}
          onClick={() => handleFLatStatus(status)}
          style={{
            opacity: filter?.commerceStatuses && filter?.commerceStatuses?.includes(status) ? 0.5 : 1,
            display: switchFilterStatus && status === FlatStatus.SOLD ? 'none' : ''
          }}
        >
          <StatusCircleIcon color={flatStatusColor[status]} /> {t(`home.${status}`)}
        </p>
      ))}
      {switchFilterStatus && (
        <>
          {t('home.Shartnoma')}:
          {contractStatuses?.map(status => (
            <p
              onClick={() => handleContractStatus(status)}
              key={status}
              style={{
                opacity: filter?.contractStatuses && filter?.contractStatuses?.includes(status) ? 0.5 : 1
              }}
            >
              <StatusCircleIcon color={contractStatusColor[status]} /> {t(`home.${status}`)}
            </p>
          ))}
        </>
      )}
    </div>
  );
};

export default ShowroomV2MenuShaxmatka;
