import React from 'react';
import { FlatId } from '@utils/models/Flat';

export interface ShaxmatkaContextDataState {
  incrementPrice?: 'SELECT' | 'BLOCK' | 'HOUSE' | '';
  houseId?: number;
  activeFlats?: FlatId[];
}

export interface ShaxmatkaContextData extends ShaxmatkaContextDataState {
  setContext: (value: ShaxmatkaContextDataState) => void;
}

export const ShaxmatkaContext = React.createContext<ShaxmatkaContextData>({
  setContext: () => {}
});
