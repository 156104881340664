/* eslint-disable max-len */
import React from 'react';
import i18n from '@service/I18nService';
import { helper } from '@utils/helper/helper';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { roundFloatNumber } from '../../../../../utils/helper/roundFloatNumber';
import { FlatDiscount } from '../../../../../utils/models/Discount';
import { FlatMore } from '../../../../../utils/models/FlatMore';
import { InformationForPdf } from '../../../../../utils/models/InformationForPdf';
import CalculatorTable from '../../calculator/CalculatorTable';
import UpdateIcon from '../assets/icons/UpdateIcon';
import Center from '../profil-side/Center';
import ImageSlider from '../profil-side/ImageSlider';
import LeftSide from '../profil-side/LeftSide';

import styles from './index.module.scss';

interface IProps {
  flatData: InformationForPdf | undefined;
  target: string[];
  checked: boolean;
  handleOpen: () => void;
  updateValues: {
    xodim: string | undefined;
    nomer: string | undefined;
    sana: string;
    ofis: string;
    call_center: string | undefined;
  };
  item: FlatMore;
  currentIndex: number;
  index: number;
  sendImages: number[][];
  setSendImages: React.Dispatch<React.SetStateAction<number[][]>>;
  setgetTableData: React.Dispatch<React.SetStateAction<FlatDiscount[] | undefined>>;
  beta2?: boolean;
}

const CenterBasic: React.FC<IProps> = ({ flatData, target, checked, handleOpen, updateValues, item, currentIndex, index, sendImages, setSendImages, setgetTableData, beta2 }) => {
  const { delay, prePayment, currency } = useAppSelector(state => state.createContract);

  const { t } = useTranslation();
  const monthlyPayment = () => roundFloatNumber((item?.totalAmount - (item?.discountAmount || 0) - prePayment) / (delay || 1)).toLocaleString('ru');

  const endPayment = roundFloatNumber(item?.totalAmount - (item?.discountAmount ? item?.discountAmount : 0));

  const hasTerracceArea = () => {
    if (item?.flat?.terrace?.area) {
      if (item?.flat?.repaired) {
        return item?.flat?.pricePerAreaRepaired === item?.flat?.terrace?.pricePerAreaRepaired;
      }
      return item?.flat?.pricePerArea === item?.flat?.terrace?.pricePerArea;
    }
    return false;
  };

  const oncePleceAmount = endPayment / (Number(item?.flat?.area) + (hasTerracceArea() ? item?.flat?.terrace?.area : 0));

  const salesOfficeAddress = [
    flatData?.houseDTO?.salesOfficeCity && flatData?.houseDTO?.salesOfficeCity[i18n.language as keyof typeof flatData.houseDTO.salesOfficeCity],
    flatData?.houseDTO?.salesOfficeDistrict && flatData?.houseDTO?.salesOfficeDistrict[i18n.language as keyof typeof flatData.houseDTO.salesOfficeDistrict],
    flatData?.houseDTO?.salesOfficeAddress
  ]
    .filter(item => !!item)
    .join(',');

  return (
    <div className={styles.center} style={{ display: index !== currentIndex ? 'none' : '' }}>
      <LeftSide flatData={flatData} item={item} />
      <div className={styles.center_in}>
        <Center flatData={flatData} item={item} />
        {item?.flat?.gallery ? (
          <div className={styles.place}>
            <p>{t`home_id.Turarjoy`}</p>
            {target[currentIndex] === '2' ? (
              <div className={styles.secondContentStyle}>
                {sendImages[index]?.map((id, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className={styles.doubleView} style={{ width: '50%' }} key={i}>
                    <ImageSlider index={i} flatIndex={index} sendImages={sendImages} setSendImages={setSendImages} item={item} id={id} />
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.contentStyle}>
                <ImageSlider
                  flatIndex={index}
                  sendImages={sendImages}
                  setSendImages={setSendImages}
                  index={0}
                  item={item}
                  id={sendImages && sendImages[index] ? sendImages[index][0] : 0}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}

        <div style={{ marginTop: '20px' }}>
          <CalculatorTable setgetTableData={setgetTableData} beta2={beta2} />
        </div>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.rightSide_top}>
          <div className={styles.rightSide_top_child}>
            <p>{t`shaxmatka.Xonalar_soni`}</p>
            <div className={styles.rightSide_top_child_in} style={{ backgroundImage: '#00A389' }}>
              <h3>{item?.flat?.rooms}x</h3>
            </div>
          </div>
          {item?.flat?.terrace ? (
            <>
              <div className={styles.rightSide_top_child}>
                <p>{t`home_id.Xonadon_maydoni`}</p>
                <h3>
                  {item?.flat?.area}m<sup>2</sup>
                </h3>
              </div>
              <div className={styles.rightSide_top_child}>
                <p>{t`home_id.Terassa_maydoni`}</p>
                <h3>
                  {item?.flat?.terrace?.area}m<sup>2</sup>
                </h3>
              </div>
            </>
          ) : (
            ''
          )}

          <div className={styles.rightSide_top_child}>
            <p>{t`showroom.Umumiy_maydon`}</p>
            <h3>
              {roundFloatNumber(item?.flat?.area + (item?.flat?.terrace?.area || 0))}m<sup>2</sup>
            </h3>
          </div>
          <div className={styles.rightSide_top_child}>
            <p>{t`home_id.Narxi`}</p>
            <h3>
              {item?.totalAmount.toLocaleString('ru')}
              {currency?.ccy}
            </h3>
          </div>
        </div>

        <div className={styles.rightSide_justify}>
          <div className={styles.rightSide_center}>
            <p>{t`one_task.Boshlangich_tolov`}</p>
            <h4>
              {item?.firstPayment.toLocaleString('ru')}
              {currency?.ccy}
            </h4>
            <p>{t('home.Qoldiq_summasi')}</p>
            <h4>
              {helper.deleteNotNumbersAndParseFloatNumber(String(endPayment - item?.firstPayment)).string} {currency?.ccy}
            </h4>
            {item?.discountAmount ? (
              <>
                <p>{t`home_id.Chegirma_summasi`}</p>
                <h4>
                  {item?.discountAmount && item?.discountAmount.toLocaleString('ru')}
                  {currency?.ccy}
                  {` (${item?.discountAmount === 0 ? 0 : item?.discountPercent}%)`}
                </h4>
              </>
            ) : (
              ''
            )}
            <p>{t`create_table_payments.1_m2_uchun_narx`}</p>
            <h4>
              {oncePleceAmount.toLocaleString('ru')} {currency?.ccy}
            </h4>
            <p>
              {t`home_id.Oylik_tolov`} ({`${delay}oy`})
            </p>
            <h4>
              {monthlyPayment()} {currency?.ccy}
            </h4>
            <div className={styles.rightSide_center_total}>
              <p>{t`home_id.Sotuv_summasi`}</p>
              <h3>
                {endPayment.toLocaleString('ru')}
                {currency?.ccy}
              </h3>
            </div>
          </div>
          <div className={styles.rightSide_bottom}>
            <div className={styles.rightSide_bottom_xodim}>
              <div className={styles.rightSide_bottom_xodim_title}>
                <div className={styles.rightSide_bottom_xodim_title_left}>
                  <p>{t(`home_id.Sotuv_ofisi_manzili`)}</p>
                  <h4>
                    {flatData?.houseDTO?.salesOfficeCity ? (
                      <>{salesOfficeAddress}</>
                    ) : (
                      <>
                        {flatData?.builderDTO?.city?.[i18n.language as keyof typeof flatData.builderDTO.city]},{' '}
                        {flatData?.builderDTO?.districtDTO?.[i18n.language as keyof typeof flatData.builderDTO.districtDTO]}, {flatData?.builderDTO?.address}
                      </>
                    )}
                  </h4>
                  <p>{t`home_id.Call_center_markazi`}</p>
                  <h4>
                    {flatData?.houseDTO?.callCenter ||
                      (updateValues?.call_center === flatData?.builderDTO?.callCenterPhone ? updateValues?.call_center : `+998 ${updateValues?.call_center}`)}
                  </h4>
                  {checked ? (
                    <>
                      <p>{t(`contract_id.Masul_xodim`)}</p>
                      <h4>{updateValues?.xodim}</h4>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <UpdateIcon className={styles.update} onClick={handleOpen} />
              </div>
              <div style={checked ? { display: 'block' } : { display: 'none' }} className={styles.rightSide_bottom_xodim_body}>
                <p>{t(`showroom.Murojaat_uchun`)}</p>
                <h4>{updateValues?.nomer === flatData?.builderDTO?.phone ? updateValues?.nomer : `+998 ${updateValues?.nomer}`}</h4>
              </div>
              <p>
                {t(`home_id.Taklif_vaqti_first`)} {updateValues?.sana} {t(`home_id.Taklif_vaqti_second`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterBasic;
