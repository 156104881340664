import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '../../../../service/RequestService';
import { endpoints } from '../utils/constants/endpoints';
import { SocialNetworlModel } from '../utils/models/SocialNetworkModel';

export function useGetSocialNetworkUrls() {
  return useQuery<SocialNetworlModel>([queryKeys.SOCIAL_NETWORK_URLS], async () => {
    const res = await $api.get(endpoints.SOCIAL_NETWORK_URLS);
    return res.data.data;
  });
}
