import React from 'react';

const StikersIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H11.25C15.3225 0.9375 17.0625 2.6775 17.0625 6.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625ZM6.75 2.0625C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V6.75C15.9375 3.2925 14.7075 2.0625 11.25 2.0625H6.75Z"
        fill="#8A8A8D"
      />
      <path
        d="M6.61508 7.31258C5.97758 7.31258 5.34008 7.07258 4.85258 6.58508C4.63508 6.36758 4.63508 6.00758 4.85258 5.79008C5.07008 5.57258 5.43008 5.57258 5.64758 5.79008C6.18008 6.32258 7.05008 6.32258 7.58258 5.79008C7.80008 5.57258 8.16008 5.57258 8.37758 5.79008C8.59508 6.00758 8.59508 6.36758 8.37758 6.58508C7.89008 7.06508 7.25258 7.31258 6.61508 7.31258Z"
        fill="#8A8A8D"
      />
      <path
        d="M11.3846 7.31258C10.7471 7.31258 10.1096 7.07258 9.62211 6.58508C9.40461 6.36758 9.40461 6.00758 9.62211 5.79008C9.83961 5.57258 10.1996 5.57258 10.4171 5.79008C10.9496 6.32258 11.8196 6.32258 12.3521 5.79008C12.5696 5.57258 12.9296 5.57258 13.1471 5.79008C13.3646 6.00758 13.3646 6.36758 13.1471 6.58508C12.6596 7.06508 12.0221 7.31258 11.3846 7.31258Z"
        fill="#8A8A8D"
      />
      <path
        d="M9 14.3625C6.825 14.3625 5.0625 12.5925 5.0625 10.425C5.0625 9.7425 5.6175 9.1875 6.3 9.1875H11.7C12.3825 9.1875 12.9375 9.7425 12.9375 10.425C12.9375 12.5925 11.175 14.3625 9 14.3625ZM6.3 10.3125C6.24 10.3125 6.1875 10.365 6.1875 10.425C6.1875 11.9775 7.4475 13.2375 9 13.2375C10.5525 13.2375 11.8125 11.9775 11.8125 10.425C11.8125 10.365 11.76 10.3125 11.7 10.3125H6.3Z"
        fill="#8A8A8D"
      />
    </svg>
  );
};

export default StikersIcon;
