import React, { FC } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { useTaskCRMFilter } from '@queries/queries';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { ScrollPagination } from 'global';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import AssigmentCard from '../assigmentCard/AssigmentCard';
import { hoverProps } from '../TaskContainer';

import { assigmentObj } from './DndTaskContainer';

import styles from './dndTaskContainer.module.scss';

interface IProps {
  assignType: string;
  hower: hoverProps;
}

const DndTaskColumn: FC<IProps> = ({ assignType, hower }) => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsTask, null>();
  const diffTime = dayjs().startOf('day').valueOf() - dayjs(searchParams?.finishDate, DATE_FORMAT).valueOf();
  const endOffLastMonth = dayjs().add(-1, 'month').endOf('M').format(DATE_WITH_TIME_FORMAT);

  const { data, fetchNextPage, isLoading, isFetching, hasNextPage, isError } = useTaskCRMFilter(
    20,
    searchParams,
    assigmentObj[assignType]?.startDate,
    endOffLastMonth === searchParams?.finishDate && assignType === 'Otib_ketgan' ? searchParams?.finishDate : assigmentObj[assignType]?.finishDate,
    // eslint-disable-next-line no-nested-ternary
    assignType === 'Otib_ketgan' && diffTime > 0
      ? true
      : // eslint-disable-next-line no-nested-ternary
        diffTime < 0
        ? true
        : // eslint-disable-next-line no-nested-ternary
          assignType === 'Bugungi' && diffTime === 0
          ? true
          : // eslint-disable-next-line no-unneeded-ternary
            !searchParams?.startDate
            ? true
            : false
  );

  return (
    <ScrollPagination
      hasNextPage={hasNextPage!}
      isLoading={isLoading}
      onScrollEnd={() => {
        fetchNextPage();
      }}
      isError={isError}
    >
      {({ ref }) => (
        <Spin spinning={isLoading} style={{ height: '100vh' }}>
          <div className={styles.columns}>
            <div className={styles.header}>
              <span className={styles.header_center}>{t(`task.${assignType}`)}</span>
              <span className={styles.header_abs}>{data?.pages[0]?.totalElements || 0}</span>
            </div>
            <div style={{ margin: 8, marginTop: 0, minWidth: '100%', height: '100%' }}>
              <Droppable isDropDisabled={!!(hower?.delete || hower?.done || hower?.lastDay || hower?.lastMonday || hower?.lastMonth)} droppableId={assignType} key={assignType}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver && assignType !== 'Otib_ketgan' ? 'lightblue' : 'transparent',
                      padding: 4,
                      minWidth: snapshot.isDraggingOver ? '100%' : '100%',
                      minHeight: 'calc(100vh - 200px)'
                    }}
                    className={styles.column}
                  >
                    {data?.pages.map(item =>
                      item?.data?.map((item, index) => (
                        <Draggable key={item?.id} draggableId={String(item?.id)} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                userSelect: 'none',
                                margin: '0 0 8px 0',
                                minHeight: '50px',
                                ...provided.draggableProps.style
                              }}
                            >
                              <span
                                style={{
                                  opacity: snapshot.isDragging ? 0.7 : 1
                                }}
                              >
                                <AssigmentCard {...item} />
                              </span>
                            </div>
                          )}
                        </Draggable>
                      ))
                    )}
                    {provided.placeholder}
                    <div ref={ref} className="h-[1px]" />
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </Spin>
      )}
    </ScrollPagination>
  );
};

export default DndTaskColumn;
