import { PaymentType } from './PaymentType';

export enum PaymentReason {
  CONTRACT = 'CONTRACT',
  SURCHARGE = 'SURCHARGE'
}

export interface CreatePaymentBody {
  amount: number;
  type: PaymentType;
  base: PaymentReason | string;
  clientId: number | null;
  note?: string;
  date?: string;
  number: string | null;
  currencyId: number;
  balanceCurrencyId?: number;
  exchange?: number | null;
  mortgage?: boolean;
  fields?: Array<{ fieldId: number; value: any }>;
}
