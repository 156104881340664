import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { changeDiscount, setDiscountText, setOptionsContract, setTermDIscount, setTotalAmount, setVariation } from '@reducers/CreateContractSlice';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';
import { Select } from 'antd';

import { ITermDiscount, IVariation, useContractVariationGroupId, useTermDiscountAvailable } from 'modules/multi-payment';

const MultiPaymentSelect = () => {
  const { data: discounts } = useTermDiscountAvailable();
  const { totalAmount, constTotalAmount, currency, variation, discountSum, flats, discountText, termDiscount, delay } = useAppSelector(state => state.createContract);
  const [oneFlat] = flats;
  const { data: variationData } = useContractVariationGroupId({ id: oneFlat?.flat?.variationGroupId });
  const dispatch = useAppDispatch();

  const changePriceTotal = (constTotalAmount || totalAmount) + ((constTotalAmount || totalAmount) / 100) * (variation?.changePricePercent || 0);

  // const parts = data?.reduce((p, c) => {
  //   if (c.parts) {
  //     p = [...p, ...c?.parts];
  //   }
  //   return p;
  // }, [] as IVariation[]);

  return (
    <div className="grid w-full grid-cols-2 gap-[16px] px-[24px]">
      {variationData && (
        <label className="flex flex-1 flex-col">
          Shartnoma turi
          <Select
            className="customSelect"
            value={variation?.id}
            onChange={(value, option) => {
              const data = (option as any).data as IVariation;
              const amount = (constTotalAmount || totalAmount) + ((constTotalAmount || totalAmount) / 100) * data?.changePricePercent;

              const discountPercentSum = (amount / 100) * (discountSum / (changePriceTotal / 100));

              dispatch(setTotalAmount({ amount: amount - discountPercentSum, constAmount: constTotalAmount || totalAmount }));
              dispatch(
                setVariation({
                  ...data,
                  ...(data?.mortgagePercent && {
                    mortgagePrice: roundFloatNumber(data?.mortgagePercent * ((amount - discountPercentSum) / 100))
                    // mortgagePaymentDate: variation?.mortgagePaymentDate ? variation?.mortgagePaymentDate : dayjs().format(DATE_FORMAT)
                  })
                })
              );
              dispatch(
                setOptionsContract({
                  delay: data?.contractMonth,
                  prePayment: ((amount - discountPercentSum) / 100) * data?.prepaymentPercent,
                  useDiscount: false,
                  notDiscount: true
                })
              );

              if (discountPercentSum) {
                dispatch(
                  changeDiscount({
                    amount: discountPercentSum,
                    changeRepairedStatus: false
                  })
                );
                dispatch(setDiscountText(`${discountPercentSum?.toLocaleString('ru')} ${currency?.ccy} chegirma qo'llanildi`));
              }
            }}
            allowClear
            onClear={() => {
              dispatch(setTotalAmount({ amount: constTotalAmount, constAmount: constTotalAmount }));
              dispatch(setVariation(undefined));
              dispatch(
                setOptionsContract({
                  delay: oneFlat?.flat?.delay,
                  prePayment: oneFlat?.flat?.prepayment,
                  useDiscount: false,
                  notDiscount: true
                })
              );

              const discountPercentSum = ((constTotalAmount / 100) * discountSum) / ((totalAmount + discountSum) / 100);

              if (discountPercentSum) {
                dispatch(
                  changeDiscount({
                    amount: discountPercentSum,
                    changeRepairedStatus: false
                  })
                );
                dispatch(setDiscountText(`${discountPercentSum?.toLocaleString('ru')} ${currency?.ccy} chegirma qo'llanildi`));
              }
            }}
          >
            {variationData?.parts?.map(item => (
              <Select.Option key={item?.id} data={item} value={item?.id}>
                {item?.prepaymentPercent}% bosh to‘lov + {item?.monthlyPaymentPercent}% muddatli to‘lov {item?.mortgagePercent ? `+ ipoteka ${item?.mortgagePercent}%` : ''} +{' '}
                {item?.contractMonth} oy
              </Select.Option>
            ))}
          </Select>
        </label>
      )}
      {discounts && (
        <label className="flex flex-1 flex-col">
          Chegirmalar
          <Select
            className="customSelect"
            onClear={() => {
              dispatch(
                changeDiscount({
                  amount: 0,
                  changeRepairedStatus: false
                })
              );
              dispatch(setTermDIscount(undefined));

              dispatch(setTotalAmount({ amount: changePriceTotal, constAmount: constTotalAmount || totalAmount }));

              if (variation?.prepaymentPercent) {
                dispatch(
                  setOptionsContract({
                    delay,
                    prePayment: (changePriceTotal / 100) * variation?.prepaymentPercent,
                    useDiscount: false,
                    notDiscount: true
                  })
                );
              }

              if (variation?.mortgagePercent) {
                dispatch(
                  setVariation({
                    ...variation!,
                    ...(variation?.mortgagePercent && {
                      mortgagePrice: roundFloatNumber(variation?.mortgagePercent * (changePriceTotal / 100))
                      // mortgagePaymentDate: variation?.mortgagePaymentDate ? variation?.mortgagePaymentDate : dayjs().format(DATE_FORMAT)
                    })
                  })
                );
              }
            }}
            value={termDiscount?.id}
            onChange={(value, option) => {
              const data = (option as any).data as ITermDiscount;
              // console.log(data);

              const discount = roundFloatNumber((changePriceTotal / 100) * data?.percentage);

              const amount = changePriceTotal - discount;

              dispatch(setTotalAmount({ amount, constAmount: constTotalAmount || totalAmount }));
              if (variation?.prepaymentPercent) {
                dispatch(
                  setOptionsContract({
                    delay,
                    prePayment: (amount / 100) * variation?.prepaymentPercent,
                    useDiscount: false,
                    notDiscount: true
                  })
                );
              }

              if (variation?.mortgagePercent) {
                dispatch(
                  setVariation({
                    ...variation!,
                    ...(variation?.mortgagePercent && {
                      mortgagePrice: roundFloatNumber(variation?.mortgagePercent * (amount / 100))
                      // mortgagePaymentDate: variation?.mortgagePaymentDate ? variation?.mortgagePaymentDate : dayjs().format(DATE_FORMAT)
                    })
                  })
                );
              }

              dispatch(setTermDIscount(data));

              dispatch(
                changeDiscount({
                  amount: discount,
                  changeRepairedStatus: false
                })
              );
              // if (!discountText) {
              dispatch(setDiscountText(`${data?.name} ${data?.percentage}% chegirma qo'llanildi`));
              // }
            }}
            allowClear
          >
            {discounts?.map(item => (
              <Select.Option key={item?.id} data={item} value={item?.id}>
                {item?.name} {item?.percentage}%
              </Select.Option>
            ))}
          </Select>
        </label>
      )}
    </div>
  );
};

export default MultiPaymentSelect;
