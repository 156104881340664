const units: Record<string, number> = {
  mln: 1e6,
  mlrd: 1e9,
  trln: 1e12,
  million: 1e6,
  billion: 1e9,
  trillion: 1e12,
  тыс: 1e3,
  миллион: 1e6,
  миллиард: 1e9,
  миллионов: 1e6,
  миллиардов: 1e9,
  миллиарда: 1e9,
  млн: 1e6,
  млрд: 1e9
};

export const textToNumber = (text: string) => {
  let cleanText = text.replace(/[^\d\w\s.,]/g, '').toLowerCase();

  cleanText = cleanText.replace(/\s+/g, ' ').replace(/,/g, '');

  const parts = cleanText.split(' ');
  let number = 0;

  parts.forEach(part => {
    if (!Number.isNaN(+part)) {
      number = parseFloat(part);
    } else if (units[part]) {
      number *= units[part];
    }
  });

  return number || null;
};
