import { FC } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';
import { Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useQueryParam } from '../../hooks/useQueryParams';
import { FlatStatus } from '../../utils/models/FlatStatus';

import CalculatorCont from './components/calculator/CalculatorCont';
import Flats from './components/flats/Flats';
import MultiPaymentSelect from './components/multi-payment-select/MultiPaymentSelect';
import PriceInformation from './components/priceInformation/PriceInformation';
import SelectCurrencyCompany from './components/selectCurrency/SelectCurrencyCompany';
import { useFlatMore } from './services/queries';

import styles from './homeId.module.scss';

const { Content } = Layout;

interface IHomeIdProps {
  idFlats?: number[];
  houseName?: string;
}

const HomeId: FC<IHomeIdProps> = ({ idFlats, houseName }) => {
  const { params } = useQueryParam<{ idFlat: string }, null>();
  const { isLoading, data } = useFlatMore(idFlats || params?.idFlat?.split('=')[1]?.split(',') || []);
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const flat = data && data[0];
  const { t } = useTranslation();

  // showCalculator
  const showCalculator = () => {
    if (data && data.length > 1) {
      return true;
    }
    return !!(data && data[0].flat.status === FlatStatus.SALE);
  };

  // getClassNameStatusInfo
  const getClassNameStatusInfo = () => {
    if (data) {
      if (data[0].flat.status === FlatStatus.SOLD) return `${styles.home_id_status_title} ${styles.home_id_SOLD}`;
      return `${styles.home_id_status_title} ${styles.home_id_BOOKED}`;
    }
    return '';
  };

  const getNameOfStatus = () => {
    if (data) {
      return t(`home.${data[0].flat?.status}`);
    }
    return '';
  };

  return (
    <>
      {!idFlats && (
        <MainHeader>
          <BreadcrumbHeader />
          <div className="d-flex align-center">
            <OnlinePbxNotifications />
            <NotificationButtons />
          </div>
        </MainHeader>
      )}
      {idFlats ? (
        <Spin spinning={isLoading}>
          <div className={styles.home_id}>
            <h1 style={{ paddingRight: 32 }}>
              <span>{t('home_id.Xonadon_malumotlari')}</span> <SelectCurrencyCompany />
            </h1>
            <Flats />
            <div className={styles.home_id_content}>
              {isMultiPayment && <MultiPaymentSelect />}
              <PriceInformation data={data} />
              {(showCalculator() || flat?.flat?.status === 'BOOKED') && <CalculatorCont idFlats={idFlats} houseName={houseName} flat={flat} />}
            </div>
            {!showCalculator() && flat?.flat?.status !== 'BOOKED' && (
              <div className={getClassNameStatusInfo()}>
                <span>{getNameOfStatus()}</span>
              </div>
            )}
          </div>
        </Spin>
      ) : (
        <Content
          style={{
            padding: '1rem 2.68rem',
            overflowY: 'auto',
            height: '50vh'
          }}
        >
          <Spin spinning={isLoading}>
            <div className={styles.home_id}>
              <h1>
                <span>{t('home_id.Xonadon_malumotlari')}</span>
                <SelectCurrencyCompany />
              </h1>
              <Flats />
              <div className={styles.home_id_content}>
                {isMultiPayment && <MultiPaymentSelect />}
                <PriceInformation data={data} />
                {(showCalculator() || flat?.flat?.status === 'BOOKED') && <CalculatorCont idFlats={idFlats} houseName={houseName} flat={flat} />}
              </div>
              {!showCalculator() && flat?.flat?.status !== 'BOOKED' && (
                <div className={getClassNameStatusInfo()}>
                  <span>{getNameOfStatus()}</span>
                </div>
              )}
            </div>
          </Spin>
        </Content>
      )}
    </>
  );
};

export default HomeId;
