import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { useMutation } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { IExpoContact } from '../utils/models/IExpoContact';

export function useExpoContact() {
  return useMutation(
    async (token: string) => {
      const res = await $api.get(endPoints.EXPO_CONTACT_TOKEN(token));
      return res.data.data as IExpoContact;
    },
    {
      onError: baseErrorHandler
    }
  );
}
