import React from 'react';
import styles from './createPayment.module.scss';
import { Button, Modal, Spin, Table } from 'antd';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useContractMonthlyPayment } from '../../queries/queries';
import { MonthlyPaymentStatus } from '../../utils/models/CreateContract';
import { useTranslation } from 'react-i18next';

interface Props {
  close: () => void;
  visible: boolean;
}

const ArchiveTableModal: React.FC<Props> = ({ visible, close }) => {
  const { params } = useQueryParam();
  const { t } = useTranslation();
  const { data, isLoading } = useContractMonthlyPayment(params.id, MonthlyPaymentStatus.ARCHIVE);
  const handleClose = () => {
    close();
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      width: 100,
      render: (i: any, a: any, b: any) => <>{b + 1}</>
    },
    {
      title: t('create_payment.To’lov_qilingan_sana'),
      dataIndex: 'date'
    },
    {
      title: t("create_payment.To'lov_summasi"),
      dataIndex: 'amount',
      render: (i: number) => <>{i?.toLocaleString('ru')}</>
    },
    {
      title: t("create_payment.To'langan_summa"),
      dataIndex: 'payment',
      render: (i: number, a: any) => <>{i === 0 ? '---' : <span style={{ color: a.paid ? '#00A389' : '#FFBB54' }}>{i?.toLocaleString('ru')}</span>}</>
    }
  ];

  return (
    <Modal
      title={t("create_payment.To'lov_grafigi_tarixi")}
      className={styles.paymentArchiveModal}
      visible={visible}
      width={865}
      onCancel={handleClose}
      footer={[
        <Button onClick={handleClose} type={'primary'} className={styles.cancel_button}>
          {t('create_payment.Yopish')}
        </Button>
      ]}
    >
      <Spin spinning={isLoading}>
        {data?.map((table, index) => (
          <>
            <div className={styles.body_date_cont}>
              <span>{t("create_payment.To'lov_grafigi_yangilangan_sana")}:</span>
              <span>{table.cancelledDate}</span>
            </div>
            <Table
              key={index}
              loading={isLoading}
              className="custom_table contract_table pagination_MCSS"
              columns={columns}
              dataSource={table.monthlyPaymentPartDtos}
              size="small"
              pagination={false}
            />
          </>
        ))}
      </Spin>
    </Modal>
  );
};

export default ArchiveTableModal;
