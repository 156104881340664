import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppSelector } from '@hooks/reduxHooks';
import CheckBoxIcon from '@icons/CheckBoxIcon';
import { CrmUserRoleModel } from '@pages/crm/utils/models/CrmUserRoleModel';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { DATE_FORMAT } from '@utils/constants/format';
import { encodeString } from '@utils/helper';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';
import dayjs from 'dayjs';
import { DraggableProvided } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import CrmCloseIcon from '../../../../assets/icons/CrmCloseIcon';
import CrmSuccessIcon from '../../../../assets/icons/CrmSuccessIcon';
import { useDeleteLead, useLeadChangeStep } from '../../../../queries/mutation';
import { CrmLeadV2 } from '../../../../utils/models/CrmLeadList';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../utils/models/GoogleAnalitks';
import { PipeStatuses } from '../../../../utils/models/PipeAll';

import facebook from '../../../../assets/img/Facebook.png';
import instagram from '../../../../assets/img/instagram.png';
import telegram from '../../../../assets/img/telegram.png';

import styles from './voronkaCards.module.scss';

interface IProps {
  provided: DraggableProvided;
  className: string;
  isDragging: boolean;
  item: CrmLeadV2;
  index: number;
  incomingClassName: string;
  columnId?: number;
  columnSecondId?: number;
  column?: PipeStatuses;
  currentUserRoleData?: CrmUserRoleModel | undefined;
}

const imgObj: any = {
  INSTAGRAM: instagram,
  FACEBOOK: facebook,
  TELEGRAM: telegram
};

const VoronkaCardItem: FC<IProps> = ({ provided, className, isDragging, item, index, incomingClassName, columnSecondId, column, currentUserRoleData, columnId }) => {
  const leadChangeStep = useLeadChangeStep();
  const { t } = useTranslation();
  const deleteLead = useDeleteLead();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = useAppSelector(state => state.userReducer.user?.id);
  const dispatch = useDispatch();
  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);
  const columnIds = useAppSelector(state => state.assigmentSlice.listColumnIds);
  const activeBalance = useAppSelector(state => state.assigmentSlice.balance);

  // click or visit one lead
  const clickOrVisitEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CLICK_OR_VISIT_ONE_LEAD
    });
  };

  // change step
  const changeStepEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CHANGE_STEP_LEAD_ON_BOARD
    });
  };


  const handleLead = () => {
    const encodedName = encodeString(item.name);

    if (index === 0) {
      navigate(`${location.pathname}/${item.id}/${encodedName}?incoming=true&task=${!!item?.taskFinishDate}&prev=true`);
    } else {
      navigate(`${location.pathname}/${item.id}/${encodedName}?prev=true&task=${!!item?.taskFinishDate}`);
    }
  };

  const handleAcceptandle = () => {
    changeStepEvent();
    if (!(deleteLead.isLoading || leadChangeStep.isLoading)) {
      leadChangeStep.mutate({
        leadId: item.id,
        updateTimeDate: dayjs().format('DD.MM.YYYY HH:mm:ss.SSS'),
        statusId: columnSecondId!,
        newLead: true
      });
    }
  };

  const handleDelete = () => {
    if (!(deleteLead.isLoading || leadChangeStep.isLoading)) {
      deleteLead.mutate(item.id);
    }
  };

  const tagElements = () => {
    const arr = item?.tags?.filter((item, index) => (index <= 1 && item?.length < 15) || index === 0);

    return {
      arr: arr?.map(item => item?.slice(0, 15) + (item?.length > 15 ? '...' : '')),
      over: item?.tags?.slice(arr?.length).length
    };
  };

  const shart =
    currentUserRoleData?.leadSave === 'DENY' || currentUserRoleData?.leadDelete === 'DENY' || currentUserRoleData?.leadSave === 'OWN' || currentUserRoleData?.leadDelete === 'OWN';

  const shart1 = currentUserRoleData?.leadSave === 'DENY' || currentUserRoleData?.leadDelete === 'DENY';

  const handleSelectOneRow = (record: any) => {
    const ids = [...activeIds];
    let balance = activeBalance;

    const newColumnIds = [...columnIds];

    if (ids.find(id => id === record.id)) {
      balance = roundFloatNumber(balance! - record.balance);
      ids.splice(ids.indexOf(record.id), 1);
    } else {
      ids.push(record.id);
      balance = roundFloatNumber(balance + record.balance);
    }

    newColumnIds.push(columnId!);
    dispatch(
      setBalanceAndIds({
        ids,
        balance
      })
    );
  };

  return (
    <div
      onClick={() => {
        clickOrVisitEvent();
      }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={className}
      style={{
        backgroundColor: isDragging ? 'rgba(255, 255, 255, 0.5)' : '#FFFFFF',
        border: activeIds?.includes(item?.id) ? '1px solid var(--royal-blue)' : '',
        ...provided.draggableProps.style
      }}
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {column?.type === 'FIRST' && currentUserRoleData && activeIds.length === 0 && (
        <div
          className={incomingClassName}
          style={{
            height: shart ? 'auto' : '100%',
            top: shart ? '50%' : '0',
            transform: shart ? 'translateY(-50%)' : 'translateY(0)',
            padding: shart ? '8px 0' : '',
            display: shart1 ? 'flex' : '',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {(currentUserRoleData?.leadSave === 'ALLOW' ||
            (currentUserRoleData?.leadSave === 'OWN' && (Number(userId) === Number(item?.responsibleById) || item?.responsibleById === null))) && (
              <span
                onClick={handleAcceptandle}
                style={{
                  borderBottom: shart ? 'none' : '',
                  paddingTop: shart ? '4px' : ''
                }}
              >
                {leadChangeStep.isLoading ? <LoadingOutlined /> : <CrmSuccessIcon />} {t('crm.Qabul_qilish')}
              </span>
            )}
          {(currentUserRoleData?.leadDelete === 'ALLOW' ||
            (currentUserRoleData?.leadDelete === 'OWN' && (Number(userId) === Number(item?.responsibleById) || item?.responsibleById === null))) && (
              <span
                onClick={handleDelete}
                style={{
                  borderBottom: shart ? 'none' : '',
                  paddingTop: shart ? '4px' : ''
                }}
              >
                {deleteLead.isLoading ? <LoadingOutlined /> : <CrmCloseIcon />}
                {t('crm.Bekor_qilish')}
              </span>
            )}
        </div>
      )}
      {column?.type === 'FIRST' && currentUserRoleData === null && activeIds.length === 0 && (
        <div className={incomingClassName}>
          <span onClick={handleAcceptandle}>
            {leadChangeStep.isLoading ? <LoadingOutlined /> : <CrmSuccessIcon />} {t('crm.Qabul_qilish')}
          </span>
          <span onClick={handleDelete}>
            {deleteLead.isLoading ? <LoadingOutlined /> : <CrmCloseIcon />}
            {t('crm.Bekor_qilish')}
          </span>
        </div>
      )}

      <div>
        <div>
          {column?.type === 'FIRST' && item.contactType && imgObj[item.contactType] && <img src={imgObj[item.contactType]} width={15} height={14} alt="img" />}
          <h1>{item?.contactName}</h1>
        </div>
        <div>
          <p>{item?.createdDate}</p>
        </div>
      </div>

      <div style={{ cursor: 'pointer' }} onClick={handleLead}>
        <div>
          <h2>{item?.name}</h2>
        </div>
      </div>

      <div>
        <div>
          {item?.balance > 0 && <h1>{item?.balance?.toLocaleString()}</h1>}
          {/* {item.tags.length >= 3 ? (
            <>
              {item.tags.slice(0, 2).map((item) => (
                <span key={item.id}>{item.name}</span>
              ))}{' '}
              <span>+{item.tags.length - 2}</span>
            </>
          ) : (
            item.tags.map((item) => <span key={item.id}>{item.name}</span>)
          )} */}
          {tagElements()?.arr?.map(item => <span key={item}>{item}</span>)}
          {tagElements()?.over ? <span>+{tagElements()?.over}</span> : ''}
        </div>
        <div style={{ opacity: activeIds?.includes(item?.id) ? 0 : 1 }} className={styles.leadDate}>
          {item?.taskFinishDate ? (
            <h3 style={{ color: '#E53535' }}>
              {' '}
              {/* eslint-disable-next-line no-nested-ternary */}
              {dayjs().format(DATE_FORMAT) === dayjs(item.taskFinishDate, DATE_FORMAT).format(DATE_FORMAT) ? (
                <span style={{ color: '#00A389' }}>{t('crm.Bugun')} •</span>
              ) : Math.ceil((dayjs(item.taskFinishDate, DATE_FORMAT).unix() - dayjs(new Date()).unix()) / (60 * 60 * 24)) < 0 ? (
                <span>
                  {Math.ceil((dayjs(item.taskFinishDate, DATE_FORMAT).unix() - dayjs(new Date()).unix()) / (60 * 60 * 24)) * -1} {t('crm.kun')}
                </span>
              ) : (
                <span style={{ color: '#00A389' }}>
                  {Math.ceil((dayjs(item.taskFinishDate, DATE_FORMAT).unix() - dayjs(new Date()).unix()) / (60 * 60 * 24))} {t('crm.kun')}
                </span>
              )}
            </h3>
          ) : (
            <h3 style={{ color: '#FFB400' }}>{t('crm.Topshiriq_yoq')} •</h3>
          )}
        </div>
      </div>
      {/* eslint-disable-next-line no-nested-ternary, no-nested-ternary, no-nested-ternary */}
      {column?.type !== 'FIRST' ? (
        <div
          onClick={() => handleSelectOneRow(item)}
          className={styles.voronkaDot}
          style={{
            border: activeIds?.includes(item?.id) ? '1px solid var(--royal-blue)' : '',
            opacity: activeIds.includes(item?.id) ? 1 : 0,
            backgroundColor: activeIds.includes(item?.id) ? 'var(--royal-blue)' : ''
          }}
        >
          <CheckBoxIcon />
        </div>
      ) : column?.type === 'FIRST' && activeIds.length > 0 ? (
        <div
          onClick={() => handleSelectOneRow(item)}
          className={styles.voronkaDot}
          style={{
            border: activeIds?.includes(item?.id) ? '1px solid var(--royal-blue)' : '',
            opacity: activeIds.includes(item?.id) ? 1 : 0,
            backgroundColor: activeIds.includes(item?.id) ? 'var(--royal-blue)' : ''
          }}
        >
          <CheckBoxIcon />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default VoronkaCardItem;
