import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import CardRemove from '../../../../assets/icons/CardRemove';
import MiniMenuIcon from '../../../../assets/icons/MiniMenuIcon';
import SuccessIconCreateOrder from '../../../../assets/icons/SuccessIconCreateOrder';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { setSelectedClient } from '../../../../store/reducers/ChangesSlice';

import styles from './client.module.scss';

interface Props {
  name: string;
  phone: string;
  id: number;
}

const ActiveClient: React.FC<Props> = ({ name, phone }) => {
  const { removeAllSearchParams } = useQueryParam();
  const [openDropDown, setOpenDropDown] = useState(false);

  const dispatch = useAppDispatch();
  const handleOpenDropDown = (visible: boolean) => {
    setOpenDropDown(visible);
  };

  const clearClient = () => {
    dispatch(
      setSelectedClient({
        data: null,
        phones: [
          {
            phone: '',
            active: true,
            code: ''
          }
        ]
      })
    );
    removeAllSearchParams();
  };
  const { t } = useTranslation();

  return (
    <div onClick={clearClient} className={styles.active_client}>
      <SuccessIconCreateOrder className={styles.active_client_check} />
      <Dropdown
        visible={openDropDown}
        onVisibleChange={visible => {
          handleOpenDropDown(visible);
        }}
        overlayClassName={styles.client_dropdown}
        overlay={
          <>
            <p>{t(`changes.client.Active_client.Amallar`)}</p>
            <div
              onClick={() => {
                console.log('click delete');
              }}
            >
              <CardRemove />
              <span>{t(`changes.client.Active_client.O'chirish`)}</span>
            </div>
          </>
        }
        trigger={['click']}
      >
        <MiniMenuIcon
          onClick={e => {
            e.stopPropagation();
            handleOpenDropDown(true);
          }}
          className={styles.active_client_trash}
        />
      </Dropdown>
      <div>
        <label>{t(`changes.client.FindClientItem.Mijoz`)}</label>
        <span>{name}</span>
      </div>
      <div>
        <label>{t(`changes.client.FindClientItem.Telefon`)}</label>
        <span>{phone}</span>
      </div>
    </div>
  );
};

export default ActiveClient;
