import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CLoseAllIcon from '../../../../../assets/icons/CLoseAllIcon';
import styles from './leadTaskCompleteModal.module.scss';

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  success: (reason: null | undefined | number) => void;
  handleCloseReason?: () => void;
  value?: null | undefined | number;
}

const LeadTaskCompleteModalOneLead: FC<IProps> = ({ close, success, visible, handleCloseReason, value }) => {
  const { t } = useTranslation();
  // handle close
  const handleClose = () => {
    close(false);
  };

  // handle save
  const handleSave = () => {
    success(value || null);
    handleClose();
    if (handleCloseReason) {
      handleCloseReason();
    }
  };

  return (
    <Modal
      title={false}
      visible={visible}
      onCancel={handleClose}
      footer={false}
      centered
      closable={false}
      width={546}
      wrapClassName={styles.container}
    >
      <div className={styles.container_title}>
        <CLoseAllIcon />
      </div>
      <p className={styles.container_content}>{t('one_lid.Yopiq_lidga_biriktirilgan_vazifalar')}</p>
      <div className={styles.container_footer}>
        <Button onClick={handleClose}>{t('crm.Yopish')}</Button>
        <Button type="primary" onClick={handleSave}>
          {t('one_lid.Tasdiqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default LeadTaskCompleteModalOneLead;
