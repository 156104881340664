import React from 'react';

const DashboardVidjet3 = () => {
  return (
    <svg width="88" height="111" viewBox="0 0 88 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 12C0.75 5.64873 5.89873 0.5 12.25 0.5H75.75C82.1013 0.5 87.25 5.64873 87.25 12V69C87.25 91.9198 68.6698 110.5 45.75 110.5H12.25C5.89872 110.5 0.75 105.351 0.75 99V12Z"
        stroke="#00A389"
      />
      <path
        d="M6.25 14C6.25 9.58172 9.83172 6 14.25 6H73.75C78.1683 6 81.75 9.58172 81.75 14V65C81.75 87.0914 63.8414 105 41.75 105H14.25C9.83172 105 6.25 101.418 6.25 97V14Z"
        fill="#E7F9F6"
      />
      <path d="M22.25 26H65.75" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
      <path
        d="M26 42.5C25.7239 42.5 25.5 42.7239 25.5 43C25.5 43.2761 25.7239 43.5 26 43.5V42.5ZM52.3333 43C52.3333 44.4728 53.5272 45.6667 55 45.6667C56.4728 45.6667 57.6667 44.4728 57.6667 43C57.6667 41.5272 56.4728 40.3333 55 40.3333C53.5272 40.3333 52.3333 41.5272 52.3333 43ZM26 43.5H55V42.5H26V43.5Z"
        fill="#00A389"
      />
      <path
        d="M26.25 49C25.9739 49 25.75 49.2239 25.75 49.5C25.75 49.7761 25.9739 50 26.25 50V49ZM44.5833 49.5C44.5833 50.9728 45.7772 52.1667 47.25 52.1667C48.7228 52.1667 49.9167 50.9728 49.9167 49.5C49.9167 48.0272 48.7228 46.8333 47.25 46.8333C45.7772 46.8333 44.5833 48.0272 44.5833 49.5ZM26.25 50H47.25V49H26.25V50Z"
        fill="#00A389"
      />
      <path
        d="M26.25 61C25.9739 61 25.75 61.2239 25.75 61.5C25.75 61.7761 25.9739 62 26.25 62V61ZM36.5833 61.5C36.5833 62.9728 37.7772 64.1667 39.25 64.1667C40.7228 64.1667 41.9167 62.9728 41.9167 61.5C41.9167 60.0272 40.7228 58.8333 39.25 58.8333C37.7772 58.8333 36.5833 60.0272 36.5833 61.5ZM26.25 62H39.25V61H26.25V62Z"
        fill="#7BBFB4"
      />
      <path
        d="M26.25 67C25.9739 67 25.75 67.2239 25.75 67.5C25.75 67.7761 25.9739 68 26.25 68V67ZM33.5833 67.5C33.5833 68.9728 34.7772 70.1667 36.25 70.1667C37.7228 70.1667 38.9167 68.9728 38.9167 67.5C38.9167 66.0272 37.7228 64.8333 36.25 64.8333C34.7772 64.8333 33.5833 66.0272 33.5833 67.5ZM26.25 68H36.25V67H26.25V68Z"
        fill="#7BBFB4"
      />
      <path
        d="M26.25 55C25.9739 55 25.75 55.2239 25.75 55.5C25.75 55.7761 25.9739 56 26.25 56V55ZM49.5833 55.5C49.5833 56.9728 50.7772 58.1667 52.25 58.1667C53.7228 58.1667 54.9167 56.9728 54.9167 55.5C54.9167 54.0272 53.7228 52.8333 52.25 52.8333C50.7772 52.8333 49.5833 54.0272 49.5833 55.5ZM26.25 56H52.25V55H26.25V56Z"
        fill="#00A389"
      />
      <path d="M22.25 85H45.25" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
    </svg>
  );
};

export default DashboardVidjet3;
