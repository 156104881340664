import React, { useEffect } from 'react';
import { useGetEmbedWidget } from '@pages/oneLid/services/queries';
import { useCreateEmbedWidget } from '@queries/mutation';
import { Form, Input, Modal } from 'antd';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const tags = ['Uchrashuv vaqti', 'Lid nomi', 'Contact nomi', 'Menejer ismi'];

const EmbedModal: React.FC<IProps> = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const { data } = useGetEmbedWidget();
  const createEmbed = useCreateEmbedWidget();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, open]);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const onFinish = (val: { code: string; name: string }) => {
    createEmbed.mutateAsync({ ...val, active: true, id: data?.id }).then(() => handleClose());
  };

  const onOk = () => form.submit();

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      title="Embed"
      width={721}
      okText="Saqlash"
      cancelText="Yopish"
      okButtonProps={{ className: 'rounded-full w-[160px] h-[38px]', loading: createEmbed?.isLoading }}
      onOk={onOk}
      cancelButtonProps={{ className: 'rounded-full w-[160px] h-[38px] border-none' }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Nomi" name="name" rules={[{ required: true, message: '' }]}>
          <Input placeholder="Ob havo vidgeti" className="customInput h-8 w-[347px]" />
        </Form.Item>
        <Form.Item name="code" rules={[{ required: true, message: '' }]}>
          <Input.TextArea rows={3} placeholder="Ob havo vidgeti" className="customInput h-8" />
        </Form.Item>
      </Form>
      {/* <div className="flex items-center gap-2">
        {tags?.map(item => (
          <div className="cursor-pointer rounded-md bg-[#00A38926] px-3 py-2.5 text-[10px] text-[#00A389]" key={item}>
            {item}
          </div>
        ))}
      </div> */}
    </Modal>
  );
};

export default EmbedModal;
