import React from 'react';

const ArrowTopOutboundIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.20406 17.205L15.2812 7.12496H8.25C7.95163 7.12496 7.66548 7.00644 7.45451 6.79546C7.24353 6.58448 7.125 6.29833 7.125 5.99996C7.125 5.70159 7.24353 5.41545 7.45451 5.20447C7.66548 4.99349 7.95163 4.87496 8.25 4.87496H18C18.2984 4.87496 18.5845 4.99349 18.7955 5.20447C19.0065 5.41545 19.125 5.70159 19.125 5.99996V15.75C19.125 16.0483 19.0065 16.3345 18.7955 16.5455C18.5845 16.7564 18.2984 16.875 18 16.875C17.7016 16.875 17.4155 16.7564 17.2045 16.5455C16.9935 16.3345 16.875 16.0483 16.875 15.75V8.71871L6.79594 18.7959C6.69129 18.9005 6.56706 18.9836 6.43033 19.0402C6.2936 19.0968 6.14706 19.126 5.99906 19.126C5.85107 19.126 5.70452 19.0968 5.5678 19.0402C5.43107 18.9836 5.30684 18.9005 5.20219 18.7959C5.09754 18.6913 5.01453 18.567 4.9579 18.4303C4.90126 18.2936 4.87211 18.147 4.87211 17.999C4.87211 17.851 4.90126 17.7045 4.9579 17.5678C5.01453 17.431 5.09754 17.3068 5.20219 17.2021L5.20406 17.205Z"
        fill="#0061FF"
      />
    </svg>
  );
};

export default ArrowTopOutboundIcon;
