import React, { FC, useEffect } from 'react';
import { Form, Button, Switch, Select, Input } from 'antd';
import styles from './reports.module.scss';
import TrushSquareIcon from '../../../assets/icons/TrushSquareIcon';
import { PaymentPeriod, ReportType } from '../../../utils/models/ReportType';
import { Reports } from '../../../utils/models/Reports';
import { useReportConfig } from '../../../queries/mutation';
import { useTranslation } from 'react-i18next';

interface IProps {
  onCancle: () => void;
  data: Reports | undefined;
  type: ReportType;
  visible: boolean;
}

const XLSReport: FC<IProps> = ({ onCancle, data, type }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const reportConfig = useReportConfig();
  const onFinish = (values: any) => {
    const telegramId = values.telegramId?.filter((item: string) => item);
    if (data?.id) {
      reportConfig.mutate({
        status: values.status,
        period: values.period,
        ...(telegramId && telegramId?.length > 0 && { telegramId: telegramId }),
        type: type,
        id: data?.id
      });
    } else {
      reportConfig.mutate({
        status: values.status,
        period: values.period,
        ...(telegramId && telegramId?.length > 0 && { telegramId: telegramId }),
        type: type
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      status: data?.status,
      telegramId: data?.telegramId ? data?.telegramId : [''],
      period: data?.period
    });
  }, [data, form]);

  return (
    <div className={styles.sms}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label={t('integration.XLS_hisobot_yuborish')} name={'status'} className={styles.sms_flex} valuePropName="checked">
          <Switch size="small" />
        </Form.Item>
        <Form.Item name={'period'} className={styles.sms_flex}>
          <Select className="customSelect">
            <Select.Option value={PaymentPeriod.DAY}>{t('boss_payments.Kunlik')}</Select.Option>
            <Select.Option value={PaymentPeriod.WEEK}>{t('boss_payments.Haftalik')}</Select.Option>
            <Select.Option value={PaymentPeriod.MONTH}>{t('boss_payments.Oylik')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.List name="telegramId" initialValue={['']}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item label={index === 0 ? 'Telegram ID' : ''} required={false} key={field.key} className={styles.list_item}>
                  <Form.Item {...field} validateTrigger={['onChange', 'onBlur']} noStyle>
                    <Input className="customInput" type="number" placeholder="" />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <span onClick={() => remove(field.name)} className={styles.list_item_delete}>
                      <TrushSquareIcon size={16} strokeWidth={1.5} />
                    </span>
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()}>
                  + {t('integration.Yana_qoshish')}
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className={styles.footer}>
          <Button onClick={onCancle}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button type="primary" htmlType="submit" loading={reportConfig.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default XLSReport;
