import React, { FC } from 'react';
import styles from './roomNumber.module.scss';

interface RoomNumberProps {
  defaultValue: string[];
  numbersArr: number[];
  onChangeSlider: (defaultValue: string[]) => void;
}

const RoomNumber: FC<RoomNumberProps> = ({ defaultValue, numbersArr, onChangeSlider }) => {
  return (
    <div className={styles.roomNumber}>
      {numbersArr.map(i => (
        <span
          key={i}
          onClick={() => {
            if (defaultValue.includes(`${i}`)) {
              onChangeSlider(defaultValue.filter(item => item !== `${i}`));
            } else {
              onChangeSlider([...defaultValue, `${i}`]);
            }
          }}
          className={defaultValue.includes(`${i}`) ? styles.active : ''}
        >
          {i}
        </span>
      ))}
    </div>
  );
};

export default RoomNumber;
