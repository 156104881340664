import { Button, Form, Input, Modal, Select } from 'antd';
import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useFlatDelete } from '@queries/mutation';
import { useTranslation } from 'react-i18next';
import { handleCreateEntrance } from './helper/handkeCreateEntrance';

interface CreateEntranceModalProps {
  data: {
    visible: boolean;
    title: string;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      title: string;
    }>
  >;
  setSaveAll: (save: boolean) => void;
}

const CreateEntranceModal: FC<CreateEntranceModalProps> = ({ setVisible, data, setSaveAll }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const shaxmatka = useAppSelector(state => state.shaxmatkaReducer.data);
  const shaxmatkaDelete = useAppSelector(state => state.shaxmatkaReducer.dataDelete);

  const { t } = useTranslation();

  const handleCancel = () => {
    setVisible({ visible: false, title: '' });
    form.resetFields();
  };

  const flatDelete = useFlatDelete();

  const onFinish = (values: any) => {
    const props = {
      shaxmatka,
      values,
      data,
      flatDelete,
      dispatch,
      handleCancel,
      setSaveAll,
      shaxmatkaDelete
    };
    handleCreateEntrance(props);
  };

  return (
    <Modal
      title={data.title === "qo'shish" ? t('shaxmatka.Kirish_eshigi_qoshish') : t('shaxmatka.Kirish_eshigi_ochirish')}
      wrapClassName="entranceDoorModal"
      visible={data.visible}
      onCancel={handleCancel}
      footer={false}
      width={407}
    >
      <Form layout="horizontal" onFinish={onFinish} form={form}>
        {data.title === "qo'shish" ? (
          <>
            <Form.Item label={t('shaxmatka.Qaysi_eshigdan_keyin')} name={'entrances'} rules={[{ required: true }]}>
              <Input type={'number'} />
            </Form.Item>
            <Form.Item label={t('shaxmatka.Kirish_eshigi_soni')} name={'entranceNumber'} rules={[{ required: true }]}>
              <Input type={'number'} />
            </Form.Item>
            <Form.Item label={t('shaxmatka.Qavatlar_soni')} name={'floorNumber'} rules={[{ required: true }]}>
              <Input type={'number'} />
            </Form.Item>
            <Form.Item label={t('shaxmatka.Qavatdagi_xonadonlar_soni')} name={'flatNumber'} rules={[{ required: true }]}>
              <Input type={'number'} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item name={'delete'} rules={[{ required: true }]}>
              <Select mode="multiple" style={{ width: '100%' }} optionLabelProp="label">
                {shaxmatka.map((item: any, index: number) => {
                  return (
                    <Select.Option key={index} value={index + 1} label={index + 1}>
                      {index + 1}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </>
        )}
        <div className="entranceDoorModalFooter">
          <Button onClick={handleCancel}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button type="primary" htmlType="submit">
            {t('shaxmatka.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateEntranceModal;
