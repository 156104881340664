import React, { ReactNode } from 'react';
import styles from './showroomV2Layout.module.scss';
import ShowroomV2Drawer from '../showroom-v2-drawer/ShowroomV2Drawer';

interface IProps {
  children: ReactNode;
}

const ShowroomV2Layout = ({ children }: IProps) => {
  return (
    <div className={styles.container}>
      {children} <ShowroomV2Drawer />
    </div>
  );
};

export default ShowroomV2Layout;
