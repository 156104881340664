import React, { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { ResponsivePie } from '@nivo/pie';
import { DATE_FORMAT } from '@utils/constants/format';
import { colors } from '@utils/constants/showRoomColors';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useGetStatSales } from './services/queries';

import styles from './bossSaleByRegion.module.scss';

const BossSaleByRegion = () => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<any, any>();
  const monthStart = dayjs().startOf('M').format(DATE_FORMAT);
  const monthEnd = dayjs().format(DATE_FORMAT);

  const { data, isLoading } = useGetStatSales({
    countryId: 181,
    finishDate: searchParams?.finish ? searchParams?.finish : monthEnd,
    startDate:
      // eslint-disable-next-line no-nested-ternary
      searchParams?.type === 'DAY'
        ? searchParams?.start
        : // eslint-disable-next-line no-nested-ternary
          searchParams?.type === 'MONTH'
          ? dayjs(searchParams?.start, DATE_FORMAT).startOf('year')?.format(DATE_FORMAT)
          : searchParams?.type === 'YEAR'
            ? searchParams?.start
            : monthStart
  });

  const [cityId, setCityId] = useState<number | undefined>();

  useEffect(() => {
    if (data && data[0]) {
      setCityId(data[0]?.city?.id);
    }
  }, [data]);

  const newData = data
    ?.map((item, index) => ({
      id: item?.city?.uz,
      label: item?.city?.uz,
      value: item?.count,
      color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)],
      cityId: item?.city?.id
    }))
    ?.sort((a, b) => b?.value - a.value);

  const districtData = data
    ?.find(item => item?.city?.id === cityId)
    ?.child?.map((prev, index) => ({
      id: prev?.district?.uz,
      label: prev?.district?.uz,
      value: prev?.count,
      color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)]
    }))
    ?.sort((a, b) => b?.value - a.value);

  const calcTotalValue = (arr: any[]) => {
    const totalValue = arr?.reduce((total, current) => total + current?.value, 0);

    return totalValue;
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.card_right}>
          {newData?.length! > 0 ? (
            <>
              <ResponsivePie
                data={newData || []}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={0.7}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                enableArcLinkLabels={false}
                colors={{ datum: 'data.color' }}
                onClick={e => setCityId(e?.data?.cityId)}
                enableArcLabels={false}
              />
              <div className={styles.layout}>
                <h1>{calcTotalValue(newData!)}</h1>
                <p>{t('titles.Sotuv')}</p>
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
        {newData?.length! > 0 && (
          <div className={styles.card_left}>
            {newData?.map(item => (
              <div
                key={item?.cityId}
                className={styles.card_left_item}
                style={{
                  backgroundColor: item?.cityId === cityId ? '#F8F8F8' : ''
                }}
                onClick={() => setCityId(item?.cityId)}
              >
                <div className={styles.card_left_item_circle}>
                  <div
                    style={{
                      borderColor: item?.color
                    }}
                  />
                  <p>{item?.label}</p>
                </div>
                <span>{item?.value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.card}>
        <div className={styles.card_right}>
          {districtData?.length! > 0 ? (
            <>
              <ResponsivePie
                data={districtData || []}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                innerRadius={0.5}
                padAngle={0.7}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                enableArcLinkLabels={false}
                colors={{ datum: 'data.color' }}
                enableArcLabels={false}
              />
              <div className={styles.layout}>
                <h1>{calcTotalValue(districtData!)}</h1>
                <p>{t('titles.Sotuv')}</p>
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
        {districtData?.length! > 0 && (
          <div className={styles.card_left}>
            {districtData?.map(item => (
              <div className={styles.card_right_item} key={item?.id}>
                <p>{item?.label}</p>
                <span>{item?.value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BossSaleByRegion;
