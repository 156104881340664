/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmployeeIcon from '@icons/EmployeeIcon';
import SystemIcon from '@icons/SystemIcon';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { MainPaths } from '../../../routing/main/mainPaths';
import { rootPaths } from '../../../routing/rootPaths';
import { BASE_URL } from '../../../service/RequestService';
import { ACTION_EVENTS_GA, TYPE_EVENTS_GA } from '../../../utils/models/GoogleAnalitks';
import { NotificationMe } from '../../../utils/models/NotificationMe';

import CheckReadMSGIcon from './assets/icons/CheckReadMSGIcon';
import { useClearNotification } from './service/mutation';

import styles from './notificationCard.module.scss';

interface IProps extends NotificationMe {
  setOpenNotification: (e: boolean) => void;
  setActiveId: React.Dispatch<React.SetStateAction<number>>;
  activeId: number;
  id?: number | undefined;
  notifType: 'ALL' | 'CONTRACT' | 'UNREAD' | 'ACTIVE_SESSION_SECURITY' | undefined;
}

const NotificationCard: FC<IProps> = props => {
  const [fullDescription, setFullDescription] = useState(false);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const clearNotification = useClearNotification(props?.notifType);

  const handleClearNotification = (id?: number) => {
    if (id) {
      clearNotification.mutate(id);
    }
  };

  // go to one task events-
  const goToOneTaskEvent = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.TASK,
      action: ACTION_EVENTS_GA.GO_TO_ONE_TASK
    });
  };

  const handleDescription = () => {
    goToOneTaskEvent();
  };

  const handleFullDescription = () => {
    setFullDescription(true);
  };

  const deviceDescription = props.description ? props.description?.split('#') : '';

  const goToContract = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.CONTRACT}/${id}/${urlEncode(name)}`);
    props.setOpenNotification(false);
  };

  return (
    <div
      className={styles.notificationCard}
      onClick={() => {
        handleDescription();
        if (props?.number && props?.type === 'CONTRACT') {
          goToContract(props?.objectId!, props?.number!);
        }
      }}
    >
      {props?.type === 'ACTIVE_SESSION_SECURITY' ? (
        <SystemIcon />
      ) : (
        <div className={styles.image}>{props?.attachment ? <img alt="err" src={`${BASE_URL}/${props?.attachment}`} /> : <EmployeeIcon />}</div>
      )}

      <div className={styles.notificationCard_content}>
        <div className={styles.notificationCard_content_left}>
          <p>{props?.type === 'ACTIVE_SESSION_SECURITY' ? t('notification.Akkauntingizga_kirildi') : t(`home.${props?.title}`)}</p>
          {props?.type === 'ACTIVE_SESSION_SECURITY' ? (
            <div
              className={styles.notificationCard_content_left_bottom}
              onClick={() => {
                navigation(rootPaths.MAIN + MainPaths.PROFILE + SettingsPaths.SECURITY);
              }}
            >
              <h1>
                {`${t('notification.Sizning_Uysot_akkauntingizga') + t('notification.da')} ${deviceDescription[1]} ${t(
                  'notification.qurilmasidan_kirildi'
                )}`}
              </h1>
              <div style={{ marginTop: '-15px' }}>
                <div>
                  {props?.notifType === 'UNREAD' && (
                    <div className={styles.field}>
                      {props?.read === false ? <div className={styles.redBounce} /> : ''}
                      <button
                        className={styles.checkBtnMSG}
                        onClick={e => {
                          e.stopPropagation();
                          handleClearNotification(props?.id);
                        }}
                        style={{ marginBottom: '15px' }}
                      >
                        <CheckReadMSGIcon />
                      </button>
                    </div>
                  )}
                </div>
                <h3>
                  {dayjs().format('DD') === dayjs(props.date).format('MM')
                    ? `${t('notification.Bugun')} ${dayjs(props.date).format('HH:mm')}`
                    : props.date}
                </h3>
              </div>
            </div>
          ) : (
            <div className={styles.notificationCard_content_left_bottom}>
              <h1>
                {t('client_events.Shartnoma_yaratildi')}:{' '}
                {fullDescription && props.description.length < 255 ? (
                  props.description
                ) : (
                  <span>
                    {props.description.slice(0, 255)} <span onClick={handleFullDescription}>...</span>
                  </span>
                )}
              </h1>
              <div style={{ marginTop: '-30px' }}>
                <div className={styles.field}>
                  {props?.notifType === 'UNREAD' && (
                    <div>
                      {props?.read === false ? <div className={styles.redBounce} /> : ''}
                      <button
                        className={styles.checkBtnMSG}
                        onClick={e => {
                          e.stopPropagation();
                          handleClearNotification(props?.id);
                        }}
                        style={{ marginBottom: '15px' }}
                      >
                        <CheckReadMSGIcon />
                      </button>
                    </div>
                  )}
                </div>

                <h3>
                  {dayjs().format('DD') === dayjs(props.date).format('MM')
                    ? `${t('notification.Bugun')} ${dayjs(props.date).format('HH:mm')}`
                    : props.date}
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
