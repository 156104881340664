import { bossCrmPaths } from '@routing/bossCrm/bossCrmPaths';
import { BossPagePaths } from '@routing/bossPage/bossPagePaths';
import { rootPaths } from '@routing/rootPaths';
import { Permissions } from '@utils/constants/permissions';

export const statistikGetUrl = (el?: Permissions[]) => {
  const p = el?.map(item => String(item));

  let url = '';

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  Object.keys(permissionURL).forEach(item => {
    if (!url && p?.includes(item as any)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      url = permissionURL[item as keyof typeof permissionURL](p as any);
    }
  });
  return url;
};
export const permissionURL: {
  [key in string]: (permission?: Permissions[]) => string;
} = {
  [Permissions.PERMISSION_STATS_MAIN]: () => rootPaths.BOSS + BossPagePaths.HOME,
  [Permissions.PERMISSION_STATS_PAYMENT]: () => rootPaths.BOSS + BossPagePaths.PAYMENT,
  [Permissions.PERMISSION_STATS_DEBT]: () => rootPaths.BOSS + BossPagePaths.ARREARAGE,
  [Permissions.PERMISSION_STATS_HOUSE]: () => rootPaths.BOSS + BossPagePaths.COMPLEX,
  [Permissions.PERMISSION_STATS_CONTRACT]: () => rootPaths.BOSS + BossPagePaths.CONTRACT,
  [Permissions.PERMISSION_PAGE_CRM]: p => {
    let url = '';
    const crmPermissionsUrl = {
      [Permissions.PERMISSION_STATS_CRM_GENERAL]: rootPaths.BOSS + BossPagePaths.CRM + bossCrmPaths.COMMON,
      [Permissions.PERMISSION_STATS_CRM_CALLS]: rootPaths.BOSS + BossPagePaths.CRM + bossCrmPaths.CALLS,
      [Permissions.PERMISSION_STATS_CRM_TASKS]: rootPaths.BOSS + BossPagePaths.CRM + bossCrmPaths.TASKS,
      [Permissions.PERMISSION_STATS_CRM_LEADS]: rootPaths.BOSS + BossPagePaths.CRM + bossCrmPaths.LEADS
    };

    Object.keys(crmPermissionsUrl).forEach(item => {
      if (!url && p?.includes(item as unknown as keyof typeof crmPermissionsUrl)) {
        url = crmPermissionsUrl[item as unknown as keyof typeof crmPermissionsUrl];
      }
    });
    return url;
  },
  [Permissions.PERMISSION_STATS_SALE]: () => rootPaths.BOSS + BossPagePaths.SALE,
  [Permissions.PERMISSION_MACRO_ANALYTICS]: () => rootPaths.BOSS + BossPagePaths.BI_ANALYTICS
};
