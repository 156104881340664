import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CLoseAllIcon from '../../../../../assets/icons/CLoseAllIcon';
import { useLeadChangeStep } from '../../../../../queries/mutation';
import styles from './leadTaskCompleteModal.module.scss';

interface IProps {
  visible: boolean;
  leadId: number;
  statusId: number;
  reasonsId?: null | number;
  setLeadTaskCompleteModal: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  >;
  setReasonsRefusal?: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  >;
}

const LeadTaskCompleteModal: FC<IProps> = ({ visible, leadId, setLeadTaskCompleteModal, statusId, reasonsId, setReasonsRefusal }) => {
  const { t } = useTranslation();
  const leadChangeStep = useLeadChangeStep();

  const handleClose = () => {
    setLeadTaskCompleteModal({
      id: 0,
      visible: false,
      leadId: 0,
      statusId: 0
    });
    if (setReasonsRefusal) {
      setReasonsRefusal({
        id: 0,
        leadId: 0,
        statusId: 0,
        visible: false
      });
    }
  };

  // handleSubmit
  const handleSubmit = () => {
    leadChangeStep
      .mutateAsync({
        leadId: leadId,
        statusId: statusId,
        updateTimeDate: dayjs().format('DD.MM.YYYY HH:mm:ss.SSS'),
        autoClosedLeadTask: true,
        ...(reasonsId && { reasonsId: reasonsId })
      })
      .then(() => {
        handleClose();
      });
  };

  return (
    <Modal
      title={false}
      visible={visible}
      onCancel={handleClose}
      footer={false}
      centered
      closable={false}
      width={546}
      wrapClassName={styles.container}
    >
      <div className={styles.container_title}>
        <CLoseAllIcon />
      </div>
      <p className={styles.container_content}>{t('one_lid.Yopiq_lidga_biriktirilgan_vazifalar')}</p>
      <div className={styles.container_footer}>
        <Button onClick={handleClose}>{t('crm.Yopish')}</Button>
        <Button type="primary" onClick={handleSubmit} loading={leadChangeStep.isLoading}>
          {t('one_lid.Tasdiqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default LeadTaskCompleteModal;
