import React, { FC, useEffect, useMemo, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { CustomFields } from '@components/custom-fields/CustomFields';
import { useGetPaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/service/queries';
import { useAppSelector } from '@hooks/reduxHooks';
import DeleteIconV2 from '@icons/areaarage/DeleteIconV2';
import FileIcon from '@icons/FileIcon';
import UploadFileIcon from '@icons/UploadFileIcon';
import { usePaymentFieldGet } from '@pages/setting/payment-changes/services/queries';
import { Permissions } from '@utils/constants/permissions';
import { ContractId } from '@utils/models/ContractId';
import { FileObject } from '@utils/models/File';
import { PayType } from '@utils/models/PayType';
import { Button, Checkbox, Dropdown, Form, Input, Select, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';

import ArrowDownLinearIcon from '../../../../assets/icons/ArrowDownLinearIcon';
import CardAddIcon from '../../../../assets/icons/contract/CardAddIcon';
import TickCircleCrmIcon from '../../../../assets/icons/TickCircleCrmIcon';
import { useCreatePayment, useUploadFile } from '../../../../queries/mutation';
import { useClientBalanceSearch, useCurrencyList, usePaymentConfig, usePaymentNumber } from '../../../../queries/queries';
import DatePicker from '../../../../service/datePicker';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { calcCurrency } from '../../../../utils/helper/calcCurrency';
import { helper } from '../../../../utils/helper/helper';
import { CurrencyItem, CurrencyItemCopy } from '../../../../utils/models/Currency';
import { PaymentType } from '../../../../utils/models/PaymentType';
import { SearchClientBalance } from '../../../../utils/models/SearchClientBalance';

import styles from './paymentClient.module.scss';

interface FileNameProps {
  originalName: string;
}

interface IProps {
  contractId?: number;
  contractCurrency?: CurrencyItem;
  payType: PayType;
  contractIdData?: ContractId;
}

const { Option } = Select;

const PaymentClientBalance: FC<IProps> = ({ contractId, contractCurrency, payType, contractIdData }) => {
  function close() { }

  const [clientId, setClientId] = useState<SearchClientBalance | null>();
  const [activeBalanceId, setActiveBalanceId] = useState(0);
  const [search, setSearch] = useState('');
  const { data: clientBalanceSearch, fetchNextPage } = useClientBalanceSearch(search);
  const { t } = useTranslation();
  const { data: paymentTypes } = usePaymentConfig();
  
  const { data: dataPaymentCustomType } = useGetPaymentCustomType();
  // @ts-expect-error
  const filteredPaymentCustomType = dataPaymentCustomType?.filter(item => item?.status === true);

  const { data: paymentNumberData } = usePaymentNumber();
  const { data: currency, isLoading } = useCurrencyList();
  const { data: paymentFields } = usePaymentFieldGet();
  const paymentFieldActives = useMemo(() => paymentFields?.filter(item => item?.show), [paymentFields]);
  const createPayment = useCreatePayment(String(contractId), close);

  const basicCurrency = currency?.find(item => item?.basic);
  const notBasicCurrency = currency?.find(item => !item?.basic);

  const [form] = Form.useForm();
  const [dateVisible, setDateVisible] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState<CurrencyItemCopy | undefined>();
  const [activeValyuta, setActiveValyuta] = useState<CurrencyItemCopy | undefined>();
  const [changedCurrency, setChangedCurrency] = useState<CurrencyItemCopy | undefined>();
  const [amount, setAmount] = useState(0);
  const [autoComplate, setAutoComplate] = useState(false);
  const [scrolling, setScrolling] = useState(true);
  const [files, setFiles] = useState<FileObject[]>();

  const mortgageField = Form.useWatch('mortgage', form);
  const selectedPaymentType = Form.useWatch('paymentType', form);

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessPastDatePayment = permissions?.includes(Permissions.PERMISSION_PAST_DATE_PAYMENT);
  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const onFinish = (values: any) => {
    const fields = values?.fields
      ?.filter((item: any) => item?.value)
      ?.map((item: any) => ({
        fieldId: item?.id,
        value: String(item?.value)
      }));

    const fileIds = files?.map(file => file?.id);

    let paymentData = {
      mortgage: values?.mortgage,
      fields,
      amount: helper.deleteNotNumbersAndParseFloatNumber(values?.paymentSum).number,
      type: payType === PayType.CUSTOM ? values?.paymentType : PaymentType.BALANCE,
      // eslint-disable-next-line no-nested-ternary
      number: values?.paymentNumber ? (paymentNumberData?.number === values?.paymentNumber ? null : values?.paymentNumber) : null,
      base: values?.penyPayment ? 'SURCHARGE' : 'CONTRACT',
      clientId: payType === PayType.BALANCE ? clientId?.id : null,
      note: values?.note,
      currencyId: values.currencyId,
      ...(payType === PayType.BALANCE && {
        balanceCurrencyId: activeBalanceId
      }),
      exchange:
        helper.deleteNotNumbersAndParseFloatSmallNumber(String(activeValyuta?.value)).number !==
          helper.deleteNotNumbersAndParseFloatSmallNumber(String(notBasicCurrency?.value)).number
          ? helper.deleteNotNumbersAndParseFloatSmallNumber(String(activeValyuta?.value)).number
          : null,
      ...(values.date && { date: dayjs(values.date).format(DATE_FORMAT) }),
      files: fileIds?.length === 0 ? null : fileIds
    };

    if (selectedPaymentType !== 'OTHERS') {
      paymentData = { ...paymentData, files: null };
    }

    // @ts-expect-error
    const selectedCustomType = filteredPaymentCustomType?.find(item => item?.id === selectedPaymentType);

    if (selectedCustomType) {
      paymentData.type = 'CUSTOM';
      paymentData.paymentCustomTypeId = selectedCustomType.id;
    }

    createPayment.mutateAsync(paymentData).then(() => {
      form.resetFields();
      form.setFieldsValue({
        paymentNumber: paymentNumberData?.number,
        currencyId: contractCurrency?.id
      });
      setActiveValyuta({ ...basicCurrency!, value: notBasicCurrency?.value! });
      setActiveCurrency({ ...notBasicCurrency!, value: basicCurrency?.value! });
      setChangedCurrency(contractCurrency);
      setClientId(null);
      setAmount(0);
      setSearch('');
    });
  };

  useEffect(() => {
    if (contractCurrency) {
      form.setFieldsValue({
        currencyId: contractCurrency?.id
      });

      setActiveValyuta({ ...basicCurrency!, value: notBasicCurrency?.value! });
      setActiveCurrency({ ...notBasicCurrency!, value: basicCurrency?.value! });
    }
  }, [contractCurrency, form, notBasicCurrency]);

  useEffect(() => {
    form.setFieldsValue({
      paymentNumber: paymentNumberData?.number,
      fields: paymentFieldActives
    });
    setChangedCurrency(contractCurrency);
  }, [paymentNumberData, form, contractCurrency, paymentFieldActives]);

  // changeText
  const changeText = (e: any) => {
    setSearch(e?.target?.value);
    setAutoComplate(true);
  };

  // handleActiveBalance
  const handleActiveBalance = (id: number) => {
    setActiveBalanceId(id);
  };

  const changeCurrency = (id: number) => {
    const currencySelect = currency?.find(item => item.id === id);

    setChangedCurrency(currencySelect);
  };

  const changeValyuta = (value: string) => {
    setActiveValyuta(prev => ({
      ...prev!,
      value: helper.deleteNotNumbersAndParseFloatSmallNumber(value).string
    }));
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  function getFile(data: FileObject[]) {
    if (files) {
      setFiles([...files, ...data]);
    } else {
      setFiles(data);
    }
  }

  const uploadFile = useUploadFile(getFile);

  const changeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0] && e.target.files[0]?.size <= 19000000) {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('files', file);
        uploadFile.mutate(formData);
        e.target.value = '';
      }
    }
  };

  const deleteFile = (id: number) => {
    setFiles(files?.filter(item => item?.id !== id));
  };

  const toKilobytes = (bytes: number) => (bytes / 1024).toFixed(2);

  useEffect(() => {
    const documentPaste = (e: ClipboardEvent) => {
      const clipDataArr: any = e.clipboardData?.items;
      const items: any[] = [...clipDataArr];

      items.forEach(item => {
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();

          // eslint-disable-next-line func-names
          reader.onload = function (event) {
            const file = blob;
            const formData = new FormData();

            formData.append('files', file);
            uploadFile.mutate(formData);
          }; // data url!
          reader.readAsDataURL(blob);
        }
      });
    };

    document.addEventListener('paste', documentPaste);
    return () => {
      document.removeEventListener('paste', documentPaste);
    };
  }, [uploadFile]);

  const FileName: React.FC<FileNameProps> = ({ originalName }) => {
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return `${text.slice(0, maxLength - 1)}...`;
      }
      return text;
    };

    const getTruncatedFileName = (fileName: string, maxLength: number) => {
      const extensionIndex = fileName.lastIndexOf('.');
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);

      if (name.length > maxLength) {
        return `${truncateText(name, maxLength)}(${extension})`;
      }
      return fileName;
    };

    const truncatedFileName = getTruncatedFileName(originalName, 35);

    return (
      <Tooltip title={originalName} overlayInnerStyle={{ borderRadius: '6px', border: '1px solid black' }}>
        <p className="text-[16px] font-medium text-[#344054]">{truncatedFileName}</p>
      </Tooltip>
    );
  };

  return (
    <Form layout="vertical" onFinish={onFinish} className={styles.form} form={form}>
      {payType === PayType.BALANCE && (
        <>
          <Form.Item label={t('create_payment.Mijoz_qidirish')} name="clientSearch">
            <Dropdown
              visible={autoComplate}
              onVisibleChange={setAutoComplate}
              overlay={
                <div className={styles.clientChangeDropdown} onScroll={e => onScroll(e)}>
                  {clientBalanceSearch?.pages?.map(data =>
                    data?.data?.map(item => (
                      <h5
                        key={item?.id}
                        onClick={() => {
                          setAutoComplate(false);
                          setClientId(item);
                          setActiveBalanceId(item?.balances[0]?.currencyDto?.id!);
                          setSearch(item?.name);
                        }}
                      >
                        {item?.name}
                      </h5>
                    ))
                  )}
                </div>
              }
              trigger={['click']}
            >
              <Input placeholder={t('titles.search-client')} style={{ width: 452, borderRadius: 6, marginBottom: 20 }} onChange={e => changeText(e)} value={search} />
            </Dropdown>
          </Form.Item>
          <div className={styles.form_balance}>
            {clientId &&
              clientId?.balances?.map(item => (
                <div
                  className={`${styles.form_balance_item} ${activeBalanceId === item?.currencyDto?.id ? styles.form_balance_item_active : ''}`}
                  key={item?.currencyDto?.id}
                  onClick={() => handleActiveBalance(item?.currencyDto?.id!)}
                >
                  <p>{item?.currencyDto?.basic ? t('create_payment.Asosiy_balans') : t('create_payment.Qoshimcha_balans')}</p>
                  <h3>
                    {item?.amount?.toLocaleString('ru')} {item?.currencyDto?.ccy}
                  </h3>
                  <span className={styles.form_balance_item_icon}>
                    <TickCircleCrmIcon background="#00A389" />
                  </span>
                </div>
              ))}
          </div>
        </>
      )}
      <Form.Item label={t('create_payment.Tolov_raqami')} name="paymentNumber" rules={[{ required: true, message: '' }]}>
        <Input className="customInput" disabled={!contractId} />
      </Form.Item>
      <div className={styles.form_space}>
        <Form.Item label={t("create_payment.To'lov_summasi")} name="paymentSum" rules={[{ required: true, message: '' }]} className={styles.form_space_selectToInput}>
          <Input
            className="customInput"
            onChange={e => {
              form.setFieldsValue({
                paymentSum: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).string
              });
              setAmount(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number);
            }}
            disabled={!contractId}
          />
        </Form.Item>
        <Form.Item label={t('create_payment.Valyuta')} name="currencyId" rules={[{ required: true, message: '' }]}>
          <Select loading={isLoading} style={{ width: 113 }} className="customSelect" onChange={changeCurrency} disabled={!contractId}>
            {currency?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Select.Option key={index} value={item.id}>
                {item.ccy}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {payType === PayType.CUSTOM && (
          <Form.Item label={t("create_payment.To'lov_turi")} name="paymentType" rules={[{ required: true, message: '' }]}>
            <Select
              style={{ width: 113 }}
              className="customSelect"
            // disabled={!clientId}
            >
              {paymentTypes?.types
                ?.filter(item => (mortgageField ? item === 'BANK' : true))
                ?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {t(`home.${item}`)}
                  </Select.Option>
                ))}
              {/* @ts-expect-error */}
              {filteredPaymentCustomType?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>
      {currency && currency?.length > 1 ? (
        <>
          <div className={styles.form_space}>
            <Form.Item label={t('create_payment.Valyuta_kursi')} className={styles.form_space_selectToInput}>
              <Input
                className="customInput"
                suffix={activeValyuta?.ccy}
                value={helper.deleteNotNumbersAndParseFloatSmallNumber(String(activeValyuta?.value)).string}
                onChange={e => {
                  changeValyuta(e.target.value);
                }}
              />
            </Form.Item>
            <div className={styles.arrowDown}>
              <ArrowDownLinearIcon />
            </div>
            <Form.Item label={t('create_payment.Natija')} className={styles.form_space_selectToInput}>
              <Input
                className="customInput"
                suffix={activeCurrency?.ccy}
                value={
                  activeCurrency?.ccy === changedCurrency?.ccy
                    ? amount?.toLocaleString('ru')
                    : calcCurrency(activeCurrency?.value!, activeValyuta?.value!, amount)?.toLocaleString('ru')
                }
                onKeyDown={e => e.preventDefault()}
                onKeyUp={e => e.preventDefault()}
              />
            </Form.Item>
          </div>
        </>
      ) : null}

      {isAccessPastDatePayment && (
        <Form.Item>
          {t('home.Sana')}: <Switch size="small" checked={dateVisible} onChange={checked => setDateVisible(checked)} disabled={!contractId} />
        </Form.Item>
      )}

      {isAccessPastDatePayment && dateVisible && (
        <Form.Item name="date" rules={[{ required: true, message: '' }]}>
          {/* @ts-ignore */}
          <DatePicker
            className="customInput"
            placeholder=""
            inputRender={props => (
              <ReactInputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar=""
                {...props}
              >
                {/* @ts-ignore */}
                {(inputProps: any) => <input {...inputProps} {...props} />}
              </ReactInputMask>
            )}
            format={DATE_FORMAT}
          />
        </Form.Item>
      )}

      <Form.Item label={t('home.Izoh')} name="note">
        <Input.TextArea autoSize={{ maxRows: 5, minRows: 1 }} className="customInput" disabled={!contractId} />
      </Form.Item>

      <Form.Item name="penyPayment" valuePropName="checked">
        <Checkbox defaultChecked={false} disabled={!contractId} onChange={() => form.setFieldsValue({ mortgage: false })}>
          {t('create_payment.Peniyaga_tolov')}
        </Checkbox>
      </Form.Item>
      {isMultiPayment && contractIdData?.mortgage ? (
        <Form.Item name="mortgage" valuePropName="checked">
          <Checkbox
            disabled={!contractId}
            onChange={() => {
              form.setFieldsValue({ penyPayment: false });

              if (form.getFieldValue('paymentType') !== 'BANK') {
                form.setFieldsValue({ paymentType: undefined });
              }
            }}
          >
            Ipotekaga to'lov
          </Checkbox>
        </Form.Item>
      ) : null}
      {/* <Form.List name="fields">
        {fields =>
          fields?.map((item, index) => {
            const field: PaymentField = form.getFieldValue('fields')[index];

            return (
              <Form.Item key={item?.key} label={field?.name} name={[item.name, 'value']} rules={[{ required: field?.required, message: '' }]}>
                {CustomFields[field?.type]
                  ? CustomFields[field?.type]!({
                    options: field?.options?.map(item => ({
                      ...item,
                      id: item?.name as any
                    }))
                  })
                  : null}
              </Form.Item>
            );
          })
        }
      </Form.List> */}

      <Form.List name="fields">
        {fields =>
          fields?.map((item, index) => {
            const field = paymentFieldActives![index];

            return (
              <Form.Item {...item} key={item?.key} label={field?.name} name={[item.name, 'value']} rules={[{ required: field?.required, message: '' }]}>
                {CustomFields[field?.type]
                  ? CustomFields[field?.type]!({
                    options: field?.options?.map(item => ({
                      ...item,
                      id: item?.name as any
                    }))
                  })
                  : null}
              </Form.Item>
            );
          })
        }
      </Form.List>

      {selectedPaymentType === 'OTHERS' && (
        <div>
          <label
            htmlFor="chatFile"
            className="flex cursor-pointer items-center justify-center rounded-xl border-[1px] border-[#EAECF0] px-[24px] py-[16px]"
            style={{ border: '1px solid #EAECF0' }}
          >
            <div>
              <label htmlFor="chatFile" className="ml-auto mr-auto flex items-center justify-center">
                {uploadFile.isLoading && <LoadingOutlined />}
                <span className="cursor-pointer">
                  <UploadFileIcon />
                </span>
                <input type="file" id="chatFile" style={{ display: 'none' }} onChange={e => changeFile(e)} />
              </label>
              <p className="ml-auto mr-auto text-[12px] font-semibold text-[#1E90FF]">{t('home.Fayl_yuklash_uchun_bosing')}</p>
              <div className="mt-1px] *Maksimal fayl hajmi 19MB*">
                <p className="text-[9px] font-medium text-[#2b2828bd]">*{t('home.maximal_size')} 19MB*</p>
              </div>
            </div>
          </label>

          <div>
            {files?.map(item => (
              <div key={item?.id} className="mt-3 flex items-center justify-between rounded-xl bg-[#F9FAFB] p-[16px]" style={{ border: '1px solid #E5E7EB' }}>
                <div className="flex items-center gap-3">
                  <span className="flex items-center justify-center rounded-[10px] border-[1px] bg-[#EFF1F5] p-[8px]" style={{ border: '1px solid #E5E7EB' }}>
                    <FileIcon />
                  </span>
                  <div>
                    <FileName originalName={item?.originalName} />
                    {/* <p className='text-[#344054] font-medium text-[16px]'>{item?.originalName}{' '}</p> */}
                    <p className="text-[16px] font-medium text-[#98A2B3]">{item?.size ? `${toKilobytes(item?.size)} KB` : 'N/A'}</p>
                  </div>
                </div>
                <span className="cursor-pointer" onClick={() => deleteFile(item?.id)}>
                  <DeleteIconV2 />
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      <br />
      <Button
        type="primary"
        htmlType="submit"
        className={styles.form_submit}
        loading={createPayment.isLoading}
        disabled={!(contractId && (payType === PayType.BALANCE ? clientId : true))}
      >
        <CardAddIcon /> {t("create_payment.To'lov_qilish")}
      </Button>
    </Form>
  );
};

export default PaymentClientBalance;
