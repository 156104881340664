import React, { useEffect, useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowBottomIcon from '@icons/ArrowBottomIcon';
import ArrowIcons from '@icons/ArrowIcons';
import ArrowTopIcon from '@icons/ArrowTopIcon';
import DashboardIcon from '@icons/DashboardIcon';
import { Permissions } from '@utils/constants/permissions';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import ExportIcon from '../../../../../assets/icons/ExportIcon';
import ImportIcon from '../../../../../assets/icons/ImportIcon';
import MenuThreeDotHorizontalIcon from '../../../../../assets/icons/MenuThreeDotHorizontalIcon';
import SettingIcon from '../../../../../assets/icons/SettingIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { openTableSettings, setOpenDashboard } from '../../../../../store/reducers/CrmSlice';
import DashboardModal from '../../dashboard-modal/DashboardModal';
import ExportModal from '../import-export/ExportModal';
import ImportModal from '../import-export/ImportModal';

import styles from './actionsButton.module.scss';

const ActionsButton = () => {
  const { t } = useTranslation();
  const { appendSingle, searchParams, remove } = useQueryParam<any, any>();
  const [open, setOpen] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [orderAndField, setOrderAndField] = useState<{ field: string; order: string | null }>({
    field: searchParams?.orderField ? searchParams?.orderField : null,
    order: searchParams?.order ? searchParams?.order : 'ASC'
  });
  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);

  const isImportExport = permissions?.includes(Permissions.PERMISSION_CRM_LEAD_IMPORT_AND_EXPORT);
  const listView = useAppSelector(state => state.assigmentSlice.showListView);

  const isAccessCreateDashboard = permissions?.includes(Permissions.PERMISSION_PAGE_DASHBOARD);

  const dispatch = useAppDispatch();
  // handle change visible
  const handleChangeVisible = (visible: boolean) => {
    setOpen(visible);
  };

  // handle import modal
  const handleImportModal = (isOpen: boolean) => {
    setOpenImportModal(isOpen);
    handleChangeVisible(false);
  };

  // handle import modal
  const handleExportModal = (isOpen: boolean) => {
    setOpenExportModal(isOpen);
    handleChangeVisible(false);
  };

  // handle open drawer
  const handleOpenDrawer = () => {
    dispatch(openTableSettings(true));
    handleChangeVisible(false);
  };

  // handle open dashboard modal
  const handleOpenDM = () => {
    dispatch(setOpenDashboard(true));
  };

  useEffect(() => {
    appendSingle('order', orderAndField?.order === null || orderAndField?.field === null ? undefined : orderAndField?.order!);
    appendSingle('orderField', orderAndField?.order === null || orderAndField?.field === null ? undefined : orderAndField?.field!);
  }, [orderAndField]);

  const sortArr = [
    {
      id: 1,
      orderField: 'DATE',
      label: t('list_view.Yaratilgan_sana')
    },
    {
      id: 2,
      orderField: 'CONTACT',
      label: t('list_view.Oxirgi_qongiroq')
    },
    {
      id: 3,
      orderField: 'CHANGES',
      label: t('list_view.Oxirgi_ozgarish')
    }
  ];

  return (
    <div className={styles.container}>
      <Dropdown
        onVisibleChange={handleChangeVisible}
        visible={open}
        placement="bottomRight"
        overlayClassName={styles.container_dropdown}
        overlay={
          <>
            {isImportExport && (
              <>
                <div className={styles.container_dropdown_item} onClick={() => handleImportModal(true)}>
                  <ImportIcon />
                  <span>{t('list_view.Import')}</span>
                </div>
                <div className={styles.container_dropdown_item} onClick={() => handleExportModal(true)}>
                  <ExportIcon />
                  <span>{t('list_view.Export')}</span>
                </div>
                {isAccessCreateDashboard ? (
                  <div className={styles.container_dropdown_item} onClick={handleOpenDM}>
                    <DashboardIcon />
                    <span>Widget</span>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
            {listView && (
              <div className={styles.container_dropdown_item} onClick={handleOpenDrawer}>
                <SettingIcon />
                <span>{t('list_view.List_settings')}</span>
              </div>
            )}
            <div className={styles.sort}>
              <p>{t('list_view.Saralash')}</p>
              {sortArr?.map(item => (
                <div
                  style={{ backgroundColor: orderAndField?.field === item?.orderField && orderAndField?.order !== null ? '#f4f4f4' : '' }}
                  className={styles.sort_item}
                  key={item?.id}
                  onClick={() => {
                    setOrderAndField(prev => ({
                      ...prev,
                      field: item?.orderField,
                      // eslint-disable-next-line no-nested-ternary
                      order:
                        // eslint-disable-next-line no-nested-ternary
                        item?.orderField !== orderAndField?.field
                          ? 'DESC'
                          : // eslint-disable-next-line no-nested-ternary
                            prev?.order === 'DESC'
                            ? 'ASC'
                            : // eslint-disable-next-line no-nested-ternary
                              prev?.order === 'ASC'
                              ? null
                              : 'DESC'
                    }));
                  }}
                >
                  <span>{item?.label}</span>
                  {orderAndField?.field === item?.orderField &&
                    orderAndField?.order !== null &&
                    // eslint-disable-next-line no-nested-ternary
                    (orderAndField?.order === 'ASC' ? <ArrowTopIcon /> : <ArrowBottomIcon />)}
                  {searchParams?.field === undefined && searchParams?.orderField !== item?.orderField && (
                    <ArrowIcons className={styles.sort_item_icons} />
                  )}
                </div>
              ))}
            </div>
          </>
        }
        trigger={['click', 'hover']}
        mouseLeaveDelay={0.5}
      >
        <MenuThreeDotHorizontalIcon onClick={() => handleChangeVisible(true)} />
      </Dropdown>
      <ImportModal visible={openImportModal} close={handleImportModal} />
      <ExportModal visible={openExportModal} close={handleExportModal} />
      <DashboardModal />
    </div>
  );
};

export default ActionsButton;
