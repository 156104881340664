import DatePicker from '@service/datePicker';
import { DATE_FORMAT } from '@utils/constants/format';
import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import dayjs from 'dayjs';

interface IProps {
  placeholder?: string;
  format?: string;
  value?: any;
  onChange: (val: string) => void;
}

const DatePickerCustom = (props: IProps) => {
  const { placeholder = '', format, value, onChange } = props;
  const formatDate = format || DATE_FORMAT;

  const keyUp = (value: string) => {
    if (value.length === formatDate.length) {
      onChange(value);
    }
  };

  return (
    <DatePicker
      placeholder={placeholder}
      value={value && dayjs(value, DATE_FORMAT)}
      format={formatDate}
      onChange={(value, dateString) => {
        onChange(dateString);
      }}
      style={{ width: '100%' }}
      inputRender={propsInput => {
        return (
          <ReactInputMask
            mask="99.99.9999"
            // @ts-ignore*
            maskChar={''}
            onKeyUp={(e: any) => keyUp(e.target.value)}
            {...propsInput}
          >
            {(inputProps: any) => <input {...inputProps} {...propsInput} />}
          </ReactInputMask>
        );
      }}
      className="customInput"
    />
  );
};

export default DatePickerCustom;
