/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
/* eslint-disable simple-import-sort/imports */
import React from 'react';
import { NavLink } from 'react-router-dom';
import TaskSquareGrayIcon from '@icons/TaskSquareGrayIcon';
import { useTranslation } from 'react-i18next';

import ArrowRightMessage from '../../../assets/icons/ArrowRightMessage';
import CodeIcon from '../../../assets/icons/CodeIcon';
import DocumentTextIcon from '../../../assets/icons/DocumentTextIcon';
import PaymentIcon from '../../../assets/icons/PaymentIcon';
import WalletMinusIcon from '../../../assets/icons/WalletMinusIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { MainPaths } from '../../../routing/main/mainPaths';
import { PaymentSettingsPath } from '../../../routing/paymentSettings/PaymentSettingsPath';
import { rootPaths } from '../../../routing/rootPaths';
import { SettingsPaths } from '../../../routing/settings/settingsPaths';

import styles from '../../paymentSettings/paymentSettings.module.scss';

const PaymentSettingsNavbar = () => {
  const param = useQueryParam();
  const { t } = useTranslation();

  const data = [
    {
      icon: DocumentTextIcon,
      text: t('navbars.Masofaviy_tolov'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.PAYMENT + PaymentSettingsPath.REMOTE_PAYMENT,

      class: param.splitUrl[param.splitUrl.length - 1] === 'remote-payment' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: CodeIcon,
      text: t('navbars.Avtomatik_tolov'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.PAYMENT + PaymentSettingsPath.AUTOMATIC_PAYMENT,

      class: param.splitUrl[param.splitUrl.length - 1] === 'automatic-payment' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: WalletMinusIcon,
      text: t('company_config.Tolov_usullari'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.PAYMENT + PaymentSettingsPath.PAYMENT_WAYS,
      class: param.splitUrl[param.splitUrl.length - 1] === 'payment-ways' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: PaymentIcon,
      text: t('titles.currency'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.PAYMENT + PaymentSettingsPath.CURRENCY,
      class: param.splitUrl[param.splitUrl.length - 1] === 'currency' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: TaskSquareGrayIcon,
      text: t('shaxmatka.Ozgaruvchilar'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.PAYMENT + PaymentSettingsPath.FIELD,
      class: param.splitUrl[param.splitUrl.length - 1] === 'field' ? `${styles.active}` : `${styles.navLinkClass}`
    }
  ];

  return (
    <div className={styles.navbar}>
      {data.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <NavLink key={index} to={item.path} className={item.class}>
          <span className="d-flex align-center">
            <item.icon /> &nbsp; &nbsp; {item.text}
          </span>

          <span className={styles.arrowDiv}>
            <ArrowRightMessage />
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default PaymentSettingsNavbar;
