import { Mask } from '@components/fields';
import CheckCircleIcon from '@icons/CheckCircleIcon';
import { Button, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { Forms } from 'modules/max-discount';
import { useMaxDiscount } from 'modules/max-discount/queries';

const Discount = () => {
  const { maxDiscountPercent, isLoading } = useMaxDiscount();
  const { t } = useTranslation();

  if (isLoading) return <Skeleton active />;

  return (
    <div className="container flex items-center justify-between rounded-md bg-white p-6">
      <div className="flex flex-col">
        <h5 className="text-xl font-semibold text-zinc-900">{t('discount.Maksimal_chegirma_foizi')}</h5>
        <span>{t('discount.xodimlar_ushbu_miqdorgacha_chegirma_bilan_shartnoma_yaratishlari_mumkin')}</span>
      </div>
      <div className="flex items-center gap-3">
        <Forms.Update defaultValues={{ maxDiscountPercent }}>
          {({ control, formState }) => (
            <div className="flex w-full gap-2">
              <Mask control={control} name="maxDiscountPercent" maskType="amount" suffix={<b>%</b>} />
              <Button className="rounded-md" htmlType="submit" loading={formState.isSubmitting} disabled={!formState.isDirty}>
                <CheckCircleIcon stroke="#00a389" />
              </Button>
            </div>
          )}
        </Forms.Update>
      </div>
    </div>
  );
};

export default Discount;
