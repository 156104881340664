import { Button, Checkbox, Form, Input, Modal, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import ArrowDownTopIcon from '../../assets/icons/ArrowDownTopIcon';
import styles from '../../pages/settingPlayMobile/settingPlayMobile.module.scss';
import { usePlayMobileConfOriginationMutation } from '../../queries/mutation';
import { useOriginatorHouse } from '../../queries/queries';
import { queryNames } from '../../utils/constants/queryNames';
import { Originator, OriginatorHouse } from '../../utils/models/PlayMobile';

interface SettingPlayMobileModalProps {
  visible: boolean;
  edit?: OriginatorHouse | any;
  setVisible: (visibility: boolean) => void;
}

const SettingPlayMobileModal: FC<SettingPlayMobileModalProps> = ({ visible, setVisible, edit }) => {
  const [form] = Form.useForm();
  const [allSetting, setAllSetting] = useState(false);
  const { data: originators } = useOriginatorHouse();
  const playMobileOriginationMutation = usePlayMobileConfOriginationMutation();
  const qc = useQueryClient();
  const { t } = useTranslation();
  const defaultOriginators = edit?.houses?.map((item: Originator) => item.id);
  const dallOriginators = edit?.houses?.concat(originators);
  const allOriginatorIds = originators?.map((item: Originator) => item.id);

  useEffect(() => {
    if (visible) {
      if (!!edit) {
        form.setFieldsValue({
          name: edit.name,
          houses: defaultOriginators
        });
      } else {
        qc.invalidateQueries([queryNames.ORIGINAL_HOUSE]);
        form.setFieldsValue({
          name: '',
          houses: allOriginatorIds
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    handleCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playMobileOriginationMutation.isSuccess]);

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
    qc.invalidateQueries([queryNames.ORIGINAL_HOUSE]);
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
    const houses = values.houses.map((item: any) => {
      return {
        id: item,
        name: ''
      };
    });
    if (edit) {
      playMobileOriginationMutation.mutate({
        id: edit.id,
        name: values.name,
        houses: houses
      });
    } else {
      playMobileOriginationMutation.mutate({
        name: values.name,
        houses: houses
      });
    }
  };

  return (
    <Modal
      title={t('setting_play_mobile.Originatorni_tahrirlash')}
      visible={visible}
      onCancel={handleCancel}
      className="settingPlayMobileModal"
      footer={null}
      width={399}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          label={t('discount_setting.Nomi')}
          name={'name'}
          rules={[
            {
              required: true,
              message: t('setting_play_mobile.Maydonni_toldiring')
            }
          ]}
        >
          <Input />
        </Form.Item>
        <span className={styles.settingPlayMobileModalSettingText} onClick={() => setAllSetting(!allSetting)}>
          {t('setting_play_mobile.Toliq_sozlamalar')}{' '}
          <span
            style={{
              transform: `rotate(${allSetting ? 180 : 0}deg)`,
              display: 'inline-block'
            }}
          >
            <ArrowDownTopIcon />
          </span>
        </span>
        <Form.Item label="" name={'houses'} style={{ paddingLeft: 15 }}>
          <Checkbox.Group
            style={{
              display: allSetting ? 'block' : 'none',
              maxHeight: 400,
              overflowY: 'auto'
            }}
          >
            <Space direction="vertical">
              {edit
                ? dallOriginators?.map((item: any) => {
                    return (
                      <Checkbox key={item.id} value={item.id}>
                        {item.name}
                      </Checkbox>
                    );
                  })
                : originators?.map((item: any) => (
                    <Checkbox key={item.id} value={item.id}>
                      {item.name}
                    </Checkbox>
                  ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <div className={styles.settingPlayMobileModalFooter}>
          <Button onClick={handleCancel}>{t('home.Yopish')}</Button>
          <Button htmlType="submit" type="primary" loading={playMobileOriginationMutation.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SettingPlayMobileModal;
