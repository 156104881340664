import { useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { $api } from '@service/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { GivePermissionModel } from '../utils/models/GivePermissionModel';

export function useGetForceData() {
  return useQuery<GivePermissionModel>({
    queryKey: [queryKeys.FORCE_CLIENT_LEAD],
    queryFn: async () => {
      const res = await $api.get(endPoints.FORCE_CLIENT_LEAD);
      return res.data.data;
    },
    refetchOnWindowFocus: false
  });
}
