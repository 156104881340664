import Discount from '@pages/discount/Discount';
import TermDiscount from '@pages/discount-config/pages/term-discount/TermDiscount';
import DiscountSetting from '@pages/discountSetting/DiscountSetting';

import { discountPaths } from './discountPaths';

export const DiscountRoutes = [
  {
    component: Discount,
    path: discountPaths.DISCOUNT,
    exact: true
  },
  {
    component: DiscountSetting,
    path: discountPaths.DISCOUNT_SETTINGS,
    exact: false
  },
  {
    component: TermDiscount,
    path: discountPaths.TERM_DISCOUNT,
    exact: false
  }
];
