import React from 'react';
import styles from './f4f.module.scss';
import { Link } from 'react-router-dom';
import F4fIcon from '../../assets/icons/F4fIcon';
import { useTranslation } from 'react-i18next';
import { rootPaths } from '../../routing/rootPaths';

const F4f = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.cont}>
      <h1>{t('access_denied.Ushbu_sahifa_topilmadi')}</h1>
      <F4fIcon />
      <div>
        <h2>{t('access_denied.Bosh_sahifaga_qaytish_uchun')}:</h2>
        <Link to={rootPaths.INDEX}>{t('access_denied.Bosh_sahifa')}</Link>
      </div>
    </div>
  );
};

export default F4f;
