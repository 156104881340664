const ChangeImageIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00074 25.6666L18.8261 13.8412C19.3542 13.3132 19.6182 13.0491 19.9226 12.9502C20.1904 12.8632 20.4789 12.8632 20.7467 12.9502C21.0511 13.0491 21.3151 13.3132 21.8431 13.8412L27.5416 19.5397M13.0013 10.3327C13.0013 11.8054 11.8074 12.9993 10.3346 12.9993C8.86188 12.9993 7.66797 11.8054 7.66797 10.3327C7.66797 8.85992 8.86188 7.66602 10.3346 7.66602C11.8074 7.66602 13.0013 8.85992 13.0013 10.3327ZM28.3346 14.9993C28.3346 22.3631 22.3651 28.3327 15.0013 28.3327C7.63751 28.3327 1.66797 22.3631 1.66797 14.9993C1.66797 7.63555 7.63751 1.66602 15.0013 1.66602C22.3651 1.66602 28.3346 7.63555 28.3346 14.9993Z"
        stroke="#464255"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChangeImageIcon;
