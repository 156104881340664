import React, { useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import styles from './editTask.module.scss';
import { TaskType } from '../../../utils/models/TaskType';
import { validateTimeString } from '../../../utils/helper/validatorDateString';
import InputMask from 'react-input-mask';
import ClockIcon from '../../../assets/icons/ClockIcon';
import { useEmployeeTask } from '../../../queries/queries';
import SendIcon from '../../../assets/icons/SendIcon';
import { helper } from '../../../utils/helper/helper';
import { Task, UpdateTask } from '../../../utils/models/Task';
import DollarGreenIcon from '../../../assets/icons/DollarGreenIcon';
import CheckedYellowIcon from '../../../assets/icons/CheckedYellowIcon';
import MessageBlueIcon from '../../../assets/icons/MessageBlueIcon';
import { DATE_FORMAT } from '../../../utils/constants/format';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { useUpdateTask } from '../../../queries/mutation';
import { useQueryParam } from '../../../hooks/useQueryParams';

const { Option } = Select;

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  isCompleted: boolean;
  task: Task | undefined;
}

interface FormI {
  date: Dayjs;
  time: string | undefined;
  assignedById: number;
  text: string;
  payment: string;
  closedText: string;
}

const EditTask: React.FC<Props> = ({ visible, onClose, task, isCompleted }) => {
  const [form] = Form.useForm<FormI>();
  const { params } = useQueryParam();

  const { isLoading: employeeLoading, data: employee } = useEmployeeTask();

  const updateTask = useUpdateTask(Number(params.contractId), task?.id || -1, handleSuccess);

  const { t } = useTranslation();

  useEffect(() => {
    if (task && visible) {
      const findEmployee = employee?.find(emp => emp.fullName === task.employee);
      form.setFieldsValue({
        date: dayjs(task.date, DATE_FORMAT),
        payment: task?.payment ? task.payment.toLocaleString('ru') : '',
        assignedById: Number(findEmployee?.id),
        time: task.date.split(' ')[1],
        text: getCurrentLanguageWord(task.text),
        closedText: task.closedText
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, form, visible]);

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  // handle cancel modal
  const handleCancel = () => {
    form.setFieldsValue({
      time: '',
      assignedById: undefined,
      payment: '',
      text: undefined,
      date: undefined,
      closedText: ''
    });
    onClose(false);
  };

  // onFinish
  const onFinish = (value: FormI) => {
    switch (task?.type) {
      case TaskType.PAYMENT:
        updatePaymentTask(value);
        break;
      case TaskType.SIMPLE:
        updateSimpleTask(value);
        break;
      case TaskType.NOTE:
        updateNoteTask(value);
        break;
      default:
        break;
    }
  };

  // update payment task
  const updatePaymentTask = (value: FormI) => {
    if (isCompleted) {
      const data: UpdateTask = {
        closedText: value.closedText
      };
      updateTask.mutate(data);
    } else {
      let date = dayjs(value.date).format(DATE_FORMAT);
      if (value.time) {
        date += ' ' + value.time;
      } else {
        date += ' 23:59';
      }
      const data: UpdateTask = {
        employeeId: value.assignedById,
        text: value.text,
        date: date,
        payment: Number(helper.deleteSpace(value.payment))
      };
      updateTask.mutate(data);
    }
  };

  // update simple task
  const updateSimpleTask = (value: FormI) => {
    if (isCompleted) {
      const data: UpdateTask = {
        closedText: value.closedText
      };
      updateTask.mutate(data);
    } else {
      let date = dayjs(value.date).format(DATE_FORMAT);
      if (value.time) {
        date += ' ' + value.time;
      } else {
        date += ' 23:59';
      }
      const data: UpdateTask = {
        employeeId: value.assignedById,
        text: value.text,
        date: date
      };
      updateTask.mutate(data);
    }
  };

  // update note task
  const updateNoteTask = (value: FormI) => {
    const data: UpdateTask = {
      text: value.text
    };

    updateTask.mutate(data);
  };

  // handle date after 1 hours
  const handleDateAfterHour = () => {
    const today = dayjs(new Date());
    form.setFieldsValue({
      date: today,
      time: today.add(1, 'h').format('HH:mm')
    });
  };

  // handle date today
  const handleDateToday = () => {
    form.setFieldsValue({
      date: dayjs(new Date()),
      time: undefined
    });
  };

  // handle date today
  const handleDateTomorrow = () => {
    form.setFieldsValue({
      date: dayjs(new Date()).add(1, 'day'),
      time: undefined
    });
  };

  // handle date end week
  const handleDateEndWeek = () => {
    form.setFieldsValue({
      date: dayjs(new Date()).endOf('week').add(-1, 'day'),
      time: undefined
    });
  };

  // handle date add week
  const handleDateAddWeek = () => {
    form.setFieldsValue({
      date: dayjs(new Date()).add(1, 'week'),
      time: undefined
    });
  };

  return (
    <Modal className={styles.container} width={627} footer={false} visible={visible} title={t('client.Tahrirlash')} onCancel={handleCancel}>
      <Select disabled={true} value={task?.type} className={styles.select + ' customSelect'}>
        <Option value={TaskType.PAYMENT}>
          <DollarGreenIcon />
          <span
            style={{
              // @ts-ignore
              '--color': '#00A389'
            }}
          >
            {t('one_task.Tolov_topshirigi')}
          </span>
        </Option>
        <Option value={TaskType.SIMPLE}>
          <CheckedYellowIcon />
          <span
            style={{
              // @ts-ignore
              '--color': '#FFB400'
            }}
          >
            {t('one_task.Topshirig')}
          </span>
        </Option>
        <Option value={TaskType.NOTE}>
          <MessageBlueIcon />
          <span
            style={{
              // @ts-ignore
              '--color': '#5961F9'
            }}
          >
            {t('one_task.Eslatma')}
          </span>
        </Option>
      </Select>
      <Form
        form={form}
        name="task-create-modal"
        onFinish={onFinish}
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
        className={styles.createTaskForm}
      >
        <Row gutter={[9, 9]}>
          <Col span={22}>
            {task?.type === TaskType.PAYMENT && (
              <Row gutter={[12, 9]}>
                <Col span={7}>
                  <Form.Item name="date" rules={[{ required: true, message: '' }]} label={''}>
                    <DatePicker
                      showToday={false}
                      disabled={isCompleted}
                      renderExtraFooter={() => (
                        <div>
                          <Button onClick={handleDateAfterHour}>{t('one_task.1_soatdan_song')}</Button>
                          <Button onClick={handleDateToday}>{t('one_task.Bugun')}</Button>
                          <Button onClick={handleDateTomorrow}>{t('one_task.Ertaga')}</Button>
                          <Button onClick={handleDateEndWeek}>{t('one_task.Hafta_oxiriga_Juma')}</Button>
                          <Button onClick={handleDateAddWeek}>{t('one_task.Kelasi_haftaga')}</Button>
                        </div>
                      )}
                      className={styles.date_input + ' customInput'}
                      placeholder={t('client_create_contract.kk_oo_yyyy')}
                      format={DATE_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  {isCompleted ? (
                    <Form.Item
                      name="time"
                      rules={[
                        { required: false, message: '', len: 5 },
                        {
                          validator: (rule, value) => {
                            if (value) {
                              form.setFieldsValue({
                                time: validateTimeString(value)
                              });
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                      label={''}
                    >
                      <Input
                        disabled={isCompleted}
                        className={styles.time_input + ' customInput'}
                        placeholder={t('one_task.Kun_davomida')}
                        suffix={<ClockIcon />}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="time"
                      rules={[
                        { required: false, message: '', len: 5 },
                        {
                          validator: (rule, value) => {
                            if (value) {
                              form.setFieldsValue({
                                time: validateTimeString(value)
                              });
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                      label={''}
                    >
                      <InputMask
                        disabled={isCompleted}
                        mask="99:99"
                        // @ts-ignore*
                        maskChar={''}
                      >
                        {(inputProps: any) => (
                          <Input
                            className={styles.time_input + ' customInput'}
                            placeholder={t('one_task.Kun_davomida')}
                            suffix={<ClockIcon />}
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </Form.Item>
                  )}
                </Col>
                <Col span={10}>
                  <Form.Item name="assignedById" rules={[{ required: true, message: '' }]} label={''}>
                    <Select
                      disabled={isCompleted}
                      optionFilterProp={'children'}
                      showSearch
                      loading={employeeLoading}
                      className={styles.employee_select + ' customSelect'}
                    >
                      {employee?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.fullName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={'payment'}
                    rules={[
                      {
                        validator: (rule, value) => {
                          if (!value) {
                            return Promise.reject('');
                          }
                          form.setFieldsValue({
                            payment: helper.deleteNotNumbers(value, null)?.toLocaleString('ru')
                          });
                          return Promise.resolve();
                        }
                      },
                      {
                        required: true,
                        message: ''
                      }
                    ]}
                  >
                    <Input disabled={isCompleted} placeholder={t('one_task.summa')} className={styles.payment + ' customInput'} />
                  </Form.Item>
                </Col>
                <Col span={17}>
                  <Form.Item
                    name={'text'}
                    rules={[
                      {
                        required: true,
                        message: ''
                      }
                    ]}
                  >
                    <Input.TextArea
                      autoSize
                      disabled={isCompleted}
                      placeholder={t('one_task.Izoh')}
                      className={styles.text_payment + ' customInput'}
                    />
                  </Form.Item>
                </Col>
                {isCompleted && (
                  <Col span={24}>
                    <Form.Item
                      name={'closedText'}
                      rules={[
                        {
                          required: true,
                          message: ''
                        }
                      ]}
                    >
                      <Input.TextArea autoSize placeholder={t('one_task.Natija_izohi')} className={'customInput'} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )}
            {task?.type === TaskType.NOTE && (
              <Row gutter={[12, 9]}>
                <Col span={24}>
                  <Form.Item
                    name={'text'}
                    rules={[
                      {
                        required: true,
                        message: ''
                      }
                    ]}
                  >
                    <Input.TextArea autoSize placeholder={t('one_task.Izoh')} className={'customInput'} />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {task?.type === TaskType.SIMPLE && (
              <Row gutter={[12, 9]}>
                <Col span={7}>
                  <Form.Item name="date" rules={[{ required: true, message: '' }]} label={''}>
                    <DatePicker
                      showToday={false}
                      disabled={isCompleted}
                      renderExtraFooter={() => (
                        <div>
                          <Button onClick={handleDateAfterHour}>{t('one_task.1_soatdan_song')}</Button>
                          <Button onClick={handleDateToday}>{t('one_task.Bugun')}</Button>
                          <Button onClick={handleDateTomorrow}>{t('one_task.Ertaga')}</Button>
                          <Button onClick={handleDateEndWeek}>{t('one_task.Hafta_oxiriga_Juma')}</Button>
                          <Button onClick={handleDateAddWeek}>{t('one_task.Kelasi_haftaga')}</Button>
                        </div>
                      )}
                      className={styles.date_input + ' customInput'}
                      placeholder={t('client_create_contract.kk_oo_yyyy')}
                      format={DATE_FORMAT}
                    />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  {isCompleted ? (
                    <Form.Item
                      name="time"
                      rules={[
                        { required: false, message: '', len: 5 },
                        {
                          validator: (rule, value) => {
                            if (value) {
                              form.setFieldsValue({
                                time: validateTimeString(value)
                              });
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                      label={''}
                    >
                      <Input
                        disabled={isCompleted}
                        className={styles.time_input + ' customInput'}
                        placeholder={t('one_task.Kun_davomida')}
                        suffix={<ClockIcon />}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="time"
                      rules={[
                        { required: false, message: '', len: 5 },
                        {
                          validator: (rule, value) => {
                            if (value) {
                              form.setFieldsValue({
                                time: validateTimeString(value)
                              });
                            }
                            return Promise.resolve();
                          }
                        }
                      ]}
                      label={''}
                    >
                      <InputMask
                        disabled={isCompleted}
                        mask="99:99"
                        // @ts-ignore*
                        maskChar={''}
                      >
                        {(inputProps: any) => (
                          <Input
                            className={styles.time_input + ' customInput'}
                            placeholder={t('one_task.Kun_davomida')}
                            suffix={<ClockIcon />}
                            {...inputProps}
                          />
                        )}
                      </InputMask>
                    </Form.Item>
                  )}
                </Col>
                <Col span={10}>
                  <Form.Item name="assignedById" rules={[{ required: true, message: '' }]} label={''}>
                    <Select
                      disabled={isCompleted}
                      optionFilterProp={'children'}
                      showSearch
                      loading={employeeLoading}
                      className={styles.employee_select + ' customSelect'}
                    >
                      {employee?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.fullName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name={'text'}
                    rules={[
                      {
                        required: true,
                        message: ''
                      }
                    ]}
                  >
                    <Input.TextArea
                      disabled={isCompleted}
                      autoSize
                      placeholder={t('one_task.Izoh')}
                      className={styles.text_simple + ' customInput'}
                    />
                  </Form.Item>
                </Col>
                {isCompleted && (
                  <Col span={24}>
                    <Form.Item
                      name={'closedText'}
                      rules={[
                        {
                          required: true,
                          message: ''
                        }
                      ]}
                    >
                      <Input.TextArea autoSize placeholder={t('one_task.Natija_izohi')} className={styles.text_simple + ' customInput'} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )}
          </Col>
          <Col className={styles.button_col} span={2}>
            <Button htmlType={'submit'} loading={updateTask.isLoading} className={styles.send_button} type={'primary'}>
              <SendIcon />
            </Button>
          </Col>
        </Row>
        <div></div>
      </Form>
    </Modal>
  );
};

export default EditTask;
