import { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import NoDataIcon from '@icons/NoDataIcon';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { colors } from '@utils/constants/showRoomColors';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetHouseDiscount } from '../../services/queries';
import { BossDiscountParam } from '../sunburst-header/SunburstHeader';

import styles from './sunburst.module.scss';

const SunburstChart = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>();
  const { discountCurrency, type } = useAppSelector(state => state.bossCurrency);
  const { searchParams } = useQueryParam<BossDiscountParam, any>();
  const { data: discount, isLoading } = useGetHouseDiscount(searchParams?.startDate, searchParams?.finishDate, discountCurrency);

  useEffect(() => {
    if (discount?.houseList && discount?.houseList?.length > 0) {
      const newTask: any[] = discount?.houseList?.map((item, index) => ({
        name: item?.houseName,
        children: item?.buildings?.map(prev => ({
          name: prev?.buildingName,
          loc: type === 'count' ? prev?.totalCount : prev?.totalDiscount
        })),
        color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)]
      }));

      setChartData(newTask);
    } else {
      setChartData([]);
    }
  }, [discount?.houseList, discountCurrency, searchParams?.finishDate, type]);

  const newData = {
    name: 'nivo',
    children: chartData
  };

  return (
    <div className={styles.wrapper}>
      <Spin spinning={isLoading}>
        <div className={styles.wrapper_center}>
          <h3>{`${t('home.Miqdori')}: ${discount?.overallCount ? discount?.overallCount : '0'}`}</h3>
          <h4>
            {`${t('boss_complex.Jami_summasi')}: ${discount?.overallAmount ? discount?.overallAmount?.toLocaleString('ru') : '0'}
          ${discountCurrency}`}
          </h4>
        </div>
      </Spin>
      {discount?.houseList?.length! > 0 ? (
        <div className={styles.container}>
          <div className={styles.container_chart}>
            <ResponsiveSunburst
              data={newData || []}
              margin={{ top: 60, right: 10, bottom: 10, left: 10 }}
              id="name"
              value="loc"
              valueFormat=" >-"
              cornerRadius={5}
              borderWidth={3}
              borderColor="white"
              colors={{ datum: 'data.color' }}
              childColor={{
                from: 'data.color',
                modifiers: [['brighter', 0.1]]
              }}
              enableArcLabels={false}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'data.color',
                modifiers: [['darker', 1.4]]
              }}
            />
          </div>
          <div className={styles.container_legends}>
            {newData?.children?.map((item, index) => (
              <div className={styles.container_legends_parent} key={index}>
                <div className={styles.parentTop}>
                  <div className={styles.container_legends_parent_circle} style={{ backgroundColor: item?.color }}>
                    <div />
                  </div>
                  <span>{item?.name}</span>
                </div>
                <div className={styles.container_legends_child}>
                  {item?.children?.map((prev: any, index: number) => (
                    <div className={styles.container_legends_child_item} key={index}>
                      <h4>{prev?.name}</h4>
                      <p>
                        (<span>{prev?.loc?.toLocaleString('ru')}</span>
                        {type === 'price' ? ` ${discountCurrency}` : ''})
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.container_empty}>
          <NoDataIcon />
          <h3>{t('boss_crm.empty')}</h3>
        </div>
      )}
    </div>
  );
};

export default SunburstChart;
