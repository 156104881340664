import React from 'react';

const TrueIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.03769 9.23333L7.03527 12.386L13.3406 4.39084" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default TrueIcon;
