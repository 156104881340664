import React, { useState } from 'react';
import { useChangeStatusKpi } from '@queries/mutation';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { ArgumentKpi, KpiItem } from '../../../utils/models/Kpi';
import AddItem from '../AddItem';

import AddModalSum from './AddModalSum';
import SimpleSum from './SimpleSum';
import SumItem from './SumItem';

import styles from '../../../pages/kpi/kpi.module.scss';

interface Props {
  kpi?: KpiItem;
  argKpi: ArgumentKpi[] | undefined;
  setKpiArg: React.Dispatch<React.SetStateAction<string>>;
  kpiArg: string;
}

const Sum: React.FC<Props> = ({ kpi, setKpiArg, kpiArg }) => {
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState(false);

  const changeStatus = useChangeStatusKpi(true, kpi?.id! === 7 ? 6 : 7);

  const handleChangeStatus = () => {
    changeStatus.mutate();
  };

  const handleAddModal = (visible: boolean) => {
    setOpenAddModal(visible);
  };

  return (
    <Spin spinning={changeStatus?.isLoading}>
      <div className={styles.cont_body_right}>
        <p>{t('kpi.Mukofot_hisoblash_asosini_tanlang')}</p>
        <div className="flex items-center">
          <div
            className={styles.cont_body_right_buttons}
            key="CONSTANT_PAYMENT_AMOUNT"
            onClick={() => {
              handleChangeStatus();
              setKpiArg('CONSTANT_PAYMENT_AMOUNT');
            }}
          >
            <div className={kpiArg === 'CONSTANT_PAYMENT_AMOUNT' ? styles.cont_body_right_buttons_active : styles.cont_body_right_buttons_not_active}>
              <span />
              <p>{t(`kpi.CONSTANT_PAYMENT_AMOUNT`)}</p>
            </div>
          </div>
          <div
            className={styles.cont_body_right_buttons}
            key="FLAT_AREA"
            onClick={() => {
              handleChangeStatus();
              setKpiArg('FLAT_AREA');
            }}
          >
            <div className={kpiArg === 'FLAT_AREA' ? styles.cont_body_right_buttons_active : styles.cont_body_right_buttons_not_active}>
              <span />
              <p>{t(`kpi.FLAT_AREA`)}</p>
            </div>
          </div>
        </div>
        <p>{t('kpi.Mukofot_hisoblash_formulalari')}</p>
        <SimpleSum kpiPart={kpi?.costKpi} />
        {kpi?.kpiPartList.map((kpiPart, index) => (
          <SumItem
            kpiPart={kpiPart}
            // eslint-disable-next-line react/no-array-index-key
            type={kpiArg}
            key={index}
          />
        ))}
        <AddItem openModal={handleAddModal} />
        <AddModalSum kpiId={kpi?.id || -1} visible={openAddModal} onClose={handleAddModal} type={kpiArg} />
      </div>
    </Spin>
  );
};

export default Sum;
