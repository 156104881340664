export const getClassNameOrder = (order: string, type: 'ASC' | 'DESC', orders: string[] | undefined) => {
  if (Array.isArray(orders)) {
    if (orders.includes(order)) {
      const index = orders.indexOf(order);
      if (orders[index + 1] === type) {
        return `orderTable_${type} active`;
      }
    }
  }
  return `orderTable_${type}`;
};
