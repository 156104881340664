import React from 'react';
import { Modal } from 'antd';
import styles from './amoCrm.module.scss';
import crownImage from './../../../assets/img/crown.png';
import Crown from '../../../assets/icons/Crown';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

const PremiumModal: React.FC<Props> = ({ visible, onClose }) => {
  const { t } = useTranslation();

  const onCloseModal = () => {
    onClose(false);
  };

  return (
    <Modal title={''} className={styles.premiumModal} closable={false} visible={visible} onCancel={onCloseModal} footer={false} width={460}>
      <div className="settingEmployeeModalFooter">
        <div className={styles.premiumModal_button}>
          <Crown />
          <span>{t('integration.Premium')}</span>
        </div>
        <h2>{t('integration.AmoCRM_integratsiya')}</h2>
        <p>{t('integration.Ushbu_xizmatdan_foydalanish_uchun_yuqori_Premium_tarifni_xarid_qilishingiz_zarur')}</p>
        <div onClick={onCloseModal} className={styles.premiumModal_close}>
          <span>{t('integration.Yopish')}</span>
        </div>
        <img alt={'crown'} src={crownImage} />
      </div>
    </Modal>
  );
};

export default PremiumModal;
