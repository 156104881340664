import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AIButton from '@components/ai-button/AIButton';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import AIStarIcon from '@icons/AIStarIcon';
import FilterReloadIcon from '@icons/FilterReloadIcon';
import PhoneOutComingIcon from '@icons/PhoneOutComingIcon';
import { useLeadCallAnalyse, useUpdateRecord } from '@pages/oneLid/services/mutation';
import {
  addLeadFieldAi,
  changeContactName,
  changeContactsFiledValue,
  changeLeadsFiledValue,
  changeLeadsFiledValueList,
  setBalance,
  setLoadingNoteId
} from '@reducers/CrmOneLidSlice';
import { Permissions } from '@utils/constants/permissions';
import { textToNumber } from '@utils/helper/textToNumber';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import PhoneOutGoingIcon from '../../../../../../assets/icons/PhoneOutGoingIcon';
import PlayerPlayIcon from '../../../../../../assets/icons/PlayerPlayIcon';
import ReceiveSquareIcon from '../../../../../../assets/icons/ReceiveSquareIcon';
import CustomPlayer from '../../../../../../components/customPlayer/CustomPlayer';
import { useDownloadFilesByLink } from '../../../../../../queries/mutation';
import i18n from '../../../../../../service/I18nService';
import { LEAD_EVENT_TYPE, LeadEventsAndTasksList, OneEvent, OneEventCallDescription } from '../../../../../../utils/models/LeadEventsAndTaskList';

import styles from './recordingPhone.module.scss';

interface Props {
  data: OneEvent;
  dataAll: LeadEventsAndTasksList[] | undefined;
}

const hasKey = {
  name: ['Ismi', 'Имя'],
  balance: ['Mijoz byudjeti', 'Бюджет клиента']
};

const RecordingPhone: React.FC<Props> = ({ data, dataAll }) => {
  const [loading, setLoading] = useState(false);
  const [activeDesc, setActiveDesc] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const params = useParams();
  const updateRecordMutate = useUpdateRecord();
  const lengthDivide = (data?.descriptionCall?.length || 0) / 4;
  const leadCallAnalyseMutate = useLeadCallAnalyse({ leadId: data?.leadId });
  const dispatch = useAppDispatch();
  const [showPlayer, setShowPlayer] = useState(false);
  const { user } = useAppSelector(state => state.userReducer);
  const { loadingNoteIds, leadFieldValue, contacts } = useAppSelector(state => state.crmOneLidField);

  const isCallAnalayse = user?.role?.permissions?.includes(Permissions.PERMISSION_CALL_ANALYSE);

  const leadFieldValueObj = leadFieldValue.reduce<Record<string, any>>((p, c) => {
    p[c.id] = c.value;

    return p;
  }, {});

  const contactFiedObj = contacts
    ?.map(item => item.fields || [])
    .flat(1)
    .reduce<Record<string, any>>((p, c) => {
      p[c.id] = c.value;

      return p;
    }, {});

  const { t } = useTranslation();

  // handle show player
  const handleShowPlayer = () => {
    setShowPlayer(true);
  };

  const handleGetAbsolutePath = () => {
    if (Array.isArray(data.files) && data.files[0]) return data.files[0].absolutePath;
    return '';
  };

  const handleGetName = () => {
    if (Array.isArray(data.files) && data.files[0]) return data.files[0].originalName;
    return '';
  };

  const download = useDownloadFilesByLink(handleGetAbsolutePath(), handleGetName(), 'mp3');

  // handle download
  const handleDownload = () => {
    download.mutate();
  };

  // translateCompleted
  const translateMainInformation = (time: string, incoming: boolean, to: string, fromNumber: string, fromName?: string, missedCall?: boolean | undefined) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            {time}{' '}
            {
              // eslint-disable-next-line no-nested-ternary
              data.missedCall ? t('home.Otkazib_yuborilgan_qongiroq') : incoming ? t('my_calls.Kiruvchi_qongiroq') : t('my_calls.Chiquvchi_qongiroq')
            }
            : от {data?.fromName || fromNumber} на {data?.toName || data?.toPhoneNumber}
          </>
        );
      case 'en':
        return (
          <>
            {time}{' '}
            {
              // eslint-disable-next-line no-nested-ternary
              data.missedCall ? t('home.Otkazib_yuborilgan_qongiroq') : incoming ? t('my_calls.Kiruvchi_qongiroq') : t('my_calls.Chiquvchi_qongiroq')
            }
            : from {data?.fromName || data?.fromPhoneNumber} to {data?.toName || data?.toPhoneNumber}
          </>
        );
      default:
        return (
          <>
            {time}{' '}
            {
              // eslint-disable-next-line no-nested-ternary
              data.missedCall ? t('home.Otkazib_yuborilgan_qongiroq') : incoming ? t('my_calls.Kiruvchi_qongiroq') : t('my_calls.Chiquvchi_qongiroq')
            }
            : {data?.fromName || data?.fromPhoneNumber} dan {data?.toName || data?.toPhoneNumber} ga
            {/* {time}{' '}
            {incoming
              ? t('my_calls.Kiruvchi_qongiroq')
              : t('my_calls.Chiquvchi_qongiroq')}

            : {fromName} {fromNumber}dan {to} 'ga */}
          </>
        );
    }
  };

  // replace seconds to minute
  const formatTime = (time: number) => {
    const seconds = time % 60;
    const minute = Math.floor(time / 60);
    let minuteString = String(minute);
    let secondsString = String(seconds);

    if (minute < 10) {
      minuteString = `0${minute}`;
    }
    if (seconds < 10) {
      secondsString = `0${seconds}`;
    }
    return `${minuteString}:${secondsString}`;
  };

  const updateRecord = () => {
    if (data?.callId) {
      updateRecordMutate
        .mutateAsync({
          callId: data?.callId,
          noteId: String(data?.id)
        })
        .then(res => {
          // console.log(res, 'res')
        });
    }
  };

  const handleReplace = (props: OneEventCallDescription, notIntoView?: boolean, allSave: boolean = true) => {
    const contactElement = document.querySelector<HTMLDivElement>(`#contact_${data?.contactId} .ant-collapse-header`);

    if (props?.fieldId) {
      if (props?.type === 'CONTACT') {
        if (!notIntoView) {
          if (contactElement?.getAttribute('aria-expanded') !== 'true') {
            contactElement?.click();
          }
          contactElement?.scrollIntoView({ behavior: 'smooth' });
        }

        const saved = () => {
          dispatch(
            changeContactsFiledValue({
              contactId: data?.contactId,
              id: props?.fieldId!,
              value: props?.value,
              key: props?.key,
              ai: true
            })
          );
        };

        if (allSave) {
          saved();
        } else if (!contactFiedObj[props?.fieldId]) {
          saved();
        }
      } else {
        if (!notIntoView) {
          const fieldElement = document.querySelector<HTMLDivElement>(`#field_${props?.fieldId}`);

          fieldElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        const saved = () => {
          if (props?.fieldType === 'MULTI_SELECT') {
            dispatch(changeLeadsFiledValueList({ id: props?.fieldId!, valueList: [props?.value], key: props?.key, ai: true }));
          } else {
            dispatch(
              changeLeadsFiledValue({
                id: props?.fieldId!,
                value: props?.value,
                key: props?.key,
                ai: true
              })
            );
          }
        };

        if (allSave) {
          saved();
        } else if (!leadFieldValueObj[props?.fieldId]) {
          saved();
        }
      }
    }

    if (props?.type === 'AI') {
      if (hasKey.name.includes(props?.key)) {
        if (!notIntoView) {
          contactElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        dispatch(
          changeContactName({
            contactId: data?.contactId,
            value: props?.value,
            ai: true,
            key: 'AI'
          })
        );
      } else if (hasKey.balance.includes(props?.key)) {
        dispatch(setBalance(textToNumber(props?.value)?.toLocaleString('ru') || ''));
        dispatch(addLeadFieldAi(`balance`));
      }
    }
  };

  const handleCallAnalyse = () => {
    const indexOf = dataAll
      ?.map(item => item?.events)
      ?.flat(1)
      ?.filter(item => item?.res?.type === LEAD_EVENT_TYPE.PHONE && !item?.events)
      ?.map(item => item.res)
      ?.indexOf(data);

    if (!data?.analysed) {
      if (data?.descriptionCall && data?.descriptionCall?.length > 0) {
        setActiveDesc(!activeDesc);
        if (!activeDesc) {
          data?.descriptionCall?.forEach(item => {
            handleReplace(item, true, indexOf === 0);
          });
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
          }, lengthDivide * 1000);
        }
      } else {
        dispatch(setLoadingNoteId(data?.id));
        leadCallAnalyseMutate.mutate({
          lang: i18n.language.toUpperCase(),
          leadId: data?.leadId,
          leadNoteId: data?.id
        });
      }
    }
  };

  const handleCallAnalayseAll = () => {
    data?.descriptionCall?.forEach(item => {
      handleReplace(item, true);
    });
  };

  const descActive = data?.descriptionCall && data?.descriptionCall.length > 0;

  useEffect(() => {
    if (loadingNoteIds?.includes(data?.id)) {
      // setActiveDesc(true);
      setLoading(true);
      // setTimeout(() => {
      //   setLoading(false);
      // }, lengthDivide * 1000);
    } else {
      // setActiveDesc(false);
      setLoading(false);
    }

    if (descActive && loading) {
      setActiveDesc(true);
      const indexOf = dataAll
        ?.map(item => item?.events)
        ?.flat(1)
        ?.filter(item => item?.res?.type === LEAD_EVENT_TYPE.PHONE && !item?.events)
        ?.map(item => item.res)
        ?.indexOf(data);

      data?.descriptionCall?.forEach(item => {
        handleReplace(item, true, indexOf === 0);
      });
    }
  }, [loadingNoteIds, data]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.container_icon}>
          {data.missedCall === true ? (
            <PhoneOutComingIcon />
          ) : (
            <div className={styles.container_icon} style={{ border: '1px solid #2196F3' }}>
              <PhoneOutGoingIcon />
            </div>
          )}
        </div>
        <div className={styles.container_body}>
          <p className={styles.container_body_header}>
            {translateMainInformation(data.createdDate, data.incoming, data.toPhoneNumber, data.fromPhoneNumber, data.fromName, data.missedCall)}
          </p>
          <div className={styles.container_body_info}>
            {/* <span className={styles.container_body_info_type}>
            {data.incoming
              ? `O'tkazib yuborilgan qo'ng'iroq`
              : t('my_calls.Chiquvchi_qongiroq')}{' '}
              {formatTime(data.duration || 0)}
            </span> */}
            <span className={styles.container_body_info_type}>
              {
                // eslint-disable-next-line no-nested-ternary
                data.missedCall ? t('home.Otkazib_yuborilgan_qongiroq') : data.incoming ? "Kiruvchi qo'ng'iroq" : t('my_calls.Chiquvchi_qongiroq')
              }{' '}
              {data.missedCall === true ? '' : formatTime(data.duration || 0)}
            </span>
            {data.missedCall === true ? (
              ''
            ) : (
              <div>
                {data?.callId ? (
                  <Button onClick={updateRecord} className={`${styles.audioReload} ${updateRecordMutate.isLoading && styles.audioReload_load}`} size="small">
                    <FilterReloadIcon /> {t('home.Qayta_yuklash')}
                  </Button>
                ) : (
                  <Tooltip overlay={<>{t('home.Audio_yozuvni_qayta_yuklash_imkonsiz')}</>}>
                    <Button className={`${styles.audioReload} ${styles.audioReload_disabled} ${updateRecordMutate.isLoading && styles.audioReload_load}`} size="small">
                      <FilterReloadIcon /> {t('home.Qayta_yuklash')}
                    </Button>
                  </Tooltip>
                )}
              </div>
            )}
            {Array.isArray(data.files) && data.files[0] && (
              <>
                {showPlayer ? (
                  <CustomPlayer isMini={true} url={data.files[0].absolutePath} name={data.files[0].originalName} type="mp3" />
                ) : (
                  <>
                    <div onClick={handleShowPlayer} className={styles.container_body_info_play}>
                      <PlayerPlayIcon />
                      <span> {t('my_calls.Eshitish')}</span>
                    </div>
                    <ReceiveSquareIcon onClick={handleDownload} className={styles.container_body_info_download} />
                  </>
                )}
              </>
            )}{' '}
            {(loading || (activeDesc && descActive)) && (
              <Button size="small" className={styles.analysedBtn} onClick={() => descActive && handleCallAnalayseAll()}>
                <AIStarIcon size={16} /> {/* eslint-disable-next-line no-nested-ternary */}
                {leadCallAnalyseMutate.isLoading ? (
                  <>
                    {t('crm.Jarayon_boshlandi')}
                    <span data-dot>.</span>
                    <span data-dot>.</span>
                    <span data-dot>.</span>
                  </>
                ) : descActive ? (
                  t('crm.Barchasini_qollash')
                ) : (
                  <>
                    {t('crm.Suhbat_tahlil_qilinmoqda')}
                    <span data-dot>.</span>
                    <span data-dot>.</span>
                    <span data-dot>.</span>
                  </>
                )}
              </Button>
            )}
          </div>
          <div className={classNames(styles.description, activeDesc && styles.description_active)} style={{ height: activeDesc ? offsetHeight : 0 }}>
            <div className={styles.description_content} ref={ref => setOffsetHeight(ref?.offsetHeight || 0)}>
              {data?.descriptionCall?.map((item, i) => (
                <div className={styles.description_content_item} key={item?.key} style={{ transitionDelay: activeDesc ? `${i / 10}s` : '' }}>
                  <div className={styles.description_content_item_left}>
                    <span className={styles.description_content_item_key}>
                      {item?.key?.split('').map((key, vIndex) => (
                        <span key={key} style={{ transitionDelay: activeDesc ? `${i / 4 + vIndex / 80}s` : '' }}>
                          {key}
                        </span>
                      ))}
                    </span>
                    <span className={styles.description_content_item_value}>
                      {(hasKey.balance.includes(item?.key) ? textToNumber(item?.value)?.toLocaleString('ru') : item?.value)?.split('').map((value, vIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={vIndex} style={{ transitionDelay: activeDesc ? `${i / 4 + vIndex / 80}s` : '' }}>
                          {value}
                        </span>
                      ))}
                    </span>
                  </div>
                  {(item?.fieldId || hasKey.name.includes(item?.key) || hasKey.balance.includes(item?.key)) && (
                    <div className={styles.description_content_item_replace}>
                      <Button type="primary" onClick={() => handleReplace(item)}>
                        Qo'llash
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classNames(styles.loader, (leadCallAnalyseMutate.isLoading || loading || data?.analysed) && styles.loader_active)}>
          <span />
        </div>
        {isCallAnalayse && data.duration >= 30 && (
          <Tooltip title="Uysot AI">
            <AIButton className={styles.aiBtn} onClick={handleCallAnalyse} loading={leadCallAnalyseMutate.isLoading || loading || data?.analysed} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default RecordingPhone;
