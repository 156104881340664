import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessDenied from '@components/accessDenied/AccessDenied';
import BreadcrumbHeader from '@components/headers/components/BreadcrumbHeader';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import DiscountNavbar from '@components/navbars/discount-navbar/DiscountNavbar';
import * as layout from 'antd/lib/layout/layout';

import { DiscountRoutes } from './discountRoutes';

const DiscountRouter = () => (
  <>
    <MainHeader>
      <BreadcrumbHeader />
      <div className="d-flex align-center">
        <OnlinePbxNotifications />
        <NotificationButtons />
      </div>
    </MainHeader>
    <layout.Content
      style={{
        padding: '1rem 2.68rem',
        overflowY: 'auto',
        height: '50vh'
      }}
    >
      <div className="d-flex  align-start">
        <DiscountNavbar />
        <Routes>
          {DiscountRoutes.map(route => (
            <Route key={route.path} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
          ))}
          <Route path="*" element={<AccessDenied />} />
        </Routes>
      </div>
    </layout.Content>
  </>
);

export default DiscountRouter;
