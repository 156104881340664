import React from 'react';
import styles from './monetaryIntegration.module.scss';
import monetaryIntegration from '@images/monetaryIntegration.png';
import BankIcon from '@icons/BankIcon';
import { useTranslation } from 'react-i18next';

const MonetaryIntegration = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <h1>
          <BankIcon /> Bank integratsiyasi
        </h1>
        <h3>UYSOT bank integratsiyasi bilan moliyaviy hisob-kitoblarni soddalashtiring</h3>
        <ul>
          <li>
            <b>Muammosiz to'lovlar:</b> barcha mijozlaringizdan kelgan to'lovlarni osongina kuzatib boring va qayd eting
          </li>
          <li>
            <b>Oson boshqaruv:</b> toʻlovlar, hisob-fakturalar va mijozlarni bir joyda osongina kuzatib boring
          </li>
          <li>
            <b>Moliyaviy ahvolingizdan xabardor bo'ling:</b> real vaqtda yangilanishlar sizning pul oqimingizdan doimo xabardor bo'lishingizni
            ta'minlaydi.
          </li>
          <li>
            <b>Bank integratsiyasi:</b> muammosiz ishinchli to'lovlar uchun yirik banklar bilan o'zaro integratsiya
          </li>
        </ul>
        <h4>{t('showroom.Bizga_murojaat_qiling')}</h4>
        <a href="tel:+998906186888">+998 90 618 68 88</a>
      </div>
      <div className={styles.right}>
        <img src={monetaryIntegration} alt="" />
      </div>
    </div>
  );
};

export default MonetaryIntegration;
