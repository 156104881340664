/* eslint-disable react/jsx-key */
/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect } from 'react';
import { useGetCurrency } from '@pages/order-id-v2/services/queries';
import { Form, Input, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditKpiPartConst } from '../../../queries/mutation';
import { helper } from '../../../utils/helper/helper';
import { KpiPart } from '../../../utils/models/Kpi';

import styles from '../../../pages/kpi/kpi.module.scss';

interface Props {
  kpiPart?: KpiPart | undefined;
}

const { Option } = Select;

const SimpleSum: React.FC<Props> = ({ kpiPart }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<{ amount: string; currencyId: number }>();
  const { data: dataCurrency } = useGetCurrency();

  const editKpiPart = useEditKpiPartConst();

  // const defaultCurrencyId = dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.id;
  const defaultCurrencyId = kpiPart?.currencyDto?.id;

  useEffect(() => {
    if (kpiPart) {
      form.setFieldsValue({
        amount: kpiPart?.amount.toLocaleString('ru'),
        currencyId: kpiPart?.currencyId || defaultCurrencyId
      });
    }
  }, [kpiPart, form, defaultCurrencyId]);

  const handleUpdate = () => {
    const amount = form.getFieldValue('amount');
    const currencyId = form.getFieldValue('currencyId') || defaultCurrencyId;

    if (amount && currencyId) {
      editKpiPart.mutate({
        amount: helper.parseFloatStringToNumber(amount),
        kpiPartId: kpiPart?.kpiId!,
        currencyId
      });
    }
  };

  return (
    <Spin spinning={editKpiPart.isLoading}>
      <Form form={form} name="SumConst">
        <div className={styles.cont_body_right_simple}>
          <div className={styles.cont_body_right_simple_left}>
            <p>{t('kpi.Odatiy_mukofot_miqdori')}</p>
            <span>
              {kpiPart?.kpiId === 6
                ? t('kpi.Sotilgan_uylar_har_m2_uchun_barcha_mukofotlar_shu_miqdorda_hisoblab_boriladi')
                : t('kpi.Sotilgan_uylar_uchun_barcha_mukofotlar_shu_miqdorda_hisoblab_boriladi')}
            </span>
          </div>
          <div className={styles.cont_body_right_simple_right}>
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject('');
                    }
                    form.setFieldsValue({
                      amount: helper.deleteNotNumbersAndParseFloatNumber(value)?.string
                    });
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input onBlur={handleUpdate} className={`${styles.cont_body_right_simple_right_input} customInput`} />
            </Form.Item>

            <Form.Item name="currencyId" initialValue={defaultCurrencyId} rules={[{ message: '', required: true }]} style={{ marginLeft: 10, fontWeight: 'normal' }}>
              <Select
                className="customSelect"
                style={{ width: 100 }}
                onBlur={handleUpdate}
                placeholder={<span style={{ fontWeight: 'normal', marginLeft: '0px' }}>{kpiPart?.currencyDto?.ccy}</span>}
              >
                {dataCurrency?.map((item: any) => <Option value={item?.id}>{item?.ccy}</Option>)}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default SimpleSum;
