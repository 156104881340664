import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import SwiperNextIcon from '@icons/SwiperNextIcon';
import SwiperPrevIcon from '@icons/SwiperPrevIcon';
import { openFilesHistory } from '@reducers/IOServiceSlice';
import { Empty, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useJobAllFiles } from 'modules/io-service';

import FilesHistoryCard from './FilesHistoryCard';

const FilesHistory = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { filesHistoryVisible } = useAppSelector(state => state.ioServiceSlice);
  const { data, isLoading } = useJobAllFiles({ enabled: filesHistoryVisible, page });
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(openFilesHistory(false));
  };

  const next = () => {
    const nextPage = page + 1;

    if (data) {
      setPage(nextPage >= data?.totalPages ? data?.totalPages : nextPage);
    }
  };

  const prev = () => {
    const nextPage = page - 1;

    if (data) {
      setPage(nextPage <= 1 ? 1 : nextPage);
    }
  };

  useEffect(() => {
    const windowKeyDown = (e: KeyboardEvent) => {
      if (e.shiftKey && e.ctrlKey && e.key === 'D') {
        e.preventDefault();
        dispatch(openFilesHistory(true));
      }
    };

    window.addEventListener('keydown', windowKeyDown);

    return () => {
      window.removeEventListener('keydown', windowKeyDown);
    };
  }, []);

  return (
    <>
      <Modal title={t('file_management.Files')} visible={filesHistoryVisible} onCancel={handleClose} footer={false} width={1224} bodyStyle={{ padding: 0 }}>
        <div className="flex flex-col gap-[16px] px-[24px] py-[16px]">
          <Spin spinning={isLoading}>
            {data?.data && data?.data?.length > 0 ? (
              <div className="grid min-h-[565px] grid-cols-3 grid-rows-5  items-start gap-[16px]">{data?.data.map(item => <FilesHistoryCard key={item?.id} item={item} />)}</div>
            ) : (
              <div className="flex min-h-[565px] items-center justify-center">
                <Empty />
              </div>
            )}
          </Spin>
          <div className="flex items-center justify-center gap-[22px] text-[#344054]">
            <span className="flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[18px] bg-[#F2F4F7]" onClick={prev}>
              <SwiperPrevIcon />
            </span>
            {t('home.Sahifa')} {page} / {data?.totalPages}
            <span className="flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[18px] bg-[#F2F4F7]" onClick={next}>
              <SwiperNextIcon />
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilesHistory;
