import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetSecondPieChartData, useGetThirdPieChartData } from '@pages/bossCrm/services/queries';
import { Empty, Spin } from 'antd';
import { t } from 'i18next';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LeftPieChart from './components/left-pie-chart/LeftPieChart';
import LeftStats from './components/left-stats/LeftStats';
import RightPieChart from './components/right-pie-chart/RightPieChart';
import RightStats from './components/right-stats/RightStats';
import styles from './secondRowBody.module.scss';
const SecondRowBody: React.FC = () => {
  const { t } = useTranslation();
  const { startDate, endDate, tjmIds, buildingIds, customFieldIds } = useContext(ConversionContext);

  const { data: secondPieChartData, isLoading } = useGetThirdPieChartData({
    startDate,
    finishDate: endDate,
    customFieldId: customFieldIds && customFieldIds !== 'tag' ? (customFieldIds as number) : null,
    houses: tjmIds!?.length > 0 ? tjmIds : [],
    buildings: buildingIds!?.length > 0 ? buildingIds : [],
    tag: customFieldIds === 'tag' ? true : null
  });
  const { data: pieChartData, isLoading: secondLoading } = useGetSecondPieChartData(startDate, endDate);

  return (
    <div className={styles.s_row_body}>
      <Spin spinning={secondLoading}>
        <div className={styles.s_row_body_el}>
          <LeftPieChart pieChartData={pieChartData!} />
          <LeftStats pieChartData={pieChartData!} />
        </div>
      </Spin>
      <Spin spinning={isLoading}>
        <div className={styles.s_row_body_el}>
          <RightPieChart pieChartData={secondPieChartData!} />
          <RightStats pieChartData={secondPieChartData!} />
        </div>
      </Spin>
    </div>
  );
};

export default SecondRowBody;
