const Deactive = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.11914 12.5265V14.1532C6.11914 15.2732 6.72581 15.4998 7.46581 14.6598L12.5125 8.92648C13.1325 8.22648 12.8725 7.64648 11.9325 7.64648H11.3125"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M9.8787 6.39359V2.84692C9.8787 1.72692 9.27203 1.50026 8.53203 2.34026L3.48537 8.07359C2.86537 8.77359 3.12537 9.35359 4.06537 9.35359H6.12537V10.1403"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path d="M14.6654 1.83301L1.33203 15.1663" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  );
};

export default Deactive;
