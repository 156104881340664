import React, { memo } from 'react';
import styles from './filterDrawer.module.scss';
import { Checkbox, Dropdown } from 'antd';
import { usePipeStatusesById } from '../../../../../queries/queries';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

interface Props {
  tagIds: string[] | string | undefined;
  voronka: string | undefined;
}

const PipeStatusFilter: React.FC<Props> = ({ tagIds, voronka }) => {
  const { appendMultipleArray, appendSingle, appendMultiple, remove } = useQueryParam();

  const { data: pipeStatus } = usePipeStatusesById(Number(voronka));
  // console.log('PIPE', pipeStatus);

  const { t } = useTranslation();

  // changePipeStatus
  const changePipeStatus = (value: string) => {
    if (Array.isArray(tagIds)) {
      tagIds.includes(value) ? remove('pipeStatusIds', value) : appendMultiple('pipeStatusIds', value);
    } else {
      if (tagIds === value) {
        remove('pipeStatusIds', value);
      } else {
        appendMultiple('pipeStatusIds', value);
      }
    }
  };

  // is has pipe Status
  const isHasPipeStatus = (id: number) => {
    if (!tagIds) {
      return false;
    }
    if (Array.isArray(tagIds)) {
      return tagIds.includes(String(id));
    }
    return tagIds === String(id);
  };

  // handle change all pipe status click
  const handleChangeAll = (checked: boolean) => {
    if (checked) {
      const ids: string[] = [];
      pipeStatus?.forEach(item => ids.push(String(item.id)));
      appendMultipleArray('pipeStatusIds', ids);
    } else {
      appendSingle('pipeStatusIds', undefined);
    }
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_step_dropdown}
      overlay={
        <>
          {pipeStatus?.map((pipe, index) => (
            <div
              onClick={() => changePipeStatus(String(pipe.id))}
              key={index}
              style={{
                // @ts-ignore
                '--color': pipe.color
              }}
              className={styles.container_step_dropdown_item}
            >
              <Checkbox checked={isHasPipeStatus(pipe.id || -1)} className={styles.container_step_dropdown_item_check} />
              <span className={styles.container_step_dropdown_item_name}>{pipe.name}</span>
            </div>
          ))}
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_steps}>
        <Checkbox
          onChange={e => handleChangeAll(e.target.checked)}
          indeterminate={!!tagIds && (!Array.isArray(tagIds) || tagIds.length !== pipeStatus?.length)}
          checked={Array.isArray(tagIds) && tagIds.length === pipeStatus?.length}
          className={styles.container_steps_check}
        />
        <span className={styles.container_steps_title}>{t('crm_filter.Barcha_bosqichlar')}</span>
      </div>
    </Dropdown>
  );
};

export default memo(PipeStatusFilter);
