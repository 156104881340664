/* eslint-disable no-empty */
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../constants/format';

export const validateDateString = (dateString: string, isBigNow?: boolean): string => {
  const dateArr = dateString.split('.');
  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (!isBigNow && dateString.length === 10) {
    const today = dayjs(new Date());
    const date = dayjs(dateString, DATE_FORMAT);

    if (!date.isBefore(today)) {
      return today.format(DATE_FORMAT);
    }
  }

  if (dateArr[2] && Number(dateArr[2]) % 4 === 0) monthLength[1] = 29;

  if (dateArr[1] && Number(dateArr[1]) > 12) dateArr[1] = '12';

  if (dateArr[0] === '29' && dateArr[1] === '02' && dateArr[2]?.length === 4) {
    dateArr[0] = String(monthLength[Number(dateArr[1]) - 1]);
  }

  if (dateArr[0] && dateArr[1]) {
    if (dateArr[0] === '29' && dateArr[1] === '02') {
    } else if (Number(dateArr[0]) > monthLength[Number(dateArr[1]) - 1]) {
      dateArr[0] = String(monthLength[Number(dateArr[1]) - 1]);
    }
  }

  // console.log('DTR', dateArr);

  return dateArr.join('.');
};

export const validateTimeString = (timeString: string): string => {
  const timeArr = timeString.split(':');

  if (timeArr[0] && timeArr[0].length === 2 && Number(timeArr[0]) >= 24) {
    timeArr[0] = '23';
  }

  if (timeArr[1] && timeArr[1].length === 2 && Number(timeArr[1]) >= 60) {
    timeArr[1] = '59';
  }

  return timeArr.join(':');
};
