// import { useTableConfig } from '@queries/queries'
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableConfig } from '@utils/models/TableConfig';

// import { TableKey } from '@utils/models/TableKey'
import { CrmField } from '../../utils/models/CrmField';
import { CrmFieldsInfoType } from '../../utils/models/CrmFieldsType';
import { LeadNotTaskConf, NotTaskPipe } from '../../utils/models/LeadNotTaskConf';

interface CrmFieldState {
  led: CrmField[];
  contact: CrmField[];
  isChangeFields: boolean;
  notChangeStepStatus: boolean;
  autoRemindNotTask: boolean;
  autoRemindTime: number;
  id?: number;
  notTaskPipes: NotTaskPipe[];
  isChangeNotTaskConf: boolean;
  dataByKey: TableConfig[];
}

const initialState: CrmFieldState = {
  led: [],
  contact: [],
  isChangeFields: false,
  isChangeNotTaskConf: false,
  autoRemindNotTask: false,
  autoRemindTime: 3000,
  notChangeStepStatus: false,
  notTaskPipes: [],
  dataByKey: []
};

export const CrmFieldSlice = createSlice({
  name: 'crmFieldState',
  initialState,
  reducers: {
    setCrmFields: (
      state,
      action: {
        payload: {
          data?: CrmField[];
          customdata?: TableConfig[];
        };
      }
    ) => {
      const led: CrmField[] = [];
      const contact: CrmField[] = [];

      for (let i = 0; i < action.payload?.data!?.length; i++) {
        if (action.payload?.data![i]?.infoType === CrmFieldsInfoType.CONTACT) {
          if (action.payload?.customdata?.length! > 0 && action.payload?.customdata![i]?.id === action.payload?.data[i]?.id) {
            contact.push({
              ...(action.payload?.data![i] as CrmField),
              order: i,
              checked: action.payload?.customdata![i].checked,
              fieldStatuses: action.payload.data![i].fieldStatuses
            });
          } else {
            contact.push({
              ...(action.payload?.data![i] as CrmField),
              order: i,
              checked: true
            });
          }
        } else if (action.payload?.customdata?.length! > 0 && action.payload?.customdata![i]?.id === action.payload?.data![i]?.id) {
          led.push({
            ...(action.payload?.data![i] as CrmField),
            order: i,
            checked: action.payload?.customdata![i].checked,
            fieldStatuses: action.payload.data![i].fieldStatuses
          });
        } else {
          led.push({
            ...(action.payload?.data![i] as CrmField),
            order: i,
            checked: true
          });
        }
      }

      state.led = led;
      state.contact = contact;
      state.isChangeFields = false;
    },
    setLedFields: (
      state,
      action: {
        payload: CrmField[];
      }
    ) => {
      state.led = action.payload;
      state.isChangeFields = true;
    },
    setContactFields: (
      state,
      action: {
        payload: CrmField[];
      }
    ) => {
      state.contact = action.payload;
      state.isChangeFields = true;
    },
    setLedFieldsAdd: (
      state,
      action: {
        payload: CrmField;
      }
    ) => {
      state.led = [...state.led, action.payload];
      state.isChangeFields = true;
    },
    setLedFieldsDelete: (
      state,
      action: {
        payload: number | null;
      }
    ) => {
      const led: CrmField[] = [];

      for (let i = 0; i < state.led.length; i++) {
        if (i !== action.payload) {
          led.push(state.led[i]);
        }
      }
      state.led = led;
      state.isChangeFields = true;
    },
    setLedFieldsEdit: (
      state,
      action: {
        payload: {
          index: number;
          field: CrmField;
        };
      }
    ) => {
      const led: CrmField[] = [];

      for (let i = 0; i < state.led.length; i++) {
        if (i === action.payload.index) {
          led.push(action.payload.field);
        } else {
          led.push(state.led[i]);
        }
      }
      state.led = led;
      state.isChangeFields = true;
    },
    setContactFieldsAdd: (
      state,
      action: {
        payload: CrmField;
      }
    ) => {
      state.contact = [...state.contact, action.payload];
      state.isChangeFields = true;
    },
    setContactFieldsDelete: (
      state,
      action: {
        payload: number | null;
      }
    ) => {
      const contact: CrmField[] = [];

      for (let i = 0; i < state.contact.length; i++) {
        if (i !== action.payload) {
          contact.push(state.contact[i]);
        }
      }
      state.contact = contact;
      state.isChangeFields = true;
    },
    setContactFieldsEdit: (
      state,
      action: {
        payload: {
          index: number;
          field: CrmField;
        };
      }
    ) => {
      const contact: CrmField[] = [];

      for (let i = 0; i < state.contact.length; i++) {
        if (i === action.payload.index) {
          contact.push(action.payload.field);
        } else {
          contact.push(state.contact[i]);
        }
      }
      state.contact = contact;
      state.isChangeFields = true;
    },
    setLeadNotTaskConf: (state, action: PayloadAction<LeadNotTaskConf | null>) => {
      state.isChangeNotTaskConf = false;
      state.id = action.payload?.id;
      state.notChangeStepStatus = action.payload?.notChangeStepStatus || false;
      state.notTaskPipes = action.payload?.notTaskPipes || [];
      state.autoRemindNotTask = action.payload?.autoRemindNotTask || false;
      state.autoRemindTime = action.payload?.autoRemindTime || 300;
    },
    setNotChangeStepStatus: (state, action: PayloadAction<boolean>) => {
      state.notChangeStepStatus = action.payload;
      state.isChangeNotTaskConf = true;
    },
    setAutoRemindNotTask: (state, action: PayloadAction<boolean>) => {
      state.autoRemindNotTask = action.payload;
      state.isChangeNotTaskConf = true;
    },
    setAutoRemindTime: (state, action: PayloadAction<number>) => {
      state.autoRemindTime = action.payload;
      state.isChangeNotTaskConf = true;
    },
    setNotTaskPipes: (state, action: PayloadAction<NotTaskPipe[]>) => {
      state.notTaskPipes = action.payload;
      state.isChangeNotTaskConf = true;
    },
    setCheckedLead: (state, action: PayloadAction<{ checked: boolean; id: number }>) => {
      const updatedLead = state.led.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            checked: action.payload.checked
          };
        }
        return {
          ...item
        };
      });

      state.led = updatedLead;
    },
    setCheckedContact: (state, action: PayloadAction<{ checked: boolean; name: string }>) => {
      const updatedContact = state.contact.map(item => {
        if (item.name === action.payload.name) {
          return {
            ...item,
            checked: action.payload.checked
          };
        }
        return {
          ...item
        };
      });

      state.contact = updatedContact;
    },
    updateLedOrder(state, action: PayloadAction<CrmField[]>) {
      const updatedLead = action.payload?.map((item, i) => ({
        ...item,
        order: i
      }));

      state.led = updatedLead;
    },
    updateContactOrder(state, action: PayloadAction<CrmField[]>) {
      const updatedContact = action.payload?.map((item, i) => ({
        ...item,
        order: i
      }));

      state.contact = updatedContact;
    },
    setDataByKey: (state, action: PayloadAction<TableConfig[]>) => {
      state.dataByKey = action.payload;
    }
  }
});

export const {
  setCrmFields,
  setContactFieldsDelete,
  setContactFieldsEdit,
  setLedFields,
  setContactFields,
  setLedFieldsAdd,
  setContactFieldsAdd,
  setLedFieldsEdit,
  setLedFieldsDelete,
  setAutoRemindNotTask,
  setNotChangeStepStatus,
  setLeadNotTaskConf,
  setNotTaskPipes,
  setAutoRemindTime,
  setCheckedLead,
  setCheckedContact,
  updateLedOrder,
  updateContactOrder,
  setDataByKey
} = CrmFieldSlice.actions;
export default CrmFieldSlice.reducer;
