import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { DuplicateControlsType } from '../utils/models/DuplicateControlsType';

export function useGetDuplicateControls() {
  return useQuery<DuplicateControlsType>({
    queryKey: [queryKeys.DUPLICATE_CONTROL],
    queryFn: async () => {
      const res = await $api.get(endPoints.DUPLICATE_CONTROL);

      return res.data.data;
    },
    refetchOnWindowFocus: false,
    retry: false
  });
}
