import React, { FC, useEffect, useState, useRef } from 'react';
import styles from './universalModal.module.scss';

interface IProps {
  visible: boolean;
  children: React.ReactNode;
  onCancel: () => void;
  maskClassName?: string;
  className?: string;
  contentClassName?: string;
  title: JSX.Element;
  top?: number;
}

const UniversalModal: FC<IProps> = ({ visible, children, onCancel, maskClassName = '', className = '', title, contentClassName = '', top = 100 }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({
    isDragging: false,
    x: 0,
    y: top,
    startX: 0,
    startY: 0
  });

  useEffect(() => {
    setPosition(prev => ({
      ...prev,
      x: window?.innerWidth / 2 - (contentRef.current?.offsetWidth || 0) / 2,
      y: top
    }));
  }, [top]);

  // mouseDown
  const mouseDown = (e: any) => {
    e.stopPropagation();
    setPosition(prev => ({
      ...prev,
      isDragging: true,
      startX: e.nativeEvent.layerX,
      startY: e.nativeEvent.layerY
    }));
  };

  useEffect(() => {
    // mouseMove
    const mouseMove = (e: any) => {
      e.stopPropagation();
      if (visible && position.isDragging) {
        let clientY = e.clientY - position.startY;
        let clientX = e.clientX - position.startX;
        let y = clientY <= 0 ? 0 : clientY;
        let x = clientX <= 0 ? 0 : clientX;
        setPosition(prev => ({
          ...prev,
          x: x,
          y: y
        }));
      }
    };

    // mouseUp
    const mouseUp = (e: any) => {
      e.stopPropagation();
      if (visible) {
        setPosition(prev => ({
          ...prev,
          isDragging: false,
          startX: 0,
          startY: 0
        }));
      }
    };

    // windowKeyUp
    const windowKeyUp = (e: KeyboardEvent) => {
      if (visible) {
        if (e.ctrlKey && e.key === 'c') {
          setPosition(prev => ({
            ...prev,
            x: window?.innerWidth / 2 - (contentRef.current?.offsetWidth || 0) / 2,
            y: top
          }));
        }
        if (e.key === 'Escape') {
          onCancel();
        }
        if (e.ctrlKey && e.key === 'ArrowLeft') {
          setPosition(prev => ({
            ...prev,
            x: 0
          }));
        }

        if (e.ctrlKey && e.key === 'ArrowRight') {
          setPosition(prev => ({
            ...prev,
            x: window.innerWidth - (contentRef.current?.offsetWidth || 0)
          }));
        }

        if (e.ctrlKey && e.key === 'ArrowUp') {
          setPosition(prev => ({
            ...prev,
            y: 0
          }));
        }

        if (e.ctrlKey && e.key === 'ArrowDown') {
          setPosition(prev => ({
            ...prev,
            y: window.innerHeight - (contentRef.current?.offsetHeight || 0)
          }));
        }
      }
    };

    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('mouseup', mouseUp);
    window.addEventListener('keyup', windowKeyUp);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', mouseUp);
      window.removeEventListener('keyup', windowKeyUp);
    };
  }, [position, top, onCancel, visible]);

  return (
    <div className={`${styles.container} ${visible ? styles.container_active : ''} ${className}`}>
      <div className={`${styles.container_mask} ${maskClassName}`} onClick={onCancel}></div>
      <div className={`${styles.container_content} ${contentClassName}`} style={{ top: position.y, left: position.x }} ref={contentRef}>
        <div className={styles.container_title} onMouseDown={mouseDown}>
          {title}
        </div>
        {children}
      </div>
    </div>
  );
};

export default UniversalModal;
