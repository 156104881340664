import { CrmSettingsPath } from '@routing/crm-settings/CrmSettingsPath';

import { CompanyConfigPaths } from '../../routing/companyConfig/companyConfigPaths';
import { ImportExportPaths } from '../../routing/importExport/importExportPaths';
import { IntegrationPaths } from '../../routing/integration/integrationPaths';
import { InterfaceConfigPaths } from '../../routing/interfaceConfig/InterfaceConfigPaths';
import { MainPaths } from '../../routing/main/mainPaths';
import { messagePath } from '../../routing/messageConfigRoutes/MessagePaths';
import { PaymentSettingsPath } from '../../routing/paymentSettings/PaymentSettingsPath';
import { rootPaths } from '../../routing/rootPaths';
import { SettingsPaths } from '../../routing/settings/settingsPaths';
import { LocalStorage } from '../../service/LocalStorage';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { User } from '../../utils/models/User';

import active_logs_img from '../../assets/img/active_logs_img.png';
import BuilderImage from '../../assets/img/builder_image.png';
import crm_settings_image from '../../assets/img/crmSettings.png';
import DiscauntImage from '../../assets/img/discaunt_image.png';
import FilialLogo from '../../assets/img/filialLogo.png';
import ImportImage from '../../assets/img/importExport.png';
import IntegrationImage from '../../assets/img/integration_image.png';
import InterfaceImage from '../../assets/img/interface_image.png';
import KompanySettingImage from '../../assets/img/kompany_setting_image.png';
import KpiImage from '../../assets/img/kpiImage.png';
import PaymentImage from '../../assets/img/payment.png';
import RobotoCall from '../../assets/img/roboto_call.png';
import security_image from '../../assets/img/security_image.png';
import SmsSettingImage from '../../assets/img/sms_setting_image.png';
import StaffImage from '../../assets/img/staff_image.png';
import TjmCreateImage from '../../assets/img/tjm_create_image.png';

const user = LocalStorage.get<User>(USER_KEY);

const fullSettingLink = [
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.HOUSE_CONFIG,
    image: TjmCreateImage,
    title: 'Turar_joy_majmualari',
    name: 'TJM_yaratish_va_tahrirlash',
    permission: Permissions.PERMISSION_SETTINGS_HOUSE,
    description: 'Bino_yaratish_xonadon_yaratish_va_tahrirlash_ishlarini_olib_borish_narxlar_kiritish_xonadon_statuslarini_sozlash'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.MESSAGE + messagePath.SENDING_MESSAGE,
    image: SmsSettingImage,
    title: 'SMS',
    name: 'Xabar_sozlamalari',
    permission: Permissions.PERMISSION_SETTINGS_MESSAGE,
    description: 'Mijozlar_bilan_aloqa_qilish_SMS_xabarlar_yuborish_SMS_xabarnomalarni_yaratish_va_tahrirlash'
  },
  {
    to: `${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.COMPANY_CONFIG + CompanyConfigPaths.TEMPLATES}?tab=contract`,
    image: KompanySettingImage,
    title: 'Kompaniya',
    name: 'Kompaniya_sozlamalari',
    permission: Permissions.PERMISSION_SETTINGS_COMPANY,
    description: 'Shartnoma_namunalari_va_ularning_sozlamalari_Shartnomalar_va_tolovlar_raqamlashni_generatsiya_qilish_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.DISCOUNT,
    image: DiscauntImage,
    title: 'Shartnoma',
    name: 'Chegirma',
    permission: Permissions.PERMISSION_SETTINGS_DISCOUNT,
    description: 'Amaldagi_chegirmalarni_tahrirlash_va_boshqarish_yangi_chegirmalar_yaratish_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTERFACE + InterfaceConfigPaths.INTERFACE_CONFIG,
    image: InterfaceImage,
    title: 'Dastur',
    name: 'Interfeys_sozlamalari',
    description: 'Korxonaning_showroom_bolimi_interfeys_sozlamalari_rang_va_til_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.BUILDER,
    image: BuilderImage,
    title: 'Kompaniya',
    name: 'Quruvchi',
    permission: Permissions.PERMISSION_SETTINGS_BUILDER,
    description: 'Quruvchi_firmalar_yaratish_va_ularni_tahrirlash_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EMPLOYEE,
    image: StaffImage,
    title: 'Kompaniya',
    name: 'Xodimlar',
    permission: Permissions.PERMISSION_SETTINGS_EMPLOYEE,
    description: 'Korxona_xodimlarini_yaratish_va_ularni_tahrirlash_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.BACKUP + ImportExportPaths.IMPORT,
    image: ImportImage,
    title: 'Kompaniya',
    name: 'Import_Export',
    permission: Permissions.PERMISSION_SETTINGS_TRANSFER_DATA,
    description: 'Malumotlar_bazasini_yuklab_olish_va_kiritish_imkoniyati'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.BRANCH,
    image: FilialLogo,
    title: 'Kompaniya',
    name: 'Filial',
    permission: Permissions.PERMISSION_SETTINGS_BRANCH,
    description: 'Korxona_filiallarini_yaratish_va_ularni_tahrirlash_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.PAYMENT + PaymentSettingsPath.REMOTE_PAYMENT,
    image: PaymentImage,
    title: 'Kompaniya',
    name: 'Tolov_sozlamalari',
    permission: Permissions.PERMISSION_SETTINGS_PAYMENT,
    description: 'Korxona_barcha_tolov_va_unga_bogliq_sozlamalari'
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTEGRATION + IntegrationPaths.INDEX,
    image: IntegrationImage,
    title: 'Kompaniya',
    name: 'Integratsiya',
    description: 'Dasturning_yordamchi_dasturlarini_boshqarish_va_sozlash',
    permission: Permissions.PERMISSION_INTEGRATION
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.KPI,
    image: KpiImage,
    title: 'Kompaniya',
    name: 'KPI',
    description: 'Xodimlar_va_vositachilarning_ish_samaradorligi_evaziga_ularni_ragbatlantirish_hisob_kitoblari_sozlamalari',
    permission: Permissions.PERMISSION_SETTINGS_KPI
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST,
    image: active_logs_img,
    title: 'Loglar',
    name: 'Voqealar_royhati',
    description: 'Tizimda_bajarilgan_barcha',
    permission: Permissions.PERMISSION_ACTIVITY_LOGS
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.SECURITY,
    image: security_image,
    title: 'Xavfsizlik',
    name: 'Xavfsizlik',
    description: 'Tizim_xavfsizlik_sozlamalari',
    permission: Permissions.PERMISSION_COMPANY_ROLE
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + CrmSettingsPath.CRM_SETTINGS,
    image: crm_settings_image,
    title: 'Xavfsizlik',
    name: 'CRM_sozlamalari',
    description: 'Tizim_xavfsizlik_sozlamalari',
    permission: Permissions.PERMISSION_SETTINGS_CRM
  },
  {
    to: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.CALLS,
    image: RobotoCall,
    title: 'Qongiroqlar',
    name: 'Qongiroqlar',
    description: 'Tizim_xavfsizlik_sozlamalari',
    permission: Permissions.PERMISSION_ROBOT_CALL
  }
];

const settingLink: typeof fullSettingLink = [];

if (typeof user === 'object' && user) {
  fullSettingLink.forEach(link => {
    if (link.permission) {
      if (user.role.permissions.includes(link.permission)) {
        settingLink.push(link);
      }
    } else {
      settingLink.push(link);
    }
  });
}

export default settingLink;
