export interface SearchParamsTask {
  page: number;
  size: number;
  startDate: string;
  finishDate: string;
  employeeList: string[] | undefined;
  leadTaskTypeIds: string[] | undefined;
  createdByIds: string[] | undefined;
  pipeStatusIds: string[] | undefined;
  leadTaskStatusFilter: LeadTaskStatusFilter;
  leadTaskTypeFilter: LeadTaskTypeFilter;
  date: string;
}

export enum LeadTaskTypeFilter {
  ALL = 'All',
  LEAD = 'LEAD',
  CONTRACT = 'CONTRACT'
}

export enum LeadTaskStatusFilter {
  DEFAULT = 'DEFAULT',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  MISSED = 'MISSED',
  ALL = 'ALL'
}
