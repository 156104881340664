import React from 'react';

const Nike = () => {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.36426 4.50015L4.78384 7.91973L11.6351 1.08057" stroke="#00A389" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default Nike;
