import React from 'react';

const TrushSquareGreenIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8085 3.59469C12.2928 3.34081 9.75404 3.21387 7.22679 3.21387C5.7266 3.21387 4.22641 3.29464 2.73775 3.44466L1.19141 3.59469"
        stroke="#374151"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.35742 2.83427L5.51898 1.84183C5.63438 1.12636 5.72669 0.583984 7.00763 0.583984H8.9925C10.2734 0.583984 10.3657 1.14944 10.4811 1.84183L10.6427 2.82274"
        stroke="#374151"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1813 3.68848L12.6851 11.3048C12.6043 12.4934 12.5351 13.4166 10.4233 13.4166H5.56493C3.45312 13.4166 3.38387 12.4934 3.30309 11.3048L2.80688 3.68848"
        stroke="#374151"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrushSquareGreenIcon;
