import React from 'react';

const MecanicKeyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4198 5.08725C10.1558 4.82324 10.0237 4.69124 9.97429 4.53902C9.93078 4.40512 9.93078 4.26089 9.97429 4.127C10.0237 3.97478 10.1558 3.84277 10.4198 3.57876L12.312 1.68651C11.8099 1.45942 11.2525 1.33301 10.6655 1.33301C8.45638 1.33301 6.66552 3.12387 6.66552 5.33301C6.66552 5.66037 6.70484 5.97854 6.77902 6.28307C6.85846 6.60917 6.89818 6.77222 6.89113 6.87523C6.88375 6.98307 6.86767 7.04044 6.81794 7.13642C6.77043 7.22809 6.67942 7.3191 6.49739 7.50113L2.33218 11.6663C1.7799 12.2186 1.7799 13.1141 2.33218 13.6663C2.88447 14.2186 3.7799 14.2186 4.33218 13.6663L8.49739 9.50113C8.67942 9.3191 8.77043 9.22809 8.86211 9.18059C8.95808 9.13085 9.01546 9.11478 9.1233 9.10739C9.2263 9.10034 9.38936 9.14006 9.71546 9.2195C10.02 9.29368 10.3382 9.33301 10.6655 9.33301C12.8747 9.33301 14.6655 7.54215 14.6655 5.33301C14.6655 4.74607 14.5391 4.18866 14.312 3.68651L12.4198 5.57876C12.1558 5.84277 12.0237 5.97478 11.8715 6.02424C11.7376 6.06774 11.5934 6.06774 11.4595 6.02424C11.3073 5.97478 11.1753 5.84277 10.9113 5.57876L10.4198 5.08725Z"
        stroke="#464255"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MecanicKeyIcon;
