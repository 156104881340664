import React, { FC, useEffect } from 'react';
import { Form, Switch } from 'antd';
import { t } from 'i18next';

import { useGetDuplicateControls } from './service/queries';
import { DuplicateControlsType, MessengersOBJType } from './utils/models/DuplicateControlsType';

import amocrm from '../../../../../assets/img/messengers/amocrm.png';
import facebook from '../../../../../assets/img/messengers/facebook.png';
import instagram from '../../../../../assets/img/messengers/instagram.png';
import marketplace from '../../../../../assets/img/messengers/marketplace.png';
import other from '../../../../../assets/img/messengers/other.png';
import webform from '../../../../../assets/img/messengers/webform.png';

import styles from './dublikatControll.module.scss';

const messengersOBJ: { [key: string]: MessengersOBJType } = {
  INSTAGRAM: {
    img: instagram,
    name: 'Instagram',
    type: 'Messenger',
    from: 'fromInstagram',
    status: true
  },
  FACEBOOK: {
    img: facebook,
    name: 'Facebook',
    type: 'Messenger',
    from: 'fromFaceBook',
    status: true
  },
  OTHERS: {
    img: other,
    name: 'Facebook form',
    type: 'Facebook ads',
    from: 'fromFaceBookForm',
    status: false
  },
  WEBFORM: {
    img: webform,
    name: 'Webform',
    type: 'Website',
    from: 'fromWebForm',
    status: true
  },
  MARKETPLACE: {
    img: marketplace,
    name: 'Marketplace',
    type: 'Website',
    from: 'fromMarketplace',
    status: true
  },
  WEBSITE: {
    img: amocrm,
    name: 'Website',
    type: 'Website',
    from: 'fromWebsite',
    status: false
  }
};

interface IProps {
  setSwitchStates: React.Dispatch<React.SetStateAction<{}>>;
  switchStates: {};
}

const DublikatControllMessengers: FC<IProps> = ({ setSwitchStates, switchStates }) => {
  const { data: dataDuplicateControl } = useGetDuplicateControls();

  useEffect(() => {
    if (dataDuplicateControl) {
      const initialStates = Object.keys(messengersOBJ).reduce(
        (acc, key) => {
          const fromKey = messengersOBJ[key].from as keyof DuplicateControlsType;

          acc[fromKey] = dataDuplicateControl[fromKey] || false;
          return acc;
        },
        {} as { [key in keyof DuplicateControlsType]?: boolean }
      );

      setSwitchStates(initialStates);
    }
  }, [dataDuplicateControl]);

  const handleSwitchChange = (from: string, checked: boolean) => {
    setSwitchStates(prevStates => ({
      ...prevStates,
      [from]: checked
    }));
  };

  return (
    <div>
      <div className={styles.dublikatControllMessengers_body}>
        <p>{t('home.Qaysi_yetakchi_manbaalarda_dublikat_nazorati_amal_qilishini_tanlang')}</p>
        <p>{t('home.Tanlanmagan_manbaalarda_oxshash_lidlar_bolsham_yangi_lid_yaratiladi')}</p>
        <div className={styles.dublikatControllMessengers_body_content}>
          {Object.values(messengersOBJ).map(item => (
            <Form.Item name={item.from} key={item.name} valuePropName="checked">
              <div className={styles.dublikatControllMessengers_body_content_item}>
                <div className={styles.dublikatControllMessengers_body_content_item_left}>
                  <img src={item?.img} alt="" width={40} height={40} />
                  <div>
                    <h3>{item?.name}</h3>
                    <p>{item?.type}</p>
                  </div>
                </div>
                <div className={styles.dublikatControllMessengers_body_content_item_right}>
                  {/* @ts-expect-error */}
                  <Switch size="small" checked={switchStates[item.from]} onChange={checked => handleSwitchChange(item.from, checked)} disabled={item?.status} />
                </div>
              </div>
            </Form.Item>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DublikatControllMessengers;
