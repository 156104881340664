import React from 'react';

const ClientStatus = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9987 11.1654C7.73203 11.1654 7.46536 11.1454 7.21203 11.0987C5.7987 10.892 4.51203 10.0787 3.6987 8.87203C3.13203 8.0187 2.83203 7.02536 2.83203 5.9987C2.83203 3.15203 5.15203 0.832031 7.9987 0.832031C10.8454 0.832031 13.1654 3.15203 13.1654 5.9987C13.1654 7.02536 12.8654 8.0187 12.2987 8.87203C11.4787 10.0854 10.192 10.892 8.76536 11.1054C8.53203 11.1454 8.26536 11.1654 7.9987 11.1654ZM7.9987 1.83203C5.6987 1.83203 3.83203 3.6987 3.83203 5.9987C3.83203 6.83203 4.07203 7.63203 4.52536 8.31203C5.18536 9.28537 6.2187 9.9387 7.36537 10.1054C7.79203 10.1787 8.21203 10.1787 8.60536 10.1054C9.77203 9.9387 10.8054 9.27869 11.4654 8.30536C11.9187 7.62536 12.1587 6.82536 12.1587 5.99202C12.1654 3.69869 10.2987 1.83203 7.9987 1.83203Z"
      fill="#292D32"
    />
    <path
      d="M4.31289 15.0613C4.21955 15.0613 4.13289 15.048 4.03955 15.028C3.60622 14.928 3.27289 14.5946 3.17289 14.1613L2.93955 13.1813C2.92622 13.1213 2.87955 13.0746 2.81289 13.0546L1.71289 12.7946C1.29955 12.6946 0.972886 12.3879 0.859553 11.9813C0.74622 11.5746 0.859553 11.1346 1.15955 10.8346L3.75955 8.23462C3.86622 8.12795 4.01289 8.07462 4.15955 8.08795C4.30622 8.10128 4.43955 8.18128 4.52622 8.30794C5.18622 9.28128 6.21955 9.94128 7.37289 10.1079C7.79955 10.1813 8.21955 10.1813 8.61289 10.1079C9.77955 9.94128 10.8129 9.28128 11.4729 8.30794C11.5529 8.18128 11.6929 8.10128 11.8396 8.08795C11.9862 8.07462 12.1329 8.12795 12.2396 8.23462L14.8396 10.8346C15.1396 11.1346 15.2529 11.5746 15.1396 11.9813C15.0262 12.3879 14.6929 12.7013 14.2862 12.7946L13.1862 13.0546C13.1262 13.0679 13.0796 13.1146 13.0596 13.1813L12.8262 14.1613C12.7262 14.5946 12.3929 14.928 11.9596 15.028C11.5262 15.1346 11.0796 14.9813 10.7996 14.6413L7.99955 11.4213L5.19955 14.6479C4.97289 14.9146 4.65289 15.0613 4.31289 15.0613ZM4.05955 9.35461L1.86622 11.548C1.80622 11.608 1.81289 11.6746 1.82622 11.7146C1.83289 11.748 1.86622 11.8146 1.94622 11.8279L3.04622 12.088C3.47955 12.188 3.81289 12.5213 3.91289 12.9546L4.14622 13.9346C4.16622 14.0213 4.23289 14.0479 4.27289 14.0613C4.31289 14.0679 4.37955 14.0746 4.43955 14.008L6.99289 11.068C5.85955 10.848 4.81955 10.2413 4.05955 9.35461ZM9.00622 11.0613L11.5596 13.9946C11.6196 14.0679 11.6929 14.0679 11.7329 14.0546C11.7729 14.0479 11.8329 14.0146 11.8596 13.928L12.0929 12.948C12.1929 12.5146 12.5262 12.1813 12.9596 12.0813L14.0596 11.8213C14.1396 11.8013 14.1729 11.7413 14.1796 11.7079C14.1929 11.6746 14.1996 11.6013 14.1396 11.5413L11.9462 9.34794C11.1796 10.2346 10.1462 10.8413 9.00622 11.0613Z"
      fill="#292D32"
    />
    <path
      d="M9.25944 8.59351C9.08611 8.59351 8.87944 8.54683 8.63278 8.40017L7.99944 8.02016L7.36611 8.39349C6.78611 8.74016 6.40611 8.54017 6.26611 8.44017C6.12611 8.34017 5.82611 8.04017 5.97944 7.38017L6.13944 6.6935L5.60611 6.20015C5.31278 5.90682 5.20611 5.5535 5.30611 5.2335C5.40611 4.9135 5.69944 4.68682 6.11278 4.62016L6.82611 4.50016L7.16611 3.7535C7.35944 3.3735 7.65944 3.16016 7.99944 3.16016C8.33944 3.16016 8.64611 3.38017 8.83278 3.76017L9.22611 4.54683L9.88611 4.62683C10.2928 4.6935 10.5861 4.92015 10.6928 5.24015C10.7928 5.56015 10.6861 5.91349 10.3928 6.20683L9.83944 6.76017L10.0128 7.38017C10.1661 8.04017 9.86611 8.34017 9.72611 8.44017C9.65278 8.50017 9.49278 8.59351 9.25944 8.59351ZM6.40611 5.59351L6.86611 6.05349C7.07944 6.26682 7.18611 6.62683 7.11944 6.92017L6.99278 7.45349L7.52611 7.14016C7.81278 6.97349 8.19944 6.97349 8.47944 7.14016L9.01278 7.45349L8.89278 6.92017C8.82611 6.62017 8.92611 6.26682 9.13944 6.05349L9.59944 5.59351L9.01944 5.49349C8.73944 5.44682 8.45944 5.24017 8.33278 4.98684L7.99944 4.3335L7.66611 5.00016C7.54611 5.24683 7.26611 5.46017 6.98611 5.50684L6.40611 5.59351Z"
      fill="#292D32"
    />
  </svg>
);

export default ClientStatus;
