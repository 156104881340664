import { $api } from "@service/RequestService";
import { useMutation, useQueryClient } from "react-query";

import { queryKeys } from "../constants";

export const useJobCancelProgress = () => {
    const qc = useQueryClient();

    return useMutation({
        mutationFn: async (fileId?: number) => {
            const res = await $api.post(`/v1/job/cancel-progress/${fileId}`)
    
            return res.data.data
        },
        onSuccess: async () => {
            await qc.invalidateQueries([queryKeys.JOB_PROGRESS_FILES])
        },
    })
}