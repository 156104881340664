import React from 'react';

const PaperClipIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0003 5.00072L4.79402 10.2945C4.62166 10.4847 4.52905 10.7339 4.53537 10.9905C4.54169 11.2471 4.64645 11.4915 4.82796 11.673C5.00947 11.8545 5.25384 11.9593 5.51046 11.9656C5.76708 11.9719 6.0163 11.8793 6.20652 11.707L12.4128 5.41322C12.7575 5.03277 12.9427 4.53432 12.9301 4.02109C12.9174 3.50785 12.7079 3.01912 12.3449 2.6561C11.9819 2.29308 11.4931 2.08356 10.9799 2.07092C10.4667 2.05828 9.96822 2.2435 9.58777 2.58822L3.38152 8.88197C2.81959 9.44389 2.50391 10.206 2.50391 11.0007C2.50391 11.7954 2.81959 12.5575 3.38152 13.1195C3.94345 13.6814 4.70559 13.9971 5.50027 13.9971C6.29496 13.9971 7.05709 13.6814 7.61902 13.1195L12.7503 8.00072"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PaperClipIcon;
