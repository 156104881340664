import React from 'react';

const DangerBackgroundWhiteIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1333 13.2667L12.8 3.66667C12.0833 2.375 11.0916 1.66667 9.99998 1.66667C8.90831 1.66667 7.91665 2.375 7.19998 3.66667L1.86665 13.2667C1.19165 14.4917 1.11665 15.6667 1.65831 16.5917C2.19998 17.5167 3.26665 18.025 4.66665 18.025H15.3333C16.7333 18.025 17.8 17.5167 18.3416 16.5917C18.8833 15.6667 18.8083 14.4833 18.1333 13.2667ZM9.37498 7.5C9.37498 7.15833 9.65831 6.875 9.99998 6.875C10.3416 6.875 10.625 7.15833 10.625 7.5V11.6667C10.625 12.0083 10.3416 12.2917 9.99998 12.2917C9.65831 12.2917 9.37498 12.0083 9.37498 11.6667V7.5ZM10.5916 14.7583C10.55 14.7917 10.5083 14.825 10.4666 14.8583C10.4166 14.8917 10.3666 14.9167 10.3166 14.9333C10.2666 14.9583 10.2166 14.975 10.1583 14.9833C10.1083 14.9917 10.05 15 9.99998 15C9.94998 15 9.89165 14.9917 9.83331 14.9833C9.78331 14.975 9.73331 14.9583 9.68331 14.9333C9.63331 14.9167 9.58331 14.8917 9.53331 14.8583C9.49165 14.825 9.44998 14.7917 9.40831 14.7583C9.25831 14.6 9.16665 14.3833 9.16665 14.1667C9.16665 13.95 9.25831 13.7333 9.40831 13.575C9.44998 13.5417 9.49165 13.5083 9.53331 13.475C9.58331 13.4417 9.63331 13.4167 9.68331 13.4C9.73331 13.375 9.78331 13.3583 9.83331 13.35C9.94165 13.325 10.0583 13.325 10.1583 13.35C10.2166 13.3583 10.2666 13.375 10.3166 13.4C10.3666 13.4167 10.4166 13.4417 10.4666 13.475C10.5083 13.5083 10.55 13.5417 10.5916 13.575C10.7416 13.7333 10.8333 13.95 10.8333 14.1667C10.8333 14.3833 10.7416 14.6 10.5916 14.7583Z"
        fill="white"
      />
    </svg>
  );
};

export default DangerBackgroundWhiteIcon;
