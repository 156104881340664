import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { OneCModel } from '../utils/models/OneCModel';
import { queryKeys } from '../utils/constants/queryKeys';
import { message } from 'antd';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';

export function usePostOneCIntegration() {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: OneCModel) => {
      const res = await $api.post(endPoints.ONE_C, data);
      return res.data;
    },
    onSuccess: res => {
      qc.invalidateQueries([queryKeys.ONE_C]);
      showMessage(res.message, res.accept);
    },
    onError: baseErrorHandler
  });
}
