import React from 'react';
import styles from './amoCrm.module.scss';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { AmoCrm } from '../../../utils/models/AmoCrm';
import { useChangeStatusNoteAmoCrm, useChangeStatusUrlAmoCrm } from '../../../queries/mutation';

interface Props {
  amoCrm: AmoCrm;
}

const ExtraInformation: React.FC<Props> = ({ amoCrm }) => {
  const { t } = useTranslation();

  const changeLeadUrl = useChangeStatusUrlAmoCrm();
  const changeNote = useChangeStatusNoteAmoCrm();

  const handleChangeLeadUrl = (checked: boolean) => {
    changeLeadUrl.mutate(checked);
  };

  const handleChangeNote = (checked: boolean) => {
    changeNote.mutate(checked);
  };

  return (
    <div className={styles.cont_extra}>
      <h3>{t('integration.Qoshimcha_malumotlar')}</h3>
      <div className={styles.cont_extra_item}>
        <p>{t('integration.Lid_nomi')}</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>{t('integration.Mijoz_F_I_SH')}</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>{t('integration.Telefon_raqam')}</p>
        <Switch checked={true} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>{t('integration.URL')}</p>
        <Switch onChange={handleChangeLeadUrl} loading={changeLeadUrl.isLoading} checked={amoCrm.existUrl} />
      </div>
      <div className={styles.cont_extra_item}>
        <p>{t('integration.Izoh')}</p>
        <Switch onChange={handleChangeNote} loading={changeNote.isLoading} checked={amoCrm.existTextArea} />
      </div>
    </div>
  );
};

export default ExtraInformation;
