import { useQuery } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { getOrdersBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { CancelOrderReasonResponse, CancelReasonOrder } from '../../../utils/models/CancelReasonOrder';
import { OrderStatus } from '../../../utils/models/OrderStatus';
import { Order } from '../../../utils/models/OrderTable';
import { Pagination } from '../../../utils/models/Pagination';
import { SearchParamsOrder } from '../Order';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

// get booked flats list
export function useBookedFlats(size: number, searchObj: SearchParamsOrder) {
  return useQuery<Pagination<Order>>([queryKeys.BOOKED_FLAT_LIST, searchObj.page, JSON.stringify(searchObj)], async () => {
    const res = await $api.post(endPoints.BOOKING_FILTER, getOrdersBody(size, searchObj));

    return res.data.data;
  });
}

export function useOneOrderCancelReason(id: string, status: OrderStatus) {
  return useQuery<CancelOrderReasonResponse>(
    [queryKeys.ORDER_CANCEL_REASON_ID, id],
    async () => {
      const res = await $api.get(`${endPoints.BOOKING_CANCEL_REASON}/${id}`);

      return res.data.data;
    },
    {
      enabled: status === OrderStatus.CANCELED,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useOrderCancelReason(companyId?: number) {
  return useQuery<CancelReasonOrder[]>(
    [queryKeys.ORDER_CANCEL_REASON, companyId],
    async () => {
      const res = await $api.get(endPoints.BOOKING_CANCEL_REASON + (companyId ? `?companyId=${companyId}` : ''));

      return res.data.data;
    },
    {
      enabled: !!companyId,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}
