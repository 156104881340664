import React, { FC, useState } from 'react';
import { t } from 'i18next';

import ArrowCircleDownIcon from '../../../../assets/icons/contract/ArrowCircleDownIcon';
import { ContractDataForCreatePayment } from '../../../../utils/models/ContractDataForCreatePayment';

import styles from './paymentClientContract.module.scss';

interface IProps {
  data: ContractDataForCreatePayment | undefined;
}

const PaymentClientContract: FC<IProps> = ({ data }) => {
  const [heightVisible, setHeightVisible] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(0);

  const handleArrov = () => {
    setHeightVisible(!heightVisible);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <div className={styles.container_header_title}>
          Shartnoma raqami <b>{data?.number}</b>
        </div>
        <div className={styles.container_header_item}>
          TJM <b>{data?.flats[0]?.houseName}</b>
        </div>
      </div>
      <div className={styles.container_content} style={{ height: heightVisible ? offsetHeight : 0 }}>
        <div
          className={styles.container_content_body}
          ref={e => {
            setOffsetHeight(e?.offsetHeight || 0);
          }}
        >
          {data?.flats?.map(item => (
            <div key={item?.id} className={styles.container_content_body_item}>
              <p>
                {t('home_id.Bino')} <b>{item?.buildingName}</b>
              </p>
              <p>
                {t('showroom.Xonadon')} <b>{item?.number}</b>
              </p>
              <p>
                {t('showroom.Xonalar_soni')} <b>{item?.rooms}X</b>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.container_bottom} onClick={handleArrov} style={{ marginTop: heightVisible ? 16 : 0 }}>
        <ArrowCircleDownIcon style={{ transform: `rotate(${heightVisible ? 0 : 180}deg)` }} />
      </div>
    </div>
  );
};

export default PaymentClientContract;
