import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from '@components/loading/Loading';

import AccessDenied from '../../components/accessDenied/AccessDenied';

import settingsRoutes from './settingsRoutes';

const SettingsRouter = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      {settingsRoutes.map(route => (
        <Route key={route.path} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
      ))}
      <Route path="*" element={<AccessDenied />} />
    </Routes>
  </Suspense>
);

export default SettingsRouter;
