import React, { FC } from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  color?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
}

const Edit2Icon: FC<IProps> = ({ color = '#374151', style, className, onClick, ...props }) => (
  <svg onClick={onClick} className={className} width="14" height="14" viewBox="0 0 14 14" style={style} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.73499 2.10021L2.94583 7.16937C2.76499 7.36187 2.58999 7.74104 2.55499 8.00354L2.33916 9.89354C2.26333 10.576 2.75333 11.0427 3.42999 10.926L5.30833 10.6052C5.57083 10.5585 5.93833 10.366 6.11916 10.1677L10.9083 5.09854C11.7367 4.22354 12.11 3.22604 10.8208 2.00687C9.53749 0.799375 8.56333 1.22521 7.73499 2.10021Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.93591 2.94629C7.18675 4.55629 8.49341 5.78712 10.1151 5.95046" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.75 12.833H12.25" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Edit2Icon;
