import React, { useEffect, useState } from 'react';

import styles from './stepContainer.module.scss';
import ArrowDownIcon from '../../../../../assets/icons/ArrowDownIconCrm';
import { Collapse, Dropdown, Progress } from 'antd';
import CheckIcon from '../../../../../assets/icons/CheckIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { usePipeAll } from '../../../../../queries/queries';
import { setChangePipeStatus } from '../../../../../store/reducers/CrmOneLidSlice';
import { PipeStatuses } from '../../../../../utils/models/PipeAll';
import { mathFloor } from '../../../../../utils/helper/mathFloor';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import ReactGA from 'react-ga';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../utils/models/GoogleAnalitks';

const { Panel } = Collapse;

type Props = {
  setPipeType: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const StepContainer: React.FC<Props> = ({ setPipeType }) => {
  const { searchParams } = useQueryParam<{ pipe: string }, any>();
  const [openDropdown, setOpenDropdown] = useState(false);

  const pipeStatusId = useAppSelector(state => state.crmOneLidField.pipeStatusId);
  const pipeStatusName = useAppSelector(state => state.crmOneLidField.pipeStatusName);
  const pipeStatusColor = useAppSelector(state => state.crmOneLidField.colorStatus);
  // const pipeId = useAppSelector((state) => state.crmOneLidField.pipeId)
  const pipeName = useAppSelector(state => state.crmOneLidField.pipeName);
  const pipeId = useAppSelector(state => state.crmOneLidField.pipeId);

  const dispatch = useAppDispatch();

  const { data: dataPipeAll } = usePipeAll();

  useEffect(() => {
    if (dataPipeAll && Number(searchParams.pipe) && !pipeStatusId) {
      const pipe = dataPipeAll.find(item => item.id === Number(searchParams.pipe));
      if (pipe) {
        handleChangeStatus(pipe.pipeStatuses[1]);
      } else {
        handleChangeStatus(dataPipeAll[0].pipeStatuses[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, dataPipeAll]);

  // handle dropdown
  const handleDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  // change step
  function changeStepEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.CHANGE_STEP_INSIDE_ONE_LEAD
    });
  }

  // get percent
  const getPercent = () => {
    if (dataPipeAll && pipeId) {
      const pipe = dataPipeAll.find(pipe => pipe.id === pipeId);
      const data = pipe?.pipeStatuses;
      if (data) {
        let indexStatus = 0;
        for (let i = 0; i < data.length; i++) {
          indexStatus += 1;
          if (data[i].id === pipeStatusId) break;
        }
        return mathFloor((indexStatus / data.length) * 100, 0);
      }
    }
    return 0;
  };

  // classNameHeader
  const classNameHeader = (index: number) => {
    let className = styles.container_menu_header;
    if (index === 0) {
      className += ` ${styles.container_menu_header_border_radius_top}`;
    }
    if (index === Number(dataPipeAll?.length) - 1) {
      className += ` ${styles.container_menu_header_border_radius_bottom}`;
    }

    if (index !== Number(dataPipeAll?.length) - 1) {
      className += ` ${styles.container_menu_header_border_bottom}`;
    }

    return className;
  };

  // handle change status
  const handleChangeStatus = (status: PipeStatuses) => {
    if (pipeStatusId !== status.id) {
      dispatch(setChangePipeStatus(status));
      changeStepEvent();
    }
  };

  // handle click one status
  const handleSelectOneStatus = (index: number, statusId: PipeStatuses) => {
    if (index !== 0) {
      handleChangeStatus(statusId);
    }
  };

  return (
    <div onClick={() => handleDropdown(true)} className={styles.container}>
      <div className={styles.container_name}>{pipeName}</div>
      <div className={styles.container_step_name}>
        <p>{pipeStatusName}</p>
        <span>
          <ArrowDownIcon />
        </span>
      </div>
      <Dropdown
        visible={openDropdown}
        onVisibleChange={handleDropdown}
        overlayClassName={styles.container_menu}
        overlay={
          <Collapse accordion={true} defaultActiveKey={[pipeId]} expandIcon={() => null} ghost>
            {dataPipeAll?.map((pipe, index) => (
              <Panel
                key={pipe.id}
                header={
                  <div className={classNameHeader(index)}>
                    <p>{pipe.name}</p>
                  </div>
                }
              >
                {pipe.pipeStatuses?.map((status, index) => (
                  <div
                    onClick={() => {
                      handleSelectOneStatus(index, status);
                      setPipeType(status?.type);
                    }}
                    style={{
                      // @ts-ignore
                      '--color': status.color
                    }}
                    className={styles.container_menu_item}
                    key={index}
                  >
                    <CheckIcon
                      style={{
                        // @ts-ignore
                        '--color': status.id === pipeStatusId ? '#fff' : 'transparent'
                      }}
                    />
                    <p className={styles.container_menu_item_name}>{status.name}</p>
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        }
        placement="bottom"
        trigger={['click']}
      >
        <Progress
          strokeColor={{
            from: pipeStatusColor,
            to: pipeStatusColor
          }}
          showInfo={false}
          percent={getPercent()}
        />
      </Dropdown>
    </div>
  );
};

export default StepContainer;
