import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import DublicateControllModal from '@pages/crm/components/listView/actions/dublicate-controll-modal/DublicateControllModal';
import { useAttachClient } from '@pages/oneLid/services/mutation';
import { useGetClientView } from '@pages/oneLid/services/queries';
import { useGetSearchLeadTasks } from '@queries/queries';
import { setOpenDublicateModal } from '@reducers/CrmOneLidSlice';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { queryNames } from '@utils/constants/queryNames';
import { OneLid } from '@utils/models/OneLid';
import { Input, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './clientModal.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const LeadModal: React.FC<Props> = ({ setVisible, visible }) => {
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const attachClient = useAttachClient();
  const { t } = useTranslation();
  const { searchParams, params } = useQueryParam<SearchParamsCrm, any>();
  const { data: client } = useGetClientView(Number(params?.idClient));
  const [searchValue, setSearchValue] = useState(searchParams.search!);
  const [clientData, setClientData] = useState<OneLid>();
  const [scrolling, setScrolling] = useState(true);
  const { data, fetchNextPage, isFetching } = useGetSearchLeadTasks({
    notMerge: true,
    search: searchValue?.length! >= 3 ? searchValue! : ''
  });

  console.log(!!(searchValue?.length! >= 3 ? searchValue! : ''));

  console.log(
    qc.getQueryData([
      queryNames.SEARCH_LEAD_TASK,
      {
        notMerge: true,
        search: searchValue?.length! >= 3 ? searchValue! : ''
      }
    ])
  );

  const handleClose = () => {
    setVisible(false);
    setClientData(undefined);
    setSearchValue('');
    qc.setQueryData<any>(
      [
        queryNames.SEARCH_LEAD_TASK,
        {
          notMerge: true,
          search: searchValue?.length! >= 3 ? searchValue! : ''
        }
      ],
      []
    );
  };

  // useEffect(() => {
  //   if (searchValue?.length > 0) {
  //     qc.fetchQuery([
  //       queryNames.SEARCH_LEAD_TASK,
  //       {
  //         notMerge: true,
  //         search: searchValue?.length! >= 3 ? searchValue! : ''
  //       }
  //     ]);
  //   }
  // }, [searchValue]);

  const handleAttachLead = (clientId: number, leadId: number) => {
    if (client?.lead) {
      dispatch(setOpenDublicateModal(true));
    } else {
      attachClient?.mutateAsync({ clientId, leadId }).then(handleClose);
    }
  };

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - 200 < e.target.scrollTop + e.target.offsetHeight) {
      setScrolling(false);
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        title={t('one_lid.lid_biriktirish')}
        onCancel={handleClose}
        okText={t('client_create_contract.Keyingisi')}
        cancelText={t('home.Bekor_qilish')}
        className={styles.clientModal}
        {...(!clientData?.id && { footer: false })}
        onOk={() => {
          handleClose();
          handleAttachLead(Number(params?.idClient), Number(clientData?.id));
          dispatch(
            setBalanceAndIds({
              ids: [client?.lead?.id!, clientData?.id!],
              balance: clientData?.balance! + client?.lead?.balance!
            })
          );
        }}
        okButtonProps={{ ...{ loading: attachClient.isLoading } }}
      >
        <div className={styles.clientModal_body}>
          <span>{t('one_lid.lidni_qidiring')}</span>
          <Input
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
            placeholder={t('one_lid.lid_qidirish')}
          />
        </div>
        <div className={styles.clientModal_searchClients}>
          <Spin spinning={isFetching}>
            {!clientData?.id ? (
              <div className={styles.clientModal_searchClients_body} onScroll={e => onScroll(e)}>
                {data?.pages?.map(data =>
                  data?.data?.map(client => (
                    <div className={styles.findClient} onClick={() => setClientData(client)} key={client?.id}>
                      <div className={styles.findClient_item}>
                        <label>{t('integration.Lid_nomi')}</label>
                        <span>{client.name}</span>
                      </div>
                      <div className={styles.findClient_item}>
                        <label>{t('one_lid.Qadam')}</label>
                        <div className={styles.topRight}>
                          <div>{client?.pipeStatusResponse?.pipeName}</div>
                          <div
                            style={{
                              backgroundColor: client?.pipeStatusResponse?.color,
                              borderBottomColor: client?.pipeStatusResponse?.color,
                              borderRightColor: client?.pipeStatusResponse?.color,
                              borderTopColor: client?.pipeStatusResponse?.color
                            }}
                          >
                            {client?.pipeStatusResponse?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div className={clientData?.id ? styles.findClient_test : styles.findClient}>
                <div className={styles.findClient_item}>
                  <label>{t('integration.Lid_nomi')}</label>
                  <span>{clientData?.name}</span>
                </div>
                <div className={styles.findClient_item}>
                  <label>{t('one_lid.Qadam')}</label>
                  <div className={styles.topRight}>
                    <div>{clientData?.pipeStatusResponse?.pipeName}</div>
                    <div
                      style={{
                        backgroundColor: clientData?.pipeStatusResponse?.color,
                        borderBottomColor: clientData?.pipeStatusResponse?.color,
                        borderRightColor: clientData?.pipeStatusResponse?.color,
                        borderTopColor: clientData?.pipeStatusResponse?.color
                      }}
                    >
                      {clientData?.pipeStatusResponse?.name}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </div>
      </Modal>
      {params?.idClient && <DublicateControllModal clientId={Number(client?.lead?.id)} />}
    </>
  );
};

export default LeadModal;
