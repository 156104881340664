import React, { useState } from 'react';
import TjmFilter from '@components/tjm-filter/TjmFilter';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsiveBar } from '@nivo/bar';
import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ArrowSquareDown from '../../../../assets/icons/ArrowSquareDown';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useMobileSaleStats } from '../../../../queries/queries';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { BossPaymentsType } from '../../../../utils/models/BossPayments';
import { SaleStatType } from '../../../../utils/models/SaleStateType';
import { BossPaymentsSearchParams } from '../../../bossPayments/BossPayments';
import BossSaleByRegion from '../sale-region/BossSaleByRegion';

import styles from './bossSaleBar.module.scss';

const BossSaleBar = () => {
  const { buildings, houses } = useAppSelector(state => state.bossCurrency);
  const [statsType, setStatsType] = useState<any>(SaleStatType.COUNT);
  const { appendMultipleDifferent, searchParams } = useQueryParam<BossPaymentsSearchParams, any>();

  const startDate = dayjs(`01.${dayjs(new Date()).format('MM.YYYY')}`, DATE_FORMAT).format(DATE_FORMAT);

  const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

  const { data } = useMobileSaleStats({
    statType: searchParams.type || BossPaymentsType.MONTH,
    saleStatType: statsType,
    start: searchParams.start || startDate,
    finish: searchParams.finish || finishDate,
    buildings: buildings?.length! > 0 ? buildings : null,
    houses: houses?.length! > 0 ? houses : null
  });

  const dataConvert: any = {
    [SaleStatType.COUNT]: data
      ? data?.map(item => ({
          ...item,
          value: item.count
        }))
      : [],
    [SaleStatType.AREA]: data
      ? data?.map(item => ({
          ...item,
          value: item.sum
        }))
      : []
  };

  const { t } = useTranslation();
  // header value month
  const headerValueMonth = () => {
    let date = dayjs(new Date()).format('YYYY');

    if (searchParams.start) {
      date = dayjs(searchParams.start, DATE_FORMAT).format('YYYY');
    }
    return date;
  };

  // header value year
  const headerValueYear = () => {
    const start = dayjs(searchParams.start, DATE_FORMAT);
    const finish = dayjs(searchParams.finish, DATE_FORMAT);

    return `${start.format('YYYY')} - ${finish.format('YYYY')}`;
  };

  // header value day
  const headerValueDay = () => {
    let date = dayjs(new Date());

    if (searchParams.start) {
      date = dayjs(searchParams.start, DATE_FORMAT);
    }
    return `${t(`boss_payments.${date.format('MMMM')}`)}, ${date.format('YYYY')}`;
  };

  // minus month
  const addMonth = (addMonth: number) => {
    const dateString = dayjs(searchParams.start, DATE_FORMAT).add(addMonth, 'year').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.MONTH, dateString, dateString]);
  };

  // minus year
  const addYear = (addYear: number) => {
    const start = dayjs(searchParams.start, DATE_FORMAT).add(addYear, 'year').format(DATE_FORMAT);
    const finish = dayjs(searchParams.finish, DATE_FORMAT).add(addYear, 'year').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.YEAR, start, finish]);
  };

  // append day
  const appendDay = () => {
    const startDate = `01.${dayjs(new Date()).format('MM.YYYY')}`;
    const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.DAY, startDate, finishDate]);
  };

  // append month
  const appendMonth = () => {
    const dateString = dayjs(new Date()).format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.MONTH, dateString, dateString]);
  };

  // append year
  const appendYear = () => {
    const start = dayjs(new Date()).add(-4, 'year').format(DATE_FORMAT);
    const finish = dayjs(new Date()).format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.YEAR, start, finish]);
  };

  // minus date
  const addDate = (addDate: number) => {
    let startDate = dayjs(`01.${dayjs(new Date()).format('MM.YYYY')}`, DATE_FORMAT);

    if (searchParams.start) {
      startDate = dayjs(searchParams.start, DATE_FORMAT);
    }

    const start = startDate.add(addDate, 'month').format(DATE_FORMAT);
    const finish = dayjs(startDate, DATE_FORMAT)
      .add(addDate + 1, 'month')
      .add(-1, 'day')
      .format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.DAY, start, finish]);
  };

  // handle change type
  const changeType = (type: SegmentedValue) => {
    switch (type) {
      case BossPaymentsType.MONTH:
        appendMonth();
        break;
      case BossPaymentsType.YEAR:
        appendYear();
        break;
      default:
        appendDay();
        break;
    }
  };

  // handle click arrow left
  const handleArrowLeft = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        addMonth(-1);
        break;
      case BossPaymentsType.YEAR:
        addYear(-5);
        break;
      default:
        addDate(-1);
        break;
    }
  };

  // handle click arrow right
  const handleArrowRight = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        addMonth(1);
        break;
      case BossPaymentsType.YEAR:
        addYear(5);
        break;
      default:
        addDate(1);
        break;
    }
  };

  // header value
  const headerValue = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        return headerValueMonth();
      case BossPaymentsType.YEAR:
        return headerValueYear();
      case BossPaymentsType.DAY:
        return headerValueDay();
      default:
        return headerValueMonth();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <div>
          <div className={styles.date}>
            <ArrowSquareDown className={styles.date_left} onClick={handleArrowLeft} />
            <p>{headerValue()}</p>
            <ArrowSquareDown className={styles.date_right} onClick={handleArrowRight} />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <div>
            <Segmented
              defaultValue={BossPaymentsType.MONTH}
              value={searchParams.type || BossPaymentsType.MONTH}
              onChange={changeType}
              className={styles.segmented}
              size="large"
              options={[
                {
                  label: <div className={styles.segmented_item}>{t('boss_payments.Kunlik')}</div>,
                  value: BossPaymentsType.DAY
                },
                {
                  label: <div className={styles.segmented_item}>{t('boss_payments.Oylik')}</div>,
                  value: BossPaymentsType.MONTH
                },
                {
                  label: <div className={styles.segmented_item}>{t('boss_payments.Yillik')}</div>,
                  value: BossPaymentsType.YEAR
                }
              ]}
            />
          </div>
          <TjmFilter />
        </div>
      </div>
      <BossSaleByRegion />
      <div className={styles.container_body}>
        <div className={styles.container_body_header}>
          <Segmented
            className={styles.segmented}
            value={statsType}
            onChange={value => setStatsType(value)}
            size="large"
            options={[
              {
                label: <div className={styles.segmented_item}>{t('house_config.Xonadonlar_soni')}</div>,
                value: SaleStatType.COUNT
              },
              {
                label: <div className={styles.segmented_item}>{t('home_id.Xonadon_maydoni')}</div>,
                value: SaleStatType.AREA
              }
            ]}
          />
        </div>
        <ResponsiveBar
          data={dataConvert[statsType]}
          margin={{ top: 50, right: 130, bottom: 130, left: 60 }}
          keys={['value']}
          indexBy="date"
          padding={0.1}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors="#917EF7"
          borderRadius={6}
          fill={[
            {
              match: {
                id: 'fries'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'sandwich'
              },
              id: 'lines'
            }
          ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]]
          }}
          axisTop={null}
          axisRight={null}
          enableLabel={false}
          tooltip={e => {
            const data: any = e?.data;

            return <div className={styles.tooltip}>{statsType === 'COUNT' ? data.value : `${data.value}m²`}</div>;
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: 32,
            format: value => {
              switch (searchParams.type) {
                case BossPaymentsType.MONTH:
                  return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
                case BossPaymentsType.YEAR:
                  return dayjs(value, DATE_FORMAT).format('YYYY');
                case BossPaymentsType.DAY:
                  return dayjs(value, DATE_FORMAT).format('DD');
                default:
                  return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
              }
            }
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            format: value => (statsType === 'COUNT' ? value : <>{value} m²</>)
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]]
          }}
          role="application"
        />
      </div>
    </div>
  );
};

export default BossSaleBar;
