import { useMutation, useQueryClient } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { showMessage } from '../../../queries/showMessage';
import i18n from '../../../service/I18nService';
import { $api } from '../../../service/RequestService';
import { getClientsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { SendMessageAllTypes } from '../../../utils/models/MessageList';
import { TemplateMessage } from '../../../utils/models/TemplateMessage';
import { SearchParamsArrearage } from '../../arrearage/Arrearage';
import { SearchParamsMessage } from '../Message';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useDownloadMessagesToExcel(size: number, searchObj: SearchParamsArrearage) {
  return useMutation(
    async () => {
      const res = await $api.post(
        `${endPoints.CLIENT_SEND_MESSAGE_FILTER_EXCEL}?lang=${i18n.language?.toUpperCase()}`,
        getClientsBody(size, searchObj),
        {
          responseType: 'blob'
        }
      );

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Xabarlar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useMessageTemplateSave(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: TemplateMessage) => {
      const res = await $api.post(endPoints.CLIENT_MESSAGE_TEMP, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.TEMPLATE_MESSAGE]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useMessageTemplateDelete(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: number) => {
      const res = await $api.delete(endPoints.CLIENT_MESSAGE_TEMP + data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.TEMPLATE_MESSAGE]);
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function usePremiumSendMessage(type: SendMessageAllTypes, searchParams: SearchParamsMessage, onSuccess: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { originator: string; text: string; extra?: { houses: number[]; statuses: string[]; repaired: boolean; buildings: number[] } }) => {
      let url = `${endPoints.CLIENT_SEND_MESSAGE}?sendTo=${type}`;

      if (data?.extra) {
        for (let i = 0; i < data.extra?.houses?.length; i++) {
          url += `&houses=${data.extra.houses[i]}`;
        }
        for (let i = 0; i < data.extra?.buildings?.length; i++) {
          url += `&buildings=${data.extra.buildings[i]}`;
        }
        for (let i = 0; i < data.extra?.statuses?.length; i++) {
          url += `&statuses=${data.extra.statuses[i]}`;
        }
        if (data.extra.repaired !== undefined) url += `&repaired=${data.extra.repaired}`;
      }
      const res = await $api.post(url, data);

      return res.data;
    },
    {
      onError: baseErrorHandler,
      onSuccess: data => {
        showMessage(data.message, data.accept);
        onSuccess();
        qc.invalidateQueries([queryKeys.MESSAGE_LIST, searchParams.page, JSON.stringify(searchParams)]);
      }
    }
  );
}
