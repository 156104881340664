import { LEAD_DATA_TYPE, LeadEventOrTask, LeadEventsAndTasksList, OneEvent } from '@utils/models/LeadEventsAndTaskList';
import { AxiosResponse } from 'axios';

export function getEvents(
  res: AxiosResponse<
    {
      data: LeadEventOrTask[];
    },
    any
  >,
  arr: LeadEventsAndTasksList[]
) {
  let list: LeadEventOrTask[] = [];
  let events: OneEvent[] = [];

  let date: string = res.data.data[0]?.date || '';
  let eventDate: string = '';

  const data: LeadEventOrTask[] = res.data.data;

  for (let i = 0; i < data.length; i++) {
    if (data.length === i + 1) {
      if (data[i].leadDataType !== LEAD_DATA_TYPE.LEAD_EVENT) {
        if (events.length > 0) {
          list.push({
            ...data[i],
            events,
            leadDataType: LEAD_DATA_TYPE.LEAD_EVENT
          });
          events = [];
          eventDate = '';
        }
        list.push(res.data.data[i]);
      } else {
        if (eventDate === data[i].res.date) {
          events.push(data[i].res);
          list.push({
            ...data[i],
            events
          });
        } else {
          if (events.length > 0) {
            list.push({
              ...data[i],
              events,
              leadDataType: LEAD_DATA_TYPE.LEAD_EVENT
            });
            events = [];
            eventDate = '';
          }
          events.push(data[i].res);
          list.push({
            ...data[i],
            events
          });
        }
      }
      arr.push({
        date,
        events: list
      });
    } else if (data[i].date === date) {
      if (data[i].leadDataType !== LEAD_DATA_TYPE.LEAD_EVENT) {
        if (events.length > 0) {
          list.push({
            ...data[i],
            events,
            leadDataType: LEAD_DATA_TYPE.LEAD_EVENT
          });
          events = [];
          eventDate = '';
        }
        list.push(data[i]);
      } else {
        if (eventDate === data[i].res.date) {
          events.push(data[i].res);
        } else {
          if (events.length > 0) {
            list.push({
              ...data[i],
              events,
              leadDataType: LEAD_DATA_TYPE.LEAD_EVENT
            });
          }
          events = [];
          events.push(data[i].res);
          eventDate = data[i].res.date;
        }
      }
    } else {
      if (events.length > 0) {
        list.push({
          leadDataType: LEAD_DATA_TYPE.LEAD_EVENT,
          date,
          events,
          res: data[i].res
        });
        events = [];
        eventDate = '';
      }
      if (data[i].leadDataType !== LEAD_DATA_TYPE.LEAD_EVENT) {
        arr.push({
          date,
          events: list
        });
        date = data[i].date;
        list = [];
        list.push(data[i]);
      } else {
        arr.push({
          date,
          events: list
        });
        date = data[i].date;
        list = [];
        events.push(data[i].res);
        eventDate = data[i].res.date;
      }
    }
  }
}
