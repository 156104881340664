import React from 'react';

const ArrowUpRight = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 18.5L18 6.5" stroke="#E53535" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 6.5H18V16.25" stroke="#E53535" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowUpRight;
