import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDown from '../../../../../assets/icons/ArrowDown';
import ArrowDownCrmIcon from '../../../../../assets/icons/ArrowDownCrmIcon';
import ShaxmatkaIcon from '../../../../../assets/icons/ShaxmatkaIcon';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { rootPaths } from '../../../../../routing/rootPaths';
import { setAllHouseFilter, setOpenDrawer } from '../../../../../store/reducers/AllHouseFilter';
import { useBookingLeadFlatDelete } from '../../../services/mutation';
import { useBookingLeadFlat } from '../../../services/queries';

import BookingLeadFlatDeleteModal from './BookingLeadFlatDeleteModal';

import styles from './showroomWidget.module.scss';

const ShowroomWidget = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useBookingLeadFlat(Number(params.id));
  const bookingLeadFlatDelete = useBookingLeadFlatDelete();
  const dispatch = useAppDispatch();
  const allHouseFilter = useAppSelector(state => state.allHouseFilter.filter);
  const [deletedId, setDeletedId] = useState({
    visible: false,
    id: 0,
    text: ''
  });

  const handleOpenShowroom = () => {
    navigate({
      pathname: rootPaths.SHOWROOM,
      search: `?prev=${btoa(location.pathname + location.search)}&leadId=${params.id}`
    });
  };

  const handleDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number, number: string) => {
    e.stopPropagation();
    setDeletedId({ id, text: number, visible: true });
  };

  const handleFlat = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number, houseId: number) => {
    e.stopPropagation();
    // @ts-expect-error
    dispatch(setOpenDrawer({ id, visible: true }));
    dispatch(
      setAllHouseFilter({
        ...allHouseFilter,
        houseId
      })
    );
    handleOpenShowroom();
  };

  return (
    <div>
      <div className={styles.container_item} onClick={handleOpenShowroom}>
        <ShaxmatkaIcon /> {t('one_lid.Xonadon_tanlash')}{' '}
        <span>
          <ArrowDown />
        </span>
      </div>
      <div className={styles.container_item_container}>
        <Collapse
          bordered={false}
          expandIconPosition="end"
          expandIcon={panelprops => (
            <span
              style={{
                transform: `rotate(${panelprops.isActive ? 180 : 0}deg)`,
                transition: '0.3s'
              }}
            >
              <ArrowDownCrmIcon />
            </span>
          )}
        >
          {data?.map(item => (
            <Collapse.Panel
              header={
                <>
                  <span onClick={e => handleFlat(e, item.flatDTO.id, item.flatDTO.building.houseId)}>Buyurtma №{item.number}</span>
                  {bookingLeadFlatDelete.isLoading && deletedId.id === item.id ? (
                    <LoadingOutlined />
                  ) : (
                    <span onClick={e => handleDelete(e, item.id, item.number)}>
                      <TrushSquareIcon strokeWidth={2} size={20} />
                    </span>
                  )}
                </>
              }
              key={item.id}
              className={styles.container_item_container_content}
            >
              <p>
                <span>TJM</span>
                <b>{item.flatDTO.houseName}</b>
              </p>
              <p>
                <span>Qavat</span>
                <b>{item.flatDTO.floor}</b>
              </p>
              <p>
                <span>Xonalar soni</span>
                <b>{item.flatDTO.rooms}</b>
              </p>
              <p>
                <span>Maydoni</span>
                <b>
                  {item.flatDTO.area} m<sup>2</sup>
                </b>
              </p>
              <p className="active">
                <span>Narxi</span>
                <b>{item.flatDTO.prepayment?.toLocaleString('ru')} UZS</b>
              </p>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
      <BookingLeadFlatDeleteModal {...deletedId} setVisible={setDeletedId} bookingLeadFlatDelete={bookingLeadFlatDelete} />
    </div>
  );
};

export default ShowroomWidget;
