import React, { useEffect } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import styles from './importExportModal.module.scss';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { SearchParamsCrm } from '../../filter/FilterButton';
import { CrmExportType } from '../../../../../utils/models/CrmExportType';
import { useAppSelector } from '../../../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { useExportCrm } from '../../../services/mutation';

interface Props {
  visible: boolean;
  close: (isOpen: boolean) => void;
}

const { Option } = Select;

const ExportModal: React.FC<Props> = ({ visible, close }) => {
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();

  const [form] = Form.useForm<{ type: CrmExportType }>();

  const listView = useAppSelector(state => state.assigmentSlice.showListView);

  const { t } = useTranslation();

  const exportMutation = useExportCrm(Number(searchParams.voronka), searchParams, handleSuccess);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        type: CrmExportType.ALL
      });
    }
  }, [visible, form]);

  // get file
  function handleSuccess() {
    handleClose();
  }

  // handle close
  const handleClose = () => {
    close(false);
    form.resetFields();
  };

  // on finish
  const onFinish = (val: { type: CrmExportType }) => {
    exportMutation.mutate(val.type);
  };

  return (
    <Modal className={styles.container} width={560} footer={false} visible={visible} title={t('crm_import.Eksport')} onCancel={handleClose}>
      <p className={styles.container_title}>{t('crm_import.Mijozlaringiz_royxatini_Microsoft_Excel')}</p>
      <Form onFinish={onFinish} form={form} name={'changeEmployeeListViewCrm'}>
        <Form.Item label={''} name={'type'} rules={[{ required: true, message: '' }]}>
          <Select className={'customSelect ' + styles.container_select}>
            <Option value={CrmExportType.ALL}>{t('crm_import.Barchasi')}</Option>
            <Option value={CrmExportType.FILTER}>{t('crm_import.Barcha_filtrdagilar')}</Option>
            {listView && <Option value={CrmExportType.CURRENT_PAGE}>{t('crm_import.Faqat_joriy_sahifadagilar')}</Option>}
          </Select>
        </Form.Item>
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button loading={exportMutation.isLoading} htmlType={'submit'} type="primary">
              {t('crm_import.Eksport')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ExportModal;
