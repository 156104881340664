interface IProps {
  stroke?: string;
  size?: number;
}

const CardPosIcon = ({ stroke = 'white', size = 20 }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.27441 13.2326L13.2327 3.27429" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.25098 15.2325L10.251 14.2325" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.4941 12.9906L13.4858 10.9989" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.00138 8.53246L8.53472 2.99913C10.3014 1.23246 11.1847 1.22413 12.9347 2.97413L17.0264 7.06579C18.7764 8.81579 18.7681 9.69913 17.0014 11.4658L11.4681 16.9991C9.70138 18.7658 8.81805 18.7741 7.06805 17.0241L2.97638 12.9325C1.22638 11.1825 1.22638 10.3075 3.00138 8.53246Z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.66699 18.3322H18.3337" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CardPosIcon;
