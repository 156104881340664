import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@components/index';
import { useAppDispatch } from '@hooks/reduxHooks';
import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { queryNames } from '@utils/constants/queryNames';
import { cx } from '@utils/cx';
import { urlDecode } from '@utils/helper/urlEncodeDecode';
import { BuildingId } from '@utils/models/Building';
import { Dropdown } from 'antd';
import { Button, Tabs } from 'global';
import { DragDropContext, Draggable, DragStart, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useQueryClient } from 'react-query';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { useBuildingOrdersSave } from 'modules/building';

import { ShaxmatkaContextDataState } from '../hooks/ShaxmatkaContext';

import styles from './shaxmatkaComponents.module.scss';

const { TabPane } = Tabs;

interface IProps {
  buildings: BuildingId[] | undefined;
  setCreateBuildingVisible: React.Dispatch<any>;
  setContext: (value: ShaxmatkaContextDataState) => void;
}

const ShaxmatkaHeader = ({ buildings, setCreateBuildingVisible, setContext }: IProps) => {
  const qc = useQueryClient();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const saveOrders = useBuildingOrdersSave();
  const [query, setQuery] = useQueryParams({ blockId: NumberParam, blockName: StringParam });
  const [draggingId, setDraggingId] = useState<number | undefined>(undefined);
  const [visibleIds, setVisibleIds] = useState<number[]>([]);
  const params = useParams<{ name?: string; id: string }>();
  const activeKey = query?.blockId;

  const buildingsSort = buildings?.sort((a, b) => {
    if (a.orders && b?.orders) {
      return a.orders - b?.orders;
    }
    return 0;
  });

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    setDraggingId(undefined);

    const updatedBuildings = [...(buildingsSort || [])]; // Create a copy of the array
    const movedElement = updatedBuildings.splice(source?.index, 1)[0];

    updatedBuildings.splice(destination!?.index, 0, movedElement);

    qc.setQueryData(
      [queryNames.BUILDING_ID, params?.id],
      updatedBuildings?.map((item, index) => ({ ...item, orders: index + 1 }))
    );

    saveOrders.mutate(updatedBuildings?.map((item, index) => ({ buildingId: item?.id, orders: index + 1 })));
  };

  const onDragStart = (start: DragStart, provided: ResponderProvided) => {
    setDraggingId(Number(start?.draggableId));
  };

  const handleBuilding = (id: number) => {
    setQuery({
      blockId: id,
      blockName: buildingsSort?.find(item => item.id === id)?.name
    });

    // @ts-expect-error
    dispatch(setShaxmatka([]));
    setContext({ activeFlats: [] });
  };

  useEffect(() => {
    function buildThresholdList() {
      const thresholds = [];
      const numSteps = 10;

      for (let i = 1.0; i <= numSteps; i++) {
        const ratio = i / numSteps;

        thresholds.push(ratio);
      }

      thresholds.push(0);
      return thresholds;
    }

    const observer = new IntersectionObserver(
      entries => {
        setVisibleIds(prev => {
          let arr: number[] = [...prev];

          entries?.forEach(item => {
            const id = Number(item?.target?.id);

            if (item?.isIntersecting && !arr.includes(id)) {
              arr.push(id);
            }
            if (!item?.isIntersecting) {
              arr = arr.filter(i => i !== id);

              // console.log(arr, item?.isIntersecting, id);
            }
          });

          if (draggingId && !arr.includes(draggingId)) {
            arr.push(draggingId);
          }

          return arr;
        });
      },
      { root: containerRef.current, threshold: buildThresholdList() }
    );

    const tabElm = containerRef.current?.querySelectorAll('.group');

    tabElm?.forEach(item => {
      observer.observe(item);
    });

    return () => {
      tabElm?.forEach(item => observer.unobserve(item));
    };
  }, [containerRef.current, buildingsSort, draggingId]);

  const isResize = visibleIds?.length !== 0 && visibleIds?.length !== buildingsSort?.length;

  return (
    <div className="relative flex h-[57px] w-full items-center justify-between rounded-[6px] bg-[#fff] pl-[27px] pr-[12px]">
      <div className="flex h-full w-[calc(100%-53px)] items-center">
        <h1 className="min-w-max pr-4 text-[14px] text-[#464255]">{urlDecode(params?.name || '')}</h1>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="shaxmatkaTabs" direction="horizontal">
            {provided => (
              <div
                ref={ref => {
                  provided.innerRef(ref);
                  containerRef.current = ref;
                }}
                {...provided.droppableProps}
                className={cx('relative ml-[80px] flex h-full overflow-x-auto overflow-y-hidden', styles.header_tabs)}
              >
                {buildingsSort
                  ?.sort((a, b) => {
                    if (a.orders && b?.orders) {
                      return a.orders - b?.orders;
                    }
                    return 0;
                  })
                  ?.map((building, index) => (
                    <Draggable key={building.id} draggableId={String(building.id)} index={index}>
                      {dragProvided => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          style={{ ...dragProvided.draggableProps.style }}
                          key={building?.id}
                          className={cx(
                            'group relative flex min-w-[180px] cursor-pointer  items-center justify-center border-[0px] border-b-[2px] border-r-[1px] border-solid border-b-transparent border-r-[#e5e7eb] bg-[#fff]',
                            activeKey === building?.id && 'border-b-[2px] border-b-[#00a389]'
                          )}
                          id={String(building.id)}
                          onClick={() => {
                            handleBuilding(building?.id);
                          }}
                          title={building?.name}
                        >
                          <span className="max-w-[200px]  overflow-hidden text-ellipsis whitespace-nowrap px-4">{building?.name}</span>
                          <span {...dragProvided.dragHandleProps} className="absolute right-2 opacity-[0] group-hover:opacity-[1] ">
                            <Icon name="DotsSixVertical" />
                          </span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
                {isResize ? (
                  <Dropdown
                    overlay={
                      <div className="flex max-h-[160px] w-[160px] flex-col overflow-y-auto rounded-md bg-[#fff] py-[6px] shadow-md">
                        {buildingsSort?.map(item =>
                          visibleIds?.includes(item?.id) ? null : (
                            <div
                              onClick={() => {
                                handleBuilding(item?.id);
                              }}
                              title={item?.name}
                              key={item?.id}
                              className="min-h-[32px] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap px-[12px] py-[6px] hover:bg-[#eeeeee]"
                            >
                              {item?.name}
                            </div>
                          )
                        )}
                      </div>
                    }
                  >
                    <div className="z-1 sticky right-0 flex cursor-pointer items-center justify-center bg-[#fff] px-[12px]">
                      <Icon name="MenuThreeDotHorizontalIcon" />
                    </div>
                  </Dropdown>
                ) : (
                  ''
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* {buildings?.length !== 0 && (
          <DragDropContext onDragEnd={() => {}}>
            <Droppable
              droppableId="shaxmatkaTabs"
              direction="horizontal"
              renderClone={props => (
                <div ref={props.innerRef} {...props.draggableProps}>
                  asadasda
                </div>
              )}
            >
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="relative">
                  <Tabs
                    defaultActiveKey={String(activeKey)}
                    activeKey={String(activeKey)}
                    className="Shaxmatka_header_tabs"
                    onChange={(key: any) => {
                      setQuery({
                        blockId: Number(key),
                        blockName: buildings?.find(item => item.id === Number(key))?.name
                      });

                      // @ts-expect-error
                      dispatch(setShaxmatka([]));
                      setContext({ activeFlats: [] });
                    }}
                    renderTabBar={(tabBarProps, DefaultTabBar) => (
                      // @ts-expect-error
                      <DefaultTabBar {...tabBarProps}>
                        {node => (
                          <Draggable key={node.key} draggableId={String(node.key)} index={buildingsObj![Number(node.key)].index}>
                            {dragProvided => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                key={node.key}
                                style={{ ...dragProvided.draggableProps.style }}
                                {...dragProvided.dragHandleProps}
                              >
                                {node}
                              </div>
                            )}
                          </Draggable>
                        )}
                      </DefaultTabBar>
                    )}
                  >
                    {buildings
                      ?.sort((a: any, b: any) => b.id - a.id)
                      .reverse()
                      .map((building, index) => (
                        <TabPane
                          tab={
                            <div key={building?.id} title={building.name} className="m-auto max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap px-4">
                              {building.name}
                            </div>
                          }
                          // @ts-expect-error
                          index={index}
                          key={String(building.id)}
                        />
                      ))}
                  </Tabs>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )} */}
      </div>
      {buildings?.length !== 0 && (
        <Button
          type="primary"
          className="float-right flex w-[33px] items-center justify-center rounded-[10px] text-[20px]"
          onClick={() => {
            setCreateBuildingVisible({
              visible: true,
              id: null
            });
          }}
        >
          +
        </Button>
      )}
    </div>
  );
};

export default ShaxmatkaHeader;
