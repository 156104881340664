export function modifyArray(originalArray: number[]) {
  const newArray = [];

  // Find the maximum value in the original array
  const maxValue = Math.max(...originalArray);

  // Round off the maximum value to the nearest multiple of 5
  const roundedMaxValue = maxValue > 10 ? Math.round(maxValue / 10) : maxValue / 10;
  // Calculate the step size to achieve a length of 10

  // Generate new array elements with a length of 10, starting from 0 and ending with the rounded maximum value
  for (let i = 0; newArray.length < 10; i += roundedMaxValue) {
    newArray.push(maxValue > 10 ? Math.round(i) : i > 0 ? i.toFixed(1) : 0);
  }
  return newArray;
}
