import DownloadIconV5 from '@icons/DownloadIconV5';
import FileIcon from '@icons/FileIcon';
import { BASE_URL } from '@service/RequestService';
import { PaymentListModel } from '@utils/models/OrderTable';
import { Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './tablePaymentsInfoModal.module.scss';

type Props = {
  payment: PaymentListModel | undefined;
  setPayment: React.Dispatch<React.SetStateAction<PaymentListModel | undefined>>;
  data: any;
};

interface FileNameProps {
  originalName: string;
}

const TablePaymentsInfoModal: React.FC<Props> = ({ payment, setPayment, data }) => {
  const { t } = useTranslation();

  const toKilobytes = (bytes: number) => (bytes / 1024).toFixed(2);

  const FileName: React.FC<FileNameProps> = ({ originalName }) => {
    const truncateText = (text: string, maxLength: number) => {
      if (text.length > maxLength) {
        return `${text.slice(0, maxLength - 1)  }...`;
      }
      return text;
    };

    const getTruncatedFileName = (fileName: string, maxLength: number) => {
      const extensionIndex = fileName.lastIndexOf('.');
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);

      if (name.length > maxLength) {
        return `${truncateText(name, maxLength)  }(${extension})`;
      }
      return fileName;
    };

    const truncatedFileName = getTruncatedFileName(originalName, 16);

    return (
      <Tooltip title={originalName} overlayInnerStyle={{ borderRadius: '6px', border: '1px solid black' }}>
        <p className="text-[16px] font-medium text-[#344054]">{truncatedFileName}</p>
      </Tooltip>
    );
  };

  const filteredData = data.find((item: any) => item.id === payment);

  // console.log('payment', filteredData);

  return (
    <Modal footer={false} title={t("home.To'lov_ma'lumotlari")} className={styles.info_modal} visible={!!payment} onCancel={() => setPayment(undefined)}>
      <div className={styles.container}>
        <div className={styles.masul_info}>
          {/* <span className={styles.masul_info_title}>{t("home.Ma'sul_xodim")}:</span>
                    <span className={styles.masul_info_value}>{filteredData?.amount}</span>

                    {filteredData?.createdDate && (
                        <>
                            <span className={styles.masul_info_title}>{t('home.Sana')}:</span>
                            <span className={styles.masul_info_value}>{filteredData?.createdDate}</span>
                        </>
                    )} */}
          {filteredData?.note && (
            <>
              <span className={styles.masul_info_title}>{t('home.Izoh')}:</span>
              <span className={styles.masul_info_value} style={{ marginTop: '-10px', marginBottom: '10px' }}>
                {filteredData?.note}
              </span>
            </>
          )}
        </div>

        <div className="mt-[-20px]">
          {filteredData?.files?.map((item: { id: number; size: number; originalName: string; absolutePath: string }) => (
            <div key={item?.id} className="mt-3 flex items-center justify-between rounded-xl bg-[#F9FAFB] p-[16px]" style={{ border: '1px solid #E5E7EB' }}>
              <div className="flex items-center gap-3">
                <span className="flex items-center justify-center rounded-[10px] border-[1px] bg-[#EFF1F5] p-[8px]" style={{ border: '1px solid #E5E7EB' }}>
                  <FileIcon />
                </span>
                <div>
                  <FileName originalName={item?.originalName} />
                  {/* <p className='text-[#344054] font-medium text-[16px]'>{item?.originalName}{' '}</p> */}
                  <p className="text-[16px] font-medium text-[#98A2B3]">{item?.size ? `${toKilobytes(item?.size)} KB` : 'N/A'}</p>
                </div>
              </div>

              <a
                href={`${BASE_URL}/${item?.absolutePath}`}
                className={`flex items-center justify-center rounded-[8px] bg-[#00A389] px-[12px] py-[6px] ${styles.btn_download}`}
                onClick={e => {
                  const fileUrl = `${BASE_URL}/${item?.absolutePath}`;
                  const fileName = item?.absolutePath.split('/').pop();
                  // @ts-expect-error
                  const fileExtension = fileName.split('.').pop().toLowerCase();
                  const viewableExtensions = ['pdf', 'txt', 'jpg', 'jpeg', 'png'];

                  if (viewableExtensions.includes(fileExtension)) {
                    e.preventDefault();
                    window.open(fileUrl, '_blank', 'noopener,noreferrer');
                  }
                }}
              >
                <div className="flex items-center justify-center gap-[8px]">
                  <DownloadIconV5 />
                  <p className="m-0 text-[14px] text-[#FFFFFF]">{t('home.Yuklab_olish')}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TablePaymentsInfoModal;
