import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { ResponsivePie } from '@nivo/pie';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { useGetAllEmployeeWithObj, useGetAllPipesWithObj } from '@pages/dashboard/services/queries';
import { ChartDataModel } from '@pages/dashboard/utils/models/ChartDataModel';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import i18n from '@service/I18nService';
import { widgetPieChartColord } from '@utils/constants/colorPieChart';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { cx } from '@utils/cx';
import { colorGenerator } from '@utils/helper/colorGenerator';
import { urlEncode } from '@utils/helper/urlEncodeDecode';
import { CrmPieChartModel } from '@utils/models/CrmLeadList';
import { PieChartData } from '@utils/models/PieChartData';
import { Empty, Spin } from 'antd';
import dayjs from 'dayjs';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

import { useAction } from 'modules/dashboard';

import styles from './pieChartWidget.module.scss';

type Props = {
  data: (CrmPieChartModel & ChartDataModel) | undefined;
  item: WidgetModel;
  loading: boolean;
};

type HouseType = {
  balance: number;
  color: string | null;
  count: number;
  en: string;
  field: string;
  iconTaskType: null;
  kr: string;
  ru: string;
  tr: string;
  type: string;
  uz: string;
};

const PieChartWidget: React.FC<Props> = ({ data, item, loading }) => {
  const { objectTypes } = useAction();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const [chartData, setChartData] = useState<PieChartData[]>([]);
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const { employeesWithObj } = useGetAllEmployeeWithObj();
  const { pipesWithObj } = useGetAllPipesWithObj();
  // const objectData = JSON.parse(item?.objectData);
  const objectDataString = item?.objectData[1] === "'" || item?.objectData[2] === "'" ? item?.objectData?.replaceAll(/'/g, '"') : item?.objectData;
  const objectData = objectDataString?.includes('{') && objectDataString?.includes('}') ? JSON.parse(objectDataString) : {};

  const objectType = item?.objectType;

  let queryString1 = '';

  useEffect(() => {
    for (const key in objectData) {
      if (Array.isArray(objectData[key])) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        objectData[key].forEach((value: string) => {
          queryString1 += `${key}=${encodeURIComponent(value)}&`;
        });
      } else {
        queryString1 += `${key}=${encodeURIComponent(objectData[key])}&`;
      }
    }
    queryString1 = queryString1.slice(0, -1);
  }, [objectData]);

  const handleNavigateFilter = () => {
    if (objectType === 'LEAD') {
      navigate(rootPaths.MAIN + MainPaths.CRM + (objectData?.voronka ? `?${queryString1}` : `?voronka=${objectData?.pipeId}&${queryString1}`));
    } else if (objectType === 'TASK') {
      navigate(`${rootPaths.MAIN + MainPaths.TASK}?${queryString1}`);
    } else {
      navigate(`${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST}?${queryString1}`);
    }
  };

  const handleNavigateDateFilter = () => {
    if (objectType === 'LEAD') {
      navigate(
        `${
          rootPaths.MAIN + MainPaths.CRM + (objectData?.voronka ? `?${queryString1}` : `?voronka=${objectData?.pipeId}&${queryString1}`)
        }&startDate=${`${searchParams?.startDate} 00:00`}&finishDate=${`${searchParams?.finishDate} 23:59`}`
      );
    } else if (objectType === 'TASK') {
      navigate(
        `${rootPaths.MAIN + MainPaths.TASK}?${queryString1}` +
          `&startDate=${urlEncode(dayjs(searchParams?.startDate, DATE_FORMAT).hour(0).minute(0).format(DATE_WITH_TIME_FORMAT))}&finishDate=${urlEncode(dayjs(searchParams?.finishDate, DATE_FORMAT).hour(23).minute(59).format(DATE_WITH_TIME_FORMAT))}`
      );
    } else {
      // eslint-disable-next-line no-useless-concat
      navigate(`${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST}?${queryString1}` + `&startDate=${searchParams?.startDate}&finishDate=${searchParams?.finishDate}`);
    }
  };

  const fieldName = (item: PieChartData) => {
    if (data?.filter?.currentPrevious) {
      if (item?.type === 'CHANGE_LEAD_EMPLOYEE' || item?.type === 'SET_LEAD_EMPLOYEE') {
        return employeesWithObj[item?.id]?.fullName || t('dashboard.Boshqalar');
      }

      if (item.type === 'CHANGE_STATUS' || item?.type === 'CREATE_STATUS') {
        return (
          <div className="flex items-center overflow-hidden rounded-3xl border border-solid border-gray-300">
            <ConditionalRender if={pipesWithObj[item?.id]?.pipeName}>
              <div className="border border-b-0 border-l-0 border-t-0 border-solid border-gray-300 px-2 py-0.5 text-sm">{pipesWithObj[item?.id]?.pipeName}</div>
            </ConditionalRender>
            <ConditionalRender
              if={pipesWithObj[item?.id]?.name}
              else={
                <div
                  className="px-2 py-0.5 text-sm"
                  style={{
                    background: pipesWithObj[item?.id]?.color
                  }}
                >
                  {item?.label}
                </div>
              }
            >
              <div
                className="px-2 py-0.5 text-sm text-white"
                style={{
                  background: pipesWithObj[item?.id]?.color
                }}
              >
                {pipesWithObj[item?.id]?.name || item?.label}
              </div>
            </ConditionalRender>
          </div>
        );
      }

      return item?.label;
    }

    return item?.label;
  };

  const chartLabelName = (house: HouseType) => {
    if (data?.filter?.currentPrevious) {
      if (house?.type === 'CHANGE_LEAD_EMPLOYEE' || house?.type === 'SET_LEAD_EMPLOYEE') {
        return employeesWithObj[house?.field]?.fullName || t('dashboard.Boshqalar');
      }

      if (house.type === 'CHANGE_STATUS' || house?.type === 'CREATE_STATUS') {
        return pipesWithObj[house?.field]?.name || house?.field;
      }

      return house?.field;
    }

    return house?.field;
  };

  useEffect(() => {
    if (data?.filter) {
      const newData: PieChartData[] = [];

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(data?.filter?.leadList) &&
        data?.filter?.leadList.forEach((house, index) => {
          let color = widgetPieChartColord[index];

          if (index > 8) {
            color = `#${colorGenerator()}`;
          }

          newData.push({
            id: objectType === 'LOG' && item?.groupBy === 'EVENT_TYPE' ? String(house[i18n.language as keyof typeof house]) : objectTypes[house.field] || house.field,
            label:
              objectType === 'LOG' && item?.groupBy === 'EVENT_TYPE'
                ? String(house[i18n.language as keyof typeof house])
                : objectTypes[house.field] || chartLabelName(house as never),
            value: house.count,
            balance: house?.balance,
            color,
            type: house?.type
          });
        });
      setChartData(newData?.sort(({ value }, { value: secondValue }) => secondValue - value));
    }
  }, [data?.filter]);

  return (
    <>
      <Spin spinning={loading}>
        <div className={styles.header} style={{ cursor: updateWidget ? 'grab' : '' }}>
          <div className={styles.header_left} onClick={handleNavigateFilter}>
            <h3>{data?.filter?.overallCount}</h3>
            {objectType === 'LEAD' && <span>{data?.filter?.totalBalance?.toLocaleString('ru') || 0} UZS</span>}
          </div>
          {data?.filterWithDate && !objectData?.startDate && (
            <div className={styles.header_right} onClick={handleNavigateDateFilter}>
              <h3>{data?.filterWithDate?.overallCount}</h3>
              <span>
                {searchParams?.startDate} - {searchParams?.finishDate}
              </span>
            </div>
          )}
        </div>
        <div className={styles.line} />
        {chartData?.length > 0 ? (
          <div className={styles.pieChart}>
            <div style={{ minWidth: 310, height: 310 }}>
              <ResponsivePie
                data={chartData}
                margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                innerRadius={0.5}
                padAngle={0}
                colors={{ datum: 'data.color' }}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                enableArcLinkLabels={false}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                tooltip={({ datum }) => (
                  <div className="box-border flex items-center gap-2 bg-white px-4 py-2 shadow-md">
                    <div>{datum?.label}</div>
                    <div className="h-4 w-4" style={{ background: datum?.color }} />
                    <div>{datum?.value}</div>
                  </div>
                )}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 4]]
                }}
                enableArcLabels={false}
              />
            </div>
            <div className={styles.pieChartCont}>
              {chartData?.map(element => {
                const filterCount =
                  data?.filterWithDate !== null &&
                  (objectType === 'LOG' && item?.groupBy === 'EVENT_TYPE'
                    ? data?.filterWithDate.leadList?.find(el => el[i18n.language as keyof typeof el] === element?.label)?.count
                    : data?.filterWithDate.leadList?.find(el => el?.field === element?.label)?.count);

                return (
                  <div className={styles.pieChartCont_rows} key={element?.id}>
                    <div className={cx(styles.pieChartCont_rows_item, { 'justify-between': !(data?.filterWithDate && !objectData?.startDate) })}>
                      <div className="flex items-center gap-2">
                        <div className={styles.circle} style={{ borderColor: element?.color }} />
                        <span>{fieldName(element)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span>{element?.value}</span>
                        {objectType === 'LEAD' && <span>({element?.balance?.toLocaleString('ru')} UZS)</span>}
                      </div>
                    </div>
                    <ConditionalRender if={data?.filterWithDate && !objectData?.startDate}>
                      <span className={styles.filterCount}>{filterCount || 0}</span>
                    </ConditionalRender>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </Spin>
    </>
  );
};

export default PieChartWidget;
