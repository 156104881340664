import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const MiniMenuIcon: React.FC<Props> = props => {
  const { className, onClick } = props;

  return (
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.0002 4.66666C8.73658 4.66666 9.33353 4.06971 9.33353 3.33333C9.33353 2.59695 8.73658 1.99999 8.0002 1.99999C7.26382 1.99999 6.66686 2.59695 6.66686 3.33333C6.66686 4.06971 7.26382 4.66666 8.0002 4.66666Z"
        fill="#464255"
      />
      <path
        d="M8.0002 9.33333C8.73658 9.33333 9.33353 8.73637 9.33353 8C9.33353 7.26362 8.73658 6.66666 8.0002 6.66666C7.26382 6.66666 6.66686 7.26362 6.66686 8C6.66686 8.73637 7.26382 9.33333 8.0002 9.33333Z"
        fill="#464255"
      />
      <path
        d="M8.0002 14C8.73658 14 9.33353 13.403 9.33353 12.6667C9.33353 11.9303 8.73658 11.3333 8.0002 11.3333C7.26382 11.3333 6.66686 11.9303 6.66686 12.6667C6.66686 13.403 7.26382 14 8.0002 14Z"
        fill="#464255"
      />
    </svg>
  );
};

export default MiniMenuIcon;
