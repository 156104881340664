import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";

export function useGetRequiredFields() {
    return useQuery(
        [queryKeys.GET_REQUIRED_FIELDS],
        async () => {
            const res = await $api.get(`${endPoints.GET_REQUIRED_FIELDS}`);

            return res.data.data;
        },

        {
            onError: baseErrorHandler,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );
}


export function useGetClientLead() {
    return useQuery(
        [queryKeys.GET_LEAD_CLIENT],
        async () => {
            const res = await $api.get(`${endPoints.GET_LEAD_CLIENT}`);

            return res.data.data;
        },

        {
            onError: baseErrorHandler,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );
}