import { useAppSelector } from "@hooks/reduxHooks";
import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { Permissions } from "@utils/constants/permissions";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { PushNotificationType } from "../utils/model/PushNotificationType";

export function useGetPushNotificationConfigAll(enabled: boolean = true) {
    const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
    const isAccessPushNotifications = permissions?.includes(Permissions.PERMISSION_PUSH_NOTIFICATION);

    // console.log('permissions', isAccessPushNotifications, enabled);

    return useQuery<PushNotificationType>(
        [queryKeys.PUSH_NOTIFICATIONS_CONFIG],
        async () => {
            const res = await $api.get(`${endPoints.PUSH_NOTIFICATIONS_CONFIG}`);

            return res.data.data;
        },

        {
            onError: baseErrorHandler,
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: enabled && isAccessPushNotifications,
        }
    );
}
