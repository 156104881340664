import { CrmFilterSaveData } from '../models/CrmFilterSaveData';

export const createCrmFilter = (data: CrmFilterSaveData | undefined, name: string, url: string) => {
  if (data?.filters) {
    let maxId = 1;
    data?.filters?.forEach(item => {
      if (maxId < item?.id) {
        maxId = item?.id;
      }
    });

    return {
      active: data?.active,
      filters: [...data?.filters, { id: maxId + 1, name: name.trim(), url }]
    };
  } else {
    return {
      active: 0,
      filters: [{ id: 1, name: name.trim(), url }]
    };
  }
};
