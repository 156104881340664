import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import ErrorBoundary from '@components/error-boundary/ErrorBoundary';
import Loading from '@components/loading/Loading';
import { setCurrentPage } from '@reducers/ContractSlice';
import { Grid, Layout } from 'antd';

import AccessDenied from '../../components/accessDenied/AccessDenied';
import ExpireDate from '../../components/expire-date/ExpireDate';
import MessageDrawer from '../../components/headers/components/message-drawer/MessageDrawer';
import MyNotification from '../../components/myNotification/MyNotification';
import NavBar from '../../components/navbars/main/NavBar';
import NotTaskLeadNotification from '../../components/notTaskLeadNotification/NotTaskLeadNotification';
import WebRtc from '../../components/webrtc/WebRtc';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { LocalStorage } from '../../service/LocalStorage';
import { COLLAPSED_MENU, REDIRECT_URL } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { rootPaths } from '../rootPaths';

import { MainPaths } from './mainPaths';
import mainRoutes from './mainRoutes';

const MainRouter = () => {
  const dispatch = useAppDispatch();
  const { push, location, params } = useQueryParam();
  const [colapsed, setColapsed] = useState(LocalStorage.get(COLLAPSED_MENU) || false);
  const { xxl } = Grid.useBreakpoint();

  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);

  const isAccessPayment = useMemo(() => permissions?.includes(Permissions.PERMISSION_CREATE_PAYMENT), [permissions]);

  useEffect(() => {
    if (!params['*']?.includes('contract')) {
      dispatch(setCurrentPage(1));
    }
  }, [params['*']]);

  useEffect(() => {
    window.addEventListener('keydown', zEvent => {
      if (zEvent.altKey && zEvent.code === 'KeyP' && isAccessPayment) {
        push(rootPaths.MAIN + MainPaths.PAYMENT);
      }
    });
    return () => {
      window.removeEventListener('keydown', () => {});
    };
  }, []);

  useEffect(() => {
    LocalStorage.set(REDIRECT_URL, location.pathname + location.search);
  }, [location]);

  const navbarFC: () => {
    position: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
    marginLeft: number;
  } = () => ({
    position: 'fixed',
    // eslint-disable-next-line no-nested-ternary
    marginLeft: colapsed ? (!xxl ? 88 * 0.7 : 88) : !xxl ? 235 * 0.7 : 235
  });

  return (
    <div>
      <MyNotification />
      <Layout style={{ height: '100vh', marginLeft: navbarFC().marginLeft }}>
        <NavBar setColapsed={setColapsed} colapsed={colapsed} style={{ position: navbarFC().position }} />
        <Layout className="bg">
          <Suspense fallback={<Loading />}>
            <ErrorBoundary>
              <Routes>
                {mainRoutes.map(route => (
                  <Route element={<route.component />} path={route.exact ? route.path : `${route.path}/*`} key={route.path} />
                ))}
                <Route path="*" element={<AccessDenied />} />
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </Layout>
      </Layout>
      <MessageDrawer />
      <NotTaskLeadNotification />
      <ExpireDate />
      <WebRtc />
    </div>
  );
};

export default MainRouter;
