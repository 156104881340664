import React, { FC } from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  checked?: boolean;
}

const ToggleCircleIcon: FC<IProps> = props => {
  return props?.checked ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.24016 2.57422H6.76016C3.76683 2.57422 1.3335 5.00755 1.3335 8.00089C1.3335 10.9942 3.76683 13.4276 6.76016 13.4276H9.24016C12.2335 13.4276 14.6668 10.9942 14.6668 8.00089C14.6668 5.00755 12.2335 2.57422 9.24016 2.57422ZM9.24016 10.9476C7.6135 10.9476 6.2935 9.62755 6.2935 8.00089C6.2935 6.37422 7.6135 5.05422 9.24016 5.05422C10.8668 5.05422 12.1868 6.37422 12.1868 8.00089C12.1868 9.62755 10.8668 10.9476 9.24016 10.9476Z"
        fill="#292D32"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.3335 13.8327H6.66683C3.44683 13.8327 0.833496 11.2127 0.833496 7.99935C0.833496 4.78602 3.44683 2.16602 6.66683 2.16602H9.3335C12.5535 2.16602 15.1668 4.78602 15.1668 7.99935C15.1668 11.2127 12.5535 13.8327 9.3335 13.8327ZM6.66683 3.16602C4.00016 3.16602 1.8335 5.33268 1.8335 7.99935C1.8335 10.666 4.00016 12.8327 6.66683 12.8327H9.3335C12.0002 12.8327 14.1668 10.666 14.1668 7.99935C14.1668 5.33268 12.0002 3.16602 9.3335 3.16602H6.66683Z"
        fill="#292D32"
      />
      <path
        d="M6.66667 11.1673C4.92 11.1673 3.5 9.74732 3.5 8.00065C3.5 6.25398 4.92 4.83398 6.66667 4.83398C8.41333 4.83398 9.83333 6.25398 9.83333 8.00065C9.83333 9.74732 8.41333 11.1673 6.66667 11.1673ZM6.66667 5.83398C5.47333 5.83398 4.5 6.80732 4.5 8.00065C4.5 9.19398 5.47333 10.1673 6.66667 10.1673C7.86 10.1673 8.83333 9.19398 8.83333 8.00065C8.83333 6.80732 7.86 5.83398 6.66667 5.83398Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default ToggleCircleIcon;
