import { z } from 'zod';

import { variation } from './variation';

export const variationGroup = z.object({
  name: z.string().min(1),
  groupId: z.number().nullable().optional(),
  parts: variation.array().optional()
});

export interface IVariationGroup extends z.infer<typeof variationGroup> {}
