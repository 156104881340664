import React, { useEffect, useState } from 'react';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import { Button, Dropdown, Form, Input, Modal, Popconfirm, Select, Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '../../../../../assets/icons/ArrowDownIconCrm';
import PlusIconAddCircle from '../../../../../assets/icons/PlusIconAddCircle';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { usePipeAll } from '../../../../../queries/queries';
import {
  setContactFieldsAdd,
  setContactFieldsDelete,
  setContactFieldsEdit,
  setLedFieldsAdd,
  setLedFieldsDelete,
  setLedFieldsEdit
} from '../../../../../store/reducers/CrmFieldSlice';
import { CrmField, CrmFieldOption, FieldsStatuses } from '../../../../../utils/models/CrmField';
import { CrmFieldsInfoType, CrmFieldsType } from '../../../../../utils/models/CrmFieldsType';

import styles from './editInputModal.module.scss';

const { DirectoryTree } = Tree;

interface Props {
  visible: boolean;
  handleClose: (visible: boolean) => void;
  data: CrmField | undefined;
  index: number | undefined;
  type: CrmFieldsInfoType;
  setIsDataChanged?: (data: boolean) => void;
}

const items = [
  { name: "O'zbekcha", label: 'nameUz' },
  { name: 'Русский', label: 'nameRu' },
  { name: 'English', label: 'nameEn' },
  { name: 'Turk', label: 'nameTr' }
];

const EditInputModal: React.FC<Props> = ({ visible, handleClose, type, data, index, setIsDataChanged }) => {
  const [activeTags, setActiveTags] = useState<
    {
      id: number;
      keys: string[];
    }[]
  >([]);

  const [options, setOptions] = useState<CrmFieldOption[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [typeSelect, setTypeSelect] = useState<CrmFieldsType>(CrmFieldsType.TEXT);
  const [nameLang, setNameLang] = useState<CrmFieldOption>({ name: '' });

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { data: dataPipeAll } = usePipeAll();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dataPipeAll) {
      const newTreeData: DataNode[] = [];

      for (let i = 0; i < dataPipeAll.length; i++) {
        const children = [];

        for (let j = 0; j < dataPipeAll[i].pipeStatuses.length; j++) {
          children.push({
            title: (
              <div
                style={{
                  // @ts-ignore
                  '--color': dataPipeAll[i].pipeStatuses[j].color
                }}
                className={styles.tree_item}
              >
                {dataPipeAll[i].pipeStatuses[j].name}
              </div>
            ),
            key: `${dataPipeAll[i].id  }-${  dataPipeAll[i].pipeStatuses[j].id  }-${  j}`
          });
        }

        newTreeData.push({
          title: <div className={styles.header_tree}>{dataPipeAll[i].name}</div>,
          key: dataPipeAll[i].id,
          children
        });
      }
      setTreeData(newTreeData);
    }
  }, [dataPipeAll]);

  useEffect(() => {
    if (visible && data && dataPipeAll) {
      setTypeSelect(data.type);
      setOptions(
        data?.options || [
          {
            name: ''
          }
        ]
      );
      setNameLang({
        name: data?.name,
        nameUz: data?.nameUz,
        nameRu: data?.nameRu,
        nameEn: data?.nameEn,
        nameTr: data?.nameTr
      });
      const obj: any = {};

      obj.name = data.name;
      obj.type = data.type;
      if (data?.options)
        for (let i = 0; i < data?.options.length; i++) {
          obj[`option${i}`] = data?.options[i].name;
        }
      form.setFieldsValue(obj);
      if (data?.fieldStatuses) {
        const keysList: {
          id: number;
          keys: string[];
        }[] = [];

        for (let i = 0; i < data?.fieldStatuses.length; i++) {
          const id = data.fieldStatuses[i].pipeId;
          const { statusId } = data.fieldStatuses[i];
          const pipe = dataPipeAll.find(item => id === item.id);
          const keys: string[] = [];

          if (pipe) {
            let isHave = false;

            for (let j = 0; j < pipe.pipeStatuses.length; j++) {
              if (statusId === pipe.pipeStatuses[j].id) {
                keys.push(`${pipe.id  }-${  pipe.pipeStatuses[j].id  }-${  j}`);
                isHave = true;
              } else if (isHave) {
                keys.push(`${pipe.id  }-${  pipe.pipeStatuses[j].id  }-${  j}`);
              }
            }
            keysList.push({
              keys,
              id
            });
          }
        }
        setActiveTags(keysList);
      }
    }
  }, [visible, form, data, dataPipeAll]);

  // active tags
  const activeTagsList = () => {
    if (activeTags) {
      let list: string[] = [];

      for (let i = 0; i < activeTags?.length; i++) {
        list = [...list, ...activeTags[i].keys];
      }
      return list;
    }
    return [];
  };

  // add option
  const addOption = () => {
    const newOptions = [...options];

    newOptions.push({
      name: ''
    });
    setOptions(newOptions);
  };

  // delete option
  const deleteOptions = (index: number) => {
    const newOptions = options.filter((item, i) => index !== i);

    const obj: any = {
      type: CrmFieldsType.SELECT,
      name: form.getFieldValue('name')
    };

    form.resetFields();

    for (let i = 0; i < newOptions.length; i++) {
      obj[`option${i}`] = newOptions[i].name;
    }

    form.setFieldsValue(obj);

    setOptions(newOptions);
  };

  // handle change option
  const handleChangeOption = (index: number, value: string) => {
    const newOptions = [...options];

    newOptions[index].name = value;
    setOptions(newOptions);
  };

  // handleCheck
  const handleCheck = (checkedKeys: any[], event: any) => {
    const isChecked: boolean = event.checked;
    const { key } = event.node;
    let id: number;

    if (typeof key === 'number') {
      id = key;
    } else {
      id = Number(key.split('-')[0]);
    }
    let keysArray: {
      id: number;
      keys: string[];
    }[] = [...activeTags];

    if (dataPipeAll) {
      const indexActiveTags = activeTags?.findIndex(item => item.id === id);

      if (indexActiveTags !== -1) {
        for (let i = 0; i < dataPipeAll.length; i++) {
          if (id === dataPipeAll[i].id) {
            if (typeof key === 'number') {
              if (isChecked) {
                const keys: string[] = [];

                for (let j = 0; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(`${dataPipeAll[i].id  }-${  dataPipeAll[i].pipeStatuses[j].id  }-${  j}`);
                }
                keysArray[indexActiveTags] = {
                  id,
                  keys
                };
              } else {
                keysArray = keysArray.filter(item => item.id !== id);
              }
            } else {
              const index = Number(key.split('-')[2]);

              if (isChecked) {
                const keys: string[] = [];

                for (let j = index; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(`${dataPipeAll[i].id  }-${  dataPipeAll[i].pipeStatuses[j].id  }-${  j}`);
                }
                keysArray[indexActiveTags] = {
                  id,
                  keys
                };
              } else if (index === dataPipeAll[i].pipeStatuses.length - 1) {
                  keysArray = keysArray.filter(item => item.id !== id);
                } else {
                  const keys: string[] = [];

                  for (let j = index + 1; j < dataPipeAll[i].pipeStatuses.length; j++) {
                    keys.push(`${dataPipeAll[i].id  }-${  dataPipeAll[i].pipeStatuses[j].id  }-${  j}`);
                  }
                  keysArray[indexActiveTags] = {
                    id,
                    keys
                  };
                }
            }
          }
        }
      } else {
        for (let i = 0; i < dataPipeAll.length; i++) {
          if (id === dataPipeAll[i].id) {
            if (typeof key === 'number') {
              if (isChecked) {
                const keys: string[] = [];

                for (let j = 0; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(`${dataPipeAll[i].id  }-${  dataPipeAll[i].pipeStatuses[j].id  }-${  j}`);
                }
                keysArray.push({
                  id,
                  keys
                });
              }
            } else {
              const index = Number(key.split('-')[2]);

              if (isChecked) {
                const keys: string[] = [];

                for (let j = index; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(`${dataPipeAll[i].id  }-${  dataPipeAll[i].pipeStatuses[j].id  }-${  j}`);
                }
                keysArray.push({
                  id,
                  keys
                });
              }
            }
          }
        }
      }
    }
    setActiveTags(keysArray);
  };

  // handle cancel modal
  const handleCancel = () => {
    form.resetFields();
    setTypeSelect(CrmFieldsType.TEXT);
    setNameLang({ name: '' });
    setOptions([
      {
        name: ''
      }
    ]);
    handleClose(false);
  };

  // on finish form
  const onFinish = (val: any) => {
    const field = getNewField(val);

    if (data?.id !== undefined) updateField(field);
    else addField(field);
    setActiveTags([]);
    handleCancel();
    setIsDataChanged && setIsDataChanged(true);
  };

  // get new filed
  const getNewField: (val: any) => CrmField = (val: any) => {
    const fieldStatuses: FieldsStatuses[] = [];

    for (let i = 0; i < activeTags.length; i++) {
      fieldStatuses.push({
        pipeId: activeTags[i].id,
        statusId: Number(activeTags[i].keys[0].split('-')[1])
      });
    }

    if (typeSelect === CrmFieldsType.TEXT) {
      return {
        type: typeSelect,
        name: val.name,
        nameUz: nameLang?.nameUz,
        nameRu: nameLang?.nameRu,
        nameEn: nameLang?.nameEn,
        nameTr: nameLang?.nameTr,
        infoType: type,
        id: data?.id || null,
        fieldStatuses,
        checked: true
      };
    } 
      return {
        type: typeSelect,
        name: val.name,
        nameUz: nameLang?.nameUz,
        nameRu: nameLang?.nameRu,
        nameEn: nameLang?.nameEn,
        nameTr: nameLang?.nameTr,
        infoType: type,
        id: data?.id || null,
        options,
        fieldStatuses,
        checked: true
      };
    
  };

  // add field
  const addField = (field: CrmField) => {
    if (type === CrmFieldsInfoType.LED) {
      dispatch(setLedFieldsAdd(field));
    } else {
      dispatch(setContactFieldsAdd(field));
    }
  };

  // update field
  const updateField = (field: CrmField) => {
    if (type === CrmFieldsInfoType.LED) {
      dispatch(
        setLedFieldsEdit({
          field,
          index: Number(index)
        })
      );
    } else {
      dispatch(
        setContactFieldsEdit({
          field,
          index: Number(index)
        })
      );
    }
  };

  // handle change select type
  const handleChangeSelectType = (value: CrmFieldsType) => {
    setTypeSelect(value);
    setOptions([
      {
        name: ''
      }
    ]);
  };

  // handle delete
  const handleDelete = () => {
    if (type === CrmFieldsInfoType.LED) {
      dispatch(setLedFieldsDelete(Number(index)));
    } else {
      dispatch(setContactFieldsDelete(Number(index)));
    }
    handleCancel();
  };

  // handleDeleteLang
  const handleDeleteLang = (optionKey: string, index: number) => {
    setOptions(prev => [
      ...prev.map((value, i) =>
        i === index
          ? {
              ...value,
              [optionKey]: null
            }
          : value
      )
    ]);
  };

  return (
    <Modal width={530} closable={false} title="" onCancel={handleCancel} className={styles.container} visible={visible} footer={false}>
      <Dropdown
        placement="bottom"
        overlayClassName={styles.container_dropdown}
        overlay={<DirectoryTree checkable checkedKeys={activeTagsList()} onCheck={handleCheck as any} treeData={treeData} />}
        trigger={['click']}
      >
        <div className={styles.container_step}>
          <div className={styles.container_step_title}>{t('one_lid.Qadamlarda_majburiyligi')}</div>
          <ArrowDownIcon />
        </div>
      </Dropdown>
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
        <Form.Item
          rules={[{ required: true, message: '' }]}
          name="type"
          label={<span className={styles.container_label}>{t('one_lid.Turi')}</span>}
        >
          <Select onChange={handleChangeSelectType} suffixIcon={<ArrowDownIcon />} className="customSelect">
            <Select.Option value={CrmFieldsType.SELECT}>{t('one_lid.Royxat')}</Select.Option>
            <Select.Option value={CrmFieldsType.TEXT}>{t('one_lid.Matn')}</Select.Option>
            <Select.Option value={CrmFieldsType.COUNTER}>{t('home.COUNTER')}</Select.Option>
            <Select.Option value={CrmFieldsType.LOCATION}>{t('shaxmatka.joylashuvi')}</Select.Option>
            <Select.Option value={CrmFieldsType.ADDRESS}>{t('settingEmployee.Addres')}</Select.Option>
            <Select.Option value={CrmFieldsType.MULTI_SELECT}>{t('one_lid.Multi_royxat')}</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.formName}>
          <Form.Item
            rules={[{ required: true, message: '' }]}
            name="name"
            label={<span className={styles.container_label}>{t('one_lid.Nomi')}</span>}
          >
            <Input />
          </Form.Item>
          <Dropdown
            overlay={
              <div className={styles.formName_dropDown}>
                {items?.map(item => (
                  <span
                    onClick={() => {
                      !nameLang[item.label as keyof typeof nameLang] &&
                        setNameLang(prev => ({
                          ...prev,
                          [item.label]: ''
                        }));
                    }}
                  >
                    {item?.name}
                  </span>
                ))}
              </div>
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <span>
              <DotsIcon />
            </span>
          </Dropdown>
        </div>
        {Object.keys(nameLang)
          ?.filter(key => key?.includes('name') && key?.length > 4 && nameLang[key as keyof typeof nameLang] !== null)
          .map(optionKey => (
            <div className={styles.nameTranslate} key={optionKey}>
              <Input
                value={nameLang[optionKey as keyof typeof nameLang]}
                addonBefore={optionKey?.substring(6, 4)}
                onChange={e =>
                  setNameLang(prev => ({
                    ...prev,
                    [optionKey]: e.target.value
                  }))
                }
              />
              <span
                onClick={() => {
                  setNameLang(prev => ({
                    ...prev,
                    [optionKey]: null
                  }));
                }}
              >
                <TrushSquareIcon />
              </span>
            </div>
          ))}

        {(typeSelect === CrmFieldsType.SELECT || typeSelect === CrmFieldsType.MULTI_SELECT) && (
          <>
            {options.map((item, index) => (
              <>
                <Form.Item
                  rules={[{ required: true, message: '' }]}
                  name={`option${index}`}
                  label={
                    <span className={styles.container_label}>
                      {index + 1}
                      {t('one_lid.variant')}
                    </span>
                  }
                >
                  <div className="d-flex align-center">
                    <Input value={item.name} onChange={e => handleChangeOption(index, e.target.value)} />
                    <span className={styles.container_delete_icon}>
                      <Dropdown
                        overlay={
                          <div className={styles.formName_dropDown}>
                            <Dropdown
                              overlay={
                                <div className={styles.formName_dropDown}>
                                  {items?.map(item => (
                                    <span
                                      onClick={() =>
                                        setOptions(prev => [
                                          ...prev.map((value, i) =>
                                            i === index && !value[item?.label as keyof typeof value]
                                              ? {
                                                  ...value,
                                                  [item?.label]: ''
                                                }
                                              : value
                                          )
                                        ])
                                      }
                                    >
                                      {item?.name}
                                    </span>
                                  ))}
                                </div>
                              }
                              placement="bottomRight"
                              trigger={['click']}
                            >
                              <span>{t('home.Tarjima_qilish')}</span>
                            </Dropdown>

                            <span onClick={() => deleteOptions(index)}>
                              <TrushSquareIcon />
                              {t('contract.Ochirish')}
                            </span>
                          </div>
                        }
                        trigger={['click']}
                      >
                        <span>
                          <DotsIcon />
                        </span>
                      </Dropdown>
                    </span>
                  </div>
                </Form.Item>
                {Object.keys(item)
                  ?.filter(key => key?.includes('name') && key?.length > 4 && item[key as keyof typeof item] !== null)
                  .map(optionKey => (
                    <div className={styles.nameTranslate}>
                      <Input
                        value={item[optionKey as keyof typeof item]}
                        onChange={e => {
                          setOptions(prev => [
                            ...prev.map((value, i) =>
                              i === index
                                ? {
                                    ...value,
                                    [optionKey]: e.target.value
                                  }
                                : value
                            )
                          ]);
                        }}
                        addonBefore={optionKey?.substring(6, 4)}
                      />
                      <span onClick={() => handleDeleteLang(optionKey, index)}>
                        <TrushSquareIcon />
                      </span>
                    </div>
                  ))}
              </>
            ))}

            <div onClick={addOption} className={styles.container_add}>
              <PlusIconAddCircle />
              <span>{t('one_lid.Yangi_qoshish')}</span>
            </div>
          </>
        )}
        <div className="d-flex align-center justify-space-between">
          {typeof index === 'number' ? (
            <Popconfirm
              title={t('one_lid.Diqqat_amal_bilan_siz_shu_maydondagi_hamma_malumotni_ochirasiz')}
              onConfirm={handleDelete}
              okText={t('one_lid.Tasdiqlash')}
              cancelText={t('one_lid.Bekor_qilish')}
            >
              <span>
                <TrushSquareIcon className={styles.container_delete_all} />
              </span>
            </Popconfirm>
          ) : (
            <div />
          )}
          <div className={styles.container_modalFooter}>
            <Button onClick={handleCancel}>{t('one_lid.Yopish')}</Button>
            <Button htmlType="submit" type="primary">
              {t('one_lid.Saqlash')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditInputModal;
