import React, { useState } from 'react';
import TableConfigDrawer from '@components/filters/TableConfigDrawer';
import { useQueryParam } from '@hooks/useQueryParams';
import FilterIcon from '@icons/FilterIcon';
import SearchIcon from '@icons/SearchIcon';
import Setting3Icon from '@icons/Setting3Icon';
import { TableKey } from '@utils/models/TableKey';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import MonetaryFilterDriver from '../monetary-filter-driver/MonetaryFilterDriver';

import styles from './monetaryheader.module.scss';

const Monetaryheader = () => {
  const { appendMultipleDifferent, searchParams } = useQueryParam<any, any>();
  const [openFilter, setOpenFilter] = useState(false);
  const [openTableConfig, setOpenTableConfig] = useState(false);
  const [search, setSearch] = useState(searchParams.search);
  const { t } = useTranslation();

  const changeSearch = (value: string) => {
    setSearch(value);
    appendMultipleDifferent(['search'], [value]);
  };

  const handleFilter = () => {
    setOpenFilter(true);
  };

  const handleTableConfig = () => {
    setOpenTableConfig(true);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_left}>
          <div className={styles.container_left_item}>
            <div className={styles.container_left_item_content} onClick={handleFilter}>
              <FilterIcon size={20} /> {t('home.Filtr')}{' '}
              {/* <span className={styles.container_left_item_content_badge}>
                1
              </span> */}
            </div>
          </div>
          <div className={styles.container_left_item}>
            <div className={styles.container_left_item_content} onClick={handleTableConfig}>
              <Setting3Icon /> {t('home.Jadval_sozlamalari')}
            </div>{' '}
          </div>
        </div>
        <div className={styles.container_right}>
          <Input
            placeholder={t('home.Qidirish')}
            className="customInput"
            prefix={<SearchIcon />}
            value={search}
            onChange={e => changeSearch(e.target.value)}
          />
        </div>
      </div>
      <MonetaryFilterDriver setVisible={setOpenFilter} visible={openFilter} />
      <TableConfigDrawer tableKey={TableKey.monetary} visible={openTableConfig} onClose={setOpenTableConfig} />
    </>
  );
};

export default Monetaryheader;
