import React, { FC } from 'react';
import { useAppDispatch } from '@hooks/reduxHooks';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { Button, Form, Modal, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import SidebarArrowRightIcon from '../../../../../../assets/icons/SidebarArrowRightIcon';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { usePipeAll } from '../../../../../../queries/queries';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { useGroupActionCrmViewList } from '../../../../services/mutation';
import { SearchParamsCrm } from '../../../filter/FilterButton';

import styles from './modals.module.scss';

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
  balance: number | undefined;
}

const ChangePipeStatus: FC<IProps> = ({ visible, close, ids, balance }) => {
  const dispatch = useAppDispatch();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();

  const [form] = Form.useForm();

  const { data: pipeAll } = usePipeAll();

  const { t } = useTranslation();
  const qc = useQueryClient();
  const pipeStatusIds = JSON.parse(localStorage.getItem('pipeIds')! || '');

  // handle cancel modal
  const handleCancel = () => {
    close(false);
  };

  // handle success
  function handleSuccess() {
    handleCancel();
    form.resetFields();
  }

  const changeEmployee = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);

  // on finish
  const onFinish = (val: { pipeStatusId: number }) => {
    const isThisPipe = pipeAll?.find(item => item?.id === Number(searchParams?.voronka))?.pipeStatuses?.some(prev => prev?.id === val?.pipeStatusId);

    changeEmployee
      .mutateAsync({
        pipeStatusId: val.pipeStatusId,
        requestMethodType: RequestMethodTypeGroupActions.CHANGED_PIPE_STATUS,
        leadIds: ids
      })
      .then(res => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        isThisPipe ? dispatch(setBalanceAndIds({ ids, balance })) : dispatch(setBalanceAndIds({ ids: [], balance: 0 }));
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
      });
  };

  return (
    <Modal className={styles.container} width={560} footer={false} visible={visible} title={t('list_view.Statusni_ozgartirish')} onCancel={handleCancel}>
      <Form onFinish={onFinish} form={form} name="changeEmployeeListViewCrm">
        <Form.Item label="" name="pipeStatusId" rules={[{ required: true, message: '' }]}>
          <Form.Item name="pipeStatusId" noStyle>
            <TreeSelect
              placeholder={t('home.Tanlang')}
              showArrow={false}
              treeExpandAction="click"
              switcherIcon={<SidebarArrowRightIcon />}
              suffixIcon={<ArrowDownIcon />}
              className={styles.container_item_tree}
              dropdownClassName="treePipeStatus"
            >
              {pipeAll?.map(item => (
                <TreeSelect.TreeNode
                  title={item.name}
                  value={item.id}
                  key={item.id}
                  selectable={false}
                  children={item.pipeStatuses.map(pipe => (
                    <TreeSelect.TreeNode
                      key={pipe?.id}
                      value={pipe.id!}
                      title={
                        <div
                          // @ts-expect-error
                          // eslint-disable-next-line react/no-unknown-property
                          pageName={item?.name!}
                        >
                          <span
                            style={{
                              background: pipe.color,
                              color: '#FFFFFF',
                              padding: '2px 7px',
                              borderRadius: '5px',
                              fontSize: 10
                            }}
                          >
                            {pipe.name}
                          </span>
                        </div>
                      }
                    />
                  ))}
                />
              ))}
            </TreeSelect>
          </Form.Item>
        </Form.Item>
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button loading={changeEmployee.isLoading} htmlType="submit" type="primary">
              {t('home.Yuborish')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePipeStatus;
