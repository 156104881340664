import React, { Ref, useEffect, useState } from 'react';
import { helper } from '@utils/helper/helper';
import { Input, InputProps, InputRef } from 'antd';

export type ICustomeInput = 'text' | 'price' | 'number' | 'intNumber';

export interface CustomeInputProps extends InputProps {
  type?: ICustomeInput;
  value?: any;
  onChange?: (value: any) => void;
  ref?: Ref<InputRef>;
  maxNumber?: number;
}

const CustomeInput = (props: CustomeInputProps) => {
  const { type = 'text', onChange, value, maxNumber } = props;
  const [val, setVal] = useState(String(value));
  const changeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'price') {
      if (maxNumber !== undefined && helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number >= maxNumber) {
        setVal(maxNumber?.toLocaleString('ru'));
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onChange && onChange(maxNumber);
      } else {
        setVal(e.target.value === '' ? '' : helper.deleteNotNumbersAndParseFloatNumber(e.target.value).string);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onChange && onChange(e.target.value === '' ? null : helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number);
      }
    } else if (type === 'number') {
      setVal(e.target.value === '' ? '' : helper.deleteNotNumbersToNumber(e.target.value).string);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChange && onChange(e.target.value === '' ? null : helper.deleteNotNumbersToNumber(e.target.value).number);
    } else if (type === 'intNumber') {
      setVal(e.target.value);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChange && onChange(e.target.value ? Number(e.target.value) : null);
    } else {
      setVal(e.target.value);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onChange && onChange(e.target.value);
    }
  };

  const getValue = () => {
    const obj = {
      price: helper.deleteNotNumbersAndParseFloatNumber(String(value)).string,
      number: helper.deleteNotNumbersToNumber(String(value)).string,
      text: String(value),
      intNumber: String(value)
    };

    return obj[type];
  };

  useEffect(() => {
    setVal(getValue());
  }, [getValue()]);

  return (
    <Input
      {...props}
      {...(props?.ref && { ref: props?.ref })}
      onKeyDown={e => {
        if (type === 'intNumber') {
          if ('+e/'.includes(e.key)) {
            e.preventDefault();
          }
        }
      }}
      type={type === 'intNumber' ? 'number' : 'text'}
      value={val}
      onChange={changeHandle}
    />
  );
};

export default CustomeInput;
