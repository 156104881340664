import React from 'react';
import styles from '../main-page/index.module.scss';
import { FlatMore } from '../../../../../utils/models/FlatMore';
import { BASE_URL } from '../../../../../service/RequestService';

interface IProps {
  item: FlatMore;
  setSendImages: React.Dispatch<React.SetStateAction<number[][]>>;
  sendImages: number[][];
  flatIndex: number;
  index: number;
  id: number;
}

const ImageSlider: React.FC<IProps> = ({ item, sendImages, setSendImages, flatIndex, index, id }) => {
  const slides = item?.flat?.gallery;

  const goToSlide = (slideIndex: any) => {
    let arr = [...sendImages];
    arr[flatIndex][index] = slideIndex;
    setSendImages(arr);
  };

  const img = slides && slides?.length ? slides?.find(item => item?.id === id) : 0;

  return (
    <>
      <div className={styles.sliderStyles}>
        <img src={BASE_URL + '/' + (slides && img ? img?.files?.thumbAbsolutePath || img?.files?.absolutePath : '')} alt="" />
      </div>
      {Object(slides).length > 1 ? (
        <div className={styles.dots}>
          {slides?.map((item, index) => {
            return (
              <div className={id === item?.id ? styles.dot : styles.dot1} key={index} onClick={() => goToSlide(item?.id)}>
                {index + 1}
              </div>
            );
          })}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default ImageSlider;
