export const getInputWidth = ({
  value,
  className,
  minWidth,
  maxWidth
}: {
  value: string;
  className?: string;
  minWidth?: number;
  maxWidth?: number;
}) => {
  const input = document.createElement('div');
  let width = 0;
  input.innerHTML = value;
  input.className = className || '';
  input.setAttribute('style', `position:fixed;opacity:0;pointer-events:none;width:auto;`);
  document.body.appendChild(input);
  width = input.clientWidth + 32;
  input.remove();

  return width;
};
