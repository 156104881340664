import React from 'react';
import { useDispatch } from 'react-redux';
import SettingsIcon from '@icons/settings-icon';
import { useGetPlanFact } from '@pages/bossCrm/services/queries';
import { queryKeys } from '@pages/bossCrm/utils/constants/queryKeys';
import { setPlanFactConfig } from '@reducers/CrmStatisticsSlice';
import { isEmptyArr } from '@utils/index';
import { Empty, Spin } from 'antd';
import { TooltipShortName } from 'global';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import Config from './config';
import Employee from './employee';

import styles from './index.module.scss';

const Top: React.FC = () => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { planFact, isLoading } = useGetPlanFact();

  const onConfig = () => {
    qc.invalidateQueries([queryKeys.PLAN_FACT_CONFIG, planFact?.id]).then(() => {
      dispatch(
        setPlanFactConfig({
          id: planFact?.id,
          visible: true,
          isLoading: false
        })
      );
    });

    dispatch(
      setPlanFactConfig({
        id: planFact?.id,
        visible: true,
        isLoading: true
      })
    );
  };

  return (
    <Spin spinning={isLoading}>
      <div className="box-border flex flex-col gap-8 rounded-xl border border-solid border-gray-300 p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <span className="text-3xl font-bold text-gray-700">{t('crm_plan_fact.Plan Fakt')}</span>
            <span className="text-3xl font-bold text-progress-500">{planFact?.pipeName}</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1.5">
              <div className="h-6 w-6 rounded-md bg-gray-500" />
              <span className="text-base font-medium text-gray-600">{t('crm_plan_fact.Fakt')}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <div className={styles.plan} />
              <span className="text-base font-medium text-gray-600">{t('crm_plan_fact.Plan')}</span>
            </div>
            <div className="h-6 w-[0.063rem] bg-gray-300" />
            <div onClick={onConfig} className="flex cursor-pointer items-center justify-center">
              <SettingsIcon />
            </div>
          </div>
        </div>
        <ConditionalRender if={!isEmptyArr(planFact?.factPipeStatusList)} else={<Empty description={t("crm_plan_fact.Ma'lumotlar mavjud emas")} />}>
          <div className="flex flex-col gap-8">
            {planFact?.factPipeStatusList?.map(({ pipeStatusResponse, id, actualLeadCount, percentage, planLeadCount }) => (
              <div className="flex items-center justify-between" key={id}>
                <div className="flex w-72 flex-col">
                  {TooltipShortName({ showArrow: false, title: pipeStatusResponse?.name, length: 23, contentClassName: 'text-lg font-semibold text-gray-600' })}
                  <span className="text-base font-normal text-progress-500">
                    {actualLeadCount} / {planLeadCount}
                  </span>
                </div>
                <div
                  className={styles.bar}
                  style={{
                    background: `${pipeStatusResponse?.color}50`
                  }}
                >
                  <div
                    className={styles.bar__content}
                    style={{
                      width: `${percentage}%`,
                      background: pipeStatusResponse?.color
                    }}
                  />
                </div>
                <span className="w-20 text-center text-lg font-medium text-gray-600">{percentage} %</span>
              </div>
            ))}
          </div>
        </ConditionalRender>
        <div className="flex flex-col">
          <div className="box-border flex flex-col gap-4 rounded-2xl border border-solid border-gray-300 bg-gray-50 p-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="text-2xl font-semibold text-gray-600">{t('crm_plan_fact.Sotuv summasi')}</span>
                <div className="flex items-center gap-2 text-xl font-medium text-progress-500">
                  <span>{planFact?.actualAmount?.toLocaleString('ru')}</span>
                  <ConditionalRender if={typeof planFact?.planAmount === 'number'}>
                    <span>/</span>
                  </ConditionalRender>
                  <span>{planFact?.planAmount?.toLocaleString('ru')}</span>
                </div>
              </div>
              <div className="flex items-center gap-2 text-xl font-semibold text-gray-600">
                <span>{planFact?.percentageAmount || 0} %</span>
                <ConditionalRender if={planFact?.percentageAmount && planFact?.percentageAmount > 100}>
                  <span>🤯</span>
                </ConditionalRender>
              </div>
            </div>
            <div className="flex h-8 overflow-hidden rounded-md bg-primary-50">
              <div
                className="rounded-md bg-primary"
                style={{
                  width: `${planFact?.percentageAmount || 0}%`
                }}
              />
            </div>
          </div>
        </div>
        <Employee employees={planFact?.factEmployeeDtoList} />
      </div>
      <Config />
    </Spin>
  );
};

export default Top;
