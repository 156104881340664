import React, { useEffect, useRef } from 'react';
import { ContractReqProps } from '@pages/createContract/CreateContract';
import { useContractField } from '@queries/queries';
import { Permissions } from '@utils/constants/permissions';
import message from '@utils/helper/message';
import { roundFloatNumber } from '@utils/helper/roundFloatNumber';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useCreateContract } from '../../../queries/mutation';
import { MainPaths } from '../../../routing/main/mainPaths';
import { clear } from '../../../store/reducers/CreateContractSlice';
import { CreateContract } from '../../../utils/models/CreateContract';

import styles from './footerButtons.module.scss';

interface IProps {
  contractFields: ContractReqProps[];
  setContractFields: React.Dispatch<React.SetStateAction<ContractReqProps[]>>;
}

const FooterButtons: React.FC<IProps> = ({ contractFields, setContractFields }) => {
  const { t } = useTranslation();
  const { push, splitUrl, location, params } = useQueryParam();
  const { data: fieldsData } = useContractField();
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const dispatch = useAppDispatch();

  const isPressButton = useRef(false);

  const createContract = useCreateContract();
  const {
    prepaymentDate,
    delay,
    flats,
    delayInterval,
    employeeId,
    brokerId,
    prePayment,
    comment,
    fields,
    discountText,
    discountSum,
    contractNumber,
    residueTablePayments,
    changeContractNumber,
    currency,
    totalAmount,
    discountId,
    variation,
    constTotalAmount
  } = useAppSelector(state => state.createContract);

  // console.log(contractFields, 'fieldsData');

  const mortgagePrice = roundFloatNumber((totalAmount / 100) * (variation?.mortgagePercent || 0));

  const monthlyPayment = () => roundFloatNumber(totalAmount - discountSum - prePayment - mortgagePrice);
  const monthlyPayment2 = () => roundFloatNumber(totalAmount - prePayment - mortgagePrice);
  const changePrice = roundFloatNumber((constTotalAmount / 100) * (variation?.changePricePercent || 0));

  const discountPercent2 = roundFloatNumber(discountSum / (((constTotalAmount || totalAmount) + changePrice) / 100));

  const isMortgage = variation?.mortgagePrice ? !!variation?.mortgagePaymentDate : true;

  const hasValue = contractFields?.filter(item => item?.required)?.every(prev => prev?.value?.length > 0);

  const handleCreateContract = () => {
    if (hasValue) {
      if (isMortgage) {
        if (residueTablePayments === 0) {
          const flatsForCreate: { flatId: number; repaired: boolean }[] = [];

          flats.forEach(flat => {
            flatsForCreate.push({
              flatId: Number(flat.flat.id),
              repaired: flat.repaired
            });
          });
          const changePrice = roundFloatNumber((constTotalAmount / 100) * (variation?.changePricePercent || 0));
          const createContractData: CreateContract = {
            flats: flatsForCreate,
            delay: delay || 6,
            delayInterval: delayInterval || 1,
            discount: discountSum || undefined,
            discountDescription: discountText || undefined,
            prepayment: roundFloatNumber(prePayment),
            comment: comment || null,
            clientId: Number(params.idClient?.split('=')[1]),
            brokerId,
            prepaymentDate,
            number: changeContractNumber ? contractNumber : null,
            fields,
            responsibleBy: employeeId,
            currencyId: currency?.id || 0,
            discountPartId: discountId === 0 ? null : discountId,
            discountPercent:
              // eslint-disable-next-line no-nested-ternary
              isMultiPayment
                ? discountPercent2
                : // eslint-disable-next-line no-nested-ternary
                  discountId === 0 && discountSum !== 0
                  ? Number(((discountSum * 100) / totalAmount)?.toString()?.substring(0, 4))
                  : discountSum === 0
                    ? null
                    : null,

            monthlyPaymentAmount: isMultiPayment ? monthlyPayment2() : monthlyPayment(),
            ...(variation?.changePricePercent && {
              offerAmount: changePrice
            }),
            ...(variation?.mortgagePercent && {
              mortgage: mortgagePrice,
              mortgagePaymentDate: variation?.mortgagePaymentDate!
            })
          };

          createContract.mutate(createContractData);
        } else {
          message.error(t('create_table_payments.Tolov_jadvali_xato_toldirilgan'));
        }
      } else {
        message.error('Ipoteka sanasini kiriting!');
      }
    } else {
      // eslint-disable-next-line no-nested-ternary
      const newFields = contractFields?.map(item => ({ ...item, border: item?.value?.length > 0 ? '#d9d9d9' : item?.required ? '#FF3F40' : '#d9d9d9' }));

      setContractFields(newFields);
    }
  };

  useEffect(
    () => () => {
      if (!isPressButton.current) {
        dispatch(clear());
      }
    },
    []
  );

  // handleCreateTable
  const handleCreateTable = () => {
    isPressButton.current = true;
    push(location.pathname + MainPaths.CREATE + MainPaths.TABLE_PAYMENT);
  };

  // handle back
  const handleBack = () => {
    isPressButton.current = true;
    let url = '';

    for (let i = 0; i <= splitUrl.length - 3; i++) {
      url += splitUrl[i];
      if (i !== splitUrl.length - 3) {
        url += '/';
      }
    }
    push(url);
  };

  return (
    <div className={styles.container}>
      <Button onClick={handleBack} type="primary" className={styles.container_cancel_button}>
        {t('create_table_payments.Orqaga')}
      </Button>
      <Button onClick={handleCreateTable} type="primary" className={styles.container_cancel_button}>
        {t('create_table_payments.Jadval_shakllantirish')}
      </Button>
      <Button onClick={handleCreateContract} loading={createContract.isLoading} className={styles.container_next_button} type="primary">
        {t('home_id.Shartnoma_yaratish')}
      </Button>
    </div>
  );
};

export default FooterButtons;
