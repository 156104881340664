import { Collapse, Drawer, Select } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '@utils/constants/format';
import FIlterColapseIcon from '@icons/FIlterColapseIcon';
import ColapseIcon from '@icons/ColapseIcon';
import FilterReloadIcon from '@icons/FilterReloadIcon';
import DatePicker from '@service/datePicker';
import dayjs from 'dayjs';
import { useQueryParam } from '@hooks/useQueryParams';
import { useGetAccountNumbers } from '@pages/monetary/services/queries';
import styles from '../../monetary.module.scss';

const { RangePicker } = DatePicker;

const { Panel } = Collapse;

interface MonetaryFilterDriverProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const MonetaryFilterDriver: FC<MonetaryFilterDriverProps> = ({ setVisible, visible }) => {
  const { appendMultipleDifferent, searchParams, removeAllSearchParams, removeMultiple } = useQueryParam<any, any>();
  const { t } = useTranslation();
  const { data: dataSelect } = useGetAccountNumbers();

  const options = dataSelect
    ? dataSelect.map((account: any) => ({
        value: account.accountNumber,
        label: (
          <div key={account.accountNumber} className={styles.selectTextStyles}>
            <span>{account.accountName.length > 20 ? account.accountName.substring(0, 20) + '...' : account.accountName}</span>
            <p>{account.accountNumber}</p>
          </div>
        )
      }))
    : [];

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const removeContractId = () => {
    if (searchParams.contractId) {
      removeMultiple(['contractId'], ['']);
    }
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'finishDate'], formatString);
    removeContractId();
  };

  const handleChange = (value: string) => {
    appendMultipleDifferent(['accountNumbers'], [value]);
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className={'filter_drawer'}>
      <h1>
        <p>{t('home.Filtr')}</p>
        <span onClick={deleteAllFilters}>
          <FilterReloadIcon />
          {t('home.Tozalash')}
        </span>
      </h1>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition={'right'}
        className="filter_drawer_colapse"
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s'
            }}
          >
            <ColapseIcon />
          </span>
        )}
      >
        <Panel
          header={
            <>
              <FIlterColapseIcon />
              {t('home.Sana')}
            </>
          }
          key="1"
        >
          <RangePicker
            format={DATE_FORMAT}
            value={
              searchParams.startDate && searchParams.finishDate
                ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.finishDate, DATE_FORMAT)]
                : [null, null]
            }
            onChange={changeDate}
            className="CustomRangePicker"
            placeholder={[t('changes.contract.kk.oo.yyyy'), t('changes.contract.kk.oo.yyyy')]}
          />
        </Panel>

        <Panel header={t('home.Hisob_raqam')} key="2">
          <Select
            className="customSelect"
            placeholder={t('crm_ins.Tanlang')}
            style={{ width: 180 }}
            onChange={handleChange}
            options={options}
            mode="multiple"
            // maxTagCount={'responsive'}
          />
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default MonetaryFilterDriver;
