import React, { useEffect } from 'react';
import { Layout } from 'antd';

import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { setClearChanges } from '../../store/reducers/ChangesSlice';
import { ChangesPageSteps, ChangesQueryParams } from '../../utils/models/Changes';

import Client from './components/client/Client';
import Contract from './components/contract/Contract';
import MonthlyPayments from './components/monthlyPayments/MonthlyPayments';
import Payments from './components/payments/Payments';
import TabsContainer from './components/TabsContainer';

import styles from './changes.module.scss';

const { Content } = Layout;

const Changes = () => {
  const { searchParams, appendSingle } = useQueryParam<ChangesQueryParams, any>();

  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(setClearChanges());
    },
    [searchParams?.clientId]
  );

  useEffect(() => {
    if (Object.keys(searchParams).length === 0) {
      dispatch(setClearChanges());
      appendSingle('page', ChangesPageSteps.client);
    }
  }, [searchParams]);

  const showActiveTab = () => {
    switch (searchParams.page) {
      case ChangesPageSteps.client:
        return <Client />;
      case ChangesPageSteps.contract:
        return <Contract />;
      case ChangesPageSteps.monthlyPayments:
        return <MonthlyPayments />;
      case ChangesPageSteps.payments:
        return <Payments />;
      default:
        return <Client />;
    }
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.container}>
          <TabsContainer />
          {showActiveTab()}
        </div>
      </Content>
    </>
  );
};

export default Changes;
