const DeleteIconTaskCreator = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 4.99935V4.33268C12.3333 3.39926 12.3333 2.93255 12.1517 2.57603C11.9919 2.26243 11.7369 2.00746 11.4233 1.84767C11.0668 1.66602 10.6001 1.66602 9.66667 1.66602H8.33333C7.39991 1.66602 6.9332 1.66602 6.57668 1.84767C6.26308 2.00746 6.00811 2.26243 5.84832 2.57603C5.66667 2.93255 5.66667 3.39926 5.66667 4.33268V4.99935M7.33333 9.58268V13.7493M10.6667 9.58268V13.7493M1.5 4.99935H16.5M14.8333 4.99935V14.3327C14.8333 15.7328 14.8333 16.4329 14.5608 16.9677C14.3212 17.4381 13.9387 17.8205 13.4683 18.0602C12.9335 18.3327 12.2335 18.3327 10.8333 18.3327H7.16667C5.76654 18.3327 5.06647 18.3327 4.53169 18.0602C4.06129 17.8205 3.67883 17.4381 3.43915 16.9677C3.16667 16.4329 3.16667 15.7328 3.16667 14.3327V4.99935"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DeleteIconTaskCreator;
