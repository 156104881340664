import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';

export const useAutoTaskConfigSave = () => {
  const qc = useQueryClient();

  return useMutation(
    async (data: { id?: number; taskTermStatus: string; dayOfTerm: number; comment: string; automaticCompleted: boolean; status: false; employees: number[] }) => {
      const res = await $api.post('/v1/auto-task-setting', data);

      return res.data.data;
    },
    {
      onSuccess: async () => {
        await qc.invalidateQueries([queryKeys.AUTO_TASK_CONFIG]);
      }
    }
  );
};
