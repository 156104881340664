import { Input, InputRef, Switch } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import {
  changeFaildContent,
  changeFaildLabel,
  changeFaildMessage,
  changeFieldRequired,
  removeField
} from '../../../../../../store/reducers/TextEditorSlice';
import EditIcon from '../../../../../../assets/icons/EditIcon';
import DeleteAreaArageIcon from '../../../../../../assets/icons/areaarage/DeleteAreaArageIcon';

import styles from './draggingInput.module.scss';

type Props = {
  type: string | undefined;
  disabled: boolean | undefined;
  label: string | undefined;
  id: number | null | undefined;
  placeholder: string;
  index: number;
  required: boolean;
  message: string;
};

const DraggingInput: React.FC<Props> = ({ type, disabled, label, id, placeholder, index, required, message }) => {
  const [active, setActive] = useState<boolean>(false);
  const [mouseEvent, setMouseEvent] = useState(false);
  const ref = useRef<InputRef | null>(null);
  const placeholderRef = useRef<InputRef | null>(null);
  const dispatch = useAppDispatch();

  const { main, textPosition, fieldsStyle } = useAppSelector(state => state.textEditorReducer);

  const handleRef = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };
  const handleStopPropagation = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const windowClick = () => {
      if (mouseEvent) {
        setActive(false);
      }
    };

    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, [mouseEvent]);

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: active ? '#fff' : '' }}
      onMouseDown={handleStopPropagation}
      onMouseEnter={() => setMouseEvent(false)}
      onMouseLeave={e => {
        handleStopPropagation(e);
        setMouseEvent(true);
        ref?.current?.blur();
      }}
      onMouseMove={handleStopPropagation}
    >
      {active ? (
        <span
          className={styles.container_done}
          onClick={() => {
            setActive(false);
            ref.current?.blur();
            placeholderRef.current?.blur();
          }}
        >
          Done
        </span>
      ) : (
        label !== 'Phone' && (
          <div className={styles.container_actions}>
            <div
              onClick={() => {
                setActive(true);
                handleRef();
              }}
              className={styles.container_actions_item}
            >
              <EditIcon />
            </div>
            <div
              className={styles.container_actions_item}
              onClick={() => {
                dispatch(removeField({ elem: String(id), index: index }));
              }}
            >
              <DeleteAreaArageIcon />
            </div>
          </div>
        )
      )}
      <div style={{ display: textPosition.labelPosition, gap: 10, width: '100%' }}>
        <div className={styles.header}>
          <Input
            className={styles.header_left}
            style={{
              color: fieldsStyle.color,
              pointerEvents: active ? 'auto' : 'none',
              fontFamily: main.fontFamily,
              fontSize: fieldsStyle?.fieldSize
            }}
            value={label}
            onChange={e => dispatch(changeFaildLabel({ label: e.target.value, index: index }))}
            ref={ref}
          />
        </div>
        <Input
          type={type}
          disabled={disabled}
          value={placeholder}
          onChange={e => dispatch(changeFaildContent({ content: e.target.value, index: index }))}
          style={{
            pointerEvents: active ? 'auto' : 'none',
            borderRadius: fieldsStyle.fieldBorderType === 'ELLIPSE' ? '12px' : 0,
            height: fieldsStyle.height,
            borderColor: fieldsStyle.fieldBorderColor,
            backgroundColor: fieldsStyle.fieldBackgroundColor,
            fontFamily: main.fontFamily
          }}
          ref={placeholderRef}
        />
      </div>
      {active && (
        <div className={styles.required}>
          <span>Required field</span>
          <Switch size="small" onChange={e => dispatch(changeFieldRequired({ index: index, switch: e }))} checked={required} />
        </div>
      )}
      {required && active && (
        <Input value={message} onChange={e => dispatch(changeFaildMessage({ index: index, message: e.target.value }))} placeholder="Error text" />
      )}
    </div>
  );
};

export default DraggingInput;
