import React from 'react';
import { Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useBasicCurrency, useBossComplexCapitalBlock } from '../../queries/queries';

import styles from './priceBlockModal.module.scss';

interface Props {
  visible: boolean;
  close: (id: number, visible: boolean) => void;
  id: number;
  startDate: string;
  finishDate: string;
}

const PriceBlockModal: React.FC<Props> = ({ id, close, visible, startDate, finishDate }) => {
  const { isLoading, isFetching, data } = useBossComplexCapitalBlock(id, startDate, finishDate);

  const { data: currency } = useBasicCurrency();

  const { t } = useTranslation();

  // handle close modal
  const handleCancel = () => {
    close(0, false);
  };

  function calcTotal(onSale: number, onSold: number) {
    return onSale + onSold;
  }

  return (
    <Modal width={539} className={styles.container} title={<h2>{data?.name}</h2>} visible={visible} footer={false} onCancel={handleCancel}>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ maxHeight: 720, overflow: 'auto' }}>
          <div className={styles.container_value}>
            <div className={styles.container_value_darker}>
              <div>{t('boss_complex.Umumiy')}</div>
              <div>
                {calcTotal(data?.onSale!, data?.onSold!)?.toLocaleString('ru')} {currency?.ccy}
              </div>
            </div>
            <div className={styles.container_value_darker}>
              <div>{t('boss_complex.Sotuvda')}</div>
              <div>
                {data?.onSale.toLocaleString('ru')} {currency?.ccy}
              </div>
            </div>
            <div className={styles.container_value_darker}>
              <div>{t('boss_complex.Sotilgan')}</div>
              <div>
                {data?.onSold.toLocaleString('ru')} {currency?.ccy}
              </div>
            </div>
            <div className={styles.container_value_light}>
              <div>{t('boss_complex.Undirilgan_mablag')}</div>
              <div>
                {data?.collected.toLocaleString('ru')} {currency?.ccy}
              </div>
            </div>
            <div className={styles.container_value_light}>
              <div>{t('boss_complex.Kutilayotgan_mablag')}</div>
              <div>
                {data?.expectation.toLocaleString('ru')} {currency?.ccy}
              </div>
            </div>
          </div>
          {data?.buildingCapitalDTOList.map((item, index) => (
            <div key={index}>
              <div className={styles.container_title}>{item.name}</div>
              <div className={styles.container_value}>
                <div className={styles.container_value_darker}>
                  <div>{t('boss_complex.Umumiy')}</div>
                  <div>
                    {calcTotal(item?.onSale, item?.onSold)?.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
                <div className={styles.container_value_darker}>
                  <div>{t('boss_complex.Sotuvda')}</div>
                  <div>
                    {item?.onSale.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
                <div className={styles.container_value_darker}>
                  <div>{t('boss_complex.Sotilgan')}</div>
                  <div>
                    {item?.onSold.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
                <div className={styles.container_value_light}>
                  <div>{t('boss_complex.Undirilgan_mablag')}</div>
                  <div>
                    {item?.collected.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
                <div className={styles.container_value_light}>
                  <div>{t('boss_complex.Kutilayotgan_mablag')}</div>
                  <div>
                    {item?.expectation.toLocaleString('ru')} {currency?.ccy}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default PriceBlockModal;
