import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { IRemainingHouses } from '../utils/models/IRemainingHouses';

export function useGetRemainingHouses(houses?: number[] | null, filter?: string, filterSecond?: string) {
  return useQuery<IRemainingHouses[]>(
    [queryKeys.GET_REMAINING_HOUSES, houses, filter, filterSecond],
    async () => {
      const res = await $api.get(`${endPoints.GET_REMAINING_HOUSES}?houses=${houses}&filter=${filter}&filterSecond=${filterSecond}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}
