/* eslint-disable react/no-array-index-key */
/* eslint-disable newline-after-var */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowRightMessage from '@icons/ArrowRightMessage';
import ManagePermissionsIcon from '@icons/ManagePermissionsIcon';
import { MainPaths } from '@routing/main/mainPaths';
import { robotoCallsPaths } from '@routing/roboto-calls/robotoCallsPath';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import { useTranslation } from 'react-i18next';

const RobotoCallsMenu = () => {
  const param = useQueryParam();
  const { t } = useTranslation();
  const data = [
    {
      icon: ManagePermissionsIcon,
      text: t('titles.calls'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.CALLS + robotoCallsPaths.INDEX
    }
  ];
  return (
    <div className="min-w-[262px]">
      {data.map((item, index) => (
        <NavLink key={index} to={item.path} className="flex items-center justify-between !bg-white p-4">
          <span className="d-flex align-center">
            <item.icon /> &nbsp; &nbsp; {item.text}
          </span>
          <span>
            <ArrowRightMessage />
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default RobotoCallsMenu;
