import { useParams } from 'react-router-dom';
import { Rate, Spin } from 'antd';
import { t } from 'i18next';

import BlueCall from './assets/icons/BlueCall';
import False from './assets/icons/False';
import GreenCall from './assets/icons/GreenCall';
import True from './assets/icons/True';
import { useGetStats } from './services/queries';

import styles from './stats.module.scss';

const Stats = () => {
  const param = useParams();
  const { data, isLoading } = useGetStats(Number(param?.id));

  return (
    <div className={styles.main}>
      {isLoading ? (
        <div
          className={styles.loader}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={styles.wrapper} style={{ marginBottom: '16px' }}>
            <p className={styles.textG}>{t('home.Interfeys')}</p>
            <p className={styles.textG} style={{ color: '#464255' }}>
              {data?.createdDate}
            </p>
          </div>

          <h1 className={styles.greenTitle}>{data?.workingSince}</h1>
          <h3 className={styles.blackDays}>{t('home.Faol_kunlar')}</h3>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Xabarlar')}</p>
            <p className={styles.textG} style={{ color: '#464255' }}>
              {data?.letters}
            </p>
          </div>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Qongiroq_kiruvchi_chiquvchi')}</p>
            <div className={styles.flxitc} style={{ gap: '5px' }}>
              <div className={styles.flxitc} style={{ gap: '3px' }}>
                <GreenCall />
                <p className={styles.textG} style={{ color: '#464255' }}>
                  {data?.incoming}
                </p>
              </div>
              <p style={{ color: '#D0D5DD' }}>|</p>
              <div className={styles.flxitc} style={{ gap: '3px' }}>
                <BlueCall />
                <p className={styles.textG} style={{ color: '#464255' }}>
                  {data?.outcoming}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Topshiriq_bajarilgan_muddati_otgan')}</p>
            <div className={styles.flxitc} style={{ gap: '5px' }}>
              <div className={styles.flxitc} style={{ gap: '3px' }}>
                <True />
                <p className={styles.textG} style={{ color: '#464255' }}>
                  {data?.closedTask}
                </p>
              </div>
              <p style={{ color: '#D0D5DD' }}>|</p>
              <div className={styles.flxitc} style={{ gap: '3px' }}>
                <False />
                <p className={styles.textG} style={{ color: '#464255' }}>
                  {data?.wasOverdue}
                </p>
              </div>
            </div>
          </div>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Eslatmalar')}</p>
            <p className={styles.textG} style={{ color: '#464255' }}>
              {data?.notes}
            </p>
          </div>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Mijoz_bilan_suhbat')}</p>
            <p className={styles.textG} style={{ color: '#464255' }}>
              {data?.chatsWitClient}
            </p>
          </div>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Ichki_suhbat')}</p>
            <p className={styles.textG} style={{ color: '#464255' }}>
              {data?.internalChats}
            </p>
          </div>

          <div className={styles.wrapper}>
            <p className={styles.textG}>{t('home.Ortacha_qongiroq_ballari')}</p>
            <div className={styles.flxitc} style={{ gap: '5px' }}>
              <p className={styles.textG} style={{ color: '#464255' }}>
                {data?.callRating}
              </p>
              <Rate allowHalf value={data?.callRating} disabled />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Stats;
