import React, { ChangeEventHandler, useState } from 'react';

import styles from './sendMessageModal.module.scss';
import { Avatar, Button, Input, Modal, Spin } from 'antd';
import InfoIcon from '../../../../assets/icons/InfoIcon';
import AktImageDelete from '../../../../pages/contractId/AktImageDelete';
import { useBotInfoForMessage } from '../../../../queries/queries';
import { BASE_URL } from '../../../../service/RequestService';
import PaperClipIcon from '../../../../assets/icons/PaperClipIcon';
import { useSendMessageWithBot, useUploadFile } from '../../../../queries/mutation';
import { FileObject } from '../../../../utils/models/File';
import { useTranslation } from 'react-i18next';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
}

const SendMessageModal: React.FC<Props> = ({ visible, close }) => {
  const [text, setText] = useState('');
  const [files, setFiles] = useState<FileObject[]>([]);

  const { data, isLoading } = useBotInfoForMessage();

  const { t } = useTranslation();

  const uploadFile = useUploadFile(getFile, false);
  const sendMessage = useSendMessageWithBot(handleSuccess);

  const input = React.createRef<HTMLInputElement>();

  // getFile
  function getFile(data: FileObject[]) {
    if (data[0]) {
      setFiles([...files, data[0]]);
    }
  }

  //handleFile
  const handleFile: React.ChangeEventHandler<HTMLInputElement> = (e: any) => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('files', file);
      uploadFile.mutate(formData);
    }
  };

  // handle click upload
  const handleClickUpload = () => {
    input.current?.click();
  };

  // handle success
  function handleSuccess() {
    handleClose();
  }

  // is disabled
  const isDisabled = !text && files.length === 0;

  // handle text
  const handleText: ChangeEventHandler<HTMLTextAreaElement> = e => {
    setText(e.target.value);
  };

  //handleClose
  const handleClose = () => {
    setText('');
    setFiles([]);
    close(false);
  };

  // handle send message
  const handleSendMessage = () => {
    const ids: number[] = [];
    files.forEach(item => ids.push(item.id));
    sendMessage.mutate({
      text: text,
      fileIds: ids
    });
  };

  // handle delete file
  const handleDeleteFile = (id: number) => {
    const newFiles = files.filter(value => value.id !== id);
    setFiles(newFiles);
  };

  return (
    <Modal className={styles.container} width={530} footer={false} visible={visible} title={t('bot_message.Xabar_yuborish')} onCancel={handleClose}>
      <Spin spinning={isLoading}>
        <div className={styles.container_header}>
          <Avatar className={styles.container_header_avatar} src={BASE_URL + '/' + data?.avatar?.absolutePath} />
          <div className={styles.container_header_info}>
            <h3>{data?.name}</h3>
            <p>
              {data?.username}, {data?.subscribers} subscribers
            </p>
          </div>
        </div>
        <Input.TextArea
          value={text}
          onChange={handleText}
          autoSize={{ minRows: 10, maxRows: 10 }}
          className={'customInput'}
          placeholder={t('bot_message.Xabar_matni')}
        />
        <Spin spinning={uploadFile.isLoading}>
          <div onClick={handleClickUpload} className={styles.container_select_file}>
            <PaperClipIcon />
            <span>{t('bot_message.Fayl_biriktirish')}</span>
          </div>
        </Spin>
        <div className="aktFiles">
          {files.map((file, index) => (
            <AktImageDelete key={index} id={file.id} name={file.originalName} onDeleteFile={handleDeleteFile} />
          ))}
        </div>
        <div className={`${styles.container_sub_information} d-flex align-center`}>
          <InfoIcon />
          <span>{t('bot_message.Xabar_botdagi')}</span>
        </div>
        {visible && !uploadFile.isLoading && <input ref={input} onChange={handleFile} type={'file'} style={{ display: 'none' }} />}
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button onClick={handleSendMessage} loading={sendMessage.isLoading} disabled={isDisabled} type="primary">
              {t('bot_message.Yuborish')}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default SendMessageModal;
