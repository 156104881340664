import React, { cloneElement, ReactElement, useState } from 'react';
import { Modal as BaseModal, ModalProps as BaseModalProps } from 'antd';

import DraggableModal from './DraggableModal';

interface ModalProps extends BaseModalProps {
  isDraggable?: boolean;
  openChild?: ReactElement;
  children?: ((props: { onCancel: () => void }) => JSX.Element) | JSX.Element;
}

const Modal: React.FC<ModalProps> = ({ ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {props?.openChild && !props?.isDraggable && cloneElement(props?.openChild!, { onClick: () => setOpen(true) })}
      {props?.isDraggable ? (
        <DraggableModal {...props} />
      ) : (
        <BaseModal
          {...props}
          onCancel={e => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            props.onCancel && props?.onCancel(e);
            setOpen(false);
          }}
          visible={props?.visible || open}
        >
          {typeof props?.children === 'function' ? props?.children({ onCancel: () => setOpen(false) }) : props?.children}
        </BaseModal>
      )}
    </>
  );
};

export default Modal;
