import React, { FC, useState } from 'react';
import styles from './voronkaMessengerInstallModal.module.scss';
import GlphIcon from '../../../../../assets/icons/GlphIcon';
import { Avatar, Button, Dropdown, Input, Select } from 'antd';
import LogoutIconMessengerIcon from '../../../../../assets/icons/LogoutIconMessengerIcon';
import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import ArrowDownCrmIcon from '../../../../../assets/icons/ArrowDownCrmIcon';
import PlusIcon from '../../../../../assets/icons/PlusIcon';
import {
  useCrmFacebookLogOut,
  useFacebookLeadFormPipeStatusDelete,
  useFacebookPagePipe,
  useFacebookPagePipeDelete
} from '../../../../../queries/mutation';
import { InstagramAccount } from '../../../../../utils/models/InstagramAccount';
import { BASE_URL } from '../../../../../service/RequestService';
import { useCrmFacebookPagePipe, useFacebookPages, useFacebookPipeStatus, usePipeAll } from '../../../../../queries/queries';
import { FacebookPages } from '../../../../../utils/models/FaceBookPages';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { messengerText } from './VoronkaMessengerInstallModal';
import Edit2Icon from '../../../../../assets/icons/Edit2Icon';
import FacebookFormsModal from './facebookFormsModal/FacebookFormsModal';
import { FacebookPipeStatus } from '../../../../../utils/models/FacebookPipeStatus';

interface IProps {
  data: InstagramAccount | undefined;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      settingVisible: boolean;
      messenger: string;
    }>
  >;
  messenger: string;
}

const VoronkaMessengerInstallModalSetting: FC<IProps> = ({ data, setVisible, messenger }) => {
  const location = useLocation();
  const [deleteActiveId, setdeleteActiveId] = React.useState(0);
  const { data: facebookPagePipe } = useCrmFacebookPagePipe(messenger);
  const { data: pipeAll } = usePipeAll();
  const { data: facebookPages } = useFacebookPages();
  const { data: facebookPipeStatus } = useFacebookPipeStatus();
  const facebookPagePipeMutate = useFacebookPagePipe();
  const facebookAccountLogOut = useCrmFacebookLogOut();
  const facebookPagePipeDelete = useFacebookPagePipeDelete();
  const facebookLeadFormDelete = useFacebookLeadFormPipeStatusDelete();
  const [visibleFacebookForms, setVisibleFacebookForms] = useState({
    visible: false,
    pageId: '',
    edit: false,
    leadFormId: ''
  });
  const [pipeStatusOne, setPipeStatus] = useState<FacebookPipeStatus>();
  const [facebookFormsDropdownVisible, setFacebookFormsDropdownVisible] = useState(false);
  const { t } = useTranslation();

  const logOut = () => {
    facebookAccountLogOut.mutate();

    setVisible({
      visible: false,
      settingVisible: false,
      messenger: ''
    });
  };

  const handlePagePipe = (value: FacebookPages, type: string) => {
    facebookPagePipeMutate.mutate({
      pageId: value.id,
      pipeId: Number(queryString.parse(location.search).voronka),
      sourceType: type,
      messenger: messenger
    });
  };

  const handlePagePipeDelete = (id: number) => {
    setdeleteActiveId(id);
    facebookPagePipeDelete.mutate(id);
  };

  const openFacebookForms = (pageId: string, edit: boolean, leadFormId: string) => {
    setVisibleFacebookForms({
      pageId: pageId,
      visible: true,
      edit: edit,
      leadFormId: leadFormId
    });
  };

  const handleFacebookFormDropdown = () => {
    setFacebookFormsDropdownVisible(true);
  };

  const handleFacebookPagePipeDelete = (id: number) => {
    setdeleteActiveId(id);
    facebookLeadFormDelete.mutate(id);
  };

  return (
    <div className={styles.container_setting}>
      <h1 className={styles.container_setting_title}>
        <GlphIcon />
        {messengerText[messenger] + ' ' + t('crm_ins.hisobingiz_muvaffaqiyatli_ulandi_Chiqish_uchun_quyidagi_Chiqish_tugmasidan_foydalaning')}
      </h1>
      <div className={styles.container_setting_account}>
        <Avatar size={38} src={BASE_URL + '/' + data?.avatar} />
        <h1>{data?.fullName}</h1>
        <span onClick={logOut}>
          <LogoutIconMessengerIcon /> {t('crm_ins.Chiqish')}
        </span>
      </div>
      <div className={styles.container_setting_content}>
        <h1>{messengerText[messenger] + ' ' + t('crm_ins.xabarlari_va_hikoyalari')} </h1>
        <p>{messengerText[messenger] + ' ' + t('crm_ins.xabarlari_va_hikoyalaridan_takliflarni_oling_va_orqali_javob_bering')}</p>
        {facebookPagePipe?.messenger.map(item => (
          <div key={item.id} className={styles.container_setting_content_card}>
            <div>
              <Avatar className={styles.container_setting_content_card_avatar} size={38} src={BASE_URL + '/' + item.instagramAvatar} />
              <p>
                {item.pageName} {item.instagramUsername ? `(${item.instagramUsername})` : ``}
              </p>
            </div>
            <div>
              <Select
                placeholder={t('crm_ins.Tanlang')}
                suffixIcon={<ArrowDownCrmIcon strokeColor="#9CA3AF" />}
                defaultValue={item.pipeId}
                onChange={value =>
                  facebookPagePipeMutate.mutate({
                    id: item.id,
                    pageId: item.pageId,
                    pipeId: value,
                    sourceType: 'DIRECT',
                    messenger: messenger
                  })
                }
              >
                {pipeAll?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <span onClick={() => handlePagePipeDelete(item.id)}>
                {item.id === deleteActiveId && facebookPagePipeDelete.isLoading ? <LoadingOutlined /> : <TrushSquareIcon />}
              </span>
            </div>
          </div>
        ))}
        <Dropdown
          overlay={
            <div className={styles.container_setting_content_dropdown}>
              {facebookPages
                ?.filter(item => !facebookPagePipe?.messenger.some(i => i.pageId === item.id))
                ?.map(item => (
                  <div key={item.id} onClick={() => handlePagePipe(item, 'DIRECT')}>
                    <Avatar src={BASE_URL + '/' + item.instagramAvatar} /> {item.name} {item.instagramUsername ? `(${item.instagramUsername})` : ''}
                  </div>
                ))}
            </div>
          }
          trigger={['click']}
        >
          <Button type="dashed">
            <PlusIcon /> {t('crm_ins.Yangi_qoshish')}
          </Button>
        </Dropdown>
      </div>
      <div className={styles.container_setting_content}>
        <h1>{t('crm_ins.Izohlar')}</h1>
        <p>{messengerText[messenger] + ' ' + t('crm_ins.sharhlaridan_takliflarni_oling_va_CRM_tizimi_orqali_javob_bering')}</p>
        {facebookPagePipe?.comment.map(item => (
          <div key={item.id} className={styles.container_setting_content_card}>
            <div>
              <Avatar className={styles.container_setting_content_card_avatar} size={38} src={BASE_URL + '/' + item.instagramAvatar} />
              <p>
                {item.pageName} ({item.instagramUsername})
              </p>
            </div>
            <div>
              <Select
                placeholder={t('crm_ins.Tanlang')}
                suffixIcon={<ArrowDownCrmIcon strokeColor="#9CA3AF" />}
                defaultValue={item.pipeId}
                onChange={value =>
                  facebookPagePipeMutate.mutate({
                    id: item.id,
                    pageId: item.pageId,
                    pipeId: value,
                    sourceType: 'COMMENT',
                    messenger: messenger
                  })
                }
              >
                {pipeAll?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <span onClick={() => handlePagePipeDelete(item.id)}>
                {item.id === deleteActiveId && facebookPagePipeDelete.isLoading ? <LoadingOutlined /> : <TrushSquareIcon />}
              </span>
            </div>
          </div>
        ))}
        <Dropdown
          overlay={
            <div className={styles.container_setting_content_dropdown}>
              {facebookPages
                ?.filter(item => !facebookPagePipe?.comment.some(i => i.pageId === item.id))
                ?.map(item => (
                  <div key={item.id} onClick={() => handlePagePipe(item, 'COMMENT')}>
                    <Avatar src={BASE_URL + '/' + item.instagramAvatar} /> {item.name} {item.instagramUsername ? `(${item.instagramUsername})` : ''}
                  </div>
                ))}
            </div>
          }
          trigger={['click']}
        >
          <Button type="dashed">
            <PlusIcon /> {t('crm_ins.Yangi_qoshish')}
          </Button>
        </Dropdown>
      </div>
      {messenger === 'FACEBOOK' && (
        <div className={styles.container_setting_content}>
          <h1>{t('crm_ins.Facebook_shakli')}</h1>
          <p>{t('crm_ins.Facebook_formasi_orqali_lidlarni_oling')}</p>
          {facebookPipeStatus?.map(item => (
            <div key={item.id} className={styles.container_setting_content_card}>
              <div>
                <Avatar size={38} src={BASE_URL + '/' + item.pageAvatar} />
                <p>
                  {item.pageName} ({item.leadFormName})
                </p>
              </div>
              <div>
                <Input value={item.pipeName} className="customInput" />
                <span
                  onClick={() => {
                    setPipeStatus(item);
                    openFacebookForms(String(item.pageId), true, item.leadFormId);
                  }}
                >
                  <Edit2Icon color="#0078D2" style={{ width: 20, height: 20 }} />
                </span>
                <span onClick={() => handleFacebookPagePipeDelete(item.id)}>
                  {item.id === deleteActiveId && facebookLeadFormDelete.isLoading ? <LoadingOutlined /> : <TrushSquareIcon />}
                </span>
              </div>
            </div>
          ))}
          <Dropdown
            overlay={
              <div className={styles.container_setting_content_dropdown}>
                {facebookPages?.map(item => (
                  <div
                    key={item.id}
                    onClick={() => {
                      setFacebookFormsDropdownVisible(false);
                      openFacebookForms(String(item.id), false, '');
                    }}
                  >
                    <Avatar src={BASE_URL + '/' + item.instagramAvatar} /> {item.name} {item.instagramUsername ? `(${item.instagramUsername})` : ''}
                  </div>
                ))}
              </div>
            }
            onVisibleChange={setFacebookFormsDropdownVisible}
            visible={facebookFormsDropdownVisible}
            trigger={['click']}
          >
            <Button type="dashed" onClick={handleFacebookFormDropdown}>
              <PlusIcon /> {t('crm_ins.Yangi_qoshish')}
            </Button>
          </Dropdown>
        </div>
      )}
      <FacebookFormsModal
        pages={facebookPages}
        setVisible={setVisibleFacebookForms}
        visible={visibleFacebookForms.visible}
        pageId={visibleFacebookForms.pageId}
        edit={visibleFacebookForms.edit}
        visibleFacebookForms={visibleFacebookForms}
        pageStatus={facebookPipeStatus}
        leadFormId={visibleFacebookForms.leadFormId}
        pipeStatusOne={pipeStatusOne}
      />
    </div>
  );
};

export default VoronkaMessengerInstallModalSetting;
