import React from 'react';
import { Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { setAutoRemindNotTask, setNotChangeStepStatus } from '../../../../../store/reducers/CrmFieldSlice';
import { useLeadNotTask } from '../../../services/queries';

import Pipes from './pipes/Pipes';
import Time from './time/Time';

import styles from './leadWithoutTaskConfig.module.scss';

const LeadWithoutTaskConfig = () => {
  const { isLoading } = useLeadNotTask();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { autoRemindNotTask, notChangeStepStatus, autoRemindTime, notTaskPipes } = useAppSelector(state => state.crmField);

  // handle isChangeFields
  const handleIsChangeFields = (checked: boolean) => {
    dispatch(setNotChangeStepStatus(checked));
  };

  // handle isChangeFields
  const handleAutoRemindNotTask = (checked: boolean) => {
    dispatch(setAutoRemindNotTask(checked));
  };

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.container_title}>
          <p>{t('one_lid.Topshiriqsiz_lidni_yangi_etapga_olib')}</p>
          <Switch onChange={handleIsChangeFields} checked={notChangeStepStatus} size="small" />
        </div>
        <Pipes pipes={notTaskPipes} />
        <div className={styles.container_title}>
          <p>{t('one_lid.Topshiriqsiz_lid_masullarinini_ogohlantirish')}</p>
          <Switch checked={autoRemindNotTask} onChange={handleAutoRemindNotTask} size="small" />
        </div>
        <Time time={autoRemindTime} />
      </div>
    </Spin>
  );
};

export default LeadWithoutTaskConfig;
