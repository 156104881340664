import React, { useState } from 'react';
import { Image, Spin } from 'antd';
import { useGetImageByImageId } from '../../queries/queries';
import { useDeleteFile } from '../../queries/mutation';
import { mathFloor } from '../../utils/helper/mathFloor';
import styles from './houseConfig.module.scss';
import TrashIcon from '../../assets/icons/TrashIcon';
import { useTranslation } from 'react-i18next';
import baseImage from '../../assets/img/plan_doma.png';
import { BASE_URL } from '../../service/RequestService';

interface Props {
  id: number;
  afterDelete: (id: number) => void;
}

const HouseImage: React.FC<Props> = ({ id, afterDelete }) => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useGetImageByImageId(id);
  const { t } = useTranslation();
  const deleteImage = useDeleteFile(afterDeleteHandler);

  const handleVisible = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  function afterDeleteHandler() {
    afterDelete(id);
  }

  const handleDelete = (e: any) => {
    e.stopPropagation();
    deleteImage.mutate(id);
  };

  return (
    <Spin spinning={isLoading || deleteImage.isLoading}>
      <div onClick={() => handleVisible(true)} className={`d-flex align-center justify-space-between ${styles.imageShowCont}`}>
        <span className={styles.imageUpload_icon}>
          <img src={data?.fileObject.thumbAbsolutePath ? BASE_URL + '/' + data?.fileObject.thumbAbsolutePath : baseImage} alt="house" />
        </span>
        <span className={styles.imageUpload_title_cont}>
          <p className={styles.imageUpload_title}>{data?.fileObject?.originalName}</p>
          <p className={styles.imageUpload_info}>
            {mathFloor((data?.fileBlob?.size || 1) / 1000000, 2)} {t('client_create_contract.Mb')}
          </p>
        </span>
        <div className={'d-flex align-center'}>
          <span onClick={handleDelete} className={styles.imageShowCont_delete}>
            <TrashIcon />
          </span>
        </div>
      </div>
      <Image
        width={200}
        style={{ display: 'none' }}
        preview={{
          visible,
          src: data?.fileBlob ? URL.createObjectURL(data?.fileBlob) : '',
          onVisibleChange: handleVisible
        }}
      />
    </Spin>
  );
};

export default HouseImage;
