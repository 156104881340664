import { CustomFieldData } from './CustomField';

export interface CreateContract {
  delay: number;
  prepayment: number;
  prepaymentDate: string;
  comment?: string | null | undefined;
  discount?: number;
  discountDescription?: string;
  clientId: number;
  flats: {
    flatId: number;
    repaired: boolean;
  }[];
  monthlyPaymentPartDtos?: MonthlyPayment[];
  number: string | null;
  delayInterval: number;
  brokerId: number;
  responsibleBy: number;
  fields: CustomFieldData[];
  currencyId: number;
  discountPercent?: number | null;
  discountPartId?: number | null;
  mortgage?: number;
  mortgagePaymentDate?: string;
  monthlyPaymentAmount?: number;
  offerAmount?: number;
  offerPositive?: boolean;
}

export interface MonthlyPayment {
  id?: number;
  amount: number;
  amountString: string;
  date: string;
  payment?: number;
  prepayment?: true;
  paid?: true;
  sum?: number;
  type: 'PREPAYMENT' | 'MORTGAGE' | 'MONTHLY_PAYMENT';
}

export enum MonthlyPaymentStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}
