import React from 'react';

const BlueHomeIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 12V10" stroke="#3772FF" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.21318 1.87998L2.59318 5.57998C2.07318 5.99331 1.73985 6.86665 1.85318 7.51998L2.73985 12.8266C2.89985 13.7733 3.80652 14.54 4.76652 14.54H12.2332C13.1865 14.54 14.0999 13.7666 14.2599 12.8266L15.1465 7.51998C15.2532 6.86665 14.9199 5.99331 14.4065 5.57998L9.78652 1.88665C9.07318 1.31331 7.91985 1.31331 7.21318 1.87998Z"
        stroke="#3772FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BlueHomeIcon;
