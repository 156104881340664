import React from 'react';

import styles from './client.module.scss';
import CallIcon from '../../../assets/icons/CallIcon';
import { useParams } from 'react-router-dom';
import { useClientId } from '../../../queries/queries';
import { Spin } from 'antd';
import { ClientType } from '../../../utils/models/ClientType';

const Client = () => {
  const params = useParams<{ idClient: string }>();

  const { isLoading, data } = useClientId(Number(params.idClient?.split('=')[1]));

  // getAvatarName
  const getAvatarName = () => {
    if (data && data.type === ClientType.NATURAL) return data.firstname?.slice(0, 2)?.toUpperCase() || '';
    if (data) {
      return data.name?.slice(0, 2)?.toUpperCase() || '';
    }
    return '';
  };

  // getName
  const getName = () => {
    if (data && data.type === ClientType.NATURAL) return data.lastname + ' ' + data.firstname + ' ' + data.fatherName;

    if (data) return data.name;
    return '';
  };

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.container_avatar}>
          <span>{getAvatarName()}</span>
        </div>
        <div className={styles.container_name_cont}>
          <div className={styles.container_name_cont_name}>{getName()}</div>
          <p className={styles.container_name_cont_phones}>
            {data?.phones.map((item, index) => (
              <React.Fragment key={index}>
                <CallIcon />
                <span>{item.phone}</span>
                {index + 1 !== data?.phones.length && <div />}
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
    </Spin>
  );
};

export default Client;
