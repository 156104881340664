import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const RefreshIcon = (props: IProps) => {
  const { size = 16 } = props;
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6663 7.99967C14.6663 11.6797 11.6797 14.6663 7.99967 14.6663C4.31967 14.6663 2.07301 10.9597 2.07301 10.9597M2.07301 10.9597H5.08634M2.07301 10.9597V14.293M1.33301 7.99967C1.33301 4.31967 4.29301 1.33301 7.99967 1.33301C12.4463 1.33301 14.6663 5.03967 14.6663 5.03967M14.6663 5.03967V1.70634M14.6663 5.03967H11.7063"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
