import React from 'react';

import styles from './calendarView.module.scss';
import Header from './header/Header';
import DateSlider from './dateSlider/DateSlider';
import { useAppSelector } from '@hooks/reduxHooks';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import DayView from './dayView/DayView';
import WeekView from './weekView/WeekView';
import MonthView from './monthView/MonthView';
import CreateTask from './createTask/CreateTask';
import CompleteTaskModal from './completeTask/CompleteTaskModal';

const CalendarView = () => {
  const type = useAppSelector(state => state.taskCalendarViewSlice.type);

  // activePage
  const activePage = () => {
    switch (type) {
      case TaskCalendarViewType.DAY:
        return <DayView />;
      case TaskCalendarViewType.WEEK:
        return <WeekView />;
      case TaskCalendarViewType.MONTH:
        return <MonthView />;
      default:
        return <span>something wrong:(</span>;
    }
  };

  return (
    <div className={styles.container}>
      <Header />
      <DateSlider />
      {activePage()}
      <CreateTask />
      <CompleteTaskModal />
    </div>
  );
};

export default CalendarView;
