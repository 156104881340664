import React from 'react';

interface FilterProps {
  className?: string;
}

const Filter: React.FC<FilterProps> = ({ className }) => {
  return (
    <svg className={className} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4982 13C18.4982 12.7274 18.39 12.466 18.1972 12.2733C18.0045 12.0806 17.7431 11.9723 17.4705 11.9723H7.1937C6.92114 11.9723 6.65975 12.0806 6.46702 12.2733C6.27429 12.466 6.16602 12.7274 6.16602 13C6.16602 13.2725 6.27429 13.5339 6.46702 13.7267C6.65975 13.9194 6.92114 14.0277 7.1937 14.0277H17.4705C17.7431 14.0277 18.0045 13.9194 18.1972 13.7267C18.39 13.5339 18.4982 13.2725 18.4982 13Z"
        fill="#464255"
      />
      <path
        d="M21.5813 6.83384C21.5813 6.56128 21.4731 6.29988 21.2803 6.10715C21.0876 5.91443 20.8262 5.80615 20.5536 5.80615H4.11069C3.83813 5.80615 3.57674 5.91443 3.38401 6.10715C3.19128 6.29988 3.08301 6.56128 3.08301 6.83384C3.08301 7.10639 3.19128 7.36779 3.38401 7.56052C3.57674 7.75325 3.83813 7.86152 4.11069 7.86152H20.5536C20.8262 7.86152 21.0876 7.75325 21.2803 7.56052C21.4731 7.36779 21.5813 7.10639 21.5813 6.83384Z"
        fill="#464255"
      />
      <path
        d="M9.24888 18.1383C8.97632 18.1383 8.71492 18.2466 8.52219 18.4393C8.32947 18.632 8.22119 18.8934 8.22119 19.166C8.22119 19.4386 8.32947 19.6999 8.52219 19.8927C8.71492 20.0854 8.97632 20.1937 9.24888 20.1937H15.415C15.6875 20.1937 15.9489 20.0854 16.1417 19.8927C16.3344 19.6999 16.4427 19.4386 16.4427 19.166C16.4427 18.8934 16.3344 18.632 16.1417 18.4393C15.9489 18.2466 15.6875 18.1383 15.415 18.1383H9.24888Z"
        fill="#464255"
      />
    </svg>
  );
};

export default Filter;
