import { ImportExportPaths } from './importExportPaths';
import ImportCont from '../../components/importExport/ImportCont';

export const ImportExportRoutes = [
  {
    component: ImportCont,
    path: ImportExportPaths.IMPORT,
    exact: true
  }
];
