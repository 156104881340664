import React from 'react';
import CLoseAllIcon from '@icons/CLoseAllIcon';
import { Checkbox, Collapse, Drawer } from 'antd';
import { useGetAllRole } from '@pages/crm-settings/services/queries';
import ColapseIcon from '@icons/ColapseIcon';

import styles from './filterDrawer.module.scss';

type Props = {
  openFiltr: boolean;
  setOpenFiltr: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Panel } = Collapse;

const FilterDrawer: React.FC<Props> = ({ openFiltr, setOpenFiltr }) => {
  const { data } = useGetAllRole();

  //handle close drawer
  const handleClose = () => {
    setOpenFiltr(false);
  };

  return (
    <Drawer
      visible={openFiltr}
      onClose={handleClose}
      placement="left"
      title={
        <div className={styles.title}>
          <span>Filtr</span>
          <span onClick={handleClose}>
            <CLoseAllIcon />
          </span>
        </div>
      }
      closeIcon={false}
      width={265}
      className={styles.filterDrawer}
    >
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        className={styles.filterDrawerColapse}
        expandIconPosition={'right'}
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s',
              top: 0
            }}
          >
            <ColapseIcon />
          </span>
        )}
      >
        <Panel header={'Rollar'} key={'1'}>
          <Checkbox.Group onChange={e => console.log(e)}>
            {data?.map(item => (
              <Checkbox value={item?.id}>
                <span>{item.name}</span>
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default FilterDrawer;
