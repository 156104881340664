import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const PlayerPlayIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 8.26887C17.0833 9.03867 17.0833 10.9632 15.75 11.733L3 19.0942C1.66667 19.864 1.10896e-06 18.9017 1.17626e-06 17.3621L1.8198e-06 2.6397C1.88709e-06 1.1001 1.66667 0.137851 3 0.907651L15.75 8.26887Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default PlayerPlayIcon;
