import React, { useState } from 'react';
import ArrowNarrowDownIcon from '@icons/arrow-narrow-down-icon';
import { PlanFactModel } from '@pages/bossCrm/utils/models/plan-fact-model';
import { cx } from '@utils/cx';
import { isEmptyArr } from '@utils/index';
import { Button, Collapse, Tooltip } from 'antd';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const { Panel } = Collapse;

type Props = {
  employees: PlanFactModel['factEmployeeDtoList'] | undefined;
};

const Employee: React.FC<Props> = ({ employees }) => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<string[]>([]);

  return (
    <ConditionalRender if={!isEmptyArr(employees)}>
      <div className={cx('flex flex-col', styles.employee)}>
        <Collapse bordered={false} collapsible="header" onChange={e => setActiveKey(e as string[])} activeKey={activeKey}>
          <Panel
            key={1}
            header={
              <Button className={styles.employee__btn}>
                <span>{t('crm_plan_fact.Yopish')}</span>
                <ArrowNarrowDownIcon rotate={activeKey?.length > 0 ? 0 : 180} />
              </Button>
            }
          >
            <div className="grid grid-cols-2 gap-4">
              {employees?.map(({ employeeCompactDto, factPipeStatusList }) => (
                <div key={employeeCompactDto?.id || 0} className="box-border flex items-center justify-between rounded-xl border border-solid border-gray-200 bg-white px-4 py-3">
                  <span className="text-base font-medium text-gray-800">{employeeCompactDto?.fullName || t('crm_plan_fact.Boshqalar')}</span>
                  <div className="flex items-center gap-2.5">
                    {factPipeStatusList?.map(({ color, count, pipeStatusName }) => (
                      <Tooltip
                        showArrow={false}
                        key={pipeStatusName}
                        title={
                          <div className="flex items-center gap-1">
                            <span>{pipeStatusName}:</span>
                            <span>{count}</span>
                          </div>
                        }
                      >
                        <div key={pipeStatusName} className="flex h-7 items-center justify-center gap-1 rounded-2xl border border-solid border-gray-200 bg-white px-2 py-1">
                          <div
                            className="box-border h-4 w-4 rounded-full"
                            style={{
                              border: `5px solid ${color}`
                            }}
                          />
                          <span className="text-base font-medium text-black">{count}</span>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>
    </ConditionalRender>
  );
};

export default Employee;
