import React, { useEffect, useState } from 'react';
import { DataNode } from 'antd/es/tree';
import { usePipeAll } from '../../../../../../queries/queries';
import styles from '../leadWithoutTaskConfig.module.scss';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import { Dropdown, Tree } from 'antd';
import { NotTaskPipe } from '../../../../../../utils/models/LeadNotTaskConf';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { setNotTaskPipes } from '../../../../../../store/reducers/CrmFieldSlice';
import { useTranslation } from 'react-i18next';

const { DirectoryTree } = Tree;

interface Props {
  pipes: NotTaskPipe[];
}

const Pipes: React.FC<Props> = ({ pipes }) => {
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [activeTags, setActiveTags] = useState<
    {
      id: number;
      keys: string[];
    }[]
  >([]);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { data: dataPipeAll } = usePipeAll();

  useEffect(() => {
    if (pipes && dataPipeAll) {
      const keysList: {
        id: number;
        keys: string[];
      }[] = [];
      for (let i = 0; i < pipes.length; i++) {
        const id = pipes[i].pipeId;
        const statusId = pipes[i].pipeStatusId;
        const pipe = dataPipeAll.find(item => id === item.id);
        const keys: string[] = [];
        if (pipe) {
          let isHave = false;
          for (let j = 0; j < pipe.pipeStatuses.length; j++) {
            if (statusId === pipe.pipeStatuses[j].id) {
              keys.push(pipe.id + '-' + pipe.pipeStatuses[j].id + '-' + j);
              isHave = true;
            } else if (isHave) {
              keys.push(pipe.id + '-' + pipe.pipeStatuses[j].id + '-' + j);
            }
          }
          keysList.push({
            keys,
            id
          });
        }
      }
      setActiveTags(keysList);
    }
    // eslint-disable-next-line
  }, [pipes]);

  useEffect(() => {
    if (dataPipeAll) {
      const newTreeData: DataNode[] = [];

      for (let i = 0; i < dataPipeAll.length; i++) {
        const children = [];

        for (let j = 0; j < dataPipeAll[i].pipeStatuses.length; j++) {
          children.push({
            title: (
              <div
                style={{
                  //@ts-ignore
                  '--color': dataPipeAll[i].pipeStatuses[j].color
                }}
                className={styles.tree_item}
              >
                {dataPipeAll[i].pipeStatuses[j].name}
              </div>
            ),
            key: dataPipeAll[i].id + '-' + dataPipeAll[i].pipeStatuses[j].id + '-' + j
          });
        }

        newTreeData.push({
          title: <div className={styles.header_tree}>{dataPipeAll[i].name}</div>,
          key: dataPipeAll[i].id,
          children
        });
      }
      setTreeData(newTreeData);
    }
  }, [dataPipeAll]);

  // active tags
  const activeTagsList = () => {
    if (activeTags) {
      let list: string[] = [];
      for (let i = 0; i < activeTags?.length; i++) {
        list = [...list, ...activeTags[i].keys];
      }
      return list;
    }
    return [];
  };

  //handleCheck
  const handleCheck = (checkedKeys: any[], event: any) => {
    const isChecked: boolean = event.checked;
    const key: string | number = event.node.key;
    let id: number;
    if (typeof key === 'number') {
      id = key;
    } else {
      id = Number(key.split('-')[0]);
    }
    let keysArray: {
      id: number;
      keys: string[];
    }[] = [...activeTags];
    // if (event?.nativeEvent?.ctrlKey) {
    if (dataPipeAll) {
      const indexActiveTags = activeTags?.findIndex(item => item.id === id);
      if (indexActiveTags !== -1) {
        for (let i = 0; i < dataPipeAll.length; i++) {
          if (id === dataPipeAll[i].id) {
            if (typeof key === 'number') {
              if (isChecked) {
                const keys: string[] = [];
                for (let j = 0; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(dataPipeAll[i].id + '-' + dataPipeAll[i].pipeStatuses[j].id + '-' + j);
                }
                keysArray[indexActiveTags] = {
                  id,
                  keys
                };
              } else {
                keysArray = keysArray.filter(item => item.id !== id);
              }
            } else {
              const index = Number(key.split('-')[2]);
              if (isChecked) {
                const keys: string[] = [];
                for (let j = index; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(dataPipeAll[i].id + '-' + dataPipeAll[i].pipeStatuses[j].id + '-' + j);
                }
                keysArray[indexActiveTags] = {
                  id,
                  keys
                };
              } else {
                if (index === dataPipeAll[i].pipeStatuses.length - 1) {
                  keysArray = keysArray.filter(item => item.id !== id);
                } else {
                  const keys: string[] = [];
                  for (let j = index + 1; j < dataPipeAll[i].pipeStatuses.length; j++) {
                    keys.push(dataPipeAll[i].id + '-' + dataPipeAll[i].pipeStatuses[j].id + '-' + j);
                  }
                  keysArray[indexActiveTags] = {
                    id,
                    keys
                  };
                }
              }
            }
          }
        }
      } else {
        for (let i = 0; i < dataPipeAll.length; i++) {
          if (id === dataPipeAll[i].id) {
            if (typeof key === 'number') {
              if (isChecked) {
                const keys: string[] = [];
                for (let j = 0; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(dataPipeAll[i].id + '-' + dataPipeAll[i].pipeStatuses[j].id + '-' + j);
                }
                keysArray.push({
                  id,
                  keys
                });
              }
            } else {
              const index = Number(key.split('-')[2]);
              if (isChecked) {
                const keys: string[] = [];
                for (let j = index; j < dataPipeAll[i].pipeStatuses.length; j++) {
                  keys.push(dataPipeAll[i].id + '-' + dataPipeAll[i].pipeStatuses[j].id + '-' + j);
                }
                keysArray.push({
                  id,
                  keys
                });
              }
            }
          }
        }
      }
    }
    const notTaskPipes: NotTaskPipe[] = [];
    for (let i = 0; i < keysArray.length; i++) {
      notTaskPipes.push({
        pipeId: keysArray[i].id,
        pipeStatusId: Number(keysArray[i].keys[0].split('-')[1])
      });
    }
    dispatch(setNotTaskPipes(notTaskPipes));
    // } else {
    //   const findTreeChildren = (id: number) => {
    //     return (
    //       treeData
    //         ?.find((item) => item?.key === id)
    //         ?.children?.map((item) => String(item?.key)) || []
    //     )
    //   }
    //   if (typeof key === 'number') {
    //     if (isChecked) {
    //       if (keysArray?.some((item) => item?.id === id)) {
    //         keysArray = keysArray?.map((item) => {
    //           return {
    //             id: item?.id,
    //             keys: id === item?.id ? findTreeChildren(id) : item?.keys,
    //           }
    //         })
    //       } else {
    //         keysArray.push({ id: id, keys: findTreeChildren(id) })
    //       }
    //     } else {
    //       keysArray = keysArray.filter((item) => item?.id !== id)
    //     }
    //   } else {
    //     if (isChecked) {
    //       if (keysArray?.some((item) => item?.id === id)) {
    //         keysArray = keysArray?.map((item) => {
    //           return {
    //             ...item,
    //             keys: id === item?.id ? [...item?.keys, key] : item?.keys,
    //           }
    //         })
    //       } else {
    //         keysArray.push({ id: id, keys: [key] })
    //       }
    //     } else {
    //       keysArray = keysArray?.map((item) => {
    //         return {
    //           ...item,
    //           keys:
    //             id === item?.id
    //               ? [...item?.keys?.filter((j) => j !== key)]
    //               : item?.keys,
    //         }
    //       })
    //     }
    //   }
    //   setActiveTags(keysArray)
    // }
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_dropdown}
      overlay={<DirectoryTree checkable checkedKeys={activeTagsList()} onCheck={handleCheck as any} treeData={treeData} />}
      trigger={['click']}
    >
      <div className={styles.container_step}>
        <div className={styles.container_step_title}>{t('one_lid.Qadamlarni_tanlash')}</div>
        <ArrowDownIcon />
      </div>
    </Dropdown>
  );
};

export default Pipes;
