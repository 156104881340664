import React from 'react';

const ArrowIcons = (svg: React.SVGProps<SVGSVGElement>) => (
  <svg {...svg} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.96659 4.47998L4.48657 2L2.00659 4.47998" stroke="#A6A4AC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.48657 14V2" stroke="#A6A4AC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.0332 11.52L11.5132 14L13.9932 11.52" stroke="#A6A4AC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5132 2V14" stroke="#A6A4AC" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArrowIcons;
