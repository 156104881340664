import React from 'react';
import dayjs from 'dayjs';

import styles from './dayItem.module.scss';
import { TaskFilter } from '@utils/models/TaskFilter';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { ONE_DAY_UNIX_VALUE } from '@utils/constants/ONE_DAY_UNIX_VALUE';

interface Props {
  data: TaskFilter;
}

const DayItem: React.FC<Props> = ({ data }) => {
  const calculateDate = () => {
    const allDay =
      dayjs(data.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() - dayjs(data.deadlineStart, DATE_WITH_TIME_FORMAT).unix() === ONE_DAY_UNIX_VALUE;
    if (allDay) return '';
    return data.deadlineStart + '-' + data.deadlineFinish;
  };

  return (
    <div className={styles.container}>
      <p>
        <span>{data?.leadName || data?.contractNumber}</span>
        <span>{calculateDate()}</span>
        <span>
          {data.leadTaskType.name}
          {data?.text && ': '}
        </span>
        {data?.text}
      </p>
    </div>
  );
};

export default DayItem;
