import React from 'react';
import TrIcon from '@icons/trIcon';
import { useTranslation } from 'react-i18next';

import EnIcon from '../../assets/icons/enIcon';
import RuIcon from '../../assets/icons/RuIcon';
import UzIcon from '../../assets/icons/UzIcon';

import styles from './interfaceConfig.module.scss';

const Language = () => {
  const { i18n, t } = useTranslation();
  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <h2 className={styles.interfaceConfig_title}>{t('interface_config.Interfeys_tili')}</h2>
      <div className={styles.interfaceConfig_language}>
        <div
          onClick={() => changeLanguageHandler('uz')}
          className={i18n.language === 'uz' ? styles.interfaceConfig_language_item_active : styles.interfaceConfig_language_item}
        >
          <UzIcon />
          <p>O'zbekcha</p>
        </div>
        <div
          onClick={() => changeLanguageHandler('ru')}
          className={i18n.language === 'ru' ? styles.interfaceConfig_language_item_active : styles.interfaceConfig_language_item}
        >
          <RuIcon />
          <p>Русский</p>
        </div>
        <div
          onClick={() => changeLanguageHandler('en')}
          className={i18n.language === 'en' ? styles.interfaceConfig_language_item_active : styles.interfaceConfig_language_item}
        >
          <EnIcon />
          <p>English</p>
        </div>
        <div
          onClick={() => changeLanguageHandler('tr')}
          className={i18n.language === 'tr' ? styles.interfaceConfig_language_item_active : styles.interfaceConfig_language_item}
        >
          <TrIcon />
          <p>Turk</p>
        </div>
      </div>
    </>
  );
};

export default Language;
