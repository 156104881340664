import { useQueryParam } from '@hooks/useQueryParams';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { BossMonthlyPaymentsModel } from '../utils/models/BossMonthlyPaymentsModel';
import { HousesModel } from '../utils/models/HousesModel';

export function useGetBossMonthlyPayments(start: string | undefined, finish: string | undefined, houses: number[] | undefined, currencyId?: number) {
  const { generateSearchParam } = useQueryParam();
  const searchParam = generateSearchParam({
    houses,
    currencyId,
    startDate: start,
    finishDate: finish
  });

  return useQuery<BossMonthlyPaymentsModel>(
    [queryKeys.BOSS_MONTHLY_PAYMENT, start, finish, houses, currencyId],
    async () => {
      const res = await $api.get(`${endPoints.BOSS_MONTHLY_PAYMENTS}?${searchParam}`);

      return res.data.data;
    },
    {
      enabled: !!currencyId,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetAllHouses() {
  return useQuery<HousesModel[]>(
    [queryKeys.HOUSES],
    async () => {
      const res = await $api.get(endPoints.HOUSES);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}
