import React from 'react';
import styles from './currency.module.scss';
import Header from '../../components/currency/header/Header';
import TableCurrency from '../../components/currency/table/TableCurrency';
import CurrencyModal from '../../components/currency/modal/CurrencyModal';

const Currency = () => {
  return (
    <>
      <div className={styles.container}>
        <Header />
        <TableCurrency />
      </div>
      <CurrencyModal />
    </>
  );
};

export default Currency;
