import React, { FC } from 'react';

interface IProps {
  stroke?: string;
}

const DocumentDownloadIcon: FC<IProps> = ({ stroke = '#464255' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11.834C5.93333 11.834 5.87333 11.8207 5.80667 11.794C5.62 11.7207 5.5 11.534 5.5 11.334V7.33398C5.5 7.06065 5.72667 6.83398 6 6.83398C6.27333 6.83398 6.5 7.06065 6.5 7.33398V10.1273L6.98 9.64732C7.17333 9.45398 7.49333 9.45398 7.68667 9.64732C7.88 9.84065 7.88 10.1607 7.68667 10.354L6.35333 11.6873C6.26 11.7807 6.12667 11.834 6 11.834Z"
        fill={stroke}
      />
      <path
        d="M5.99964 11.8336C5.87297 11.8336 5.7463 11.787 5.6463 11.687L4.31297 10.3536C4.11964 10.1603 4.11964 9.84029 4.31297 9.64695C4.5063 9.45362 4.8263 9.45362 5.01964 9.64695L6.35297 10.9803C6.5463 11.1736 6.5463 11.4936 6.35297 11.687C6.25297 11.787 6.1263 11.8336 5.99964 11.8336Z"
        fill={stroke}
      />
      <path
        d="M9.99967 15.1673H5.99967C2.37967 15.1673 0.833008 13.6207 0.833008 10.0007V6.00065C0.833008 2.38065 2.37967 0.833984 5.99967 0.833984H9.33301C9.60634 0.833984 9.83301 1.06065 9.83301 1.33398C9.83301 1.60732 9.60634 1.83398 9.33301 1.83398H5.99967C2.92634 1.83398 1.83301 2.92732 1.83301 6.00065V10.0007C1.83301 13.074 2.92634 14.1673 5.99967 14.1673H9.99967C13.073 14.1673 14.1663 13.074 14.1663 10.0007V6.66732C14.1663 6.39398 14.393 6.16732 14.6663 6.16732C14.9397 6.16732 15.1663 6.39398 15.1663 6.66732V10.0007C15.1663 13.6207 13.6197 15.1673 9.99967 15.1673Z"
        fill={stroke}
      />
      <path
        d="M14.6663 7.16633H11.9997C9.71967 7.16633 8.83301 6.27967 8.83301 3.99967V1.333C8.83301 1.133 8.95301 0.946334 9.13967 0.873C9.32634 0.793 9.53967 0.839667 9.68634 0.979667L15.0197 6.313C15.1597 6.453 15.2063 6.673 15.1263 6.85967C15.0463 7.04633 14.8663 7.16633 14.6663 7.16633ZM9.83301 2.53967V3.99967C9.83301 5.71967 10.2797 6.16633 11.9997 6.16633H13.4597L9.83301 2.53967Z"
        fill={stroke}
      />
    </svg>
  );
};

export default DocumentDownloadIcon;
