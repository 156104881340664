import React from 'react';
import { Col, Row } from 'antd';
import ShowButtonsPlace from './ShowButtonsPlace';
import BotInterfaceInputs from './BotInterfaceInputs';

const BotInterface = () => {
  return (
    <Row>
      <Col span={17}>
        <BotInterfaceInputs />
      </Col>
      <Col span={7}>
        <ShowButtonsPlace />
      </Col>
    </Row>
  );
};

export default BotInterface;
