import { baseErrorHandler } from '@queries/baseErrorHandler';
import { useQuery } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { ControlRoles } from '../utils/models/ControlRoles';
import { $api } from '@service/RequestService';

export function useControlPermissions() {
  return useQuery<ControlRoles[]>(
    [queryKeys.CONTROL_PERMISSIONS],
    async () => {
      const res = await $api.get(endPoints.CONTROL_PERMISSIONS);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useControlRoles() {
  return useQuery<ControlRoles[]>(
    [queryKeys.GET_ROLES],
    async () => {
      const res = await $api.get(endPoints.GET_ROLES);
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}
