import { Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import styles from '../filters/mainFilters.module.scss';
import { SearchParamsBranch } from '../../pages/settingBranch/SettingBranch';
import { CloseOutlined } from '@ant-design/icons';

const BranchFilter = () => {
  const { searchParams, remove, appendSingle } = useQueryParam<SearchParamsBranch, any>();
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.cont}>
      <div className={'d-flex align-center'}>
        <Input
          value={searchParams.search}
          onChange={e => handleSearch(e.target.value)}
          suffix={showIconInput()}
          placeholder={t('home.Qidirish')}
          className={styles.search}
          style={{
            marginLeft: '0px'
          }}
        />
      </div>
    </div>
  );
};

export default BranchFilter;
