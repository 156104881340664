import React from 'react';

const PhoneOutComingIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="20.5" stroke="#F32121" />
      <path
        d="M16.9805 21.651C17.7522 23.2447 19.041 24.5302 20.6367 25.2978C20.7544 25.3536 20.8846 25.3777 21.0145 25.3678C21.1443 25.358 21.2694 25.3145 21.3773 25.2416L23.7211 23.676C23.8246 23.6057 23.9443 23.5629 24.0689 23.5514C24.1935 23.5399 24.319 23.5602 24.4336 23.6103L28.8211 25.4947C28.971 25.5571 29.0963 25.6671 29.1776 25.8077C29.2589 25.9483 29.2918 26.1117 29.2711 26.2728C29.132 27.3582 28.6023 28.3557 27.7809 29.0787C26.9595 29.8018 25.9028 30.2007 24.8086 30.201C21.4271 30.201 18.1841 28.8577 15.793 26.4666C13.4019 24.0755 12.0586 20.8325 12.0586 17.451C12.0588 16.3567 12.4578 15.3 13.1808 14.4787C13.9038 13.6573 14.9013 13.1275 15.9867 12.9885C16.1478 12.9678 16.3113 13.0006 16.4519 13.0819C16.5925 13.1632 16.7024 13.2885 16.7648 13.4385L18.6492 17.8353C18.6982 17.9482 18.7187 18.0713 18.7089 18.1939C18.6991 18.3165 18.6593 18.4349 18.593 18.5385L17.0273 20.9197C16.9576 21.0274 16.9167 21.1512 16.9085 21.2792C16.9003 21.4072 16.9251 21.5352 16.9805 21.651V21.651Z"
        stroke="#F32121"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M23.3086 15.2012V18.9512H27.0586" stroke="#F32121" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M23.3086 18.9512L27.8086 14.4512" stroke="#F32121" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default PhoneOutComingIcon;
