export const queryNames = {
  MESSAGE_TEMPLATE: 'MESSAGE_TEMPLATE',
  MESSAGE_CONFIG: 'MESSAGE_CONFIG',
  HOUSE_SHOW: 'HOUSE_SHOW',
  FLAT_LIST: 'FLAT_LIST',
  HOUSES_ID_LIST: 'HOUSES_ID_LIST',
  BOOKED_FLAT_LIST: 'BOOKED_FLAT_LIST',
  BOOKED: 'BOOKED',
  FLAT_FILTER: 'FLAT_FILTER',
  TABLE: 'TABLE',
  FLAT: 'FLAT',
  FLAT_MORE: 'FLAT_MORE',
  DISCOUNT_BY_FLAT_ID: 'DISCOUNT_BY_FLAT_ID',
  BOOKING_CLIENT_SEARCH: 'BOOKING_CLIENT_SEARCH',
  ORDER_CANCEL_REASON: 'ORDER_CANCEL_REASON',
  CLIENT: 'CLIENT',
  CLIENT_VIEW: 'CLIENT_VIEW',
  CLIENT_BALANCE_LIST: 'CLIENT_BALANCE_LIST',
  REGION: 'REGION',
  DISTRICT: 'DISTRICT',
  CONTRACT_FULL: 'CONTRACT_FULL',
  CONTRACT_ID: 'CONTRACT_ID',
  CLIENT_ID: 'CLIENT_ID',
  PAYMENT: 'PAYMENT',
  FLAT_INFO: 'FLAT_INFO',
  CONTRACT_AMOUNT: 'CONTRACT_AMOUNT',
  CONTRACT_MOTHLY_PAYMENT_ACTIVE: 'CONTRACT_MOTHLY_PAYMENT_ACTIVE',
  CONTRACT_MOTHLY_PAYMENT: 'CONTRACT_MOTHLY_PAYMENT',
  IMAGE: 'IMAGE',
  CONTRACT: 'CONTRACT',
  CONTRACT_BOOKING: 'CONTRACT_BOOKING',
  UPDATE_MONTHLY_PAYMENT: 'UPDATE_MONTHLY_PAYMENT',
  CONTRACT_LIST_FOR_PAYMENT: 'CONTRACT_LIST_FOR_PAYMENT',
  CONTRACT_DATA_FOR_CREATE_PAYMENT: 'CONTRACT_DATA_FOR_CREATE_PAYMENT',
  PAYMENT_CLIENT_BALANCE_LIST: 'PAYMENT_CLIENT_BALANCE_LIST',
  DEBIT_LIST: 'DEBIT_LIST',
  MESSAGE_LIST: 'MESSAGE_LIST',
  EMPLOYEE_LIST: 'EMPLOYEE_LIST',
  HOUSE_CONFIG_LIST: 'HOUSE_CONFIG_LIST',
  ONE_HOUSE: 'ONE_HOUSE',
  BUILDERS_COMPACT_LIST: 'BUILDERS_COMPACT_LIST',
  BUILDER_SETTINGS: 'BUILDER_SETTINGS',
  BRANCH_SETTING: 'BRANCH_SETTING',
  SINGLE_BRANCH_SETTING: 'SINGLE_BRANCH_SETTING',
  COMPANY_LOGO: 'COMPANY_LOGO',
  SHOWROOM_COLOR: 'SHOWROOM_COLOR',
  ORIGINAL_HOUSE: 'ORIGINAL_HOUSE',
  PLAY_MOBILE: 'PLAY_MOBILE',
  DISCOUNT: 'DISCOUNT',
  DISCOUNT_ID: 'DISCOUNT_ID',
  COMPANY_CONFIG_NUMERATION: 'COMPANY_CONFIG_NUMERATION',
  COMPANY_CONFIG_NUMERATION_ANNOTATION: 'COMPANY_CONFIG_NUMERATION_ANNOTATION',
  CONTRACT_TEMPLATE: 'CONTRACT_TEMPLATE',
  PREMIUM_ORIGINATOR: 'PREMIUM_ORIGINATOR',
  DOCUMENT_TEMPLATE: 'DOCUMENT_TEMPLATE',
  FLAT_ALL: 'FLAT_ALL',
  BUILDING_ID: 'BUILDING_ID',
  TRANSFER_DATA_LIST: 'TRANSFER_DATA_LIST',
  COMPANIES_LIST: 'COMPANIES_LIST',
  ONE_COMPANY: 'ONE_COMPANY',
  ONE_COMPANY_EMPLOYEES: 'ONE_COMPANY_EMPLOYEES',
  ONE_COMPANY_EMPLOYEE: 'ONE_COMPANY_EMPLOYEE',
  EMPLOYEE_LIST_ONE_COMPANY: 'EMPLOYEE_LIST_ONE_COMPANY',
  COMPANY_COMPACT: 'COMPANY_COMPACT',
  COMPANY_ROLE_PERMISSION: 'COMPANY_ROLE_PERMISSION',
  BRANCH_COMPACT: 'BRANCH_COMPACT',
  BUILDING_EDIT: 'BUILDING_EDIT',
  SEARCH_CLIENT_CHANGES: 'SEARCH_CLIENT_CHANGES',
  ONE_CLIENT_CHANGE: 'ONE_CLIENT_CHANGE',
  HOUSE_COMPACT: 'HOUSE_COMPACT',
  FLAT_COMPACT: 'FLAT_COMPACT',
  CONTRACT_AND_CLIENT_INFO_CHANGES: 'CONTRACT_AND_CLIENT_INFO_CHANGES',
  CHANGES_MONTHLY_PAYMENT: 'CHANGES_MONTHLY_PAYMENT',
  CHANGES_PAYMENT: 'CHANGES_PAYMENT',
  CHANGES_CONTRACT_LIST: 'CHANGES_CONTRACT_LIST',
  ONE_CONTRACT_CHANGE: 'ONE_CONTRACT_CHANGE',
  FIND_CLIENT_SEARCH_BY_PASSPORT_DATA: 'FIND_CLIENT_SEARCH_BY_PASSPORT_DATA',
  BUILDING_COMPACT: 'BUILDING_COMPACT',
  CLIENT_SEARCH: 'CLIENT_SEARCH',
  CONTRACT_NUMBER: 'CONTRACT_NUMBER',
  PAYMENT_NUMBER: 'PAYMENT_NUMBER',
  PAYME_CASH: 'PAYME_CASH',
  CONTRACT_CARDS: 'CONTRACT_CARDS',
  SURCHARGE: 'SURCHARGE',
  TELEGRAM_BOT: 'TELEGRAM_BOT',
  TELEGRAM_FAQ: 'TELEGRAM_FAQ',
  AMO_CRM_CONFIG: 'AMO_CRM_CONFIG',
  MERCHANT_APELSIN: 'MERCHANT_APELSIN',
  ALL_BUILDING_BY: 'ALL_BUILDING_BY',
  FLAT_DATA_BY: 'FLAT_DATA_BY',
  X_AUTH_TOKEN: 'X_AUTH_TOKEN',
  BLOCK_STATISTIC: 'BLOCK_STATISTIC',
  BLOCK_FLOOR_STATISTIC: 'BLOCK_FLOOR_STATISTIC',
  BLOCK_FLAT_DATA: 'BLOCK_FLAT_DATA',
  HOUSE_SALE_CRM: 'HOUSE_SALE_CRM',
  STAFF: 'STAFF',
  STAFF_INFORMATION: 'STAFF_INFORMATION',
  STAFF_LIST: 'STAFF_LIST',
  KPI_CONFIG: 'KPI_CONFIG',
  PROFILE: 'PROFILE',
  EMPLOYEE_STAFF: 'EMPLOYEE_STAFF',
  BOSS_HEADER_CARDS: 'BOSS_HEADER_CARDS',
  BOSS_FLATS_COUNT: 'BOSS_FLATS_COUNT',
  BOSS_HOME_PAYMENTS: 'BOSS_HOME_PAYMENTS',
  BOSS_PAYMENTS: 'BOSS_PAYMENTS',
  BOSS_PAYMENT_CLIENTS: 'BOSS_PAYMENT_CLIENTS',
  BOSS_COMPLEX_TOTAL_CAPITAL: 'BOSS_COMPLEX_TOTAL_CAPITAL',
  BOSS_COMPLEX_CAPITAL: 'BOSS_COMPLEX_CAPITAL',
  BOSS_COMPLEX_SALE_FLATS: 'BOSS_COMPLEX_SALE_FLATS',
  BOSS_COMPLEX_PREPAYMENT_HOUSES: 'BOSS_COMPLEX_PREPAYMENT_HOUSES',
  MOBILE_DEBT: 'MOBILE_DEBT',
  MOBILE_DEBT_GRAPHIC: 'MOBILE_DEBT_GRAPHIC',
  MOBILE_HOUSE: 'MOBILE_HOUSE',
  MOBILE_HOUSE_CONTRACTS: 'MOBILE_HOUSE_CONTRACTS',
  MOBILE_CLIENT_CONTRACTS: 'MOBILE_HOUSE_CONTRACTS',
  MOBILE_CLIENT_CONTRACTS_PHONE: 'MOBILE_HOUSE_CONTRACTS_PHONE',
  MOBILE_CLIENT_PAYMENT_DELAY: 'MOBILE_HOUSE_PAYMENT_DELAY',
  TASK_EMPLOYEE: 'TASK_EMPLOYEE',
  TASK_CONTRACT_INFORMATION: 'TASK_CONTRACT_INFORMATION',
  UNCOMPLETED_TASK: 'UNCOMPLETED_TASK',
  COMPLETED_TASK: 'COMPLETED_TASK',
  TASK_FILTER: 'TASK_FILTER',
  TASK_FILTER_COUNT: 'TASK_FILTER_COUNT',
  TASK_FILTER_CRM: 'TASK_FILTER_CRM',
  TASK_FILTER_CALENDAR: 'TASK_FILTER_CALENDAR',
  PIPE_ALL: 'PIPE_ALL',
  CRM_LEAD_FILTER: 'CRM_LEAD_FILTER',
  CRM_FIELDS: 'CRM_FIELDS',
  CRM_ONE_LID: 'CRM_ONE_LID',
  CRM_TAGS: 'CRM_TAGS',
  PIPE_STATUS_ONE: 'PIPE_STATUS_ONE',
  CRM_EMPLOYEE: 'CRM_EMPLOYEE',
  MY_CALLS_EMPLOYEE: 'MY_CALLS_EMPLOYEE',
  LEAD_TASK_TYPE_ALL: 'LEAD_TASK_TYPE_ALL',
  LEAD_NOT_CLOSED: 'LEAD_NOT_CLOSED',
  LEAD_COMPLETED_DATA: 'LEAD_COMPLETED_DATA',
  LEAD_EMPLOYEE_DATA: 'LEAD_EMPLOYEE_DATA',
  FACEBOOK_ACCOUNT: 'FACEBOOK_ACCOUNT',
  FACEBOOK_PAGE_PIPE: 'FACEBOOK_PAGE_PIPE',
  TELEGRAM_AND_WEB: 'TELEGRAM_AND_WEB',
  FACEBOOK_PAGE_PAGES: 'FACEBOOK_PAGE_PAGES',
  FACEBOOK_PIPE_STATUS: 'FACEBOOK_PIPE_STATUS',
  FACEBOOK_LEAD_FORM: 'FACEBOOK_LEAD_FORM',
  FACEBOOK_LEAD_FORM_ONE: 'FACEBOOK_LEAD_FORM_ONE',
  MY_CALLS_CONFIG: 'MY_CALLS_CONFIG',
  MY_MESSAGE_TEMPLATE: 'MY_MESSAGE_TEMPLATE',
  FACEBOOK_LEAD_FORM_FIELD: 'FACEBOOK_LEAD_FORM_FIELD',
  NOTIFICATION: 'NOTIFICATION',
  NOTIFICATION_ID: 'NOTIFICATION_ID',
  NOTIFICATION_ME: 'NOTIFICATION_ME',
  NOTIFICATION_ME_COUNT: 'NOTIFICATION_ME_COUNT',
  REASONS_FOR_REFUSAL: 'REASONS_FOR_REFUSAL',
  TRIGGERS_LIST: 'TRIGGERS_LIST',
  ONE_TRIGGER: 'ONE_TRIGGER',
  ONLINE_PBX: 'ONLINE_PBX',
  ONLINE_PBX_EMPLOYEE: 'ONLINE_PBX_EMPLOYEE',
  ONLINE_PBX_PIPE_SETTINGS: 'ONLINE_PBX_PIPE_SETTINGS',
  ONLINE_PBX_PIPE_CONTACTS: 'ONLINE_PBX_PIPE_CONTACTS',
  ONLINE_PBX_HISTORY_CALL: 'ONLINE_PBX_HISTORY_CALL',
  BOOKING_LEAD_FLAT: 'BOOKING_LEAD_FLAT',
  CAN_MAKE_CALL_ONLINE_PBX: 'CAN_MAKE_CALL_ONLINE_PBX',
  CRM_LEAD_LIST: 'CRM_LEAD_LIST',
  CRM_LEAD_LIST_IDS: 'CRM_LEAD_LIST_IDS',
  MARKETPLACE: 'MARKETPLACE',
  MARKETPLACE_HOUSES: 'MARKETPLACE_HOUSES',
  FLAT_FIELD: 'FLAT_FIELD',
  PAYMENT_CONFIG: 'PAYMENT_CONFIG',
  PLAN_FACT_PIPE: 'PLAN_FACT_PIPE',
  PLAN_FACT_COST: 'PLAN_FACT_COST',
  PLAN_FACT_COST_COMPANY_LEAD_FIELD: 'PLAN_FACT_COST_COMPANY_LEAD_FIELD',
  PLAN_FACT_PIPE_PIPES: 'PLAN_FACT_PIPE_PIPES',
  CRM_STATISTICS_COMPANY_FIELD: 'CRM_STATISTICS_COMPANY_FIELD',
  CRM_STATISTICS_ONE_LEAD_COST: 'CRM_STATISTICS_ONE_LEAD_COST',
  BOT_INFO_FOR_MESSAGE: 'BOT_INFO_FOR_MESSAGE',
  SEARCH_LEAD_TASK: 'SEARCH_LEAD_TASK',
  CONTRACT_FIELD: 'CONTRACT_FIELD',
  TEMPLATE_MESSAGE: 'TEMPLATE_MESSAGE',
  TEMPLATE_MESSAGE_FIELDS: 'TEMPLATE_MESSAGE_FIELDS',
  CUSTOMER_FLOW: 'CUSTOMER_FLOW',
  LEAD_CHATBOX: 'LEAD_CHATBOX',
  COUNT_NOT_READ: 'COUNT_NOT_READ',
  FLAT_MORE_LIST: 'FLAT_MORE_LIST',
  CONTRACT_EMPLOYEE: 'CONTRACT_EMPLOYEE',
  TELEGRAM_BOT_CHATS: 'TELEGRAM_BOT_CHATS',
  UYQUR_BUILDERS: 'UYQUR_BUILDERS',
  UYQUR: 'UYQUR',
  CURRENCY: 'CURRENCY',
  CURRENCY_LIST: 'CURRENCY_LIST',
  CURRENCY_LIST_BASIC: 'CURRENCY_LIST_BASIC',
  LEAD_NOT_TASK: 'LEAD_NOT_TASK',
  COMPANY_INFORMATION_FOR_PDF: 'COMPANY_INFORMATION_FOR_PDF',
  WIDGET: 'WIDGET',
  EMPLOYEE_NOT_TASK_LIST: 'EMPLOYEE_NOT_TASK_LIST',
  PER_AREA_AVG_PRICE: 'PER_AREA_AVG_PRICE',
  DOWNLOAD_PDF: 'DOWNLOAD_PDF',
  COMPANY_IS_PRESENT: 'COMPANY_IS_PRESENT',
  BOOKING_STATUS_SUM: 'BOOKING_STATUS_SUM',
  EXPIRE_DATE: 'EXPIRE_DATE',
  BIG_DATA_CLOUD: 'BIG_DATA_CLOUD',
  OPEN_CAGA_DATA: 'OPEN_CAGA_DATA',
  DOCUMENT_TEMPLATE_COMPACT: 'DOCUMENT_TEMPLATE_COMPACT',
  ACTIVE_SESSION: 'ACTIVE_SESSION',
  REPORT_CONFIG: 'REPORT_CONFIG',
  MONETARY_CONFIG: 'MONETARY_CONFIG',
  MONETARY_PAYMENT_FILTER: 'MONETARY_PAYMENT_FILTER',
  MONETARY_PAYMENT_FILTER_COUNT: 'MONETARY_PAYMENT_FILTER_COUNT',
  MONETARY_PAYMENT_CONTRACT: 'MONETARY_PAYMENT_CONTRACT',
  MONETARY_PAYMENT_ID: 'MONETARY_PAYMENT_ID',
  REALTOR_CONFIG_CHECH_PERMISSION: 'REALTOR_CONFIG_CHECH_PERMISSION',
  REALTOR_CONFIG: 'REALTOR_CONFIG',
  HOUSE_FOR_SHOWROOM: 'HOUSE_FOR_SHOWROOM',
  GEO_CODE_YANDEX: 'GEO_CODE_YANDEX',
  MOBILE_SALE_STATS: 'MOBILE_SALE_STATS',
  EMPLOYEE_CHANGE_PASSWORD_DATE: 'EMPLOYEE_CHANGE_PASSWORD_DATE',
  MOBILE_PAYMENT_TYPE: 'MOBILE_PAYMENT_TYPE',
  SEARCH_LEAD_EXTRA: 'SEARCH_LEAD_EXTRA',
  BUILDING_ALL: 'BUILDING_ALL',
  FLAT_PRICE_CHANGES_DATE: 'FLAT_PRICE_CHANGES_DATE',
  MY_HOME_CONFIG: 'MY_HOME_CONFIG',
  HOUSE_BUILDING: 'HOUSE_BUILDING',
  DOCUMENT_TEMPLATE_CUSTOM: 'DOCUMENT_TEMPLATE_CUSTOM',
  NUMERATION_CONFIG: 'NUMERATION_CONFIG',
  CLIENT_EVENT: 'CLIENT_EVENT'
};
