import { forwardRef } from 'react';
import { Table as BaseTable, TableProps as BaseTableProps } from 'antd';

interface IProps extends Partial<BaseTableProps<any>> {}

interface ITable extends React.ForwardRefExoticComponent<IProps & React.RefAttributes<HTMLDivElement>> {
  Column: typeof BaseTable.Column;
}

const forwardRefTable = forwardRef<HTMLDivElement, IProps>((props, ref) => <BaseTable {...props} ref={ref} />);

// const Table = Object.assign(
//   forwardRef<HTMLDivElement, IProps>((props, ref) => <BaseTable {...props} ref={ref} />),
//   {
//     Column: BaseTable.Column
//   }
// );

const Table = {
  ...forwardRefTable,
  Column: BaseTable.Column
} as ITable;

export default Table;
