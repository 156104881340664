/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-key */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from '@hooks/useQueryParams';
import CloseIcon from '@icons/CloseIcon';
import NavigationIcon from '@icons/NavigationIcon';
import { useAttachClient, useIgnoreClientLeads } from '@pages/oneLid/services/mutation';
import { useGetClientView, useGetSuggestionLeads } from '@pages/oneLid/services/queries';
import { setOpenDublicateModal } from '@reducers/CrmOneLidSlice';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL } from '@service/RequestService';
import { encodeString } from '@utils/helper';
import { OneLid } from '@utils/models/OneLid';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './leadCard.module.scss';

const SimilarLeads = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const leadIgnore = useIgnoreClientLeads();
  const attachClient = useAttachClient();
  const { t } = useTranslation();
  const { params } = useQueryParam();
  const { data } = useGetClientView(Number(params?.idClient));
  const { data: similarLeads } = useGetSuggestionLeads(Number(params?.idClient));

  const goToOneLead = (id: number, name: string) => {
    navigation(`${rootPaths.MAIN + MainPaths.CRM}/${id}/${encodeString(name)}`);
  };

  const handleIgnoreLeads = (currentObjectId: number, ignoreObjectIds: number[], ignoreType: string) => {
    leadIgnore.mutate({
      currentObjectId,
      ignoreObjectIds,
      ignoreType
    });
  };

  const handleAttachClient = (leadId: number, clientId: number) => {
    attachClient.mutate({ clientId, leadId });
  };

  return (
    <>
      {similarLeads?.length! > 0 && (
        <>
          <span className={styles.suggestionTitle}>{t('one_lid.Mijoz_raqamlari_oxshash')}</span>
          {similarLeads?.map(item => (
            <div className={styles.leadInfo}>
              <div className={styles.leadInfo_top}>
                <div className={styles.topLeft}>
                  <div className={styles.tags}>{item?.tags?.length > 0 ? item?.tags?.map(item => <p>{item?.name}</p>) : '-'}</div>
                  <div
                    onClick={() => {
                      handleIgnoreLeads(Number(data?.id), [item?.id], 'LEAD');
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className={styles.topRight}>
                  <div>{item?.pipeStatusResponse?.pipeName}</div>
                  <div
                    style={{
                      backgroundColor: item?.pipeStatusResponse?.color,
                      borderBottomColor: item?.pipeStatusResponse?.color,
                      borderRightColor: item?.pipeStatusResponse?.color,
                      borderTopColor: item?.pipeStatusResponse?.color
                    }}
                  >
                    {item?.pipeStatusResponse?.name}
                  </div>
                </div>
              </div>
              <div className={styles.leadInfo_line} />
              <div className={styles.leadInfo_bottom}>
                <span>
                  {item?.name}({t('home.Lid_nomi')})
                </span>
                <div className={styles.leadUser}>
                  <div className={styles.contacts}>
                    {item?.contacts?.map(item => (
                      <div className={styles.leadUser_contact}>
                        {item?.avatar ? (
                          <img
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: '50%'
                            }}
                            src={`${BASE_URL}/${item?.avatar}`}
                          />
                        ) : (
                          <div className={styles.userAvatar}>{item?.name?.substring(0, 1)}</div>
                        )}
                        <p>{item?.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className={styles.leadUser_navigate} onClick={() => goToOneLead(item?.id, item?.name)}>
                    {/* <span>{t('one_lid.Leadga_otish')}</span> */}
                    <NavigationIcon />
                  </div>
                </div>
                <Button
                  onClick={() => {
                    const clickedLid: OneLid = similarLeads?.find(prev => prev?.id === item?.id)!;

                    data?.lead
                      ? (dispatch(setOpenDublicateModal(true)),
                        dispatch(
                          setBalanceAndIds({
                            ids: [data?.lead!?.id, clickedLid!?.id],
                            balance: data?.lead?.balance! + clickedLid?.balance
                          })
                        ))
                      : handleAttachClient(Number(clickedLid?.id), Number(data?.id));
                  }}
                  loading={attachClient.isLoading}
                  className={styles.leadInfo_bottom_btn}
                >
                  {t('one_lid.biriktirish')}
                </Button>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default SimilarLeads;
