import React from 'react';

const SidebarArrowRightIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.47498 9.95986L7.73498 6.69986C8.11998 6.31486 8.11998 5.68486 7.73498 5.29986L4.47498 2.03986"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SidebarArrowRightIcon;
