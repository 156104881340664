import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useQueryParam } from '@hooks/useQueryParams';
import ContactPlusIcon from '@icons/ContactPlusIcon';
import CloseIcon from '@icons/contract/CloseIcon';
import { useGetInvitingClients, useGetOneClient } from '@pages/oneLid/services/queries';
import { setOpenConfirmModal } from '@reducers/CrmOneLidSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { OneLid } from '@utils/models/OneLid';
import { useTranslation } from 'react-i18next';

import ClientModal from './client-modal/ClientModal';
import ConfirmModal from './confirm-modal/ConfirmModal';
import RecomClient from './recom-client/RecomClient';

import styles from './clients.module.scss';

type Props = {
  data: OneLid | undefined;
};

const Clients: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { params } = useQueryParam<any, any>();
  const navigate = useNavigate();
  const { data: client } = useGetInvitingClients(params?.id);
  const { data: oneClient } = useGetOneClient(Number(data?.client?.id));

  const [visible, setVisible] = useState(false);

  // handle open client search modal

  const handleOpenModal = () => {
    setVisible(true);
  };

  const goToClientPage = (id: number, name: string) => {
    navigate(`${rootPaths.MAIN + MainPaths.CLIENT}/${id}/${name}`);
  };

  const urlName = oneClient?.type === 'JuridicalPerson' ? `${oneClient?.name}` : `${oneClient?.lastname} ${oneClient?.firstname} ${oneClient?.fatherName}`;

  return (
    <div className={styles.container}>
      {data?.client ? (
        <div className={styles.container_client}>
          <div
            className={styles.container_client_left}
            onClick={() => {
              goToClientPage(Number(data?.client?.id), String(urlName));
            }}
          >
            <div className={styles.circle}>{urlName.substring(0, 1)}</div>
            <div className={styles.container_client_left_item}>
              <p>{urlName}</p>
              <span>{data?.client?.phone}</span>
            </div>
          </div>
          <div className={styles.container_client_right} onClick={() => dispatch(setOpenConfirmModal(true))}>
            <CloseIcon />
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.container_add} onClick={handleOpenModal}>
            <ContactPlusIcon />
            <div className={styles.container_add_title}>{t('monetary.Mijoz_biriktirish')}</div>
          </div>
          <div className={styles.recommendedCustomer}>{client?.map(item => <RecomClient key={item?.id} item={item} />)}</div>
        </div>
      )}
      <ClientModal visible={visible} setVisible={setVisible} clientId={Number(data?.client?.id)} />
      <ConfirmModal leadName={params?.name} clientName={`${oneClient?.lastname} ${oneClient?.firstname} ${oneClient?.fatherName}`} clientId={data?.client?.id} type="client" />
    </div>
  );
};

export default Clients;
