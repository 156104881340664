import React, { useEffect, useState } from 'react';
import styles from './botConfig.module.scss';
import { Button, Form, Input, Spin } from 'antd';
import { TelegramBot } from '../../../utils/models/TelegramBot';
import { useCreateTelegramBot, useStartTelegramBot, useStopTelegramBot } from '../../../queries/mutation';
import { useGetTelegramBot } from '../../../queries/queries';
import FlashSlash from '../../../assets/icons/FlashSlash';
import FlashIcon from '../../../assets/icons/FlashIcon';
import { useTranslation } from 'react-i18next';
import SettingHexagonIcon from '../../../assets/icons/SettingHexagonIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { rootPaths } from '../../../routing/rootPaths';
import { MainPaths } from '../../../routing/main/mainPaths';
import { IntegrationPaths } from '../../../routing/integration/integrationPaths';
import { SettingsPaths } from '../../../routing/settings/settingsPaths';
import SendMessageModal from './sendMessageModal/SendMessageModal';

const BotConfig = () => {
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const { push } = useQueryParam();

  const [form] = Form.useForm<TelegramBot>();

  const { isLoading, data, isFetching } = useGetTelegramBot();
  const createBot = useCreateTelegramBot();
  const startBot = useStartTelegramBot();
  const stopBot = useStopTelegramBot();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  // handle send message modal
  const handleSendMessageModal = (isOpen: boolean) => {
    setOpenSendMessageModal(isOpen);
  };

  const onFinish = (values: TelegramBot) => {
    if (data) {
      createBot.mutate({
        ...data,
        ...values,
        admin: values.admin?.trim(),
        username: values.username?.trim(),
        token: values.token?.trim()
      });
    } else {
      createBot.mutate({
        ...values,
        admin: values.admin?.trim(),
        username: values.username?.trim(),
        token: values.token?.trim()
      });
    }
  };

  const handleStart = () => {
    startBot.mutate();
  };

  const handleStop = () => {
    stopBot.mutate();
  };

  const goToBotButtons = () => {
    push(rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTEGRATION + IntegrationPaths.TELEGRAM + IntegrationPaths.BOT_INTERFACE);
  };

  return (
    <div className={styles.cont}>
      <Spin spinning={isLoading || isFetching || stopBot.isLoading || startBot.isLoading}>
        <Form onFinish={onFinish} name={'telegram-bot'} form={form}>
          <div className={styles.cont_header}>
            <div className={styles.cont_header_left}>
              <h1>{t('integration.Telegram_BOT')}</h1>
              <h2>{t('integration.Korporativ_botingizni_boglang_va_mijozlaringiz_bilan_bevosita_muloqot_qiling')}</h2>
            </div>
            <div className={'d-flex align-center'}>
              {data?.username && (
                <>
                  <Button onClick={() => handleSendMessageModal(true)} className={styles.cont_header_button_message} type={'primary'}>
                    {t('home.Xabar_yuborish')}
                  </Button>
                  <div onClick={goToBotButtons} className={styles.cont_header_settings}>
                    <SettingHexagonIcon />
                    <span>{t('integration.Sozlash')}</span>
                  </div>
                </>
              )}
              {data?.active ? (
                <Button className={styles.cont_header_button_active} type={'primary'} loading={stopBot.isLoading} onClick={handleStop}>
                  <FlashIcon />
                  {t('integration.Aktiv')}
                </Button>
              ) : (
                <Button type={'primary'} danger loading={startBot.isLoading} onClick={handleStart} className={styles.cont_header_button}>
                  <FlashSlash />
                  {t('integration.Aktivlashtirish')}
                </Button>
              )}
            </div>
          </div>
          <div className={styles.cont_body}>
            <div className={styles.cont_body_item}>
              <div className={styles.cont_body_item_left}>
                <h3>{t('integration.Botning_foydalanuvchi_nomi')}</h3>
                <p>{t('integration.Masalan_qwerty_bot')}</p>
              </div>
              <div className={styles.cont_body_item_right}>
                <Form.Item
                  name={'username'}
                  label={''}
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <Input
                    style={{
                      width: '319px'
                    }}
                    className={'customInput'}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.cont_body_item}>
              <div className={styles.cont_body_item_left}>
                <h3>{t('integration.Botning_tokeni')}</h3>
                <p>{t('integration.Masalan_AA')}</p>
              </div>
              <div className={styles.cont_body_item_right}>
                <Form.Item
                  name={'token'}
                  label={''}
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <Input
                    style={{
                      width: '499px'
                    }}
                    className={'customInput'}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={styles.cont_body_item}>
              <div className={styles.cont_body_item_left}>
                <h3>{t('integration.Masul_adminning_telegram_ID_raqami')}</h3>
                <p>{t('integration.Masalan_51')}</p>
              </div>
              <div className={styles.cont_body_item_right}>
                <Form.Item
                  name={'admin'}
                  label={''}
                  rules={[
                    {
                      required: true,
                      message: ''
                    }
                  ]}
                >
                  <Input
                    style={{
                      width: '319px'
                    }}
                    className={'customInput'}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={styles.cont_footer}>
            <Button loading={createBot.isLoading} htmlType={'submit'} className={styles.cont_footer_save} type={'primary'}>
              {t('integration.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
      {data?.username && <SendMessageModal visible={openSendMessageModal} close={handleSendMessageModal} />}
    </div>
  );
};

export default BotConfig;
