import { Checkbox, Collapse, Drawer, Select, Space, Spin } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeFilter from './employee/EmployeeFilter';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { SearchParamsArrearage } from '../../Arrearage';
import { useCurrencyList, useHousesNameAndID } from '../../../../queries/queries';
import FilterReloadIcon from '../../../../assets/icons/FilterReloadIcon';
import ColapseIcon from '../../../../assets/icons/ColapseIcon';
import FIlterColapseIcon from '../../../../assets/icons/FIlterColapseIcon';
import HouseItem from '../../../contract/components/table/houseItem/HouseItem';
import SlashIcon from '../../../../assets/icons/SlashIcon';
import { tableStatusColor } from '../../../../utils/constants/TableStatusColor';
import EllipseIcon from '../../../../assets/icons/EllipseIcon';
import { ContractStatus } from '@utils/models/ContractStatus';
import '@components/filters/filterDrawer.module.scss';

const { Panel } = Collapse;

interface ArrearageFilterDrawerProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const contractStatus = ['ACTIVE', "BOSHLANG'ICH"];

const ArrearageFilterDrawer: FC<ArrearageFilterDrawerProps> = ({ visible, setVisible }) => {
  const { removeAllSearchParams, appendMultiple, searchParams, remove } = useQueryParam<SearchParamsArrearage, any>();
  const { t } = useTranslation();
  const { isLoading, data: houses } = useHousesNameAndID();
  const { data: currencyList } = useCurrencyList();

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const handleColor = (key: string) => {
    searchParams.color?.includes('NOTHING') && remove('color', 'NOTHING');
    searchParams.color?.includes(key) ? remove('color', key) : appendMultiple('color', key);
  };

  const clearColor = () => {
    if (searchParams.color) {
      for (let i = 0; i <= searchParams.color.length; i++) {
        remove('color', searchParams.color[i]);
      }
    }
    searchParams.color?.includes('NOTHING') ? remove('color', 'NOTHING') : appendMultiple('color', 'NOTHING');
  };

  const changeCurrency = (value: string) => {
    if (Array.isArray(searchParams.currencies)) {
      searchParams.currencies.includes(value) ? remove('currencies', value) : appendMultiple('currencies', value);
    } else {
      if (searchParams.currencies === value) {
        remove('currencies', value);
      } else {
        appendMultiple('currencies', value);
      }
    }
  };

  const changeContractStatus = (value: ContractStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else {
      if (searchParams.statuses === value) {
        remove('statuses', value);
      } else {
        appendMultiple('statuses', value);
      }
    }
  };

  return (
    <Drawer width={265} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className={'filter_drawer'}>
      <Spin spinning={isLoading}>
        <h1>
          <p>{t('home.Filtr')}</p>
          <span onClick={deleteAllFilters}>
            <FilterReloadIcon />
            {t('home.Tozalash')}
          </span>
        </h1>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition={'right'}
          className="filter_drawer_colapse"
          expandIcon={({ isActive }) => (
            <span
              style={{
                transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
                transition: '0.3s'
              }}
            >
              <ColapseIcon />
            </span>
          )}
        >
          <Panel
            className={'house_item'}
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Turar_joy_majmuasi')}
              </>
            }
            key="1"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {houses?.map((house, index) => <HouseItem key={index} id={house.id || -1} name={house.name} />)}
            </Space>
          </Panel>
          <Panel
            className="colorPanel"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Belgi_ranglari')}
              </>
            }
            key="2"
          >
            <div>
              <span onClick={clearColor}>
                <SlashIcon />
              </span>
              {Object.values(tableStatusColor).map(item =>
                item.type === 'NOTHING' ? null : (
                  <span
                    key={item.color}
                    onClick={() => handleColor(item.type)}
                    className={
                      Array.isArray(searchParams.color)
                        ? searchParams.color.includes(item.type)
                          ? 'activeColor'
                          : ''
                        : searchParams.color === item.type
                          ? 'activeColor'
                          : ''
                    }
                  >
                    <EllipseIcon background={item.color} stroke={item.color} />
                  </span>
                )
              )}
            </div>
          </Panel>
          <Panel
            className="colorPanel"
            header={
              <>
                <FIlterColapseIcon />
                {t('home.Xodimlar')}
              </>
            }
            key="3"
          >
            <EmployeeFilter ids={searchParams.responsibleByIds} />
          </Panel>
          {currencyList && currencyList?.length > 1 && (
            <Panel
              className="colorPanel"
              header={
                <>
                  <FIlterColapseIcon />
                  {t('create_payment.Valyuta')}
                </>
              }
              key="4"
            >
              <Space direction="vertical">
                {currencyList?.map(item => (
                  <Checkbox
                    key={item.id}
                    onChange={() => changeCurrency(String(item.id))}
                    checked={
                      Array.isArray(searchParams.currencies)
                        ? searchParams.currencies.includes(String(item.id))
                        : searchParams.currencies === String(item.id)
                    }
                  >
                    {item.ccy}
                  </Checkbox>
                ))}
              </Space>
            </Panel>
          )}
          <Panel
            header={
              <>
                <FIlterColapseIcon />
                {t('premium_modal.Shartnoma_holati')}
              </>
            }
            key="5"
          >
            <Space direction="vertical">
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.ACTIVE)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(ContractStatus.ACTIVE)
                    : searchParams.statuses === ContractStatus.ACTIVE
                }
              >
                {t(`home.${ContractStatus.ACTIVE}`)}
              </Checkbox>
              <Checkbox
                onChange={() => changeContractStatus(ContractStatus.STARTED)}
                checked={
                  Array.isArray(searchParams.statuses)
                    ? searchParams.statuses.includes(ContractStatus.STARTED)
                    : searchParams.statuses === ContractStatus.STARTED
                }
              >
                {t(`home.${ContractStatus.STARTED}`)}
              </Checkbox>
            </Space>
          </Panel>
        </Collapse>
      </Spin>
    </Drawer>
  );
};

export default ArrearageFilterDrawer;
