import React from 'react';
import RemainingHouses from '@components/bossComplex/remaining-house/RemainingHouses';
import { Layout } from 'antd';

import ComplexBarChart from '../../components/bossComplex/ComplexBarChart';
import PrepaymentHouses from '../../components/bossComplex/PrepaymentHouses';
import SaleFlats from '../../components/bossComplex/SaleFlats';
import SumPriceDonutChart from '../../components/bossComplex/SumPriceDonutChart';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';

import PerAreaAvgPrice from './per-area-avg-price/PerAreaAvgPrice';

import styles from './bossComplex.module.scss';

const { Content } = Layout;

const BossComplex = () => (
  <>
    <MainHeader>
      <BreadcrumbHeader />
      <NotificationButtons />
    </MainHeader>
    <Content
      style={{
        padding: '1rem 2.68rem',
        overflowY: 'auto',
        height: '50vh'
      }}
    >
      <div className={styles.container}>
        <div className="d-flex flex-direction-column">
          <SumPriceDonutChart />
          <SaleFlats />
          <PerAreaAvgPrice />
        </div>
        <div className="d-flex flex-direction-column">
          <ComplexBarChart />
          <PrepaymentHouses />
          <RemainingHouses />
        </div>
      </div>
    </Content>
  </>
);

export default BossComplex;
