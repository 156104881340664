import { useParams } from 'react-router-dom';
import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

export const useBuildingOrdersSave = () => {
  const qc = useQueryClient();
  const { id } = useParams<{ id: string }>();

  return useMutation(async (data: { buildingId: number; orders: number }[]) => {
    const res = await $api.post(`/v1/building/orders/${id}`, data);

    return res.data.data;
  });
};
