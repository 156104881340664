import React from 'react';
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { cx } from '@utils/cx';

import cls from './wrapper.module.scss';

export interface BaseProps {
  control: Control<any>;
  label?: React.ReactNode;
  labelClassName?: string;
  errorMessage?: boolean;
  id?: string;
}

interface WrapperProps extends BaseProps {
  name: string;
  disabled?: boolean;
  children: (field: ControllerRenderProps<FieldValues, any>, error: string) => React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ control, name, label = '', labelClassName, children, errorMessage = true, id }) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState }) => {
      const error = fieldState.error?.message!;

      return (
        <div className={cx('flex w-full flex-col gap-1', cls.wrapper)}>
          {label && (
            <label htmlFor={id || name} className={cx('text-[12px] font-normal text-gray-500', labelClassName)} hidden={!label}>
              {label} :
            </label>
          )}
          {children(field, fieldState?.error?.message!)}
          {errorMessage && error && <div className="text-[12px] !text-red-600">{error}</div>}
        </div>
      );
    }}
  />
);

export default React.memo(Wrapper, () => false);
