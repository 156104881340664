/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import dayjs from 'dayjs';
import { Input, Select } from 'global';
import { MaskedRange } from 'imask/esm/index';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { useCrmFields, useTriggersOneTrigger } from '../../../../../../queries/queries';
import { clearAll } from '../../../../../../store/reducers/TriggerSlice';
import { CrmFieldsType } from '../../../../../../utils/models/CrmFieldsType';
import { FieldsCondition, OrderTriggerCondition, TriggerConditionType, TYPE_TRIGGER } from '../../../../../../utils/models/Trigger';

import Budget from './budget/Budget';
import Buttons from './buttons/Buttons';
import ChangeResponsible from './changeResponsible/ChangeResponsible';
import Condition from './condition/Condition';
import DateFinish from './dateFinish/DateFinish';
import DoneBy from './DoneBy/DoneBy';
import MultiResponsible from './multiResponsible/MultiResponsible';
import MultiSelectField from './multiSelectField/MultiSelectField';
import Note from './note/Note';
import PipeStatus from './pipeStatus/PipeStatus';
import Responsible from './responsible/Responsible';
import Teg from './teg/Teg';
import TextField from './textField/TextField';
import Type from './type/Type';

import styles from './triggers.module.scss';

const Triggers = () => {
  const { data } = useCrmFields();

  const [recallNumber, setRecallNumber] = useState<number | undefined>();
  const [intervalTime, setIntervalTime] = useState<number | undefined>();
  const [timeValues, setTimeValues] = useState<{ first: string, second: string }>({ first: '', second: '' });

  const handleTimeChange = (key: 'first' | 'second', value: string) => {
    setTimeValues(prev => ({ ...prev, [key]: value }));
  };

  const { t } = useTranslation();

  const visible = useAppSelector(state => state.triggerSlice.visible);
  const id = useAppSelector(state => state.triggerSlice.id);
  const orders = useAppSelector(state => state.triggerSlice.orderTriggerCondition);

  const fieldsValue = useAppSelector(state => state.triggerSlice.fields);
  const type = useAppSelector(state => state.triggerSlice.typeTrigger);

  const dispatch = useAppDispatch();

  const { isLoading, data: dataOneTrigger } = useTriggersOneTrigger(id, type);

  useEffect(() => {
    if (dataOneTrigger) {
      setRecallNumber(dataOneTrigger?.recallNumber);
      setIntervalTime(dataOneTrigger?.intervalTime);
      setTimeValues({
        first: dataOneTrigger?.workTimeStart || '',
        second: dataOneTrigger?.workTimeEnd || ''
      });
    }
  }, [dataOneTrigger]);

  // handle cancel
  const handleCancel = () => {
    dispatch(clearAll());
    setRecallNumber?.(undefined);
    setIntervalTime?.(undefined);
    setTimeValues?.({ first: '', second: '' });
  };

  // show current
  const showConditions = (order: OrderTriggerCondition, index: number) => {
    switch (order.type) {
      case TriggerConditionType.BUDGET:
        return <Budget key={index} order={index} />;
      case TriggerConditionType.EMPLOYEE:
        return <MultiResponsible key={index} order={index} />;
      case TriggerConditionType.TAG:
        return <Teg key={index} order={index} />;
      case TriggerConditionType.FIELD:
        const field = data?.find(data => data.id === order.fieldId);
        const fieldValue = fieldsValue?.find(item => item.fieldId === order.fieldId);

        if (field?.type === CrmFieldsType.TEXT || field?.type === CrmFieldsType.COUNTER)
          return <TextField fieldValue={fieldValue as FieldsCondition} field={field} order={index} key={index} />;

        if (field?.type === CrmFieldsType.SELECT)
          return <MultiSelectField fieldValue={fieldValue as FieldsCondition} field={field} order={index} key={index} />;

        if (field?.type === CrmFieldsType.MULTI_SELECT)
          return <MultiSelectField fieldValue={fieldValue as FieldsCondition} field={field} order={index} key={index} />;

        return null;
      default:
        return null;
    }
  };

  return (
    <Modal title="" closable={false} footer={false} visible={visible} width={442} className={styles.container} onCancel={handleCancel}>
      <Spin spinning={isLoading}>
        <div className={styles.container_header}>
          <h1>{t('triggers.Ushbu_korinishdagi_barcha_bitimlar_uchun')}</h1>
          {orders.map((order, index) => showConditions(order, index))}
          <Condition orders={orders} />
        </div>
        <div className={styles.container_body}>
          {type === TYPE_TRIGGER.TASK_TRIGGER || type === TYPE_TRIGGER.PIPE_STATUS_TRIGGER || type === TYPE_TRIGGER.MANAGER_TRIGGER && (<DoneBy />)}
          {/* <DoneBy /> */}
          {type === TYPE_TRIGGER.TASK_TRIGGER && (
            <>
              <DateFinish />
              <Responsible />
              <Type />
              <Note showTextArea={true} />
              <Buttons />
            </>
          )}
          {type === TYPE_TRIGGER.PIPE_STATUS_TRIGGER && (
            <>
              <PipeStatus />
              <Note showTextArea={false} />
              <Buttons />
            </>
          )}
          {type === TYPE_TRIGGER.MANAGER_TRIGGER && (
            <>
              <ChangeResponsible />
              <Note showTextArea={false} />
              <Buttons />
            </>
          )}
          {type === TYPE_TRIGGER.CALL_BROJECT_TRIGGER && (
            <>
              <h3 className='text-[#8c8c8c] text-[12px] font-medium mb-[3px]'>{t('home.Qayta_qongiroqlar_soni')}</h3>
              <Input
                value={recallNumber}
                placeholder='Max: 5'
                className='text-[12px] py-[5px] mb-[16px]'
                type='number'
                maskOption={{ min: 1, max: 5 }}
                onChange={e => setRecallNumber(Number(e))}
              />

              <h3 className='text-[#8c8c8c] text-[12px] font-medium mb-[-12px]'>{t('create_table_payments.Vaqt_oraligi')}</h3>
              <Select
                value={intervalTime}
                onChange={value => setIntervalTime(value)}
                className='mt-[16px] mb-[18px] text-[12px]'
                placeholder={t('home.Tanlang')}
              >
                <Select.Option value={30}>{t('one_lid.30_minut')}</Select.Option>
                <Select.Option value={1}>1 {t('triggers.soat')}</Select.Option>
                <Select.Option value={2}>2 {t('triggers.soat')}</Select.Option>
                <Select.Option value={3}>3 {t('triggers.soat')}</Select.Option>
                <Select.Option value={4}>4 {t('triggers.soat')}</Select.Option>
              </Select>

              <PipeStatus />

              <div className='rounded-[6px] border-[1px] border-solid border-[#E5E7EB] py-[8px] px-[10px] flex items-center mb-[16px]'>
                <p className='text-[#9DA4AE] text-[14px] font-normal mr-[20px]'>{t('home.Ish_vaqti')}</p>

                <Input
                  value={timeValues.first || ''}
                  onClick={e => e.stopPropagation()}
                  onChange={e => handleTimeChange('first', e)}
                  placeholder="00:00"
                  width={65}
                  type="mask"
                  maskOption={{
                    mask: Date,
                    pattern: 'HH:mm',
                    format: (date: Date | dayjs.Dayjs | null) => dayjs(date).format('HH:mm'),
                    parse: (str: string) => dayjs(str, 'HH:mm').toDate(),
                    blocks: {
                      HH: {
                        mask: MaskedRange,
                        from: 0,
                        to: 23
                      },
                      mm: {
                        mask: MaskedRange,
                        from: 0,
                        to: 59
                      }
                    }
                  }}
                />
                <p className='text-[#9DA4AE] text-[12px] font-normal mx-[10px]'>-</p>
                <Input
                  value={timeValues.second || ''}
                  onClick={e => e.stopPropagation()}
                  onChange={e => handleTimeChange('second', e)}
                  placeholder="00:00"
                  width={65}
                  type="mask"
                  maskOption={{
                    mask: Date,
                    pattern: 'HH:mm',
                    format: (date: Date | dayjs.Dayjs | null) => dayjs(date).format('HH:mm'),
                    parse: (str: string) => dayjs(str, 'HH:mm').toDate(),
                    blocks: {
                      HH: {
                        mask: MaskedRange,
                        from: 0,
                        to: 23
                      },
                      mm: {
                        mask: MaskedRange,
                        from: 0,
                        to: 59
                      }
                    }
                  }}
                />

              </div>
              <Buttons
                recallNumber={recallNumber}
                intervalTime={intervalTime}
                timeValues={timeValues}
                setIntervalTime={setIntervalTime}
                setRecallNumber={setRecallNumber}
                setTimeValues={setTimeValues}
              />
            </>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default Triggers;
