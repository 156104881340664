import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import CheveronBottom from '@icons/CheveronBottom';
import Edit2Icon from '@icons/Edit2Icon';
import MinusRedIcon from '@icons/MinusRedIcon';
import MoreHorizIcon from '@icons/MoreHorizIcon';
import PlusIcon from '@icons/PlusIcon';
import ReceiveSquare from '@icons/ReceiveSquare';
import TrushSquareBlackStrokeIcon from '@icons/TrushSquareBlackStrokeIcon';
import { useFlatFieldDelete } from '@queries/mutation';
import { useDiscount, useFlatFieldGet } from '@queries/queries';
import { noResidentialTypeOptions } from '@utils/constants/noResidentialTypeOptions';
import { Permissions } from '@utils/constants/permissions';
import { helper } from '@utils/helper/helper';
import { mathFloor } from '@utils/helper/mathFloor';
import message from '@utils/helper/message';
import { DiscountList } from '@utils/models/Discount';
import { FileObject } from '@utils/models/File';
import { FlatFieldId, FlatId } from '@utils/models/Flat';
import { FlatField } from '@utils/models/FlatField';
import { Button, Dropdown, Form, Input, Menu, Radio, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { useContractVariationGroup } from 'modules/multi-payment';

import { ShaxmatkaContext } from '../hooks/ShaxmatkaContext';

import { getSelectableForm } from './helper/getSelectableForm';
import { handleFormSave } from './helper/handleFormSave';
import CreateConstantModal from './CreateConstantModal';
import TerassaButton from './TerassaButton';
import UploadExtraFiles from './UploadExtraFiles';
import UploadFIle from './UploadFIle';

import '@pages/shaxmatka/shaxmatka.module.scss';

interface OpenDrawerProps {
  room: FlatId | undefined;
  setRoom: (room: FlatId | any) => void;
  selectVsible: any;
  indexesToSelect: FlatId[];
  setSaveAll: (save: boolean) => void;
}

const { Option } = Select;

export const category = [
  { id: 'ECONOMICAL', label: 'Ekanom' },
  { id: 'COMFORT', label: 'Kamfort' },
  { id: 'BUSINESS', label: 'Biznes' },
  { id: 'PREMIUM', label: 'Premium' }
];

const OpenDrawer: FC<OpenDrawerProps> = ({ room, selectVsible, setSaveAll }) => {
  const { user } = useAppSelector(state => state.userReducer);
  const { data: variations } = useContractVariationGroup();
  const { activeFlats } = useContext(ShaxmatkaContext);
  const dispatch = useAppDispatch();
  const houseCurrency = useAppSelector(state => state.currency.houseCurrency);
  const { data: shaxmatka, priceCalculateType, tableMode } = useAppSelector(state => state.shaxmatkaReducer);
  const flatFieldDelete = useFlatFieldDelete();
  const isSaveImageNew = useRef(false);
  const { data: discountData } = useDiscount();
  const { data: flatField } = useFlatFieldGet();
  const [openConstants, setOpenConstants] = useState(false);
  const [openConstantsEdit, setOpenConstantsEdit] = useState(0);
  const [flatFieldOne, setFlatFieldOne] = useState<FlatField | undefined>();
  const [form] = Form.useForm();
  const [terassaVisible, setTerassaVisible] = useState({
    visible: false,
    isDelete: false
  });
  const [homePlanFileImageId, setHomePlanFileImageId] = useState<any>();
  const [buildingLocationImageId, setBuildingLocationImageId] = useState<any>();
  const [extraImages, setExtraImages] = useState<FileObject[] | any>([]);
  const [roomObj, setRoomObj] = useState<FlatId>();
  const [constantsCopy, setConstantsCopy] = useState<any[]>([]);

  const isMultiPayment = user?.role?.permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const apartmentField = Form.useWatch('apartment', form);

  const { t } = useTranslation();

  const addExtraImage = (file: FileObject) => {
    setExtraImages([...extraImages, file]);
  };

  const deleteExtraImage = (id: number) => {
    isSaveImageNew.current = true;
    setExtraImages(extraImages.filter((image: FileObject) => image.id !== id));
  };

  const imageDelete = () => {
    isSaveImageNew.current = true;
  };

  // console.log(form.getFieldsValue());

  useEffect(() => {
    const rooms = document.querySelectorAll('.selectableItems');

    const arrSelect: FlatId[] = [...((tableMode && activeFlats) || [])];

    if (!tableMode) {
      rooms.forEach((item: any) => {
        const flat: FlatId = JSON.parse(item.getAttribute('data'));

        if (flat.isSelected) arrSelect.push(flat);
      });
    }
    const constants = flatField;
    let fields: FlatFieldId[] = arrSelect[0]?.fields || [];

    arrSelect.forEach(flat => {
      fields = fields?.filter(item => flat?.fields?.some(el => item?.fieldId === el?.fieldId && item?.value === el?.value));
    });

    if (constants) {
      form.setFieldsValue({
        constants: constants?.map(item => ({
          ...item,
          id: Number(item.id),
          value: item.type === 'SELECT' ? item.options.find(i => i.name === fields?.find(i => i.fieldId === item.id)?.value)?.id : fields?.find(i => i.fieldId === item.id)?.value,
          name: item.name
        }))
      });
      setConstantsCopy([
        ...constants?.map(item => ({
          ...item,
          id: Number(item.id),
          value: item.type === 'SELECT' ? item.options.find(i => i.name === fields?.find(i => i.fieldId === item.id)?.value)?.id : fields?.find(i => i.fieldId === item.id)?.value,
          name: item.name
        }))
      ]);
    }
  }, [selectVsible, room, form, flatField, activeFlats]);

  useEffect(() => {
    const getSelectableFormProps = {
      form,
      isSaveImageNew,
      setHomePlanFileImageId,
      setBuildingLocationImageId,
      setExtraImages,
      setTerassaVisible,
      setRoomObj,
      activeFlats,
      tableMode
    };

    getSelectableForm(getSelectableFormProps);
  }, [selectVsible, room, form, activeFlats]);

  const onFinish = (values: any) => {
    const saveFormProps = {
      values,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getValueToSavePrice,
      terassaVisible,
      homePlanFileImageId,
      buildingLocationImageId,
      extraImages,
      isSaveImageNew,
      shaxmatka,
      dispatch,
      priceCalculateType,
      constantsCopy,
      activeFlats,
      tableMode
    };

    handleFormSave(saveFormProps);
  };

  // getValueToSavePrice
  const getValueToSavePrice = (value: string | null, oldValue?: number) => {
    try {
      if (!value) return 0;
      if (value === '*') return oldValue;
      if (helper.parseFloatStringToNumber(String(value))) return helper.parseFloatStringToNumber(String(value));
      // if (String(value)) return (String(value));
      return null;
    } catch (e) {
      return null;
    }
  };

  // getValueToSaveFullPrice
  // const getValueToSaveFullPrice = (value: string | null, oldValue: number, area: string | null) => {
  //   try {
  //     if (value === '*' || area === '*') return oldValue;
  //     if (oldValue) return helper.parseFloatStringToNumber(String(oldValue));
  //     if (!helper.parseFloatStringToNumber(value!)) return null;
  //     if (!Number(String(area)?.replace(/\D/g, ''))) return null;
  //     return helper.parseFloatStringToNumber(value!) * Number(String(area)?.replace(/[-e]/g, ''));
  //   } catch (e) {
  //     return null;
  //   }
  // };

  // saveValueToForm
  const saveValueToForm = (name: string, value: string) => {
    form.setFieldsValue({
      [name]: helper.deleteNotNumbersAndParseFloatNumber(value).string
    });
  };
  const getValueToForm = (name: string) => form.getFieldValue(name);

  // inputKeyUp
  const inputKeyUp = (e: any, format: any) => {
    const n = e.target.value;

    if (format === 'number') {
      e.target.value = helper.deleteNotNumbersToNumber(n).string.replace(/,/g, '.');
    } else {
      e.target.value = helper.deleteNotNumbersAndParseFloatNumber(n).string;
    }
  };

  // handleClickSubmitButton
  const handleClickSubmitButton = async () => {
    try {
      await form.validateFields();
      const mSpinning: any = document.querySelector('.mSpinning');

      mSpinning.style.display = 'flex';
      message.success("Ma'lumotlar o'zgartirildi");
    } catch (e) {
      /* empty */
    } finally {
      setSaveAll(false);
    }
  };

  // resedClear
  const resedClear = () => {
    form.resetFields();
    setBuildingLocationImageId(null);
    setHomePlanFileImageId(null);
    setExtraImages(null);
  };

  // keyDown
  const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      form.submit();
    }
  };

  // handleCreateConstants
  const handleCreateConstants = () => {
    setOpenConstants(true);
  };

  // handleCreateConstantsEdit
  const handleCreateConstantsEdit = (id: number) => {
    setOpenConstantsEdit(id);
    setOpenConstants(true);
  };

  // handleDelete
  const handleDelete = (id: number) => {
    flatFieldDelete.mutate(id);
  };

  // handleSwitch
  // const handleSwitch = () => {
  //   dispatch(setPriceCalculateType(PriceCalculateType))
  // }

  useEffect(() => {
    if (priceCalculateType === 'STATIC') {
      setRoomObj({
        ...roomObj!,
        pricePerAreaRepaired: roomObj?.priceRepaired! / roomObj?.area!,
        pricePerArea: roomObj?.priceNotRepaired! / roomObj?.area!,
        priceRepaired: roomObj?.priceRepaired!,
        priceNotRepaired: roomObj?.priceNotRepaired!
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('pricePerAreaRepaired') !== '*' && saveValueToForm('pricePerAreaRepaired', String(roomObj?.priceRepaired! / roomObj?.area! || '*'));

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('pricePerArea') !== '*' && saveValueToForm('pricePerArea', String(roomObj?.priceNotRepaired! / roomObj?.area! || '*'));

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('priceRepaired') !== '*' && saveValueToForm('priceRepaired', String(roomObj?.priceRepaired! || '*'));

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('priceNotRepaired') !== '*' && saveValueToForm('priceNotRepaired', String(roomObj?.priceNotRepaired! || '*'));
    } else {
      setRoomObj({
        ...roomObj!,
        pricePerAreaRepaired: roomObj?.pricePerAreaRepaired!,
        pricePerArea: roomObj?.pricePerArea!,
        priceRepaired: roomObj?.area! * roomObj?.pricePerAreaRepaired!,
        priceNotRepaired: roomObj?.area! * roomObj?.pricePerArea!
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('pricePerAreaRepaired') !== '*' && saveValueToForm('pricePerAreaRepaired', String(roomObj?.pricePerAreaRepaired! || '*'));

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('pricePerArea') !== '*' && saveValueToForm('pricePerArea', String(roomObj?.pricePerArea! || '*'));

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('priceRepaired') !== '*' && saveValueToForm('priceRepaired', String(roomObj?.area! * roomObj?.pricePerAreaRepaired! || '*'));

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getValueToForm('priceNotRepaired') !== '*' && saveValueToForm('priceNotRepaired', String(roomObj?.area! * roomObj?.pricePerArea! || '*'));
    }
  }, [priceCalculateType]);

  const hasChange = (obj: any, key: string) => {
    if (getValueToForm(key) !== '*') {
      return obj;
    }
    return {};
  };

  return (
    <div className="shaxmatka_drawer">
      <Form form={form} onFinish={onFinish}>
        <div className="shaxmatka_drawer_title">
          <h1>{t('shaxmatka.Xonadon_sozlamasi')}</h1>
          <span onClick={() => resedClear()}>
            <ReceiveSquare /> {t('shaxmatka.Tozalash')}
          </span>
        </div>
        <div className="shaxmatka_drawer_building_info">
          <div>
            <h1>{t('shaxmatka.Bino_nomi')}</h1>
            <p>{room?.building?.name}</p>
          </div>
          <div>
            <h1>{t('shaxmatka.Xonadon_raqami')}</h1>
            <Form.Item name="number">
              <Input placeholder={t('shaxmatka.Masalan_3')} onKeyDown={e => keyDown(e)} />
            </Form.Item>
          </div>
          <div>
            <h1>{t('shaxmatka.Xonalar_soni')}</h1>
            <Form.Item name="rooms">
              <Input placeholder={t('shaxmatka.Masalan_3')} type="string" onKeyDown={e => keyDown(e)} />
            </Form.Item>
          </div>
          <div>
            <h1>{t('shaxmatka.Maydoni')}</h1>
            <Space>
              <Form.Item name="area">
                <Input
                  placeholder={t('shaxmatka.Masalan_3')}
                  onKeyUp={e => inputKeyUp(e, 'number')}
                  onChange={(e: any) => {
                    if (Number(e.target.value) < Number(form.getFieldValue('livingArea'))) {
                      form.setFieldsValue({ livingArea: e.target.value });
                    }
                    if (priceCalculateType === 'STATIC') {
                      setRoomObj({
                        ...roomObj!,
                        ...hasChange(
                          {
                            pricePerAreaRepaired: roomObj?.priceRepaired! / helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number
                          },
                          'priceRepaired'
                        ),
                        ...hasChange(
                          {
                            pricePerArea: roomObj?.priceNotRepaired! / helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number
                          },
                          'priceNotRepaired'
                        ),
                        ...hasChange({ priceRepaired: roomObj?.priceRepaired! }, 'priceRepaired'),
                        ...hasChange({ priceNotRepaired: roomObj?.priceNotRepaired! }, 'priceNotRepaired'),
                        area: Number(e.target.value)
                      });

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('pricePerAreaRepaired') !== '*' &&
                        saveValueToForm('pricePerAreaRepaired', String(roomObj?.priceRepaired! / helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number));

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('pricePerArea') !== '*' &&
                        saveValueToForm('pricePerArea', String(roomObj?.priceNotRepaired! / helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number));

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('priceRepaired') !== '*' && saveValueToForm('priceRepaired', String(roomObj?.priceRepaired!));

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('priceNotRepaired') !== '*' && saveValueToForm('priceNotRepaired', String(roomObj?.priceNotRepaired!));
                    } else {
                      setRoomObj({
                        ...roomObj!,
                        ...hasChange(
                          {
                            pricePerAreaRepaired: roomObj?.pricePerAreaRepaired!
                          },
                          'pricePerAreaRepaired'
                        ),
                        ...hasChange({ pricePerArea: roomObj?.pricePerArea! }, 'pricePerArea'),
                        ...hasChange(
                          {
                            priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.pricePerAreaRepaired!
                          },
                          'pricePerAreaRepaired'
                        ),
                        ...hasChange(
                          {
                            priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.pricePerArea!
                          },
                          'pricePerArea'
                        ),
                        area: Number(e.target.value)
                      });

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('pricePerAreaRepaired') !== '*' && saveValueToForm('pricePerAreaRepaired', String(roomObj?.pricePerAreaRepaired!));

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('pricePerArea') !== '*' && saveValueToForm('pricePerArea', String(roomObj?.pricePerArea!));

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('priceRepaired') !== '*' &&
                        saveValueToForm('priceRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.pricePerAreaRepaired!));

                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      getValueToForm('priceNotRepaired') !== '*' &&
                        saveValueToForm('priceNotRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.pricePerArea!));
                    }
                  }}
                  onKeyDown={e => keyDown(e)}
                />
              </Form.Item>
              {t('shaxmatka.m')}
              <sup>2</sup>
            </Space>
          </div>
          <div>
            <h1>{t('shaxmatka.yashash_maydoni')}</h1>
            <Space>
              <Form.Item
                name="livingArea"
                rules={[
                  {
                    validator: (_, value) => {
                      if (Number(form.getFieldValue('area')) <= Number(value)) {
                        form.setFieldsValue({ livingArea: form.getFieldValue('area') });
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.resolve();
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input placeholder={t('shaxmatka.Masalan_3')} onKeyUp={e => inputKeyUp(e, 'number')} onKeyDown={e => keyDown(e)} />
              </Form.Item>
              {t('shaxmatka.m')}
              <sup>2</sup>
            </Space>
          </div>
        </div>
        <div className="shaxmatka_drawer_repaired">
          <h1>
            {t('shaxmatka.Tamirsiz')} {/* <SwitchIcon onClick={handleSwitch} cursor={'pointer'} /> */}
          </h1>
          <div>
            <h1>
              1 {t('shaxmatka.m')}
              <sup>2</sup> {t('shaxmatka.narxi')}
            </h1>
            {priceCalculateType === 'STATIC' && (
              <p>
                {helper.deleteNotNumbersAndParseFloatNumber(String(roomObj?.priceNotRepaired! / roomObj?.area!)).string} {houseCurrency?.ccy}
              </p>
            )}
            <div style={{ display: priceCalculateType === 'STATIC' ? 'none' : '' }} className="d-flex align-center">
              <Form.Item name="pricePerArea">
                <Input
                  placeholder={t('shaxmatka.Masalan_3')}
                  // onKeyUp={(e) => inputKeyUp(e, 'price')}
                  onChange={e => {
                    setRoomObj({
                      ...roomObj!,
                      pricePerArea: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number,
                      priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.area!
                    });
                    saveValueToForm('pricePerArea', e.target.value);
                    saveValueToForm('priceNotRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.area!));
                  }}
                  onKeyDown={e => keyDown(e)}
                />
              </Form.Item>
              {houseCurrency?.ccy}
            </div>
          </div>
          <div>
            <h1>{t('shaxmatka.Umumiy_narxi')}</h1>
            <div className="d-flex align-center" style={{ display: priceCalculateType === 'STATIC' ? '' : 'none' }}>
              <Form.Item name="priceNotRepaired">
                <Input
                  placeholder={t('shaxmatka.Masalan_3')}
                  // onKeyUp={(e) => inputKeyUp(e, 'price')}
                  onChange={e => {
                    setRoomObj({
                      ...roomObj!,
                      pricePerArea: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number / roomObj?.area!,
                      priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number
                    });
                    saveValueToForm('pricePerArea', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number / roomObj?.area!));
                    saveValueToForm('priceNotRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).string));
                  }}
                  onKeyDown={e => keyDown(e)}
                />
              </Form.Item>
              {houseCurrency?.ccy}
            </div>
            {priceCalculateType !== 'STATIC' && (
              <p>
                {helper.deleteNotNumbersAndParseFloatNumber(String(roomObj?.pricePerArea! * roomObj?.area!)).string} {houseCurrency?.ccy}
              </p>
            )}
          </div>
          <h1>
            {t('shaxmatka.Tamirli')}{' '}
            {/* <SwitchIcon
              onClick={() =>
                setSwitchAllPrice((prev) => ({
                  ...prev,
                  repaired: !prev?.repaired,
                }))
              }
              cursor={'pointer'}
            /> */}
          </h1>
          <div>
            <h1>
              1 {t('shaxmatka.m')}
              <sup>2</sup> {t('shaxmatka.narxi')}
            </h1>
            {priceCalculateType === 'STATIC' && (
              <p>
                {helper.deleteNotNumbersAndParseFloatNumber(String(roomObj?.priceRepaired! / roomObj?.area!)).string} {houseCurrency?.ccy}
              </p>
            )}
            <div style={{ display: priceCalculateType !== 'STATIC' ? '' : 'none' }} className="d-flex align-center">
              <Form.Item name="pricePerAreaRepaired">
                <Input
                  placeholder={t('shaxmatka.Masalan_3')}
                  onKeyUp={e => inputKeyUp(e, 'price')}
                  onChange={e => {
                    setRoomObj({
                      ...roomObj!,
                      pricePerAreaRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number,
                      priceRepaired: mathFloor(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.area!, 2)
                    });
                    saveValueToForm('pricePerAreaRepaired', e.target.value);
                    saveValueToForm('priceRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number * roomObj?.area!));
                  }}
                  onKeyDown={e => keyDown(e)}
                />
              </Form.Item>
              {houseCurrency?.ccy}
            </div>
          </div>
          <div>
            <h1>{t('shaxmatka.Umumiy_narxi')}</h1>
            <div className="d-flex align-center" style={{ display: priceCalculateType === 'STATIC' ? '' : 'none' }}>
              <Form.Item name="priceRepaired">
                <Input
                  placeholder={t('shaxmatka.Masalan_3')}
                  // onKeyUp={(e) => inputKeyUp(e, 'price')}
                  onChange={e => {
                    setRoomObj({
                      ...roomObj!,
                      pricePerAreaRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number / roomObj?.area!,
                      priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number
                    });
                    saveValueToForm('pricePerAreaRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).number / roomObj?.area!));
                    saveValueToForm('priceRepaired', String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).string));
                  }}
                  onKeyDown={e => keyDown(e)}
                />
              </Form.Item>
              {houseCurrency?.ccy}
            </div>
            {priceCalculateType !== 'STATIC' && (
              <p>
                {helper.deleteNotNumbersAndParseFloatNumber(String(roomObj?.pricePerAreaRepaired! * roomObj?.area!)).string} {houseCurrency?.ccy}
              </p>
            )}
          </div>
        </div>
        <div className="shaxmatka_drawer_footer">
          <div className="shaxmatka_drawer_footer_default">
            <h1>{t('shaxmatka.Boshlangich_tolov')}</h1>
            <div className="d-flex align-center">
              <Form.Item
                rules={[
                  {
                    validator: (_, value) => {
                      if (Number(value) < 0 || Number(value) > 100) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                name="prepaymentPercent"
              >
                <Input
                  placeholder={t('shaxmatka.Masalan_3')}
                  onKeyUp={e => inputKeyUp(e, 'number')}
                  onChange={e => {
                    const { value } = e.target;

                    form.setFieldsValue({
                      prepaymentPercent: (() => {
                        if (value || value === '0') {
                          return Number(value) > 100 ? '100' : value;
                        }

                        return '';
                      })()
                    });
                  }}
                  onKeyDown={e => keyDown(e)}
                />
              </Form.Item>
              <span style={{ minWidth: '15px' }}>%</span>
            </div>
          </div>
          <div style={{ marginTop: '10px' }} className="shaxmatka_drawer_footer_default">
            <h1>{t('shaxmatka.Muddatli_tolov')}</h1>
            <div className="d-flex align-center">
              <Form.Item
                rules={[
                  {
                    validator: (_, value) => {
                      if (Number(value) < 0 || Number(value) > 240) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                name="delay"
              >
                <Input placeholder={t('shaxmatka.Masalan_3')} type="number" onKeyDown={e => keyDown(e)} />
              </Form.Item>
              <span style={{ minWidth: '15px' }}>{t('shaxmatka.oy')}</span>
            </div>
          </div>
          <h1>{t('shaxmatka.Xonadon_topshirilish_holati')}</h1>
          <Form.Item name="repaired">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={false}>{t('shaxmatka.Tamirsiz')}</Radio>
                <Radio value={true}>{t('shaxmatka.Tamirli')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <h1>{t('shaxmatka.Xonadon_turi')}</h1>
          <Form.Item name="apartment" initialValue={true}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={true}>{t('shaxmatka.Turar_joy')}</Radio>
                <Radio value={false}>{t('shaxmatka.Noturar_joy')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!apartmentField && (
            <Form.Item name="nonResidentialType" initialValue="SHOP">
              <Select className="customSelect mt-[8px]">
                {noResidentialTypeOptions.map(item => (
                  <Select.Option key={item} value={item}>
                    {t(`flat.${item}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <h1 className="text-[12px] font-semibold text-dark">{t('home.Xonadon_sinfi')}</h1>
          <Form.Item name="category">
            <Select className="customSelect" placeholder={t('home.Tanlang')}>
              {category?.map(item => (
                <Option key={item?.id} value={item?.id}>
                  {item?.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <h1>
            {t('shaxmatka.Terassa')}{' '}
            {terassaVisible.visible && (
              <span
                onClick={() =>
                  setTerassaVisible({
                    visible: false,
                    isDelete: true
                  })
                }
              >
                <MinusRedIcon />
              </span>
            )}
          </h1>
          <div className="shaxmatka_drawer_footer_terassa">
            {!terassaVisible.visible && (
              <TerassaButton
                onClick={() =>
                  setTerassaVisible({
                    visible: true,
                    isDelete: false
                  })
                }
              />
            )}
            {terassaVisible.visible && (
              <>
                <div>
                  <h1>{t('shaxmatka.Maydoni')}</h1>
                  <div className="d-flex align-center">
                    <Form.Item rules={[{ required: true }]} name="terraceArea">
                      <Input
                        placeholder={t('shaxmatka.Masalan_3')}
                        // type="number"
                        onKeyDown={e => keyDown(e)}
                        onKeyUp={e => inputKeyUp(e, 'number')}
                      />
                    </Form.Item>
                    {t('shaxmatka.m')}
                    <sup>2</sup>
                  </div>
                </div>
                <div>
                  <h1>{t('shaxmatka.1_m_kv_narxi_tamirli')}</h1>
                  <div className="d-flex align-center">
                    <Form.Item
                      // rules={[{ required: true }]}
                      name="terracePricePerArea"
                    >
                      <Input
                        onChange={e => {
                          saveValueToForm('terracePricePerArea', e.target.value);
                        }}
                        placeholder={t('shaxmatka.Masalan_3')}
                        onKeyUp={e => inputKeyUp(e, 'price')}
                        onKeyDown={e => keyDown(e)}
                      />
                    </Form.Item>
                    {houseCurrency?.ccy}
                  </div>
                </div>
                <div>
                  <h1>{t('shaxmatka.1_m_kv_narxi_tamirsiz')}</h1>
                  <div className="d-flex align-center">
                    <Form.Item
                      // rules={[{ required: true }]}
                      name="terracePricePerAreaRepaired"
                    >
                      <Input
                        onChange={e => {
                          saveValueToForm('terracePricePerAreaRepaired', e.target.value);
                        }}
                        placeholder={t('shaxmatka.Masalan_3')}
                        onKeyUp={e => inputKeyUp(e, 'price')}
                        onKeyDown={e => keyDown(e)}
                      />
                    </Form.Item>
                    {houseCurrency?.ccy}
                  </div>
                </div>
              </>
            )}
          </div>
          <h1>{t('shaxmatka.Uy_rejasi')}</h1>
          <div className="shaxmatka_drawer_footer_home_plan">
            <UploadFIle deleteImage={imageDelete} name="homePlan" fileId={homePlanFileImageId} setFileId={setHomePlanFileImageId} />
          </div>
          <h1>{t('shaxmatka.Binoda_joylashuvi')}</h1>
          <div className="shaxmatka_drawer_footer_home_plan">
            <UploadFIle deleteImage={imageDelete} name="buildingLocation" fileId={buildingLocationImageId} setFileId={setBuildingLocationImageId} />
          </div>
          <h1>{t('shaxmatka.Qoshimcha_rasmlar')}</h1>
          <div className="shaxmatka_drawer_footer_home_plan">
            <UploadExtraFiles deleteExtraImage={deleteExtraImage} extraImages={extraImages} addExtraImage={addExtraImage} />
          </div>
          <div className="shaxmatka_drawer_footer_discount">
            <h1>{t('shaxmatka.Chegirma')}</h1>
            <Form.Item name="discount">
              <Select allowClear placeholder={t('shaxmatka.Tanlash')} suffixIcon={<CheveronBottom />}>
                {discountData &&
                  discountData.map((discount: DiscountList) => (
                    <Select.Option value={discount.id} key={discount.id}>
                      {discount.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="shaxmatka_drawer_footer_constants">
            <div className="shaxmatka_drawer_footer_constants_title">
              {t('shaxmatka.Ozgaruvchilar')}
              {flatField && flatField?.length > 0 && (
                <span onClick={handleCreateConstants}>
                  <PlusIcon />
                </span>
              )}
            </div>
            <div className="shaxmatka_drawer_footer_constants_content">
              {!(flatField && flatField?.length > 0) && (
                <Button className="shaxmatka_drawer_footer_constants_createButton" onClick={handleCreateConstants}>
                  <PlusIcon /> {t('shaxmatka.Ozgaruvchilar')}
                </Button>
              )}
              {flatField && (
                <Form.List name="constants">
                  {fields => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} align="baseline">
                          <Form.Item {...restField} name={[name, 'value']}>
                            {flatField[key]?.type === 'TEXT' ? (
                              <Input className="customInput" placeholder={flatField[key].name} />
                            ) : (
                              <Select className="customSelect" placeholder={flatField[key]?.name} style={{ width: 190 }} allowClear>
                                {flatField[key]?.options.map(item => (
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                          <Dropdown
                            trigger={['click']}
                            overlay={
                              <Menu>
                                <Menu.Item
                                  onClick={() => {
                                    setFlatFieldOne(flatField[key]);
                                    handleCreateConstantsEdit(flatField[key]?.id);
                                  }}
                                >
                                  <Edit2Icon /> {t('shaxmatka.Tahrirlash')}
                                </Menu.Item>
                                <Menu.Item
                                  onClick={() => {
                                    handleDelete(flatField[key]?.id);
                                  }}
                                >
                                  <TrushSquareBlackStrokeIcon /> {t('one_lid.Ochirish')}
                                </Menu.Item>
                              </Menu>
                            }
                            placement="bottomLeft"
                            arrow
                          >
                            <Button type="text">
                              <MoreHorizIcon />
                            </Button>
                          </Dropdown>
                        </Space>
                      ))}
                    </>
                  )}
                </Form.List>
              )}
            </div>
          </div>
          {isMultiPayment ? (
            <div>
              <label>
                <p className="mb-[4px]"> {t('contract.Tolov_taklifi')}</p>

                <Form.Item
                  // rules={[{ required: true }]}

                  name="variationGroupId"
                >
                  <Select placeholder={t('home.Tanlang')} className="customSelect" allowClear>
                    {variations?.map(item => (
                      <Select.Option key={item?.groupId} value={item?.groupId}>
                        {item?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </label>
            </div>
          ) : null}
        </div>
        <div className="shaxmatka_drawer_footer_button">
          <Button type="primary" htmlType="submit" onClick={handleClickSubmitButton}>
            {t('shaxmatka.Bajarish')}
          </Button>
        </div>
      </Form>
      <CreateConstantModal setVisible={setOpenConstants} visible={openConstants} setEdit={setOpenConstantsEdit} id={openConstantsEdit} flatFieldOne={flatFieldOne} />
    </div>
  );
};

export default OpenDrawer;
