import React from 'react';

interface Props {
  className?: string;
}

const ArchiveBookIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 5.83268V14.166C17.5 16.666 16.25 18.3327 13.3333 18.3327H6.66667C3.75 18.3327 2.5 16.666 2.5 14.166V5.83268C2.5 3.33268 3.75 1.66602 6.66667 1.66602H13.3333C16.25 1.66602 17.5 3.33268 17.5 5.83268Z"
        stroke="#87898E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9173 1.66602V8.216C12.9173 8.58267 12.484 8.766 12.2173 8.52433L10.284 6.74104C10.1257 6.59104 9.87563 6.59104 9.7173 6.74104L7.78401 8.52433C7.51734 8.766 7.08398 8.58267 7.08398 8.216V1.66602H12.9173Z"
        stroke="#87898E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.042 11.666H14.5837" stroke="#87898E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 15H14.5833" stroke="#87898E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArchiveBookIcon;
