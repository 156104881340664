import React, { useEffect, useState } from 'react';
import { useUpdateDashboard, useUpdateWidgetName } from '@pages/dashboard/services/mutations';
import { useGetDashboards } from '@pages/dashboard/services/queries';
import { DashboardModel } from '@pages/dashboard/utils/models/DashboardModel';
import { Button, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './widgetUpdate.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  id: number;
  currentDashboard: DashboardModel | undefined;
};

const DashboardUpdate: React.FC<Props> = ({ setVisible, visible, id, currentDashboard }) => {
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState<string>();

  useEffect(() => {
    setNameValue(currentDashboard?.name);
  }, [currentDashboard]);

  const updateWidgetName = useUpdateWidgetName(id);
  const updateDashboard = useUpdateDashboard();
  const { data: dashboard } = useGetDashboards();

  const handleClose = () => {
    setVisible(false);
  };

  const handleUpdateDashboard = () => {
    const newDash = dashboard?.filter(item => item?.id !== id)?.map(prev => ({ id: prev?.id, order: prev?.order, name: prev?.name }));
    const finalData = newDash?.concat({
      id,
      order: currentDashboard?.order,
      name: String(nameValue)
    });

    updateDashboard.mutateAsync(finalData as any).then(handleClose);
  };

  return (
    <Modal visible={visible} onCancel={handleClose} title={t('emp_dashboard.Nomini_tahrirlash')} footer={false} className={styles.widgetUpdate}>
      <Input value={nameValue} onChange={e => setNameValue(e.target.value)} />
      <div className={styles.widgetUpdate_footer}>
        <Button onClick={handleClose}>{t('home.Yopish')}</Button>
        <Button loading={updateWidgetName.isLoading} onClick={handleUpdateDashboard}>
          {t('home.Saqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default DashboardUpdate;
