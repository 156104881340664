import React from 'react';
import { changeCountryCode } from '@reducers/ClientSlice';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { setClientType } from '../../../../store/reducers/ChangesSlice';
import { ClientType } from '../../../../utils/models/ClientType';

import styles from './client.module.scss';

const SelectType = () => {
  const { appendMultipleDifferent } = useQueryParam();
  const clientType = useAppSelector(state => state.changesReducer.clientType);

  const dispatch = useAppDispatch();
  const changesType = (type: ClientType) => {
    dispatch(changeCountryCode('UZ'));
    if (type !== clientType) {
      dispatch(setClientType(type));
      appendMultipleDifferent(
        ['page', 'contractId', 'clientId'],
        // @ts-ignore
        [ChangesPageSteps.client, undefined, undefined]
      );
    }
  };

  const getActiveClass = (type: ClientType) => (clientType === type ? styles.select_type_active : styles.select_type_default);
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t(`changes.client.SelectType.Mijoz_turi`)}</h2>
      <div className={styles.select_type}>
        <span onClick={() => changesType(ClientType.NATURAL)} className={getActiveClass(ClientType.NATURAL)}>
          {t(`createOrder.Jismoniy_shaxs`)}
        </span>
        <span onClick={() => changesType(ClientType.JURIDICAL)} className={getActiveClass(ClientType.JURIDICAL)}>
          {t(`createOrder.Yuridik_shaxs`)}
        </span>
      </div>
    </div>
  );
};

export default SelectType;
