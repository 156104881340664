import React, { useEffect } from 'react';
import styles from './contractTableUpdate.module.scss';
import { useUpdateMonthlyPaymentData } from '../../queries/queries';
import { useQueryParam } from '../../hooks/useQueryParams';
import MainInputs from '../../components/contractTableUpdate/MainInputs';
import TableMonthlyPayments from '../../components/contractTableUpdate/TableMonthlyPayments';
import { Layout, Spin } from 'antd';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../utils/constants/format';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { clear } from '../../store/reducers/MonthlyPaymentsSlice';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';

const { Content } = Layout;

const ContractTableUpdate = () => {
  const { params } = useQueryParam();
  const { isLoading, data } = useUpdateMonthlyPaymentData(params.id);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <Spin spinning={isLoading}>
          <div className={styles.contractTableUpdate}>
            <MainInputs date={data?.date || dayjs(new Date()).add(1, 'month').format(DATE_FORMAT)} />
            <TableMonthlyPayments />
          </div>
        </Spin>
      </Content>
    </>
  );
};

export default ContractTableUpdate;
