import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Switch } from 'antd';

import styles from './myHome.module.scss';
import { usePostMyHomeConfig } from '@queries/mutation';
import { useGetMyHomeConfig } from '@queries/queries';
import { MyHomeConfigModel } from '@utils/models/MyHomeConfigModel';
import { useTranslation } from 'react-i18next';

type Props = {
  openHome: boolean;
  setOpenHome: React.Dispatch<React.SetStateAction<boolean>>;
};

const MyHomeModal: React.FC<Props> = ({ openHome, setOpenHome }) => {
  const { t } = useTranslation();
  const { data } = useGetMyHomeConfig();
  const [form] = Form.useForm();
  const myHomeConfig = usePostMyHomeConfig();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const handleClose = () => {
    setOpenHome(false);
    form.setFieldsValue(data);
  };

  const onFinish = (values: MyHomeConfigModel) => {
    myHomeConfig.mutateAsync(values).then(handleClose);
  };

  return (
    <Modal visible={openHome} onCancel={handleClose} title={t('integration.Mening_uyim')} footer={false} className={styles.myHome} width={466}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className={styles.myHome_item}>
          <span>{t('integration.Mijoz_uyi_haqda')}</span>
          <Form.Item name="accessMyHome" valuePropName={'checked'}>
            <Switch />
          </Form.Item>
        </div>
        <div className={styles.myHome_item}>
          <span>{t('integration.Mijoz_shartnoma_tuzganda')}</span>
          <Form.Item name="sendSmsCreatContract" valuePropName={'checked'}>
            <Switch />
          </Form.Item>
        </div>
        <div className={styles.myHome_btn}>
          <Button htmlType="submit" loading={myHomeConfig.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MyHomeModal;
