import React, { FC } from 'react';
import styles from './oneLidRestore.module.scss';
import DangerBackgroundWhiteIcon from '../../../../assets/icons/DangerBackgroundWhiteIcon';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLeadRecoveringDeleted } from '../../services/mutation';

interface IProps {
  id: number;
}

const OneLidRestore: FC<IProps> = ({ id }) => {
  const { t } = useTranslation();
  const deleted = useLeadRecoveringDeleted();
  return (
    <div className={styles.container}>
      <DangerBackgroundWhiteIcon /> {t('one_lid.Lid_ochirilgan')}{' '}
      <Button onClick={() => deleted.mutate(id)} loading={deleted.isLoading}>
        {t('one_lid.Qayta_tiklash')}
      </Button>
    </div>
  );
};

export default OneLidRestore;
