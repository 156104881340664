import React, { useState } from 'react';
import TrushSquareGreenIcon from '@icons/TrushSquareGreenIcon';
import TaskCompleteModal, { checkMondayDate } from './TaskCompleteModal';
import { TaskFilter } from '@utils/models/TaskFilter';
import { hoverProps } from '../TaskContainer';

import styles from './dndTaskContainer.module.scss';
import { useAppSelector } from '@hooks/reduxHooks';

type Props = {
  dragging: boolean;
  draggingId: number | undefined;
  currentData: TaskFilter | undefined;
  hower: hoverProps;
  setHower: React.Dispatch<React.SetStateAction<hoverProps>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};
export type Interface = {
  id: number;
  text: string;
  icon: JSX.Element | null;
  color: string;
  check: boolean | undefined;
  name: string;
};

const DndDeleteCompleteTask: React.FC<Props> = ({ dragging, draggingId, currentData, setHower, hower, openModal, setOpenModal }) => {
  const { nextMonth } = useAppSelector(state => state.crmStatistic);
  const [modalData, setModalData] = useState<Interface>();
  const taskFooter: Interface[] = [
    {
      id: 1,
      text: '',
      icon: <TrushSquareGreenIcon />,
      color: 'red',
      check: hower?.delete,
      name: 'delete'
    },
    {
      id: 2,
      text: 'Kelasi dushanba',
      icon: null,
      color: 'blue',
      check: hower?.lastMonday,
      name: 'lastMonday'
    },
    {
      id: 3,
      text: 'Kelasi oy',
      icon: null,
      color: 'gray',
      check: hower?.lastMonth,
      name: 'lastMonth'
    },
    {
      id: 4,
      text: 'Yakunlash',
      icon: null,
      color: 'green',
      check: hower?.done,
      name: 'done'
    }
  ];

  return (
    <>
      <div
        className={styles.wrapper}
        style={{
          opacity: dragging ? 1 : 0,
          display: dragging ? 'flex' : 'none'
        }}
      >
        {taskFooter?.map(item => {
          return (
            <div
              className={styles.wrapper_card}
              style={{
                borderTop: `4px solid ${item?.color}`,
                backgroundColor: item?.check ? 'lightblue' : '#fff'
              }}
              onMouseEnter={e => {
                setHower({ [item?.name]: true });
              }}
              onMouseLeave={e => {
                setHower({ [item?.name]: false });
              }}
              onMouseUp={() => {
                setModalData(item);
                if (
                  (item?.name === 'lastMonday' && currentData?.deadlineStart === checkMondayDate) ||
                  (item?.name === 'lastMonth' && currentData?.deadlineStart === `${nextMonth} 00:00`)
                ) {
                  setOpenModal(false);
                } else {
                  setOpenModal(true);
                }
              }}
            >
              {item?.icon !== null ? item?.icon : item?.text}
            </div>
          );
        })}
      </div>
      <TaskCompleteModal openModal={openModal} setOpenModal={setOpenModal} draggingId={draggingId} currentData={currentData} modalData={modalData!} />
    </>
  );
};

export default DndDeleteCompleteTask;
