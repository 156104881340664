import { $api } from "@service/RequestService";
import message from '@utils/helper/message';
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../end-points";
import { keys } from "../keys";

export const useDeleteUzumConfig = () => {
  const client = useQueryClient();

  return useMutation(
    async () => {
      await $api.delete(`${endPoints.UZUM_CONFIG}`);
    },
    {
      onSuccess: async () => {
        client.invalidateQueries({ queryKey: [keys.UZUM_CONFIG] })
        message.success("Muvaffaqqiyatli")
      }
    }

  );
}