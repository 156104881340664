import React, { useEffect, useState } from 'react';
import styles from './botConfig.module.scss';
import AddCircleIcon from '../../../assets/icons/AddCircleIcon';
import { Form, Input, Spin } from 'antd';
import { Faq, LangBot } from '../../../utils/models/TelegramBot';
import TickSquareIcon from '../../../assets/icons/TickSquareIcon';
import { useCreateFaq } from '../../../queries/mutation';
import { useTranslation } from 'react-i18next';

interface Props {
  lang: LangBot;
}

const AddFaqItem: React.FC<Props> = ({ lang }) => {
  const [form] = Form.useForm<Faq>();
  const [showAddButton, setShowAddButton] = useState(true);

  const createFaq = useCreateFaq(onSuccess, lang);

  const { t } = useTranslation();

  useEffect(() => {
    setShowAddButton(true);
    form.resetFields();
  }, [lang, form]);

  function onSuccess() {
    setShowAddButton(true);
    form.resetFields();
  }

  const handleShowAddButton = () => {
    setShowAddButton(!showAddButton);
  };

  const handelSubmit = async () => {
    await form.validateFields();
    createFaq.mutate({
      ...form.getFieldsValue(),
      lang
    });
  };

  return (
    <Spin spinning={createFaq.isLoading}>
      {showAddButton && (
        <div onClick={handleShowAddButton} className={styles.faqModal_add}>
          <AddCircleIcon />
        </div>
      )}
      {!showAddButton && (
        <Form form={form} name={'faqItemCreate'}>
          <div className={styles.faqModal_item}>
            <div className={styles.faqModal_item_input}>
              <p className={styles.faqModal_item_input_label}>{t('integration.Savol')}</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
                name={'question'}
              >
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.faqModal_item_input}>
              <p className={styles.faqModal_item_input_label}>{t('integration.Javob')}</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: ''
                  }
                ]}
                name={'answer'}
              >
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} className={'customInput'} />
              </Form.Item>
            </div>
            <div onClick={handelSubmit} className={styles.faqModal_item_button}>
              <TickSquareIcon />
            </div>
          </div>
        </Form>
      )}
    </Spin>
  );
};

export default AddFaqItem;
