import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { useMarketplaceCreate } from '../../queries/mutation';
import { useMarketplace, useMarketplaceHouses } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';

import logo from '../../assets/img/big_logo.png';

import styles from './rootIntegration.module.scss';

const RootIntegration = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const marketplaceMutate = useMarketplaceCreate();
  const { data: marketplaceHousesData } = useMarketplaceHouses();
  const { data: marketplaceData } = useMarketplace();
  const { query } = queryString.parseUrl(location.search);

  useEffect(() => {
    if (marketplaceData?.houses) {
      form.setFieldsValue({
        houses: marketplaceData?.houses.map(item => item.id)
      });
    }
  }, [marketplaceData?.houses, form]);

  const onFinish = (values: any) => {
    const housesArr: any[] = marketplaceHousesData!?.filter(item => values.houses.includes(item.id));

    marketplaceMutate
      .mutateAsync({
        extra: String(query?.extra_id),
        houses: housesArr,
        redirectUrl: String(query?.redirect_url)
      })
      .then(res => {
        const copyRes = {
          ...res?.data,
          houses: res?.data?.houses?.map(item => item?.id)
        };

        delete copyRes?.redirectUrl;

        window.location.replace(`${res?.data?.redirectUrl}?${queryString.stringify(copyRes)}`);
      });
  };

  const backHandle = () => {
    navigate({ pathname: rootPaths.MAIN + MainPaths.INDEX });
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_integration}>
        <div className={styles.container_content}>
          <img src={logo} alt="" />
          <h3>
            <a href="http://uysotmarket.uz">Uysotmarket.uz</a> savdo maydonchasiga chop etiladigan turar-joy majmualarni tanlang:
          </h3>
          <Form onFinish={onFinish} form={form}>
            <Form.Item name="houses">
              <Checkbox.Group className="h-[400px] overflow-y-auto">
                {marketplaceHousesData?.map(item => (
                  <Checkbox key={item.id} value={item.id}>
                    {item.name}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <div className={styles.container_content_footer}>
              <Button onClick={backHandle}>{t('contract.Orqaga')}</Button>
              <Button type="primary" htmlType="submit" loading={marketplaceMutate.isLoading}>
                {t('one_lid.Tasdiqlash')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RootIntegration;
