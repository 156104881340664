interface IProps {
  size?: number;
}

const AIStarIcon = ({ size = 20 }: IProps) => (
  <svg width={size} height={size} viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.406 1.22026C9.1395 0.271633 7.73501 0.271633 7.46851 1.22026L7.0913 2.56392C6.2776 5.45897 5.1657 7.72039 2.14459 8.49989L0.742541 8.86172C-0.247514 9.11709 -0.247514 10.463 0.742541 10.7184L2.14459 11.0802C5.1657 11.8597 6.2776 14.1211 7.0913 17.0162L7.46851 18.3598C7.73501 19.3084 9.1395 19.3084 9.406 18.3598L9.7837 17.0162C10.5969 14.1211 11.7093 11.8597 14.7304 11.0802L16.1325 10.7184C17.1225 10.463 17.1225 9.11709 16.1325 8.86172C15.1424 8.60634 14.7304 8.49989 14.7304 8.49989C11.7093 7.72039 10.5969 5.45897 9.7837 2.56392L9.406 1.22026Z"
      fill="url(#paint0_linear_5881_1718)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5186 0.961542C22.3587 0.357871 21.516 0.357871 21.3561 0.961542L21.1298 1.8166C20.6416 3.6589 19.9744 5.09799 18.1618 5.59404L17.3205 5.82429C16.7265 5.9868 16.7265 6.84328 17.3205 7.00579L18.1618 7.23604C19.9744 7.73209 20.6416 9.17118 21.1298 11.0135L21.3561 11.8685C21.516 12.4722 22.3587 12.4722 22.5186 11.8685L22.7452 11.0135C23.2331 9.17118 23.9006 7.73209 25.7132 7.23604L26.5545 7.00579C27.1485 6.84328 27.1485 5.9868 26.5545 5.82429C25.9604 5.66178 25.7132 5.59404 25.7132 5.59404C23.9006 5.09799 23.2331 3.6589 22.7452 1.8166L22.5186 0.961542Z"
      fill="url(#paint1_linear_5881_1718)"
    />
    <defs>
      <linearGradient id="paint0_linear_5881_1718" x1="8.27995" y1="18.8393" x2="17.3532" y2="1.2427" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF02F0" />
        <stop offset="1" stopColor="#FFC800" />
      </linearGradient>
      <linearGradient id="paint1_linear_5881_1718" x1="21.69" y1="12.3686" x2="27.5239" y2="1.25639" gradientUnits="userSpaceOnUse">
        <stop stopColor="#8930FD" />
        <stop offset="1" stopColor="#49CCF9" />
      </linearGradient>
    </defs>
  </svg>
);

export default AIStarIcon;
