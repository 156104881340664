import React from 'react';

const TagTwoIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0701 21.9793C10.6601 21.9793 9.24009 21.4393 8.17009 20.3693L3.64009 15.8393C2.54009 14.7393 1.96009 13.2193 2.03009 11.6693L2.27009 6.65935C2.38009 4.26935 4.27009 2.37935 6.67009 2.25935L11.6701 2.01935C13.2301 1.94935 14.7401 2.52935 15.8401 3.62935L20.3701 8.15935C22.5201 10.3093 22.5201 13.8193 20.3701 15.9693L15.9801 20.3593C14.9001 21.4393 13.4901 21.9793 12.0701 21.9793ZM4.70009 14.7693L9.23009 19.2993C10.8001 20.8693 13.3501 20.8693 14.9201 19.2993L19.3101 14.9093C20.8801 13.3393 20.8801 10.7893 19.3101 9.21935L14.7701 4.69935C13.9701 3.89935 12.8801 3.47935 11.7301 3.52935L6.73009 3.76935C5.11009 3.83935 3.84009 5.10935 3.76009 6.72935L3.52009 11.7393C3.47009 12.8593 3.90009 13.9693 4.70009 14.7693Z"
      fill="white"
    />
    <path
      d="M9.5 12.75C7.71 12.75 6.25 11.29 6.25 9.5C6.25 7.71 7.71 6.25 9.5 6.25C11.29 6.25 12.75 7.71 12.75 9.5C12.75 11.29 11.29 12.75 9.5 12.75ZM9.5 7.75C8.54 7.75 7.75 8.54 7.75 9.5C7.75 10.46 8.54 11.25 9.5 11.25C10.46 11.25 11.25 10.46 11.25 9.5C11.25 8.54 10.46 7.75 9.5 7.75Z"
      fill="white"
    />
    <path
      d="M13.0004 17.7495C12.8104 17.7495 12.6204 17.6795 12.4704 17.5295C12.1804 17.2395 12.1804 16.7595 12.4704 16.4695L16.4704 12.4695C16.7604 12.1795 17.2404 12.1795 17.5304 12.4695C17.8204 12.7595 17.8204 13.2395 17.5304 13.5295L13.5304 17.5295C13.3804 17.6795 13.1904 17.7495 13.0004 17.7495Z"
      fill="white"
    />
  </svg>
);

export default TagTwoIcon;
