import React, { FC } from 'react';
import { Avatar, Input, Select } from 'antd';
import styles from './sipuUsers.module.scss';
import { BASE_URL } from '../../../../../../service/RequestService';
import avatarDefaultClient from '../../../../../../assets/img/avatarDefaultClient.jpg';
import { CrmEmployee } from '../../../../../../utils/models/OneLid';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import CloseIcon from '../../../../../homeId/components/detailed/assets/icons/CloseIcon';

interface IProps {
  setSipNumber: React.Dispatch<React.SetStateAction<string>>;
  setEmployeeId: React.Dispatch<React.SetStateAction<number | null>>;
  setCreate: (value: React.SetStateAction<boolean>) => void;
  employeeList: CrmEmployee[] | undefined;
  sipNumber: string;
  employeeId: number | null;
}

const SipuUserCreate: FC<IProps> = ({ employeeList, employeeId, sipNumber, setEmployeeId, setSipNumber, setCreate }) => {
  return (
    <div className={styles.user}>
      <div>
        <Input
          className="customInput"
          size="large"
          onChange={e => setSipNumber(e.target.value)}
          value={sipNumber}
          placeholder="SIPUNI foydalanuvchi raqami"
        />
      </div>
      <div>
        <Select
          className="customSelect"
          size="large"
          onChange={value => {
            setEmployeeId(value);
          }}
          value={employeeId}
          suffixIcon={<ArrowDownIcon />}
          placeholder="Xodim tanlang"
        >
          {employeeList?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              <Avatar
                src={item?.avatar ? BASE_URL + '/' + item?.avatar : avatarDefaultClient}
                size={24}
                icon={<img src={avatarDefaultClient} alt="" />}
              />{' '}
              {item?.fullName}
            </Select.Option>
          ))}
        </Select>
      </div>
      <span className={styles.user_delete} onClick={() => setCreate(false)}>
        <CloseIcon />
      </span>
    </div>
  );
};

export default SipuUserCreate;
