import React, { useState } from 'react';
import { Button, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ArrowCircleRightGreenIcon from '../../assets/icons/ArrowCircleRightGreenIcon';
import BuildingIcon from '../../assets/icons/BuildingIcon';
import { useBossComplexSaleFlats } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { START_DATE_DEFAULT } from '../../utils/constants/startDateDefault';
import { BoosComplexSelectType } from '../../utils/models/BoosComplexSelectType';
import { FlatsInfoOneHouse } from '../../utils/models/BossComplex';

import SaleOneHouseModal from './SaleOneHouseModal';
import SpecialTimeModal from './SpecialTimeModal';

import styles from './saleFlats.module.scss';

const { Option } = Select;

const SaleFlats = () => {
  const [showAll, setShowAll] = useState(false);
  const [selectType, setSelectType] = useState<BoosComplexSelectType | (string & {})>(BoosComplexSelectType.NOW);
  const [oneHouseFlatsModal, setOneHouseFlatsModal] = useState<{
    visible: boolean;
    house: FlatsInfoOneHouse | undefined;
  }>({
    visible: false,
    house: undefined
  });
  const [time, setTime] = useState({
    startDate: '',
    finishDate: ''
  });
  const [specialModal, setSpecialModal] = useState(false);

  const { isLoading, data, isFetching } = useBossComplexSaleFlats(time.startDate, time.finishDate);

  const { t } = useTranslation();

  // handel show all
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  // house name
  const houseName = (name: string) => {
    if (name.length > 8) {
      return `${name.slice(0, 8)}..`;
    }
    return name;
  };

  // handel show one house modal
  const handleShowOneHouseModal = (house: FlatsInfoOneHouse | undefined, visible: boolean) => {
    setOneHouseFlatsModal({
      visible,
      house
    });
  };

  // set now
  const setNow = () => {
    setTime({
      startDate: START_DATE_DEFAULT,
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set today
  const setToday = () => {
    setTime({
      startDate: dayjs(new Date()).format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set yesterday
  const setYesterday = () => {
    setTime({
      startDate: dayjs(new Date()).add(-1, 'day').format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // set this week
  const setThisWeek = () => {
    if (dayjs(new Date()).startOf('week').format(DATE_FORMAT) === dayjs(new Date()).format(DATE_FORMAT)) {
      setTime({
        startDate: dayjs(new Date()).add(-6, 'day').format(DATE_FORMAT),
        finishDate: dayjs(new Date()).format(DATE_FORMAT)
      });
    } else {
      setTime({
        startDate: dayjs(new Date()).startOf('week').add(1, 'day').format(DATE_FORMAT),
        finishDate: dayjs(new Date()).format(DATE_FORMAT)
      });
    }
  };

  // set this month
  const setThisMonth = () => {
    setTime({
      startDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT),
      finishDate: dayjs(new Date()).format(DATE_FORMAT)
    });
  };

  // handle special modal
  const handleSpecialModal = (visible: boolean) => {
    setSpecialModal(visible);
  };

  // set special
  const setSpecial = () => {
    handleSpecialModal(true);
  };

  // handle select
  const handleSelect = (value: string) => {
    switch (value) {
      case BoosComplexSelectType.TODAY:
        setToday();
        setSelectType(value);
        break;
      case BoosComplexSelectType.YESTERDAY:
        setYesterday();
        setSelectType(value);
        break;
      case BoosComplexSelectType.THIS_WEEK:
        setThisWeek();
        setSelectType(value);
        break;
      case BoosComplexSelectType.THIS_MONTH:
        setThisMonth();
        setSelectType(value);
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        break;
      default:
        setNow();
        setSelectType(value);
        break;
    }
  };

  // handle select date
  const handleSelectDate = (date: string[]) => {
    setSelectType(`${date[0]} - ${date[1]}`);

    setTime({
      startDate: date[0],
      finishDate: date[1]
    });
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h3>{t('boss_complex.Xonadonlar_sotuvi')}</h3>
          <Select
            onChange={handleSelect}
            className={`${styles.container_header_select} customSelect`}
            value={selectType}
            style={{ width: Object.keys(BoosComplexSelectType).includes(selectType) ? '' : 220 }}
          >
            <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
            <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
            <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
            <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
            <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
            <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option>
          </Select>
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_title}>
            <h2>{data?.total}</h2>
            <p>{t('boss_complex.Sotilgan_uylar_soni')}</p>
          </div>
          {data?.houses.map((item, index) => {
            if (!showAll && index >= 5) {
              return '';
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div onClick={() => handleShowOneHouseModal(item, true)} key={index} className={styles.container_body_item}>
                <div className={styles.container_body_item_card}>
                  <BuildingIcon />
                  <p title={item.name} className={styles.container_body_item_card_name}>
                    {houseName(item.name)}
                  </p>
                  <p className={styles.container_body_item_card_title}>{t('boss_complex.Sotilgan')}</p>
                  <p className={styles.container_body_item_card_count}>{item?.totalSold}</p>
                </div>
                <ArrowCircleRightGreenIcon className={styles.container_body_item_arrow} />
              </div>
            );
          })}
          {!showAll && (
            <Button onClick={handleShowAll} className={styles.container_body_button}>
              {t('boss_complex.Barchasini_korish')}
            </Button>
          )}
        </div>
      </div>
      <SaleOneHouseModal
        house={oneHouseFlatsModal.house}
        visible={oneHouseFlatsModal.visible}
        close={handleShowOneHouseModal}
        startDate={time.startDate}
        finishDate={time.finishDate}
      />
      <SpecialTimeModal visible={specialModal} close={handleSpecialModal} handleSelect={handleSelectDate} />
    </Spin>
  );
};

export default SaleFlats;
