import React, { useEffect, useState } from 'react';
import styles from './showroomShaxmatkaPlus.module.scss';
import ShowroomV2Layout from '@pages/showroom-v2/components/showroom-v2-layout/ShowroomV2Layout';
import { useSmartCatalogShowroom, useSmartCatalogShowroomFilter } from '@pages/showroom-v2/services/queries';
import ShowroomShaxmatkaPlusFlat from './showroom-shaxmatka-plus-flat/ShowroomShaxmatkaPlusFlat';
import { Spin } from 'antd';
import CustomeDraggable from '@components/custome-draggable/CustomeDraggable';
import { useAppSelector } from '@hooks/reduxHooks';
import { useTranslation } from 'react-i18next';

const ShowroomShaxmatkaPlus = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useSmartCatalogShowroom();
  const { data: filterData, isLoading: filterDataIsLoading } = useSmartCatalogShowroomFilter();

  const [offsetWidth, setOffsetWidth] = useState(0);
  const { drawer } = useAppSelector(state => state.showroomSlice);

  useEffect(() => {
    const windowResize = () => {
      const activeFlat = document.getElementById('activeFlat');
      const focusedFloorActive = document.getElementById('focusedFloorActive');
      if (activeFlat && focusedFloorActive && focusedFloorActive?.parentElement) {
        focusedFloorActive.setAttribute(
          'style',
          `top:${
            activeFlat.getBoundingClientRect().y +
            focusedFloorActive.parentElement?.scrollTop! -
            focusedFloorActive?.parentElement?.getBoundingClientRect().y
          }px;`
        );
      }
    };

    windowResize();
  }, [drawer.id]);

  useEffect(() => {
    const shaxmatka_plus = document.getElementById('shaxmatka_plus');

    if (shaxmatka_plus) {
      shaxmatka_plus?.scrollTo({
        top: shaxmatka_plus?.scrollHeight - shaxmatka_plus?.offsetHeight
      });
    }
  }, [data]);

  const floorTop = data && data?.floors?.length > 0 ? data?.floors[0] : undefined;

  return (
    <ShowroomV2Layout>
      <CustomeDraggable>
        <div className={styles.container} id="shaxmatka_plus">
          <div className={styles.top}>
            {floorTop?.buildings?.map(building => {
              const flatLength = building?.entrances?.reduce((prev, current) => (prev += current?.flats?.length), 0);

              const buildingWidth = flatLength * 208 + (flatLength - 1) * 8 + (building?.entrances?.length - 1) * 16;

              return (
                <div key={building?.name} style={{ minWidth: buildingWidth + 44 }} className={styles.top_name}>
                  <h3> {building?.name}</h3>
                  <div className={styles.top_name_entrances}>
                    {building?.entrances?.map(entrance => {
                      const entranceWidth = entrance?.flats?.length * 208 + (entrance?.flats?.length - 1) * 8 + 16;
                      return (
                        <p style={{ width: entranceWidth }}>
                          <span>
                            {' '}
                            {entrance?.entranceName || entrance?.entrance} - {t('home.podez')}
                          </span>
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {data?.floors?.map(floor => (
            <div className={`${styles.floor} ${drawer?.floor === floor?.floor ? styles.floor_active : ''}`} key={floor?.floor}>
              <div className={styles.floor_index}>{floor?.floor}</div>
              {floor?.buildings?.map(building => (
                <div key={building?.name} className={styles.floor_building}>
                  {building?.entrances?.map(entrance => (
                    <div className={styles.floor_entrance}>
                      {entrance?.flats?.map(flat => <ShowroomShaxmatkaPlusFlat {...flat} key={flat?.id} filterData={filterData} />)}
                    </div>
                  ))}
                </div>
              ))}
              <div className={styles.floor_index} style={{ right: 0, marginLeft: 'auto' }}>
                {floor?.floor}
              </div>
            </div>
          ))}
          <Spin spinning={isLoading || filterDataIsLoading} className={styles.spin} size="large"></Spin>
        </div>
      </CustomeDraggable>
      <style>
        {`
        :root{
          --focusedFloorWidth: ${offsetWidth}px;
        }
        `}
      </style>
    </ShowroomV2Layout>
  );
};

export default ShowroomShaxmatkaPlus;
