// У тебя есть огромная задача.
// Впервую очередь, ты должен сделать всё содержимое которое я отметил (СТАРТ, ФИНИШ) с нужными зависимостями в отдельный JSON файл который написан очень аккуратно и структурированно.
// Это нужно для того чтобы я отрпавлял к бекенду дату в формате JSON.

import React, { FC, useState } from 'react';
import DragAndDropIcon from '@pages/crm/assets/icons/DragAndDropIcon';
import { setCheckedContact, setCheckedLead, updateContactOrder, updateLedOrder } from '@reducers/CrmFieldSlice';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';
import { PipeStatuses } from '@utils/models/PipeAll';
import { TableKey } from '@utils/models/TableKey';
import { Button, Switch } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import PlusIconAddCircle from '../../../../../assets/icons/PlusIconAddCircle';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { useFieldsSave, useTableConfigMutation } from '../../../../../queries/mutation';
import { CrmField } from '../../../../../utils/models/CrmField';
import { CrmFieldsInfoType, CrmFieldsType } from '../../../../../utils/models/CrmFieldsType';
import EditInputModal from '../../../../oneLid/components/information/editInputModal/EditInputModal';

import styles from './createLead.module.scss';

interface IProps {
  setSetting: React.Dispatch<React.SetStateAction<boolean>>;
  tableKey: TableKey;
  selectedPipeStatusId: number;
  pipeStatus: PipeStatuses[];
}

const CreateLeadControll: FC<IProps> = ({ setSetting, tableKey, selectedPipeStatusId, pipeStatus }) => {
  const { t } = useTranslation();
  const { led, contact } = useAppSelector(state => state.crmField);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<{
    visible: boolean;
    data?: CrmField;
    index?: number;
  }>({
    visible: false
  });
  const [type, setType] = useState<CrmFieldsInfoType>(CrmFieldsInfoType.LED);

  const saveFields = useFieldsSave();
  const dispatch = useAppDispatch();
  const saveTableConfigMutation = useTableConfigMutation(tableKey);

  const getTypeField = (item: CrmField) => {
    if (item.type === CrmFieldsType.TEXT) {
      return '...';
    }
    return item.options?.length + t('one_lid.ta_tanlov');
  };

  // handleOpenSetting
  const handleOpenSetting = () => {
    setSetting(false);
  };

  // open edit modal handler
  const editModalHandler = (visible: boolean, data?: CrmField, index?: number, type?: CrmFieldsInfoType) => {
    setOpenEditModal({ visible, data, index });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type && setType(type);
  };

  // handle save
  const handleSave = () => {
    const TableConfigValue = {
      config: [...led, ...contact],
      size: 10
    };

    const newLead = led?.map((item: CrmField) => {
      const { checked, order, ...rest } = item;

      return rest;
    });

    const newContact = contact?.map((item: CrmField) => {
      const { checked, order, ...rest } = item;

      return rest;
    });

    if (isDataChanged) {
      saveFields.mutateAsync([...newLead, ...newContact]);
    }

    saveTableConfigMutation.mutateAsync({
      key: `${tableKey}/${selectedPipeStatusId}`,
      value: JSON.stringify(TableConfigValue)
    });
    setIsDataChanged(false);
  };

  // for switch [lead.map]
  const onChangeLead = (data: { checked: boolean; id: number }) => {
    dispatch(setCheckedLead({ checked: data.checked, id: data.id }));
  };

  // for switch [contact.map]
  const onChangeContact = (data: { checked: boolean; name: string }) => {
    dispatch(setCheckedContact({ checked: data.checked, name: data.name }));
  };

  const handleOnDragEndLead = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(led);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    // updateCharacters(items);
    dispatch(updateLedOrder(items as CrmField[])); // Отправляем новый порядок в редюсер
  };

  const handleOnDragEndContact = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(contact);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(updateContactOrder(items)); // Отправляем новый порядок в редюсер
  };

  const foundPipeOrder = pipeStatus?.find(el => el.id === selectedPipeStatusId)?.orders;

  return (
    // СТАРТ
    <div className={styles.container}>
      <h1>{t('client_create_contract.Bitim_malumotlari')}</h1>
      {/* Lead */}
      <DragDropContext onDragEnd={handleOnDragEndLead}>
        <Droppable droppableId="droppableLead">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} className={styles.container}>
              {led?.map((item, index) => (
                <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.container_item}>
                      <span style={{ marginRight: '10px' }}>
                        <DragAndDropIcon />
                      </span>
                      <div
                        onClick={() => editModalHandler(true, item as CrmField, index, CrmFieldsInfoType.LED)}
                        className={styles.container_item_title}
                      >
                        <div className={styles.container_item_title_name}>
                          {item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}
                        </div>
                        <div className={styles.container_item_title_value}>{getTypeField(item as CrmField)}</div>
                      </div>
                      <span style={{ marginLeft: '6px' }}>
                        <Switch
                          disabled={
                            item?.fieldStatuses?.length! > 0 &&
                            (item?.fieldStatuses![0]?.statusId === selectedPipeStatusId ||
                              pipeStatus?.find(el => el.id === item?.fieldStatuses![0]?.statusId)?.orders! <= foundPipeOrder!)
                          }
                          checked={item.checked}
                          size="small"
                          onChange={e => onChangeLead({ checked: e, id: item?.id! })}
                        />
                      </span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div
        onClick={() => {
          setType(CrmFieldsInfoType.LED);
          editModalHandler(true);
        }}
        className={styles.container_add}
      >
        <PlusIconAddCircle />
        <span>{t('one_lid.Yangi_qoshish')}</span>
      </div>

      <h1>{t('client_create_contract.Mijoz_malumotlari')}</h1>
      {/* Contact */}
      <DragDropContext onDragEnd={handleOnDragEndContact}>
        <Droppable droppableId="droppableContact">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} className={styles.container}>
              {contact.map((item, index) => (
                <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.container_item}>
                      <span style={{ marginRight: '10px' }}>
                        <DragAndDropIcon />
                      </span>
                      <div onClick={() => editModalHandler(true, item, index, CrmFieldsInfoType.CONTACT)} className={styles.container_item_title}>
                        <div className={styles.container_item_title_name}>
                          {item[`name${textCapitalize(i18n.language)}` as keyof typeof item] || item?.name}
                        </div>
                        <div className={styles.container_item_title_value}>{getTypeField(item)}</div>
                      </div>
                      <span style={{ marginLeft: '6px' }}>
                        <Switch
                          disabled={
                            item?.fieldStatuses?.length! > 0 &&
                            (item?.fieldStatuses![0]?.statusId === selectedPipeStatusId ||
                              pipeStatus?.find(el => el.id === item?.fieldStatuses![0]?.statusId)?.orders! <= foundPipeOrder!)
                          }
                          defaultChecked
                          size="small"
                          onChange={e => onChangeContact({ checked: e, name: item.name })}
                        />
                      </span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div
        onClick={() => {
          setType(CrmFieldsInfoType.CONTACT);
          editModalHandler(true);
        }}
        className={styles.container_add}
      >
        <PlusIconAddCircle />
        <span>{t('one_lid.Yangi_qoshish')}</span>
      </div>
      {/* ФИНИШ */}

      <div className={styles.createLead_footer}>
        <div className={styles.createLead_footer_left}>
          <Button type="primary" onClick={handleSave} loading={saveFields.isLoading || saveTableConfigMutation.isLoading}>
            {t('one_task.Saqlash')}
          </Button>
          <Button onClick={handleOpenSetting} className={styles.createLead_footer_left_closeButton}>
            {t('crm.Bekor_qilish')}
          </Button>
        </div>
      </div>
      <EditInputModal
        index={openEditModal.index}
        data={openEditModal.data}
        visible={openEditModal.visible}
        handleClose={editModalHandler}
        type={type}
        setIsDataChanged={setIsDataChanged}
      />
    </div>
  );
};

export default CreateLeadControll;
// @thtflx
