import SecurityControlPermissions from '@pages/security/security-control-permissions/SecurityControlPermissions';
import SecuritySetting from '../../pages/security/security-setting/SecuritySetting';
import { SecuritySettingsPath } from './SecuritySettingsPath';

export const securitySettingsRoutes = [
  {
    path: SecuritySettingsPath.SECURITY_SETTINGS,
    component: SecuritySetting,
    exact: true
  },
  {
    path: SecuritySettingsPath.CONTROL_PERMISSIONS,
    component: SecurityControlPermissions,
    exact: false
  }
];
