import React from 'react';

const ArrowDown: React.FC = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.72003 6.4668L7.0667 10.8135C7.58003 11.3268 8.42003 11.3268 8.93336 10.8135L13.28 6.4668"
        stroke="#464255"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowDown;
