import React from 'react';

interface IProps {
  pathProps?: React.SVGProps<SVGPathElement>;
}

const xIcon = ({ pathProps }: IProps) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33464 1.66406L1.66797 8.33073M1.66797 1.66406L8.33464 8.33073" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" {...pathProps} />
  </svg>
);

export default xIcon;
