import React, { useState } from 'react';

import styles from '../taskChatContainer.module.scss';
import { OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';
import Event from './Event';
import { useTranslation } from 'react-i18next';

interface Props {
  events: OneEvent[];
}

const EventContainer: React.FC<Props> = ({ events }) => {
  const [showAll, setShowAll] = useState(false);
  const { t } = useTranslation();

  // handle show all
  const handleShowAll = () => {
    setShowAll(true);
  };

  if (events.length === 0) return null;

  if (events.length === 1) {
    return <Event event={events[0]} />;
  }

  if (showAll)
    return (
      <>
        {events.map((event, index) => (
          <div key={index + 'abc'}>
            <Event event={event} />
          </div>
        ))}
      </>
    );

  return (
    <div className={styles.container_event}>
      <span className={styles.container_event_title}>
        {events[0].date} {t('one_lid.Ozgarish')}: {events.length} {t('one_lid.ta')}
      </span>
      <span onClick={handleShowAll} className={styles.container_event_value_name + ' ' + styles.container_event_value_name_cursor}>
        {t('one_lid.Hammasini_korish')}
      </span>
    </div>
  );
};

export default EventContainer;
