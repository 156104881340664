import React, { useState } from 'react';
import ArrowDownIcon from '@icons/ArrowDownIconCrm';
import CheckCircleIcon from '@icons/CheckCircleIcon';
import EditIcon from '@icons/EditIcon';
import { useUpdateNumerationConfig } from '@queries/mutation';
import { useGetNumerationConfigValues } from '@queries/queries';
import { NumerationConfigModel } from '@utils/models/NumerationConfigModel';
import { Input, Modal, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

type GroupedData = {
  [key: string]: NumerationConfigModel[];
};

const NumerationSettings: React.FC<IProps> = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const { data } = useGetNumerationConfigValues();
  const [currentElem, setCurrentElem] = useState({ open: false, key: '', value: '' });
  const numerationConfig = useUpdateNumerationConfig();
  const titles = {
    COUNTER_CONTRACT: t('home.shartnoma_soni'),
    COUNTER_PAYMENT: t('home.tolov_id'),
    COUNTER_CONTRACT_BY_BUILDER: (
      <p>
        {t('home.shartnoma_soni')} <span className="!text-[#98A2B3]">( {t('home.Quruvchi')} )</span>
      </p>
    ),
    COUNTER_CONTRACT_BY_HOUSE: (
      <p>
        {t('home.shartnoma_soni')} <span className="!text-[#98A2B3]">( {t('home.Uy')} )</span>
      </p>
    ),
    COUNTER_CONTRACT_BY_BUILDING: (
      <p>
        {t('home.shartnoma_soni')} <span className="!text-[#98A2B3]">( {t('home.Bino')} )</span>
      </p>
    )
  };

  const handleUpdateValue = (data: { id: number; value: string }) => {
    numerationConfig.mutateAsync(data).then(() => {
      setCurrentElem({ key: '', open: false, value: '' });
    });
  };

  const groupedData: GroupedData | undefined = data?.reduce((result, item) => {
    if (!result[item.key]) {
      result[item.key] = [];
    }
    result[item.key].push(item);
    return result;
  }, {} as GroupedData);

  const groupedArray =
    groupedData &&
    Object.keys(groupedData!).map(key => ({
      key,
      items: groupedData![key]
    }));

  const handleClose = () => {
    setVisible(false);
    setCurrentElem({ key: '', open: false, value: '' });
  };

  const columns = [
    {
      title: t('home.Nomi'),
      key: 'name',
      render: (record: any) => (
        <p className="inline-block select-none text-base font-medium text-[#344054]">
          {record?.key === 'COUNTER_CONTRACT' ||
          record?.key === 'COUNTER_PAYMENT' ||
          record?.key === 'COUNTER_CONTRACT_BY_BUILDER' ||
          record?.key === 'COUNTER_CONTRACT_BY_HOUSE' ||
          record?.key === 'COUNTER_CONTRACT_BY_BUILDING' ? (
            titles[record?.name as keyof typeof titles]
          ) : (
            <span className="text-[13px] font-normal text-[#344054]">{record?.name}</span>
          )}
        </p>
      )
    },
    {
      title: t('home.Qiymati'),
      key: 'qiymati',
      width: '12%',
      render: (record: any) =>
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        record?.key === currentElem?.key ? (
          <Input
            type="number"
            value={currentElem?.value}
            className="rounded-md"
            onChange={e => {
              setCurrentElem(elem => ({ ...elem, value: e?.target?.value }));
            }}
          />
        ) : (
          <span className="text-[13px] font-normal text-[#344054]">{record?.qiymati}</span>
        )
    },
    {
      width: '5%',
      render: (record: any) => (
        <div
          className={`flex h-full w-full items-center justify-center ${currentElem?.key === record?.key ? 'opacity-1' : 'opacity-0'} transition-all duration-300 hover:opacity-100`}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {record?.qiymati ? (
            currentElem?.key === record?.key ? (
              <Spin spinning={numerationConfig?.isLoading}>
                <div
                  className="flex cursor-pointer items-center justify-center rounded-lg bg-[#12B76A] p-2 transition-all duration-300"
                  onClick={() => {
                    handleUpdateValue({ id: Number(record?.id), value: currentElem?.value });
                  }}
                >
                  <CheckCircleIcon stroke="#fff" />
                </div>
              </Spin>
            ) : (
              <div
                className="flex items-center justify-center"
                onClick={() => {
                  setCurrentElem({ key: record?.key, open: true, value: record?.qiymati });
                }}
              >
                <EditIcon stroke="#475467" />
              </div>
            )
          ) : (
            ''
          )}
        </div>
      )
    }
  ];

  const newData = groupedArray
    ?.sort((a, b) => {
      if (a.key === 'COUNTER_PAYMENT') return -1;
      if (b.key === 'COUNTER_PAYMENT') return 1;
      return 0;
    })
    ?.map(elem => ({
      key: elem?.key,
      name: elem?.key,
      qiymati: elem?.items?.length < 2 ? elem?.items[0]?.value : '',
      id: elem?.items?.find(p => p?.key === elem?.key)?.id,
      children: elem?.items?.length > 1 && elem?.items?.map(item => ({ key: item?.id, qiymati: item?.value, name: item?.name, id: item?.id }))
    }));

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title={t('home.ozgaruvchilarning_qiymati')}
      width={1224}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={t('home.Yopish')}
      cancelButtonProps={{ className: 'rounded-full w-[190px] text-[#344054] text-base h-11' }}
      centered
    >
      <div>
        <Table
          columns={columns}
          dataSource={newData}
          bordered
          pagination={false}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              record?.children ? (
                <ArrowDownIcon
                  onClick={(e: any) => onExpand(record, e)}
                  className="mr-1 mt-1 cursor-pointer transition-all duration-300"
                  style={{ transform: expanded ? 'rotate(0deg)' : 'rotate(90deg)' }}
                />
              ) : (
                ''
              )
          }}
        />
      </div>
    </Modal>
  );
};

export default NumerationSettings;
