import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './counterInput.module.scss';
import AddCircleIcon from '@icons/AddCircleIcon';
import PlusCircle from '@icons/PlusCircle';
import MinusCircle from '@icons/MinusCircle';
import { helper } from '@utils/helper/helper';
import { numbers } from '@utils/constants/numbers';
import { isNumber } from '@utils/helper/isNumber';

interface IProps {
  value: string;
  setValue: (value: string) => void;
}

const CounterInput: FC<IProps> = ({ setValue, value }) => {
  const [selectionStart, setSelectionStart] = useState<number | null>(0);
  const interval = useRef<any>(null);
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    spanRef.current!.innerHTML = value;
    inputRef.current!.style.width = spanRef.current?.clientWidth! + 4 + 'px';
  }, [value]);

  const handleCount = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      const copyValue = String(Number(value) + 1);
      setValue(copyValue.length <= 6 || copyValue.includes('-') ? String(Number(value) + 1) : value);
    } else {
      const copyValue = String(Number(value) - 1);
      setValue(copyValue.length <= 7 ? String(Number(value) - 1) : value);
    }
  };

  return (
    <div className={styles.counter}>
      <MinusCircle onClick={() => handleCount('minus')} />
      <input
        ref={inputRef}
        style={{ minWidth: 20, width: 0 }}
        value={value}
        onPaste={e => {
          if (!isNumber(e.clipboardData.getData('text'))) {
            e.preventDefault();
          }
        }}
        onKeyDown={e => {
          setSelectionStart(e.currentTarget.selectionStart);
          if (e.key === 'ArrowUp') {
            handleCount('plus');
          }
          if (e.key === 'ArrowDown') {
            handleCount('minus');
          }
        }}
        onClick={e => {
          setSelectionStart(e.currentTarget.selectionStart);
        }}
        onKeyPress={e => {
          if (e.key === '-' && selectionStart === 0) {
            return e.key;
          } else if (!numbers.includes(e.key)) {
            e.preventDefault();
          }
        }}
        onChange={e => {
          const val = e.target.value.replace('-', '');
          const value = val[0] === '0' ? '0' : e.target.value;
          if (val.length <= 6) {
            spanRef.current!.innerHTML = value;
            e.currentTarget.style.width = spanRef.current?.clientWidth! + 4 + 'px';
            setValue(value);
          }
        }}
      />
      <span ref={spanRef} className={styles.spanRef}></span>
      <PlusCircle onClick={() => handleCount('plus')} />
    </div>
  );
};

export default CounterInput;
