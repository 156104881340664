import { lazy } from 'react';
import BiAnalytics from '@pages/boss/bi-analytics/BiAnalytics';

import { LocalStorage } from '../../service/LocalStorage';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { User } from '../../utils/models/User';

import { BossPagePaths } from './bossPagePaths';
// import { bossCrmPaths } from '../bossCrm/bossCrmPaths'
// import BossHome from '../../pages/bossHome/BossHome'
// import BossPayments from '../../pages/bossPayments/BossPayments'
// import BossComplex from '../../pages/bossComplex/BossComplex'
// import BossContract from '../../pages/bossContract/BossContract'
// import BossArrearage from '../../pages/bossArrearage/BossArrearage'
// import BossCrm from '../../pages/bossCrm/BossCrm'
// import BossSale from '../../pages/boss/boss-sale/BossSale'

const BossHome = lazy(() => import('../../pages/bossHome/BossHome'));
const BossPayments = lazy(() => import('../../pages/bossPayments/BossPayments'));
const BossComplex = lazy(() => import('../../pages/bossComplex/BossComplex'));
const BossContract = lazy(() => import('../../pages/bossContract/BossContract'));
const BossArrearage = lazy(() => import('../../pages/bossArrearage/BossArrearage'));
const BossCrm = lazy(() => import('../../pages/bossCrm/BossCrm'));
const BossSale = lazy(() => import('../../pages/boss/boss-sale/BossSale'));

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: BossPagePaths.HOME,
    component: BossHome,
    exact: true,
    permission: Permissions.PERMISSION_STATS_MAIN
  },
  {
    path: BossPagePaths.PAYMENT,
    component: BossPayments,
    exact: true,
    permission: Permissions.PERMISSION_STATS_PAYMENT
  },
  {
    path: BossPagePaths.ARREARAGE,
    component: BossArrearage,
    exact: true,
    permission: Permissions.PERMISSION_STATS_DEBT
  },
  {
    path: BossPagePaths.COMPLEX,
    component: BossComplex,
    exact: true,
    permission: Permissions.PERMISSION_STATS_HOUSE
  },
  {
    path: BossPagePaths.CONTRACT,
    component: BossContract,
    exact: false,
    permission: Permissions.PERMISSION_STATS_CONTRACT
  },
  {
    path: BossPagePaths.CRM,
    component: BossCrm,
    exact: false,
    permission: Permissions.PERMISSION_PAGE_CRM
  },
  {
    path: BossPagePaths.SALE,
    component: BossSale,
    exact: true,
    permission: Permissions.PERMISSION_STATS_SALE
  },
  {
    path: BossPagePaths.BI_ANALYTICS,
    component: BiAnalytics,
    exact: true,
    permission: Permissions.PERMISSION_MACRO_ANALYTICS
  }
];

const bossPageRoutes: typeof routes = [];

if (typeof user === 'object' && user) {
  routes.forEach(route => {
    if (route.permission) {
      if (user.role.permissions.includes(route.permission) || user.role.permissions.includes(Permissions.PERMISSION_MOBILE)) {
        bossPageRoutes.push(route);
      }
    } else {
      bossPageRoutes.push(route);
    }
  });
}

export default bossPageRoutes;
