import ElementplusIcon from '@icons/ElementplusIcon';
import { OneLid } from '@utils/models/OneLid';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/reduxHooks';
import { Permissions } from '../../../../utils/constants/permissions';

import AutoSMSTriggerWidget from './auto-sms-trigger/AutoSMSTriggerWidget';
import EmberWidget from './ember-widget/EmberWidget';
import ShowroomWidget from './showroom/ShowroomWidget';

import styles from './widgets.module.scss';

interface IProps {
  data: OneLid | undefined;
}

const Widgets: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessShowroom = permissions?.includes(Permissions.PERMISSION_SHOWROOM);

  return (
    <div className={styles.container}>
      <span className={styles.arrow}>
        <ElementplusIcon />
      </span>
      <div className={styles.container_header}>{t('one_lid.Vidjetlar')}</div>
      <div className={styles.container_content}>
        {isAccessShowroom && <ShowroomWidget />}
        <AutoSMSTriggerWidget />
        <EmberWidget data={data} />
      </div>
    </div>
  );
};

export default Widgets;
