import { queryNames } from '@utils/constants/queryNames';
import { useMutation, useQueryClient } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { showMessage } from '../../../queries/showMessage';
import { $api } from '../../../service/RequestService';
import { LeadNotTaskConf } from '../../../utils/models/LeadNotTaskConf';
import { LeadTaskClose } from '../../../utils/models/LeadTaskClose';
import { SendMessageBody } from '../../../utils/models/MyCalls';
import { endPoints } from '../utils/constants/endPoints';
// eslint-disable-next-line import/no-duplicates
import { queryKeys as oneLidQueryKeys } from '../utils/constants/queryKeys';
// eslint-disable-next-line import/no-duplicates
import { queryKeys } from '../utils/constants/queryKeys';

export function useBookingLeadFlatDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.BOOKING_LEAD_FLAT + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.BOOKING_LEAD_FLAT]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useTaskTypeCreate() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { name: string; icon: string; deleted: boolean; id?: number }) => {
      const res = await $api.post(endPoints.LEAD_TASK_TYPE_SAVE, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.LEAD_TASK_TYPE_ALL]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useTaskTypeDelete() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.LEAD_TASK_TYPE + id);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.LEAD_TASK_TYPE_ALL]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadTaskClosed(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: LeadTaskClose) => {
      const res = await $api.post(endPoints.LEAD_TASK_CLOSE, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.LEAD_NOT_CLOSED]);
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA, id]);
        qc.invalidateQueries([queryNames.TASK_FILTER_CRM]);
        qc.invalidateQueries([oneLidQueryKeys.CRM_ONE_LID]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadTaskDelete(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.LEAD_TASK + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.LEAD_NOT_CLOSED]);
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA, id]);
        qc.invalidateQueries([queryNames.TASK_FILTER_CRM]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteLeadNote(id: number, noteId: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(endPoints.LEAD_NOTE + noteId);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA, id]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadRecoveringDeleted() {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.get(`${endPoints.LEAD_RECOVERING_DELETED}/${id}`);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA]);
        qc.invalidateQueries([queryKeys.LEAD_NOT_CLOSED]);
        qc.invalidateQueries([queryKeys.CRM_ONE_LID]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMyCallsSendMessage(id: number, onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: SendMessageBody) => {
      const res = await $api.post(endPoints.MOI_ZVONKI_SEND_REQUEST_SEND_SMS, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA, id]);
        showMessage(data.message, data.accept);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useSaveNotTaskLeadConf() {
  const qc = useQueryClient();

  return useMutation(
    async (data: LeadNotTaskConf) => {
      const res = await $api.post(endPoints.LEAD_NOT_TASK_CONF, data);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.LEAD_NOT_TASK]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useMyCallsMakeCall() {
  return useMutation(
    async (data: { leadId: number; to: string }) => {
      const res = await $api.post(endPoints.MOI_ZVONKI_SEND_REQUEST_MAKE_CALL, data);

      return res.data;
    },
    {
      onError: baseErrorHandler
    }
  );
}

export function useSaleCrmTag() {
  const qc = useQueryClient();

  return useMutation(
    async (data: boolean) => {
      const res = await $api.post(`${endPoints.COMPANY_CONFIG_SALE_CRM_TAG}?value=${data}`);

      return res.data;
    },
    {
      onSuccess: () => {
        // showMessage(data.message, data.accept)
        qc.invalidateQueries([queryKeys.CRM_TAGS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteTag() {
  const qc = useQueryClient();

  return useMutation(
    async (data: number) => {
      const res = await $api.delete(endPoints.TAG + data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.CRM_TAGS]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useDeleteLid(id: number, onSuccess?: () => void) {
  return useMutation(
    async () => {
      const res = await $api.delete(endPoints.LEAD + id);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateRecord() {
  return useMutation(
    async (data: { callId: string; noteId: string }) => {
      const res = await $api.post(`${endPoints.CALL_HISTORY}${data?.callId}/update-record/${data?.noteId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useIgnoreDublicateLeads() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { currentObjectId: number; ignoreObjectIds: number[]; ignoreType: string }) => {
      const res = await $api.post(endPoints.IGNORE_LEAD, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.LEAD_DUPLICATE]);
        qc.invalidateQueries([queryKeys.BOOKING_LEAD_FLAT]);
        qc.invalidateQueries([queryKeys.INVITING_CLIENT]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}
export function useIgnoreClientLeads() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { currentObjectId: number; ignoreObjectIds: number[]; ignoreType: string }) => {
      const res = await $api.post(endPoints.IGNORE_CLIENT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.LEAD_DUPLICATE]);
        qc.invalidateQueries([queryKeys.BOOKING_LEAD_FLAT]);
        qc.invalidateQueries([queryKeys.SUGGESTION_LEAD]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useAttachClient() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { leadId: number; clientId: number }) => {
      const res = await $api.post(endPoints.ATTACH_CLIENT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.CRM_ONE_LID]);
        qc.invalidateQueries([queryKeys.CLIENT_VIEW]);
        qc.invalidateQueries([queryKeys.CLIENT_EVENT]);
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA]);
        qc.invalidateQueries([queryKeys.SUGGESTION_LEAD]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}
export function useCancelClient() {
  const qc = useQueryClient();

  return useMutation(
    async (data: { leadId: number; clientId: number }) => {
      const res = await $api.post(endPoints.CANCEL_CLIENT, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([queryKeys.CRM_ONE_LID]);
        qc.invalidateQueries([queryKeys.ATTACH_CLIENT]);
        qc.invalidateQueries([queryKeys.CLIENT_VIEW]);
        qc.invalidateQueries([queryKeys.CLIENT_EVENT]);
        qc.invalidateQueries([queryKeys.LEAD_COMPLETED_DATA]);
        qc.invalidateQueries([queryKeys.SUGGESTION_LEAD]);
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
}

export function useLeadCallAnalyse(props?: { onSuccess?: () => void; leadId?: number }) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { lang: string; leadId: number; leadNoteId: number }) => {
      const res = await $api.post(endPoints.LEAD_CALL_ANALYSE, data);

      return res.data;
    },
    {
      onSuccess: () => {
        qc.fetchQuery([queryKeys.LEAD_COMPLETED_DATA, props?.leadId]).finally(() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          props?.onSuccess && props?.onSuccess();
        });
      }
    }
  );
}
