import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import Broom from '../../../../assets/icons/Broom';
import Filter from '../../../../assets/icons/Filter';
import FilterItemClose from '../../../../assets/icons/FilterItemClose';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useContractEmployee, useHousesNameAndID } from '../../../../queries/queries';
import { SearchParamsContract } from '../../Contract';

import ContractFilterDrawer from './ContractFilterDrawer';

import styles from '../../../../components/filters/mainFilters.module.scss';

const ContractFilter = () => {
  const { t } = useTranslation();
  const { searchParams, remove, removeAllSearchParams, removeMultiple, appendSingle } = useQueryParam<SearchParamsContract, any>();
  const { data: houses } = useHousesNameAndID();
  const { data: employee } = useContractEmployee();
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [countFilters, setCountFilters] = useState(0);

  const deleteOneFilter = (key: string, value: string) => {
    remove(key, value);
  };

  const deleteMultipleFilters = (key: string[], value: string[]) => {
    removeMultiple(key, value);
  };

  useEffect(() => {
    let count = 0;

    if (searchParams.houses) {
      if (Array.isArray(searchParams.houses)) {
        count = searchParams.houses.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.responsibleByIds) {
      if (Array.isArray(searchParams.responsibleByIds)) {
        count = searchParams.responsibleByIds.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.statuses) {
      if (Array.isArray(searchParams.statuses)) {
        count += searchParams.statuses.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.currencies) {
      if (Array.isArray(searchParams.currencies)) {
        count += searchParams.currencies.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.startDate && searchParams.finishDate) {
      count += 1;
    }
    if (searchParams.contractNumber) {
      count += 1;
    }
    setCountFilters(count);
  }, [searchParams]);

  const itemHas = () => {
    const newSearchParams = { ...searchParams };

    delete newSearchParams.page;
    delete newSearchParams.size;
    delete newSearchParams.search;
    delete newSearchParams.order;
    delete newSearchParams.buildings;
    delete newSearchParams.color;
    return Object.keys(newSearchParams).length > 0;
  };

  const handleFilter = () => {
    setFilterDrawerVisible(true);
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const showHouses = () => {
    if (searchParams.houses) {
      if (!Array.isArray(searchParams.houses)) {
        const houseName = houses?.find(house => String(house.id) === (searchParams.houses as any));

        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{houseName?.name}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('houses', searchParams.houses as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } 
        return searchParams.houses.map(houseId => {
          const houseName = houses?.find(house => String(house.id) === (houseId as any));

          return (
            <div className={`${styles.item} d-flex align-center`} key={houseId}>
              <span className={styles.title}>{houseName?.name}</span>
              <FilterItemClose onClick={() => deleteOneFilter('houses', houseId)} className={styles.icon} />
            </div>
          );
        });
      
    }
    return null;
  };

  const showEmployees = () => {
    if (searchParams.responsibleByIds) {
      if (!Array.isArray(searchParams.responsibleByIds)) {
        const employeeName = employee?.find(emp => String(emp.id) === (searchParams.responsibleByIds as any));

        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{employeeName?.fullName}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('responsibleByIds', searchParams.responsibleByIds as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } 
        return searchParams.responsibleByIds.map(ids => {
          const employeeName = employee?.find(emp => String(emp.id) === ids);

          return (
            <div className={`${styles.item} d-flex align-center`} key={ids}>
              <span className={styles.title}>{employeeName?.fullName}</span>
              <FilterItemClose onClick={() => deleteOneFilter('responsibleByIds', ids)} className={styles.icon} />
            </div>
          );
        });
      
    }
    return null;
  };

  const showContractStatus = () => {
    if (searchParams.statuses) {
      if (!Array.isArray(searchParams.statuses)) {
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{t(`home.${searchParams.statuses as string}`)}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('statuses', searchParams.statuses as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } 
        return searchParams.statuses.map(status => (
            <div className={`${styles.item} d-flex align-center`} key={status}>
              <span className={styles.title}>{t(`home.${status}`)}</span>
              <FilterItemClose onClick={() => deleteOneFilter('statuses', status)} className={styles.icon} />
            </div>
          ));
      
    }
    return null;
  };

  const showDates = () => {
    if (searchParams.startDate && searchParams.finishDate) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>
            {searchParams.startDate}-{searchParams.finishDate}
          </span>
          <FilterItemClose
            onClick={() => deleteMultipleFilters(['startDate', 'finishDate'], [searchParams.startDate as string, searchParams.finishDate as string])}
            className={styles.icon}
          />
        </div>
      );
    } return '';
  };

  const showNumberFlat = () => {
    if (searchParams.flatNumber) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>
            {searchParams.flatNumber}-{t('home.xonadon')}
          </span>
          <FilterItemClose onClick={() => remove('flatNumber', undefined)} className={styles.icon} />
        </div>
      );
    } return '';
  };
  const showContractNumber = () => {
    if (searchParams.contractNumber) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>
            {searchParams.contractNumber}-{t('boss_contract.shartnoma')}
          </span>
          <FilterItemClose onClick={() => remove('contractNumber', undefined)} className={styles.icon} />
        </div>
      );
    } return '';
  };

  const showUnpaid = () => {
    if (searchParams.unpaid) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>{searchParams.unpaid && t('crm.unpaid')}</span>
          <FilterItemClose
            onClick={() => {
              remove('unpaid', undefined);
              deleteOneFilter('unpaid', '');
            }}
            className={styles.icon}
          />
        </div>
      );
    } return '';
  };
  const showDiscounted = () => {
    if (searchParams.discounted) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>{searchParams.discounted && t('crm.discounted')}</span>
          <FilterItemClose
            onClick={() => {
              remove('discounted', undefined);
              deleteOneFilter('discounted', '');
            }}
            className={styles.icon}
          />
        </div>
      );
    } return '';
  };

  const showFormal = () => {
    if (searchParams.formal) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>{searchParams.formal === 'true' ? t('home.Tasdiqlangan') : t('home.Tasdiqlanmagan')}</span>
          <FilterItemClose onClick={() => remove('formal', undefined)} className={styles.icon} />
        </div>
      );
    } return '';
  };

  const showFilters = () => {
    const statuses = showContractStatus();
    const dates = showDates();
    const houses = showHouses();
    const number = showNumberFlat();
    const formal = showFormal();
    const employees = showEmployees();
    const contract = showContractNumber();
    const unpaid = showUnpaid();
    const discounted = showDiscounted();

    return (
      <>
        {statuses}
        {houses}
        {dates}
        {number}
        {formal}
        {employees}
        {contract}
        {unpaid}
        {discounted}
      </>
    );
  };

  const clearAllFilter = () => {
    removeAllSearchParams();
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className="deleteSearchIcon" />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.cont}>
      <ContractFilterDrawer visible={filterDrawerVisible} setVisible={setFilterDrawerVisible} />
      <div className="d-flex align-center">
        <div
          onClick={() => handleFilter()}
          className={`${countFilters > 0 ? `${styles.filter_button  } ${  styles.active}` : styles.filter_button} d-flex align-center`}
        >
          <Filter />
          <span className={styles.title}>{t('home.Filtr')}</span>
          {countFilters > 0 && <span className={styles.count_filters}>{countFilters}</span>}
        </div>
        <Input
          value={searchParams.search}
          onChange={e => handleSearch(e.target.value)}
          suffix={showIconInput()}
          placeholder={t('home.Qidirish')}
          className={styles.search}
        />
        {itemHas() ? (
          <div className={`${styles.filtered_items_cont_right} d-flex align-center`}>
            {showFilters()}
            <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
              <Broom />
            </div>
            <div className={styles.line} />
          </div>
        ) : (
          <div className={styles.not_select_title_right}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
        )}
      </div>
      {itemHas() ? (
        <div className={`${styles.filtered_items_cont} d-flex align-center`}>
          {showFilters()}
          <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
            <Broom />
          </div>
          <div className={styles.line} />
        </div>
      ) : (
        <div className={styles.not_select_title}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
      )}
    </div>
  );
};

export default ContractFilter;
