import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsivePie } from '@nivo/pie';
import { useGetSuccessfulLeads } from '@pages/bossCrm/services/queries';
import { setSuccessfulLeadsModal } from '@reducers/CrmStatisticsSlice';
import { colors } from '@utils/constants';
import { colorPieChart } from '@utils/constants/colorPieChart';
import { useTranslation } from 'react-i18next';

import SuccessFulLeadsModal from '../successful-leads-modal';

import styles from './index.module.scss';

const SuccessfulLeads: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pieColors = [...colorPieChart].reverse();
  const { selectedPipe, successfulLeadModal, startDatePipe, finishDatePipe } = useAppSelector(state => state.crmStatistic);

  const { data } = useGetSuccessfulLeads({ statType: 'EMPLOYEE', pipeId: selectedPipe?.id, finishDate: finishDatePipe, startDate: startDatePipe });

  const other = {
    REASON: t('crm_filter.Sababsiz'),
    EMPLOYEE: t('task.Boshqalar')
  };

  const newArray = data?.result?.map(({ id, value, count }, index) => ({
    id,
    value: count,
    color: pieColors[index],
    label: id === -1 ? other?.EMPLOYEE : value
  }));

  const sum = data?.total;

  return (
    <div className={styles.successful}>
      <div className={styles.successful__header}>
        <h1>{t('crm.Muvafaqqiyatli_lidlar')}</h1>
      </div>
      <div className={styles.successful__body}>
        <ResponsivePie
          arcLabel="value"
          cornerRadius={3}
          innerRadius={0.65}
          data={newArray || []}
          enableArcLabels={false}
          arcLabelsSkipAngle={10}
          arcLinkLabelsThickness={2}
          arcLinkLabelsSkipAngle={10}
          enableArcLinkLabels={false}
          arcLabelsRadiusOffset={0.3}
          activeOuterRadiusOffset={16}
          colors={{ datum: 'data.color' }}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabelsTextColor={colors.gray[600]}
          margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
          borderColor={{
            from: 'color',
            modifiers: [['opacity', 0.2]]
          }}
          onClick={item => {
            dispatch(
              setSuccessfulLeadsModal({
                ...successfulLeadModal,
                visible: true,
                responsibleById: item?.data?.id
              })
            );
          }}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]]
          }}
          tooltip={({ datum: { value, label } }) => (
            <div className="rounded-lg bg-gray-600 p-3 text-white">
              <strong>
                {label}: {value}
              </strong>
            </div>
          )}
          theme={{
            tooltip: {
              container: {
                background: colors.white
              }
            }
          }}
        />
        <div className={styles.successful__body__all}>
          <h3>{sum}</h3>
          <p>{t('home.Jami')}</p>
        </div>
      </div>
      <div className={styles.container_every_x_month}>
        {newArray?.map(item => (
          <div className={styles.body} key={item.id}>
            <div className={styles.body_item}>
              <div className={styles.txt}>
                <div style={{ background: item.color }} className={styles.color}>
                  <div className={styles.circle} />
                </div>
                <h2>{item.label}</h2>
              </div>
              <div className={styles.quantity}>
                <h2>{item?.value}</h2>
              </div>
            </div>
          </div>
        ))}
      </div>
      <SuccessFulLeadsModal />
    </div>
  );
};

export default SuccessfulLeads;
