import React from 'react';

const CheckExpentIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0013 29.3327C23.3651 29.3327 29.3346 23.3631 29.3346 15.9993C29.3346 8.63555 23.3651 2.66602 16.0013 2.66602C8.63751 2.66602 2.66797 8.63555 2.66797 15.9993C2.66797 23.3631 8.63751 29.3327 16.0013 29.3327Z"
        stroke="#EAECF0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8333 14.6667L22.5 10M22.5 10H18.5M22.5 10V14M15.1667 17.3333L10.5 22M10.5 22H14.5M10.5 22L10.5 18"
        stroke="#464255"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckExpentIcon;
