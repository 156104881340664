import React, { FC, useEffect, useState } from 'react';
import styles from './sipuConnection.module.scss';
import { Button, Checkbox, Form, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../../../../../../assets/icons/CopyIcon';
import { useSipuniConfigAuth, useSipuniConfigLogOut } from '../utils/service/mutation';
import queryString from 'query-string';
import { SipuiAuth } from '../utils/models/SipuiAuth';
import LogoutIconMessengerIcon from '../../../../../../assets/icons/LogoutIconMessengerIcon';
import { BASE_URL } from '../../../../../../service/RequestService';

export interface IProps {
  data: SipuiAuth | undefined;
}

const SipuConnection: FC<IProps> = ({ data }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const sipuAuth = useSipuniConfigAuth();
  const logOut = useSipuniConfigLogOut();
  const [saveVisible, setSaveVisible] = useState(false);
  const [token, setToken] = useState('');
  const [copy, setCopy] = useState(false);

  const url = `${BASE_URL}/v1/sipuni/webhook/call?token=${token}`;

  const onFinish = (values: any) => {
    sipuAuth.mutate({
      secret: values.secret,
      userNumber: values.userNumber,
      token: String(queryString.parseUrl(url).query.token)
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        secret: data?.secret,
        userNumber: data?.userNumber
      });
      setToken(data?.token);
    } else {
      const rand = function () {
        return Math.random().toString(36).substr(2);
      };
      setToken(rand() + rand());
    }
  }, [data, form]);

  const logOutHandle = () => {
    logOut.mutate(data?.id!);
  };

  return data ? (
    <Form layout="vertical" className={styles.container}>
      <Form.Item label={t('home.Ulangan_hisob')} className={styles.connectAccount}>
        <Input placeholder={t('home.Qiymatlar')} className="customInput" size="large" value={data?.userNumber} />
        <span className={styles.logOut} onClick={logOutHandle}>
          <LogoutIconMessengerIcon />
        </span>
      </Form.Item>
      <Form.Item label="Webhook">
        <Input
          placeholder={t('home.Qiymatlar')}
          className="customInput"
          size="large"
          value={url}
          readOnly
          suffix={
            <Tooltip overlay={copy ? 'Copied' : 'Copy'}>
              <CopyIcon
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  setCopy(true);
                }}
              />
            </Tooltip>
          }
        />
      </Form.Item>
    </Form>
  ) : (
    <Form layout="vertical" className={styles.container} onFinish={onFinish} form={form}>
      <Form.Item label={t('home.API_kalit')} name={'secret'}>
        <Input placeholder={t('home.Qiymatlar')} className="customInput" size="large" />
      </Form.Item>
      <Form.Item label={t('home.Foydalanuvchi')} name={'userNumber'}>
        <Input placeholder={t('home.Qiymatlar')} className="customInput" size="large" />
      </Form.Item>
      <Form.Item label="Webhook">
        <Input
          placeholder={t('home.Qiymatlar')}
          className="customInput"
          size="large"
          value={url}
          disabled
          suffix={
            <CopyIcon
              onClick={() => {
                navigator.clipboard.writeText(url);
              }}
            />
          }
        />
      </Form.Item>
      <Form.Item>
        <Checkbox checked={saveVisible} onChange={e => setSaveVisible(e?.target?.checked)}>
          {t('home.Uchinchi_tomonga_malumotlar_uzatilishiga_roziman')}
        </Checkbox>
      </Form.Item>
      <p>{t('home.Integratsiya_boyicha_qollanmani_bu_yerdan_topasiz')}</p>
      <Button className={styles.saveBtn} htmlType="submit" loading={sipuAuth.isLoading} disabled={!saveVisible}>
        {t('home.Saqlash')}
      </Button>
    </Form>
  );
};

export default SipuConnection;
