import React, { useEffect, useState } from 'react';
import PremiumClientIcon from '@icons/PremiumClientIcon';
import VipClientIcon from '@icons/VipClientIcon';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import TableOrderIcon from '../../../assets/icons/TableOrderIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { getClassNameOrder } from '../../../utils/helper/getClassNameTableOrder';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { BaseLanguageEntity } from '../../../utils/models/BaseLanguageEntity';
import { ClientBalanceItem, ClientListData, ClientStatus } from '../../../utils/models/Client';
import { TableConfig } from '../../../utils/models/TableConfig';
import { SearchParamsClient } from '../Client';

import MenuDropDown from './MenuDropDown';

import styles from '../../arrearage/arrearage.module.scss';

interface TableClientProps {
  data: ClientListData[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  currentPage: number;
  pageSize: number;
  isFetching: boolean;
}

const ClientTable: React.FC<TableClientProps> = ({ tableConfig, tableConfigFetch, data, isLoading, pageSize, currentPage, isFetching }) => {
  const { searchParams, location, orderTable, push } = useQueryParam<SearchParamsClient, null>();

  const { t } = useTranslation();

  const initialColumn: ColumnsType<ClientListData> | undefined = [
    {
      title: t('home.№'),
      render: (text: any, record: ClientListData, index: number) => pageSize * (currentPage - 1) + index + 1
    },
    {
      title: t('home.Mijoz'),
      align: 'left',
      render: (record: ClientListData) => (
        <div className="flex items-center gap-1">
          <span>{record?.name}</span>
          {/* eslint-disable-next-line no-nested-ternary */}
          {record?.level === 'VIP' ? (
            <Tooltip overlayStyle={{ maxWidth: '490px' }} overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>}>
              <div className="flex items-center justify-center">
                <VipClientIcon />
              </div>
            </Tooltip>
          ) : record?.level === 'REGULAR' ? (
            <Tooltip overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>} overlayStyle={{ maxWidth: 440 }}>
              <div className="flex items-center justify-center">
                <PremiumClientIcon />
              </div>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
      )
    },
    {
      title: t('home.Telefon_raqam'),
      dataIndex: 'phone'
    },
    {
      title: t('home.Yashash_joyi'),
      dataIndex: 'city',
      align: 'left',
      render: (city: BaseLanguageEntity | null) => <>{city ? getCurrentLanguageWord(city) : '-'}</>
    },
    {
      title: (
        <div className="orderTableCont">
          <div>{t('home.Mijoz_balansi')}</div>
        </div>
      ),
      dataIndex: 'balances',
      render: (i: ClientBalanceItem[]) => (
        <>
          {i.map((item, index) => (
            <div key={index}>
              {item.amount?.toLocaleString('ru')} {item.currencyDto.ccy}
            </div>
          ))}
        </>
      )
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      render: (item: ClientStatus) => (
        <span
          className={
            // eslint-disable-next-line no-nested-ternary
            item === ClientStatus.ACTIVE
              ? 'table_client_status_active'
              : // eslint-disable-next-line no-nested-ternary
                item === ClientStatus.ARCHIVE
                ? 'table_client_status_archive'
                : item === ClientStatus.PASSIVE
                  ? 'table_client_status_not_active'
                  : 'table_client_status_new'
          }
        >
          {t(`home.${item}`)}
        </span>
      )
    },
    {
      title: t('home.Masul'),
      dataIndex: 'employee',
      align: 'left'
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <div onClick={() => handleOrders('DATE')} className="orderTableCont">
          <span>{t('home.Sana')}</span>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      dataIndex: 'date',
      render: (i: string) => <span style={{ color: '#A3A3A3' }}>{i}</span>
    },
    {
      title: '',
      width: '80px',
      render: (text: any, record: ClientListData) => <MenuDropDown id={record?.id} name={record?.name} phone={record?.phone} />
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Sana':
              newConfig.push({
                title: (
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  <div onClick={() => handleOrders('DATE')} className="orderTableCont">
                    <span>{t('home.Sana')}</span>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'date',
                align: 'right',
                render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
              });
              break;
            case '№':
              newConfig.push({
                title: t('home.№'),
                render: (text: any, record: ClientListData, index: number) => pageSize * (currentPage - 1) + index + 1
              });
              break;
            case 'Mijoz':
              newConfig.push({
                title: t('home.Mijoz'),
                align: 'left',
                render: (record: ClientListData) => (
                  <div className="flex items-center gap-1">
                    <span>{record?.name}</span>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {record?.level === 'VIP' ? (
                      <Tooltip overlayStyle={{ maxWidth: '490px' }} overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>}>
                        <div className="flex items-center justify-center">
                          <VipClientIcon />
                        </div>
                      </Tooltip>
                    ) : record?.level === 'REGULAR' ? (
                      <Tooltip overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>} overlayStyle={{ maxWidth: 440 }}>
                        <div className="flex items-center justify-center">
                          <PremiumClientIcon />
                        </div>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                )
              });
              break;
            case 'Telefon_raqam':
              newConfig.push({
                title: t('home.Telefon_raqam'),
                dataIndex: 'phone'
              });
              break;
            case 'Yashash_joyi':
              newConfig.push({
                title: t('home.Yashash_joyi'),
                dataIndex: 'city',
                align: 'left',
                render: (city: BaseLanguageEntity | null) => <>{city ? getCurrentLanguageWord(city) : '-'}</>
              });
              break;
            case 'Mijoz_balansi':
              newConfig.push({
                title: (
                  <div className="orderTableCont">
                    <div>{t('home.Mijoz_balansi')}</div>
                  </div>
                ),
                dataIndex: 'balances',
                render: (i: ClientBalanceItem[]) => (
                  <>
                    {i.map((item, index) => (
                      <div key={index}>
                        {item.amount?.toLocaleString('ru')} {item.currencyDto.ccy}
                      </div>
                    ))}
                  </>
                )
              });
              break;
            case 'Status':
              newConfig.push({
                title: t('home.Status'),
                dataIndex: 'status',
                render: (item: ClientStatus) => (
                  <span
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      item === ClientStatus.ACTIVE
                        ? 'table_client_status_active'
                        : // eslint-disable-next-line no-nested-ternary
                          item === ClientStatus.ARCHIVE
                          ? 'table_client_status_archive'
                          : item === ClientStatus.PASSIVE
                            ? 'table_client_status_not_active'
                            : 'table_client_status_new'
                    }
                  >
                    {t(`home.${item}`)}
                  </span>
                )
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                dataIndex: 'employee',
                align: 'left'
              });
              break;
            default:
              break;
          }
        }
      });
      newConfig.push({
        title: '',
        width: '80px',
        render: (text: any, record: ClientListData) => <MenuDropDown id={record?.id} name={record?.name} phone={record?.phone} />
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch, location.search]);

  useEffect(() => {
    const newConfig: any[] = [...column];

    if (tableConfig.length === 0) {
      newConfig[0] = {
        title: t('home.№'),
        render: (text: any, record: ClientListData, index: number) => pageSize * (currentPage - 1) + index + 1
      };
      newConfig[7] = {
        title: (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <div onClick={() => handleOrders('DATE')} className="orderTableCont">
            <span>{t('home.Sana')}</span>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        dataIndex: 'date',
        render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
      };
      setColumn(newConfig);
    }
  }, [location.search, currentPage]);

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  const goToClientPage = (e: ClientListData) => {
    push(`${location.pathname}/${e.id}/${e.name}`);
  };

  return (
    <Table
      className={`custom_table contract_table_size ${styles.client_table}`}
      columns={column}
      dataSource={data}
      loading={isLoading || tableConfigFetch || isFetching}
      size="small"
      rowKey="id"
      pagination={false}
      onRow={(e: ClientListData) => ({
        onClick: () => {
          goToClientPage(e);
        }
      })}
    />
  );
};

export default ClientTable;
