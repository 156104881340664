/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { PaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/utils/models/PaymentCustomType';
import { Layout, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import BarChartContainer from '../../components/bossPayments/BarChartContainer';
import TablePayments from '../../components/bossPayments/TablePayments';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useBossPayments } from '../../queries/queries';
import { BossPaymentsType } from '../../utils/models/BossPayments';

import BossClientStatistic from './boss-client-statistic/BossClientStatistic';
import BossMonthlyPayment from './boss-monthly-payment/BossMonthlyPayment';
import BossPaymentTerm from './boss-payment-term/BossPaymentTerm';

import styles from './bossPayments.module.scss';

const { Content } = Layout;

export interface BossPaymentsSearchParams {
  page?: string;
  size?: string;
  type?: BossPaymentsType;
  start?: string;
  finish?: string;
  houses?: string[];
  buildings?: string[];
}

const BossPayments = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');
  const { searchParamsString } = useQueryParam();
  const { isLoading, data, isFetching } = useBossPayments(searchParamsString);

  const dataConverted = data?.map(item => ({
    ...item,
    ...item?.payment?.custom?.reduce<Record<string, PaymentCustomType>>((p, c) => {
      p[`custom_${ c?.id }`] = c;
      return p;
    }, {})
  }));

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <NotificationButtons />
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <Spin spinning={isLoading || isFetching}>
          <div className={styles.container}>
            <Tabs onChange={setActiveTab} activeKey={activeTab}>
              <Tabs.TabPane key="1" tab={t('boss_home.Tolovlar')} />
              <Tabs.TabPane key="2" tab={t('showroom.Tolov_muddati')} />
              <Tabs.TabPane key="3" tab={t('boss_crm.Oylik_tolov_grafigi')} />
            </Tabs>
            {activeTab === '1' ? (
              <>
                <BarChartContainer data={dataConverted} />
                <TablePayments data={data} />
              </>
            ) : activeTab === '2' ? (
              <div className={styles.bottom}>
                <BossPaymentTerm />
                <BossClientStatistic />
              </div>
            ) : (
              <BossMonthlyPayment />
            )}
          </div>
        </Spin>
      </Content>
    </>
  );
};

export default BossPayments;
