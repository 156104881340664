import React from 'react';

const LinearGridIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75V11.25C16.5 15 15 16.5 11.25 16.5Z"
        stroke="#3772FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.25 1.5V16.5" stroke="#3772FF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LinearGridIcon;
