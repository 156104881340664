export enum TYPE_EVENTS_GA {
  TASK = 'TASK',
  NOTIFICATION = 'NOTIFICATION',
  KPI = 'KPI',
  SHOWROOM = 'SHOWROOM',
  CRM = 'CRM'
}

export enum ACTION_EVENTS_GA {
  CREATE_TASK_INSIDE_TASK = 'Create Task inside one task',
  COMPLETE_TASK_INSIDE_ONE_TASK = 'Complete task inside one task',
  COMPLETE_TASK_INSIDE_NOTIFICATION = 'Complete task inside notification',
  COMPLETE_TASK_SOCKET = 'Complete task inside socket notification',
  CREATE_TASK_INSIDE_ARREARAGE = 'Create Task inside arrearage',
  GO_TO_ALL_TASK = 'Go to all task inside notification',
  GO_TO_ONE_TASK = 'Go to one task inside notification',
  CLICK_CALENDAR_VIEW_BUTTON = 'click calendar view button',
  OPEN_NOTIFICATION = 'open notification',
  FULL_INFORMATION_SHOWROOM = 'press button full information one flat',
  GO_TO_BRON_PAGE_INSIDE_SHOWROOM = 'go to bron page inside showroom',
  GO_TO_CREATE_CONTRACT_INSIDE_SHOWROOM = 'go to create contract inside showroom',
  CONFIRM_OUTCOME_MONEY_KPI = 'confirm outcome money',
  CREATE_BROKER_INSIDE_KPI = 'create broker'
}

export enum ACTION_EVENTS_GA_CRM {
  CLICK_OR_VISIT_ONE_LEAD = 'click or visit one lead',
  CREATE_TASK = 'create task',
  COMPLETE_TASK = 'complete task',
  CREATE_NOTE = 'create note',
  BEGIN_CHAT = 'begin chat',
  CHANGE_STEP_INSIDE_ONE_LEAD = 'change step lead inside one lead',
  CHANGE_STEP_LEAD_ON_BOARD = 'change step lead on board',
  CLICK_CREATE_NEW_LEAD = 'click create new lead',
  CREATE_LEAD_FAST = 'fast create lead',
  CLICK_SETTINGS_BOARD = 'click settings board',
  CLICK_INSTAGRAM_CONFIG = 'click instagram config',
  WEB_SITE_TELEGRAM_AND_BOT_CONFIG = 'web site,telegram and bot config',
  CHANGE_ONE_LEAD_INFORMATION = 'change one lead information',
  ADD_TAG = 'add tag',
  PIN_NOTE = 'pin note'
}
