import React, { useEffect } from 'react';
import { ContractReqProps } from '@pages/createContract/CreateContract';
import DatePicker from '@service/datePicker';
import { DATE_FORMAT } from '@utils/constants';
import { Permissions } from '@utils/constants/permissions';
import { Form, Input, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useContractEmployee, useContractField, useContractNumber, useListStaffCreateContract } from '../../../queries/queries';
import { setBroker, setComment, setContractNumber, setCustomField, setDelay, setDelayInterval, setEmployee, setVariation } from '../../../store/reducers/CreateContractSlice';
import { helper } from '../../../utils/helper/helper';
import { CrmFieldsType } from '../../../utils/models/CrmFieldsType';
import { CustomFieldData } from '../../../utils/models/CustomField';

import styles from './contractProps.module.scss';

enum TypeAddon {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT'
}

interface IProps {
  contractFields: ContractReqProps[];
  setContractFields: React.Dispatch<React.SetStateAction<ContractReqProps[]>>;
}

const ContractProps: React.FC<IProps> = ({ contractFields, setContractFields }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isMultiPayment = permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const isMultiPaymentEdit = permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT);
  const disabled = isMultiPayment ? !isMultiPaymentEdit : false;

  const { brokerId, delay, contractNumber, delayInterval, flats, fields, employeeId, variation, comment } = useAppSelector(state => state.createContract);
  const dispatch = useAppDispatch();

  const { data: employeeList, isLoading: loadingEmployee } = useContractEmployee();
  const { data: brokerList, isLoading } = useListStaffCreateContract();
  const { isLoading: loadingNumber } = useContractNumber(flats[0]?.flat?.id || 0);
  const { data: fieldsData } = useContractField();

  useEffect(() => {
    if (fieldsData) {
      setContractFields(fieldsData?.map(item => ({ value: '', required: Boolean(item?.required), id: item?.id!, border: '#d9d9d9' }))?.filter(elem => elem?.id));
    }
  }, [fieldsData]);

  useEffect(() => {
    form.setFieldsValue({
      brokerId,
      contractNumber,
      employeeId
    });
  }, [brokerId, form, contractNumber, employeeId]);

  // handle change broker id
  const handleChangeBrokerId = (val: number) => {
    dispatch(setBroker(val));
  };

  // handle change employee
  const handleChangeEmployee = (val: number) => {
    dispatch(setEmployee(val));
  };

  // incrementAndDecrementDelay
  const incrementAndDecrementDelay = (type: TypeAddon) => {
    if (!disabled) {
      if (type === TypeAddon.INCREMENT) {
        const nextDelay = delay + 1;

        dispatch(setDelay(nextDelay));
      } else {
        if (delay && delay <= 1) return;
        const nextDelay = delay - 1;

        dispatch(setDelay(nextDelay));
      }
    }
  };

  // handle change delay
  const handleChangeDelay: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (!disabled) {
      const newDelay = Number(helper.deleteNotNumbers(e.target.value, 0));

      dispatch(setDelay(newDelay));
    }
  };

  // incrementAndDecrementDelay
  const incrementAndDecrementDelayInterval = (type: TypeAddon) => {
    if (!disabled) {
      if (type === TypeAddon.INCREMENT) {
        const nextDelay = delayInterval + 1;

        dispatch(setDelayInterval(nextDelay));
      } else {
        if (delayInterval && delayInterval <= 1) return;
        const nextDelay = delayInterval - 1;

        dispatch(setDelayInterval(nextDelay));
      }
    }
  };

  // handle change delay
  const handleChangeDelayInterval: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (!disabled) {
      const newDelayInterval = Number(helper.deleteNotNumbers(e.target.value, 0));

      dispatch(setDelayInterval(newDelayInterval));
    }
  };

  // handle change delay
  const handleChangeContractNumber: React.ChangeEventHandler<HTMLInputElement> = e => {
    dispatch(
      setContractNumber({
        isChange: true,
        number: e.target.value
      })
    );
  };

  // hande change custom fields
  const handleChangeCustomFields = (value: CustomFieldData) => {
    dispatch(setCustomField(value));
  };

  // get fieldValue
  const getFieldValue = (id: number) => {
    const find = fields.find(item => item.fieldId === id);

    if (find) return find.value;
    return '';
  };

  return (
    <Form form={form} name="ContractProps" autoComplete="off">
      <div className={styles.container}>
        <div className={styles.container_main}>
          <div className={`${styles.container_main_number} mt-[5px]`}>
            <Spin spinning={loadingNumber}>
              <label htmlFor="contractNumber">{t('create_table_payments.Shartnoma_raqami')}</label>
              <Form.Item name="contractNumber">
                <Input onChange={handleChangeContractNumber} id="contractNumber" className="customInput" />
              </Form.Item>
            </Spin>
          </div>
          <div className={`${styles.container_main_delay} mt-[5px]`}>
            <label htmlFor="delay">{t('create_table_payments.Qaytarish_muddati_oy')}</label>
            <div className={styles.container_main_delay_group}>
              <span onClick={() => incrementAndDecrementDelay(TypeAddon.DECREMENT)} className={styles.container_main_delay_group_minus}>
                -
              </span>
              <Input onChange={handleChangeDelay} value={delay} id="delay" disabled={disabled} />
              <span onClick={() => incrementAndDecrementDelay(TypeAddon.INCREMENT)} className={styles.container_main_delay_group_plus}>
                +
              </span>
            </div>
          </div>
          <div className={`${styles.container_main_delay} mt-[5px]`}>
            <label htmlFor="delayInterval">{t('create_table_payments.Vaqt_oraligi')}</label>
            <div className={styles.container_main_delay_group}>
              <span onClick={() => incrementAndDecrementDelayInterval(TypeAddon.DECREMENT)} className={styles.container_main_delay_group_minus}>
                -
              </span>
              <Input onChange={handleChangeDelayInterval} value={delayInterval} id="delayInterval" disabled={disabled} />
              <span onClick={() => incrementAndDecrementDelayInterval(TypeAddon.INCREMENT)} className={styles.container_main_delay_group_plus}>
                +
              </span>
            </div>
          </div>
          <div className={`${styles.container_main_number} mt-[5px]`}>
            <label htmlFor="contractNumber">{t('create_table_payments.Vositachi')}</label>
            <Form.Item name="brokerId">
              <Select
                showSearch
                optionFilterProp="children"
                loading={isLoading}
                onChange={e => handleChangeBrokerId(e as number)}
                className={`customSelect ${styles.container_main_number_select}`}
              >
                {brokerList?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Option key={index} value={item.id}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={`${styles.container_main_number} mt-[5px]`}>
            <label htmlFor="contractNumber">{t('create_table_payments.Masul_xodim')}</label>
            <Form.Item name="employeeId">
              <Select
                showSearch
                optionFilterProp="children"
                loading={loadingEmployee}
                onChange={e => handleChangeEmployee(e as number)}
                className={`customSelect ${styles.container_main_number_select}`}
              >
                {employeeList?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Option key={index} value={item.id}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className={`${styles.container_price} mt-[5px]`}>
            <label>{t('home.Izoh')}</label>
            <Form.Item name="comment">
              <Input className="customInput" value={comment as string} onChange={e => dispatch(setComment(e.target.value))} />
            </Form.Item>
          </div>
        </div>
        <div className={styles.container_custom_field}>
          {fieldsData?.map((item, index) => {
            const borderColor = contractFields?.find(elem => elem?.id === item?.id)?.border;

            if (item.type === CrmFieldsType.TEXT) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={styles.container_custom_field_text}>
                  <label htmlFor={`field_${item.name}`}>{item.name}</label>
                  <Input
                    value={getFieldValue(Number(item.id))}
                    onChange={e => {
                      const newData = { border: '#d9d9d9', id: item?.id!, required: Boolean(item?.required), value: e?.target?.value };

                      setContractFields(prev => [...prev, newData]);

                      handleChangeCustomFields({
                        fieldId: Number(item.id),
                        value: e.target.value
                      });
                    }}
                    id={`field_${item.name}`}
                    className={`customInput  ${borderColor !== '#d9d9d9' && 'requiredInput'}`}
                  />
                </div>
              );
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles.container_custom_field_text}>
                <label htmlFor={`field_${item.name}`}>{item.name}</label>
                <Select
                  id={`field_${item.name}`}
                  showSearch
                  optionFilterProp="children"
                  value={getFieldValue(Number(item.id))}
                  onChange={e => {
                    const newData = { border: '#d9d9d9', id: item?.id!, required: Boolean(item?.required), value: e };

                    setContractFields(prev => [...prev, newData]);

                    handleChangeCustomFields({
                      fieldId: Number(item.id),
                      value: e
                    });
                  }}
                  className={`customSelect ${styles.container_custom_field_text_select} ${borderColor !== '#d9d9d9' && 'requiredSelect'}`}
                >
                  {item?.options?.map((option, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Select.Option key={i} value={option.name}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            );
          })}
          {variation?.mortgagePercent ? (
            <label className="flex flex-col text-[14px] text-[#8c8c8c]">
              Ipoteka sanasi{' '}
              <DatePicker
                allowClear={false}
                className="rounded-[6px]"
                value={variation?.mortgagePaymentDate ? dayjs(variation?.mortgagePaymentDate, DATE_FORMAT) : null}
                format={DATE_FORMAT}
                onChange={(_, dateString) => dispatch(setVariation({ ...variation, mortgagePaymentDate: dateString }))}
              />
            </label>
          ) : null}
        </div>
      </div>
    </Form>
  );
};

export default ContractProps;
