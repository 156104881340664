import React from 'react';
import { downloadFileByUrl } from '../../utils/helper/downloadFile';
import { BASE_URL } from '../../service/RequestService';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { handleClose as close } from '../../store/reducers/GoogleDocVieweSlice';
import styles from './fileViewerModal.module.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';

const FileViewerModal = () => {
  const { url, name, format, isOpen } = useAppSelector(state => state.googleDocViewer);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  //downloadFunc
  const downloadFunc: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    downloadFileByUrl(url, name, format);
    handleClose();
  };

  // handleClose
  const handleClose = () => {
    dispatch(close());
  };

  return (
    <div onClick={handleClose} className={`${styles.container} ${isOpen ? styles.container_active : ''}`}>
      <div className={styles.container_header}>
        <p onClick={e => e.stopPropagation()}>{name}</p>
        <div className={styles.container_header_right}>
          <Button className={styles.container_header_right_button} type={'primary'} onClick={downloadFunc}>
            {t('client_balance.Yuklab_olish')}
          </Button>
          <CloseOutlined className={styles.container_header_right_icon} />
        </div>
      </div>
      <div onClick={e => e.stopPropagation()} className={styles.container_file}>
        {url &&
          (format === 'pdf' ? (
            <iframe
              title={'view file iframe'}
              height="100%"
              width="100%"
              src={`https://docs.google.com/gview?url=${BASE_URL + '/' + url}&embedded=true`}
            />
          ) : (
            <iframe
              title={'view file iframe'}
              height="100%"
              width="100%"
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${BASE_URL + '/' + url}`}
            />
          ))}
      </div>
    </div>
  );
};

export default FileViewerModal;
