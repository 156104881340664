import i18n from '../../service/I18nService';

export const getCurrentLanguageWord = (langObj: any) => {
  switch (i18n.language) {
    case 'ru':
      return langObj?.ru;
    case 'en':
      return langObj?.en;
    case 'kr':
      return langObj?.kr;
    case 'tr':
      return langObj?.tr;
    default:
      return langObj?.uz;
  }
};
