/* eslint-disable react/jsx-no-bind */
import React, { ChangeEventHandler, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditProfile } from '../../../queries/mutation';
import { PasswordValidationColor } from '../../../utils/models/PasswordValidationColor';
import { Profile } from '../../../utils/models/Profile';
import PasswordValidationPopover from '../../passwordValidationPopover/PasswordValidationPopover';

import styles from './changePassword.module.scss';

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  profile?: Profile;
}

interface IForm {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePassword: React.FC<Props> = ({ visible, onClose, profile }) => {
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    letters: false,
    beginUppercase: false,
    number: false
  });
  const [form] = Form.useForm<IForm>();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const editProfile = useEditProfile(handleCancel);

  const { t } = useTranslation();

  // handle close modal
  function handleCancel() {
    onClose(false);
    form.resetFields();
  }

  // handle submit form
  const onFinish = (values: IForm) => {
    if (profile) {
      editProfile.mutate({
        ...profile,
        oldPassword: values.oldPassword,
        password: values.password
      });
    }
  };

  // handle password change
  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = e => {
    setPasswordValidation({
      length: e.target.value?.length >= 8,
      beginUppercase: !!e.target.value[0]?.match(/[A-Z]/),
      letters: !!e.target.value?.match(/[A-Za-z]/),
      number: !!e.target.value?.match(/[0-9]/)
    });
  };

  // title password validation
  const titlePasswordValidation = () => {
    if (passwordValidation.number && passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ajoyib_Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return t('profile.Orta_ishonchli_parol');
    }
    return t('profile.Ishonchsiz_parol');
  };

  // title password validation
  const titlePasswordValidationColor = () => {
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return PasswordValidationColor.strong;
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return PasswordValidationColor.middle;
    }
    return PasswordValidationColor.low;
  };

  return (
    <Modal
      width={546}
      className="createBuilderModal"
      title={t('profile.Parolni_ozgartirish')}
      visible={visible}
      footer={false}
      // eslint-disable-next-line react/jsx-no-bind
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={t('profile.Amaldagi_parolni_kiriting')}
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
            >
              <Input.Password className="customInput" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              label={t('profile.Yangi_parolni_kiriting')}
              rules={[
                {
                  required: true
                },
                {
                  validator: (rule, value) => {
                    if (!!value.match(/[A-Za-z]/) && value[0]?.match(/[A-Z]/) && value.length >= 8) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  }
                }
              ]}
              hasFeedback
            >
              <Input.Password onChange={handlePasswordChange} className="customInput" />
            </Form.Item>
          </Col>
          <Col className={styles.passwordValidation} span={24}>
            <PasswordValidationPopover
              length={passwordValidation.length}
              letters={passwordValidation.letters}
              beginUppercase={passwordValidation.beginUppercase}
              number={passwordValidation.number}
            >
              <p style={{ color: titlePasswordValidationColor() }} className={styles.passwordValidation_title}>
                {titlePasswordValidation()}
              </p>
            </PasswordValidationPopover>
            <div className={styles.passwordValidation_step}>
              <div
                style={{
                  background: passwordValidation.length ? PasswordValidationColor.low : undefined
                }}
              />
              <div
                style={{
                  background: passwordValidation.letters && passwordValidation.length ? PasswordValidationColor.middle : undefined
                }}
              />
              <div
                style={{
                  background:
                    passwordValidation.beginUppercase && passwordValidation.letters && passwordValidation.length
                      ? PasswordValidationColor.strong
                      : undefined
                }}
              />
              <div
                style={{
                  background:
                    passwordValidation.beginUppercase && passwordValidation.number && passwordValidation.letters && passwordValidation.length
                      ? PasswordValidationColor.strong
                      : undefined
                }}
              />
            </div>
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirmPassword"
              label={t('profile.Yangi_parolni_qayta_kiriting')}
              dependencies={['password']}
              rules={[
                {
                  required: true
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  }
                })
              ]}
            >
              <Input.Password className="customInput" />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button loading={editProfile.isLoading} htmlType="submit" type="primary">
            {t('profile.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePassword;
