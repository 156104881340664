import React from 'react';

interface Props {
  className?: string;
}

const ArrowRightMessage: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.928151 9.97175C0.557153 9.60075 0.557153 8.99925 0.928151 8.62825L4.0564 5.5L0.928151 2.37175C0.557153 2.00075 0.557153 1.39925 0.928151 1.02825C1.29915 0.65725 1.90066 0.65725 2.27165 1.02825L6.07165 4.82825C6.44265 5.19925 6.44265 5.80075 6.07165 6.17175L2.27165 9.97175C1.90066 10.3427 1.29915 10.3427 0.928151 9.97175Z"
        fill="#fff"
      />
    </svg>
  );
};

export default ArrowRightMessage;
