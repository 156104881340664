import React, { useState } from 'react';
import { Empty, Modal, Pagination, Spin } from 'antd';
import { useGetOnePaymentDelay } from '../../services/queries';
import { t } from 'i18next';
import PaginationPrev from '../../../../../assets/icons/PaginationPrev';
import PaginationNext from '../../../../../assets/icons/PaginationNext';

import styles from './bossPaymentModal.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  color: string;
  paymentType: string;
};

const BossPaymentModal: React.FC<Props> = ({ visible, setVisible, title, color, paymentType }) => {
  const [pageSize, setPageSize] = useState({ page: 1, size: 4 });

  const { data, isLoading } = useGetOnePaymentDelay(paymentType, pageSize.page, pageSize.size);

  //handleClose
  const handleClose = () => {
    setVisible(false);
    setPageSize({ page: 1, size: 4 });
  };

  // changePage
  const changePage = (page: number, size: number) => {
    setPageSize({ page: page, size: size });
  };

  return (
    <Modal
      className={styles.paymentModal}
      visible={visible}
      footer={false}
      title={
        <div className={styles.modalTitle}>
          <div
            className={styles.modalTitle_circle}
            style={{
              borderColor: `${color}`
            }}
          ></div>
          <span>
            {title} ({data?.totalElements})
          </span>
        </div>
      }
      onCancel={handleClose}
      centered
      width={581}
      style={{ minHeight: 674 }}
    >
      <Spin spinning={isLoading}>
        {data?.data?.length! > 0 ? (
          <div className={styles.container}>
            {data?.data?.map(item => (
              <div className={styles.container_item}>
                <div className={styles.container_item_child}>
                  <span>{t('home.Mijoz')}</span>
                  <h5>{item?.clientName}</h5>
                </div>
                <div className={styles.container_item_child}>
                  <span>{t('home.Telefon')}</span>
                  <h5>{item?.clientPhone}</h5>
                </div>
                <div className={styles.container_item_child}>
                  <span>{t('home.Shartnoma')}</span>
                  <h5>{item?.contractNumber}</h5>
                </div>
                <div className={styles.container_item_child}>
                  <span>{t('home.Ortacha_kechikish_muddati')}</span>
                  <h5>
                    {item?.delay} {t('one_lid.kun')}
                  </h5>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        )}
      </Spin>
      <Pagination
        size="small"
        showSizeChanger={false}
        current={pageSize.page || 1}
        total={data?.totalElements}
        pageSize={4}
        prevIcon={
          <div className={styles.dots}>
            <PaginationPrev />
          </div>
        }
        nextIcon={
          <div className={styles.dots}>
            <PaginationNext />
          </div>
        }
        onChange={(page, size) => {
          changePage(page, size);
        }}
        hideOnSinglePage={true}
      />
    </Modal>
  );
};

export default BossPaymentModal;
