import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import SettingsHeader from '@components/setting/settingsHeader/SettingsHeader';
import RobotoCallsMenu from '@pages/roboto-calls/components/roboto-calls-menu/RobotoCallsMenu';
import { Layout } from 'antd';

import { RobotoCallsRoutes } from './RobotoCallsRoutes';

const { Content } = Layout;

const CrmSettingsRouter = () => (
  <>
    <MainHeader>
      <SettingsHeader />
      <div className="d-flex align-center">
        <OnlinePbxNotifications />
        <NotificationButtons />
      </div>
    </MainHeader>
    <Content
      style={{
        padding: '1rem 2.68rem',
        overflowY: 'auto',
        height: '50vh'
      }}
    >
      <div className="flex items-start gap-8">
        <RobotoCallsMenu />
        <Routes>{RobotoCallsRoutes?.map((route, index) => <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />)}</Routes>
      </div>
    </Content>
  </>
);

export default CrmSettingsRouter;
