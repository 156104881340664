import { Tag } from 'antd';
import React, { FC } from 'react';
import { useCrmFields } from '../../../../../../queries/queries';
import { FieldValue, OneLid } from '../../../../../../utils/models/OneLid';
import styles from './dublicateControllModal.module.scss';
import { useTranslation } from 'react-i18next';
import i18n from '@service/I18nService';

interface IProps {
  dublicate: OneLid | undefined;
  fieldsArr: FieldValue[];
}

const DublicateControllFieldAll: FC<IProps> = ({ dublicate, fieldsArr }) => {
  const { t } = useTranslation();
  const { data } = useCrmFields();

  const fields = data?.filter(item => item?.infoType === 'LED');

  const convertLocation = (value: string) => {
    const obj = value && value.includes('}') && value.includes('{') ? JSON.parse(value) : {};
    return {
      lat: obj?.lat,
      lng: obj?.lng,
      radius: obj?.radius,
      name: obj?.name || value || ''
    };
  };

  const convertAddress = (value: string) => {
    const obj = value && value.includes('{') && value.includes('}') ? JSON.parse(value) : {};
    return {
      city: obj[`city_${i18n.language}`] ? obj[`city_${i18n.language}`] : '',
      region: obj[`region_${i18n.language}`] ? obj[`region_${i18n.language}`] : '',
      country: obj[`country_${i18n.language}`] ? obj[`country_${i18n.language}`] : ''
    };
  };

  const filedValue = (id: number, value: string, valueList: string[] | undefined) => {
    const fieldType = fields?.find(item => item?.id === id)?.type;

    if (fieldType === 'LOCATION') {
      return convertLocation(value)?.name?.length > 30 ? convertLocation(value)?.name.slice(0, 30) + '...' : convertLocation(value)?.name;
    } else if (fieldType === 'ADDRESS') {
      return `${convertAddress(value)?.country} ${convertAddress(value)?.region} ${convertAddress(value)?.city}`;
    } else if (fieldType === 'MULTI_SELECT') {
      return valueList?.map(prev => ` ${prev},`);
    } else {
      return value;
    }
  };

  return (
    <div className={styles.body_all}>
      <div>
        <label>{t('client_create_contract.Ism')}</label>
        <p className={styles.field_name}>{dublicate?.name}</p>
      </div>
      <div>
        <label>{t('triggers.Masul_xodim')}</label>
        <p className={styles.field_createBy}>{dublicate?.responsibleBy}</p>
      </div>
      <div>
        <label>{t('home.Yaratilgan_sana')}</label>
        <p className={styles.field_createDate}>{dublicate?.createdDate}</p>
      </div>
      <div>
        <label>{t('one_lid.Budjet')}</label>
        <p className={styles.field_createBy}>{dublicate?.balance?.toLocaleString('ru')}</p>
      </div>
      <div>
        <label>{t('one_lid.Qadam')}</label>
        <div className={styles.field_pipe}>
          {dublicate?.pipeStatusResponse?.pipeName}{' '}
          <span className={styles.field_pipe_status} style={{ background: dublicate?.pipeStatusResponse?.color }}>
            {dublicate?.pipeStatusResponse?.name}
          </span>
        </div>
      </div>
      {fieldsArr?.map(item => {
        const name = fields?.find(field => field?.id === item?.id)?.name;
        return (
          fields?.find(field => field?.id === item?.id)?.name && (
            <div>
              <label>{name}</label>
              <p className={styles.field_createBy}>
                {filedValue(
                  item?.id!,
                  dublicate?.fields?.find(field => field?.id === item?.id)?.value!,
                  dublicate?.fields?.find(field => field?.id === item?.id)?.valueList!
                ) || 'Not found'}
              </p>
            </div>
          )
        );
      })}
      {dublicate?.tags && dublicate?.tags?.length > 0 && (
        <div>
          <label>{t('one_lid.Tags')}</label>
          <div className={styles.tags}>{dublicate?.tags?.map(tag => <Tag key={tag?.id}>{tag?.name}</Tag>)}</div>
        </div>
      )}
      {dublicate?.contacts?.map(item => (
        <div key={item?.id} className={styles.field_contact}>
          <h3>{item?.name}</h3> {item?.phones?.map((phone, i) => <p key={i}>{phone}</p>)}
        </div>
      ))}
    </div>
  );
};

export default DublicateControllFieldAll;
