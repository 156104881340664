import React from 'react';

const SendIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0581 7.09178L7.92473 3.52511C3.13306 1.12511 1.1664 3.09178 3.5664 7.88345L4.2914 9.33345C4.49973 9.75845 4.49973 10.2501 4.2914 10.6751L3.5664 12.1168C1.1664 16.9084 3.12473 18.8751 7.92473 16.4751L15.0581 12.9084C18.2581 11.3084 18.2581 8.69178 15.0581 7.09178ZM12.3664 10.6251H7.8664C7.52473 10.6251 7.2414 10.3418 7.2414 10.0001C7.2414 9.65845 7.52473 9.37511 7.8664 9.37511H12.3664C12.7081 9.37511 12.9914 9.65845 12.9914 10.0001C12.9914 10.3418 12.7081 10.6251 12.3664 10.6251Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;
