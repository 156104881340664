export enum ReportType {
  EXCEL_SUMMARY = 'EXCEL_SUMMARY',
  WEEKLY_SUMMARY = 'WEEKLY_SUMMARY'
}

export enum PaymentPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH'
}
