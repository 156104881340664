export const loginGenerator = (length: number) => {
  const a = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const b = [];
  for (let i = 0; i < length; i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[Number(j)];
  }
  return b.join('');
};

export const passwordGenerator = (length: number) => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
  const b = [];
  for (let i = 0; i < length; i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[Number(j)];
  }
  return b.join('');
};
