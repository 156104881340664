import { $api } from '@service/RequestService';
import queryString from 'query-string';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';
import { IVariationGroup } from '../schema';

export const useContractVariationGroupCreate = (props?: { onSuccess?: (data?: IVariationGroup) => void }) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: { id?: number | null; data: IVariationGroup }) => {
      if (data?.id) {
        const res = await $api.patch(
          `/v1/contract/variation-group/${data?.id}${queryString.stringifyUrl({
            url: '',
            query: {
              name: data.data?.name,
              groupId: data.data?.groupId
            }
          })}`
        );

        return res.data.data;
      }
      const res = await $api.post(
        `/v1/contract/variation-group${queryString.stringifyUrl({
          url: '',
          query: {
            name: data.data?.name,
            groupId: data.data?.groupId
          }
        })}`
      );

      return res.data.data;
    },
    onSuccess: data => {
      qc.invalidateQueries([queryKeys.CONTRACT_VARIATION_GROUP]);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onSuccess && props?.onSuccess(data);
    }
  });
};
