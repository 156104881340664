import React from 'react';

const DashboardVidget4 = () => {
  return (
    <svg width="88" height="111" viewBox="0 0 88 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 12C0.75 5.64873 5.89873 0.5 12.25 0.5H75.75C82.1013 0.5 87.25 5.64873 87.25 12V69C87.25 91.9198 68.6698 110.5 45.75 110.5H12.25C5.89872 110.5 0.75 105.351 0.75 99V12Z"
        stroke="#00A389"
      />
      <path
        d="M6.25 14C6.25 9.58172 9.83172 6 14.25 6H73.75C78.1683 6 81.75 9.58172 81.75 14V65C81.75 87.0914 63.8414 105 41.75 105H14.25C9.83172 105 6.25 101.418 6.25 97V14Z"
        fill="#E7F9F6"
      />
      <path d="M22.25 26H65.75" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
      <path d="M26 43H37" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M41 43H47" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M51 43H61" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M26.25 50H47.25" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M50 50L61 50" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M26.25 61.5H39.25" stroke="#5F8E87" strokeWidth="2" strokeLinecap="round" />
      <path d="M26.25 67.5H36.25" stroke="#5F8E87" strokeWidth="2" strokeLinecap="round" />
      <path d="M26 56H40" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M44 56H58" stroke="#00A389" strokeWidth="2" strokeLinecap="round" />
      <path d="M22.25 85H45.25" stroke="#00A389" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="5 5" />
    </svg>
  );
};

export default DashboardVidget4;
