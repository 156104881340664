import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import navBarLinks from './navBarLinks';

import styles from './navBar.module.scss';

const NavBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.navbar}>
      {navBarLinks?.map((item, index) => (
        <NavLink key={index} to={item?.path} end>
          <item.icon /> {t(`boss_crm.${item.name}`)}
        </NavLink>
      ))}
    </div>
  );
};

export default NavBar;
