import React from 'react';

const PdfIcon = () => (
  <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5958 10.3942L30.5948 3.80798V6.68464C30.5948 8.74961 32.2414 10.3942 34.2367 10.3942H37.5958ZM28.0948 2.5V6.68464C28.0948 10.0979 30.8285 12.8942 34.2367 12.8942H39.5V36.1186C39.5 36.914 38.873 37.5 38.1715 37.5H13.9142C13.214 37.5 12.5878 36.9162 12.5857 36.1224L12.5 3.88522C12.4979 3.0882 13.1257 2.5 13.8285 2.5H28.0948ZM30.4468 0.431267C30.4967 0.457321 30.546 0.4845 30.5948 0.512789C30.8459 0.658514 31.0817 0.833688 31.297 1.03622L40.7756 9.9533C41.5564 10.6878 42 11.7187 42 12.7985V36.1186C42 38.2622 40.2859 40 38.1715 40H13.9142C11.8038 40 10.0914 38.2686 10.0857 36.1291L10 3.89187C9.9943 1.74415 11.71 0 13.8285 0H28.6929C29.3081 0 29.9088 0.150146 30.4468 0.431267Z"
      fill="#9AA4B2"
    />
    <rect y="17" width="26" height="16" rx="2" fill="#D92D20" />
    <path
      d="M3.8323 29V21.7273H6.70162C7.25323 21.7273 7.72316 21.8326 8.11142 22.0433C8.49967 22.2517 8.7956 22.5417 8.9992 22.9134C9.20517 23.2827 9.30815 23.7088 9.30815 24.1918C9.30815 24.6747 9.20398 25.1009 8.99565 25.4702C8.78732 25.8395 8.48547 26.1271 8.09011 26.3331C7.69712 26.5391 7.22127 26.642 6.66255 26.642H4.83372V25.4098H6.41397C6.7099 25.4098 6.95375 25.3589 7.14551 25.2571C7.33964 25.1529 7.48405 25.0097 7.57875 24.8274C7.67581 24.6428 7.72434 24.4309 7.72434 24.1918C7.72434 23.9503 7.67581 23.7396 7.57875 23.5597C7.48405 23.3774 7.33964 23.2365 7.14551 23.1371C6.95138 23.0353 6.70517 22.9844 6.40687 22.9844H5.36994V29H3.8323ZM12.885 29H10.3069V21.7273H12.9063C13.6379 21.7273 14.2676 21.8729 14.7955 22.1641C15.3235 22.4529 15.7295 22.8684 16.0136 23.4105C16.3 23.9527 16.4433 24.6013 16.4433 25.3565C16.4433 26.1141 16.3 26.7652 16.0136 27.3097C15.7295 27.8542 15.3211 28.272 14.7884 28.5632C14.2581 28.8544 13.6237 29 12.885 29ZM11.8445 27.6825H12.8211C13.2757 27.6825 13.658 27.602 13.9681 27.4411C14.2806 27.2777 14.515 27.0256 14.6713 26.6847C14.8299 26.3414 14.9092 25.8987 14.9092 25.3565C14.9092 24.8191 14.8299 24.38 14.6713 24.0391C14.515 23.6982 14.2818 23.4472 13.9717 23.2862C13.6615 23.1252 13.2792 23.0447 12.8247 23.0447H11.8445V27.6825ZM17.5823 29V21.7273H22.3976V22.995H19.1199V24.728H22.078V25.9957H19.1199V29H17.5823Z"
      fill="white"
    />
  </svg>
);

export default PdfIcon;
