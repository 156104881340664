import { RemoteCurrency } from '../models/Currency';

export const currencyUZS: RemoteCurrency = {
  id: -1,
  Code: '',
  Ccy: 'UZS',
  CcyNm_RU: 'Узбекский сум',
  CcyNm_UZ: "O'zbekiston so'mi",
  CcyNm_UZC: 'Ўзбекистон сўми',
  CcyNm_EN: 'Uzbek soum',
  CcyNm_TR: 'Uzbek soum',
  Nominal: '1',
  Rate: '1',
  Diff: '',
  Date: ''
};
