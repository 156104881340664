import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import AddSquareBackground from '../../assets/icons/AddSquareBackground';
import TableSettingsMine from '../../components/filters/TableSettingMine';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';
import EmployeeFilter from '../../components/settingEmployee/EmployeeFilter';
import SettingEmployeeModal from '../../components/settingEmployee/SettingEmployeeModal';
import TableEmployee from '../../components/settingEmployee/TableEmployee';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useEmployeeList, useTableConfig } from '../../queries/queries';
import { TableKey } from '../../utils/models/TableKey';

import styles from './settingEmployee.module.scss';

const { Content } = Layout;

export interface SearchParamsEmployee {
  page?: string;
  size?: string;
  roles?: string[];
  statuses?: string[];
  search?: string;
}

const SettingEmployee = () => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsEmployee, any>();

  const { data, isLoading, isFetching } = useEmployeeList(Number(searchParams?.size), searchParams);
  const [modalVisible, setModalVisible] = useState({ visible: false, id: null });

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.employee);

  useEffect(() => {
    if (tableConfig?.size) {
      appendMultipleDifferent(['page', 'size'], [searchParams?.page || 1, tableConfig?.size]);
    }
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableConfig?.size || 10]);
  };

  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className="setting_employee_page">
          <div className="d-flex align-center justify-space-between">
            <EmployeeFilter />
            <TableSettingsMine size="large" tableKey={TableKey.employee} />
            <div
              className={styles.settingEmployeeAdd}
              onClick={() => {
                setModalVisible({ visible: true, id: null });
              }}
            >
              <AddSquareBackground />
            </div>
          </div>
          <TableEmployee
            data={data?.data || []}
            isLoading={isLoading}
            tableConfig={tableConfig?.config || []}
            tableConfigFetch={tableConfigFetch}
            isFetching={isFetching}
            setModalVisible={setModalVisible}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={Number(searchParams?.page) || 1}
            defaultPageSize={Number(searchParams?.size) || 10}
          />
          <SettingEmployeeModal id={modalVisible.id} visible={modalVisible.visible} setVisible={setModalVisible} />
        </div>
      </Content>
    </>
  );
};

export default SettingEmployee;
