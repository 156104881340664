import React from 'react';
import { useGetAllBuildings } from '@queries/queries';
import { Button, Form, Input, Spin, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import { useCashCreate } from '../../../queries/mutation';
import { Cash, SystemTypeCash } from '../../../utils/models/Cash';

import { CashModel } from './CashModel';

import logo from '../../../assets/img/PaymeLogo.png';

import styles from '../paymentSettings.module.scss';

interface Props {
  data: Cash | undefined;
}

const LastPayme: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const createPaymeCash = useCashCreate();
  const { data: buildingAll } = useGetAllBuildings();

  const tree = buildingAll?.map(item => ({
    title: item?.houseName,
    value: `0-${item?.houseId}`,
    key: `0-${item?.houseId}`,
    children: item?.buildingCompactDto?.map((prev, i) => ({
      title: prev?.name,
      value: `1-${prev?.id}`,
      key: `1-${prev?.id}`
    }))
  }));

  const tProps = {
    treeData: tree,
    treeCheckable: true,
    placeholder: `${t('boss_crm.Manbaalarni_tanlang')}...`,
    style: {
      width: 298
    }
  };

  const onFinish = (values: CashModel) => {
    createPaymeCash.mutate({
      cashId: values.cashId?.trim(),
      cashKey: values.cashKey?.trim(),
      systemType: SystemTypeCash.PAYME,
      buildingIds: values?.buildingIds?.map(item => Number(item?.toString().replace('1-', '')))
    });
  };

  return (
    <Spin spinning={createPaymeCash.isLoading}>
      <div className={`d-flex align-center ${styles.margin_bottom}`}>
        <div className={styles.logo}>
          <img src={logo} alt="Payme" />
        </div>
        <div className={styles.cont}>
          <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="basic" initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
            <div className="d-flex align-center">
              <div className={styles.cont_inputCont}>
                <Form.Item label={<label className={styles.label}>{t('paymeCash.Kassa_ID')}</label>} rules={[{ required: true, message: '' }]} name="cashId">
                  <Input disabled={!!data} className={styles.input} />
                </Form.Item>
              </div>
              <div className={styles.cont_inputCont}>
                <Form.Item label={<label className={styles.label}>{t('paymeCash.Kassa_kaliti')}</label>} rules={[{ required: true, message: '', min: 4 }]} name="cashKey">
                  <Input disabled={!!data} className={styles.input} />
                </Form.Item>
              </div>
              <div className={styles.cont_inputCont}>
                <Form.Item label={<label className={styles.label}>{t('home.obyekt')}</label>} rules={[{ required: true, message: '' }]} name="buildingIds">
                  <TreeSelect {...tProps} maxTagCount="responsive" disabled={!!data} />
                </Form.Item>
              </div>
              {!data && (
                <div className={styles.cont_button_cont}>
                  <Button loading={createPaymeCash.isLoading} htmlType="submit" className={styles.cont_button_cont_button} type="primary">
                    {t('paymeCash.Saqlash')}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default LastPayme;
