import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './clientBalance.module.scss';
import { useBasicCurrency } from '../../queries/queries';
import { ClientBalanceItem } from '../../utils/models/Client';

interface Props {
  balances: ClientBalanceItem[];
}

const BalanceInfo: React.FC<Props> = ({ balances }) => {
  const { t } = useTranslation();
  const { data } = useBasicCurrency();

  //findBasicCurrency
  const findBasicCurrency = () => {
    const findCurrency = balances.find(item => item.currencyDto.id === data?.id);
    if (findCurrency) {
      return (
        <div className={styles.balance_card_values_amount}>
          {findCurrency.amount?.toLocaleString('ru')} {findCurrency.currencyDto.ccy}
        </div>
      );
    }
    return <div className={styles.balance_card_values_amount}>0 {data?.ccy}</div>;
  };

  //findBasicCurrency
  const findNotBasicCurrency = () => {
    const findCurrency = balances.find(item => item.currencyDto.id !== data?.id);
    if (findCurrency) {
      return (
        <div className={styles.balance_card_values_amount}>
          {findCurrency.amount?.toLocaleString('ru')} {findCurrency.currencyDto.ccy}
        </div>
      );
    }
    return <div className={styles.balance_card_values_amount}>0</div>;
  };

  return (
    <div className={styles.balance_card}>
      <div className={styles.balance_card_title}>{t('home.Mijoz_balansi')}</div>
      <div className={styles.balance_card_values}>
        <p className={styles.balance_card_values_title}>{t('create_payment.Asosiy_balans')}</p>
        {findBasicCurrency()}
      </div>
      <div className={styles.balance_card_values}>
        <p className={styles.balance_card_values_title}>{t('create_payment.Qoshimcha_balans')}</p>
        {findNotBasicCurrency()}
      </div>
    </div>
  );
};

export default BalanceInfo;
