import { Collapse, Form, FormInstance, Input, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { helper } from '../../utils/helper/helper';
import { PaymentType } from '../../utils/models/PaymentType';
import { FormIPaymentCreate } from './PaymentCreateModal';
import InputMask from 'react-input-mask';
import CalendarIcon from '../../assets/icons/CalendarIcon';
import { validateDateString } from '../../utils/helper/validatorDateString';
import styles from './createPayment.module.scss';
import SelectIcon from '../../assets/icons/SelectIcon';
import { usePaymentConfig } from '../../queries/queries';

interface Props {
  form: FormInstance<FormIPaymentCreate>;
  residue: number;
}

const { Option } = Select;

const SimplePaymentInputs: React.FC<Props> = ({ form, residue }) => {
  const { data, isLoading } = usePaymentConfig();

  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        label={
          <p
            style={{
              color: 'var(--philippine-gray)',
              fontSize: '14px'
            }}
          >
            {/* {t('create_payment.Tolov_raqami')} */}
          </p>
        }
      >
        <Form.Item
          noStyle
          name={'number'}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <Input className="customInput" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={
          <p
            style={{
              color: 'var(--philippine-gray)',
              fontSize: '14px'
            }}
          >
            {t("create_payment.To'lov_summasi")}
          </p>
        }
      >
        <Form.Item
          noStyle
          name={'amount'}
          rules={[
            {
              required: true,
              message: ''
            },
            {
              validator: (rule, value) => {
                if (value === undefined || value === null) {
                  return Promise.reject('');
                }
                const amount = helper.deleteNotNumbersAndParseFloatNumber(value);
                if (amount && amount.number > residue) {
                  form.setFieldsValue({
                    amount: amount.string
                  });
                  return Promise.reject('');
                }
                form.setFieldsValue({
                  amount: amount.string
                });
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input className="customInput" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={
          <p
            style={{
              color: 'var(--philippine-gray)',
              fontSize: '14px'
            }}
          >
            {t("create_payment.To'lov_turi")}
          </p>
        }
      >
        <Form.Item
          noStyle
          name={'type'}
          rules={[
            {
              required: true,
              message: ''
            }
          ]}
        >
          <Select loading={isLoading} style={{ border: '6px' }} className="customSelect">
            {data?.types.map((item, index) => (
              <Option key={index} value={PaymentType[item]}>
                {t(`home.${PaymentType[item]}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>
      <Collapse
        bordered={false}
        expandIconPosition={'left'}
        className={styles.date_input}
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s'
            }}
          >
            <SelectIcon />
          </span>
        )}
      >
        <Collapse.Panel
          header={
            <p
              style={{
                color: 'var(--philippine-gray)',
                fontSize: '14px'
              }}
            >
              {t('home.Sana')}
            </p>
          }
          key="1"
        >
          <Form.Item>
            <Form.Item
              noStyle
              name={'date'}
              rules={[
                { message: '', len: 10 },
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        date: validateDateString(value, false)
                      });
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <InputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar={''}
              >
                {(inputProps: any) => (
                  <Input className="customInput" placeholder={t('client_create_contract.kk_oo_yyyy')} suffix={<CalendarIcon />} {...inputProps} />
                )}
              </InputMask>
            </Form.Item>
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default SimplePaymentInputs;
