import { createContext } from 'react';

import { ITermDiscount, IVariationGroup } from '../schema';
import { ActionType } from '../schema/action';

export type ActionObj = {
  PAYMENT_TYPE_CREATE: undefined;
  PAYMENT_TYPE_UPDATE?: IVariationGroup;
  PAYMENT_TYPE_DELETE: number | undefined | null;
  TERM_DISCOUNT_CREATE: undefined;
  TERM_DISCOUNT_UPDATE: ITermDiscount;
};

interface IQuery {
  groupId?: number | null;
  name?: string | null;
}

export const defaultQuery: IQuery = {
  groupId: undefined,
  name: undefined
};

export interface Methods {
  setAction: <T extends ActionType>(key: T, data?: ActionObj[T]) => void;
  getActionData?: ActionObj;
  closeAction: () => void;
  setQuery: (value: IQuery) => void;
}

export interface IContext extends Methods {
  query?: IQuery;
  action?: ActionType;
}

const Context = createContext<IContext>({} as IContext);

export default Context;
