import React, { useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useChangeStatusArgumentKpi } from '../../../queries/mutation';
import { ArgumentKpi, KpiItem } from '../../../utils/models/Kpi';
import AddItem from '../AddItem';

import AddModalPercent from './AddModalPercent';
import PercentItem from './PercentItem';
import SimplePercent from './SimplePercent';

import styles from '../../../pages/kpi/kpi.module.scss';

interface Props {
  kpi?: KpiItem;
}

const Percent: React.FC<Props> = ({ kpi }) => {
  const [openAddModal, setOpenAddModal] = useState(false);

  const changeArgumentKpi = useChangeStatusArgumentKpi(kpi?.id || -1);

  const { t } = useTranslation();

  const handleAddModal = (visible: boolean) => {
    setOpenAddModal(visible);
  };

  const getClassNameActive = (type: ArgumentKpi) => {
    if (kpi?.argumentKpi === type) {
      return styles.cont_body_right_buttons_active;
    }
    return styles.cont_body_right_buttons_not_active;
  };

  const handleChangeStatus = (type: ArgumentKpi) => {
    changeArgumentKpi.mutate(type);
  };

  return (
    <Spin spinning={changeArgumentKpi.isLoading}>
      <div className={styles.cont_body_right} style={{}}>
        <p>{t('kpi.Mukofot_hisoblash_asosini_tanlang')}</p>
        <div className={styles.cont_body_right_buttons}>
          <div onClick={() => handleChangeStatus(ArgumentKpi.CONTRACT_PAYMENT_AMOUNT)} className={`${getClassNameActive(ArgumentKpi.CONTRACT_PAYMENT_AMOUNT)} px-[10px] py-[2px]`}>
            <span />
            <p>{t('kpi.Shartnoma_summasi')}</p>
          </div>
          <div onClick={() => handleChangeStatus(ArgumentKpi.PRE_PAYMENT_AMOUNT)} className={`${getClassNameActive(ArgumentKpi.PRE_PAYMENT_AMOUNT)}`}>
            <span />
            <p>{t('kpi.Boshlangich_tolov_summasi')}</p>
          </div>
          <div onClick={() => handleChangeStatus(ArgumentKpi.CONTRACT_AMOUNT_WITH_DISCOUNT)} className={`${getClassNameActive(ArgumentKpi.CONTRACT_AMOUNT_WITH_DISCOUNT)}`}>
            <span />
            <p>{t('kpi.Shartnoma_chegirmali_summasi')}</p>
          </div>
        </div>
        <p>{t('kpi.Mukofot_hisoblash_formulalari')}</p>
        <SimplePercent kpiPart={kpi?.costKpi} kpi={kpi} />
        {kpi?.kpiPartList.map((kpiPart, index) => <PercentItem kpiPart={kpiPart} key={index} />)}
        <AddItem openModal={handleAddModal} />
        <AddModalPercent kpiId={kpi?.id || -1} visible={openAddModal} onClose={handleAddModal} />
      </div>
    </Spin>
  );
};

export default Percent;
