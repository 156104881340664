import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { StatLeadDateDto } from '../models/StatLead';

export const dateFormatData = (
  key: string,
  data: StatLeadDateDto[] | undefined,
  date: {
    startDate: string;
    finishDate: string;
  }
) => {
  const dataConvert: { [key: string]: StatLeadDateDto[] } = {
    DAY: Array.from({
      length: dayjs(date.startDate, DATE_FORMAT).endOf('month').date()
    }).map((item, index) => ({
      date: dayjs(date.startDate, DATE_FORMAT)
        .date(index + 1)
        .format(DATE_FORMAT),
      countLead: 0,
      countSuccessLead: 0,
      countUnsuccessLead: 0
    })),
    MONTH: Array.from({ length: 12 }).map((item, index) => ({
      date: dayjs(date.startDate, DATE_FORMAT).date(1).month(index).format(DATE_FORMAT),
      countLead: 0,
      countSuccessLead: 0,
      countUnsuccessLead: 0
    })),
    YEAR: Array.from({ length: 5 }).map((item, index) => ({
      date: dayjs(date.finishDate, DATE_FORMAT)
        .date(1)
        .month(0)
        .add(index + 1 - 5, 'year')
        .format(DATE_FORMAT),
      countLead: 0,
      countSuccessLead: 0,
      countUnsuccessLead: 0
    }))
  };

  return dataConvert[key].map(item => {
    if (data) {
      const findData = data?.find(value => value?.date === item?.date)!;
      return {
        date: item?.date,
        countLead: findData?.countLead || 0,
        countSuccessLead: findData?.countSuccessLead || 0,
        countUnsuccessLead: findData?.countUnsuccessLead || 0
      };
    } else {
      return item;
    }
  });
};
