import React from 'react';

const LidIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 0.5V15H15M15 8V0.5M7.5 8L15 0.5M15 0.5H7.5" stroke="black" />
    </svg>
  );
};

export default LidIcon;
