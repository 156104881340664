import { InviteClientModel } from '@pages/oneLid/utils/models/InviteClientModel';

import { BaseEntity } from './BaseEntity';
import { BaseLanguageEntity } from './BaseLanguageEntity';
import { EmployeeStatus } from './EmployeeStatus';
import { PipeStatuses } from './PipeAll';

export interface FieldValue {
  id: number;
  value?: string;
  valueType?: string;
  valueList?: string[];
}

export enum TypeContact {
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM',
  FACEBOOK = 'FACEBOOK'
}

export interface ContactCrm {
  id: number | null;
  name: string;
  avatar?: string;
  fields: FieldValue[];
  username?: string;
  avatarId?: number;
  type?: TypeContact;
  accountId?: string;
  phones: string[];
}

export interface OneLid {
  id: number;
  name: string;
  balance: number;
  responsibleById: undefined | number;
  responsibleBy?: string;
  createdDate?: string;
  pipeStatusResponse: PipeStatuses;
  tags: BaseEntity[];
  fields: FieldValue[];
  contacts: ContactCrm[];
  isMoiZvonki: boolean;
  deletedBy?: string;
  deletedDate?: string;
  client: InviteClientModel;
  uuid?: string;
}

export interface CrmEmployee {
  id: number;
  fullName: string;
  role?: BaseLanguageEntity;
  phone?: string;
  expiry?: null | string;
  status?: EmployeeStatus;
  avatar: null;
  company?: string;
  haveAccess?: boolean;
}
