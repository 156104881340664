import { Input } from 'global';
import { useTranslation } from 'react-i18next';

import { useAction } from 'modules/event-list';

import ChangeStatus from '../../change-status/ChangeStatus';

import EmployeeSelect from './EmployeeSelect';
import MultiSelects from './MultiSelects';

const PreviousCurrent = () => {
  const { t } = useTranslation();

  const { query, setQuery } = useAction();

  const checkDisabled = () => {
    if (query?.actionTypes?.includes('DELETE_LEAD') || query?.actionTypes?.includes('CREATE_LED') || query?.actionTypes?.includes('RECOVERY_LEAD')) {
      return true;
    }
    return false;
  };

  const obj = {
    'CHANGE_LEAD_EMPLOYEE,SET_LEAD_EMPLOYEE': (
      <>
        <EmployeeSelect placeholder={t('home.Oldingi_qiymat')} value={query?.previous} onChange={value => setQuery({ previous: value })} />
        <EmployeeSelect placeholder={t('home.Keyingi_qiymat')} value={query?.current} onChange={value => setQuery({ current: value })} />
      </>
    ),
    'CHANGE_STATUS,CREATE_STATUS': <ChangeStatus />,
    'CREATE_TAG,REMOVE_TAG':
      <MultiSelects
      valuePrevious={query?.previousTags}
      valueCurrent={query?.currentTags}
      onChangeCurrent={value => setQuery({ currentTags: value })}
        onChangePrevious={value => setQuery({ previousTags: value })}
      />
  };

  const getActionType = () => {
    const a = Object.keys(obj)?.find(key => {
      const v = key.split(',');

      return v.length >= (query?.actionTypes?.length || 1) && query?.actionTypes?.every(item => v.includes(item));
    });

    return a ? (
      obj[a as keyof typeof obj]
    ) : (
      <>
        <Input
          disabled={checkDisabled()}
          placeholder={t('home.Oldingi_qiymat')}
          value={query?.previous || ''}
          onChange={value => {
            setQuery({ previous: value });
          }}
        />
        <Input disabled={checkDisabled()} placeholder={t('home.Keyingi_qiymat')} value={query?.current || ''} onChange={value => setQuery({ current: value })} />
      </>
    );
  };

  return <div className="flex flex-col gap-3">{getActionType()}</div>;
};

export default PreviousCurrent;
