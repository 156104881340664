import React, { useState } from 'react';
import styles from './amoCrm.module.scss';
import { Button, Spin } from 'antd';
import Empty from './Empty';
import AddCrmModal from './AddCrmModal';
import ExtraInformation from './ExtraInformation';
import PremiumModal from './PremiumModal';
import { useTranslation } from 'react-i18next';
import { useGetAmoCrmConfig } from '../../../queries/queries';
import SettingHexagonIcon from '../../../assets/icons/SettingHexagonIcon';
import FlashIcon from '../../../assets/icons/FlashIcon';
import FlashSlash from '../../../assets/icons/FlashSlash';
import { useChangeStatusAmoCrm } from '../../../queries/mutation';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Permissions } from '../../../utils/constants/permissions';

const AmoCrm = () => {
  const [openModalCrm, setOpenModalCrm] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);

  const { t } = useTranslation();

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessAmoCrm = permissions?.includes(Permissions.PERMISSION_SETTINGS_AMOCRM);

  const { isLoading, data, isFetching } = useGetAmoCrmConfig(!!isAccessAmoCrm);

  const changeStatus = useChangeStatusAmoCrm();

  const handleChangeStatus = (status: boolean) => {
    changeStatus.mutate(status);
  };

  const handleModalCrm = (isVisible: boolean) => {
    setOpenModalCrm(isVisible);
  };

  const handlePremiumModal = (isVisible: boolean) => {
    setPremiumModal(isVisible);
  };

  const activePage = () => {
    if (!data?.id || !isAccessAmoCrm) return <Empty />;
    return <ExtraInformation amoCrm={data} />;
  };

  const handleClickButtonCreate = () => {
    if (isAccessAmoCrm) handleModalCrm(true);
    else handlePremiumModal(true);
  };

  const handleButtonsShow = () => {
    if (!data?.id || !isAccessAmoCrm) {
      return (
        <Button onClick={handleClickButtonCreate} className={styles.cont_header_create_button} type="primary">
          {t('integration.Ornatish')}
        </Button>
      );
    }
    if (data.status === 'ACTIVE') {
      return (
        <div className={'d-flex align-center'}>
          <div onClick={() => handleModalCrm(true)} className={styles.cont_header_settings}>
            <SettingHexagonIcon />
            <span>{t('integration.Tahrirlash')}</span>
          </div>
          <Button
            loading={changeStatus.isLoading}
            onClick={() => handleChangeStatus(false)}
            className={styles.cont_header_button_active}
            type={'primary'}
          >
            <FlashIcon />
            {t('integration.Aktiv')}
          </Button>
        </div>
      );
    }
    return (
      <div className={'d-flex align-center'}>
        <div onClick={() => handleModalCrm(true)} className={styles.cont_header_settings}>
          <SettingHexagonIcon />
          <span>{t('integration.Tahrirlash')}</span>
        </div>
        <Button
          loading={changeStatus.isLoading}
          onClick={() => handleChangeStatus(true)}
          type={'primary'}
          danger
          className={styles.cont_header_button}
        >
          <FlashSlash />
          {t('integration.Aktivlashtirish')}
        </Button>
      </div>
    );
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.cont}>
        <div className={styles.cont_header}>
          <div className={styles.cont_header_left}>
            <h1>AmoCRM</h1>
            <h2>{t('integration.AmoCRM_panelidagi_Uysot_vidjeti_orqali_sotuvni_yangi_bosqichga_olib_chiqing')}</h2>
          </div>
          <div>{handleButtonsShow()}</div>
        </div>
        {activePage()}
        <AddCrmModal data={data} visible={openModalCrm} onClose={handleModalCrm} />
        <PremiumModal visible={premiumModal} onClose={handlePremiumModal} />
      </div>
    </Spin>
  );
};

export default AmoCrm;
