import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../utils/constants/format';
import { BoosComplexSelectType } from '../../utils/models/BoosComplexSelectType';
import { LeadStatus } from '../../utils/models/LeadStatus';
import { PipeAll } from '../../utils/models/PipeAll';

interface CrmStatistic {
  startDate: string;
  finishData: string;
  startDatePipe: string;
  finishDatePipe: string;
  planFactId: number;
  costTypeLead: boolean;
  selectedPipe: PipeAll | undefined;
  leadStatus: LeadStatus;
  employeeId: number | undefined;
  finish1?: string;
  start1?: string;
  finish2?: string;
  start2?: string;
  finish3?: string;
  start3?: string;
  defaulGlobaltValue?: string;
  defaultValue?: string;
  defaultValue1?: string;
  defaultValue2?: string;
  nextMonth?: string;
  unSuccessfulLeadModal: {
    visible: boolean;
    reasonsFRId?: number;
    responsibleById?: number;
    type: 'REASON' | 'EMPLOYEE';
  };
  successfulLeadModal: {
    page: number;
    search?: string;
    visible: boolean;
    responsibleById?: number;
  };
  planFact: {
    startDate?: string;
    finishDate?: string;
    pipeId?: number;
    formatDate?: string;
    visibleCustomDateModal?: boolean;
    planFactConfig: {
      id?: number;
      visible: boolean;
      isLoading?: boolean;
    };
  };
}

const initialState: CrmStatistic = {
  finishData: dayjs(new Date()).endOf('d').format(DATE_FORMAT),
  startDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT),
  finishDatePipe: dayjs(new Date()).endOf('month').format(DATE_FORMAT),
  startDatePipe: dayjs(new Date()).startOf('month').format(DATE_FORMAT),
  planFactId: 0,
  costTypeLead: false,
  selectedPipe: undefined,
  leadStatus: LeadStatus.ALL,
  employeeId: undefined,
  finish1: String(dayjs(new Date()).endOf('d').format(DATE_WITH_TIME_FORMAT)),
  start1: String(dayjs(new Date()).startOf('month').format(DATE_WITH_TIME_FORMAT)),
  finish2: String(dayjs(new Date()).endOf('d').unix()),
  start2: String(dayjs(new Date()).startOf('month').unix()),
  finish3: String(dayjs(new Date()).add(6, 'month').unix()),
  start3: String(dayjs(new Date()).add(-6, 'month').unix()),
  defaulGlobaltValue: BoosComplexSelectType.THIS_MONTH,
  defaultValue: BoosComplexSelectType.THIS_MONTH,
  defaultValue1: BoosComplexSelectType.THIS_MONTH,
  defaultValue2: BoosComplexSelectType.THIS_MONTH,
  nextMonth: String(dayjs(new Date()).add(1, 'month').startOf('month').format(DATE_FORMAT)),
  unSuccessfulLeadModal: {
    visible: false,
    type: 'REASON'
  },
  successfulLeadModal: {
    visible: false,
    page: 1
  },
  planFact: {
    planFactConfig: {
      visible: false
    }
  }
};

export const houseSlice = createSlice({
  name: 'house',
  initialState,
  reducers: {
    setDate: (
      state,
      action: PayloadAction<{
        startDate: string;
        finishData: string;
      }>
    ) => {
      state.startDate = action.payload.startDate;
      state.finishData = action.payload.finishData;
    },
    setDate1: (state, action: PayloadAction<{ startDate: string; finishDate: string }>) => {
      state.start1 = action.payload.startDate;
      state.finish1 = action.payload.finishDate;
    },
    setDate3: (state, action: PayloadAction<{ startDate: string; finishData: string }>) => {
      state.start2 = action.payload.startDate;
      state.finish2 = action.payload.finishData;
    },
    setDate4: (state, action: PayloadAction<{ startDate: string; finishData: string }>) => {
      state.start3 = action.payload.startDate;
      state.finish3 = action.payload.finishData;
    },
    setDatePipe: (
      state,
      action: PayloadAction<{
        startDate: string;
        finishData: string;
      }>
    ) => {
      state.startDatePipe = action.payload.startDate;
      state.finishDatePipe = action.payload.finishData;
    },
    setPlanFactId: (state, action: PayloadAction<number>) => {
      state.planFactId = action.payload;
    },
    setCostTypeLead: (state, action: PayloadAction<boolean>) => {
      state.costTypeLead = action.payload;
    },
    setPipe: (state, action: PayloadAction<PipeAll | undefined>) => {
      state.selectedPipe = action.payload;
    },
    setLeadStatus: (state, action: PayloadAction<LeadStatus>) => {
      state.leadStatus = action.payload;
    },
    setEmployeeId: (state, action: PayloadAction<number | undefined>) => {
      state.employeeId = action.payload;
    },
    setDefaultData: state => {
      state.finishData = dayjs(new Date()).format(DATE_FORMAT);
      state.startDate = dayjs(new Date()).startOf('month').format(DATE_FORMAT);
      state.finishDatePipe = dayjs(new Date()).format(DATE_FORMAT);
      state.startDatePipe = dayjs(new Date()).startOf('month').format(DATE_FORMAT);
      state.planFactId = 0;
      state.costTypeLead = false;
    },
    setChangeDefaultGlobalValue: (state, action: PayloadAction<string>) => {
      state.defaulGlobaltValue = action.payload;
    },
    setChangeDefaultValue: (state, action: PayloadAction<string>) => {
      state.defaultValue = action.payload;
    },
    setChangeDefaultValue1: (state, action: PayloadAction<string>) => {
      state.defaultValue1 = action.payload;
    },
    setChangeDefaultValue2: (state, action: PayloadAction<string>) => {
      state.defaultValue2 = action.payload;
    },
    setUnSuccessfulLeadsModal: (state, action: PayloadAction<CrmStatistic['unSuccessfulLeadModal']>) => {
      state.unSuccessfulLeadModal.type = action.payload.type;
      state.unSuccessfulLeadModal.visible = action?.payload?.visible;
      state.unSuccessfulLeadModal.reasonsFRId = action?.payload?.reasonsFRId;
      state.unSuccessfulLeadModal.responsibleById = action.payload?.responsibleById;
    },
    setSuccessfulLeadsModal: (state, action: PayloadAction<CrmStatistic['successfulLeadModal']>) => {
      state.successfulLeadModal.page = action.payload.page;
      state.successfulLeadModal.search = action.payload.search;
      state.successfulLeadModal.visible = action.payload.visible;
      state.successfulLeadModal.responsibleById = action.payload.responsibleById;
    },
    setPlanFactDate: (state, action: PayloadAction<{ startDate: string; finishDate: string }>) => {
      state.planFact.startDate = action.payload.startDate;
      state.planFact.finishDate = action.payload.finishDate;
    },
    setPlanFactFormatDate: (state, action: PayloadAction<string>) => {
      state.planFact.formatDate = action.payload;
    },
    setPlanFactPipeId: (state, action: PayloadAction<number>) => {
      state.planFact.pipeId = action.payload;
    },
    setPlanFactVisibleCustomDateModal: (state, action: PayloadAction<boolean>) => {
      state.planFact.visibleCustomDateModal = action.payload;
    },
    setPlanFactConfig: (state, action: PayloadAction<{ id?: number; visible: boolean; isLoading?: boolean }>) => {
      state.planFact.planFactConfig.id = action.payload.id;
      state.planFact.planFactConfig.visible = action.payload.visible;
      state.planFact.planFactConfig.isLoading = action.payload.isLoading;
    }
  }
});

export const {
  setDate,
  setPipe,
  setDate1,
  setDate3,
  setDate4,
  setDatePipe,
  setPlanFactId,
  setLeadStatus,
  setEmployeeId,
  setDefaultData,
  setCostTypeLead,
  setChangeDefaultValue,
  setChangeDefaultValue1,
  setChangeDefaultValue2,
  setSuccessfulLeadsModal,
  setUnSuccessfulLeadsModal,
  setChangeDefaultGlobalValue,
  setPlanFactDate,
  setPlanFactPipeId,
  setPlanFactVisibleCustomDateModal,
  setPlanFactFormatDate,
  setPlanFactConfig
} = houseSlice.actions;
export default houseSlice.reducer;
