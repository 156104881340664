import React, { Component, ReactNode } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import ErrorPageIcon from '@icons/ErrorPageIcon';
import * as Sentry from '@sentry/react';
import { withRouter } from '@utils/helper/withRouter';
import { t } from 'i18next';

import styles from './errorBoundary.module.scss';

interface IProps {
  fallback?: ReactNode;
  children?: ReactNode;
  navigate?: NavigateFunction;
  location?: Location;
}

export class ErrorBoundary extends Component<IProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps?.location?.pathname !== this.props.location?.pathname) {
      this.state.hasError = false;
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.log(error, 'errors');
  }

  render() {
    if (this.state.hasError) {
      return (
        this.props.fallback || (
          <div className={styles.container}>
            <div className={styles.left}>
              <ErrorPageIcon />
            </div>
            <div className={styles.right}>
              <h3>{t('home.Tizimda_xatolik')}!</h3>
              <p>
                {t('home.Xatolik_uchun_uzr_soraymiz')}, {t('home.Iltimos_qayta_urinib_koring')}!
              </p>
              <a href="https://t.me/uysot_admin" target="_blank" rel="noreferrer">
                <small> ▶</small> {t('home.Bizga_xatolik_haqida_habar_bering')}
              </a>
            </div>
          </div>
        )
      );
    }
    return this.props.children;
  }
}

export default Sentry.withErrorBoundary(withRouter(ErrorBoundary), {});
