import { BaseLanguageEntity } from './BaseLanguageEntity';

export interface EmployeeList {
  id: number;
  fullName: string;
  role: BaseLanguageEntity;
  filial: string;
  phone: string;
  phoneCode?: string;
  expiry: string;
  status: EmployeeStatus;
  avatar: string | null;
  roleBranches: {
    role: {
      name: string;
      nameUz: string;
      nameRu: string;
      nameEn: string;
      nameKr: string;
      nameTr: string;
      permissions: number[] | string[];
      companyId: 5;
      id: 14;
    };
    branch: null;
  }[];
}

export interface EmployeeId {
  fullName: string;
  phone: string;
  phoneCode: string;
  username: string;
  countryCode: string;
  password?: string;
  districtId?: number;
  address: string;
  status: EmployeeStatus;
  expiry: string;
  cityId: number;
  roleBranches: RoleBranches[];
  roleId: number;
  branchId: number;
  id?: number;
  avatarId?: number;
  avatarPath?: string;
  role?: {
    id: number;
    base: string;
    uz: string;
    ru: string;
    en: string;
    kr: string;
    tr: string;
  };
}

export interface CompanyEmployeeCreate {
  fullName: string;
  phone: string;
  phoneCode?: string;
  username: string;
  password: string;
  districtId: number;
  address: string;
  roleId: number;
  expiry: string;
  roleBranches?: Array<{
    roleId: number;
    branchId?: number | null;
  }> | null;
}

export interface RoleBranches {
  role: {
    name: string;
    nameUz: string;
    nameRu: string;
    nameEn: string;
    nameKr: string;
    nameTr: string;
    permissions: string[];
    companyId: number;
    id: number;
  };
  branch: {
    id: number;
    name: string;
    city: {
      uz: string;
      ru: string;
      en: string;
      kr: string;
      tr: string;
      id: number;
    };
    district: {
      uz: string;
      ru: string;
      en: string;
      kr: string;
      tr: string;
      id: number;
    };
    address: string;
    createdDate: string;
    createdBy: string;
  };
}

export enum EmployeeStatus {
  ENABLED = 'ENABLED',
  LOCKED = 'LOCKED',
  EXPIRED = 'EXPIRED'
}
