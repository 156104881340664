import React, { ChangeEventHandler, useState } from 'react';
import bg from '../../assets/img/bg_new.jpg';
import styles from './changePassword.module.scss';
import PrivacyColicies from '../../components/privacyColicies/PrivacyColicies';
import CloseIcon from '../../assets/icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import PasswordValidationPopover from '../../components/passwordValidationPopover/PasswordValidationPopover';
import { PasswordValidationColor } from '../../utils/models/PasswordValidationColor';
import { useAccountPasswordChange } from '../../queries/mutation';

const ChangePassword = () => {
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    letters: false,
    beginUppercase: false,
    number: false
  });
  const [privacyPolicies, setPrivacyPolicies] = useState(false);
  const account = useAccountPasswordChange();
  const { i18n, t } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  //handlePrivacyPolicies
  const handlePrivacyPolicies = () => {
    setPrivacyPolicies(true);
  };
  //handlePrivacyPoliciesClose
  const handlePrivacyPoliciesClose = () => {
    setPrivacyPolicies(false);
  };

  // title password validation
  const titlePasswordValidation = () => {
    if (passwordValidation.number && passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ajoyib_Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return t('profile.Orta_ishonchli_parol');
    }
    return t('profile.Ishonchsiz_parol');
  };

  // title password validation
  const titlePasswordValidationColor = () => {
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return PasswordValidationColor.strong;
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return PasswordValidationColor.middle;
    }
    return PasswordValidationColor.low;
  };

  // handle password change
  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = e => {
    setPasswordValidation({
      length: e.target.value?.length >= 8,
      beginUppercase: !!e.target.value[0]?.match(/[A-Z]/),
      letters: !!e.target.value?.match(/[A-Za-z]/),
      number: !!e.target.value?.match(/[0-9]/)
    });
  };

  const onFinish = (values: any) => {
    if (values?.password === values?.confirmPassword) {
      account.mutate(values.password);
    }
  };
  return (
    <div className={styles.login}>
      <img src={bg} className={styles.login_login_newYear_bg} alt="bg" />
      <div className={styles.login_container_div}>
        <div className={styles.login_body}>
          <div className={styles.form}>
            <h2>{t('profile.Parolni_yangilash')}</h2>
            <p>{t('profile.Parolingiz_muddati_tugagan_Tizimdan')}.</p>
            <Form
              // form={form}
              name="basic"
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <PasswordValidationPopover
                length={passwordValidation.length}
                letters={passwordValidation.letters}
                beginUppercase={passwordValidation.beginUppercase}
                number={passwordValidation.number}
              >
                <div>
                  <Form.Item
                    label={<span className={styles.form_title}>{t('profile.Yangi_parolni_kiriting')}</span>}
                    name="password"
                    rules={[
                      { required: true, message: '' },
                      {
                        validator: (rule, value) => {
                          if (!!value.match(/[A-Za-z]/) && value[0]?.match(/[A-Z]/) && value.length >= 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        }
                      }
                    ]}
                  >
                    <Input.Password onChange={handlePasswordChange} className={styles.form_input} />
                  </Form.Item>
                  <div className={styles.form_passwordValidation}>
                    <p style={{ color: titlePasswordValidationColor() }} className={styles.form_passwordValidation_title}>
                      {titlePasswordValidation()}
                    </p>
                    <div className={styles.form_passwordValidation_step}>
                      <div
                        style={{
                          background: passwordValidation.length ? PasswordValidationColor.low : undefined
                        }}
                      />
                      <div
                        style={{
                          background: passwordValidation.letters && passwordValidation.length ? PasswordValidationColor.middle : undefined
                        }}
                      />
                      <div
                        style={{
                          background:
                            passwordValidation.beginUppercase && passwordValidation.letters && passwordValidation.length
                              ? PasswordValidationColor.strong
                              : undefined
                        }}
                      />
                      <div
                        style={{
                          background:
                            passwordValidation.beginUppercase && passwordValidation.number && passwordValidation.letters && passwordValidation.length
                              ? PasswordValidationColor.strong
                              : undefined
                        }}
                      />
                    </div>
                  </div>

                  <Form.Item
                    name="confirmPassword"
                    label={<span className={styles.form_title}>{t('profile.Yangi_parolni_qayta_kiriting')}</span>}
                    dependencies={['password']}
                    rules={[
                      {
                        required: true
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        }
                      })
                    ]}
                  >
                    <Input.Password className={styles.form_input} />
                  </Form.Item>
                </div>
              </PasswordValidationPopover>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button loading={account.isLoading} type="primary" htmlType="submit" className={styles.form_button}>
                  {t('home.Saqlash')}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <footer className={styles.footer} onClick={handlePrivacyPolicies}>
            {t('privacy_policies.Yuqoridagi_tugmani_bosish_orqali_siz_bizning')}{' '}
            <span>&nbsp;{t('privacy_policies.foydalanish_shartlari')} &nbsp;</span> {t('privacy_policies.va')}{' '}
            <span> &nbsp;{t('privacy_policies.maxfiylik_siyosatimizga')}</span>
          </footer>
        </div>
        <div
          className={`${styles.privacyPoliciesMask} ${privacyPolicies ? styles.privacyPoliciesMask_active : ''}`}
          onClick={handlePrivacyPoliciesClose}
        />
        <div className={`${styles.privacyPolicies} ${privacyPolicies ? styles.privacyPolicies_active : ''}`}>
          <span className={styles.privacyPolicies_close} onClick={handlePrivacyPoliciesClose}>
            <CloseIcon />
          </span>
          <PrivacyColicies />
        </div>
        <div className={styles.lang}>
          <span
            onClick={() => changeLanguageHandler('en')}
            className={styles.lang_item + (i18n.language === 'en' ? ' ' + styles.lang_item_active : '')}
          >
            English
          </span>
          <span className={styles.lang_dot} />
          <span
            onClick={() => changeLanguageHandler('ru')}
            className={styles.lang_item + (i18n.language === 'ru' ? ' ' + styles.lang_item_active : '')}
          >
            Русский
          </span>
          <span className={styles.lang_dot} />
          <span
            onClick={() => changeLanguageHandler('uz')}
            className={styles.lang_item + (i18n.language === 'uz' ? ' ' + styles.lang_item_active : '')}
          >
            O’zbek
          </span>
          <span className={styles.lang_dot} />
          <span
            onClick={() => changeLanguageHandler('tr')}
            className={styles.lang_item + (i18n.language === 'tr' ? ' ' + styles.lang_item_active : '')}
          >
            Turk
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
