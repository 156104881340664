import { FirstPieChartData } from '@pages/bossCrm/utils/models/FirstPieChartData';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './firstRowStats.module.scss';
type Props = {
  pieChartData: FirstPieChartData;
};
const FirstRowStats: React.FC<Props> = ({ pieChartData }) => {
  const { t } = useTranslation();

  const data = pieChartData
    ? [
        {
          name: t('boss_crm_conversion.Shartnoma_qilingan'),
          value: pieChartData.countSuccess,
          color: 'rgba(255, 183, 98, 1)'
        },
        {
          name: t('boss_crm_conversion.Shartnoma_qilinmagan'),
          value: pieChartData.countFailed,
          color: '#D444F1'
        },
        {
          name: t('import_export.Jarayonda'),
          value: pieChartData.overall! - (pieChartData.countFailed! + pieChartData.countSuccess!),
          color: 'blue'
        }
      ]
    : [];
  return (
    <div className={styles.f_row_stats_container}>
      <div className={styles.title_container}>{t("boss_crm_conversion.Lidlar_bo'yicha_statistika")}</div>
      {data?.map(item => (
        <div className={styles.f_row_legends_container} key={item.color}>
          <div className={styles.legends_item}>
            <span className={styles.legends_item_circle} style={{ border: `3.48px solid ${item.color}` }} />
            {item.name}
          </div>
          <span className={styles.legends_item_value}>{item.value}</span>
        </div>
      ))}
    </div>
  );
};

export default FirstRowStats;
