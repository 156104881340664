import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import EditClientIcon from '@icons/client/EditClientIcon';
import NewFileIcon from '@icons/NewFileIcon';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import DeleteConfirm1 from '@pages/dashboard/components/confirm-modal/DeleteConfirm1';
import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import CheckCircleIcon from '../../../assets/icons/CheckCircleIcon';
import DownloadIcon from '../../../assets/icons/DownloadIcon';
import Trash01Icon from '../../../assets/icons/Trash01Icon';
import { useDeleteDocumentSample, useDownloadFilesByLink, useTestTemplate } from '../../../queries/mutation';
import { BASE_URL } from '../../../service/RequestService';
import { FileTemplate, FileTemplateType } from '../../../utils/models/FileTemplate';

import styles from './templateFile.module.scss';

interface IProps {
  file: FileTemplate;
  type: FileTemplateType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setFileId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const TemplateFile: FC<IProps> = ({ file, type, setVisible, setFileId }) => {
  const { t } = useTranslation();
  const deleteSample = useDeleteDocumentSample(file.id, type);
  const testContract = useTestTemplate(file.id, type);
  const download = useDownloadFilesByLink(`${BASE_URL}/${file.document.absolutePath}`, file.document.originalName.split('.')[0], 'docx');

  // handle download original file
  const handleDownloadOriginalFile = () => {
    download.mutate();
  };

  const handleTest = () => {
    testContract.mutate();
  };

  const handleDelete = () => deleteSample.mutateAsync();

  const handleOpenModal = (id: number) => {
    setVisible(true);
    setFileId(id);
  };

  return (
    <div className={styles.fileItem}>
      <div className={styles.fileItem_left}>
        <NewFileIcon />
        <div className={styles.fileItem_left_text}>
          <h5>{file?.document?.originalName?.length > 30 ? `${file?.document?.originalName?.substring(0, 30)}...` : file?.document?.originalName}</h5>
          <p>
            {Math.floor(file.document.size / 1024)} KB | {file?.document?.format}
          </p>
        </div>
      </div>
      <Dropdown
        overlay={
          <Menu className={styles.dropdownMenu}>
            <Menu.Item onClick={() => handleOpenModal(file?.id)}>
              <div className={styles.menuItem}>
                <span>
                  <EditClientIcon />
                </span>
                <span>{t('client.Tahrirlash')}</span>
              </div>
            </Menu.Item>
            <Menu.Item onClick={handleTest}>
              <div className={styles.menuItem}>
                <span>{testContract.isLoading ? <LoadingOutlined /> : <CheckCircleIcon />}</span>
                <span>{t('company_config.Test_qilish')}</span>
              </div>
            </Menu.Item>
            <Menu.Item onClick={handleDownloadOriginalFile}>
              <div className={styles.menuItem}>
                <span>{download.isLoading ? <LoadingOutlined /> : <DownloadIcon />}</span>
                <span>{t('shaxmatka.Yuklash')}</span>
              </div>
            </Menu.Item>
            <Menu.Item>
              <DeleteConfirm1
                children={
                  <div className={styles.menuItem}>
                    <Trash01Icon />
                    <span>{t('shaxmatka.Ochirish')}</span>
                  </div>
                }
                onConfirm={handleDelete}
                text={`${t('home.Haqiqatdan_ham_ochirmoqchimisiz?')}(${file?.document?.originalName?.length! > 30 ? `${file?.document?.originalName?.substring(0, 30)}...` : file?.document?.originalName})`}
                isLoading={deleteSample.isLoading}
              />
            </Menu.Item>
          </Menu>
        }
        trigger={['click', 'hover']}
        mouseEnterDelay={1000}
        mouseLeaveDelay={0.3}
      >
        <span className={styles.dotsIcon}>
          <DotsIcon />
        </span>
      </Dropdown>
    </div>
  );
};

export default TemplateFile;
