export const colors = [
  '#FFA12F',
  '#FF5722',
  '#F42C2C',
  '#4169E1',
  '#5F81FF',
  '#0AB4FF',
  '#1DB954',
  '#2EA52C',
  '#7B68EE',
  '#757380',
  '#202020',
  '#F8306D',
  '#FF00FC'
];
