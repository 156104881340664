import { Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useMessageTemplate } from '../../queries/queries';
import styles from './../../pages/messageSettings/MessageSettings.module.scss';
import OneMessageTemplate from './OneMessageTemplate';
import { CloseOutlined } from '@ant-design/icons';

function MessageTemplates() {
  const [search, setSearch] = useState('');
  const { data, isLoading } = useMessageTemplate(search);
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const showIconInput = () => {
    if (search) return <CloseOutlined onClick={() => handleSearch('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.messageTemplate}>
      <Spin spinning={isLoading}>
        <Form style={{ width: '321px' }}>
          <Form.Item>
            <Input
              value={search}
              onChange={e => handleSearch(e.target.value)}
              placeholder={t('message_settings.Sozlamani_qidirish')}
              style={{ borderRadius: '6px' }}
              suffix={showIconInput()}
              size="large"
            />
          </Form.Item>
        </Form>

        {data?.map((item, index) => {
          return <OneMessageTemplate item={item} key={index} index={index} />;
        })}
      </Spin>
    </div>
  );
}

export default MessageTemplates;
