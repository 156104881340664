import { DATE_WITH_TIME_FORMAT } from '@utils/constants';
import dayjs from 'dayjs';

export const dateData = () => ({
  '15_daqiqadan_song': {
    text: '15_daqiqadan_song',
    date: dayjs(new Date()).add(15, 'minute'),
    dateFormat: dayjs(new Date()).add(15, 'minute').format('DD.MM.YYYY HH:mm')
  },
  '30_daqiqadan_song': {
    text: '30_daqiqadan_song',
    date: dayjs(new Date()).add(30, 'minute'),
    dateFormat: dayjs(new Date()).add(30, 'minute').format('DD.MM.YYYY HH:mm')
  },
  '1_soatdan_song': {
    text: '1_soatdan_song',
    date: dayjs(new Date()).add(1, 'hour'),
    dateFormat: dayjs(new Date()).add(1, 'hour').format('DD.MM.YYYY HH:mm')
  },
  Bugun: {
    text: 'Bugun',
    date: dayjs(new Date()),
    dateFormat: dayjs(new Date()).format('DD.MM.YYYY HH:mm')
  },
  Ertaga: {
    text: 'Ertaga',
    date: dayjs(new Date()).add(1, 'day'),
    dateFormat: dayjs(new Date()).add(1, 'day').format('DD.MM.YYYY HH:mm')
  },
  Hafta_oxiriga: {
    text: 'Hafta_oxiriga',
    date: dayjs(new Date()).endOf('week'),
    dateFormat: dayjs(new Date()).endOf('week').format('DD.MM.YYYY HH:mm')
  },
  '1_haftadan_song': {
    text: '1_haftadan_song',
    date: dayjs(new Date()).add(1, 'week'),
    dateFormat: dayjs(new Date()).add(1, 'week').format('DD.MM.YYYY HH:mm')
  },
  '1_oydan_song': {
    text: '1_oydan_song',
    date: dayjs(new Date()).add(1, 'month'),
    dateFormat: dayjs(new Date()).add(1, 'month').format('DD.MM.YYYY HH:mm')
  },
  '1_yildan_song': {
    text: '1_yildan_song',
    date: dayjs(new Date()).add(1, 'year'),
    dateFormat: dayjs(new Date()).add(1, 'year').format('DD.MM.YYYY HH:mm')
  }
});

export const dateDataV2 = {
  Kun_boshlanishi: {
    text: 'Kun_boshlanishi',
    date: dayjs().startOf('day'),
    dateFormat: dayjs().startOf('day').format(DATE_WITH_TIME_FORMAT)
  },
  Kun_oxiri: {
    text: 'Kun_oxiri',
    date: dayjs().endOf('day'),
    dateFormat: dayjs().endOf('day').format(DATE_WITH_TIME_FORMAT)
  },
  '1_soatdan_song': {
    text: '1_soatdan_song',
    date: dayjs(new Date()).add(1, 'hour'),
    dateFormat: dayjs(new Date()).add(1, 'hour').format('DD.MM.YYYY HH:mm')
  },
  Bugun: {
    text: 'Bugun',
    date: dayjs(new Date()),
    dateFormat: dayjs(new Date()).format('DD.MM.YYYY HH:mm')
  },
  Ertaga: {
    text: 'Ertaga',
    date: dayjs(new Date()).add(1, 'day'),
    dateFormat: dayjs(new Date()).add(1, 'day').format('DD.MM.YYYY HH:mm')
  },
  Hafta_oxiriga: {
    text: 'Hafta_oxiriga',
    date: dayjs(new Date()).endOf('week'),
    dateFormat: dayjs(new Date()).endOf('week').format('DD.MM.YYYY HH:mm')
  },
  '1_haftadan_song': {
    text: '1_haftadan_song',
    date: dayjs(new Date()).add(1, 'week'),
    dateFormat: dayjs(new Date()).add(1, 'week').format('DD.MM.YYYY HH:mm')
  },
  '1_oydan_song': {
    text: '1_oydan_song',
    date: dayjs(new Date()).add(1, 'month'),
    dateFormat: dayjs(new Date()).add(1, 'month').format('DD.MM.YYYY HH:mm')
  },
  '1_yildan_song': {
    text: '1_yildan_song',
    date: dayjs(new Date()).add(1, 'year'),
    dateFormat: dayjs(new Date()).add(1, 'year').format('DD.MM.YYYY HH:mm')
  }
};

export const dateDataDay = {
  Bugun: {
    text: 'Bugun',
    date: dayjs(new Date()),
    dateFormat: dayjs(new Date()).format('DD.MM.YYYY HH:mm')
  },
  Ertaga: {
    text: 'Ertaga',
    date: dayjs(new Date()).add(1, 'day'),
    dateFormat: dayjs(new Date()).add(1, 'day').format('DD.MM.YYYY HH:mm')
  },
  Kecha: {
    text: 'Kecha',
    date: dayjs(new Date()).add(-1, 'day'),
    dateFormat: dayjs(new Date()).add(-1, 'day').format('DD.MM.YYYY HH:mm')
  }
};
