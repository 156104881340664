import React from 'react';

const CrmCloseIcon = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2812 4.21875L3.71875 13.7812" stroke="#FF6565" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.2812 13.7812L3.71875 4.21875" stroke="#FF6565" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CrmCloseIcon;
