import React, { useEffect } from 'react';
import TrushSquareIcon from '@icons/TrushSquareIcon';
import { Col, Form, Input, Row, Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useCreateUzumConfig } from 'modules/uzum-integration/mutations/use-create-uzum-config';
import { useDeleteUzumConfig } from 'modules/uzum-integration/mutations/use-delete-uzum-config';
import { UzumIntegration } from 'modules/uzum-integration/schema';

import UzumServices from './UzumServices';

type IProps = {
  data: UzumIntegration | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateNewConfig: React.FC<IProps> = ({ data, setOpen }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const createAndUpdateUzumConfig = useCreateUzumConfig();

  const deleteUzumConfig = useDeleteUzumConfig();

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      username: data?.username || undefined
    });
  }, [data]);

  const updateRemotePayment = (value: UzumIntegration) => {
    createAndUpdateUzumConfig.mutate(value);
  };

  const handleDeleteUzumConfig = () => {
    deleteUzumConfig.mutateAsync().then(() => {
      form.resetFields();
      setOpen(false);
    });
  };

  const handleBlurInput = async () => {
    await form.validateFields();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRemotePayment({
      status: form.getFieldsValue().status === null ? false : form.getFieldsValue().status,
      username: form.getFieldsValue().username,
      password: form.getFieldsValue().password
    });
  };

  const handleClickSwitch = async (checked: boolean) => {
    await form.validateFields();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRemotePayment({
      username: form.getFieldsValue().username?.trim(),
      password: form.getFieldsValue().password?.trim(),
      status: checked
    });
  };

  const status = form.getFieldValue('status');

  return (
    <>
      <Spin spinning={createAndUpdateUzumConfig?.isLoading || deleteUzumConfig.isLoading}>
        <Form form={form} layout="vertical" autoComplete="off">
          <Row gutter={24} className="px-4 py-3">
            <Col span={8}>
              <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('home.foydalanuvchi_nomi')}</label>} name="username" rules={[{ required: true, message: '' }]}>
                <Input onBlur={handleBlurInput} className="h-[52px] rounded-md px-3 py-2 text-2xl font-medium" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('integration.Parol')}</label>} name="password" rules={[{ required: true, message: '' }]}>
                <Input onBlur={handleBlurInput} className="h-[52px] rounded-md px-3 py-2 text-2xl" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('paymeCash.Holati')}</label>} name="status" valuePropName="checked">
                <Switch onChange={handleClickSwitch} className="mt-3" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('arrearage.Amallar')}</label>}>
                <div className="mt-2 flex cursor-pointer items-center border-none p-0" onClick={handleDeleteUzumConfig}>
                  <TrushSquareIcon />
                  <span className="text-[#ff3f40]">{t('paymeCash.Ochirish')}</span>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <UzumServices data={data} />
    </>
  );
};

export default CreateNewConfig;
