import React from 'react';
import styles from './orderIdV2.module.scss';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OrderIdV2Info from './components/order-id-v2-info/OrderIdV2Info';
import OrderV2Table from './components/order-id-v2-table/OrderV2Table';
import { useParams } from 'react-router';
import { useOneOrder } from '../../queries/queries';

const OrderIdV2 = () => {
  const params = useParams<{ orderId: string }>();
  const { isLoading, data } = useOneOrder(params.orderId);
  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className={styles.container}>
        <OrderIdV2Info data={data} isLoading={isLoading} />
        <OrderV2Table data={data} isLoading={isLoading} />
      </div>
    </>
  );
};

export default OrderIdV2;
