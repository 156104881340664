import React from 'react';
import TrashIcon from '@icons/TrashIcon';
import { useDeleteRole } from '@pages/crm-settings/services/mutation';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from '../task-modal/taskModal.module.scss';

type Props = {
  openDeleteRole: boolean;
  setOpenDeleteRole: React.Dispatch<React.SetStateAction<boolean>>;
  roleId: number | undefined;
};

const DeleteRoleModal: React.FC<Props> = ({ openDeleteRole, setOpenDeleteRole, roleId }) => {
  const { t } = useTranslation();

  const deleteRole = useDeleteRole(roleId);

  // handle close
  const handleClose = () => {
    setOpenDeleteRole(false);
  };

  const handleDeleteRole = () => {
    deleteRole.mutateAsync().then(handleClose);
  };

  return (
    <Modal
      visible={openDeleteRole}
      onCancel={handleClose}
      footer={
        <div className={styles.deleteFooter}>
          <Button onClick={handleClose}>{t('one_lid.Bekor_qilish')}</Button>
          <Button loading={deleteRole.isLoading} onClick={handleDeleteRole}>
            {t('crm.Ochirish')}
          </Button>
        </div>
      }
      className={styles.deleteModal}
    >
      <div className={styles.deleteBody}>
        <TrashIcon />
        <span>{t('home.Haqiqatdan_ham_ochirmoqchimisiz?')}</span>
      </div>
    </Modal>
  );
};

export default DeleteRoleModal;
