import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import DeleteIconV3 from '@icons/DeleteIconV3';
import { useSavePlanFactPipe } from '@pages/bossCrm/services/mutate';
import { useGetPlanFactConfig, useGetPlanFactPipes } from '@pages/bossCrm/services/queries';
import { PlanFactConfigFormModel } from '@pages/bossCrm/utils/models/plan-fact-config-payload-model';
import { setPlanFactConfig } from '@reducers/CrmStatisticsSlice';
import { parseLocaleString } from '@utils/helper/parse-locale-string';
import { generateReqBody, isEmptyArr } from '@utils/index';
import { Button, Dropdown, Form, Menu, Modal, Select, Spin } from 'antd';
import { Input } from 'global';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const { Option } = Select;

const { useForm, Item, useWatch, List } = Form;

const Config: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm<PlanFactConfigFormModel>();
  const { config, isLoading } = useGetPlanFactConfig();
  const { pipes, isLoading: isLoadingPipes } = useGetPlanFactPipes();
  const pipeStatusMapArr = useWatch('pipeStatusMap', form);
  const pipeId = useWatch('pipeId', form);
  const planAmount = useWatch('planAmount', form);
  const { mutateAsync, isLoading: isLoadingSave } = useSavePlanFactPipe();
  const { visible, id, isLoading: isLoadingState } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact.planFactConfig);

  const onCancel = () => {
    dispatch(
      setPlanFactConfig({
        id,
        visible: false
      })
    );
  };

  const factPipeStatusList = useMemo(() => pipes?.find(({ id }) => id === pipeId)?.pipeStatuses, [pipeId]);

  const onChangePipe = (e: number) => {
    form.setFieldsValue({
      pipeId: e,
      pipeStatusMap:
        e !== config?.pipeId
          ? []
          : config?.factPipeStatusDtoList?.map(({ planLeadCount, pipeStatusResponse }) => ({ count: planLeadCount?.toLocaleString('ru'), id: pipeStatusResponse?.id })) || []
    });
  };

  const pipeStatusList = (key: number) => factPipeStatusList?.filter(({ id }) => id === pipeStatusMapArr?.[key]?.id || !pipeStatusMapArr?.some(({ id: formId }) => formId === id));

  const onFinish = (values: PlanFactConfigFormModel) => {
    let pipeStatusMap = {};

    values?.pipeStatusMap?.forEach(({ id, count }) => {
      pipeStatusMap = {
        ...pipeStatusMap,
        [id]: parseLocaleString(count)
      };
    });

    mutateAsync(generateReqBody({ ...values, planAmount: parseLocaleString(values?.planAmount), pipeStatusMap })).then(onCancel);
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        id,
        planAmount: typeof config?.planAmount === "undefined" ? planAmount : config?.planAmount?.toLocaleString('ru'),
        pipeId: typeof config?.pipeId === "undefined" ? pipeId : config?.pipeId,
        pipeStatusMap: typeof config?.factPipeStatusDtoList === "undefined" ? pipeStatusMapArr :
          config?.factPipeStatusDtoList?.map(({ planLeadCount, pipeStatusResponse }) => ({ count: planLeadCount?.toLocaleString('ru'), id: pipeStatusResponse?.id })) || []
      });
    }
  }, [visible, config]);

  return (
    <Modal
      centered
      width={545}
      footer={false}
      visible={visible}
      onCancel={onCancel}
      className={styles.container}
      afterClose={() => form.resetFields()}
      title={t('boss_crm.Rejani_belgilash')}
    >
      <Spin spinning={isLoading || isLoadingState}>
        <Form<PlanFactConfigFormModel> form={form} layout="vertical" onFinish={onFinish}>
          <Item className="hidden" name="id" />
          <div className="flex gap-3">
            <Item name="pipeId" className="w-1/2" label={t('crm_plan_fact_config.Sotuv varonkasi')}>
              <Select className="customSelect" loading={isLoadingPipes} onChange={onChangePipe}>
                {pipes?.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item name="planAmount" className="flex-1" label={t('crm_plan_fact_config.Sotuv hajmi')}>
              <Input type="currency" />
            </Item>
          </div>
          <List name="pipeStatusMap">
            {(fields, { add, remove }) => (
              <div className="mb-7 flex flex-col gap-3">
                {fields?.map((_, key) => (
                  <div className="flex gap-3" key={key}>
                    <Item name={[key, 'id']} label={t('crm_plan_fact_config.Bitim nomi')} className="w-1/2">
                      <Select className="customSelect">
                        {pipeStatusList(key)?.map(({ id, name }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Item>
                    <Item name={[key, 'count']} label={t('crm_plan_fact_config.Bitim soni')}>
                      <Input type="currency" />
                    </Item>
                    <div onClick={() => remove(key)} className="flex cursor-pointer items-center justify-center">
                      <DeleteIconV3 />
                    </div>
                  </div>
                ))}
                <ConditionalRender if={!isEmptyArr(pipeStatusList(-1))}>
                  <Dropdown
                    arrow={false}
                    trigger={['click']}
                    placement="bottom"
                    overlay={
                      <Menu
                        items={
                          pipeStatusList(-1)?.map(({ id, name }, index) => ({
                            key: id || index,
                            label: name,
                            onClick: () => {
                              add({
                                id,
                                count: ''
                              });
                            }
                          })) || []
                        }
                      />
                    }
                  >
                    <Button className="w-max rounded-md">+ {t("crm_plan_fact_config.Qo'shish")}</Button>
                  </Dropdown>
                </ConditionalRender>
              </div>
            )}
          </List>
          <div className="flex items-center justify-end gap-4">
            <Button
              onClick={onCancel}
              className="box-border flex h-max items-center justify-center rounded-[50px] border border-solid border-gray-300 px-12 py-3 text-base font-medium"
            >
              {t('home.Yopish')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingSave}
              className="box-border flex h-max items-center justify-center rounded-[50px] px-12 py-3 text-base font-medium text-white"
            >
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Config;
