import { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDownCrmIcon from '../../../../assets/icons/ArrowDownCrmIcon';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { usePipeAll } from '../../../../queries/queries';
import { setPipe } from '../../../../store/reducers/CrmStatisticsSlice';
import { PipeAll } from '../../../../utils/models/PipeAll';

import styles from './header.module.scss';

const Pipe = () => {
  const { t } = useTranslation();
  const [openChangePipe, setOpenChangePipe] = useState(false);

  const { data } = usePipeAll();

  const dispatch = useAppDispatch();
  const pipe = useAppSelector(state => state.crmStatistic.selectedPipe);

  // useEffect(() => {
  //   if (data && !pipe) {
  //     dispatch(setPipe(data[0]))
  //   }
  // }, [data, pipe, dispatch])

  // handleOpenChangePipe
  const handleOpenChangePipe = (isOpen: boolean) => {
    setOpenChangePipe(isOpen);
  };

  // handle select pipe
  const handleSelectPipe = (pipe: PipeAll | undefined) => {
    dispatch(setPipe(pipe));
    handleOpenChangePipe(false);
  };

  return (
    <Dropdown
      visible={openChangePipe}
      overlayClassName={styles.drop_down}
      overlay={
        <>
          {pipe?.name && <div onClick={() => handleSelectPipe(undefined)}>{t('home.Barchasi')}</div>}
          {data?.map((pipe, index) => (
            <div onClick={() => handleSelectPipe(pipe)} key={index}>
              {pipe.name}
            </div>
          ))}
        </>
      }
      onVisibleChange={e => handleOpenChangePipe(e)}
      trigger={['click']}
      placement="bottomCenter"
    >
      <div onClick={() => handleOpenChangePipe(true)} className={styles.container_select}>
        <span>{pipe?.name || t('home.Barchasi')}</span>
        <ArrowDownCrmIcon />
      </div>
    </Dropdown>
  );
};

export default Pipe;
