import React, { FC } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import ColapseIcon from '@icons/ColapseIcon';
import FIlterColapseIcon from '@icons/FIlterColapseIcon';
import FilterReloadIcon from '@icons/FilterReloadIcon';
import { RobotoStatus } from '@pages/roboto-calls/utils/constants/callsStatus';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '@utils/constants';
import { Checkbox, Collapse, Drawer, Space } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../../service/datePicker';

import '@components/filters/filterDrawer.module.scss';

const { Panel } = Collapse;

const { RangePicker } = DatePicker;

interface CallsFilterProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export interface SearchParamsRobotCalls {
  page: number;
  size: number;
  statuses: string[];
  startDate: string;
  endDate: string;
}

const CampagingDrawer: FC<CallsFilterProps> = ({ visible, setVisible }) => {
  const { appendMultipleDifferent, searchParams, removeAllSearchParams, remove, appendMultiple } = useQueryParam<SearchParamsRobotCalls, any>();
  const { t } = useTranslation();

  const onClose = () => {
    setVisible(false);
  };

  const deleteAllFilters = () => {
    removeAllSearchParams();
  };

  const changeDate = (values: any, formatString: [string, string]) => {
    appendMultipleDifferent(['startDate', 'endDate'], formatString);
  };

  const changeCallStatus = (value: RobotoStatus) => {
    if (Array.isArray(searchParams.statuses)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      searchParams.statuses.includes(value) ? remove('statuses', value) : appendMultiple('statuses', value);
    } else if (searchParams.statuses === value) {
      remove('statuses', value);
    } else {
      appendMultiple('statuses', value);
    }
  };

  return (
    <Drawer width={340} headerStyle={{ display: 'none' }} placement="left" onClose={onClose} visible={visible} className="filter_drawer">
      <h1>
        <p>{t('home.Filtr')}</p>
        <span onClick={deleteAllFilters}>
          <FilterReloadIcon />
          {t('home.Tozalash')}
        </span>
      </h1>
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIconPosition="right"
        className="filter_drawer_colapse"
        expandIcon={({ isActive }) => (
          <span
            style={{
              transform: `${isActive ? 'rotate(0deg)' : 'rotate(90deg)'}`,
              transition: '0.3s'
            }}
          >
            <ColapseIcon />
          </span>
        )}
      >
        <Panel
          header={
            <>
              <FIlterColapseIcon />
              {t('home.Sana')}
            </>
          }
          key="6"
        >
          <RangePicker
            format={DATE_WITH_TIME_FORMAT}
            value={searchParams.startDate && searchParams.endDate ? [dayjs(searchParams.startDate, DATE_FORMAT), dayjs(searchParams.endDate, DATE_FORMAT)] : [null, null]}
            onChange={changeDate}
            className="CustomRangePicker"
            placeholder={[t('client_create_contract.kk_oo_yyyy'), t('client_create_contract.kk_oo_yyyy')]}
          />
        </Panel>
        <Panel
          header={
            <>
              <FIlterColapseIcon />
              Holati
            </>
          }
          key="2"
        >
          <Space direction="vertical">
            <Checkbox
              onChange={() => changeCallStatus(RobotoStatus.PENDING)}
              checked={Array.isArray(searchParams?.statuses) ? searchParams?.statuses.includes(RobotoStatus.PENDING) : searchParams?.statuses === RobotoStatus.PENDING}
            >
              Kutulmoqda
            </Checkbox>
            <Checkbox
              onChange={() => changeCallStatus(RobotoStatus.MISSED)}
              checked={Array.isArray(searchParams?.statuses) ? searchParams?.statuses.includes(RobotoStatus.MISSED) : searchParams?.statuses === RobotoStatus.MISSED}
            >
              Ko'tarilmagan
            </Checkbox>
            <Checkbox
              onChange={() => changeCallStatus(RobotoStatus.RINGING)}
              checked={Array.isArray(searchParams?.statuses) ? searchParams?.statuses.includes(RobotoStatus.RINGING) : searchParams?.statuses === RobotoStatus.RINGING}
            >
              Bog'lanmoqda
            </Checkbox>
            <Checkbox
              onChange={() => changeCallStatus(RobotoStatus.DROPPED)}
              checked={Array.isArray(searchParams?.statuses) ? searchParams?.statuses.includes(RobotoStatus.DROPPED) : searchParams?.statuses === RobotoStatus.DROPPED}
            >
              Xatolik
            </Checkbox>
            <Checkbox
              onChange={() => changeCallStatus(RobotoStatus.TERMINATED)}
              checked={Array.isArray(searchParams?.statuses) ? searchParams?.statuses.includes(RobotoStatus.TERMINATED) : searchParams?.statuses === RobotoStatus.TERMINATED}
            >
              O'chirib qo'yilgan
            </Checkbox>
            <Checkbox
              onChange={() => changeCallStatus(RobotoStatus.COMPLETED)}
              checked={Array.isArray(searchParams?.statuses) ? searchParams?.statuses.includes(RobotoStatus.COMPLETED) : searchParams?.statuses === RobotoStatus.COMPLETED}
            >
              Muvaffaqqiyatli
            </Checkbox>
          </Space>
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default CampagingDrawer;
