import { FC } from 'react';
import { useScrollView } from '@hooks/useScrollView';
import { Spin } from 'antd';

import { useMobileContractClientPhone } from '../../../queries/queries';
import BossContractPeriodItem from '../bossContractPeriodItem/BossContractPeriodItem';

interface ClientPhoneInfoProps {
  number: string;
  size: number;
}

const ClientPhoneInfo: FC<ClientPhoneInfoProps> = ({ number, size }) => {
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useMobileContractClientPhone(number, size);
  const { ref } = useScrollView({ handleScroll: fetchNextPage, deps: [isFetching], isChange: !isFetching && hasNextPage });

  return (
    <>
      <Spin spinning={isLoading || isFetching}>
        <div style={{ overflow: 'auto', maxHeight: 500 }}>
          {data ? (
            data?.pages?.map(data =>
              data?.data?.map((item, index) => (
                <BossContractPeriodItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  item={item}
                />
              ))
            )
          ) : (
            <></>
          )}
          <div ref={ref} className="p-2" />
        </div>
      </Spin>
    </>
  );
};

export default ClientPhoneInfo;
