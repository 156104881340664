import React, { useState } from 'react';
import ErrorIcon from '@icons/ErrorIcon';
import { Button, Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import LogoutIconMessengerIcon from '../../../../../assets/icons/LogoutIconMessengerIcon';
import RefreshCircleIcon from '../../../../../assets/icons/RefreshCircleIcon';
import { useCreateOnlinePbx, useDeleteOnlinePbx } from '../../../../../queries/mutation';
import { OnlinePbx } from '../../../../../utils/models/OnlinePbx';

import ConfirmModal from './ConfirmModal';

import styles from './onlinePBX.module.scss';

interface IFrom {
  domain: string;
  apiKey: string;
}

interface Props {
  onlinePbx: OnlinePbx | null;
  refreshOnlinePBX: UseMutationResult<any, unknown, void, unknown>;
}

const CreatePBX: React.FC<Props> = ({ onlinePbx, refreshOnlinePBX }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IFrom>();
  const [visible, setVisible] = useState(false);
  const [apiKeyValue, setApiKeyValue] = useState('');

  const createPbx = useCreateOnlinePbx();
  const deletePbx = useDeleteOnlinePbx(onlinePbx?.id || 0);

  // on finish
  const onFinish = (val: IFrom) => {
    createPbx.mutate({
      domain: val.domain?.trim(),
      apiKey: val.apiKey?.trim()
    });
    form.resetFields();
  };

  const handleCreate = () => {
    createPbx.mutate({
      domain: onlinePbx?.domain?.trim()!,
      apiKey: apiKeyValue?.trim(),
      connectError: true
    });
  };

  if (onlinePbx)
    return (
      <Spin spinning={deletePbx.isLoading}>
        {(onlinePbx?.connectError || refreshOnlinePBX?.data?.connectError) && (
          <div className="mt-2.5 flex items-center gap-2 rounded-lg border border-solid border-[#FDA29B] bg-[#FFFBFA] py-2 pl-3 pr-4">
            <ErrorIcon />
            <p className="mt-[3px] text-xs font-medium text-[#D92D20]">{t('home.onlinepbx_text')}</p>
          </div>
        )}
        <p className={styles.container_body_create_title}>
          {t('online_pbx.Qongiroqlar_orqali_mijozlarni_toplang_suhbatlarni_saqlang')}

          <span onClick={() => refreshOnlinePBX.mutate()} className={refreshOnlinePBX.isLoading ? styles.refresh : ''}>
            <RefreshCircleIcon />
          </span>
        </p>
        <div className={styles.container_body_create_item}>
          <span>{t('online_pbx.URL_manzil')}</span>
          <div className={styles.container_body_create_item_name}>
            <p>{onlinePbx.domain}</p>
            <div>
              <div onClick={() => setVisible(true)} className={styles.container_body_create_item_name_log_out}>
                <LogoutIconMessengerIcon />
                <span>{t('my_calls.Chiqish')}</span>
              </div>
            </div>
          </div>
        </div>

        <hr />
        {(onlinePbx?.connectError || refreshOnlinePBX?.data?.connectError) && (
          <>
            <div className={styles.container_body_create_item}>
              <span>{t('my_calls.API_key')}</span>

              <Input className="customInput" placeholder="52xmwh6ogf0wuchi5f0rj4ztxa6sez" value={apiKeyValue} onChange={e => setApiKeyValue(e.target.value)} />
            </div>
            <div className="mt-5 flex items-center justify-end">
              <Button type="primary" className="rounded-full" onClick={handleCreate} loading={createPbx.isLoading} disabled={!apiKeyValue}>
                {t('home.Ulanish')}
              </Button>
            </div>
          </>
        )}

        <ConfirmModal visible={visible} setVisible={setVisible} onlinePbx={onlinePbx} />
      </Spin>
    );

  return (
    <>
      <Form
        form={form}
        name="cretePBX"
        initialValues={{ remember: true }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <p className={styles.container_body_create_title}>{t('online_pbx.Qongiroqlar_orqali_mijozlarni_toplang_suhbatlarni_saqlang')}</p>
        <div className={styles.container_body_create_item}>
          <span>{t('online_pbx.URL_manzil')}</span>
          <Form.Item
            name="domain"
            rules={[
              {
                required: true,
                message: ''
              }
            ]}
          >
            <Input className="customInput" placeholder="example.onpbx.ru" />
          </Form.Item>
        </div>
        <hr />
        <div className={styles.container_body_create_item}>
          <span>{t('my_calls.API_key')}</span>
          <Form.Item
            name="apiKey"
            rules={[
              {
                required: true,
                message: ''
              }
            ]}
          >
            <Input className="customInput" placeholder="52xmwh6ogf0wuchi5f0rj4ztxa6sez" />
          </Form.Item>
        </div>
        <hr />
        <div className={styles.container_body_modalFooter}>
          <Button loading={createPbx.isLoading} htmlType="submit" type="primary">
            {t('my_calls.Saqlash')}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreatePBX;
