import { Button, Form, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { SearchParamsMessage } from '../../Message';
import { SendMessageAllTypes } from '../../../../utils/models/MessageList';
import { usePremiumOriginator } from '../../services/queries';
import { usePremiumSendMessage } from '../../services/mutation';

const { Option } = Select;

const MessageAllCustomer = ({ setVisible, visible }: { setVisible: (value: boolean) => void; visible: boolean }) => {
  const [form] = Form.useForm();
  const { data, isLoading } = usePremiumOriginator();
  const { searchParams } = useQueryParam<SearchParamsMessage, any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const premiumAllCustomerMutation = usePremiumSendMessage(SendMessageAllTypes.ALL, searchParams, onSuccess);

  function onSuccess() {
    form.resetFields();
    setVisible(false);
  }

  const onFinish = (fields: any) => {
    premiumAllCustomerMutation.mutate({
      originator: fields.originator,
      text: fields.text
    });
  };

  return (
    <div className={'allFormStylePremium'}>
      <Form form={form} name={'messageAllCustomer'} layout="vertical" onFinish={onFinish}>
        {data?.length !== 0 && (
          <Form.Item name={'originator'} label={t('premium_modal.Originator')}>
            <Select loading={isLoading} placeholder={t('premium_modal.Select')}>
              {data?.map((item: any, index: number) => (
                <Option key={index} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          rules={[
            {
              required: true,
              message: t('premium_modal.Xabar_matni_kiritilmagan')
            }
          ]}
          name={'text'}
        >
          <TextArea className={'customInput'} autoSize={{ minRows: 4, maxRows: 4 }} placeholder={t('premium_modal.Xabar_matnini_kiriting')} />
        </Form.Item>
        <Form.Item className="sendButtonItem">
          <Button onClick={onSuccess} htmlType="button">
            {t('home.Yopish')}
          </Button>
          <Button loading={premiumAllCustomerMutation.isLoading} type="primary" htmlType="submit">
            {t('home.Yuborish')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MessageAllCustomer;
