import { setShaxmatka } from '../../../store/reducers/ShaxmatkaSlice';
import { FlatId } from '../../../utils/models/Flat';
import { FlatStatus } from '../../../utils/models/FlatStatus';

interface IProps {
  dispatch: (e: any) => void;
  setSaveAll: React.Dispatch<React.SetStateAction<boolean>>;
  shaxmatka: any[];
  activeKey: number;
}

export const createApartment = (i: FlatId, props: IProps) => {
  const shaxmatkaCopy = props.shaxmatka.map((podezd: any) =>
    podezd.map((floor: any) =>
      floor.map((room: FlatId) =>
        room === i
          ? {
              ...room,
              number: '0',
              buildingID: {
                id: Number(props.activeKey),
                name: '',
                houseId: Number(props.activeKey)
              },
              rooms: null,
              area: null,
              repaired: false,
              apartment: true,
              terrace: null,
              options: [],
              status: FlatStatus.SALE,
              prepaymentPercent: null,
              prepayment: null,
              delay: null,
              deletedBy: null,
              deletedDate: null,
              price: null,
              pricePerArea: null,
              pricePerAreaRepaired: null,
              priceRepaired: null,
              priceNotRepaired: null,
              gallery: null,
              discountID: null,
              createdBy: null,
              createdDate: null,
              new: false,
              isSelected: false,
              floor: room.floor,
              entrance: room.entrance,
              // entranceName: room.entranceName,
              xaxis: room.xaxis,
              yaxis: room.yaxis,
              customId: `${room.entrance}-${room.floor}-${room?.xaxis[0]}`
            }
          : {
              ...room
            }
      )
    )
  );

  // @ts-expect-error
  props.dispatch(setShaxmatka(shaxmatkaCopy));
  props.setSaveAll(false);
};
