import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setNewBranch } from '@reducers/UserSlice';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './branch-modal.module.scss';

export type Props = {
  visible: boolean;
  setOpenChangeBranch: (value: boolean) => void;
};

export interface FormFields {
  branchId: number;
}

const ChangeBranchModal: React.FC<Props> = ({ visible, setOpenChangeBranch }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const branches = useAppSelector(state => state.userReducer.user);

  const selectItems = branches?.roleTokens
    ?.map(item => ({
      label: item.branch.name,
      value: item.branch.id
    }))
    ?.sort((a, b) => a.value - b.value);

  useEffect(() => {
    if (selectItems) {
      form.setFieldsValue({ branchId: selectItems?.find(item => item?.value === branches?.branchId)?.value });
    }
  }, [selectItems, branches]);

  const handleCancel = () => {
    setOpenChangeBranch(false);
  };

  const onFinish = (values: FormFields) => {
    dispatch(setNewBranch(values.branchId));
    setOpenChangeBranch(false);
    form.resetFields();
    window.location.reload();
  };

  return (
    <Modal
      width={548}
      visible={visible}
      className={`createBuilderModal ${styles.branch_modal}`}
      footer={false}
      title={t('home.Filialni_tahrirlash')}
      onCancel={handleCancel}
    >
      <Form className={`flex flex-col gap-[8px] ${styles.branch_form}`} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={t('home.Filial_nomi')}
              name="branchId"
              rules={[
                {
                  required: true,
                  message: 'Iltimos branchni tanlang'
                }
              ]}
            >
              <Select className="customSelect w-[100%]" options={selectItems} />
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.modalFooter}>
          <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
          <Button htmlType="submit" type="primary">
            {t('profile.Tasdiqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangeBranchModal;
