import PremiumClientIcon from '@icons/PremiumClientIcon';
import VipClientIcon from '@icons/VipClientIcon';
import WarningIcon from '@icons/WarningIcon';
import { Avatar, Dropdown, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import CallIcon from './assets/icons/CallIcon';

import styles from './clientMiniInfo.module.scss';

interface IProps {
  score?: string;
  phones?: string[] | undefined;
  name?: string;
  avatar?: string;
  passportExpired?: boolean;
  level?: string | undefined;
}

const number = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5
};

const customIcons: Record<number, React.ReactNode> = {
  1: <span className={styles.circleIcon} style={{ backgroundColor: '#EC5B26' }} />,
  2: <span className={styles.circleIcon} style={{ backgroundColor: '#F8BF0D' }} />,
  3: <span className={styles.circleIcon} style={{ backgroundColor: '#B8E117' }} />,
  4: <span className={styles.circleIcon} style={{ backgroundColor: '#34C177' }} />,
  5: <span className={styles.circleIcon} style={{ backgroundColor: '#25A08B' }} />,
  0: <span className={styles.circleIcon} style={{ backgroundColor: '#D9D9D9' }} /> // Значение 0 для пустых иконок
};

const CircleRate = ({ value }: { value: number }) => {
  const { t } = useTranslation();
  const descriptions = [
    {
      title: t('home.Ishonchsiz_mijoz'),
      text: t('home.bir_yildan_oshiq_muddatdan_beri_tolov_qilmayotgan_mijozlar'),
      backgroundColor: '#EC5B26',
      boxShadow: '0px 0px 10px 4px rgba(236, 91, 38, 0.329)'
    },
    {
      title: t('home.Intizomsiz_mijoz'),
      text: t('home.tolovlarni_bir_yil_kechikib_tolaydiganlar'),
      backgroundColor: '#F8BF0D',
      boxShadow: '0px 0px 10px 4px rgba(248, 189, 13, 0.329)'
    },
    {
      title: t('home.Orta_ishonchli_mijoz'),
      text: t('home.tolovlarni_3_oy_kechikib_tolaydiganlar'),
      backgroundColor: '#B8E117',
      boxShadow: '0px 0px 10px 4px rgba(184, 225, 23, 0.329)'
    },
    {
      title: t('home.Ishonchli_mijoz'),
      text: t('home.tolovlarni_bir_oy_kechiktirib_tolaydigan_mijozlar'),
      backgroundColor: '#34C177',
      boxShadow: '0px 0px 10px 4px rgba(52, 193, 119, 0.329)'
    },
    {
      title: t('home.Intizomli_mijoz'),
      text: t('home.tolovlarni_oz_vaqtida_tolayotgan_mijozlar'),
      backgroundColor: '#25A08B',
      boxShadow: '0px 0px 10px 4px rgba(37, 160, 139, 0.329)'
    }
  ];

  const renderDropdown = (text: string, title: string, index: number, backgroundColor?: string, boxShadow?: string) => (
    <Dropdown
      key={index}
      overlay={
        <Menu className={styles.menuDropdown}>
          <Menu.Item className={styles.menuDropdownItem}>
            <h4>{t('home.Status_tarifi')}</h4>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                marginLeft: '10px'
              }}
            >
              <span className={styles.range} style={{ backgroundColor, boxShadow }} />
              {title}
            </div>
            <p className={styles.flexibleText}>
              <span style={{ fontSize: '10px' }}>●</span> {text}
            </p>
          </Menu.Item>
        </Menu>
      }
      placement="bottomLeft"
    >
      <span className={`${styles.score} ${index + 1 === value ? styles.activeCircle : ''}`}>{customIcons[index + 1]}</span>
    </Dropdown>
  );

  return (
    <div className={styles.wrapDiv}>
      {descriptions.map((description, index, backgroundColor) => renderDropdown(description.text, description.title, index, description.backgroundColor, description.boxShadow))}
    </div>
  );
};

const ClientMiniInfo = ({ avatar, name, phones, score, passportExpired, level }: IProps) => {
  const { t } = useTranslation();

  return (
    <>
      <main>
        <h1 className={styles.mini_info_title}>{t('changes.Mijoz_malumotlari')}</h1>

        <div style={{ display: 'flex', alignItems: 'start', gap: '8px' }}>
          <Avatar className={styles.avatar}>{name && name.length >= 2 ? name.substring(0, 2) : ''}</Avatar>
          <div className={styles.mini_info_info}>
            <div style={{ margin: '0px' }}>
              <h3 className={styles.title}>
                <div className="flex items-center gap-1">
                  <span>{name}</span>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {level === 'VIP' ? (
                    <Tooltip overlayStyle={{ maxWidth: '490px' }} overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Vip_mijoz_text')}</span>}>
                      <div className="flex items-center justify-center">
                        <VipClientIcon />
                      </div>
                    </Tooltip>
                  ) : level === 'REGULAR' ? (
                    <Tooltip overlay={<span className="text-xs font-medium text-[#ffffff]">{t('home.Premium_mijoz_text')}</span>} overlayStyle={{ maxWidth: 440 }}>
                      <div className="flex items-center justify-center">
                        <PremiumClientIcon />
                      </div>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
                {passportExpired && (
                  <Tooltip overlay="Passport malumotlari eskirgan">
                    <div className={styles.warning}>
                      <WarningIcon />
                    </div>
                  </Tooltip>
                )}
              </h3>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '7px',
                  marginTop: '0px'
                }}
              >
                <CircleRate value={score !== null ? number[score as keyof typeof number] : number.ZERO} />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                width: '300px'
              }}
            >
              {phones?.map((item, i) => (
                <p key={i} className={styles.number}>
                  <span>
                    <CallIcon />
                  </span>
                  {item}
                </p>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ClientMiniInfo;
