import Conversion from '@pages/bossCrm/components/conversion/Conversion';
import { LocalStorage } from '@service/LocalStorage';
import { USER_KEY } from '@utils/constants/localStorageKeys';
import { Permissions } from '@utils/constants/permissions';
import { User } from '@utils/models/User';

import CallStatistics from '../../pages/bossCrm/components/callStatistics/CallStatistics';
import CommonStatistic from '../../pages/bossCrm/components/commonStatistic/CommonStatistic';
import LeadStatistic from '../../pages/bossCrm/components/lead-statistic/LeadStatistic';
import PlanFact from '../../pages/bossCrm/components/plan-fact';
import TasksStatistics from '../../pages/bossCrm/components/tasksStatistics/TasksStatistics';

import { bossCrmPaths } from './bossCrmPaths';

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: bossCrmPaths.COMMON,
    component: CommonStatistic,
    permission: Permissions.PERMISSION_STATS_CRM_GENERAL,
    exact: false
  },
  {
    path: bossCrmPaths.CALLS,
    component: CallStatistics,
    permission: Permissions.PERMISSION_STATS_CRM_CALLS,
    exact: false
  },
  {
    path: bossCrmPaths.TASKS,
    component: TasksStatistics,
    permission: Permissions.PERMISSION_STATS_CRM_TASKS,
    exact: false
  },
  {
    path: bossCrmPaths.LEADS,
    component: LeadStatistic,
    permission: Permissions.PERMISSION_STATS_CRM_LEADS,
    exact: false
  },
  {
    path: bossCrmPaths.CONVERSION,
    component: Conversion,
    exact: false
  },
  {
    path: bossCrmPaths.PLAN_FACT,
    component: PlanFact,
    exact: false
  }
  // {
  //   path: bossCrmPaths.EMPLOYEES,
  //   component: EmployeeStatistics,
  //   exact: true
  // }
];

const bossCrmRoutes: typeof routes = [];

if (typeof user === 'object' && user) {
  routes.forEach(route => {
    if (route.permission) {
      if (user.role.permissions.includes(route.permission) || user.role.permissions.includes(Permissions.PERMISSION_MOBILE)) {
        bossCrmRoutes.push(route);
      }
    } else {
      bossCrmRoutes.push(route);
    }
  });
}

export default bossCrmRoutes;
