import React from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { useCreateService } from 'modules/uzum-integration/mutations/use-create-service';
import { useDeleteService } from 'modules/uzum-integration/mutations/use-delete-service';
import { useUpdateService } from 'modules/uzum-integration/mutations/use-update-service';
import { UzumHouses, UzumIntegration } from 'modules/uzum-integration/schema';

interface IProps {
  key: number;
  name: number;
  restField: {
    fieldKey?: number | undefined;
  };
  form: FormInstance<any>;
  remove: (index: number | number[]) => void;
  data: UzumIntegration | undefined;
  setButtonIndex: React.Dispatch<React.SetStateAction<number>>;
  houses: UzumHouses[] | undefined;
}

const { Option } = Select;

const ServicesItem: React.FC<IProps> = ({ name, restField, form, remove, data, setButtonIndex, houses }) => {
  const { t } = useTranslation();

  const createService = useCreateService();
  const updateService = useUpdateService();
  const deleteService = useDeleteService();
  const id = form.getFieldsValue()?.services[name]?.id;

  const ids = data?.services
    ?.map(item => item?.houses)
    ?.flat(Infinity)
    ?.map((prev: any) => prev?.id);

  function filterArrayByIds(data: any[], ids: number[]) {
    return data.filter(item => !ids.includes(item.id));
  }

  const handleDeleteService = () => {
    if (id) {
      deleteService.mutateAsync({ id }).then(() => remove(id!));
    } else remove(name);
  };

  return (
    <Row gutter={12} className="mb-4 rounded-xl border-x border-y border-solid border-[#EAECF0] bg-[#F9FAFB] px-4 py-3">
      <Col span={8}>
        <Form.Item
          {...restField}
          label={<label className="w-full !text-xl text-[#A0A0A0]">{t('home.xizmatId')}</label>}
          name={[name, 'serviceId']}
          rules={[{ required: true, message: '' }]}
        >
          <InputMask
            mask="99999999999999"
            // @ts-ignore*
            maskChar=""
            className="h-[52px] !w-full rounded-md px-3 py-2 text-2xl font-medium"
          >
            {/* @ts-ignore */}
            {(inputProps: any) => <Input className="customInput" style={{ width: 235, marginRight: '17px' }} {...inputProps} />}
          </InputMask>
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          {...restField}
          label={<label className="w-full !text-xl text-[#A0A0A0]">{t('home.loyiha')}</label>}
          name={[name, 'houses']}
          rules={[{ required: true, message: '' }]}
        >
          <Select mode="multiple" maxTagCount="responsive" className="customSelect1">
            {houses?.map(item => (
              <Option key={item?.id} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4} className="mt-2 flex items-center">
        <Button
          htmlType="submit"
          type="primary"
          className="h-[40px] w-full rounded-[50px]"
          onClick={() => setButtonIndex(name)}
          loading={createService.isLoading || updateService.isLoading}
        >
          {t('home.Saqlash')}
        </Button>
      </Col>
      <Col span={4} className="mt-2 flex items-center">
        <Button
          onClick={handleDeleteService}
          className="h-[40px] w-full rounded-[50px] border-[#F04438] bg-[#F04438] text-white hover:border-[#F04438] hover:bg-[#F04438] hover:text-white"
          loading={deleteService.isLoading}
        >
          {t('boss_crm.Ochirish')}
        </Button>
      </Col>
    </Row>
  );
};

export default ServicesItem;
