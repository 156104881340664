import React from 'react';

type Propps = {
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
};
const ChatReplyIcon: React.FC<Propps> = ({ onClick }) => {
  return (
    <svg style={{ cursor: 'pointer' }} onClick={onClick} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V7.25C16.5 3.5 15 2 11.25 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17Z"
        stroke="#B0B0B0"
        stroke-width="1.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.75078 12.0341H10.4408C11.7158 12.0341 12.7508 10.9991 12.7508 9.72406C12.7508 8.44906 11.7158 7.41406 10.4408 7.41406H5.36328"
        stroke="#B0B0B0"
        stroke-width="1.1"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M6.4275 8.57734L5.25 7.39234L6.4275 6.21484" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default ChatReplyIcon;
