import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './showroomWidget.module.scss';
import TrushSquareIcon from '../../../assets/icons/TrushSquareIcon';
import RefreshCircleIcon from '../../../assets/icons/RefreshCircleIcon';
import TickCircleIcon from '../../../assets/icons/TickCircleIcon';
import { useRealtorConfig } from '../../../queries/queries';
import { useRealtorConfigCreate, useRealtorConfigDelete } from '../../../queries/mutation';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const ShowroomWidget: FC<IProps> = ({ setVisible, visible }) => {
  const { t } = useTranslation();
  const [created, setCreated] = useState({
    companyName: '',
    username: '',
    password: '',
    visible: false
  });
  const [activeIndex, setActiveIndex] = useState(-1);
  const [form] = Form.useForm();
  const { data } = useRealtorConfig();
  const createRealtor = useRealtorConfigCreate();
  const deleteRealtor = useRealtorConfigDelete();
  const handleClose = () => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    createRealtor.mutateAsync(values.accounts[activeIndex]).then(() => {
      setActiveIndex(-1);
    });
  };

  const handleTick = (index: number) => {
    setActiveIndex(index);
    form.submit();
  };

  const handleCreate = () => {
    createRealtor
      .mutateAsync({
        companyName: created.companyName,
        password: created.password,
        username: created.username
      })
      .then(() => {
        setCreated({
          companyName: '',
          password: '',
          username: '',
          visible: false
        });
      });
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      form.setFieldsValue({ accounts: data });
      setCreated(prev => ({ ...prev, visible: false }));
    } else {
      setCreated(prev => ({ ...prev, visible: true }));
    }
  }, [data, form, visible]);

  return (
    <Modal title={t('titles.showroom')} visible={visible} onCancel={handleClose} wrapClassName={styles.modal} footer={false}>
      <Form onFinish={onFinish} autoComplete="off" form={form}>
        <label className={styles.label}>{t('integration.Boglangan_hisoblar')}</label>
        <Form.List name="accounts">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item {...restField} name={[name, 'companyName']} rules={[{ required: index === activeIndex, message: '' }]}>
                    <Input className="customInput" placeholder="Company" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'username']} rules={[{ required: index === activeIndex, message: '' }]}>
                    <Input className="customInput" placeholder="Login" disabled={index !== activeIndex} />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'password']} rules={[{ required: index === activeIndex, message: '' }]}>
                    <Input className="customInput" placeholder="Parol" disabled={index !== activeIndex} />
                  </Form.Item>
                  {activeIndex === index || data?.length === index ? (
                    <span className={styles.icon} onClick={() => handleTick(index)}>
                      {createRealtor.isLoading ? <LoadingOutlined /> : <TickCircleIcon />}
                    </span>
                  ) : (
                    <span className={styles.icon} onClick={() => setActiveIndex(index)}>
                      <RefreshCircleIcon />
                    </span>
                  )}
                  <span
                    onClick={() => {
                      if (data[index]?.id) {
                        deleteRealtor.mutateAsync(data[index]?.id).then(() => {
                          remove(name);
                        });
                      } else {
                        remove(name);
                      }
                      setActiveIndex(-1);
                    }}
                    className={styles.icon}
                  >
                    {deleteRealtor.isLoading ? <LoadingOutlined /> : <TrushSquareIcon size={16} strokeWidth={1.5} />}
                  </span>
                </Space>
              ))}
            </>
          )}
        </Form.List>
        {created?.visible ? (
          <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
            <Input
              className="customInput"
              value={created?.companyName}
              onChange={e =>
                setCreated(prev => ({
                  ...prev,
                  companyName: e?.target?.value
                }))
              }
              placeholder={t('setting.Kompaniya')}
            />
            <Input
              className="customInput"
              value={created?.username}
              onChange={e => setCreated(prev => ({ ...prev, username: e?.target?.value }))}
              placeholder={t('integration.Login')}
            />
            <Input
              className="customInput"
              value={created?.password}
              onChange={e => setCreated(prev => ({ ...prev, password: e?.target?.value }))}
              placeholder={t('integration.Parol')}
            />
            <span className={styles.icon} onClick={handleCreate}>
              {createRealtor.isLoading ? <LoadingOutlined /> : <TickCircleIcon />}
            </span>
          </Space>
        ) : (
          <Button type="dashed" icon={<PlusOutlined />} onClick={() => setCreated(prev => ({ ...prev, visible: true }))}>
            {t('integration.Yana_qoshish')}
          </Button>
        )}
      </Form>
    </Modal>
  );
};

export default ShowroomWidget;
