import React, { useContext } from 'react';
import FilterIcon from '@icons/FilterIcon';
import ReceiveSquare from '@icons/ReceiveSquare';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetUnsuccessfullLeads } from '@pages/bossCrm/services/mutate';
import { UnsuccessfullLeadsTableData } from '@pages/bossCrm/utils/models/UnsuccessfullLeadsTableData';
import { useReasonsForRefusal } from '@pages/crm/services/queries';
import { useCrmEmployee, usePipeAll } from '@queries/queries';
import { generateReqBody, isEmptyArr, isEmptyObj } from '@utils/index';
import { Button, Drawer, Select, Spin } from 'antd';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

import styles from '../index.module.scss';

const FilterDrawer: React.FC = () => {
  const { t } = useTranslation();
  const firstTableDataMutation = useGetUnsuccessfullLeads();
  const { data: pipes, isLoading: isLoadingPipes } = usePipeAll();
  const { data: employees, isLoading: isLoadingEmployees } = useCrmEmployee();
  const { data: reasons, isLoading: isLoadingReasons } = useReasonsForRefusal();
  const { setContext, unsuccessfulLeadsFilter, ...arg } = useContext(ConversionContext);

  const onOpen = () => {
    setContext({
      ...arg,
      unsuccessfulLeadsFilter: {
        ...(unsuccessfulLeadsFilter || {}),
        visible: true
      }
    });
  };

  const onClose = () => {
    setContext({
      ...arg,
      unsuccessfulLeadsFilter: {
        ...(unsuccessfulLeadsFilter || {}),
        visible: false
      }
    });

    const conditionObj = { ...unsuccessfulLeadsFilter };

    delete conditionObj.visible;

    if (!isEmptyObj(conditionObj)) {
      setContext({
        ...arg,
        isTableDataLoading: true
      });

      firstTableDataMutation
        ?.mutateAsync(
          generateReqBody({
            size: 10,
            page: arg?.page!,
            startDate: arg?.startDate!,
            finishDate: arg?.endDate!,
            search: unsuccessfulLeadsFilter?.search,
            pipeIds: unsuccessfulLeadsFilter?.pipeStatusIds,
            reasonsFRIds: unsuccessfulLeadsFilter?.reasonsFRIds,
            responsibleById: unsuccessfulLeadsFilter?.responsibleById
          })
        )
        .then((data: UnsuccessfullLeadsTableData) => setContext({ firstTableData: data, isTableDataLoading: false }));
    }
  };

  const onReset = () => {
    setContext({
      ...arg,
      isTableDataLoading: true,
      unsuccessfulLeadsFilter: {
        visible: true
      }
    });

    firstTableDataMutation
      ?.mutateAsync(
        generateReqBody({
          size: 10,
          page: arg?.page!,
          finishDate: arg?.endDate!,
          startDate: arg?.startDate!
        })
      )
      .then((data: UnsuccessfullLeadsTableData) => setContext({ firstTableData: data, isTableDataLoading: false }));
  };

  const onChangeProperty = (e: number[], key: 'reasonsFRIds' | 'pipeStatusIds' | 'responsibleById') => {
    setContext({
      ...arg,
      unsuccessfulLeadsFilter: {
        visible: true,
        ...(unsuccessfulLeadsFilter || {}),
        [key]: e
      }
    });
  };

  const title = (
    <div className="flex items-center justify-between">
      <span>{t('boss_crm_conversion.Filterlari')}</span>
      <div className="flex cursor-pointer items-center gap-2 text-xs font-medium text-danger" onClick={onReset}>
        <ReceiveSquare size={20} />
        <span>{t('boss_crm_conversion.Tozalash')}</span>
      </div>
    </div>
  );

  const filterCount = () => {
    let count = 0;
    const conditionObj = { ...unsuccessfulLeadsFilter };

    delete conditionObj.visible;

    Object.keys(conditionObj || {})?.forEach(key => {
      if (key) {
        const item = conditionObj?.[key as keyof typeof conditionObj];

        if (typeof item === 'object' && !isEmptyArr(conditionObj?.[key as keyof typeof conditionObj] as never)) {
          count += 1;
        }
      }
    });

    return count;
  };

  return (
    <>
      <Button className={styles.filter__button} onClick={onOpen}>
        <FilterIcon />
        <span>Filter</span>
        <ConditionalRender if={filterCount() > 0}>
          <div className="rounded-2xl bg-primary px-2 py-0.5 text-sm text-[#fff]">{filterCount()}</div>
        </ConditionalRender>
      </Button>
      <Drawer title={title} className={styles.drawer} visible={unsuccessfulLeadsFilter?.visible} onClose={onClose}>
        <Spin spinning={isLoadingPipes || isLoadingEmployees || isLoadingReasons}>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1.5">
              <span className="text-xs font-normal text-gray-500">{t('boss_crm_conversion.Varonka bosqichi')}</span>
              <Select
                allowClear
                mode="multiple"
                placeholder={t('boss_crm_conversion.Tanlang')}
                value={unsuccessfulLeadsFilter?.pipeStatusIds}
                onChange={e => onChangeProperty(e, 'pipeStatusIds')}
                options={pipes?.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <span className="text-xs font-normal text-gray-500">{t('boss_crm_conversion.Bekor bo‘lish sababi')}</span>
              <Select
                allowClear
                mode="multiple"
                placeholder={t('boss_crm_conversion.Tanlang')}
                value={unsuccessfulLeadsFilter?.reasonsFRIds}
                onChange={e => onChangeProperty(e, 'reasonsFRIds')}
                options={reasons?.map(({ id, value }) => ({
                  value: id,
                  label: value
                }))}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <span className="text-xs font-normal text-gray-500">{t('boss_crm_conversion.Masul shaxs')}</span>
              <Select
                allowClear
                mode="multiple"
                placeholder={t('boss_crm_conversion.Tanlang')}
                value={unsuccessfulLeadsFilter?.responsibleById}
                onChange={e => onChangeProperty(e, 'responsibleById')}
                options={employees?.map(({ id, fullName }) => ({
                  value: id,
                  label: fullName
                }))}
              />
            </div>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
