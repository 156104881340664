import ShowroomVisualBlock from '../../pages/showroom-visual-block/ShowroomVisualBlock';
import ShowroomVisualFloor from '../../pages/showroom-visual-floor/ShowroomVisualFloor';
import ShowroomVisualHouse from '../../pages/showroom-visual-house/ShowroomVisualHouse';
import { showroomVisualPath } from './showroomVisualPath';

export const showroomVisualRoutes = [
  {
    path: showroomVisualPath.INDEX,
    component: ShowroomVisualHouse,
    exact: true
  },
  {
    path: showroomVisualPath.BLOCK,
    component: ShowroomVisualBlock,
    exact: true
  },
  {
    path: showroomVisualPath.BLOCK_FLOOR,
    component: ShowroomVisualFloor,
    exact: true
  }
];
