import React from 'react';
import { Button } from 'antd';
import Dropzone from 'react-dropzone';
import ImportExportIcon from '../../assets/icons/ImportExportIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  handleDrop: (file: any) => void;
}

const ImportDropZone: React.FC<Props> = ({ handleDrop }) => {
  const { t } = useTranslation();

  return (
    <Dropzone multiple={false} onDrop={handleDrop}>
      {({ getRootProps, isDragAccept, isDragReject }) => {
        const additionalClass = isDragAccept ? 'accept' : isDragReject ? 'reject' : '';

        return (
          <div
            {...getRootProps({
              className: `importDropzone ${additionalClass}`
            })}
          >
            <div>
              <span>
                <ImportExportIcon />
              </span>
              <div>
                <h1>{t('import_export.Import')}</h1>
                <p>{t('import_export.bir_vaqtda_kochirib_olishingiz_mumkin')}</p>
              </div>
            </div>

            <Button type="primary">{t('import_export.Import')}</Button>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default ImportDropZone;
