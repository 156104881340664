import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClockCounterClockwiseIcon from '../../../../assets/icons/contract/ClockCounterClockwiseIcon';
import TaskIcon from '../../../../assets/icons/contract/TaskIcon';
import { ContractId } from '../../../../utils/models/ContractId';
import ContractIdPaymentGraphic from '../../../contractIdV2/components/contract-id-payment-history/ContractIdPaymentGraphic';
import ContractIdPaymentHistory from '../../../contractIdV2/components/contract-id-payment-history/ContractIdPaymentHistory';
import styles from './paymentContractTable.module.scss';

import tableSearchImg from '../../../../assets/img/tableSearchImg.png';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { SearchParamsPayment } from '../../../payment/Payment';

interface IProps {
  contractIdData: ContractId | undefined;
}

const PaymentContractTable: FC<IProps> = ({ contractIdData }) => {
  const { searchParams } = useQueryParam<SearchParamsPayment, any>();
  const { t } = useTranslation();
  const [tapleKey, setTapleKey] = useState('PAYMENT_HISTORY');
  const [noDataHeight, setNoDataHeight] = useState(0);

  const contractId = searchParams.contractId;
  const contractIdNumber = Number(contractId);

  const tableKeys = {
    PAYMENT_HISTORY: {
      text: t('contract_id.Tolov_tarixi'),
      type: 'PAYMENT_HISTORY',
      icon: <ClockCounterClockwiseIcon />,
      component: <ContractIdPaymentHistory data={contractIdData} />
    },
    PAYMENT_GRAPHIC: {
      text: t('contract_id.Tolov_grafigi'),
      type: 'PAYMENT_GRAPHIC',
      icon: <TaskIcon />,
      component: <ContractIdPaymentGraphic />
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        {Object.values(tableKeys).map(item => (
          <div
            key={item.type}
            className={`${styles.container_header_item} ${tapleKey === item.type ? styles.container_header_item_active : ''}`}
            onClick={() => setTapleKey(item.type)}
          >
            {item.icon} {item.text}
          </div>
        ))}
      </div>
      {contractIdNumber ? (
        <div className={styles.container_body}>{tableKeys[tapleKey as keyof typeof tableKeys].component}</div>
      ) : (
        <div
          className={styles.container_noData}
          style={{ height: `calc(100vh - ${noDataHeight + 30}px)` }}
          ref={ref => setNoDataHeight(ref?.getClientRects()[0].top || 0)}
        >
          <img src={tableSearchImg} alt="" />
          <h3>{t('create_payment.Mijoz_tanlanmagan')}...</h3>
          <p>{t('create_payment.Mijozni_qidirish_tanlash')}</p>
        </div>
      )}
    </div>
  );
};

export default PaymentContractTable;
