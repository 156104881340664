import React from 'react';

const CardPosBossIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 21.25C22 21.66 21.66 22 21.25 22H2.75C2.34 22 2 21.66 2 21.25C2 20.84 2.34 20.5 2.75 20.5H21.25C21.66 20.5 22 20.84 22 21.25Z"
        fill="#464255"
      />
      <path
        d="M15.39 4.52001L4.65 15.26C4.24 15.67 3.58 15.67 3.18 15.26H3.17C1.78 13.86 1.78 11.6 3.17 10.21L10.32 3.06001C11.72 1.66001 13.98 1.66001 15.38 3.06001C15.79 3.45001 15.79 4.12001 15.39 4.52001Z"
        fill="#464255"
      />
      <path
        d="M20.82 8.49001L17.77 5.44001C17.36 5.03001 16.7 5.03001 16.3 5.44001L5.56 16.18C5.15 16.58 5.15 17.24 5.56 17.65L8.61 20.71C10.01 22.1 12.27 22.1 13.67 20.71L20.81 13.56C22.23 12.16 22.23 9.89001 20.82 8.49001ZM12.76 17.52L11.55 18.74C11.3 18.99 10.89 18.99 10.63 18.74C10.38 18.49 10.38 18.08 10.63 17.82L11.85 16.6C12.09 16.36 12.51 16.36 12.76 16.6C13.01 16.85 13.01 17.28 12.76 17.52ZM16.73 13.55L14.29 16C14.04 16.24 13.63 16.24 13.37 16C13.12 15.75 13.12 15.34 13.37 15.08L15.82 12.63C16.06 12.39 16.48 12.39 16.73 12.63C16.98 12.89 16.98 13.3 16.73 13.55Z"
        fill="#464255"
      />
    </svg>
  );
};

export default CardPosBossIcon;
