export const colorPieChart = [
  '#8EFB98',
  '#FF9364',
  '#6021A3',
  '#B09FFF',
  '#E74141',
  '#FFD572',
  '#F25F33',
  '#0E84E5',
  '#00A389',
  '#8B62FF',
  '#3075be',
  '#8BBFCC',
  '#E69999',
  '#DDBF82',
  '#96BE84',
  '#D8B285',
  '#9C87D2',
  '#C38AAC',
  '#D4BE8E',
  '#A3C2BA',
  '#D4B3B5',
  '#B9D4A0',
  '#AABFDD',
  '#DCC3A6',
  '#D3B3A0',
  '#A0D3DD',
  '#D1B2A6',
  '#B09BC8',
  '#D2C1A9',
  '#D3B181',
  '#BCC8B8',
  '#ff4405'
];
export const colorCallsStatus = ['#FDB022', '#F97066', '#1E90FF', '#F04438', '#875BF7', '#32D583', '#FF3800'];

export const widgetPieChartColord = ['#D83F31', '#7743DB', '#FFB534', '#65B741', '#0CAF61', '#8B62FF', '#FDDC61', '#FE9649', '#0766AD'];
