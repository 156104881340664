import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { setPrePayment, setPrepaymentDate } from '../../../store/reducers/CreateContractSlice';
import { DATE_FORMAT } from '../../../utils/constants/format';
import { helper } from '../../../utils/helper/helper';
import { roundFloatNumber } from '../../../utils/helper/roundFloatNumber';
import DatePickerGlobal from '../../date-picker/DatePicker';

import styles from './paymentProps.module.scss';

interface IForm {
  prePayment: string;
  totalAmount: string;
  residueAmount: string;
  monthlyPayment: string;
  prepaymentDate: any;
}

const PaymentProps = () => {
  const [form] = Form.useForm<IForm>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { prePayment, totalAmount, delay, delayInterval, discountSum, currency, prepaymentDate } = useAppSelector(state => state.createContract);

  useEffect(() => {
    const count = delay / (delayInterval || 1);
    const residueAmount = roundFloatNumber(totalAmount - prePayment - discountSum);
    const monthlyPayment = roundFloatNumber(residueAmount / (count || 1));

    form.setFieldsValue({
      monthlyPayment: monthlyPayment.toLocaleString('ru'),
      totalAmount: totalAmount.toLocaleString('ru'),
      prePayment: prePayment.toLocaleString('ru'),
      residueAmount: residueAmount.toLocaleString('ru')
      // prepaymentDate: dayjs(prepaymentDate, DATE_FORMAT),
    });
  }, [prePayment, totalAmount, delay, delayInterval, discountSum, prepaymentDate, form]);

  // handle change pre payment
  const changePrePayment: React.ChangeEventHandler<HTMLInputElement> = e => {
    const payment = helper.deleteNotNumbersAndParseFloatNumber(e.target.value);

    if (payment.number > totalAmount - discountSum) {
      dispatch(setPrePayment(roundFloatNumber(totalAmount - discountSum)));
      form.setFieldsValue({
        prePayment: roundFloatNumber(totalAmount - discountSum).toLocaleString('ru')
      });
    } else {
      dispatch(setPrePayment(payment.number));
      form.setFieldsValue({
        prePayment: payment.string
      });
    }
  };

  return (
    <Form form={form} name="PaymentProps" autoComplete="off">
      <div className={styles.container}>
        <div className={`${styles.container_price} mt-[5px]`}>
          <label>{t('create_table_payments.Umumiy_tolov_summasi')}</label>
          <Form.Item name="totalAmount">
            <Input disabled suffix={<span>{currency?.ccy}</span>} className="customInput" />
          </Form.Item>
        </div>
        <div className={`${styles.container_price} mt-[5px]`}>
          <label htmlFor="prePayment">{t('create_table_payments.Boshlangich_tolov')}</label>
          <Form.Item name="prePayment" rules={[{ required: true, message: '' }]}>
            <Input onChange={changePrePayment} suffix={<span>{currency?.ccy}</span>} className="customInput" id="prePayment" />
          </Form.Item>
        </div>
        <div className={`${styles.container_price} mt-[5px]`}>
          <label htmlFor="prepaymentDate">{t('create_table_payments.Boshlangich_tolov_sanasi')}</label>
          <DatePickerGlobal
            form={form}
            formItemName="prepaymentDate"
            rules={[{ required: true, message: '' }]}
            initialValue={dayjs(prepaymentDate, DATE_FORMAT)}
            onChange={value => dispatch(setPrepaymentDate(value.format(DATE_FORMAT)))}
          />
        </div>
        <div className={`${styles.container_price} mt-[5px]`}>
          <label>{t('create_table_payments.Qoldiq_summa')}</label>
          <Form.Item name="residueAmount">
            <Input disabled suffix={<span>{currency?.ccy}</span>} className="customInput" />
          </Form.Item>
        </div>
        <div className={`${styles.container_price} mt-[5px]`}>
          <label>{t('create_table_payments.Oylik_tolov')}</label>
          <Form.Item name="monthlyPayment">
            <Input disabled suffix={<span>{currency?.ccy}</span>} className="customInput" />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default PaymentProps;
