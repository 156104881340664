import React from 'react';

const CardTickIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66699 7.08325H11.2503" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 13.75H6.66667" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.75 13.75H12.0833" stroke="#444444" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.3337 9.19175V13.4251C18.3337 16.3501 17.592 17.0834 14.6337 17.0834H5.36699C2.40866 17.0834 1.66699 16.3501 1.66699 13.4251V6.57508C1.66699 3.65008 2.40866 2.91675 5.36699 2.91675H11.2503"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.75 5.00008L15 6.25008L18.3333 2.91675" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CardTickIcon;
