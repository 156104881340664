import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Permissions } from '@utils/constants/permissions';
import { Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import VuesaxArrowRight from '../../../../../assets/icons/VuesaxArrowRight';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { setBalanceAndIds } from '../../../../../store/reducers/CrmSlice';
import { roundFloatNumber } from '../../../../../utils/helper/roundFloatNumber';
import { CrmLead } from '../../../../../utils/models/CrmLeadList';
import { PipeStatusesType } from '../../../../../utils/models/PipeAll';
import { TableConfig } from '../../../../../utils/models/TableConfig';

import '../listView.module.scss';

interface TableOrdersProps {
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
  data: CrmLead[];
  allIds: number[];
  totalBalance: number;
}

const TableListView: React.FC<TableOrdersProps> = ({ tableConfig, tableConfigFetch, isLoading, isFetching, data, allIds, totalBalance }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);
  const isCrmLeadGroup = permissions?.includes(Permissions.PERMISSION_CRM_LEAD_GROUP_FUNCTION);
  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);
  const tenRowIds = data?.map(item => item?.id);

  function removeElementsById(array1: CrmLead[], array2: number[]) {
    return array1.filter(item => array2.includes(item.id));
  }

  const tenRowBalances = data?.map(item => item?.balance);

  function sumArray(numbers: number[]) {
    return numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  const activeBalance = useAppSelector(state => state.assigmentSlice.balance);

  // go to one lid
  const goToLead = (item: CrmLead) => {
    if (item.pipeStatusType === PipeStatusesType.FIRST) {
      navigate(`${location.pathname}/${item.id}/${encodeURIComponent(item.name)}?incoming=true`);
    } else {
      navigate(`${location.pathname}/${item.id}/${encodeURIComponent(item.name)}`);
    }
  };

  const initialColumn: ColumnsType<{}> | undefined = [
    {
      align: 'left',
      title: t('home.Lid_nomi'),
      dataIndex: 'name'
    },
    {
      align: 'left',
      title: t('home.Asosiy_aloqa'),
      dataIndex: 'contactName'
    },
    {
      title: t('home.Yaratilgan_sana'),
      align: 'center',
      dataIndex: 'createdDate',
      render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
    },
    {
      title: t('home.Masul'),
      align: 'left',
      dataIndex: 'responsibleBy'
    },
    {
      title: t('home.Status'),
      align: 'center',
      render: (item: CrmLead) => (
        <span
          className="crmStatus"
          style={{
            color: item.pipeStatusColor,
            background: `${item.pipeStatusColor}15`
          }}
        >
          {item?.pipeStatusName}
        </span>
      )
    },
    {
      title: '',
      align: 'center',
      width: 80,
      render: record => (
        <span
          className="nextIconCrm"
          onClick={e => {
            e.stopPropagation();
            goToLead(record);
          }}
        >
          <VuesaxArrowRight />
        </span>
      )
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Lid_nomi':
              newConfig.push({
                align: 'left',
                title: t('home.Lid_nomi'),
                dataIndex: 'name'
              });
              break;
            case 'Asosiy_aloqa':
              newConfig.push({
                align: 'left',
                title: t('home.Asosiy_aloqa'),
                dataIndex: 'contactName'
              });
              break;
            case 'Yaratilgan_sana':
              newConfig.push({
                title: t('home.Yaratilgan_sana'),
                align: 'center',
                dataIndex: 'createdDate',
                render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                align: 'left',
                dataIndex: 'responsibleBy'
              });
              break;
            case 'Status':
              newConfig.push({
                title: t('home.Status'),
                align: 'center',
                render: (item: CrmLead) => (
                  <span
                    className="crmStatus"
                    style={{
                      color: item.pipeStatusColor,
                      background: `${item.pipeStatusColor}15`
                    }}
                  >
                    {item?.pipeStatusName}
                  </span>
                )
              });
              break;
            default:
              break;
          }
        }
      });
      newConfig.push({
        title: '',
        align: 'center',
        width: 80,
        render: (record: CrmLead) => (
          <span
            className="nextIconCrm"
            onClick={e => {
              e.stopPropagation();
              goToLead(record);
            }}
          >
            <VuesaxArrowRight />
          </span>
        )
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch]);

  // handle select one row
  const handleSelectOneRow = (record: any) => {
    const ids = [...activeIds];
    let balance = activeBalance;

    if (ids.find(id => id === record.id)) {
      balance = roundFloatNumber(balance! - record.balance);
      ids.splice(ids.indexOf(record.id), 1);
    } else {
      ids.push(record.id);
      balance = roundFloatNumber(balance + record.balance);
    }
    dispatch(
      setBalanceAndIds({
        ids,
        balance
      })
    );
  };

  // handle select all row
  const handleSelectAllRow = (checked: any) => {
    if (checked) {
      dispatch(
        setBalanceAndIds({
          ids: allIds,
          balance: totalBalance
        })
      );
    } else {
      dispatch(
        setBalanceAndIds({
          ids: [],
          balance: 0
        })
      );
    }
  };

  function removeCommonElements(arr1: number[], arr2: number[]) {
    const set = new Set(arr2);
    const result = arr1.filter(item => !set.has(item));

    return result;
  }

  function removeDuplicates(arr: number[]) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }

  // handle select ten row
  const handleSelectTenRow = (checked: any) => {
    if (!checked) {
      const result = removeCommonElements(activeIds, tenRowIds);

      dispatch(
        setBalanceAndIds({
          ids: removeDuplicates(result),
          balance: Number(activeBalance! - sumArray(tenRowBalances))
        })
      );
    } else {
      const result = activeIds?.concat(tenRowIds)?.filter((item: any) => item);

      dispatch(
        setBalanceAndIds({
          ids: removeDuplicates(result),
          balance: Number(activeBalance! + sumArray(tenRowBalances) - sumArray(removeElementsById(data, activeIds)?.map(item => item?.balance)))
        })
      );
    }
  };

  const tenActive = () => tenRowIds?.every((item: number) => activeIds?.includes(item));
  const tenIndeterminate = () => tenRowIds?.some((item: number) => activeIds?.includes(item));

  // rowSelection
  const rowSelection = {
    selectedRowKeys: activeIds,
    checkStrictly: true,
    renderCell: (checked: boolean, record: any) => (
      <div
        onClick={e => {
          e.stopPropagation();
          handleSelectOneRow(record);
        }}
        style={{
          padding: '10px 10px 10px 0'
        }}
      >
        <Checkbox checked={checked} />
      </div>
    ),
    columnTitle: () => (
      <div>
        <Checkbox
          id="selectAll"
          onChange={e => handleSelectAllRow(e?.target?.checked)}
          style={{ position: 'absolute', opacity: 0, zIndex: '-100' }}
        />
        {data?.length !== 0 && (
          <Checkbox
            onChange={e => handleSelectTenRow(e?.target?.checked)}
            defaultChecked={false}
            style={{ margin: 0 }}
            indeterminate={!tenActive() && tenIndeterminate()}
            checked={tenActive()}
          />
        )}
      </div>
    )
  };

  const sizeTable = activeIds.length === 0 ? '180px' : '240px';
  const sizeTableTop = activeIds.length === 0 ? '0px' : '72px';

  return (
    <Table
      {...(isCrmLeadGroup && { rowSelection })}
      loading={isLoading || tableConfigFetch || isFetching}
      className="custom_table custom_table_size custom_table_size_crm"
      size="small"
      style={{
        // @ts-ignore
        '--size': sizeTable,
        marginTop: sizeTableTop
      }}
      rowKey="id"
      pagination={false}
      columns={column}
      onRow={record => ({
        onClick: e => {
          // goToLead(e as CrmLead);
          e.stopPropagation();
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          activeIds?.length > 0 ? handleSelectOneRow(record) : goToLead(record as CrmLead);
        }
      })}
      dataSource={data}
    />
  );
};

export default TableListView;
