import { useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks/reduxHooks';
import { useGetAllTjm } from '@queries/queries';
import { setChangeBuildings, setChangeHouses } from '@reducers/BossCurrencySlice';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './tjmFilter.module.scss';

const TjmFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data } = useGetAllTjm();
  const [value, setValue] = useState<string[]>([]);
  const [_, setOpen] = useState(false);

  const tree = data?.map(item => ({
    value: `h-${item?.id}`,
    title: item?.name,
    key: `h-${item?.id}`,
    children: item?.buildings?.map(prev => ({
      value: `b-${prev?.id}`,
      title: prev?.name,
      key: `b-${prev?.id}`
    }))
  }));

  const houses1 = value?.filter(item => item?.split('-')[0] === 'h')?.map(item => Number(item?.split('-')[1]));
  const buildings = value?.filter(item => item?.split('-')[0] === 'b')?.map(item => Number(item?.split('-')[1]));

  const onChange = (newValue: string[]) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(setChangeHouses(houses1));
    dispatch(setChangeBuildings(buildings));
  }, [value]);

  const count = houses1?.length + buildings?.length;

  return (
    <div className={styles.container}>
      <TreeSelect
        style={{ width: '100%', height: '100%' }}
        value={value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder={t('home.Tanlang')}
        onChange={onChange}
        allowClear
        multiple
        treeData={tree}
        maxTagCount="responsive"
      />
      <div className={styles.container_count} onClick={() => setOpen(prev => !prev)}>
        {count > 0 && <div className={styles.container_count_circle}>{houses1?.length + buildings?.length}</div>}
        {count > 0 ? <span>{t('home.Tanlangan_TJM_lar')}</span> : <span style={{ opacity: '0.5', paddingLeft: 6 }}>{t('home.Tanlang')}</span>}
      </div>
    </div>
  );
};

export default TjmFilter;
