import { Modal, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './blocksPrepaymentModal.module.scss';
import { useBasicCurrency, useBossPrepaymentHouseBlocks } from '../../queries/queries';

interface Props {
  visible: boolean;
  close: (id: number, name: string, visible: boolean) => void;
  id: number;
  name: string;
  startDate: string;
  finishDate: string;
}

const BlocksPrepaymentModal: React.FC<Props> = ({ visible, close, id, name, startDate, finishDate }) => {
  const { isLoading, data, isFetching } = useBossPrepaymentHouseBlocks(id, startDate, finishDate);

  const { t } = useTranslation();

  const { data: currency } = useBasicCurrency();

  // handle close modal
  const handleCancel = () => {
    close(0, '', false);
  };

  return (
    <Modal
      width={539}
      className={styles.container}
      title={
        <>
          <h2>{name}</h2>
          <span> {t('boss_complex.Boshlangich_tolov')}</span>
        </>
      }
      visible={visible}
      footer={false}
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading || isFetching}>
        {data?.map((block, index) => (
          <div className={styles.container_item} key={index}>
            <div>{block.name}</div>
            <div>
              {block?.prepayment.toLocaleString('ru')} {currency?.ccy}
            </div>
          </div>
        ))}
      </Spin>
    </Modal>
  );
};

export default BlocksPrepaymentModal;
