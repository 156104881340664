import React from 'react';

const OutlayIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 15L15 5" stroke="#FF3F40" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.875 5H15V13.125" stroke="#FF3F40" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default OutlayIcon;
