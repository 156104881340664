import React, { FC, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';
import { FlatMore } from '@utils/models/FlatMore';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { FlatDiscount } from '../../../../utils/models/Discount';
import FooterButtons from '../footer/FooterButtons';

import CalculatorInputs from './CalculatorInputs';
import CalculatorTable from './CalculatorTable';

import styles from '../../homeId.module.scss';

interface IProps {
  idFlats?: number[];
  houseName?: string;
  flat: FlatMore | undefined;
}

const CalculatorCont: FC<IProps> = ({ idFlats, houseName, flat }) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<FlatDiscount[]>();
  const { user } = useAppSelector(state => state.userReducer);

  const isMultiPayment = user?.role?.permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  return (
    <div style={{ margin: '0 24px' }}>
      {!isMultiPayment && (
        <>
          <div className={styles.home_id_content_header}>
            <h2>{t('home_id.Ixtiyoriy_boshlangich_tolov')}</h2>
          </div>
          <Row gutter={[15, 0]}>
            <Col span={6}>
              <CalculatorInputs />
            </Col>
            <Col span={18}>
              <CalculatorTable setgetTableData={setTableData} />
            </Col>
          </Row>
        </>
      )}
      <FooterButtons idFlats={idFlats} houseName={houseName} flat={flat} />
    </div>
  );
};

export default CalculatorCont;
