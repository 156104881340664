import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import ClientMiniInfo from '@components/clientMiniInfo/ClientMiniInfo';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowCircleDownIcon from '../../../../assets/icons/contract/ArrowCircleDownIcon';
import SMSIcon from '../../../../assets/icons/contract/SMSIcon';
import DollarSquareBorderredIcon from '../../../../assets/icons/DollarSquareBorderredIcon';
import { useClientId } from '../../../../queries/queries';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { ClientType } from '../../../../utils/models/ClientType';
import { ContractId } from '../../../../utils/models/ContractId';
import ClientMessageModal from '../../../client/components/ClientMessageModal';

import styles from './contractIdClientInfo.module.scss';

interface IProps {
  data: ContractId | undefined;
  isLoading: boolean;
}

const ContractIdClientInfo: FC<IProps> = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const [clientMessage, setClientMessage] = useState(false);
  const { t, i18n } = useTranslation();
  const { data: clientId, isLoading: clientLoading } = useClientId(data?.clientId || -1);
  const [contentVisible, setContentVisible] = useState(false);
  const [infoMaxHeight, setInfoMaxHeight] = useState(0);

  const handleContentVisible = () => {
    setContentVisible(!contentVisible);
  };

  function Loading(element: JSX.Element) {
    return isLoading || clientLoading ? (
      <>
        <LoadingOutlined />
      </>
    ) : (
      element
    );
  }

  const closeClientMessage = () => {
    setClientMessage(false);
  };

  // handleSMS
  const handleSMS = () => {
    setClientMessage(true);
  };

  // handleClientBalance
  const handleClientBalance = () => {
    navigate(`${rootPaths.MAIN + MainPaths.PAYMENT}/${data?.clientId}${MainPaths.CLIENT_BALANCE}`);
  };

  return (
    <>
      <div className={styles.container}>
        {/* <h1 className={styles.container_title}>{t('titles.client-info')}</h1> */}
        <div className={styles.container_header}>
          <div className={styles.container_header_left}>
            {/* <div className={styles.container_header_left_avatar}>
              {clientId?.firstname?.slice(0, 2) || clientId?.name?.slice(0, 2)}
            </div>
            <div className={styles.container_header_left_content}>
              <h3>
                {clientId?.type === ClientType.NATURAL
                  ? Loading(
                      <>
                        {clientId?.lastname +
                          ' ' +
                          clientId?.firstname +
                          ' ' +
                          clientId?.fatherName}
                      </>
                    )
                  : clientId?.name}
              </h3>
              <div>
                {Loading(
                  <>
                    {clientId?.phones?.map((item) => (
                      <span key={item?.id}>
                        <CallIcon /> {item?.phone}
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div> */}
            <ClientMiniInfo
              name={clientId?.type === ClientType.NATURAL ? `${clientId?.lastname} ${clientId?.firstname} ${clientId?.fatherName}` : clientId?.name}
              phones={clientId?.phones?.map(item => item.phone) || []}
              score={clientId?.score}
              passportExpired={clientId?.passportExpired}
              level={clientId?.level}
            />
          </div>
          <div className={styles.container_header_right}>
            <Tooltip title={t('payment.Mijoz_balansi')}>
              <Button shape="circle" onClick={handleClientBalance}>
                <DollarSquareBorderredIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('client.Xabar_yuborish')}>
              <Button shape="circle" onClick={handleSMS}>
                <SMSIcon />
              </Button>
            </Tooltip>
            {/* <Button shape="circle">
              <MoreVerticalIcon size={16} />
            </Button> */}
          </div>
        </div>

        <div className={styles.container_content}>
          <div className={styles.container_content_row}>
            <div className={styles.container_content_row_item}>
              <p>{t('home.Viloyat')}</p>
              <h3>{Loading(<>{clientId?.city && clientId?.city[i18n.language as keyof typeof clientId.city]}</>)}</h3>
            </div>
            <div className={styles.container_content_row_item}>
              <p>{t('client_create_contract.Shahar_tuman')}</p>
              <h3>{Loading(<>{clientId?.district && clientId?.district[i18n.language as keyof typeof clientId.district]}</>)}</h3>
            </div>

            <div className={styles.container_content_row_item}>
              <p>{t('client.Manzil')}</p>
              <h3>{Loading(<>{clientId?.address}</>)}</h3>
            </div>
            {clientId?.type === ClientType.NATURAL && (
              <div className={styles.container_content_row_item}>
                <p>{t('client.Pasport_seriyasi_raqami')}</p>
                <h3>{Loading(<>{`${clientId?.passportSerial} ${clientId?.passportNumber}`}</>)}</h3>
              </div>
            )}
          </div>
        </div>
        <div className={styles.container_info} style={{ height: contentVisible ? infoMaxHeight : 0 }}>
          <div className={styles.container_info_content} ref={el => setInfoMaxHeight(el?.offsetHeight!)}>
            {clientId?.type === ClientType.NATURAL ? (
              <>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.Berilgan_vaqti')}</p>
                  <span>{Loading(<>{clientId?.passportDate}</>)}</span>
                </div>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.Tugilgan_kuni')}</p>
                  <span>{Loading(<>{clientId?.dateBirth}</>)}</span>
                </div>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.Ish_joyi')}</p>
                  <span>{Loading(<>{clientId?.workPlace}</>)}</span>
                </div>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.Berilgan_joyi')}</p>
                  <span>{Loading(<>{clientId?.passportPlace}</>)}</span>
                </div>
              </>
            ) : (
              <>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.INN')}</p>
                  <span>{Loading(<>{clientId?.inn}</>)}</span>
                </div>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.OKED')}</p>
                  <span>{Loading(<>{clientId?.oked}</>)}</span>
                </div>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.Bank_nomi')}</p>
                  <span>{Loading(<>{clientId?.bankName}</>)}</span>
                </div>
                <div className={styles.container_info_content_item}>
                  <p>{t('client.MFO')}</p>
                  <span>{Loading(<>{clientId?.mfo}</>)}</span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.container_arrowDown} onClick={handleContentVisible}>
          <ArrowCircleDownIcon style={{ transform: `rotate(${contentVisible ? 0 : 180}deg)` }} />
        </div>
      </div>
      <ClientMessageModal
        modalWidth={689}
        visible={clientMessage}
        close={closeClientMessage}
        name={clientId?.type === ClientType.NATURAL ? `${clientId?.lastname} ${clientId?.firstname} ${clientId?.fatherName}`! : clientId?.name!}
        phoneNumber={clientId?.phones?.find(item => item?.active)?.phone!}
        clientId={clientId?.id!}
      />
    </>
  );
};

export default ContractIdClientInfo;
