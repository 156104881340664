import { Modal } from 'antd';
import React, { useState } from 'react';
import './messageSendModal.module.scss';
import { useTranslation } from 'react-i18next';
import MessageBody from './MessageBody';
import TemplateMessage from './TemplateMessage';
import { TemplateMessage as TemplateMessageInterface } from '../../../../utils/models/TemplateMessage';
import Crown from '../../../../assets/icons/Crown';

const MessageSendModal = ({ visible, setVisible }: { visible: boolean; setVisible: (value: boolean) => void }) => {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState('allCustomer');

  const [showTemplateModal, setShowTemplateModal] = useState<{
    visible: boolean;
    template: TemplateMessageInterface | undefined;
  }>({
    visible: false,
    template: undefined
  });

  const handleCancel = () => {
    setVisible(false);
    handleChangeVisibleTemplatePage(false, undefined);
    setCustomer('allCustomer');
  };

  // handle change visible template page
  const handleChangeVisibleTemplatePage = (visible: boolean, template: any | undefined) => {
    setShowTemplateModal({
      template,
      visible
    });
  };

  return (
    <Modal
      className={'modalSend'}
      width={showTemplateModal.visible ? 560 : 721}
      title={
        showTemplateModal.visible ? (
          t('my_calls.SMS_xabarnoma_shablonlari')
        ) : (
          <div className="d-flex align-center">
            <h1>{t('titles.Umumiy_xabarnoma_yuborish')}</h1>
            <div className={'premium'}>
              <Crown />
              <p style={{ marginLeft: '4px' }}>{t('home.Premium')}</p>
            </div>
          </div>
        )
      }
      footer={false}
      visible={visible}
      onCancel={handleCancel}
    >
      {showTemplateModal.visible ? (
        <TemplateMessage handleChangeVisibleTemplatePage={handleChangeVisibleTemplatePage} template={showTemplateModal.template} />
      ) : (
        <MessageBody
          handleChangeVisibleTemplatePage={handleChangeVisibleTemplatePage}
          customer={customer}
          visible={visible}
          setVisible={setVisible}
          setCustomer={setCustomer}
        />
      )}
    </Modal>
  );
};

export default MessageSendModal;
