import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import CloseIcon from '@icons/CloseIcon';
import { crmIcons } from '@icons/crmIcons/crmIcons';
import Info1Icon from '@icons/Info1Icon';
import { useLeadTaskActions } from '@pages/task/services/mutation';
import { useGetTaskTypes } from '@pages/task/services/queries';
import { setChecked, setClearTaskListView, setTaskIgnoreIds } from '@reducers/CrmSlice';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { Button, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './actionModals.module.scss';

type Props = {
  openEmployeeChange: boolean;
  setOpenEmployeeChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Option } = Select;

const ChangeTypeModal: React.FC<Props> = ({ openEmployeeChange, setOpenEmployeeChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data } = useGetTaskTypes();
  const { searchParams } = useQueryParam<SearchParamsTask, any>();
  const changeTaskEmployee = useLeadTaskActions(searchParams);
  const [taskTypeId, setTaskTypeId] = useState<number>();
  const { taskListViewActiveIds: activeIds, taskListViewIgnoreIds } = useAppSelector(state => state.assigmentSlice);

  const handleClose = () => {
    setOpenEmployeeChange(false);
    setTaskTypeId(undefined);
  };

  const handleChangeTaskEmployee = () => {
    changeTaskEmployee
      .mutateAsync({
        tasks: activeIds,
        taskTypeId,
        actionType: 'CHANGE_TYPE',
        ignoreTasks: taskListViewIgnoreIds.length === 0 ? null : taskListViewIgnoreIds
      })
      .then(() => {
        handleClose();
        dispatch(setClearTaskListView());
        dispatch(setChecked(false));
        dispatch(setTaskIgnoreIds([]));
      });
  };

  return (
    <Modal visible={openEmployeeChange} onCancel={handleClose} title={false} footer={false} className={styles.changeEmployee}>
      <div className={styles.changeEmployee_body}>
        <div className={styles.changeEmployee_title}>
          <Info1Icon />
          <div className={styles.changeEmployee_title_right}>
            <div className={styles.changeEmployee_title_right_top}>
              <p>{t('one_task.Turini_ozgartirish')}</p>
              <CloseIcon onClick={handleClose} />
            </div>
            <span>{t('one_task.Siz_vazifani_turini_ozgartirmoqchimisiz')}</span>
          </div>
        </div>
        <div className={styles.changeEmployee_select}>
          <span>{t('one_task.Vazifa_turi')}</span>
          <Select value={taskTypeId} placeholder={t('premium_modal.Select')} onChange={e => setTaskTypeId(e)}>
            {data?.map(item => (
              <Option key={item?.id} value={item?.id}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  {crmIcons[item?.icon.split('#')[0]]?.icon(`#${item?.icon.split('#')[1]}`)} <span>{item?.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.changeEmployee_footer}>
          <Button onClick={handleClose}>{t('home.Yopish')}</Button>
          <Button loading={changeTaskEmployee.isLoading} onClick={handleChangeTaskEmployee}>
            {t('home_id.Tasdiqlash')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeTypeModal;
