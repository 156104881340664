import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { getTaskBody } from '@utils/helper/getSearchAndDownloadBodyAllTables';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { Pagination } from '@utils/models/Pagination';
import { TaskFilter } from '@utils/models/TaskFilter';
import { useInfiniteQuery, useQuery } from 'react-query';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';
import { TaskTableViewModel } from '../utils/models/TaskTableViewModel';
import { TaskEmployeeModel } from '../utils/models/TaskEmployeeModel';
import { TaskTypeModel } from '../utils/models/TaskTypeModel';

export function useTaskCRMFilter(
  size: number = 10,
  searchParams: SearchParamsTask,
  startDate: string | null = null,
  finishDate: string | null = null
) {
  return useInfiniteQuery<Pagination<TaskFilter>>(
    [queryKeys.TASK_FILTER_CRM, size, JSON.stringify(searchParams), startDate, finishDate],
    async ({ pageParam = 1 }) => {
      const res = await $api.post(endPoints.LEAD_TASK_FILTER, {
        ...getTaskBody(searchParams),
        page: pageParam,
        size: size,
        ...(startDate && { startDate: startDate }),
        ...(finishDate && { finishDate: finishDate })
      });
      return res.data.data?.leadTaskList;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage.totalPages) {
          return page.length + 1;
        } else {
          return undefined;
        }
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useTaskCalendarFilter(searchParams: SearchParamsTask, startDate: string, finishDate: string) {
  return useQuery<TaskFilter[]>(
    [queryKeys.TASK_FILTER_CALENDAR, startDate, finishDate, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(endPoints.LEAD_TASK_FILTER_CALENDAR, {
        ...getTaskBody(searchParams),
        startDate: startDate + ' 00:00',
        finishDate: finishDate + ' 23:59'
      });
      return res.data.data?.leadTaskList;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetTaskTableView(page: number, size: number = 15, searchParams: SearchParamsTask) {
  return useQuery<Pagination<TaskTableViewModel>>(
    [queryKeys.TABLE_VIEW_TASK, page, size, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(`${endPoints.TABLE_VIEW_TASK}?page=${page}&size=${size}`, {
        ...getTaskBody(searchParams),
        size: size,
        page: page
      });
      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useGetTaskEmployees() {
  return useQuery<TaskEmployeeModel[]>(
    [queryKeys.TASK_EMPLOYEE],
    async () => {
      const res = await $api.get(endPoints.TASK_EMPLOYEE);
      return res.data.data;
    },

    {
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetTaskTypes() {
  return useQuery<TaskTypeModel[]>(
    [queryKeys.TASK_TYPE],
    async () => {
      const res = await $api.get(endPoints.TASK_TYPE);
      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}
