import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { clearMonthlyPaymentsTable } from '../../store/reducers/MonthlyPaymentsSlice';

import styles from '../../pages/order/order.module.scss';

interface Props {
  visible: boolean;
  onCancel: (visible: boolean) => void;
}

const ClearTableModal: React.FC<Props> = ({ visible, onCancel }) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    onCancel(false);
  };

  const handelClearTable = () => {
    dispatch(clearMonthlyPaymentsTable());
    handleClose();
  };
  const { t } = useTranslation();

  return (
    <Modal className={styles.cancel_order_modal} closable={false} footer={null} width={420} visible={visible} onCancel={handleClose}>
      <div className={`${styles.icon_cont} d-flex justify-center`}>
        <span className={`${styles.icon} d-flex align-center justify-center`}>
          <CancelBronGarbage />
        </span>
      </div>
      <h1 style={{ marginBottom: '24px' }} className={styles.title}>
        {t(`changes.monthlyPayments.Jadval_ma’lumotlarini_o’chirib_yubormoqchimisiz?`)}
      </h1>
      <div className="d-flex align-center">
        <Button type="primary" onClick={handleClose} className={styles.btn_cancel}>
          {t(`changes.monthlyPayments.Yopish`)}
        </Button>
        <Button onClick={handelClearTable} danger type="primary" className={styles.btn_confirm}>
          {t(`changes.monthlyPayments.O'chirish`)}
        </Button>
      </div>
    </Modal>
  );
};

export default ClearTableModal;
