import React from 'react';
import styles from '../paymentSettings.module.scss';
import logo from '../../../assets/img/uzcard.png';
import { Button, Form, Input, Spin, TreeSelect } from 'antd';
import { useCashCreate } from '../../../queries/mutation';
import { useTranslation } from 'react-i18next';
import { Cash, SystemTypeCash } from '../../../utils/models/Cash';
import { useGetAllBuildings } from '@queries/queries';
import { CashModel } from './CashModel';

interface Props {
  data: Cash | undefined;
}

const LastUzcard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { data: buildingAll } = useGetAllBuildings();
  const createPaymeCash = useCashCreate();

  const tree = buildingAll?.map(item => {
    return {
      title: item?.houseName,
      value: `0-${item?.houseId}`,
      key: `0-${item?.houseId}`,
      children: item?.buildingCompactDto?.map((prev, i) => {
        return {
          title: prev?.name,
          value: `1-${prev?.id}`,
          key: `1-${prev?.id}`
        };
      })
    };
  });

  const tProps = {
    treeData: tree,
    treeCheckable: true,
    placeholder: `${t('boss_crm.Manbaalarni_tanlang')}...`,
    style: {
      width: 298
    }
  };

  const onFinish = (values: CashModel) => {
    createPaymeCash.mutate({
      cashId: values.cashId?.trim(),
      cashKey: values.cashKey?.trim(),
      systemType: SystemTypeCash.MY_UZCARD,
      buildingIds: values?.buildingIds?.map(item => {
        return Number(item?.toString().replace('1-', ''));
      })
    });
  };

  return (
    <Spin spinning={createPaymeCash.isLoading}>
      <div className={'d-flex align-center ' + styles.margin_bottom}>
        <div className={styles.logo}>
          <img src={logo} alt={'Payme'} />
        </div>
        <div className={styles.cont}>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className={'d-flex align-center'}>
              <div className={styles.cont_inputCont}>
                <Form.Item
                  label={<label className={styles.label}>{t('uzcard.Login')}</label>}
                  rules={[{ required: true, message: '' }]}
                  name={'cashId'}
                >
                  <Input className={styles.input} disabled={!!data} />
                </Form.Item>
              </div>
              <div className={styles.cont_inputCont}>
                <Form.Item
                  label={<label className={styles.label}>{t('uzcard.Parol')}</label>}
                  rules={[{ required: true, message: '', min: 4 }]}
                  name={'cashKey'}
                >
                  <Input className={styles.input} disabled={!!data} />
                </Form.Item>
              </div>
              <div className={styles.cont_inputCont}>
                <Form.Item
                  label={<label className={styles.label}>{t('home.obyekt')}</label>}
                  rules={[{ required: true, message: '' }]}
                  name={'buildingIds'}
                >
                  <TreeSelect {...tProps} maxTagCount={'responsive'} disabled={!!data} />
                </Form.Item>
              </div>
              {!data && (
                <div className={styles.cont_button_cont}>
                  <Button loading={createPaymeCash.isLoading} htmlType={'submit'} className={styles.cont_button_cont_button} type={'primary'}>
                    {t('paymeCash.Saqlash')}
                  </Button>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default LastUzcard;
