import React, { useState } from 'react';
import { useAppDispatch } from '@hooks/reduxHooks';
import { PipeTemplateModel } from '@pages/voronka-shablon/utils/models/PipeTemplateModel';

import CreateVoronka1 from '../create-voronka/CreateVoronka1';

import shablonBg from '../../../../../assets/img/shablonBg.png';

import styles from './shablonModal.module.scss';

type Props = {
  data: PipeTemplateModel[] | undefined;
  setOpenShablon: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
};

const ShablonItem: React.FC<Props> = ({ data, setOpenShablon, title }) => {
  const [shablonId, setShablonId] = useState<number | undefined>();
  const [createOpen, setOpenCreate] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <h4>{title}</h4>
        <div className={styles.container_items}>
          {data?.map((item, index) => (
            <div
              key={index}
              className={styles.container_items_item}
              onClick={() => {
                setOpenShablon(false);
                setOpenCreate(true);
                setShablonId(item?.id);
              }}
            >
              <div className={styles.img}>
                <img src={shablonBg} alt="err" />
              </div>
              <p>{item?.name}</p>
            </div>
          ))}
        </div>
      </div>
      <CreateVoronka1 shablonId={shablonId} createOpen={createOpen} setOpenCreate={setOpenCreate} setOpenShablon={setOpenShablon} />
    </>
  );
};

export default ShablonItem;
