import React from 'react';

const FacebookBlueIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.75 23.25H10.25V16H9.29999C8.71999 16 8.25 15.53 8.25 14.95V13.05C8.25 12.47 8.71999 12 9.29999 12H10.25V9.5C10.25 7.43 11.93 5.75 14 5.75H16.7C17.28 5.75 17.75 6.21999 17.75 6.79999V9.20001C17.75 9.78001 17.28 10.25 16.7 10.25H14.75V12H16.63C16.95 12 17.24 12.14 17.44 12.38C17.64 12.62 17.72 12.94 17.66 13.25L17.28 15.15C17.18 15.64 16.75 15.99 16.25 15.99H14.75V23.25ZM11.75 21.75H13.25V14.5H15.89L16.09 13.5H13.26V9.79999C13.26 9.21999 13.73 8.75 14.31 8.75H16.26V7.25H14C12.76 7.25 11.75 8.26 11.75 9.5V13.5H9.75V14.5H11.75V21.75Z"
        fill="#3772FF"
      />
      <path
        d="M15 23.25H9C3.57 23.25 1.25 20.93 1.25 15.5V9.5C1.25 4.07 3.57 1.75 9 1.75H15C20.43 1.75 22.75 4.07 22.75 9.5V15.5C22.75 20.93 20.43 23.25 15 23.25ZM9 3.25C4.39 3.25 2.75 4.89 2.75 9.5V15.5C2.75 20.11 4.39 21.75 9 21.75H15C19.61 21.75 21.25 20.11 21.25 15.5V9.5C21.25 4.89 19.61 3.25 15 3.25H9Z"
        fill="#3772FF"
      />
    </svg>
  );
};

export default FacebookBlueIcon;
