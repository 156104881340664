export const queryKeys = {
  CONTRACT_AND_CLIENT_INFO_CHANGES: 'CONTRACT_AND_CLIENT_INFO_CHANGES',
  CHANGES_PAYMENT: 'CHANGES_PAYMENT',
  CHANGES_MONTHLY_PAYMENT: 'CHANGES_MONTHLY_PAYMENT',
  BUILDING_ID: 'BUILDING_ID',
  FLAT_COMPACT: 'FLAT_COMPACT',
  CHANGES_CONTRACT_LIST: 'CHANGES_CONTRACT_LIST',
  ONE_CONTRACT_CHANGE: 'ONE_CONTRACT_CHANGE',
  CONTRACT_EMPLOYEE: 'CONTRACT_EMPLOYEE',
  ONE_CLIENT_CHANGE: 'ONE_CLIENT_CHANGE',
  SEARCH_CLIENT_CHANGES: 'SEARCH_CLIENT_CHANGES'
};
