import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './qrCode.module.scss';
import ShieldDoneIcon from '../../assets/icons/ShieldDoneIcon';
import { useGetPaymentDelay } from './services/queries';
import { useTranslation } from 'react-i18next';
import ArrowDownBottom from '../../assets/icons/ArrowDownBottom';
import uzImg from '../../assets/img/lang/uz.png';
import ruImg from '../../assets/img/lang/ru.png';
import enImg from '../../assets/img/lang/en.png';
import { Dropdown } from 'antd';

const QrCode = () => {
  const [visible, setVisible] = useState(false);
  const { i18n, t } = useTranslation();
  const { data } = useGetPaymentDelay();

  const langs = [
    {
      img: uzImg,
      text: 'o’zbek',
      lang: 'uz'
    },
    {
      img: ruImg,
      text: 'русский',
      lang: 'ru'
    },
    {
      img: enImg,
      text: 'english',
      lang: 'en'
    }
  ];

  return (
    <div className={styles.container}>
      {data && (
        <div className={styles.body}>
          <h1>
            <ShieldDoneIcon status={data?.contractStatus} /> {t('home.' + data?.contractStatus)}
          </h1>
          <div>
            <h3>{t('contract_table_update.Shartnoma_raqami')}:</h3>
            <h2>{data?.contractNumber}</h2>
          </div>
          <div>
            <h3>{t('titles.builder-config')}:</h3>
            <h2>{data?.builder}</h2>
          </div>
          <div>
            <h3>{t('home.Tuzilgan')}:</h3>
            <h2>{data?.createdDate}</h2>
          </div>
          <div>
            <h3>{t('create_payment.uy')}:</h3>
            <h2>{data?.house}</h2>
          </div>
          <div>
            <h3>{t('create_payment.Mijoz')}:</h3>
            <h2>{data?.client}</h2>
          </div>
        </div>
      )}
      <div className={styles.footer}>
        {data && (
          <p>
            {t('home.Quruvchi_telefon_raqami')}: <span> {data?.numberOfBuilder}</span>
          </p>
        )}
        <Link to={'/'}>{t('home.Uysot_sahifasiga_otish')}</Link>
      </div>
      <Dropdown
        trigger={['click']}
        visible={visible}
        onVisibleChange={setVisible}
        overlay={
          <div className={styles.lang_drawer}>
            {langs
              ?.filter(item => item?.lang !== i18n?.language)
              ?.map(item => (
                <div
                  key={item?.lang}
                  onClick={() => {
                    i18n.changeLanguage(item?.lang);
                    setVisible(false);
                  }}
                >
                  <img src={item?.img} alt="" /> {item?.text}
                </div>
              ))}
          </div>
        }
      >
        <div className={styles.lang}>
          <img src={langs?.find(item => item?.lang === i18n.language)?.img} alt="" /> {langs?.find(item => item?.lang === i18n.language)?.text}{' '}
          <ArrowDownBottom />
        </div>
      </Dropdown>
    </div>
  );
};

export default QrCode;
