import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CancelBronGarbage from '../../../../../../assets/icons/CancelBronGarbage';

import styles from './deleteModal.module.scss';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { SearchParamsCrm } from '../../../filter/FilterButton';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { useGroupActionCrmViewList } from '../../../../services/mutation';
import { setBalanceAndIds, setClearListView } from '@reducers/CrmSlice';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import { useQueryClient } from 'react-query';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
}

const DeleteLead: React.FC<Props> = ({ visible, close, ids }) => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const qc = useQueryClient();
  const dispatch = useAppDispatch();
  const pipeStatusIds = JSON.parse(localStorage.getItem('pipeIds')!);

  // handle close
  const handleClose = () => {
    close(false);
  };

  // handle success
  function handleSuccess() {
    handleClose();
    dispatch(setClearListView());
  }

  const deleteLead = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);

  // handelClearTable
  const handelClearTable = () => {
    deleteLead
      .mutateAsync({
        requestMethodType: RequestMethodTypeGroupActions.DELETE_LEAD,
        leadIds: ids
      })
      .then(() => {
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
      });
  };

  return (
    <Modal className={styles.container} closable={false} footer={null} width={543} visible={visible} onCancel={handleClose}>
      <div className={`${styles.icon_cont} d-flex justify-center`}>
        <span className={`${styles.icon} d-flex align-center justify-center`}>
          <CancelBronGarbage />
        </span>
      </div>
      <h1 style={{ marginBottom: '24px' }} className={styles.title}>
        {t('list_view.Haqiqatan_ham_tanlangan_elementlarni')}
      </h1>
      <div className={'d-flex align-center justify-center'}>
        <Button type={'primary'} onClick={handleClose} className={styles.btn_cancel}>
          {t(`changes.monthlyPayments.Yopish`)}
        </Button>
        <Button onClick={handelClearTable} danger type={'primary'} className={styles.btn_confirm} loading={deleteLead.isLoading}>
          {t(`changes.monthlyPayments.O'chirish`)}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteLead;
