import { cx } from '@utils/cx';
import BaseInput, { InputProps as BaseInputProps } from 'antd/lib/input';

import { BaseProps, Wrapper } from './wrapper';

export interface InputProps extends BaseProps, BaseInputProps {
  name: string;
  type?: 'number' | 'text';
  loading?: boolean;
}

const Input: React.FC<InputProps> = ({ labelClassName, name = '', control, disabled, loading = false, label, ...args }) => {
  label = label || args.placeholder;

  return (
    <Wrapper {...{ control, name, label, labelClassName, disabled }}>
      {(field, error) => (
        <BaseInput
          {...args}
          {...field}
          value={field.value || undefined}
          id={name}
          className={cx('field', args.className)}
          status={error ? 'error' : undefined}
          disabled={disabled || field.disabled || loading}
        />
      )}
    </Wrapper>
  );
};

export default Input;
