import { t } from 'i18next';

export const priceSeparator = (price: number) => {
  if (price < 1000) {
    return price;
  }
  if (price < 1000000) {
    return (price / 1000).toFixed(1) + t('home.ming');
  }
  if (price < 1000000000) {
    return (price / 1000000).toFixed(1) + t('home.mln');
  }
  if (price < 1000000000000) {
    return (price / 1000000000).toFixed(1) + t('home.mlrd');
  }
  return (price / 1000000000000).toFixed(1) + t('home.trln');
};
