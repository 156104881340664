import { Button, Form, Input, Modal, Select } from 'antd';
import React, { FC, useEffect } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import '@pages/shaxmatka/shaxmatka.module.scss';
import { useBuilding, useBuildingId, useCreateBuilding } from '@queries/mutation';
import { useTranslation } from 'react-i18next';
import { useBuildingEdit } from '@queries/queries';
import DatePickerGlobal from '@components/date-picker/DatePicker';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@utils/constants/format';
import { PriceCalculateType } from '@utils/models/PriceCalculateType';

interface CreateBuildingModalProps {
  visible: boolean;
  setVisible: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: null;
    }>
  >;
  id?: number | null;
}

const CreateBuildingModal: FC<CreateBuildingModalProps> = ({ visible, setVisible, id }) => {
  const { data: buildingEdit } = useBuildingEdit(Number(id));
  const buildingIdEdit = useBuildingId();
  const [form] = Form.useForm();
  const { params, push, searchParams } = useQueryParam<any, any>();
  const createBuilding = useCreateBuilding();
  const handleCancel = () => {
    setVisible({ visible: false, id: null });
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (createBuilding.isLoading || buildingIdEdit.isLoading) {
      form.resetFields();
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBuilding.isLoading, buildingIdEdit.isLoading]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name: buildingEdit?.name,
        floor: buildingEdit?.floor,
        startDate: dayjs(buildingEdit?.startDate, DATE_FORMAT),
        finishDate: dayjs(buildingEdit?.finishDate, DATE_FORMAT),
        priceCalculateType: buildingEdit?.priceCalculateType
      });
    } else {
      form.resetFields();
    }
  }, [visible, id, buildingEdit, form]);

  const onFinish = (values: any) => {
    if (id) {
      createBuilding
        .mutateAsync({
          id: Number(searchParams?.blockId),
          houseId: Number(params.id),
          name: values.name,
          floor: values?.floor,
          startDate: values.startDate.format(DATE_FORMAT),
          finishDate: values.finishDate.format(DATE_FORMAT),
          priceCalculateType: values?.priceCalculateType
        })
        .then(res => {
          push({
            search: `?blockId=${res.data.id}&blockName=${values.name}`
          });
        });
    } else {
      createBuilding
        .mutateAsync({
          houseId: Number(params.id),
          name: values.name,
          floor: values?.floor,
          startDate: values.startDate.format(DATE_FORMAT),
          finishDate: values.finishDate.format(DATE_FORMAT),
          priceCalculateType: values?.priceCalculateType
        })
        .then(res => {
          push({
            search: `?blockId=${res.data.id}&blockName=${values.name}`
          });
        });
    }
  };
  return (
    <Modal
      visible={visible}
      title={id ? t('home:Bino_nomini_ozgartirish') : t('shaxmatka.Bino_yaratish')}
      wrapClassName="createBuildingModal"
      footer={false}
      onCancel={handleCancel}
      width={407}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label={t('shaxmatka.Bino_nomi')} name={'name'} rules={[{ required: true }]}>
          <Input placeholder={t('shaxmatka.Masalan_Blok')} />
        </Form.Item>
        <Form.Item label={t('shaxmatka.qavatlar_soni')} name={'floor'} rules={[{ required: true }]}>
          <Input placeholder={t('shaxmatka.Masalan_3')} />
        </Form.Item>
        <Form.Item label={t('home.Narxlash')} name={'priceCalculateType'} rules={[{ required: true }]} initialValue={PriceCalculateType.PER_AREA}>
          <Select className="customSelect">
            <Select.Option value={PriceCalculateType.PER_AREA}>{t('home.1_m_narxi_boyicha')}</Select.Option>
            <Select.Option value={PriceCalculateType.STATIC}>{t('home.Umumiy_narxi_boyicha')}</Select.Option>
          </Select>
        </Form.Item>
        <div className="d-flex justify-space-between">
          <DatePickerGlobal
            form={form}
            formItemName="startDate"
            label={t('house_config.Boshlanish_sanasi')}
            rules={[{ required: true }]}
            noStyle={false}
            initialValue={dayjs()}
          />
          <DatePickerGlobal
            form={form}
            formItemName="finishDate"
            label={t('house_config.Yakunlanish_sanasi')}
            rules={[{ required: true }]}
            noStyle={false}
            initialValue={dayjs().add(1, 'month')}
          />
        </div>
        <div className="createBuildingModalFooter">
          <Button onClick={handleCancel}>{t('shaxmatka.Bekor_qilish')}</Button>
          <Button htmlType="submit" type="primary">
            {t('shaxmatka.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateBuildingModal;
