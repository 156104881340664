import React, { FC } from 'react';
import { Button, Form, Select, TreeSelect } from 'antd';
import { useCrmEmployee, usePipeAll } from '../../../../queries/queries';
import CheveronBottom from '../../../../assets/icons/CheveronBottom';
import ReceiveSquare from '../../../../assets/icons/ReceiveSquare';
import SidebarArrowRightIcon from '../../../../assets/icons/SidebarArrowRightIcon';
import ArrowDownIcon from '../../../../assets/icons/ArrowDownIcon';

import styles from './messageDrawer.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  filterVisible: boolean;
  setFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterData: React.Dispatch<
    React.SetStateAction<
      | {
          pipeStatusIds: number[] | null;
          responsibleByIds: number[] | null;
          types: string[] | null;
        }
      | undefined
    >
  >;
}

const MessageDrawerFilter: FC<IProps> = ({ filterVisible, setFilterVisible, setFilterData }) => {
  const { t } = useTranslation();
  const { data: pipeAll } = usePipeAll();
  const { data: employeeData } = useCrmEmployee();
  const [form] = Form.useForm();

  //handleClose
  const handleClose = () => {
    setFilterVisible(false);
  };

  //onFinish
  const onFinish = (values: { pipeStatusIds: number[] | null; responsibleByIds: number[] | null; types: string[] | null }) => {
    setFilterData(values);
    handleClose();
  };

  //handleReset
  const handleReset = () => {
    setFilterData({
      pipeStatusIds: null,
      responsibleByIds: null,
      types: null
    });
    form.resetFields();
    handleClose();
  };

  return (
    <div className={`${styles.filter} ${filterVisible && styles.filter_active}`}>
      <div className={styles.filter_header}>
        <h3>{t('showroom.Filtr')}</h3>
        <span onClick={handleReset}>
          <ReceiveSquare size={16} stroke={'#FF3F40'} /> {t('home.Tozalash')}
        </span>
      </div>
      <Form className={styles.filter_form} form={form} onFinish={onFinish}>
        <Form.Item name={'pipeStatusIds'} noStyle>
          {/* <Select
            className="customSelect"
            suffixIcon={<CheveronBottom />}
            placeholder={'Voronka, statuslar'}
            mode="multiple"
          >
            {pipeStatus?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {' '}
                {item.name}
              </Select.Option>
            ))}
          </Select> */}
          <TreeSelect
            treeExpandAction={'click'}
            switcherIcon={<SidebarArrowRightIcon />}
            suffixIcon={<ArrowDownIcon />}
            placeholder={t('home.Voronka_statuslar')}
            dropdownClassName="FacebookFormsTreeSelect"
            className="customSelect"
            treeCheckable
          >
            {pipeAll?.map(item => (
              <TreeSelect.TreeNode
                title={item.name}
                value={item.id}
                key={item.id}
                selectable={false}
                children={item.pipeStatuses.map(pipe => (
                  <TreeSelect.TreeNode
                    key={pipe?.id}
                    value={pipe.id!}
                    title={
                      <div
                        //@ts-ignore
                        pageName={item?.name}
                      >
                        <span
                          style={{
                            background: pipe.color,
                            color: '#FFFFFF',
                            padding: '2px 7px',
                            borderRadius: '5px',
                            fontSize: 10
                          }}
                        >
                          {pipe.name}
                        </span>
                      </div>
                    }
                  />
                ))}
              />
            ))}
          </TreeSelect>
        </Form.Item>
        <Form.Item name={'responsibleByIds'} noStyle>
          <Select className="customSelect" suffixIcon={<CheveronBottom />} placeholder={t('triggers.Masul_xodim')} mode="multiple">
            {employeeData?.map(item => (
              <Select.Option value={item?.id} key={item?.id}>
                {item?.fullName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'types'} noStyle>
          <Select className="customSelect" suffixIcon={<CheveronBottom />} placeholder={t('list_view.Chat_manbasi')} mode="multiple">
            <Select.Option value="INSTAGRAM">{t('company_links.COMPANY_INSTAGRAM')}</Select.Option>
            <Select.Option value="TELEGRAM">{t('company_links.COMPANY_TELEGRAM')}</Select.Option>
            <Select.Option value="FACEBOOK">{t('company_links.COMPANY_FACEBOOK')}</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.filter_form_footer}>
          <Button type="primary" htmlType="submit">
            {t('showroom.Filtr')}
          </Button>
          <Button onClick={handleClose}>{t('crm.Bekor_qilish')}</Button>
        </div>
      </Form>
    </div>
  );
};

export default MessageDrawerFilter;
