import React from 'react';

const PremiumClientIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 8L14.2255 6.00714L14.4727 3.37143L11.8473 2.78571L10.4727 0.5L8 1.54286L5.52727 0.5L4.15273 2.77857L1.52727 3.35714L1.77455 6L0 8L1.77455 9.99286L1.52727 12.6357L4.15273 13.2214L5.52727 15.5L8 14.45L10.4727 15.4929L11.8473 13.2143L14.4727 12.6286L14.2255 9.99286L16 8Z"
      fill="#0075FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5056 5.259C11.797 5.56185 11.7877 6.04354 11.4848 6.33489L7.30512 10.3559C7.15796 10.4975 6.96007 10.574 6.75594 10.5682C6.55182 10.5624 6.3586 10.4748 6.21972 10.325L4.87067 8.87071C4.58488 8.56261 4.60296 8.08117 4.91106 7.79538C5.21916 7.50958 5.7006 7.52767 5.9864 7.83576L6.80847 8.72199C6.88737 8.80089 8.85338 6.78773 10.4328 5.24055C10.733 4.94647 11.2143 4.95615 11.5056 5.259Z"
      fill="white"
    />
  </svg>
);

export default PremiumClientIcon;
