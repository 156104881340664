import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { brojectConstants, brojectSchema } from '..';

export const useBrojectConfigSave = () => {
  const qc = useQueryClient();

  return useMutation(
    async (data: brojectSchema.IBrojectConfig) => {
      const res = await $api.post('/v1/broject-config', data);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([brojectConstants.queryKeys.BROJECT_CONFIG]);
      }
    }
  );
};
