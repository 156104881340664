import React from 'react';

const ArrowCircleDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="6.5" transform="matrix(1 0 0 -1 12.5 12.5)" fill="white" />
      <path
        d="M12 22C6.49 22 2 17.51 2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22ZM16.06 12.73L12.53 9.2C12.38 9.05 12.19 8.98 12 8.98C11.81 8.98 11.62 9.05 11.47 9.2L7.94 12.73C7.65 13.02 7.65 13.5 7.94 13.79C8.23 14.08 8.71 14.08 9 13.79L12 10.79L15 13.79C15.29 14.08 15.77 14.08 16.06 13.79C16.35 13.5 16.35 13.03 16.06 12.73Z"
        fill="#464255"
      />
    </svg>
  );
};

export default ArrowCircleDownIcon;
