/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Pagination, Spin } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import { useOneEmployeeInformation } from '../../queries/queries';
import DatePicker from '../../service/datePicker';
import { MONTH_FORMAT } from '../../utils/constants/format';

import TableOneBroker from './TableBalance';

import styles from './kpiInformation.module.scss';

const { RangePicker } = DatePicker;

const disabledDate: RangePickerProps['disabledDate'] = current => current && current > dayjs().endOf('day');

const KpiInformation = () => {
  const [selectedMonth, setSelectedMonth] = useState([dayjs(new Date()).format(MONTH_FORMAT), dayjs(new Date()).format(MONTH_FORMAT)]);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  const { data, isLoading } = useOneEmployeeInformation(selectedMonth);

  // console.log('KPI-duck', data);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeMonth = (date: any, dateString: string[]) => {
    setSelectedMonth(dateString);
  };

  const getTableData = () => {
    if (data?.payments) {
      const table = [...data?.payments];
      const page = currentPage - 1;

      return table.slice(page * 10, page * 10 + 10);
    }
    return [];
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <div className={styles.container}>
          <div className={styles.container_balance}>
            <div className={styles.container_balance_rigth}>
              <div className={styles.container_balance_rigth_title}>{t('broker.Joriy_balans')}</div>
              <div className={styles.container_balance_rigth_value}>
                <div className={styles.driver_balance_right_value} style={{ fontSize: '30px' }}>
                  {data?.balances
                    ?.map((item: any) => {
                      const currency = item?.currencyDto?.ccy;
                      const amount = item?.amount;

                      return `${amount?.toLocaleString('ru')} ${currency}`;
                    })
                    .join(' | ')}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container_select}>
            {/* @ts-ignore */}
            <RangePicker
              disabledDate={disabledDate as any}
              onChange={handleChangeMonth}
              allowClear={false}
              value={selectedMonth ? [dayjs(selectedMonth[0], MONTH_FORMAT), dayjs(selectedMonth[1], MONTH_FORMAT)] : undefined}
              picker="month"
              format={MONTH_FORMAT}
              size="small"
              placeholder={[t('broker.Boshlanishi'), t('broker.Tugashi')]}
            />
          </div>
          <div className={styles.container_info}>
            <div className={styles.container_info_item}>
              <p className={styles.container_info_item_title}>{t('broker.Sotilgan_xonadonlar_soni')}</p>
              <p className={styles.container_info_item_value}>{data?.contracts}</p>
            </div>
            <div className={styles.container_info_item}>
              <p className={styles.container_info_item_title}>{t('broker.Boshlangich_tolovlar_undirilgan_shartnomalar_soni')}</p>
              <p className={styles.container_info_item_value}>{data?.activeContracts}</p>
            </div>
            <div className={styles.container_info_item}>
              <p className={styles.container_info_item_title}>{t('broker.Hisoblangan_mukofot_puli')}</p>
              <p className={styles.container_info_item_value} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: 16 }}>
                {/* @ts-ignore */}
                {data?.reward?.map((item: any, index: number) => {
                  const currency = item?.currencyDto?.ccy;
                  const amount = item?.amount;
                  // @ts-ignore
                  const isLastItem = index === data?.reward?.length - 1;

                  return (
                    <React.Fragment key={index}>
                      <span key={index} style={{ marginBottom: '-10px' }}>
                        {amount?.toLocaleString('ru')} {currency}
                      </span>
                      {!isLastItem}
                    </React.Fragment>
                  );
                })}
              </p>
            </div>
            <div className={styles.container_info_item}>
              <p className={styles.container_info_item_title}>{t('broker.Qabul_qilib_olingan_mukofot_pullari_summasi')}</p>
              <p className={styles.container_info_item_value} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: 16 }}>
                {/* @ts-ignore */}
                {data?.paymentSum?.map((item: any, index: number) => {
                  const currency = item?.currencyDto?.ccy;
                  const amount = item?.amount;
                  // @ts-ignore
                  const isLastItem = index === data?.reward?.length - 1;

                  return (
                    <React.Fragment key={index}>
                      <span key={index} style={{ marginBottom: '-10px' }}>
                        {amount?.toLocaleString('ru')} {currency}
                      </span>
                      {!isLastItem}
                    </React.Fragment>
                  );
                })}
              </p>
            </div>
          </div>
          <TableOneBroker currentPage={currentPage} data={getTableData()} />
          <div className={styles.container_pagination}>
            <Pagination
              onChange={handlePageChange}
              prevIcon={
                <div className={styles.container_pagination_next_prev_buttons}>
                  <PaginationPrev />
                </div>
              }
              nextIcon={
                <div className={styles.container_pagination_next_prev_buttons}>
                  <PaginationNext />
                </div>
              }
              pageSize={10}
              showSizeChanger={false}
              current={currentPage}
              total={data?.payments.length}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default KpiInformation;
