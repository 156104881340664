import { PipeStatuses } from '@utils/models/PipeAll';
import queryString from 'query-string';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { getCrmBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { CrmLeadList, CrmLeadListV2 } from '../../../utils/models/CrmLeadList';
import { OneLid } from '../../../utils/models/OneLid';
import { ReasonsRefusal } from '../../../utils/models/ReasonsRefusal';
import { TriggerList } from '../../../utils/models/Trigger';
import { SearchParamsCrm } from '../components/filter/FilterButton';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { CrmUserRoleModel } from '../utils/models/CrmUserRoleModel';

// Lead filter
export function useLeadFilter(pipeStatusId: number, searchParams: SearchParamsCrm, enabled?: boolean) {
  const qc = useQueryClient();

  return useInfiniteQuery<CrmLeadListV2>(
    [queryKeys.CRM_LEAD_FILTER, pipeStatusId, searchParams],
    async ({ pageParam = 1 }) => {
      if (enabled) {
        const res = await $api.post(endPoints.LEAD_FILTER_V2, {
          ...getCrmBody(searchParams),
          page: pageParam,
          size: 20,
          pipeStatusIds: [pipeStatusId]
        });

        return res.data.data;
      }
      const scrollDataState = qc.getQueryData([queryKeys.CRM_LEAD_FILTER, pipeStatusId, searchParams]) as any;

      return scrollDataState?.pages[0] || undefined;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.leadList?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      refetchInterval: Infinity,
      refetchOnWindowFocus: false
    }
  );
}

export function useTriggersList(pipeStatusId: number) {
  return useQuery<TriggerList[]>(
    [queryKeys.TRIGGERS_LIST, pipeStatusId],
    async () => {
      const res = await $api.get(`${endPoints.CRM_TRIGGERS}?pipeStatusId=${pipeStatusId}`);

      return res.data.data;
    },
    {
      enabled: !!pipeStatusId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useReasonsForRefusal() {
  return useQuery<ReasonsRefusal[]>(
    [queryKeys.REASONS_FOR_REFUSAL],
    async () => {
      const res = await $api.get(endPoints.REASONS_FOR_REFUSAL);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useLeadList(pipeId: number, searchParams: SearchParamsCrm) {
  return useQuery<CrmLeadList>(
    [queryKeys.CRM_LEAD_LIST, pipeId, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(endPoints.LEAD_TABLE_FILTERS_V2, {
        ...getCrmBody(searchParams, true),
        pipeId
      });

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useLeadListIds(pipeId: number, searchParams: SearchParamsCrm) {
  return useQuery<{ totalBalance: number; leadIds: number[] }>(
    [queryKeys.CRM_LEAD_LIST_IDS, pipeId, JSON.stringify(searchParams)],
    async () => {
      const res = await $api.post(endPoints.LEAD_TABLE_FILTERS_LEAD_IDS, {
        ...getCrmBody(searchParams, true),
        pipeId
      });

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: 60000,
      onError: baseErrorHandler,
      retry: false
    }
  );
}

export function useCrmLeadBy(ids: number[]) {
  return useQuery<OneLid[]>(
    [queryKeys.SEARCH_LEAD_EXTRA, ids],
    async () => {
      const res = await $api.get(`${endPoints.LEAD_BY}?${queryString.stringify({ leadIds: ids })}`);

      return res.data.data.sort((a: any, b: any) => ids.indexOf(a.id) - ids.indexOf(b.id));
    },
    {
      enabled: !!(ids.length > 0),
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetCrmCurrentUserRole() {
  return useQuery<CrmUserRoleModel>(
    [queryKeys.CRM_CURRENT_USER_ROLE],
    async () => {
      const res = await $api.get(endPoints.CRM_CURRENT_USER_ROLE);

      return res?.data?.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetPipeStatus(id?: number | string) {
  const initial: { statuses: PipeStatuses[] } = {
    statuses: []
  };

  const { data = initial, ...arg } = useQuery<{ statuses: PipeStatuses[] }>(
    [queryKeys.PIPE_STATUS_WITH_PIPE, id],
    async () => {
      const { data } = await $api.get(`/v1/status/all/${id}`);

      return { statuses: data?.data };
    },
    { enabled: !!id }
  );

  return { ...data, ...arg };
}
