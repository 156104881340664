/* eslint-disable max-len */
import React from 'react';
import i18n from '@service/I18nService';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import { useTranslation } from 'react-i18next';

import { FlatMore } from '../../../../../utils/models/FlatMore';
import { InformationForPdf } from '../../../../../utils/models/InformationForPdf';

import styles from './profile.module.scss';

interface IProps {
  flatData: InformationForPdf | undefined;
  item: FlatMore;
}

const Center: React.FC<IProps> = ({ flatData, item }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.centerSide}>
      <div className={styles.centerSide_first}>
        <div>
          <p>{t`home_id.Bino`}</p>
          <h3>{item?.flat?.building?.name}</h3>
        </div>
        <div>
          <p>{t`home_id.Podyezd`}</p>
          <h3>{item?.flat?.entranceName || item?.flat?.entrance}</h3>
        </div>
        <div>
          <p>{t`home.Qavat`}</p>
          <h3>{item?.flat?.floor}</h3>
        </div>
        <div>
          <p>{t(`home_id.Xonadon`)}</p>
          <h3>№{item?.flat?.number}</h3>
        </div>
        <div>
          <p>{t`home_id.Holati`}</p>
          <h3>{item?.repaired ? t`home.Tamirli` : t`home.Tamirsiz`}</h3>
        </div>
        {item?.flat?.fields?.map(
          item =>
            item?.showPDF && (
              // eslint-disable-next-line react/jsx-key
              <div>
                <p>{item?.name}</p>
                <h3>{item?.value}</h3>
              </div>
            )
        )}
      </div>
      <div className={styles.centerSide_second}>
        <p>{t`home_id.Bino_manzili`}:</p>
        <h3>
          {flatData?.houseDTO?.city?.[i18n.language as keyof BaseLanguageEntity]}, {flatData?.houseDTO?.district?.[i18n.language as keyof BaseLanguageEntity]}, {flatData?.houseDTO?.address}
        </h3>
      </div>
    </div >
  );
};

export default Center;
