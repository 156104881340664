import React, { useEffect, useState } from 'react';
import PlusIcon from '@icons/PlusIcon';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetCash } from '../../../queries/queries';
import { Cash, SystemTypeCash } from '../../../utils/models/Cash';

import LastPayme from './LastPayme';
import LastUzcard from './LastUzcard';
import Payme from './Payme';
import Uzcard from './Uzcard';

import styles from '../paymentSettings.module.scss';

const newObj: Cash | undefined = {
  cashId: '',
  cashKey: '',
  buildingIds: [],
  systemType: SystemTypeCash.PAYME,
  type: 'new'
};
const newObj1: Cash | undefined = {
  cashId: '',
  cashKey: '',
  buildingIds: [],
  systemType: SystemTypeCash.MY_UZCARD,
  type: 'new'
};

const AutomaticPayment = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useGetCash();

  const [paymeVal, setPaymeVal] = useState<Cash[] | undefined>([newObj]);
  const [uzcardVal, setUzcardVal] = useState<Cash[] | undefined>([newObj1]);

  useEffect(() => {
    const newData = data?.filter(item => item?.systemType === SystemTypeCash.PAYME);
    const newData1 = data?.filter(item => item?.systemType === SystemTypeCash.MY_UZCARD);

    setUzcardVal(newData1);
    setPaymeVal(newData);
  }, [data]);

  const handleAddPaymeCard = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !paymeVal?.includes(newObj) && paymeVal?.length !== 0 && setPaymeVal(prev => [...prev!, newObj]);
  };
  const handleAddMyUzcard = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !uzcardVal?.includes(newObj1) && uzcardVal?.length !== 0 && setUzcardVal(prev => [...prev!, newObj1]);
  };

  // findCash
  // eslint-disable-next-line consistent-return
  const findCash = (type: SystemTypeCash) => {
    if (data) {
      return data.find(item => item.systemType === type);
    }
  };

  return (
    <Spin spinning={isLoading} className={styles.spinner}>
      <div style={{ width: '100%' }}>
        <div className={styles.addNew}>
          {paymeVal?.length! >= 1 ? (
            paymeVal?.map((item, index) => <Payme key={index} data={item} index={index} setPaymeVal={setPaymeVal} />)
          ) : (
            <LastPayme data={findCash(SystemTypeCash.PAYME)} />
          )}
          <div className={styles.contAdd}>
            <div className="min-w-[292px]" />
            <div
              className={styles.add_button}
              onClick={() => {
                handleAddPaymeCard();
              }}
              style={{ width: '100%' }}
            >
              <PlusIcon />
              <span>{t('crm.Yangi_qoshish')}</span>
            </div>
          </div>
        </div>

        <div className={styles.addNew}>
          {uzcardVal?.length! >= 1 ? (
            uzcardVal?.map((item, index) => <Uzcard key={index} data={item} index={index} setUzcardVal={setUzcardVal} />)
          ) : (
            <LastUzcard data={findCash(SystemTypeCash.MY_UZCARD)} />
          )}
          <div className={styles.contAdd}>
            <div className="min-w-[292px]" />
            <div className={styles.add_button} onClick={handleAddMyUzcard} style={{ width: '100%' }}>
              <PlusIcon />
              <span>{t('crm.Yangi_qoshish')}</span>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AutomaticPayment;
