/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-alert */
import ReloadIconNotifs from '@icons/ReloadIconNotifs';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { cx, getLocalStorage } from '@utils/index';
import { Button, Modal, Switch, Tooltip } from 'global';
import { useTranslation } from 'react-i18next';

import { usePostPushNotificationConfig } from '../../service/mutation';
import { useGetPushNotificationConfigAll } from '../../service/queries';
import { caseTypes } from '../../utils/helpers/caseTypes';
import { timeOptions } from '../../utils/helpers/timeOptions';
import { PushNotificationType } from '../../utils/model/PushNotificationType';

import TaskAfterTime from './TaskAfterTime';
import TaskBeforeTime from './TaskBeforeTime';
import TaskDuringTheDayTIme from './TaskDuringTheDayTIme';

interface IProps {
  visible: boolean;
  handleCloseModal: () => void;
}

const TasKHeaderNotificationsModal: React.FC<IProps> = ({ visible, handleCloseModal }) => {
  const { t } = useTranslation();

  const firebaseToken = getLocalStorage('FIREBASE_TOKEN');

  const { data: dataPushNotificationsConfigAll } = useGetPushNotificationConfigAll(visible);
  const postPushNotificationConfig = usePostPushNotificationConfig();

  const dataConverted = dataPushNotificationsConfigAll?.parts?.reduce<Record<keyof typeof caseTypes, PushNotificationType>>(
    (acc, part) => {
      if (part?.type) {
        const timeCustome = part?.timeIntervals?.filter(item => !timeOptions?.includes(item))[0];

        // @ts-expect-error
        acc[part?.type as keyof typeof acc] = { ...part, timeCustome };
      }

      return acc;
    },
    {} as Record<keyof typeof caseTypes, PushNotificationType>
  );

  const handleSendInSiteStatus = (value: boolean) => {
    // @ts-expect-error
    postPushNotificationConfig.mutate({
      ...dataPushNotificationsConfigAll,
      firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
      inSiteStatus: value,
    });
  }

  const handleSendStatus = (value: boolean) => {
    // @ts-expect-error
    postPushNotificationConfig.mutate({
      firebaseToken: getLocalStorage('FIREBASE_TOKEN'),
      ...dataPushNotificationsConfigAll,
      status: value,
    });
  };


  return (
    <>
      <Modal
        title={
          <div className='flex items-center justify-between m-0'>
            <div className='flex items-center gap-5 m-0'>
              <h3 className='m-0'>{t('home.Bildirishnoma_sozlamasi')}</h3>
              <div className='mr-[80px] flex items-center gap-2'>
                <span>
                  <Switch
                    checked={dataPushNotificationsConfigAll?.status}
                    onChange={(e) => {
                      handleSendStatus(e);
                    }}
                  />
                </span>
              </div>
            </div>

            <div className='mr-[40px] mb-0 mt-0 cursor-pointer'>
              <Tooltip title={t('home.Token_yangilash_tugmasi')} overlayInnerStyle={{ borderRadius: 10 }}>
                <Button
                  className='flex items-center justify-center gap-3 rounded-lg'
                  onClick={() => {
                    $api.post(`v1/push-notification/config/clean?firebaseToken=${localStorage.getItem('firebaseToken')}`)
                      .then(response => {
                        if (response.data.message) {
                          showMessage(response.data.message, response.data.accept);
                        }
                      })
                  }}>
                  {t('profile.Yangilash')}
                  <ReloadIconNotifs />
                </Button>
              </Tooltip>
            </div>

          </div >
        }

        visible={visible}
        footer={false}
        onCancel={handleCloseModal}
        className="!w-[1120px]"
      >
        <div className={cx(`flex flex-col gap-[24px] ${dataPushNotificationsConfigAll?.status ? '' : 'pointer-events-none opacity-55'}`)}>
          <div className="flex items-center gap-[30px]">
            <Tooltip title={t('home.Eng_kopi_3_kun_otgan_topshiriqlar')} overlayInnerStyle={{ borderRadius: 10 }}>
              <h3 className="m-0 w-[220px] text-[15px]">{t('home.Topshiriq_otib_ketsa')}</h3>
            </Tooltip>
            {timeOptions.map(time => (
              <TaskAfterTime
                key={time}
                time={time}
                times={dataConverted?.AFTER?.timeIntervals}
                id={dataConverted?.AFTER?.id}
              />
            ))}
            <TaskAfterTime created time={dataConverted?.AFTER?.timeCustome} times={dataConverted?.AFTER?.timeIntervals} id={dataConverted?.AFTER?.id} />
          </div>

          <div className="flex items-center gap-[30px]">
            <h3 className="m-0 w-[220px] text-[15px]">{t('home.Topshiriq_boshlashdan')}</h3>
            {timeOptions.map(time => (
              <TaskBeforeTime
                key={time}
                time={time}
                times={dataConverted?.BEFORE?.timeIntervals}
                id={dataConverted?.BEFORE?.id}
              />
            ))}
            <TaskBeforeTime created time={dataConverted?.BEFORE?.timeCustome} times={dataConverted?.BEFORE?.timeIntervals} id={dataConverted?.BEFORE?.id} />
          </div>

          <div className="flex items-center gap-[30px]">
            <h3 className="m-0 w-[220px] text-[15px]">{t('home.Kun_davomidagi_topshiriqlar')}</h3>
            {dataConverted?.DURING_THE_DAY?.times?.map(item => (
              <TaskDuringTheDayTIme
                key={item}
                id={dataConverted?.DURING_THE_DAY?.id}
                time={item}
                times={dataConverted?.DURING_THE_DAY?.times}
              />
            ))}
            <TaskDuringTheDayTIme created times={dataConverted?.DURING_THE_DAY?.times} timesString={dataConverted?.DURING_THE_DAY?.times} />
          </div>

          <div className='mr-[80px] flex items-center gap-[30px]'>
            <h3 className='text-[15px] w-[220px]'>{t('home.Dastur_ichida_bildirishnomalarni_yoqish/ochirish')}</h3>
            <span className='mt-[-10px]'>
              <Switch
                checked={dataPushNotificationsConfigAll?.inSiteStatus}
                onChange={(e) => {
                  handleSendInSiteStatus(e);
                }}
              />
            </span>
          </div>
        </div>
      </Modal >
    </>
  );
};

export default TasKHeaderNotificationsModal;
