import { lazy } from 'react';

import { LocalStorage } from '../../service/LocalStorage';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { Permissions } from '../../utils/constants/permissions';
import { User } from '../../utils/models/User';

import { SettingsPaths } from './settingsPaths';
// import DiscountRouter from '@routing/discount/DiscountRouter';
// import SettingHome from '../../pages/settingHome/SettingHome'
// import SettingEmployee from '../../pages/settingEmployee/SettingEmployee'
// import MessageRouter from '../messageConfigRoutes/MessageRouter'
// import HouseConfig from '../../pages/houseConfig/HouseConfig'
// import BuilderSettings from '../../pages/builderSettings/BuilderSettings'
// import InterfaceConfigRouter from '../interfaceConfig/InterfaceConfigRouter'
// import CompanyConfigRouter from '../companyConfig/CompanyConfigRouter'
// import DiscountSetting from '../../pages/discountSetting/DiscountSetting'
// import Shaxmatka from '../../pages/shaxmatka/Shaxmatka'
// import ImportExportRouter from '../importExport/ImportExportRouter'
// import SettingBranch from '../../pages/settingBranch/SettingBranch'
// import PaymentSettingsRouter from '../paymentSettings/PaymentSettingsRouter'
// import IntegrationRouter from '../integration/IntegrationRouter'
// import Kpi from '../../pages/kpi/Kpi'
// import EventList from '../../pages/activityLogs/Index'
// import SecuritySettingsRouter from '../securitySettings/SecuritySettingsRouter'
// import CrmSettingsRouter from '@routing/crm-settings/CrmSettingsRouter'
// import ShaxmatkaV3 from '../../pages/shaxmatka-v3/ShaxmatkaV3'
// import ShaxmatkaV2 from '../../pages/shaxmatka_v2/ShaxmatkaV2'
const CrmSettingsRouter = lazy(() => import('../crm-settings/CrmSettingsRouter'));
const RobotoCallsRouter = lazy(() => import('../roboto-calls/RobotoCallsRouter'));

const SettingHome = lazy(() => import('../../pages/settingHome/SettingHome'));
const SettingEmployee = lazy(() => import('../../pages/settingEmployee/SettingEmployee'));
const MessageRouter = lazy(() => import('../messageConfigRoutes/MessageRouter'));
const HouseConfig = lazy(() => import('../../pages/houseConfig/HouseConfig'));
const BuilderSettings = lazy(() => import('../../pages/builderSettings/BuilderSettings'));
const InterfaceConfigRouter = lazy(() => import('../interfaceConfig/InterfaceConfigRouter'));
const CompanyConfigRouter = lazy(() => import('../companyConfig/CompanyConfigRouter'));
const DiscountRouter = lazy(() => import('../discount/DiscountRouter'));
// const Shaxmatka = lazy(() => import('../../pages/shaxmatka_v4/shaxmatka'));
const Shaxmatka = lazy(() => import('../../pages/shaxmatka/Shaxmatka'));
const ImportExportRouter = lazy(() => import('../importExport/ImportExportRouter'));
const SettingBranch = lazy(() => import('../../pages/settingBranch/SettingBranch'));
const PaymentSettingsRouter = lazy(() => import('../paymentSettings/PaymentSettingsRouter'));
const IntegrationRouter = lazy(() => import('../integration/IntegrationRouter'));
const Kpi = lazy(() => import('../../pages/kpi/Kpi'));
const EventList = lazy(() => import('../../pages/activityLogs/ActivityLogs'));
const SecuritySettingsRouter = lazy(() => import('../securitySettings/SecuritySettingsRouter'));

const user = LocalStorage.get<User>(USER_KEY);

const routes = [
  {
    path: SettingsPaths.INDEX,
    component: SettingHome,
    exact: true
  },
  {
    path: SettingsPaths.EMPLOYEE,
    component: SettingEmployee,
    exact: true,
    permission: Permissions.PERMISSION_SETTINGS_EMPLOYEE
  },
  {
    path: SettingsPaths.MESSAGE,
    component: MessageRouter,
    permission: Permissions.PERMISSION_SETTINGS_MESSAGE,
    exact: false
  },
  {
    path: SettingsPaths.HOUSE_CONFIG,
    component: HouseConfig,
    exact: true,
    permission: Permissions.PERMISSION_SETTINGS_HOUSE
  },
  {
    path: SettingsPaths.HOUSE_CONFIG + SettingsPaths.SHAXMATKA,
    component: Shaxmatka,
    exact: true,
    permission: Permissions.PERMISSION_SETTINGS_HOUSE
  },
  {
    path: SettingsPaths.BUILDER,
    component: BuilderSettings,
    exact: true,
    permission: Permissions.PERMISSION_SETTINGS_BUILDER
  },
  {
    path: SettingsPaths.INTERFACE,
    component: InterfaceConfigRouter,
    exact: false
  },
  {
    path: SettingsPaths.COMPANY_CONFIG,
    component: CompanyConfigRouter,
    exact: false,
    permission: Permissions.PERMISSION_SETTINGS_COMPANY
  },
  {
    path: SettingsPaths.DISCOUNT,
    component: DiscountRouter,
    exact: false,
    permission: Permissions.PERMISSION_SETTINGS_DISCOUNT
  },
  {
    path: SettingsPaths.BACKUP,
    component: ImportExportRouter,
    exact: false,
    permission: Permissions.PERMISSION_SETTINGS_TRANSFER_DATA
  },
  {
    path: SettingsPaths.BRANCH,
    component: SettingBranch,
    exact: true,
    permission: Permissions.PERMISSION_SETTINGS_BRANCH
  },
  {
    path: SettingsPaths.PAYMENT,
    component: PaymentSettingsRouter,
    exact: false,
    permission: Permissions.PERMISSION_SETTINGS_PAYMENT
  },
  {
    path: SettingsPaths.INTEGRATION,
    component: IntegrationRouter,
    exact: false,
    permission: Permissions.PERMISSION_INTEGRATION
  },
  {
    path: SettingsPaths.KPI,
    component: Kpi,
    exact: true,
    permission: Permissions.PERMISSION_SETTINGS_KPI
  },
  {
    path: SettingsPaths.EVET_LIST,
    component: EventList,
    exact: true,
    permission: Permissions.PERMISSION_ACTIVITY_LOGS
  },
  {
    path: SettingsPaths.SECURITY,
    component: SecuritySettingsRouter,
    exact: false,
    permission: Permissions.PERMISSION_COMPANY_ROLE
  },
  {
    path: SettingsPaths.CRM_SETTINGS,
    component: CrmSettingsRouter,
    exact: false,
    permission: Permissions.PERMISSION_SETTINGS_CRM
  },
  {
    path: SettingsPaths.CALLS,
    component: RobotoCallsRouter,
    exact: false,
    permission: Permissions.PERMISSION_SETTINGS_CRM
  }
];

const settingRoutes: typeof routes = [];

if (typeof user === 'object' && user) {
  routes.forEach(route => {
    if (route.permission) {
      if (user.role?.permissions?.includes(route.permission)) {
        settingRoutes.push(route);
      }
    } else {
      settingRoutes.push(route);
    }
  });
}

export default settingRoutes;
