import React, { useEffect, useState } from 'react';
import EditIcon from '../../../../../../assets/icons/EditIcon';
import PositionLeftIcon from '../../../../../../assets/icons/webforma/PositionLeftIcon';
import PositionCenterIcon from '../../../../../../assets/icons/webforma/PositionCenterIcon';
import PositionRightIcon from '../../../../../../assets/icons/webforma/PositionRightIcon';
import { Input, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import {
  changeFontWeight,
  changeFontStyle,
  changeTextDecoration,
  changeTextAlign,
  changeTextColor,
  changeTextContent,
  changeTextSize
} from '../../../../../../store/reducers/TextEditorSlice';

import styles from './textEditor.module.scss';

type Props = {
  children: any;
  type: string;
  style: any;
};

const { Option } = Select;

const TextEditor: React.FC<Props> = ({ children, type, style }) => {
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);
  const [mouseEvent, setMouseEvent] = useState(false);
  const sizes = ['13', '14', '15', '16', '17', '18', '20', '22', '24', '28', '30', '32', '34'];
  const { leftHeader, main } = useAppSelector(state => state.textEditorReducer);
  const { header, paragraph } = leftHeader;
  const textStyle = {
    header,
    paragraph
  };

  const handleFocus = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    const windowClick = () => {
      if (mouseEvent) {
        setVisible(false);
      }
    };
    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, [mouseEvent]);

  useEffect(() => {
    if (ref?.current) {
      ref.current.innerHTML = children;
    }
    // eslint-disable-next-line
  }, [ref, main]);

  return (
    <div
      className={styles.container}
      style={{ boxShadow: visible ? '0 4px 5px rgba(0, 0, 0, 0.4)' : '' }}
      onMouseEnter={() => setMouseEvent(false)}
      onMouseLeave={() => setMouseEvent(true)}
    >
      <div className={styles.textEditor} style={{ backgroundColor: visible ? '#fff' : '' }}>
        <div
          ref={ref}
          className={styles.textEditor_mainInput}
          style={{
            ...style,
            pointerEvents: visible ? 'auto' : 'none',
            fontFamily: main.fontFamily,
            justifyContent: style?.textAlign
          }}
          onKeyUp={e => {
            dispatch(
              changeTextContent({
                content: e.currentTarget?.innerHTML,
                type: type
              })
            );
          }}
          contentEditable
        ></div>
        <span>
          {visible ? (
            <span style={{ cursor: 'pointer' }} onClick={() => setVisible(false)}>
              Done
            </span>
          ) : (
            <EditIcon
              onClick={() => {
                setVisible(true);
                handleFocus();
              }}
            />
          )}
        </span>
      </div>
      <div className={styles.textElements} style={{ display: visible ? 'flex' : 'none' }}>
        <div className={styles.textElements_child}>
          <div
            className={styles.textElements_child_mask}
            style={{
              backgroundColor: textStyle[type as keyof typeof textStyle].color
            }}
          ></div>
          <Input
            type="color"
            value={textStyle[type as keyof typeof textStyle].color}
            onChange={e => {
              dispatch(changeTextColor({ color: e.target.value, type: type }));
            }}
          />
        </div>
        <div className={styles.textElements_child}>
          <div
            className={styles.textElements_child_quils}
            onClick={() =>
              dispatch(
                changeFontWeight({
                  fontWeight: 'bold',
                  type: type
                })
              )
            }
            style={{
              background: textStyle[type as keyof typeof textStyle].fontWeight ? '#bae7ff' : ''
            }}
          >
            B
          </div>
          <div
            className={styles.textElements_child_quils}
            onClick={() =>
              dispatch(
                changeFontStyle({
                  fontStyle: 'italic',
                  type: type
                })
              )
            }
            style={{
              background: textStyle[type as keyof typeof textStyle].fontStyle ? '#bae7ff' : ''
            }}
          >
            I
          </div>
          <div
            className={styles.textElements_child_quils}
            onClick={() =>
              dispatch(
                changeTextDecoration({
                  textDecoration: 'underline',
                  type: type
                })
              )
            }
            style={{
              background: textStyle[type as keyof typeof textStyle].textDecoration ? '#bae7ff' : ''
            }}
          >
            U
          </div>
        </div>
        <div className={styles.textElements_child}>
          <span
            style={{
              backgroundColor: textStyle[type as keyof typeof textStyle].textAlign === 'left' ? '#bae7ff' : ''
            }}
          >
            <PositionLeftIcon onClick={() => dispatch(changeTextAlign({ textAlign: 'left', type: type }))} />
          </span>
          <span
            style={{
              backgroundColor: textStyle[type as keyof typeof textStyle].textAlign === 'center' ? '#bae7ff' : ''
            }}
          >
            <PositionCenterIcon onClick={() => dispatch(changeTextAlign({ textAlign: 'center', type: type }))} />
          </span>
          <span
            style={{
              backgroundColor: textStyle[type as keyof typeof textStyle].textAlign === 'right' ? '#bae7ff' : ''
            }}
          >
            <PositionRightIcon onClick={() => dispatch(changeTextAlign({ textAlign: 'right', type: type }))} />
          </span>
        </div>
        <div className={styles.textElements_child}>
          <Select defaultValue={14} style={{ width: 65 }} onChange={e => dispatch(changeTextSize({ type: type, fontSize: +e }))}>
            {sizes?.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
