import React, { useEffect } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { useShareDashboard } from '@pages/dashboard/services/mutations';
import { useGetSharedUsers } from '@pages/dashboard/services/queries';
import { queryKeys } from '@pages/dashboard/utils/constants/queryKeys';
import { useCrmEmployee } from '@queries/queries';
import { Button, Empty, Form, Modal, Select } from 'antd';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';

import styles from './shareDashboard.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Option } = Select;

const ShareDashboard: React.FC<Props> = ({ setVisible, visible }) => {
  const qc = useQueryClient();
  const { searchParams } = useQueryParam<any, any>();
  const { data } = useCrmEmployee();
  const { data: sharedUsers } = useGetSharedUsers(searchParams?.dashboardId);
  const [form] = Form.useForm();

  const shareDashboard = useShareDashboard();

  const userId = useAppSelector(state => state.userReducer.user)?.id;

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  function transformArray(arr: any) {
    const result: any = [];

    arr.forEach((item: any) => {
      result[item.employeeId] = item.permission;
    });

    return result;
  }

  useEffect(() => {
    if (visible) {
      qc.fetchQuery([queryKeys.SHARED_USERS, searchParams?.dashboardId]);
    }
  }, [visible]);

  useEffect(() => {
    if (sharedUsers) {
      form.setFieldsValue({ employee: transformArray(sharedUsers) });
    }
  }, [sharedUsers, visible]);

  function removeDuplicates(array: any) {
    const uniqueItems = new Map();

    array.forEach((item: any) => {
      if (!uniqueItems.has(item.employeeId)) {
        uniqueItems.set(item.employeeId, item);
      }
    });

    return Array.from(uniqueItems.values());
  }

  const onFinish = (val: any) => {
    const items = form.getFieldsValue()?.employee;

    const newData = items?.map((elem: any, index: number) => ({ employeeId: index, permission: elem }))?.filter((prev: any) => prev?.permission);

    const sendData = {
      id: searchParams?.dashboardId,
      employees: removeDuplicates(newData)
    };

    shareDashboard.mutateAsync(sendData).then(handleClose);
  };

  return (
    <Modal title={t('emp_dashboard.Ulashish')} visible={visible} onCancel={handleClose} footer={false} className={styles.shareDashboard}>
      <span className={styles.title}>{t('online_pbx.Foydalanuvchilar')}</span>
      {data?.length! > 0 ? (
        <Form onFinish={onFinish} form={form}>
          <div className={styles.wrapper}>
            {data
              ?.filter(prev => prev?.id !== userId)
              ?.map(item => (
                <div className={styles.container} key={item?.id}>
                  <div className={styles.container_left}>
                    <span className={styles.container_left_title}>{item?.fullName}</span>
                    <Form.Item name={['employee', item?.id]}>
                      <Select onClick={e => e.stopPropagation()} placeholder={t('home.Tanlang')} allowClear>
                        <Option value="EDIT">{t('client.Tahrirlash')}</Option>
                        <Option value="READ">{t('client.Korish')}</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.footer}>
            <Button type="primary" htmlType="submit" loading={shareDashboard.isLoading}>
              {t('online_pbx.Saqlash')}
            </Button>
          </div>
        </Form>
      ) : (
        <Empty description={t('home.Malumotlar_mavjud_emas')} />
      )}
    </Modal>
  );
};

export default ShareDashboard;
