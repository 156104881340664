import React, { FC } from 'react';

interface IProps {
  color?: string;
}

const LineBarIcon: FC<IProps> = ({ color = '#F7BA1E' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="37" viewBox="0 0 32 37" fill="none">
      <g filter="url(#filter0_d_3559_9124)">
        <rect x="8" y="14" width="4" height="16" rx="2" fill={color} />
      </g>
      <g filter="url(#filter1_d_3559_9124)">
        <rect x="14" y="2" width="4" height="18" rx="2" fill={color} />
      </g>
      <g filter="url(#filter2_d_3559_9124)">
        <rect x="20" y="12" width="4" height="14" rx="2" fill={color} />
      </g>
      <defs>
        <filter id="filter0_d_3559_9124" x="5" y="14" width="10" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.968627 0 0 0 0 0.729412 0 0 0 0 0.117647 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3559_9124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3559_9124" result="shape" />
        </filter>
        <filter id="filter1_d_3559_9124" x="11" y="2" width="10" height="25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.968627 0 0 0 0 0.729412 0 0 0 0 0.117647 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3559_9124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3559_9124" result="shape" />
        </filter>
        <filter id="filter2_d_3559_9124" x="17" y="12" width="10" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.968627 0 0 0 0 0.729412 0 0 0 0 0.117647 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3559_9124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3559_9124" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default LineBarIcon;
