import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessDenied from '../../components/accessDenied/AccessDenied';
import { profileRoutes } from './profileRoutes';

const ProfileRouter = () => {
  return (
    <>
      {' '}
      <Routes>
        {profileRoutes.map((route, index) => (
          <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
        ))}
        <Route path={'*'} element={<AccessDenied />} />
      </Routes>
    </>
  );
};

export default ProfileRouter;
