export const EventElements = {
  CREATE_LED: 'one_lid.lid_yaratildi',
  CREATE_CONTACT: 'one_lid.Yangi_kontakt_yaratildi',
  CREATE_TAG: 'one_lid.Teg_qoshildi',
  CREATE_CONTACT_PHONE: 'one_lid.kontakt_telefon_raqami_yaratildi',
  CREATE_REASONS_FOR_REFUSAL: 'one_lid.lead_yopilish',
  DELETE_VALUE: 'one_lid.filed_qiymati_ochirildi',
  SET_VALUE: 'one_lid.qiymatini_ozgartirildi',
  REMOVE_TAG: 'one_lid.Teg_olib_tashlandi',
  CHANGE_STATUS: 'one_lid.Yangi_bosqich',
  CHANGE_LEAD_EMPLOYEE: 'one_lid.lid_masul_shaxsi_ozgartirildi',
  RECOVERY_LEAD: 'one_lid.recovery_joylashtiriladi',
  DELETE_LEAD: 'one_lid.lid_ochirildi',
  CHANGE_CONTACT_NAME: 'one_lid.kontakt_nomi_ozgartirildi',
  SET_LEAD_EMPLOYEE: 'one_lid.lid_uchun_masul_biriktirildi',
  CHANGE_LEAD_BALANCE: 'one_lid.lid_balansi_ozgartirildi',
  REMOVE_CONTACT_PHONE: 'one_lid.kontakt_telefon_raqami_ochirildi',
  CHANGE_LEAD_NAME: 'one_lid.lid_nomi_ozgartirildi',
  REMOVE_CONTACT: 'one_lid.kontakt_ochirildi',
  CHANGE_REASONS_FOR_REFUSAL: 'one_lid.lead_yopilishi_qaytarilishi',
  CREATE_STATUS: 'one_lid.Bosqich_yaratildi'
};
