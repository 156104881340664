import React from 'react';

const MessageSquareIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6.5C3 5.09987 3 4.3998 3.27248 3.86502C3.51217 3.39462 3.89462 3.01217 4.36502 2.77248C4.8998 2.5 5.59987 2.5 7 2.5H14C15.4001 2.5 16.1002 2.5 16.635 2.77248C17.1054 3.01217 17.4878 3.39462 17.7275 3.86502C18 4.3998 18 5.09987 18 6.5V11C18 12.4001 18 13.1002 17.7275 13.635C17.4878 14.1054 17.1054 14.4878 16.635 14.7275C16.1002 15 15.4001 15 14 15H11.9031C11.3831 15 11.1231 15 10.8743 15.051C10.6537 15.0963 10.4402 15.1712 10.2396 15.2737C10.0135 15.3892 9.81043 15.5517 9.40434 15.8765L7.41646 17.4668C7.06973 17.7442 6.89636 17.8829 6.75045 17.8831C6.62356 17.8832 6.50352 17.8255 6.42436 17.7263C6.33333 17.6123 6.33333 17.3903 6.33333 16.9463V15C5.55836 15 5.17087 15 4.85295 14.9148C3.99022 14.6836 3.31635 14.0098 3.08519 13.147C3 12.8291 3 12.4416 3 11.6667V6.5Z"
        stroke="#464255"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageSquareIcon;
