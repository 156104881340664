import React from 'react';
const CheckReadMSGIcon = () => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6693 1L5.5026 10.1667L1.33594 6" stroke="#00A389" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default CheckReadMSGIcon;
