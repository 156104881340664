import React from 'react';

const SaveBlueIcon = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H12V16L6 12L0 16V0Z" fill="#3772FF" />
    </svg>
  );
};

export default SaveBlueIcon;
