/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useGetPaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/service/queries';
import { PaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/utils/models/PaymentCustomType';
import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Tooltip } from 'global';
import { useTranslation } from 'react-i18next';

import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import { useQueryParam } from '../../hooks/useQueryParams';
import { BossPaymentsSearchParams } from '../../pages/bossPayments/BossPayments';
import { useBasicCurrency, useMobilePaymentsType } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { BossHomePayments } from '../../utils/models/BossHome';
import { BossPayments, BossPaymentsType } from '../../utils/models/BossPayments';
import { PaymentType } from '../../utils/models/PaymentType';

import styles from './tablePayments.module.scss';

interface Props {
  data: BossPayments[] | undefined;
}

const TablePayments: React.FC<Props> = ({ data }) => {
  const { data: paymentsType } = useMobilePaymentsType();
  const { data: currency } = useBasicCurrency();
  const { data: dataPaymentCustomType } = useGetPaymentCustomType();

  const { t } = useTranslation();
  const { searchParams } = useQueryParam<BossPaymentsSearchParams, any>();

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams.type]);


  const dataConverted = data?.map(item => ({
    ...item,
    ...item?.payment?.custom?.reduce<Record<string, PaymentCustomType>>((p, c) => {
      p[`custom_${c?.id}`] = c;
      return p;
    }, {})
  }));

  const columns: ColumnsType<BossPayments> | undefined = [
    {
      title: (
        <p className='w-[50px]'>{t('boss_payments.Sana')}</p>
      ),
      dataIndex: 'date',
      width: '90%',
      fixed: 'left',
      render: (date: string) => {
        switch (searchParams.type) {
          case BossPaymentsType.MONTH:
            return `${t(`boss_payments.${dayjs(date, DATE_FORMAT).format('MMM')}`)} ${dayjs(date, DATE_FORMAT).format('YYYY')}`;
          case BossPaymentsType.YEAR:
            return dayjs(date, DATE_FORMAT).format('YYYY');
          default:
            return `${dayjs(date, DATE_FORMAT).format('DD')} ${t(`boss_payments.${dayjs(date, DATE_FORMAT).format('MMM')}`)}`;
        }
      }
    },
    {
      title: (
        <p className='w-[150px]'>{t('boss_payments.Naqd')}</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.cash.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.CASH
    },
    {
      title: (
        <p className='w-[150px]'>{t('boss_payments.Karta')}</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      width: '50px',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.card.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.CARD
    },
    {
      title: (
        <p className='w-[150px]'>{t('boss_payments.Bank')}</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.bank.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.BANK
    },
    {
      title: (
        <p className='w-[150px]'>{t('boss_payments.Pul_otkazmasi')}</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.transfer.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.TRANSFER
    },
    {
      title: (
        <p className='w-[150px]'>{t('boss_payments.Balansdan')}</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.balance.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.BALANCE
    },
    {
      title: (
        <p className='w-[150px]'>My Uzcard</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.myUzCard.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.MY_UZCARD
    },
    {
      title: (
        <p className='w-[150px]'>Payme</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.payme.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.PAYME
    },
    {
      title: (
        <p className='w-[150px]'>Paylov</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.paylov.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.PAYLOV
    },
    {
      title: (
        <p className='w-[150px]'>Uzum</p>
      ),
      dataIndex: 'payment',
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.apelsin?.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.UZUM
    },
    {
      title: (
        <p className='w-[150px]'>{t("home.OTHERS")}</p>
      ),
      dataIndex: 'payment',
      width: 20,
      align: 'left',
      render: (payment: BossHomePayments) => (
        <span>
          {payment?.others?.toLocaleString('ru')} {currency?.ccy}
        </span>
      ),
      key: PaymentType.OTHERS
    },
    ...(dataPaymentCustomType || [])?.map((item) => ({
      title: <Tooltip
        title={item?.name}
        overlayInnerStyle={{ width: '100%' }}
      >
        <p className='w-[150px] overflow-hidden text-ellipsis whitespace-nowrap pr-5'>{item?.name}</p>
      </Tooltip>,
      dataIndex: `custom_${item?.id}`,
      render: (item: { amount?: number }) => (
        <span>
          {item ? item?.amount?.toLocaleString('ru') : '0'} {currency?.ccy}
        </span>
      ),
    }))
  ];


  // handle pagination
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // get table data
  const getTableData = () => {
    if (dataConverted) {
      const tableData = [...dataConverted];

      return tableData.splice((currentPage - 1) * 10, 10);
    }
    return [];
  };

  return (
    <>
      <div className={styles.table} style={{ overflowX: 'auto' }}>
        <Table
          columns={columns.filter(item => !item.key || paymentsType?.includes(item?.key as string))}
          dataSource={getTableData()}
          loading={false}
          pagination={false}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          onChange={handlePageChange}
          prevIcon={
            <div className={styles.pagination_next_prev_buttons}>
              <PaginationPrev />
            </div>
          }
          nextIcon={
            <div className={styles.pagination_next_prev_buttons}>
              <PaginationNext />
            </div>
          }
          pageSize={10}
          showSizeChanger={false}
          current={currentPage}
          total={data?.length}
        />
      </div>
    </>
  );
};

export default TablePayments;
