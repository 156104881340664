import { Permissions } from '@utils/constants/permissions';

import { useAppSelector } from '../../../../hooks/reduxHooks';
import FlatCard from '../flatCard/FlatCard';

import styles from './flats.module.scss';

const Flats = () => {
  const { flats, currency, variation } = useAppSelector(state => state.createContract);
  const { user } = useAppSelector(state => state.userReducer);

  const isMultiPayment = user?.role?.permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const changePricePercent = variation?.changePricePercent || 0;

  return (
    <div className={styles.container}>
      {flats.map((item, index) => {
        const { priceNotRepaired, priceRepaired, pricePerArea, pricePerAreaRepaired, terrace } = item.flat;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <FlatCard
            currency={currency}
            flatMore={
              isMultiPayment
                ? {
                    ...item,
                    totalAmount: item?.totalAmount + (item?.totalAmount / 100) * changePricePercent,
                    pricePerArea: item?.pricePerArea + (item?.pricePerArea / 100) * changePricePercent,
                    pricePerAreaTerrace: item?.pricePerAreaTerrace ? item?.pricePerAreaTerrace + (item?.pricePerAreaTerrace / 100) * changePricePercent : null,
                    flat: {
                      ...item.flat,
                      priceNotRepaired: priceNotRepaired + (priceNotRepaired / 100) * changePricePercent,
                      priceRepaired: priceRepaired + (priceRepaired / 100) * changePricePercent,
                      pricePerArea: pricePerArea + (pricePerArea / 100) * changePricePercent,
                      pricePerAreaRepaired: pricePerAreaRepaired + (pricePerAreaRepaired / 100) * changePricePercent,
                      ...(terrace && {
                        terrace: {
                          ...terrace,
                          pricePerArea: (terrace?.pricePerArea || 0) + ((terrace?.pricePerArea || 0) / 100) * changePricePercent,
                          pricePerAreaRepaired: (terrace?.pricePerAreaRepaired || 0) + ((terrace?.pricePerAreaRepaired || 0) / 100) * changePricePercent
                        }
                      })
                    }
                  }
                : item
            }
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        );
      })}
    </div>
  );
};

export default Flats;
