import { useQuery } from 'react-query';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { setFlats } from '../../../store/reducers/CreateContractSlice';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { FlatMore } from '../../../utils/models/FlatMore';
import { $api } from '../../../service/RequestService';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';

export function useFlatMore(idsList: string[]) {
  const dispatch = useAppDispatch();
  let ids = `?flats=${idsList[0]}`;
  if (idsList.length > 1) {
    for (let i = 1; i < idsList.length; i++) {
      ids += `&flats=${idsList[i]}`;
    }
  }
  return useQuery<FlatMore[]>(
    [queryKeys.FLAT_MORE_LIST, ids],
    async () => {
      const res = await $api.get(endPoints.FLAT_MORE + ids);
      return res.data.data;
    },
    {
      onSuccess: val => {
        dispatch(setFlats(val));
      },
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}
