import { widgetIcons } from '@components/widgets/Widgets';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setWidget } from '@reducers/UserSlice';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useWidgetSend } from '../../queries/mutation';
import { useWidget } from '../../queries/queries';
import { widgetFIleURL } from '../../utils/constants/widgetFileURL';
import { Widget } from '../../utils/models/Widget';

import styles from './widgetConfig.module.scss';

const WidgetConfig = () => {
  const { widgets } = useAppSelector(state => state.userReducer);
  const { t } = useTranslation();
  const { data } = useWidget();
  const dispatch = useAppDispatch();

  // send widget
  const sendWidget = useWidgetSend();

  // handleActivation
  const handleActivation = (item: Widget, status: boolean) => {
    sendWidget.mutateAsync({
      ...item,
      status
    });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.container_title}>{t('widgets.Widgetlar')}</h1>
      <div className={styles.container_body}>
        {data?.map(item => (
          <div key={item?.id} className={styles.container_body_item}>
            <img src={widgetFIleURL + item?.icon} alt="" />
            <h3>{t('widgets.Kredit_kalkulyatori')}</h3>
            <p>{t('widgets.Kalkulyator')}</p>
            {!item?.status ? (
              <Button className={styles.container_body_item_active} onClick={() => handleActivation(item, true)} loading={sendWidget.isLoading}>
                {t('widgets.Aktivlashtirish')}
              </Button>
            ) : (
              <Button className={styles.container_body_item_delete} onClick={() => handleActivation(item, false)} loading={sendWidget.isLoading}>
                {t('one_lid.Ochirish')}
              </Button>
            )}
          </div>
        ))}

        {widgets?.map(item => (
          <div className={styles.container_body_item} key={item?.id}>
            {widgetIcons[item?.type]({ className: 'mb-[54px] mt-[20px] w-[92px] h-[92px] text-[42px]' })}
            <h3>{t('file_management.Files')}</h3>
            <p>{t('file_management.Files')}</p>
            {!item?.status ? (
              <Button
                className={styles.container_body_item_active}
                onClick={() => {
                  dispatch(setWidget({ id: item?.id, status: true }));
                }}
              >
                {t('widgets.Aktivlashtirish')}
              </Button>
            ) : (
              <Button
                className={styles.container_body_item_delete}
                onClick={() => {
                  dispatch(setWidget({ id: item?.id, status: false }));
                }}
              >
                {t('one_lid.Ochirish')}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetConfig;
