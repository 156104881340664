import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const CloseCircle: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg onClick={onClick} className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#FF3F40"
      />
      <path
        d="M9.16986 15.5794C8.97986 15.5794 8.78986 15.5094 8.63986 15.3594C8.34986 15.0694 8.34986 14.5894 8.63986 14.2994L14.2999 8.63938C14.5899 8.34938 15.0699 8.34938 15.3599 8.63938C15.6499 8.92937 15.6499 9.40937 15.3599 9.69937L9.69986 15.3594C9.55986 15.5094 9.35986 15.5794 9.16986 15.5794Z"
        fill="#FF3F40"
      />
      <path
        d="M14.8299 15.5794C14.6399 15.5794 14.4499 15.5094 14.2999 15.3594L8.63986 9.69937C8.34986 9.40937 8.34986 8.92937 8.63986 8.63938C8.92986 8.34938 9.40986 8.34938 9.69986 8.63938L15.3599 14.2994C15.6499 14.5894 15.6499 15.0694 15.3599 15.3594C15.2099 15.5094 15.0199 15.5794 14.8299 15.5794Z"
        fill="#FF3F40"
      />
    </svg>
  );
};

export default CloseCircle;
