import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { Verto } from '../../utils/helper/verto';

interface Phones {
  [key: string]: {
    callerName: string;
    callerNumber: string;
    answerCalling: boolean;
    calling: boolean;
    hold: boolean;
    call: any;
    employeeName?: string;
    employeePhoneNumber?: string;
  };
}

interface WebRTCState {
  webrtc: {
    host: string;
    password: string;
    user: string;
    domain: string;
  };
  vertoRef: Verto | null;
  video: any | null;
  answerCalling: boolean;
  calling: boolean;
  running_call: any | null;
  startTimer: boolean;
  resedTimer: boolean;
  callerName: string;
  callerNumber: string;
  disable?: boolean | null;
  hold: boolean;
  phones: Phones;
}

const initialState: WebRTCState = {
  webrtc: {
    host: '',
    password: '',
    user: '',
    domain: ''
  },
  vertoRef: null,
  video: null,
  answerCalling: false,
  calling: false,
  running_call: null,
  startTimer: false,
  resedTimer: false,
  callerName: '',
  callerNumber: '',
  disable: false,
  hold: false,
  phones: {}
};

export const webRTCSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWebRTC: (
      state,
      action: PayloadAction<{
        host?: string;
        password?: string;
        user?: string;
        domain?: string;
      }>
    ) => {
      const { webrtc } = current(state);
      if (!webrtc?.password) {
        state.webrtc = { ...webrtc, ...action.payload };
        state.vertoRef = new Verto({
          transportConfig: {
            passwd: action.payload?.password!,
            socketUrl: 'wss://' + action.payload?.host,
            login: action.payload?.user + '@' + webrtc?.domain
          },
          debug: true,
          ice_timeout: 5000
        });
      }
    },
    setVerto: (state, action: PayloadAction<Verto | null>) => {
      state.vertoRef = action?.payload;
    },
    setAnswerCalling: (state, action: PayloadAction<boolean>) => {
      state.answerCalling = action.payload;
    },
    setCalling: (state, action: PayloadAction<boolean>) => {
      state.calling = action.payload;
    },
    setRunningCall: (state, action: PayloadAction<any>) => {
      state.running_call = action.payload;
    },
    setStartTimer: (state, action: PayloadAction<boolean>) => {
      state.startTimer = action.payload;
    },
    setResedTimer: (state, action: PayloadAction<boolean>) => {
      state.resedTimer = action.payload;
    },
    setCallerName: (state, action: PayloadAction<{ callerName: string; callerNumber: string }>) => {
      state.callerName = action.payload?.callerName;
      state.callerNumber = action.payload?.callerNumber;
    },
    setWebRTCDisable: (state, action: PayloadAction<boolean | null | undefined>) => {
      state.disable = action.payload;
    },
    setWebRTCHold: (state, action: PayloadAction<boolean>) => {
      state.hold = action.payload;
    },
    setPhones: (state, action: PayloadAction<Phones>) => {
      state.phones = {
        ...state.phones,
        ...action.payload
      };
    },
    setPhoneEdit: (
      state,
      action: PayloadAction<{
        key: string;
        params: {
          callerName?: string;
          callerNumber?: string;
          answerCalling?: boolean;
          calling?: boolean;
          hold?: boolean;
        };
      }>
    ) => {
      state.phones[action.payload.key] = {
        ...state.phones[action.payload.key],
        ...action.payload.params
      };
    },
    setPhoneDelete: (state, action: PayloadAction<string>) => {
      delete state.phones[action.payload];
    }
  }
});

export const {
  setWebRTC,
  setVerto,
  setAnswerCalling,
  setCalling,
  setRunningCall,
  setStartTimer,
  setResedTimer,
  setCallerName,
  setWebRTCDisable,
  setWebRTCHold,
  setPhones,
  setPhoneDelete,
  setPhoneEdit
} = webRTCSlice.actions;
export default webRTCSlice.reducer;
