import React, { FC, useState } from 'react';
import styles from './rangeSlider.module.scss';
interface RangeSliderProps {
  defaultValue: number[];
  maxFloor: number;
  minFloor: number;
  onChangeSlider: (defaultValue: number[]) => void;
}

const RangeSlider: FC<RangeSliderProps> = ({ defaultValue, maxFloor, minFloor, onChangeSlider }) => {
  const [range, setRange] = useState(false);
  const arr = Array.from({ length: defaultValue[1] - defaultValue[0] + 1 }, (_, i) => i + (defaultValue[0] - 1));

  const rangeSliderHandle = (e: number) => {
    if (range) {
      if (defaultValue[1] < e) {
        onChangeSlider([defaultValue[0], e]);
        setRange(false);
      } else {
        onChangeSlider([e, defaultValue[1]]);
        setRange(false);
      }
    } else {
      onChangeSlider([e, e]);
      setRange(true);
    }
  };

  return (
    <div className={styles.rangeSlider_mj}>
      {Array.from(Array(maxFloor - minFloor + 1).keys()).map((i: number) => (
        <div
          key={i}
          onClick={() => rangeSliderHandle(minFloor + i)}
          className={
            arr.includes(minFloor + i - 1)
              ? defaultValue[0] === minFloor + i
                ? styles.activeSliderMjsOne
                : defaultValue[1] === minFloor + i
                  ? styles.activeSliderMjsTwo
                  : styles.activeSliderMjspagination
              : ''
          }
        >
          <span>{minFloor + i}</span>
        </div>
      ))}
    </div>
  );
};

export default RangeSlider;
