/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import TextEditable from '@components/text-editable/TextEditable';
import { cx } from '@utils/cx';
import { Button, Form, Modal, Select } from 'antd';
import { Input } from 'global';
import { t } from 'i18next';

import AutoSMSTriggerNotifications from './components/AutoSMSTriggerNotifications';
import { usePostSMSBot } from './service/mutation';
import { useGetOriginator, useGetSMSConfigAll } from './service/queries';
import { warningTime } from './utils/constants/warningTime';
import { Originator } from './utils/models/Originator';

import styles from '../../integration.module.scss';

type AutoSmsModalProps = {
  smsAutoTrigger: boolean;
  setAutoSmsTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  handleSettingsClick: () => void;
  handleNotificationsClick: () => void;
  contentMode: string;
  selectedId: number | null;
};

const buttonValues = [
  { label: t('integration.Uchrashuv'), key: '&date&' },
  { label: t('integration.Lid_nomi'), key: '&leadName&' },
  { label: t('integration.Contact_nomi'), key: '&contactName&' },
  { label: t('integration.Menejer_ismi'), key: '&responsible&' }
];

const AutoSMSTrigger: React.FC<AutoSmsModalProps> = ({ smsAutoTrigger, setAutoSmsTrigger, handleSettingsClick, handleNotificationsClick, contentMode, selectedId }) => {
  const { data: dataOriginator } = useGetOriginator();
  const { data: dataSMSBot, refetch: refetchSMSConfig } = useGetSMSConfigAll();
  const [form] = Form.useForm<any>();
  const postSMSBot = usePostSMSBot();
  const [_, setIsFormChanged] = useState(false);
  const [text, setText] = useState('');
  const leadTaskTypeId = Form.useWatch('leadTaskTypeId', form);
  const originator = Form.useWatch('originator', form);
  const times = Form.useWatch('times', form);

  const selectedData = dataSMSBot?.find((item: { leadTaskTypeId: number | null; }) => item?.leadTaskTypeId === selectedId);

  const onFinish = (values: { originator: string; times: string[]; text: string; active: boolean }) => {
    refetchSMSConfig();

    postSMSBot
      .mutateAsync({
        originator: values?.originator,
        leadTaskTypeId: selectedId,
        times: values?.times,
        text,
        active: values?.active,
      })
      .then(() => {
        form.resetFields();
        setAutoSmsTrigger(false);
        refetchSMSConfig();
      });
  };

  const isDisabled = () => {
    if (
      dataSMSBot?.leadTaskTypeId === leadTaskTypeId &&
      dataSMSBot?.text === text &&
      dataSMSBot?.originator === originator &&
      dataSMSBot?.times?.length === times?.length &&
      dataSMSBot?.times?.every((item: string) => times?.includes(item))
    ) {
      return true;
    }
    return false;
  };

  // console.log("Data💾", dataSMSBot)

  useEffect(() => {
    if (selectedData && smsAutoTrigger) {
      form.setFieldsValue(selectedData);
      setText(selectedData?.text);
    }
  }, [selectedData, form, smsAutoTrigger]);

  const onCancel = () => {
    handleSettingsClick();
    setAutoSmsTrigger(false);
    form.resetFields();
  };

  return (
    <Modal
      title={<h3 style={{ color: '#464255', fontSize: '20px', fontWeight: 700 }}>{t('integration.Avtomatik_sms_bildirishnomalar')}</h3>}
      className={styles.modal}
      footer={false}
      visible={smsAutoTrigger}
      onCancel={onCancel}
      maskClosable={false}
    >
      <div className={styles.btns_wrapper}>
        <Button onClick={handleSettingsClick} className={contentMode === 'settings' ? styles.active : ''}>
          {t('navbars.Sozlamalar')}
        </Button>
        <Button onClick={handleNotificationsClick} className={contentMode === 'notifications' ? styles.active : ''}>
          {t('notification.Bildirishnomalar')}
        </Button>
      </div>

      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFieldsChange={() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          text !== dataSMSBot?.text && setIsFormChanged(true);
        }}
      >
        {contentMode === 'settings' && (
          <>
            <Form.Item name="leadTaskTypeId" className='hidden'>
              <Input />
            </Form.Item>

            {dataOriginator && dataOriginator.length > 0 && (
              <Form.Item
                name="originator"
                label={
                  <h3 className={cx(styles.modal_title, 'mb-[8px]')}>
                    {t('setting_play_mobile.Originators')}
                  </h3>
                }
              >
                <Select
                  className={`${styles.modal_select && 'customSelect'}`}
                  placeholder={t('settingEmployee.Tanlang')}
                  style={{
                    width: '100%',
                    color: '#969696',
                    fontWeight: 400,
                    fontSize: '14px'
                  }}
                  options={dataOriginator.map((item: Originator) => ({
                    value: item.name,
                    label: (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p>{item.name}</p>
                        </div>
                      </>
                    )
                  }))}
                />
              </Form.Item>
            )}

            <Form.Item
              name="times"
              rules={[{ required: true, message: '' }]}
              label={
                <h3 className={cx(styles.modal_title, 'mb-[8px] mt-[8px]')}>
                  {t('integration.Mijozga_eslatma_yuborish')}
                </h3>
              }
            >
              <Select
                className={`${styles.modal_select && 'customSelect'}`}
                mode="multiple"
                allowClear
                placeholder={t('settingEmployee.Tanlang')}
                style={{
                  width: '100%',
                  color: '#969696',
                  fontWeight: 400,
                  fontSize: '14px'
                }}
                options={Object.keys(warningTime).map(key => ({
                  label: t(`home.${warningTime[key as keyof typeof warningTime].label}`),
                  value: key
                }))}
              />
            </Form.Item>

            <Form.Item
              name="text"
              rules={[{ required: true, message: '' }]}
              label={
                <h3 style={{ marginBottom: '8px' }} className={styles.modal_title}>
                  {t('client.Xabar_yuborish')}
                </h3>
              }
            >
              <TextEditable
                fields={buttonValues}
                onInput={val => {
                  form.setFieldsValue({ text: val });
                  setText(val);
                }}
                isEmpty={!text}
              />
            </Form.Item>
          </>
        )}

        {contentMode === 'notifications' && (
          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <AutoSMSTriggerNotifications selectedId={selectedId} />
          </div>
        )}
        <div className={styles.footer_btns} style={{ marginTop: '25px', display: 'flex', gap: '30px' }}>
          {contentMode === 'settings' && (
            <>
              <Button onClick={onCancel}>{t('home.Yopish')}</Button>
              <Button htmlType="submit" type="primary" disabled={isDisabled()} loading={postSMSBot.isLoading}>
                {t('home.Saqlash')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default AutoSMSTrigger;
