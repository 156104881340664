import React, { FC } from 'react';
import { Modal, Tabs } from 'antd';
import styles from './reports.module.scss';
import SMSReport from './SMSReport';
import XLSReport from './XLSReport';
import { useReportConfig } from '../../../queries/queries';
import { ReportType } from '../../../utils/models/ReportType';
import { useTranslation } from 'react-i18next';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const Reports: FC<IProps> = ({ setVisible, visible }) => {
  const { t } = useTranslation();
  const { data } = useReportConfig();
  const onCancle = () => {
    setVisible(false);
  };
  const items = [
    {
      key: '1',
      label: `${t('integration.SMS_hisobot')}`,
      children: (
        <SMSReport
          data={data?.find(item => item?.type === ReportType.WEEKLY_SUMMARY)}
          onCancle={onCancle}
          type={ReportType.WEEKLY_SUMMARY}
          visible={visible}
        />
      )
    },
    {
      key: '2',
      label: `${t('integration.XLS_hisobot')}`,
      children: (
        <XLSReport
          data={data?.find(item => item?.type === ReportType.EXCEL_SUMMARY)}
          onCancle={onCancle}
          type={ReportType.EXCEL_SUMMARY}
          visible={visible}
        />
      )
    }
  ];

  return (
    <Modal visible={visible} onCancel={onCancle} wrapClassName={styles.modal} width={466} title={t('integration.Hisobotlar')} footer={false}>
      <Tabs defaultActiveKey="1">
        {items?.map(item => (
          <Tabs.TabPane key={item?.key} tab={item?.label}>
            {item?.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Modal>
  );
};

export default Reports;
