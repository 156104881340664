import React, { useEffect, useState } from 'react';
import Active from '@pages/integration/assets/icons/Active';
import Deactive from '@pages/integration/assets/icons/Deactive';
import { usePostOneCIntegration } from '@pages/integration/service/mutation';
import { useGetOneCIntegration } from '@pages/integration/service/queries';
import { queryKeys } from '@pages/integration/utils/constants/queryKeys';
import { Button, Spin } from 'antd';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';

import styles from '../../../integration.module.scss';

const OneCTriggerNotifications = () => {
  const { data: dataSMSBot } = useGetOneCIntegration();
  const changeStatus = usePostOneCIntegration();
  const [isLoading, setIsLoading] = useState(false);
  const qc = useQueryClient();

  const handleToggleStatus = () => {
    setIsLoading(true);
    changeStatus.mutateAsync({ ...dataSMSBot!, status: !dataSMSBot?.status }).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    qc.fetchQuery([queryKeys.ONE_C]);
  }, [isLoading]);

  return (
    <div style={{ cursor: 'pointer' }}>
      <Button type="primary" onClick={handleToggleStatus} loading={isLoading} className={styles.greeBtn} style={{ background: dataSMSBot?.status ? '' : '#ff4d4f' }}>
        <Spin spinning={isLoading} size="small" style={{ marginRight: '2px' }}>
          {dataSMSBot?.status ?? false ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Active />
              <p>{t('integration.Aktiv')}</p>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Deactive />
              <p>{t('integration.Aktivlashtirish')}</p>
            </div>
          )}
        </Spin>
      </Button>
    </div>
  );
};

export default OneCTriggerNotifications;

// Мы поняли что с бекенда приходит true или false.
// Но, с бекенда может приходить null, и для него тоже нужно сделать состояние.
