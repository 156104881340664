import React, { useState } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Edit from '../../../assets/icons/Edit';
import { useUpdateCompanyConfigPatters } from '../../../queries/mutation';
import { useCompanyConfigNumeration, useCompanyConfigNumerationFields } from '../../../queries/queries';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import Editable from '../../messageSettings/Editable';

import styles from '../../../pages/messageSettings/MessageSettings.module.scss';

interface IProps {
  title: string;
  type: 'contract' | 'payment';
}

const NumerationItem: React.FC<IProps> = ({ title, type }) => {
  const [visible, setVisible] = useState(true);
  const { data, isLoading } = useCompanyConfigNumeration(type);
  const { data: fields, isLoading: fieldsLoading } = useCompanyConfigNumerationFields(type);
  const updatePattern = useUpdateCompanyConfigPatters(type, () => setVisible(true));
  const { t } = useTranslation();

  const showFields = () => {
    let newText = data || '';

    if (fields) {
      for (let i = 0; i < fields?.length; i++) {
        newText = newText.replaceAll(`${fields[i].key}`, `<span style="margin-right:5px">${getCurrentLanguageWord(fields[i] as any)}</span>`);
      }
    }
    return newText;
  };

  const updateNumeration = (value: string) => {
    updatePattern.mutate(value);
  };

  return (
    <div className={styles.messageSample}>
      <Spin spinning={isLoading && fieldsLoading}>
        {!visible ? (
          <Editable text={data || ''} title={title} fields={fields || []} updateTemplate={updateNumeration} isLoading={updatePattern.isLoading} />
        ) : (
          <>
            <div className={styles.titleDiv}>
              <h5>{title}</h5>
              <div className={styles.edit}>
                <p onClick={() => setVisible(!visible)}>
                  <span>
                    <Edit />
                  </span>
                  {t('client.Tahrirlash')}
                </p>
              </div>
            </div>
            <p
              className={styles.show}
              dangerouslySetInnerHTML={{
                __html: showFields()
              }}
            />
          </>
        )}
      </Spin>
    </div>
  );
};

export default NumerationItem;
