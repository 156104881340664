import React, { FC } from 'react';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowSquareDown from '../../../../../assets/icons/ArrowSquareDown';
import { DateFormatType } from '../../../../../utils/constants/DateFormatType';
import { NextPrev } from '../../../../../utils/constants/NextPrev';
import { dateFormat } from '../../../utils/helper/DateFormat';
import { dateNextPrev } from '../../../utils/helper/DateNextPrev';
import { getDateType } from '../../../utils/helper/GetDateType';
import { StatLeadDateDto } from '../../../utils/models/StatLead';
import Header from '../../header/Header';

import styles from './leadStatisticHeader.module.scss';

interface IProps {
  setDateType: React.Dispatch<React.SetStateAction<DateFormatType>>;
  dateType: DateFormatType;
  dataDate: StatLeadDateDto[] | undefined;
  date: {
    startDate: string;
    finishDate: string;
  };
  setDate: React.Dispatch<
    React.SetStateAction<{
      startDate: string;
      finishDate: string;
    }>
  >;
}

const LeadStatisticHeader: FC<IProps> = ({ setDate, setDateType, dateType, dataDate, date }) => {
  const count = dataDate?.reduce((prev, current) => prev + current?.countLead, 0);
  const { t } = useTranslation();

  const handleNextPrev = (type: NextPrev) => {
    setDate(dateNextPrev(type, dateType, date));
  };

  const changeSegmented = (val: DateFormatType) => {
    setDateType(val);
    setDate(dateFormat(val));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>{t('boss_crm.Lidlar')}</div>
        <div className={styles.center}>
          <Segmented
            value={dateType}
            options={[
              { label: t('boss_payments.Kunlik'), value: 'DAY' },
              { label: t('boss_payments.Oylik'), value: 'MONTH' },
              { label: t('boss_payments.Yillik'), value: 'YEAR' }
            ]}
            onChange={val => changeSegmented(val as DateFormatType)}
            className={styles.segmented}
          />
        </div>
        <div>
          <Header />
        </div>
      </div>
      <div className={styles.slider}>
        <ArrowSquareDown onClick={() => handleNextPrev('PREV')} className={styles.slider_arrow_left} />
        <div className={styles.slider_middle}>
          <h2>{getDateType(dateType, date.startDate, date.finishDate)}</h2>
          <p>
            {count} {t('boss_crm.bitim')}
          </p>
        </div>
        <ArrowSquareDown onClick={() => handleNextPrev('NEXT')} className={styles.slider_arrow_right} />
      </div>
    </>
  );
};

export default LeadStatisticHeader;
