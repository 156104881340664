import React from 'react';

const SwiperPrevIcon = () => {
  return (
    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.764134 4.99448C0.765141 4.87896 0.810983 4.76834 0.891989 4.68597L5.25562 0.322333C5.33792 0.242846 5.44815 0.198863 5.56256 0.199857C5.67698 0.200851 5.78642 0.246743 5.86733 0.327648C5.94823 0.408554 5.99412 0.517999 5.99512 0.632412C5.99611 0.746826 5.95213 0.857052 5.87264 0.939351L1.81752 4.99448L5.87264 9.04961C5.91432 9.08986 5.94756 9.13801 5.97043 9.19125C5.9933 9.24449 6.00534 9.30175 6.00584 9.35969C6.00635 9.41763 5.99531 9.47509 5.97336 9.52871C5.95142 9.58234 5.91902 9.63106 5.87805 9.67203C5.83708 9.713 5.78836 9.74541 5.73473 9.76735C5.6811 9.78929 5.62364 9.80033 5.5657 9.79982C5.50776 9.79932 5.4505 9.78728 5.39727 9.76441C5.34403 9.74154 5.29588 9.7083 5.25562 9.66662L0.891989 5.30299C0.810983 5.22062 0.765141 5.11 0.764134 4.99448Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default SwiperPrevIcon;
