import { BackendFetch, DevTools, I18nextPlugin, Tolgee, withTolgee } from '@tolgee/i18next';
import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languages = ['uz', 'ru', 'en', 'tr'];
const ns = 'uysot';
const detection = { order: ['sessionStorage', 'localStorage', 'navigator'] };

const tolgee = Tolgee()
  .use(DevTools())
  .use(BackendFetch())
  .use(I18nextPlugin())
  .init({
    apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
    projectId: import.meta.env.VITE_TOLGEE_PROJECT_ID,
    ns: ['uysot'],
    availableLanguages: languages,
    defaultLanguage: languages[0]
  });

withTolgee(i18n, tolgee)
  .use(initReactI18next)
  .use(languageDetector)
  .init({ detection, supportedLngs: languages, ns, react: { useSuspense: false } });

export default i18n;
