import { FlatDiscount } from '../models/Discount';
import { FlatState } from '../../store/reducers/FlatSlice';

export const calculateDiscount: (baseList: FlatDiscount[], prepayment: number) => number | null = (baseList: FlatDiscount[], prepayment: number) => {
  for (let i = 0; i < baseList.length; i++) {
    if (
      (prepayment >= baseList[i].firstPayment && i === baseList.length - 1) ||
      (prepayment >= baseList[i].firstPayment && prepayment < baseList[i + 1].firstPayment)
    ) {
      return baseList[i].discountAmount;
    }
  }
  return null;
};

export const changeDiscount: (state: FlatState) => FlatState = state => {
  const currentState = { ...state };
  if (currentState.prepayment && currentState.isUsingDiscount) {
    if (currentState.selectedType === 'repaired') {
      const discount = calculateDiscount(currentState.discountRepaired, currentState.prepayment);
      currentState.discount = discount;
      if (discount && discount !== 0) {
        currentState.discount = discount;
        currentState.discountDescription = `Chegirma mavjudligi tufayli`;
      } else {
        currentState.discount = null;
        currentState.discountDescription = ``;
      }
    } else {
      const discount = calculateDiscount(currentState.discountNotRepaired, currentState.prepayment);
      if (discount && discount !== 0) {
        currentState.discount = discount;
        currentState.discountDescription = `Chegirma mavjudligi tufayli`;
      } else {
        currentState.discount = null;
        currentState.discountDescription = ``;
      }
    }
  }
  return currentState;
};
