import React from 'react';

const TabUserIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.49984 6.49992C7.99561 6.49992 9.20817 5.28736 9.20817 3.79158C9.20817 2.29581 7.99561 1.08325 6.49984 1.08325C5.00407 1.08325 3.7915 2.29581 3.7915 3.79158C3.7915 5.28736 5.00407 6.49992 6.49984 6.49992Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.153 11.9167C11.153 9.82042 9.06758 8.125 6.50008 8.125C3.93258 8.125 1.84717 9.82042 1.84717 11.9167"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TabUserIcon;
