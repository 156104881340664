import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContractState {
  contractId: number | null;
  contractNumber: string | null;
  visible: boolean;
  currentPage: number;
}

const initialState: ContractState = {
  contractId: null,
  contractNumber: null,
  visible: false,
  currentPage: 1
};

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setContractId: (state, action: PayloadAction<number | null>) => {
      state.contractId = action.payload;
    },
    setContractNumber: (state, action: PayloadAction<string | null>) => {
      state.contractNumber = action.payload;
    },
    setVisible: (
      state,
      action: PayloadAction<{
        visible: boolean;
        contractId: number | null;
        contractNumber: string | null;
      }>
    ) => {
      state.visible = action.payload.visible;
      state.contractId = action.payload.contractId;
      state.contractNumber = action.payload.contractNumber;
    },
    setClear: state => {
      state.contractId = null;
      state.contractNumber = null;
      state.visible = false;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    }
  }
});

export const { setContractNumber, setContractId, setVisible, setClear, setCurrentPage } = contractSlice.actions;
export default contractSlice.reducer;
