import React, { useState } from 'react';
import { encodeString } from '@utils/helper';
import { Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLeftIcon from '../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../assets/icons/contract/ArrowRightIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useStartCountTimeNotTaskLeadNotification } from '../../queries/mutation';
import { useEmployeeNotTaskList } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { hideNotificationNotTaskLead } from '../../store/reducers/NotTaskLeadNotificationSlice';
import { CrmLead } from '../../utils/models/CrmLeadList';
import { EmployeeId } from '../../utils/models/EmployeeList';

import styles from './notTaskLeadNotification.module.scss';

const NotTaskLeadNotification = () => {
  const { push } = useQueryParam();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const showModal = useAppSelector(state => state.notTaskLeadNotification.showModal);

  const { data, isLoading, isFetching } = useEmployeeNotTaskList(showModal, page, size);

  const { t } = useTranslation();

  const startCountTime = useStartCountTimeNotTaskLeadNotification();

  const initialColumn = [
    {
      title: t('not_taks_notif.Lid_nomi'),
      dataIndex: 'name'
    },
    {
      title: t('not_taks_notif.Asosiy_aloqa'),
      dataIndex: 'contactName'
    },
    {
      title: t('not_taks_notif.Yaratilgan_sana'),
      dataIndex: 'createdDate'
    },
    {
      title: t('not_taks_notif.Masul'),
      dataIndex: 'responsibleBy',
      render: (record: EmployeeId) => record?.fullName
    },
    {
      title: t('not_taks_notif.Status'),
      dataIndex: 'pipeStatusName',
      align: 'center',
      render: (record: string) => (
        <div className={styles.container_status}>
          <span>{record}</span>
        </div>
      )
    }
  ];

  // handle cancel
  const handleCancel = () => {
    startCountTime.mutate();
    dispatch(hideNotificationNotTaskLead());
  };

  // go to one lead
  const goToOneLead = (e: CrmLead) => {
    push(`${rootPaths.MAIN + MainPaths.CRM}/${e.id}/${encodeString(e.name)}?noteTaskModal=true`);
    handleCancel();
  };

  return (
    <Modal
      className={styles.container}
      width={1138}
      footer={false}
      visible={showModal}
      title={t('not_taks_notif.Topshiriq_biriktirilmagan_lidlar')}
      onCancel={handleCancel}
    >
      <div className={styles.container_table}>
        <Table
          columns={initialColumn as any}
          dataSource={data?.data || []}
          rowKey="id"
          className="tableNewStyle"
          pagination={{
            className: 'tableNewStyle_pagination',
            prevIcon: (
              <div className="tableNewStyle_pagination_prev">
                <ArrowLeftIcon /> {t('my_calls.Ortga')}
              </div>
            ),
            onChange(page, pageSize) {
              setPage(page);
              setSize(pageSize);
            },
            pageSize: 10,
            total: data?.totalElements,
            current: Number(data?.currentPage) || 1,
            nextIcon: (
              <div className="tableNewStyle_pagination_next">
                {t('crm_ins.Keyingisi')} <ArrowRightIcon />
              </div>
            )
          }}
          loading={isLoading || isFetching}
          onRow={(e: CrmLead) => ({
            onClick: () => goToOneLead(e)
          })}
        />
      </div>
    </Modal>
  );
};

export default NotTaskLeadNotification;
