import React from 'react';
import { useTranslation } from 'react-i18next';

import PlusIcon from '../../assets/icons/PlusIcon';

import styles from '../../pages/kpi/kpi.module.scss';

interface Props {
  openModal: (visible: boolean) => void;
}

const AddItem: React.FC<Props> = ({ openModal }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    openModal(true);
  };

  return (
    <div onClick={handleClick} className={styles.cont_body_right_add} style={{ marginBottom: '50px' }}>
      <PlusIcon />
      <span>{t('kpi.Mukofot_formulasi_qoshish')}</span>
    </div>
  );
};

export default AddItem;
