import { useLocation, useNavigate } from 'react-router-dom';

export const withRouter = (OriginalComponent: any) => {
  const NewCompoent = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <OriginalComponent navigate={navigate} location={location} {...props} />;
  };

  return NewCompoent;
};
