import React, { FC, useEffect, useState } from 'react';
import { AutoComplete, Button, Input, Modal } from 'antd';
import { t } from 'i18next';

import { useOpenCagaData } from '../../../queries/queries';

import HouseLocationMap, { DEFAULT_CENTER } from './HouseLocationMap';

import styles from './houseLocationModal.module.scss';

interface IProps {
  setHouseLocationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  houseLocationVisible: boolean;
  setLocation: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  location: {
    lat: number;
    lng: number;
  };
}

const HouseLocationModal: FC<IProps> = ({ houseLocationVisible, setHouseLocationVisible, location, setLocation }) => {
  const [select, setSelect] = useState('');
  const { data } = useOpenCagaData(`q=${select}`);
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0
  });

  useEffect(() => {
    function myLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          err => {
            setCenter(DEFAULT_CENTER);
          }
        );
      } else {
        setCenter(DEFAULT_CENTER);
      }
    }
    myLocation();
  }, []);

  useEffect(() => {
    if (location.lat && location.lng) {
      setCenter(location);
    }
  }, [location]);

  const handleCancel = () => {
    setHouseLocationVisible(false);
  };

  const handleSave = () => {
    setLocation(center);
    handleCancel();
  };

  return (
    <Modal
      title={t('home.Lokatsiyani_biriktirish')}
      visible={houseLocationVisible}
      onCancel={handleCancel}
      footer={false}
      wrapClassName={styles.container}
    >
      <div className={styles.search}>
        <p>{t('profile.Manzil')}</p>
        <AutoComplete
          options={data?.results?.map(item => ({
            ...item,
            value: item?.formatted
          }))}
          onSelect={(e: any, b: any) => {
            setCenter(b?.geometry);
          }}
          onSearch={e => e.length >= 3 && setSelect(e)}
        >
          <Input className="customInput" placeholder={t('home.Kocha_Kordinata_yoki_URL_orqali_izlang')} />
        </AutoComplete>
      </div>
      <div className={styles.map}>
        <HouseLocationMap center={center} setCenter={setCenter} />
      </div>

      <div className={styles.footer}>
        <Button onClick={handleCancel}>{t('one_lid.Bekor_qilish')}</Button>
        <Button type="primary" onClick={handleSave}>
          {t('one_lid.Saqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default HouseLocationModal;
