import React from 'react';

import ClientMainData from './ClientMainData';

import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

const Juridical: React.FC = () => (
  <div className={styles.client_cont}>
    <ClientMainData />
  </div>
);

export default Juridical;
