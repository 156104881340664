import React, { FC, useState } from 'react';

// import { useLeadChangeStep } from '../../../../queries/mutation'
import { PipeStatuses } from '../../../../utils/models/PipeAll';

import styles from './voronkaCards.module.scss';

interface IProps extends PipeStatuses {
  index: number;
  dragging: boolean;
  draggingId: number;
  setLeadTaskCompleteModal: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  >;
  setReasonsRefusal: (
    value: React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  ) => void;
}

const VoronkaCardColumnFooter: FC<IProps> = props => {
  const [hover, setHover] = useState(false);

  const draggingFinish = () => {
    if (props.type === 'FAILED') {
      props.setReasonsRefusal({
        id: props?.pipeId,
        leadId: props?.draggingId,
        statusId: props?.id!,
        visible: true
      });
    } else {
      props.setLeadTaskCompleteModal({
        id: props?.pipeId,
        leadId: props?.draggingId,
        statusId: props?.id!,
        visible: true
      });
      // leadChageStep.mutate({
      //   leadId: props?.draggingId,
      //   statusId: props?.id!,
      //   updateTimeDate: dayjs().format('DD.MM.YYYY HH:mm:ss.SSS'),
      // })
    }
  };

  return (
    <div
      className={styles.voronkaCards_footer_card}
      style={{
        borderColor: props.color,
        color: props.color,
        background: hover && props.dragging ? 'lightblue' : '#ffffff'
      }}
      onMouseEnter={e => {
        setHover(true);
      }}
      onMouseUp={e => {
        draggingFinish();
      }}
      onMouseLeave={e => {
        setHover(false);
      }}
    >
      {props.name}
    </div>
  );
};

export default VoronkaCardColumnFooter;
