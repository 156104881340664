import { Button, Popover, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Nike from '../../../../assets/icons/Nike';
import Square from '../../../../assets/icons/Square';
import ThreePoint from '../../../../assets/icons/ThreePoint';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useCurrencyList } from '../../../../queries/queries';
import { setSelectedContract } from '../../../../store/reducers/ChangesSlice';
import { ChangesPageSteps, ChangesQueryParams } from '../../../../utils/models/Changes';
import { ContractChanges } from '../../../../utils/models/ContractId';
import { useGetContractChanges, useGetOneContractChanges } from '../../services/queries';

import styles from './Contract.module.scss';

const ContractsCard = () => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<ChangesQueryParams, any>();
  const { data, isLoading } = useGetContractChanges(searchParams.clientId);
  const { data: currency } = useCurrencyList();

  const dispatch = useAppDispatch();

  const selectedContract = useAppSelector(state => state.changesReducer.selectedContract);

  const { isLoading: LoadingOneContract } = useGetOneContractChanges(searchParams.contractId, !!searchParams.contractId && !selectedContract);

  const { t } = useTranslation();

  const content = (
    <div className="popoverContractEdit">
      <p>
        <span>
          <Square />
        </span>
        O'chirish
      </p>
    </div>
  );

  const handleClickContract = (contract: ContractChanges | null) => {
    if (!contract) {
      appendMultipleDifferent(['page', 'contractId'], [ChangesPageSteps.contract, undefined as any]);
    }
    dispatch(setSelectedContract(contract));
  };

  const goToNextPage = () => {
    appendMultipleDifferent(['page', 'contractId'], [ChangesPageSteps.monthlyPayments, String(selectedContract?.id)]);
  };

  // get currency ccy
  // eslint-disable-next-line consistent-return
  const getCcy = (id: number | undefined) => {
    if (currency && id) {
      return currency.find(item => item.id === id)?.ccy;
    }
  };

  return (
    <Spin spinning={isLoading || LoadingOneContract}>
      <div>
        <h1 style={{ marginBottom: '1rem' }}>{t('changes.contract.Shartnomalar')}</h1>

        {selectedContract ? (
          <div onClick={() => handleClickContract(null)} className={styles.contractsCardActive}>
            <div>
              <Nike />
            </div>
            <Popover title="Amallar" content={content} trigger="click" placement="rightTop">
              <div>
                <ThreePoint />
              </div>
            </Popover>
            <p>
              {t('changes.contract.Shartnoma_raqami')}: <span>{selectedContract.number}</span>
            </p>
            <p>
              {t('changes.contract.Yaratilgan')}: <span>{selectedContract.createdDate}</span>
            </p>
            <p>
              {t('changes.contract.Muddati')}:{' '}
              <span>
                {selectedContract.delay} {t('changes.contract.oy')}
              </span>
            </p>
            <p>
              {t('changes.contract.Shartnoma_summasi')}:
              <span>
                {selectedContract.amount?.toLocaleString('ru')} {getCcy(selectedContract.currencyId)}
              </span>
            </p>
          </div>
        ) : (
          data?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div onClick={() => handleClickContract(item)} key={index} className={styles.contractsCard}>
              <Popover title="Amallar" content={content} trigger="click" placement="rightTop">
                <div>
                  <ThreePoint />
                </div>
              </Popover>
              <p>
                {t('changes.contract.Shartnoma_raqami')}: <span>{item.number}</span>
              </p>
              <p>
                {t('changes.contract.Yaratilgan')}: <span>{item.createdDate}</span>
              </p>
              <p>
                {t('changes.contract.Muddati')}:{' '}
                <span>
                  {item.delay} {t('changes.contract.oy')}
                </span>
              </p>
              <p>
                {t('changes.contract.Shartnoma_summasi')}:{' '}
                <span>
                  {item.amount.toLocaleString('ru')} {getCcy(item.currencyId)}
                </span>
              </p>
            </div>
          ))
        )}

        {selectedContract && (
          <Button onClick={goToNextPage} className="customFormButton next_stageBTN">
            {t('changes.contract.Keyingi_bosqich')}
          </Button>
        )}
      </div>
    </Spin>
  );
};

export default ContractsCard;
