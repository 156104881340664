import { useAppSelector } from '@hooks/reduxHooks';
import { $api } from '@service/RequestService';
import { Permissions } from '@utils/constants/permissions';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/helpers/endPoints';
import { queryKeys } from '../utils/helpers/queryKeys';
import { TGPremiumType } from '../utils/models/TGPremiumType';

export function useGetTGChatBotData() {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isViewTelegramChatBot = permissions?.includes(Permissions.PERMISSION_TELEGRAM_CHAT_BOT);

  return useQuery<TGPremiumType | null>({
    queryKey: [queryKeys.TG_CHATBOT],
    queryFn: async () => {
      const res = await $api.get(endPoints.TG_CHATBOT);

      return res.data.data;
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isViewTelegramChatBot
  });
}

export function useGetEmployees() {
  return useQuery<any>({
    queryKey: [queryKeys.GET_EMPLOYEES],
    queryFn: async () => {
      const res = await $api.get(endPoints.GET_EMPLOYEES);

      return res.data.data;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false
  });
}

export function useGetTGChatBotURL(enabled: boolean) {
  return useQuery<TGPremiumType>({
    queryKey: [queryKeys.GET_TG_CHATBOT_URL],
    queryFn: async () => {
      const res = await $api.get(endPoints.GET_TG_CHATBOT_URL);

      return res.data.data;
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled
  });
}
