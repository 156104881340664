import { useMutation, useQueryClient } from 'react-query';
import { endPoints } from '../utils/constants/endPoints';
import { $api } from '@service/RequestService';
import { queryKeys } from '../utils/constants/queryKeys';
import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { queryNames } from '@utils/constants/queryNames';

export function useCompanyRolePermission() {
  const qc = useQueryClient();
  return useMutation(
    async (data: { id: number; permission: string; active: boolean }) => {
      const res = await $api.patch(endPoints.GET_ROLES + '/' + data.id + '/permission/' + data.permission + `?active=${data.active}`);
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.GET_ROLES]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCompanyCreateRole() {
  const qc = useQueryClient();
  return useMutation(
    async (data: {
      name?: string | null;
      nameUz?: string;
      nameRu?: string;
      nameEn?: string;
      nameKr?: string;
      nameTr?: string;
      permissions?: string[];
      id?: number;
    }) => {
      const res = await $api.post(endPoints.GET_ROLES, data);
      return res.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.GET_ROLES]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useCompanyDeleteRole() {
  const qc = useQueryClient();
  return useMutation(
    async (id: number) => {
      const res = await $api.delete(endPoints.DELETE_ROLES + '/' + id);
      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.GET_ROLES]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useUpdateAllPermissions(companyId?: string) {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const res = await $api.put(endPoints.PERMISSION_COMPANY_ROLE_STATE, data);
      return res.data;
    },
    onSuccess: res => {
      showMessage(res.message, res.accept);
      qc.invalidateQueries({
        queryKey: [queryNames.COMPANY_ROLE_PERMISSION, companyId]
      });
    },
    onError: baseErrorHandler
  });
}

export function useUpdateAllPermissionsDev(companyId?: string) {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      const res = await $api.put(endPoints.PERMISSION_STATE, data);
      return res.data;
    },
    onSuccess: res => {
      showMessage(res.message, res.accept);
      qc.invalidateQueries({
        queryKey: [queryNames.COMPANY_ROLE_PERMISSION, companyId]
      });
    },
    onError: baseErrorHandler
  });
}
