import React, { useEffect, useState } from 'react';
import Active from '@pages/integration/assets/icons/Active';
import Deactive from '@pages/integration/assets/icons/Deactive';
import { queryKeys } from '@pages/integration/utils/constants/queryKeys';
import { useGetEmbedWidget } from '@pages/oneLid/services/queries';
import { useCreateEmbedWidget } from '@queries/mutation';
import { Button, Spin } from 'antd';
import { t } from 'i18next';
import { useQueryClient } from 'react-query';

import styles from '../../../integration.module.scss';

const EmbedWidgetActivate = () => {
  const { data: embedWidget } = useGetEmbedWidget();
  const changeEmbedStatus = useCreateEmbedWidget();
  const [isLoading, setIsLoading] = useState(false);
  const qc = useQueryClient();

  const handleToggleStatus = () => {
    setIsLoading(true);
    changeEmbedStatus.mutateAsync({ ...embedWidget!, active: !embedWidget?.active }).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    qc.fetchQuery([queryKeys.EMBED_WIDGET]);
  }, [isLoading]);

  return (
    <div style={{ cursor: 'pointer' }}>
      <Button type="primary" onClick={handleToggleStatus} loading={isLoading} className={styles.greeBtn} style={{ background: embedWidget?.active ? '' : '#ff4d4f' }}>
        <Spin spinning={isLoading} size="small" style={{ marginRight: '2px' }}>
          {embedWidget?.active ?? false ? (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Active />
              <p>{t('integration.Aktiv')}</p>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Deactive />
              <p>{t('integration.Aktivlashtirish')}</p>
            </div>
          )}
        </Spin>
      </Button>
    </div>
  );
};

export default EmbedWidgetActivate;

// Мы поняли что с бекенда приходит true или false.
// Но, с бекенда может приходить null, и для него тоже нужно сделать состояние.
