import MonthDiscount from './components/month-discount/MonthDiscount';
import MonthDiscountHeader from './components/month-discount-header/MonthDiscountHeader';
import SunburstChart from './components/sunburst/SunburstChart';
import SunburstHeader from './components/sunburst-header/SunburstHeader';

import styles from './contractComplex.module.scss';

const ContractComplex = () => (
  <div className={styles.container}>
    <div className={styles.container_item}>
      <SunburstHeader />
      <SunburstChart />
    </div>
    <div className={styles.container_item}>
      <MonthDiscountHeader />
      <MonthDiscount />
    </div>
  </div>
);

export default ContractComplex;
