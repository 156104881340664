import React from 'react';
import { Radio as BaseRadio, RadioGroupProps as BaseRadioGroupProps, RadioProps as BaseRadioProps } from 'antd';

export interface RadioProps extends BaseRadioProps {}

const Radio: React.FC<RadioProps> & { Group: React.FC<BaseRadioGroupProps> } = ({ className, ...props }) => <BaseRadio {...props} className={className} />;

Radio.Group = BaseRadio.Group;

export default Radio;
