/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useTimer } from '@hooks/useTimer';
import { Slider, Spin } from 'antd';

import PlayerPauseIcon from '../../assets/icons/PlayerPauseIcon';
import PlayerPlayIcon1 from '../../assets/icons/PlayerPlayIcon1';
import ReceiveSquareIcon from '../../assets/icons/ReceiveSquareIcon';
import { useDownloadFilesByLink } from '../../queries/mutation';
import { BASE_URL } from '../../service/RequestService';

import defaultStyles from './customPlayer.module.scss';
import miniStyles from './miniPlayer.module.scss';

interface Props {
  url: string;
  name: string;
  type: string;
  isMini?: boolean;
  isBaseUrl?: boolean;
  play?: boolean;
  isloading?: boolean;
  isDownload?: boolean;
  calls?: boolean;
  forDownload?: boolean;
  callTime?: number;
}

const CustomPlayer: React.FC<Props> = ({ url, name, type, isMini, isBaseUrl, play, isloading, isDownload, calls, forDownload, callTime }) => {
  const [sound, setSound] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentMoment, setCurrentMoment] = useState(0);
  const { handleStart, handlePause, timer, handleReset } = useTimer(0);

  const download = useDownloadFilesByLink(url, name, type);

  const interval = useRef<any>();
  const player = React.createRef<HTMLAudioElement>();

  useEffect(() => {
    if (player.current && player.current.duration) {
      setDuration(Math.floor(player.current.duration) * 10);
    }
  }, [player]);

  useEffect(
    () => () => {
      setCurrentMoment(0);
      clearInterval(interval.current);
      setIsPlaying(false);
      handleReset();
    },
    []
  );

  useEffect(() => {
    if (player.current && isPlaying) {
      interval.current = setInterval(() => {
        if (player.current?.currentTime) {
          if (currentMoment + 1 === duration) {
            player.current.pause();
            player.current.currentTime = 0;
            setCurrentMoment(0);
            clearInterval(interval.current);
            setIsPlaying(false);
            handleReset();
          } else {
            setCurrentMoment(currentMoment + 1);
          }
        }
      }, 100 / sound);
    }
  }, [player, isPlaying, sound]);

  useEffect(() => {
    if (player.current) {
      player.current.playbackRate = sound;
    }
  }, [sound, player]);

  // handle download
  const handleDownload = async () => {
    download.mutate();
  };

  // handle change duration
  const handleChange = (current: number) => {
    if (player.current && player.current.currentTime) {
      player.current.currentTime = current / 10;
      setCurrentMoment(current);
    }
  };

  // handle play
  const handlePlay = () => {
    if (player.current) {
      handleStart();
      player.current.play();
    }
  };

  // handle pause
  const handlePause1 = () => {
    if (player.current) {
      handlePause();
      player.current.pause();
      clearInterval(interval.current);
    }
  };

  // handle action button
  const handleAction = () => {
    if (isPlaying) {
      handlePause1();
    } else {
      handlePlay();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (play) {
      handlePlay();
    } else {
      handlePause1();
    }
  }, [play]);

  // handleSound
  const handleSound = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const sounds = [1, 1.2, 1.5, 1.7, 2];
    const newValue = (index: number) => (e.ctrlKey ? sounds[sounds.indexOf(index) - 1] ?? sounds[sounds.length - 1] : sounds[sounds.indexOf(index) + 1] ?? sounds[0]);

    setSound(newValue);
  };

  function convertSeconds(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes > 9 ? minutes : `0${minutes}`}:${remainingSeconds > 9 ? remainingSeconds : `0${remainingSeconds}`}`;
  }

  if (!forDownload) {
    return (
      <div className={isMini && !calls ? miniStyles.container : calls ? miniStyles.callsContainer : defaultStyles.container} style={{ width: calls ? 360 : '' }}>
        <audio ref={player} src={isBaseUrl ? url : `${BASE_URL}/${url}`} />

        <div onClick={handleAction} className={isMini && !calls ? miniStyles.container_player : calls ? miniStyles.callsContainer_player : defaultStyles.container_player}>
          {isPlaying && url !== '' ? (
            <PlayerPauseIcon />
          ) : isloading ? (
            <Spin spinning={isloading}>
              <PlayerPlayIcon1 fill="#00A389" />
            </Spin>
          ) : (
            <PlayerPlayIcon1 fill={url === '' ? 'red' : '#00A389'} />
          )}
        </div>
        {calls && (
          <div className="flex items-center text-[#D0D5DD]">
            {convertSeconds(timer)} | {convertSeconds(callTime!)}
          </div>
        )}
        <Slider
          onChange={handleChange}
          value={currentMoment}
          max={duration}
          tooltipVisible={false}
          className={isMini && !calls ? miniStyles.container_slider : calls ? miniStyles.callsContainer_slider : defaultStyles.container_slider}
          style={{ width: calls ? 180 : '' }}
        />
        {!calls && (
          <span className={defaultStyles.sound} onClick={handleSound}>
            {sound}x
          </span>
        )}
        {download.isLoading ? (
          <Spin size="small" />
        ) : !isDownload && !calls ? (
          <ReceiveSquareIcon onClick={handleDownload} className={isMini ? miniStyles.container_download : defaultStyles.container_download} />
        ) : (
          ''
        )}
      </div>
    );
  }
  return (
    <div className={defaultStyles.container} style={{ boxShadow: 'none', width: '100%', padding: 0 }}>
      <audio ref={player} src={isBaseUrl ? url : `${BASE_URL}/${url}`} />

      <div onClick={handleAction}>
        {isPlaying && url !== '' ? (
          <div className="flex items-center justify-center rounded-lg border border-solid border-[#EAECF0] bg-[#EFF1F5] p-2">
            <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#00A389]">
              <PlayerPauseIcon fill="#ffffff" />
            </div>
          </div>
        ) : isloading ? (
          <Spin spinning={isloading}>
            <div className="flex items-center justify-center rounded-lg border border-solid border-[#EAECF0] bg-[#EFF1F5] p-2">
              <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#00A389]">
                <PlayerPlayIcon1 fill="#ffffff" className="h-4 w-4" />
              </div>
            </div>
          </Spin>
        ) : (
          <div className="flex items-center justify-center rounded-lg border border-solid border-[#EAECF0] bg-[#EFF1F5] p-2">
            <div className="flex h-7 w-7 items-center justify-center rounded-full bg-[#00A389]">
              <PlayerPlayIcon1 fill="#ffffff" className="h-4 w-4" />
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full flex-col gap-1">
        <span className="pl-5">{name}</span>
        <Slider
          onChange={handleChange}
          value={currentMoment}
          max={duration}
          tooltipVisible={false}
          className={defaultStyles.container_slider}
          style={{ width: '90%', marginLeft: 24 }}
        />
      </div>
    </div>
  );
};

export default CustomPlayer;
