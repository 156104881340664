/* eslint-disable react/jsx-key */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { setUrl } from '@utils/helper/signInSuccess';
import { Avatar, Dropdown, Grid, Layout, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import DashboardIcon2 from '@icons/DashboardIcon2';
import ExportBossIcon from '../../../assets/icons/ExportBossIcon';
import LoginArrovRightIcon from '../../../assets/icons/LoginArrovRightIcon';
import LogOutIcon from '../../../assets/icons/LogOutIcon';
import UserAvatarIcon from '../../../assets/icons/UserAvatarIcon';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { MainPaths } from '../../../routing/main/mainPaths';
import { rootPaths } from '../../../routing/rootPaths';
import { LocalStorage } from '../../../service/LocalStorage';
import { BASE_URL } from '../../../service/RequestService';
import { COLLAPSED_MENU } from '../../../utils/constants/localStorageKeys';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import { logOut } from '../../../utils/helper/logOut';

import navBarlinks from './navBarLinks';

import logo from '../../../assets/img/logo.png';
import LoginImage from '../../../assets/img/unsplash_3TLl_97HNJo.png';

import styles from './navbar.module.scss';

const { Sider } = Layout;

const NavBar = () => {
  const { push } = useQueryParam();

  const { xxl } = Grid.useBreakpoint();

  const [colapsed, setColapsed] = useState(LocalStorage.get(COLLAPSED_MENU) || false);

  const user = useAppSelector(state => state.userReducer.user);

  const { t } = useTranslation();

  // log out
  const logOutHandler = async () => {
    await logOut();
  };

  const LogOut = () => (
    <div className="LoginUser">
      <div>
        <Avatar
          style={{
            width: '60px',
            height: '60px'
          }}
          src={BASE_URL + '/' + user?.avatar}
          alt={LoginImage}
        />
        <div onClick={goToProfile}>
          <p>{user?.fullName}</p>
          <span>
            {t('navbars.Profilni_korish')} <LoginArrovRightIcon />
          </span>
        </div>
      </div>
      <span onClick={logOutHandler}>
        <LogOutIcon />
        {t('navbars.Chiqish')}
      </span>
    </div>
  );

  const goToProfile = () => {
    push(rootPaths.MAIN + MainPaths.PROFILE);
  };

  // change collapsed
  const changeCollapsed = () => {
    LocalStorage.set(COLLAPSED_MENU, String(!colapsed));
    setColapsed(!colapsed);
  };

  const permissions = useAppSelector(state =>
    state.userReducer?.user?.role
      ? state.userReducer?.user?.role.permissions
      : state.userReducer?.user?.roleTokens && state.userReducer?.user?.roleTokens[0]?.role?.permissions
  );

  return (
    <Sider collapsedWidth={!xxl ? 88 * 0.7 : 88} width={!xxl ? 235 * 0.7 : 235} className="navbar" theme="light" trigger={null} collapsible collapsed={colapsed as boolean}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
        <span style={{ marginRight: '12px' }}>{!colapsed && 'UYSOT'}</span>
        <button onClick={changeCollapsed} className={styles.dashboardIconStyle}>
          <DashboardIcon2 />
        </button>
        <div className={styles.logoArrow} />
      </div>
      {/* <div className="SideBarArrow" onClick={changeCollapsed}>
        <UnionIcon />
        <span
          style={{
            transform: colapsed ? 'rotateY(0deg)' : 'rotateY(180deg)'
          }}
        >
          <SidebarArrowRightIcon />
        </span>
      </div> */}
      <div className={styles.navbar_direction}>
        {navBarlinks.map(navlink =>
          !colapsed ? (
            <NavLink className="bossMenuNavLik" key={navlink.path} to={navlink.path}>
              {navlink.icon}
              <span>{t(`titles.${navlink.name}`)}</span>
            </NavLink>
          ) : (
            // eslint-disable-next-line react/jsx-key
            <Tooltip placement="right" title={t(`titles.${navlink.name}`)}>
              <NavLink className="bossMenuNavLik" key={navlink.path} to={navlink.path}>
                {navlink.icon}
              </NavLink>
            </Tooltip>
          )
        )}
      </div>
      <div className={styles.navbar_footer}>
        {!colapsed ? (
          <>
            <NavLink className="bossMenuNavLik" to={setUrl(permissions || [])}>
              <ExportBossIcon />
              <span>Uysot.uz</span>
            </NavLink>
            <Dropdown overlay={LogOut} trigger={['click']}>
              <div>
                <UserAvatarIcon />
                <div>
                  <h3>{user?.fullName}</h3>
                  <h4>{user?.role && getCurrentLanguageWord(user.role.lang)}</h4>
                </div>
              </div>
            </Dropdown>
          </>
        ) : (
          <>
            <Tooltip placement="right" title="Uysot.uz">
              <NavLink className="bossMenuNavLik" to={setUrl(user?.role?.permissions || [])}>
                <ExportBossIcon />
              </NavLink>
            </Tooltip>
            <Dropdown overlay={LogOut} trigger={['click']}>
              <Tooltip placement="right" title={user?.role && getCurrentLanguageWord(user.role.lang)}>
                <div>
                  <UserAvatarIcon />
                </div>
              </Tooltip>
            </Dropdown>
          </>
        )}
      </div>
    </Sider>
  );
};

export default NavBar;
