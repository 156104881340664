import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setCalendarView, setChangeDefaultActiveKey } from '@reducers/CrmSlice';
import { ACTION_EVENTS_GA, TYPE_EVENTS_GA } from '@utils/models/GoogleAnalitks';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import ReactGA from 'react-ga';
import KanbanIcon from '@icons/KanbanIcon';
import CalendarTwoIcon from '@icons/CalendarTwoIcon';
import TableIcon from '@icons/TableIcon';

import styles from './changeViewTypeButtons.module.scss';

const { TabPane } = Tabs;

const ChangeViewTypeButtons = () => {
  const { t } = useTranslation();
  const viewType = useAppSelector(state => state.assigmentSlice.calendarView);
  const activeKey = useAppSelector(state => state.assigmentSlice.defaultActiveKey);
  const dispatch = useAppDispatch();

  const showKanbanView = () => {
    dispatch(setCalendarView('kanban'));
    dispatch(setChangeDefaultActiveKey('1'));
    // LocalStorage.set(KANBAN_VIEW_TASK, 'kanban')
    // LocalStorage.remove(CALENDAR_VIEW_TASK)
    // LocalStorage.remove(LIST_VIEW_TASK)
  };

  const showListView = () => {
    dispatch(setCalendarView('list'));
    dispatch(setChangeDefaultActiveKey('2'));
    // LocalStorage.set(LIST_VIEW_TASK, 'list')
    // LocalStorage.remove(CALENDAR_VIEW_TASK)
    // LocalStorage.remove(KANBAN_VIEW_TASK)
  };

  const showCalendarView = () => {
    dispatch(setCalendarView('calendar'));
    dispatch(setChangeDefaultActiveKey('3'));
    // LocalStorage.set(CALENDAR_VIEW_TASK, 'calendar')
    // LocalStorage.remove(LIST_VIEW_TASK)
    clickCalendarView();
  };

  // create broker events
  const clickCalendarView = () => {
    ReactGA.event({
      category: TYPE_EVENTS_GA.TASK,
      action: ACTION_EVENTS_GA.CLICK_CALENDAR_VIEW_BUTTON
    });
  };

  return (
    <div className={styles.container}>
      <Tabs defaultActiveKey={activeKey}>
        <TabPane
          tab={
            <div onClick={showKanbanView} className={styles.container_item}>
              <div className={styles.container_item_child}>
                <KanbanIcon className={styles.container_kanban} />
                <span>{t('one_task.Ustun')}</span>
              </div>
            </div>
          }
          key={'1'}
        />
        <TabPane
          tab={
            <div onClick={showListView} className={styles.container_item}>
              <div className={styles.container_item_child}>
                <TableIcon className={viewType !== 'list' ? styles.container_table : styles.container_table_active} />
                <span>{t('one_task.Jadval')}</span>
              </div>
            </div>
          }
          key={'2'}
        />
        <TabPane
          tab={
            <div onClick={showCalendarView} className={styles.container_item}>
              <div className={styles.container_item_child}>
                <CalendarTwoIcon />
                <span>{t('one_task.Taqvim')}</span>
              </div>
            </div>
          }
          key={'3'}
        />
      </Tabs>
    </div>
  );
};

export default ChangeViewTypeButtons;
