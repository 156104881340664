import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import styles from './configInputs.module.scss';
import DotsSixVertical from '../../../../../assets/icons/DotsSixVertical';
import PlusIconAddCircle from '../../../../../assets/icons/PlusIconAddCircle';
import EditInputModal from '../editInputModal/EditInputModal';
import { CrmField } from '../../../../../utils/models/CrmField';
import { CrmFieldsInfoType, CrmFieldsType } from '../../../../../utils/models/CrmFieldsType';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { setContactFields, setLedFields } from '../../../../../store/reducers/CrmFieldSlice';
import { useTranslation } from 'react-i18next';
import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';

interface Props {
  fields: CrmField[];
  type: CrmFieldsInfoType;
}

const InputsList: React.FC<Props> = ({ fields, type }) => {
  const [openEditModal, setOpenEditModal] = useState<{
    visible: boolean;
    data?: CrmField;
    index?: number;
  }>({
    visible: false
  });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  //handle drag end
  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    const newFields: CrmField[] = [];
    for (let i = 0; i < fields.length; i++) {
      if (source.index === i) {
        newFields.push(fields[destination?.index as number]);
      } else if (destination?.index === i) {
        newFields.push(fields[source.index]);
      } else {
        newFields.push(fields[i]);
      }
    }
    if (type === CrmFieldsInfoType.LED) {
      dispatch(setLedFields(newFields));
    } else {
      dispatch(setContactFields(newFields));
    }
  };

  //open edit modal handler
  const editModalHandler = (visible: boolean, data?: CrmField, index?: number) => {
    setOpenEditModal({ visible, data, index });
  };

  const getTypeField = (item: CrmField) => {
    if (item.type === CrmFieldsType.TEXT) {
      return '...';
    }
    return item.options?.length + t('one_lid.ta_tanlov');
  };

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={type}>
          {provided => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((item, index) => (
                  <Draggable key={index} draggableId={type + String(index)} index={index}>
                    {provided => {
                      return (
                        <div ref={provided.innerRef} {...provided.draggableProps} className={styles.container_item}>
                          <span {...provided.dragHandleProps}>
                            <DotsSixVertical />
                          </span>
                          <div onClick={() => editModalHandler(true, item, index)} className={styles.container_item_title}>
                            <div className={styles.container_item_title_name}>
                              {item[('name' + textCapitalize(i18n.language)) as keyof typeof item] || item?.name}
                            </div>
                            <div className={styles.container_item_title_value}>{getTypeField(item)}</div>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <div onClick={() => editModalHandler(true)} className={styles.container_add}>
        <PlusIconAddCircle />
        <span>{t('one_lid.Yangi_qoshish')}</span>
      </div>
      <EditInputModal
        index={openEditModal.index}
        data={openEditModal.data}
        visible={openEditModal.visible}
        handleClose={editModalHandler}
        type={type}
      />
    </div>
  );
};

export default InputsList;
