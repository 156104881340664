import React, { useEffect, useState } from 'react';
import styles from './selectCurrency.module.scss';
import { Segmented, Spin } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { SegmentedLabeledOption } from 'antd/es/segmented';
import { useCurrencyList } from '../../../../queries/queries';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { setCurrency } from '../../../../store/reducers/CreateContractSlice';

const SelectCurrency = () => {
  const { data, isLoading } = useCurrencyList();
  const [options, setOptions] = useState<(SegmentedValue | SegmentedLabeledOption)[]>([]);

  const currency = useAppSelector(state => state.createContract.currency);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      const newOptions: (SegmentedValue | SegmentedLabeledOption)[] = [];
      data.forEach(item => {
        newOptions.push({
          label: <span className={styles.container_segment_item}>{item.ccy}</span>,
          value: item.ccy
        });
      });
      setOptions(newOptions);
    }
  }, [data]);

  // handleChangeCurrency
  const handleChangeCurrency = (e: SegmentedValue) => {
    const newCurrency = data?.find(item => item.ccy === e);
    dispatch(setCurrency(newCurrency));
  };

  return (
    <div className={styles.container}>
      {data && data?.length > 1 ? (
        <Spin spinning={isLoading}>
          <Segmented onChange={handleChangeCurrency} value={currency?.ccy || ''} className={styles.container_segment} options={options} />
        </Spin>
      ) : (
        ''
      )}
    </div>
  );
};

export default SelectCurrency;
