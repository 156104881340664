import React from 'react';
import { NavLink } from 'react-router-dom';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { SettingsPaths } from '../../routing/settings/settingsPaths';
import styles from './importExport.module.scss';
import ArrowRightMessage from '../../assets/icons/ArrowRightMessage';
import { ImportExportPaths } from '../../routing/importExport/importExportPaths';
import ImportIcon from '../../assets/icons/ImportIcon';
import ExportIcon from '../../assets/icons/ExportIcon';
import { useTranslation } from 'react-i18next';

const ImportExportNavbar = () => {
  const { t } = useTranslation();

  const data = [
    {
      icon: ImportIcon,
      text: t('import_export.Import'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.BACKUP + ImportExportPaths.IMPORT
    },
    {
      icon: ExportIcon,
      text: t('import_export.Export'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.BACKUP + ImportExportPaths.EXPORT
    }
  ];
  return (
    <div className={styles.importNavbar}>
      {data.map((item, index) => (
        <NavLink key={index} to={item.path} className="navbarLink">
          <item.icon />
          {item.text}
          <ArrowRightMessage className={'import_cheveron'} />
        </NavLink>
      ))}
    </div>
  );
};

export default ImportExportNavbar;
