import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { ClientPhone, JuridicalPersonCreate, NaturalPersonCreate } from '../../utils/models/Client';
import { ClientType } from '../../utils/models/ClientType';

interface ClientState {
  type: ClientType;
  juridicalPerson: JuridicalPersonCreate | null;
  naturalPerson: NaturalPersonCreate | null;
  clientPhones: ClientPhone[];
  searchByPhone: number | null;
  passportOneId?: number;
  passportTwoId?: number;
  leadName?: string;
  leadContact?: string;
  leadContactName?: string;
  countryCode?: string;
  currentPage: number;
  searchPhone: number | null;
}

const initialState: ClientState = {
  type: ClientType.NATURAL,
  juridicalPerson: null,
  naturalPerson: null,
  clientPhones: [
    {
      phone: '',
      active: true,
      code: ''
    }
  ],
  searchByPhone: null,
  leadContact: '',
  leadContactName: '',
  leadName: '',
  countryCode: '',
  currentPage: 1,
  searchPhone: null
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientType: (state, action: PayloadAction<ClientType>) => {
      state.type = action.payload;
      state.clientPhones = [
        {
          phone: '',
          active: true,
          code: ''
        }
      ];
      state.juridicalPerson = null;
      state.naturalPerson = null;
      state.searchByPhone = null;
      state.juridicalPerson = null;
      state.naturalPerson = null;
    },
    setClientBySearchPassportDate: (state, action: PayloadAction<NaturalPersonCreate>) => {
      state.naturalPerson = {
        ...current(state).naturalPerson,
        ...action.payload
      };
    },
    setClientPhone: (
      state,
      action: PayloadAction<{
        index: number;
        phone: string;
      }>
    ) => {
      state.clientPhones = state.clientPhones.map((item, i) =>
        i === action.payload.index
          ? {
              ...item,
              phone: action.payload.phone
            }
          : item
      );
    },
    setClientAndClientTypeAndClientPhone: (
      state,
      action: PayloadAction<{
        client: JuridicalPersonCreate | NaturalPersonCreate;
        type: ClientType;
        phones: ClientPhone[];
      }>
    ) => {
      if (action.payload.type === ClientType.NATURAL) {
        state.naturalPerson = action.payload.client as NaturalPersonCreate;
        // @ts-ignore
        if (action.payload.client.passportImg1Id) {
          // @ts-ignore
          state.passportOneId = action.payload.client.passportImg1Id;
        }
        // @ts-ignore
        if (action.payload.client.passportImg2Id) {
          // @ts-ignore
          state.passportTwoId = action.payload.client.passportImg2Id;
        }
      } else {
        state.juridicalPerson = action.payload.client as JuridicalPersonCreate;
      }
      state.type = action.payload.type;
      state.clientPhones = action.payload.phones;
    },
    setClient: (state, action: PayloadAction<JuridicalPersonCreate | NaturalPersonCreate | null>) => {
      if (action.payload?.type === ClientType.NATURAL) {
        state.naturalPerson = action.payload as NaturalPersonCreate;
      } else {
        state.juridicalPerson = action.payload as JuridicalPersonCreate;
      }
    },
    setClientPhones: (state, action: PayloadAction<ClientPhone[]>) => {
      state.clientPhones = action.payload;
    },
    deleteClientPhones: (state, action: PayloadAction<number>) => {
      const { clientPhones } = current(state);
      const newClientPhones = state.clientPhones.filter((phone, index) => index !== action.payload);

      if (clientPhones[action.payload].active) {
        newClientPhones[0].active = true;
      }
      state.clientPhones = newClientPhones;
    },
    addClientPhone: state => {
      state.clientPhones.push({
        phone: '',
        active: false,
        code: ''
      });
    },
    checkClientPhone: (state, action: PayloadAction<number>) => {
      const phones = state.clientPhones.slice();

      for (let i = 0; i < phones.length; i++) {
        phones[i].active = i === action.payload;
      }
      state.clientPhones = phones;
    },
    clearClientReducer: state => {
      state.type = ClientType.NATURAL;
      state.juridicalPerson = null;
      state.naturalPerson = null;
      state.passportTwoId = undefined;
      state.passportOneId = undefined;
      state.clientPhones = [
        {
          phone: '',
          active: true,
          code: ''
        }
      ];
      state.searchByPhone = null;
    },
    clearSelectedClient: state => {
      state.juridicalPerson = null;
      state.naturalPerson = null;
      state.passportTwoId = undefined;
      state.passportOneId = undefined;
      state.clientPhones = [
        {
          phone: '',
          active: true,
          code: ''
        }
      ];
    },
    setSearchByPhone: (state, action: PayloadAction<number | null>) => {
      state.searchByPhone = action.payload;
    },
    setSearchPhone: (state, action: PayloadAction<number | null>) => {
      state.searchPhone = action.payload;
    },
    setNaturalPersonImage: (
      state,
      action: PayloadAction<{
        id: number | undefined;
        type: 'first' | 'second';
      }>
    ) => {
      if (action.payload.type === 'first') {
        state.passportOneId = action.payload.id;
      } else {
        state.passportTwoId = action.payload.id;
      }
    },
    setChangeNewLeadData: (state, action: { payload: { name?: string; contact?: string; contactName?: string } }) => {
      const obj = {
        name: state.leadName,
        contact: state.leadContact,
        contactName: state.leadContactName,
        ...action.payload
      };

      state.leadName = obj.name;
      state.leadContact = obj.contact;
      state.leadContactName = obj.contactName;
    },
    changeCountryCode: (state, action: { payload: string }) => {
      state.countryCode = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    }
  }
});

export const {
  setClientType,
  setClient,
  setClientAndClientTypeAndClientPhone,
  setClientPhones,
  deleteClientPhones,
  addClientPhone,
  checkClientPhone,
  setSearchByPhone,
  clearClientReducer,
  clearSelectedClient,
  setNaturalPersonImage,
  setClientBySearchPassportDate,
  setClientPhone,
  setChangeNewLeadData,
  changeCountryCode,
  setCurrentPage,
  setSearchPhone
} = clientSlice.actions;
export default clientSlice.reducer;
