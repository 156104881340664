import { useEffect, useState } from 'react';
import TjmFilter from '@components/tjm-filter/TjmFilter';
import ArrowSquireDown from '@icons/ArrowSquareDown';
import { ResponsiveBar } from '@nivo/bar';
import { Segmented, Spin } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useMobileDebtGraphic } from '../../queries/queries';
import { DATE_FORMAT } from '../../utils/constants/format';
import { priceSeparator } from '../../utils/helper/priceSeparator';
import { BossPaymentsType } from '../../utils/models/BossPayments';

import SelectCurrency from './selectCurrency/SelectCurrency';

import styles from './bossContent.module.scss';

const BossLineChart = () => {
  const { appendMultipleDifferent, searchParamsString, searchParams, push } = useQueryParam<any, any>();

  const currency = useAppSelector(state => state.bossCurrency.currency);
  const [segmentValue, setSegmentValue] = useState<any>();
  const startDate = `01.${  dayjs(new Date()).format('MM.YYYY')}`;
  const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

  const convertSearchParam = () => queryString.stringify({
      ...searchParams,
      currencyId: searchParams?.currencyId || null,
      start: searchParams.start || startDate,
      finish: searchParams.finish || finishDate,
      type: searchParams.type || BossPaymentsType.DAY
    });

  const { data: graphicData, isLoading, isFetching } = useMobileDebtGraphic(convertSearchParam());

  const { t } = useTranslation();

  // handle change type
  const changeType = (type: SegmentedValue) => {
    setSegmentValue(type);
    switch (type) {
      case BossPaymentsType.MONTH:
        appendMonth();
        break;
      case BossPaymentsType.YEAR:
        appendYear();
        break;
      default:
        appendDay();
        break;
    }
  };

  // append day
  const appendDay = () => {
    const startDate = `01.${  dayjs(new Date()).format('MM.YYYY')}`;
    const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.DAY, startDate, finishDate]);
  };

  // append month
  const appendMonth = () => {
    const dateString = dayjs(new Date()).format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.MONTH, dateString, dateString]);
  };

  // append year
  const appendYear = () => {
    const start = dayjs(new Date()).add(-4, 'year').format(DATE_FORMAT);
    const finish = dayjs(new Date()).format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.YEAR, start, finish]);
  };

  // maping api of the line chart
  const getData = () => {
    const arr: { x: string; y: number }[] = [];

    graphicData?.forEach(item => {
      arr.push({
        x: item?.date,
        y: item?.debt
      });
    });
    return arr;
  };

  useEffect(() => {
    if (!searchParamsString?.includes('currencyId') && currency?.id) {
      push({ search: `${searchParamsString  }&currencyId=${currency?.id}` });
    }
  }, [currency, searchParamsString, push]);

  const handleArrowLeft = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        addMonth(-1);
        break;
      case BossPaymentsType.YEAR:
        addYear(-5);
        break;
      default:
        addDate(-1);
        break;
    }
  };
  const addDate = (addDate: number) => {
    let startDate = dayjs(`01.${  dayjs(new Date()).format('MM.YYYY')}`, DATE_FORMAT);

    if (searchParams.start) {
      startDate = dayjs(searchParams.start, DATE_FORMAT);
    }

    const start = startDate.add(addDate, 'month').format(DATE_FORMAT);
    const finish = dayjs(startDate, DATE_FORMAT)
      .add(addDate + 1, 'month')
      .add(-1, 'day')
      .format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.DAY, start, finish]);
  };

  // minus month
  const addMonth = (addMonth: number) => {
    const dateString = dayjs(searchParams.start, DATE_FORMAT).add(addMonth, 'year').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.MONTH, dateString, dateString]);
  };

  // minus year
  const addYear = (addYear: number) => {
    const start = dayjs(searchParams.start, DATE_FORMAT).add(addYear, 'year').format(DATE_FORMAT);
    const finish = dayjs(searchParams.finish, DATE_FORMAT).add(addYear, 'year').format(DATE_FORMAT);

    appendMultipleDifferent(['type', 'start', 'finish'], [BossPaymentsType.YEAR, start, finish]);
  };
  // handle click arrow right
  const handleArrowRight = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        addMonth(1);
        break;
      case BossPaymentsType.YEAR:
        addYear(5);
        break;
      default:
        addDate(1);
        break;
    }
  };

  // header value
  const headerValue = () => {
    switch (searchParams.type) {
      case BossPaymentsType.MONTH:
        return headerValueMonth();
      case BossPaymentsType.YEAR:
        return headerValueYear();
      default:
        return headerValueDay();
    }
  };

  // header value month
  const headerValueMonth = () => dayjs(searchParams.start, DATE_FORMAT).format('YYYY');

  // header value year
  const headerValueYear = () => {
    const start = dayjs(searchParams.start, DATE_FORMAT);
    const finish = dayjs(searchParams.finish, DATE_FORMAT);

    return `${start.format('YYYY')  } - ${  finish.format('YYYY')}`;
  };

  // header value day
  const headerValueDay = () => {
    let date = dayjs(new Date());

    if (searchParams.start) {
      date = dayjs(searchParams.start, DATE_FORMAT);
    }
    return `${t(`boss_payments.${date.format('MMMM')}`)  }, ${  date.format('YYYY')}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <SelectCurrency />
        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <Segmented
            defaultValue={BossPaymentsType.DAY}
            value={searchParams.type}
            onChange={changeType}
            className={styles.line_chart_segment}
            size="large"
            options={[
              {
                label: <div className={styles.line_chart_segment_item}>{t('boss_payments.Kunlik')}</div>,
                value: BossPaymentsType.DAY
              },
              {
                label: <div className={styles.line_chart_segment_item}>{t('boss_payments.Oylik')}</div>,
                value: BossPaymentsType.MONTH
              },
              {
                label: <div className={styles.line_chart_segment_item}>{t('boss_payments.Yillik')}</div>,
                value: BossPaymentsType.YEAR
              }
            ]}
          />
          <TjmFilter />
        </div>
      </div>

      <div className={styles.line_chart}>
        <div className={styles.line_chart_arrows_container}>
          <ArrowSquireDown className={styles.arrow_left} onClick={handleArrowLeft} />
          <div className={styles.line_chart_middle_body}>
            <h2>{headerValue()}</h2>
          </div>
          <ArrowSquireDown className={styles.arrow_right} onClick={handleArrowRight} />
        </div>
        <Spin spinning={isLoading || isFetching}>
          {currency ? (
            <>
              {/* @ts-ignore */}
              <ResponsiveBar
                data={getData()}
                keys={['y']}
                indexBy="x"
                margin={{ top: 20, right: 130, bottom: 160, left: 40 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors="#917EF7"
                axisLeft={{
                  format: value => priceSeparator(value)
                }}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]]
                }}
                enableLabel={false}
                tooltip={e => {
                  const data: any = e?.data;

                  return (
                    <div className={styles.line_chart_tooltip}>
                      <span>{data?.x}</span>
                      {data.y?.toLocaleString('rue')} {currency?.ccy}
                    </div>
                  );
                }}
                axisBottom={{
                  format: value => {
                    switch (searchParams.type) {
                      case BossPaymentsType.MONTH:
                        return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
                      case BossPaymentsType.YEAR:
                        return dayjs(value, DATE_FORMAT).format('YYYY');
                      default:
                        return dayjs(value, DATE_FORMAT).format('DD');
                    }
                  }
                }}
                axisTop={null}
                axisRight={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]]
                }}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                  return `${e.id  }: ${  e.formattedValue  } in country: ${  e.indexValue}`;
                }}
              />
            </>
          ) : null}
        </Spin>
      </div>
    </div>
  );
};

export default BossLineChart;
