import React from 'react';
import MainHeader from '../../components/headers/MainHeader';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { securitySettingsRoutes } from './securitySettingsRoutes';
import SecurityMenu from '../../pages/security/security-menu/SecurityMenu';
const { Content } = Layout;

const SecuritySettingsRouter = () => {
  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={'d-flex align-start'}>
          <SecurityMenu />
          <Routes>
            {securitySettingsRoutes.map((route, index) => (
              <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
            ))}
          </Routes>
        </div>
      </Content>
    </>
  );
};

export default SecuritySettingsRouter;
