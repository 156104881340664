import React, { useState } from 'react';
import { Button } from 'antd';
import styles from '../../pages/createTablePayments/createTablePayments.module.scss';
import { useTranslation } from 'react-i18next';
import ClearTableModal from './ClearTableModal';
import AddItemTable from './AddItemTable';

const Buttons = () => {
  const [openClearModal, setOpenClearModal] = useState(false);
  const [openAddItemTableModal, setOpenAddItemTableModal] = useState(false);
  const { t } = useTranslation();

  const handleClearModal = (open: boolean) => {
    setOpenClearModal(open);
  };

  const handleAddItemTableModal = (open: boolean) => {
    setOpenAddItemTableModal(open);
  };

  return (
    <>
      <div className={styles.buttons}>
        <Button onClick={() => handleAddItemTableModal(true)} className={styles.buttons_item} type="primary" ghost>
          {t(`changes.monthlyPayments.To’lov_grafigi_qo’shish`)}
        </Button>
        <Button onClick={() => handleClearModal(true)} className={styles.buttons_item} type="primary" ghost>
          {t(`changes.monthlyPayments.Jadvalni_tozalash`)}
        </Button>
      </div>
      <ClearTableModal visible={openClearModal} onCancel={handleClearModal} />
      <AddItemTable visible={openAddItemTableModal} onClose={handleAddItemTableModal} />
    </>
  );
};

export default Buttons;
