import React, { FC } from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const ArrowLineDownIcon: FC<IProps> = ({ color, size = 20 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2.5V14.375" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.375 8.75L10 14.375L15.625 8.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.125 16.875H16.875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowLineDownIcon;
