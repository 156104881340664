import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';

export const useContractVariationGroupDelete = (props?: { onSuccess?: () => void }) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id?: number | null) => {
      const res = await $api.delete(`/v1/contract/variation-group/${id}`);

      return res?.data?.data;
    },
    onSuccess: async () => {
      await qc.invalidateQueries([queryKeys.CONTRACT_VARIATION_GROUP]);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onSuccess && props?.onSuccess();
    }
  });
};
