import React, { useEffect } from 'react';
import { BASE_URL } from '@service/RequestService';
import { Avatar, Form, Select, Switch } from 'antd';
import { t } from 'i18next';

import TrashIcon from '../assets/TrashIcon';
import { useDeleteTGChatBotConfigUser, usePatchTGChatBotConfigUser } from '../service/mutation';
import { useGetEmployees } from '../service/queries';
import { TGPremiumType } from '../utils/models/TGPremiumType';

type Props = {
  item: TGPremiumType;
};

const TGUserItem: React.FC<Props> = ({ item }) => {
  const [form] = Form.useForm();

  const { data: dataEmployees } = useGetEmployees();

  const patchTGChatBotConfigUser = usePatchTGChatBotConfigUser();
  const deleteTGChatBotConfigUser = useDeleteTGChatBotConfigUser();

  const handleDeleteTGChatBotConfigUser = (id: number) => {
    deleteTGChatBotConfigUser?.mutateAsync(id);
  };

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        ...item,
        responsibleId: item?.responsibleEmployeeId
      });
    }
  }, [item]);

  return (
    <Form
      form={form}
      onFieldsChange={(_, allFields) => {
        const data = allFields?.reduce(
          (p, c) => {
            if (c?.name) {
              // @ts-expect-error
              p[c?.name[0]] = c?.value;
            }
            return p;
          },
          { id: item?.id } as Record<string, any>
        );

        patchTGChatBotConfigUser.mutate(data);
      }}
    >
      <div className="mb-[16px] rounded-[12px] border-[1px] border-solid border-[#EAECF0] p-[16px]">
        <div className="mt-[-10px] flex items-center gap-[5px]">
          <Avatar size={25} src={`${BASE_URL}/${item?.avatar}`} />
          <h3 className="mt-[10px] text-[16px] font-[400] text-[#101828]">
            {item?.fullName} <span className="text-[#98A2B3]">({item?.chatId})</span>
          </h3>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex h-[55px] items-center justify-center rounded-[12px] bg-[#F2F4F7] px-[8px] pt-[1px]">
            <Form.Item className="mt-5" name="responsibleId" label={<p className="text-[14px] font-[500] text-[#667085]">{t('integration.Xodim')}</p>}>
              <Select style={{ width: 380 }} className="customSelect" placeholder={t('tg.Tanlang')}>
                {dataEmployees?.map((emp: any) => (
                  <Select.Option key={emp?.id} value={emp?.id}>
                    {emp?.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="mb-[-15px]">
            <p className="text-[14px] font-[500] text-[#667085]">{t('integration.Holati')}</p>
            <Form.Item name="status" valuePropName="checked" initialValue={false}>
              <Switch />
            </Form.Item>
          </div>

          <div className="cursor-pointer" onClick={() => handleDeleteTGChatBotConfigUser(item?.id as number)}>
            <TrashIcon />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default TGUserItem;
