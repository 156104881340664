import React from 'react';
import styles from './interfaceConfig.module.scss';
import UysotLogo from '../../assets/icons/UysotLogo';
import { colors } from '../../utils/constants/showRoomColors';
import { useShowroomColor } from '../../queries/queries';
import { Spin } from 'antd';
import CheckIcon from '../../assets/icons/CheckIcon';
import { useSaveShowroomColor } from '../../queries/mutation';
import { useTranslation } from 'react-i18next';

const ShowRoomColor = () => {
  const { isLoading, data, isFetching } = useShowroomColor();
  const saveColor = useSaveShowroomColor();
  const { t } = useTranslation();

  const handleSaveColor = (color: string) => {
    saveColor.mutate(color);
  };

  return (
    <>
      <Spin spinning={isLoading || isFetching || saveColor.isLoading}>
        <h2 className={styles.interfaceConfig_title}>{t('interface_config.Showroom_rangini_tanlang')}</h2>
        <div className={styles.interfaceConfig_colors}>
          <div onClick={() => handleSaveColor('#00A389')} className={styles.interfaceConfig_colors_first}>
            <UysotLogo />
          </div>
          {colors.map((color, index) => {
            if (data === color)
              return (
                <div key={index} className={styles.interfaceConfig_colors_item_active} style={{ backgroundColor: color }}>
                  <CheckIcon />
                </div>
              );
            return (
              <div
                onClick={() => handleSaveColor(color)}
                key={index}
                className={styles.interfaceConfig_colors_item}
                style={{ backgroundColor: color }}
              />
            );
          })}
        </div>
      </Spin>
    </>
  );
};

export default ShowRoomColor;
