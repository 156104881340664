import { HEAD_PHONE } from '../constants/headPhone';

export const helper: {
  deleteNotNumbers: (n: string, returnValueErrorMoment: number | null) => number | null;
  deleteSpace: (n: string) => string;
  clientPhoneToUser: (n: string) => string;
  clientPhoneToUser1: (n: string, phoneCode: string) => string;
  clientPhoneToServer: (n: string) => string;
  clientPhoneToServer1: (code: string, n: string) => string;
  parseFloatStringToNumber: (n: string) => number;
  deleteNotNumbersAndParseFloatNumber: (n: string) => {
    number: number;
    string: string;
  };
  deleteNotNumbersAndParseFloatSmallNumber: (n: string) => {
    number: number;
    string: string;
  };
  localeStringSmallNumbers: (n: number) => string;
  deleteNotNumbersToNumber: (n: string) => {
    number: number;
    string: string;
  };
  NumberInt: (n: string) => {
    number: number;
    string: string;
  };
} = {
  deleteNotNumbers: (n: string, returnValueErrorMoment: number | null) => {
    if (!parseInt(n.replace(/\D/g, ''), 10)) return returnValueErrorMoment;
    return Number(n.replace(/\D/g, ''));
  },
  deleteSpace: (n: string) => n.replace(/\s/g, ''),
  parseFloatStringToNumber: (n: string) => {
    n = n.replace(/\s/g, '').replace(/,/g, '.');
    return Number(n);
  },
  clientPhoneToUser: (n: string) => n.slice(4, n.length),
  clientPhoneToUser1: (n: string, phoneCode: string) => n.replace(phoneCode, ''),
  clientPhoneToServer: (n: string) => HEAD_PHONE + n,
  clientPhoneToServer1: (code: string, n: string) => code + n,
  deleteNotNumbersAndParseFloatNumber: (str: string) => {
    let newValue = str.replace(/[^\d.,]/g, '').replaceAll(/,/g, '.');

    const arr = newValue.split('.');

    if (arr.length === 1) {
      newValue = newValue ? (+newValue).toLocaleString('ru') : '';
    } else if (arr.length === 2) {
      newValue = newValue ? `${(+arr[0]).toLocaleString('ru')},${arr[1]?.slice(0, 2)}` : '';
    } else {
      newValue = newValue ? `${(+arr[0]).toLocaleString('ru')},${arr[1]}` : '';
    }

    return {
      number: Number(newValue.replaceAll(/\s/g, '').replaceAll(/,/g, '.')),
      string: newValue
    };
  },
  deleteNotNumbersAndParseFloatSmallNumber: (str: string) => {
    let newValue = str.replace(/[^\d.,]/g, '').replaceAll(/,/g, '.');

    const arr = newValue.split('.');

    if (arr.length === 1) {
      newValue = newValue ? (+newValue).toLocaleString('ru') : '';
    } else if (arr.length === 2) {
      newValue = newValue ? `${(+arr[0]).toLocaleString('ru')},${arr[1]?.slice(0, 7)}` : '';
    } else {
      newValue = newValue ? `${(+arr[0]).toLocaleString('ru')},${arr[1]}` : '';
    }

    return {
      number: Number(newValue.replaceAll(/\s/g, '').replaceAll(/,/g, '.')),
      string: newValue
    };
  },
  localeStringSmallNumbers: (n: number) => {
    const arr = String(n).split('.');
    let str = Number(arr[0] || '0').toLocaleString('ru');

    if (arr[1]) {
      str += `,${arr[1]}`;
    }
    return str;
  },
  deleteNotNumbersToNumber: (str: string) => {
    let newValue = str.replace(/[^\d.,]/g, '').replaceAll(/,/g, '.');

    const arr = newValue.split('.');

    if (arr.length === 1) {
      newValue = newValue || '';
    } else if (arr.length === 2) {
      newValue = newValue ? `${+arr[0]},${arr[1]?.slice(0, 2)}` : '';
    } else {
      newValue = newValue ? `${+arr[0]},${arr[1]}` : '';
    }

    return {
      number: Number(newValue.replaceAll(/\s/g, '').replaceAll(/,/g, '.')),
      string: newValue
    };
  },
  NumberInt: (str: string) => {
    let newValue = str.replace(/[^\d.,]/g, '').replaceAll(/,/g, '.');

    const arr = newValue.split('.');

    if (arr.length === 1) {
      newValue = newValue || '';
    } else if (arr.length === 2) {
      newValue = newValue ? `${+arr[0]},${arr[1]?.slice(0, 2)}` : '';
    } else {
      newValue = newValue ? `${+arr[0]},${arr[1]}` : '';
    }

    return {
      number: Number(newValue),
      string: newValue
    };
  }
};
