import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDiscount } from '../../queries/queries';
import { urlEncode } from '../../utils/helper/urlEncodeDecode';

import DiscountSettingCreateModal from './DiscountSettingCreateModal';

import '../../pages/discountSetting/discountSetting.module.scss';

const DiscountSettingNavbar = () => {
  const [visibleCreate, setVisibleCreate] = useState(false);
  const { data, isLoading } = useDiscount();
  const { t } = useTranslation();

  return (
    <div className="settingDiscountNavbar">
      {isLoading && <Spin />}
      {data?.map(item => (
        <NavLink key={item.id} to={String(`${item.id}/${urlEncode(item.name)}`)}>
          {item.name}
        </NavLink>
      ))}
      <Button type="primary" onClick={() => setVisibleCreate(true)}>
        + {t('discount_setting.Chegirma_yaratish')}
      </Button>
      <DiscountSettingCreateModal visible={visibleCreate} setVisible={setVisibleCreate} />
    </div>
  );
};

export default DiscountSettingNavbar;
