export const leadInitialColumn = [
  { id: '1', column: 'name' },
  { id: '2', column: 'contactName' },
  { id: '3', column: 'createdDate' },
  { id: '4', column: 'fullName' },
  { id: '5', column: 'pipeStatusName' }
];

export const taskInitialColumn = [
  { id: '1', column: 'clientName' },
  { id: '2', column: 'date' },
  { id: '3', column: 'leadTaskType' },
  { id: '4', column: 'text' },
  { id: '5', column: 'state' },
  { id: '6', column: 'assignedBy' }
];

export const eventListInitialColumn = [
  { id: '1', column: 'name' },
  { id: '2', column: 'createdDate' },
  { id: '3', column: 'actionType' },
  { id: '4', column: 'previous' },
  { id: '5', column: 'current' },
  { id: '6', column: 'responsibleBy' }
];
