import React, { memo } from 'react';
import styles from './filterDrawer.module.scss';
import { Dropdown } from 'antd';
import { useQueryParam } from '@hooks/useQueryParams';
import { LeadTaskStatusFilter } from '@utils/models/FilterTask';
import CheveronBottom from '@icons/CheveronBottom';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  status: LeadTaskStatusFilter | undefined;
}

const StatusFilter: React.FC<Props> = ({ status }) => {
  const { appendSingle } = useQueryParam();

  const { t } = useTranslation();

  // changePipeStatus
  const changeReasons = (value: LeadTaskStatusFilter | undefined) => {
    appendSingle('leadTaskStatusFilter', value);
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_dropdown}
      overlay={
        <>
          <div onClick={() => changeReasons(undefined)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskStatusFilter.ACTIVE}`)}</span>
            {!status && <CheckmarkIcon />}
          </div>
          <div onClick={() => changeReasons(LeadTaskStatusFilter.ALL)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskStatusFilter.ALL}`)}</span>
            {status === LeadTaskStatusFilter.ALL && <CheckmarkIcon />}
          </div>
          <div onClick={() => changeReasons(LeadTaskStatusFilter.MISSED)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskStatusFilter.MISSED}`)}</span>
            {status === LeadTaskStatusFilter.MISSED && <CheckmarkIcon />}
          </div>
          <div onClick={() => changeReasons(LeadTaskStatusFilter.CLOSED)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskStatusFilter.CLOSED}`)}</span>
            {status === LeadTaskStatusFilter.CLOSED && <CheckmarkIcon />}
          </div>
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_employee}>
        <span className={styles.container_employee_placeholder}>{t('one_task.Topshiriq_holati')}</span>
        <CheveronBottom className={styles.container_employee_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(StatusFilter);
