import React, { FC } from 'react';

interface IProps {
  size?: number;
}

const ClipBoardTickIcon: FC<IProps> = ({ size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.20703 9.79997L7.20703 10.8L9.8737 8.1333" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.66732 4.00004H9.33398C10.6673 4.00004 10.6673 3.33337 10.6673 2.66671C10.6673 1.33337 10.0007 1.33337 9.33398 1.33337H6.66732C6.00065 1.33337 5.33398 1.33337 5.33398 2.66671C5.33398 4.00004 6.00065 4.00004 6.66732 4.00004Z"
        stroke="#464255"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 2.68005C12.8867 2.80005 14 3.62005 14 6.66672V10.6667C14 13.3334 13.3333 14.6667 10 14.6667H6C2.66667 14.6667 2 13.3334 2 10.6667V6.66672C2 3.62672 3.11333 2.80005 5.33333 2.68005"
        stroke="#464255"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClipBoardTickIcon;
