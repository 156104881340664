import React from 'react';

type Props = {
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
};

const ChangesUndoIcon: React.FC<Props> = ({ onClick, className }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
    <path
      d="M9.69992 14.9493C9.47992 14.9493 9.27325 14.8026 9.21325 14.5759C9.14659 14.3093 9.29992 14.0359 9.56659 13.9626C12.2733 13.2493 14.1599 10.7959 14.1599 7.99594C14.1599 4.59594 11.3933 1.82927 7.99325 1.82927C5.10659 1.82927 3.21325 3.51594 2.32659 4.53594H4.28659C4.55992 4.53594 4.78659 4.7626 4.78659 5.03594C4.78659 5.30927 4.57325 5.5426 4.29325 5.5426H1.33992C1.29325 5.5426 1.24659 5.53594 1.19992 5.5226C1.13992 5.5026 1.08659 5.47594 1.03992 5.4426C0.979919 5.4026 0.933252 5.34927 0.899919 5.28927C0.866585 5.2226 0.839919 5.15594 0.833252 5.0826C0.833252 5.0626 0.833252 5.04927 0.833252 5.02927V2.0026C0.833252 1.72927 1.05992 1.5026 1.33325 1.5026C1.60659 1.5026 1.83325 1.72927 1.83325 2.0026V3.59594C2.91992 2.42927 4.96659 0.835938 7.99992 0.835938C11.9533 0.835938 15.1666 4.04927 15.1666 8.0026C15.1666 11.2559 12.9733 14.1093 9.82659 14.9359C9.78659 14.9426 9.73992 14.9493 9.69992 14.9493Z"
      fill="currentColor"
    />
    <path
      d="M7.52658 15.1563C7.51325 15.1563 7.49325 15.1563 7.47992 15.1496C6.76658 15.1029 6.06659 14.9429 5.39992 14.6829C5.20659 14.6096 5.07992 14.4163 5.07992 14.2096C5.07992 14.1496 5.09325 14.0896 5.11325 14.0363C5.21325 13.7829 5.50659 13.6496 5.75992 13.7496C6.33325 13.9763 6.93992 14.1096 7.55325 14.1563H7.55992C7.82658 14.1696 8.02658 14.3963 8.02658 14.6629C8.02658 14.6696 8.02658 14.6829 8.02658 14.6896C8.00658 14.9496 7.78658 15.1563 7.52658 15.1563ZM3.85325 13.7229C3.73992 13.7229 3.63325 13.6896 3.53992 13.6163C2.97992 13.1696 2.49325 12.6429 2.08659 12.0496C2.02659 11.9629 1.99325 11.8696 1.99325 11.7696C1.99325 11.6029 2.07325 11.4496 2.21325 11.3563C2.43325 11.2029 2.75992 11.2629 2.90659 11.4829C3.25992 11.9963 3.67992 12.4496 4.16658 12.8296C4.27992 12.9229 4.35325 13.0629 4.35325 13.2163C4.35325 13.3296 4.31992 13.4429 4.24658 13.5363C4.15325 13.6563 4.00659 13.7229 3.85325 13.7229ZM1.62659 10.4696C1.40659 10.4696 1.21325 10.3296 1.15325 10.1229C0.939919 9.43626 0.833252 8.72293 0.833252 8.00293C0.833252 7.7296 1.05992 7.50293 1.33325 7.50293C1.60659 7.50293 1.83325 7.7296 1.83325 8.00293C1.83325 8.62293 1.92659 9.23626 2.10659 9.82293C2.11992 9.8696 2.12659 9.92293 2.12659 9.97626C2.12659 10.1963 1.98659 10.3829 1.77992 10.4496C1.72659 10.4629 1.67992 10.4696 1.62659 10.4696Z"
      fill="currentColor"
    />
    <path
      d="M7.99992 10.6693C9.47268 10.6693 10.6666 9.47536 10.6666 8.0026C10.6666 6.52984 9.47268 5.33594 7.99992 5.33594C6.52716 5.33594 5.33325 6.52984 5.33325 8.0026C5.33325 9.47536 6.52716 10.6693 7.99992 10.6693Z"
      fill="currentColor"
    />
  </svg>
);

export default ChangesUndoIcon;
