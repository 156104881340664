import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { Pagination } from 'antd';
import { Checkbox, Input } from 'global';
import { useTranslation } from 'react-i18next';

import ArrowCircleLeft from '../../assets/icons/ArrowCircleLeft';
import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';

import styles from './pagination.module.scss';

interface PaginationI {
  defaultPageSize: number;
  currentPage: number;
  total: number;
  totalPages: number;
  changePage: (page: number) => void;
  allIds?: number[];
  type?: string;
}

const PaginationCont: React.FC<PaginationI> = ({ currentPage, changePage, defaultPageSize, total, totalPages, allIds, type }) => {
  const [quickChange, setQuickChange] = useState<number | null>();
  const [totalElementState, setTotalElementState] = useState(1);

  useEffect(() => {
    if (total !== -1) {
      setTotalElementState(total);
    }
  }, [total]);

  const { t } = useTranslation();

  const handleChangeInput = (value: number) => {
    setQuickChange(value);
  };

  const handleChangePage = (page: number) => {
    setQuickChange(null);
    changePage(page);
  };

  const handleClickInputIcon = (e: any) => {
    e.stopPropagation();
    if (quickChange && quickChange <= totalPages) {
      handleChangePage(quickChange);
    }
  };
  const showItems = () => {
    if (totalElementState === 0) return '0-0';
    if (currentPage !== totalPages) return `${(currentPage - 1) * defaultPageSize + 1}-${currentPage * defaultPageSize}`;
    return `${(currentPage - 1) * defaultPageSize + 1}-${totalElementState}`;
  };

  const selectAll = document.getElementById('selectAll');
  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);

  return (
    <div className={`${styles.container} d-flex align-center justify-space-between`}>
      <div className={styles.views_count}>
        {total !== 0 && type && (
          <Checkbox onClick={() => selectAll?.click()} checked={activeIds?.length === total!} indeterminate={activeIds?.length !== allIds?.length && activeIds?.length > 0} />
        )}
        {t('pagination.Korinishlar')} {showItems()} {t('pagination.ta_jami')} {total} {t('pagination.ta')}
      </div>
      <div className={styles.pagination_cont}>
        <Pagination
          prevIcon={
            <div className={styles.next_prev_buttons}>
              <PaginationPrev />
            </div>
          }
          nextIcon={
            <div className={styles.next_prev_buttons}>
              <PaginationNext />
            </div>
          }
          pageSize={defaultPageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
          current={currentPage}
          total={totalElementState || 1}
        />
      </div>
      <div className="d-flex align-center">
        <div className={styles.label}>{t('pagination.Ixtiyoriy_sahifaga_otish')}</div>
        <Input
          onPressEnter={handleClickInputIcon}
          value={quickChange as any}
          onChange={handleChangeInput}
          returnType="number"
          suffix={<ArrowCircleLeft onClick={handleClickInputIcon} className={styles.input_icon} />}
          placeholder="..."
          className={`${styles.input}`}
        />
      </div>
    </div>
  );
};

export default PaginationCont;
