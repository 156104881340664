import { t } from 'i18next';
import DotIcon from '../../../../assets/icons/contract/DotIcon';
import styles from '../../orderIdV2.module.scss';

export const orderTypes = {
  ACTIVE: (
    <span className={styles.span} style={{ background: 'var(--persian-green)' }}>
      <DotIcon color="#fff" /> <span style={{ color: '#fff' }}>{t('home.ACTIVE')}</span>
    </span>
  ),
  CANCELED: (
    <span className={styles.span} style={{ background: 'var(--success-50, #6C6C6C)' }}>
      <DotIcon color="#fff" />
      <span style={{ color: '#fff' }}>{t('home.CANCELED')}</span>
    </span>
  ),
  CONTRACT: (
    <span className={styles.span} style={{ background: 'var(--fire-engine-red)' }}>
      <DotIcon color="#fff" />
      <span style={{ color: '#fff' }}>{t('home.CONTRACT')}</span>
    </span>
  )
};
