import React, { FC, useState, useEffect } from 'react';
import styles from './monetaryTableInformation.module.scss';
import { Button, Input, Modal, Radio } from 'antd';
import { useCurrencyList, useMonetaryPaymentId } from '@queries/queries';
import { useMonetaryPayment } from '@queries/mutation';
import MonetaryPaymentRollback from '../MonetaryPaymentRollback';
import { useTranslation } from 'react-i18next';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import MonetaryTableAddClient from '../monetary-table-add-client/MonetaryTableAddClient';
import RefreshIcon from '@icons/RefreshIcon';
import { CurrencyItem } from '@utils/models/Currency';
import CustomeInput from '@components/custome-input/CustomeInput';

interface IProps {
  setVisible: (visible: number | null) => void;
  id: number | null;
}

const MonetaryTableInformation: FC<IProps> = ({ setVisible, id }) => {
  const [currency, setCurrency] = useState<CurrencyItem[] | undefined>();
  const [valyuta, setValyuta] = useState<{
    secondCurrency: CurrencyItem;
    basic: CurrencyItem;
    value: number;
  }>();
  const { data: currencyList } = useCurrencyList();
  const { t } = useTranslation();
  const [openRollback, setOpenRollback] = useState<number | null>(0);
  const [openAddClient, setOpenAddClient] = useState<number | null>(null);
  const [note, setNote] = useState('');
  const monetaryPayment = useMonetaryPayment();
  const { data } = useMonetaryPaymentId(id || 0);

  useEffect(() => {
    if (data?.note) {
      setNote(data?.note);
    } else {
      setNote('');
    }
  }, [data, id]);

  useEffect(() => {
    setCurrency(
      currencyList?.map(item => ({
        ...item,
        basic: data?.currencyId === item?.id
      }))
    );
    if (currencyList && data) {
      const basic = currencyList?.find(item => item?.ccy === 'UZS')!;
      const secondCurrency = currencyList?.find(item => item?.ccy !== 'UZS')!;
      setValyuta({
        secondCurrency: {
          ...secondCurrency,
          value: data?.exchange || secondCurrency?.value
        },
        basic: { ...basic },
        value: data?.currencyAmount
      });
    }
  }, [currencyList, data]);

  const handleClose = () => {
    setVisible(null);
  };

  const handleAccept = () => {
    if (id) {
      monetaryPayment
        .mutateAsync({
          paymentId: id,
          note: note,
          currencyId: currencyBasic?.id,
          currencyAmount: valyuta?.value,
          exchange: valyuta?.secondCurrency?.value
        })
        .then(() => {
          handleClose();
        });
    }
  };

  const handleRollback = () => {
    setOpenRollback(id);
  };

  const currencyBasic = currency?.find(item => item?.basic);

  const valyutaChange = (value: number) => {
    setValyuta(prev => ({
      ...prev!,
      secondCurrency: {
        ...prev!?.secondCurrency,
        value
      },
      value: data?.amount! / value
    }));
  };

  const amountChange = (value: number) => {
    setValyuta(prev => ({
      ...prev!,
      secondCurrency: {
        ...prev!?.secondCurrency,
        value: data?.amount! / value
      },
      value
    }));
  };

  return (
    <>
      <Modal visible={!!id} onCancel={handleClose} title={t('home_id.Umumiy_malumotlar')} wrapClassName={styles.modal} footer={false} width={752}>
        <div className={styles.information}>
          <div>
            <p>{t('monetary.Bank_tolov_raqami')}</p>
            <h3>{data?.number}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovni_qabul_qiluvchi_STIR')}</p>
            <h3>{data?.toTin}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovchi_STIR')}</p>
            <h3>{data?.fromTin}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovni_qabul_qiluvchi_MFO')}</p>
            <h3>{data?.toBankMfo}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovchi_bank_MFO')}</p>
            <h3>{data?.fromBankMfo}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovni_qabul_qiluvchi_bank_hisob_raqami')}</p>
            <h3>{data?.toAccount}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovchi_bank_hisob_raqami')}</p>
            <h3>{data?.fromAccount}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovni_qabul_qiluvchi_nomi')}</p>
            <h3>{data?.toName}</h3>
          </div>
          <div>
            <p>{t('monetary.Tolovchi_nomi')}</p>
            <h3>{data?.fromName}</h3>
          </div>
          {currencyList && currencyList?.length > 0 && (
            <div className={styles.valyuta}>
              <p>{t('monetary.Tolov_qabul_qilish_valyutasi')}</p>
              <Radio.Group value={currencyBasic?.id}>
                {currency?.map(item => (
                  <Radio
                    value={item?.id}
                    onChange={value => {
                      setCurrency(prev => [
                        ...currency?.map(c => ({
                          ...c,
                          basic: item?.id === c?.id
                        }))
                      ]);
                    }}
                  >
                    {item?.ccy}
                  </Radio>
                ))}
              </Radio.Group>
              <h4>
                {t('monetary.Tolov_miqdori')} <span>{data?.amount?.toLocaleString('ru')} UZS</span>
              </h4>
              <h4>
                1 {valyuta?.secondCurrency?.ccy}
                <CustomeInput type="price" addonAfter={valyuta?.basic?.ccy} value={valyuta?.secondCurrency?.value} onChange={valyutaChange} />
              </h4>
              <h4>
                {t('create_payment.Valyuta')}:
                <CustomeInput type="price" addonAfter={valyuta?.secondCurrency?.ccy} value={valyuta?.value} onChange={amountChange} />
              </h4>
            </div>
          )}
        </div>
        <div className={styles.comment}>
          <p>{t('home.Izoh')}</p>
          <h3>{data?.description}</h3>
        </div>
        <div className={styles.contract}>
          <div className={styles.contract_info}>
            {/* <div className={styles.contract_info_item}>
              <h1>{t('monetary.Bank_malumotlari')}</h1>
              <div>
                <p>{t('monetary.Shartnoma_sanasi')}</p>
                <h3>{data?.paymentContractDate}</h3>
              </div>
              <div>
                <p>{t('boss_contract.Shartnoma_raqami')}</p>
                <h3>{data?.paymentContractId}</h3>
              </div>
              <div>
                <p>{t('home.Mijoz')}</p>
                <h3>{data?.paymentFullName}</h3>
              </div>
            </div> */}
            <div className={styles.contract_info_item}>
              <h1>
                {t('monetary.Uysot_malumotlari')}
                {data?.uysotContractNumber && (
                  <span className={styles.refresh} onClick={() => setOpenAddClient(data?.id || null)}>
                    <RefreshIcon />
                  </span>
                )}
              </h1>
              {data?.uysotContractNumber ? (
                <>
                  <div>
                    <p>{t('monetary.Shartnoma_sanasi')}</p>
                    <h3>{data?.uysotContractContractDate}</h3>
                  </div>
                  <div>
                    <p>{t('boss_contract.Shartnoma_raqami')}</p>
                    <h3>{data?.uysotContractNumber}</h3>
                  </div>
                  <div>
                    <p>{t('home.Mijoz')}</p>
                    <h3>{data?.uysotClientName}</h3>
                  </div>
                </>
              ) : (
                <div className={styles.addContract} onClick={() => setOpenAddClient(data?.id || null)}>
                  <PlusCircleIcon stroke="#464255" size={32} strokeWidth={1} />
                </div>
              )}
            </div>
            <div className={styles.contract_comment}>
              <br />
              <p>{t('home.Izoh')}</p>
              <div>
                <Input.TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  value={note}
                  disabled={data?.accept}
                  className="customInput"
                  onChange={e => setNote(e?.target?.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
          {data?.accept ? (
            <Button danger onClick={handleRollback} style={{ background: '#D92D20', color: 'white' }}>
              {t('monetary.Tasdiqlashni_qaytarish')}
            </Button>
          ) : (
            <Button type="primary" onClick={handleAccept} loading={monetaryPayment.isLoading}>
              {t('home.Saqlash')}
            </Button>
          )}{' '}
        </div>
      </Modal>
      <MonetaryPaymentRollback setVisible={setOpenRollback} id={openRollback} />
      <MonetaryTableAddClient id={openAddClient} setVisible={setOpenAddClient} />
    </>
  );
};

export default MonetaryTableInformation;
