import React from 'react';
import SecondRowBody from './body/SecondRowBody';
import SecondRowHeader from './header/SecondRowHeader';
import styles from './secondRow.module.scss';
const SecondRow: React.FC = () => {
  return (
    <div className={styles.s_row_container}>
      <SecondRowHeader />
      <SecondRowBody />
    </div>
  );
};

export default SecondRow;
