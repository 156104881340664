import React, { useState } from 'react';
import ClientInfo from '../../components/clientBalance/ClientInfo';
import BalanceInfo from '../../components/clientBalance/BalanceInfo';
import PaymentInOutButton from '../../components/clientBalance/PaymentInOutButton';
import TableBalance from '../../components/clientBalance/TableBalance';
import { useClientViewByClientId } from '../../queries/queries';
import { Layout, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { ClientType } from '../../utils/models/ClientType';
import CreateDebitModal from '../../components/clientBalance/CreateDebitModal';
import DownloadButton from '../../components/clientBalance/DownloadButton';
import { useTranslation } from 'react-i18next';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';

const { Content } = Layout;

const ClientBalance = () => {
  const params = useParams<{ idClient: string }>();
  const [debitModal, setDebitModal] = useState<{
    type: 'out' | 'in';
    visible: boolean;
  }>({
    type: 'out',
    visible: false
  });
  const { isLoading, data } = useClientViewByClientId(params.idClient || '-1');
  const { t } = useTranslation();

  const getClientName: () => string = () => {
    if (data && data.type === ClientType.JURIDICAL) {
      // @ts-ignore
      return data?.name;
    } else if (data) {
      // @ts-ignore
      return data?.firstname + ' ' + data?.lastname + ' ' + data?.fatherName;
    }
    return '';
  };

  const handleDebitModal = (type: 'out' | 'in') => {
    setDebitModal({
      type,
      visible: true
    });
  };

  const handleCloseDebitModal = () => {
    setDebitModal({
      type: 'out',
      visible: false
    });
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div>
          <Spin spinning={isLoading}>
            <div className={'d-flex align-center justify-space-between'}>
              <ClientInfo clientName={getClientName()} phones={data?.phones || []} />
              <BalanceInfo balances={data?.balances || []} />
              <PaymentInOutButton openModal={handleDebitModal} title={t('client_balance.Mablag_kiritish')} type={'in'} />
              <PaymentInOutButton openModal={handleDebitModal} title={t('client_balance.Mablag_chiqarish')} type={'out'} />
              <DownloadButton id={params.idClient || '-1'} name={getClientName()} />
            </div>
          </Spin>
          <TableBalance clientId={params.idClient || '-1'} />
          <CreateDebitModal clientId={params.idClient || '-1'} type={debitModal.type} visible={debitModal.visible} close={handleCloseDebitModal} />
        </div>
      </Content>
    </>
  );
};

export default ClientBalance;
