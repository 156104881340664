import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const TagTwoYellowIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5249 7.24992L12.7499 3.47492C11.9582 2.68325 10.8666 2.25825 9.7499 2.31659L5.58323 2.51659C3.91656 2.59159 2.59156 3.91659 2.50823 5.57492L2.30823 9.74159C2.25823 10.8583 2.6749 11.9499 3.46656 12.7416L7.24156 16.5166C8.79156 18.0666 11.3082 18.0666 12.8666 16.5166L16.5249 12.8583C18.0832 11.3166 18.0832 8.79992 16.5249 7.24992ZM7.91656 10.3166C6.5999 10.3166 5.51656 9.24159 5.51656 7.91659C5.51656 6.59159 6.5999 5.51659 7.91656 5.51659C9.23323 5.51659 10.3166 6.59159 10.3166 7.91659C10.3166 9.24159 9.23323 10.3166 7.91656 10.3166ZM14.6082 11.2749L11.2749 14.6083C11.1499 14.7333 10.9916 14.7916 10.8332 14.7916C10.6749 14.7916 10.5166 14.7333 10.3916 14.6083C10.1499 14.3666 10.1499 13.9666 10.3916 13.7249L13.7249 10.3916C13.9666 10.1499 14.3666 10.1499 14.6082 10.3916C14.8499 10.6333 14.8499 11.0333 14.6082 11.2749Z"
        fill="#FFB400"
      />
    </svg>
  );
};

export default TagTwoYellowIcon;
