import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';

import styles from './sumPriceDonutChart.module.scss';
import { useBasicCurrency, useBossComplexTotalCapital } from '../../queries/queries';
import { colorPieChart } from '../../utils/constants/colorPieChart';
import { PieChartData } from '../../utils/models/PieChartData';

const SumPriceDonutChart = () => {
  const [dataChart, setDataChart] = useState<PieChartData[]>([]);

  const { isLoading, data, isFetching } = useBossComplexTotalCapital();

  const { t } = useTranslation();

  const { data: currency } = useBasicCurrency();

  useEffect(() => {
    if (data) {
      setDataChart([
        {
          id: 'Undirilgan mablag’',
          label: t('boss_complex.Undirilgan_mablag'),
          value: data?.collected,
          color: colorPieChart[0]
        },
        {
          id: 'Sotuvda',
          label: t('boss_complex.Sotuvda'),
          value: data?.onSale,
          color: colorPieChart[1]
        },
        {
          id: 'Kutilmoqda',
          label: t('boss_complex.Kutilmoqda'),
          value: data?.expectation,
          color: colorPieChart[2]
        }
      ]);
    }
  }, [data, t]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h3>{t('boss_complex.Umumiy_statistika')}</h3>
        </div>
        <div className={styles.container_body}>
          <div className={styles.container_body_title}>
            <p>{t('boss_complex.Umumiy_uylar_bahosi')}</p>
            <h2>
              {data?.totalValue?.toLocaleString('ru')} {currency?.ccy}
            </h2>
          </div>
          <div className={styles.container_body_chart}>
            {currency ? (
              <ResponsivePie
                data={dataChart}
                margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                innerRadius={0.65}
                cornerRadius={3}
                activeOuterRadiusOffset={16}
                colors={{ datum: 'data.color' }}
                borderColor={{
                  from: 'color',
                  modifiers: [['opacity', 0.2]]
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabel="value"
                arcLabelsRadiusOffset={0.3}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                tooltip={e => {
                  const item = e.datum;
                  return (
                    <div className={styles.tooltip}>
                      <div className={styles.tooltip_label}>{item.label}</div>
                      <div className={styles.tooltip_value}>
                        {item.value.toLocaleString('ru')} {currency?.ccy}
                      </div>
                    </div>
                  );
                }}
                legends={[]}
              />
            ) : null}
          </div>
          <div className={styles.container_body_legneds}>
            {dataChart.map((item, index) => (
              <div key={index} className={styles.container_body_legneds_item}>
                <span
                  style={{
                    // @ts-ignore
                    '--color': colorPieChart[index]
                  }}
                />
                <p>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SumPriceDonutChart;
