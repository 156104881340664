import React from 'react';

const ErrorIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00033 0.666504C4.42533 0.666504 2.96699 1.30817 1.85866 2.4165C0.750326 3.52484 0.166992 5.0415 0.166992 6.6165C0.166992 9.7665 2.73366 12.3332 5.88366 12.3332H6.00033C9.26641 12.2748 11.8337 9.64984 11.8337 6.38317C11.8337 3.23317 9.26641 0.666504 6.11699 0.666504H6.00033ZM5.53366 3.92156C5.53366 3.6299 5.76699 3.4549 6.00033 3.4549C6.29199 3.4549 6.46699 3.68823 6.46699 3.92156V6.83823C6.46699 7.1299 6.23366 7.3049 6.00033 7.3049C5.70866 7.3049 5.53366 7.1299 5.53366 6.83823V3.92156ZM6.00021 9.55079C6.41902 9.55079 6.75854 9.21127 6.75854 8.79245C6.75854 8.37364 6.41902 8.03412 6.00021 8.03412C5.58139 8.03412 5.24188 8.37364 5.24188 8.79245C5.24188 9.21127 5.58139 9.55079 6.00021 9.55079Z"
        fill="#D21C1C"
      />
    </svg>
  );
};

export default ErrorIcon;
