import React from 'react';
import styles from '../../pages/createTablePayments/createTablePayments.module.scss';
import PaymentInfoCard from '../../assets/icons/PaymentInfoCard';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';

const Cards = () => {
  const { residueTablePayments, sumTablePayments, currency } = useAppSelector(state => state.createContract);
  const { t } = useTranslation();
  return (
    <div className={styles.cards_cont}>
      <div className={`d-flex align-center ${styles.cards_cont_card}`}>
        <div className={styles.cards_cont_card_sum_chash}>
          <PaymentInfoCard />
        </div>
        <div>
          <p className={styles.cards_cont_card_title}>{t(`changes.monthlyPayments.Qoldiq_summa`)}</p>
          <p className={styles.cards_cont_card_amount}>
            {residueTablePayments?.toLocaleString('ru')} {currency?.ccy}
          </p>
        </div>
      </div>
      <div className={`d-flex align-center ${styles.cards_cont_card}`}>
        <div className={styles.cards_cont_card_sum_bank_card}>
          <PaymentInfoCard />
        </div>
        <div>
          <p className={styles.cards_cont_card_title}>{t(`changes.monthlyPayments.Jadvaldagi_summa`)}</p>
          <p className={styles.cards_cont_card_amount}>
            {sumTablePayments?.toLocaleString('ru')} {currency?.ccy}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cards;
