import React, { useEffect, useState } from 'react';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import Edit2Icon from '@icons/Edit2Icon';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import DeleteConfirm1 from '@pages/dashboard/components/confirm-modal/DeleteConfirm1';
import { useDeleteCustomDocTemplate } from '@queries/mutation';
import { useGetCustomTemplateDocument } from '@queries/queries';
import { Dropdown, Menu, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '../../hooks/useQueryParams';
import { FileTemplateType } from '../../utils/models/FileTemplate';

import CompanySetting from './company-setting/CompanySetting';
import CreateShablon from './create-shablon/CreateShablon';

import styles from './companySetting.module.scss';

const { TabPane } = Tabs;

type TabOptions = {
  id: number;
  key: string;
  tab: string;
  children: JSX.Element;
  type?: string;
};

const type = FileTemplateType?.CONTRACT_CUSTOM;

const CompanySettingTemplates = () => {
  const { t } = useTranslation();
  const { searchParams, appendSingle } = useQueryParam<{ tab: string }, null>();
  const { data } = useGetCustomTemplateDocument();
  const deleteTemp = useDeleteCustomDocTemplate();

  const [tabOptions, setTabOptions] = useState<TabOptions[]>();
  const [openShablon, setOpenShablon] = useState(false);
  const [sendData, setSendData] = useState<{ name: string; id: number }>();
  const newData =
    data &&
    data?.map(item => ({
      id: item?.id,
      key: item?.name,
      tab: item?.name,
      children: <CompanySetting type={FileTemplateType.CONTRACT_CUSTOM} tempId={item?.id} />,
      type: 'CUSTOM'
    }));
  const tabsOptions = [
    {
      id: 100,
      key: 'contract',
      tab: t('home.Shartnoma'),
      children: <CompanySetting type={FileTemplateType.CONTRACT} />
    },
    {
      id: 99,
      key: 'check',
      tab: t('company_config.Check_tulovlar'),
      children: <CompanySetting type={FileTemplateType.PAYMENT} />
    },
    {
      id: 98,
      tab: t('company_config.Check_client'),
      key: 'check_client',
      children: <CompanySetting type={FileTemplateType.CLIENT_PAYMENT} />
    },
    {
      id: 97,
      tab: t('company_config.Akt'),
      key: 'akt',
      children: <CompanySetting type={FileTemplateType.ACT} />
    },
    {
      id: 96,
      key: 'order',
      tab: t('titles.order'),
      children: <CompanySetting type={FileTemplateType.BOOKING} />
    },
    {
      id: 95,
      key: 'booking',
      tab: t('titles.booking'),
      children: <CompanySetting type={FileTemplateType.BOOKING_PAYMENT} />
    }
  ];

  useEffect(() => {
    if (data) {
      setTabOptions(prev => [...tabsOptions!, ...newData!]);
    }
  }, [data]);

  const changeTab = (key: string) => {
    appendSingle('tab', key);
  };

  const handleOpen = () => {
    setOpenShablon(true);
  };

  const handleDeleteTemp = (id: number) =>
    deleteTemp.mutateAsync(id).then(() => {
      appendSingle('tab', 'contract');
    });

  return (
    <div className={styles.templatesTab}>
      <Tabs activeKey={searchParams.tab} onChange={changeTab} className="templatesTabs">
        {tabOptions?.map(item => (
          <TabPane
            key={item?.key}
            tab={
              <div className={styles.tabItem}>
                <span>{item?.tab}</span>
                {item?.type && (
                  <Dropdown
                    overlay={
                      <Menu style={{ width: 240, borderRadius: 6 }}>
                        <Menu.Item
                          onClick={() => {
                            handleOpen();
                            setSendData({ id: item?.id, name: item?.tab });
                          }}
                        >
                          <Edit2Icon /> <p className={styles.menuDropdownItem}>Tahrirlash</p>
                        </Menu.Item>
                        <Menu.Item>
                          <DeleteConfirm1
                            children={
                              <div className={styles.actionsDropdown_item}>
                                <TrushIcon />
                                <p className={styles.menuDropdownItem}>{t('shaxmatka.Ochirish')}</p>
                              </div>
                            }
                            onConfirm={() => handleDeleteTemp(Number(item?.id))}
                            text={`${t('home.Haqiqatdan_ham_ochirmoqchimisiz?')}(${item?.tab})`}
                            isLoading={deleteTemp.isLoading}
                          />
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click', 'hover']}
                    mouseEnterDelay={1000}
                    mouseLeaveDelay={0.3}
                  >
                    <span onClick={e => e.stopPropagation()} className={styles.tabItem_dots}>
                      <DotsIcon />
                    </span>
                  </Dropdown>
                )}
              </div>
            }
          >
            {item?.children}
          </TabPane>
        ))}
      </Tabs>
      <div className={styles.createShablon} onClick={handleOpen}>
        <PlusCircleIcon stroke="#1E90FF" />
        <span>Shablon qo'shish</span>
      </div>

      <CreateShablon open={openShablon} setOpen={setOpenShablon} sendData={sendData} setSendData={setSendData} />
    </div>
  );
};

export default CompanySettingTemplates;
