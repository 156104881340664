import React from 'react';

const ArrowDownTopIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.08 8L5.34 4.74C5.725 4.355 6.355 4.355 6.74 4.74L10 8"
        stroke="#0078D2"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownTopIcon;
