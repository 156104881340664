import React, { FC } from 'react';

interface IProps {
  background?: string;
  stroke?: string;
  style?: React.CSSProperties;
}

const EllipseIcon: FC<IProps> = ({ stroke = '#87898E', background = 'none', style }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <circle cx="6.69296" cy="6.49996" r="5.38046" stroke={stroke} fill={background} />
    </svg>
  );
};

export default EllipseIcon;
