export enum MainPaths {
  INDEX = '/home',
  ORDER = '/order',
  CONTRACT = '/contract',
  PAYMENT = '/payment',
  CLIENT = '/client',
  ARREARAGE = '/arrearage',
  MESSAGE = '/message',
  CHANGES = '/changes',
  BROKER = '/broker',
  FLAT_ID = '/:idFlat',
  HOME_NAME = '/:nameHouse',
  ORDER_ID = '/:orderId',
  ROBOTO_CALLS_ID = '/:robotoCallsId',
  ORDER_OR_FLAT_ID = '/:orderIdOrFlatId',
  ORDER_NUMBER = '/:orderNumber',
  ROBOTO_CALLS_NAME = '/:robotoCallsName',
  CREATE_ORDER = '/create-order',
  SETTING = '/setting',
  CONTRACT_ID = '/:id',
  CONTRACT_NUMBER = '/:number',
  CLIENT_NUMBER = '/:number',
  HOUSE_INFO = '/house-info',
  CLIENT_INFO = '/client-info',
  CLIENT_ID = '/:idClient',
  CREATE = '/create',
  TABLE_PAYMENT = '/table-payment',
  UPDATE_TABLE = '/update-table',
  CREATE_PAYMENT_SEARCH_CLIENT = '/new-payment/search-client',
  CLIENT_BALANCE_SEARCH_CLIENT = '/client-balance/search-client',
  CREATE_PAYMENT = '/create-payment',
  CLIENT_BALANCE = '/client-balance',
  CREATE_NEW_CLIENT = '/create/new-client',
  PROFILE = '/profile',
  TASK = '/task',
  ONE_TASK = '/task/:contractId/:clientName',
  CRM = '/crm',
  CRM_ONE_LID = '/crm/:id/:name',
  CONFIRM = '/confirm',
  CONFIRM_REPORT = '/confirm-report',
  MONETARY = '/monetary',
  EVENTS_LIST = '/events-list',
  DASHBOARD = '/dashboard',
  ROBOTO_CALLS = "/roboto-calls"
}
