import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const AddCircleIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.5C17.5 22.5 22 18 22 12.5C22 7 17.5 2.5 12 2.5C6.5 2.5 2 7 2 12.5C2 18 6.5 22.5 12 22.5Z"
        stroke="#3772FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 12.5H16" stroke="#3772FF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16.5V8.5" stroke="#3772FF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddCircleIcon;
