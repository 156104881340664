import { PaymentSettingsPath } from './PaymentSettingsPath';
import RemotePayment from '../../components/paymentSettings/remotePayment/RemotePayment';
import AutomaticPayment from '../../components/paymentSettings/automaticPayment/AutomaticPayment';
import PaymentWays from '../../components/paymentSettings/paymentWays/PaymentWays';
import Currency from '../../pages/currency/Currency';
import PaymentChanges from '@pages/setting/payment-changes/PaymentChanges';

export const paymentSettingsRoutes = [
  {
    path: PaymentSettingsPath.REMOTE_PAYMENT,
    component: RemotePayment,
    exact: true
  },
  {
    path: PaymentSettingsPath.AUTOMATIC_PAYMENT,
    component: AutomaticPayment,
    exact: true
  },
  {
    component: PaymentWays,
    path: PaymentSettingsPath.PAYMENT_WAYS,
    exact: false
  },
  {
    component: Currency,
    path: PaymentSettingsPath.CURRENCY,
    exact: true
  },
  {
    component: PaymentChanges,
    path: PaymentSettingsPath.FIELD,
    exact: true
  }
];
