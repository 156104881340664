import React from 'react';

const NavigateIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.66797 7.33385L14.1346 1.86719" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.6648 4.53398V1.33398H11.4648" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.33203 1.33398H5.9987C2.66536 1.33398 1.33203 2.66732 1.33203 6.00065V10.0007C1.33203 13.334 2.66536 14.6673 5.9987 14.6673H9.9987C13.332 14.6673 14.6654 13.334 14.6654 10.0007V8.66732"
        stroke="#00A389"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default NavigateIcon;
