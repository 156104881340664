const CallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M10.985 9.165C10.985 9.345 10.945 9.53 10.86 9.71C10.775 9.89 10.665 10.06 10.52 10.22C10.275 10.49 10.005 10.685 9.7 10.81C9.4 10.935 9.075 11 8.725 11C8.215 11 7.67 10.88 7.095 10.635C6.52 10.39 5.945 10.06 5.375 9.645C4.8 9.225 4.255 8.76 3.735 8.245C3.22 7.725 2.755 7.18 2.34 6.61C1.93 6.04 1.6 5.47 1.36 4.905C1.12 4.335 1 3.79 1 3.27C1 2.93 1.06 2.605 1.18 2.305C1.3 2 1.49 1.72 1.755 1.47C2.075 1.155 2.425 1 2.795 1C2.935 1 3.075 1.03 3.2 1.09C3.33 1.15 3.445 1.24 3.535 1.37L4.695 3.005C4.785 3.13 4.85 3.245 4.895 3.355C4.94 3.46 4.965 3.565 4.965 3.66C4.965 3.78 4.93 3.9 4.86 4.015C4.795 4.13 4.7 4.25 4.58 4.37L4.2 4.765C4.145 4.82 4.12 4.885 4.12 4.965C4.12 5.005 4.125 5.04 4.135 5.08C4.15 5.12 4.165 5.15 4.175 5.18C4.265 5.345 4.42 5.56 4.64 5.82C4.865 6.08 5.105 6.345 5.365 6.61C5.635 6.875 5.895 7.12 6.16 7.345C6.42 7.565 6.635 7.715 6.805 7.805C6.83 7.815 6.86 7.83 6.895 7.845C6.935 7.86 6.975 7.865 7.02 7.865C7.105 7.865 7.17 7.835 7.225 7.78L7.605 7.405C7.73 7.28 7.85 7.185 7.965 7.125C8.08 7.055 8.195 7.02 8.32 7.02C8.415 7.02 8.515 7.04 8.625 7.085C8.735 7.13 8.85 7.195 8.975 7.28L10.63 8.455C10.76 8.545 10.85 8.65 10.905 8.775C10.955 8.9 10.985 9.025 10.985 9.165Z"
        stroke="#8C8C8C"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default CallIcon;
