import React, { useEffect, useRef, useState } from 'react';
import DeleteIconTaskCreator from '@pages/oneLid/assets/icons/DeleteIconTaskCreator';
import EditIconTaskCreator from '@pages/oneLid/assets/icons/EditIconTaskCreator';
import InfoIconTaskCreator from '@pages/oneLid/assets/icons/InfoIconTaskCreator';
import { Button, Checkbox, Dropdown, Input, Menu, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import dayjs from 'dayjs';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import ClockCrmIcon from '../../../../../../assets/icons/ClockCrmIcon';
import CheckmarkIcon from '../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '../../../../../../assets/icons/crmIcons/crmIcons';
import MoreHorizIcon from '../../../../../../assets/icons/MoreHorizIcon';
import SmileyIcon from '../../../../../../assets/icons/SmileyIcon';
import MDatePicker from '../../../../../../components/mDatePicker/MDatePicker';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { useCreateLeadTask } from '../../../../../../queries/mutation';
import { useCrmEmployee, useLeadTaskType } from '../../../../../../queries/queries';
import i18n from '../../../../../../service/I18nService';
import { emojies } from '../../../../../../utils/constants/emojies';
import { DATE_WITH_TIME_FORMAT } from '../../../../../../utils/constants/format';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../../utils/models/GoogleAnalitks';
import { OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';
import { useLeadTaskClosed, useLeadTaskDelete } from '../../../../services/mutation';

import styles from './unCompleted.module.scss';

interface Props {
  event: OneEvent;
}

const UnCompletedTask: React.FC<Props> = ({ event }) => {
  const { params } = useQueryParam<any, { id: string }>();
  const [selectionStart, setSelectionStart] = useState(0);
  const [visbleEmoje, setVisbleEmoje] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openExtraFooter, setOpenExtraFooter] = useState(false);
  const [text, setText] = useState('');
  const [textEdit, setTextEdit] = useState('');
  const [checked, setChecked] = useState(false);
  const [meetingSelectVisible, setMeetingSelectVisible] = useState(false);
  const [activeMeetingSelectId, setActiveMeetingSelectId] = useState(1);
  const [mPickerVisible, setMPickerVisible] = useState(false);
  const [dateValue, setDateValue] = useState(`${event.deadlineStart} ${dayjs(event.deadlineFinish, 'DD.MM.YYYY HH:mm').format('HH:mm')}`);
  const [createDeadline, setCreateDeadline] = useState({
    start: event.deadlineStart,
    end: event.deadlineFinish
  });
  const [employeeSelectVisible, setEmployeeSelectVisible] = useState(false);
  const { data: leadEmployeeList } = useCrmEmployee();
  const [activeEmployeeId, setActiveEmployeeId] = useState(0);

  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  const leadSave = useCreateLeadTask(Number(params.id));

  const inputRef = useRef<any>(null);
  const inputRefEdit = useRef<any>(null);

  const { t } = useTranslation();

  // close task
  function closeTaskEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.COMPLETE_TASK
    });
  }

  const leadTaskClose = useLeadTaskClosed(Number(params.id), closeTaskEvent);
  const deleteTask = useLeadTaskDelete(Number(params.id));

  const { data: leadTaskTypeAll } = useLeadTaskType();

  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setActiveMeetingSelectId(event.leadTaskTypeDto.id);
    }
    // eslint-disable-next-line
  }, [leadTaskTypeAll]);

  // handleCloseLeadTask
  const handleCloseLeadTask = () => {
    leadTaskClose.mutate({
      closedText: text,
      deadlineStart: createDeadline.start,
      deadlineFinish: createDeadline.end,
      reCreate: checked,
      id: event.id,
      leadTaskTypeId: activeMeetingSelectId
    });
  };

  // handleMeetingSelect
  const handleMeetingSelect = () => {
    setMeetingSelectVisible(true);
  };

  // handleMeetingSelectItem
  const handleMeetingSelectItem = (id: number) => {
    setMeetingSelectVisible(false);
    setActiveMeetingSelectId(id);
  };

  // handle open extra footer
  const handleOpenExtraFooter = (visible: boolean) => {
    inputRef.current?.focus();
    setOpenExtraFooter(visible);
  };

  // change text
  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  // change text
  const changeChecked = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
  };

  // onChangeMPicker
  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(`${date} ${start} ${end}`);
    setCreateDeadline({
      start: `${date} ${start}`,
      end: `${date} ${end}`
    });
  };

  // handle delete task
  const handleDeleteTask = () => {
    deleteTask.mutate(event.id);
  };

  // handlePicker
  const handlePicker = () => {
    setMPickerVisible(true);
  };

  // translateFor
  const translateFor = () => {
    switch (i18n.language) {
      case 'ru':
        return <>для {event.assignedBy}</>;
      case 'en':
        return <>for {event.assignedBy}</>;
      default:
        return <>{event.assignedBy} uchun</>;
    }
  };

  const hadleEditTask = () => {
    setEdit(!edit);
    setActiveEmployeeId(leadEmployeeList?.find(item => item.fullName === event.assignedBy)?.id!);
    if (inputRefEdit) {
      inputRefEdit?.current?.focus();
    }
    setTextEdit(event.text);
  };

  const handleOpenTaskInfo = () => {
    setIsInfoModalVisible(!isInfoModalVisible); // Инвертируем состояние при каждом вызове функции
  };

  // menuItems
  const menuItems: ItemType[] = [
    {
      key: '1',
      label: (
        <div className={styles.flexItems}>
          <EditIconTaskCreator />
          {t('shaxmatka.Tahrirlash')}
        </div>
      ),
      onClick: () => hadleEditTask()
    },
    {
      key: '2',
      label: (
        <div className={styles.flexItems}>
          <DeleteIconTaskCreator />
          {t('one_lid.Ochirish')}
        </div>
      ),
      onClick: () => handleDeleteTask()
    },
    {
      key: '3',
      label: (
        <div className={styles.flexItems}>
          <InfoIconTaskCreator />
          {t('contract_id.Malumot')}
        </div>
      ),
      onClick: () => handleOpenTaskInfo()
    }
  ];

  // handleEmployeeSelect
  const handleEmployeeSelect = () => {
    setEmployeeSelectVisible(true);
  };

  // handleEmployee
  const handleEmployee = async (id: number) => {
    setEmployeeSelectVisible(false);
    await setActiveEmployeeId(id);
    inputRef.current?.focus();
  };

  // translateForTask
  const translateForTask = (name: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            для <span>{name}</span>:
          </>
        );
      case 'en':
        return (
          <>
            for <span>{name}</span>:
          </>
        );
      default:
        return (
          <>
            <span>{name}</span> uchun:
          </>
        );
    }
  };

  // changeTextEdit
  const changeTextEdit = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextEdit(e.target.value);
  };

  // handleSubmit
  const handleSubmit = () => {
    leadSave
      .mutateAsync({
        assignedById: activeEmployeeId,
        deadlineStart: createDeadline.start,
        deadlineFinish: createDeadline.end,
        leadId: Number(params?.id),
        leadTaskTypeId: activeMeetingSelectId,
        text: textEdit,
        id: event.id
      })
      .then(() => {
        setEdit(!edit);
      });
  };

  // handleEmoje
  const handleEmoje = (item: string) => {
    const textStart = textEdit?.slice(0, selectionStart);
    const textEnd = textEdit?.slice(selectionStart);

    setTextEdit(textStart + item + textEnd);
    setVisbleEmoje(false);
  };

  // handleTextarea
  const handleTextarea = (e: any) => {
    setSelectionStart(e?.target?.selectionStart);
  };

  return (
    <div
      onClick={() => {
        handleOpenExtraFooter(true);
      }}
      className={styles.container}
    >
      <Modal visible={isInfoModalVisible} className={styles.modal} closable={false} onCancel={() => setIsInfoModalVisible(false)} footer={null}>
        <div className={styles.inform} style={{ marginBottom: '10px' }}>
          <p>{t('home.Yaratilgan_sana')}</p>
          <span>{event.createdDate}</span>
        </div>
        <div className={styles.inform}>
          <p>{t('home.Yaratdi')}</p>
          <span>{event.createdBy}</span>
        </div>
      </Modal>

      <Dropdown overlay={<Menu items={menuItems} />} placement="bottom" trigger={['click']}>
        <div className={styles.container_more} onClick={e => e.stopPropagation()}>
          <MoreHorizIcon width={3} height={16} />
        </div>
      </Dropdown>
      <div className={styles.container_icon}>
        <ClockCrmIcon />
      </div>
      <div className={styles.container_main}>
        {!edit && (
          <>
            <div className={styles.container_main_date}>
              {event.deadlineFinish.slice(0, 10)}{' '}
              <span>
                ({Math.abs(Math.ceil((dayjs(event.deadlineFinish, DATE_WITH_TIME_FORMAT).unix() - dayjs(new Date()).unix()) / (60 * 60 * 24)))}{' '}
                {t('one_lid.kun')})
              </span>{' '}
              {translateFor()}{' '}
            </div>
            <div className={styles.container_main_note}>
              {crmIcons[event.leadTaskTypeDto.icon?.split('#')[0]!]?.icon(`#${event.leadTaskTypeDto.icon?.split('#')[1]}`)}
              <p>
                <span> {event.leadTaskTypeDto.name} -</span> {event.text}
              </p>
            </div>
            <div className={styles.container_main_text_input}>
              <Input.TextArea ref={inputRef} onChange={e => changeText(e)} autoSize={{ minRows: 1, maxRows: 4 }} />
              <Button loading={leadTaskClose.isLoading} onClick={handleCloseLeadTask} type="primary">
                {t('one_lid.Bajarildi')}
              </Button>
            </div>
          </>
        )}

        {openExtraFooter && (
          <div className={styles.container_main_editable_bottom}>
            {!edit && <Checkbox onChange={changeChecked} checked={checked} />}
            <Dropdown
              onVisibleChange={visible => setMeetingSelectVisible(visible)}
              visible={meetingSelectVisible}
              overlay={
                <div className={styles.container_main_editable_bottom_meetingSelect}>
                  {leadTaskTypeAll?.map(item => (
                    <div key={item.id} onClick={() => handleMeetingSelectItem(item.id)}>
                      {crmIcons[item.icon.split('#')[0]]?.icon(`#${item.icon.split('#')[1]}`)} {item.name}
                      {activeMeetingSelectId === item.id && (
                        <span>
                          <CheckmarkIcon />
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              }
              trigger={['click']}
              placement="topLeft"
            >
              <button onClick={handleMeetingSelect}>
                {crmIcons[leadTaskTypeAll?.find(item => item.id === activeMeetingSelectId)?.icon?.split('#')[0]!]?.icon(
                  `#${leadTaskTypeAll?.find(item => item.id === activeMeetingSelectId)?.icon?.split('#')[1]}`
                )}
                {leadTaskTypeAll?.find(item => item.id === activeMeetingSelectId)?.name}
                <ArrowDownIcon />
              </button>
            </Dropdown>
            <Dropdown
              visible={mPickerVisible}
              onVisibleChange={setMPickerVisible}
              overlay={
                <div>
                  <MDatePicker
                    visibleTime={mPickerVisible}
                    onChangeMPicker={onChangeMPicker}
                    data={[
                      {
                        id: 'edit',
                        title: `${t('one_lid.Topshiriq')}: ${dayjs(event?.deadlineStart, 'DD.MM.YYYY HH:mm').format('HH:mm')} ${dayjs(
                          event?.deadlineFinish,
                          'DD.MM.YYYY HH:mm'
                        ).format('HH:mm')} `,
                        start: dayjs(event?.deadlineStart, 'DD.MM.YYYY HH:mm')?.toISOString(),
                        end: dayjs(event?.deadlineFinish, 'DD.MM.YYYY HH:mm')?.toISOString(),
                        allDay: false
                      }
                    ]}
                  />
                </div>
              }
              trigger={['click']}
              placement="topCenter"
            >
              <Input
                onClick={handlePicker}
                value={dateValue}
                style={{
                  width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                }}
              />
            </Dropdown>
            {edit && (
              <Dropdown
                visible={employeeSelectVisible}
                onVisibleChange={visible => setEmployeeSelectVisible(visible)}
                overlay={
                  <div className={styles.container_taskEmployee_content}>
                    {leadEmployeeList!?.map(item => (
                      <div key={item.id} onClick={() => handleEmployee(item.id)}>
                        {item.fullName}{' '}
                        {activeEmployeeId === item.id && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                trigger={['click']}
                placement="topLeft"
              >
                <div className={styles.container_taskEmployee} onClick={handleEmployeeSelect}>
                  {translateForTask(leadEmployeeList!?.find(item => item.id === activeEmployeeId)?.fullName || '')}
                </div>
              </Dropdown>
            )}
            {/* <span
              className={styles.container_main_editable_bottom_delete}
              onClick={handleDeleteTask}
            >
              <TrushSquareIcon /> {t('one_lid.Ochirish')}
            </span> */}
          </div>
        )}
        {edit && (
          <>
            <Input.TextArea
              autoSize={{ minRows: 3 }}
              style={{ margin: '10px 0' }}
              className="customInput"
              ref={inputRefEdit}
              value={textEdit}
              onChange={changeTextEdit}
              onClick={handleTextarea}
              onKeyUp={handleTextarea}
            />
            <div className={styles.container_main_footer}>
              <Button type="primary" onClick={handleSubmit} loading={leadSave.isLoading}>
                {t('one_task.Saqlash')}
              </Button>
              <Button onClick={hadleEditTask}>{t('one_lid.Bekor_qilish')}</Button>
              <Dropdown
                overlay={
                  <div className="emojies">
                    <div className="emojies_content">
                      {emojies?.map(item => (
                        <span onClick={() => handleEmoje(item)} key={item}>
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                }
                visible={visbleEmoje}
                onVisibleChange={setVisbleEmoje}
                placement="topCenter"
                trigger={['click']}
              >
                <SmileyIcon className={styles.container_submit_right_item} />
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UnCompletedTask;
// @thtflx
