/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditAddTagIcon from '@icons/addTag/EditAddTagIcon';
import TrushAddTagIcon from '@icons/addTag/TrushAddTagIcon';
import MenuThreeDotHorizontalIcon from '@icons/MenuThreeDotHorizontalIcon';
import ProgrammingArrowsIcon from '@icons/ProgrammingArrowsIcon';
import { useGetLeadDuplicate } from '@pages/oneLid/services/queries';
import { Dropdown, Spin } from 'antd';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useCreateTag } from '../../../../../queries/mutation';
import { useTags } from '../../../../../queries/queries';
import { setTags } from '../../../../../store/reducers/CrmOneLidSlice';
import { BaseEntity } from '../../../../../utils/models/BaseEntity';
import { ACTION_EVENTS_GA_CRM, TYPE_EVENTS_GA } from '../../../../../utils/models/GoogleAnalitks';
import { useDeleteLid } from '../../../services/mutation';
import DuplicateModal from '../duplicate-modal/DuplicateModal';

import ControlTagsModal from './ControlTagsModal';
import SearchLidModal from './SearchLidModal';

import styles from './addTag.module.scss';

const AddTag = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { params } = useQueryParam<any, { id: string }>();

  const [newTagValue, setNewTagValue] = useState('');
  const [showLine, setShowLine] = useState(false);
  const [notActiveTags, setNotActiveTags] = useState<BaseEntity[]>([]);
  const [countPressBack, setCountPressBack] = useState(0);
  const [placeHolderTag, setPlaceHolderTag] = useState('');
  const [openControlTagModal, setOpenControlTagModal] = useState(false);
  const [openActionDropdown, setOpenActionDropdown] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openLidSearchModal, setOpenLidSearchModal] = useState(false);

  const isClosableMenu = useRef(true);

  const { isLoading, data: tags } = useTags();
  const createTag = useCreateTag(addNewTag);

  const deleteLidMutation = useDeleteLid(Number(params.id), goBack);

  const { tags: activeTags } = useAppSelector(state => state.crmOneLidField);

  const { data: leadDuplicate } = useGetLeadDuplicate(Number(params?.id));

  const leadClient = leadDuplicate?.find(item => item?.id === Number(params?.id))?.client;

  const input = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (tags && activeTags) {
      const notActive: BaseEntity[] = [];

      for (let i = 0; i < tags.tagList.length; i++) {
        const index = activeTags.findIndex(item => item.id === tags.tagList[i].id);

        if (index === -1) {
          notActive.push(tags.tagList[i]);
        }
      }
      setPlaceHolderTag(notActive[0] ? notActive[0].name : '');
      setNotActiveTags(notActive);
    }
  }, [activeTags, tags]);

  // add teg
  function addTegEvent() {
    ReactGA.event({
      category: TYPE_EVENTS_GA.CRM,
      action: ACTION_EVENTS_GA_CRM.ADD_TAG
    });
  }

  // handle actions dropdown
  const handleActionsDropdown = (visible: boolean) => {
    setOpenActionDropdown(visible);
  };

  // handle control modal
  const handleControlModal = (visible: boolean) => {
    handleActionsDropdown(false);
    setOpenControlTagModal(visible);
  };

  // handle open lid search modal
  const handleOpenLidSearchModal = () => {
    handleActionsDropdown(false);
    setOpenLidSearchModal(true);
  };

  // handle focus change
  const handleFocusChange = (focus: boolean) => {
    if (showLine && newTagValue) {
      handleCreateTag();
    }
    setCountPressBack(0);
    if (isClosableMenu.current) setShowLine(focus);
  };

  // handleCreateTag
  const handleCreateTag = () => {
    for (let i = 0; i < notActiveTags.length; i++) {
      if (notActiveTags[i].name === newTagValue) {
        const active = [...activeTags];

        active.push(notActiveTags[i]);
        dispatch(setTags([...active]));
        addTegEvent();
        setNewTagValue('');
        return;
      }
    }
    createTag.mutate(newTagValue);
  };

  // add new tag
  function addNewTag(tag: BaseEntity) {
    const active = [...activeTags];

    active?.push(tag);
    setNewTagValue('');
    dispatch(setTags(active));
    addTegEvent();
    input.current?.focus();
  }

  // add place holder tag
  const addPlaceHolderTag = () => {
    if (notActiveTags?.length) {
      const active = [...activeTags];

      active.push(notActiveTags[0]);
      dispatch(setTags([...active]));
      addTegEvent();
      setNewTagValue('');
      input.current?.focus();
    }
  };

  // handle press key
  const handlePresKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      if (!newTagValue) {
        addPlaceHolderTag();
      } else if (newTagValue) {
        handleCreateTag();
      }
      input.current?.focus();
    } else if (e.key === 'Enter') {
      if (!newTagValue) {
        addPlaceHolderTag();
      } else if (newTagValue) {
        handleCreateTag();
      }
      input.current?.focus();
    } else if (e.key === 'Backspace') {
      if (countPressBack === 1 && activeTags?.length) {
        const active = [...activeTags];

        active?.pop();
        dispatch(setTags(active));
        setCountPressBack(0);
      } else {
        setCountPressBack(1);
      }
    }
  };

  // handle change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountPressBack(0);
    setNewTagValue(e.target.value);
  };

  // handle press add button
  const handlePressAddButton = () => {
    input.current?.focus();
  };

  // handle closeable menu
  const handleClosableMenu = (isClosable: boolean) => {
    isClosableMenu.current = isClosable;
  };

  // handle mouse enter
  const handleMouseEnter = (value: string) => {
    setPlaceHolderTag(value);
  };

  // handle click menu item
  const handleClickMenuItem = (addTag: BaseEntity) => {
    const active = [...activeTags];

    active.push(addTag);
    dispatch(setTags([...active]));
    addTegEvent();
    setNewTagValue('');
    input.current?.focus();
  };

  // handle open duplicate modal
  const handleOpenDuplicateModal = () => {
    setOpenDuplicateModal(true);
  };

  // menu drop down tags
  const menuDropDown = () => {
    if (!notActiveTags?.length) {
      isClosableMenu.current = true;
      return <></>;
    }
    return (
      <div onMouseEnter={() => handleClosableMenu(false)} onMouseLeave={() => handleClosableMenu(true)} className={styles.container_menu_tags}>
        {notActiveTags
          .filter(tag => tag.name.includes(newTagValue))
          .map((tag, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div onClick={() => handleClickMenuItem(tag)} onMouseEnter={() => handleMouseEnter(tag.name)} key={index}>
              <span>{tag.name}</span>
            </div>
          ))}
      </div>
    );
  };

  // delete clicked tag
  const deleteClickedTag = (tag: BaseEntity) => {
    if (showLine) {
      const active = [...activeTags];
      const newTags = active.filter(item => item.id !== tag.id);

      dispatch(setTags(newTags));
      setCountPressBack(0);
    } else {
      setShowLine(true);
    }
  };

  // delete lid
  const deleteLid = () => {
    if (params.id === 'new') {
      goBack();
    } else {
      deleteLidMutation.mutate();
    }
  };

  // go back
  function goBack() {
    navigate(-1);
  }

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles.container_input}>
            {activeTags?.length
              ? activeTags.map((tag, index) => (
                  <div
                    onClick={() => {
                      deleteClickedTag(tag);
                    }}
                    className={countPressBack === 1 && index + 1 === activeTags.length ? styles.container_active_tag : styles.container_tag}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    {tag.name}
                  </div>
                ))
              : !showLine && (
                  <div onClick={handlePressAddButton} className={styles.container_add}>
                    {t('one_lid.Teg_qoshish')}
                  </div>
                )}

            <input
              ref={input}
              placeholder={showLine ? placeHolderTag : ''}
              value={newTagValue}
              onKeyDown={handlePresKey}
              onBlur={() => handleFocusChange(false)}
              onChange={handleChange}
              onFocus={() => handleFocusChange(true)}
            />
          </div>
          {leadDuplicate?.length! > 0 && (
            <span className={styles.dublicateText} onClick={handleOpenDuplicateModal}>
              {t('home.Dublicate')}
            </span>
          )}
        </div>
        {showLine && (
          <Dropdown visible={showLine} overlay={menuDropDown} placement="bottom">
            <hr className={styles.line} />
          </Dropdown>
        )}
        <span
          style={{
            position: 'absolute',
            right: leadDuplicate?.length! > 0 ? '0px' : '10px',
            top: '0'
          }}
        >
          <Dropdown
            visible={openActionDropdown}
            onVisibleChange={visible => handleActionsDropdown(visible)}
            placement="bottomRight"
            overlayClassName={styles.container_dropdown}
            overlay={
              <>
                <div onClick={() => handleControlModal(true)}>
                  <EditAddTagIcon />
                  <span>{t('one_lid.Taglarni_boshqarish')}</span>
                </div>
                <div onClick={deleteLid}>
                  <TrushAddTagIcon />
                  <span>{t('one_lid.Bitimni_ochirish')}</span>
                </div>
                <div onClick={handleOpenLidSearchModal}>
                  <ProgrammingArrowsIcon />
                  <span>{t('home.Lidlarni_birlashtirish')}</span>
                </div>
              </>
            }
            trigger={['click']}
          >
            <span onClick={() => handleActionsDropdown(true)} className={styles.container_menu}>
              <MenuThreeDotHorizontalIcon />
            </span>
          </Dropdown>
          <ControlTagsModal visible={openControlTagModal} onClose={handleControlModal} />
        </span>

        <DuplicateModal setVisible={setOpenDuplicateModal} visible={openDuplicateModal} />
        <SearchLidModal visible={openLidSearchModal} setVisible={setOpenLidSearchModal} leadClient={leadClient} />
      </div>
    </Spin>
  );
};

export default AddTag;
