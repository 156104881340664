import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAction, useContractVariationGroupDelete } from 'modules/multi-payment/hooks';

const PaymentDeleteOffer = () => {
  const { t } = useTranslation();
  const { closeAction, getActionData } = useAction();
  const navigate = useNavigate();
  const location = useLocation();
  const deleteOffer = useContractVariationGroupDelete({
    onSuccess: () => {
      closeAction();
      navigate({ pathname: location.pathname });
    }
  });

  return (
    <div className="flex flex-col items-center gap-4">
      <h1 className="text-[16px] text-[#23262f]">{t('payment.Tolov_taklifini_ochirmoqchimisiz')}</h1>
      <p className="text-[12px] text-[#87898e]">{t('contract_type.Agar_bu_tolov_taklifi_xonadonlarga_biriktirilgan')}</p>
      <div className="flex w-full justify-around">
        <Button
          onClick={closeAction}
          className="w-[146px] rounded-[50px] border-none bg-[#f2f2f2] text-[#23262f] hover:bg-[#f2f2f2] hover:text-[#23262f] focus:bg-[#f2f2f2] focus:text-[#23262f]"
        >
          {t('home.Yopish')}
        </Button>
        <Button
          onClick={() => deleteOffer.mutate(getActionData?.PAYMENT_TYPE_DELETE)}
          loading={deleteOffer.isLoading}
          className="w-[146px] rounded-[50px] border-none bg-[#ff3f40] text-[#ffffff] hover:bg-[#ff3f40] hover:text-[#ffffff] focus:bg-[#ff3f40] focus:text-[#ffffff]"
        >
          {t('home_id.Tasdiqlash')}
        </Button>
      </div>
    </div>
  );
};

export default PaymentDeleteOffer;
