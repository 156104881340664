import React from 'react';

const NotifIcon = () => {
  return (
    <svg width="306" height="306" viewBox="0 0 306 306" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="151.633" cy="168.57" r="95.8982" fill="#00A389" fillOpacity="0.2" />
      <circle cx="61.7451" cy="103.817" r="6.01071" fill="#00A389" fillOpacity="0.2" />
      <circle cx="217.202" cy="80.0475" r="11.2018" fill="#00A389" fillOpacity="0.2" />
      <circle cx="63.6554" cy="230.316" r="10.6554" fill="#00A389" fillOpacity="0.2" />
      <circle cx="254.358" cy="219.933" r="10.6554" fill="#00A389" fillOpacity="0.2" />
      <circle cx="233.597" cy="231.955" r="5.19107" fill="#00A389" fillOpacity="0.2" />
      <path
        d="M167.638 87.7177C167.321 87.6503 167.321 87.1981 167.638 87.1308L172.428 86.1122C172.543 86.0877 172.633 85.9977 172.658 85.8824L173.69 81.1263C173.758 80.8111 174.208 80.8111 174.276 81.1263L175.307 85.8824C175.332 85.9977 175.423 86.0877 175.538 86.1122L180.328 87.1308C180.645 87.1981 180.645 87.6503 180.328 87.7177L175.538 88.7362C175.423 88.7608 175.332 88.8507 175.307 88.9661L174.276 93.7221C174.208 94.0374 173.758 94.0374 173.69 93.7221L172.658 88.9661C172.633 88.8507 172.543 88.7608 172.428 88.7362L167.638 87.7177Z"
        fill="#00A389"
      />
      <path
        d="M50.9392 172.461C51.0615 171.883 51.8861 171.883 52.0084 172.461L53.835 181.091C53.8795 181.301 54.0432 181.466 54.2533 181.511L62.7937 183.372C63.3672 183.497 63.3672 184.315 62.7937 184.44L54.2533 186.3C54.0432 186.346 53.8795 186.511 53.835 186.721L52.0084 195.35C51.8861 195.928 51.0615 195.928 50.9392 195.35L49.1126 186.721C49.0681 186.511 48.9044 186.346 48.6943 186.3L40.1539 184.44C39.5804 184.315 39.5804 183.497 40.1539 183.372L48.6943 181.511C48.9044 181.466 49.0681 181.301 49.1126 181.091L50.9392 172.461Z"
        fill="#00A389"
      />
      <path
        d="M92.0483 58.7741C92.1163 58.4532 92.5744 58.4532 92.6423 58.7741L93.657 63.5681C93.6818 63.6849 93.7727 63.7764 93.8894 63.8018L98.6338 64.8354C98.9524 64.9048 98.9524 65.3592 98.6338 65.4286L93.8894 66.4621C93.7727 66.4876 93.6818 66.579 93.657 66.6959L92.6423 71.4898C92.5744 71.8107 92.1163 71.8107 92.0483 71.4898L91.0336 66.6959C91.0089 66.579 90.9179 66.4876 90.8012 66.4621L86.0568 65.4286C85.7382 65.3592 85.7382 64.9048 86.0568 64.8354L90.8012 63.8018C90.9179 63.7764 91.0089 63.6849 91.0336 63.5681L92.0483 58.7741Z"
        fill="#00A389"
      />
      <path
        d="M237.337 106.344C237.46 105.766 238.284 105.766 238.407 106.344L239.472 111.378C239.517 111.589 239.68 111.753 239.89 111.799L244.887 112.887C245.461 113.012 245.461 113.83 244.887 113.955L239.89 115.044C239.68 115.089 239.517 115.254 239.472 115.464L238.407 120.499C238.284 121.076 237.46 121.076 237.337 120.499L236.272 115.464C236.227 115.254 236.064 115.089 235.854 115.044L230.857 113.955C230.284 113.83 230.284 113.012 230.857 112.887L235.854 111.799C236.064 111.753 236.227 111.589 236.272 111.378L237.337 106.344Z"
        fill="#00A389"
      />
      <path
        d="M259.364 187.055L261.038 194.96L268.83 196.658L261.038 198.355L259.364 206.261L257.691 198.355L249.898 196.658L257.691 194.96L259.364 187.055Z"
        fill="white"
      />
      <path
        d="M141.435 74.7911C143.192 81.2739 139.093 88.1159 132.145 89.9991C125.197 91.8822 118.204 88.0468 116.446 81.5641C114.689 75.0813 118.789 68.2393 125.737 66.3562C132.685 64.473 139.678 68.3084 141.435 74.7911Z"
        fill="#00A389"
        stroke="#00A389"
        strokeWidth="2"
      />
      <path
        d="M124.738 78.2375L128.824 76.5847L136.444 74.5195C142.239 72.9487 148.321 72.7525 154.205 73.9464C156.392 74.39 158.535 75.0224 160.612 75.8365L168.329 78.8612L172.934 81.3446C176.425 83.2273 179.679 85.5204 182.626 88.1749C186.017 91.2288 188.972 94.7341 191.408 98.5924L195.814 105.57L204.471 124.197L210.444 137.36L219.889 152.978L227.205 165.178L232.798 173.203C234.508 175.657 237.066 177.391 239.98 178.069C242.176 178.581 244.185 179.696 245.781 181.288L248.543 184.045C248.92 184.42 249.26 184.831 249.559 185.27C251.33 187.87 251.509 191.238 250.026 194.012L249.838 194.363C248.853 196.204 247.264 197.651 245.339 198.461L232.301 203.947L212.536 210.902L177.716 221.2L157.864 226.581L129.999 233.273L105.133 238.414L91.3246 240.276C88.9424 240.597 86.5499 239.763 84.8844 238.03C83.375 236.459 82.6192 234.312 82.8117 232.142L83.037 229.604C83.2679 227.002 84.5564 224.608 86.6014 222.982C88.6132 221.383 89.8942 219.039 90.1543 216.483L91.414 204.095L91.414 187.8L89.3486 165.388L88.2511 149.505L87.6781 142.717C87.3354 138.657 87.3239 134.575 87.6438 130.513L87.7933 128.615C88.1542 124.032 89.0509 119.507 90.4651 115.132C91.7321 111.213 93.4072 107.437 95.4627 103.868L96.0572 102.835C98.0464 99.3809 100.401 96.1504 103.081 93.1995C105.84 90.1621 108.926 87.4393 112.284 85.0809L112.704 84.7858C116.453 82.1525 120.49 79.9555 124.738 78.2375Z"
        fill="#DDE2FF"
      />
      <path
        d="M124.73 78.2199L128.816 76.5672L136.436 74.5019C142.231 72.9311 148.313 72.7349 154.198 73.9289C156.384 74.3724 158.527 75.0048 160.604 75.8189L168.321 78.8437L172.926 81.327C176.417 83.2098 179.671 85.5028 182.618 88.1573C186.009 91.2112 188.964 94.7165 191.401 98.5748L195.806 105.552L204.463 124.18L210.436 137.343L219.881 152.96L227.197 165.16L232.168 171.961C234.36 174.961 237.582 177.047 241.217 177.82L241.549 177.891C243.872 178.385 246.029 179.471 247.811 181.043L248.853 181.962C250.265 183.208 251.266 184.851 251.725 186.677C252.185 188.504 252.081 190.428 251.425 192.194L251.38 192.318C251.141 192.961 250.834 193.578 250.466 194.158L250.277 194.455C249.056 196.375 247.355 197.942 245.342 199.002L241.93 200.8L235.446 204.215L217.267 212.411L204.972 217.329L194.317 221.427L185.301 224.433L176.012 227.711L168.088 229.897L162.624 231.263L147.871 234.268L133.937 237L107.708 240.77L99.6903 241.657C98.1192 241.831 96.5334 241.825 94.9634 241.641L90.9201 241.167C88.7261 240.91 86.6896 239.899 85.159 238.306C83.4534 236.531 82.5009 234.165 82.5009 231.704L82.5009 230.841C82.5009 230.213 82.5516 229.585 82.6527 228.965L82.7101 228.612C83.1563 225.872 84.3037 223.295 86.0409 221.13L87.2062 219.677C90.771 215.234 92.5737 209.634 92.2698 203.945L91.4062 187.782L89.3408 165.37L88.2433 149.488L87.6703 142.699C87.3276 138.639 87.3161 134.558 87.636 130.496L87.7855 128.597C88.1464 124.014 89.0431 119.489 90.4573 115.115C91.7243 111.195 93.3994 107.42 95.4549 103.85L96.0494 102.818C98.0386 99.3634 100.393 96.1329 103.073 93.182C105.832 90.1445 108.918 87.4217 112.276 85.0633L112.696 84.7682C116.445 82.1349 120.483 79.9379 124.73 78.2199Z"
        fill="url(#paint0_linear_23007_97320)"
        stroke="#00A389"
        strokeWidth="2"
      />
      <ellipse cx="166.072" cy="211.834" rx="74.6222" ry="13.0487" transform="rotate(-15.4595 166.072 211.834)" fill="#00A389" />
      <path
        d="M153.873 215.886L153.061 215.019C152.196 214.095 151.458 213.06 150.865 211.941C150.46 211.176 150.125 210.377 149.864 209.552L148.457 205.096L151.758 203.924L155.067 202.695L163.208 200.501L172.376 198.061L177.77 196.889L178.003 197.686C178.783 200.357 179.146 203.133 179.076 205.915L178.886 207.908C178.662 210.264 177.861 212.529 176.556 214.503L176.486 214.608C175.953 215.414 175.334 216.16 174.639 216.832C172.697 218.71 170.228 219.952 167.561 220.392L166.587 220.552C165.758 220.689 164.917 220.748 164.076 220.727C161.319 220.658 158.651 219.739 156.435 218.097L155.936 217.726C155.194 217.176 154.504 216.56 153.873 215.886Z"
        fill="#66C8B8"
        stroke="#00A389"
        strokeWidth="2"
      />
      <defs>
        <linearGradient id="paint0_linear_23007_97320" x1="178.677" y1="220.484" x2="134.143" y2="75.68" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="1" stop-color="#CCEDE7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotifIcon;
