import React from 'react';
import dayjs from 'dayjs';

import styles from './day.module.scss';
import CaretRightIcon from '@icons/CaretRightIcon';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { DATE_FORMAT } from '@utils/constants/format';
import { addDate, minusDate } from '@reducers/TaskCalendarViewSlice';
import { useTranslation } from 'react-i18next';

const Day = () => {
  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // handle add date
  const handleAddDate = () => {
    dispatch(addDate());
  };

  // handle delete date
  const handleDeleteDate = () => {
    dispatch(minusDate());
  };

  return (
    <div className={styles.container}>
      <CaretRightIcon onClick={handleDeleteDate} />
      <span>
        {t(`calendar_view.${dayjs(date, DATE_FORMAT).format('dddd')}`)} {dayjs(date, DATE_FORMAT).format('D')}
      </span>
      <CaretRightIcon onClick={handleAddDate} />
    </div>
  );
};

export default Day;
