import React, { FC, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import ReceiptItemIcon from '@icons/ReceiptItemIcon';
import { Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import CardPosBgIcon from '../../../../assets/icons/contract/CardPosBgIcon';
import ContractDelayIcon from '../../../../assets/icons/contract/ContractDelayIcon';
import DollorCircleBgIcon from '../../../../assets/icons/contract/DollorCircleBgIcon';
import MoneysBgIcon from '../../../../assets/icons/contract/MoneysBgIcon';
import SecurityCardBgIcon from '../../../../assets/icons/contract/SecurityCardBgIcon';
import Wallet3BgIcon from '../../../../assets/icons/contract/Wallet3BgIcon';
import GlphIcon from '../../../../assets/icons/GlphIcon';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { ContractId } from '../../../../utils/models/ContractId';

import styles from './contractIdPaymentContainer.module.scss';

interface IProps {
  data: ContractId | undefined;
  isLoading: boolean;
}

const ContractIdPaymentContainer: FC<IProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const [discountClassName, setDiscountClassName] = useState(false);

  function Loading(element: JSX.Element) {
    return isLoading ? (
      <>
        <LoadingOutlined />
      </>
    ) : (
      element
    );
  }

  const discountProcent = roundFloatNumber((Number(data?.discount) / Number(data?.amount)) * 100);

  return (
    <div className={styles.container}>
      <div className={styles.commonCard} onMouseLeave={() => setDiscountClassName(false)}>
        <div className={styles.container_item}>
          {data?.discount ? (
            <div className={discountClassName ? styles.discountTitleOpen : styles.discountTitle} onClick={() => setDiscountClassName(true)}>
              {!discountClassName && <span>{`${discountProcent}%`}</span>}
            </div>
          ) : (
            ''
          )}

          <div className={styles.container_item_top}>
            <DollorCircleBgIcon />
            <p>{t('one_task.Shartnoma_summasi')}</p>
          </div>
          <h1>
            {Loading(
              <>
                {roundFloatNumber(Number(data?.amount) - Number(data?.discount))?.toLocaleString()}
                {data?.currency?.ccy}
              </>
            )}
            {data?.discount ? <h4>{`${data?.amount?.toLocaleString()} ${data?.currency?.ccy}`}</h4> : ''}
          </h1>
        </div>
        <div className={discountClassName ? styles.descriptionOpen : styles.description}>
          <p>
            {t('home.Asl_narx')}:
            <b>
              {data?.amount?.toLocaleString('ru')} {data?.currency?.ccy}
            </b>
          </p>
          <p>
            {t('titles.discount')}:
            <b>
              {data?.discount?.toLocaleString('ru')} {data?.currency?.ccy}
            </b>
            <div>{`${discountProcent}%`}</div>
          </p>

          {data?.discountDescription && (
            <h3>
              <p>{t('contract.Izoh')}:</p>
              <span>
                {data?.discountDescription?.length! >= 100 ? (
                  <Tooltip overlayInnerStyle={{ width: 400 }} overlay={data?.discountDescription}>{`${data?.discountDescription?.substring(0, 100)}...`}</Tooltip>
                ) : (
                  data?.discountDescription
                )}
              </span>
            </h3>
          )}
        </div>
      </div>
      <div className={styles.container_item}>
        <div className={styles.container_item_top}>
          <CardPosBgIcon />
          <p>{t('contract_id.Birinchi_tolov')}</p>
        </div>
        <h1>
          {Loading(
            <>
              {data?.prepayment?.toLocaleString()} {data?.currency?.ccy}
            </>
          )}
        </h1>
      </div>
      <div className={styles.container_item}>
        <div className={styles.container_item_top}>
          <ContractDelayIcon />
          <p>
            {t('boss_contract.Shartnoma_muddati')}
            <Dropdown
              overlay={
                <div className={styles.container_item_content}>
                  <p>
                    To’lov qilingan
                    <b>
                      {data?.contractDelay?.PAID} {t('home_id.oy')}
                    </b>
                  </p>
                  <p>
                    To’lov qilinadi{' '}
                    <b>
                      {data?.contractDelay?.NOT_PAID} {t('home_id.oy')}
                    </b>
                  </p>
                  <h3>
                    Shartnoma yaratildi <span>{data?.createdDate}</span>
                  </h3>
                </div>
              }
              trigger={['click', 'hover']}
              placement="topCenter"
              mouseEnterDelay={1000}
              mouseLeaveDelay={0.3}
            >
              <span className={styles.container_item_info}>
                <GlphIcon />
              </span>
            </Dropdown>
          </p>
        </div>
        <h1>
          {Loading(
            <>
              {data?.contractDelay?.TOTAL} {t('home_id.oy')}
            </>
          )}
        </h1>
      </div>
      <div className={styles.container_item}>
        <div className={styles.container_item_top}>
          <SecurityCardBgIcon />
          <p>{t('contract_id.Tolangan_summa')}</p>
        </div>
        <h1>
          {Loading(
            <>
              {data?.payed?.toLocaleString()} {data?.currency?.ccy}
            </>
          )}
        </h1>
      </div>
      <div className={styles.container_item}>
        <div className={styles.container_item_top}>
          <MoneysBgIcon />
          <p>{t('contract_id.Qolgan_summa')}</p>
        </div>
        <h1>
          {Loading(
            <>
              {data?.residue?.toLocaleString()} {data?.currency?.ccy}
            </>
          )}
        </h1>
      </div>
      <div className={styles.container_item}>
        <div className={styles.container_item_top}>
          <Wallet3BgIcon />
          <p>{t('contract_id.Oylik_qarzdorlik')}</p>
        </div>
        <h1>
          {Loading(
            <>
              {data?.debt?.toLocaleString()} {data?.currency?.ccy}
            </>
          )}
        </h1>
      </div>
      {data?.mortgage ? (
        <div className={styles.container_item}>
          <div className={styles.container_item_top}>
            <span>
              <ReceiptItemIcon width={28} height={28} />
            </span>
            <p>
              {t('contract.Ipoteka_summasi')}{' '}
              <Dropdown
                overlay={
                  <div className={styles.container_item_content}>
                    <p>
                      To’lov qilingan
                      <b>
                        {data?.mortgagePayed?.toLocaleString('ru')} {data?.currency?.ccy}
                      </b>
                    </p>
                    <p>
                      To’lov qilinadi{' '}
                      <b>
                        {data?.mortgageResidue?.toLocaleString('ru')} {data?.currency?.ccy}
                      </b>
                    </p>
                    <h3>
                      Ipoteka muddati <span>{data?.mortgageDate}</span>
                    </h3>
                  </div>
                }
                trigger={['click', 'hover']}
                placement="topCenter"
                mouseEnterDelay={1000}
                mouseLeaveDelay={0.3}
              >
                <span className={styles.container_item_info}>
                  <GlphIcon />
                </span>
              </Dropdown>
            </p>
          </div>
          <h1>
            {Loading(
              <>
                {data?.mortgage?.toLocaleString()} {data?.currency?.ccy}
              </>
            )}
          </h1>
        </div>
      ) : null}
    </div>
  );
};

export default ContractIdPaymentContainer;
