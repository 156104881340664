import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import BaseDatePicker, { DatePickerProps } from '@service/datePicker';
import { cx } from '@utils/cx';
import dayjs from 'dayjs';
import IMask, { FactoryArg } from 'imask';

import RangePicker from './RangePicker';

type dateFormat = 'DD.MM.YYYY' | 'HH:mm' | 'DD.MM.YYYY HH:mm' | (string & {});

type Additional = {
  onChange?: (value: any) => void;
  returnType?: 'unix' | 'unix-seconds' | dateFormat;
  format?: dateFormat;
  value?: string | number;
  dateOf?: { start: true; startOf: dayjs.OpUnitType } | { start: false; endOf: dayjs.OpUnitType };
};

type IProps = (DatePickerProps & Additional) | (Omit<DatePickerProps, 'format' | 'onChange' | 'value'> & Additional);

const DatePicker = ({ format = 'DD.MM.YYYY', ...args }: IProps) => {
  const { returnType = format as Additional['returnType'], dateOf, ...props } = args;
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const imaskRef = useRef<ReturnType<typeof IMask> | null>(null);

  const maskOptions: FactoryArg = {
    mask:
      format
        .split('')
        .map((item, i) => (item.charCodeAt(0) >= 65 && item.charCodeAt(0) <= 122 ? '0' : item))
        .join('') || '',
    lazy: true,
    placeholderChar: '_'
  };

  const onChange = (d: dayjs.Dayjs | null) => {
    setDate(d);

    let dCopy = d;

    if (dCopy && dateOf?.start) {
      dCopy = dCopy?.startOf(dateOf?.startOf);
    }

    if (dCopy && !dateOf?.start) {
      dCopy = dCopy?.endOf(dateOf?.endOf!);
    }

    if (returnType === 'unix') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onChange && props?.onChange(dCopy?.valueOf());
    } else if (returnType === 'unix-seconds') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onChange && props?.onChange(dCopy?.unix());
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onChange && props?.onChange(dCopy?.format(returnType));
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const d = dayjs(value, format || undefined);

    if (value?.length === format.length) {
      onChange(d);
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current!;

    imaskRef.current = IMask(inputElement, maskOptions);

    return () => {
      imaskRef.current?.destroy();
    };
  }, [imaskRef]);

  useEffect(() => {
    if (props?.value) {
      if (typeof props?.value === 'string') {
        const d = format
          .split('')
          // @ts-expect-error
          .map((item, i) => props?.value![i] || (item.charCodeAt(0) >= 65 && item.charCodeAt(0) <= 122 ? '0' : item))
          .join('');

        onChange(dayjs(d, format || undefined));
      } else if (typeof props?.value === 'number') {
        onChange(dayjs.unix(props?.value));
      } else {
        onChange(props?.value);
      }
    }
  }, [props.value, format]);

  return (
    <BaseDatePicker
      {...props}
      className={cx('customInput', props.className)}
      onChange={onChange}
      value={date}
      format={format}
      inputRender={inputProps => <input {...inputProps} ref={inputRef} onKeyUp={onKeyUp} />}
    />
  );
};

DatePicker.RangePicker = RangePicker;
export default DatePicker;
