import React from 'react';

const CompleteIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1 1.25H12.9C8.99 1.25 7.25 2.99 7.25 6.9V8C7.25 8.41 7.59 8.75 8 8.75H11.1C14.2 8.75 15.25 9.8 15.25 12.9V16C15.25 16.41 15.59 16.75 16 16.75H17.1C21.01 16.75 22.75 15.01 22.75 11.1V6.9C22.75 2.99 21.01 1.25 17.1 1.25ZM8.75 7.25V6.9C8.75 3.8 9.8 2.75 12.9 2.75H17.1C20.2 2.75 21.25 3.8 21.25 6.9V11.1C21.25 14.2 20.2 15.25 17.1 15.25H16.75V12.9C16.75 8.99 15.01 7.25 11.1 7.25H8.75Z"
        fill="white"
      />
      <path
        d="M11.1 7.25H6.9C2.99 7.25 1.25 8.99 1.25 12.9V17.1C1.25 21.01 2.99 22.75 6.9 22.75H11.1C15.01 22.75 16.75 21.01 16.75 17.1V12.9C16.75 8.99 15.01 7.25 11.1 7.25ZM6.9 21.25C3.8 21.25 2.75 20.2 2.75 17.1V12.9C2.75 9.8 3.8 8.75 6.9 8.75H11.1C14.2 8.75 15.25 9.8 15.25 12.9V17.1C15.25 20.2 14.2 21.25 11.1 21.25H6.9Z"
        fill="white"
      />
      <path
        d="M8.02953 17.7C7.83953 17.7 7.64953 17.63 7.49953 17.48L5.54953 15.53C5.25953 15.24 5.25953 14.76 5.54953 14.47C5.83953 14.18 6.31953 14.18 6.60953 14.47L8.02953 15.89L11.3895 12.53C11.6795 12.24 12.1595 12.24 12.4495 12.53C12.7395 12.82 12.7395 13.3 12.4495 13.59L8.54953 17.48C8.40953 17.62 8.21953 17.7 8.02953 17.7Z"
        fill="white"
      />
    </svg>
  );
};

export default CompleteIcon;
