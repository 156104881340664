import React from 'react';

interface Props {
  className?: string;
}

const MessageBlueIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5866 8H9.05329C8.70663 8 8.37329 8.01333 8.05329 8.05333C4.46663 8.36 2.66663 10.48 2.66663 14.3867V19.72C2.66663 25.0533 4.79996 26.1067 9.05329 26.1067H9.58663C9.87996 26.1067 10.2666 26.3067 10.44 26.5333L12.04 28.6667C12.7466 29.6133 13.8933 29.6133 14.6 28.6667L16.2 26.5333C16.4 26.2667 16.72 26.1067 17.0533 26.1067H17.5866C21.4933 26.1067 23.6133 24.32 23.92 20.72C23.96 20.4 23.9733 20.0667 23.9733 19.72V14.3867C23.9733 10.1333 21.84 8 17.5866 8ZM8.66663 18.6667C7.91996 18.6667 7.33329 18.0667 7.33329 17.3333C7.33329 16.6 7.93329 16 8.66663 16C9.39996 16 9.99996 16.6 9.99996 17.3333C9.99996 18.0667 9.39996 18.6667 8.66663 18.6667ZM13.32 18.6667C12.5733 18.6667 11.9866 18.0667 11.9866 17.3333C11.9866 16.6 12.5866 16 13.32 16C14.0533 16 14.6533 16.6 14.6533 17.3333C14.6533 18.0667 14.0666 18.6667 13.32 18.6667ZM17.9866 18.6667C17.24 18.6667 16.6533 18.0667 16.6533 17.3333C16.6533 16.6 17.2533 16 17.9866 16C18.72 16 19.32 16.6 19.32 17.3333C19.32 18.0667 18.72 18.6667 17.9866 18.6667Z"
        fill="#5961F9"
      />
      <path
        d="M29.3067 9.05268V14.386C29.3067 17.0527 28.48 18.866 26.8267 19.866C26.4267 20.106 25.96 19.786 25.96 19.3193L25.9734 14.386C25.9734 9.05268 22.92 5.99935 17.5867 5.99935L9.46671 6.01268C9.00004 6.01268 8.68004 5.54602 8.92004 5.14602C9.92004 3.49268 11.7334 2.66602 14.3867 2.66602H22.92C27.1734 2.66602 29.3067 4.79935 29.3067 9.05268Z"
        fill="#5961F9"
      />
    </svg>
  );
};

export default MessageBlueIcon;
