import { useEffect, useState } from 'react';

const useNetworkStatus: () => boolean = () => {
  const [status, setStatus] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setStatus(true));
    window.addEventListener('offline', () => setStatus(false));
    return () => {
      window.removeEventListener('online', () => setStatus(true));
      window.removeEventListener('offline', () => setStatus(false));
    };
  }, []);

  return status;
};

export default useNetworkStatus;
