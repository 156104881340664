import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const BookSquareIcon: FC<IProps> = ({ color = '#13927D', onClick, className }) => {
  return (
    <svg onClick={onClick} className={className} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={color}
      />
      <path
        d="M12.0001 18.9501C11.7601 18.9501 11.5201 18.8901 11.3201 18.7801C10.1601 18.1501 8.11013 17.4701 6.82013 17.3001L6.63013 17.2701C5.65013 17.1501 4.87012 16.2701 4.87012 15.2601V7.56009C4.87012 6.99009 5.11014 6.44009 5.52014 6.06009C5.94014 5.68009 6.48012 5.4901 7.06012 5.5401C8.52012 5.6601 10.6401 6.3601 11.8901 7.1401L11.9901 7.2001L12.0302 7.1701C13.2902 6.3801 15.4101 5.6701 16.8801 5.5401C16.9001 5.5401 16.9601 5.5401 16.9801 5.5401C17.5001 5.4901 18.0601 5.69009 18.4701 6.06009C18.8901 6.44009 19.1201 6.97008 19.1201 7.55008V15.2401C19.1201 16.2401 18.3401 17.1201 17.3501 17.2501L17.1401 17.2801C15.8601 17.4501 13.8001 18.1301 12.6601 18.7601C12.4701 18.9001 12.2401 18.9501 12.0001 18.9501ZM6.89014 7.05008C6.75014 7.05008 6.6301 7.10008 6.5401 7.18008C6.4301 7.28008 6.38013 7.4201 6.38013 7.5701V15.2701C6.38013 15.5101 6.60014 15.7601 6.83014 15.7901L7.02014 15.8201C8.48014 16.0201 10.7201 16.7501 12.0201 17.4601C13.2301 16.7601 15.4801 16.0101 16.9501 15.8201L17.1701 15.7901C17.4001 15.7601 17.6201 15.5101 17.6201 15.2701V7.58007C17.6201 7.42007 17.5601 7.29009 17.4601 7.19009C17.3501 7.09009 17.2101 7.05009 17.0501 7.06009C17.0301 7.06009 16.9701 7.06009 16.9501 7.06009C15.7901 7.16009 13.8801 7.80008 12.8401 8.46008L12.7401 8.52008C12.3001 8.79008 11.6801 8.79009 11.2601 8.53009L11.1101 8.44009C10.0601 7.78009 8.15012 7.15008 6.94012 7.05008C6.92012 7.05008 6.91014 7.05008 6.89014 7.05008Z"
        fill={color}
      />
      <path
        d="M12 18.4101C11.59 18.4101 11.25 18.0701 11.25 17.6601V8.1001C11.25 7.6901 11.59 7.3501 12 7.3501C12.41 7.3501 12.75 7.6901 12.75 8.1001V17.6601C12.75 18.0801 12.41 18.4101 12 18.4101Z"
        fill={color}
      />
    </svg>
  );
};

export default BookSquareIcon;
