import { useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import ProfileAddIcon from '@icons/ProfileAddIcon';
import TrushWhiteIcon from '@icons/TrushWhiteIcon';
import ChangeEmployeModal from '../action-models/ChangeEmployeModal';
import CompleteIcon from '@icons/CompleteIcon';
import ChangeTimeIcon from '@icons/ChangeTimeIcon';
import ChangeTypeIcon from '@icons/ChangeTypeIcon';
import ChangeTypeModal from '../action-models/ChangeTypeModal';
import ChangeTimeModal from '../action-models/ChangeTimeModal';
import DeleteTasksModal from '../action-models/DeleteTasksModal';
import CompleteTaskModal from '../action-models/CompleteTaskModal';

import styles from './taskActions.module.scss';

type Props = {
  totalElements: number | undefined;
};

const TaskActions: React.FC<Props> = ({ totalElements }) => {
  const [openEmployeeChange, setOpenEmployeeChange] = useState(false);
  const [openTaskChange, setOpenTaskChange] = useState(false);
  const [openDeleteLead, setOpenDeleteLead] = useState(false);
  const [openTimeChange, setOpenTimeChange] = useState(false);
  const [openComplate, setOpenComplate] = useState(false);

  const { taskListViewActiveIds: activeIds, checked, taskListViewIgnoreIds } = useAppSelector(state => state.assigmentSlice);

  const { t } = useTranslation();

  const idsLength = checked
    ? checked && taskListViewIgnoreIds.length > 0
      ? Number(totalElements) - taskListViewIgnoreIds.length
      : totalElements
    : activeIds?.length;

  // handleChangeEmployee
  const handleChangeEmployee = (visible: boolean) => {
    setOpenEmployeeChange(visible);
  };

  // handleDeleteLead
  const handleDeleteLead = (visible: boolean) => {
    setOpenDeleteLead(visible);
  };

  // handleChangeTime
  const handleChangeTime = (visible: boolean) => {
    setOpenTimeChange(visible);
  };

  // handleChangeTypes
  const handleChangeTypes = (visible: boolean) => {
    setOpenTaskChange(visible);
  };

  // handleComplate
  const handleComplate = (visible: boolean) => {
    setOpenComplate(visible);
  };

  if (activeIds?.length === 0) return null;

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.container_count}>
          {idsLength} {t('list_view.ta_belgilangan')}
        </span>
      </div>
      <div>
        <span className={styles.container_icon}>
          <Tooltip title={<span>{t('one_task.Topshiriqni_yakunlash')}</span>} placement={'bottom'}>
            <span onClick={() => handleComplate(true)}>
              <CompleteIcon />
            </span>
          </Tooltip>
        </span>
        <span className={styles.container_icon}>
          <Tooltip title={<span>{t('crm.Ochirish')}</span>} placement={'bottom'}>
            <i onClick={() => handleDeleteLead(true)}>
              <TrushWhiteIcon />
            </i>
          </Tooltip>
        </span>
        <span className={styles.container_icon}>
          <Tooltip title={<span>{t('list_view.Masul_shaxsni_ozgartirish')}</span>} placement={'bottom'}>
            <span onClick={() => handleChangeEmployee(true)}>
              <ProfileAddIcon />
            </span>
          </Tooltip>
        </span>
        <span className={styles.container_icon}>
          <Tooltip
            title={<span className={styles.container_tool_tip_title}>{t('one_task.Topshiriq_vaqtini_ozgartirish')}</span>}
            placement={'bottom'}
          >
            <span onClick={() => handleChangeTime(true)}>
              <ChangeTimeIcon />
            </span>
          </Tooltip>
        </span>
        <span className={styles.container_icon}>
          <Tooltip title={<span className={styles.container_tool_tip_title}>{t('one_task.Topshiriq_turini_ozgartirish')}</span>} placement={'bottom'}>
            <span onClick={() => handleChangeTypes(true)}>
              <ChangeTypeIcon />
            </span>
          </Tooltip>
        </span>
      </div>
      <ChangeEmployeModal openEmployeeChange={openEmployeeChange} setOpenEmployeeChange={setOpenEmployeeChange} />
      <ChangeTypeModal openEmployeeChange={openTaskChange} setOpenEmployeeChange={setOpenTaskChange} />
      <ChangeTimeModal openEmployeeChange={openTimeChange} setOpenEmployeeChange={setOpenTimeChange} />
      <DeleteTasksModal openEmployeeChange={openDeleteLead} setOpenEmployeeChange={setOpenDeleteLead} />
      <CompleteTaskModal openEmployeeChange={openComplate} setOpenEmployeeChange={setOpenComplate} />
    </div>
  );
};

export default TaskActions;
