import { baseErrorHandler } from '@queries/baseErrorHandler';
import { $api } from '@service/RequestService';
import { Pagination } from '@utils/models/Pagination';
import { useInfiniteQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { MessageSMSBot } from '../utils/models/MessageSMSBotType';

export function useGetMessageSMSBot(id: number) {
  return useInfiniteQuery<Pagination<MessageSMSBot>>(
    [queryKeys.GET_MSG_SMSBOT],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(`${endPoints.GET_MSG_SMSBOT}/${id}?size=10&page=${pageParam}`);

      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      enabled: !!id && String(id) !== 'new',
      refetchInterval: Infinity,
      retry: false
    }
  );
}

// export function useTaskCRMFilter(
//     size: number = 10,
//     searchParams: SearchParamsTask,
//     startDate: string | null = null,
//     finishDate: string | null = null
// ) {
//     return useInfiniteQuery<Pagination<TaskFilter>>(
//         [
//             queryNames.TASK_FILTER_CRM,
//             size,
//             JSON.stringify(searchParams),
//             startDate,
//             finishDate,
//         ],
//         async ({ pageParam = 1 }) => {
//             const res = await $api.post(leadTaskEndpoints.FILTER, {
//                 ...getTaskBody(searchParams),
//                 page: pageParam,
//                 size: size,
//                 ...(startDate && { startDate: startDate }),
//                 ...(finishDate && { finishDate: finishDate }),
//             })
//             return res.data.data?.leadTaskList
//         },
//         {
//             getNextPageParam: (lastPage, page) => {
//                 if (page.length < lastPage.totalPages) {
//                     return page.length + 1
//                 } else {
//                     return undefined
//                 }
//             },
//             refetchOnWindowFocus: false,
//             onError: baseErrorHandler,
//             retry: false,
//         }
//     )
// }
