import React from 'react';

interface Props {
  className?: string;
}

const CheckedYellowIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 2.66602C8.65329 2.66602 2.66663 8.65268 2.66663 15.9993C2.66663 23.346 8.65329 29.3327 16 29.3327C23.3466 29.3327 29.3333 23.346 29.3333 15.9993C29.3333 8.65268 23.3466 2.66602 16 2.66602ZM22.3733 12.9327L14.8133 20.4927C14.6266 20.6793 14.3733 20.786 14.1066 20.786C13.84 20.786 13.5866 20.6793 13.4 20.4927L9.62663 16.7193C9.23996 16.3327 9.23996 15.6927 9.62663 15.306C10.0133 14.9193 10.6533 14.9193 11.04 15.306L14.1066 18.3727L20.96 11.5193C21.3466 11.1327 21.9866 11.1327 22.3733 11.5193C22.76 11.906 22.76 12.5327 22.3733 12.9327Z"
        fill="#FFB400"
      />
    </svg>
  );
};

export default CheckedYellowIcon;
