import React, { useState } from 'react';
import { Dropdown } from 'antd';
import styles from '../triggers.module.scss';
import CheckmarkIcon from '../../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import CheveronBottom from '../../../../../../../assets/icons/CheveronBottom';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { useCrmEmployee } from '../../../../../../../queries/queries';
import { setAssignById } from '../../../../../../../store/reducers/TriggerSlice';
import { useTranslation } from 'react-i18next';

const Responsible = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { data } = useCrmEmployee();

  const { t } = useTranslation();

  const assignById = useAppSelector(state => state.triggerSlice.assignById);

  const dispatch = useAppDispatch();

  // handle change responsible
  const handleResponsible = (res: number | null) => {
    dispatch(setAssignById(res));
    handleOpenDropdown(false);
  };

  // handle open dropdown
  const handleOpenDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  // return label
  const returnLabel = () => {
    if (assignById === null) return t('triggers.Joriy_masul_xodim');
    const emp = data?.find(emp => emp.id === assignById);
    if (emp) return emp.fullName;
    return '';
  };

  return (
    <Dropdown
      visible={openDropdown}
      onVisibleChange={handleOpenDropdown}
      placement={'bottom'}
      overlayClassName={styles.container_body_item_dropdown}
      overlay={
        <>
          <div onClick={() => handleResponsible(null)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.Joriy_masul_xodim')}</span>
            {assignById === null && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
          {data?.map((emp, index) => (
            <div onClick={() => handleResponsible(emp.id)} key={index} className={styles.container_body_item_dropdown_item}>
              <span className={styles.container_body_item_dropdown_item_name}>{emp.fullName}</span>
              {assignById === emp.id && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
            </div>
          ))}
        </>
      }
      trigger={['click']}
    >
      <div onClick={() => handleOpenDropdown(true)} className={styles.container_body_item}>
        <div className={styles.container_body_item_title}>
          {t('triggers.Masul_xodim')}: {returnLabel()}
        </div>
        <CheveronBottom className={styles.container_body_item_select} />
      </div>
    </Dropdown>
  );
};

export default Responsible;
