import React from 'react';

const SuccessfullIcon = () => (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#3772FF" fillOpacity="0.1" />
      <path
        d="M27.75 31H20.25C15.25 31 14 30 14 26V22C14 18 15.25 17 20.25 17H27.75C32.75 17 34 18 34 22V26C34 30 32.75 31 27.75 31Z"
        stroke="#3772FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3789 22.0002C18.3789 23.4436 18.3789 24.4364 18.3789 26.0002M22 22C22 23.4435 22 24.4362 22 26M25.5 22.0002C25.5 23.4436 25.5 24.4364 25.5 26.0002M29 22.0002C29 23.4436 29 24.4364 29 26.0002"
        stroke="#3772FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default SuccessfullIcon;
