// import DatePicker from '@service/datePicker';
import { Form } from 'antd';
import { DatePicker, Input } from 'global';
import { useTranslation } from 'react-i18next';

const CreateBrojectCollection = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-2 gap-[16px] rounded-[16px] border-[1px] border-solid border-[#EAECF0] p-[16px]">
        <Form.Item label={t('home.Nomi')} name="name" rules={[{ required: true, message: '' }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('home.Boshlanish_vaqti')} name="startDate" rules={[{ required: true, message: '' }]}>
          <DatePicker className="w-full" format="DD.MM.YYYY HH:mm" showTime />
        </Form.Item>
      </div>
    </div>
  );
};

export default CreateBrojectCollection;
