import styles from './filteringYearPicker.module.scss';
import dayjs from 'dayjs';
import DatePicker from '@service/datePicker';

const FilteringYearPicker = ({ setChartsFilteringStartDateAndEndDate }: any) => {
  const onChange = (value: dayjs.Dayjs | null, dateString: string) => {
    setChartsFilteringStartDateAndEndDate({
      startDate: dateString,
      endDate: dateString
    });
  };

  return <DatePicker defaultValue={dayjs(new Date())} className={styles.purchase_date_picker} onChange={onChange} picker="year" clearIcon={null} />;
};

export default FilteringYearPicker;
