import { DateRangePicker, Input, Mask } from '@components/fields';
import { Button } from 'antd';
import { InputMaskOptions } from 'antd-mask-input/build/main/lib/MaskedInput';

import { Form, useAction } from 'modules/multi-payment';

const TermDiscountCreate = () => {
  const { closeAction, getActionData, action } = useAction();

  const defaultValues = action && getActionData ? getActionData[action] : undefined;

  return (
    <div>
      <Form.TermDiscount defaultValues={defaultValues as any}>
        {({ control, formState: { isSubmitting } }) => (
          <div className="flex flex-col gap-[16px]">
            <Input control={control} name="name" label="Nomi" />
            <div className="flex gap-[12px]">
              <Mask maskType="float" maskOptions={{ thousandsSeparator: '', max: 100 } as InputMaskOptions} control={control} name="percentage" label="Chegirma" />
              <DateRangePicker control={control} name="date" label="Muddat" />
            </div>
            <br />
            <div className="flex justify-end gap-[12px]">
              <Button className="h-[44px] w-[190px] rounded-[30px] border-[#D0D5DD]" onClick={closeAction}>
                Bekor qilish
              </Button>
              <Button className="h-[44px] w-[190px] rounded-[30px]" type="primary" htmlType="submit" loading={isSubmitting}>
                Saqlash
              </Button>
            </div>
          </div>
        )}
      </Form.TermDiscount>
    </div>
  );
};

export default TermDiscountCreate;
