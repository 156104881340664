/* eslint-disable no-nested-ternary */
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import ArrowDownBlackIcon from '@icons/ArrowDownBlackIconShowroom';
import ArrowDownTopIcon from '@icons/ArrowDownTopIcon';
// import { useSmartCatalogFlat } from '@pages/showroom-v2/services/queries';
import { showroomPath } from '@pages/showroom-v2/utils/constants/showroomPath';
import { clear } from '@reducers/CreateContractSlice';
import { setShowroomApartments, setShowroomDrawer } from '@reducers/ShowroomSlice';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL_VISUAL } from '@service/RequestService';
import { defaultImage } from '@utils/constants/defaultImage';
import { FlatStatisticsDtoList } from '@utils/models/FlatStatistics';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useWebsiteBlockFlatStatistic, useWebsiteBlockFloorEmptyFlatCount } from '../../services/queries';
import { showroomVisualPath } from '../../utils/constants/showroomVisualPath';
import { IWebsiteFlatStatisticsDtoList } from '../../utils/models/IWebsiteFlatStatistics';

import styles from './showroomVisualFloor.module.scss';

const ShowroomVisualFloor = () => {
  const { drawer, apartments, apartmentActiveIndex } = useAppSelector(state => state.showroomSlice);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const params = useParams();
  const { data } = useWebsiteBlockFlatStatistic(Number(params.block), Number(params.floor));
  const { data: floors } = useWebsiteBlockFloorEmptyFlatCount(Number(params.block));
  const [imageSize, setImageSize] = useState({ width: 566, height: 566 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [flatDropdownVisible, setFlatDropdownVisible] = useState(false);
  const [activeFlat, setActiveFlat] = useState<FlatStatisticsDtoList | undefined>();

  // console.log('apartments', apartments);
  // console.log('drawer', drawer);
  // console.log('apartmentActiveIndex', apartmentActiveIndex);

  const loadImage = (e: any) => {
    setImageSize({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight
    });
  };

  const mouseMoveFlat = (e: React.MouseEvent<HTMLElement, MouseEvent>, item: FlatStatisticsDtoList) => {
    setFlatDropdownVisible(true);
    setActiveFlat(item);
    setPosition({ x: e.clientX + 32, y: e.clientY - 32 });
  };

  const mouseOutFlat = () => {
    setFlatDropdownVisible(false);
  };

  const flatStatus = {
    BOOKED: 'Booked',
    RESERVE: 'Booked',
    SALE: `${activeFlat?.flatRooms}X`,
    SOLD: 'Sotilgan'
  };

  const handleClick = (flat: IWebsiteFlatStatisticsDtoList) => {
    if (
      ((apartments?.length === 0 || !apartments.some(item => item.id === flat?.flatId)) && flat?.flatStatus === 'SALE') ||
      (!apartments.some(item => item.id === flat?.flatId) && apartments?.length === 1 && flat?.flatStatus === 'BOOKED')
    ) {
      dispatch(
        setShowroomApartments([
          ...apartments.map((item, index) =>
            index === apartmentActiveIndex
              ? {
                  id: flat?.flatId,
                  number: flat?.flatRooms,
                  rooms: flat?.flatRooms,
                  status: flat?.flatStatus
                }
              : item
          )
        ])
      );
      dispatch(clear());
    }
    dispatch(setShowroomDrawer({ id: flat?.flatId, visible: true, floor: null }));

    console.log('DUK NUKEM');
  };

  const maxEmptyFlatCount = useMemo(() => {
    if (floors && floors?.length > 0) {
      return floors?.reduce((prev, current) => (current?.emptyFlatCount > prev ? (prev = current?.emptyFlatCount) : prev), 0);
    }
    return 1;
  }, [floors]);

  return (
    <>
      <span className={styles.back} onClick={() => window.history.back()}>
        <ArrowDownBlackIcon /> {t('my_calls.Ortga')}
      </span>
      <div className={styles.container}>
        <div className={styles.left}>
          <span className={styles.floorTopNext}>
            <ArrowDownTopIcon />
          </span>
          <Swiper
            className={styles.swiper}
            direction="vertical"
            modules={[Mousewheel, FreeMode, Navigation]}
            freeMode={true}
            mousewheel={true}
            spaceBetween={8}
            slidesPerView="auto"
            navigation={{
              prevEl: `.${styles.floorTopNext}`,
              nextEl: `.${styles.floorBottomNext}`
            }}
          >
            {floors?.map(item => {
              const emptyFlatCount = 64 / maxEmptyFlatCount;

              return (
                <SwiperSlide key={item?.floor}>
                  <Tooltip overlay={<span>{item?.emptyFlatCount}</span>}>
                    {' '}
                    <Link
                      to={`${rootPaths.SHOWROOM + showroomPath.VISUAL + showroomVisualPath.INDEX + params?.block}/${
                        item?.floor
                      }${window.location.search}`}
                      className={`${styles.swiper_slide} ${params.floor === String(item?.floor) ? styles.swiper_slide_active : ''}`}
                    >
                      <b>{item?.floor}</b>
                      <span
                        style={{
                          width: item?.emptyFlatCount * emptyFlatCount,
                          background: item?.emptyFlatCount === 0 ? '#D9D9D9' : ''
                        }}
                      />
                      {/* {params.floor === String(item?.floor) ? (
                      <span></span>
                    ) : (
                      ''
                    )} */}
                    </Link>
                  </Tooltip>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <span className={styles.floorBottomNext}>
            <ArrowDownTopIcon />
          </span>
        </div>
        <div className={styles.right}>
          <img
            src={data?.buildingFloorImagePath ? BASE_URL_VISUAL + data?.buildingFloorImagePath : defaultImage}
            alt=""
            onLoad={e => {
              loadImage(e);
            }}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
            xmlSpace="preserve"
            // preserveAspectRatio="xMinYMin meet"
            // preserveAspectRatio="xMinYMin slice"
          >
            {data?.buildingFloorImagePath &&
              data?.blockFlatDataDtoList.map(item => {
                const xArr =
                  item?.flatImage &&
                  item?.flatImage
                    .split(' ')
                    .map((item: any) => item.split(','))
                    .map((arr: any) => Number(arr[0]))
                    .filter((i: any) => !Number.isNaN(i) && i !== 0);
                const yArr =
                  item?.flatImage &&
                  item?.flatImage
                    .split(' ')
                    .map((item: any) => item.split(','))
                    .map((arr: any) => Number(arr[1]))
                    .filter((i: any) => !Number.isNaN(i) && i !== 0);

                const x = item?.flatImage && Math.max(...xArr) - (Math.max(...xArr) - Math.min(...xArr)) / 2;
                const y = item?.flatImage && Math.max(...yArr) - (Math.max(...yArr) - Math.min(...yArr)) / 2;

                // console.log(xArr, x);
                return (
                  <Link
                    to={window.location.pathname + window.location.search}
                    key={item.flatId}
                    onMouseMove={e => mouseMoveFlat(e, item)}
                    onMouseOut={e => mouseOutFlat()}
                    onClick={e => {
                      e.stopPropagation();
                      handleClick(item);
                    }}
                    style={{
                      // pointerEvents:
                      //   item.flatStatus === 'SALE' ? 'inherit' : 'none',
                      zIndex: item.flatStatus === 'SALE' ? '0' : '1'
                    }}
                  >
                    <polygon
                      points={item.flatImage}
                      fill={
                        item.flatStatus === 'SOLD'
                          ? 'rgba(30, 203, 137, 0.9)'
                          : item.flatStatus === 'BOOKED' || item.flatStatus === 'RESERVE'
                            ? 'rgba(249, 218, 141, 0.9)'
                            : 'rgba(91, 93, 101, 0.9)'
                      }
                      style={{
                        fill: item.flatId === drawer?.id && drawer?.visible ? '#f9da8d50' : ''
                      }}
                    />
                    {item.flatStatus === 'SOLD' ? (
                      <>
                        {/* <rect x={x - 25} y={y - 50} width="25" height="20" rx="7" fill="#ffffff" z={100000000000000} stroke="#008000" strokeWidth="1" /> */}
                        <text x={x - 27} y={y - 25} style={{ fontSize: 12, fontStyle: 'italic', fill: '#fff' }}>
                          №{item.flatNumber}
                        </text>
                        <rect x={x - 45} y={y - 15} width="70" height="20" rx="12.5" fill="#ffffff" />
                        <text x={x - 38} y={y - 1} style={{ fontSize: 14 }}>
                          {t('shaxmatka.Sotilgan')}
                        </text>
                      </>
                    ) : item.flatStatus === 'BOOKED' || item.flatStatus === 'RESERVE' ? (
                      <>
                        <rect x={x - 47} y={y + 4} width="45" height="20" rx="12.5" fill="#ffffff" />
                        <text x={x - 38} y={y + 17} style={{ fontSize: 14 }}>
                          Booked
                        </text>
                      </>
                    ) : (
                      <>
                        {/* <rect x={x + 12} y={y - 25} width="35" height="17" rx="8" fill="#ffffff" z={100000000000000} /> */}
                        <text x={x - 14} y={y - 19} style={{ fontSize: 12, fontStyle: 'italic', fill: '#fff' }}>
                          №{item.flatNumber}
                        </text>
                        <rect x={x - 7} y={y - 13} width="22" height="22" rx="50" fill="#A4A4A4" />
                        <text x={x - 6} y={y + 3} fontSize={13}>
                          {item.flatRooms}X
                        </text>
                      </>
                    )}
                  </Link>
                );
              })}
          </svg>
        </div>
      </div>
    </>
  );
};

export default ShowroomVisualFloor;
