import React from 'react';

const ReceiveSquareIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.50033 18.3334H12.5003C16.667 18.3334 18.3337 16.6667 18.3337 12.5001V7.50008C18.3337 3.33341 16.667 1.66675 12.5003 1.66675H7.50033C3.33366 1.66675 1.66699 3.33341 1.66699 7.50008V12.5001C1.66699 16.6667 3.33366 18.3334 7.50033 18.3334Z"
        stroke="#464255"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 9.59155L10 12.0916L12.5 9.59155" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 12.0917V5.42505" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 13.7583C8.24167 14.8416 11.7583 14.8416 15 13.7583" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ReceiveSquareIcon;
