import { t } from 'i18next';

import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';
import NavBar from '../../components/navbars/bossCrm/NavBar';
import BossCrmRouter from '../../routing/bossCrm/BossCrmRouter';

import styles from './bossCrm.module.scss';

const BossCrm = () => (
  <>
    <MainHeader>
      <h1 className={styles.header}>{t(`titles.crm`)}</h1>
      <NotificationButtons />
    </MainHeader>
    <div className={styles.wrapper}>
      <NavBar />
      <BossCrmRouter />
    </div>
  </>
);

export default BossCrm;
