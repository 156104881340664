import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { KpiItem } from '../../utils/models/Kpi';

import Sum from './sum/Sum';
import KpiType from './KpiType';

import styles from '../../pages/kpi/kpi.module.scss';

interface Props {
  kpi?: KpiItem[] | undefined;
}

const CalculateWithSum: React.FC<Props> = ({ kpi }) => {
  const defKpiArg = kpi?.find(item => item?.status === 'ACTIVE')?.argumentKpi;

  const [kpiArg, setKpiArg] = useState<string>(defKpiArg!);

  useEffect(() => {
    setKpiArg(defKpiArg!);
  }, [defKpiArg]);

  const item = kpi?.find(elem => elem?.argumentKpi === kpiArg);

  const argKpi = kpi?.map(item => item?.argumentKpi)?.reverse();

  return (
    <>
      <Row gutter={[48, 0]}>
        <Col className={styles.cont_body_left} span={8}>
          <KpiType
            title="Mukofot_summasi_ozgarmas"
            subtitle="Xodim_va_vositachilarni_ish_samaradorligini_ozgarmas_summalarda_ragbatlantirish"
            isActive={item?.status === 'ACTIVE'}
            id={item?.id || -1}
          />
        </Col>
        <Col span={16} key={item?.id}>
          <Sum kpi={item} argKpi={argKpi} setKpiArg={setKpiArg} kpiArg={kpiArg} />
        </Col>
      </Row>
    </>
  );
};

export default CalculateWithSum;
