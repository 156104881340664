import React from 'react';

const PlanFactIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16667 2.50002H6.5C5.09987 2.50002 4.3998 2.50002 3.86502 2.7725C3.39462 3.01219 3.01217 3.39464 2.77248 3.86504C2.5 4.39982 2.5 5.09989 2.5 6.50002V13.5C2.5 14.9002 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9879 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9879 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9002 17.5 13.5V10.8334M10 6.66669H13.3333V10M12.9167 2.91669V1.66669M16.1995 3.80057L17.0833 2.91669M17.0919 7.08335H18.3419M2.5 11.1226C3.04328 11.2065 3.59989 11.25 4.16667 11.25C7.82197 11.25 11.0544 9.43968 13.0164 6.66669"
      stroke="#00A389"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlanFactIcon;
