import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { changeCountryCode, setClientType } from '../../store/reducers/ClientSlice';
import { JuridicalPersonCreate, NaturalPersonCreate } from '../../utils/models/Client';
import { ClientType } from '../../utils/models/ClientType';

import styles from '../../pages/clientCreateContract/clientCreateContract.module.scss';
// import { useParams } from 'react-router-dom'

interface Props {
  data: JuridicalPersonCreate | NaturalPersonCreate | undefined;
  setCountSearchClient: React.Dispatch<React.SetStateAction<number>>;
}

const HeaderCreateClient: React.FC<Props> = ({ data, setCountSearchClient }) => {
  // const params = useParams<{ orderIdOrFlatId: string }>()
  const clientType = useAppSelector(state => state.clientReducer.type);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const changesType = (activeKey: string) => {
    if (!data?.type) {
      setCountSearchClient(0);
      dispatch(setClientType(activeKey as ClientType));
      dispatch(changeCountryCode('UZ'));
    }
  };

  const getActiveClass = (type: ClientType) => (clientType === type ? styles.select_cont_type_active : styles.select_cont_type_default);

  // const showExtraInformation = () => {
  //   return (
  //     params?.orderIdOrFlatId?.split('=')[0] === 'flatId' ||
  //     params?.orderIdOrFlatId?.split('=')[0] === 'orderId'
  //   )
  // }

  return (
    <>
      <div>
        <div className={styles.header}>
          <h1>{t(`changes.client.SelectType.Mijoz_malumotlari`)}</h1>
        </div>
        <div className={styles.select_cont}>
          <h2>{t(`changes.client.SelectType.Mijoz_turi`)}</h2>
          <div className={styles.select_cont_type}>
            <span onClick={() => changesType(ClientType.NATURAL)} className={getActiveClass(ClientType.NATURAL)}>
              {t(`createOrder.Jismoniy_shaxs`)}
            </span>
            <span onClick={() => changesType(ClientType.JURIDICAL)} className={getActiveClass(ClientType.JURIDICAL)}>
              {t(`createOrder.Yuridik_shaxs`)}
            </span>
          </div>
        </div>
      </div>
      {/* {showExtraInformation() && ( */}
      {/*  <HomeInformationModal onClose={setOpenModal} isOpen={openModal} /> */}
      {/* )} */}
    </>
  );
};

export default HeaderCreateClient;
