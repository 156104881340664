import { $api } from "@service/RequestService";
import { Pagination } from "@utils/models/Pagination";
import { useQuery } from "react-query";

import { File } from "modules/common";

import { queryKeys } from "../constants";

export const useJobAllFiles = (props?:{enabled?: boolean, page?: number }) => useQuery<Pagination<File>>({
    queryKey:[queryKeys.JOB_ALL_FILES, props],
    queryFn: async () => {
        const res = await $api.post("/v1/job/all-files",{
            page: props?.page,
            size: 15
        });

        return res.data.data
    },
    enabled: props?.enabled
})