import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { FlatId } from '@utils/models/Flat';

interface IProps {
  values: { flatCount: string; floorCount: string };
  shaxmatka: any[];
  dispatch: (e: any) => void;
  setSaveAll: (save: boolean) => void;
  handleCancel: () => void;
}

export const createPodval = ({ values, shaxmatka, dispatch, setSaveAll, handleCancel }: IProps) => {
  const arr: FlatId[] = [];
  let maxEntrance = 1;
  let maxFloor = 1;
  let maxXaxis = Number(values.flatCount);
  let minFloor = 1;
  let maxYaxis = 0;

  shaxmatka.forEach((entrance: any) => {
    entrance.forEach((floor: any) => {
      floor.forEach((flat: any) => {
        if (!flat.new) {
          arr.push(flat);
        }
      });
    });
  });

  arr.forEach(flat => {
    if (maxEntrance < flat.entrance) {
      maxEntrance = flat.entrance;
    }
    if (maxFloor < flat.floor) {
      maxFloor = flat.floor;
    }
    if (maxXaxis < flat.xaxis[0]) {
      // eslint-disable-next-line prefer-destructuring
      maxXaxis = flat.xaxis[0];
    }
    if (minFloor > flat.floor) {
      minFloor = flat.floor;
    }
    if (maxYaxis < flat.yaxis[0]) {
      // eslint-disable-next-line prefer-destructuring
      maxYaxis = flat.yaxis[0];
    }
  });

  const floorArr: number[] = [];

  for (let i = minFloor; i <= maxFloor; i++) {
    floorArr.push(i);
  }

  function flatPosition(entrance: number, floor: number, xaxis: number) {
    return (
      arr?.find((item: any) => item.entrance === entrance && item.floor === floor && item.xaxis[0] === xaxis) || {
        floor,
        xaxis: [xaxis],
        new: true,
        isSelected: false,
        apartment: null
      }
    );
  }

  const convertShaxmatka = Array.from({ length: maxEntrance }).map((entrance: any, entranceIndex: number) =>
    floorArr.map((floor: any) =>
      Array.from({ length: maxXaxis }).map((flat: any, flatIndex: number) => flatPosition(entranceIndex + 1, floor, flatIndex + 1))
    )
  );

  const podvalAdd = Array.from({ length: Number(values.floorCount) }).map((floor: any, floorIndex: number) =>
    Array.from({ length: Number(maxXaxis) }).map((flat: any, flatIndex: number) => ({
      floor: minFloor - (floorIndex + 1),
      xaxis: [flatIndex + 1],
      yaxis: [maxYaxis + floorIndex + 1],
      new: false,
      isSelected: false,
      apartment: true,
      number: 'new'
    }))
  );

  convertShaxmatka.forEach((entrance: any[], entranceIndex) => {
    entrance.unshift(
      ...podvalAdd.map(floor =>
        floor.map(flat => ({
          ...flat,
          entrance: entranceIndex + 1,
          customId: `${entranceIndex + 1}-${flat?.floor}-${flat?.xaxis[0]}`
        }))
      )
    );
  });

  dispatch(setShaxmatka(convertShaxmatka as any));
  setSaveAll(false);
  handleCancel();

  //   let ArrPodezLength = 0
  //   let ArrFloorLength: any = []
  //   let ArrXaxis = 0
  //   let ArrYaxis = 0
  //   let lenLength = 0
  //   let lenLengthMin = 1

  //   shaxmatka.forEach((entrance: any) => {
  //     entrance.forEach((floor: any) => {
  //       floor.forEach((flat: any) => {
  //         if (!flat.new) {
  //           arr.push(flat)
  //         }
  //       })
  //     })
  //   })

  //   arr.forEach((el: FlatId) => {
  //     buildingId = el?.building?.id
  //     buildingName = el?.building?.name
  //     lenLength = lenLength > el.floor ? lenLength : el.floor
  //     lenLengthMin = lenLengthMin < el.floor ? lenLengthMin : el.floor

  //     ArrPodezLength = ArrPodezLength > el.entrance ? ArrPodezLength : el.entrance

  //     if (!ArrFloorLength.includes(el.floor)) {
  //       ArrFloorLength?.push(el.floor)
  //     }
  //     ArrXaxis = ArrXaxis > el.xaxis[0] ? ArrXaxis : el.xaxis[0]
  //     ArrXaxis =
  //       ArrXaxis > Number(values.flatCount) ? ArrXaxis : Number(values.flatCount)
  //     ArrYaxis = ArrYaxis > el.yaxis[0] ? ArrYaxis : el.yaxis[0]
  //   })

  //   let len = lenLength - ArrYaxis + 1
  //   let lenLength2 = lenLength + Number(values.floorCount)

  //   for (var i = lenLengthMin - Number(values.floorCount); i <= lenLength; i++) {
  //     if (!ArrFloorLength.includes(i)) {
  //       ArrFloorLength.push(i)
  //     }
  //   }

  //   ArrFloorLength.sort(function (a: any, b: any) {
  //     return b - a
  //   }).reverse()

  //   let resultCopy = arr.map((flat: FlatId) => {
  //     if (flat.yaxis[0] >= lenLengthMin) {
  //       return {
  //         ...flat,
  //         yaxis: [flat?.yaxis[0] + Number(values.floorCount)],
  //         //   floor: flat?.floor - Number(values.floorCount),
  //       }
  //     } else {
  //       return flat
  //     }
  //   })
  //   let createEntrance: any = []
  //   let createFloor: any = []
  //   let createFlat: any = []

  //   for (let i = 1; i <= ArrPodezLength; i++) {
  //     if (!createEntrance.includes(i)) {
  //       createEntrance.push(i)
  //     }
  //   }

  //   for (let i = lenLengthMin - Number(values.floorCount); i <= lenLength; i++) {
  //     if (!createFloor.includes(i)) {
  //       createFloor.push(i)
  //     }
  //   }

  //   for (let i = 1; i <= Number(values.flatCount); i++) {
  //     if (!createFlat.includes(i)) {
  //       createFlat.push(i)
  //     }
  //   }

  //   let result2 = Array.from({ length: ArrPodezLength }, (_, i) =>
  //     ArrFloorLength.map((floor: any, j: number) =>
  //       Array.from({ length: ArrXaxis }, (_, k) => {
  //         return {
  //           new: !(
  //             createEntrance.includes(i + 1) &&
  //             createFloor.includes(floor) &&
  //             createFlat.includes(k + 1) &&
  //             lenLengthMin > floor
  //           ),
  //           floor: floor,
  //           isSelected: false,
  //           apartment: true,
  //           area: null,
  //           building: { id: buildingId, name: buildingName },
  //           createdBy: '',
  //           createdDate: '',
  //           delay: null,
  //           deletedBy: null,
  //           deletedDate: null,
  //           entrance: i + 1,
  //           gallery: [],
  //           number: 'new',
  //           options: null,
  //           prepayment: null,
  //           prepaymentPercent: null,
  //           price: null,
  //           priceNotRepaired: null,
  //           pricePerArea: null,
  //           pricePerAreaRepaired: null,
  //           priceRepaired: null,
  //           repaired: false,
  //           rooms: null,
  //           status: 'SALE',
  //           terrace: null,
  //           xaxis: [k + 1],
  //           yaxis: [j + 1],
  //         }
  //       })
  //     )
  //   )

  //   let resultCopy2 = result2.map((entrance: any) => {
  //     return entrance.map((floor: any) => {
  //       return floor.map((room: any) => {
  //         function New(a: FlatId) {
  //           return (
  //             a?.yaxis &&
  //             a?.yaxis[0] === room?.yaxis[0] &&
  //             a?.xaxis &&
  //             a?.xaxis[0] === room?.xaxis[0] &&
  //             a?.entrance &&
  //             a?.entrance === room?.entrance
  //           )
  //         }
  //         return resultCopy && resultCopy?.some((el: any) => New(el))
  //           ? {
  //               ...resultCopy?.find((el: any) => New(el)),
  //               floor: room.floor,
  //               new: false,
  //             }
  //           : {
  //               ...room,
  //             }
  //       })
  //     })
  //   })

  //   dispatch(setShaxmatka(resultCopy2))
  //   setSaveAll(false)
  //   handleCancel()
};
