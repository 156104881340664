import React from 'react';
import TrashIcon from '@icons/TrashIcon';
import { useDeleteWidget } from '@pages/dashboard/services/mutations';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './deleteConfirm.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  item: WidgetModel;
};

const DeleteConfirm: React.FC<Props> = ({ setVisible, visible, item }) => {
  const { t } = useTranslation();
  const deleteWidget = useDeleteWidget();

  const handleClose = () => {
    setVisible(false);
  };

  const handleDeleteWidget = () => {
    deleteWidget.mutateAsync(Number(item.id));
  };

  return (
    <Modal visible={visible} onCancel={handleClose} wrapClassName={styles.deleteVoronka} footer={false}>
      <span>
        <TrashIcon />
      </span>
      <p>
        «{item?.name}»{/* {t('crm.voronkasini_ochirishingizga_ishonchingiz_komilmi')} */}
        {t('emp_dashboard.vidjetni_ochirishga_ishonchingiz_komilmi')}
      </p>
      <div>
        <Button onClick={handleClose} onMouseDown={e => e.stopPropagation()}>
          {t('crm.Yopish')}
        </Button>
        <Button onClick={handleDeleteWidget} onMouseDown={e => e.stopPropagation()} loading={deleteWidget.isLoading}>
          {t('crm.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteConfirm;
