import React from 'react';

const SettingsIcon: React.FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.7998 12.2933C24.3865 12.2933 23.3998 10.5867 24.5998 8.49333C25.2932 7.28 24.8798 5.73333 23.6665 5.04L21.3598 3.72C20.3065 3.09333 18.9465 3.46666 18.3198 4.52L18.1732 4.77333C16.9732 6.86666 14.9998 6.86666 13.7865 4.77333L13.6398 4.52C13.0398 3.46666 11.6798 3.09333 10.6265 3.72L8.31984 5.04C7.1065 5.73333 6.69317 7.29333 7.3865 8.50666C8.59984 10.5867 7.61317 12.2933 5.19984 12.2933C3.81317 12.2933 2.6665 13.4267 2.6665 14.8267V17.1733C2.6665 18.56 3.79984 19.7067 5.19984 19.7067C7.61317 19.7067 8.59984 21.4133 7.3865 23.5067C6.69317 24.72 7.1065 26.2667 8.31984 26.96L10.6265 28.28C11.6798 28.9067 13.0398 28.5333 13.6665 27.48L13.8132 27.2267C15.0132 25.1333 16.9865 25.1333 18.1998 27.2267L18.3465 27.48C18.9732 28.5333 20.3332 28.9067 21.3865 28.28L23.6932 26.96C24.9065 26.2667 25.3198 24.7067 24.6265 23.5067C23.4132 21.4133 24.3998 19.7067 26.8132 19.7067C28.1998 19.7067 29.3465 18.5733 29.3465 17.1733V14.8267C29.3332 13.44 28.1998 12.2933 26.7998 12.2933ZM15.9998 20.3333C13.6132 20.3333 11.6665 18.3867 11.6665 16C11.6665 13.6133 13.6132 11.6667 15.9998 11.6667C18.3865 11.6667 20.3332 13.6133 20.3332 16C20.3332 18.3867 18.3865 20.3333 15.9998 20.3333Z"
      fill="#464255"
    />
  </svg>
);

export default SettingsIcon;
