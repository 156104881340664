import { useContext, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import CustomeInput, { ICustomeInput } from '@components/custome-input/CustomeInput';
import { useAppSelector } from '@hooks/reduxHooks';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import MiniMenuIcon from '@icons/MiniMenuIcon';
import ApartamentCard from '@pages/shaxmatka/components/ApartamentCard';
import { ShaxmatkaContext } from '@pages/shaxmatka/hooks/ShaxmatkaContext';
import { BASE_URL } from '@service/RequestService';
import { FlatId } from '@utils/models/Flat';
import { FlatStatus } from '@utils/models/FlatStatus';
import { Button, Checkbox, Image, InputRef, Space, Table, TableColumnType } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

import styles from './shaxmatkaTable.module.scss';

interface IProps {
  loading: boolean;
  repaired: boolean;
  flatCopy: FlatId | undefined;
  setDeleteLoad: React.Dispatch<React.SetStateAction<boolean>>;
  setRoom: React.Dispatch<any>;
  setFlatCopy: React.Dispatch<React.SetStateAction<FlatId | undefined>>;
  activeKey: number;
  setSaveAll: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateFloorModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      data: {};
    }>
  >;
}

type DataIndex = keyof FlatId;

const ShaxmatkaTable = ({ loading, repaired, flatCopy, setDeleteLoad, setFlatCopy, setRoom, activeKey, setCreateFloorModal, setSaveAll }: IProps) => {
  const [checked, setChecked] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const [currentDataSource, setCurrentDataSource] = useState<FlatId[] | undefined>([]);
  const { activeFlats, setContext } = useContext(ShaxmatkaContext);
  const { t } = useTranslation();
  const { data } = useAppSelector(state => state.shaxmatkaReducer);
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: DataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm']) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex: DataIndex, type: ICustomeInput = 'text'): TableColumnType<FlatId> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <CustomeInput
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ''}
          onChange={value => setSelectedKeys(value ? [value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
          type={type}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      // console.log(value)
      // setFilter((prev) => ({...prev, ...(prev[dataIndex  as keyof typeof prev] !== String(value) && {[dataIndex]:String(value)})}))

      record[dataIndex].toString().toLowerCase() === String(value),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const shaxmatka: FlatId[] = data?.flat(2).filter(item => !item?.new);

  const columns: ColumnsType<FlatId> | undefined = [
    {
      title: 'Number',
      dataIndex: 'number',
      fixed: 'left',
      width: 120,
      render(value, record, index) {
        return (
          <>
            {value} {record?.discountID && !Number.isNaN(record.discountID) && <span className={styles.discount}>%</span>}
          </>
        );
      },
      ...getColumnSearchProps('number'),
      sorter: (a, b) => {
        const nameA = a?.number.toUpperCase();
        const nameB = b?.number.toUpperCase();

        if (Number.isNaN(Number(nameA)) || Number.isNaN(Number(nameB))) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        }
        return Number(nameA) - Number(nameB);
      },
      showSorterTooltip: false
    },

    {
      title: 'Podez',
      dataIndex: 'entrance',
      width: 120,
      ...getColumnSearchProps('entrance'),
      sorter: (a, b) => a?.entrance - b.entrance,
      showSorterTooltip: false
    },
    {
      title: 'Qavat',
      dataIndex: 'floor',
      width: 120,
      ...getColumnSearchProps('floor'),
      sorter: (a, b) => a?.floor - b.floor,
      showSorterTooltip: false
    },
    {
      title: 'Xaxis',
      dataIndex: 'xaxis',
      width: 120,
      ...getColumnSearchProps('xaxis'),
      sorter: (a, b) => a?.xaxis[0] - b.xaxis[0],
      showSorterTooltip: false
    },
    {
      title: 'Maydoni',
      dataIndex: 'area',
      width: 120,
      ...getColumnSearchProps('area'),
      sorter: (a, b) => a?.area - b.area,
      showSorterTooltip: false
    },
    {
      title: 'Xonalar soni',
      dataIndex: 'rooms',
      width: 160,
      ...getColumnSearchProps('rooms'),
      sorter: (a, b) => a?.rooms - b.rooms,
      showSorterTooltip: false
    },
    {
      title: 'Umumiy narx',
      width: 160,
      render(value, record, index) {
        return repaired ? Number(record?.priceRepaired).toLocaleString('ru') : Number(record?.priceNotRepaired).toLocaleString('ru');
      },
      ...getColumnSearchProps(repaired ? 'priceRepaired' : 'priceNotRepaired', 'price'),
      sorter: (a, b) => {
        if (repaired) {
          return a?.priceRepaired - b.priceRepaired;
        }
        return a?.priceNotRepaired - b.priceNotRepaired;
      },
      showSorterTooltip: false
    },
    {
      title: '1 m² uchun',
      width: 160,
      render(value, record, index) {
        return repaired ? Number(record?.pricePerAreaRepaired).toLocaleString('ru') : Number(record?.pricePerArea).toLocaleString('ru');
      },
      ...getColumnSearchProps(repaired ? 'pricePerAreaRepaired' : 'pricePerArea', 'price'),
      sorter: (a, b) => {
        if (repaired) {
          return a?.pricePerAreaRepaired - b.pricePerAreaRepaired;
        }
        return a?.pricePerArea - b.pricePerArea;
      },
      showSorterTooltip: false
    },
    {
      title: t('home_id.Holati'),
      width: 160,
      render: (value, record) => (record?.repaired ? "Ta'mirli" : "Ta'mirsiz"),
      filters: [
        { text: "Ta'mirli", value: true },
        { text: "Ta'mirsiz", value: false }
      ],
      filterMode: 'menu',
      onFilter: (value, record) => record.repaired === value
    },
    {
      title: 'Xonadon turi',
      width: 160,
      render: (value, record) => (record?.apartment ? 'Turar-joy' : 'Noturar-joy'),
      filters: [
        { text: 'Turar-joy', value: true },
        { text: 'Noturar-joy', value: false }
      ],
      filterMode: 'menu',
      onFilter: (value, record) => record.apartment === value
    },
    {
      title: 'Blok',
      dataIndex: 'block',
      width: 160,
      render: (value, record) => record?.building?.name
    },
    {
      title: 'Uy rejasi',
      width: 100,
      render(value, record, index) {
        const imagePlan = record?.gallery?.find(item => item?.position === 'FLAT_PLAN')?.files;

        return (
          <Image
            src={`${BASE_URL}/${imagePlan?.absolutePath}`}
            width={32}
            height={32}
            placeholder={
              <Image
                preview={false}
                src={`${BASE_URL}/${imagePlan?.smallestAbsolutePath || imagePlan?.thumbAbsolutePath || imagePlan?.absolutePath}`}
                width={200}
              />
            }
          />
        );
      },
      className: styles.table_img
    },
    {
      render(value, record) {
        return (
          <ApartamentCard
            setDeleteLoad={setDeleteLoad}
            i={record}
            repaired={repaired}
            setRoom={setRoom}
            activeKey={activeKey}
            flatCopy={flatCopy}
            setFlatCopy={setFlatCopy}
            setSaveAll={setSaveAll}
            setCreateFloorModal={setCreateFloorModal}
            trigger={['click', 'hover']}
            mouseEnterDelay={2000}
            mouseLeaveDelay={0.3}
            placement="bottom"
          >
            <MiniMenuIcon className={styles.miniMenu} />
          </ApartamentCard>
        );
      },
      width: 60,
      fixed: 'right'
    }
  ];

  const getArr = (arr: any[] | undefined) => {
    if (arr && arr?.length > 0) {
      return arr;
    }
    return undefined;
  };

  return (
    <div>
      <Table
        bordered
        className={`tableNewStyle ${styles.table}`}
        scroll={{ y: `calc(100vh - ${offsetTop}px - 216px)` }}
        ref={ref => {
          setOffsetTop(ref?.offsetTop || 0);
        }}
        rowClassName={styles.row}
        onChange={(p, f, s, e) => {
          setCurrentDataSource(e?.currentDataSource);
        }}
        onRow={data => {
          const getBackgroundColor = () => {
            if (FlatStatus.SOLD === data?.status) return styles.SOLD;
            if (FlatStatus.BOOKED === data?.status) return styles.BOOKED;
            if (FlatStatus.RESERVE === data?.status) return styles.RESERVE;
            return styles.DEFAULT;
          };

          return {
            onClick: e => {
              if (e.ctrlKey) {
                setContext({
                  activeFlats: [...(activeFlats?.filter(item => item?.customId !== data?.customId) || [])].concat([
                    ...(activeFlats?.some(item => item?.customId === data?.customId) ? [] : [{ ...data, isSelected: true }])
                  ])
                });
              } else {
                setContext({
                  activeFlats: [{ ...data, isSelected: true }]
                });
              }
            },
            className: getBackgroundColor()
          };
        }}
        rowSelection={{
          onSelect(r, s, selectedRows) {
            setContext({
              activeFlats: selectedRows?.map(item => ({
                ...item,
                isSelected: true
              }))
            });
          },
          selectedRowKeys: activeFlats?.map(item => item?.customId || ''),
          columnTitle: (
            <Checkbox
              indeterminate={activeFlats && activeFlats?.length > 0 && activeFlats?.length !== shaxmatka?.length}
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked);
                if (e.target.checked) {
                  setContext({
                    activeFlats: (getArr(currentDataSource) || getArr(shaxmatka))?.map(item => ({
                      ...item,
                      isSelected: true
                    }))
                  });
                } else {
                  setContext({ activeFlats: [] });
                }
              }}
            />
          )
        }}
        rowKey={record => record?.customId || ''}
        columns={columns}
        dataSource={shaxmatka}
        loading={loading}
        pagination={{
          className: 'tableNewStyle_pagination',
          prevIcon: (
            <div className="tableNewStyle_pagination_prev">
              <ArrowLeftIcon /> {t('my_calls.Ortga')}
            </div>
          ),
          nextIcon: (
            <div className="tableNewStyle_pagination_next">
              {t('crm_ins.Keyingisi')} <ArrowRightIcon />
            </div>
          ),
          pageSize: 15
        }}
      />
    </div>
  );
};

export default ShaxmatkaTable;
