import React from 'react';

const TaskSquareGrayIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3701 8.87988H17.6201" stroke="#898F98" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.37988 8.87988L7.12988 9.62988L9.37988 7.37988" stroke="#898F98" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3701 15.8799H17.6201" stroke="#898F98" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.37988 15.8799L7.12988 16.6299L9.37988 14.3799" stroke="#898F98" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#898F98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TaskSquareGrayIcon;
