import React, { useEffect } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { usePostOneCIntegration } from '@pages/integration/service/mutation';
import { useGetOneCIntegration } from '@pages/integration/service/queries';
import { queryKeys } from '@pages/integration/utils/constants/queryKeys';
import { OneCModel } from '@pages/integration/utils/models/OneCModel';
import { Permissions } from '@utils/constants/permissions';
import { Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './oneCModal.module.scss';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const OneCModal: React.FC<Props> = ({ open, setOpen }) => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { data } = useGetOneCIntegration();
  const updateIntegration = usePostOneCIntegration();
  const user = useAppSelector(state => state.userReducer.user);
  const is1C = user?.role?.permissions?.includes(Permissions.PERMISSION_ONEC);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open || is1C) {
      qc.fetchQuery([queryKeys.ONE_C]);
    }
  }, [open, is1C]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const onOk = () => {
    form.submit();
  };

  const onFinish = (value: OneCModel) => {
    updateIntegration.mutateAsync({ ...value, id: data?.id!, status: data?.status! }).then(handleClose);
  };

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      title={t('integration.oneC_title')}
      onOk={onOk}
      okText={t('home.Saqlash')}
      cancelText={t('shaxmatka.Bekor_qilish')}
      okButtonProps={{ loading: updateIntegration?.isLoading }}
      className={styles.integrationModal}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
        <Form.Item name="key" label="KEY">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="ip" label={t('security.IP_Manzil')}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OneCModal;
