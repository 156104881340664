export interface TableStatusColorTypes {
  [key: string]: {
    type: string;
    color: string;
  };
}

export const tableStatusColor: TableStatusColorTypes = {
  NOTHING: {
    type: 'NOTHING',
    color: ''
  },
  RED: {
    type: 'RED',
    color: '#F42C2C'
  },
  ORANGE: {
    type: 'ORANGE',
    color: '#FFA12F'
  },
  GREEN: {
    type: 'GREEN',
    color: '#1DB954'
  },
  BLUE: {
    type: 'BLUE',
    color: '#0AB4FF'
  },
  INDIGO: {
    type: 'INDIGO',
    color: '#2C2DA0'
  },
  PURPLE: {
    type: 'PURPLE',
    color: '#7B68EE'
  },
  CORAL: {
    type: 'CORAL',
    color: '#FF5722'
  },
  PINK: {
    type: 'PINK',
    color: '#F900EA'
  },
  SILVER: {
    type: 'SILVER',
    color: '#667684'
  }
};
