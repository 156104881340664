import React, { useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import SearchIcon from '@icons/SearchIcon';
import { Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import CrmSearch from '../../voronkaCards/crm-search/CrmSearch';
import { SearchParamsCrm } from '../FilterButton';

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: React.MutableRefObject<any>;
}

const SearchModal: React.FC<IProps> = ({ visible, setVisible, inputRef }) => {
  const { t } = useTranslation();
  const { searchParams, appendSingle, remove } = useQueryParam<SearchParamsCrm, any>();
  const [searchValue, setSearchValue] = useState(searchParams.search!);

  const handleSearch = (value: string) => {
    setVisible(false);
    setTimeout(() => {
      if (value.length >= 3) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const handleClose = () => {
    setVisible(false);
    setSearchValue('');
  };

  return (
    <Modal visible={visible} onCancel={handleClose} footer={false} closeIcon={false} closable={false} className="customModal">
      <Input
        ref={inputRef}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        className="customInput1"
        placeholder={t('home.Qidirish')}
        onPressEnter={(e: any) => {
          handleSearch(e.target.value);
        }}
        addonBefore={<SearchIcon />}
      />
      {searchValue?.length >= 3 && <CrmSearch type="modal" foo={searchValue} />}
    </Modal>
  );
};

export default SearchModal;
