import { FC } from 'react';
import { Pagination, PaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLeftIcon from '../../../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../../../assets/icons/contract/ArrowRightIcon';

import styles from './paymentPagination.module.scss';

interface IProps extends PaginationProps {}

const PaymentPagination: FC<IProps> = props => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Pagination
        className={styles.container_pagination}
        {...props}
        prevIcon={
          <div className={styles.container_pagination_prev}>
            <ArrowLeftIcon /> {t('integration.Oldingi')}
          </div>
        }
        nextIcon={
          <div className={styles.container_pagination_next}>
            {t('profile.Keyingi')} <ArrowRightIcon />
          </div>
        }
      />
    </div>
  );
};

export default PaymentPagination;
