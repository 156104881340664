import React from 'react';

const PersonCreateIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 20H15" stroke="#00A389" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 22V18" stroke="#00A389" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.66 11.37C12.56 11.36 12.44 11.36 12.33 11.37C9.94997 11.29 8.05997 9.34 8.05997 6.94C8.04997 4.49 10.04 2.5 12.49 2.5C14.94 2.5 16.93 4.49 16.93 6.94C16.93 9.34 15.03 11.29 12.66 11.37Z"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.49 22.3097C10.67 22.3097 8.85998 21.8497 7.47998 20.9297C5.05998 19.3097 5.05998 16.6697 7.47998 15.0597C10.23 13.2197 14.74 13.2197 17.49 15.0597"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonCreateIcon;
