import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import RefreshIcon from '../../../assets/icons/RefreshIcon';
import TrushSquareIcon from '../../../assets/icons/TrushSquareIcon';
import { useDeleteRemotePayment, useUpdateRemotePayment } from '../../../queries/mutation';
import { useHousesNameAndID } from '../../../queries/queries';
import { Apelsin } from '../../../utils/models/Apelsin';

interface Props {
  data: Apelsin;
}

const ItemRemotePayment: React.FC<Props> = ({ data }) => {
  const [form] = Form.useForm<Apelsin>();

  const { isLoading: housesLoading, data: houses } = useHousesNameAndID();

  const deleteRemotePayment = useDeleteRemotePayment();
  const editRemotePayment = useUpdateRemotePayment();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const { t } = useTranslation();

  const handleDelete = () => {
    deleteRemotePayment.mutate(data.id);
  };

  const handleClickSwitch = async (checked: boolean) => {
    await form.validateFields();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRemotePayment({
      ...form.getFieldsValue(),
      username: form.getFieldsValue().username?.trim(),
      password: form.getFieldsValue().password?.trim(),
      status: checked,
      id: data.id
    });
  };

  const handleBlurInput = async () => {
    await form.validateFields();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    updateRemotePayment({
      ...form.getFieldsValue(),
      username: form.getFieldsValue().username?.trim(),
      password: form.getFieldsValue().password?.trim(),
      status: data.status,
      id: data.id
    });
  };

  const updateRemotePayment = (value: Apelsin) => {
    editRemotePayment.mutate(value);
  };

  return (
    <Spin spinning={deleteRemotePayment.isLoading || editRemotePayment.isLoading}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="basic" initialValues={{ remember: true }} autoComplete="off">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('paymeCash.ID')}</label>} rules={[{ required: true, message: '' }]} name="username">
              <Input onBlur={handleBlurInput} className="h-[52px] rounded-md px-3 py-2 text-2xl font-medium" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('paymeCash.Key')}</label>} rules={[{ required: true, message: '' }]} name="password">
              <Input onBlur={handleBlurInput} className="customInput1" suffix={<RefreshIcon />} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={<label className="w-full !text-xl text-[#A0A0A0]">{t('paymeCash.Turar_joy_majmuasi')}</label>}
              rules={[{ required: true, message: '' }]}
              name="houseIds"
            >
              <Select onBlur={handleBlurInput} mode="multiple" className="customSelect1" optionFilterProp="children" showSearch loading={housesLoading}>
                {houses?.map(house => (
                  <Select.Option key={house?.id} value={house.id}>
                    {house.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={<label className="w-full !text-xl text-[#A0A0A0]">{t('paymeCash.Holati')}</label>}>
              <Switch onChange={handleClickSwitch} checked={data?.status} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={<label className="w-full !text-xl  text-[#A0A0A0]">{t('paymeCash.Amallar')}</label>}>
              <Button className="flex items-center border-none p-0" onClick={handleDelete} loading={deleteRemotePayment.isLoading}>
                <TrushSquareIcon />
                <span className="text-[#ff3f40]">{t('paymeCash.Ochirish')}</span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ItemRemotePayment;
