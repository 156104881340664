import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import DublicateControllField from '@pages/crm/components/listView/actions/dublicate-controll-modal/DublicateControllField';
import DublicateControllFieldAll from '@pages/crm/components/listView/actions/dublicate-controll-modal/DublicateControllFieldAll';
import { useLeadGroup } from '@pages/crm/services/mutation';
import { useCrmLeadBy } from '@pages/crm/services/queries';
import { setClearListView } from '@reducers/CrmSlice';
import { BaseEntity } from '@utils/models/BaseEntity';
import { ContactCrm, FieldValue, OneLid } from '@utils/models/OneLid';
import { Button, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../../../../crm/components/listView/actions/dublicate-controll-modal/dublicateControllModal.module.scss';

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  leadId?: number;
}

const MergeModal: FC<IProps> = ({ setVisible, visible, leadId }) => {
  const { t } = useTranslation();
  const activeIds = useAppSelector(state => state.assigmentSlice?.listViewActiveIds);
  const { data } = useCrmLeadBy(visible ? activeIds : []);
  const [dublicate, setDublicate] = useState<OneLid | undefined>();
  const [fields, setFields] = useState<FieldValue[]>([]);
  const leadGroup = useLeadGroup();
  const dispatch = useAppDispatch();

  const handleCancle = () => {
    setVisible(false);
    // dispatch(setClearListView());
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      let contacts: ContactCrm[][] = [];
      data?.forEach(item => {
        contacts.push(item?.contacts);
      });

      let fields: FieldValue[] = [];
      let tags: BaseEntity[] = [];
      data?.forEach(item => {
        item?.tags?.forEach(tag => {
          if (!tags.some(val => val?.id === tag?.id)) {
            tags.push(tag);
          }
        });
        item?.fields?.forEach(field => {
          if (!fields?.some(val => val?.id === field?.id)) {
            fields?.push(field);
          }
        });
      });

      setFields(fields);
      setDublicate({
        ...data[0],
        responsibleById: data?.find(item => item?.responsibleById)?.responsibleById,
        responsibleBy: data?.find(item => item?.responsibleBy)?.responsibleBy || '',
        contacts: contacts?.flat(),
        fields: fields,
        tags: tags
      });
    }
  }, [data]);

  const notKeys = ['id', 'isMoiZvonki', 'responsibleBy'];

  const handleSave = () => {
    leadGroup
      .mutateAsync({
        leadIds: activeIds,
        requestMethodType: 'LEAD_MERGE',
        leadMergeDto: {
          leadId: leadId ? leadId : dublicate?.id,
          leadName: dublicate?.name,
          balance: dublicate?.balance,
          responsibleById: dublicate?.responsibleById,
          pipeStatusId: dublicate?.pipeStatusResponse?.id,
          contactIdList: dublicate?.contacts?.map(item => item?.id!),
          createdDate: dublicate?.createdDate,
          fields: dublicate?.fields,
          tagIdList: dublicate?.tags?.map(item => item?.id!)
        }
      })
      .then(() => {
        handleCancle();
      });
  };

  const dataKeys = () => {
    let arr = ['name', 'responsibleById', 'createdDate', 'balance', 'pipeStatusResponse', 'fields', 'tags', 'contacts'];
    if (data && data?.length > 0) {
      Object.keys(data[0])?.forEach(item => {
        if (!arr.includes(item)) {
          arr.push(item);
        }
      });
    }
    return arr;
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancle}
      title={t('one_lid.Potensial_mijozlarni_birlashtirish')}
      width={1100}
      footer={false}
      wrapClassName={styles.modal}
    >
      <div className={styles.container}>
        <p className={styles.container_title}>{t('one_lid.Quyida_dublikat_yol_yoriqlar_toplami_keltirilgan')}</p>
        <div className={styles.body}>
          <div className={styles.body_content}>
            {data &&
              data?.length > 0 &&
              dataKeys()?.map(
                (key, i) =>
                  !notKeys.includes(key) && (
                    <DublicateControllField
                      key={i}
                      {...data[0]!}
                      dublicate={dublicate}
                      setDublicate={setDublicate}
                      data={data}
                      keyObj={key}
                      index={i}
                      fieldsArr={fields}
                    />
                  )
              )}
          </div>
          <DublicateControllFieldAll dublicate={dublicate} fieldsArr={fields} />
        </div>
        <div className={styles.container_footer}>
          <Button onClick={handleCancle}>{t('home.Bekor_qilish')}</Button>
          <Button type="primary" onClick={handleSave} loading={leadGroup.isLoading}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MergeModal;
