import React, { useEffect } from 'react';
import styles from './createTablePayments.module.scss';
import TableMonthlyPayments from '../../components/createTablePayments/TableMonthlyPayments';
import CardConfiguration from '../../components/createTablePayments/CardConfiguration';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import { Layout } from 'antd';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import AfterContractCreateModal from '../../components/createContract/afterCreateContract/AfterContractCreateModal';

const { Content } = Layout;

const CreateTablePayments = () => {
  const flats = useAppSelector(state => state.createContract.flats);
  const { splitUrl, push } = useQueryParam();

  useEffect(() => {
    if (flats.length === 0) {
      let url = '';
      for (let i = 0; i <= splitUrl.length - 3; i++) {
        url += splitUrl[i];
        if (i !== splitUrl.length - 3) {
          url += '/';
        }
      }
      push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.contractCondition}>
          <TableMonthlyPayments />
          <CardConfiguration />
          <AfterContractCreateModal />
        </div>
      </Content>
    </>
  );
};

export default CreateTablePayments;
