import React from 'react';

const ViewDoubleIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_14195_96779)">
        <rect x="4" y="2" width="5" height="16" rx="1.3" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
        <rect x="11" y="2" width="5" height="16" rx="1.3" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.66797 16.25V3.75" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.332 15.8335V3.3335" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_14195_96779">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ViewDoubleIcon;
