import React, { useEffect } from 'react';
import { Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useEditKpiPartConst } from '../../../queries/mutation';
import { KpiItem, KpiPart } from '../../../utils/models/Kpi';

import styles from '../../../pages/kpi/kpi.module.scss';

interface Props {
  kpiPart?: KpiPart;
  kpi?: KpiItem;
}

const SimplePercent: React.FC<Props> = ({ kpiPart, kpi }) => {
  const [form] = Form.useForm<{ amount: number }>();

  const editKpiPart = useEditKpiPartConst();

  const { t } = useTranslation();

  useEffect(() => {
    if (kpiPart) {
      form.setFieldsValue({
        amount: kpiPart.amount
      });
    }
  }, [kpiPart, form]);

  const handleUpdate = () => {
    if (form.getFieldValue('amount')) {
      editKpiPart.mutate({
        amount: Number(form.getFieldValue('amount')),
        kpiPartId: kpi?.id || -1,
        currencyId: kpiPart?.currencyDto?.id
      });
    }
  };

  return (
    <Spin spinning={editKpiPart.isLoading}>
      <Form form={form} name="PercentConst">
        <div className={styles.cont_body_right_simple}>
          <div className={styles.cont_body_right_simple_left}>
            <p>{t('kpi.Odatiy_mukofot_foizi')}</p>
            <span>{t('kpi.Sotilgan_uylar_uchun_barcha_mukofotlar_shu_foizda_hisoblab_boriladi')}</span>
          </div>
          <div className={styles.cont_body_right_simple_right}>
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  validator: (rule, value) => {
                    if (value < 0) {
                      form.setFieldsValue({
                        amount: 0
                      });
                    } else if (value > 100) {
                      form.setFieldsValue({
                        amount: 100
                      });
                    }
                  }
                }
              ]}
            >
              <Input onBlur={handleUpdate} type="number" className={`${styles.cont_body_right_simple_right_input} customInput`} />
            </Form.Item>
            <span>%</span>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default SimplePercent;
