import ContractCommon from '@pages/bossContract/components/contract-common/ContractCommon';
import ContractComplex from '@pages/bossContract/components/contract-complex/ContractComplex';

import { contractStatisticsPath } from './contractStatisticsPath';

export const contractStatisticsRoute = [
  {
    path: contractStatisticsPath.COMMON,
    component: ContractCommon,
    exact: false
  },
  {
    path: contractStatisticsPath.COMPLEX,
    component: ContractComplex,
    exact: false
  }
];
