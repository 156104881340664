import React from 'react';

interface Props {
  className?: string;
}

const ImportExportIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.9999 124.667H84.9999C113.333 124.667 124.667 113.334 124.667 85.0006V51.0006C124.667 22.6673 113.333 11.334 84.9999 11.334H50.9999C22.6666 11.334 11.3333 22.6673 11.3333 51.0006V85.0006C11.3333 113.334 22.6666 124.667 50.9999 124.667Z"
        stroke="#A2A2A2"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34 80.2598L51 97.2598L68 80.2598" stroke="#A2A2A2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M51 97.2591V51.9258" stroke="#A2A2A2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M102 55.9453L85 38.9453L68 55.9453" stroke="#A2A2A2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M85 38.946V84.2793" stroke="#A2A2A2" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ImportExportIcon;
