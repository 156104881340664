import React from 'react';
import Crown from '../../assets/icons/Crown';
import SmsTracking from '../../assets/icons/SmsTracking';
import './MessagePremium.module.scss';
import { useTranslation } from 'react-i18next';

const MessagePremium = () => {
  const { t } = useTranslation();
  return (
    <div className={'messagePremium'}>
      <SmsTracking />

      <p className={'premiumParagraph'}>
        <span>
          <Crown />
        </span>
        {t('home.Premium')}
      </p>
    </div>
  );
};

export default MessagePremium;
