import { useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import DeleteAreaArageIcon from '@icons/areaarage/DeleteAreaArageIcon';
import { Button, Collapse, Dropdown, Menu, Tooltip } from 'antd';
import { t } from 'i18next';

import { useDeleteMessageSMSBot } from './services/mutation';
import { useGetMessageSMSBot } from './services/queries';

import ddropdown from './assets/ddropdown.svg';
import failed from './assets/failed.svg';
import messageText from './assets/messageText.svg';
import pending from './assets/pending.svg';
import rejected from './assets/rejected.svg';
import sent from './assets/sent.svg';
import sms2 from './assets/sms2.svg';

import styles from './AutoSMSTriggerWidget.module.scss';

const AutoSMSTriggerWidget = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { params } = useQueryParam();
  const { data, fetchNextPage } = useGetMessageSMSBot(params.id);
  const deleteMessageSMSBot = useDeleteMessageSMSBot();
  const [menuVisible, setMenuVisible] = useState(false);
  const [scrolling, setScrolling] = useState(true);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleDelete = (id: number) => {
    deleteMessageSMSBot.mutate(id);
  };

  const type: { [key: string]: { text: string; icon: string } } = {
    SENT: {
      text: t('home.Jonatilgan'),
      icon: sent
    },
    PENDING: {
      text: t('home.Jonatish_kutilmoqda'),
      icon: pending
    },
    REJECTED: {
      text: t('home.Jonatish_rad_etilgan'),
      icon: rejected
    },
    FAILED_SENT: {
      text: t('home.Jonatilmagan'),
      icon: failed
    }
  };

  const scrollColumn = (e: any) => {
    if (e.target.offsetHeight + e.target.scrollTop + 200 >= e.target.scrollHeight && e.target.scrollHeight > e.target.offsetHeight) {
      setScrolling(false);
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  return (
    <div className={styles.bbblur}>
      <div className={styles.wrapper} onScroll={scrollColumn}>
        <Collapse
          bordered={false}
          // defaultActiveKey={['autoSMS']}
          onChange={keys => setDropdownVisible(keys.includes('autoSMS'))}
          className={styles.clps}
          expandIconPosition="right"
        >
          <Collapse.Panel
            key="autoSMS"
            header={
              <div className={styles.title}>
                <div className={styles.fixedTitle}>
                  <img src={sms2} alt="img" width={23} />
                  <h1 title={t('integration.Avtomatik_sms')} onClick={e => e.preventDefault()} className="w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {t('integration.Avtomatik_sms')}
                  </h1>
                </div>
              </div>
            }
            className={styles.bblur}
          >
            <div className={styles.container} style={{ marginTop: '10px' }}>
              {data?.pages[0]?.data?.length! > 0 ? (
                data?.pages.map(item =>
                  item.data.map(el => (
                    <div
                      key={el?.id}
                      className={styles.tableRow}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '7px'
                      }}
                    >
                      <Tooltip title={el.date}>
                        <span className={styles.table_text}>{el.date.length > 25 ? `${el.date.substring(0, 25)}...` : el.date}</span>
                      </Tooltip>
                      <div className={styles.table_icons}>
                        <Tooltip title={el.text}>
                          <img src={messageText} alt="icon" />
                        </Tooltip>

                        {el.type === 'REJECTED' || el.type === 'SENT' || el.type === 'FAILED_SENT' ? (
                          <Tooltip title={type[el.type]?.text}>
                            <img src={type[el.type]?.icon} alt="ИКОНКА" style={{ marginRight: '26px' }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={type[el.type]?.text}>
                            <img src={type[el.type]?.icon} alt="ИКОНКА" />
                          </Tooltip>
                        )}

                        {el.type === 'PENDING' ? (
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item key="1">
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <DeleteAreaArageIcon />
                                    <Button type="text" onClick={() => handleDelete(el.id)}>
                                      {t('home.Bekor_qilish')}
                                    </Button>
                                  </div>
                                </Menu.Item>
                              </Menu>
                            }
                            onVisibleChange={toggleMenu}
                            trigger={['click']}
                          >
                            <Tooltip title={t('integration.Tahrirlash')}>
                              <button
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  cursor: 'pointer'
                                }}
                                onClick={() => console.log(el.id)}
                              >
                                <img src={ddropdown} alt="icon" />
                              </button>
                            </Tooltip>
                          </Dropdown>
                        ) : null}
                      </div>
                    </div>
                  ))
                )
              ) : (
                <div className={styles.noData}>{t('home.Malumotlar_mavjud_emas')}.</div>
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default AutoSMSTriggerWidget;
