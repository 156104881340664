import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { queryKeys } from '../constants';
import { ITermDiscount } from '../schema';

export const useTermDiscount = () =>
  useQuery<ITermDiscount[]>({
    queryFn: async () => {
      const res = await $api.get('/v1/term-discount');

      return res.data.data;
    },
    queryKey: [queryKeys.TERM_DISCOUNT]
  });
