import React, { FC, useEffect, useRef, useState } from 'react';
import { Icon } from '@components/index';
import CheveronRight from '@icons/CheveronRight';
import { cx } from '@utils/cx';
import { AutoTaskSetting, AutoTaskSettingPut } from '@utils/models/AutoTaskSetting';
import { Button, Checkbox, Form, Input, Select, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Modal } from 'global';
import { useTranslation } from 'react-i18next';

import { useAutoTaskConfig, useAutoTaskConfigDelete, useAutoTaskConfigEmployee, useAutoTaskConfigSave } from 'modules/auto-task';

import styles from './taskBotSettingModal.module.scss';

const { Option } = Select;

interface IProps extends AutoTaskSetting {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

const TaskBotSettingModal: FC<IProps> = props => {
  const autoTaskSettingPut = useAutoTaskConfigSave();
  const autoTaskConfigDelete = useAutoTaskConfigDelete();
  const [form] = Form.useForm();
  const [dayVisible, setDayVisible] = useState(false);
  const [disable, setDisable] = useState(!props.status);
  const [content, setContent] = useState(props.comment);
  const [contentRule, setContentRule] = useState(false);
  const contentRef = useRef<any>(null);
  const [anchorOffset, setAnchorOffset] = useState<number | undefined>(undefined);
  const { data, isLoading } = useAutoTaskConfig();
  const { data: employeeData } = useAutoTaskConfigEmployee();

  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      dayOfTerm: data?.dayOfTerm,
      automaticCompleted: data?.automaticCompleted,
      // comment: data?.comment,
      employees: data?.employees?.map(item => item.id),
      id: data?.id,
      status: data?.status,
      taskTermStatus: data?.taskTermStatus
    });
    setDayVisible(data?.taskTermStatus === 'NOW');
    setDisable(!data?.status);
    setContent(data?.comment || '');
    // eslint-disable-next-line
  }, [data, props.visible]);

  // handle close
  const handleClose = () => {
    props.setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line
  }, [autoTaskSettingPut.isSuccess]);

  // handle finish
  const onFinish = (values: AutoTaskSettingPut) => {
    if (contentRef.current.textContent?.trim().length === 0) {
      setContentRule(true);
    } else {
      setContentRule(false);
      autoTaskSettingPut.mutate({
        id: data?.id,
        automaticCompleted: values.automaticCompleted,
        comment: contentRef.current.textContent,
        dayOfTerm: values.dayOfTerm,
        employees: values.employees,
        status: values.status,
        taskTermStatus: values.taskTermStatus
      });
    }
  };

  // change settings
  const settingChange = (e: CheckboxChangeEvent) => {
    setDisable(!e.target.checked);
  };

  // change day input
  const changeDayInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value.replace('e', '');
    if (e.target.value.split('').includes('e')) {
      form.setFieldsValue({
        dayOfTerm: form.getFieldValue('dayOfTerm').replace('e', '')
      });
    } else {
      if (Number(e.target.value) >= 25) {
        form.setFieldsValue({ dayOfTerm: '25' });
      }
      if (Number(e.target.value) <= 0) {
        form.setFieldsValue({ dayOfTerm: '' });
      }
    }
  };

  // hande change select date
  const daySelectChange = (e: any) => {
    if (e === 'NOW') {
      setDayVisible(true);
    } else {
      setDayVisible(false);
    }
  };

  // changeContentEditable

  const changeContentEditable = async (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await updateAnchorOffset();
    const sel = window.getSelection();
    const select = sel?.anchorOffset;

    await setContent(e.target.innerHTML);
    if (select) {
      const range = new Range();

      range.setStart(e.target.lastChild, select);
      range.setEnd(e.target.lastChild, select);
      sel?.removeAllRanges();
      sel?.addRange(range);
    }
  };

  // handleContentTag
  const handleContentTag = async (tag: string) => {
    if (!disable) {
      const sel = await window.getSelection();
      const el = document.querySelector(`.${styles.roboSettingModal_descriptionTextarea}`);

      if (anchorOffset !== undefined) {
        const range = new Range();
        // eslint-disable-next-line no-underscore-dangle
        const __html = el?.textContent!;
        const front = __html.slice(0, anchorOffset);
        const latter = __html.slice(anchorOffset);

        await setContent(`${front} ${tag} ${latter}`);
        range.setStart(el?.firstChild!, anchorOffset + tag.length + 2);
        range.setEnd(el?.firstChild!, anchorOffset + tag.length + 2);
        sel?.removeAllRanges();
        sel?.addRange(range);
        setAnchorOffset(anchorOffset + tag.length + 2);
      }
    }
    setContentRule(contentRef.current.textContent?.trim().length === 0);
  };

  /// updateAnchorOffset
  const updateAnchorOffset = async () => {
    const sel = await window.getSelection();

    setAnchorOffset(sel?.focusOffset!);
  };

  // handleContentEditable
  const handleContentEditable = () => {
    setContentRule(contentRef.current.textContent?.trim().length === 0);

    updateAnchorOffset();
  };

  const handleDelete = (onCancel: () => void) => {
    autoTaskConfigDelete.mutate(undefined, {
      onSuccess: () => {
        onCancel();
        handleClose();
      }
    });
  };

  return (
    <Modal visible={props.visible} onCancel={handleClose} title={t('auto_task.Bot_sozlamalari')} wrapClassName={styles.roboSettingModal} width={466} footer={false}>
      <Spin spinning={isLoading}>
        <Form onFinish={onFinish} form={form} layout="vertical" autoComplete="off" initialValues={data}>
          <Form.Item name="status" valuePropName="checked">
            <Checkbox onChange={e => settingChange(e)}>{t('auto_task.Sozlamalarni_faollashtirish')}</Checkbox>
          </Form.Item>
          <div className={styles.roboSettingModal_days}>
            {dayVisible ? (
              <Form.Item className={styles.roboSettingModal_days_left} label={t('auto_task.Tolov_topshirigi_qachon_yaratilsin')}>
                <Input disabled={true} type="number" />
              </Form.Item>
            ) : (
              <Form.Item
                name="dayOfTerm"
                rules={[{ required: true, message: '' }]}
                className={styles.roboSettingModal_days_left}
                label={t('auto_task.Tolov_topshirigi_qachon_yaratilsin')}
              >
                <Input disabled={disable} type="number" onChange={e => changeDayInput(e)} />
              </Form.Item>
            )}
            <Form.Item name="taskTermStatus" rules={[{ required: true, message: '' }]} className={styles.roboSettingModal_days_right}>
              <Select disabled={disable} size="small" onChange={e => daySelectChange(e)} suffixIcon={<CheveronRight style={{ transform: 'rotate(90deg)' }} />}>
                <Option value="BEFORE">{t('auto_task.kun_qolganda')}</Option>
                <Option value="AFTER">{t('auto_task.kun_otganda')}</Option>
                <Option value="NOW">{t('auto_task.tolov_kunida')}</Option>
              </Select>
            </Form.Item>
          </div>
          <Form.Item name="employees" rules={[{ required: true, message: '' }]} className={styles.roboSettingModal_employeeSelect}>
            <Select disabled={disable} mode="multiple" placeholder={t('auto_task.Masul_hodimlar')}>
              {employeeData &&
                employeeData.length > 0 &&
                employeeData?.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.fullName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="clientChange" className={styles.roboSettingModal_employeeSelect}>
            <Select disabled placeholder={t('auto_task.Mijoz_bilan_boglanish')} suffixIcon={<CheveronRight style={{ transform: 'rotate(90deg)' }} />} />
          </Form.Item>
          {/* <Form.Item
            name={'comment'}
            rules={[{ required: true, message: '' }]}
            className={styles.roboSettingModal_description}
          >
            <Input.TextArea
              disabled={disable}
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder={t(
                'auto_task.Tolov_yaqinlashmoqda_Siz_nazorat_qilishingiz_kerak'
              )}
            />
          </Form.Item> */}
          {/* <ContentEditable
            // className="ant-input customInput"
            className={styles.roboSettingModal_descriptionTextarea}
            onChange={(e: any) => changeContentEditable(e)}
            onClick={(e: any) => handleContentEditableClick(e)}
            onKeyUp={(e: any) => handleContentEditable(e)}
            html={content}
            ref={contentRef}
          /> */}
          <div
            onClick={handleContentEditable}
            onKeyUp={handleContentEditable}
            className={cx(styles.roboSettingModal_descriptionTextarea, disable && 'ant-input-disabled', 'ant-input', contentRule && '!border-[red]')}
            onKeyDown={e => changeContentEditable(e)}
            contentEditable={!disable}
            ref={contentRef}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <p onClick={() => handleContentTag('&amount&')} className={styles.roboSettingModal_description_tag}>
            &amount& - {t('broker.Tolov_miqdori')}
          </p>
          <p onClick={() => handleContentTag('&date&')} className={styles.roboSettingModal_description_tag}>
            &date& - {t('home.Kutilayotgan_tolov_sanasi')}
          </p>
          <br />
          <Form.Item name="automaticCompleted" valuePropName="checked">
            <Checkbox disabled={disable}>{t('auto_task.Tolov_qilinganda_topshiriq_avtomatik_bajarilishi')}</Checkbox>
          </Form.Item>
          <div className={cx(styles.roboSettingModal_footer, 'flex items-center justify-end')}>
            <Modal
              openChild={<Icon name="TrashShaxmatkaIcon" className="mr-auto h-[20px] w-[20px] cursor-pointer text-[#ff0000]" pathProps={{ strokeWidth: 1 }} />}
              centered
              width={300}
              footer={null}
            >
              {({ onCancel }) => (
                <div className="flex flex-col items-center gap-[24px]">
                  <div className="flex h-[32px] w-[32px] items-center justify-center rounded-[24px] bg-[#ffebeb]">
                    <Icon name="TrashShaxmatkaIcon" className="text-[#ff0000]" pathProps={{ strokeWidth: 1 }} />
                  </div>
                  {t('auto_task.Bot_sozlamalarini_ochirmoqchimisiz')}
                  <div className="flex items-center justify-around gap-[16px]">
                    <Button className="w-[120px] rounded-[50px] border-none" onClick={onCancel}>
                      {t('auto_task.Yopish')}
                    </Button>
                    <Button
                      className="w-[120px] rounded-[50px] border-none bg-[#ff0000] text-[#ffffff] hover:bg-[#ff0000] hover:text-[#ffffff]"
                      onClick={() => handleDelete(onCancel)}
                      loading={autoTaskConfigDelete.isLoading}
                    >
                      {t('home_id.Tasdiqlash')}
                    </Button>
                  </div>
                </div>
              )}
            </Modal>
            <Button onClick={handleClose}>{t('auto_task.Yopish')}</Button>
            <Button htmlType="submit" type="primary" loading={autoTaskSettingPut.isLoading}>
              {t('auto_task.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default TaskBotSettingModal;
