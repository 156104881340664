import React, { useEffect } from 'react';
import styles from './showroomV2Petition.module.scss';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { Modal } from 'antd';
import { setShowroomPetition } from '@reducers/ShowroomSlice';
import HomeId from '@pages/homeId/HomeId';

const ShowroomV2Petition = () => {
  const dispatch = useAppDispatch();
  const { apartments, petition } = useAppSelector(state => state.showroomSlice);
  const apartmentsId = apartments?.filter(item => item.id).map(item => item.id);

  const handleClose = () => {
    dispatch(setShowroomPetition({ visible: false }));
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <Modal
      title={false}
      visible={petition?.visible}
      onCancel={handleClose}
      footer={false}
      // width={'max-content'}
      style={{ padding: 0, top: 20 }}
      bodyStyle={{ padding: 0 }}
      maskStyle={{ padding: 0 }}
      wrapClassName={styles.modal}
    >
      <div className={styles.container}>
        <HomeId idFlats={[...apartmentsId]} houseName={petition?.houseName} />
      </div>
    </Modal>
  );
};

export default ShowroomV2Petition;
