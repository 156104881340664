import { createSlice } from '@reduxjs/toolkit';
import { MyCallsMessageType } from '../../utils/models/MyCalls';

interface SendMessageCrm {
  visible: boolean;
  type: MyCallsMessageType;
  phone: string;
}

const initialState: SendMessageCrm = {
  visible: false,
  type: MyCallsMessageType.MOI_ZVONKI,
  phone: ''
};

export const SendMessageCrmSlice = createSlice({
  name: 'sendMessageCrm',
  initialState,
  reducers: {
    handleOpen: (
      state,
      action: {
        payload: {
          visible: boolean;
          type: MyCallsMessageType;
          phone: string;
        };
      }
    ) => {
      state.visible = action.payload.visible;
      state.type = action.payload.type;
      state.phone = action.payload.phone;
    },
    handleType: (
      state,
      action: {
        payload: MyCallsMessageType;
      }
    ) => {
      state.type = action.payload;
    }
  }
});

export const { handleOpen, handleType } = SendMessageCrmSlice.actions;
export default SendMessageCrmSlice.reducer;
