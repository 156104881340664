import React, { memo } from 'react';
import styles from './employee.module.scss';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { useContractEmployee } from '../../../../../queries/queries';
import SubtractIcon from '../../../../../assets/icons/SubtractIcon';
import CheckmarkIcon from '../../../../../assets/icons/crmIcons/CheckmarkIcon';
import CheveronBottom from '../../../../../assets/icons/CheveronBottom';

interface Props {
  ids: string[] | string | undefined;
}

const EmployeeFilter: React.FC<Props> = ({ ids }) => {
  const { appendSingle, appendMultiple, remove } = useQueryParam();

  const { data } = useContractEmployee();

  const { t } = useTranslation();

  // change
  const handleChange = (value: string) => {
    if (Array.isArray(ids)) {
      ids.includes(value) ? remove('responsibleByIds', value) : appendMultiple('responsibleByIds', value);
    } else {
      if (ids === value) {
        remove('responsibleByIds', value);
      } else {
        appendMultiple('responsibleByIds', value);
      }
    }
  };

  // select All
  const selectAll = () => {
    appendSingle('responsibleByIds', undefined);
  };

  // is has responsibleBy
  const isHasResponsibleBy = (id: number) => {
    if (!ids) {
      return false;
    }
    if (Array.isArray(ids)) {
      return ids.includes(String(id));
    }
    return ids === String(id);
  };

  // responsibleBy tags
  const responsibleByTags = () => {
    if (ids) {
      if (Array.isArray(ids)) {
        return (
          <>
            {ids.map((id, index) => {
              const emp = data?.find(emp => emp.id === Number(id));
              if (emp) {
                return (
                  <div key={index} className={styles.container_employee_items}>
                    <span className={styles.container_employee_item}>
                      {emp.fullName}
                      <SubtractIcon
                        onClick={e => {
                          handleChange(String(emp.id));
                          e.stopPropagation();
                        }}
                      />
                    </span>
                  </div>
                );
              }
              return null;
            })}
          </>
        );
      }
      const emp = data?.find(emp => emp.id === Number(ids));
      if (emp) {
        return (
          <div className={styles.container_employee_items}>
            <span className={styles.container_employee_item}>
              {emp.fullName}
              <SubtractIcon
                onClick={e => {
                  handleChange(String(emp.id));
                  e.stopPropagation();
                }}
              />
            </span>
          </div>
        );
      }
      return null;
    }
    return <div className={styles.container_employee_placeholder}>{t('crm_filter.Menejerlar')}</div>;
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_employee_dropdown}
      overlay={
        <>
          <div onClick={selectAll} className={styles.container_employee_dropdown_all}>
            <span className={styles.container_employee_dropdown_all_name}>{t('crm_filter.Sotuv_bolimi')}</span>
            <span className={styles.container_employee_dropdown_all_button}>{t('crm_filter.Barchaga')}</span>
          </div>
          {data?.map((emp, index) => (
            <div onClick={() => handleChange(String(emp.id))} key={index} className={styles.container_employee_dropdown_one}>
              <span className={styles.container_step_dropdown_one_name}>{emp.fullName}</span>
              {isHasResponsibleBy(emp.id) && <CheckmarkIcon />}
            </div>
          ))}
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_employee}>
        {responsibleByTags()}
        <CheveronBottom className={styles.container_employee_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(EmployeeFilter);
