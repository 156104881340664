import React from 'react';

const InformationIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5 4.89965C22.9 3.71965 25.16 3.71965 26.52 4.89965L29.68 7.59965C30.28 8.09965 31.42 8.51965 32.22 8.51965H35.62C37.74 8.51965 39.48 10.2596 39.48 12.3796V15.7796C39.48 16.5796 39.9 17.6996 40.4 18.2996L43.1 21.4596C44.28 22.8596 44.28 25.1196 43.1 26.4796L40.4 29.6396C39.9 30.2396 39.48 31.3596 39.48 32.1596V35.5597C39.48 37.6797 37.74 39.4196 35.62 39.4196H32.22C31.42 39.4196 30.3 39.8396 29.7 40.3396L26.54 43.0396C25.14 44.2196 22.88 44.2196 21.52 43.0396L18.36 40.3396C17.76 39.8396 16.62 39.4196 15.84 39.4196H12.34C10.22 39.4196 8.48 37.6797 8.48 35.5597V32.1396C8.48 31.3596 8.08 30.2196 7.58 29.6396L4.88 26.4596C3.72 25.0796 3.72 22.8396 4.88 21.4596L7.58 18.2796C8.08 17.6796 8.48 16.5596 8.48 15.7796V12.3996C8.48 10.2796 10.22 8.53965 12.34 8.53965H15.8C16.6 8.53965 17.72 8.11965 18.32 7.61965L21.5 4.89965Z"
      stroke="#3772FF"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24 16.2598V25.9198" stroke="#3772FF" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.989 32H24.007" stroke="#3772FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default InformationIcon;
