import React from 'react';
import classNames from 'classnames';

import styles from './lineGradientLayout.module.scss';

interface IProps {
  children: JSX.Element;
  loading?: boolean;
}

const LineGradientLayout = ({ children, loading }: IProps) => (
  <div className={classNames(styles.loader, loading && styles.loader_active)}>
    <span className={styles.loader_child} />
    <div className={styles.loader_children}>{children}</div>
  </div>
);

export default LineGradientLayout;
