import React, { useState } from 'react';
import { Button, DatePicker, DatePickerProps, Modal } from 'antd';
import { useLeadTaskActions } from '@pages/task/services/mutation';
import { SearchParamsTask } from '@utils/models/FilterTask';
import { useQueryParam } from '@hooks/useQueryParams';
import { useAppSelector } from '@hooks/reduxHooks';
import { DATE_WITH_TIME_FORMAT } from '@utils/constants/format';
import { setChecked, setClearTaskListView, setTaskIgnoreIds } from '@reducers/CrmSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@icons/CloseIcon';
import Info1Icon from '@icons/Info1Icon';

import styles from './actionModals.module.scss';

type Props = {
  openEmployeeChange: boolean;
  setOpenEmployeeChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangeTimeModal: React.FC<Props> = ({ openEmployeeChange, setOpenEmployeeChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsTask, any>();
  const changeTaskTime = useLeadTaskActions(searchParams);
  const [resDate, setResDate] = useState<any>();
  const { taskListViewActiveIds: activeIds, taskListViewIgnoreIds } = useAppSelector(state => state.assigmentSlice);

  const handleClose = () => {
    setOpenEmployeeChange(false);
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setResDate(dateString);
  };

  const handleChangeTaskTime = () => {
    changeTaskTime
      .mutateAsync({
        tasks: activeIds,
        rescheduleDate: resDate,
        actionType: 'RESCHEDULE',
        ignoreTasks: taskListViewIgnoreIds.length === 0 ? null : taskListViewIgnoreIds
      })
      .then(() => {
        handleClose();
        dispatch(setClearTaskListView());
        dispatch(setChecked(false));
        dispatch(setTaskIgnoreIds([]));
      });
  };

  return (
    <Modal visible={openEmployeeChange} onCancel={handleClose} title={false} footer={false} className={styles.changeEmployee}>
      <div className={styles.changeEmployee_body}>
        <div className={styles.changeEmployee_title}>
          <Info1Icon />
          <div className={styles.changeEmployee_title_right}>
            <div className={styles.changeEmployee_title_right_top}>
              <p>{t('one_task.Vaqtni_ozgartirish')}</p>
              <CloseIcon onClick={handleClose} />
            </div>
            <span>{t('one_task.Vaqtni_ozgartirmoqchimisiz')}</span>
          </div>
        </div>
        <div className={styles.changeEmployee_select}>
          <span>{t('home.Sana')}</span>
          <DatePicker format={DATE_WITH_TIME_FORMAT} onChange={onChange} />
        </div>
        <div className={styles.changeEmployee_footer}>
          <Button onClick={handleClose}>{t('home.Yopish')}</Button>
          <Button loading={changeTaskTime.isLoading} onClick={handleChangeTaskTime}>
            {t('home_id.Tasdiqlash')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeTimeModal;
