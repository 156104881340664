import React from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import CloseIcon from '@icons/CloseIcon';
import DealIcon from '@icons/DealIcon';
import DefaultWidgetIcon from '@icons/DefaultWidgetIcon';
import FlowIcon from '@icons/FlowIcon';
import SuccessfullIcon from '@icons/SuccessfullIcon';
import { useGetWidgets } from '@pages/dashboard/services/queries';
import { usePipeAll } from '@queries/queries';
import { BASE_URL } from '@service/RequestService';
import { DATE_FORMAT } from '@utils/constants/format';
import { Drawer } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import DefaultWidgetItem from './DefaultWidgetItem';

import styles from './defaultWidget.module.scss';

type Props = {
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  dashPermission: boolean;
};

const DefaultWidgets: React.FC<Props> = ({ openDrawer, setOpenDrawer, dashPermission }) => {
  const { searchParams } = useQueryParam<any, any>();
  const { data } = useGetWidgets(Number(searchParams?.dashboardId));
  const { data: pipeList } = usePipeAll();
  const { t } = useTranslation();
  const startWeek = dayjs().startOf('week').add(1, 'day').format(DATE_FORMAT);
  const endWeek = dayjs().endOf('week').add(1, 'day').format(DATE_FORMAT);

  const pipeId = pipeList?.find(item => item?.orders === 1)?.id;
  const pipeStatusesIds = pipeList?.find(item => item?.orders === 1)?.pipeStatuses?.find(prev => prev?.type === 'SUCCESS')?.id;

  const handleClose = () => setOpenDrawer(false);

  const convertData = () => {
    const maxX = 0;
    let maxY = 0;

    data?.forEach(item => {
      if (item?.position[1] + item?.position[3] > maxY) {
        maxY = item?.position[1] + item?.position[3];
      }
    });
    return { maxX, maxY };
  };

  const defWidgets = [
    {
      id: 1,
      newData: {
        name: 'So’ngi lidlar',
        dashboardId: Number(searchParams?.dashboardId),
        type: 'LIST',
        objectData: JSON.stringify({ voronka: pipeId, pipeId, page: 1 }),
        position: [convertData().maxX, convertData().maxY, 6, 4],
        url: `${BASE_URL}/v1/lead/table-filter`,
        requestType: 'POST',
        fields: '[]',
        groupBy: null,
        customFieldId: null,
        objectType: 'LEAD'
      },
      title: t('emp_dashboard.Songi_lidlar'),
      description: t('emp_dashboard.Oxirgi_kelib_tushgan_lidlar_royhati'),
      icon: <DefaultWidgetIcon />
    },
    {
      id: 3,
      newData: {
        name: 'Bitim bosqichlari',
        dashboardId: Number(searchParams?.dashboardId),
        PIE_CHART: 'PIPE_STATUS',
        type: 'PIE_CHART',
        objectData: JSON.stringify({ pipeId, page: 1, voronka: pipeId }),
        position: [convertData().maxX, convertData().maxY, 5, 6],
        url: `${BASE_URL}/v1/dashboard/widget/chart/v2`,
        requestType: 'POST',
        fields: '[]',
        groupBy: 'PIPE_STATUS',
        customFieldId: null,
        objectType: 'LEAD'
      },
      title: t('emp_dashboard.Bitim_bosqichlari'),
      description: t('emp_dashboard.Bosqichlar_kesimida_lidlar_soni_va_budjet'),
      icon: <DealIcon />
    },

    {
      id: 5,
      newData: {
        name: 'Lidlar oqimi',
        dashboardId: Number(searchParams?.dashboardId),
        type: 'TIMELINE',
        objectData: JSON.stringify({ actionTypes: ['CREATE_LED'], pipeId: null, page: 1 }),
        position: [convertData().maxX, convertData().maxY, 6, 6],
        url: `${BASE_URL}/v1/activity-logs/filter/widget?startDate=${startWeek}&finishDate=${endWeek}`,
        requestType: 'POST',
        fields: '[]',
        groupBy: null,
        customFieldId: null,
        objectType: 'LOG'
      },
      title: t('emp_dashboard.Lidlar_oqimi'),
      description: t('emp_dashboard.Vaqt_kesimida_tizimda_yaratilgan_lidlar_soni'),
      icon: <FlowIcon />
    },
    {
      id: 6,
      newData: {
        name: 'Muvafaqqiyatli yopilgan lidlar',
        dashboardId: Number(searchParams?.dashboardId),
        PIE_CHART: 'EMPLOYEE',
        type: 'PIE_CHART',
        objectData: JSON.stringify({ pipeStatusIds: pipeStatusesIds, voronka: pipeId, pipeId, page: 1 }),
        position: [convertData().maxX, convertData().maxY, 6, 5],
        url: `${BASE_URL}/v1/dashboard/widget/chart/v2`,
        requestType: 'POST',
        fields: '[]',
        groupBy: 'EMPLOYEE',
        customFieldId: null,
        objectType: 'LEAD'
      },
      title: t('emp_dashboard.Muvafaqqiyatli_yopilgan_lidlar'),
      description: t('emp_dashboard.Muvofaqqiyatli_yopilgan_lidlar_soni'),
      icon: <SuccessfullIcon />
    }
  ];

  return (
    <Drawer
      visible={openDrawer}
      onClose={handleClose}
      className={styles.drawer}
      title={
        <div className={styles.title}>
          <span>{t('emp_dashboard.Vidjetlar')}</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
      closeIcon={false}
    >
      {defWidgets?.map(item => <DefaultWidgetItem item={item} handleClose={handleClose} data={data} key={item?.id} dashPermission={dashPermission} />)}
    </Drawer>
  );
};

export default DefaultWidgets;
