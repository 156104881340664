import React, { cloneElement, ReactElement, useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/reduxHooks';
import ArrearageTask from '../../arrearageTask/ArrearageTask';

import styles from './createTask.module.scss';

interface Props {
  visible?: boolean;
  onClose?: (visible: boolean) => void;
  id?: number;
  arrearage?: number;
  clientId?: number;
  children?: ReactElement;
  contracts?: number[] | null;
  ignoreContracts?: number[] | null;
}

interface FormI {
  date: Dayjs;
  time: string | undefined;
  assignedById: number;
  text: string;
  payment: string;
}

const CreateTask: React.FC<Props> = ({ visible, onClose, id, arrearage, clientId, children, contracts, ignoreContracts }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<FormI>();
  const employeeId = useAppSelector(state => state?.userReducer?.user?.id);

  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        date: dayjs(new Date()),
        payment: arrearage?.toLocaleString('ru'),
        assignedById: Number(employeeId)
      });
    }
  }, [visible, form]);

  // handle cancel modal
  const handleCancel = () => {
    form.setFieldsValue({
      time: '',
      assignedById: undefined,
      payment: '',
      text: undefined,
      date: undefined
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onClose && onClose(false);
    setOpen(false);
  };

  return (
    <>
      <Modal
        className={styles.container}
        width={710}
        footer={false}
        visible={visible === undefined ? open : visible}
        title={t('one_task.Topshiriq_yaratish')}
        onCancel={handleCancel}
      >
        <ArrearageTask id={id} clientId={clientId} handleCancel={handleCancel} contracts={contracts} ignoreContracts={ignoreContracts} />
      </Modal>
      {children ? cloneElement(children, { onClick: () => setOpen(true) }) : <></>}
    </>
  );
};

export default CreateTask;
