import React from 'react';

interface Props {
  className?: string;
}

const LinearUser: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 39C30.45 39 39 30.45 39 20C39 9.55 30.45 1 20 1C9.55 1 1 9.55 1 20C1 30.45 9.55 39 20 39Z"
        fill="white"
        stroke="#898F98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20C22.0711 20 23.75 18.3211 23.75 16.25C23.75 14.1789 22.0711 12.5 20 12.5C17.9289 12.5 16.25 14.1789 16.25 16.25C16.25 18.3211 17.9289 20 20 20Z"
        stroke="#898F98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4426 27.5C26.4426 24.5975 23.5551 22.25 20.0001 22.25C16.4451 22.25 13.5576 24.5975 13.5576 27.5"
        stroke="#898F98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinearUser;
