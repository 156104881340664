import React from 'react';

const CheckIconEmployeeChatCrm = () => {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 4.50043L4.56565 8.06608C4.6828 8.18324 4.87275 8.18324 4.98991 8.06608L12.3333 0.722656"
        stroke="#8C8C8C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckIconEmployeeChatCrm;
