import React, { useEffect } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { useCreateCustomDocumentTemplate } from '@queries/mutation';
import { Form, Input, Modal } from 'antd';

import styles from './createShablon.module.scss';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  sendData:
    | {
        name: string;
        id: number;
      }
    | undefined;

  setSendData: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          id: number;
        }
      | undefined
    >
  >;
};

const { Item, useForm } = Form;

const CreateShablon: React.FC<Props> = ({ setOpen, open, sendData, setSendData }) => {
  const [form] = useForm();
  const { appendSingle } = useQueryParam<{ tab: string }, null>();
  const createShablon = useCreateCustomDocumentTemplate();

  useEffect(() => {
    if (sendData) {
      form.setFieldsValue(sendData);
    }
  }, [sendData]);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
    setSendData(undefined);
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: { name: string }) => {
    if (sendData?.id) {
      createShablon.mutateAsync({ name: values?.name, id: Number(sendData?.id) }).then(res => {
        handleClose();
        appendSingle('tab', res?.data?.name);
      });
    } else {
      createShablon.mutateAsync({ name: values?.name }).then(res => {
        handleClose();
        appendSingle('tab', res?.data?.name);
      });
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      title="Shablon qo’shish"
      okText="Saqlash"
      cancelText="Bekor qilish"
      className={styles.createShablonModal}
      onOk={onOk}
      okButtonProps={{ loading: createShablon.isLoading }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        <Item name="name" label="Shablon nomi">
          <Input placeholder="Shablon nomi" />
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateShablon;
