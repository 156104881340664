/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import { setQrCodeModeActive } from '@reducers/QrCodeModeSlice';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { Permissions } from '@utils/constants/permissions';
import { encodeString } from '@utils/helper';
import { hasKril } from '@utils/helper/hasKril';
import message from '@utils/helper/message';
import { Avatar, notification } from 'antd';

// eslint-disable-next-line import/order
import { useExpoContact } from './services/mutation';

import avatarDefaultClient from '@images/avatarDefaultClient.jpg';
import qr_code_scan_img from '@images/qr_code_scan_img.png';

import styles from './qrCodeMode.module.scss';

const QrCodeMode = () => {
  const user = useAppSelector(state => state.userReducer.user);
  const { active } = useAppSelector(state => state.qrCodeModeSlice);
  const dispatch = useAppDispatch();
  const [copyText, setCopyText] = useState('');
  const expoContact = useExpoContact();
  const { push } = useQueryParam();

  const isExpo = user?.role?.permissions?.includes(Permissions.PERMISSION_EXPO);

  const handleScan = () => {
    dispatch(setQrCodeModeActive(!active));
  };

  useEffect(() => {
    const windowEvent = (ev: KeyboardEvent) => {
      if (active) {
        setCopyText(prev => prev + ev.key);
      }
    };

    isExpo && window.addEventListener('keypress', windowEvent);
    return () => {
      isExpo && window.addEventListener('keypress', windowEvent);
    };
  }, [isExpo, active, setCopyText]);

  useEffect(() => {
    if (isExpo && active && copyText.includes('Enter')) {
      if (hasKril(copyText)) {
        message.info("Kompyuteringizni lotin alifbosiga o'tkazing");
        setCopyText('');
      } else {
        const text = copyText.replace('Enter', '');
        // let textAtob = atob(text)?.replace(/â\x80\x99/g, "'")
        const textBtoa = btoa(text);

        setCopyText('');

        expoContact
          .mutateAsync(textBtoa)
          .then(res => {
            notification.open({
              message: (
                <div className={styles.notif} onClick={() => push(`${rootPaths.MAIN + MainPaths.CRM}/${res?.id}/${encodeString(res?.name)}`)}>
                  <Avatar src={avatarDefaultClient} shape="circle" size={32} />
                  <div>
                    <h1>{res?.name}</h1>
                    <p>{res?.phone}</p>
                  </div>
                </div>
              ),
              duration: 3
            });
          })
          .catch(err => {
            setCopyText('');
            console.log(err, 'err');
          });
      }
    }
  }, [copyText, active, isExpo]);

  return isExpo ? (
    <>
      <div className={`${styles.scanBtn} ${active ? styles.scanBtn_active : ''}`} onClick={handleScan}>
        <img src={qr_code_scan_img} alt="" />
      </div>
    </>
  ) : null;
};

export default QrCodeMode;
