export const timeValidatingString = (val: string) => {
  let value = val.split(' ').map(item => item.split(':'));
  let newValue: any[][] = [];

  value.forEach((item, i) => {
    newValue.push([]);
    item.forEach((item2, j) => {
      if ((j + 1) % 2 === 0) {
        if (Number(item2) < 60) {
          newValue[i].push(item2.padEnd(2, '0'));
        } else {
          newValue[i].push('59');
        }
      } else {
        if (Number(item2) < 24) {
          newValue[i].push(item2.padEnd(2, '0'));
        } else {
          newValue[i].push('23');
        }
      }
    });
  });
  let valString = newValue.join(' ').replace(/,/g, ':');

  return valString;
};
