import React, { useEffect, useRef } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Input, Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../assets/icons/SearchIcon';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useClientBalanceSearch } from '../../queries/queries';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';

import styles from "../paymentSearchClient/paymentSearchClient.module.scss";

const { Content } = Layout;

const ClientBalanceSearchClient = () => {
  const inputRef = useRef(null);
  const { appendSingle, remove, searchParams, push } = useQueryParam<{ search: string }, any>();
  const { t } = useTranslation();
  const { data, isLoading } = useClientBalanceSearch(searchParams.search);

  useEffect(() => {
    // @ts-ignore
    inputRef.current!.focus({
      cursor: 'start'
    });
  }, []);

  const handleChangeSearchInput = (value: string) => {
    if (value) appendSingle('search', value);
    else remove('search', undefined);
  };

  const goToCreatePayment = (id: number) => {
    push(`${rootPaths.MAIN + MainPaths.PAYMENT  }/${  id  }${MainPaths.CLIENT_BALANCE}`);
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleChangeSearchInput('')} className="deleteSearchIcon" />;
    return <SearchIcon />;
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.cont}>
          <div className={styles.searchCont}>
            <h1>{t('client_balance.Tolov_qiluvchi_mijoz_qidirish')}</h1>
            <Input
              ref={inputRef}
              value={searchParams.search}
              onChange={e => handleChangeSearchInput(e.target.value)}
              placeholder={t('client_balance.Mijoz_boyicha_qidirish')}
              className={styles.searchCont_input}
              suffix={showIconInput()}
            />
          </div>
          <Spin spinning={isLoading}>
            <div className={styles.items}>
              {data?.pages?.map(data =>
                data?.data?.map((item, index) => (
                  <div onClick={() => goToCreatePayment(item?.id || 0)} key={index} className={styles.items_item}>
                    <div className={styles.items_item_main}>
                      <div className={styles.items_item_main_client_name}>{item.name}</div>
                    </div>
                    <div className={styles.items_item_body}>
                      <div className={styles.items_item_body_title}>{t('client_balance.Telefon')}</div>
                      <div className={styles.items_item_body_value}>{item.activePhone}</div>
                    </div>
                    <div className={styles.items_item_body}>
                      <div className={styles.items_item_body_title}>{t('client_balance.Balans')}</div>
                      <div className={styles.items_item_body_value}>
                        {item.balance?.toLocaleString('ru')} {t('client_balance.som')}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Spin>
        </div>
      </Content>
    </>
  );
};

export default ClientBalanceSearchClient;
