import React from 'react';
import { cx } from '@utils/cx';
import Input from 'antd-mask-input';
import { InputMaskOptions, MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';

import { BaseProps, Wrapper } from './wrapper';

type MaskType = 'number' | 'amount' | 'month' | 'float';

interface MaskProps extends BaseProps, Omit<MaskedInputProps, 'mask'> {
  name: string;
  loading?: boolean;
  maskType?: MaskType;
  errorMessage?: boolean;
  id?: string;
}

function getMaskOptions(type?: MaskType, maskOptions: InputMaskOptions = {}): Pick<MaskedInputProps, 'mask' | 'maskOptions'> {
  if (type === 'number') {
    return { mask: Number, maskOptions: { thousandsSeparator: ' ', radix: '.', ...maskOptions } };
  }

  if (type === 'float') {
    return {
      mask: Number,
      maskOptions: {
        scale: 1,
        thousandsSeparator: ' ',
        padFractionalZeros: false,
        normalizeZeros: false,
        radix: '.',
        mapToRadix: ['.'],
        autofix: true,
        ...maskOptions
      }
    };
  }

  if (type === 'amount') {
    return { mask: Number, maskOptions: { ...maskOptions, thousandsSeparator: ' ', radix: '.', min: 0, max: 100 } as InputMaskOptions };
  }

  if (type === 'month') {
    return { mask: Number, maskOptions: { ...maskOptions, min: 0, max: 360 } as InputMaskOptions };
  }

  return { mask: Number, maskOptions: { ...maskOptions, thousandsSeparator: ' ', radix: '.', scale: 0 } };
}

const Mask: React.FC<MaskProps> = ({
  labelClassName,
  name = '',
  maskType = 'number',
  disabled,
  control,
  loading = false,
  label,
  maskOptions,
  errorMessage = true,
  id,
  ...args
}) => {
  label = label || args.placeholder;

  return (
    <Wrapper {...{ control, name, label, labelClassName, disabled, errorMessage, id }}>
      {(field, error) => (
        <Input
          {...field}
          {...args}
          {...getMaskOptions(maskType, maskOptions)}
          onPaste={e => e.preventDefault()}
          value={String(field.value ?? '') || ''}
          onChange={args.onChange || (({ unmaskedValue }) => field.onChange(unmaskedValue))}
          id={id || name}
          className={cx('field', args.className)}
          disabled={disabled || field.disabled || loading}
          status={error ? 'error' : undefined}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(Mask);
