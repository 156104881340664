import React from 'react';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  stroke?: string;
  size?: number;
}

const CheckIcon: React.FC<Props> = ({ style, className, stroke = 'white', size = 26 }) => {
  return (
    <svg className={className} style={style} width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9375 7.3125L10.5625 18.6875L4.875 13" stroke={stroke} strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CheckIcon;
