import React from 'react';

const ArrowSquareRightIcon = () => {
  return (
    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.53003 9.11833L5.6367 5L1.53003 0.881668" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowSquareRightIcon;
