import React, { useContext, useState } from 'react';
import SearchMdIcon from '@pages/bossCrm/components/conversion/assets/icons/SearchMdIcon';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import { useGetUnsuccessfullLeads } from '@pages/bossCrm/services/mutate';
import { UnsuccessfullLeadsTableData } from '@pages/bossCrm/utils/models/UnsuccessfullLeadsTableData';
import { generateReqBody } from '@utils/index';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import FilterDrawer from './drawer';

import styles from './index.module.scss';

const Filter: React.FC = () => {
  const { t } = useTranslation();
  const [time, setTime] = useState<NodeJS.Timeout>();
  const firstTableDataMutation = useGetUnsuccessfullLeads();
  const { setContext, startDate, endDate, page, name, unsuccessfulLeadsFilter } = useContext(ConversionContext);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setContext({
          name,
          isTableDataLoading: true,
          unsuccessfulLeadsFilter: {
            ...(unsuccessfulLeadsFilter || {}),
            search: value,
            visible: false
          }
        });

        firstTableDataMutation
          ?.mutateAsync(
            generateReqBody({
              size: 10,
              page: page!,
              search: value,
              finishDate: endDate!,
              startDate: startDate!,
              reasonsFRIds: unsuccessfulLeadsFilter?.reasonsFRIds,
              pipeIds: unsuccessfulLeadsFilter?.pipeStatusIds,
              responsibleById: unsuccessfulLeadsFilter?.responsibleById
            })
          )
          .then((data: UnsuccessfullLeadsTableData) => setContext({ firstTableData: data, isTableDataLoading: false }));
      }, 500)
    );
  };

  return (
    <div className={styles.filter}>
      <FilterDrawer />
      <Input defaultValue={undefined} prefix={<SearchMdIcon />} onChange={onChange} placeholder={t("boss_crm_conversion.Lid va sababi bo'yicha qidiring")} />
    </div>
  );
};

export default Filter;
