import React, { useState } from 'react'; // Импорт библиотеки React
import { useTranslation } from 'react-i18next'; // Импорт хука useTranslation из библиотеки react-i18next для работы с локализацией
import styles from './tabs.module.scss'; // Импорт стилей из файла tabs.module.scss
import { PageNameLidInformation } from '../InformationContainer'; // Импорт компонента PageNameLidInformation из InformationContainer
import { useAppSelector } from '../../../../../hooks/reduxHooks'; // Импорт хука useAppSelector из кастомного хука reduxHooks для доступа к состоянию Redux
import { Permissions } from '../../../../../utils/constants/permissions'; // Импорт констант разрешений из файла permissions в папке constants/utils

interface Props {
  onChangePage: (page: PageNameLidInformation) => void; // Интерфейс Props, определяющий свойство onChangePage как функцию, принимающую аргумент типа PageNameLidInformation и возвращающую void
  activePage: PageNameLidInformation;
}

const Tabs: React.FC<Props> = ({ onChangePage, activePage }) => {
  const { t } = useTranslation(); // Использование хука useTranslation для получения функции t для локализации

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  // is access config
  const isAccessConfig = permissions?.includes(Permissions.PERMISSION_SETTINGS_CRM);

  // go to setting
  const goToSettings = () => {
    onChangePage(PageNameLidInformation.SETTINGS);
  };

  const goToStatistics = () => {
    onChangePage(PageNameLidInformation.STATS);
  };

  const goToInfo = () => {
    onChangePage(PageNameLidInformation.INFORMATION);
  };

  return (
    <div className={styles.container} style={{ gap: '20px', cursor: 'pointer' }}>
      <div
        onClick={goToInfo}
        className={`${styles.container_main} ${activePage === PageNameLidInformation.INFORMATION ? styles.container_active : ''}`}
      >
        {t('home.Info')}
      </div>
      <div
        onClick={goToStatistics}
        className={`${styles.container_main} ${activePage === PageNameLidInformation.STATS ? styles.container_active : ''}`}
      >
        {t('navbars.Statistikalar')}
      </div>
      {isAccessConfig && (
        <div
          onClick={goToSettings}
          className={`${styles.container_main} ${activePage === PageNameLidInformation.SETTINGS ? styles.container_active : ''}`}
        >
          {t('integration.Sozlash')}
        </div>
      )}
    </div>
  );
};

export default Tabs;
