import React from 'react';

const CardPosBgIcon = () => {
  return (
    <svg width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.62226 24.106L21.1193 7.62226L19.8924 6.40866C18.0119 4.52823 14.9579 4.52823 13.0642 6.42199L3.42199 16.0642C1.52823 17.9579 1.52823 21.0119 3.40866 22.8924L4.62226 24.106ZM17.6126 30.2409L27.2548 20.5987C29.1486 18.7049 29.1486 15.6509 27.2682 13.7705L21.1201 7.62244L4.63639 24.1062L10.7844 30.2542C12.6649 32.1346 15.7189 32.1346 17.6126 30.2409Z"
        fill="url(#paint0_linear_12244_71146)"
      />
      <g filter="url(#filter0_b_12244_71146)">
        <path
          d="M31.1192 20.5998L21.4792 30.2398C19.5859 32.1331 16.5325 32.1331 14.6525 30.2531L8.50586 24.1065L24.9859 7.62646L31.1325 13.7731C33.0125 15.6531 33.0125 18.7065 31.1192 20.5998Z"
          fill="#FFA692"
        />
      </g>
      <g filter="url(#filter1_bd_12244_71146)">
        <path
          d="M24.9861 7.62655L8.49276 24.1066L7.27943 22.8932C5.39943 21.0132 5.39943 17.9599 7.29276 16.0666L16.9328 6.42655C18.8261 4.53322 21.8794 4.53322 23.7594 6.41322L24.9861 7.62655Z"
          fill="url(#paint1_linear_12244_71146)"
        />
        <path
          d="M23.6887 6.48393L23.6891 6.48432L24.8443 7.62691L8.49279 23.9652L7.35014 22.8225C5.50937 20.9817 5.50901 17.9917 7.36348 16.1373L17.0035 6.49726C18.8579 4.6428 21.848 4.64316 23.6887 6.48393Z"
          stroke="url(#paint2_linear_12244_71146)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter2_bd_12244_71146)">
        <path
          d="M20.8524 26.8L19.0524 28.6C18.6791 28.9733 18.0791 28.9733 17.7058 28.6C17.3324 28.2267 17.3324 27.6267 17.7058 27.2533L19.5058 25.4533C19.8791 25.08 20.4791 25.08 20.8524 25.4533C21.2258 25.8267 21.2258 26.4267 20.8524 26.8Z"
          fill="url(#paint3_linear_12244_71146)"
        />
        <path
          d="M20.7817 26.7293L18.9817 28.5293C18.6475 28.8636 18.1108 28.8636 17.7765 28.5293C17.4422 28.195 17.4422 27.6583 17.7765 27.3241L19.5765 25.5241C19.9108 25.1898 20.4475 25.1898 20.7817 25.5241C21.116 25.8583 21.116 26.395 20.7817 26.7293Z"
          stroke="url(#paint4_linear_12244_71146)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter3_bd_12244_71146)">
        <path
          d="M26.6938 20.9597L23.1071 24.5463C22.7338 24.9197 22.1338 24.9197 21.7605 24.5463C21.3871 24.173 21.3871 23.573 21.7605 23.1997L25.3471 19.613C25.7205 19.2397 26.3205 19.2397 26.6938 19.613C27.0538 19.9863 27.0538 20.5863 26.6938 20.9597Z"
          fill="url(#paint5_linear_12244_71146)"
        />
        <path
          d="M26.6225 19.6831C26.9443 20.0175 26.9443 20.5552 26.6224 20.8896L23.0364 24.4756C22.7021 24.8099 22.1655 24.8099 21.8312 24.4756C21.4969 24.1413 21.4969 23.6047 21.8312 23.2704L25.4178 19.6837C25.7519 19.3497 26.2881 19.3494 26.6225 19.6831Z"
          stroke="url(#paint6_linear_12244_71146)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter4_b_12244_71146)">
        <path
          d="M31.9987 32.6665H7.33203C6.78536 32.6665 6.33203 32.2132 6.33203 31.6665C6.33203 31.1198 6.78536 30.6665 7.33203 30.6665H31.9987C32.5454 30.6665 32.9987 31.1198 32.9987 31.6665C32.9987 32.2132 32.5454 32.6665 31.9987 32.6665Z"
          fill="#FFAC95"
          fillOpacity="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_12244_71146"
          x="-6.49414"
          y="-7.37354"
          width="54.0352"
          height="54.0352"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71146" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12244_71146" result="shape" />
        </filter>
        <filter
          id="filter1_bd_12244_71146"
          x="-9.12891"
          y="-9.99512"
          width="49.1152"
          height="49.1016"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71146" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.447059 0 0 0 0 0.368627 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71146" result="effect2_dropShadow_12244_71146" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71146" result="shape" />
        </filter>
        <filter
          id="filter2_bd_12244_71146"
          x="2.42578"
          y="10.1733"
          width="33.707"
          height="33.7065"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71146" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.447059 0 0 0 0 0.368627 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71146" result="effect2_dropShadow_12244_71146" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71146" result="shape" />
        </filter>
        <filter
          id="filter3_bd_12244_71146"
          x="6.48047"
          y="4.33301"
          width="35.4824"
          height="35.4932"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71146" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.447059 0 0 0 0 0.368627 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71146" result="effect2_dropShadow_12244_71146" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71146" result="shape" />
        </filter>
        <filter
          id="filter4_b_12244_71146"
          x="-8.66797"
          y="15.6665"
          width="56.666"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71146" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12244_71146" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_12244_71146" x1="17.3023" y1="20.1169" x2="-6.13361" y2="31.5179" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA78F" />
          <stop offset="1" stopColor="#F23E2C" />
        </linearGradient>
        <linearGradient id="paint1_linear_12244_71146" x1="23.6309" y1="8.45426" x2="2.33859" y2="9.14897" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint2_linear_12244_71146" x1="24.1671" y1="14.9598" x2="5.58311" y2="15.7345" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5D5D5" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_12244_71146" x1="20.8696" y1="25.8427" x2="16.7408" y2="25.9773" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint4_linear_12244_71146" x1="20.9736" y1="27.1051" x2="17.3699" y2="27.2552" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint5_linear_12244_71146" x1="26.575" y1="20.325" x2="20.4671" y2="20.5238" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint6_linear_12244_71146" x1="26.7289" y1="22.1959" x2="21.3978" y2="22.4176" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CardPosBgIcon;
