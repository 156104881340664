import React, { memo } from 'react';
import styles from './filterDrawer.module.scss';
import { Dropdown } from 'antd';
import { useQueryParam } from '@hooks/useQueryParams';
import { LeadTaskTypeFilter } from '@utils/models/FilterTask';
import CheveronBottom from '@icons/CheveronBottom';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  type: LeadTaskTypeFilter | undefined;
}

const TaskTypeFilter: React.FC<Props> = ({ type }) => {
  const { appendSingle } = useQueryParam();

  const { t } = useTranslation();

  // changePipeStatus
  const changeReasons = (value: LeadTaskTypeFilter | undefined) => {
    appendSingle('leadTaskTypeFilter', value);
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_dropdown}
      overlay={
        <>
          <div onClick={() => changeReasons(undefined)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskTypeFilter.ALL}`)}</span>
            {!type && <CheckmarkIcon />}
          </div>
          <div onClick={() => changeReasons(LeadTaskTypeFilter.CONTRACT)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskTypeFilter.CONTRACT}`)}</span>
            {type === LeadTaskTypeFilter.CONTRACT && <CheckmarkIcon />}
          </div>
          <div onClick={() => changeReasons(LeadTaskTypeFilter.LEAD)} className={styles.container_dropdown_one}>
            <span className={styles.container_dropdown_one_name}>{t(`calendar_view.${LeadTaskTypeFilter.LEAD}`)}</span>
            {type === LeadTaskTypeFilter.LEAD && <CheckmarkIcon />}
          </div>
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_employee}>
        <span className={styles.container_employee_placeholder}>{t('one_task.Topshiriq_manbaasi')}</span>
        <CheveronBottom className={styles.container_employee_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(TaskTypeFilter);
