import React, { FC, useRef } from 'react';

interface DropDownProps {
  top?: number;
  left?: number;
  visible?: boolean;
  children?: React.ReactNode;
}

const DropDown: FC<DropDownProps> = ({ left, top, visible, children }) => {
  const dropDown = useRef<any>(null);
  return (
    <div
      className={visible ? 'dropDown active' : 'dropDown'}
      ref={dropDown}
      style={{
        top: top ? (top >= window.innerHeight - dropDown.current?.clientHeight ? window.innerHeight - dropDown.current?.clientHeight : top) : 0,
        left: left ? (left >= window.innerWidth - 254 ? window.innerWidth - 254 : left) : 0
      }}
    >
      {children}
    </div>
  );
};

export default DropDown;
