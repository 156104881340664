import { FC, useState } from 'react';
import { Select } from 'antd';

import { useCreateTag } from '../../queries/mutation';
import { useTags } from '../../queries/queries';

type IProps =
  | { isId: true; onChange?: (value: number[]) => void; value?: number[] | null | undefined }
  | {
      isId?: false;
      onChange?: (value: string[]) => void;
      value?: string[] | null | undefined;
    };

const TagSelectV2: FC<IProps> = ({ onChange, value, isId }) => {
  const { data } = useTags();
  const createTag = useCreateTag();
  const [search, setSearch] = useState('');

  return (
    <Select
      size="middle"
      value={value as never}
      mode="multiple"
      onChange={onChange as never}
      searchValue={search}
      className="customSelect"
      maxTagCount="responsive"
      onSearch={e => {
        setSearch(e);
      }}
      onInputKeyDown={e => {
        if (e.key === 'Enter') {
          createTag.mutateAsync(e?.currentTarget?.value).then(val => {
            if (onChange) {
              onChange([...(value || []), val?.data?.name as string] as never);
            }

            setSearch('');
          });
        }
      }}
    >
      {data?.tagList?.map(item => (
        <Select.Option key={item?.id} value={isId ? item?.id : item?.name}>
          {item?.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TagSelectV2;
