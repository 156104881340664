import React, { ChangeEventHandler, useState } from 'react';
import { Input, Spin } from 'antd';
import styles from './messageSendModal.module.scss';
import { useTranslation } from 'react-i18next';
import Editable from './Editable';
import { TemplateMessage as TemplateMessageInterface } from '../../../../utils/models/TemplateMessage';
import TrushSquareIcon from '../../../../assets/icons/TrushSquareIcon';
import { useMessageTemplateDelete, useMessageTemplateSave } from '../../services/mutation';

interface Props {
  handleChangeVisibleTemplatePage: (visible: boolean, template: any | undefined) => void;
  template: TemplateMessageInterface | undefined;
}

const TemplateMessage: React.FC<Props> = ({ handleChangeVisibleTemplatePage, template }) => {
  const [errors, setErrors] = useState({
    name: false,
    text: false
  });
  const [name, setName] = useState(template?.title || '');

  const { t } = useTranslation();

  const saveTemplate = useMessageTemplateSave(handleSuccess);
  const deleteMessageTemplate = useMessageTemplateDelete(handleSuccess);

  // handle success
  function handleSuccess() {
    setName('');
    handleChangeVisibleTemplatePage(false, undefined);
  }

  // handle name
  const handleName: ChangeEventHandler<HTMLInputElement> = e => {
    setName(e.target.value);
  };

  // updateTemplate
  const updateTemplate = (value: string) => {
    if (!name || !value) {
      setErrors({
        text: !value,
        name: !name
      });
    } else {
      setErrors({
        text: false,
        name: false
      });
      saveTemplate.mutate({
        title: name,
        text: value,
        id: template?.id
      });
    }
  };

  // handle close template page
  const handleCloseTemplatePage = () => {
    setName('');
    handleChangeVisibleTemplatePage(false, undefined);
  };

  // handle delete
  const handleDelete = () => {
    deleteMessageTemplate.mutate(template?.id || 0);
  };

  return (
    <Spin spinning={deleteMessageTemplate.isLoading}>
      <div className={styles.template}>
        <div className={styles.template_select_cont}>
          <Input
            status={errors.name ? 'error' : undefined}
            value={name}
            onChange={handleName}
            className={'customInput ' + styles.template_select_cont_select}
            placeholder={t('my_calls.Nom_kiritiriting')}
          />
          {template && <TrushSquareIcon onClick={handleDelete} className={styles.template_select_cont_settings_icon} />}
        </div>
        <Editable
          isTemplatePage={true}
          handleCloseTemplatePage={handleCloseTemplatePage}
          loading={saveTemplate.isLoading}
          error={errors.text}
          text={template?.text || ''}
          success={updateTemplate}
        />
      </div>
    </Spin>
  );
};

export default TemplateMessage;
