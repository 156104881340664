import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { useCrmEmployee } from '../../../../queries/queries';
import { setEmployeeId } from '../../../../store/reducers/CrmStatisticsSlice';

import styles from './header.module.scss';

const { Option } = Select;
const Employee = () => {
  const { data } = useCrmEmployee();

  const dispatch = useAppDispatch();
  const employeeId = useAppSelector(state => state.crmStatistic.employeeId);

  const { t } = useTranslation();

  // handle change employee
  const handleChangeEmployee = (val: number | undefined) => {
    dispatch(setEmployeeId(val));
  };

  return (
    <Select
      optionFilterProp="children"
      showSearch
      placeholder={t('boss_crm.Xodim_barchasi')}
      defaultValue={t('boss_crm.Xodim_barchasi')}
      value={employeeId}
      onChange={handleChangeEmployee}
      className={`customSelect ${styles.container_select_employee}`}
    >
      <Option value={undefined}>{t('boss_crm.Xodim_barchasi')}</Option>
      {data?.map((emp, index) => (
        <Option key={index} value={emp.id}>
          {emp.fullName}
        </Option>
      ))}
    </Select>
  );
};

export default Employee;
