import { defaultData, keys, models } from '../constants';

export function getModel(model: keyof typeof models, data: string = '', key: keyof typeof keys) {
  if (model === models.string) {
    return data || defaultData[key];
  }
  if (model === models.number) {
    return Number(data);
  }
  return '';
}
