import React from 'react';

interface IProps {
  checked?: boolean;
}

const CheckCircleIcon = ({ checked }: IProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_22099_99137)">
        <path
          d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6826 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6826 4.3168 14.6673 7.9987 14.6673Z"
          fill={checked ? '#00A389' : ''}
          stroke="#00A389"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {checked && <path d="M5 8L7 10L11 6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />}
      </g>
      <defs>
        <clipPath id="clip0_22099_99137">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircleIcon;
