import React, { FC } from 'react';

interface IProps {
  size?: number;
  color?: string;
}

const GlphIcon: FC<IProps> = ({ size = 14, color = '#0172CB' }) => (
  <svg width={size} height={size} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.06634 4.36659C8.06634 3.90436 7.6619 3.49992 7.19967 3.49992C6.73745 3.49992 6.33301 3.90436 6.33301 4.36659C6.33301 4.82881 6.67967 5.23325 7.19967 5.23325C7.71967 5.23325 8.06634 4.82881 8.06634 4.36659ZM6.2219 10.8333H8.44412C8.77745 10.8333 8.99967 10.6759 8.99967 10.3613C8.99967 10.0467 8.77745 9.837 8.44412 9.837H7.99967C7.94412 9.837 7.88856 9.78456 7.88856 9.73213V7.21527C7.88856 6.63849 7.38856 6.16658 6.77745 6.16658H6.2219C5.88856 6.16658 5.66634 6.37632 5.66634 6.69093C5.66634 7.00554 5.88856 7.21527 6.2219 7.21527H6.66634C6.7219 7.21527 6.77745 7.26771 6.77745 7.32014V9.67969C6.77745 9.73213 6.7219 9.78456 6.66634 9.78456H6.2219C5.88856 9.78456 5.66634 9.9943 5.66634 10.3089C5.66634 10.6235 5.88856 10.8333 6.2219 10.8333ZM6.99967 0.833252C3.33301 0.833252 0.333008 3.83325 0.333008 7.49992C0.333008 11.1666 3.33301 14.1666 6.99967 14.1666C10.6663 14.1666 13.6663 11.1666 13.6663 7.49992C13.6663 3.83325 10.6663 0.833252 6.99967 0.833252Z"
      fill={color}
    />
  </svg>
);

export default GlphIcon;
