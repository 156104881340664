import React from 'react';

const ShaxmatkaIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="21" height="20" fill="#00A389" />
      <mask id="path-2-inside-1_6983_35992" fill="white">
        <path d="M0 20H21V41H0V20Z" />
      </mask>
      <path d="M0 20H21V41H0V20Z" fill="white" />
      <path d="M0 41H-1V42H0V41ZM21 40H0V42H21V40ZM1 41V20H-1V41H1Z" fill="#00A389" mask="url(#path-2-inside-1_6983_35992)" />
      <mask id="path-4-inside-2_6983_35992" fill="white">
        <path d="M21 0H41V20H21V0Z" />
      </mask>
      <path d="M21 0H41V20H21V0Z" fill="white" />
      <path d="M41 0H42V-1H41V0ZM21 1H41V-1H21V1ZM40 0V20H42V0H40Z" fill="#00A389" mask="url(#path-4-inside-2_6983_35992)" />
      <rect x="21" y="20" width="20" height="21" fill="#00A389" />
    </svg>
  );
};

export default ShaxmatkaIcon;
