import React from 'react';
import styles from './clientBalance.module.scss';
import Download from '../../assets/icons/Download';
import { useDownloadClientBalance } from '../../queries/mutation';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  id: string;
}

const DownloadButton: React.FC<Props> = ({ id, name }) => {
  const downloadBalance = useDownloadClientBalance(id, name);
  const { t } = useTranslation();

  const handleClick = () => {
    downloadBalance.mutate();
  };

  return (
    <Spin spinning={downloadBalance.isLoading}>
      <div onClick={handleClick} className={styles.out_in_button}>
        <Download className={styles.out_in_button_icon} />
        <div className={styles.out_in_button_title}>{t('client_balance.Yuklab_olish')}</div>
      </div>
    </Spin>
  );
};

export default DownloadButton;
