import { Icon } from '@components/index';
import { $api, BASE_URL } from '@service/RequestService';
import { downloadFile } from '@utils/helper/downloadFile';
import { Spin } from 'antd';
import { useMutation } from 'react-query';
import { cx, formatBytes } from 'utils';

import { File } from 'modules/common';

interface IProps {
  item: File;
}

const FilesHistoryCard = ({ item }: IProps) => {
  const handleDownload = useMutation(
    async () => {
      const res = await $api.get(`${BASE_URL}/${item?.absolutePath}`, { responseType: 'blob' });

      return res.data;
    },
    {
      onSuccess: res => {
        downloadFile(res, item?.originalName, item?.format);
      }
    }
  );

  return (
    <div
      className={cx(
        'group flex h-max min-h-[100%] justify-between rounded-[12px] border-[1px] border-solid border-[#EAECF0] p-[16px]',
        (item?.status === 'ERROR' || item?.status === 'CANCELED') && 'bg-[#EAECF0]'
      )}
    >
      <div className="flex w-[100%] gap-[16px]">
        <Icon name={item?.format as any} className="min-h-[40px] min-w-[42px] " />
        <div className="flex w-full flex-col">
          <p className="w-[95%] overflow-hidden text-ellipsis whitespace-nowrap text-[14px] font-[600] text-[#1D2939]" title={item?.originalName}>
            {item?.originalName}
          </p>
          <p className="text-[14px] font-[400] text-[#475467]">
            {formatBytes({ bytes: item?.size })} | {item?.createdDate}
          </p>
        </div>
      </div>
      {item?.size ? (
        <div
          className="flex h-[40px] min-w-[40px] cursor-pointer items-center justify-center rounded-[12px] bg-[#F2F4F7] opacity-0 duration-[0.3s] group-hover:opacity-[1]"
          onClick={() => {
            if (!handleDownload.isLoading) {
              handleDownload.mutate();
            }
          }}
        >
          <Spin spinning={handleDownload.isLoading}>
            <Icon name="download" className="h-[22px] w-[22px] " stroke="#475467" />
          </Spin>
        </div>
      ) : null}
    </div>
  );
};

export default FilesHistoryCard;
