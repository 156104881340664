import React, { useState } from 'react';
import { BASE_URL } from '@service/RequestService';
import { FileObject } from '@utils/models/File';
import { Image } from 'antd';

import styles from '@pages/shaxmatka/shaxmatka.module.scss';

interface Props {
  image: FileObject;
}

const ExtraImageCont: React.FC<Props> = ({ image }) => {
  const [openPreview, setOpenPreview] = useState(false);

  return (
    <>
      <img
        onClick={() => setOpenPreview(true)}
        alt="extra_image"
        src={`${BASE_URL}/${image?.thumbAbsolutePath}`}
        className={styles.extra_image_item_img}
      />
      <Image
        style={{
          display: 'none'
        }}
        src={`${BASE_URL}/${image?.absolutePath}`}
        preview={{
          visible: openPreview,
          onVisibleChange: value => {
            setOpenPreview(value);
          }
        }}
      />
    </>
  );
};

export default ExtraImageCont;
