import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import AddCircleIcon from '../../../../assets/icons/AddCircleIcon';
import TrushIcon from '../../../../assets/icons/crmIcons/TrushIcon';
import TickCircleIcon from '../../../../assets/icons/TickCircleIcon';
import { useFlatFieldGet, useHousesNameAndID } from '../../../../queries/queries';
import { ChangesPageFlats } from '../../../../utils/models/ContractId';
import { Flat } from '../../../../utils/models/Flat';
import { useFlatCompact, useGetBuildings } from '../../services/queries';

import styles from './Contract.module.scss';

interface Props {
  flat: ChangesPageFlats;
  index: number;
  isLastOne: boolean;
  length: number;
  addNew: () => void;
  changeHouse: (index: number, id: number) => void;
  changeFlat: (index: number, id: number) => void;
  changeBuilding: (index: number, id: number) => void;
  changeRepaired: (index: number, repaired: boolean) => void;
  deleteFlat: (index: number) => void;
}

const FlatInformation: React.FC<Props> = ({ flat, index, isLastOne, addNew, changeHouse, changeBuilding, changeRepaired, changeFlat, deleteFlat, length }) => {
  const { data: flatField } = useFlatFieldGet();
  const { data: houseCompact, isLoading: houseLoading } = useHousesNameAndID();
  const { t } = useTranslation();

  const { data: getBuildings, isLoading: buildingLoading } = useGetBuildings(flat.houseId);
  const { data: getFlatCompact, isLoading: flatLoading } = useFlatCompact(flat.buildingId);

  // changeHouseInside
  const changeHouseInside = (value: number) => {
    changeHouse(index, value);
  };

  // changeBuildingInside
  const changeBuildingInside = (value: number) => {
    changeBuilding(index, value);
  };

  // changeFlatInside
  const changeFlatInside = (value: number) => {
    changeFlat(index, value);
  };

  // change repaired
  const changeRepairedInside = (val: boolean) => {
    changeRepaired(index, val);
  };

  // handle delete
  const handleDelete = () => {
    deleteFlat(index);
  };

  const flatCopy = {
    id: flat?.id,
    number: flat?.flatNumber,
    area: flat?.area,
    entranceName: flat?.entranceName,
    floor: flat?.floor,
    entrance: flat?.entrance,
    fields: []
  } as any;

  const getFlatCompactConvert = getFlatCompact?.filter(item => item?.id !== flat?.id).concat(flat?.flatId && flat?.flatId > 0 ? [flatCopy] : []);

  return (
    <>
      <h3>
        {index + 1}-{t('changes.contract.xonadon')} {length > 1 ? <TrushIcon onClick={handleDelete} /> : null}
      </h3>
      <Row gutter={[4, 0]}>
        <Col span={12}>
          <Form.Item rules={[{ required: true, message: '' }]} name={`${index}houseId`} className="changesInput" label={t('changes.contract.TJM')}>
            <Select optionFilterProp="children" showSearch loading={houseLoading} onChange={changeHouseInside} className="selectRoomInformation">
              {houseCompact?.map(house => (
                <Select.Option key={house.id} value={house.id}>
                  {house.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item rules={[{ required: true, message: '' }]} name={`${index}buildingId`} className="changesInput" label={t('changes.contract.Bino')}>
            <Select optionFilterProp="children" showSearch onChange={changeBuildingInside} loading={buildingLoading} className="selectRoomInformation">
              {getBuildings?.map((building: any) => (
                <Select.Option key={building.id} value={building.id}>
                  {building.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item rules={[{ required: true, message: '' }]} name={`${index}flatId`} className="changesInput" label={t('changes.contract.Xonadon')}>
            <Select showSearch optionFilterProp="children" loading={flatLoading} onChange={changeFlatInside} className="selectRoomInformation">
              {getFlatCompactConvert?.map((compact: Flat) => (
                <Select.Option key={compact.id} value={compact.id}>
                  {compact.number}-{t('changes.contract.Xonadon')},{compact?.entranceName || compact?.entrance}-{t('changes.contract.Podyez')},{compact.floor}-
                  {t('changes.contract.etaj')},{compact.area}-{t('create_table_payments.m2')}
                  {compact?.fields?.map(field => {
                    if (flatField) {
                      const findField = flatField.find(f => f.id === field.fieldId);

                      if (findField) {
                        return `,${findField.name}-${field.value}`;
                      }
                      return '';
                    }
                    return '';
                  })}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className="changesInput" label={t('changes.contract.Xonadon_holati')}>
        <div onClick={() => changeRepairedInside(true)} className={flat.repaired ? styles.activeCondition : styles.condition}>
          <p>{t("changes.contract.Ta'mirli")}</p>
          <TickCircleIcon />
        </div>
        <div onClick={() => changeRepairedInside(false)} className={flat.repaired ? styles.condition : styles.activeCondition}>
          <p>{t("changes.contract.Ta'mirsiz")}</p>
          <TickCircleIcon />
        </div>
      </Form.Item>

      {isLastOne && (
        <div className={styles.addFlat} onClick={addNew}>
          <AddCircleIcon />
          <span>{t('changes.contract.Xonadon_qoshish')}</span>
        </div>
      )}
    </>
  );
};

export default FlatInformation;
