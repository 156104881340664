export const endPoints = {
  GET_SMSBOT: '/v1/smsBot/config',
  POST_SMSBOT: '/v1/smsBot/config',
  GET_LEADTASKTYPE: '/v1/smsBot/config/leadTaskType',
  GET_ORIGINATOR: '/v1/smsBot/config/originator',
  GET_MESSAGE: '/v1/smsBot/message/filter',
  DELETE_MESSAGE: '/v1/smsBot/message',
  CHANGE_STATUS: '/v1/smsBot/config/change-status',
  CONFIG_ALL: '/v1/smsBot/config/all'
};
