import React from 'react';

const FilterReloadIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 6.75C13 10.2 10.2 13 6.75 13C3.3 13 1.19375 9.525 1.19375 9.525M1.19375 9.525H4.01875M1.19375 9.525V12.65M0.5 6.75C0.5 3.3 3.275 0.5 6.75 0.5C10.9188 0.5 13 3.975 13 3.975M13 3.975V0.85M13 3.975H10.225"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterReloadIcon;
