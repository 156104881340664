import React, { useEffect, useState } from 'react';
import styles from './botConfig.module.scss';
import { Form, Input, Spin } from 'antd';
import CancelBronGarbage from '../../../assets/icons/CancelBronGarbage';
import { Faq, LangBot } from '../../../utils/models/TelegramBot';
import { useDeleteFaq, useUpdateFaq } from '../../../queries/mutation';
import TickSquareIcon from '../../../assets/icons/TickSquareIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  faq: Faq;
  lang: LangBot;
}

const FaqItem: React.FC<Props> = ({ faq, lang }) => {
  const [isChangeFaq, setIsChangeFaq] = useState(false);

  const [form] = Form.useForm<Faq>();

  const deleteFaq = useDeleteFaq(faq.id, lang);
  const updateFaq = useUpdateFaq(lang);

  const { t } = useTranslation();

  useEffect(() => {
    if (faq) {
      form.setFieldsValue(faq);
    }
  }, [faq, form]);

  const handleDelete = () => {
    deleteFaq.mutate();
  };

  const handleUpdate = () => {
    updateFaq.mutate({
      ...form.getFieldsValue(),
      id: faq.id,
      lang
    });
    setIsChangeFaq(false);
  };

  const handleButton = () => {
    if (isChangeFaq) {
      handleUpdate();
    } else {
      handleDelete();
    }
  };

  return (
    <Spin spinning={deleteFaq.isLoading || updateFaq.isLoading}>
      <Form form={form} name={`faqItem${faq.id}`}>
        <div className={styles.faqModal_item}>
          <div className={styles.faqModal_item_input}>
            <p className={styles.faqModal_item_input_label}> {t('integration.Savol')}</p>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
              name={'question'}
            >
              <Input.TextArea onChange={() => setIsChangeFaq(true)} autoSize={{ minRows: 4, maxRows: 4 }} className={'customInput'} />
            </Form.Item>
          </div>
          <div className={styles.faqModal_item_input}>
            <p className={styles.faqModal_item_input_label}>{t('integration.Javob')}</p>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: ''
                }
              ]}
              name={'answer'}
            >
              <Input.TextArea onChange={() => setIsChangeFaq(true)} autoSize={{ minRows: 4, maxRows: 4 }} className={'customInput'} />
            </Form.Item>
          </div>
          <div onClick={handleButton} className={styles.faqModal_item_button}>
            {isChangeFaq ? <TickSquareIcon /> : <CancelBronGarbage />}
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default FaqItem;
