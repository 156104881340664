import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HouseState {
    filesHistoryVisible: boolean
    refetchTimers: Record<number,number> | undefined
}

const initialState: HouseState = {
    filesHistoryVisible: false,
    refetchTimers: undefined
};

export const ioServiceSlice = createSlice({
  name: 'ioService',
  initialState,
  reducers: {
    openFilesHistory: (state, action: PayloadAction<boolean>) => {
      state.filesHistoryVisible = action.payload
    },
    setRefetchTimer:(state, action: PayloadAction< Record<number,number> | undefined>) =>{
      state.refetchTimers = action.payload
    }
  }
});

export const { openFilesHistory, setRefetchTimer } = ioServiceSlice.actions;
export default ioServiceSlice.reducer;
