import React, { useEffect, useState } from 'react';
import LineGradientLayout from '@components/line-gradient-layout/LineGradientLayout';
import { Select, SelectProps } from 'antd';

import styles from './customSelect.module.scss';

interface IProps extends SelectProps {
  hasValue?: boolean;
  ai?: boolean;
  onStopAiTime?: () => void;
}

const CustomSelect = ({ hasValue, ai, onStopAiTime, ...props }: IProps) => {
  const [opacity, setOpacity] = useState(0);
  const value = props?.value?.toString() || '';

  useEffect(() => {
    if (ai) {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
      if (onStopAiTime) {
        setTimeout(
          () => {
            onStopAiTime();
            setOpacity(0);
          },
          (value?.length / 6) * 1000
        );
      }
    }
  }, [ai, value]);

  return (
    <LineGradientLayout loading={ai}>
      {ai ? (
        <div className={styles.input}>
          {(props?.value?.toString() || '')?.split('').map((item: any, i: number) => (
            <span key={item} style={{ transitionDelay: `${i / 8}s`, opacity }}>
              {item}
            </span>
          ))}
        </div>
      ) : (
        <Select {...props}>{props.children}</Select>
      )}
    </LineGradientLayout>
  );
};

export default CustomSelect;
