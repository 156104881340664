import React, { useEffect, useState } from 'react';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useBuilderList, useTableConfig } from '../../queries/queries';
import { TableKey } from '../../utils/models/TableKey';
import AddSquareBackground from '../../assets/icons/AddSquareBackground';
import styles from './builderSettings.module.scss';
import { useQueryParam } from '../../hooks/useQueryParams';
import CreateBuilderSettings from '../../components/builderSettings/CreateBuilderSettings';
import TableBuilder from '../../components/builderSettings/TableBuilder';
import BuilderFilter from '../../components/builderSettings/BuilderFilter';
import TableSettingsMine from '../../components/filters/TableSettingMine';
import MainHeader from '../../components/headers/MainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import { Layout } from 'antd';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';

const { Content } = Layout;

export interface SearchParamsBuilder {
  page?: string;
  size?: string;
  search?: string;
}

const BuilderSettings = () => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsBuilder, any>();
  const [tableSize, setTableSize] = useState(10);

  const { data, isLoading, isFetching } = useBuilderList(searchParams);
  const [modalVisible, setModalVisible] = useState({ visible: false, id: null });

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.builder);

  useEffect(() => {
    if (tableConfig?.size) {
      appendMultipleDifferent(['page', 'size'], [1, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };

  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className="builder_settings_page">
          <div className={'d-flex align-center justify-space-between'}>
            <BuilderFilter />
            <TableSettingsMine size={'small'} tableKey={TableKey.builder} />
            <div
              className={styles.settingBuilderAdd}
              onClick={() => {
                setModalVisible({ visible: true, id: null });
              }}
            >
              <AddSquareBackground />
            </div>
          </div>
          <TableBuilder
            data={data?.data || []}
            isLoading={isLoading}
            tableConfig={tableConfig?.config || []}
            tableConfigFetch={tableConfigFetch}
            isFetching={isFetching}
            setModalVisible={setModalVisible}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
          <CreateBuilderSettings id={modalVisible.id} visible={modalVisible.visible} setVisible={setModalVisible} />
        </div>
      </Content>
    </>
  );
};

export default BuilderSettings;
