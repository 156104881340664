import { useMutation, useQueryClient } from 'react-query';
import { $api } from '../../../../service/RequestService';
import { endpoints } from '../utils/constants/endpoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { SocialNetworkUpdateModel } from '../utils/models/SocialNetworkUpdateModel';
import { showMessage } from '../../../../queries/showMessage';
import { baseErrorHandler } from '../../../../queries/baseErrorHandler';

export function useUpdateSocialNetworkUrls() {
  const qc = useQueryClient();
  return useMutation(
    async (data: SocialNetworkUpdateModel[]) => {
      const res = await $api.post(endpoints.SOCIAL_NETWORK_URLS, data);
      return res.data;
    },
    {
      onSuccess: res => {
        qc.invalidateQueries([queryKeys.SOCIAL_NETWORK_URLS]);
        showMessage(res.message, res.accept);
      },
      onError: baseErrorHandler
    }
  );
}
