import React, { FC } from 'react';
import styles from './leadStatisticLine.module.scss';
import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';
import { StatLeadDateDto } from '../../../utils/models/StatLead';
import { BossPaymentsType } from '../../../../../utils/models/BossPayments';
import { DATE_FORMAT } from '../../../../../utils/constants/format';
import { useTranslation } from 'react-i18next';
import DotIcon from '@icons/contract/DotIcon';

interface IProps {
  data: StatLeadDateDto[] | undefined;
  dateType: string;
  date: {
    startDate: string;
    finishDate: string;
  };
}

const LeadStatisticLine: FC<IProps> = ({ data, dateType, date }) => {
  const dateConvert = (date: string) => {
    return dayjs(date, DATE_FORMAT).startOf(startDateObj[dateType]?.end).unix();
  };
  const { t } = useTranslation();

  const startDateObj: any = {
    [BossPaymentsType.MONTH]: {
      end: 'month',
      add: 'month'
    },
    [BossPaymentsType.YEAR]: {
      end: 'year',
      add: 'year'
    },
    [BossPaymentsType.DAY]: {
      end: 'date',
      add: 'day'
    }
  };

  const dataCopy = data?.map(item => ({
    x: item?.date,
    y: dateConvert(item?.date) >= dayjs().startOf(startDateObj[dateType]?.end).unix() ? null : item?.countLead,
    ...item
  }));
  const dataCopy2 = data?.map(item => ({
    x: item?.date,
    y:
      dateConvert(item?.date) === dayjs().startOf(startDateObj[dateType]?.end).unix() ||
      dateConvert(item?.date) === dayjs().startOf(startDateObj[dateType]?.end).add(-1, startDateObj[dateType]?.add).unix()
        ? item?.countLead
        : null,
    ...item
  }));

  const dataCopySucces = data?.map(item => ({
    x: item?.date,
    y: dateConvert(item?.date) >= dayjs().startOf(startDateObj[dateType]?.end).unix() ? null : item?.countSuccessLead,
    ...item
  }));
  const dataCopySucces2 = data?.map(item => ({
    x: item?.date,
    y:
      dateConvert(item?.date) === dayjs().startOf(startDateObj[dateType]?.end).unix() ||
      dateConvert(item?.date) === dayjs().startOf(startDateObj[dateType]?.end).add(-1, startDateObj[dateType]?.add).unix()
        ? item?.countSuccessLead
        : null,
    ...item
  }));

  const getDate = (value: string) => {
    const obj = {
      [BossPaymentsType.MONTH]: t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`),
      [BossPaymentsType.YEAR]: dayjs(value, DATE_FORMAT).format('YYYY'),
      [BossPaymentsType.DAY]: value
    };
    return obj[dateType as keyof typeof obj];
  };

  const dataConvert = [
    { id: '1', color: '#0061FF', data: dataCopy || [] },
    { id: '2', color: '#cccccc', data: dataCopy2 || [] },
    { id: '3', color: '#00ff00', data: dataCopySucces || [] },
    { id: '4', color: '#cccccc', data: dataCopySucces2 || [] }
  ];

  const dataMaxLead = Math.max(...(data?.map(item => item?.countLead) || [0]));

  return (
    <div className={styles.container}>
      <ResponsiveLine
        data={dataConvert}
        margin={{ top: 10, right: 40, bottom: 100, left: 40 }}
        // animate={false}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          // stacked: true,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisLeft={{
          ...(dataMaxLead <= 10 && {
            tickValues: Array.from({ length: dataMaxLead + 1 }).map((item, index) => index)
          })
        }}
        axisBottom={{
          format: value => {
            switch (dateType) {
              case BossPaymentsType.MONTH:
                return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
              case BossPaymentsType.YEAR:
                return dayjs(value, DATE_FORMAT).format('YYYY');
              case BossPaymentsType.DAY:
                return dayjs(value, DATE_FORMAT).format('DD');
              default:
                return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
            }
          },
          tickRotation: -45
        }}
        tooltip={e => {
          const data: any = e?.point?.data;
          return (
            <div className={styles.tooltip}>
              <h1>{getDate(data?.x)}</h1>
              {data?.countLead ? (
                <p>
                  <span>
                    <DotIcon size={8} color={'#0061FF'} /> {t('home.Barchasi')}
                  </span>
                  <span> {data?.countLead + ' ' + t('pagination.ta')}</span>
                </p>
              ) : (
                ''
              )}
              {data?.countSuccessLead ? (
                <p>
                  <span>
                    <DotIcon size={8} color={'#00ff00'} /> {t('import_export.Muvaffaqiyatli')}
                  </span>
                  <span>{data?.countSuccessLead + ' ' + t('pagination.ta')}</span>{' '}
                </p>
              ) : (
                ''
              )}
              {/* <div className={styles.tooltip_label}>
                {getDate(data?.x)}:{' '}
                <span>
                  {data?.y} {data?.y ? t('pagination.ta') : ''}
                </span>
              </div> */}
              {/* <div className={styles.container_body_chart_tooltip_value}>
                <span>{t('home.Barchasi')}:</span> {data?.y}
              </div> */}
            </div>
          );
        }}
        pointSize={5}
        // pointColor={'#0061FF'}
        pointBorderWidth={1}
        // pointBorderColor={'#0061FF'}
        colors={e => e?.color}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
};

export default LeadStatisticLine;
