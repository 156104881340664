import React from 'react';

const SaleIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0402 6.82L14.2802 2.79C12.7102 1.69 10.3002 1.75 8.79023 2.92L3.78023 6.83C2.78023 7.61 1.99023 9.21 1.99023 10.47V17.37C1.99023 19.92 4.06023 22 6.61023 22H17.3902C19.9402 22 22.0102 19.93 22.0102 17.38V10.6C22.0102 9.25 21.1402 7.59 20.0402 6.82ZM16.8802 13.4C16.8802 13.79 16.5702 14.1 16.1802 14.1C15.7902 14.1 15.4802 13.79 15.4802 13.4V13.22L12.7602 15.94C12.6102 16.09 12.4102 16.16 12.2002 16.14C12.0002 16.12 11.8102 16 11.7002 15.83L10.6802 14.31L8.30023 16.69C8.16023 16.83 7.99023 16.89 7.81023 16.89C7.63023 16.89 7.45023 16.82 7.32023 16.69C7.05023 16.42 7.05023 15.98 7.32023 15.7L10.3002 12.72C10.4502 12.57 10.6502 12.5 10.8602 12.52C11.0702 12.54 11.2602 12.65 11.3702 12.83L12.3902 14.35L14.5002 12.24H14.3202C13.9302 12.24 13.6202 11.93 13.6202 11.54C13.6202 11.15 13.9302 10.84 14.3202 10.84H16.1802C16.2702 10.84 16.3602 10.86 16.4502 10.89C16.6202 10.96 16.7602 11.1 16.8302 11.27C16.8702 11.36 16.8802 11.45 16.8802 11.54V13.4Z"
        fill="#464255"
      />
    </svg>
  );
};

export default SaleIcon;
