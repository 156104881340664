import React, { useState } from 'react';
import styles from './tableSettings.module.scss';
import TableSettingsIcon from '../../assets/icons/TableSettings';
import TableConfigDrawer from './TableConfigDrawer';
import { TableKey } from '../../utils/models/TableKey';

interface Props {
  tableKey: TableKey;
  size: 'large' | 'small';
}

const TableSettingsMine: React.FC<Props> = ({ tableKey, size }) => {
  const [visibleDriver, setVisibleDriver] = useState(false);

  const handleClick = (visible: boolean) => {
    setVisibleDriver(visible);
  };

  return (
    <>
      <div className={`${size === 'large' ? styles.cont : styles.cont_mine} d-flex align-center justify-space-between`}>
        <span onClick={() => handleClick(true)} className={styles.icon}>
          <TableSettingsIcon />
        </span>
      </div>
      <TableConfigDrawer tableKey={tableKey} visible={visibleDriver} onClose={handleClick} />
    </>
  );
};

export default TableSettingsMine;
