import React from 'react';

const ArrowBottomInboundIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7959 6.79504L8.71875 16.875H15.75C16.0484 16.875 16.3345 16.9936 16.5455 17.2045C16.7565 17.4155 16.875 17.7017 16.875 18C16.875 18.2984 16.7565 18.5846 16.5455 18.7955C16.3345 19.0065 16.0484 19.125 15.75 19.125H6C5.70163 19.125 5.41548 19.0065 5.2045 18.7955C4.99353 18.5846 4.875 18.2984 4.875 18V8.25004C4.875 7.95167 4.99353 7.66552 5.2045 7.45454C5.41548 7.24356 5.70163 7.12504 6 7.12504C6.29837 7.12504 6.58452 7.24356 6.7955 7.45454C7.00647 7.66552 7.125 7.95167 7.125 8.25004V15.2813L17.2041 5.2041C17.3087 5.09945 17.4329 5.01644 17.5697 4.95981C17.7064 4.90317 17.8529 4.87402 18.0009 4.87402C18.1489 4.87402 18.2955 4.90317 18.4322 4.95981C18.5689 5.01644 18.6932 5.09945 18.7978 5.2041C18.9025 5.30875 18.9855 5.43298 19.0421 5.56971C19.0987 5.70644 19.1279 5.85298 19.1279 6.00098C19.1279 6.14897 19.0987 6.29551 19.0421 6.43224C18.9855 6.56897 18.9025 6.6932 18.7978 6.79785L18.7959 6.79504Z"
        fill="#0CAF61"
      />
    </svg>
  );
};

export default ArrowBottomInboundIcon;
