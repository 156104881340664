import { useAppSelector } from '@hooks/reduxHooks';
import { DATE_FORMAT, generateReqBody } from '@utils/index';
import { PipeAll } from '@utils/models/PipeAll';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useQuery } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { Pagination } from '../../../utils/models/Pagination';
import { obj } from '../components/filterCalls/FilterCalls';
import { checkboxValueObj } from '../components/header/Header';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { dateFormatData } from '../utils/helper/DateFormatData';
import { CallHistoryCardModel } from '../utils/models/CallHistoryCardModel';
import { CallHistoryFilterModel } from '../utils/models/CallHistoryFilterModel';
import { CallHistoryGraphModel } from '../utils/models/CallHistoryGraphModel';
import { CallHistoryModel } from '../utils/models/CallHistoryModel';
import { FirstPieChartData } from '../utils/models/FirstPieChartData';
import { LeadAndClientCostConfigModel } from '../utils/models/lead-and-client-cost-config-model';
import { LeadAndClientCostModel } from '../utils/models/lead-and-client-cost-model';
import { LeadAndClientCostSourceListModel, LeadAndClientCostStatResultModel } from '../utils/models/lead-and-client-cost-statistics-model';
import { LeadSourceModel } from '../utils/models/lead-source-model';
import { LeadTasksModel } from '../utils/models/LeadTasksModel';
import { PlanFactConfigModel } from '../utils/models/plan-fact-config-model';
import { PlanFactModel } from '../utils/models/plan-fact-model';
import { PlanFactPipeSourceModel } from '../utils/models/plan-fact-pipe-source-model';
import { SecondPieChartData } from '../utils/models/SecondPieChartData';
import { StatLead, StatLeadDateDto } from '../utils/models/StatLead';
import { SuccessFulLeadModel } from '../utils/models/successful-lead-model';
import { ThirdPieChartData } from '../utils/models/ThirdPieChartData';
import { TJMS } from '../utils/models/TJMS';
import { IUniqueCalls } from '../utils/models/UniqueCalls';

export function useGetFailedLead({
  statType,
  pipeId,
  startDate,
  finishDate
}: {
  statType?: string;
  pipeId?: number | null;
  startDate: string | undefined;
  finishDate: string | undefined;
}) {
  return useQuery<{ result: { id: number; count: number; value: string }[]; total: number }>(
    [queryKeys.FAILED_LEAD, statType, pipeId, startDate, finishDate],
    async () => {
      const res = await $api.post(
        endPoints.FAILED_LEAD,
        generateReqBody({
          statType,
          pipeId,
          startDate,
          finishDate
        })
      );

      return res.data.data;
    },
    {
      enabled: !!statType,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetCallHistory(
  startDate: number,
  finishDate: number,
  pipeId?: number | null,
  employeeId?: number,
  statuses?: string[],
  unique?: boolean,
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<CallHistoryModel>(
    [queryKeys.CALL_HISTORY, startDate, finishDate, pipeId, employeeId, statuses, unique, durationStart, durationFinish, talkTimeStart, talkTimeFinish],

    async () => {
      const searchParams = queryString.stringify({
        startDate,
        finishDate,
        pipeId,
        employeeId,
        status: statuses,
        unique,
        durationStart,
        durationFinish,
        talkTimeStart,
        talkTimeFinish
      });
      const res = await $api.get(`${endPoints.CALL_HISTORY}?${searchParams}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined
    }
  );
}

export function useGetCallHistoryCArd(
  startDate: number,
  finishDate: number,
  pipeId?: number | null,
  employeeId?: number,
  unique?: string,
  statuses?: string[],
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<CallHistoryCardModel>(
    [queryKeys.CALL_HISTORY_CARD, startDate, finishDate, pipeId, employeeId, unique, statuses, durationStart, durationFinish, talkTimeStart, talkTimeFinish],
    async () => {
      const searchParams = queryString.stringify({
        startDate,
        finishDate,
        pipeId,
        employeeId,
        unique: checkboxValueObj[unique!] || false,
        status: statuses,
        durationStart,
        durationFinish,
        talkTimeStart,
        talkTimeFinish
      });
      const res = await $api.get(`${endPoints.CALL_HISTORY_CARD}?${searchParams}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined
    }
  );
}

export function useGetCallHistoryGrapg(
  startDate: number,
  finishDate: number,
  pipeId?: number | null,
  employeeId?: number,
  statuses?: string[],
  unique?: boolean,
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<CallHistoryGraphModel>(
    [queryKeys.CALL_HISTORY_GRAPH, startDate, finishDate, pipeId, employeeId, unique, durationStart, durationFinish, talkTimeStart, talkTimeFinish, statuses],
    async () => {
      const searchParams = queryString.stringify({
        startDate,
        finishDate,
        pipeId,
        employeeId,
        unique,
        durationStart,
        durationFinish,
        talkTimeStart,
        talkTimeFinish,
        status: statuses
      });
      const res = await $api.get(`${endPoints.CALL_HISTORY_GRAPH}?${searchParams}`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined
    }
  );
}

export function useGetCallHistoryFilter(
  page: number,
  size: number,
  startDate: number,
  finishDate: number,
  employeeId?: number,
  callType?: string,
  unique?: string,
  statuses?: string[],
  pipeId?: number,
  durationStart?: number,
  durationFinish?: number,
  talkTimeStart?: number,
  talkTimeFinish?: number
) {
  return useQuery<Pagination<CallHistoryFilterModel> | Pagination<IUniqueCalls>>(
    [
      queryKeys.CALL_HISTORY_FILTER,
      page,
      size,
      startDate,
      finishDate,
      employeeId,
      callType,
      unique,
      statuses,
      pipeId,
      durationStart,
      durationFinish,
      talkTimeStart,
      talkTimeFinish
    ],
    async () => {
      const res = await $api.post(
        `${obj[unique!] ? `${endPoints.CALL_HISTORY_FILTER}/unique` : endPoints.CALL_HISTORY_FILTER}?page=${page}&size=${size}&employeeId=${employeeId || ''}&status=${statuses || ''}&pipeId=${pipeId}&durationStart=${durationStart || null}&durationFinish=${durationFinish || null}&talkTimeStart=${talkTimeStart || null}&talkTimeFinish=${talkTimeFinish || null}`,
        {
          page,
          size,
          startDate,
          finishDate,
          employeeId,
          callType,
          // eslint-disable-next-line no-nested-ternary
          statuses: statuses ? (Array.isArray(statuses) ? statuses : [statuses]) : null,
          pipeId,
          durationStart: Number(durationStart) ? Number(durationStart) : undefined,
          durationFinish: Number(durationFinish) ? Number(durationFinish) : undefined,
          talkTimeStart: Number(talkTimeStart) ? Number(talkTimeStart) : undefined,
          talkTimeFinish: Number(talkTimeFinish) ? Number(talkTimeFinish) : undefined
        }
      );

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      enabled: startDate !== undefined && finishDate !== undefined && !!page && !!size
    }
  );
}

export function useGetLeadTasks(employeeList: null | number[], leadTaskStatusFilter: string, startDate: string, finishDate: string) {
  return useQuery<LeadTasksModel>(
    [queryKeys.LEAD_TASK, employeeList, leadTaskStatusFilter, startDate, finishDate],
    async () => {
      const res = await $api.post(`${endPoints.LEAD_TASK}`, {
        employeeList,
        leadTaskStatusFilter,
        startDate,
        finishDate
      });

      return res.data;
    },
    {
      enabled: !!leadTaskStatusFilter,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetPhoneRecord(uuid: string | null, accountcode: string | null, callHistoryType: string | null) {
  return useQuery(
    [queryKeys.PHONE_RECORD, uuid, accountcode, callHistoryType],
    async () => {
      const res = await $api.get(`${endPoints.PHONE_RECORD}?uuid=${uuid}&accountcode=${accountcode}&callHistoryType=${callHistoryType}`);

      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useStatLead(pipeId: number | null, employeeId?: number, startDate?: string, finishDate?: string) {
  return useQuery<StatLead>(
    [queryKeys.STAT_LEAD, pipeId, startDate, finishDate, employeeId],
    async () => {
      const res = await $api.post(endPoints.STAT_LEAD, {
        pipeId,
        employeeList: employeeId && [employeeId],
        startDate,
        finishDate
      });

      return res.data.data;
    },
    {
      // enabled: !!pipeId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useStatLeadDate(pipeId: number | null, employeeId?: number, startDate?: string, finishDate?: string, dateEnum?: string) {
  return useQuery<StatLeadDateDto[]>(
    [queryKeys.STAT_LEAD_DATE, pipeId, startDate, finishDate, employeeId, dateEnum],
    async () => {
      const res = await $api.post(endPoints.STAT_LEAD_DATE, {
        pipeId,
        employeeList: employeeId && [employeeId],
        startDate,
        finishDate,
        dateEnum
      });

      return dateFormatData(dateEnum!, res.data.data, {
        startDate: startDate || '',
        finishDate: finishDate || ''
      });
    },
    {
      // enabled: pipeId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export const useGetFirstPieChartData = (startDate?: string, endDate?: string) =>
  useQuery<FirstPieChartData>(
    [queryKeys.FIRST_PIE_CHART_DATA, startDate, endDate],
    async () => {
      const res = await $api.get(`${endPoints.FIRST_PIE_CHART_DATA}?startDate=${startDate}&finishDate=${endDate}`);

      return res.data?.data;
    },
    {
      retry: false,
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );

export const useGetSecondPieChartData = (startDate?: string, endDate?: string) =>
  useQuery<SecondPieChartData>(
    [queryKeys.SECOND_PIE_CHART_DATA, startDate, endDate],
    async () => {
      const res = await $api.get(`${endPoints.SECOND_PIE_CHART_DATA}?startDate=${startDate}&finishDate=${endDate}`);

      return res.data?.data;
    },
    {
      retry: false,
      enabled: !!startDate && !!endDate,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );

export const useGetAllTJMSWithBuildings = () =>
  useQuery<TJMS[]>(
    [queryKeys.TJMS],
    async () => {
      const res = await $api.get(endPoints.TJMS);

      return res.data?.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler
    }
  );

export const useGetThirdPieChartData = (data: {
  startDate?: string;
  finishDate?: string;
  houses?: Array<number>;
  buildings?: Array<number>;
  customFieldId?: number | null;
  tag?: boolean | null;
}) =>
  useQuery<ThirdPieChartData>(
    [queryKeys.THIRD_PIE_CHART_DATA, data],
    async () => {
      const res = await $api.post(endPoints.THIRD_PIE_CHART_DATA, data);

      return res.data?.data;
    },
    {
      retry: false,
      onError: baseErrorHandler,
      enabled: !!data?.customFieldId || !!data?.tag
    }
  );

export function useGetSuccessfulLeads({
  pipeId,
  statType,
  startDate,
  finishDate
}: {
  pipeId?: number | null;
  startDate: string | undefined;
  finishDate: string | undefined;
  statType: 'REASON' | 'EMPLOYEE';
}) {
  return useQuery<{ result: { id: number; count: number; value: string }[]; total: number }>(
    [queryKeys.SUCCESSFUL_LEADS, statType, pipeId, startDate, finishDate],
    async () => {
      const res = await $api.post(
        endPoints.SUCCESSFUL_LEADS,
        generateReqBody({
          statType,
          pipeId,
          startDate,
          finishDate
        })
      );

      return res.data.data;
    },
    {
      enabled: !!statType,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetSuccessFulTableLeads({
  page,
  size,
  search,
  pipeIds,
  startDate,
  finishDate,
  responsibleById
}: {
  page: number;
  size: number;
  search?: string;
  startDate: string;
  finishDate: string;
  pipeIds?: (number | undefined)[];
  responsibleById?: (number | undefined)[];
}) {
  const initial = {
    leads: [],
    totalPages: 1,
    currentPage: 1,
    totalElements: 0
  };

  const { data = initial, ...arg } = useQuery<{
    totalPages: number;
    currentPage: number;
    totalElements: number;
    leads: SuccessFulLeadModel[];
  }>(
    [queryKeys.SUCCESSFUL_TABLE_LEADS, page, size, search, pipeIds, startDate, finishDate, responsibleById],
    async () => {
      const { data } = await $api.post(
        endPoints.SUCCESSFUL_TABLE_LEADS,
        generateReqBody({
          page,
          size,
          search,
          pipeIds,
          startDate,
          finishDate,
          responsibleById
        })
      );

      return { leads: data?.data?.data, totalPages: data?.data?.totalPages, currentPage: data?.data?.currentPage, totalElements: data?.data?.totalElements };
    },
    {
      retry: false,
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );

  return { ...data, ...arg };
}

export function useGetPlanFact() {
  const { finishDate, startDate } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  const { monthFinishDate, monthStartDate } = {
    monthFinishDate: dayjs(new Date()).format(DATE_FORMAT),
    monthStartDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT)
  };

  const initial: { planFact: PlanFactModel | undefined } = {
    planFact: undefined
  };

  const { data = initial, ...arg } = useQuery<{ planFact: PlanFactModel }>([queryKeys.PLAN_FACT, startDate, finishDate], async () => {
    const { data } = await $api.get(`/v1/statistics/plan-fact-pipe?startDate=${startDate || monthStartDate}&finishDate=${finishDate || monthFinishDate}`);

    return { planFact: data?.data };
  });

  return { ...data, ...arg };
}

export function useGetLeadAndClientCostStatistics() {
  const { pipeId } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  const initial: LeadAndClientCostStatResultModel = {
    leadStats: {
      sourceList: {},
      overallAveragePrice: 0
    },
    clientStats: {
      sourceList: {},
      overallAveragePrice: 0
    }
  };

  const { data = initial, ...arg } = useQuery<LeadAndClientCostStatResultModel>(
    [queryKeys.LEAD_AND_CLIENT_COST_STATISTICS, pipeId],
    async () => {
      const { data } = await $api.get(`/v1/statistics/lead-and-client-cost/statistics${pipeId ? `?pipeId=${pipeId}` : ''}`);

      return { leadStats: data?.data?.leadStats, clientStats: data?.data?.clientStats };
    },
    {
      select: ({ clientStats, leadStats }) => {
        let clientSourceList: Record<string, LeadAndClientCostSourceListModel> = {};
        let leadSourceList: Record<string, LeadAndClientCostSourceListModel> = {};

        (leadStats?.sourceList as never as LeadAndClientCostSourceListModel[])?.forEach(({ sourceName, ...leadArg }) => {
          leadSourceList = {
            ...leadSourceList,
            [sourceName]: {
              sourceName,
              ...leadArg
            }
          };
        });

        (clientStats?.sourceList as never as LeadAndClientCostSourceListModel[])?.forEach(({ sourceName, ...clientArg }) => {
          clientSourceList = {
            ...clientSourceList,
            [sourceName]: {
              sourceName,
              ...clientArg
            }
          };
        });

        return {
          clientStats: {
            ...clientStats,
            sourceList: clientSourceList
          },
          leadStats: {
            ...leadStats,
            sourceList: leadSourceList
          }
        };
      }
    }
  );

  return { ...data, ...arg };
}

export function useGetLeadAndCLientCost() {
  const { pipeId } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  const initial: { leadAndClientCost: LeadAndClientCostModel[] } = {
    leadAndClientCost: []
  };

  const { data = initial, ...arg } = useQuery<{ leadAndClientCost: LeadAndClientCostModel[] }>([queryKeys.LEAD_AND_CLIENT_COST, pipeId], async () => {
    const { data } = await $api.get(`/v1/statistics/lead-and-client-cost${pipeId ? `?pipeId=${pipeId}` : ''}`);

    return { leadAndClientCost: data?.data };
  });

  return { ...data, ...arg };
}

export function useGetPlanFactPipeStatistics() {
  const { finishDate, startDate, pipeId } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact);

  const { monthFinishDate, monthStartDate } = {
    monthFinishDate: dayjs(new Date()).format(DATE_FORMAT),
    monthStartDate: dayjs(new Date()).startOf('month').format(DATE_FORMAT)
  };

  const initial: { overallCount: number; sourceList: PlanFactPipeSourceModel[] } = {
    overallCount: 0,
    sourceList: []
  };

  const { data = initial, ...arg } = useQuery<{ overallCount: number; sourceList: PlanFactPipeSourceModel[] }>([queryKeys.PLAN_FACT, startDate, finishDate, pipeId], async () => {
    const { data } = await $api.get(
      `/v1/statistics/plan-fact-pipe/source?startDate=${startDate || monthStartDate}&finishDate=${finishDate || monthFinishDate}${pipeId ? `&pipeId=${pipeId}` : ''}`
    );

    return { overallCount: data?.data?.overallCount, sourceList: data?.data?.sourceList };
  });

  return { ...data, ...arg };
}

export function useGetPlanFactPipes() {
  const initial: { pipes: PipeAll[] } = {
    pipes: []
  };

  const { data = initial, ...arg } = useQuery<{ pipes: PipeAll[] }>([queryKeys.PLAN_FACT_PIPES], async () => {
    const { data } = await $api.get('/v1/statistics/plan-fact-pipe/pipe');

    return {
      pipes: data?.data
    };
  });

  return { ...data, ...arg };
}

export function useGetPlanFactConfig() {
  const { id } = useAppSelector(({ crmStatistic }) => crmStatistic.planFact.planFactConfig);

  const initial: { config: Partial<PlanFactConfigModel> } = {
    config: {}
  };

  const { data = initial, ...arg } = useQuery<{ config: PlanFactConfigModel }>(
    [queryKeys.PLAN_FACT_CONFIG, id],
    async () => {
      const { data } = await $api.get(`/v1/statistics/plan-fact-pipe/${id}`);

      return { config: data?.data };
    },
    { enabled: !!id }
  );

  return { ...data, ...arg };
}

export function useGetLeadAndClientCostConfig() {
  const initial: { leadAndClientCost: LeadAndClientCostConfigModel[] } = {
    leadAndClientCost: []
  };

  const { data = initial, ...arg } = useQuery<{ leadAndClientCost: LeadAndClientCostConfigModel[] }>([queryKeys.LEAD_AND_CLIENT_COST_CONFIG], async () => {
    const { data } = await $api.get('/v1/statistics/lead-and-client-cost');

    return { leadAndClientCost: data?.data };
  });

  return { ...data, ...arg };
}

export function useGetLeadSources() {
  const initial: { sources: LeadSourceModel[] } = {
    sources: []
  };

  const { data = initial, ...arg } = useQuery<{ sources: LeadSourceModel[] }>([queryKeys.LEAD_SOURCES], async () => {
    const { data } = await $api.get('/v1/lead/sources');

    return { sources: data?.data };
  });

  return { ...data, ...arg };
}
