import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';

export const useTermDiscountDelete = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id?: number | null) => {
      const res = await $api.delete(`/v1/term-discount/${id}`);

      return res.data.data;
    },
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.TERM_DISCOUNT]);
    }
  });
};
