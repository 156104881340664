import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';

export const dateFormatTimeLine = (
  key: string,
  data: { count: number; time: string }[] | undefined,
  date: {
    startDate: string;
    finishDate: string;
  }
) => {
  const dataConvert: { [key: string]: { count: number; time: string }[] } = {
    DAY: Array.from({
      length: dayjs(date.startDate, DATE_FORMAT).endOf('month').date()
    }).map((item, index) => ({
      time: dayjs(date.startDate, DATE_FORMAT)
        .date(index + 1)
        .format(DATE_WITH_TIME_FORMAT),
      count: 0
    })),
    MONTH: Array.from({ length: 12 }).map((item, index) => ({
      time: dayjs(date.startDate, DATE_FORMAT).date(1).month(index).format(DATE_WITH_TIME_FORMAT),
      count: 0
    })),
    YEAR: Array.from({ length: 5 }).map((item, index) => ({
      time: dayjs(date.finishDate, DATE_FORMAT)
        .date(1)
        .month(0)
        .add(index + 1 - 5, 'year')
        .format(DATE_WITH_TIME_FORMAT),
      count: 0
    }))
  };

  return dataConvert[key]?.map(item => {
    if (data) {
      const findData = data?.find(value => value?.time === item?.time)!;
      return {
        time: item?.time,
        count: findData?.count || 0
      };
    } else {
      return item;
    }
  });
};
