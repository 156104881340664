import React from 'react';
import styles from './interfaceConfig.module.scss';
import EditLogo from './EditLogo';
import ShowRoomColor from './ShowroomColor';
import Language from './Language';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Permissions } from '../../utils/constants/permissions';
import SocialMedias from './socialMedias/SocialMedias';

const InterfaceConfig = () => {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessReadContract = permissions?.includes(Permissions.PERMISSION_SETTINGS_INTERFACE);

  return (
    <div className={styles.interfaceConfig}>
      {isAccessReadContract && (
        <>
          <EditLogo />
          <ShowRoomColor />
        </>
      )}
      <Language />
      <SocialMedias />
    </div>
  );
};

export default InterfaceConfig;
