import React, { FC, useEffect } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDiscountCreate, useDiscountEdit } from '../../queries/mutation';

const { Option } = Select;

interface DiscountSettingCreateModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  id?: number;
  name?: string;
  calculateType?: string;
}

const DiscountSettingCreateModal: FC<DiscountSettingCreateModalProps> = ({ visible, setVisible, id, name, calculateType }) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };
  const discountEdit = useDiscountEdit(handleCancel);
  const discountCreate = useDiscountCreate(handleCancel);
  const { t } = useTranslation();

  const onFinishHandle = (values: any) => {
    if (id) {
      discountEdit.mutate({
        id,
        name: values.name?.trim(),
        calculateType: values?.calculateType
      });
    } else {
      discountCreate.mutate({ name: values.name?.trim(), calculateType: values?.calculateType });
    }
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({ name, calculateType });
    }
  }, [visible, form, id, name, calculateType]);

  // submit button loader
  const buttonLoader = () => {
    if (id) {
      return discountEdit.isLoading;
    }
    return discountCreate.isLoading;
  };

  return (
    <Modal
      title={id ? t('discount_setting.Chegirmani_tahrirlash') : t('discount_setting.Chegirma_yaratish')}
      visible={visible}
      footer={null}
      centered
      onCancel={handleCancel}
      className="discountSettingCreateModal"
      width={399}
    >
      <Form onFinish={onFinishHandle} layout="vertical" form={form}>
        <Form.Item label={t('discount_setting.Nomi')} name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('discount_setting.type_discount_type')} name="calculateType" rules={[{ required: true }]}>
          <Select className="customSelect" placeholder={t('home.Tanlang')}>
            <Option value="BEFORE_DISCOUNT">{t('home.Chegirmadan_oldin')}</Option>
            <Option value="AFTER_DISCOUNT">{t('home.Chegirmadan_keyin')}</Option>
          </Select>
        </Form.Item>
        <div>
          <Button onClick={handleCancel}>{t('home.Yopish')}</Button>
          <Button type="primary" htmlType="submit" loading={buttonLoader()}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DiscountSettingCreateModal;
