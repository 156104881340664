import React, { useState } from 'react';
import { useUpdateWidgetName } from '@pages/dashboard/services/mutations';
import { Button, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './widgetUpdate.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  id: number;
};

const WidgetUpdate: React.FC<Props> = ({ setVisible, visible, name, id }) => {
  const { t } = useTranslation();
  const [nameValue, setNameValue] = useState(name);
  const updateWidgetName = useUpdateWidgetName(id);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div onMouseDown={e => e.stopPropagation()}>
      <Modal
        visible={visible}
        onCancel={handleClose}
        title={t('emp_dashboard.Vidjet_nomini_tahrirlash')}
        footer={false}
        className={styles.widgetUpdate}
      >
        <Input value={nameValue} onChange={e => setNameValue(e.target.value)} />
        <div className={styles.widgetUpdate_footer}>
          <Button onClick={handleClose}>{t('home.Yopish')}</Button>
          <Button loading={updateWidgetName.isLoading} onClick={() => updateWidgetName.mutate({ name: nameValue })}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default WidgetUpdate;
