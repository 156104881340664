import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { crmIcons } from '../../../../assets/icons/crmIcons/crmIcons';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';
import { TaskFilter } from '../../../../utils/models/TaskFilter';

import styles from './assigmentCard.module.scss';

const AssigmentCard: FC<TaskFilter> = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const encodeString = (str: string) => {
    try {
      const safeStr = str.replace(/\./g, '%2E');

      return encodeURIComponent(safeStr);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Encoding error:', error);
      return '';
    }
  };

  const handleOpen = (e: any) => {
    e.stopPropagation();
    if (props?.clientId) {
      navigate(`${rootPaths.MAIN + MainPaths.CLIENT}/${props?.clientId}/${encodeString(props?.clientName)}`);
    } else if (!props.contractId) {
      navigate(`${rootPaths.MAIN + MainPaths.CRM}/${props.leadId}/${encodeString(props.leadName)}?next=task`);
    } else {
      navigate(`${location.pathname}/${props.contractId}/${encodeString(props.clientName)}`);
    }
  };

  const startDateTime = dayjs(props.deadlineStart, DATE_WITH_TIME_FORMAT).format('HH:mm');
  const dueDateTime = dayjs(props.deadlineFinish, DATE_WITH_TIME_FORMAT).format('HH:mm');

  const getTime = () => {
    if (startDateTime === '00:00' && (dueDateTime === '23:59' || dueDateTime === '00:00')) {
      return t('one_task.Kun_davomida');
    }
    return `${startDateTime} ${dueDateTime}`;
  };

  return (
    <div className={styles.assigmentCard} onClick={handleOpen}>
      <div className={styles.assigmentCard_top}>
        <p>
          {/* <Dropdown
            overlay={
              <Menu style={{ borderRadius: '10px' }}>
                <div style={{ padding: '10px 16px' }}>
                  <div className={styles.inform} style={{ marginBottom: '10px' }}>
                    <p>{t('home.Yaratilgan_sana')}</p>
                    <span>{props.createdDate}</span>
                  </div>

                  <div className={styles.inform}>
                    <p>{t('home.Yaratdi')}</p>
                    <span>{props.createdBy}</span>
                  </div>
                </div>
              </Menu>
            }
            trigger={['hover']}
          >
            <span style={{ cursor: 'pointer' }}>
              <InfoIconCRM />
            </span>
          </Dropdown> */}

          <span>{props.leadName}</span>
          {/* {props.payment ? <DollarCircleCrmIcon /> : <TickCircleCrmIcon />} */}
          {props.contractNumber}
        </p>
        <span>{props.clientName}</span>
      </div>
      <div className={styles.assigmentCard_center}>
        <p>
          {props.assignedBy} {t('showroom.uchun')}
        </p>
        <span>
          {dayjs(props.deadlineStart, DATE_WITH_TIME_FORMAT).format(DATE_FORMAT)} {getTime()}{' '}
        </span>
      </div>
      <div className={styles.assigmentCard_bottom}>
        <p>
          {crmIcons[props.leadTaskType.icon.split('#')[0]]?.icon(`#${props.leadTaskType.icon.split('#')[1]}`)}{' '}
          <span>
            {props?.leadTaskType?.name === 'Uchrashuv' || props?.leadTaskType?.name === 'To’lov' || props?.leadTaskType?.name === 'Qayta aloqa'
              ? t(`one_lid.${props.leadTaskType.name}`)
              : props?.leadTaskType?.name}
          </span>
        </p>
        <span title={props.text}>{props.text?.length > 30 ? `${props.text.slice(0, 30)}...` : props.text}</span>
      </div>
    </div>
  );
};

export default AssigmentCard;
// @thtflx
