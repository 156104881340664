import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProfileOutlined } from '@ant-design/icons';
import QrCodeMode from '@features/qr-code-mode/QrCodeMode';
import { openFilesHistory } from '@reducers/IOServiceSlice';
import { cx } from '@utils/cx';
import { WidgetTypes } from '@utils/models/User';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { widgetFIleURL } from '../../utils/constants/widgetFileURL';

import KreditCalculation from './kreditCalculation/KreditCalculation';

import styles from './widgets.module.scss';

export const widgetIcons: Record<WidgetTypes, (props?: { className?: string }) => JSX.Element> = {
  FILES: props => (
    <div className={cx(' flex h-[64px] w-[64px] items-center justify-center rounded-[50%] bg-[var(--ant-primary-color)] text-[32px] text-[#ffffff]', props?.className)}>
      <ProfileOutlined size={32} />
    </div>
  )
};

const Widgets = () => {
  const location = useLocation();
  const [kreditCalculatorVisible, setKreditCalculatorVisible] = useState(false);
  const { user, widgets } = useAppSelector(state => state.userReducer);
  const dispatch = useAppDispatch();

  // handleOpenKreditCalkulator
  const handleOpenKreditCalkulator = () => {
    setKreditCalculatorVisible(true);
  };

  return (
    <>
      <div className={styles.container}>
        {location.pathname?.includes('crm') ? <QrCodeMode /> : ''}
        {widgets?.map(
          item =>
            item.status && (
              <div key={item.id} className={styles.container_item} onClick={() => dispatch(openFilesHistory(true))}>
                {widgetIcons[item?.type]()}
              </div>
            )
        )}

        {user?.widgets?.map(
          item =>
            item.status && (
              <div key={item.id} className={styles.container_item} onClick={handleOpenKreditCalkulator}>
                <img src={widgetFIleURL + item?.icon} alt="" />
              </div>
            )
        )}
      </div>
      <KreditCalculation kreditCalculatorVisible={kreditCalculatorVisible} setKreditCalculatorVisible={setKreditCalculatorVisible} />
    </>
  );
};

export default Widgets;
