import React from 'react';

const DotsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="7" cy="12.5" r="2" fill="#7C828D" />
      <circle cx="12" cy="12.5" r="2" fill="#7C828D" />
      <circle cx="17" cy="12.5" r="2" fill="#7C828D" />
    </svg>
  );
};

export default DotsIcon;
