import React from 'react';

const IncomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 5L5 15" stroke="#15CD7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.125 15H5V6.875" stroke="#15CD7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default IncomeIcon;
