import React from 'react';

const DollorCircleBgIcon = () => {
  return (
    <svg width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.168" cy="14.5" r="12.5" fill="url(#paint0_linear_12244_71139)" />
      <g filter="url(#filter0_b_12244_71139)">
        <path
          d="M15.3333 30.5329C22.6971 30.5329 28.6667 24.5633 28.6667 17.1995C28.6667 9.83575 22.6971 3.86621 15.3333 3.86621C7.96954 3.86621 2 9.83575 2 17.1995C2 24.5633 7.96954 30.5329 15.3333 30.5329Z"
          fill="#6EB2F9"
        />
        <path
          d="M15.3333 30.7829C22.8352 30.7829 28.9167 24.7014 28.9167 17.1995C28.9167 9.69768 22.8352 3.61621 15.3333 3.61621C7.83147 3.61621 1.75 9.69768 1.75 17.1995C1.75 24.7014 7.83147 30.7829 15.3333 30.7829Z"
          stroke="url(#paint1_linear_12244_71139)"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter1_bd_12244_71139)">
        <path
          d="M18.3475 17.3335L16.3342 16.6268V12.1068H16.8142C17.8942 12.1068 18.7742 13.0535 18.7742 14.2135C18.7742 14.7602 19.2275 15.2135 19.7742 15.2135C20.3209 15.2135 20.7742 14.7602 20.7742 14.2135C20.7742 11.9468 19.0009 10.1068 16.8142 10.1068H16.3342V9.3335C16.3342 8.78683 15.8809 8.3335 15.3342 8.3335C14.7875 8.3335 14.3342 8.78683 14.3342 9.3335V10.1068H13.4675C11.4942 10.1068 9.88086 11.7735 9.88086 13.8135C9.88086 16.2002 11.2675 16.9602 12.3209 17.3335L14.3342 18.0402V22.5468H13.8542C12.7742 22.5468 11.8942 21.6002 11.8942 20.4402C11.8942 19.8935 11.4409 19.4402 10.8942 19.4402C10.3475 19.4402 9.89419 19.8935 9.89419 20.4402C9.89419 22.7068 11.6675 24.5468 13.8542 24.5468H14.3342V25.3335C14.3342 25.8802 14.7875 26.3335 15.3342 26.3335C15.8809 26.3335 16.3342 25.8802 16.3342 25.3335V24.5602H17.2009C19.1742 24.5602 20.7875 22.8935 20.7875 20.8535C20.7742 18.4535 19.3875 17.6935 18.3475 17.3335ZM12.9875 15.4535C12.3075 15.2135 11.8942 14.9868 11.8942 13.8268C11.8942 12.8802 12.6009 12.1202 13.4809 12.1202H14.3475V15.9335L12.9875 15.4535ZM17.2009 22.5602H16.3342V18.7468L17.6809 19.2135C18.3609 19.4535 18.7742 19.6802 18.7742 20.8402C18.7742 21.7868 18.0675 22.5602 17.2009 22.5602Z"
          fill="url(#paint2_linear_12244_71139)"
        />
        <path
          d="M18.3144 17.4279L18.3148 17.428C18.8281 17.6057 19.4162 17.8786 19.878 18.3951C20.3378 18.9094 20.6809 19.6747 20.6875 20.8537C20.6874 22.8415 19.1158 24.4602 17.2009 24.4602H16.3342H16.2342V24.5602V25.3335C16.2342 25.8249 15.8256 26.2335 15.3342 26.2335C14.8428 26.2335 14.4342 25.8249 14.4342 25.3335V24.5468V24.4468H14.3342H13.8542C11.7262 24.4468 9.99419 22.6551 9.99419 20.4402C9.99419 19.9487 10.4028 19.5402 10.8942 19.5402C11.3856 19.5402 11.7942 19.9487 11.7942 20.4402C11.7942 21.6484 12.7123 22.6468 13.8542 22.6468H14.3342H14.4342V22.5468V18.0402V17.9693L14.3673 17.9458L12.3543 17.2392C12.3542 17.2392 12.3541 17.2392 12.354 17.2392C11.8335 17.0547 11.2422 16.7784 10.7804 16.2618C10.3207 15.7476 9.98086 14.9857 9.98086 13.8135C9.98086 11.8256 11.5525 10.2068 13.4675 10.2068H14.3342H14.4342V10.1068V9.3335C14.4342 8.84206 14.8428 8.4335 15.3342 8.4335C15.8256 8.4335 16.2342 8.84206 16.2342 9.3335V10.1068V10.2068H16.3342H16.8142C18.9422 10.2068 20.6742 11.9986 20.6742 14.2135C20.6742 14.7049 20.2656 15.1135 19.7742 15.1135C19.2828 15.1135 18.8742 14.7049 18.8742 14.2135C18.8742 13.0052 17.9561 12.0068 16.8142 12.0068H16.3342H16.2342V12.1068V16.6268V16.6977L16.3011 16.7212L18.3144 17.4279ZM14.4475 12.1202V12.0202H14.3475H13.4809C12.5389 12.0202 11.7942 12.8319 11.7942 13.8268C11.7942 14.4164 11.8988 14.7906 12.1083 15.0482C12.3162 15.304 12.615 15.4281 12.9542 15.5478L14.3142 16.0278L14.4475 16.0748V15.9335V12.1202ZM16.2342 22.5602V22.6602H16.3342H17.2009C18.131 22.6602 18.8742 21.8335 18.8742 20.8402C18.8742 20.2506 18.7696 19.8764 18.5601 19.6187C18.3522 19.363 18.0534 19.2389 17.7141 19.1192L17.7136 19.119L16.3669 18.6523L16.2342 18.6063V18.7468V22.5602Z"
          stroke="url(#paint3_linear_12244_71139)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_12244_71139"
          x="-22.5"
          y="-20.6338"
          width="75.666"
          height="75.6665"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71139" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_12244_71139" result="shape" />
        </filter>
        <filter
          id="filter1_bd_12244_71139"
          x="-5.11914"
          y="-6.6665"
          width="42.9062"
          height="50"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_12244_71139" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.15375 0 0 0 0 0.522433 0 0 0 0 0.9 0 0 0 0.28 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_12244_71139" result="effect2_dropShadow_12244_71139" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_12244_71139" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_12244_71139" x1="30.77" y1="2" x2="3.32763" y2="24.2886" gradientUnits="userSpaceOnUse">
          <stop stopColor="#39AFFD" />
          <stop offset="1" stopColor="#477FFF" />
        </linearGradient>
        <linearGradient id="paint1_linear_12244_71139" x1="6.2449" y1="6.97291" x2="28.6449" y2="28.8387" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_12244_71139" x1="20.0143" y1="11.5839" x2="7.85709" y2="11.8241" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint3_linear_12244_71139" x1="11.0557" y1="14.3716" x2="20.3172" y2="14.4026" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DollorCircleBgIcon;
