import React, { FC, useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { useTranslation } from 'react-i18next';

import SwiperNextIcon from '../../assets/icons/SwiperNextIcon';
import SwiperPrevIcon from '../../assets/icons/SwiperPrevIcon';
import UnionShowroomIcon from '../../assets/icons/UnionShowroomIcon';
import { BASE_URL } from '../../service/RequestService';
import { FlatGallery } from '../../utils/models/Flat';
import { GalleryPosition } from '../../utils/models/Gallery';

import FlatGalleryModal from './FlatGalleryModal';

import default2d from '../../assets/img/default2d.png';

import styles from './flatGallery.module.scss';

interface Props {
  gallery: FlatGallery[] | undefined;
  active?: number;
}

const FlatGalleryFC: FC<Props> = ({ gallery, active = 0 }) => {
  const carouselRef = useRef<CarouselRef | null>(null);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(active);

  const { t } = useTranslation();
  // const find showed image
  const findImageSrc = () => {
    if (gallery) {
      const image = gallery?.find(i => i.position === GalleryPosition.FLAT_PLAN);

      if (image) {
        return `${BASE_URL}/${image?.files?.absolutePath}`;
      }
    }
    return undefined;
  };

  const handleActiveImage = (index: number) => {
    setActiveIndex(index);
    setVisible(true);
  };

  useEffect(() => {
    setActiveIndex(active);
  }, [active]);

  return (
    <>
      <div className={styles.container}>
        {findImageSrc() ? (
          <>
            <Carousel
              afterChange={swipeDirection => setActiveIndex(swipeDirection)}
              className={styles.container}
              draggable
              dots={false}
              ref={carouselRef}
            >
              {gallery?.map((item, index) => (
                <div key={item?.id} onClick={() => handleActiveImage(index)}>
                  <img src={`${BASE_URL}/${item?.files?.absolutePath}`} alt="" />
                </div>
              ))}
            </Carousel>
            {gallery && gallery?.length > 1 ? (
              <>
                <div className={styles.next} onClick={() => carouselRef.current?.next()}>
                  <SwiperNextIcon />
                </div>
                <div className={styles.prev} onClick={() => carouselRef.current?.prev()}>
                  <SwiperPrevIcon />
                </div>
                <div className={styles.dots}>
                  {activeIndex + 1}/{gallery?.length}
                </div>
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          <div className={styles.container_left}>
            <img alt="" src={findImageSrc() || default2d} />
            {!findImageSrc() && (
              <p>
                <UnionShowroomIcon /> {t('showroom.Rasm_mavjud_emas')}
              </p>
            )}
          </div>
        )}
      </div>
      <FlatGalleryModal setVisible={setVisible} visible={visible} gallery={gallery} defaultIndex={activeIndex} />
    </>
  );
};

export default FlatGalleryFC;
