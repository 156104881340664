import { Form, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from './crmForce.module.scss';
import { usePipeAll } from '@queries/queries';
import { PipeStatuses } from '@utils/models/PipeAll';
import { useGivePermission } from './services/mutations';
import { useGetForceData } from './services/queries';
import { t } from 'i18next';

const { Option } = Select;

const CrmForce = () => {
  const [form] = Form.useForm();
  const { data } = usePipeAll();
  const { data: force } = useGetForceData();
  const [pipeStatuses, setPipeStatuses] = useState<PipeStatuses[] | undefined>([]);
  const [pipeId, setPipeId] = useState<number>();
  const [disabled, setDisabled] = useState(true);

  const givePermission = useGivePermission();
  const pipe = pipeStatuses?.find(item => item?.id === pipeId);

  const handleGivePermission = () => {
    givePermission.mutateAsync({
      pipeStatus: pipe,
      status: form.getFieldValue('status')
    });
  };

  useEffect(() => {
    if (force) {
      setPipeId(force?.pipeStatus?.id);
      form.setFieldsValue({
        status: force?.status,
        voronka: force?.pipeStatus?.pipeName,
        etap: force?.pipeStatus?.name
      });
      const newPipeId = force?.pipeStatus?.pipeId;
      const newPipes = data?.find(item => item?.id === newPipeId)?.pipeStatuses;
      setPipeStatuses(newPipes);
      setDisabled(false);
    }
  }, [force]);

  return (
    <div className={styles.crmForce}>
      <span>{t('setting.Majburiy_mijoz_birlashtirish')}</span>
      <Form
        layout="vertical"
        form={form}
        onFieldsChange={(field, allFields) => {
          const test = allFields?.filter(item => Array.isArray(item?.name) && item?.name[0] !== 'status')?.every(item => Boolean(item?.value));
          setDisabled(!test);
        }}
      >
        <Form.Item name="voronka">
          <Select
            allowClear
            placeholder={t('setting.Voronka_tanlang')}
            onChange={e => {
              form.setFieldsValue({ etap: null });
              setDisabled(true);
              const selectedData = data?.find(item => item?.id === e)?.pipeStatuses;

              if (selectedData) {
                setPipeStatuses(selectedData);
              } else {
                form.resetFields();
                setPipeStatuses([]);
              }
            }}
          >
            {data?.map(item => (
              <Option key={item?.id} value={item?.id}>
                <div>{item?.name}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="etap">
          <Select
            allowClear
            placeholder={t('setting.Etap_tanlang')}
            onChange={e => {
              const pipeStatus = pipeStatuses?.find(item => item?.id === e);
              setPipeId(e);
              givePermission.mutate({
                pipeStatus: pipeStatus,
                status: force?.status!
              });
            }}
          >
            {pipeStatuses?.map(item => (
              <Option key={item?.id} value={item?.id}>
                <div>{item?.name}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="status" valuePropName="checked">
          <Switch disabled={disabled} onChange={handleGivePermission} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default CrmForce;
