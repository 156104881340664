import React, { useEffect } from 'react';
import { Permissions } from '@utils/constants/permissions';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { changeDiscount, setChangePartId, setDiscountText } from '../../../store/reducers/CreateContractSlice';
import { helper } from '../../../utils/helper/helper';
import { roundFloatNumber } from '../../../utils/helper/roundFloatNumber';

import styles from './discountProps.module.scss';

interface IForm {
  price: string;
  note: string;
}

const DiscountProps = () => {
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isMultiPayment = permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);
  const isMultiPaymentEdit = permissions?.includes(Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT);
  const disabled = isMultiPayment ? !isMultiPaymentEdit : false;

  const [form] = Form.useForm<IForm>();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { discountSum, discountText, totalAmount, prePayment, currency } = useAppSelector(state => state.createContract);

  useEffect(() => {
    if (!discountText && discountSum) {
      dispatch(setDiscountText(`${discountSum.toLocaleString('ru')} ${currency?.ccy} chegirma qo'llanildi`));
    }
    form.setFieldsValue({
      note: discountText || `${discountSum.toLocaleString('ru')} ${currency?.ccy} chegirma qo'llanildi`,
      price: discountSum.toLocaleString('ru')
    });
  }, [discountText, discountSum, currency, form]);

  // changeDiscountSum
  const changeDiscountSum: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (!disabled) {
      const payment = helper.deleteNotNumbersAndParseFloatNumber(e.target.value);

      if (payment.number > totalAmount - prePayment) {
        dispatch(
          changeDiscount({
            amount: roundFloatNumber(totalAmount - prePayment),
            changeRepairedStatus: false
          })
        );
        form.setFieldsValue({
          price: roundFloatNumber(totalAmount - prePayment)?.toLocaleString('ru')
        });
      } else {
        dispatch(
          changeDiscount({
            amount: payment.number,
            changeRepairedStatus: false
          })
        );
        form.setFieldsValue({
          price: payment.string
        });
      }
      dispatch(
        setDiscountText(`${payment.number < totalAmount - prePayment ? payment.string : (totalAmount - prePayment).toLocaleString('ru')} ${currency?.ccy} chegirma qo'llanildi`)
      );
      dispatch(setChangePartId(0));
    }
  };

  // handle change discount text
  const handleChangeDiscountText: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    if (!disabled) {
      dispatch(setDiscountText(e.target.value));
    }
  };

  return (
    <Form form={form} name="discountProps" autoComplete="off">
      <div className={styles.container}>
        <div className={styles.container_price}>
          <label htmlFor="priceDiscount">{t('create_table_payments.Chegirma_summasi')}</label>
          <Form.Item name="price">
            <Input onChange={changeDiscountSum} suffix={<span>{currency?.ccy}</span>} className="customInput" id="priceDiscount" disabled={disabled} />
          </Form.Item>
        </div>
        {discountSum ? (
          <div className={styles.container_note}>
            <label htmlFor="noteDiscount">{t('create_table_payments.Izoh')}</label>
            <Form.Item name="note">
              <Input.TextArea
                status={discountSum > 0 && !discountText ? 'error' : undefined}
                onChange={handleChangeDiscountText}
                className="customInput"
                id="noteDiscount"
                autoSize={{ minRows: 1, maxRows: 2 }}
                disabled={disabled}
              />
            </Form.Item>
          </div>
        ) : null}
      </div>
    </Form>
  );
};

export default DiscountProps;
