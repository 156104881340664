import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ClientMiniInfo from '@components/clientMiniInfo/ClientMiniInfo';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import ClipBoardTickIcon from '../../../../assets/icons/ClipBoardTickIcon';
import CloseIcon from '../../../../assets/icons/contract/CloseIcon';
import DollarSquareBorderredIcon from '../../../../assets/icons/DollarSquareBorderredIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { MainPaths } from '../../../../routing/main/mainPaths';
import { rootPaths } from '../../../../routing/rootPaths';
import { ContractDataForCreatePayment } from '../../../../utils/models/ContractDataForCreatePayment';

import styles from './paymentClient.module.scss';

interface IProps {
  data: ContractDataForCreatePayment | undefined;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentClientInfo: FC<IProps> = ({ data, setSearch }) => {
  const { push } = useQueryParam();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // handleContract
  const handleContract = () => {
    navigate(`${rootPaths.MAIN + MainPaths.CONTRACT}/${data?.id}/${data?.clientName}`);
  };

  // handleClientBalance
  const handleClientBalance = () => {
    navigate(`${rootPaths.MAIN + MainPaths.PAYMENT}/${data?.clientId}${MainPaths.CLIENT_BALANCE}`);
  };

  // handleClose
  const handleClose = () => {
    push({
      search: queryString.stringify({
        contractId: '0'
      })
    });
    setSearch('');
  };

  return (
    <div className={styles.clientInfo}>
      <span className={styles.clientInfo_close} onClick={handleClose}>
        <CloseIcon size={20} stroke="#A2A2A2" />
      </span>
      <div className={styles.clientInfo_container}>
        <div className={styles.clientInfo_container_title}>
          {/* <div className={styles.clientInfo_container_title_avatar}>
            {data?.clientName?.slice(0, 2)}
          </div>
          <div className={styles.clientInfo_container_title_content}>
            <h1>{data?.clientName}</h1>
            <div className={styles.clientInfo_container_title_content_body}>
              {data?.clientPhones?.map((item) => (
                <div
                  key={item?.id}
                  className={
                    styles.clientInfo_container_title_content_body_item
                  }
                >
                  <CallIcon /> {item?.phone}
                </div>
              ))}
            </div>

          </div> */}
          <ClientMiniInfo name={data?.clientName} score={data?.clientScore} phones={data?.clientPhones?.map(item => item.phone) || []} passportExpired={data?.passportExpired} />
        </div>
        <div className={styles.clientInfo_container_center}>
          <div className={styles.clientInfo_container_center_item} onClick={handleClientBalance}>
            <DollarSquareBorderredIcon size={20} /> {t('payment.Mijoz_balansi')}
          </div>
          <div className={styles.clientInfo_container_center_item} onClick={handleContract}>
            <ClipBoardTickIcon size={20} /> Mijoz shartnomasi
          </div>
        </div>
      </div>
      {data?.clientBalances?.some(item => item?.amount) && (
        <div className={styles.clientInfo_balance}>
          {data?.clientBalances?.map((item, index) => (
            <div className={styles.clientInfo_balance_item} key={index}>
              <p>{item?.currencyDto?.basic ? 'Asosiy balans' : 'Qo’shimcha balans'}</p>
              <h3>
                {item?.amount?.toLocaleString('ru')} {item?.currencyDto?.ccy}
              </h3>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PaymentClientInfo;
