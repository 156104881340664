import React, { useEffect, useState } from 'react';
import { Dropdown, Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLineDownIcon from '../../assets/icons/ArrowLineDownIcon';
import Download from '../../assets/icons/Download';
import ExcelIcon from '../../assets/icons/ExcelIcon';
import MessagePremium from '../../components/filters/MessagePremium';
import TableSettings from '../../components/filters/TableSettings';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import PaginationCont from '../../components/pagenation/PaginationCont';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useTableConfig } from '../../queries/queries';
import { Permissions } from '../../utils/constants/permissions';
import { TableKey } from '../../utils/models/TableKey';

import MessageFilters from './components/MessageFilters';
import MessageTable from './components/MessageTable';
import MessageSendModal from './components/sendMessage/MessageSendModal';
import { useDownloadMessagesToExcel } from './services/mutation';
import { useMessageList } from './services/queries';

import './message.module.scss';
import stylesButton from '../../components/filters/tableSettings.module.scss';

const { Content } = Layout;

export interface SearchParamsMessage {
  page?: string;
  size?: string;
  startDate?: string;
  finishDate?: string;
  statuses?: string[];
  search?: string;
  order?: string[];
}

const Message = () => {
  const { searchParams, appendMultipleDifferent } = useQueryParam<SearchParamsMessage, any>();
  const [tableSize, setTableSize] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [visibleDownloadMenu, setVisibleDownloadMenu] = useState(false);

  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useMessageList(tableSize, searchParams);
  const download = useDownloadMessagesToExcel(tableSize, searchParams);

  const { data: tableConfig, isFetching: tableConfigFetch } = useTableConfig(TableKey.message);

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);

  const isAccessSendMessage = permissions?.includes(Permissions.PERMISSION_SEND_MESSAGE);

  useEffect(() => {
    if (tableConfig?.size) {
      appendMultipleDifferent(['page', 'size'], [1, tableConfig.size]);
      setTableSize(tableConfig.size);
    }
  }, [tableConfig?.size]);

  const changePage = (page: number) => {
    appendMultipleDifferent(['page', 'size'], [page, tableSize]);
  };

  const downloadExcel = () => {
    download.mutate();
    setVisibleDownloadMenu(false);
  };

  const downloadButton = (
    <Dropdown
      visible={visibleDownloadMenu}
      onVisibleChange={visible => {
        setVisibleDownloadMenu(visible);
      }}
      placement="bottomRight"
      overlay={
        <div className={stylesButton.downloadPopover}>
          <div onClick={downloadExcel} className={stylesButton.downloadPopover_item}>
            <div className={stylesButton.downloadPopover_item_element}>
              <ExcelIcon />
              <span>{t('home.Excel')}</span>
            </div>
            <ArrowLineDownIcon />
          </div>
        </div>
      }
      trigger={['click']}
    >
      <span onClick={() => setVisibleDownloadMenu(true)} className={stylesButton.icon}>
        <Download />
      </span>
    </Dropdown>
  );

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className="message_page">
          <div className="d-flex align-center justify-space-between">
            <MessageFilters />
            <Spin spinning={download.isLoading}>
              <TableSettings downloadButton={downloadButton} tableKey={TableKey.message} />
            </Spin>
            {isAccessSendMessage && (
              <div onClick={() => setModalVisible(true)}>
                <MessagePremium />
              </div>
            )}
          </div>
          <MessageTable
            isFetching={isFetching}
            data={data?.data || []}
            isLoading={isLoading}
            currentPage={searchParams.page ? Number(searchParams.page) : 1}
            pageSize={tableSize}
            tableConfig={tableConfig?.config || []}
            tableConfigFetch={tableConfigFetch}
          />
          <PaginationCont
            total={data?.totalElements !== undefined ? data.totalElements : -1}
            totalPages={data?.totalPages || 1}
            changePage={changePage}
            currentPage={data?.currentPage ? data.currentPage : 1}
            defaultPageSize={tableSize}
          />
          <MessageSendModal visible={modalVisible} setVisible={setModalVisible} />
        </div>
      </Content>
    </>
  );
};

export default Message;
