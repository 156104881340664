import { baseErrorHandler } from "@queries/baseErrorHandler";
import { showMessage } from "@queries/showMessage";
import { $api } from "@service/RequestService";
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";

export function useLogOutGoogleAuth() {
    const qc = useQueryClient();

    return useMutation(
        async (data: any) => {
            const res = await $api.post(endPoints.GOOGLE_AUTH_LOGOUT, data);

            return res.data;
        },
        {
            onSuccess: res => {
                qc.invalidateQueries([queryKeys.GOOGLE_AUTH_LOGOUT]);
                showMessage(res.message, res.accept);
            },
            onError: baseErrorHandler
        }
    );
}
