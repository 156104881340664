import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { Task } from '../../../utils/models/Task';
import styles from './completedTasks.module.scss';
import MessageBlueIcon from '../../../assets/icons/MessageBlueIcon';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import Edit2Icon from '../../../assets/icons/Edit2Icon';
import TrushSquareIcon from '../../../assets/icons/TrushSquareIcon';
import MiniMenuIcon from '../../../assets/icons/MiniMenuIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useDeleteTask } from '../../../queries/mutation';
import EditTaskModal from './EditTaskModal';

interface Props {
  task: Task;
}

const NoteTypeTask: React.FC<Props> = ({ task }) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { params } = useQueryParam();

  const deleteTask = useDeleteTask(Number(params.contractId));

  const { t } = useTranslation();

  // handle drop down
  const handleDropDown = (visible: boolean) => {
    setOpenDropDown(visible);
  };

  // handle edit modal
  const handleEditModal = (visible: boolean) => {
    setOpenEditModal(visible);
    handleDropDown(false);
  };

  // handle delete
  const handleDeleteTask = () => {
    deleteTask.mutate(task?.id || -1);
    handleDropDown(false);
  };

  return (
    <div className={styles.item}>
      <div className={styles.item_icon}>
        <MessageBlueIcon />
      </div>
      <div>
        <p className={styles.item_closed}>{getCurrentLanguageWord(task.text)}</p>
      </div>
      <Dropdown
        visible={openDropDown}
        placement={'bottomLeft'}
        onVisibleChange={visible => handleDropDown(visible)}
        overlayClassName={styles.dropdown}
        overlay={
          <>
            <p>{t('arrearage.Amallar')}</p>
            <div
              onClick={() => {
                handleEditModal(true);
              }}
            >
              <Edit2Icon />
              <span>{t('client.Tahrirlash')}</span>
            </div>
            <div
              onClick={e => {
                e.stopPropagation();
                handleDeleteTask();
              }}
            >
              <TrushSquareIcon />
              <span>{t('discount_setting.Ochirish')}</span>
            </div>
          </>
        }
        trigger={['click']}
      >
        <span
          onClick={e => {
            e.stopPropagation();
            handleDropDown(true);
          }}
          className={styles.item_button}
        >
          <MiniMenuIcon />
        </span>
      </Dropdown>
      <EditTaskModal visible={openEditModal} onClose={handleEditModal} isCompleted={true} task={task} />
    </div>
  );
};

export default NoteTypeTask;
