import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

import styles from './filterButton.module.scss';
import FilterIcon from '@icons/FilterIcon';
import FilterDrawer from './drawer/FilterDrawer';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';

const FilterButton = () => {
  const [filterDriverOpen, setFilterDriverOpen] = useState(false);
  const [countFilters, setCountFilters] = useState(0);

  const { searchParams } = useQueryParam<SearchParamsTask, any>();

  const { t } = useTranslation();

  useEffect(() => {
    // url has one no filter params
    let count = -1;
    if (searchParams.pipeStatusIds) {
      if (Array.isArray(searchParams.pipeStatusIds)) {
        count += searchParams.pipeStatusIds.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.leadTaskTypeIds) {
      if (Array.isArray(searchParams.leadTaskTypeIds)) {
        count += searchParams.leadTaskTypeIds.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.createdByIds) {
      if (Array.isArray(searchParams.createdByIds)) {
        count += searchParams.createdByIds.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.employeeList) {
      if (Array.isArray(searchParams.employeeList)) {
        count += searchParams.employeeList.length;
      } else {
        count += 1;
      }
    }
    if (searchParams.startDate && searchParams?.finishDate) {
      count += 1;
    }
    if (searchParams.leadTaskTypeFilter) {
      count += 1;
    }
    if (searchParams.leadTaskStatusFilter) {
      count += 1;
    }
    setCountFilters(count);
  }, [searchParams]);

  // handle filter drawer
  const handleFilterDriver = (visible: boolean) => {
    setFilterDriverOpen(visible);
  };

  return (
    <>
      <div className={styles.container}>
        {countFilters === -1 ? (
          <Button onClick={() => handleFilterDriver(true)} className={styles.container_button}>
            <FilterIcon />
            <span>{t('crm_filter.Filtr')}</span>
          </Button>
        ) : (
          <Button onClick={() => handleFilterDriver(true)} className={styles.container_button_has_filter}>
            <FilterIcon />
            <span className={styles.container_button_has_filter_title}>{t('crm_filter.Filtr')}</span>
            <span className={styles.container_button_has_filter_count}>{countFilters + 1}</span>
          </Button>
        )}
      </div>
      <FilterDrawer visible={filterDriverOpen} onClose={handleFilterDriver} />
    </>
  );
};

export default FilterButton;
