import React from 'react';

const ArrowLocalIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7041 11.954L18.2813 9.37496H8.25C7.15598 9.37496 6.10677 9.80956 5.33318 10.5831C4.5596 11.3567 4.125 12.4059 4.125 13.5C4.125 14.594 4.5596 15.6432 5.33318 16.4168C6.10677 17.1904 7.15598 17.625 8.25 17.625H16.5C16.7984 17.625 17.0845 17.7435 17.2955 17.9545C17.5065 18.1654 17.625 18.4516 17.625 18.75C17.625 19.0483 17.5065 19.3345 17.2955 19.5455C17.0845 19.7564 16.7984 19.875 16.5 19.875H8.25C6.55925 19.875 4.93774 19.2033 3.74219 18.0078C2.54665 16.8122 1.875 15.1907 1.875 13.5C1.875 11.8092 2.54665 10.1877 3.74219 8.99215C4.93774 7.79661 6.55925 7.12496 8.25 7.12496H18.2813L15.7041 4.5459C15.5994 4.44125 15.5164 4.31702 15.4598 4.18029C15.4031 4.04356 15.374 3.89702 15.374 3.74902C15.374 3.60103 15.4031 3.45448 15.4598 3.31776C15.5164 3.18103 15.5994 3.05679 15.7041 2.95215C15.8087 2.8475 15.9329 2.76449 16.0697 2.70785C16.2064 2.65122 16.3529 2.62207 16.5009 2.62207C16.6489 2.62207 16.7955 2.65122 16.9322 2.70785C17.0689 2.76449 17.1932 2.8475 17.2978 2.95215L21.7978 7.45215C21.9027 7.55666 21.9859 7.68086 22.0427 7.8176C22.0995 7.95435 22.1287 8.10096 22.1287 8.24902C22.1287 8.39709 22.0995 8.5437 22.0427 8.68044C21.9859 8.81719 21.9027 8.94138 21.7978 9.0459L17.2978 13.5459C17.0865 13.7572 16.7998 13.876 16.5009 13.876C16.2021 13.876 15.9154 13.7572 15.7041 13.5459C15.4927 13.3346 15.374 13.0479 15.374 12.749C15.374 12.4501 15.4927 12.1635 15.7041 11.9521V11.954Z"
        fill="#5A5A5A"
      />
    </svg>
  );
};

export default ArrowLocalIcon;
