import React from 'react';

import styles from './dateSlider.module.scss';
import { useAppSelector } from '@hooks/reduxHooks';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import Day from './day/Day';

const DateSlider = () => {
  const type = useAppSelector(state => state.taskCalendarViewSlice.type);

  // activePage
  const activePage = () => {
    switch (type) {
      case TaskCalendarViewType.DAY:
        return <Day />;
      default:
        return <></>;
    }
  };

  return <div className={styles.container}>{activePage()}</div>;
};

export default DateSlider;
