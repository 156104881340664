import React from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { useCustomerFlow } from '@queries/queries';

import Header from './header/Header';
import PipesBody from './PipesBody';

import styles from './pipies.module.scss';

const Pipes: React.FC = () => {
  const { selectedPipe, employeeId, leadStatus, finishDatePipe, startDatePipe } = useAppSelector(state => state.crmStatistic);
  const { data, isLoading, isFetching } = useCustomerFlow(selectedPipe?.id, employeeId, leadStatus, finishDatePipe, startDatePipe);

  return (
    <div className={styles.container}>
      <Header pipeName={data?.pipeName} />
      <PipesBody data={data} isFetching={isFetching} isLoading={isLoading} />
    </div>
  );
};

export default Pipes;
