import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsiveLine } from '@nivo/line';
import { Segmented } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import DotIcon from '../../../../assets/icons/contract/DotIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useMobileSaleStats } from '../../../../queries/queries';
import { DATE_FORMAT } from '../../../../utils/constants/format';
import { colors } from '../../../../utils/constants/showRoomColors';
import { BossPaymentsType } from '../../../../utils/models/BossPayments';
import { SaleStatType } from '../../../../utils/models/SaleStateType';
import { BossPaymentsSearchParams } from '../../../bossPayments/BossPayments';

import styles from './bossSaleLine.module.scss';

const BossSaleLine = () => {
  const { t } = useTranslation();
  const [statsType, setStatsType] = useState<any>(SaleStatType.ROOMS);
  const { searchParams } = useQueryParam<BossPaymentsSearchParams, any>();
  const [newData, setNewData] = useState<any[]>([]);
  const [activeLine, setActiveLine] = useState('');
  const { buildings, houses } = useAppSelector(state => state.bossCurrency);

  const startDate = dayjs(`01.${dayjs(new Date()).format('MM.YYYY')}`, DATE_FORMAT).format(DATE_FORMAT);

  const finishDate = dayjs(startDate, DATE_FORMAT).add(1, 'month').add(-1, 'day').format(DATE_FORMAT);

  const { data } = useMobileSaleStats({
    statType: searchParams.type || BossPaymentsType.MONTH,
    saleStatType: statsType,
    start: searchParams.start || startDate,
    finish: searchParams.finish || finishDate,
    buildings: buildings?.length! > 0 ? buildings : null,
    houses: houses?.length! > 0 ? houses : null
  });

  useEffect(() => {
    const convertDataId = (id: string) => {
      const obj = {
        [SaleStatType.ROOMS]: `${id} ${t('home.xonali')}`,
        [SaleStatType.FLOOR]: `${id}-${t('home.qavat')}`,
        [SaleStatType.APARTMENT]: id === 'true' ? t('home.Tamirli') : t('home.Tamirsiz')
      };

      return obj[statsType as keyof typeof obj];
    };

    if (data) {
      const dataCopy: any[] = [];
      const dataConvert: any[] = [];

      data?.forEach(item => {
        const objectKeeys = Object.keys(item?.objects);

        if (objectKeeys.length > 0) {
          objectKeeys.forEach(key => {
            if (!dataConvert.includes(key)) {
              dataConvert.push(key);
            }
          });

          dataCopy.push(item);
        }
      });
      const newData = dataConvert?.map((item, index) => ({
        id: convertDataId(item),
        color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)],
        data: data?.map(value => ({
          x: value.date,
          y: value?.objects[item] || 0
        }))
      }));

      setNewData(newData);
    }
    // eslint-disable-next-line
  }, [data, statsType]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Segmented
          className={styles.segmented}
          size="large"
          onChange={setStatsType}
          options={[
            {
              label: <div className={styles.segmented_item}>{t('home.Xonalar_soni')}</div>,
              value: SaleStatType.ROOMS
            },
            {
              label: <div className={styles.segmented_item}>{t('home.Qavatlar')}</div>,
              value: SaleStatType.FLOOR
            },
            {
              label: <div className={styles.segmented_item}>{t('home_id.Xonadon_turi')}</div>,
              value: SaleStatType.APARTMENT
            }
          ]}
        />
      </div>
      <ResponsiveLine
        curve="monotoneX"
        data={newData?.filter(item => item?.id === activeLine || !activeLine)}
        margin={{ top: 50, right: 130, bottom: 130, left: 60 }}
        useMesh={true}
        axisBottom={{
          format: (value: string | number | Date | dayjs.Dayjs | null | undefined) => {
            switch (searchParams.type) {
              case BossPaymentsType.MONTH:
                return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
              case BossPaymentsType.YEAR:
                return dayjs(value, DATE_FORMAT).format('YYYY');
              case BossPaymentsType.DAY:
                return dayjs(value, DATE_FORMAT).format('DD');
              default:
                return t(`boss_payments.${dayjs(value, DATE_FORMAT).format('MMM')}`);
            }
          }
        }}
        colors={e => e?.color}
        pointSize={12}
        pointColor="white"
        pointBorderWidth={4}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        tooltip={props => (
          <div className={styles.tooltip}>
            <h1>{dayjs(props.point.data.x, DATE_FORMAT).format('MMM YYYY')}</h1>
            {newData?.map(item => (
              <p key={item?.id}>
                <span>
                  <DotIcon size={8} color={item?.color} /> {item?.id}
                </span>
                <span>{item?.data?.find((el: any) => el?.x === props.point.data.x)?.y}</span>
              </p>
            ))}
          </div>
        )}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            onMouseEnter: (props, data) => {
              setActiveLine(String(props?.id));
            },

            onMouseLeave: (props, data) => {
              setActiveLine('');
            },
            // data: newData,
            data: newData?.map(item => ({
              id: item?.id,
              label: item?.id,
              color: item?.color
            })),
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};

export default BossSaleLine;
