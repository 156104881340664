import React, { useEffect, useState } from 'react';
import styles from './botConfig.module.scss';
import { Form, Input, Spin, Switch } from 'antd';
import GoogleMapIcon from '../../../assets/icons/GoogleMapIcon';
import LinearMessage from '../../../assets/icons/LinearMessage';
import { useGetTelegramBot } from '../../../queries/queries';
import { TelegramBot } from '../../../utils/models/TelegramBot';
import { useCreateTelegramBot } from '../../../queries/mutation';
import FaqModal from './FaqModal';
import { useTranslation } from 'react-i18next';
import ChatsModal from './chats/ChatsModal';
import FacebookBlueIcon from '../../../assets/icons/FaceBookBlueIcon';

const BotInterfaceInputs = () => {
  const [faqModal, setFaqModal] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const [form] = Form.useForm<TelegramBot>();

  const { isLoading, data, isFetching } = useGetTelegramBot();

  const createBot = useCreateTelegramBot();

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);

  const onFinish = () => {
    handleUpdate();
  };

  const handleUpdate = () => {
    createBot.mutate({
      ...data,
      ...form.getFieldsValue()
    });
  };

  const handleFaqModal = (isVisible: boolean) => {
    setFaqModal(isVisible);
  };

  //handleChatModal
  const handleChatModal = (isVisible: boolean) => {
    setChatModal(isVisible);
  };

  return (
    <div className={styles.interface}>
      <Spin spinning={isLoading || isFetching || createBot.isLoading}>
        <Form form={form} onFinish={onFinish} name={'botInterface'}>
          <h1>{t('integration.Bot_interfeysini_sozlash')}</h1>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Uy_tanlash')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'houseActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <p className={styles.interface_sub_information}>
            {t('integration.Sotuvda_mavjud_bolgan_xonadonlarni_korish_narxlari_bilan_tanishish_va_buyurtma_qilish')}
          </p>
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Narxni_kursatish')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'showFlatPrice'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <br />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Buyurtma_qilish')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'bookingActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <br />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('crm.Buyurtma_qilingan_uylarni')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'showBookingFlat'}>
              <Switch onChange={handleUpdate} size="small" />
            </Form.Item>
          </div>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Biz_haqimizda')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'aboutActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <p className={styles.interface_sub_information}>{t('integration.Qurilish_kompaniyasi_haqida_qisqa_malumot')}</p>
          <div className={'d-flex align-center justify-space-between'}>
            <div className={styles.interface_language_text_area}>
              <label>{t('integration.Ozbekcha')}</label>
              <Form.Item noStyle name={'about'}>
                <Input.TextArea onBlur={handleUpdate} autoSize={{ minRows: 7, maxRows: 7 }} className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.interface_language_text_area}>
              <label>{t('integration.Ruscha')}</label>
              <Form.Item noStyle name={'aboutRu'}>
                <Input.TextArea onBlur={handleUpdate} autoSize={{ minRows: 7, maxRows: 7 }} className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.interface_language_text_area}>
              <label>{t('integration.Inglizcha')}</label>
              <Form.Item noStyle name={'aboutEn'}>
                <Input.TextArea onBlur={handleUpdate} autoSize={{ minRows: 7, maxRows: 7 }} className={'customInput'} />
              </Form.Item>
            </div>
          </div>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Aloqa')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'contactActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <div className={styles.interface_inputs_with_button}>
            <p>{t('integration.Manzilning_xaritada_joylashuvi')}</p>
            <div className={styles.interface_inputs_with_button_right}>
              <div>
                <GoogleMapIcon />
                <span>{t('integration.Google_xarita')}</span>
              </div>
              <Form.Item noStyle name={'longitude'}>
                <Input
                  onBlur={handleUpdate}
                  onPressEnter={handleUpdate}
                  type={'number'}
                  placeholder={t('integration.Uzunlik')}
                  className={'customInput'}
                />
              </Form.Item>
              <Form.Item noStyle name={'latitude'}>
                <Input
                  onBlur={handleUpdate}
                  onPressEnter={handleUpdate}
                  type={'number'}
                  placeholder={t('integration.Kenglik')}
                  className={'customInput'}
                />
              </Form.Item>
            </div>
          </div>
          <p className={styles.interface_sub_information}>{t('integration.Aloqa_va_manzil')}</p>
          <div className={'d-flex align-center justify-space-between'}>
            <div className={styles.interface_language_text_area}>
              <label>{t('integration.Ozbekcha')}</label>
              <Form.Item noStyle name={'contact'}>
                <Input.TextArea onBlur={handleUpdate} autoSize={{ minRows: 7, maxRows: 7 }} className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.interface_language_text_area}>
              <label>{t('integration.Ruscha')}</label>
              <Form.Item noStyle name={'contactRu'}>
                <Input.TextArea onBlur={handleUpdate} autoSize={{ minRows: 7, maxRows: 7 }} className={'customInput'} />
              </Form.Item>
            </div>
            <div className={styles.interface_language_text_area}>
              <label>{t('integration.Inglizcha')}</label>
              <Form.Item noStyle name={'contactEn'}>
                <Input.TextArea onBlur={handleUpdate} autoSize={{ minRows: 7, maxRows: 7 }} className={'customInput'} />
              </Form.Item>
            </div>
          </div>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Murojaatlar')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'appealActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <p className={styles.interface_sub_information}>{t('integration.Mijozlar_tomonidan_tushgan_murojaatlarni_qabul_qilish')}</p>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Shartnoma')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'contractActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.FAQ')}</h2>
            <Form.Item noStyle valuePropName="checked" name={'faqActive'}>
              <Switch onChange={handleUpdate} size={'small'} />
            </Form.Item>
          </div>
          <div onClick={() => handleFaqModal(true)} className={styles.interface_faq_button}>
            <p>{t('integration.Eng_kop_beriladigan_savollarga_javoblar')}</p>
            <div>
              <LinearMessage />
              <span>{t('integration.Savol_javoblar')}</span>
            </div>
          </div>
          <hr />
          <div className={styles.interface_header_with_switch}>
            <h2>{t('integration.Ijtimoiy_tarmoqlar')}</h2>
          </div>
          <div onClick={() => handleChatModal(true)} className={styles.interface_faq_button}>
            <p>{t('integration.Bot_foydalanuvchilarini_sahifalaringizga_obuna_qiling')}</p>
            <div>
              <FacebookBlueIcon />
              <span>{t('integration.Ijtimoiy_tarmoqlar_qoshish')}</span>
            </div>
          </div>
        </Form>
      </Spin>
      <FaqModal visible={faqModal} onClose={handleFaqModal} />
      <ChatsModal visible={chatModal} onClose={handleChatModal} />
    </div>
  );
};

export default BotInterfaceInputs;
