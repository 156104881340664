import { Button, Dropdown } from 'antd';
import { t } from 'i18next';
import React, { FC } from 'react';
import TrushSquareIcon from '../../assets/icons/TrushSquareIcon';
import styles from '../../pages/settingPlayMobile/settingPlayMobile.module.scss';
import { usePlayMobileConfOriginationDelete } from '../../queries/mutation';

interface SettingPlayMobileDeleteOriginationProps {
  id: number;
}

const SettingPlayMobileDeleteOrigination: FC<SettingPlayMobileDeleteOriginationProps> = ({ id }) => {
  const deleteOrigination = usePlayMobileConfOriginationDelete(id);
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <div className={styles.playMobileDelete}>
          <p>{t('discount_setting.Ochirmoqchimisiz')}</p>
          <div>
            <Button>{t('setting_play_mobile.Yoq')}</Button>
            <Button onClick={() => deleteOrigination.mutate()} loading={deleteOrigination.isLoading} type="primary">
              {t('setting_play_mobile.Ha')}
            </Button>
          </div>
        </div>
      }
    >
      <span>
        <TrushSquareIcon /> {t('discount_setting.Ochirish')}
      </span>
    </Dropdown>
  );
};

export default SettingPlayMobileDeleteOrigination;
