const Active = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M4.06072 9.35359H6.12072V14.1536C6.12072 15.2736 6.72739 15.5003 7.46739 14.6603L12.5141 8.92692C13.1341 8.22692 12.8741 7.64692 11.9341 7.64692H9.87405V2.84692C9.87405 1.72692 9.26739 1.50026 8.52739 2.34026L3.48072 8.07359C2.86739 8.78026 3.12739 9.35359 4.06072 9.35359Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Active;
