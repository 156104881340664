import React, { useRef } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { ResponsiveBar } from '@nivo/bar';
import { DATE_FORMAT } from '@utils/constants/format';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useGetBossMonthlyPayments } from '../../services/queries';

import styles from '../../bossMonthlyPayments.module.scss';

type Props = {
  currency: string;
  currencyId?: number;
  multiple: number[] | undefined;
  setMultiple: React.Dispatch<React.SetStateAction<number[] | undefined>>;
};

const MyResponsiveBar: React.FC<Props> = ({ currency, multiple, currencyId }) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const { start3, finish3 } = useAppSelector(state => state.crmStatistic);
  const startDate = dayjs.unix(Number(start3)).format(DATE_FORMAT);
  const finishDate = dayjs.unix(Number(finish3)).format(DATE_FORMAT);
  const { data: bar, isLoading } = useGetBossMonthlyPayments(startDate, String(finishDate), multiple, currencyId);
  const { t } = useTranslation();
  const dataConvert = Array.from({
    length: Number(dayjs(finishDate, DATE_FORMAT).diff(dayjs(startDate, DATE_FORMAT), 'month')) + 1
  }).map((_, index) => dayjs(startDate, DATE_FORMAT).startOf('month').add(index, 'month').format(DATE_FORMAT));

  const newData = bar?.graphicPayments?.filter(item => item?.ccy === currency || item?.ccy === '') || [];
  const newData2 = bar?.paidPayments?.filter(item => item?.ccy === currency || item?.ccy === '') || [];

  const arr = [
    ...dataConvert?.map(item => ({
      date: item,
      amount: newData?.find(i => i?.date === item)?.amount || 0,
      amount2: newData2?.find(i => i?.date === item)?.sum || 0,
      color: '#FECB49'
    }))
  ];

  const tickValues = dataConvert.filter((_, index) => index % (Math.floor(dataConvert.length / 24) || 1) === 0);

  return (
    <Spin spinning={isLoading}>
      <ResponsiveBar
        data={arr}
        keys={['amount', 'amount2']}
        groupMode="grouped"
        indexBy="date"
        margin={{ top: 50, right: 0, bottom: 50, left: 130 }}
        padding={0.1}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        // colors={['red', 'green']}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 30,
          tickRotation: 20,
          legendOffset: 0,
          legendPosition: 'middle',
          tickValues,
          format: e => (
            <>
              {t(`boss_payments.${dayjs(e, DATE_FORMAT).format('MMM')}`)} {dayjs(e, DATE_FORMAT).format('YYYY')}
            </>
          )
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 20,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          format: props => <>{`${props.toLocaleString('ru')} ${currency}`}</>
        }}
        enableGridX={false}
        layers={[
          'axes',
          'grid',
          props => {
            const data1 = props?.bars?.filter(item => item?.data?.id === 'amount');
            const data2 = props?.bars?.filter(item => item?.data?.id === 'amount2');
            const barWidth = props?.innerWidth / data1?.length;
            const tooltip = document?.querySelector(`.${styles.tooltip}`);

            return (
              <>
                {data1?.map((item, index) => {
                  const item2 = data2[index];

                  return (
                    <g
                      key={index}
                      onMouseOut={e => {
                        tooltipRef.current?.setAttribute(
                          'style',
                          `left: ${e?.clientX - tooltipRef.current?.clientWidth / 2}px; top: ${e?.clientY - tooltipRef.current?.clientHeight - 12}px; opacity: 0;`
                        );
                      }}
                      onMouseMove={e => {
                        tooltipRef.current?.setAttribute(
                          'style',
                          `left: ${e?.clientX - tooltipRef.current?.clientWidth / 2}px; top: ${e?.clientY - tooltipRef.current?.clientHeight - 12}px; opacity: 1;`
                        );
                        tooltip!.innerHTML = `
                        <span>
                       ${t(`boss_payments.${dayjs(item?.data?.data?.date, DATE_FORMAT).format('MMM')}`)} ${dayjs(item?.data?.data?.date, DATE_FORMAT).format('YYYY')}
                      </span>
                      <span style="display: ${item?.data?.data?.amount2 ? '' : 'none'}">
                      ${t('monetary.Tolov_miqdori')}
                         ${item?.data?.data?.amount2?.toLocaleString('ru')} ${currency}
                      </span>
                      <span style="display: ${data2?.find(e => e?.data?.data?.date === item?.data?.data?.date)?.data?.data?.amount ? '' : 'none'};">
                        ${t('boss_home.Reja')}: ${data2?.find(e => e?.data?.data?.date === item?.data?.data?.date)?.data?.data?.amount?.toLocaleString('ru')} ${currency}
                      </span>
                        `;
                      }}
                    >
                      {item?.data?.data?.amount && (
                        <rect
                          style={{ transition: '0.3s' }}
                          x={index * barWidth}
                          y={item?.y}
                          width={barWidth}
                          height={props?.innerHeight - item?.y}
                          fill="#FECB49"
                          stroke="white"
                        />
                      )}
                      {item2?.data?.data?.amount && (
                        <rect
                          style={{ transition: '0.3s' }}
                          fill="#00A389"
                          x={index * barWidth + barWidth / 6}
                          y={item2.y}
                          width={barWidth - barWidth / 3}
                          height={item2?.height}
                        />
                      )}
                    </g>
                  );
                })}
              </>
            );
          }
        ]}
      />
      <div className={styles.tooltip} ref={tooltipRef}>
        <span>{/* {dayjs(props?.point?.data?.x, DATE_FORMAT).format('MMM YYYY')} */}</span>
        <span>
          {t('monetary.Tolov_miqdori')}:{/* {` ${props?.point?.data?.y.toLocaleString('ru')} ${currency}`} */}
        </span>
        {/* {tolov && <span>To'lov: {tolov?.toLocaleString('ru')}</span>} */}
        <span>
          To'lov:
          {/* {tolov?.toLocaleString('ru')} */}
        </span>
      </div>
    </Spin>
  );
};

export default MyResponsiveBar;
