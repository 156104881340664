import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsClient } from '@pages/client/Client';
import { queryNames } from '@utils/constants/queryNames';
import { useMutation, useQueryClient } from 'react-query';

import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { showMessage } from '../../../queries/showMessage';
import i18n from '../../../service/I18nService';
import { $api } from '../../../service/RequestService';
import { getDebitsBody } from '../../../utils/helper/getSearchAndDownloadBodyAllTables';
import { SearchParamsArrearage } from '../Arrearage';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useDownloadDebitsToExcel(size: number, searchObj: SearchParamsArrearage) {
  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.DEBT_EXCEL}?lang=${i18n.language?.toUpperCase()}`, getDebitsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Qarzdorlik-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useArrearageOneDayBeta(size: number, searchObj: SearchParamsArrearage, onClose: () => void) {
  return useMutation(
    async (date: string) => {
      const res = await $api.post(
        `${endPoints.DEBT_EXCEL_BETA}?lang=${i18n.language?.toUpperCase()}`,
        {
          ...getDebitsBody(size, searchObj),
          date
        },
        {
          responseType: 'blob'
        }
      );

      return {
        data: res.data,
        date
      };
    },
    {
      onSuccess: data => {
        // downloadFile(data.data, `Qarzdorlik-${data.date}`, 'xlsx');
        onClose();
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useSendMessageToAllDebtor(searchParams: SearchParamsArrearage) {
  const queryClient = useQueryClient();

  const paramCopy = { ...getDebitsBody(10, searchParams) };

  delete paramCopy.page;
  delete paramCopy.size;

  return useMutation(
    async (variables: { contracts: number[] | null; ignoreContracts: number[] }) => {
      const { contracts, ignoreContracts } = variables;
      const res = await $api.post(endPoints.DEBT_SEND_MESSAGE, {
        contracts,
        ignoreContracts,
        ...paramCopy
      });

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        // Optionally, you can invalidate queries or perform other actions on success
        queryClient.invalidateQueries('yourQueryKey');
      },
      onError: baseErrorHandler
    }
  );
}

export function useDebitDelete(id: number, searchObj: SearchParamsArrearage) {
  const qc = useQueryClient();

  return useMutation(
    async () => {
      const res = await $api.delete(`${endPoints.CONTRACT + id}/surcharge`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.DEBIT_LIST, searchObj.page || '10', JSON.stringify(searchObj)]);
      },
      onError: baseErrorHandler
    }
  );
}

export function useChangeEmployeeArrearage(onSuccess?: () => void) {
  const qc = useQueryClient();

  return useMutation(
    async (data: { contractId: number; employeeId: number }) => {
      const res = await $api.put(`${endPoints.CONTRACT + data.contractId}/responsible-employee/${data.employeeId}`);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries([queryKeys.DEBIT_LIST]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: baseErrorHandler
    }
  );
}

export function useDownloadDebitsMonthlyToExcel(size: number, searchObj: SearchParamsArrearage) {
  return useMutation(
    async () => {
      const res = await $api.post(`${endPoints.DEBT_MONTHLY_EXCEL}?lang=${i18n.language?.toUpperCase()}`, getDebitsBody(size, searchObj), {
        responseType: 'blob'
      });

      return res.data;
    },
    {
      onSuccess: data => {
        // downloadFile(data, `Joriy va oldingi qarzdorliklar-${dayjs(Date.now()).format('DD-MM-YYYY')}`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}

export function useUpdateClientLevel() {
  const qc = useQueryClient();
  const { searchParams, params } = useQueryParam<SearchParamsArrearage & SearchParamsClient, any>();
  const searchObj = { ...searchParams, expired: Boolean(searchParams?.expired) };

  return useMutation(
    async (data: { clientId: number; level: string }) => {
      const res = await $api.post(endPoints.UPDATE_CLIENT_LEVEL, data);

      return res.data;
    },
    {
      onSuccess: data => {
        showMessage(data.message, data.accept);
        qc.invalidateQueries({ queryKey: [queryKeys.DEBIT_LIST, searchParams.page, JSON.stringify(searchParams)] });
        qc.invalidateQueries({ queryKey: [queryNames.CLIENT, searchObj.page, JSON.stringify(searchObj)] });
        qc.invalidateQueries({ queryKey: [queryNames.CLIENT_ID, Number(params?.idClient)] });
        qc.invalidateQueries({ queryKey: [queryNames.CLIENT_ID, Number(params?.idClient)] });
        qc.invalidateQueries({ queryKey: [queryNames.CLIENT_EVENT, Number(params?.idClient)] });
      },
      onError: baseErrorHandler
    }
  );
}
