import React from 'react';

const MinusCircleIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00016 1.33325C4.32683 1.33325 1.3335 4.32659 1.3335 7.99992C1.3335 11.6733 4.32683 14.6666 8.00016 14.6666C11.6735 14.6666 14.6668 11.6733 14.6668 7.99992C14.6668 4.32659 11.6735 1.33325 8.00016 1.33325Z"
        fill="#E53535"
      />
      <path
        d="M5.28027 8.49988H10.6136C10.8936 8.49988 11.1136 8.27321 11.1136 7.99988C11.1136 7.72654 10.8869 7.49988 10.6136 7.49988H5.28027C5.00694 7.49988 4.78027 7.72654 4.78027 7.99988C4.78027 8.27321 5.00694 8.49988 5.28027 8.49988Z"
        fill="white"
      />
    </svg>
  );
};

export default MinusCircleIcon;
