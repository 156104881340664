import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { useUzumHouses } from 'modules/uzum-integration';
import { useCreateService } from 'modules/uzum-integration/mutations/use-create-service';
import { useUpdateService } from 'modules/uzum-integration/mutations/use-update-service';
import { UzumIntegration } from 'modules/uzum-integration/schema';

import ServicesItem from './ServicesItem';

interface IProps {
  data: UzumIntegration | undefined;
}

const UzumServices: React.FC<IProps> = ({ data }) => {
  const { t } = useTranslation();
  const [formList] = Form.useForm();
  const createService = useCreateService();
  const updateService = useUpdateService();
  const [buttonIndex, setButtonIndex] = useState<number>(0);
  const { houses } = useUzumHouses();

  useEffect(() => {
    formList.setFieldsValue({
      ...data,
      username: data?.username || undefined,
      services: data?.services?.map(item => ({ ...item, houses: item?.houses?.map(prev => Number(prev?.id)) }))
    });
  }, [data]);

  const onFinish = (val: any) => {
    if (val?.services[buttonIndex]?.id) {
      updateService.mutateAsync({
        serviceId: Number(val?.services[buttonIndex]?.serviceId),
        houses: houses?.filter(item => val?.services[buttonIndex]?.houses?.includes(item?.id))?.map(prev => ({ id: prev?.id, name: prev?.name })),
        id: val?.services[buttonIndex]?.id
      });
    } else {
      createService.mutate({
        serviceId: Number(val?.services[buttonIndex]?.serviceId),
        houses: houses?.filter(item => val?.services[buttonIndex]?.houses?.includes(item?.id))?.map(prev => ({ id: prev?.id, name: prev?.name }))
      });
    }
  };

  return (
    <Form form={formList} layout="vertical" onFinish={onFinish}>
      <Row>
        <Form.List name="services">
          {(fields, { add, remove }) => (
            <div className="flex w-full flex-col">
              {fields.map(({ key, name, ...restField }) => (
                <ServicesItem key={key} name={name} restField={restField} form={formList} remove={remove} data={data} setButtonIndex={setButtonIndex} houses={houses} />
              ))}
              <Button
                disabled={data?.username === null}
                onClick={() => add()}
                type="dashed"
                block
                icon={<PlusOutlined />}
                className="h-[60px] bg-white py-2 !text-xl font-medium text-[#344054]"
              >
                {t('home.Xizmat_qoshish')}
              </Button>
            </div>
          )}
        </Form.List>
      </Row>
    </Form>
  );
};

export default UzumServices;
