import React from 'react';

const SwiperNextIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.23587 5.99448C8.23486 5.87896 8.18902 5.76834 8.10801 5.68597L3.74438 1.32233C3.66208 1.24285 3.55185 1.19886 3.43744 1.19986C3.32302 1.20085 3.21358 1.24674 3.13267 1.32765C3.05177 1.40855 3.00588 1.518 3.00488 1.63241C3.00389 1.74683 3.04787 1.85705 3.12736 1.93935L7.18248 5.99448L3.12736 10.0496C3.08568 10.0899 3.05244 10.138 3.02957 10.1912C3.0067 10.2445 2.99466 10.3017 2.99416 10.3597C2.99365 10.4176 3.00469 10.4751 3.02664 10.5287C3.04858 10.5823 3.08098 10.6311 3.12195 10.672C3.16292 10.713 3.21164 10.7454 3.26527 10.7673C3.3189 10.7893 3.37636 10.8003 3.4343 10.7998C3.49224 10.7993 3.5495 10.7873 3.60273 10.7644C3.65597 10.7415 3.70412 10.7083 3.74438 10.6666L8.10801 6.30299C8.18902 6.22062 8.23486 6.11 8.23587 5.99448Z"
        fill="#00A389"
      />
    </svg>
  );
};

export default SwiperNextIcon;
