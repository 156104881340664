import { models } from '../constants';
import { ITableRequestNumber, ITableRequestString } from '../models';

export const sendModel = (data: ITableRequestString | ITableRequestNumber, model?: keyof typeof models): ITableRequestString => {
  if (model === 'number') {
    return {
      key: data?.key,
      allUser: data?.allUser,
      value: String(data?.value)
    };
  }
  return {
    key: data?.key,
    allUser: data?.allUser,
    value: String(data?.value)
  };
};
