import CubeIcon from '@icons/CubeIcon';
import { useTranslation } from 'react-i18next';

import styles from './showroomVisual.module.scss';

const ShowroomVisualNone = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.visualNone}>
      <div className={styles.visualNone_left}>
        <h1>
          <CubeIcon /> {t('visual.Vizual_korinish')}
        </h1>
        <h3>{t('visual.Mijozlar_oz_xonadonlarini_vizual_korib_sotib_olish_imkoniyati')}</h3>
        <div>
          <p>{t('visual.Turarjoy_majmuasini_chiroyini_va_hashamatini_mijozga_toliq_korsatib_berish')}</p>
          <p>{t('visual.Bino_TJMning_qaysi_qismida_joylashganligi_korish')}</p>
          <p>{t('visual.Xonadonning_qavatlarda_joylashuvini_korish_va_tanlash')}</p>
          <p>{t('visual.Kochmas_mulkingizni_mijozlaringizga_tanishtirish_uchun_ajoyib_interfeys')}</p>
        </div>
        <h6>{t('visual.Bizga_murojaat_qiling')}</h6>
        <h2>+998 90 618 68 88</h2>
      </div>
      <div className={styles.visualNone_right}>
        <video
          autoPlay={true}
          loop
          muted
          preload="metadata"
          style={{
            objectFit: 'contain',
            zIndex: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        >
          <source src="https://get.uysot.uz/banner/showroom.promo.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default ShowroomVisualNone;
