import React from 'react';

const RightCheveronCircle = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 2.18557e-08 -2.18557e-08)" fill="white" />
      <path
        d="M28.3528 23.9918C28.3513 23.8185 28.2825 23.6526 28.161 23.529L21.6156 16.9835C21.4921 16.8643 21.3268 16.7983 21.1552 16.7998C20.9836 16.8013 20.8194 16.8702 20.698 16.9915C20.5767 17.1129 20.5078 17.277 20.5063 17.4487C20.5049 17.6203 20.5708 17.7856 20.6901 17.9091L26.7728 23.9918L20.6901 30.0745C20.6275 30.1348 20.5777 30.2071 20.5434 30.2869C20.5091 30.3668 20.491 30.4527 20.4903 30.5396C20.4895 30.6265 20.5061 30.7127 20.539 30.7931C20.5719 30.8736 20.6205 30.9466 20.6819 31.0081C20.7434 31.0695 20.8165 31.1182 20.8969 31.1511C20.9774 31.184 21.0636 31.2005 21.1505 31.1998C21.2374 31.199 21.3233 31.181 21.4031 31.1467C21.483 31.1124 21.5552 31.0625 21.6156 31L28.161 24.4545C28.2825 24.331 28.3513 24.165 28.3528 23.9918Z"
        fill="#00A389"
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        transform="matrix(4.37114e-08 1 1 -4.37114e-08 2.18557e-08 -2.18557e-08)"
        stroke="#00A389"
      />
    </svg>
  );
};

export default RightCheveronCircle;
