import React from 'react';

import styles from './budget.module.scss';
import TrushSquareIcon from '../../../../../../../assets/icons/TrushSquareIcon';
import { helper } from '../../../../../../../utils/helper/helper';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { setBudgetCondition, setBudgetHigh, setBudgetLow } from '../../../../../../../store/reducers/TriggerSlice';
import { TriggerConditionErrorType } from '../../../../../../../utils/models/Trigger';
import i18n from '../../../../../../../service/I18nService';
import { useTranslation } from 'react-i18next';

interface Props {
  order: number;
}

const Budget: React.FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const min = useAppSelector(state => state.triggerSlice?.balance?.lowString);
  const max = useAppSelector(state => state.triggerSlice?.balance?.highString);
  const errorList = useAppSelector(state => state.triggerSlice.errorList);

  // handle change
  const handleChange = (type: 'min' | 'max', value: string) => {
    const val = helper.deleteNotNumbersAndParseFloatNumber(value).string;
    if (type === 'min') {
      dispatch(setBudgetLow(val));
    } else {
      dispatch(setBudgetHigh(val));
    }
  };

  // handle delete budget
  const handleDeleteBudget = () => {
    dispatch(
      setBudgetCondition({
        add: false
      })
    );
  };

  // return width
  const getWidth = (val: string) => {
    if (val.length <= 5) return 50;
    if (val.length > 11) return 110;
    return val.length * 10;
  };

  // show error
  const showError = () => {
    let errorValue = false;
    errorList.forEach(error => {
      if (error.type === TriggerConditionErrorType.BUDGET) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  // translate
  const translateBalanceInputs = () => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            <span className={styles.container_body_label}>от</span>
            <input
              style={{
                width: getWidth(min || '')
              }}
              value={min}
              onChange={e => handleChange('min', e.target.value)}
              className={styles.container_body_input}
            />
            <span className={styles.container_body_label}>до</span>
            <input
              style={{
                width: getWidth(max || '')
              }}
              value={max}
              onChange={e => handleChange('max', e.target.value)}
              className={styles.container_body_input}
            />
          </>
        );
      case 'en':
        return (
          <>
            <input
              style={{
                width: getWidth(min || '')
              }}
              value={min}
              onChange={e => handleChange('min', e.target.value)}
              className={styles.container_body_input}
            />
            <span className={styles.container_body_label}>to</span>
            <input
              style={{
                width: getWidth(max || '')
              }}
              value={max}
              onChange={e => handleChange('max', e.target.value)}
              className={styles.container_body_input}
            />
          </>
        );
      default:
        return (
          <>
            <input
              style={{
                width: getWidth(min || '')
              }}
              value={min}
              onChange={e => handleChange('min', e.target.value)}
              className={styles.container_body_input}
            />
            <span className={styles.container_body_label}>dan</span>
            <input
              style={{
                width: getWidth(max || '')
              }}
              value={max}
              onChange={e => handleChange('max', e.target.value)}
              className={styles.container_body_input}
            />
            <span className={styles.container_body_label}>gacha</span>
          </>
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_body + (showError() ? ' ' + styles.container_body_error : '')}>
        <span className={styles.container_body_title}>
          {order !== 0 && t('triggers.va')} {t('triggers.Budjet')}:{translateBalanceInputs()}
        </span>
      </div>
      <TrushSquareIcon onClick={handleDeleteBudget} className={styles.container_delete} />
    </div>
  );
};

export default Budget;
