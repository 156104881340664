import React from 'react';
import styles from './client.module.scss';

interface Props {
  title: string;
}

const NotFoundClient: React.FC<Props> = ({ title }) => {
  return <div className={styles.not_found_client}>{title}</div>;
};

export default NotFoundClient;
