import { useQuery } from 'react-query';
import { $api } from '../../../../service/RequestService';
import { baseErrorHandler } from '../../../../queries/baseErrorHandler';
import { queryKeys } from '../utils/constants/queryKeys';
import { endPoints } from '../utils/constants/endPoints';
import { AnalyticRatio } from '../utils/models/AnalyticRatio';
import { AnalyticCountOwnother } from '../utils/models/AnalyticCountOwnOther';
import { AnalyticCountContractLead } from '../utils/models/AnalyticCountContractLead';
import { AnalyticNumberOfSalesByEmployee } from '../utils/models/AnalyticNumberOfSalesByEmployee';

export const useGetAnalyticRatio = (startDate: string, endDate: string) => {
  return useQuery<AnalyticRatio>(
    [queryKeys.ANALYTIC_RATIO, startDate, endDate],
    async () => {
      const res = await $api.get(endPoints.ANALYTIC_RATIO + `?startDate=01.01.${startDate}&finishDate=31.12.${endDate}`);
      return res.data.data;
    },
    {
      enabled: !!(startDate && endDate),
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
};

export const useGetAnalyticCountOwnOther = (startDate: string, endDate: string) => {
  return useQuery<AnalyticCountOwnother[]>(
    [queryKeys.ANALYTIC_COUNT_OWN_OTHER, startDate, endDate],
    async () => {
      const res = await $api.get(endPoints.ANALYTIC_COUNT_OWN_OTHER + `?startDate=01.01.${startDate}&finishDate=31.12.${endDate}`);
      return res.data.data;
    },
    {
      enabled: !!(startDate && endDate),
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
};

export const useGetAnalyticCountContractLead = (startDate: string, endDate: string) => {
  return useQuery<AnalyticCountContractLead>(
    [queryKeys.ANALYTIC_COUNT_CONTRACT_LEAD, startDate, endDate],
    async () => {
      const res = await $api.get(endPoints.ANALYTIC_COUNT_CONTRACT_LEAD + `?startDate=01.01.${startDate}&finishDate=31.12.${endDate}`);
      return res.data.data;
    },
    {
      enabled: !!(startDate && endDate),
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
};

export const useGetAnalyticByEmployee = (startDate: string, endDate: string) => {
  return useQuery<AnalyticNumberOfSalesByEmployee[]>(
    [queryKeys.ANALYTIC_BY_EMPLOYEE, startDate, endDate],
    async () => {
      const res = await $api.get(endPoints.ANALYTIC_BY_EMPLOYEE + `?startDate=${startDate}&finishDate=${endDate}`);
      return res.data.data;
    },
    {
      enabled: !!(startDate && endDate),
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
};
