import { $api } from '@service/RequestService';
import { useMutation } from 'react-query';

export const useLeadTaskSaveGroup = () =>
  useMutation(
    async (data: {
      leadTaskTypeId: number;
      assignedById: number;
      deadlineStart: string;
      deadlineFinish: string;
      text: string;
      debtFilterDto?: {
        contracts?: number[] | null;
        ignoreContracts?: number[] | null;
      };
    }) => {
      const res = await $api.post('/v1/lead-task/save-group', data);

      return res.data.data;
    }
  );
