import React, { FC, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const iconPerson = new L.Icon({
  iconUrl: '/MapPin.svg',
  iconRetinaUrl: '/MapPin.svg',
  iconSize: new L.Point(25, 41)
});

export const DEFAULT_CENTER = {
  lat: 40.08535549506133,
  lng: 65.38380561484493
};

interface IProps {
  center: {
    lat: number;
    lng: number;
  };
  radius: number;
  setCenter: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  setSelect: React.Dispatch<React.SetStateAction<string>>;
}

const LocationAttachmentMap: FC<IProps> = ({ center, setCenter, radius, setSelect }) => {
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setCenter(e.latlng);
        setSelect(e?.latlng?.lat + ',' + e?.latlng?.lng);
      },
      locationfound(e) {
        map.flyTo(center);
      }
    });
    useEffect(() => {
      map.flyTo(center);
      // eslint-disable-next-line
    }, [center]);
    useEffect(() => {
      setTimeout(() => {
        map.invalidateSize();
      }, 150);
    }, [map]);

    return center === null ? null : (
      <Marker position={center} icon={iconPerson}>
        <Circle center={center} radius={radius} />
      </Marker>
    );
  };

  return (
    <MapContainer center={{ lat: center?.lat, lng: center?.lng }} zoom={15} scrollWheelZoom={true} style={{ height: '100%' }}>
      <TileLayer url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']} />
      <LocationMarker />
    </MapContainer>
  );
};

export default LocationAttachmentMap;
