import React from 'react';

const ComplexFourColorIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="8" width="16" height="16" rx="8" fill="#63CBA5" />
      <rect y="8" width="16" height="16" rx="8" fill="#FC65FF" />
      <rect x="8" width="16" height="16" rx="8" fill="#FF6565" />
      <rect width="16" height="16" rx="8" fill="#5786FF" />
    </svg>
  );
};

export default ComplexFourColorIcon;
