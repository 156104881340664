import { Pagination } from '@utils/models/Pagination';
import { useInfiniteQuery, useQuery } from 'react-query';

import { useAppDispatch } from '../../../hooks/reduxHooks';
import { baseErrorHandler } from '../../../queries/baseErrorHandler';
import { $api } from '../../../service/RequestService';
import { setCurrency, setMonthlyPaymentParts, setSelectedClient, setSelectedContract } from '../../../store/reducers/ChangesSlice';
import { ClientAndContractInfo, JuridicalPersonCreate, NaturalPersonCreate, SearchClientChanges } from '../../../utils/models/Client';
import { ContractChanges } from '../../../utils/models/ContractId';
import { ChangesMonthlyPayment } from '../../../utils/models/ContractMothlyPayment';
import { Flat } from '../../../utils/models/Flat';
import { CrmEmployee } from '../../../utils/models/OneLid';
import { PaymentListChange } from '../../../utils/models/PaymentChange';
import { IFormContractChanges } from '../components/contract/ContractInformation';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useContractAndClientInfoChanges(id: string) {
  return useQuery<ClientAndContractInfo>(
    [queryKeys.CONTRACT_AND_CLIENT_INFO_CHANGES, id],
    async () => {
      const res = await $api.get(`${endPoints.CONTRACT_CHANGES}/${id}/client`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function usePaymentsChangePage(id: string) {
  const dispatch = useAppDispatch();

  return useQuery<PaymentListChange>(
    [queryKeys.CHANGES_PAYMENT, id],
    async () => {
      const res = await $api.get(`${endPoints.CONTRACT_CHANGES}/${id}/payment`);

      return res.data.data;
    },
    {
      onSuccess: data => {
        dispatch(setCurrency(data.currency));
      },
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useTablePaymentsChange(id: string) {
  const dispatch = useAppDispatch();

  return useQuery<ChangesMonthlyPayment>(
    [queryKeys.CHANGES_MONTHLY_PAYMENT, id],
    async () => {
      const res = await $api.get(`${endPoints.CONTRACT_CHANGES}/${id}/monthlyPayment`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      onSuccess: data => {
        dispatch(setMonthlyPaymentParts(data));
      }
    }
  );
}

export function useGetBuildings(id: number) {
  const url = endPoints.BUILDING;

  return useQuery<IFormContractChanges[]>(
    [queryKeys.BUILDING_ID, id],
    async () => {
      const res = await $api.get(`${url}?houseId=${id}`);

      return res.data.data;
    },
    {
      enabled: id !== -1,
      refetchOnWindowFocus: false,
      onError: baseErrorHandler,
      refetchInterval: Infinity,
      retry: false
    }
  );
}

export function useFlatCompact(id: number) {
  const url = endPoints.FLAT;

  return useQuery<Flat[]>(
    [queryKeys.FLAT_COMPACT, id],
    async () => {
      const res = await $api.get(`${url}?buildingID=${id}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetContractChanges(id: string) {
  const url = endPoints.CONTRACT_CHANGES;

  return useQuery<ContractChanges[]>(
    [queryKeys.CHANGES_CONTRACT_LIST, id],
    async () => {
      const res = await $api.get(`${url}/client/${id}`);

      return res.data.data;
    },
    {
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetOneContractChanges(id: string, enabled: boolean) {
  const dispatch = useAppDispatch();

  return useQuery<ContractChanges>(
    [queryKeys.ONE_CONTRACT_CHANGE, id],
    async () => {
      const res = await $api.get(`${endPoints.CONTRACT_CHANGES}/${id}`);

      return res.data.data;
    },
    {
      enabled,
      onError: baseErrorHandler,
      onSuccess: data => {
        dispatch(setSelectedContract(data));
      },
      refetchOnWindowFocus: false
    }
  );
}

export function useEmployeeChanges() {
  return useQuery<CrmEmployee[]>(
    [queryKeys.CONTRACT_EMPLOYEE],
    async () => {
      const res = await $api.get(endPoints.CONTRACT_CHANGES_EMPLOYEE);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useOneClientChanges(id: string) {
  const dispatch = useAppDispatch();

  return useQuery<JuridicalPersonCreate | NaturalPersonCreate>(
    [queryKeys.ONE_CLIENT_CHANGE, id],
    async () => {
      const res = await $api.get(`${endPoints.CLIENT_CHANGES}/${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler,
      onSuccess: data => {
        dispatch(
          setSelectedClient({
            data,
            phones: data.phones
          })
        );
      }
    }
  );
}

export function useSearchClientChanges(search: string) {
  return useInfiniteQuery<Pagination<SearchClientChanges>>(
    [queryKeys.SEARCH_CLIENT_CHANGES, search],
    async ({ pageParam = 1 }) => {
      if (search?.length >= 3) {
        const res = await $api.get(`${endPoints.CLIENT_CHANGES}?search=${search}&page=${pageParam}`);

        return res.data.data;
      }
      return null;
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1;
        }
        return undefined;
      },
      enabled: search !== '' && search.length >= 3,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}
