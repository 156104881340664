import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CompanyEmployeesState {
  editAndCreateModalOpen: boolean;
  id: number;
}

const initialState: CompanyEmployeesState = {
  editAndCreateModalOpen: false,
  id: 0
};

export const companyEmployeesSlice = createSlice({
  name: 'companyEmployees',
  initialState,
  reducers: {
    editAndCreateModal: (state, action: PayloadAction<{ open: boolean; id: number }>) => {
      state.editAndCreateModalOpen = action.payload.open;
      state.id = action.payload.id;
    }
  }
});

export const { editAndCreateModal } = companyEmployeesSlice.actions;
export default companyEmployeesSlice.reducer;
