import React from 'react';
import NavBar from '@components/navbars/boss-contract/Navbar';
import ContractStatisticsRouter from '@routing/contract-statistics/ContractStatisticsRouter';
import { t } from 'i18next';

import NotificationButtons from '../../components/headers/components/NotificationButtons';
import MainHeader from '../../components/headers/MainHeader';

import styles from './bossContract.module.scss';

const BossContract: React.FC = () => (
  <>
    <MainHeader>
      <h1 className={styles.header}>{t(`titles.contract`)}</h1>
      <NotificationButtons />
    </MainHeader>
    <div className={styles.wrapper}>
      <NavBar />
      <ContractStatisticsRouter />
    </div>
  </>
);

export default BossContract;
