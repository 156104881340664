import React from 'react';

import Bottom from './bottom';
import Center from './center';
import Filter from './filter';
import Top from './top';

const PlanFact: React.FC = () => (
  <div className="box-border w-full overflow-auto p-4">
    <div className="box-border flex h-max w-full flex-col gap-8 rounded-xl bg-white p-5">
      <Filter />
      <Top />
      <Center />
      <Bottom />
    </div>
  </div>
);

export default PlanFact;
