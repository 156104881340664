import React from 'react';
import { ContractDetailProps } from '@utils/models/BossContractDetail';
import { useTranslation } from 'react-i18next';

import styles from '../bossContractPeriod/bossContractPeriodInfo.module.scss';

type Props = {
  item: ContractDetailProps;
};

const BossContractPeriodItem: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.body_txt_cont}>
      <div className={styles.body_txt_main}>
        <div className={styles.body_txt}>
          <div className={styles.txt_left}>
            <p>{t('boss_contract.Mijoz')}</p>
          </div>
          <div className={styles.txt_right}>
            <p>{item.clientName}</p>
          </div>
        </div>
        <div className={styles.body_txt}>
          <div className={styles.txt_left}>
            <p>{t('boss_contract.Xodim')}</p>
          </div>
          <div className={styles.txt_right}>
            <p>{item.createdByName}</p>
          </div>
        </div>
        <div className={styles.body_txt}>
          <div className={styles.txt_left}>
            <p>{t('boss_contract.Shartnoma_summasi')}</p>
          </div>
          <div className={styles.txt_right}>
            <p>
              {item.amount.toLocaleString('ru')} {item?.currency?.ccy}
            </p>
          </div>
        </div>
        <div className={styles.body_txt}>
          <div className={styles.txt_left}>
            <p>{t('boss_contract.TJM')}</p>
          </div>
          <div className={styles.txt_right}>
            <p>{item.houseName}</p>
          </div>
        </div>
        <div className={styles.body_txt}>
          <div className={styles.txt_left}>
            <p>{t('boss_contract.Shartnoma_raqami')}</p>
          </div>
          <div className={styles.txt_right}>
            <p className={styles.bold}>{item.number}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BossContractPeriodItem;
