import React, { FC } from 'react';
import { useMonetaryRollback } from '@queries/mutation';
import CloseIcon from '@icons/contract/CloseIcon';
import { Button, Modal } from 'antd';
import styles from './monetaryTable.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  id: number | null;
  setVisible: (id: number | null) => void;
}

const MonetaryPaymentRollback: FC<IProps> = ({ id, setVisible }) => {
  const { t } = useTranslation();
  const paymentRollback = useMonetaryRollback();
  const handleClose = () => {
    setVisible(null);
  };

  const handleSubmit = () => {
    if (id) {
      paymentRollback.mutateAsync(id).then(() => {
        handleClose();
      });
    }
  };
  return (
    <Modal visible={!!id} onCancel={handleClose} title={false} closable={false} footer={false} wrapClassName={styles.modal} width={497}>
      <div className={styles.rollback}>
        <div className={styles.rollback_icon}>
          <CloseIcon stroke="red" size={18} />
        </div>
        <h1>{t('monetary.Tasdiqlashni_qaytarmoqchimisiz')}</h1>
        <p>{t('monetary.Tasdiqlashni_qaytarish_bilan')}</p>
        <div className={styles.rollback_footer}>
          <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
          <Button onClick={handleSubmit} loading={paymentRollback.isLoading}>
            {t('monetary.Tasdiqlashni_qaytarish')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MonetaryPaymentRollback;
