import { baseErrorHandler } from "@queries/baseErrorHandler";
import { showMessage } from "@queries/showMessage";
import { $api } from "@service/RequestService";
import { useMutation, useQueryClient } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { LicenseSMSType } from "../utils/model/LicenseSMSType";

export function usePostLicenseSMS() {
    const qc = useQueryClient();

    return useMutation({
        mutationFn: async (data: LicenseSMSType) => {
            const res = await $api.post(endPoints.POST_SMS_LICENSE, { ...data });

            return res?.data;
        },
        onSuccess: data => {
            qc.invalidateQueries({ queryKey: [queryKeys.GET_SMS_LICENSE] });
            showMessage(data?.message, data?.accept);
        },
        onError: baseErrorHandler
    });
}