import React from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
  size?: number;
  color?: string;
};
const Info: React.FC<Props> = ({ className, onClick, color, size }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6 7.3C13.6 6.60667 12.9933 6 12.3 6C11.6067 6 11 6.60667 11 7.3C11 7.99333 11.52 8.6 12.3 8.6C13.08 8.6 13.6 7.99333 13.6 7.3ZM10.8333 17H14.1667C14.6667 17 15 16.764 15 16.2921C15 15.8202 14.6667 15.5056 14.1667 15.5056H13.5C13.4167 15.5056 13.3333 15.427 13.3333 15.3483V11.573C13.3333 10.7079 12.5833 10 11.6667 10H10.8333C10.3333 10 10 10.3146 10 10.7865C10 11.2584 10.3333 11.573 10.8333 11.573H11.5C11.5833 11.573 11.6667 11.6517 11.6667 11.7303V15.2697C11.6667 15.3483 11.5833 15.427 11.5 15.427H10.8333C10.3333 15.427 10 15.7416 10 16.2135C10 16.6854 10.3333 17 10.8333 17ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
        fill={color || '#B5B5B5'}
      />
    </svg>
  );

export default Info;
