import React from 'react';

const SystemIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20.0078" cy="20.0078" r="20.0078" fill="#FEF0C7" />
      <path
        d="M20.0112 9.90234C14.4434 9.90234 9.90625 14.4395 9.90625 20.0073C9.90625 25.5751 14.4434 30.1123 20.0112 30.1123C25.579 30.1123 30.1162 25.5751 30.1162 20.0073C30.1162 14.4395 25.579 9.90234 20.0112 9.90234ZM19.2533 15.9653C19.2533 15.551 19.5969 15.2074 20.0112 15.2074C20.4255 15.2074 20.7691 15.551 20.7691 15.9653V21.0178C20.7691 21.4321 20.4255 21.7757 20.0112 21.7757C19.5969 21.7757 19.2533 21.4321 19.2533 21.0178V15.9653ZM20.9409 24.4333C20.8903 24.5646 20.8196 24.6657 20.7287 24.7667C20.6276 24.8577 20.5165 24.9284 20.3952 24.9789C20.2739 25.0295 20.1426 25.0598 20.0112 25.0598C19.8798 25.0598 19.7485 25.0295 19.6272 24.9789C19.506 24.9284 19.3948 24.8577 19.2938 24.7667C19.2028 24.6657 19.1321 24.5646 19.0815 24.4333C19.031 24.312 19.0007 24.1806 19.0007 24.0493C19.0007 23.9179 19.031 23.7866 19.0815 23.6653C19.1321 23.544 19.2028 23.4329 19.2938 23.3318C19.3948 23.2409 19.506 23.1701 19.6272 23.1196C19.8697 23.0186 20.1527 23.0186 20.3952 23.1196C20.5165 23.1701 20.6276 23.2409 20.7287 23.3318C20.8196 23.4329 20.8903 23.544 20.9409 23.6653C20.9914 23.7866 21.0217 23.9179 21.0217 24.0493C21.0217 24.1806 20.9914 24.312 20.9409 24.4333Z"
        fill="#F79009"
      />
    </svg>
  );
};

export default SystemIcon;
