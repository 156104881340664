import { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomeDraggable from '@components/custome-draggable/CustomeDraggable';
import { useWebsiteBlockStatistic } from '@pages/showroom-v2/services/queries';
import { showroomPath } from '@pages/showroom-v2/utils/constants/showroomPath';
import { rootPaths } from '@routing/rootPaths';
import { BASE_URL_VISUAL } from '@service/RequestService';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import DropDown from '../../components/drop-down/DropDown';
import ShowroomVisualNone from '../../ShowroomVisualNone';
import { showroomVisualPath } from '../../utils/constants/showroomVisualPath';

import styles from './showroomVisualHouse.module.scss';

const ShowroomVisualHouse = () => {
  const [imageSize, setImageSize] = useState({ width: 1920, height: 1080 });
  const statistic = useWebsiteBlockStatistic();
  const { data, isLoading, isIdle } = statistic;
  const [dropChildren, setDropChildren] = useState<JSX.Element>(<></>);
  const [positionDown, setPositionDown] = useState({
    top: 0,
    left: 0,
    visible: false
  });
  const { t } = useTranslation();

  const blockMouseMove = async (e: any) => {
    setPositionDown({
      left: e?.clientX + 20,
      top: e?.clientY + 20,
      visible: true
    });
  };

  const blockMouseOut = (e: any) => {
    setDropChildren(<></>);

    setPositionDown({
      left: e?.clientX + 20,
      top: e?.clientY + 20,
      visible: false
    });
  };

  const loadImage = (e: any) => {
    setImageSize({
      width: e.naturalWidth,
      height: e.naturalHeight
    });
  };

  return (
    <CustomeDraggable>
      <div className={styles.container}>
        <Spin spinning={isLoading || isIdle}>
          {' '}
          {data?.houseImagePath ? (
            <div className={styles.container_center}>
              <img src={BASE_URL_VISUAL + data?.houseImagePath} alt="" className={styles.blurImage} />
              <img
                id="visualHouseImage"
                src={BASE_URL_VISUAL + data?.houseImagePath}
                alt=""
                onLoad={e => {
                  loadImage(e.target);
                }}
              />
              <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}>
                {data?.visualViewBlockStatisticWithImages.map(item => (
                  <>
                    <Link
                      key={item.blockId}
                      to={rootPaths.SHOWROOM + showroomPath.VISUAL + showroomVisualPath.INDEX + item.blockId + window.location.search}
                      onMouseEnter={() => {
                        setDropChildren(<></>);
                        const children = (
                          <div>
                            <h1>
                              {t('home.Blok')}-{item.blockName}
                            </h1>
                            <div>
                              <div className="mb-[5px] flex items-center justify-between" style={{ borderBottom: '1px solid #EAEAEA' }}>
                                <h3>{t('house_config.Xonadonlar_soni')} </h3>
                                <h3>
                                  {item.totalFlatCount} {t('kpi.dona')}
                                </h3>
                              </div>
                              <div>
                                {item.roomsCount.map(room => (
                                  <p key={room.roomsCount}>
                                    {room.rooms} {t('home_id.xonali')}{' '}
                                    <b>
                                      {room.roomsCount} {t('kpi.dona')}
                                    </b>
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>
                        );

                        setTimeout(() => {
                          setDropChildren(children);
                        }, 0);
                      }}
                      onMouseMove={e => {
                        blockMouseMove(e);
                      }}
                      onMouseOut={blockMouseOut}
                    >
                      <path fillRule="evenodd" xmlns="http://www.w3.org/2000/svg" d={item.buildingSVGImage[0]?.backgroundImage} />
                      <polygon
                        className={styles.container_center_housePolygon}
                        xmlns="http://www.w3.org/2000/svg"
                        points={item.buildingSVGImage[0]?.frontImage}
                      />
                    </Link>
                  </>
                ))}
              </svg>
            </div>
          ) : (
            <>
              <ShowroomVisualNone />
            </>
          )}
        </Spin>
        <DropDown left={positionDown.left} top={positionDown.top} visible={positionDown.visible}>
          {dropChildren}
        </DropDown>
      </div>
    </CustomeDraggable>
  );
};

export default ShowroomVisualHouse;
