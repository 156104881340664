import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../../utils/constants/format';
import { TaskCalendarViewType } from '../../utils/models/TaskCalendarView';
import { TaskFilter } from '../../utils/models/TaskFilter';

interface CalendarState {
  type: TaskCalendarViewType;
  date: string;
  createTaskVisible: boolean;
  completeTaskVisible: boolean;
  task: TaskFilter | undefined;
}

const initialState: CalendarState = {
  type: TaskCalendarViewType.DAY,
  date: dayjs(new Date()).format(DATE_FORMAT),
  createTaskVisible: false,
  completeTaskVisible: false,
  task: undefined
};

export const taskCalendarViewSlice = createSlice({
  name: 'taskCalendarView',
  initialState,
  reducers: {
    setType: (state, action: PayloadAction<TaskCalendarViewType>) => {
      state.type = action.payload;
      state.date = dayjs(new Date()).format(DATE_FORMAT);
    },
    addMonth: state => {
      state.date = dayjs(state.date, DATE_FORMAT).add(1, 'month').startOf('month').format(DATE_FORMAT);
    },
    minusMonth: state => {
      state.date = dayjs(state.date, DATE_FORMAT).add(-1, 'month').startOf('month').format(DATE_FORMAT);
    },
    addDate: state => {
      state.date = dayjs(state.date, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT);
    },
    minusDate: state => {
      state.date = dayjs(state.date, DATE_FORMAT).add(-1, 'day').format(DATE_FORMAT);
    },
    addWeek: state => {
      state.date = dayjs(state.date, DATE_FORMAT).add(1, 'week').format(DATE_FORMAT);
    },
    minusWeek: state => {
      state.date = dayjs(state.date, DATE_FORMAT).add(-1, 'week').format(DATE_FORMAT);
    },
    createTaskVisible: (state, action: PayloadAction<boolean>) => {
      state.createTaskVisible = action.payload;
    },
    completeTaskVisible: (
      state,
      action: PayloadAction<{
        visible: boolean;
        task: TaskFilter | undefined;
      }>
    ) => {
      state.completeTaskVisible = action.payload.visible;
      state.task = action.payload.task;
    },
    setTypeAndDate: (
      state,
      action: PayloadAction<{
        type: TaskCalendarViewType;
        date: string;
      }>
    ) => {
      state.type = action.payload.type;
      state.date = action.payload.date;
    }
  }
});

export const { setType, addMonth, minusMonth, addDate, minusDate, createTaskVisible, completeTaskVisible, setTypeAndDate, addWeek, minusWeek } =
  taskCalendarViewSlice.actions;
export default taskCalendarViewSlice.reducer;
