import React from 'react';

interface Props {
  className?: string;
}

const CardRemove: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33398 5.66663H9.00065" stroke="#00A389" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 11H5.33333" stroke="#00A389" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 11H9.66667" stroke="#00A389" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.6673 8.02004V10.74C14.6673 13.08 14.074 13.6667 11.7073 13.6667H4.29398C1.92732 13.6667 1.33398 13.08 1.33398 10.74V5.26004C1.33398 2.92004 1.92732 2.33337 4.29398 2.33337H9.00065"
        stroke="#00A389"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.5391 5.45971L14.1257 2.87305" stroke="#00A389" strokeLinecap="round" />
      <path d="M14.1257 5.45971L11.5391 2.87305" stroke="#00A389" strokeLinecap="round" />
    </svg>
  );
};

export default CardRemove;
