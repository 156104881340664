import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { CreateNewLeadType } from '../utils/model/CreateNewLeadType';

export function useCreateNewLead() {
  const qc = useQueryClient();

  return useMutation(
    async (data: CreateNewLeadType) => {
      const res = await $api.post(endPoints.POST_DEFAULT_CREATE_LEAD, data);

      return res.data;
    },
    {
      onSuccess: data => {
        // @ts-expect-error
        qc.invalidateQueries([queryKeys.POST_DEFAULT_CREATE_LEAD])
        showMessage(data.message, data.accept);
      },
      onError: baseErrorHandler
    }
  );
};
