import React, { FC } from 'react';

interface IProps {
  color?: string;
  size?: number;
}

const DotIcon: FC<IProps> = ({ color = '#12B76A', size = 6 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="3" fill={color} />
    </svg>
  );
};

export default DotIcon;
