import React, { useRef, useState } from 'react';
import styles from './addCard.module.scss';
import 'swiper/css/pagination';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../utils/models/Card';
import LeftCheveronCircle from '../../../../assets/icons/LeftCheveronCircle';
import RightCheveronCircle from '../../../../assets/icons/RightCheveronCircle';
import { useChangeStyleCard } from '../../../../queries/mutation';

interface Props {
  card?: Card;
  contractId: number;
  name: string;
  handleCancel: () => void;
}

const ChangeStyle: React.FC<Props> = ({ card, contractId, name, handleCancel }) => {
  const navigationPrevRef = useRef<HTMLSpanElement>(null);
  const navigationNextRef = useRef<HTMLSpanElement>(null);
  const [styleKey, setStyleKey] = useState(0);

  const { t } = useTranslation();
  const changeStyle = useChangeStyleCard(contractId, handleCancel);

  const getName = () => {
    if (name.length > 17) return name.substring(0, 17) + '...';
    return name;
  };

  const getType = (number: string) => {
    if (number.slice(0, 4) === '9860') return styles.cont_card_humo;
    return styles.cont_card_uzcard;
  };

  const handleChangeStyle = () => {
    changeStyle.mutate({
      cardId: card?.id || 0,
      style: styleKey
    });
  };

  return (
    <div>
      <div className={styles.cont_new_slider}>
        <span className={styles.cont_new_buttons} ref={navigationPrevRef}>
          <LeftCheveronCircle />
        </span>
        <Swiper
          initialSlide={Number(card?.style)}
          spaceBetween={20}
          slidesPerView={'auto'}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current
          }}
          modules={[Navigation, Mousewheel, Pagination]}
          mousewheel={false}
          cssMode={true}
          pagination={{
            clickable: true
          }}
          onSlideChange={swiper => {
            setStyleKey(swiper.activeIndex);
          }}
          onSwiper={(swiper: any) => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
        >
          <SwiperSlide>
            <div datatype={'ddd'} className={styles.cont_new_slider_item}>
              <div className={styles.cont_card + ' ' + styles.cont_card_0}>
                <div className={getType(card?.number || '')} />
                <div className={styles.cont_card_body}>
                  <div className={styles.cont_card_body_number}>{card?.number}</div>
                  <div className={styles.cont_card_body_expired}>{card?.expire}</div>
                  <div className={styles.cont_card_body_name_cont}>
                    <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.cont_new_slider_item}>
              <div className={styles.cont_card + ' ' + styles.cont_card_1}>
                <div className={getType(card?.number || '')} />
                <div className={styles.cont_card_body}>
                  <div className={styles.cont_card_body_number}>{card?.number}</div>
                  <div className={styles.cont_card_body_expired}>{card?.expire}</div>
                  <div className={styles.cont_card_body_name_cont}>
                    <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.cont_new_slider_item}>
              <div className={styles.cont_card + ' ' + styles.cont_card_2}>
                <div className={getType(card?.number || '')} />
                <div className={styles.cont_card_body}>
                  <div className={styles.cont_card_body_number}>{card?.number}</div>
                  <div className={styles.cont_card_body_expired}>{card?.expire}</div>
                  <div className={styles.cont_card_body_name_cont}>
                    <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.cont_new_slider_item}>
              <div className={styles.cont_card + ' ' + styles.cont_card_3}>
                <div className={getType(card?.number || '')} />
                <div className={styles.cont_card_body}>
                  <div className={styles.cont_card_body_number}>{card?.number}</div>
                  <div className={styles.cont_card_body_expired}>{card?.expire}</div>
                  <div className={styles.cont_card_body_name_cont}>
                    <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.cont_card + ' ' + styles.cont_card_4}>
              <div className={getType(card?.number || '')} />
              <div className={styles.cont_card_body}>
                <div className={styles.cont_card_body_number}>{card?.number}</div>
                <div className={styles.cont_card_body_expired}>{card?.expire}</div>
                <div className={styles.cont_card_body_name_cont}>
                  <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={styles.cont_card + ' ' + styles.cont_card_5}>
              <div className={getType(card?.number || '')} />
              <div className={styles.cont_card_body}>
                <div className={styles.cont_card_body_number}>{card?.number}</div>
                <div className={styles.cont_card_body_expired}>{card?.expire}</div>
                <div className={styles.cont_card_body_name_cont}>
                  <div className={styles.cont_card_body_name_cont_name}>{getName()}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <span className={styles.cont_new_buttons} ref={navigationNextRef}>
          <RightCheveronCircle />
        </span>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
        <Button loading={changeStyle.isLoading} onClick={handleChangeStyle} type="primary">
          {t('home.Saqlash')}
        </Button>
      </div>
    </div>
  );
};

export default ChangeStyle;
