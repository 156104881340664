import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { crmConstants, crmModels } from '..';

export const useCrmField = () =>
  useQuery<crmModels.ICrmField[]>({
    queryKey: [crmConstants.queryKeys.CRM_FIELD],
    queryFn: async () => {
      const res = await $api.get('/v1/crm/field');

      return res.data.data;
    }
  });
