import React, { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { cx } from '@utils/cx';

import { useAction } from '../hooks';
import { useTermDiscountCreate } from '../hooks/use-term-discount-create';
import { ITermDiscount } from '../schema';

type Props = UseFormReturn<ITermDiscount> & { onSubmit?: (e?: any) => void };

interface IProps {
  children: (props: Props) => React.ReactNode;
  className?: string;
  loading?: boolean;
  defaultValues?: ITermDiscount;
}

const TermDiscount = ({ children, className, defaultValues }: IProps) => {
  const { closeAction, action } = useAction();
  const mutation = useTermDiscountCreate({
    onSuccess: closeAction
  });
  const form: Props = useForm<ITermDiscount>({
    // defaultValues,
    // resolver: zodResolver(termDiscount)
  });

  const onFinish = async (values: ITermDiscount) => {
    await new Promise(onSettled => {
      const [start, end] = values?.date;

      mutation.mutate(
        {
          percentage: values?.percentage,
          startDate: start,
          finishDate: end,
          name: values?.name,
          id: values?.id
        },
        { onSettled }
      );
    });
  };

  form.onSubmit = e => form.handleSubmit(onFinish)(e);

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        date: [defaultValues?.startDate, defaultValues?.finishDate]
      });
    } else {
      // console.log(defaultValues, action);
      form.reset({
        date: undefined,
        name: '',
        finishDate: undefined,
        percentage: undefined,
        startDate: undefined
      });
    }
  }, [defaultValues, action]);

  return (
    <form onSubmit={form.onSubmit} className={cx('w-full', className)}>
      {action && children(form)}
    </form>
  );
};

export default TermDiscount;
