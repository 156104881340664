import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { setRefetchTimer } from '@reducers/IOServiceSlice';
import { cx } from '@utils/cx';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';

import { File } from 'modules/common';
import { ioServiceConstants, useJobProgressFiles } from 'modules/io-service';

import NotificationCard from './NotificationCard';

import styles from './notification.module.scss';

const Notification = () => {
  const { refetchTimers } = useAppSelector(state => state.ioServiceSlice);
  const { data } = useJobProgressFiles();
  const [state,setState] = useState<typeof data>([])
  const [time, setTime] = useState(0);
  const qc = useQueryClient();
  const dispatch = useAppDispatch();
  const timeRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeRef.current = setInterval(() => {
      const realTime = dayjs().valueOf();
      const hasRefetch = Object.values(refetchTimers || {}).some(item => realTime > item);

      if (hasRefetch) {
        qc.invalidateQueries([ioServiceConstants.queryKeys.JOB_PROGRESS_FILES]);
      }

      setTime(realTime);
    }, 1000);

    return () => {
      if (timeRef.current) {
        clearInterval(timeRef.current);
      }
    };
  }, [timeRef, refetchTimers]);

  useEffect(() => {
    const timers = data?.reduce<Record<number, number>>((p, c) => {
      if (c?.size) {
        if (refetchTimers && refetchTimers[c?.id]) {
          p[c?.id] = dayjs().add(10, 'second').valueOf();
        } else {
          p[c?.id] = dayjs().add(10, 'second').valueOf();
        }
      }

      return p;
    }, {});

    dispatch(setRefetchTimer(timers));
  }, [data]);

  const handleCloseNotification = (id:number)=>{
    setState(state=>state?.filter(el=>el?.id!==id))
  };

  useEffect(()=>{
    if(data){
      setState(data)
    }
  },[data])

  const getHeight = (item?: File, i?: number) => {
    if (item?.size) {
      return 112;
    }
    return 92;
  };

  const renderNtf = () => {
    let h = 0;
    let height = 0;

    const components =
      state && state?.length > 0
        ? state?.map((item, i) => {
            if (i === 0) {
              h = 0;
            } else {
              h += getHeight(state[i - 1], i - 1);
            }

            if (state?.length > 3) {
              if (i === 0) {
                height += getHeight(item, i);
              }
            } else {
              height += getHeight(item, i);
            }

            return (
              <div
                key={item?.id}
                style={{
                  ['--height' as string]: refetchTimers && time > refetchTimers[item?.id!] ? 0 : `${getHeight(item, i)}px`,
                  ['--bottom' as string]: `${h}px`,
                  ['--height-mini' as string]: `${getHeight(state[0], 0)}px`
                }}
              >
                <NotificationCard item={item} handleCloseNotification={handleCloseNotification} />
                <div className="h-[16px]" />
              </div>
            );
          })
        : null;

    return {
      height: height + 30,
      components
    };
  };



  return data && data?.length > 0 ? (
    <div
      className={cx('absolute bottom-[90px] right-[100px] z-10 flex flex-col-reverse ', styles.container, data && data?.length > 3 && styles.miniContainer)}
      style={{ height: `min(${renderNtf().height}px, 80vh)` }}
    >
      {renderNtf().components}
    </div>
  ) : null;
};

export default Notification;
