import React from 'react';

const UzIcon = () => {
  return (
    <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3520_14699)">
        <rect x="0.5" y="0.5" width="32" height="24" fill="white" />
        <path d="M0.5 16.5H32.5V24.5H0.5V16.5Z" fill="#1EB53A" />
        <path d="M0.5 0.5H32.5V8.5H0.5V0.5Z" fill="#0099B5" />
        <path d="M0.5 8.17969H32.5V16.8197H0.5V8.17969Z" fill="#CE1126" />
        <path d="M0.5 8.66016H32.5V16.3402H0.5V8.66016Z" fill="white" />
        <path
          d="M7.22009 7.22094C8.81067 7.22094 10.1001 5.93152 10.1001 4.34094C10.1001 2.75036 8.81067 1.46094 7.22009 1.46094C5.62951 1.46094 4.34009 2.75036 4.34009 4.34094C4.34009 5.93152 5.62951 7.22094 7.22009 7.22094Z"
          fill="white"
        />
        <path
          d="M8.18005 7.22094C9.77063 7.22094 11.0601 5.93152 11.0601 4.34094C11.0601 2.75036 9.77063 1.46094 8.18005 1.46094C6.58947 1.46094 5.30005 2.75036 5.30005 4.34094C5.30005 5.93152 6.58947 7.22094 8.18005 7.22094Z"
          fill="#0099B5"
        />
        <path d="M13.555 6.06836L13.3726 6.61556L13.651 6.71156" fill="white" />
      </g>
      <rect x="0.25" y="0.25" width="32.5" height="24.5" stroke="#DADADA" strokeWidth="0.5" />
      <defs>
        <clipPath id="clip0_3520_14699">
          <rect x="0.5" y="0.5" width="32" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UzIcon;
