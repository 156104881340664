import React, { useState } from 'react';
import { Button, Modal, TreeSelect } from 'antd';
import { usePipeAll } from '@queries/queries';
import SidebarArrowRightIcon from '@icons/SidebarArrowRightIcon';
import ArrowDownIcon from '@icons/ArrowDownIcon';
import { CrmSettingsModel } from '@pages/crm-settings/utils/models/CrmSettingsModel';
import { useOneCrmSettingRole } from '@pages/crm-settings/services/mutation';

import styles from './taskModal.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  openTaskModal: boolean;
  setOpenTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  filterData: CrmSettingsModel | undefined;
};

type Pipes = {
  color: string;
  id: number;
  name: string;
  orders: number;
  pipeId: number;
  pipeName: string;
  type: string;
};

const TaskModal: React.FC<Props> = ({ openTaskModal, setOpenTaskModal, filterData }) => {
  const { t } = useTranslation();
  const createTask = useOneCrmSettingRole();
  const [pipeStatus, setPipeStatus] = useState<Pipes>();
  const { data: pipeAll } = usePipeAll();

  const newTask: any = {
    pipeStatusId: pipeStatus?.id,
    read: 'DENY',
    save: 'DENY',
    delete: 'DENY',
    pipeName: pipeStatus?.pipeName,
    pipeStatusName: pipeStatus?.name
  };
  //handleClose
  const handleClose = () => {
    setOpenTaskModal(false);
  };
  const test = () => {
    let arr = filterData?.pipeStatuses;
    arr?.push(newTask);
    return arr;
  };

  const handleCreateTask = () => {
    createTask.mutateAsync({ ...filterData!, pipeStatuses: test() }).then(handleClose);
  };

  return (
    <Modal
      visible={openTaskModal}
      onCancel={handleClose}
      title={<span>{t('crm.Yangi_bosqich_yaratish')}</span>}
      footer={
        <div className={styles.footer}>
          <Button onClick={handleClose}>{t('one_lid.Bekor_qilish')}</Button>
          <Button loading={createTask.isLoading} onClick={handleCreateTask}>
            {t('one_task.Saqlash')}
          </Button>
        </div>
      }
      className={styles.taskCreateModal}
    >
      <div className={styles.potensialClientSourrce}>
        <TreeSelect
          treeExpandAction={'click'}
          switcherIcon={<SidebarArrowRightIcon />}
          suffixIcon={<ArrowDownIcon />}
          className="customSelect"
          placeholder="Barcha varonkalar, barcha qadamlar"
          onChange={e => setPipeStatus(JSON.parse(e))}
        >
          {pipeAll?.map(item => (
            <TreeSelect.TreeNode
              title={item.name}
              value={item.id}
              key={item.id}
              selectable={false}
              children={item.pipeStatuses.map(pipe => (
                <TreeSelect.TreeNode
                  key={pipe?.id}
                  value={JSON.stringify(pipe)}
                  title={
                    <div
                      //@ts-ignore
                      pageName={item?.name}
                    >
                      <span
                        style={{
                          background: pipe.color,
                          color: '#FFFFFF',
                          padding: '2px 7px',
                          borderRadius: '5px',
                          fontSize: 10
                        }}
                      >
                        {pipe.name}
                      </span>
                    </div>
                  }
                />
              ))}
            />
          ))}
        </TreeSelect>
      </div>
    </Modal>
  );
};

export default TaskModal;
