import { FC, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import styles from './modals.module.scss';
import { useCrmEmployee } from '../../../../../../queries/queries';
import { useTranslation } from 'react-i18next';
import TaskChange from './TaskChange';

const { Option } = Select;

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
}

const ChangeEmployee: FC<IProps> = ({ visible, close, ids }) => {
  const [taskChangeVisible, setTaskChangeVisible] = useState<boolean>(false);
  const [employeeid, setEmployeeeId] = useState<number>();
  const [form] = Form.useForm();
  const { data, isLoading } = useCrmEmployee();

  const { t } = useTranslation();

  // handle success
  function handleSuccess() {
    form.resetFields();
  }

  // handle cancel modal
  const handleCancel = () => {
    close(false);
  };

  // on finish
  const onFinish = (val: { employee: number }) => {
    setEmployeeeId(val.employee);
    setTaskChangeVisible(true);
    close(false);
  };

  const onTaskChangeClose = () => {
    setTaskChangeVisible(false);
  };
  return (
    <>
      <Modal
        className={styles.container}
        width={560}
        footer={false}
        visible={visible}
        title={t('list_view.Masul_shaxsni_ozgartirish')}
        onCancel={handleCancel}
      >
        <Form onFinish={onFinish} form={form} name={'changeEmployeeListViewCrm'}>
          <Form.Item label={''} name={'employee'} rules={[{ required: true, message: '' }]}>
            <Select
              optionFilterProp={'children'}
              showSearch
              className={'customSelect'}
              placeholder={t('list_view.Masul_xodimni_tanlang')}
              loading={isLoading}
            >
              {data?.map((emp, index) => (
                <Option key={index} value={emp.id}>
                  {emp.fullName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles.container_submit}>
            <div className={styles.container_submit_left}>
              <Button htmlType={'submit'} type="primary">
                {t('home.Yuborish')}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
      {taskChangeVisible && (
        <TaskChange
          visible={taskChangeVisible}
          setVisible={setTaskChangeVisible}
          ids={ids}
          employeeId={employeeid}
          onCancel={onTaskChangeClose}
          handleSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default ChangeEmployee;
