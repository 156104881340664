import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import InternationalPhoneNumber from '@components/international-phone-input/InternationalPhoneNumber';
import { useAppDispatch } from '@hooks/reduxHooks';
import SendSquare from '@icons/SendSquare';
import TelPlusIcon from '@icons/TelPlusIcon';
import ClientLead from '@pages/changes/components/client/ClientLead';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import ConnectLeadModal from '@pages/createOrder/components/connect-lead/ConnectLeadModal';
import CreateLeadModal from '@pages/createOrder/components/create-lead/CreateLeadModal';
import { useGetForceData } from '@pages/crm-force/services/queries';
import { setClientPhone, setSearchByPhone } from '@reducers/ClientSlice';
import i18n from '@service/I18nService';
import { getCurrentLanguageWord } from '@utils/helper/getCurrentLanguageWord';
import { helper } from '@utils/helper/helper';
import { validateDateString } from '@utils/helper/validatorDateString';
import { phoneValidator } from '@utils/index';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import { BronIdOrFlatIds } from '@utils/models/BronIdOrFlatIds';
import { ClientPhone, NaturalPersonCreate } from '@utils/models/Client';
import { ClientLeadModel } from '@utils/models/ClientLeadModel';
import { Button, Checkbox, Col, Form, FormInstance, Input, Row, Select, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import { FormMainData } from './Natural';
import PassportImage from './PassportImage';

import img from '../../../assets/img/addLead.png';

import 'react-international-phone/style.css';
import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

interface Props {
  form: FormInstance<FormMainData>;
  clientPhones: ClientPhone[];
  handleCheckedPhone: (index: number) => void;
  handleRemoveClientPhone: (index: number) => void;
  handleAddClientPhone: () => void;
  clientData: NaturalPersonCreate | null;
  handlePassportImage: (e: any, type: 'first' | 'second') => void;
  uploadPassportImageLoading?: boolean;
  regions: BaseLanguageEntity[] | undefined;
  loadingRegions: boolean;
  changeRegion: (value: number) => void;
  districts: BaseLanguageEntity[] | undefined;
  districtsLoading: boolean;
  passportTwoId: number | undefined;
  passportOneId: number | undefined;
  moreInfoVisible?: boolean;
  setSelectLead?: React.Dispatch<React.SetStateAction<ClientLeadModel | undefined>>;
  currentClientLead?: ClientLeadModel | undefined;
  setCountryId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ClientMainData: React.FC<Props> = ({
  form,
  clientPhones,
  handleCheckedPhone,
  handleRemoveClientPhone,
  handleAddClientPhone,
  handlePassportImage,
  changeRegion,
  regions,
  loadingRegions,
  districtsLoading,
  districts,
  passportOneId,
  passportTwoId,
  clientData,
  moreInfoVisible,
  setSelectLead,
  currentClientLead,
  uploadPassportImageLoading,
  setCountryId
}) => {
  const { t } = useTranslation();
  const { data: force } = useGetForceData();
  const cityID = Form.useWatch('cityID', form) as number;
  const [countryCode, setCountryCode] = useState<string | undefined>('uz');

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);

  const handleOpenLeadModal = () => {
    setOpenLeadModal(true);
  };

  useEffect(() => {
    if (clientData?.countryCode) setCountryCode(clientData?.countryCode);
  }, [clientData?.countryCode]);

  const params = useParams<{ orderIdOrFlatId: string }>();

  const dispatch = useAppDispatch();

  const handleSearchClient = (phone: string, index: number) => {
    dispatch(setClientPhone({ index, phone }));
    if (!params.orderIdOrFlatId?.includes(BronIdOrFlatIds.BRON_ID)) {
      const phoneNumber = helper.deleteNotNumbers(phone, 1);

      if (index === 0 && String(phoneNumber).length >= 4) {
        dispatch(setSearchByPhone(phoneNumber));
      }
    }
  };

  const checkFieldsRequired = !(!params.orderIdOrFlatId || params?.orderIdOrFlatId?.includes('clientId'));

  const isCliendLead = !!clientData?.lead;

  const isViewCityAndDistrict = useMemo(() => countryCode === 'uz' || countryCode === 'tj', [countryCode]);

  return (
    <Form form={form} name="basic" layout="vertical" initialValues={{ remember: true }} autoComplete="off">
      <h2 className={styles.inputs_cont_title}>{t(`client_create_contract.Mijoz_malumotlari`)}</h2>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <span className="text-[#8c8c8c]">{t(`changes.client.NaturalClient.Telefon_raqam`)}</span>
          {clientPhones.map((phone, index) => (
            <Space direction="horizontal" className="d-flex" key={phone?.id}>
              <div>
                <Form.Item name={`code${index + 1}`} className="d-none" />
                <div className="flex flex-col gap-[5px]">
                  <Form.Item style={{ marginTop: '10px' }} name={`phone${index + 1}`} rules={[phoneValidator()]}>
                    <InternationalPhoneNumber
                      defaultCountry="uz"
                      className={styles.phoneInput2}
                      onChange={(e, phone) => {
                        form.setFieldsValue({ [`code${index + 1}`]: `${phone?.country?.iso2?.toUpperCase()}` });

                        handleSearchClient(e, index);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Checkbox style={{ transform: 'translateY(15px)' }} onChange={() => handleCheckedPhone(index)} checked={phone.active} />
              {index !== 0 && (
                <Button onClick={() => handleRemoveClientPhone(index)} style={{ marginLeft: 5, transform: 'translateY(15px)' }} shape="circle">
                  <CloseOutlined />
                </Button>
              )}
            </Space>
          ))}

          {clientPhones.length < 5 && (
            <p onClick={handleAddClientPhone} className={styles.conditionTelPlus}>
              <TelPlusIcon />
              {t('client_create_contract.Telefon_raqam_qoshish')}
            </p>
          )}
        </Col>
        <Col span={12}>
          <Form.Item name="firstname" rules={[{ required: true, message: '' }]} label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Ism')}</span>}>
            <Input className="customInput" />
          </Form.Item>
          <Form.Item name="lastname" rules={[{ required: true, message: '' }]} label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Familya')}</span>}>
            <Input className="customInput" />
          </Form.Item>
          <Form.Item
            name="fatherName"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Otasi_ismi')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>
        </Col>
      </Row>
      <h2 className={styles.inputs_cont_title}>{t('client_create_contract.Pasport_malumotlari')}</h2>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item name="pinfl" label={<span className={styles.inputs_cont_formLabel}>{t('client.PINFL')}</span>}>
            <Input className="customInput" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="inn" label={<span className={styles.inputs_cont_formLabel}>{t('client.INN')}</span>}>
            <Input className="customInput" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item
            name="passportDate"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    form.setFieldsValue({
                      passportDate: validateDateString(value)
                    });
                  }
                  return Promise.resolve();
                }
              }
            ]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Berilgan_sana')}</span>}
          >
            <InputMask
              mask="99.99.9999"
              className="customInput ant-input"
              placeholder={t('client_create_contract.kk_oo_yyyy')}
              // @ts-ignore*
              maskChar=""
            />
          </Form.Item>
          <Form.Item name="passportExpiredDate" label={<span className={styles.inputs_cont_formLabel}>{t('boss_crm.Amal_qilish_muddati')}</span>}>
            <InputMask
              mask="99.99.9999"
              className="customInput ant-input"
              placeholder={t('client_create_contract.kk_oo_yyyy')}
              // @ts-ignore*
              maskChar=""
            />
          </Form.Item>
          <span style={{ marginBottom: '7px', display: 'block' }} className={styles.inputs_cont_formLabel}>
            {t('client_create_contract.Pasport_fayli_muhim_emas')}
          </span>
          {!passportOneId ? (
            <Spin spinning={uploadPassportImageLoading}>
              <label htmlFor="passportFile1" className={`d-flex align-center justify-space-between ${styles.passportImageUpload}`}>
                <span className={styles.passportImageUpload_icon}>
                  <SendSquare />
                </span>
                <span className={styles.passportImageUpload_title_cont}>
                  <p className={styles.passportImageUpload_title}>{t('client_create_contract.Pasport_rasmini_yuklash')}</p>
                  <p className={styles.passportImageUpload_info}>{t('client_create_contract.JPG_PNG_yoki_PDF_fayl_hajmi_10MBdan_oshmasligi_zarur')}</p>
                </span>
                <span className={styles.passportImageUpload_button}>{t('client_create_contract.Yuklash')}</span>
              </label>
              <input accept="image/*,application/pdf" onChange={e => handlePassportImage(e, 'first')} type="file" style={{ display: 'none' }} id="passportFile1" />
            </Spin>
          ) : (
            <PassportImage type="first" id={passportOneId} />
          )}
        </Col>
        <Col span={12}>
          <Form.Item
            name="passportPlace"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client.Berilgan_joyi')}</span>}
          >
            <Input className="customInput" />
          </Form.Item>
          <div style={{ marginTop: '33px' }}>
            {passportOneId && !passportTwoId && (
              <Spin spinning={false}>
                <label htmlFor="passportFile2" className={`d-flex align-center justify-space-between ${styles.passportImageUpload}`}>
                  <span className={styles.passportImageUpload_icon}>
                    <SendSquare />
                  </span>
                  <span className={styles.passportImageUpload_title_cont}>
                    <p className={styles.passportImageUpload_title}>{t('client_create_contract.Pasport_rasmini_yuklash')}</p>
                    <p className={styles.passportImageUpload_info}>{t('client_create_contract.JPG_PNG_yoki_PDF_fayl_hajmi_10MBdan_oshmasligi_zarur')}</p>
                  </span>
                  <span className={styles.passportImageUpload_button}>{t('client_create_contract.Yuklash')}</span>
                </label>
                <input accept="image/*" onChange={e => handlePassportImage(e, 'second')} type="file" style={{ display: 'none' }} id="passportFile2" />
              </Spin>
            )}
            {passportTwoId && <PassportImage type="second" id={passportTwoId} />}
          </div>
        </Col>
      </Row>
      <h2 className={styles.inputs_cont_title}>{t('client_create_contract.Yashash_joyi_malumotlari')}</h2>
      <Row gutter={[8, 0]}>
        <Col span={8}>
          <Form.Item name="countryCode" label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>}>
            <Select
              className="customSelect"
              value={countryCode}
              onChange={(e, option) => {
                setCountryCode(e);

                setCountryId(((option as any)?.props as number) + 1);

                form.setFieldsValue({
                  cityID: undefined,
                  districtId: undefined
                });
              }}
              showSearch
              optionFilterProp="children"
            >
              {countrCode?.map(item => (
                <Select.Option key={item?.code} props={item?.index}>
                  {item[`country_${i18n.language}` as keyof typeof item]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isViewCityAndDistrict ? (
          <>
            <Col span={8}>
              <Form.Item
                name="cityID"
                rules={[{ required: checkFieldsRequired, message: '' }]}
                label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Viloyat')}</span>}
                shouldUpdate
              >
                <Select
                  className="customSelect"
                  onChange={changeRegion}
                  loading={loadingRegions}
                  optionFilterProp="children"
                  showSearch
                  placeholder={t('client_create_contract.Hududni_tanlang')}
                >
                  {regions?.map(region => (
                    <Select.Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="districtId"
                rules={[{ required: checkFieldsRequired, message: '' }]}
                label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Shahar_tuman')}</span>}
                shouldUpdate
              >
                <Select className="customSelect" loading={districtsLoading} optionFilterProp="children" showSearch placeholder={t('client_create_contract.Hududni_tanlang')}>
                  {(cityID ? districts : [])?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {getCurrentLanguageWord(district)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        ) : (
          <Col span={16} />
        )}
        <Col span={12}>
          <Form.Item
            name="address"
            rules={[{ required: checkFieldsRequired, message: '' }]}
            label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.MFY_kocha_uy')}</span>}
          >
            <Input.TextArea className="customInput" autoSize={{ minRows: 2 }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {' '}
          <Form.Item label={<span className={styles.inputs_cont_formLabel}>{t('client.Ish_joyi')}</span>}>
            <Form.Item noStyle name="workPlace">
              <Input.TextArea className="customInput" autoSize={{ minRows: 2 }} />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      {force?.status && !moreInfoVisible && (
        <>
          <div className={styles.connectLead}>
            <span className={styles.connectLead_title}>{t('one_lid.Lead_malumotlari')}</span>
            {currentClientLead ? (
              <ClientLead setSelectLead={setSelectLead!} currentClientLead={currentClientLead} isCliendLead={isCliendLead} />
            ) : (
              <Form.Item name="test" rules={[{ required: true, message: 'test' }]}>
                <div className={styles.connectLead_body}>
                  <img src={img} alt="err" />
                  <span onClick={handleOpenLeadModal}>{t('one_lid.lid_biriktirish')}</span>
                </div>
              </Form.Item>
            )}
          </div>
          <ConnectLeadModal visible={openLeadModal} setVisible={setOpenLeadModal} setVisibleCreate={setOpenCreateModal} clientId={0} setSelectLead={setSelectLead!} />
          {/* @ts-expect-error */}
          <CreateLeadModal visible={openCreateModal} setVisible={setOpenCreateModal} setSelectLead={setSelectLead!} />
        </>
      )}
    </Form>
  );
};

export default ClientMainData;
