import React, { memo } from 'react';
import styles from './filterDrawer.module.scss';
import { Checkbox, Dropdown } from 'antd';
import { useLeadTaskType } from '@queries/queries';
import { useQueryParam } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { crmIcons } from '@icons/crmIcons/crmIcons';

interface Props {
  leadTaskTypeIds: string[] | string | undefined;
}

const TaskTypeIds: React.FC<Props> = ({ leadTaskTypeIds }) => {
  const { appendMultipleArray, appendSingle, appendMultiple, remove } = useQueryParam();

  const { data } = useLeadTaskType();

  const { t } = useTranslation();

  // changePipeStatus
  const changeReasons = (value: string) => {
    if (Array.isArray(leadTaskTypeIds)) {
      leadTaskTypeIds.includes(value) ? remove('leadTaskTypeIds', value) : appendMultiple('leadTaskTypeIds', value);
    } else {
      if (leadTaskTypeIds === value) {
        remove('leadTaskTypeIds', value);
      } else {
        appendMultiple('leadTaskTypeIds', value);
      }
    }
  };

  // is has pipe Status
  const isHasReasons = (id: string) => {
    if (!leadTaskTypeIds) {
      return false;
    }
    if (Array.isArray(leadTaskTypeIds)) {
      return leadTaskTypeIds.includes(String(id));
    }
    return leadTaskTypeIds === String(id);
  };

  // handle change all pipe status click
  const handleChangeAll = (checked: boolean) => {
    if (checked) {
      const ids: string[] = ['null'];
      data?.forEach(item => ids.push(String(item.id)));
      appendMultipleArray('leadTaskTypeIds', ids);
    } else {
      appendSingle('leadTaskTypeIds', undefined);
    }
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_reason_dropdown}
      overlay={
        <>
          {data?.map((item, index) => (
            <div onClick={() => changeReasons(String(item.id))} key={index} className={styles.container_reason_dropdown_item}>
              <Checkbox checked={isHasReasons(String(item.id))} className={styles.container_reason_dropdown_item_check} />
              <span className={styles.container_reason_dropdown_item_name}>
                {crmIcons[item.icon?.split('#')[0]!]?.icon('#' + item.icon?.split('#')[1])}
                {item.deleted ? item.name : t(`one_lid.${item.name}`)}
              </span>
            </div>
          ))}
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_steps}>
        <Checkbox
          onChange={e => handleChangeAll(e.target.checked)}
          indeterminate={!!leadTaskTypeIds && (!Array.isArray(leadTaskTypeIds) || leadTaskTypeIds.length - 1 !== data?.length)}
          checked={Array.isArray(leadTaskTypeIds) && leadTaskTypeIds.length - 1 === data?.length}
          className={styles.container_steps_check}
        />
        <span className={styles.container_steps_title}>{t('notification.Barcha_topshiriqlar')}</span>
      </div>
    </Dropdown>
  );
};

export default memo(TaskTypeIds);
