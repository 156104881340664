/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import TrushSquareIcon from '../../../../../assets/icons/TrushSquareIcon';
import { ReasonsRefusal } from '../../../../../utils/models/ReasonsRefusal';
import { useReasonsForRefusalCreate } from '../../../services/mutation';
import { useReasonsForRefusal } from '../../../services/queries';

import ReasonsRefusalCard from './ReasonsRefusalCard';

import styles from './reasonsRefusal.module.scss';

const ReasonsForRefusal = () => {
  const [setting, setSetting] = useState(false);
  const [createCard, setCreateCard] = useState(false);
  const [createValue, setCreateValue] = useState('');
  const [dataCopy, setDataCopy] = useState<ReasonsRefusal[] | undefined>();
  const [changeAllText, setChangeAllText] = useState(false);
  const createReasonsForRefusal = useReasonsForRefusalCreate(onSucces);
  const { data } = useReasonsForRefusal();
  const inputRef = useRef<any>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setDataCopy(data);
  }, [data]);

  const openSetting = () => {
    setSetting(true);
  };

  const handleCreateCard = async () => {
    await setCreateCard(true);
    inputRef.current.focus();
  };

  const changeCreateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateValue(e.target.value);
    if (e.target.value === '') {
      if (changeAllText) {
        setChangeAllText(false);
      }
    } else if (!changeAllText) {
      setChangeAllText(true);
    }
  };
  const changeEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
    if (e.key === 'Escape') {
      closeCreate();
    }
  };

  const closeCreate = () => {
    setChangeAllText(false);
    setCreateValue('');
    setCreateCard(false);
  };

  console.log('dataCopy', dataCopy);

  const handleSave = () => {
    const arr = dataCopy;

    if (createValue) {
      arr?.push({
        value: createValue,
        orders: dataCopy!.length + 1
      });
    }
    createReasonsForRefusal.mutate(
      arr!?.map((item, index) => ({
        ...item,
        orders: index + 1
      }))
    );
    setCreateValue('');
  };


  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const updatedData = Array?.from(dataCopy || []);
    const [movedItem] = updatedData?.splice(source?.index, 1);

    updatedData.splice(destination?.index, 0, movedItem);

    const reorderedData = updatedData?.map((item, index) => ({
      ...item,
      orders: index + 1,
    }));

    setDataCopy(reorderedData);

    createReasonsForRefusal.mutate(reorderedData);
  };

  // const onDragEnd = (result: DropResult) => {
  //   const { source, destination } = result;

  //   if (!result.destination) {
  //     return;
  //   }
  //   const arr = dataCopy;
  //   const [removed] = arr!.splice(source.index, 1);

  //   arr!.splice(destination?.index!, 0, removed);
  //   setDataCopy(
  //     arr?.map((item, index) => ({
  //       ...item,
  //       orders: index + 1
  //     }))
  //   );
  // };

  function onSucces() {
    setChangeAllText(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <p>{t('reason_refusal.Rad_etish_sabablari')}</p> {/* <Switch checked size="small" /> */}
      </div>
      <div className={styles.container_content}>
        {t('reason_refusal.Rad_etish_sabablarini_ornating_va_savdo_samaradorligini_oshirish_uchun_zaif_tomonlarini_aniqlang')}
      </div>
      {setting && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="reasonsRefusal">
              {provided => (
                <div
                  className={styles.container_reasons}
                  onMouseMove={e => e.stopPropagation()}
                  onMouseDown={e => e.stopPropagation()}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {dataCopy &&
                    dataCopy.length > 0 &&
                    dataCopy.map((item, index) => (
                      <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                        {previded => (
                          <div
                            ref={previded.innerRef}
                            key={item.id}
                            style={{
                              ...previded.draggableProps.style
                            }}
                            {...previded.draggableProps}
                          >
                            <ReasonsRefusalCard
                              setChangeAllText={setChangeAllText}
                              changeAllText={changeAllText}
                              setDataCopy={setDataCopy}
                              dataCopy={dataCopy}
                              // @ts-expect-error
                              dragHandleProps={previded.dragHandleProps}
                              {...item}
                            />

                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                  {createCard && (
                    <div className={styles.container_reasons_card}>
                      <span />
                      <Input
                        value={createValue}
                        onChange={e => changeCreateValue(e)}
                        onKeyDown={e => changeEnter(e)}
                        className="customInput"
                        ref={inputRef}
                      />
                      <span onClick={closeCreate}>
                        <TrushSquareIcon />
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className={styles.container_footer}>
            <span className={styles.container_setting} onClick={handleCreateCard}>
              {t('reason_refusal.Sabab_qoshish')}
            </span>
            <Button
              type="primary"
              onClick={handleSave}
              loading={createReasonsForRefusal.isLoading}
              disabled={!changeAllText}
            >
              {t('reason_refusal.Saqlash')}
            </Button>
          </div>
        </>
      )}
      {!setting && (
        <span className={styles.container_setting} onClick={openSetting}>
          {t('reason_refusal.Sozlash')}
        </span>
      )}
    </div>
  );
};

export default ReasonsForRefusal;
