import React, { useState, useEffect } from 'react';
import styles from './filterCalls.module.scss';
import PlayIcon from '../../../../assets/icons/PlayIcon';
import DownloadIcon2 from '../../../../assets/icons/DownloadIcon2';
import { Dropdown, Spin } from 'antd';
import CustomPlayer from '../../../../components/customPlayer/CustomPlayer';
import { CallHistoryFilterModel } from '../../utils/models/CallHistoryFilterModel';
import { useGetPhoneRecord } from '../../services/mutate';
import { useDownloadFilesByLink } from '../../../../queries/mutation';

type Props = {
  render: CallHistoryFilterModel;
};

const FilterActions: React.FC<Props> = ({ render }) => {
  const [visible, setVisible] = useState(false);

  const [phoneUrl, setPhoneUrl] = useState('');

  const phoneRecord = useGetPhoneRecord(render?.uuid, render?.accountcode, render?.callHistoryType);

  const openDropDown = () => {
    setVisible(true);
    phoneRecord.mutateAsync().then(res => {
      setPhoneUrl(res);
    });
  };
  const download = useDownloadFilesByLink(
    // handleGetAbsolutePath(),
    phoneUrl,
    'name',
    'mp3'
  );

  // handle download
  const handleDownload = () => {
    if (phoneUrl) {
      download.mutate();
    } else {
      phoneRecord.mutateAsync().then(res => {
        download.mutate(res);
        setPhoneUrl(res);
      });
    }
  };

  return (
    <div className={styles.actions}>
      <span>
        <Dropdown
          onVisibleChange={setVisible}
          visible={visible}
          overlay={
            <div>
              <CustomPlayer name="player" type="mp3" url={phoneUrl} play={visible} isloading={phoneRecord?.isLoading} isDownload={true} />
            </div>
          }
          trigger={['click']}
          placement="top"
        >
          <span onClick={openDropDown}>
            <PlayIcon />
          </span>
        </Dropdown>
      </span>
      {render?.record ? (
        <Spin spinning={download?.isLoading}>
          <span
            onClick={() => {
              handleDownload();
            }}
          >
            <DownloadIcon2 />
          </span>
        </Spin>
      ) : (
        <span
          onClick={() => {
            handleDownload();
          }}
        >
          <DownloadIcon2 />
        </span>
      )}
    </div>
  );
};

export default FilterActions;
