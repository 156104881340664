import React, { memo } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '../../../../../hooks/useQueryParams';
import { usePipeStatusesById } from '../../../../../queries/queries';
import { CLOSED_FAIL } from '../../../../../utils/constants/PipeStatusOrder';
import { useReasonsForRefusal } from '../../../services/queries';

import styles from './filterDrawer.module.scss';

interface Props {
  reasonsFRIds: [string | null] | string | undefined;
  voronka: string | undefined;
}

const ReasonsFRFilter: React.FC<Props> = ({ reasonsFRIds, voronka }) => {
  const { t } = useTranslation();
  const { appendMultipleArray, appendSingle, appendMultiple, remove } = useQueryParam();
  const { data: pipeStatus } = usePipeStatusesById(Number(voronka));
  const { data } = useReasonsForRefusal();

  // get closed pipe status id
  const getClosedPipeStatusId = () => {
    if (pipeStatus) {
      let id = 0;

      pipeStatus.forEach(status => {
        if (status.orders === CLOSED_FAIL) {
          id = Number(status.id);
        }
      });
      return id;
    }
    return undefined;
  };

  // changePipeStatus
  const changeReasons = (value: string) => {
    if (Array.isArray(reasonsFRIds)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      reasonsFRIds.includes(value) ? remove('reasonsFRIds', value) : appendMultiple('reasonsFRIds', value);
    } else if (reasonsFRIds === value) {
      remove('reasonsFRIds', value);
      appendSingle('pipeStatusIds', undefined);
    } else {
      appendMultiple('reasonsFRIds', value);
      appendSingle('pipeStatusIds', getClosedPipeStatusId());
    }
  };

  // is has pipe Status
  const isHasReasons = (id: string) => {
    if (!reasonsFRIds) {
      return false;
    }
    if (Array.isArray(reasonsFRIds)) {
      return reasonsFRIds.includes(String(id));
    }
    return reasonsFRIds === String(id);
  };

  // handle change all pipe status click
  const handleChangeAll = (checked: boolean) => {
    if (checked) {
      const ids: string[] = ['0'];

      data?.forEach(item => ids.push(String(item.id)));

      appendMultipleArray('reasonsFRIds', ids);
      appendSingle('pipeStatusIds', getClosedPipeStatusId());
    } else {
      appendSingle('pipeStatusIds', undefined);
      appendSingle('reasonsFRIds', undefined);
    }
  };

  return (
    <Dropdown
      placement="bottom"
      overlayClassName={styles.container_reason_dropdown}
      overlay={
        <>
          <div onClick={() => changeReasons('0')} className={styles.container_reason_dropdown_item}>
            <Checkbox checked={isHasReasons('0')} className={styles.container_reason_dropdown_item_check} />
            <span className={styles.container_reason_dropdown_item_name}>{t('crm_filter.Sababsiz')}</span>
          </div>
          {data?.map((item, index) => (
            <div onClick={() => changeReasons(String(item.id))} key={index} className={styles.container_reason_dropdown_item}>
              <Checkbox checked={isHasReasons(String(item.id))} className={styles.container_reason_dropdown_item_check} />
              <span className={styles.container_reason_dropdown_item_name}>{item.value}</span>
            </div>
          ))}
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_steps}>
        <Checkbox
          onChange={e => {
            handleChangeAll(e.target.checked);
          }}
          indeterminate={!!reasonsFRIds && (!Array.isArray(reasonsFRIds) || reasonsFRIds.length - 1 !== data?.length)}
          checked={Array.isArray(reasonsFRIds) && reasonsFRIds.length - 1 === data?.length}
          className={styles.container_steps_check}
        />
        <span className={styles.container_steps_title}>{t('crm_filter.Amalga_oshmaganlik_sabablari')}</span>
      </div>
    </Dropdown>
  );
};

export default memo(ReasonsFRFilter);
