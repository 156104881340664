import { ResponsiveLine } from '@nivo/line';
import React, { useState, useEffect } from 'react';
import { CallHistoryGraphModel } from '../../utils/models/CallHistoryGraphModel';
import { useTranslation } from 'react-i18next';
import DotIcon from '../../../../assets/icons/contract/DotIcon';
import styles from '../callStatistics/callStatistics.module.scss';
import { Spin } from 'antd';
import dayjs from 'dayjs';

type Props = {
  graph: CallHistoryGraphModel | undefined;
  graphLoading: boolean;
  distance: number;
};

const LineChart: React.FC<Props> = ({ graph, graphLoading, distance }) => {
  const { t } = useTranslation();
  let newData = [
    {
      id: t('online_pbx.Chiquvchi'),
      color: '#0CAF61',
      data:
        graph?.graph?.map(item => {
          return {
            x: distance === 0 ? String(item?.hour) : item?.callDate,
            y: item?.outboundCount
          };
        }) || []
    },
    {
      id: t('online_pbx.Kiruvchi'),
      color: '#8B62FF',
      data:
        graph?.graph?.map(item => {
          return {
            x: distance === 0 ? String(item?.hour) : item?.callDate,
            y: item?.inboundCount
          };
        }) || []
    }
  ];

  return (
    <Spin spinning={graphLoading}>
      <div style={{ height: 400, width: '98%' }}>
        <ResponsiveLine
          curve="monotoneX"
          data={newData}
          margin={{ top: 50, right: 50, bottom: 50, left: 30 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear'
          }}
          // yFormat=" >-.2f"
          axisTop={null}
          axisLeft={null}
          axisBottom={{
            format: value => {
              return distance === 0 ? `${value}:00` : `${value}`;
            },
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 35,
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={3}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={3}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          colors={{ datum: 'color' }}
          enableGridX={false}
          tooltip={props => {
            return (
              <div className={styles.tooltip}>
                {newData?.map(item => (
                  <p key={item?.id}>
                    <span>
                      <DotIcon size={8} color={item?.color} /> {item?.id}
                    </span>
                    <span className={styles.tooltip_span}>
                      {item?.data?.reduce((prev, current) => (prev += current?.x === props.point.data.x ? current?.y : 0), 0)}
                    </span>
                  </p>
                ))}
              </div>
            );
          }}
        />
      </div>
    </Spin>
  );
};

export default LineChart;
