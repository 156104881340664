import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import MultiPaymentSelect from '@pages/homeId/components/multi-payment-select/MultiPaymentSelect';
import PriceInformation from '@pages/homeId/components/priceInformation/PriceInformation';
import { Permissions } from '@utils/constants/permissions';
import { Layout, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import AfterContractCreateModal from '../../components/createContract/afterCreateContract/AfterContractCreateModal';
import Client from '../../components/createContract/client/Client';
import ContractProps from '../../components/createContract/contractProps/ContractProps';
import DiscountProps from '../../components/createContract/discountProps/DiscountProps';
import FooterButtons from '../../components/createContract/footerButtons/FooterButtons';
import PaymentProps from '../../components/createContract/paymentProps/PaymentProps';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import MainHeader from '../../components/headers/MainHeader';
import { useFlatsByOrderIdOrFlatIds } from '../../queries/queries';
import Flats from '../homeId/components/flats/Flats';
import SelectCurrency from '../homeId/components/selectCurrency/SelectCurrency';

import styles from './createContract.module.scss';

const { Content } = Layout;

export interface ContractReqProps {
  required: boolean;
  value: string;
  id: number;
  border: string;
}

const CreateContract = () => {
  const { t } = useTranslation();
  const params = useParams<{ orderIdOrFlatId: string }>();
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);
  const { isLoading } = useFlatsByOrderIdOrFlatIds(params.orderIdOrFlatId || '');
  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const [contractFields, setContractFields] = useState<ContractReqProps[]>([]);

  const removeDuplicates = (arr: ContractReqProps[]) => {
    const uniqueMap = new Map<number, ContractReqProps>();

    arr.forEach(item => {
      uniqueMap.set(item.id, item);
    });

    return Array.from(uniqueMap.values());
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <Spin spinning={isLoading}>
          <div className={styles.container}>
            <h2 className={styles.title}>
              <span>{t('create_table_payments.Tanlangan_xonadonlar')}</span>
              <SelectCurrency />
            </h2>
            <Flats />
            <h2 className={styles.title}>{t('create_table_payments.Mijoz_malumotlari')}</h2>
            <Client />
            <h2 className={styles.title}>{t('create_table_payments.Shartnoma_malumotlari')}</h2>
            <ContractProps contractFields={removeDuplicates(contractFields)} setContractFields={setContractFields} />
            {isMultiPayment ? (
              <>
                <MultiPaymentSelect />
                <PriceInformation />
              </>
            ) : (
              <>
                <h2 className={styles.title}>{t('create_table_payments.Tolov_malumotlari')}</h2>
                <PaymentProps />
                <h2 className={styles.title}>{t('create_table_payments.Chegirma')}</h2>
              </>
            )}
            <DiscountProps />
            <FooterButtons contractFields={removeDuplicates(contractFields)} setContractFields={setContractFields} />
          </div>
        </Spin>
        <AfterContractCreateModal />
      </Content>
    </>
  );
};

export default CreateContract;
