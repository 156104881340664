import React from 'react';
import { Dropdown } from 'antd';

import styles from '../triggers.module.scss';
import CheveronBottom from '../../../../../../../assets/icons/CheveronBottom';
import TimeDropdown from './TimeDropdown';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { ExecStatus } from '../../../../../../../utils/models/Trigger';
import { setExecStatus } from '../../../../../../../store/reducers/TriggerSlice';
import CheckmarkIcon from '../../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { useTranslation } from 'react-i18next';

const DoneBy = () => {
  const status = useAppSelector(state => state.triggerSlice.execStatus);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // change status
  const changeStatus = (newStatus: ExecStatus) => {
    if (newStatus !== status) dispatch(setExecStatus(newStatus));
  };

  // title status
  const title = () => {
    switch (status) {
      case ExecStatus.WHEN_MOVED_THIS_STAGE:
        return t('triggers.WHEN_MOVED_THIS_STAGE');
      case ExecStatus.WHEN_RESPONSIBLE_USER_CHANGE:
        return t('triggers.WHEN_RESPONSIBLE_USER_CHANGE');
      case ExecStatus.WHEN_CREATE_THIS_STAGE:
        return t('triggers.WHEN_CREATE_THIS_STAGE');
      case ExecStatus.WHEN_CREATE_OR_MOVED_THIS_STAGE:
        return t('triggers.WHEN_CREATE_OR_MOVED_THIS_STAGE');
      default:
        return t('triggers.Tanlang');
    }
  };

  return (
    <Dropdown
      placement={'bottom'}
      overlayClassName={styles.container_body_item_dropdown}
      overlay={
        <>
          <div className={styles.container_body_item_dropdown_title}>{t('triggers.Varonka_triggerlari')}</div>
          <div onClick={() => changeStatus(ExecStatus.WHEN_CREATE_THIS_STAGE)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.WHEN_CREATE_THIS_STAGE')}</span>
            {status === ExecStatus.WHEN_CREATE_THIS_STAGE && <TimeDropdown />}
          </div>
          <div onClick={() => changeStatus(ExecStatus.WHEN_MOVED_THIS_STAGE)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.WHEN_MOVED_THIS_STAGE')}</span>
            {status === ExecStatus.WHEN_MOVED_THIS_STAGE && <TimeDropdown />}
          </div>
          <div onClick={() => changeStatus(ExecStatus.WHEN_CREATE_OR_MOVED_THIS_STAGE)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.WHEN_CREATE_OR_MOVED_THIS_STAGE')}</span>
            {status === ExecStatus.WHEN_CREATE_OR_MOVED_THIS_STAGE && <TimeDropdown />}
          </div>
          <div onClick={() => changeStatus(ExecStatus.WHEN_RESPONSIBLE_USER_CHANGE)} className={styles.container_body_item_dropdown_item}>
            <span className={styles.container_body_item_dropdown_item_name}>{t('triggers.WHEN_RESPONSIBLE_USER_CHANGE')}</span>
            {status === ExecStatus.WHEN_RESPONSIBLE_USER_CHANGE && <CheckmarkIcon className={styles.container_body_item_dropdown_item_icon} />}
          </div>
        </>
      }
      trigger={['click']}
    >
      <div className={styles.container_body_item}>
        <div className={styles.container_body_item_title}>
          {t('triggers.Bajariladi')}: {title()}
        </div>
        <CheveronBottom className={styles.container_body_item_select} />
      </div>
    </Dropdown>
  );
};

export default DoneBy;
