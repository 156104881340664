import React from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { Drawer } from 'antd';
import { useTranslation } from 'react-i18next';

import Header from '../../header/Header';
import RefreshIcon from '../assets/icons/RefreshIcon';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const FilterDrawer: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { removeAllSearchParams } = useQueryParam();

  const handleClear = () => {
    removeAllSearchParams();
    onClose();
  };

  return (
    <Drawer visible={open} onClose={onClose} placement="left" closable={false}>
      <div className="flex flex-col gap-[14px] px-[6px] py-[2px]">
        <div className="flex items-center justify-between">
          <span
            className="text-[16px] font-medium leading-[19.09px]"
            style={{
              color: 'rgba(70, 66, 85, 1)'
            }}
          >
            {t('crm_filter.Filtr')}
          </span>
          <span className="flex cursor-pointer items-center gap-[7px] text-[12px] font-medium leading-[16px]" style={{ color: 'rgba(255, 63, 64, 1)' }} onClick={handleClear}>
            <RefreshIcon color="red" />
            {t('home.Tozalash')}
          </span>
        </div>
        <Header />
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
