import React from 'react';
import PersonalCardIcon from './PersonalCardIcon';
import ArchiveTickIcon from './ArchiveTickIcon';
import BookSquareIcon from './BookSquareIcon';
import BriefCaseIcon from './BriefCaseIcon';
import CallCallingIcon from './CallCallingIcon';
import DollarCircleIcon from './DollarCircleIcon';
import TrushIcon from './TrushIcon';
import PrinterIcon from './PrinterIcon';
import ReceiptItemIcon from './ReceiptItemIcon';
import LikeIcon from './LikeIcon';
import DislikeIcon from './DislikeIcon';
import MicrophoneIcon from './MicrophoneIcon';

export const crmIcons: {
  [key: string]: {
    icon: (color: string) => JSX.Element;
  };
} = {
  briefcase: {
    icon: (color: string) => <BriefCaseIcon color={color} />
  },
  dollar: {
    icon: (color: string) => <DollarCircleIcon color={color} />
  },
  calling: {
    icon: (color: string) => <CallCallingIcon color={color} />
  },
  archive_tick: {
    icon: (color: string) => <ArchiveTickIcon color={color} />
  },
  book_square: {
    icon: (color: string) => <BookSquareIcon color={color} />
  },
  personal_card: {
    icon: (color: string) => <PersonalCardIcon color={color} />
  },
  trush: {
    icon: (color: string) => <TrushIcon color={color} />
  },
  printer: {
    icon: (color: string) => <PrinterIcon color={color} />
  },
  receipt_item: {
    icon: (color: string) => <ReceiptItemIcon color={color} />
  },
  like: {
    icon: (color: string) => <LikeIcon color={color} />
  },
  dislike: {
    icon: (color: string) => <DislikeIcon color={color} />
  },
  microphone: {
    icon: (color: string) => <MicrophoneIcon color={color} />
  }
};
