import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Tabs } from 'antd';
import { t } from 'i18next';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/reduxHooks';
import { BASE_URL } from '../../../../../../service/RequestService';
import { clearForm, setOpenShablon } from '../../../../../../store/reducers/TextEditorSlice';
import DragAndDrop from '../dragAndDrop/DragAndDrop';
import FormaSettings from '../formaSettings/FormaSettings';
import { useWebForm } from '../service/mutation';
import { useGetOneForm } from '../service/queries';
import { FormaModels } from '../utils/models/FormaModels';

import styles from '../dragDrop.module.scss';

const { TabPane } = Tabs;

const Shablon1 = () => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState('1');
  const [viewForm, setViewForm] = useState(false);
  const { main, fieldsStyle, textPosition, fields, submitButton, leftHeader, leftBgImgBinary, rightBgImgBinary, pageBackImg } = useAppSelector(
    state => state.textEditorReducer
  );
  const { data } = useGetOneForm(main.formId);
  const createForm = useWebForm(main.formId);
  const dispatch = useAppDispatch();

  const handleViewForm = () => {
    setViewForm(true);
  };

  // handleClose
  const handleClose = () => {
    dispatch(setOpenShablon(false));
    setActiveTab('1');
    dispatch(clearForm());
    form.resetFields();
  };

  // handleChangeTab
  const handleChangeTab = (activeTab: string) => {
    setActiveTab(activeTab);
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0]?.name[0] as string;
    const tabs = ['1', 'name'];

    if (tabs.includes(name)) {
      setActiveTab(name);
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data, form]);
  const sortFields = [...fields['3']?.items]?.sort((a, b) => a.step - b.step);

  const onFinish = () => {
    const sendData2: FormaModels = {
      id: main?.formId!,
      autofill: true,
      elements: sortFields?.map((item, index) => ({
        companyLeadFieldId: item?.id,
        label: item?.label,
        placeHolder: item?.placeHolder || '',
        required: item?.required || false,
        requiredMessage: item?.requiredMessage || '',
        step: index,
        type: item?.type === 'Phone' ? 'PHONE' : null
      })),
      link: '',
      name: main?.name,
      pipeStatusId: main?.pipeStatusId,
      redirectTime: main?.redirectTime,
      redirectUrl: main?.redirectUrl,
      style: {
        borderColor: main?.borderColor,
        borderRadius: main?.borderRadius,
        boxShadow: main?.boxShadow,
        fieldBackgroundColor: fieldsStyle?.fieldBackgroundColor,
        fieldBorderColor: fieldsStyle?.fieldBorderColor,
        fieldBorderType: fieldsStyle?.fieldBorderType,
        fieldHeight: fieldsStyle?.height,
        fontColor: fieldsStyle?.color,
        fontFamily: main?.fontFamily,
        height: main?.height,
        labelPosition: textPosition?.labelPosition === 'block' ? 'TOP' : 'LEFT',
        leftBackgroundColor: main?.leftBackgroundColor,
        leftBackgroundImage: leftBgImgBinary?.absolutePath || main?.leftBackgroundImage,
        leftWidth: main?.leftWidth,
        rightBackgroundColor: main?.rightBackgroundColor,
        rightBackgroundImage: rightBgImgBinary?.absolutePath || main?.rightBackgroundImage,
        rightWidth: main?.fullWidth - main?.leftWidth,
        fontSize: fieldsStyle?.fieldSize || 14,
        pageBackgroundColor: main?.pageBackgroundColor,
        pageBackgroundImage: pageBackImg?.absolutePath || main?.pageBackgroundImage
      },
      tagIds: main?.tagsId?.map(item => +item),
      textSubmission: main?.textSubmission,
      staticElementFormDto: [
        {
          alignment: leftHeader?.header?.textAlign?.toUpperCase(),
          fontStyle: [
            leftHeader?.header?.fontWeight?.toUpperCase(),
            leftHeader?.header?.fontStyle?.toUpperCase(),
            leftHeader?.header?.textDecoration?.toUpperCase()
          ].filter(item => item),
          text: leftHeader?.header?.content,
          textColor: leftHeader?.header?.color,
          textSize: leftHeader?.header?.fontSize,
          type: 'HEADER'
        },
        {
          alignment: leftHeader?.paragraph?.textAlign?.toUpperCase(),
          fontStyle: [
            leftHeader?.paragraph?.fontWeight?.toUpperCase(),
            leftHeader?.paragraph?.fontStyle?.toUpperCase(),
            leftHeader?.paragraph?.textDecoration?.toUpperCase()
          ].filter(item => item),
          text: leftHeader?.paragraph?.content,
          textColor: leftHeader?.paragraph?.color,
          textSize: leftHeader?.paragraph?.fontSize,
          type: 'PARAGRAPH'
        },
        {
          alignment: submitButton?.justifyContent?.toUpperCase(),
          fontStyle: [
            submitButton?.fontWeight?.toUpperCase(),
            submitButton?.fontStyle?.toUpperCase(),
            submitButton?.textDecoration?.toUpperCase()
          ].filter(item => item),
          text: submitButton?.value,
          textColor: submitButton?.color,
          type: 'BUTTON',
          buttonBorderType: submitButton?.borderRadius,
          buttonColor: submitButton?.backgroundColor,
          buttonWidth: submitButton?.width
        }
      ]
    };

    createForm.mutateAsync(sendData2).then(handleViewForm);
  };

  return (
    <Modal
      visible={main?.openShablon}
      onCancel={handleClose}
      width={1505}
      title={<div>{main.name}</div>}
      okText={t('one_lid.Saqlash')}
      cancelText={t('one_lid.Bekor_qilish')}
      okButtonProps={{ loading: createForm?.isLoading }}
      className={styles.globalModal}
    >
      <div>
        <img src={BASE_URL + pageBackImg} alt="" />
      </div>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <TabPane key="1" tab={<span>{t('contract_custom_field.Tahrirlash')}</span>} forceRender={true}>
            <DragAndDrop />
          </TabPane>
          <TabPane key="name" tab={<span>{t('crm_ins.Sozlamalari')}</span>} forceRender={true}>
            <FormaSettings viewForm={viewForm} />
          </TabPane>
        </Tabs>
        <div className={styles.footer}>
          <Button htmlType="submit">{t('one_lid.Saqlash')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Shablon1;
