import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from '@service/LocalStorage';

interface IQrCodeModeSlice {
  active: boolean;
}

const initialState: IQrCodeModeSlice = {
  active: Boolean(LocalStorage.get('qrCodeMode')) || false
};

export const qrCodeModeSlice = createSlice({
  name: 'qrCodeModeSlice',
  initialState,
  reducers: {
    setQrCodeModeActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
      LocalStorage.set('qrCodeMode', String(action.payload));
    }
  }
});

export const { setQrCodeModeActive } = qrCodeModeSlice.actions;
export default qrCodeModeSlice.reducer;
