import React from 'react';

const AnswerIcon = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8637 10.0445V10.0418C11.8637 10.0418 9.59009 7.77498 9.58869 7.77388C9.40843 7.59385 9.12014 7.59775 8.94279 7.77508L7.89626 8.82401C7.4894 9.23022 6.96478 8.86976 5.95777 8.05013C5.29151 7.50784 4.52494 6.77862 3.86029 5.95648C3.39385 5.37965 2.98137 4.77388 3.40749 4.34864C3.40859 4.34754 4.45834 3.30242 4.45814 3.30132L4.45894 3.29912C4.64732 3.11108 4.62906 2.82301 4.45703 2.6511V2.64849C4.45703 2.64849 2.1177 0.316343 2.1164 0.314941C1.93614 0.135014 1.64795 0.139019 1.47051 0.316143L0.416248 1.36847C-0.0180943 1.91106 -0.983377 4.49703 3.15491 8.73119C7.51438 13.1919 10.3275 12.2377 10.8118 11.7344C10.8118 11.7344 11.8649 10.6956 11.8649 10.6946L11.8658 10.6925C12.0542 10.5043 12.0356 10.2165 11.8637 10.0445Z"
        fill="#28C76F"
      />
    </svg>
  );
};

export default AnswerIcon;
