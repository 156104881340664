import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { clientConstants } from '..';

export const useClientExterminate = (props?: { onSuccess: () => void }) => {
  const qc = useQueryClient();

  return useMutation(
    async (id: number) => {
      const res = await $api.delete(`/v1/client/exterminate/${id}`);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([clientConstants.queryKeys.CLIENT]);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props?.onSuccess && props?.onSuccess();
      }
    }
  );
};
