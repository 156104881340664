import React from 'react';

const ArrowDownBottom = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6" fill="none">
      <path d="M0.909118 0.5L5.90912 5.5L10.9091 0.5L0.909118 0.5Z" fill="#222222" />
    </svg>
  );
};

export default ArrowDownBottom;
