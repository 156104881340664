import React from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import CheckboxWhiteIcon from '@icons/CheckboxWhiteIcon';
import PlusCircleIcon from '@icons/PlusCircleIcon';
import { useCreateWidget } from '@pages/dashboard/services/mutations';
import { queryKeys } from '@pages/dashboard/utils/constants/queryKeys';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { Permissions } from '@utils/constants/permissions';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import styles from './defaultWidget.module.scss';

type Props = {
  item: any;
  handleClose: () => void;
  data: WidgetModel[] | undefined;
  dashPermission: boolean;
};

const DefaultWidgetItem: React.FC<Props> = ({ item, handleClose, data, dashPermission }) => {
  const { t } = useTranslation();
  const createWidget = useCreateWidget();
  const qc = useQueryClient();
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isAccessEditDashboard = permissions?.includes(Permissions.PERMISSION_EDIT_MAIN_DASHBOARD);

  const onFinish = () => {
    const newData = item?.newData;

    createWidget.mutateAsync(newData as any).then(() => {
      handleClose();
      qc.invalidateQueries({ queryKey: [queryKeys.WIDGETS] });
    });
  };
  const isHasWidget = data?.some(prev => prev?.name === item?.newData?.name);

  return (
    <div className={styles.container}>
      <div className={styles.container_top}>
        {item?.icon}
        {isHasWidget ? (
          <div className={styles.container_top_btn1}>
            <CheckboxWhiteIcon />
            <span>{t('emp_dashboard.Qoshildi')}</span>
          </div>
        ) : (
          <Button loading={createWidget.isLoading} className={styles.container_top_btn} onClick={onFinish} disabled={dashPermission}>
            <PlusCircleIcon stroke="#475467" />
            <span>{t('emp_dashboard.Dashboardga_qoshish')}</span>
          </Button>
        )}
      </div>
      <h3>{item?.title}</h3>
      <h5>{item?.description}</h5>
    </div>
  );
};

export default DefaultWidgetItem;
