import i18n from '@service/I18nService';
import { textCapitalize } from '@utils/helper/textConverter';

import { useActivityLogs } from 'modules/event-list';

import Context from './context';

interface IProps {
  children: React.ReactNode;
}

const Provider = ({ children }: IProps) => {
  const { data: activityLogs } = useActivityLogs();

  const objectTypes = [...(activityLogs?.actionTypeClient || []), ...(activityLogs?.actionType || [])]?.reduce<Record<string, string>>((p, c) => {
    p[c?.name] = c[`name${textCapitalize(i18n.language)}` as keyof typeof c]?.toString().replace(/%s/gi, '') || '';

    return p;
  }, {});

  return <Context.Provider value={{ objectTypes }}>{children}</Context.Provider>;
};

export default Provider;
