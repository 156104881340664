import React, { useState } from 'react';
import { useCrmSettingsFilter, useGetAllRole } from '@pages/crm-settings/services/queries';
import { Select, Table } from 'antd';
import { CrmSettingsFilterModel } from '@pages/crm-settings/utils/models/CrmSettingsFilter';
import { useUpdateCrmEmployeeRole } from '@pages/crm-settings/services/mutation';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsHome } from '@pages/home/Home';
import ArrowLeftIcon from '@icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '@icons/contract/ArrowRightIcon';
import CLoseAllIcon from '@icons/CLoseAllIcon';

import styles from './crmSettingsTable.module.scss';
import DeleteRoleModal from '../role-modal/DeleteRoleModal';

type Props = {
  openDeleteRole: boolean;
  setOpenDeleteRole: React.Dispatch<React.SetStateAction<boolean>>;
  roleId: number | undefined;
  setRoleId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const { Option } = Select;

const CrmSettingsTable: React.FC<Props> = ({ openDeleteRole, setOpenDeleteRole, roleId, setRoleId }) => {
  const { appendMultipleDifferent, searchParams } = useQueryParam<SearchParamsHome, any>();
  const { t } = useTranslation();
  const updateEmployeeRole = useUpdateCrmEmployeeRole();
  const [activeId, setActiveId] = useState(0);
  const { data } = useCrmSettingsFilter(searchParams);
  const { data: roles } = useGetAllRole();

  // changePage
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(['page', 'size'], [page, size]);
  };

  //handle open delete role modal
  const handleOpenRoleModal = () => {
    setOpenDeleteRole(true);
  };

  const columns = [
    {
      title: t('home.Xodim'),
      dataIndex: 'employeeFullName',
      key: 'xodim'
    },
    {
      title: t('home.Foydalanuvchi_nomi'),
      dataIndex: 'username',
      key: 'name'
    },
    {
      title: t('home.Telefon'),
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: t('home.Crm_tizimidagi_roli'),
      key: 'role',
      render: (render: CrmSettingsFilterModel) => (
        <span>
          <Select
            onChange={e => {
              updateEmployeeRole.mutate({
                crmRoleId: e === null ? null : Number(e),
                employeeId: render.employeeId
              });
              setActiveId(Number(render?.employeeId));
            }}
            loading={render?.employeeId === activeId && updateEmployeeRole.isLoading}
            value={render?.crmRoleName === null ? '-' : render?.crmRoleName}
          >
            {roles?.map(item => (
              <Option value={item?.id}>
                <div className={styles.tableOption}>
                  <span>{item?.name}</span>
                  <span className={styles.closeIcon}>
                    <CLoseAllIcon
                      onClick={e => {
                        setRoleId(item?.id);
                        e.stopPropagation();
                        handleOpenRoleModal();
                      }}
                    />
                  </span>
                </div>
              </Option>
            ))}
            <Option value={null}>General</Option>
          </Select>
        </span>
      )
    }
  ];

  return (
    <>
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={{
          current: data?.currentPage ? data?.currentPage : 1,
          total: data?.totalElements,
          pageSize: 10,
          onChange: (page, size) => changePage(page, 10),
          hideOnSinglePage: true,
          className: 'tableNewStyle_pagination',
          prevIcon: (
            <div className={'tableNewStyle_pagination_prev'}>
              <ArrowLeftIcon /> {t('my_calls.Ortga')}
            </div>
          ),
          nextIcon: (
            <div className={'tableNewStyle_pagination_next'}>
              {t('crm_ins.Keyingisi')} <ArrowRightIcon />
            </div>
          )
        }}
        className={styles.crmSettingsTable}
      />
      <DeleteRoleModal openDeleteRole={openDeleteRole} setOpenDeleteRole={setOpenDeleteRole} roleId={roleId} />
    </>
  );
};

export default CrmSettingsTable;
