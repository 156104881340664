import React, { useEffect, useRef, useState } from 'react';

import styles from './sendCode.module.scss';
import { Button, Input, InputRef } from 'antd';
import { helper } from '../../../utils/helper/helper';
import ArrowLeftIcon from '../../../assets/icons/contract/ArrowLeftIcon';
import { useSendCodeChangePassword, useVerifyCodeChangePassword } from '../../../queries/mutation';
import { useTranslation } from 'react-i18next';

interface Props {
  changePage: (page: number) => void;
  user: { phone: string; username: string };
}

const SendCode: React.FC<Props> = ({ changePage, user }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string[]>([]);
  const [resentCode, setResentCode] = useState(false);
  const [haveError, setHaveError] = useState(false);

  const firstInput = useRef<InputRef>(null);
  const secondInput = useRef<InputRef>(null);
  const thirdInput = useRef<InputRef>(null);
  const fourthInput = useRef<InputRef>(null);
  const fifthInput = useRef<InputRef>(null);
  const sixthInput = useRef<InputRef>(null);

  const sendCode = useSendCodeChangePassword(handleSuccess);
  const verifyCode = useVerifyCodeChangePassword(handleSuccessVerify);

  useEffect(() => {
    setTimeout(() => {
      setResentCode(true);
    }, 60000);
  }, [user]);

  // handle success
  function handleSuccess() {
    setTimeout(() => {
      setResentCode(true);
    }, 60000);
  }

  // handle success verify
  function handleSuccessVerify() {
    changePage(3);
  }

  // handle change first input
  const handleChangeFirstInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value.length === 6) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        newCode.push(e.target.value[i]);
      }
      setCode(newCode);
      return;
    }
    const newCode: string[] = [];
    for (let i = 0; i < 6; i++) {
      if (i === 0) {
        if (e.target.value[0]) {
          newCode[i] = e.target.value[i];
          secondInput.current!.focus({
            cursor: 'all'
          });
        } else {
          newCode[i] = '';
        }
      } else {
        newCode[i] = code[i] || '';
      }
    }
    setCode(newCode);
  };

  // handleChangeSecondInput
  const handleChangeSecondInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value.length === 6) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        newCode.push(e.target.value[i]);
      }
      setCode(newCode);
      return;
    }

    const letter = helper.deleteSpace(e.target.value)[0];

    const newCode: string[] = [];
    for (let i = 0; i < 6; i++) {
      if (i === 1) {
        if (letter) {
          newCode[i] = letter;
          thirdInput.current!.focus({
            cursor: 'all'
          });
        } else {
          newCode[i] = '';
        }
      } else {
        newCode[i] = code[i] || '';
      }
    }
    setCode(newCode);
  };

  // handlePressBackSecondInput
  const handlePressBackSecondInput: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Backspace' && !code[1]) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        if (i === 0) {
          newCode[i] = '';
        } else {
          newCode[i] = code[i];
        }
      }
      firstInput.current!.focus({
        cursor: 'all'
      });
      setCode(newCode);
    }
  };

  // handleChangeThirdInput
  const handleChangeThirdInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value.length === 6) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        newCode.push(e.target.value[i]);
      }
      setCode(newCode);
      return;
    }

    const letter = helper.deleteSpace(e.target.value)[0];

    const newCode: string[] = [];
    for (let i = 0; i < 6; i++) {
      if (i === 2) {
        if (letter) {
          newCode[i] = letter;
          fourthInput.current!.focus({
            cursor: 'all'
          });
        } else {
          newCode[i] = '';
        }
      } else {
        newCode[i] = code[i] || '';
      }
    }
    setCode(newCode);
  };

  // handlePressBackSecondInput
  const handlePressBackThirdInput: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Backspace' && !code[2]) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        if (i === 1) {
          newCode[i] = '';
        } else {
          newCode[i] = code[i];
        }
      }
      secondInput.current!.focus({
        cursor: 'all'
      });
      setCode(newCode);
    }
  };

  // handleChangeFourthInput
  const handleChangeFourthInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value.length === 6) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        newCode.push(e.target.value[i]);
      }
      setCode(newCode);
      return;
    }

    const letter = helper.deleteSpace(e.target.value)[0];

    const newCode: string[] = [];
    for (let i = 0; i < 6; i++) {
      if (i === 3) {
        if (letter) {
          newCode[i] = letter;
          fifthInput.current!.focus({
            cursor: 'all'
          });
        } else {
          newCode[i] = '';
        }
      } else {
        newCode[i] = code[i] || '';
      }
    }
    setCode(newCode);
  };

  // handlePressBackSecondInput
  const handlePressBackFourthInput: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Backspace' && !code[3]) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        if (i === 2) {
          newCode[i] = '';
        } else {
          newCode[i] = code[i];
        }
      }
      thirdInput.current!.focus({
        cursor: 'all'
      });
      setCode(newCode);
    }
  };

  // handleChangeFifthInput
  const handleChangeFifthInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value.length === 6) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        newCode.push(e.target.value[i]);
      }
      setCode(newCode);
      return;
    }

    const letter = helper.deleteSpace(e.target.value)[0];

    const newCode: string[] = [];
    for (let i = 0; i < 6; i++) {
      if (i === 4) {
        if (letter) {
          newCode[i] = letter;
          sixthInput.current!.focus({
            cursor: 'all'
          });
        } else {
          newCode[i] = '';
        }
      } else {
        newCode[i] = code[i] || '';
      }
    }
    setCode(newCode);
  };

  // handlePressBackFifthInput
  const handlePressBackFifthInput: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Backspace' && !code[4]) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        if (i === 3) {
          newCode[i] = '';
        } else {
          newCode[i] = code[i];
        }
      }
      fourthInput.current!.focus({
        cursor: 'all'
      });
      setCode(newCode);
    }
  };

  // handleChangeSixthInput
  const handleChangeSixthInput: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.value.length === 6) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        newCode.push(e.target.value[i]);
      }
      setCode(newCode);
      return;
    }

    const letter = helper.deleteSpace(e.target.value)[0];

    const newCode: string[] = [];
    for (let i = 0; i < 6; i++) {
      if (i === 5) {
        if (letter) {
          newCode[i] = letter;
        } else {
          newCode[i] = '';
        }
      } else {
        newCode[i] = code[i] || '';
      }
    }
    setCode(newCode);
  };

  // handlePressBackFifthInput
  const handlePressBackSixthInput: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Backspace' && !code[5]) {
      let newCode = [];
      for (let i = 0; i < 6; i++) {
        if (i === 4) {
          newCode[i] = '';
        } else {
          newCode[i] = code[i];
        }
      }
      fifthInput.current!.focus({
        cursor: 'all'
      });
      setCode(newCode);
    }
  };

  // handle submit
  const handleSubmit = () => {
    if (helper.deleteSpace(code.join('')).length === 6) {
      verifyCode.mutate({
        verifyCode: code.join(''),
        username: user.username
      });
    } else {
      setHaveError(true);
    }
  };

  // handle click back
  const handleClickBack = () => {
    changePage(1);
  };

  // handle resend code
  const handleResendCode = () => {
    sendCode.mutate(user.username);
    setResentCode(false);
  };

  // get phone
  const getPhone = () => {
    if (user.phone) {
      return user.phone.slice(0, 4) + ' ' + user.phone.slice(4, 6) + ' ••• •• ' + user.phone.slice(11, 13);
    }
    return '';
  };

  return (
    <div className={styles.container}>
      <h2>
        <ArrowLeftIcon onClick={handleClickBack} />
        {t('profile.Hisobni_qayta_tiklash')}
      </h2>
      <p>
        {t('profile.Biz')} {getPhone()} {t('profile.raqamiga_olti_xonali_tasdiqlash')}.
      </p>
      <div className={styles.container_title}>Tasdiqlash kodi</div>
      <div className={styles.container_input}>
        <Input
          status={haveError || verifyCode.isError ? 'error' : undefined}
          ref={firstInput}
          onChange={handleChangeFirstInput}
          value={code[0] || ''}
        />
        <Input
          status={haveError || verifyCode.isError ? 'error' : undefined}
          onChange={handleChangeSecondInput}
          onKeyDown={handlePressBackSecondInput}
          ref={secondInput}
          value={code[1] || ''}
        />
        <Input
          status={haveError || verifyCode.isError ? 'error' : undefined}
          onChange={handleChangeThirdInput}
          onKeyDown={handlePressBackThirdInput}
          ref={thirdInput}
          value={code[2] || ''}
        />
        <Input
          status={haveError || verifyCode.isError ? 'error' : undefined}
          onChange={handleChangeFourthInput}
          onKeyDown={handlePressBackFourthInput}
          ref={fourthInput}
          value={code[3] || ''}
        />
        <Input
          status={haveError || verifyCode.isError ? 'error' : undefined}
          onChange={handleChangeFifthInput}
          onKeyDown={handlePressBackFifthInput}
          ref={fifthInput}
          value={code[4] || ''}
        />
        <Input
          status={haveError || verifyCode.isError ? 'error' : undefined}
          onChange={handleChangeSixthInput}
          onKeyDown={handlePressBackSixthInput}
          ref={sixthInput}
          value={code[5] || ''}
        />
      </div>
      <div className={styles.container_resent}>
        <span className={styles.container_resent_title}>{t('profile.Kodni_olmadingizmi')}?</span>
        {resentCode ? (
          <span onClick={handleResendCode} className={styles.container_resent_button}>
            {t('contract.Qayta_yuborish')}
          </span>
        ) : (
          <span className={styles.container_resent_disable_button}>{t('contract.Qayta_yuborish')}</span>
        )}
      </div>
      <Button loading={verifyCode.isLoading} onClick={handleSubmit} type="primary" htmlType="submit" className={styles.container_button}>
        {t('profile.Keyingi')}
      </Button>
    </div>
  );
};

export default SendCode;
