import React, { FC, useState } from 'react';

import TrushSquareGreenIcon from '../../../../assets/icons/TrushSquareGreenIcon';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { setAllColumnDisable } from '../../../../store/reducers/CrmSlice';
import { PipeAll } from '../../../../utils/models/PipeAll';

import VoronkaCardColumnFooter from './VoronkaCardColumnFooter';

import styles from './voronkaCards.module.scss';

interface IProps {
  dragging: boolean;
  sortableColumn: boolean;
  draggingId: number;
  data: PipeAll | undefined;
  setDeleteLead: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id: number;
    }>
  >;
  setReasonsRefusal: (
    value: React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  ) => void;
  setLeadTaskCompleteModal: React.Dispatch<
    React.SetStateAction<{
      id: number;
      visible: boolean;
      leadId: number;
      statusId: number;
    }>
  >;
}

const VoronkaCardColumnFooterContent: FC<IProps> = ({ dragging, sortableColumn, draggingId, data, setDeleteLead, setReasonsRefusal, setLeadTaskCompleteModal }) => {
  const [hover, setHover] = useState(false);
  const dispatch = useAppDispatch();

  const deleteLead = () => {
    setDeleteLead({
      visible: true,
      id: draggingId
    });
  };

  return (
    <div
      className={`${styles.voronkaCards_footer} ${dragging && styles.voronkaCards_footer_active}`}
      onMouseEnter={e => {
        dispatch(setAllColumnDisable(true));
      }}
      onMouseLeave={e => {
        dispatch(setAllColumnDisable(false));
      }}
    >
      {!sortableColumn && (
        <div
          className={styles.voronkaCards_footer_card}
          style={{
            borderColor: '#E53535',
            background: hover && dragging ? 'lightblue' : '#ffffff'
          }}
          onMouseEnter={e => {
            setHover(true);
          }}
          onMouseLeave={e => {
            setHover(false);
          }}
          onMouseUp={e => {
            deleteLead();
          }}
        >
          <TrushSquareGreenIcon />
        </div>
      )}
      {data?.pipeStatuses?.map(
        (column, index) =>
          (column.type === 'FAILED' || column.type === 'SUCCESS') &&
          !sortableColumn && (
            <VoronkaCardColumnFooter
              {...column}
              key={column?.id}
              index={index}
              dragging={dragging}
              draggingId={draggingId}
              setReasonsRefusal={setReasonsRefusal}
              setLeadTaskCompleteModal={setLeadTaskCompleteModal}
            />
          )
      )}
    </div>
  );
};

export default VoronkaCardColumnFooterContent;
