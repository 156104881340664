import React, { useState, useEffect, useCallback } from 'react';
import styles from './crmFilterSave.module.scss';
import { Button } from 'antd';
import CrmFilterSaveCreate from './CrmFilterSaveCreate';
import CrmFilterSaveItem from './CrmFilterSaveItem';
import { useTableConfigCustom } from '../../../../../../queries/queries';
import { TableKeyCustom } from '../../../../../../utils/models/TableKey';
import { CrmFilterSaveData } from './utils/models/CrmFilterSaveData';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';

const CrmFilterSave = () => {
  const { searchParams, location } = useQueryParam<any, any>();
  const [created, setCreated] = useState(false);
  const { data } = useTableConfigCustom<CrmFilterSaveData>(TableKeyCustom.crm_filter);

  const permissionCreate = useCallback(() => {
    if (Object.entries(searchParams).length > 1 && !data?.filters?.some(item => item?.url === location?.search)) {
      return true;
    } else {
      return false;
    }
  }, [data, location.search, searchParams]);

  useEffect(() => {
    if (!permissionCreate()) {
      setCreated(false);
    }
  }, [data, permissionCreate]);

  const handleCreated = () => {
    setCreated(true);
  };

  return (
    <div className={styles.container}>
      {permissionCreate() &&
        (created ? (
          <CrmFilterSaveCreate data={data} />
        ) : (
          <Button type="primary" className={styles.createBtn} onClick={handleCreated}>
            Saqlash
          </Button>
        ))}
      <div className={styles.items}>{data?.filters?.map(item => <CrmFilterSaveItem {...item} key={item?.id} data={data} />)}</div>
    </div>
  );
};

export default CrmFilterSave;
