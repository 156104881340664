import React, { useState } from 'react';
import styles from './header.module.scss';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { BoosComplexSelectType } from '../../../../utils/models/BoosComplexSelectType';
import { setChangeDefaultValue2, setDate1 } from '../../../../store/reducers/CrmStatisticsSlice';
import { DATE_WITH_TIME_FORMAT } from '../../../../utils/constants/format';
import { START_DATE_DEFAULT } from '../../../../utils/constants/startDateDefault';
import SpecialTimeModal from '../../../../components/bossComplex/SpecialTimeModal';

const { Option } = Select;

const Header3 = () => {
  const [specialModal, setSpecialModal] = useState(false);
  const [date, setDate] = useState<any>('');

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { defaultValue2 } = useAppSelector(state => state.crmStatistic);

  // change date
  const changeDate = (value: string) => {
    switch (value) {
      case BoosComplexSelectType.TODAY:
        setToday();
        dispatch(setChangeDefaultValue2(value));
        break;
      case BoosComplexSelectType.YESTERDAY:
        setYesterday();
        dispatch(setChangeDefaultValue2(value));
        break;
      case BoosComplexSelectType.THIS_WEEK:
        setThisWeek();
        dispatch(setChangeDefaultValue2(value));
        break;
      case BoosComplexSelectType.THIS_MONTH:
        setThisMonth();
        dispatch(setChangeDefaultValue2(value));
        break;
      case BoosComplexSelectType.NOW:
        setNow();
        dispatch(setChangeDefaultValue2(value));
        break;
      case BoosComplexSelectType.SPECIAL:
        setSpecial();
        // dispatch(setChangeDefaultValue2('value'))
        break;
    }
  };

  // set now
  const setNow = () => {
    dispatch(
      setDate1({
        finishDate: dayjs(new Date()).format(DATE_WITH_TIME_FORMAT),
        startDate: `${START_DATE_DEFAULT} 00:00`
      })
    );
  };

  // set today
  const setToday = () => {
    dispatch(
      setDate1({
        finishDate: dayjs(new Date()).format(DATE_WITH_TIME_FORMAT),
        startDate: dayjs().hour(0).minute(0).format(DATE_WITH_TIME_FORMAT)
      })
    );
  };

  // set yesterday
  const setYesterday = () => {
    dispatch(
      setDate1({
        finishDate: dayjs(new Date()).endOf('day').add(-1, 'day').format(DATE_WITH_TIME_FORMAT),
        startDate: dayjs(new Date()).startOf('day').add(-1, 'day').format(DATE_WITH_TIME_FORMAT)
      })
    );
  };

  // set this week
  const setThisWeek = () => {
    if (dayjs(new Date()).startOf('week').format(DATE_WITH_TIME_FORMAT) === dayjs(new Date()).format(DATE_WITH_TIME_FORMAT)) {
      dispatch(
        setDate1({
          finishDate: dayjs(new Date()).format(DATE_WITH_TIME_FORMAT),
          startDate: dayjs(new Date()).add(-6, 'day').format(DATE_WITH_TIME_FORMAT)
        })
      );
    } else {
      dispatch(
        setDate1({
          finishDate: dayjs(new Date()).format(DATE_WITH_TIME_FORMAT),
          startDate: dayjs(new Date()).startOf('week').add(1, 'day').format(DATE_WITH_TIME_FORMAT)
        })
      );
    }
  };

  const setThisMonth = () => {
    dispatch(
      setDate1({
        finishDate: dayjs(new Date()).format(DATE_WITH_TIME_FORMAT),
        startDate: dayjs(new Date()).startOf('month').format(DATE_WITH_TIME_FORMAT)
      })
    );
  };
  // set special
  const setSpecial = () => {
    setSpecialModal(true);
  };

  // handle special modal
  const handleClose = () => {
    setSpecialModal(false);
  };

  // handle select date
  const handleSelectDate = (date: string[]) => {
    setDate(date);
    dispatch(setChangeDefaultValue2(`${date[0]}-${date[1]}`));
    dispatch(
      setDate1({
        finishDate: `${date[1]} 00:00`,
        startDate: `${date[0]} 00:00`
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={'d-flex align-center'}>
        <Select
          className={'customSelect ' + styles.container_select}
          // defaultValue={defaultValue2}
          onChange={changeDate}
          value={defaultValue2}
        >
          <Option value={BoosComplexSelectType.NOW}>{t('boss_complex.Shu_kungacha')}</Option>
          <Option value={BoosComplexSelectType.TODAY}>{t('boss_complex.Bugun')}</Option>
          <Option value={BoosComplexSelectType.YESTERDAY}>{t('boss_complex.Kecha')}</Option>
          <Option value={BoosComplexSelectType.THIS_WEEK}>{t('boss_complex.Joriy_hafta')}</Option>
          <Option value={BoosComplexSelectType.THIS_MONTH}>{t('boss_complex.Joriy_oy')}</Option>
          <Option value={BoosComplexSelectType.SPECIAL}>{t('boss_complex.Maxsus')}</Option>
        </Select>
      </div>
      <SpecialTimeModal visible={specialModal} close={handleClose} handleSelect={handleSelectDate} />
    </div>
  );
};

export default Header3;
