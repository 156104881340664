/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import CheveronBottom from '../../../../../../../assets/icons/CheveronBottom';
import CheckmarkIcon from '../../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '../../../../../../../assets/icons/crmIcons/crmIcons';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { useLeadTaskType } from '../../../../../../../queries/queries';
import { TriggerConditionErrorType } from '../../../../../../../utils/models/Trigger';

import styles from '../triggers.module.scss';

const TypeV2 = ({ value, onChange }: { value?: any; onChange?: (value: any) => void }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  // const [currentType, setCurrentType] = useState<LeadTaskType>();
  const { data } = useLeadTaskType();
  const currentType = data?.find(item => item?.id === value);
  const setCurrentType = onChange || ((a: any) => {});

  const { t } = useTranslation();

  const taskTypeId = useAppSelector(state => state.triggerSlice.taskTypeId);
  const errorList = useAppSelector(state => state.triggerSlice.errorList);
  const dispatch = useAppDispatch();

  // handle open dropdown
  const handleOpenDropdown = (visible: boolean) => {
    setOpenDropdown(visible);
  };

  // show error
  const showError = () => {
    let errorValue = false;

    errorList.forEach(error => {
      if (error.type === TriggerConditionErrorType.TASK) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  const setCurrentType1 = (typeId: any) => {
    setOpenDropdown(false);
    onChange && onChange(typeId);
  };

  return (
    <Dropdown
      visible={openDropdown}
      onVisibleChange={handleOpenDropdown}
      placement="bottom"
      overlayClassName={styles.container_body_type_dropdown}
      overlay={
        <>
          {data?.map((type, index) => (
            <div onClick={() => setCurrentType1(type?.id)} key={index} className={styles.container_body_type_dropdown_item}>
              {crmIcons[type.icon.split('#')[0]]?.icon(`#${type.icon.split('#')[1]}`)}
              <span className={styles.container_body_type_dropdown_item_title}>{type.name}</span>
              {taskTypeId === type.id && <CheckmarkIcon className={styles.container_body_type_dropdown_item_check} />}
            </div>
          ))}
        </>
      }
      trigger={['click']}
    >
      <div
        onClick={() => handleOpenDropdown(true)}
        className={styles.container_body_type + (showError() ? ` ${styles.container_body_type_error}` : '')}
      >
        {currentType ? (
          <>
            {crmIcons[currentType.icon?.split('#')[0]]?.icon(`#${currentType.icon?.split('#')[1]}`)}
            <span className={styles.container_body_type_title}>{currentType.name}</span>
          </>
        ) : (
          t('triggers.Topshiriq_turi')
        )}
        <CheveronBottom className={styles.container_body_type_select} />
      </div>
    </Dropdown>
  );
};

export default TypeV2;
