import React from 'react';
import { Button, Modal } from 'antd';
import styles from '../../../order/order.module.scss';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { ChangesQueryParams } from '../../../../utils/models/Changes';
import CancelBronGarbage from '../../../../assets/icons/CancelBronGarbage';
import { useDeletePaymentChangesPage } from '../../services/mutation';

interface Props {
  visible: boolean;
  id: number;
  onCancel: (visible: boolean) => void;
}

const DeleteModal: React.FC<Props> = ({ visible, onCancel, id }) => {
  const { searchParams } = useQueryParam<ChangesQueryParams, any>();

  const { t } = useTranslation();

  const deletePayment = useDeletePaymentChangesPage(searchParams.contractId, id, handleClose);

  function handleClose() {
    onCancel(false);
  }

  const handelDelete = () => {
    deletePayment.mutate();
  };

  return (
    <Modal className={styles.cancel_order_modal} closable={false} footer={null} width={420} visible={visible} onCancel={handleClose}>
      <div className={`${styles.icon_cont} d-flex justify-center`}>
        <span className={`${styles.icon} d-flex align-center justify-center`}>
          <CancelBronGarbage />
        </span>
      </div>
      <h1 style={{ marginBottom: '24px' }} className={styles.title}>
        {t('changes.Tolovni_ochirib_yubormoqchimisiz')}
      </h1>
      <div className={'d-flex align-center'}>
        <Button type={'primary'} onClick={handleClose} className={styles.btn_cancel}>
          {t('changes.Yopish')}
        </Button>
        <Button loading={deletePayment.isLoading} onClick={handelDelete} danger type={'primary'} className={styles.btn_confirm}>
          {t('changes.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
