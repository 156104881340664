import { ReactNode } from 'react';

import { ActionType } from 'modules/multi-payment';

import PaymentCreateOffer from './payment-create-offer/PaymentCreateOffer';
import PaymentDeleteOffer from './payment-delete-offer/PaymentDeleteOffer';
import TermDiscountCreate from './term-discount-create/TermDiscountCreate';

export const actions: Record<
  ActionType,
  {
    component: ReactNode;
    title?: string;
    width?: number;
    closable?: boolean
  }
> = {
  PAYMENT_TYPE_CREATE: {
    component: <PaymentCreateOffer />,
    title: 'To‘lov turini yaratish',
    width: 399
  },
  PAYMENT_TYPE_UPDATE: {
    component: <PaymentCreateOffer />,
    title: 'To‘lov turini tahrirlash',
    width: 399
  },
  TERM_DISCOUNT_CREATE: {
    component: <TermDiscountCreate />,
    title: 'Muddatli chegirma qo‘shish',
    width: 694
  },
  TERM_DISCOUNT_UPDATE: {
    component: <TermDiscountCreate />,
    title: 'Muddatli chegirmani tahrirlash',
    width: 694
  },
  PAYMENT_TYPE_DELETE: {
    component: <PaymentDeleteOffer />,
    width: 360,
    closable:false
  }
};
