import { createSlice, current } from '@reduxjs/toolkit';

import { FlatId } from '../../utils/models/Flat';

interface ShaxmatkaState {
  data: FlatId[][][];
  activeKey: {
    id: number;
    name: string;
  };
  floorArr: {
    floor: number;
    checked: boolean;
  }[];
  xaxisArr: number[];
  selectedFloorArr: number[];
  selectedXaxisArr: any[];
}

const initialState: ShaxmatkaState = {
  data: [],
  activeKey: {
    id: 0,
    name: ''
  },
  floorArr: [],
  xaxisArr: [],
  selectedFloorArr: [],
  selectedXaxisArr: []
};

export const shaxmatkaV2Slice = createSlice({
  name: 'shaxmatkaV2',
  initialState,
  reducers: {
    setActiveKey: (
      state,
      action: {
        payload: {
          id: number;
          name: string;
        };
      }
    ) => {
      state.activeKey = action.payload;
    },

    setShaxmatkaV2: (state, action: any) => {
      state.data = action.payload;
    },

    setFloorArr: (state, action: any) => {
      state.floorArr = action.payload.map((item: any) => ({
        floor: item.floor,
        checked: item.checked
      }));
    },

    setXaxisArr: (state, action: any) => {
      state.xaxisArr = action.payload;
    },

    setApartment: (
      state,
      action: {
        payload: {
          entrance: number;
          floor: number;
          xaxis: number;
          ctrlKey: boolean;
        };
      }
    ) => {
      const { data } = current(state);

      function Apartment(e: FlatId) {
        return action.payload.entrance === e.entrance && action.payload.floor === e.floor && action.payload.xaxis === e.xaxis[0];
      }

      state.data = data.map(entrance =>
        entrance.map(floor =>
          floor.map(apartment => Apartment(apartment)
            ? {
              ...apartment,
              selected: true
            }
            : {
              ...apartment,
              selected: action.payload.ctrlKey ? apartment.selected : false
            })
        )
      );
    },

    setShaxmatkaV2IdSelect: (
      state,
      action: {
        payload: any[];
      }
    ) => {
      // console.log(action.payload)
      const { data } = current(state);

      state.data = data.map(entrance => entrance.map(floor => floor.map(apartment => ({
        ...apartment,
        selected: action.payload.includes(apartment.id)
      }))));
    },

    setShaxmatkaV2Select: (
      state,
      action: {
        payload: any;
      }
    ) => {
      const { data } = current(state);

      if (action.payload?.checked) {
        state.selectedFloorArr.push(action.payload?.floor);
        state.selectedXaxisArr.push({
          xaxis: action.payload?.xaxis,
          entrance: action.payload?.entrance
        });
        state.data = data.map(entrance => entrance.map(floor => floor.map(apartment => ({
          ...apartment,
          selected:
            action.payload?.floor === apartment.floor ||
              (action.payload?.xaxis === apartment.xaxis[0] && action.payload?.entrance === apartment.entrance)
              ? true
              : apartment.selected
        }))));
      } else {
        state.selectedFloorArr.filter(i => i !== action.payload?.floor);
        state.selectedXaxisArr.filter(i => i.xaxis !== action.payload?.xaxis && i.entrance !== action.payload?.entrance);
        state.data = data.map(entrance => entrance.map(floor => floor.map(apartment => ({
          ...apartment,
          selected:
            action.payload?.floor === apartment.floor ||
              (action.payload?.xaxis === apartment.xaxis[0] && action.payload?.entrance === apartment.entrance)
              ? false
              : apartment.selected
        }))));
      }
    }
  }
});

export const { setShaxmatkaV2, setActiveKey, setFloorArr, setXaxisArr, setShaxmatkaV2Select, setApartment, setShaxmatkaV2IdSelect } =
  shaxmatkaV2Slice.actions;
export default shaxmatkaV2Slice.reducer;
