import React, { FC } from 'react';
import DocumentIcon from '../../assets/icons/DocumentIcon';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import { useDeleteFile } from '../../queries/mutation';
import { Spin } from 'antd';

interface AktImageDeleteProps {
  name: string;
  id: number;

  onDeleteFile(id?: any): void;
}

const AktImageDelete: FC<AktImageDeleteProps> = ({ name, id, onDeleteFile }) => {
  const deleteFile = useDeleteFile(handleSuccess);

  //handleSuccess
  function handleSuccess() {
    onDeleteFile(id);
  }

  return (
    <div>
      <p>
        <DocumentIcon />
        {name}
      </p>
      <Spin spinning={deleteFile.isLoading}>
        <span
          onClick={() => {
            deleteFile.mutate(id);
          }}
        >
          <RemoveIcon />
        </span>
      </Spin>
    </div>
  );
};

export default AktImageDelete;
