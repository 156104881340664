import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import TableOrderIcon from '../../../assets/icons/TableOrderIcon';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { setCompanyId } from '../../../store/reducers/CompanySlice';
import { getClassNameOrder } from '../../../utils/helper/getClassNameTableOrder';
import { urlEncode } from '../../../utils/helper/urlEncodeDecode';
import { OrderStatus } from '../../../utils/models/OrderStatus';
import { Order } from '../../../utils/models/OrderTable';
import { TableConfig } from '../../../utils/models/TableConfig';
import { SearchParamsOrder } from '../Order';

interface TableOrdersProps {
  data: Order[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
}

const TableOrders: React.FC<TableOrdersProps> = ({ tableConfig, tableConfigFetch, data, isLoading, isFetching }) => {
  const dispatch = useAppDispatch();
  const { searchParams, location, orderTable, push } = useQueryParam<SearchParamsOrder, null>();
  const { t } = useTranslation();

  const initialColumn: ColumnsType<{}> | undefined = [
    {
      title: t('home.Mijoz'),
      dataIndex: 'name'
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <div onClick={() => handleOrders('DATE')} className="orderTableCont">
          <span>{t('home.Sana')}</span>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      dataIndex: 'date',
      align: 'center',
      render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
    },
    {
      align: 'left',
      title: t('home.TJM_nomi'),
      dataIndex: 'houseName'
    },
    {
      title: (
        <>
          <div>{t('home.Uy_malumotlari')}</div>
          <div style={{ color: '#A3A3A3' }}>{t('contract.Uy_raqami_Qavat_Xona_soni')}</div>
        </>
      ),
      align: 'center',
      width: '330px',
      render: (item: Order) => (
        <span>
          {item.flatNumber} | {item.flatFloor} | {item.flatRooms}
        </span>
      )
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <div onClick={() => handleOrders('REMAINED')} className="orderTableCont">
          <span>{t('home.Qolgan_muddati')}</span>
          <div className="orderIcons">
            <TableOrderIcon className="orderTable_ASC" />
            <TableOrderIcon className="orderTable_DESC" />
          </div>
        </div>
      ),
      width: '150px',
      align: 'center',
      dataIndex: 'remained'
    },
    {
      title: t('home.Status'),
      dataIndex: 'status',
      align: 'center',
      render: (item: OrderStatus) => (
        // eslint-disable-next-line no-nested-ternary
        <span className={item === 'ACTIVE' ? 'table_status_active' : item === 'CONTRACT' ? 'table_status_contract_mode' : 'table_status_canceled'}>
          {t(`home.${item}`)}
        </span>
      )
    },
    {
      title: t('home.Masul'),
      align: 'center',
      dataIndex: 'employee'
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Sana':
              newConfig.push({
                align: 'center',
                title: (
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  <div onClick={() => handleOrders('DATE')} className="orderTableCont">
                    <span>{t('home.Sana')}</span>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'date',
                render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
              });
              break;
            case 'Uy_malumotlari':
              newConfig.push({
                width: '330px',
                title: (
                  <>
                    <div>{t('home.Uy_malumotlari')}</div>
                    <div style={{ color: '#A3A3A3' }}>{t('contract.Uy_raqami_Qavat_Xona_soni')}</div>
                  </>
                ),
                align: 'center',
                render: (item: Order) => (
                  <span>
                    {item.flatNumber} | {item.flatFloor} | {item.flatRooms}
                  </span>
                )
              });
              break;
            case 'Status':
              newConfig.push({
                title: t('home.Status'),
                dataIndex: 'status',
                align: 'center',
                render: (item: OrderStatus) => (
                  <span
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      item === 'ACTIVE' ? 'table_status_active' : item === 'CONTRACT' ? 'table_status_contract_mode' : 'table_status_canceled'
                    }
                  >
                    {t(`home.${item}`)}
                  </span>
                )
              });
              break;
            case 'Mijoz':
              newConfig.push({
                title: t('home.Mijoz'),
                dataIndex: 'name'
              });
              break;
            case 'TJM_nomi':
              newConfig.push({
                align: 'left',
                title: t('home.TJM_nomi'),
                dataIndex: 'houseName'
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                align: 'center',
                dataIndex: 'employee'
              });
              break;
            case 'Qolgan_muddati':
              newConfig.push({
                width: '150px',
                align: 'center',
                title: (
                  // eslint-disable-next-line @typescript-eslint/no-use-before-define
                  <div onClick={() => handleOrders('REMAINED')} className="orderTableCont">
                    <span>{t('home.Qolgan_muddati')}</span>
                    <div className="orderIcons">
                      <TableOrderIcon className={getClassNameOrder('REMAINED', 'ASC', searchParams.order)} />
                      <TableOrderIcon className={getClassNameOrder('REMAINED', 'DESC', searchParams.order)} />
                    </div>
                  </div>
                ),
                dataIndex: 'remained'
              });
              break;
            default:
              break;
          }
        }
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch, location.search]);

  useEffect(() => {
    const newConfig: any[] = [...column];

    if (tableConfig.length === 0) {
      newConfig[1] = {
        align: 'center',
        title: (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <div onClick={() => handleOrders('DATE')} className="orderTableCont">
            <span>{t('home.Sana')}</span>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('DATE', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('DATE', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        dataIndex: 'date',
        render: (date: string) => <span style={{ color: '#A3A3A3' }}>{date}</span>
      };
      newConfig[4] = {
        width: '150px',
        align: 'center',
        title: (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <div onClick={() => handleOrders('REMAINED')} className="orderTableCont">
            <span>{t('home.Qolgan_muddati')}</span>
            <div className="orderIcons">
              <TableOrderIcon className={getClassNameOrder('REMAINED', 'ASC', searchParams.order)} />
              <TableOrderIcon className={getClassNameOrder('REMAINED', 'DESC', searchParams.order)} />
            </div>
          </div>
        ),
        dataIndex: 'remained'
      };
      setColumn(newConfig);
    }
  }, [location.search]);

  const tableOnRow = (item: any) => {
    dispatch(setCompanyId(item?.companyId));
    push(`${location.pathname}/${item.id}/${urlEncode(`№ ${item.number}`)}?company=${btoa(String(item?.companyId || ''))}`);
  };

  const handleOrders = (key: string) => {
    orderTable(key);
  };

  return (
    <Table
      loading={isLoading || tableConfigFetch || isFetching}
      className="custom_table contract_table_size order_table"
      size="small"
      rowKey="id"
      pagination={false}
      columns={column}
      dataSource={data}
      onRow={e => ({
        onClick: () => tableOnRow(e)
      })}
    />
  );
};

export default TableOrders;
