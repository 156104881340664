import { contractStatisticsPath } from '@routing/contract-statistics/contractStatisticsPath';

import BarLineChartIcon from '../../../assets/icons/BarLineChartIcon';
import NotificationBoxIcon from '../../../assets/icons/NotificationBoxIcon';
import { BossPagePaths } from '../../../routing/bossPage/bossPagePaths';
import { rootPaths } from '../../../routing/rootPaths';

const defaultUrl = rootPaths.BOSS + BossPagePaths.CONTRACT;

const navBarLinks = [
  {
    name: 'Umumiy',
    path: rootPaths.BOSS + BossPagePaths.CONTRACT + contractStatisticsPath.COMMON,
    icon: NotificationBoxIcon
  },
  {
    name: 'Chegirmalar',
    path: defaultUrl + contractStatisticsPath.COMPLEX,
    icon: BarLineChartIcon
  }
];

export default navBarLinks;
