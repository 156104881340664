import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { downloadFile } from '@utils/helper/downloadFile';
import { useMutation } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';

export function useGetPaymentDelayExcel() {
  return useMutation(
    async lang => {
      const res = await $api.get(`${endPoints.BOSS_PAYMENT_DELAY_EXCEL}/?lang=${lang}`, {
        responseType: 'blob'
      });

      return {
        data: res.data
      };
    },
    {
      onSuccess: data => {
        downloadFile(data.data, `payment-period`, 'xlsx');
      },
      onError: async (error: any) => {
        const errorObj = JSON.parse(await error.response.data.text());

        showMessage(errorObj.message, errorObj.accept);
      }
    }
  );
}
