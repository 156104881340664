import ClientInformationCard from './ClientInformationCard';
import ContractsCard from './ContractsCard';

import styles from './Contract.module.scss';

const ClientInformation = () => (
  <div className={styles.clientInformationDiv}>
    <ClientInformationCard />
    <ContractsCard />
  </div>
);

export default ClientInformation;
