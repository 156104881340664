import React, { useState } from 'react';
import SettingIcon from '@icons/SettingIcon';
import { useTranslation } from 'react-i18next';

import NumerationItem from './NumerationItem';
import NumerationSettings from './NumerationSettings';

import styles from '../../../pages/messageSettings/MessageSettings.module.scss';

function CompanyConfigNumeration() {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <div className={styles.messageTemplate}>
      <div className="mb-2 flex items-center justify-between">
        <p className="text-2xl font-semibold text-[#464255]">{t('home.tartiblash')}</p>
        <div className="flex h-[38px] cursor-pointer items-center gap-2 rounded-[10px] bg-[#EAECF0] pl-6 pr-5" onClick={handleOpen}>
          <SettingIcon stroke="#344054" />
          <span className="text-base font-medium text-[#344054]">{t('home.Sozlash')}</span>
        </div>
      </div>
      <NumerationItem type="contract" title={t('company_config.Shartnoma_seriya_raqami_generatsiya_qilish')} />
      <NumerationItem type="payment" title={t('company_config.Tolovlar_seriya_raqami_generatsiya_qilish')} />

      <NumerationSettings visible={visible} setVisible={setVisible} />
    </div>
  );
}

export default CompanyConfigNumeration;
