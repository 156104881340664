import React, { FC } from 'react';
import styles from './contractIdApartmentInfo.module.scss';
import { ContractId } from '../../../../utils/models/ContractId';
import { useTranslation } from 'react-i18next';
import FlatGalleryFC from '../../../../components/flat-gallery/FlatGallery';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';

interface IProps {
  data: ContractId | undefined;
  isLoading: boolean;
}

const ContractIdApartmentInfo: FC<IProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h1 className={styles.container_title}>{t('home_id.Xonadon_malumotlari')}</h1>
      {data?.flats?.map(item => (
        <div className={styles.container_content}>
          <div className={styles.container_content_left}>
            <FlatGalleryFC gallery={item?.flatGallery} />
          </div>
          <div className={styles.container_content_right}>
            <div className={styles.container_content_right_item}>
              <p>{t('contract_id.Bino')}</p>
              <span>
                {item?.houseName}, {item?.buildingName}
              </span>
            </div>
            <div className={styles.container_content_right_item}>
              <p>{t('contract_id.Xonalar_soni')}</p>
              <span>{item?.flatRooms}x</span>
            </div>
            <div className={styles.container_content_right_item}>
              <p>{t('contract_id.Qavat')}</p>
              <span>{item?.flatFloor}</span>
            </div>
            <div className={styles.container_content_right_item}>
              <p>{t('home.Xonadon_raqami')}</p>
              <span>№{item?.flatNumber}</span>
            </div>
            <div className={styles.container_content_right_item}>
              <p>{t('showroom.Umumiy_maydoni')}</p>
              <span>
                {roundFloatNumber((item?.flatArea ? item?.flatArea : 0) + (item?.terraceArea ? item?.terraceArea : 0))} m<sup>2</sup>
              </span>
            </div>
            <div className={styles.container_content_right_item}>
              <p>{t('contract_id.Topshirish_holati')}</p>
              <span>{item.repaired ? t('home.Tamirli') : t('home.Tamirsiz')}</span>
            </div>
            {item?.terraceArea && (
              <div className={styles.container_content_right_item}>
                <p>{t('home_id.Terassa_maydoni')}</p>
                <span>
                  {item?.terraceArea} m<sup>2</sup>
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContractIdApartmentInfo;
