import React, { useEffect, useState } from 'react';
import { setChangePartId, setOptionsContract } from '@reducers/CreateContractSlice';
import { Permissions } from '@utils/constants/permissions';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import { roundFloatNumber } from '../../../../utils/helper/roundFloatNumber';
import { FlatDiscount } from '../../../../utils/models/Discount';

interface IProps {
  setgetTableData: React.Dispatch<React.SetStateAction<FlatDiscount[] | undefined>>;
  beta2?: boolean;
}

const CalculatorTable: React.FC<IProps> = ({ setgetTableData, beta2 }) => {
  const permission = useAppSelector(state => state.userReducer.user?.role?.permissions);

  const isDiscount = permission?.includes(Permissions.PERMISSION_GIVE_DISCOUNT);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [tableData, setTableData] = useState<FlatDiscount[]>();

  const { prePayment, discountSum, delay, totalAmount, flats, useDiscount } = useAppSelector(state => state.createContract);

  useEffect(() => {
    if (tableData && tableData?.length > 0) {
      setgetTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (!useDiscount) {
      const monthlyPayment = roundFloatNumber((totalAmount - prePayment - discountSum) / delay);
      const discount = roundFloatNumber((discountSum / totalAmount) * 100);
      const percent = roundFloatNumber((prePayment / totalAmount) * 100);

      setTableData([
        {
          month: delay,
          firstPayment: prePayment,
          discountAmount: discountSum,
          total: roundFloatNumber(totalAmount - discountSum),
          monthlyPayment: monthlyPayment || 0,
          discount,
          percent
        }
      ]);
    } else {
      const discountTable: FlatDiscount[] = [];
      let list = flats[0].repaired ? flats[0].discountRepairedList : flats[0].discountNotRepairedList;
      const monthlyPayment = delay ? roundFloatNumber((totalAmount - prePayment - discountSum) / delay) : 0;
      const percent = roundFloatNumber((prePayment / totalAmount) * 100);
      let hasZeroDiscount = true;

      if (list[0] && list[0]?.discount !== 0) {
        hasZeroDiscount = false;
        list = [
          {
            month: delay,
            discountAmount: 0,
            discount: 0,
            firstPayment: 0,
            monthlyPayment: roundFloatNumber(totalAmount / delay) || 0,
            total: totalAmount,
            percent: 0
          },
          ...list
        ];
      }
      let find = false;

      for (let i = 0; i < list.length; i++) {
        if (!find && delay === list[i].month && (i + 1 === list.length || (list[i].firstPayment <= prePayment && list[i + 1].firstPayment > prePayment))) {
          find = true;
          discountTable.push({
            ...list[i],
            discountAmount: flats[0].discountAmount || 0,
            month: delay,
            monthlyPayment: monthlyPayment || 0,
            firstPayment: prePayment,
            total: roundFloatNumber(totalAmount - discountSum),
            percent: list[i].firstPayment === prePayment ? list[i].percent : percent,
            discount: !flats[0].discountAmount ? 0 : flats[0].discountPercent || 0
          });
        } else {
          discountTable.push(list[i]);
        }
      }
      if (hasZeroDiscount) {
        setTableData(discountTable);
      } else {
        setTableData(discountTable.slice(1, discountTable.length));
      }
    }
  }, [prePayment, discountSum, delay, totalAmount, flats, useDiscount]);

  const [oneFlat] = flats;

  const hasTerracceArea = () => {
    if (oneFlat?.flat?.terrace?.area) {
      if (oneFlat?.flat?.repaired) {
        return oneFlat?.flat?.pricePerAreaRepaired === oneFlat?.flat?.terrace?.pricePerAreaRepaired;
      }
      return oneFlat?.flat?.pricePerArea === oneFlat?.flat?.terrace?.pricePerArea;
    }
    return false;
  };

  const area = Number(oneFlat?.flat?.area) + (hasTerracceArea() ? oneFlat?.flat?.terrace?.area : 0);

  const tableColumns = [
    {
      title: t('home_id.Boshlangich_tolov'),
      render: (text: any, record: FlatDiscount) => `${record.firstPayment.toLocaleString('ru')} (${record.percent}%)`
    },
    // {
    //   title: t('home_id.Foizi'),
    //   render: (text: any, record: FlatDiscount) => record.percent + '%',
    // },
    {
      title: beta2 ? t('discount_setting.Chegirma_foiz') : t('home_id.Chegirma_summasi'),
      render: (text: any, record: FlatDiscount) => (beta2 ? `${record.discount}%` : `${record.discountAmount.toLocaleString('ru')} (${record.discount}%)`)
    },
    {
      ...(beta2 &&
        flats.length === 1 && {
          title: t('create_table_payments.1_m2_uchun_narx'),
          render: (text: any, record: FlatDiscount) => roundFloatNumber(record.total / area)?.toLocaleString('ru')
        })
    },
    {
      title: t('home_id.Sotuv_summasi'),
      render: (text: any, record: FlatDiscount) => record.total.toLocaleString('ru')
    },
    {
      title: t('home_id.Muddat'),
      dataIndex: 'month'
    },
    {
      title: t('home_id.Oylik_tolov'),
      render: (text: any, record: FlatDiscount) => record.monthlyPayment.toLocaleString('ru')
    }
  ];

  return (
    <Table
      className="table_calculator"
      columns={tableColumns}
      rowClassName={record => {
        if (record.firstPayment === prePayment && record?.month === delay) return `table_calculate_active`;
        return '';
      }}
      onRow={record => ({
        onClick: e => {
          if (!beta2) {
            dispatch(
              setOptionsContract({
                delay: record?.month,
                prePayment: record?.firstPayment,
                useDiscount: isDiscount ? useDiscount : true
              })
            );
            dispatch(setChangePartId(record?.id!));
          }
        }
      })}
      dataSource={tableData}
      pagination={false}
    />
  );
};

export default CalculatorTable;
