import React, { useState } from 'react';
import { Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import DeleteImage from '../../../assets/icons/DeleteImage';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { useDeleteFile } from '../../../queries/mutation';
import { useGetImageByImageId } from '../../../queries/queries';
import { BASE_URL } from '../../../service/RequestService';
import { setNaturalPersonImage } from '../../../store/reducers/ClientSlice';
import { mathFloor } from '../../../utils/helper/mathFloor';

import baseImage from '../../../assets/img/plan_doma.png';

import styles from '../../../pages/clientCreateContract/clientCreateContract.module.scss';

interface Props {
  id: number;
  type: 'first' | 'second';
}

const PasswordImage: React.FC<Props> = ({ id, type }) => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useGetImageByImageId(id);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  function afterDelete() {
    dispatch(
      setNaturalPersonImage({
        id: undefined,
        type
      })
    );
  }

  const deleteImage = useDeleteFile(afterDelete);

  const handleVisible = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  const handleDelete = () => {
    deleteImage.mutate(id);
  };

  const getFileName = () => {
    if (data?.fileObject && data.fileObject?.originalName?.length > 20) {
      return `${data.fileObject?.originalName?.substring(0, 20)}...`;
    }
    return data?.fileObject?.originalName;
  };

  return (
    <Spin spinning={isLoading}>
      <div className={`d-flex align-center justify-space-between ${styles.passportImageShowCont}`}>
        <div className="d-flex align-center">
          <img src={data?.fileObject.thumbAbsolutePath ? `${BASE_URL}/${data?.fileObject.thumbAbsolutePath}` : baseImage} alt="passport" />
          <span className={styles.passportImageShowCont_name}>{getFileName()}</span>
          <span className={styles.passportImageShowCont_elips} />
          <span className={styles.passportImageShowCont_size}>
            {mathFloor((data?.fileBlob?.size || 1) / 1000000, 2)} {t('client_create_contract.Mb')}
          </span>
        </div>
        <div className="d-flex align-center">
          <span onClick={() => handleVisible(true)} className={styles.passportImageShowCont_view}>
            {t('client.Korish')}
          </span>
          {deleteImage.isLoading ? <Spin /> : <DeleteImage onClick={handleDelete} className={styles.passportImageShowCont_delete} />}
        </div>
      </div>
      <Image
        width={200}
        style={{ display: 'none' }}
        preview={{
          visible,
          src: data?.fileBlob ? URL.createObjectURL(data?.fileBlob) : '',
          onVisibleChange: handleVisible
        }}
      />
    </Spin>
  );
};

export default PasswordImage;
