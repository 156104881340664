import React from 'react';
import styles from './botConfig.module.scss';

const MessagesList = () => {
  return (
    <>
      <svg
        className={styles.showInterface_message_bar_item}
        width="57"
        height="16"
        viewBox="0 0 57 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="57" height="16" rx="4" fill="#F5F5F5" />
      </svg>
      <svg
        className={styles.showInterface_message_bar_item}
        width="127"
        height="37"
        viewBox="0 0 127 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M117 20C117 8.9543 108.046 0 97 0H18.5C8.28274 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37H127L119.179 29.5698C117.788 28.2483 117 26.4136 117 24.4948V20Z"
          fill="#F2F2F2"
        />
      </svg>
      <svg
        className={styles.showInterface_message_bar_item}
        width="317"
        height="47"
        viewBox="0 0 317 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C10 8.95431 18.9543 0 30 0H297C308.046 0 317 8.95431 317 20V27C317 38.0457 308.046 47 297 47H0L7.82125 39.5698C9.21236 38.2483 10 36.4136 10 34.4948V20Z"
          fill="#F2F2F2"
        />
      </svg>
      <svg
        className={styles.showInterface_message_bar_item}
        width="127"
        height="37"
        viewBox="0 0 127 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M117 20C117 8.9543 108.046 0 97 0H18.5C8.28274 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37H127L119.179 29.5698C117.788 28.2483 117 26.4136 117 24.4948V20Z"
          fill="#F2F2F2"
        />
      </svg>
      <svg
        className={styles.showInterface_message_bar_item}
        width="217"
        height="41"
        viewBox="0 0 217 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C10 8.9543 18.9543 0 30 0H197C208.046 0 217 8.95431 217 20V21C217 32.0457 208.046 41 197 41H0L7.82125 33.5698C9.21236 32.2483 10 30.4136 10 28.4948V20Z"
          fill="#F2F2F2"
        />
      </svg>
      <svg
        className={styles.showInterface_message_bar_item}
        width="317"
        height="47"
        viewBox="0 0 317 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C10 8.95431 18.9543 0 30 0H297C308.046 0 317 8.95431 317 20V27C317 38.0457 308.046 47 297 47H0L7.82125 39.5698C9.21236 38.2483 10 36.4136 10 34.4948V20Z"
          fill="#F2F2F2"
        />
      </svg>
    </>
  );
};

export default MessagesList;
