import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal, Tabs } from 'antd';
import { t } from 'i18next';

import { usePostDuplicateControls } from './service/mutation';
import { useGetDuplicateControls } from './service/queries';
import { DuplicateControlsType } from './utils/models/DuplicateControlsType';
import DublikatControllMessengers from './DublikatControllMessengers';
import PotensialClientSource from './PotensialClientSource';

import styles from './dublikatControll.module.scss';

interface IProps {
  setDublikatControll: React.Dispatch<React.SetStateAction<boolean>>;
  dublikatControll: boolean;
}

const DublikatControll: FC<IProps> = ({ dublikatControll, setDublikatControll }) => {
  const [form] = Form.useForm();
  const [switchStates, setSwitchStates] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);


  const { data: dataDuplicateControl, isLoading } = useGetDuplicateControls();
  const postDuplicateControl = usePostDuplicateControls();

  const handleClose = () => {
    setDublikatControll(false);
  };

  useEffect(() => {
    if (dataDuplicateControl) {
      form.setFieldsValue({
        ...dataDuplicateControl,
        isNewData: dataDuplicateControl?.isNewData,
        conflict: dataDuplicateControl?.conflict,
        text: dataDuplicateControl?.duplicateTaskResponsible?.text,
        pipeStatusIds: dataDuplicateControl?.pipes?.map((item: any) => item?.pipeStatuses?.map((prev: any) => prev?.id))?.flat(Infinity),
        fromInstagram: switchStates.fromInstagram,
        fromFaceBook: switchStates.fromFaceBook,
        
        leadTaskTypeId: dataDuplicateControl?.duplicateTaskResponsible?.leadTaskType?.id,
        dublicate: {
          deadLineAfter: dataDuplicateControl?.duplicateTaskResponsible?.deadLineAfter,
          deadLineAfterStatus: dataDuplicateControl?.duplicateTaskResponsible?.deadLineAfterStatus
        }
      });
    }
  }, [dataDuplicateControl]);

  const onFinish = async (values: DuplicateControlsType) => {
    setIsSubmitting(true);
    const { text, dublicate, leadTaskTypeId, ...rest } = values;

    const formData = {
      ...rest,
      isNewData: values?.isNewData,
      conflict: values?.conflict,
      pipeStatusIds: values?.pipeStatusIds,
      fromInstagram: switchStates?.fromInstagram,
      fromFaceBook: switchStates?.fromFaceBook,
      // fromOthers: switchStates?.fromOthers,
      fromFaceBookForm: switchStates?.fromFaceBookForm,
      fromWebForm: switchStates?.fromWebForm,
      fromMarketplace: switchStates?.fromMarketplace,
      fromWebsite: switchStates?.fromWebsite,
      duplicateTaskResponsible: {
        ...dublicate,
        text: values?.text,
        leadTaskTypeId: values?.leadTaskTypeId
      }
    };


    try {
      await postDuplicateControl.mutateAsync(formData);
      handleClose();
    } finally {
      setIsSubmitting(false);
    }

    // postDuplicateControl.mutateAsync(formData).then(handleClose);
  };

  return (
    <Modal
      onCancel={handleClose}
      visible={dublikatControll}
      title={t('home.Dublikatlar_nazorati')}
      wrapClassName={styles.container}
      footer={false}
      width={602}
    >
      <Form form={form} onFinish={onFinish}>
        <Tabs>
          <Tabs.TabPane tab={t('home.Potentsial_mijoz_manbalari')} key="1" forceRender>
            <PotensialClientSource />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('home.Dublikatlarni_nazorat_qilish')} key="2" forceRender>
            <DublikatControllMessengers setSwitchStates={setSwitchStates} switchStates={switchStates} />
          </Tabs.TabPane>
        </Tabs>
        <div className={styles.potensialClientSourrce_footer}>
          <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            {t('home.Saqlash')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DublikatControll;
