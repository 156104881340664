export interface ChangesQueryParams {
  page: ChangesPageSteps;
  clientId: string;
  contractId: string;
}

export enum ChangesPageSteps {
  client = 'client',
  contract = 'contract',
  monthlyPayments = 'monthlyPayments',
  payments = 'payments'
}
