import React from 'react';
import { Button, Modal } from 'antd';
import styles from '../../pages/order/order.module.scss';
import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setCloseDeleteModal } from '../../store/reducers/HouseConfigSlice';
import { useDeleteHouse } from '../../queries/mutation';
import { useTranslation } from 'react-i18next';

const DeleteHouseModal = () => {
  const { searchParams } = useQueryParam<{ search: string }, any>();

  const dispatch = useAppDispatch();
  const { id, openDeleteModal, houseName } = useAppSelector(state => state.houseConfigReducer);
  const { t } = useTranslation();
  const deleteHouse = useDeleteHouse(searchParams.search, handleClose, id || 0);

  function handleClose() {
    dispatch(setCloseDeleteModal());
  }

  const handleDelete = () => {
    deleteHouse.mutate();
  };

  return (
    <Modal className={styles.cancel_order_modal} centered closable={false} footer={null} width={420} visible={openDeleteModal} onCancel={handleClose}>
      <div className={`${styles.icon_cont} d-flex justify-center`}>
        <span className={`${styles.icon} d-flex align-center justify-center`}>
          <CancelBronGarbage />
        </span>
      </div>
      <h1 style={{ textAlign: 'center' }} className={styles.title}>
        "{houseName}" {t('house_config.TJMni_ochirmoqchimisiz')}
      </h1>
      <div style={{ marginTop: '16px' }} className={'d-flex align-center'}>
        <Button type={'primary'} onClick={handleClose} className={styles.btn_cancel}>
          {t('home.Yopish')}
        </Button>
        <Button onClick={handleDelete} loading={deleteHouse.isLoading} htmlType={'submit'} danger type={'primary'} className={styles.btn_confirm}>
          {t('discount_setting.Ochirish')}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteHouseModal;
