import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { paymentSettingsRoutes } from './paymentSettingsRoutes';
import PaymentSettingsNavbar from '../../components/navbars/paymentSettings/PaymetSettingsNavbar';
import MainHeader from '../../components/headers/MainHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import { Layout } from 'antd';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';
import SettingsHeader from '../../components/setting/settingsHeader/SettingsHeader';

const { Content } = Layout;

function PaymentRouter() {
  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={'d-flex align-start'}>
          <PaymentSettingsNavbar />
          <Routes>
            {paymentSettingsRoutes.map((route, index) => (
              <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
            ))}
          </Routes>
        </div>
      </Content>
    </>
  );
}

export default PaymentRouter;
