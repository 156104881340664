import React, { FC } from 'react';

interface IProps {
  color?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
  className?: string;
}

const DollarCircleIcon: FC<IProps> = ({ color = '#F04041', onClick, className }) => {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M8.5 10.6129H8.93333C9.36667 10.6129 9.72667 10.2262 9.72667 9.75956C9.72667 9.17956 9.52 9.06622 9.18 8.94622L8.50667 8.71289V10.6129H8.5Z"
        fill={color}
      />
      <path
        d="M7.98021 1.26759C4.30021 1.28092 1.32021 4.27426 1.33354 7.95426C1.34687 11.6343 4.34021 14.6143 8.02021 14.6009C11.7002 14.5876 14.6802 11.5943 14.6669 7.91426C14.6535 4.23426 11.6602 1.26092 7.98021 1.26759ZM9.50687 8.00092C10.0269 8.18092 10.7269 8.56759 10.7269 9.76092C10.7269 10.7876 9.92021 11.6143 8.93354 11.6143H8.50021V12.0009C8.50021 12.2743 8.27354 12.5009 8.00021 12.5009C7.72687 12.5009 7.50021 12.2743 7.50021 12.0009V11.6143H7.26021C6.16687 11.6143 5.28021 10.6943 5.28021 9.56092C5.28021 9.28759 5.50687 9.06092 5.78021 9.06092C6.05354 9.06092 6.28021 9.28759 6.28021 9.56092C6.28021 10.1409 6.72021 10.6143 7.26021 10.6143H7.50021V8.36092L6.49354 8.00092C5.97354 7.82092 5.27354 7.43426 5.27354 6.24092C5.27354 5.21426 6.08021 4.38759 7.06687 4.38759H7.50021V4.00092C7.50021 3.72759 7.72687 3.50092 8.00021 3.50092C8.27354 3.50092 8.50021 3.72759 8.50021 4.00092V4.38759H8.74021C9.83354 4.38759 10.7202 5.30759 10.7202 6.44092C10.7202 6.71426 10.4935 6.94092 10.2202 6.94092C9.94687 6.94092 9.72021 6.71426 9.72021 6.44092C9.72021 5.86092 9.28021 5.38759 8.74021 5.38759H8.50021V7.64092L9.50687 8.00092Z"
        fill={color}
      />
      <path
        d="M6.27979 6.24672C6.27979 6.82672 6.48645 6.94005 6.82645 7.06005L7.49979 7.29339V5.38672H7.06645C6.63312 5.38672 6.27979 5.77339 6.27979 6.24672Z"
        fill={color}
      />
    </svg>
  );
};

export default DollarCircleIcon;
