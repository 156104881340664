import React from 'react';

const ContractDelayIcon = () => {
  return (
    <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5712 7.20867C21.0643 7.10898 20.6683 6.68487 20.6683 6.16831V5C20.6683 4.45333 20.2149 4 19.6683 4C19.1216 4 18.6683 4.45333 18.6683 5V6C18.6683 6.55228 18.2206 7 17.6683 7H11.0016C10.4493 7 10.0016 6.55228 10.0016 6V5C10.0016 4.45333 9.54827 4 9.00161 4C8.45494 4 8.00161 4.45333 8.00161 5V6.16831C8.00161 6.68488 7.60555 7.10898 7.0987 7.20867C4.1072 7.79706 2.6316 9.8389 2.38827 12.7467C2.36161 13.1333 2.68161 13.4533 3.05494 13.4533H25.6149C26.0016 13.4533 26.3216 13.12 26.2816 12.7467C26.0383 9.8389 24.5627 7.79706 21.5712 7.20867Z"
        fill="url(#paint0_linear_14261_95867)"
      />
      <g filter="url(#filter0_bd_14261_95867)">
        <path
          d="M23.6683 8.08V6C23.6683 5.45333 23.2149 5 22.6683 5C22.1216 5 21.6683 5.45333 21.6683 6V8H13.0016V6C13.0016 5.45333 12.5483 5 12.0016 5C11.4549 5 11.0016 5.45333 11.0016 6V8.08C7.40161 8.41333 5.65494 10.56 5.38827 13.7467C5.36161 14.1333 5.68161 14.4533 6.05494 14.4533H28.6149C29.0016 14.4533 29.3216 14.12 29.2816 13.7467C29.0149 10.56 27.2683 8.41333 23.6683 8.08Z"
          fill="url(#paint1_linear_14261_95867)"
        />
        <path
          d="M23.5683 8.08V8.17116L23.6591 8.17957C25.4404 8.34451 26.7524 8.95668 27.6521 9.9119C28.5526 10.8678 29.0499 12.1775 29.182 13.755L29.1819 13.755L29.1822 13.7573C29.2153 14.0662 28.9476 14.3533 28.6149 14.3533H6.05494C5.73691 14.3533 5.46594 14.08 5.48799 13.7542C5.62009 12.1771 6.11743 10.8676 7.01773 9.9119C7.91753 8.95668 9.22947 8.34451 11.0108 8.17957L11.1016 8.17116V8.08V6C11.1016 5.50856 11.5102 5.1 12.0016 5.1C12.493 5.1 12.9016 5.50856 12.9016 6V8V8.1H13.0016H21.6683H21.7683V8V6C21.7683 5.50856 22.1768 5.1 22.6683 5.1C23.1597 5.1 23.5683 5.50856 23.5683 6V8.08Z"
          stroke="url(#paint2_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <path
        d="M25.0007 14.4536C25.734 14.4536 26.334 15.0536 26.334 15.7869V24.0003C26.334 28.0003 24.334 30.667 19.6673 30.667H9.00065C4.33398 30.667 2.33398 28.0003 2.33398 24.0003V15.7869C2.33398 15.0536 2.93398 14.4536 3.66732 14.4536H25.0007Z"
        fill="url(#paint3_linear_14261_95867)"
      />
      <g filter="url(#filter1_b_14261_95867)">
        <path
          d="M28.0007 16.4536C28.734 16.4536 29.334 17.0536 29.334 17.7869V26.0003C29.334 30.0003 27.334 32.667 22.6673 32.667H12.0007C7.33398 32.667 5.33398 30.0003 5.33398 26.0003V17.7869C5.33398 17.0536 5.93398 16.4536 6.66732 16.4536H28.0007Z"
          fill="#F7CD59"
        />
        <path
          d="M29.584 17.7869C29.584 16.9155 28.8721 16.2036 28.0007 16.2036H6.66732C5.79591 16.2036 5.08398 16.9155 5.08398 17.7869V26.0003C5.08398 28.0386 5.59353 29.7754 6.73412 31.0037C7.87782 32.2354 9.61967 32.917 12.0007 32.917H22.6673C25.0483 32.917 26.7901 32.2354 27.9338 31.0037C29.0744 29.7754 29.584 28.0386 29.584 26.0003V17.7869Z"
          stroke="url(#paint4_linear_14261_95867)"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#filter2_bd_14261_95867)">
        <path
          d="M12.6673 23.3337C12.3207 23.3337 11.974 23.187 11.7207 22.947C11.4807 22.6936 11.334 22.347 11.334 22.0003C11.334 21.6537 11.4807 21.307 11.7207 21.0537C12.094 20.6803 12.6807 20.5603 13.174 20.7736C13.3473 20.8403 13.494 20.9337 13.614 21.0537C13.854 21.307 14.0007 21.6537 14.0007 22.0003C14.0007 22.347 13.854 22.6936 13.614 22.947C13.3606 23.187 13.014 23.3337 12.6673 23.3337Z"
          fill="url(#paint5_linear_14261_95867)"
        />
        <path
          d="M13.1343 20.8654L13.1343 20.8655L13.1381 20.867C13.2991 20.9289 13.4333 21.0147 13.5423 21.1234C13.7654 21.3594 13.9007 21.6814 13.9007 22.0003C13.9007 22.3188 13.7657 22.6404 13.5432 22.8762C13.3074 23.0987 12.9858 23.2337 12.6673 23.2337C12.3488 23.2337 12.0272 23.0987 11.7914 22.8762C11.5689 22.6404 11.434 22.3188 11.434 22.0003C11.434 21.6814 11.5693 21.3594 11.7923 21.1234C12.1373 20.7793 12.6798 20.6689 13.1343 20.8654Z"
          stroke="url(#paint6_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter3_bd_14261_95867)">
        <path
          d="M17.3333 23.3337C16.9867 23.3337 16.64 23.187 16.3867 22.947C16.1467 22.6936 16 22.347 16 22.0003C16 21.6537 16.1467 21.307 16.3867 21.0537C16.5067 20.9337 16.6533 20.8403 16.8267 20.7736C17.32 20.5603 17.9067 20.6803 18.28 21.0537C18.52 21.307 18.6667 21.6537 18.6667 22.0003C18.6667 22.347 18.52 22.6936 18.28 22.947C18.2133 23.0003 18.1467 23.0536 18.08 23.107C18 23.1603 17.92 23.2003 17.84 23.227C17.76 23.267 17.68 23.2937 17.6 23.307C17.5067 23.3203 17.4267 23.3337 17.3333 23.3337Z"
          fill="url(#paint7_linear_14261_95867)"
        />
        <path
          d="M16.8626 20.867L16.8626 20.867L16.8664 20.8654C17.3209 20.6689 17.8634 20.7793 18.2083 21.1234C18.4314 21.3594 18.5667 21.6814 18.5667 22.0003C18.5667 22.3175 18.4329 22.6377 18.212 22.8733L18.1175 22.9489L18.021 23.0261C17.9486 23.074 17.8777 23.109 17.8084 23.1321L17.8016 23.1344L17.7953 23.1376C17.7237 23.1734 17.6535 23.1966 17.5847 23.2082C17.5745 23.2096 17.5646 23.2111 17.555 23.2125C17.4737 23.2242 17.4086 23.2337 17.3333 23.2337C17.0148 23.2337 16.6933 23.0987 16.4574 22.8762C16.2349 22.6404 16.1 22.3188 16.1 22.0003C16.1 21.6814 16.2353 21.3594 16.4583 21.1234C16.5674 21.0147 16.7015 20.9289 16.8626 20.867Z"
          stroke="url(#paint8_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter4_bd_14261_95867)">
        <path
          d="M22.0013 23.3337C21.6546 23.3337 21.308 23.187 21.0546 22.947C20.8146 22.6937 20.668 22.347 20.668 22.0003C20.668 21.6537 20.8146 21.307 21.0546 21.0537C21.188 20.9337 21.3213 20.8403 21.4946 20.7737C21.7346 20.667 22.0013 20.6403 22.268 20.6937C22.348 20.707 22.428 20.7337 22.508 20.7737C22.588 20.8003 22.668 20.8404 22.748 20.8937C22.8146 20.947 22.8813 21.0004 22.948 21.0537C23.188 21.307 23.3346 21.6537 23.3346 22.0003C23.3346 22.347 23.188 22.6937 22.948 22.947C22.8813 23.0003 22.8146 23.0537 22.748 23.107C22.668 23.1603 22.588 23.2004 22.508 23.227C22.428 23.267 22.348 23.2937 22.268 23.307C22.1746 23.3204 22.0813 23.3337 22.0013 23.3337Z"
          fill="url(#paint9_linear_14261_95867)"
        />
        <path
          d="M21.5305 20.867L21.5306 20.8671L21.5352 20.865C21.7553 20.7672 22.0008 20.7422 22.2484 20.7917L22.2483 20.7918L22.2515 20.7923C22.3207 20.8038 22.3913 20.8271 22.4632 20.8631L22.4696 20.8663L22.4763 20.8685C22.5457 20.8916 22.6166 20.9267 22.689 20.9745L22.7855 21.0518L22.88 21.1274C23.1008 21.363 23.2346 21.6832 23.2346 22.0003C23.2346 22.3175 23.1008 22.6377 22.88 22.8733L22.7855 22.9489L22.689 23.0261C22.6166 23.074 22.5457 23.109 22.4763 23.1322L22.4696 23.1344L22.4632 23.1376C22.3916 23.1734 22.3215 23.1966 22.2526 23.2082C22.1578 23.2217 22.0725 23.2337 22.0013 23.2337C21.6828 23.2337 21.3612 23.0988 21.1254 22.8763C20.9029 22.6404 20.768 22.3188 20.768 22.0003C20.768 21.6822 20.9025 21.3611 21.1245 21.1253C21.2507 21.0122 21.373 20.9276 21.5305 20.867Z"
          stroke="url(#paint10_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter5_bd_14261_95867)">
        <path
          d="M12.6673 28.0002C12.494 28.0002 12.3206 27.9602 12.1606 27.8935C11.9873 27.8269 11.854 27.7335 11.7207 27.6135C11.4807 27.3602 11.334 27.0135 11.334 26.6668C11.334 26.3202 11.4807 25.9735 11.7207 25.7202C11.854 25.6002 11.9873 25.5068 12.1606 25.4402C12.4006 25.3335 12.6673 25.3068 12.934 25.3602C13.014 25.3735 13.094 25.4002 13.174 25.4402C13.254 25.4668 13.334 25.5069 13.414 25.5602C13.4807 25.6135 13.5473 25.6669 13.614 25.7202C13.854 25.9735 14.0007 26.3202 14.0007 26.6668C14.0007 27.0135 13.854 27.3602 13.614 27.6135C13.5473 27.6668 13.4807 27.7335 13.414 27.7735C13.334 27.8268 13.254 27.8669 13.174 27.8935C13.094 27.9335 13.014 27.9602 12.934 27.9735C12.8407 27.9869 12.7607 28.0002 12.6673 28.0002Z"
          fill="url(#paint11_linear_14261_95867)"
        />
        <path
          d="M12.1991 27.8012L12.1991 27.8012L12.1965 27.8002C12.0391 27.7396 11.9167 27.655 11.7905 27.5419C11.5686 27.3061 11.434 26.9849 11.434 26.6668C11.434 26.3487 11.5686 26.0276 11.7905 25.7918C11.9167 25.6787 12.0391 25.5941 12.1965 25.5335L12.1966 25.5336L12.2013 25.5315C12.4213 25.4337 12.6668 25.4087 12.9144 25.4582L12.9144 25.4583L12.9175 25.4588C12.9868 25.4703 13.0573 25.4936 13.1293 25.5296L13.1356 25.5328L13.1424 25.535C13.2117 25.5581 13.2826 25.5932 13.355 25.6411L13.4515 25.7183L13.546 25.7939C13.7669 26.0295 13.9007 26.3497 13.9007 26.6668C13.9007 26.9841 13.7668 27.3043 13.5459 27.5399C13.5239 27.5578 13.501 27.5776 13.4797 27.596C13.469 27.6053 13.4588 27.6142 13.4492 27.6223C13.4166 27.65 13.3884 27.6722 13.3625 27.6877L13.3625 27.6876L13.3585 27.6903C13.2849 27.7394 13.2128 27.7752 13.1424 27.7987L13.1356 27.8009L13.1293 27.8041C13.0577 27.8399 12.9875 27.8631 12.9186 27.8747C12.9085 27.8762 12.8986 27.8776 12.889 27.879C12.8077 27.8908 12.7426 27.9002 12.6673 27.9002C12.5091 27.9002 12.3489 27.8636 12.1991 27.8012Z"
          stroke="url(#paint12_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter6_bd_14261_95867)">
        <path
          d="M17.3333 28.0001C16.9867 28.0001 16.64 27.8534 16.3867 27.6134C16.1467 27.3601 16 27.0134 16 26.6668C16 26.3201 16.1467 25.9734 16.3867 25.7201C16.88 25.2268 17.7867 25.2268 18.28 25.7201C18.52 25.9734 18.6667 26.3201 18.6667 26.6668C18.6667 27.0134 18.52 27.3601 18.28 27.6134C18.0267 27.8534 17.68 28.0001 17.3333 28.0001Z"
          fill="url(#paint13_linear_14261_95867)"
        />
        <path
          d="M17.3333 27.9001C17.0148 27.9001 16.6933 27.7652 16.4574 27.5427C16.2349 27.3068 16.1 26.9853 16.1 26.6668C16.1 26.3478 16.2353 26.0258 16.4583 25.7899C16.6833 25.5654 17.006 25.4501 17.3333 25.4501C17.6607 25.4501 17.9833 25.5654 18.2083 25.7899C18.4314 26.0258 18.5667 26.3478 18.5667 26.6668C18.5667 26.9853 18.4318 27.3068 18.2093 27.5427C17.9734 27.7652 17.6518 27.9001 17.3333 27.9001Z"
          stroke="url(#paint14_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <g filter="url(#filter7_bd_14261_95867)">
        <path
          d="M22.0013 28.0001C21.6546 28.0001 21.308 27.8534 21.0546 27.6134C20.8146 27.3601 20.668 27.0134 20.668 26.6668C20.668 26.3201 20.8146 25.9734 21.0546 25.7201C21.548 25.2268 22.4546 25.2268 22.948 25.7201C23.188 25.9734 23.3346 26.3201 23.3346 26.6668C23.3346 27.0134 23.188 27.3601 22.948 27.6134C22.6946 27.8534 22.348 28.0001 22.0013 28.0001Z"
          fill="url(#paint15_linear_14261_95867)"
        />
        <path
          d="M22.0013 27.9001C21.6828 27.9001 21.3612 27.7652 21.1254 27.5427C20.9029 27.3068 20.768 26.9853 20.768 26.6668C20.768 26.3478 20.9033 26.0258 21.1263 25.7899C21.3513 25.5654 21.6739 25.4501 22.0013 25.4501C22.3287 25.4501 22.6513 25.5654 22.8763 25.7899C23.0994 26.0258 23.2346 26.3478 23.2346 26.6668C23.2346 26.9853 23.0997 27.3068 22.8772 27.5427C22.6414 27.7652 22.3198 27.9001 22.0013 27.9001Z"
          stroke="url(#paint16_linear_14261_95867)"
          strokeOpacity="0.5"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_14261_95867"
          x="-9.61328"
          y="-10"
          width="53.8984"
          height="39.4531"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter1_b_14261_95867"
          x="-19.166"
          y="-8.04639"
          width="73"
          height="65.2134"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter2_bd_14261_95867"
          x="-3.66602"
          y="5.66846"
          width="32.666"
          height="32.665"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter3_bd_14261_95867"
          x="1"
          y="5.66846"
          width="32.666"
          height="32.665"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter4_bd_14261_95867"
          x="5.66797"
          y="5.66699"
          width="32.666"
          height="32.6665"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter5_bd_14261_95867"
          x="-3.66602"
          y="10.3335"
          width="32.666"
          height="32.6665"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter6_bd_14261_95867"
          x="1"
          y="10.3501"
          width="32.666"
          height="32.6499"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <filter
          id="filter7_bd_14261_95867"
          x="5.66797"
          y="10.3501"
          width="32.666"
          height="32.6499"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14261_95867" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.870588 0 0 0 0 0.376471 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_14261_95867" result="effect2_dropShadow_14261_95867" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_14261_95867" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_14261_95867" x1="26.285" y1="4" x2="22.1893" y2="16.1095" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC63E" />
          <stop offset="1" stopColor="#EFA900" />
        </linearGradient>
        <linearGradient id="paint1_linear_14261_95867" x1="27.5906" y1="6.70708" x2="1.12174" y2="8.88878" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint2_linear_14261_95867" x1="7.96108" y1="8.17113" x2="28.2507" y2="8.45429" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint3_linear_14261_95867" x1="26.334" y1="14.4536" x2="16.3097" y2="31.8081" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC63E" />
          <stop offset="1" stopColor="#EFA900" />
        </linearGradient>
        <linearGradient id="paint4_linear_14261_95867" x1="9.15439" y1="18.3425" x2="20.7129" y2="36.5265" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.25" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint5_linear_14261_95867" x1="13.8116" y1="21.1497" x2="10.8412" y2="21.2466" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint6_linear_14261_95867" x1="11.6212" y1="21.5625" x2="13.8856" y2="21.575" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint7_linear_14261_95867" x1="18.4776" y1="21.1497" x2="15.5072" y2="21.2466" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint8_linear_14261_95867" x1="16.2873" y1="21.5625" x2="18.5516" y2="21.575" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint9_linear_14261_95867" x1="23.1456" y1="21.1485" x2="20.1752" y2="21.2454" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint10_linear_14261_95867" x1="20.9552" y1="21.5615" x2="23.2196" y2="21.574" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint11_linear_14261_95867" x1="13.8116" y1="25.815" x2="10.8412" y2="25.9119" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint12_linear_14261_95867" x1="11.6212" y1="26.228" x2="13.8856" y2="26.2405" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint13_linear_14261_95867" x1="18.4776" y1="25.8286" x2="15.5072" y2="25.9261" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint14_linear_14261_95867" x1="16.2873" y1="26.239" x2="18.5516" y2="26.2516" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint15_linear_14261_95867" x1="23.1456" y1="25.8286" x2="20.1752" y2="25.9261" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient id="paint16_linear_14261_95867" x1="20.9552" y1="26.239" x2="23.2196" y2="26.2516" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ContractDelayIcon;
