import React, { useEffect, useState } from 'react';
import { countrCode } from '@pages/changes/utils/helpers/CountryCodes';
import { Button, Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useCreateBranch, useUpdateBranch } from '../../queries/mutation';
import { useBranchId, useGetDistrict, useRegions } from '../../queries/queries';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';

import styles from '../../pages/builderSettings/builderSettings.module.scss';

interface Iprops {
  visible: boolean;
  id: number | null;
  setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: number | null }>>;
}

interface IForm {
  name: string;
  region: number;
  district: number;
  address: string;
  countryCode: string;
}

const CreateBranch: React.FC<Iprops> = ({ id, setVisible, visible }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const [regionId, setRegionId] = useState<number>(0);
  const [countryId, setCountryId] = useState<number>();
  const { data, isLoading } = useBranchId(id as number);
  const countryCode = Form.useWatch('countryCode', form) as string;
  const { data: regions, isLoading: loadingRegions } = useRegions(countryId);
  const { data: districts, isLoading: districtsLoading } = useGetDistrict(regionId, 'region');

  const createBranch = useCreateBranch(setVisible);
  const updateBranch = useUpdateBranch(setVisible);

  // modal title
  const modalTitle = () => {
    if (id) {
      return t('home.Filialni_tahrirlash');
    }

    return t('home.Filial_yaratish');
  };

  // after close
  const afterClose = () => {
    form.resetFields();
    setCountryId(undefined);
    form.setFieldsValue({
      district: undefined,
      region: undefined
    });
  };

  // hondle cancel
  const handleCancel = () => {
    setVisible({ visible: false, id: null });
    afterClose();
  };

  // change region
  const changeRegion = (region: number) => {
    setRegionId(region);
    form.setFieldsValue({
      district: undefined
    });
  };

  // handle submit
  const onFinish = (fields: IForm) => {
    const data = {
      countryCode: fields?.countryCode,
      name: fields.name?.trim(),
      districtID: fields.district,
      address: fields.address
    };

    if (id) {
      updateBranch.mutate({ ...data, id });
    } else {
      createBranch.mutate(data);
    }
  };

  // initial values
  useEffect(() => {
    if (data) {
      const country = countrCode?.find(({ code }) => code === data?.countryCode);

      form.setFieldsValue({
        name: data.name,
        region: data.city.id,
        address: data.address,
        countryCode: data?.countryCode?.toLowerCase(),
        district: data.district.id
      });
      setRegionId(data.city.id);

      if (country) setCountryId(country?.index + 1);
    }
  }, [data, form]);

  return (
    <Modal width={774} title={modalTitle()} visible={visible} onCancel={handleCancel} afterClose={afterClose} footer={false} centered className="createBuilderModal">
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
          <Row>
            <Col flex="1">
              <Form.Item rules={[{ required: true, message: '' }]} name="name" label={t('home.Nomi')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col flex="1">
              <Form.Item label={<span className={styles.inputs_cont_formLabel}>{t('client_create_contract.Mamlakat')}</span>} name="countryCode" rules={[{ required: true }]}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={t('home.Tanlang')}
                  onChange={(_, option: any) => {
                    setCountryId(option?.props);
                    setRegionId(0);

                    form.setFieldsValue({
                      region: undefined,
                      district: undefined
                    });
                  }}
                >
                  {countrCode?.map(item => (
                    <Select.Option key={item?.code} value={item?.code} props={item?.index + 1}>
                      {item[`country_${i18n.language}` as keyof typeof item]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="1">
              <Form.Item label={t('home.Viloyat')} name="region" rules={[{ required: true }]}>
                <Select
                  showSearch
                  loading={loadingRegions}
                  optionFilterProp="children"
                  placeholder={t('home.Tanlang')}
                  onChange={i => changeRegion(i)}
                  disabled={!(countryCode === 'uz' || countryCode === 'tj')}
                >
                  {regions?.map(region => (
                    <Select.Option key={region.id} value={region.id}>
                      {getCurrentLanguageWord(region)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="1">
              <Form.Item label={t('home.Shaxar')} name="district" rules={[{ required: true }]}>
                <Select
                  optionFilterProp="children"
                  disabled={!(countryCode === 'uz' || countryCode === 'tj') || !regionId}
                  showSearch
                  placeholder={t('home.Tanlang')}
                  loading={districtsLoading}
                >
                  {districts?.map(district => (
                    <Select.Option key={district.id} value={district.id}>
                      {getCurrentLanguageWord(district)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col flex="1">
              <Form.Item rules={[{ required: true, message: '' }]} name="address" label={t('home.Manzil')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.modalFooter}>
            <Button onClick={handleCancel}> {t('home.Yopish')}</Button>
            <Button htmlType="submit" type="primary" loading={createBranch.isLoading || updateBranch.isLoading}>
              {t('home.Saqlash')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateBranch;
