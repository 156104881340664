import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { Pagination } from "@utils/models/Pagination";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { OneCompagingCallModel } from "../utils/models/OneCompagingCallModel";

export function useGetAllRobotCalls(compagingId: number, searchParams: any) {
  return useQuery<Pagination<OneCompagingCallModel>>({
    queryKey: [queryKeys.ONE_COMPAGING_CALL, searchParams, compagingId], queryFn: async () => {
      const res = await $api.post(`${endPoints.ONE_COMPAGING_CALL}/${compagingId}`,
        {
          ...searchParams,
          page: searchParams?.page || 1,
          size: searchParams?.size || 8,
          statuses: searchParams?.statuses && (Array.isArray(searchParams?.statuses) ? searchParams?.statuses : [searchParams?.statuses]),
        }
      )

      return res.data
    },
    onError: baseErrorHandler
  })
}

export function useGetCallsStatus(compaginId: number) {
  return useQuery<{
    status: string
    count: number,
  }[]>({
    queryKey: [queryKeys.CALLS_STATUS, compaginId],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.CALLS_STATUS}/${compaginId}`)

      return res.data.data
    },
    onError: baseErrorHandler
  })
}

export function useGetCallsDuration(compaginId: number) {
  return useQuery<{
    duration: number
    count: number,
  }[]>({
    queryKey: [queryKeys.CALLS_DURATION, compaginId],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.CALLS_DURATION}/${compaginId}`)

      return res.data.data
    },
    onError: baseErrorHandler
  })
}