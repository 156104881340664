import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
}

const ArrowLeftMainHeader: React.FC<Props> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5625 11H3.4375" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.625 4.8125L3.4375 11L9.625 17.1875" stroke="#464255" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowLeftMainHeader;
