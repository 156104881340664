import { CrmFilterSaveData } from '../models/CrmFilterSaveData';

export const editCrmFilter = (data: CrmFilterSaveData | undefined, id: number, name: string) => {
  if (data?.active) {
    return {
      active: data?.active,
      filters: data?.filters?.map(item => {
        return {
          ...item,
          name: item?.id === id ? name.trim() : item?.name.trim()
        };
      })
    };
  } else {
    return data;
  }
};
