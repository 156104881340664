import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConversionContext } from '@pages/bossCrm/hooks/ConversionContext';
import DatePicker from '@service/datePicker';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './firstRowHeader.module.scss';

const { RangePicker } = DatePicker;
const FirstRowHeader: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dates = JSON.parse(searchParams.get('dates') as string);
  const { setContext, startDate, endDate } = useContext(ConversionContext);
  const onChange = (__: any, dateString: [string, string] | string) => {
    setContext({ startDate: dateString[0], endDate: dateString[1] });
    searchParams.set('dates', JSON.stringify([dateString[0], dateString[1]]));
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (dates) {
      form.setFieldsValue({
        dates: [dayjs(dates[0], 'DD.MM.YYYY'), dayjs(dates[1], 'DD.MM.YYYY')]
      });
      setContext({ startDate: dates[0], endDate: dates[1] });
    }
  }, []);
  return (
    <div className={styles.f_row_header}>
      <span>{t('bi_analytics.Konversiya')}</span>
      <Form style={{ marginTop: '30px' }} form={form}>
        <Form.Item name="dates">
          <RangePicker
            style={{
              borderRadius: '6px'
            }}
            onChange={onChange}
            placeholder={[t('boss_crm_conversion.boshlanish_sanasi'), t('boss_crm_conversion.tugash_sanasi')]}
            defaultValue={[dayjs(startDate, 'DD.MM.YYYY'), dayjs(endDate, 'DD.MM.YYYY')]}
            format="DD.MM.YYYY"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FirstRowHeader;
