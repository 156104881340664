import React from 'react';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '../../../../../../../assets/icons/ArrowDownIconCrm';
import SidebarArrowRightIcon from '../../../../../../../assets/icons/SidebarArrowRightIcon';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/reduxHooks';
import { usePipeAll } from '../../../../../../../queries/queries';
import { setPipeStatusId } from '../../../../../../../store/reducers/TriggerSlice';
import { TriggerConditionErrorType } from '../../../../../../../utils/models/Trigger';

import styles from './pipeStatus.module.scss';

type Props = {
  mode?: string;
  label?: string;
};

const PipeStatus: React.FC<Props> = ({ mode, label }) => {
  const { t } = useTranslation();

  const pipeStatusId = useAppSelector(state => state.triggerSlice.toPipeStatusId);
  const errorList = useAppSelector(state => state.triggerSlice.errorList);
  const dispatch = useAppDispatch();

  const { data: pipeAll } = usePipeAll();

  // handle change
  const handleChange = (e: number) => {
    dispatch(setPipeStatusId(e));
  };

  // show error
  const showError = () => {
    let errorValue = false;

    errorList.forEach(error => {
      if (error.type === TriggerConditionErrorType.PIPE) {
        errorValue = true;
      }
    });
    return errorValue;
  };

  return (
    <div className={styles.container}>
      <p className={styles.container_title}>{label || t('triggers.Yangi_status')}</p>
      <TreeSelect
        value={pipeStatusId === -1 ? undefined : pipeStatusId}
        placeholder={t('home.Tanlang')}
        onChange={handleChange}
        showArrow={false}
        treeExpandAction="click"
        switcherIcon={<SidebarArrowRightIcon />}
        suffixIcon={<ArrowDownIcon />}
        multiple={!!mode}
        maxTagCount="responsive"
        allowClear={!!mode}
        style={
          // @ts-ignore
          { '--color': showError() ? '#FF3F40' : '#E5E7EB' }
        }
        className={styles.container_tree}
        dropdownClassName="treeMyCals"
      >
        {pipeAll?.map(item => (
          <TreeSelect.TreeNode
            title={item.name}
            value={item.id}
            key={item.id}
            selectable={false}
            children={item.pipeStatuses.map(pipe => (
              <TreeSelect.TreeNode
                key={pipe?.id}
                value={pipe.id!}
                title={
                  <div
                    // @ts-ignore
                    // eslint-disable-next-line react/no-unknown-property
                    pageName={item.name}
                  >
                    <span
                      style={{
                        background: pipe.color,
                        color: '#FFFFFF',
                        padding: '2px 7px',
                        borderRadius: '5px',
                        fontSize: 10
                      }}
                    >
                      {pipe.name}
                    </span>
                  </div>
                }
              />
            ))}
          />
        ))}
      </TreeSelect>
    </div>
  );
};

export default PipeStatus;
