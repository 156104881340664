import { SelectProps } from 'antd';
import { Select } from 'global';

import { useLeadEmployeeAll } from '../hooks';

interface IProps extends SelectProps {}

const CrmEmployeeSelect = ({ placeholder, onChange, value, ...props }: IProps) => {
  const { data } = useLeadEmployeeAll();

  return (
    <Select placeholder={placeholder} value={value} onChange={onChange} {...props}>
      {data?.map(employee => <Select.Option key={employee?.id}>{employee?.fullName}</Select.Option>)}
    </Select>
  );
};

export default CrmEmployeeSelect;
