/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@hooks/reduxHooks';
import DostsSixVertical from '@icons/DotsSixVertical';
import { useCreateDashboard, useUpdateDashboard } from '@pages/dashboard/services/mutations';
import { DashboardModel } from '@pages/dashboard/utils/models/DashboardModel';
import { useTableConfigCustomMutation } from '@queries/mutation';
import { TableKeyCustom } from '@utils/models/TableKey';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import AddCircleIcon from '../../../../assets/icons/AddCircleIcon';
import SuccessIcon from '../../../../assets/icons/SuccessIcon';

import styles from './dashboardDriwer.module.scss';

interface IProps {
  voronkaVisible: boolean;
  setVoronkaVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: DashboardModel[] | undefined;
}

const DashboardDrawer: FC<IProps> = ({ voronkaVisible, setVoronkaVisible, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableConfig = useTableConfigCustomMutation(TableKeyCustom.dashboard_id);
  const [createInputValue, setCreateInputValue] = useState('');
  const [createVoronkaVisible, setCreateVoronkaVisible] = useState(false);
  const [updateDashboardId, setUpdateDashboardId] = useState(0);
  const [dashboardList, setDashboardList] = useState<DashboardModel[] | undefined>(data);
  const createDashboard = useCreateDashboard();
  const updateDashboard = useUpdateDashboard();
  const inputRef = useRef<any>(null);
  const createRef = useRef<any>(null);
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);

  const handleClose = () => {
    setVoronkaVisible(false);
    setCreateVoronkaVisible(false);
    setUpdateDashboardId(0);
  };

  const createVoronkaVisibled = () => {
    setCreateVoronkaVisible(true);
  };

  const createInputChage = (e: any) => {
    setCreateInputValue(e.target.value);
  };

  const handleCreateDashboard = (value: DashboardModel) => {
    createDashboard.mutateAsync({ ...value, order: data?.length! }).then(() => {
      setCreateVoronkaVisible(false);
      setCreateInputValue('');
    });
  };

  const handleNavigate = (id: number) => {
    tableConfig.mutate({ key: TableKeyCustom.dashboard_id, value: String(id) });
    navigate(`${window.location.pathname}?dashboardId=${id}`);
  };
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!result.destination) {
      return;
    }
    const dashboardListCopy = [...dashboardList!];
    const [removed] = dashboardListCopy.splice(source.index, 1);

    dashboardListCopy.splice(destination?.index!, 0, removed);
    setDashboardList(dashboardListCopy);
    updateDashboard.mutate([
      ...dashboardListCopy?.map((item, index) => ({
        id: item?.id!,
        name: item?.name!,
        order: index!
      }))
    ]);
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    margin: `0 0 8px 0`,
    background: isDragging ? '#ccc' : '#fff',
    width: '100%',
    borderBottom: '1px solid #ececec',
    paddingLeft: '12px',
    ...draggableStyle
  });

  const changeDashboardName = (e: any) => {
    setDashboardList(
      dashboardList?.map(item => ({
        ...item,
        name: item.id === updateDashboardId ? e.target.value : item.name
      }))
    );
  };

  const handleUpdateDashboard = (id: number, name: string, order: number) => {
    updateDashboard.mutateAsync([{ id, name, order }]).then(() => setUpdateDashboardId(0));
  };

  useEffect(() => {
    setDashboardList(data);
  }, [data]);

  return (
    <div
      className={styles.voronka}
      style={{
        opacity: voronkaVisible ? 1 : 0,
        pointerEvents: voronkaVisible ? 'inherit' : 'none'
      }}
      onMouseMove={e => e.stopPropagation()}
      onMouseDown={e => e.stopPropagation()}
    >
      <div className={styles.voronka_container}>
        <h1>Dashboardlar</h1>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {dashboardList?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Draggable key={index} draggableId={String(index)} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                        <div className={styles.tableSettingsModal_body_columns_item}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {updateWidget && (
                              <span {...provided.dragHandleProps}>
                                <DostsSixVertical />
                              </span>
                            )}
                            <div
                              className={styles.voronka_container_items_item}
                              onClick={() => {
                                setVoronkaVisible(false);
                                handleNavigate(Number(item?.id));
                              }}
                            >
                              <span>
                                {updateDashboardId === item.id ? (
                                  <input
                                    style={{ width: 160 }}
                                    value={item.name}
                                    id={item.name + item.id}
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus={true}
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    className={styles.voronka_container_item_editable}
                                    onChange={changeDashboardName}
                                    onKeyPress={e => e.key === 'Enter' && handleUpdateDashboard(item?.id!, item?.name, item?.order!)}
                                    ref={createRef}
                                  />
                                ) : item.name.length > 10 ? (
                                  `${item.name.slice(0, 10)}...`
                                ) : (
                                  item.name
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {createVoronkaVisible && voronkaVisible ? (
          <div
            className={styles.voronka_container_create}
            style={{ cursor: 'default' }}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <input
              ref={inputRef}
              value={createInputValue}
              onChange={createInputChage}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              required
              onKeyPress={e => createInputValue && e.key === 'Enter' && handleCreateDashboard({ name: createInputValue })}
            />
            <span onClick={() => createInputValue && handleCreateDashboard({ name: createInputValue })}>
              <SuccessIcon />
            </span>
          </div>
        ) : (
          <div className={styles.voronka_container_create} onClick={createVoronkaVisibled}>
            <AddCircleIcon />
            {t('crm.Yangi_qoshish')}
          </div>
        )}
        <div
          style={{ height: '100%' }}
          onClick={() => {
            setUpdateDashboardId(0);
            setCreateVoronkaVisible(false);
            setCreateInputValue('');
          }}
        />
      </div>
      <div className={styles.voronka_mask} onClick={handleClose} />
    </div>
  );
};

export default DashboardDrawer;
