import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Broom from '../../assets/icons/Broom';
import Filter from '../../assets/icons/Filter';
import FilterItemClose from '../../assets/icons/FilterItemClose';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useRegions } from '../../queries/queries';
import styles from '../filters/mainFilters.module.scss';
import BrokerFilterDrawer from './BrokerFilterDrawer';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { BaseLanguageEntity } from '../../utils/models/BaseLanguageEntity';
import { CloseOutlined } from '@ant-design/icons';
import { SearchParamsBroker } from '../../pages/broker/Broker';

const BrokerFilter = () => {
  const { searchParams, remove, removeAllSearchParams, appendSingle } = useQueryParam<SearchParamsBroker, any>();
  const { data: regions } = useRegions();
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [countFilters, setCountFilters] = useState(0);

  useEffect(() => {
    let count = 0;
    if (searchParams.cities) {
      if (Array.isArray(searchParams.cities)) {
        count = searchParams.cities.length;
      } else {
        count = 1;
      }
    }
    setCountFilters(count);
  }, [searchParams]);

  const itemHas = () => {
    const newSearchParams = { ...searchParams };
    delete newSearchParams.page;
    delete newSearchParams.size;
    delete newSearchParams.search;
    delete newSearchParams.order;
    return Object.keys(newSearchParams).length > 0;
  };

  const handleFilter = () => {
    setFilterDrawerVisible(true);
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const showCities = () => {
    if (searchParams.cities) {
      if (!Array.isArray(searchParams.cities)) {
        const cities: BaseLanguageEntity | undefined = regions?.find(house => String(house.id) === (searchParams.cities as any));
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{cities && getCurrentLanguageWord(cities)}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('cities', searchParams.cities as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } else {
        return searchParams.cities.map(cityId => {
          const cities = regions?.find(house => String(house.id) === (cityId as any));
          return (
            <div className={`${styles.item} d-flex align-center`} key={cityId}>
              <span className={styles.title}> {cities && getCurrentLanguageWord(cities)}</span>
              <FilterItemClose onClick={() => deleteOneFilter('cities', cityId)} className={styles.icon} />
            </div>
          );
        });
      }
    }
    return null;
  };

  const showFilters = () => {
    const cities = showCities();
    return <>{cities}</>;
  };

  const deleteOneFilter = (key: string, value: string) => {
    remove(key, value);
  };

  const clearAllFilter = () => {
    removeAllSearchParams();
  };

  const { t } = useTranslation();

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.cont}>
      <BrokerFilterDrawer visible={filterDrawerVisible} setVisible={setFilterDrawerVisible} />
      <div className={'d-flex align-center'}>
        <div
          onClick={() => handleFilter()}
          className={`${countFilters > 0 ? styles.filter_button + ' ' + styles.active : styles.filter_button} d-flex align-center`}
        >
          <Filter />
          <span className={styles.title}>{t('home.Filtr')}</span>
          {countFilters > 0 && <span className={styles.count_filters}>{countFilters}</span>}
        </div>
        <Input
          value={searchParams.search}
          onChange={e => handleSearch(e.target.value)}
          suffix={showIconInput()}
          placeholder={t('home.Qidirish')}
          className={styles.search}
        />
        {itemHas() ? (
          <div className={`${styles.filtered_items_cont_right} d-flex align-center`}>
            {showFilters()}
            <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
              <Broom />
            </div>
            <div className={styles.line} />
          </div>
        ) : (
          <div className={styles.not_select_title_right}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
        )}
      </div>
      {itemHas() ? (
        <div className={`${styles.filtered_items_cont} d-flex align-center`}>
          {showFilters()}
          <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
            <Broom />
          </div>
          <div className={styles.line} />
        </div>
      ) : (
        <div className={styles.not_select_title}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
      )}
    </div>
  );
};

export default BrokerFilter;
