import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { Branch } from '../../utils/models/BranchSetting';
import { TableConfig } from '../../utils/models/TableConfig';

import '../../pages/settingBranch/SettingBranch.module.css';

interface Props {
  data: Branch[];
  isLoading: boolean;
  tableConfig: TableConfig[];
  tableConfigFetch: boolean;
  isFetching: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; id: number | null }>>;
}

const TableBranch: React.FC<Props> = ({ isLoading, tableConfigFetch, tableConfig, data, isFetching, setModalVisible }) => {
  const { t } = useTranslation();

  const initialColumn: ColumnsType<{}> | undefined = [
    {
      title: t('home.Filial_nomi'),
      dataIndex: 'name',
      align: 'left'
    },
    {
      title: t('home.Manzil'),
      align: 'left',
      render: (branch: Branch) => (
        <>
          {getCurrentLanguageWord(branch.district)},&nbsp;{branch.address}
        </>
      )
    },
    {
      title: t('home.Masul'),
      dataIndex: 'createdBy'
    },
    {
      title: t('home.Yaratilgan_sana'),
      dataIndex: 'createdDate'
    }
  ];
  const [column, setColumn] = useState(initialColumn);

  useEffect(() => {
    if (tableConfig.length > 0 && !tableConfigFetch) {
      const newConfig: any[] = [];

      tableConfig.forEach(item => {
        if (item.checked) {
          switch (item.name) {
            case 'Filial_nomi':
              newConfig.push({
                title: t('home.Filial_nomi'),
                dataIndex: 'name',
                align: 'left'
              });
              break;
            case 'Masul':
              newConfig.push({
                title: t('home.Masul'),
                dataIndex: 'createdBy'
              });
              break;
            case 'Yaratilgan_sana':
              newConfig.push({
                title: t('home.Yaratilgan_sana'),
                dataIndex: 'createdDate'
              });
              break;
            case 'Manzil':
              newConfig.push({
                title: t('home.Manzil'),
                align: 'left',
                render: (branch: Branch) => (
                    <>
                      {getCurrentLanguageWord(branch.district)},&nbsp;
                      {branch.address}
                    </>
                  )
              });
              break;
            default:
              break;
          }
        }
      });
      setColumn(newConfig);
    }
  }, [tableConfig, tableConfigFetch, t]);

  return (
    <Table
      className="custom_table contract_table_size branch_table"
      columns={column}
      dataSource={data}
      loading={isLoading || isFetching || tableConfigFetch}
      pagination={false}
      onRow={(record: any) => ({
          onClick: () => {
            setModalVisible({ visible: true, id: record.id });
          }
        })}
    />
  );
};

export default TableBranch;
