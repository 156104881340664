import React from 'react';

const CloseIcon = (props: any) => {
  return (
    <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 7L7 25" stroke="#464255" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25 25L7 7" stroke="#464255" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CloseIcon;
