import React from 'react';

const CaretRightBigIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 6L22 16L12 26" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CaretRightBigIcon;
