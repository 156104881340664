import React, { useState } from 'react';
import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';

import { ClientExterminateModal } from '../components';

import Context, { IContext } from './context';

interface IProps {
  children: React.ReactNode;
}

const Provider = ({ children }: IProps) => {
  const [exterminate, setExterminate] = useState<IContext['exterminate']>({
    id: undefined,
    name: ''
  });

  const permissions = useAppSelector(state => state?.userReducer?.user?.role?.permissions);

  const getPermissions = (permissions || [])?.reduce<IContext['permissions']>(
    (p, c) => {
      p.PERMISSION_EXTERMINATE_CLIENT = c === Permissions.PERMISSION_EXTERMINATE_CLIENT || !!p.PERMISSION_EXTERMINATE_CLIENT;
      p.PERMISSION_SEND_MESSAGE_TO_CLIENT = c === Permissions.PERMISSION_SEND_MESSAGE_TO_CLIENT || !!p.PERMISSION_SEND_MESSAGE_TO_CLIENT;
      p.PERMISSION_UPDATE_CLIENT = c === Permissions.PERMISSION_UPDATE_CLIENT || !!p.PERMISSION_UPDATE_CLIENT;

      return p;
    },
    {} as IContext['permissions']
  );

  return (
    <Context.Provider
      value={{
        exterminate,
        setExterminate,
        permissions: getPermissions
      }}
    >
      {children}
      <ClientExterminateModal />
    </Context.Provider>
  );
};

export default Provider;
