import React, { useEffect } from 'react';
import { useGetPaymentCustomType } from '@components/paymentSettings/paymentWays/custom-payment/service/queries';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { t } from 'i18next';

import { useAutoPrintClientBalanceCheck, useDebitCreate, useDownloadClientPaymentToWord } from '../../queries/mutation';
import { useCurrencyList, usePaymentConfig } from '../../queries/queries';
import { helper } from '../../utils/helper/helper';
import { ClientBalanceHistoryList } from '../../utils/models/Client';
import { PaymentType } from '../../utils/models/PaymentType';

import styles from './clientBalance.module.scss';

const { Option } = Select;

interface Props {
  visible: boolean;
  close: () => void;
  type: 'in' | 'out';
  clientId: string;
}

interface Values {
  amount: string;
  type: PaymentType;
  note: string;
  balanceCurrencyId: number;
  currencyId: number;
}

const CreateDebitModal: React.FC<Props> = ({
  // balance,
  visible,
  close,
  type,
  clientId
}) => {
  const [form] = Form.useForm<Values>();
  const createDebit = useDebitCreate(clientId, type, handleClose);
  const { data: currency, isLoading: loadingCurrency } = useCurrencyList();

  const selectedPaymentType = Form.useWatch('type', form);


  const { data, isLoading } = usePaymentConfig();
  const { data: dataPaymentCustomType } = useGetPaymentCustomType();

  const filteredPaymentCustomType = dataPaymentCustomType?.filter(item => item?.status === true);

  const downloadPayment = useDownloadClientPaymentToWord();
  const autoPrintCheck = useAutoPrintClientBalanceCheck();

  useEffect(() => {
    form.setFieldsValue({
      balanceCurrencyId: currency?.find(item => item.basic)?.id,
      currencyId: currency?.find(item => item.basic)?.id
    });
  }, [currency, form]);

  const printAutoCheck = (id: number) => {
    const type: PaymentType = form.getFieldValue('type');

    if (type !== PaymentType.BANK) {
      autoPrintCheck.mutate({
        id
      });
    }
  };

  function handleClose(data?: ClientBalanceHistoryList) {
    if (data) {
      downloadPayment.mutate({
        id: data.id,
        date: data.createdDate
      });
      printAutoCheck(data.id);
    }
    setTimeout(() => {
      form.resetFields();
      form.setFieldsValue({
        balanceCurrencyId: currency?.find(item => item.basic)?.id,
        currencyId: currency?.find(item => item.basic)?.id
      });
      close();
    }, 0);
  }

  const handleSubmit = (val: Values) => {
    const debitdata = {
      amount: helper.parseFloatStringToNumber(val.amount),
      type: val.type,
      note: form.getFieldValue('note'),
      currencyId: val.currencyId,
      balanceCurrencyId: val.balanceCurrencyId
    }

    // @ts-expect-error
    const selectedCustomType = filteredPaymentCustomType?.find(item => item?.id === selectedPaymentType);

    if (selectedCustomType) {
      debitdata.type = PaymentType?.CUSTOM;
      // @ts-expect-error
      debitdata.paymentCustomTypeId = selectedCustomType.id;
    }

    createDebit.mutate(debitdata);
  };

  return (
    <Modal
      title={type === 'in' ? t('client_balance.Mablag_kiritish') : t('client_balance.Mablag_chiqarish')}
      className={styles.debitModal}
      visible={visible}
      width={432}
      onCancel={() => handleClose()}
      footer={null}
    >
      <div className={styles.body}>
        <Form
          form={form}
          name="debit_form"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          layout="vertical"
          className={styles.createOrderForm}
        >
          <Form.Item
            label={
              <p
                style={{
                  color: 'var(--philippine-gray)',
                  fontSize: '14px'
                }}
              >
                {type === 'out' ? t('create_payment.Qaysi_valyutadan') : t('create_payment.Qaysi_valyutaga')}
              </p>
            }
          >
            <Form.Item noStyle name="balanceCurrencyId" rules={[{ required: true, message: '' }]}>
              <Select loading={loadingCurrency} style={{ border: '6px' }} className="customSelect">
                {currency?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.ccy}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <p
                style={{
                  color: 'var(--philippine-gray)',
                  fontSize: '14px'
                }}
              >
                {t('home.Tolov_summasi_sum')}
              </p>
            }
          >
            <Form.Item
              noStyle
              name="amount"
              rules={[
                {
                  required: true,
                  message: ''
                },
                {
                  validator: (rule, value) => {
                    if (value === undefined || value === null) {
                      return Promise.reject(t('client_balance.Summa_kiritilmagan'));
                    }
                    const amount = helper.deleteNotNumbersAndParseFloatNumber(value);

                    form.setFieldsValue({
                      amount: amount.string
                    });
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input className="customInput" />
            </Form.Item>
          </Form.Item>
          <Row gutter={[8, 0]}>
            <Col span={12}>
              <Form.Item
                label={
                  <p
                    style={{
                      color: 'var(--philippine-gray)',
                      fontSize: '14px'
                    }}
                  >
                    {t('home.Tulov_turi')}
                  </p>
                }
              >
                <Form.Item noStyle name="type" rules={[{ required: true, message: "To'lov turi tanlanmagan" }]}>
                  <Select loading={isLoading} style={{ border: '6px' }} className="customSelect">
                    {data?.types.map((item, index) => (
                      <Option key={index} value={PaymentType[item]}>
                        {t(`home.${PaymentType[item]}`)}
                      </Option>
                    ))}

                    {filteredPaymentCustomType?.map((item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <p
                    style={{
                      color: 'var(--philippine-gray)',
                      fontSize: '14px'
                    }}
                  >
                    {t('create_payment.Valyuta')}
                  </p>
                }
              >
                <Form.Item noStyle name="currencyId" rules={[{ required: true, message: '' }]}>
                  <Select loading={loadingCurrency} style={{ border: '6px' }} className="customSelect">
                    {currency?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.ccy}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Form.Item noStyle name="note">
              <Input.TextArea placeholder={t('flat_condition.Izoh_qoldirish')} autoSize={{ minRows: 4, maxRows: 4 }} className="customInput" />
            </Form.Item>
          </Form.Item>
          <Button loading={createDebit.isLoading} htmlType="submit" className={styles.body_pay_simple} type="primary">
            {t('client_balance.Tolov_qilish')}
          </Button>
          <Button onClick={() => handleClose()} className={styles.body_close_button} type="primary">
            {t('arrearage.Yopish')}
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateDebitModal;
