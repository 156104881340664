/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-template */
/* eslint-disable newline-after-var */
import React, { useEffect, useState } from 'react';
import { Permissions } from '@utils/constants/permissions';
import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import CalendarIcon from '../../../../assets/icons/CalendarIcon';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useContractField, useCurrencyList } from '../../../../queries/queries';
import { helper } from '../../../../utils/helper/helper';
import { validateDateString } from '../../../../utils/helper/validatorDateString';
import { ChangesPageFlats } from '../../../../utils/models/ContractId';
import { CrmFieldsType } from '../../../../utils/models/CrmFieldsType';
import { CustomFieldShowData } from '../../../../utils/models/CustomField';
import { useCreateAndUpdateContract } from '../../services/mutation';
import { useEmployeeChanges } from '../../services/queries';

import FlatInformation from './FlatInformation';

import styles from './Contract.module.scss';

export interface IFormContractChanges {
  number: string;
  createdDate: string;
  amount: string;
  prepayment: string;
  discount: string;
  discountDescription: string;
  delay: string;
  repaired: boolean;
  comment: string;
  clientId: number;
  flatId: number;
  id: number;
  prepaymentDate: string;
  responsibleBy: number;
  currencyId: number;
}

interface useParamsSearch {
  clientId?: string;
  page?: string;
}

const ContractInformation = () => {
  const [form] = Form.useForm();
  const { data: currency } = useCurrencyList();
  const permissions = useAppSelector(state => state.userReducer?.user?.role?.permissions);

  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  const { isLoading: loadingFields, data } = useContractField();
  const { isLoading: employeeLoading, data: employeeData } = useEmployeeChanges();

  const [flats, setFlats] = useState<ChangesPageFlats[]>([
    {
      flatNumber: '',
      repaired: false,
      flatId: -1,
      buildingId: -1,
      houseId: -1
    }
  ]);
  const { searchParams } = useQueryParam<useParamsSearch, any>();

  const createAndUpdateContract = useCreateAndUpdateContract(searchParams.clientId);

  const selectedContract = useAppSelector(state => state.changesReducer.selectedContract);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedContract) {
      let formObj = {
        number: selectedContract.number,
        amount: selectedContract.amount.toLocaleString('ru'),
        prepayment: selectedContract.prepayment.toLocaleString('ru'),
        discount: selectedContract.discount !== 0 ? selectedContract.discount?.toLocaleString('ru') : undefined,
        delay: String(selectedContract.delay),
        createdDate: selectedContract.createdDate,
        comment: selectedContract.comment,
        discountDescription: selectedContract.discountDescription,
        prepaymentDate: selectedContract.prepaymentDate,
        responsibleBy: selectedContract.responsibleBy,
        currencyId: selectedContract.currencyId,
        mortgageAmount: selectedContract.mortgageAmount?.toLocaleString('ru'),
        mortgageDate: selectedContract.mortgageDate || ''
      };

      selectedContract?.fields?.forEach(element => {
        formObj = {
          ...formObj,
          [String(element.fieldId)]: element.value
        };
      });
      selectedContract.flats.forEach((flat, index) => {
        formObj = {
          ...formObj,
          [`${index}houseId`]: flat.houseId,
          [`${index}buildingId`]: flat.buildingId,
          [`${index}flatId`]: flat.flatId
        };
      });

      setFlats(
        selectedContract.flats?.map(item => ({
          ...item,
          id: item?.flatId
        }))
      );
      form.setFieldsValue(formObj);
    } else {
      form.resetFields();
    }
  }, [selectedContract, form]);

  const onFinish = (values: any) => {
    const fields: CustomFieldShowData[] = [];

    if (data && data.length > 0) {
      for (const key in values) {
        const field = data.find(element => element.id === Number(key));
        if (field && values[key]) {
          fields.push({
            value: values[key],
            fieldId: Number(key),
            name: field.name
          });
        }
      }
    }

    createAndUpdateContract.mutate({
      number: values.number,
      createdDate: values.createdDate,
      amount: helper.parseFloatStringToNumber(values?.amount),
      prepayment: helper.parseFloatStringToNumber(values?.prepayment),
      discount: values?.discount ? helper.parseFloatStringToNumber(values?.discount) : undefined,
      discountDescription: values.discountDescription,
      delay: Number(values.delay),
      comment: values.comment,
      clientId: searchParams.clientId,
      flats,
      id: selectedContract?.id,
      prepaymentDate: values.prepaymentDate,
      fields,
      responsibleBy: values.responsibleBy,
      currencyId: values.currencyId,
      ...(isMultiPayment && {
        mortgageAmount: helper.parseFloatStringToNumber(values?.mortgageAmount || ''),
        mortgageDate: values?.mortgageDate
      })
    });
  };

  // change house
  const changeHouse = (index: number, id: number) => {
    const newFlats = [...flats];

    newFlats[index] = {
      ...flats[index],
      houseId: id,
      buildingId: -1,
      flatId: -1
    };
    form.setFieldsValue({
      [`${index}houseId`]: id,
      [`${index}buildingId`]: undefined,
      [`${index}flatId`]: undefined
    });
    setFlats(newFlats);
  };

  // change building
  const changeBuilding = (index: number, id: number) => {
    const newFlats = [...flats];
    newFlats[index] = {
      ...flats[index],
      buildingId: id,
      flatId: -1
    };
    form.setFieldsValue({
      [index + 'buildingId']: id,
      [index + 'flatId']: undefined
    });
    setFlats(newFlats);
  };

  // changeFlat
  const changeFlat = (index: number, id: number) => {
    const newFlats = [...flats];
    newFlats[index] = {
      ...flats[index],
      flatId: id
    };
    form.setFieldsValue({
      [index + 'flatId']: id
    });
    setFlats(newFlats);
  };

  // change repaired
  const changeRepaired = (index: number, repaired: boolean) => {
    const newFlats = [...flats];
    newFlats[index] = {
      ...flats[index],
      repaired
    };
    setFlats(newFlats);
  };

  // delete flat
  const deleteFlat = (index: number) => {
    const newFlats: ChangesPageFlats[] = [];
    flats.forEach((item, i) => {
      if (i !== index) {
        newFlats.push(item);
      }
    });
    newFlats.forEach((item, i) => {
      form.setFieldsValue({
        [i + 'houseId']: item.houseId === -1 ? undefined : item.houseId,
        [i + 'buildingId']: item.buildingId === -1 ? undefined : item.buildingId,
        [i + 'flatId']: item.flatId === -1 ? undefined : item.flatId
      });
    });
    setFlats(newFlats);
  };

  // add new flat
  const addNewFlat = () => {
    const newFlats = [...flats];
    newFlats.push({
      flatNumber: '',
      repaired: false,
      flatId: -1,
      buildingId: -1,
      houseId: -1
    });
    const index = newFlats.length - 1;
    form.setFieldsValue({
      [index + 'houseId']: undefined,
      [index + 'buildingId']: undefined,
      [index + 'flatId']: undefined
    });
    setFlats(newFlats);
  };

  return (
    <div className={styles.contractInformation}>
      <h1>{t("changes.contract.Shartnoma_ma'lumotlari")}</h1>
      <Form form={form} onFinish={onFinish} layout={'vertical'}>
        <Row gutter={[8, 0]}>
          <Col span={8}>
            <Form.Item rules={[{ required: true, message: '' }]} name={'number'} className="changesInput" label={t('changes.contract.Shartnoma_raqami')}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'amount'}
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        // @ts-ignore
                        amount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                      });
                    }
                    return Promise.resolve();
                  }
                },
                { required: true, message: '' }
              ]}
              className="changesInput"
              label={t('changes.contract.Shartnoma_summasi')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item rules={[{ required: true, message: '' }]} name={'currencyId'} className="changesInput" label={'Valyuta'}>
              <Select showSearch optionFilterProp={'children'} loading={employeeLoading} className="selectRoomInformation">
                {currency?.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Option key={i} value={item.id}>
                    {item.ccy}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[4, 0]}>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        // @ts-ignore
                        prepayment: helper.deleteNotNumbersAndParseFloatNumber(value).string
                      });
                    }
                    return Promise.resolve();
                  }
                },
                { required: true, message: '' }
              ]}
              name={'prepayment'}
              className="changesInput"
              label={t("changes.contract.Boshlang'ich_to'lovi")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        // @ts-ignore
                        discount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                      });
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              name={'discount'}
              className="changesInput"
              label={t('changes.contract.Chegirma')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item rules={[{ required: true, message: '' }]} name={'responsibleBy'} className="changesInput" label={t('create_table_payments.Masul_xodim')}>
              <Select showSearch optionFilterProp={'children'} loading={employeeLoading} className="selectRoomInformation">
                {employeeData?.map((employee, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Option key={i} value={employee.id}>
                    {employee.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className={styles.Col} flex={1}>
            <Form.Item name={'discountDescription'} className="changesInput" label={t('changes.contract.Chegirma_izohi')}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className={styles.Col} flex={1}>
            <Form.Item
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        // @ts-ignore
                        delay: String(helper.deleteNotNumbers(value, 0))
                      });
                    }
                    return Promise.resolve();
                  }
                },
                { required: true, message: '' }
              ]}
              name={'delay'}
              className="changesInput"
              label={t('changes.contract.Shartnoma_muddati_(oy)')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className={styles.Col} flex={1}>
            <Form.Item
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        createdDate: validateDateString(value, true)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
              name={'createdDate'}
              className="changesInput"
              label={t('changes.contract.Shartnoma_yaratilgan_sana')}
            >
              <InputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar={''}
              >
                {/* @ts-ignore */}
                {(inputProps: any) => <Input className="customInput" placeholder={t('changes.contract.kk.oo.yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
          <Col className={styles.Col} flex={1}>
            <Form.Item
              rules={[
                {
                  validator: (rule, value) => {
                    if (value) {
                      form.setFieldsValue({
                        prepaymentDate: validateDateString(value, true)
                      });
                    }
                    return Promise.resolve();
                  }
                },
                {
                  required: true,
                  len: 10,
                  message: ''
                }
              ]}
              name={'prepaymentDate'}
              className="changesInput"
              label={t('changes.contract.Boshlangich_tolov_sanasi')}
            >
              <InputMask
                mask="99.99.9999"
                // @ts-ignore*
                maskChar={''}
              >
                {/* @ts-ignore */}
                {(inputProps: any) => <Input className="customInput" placeholder={t('changes.contract.kk.oo.yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
              </InputMask>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col className={styles.Col} flex={1}>
            <Form.Item name={'comment'} className="changesInput" label={t('changes.contract.Shartnoma_izohi')}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
            </Form.Item>
          </Col>
        </Row>
        <Spin spinning={loadingFields}>
          <Row gutter={[4, 0]}>
            {data?.map((field, index) => {
              if (field.type === CrmFieldsType.TEXT)
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col key={index} span={12}>
                    <Form.Item name={String(field.id)} className="changesInput" label={field.name} rules={[{ required: Boolean(field?.required), message: '' }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                );
              return (
                // eslint-disable-next-line react/jsx-key
                <Col span={12}>
                  <Form.Item name={String(field.id)} className="changesInput" label={field.name} rules={[{ required: Boolean(field?.required), message: '' }]}>
                    <Select className="selectRoomInformation">
                      {field.options?.map((option, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Select.Option key={i} value={option.name}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Spin>
        {isMultiPayment && selectedContract?.mortgageAmount ? (
          <Row>
            <Col className={styles.Col} flex={1}>
              <Form.Item
                name={'mortgageAmount'}
                className="changesInput"
                label={t('contract.Ipoteka_summasi')}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (value) {
                        form.setFieldsValue({
                          // @ts-ignore
                          mortgageAmount: helper.deleteNotNumbersAndParseFloatNumber(value).string
                        });
                      }
                      return Promise.resolve();
                    }
                  },
                  { required: false, message: '' }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className={styles.Col} flex={1}>
              <Form.Item
                name={'mortgageDate'}
                className="changesInput"
                label={t('contract.Ipoteka_sanasi')}
                rules={[
                  {
                    validator: (rule, value) => {
                      if (form.getFieldValue('mortgageAmount') && !value) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    }
                  },
                  {
                    required: false,
                    len: 10,
                    message: ''
                  }
                ]}
              >
                <InputMask
                  mask="99.99.9999"
                  // @ts-ignore*
                  maskChar={''}
                >
                  {/* @ts-ignore */}
                  {(inputProps: any) => <Input className="customInput" placeholder={t('changes.contract.kk.oo.yyyy')} suffix={<CalendarIcon />} {...inputProps} />}
                </InputMask>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        <div className={styles.roomInformation}>
          <h1>{t("changes.contract.Xonadon_ma'lumotlari")}</h1>
          {flats.map((item, index) => (
            <FlatInformation
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              deleteFlat={deleteFlat}
              length={flats.length}
              changeRepaired={changeRepaired}
              changeHouse={changeHouse}
              changeFlat={changeFlat}
              changeBuilding={changeBuilding}
              flat={item}
              index={index}
              isLastOne={index === flats.length - 1}
              addNew={addNewFlat}
            />
          ))}
        </div>
        <Button
          loading={createAndUpdateContract.isLoading}
          className="customFormButton"
          style={{
            backgroundColor: '#00a389',
            color: 'white',
            margin: '0 auto',
            display: 'block'
          }}
          htmlType="submit"
        >
          {selectedContract ? t('changes.contract.O`zgartirish') : t('changes.contract.Yaratish')}
        </Button>
      </Form>
    </div>
  );
};

export default ContractInformation;
