import { helper } from "./helper";

export const toHHMMSS = (time: string) => {
    const t = helper.deleteNotNumbersToNumber(time).number;
    const sec_num = parseInt(String(Math.round(t)), 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    const seconds = sec_num - hours * 3600 - minutes * 60;
  
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }