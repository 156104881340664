import { CrmFieldOption } from '@utils/models/CrmField';
import { CrmFieldsType } from '@utils/models/CrmFieldsType';
import { Select } from 'antd';
import DatePickerCustom from './components/date-picker-custom/DatePickerCustom';
import CustomInput from './components/custom-input/CustomInput';

const { Option } = Select;

export const CustomFields: {
  [key in CrmFieldsType]?: (props?: {
    hasValue?: boolean;
    value?: any;
    placeholder?: string;
    options?: CrmFieldOption[];
    onChange?: (value: string) => void;
    suffixIcon?: React.ReactNode;
  }) => JSX.Element;
} = {
  TEXT: props => {
    return <CustomInput {...props} />;
  },
  SELECT: props => {
    return (
      <Select
        {...((props?.hasValue || props?.value) && { value: props?.value })}
        placeholder={props?.placeholder}
        className="customSelect"
        style={{ width: '100%' }}
        allowClear
        suffixIcon={props?.suffixIcon}
        onChange={e => {
          if (props?.onChange) {
            props?.onChange(e);
          }
        }}
        onClear={() => {
          props?.onChange && props?.onChange('');
        }}
      >
        {props?.options?.map(item => <Option value={String(item?.id)}>{item?.name}</Option>)}
      </Select>
    );
  },
  DATE: props => {
    return (
      <DatePickerCustom
        onChange={e => {
          if (props?.onChange) {
            props?.onChange(e);
          }
        }}
        {...((props?.hasValue || props?.value) && { value: props?.value })}
      />
    );
  }
};
