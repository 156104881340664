import { $api } from '@service/RequestService';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { endPoints, ITableRequestNumber, ITableRequestString, models, queryKeys, sendModel } from './utils';

export function useTable(key?: string): UseMutationResult<any, unknown, ITableRequestString, unknown>;
export function useTable(key?: string, model?: 'string'): UseMutationResult<any, unknown, ITableRequestString, unknown>;
export function useTable(key?: string, model?: 'number'): UseMutationResult<any, unknown, ITableRequestNumber, unknown>;

export function useTable(key?: string, model?: keyof typeof models) {
  const qc = useQueryClient();

  return useMutation(
    async (request: any) => {
      const res = await $api.post(endPoints.TABLE, sendModel({ allUser: false, ...request }, model));

      return res.data.data;
    },
    {
      onSuccess: () => {
        if (key) {
          qc.invalidateQueries([queryKeys.TABLE_KEY(key)]);
        }
      }
    }
  );
}
