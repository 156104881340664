import React, { ChangeEventHandler, useState } from 'react';

import styles from './restorePassword.module.scss';
import { Button, Form, Input } from 'antd';
import PasswordValidationPopover from '../../passwordValidationPopover/PasswordValidationPopover';
import { PasswordValidationColor } from '../../../utils/models/PasswordValidationColor';
import ArrowLeftIcon from '../../../assets/icons/contract/ArrowLeftIcon';
import { useChangePassword } from '../../../queries/mutation';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { rootPaths } from '../../../routing/rootPaths';
import { useTranslation } from 'react-i18next';

interface IForm {
  password: string;
  confirmPassword: string;
}

interface Props {
  changePage: (page: number) => void;
  user: { phone: string; username: string };
}

const RestorePassword: React.FC<Props> = ({ changePage, user }) => {
  const [form] = Form.useForm<IForm>();
  const { push } = useQueryParam();
  const { t } = useTranslation();

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    letters: false,
    beginUppercase: false,
    number: false
  });

  const changePassword = useChangePassword(handleSuccess);

  // handle success
  function handleSuccess() {
    push(rootPaths.INDEX);
  }

  // handle password change
  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = e => {
    setPasswordValidation({
      length: e.target.value?.length >= 8,
      beginUppercase: !!e.target.value[0]?.match(/[A-Z]/),
      letters: !!e.target.value?.match(/[A-Za-z]/),
      number: !!e.target.value?.match(/[0-9]/)
    });
  };

  // title password validation
  const titlePasswordValidation = () => {
    if (passwordValidation.number && passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ajoyib_Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return t('profile.Ishonchli_parol');
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return t('profile.Orta_ishonchli_parol');
    }
    return t('profile.Ishonchsiz_parol');
  };

  // title password validation
  const titlePasswordValidationColor = () => {
    if (passwordValidation.length && passwordValidation.letters && passwordValidation.beginUppercase) {
      return PasswordValidationColor.strong;
    }
    if (passwordValidation.length && passwordValidation.letters) {
      return PasswordValidationColor.middle;
    }
    return PasswordValidationColor.low;
  };

  const onFinish = (value: IForm) => {
    changePassword.mutate({
      password: value.password,
      username: user.username
    });
  };

  // handle click back
  const handleClickBack = () => {
    changePage(2);
  };

  return (
    <div className={styles.container}>
      <h2>
        <ArrowLeftIcon onClick={handleClickBack} />
        {t('profile.Ishonchli_parol_yarating')}
      </h2>
      <p>{t('profile.Boshqa_joyda_ishlatmaydigan_kuchli')}.</p>
      <Form
        form={form}
        name="basic"
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label={<span className={styles.container_title}>{t('profile.Yangi_parolni_kiriting')}</span>}
          name="password"
          rules={[
            { required: true, message: '' },
            {
              validator: (rule, value) => {
                if (!!value.match(/[A-Za-z]/) && value[0]?.match(/[A-Z]/) && value.length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject();
              }
            }
          ]}
        >
          <Input.Password onChange={handlePasswordChange} className={styles.container_input} />
        </Form.Item>
        <div className={styles.container_passwordValidation}>
          <PasswordValidationPopover
            length={passwordValidation.length}
            letters={passwordValidation.letters}
            beginUppercase={passwordValidation.beginUppercase}
            number={passwordValidation.number}
          >
            <p style={{ color: titlePasswordValidationColor() }} className={styles.container_passwordValidation_title}>
              {titlePasswordValidation()}
            </p>
          </PasswordValidationPopover>
          <div className={styles.container_passwordValidation_step}>
            <div
              style={{
                background: passwordValidation.length ? PasswordValidationColor.low : undefined
              }}
            />
            <div
              style={{
                background: passwordValidation.letters && passwordValidation.length ? PasswordValidationColor.middle : undefined
              }}
            />
            <div
              style={{
                background:
                  passwordValidation.beginUppercase && passwordValidation.letters && passwordValidation.length
                    ? PasswordValidationColor.strong
                    : undefined
              }}
            />
            <div
              style={{
                background:
                  passwordValidation.beginUppercase && passwordValidation.number && passwordValidation.letters && passwordValidation.length
                    ? PasswordValidationColor.strong
                    : undefined
              }}
            />
          </div>
        </div>
        <Form.Item
          name="confirmPassword"
          label={<span className={styles.container_title}>{t('profile.Yangi_parolni_takrorlang')}</span>}
          dependencies={['password']}
          rules={[
            {
              required: true
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              }
            })
          ]}
        >
          <Input.Password className={styles.container_input} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Button loading={changePassword.isLoading} type="primary" htmlType="submit" className={styles.container_button}>
            {t('home.Saqlash')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RestorePassword;
