import React, { useState } from 'react';
import DashboardModal from '@pages/crm/components/dashboard-modal/DashboardModal';

import { EventListProvider } from 'modules/event-list';

import TableConfigDrawer from '../../components/filters/TableConfigDrawer';
import { useQueryParam } from '../../hooks/useQueryParams';
import { TableKey } from '../../utils/models/TableKey';
import { SearchParamsHome } from '../home/Home';

import ClientTable from './components/ClientTable';
import FiltrDrawer from './components/filtrDrawer/FiltrDrawer';
import Header from './components/header/Header';
import LeadTable from './components/LeadTable';

import styles from './activityLogs.module.scss';

const ActivityLogs: React.FC = () => {
  const { appendMultipleDifferent, searchParams } = useQueryParam<SearchParamsHome, any>();
  const [openFiltrDrawer, setOpenFiltrDrawer] = useState<boolean>(false);
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState<boolean>(false);

  // changePage
  const changePage = (page: number, size: number = 18) => {
    appendMultipleDifferent(['page', 'size'], [page, size || 18]);
  };

  // handleOpenFiltrDrawer
  const handleOpenFiltrDrawer = () => {
    setOpenFiltrDrawer(true);
  };

  // handleOpenSettingsDrawer
  const handleOpenSettingsDrawer = () => {
    setOpenSettingsDrawer(true);
  };
  const handleCloseSettingsDrawer = () => {
    setOpenSettingsDrawer(false);
  };

  return (
    <EventListProvider>
      {({ query }) => (
        <div className={styles.container}>
          <Header handleOpenFiltrDrawer={handleOpenFiltrDrawer} handleOpenSettingsDrawer={handleOpenSettingsDrawer} />
          <div className={styles.eventsList}>{query?.tab === 'LEAD' ? <LeadTable /> : <ClientTable />}</div>
          <FiltrDrawer setOpenFiltrDrawer={setOpenFiltrDrawer} openFiltrDrawer={openFiltrDrawer} />
          <TableConfigDrawer visible={openSettingsDrawer} tableKey={TableKey.activity_logs} onClose={handleCloseSettingsDrawer} />
          <DashboardModal objectType={query?.tab === 'LEAD' ? 'LOG' : 'LOG_CLIENT'} />
        </div>
      )}
    </EventListProvider>
  );
};

export default ActivityLogs;
