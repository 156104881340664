import { useQuery } from 'react-query';

import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { baseErrorHandler } from '../../../../../../queries/baseErrorHandler';
import { $api } from '../../../../../../service/RequestService';
import { setCrmFields } from '../../../../../../store/reducers/CrmFieldSlice';
import { setEditForm } from '../../../../../../store/reducers/TextEditorSlice';
import { crmEndpoints } from '../../../../../../utils/constants/apis';
import { queryNames } from '../../../../../../utils/constants/queryNames';
import { endpoints } from '../utils/constants/endpoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { FormaModels } from '../utils/models/FormaModels';
import { GalleryModel } from '../utils/models/GalleryModel';

export function useGetForm() {
  return useQuery<any[]>(
    [queryKeys.FORMA],
    async () => {
      const res = await $api.get(endpoints.OLD_FORM);

      return res.data.data;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetOneForm(id: number | null | undefined) {
  const dispatch = useAppDispatch();

  return useQuery<FormaModels>(
    [queryKeys.FORMA, id],
    async () => {
      const res = await $api.get(`${endpoints.ONE_FORM}/${id}`);

      return res?.data?.data;
    },
    {
      enabled: !!id,
      onSuccess(data) {
        dispatch(setEditForm(data));
      },
      onError: baseErrorHandler,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetFormElement(token: string | undefined) {
  return useQuery([queryKeys.FORMA, token], async () => {
    const res = await $api.get(`${endpoints.OLD_FORM}/${token}`);

    return res.data;
  });
}

//

// crm Fields
export function useCrmFields(customdata?: any) {
  const dispatch = useAppDispatch();

  return useQuery<any[]>(
    [queryNames.CRM_FIELDS],
    async () => {
      const res = await $api.get(crmEndpoints.FIELD);

      return res.data.data;
    },
    {
      onSuccess: (data: any) => {
        console.log('custom', customdata);
        dispatch(setCrmFields(data));
      },
      refetchOnWindowFocus: false,
      retry: false,
      onError: baseErrorHandler
    }
  );
}

export function useGetAllImages() {
  return useQuery<GalleryModel>({
    queryKey: [queryKeys.GALLERY],
    queryFn: async () => {
      const res = await $api.get(endpoints.GALLERY);

      return res.data.data;
    },
    refetchOnWindowFocus: false,
    retry: false,
    onError: baseErrorHandler
  });
}
