import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Modal, Radio, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import BlueHomeIcon from '../../../assets/icons/BlueHomeIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { useDownloadContractToWord } from '../../../queries/mutation';
import { useCompanyTemplatesCompact } from '../../../queries/queries';
import { MainPaths } from '../../../routing/main/mainPaths';
import { rootPaths } from '../../../routing/rootPaths';
import { setClear } from '../../../store/reducers/ContractSlice';
import { FileTemplateLanguage, FileTemplateType } from '../../../utils/models/FileTemplate';

interface FormInterface {
  language: FileTemplateLanguage | undefined;
}

const AfterContractCreateModal = () => {
  const { push } = useQueryParam();
  const [form] = Form.useForm<FormInterface>();
  const { t } = useTranslation();
  const { contractId, contractNumber, visible } = useAppSelector(state => state.contractReducer);
  const dispatch = useAppDispatch();

  const { isLoading, data } = useCompanyTemplatesCompact(FileTemplateType.CONTRACT);

  const downloadContract = useDownloadContractToWord(contractId, contractNumber, onSuccess);

  function onSuccess() {
    dispatch(setClear());
    form.setFieldsValue({
      language: undefined
    });
    push(rootPaths.MAIN + MainPaths.CONTRACT);
  }

  const handleCancel = () => {
    dispatch(setClear());
    form.setFieldsValue({
      language: undefined
    });
    push(rootPaths.MAIN + MainPaths.INDEX);
  };

  const onFinish = (values: FormInterface) => {
    downloadContract.mutate(values.language);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleClick = () => {
    if (data?.length === 0) {
      onSuccess();
    }
  };

  return (
    <Modal
      title={t('create_table_payments.Shartnoma_Number') + contractNumber}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      wrapClassName={'contractConditionModal'}
      width={381}
    >
      <Spin spinning={downloadContract.isLoading || isLoading}>
        <Form name="languageModal" form={form} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
          {data && data.length > 0 && (
            <Form.Item
              name="language"
              rules={[
                {
                  required: true,
                  message: t('create_table_payments.Iltimos_bittasini_tanlang')
                }
              ]}
            >
              <Radio.Group className="contractRadioGroup">
                <Space direction="vertical">
                  {data?.map((template, index) => (
                    <Radio key={index} value={template.language}>
                      {t(`create_table_payments.${template.language}`)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
          <Button
            onClick={handleClick}
            type="primary"
            htmlType={data?.length !== 0 ? 'submit' : 'button'}
            style={{
              width: '100%',
              borderRadius: 50,
              height: 46,
              marginBottom: 30
            }}
          >
            {t('create_table_payments.Yakunlash')}
          </Button>
        </Form>
        <Link onClick={handleCancel} to={rootPaths.MAIN + MainPaths.INDEX} className="d-flex align-center justify-center">
          <BlueHomeIcon />
          <span style={{ color: '#3772FF', marginLeft: 10, fontSize: 14 }}>{t('create_table_payments.Bosh_sahifa')}</span>
        </Link>
      </Spin>
    </Modal>
  );
};

export default AfterContractCreateModal;
