import React, { useEffect, useRef } from 'react';
import styles from './paymentSearchClient.module.scss';
import { Input, Layout, Spin } from 'antd';
import SearchIcon from '../../assets/icons/SearchIcon';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useContractListForPayment } from '../../queries/queries';
import { rootPaths } from '../../routing/rootPaths';
import { MainPaths } from '../../routing/main/mainPaths';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';

const { Content } = Layout;

const PaymentSearchClient = () => {
  const inputRef = useRef(null);

  const { appendSingle, remove, searchParams, push } = useQueryParam<{ search: string }, any>();

  const { data, isLoading } = useContractListForPayment(searchParams.search);
  const { t } = useTranslation();

  useEffect(() => {
    // @ts-ignore
    inputRef.current!.focus({
      cursor: 'start'
    });
  }, []);

  const handleChangeSearchInput = (value: string) => {
    if (value) appendSingle('search', value);
    else remove('search', undefined);
  };

  const goToCreatePayment = (id: number) => {
    push(rootPaths.MAIN + MainPaths.PAYMENT + '/' + id + MainPaths.CREATE_PAYMENT);
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleChangeSearchInput('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={styles.cont}>
          <div className={styles.searchCont}>
            <h1>{t('payment_search_client.To’lov_qiluvchi_mijoz_qidirish')}</h1>
            <Input
              ref={inputRef}
              value={searchParams.search}
              onChange={e => handleChangeSearchInput(e.target.value)}
              placeholder={t('payment_search_client.Mijoz_bo’yicha_qidirish')}
              className={styles.searchCont_input}
              suffix={showIconInput()}
            />
          </div>
          <Spin spinning={isLoading}>
            <div className={styles.items}>
              {data?.map((item, index) => (
                <div onClick={() => goToCreatePayment(item.id)} key={index} className={styles.items_item}>
                  <div className={styles.items_item_main}>
                    <div className={styles.items_item_main_client_name}>{item.clientName}</div>
                    <div className={styles.items_item_main_contract_number}>{item.number}</div>
                  </div>
                  <div className={styles.items_item_body}>
                    <div className={styles.items_item_body_title}>{t('payment_search_client.Telefon')}</div>
                    <div className={styles.items_item_body_value}>{item.clientPhone}</div>
                  </div>
                  <div className={styles.items_item_body}>
                    <div className={styles.items_item_body_title}>{t('payment_search_client.Umumiy_summa')}</div>
                    <div className={styles.items_item_body_value}>
                      {item.amount?.toLocaleString('ru')} {t('payment_search_client.so’m')}
                    </div>
                  </div>
                  <div className={styles.items_item_body}>
                    <div className={styles.items_item_body_title}>{t('payment_search_client.Birinchi_to’lov')}</div>
                    <div className={styles.items_item_body_value}>
                      {item.prepayment?.toLocaleString('ru')} {t('payment_search_client.so’m')}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Spin>
        </div>
      </Content>
    </>
  );
};

export default PaymentSearchClient;
