import { useAppSelector } from '@hooks/reduxHooks';
import { Permissions } from '@utils/constants/permissions';
import { Spin } from 'antd';

import { useGetRemotePayment } from '../../../queries/queries';
import UzumContent from '../uzum-integration/content/UzumContent';

import PaylovConfig from './paylov-config/PaylovConfig';
import AddRemotePayment from './AddRemotePayment';
import ItemRemotePayment from './ItemRemotePayment';

import logo from '../../../assets/img/apelsinLogo.png';

const RemotePayment = () => {
  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);
  const { isLoading, data, isFetching } = useGetRemotePayment();

  const isPaylov = permissions?.includes(Permissions.PERMISSION_PAYLOV_CONFIG);
  const isEmpty = !data?.length;

  return (
    <div className="flex w-[calc(100%-280px)] flex-col gap-[20px]">
      <Spin spinning={isLoading || isFetching}>
        <div className="flex items-start gap-2">
          <div className="!w-[280px] rounded-md bg-white px-12 py-11">
            <img className="h-[60px] w-[160px] object-contain" src={logo} alt="apelsin" />
          </div>
          <div className="w-[calc(100%-280px)] rounded-md bg-white px-11 py-6">
            {data?.map(item => <ItemRemotePayment data={item} key={item?.id} />)}
            <AddRemotePayment isEmpty={isEmpty} />
          </div>
        </div>
      </Spin>
      <UzumContent />
      {isPaylov ? <PaylovConfig /> : ''}
    </div>
  );
};

export default RemotePayment;
