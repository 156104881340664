const GoogleIcon = () => (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.18 8.18324C16.18 7.61597 16.1291 7.07051 16.0345 6.54688H8.5V9.64142H12.8055C12.62 10.6414 12.0564 11.4887 11.2091 12.056V14.0632H13.7945C15.3073 12.6705 16.18 10.6196 16.18 8.18324Z" fill="#4285F4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.50065 15.9956C10.6607 15.9956 12.4716 15.2793 13.7952 14.0574L11.2097 12.0502C10.4934 12.5302 9.57702 12.8138 8.50065 12.8138C6.41702 12.8138 4.65338 11.4065 4.02429 9.51562H1.35156V11.5884C2.66793 14.2029 5.37338 15.9956 8.50065 15.9956Z" fill="#34A853" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.02364 9.52288C3.86364 9.04288 3.77273 8.53016 3.77273 8.00288C3.77273 7.47561 3.86364 6.96288 4.02364 6.48288V4.41016H1.35091C0.809091 5.49016 0.5 6.71197 0.5 8.00288C0.5 9.29379 0.809091 10.5156 1.35091 11.5956L4.02364 9.52288Z" fill="#FBBC05" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.50065 3.18182C9.6752 3.18182 10.7297 3.58545 11.5588 4.37818L13.8534 2.08364C12.4679 0.792727 10.657 0 8.50065 0C5.37338 0 2.66793 1.79273 1.35156 4.40727L4.02429 6.48C4.65338 4.58909 6.41702 3.18182 8.50065 3.18182Z" fill="#EA4335" />
    </svg>
)

export default GoogleIcon
