import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import DatePicker, { RangePickerProps } from '@service/datePicker';
import { cx } from '@utils/cx';
import dayjs from 'dayjs';
import IMask, { FactoryArg } from 'imask';

const { RangePicker: BaseRangePicker } = DatePicker;

type dateFormat = 'DD.MM.YYYY' | 'HH:mm' | 'DD.MM.YYYY HH:mm' | (string & {});

type Additional = {
  returnType?: 'unix' | 'unix-seconds' | dateFormat;
  onChange?: (value: [string | number | null, string | number | null] | null) => void;
  startOf?: dayjs.OpUnitType;
  endOf?: dayjs.OpUnitType;
  format?: dateFormat;
  value?: [string | number | null, string | number | null] | null;
};

type IProps = (RangePickerProps & Additional) | (Omit<RangePickerProps, 'format' | 'value'> & Additional);

const RangePicker = ({ format = 'DD.MM.YYYY', ...args }: IProps) => {
  const { returnType = format as Additional['returnType'], startOf, endOf, ...props } = args;
  const divRef = useRef<HTMLDivElement | null>(null);
  const [date, setDate] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>(null);
  const imaskRef1 = useRef<ReturnType<typeof IMask> | null>(null);
  const imaskRef2 = useRef<ReturnType<typeof IMask> | null>(null);

  const maskOptions: FactoryArg = {
    mask:
      format
        ?.split('')
        .map(item => (item.charCodeAt(0) >= 65 && item.charCodeAt(0) <= 122 ? '0' : item))
        .join('') || '',
    lazy: true,
    placeholderChar: '_'
  };

  const onChange = (v: typeof date, f?: [string, string]) => {
    let v0 = v ? v[0] : null;
    let v1 = v ? v[1] : null;

    if (v0 && startOf) {
      v0 = v0?.startOf(startOf);
    }

    if (v1 && endOf) {
      v1 = v1?.endOf(endOf);
    }

    const dataConvert: typeof date = v ? [v0, v1] : null;

    setDate(dataConvert);
    if (returnType === 'unix') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onChange && props?.onChange(dataConvert ? [dataConvert[0] ? dataConvert[0].valueOf() : null, dataConvert[1] ? dataConvert[1].valueOf() : null] : null);
    }
    if (returnType === 'unix-seconds') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onChange && props?.onChange(dataConvert ? [dataConvert[0] ? dataConvert[0].unix() : null, dataConvert[1] ? dataConvert[1].unix() : null] : null);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onChange &&
        props?.onChange(dataConvert ? [dataConvert[0] ? dataConvert[0].format(returnType) : null, dataConvert[1] ? dataConvert[1].format(returnType) : null] : null);
    }
  };

  useEffect(() => {
    const inputs = divRef.current?.querySelectorAll('input')!;
    const [input1, input2] = inputs;

    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
      const { value } = e.currentTarget;
      const d = dayjs(value, format || undefined);
      const dateConvert: typeof date = [input1.value ? dayjs(input1.value, format) : null, input2.value ? dayjs(input2.value, format) : null];

      if (value?.length === format.length && e.key.charCodeAt(0) >= 48 && e.key.charCodeAt(0) <= 57) {
        dateConvert[index] = d;
        onChange(dateConvert);

        if (index === 0) {
          input2.focus();
        }
      }
    };

    input1.addEventListener('keyup', e => onKeyUp(e as any, 0));
    input2.addEventListener('keyup', e => onKeyUp(e as any, 1));

    imaskRef1.current = IMask(input1, maskOptions);
    imaskRef2.current = IMask(input2, maskOptions);

    return () => {
      input1.removeEventListener('keyup', e => onKeyUp(e as any, 0));
      input2.removeEventListener('keyup', e => onKeyUp(e as any, 1));
      imaskRef1.current?.destroy();
      imaskRef2.current?.destroy();
    };
  }, [divRef.current]);

  useEffect(() => {
    if (props?.value) {
      const [value1, value2] = props?.value;

      const dateConvert: typeof date = [null, null];

      if (typeof value1 === 'string') {
        const d = format
          .split('')
          .map((item, i) => value1![i] || (item.charCodeAt(0) >= 65 && item.charCodeAt(0) <= 122 ? '0' : item))
          .join('');

        dateConvert[0] = dayjs(d, format || undefined);
      } else if (typeof value1 === 'number') {
        dateConvert[0] = dayjs.unix(value1);
      }

      if (typeof value2 === 'string') {
        const d = format
          .split('')
          .map((item, i) => value2![i] || (item.charCodeAt(0) >= 65 && item.charCodeAt(0) <= 122 ? '0' : item))
          .join('');

        dateConvert[1] = dayjs(d, format || undefined);
      } else if (typeof value2 === 'number') {
        dateConvert[1] = dayjs.unix(value2);
      }

      setDate(dateConvert);
    }
  }, [props.value, format]);

  return (
    <div ref={divRef}>
      <BaseRangePicker {...props} className={cx('customInput', props.className)} value={date} onChange={onChange} format={format} />
    </div>
  );
};

export default RangePicker;
