import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { cx } from '@utils/cx';

import { useContractVariationCreate } from '../hooks/use-contract-variation-create';
import { IVariation, variation } from '../schema';

type Props = UseFormReturn<IVariation> & { onSubmit?: () => void };

interface IProps {
  children: (props: Props) => React.ReactNode;
  className?: string;
  defaultValues?: Partial<IVariation>;
  handleClose?: () => void;
}

const OfferItem = ({ children, className, defaultValues, handleClose }: IProps) => {
  const form: Props = useForm<IVariation>({
    defaultValues,
    resolver: zodResolver(variation)
  });
  const mutation = useContractVariationCreate({
    groupId: defaultValues?.groupId,
    onSuccess: () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handleClose && handleClose();
    }
  });

  const onFinish = async (values: IVariation) => {
    await new Promise(onSettled => {
      mutation.mutate(
        {
          ...values
        },
        { onSettled }
      );
    });
  };

  form.onSubmit = form.handleSubmit(onFinish);

  return (
    <form onSubmit={form.onSubmit} className={cx('w-full', className)}>
      {children(form)}
    </form>
  );
};

export default OfferItem;
