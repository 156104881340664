import React from 'react';
import { useGetPlanFactPipeStatistics } from '@pages/bossCrm/services/queries';
import { Spin } from 'antd';
import { ConditionalRender } from 'hoc';
import { useTranslation } from 'react-i18next';

const Bottom: React.FC = () => {
  const { t } = useTranslation();
  const { sourceList, overallCount, isLoading } = useGetPlanFactPipeStatistics();

  return (
    <div className="flex w-[48.75rem] flex-col gap-8 rounded-lg border border-solid border-gray-300 px-8 py-6">
      <h2 className="m-0 text-3xl font-bold text-gray-700">{t('crm_plan_fact.Manbaalar')}</h2>
      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <h1 className="m-0 flex gap-2 text-6xl font-bold text-gray-800">
              <span>{overallCount || 0}</span>
              <span>{t('crm_plan_fact.ta')}</span>
            </h1>
            <p className="m-0 text-base font-medium text-gray-600">{t('crm_plan_fact.Umumiy murojaatlar soni')}</p>
          </div>
          <ConditionalRender if={sourceList?.length > 0}>
            <div className="h-[0.063rem] w-full bg-gray-200" />
          </ConditionalRender>
          <div className="flex flex-col gap-8">
            {sourceList?.map(({ count, percentage, sourceName }) => (
              <div key={sourceName} className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="text-sm font-normal text-gray-400">{sourceName}</span>
                  <div className="flex gap-2 text-2xl font-semibold text-gray-700">
                    <span>{count}</span>
                    <span>{t('crm_plan_fact.ta')}</span>
                  </div>
                </div>
                <span className="text-lg font-medium text-gray-700">{percentage} %</span>
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Bottom;
