import React, { useEffect, useState } from 'react';
import { Input, Spin } from 'antd';

import { useSaveCurrency } from '../../../../queries/mutation';
import { currencyFlags } from '../../../../utils/constants/currencyFlags';
import { defaultImage } from '../../../../utils/constants/defaultImage';
import { getCurrentLanguageWord } from '../../../../utils/helper/getCurrentLanguageWord';
import { helper } from '../../../../utils/helper/helper';
import { CurrencyItem } from '../../../../utils/models/Currency';

import styles from './changeCurrency.module.scss';

interface Props {
  currency: CurrencyItem;
  basic: CurrencyItem | undefined;
}

const ChangeCurrencyItem: React.FC<Props> = ({ currency, basic }) => {
  const [value, setValue] = useState('');

  const save = useSaveCurrency();

  useEffect(() => {
    if (currency.value) {
      setValue(helper.localeStringSmallNumbers(currency.value));
    }
  }, [currency]);

  // handle change value
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setValue(helper.deleteNotNumbersAndParseFloatSmallNumber(e.target.value).string);
  };

  // handle save
  const handleSave = () => {
    if (value) {
      save.mutate({
        ...currency,
        value: helper.parseFloatStringToNumber(value)
      });
    }
  };

  return (
    <Spin spinning={save.isLoading}>
      <div className={styles.container_item}>
        <div className={styles.container_item_name}>
          <img src={currencyFlags[`${currency.ccy.toLowerCase()}`] || defaultImage} alt={currency.ccy} />
          <span className={styles.container_item_name_ccy}>{currency.ccy}</span>
          <span className={styles.container_item_name_name}>- {getCurrentLanguageWord(currency.name)}</span>
        </div>
        <Input
          onBlur={handleSave}
          className={styles.container_item_input}
          value={value}
          status={value === '' ? 'error' : ''}
          suffix={<span>{basic?.ccy}</span>}
          onChange={handleChange}
        />
      </div>
    </Spin>
  );
};

export default ChangeCurrencyItem;
