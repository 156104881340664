import React from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';

import styles from './orderV2Modals.module.scss';
import CancelBronGarbage from '../../../../assets/icons/CancelBronGarbage';
import { t } from 'i18next';
import { useCancelBookingPayment } from '../../services/mutation';

type Props = {
  paymentIds: number | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paymentNumber: string | undefined;
};

const OrderV2CancelModal: React.FC<Props> = ({ paymentIds, open, setOpen, paymentNumber }) => {
  const [form] = Form.useForm();
  const cancelOrder = useCancelBookingPayment();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const onFinish = (e: any) => {
    cancelOrder.mutateAsync({ ...e, paymentIds: [paymentIds] }).then(handleClose);
  };

  return (
    <Modal visible={open} onCancel={handleClose} footer={false} className={styles.cancelOrder} closable={false} width={400} centered>
      <Spin spinning={cancelOrder.isLoading}>
        <Form onFinish={onFinish} form={form}>
          <div className={styles.deleteIcon}>
            <span>
              <CancelBronGarbage />
            </span>
          </div>
          <h1 className={styles.title}>
            {paymentNumber}- {t('createOrder.Tolovni_bekor_qilish')}
          </h1>
          <h3 className={styles.cancel_reason_title}>{t('order.Bekor_qilish_sabablari')}</h3>
          <Form.Item name="cancelText" rules={[{ message: '', required: true }]}>
            <Input.TextArea placeholder={t('order.Izoh_matnini_kiriting')} className={styles.text_area} />
          </Form.Item>
          <div className={styles.cancelOrder_btns}>
            <Button onClick={handleClose} className={styles.btn_cancel}>
              {t('home.Yopish')}
            </Button>
            <Button loading={cancelOrder.isLoading} htmlType={'submit'} className={styles.btn_confirm}>
              {t('home.Bekor_qilish')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OrderV2CancelModal;
