import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';
import { IVariation } from '../schema';

export const useContractVariationCreate = (props?: { groupId?: number | null; onSuccess?: () => void }) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: IVariation) => {
      if (data?.id) {
        const res = await $api.patch(`/v1/contract/variation`, data);

        return res.data.data;
      }
      const res = await $api.post(`/v1/contract/variation`, data);

      return res.data.data;
    },
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.CONTRACT_VARIATION_GROUP_ID, props?.groupId]);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props?.onSuccess && props?.onSuccess();
    }
  });
};
