import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import MainHeader from '@components/headers/MainHeader';
import { useQueryParam } from '@hooks/useQueryParams';
import ArrowLeftMainHeader from '@icons/ArrowLeftMainHeader';
import FilterIcon from '@icons/FilterIcon';
import SearchIcon from '@icons/SearchIcon';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import CallsDuration from './components/calls-duration/CallsDuration';
import CallsStatus from './components/calls-status/CallsStatus';
import CallsTable from './components/calls-table/CallsTable';
import CampagingDrawer from './components/campaging-drawer/CampagingDrawer';

const OneCampaging = () => {
  const { t } = useTranslation();
  const { params, searchParams, appendSingle, remove } = useQueryParam<any, any>();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  const handleOpenDrawer = () => {
    setVisible(true);
  };

  const goBack = () => {
    navigate(rootPaths.MAIN + MainPaths.ROBOTO_CALLS);
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('text', value);
      else remove('text', undefined);
    });
  };

  return (
    <>
      <MainHeader>
        <div className="flex cursor-pointer items-center gap-2">
          <ArrowLeftMainHeader className="cursor-pointer" onClick={goBack} />
          <span className="text-xl font-medium">{params?.robotoCallsName}</span>
        </div>
        <div className="d-flex align-center">
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <div className="flex h-[44px] items-center justify-between border-b border-l-0 border-r-0 border-t border-solid border-[#EAEAEA] bg-white">
        <div className="flex items-center">
          <div
            className="flex h-full cursor-pointer items-center gap-2 border-b-0 border-l-0 border-r border-t-0 border-solid border-[#EAEAEA] px-6 py-3"
            onClick={handleOpenDrawer}
          >
            <FilterIcon />
            <span className="font-medium text-[#344054]">{t('home.Filtr')}</span>
          </div>
        </div>
        <div className="flex h-full items-center gap-4">
          <div className="mr-3 flex h-8 items-center rounded-lg border border-solid border-[#EAEAEA] px-4">
            <SearchIcon />
            <Input placeholder={t('home.Qidirish')} value={searchParams?.text} onChange={e => handleSearch(e.target.value)} className="!border-none !shadow-none" />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-3">
        <div className="grid grid-cols-2 gap-4">
          <CallsDuration />
          <CallsStatus />
        </div>
        <CallsTable />
        <CampagingDrawer setVisible={setVisible} visible={visible} />
      </div>
    </>
  );
};

export default OneCampaging;
