import React, { FC, useState } from 'react';
import { Avatar, Input, Select } from 'antd';
import styles from './sipuUsers.module.scss';
import { CrmEmployee } from '../../../../../../utils/models/OneLid';
import { useSipuniConfigEmployee, useSipuniConfigEmployeeDelete } from '../utils/service/mutation';
import { SipEmployee } from '../utils/models/SipEmployee';
import { BASE_URL } from '../../../../../../service/RequestService';
import avatarDefaultClient from '../../../../../../assets/img/avatarDefaultClient.jpg';
import ArrowDownIcon from '../../../../../../assets/icons/ArrowDownIconCrm';
import CloseIcon from '../../../../../homeId/components/detailed/assets/icons/CloseIcon';
import { t } from 'i18next';

interface IProps extends SipEmployee {
  employeeList: CrmEmployee[] | undefined;
  sipuniConfId: number | undefined;
  index: number;
}

const SipuUser: FC<IProps> = props => {
  const { employeeList, sipuniConfId, id, index } = props;
  const [sipNumber, setSipNumber] = useState(props?.sipNumber);
  const [employeeId, setEmployeeId] = useState<number | null>(props?.employeeId);
  const employeeMutate = useSipuniConfigEmployee();
  const employeeDelete = useSipuniConfigEmployeeDelete();

  const saveEmployee = () => {
    if (employeeId && sipNumber && sipuniConfId) {
      employeeMutate.mutate({
        employeeId: employeeId,
        sipNumber: sipNumber,
        sipuniConfId: sipuniConfId,
        id: id
      });
    }
  };

  const deleteEmployeeHandle = () => {
    employeeDelete.mutate({
      id: sipuniConfId!,
      sEmployeeId: id!
    });
  };

  return (
    <div className={styles.user}>
      <div>
        {index === 0 && <label>SIPUNI USER</label>}
        <Input
          className="customInput"
          size="large"
          onChange={e => setSipNumber(e.target.value)}
          value={sipNumber}
          onBlur={() => sipNumber !== props?.sipNumber && saveEmployee()}
          placeholder={t('home.SIPUNI foydalanuvchi raqami')}
        />
      </div>
      <div>
        {index === 0 && <label>{t('home.Uysot_CRM_foydalanuvchisi')}</label>}
        <Select
          className="customSelect"
          size="large"
          onChange={value => {
            setEmployeeId(value);
            employeeMutate.mutate({
              employeeId: value,
              sipNumber: sipNumber,
              sipuniConfId: sipuniConfId,
              id: id
            });
          }}
          onBlur={() => employeeId !== props?.employeeId && saveEmployee()}
          value={employeeId}
          suffixIcon={<ArrowDownIcon />}
          placeholder={t('home.Xodim_tanlang')}
        >
          {employeeList?.map(item => (
            <Select.Option key={item?.id} value={item?.id}>
              <Avatar
                src={item?.avatar ? BASE_URL + '/' + item?.avatar : avatarDefaultClient}
                size={24}
                icon={<img src={avatarDefaultClient} alt="" />}
              />{' '}
              {item?.fullName}
            </Select.Option>
          ))}
        </Select>
      </div>
      <span className={styles.user_delete} onClick={deleteEmployeeHandle}>
        <CloseIcon />
      </span>
    </div>
  );
};

export default SipuUser;
