import MainHeader from '@components/headers/MainHeader';
import NotificationButtons from '@components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '@components/headers/components/OnlinePbxNotifications';
import SettingsHeader from '@components/setting/settingsHeader/SettingsHeader';
import { Layout } from 'antd';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { crmSettingsRoutes } from './CrmSettingsRoutes';
import CrmSettingsMenu from '@pages/crm-settings/components/crm-settings-menu/CrmSettingsMenu';

const { Content } = Layout;

const CrmSettingsRouter = () => {
  return (
    <>
      <MainHeader>
        <SettingsHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <div className={'d-flex align-start'}>
          <CrmSettingsMenu />
          <Routes>
            {crmSettingsRoutes.map((route, index) => (
              <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
            ))}
          </Routes>
        </div>
      </Content>
    </>
  );
};

export default CrmSettingsRouter;
