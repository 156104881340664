import React, { useEffect, useRef, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { useScrollView } from '@hooks/useScrollView';
import { Collapse, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useMobileContractClient } from '../../../queries/queries';

import ClientPhoneInfo from './ClientPhoneInfo';

import call from '../../../assets/img/call-icon.png';

import styles from './bossContractClient.module.scss';

const { Panel } = Collapse;

const BossContractClient = () => {
  const [dataCopy, setDataCopy] = useState<{ count: number; phone: string }[]>([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [page, setPage] = useState(1);
  const { data, isFetching, isLoading } = useMobileContractClient(String(page));
  const container = useRef<any>(null);
  const { t } = useTranslation();

  const onScroll = () => {
    let pageCopy = page;

    if (page !== data?.totalPages || !(page >= data?.totalPages)) {
      setScrollTop(container.current.scrollTop);

      // eslint-disable-next-line no-plusplus
      pageCopy++;
      setPage(pageCopy);
    }
  };
  const { ref } = useScrollView({ handleScroll: onScroll, deps: [isFetching], isChange: !isFetching && data?.currentPage !== data?.totalPages });

  useEffect(() => {
    if (data?.data) {
      setDataCopy(dataCopy.concat(data.data));
      container.current.scrollTop = scrollTop;
    }
  }, [data]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h1>{t('boss_contract.Mijoz_shartnomalari')}</h1>
        </div>
        <div className={styles.line} />
        <div className={styles.container_body} ref={container}>
          <Collapse accordion bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />} className="site-collapse-custom-collapse">
            {data?.data &&
              dataCopy.map((item, index) => (
                <Panel
                  header={
                    <>
                      <img src={call} alt="call" />
                      <p>{item.phone}</p>
                      <div className={styles.header_contract}>
                        <p>{item.count}</p>
                      </div>
                    </>
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="site-collapse-custom-panel"
                >
                  <ClientPhoneInfo number={item.phone} size={item.count} />
                </Panel>
              ))}
          </Collapse>
          <div ref={ref} className="p-2" />
        </div>
      </div>
    </Spin>
  );
};

export default BossContractClient;
