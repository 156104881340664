import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface ScrollPaginationProps<T> {
  onScrollEnd: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
  children: (props: { ref: (node?: Element | null) => void }) => JSX.Element;
  isError?: boolean;
}

const ScrollPagination = <T, _>({ onScrollEnd, hasNextPage, isLoading, children }: ScrollPaginationProps<T>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && !isLoading) {
      onScrollEnd();
    }
  }, [inView]);

  return <div>{children({ ref })}</div>;
};

export default ScrollPagination;
