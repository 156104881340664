import React, { useState } from 'react';
import { Table } from 'antd';
import styles from './createPayment.module.scss';
import { useContractMonthlyPayment } from '../../queries/queries';
import { useQueryParam } from '../../hooks/useQueryParams';
import { MonthlyPaymentStatus } from '../../utils/models/CreateContract';
import CardEditIcon from '../../assets/icons/CardEditIcon';
import ArchiveTableModal from './ArchiveTableModal';
import { useTranslation } from 'react-i18next';

interface Props {
  hasArchiveMonthlyPayment: boolean;
}

const TableMonthlyPayments: React.FC<Props> = ({ hasArchiveMonthlyPayment }) => {
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const { t } = useTranslation();

  const { params } = useQueryParam<{ id: string }, any>();
  const { data, isLoading } = useContractMonthlyPayment(params.id, MonthlyPaymentStatus.ACTIVE);

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      width: 100,
      render: (i: any, a: any, b: any) => <>{b + 1}</>
    },
    {
      title: t('create_payment.To’lov_qilingan_sana'),
      dataIndex: 'date'
    },
    {
      title: t("create_payment.To'lov_summasi"),
      dataIndex: 'amount',
      render: (i: number) => <>{i?.toLocaleString('ru')}</>
    },
    {
      title: t("create_payment.To'langan_summa"),
      dataIndex: 'payment',
      render: (i: number, a: any) => <>{i === 0 ? '---' : <span style={{ color: a.paid ? '#00A389' : '#FFBB54' }}>{i?.toLocaleString('ru')}</span>}</>
    }
  ];

  const handleOpenArchiveModal = () => {
    setOpenArchiveModal(true);
  };

  const handleCloseArchiveModal = () => {
    setOpenArchiveModal(false);
  };

  const getActiveTable = () => {
    if (data && data[0] && data[0].monthlyPaymentPartDtos) {
      return data[0].monthlyPaymentPartDtos;
    }
    return [];
  };

  return (
    <div className={styles.table_monthly_payments}>
      {hasArchiveMonthlyPayment && (
        <div onClick={handleOpenArchiveModal} className={styles.table_monthly_payments_archive_button}>
          <CardEditIcon />
          <span className={styles.table_monthly_payments_archive_button_title}>{t("create_payment.To'lov_grafigi_angilangan")}</span>
        </div>
      )}
      <p className={styles.table_monthly_payments_date}>{data && data[0] && data[0].createdDate}</p>
      <Table
        loading={isLoading}
        className={` custom_table contract_table pagination_MCSS ${styles.table_monthly_payments_leftTable}`}
        columns={columns}
        dataSource={getActiveTable()}
        size="small"
        pagination={false}
      />
      {hasArchiveMonthlyPayment && <ArchiveTableModal close={handleCloseArchiveModal} visible={openArchiveModal} />}
    </div>
  );
};

export default TableMonthlyPayments;
