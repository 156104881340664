import { Input } from '@components/fields';
import { Button } from 'antd';

import { Form, useAction } from 'modules/multi-payment';

const PaymentCreateOffer = () => {
  const { closeAction, getActionData, setQuery, action } = useAction();

  const defaultValues = action && getActionData ? getActionData[action] : undefined;

  return (
    <div>
      <Form.Offer
        closeAction={data => {
          setQuery({ groupId: data?.groupId, name: data?.name });
          closeAction();
        }}
        defaultValues={defaultValues}
      >
        {({ control, formState: { isSubmitting } }) => (
          <div className="flex flex-col gap-[16px]">
            <Input control={control} name="name" label="Nomi" />
            <div className="flex justify-end">
              <Button className="rounded-[50px] border-[0] px-[50px]" onClick={closeAction}>
                Yopish
              </Button>
              <Button className="rounded-[50px] px-[50px]" type="primary" htmlType="submit" loading={isSubmitting}>
                Saqlash
              </Button>
            </div>
          </div>
        )}
      </Form.Offer>
    </div>
  );
};

export default PaymentCreateOffer;
