import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowDownIcon from '../../../../assets/icons/ArrowDownIconCrm';
import PlusIconAddCircle from '../../../../assets/icons/PlusIconAddCircle';
import TrushSquareIcon from '../../../../assets/icons/TrushSquareIcon';
import { useCreateContractField, useDeleteContractField } from '../../../../queries/mutation';
import { CrmField, CrmFieldOption } from '../../../../utils/models/CrmField';
import { CrmFieldsType } from '../../../../utils/models/CrmFieldsType';

import styles from './createFieldModal.module.scss';

interface Props {
  visible: boolean;
  handleClose: (visible: boolean) => void;
  data: CrmField | undefined;
}

const CreateFieldModal: React.FC<Props> = ({ visible, handleClose, data }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<CrmFieldOption[]>([]);
  const [typeSelect, setTypeSelect] = useState<CrmFieldsType>(CrmFieldsType.TEXT);
  const [form] = Form.useForm();

  // handle cancel modal
  const handleCancel = () => {
    form.resetFields();
    setTypeSelect(CrmFieldsType.TEXT);
    setOptions([
      {
        name: ''
      }
    ]);
    handleClose(false);
  };

  // handle success
  function handleSuccess() {
    handleCancel();
  }

  const edit = useCreateContractField(handleSuccess);
  const deleteField = useDeleteContractField(handleSuccess);

  useEffect(() => {
    if (visible && data) {
      setTypeSelect(data.type);
      setOptions(
        data?.options || [
          {
            name: ''
          }
        ]
      );
      const obj: any = {};

      obj.name = data.name;
      obj.type = data.type;
      obj.required = Boolean(data?.required);
      if (data?.options)
        for (let i = 0; i < data?.options.length; i++) {
          obj[`option${i}`] = data?.options[i].name;
        }
      form.setFieldsValue(obj);
    }
  }, [visible, form, data]);

  // handle delete field
  const handleDeleteField = (id: number) => {
    deleteField.mutate(id);
  };

  // add option
  const addOption = () => {
    const newOptions = [...options];

    newOptions.push({
      name: ''
    });
    setOptions(newOptions);
  };

  // delete option
  const deleteOptions = (index: number) => {
    const newOptions = options.filter((item, i) => index !== i);

    const obj: any = {
      type: CrmFieldsType.SELECT,
      name: form.getFieldValue('name')
    };

    form.resetFields();

    for (let i = 0; i < newOptions.length; i++) {
      obj[`option${i}`] = newOptions[i].name;
    }

    form.setFieldsValue(obj);

    setOptions(newOptions);
  };

  // handle change option
  const handleChangeOption = (index: number, value: string) => {
    const newOptions = [...options];

    newOptions[index].name = value;
    setOptions(newOptions);
  };

  // on finish form
  const onFinish = (val: any) => {
    edit.mutate({
      type: typeSelect,
      name: val.name?.trim(),
      id: data?.id || null,
      options: typeSelect === CrmFieldsType.SELECT ? options : undefined,
      required: Boolean(val?.required)
    });
  };

  // handle change select type
  const handleChangeSelectType = (value: CrmFieldsType) => {
    setTypeSelect(value);
    setOptions([
      {
        name: ''
      }
    ]);
  };

  return (
    <Modal width={530} closable={false} title="" onCancel={handleCancel} className={styles.container} visible={visible} footer={false}>
      <Spin spinning={deleteField.isLoading}>
        <Form form={form} layout="vertical" onFinish={onFinish} className={styles.formBuilderModal}>
          <Form.Item rules={[{ required: true, message: '' }]} name="required" label={<span className={styles.container_label}>{t('one_lid.required')}</span>}>
            <Select placeholder={t('one_lid.required')} className="customSelect">
              <Select.Option value={true}>{t('one_lid.majburiy')}</Select.Option>
              <Select.Option value={false}>{t('one_lid.ixtiyoriy')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: '' }]} name="type" label={<span className={styles.container_label}>{t('one_lid.Turi')}</span>}>
            <Select onChange={handleChangeSelectType} suffixIcon={<ArrowDownIcon />} className="customSelect">
              <Select.Option value={CrmFieldsType.SELECT}>{t('one_lid.Multi_royxat')}</Select.Option>
              <Select.Option value={CrmFieldsType.TEXT}>{t('one_lid.Matn')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item rules={[{ required: true, message: '' }]} name="name" label={<span className={styles.container_label}>{t('one_lid.Nomi')}</span>}>
            <Input />
          </Form.Item>
          {typeSelect === CrmFieldsType.SELECT && (
            <>
              {options.map((item, index) => (
                // eslint-disable-next-line react/jsx-key
                <Form.Item
                  rules={[{ required: true, message: '' }]}
                  name={`option${index}`}
                  label={
                    <span className={styles.container_label}>
                      {index + 1}
                      {t('one_lid.variant')}
                    </span>
                  }
                >
                  <div className="d-flex align-center">
                    <Input value={item.name} onChange={e => handleChangeOption(index, e.target.value)} />
                    <span onClick={() => deleteOptions(index)} className={styles.container_delete_icon}>
                      <TrushSquareIcon />
                    </span>
                  </div>
                </Form.Item>
              ))}
              <div onClick={addOption} className={styles.container_add}>
                <PlusIconAddCircle />
                <span>{t('one_lid.Yangi_qoshish')}</span>
              </div>
            </>
          )}
          <div className="d-flex align-center justify-space-between">
            {data ? (
              <Popconfirm
                title={"O'chirishni tasdiqlash"}
                onConfirm={() => {
                  handleDeleteField(Number(data?.id));
                }}
                okText={t('one_lid.Tasdiqlash')}
                cancelText={t('one_lid.Bekor_qilish')}
              >
                <span>
                  <TrushSquareIcon className={styles.container_delete_all} />
                </span>
              </Popconfirm>
            ) : (
              <div />
            )}
            <div className={styles.container_modalFooter}>
              <Button onClick={handleCancel}>{t('one_lid.Yopish')}</Button>
              <Button loading={edit.isLoading} htmlType="submit" type="primary">
                {t('one_lid.Saqlash')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateFieldModal;
