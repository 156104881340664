import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from '../constants';
import { IPaylovConfig } from '../schema';

export const usePaylovConfigSave = () => {
  const qc = useQueryClient();

  return useMutation(
    async (data: IPaylovConfig) => {
      const res = await $api.post('/v1/paylov/config', data);

      return res.data.data;
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.PAYLOV_CONFIG]);
      }
    }
  );
};
