import { baseErrorHandler } from '@queries/baseErrorHandler';
import { showMessage } from '@queries/showMessage';
import { $api } from '@service/RequestService';
import { useMutation, useQueryClient } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function usePostDuplicateControls() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: any) => {
      const res = await $api.post(endPoints.DUPLICATE_CONTROL, { ...data });

      return res.data;
    },
    onSuccess: data => {
      qc.invalidateQueries({ queryKey: [queryKeys.DUPLICATE_CONTROL] });
      showMessage(data.message, data.accept);
    },
    onError: baseErrorHandler
  });
}
