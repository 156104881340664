import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import styles from '../../pages/order/components/order.module.scss';
import CancelBronGarbage from '../../assets/icons/CancelBronGarbage';
import { useDeleteOrder } from '../../queries/mutation';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { SearchParamsPayment } from '../../pages/payment/Payment';
import { useQueryClient } from 'react-query';
import { queryNames } from '../../utils/constants/queryNames';

interface Props {
  visible: boolean;
  onCancel: () => void;
  id: number;
  contractId: number;
  paymentPage?: boolean;
}

interface FromType {
  note: string;
}

const DeletePaymentModal: React.FC<Props> = ({ visible, onCancel, id, contractId }) => {
  const [form] = Form.useForm<FromType>();
  const { searchParams } = useQueryParam<SearchParamsPayment, any>();
  const { t } = useTranslation();
  const qc = useQueryClient();

  const deletePayment = useDeleteOrder([id], handleClose, String(contractId), searchParams);

  function handleClose() {
    form.resetFields();
    onCancel();
  }

  const handleSubmit = (e: FromType) => {
    deletePayment.mutateAsync(e.note).then(() => {
      qc.invalidateQueries([queryNames.PAYMENT]);
    });
  };

  return (
    <Modal className={styles.cancel_order_modal} centered closable={false} footer={null} visible={visible} onCancel={handleClose}>
      <Form form={form} name="delete_payment_form" onFinish={handleSubmit} initialValues={{ remember: true }} autoComplete="off">
        <div className={`${styles.icon_cont} d-flex justify-center`}>
          <span className={`${styles.icon} d-flex align-center justify-center`}>
            <CancelBronGarbage />
          </span>
        </div>
        <h1 className={styles.title}>{t("create_payment.To'lovni_o'chirmoqchimisiz")}?</h1>
        <h2 className={styles.sub_title}>{t("create_payment.O'chirib_yuborilgan_to'lovlar_ustida_hech_qanday_amal_bajarib_bo'lmaydi")}</h2>
        <Form.Item>
          <Form.Item
            noStyle
            name="note"
            rules={[
              {
                required: true,
                message: t('create_payment.Izoh_kiritilmagan')
              }
            ]}
          >
            <Input.TextArea rows={6} placeholder={t('create_payment.Izoh_matnini_kiriting')} className={styles.text_area_payment} />
          </Form.Item>
        </Form.Item>
        <div className={'d-flex align-center'}>
          <Button type={'primary'} onClick={handleClose} className={styles.btn_cancel}>
            {t('create_payment.Yopish')}
          </Button>
          <Button loading={deletePayment.isLoading} htmlType={'submit'} danger type={'primary'} className={styles.btn_confirm}>
            {t("create_payment.O'chirish")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DeletePaymentModal;
