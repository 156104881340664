import React, { FC, useRef, useState } from 'react';
import { BASE_URL } from '../../service/RequestService';
import { FlatGallery } from '../../utils/models/Flat';
import styles from './flatGallery.module.scss';

interface IProps extends FlatGallery {
  index: number;
  zoom: number;
}

const FlatGalleryItem: FC<IProps> = item => {
  const bodyRef = useRef<any>(null);
  const [position, setPosition] = useState({
    startY: 0,
    startX: 0,
    scrollLeft: 0,
    scrollTop: 0,
    isDown: false,
    xPercent: 0,
    yPercent: 0
  });

  // const bodymouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (position.isDown && bodyRef.current) {
  //     e.preventDefault()
  //     const y = e.pageY - bodyRef.current.offsetTop
  //     const walkY = y - position.startY
  //     bodyRef.current.scrollTop = position.scrollTop - walkY

  //     const x = e.pageX - bodyRef.current.offsetLeft
  //     const walkX = x - position.startX
  //     bodyRef.current.scrollLeft = position.scrollLeft - walkX
  //   }
  // }
  // const bodymouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
  //   setPosition((prev) => ({
  //     ...prev,
  //     isDown: false,
  //   }))
  // }
  // const bodymouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (item.zoom !== 1) {
  //     e.stopPropagation()
  //   }
  //   if (bodyRef.current) {
  //     setPosition((prev) => ({
  //       ...prev,
  //       isDown: true,
  //       startY: e.pageY - bodyRef.current.offsetTop,
  //       startX: e.pageX - bodyRef.current.offsetLeft,
  //       scrollTop: bodyRef.current.scrollTop,
  //       scrollLeft: bodyRef.current.scrollLeft,
  //     }))
  //   }
  // }
  // const bodymouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
  //   setPosition((prev) => ({
  //     ...prev,
  //     isDown: false,
  //   }))
  // }

  const imageMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const img_pos = e.currentTarget.getBoundingClientRect();
    let mouseX = e.clientX - img_pos.left;
    let mouseY = e.clientY - img_pos.top;
    let xPercent = (mouseX / img_pos.width) * 100;
    let yPercent = (mouseY / img_pos.height) * 100;
    setPosition(prev => ({
      ...prev,
      xPercent,
      yPercent
    }));
  };

  return (
    <div
      key={item?.id}
      className={styles.modal_container_item}
      id={'imageId_' + item?.index}
      ref={bodyRef}
      // onMouseMove={bodymouseMove}
      // onMouseUp={bodymouseUp}
      // onMouseDown={bodymouseDown}
      // onMouseLeave={bodymouseLeave}
    >
      <img
        onMouseMove={imageMouseMove}
        style={{
          transform: `scale(${item?.zoom})`,
          transformOrigin: `${position.xPercent}% ${position.yPercent}%`
        }}
        src={BASE_URL + '/' + item?.files?.absolutePath}
        alt=""
      />
    </div>
  );
};

export default FlatGalleryItem;
