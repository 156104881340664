import React from 'react';
import { NavLink } from 'react-router-dom';
import ArrowRightMessage from '../../../assets/icons/ArrowRightMessage';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { rootPaths } from '../../../routing/rootPaths';
import { MainPaths } from '../../../routing/main/mainPaths';
import { SettingsPaths } from '../../../routing/settings/settingsPaths';

import styles from '../../interfaceConfig/interfaceConfig.module.scss';
import { InterfaceConfigPaths } from '../../../routing/interfaceConfig/InterfaceConfigPaths';
import Monitor from '../../../assets/icons/Monitor';
import { useTranslation } from 'react-i18next';
import ElementplusIcon from '../../../assets/icons/ElementplusIcon';

const InterfaceConfigNavbar = () => {
  const param = useQueryParam();
  const { t } = useTranslation();

  const data = [
    {
      icon: Monitor,
      text: t('navbars.Interfeys_sozlamalari'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTERFACE + InterfaceConfigPaths.INTERFACE_CONFIG,

      class: param.splitUrl[param.splitUrl.length - 1] === 'interface-config' ? `${styles.active}` : `${styles.navLinkClass}`
    },
    {
      icon: ElementplusIcon,
      text: t('widgets.Widgetlar'),
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTERFACE + InterfaceConfigPaths.WIDGET_CONFIG,

      class: param.splitUrl[param.splitUrl.length - 1] === 'widget-config' ? `${styles.active}` : `${styles.navLinkClass}`
    }
  ];

  return (
    <div className={styles.navbar}>
      {data.map((item, index) => (
        <NavLink key={index} to={item.path} className={item.class}>
          <span className="d-flex align-center">
            {<item.icon />} &nbsp; &nbsp; {item.text}
          </span>

          <span className={styles.arrowDiv}>
            <ArrowRightMessage />
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default InterfaceConfigNavbar;
