import { addControllerPrefix } from '../helper/addControllerPrefix';

export const controllers = {
  AUTH: '/v1/auth',
  HOUSE: '/v1/house',
  FLAT: '/v1/flat',
  FLAT2: '/v2/flat',
  ENTRANCE: '/v2/entrance',
  TABLE: '/v1/table',
  BOOKING: '/v1/booking',
  CLIENT: '/v1/client',
  ADDRESS: '/v1/address',
  CONTRACT: '/v1/contract',
  FILES: '/v1/files',
  DEBT: '/v1/debt',
  EMPLOYEE: '/v1/employee',
  BUILDER: '/v1/builder',
  BRANCH: '/v1/branch',
  SMS_TEMPLATE: '/v1/sms-template',
  SMS_CONFIG: '/v1/sms-config',
  COMPANY_CONFIG: '/v1/company-config',
  PLAY_MOBILE_CONF: '/v1/sms-system-conf',
  DISCOUNT: '/v1/discount',
  BUILDING: '/v1/building',
  BUILDING2: '/v2/building',
  TRANSFER_DATA: '/v1/transfer-data',
  COMPANY: '/v1/company',
  DEV: '/v1/dev',
  SHOWROOM: '/v1/shourum',
  PAYME: '/v1/payme',
  TELEGRAM: '/v1/telegram',
  AMO_CRM: '/v1/amo-crm',
  COMPANY_AMO_CRM_CONFIG: '/v1/company-amoCrm-config',
  MERCHANT: '/v1/merchant',
  HOUSE_SALE_CRM: '/v1/home-sale-crm',
  WEBSITE: '/v1/website',
  STAFF: '/v1/staff',
  EMPLOYEE_KPI: '/v1/employee-kpi',
  ACCOUNT: '/v1/account',
  MOBILE: '/v1/mobile',
  MOBILE_VERSION_TWO: '/v2/mobile',
  MOBILE_VERSION_THREE: '/v3/mobile',
  TASK: '/v1/task',
  TABLE_COLOR: '/v1/table-color',
  AUTO_TASK_SETTING: '/v1/auto-task-setting',
  PIPE: '/v1/pipe',
  LEAD: '/v1/lead',
  CRM: '/v1/crm',
  PIPE_STATUS: '/v1/status',
  TAG: '/v1/tag',
  LEAD_TASK_TYPE: '/v1/lead-task-type',
  LEAD_TASK: '/v1/lead-task',
  LEAD_NOTE: '/v1/lead-note',
  TASK_NOTE: '/v1/task/note',
  CHAT_MESSAGE: '/v1/chat-message',
  TELEGRAM_AND_WEB: '/v1/telegram-and-web',
  MOI_ZVONKI_CONFIG: '/v1/moi-zvonki-config',
  MOI_ZVONKI_SEND_REQUEST: '/v1/moi-zvonki-send-request',
  MOI_SMS_TEMPLATE: '/v1/moi-sms-template',
  NOTIFICATION: '/v1/notification',
  REASONS_FOR_REFUSAL: '/v1/reasons-for-refusal',
  ONLINE_PBX: '/v1/online-pbx',
  BOOKING_LEAD_FLAT: '/v1/booking-lead-flat',
  MARKETPLACE: '/v1/marketplace',
  PAYMENT: '/v1/payment',
  STATISTICS: '/v1/statistics',
  CASH: '/v1/cash',
  CARD: '/v1/card',
  CLIENT_MESSAGE_TEMP: '/v1/client-message-temp',
  UYQUR: '/v1/uyqur',
  RESET_PASSWORD: '/v1/reset-password',
  CURRENCY: '/v1/currency',
  LEAD_NOT_TASK_CONF: '/v1/lead-not-task-conf',
  WIDGET: '/v1/widget',
  DOWNLOAD_PDF: '/v1/shourum/new-design',
  ACTIVE_SESSION: '/v1/active/session',
  REPORT_CONFIG: '/v1/report-config',
  MONETARY: '/v1/monetary',
  REALTOR_CONFIG: '/v1/realtor-config',
  CALL_HITORY: '/v1/call-history',
  MY_HOME_CONFIG: '/v1/my-home/config'
};

export const GEO_CODE_YANDEX = 'https://geocode-maps.yandex.ru/1.x/?apikey=afb187c4-cba9-48fb-9b21-c8c30027b26c&format=json&lang=uz_UZ';

export const SEARCH_BY_PASSPORT_CLIENT_FOR_ONLINE_MAHALLA = 'https://api.online-mahalla.uz/api/v1/public/tax/passport';
export const SEARCH_BY_PASSPORT_CLIENT_FOR_STUDY_SCHOLARSHIP = 'https://api.talim-krediti.uz/api/v1/public/tax/passport';

export const PRINT_CHECK_AUTO = 'http://localhost:7777';

export const ONLINE_PBX_API = 'https://api2.onlinepbx.ru';

export const BIG_DATA_CLOUD = 'https://api.bigdatacloud.net/data/reverse-geocode-client';

export const OPEN_CAGA_DATA = 'https://api.opencagedata.com/geocode/v1/json';
export const OPEN_CAGA_DATA_KEY = '312baf596358470397b6d000478675b5';

// payme cash
// const payme = {
//   CASH: '/cash',
//   CARD: '/card',
//   CARD_VERIFY: '/card/verify',
//   CARD_STEP: '/card/step',
//   CARD_STYLE: '/card/style',
//   CARD_ACTIVATE: '/card/activate',
// }
//
// export const paymeEndpoints: typeof payme = addControllerPrefix(
//   payme,
//   controllers.PAYME
// )

// company-config
const company_config = {
  INDEX: '/',
  SYSTEM_COLOR: '/system-color',
  COMPANY_LOGO: '/company-logo',
  CONTRACT_NUMBER_PATTERN: '/contract-number-pattern',
  PAYMENT_NUMBER_PATTERN: '/payment-number-pattern',
  CONTRACT_NUMBER_PATTERN_ANNOTATIONS: '/contract-number-pattern-annotations',
  PAYMENT_NUMBER_PATTERN_ANNOTATIONS: '/payment-number-pattern-annotations',
  DOCUMENT_TEMPLATE: '/document-template',
  DOCUMENT_TEMPLATE_COMPACT: '/document-template/compact',
  DOCUMENT_TEMPLATE_CUSTOM: '/document-template/custom',
  SALE_CRM_TAG: '/sale-crm-tag',
  EMPLOYEE_CHANGE_PASSWORD_DATE: '/employee-change-password-date'
};

export const companyConfigEndpoints: typeof company_config = addControllerPrefix(company_config, controllers.COMPANY_CONFIG);

// sms-config
const sms_config = {
  INDEX: '/'
};

export const smsConfigEndpoints: typeof sms_config = addControllerPrefix(sms_config, controllers.SMS_CONFIG);

// sms-template
const sms_template = { INDEX: '/' };

export const smsTemplateEndpoints: typeof sms_template = addControllerPrefix(sms_template, controllers.SMS_TEMPLATE);

// auth
const auth = {
  SIGN_IN: '/sign-in',
  SIGN_IN_DEV: '/sign-in/dev',
  REFRESH: '/refresh',
  LOGOUT: '/logout',
  ROLE: '/role',
  ROLE_COMPANY: '/role/company',
  PERMISSION: '/permission'
};

export const authEndpoints: typeof auth = addControllerPrefix(auth, controllers.AUTH);

// house
const house = {
  EXTRA: '/extra',
  COMPACT: '/compact',
  INDEX: '/',
  INFO: '/info',
  ON_SALE: '/on-sale',
  FOR_SHOWROOM: '/for-showroom'
};

export const houseEndpoints: typeof house = addControllerPrefix(house, controllers.HOUSE);

// flat
const flat = {
  INDEX: '/',
  INIT: '/init',
  EDITOR: '/editor',
  FILTER: '/filter',
  FILTER_EXCEL: '/filter/excel',
  FILTER_PARAMS: '/filter/params',
  BY_BOOKING: '/by-booking',
  INFO: '/info',
  MORE: '/more',
  DISCOUNT: '/discount',
  FIELD: '/field',
  CHANGE_PRICE: '/change-price',
  FILTER_SUMMARY_EXCEL: '/filter/summary/excel',
  FILTER_SUMMARY_EXCEL_BETA: '/filter/summary/excel/beta',
  LAST_CHANGE_PRICE: '/last-flat-price-changes-date',
  UNDO_PRICE: '/undo-price'
};

export const flatEndpoints: typeof flat = addControllerPrefix(flat, controllers.FLAT);

// flat
const flat2 = {
  INDEX: '/',
  INIT: '/init',
  CHANGE_PRICE: '/change-price',
  ALL: '/all'
};

export const flat2Endpoints: typeof flat2 = addControllerPrefix(flat2, controllers.FLAT2);

// entrance
const entrance = {
  INDEX: '/',
  INIT: '/init',
  DUPLICATE: '/duplicate'
};

export const entranceEndpoints: typeof entrance = addControllerPrefix(entrance, controllers.ENTRANCE);

// table
const table = {
  INDEX: '/',
  ALL: '/all'
};

export const tableEndpoints: typeof table = addControllerPrefix(table, controllers.TABLE);

// booking
const booking = {
  INDEX: '/',
  CANCEL_REASON: '/cancel/reason',
  FILTER: '/filter',
  FILTER_EXCEL: '/filter/excel',
  NATURAL: '/natural',
  JURIDICAL: '/juridical',
  CANCEL: '/cancel'
};

export const bookingEndpoints: typeof booking = addControllerPrefix(booking, controllers.BOOKING);

// client
const client = {
  INDEX: '/',
  NATURAL: '/natural',
  JURIDICAL: '/juridical',
  BOOKING: '/booking',
  BALANCE: '/balance',
  VIEW: '/view',
  FILTER: '/filter',
  FILTER_EXCEL: '/filter/excel',
  SEND_MESSAGE: '/send-message',
  SEND_MESSAGE_FILTER_EXCEL: '/send-message/filter/excel',
  CLIENT_PAYMENT_TEMPLATE: '/client-payment/template',
  CHANGES: '/changes',
  CHANGES_JURIDICAL: '/changes/juridical',
  CHANGES_NATURAL: '/changes/natural',
  SEARCH: '/search'
};

export const clientEndpoints: typeof client = addControllerPrefix(client, controllers.CLIENT);

// address
const address = {
  INDEX: '/',
  TEMP: '/temp',
  DISTRICT_ID: '/districtId',
  CITY_ID: '/cityId',
  CITY: '/city',
  DISTRICT: '/district'
};

export const addressEndpoints: typeof address = addControllerPrefix(address, controllers.ADDRESS);

// contract
const contract = {
  INDEX: '/',
  FILTER: '/filter',
  FILTER_EXCEL: '/filter/excel',
  FILTER_EXCEL_SUMMARY: '/filter/excel/summary',
  TRANSFER: '/transfer',
  MONTHLY_PAYMENT: '/monthly-payment',
  AMOUNT: '/amount',
  TEMPLATE: '/template',
  PAYMENT: '/payment',
  PAYMENT_FILTER: '/payment/filter',
  PAYMENT_FILTER_EXCEL: '/payment/filter/excel',
  PAYMENT_FILTER_SUM: '/payment/filter/sum',
  CANCEL: '/amount/cancel',
  FOR_PAYMENT: '/for-payment',
  CHANGES: '/changes',
  CHANGES_EMPLOYEE: '/changes/employee',
  CLIENT: '/client',
  CURRENT_NUMBER: '/current-number',
  staff: '/staff',
  PAYMENT_CURRENT_NUMBER: '/payment/current-number',
  FIELD: '/field',
  EMPLOYEE: '/employee'
};

export const contractEndpoints: typeof contract = addControllerPrefix(contract, controllers.CONTRACT);

// files
const files = {
  INDEX: '/',
  DEVELOPER: '/developer'
};

export const filesEndpoints: typeof files = addControllerPrefix(files, controllers.FILES);

// debit
const debit = {
  INDEX: '/',
  EXCEL: '/excel',
  SEND_MESSAGE: '/send-message',
  IN_DATE_EXCEL: '/in-date/excel',
  IN_DATE_EXCEL_BETA: '/in-date/excel/beta'
};

export const debitEndpoints: typeof debit = addControllerPrefix(debit, controllers.DEBT);

// employee
const employee = {
  INDEX: '/',
  FILTER: '/filter'
};

export const employeeEndpoints: typeof employee = addControllerPrefix(employee, controllers.EMPLOYEE);

// builder
const builder = {
  INDEX: '/',
  COMPACT: '/compact'
};

export const builderEndpoints: typeof builder = addControllerPrefix(builder, controllers.BUILDER);

// builder
const branch = {
  INDEX: '/',
  COMPACT: '/compact',
  DEV: '/dev/compact'
};

export const branchEndpoints: typeof branch = addControllerPrefix(branch, controllers.BRANCH);

// premium
const premium = {
  INDEX: '/',
  SEND_MESSAGES: '/send-messages'
};

export const premiumEndpoints: typeof premium = addControllerPrefix(premium, controllers.CLIENT);

// play-mobile-conf
const play_mobile_conf = {
  INDEX: '/',
  ORIGINATOR: '/originator',
  HOUSE: '/house'
};

export const playMobileEndpoints: typeof play_mobile_conf = addControllerPrefix(play_mobile_conf, controllers.PLAY_MOBILE_CONF);

// discount
const discount = {
  INDEX: '/',
  NEW: '',
  MAX_DISCOUNT: '/config'
};

export const discountEndpoints: typeof discount = addControllerPrefix(discount, controllers.DISCOUNT);

// building
const building = {
  INDEX: '/',
  NEW: '',
  COMPACT: '/compact',
  ALL: '/all'
};

export const buildingEndpoints: typeof building = addControllerPrefix(building, controllers.BUILDING);

// building
const building2 = {
  INDEX: '/',
  NEW: '',
  ORDER: '/order',
  ORDER_FLAT: '/order/flat',
  CHANGE_PRICE: '/change-price',
  BASEMENT: '/basement',
  FLOOR: '/floor',
  DUPLICATE: '/duplicate'
};

export const building2Endpoints: typeof building2 = addControllerPrefix(building2, controllers.BUILDING2);

// flat compact
const flatCompact = {
  INDEX: '/',
  COMPACT: '/compact'
};

export const flatCompactEndpoints: typeof flatCompact = addControllerPrefix(flatCompact, controllers.FLAT);

// transfer-data
const transfer_data = {
  INDEX: '/'
};

export const transferDataEndpoints: typeof transfer_data = addControllerPrefix(transfer_data, controllers.TRANSFER_DATA);

// company
const company = {
  MAIN: '/',
  INDEX: '/filter',
  COMPACT: '/compact',
  SURCHARGE: '/surcharge',
  EXPIRE_DATE: '/expiry-date',
  EXTRA_DAY: '/extra-day'
};

export const companyEndpoints: typeof company = addControllerPrefix(company, controllers.COMPANY);

// developer
const developer = {
  INDEX: '/',
  EMPLOYEE: '/employee',
  EMPLOYEE_ROLE_COMPANY: '/employee/role/company'
};

export const developerEndpoints: typeof developer = addControllerPrefix(developer, controllers.DEV);

// showroom
const showroom = {
  INDEX: '/',
  PDF_PLAN_TABLE: '/pdf-plan-table',
  PDF_PLAN_IMAGE: '/pdf-plan-image',
  FLAT_DATA: '/flat-data'
};

export const showroomEndpoints: typeof showroom = addControllerPrefix(showroom, controllers.SHOWROOM);

// telegram
const telegram = {
  INDEX: '/',
  BOT: '/bot',
  BOT_STOP: '/bot/stop',
  BOT_START: '/bot/start',
  BOT_BUTTON: '/bot/button',
  BOT_FAQ: '/bot/faq',
  BOT_SEND_MESSAGE_USERS: '/bot/send-message-users',
  BOT_FOR_MESSAGE: '/bot/for-message',
  BOT_CHAT: '/bot/chats'
};

export const telegramEndpoints: typeof telegram = addControllerPrefix(telegram, controllers.TELEGRAM);

// amo-task
const amoCrm = {
  AUTH_EDIT: '/auth/edit',
  AUTH_CREATE: '/auth/create',
  AUTH_GET_BY: '/auth/get-by',
  AUTH_CHANGE_STATUS: '/auth/change-status'
};

export const amoCrmEndpoints: typeof amoCrm = addControllerPrefix(amoCrm, controllers.AMO_CRM);

// company amo-task config
const companyAmoCrmConfig = {
  LEAD_URL: '/lead-url',
  LEAD_TEXT_AREA: '/lead-text-area'
};

export const companyAmoCrmConfigEndpoints: typeof companyAmoCrmConfig = addControllerPrefix(companyAmoCrmConfig, controllers.COMPANY_AMO_CRM_CONFIG);

// merchant
const merchant = {
  APELSIN: '/apelsin'
};

export const merchantEndpoints: typeof merchant = addControllerPrefix(merchant, controllers.MERCHANT);

// house task

// amoCrmHouse
export const amoCrmHouse = {
  ALL_DATA_BY_FILTER: '/all-data-by-filter',
  FLAT_DATA_BY: '/flat-data-by',
  X_AUTH_TOKEN: '/x-auth-token',
  CHECK_PERMISSION_DELAY: '/check-permission-delay'
};

export const amoCrmHouseEndpoints: typeof amoCrmHouse = addControllerPrefix(amoCrmHouse, controllers.HOUSE_SALE_CRM);

// website
const website = {
  GET_ALL_HOUSE: '/get-all-house',
  BLOCK_GET_ALL_FLAT_BY_FILTER: '/block/get-all-flat-by-filter',
  BLOCK_FLAT_ALL_DATA: '/block/flat-all-data',
  BLOCK_STATISTIC: '/block-statistic',
  BLOCK_FLOOR_STATISTIC: '/block-floor-statistic',
  BLOCK_FLAT_DATA: '/block-flat-data',
  FLAT_SHOURM_PDF: '/flat-shourum-pdf'
};

export const websiteEndpoints: typeof website = addControllerPrefix(website, controllers.WEBSITE);

// staff
export const staff = {
  INDEX: '/',
  FILTER: '/filter'
};

export const staffEndpoints: typeof staff = addControllerPrefix(staff, controllers.STAFF);

// kpi
export const kpi = {
  INDEX: '/',
  EDIT_CONSTANT_BONUS_AMOUNT: '/edit-constant-bonus-amount',
  EDIT_ARGUMENT: '/edit-argument',
  CREATE_KPI_PART: '/create-kpi-part',
  CHANGES_STATUS: '/change-status',
  ALL_SETTING: '/all-setting',
  DELETE: '/delete'
};

export const kpiEndpoints: typeof kpi = addControllerPrefix(kpi, controllers.EMPLOYEE_KPI);

// account
export const account = {
  INDEX: '/',
  INFO: '/info',
  PASSWORD: '/password'
};

export const accountEndpoints: typeof account = addControllerPrefix(account, controllers.ACCOUNT);

// mobile
export const mobile = {
  INDEX: '/',
  INDEBTEDNESS_MONTH: '/indebtedness/month',
  INDEBTEDNESS_ALL: '/indebtedness/all',
  CLIENT_BALANCE: '/client/balance',
  FLAT_SOLD: '/flat/sold',
  FLAT_BLANK: '/flat/blank',
  CLIENT_BOOKING: '/client/booking',
  PAYMENT: '/payment',
  PAYMENTS: '/payments',
  DEBT: '/debt',
  GRAPHIC: '/debt/stats',
  HOUSE: '/house',
  CONTRACT_DELAY: '/contract/delay',
  CONTRACT_CLIENT: '/contract/client',
  CONTRACT_PAYMENT_DELAY: '/contract/payment/delay',
  SALE_STATS: '/sale/stats',
  PAYMENT_TYPE: '/payment/type'
};

export const mobileEndpoints: typeof mobile = addControllerPrefix(mobile, controllers.MOBILE);

// mobile v2
export const mobileVersionTwo = {
  INDEX: '/',
  HOUSE_TOTAL_CAPITAL: '/house/total-capital',
  HOUSE_CAPITAL: '/house/capital',
  HOUSE_CAPITAL_SPLIT: '/house/capital/split',
  PAYMENT_CLIENTS: '/payment/clients',
  CONTRACT_HOUSE: '/contract/house',
  CONTRACT_PREPAYMENT_HOUSES: '/contract/prepayment/house'
};

export const mobileVersionTwoEndpoints: typeof mobileVersionTwo = addControllerPrefix(mobileVersionTwo, controllers.MOBILE_VERSION_TWO);

// mobile v3
export const mobileVersionThree = {
  INDEX: '/',
  CONTRACT_HOUSE: '/contract/house',
  PER_AREA_AVG_PRICE: '/house/per-area-avg-price'
};

export const mobileVersionThreeEndpoints: typeof mobileVersionThree = addControllerPrefix(mobileVersionThree, controllers.MOBILE_VERSION_THREE);

// task
export const task = {
  INDEX: '/',
  SIMPLE: '/simple',
  PAYMENT: '/payment',
  NOTE: '/note',
  CLOSE: '/close',
  EMPLOYEE: '/employee',
  CONTRACT_INFO: '/contract-info',
  CONTRACT: '/contract',
  CONTRACT_EVENT: '/contract-event',
  FILTER: '/notify/filter',
  FILTER_CRM: '/filter',
  COUNT: '/notify/count'
};

export const taskEndpoints: typeof task = addControllerPrefix(task, controllers.TASK);

// table-color
export const tableColor = {
  INDEX: '/'
};

export const tableColorEndpoints: typeof tableColor = addControllerPrefix(tableColor, controllers.TABLE_COLOR);

// auto-task-setting
export const autoTaskSetting = {
  INDEX: '/'
};

export const autoTaskSettingEndpoints: typeof autoTaskSetting = addControllerPrefix(autoTaskSetting, controllers.AUTO_TASK_SETTING);

// crm-status
export const crmPipe = {
  INDEX: '/',
  CREATE: '/create',
  ALL: '/all'
};

export const crmPipeEndpoints: typeof crmPipe = addControllerPrefix(crmPipe, controllers.PIPE);

// crm-lead
export const crmLead = {
  INDEX: '/',
  SAVE: '/save',
  FILTER: '/filter',
  CHANGE_STEP: '/change-step',
  EMPLOYEE: '/employee/all',
  NOT_TASK_EMPLOYEE: '/not-task/employee',
  DATA: '/data',
  TABLE_FILTERS: '/table-filter',
  TABLE_FILTERS_LEAD_IDS: '/table-filter/lead-ids',
  GROUP_ACTION: '/group-action',
  IMPORT: '/import',
  IMPORT_EXAMPLE: '/import-example',
  EXPORT: '/export',
  CHAT_BOX: '/chat-box',
  EXTRA: '/extra',
  LEAD_BY: '/by',
  GROUP: '/group-action',
  RECOVERING_DELETED: '/recovering-deleted',
  SEARCH: '/search'
};

export const crmLeadEndpoints: typeof crmLead = addControllerPrefix(crmLead, controllers.LEAD);

// crm
export const crm = {
  INDEX: '/',
  FIELD: '/field',
  FACEBOOK_ACCOUNT: '/facebook/account',
  FACEBOOK_REDIRECT_URL: '/facebook/redirect-url',
  FACEBOOK_PAGE_PIPE: '/facebook/page-pipe',
  FACEBOOK_PAGES: '/facebook/pages',
  FACEBOOK_LEAD_FORM: '/facebook/lead-form',
  FACEBOOK_LEAD_FORM_FIELD: '/facebook/lead-form/field',
  FACEBOOK_LEAD_FORM_PIPE_STATUS: '/facebook/lead-form/pipe-status',
  FACEBOOK_APP_PIPE: '/facebook/app-pipe',
  TRIGGERS_TASK_TRIGGER: '/triggers/task-trigger',
  TRIGGERS_PIPE_STATUS_TRIGGER: '/triggers/pipe-status-trigger',
  TRIGGERS_MANAGER_TRIGGER: '/triggers/manager-trigger',
  TRIGGERS_PIPE_STATUS_TRIGGERS: '/triggers/pipe-status-triggers',
  CALL_BROJECT_TRIGGER: '/triggers/call-trigger',
  TRIGGERS: '/triggers',
  TRIGGERS_TAG: '/triggers/tag',
  TRIGGERS_FIELD: '/triggers/field',
  TRIGGERS_EMPLOYEE: '/triggers/employee'
};

export const crmEndpoints: typeof crm = addControllerPrefix(crm, controllers.CRM);

// pipeStatus
export const pipeStatus = {
  INDEX: '/',
  SAVE: '/save',
  ALL: '/all'
};

export const pipeStatusEndpoints: typeof pipeStatus = addControllerPrefix(pipeStatus, controllers.PIPE_STATUS);

// tags
export const tag = {
  INDEX: '/',
  ALL: '/all'
};

export const tagEndpoints: typeof tag = addControllerPrefix(tag, controllers.TAG);

// leadTaskType

export const leadTaskType = {
  INDEX: '/',
  ALL: '/all',
  SAVE: '/save'
};

export const leadTaskTypeEndpoints: typeof leadTaskType = addControllerPrefix(leadTaskType, controllers.LEAD_TASK_TYPE);

// leadTask

export const leadTask = {
  INDEX: '/',
  SAVE: '/save',
  CLOSE: '/close',
  NOT_CLOSED: '/not-closed',
  FILTER: '/filter',
  FILTER_CALENDAR: '/filter-calendar',
  COUNT: '/count'
};

export const leadTaskEndpoints: typeof leadTask = addControllerPrefix(leadTask, controllers.LEAD_TASK);

// leadNote

export const leadNote = {
  INDEX: '/'
};
export const leadNoteEndpoints: typeof leadNote = addControllerPrefix(leadNote, controllers.LEAD_NOTE);

export const clientNote = {
  INDEX: '/'
};

export const clientNoteEndpoints: typeof leadNote = addControllerPrefix(clientNote, controllers.TASK_NOTE);

// chatMessage

export const chatMessage = {
  INDEX: '/',
  COUNT_NOT_READ: '/count/not-read'
};

export const chatMessageEndpoints: typeof chatMessage = addControllerPrefix(chatMessage, controllers.CHAT_MESSAGE);

// telegramAndWeb
export const telegramAndWeb = {
  INDEX: '/'
};

export const telegramAndWebEndpoints: typeof telegramAndWeb = addControllerPrefix(telegramAndWeb, controllers.TELEGRAM_AND_WEB);

// my calls
export const moiZvonki = {
  INDEX: '/',
  EMPLOYEE: '/employee',
  SET_EMPLOYEE: '/set-employee'
};

export const moiZvonkiEndpoints: typeof moiZvonki = addControllerPrefix(moiZvonki, controllers.MOI_ZVONKI_CONFIG);

// my calls send request
export const moiZvonkiSenRequest = {
  INDEX: '/',
  SEND_SMS: '/send-sms',
  MAKE_CALL: '/make-call'
};

export const moiZvonkiSenRequestEndpoints: typeof moiZvonkiSenRequest = addControllerPrefix(moiZvonkiSenRequest, controllers.MOI_ZVONKI_SEND_REQUEST);

// my message template
export const moiSmsTemplate = {
  INDEX: '/'
};

export const moiSmsTemplateEndpoints: typeof moiSmsTemplate = addControllerPrefix(moiSmsTemplate, controllers.MOI_SMS_TEMPLATE);

// notification
export const notification = {
  INDEX: '/',
  FILTER: '/filter',
  ME: '/me',
  ME_COUNT: '/me/count',
  ME_READ_ALL: '/me/read-all'
};

export const notificationEndpoints: typeof notification = addControllerPrefix(notification, controllers.NOTIFICATION);

// reasonsForRefusal
export const reasonsForRefusal = {
  INDEX: '/'
};

export const reasonsForRefusalEndpoints: typeof reasonsForRefusal = addControllerPrefix(reasonsForRefusal, controllers.REASONS_FOR_REFUSAL);

// online pbx
export const onlinePbx = {
  INDEX: '/',
  SET_EMPLOYEE: '/set-employee',
  PIPE_SETTING: '/pipe-setting',
  AUTH: '/auth',
  EMPLOYEE: '/employee',
  CONTACT: '/contact',
  CALL_HISTORY: '/call-history',
  CAN_MAKE_CALL: '/can-make-call',
  REFRESH_KEY: '/refresh-key'
};

export const onlinePbxEndpoints: typeof onlinePbx = addControllerPrefix(onlinePbx, controllers.ONLINE_PBX);

// booking-leadFlat
export const bookingLeadFlat = {
  INDEX: '/'
};

export const bookingLeadFlatEndpoints: typeof bookingLeadFlat = addControllerPrefix(bookingLeadFlat, controllers.BOOKING_LEAD_FLAT);

// marketplace
export const marketplace = {
  INDEX: '/',
  HOUSES: '/houses'
};

export const marketplaceEndpoints: typeof marketplace = addControllerPrefix(marketplace, controllers.MARKETPLACE);

// payment
export const payment = {
  INDEX: '/',
  TYPE_CONFIG: '/type/config'
};

export const paymentEndpoints: typeof payment = addControllerPrefix(payment, controllers.PAYMENT);

// payment
export const statistics = {
  INDEX: '/',
  PLAN_FACT_PIPE: '/plan-fact-pipe',
  PLAN_FACT_COST: '/plan-fact-cost',
  PLAN_FACT_PIPE_COMPANY_FIELD: '/plan-fact-pipe/company-field',
  PLAN_FACT_COST_COMPANY_LEAD_FIELD: '/plan-fact-cost/company-lead-field',
  PLAN_FACT_PIPE_ONE_LEAD_COST: '/plan-fact-pipe/one-lead-cost',
  PLAN_FACT_PIPE_PIPE: '/plan-fact-pipe/pipe',
  CUSTOMER_FLOW: '/customer-flow'
};

export const statisticsEndpoints: typeof statistics = addControllerPrefix(statistics, controllers.STATISTICS);

// cash
export const cash = {
  INDEX: ''
};

export const cashEndpoints: typeof cash = addControllerPrefix(cash, controllers.CASH);

// card
export const card = {
  INDEX: '',
  STYLE: '/style',
  STEP: '/step',
  PAYME_VERIFY: '/payme/verify',
  PAYME_ACTIVATE: '/payme/activate',
  MY_UZCARD_ACTIVATE: '/my-uzcard/activate',
  PAYME: '/payme',
  MY_UZCARD: '/my-uzcard',
  MY_UZCARD_VERIFY: '/my-uzcard/verify'
};

export const cardEndpoints: typeof card = addControllerPrefix(card, controllers.CARD);

// message-temp-controller
export const messageTemp = {
  INDEX: '/',
  FIELDS: '/fields'
};

export const messageTempEndpoints: typeof messageTemp = addControllerPrefix(messageTemp, controllers.CLIENT_MESSAGE_TEMP);

// message-temp-controller
export const uyqur = {
  INDEX: '/',
  BUILDERS: '/builders'
};

export const uyqurEndpoints: typeof uyqur = addControllerPrefix(uyqur, controllers.UYQUR);

// reset password
export const resetPassword = {
  INDEX: '/',
  VERIFY_CODE: '/verify-code',
  SEND_CODE: '/send-code',
  CHANGE_PASSWORD: '/change-password'
};

export const resetPasswordEndpoints: typeof resetPassword = addControllerPrefix(resetPassword, controllers.RESET_PASSWORD);

// currency
export const currency = {
  INDEX: '/',
  GLOBAL: '/global',
  BASIC: '/basic'
};

export const currencyEndpoints: typeof currency = addControllerPrefix(currency, controllers.CURRENCY);

// lead not task conf
export const leadNotTaskConf = {
  INDEX: '/',
  REFRESH_REMIND_TIME: '/refresh-remind-time'
};

export const leadNotTaskConfEndpoints: typeof leadNotTaskConf = addControllerPrefix(leadNotTaskConf, controllers.LEAD_NOT_TASK_CONF);

// lead not task conf
export const widget = {
  INDEX: ''
};

export const widgetEndpoints: typeof widget = addControllerPrefix(widget, controllers.WIDGET);

export const downloadpdf = {
  INDEX: '',
  V2: '/v2'
};

export const downloadpdfEndpoints: typeof downloadpdf = addControllerPrefix(downloadpdf, controllers.DOWNLOAD_PDF);

export const activeSession = {
  INDEX: ''
};

export const activeSessionEndpoints: typeof activeSession = addControllerPrefix(activeSession, controllers.ACTIVE_SESSION);

export const reportConfig = {
  INDEX: '/'
};

export const reportConfigEndpoints: typeof reportConfig = addControllerPrefix(reportConfig, controllers.REPORT_CONFIG);

export const monetaryConfig = {
  CONFIG: '/config'
};

export const monetaryConfigEndpoints: typeof monetaryConfig = addControllerPrefix(monetaryConfig, controllers.MONETARY);

export const monetary = {
  PAYMENT: '/payment',
  PAYMENT_FILTER: '/payment/filter',
  PAYMENT_FILTER_COUNT: '/payment/filter/count',
  PAYMENT_CREATE: '/payment/create',
  PAYMENT_CONTRACT: '/payment/contract',
  PAYMENT_ACCEPT: '/payment/accept',
  PAYMENT_ROLLBACK: '/payment/rollback'
};

export const monetaryEndpoints: typeof monetary = addControllerPrefix(monetary, controllers.MONETARY);

export const realtorCongig = {
  INDEX: '/',
  CHECH_PERMISSION: '/check-permission/'
};

export const realtorCongigEndpoints: typeof realtorCongig = addControllerPrefix(realtorCongig, controllers.REALTOR_CONFIG);

export const callHistory = {
  INDEX: '/',
  GET_RECORD: '/get-record'
};

export const myHomeConfig = {
  INDEX: '',
  HOME_CONFIG: '/my-home/config'
};

export const myHomeConfigEndPoints: typeof myHomeConfig = addControllerPrefix(myHomeConfig, controllers.MY_HOME_CONFIG);

export const callHistoryEndpoints: typeof callHistory = addControllerPrefix(callHistory, controllers.CALL_HITORY);

export const saleEndPoints = {
  HOUSE_BUILDING: 'v3/mobile/sale-plan/flat/house-building'
};
