import React from 'react';

interface Props {
  fill: string;
}

const FileIconCrm: React.FC<Props> = ({ fill }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.1104" cy="12.1104" r="12.1104" fill={fill} />
      <rect x="18.0815" y="5.60938" width="3" height="11.9428" rx="1.5" transform="rotate(90 18.0815 5.60938)" fill="white" />
      <rect x="18.082" y="10.6094" width="3" height="11.9432" rx="1.5" transform="rotate(90 18.082 10.6094)" fill="white" />
      <rect x="18.0815" y="15.6094" width="3" height="11.943" rx="1.5" transform="rotate(90 18.0815 15.6094)" fill="white" />
    </svg>
  );
};

export default FileIconCrm;
