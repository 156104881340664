import React from 'react';
import { Col, Row, Spin } from 'antd';

import { useGetProfile } from '../../queries/queries';

import Avatar from './avatar/Avatar';
import Information from './Information';

import styles from './userInformation.module.scss';

const UserInformation = () => {
  const { isLoading, data, isFetching, refetch } = useGetProfile();

  return (
    <Spin spinning={isLoading || isFetching}>
      <div className={styles.container}>
        <Row>
          <Col span={10}>
            <Avatar profile={data} refetch={refetch} />
          </Col>
          <Col span={10}>
            <Information profile={data} />
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default UserInformation;
