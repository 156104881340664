import CompanyNotification from '@components/companyConfig/company-notification/CompanyNotification';
import ContractPaymentType from '@pages/company-config/pages/contract-payment-type/ContractPaymentType';

import CompanySettingTemplates from '../../components/companyConfig/CompanySettingTemplates';
import ContractFields from '../../components/companyConfig/contractFields/ContractFields';
import CompanyConfigNumeration from '../../components/companyConfig/numeration/CompanyConfigNumerationCont';
import Surcharge from '../../components/companyConfig/surchage/Surcharge';

import { CompanyConfigPaths } from './companyConfigPaths';

export const CompanyConfigRoutes = [
  {
    component: CompanySettingTemplates,
    path: CompanyConfigPaths.TEMPLATES,
    exact: false
  },
  {
    component: CompanyConfigNumeration,
    path: CompanyConfigPaths.TEMPLATE_NUMERATION,
    exact: false
  },
  {
    component: Surcharge,
    path: CompanyConfigPaths.SURCHARGE,
    exact: false
  },
  {
    component: ContractFields,
    path: CompanyConfigPaths.CONTRACT_FIELDS,
    exact: false
  },
  {
    component: CompanyNotification,
    path: CompanyConfigPaths.CONTRACT_CONFIG,
    exact: false
  },
  {
    component: ContractPaymentType,
    path: CompanyConfigPaths.CONTRACT_PAYMENT_TYPE,
    exact: false
  }
];
