import { Modal } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import styles from './taskChange.module.scss';
import CheckIcon from '../../../../assets/icons/CheckIcon';
import { useTranslation } from 'react-i18next';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { useQueryParam } from '@hooks/useQueryParams';
import { useGroupActionCrmViewList } from '@pages/crm/services/mutation';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';
import { useQueryClient } from 'react-query';

type Props = {
  visible?: boolean;
  employeeId?: number;
  ids?: number[];
  onCancel: () => void;
  handleSuccess: () => void;
  setVisible: Dispatch<SetStateAction<boolean>>;
};
const TaskChange: React.FC<Props> = ({ visible, employeeId, ids, onCancel, handleSuccess, setVisible }) => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const changeEmployee = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);
  const pipeStatusIds = JSON.parse(localStorage.getItem('pipeIds')!);

  const onTransfer = () => {
    changeEmployee
      .mutateAsync({
        leadIds: ids!,
        requestMethodType: RequestMethodTypeGroupActions.CHANGED_RESPONSIBLE_EMPLOYEE,
        responsibleEmployeeId: employeeId
      })
      .then(() => {
        // dispatch(setBalanceAndIds({ ids: ids! }));
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
        setVisible(false);
      });
  };

  const onAttach = () => {
    changeEmployee
      .mutateAsync({
        leadIds: ids!,
        requestMethodType: RequestMethodTypeGroupActions.CHANGED_RESPONSIBLE_EMPLOYEE,
        responsibleEmployeeId: employeeId,
        changeTaskResponsible: true
      })
      .then(() => {
        // dispatch(setBalanceAndIds({ ids: ids! }));
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
        setVisible(false);
      });
  };
  return (
    <Modal visible={visible} footer={false} className={styles.task_change_modal} onCancel={onCancel}>
      <div className={styles.task_change_modal_body}>
        <div className={styles.body_header}>
          <CheckIcon />
          <span>{t('home.Lidga_tegishli_topshiriqlarni_yangi_masul_xodimga_biriktirasizni')}</span>
        </div>
        <div className={styles.body_footer}>
          <span className={styles.footer_otkazish} onClick={onTransfer}>
            {t('home.Otkazish')}
          </span>
          <span className={styles.footer_biriktirish} onClick={onAttach}>
            {t('home.Biriktirish')}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default TaskChange;
