import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input, Modal } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import CheckmarkIcon from '../../../../../../assets/icons/crmIcons/CheckmarkIcon';
import { crmIcons } from '../../../../../../assets/icons/crmIcons/crmIcons';
import MDatePicker from '../../../../../../components/mDatePicker/MDatePicker';
import { useQueryParam } from '../../../../../../hooks/useQueryParams';
import { useCrmEmployee, useLeadTaskType } from '../../../../../../queries/queries';
import { LocalStorage } from '../../../../../../service/LocalStorage';
import { USER_KEY } from '../../../../../../utils/constants/localStorageKeys';
import { RequestMethodTypeGroupActions } from '../../../../../../utils/models/ListViewCrm';
import { useGroupActionCrmViewList } from '../../../../services/mutation';
import { SearchParamsCrm } from '../../../filter/FilterButton';

import styles from './modals.module.scss';
import { setBalanceAndIds } from '@reducers/CrmSlice';
import { useAppDispatch } from '@hooks/reduxHooks';
import { useQueryClient } from 'react-query';
import { queryKeys } from '@pages/crm/utils/constants/queryKeys';

interface IProps {
  visible: boolean;
  close: (visible: boolean) => void;
  ids: number[];
  balance: number | undefined;
}

const CreateTask: FC<IProps> = ({ visible, close, ids, balance }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();
  const qc = useQueryClient();

  const fromId = LocalStorage.get(USER_KEY) ? LocalStorage.get(USER_KEY).id : 0;

  const { data: leadTaskTypeAll } = useLeadTaskType();
  const { data: leadEmployeeList } = useCrmEmployee();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const createTask = useGroupActionCrmViewList(Number(searchParams.voronka), searchParams, handleSuccess);

  const [text, setText] = useState('');
  const [mPickerVisible, setMPickerVisible] = useState(false);
  const [taskMeetingSelectVisible, setTaskMeetingSelectVisible] = useState(false);
  const [taskMeetingSelectActiveId, setTaskMeetingSelectActiveId] = useState(leadTaskTypeAll ? leadTaskTypeAll![0]?.id : 0);
  const [activeEmployeeId, setActiveEmployeeId] = useState(fromId);
  const [employeeSelectVisible, setEmployeeSelectVisible] = useState(false);
  const [dateValue, setDateValue] = useState('Bugun');
  const [createDeadline, setCreateDeadline] = useState({
    start: dayjs().hour(0).minute(0).format('DD.MM.YYYY HH:mm'),
    end: dayjs().hour(23).minute(59).format('DD.MM.YYYY HH:mm')
  });
  const pipeStatusIds = JSON.parse(localStorage.getItem('pipeIds')!);

  const containerFooterTopRef = useRef<any>(null);
  const inputRef = useRef<TextAreaRef>(null);

  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setTaskMeetingSelectActiveId(leadTaskTypeAll![0]?.id);
    }
  }, [leadTaskTypeAll]);

  // translateFor
  const translateFor = (name: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            для <span>{name}</span>:
          </>
        );
      case 'en':
        return (
          <>
            for <span>{name}</span>:
          </>
        );
      default:
        return (
          <>
            <span>{name}</span> uchun:
          </>
        );
    }
  };

  // handle success
  function handleSuccess() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleCancel();
    setText('');
  }

  // handleTaskMeetingSelect
  const handleTaskMeetingSelect = () => {
    setTaskMeetingSelectVisible(true);
  };

  // handleTaskMeetingSelectItem
  const handleTaskMeetingSelectItem = (id: number) => {
    setTaskMeetingSelectVisible(false);
    setTaskMeetingSelectActiveId(id);
  };

  // handleEmployeeSelect
  const handleEmployeeSelect = () => {
    setEmployeeSelectVisible(true);
  };

  // handleEmployee
  const handleEmployee = (id: number) => {
    setEmployeeSelectVisible(false);
    setActiveEmployeeId(id);
  };

  // handleMPicker
  const handleMPicker = () => {
    setMPickerVisible(true);
  };

  // changeText
  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  // handleCreateTask
  const handleCreateTask = () => {
    createTask
      .mutateAsync({
        leadIds: ids,
        requestMethodType: RequestMethodTypeGroupActions.CREATE_TASK_FOR_LEAD,
        text,
        assignedById: activeEmployeeId,
        deadlineStart: createDeadline.start,
        deadlineFinish: createDeadline.end,
        leadTaskTypeId: taskMeetingSelectActiveId
      })
      .then(() => {
        dispatch(setBalanceAndIds({ ids: ids, balance: balance }));
        pipeStatusIds?.forEach((i: any) => {
          qc.invalidateQueries([queryKeys.CRM_LEAD_FILTER, i, searchParams]);
        });
      });
  };

  // onChangeMPicker
  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(`${date} ${start} ${end}`);
    setCreateDeadline({
      start: `${date} ${start}`,
      end: `${date} ${end}`
    });
  };

  // handleChatFooter
  const handleChatFooter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  // keyDownText
  const keyDownText = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();
      handleCreateTask();
    } else if (e.key === 'Escape') {
      setText('');
      inputRef.current?.blur();
    }
  };

  // handle cancel modal
  const handleCancel = () => {
    close(false);
  };

  return (
    <Modal className={styles.container} width={710} footer={false} visible={visible} title={t('one_task.Topshiriq_yaratish')} onCancel={handleCancel}>
      <div className={styles.container} onClick={e => handleChatFooter(e)}>
        <div className={styles.container_footer}>
          <div className={styles.container_content}>
            <div className={styles.container_footer_top} ref={containerFooterTopRef}>
              <Dropdown
                visible={mPickerVisible}
                onVisibleChange={setMPickerVisible}
                overlay={
                  <div>
                    <MDatePicker visibleTime={mPickerVisible} onChangeMPicker={onChangeMPicker} />
                  </div>
                }
                trigger={['click']}
                placement="topCenter"
              >
                <Input
                  className={styles.container_footer_top_taskInput}
                  onClick={handleMPicker}
                  value={dateValue}
                  style={{
                    width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 14.5
                  }}
                />
              </Dropdown>
              <Dropdown
                visible={employeeSelectVisible}
                onVisibleChange={visible => setEmployeeSelectVisible(visible)}
                overlay={
                  <div className={styles.container_footer_top_taskEmployee_content}>
                    {leadEmployeeList!?.map(item => (
                      <div key={item.id} onClick={() => handleEmployee(item.id)}>
                        {item.fullName}{' '}
                        {activeEmployeeId === item.id && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                trigger={['click']}
                placement="topLeft"
              >
                <div className={styles.container_footer_top_taskEmployee} onClick={handleEmployeeSelect}>
                  {translateFor(leadEmployeeList!?.find(item => item.id === activeEmployeeId)?.fullName || '')}
                </div>
              </Dropdown>
              <Dropdown
                trigger={['click']}
                visible={taskMeetingSelectVisible}
                onVisibleChange={visible => setTaskMeetingSelectVisible(visible)}
                overlay={
                  <div className={styles.container_footer_top_taskMeetingSelect_content}>
                    {leadTaskTypeAll?.map(item => (
                      <div key={item.id} onClick={() => handleTaskMeetingSelectItem(item.id)}>
                        {crmIcons[item.icon.split('#')[0]]?.icon(`#${item.icon.split('#')[1]}`)}{' '}
                        {item.deleted ? item.name : t(`one_lid.${item.name}`)}
                        {taskMeetingSelectActiveId === item.id && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                placement="topLeft"
              >
                <div className={styles.container_footer_top_taskMeetingSelect} onClick={handleTaskMeetingSelect}>
                  {crmIcons[leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[0]!]?.icon(
                    `#${leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[1]}`
                  )}
                  {leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.deleted
                    ? leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name
                    : t(`one_lid.${leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name}`)}
                </div>
              </Dropdown>
            </div>
            <Input.TextArea
              className={styles.container_footer_textArea}
              placeholder={t('one_lid.Matn_kiriting')}
              autoSize={{ minRows: 4 }}
              onChange={e => changeText(e)}
              onKeyUp={e => keyDownText(e)}
              ref={inputRef}
              value={text}
            />
          </div>
        </div>
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button loading={createTask.isLoading} disabled={!text} type="primary" onClick={handleCreateTask}>
              {t('one_lid.Yuborish')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTask;
