import { Button, Modal, Radio, Table } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MessageIcon from '../../../../assets/icons/MessageIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useContractMonthlyPayment } from '../../../../queries/queries';
import { ContractMonthlyPayment } from '../../../../utils/models/ContractMothlyPayment';
import { MonthlyPayment, MonthlyPaymentStatus } from '../../../../utils/models/CreateContract';
import CommentModal from '../../../contractId/CommentModal';
import styles from './contractIdHeader.module.scss';

interface ContractIdPaymentScheduleArchiveProps {
  visible: boolean;
  setVisible: (i: boolean) => void;
}

const ContractIdPaymentScheduleArchive: FC<ContractIdPaymentScheduleArchiveProps> = ({ visible, setVisible }) => {
  const [commentModal, setCommentModal] = useState(false);
  const [comment, setcomment] = useState<string>();
  const [commentName, setcommentName] = useState<string>();
  const [commentDate, setcommentDate] = useState<string>();
  const [data, setData] = useState<MonthlyPayment[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const { params } = useQueryParam();
  const { data: paymentArchiveData, isLoading } = useContractMonthlyPayment(params.id, MonthlyPaymentStatus.ARCHIVE);

  const initialColumns = [
    {
      title: '№',
      dataIndex: 'number',
      width: 100,
      render: (i: any, a: any, b: any) => <>{b + 1}</>
    },
    {
      title: t('contract_id.Tolov_qilingan_sana'),
      dataIndex: 'date'
    },
    {
      title: t('home.Tolov_summasi_sum'),
      dataIndex: 'amount',
      render: (i: any) => <>{i.toLocaleString()}</>
    },
    {
      title: t('contract_id.Tolangan_summa_som'),
      dataIndex: 'payment',
      render: (i: any, a: any) => <>{i === 0 ? '---' : <span style={{ color: a.paid ? '#00A389' : '#FFBB54' }}>{i.toLocaleString()}</span>}</>
    }
  ];

  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    const newColumns = [...initialColumns];
    newColumns[0] = {
      title: '№',
      dataIndex: 'number',
      width: 100,
      render: (i: any, a: any, b: any) => <>{(currentPage - 1) * 4 + b + 1}</>
    };
    setColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    paymentArchiveData?.forEach((e: ContractMonthlyPayment) => {
      setData(e.monthlyPaymentPartDtos);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleCurrentPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const changePayment = (i: any) => {
    paymentArchiveData?.forEach((e: ContractMonthlyPayment) => {
      if (e.id === i.target.value) {
        setData(e.monthlyPaymentPartDtos);
      }
    });
  };

  return (
    <>
      <Modal
        wrapClassName="ContractIdPaymentScheduleArchive"
        title={false}
        visible={visible}
        footer={null}
        closable={false}
        width={740}
        onCancel={handleCancel}
      >
        <h1>{t('contract_id.Tolov_jadvali_arxivi')}</h1>

        <Radio.Group defaultValue={1} onChange={changePayment}>
          {paymentArchiveData?.map((i: ContractMonthlyPayment, number: any) => (
            <Radio.Button value={i.id}>
              <h1>
                {number + 1}-{t('contract_id.Tolov_jadvali')}{' '}
                <span
                  onClick={(e: any) => {
                    setcomment(i.canceledText);
                    setcommentDate(i.cancelledDate);
                    setcommentName(i.cancelledBy);
                    setCommentModal(true);
                    e.stopPropagation();
                  }}
                >
                  <MessageIcon />
                </span>
              </h1>
              <p>
                {t('contract_id.Yaratilgan_sana')} <span>{i.createdDate}</span>
              </p>
              <p>
                {t('Bekor_qilingan_sana')} <span>{i.cancelledDate}</span>
              </p>
            </Radio.Button>
          ))}
        </Radio.Group>
        <Table
          className="custom_table_modal pagination_MCSS"
          columns={columns}
          dataSource={data}
          size="small"
          pagination={{
            onChange: handleCurrentPageChange,
            pageSize: 4,
            position: ['bottomLeft']
          }}
          style={{ marginBottom: 16 }}
          scroll={{ y: 'calc(100vh - 300px)' }}
        />
        <div className={styles.ContractIdPaymentScheduleArchiveFooter} style={{ marginTop: data.length === 0 ? 0 : '-50px' }}>
          <Button type="primary" onClick={handleCancel}>
            {t('arrearage.Yopish')}
          </Button>
        </div>
      </Modal>
      <CommentModal visible={commentModal} setVisible={setCommentModal} comment={comment} name={commentName} date={commentDate} />
    </>
  );
};

export default ContractIdPaymentScheduleArchive;
