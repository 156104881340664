import { useState } from 'react';
import Icon from '@components/icon/icon';
import { useTranslation } from 'react-i18next';

import { usePaylovConfig } from 'modules/payment/paylov';

import PaylovConfigItem from './PaylovConfigItem';

// eslint-disable-next-line import/order
import paylov from '@images/paylov.png';

const PaylovConfig = () => {
  const [created, setCreated] = useState(false);
  const { t } = useTranslation();
  const { data } = usePaylovConfig();

  return (
    <div className="flex items-start justify-start gap-2">
      <div className="flex h-[148px]  min-w-[280px] items-center justify-center rounded-md bg-white">
        <img src={paylov} alt="" />
      </div>
      {created || (data && data?.length > 0) ? (
        <div className="inline-flex w-full flex-col  justify-start gap-4 self-stretch rounded-md bg-white px-11 py-6">
          {data?.map(item => <PaylovConfigItem key={item?.id} item={item} />)}
          <PaylovConfigItem isCreated={data && data?.length < 1} />
        </div>
      ) : (
        <div
          className="flex w-full cursor-pointer items-center justify-center gap-[8px] self-stretch rounded-md bg-white px-11 py-6 text-[20px] text-[#00a389]"
          onClick={() => setCreated(true)}
        >
          <Icon name="PlusIcon" className="h-[32px] w-[32px]" /> {t('crm.Yangi_yaratish')}
        </div>
      )}
    </div>
  );
};

export default PaylovConfig;
