import React, { useEffect, useState } from 'react';
import styles from '../../../components/filters/mainFilters.module.scss';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import OrderFilterDrawer from './OrderFilterDrawer';
import { CloseOutlined } from '@ant-design/icons';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { SearchParamsOrder } from '../Order';
import { useCurrencyList, useHousesNameAndID } from '../../../queries/queries';
import FilterItemClose from '../../../assets/icons/FilterItemClose';
import SearchIcon from '../../../assets/icons/SearchIcon';
import Filter from '../../../assets/icons/Filter';
import Broom from '../../../assets/icons/Broom';

const OrderFilters = () => {
  const { searchParams, remove, removeAllSearchParams, removeMultiple, appendSingle } = useQueryParam<SearchParamsOrder, any>();
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [countFilters, setCountFilters] = useState(1);

  const { t } = useTranslation();
  const { data: houses } = useHousesNameAndID();
  const { data: currency } = useCurrencyList();

  const itemHas = () => {
    const newSearchParams = { ...searchParams };
    delete newSearchParams.page;
    delete newSearchParams.size;
    delete newSearchParams.search;
    delete newSearchParams.order;
    delete newSearchParams.buildings;
    delete newSearchParams.responsibleByIds;
    return Object.keys(newSearchParams).length > 0;
  };

  const handleFilter = () => {
    setFilterDrawerVisible(true);
  };

  useEffect(() => {
    let count = 0;
    if (searchParams.houses) {
      if (Array.isArray(searchParams.houses)) {
        count = searchParams.houses.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.responsibleByIds) {
      if (Array.isArray(searchParams.responsibleByIds)) {
        count = searchParams.responsibleByIds.length;
      } else {
        count = 1;
      }
    }
    if (searchParams.statuses) {
      if (Array.isArray(searchParams.statuses)) {
        count += searchParams.statuses.length;
      } else {
        count += 1;
      }
    }

    if (searchParams.currencies) {
      if (Array.isArray(searchParams.currencies)) {
        count = searchParams.currencies.length;
      } else {
        count = 1;
      }
    }

    if (searchParams.startDate && searchParams.finishDate) {
      count += 1;
    }
    setCountFilters(count);
  }, [searchParams]);

  const showHouses = () => {
    if (searchParams.houses) {
      if (!Array.isArray(searchParams.houses)) {
        const houseName = houses?.find(house => String(house.id) === (searchParams.houses as any));
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{houseName?.name}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('houses', searchParams.houses as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } else {
        return searchParams.houses.map(houseId => {
          const houseName = houses?.find(house => String(house.id) === (houseId as any));
          return (
            <div className={`${styles.item} d-flex align-center`} key={houseId}>
              <span className={styles.title}>{houseName?.name}</span>
              <FilterItemClose onClick={() => deleteOneFilter('houses', houseId)} className={styles.icon} />
            </div>
          );
        });
      }
    }
    return null;
  };

  const showOrderStatus = () => {
    if (searchParams.statuses) {
      if (!Array.isArray(searchParams.statuses)) {
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{t(`home.${searchParams.statuses as string}`)}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('statuses', searchParams.statuses as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } else {
        return searchParams.statuses.map(status => {
          return (
            <div className={`${styles.item} d-flex align-center`} key={status}>
              <span className={styles.title}>{t(`home.${status}`)}</span>
              <FilterItemClose onClick={() => deleteOneFilter('statuses', status)} className={styles.icon} />
            </div>
          );
        });
      }
    }
    return null;
  };

  const showDates = () => {
    if (searchParams.startDate && searchParams.finishDate) {
      return (
        <div className={`${styles.item} d-flex align-center`}>
          <span className={styles.title}>
            {searchParams.startDate}-{searchParams.finishDate}
          </span>
          <FilterItemClose
            onClick={() => deleteMultipleFilters(['startDate', 'finishDate'], [searchParams.startDate as string, searchParams.finishDate as string])}
            className={styles.icon}
          />
        </div>
      );
    }
  };

  const showCurrencies = () => {
    if (searchParams.currencies) {
      if (!Array.isArray(searchParams.currencies)) {
        const currencyName = currency?.find(crnc => String(crnc.id) === (searchParams.currencies as any));
        return (
          <div className={`${styles.item} d-flex align-center`}>
            <span className={styles.title}>{currencyName?.ccy}</span>
            <FilterItemClose
              onClick={() => {
                deleteOneFilter('currencies', searchParams.currencies as any);
              }}
              className={styles.icon}
            />
          </div>
        );
      } else {
        return searchParams.currencies.map(ids => {
          const currencyName = currency?.find(crnc => String(crnc.id) === ids);
          return (
            <div className={`${styles.item} d-flex align-center`} key={ids}>
              <span className={styles.title}>{currencyName?.ccy}</span>
              <FilterItemClose onClick={() => deleteOneFilter('currencies', ids)} className={styles.icon} />
            </div>
          );
        });
      }
    }
    return null;
  };

  const showFilters = () => {
    const statuses = showOrderStatus();
    const dates = showDates();
    const houses = showHouses();
    const currencies = showCurrencies();
    return (
      <>
        {statuses}
        {houses}
        {dates}
        {currencies}
      </>
    );
  };

  const deleteOneFilter = (key: string, value: string) => {
    remove(key, value);
  };

  const deleteMultipleFilters = (key: string[], value: string[]) => {
    removeMultiple(key, value);
  };

  const handleSearch = (value: string) => {
    setTimeout(() => {
      if (value) appendSingle('search', value);
      else remove('search', undefined);
    });
  };

  const clearAllFilter = () => {
    removeAllSearchParams();
  };

  const showIconInput = () => {
    if (searchParams.search) return <CloseOutlined onClick={() => handleSearch('')} className={'deleteSearchIcon'} />;
    return <SearchIcon />;
  };

  return (
    <div className={styles.cont}>
      <OrderFilterDrawer visible={filterDrawerVisible} setVisible={setFilterDrawerVisible} />
      <div className={'d-flex align-center'}>
        <div
          onClick={() => handleFilter()}
          className={`${countFilters > 0 ? styles.filter_button + ' ' + styles.active : styles.filter_button} d-flex align-center`}
        >
          <Filter />
          <span className={styles.title}>{t('home.Filtr')}</span>
          {countFilters > 0 && <span className={styles.count_filters}>{countFilters}</span>}
        </div>
        <Input
          value={searchParams.search}
          onChange={e => handleSearch(e.target.value)}
          suffix={showIconInput()}
          placeholder={t('home.Qidirish')}
          className={styles.search}
        />
        {itemHas() ? (
          <div className={`${styles.filtered_items_cont_right} d-flex align-center`}>
            {showFilters()}
            <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
              <Broom />
            </div>
            <div className={styles.line} />
          </div>
        ) : (
          <div className={styles.not_select_title_right}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
        )}
      </div>
      {itemHas() ? (
        <div className={`${styles.filtered_items_cont} d-flex align-center`}>
          {showFilters()}
          <div onClick={clearAllFilter} className={`${styles.clear} d-flex align-center justify-center`}>
            <Broom />
          </div>
          <div className={styles.line} />
        </div>
      ) : (
        <div className={styles.not_select_title}>{t('home.Filtlash_orqali_qidiruvni_soddalashtiring')}</div>
      )}
    </div>
  );
};

export default OrderFilters;
