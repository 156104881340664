const EditIconTaskCreator = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3954 15.0973C1.43368 14.7527 1.45283 14.5804 1.50496 14.4194C1.55121 14.2765 1.61656 14.1405 1.69923 14.0152C1.79241 13.8739 1.91499 13.7513 2.16014 13.5061L13.1654 2.5009C14.0859 1.58043 15.5782 1.58043 16.4987 2.5009C17.4192 3.42138 17.4192 4.91376 16.4987 5.83424L5.49347 16.8395C5.24832 17.0846 5.12574 17.2072 4.98444 17.3004C4.85907 17.383 4.72311 17.4484 4.58024 17.4946C4.4192 17.5468 4.24691 17.5659 3.90234 17.6042L1.08203 17.9176L1.3954 15.0973Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditIconTaskCreator;
