import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@hooks/reduxHooks';
// import { setChecked, setDebtActiveIds, setDebtIgnoreIds } from '@reducers/DebtSlice';
import { Button, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import CloseArreaRageSmsPopoverIcon from '../../../assets/icons/arreaReageSmsPopover/closeArreaRageSmsPopoverIcon';
import SMSNotification from '../../../assets/icons/SMSNotification';
// import SMSTrackingIcon from '../../../assets/icons/SMSTrackingIcon';
import { useQueryParam } from '../../../hooks/useQueryParams';
import { SearchParamsArrearage } from '../Arrearage';
import { useSendMessageToAllDebtor } from '../services/mutation';

import styles from '../arrearage.module.scss';

interface IProps {
  children: ReactElement;
  count?: number;
}

const ArrearageSmsPopover = ({ children, count }: IProps) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { searchParams } = useQueryParam<SearchParamsArrearage, any>();
  const sendMessageMutation = useSendMessageToAllDebtor(searchParams);
  const { debtListActiveIds: activeIds, debtListIgnoredIds } = useAppSelector(state => state.debtSlice);

  const handleCancel = () => {
    setVisible(false);
  };

  // sending message to all debtors
  const sendMessage = () => {
    sendMessageMutation.mutate(
      {
        contracts: activeIds,
        ignoreContracts: debtListIgnoredIds
      },
      {
        onSuccess: () => {
          handleCancel();
          // dispatch(setDebtActiveIds({ ids: [] }));
          // dispatch(setDebtIgnoreIds([]));
          // dispatch(setChecked(false));
        }
      }
    );
  };

  return (
    <Popover
      overlayClassName="arrearageSmsPopover"
      content={
        <>
          <div className={styles.arrearageSmsPopover_item}>
            <span className={styles.sms}>
              <SMSNotification />
            </span>
            <span className={styles.exit} onClick={handleCancel}>
              <CloseArreaRageSmsPopoverIcon />
            </span>
          </div>
          <h1>
            {/* {t('arrearage.Barcha_qarzdor_mijozlarga_SMS_xabar_yubormoqchimisiz')}  */}
            {count && count > 1
              ? t('arrearage.ta_qarzdor_mijozlarga_SMS_xabar_yubormoqchimisiz', { count })
              : t('arrearage.ta_qarzdor_mijozga_SMS_xabar_yubormoqchimisiz', { count })}
          </h1>

          <div>
            <Button className={styles.cancel} onClick={handleCancel}>
              {t('arrearage.Yopish')}
            </Button>
            <Button onClick={sendMessage} type="primary" loading={sendMessageMutation.isLoading}>
              {t('arrearage.Tasdiqlash')}
            </Button>
          </div>
        </>
      }
      title={false}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      placement="bottomRight"
    >
      {/* <div onClick={() => setVisible(true)} className={styles.sendMessageButton}>
        <SMSTrackingIcon className={styles.sendMessageButton_icon} />
      </div> */}
      {React.cloneElement(children, { onClick: () => setVisible(true) })}
    </Popover>
  );
};

export default ArrearageSmsPopover;
