import React from 'react';

const CheveronDownIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99434 8.23587C5.87882 8.23486 5.76821 8.18902 5.68584 8.10801L1.3222 3.74438C1.24271 3.66208 1.19873 3.55185 1.19972 3.43744C1.20072 3.32302 1.24661 3.21358 1.32752 3.13267C1.40842 3.05177 1.51787 3.00588 1.63228 3.00488C1.74669 3.00389 1.85692 3.04787 1.93922 3.12736L5.99434 7.18248L10.0495 3.12736C10.0897 3.08568 10.1379 3.05244 10.1911 3.02957C10.2444 3.0067 10.3016 2.99466 10.3596 2.99416C10.4175 2.99365 10.475 3.00469 10.5286 3.02664C10.5822 3.04858 10.6309 3.08098 10.6719 3.12195C10.7129 3.16292 10.7453 3.21164 10.7672 3.26527C10.7892 3.3189 10.8002 3.37636 10.7997 3.4343C10.7992 3.49224 10.7871 3.5495 10.7643 3.60273C10.7414 3.65597 10.7082 3.70412 10.6665 3.74438L6.30285 8.10801C6.22048 8.18902 6.10987 8.23486 5.99434 8.23587Z"
        fill="#464255"
      />
    </svg>
  );
};

export default CheveronDownIcon;
