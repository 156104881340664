export interface MessageList {
  id: number;
  clientName: string;
  phone: string;
  text: string;
  sendDate: string;
  employee: string;
  status: MessageStatus;
}

export enum MessageStatus {
  WILL_BE_SENT = 'WILL_BE_SENT',
  IS_BEING_SENT = 'IS_BEING_SENT',
  WAS_SENT = 'WAS_SENT',
  NOT_SENT = 'NOT_SENT',
  WRONG_MESSAGE = 'WRONG_MESSAGE',
  CANCELED = 'CANCELED'
}

export enum SendMessageAllTypes {
  ALL = 'ALL',
  CONTRACT = 'CONTRACT',
  NO_CONTRACT = 'NO_CONTRACT'
}
