import React, { useEffect, useState } from 'react';
import { crmIcons } from '@icons/crmIcons/crmIcons';
import { ResponsivePie } from '@nivo/pie';
import { LeadTasksModelEmployeeTask } from '@pages/bossCrm/utils/models/LeadTasksModel';
import { useLeadTaskType } from '@queries/queries';
import { Segmented, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Employee from '../../../../components/bossCrm/pipes/header/Employee';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { colors } from '../../../../utils/constants/showRoomColors';
import { useGetLeadTasks } from '../../services/queries';
import Header3 from '../header/Header3';

import { TaskStatus } from './taskStatus';

import styles from './taskStatistics.module.scss';

const TasksStatistics = () => {
  const { t } = useTranslation();
  const [newData, setNewData] = useState<LeadTasksModelEmployeeTask[]>([]);
  const [taskStatus, setTaskStatus] = useState<any>(TaskStatus.ALL);
  const handleChangeStatus = (val: any) => {
    setTaskStatus(val);
  };
  const employeeId = useAppSelector(state => state.crmStatistic.employeeId);
  const { start1, finish1 } = useAppSelector(state => state.crmStatistic);

  const { data: task, isLoading } = useGetLeadTasks(employeeId ? [employeeId] : null, taskStatus, `${start1}`, `${finish1}`);
  const { data: leadTaskTypeAll } = useLeadTaskType();

  useEffect(() => {
    if (task?.data?.employeeTask && task?.data?.employeeTask?.length > 0) {
      const newTask: LeadTasksModelEmployeeTask[] = task?.data?.employeeTask?.map((item, index) => ({
        ...item,
        employee: item?.id,
        id: item?.name,
        value: item?.taskNumber,
        label: item?.name,
        color: colors[index] ? colors[index] : colors[Math.floor(Math.random() * colors.length)]
      }));

      setNewData(newTask);
    }
  }, [task]);
  const defaultTask = leadTaskTypeAll?.map(item => ({
    ...item,
    name: item?.name === 'Uchrashuv' || item?.name === 'To’lov' || item?.name === 'Qayta aloqa' ? t(`one_lid.${item?.name}`) : item?.name,
    count: 0,
    employeeId: 0,
    fullName: ''
  }));

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <div>
          <Header3 />
        </div>
        <div>
          <Employee />
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.main_subTask}>
          <h1>{t('crm.Topshiriqlar_Xodimlar')}</h1>
          <div>
            <Segmented
              defaultValue={taskStatus}
              options={[
                {
                  label: t('home.Barchasi'),
                  value: TaskStatus.ALL
                },
                {
                  label: t('crm.Bajarilgan'),
                  value: TaskStatus.CLOSED
                },
                {
                  label: t(`task.Otib_ketgan`),
                  value: TaskStatus.MISSED
                },
                {
                  label: t('crm.Kutilayotgan'),
                  value: TaskStatus.ACTIVE
                }
              ]}
              value={taskStatus}
              onChange={e => handleChangeStatus(e)}
            />
          </div>
        </div>
        <div className={styles.main_element}>
          <Spin spinning={isLoading}>
            <div className={styles.main_chart} style={{ height: 400 }}>
              <ResponsivePie
                data={newData}
                margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                colors={{ datum: 'data.color' }}
              />
              <div className={styles.main_chart_all}>
                <span>{task?.data?.overallTaskCount}</span>
                <span>{t('home.Jami')}</span>
              </div>
            </div>
          </Spin>
          <div className={styles.main_legends}>
            {newData
              ?.sort((a, b) => b?.value! - a?.value!)
              ?.filter(filter => filter?.employee === employeeId || employeeId === null || !employeeId)
              ?.map((item, i) => (
                <div className={styles.main_legends_item} key={i}>
                  <div className={styles.main_legends_item_in}>
                    <div
                      className={styles.main_legends_item_circle}
                      style={{
                        borderColor: item?.color
                      }}
                    />
                    <p>{item?.id}</p>
                  </div>
                  <div className={styles.elements}>
                    {defaultTask?.map((prev, index) => (
                      <Tooltip placement="topLeft" title={prev?.name} key={index}>
                        <div className={styles.elements_items}>
                          <p className="d-flex align-center">{crmIcons[prev?.icon.split('#')[0]].icon(`#${prev?.icon.split('#')[1]}`)}</p>
                          <p>{item?.taskStats?.find(el => el?.icon === prev?.icon)?.count || 0}</p>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                  <span className={styles.countTasks}>{item?.value}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksStatistics;
