export const queryKeys = {
  FAILED_LEAD: 'FAILED_LEAD',
  CALL_HISTORY: 'CALL_HISTORY',
  CALL_HISTORY_CARD: 'CALL_HISTORY_CARD',
  CALL_HISTORY_GRAPH: 'CALL_HISTORY_GRAPH',
  CALL_HISTORY_FILTER: 'CALL_HISTORY_FILTER',
  LEAD_TASK: 'LEAD_TASK',
  PHONE_RECORD: 'PHONE_RECORD',
  STAT_LEAD: 'STAT_LEAD',
  STAT_LEAD_DATE: 'STAT_LEAD_DATE',
  FIRST_PIE_CHART_DATA: 'FIRST_PIE_CHART_DATA',
  SECOND_PIE_CHART_DATA: 'SECOND_PIE_CHART_DATA',
  TJMS: 'TJMS',
  THIRD_PIE_CHART_DATA: 'THIRD_PIE_CHART_DATA',
  SUCCESS_TABLE_DATA: 'SUCCESS_TABLE_DATA',
  FAILED_TABLE_DATA: 'FAILED_TABLE_DATA',
  SUCCESSFUL_LEADS: 'successful_leads',
  SUCCESSFUL_TABLE_LEADS: 'successful_table_leads',
  PLAN_FACT: 'plan-fact',
  LEAD_AND_CLIENT_COST_STATISTICS: 'lead_and_client_cost_statistics',
  LEAD_AND_CLIENT_COST: 'lead_and_client_cost',
  PLAN_FACT_PIPES: 'plan_fact_pipes',
  PLAN_FACT_CONFIG: 'plan_fact_config_or_settings',
  LEAD_AND_CLIENT_COST_CONFIG: 'lead_and_client_cost_config',
  LEAD_SOURCES: 'lead_sources'
};
