import React, { useRef } from 'react';
import TrushIcon from '@icons/crmIcons/TrushIcon';
import PlusIcon from '@icons/PlusIcon';
import DragAndDropIcon from '@pages/crm/assets/icons/DragAndDropIcon';
import { Button, Form, FormInstance, Input } from 'antd';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import styles from './voronkaDnd.module.scss';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : '#fff',
  border: '1px solid #EAECF0',
  borderRadius: 8,
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
  width: '100%',
  maxHeight: 295,
  overflow: 'auto'
});

type Props = {
  defaultLang: string;
  form: FormInstance<any>;
};

const VoronkaDnd: React.FC<Props> = ({ defaultLang, form }) => {
  const { t } = useTranslation();
  const items = Form.useWatch('causes', form);
  const container = useRef<any>(null);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(items!, result.source.index, result.destination.index);

    form.setFieldsValue({
      causes: reorderedItems
    });
  };

  const goToTop = () => {
    setTimeout(() => {
      const elem = document.getElementById('elem');

      elem?.scrollTo({ top: elem?.scrollHeight + elem?.offsetHeight, behavior: 'smooth' });
    }, 50);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} id="elem">
              <Form.List name="causes">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map(({ key, name, ...restField }, index) => (
                      <Draggable key={key} draggableId={String(key)} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                            className={styles.dndItem}
                          >
                            <span {...provided.dragHandleProps}>
                              <DragAndDropIcon />
                            </span>
                            <Form.Item {...restField} name={[name, 'color']} className={styles.colorPicker}>
                              <input type="color" />
                            </Form.Item>
                            <Form.Item {...restField} name={[name, `name${defaultLang}`]} rules={[{ required: true, message: '' }]}>
                              <Input style={{ borderRadius: 6 }} />
                            </Form.Item>
                            <TrushIcon onClick={() => remove(name)} className="cursor-pointer" />
                          </div>
                        )}
                      </Draggable>
                    ))}

                    <Button
                      onClick={() => {
                        add();
                        goToTop();
                      }}
                      type="dashed"
                      block
                      icon={<PlusIcon stroke="#87898E" />}
                      style={{
                        background: 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '18px 0',
                        position: 'absolute',
                        bottom: 15,
                        width: '84%',
                        borderRadius: 6
                      }}
                    >
                      {t('crm.Yangi_qoshish')}
                    </Button>
                  </>
                )}
              </Form.List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default VoronkaDnd;
