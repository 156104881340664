import React, { useState } from 'react';
import DotsIcon from '@pages/activityLogs/assets/DotsIcon';
import InfoIconTaskCreator from '@pages/oneLid/assets/icons/InfoIconTaskCreator';
import { Dropdown, Menu, Modal, Popover } from 'antd';
import { t } from 'i18next';

import { crmIcons } from '../../../../../../assets/icons/crmIcons/crmIcons';
import TickCircleIconCrm from '../../../../../../assets/icons/TickCircleIconCrm';
import { OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';

import Avatar from '../../../../../../assets/img/avatarDefaultClient.jpg';

import styles from './completedTask.module.scss';

interface Props {
  event: OneEvent;
}

const CompletedTask: React.FC<Props> = ({ event }) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

  const handleOpenTaskInfo = () => {
    setIsInfoModalVisible(!isInfoModalVisible);
  };

  return (
    <div className={styles.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={styles.container_icon}>
          <TickCircleIconCrm />
        </div>

        <div className={styles.container_wrapper}>
          <div className={styles.container_notes} style={{ marginRight: '10px' }}>
            <span className={styles.container_notes_date}>
              <Popover overlayInnerStyle={{ borderRadius: '12px' }} content={<span>{event.createdBy}</span>}>
                <img src={Avatar} style={{ width: '17px', height: '17px', cursor: 'pointer' }} alt="error img" />
              </Popover>
              {event.createdDate} {event.assignedBy}
            </span>
            <p className={styles.container_notes_note}>
              {crmIcons[event.leadTaskTypeDto.icon?.split('#')[0]!]?.icon(`#${  event.leadTaskTypeDto.icon?.split('#')[1]}`)}
              <span>
                {event.leadTaskTypeDto.name}
                {event?.text.length > 0 ? ':' : ''}
              </span>
              <span>{event.text}</span>
            </p>
            <p className={styles.container_notes_reason}>{event.closedText}</p>
          </div>
        </div>
      </div>

      <div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleOpenTaskInfo()}>
                <div className={styles.flexItems}>
                  <InfoIconTaskCreator />
                  {t('contract_id.Malumot')}
                </div>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <button className={styles.dotsBtn}>
            <DotsIcon />
          </button>
        </Dropdown>

        <Modal visible={isInfoModalVisible} className={styles.modal} closable={false} onCancel={() => setIsInfoModalVisible(false)} footer={null}>
          <div className={styles.inform} style={{ marginBottom: '5px' }}>
            <p>Yaratilgan sana</p>
            <span>{event.createdDate}</span>
          </div>
          <div className={styles.inform}>
            <p>{t('home.Yaratdi')}</p>
            <span>{event.createdBy}</span>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CompletedTask;
