import React from 'react';

const ShieldTickIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.73984 2.35879L4.58151 3.92546C3.62318 4.28379 2.83984 5.41712 2.83984 6.43379V12.6255C2.83984 13.6088 3.48984 14.9005 4.28151 15.4921L7.86484 18.1671C9.03984 19.0505 10.9732 19.0505 12.1482 18.1671L15.7315 15.4921C16.5232 14.9005 17.1732 13.6088 17.1732 12.6255V6.43379C17.1732 5.40879 16.3898 4.27546 15.4315 3.91712L11.2732 2.35879C10.5648 2.10046 9.43151 2.10046 8.73984 2.35879Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.54297 10.3921L8.88464 11.7337L12.468 8.15039" stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ShieldTickIcon;
