import { ReactNode, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { Action } from '../action';
import { ActionType } from '../schema/action';

import Context, { ActionObj } from './context';

interface IProps {
  children: ReactNode;
}

const Provider = ({ children }: IProps) => {
  const [query, setQuery] = useQueryParams({
    groupId: NumberParam,
    name: StringParam
  });
  const [state, setState] = useState<ActionType | undefined>(undefined);
  const [data, setData] = useState<ActionObj | undefined>(undefined);

  function setAction<T extends ActionType>(key: T, data?: ActionObj[T]) {
    setState(key);
    setData(prev => ({
      ...prev!,
      [key]: data
    }));
  }

  return (
    <Context.Provider
      value={{
        setAction,
        action: state,
        closeAction: () => {
          setState(undefined);
          setData(undefined);
        },
        getActionData: data,
        setQuery,
        query
      }}
    >
      <>
        {children}
        <Action />
      </>
    </Context.Provider>
  );
};

export default Provider;
