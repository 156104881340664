/* eslint-disable react/jsx-key */
/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect } from 'react';
import TrashIconKPI from '@icons/contract/TrashIconKPI';
import { useGetCurrency } from '@pages/order-id-v2/services/queries';
import { Form, Input, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDeleteKpiPart, useEditKpiPart } from '../../../queries/mutation';
import { helper } from '../../../utils/helper/helper';
import { KpiPart } from '../../../utils/models/Kpi';

import styles from '../../../pages/kpi/kpi.module.scss';

interface Props {
  kpiPart?: KpiPart;
  type: string;
}

const { Option } = Select;

const SumItem: React.FC<Props> = ({ kpiPart, type }) => {
  const [form] = Form.useForm<{ amount: string; count: string; currencyId: number }>();
  const { data: dataCurrency } = useGetCurrency();

  const editKpiPart = useEditKpiPart();
  const deleteKpiPart = useDeleteKpiPart(kpiPart?.id || 0);

  const { t } = useTranslation();

  // const defaultCurrencyId = dataCurrency?.find((currency: any) => currency.ccy === 'UZS')?.id;
  const defaultCurrencyId = kpiPart?.currencyDto?.id;

  useEffect(() => {
    if (kpiPart) {
      form.setFieldsValue({
        amount: kpiPart.amount.toLocaleString('ru'),
        count: type === 'FLAT_AREA' ? kpiPart?.area?.toLocaleString('ru') : kpiPart?.count?.toLocaleString('ru'),
        currencyId: kpiPart.currencyId || defaultCurrencyId
      });
    }
  }, [kpiPart, form, defaultCurrencyId]);

  const handleUpdate = async () => {
    const { amount, count, currencyId } = await form.validateFields();
    const updatedCurrencyId = currencyId || defaultCurrencyId;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type === 'FLAT_AREA'
      ? editKpiPart.mutate({
          amount: Number(helper.deleteSpace(amount)),
          area: Number(helper.deleteSpace(count)),
          currencyId: updatedCurrencyId,
          id: kpiPart?.id,
          kpiId: kpiPart?.kpiId || 0
        })
      : editKpiPart.mutate({
          amount: Number(helper.deleteSpace(amount)),
          count: Number(helper.deleteSpace(count)),
          currencyId: updatedCurrencyId,
          id: kpiPart?.id,
          kpiId: kpiPart?.kpiId || 0
        });
  };

  const handleDelete = () => {
    deleteKpiPart.mutate();
  };

  return (
    <Spin spinning={editKpiPart.isLoading || deleteKpiPart.isLoading}>
      <Form form={form} name="SumItem">
        <div className={`d-flex align-center justify-space-between  ${styles.amongus}`}>
          <div className={styles.cont_body_right_simple}>
            <div className={styles.cont_body_right_simple_left}>
              <p>{t('kpi.Ixtiyori_mukofot_miqdori')}</p>
              <span>
                {kpiPart?.kpiId === 6
                  ? t('kpi.Sotilgan_uylar_soniga_bogliq_ravishda_mukofot_hisoblab_boriladi')
                  : t('kpi.Sotilgan_uylar_maydoniga_bogliq_ravishda_mukofot_hisoblab_boriladi')}
              </span>
            </div>
            <div className={styles.cont_body_right_simple_right}>
              <Form.Item
                name="count"
                rules={[
                  { required: true, message: '', min: 1 },
                  {
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject('');
                      }
                      const count = helper.deleteNotNumbers(value, null);

                      form.setFieldsValue({
                        count: count?.toLocaleString('ru')
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input onBlur={handleUpdate} className={`${styles.cont_body_right_simple_right_mini_input} customInput`} />
              </Form.Item>
              <span className={styles.cont_body_right_simple_right_middle_title} style={{ fontWeight: '400', color: '#898f98' }}>
                {kpiPart?.kpiId === 6 ? t('kpi.ta_dan_oshsa') : t('kpi.m2_dan_oshsa')}
              </span>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: (rule, value) => {
                      if (!value) {
                        return Promise.reject('');
                      }
                      form.setFieldsValue({
                        amount: helper.deleteNotNumbers(value, null)?.toLocaleString('ru')
                      });
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input onBlur={handleUpdate} className={`${styles.cont_body_right_simple_right_mini_input} customInput`} />
              </Form.Item>

              <Form.Item name="currencyId" initialValue={defaultCurrencyId} rules={[{ required: true }]} style={{ marginLeft: 10 }}>
                <Select
                  className="customSelect"
                  onBlur={handleUpdate}
                  style={{ width: 100, fontWeight: 'normal' }}
                  placeholder={<span style={{ fontWeight: 'normal', marginLeft: '0px' }}>{kpiPart?.currencyDto?.ccy}</span>}
                >
                  {dataCurrency?.map((item: any) => <Option value={item?.id}>{item?.ccy}</Option>)}
                </Select>
              </Form.Item>

              <span onClick={handleDelete} className={`${styles.cont_body_right_delete} ${styles.trashIcon}`}>
                <TrashIconKPI />
              </span>
            </div>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default SumItem;
