import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useScrollView } from '@hooks/useScrollView';
import ArrowDownCrmIcon from '@icons/ArrowDownCrmIcon';
import CalendarIcon from '@icons/CalendarIcon';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import EmployeeIcon from '@icons/EmployeeIcon';
import { useCrmEmployee } from '@queries/queries';
import DatePicker from '@service/datePicker';
import { cx } from '@utils/cx';
import { encodeString } from '@utils/helper';
import message from '@utils/helper/message';
import { Avatar, Col, Drawer, Dropdown, Empty, Input, Row, Spin, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';

import AnswerIcon from '../../assets/icons/onlinePbx/AnswerIcon';
import ArrowDownLeft from '../../assets/icons/onlinePbx/ArrowDownLeft';
import ArrowElbowLeft from '../../assets/icons/onlinePbx/ArrowElbowLeft';
import ArrowUpRight from '../../assets/icons/onlinePbx/ArrowUpRight';
import PickUpIcon from '../../assets/icons/onlinePbx/PickUpIcon';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCallHistoryGetRecord, useCallOnlinePbx, useFindClientOnlinePbxDrawer, useSetEmployeeOnlinePbx } from '../../queries/mutation';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { BASE_URL } from '../../service/RequestService';
import { setPhoneEdit, setPhones, setRunningCall } from '../../store/reducers/WebRTCSlice';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../utils/constants/format';
import { phoneCleanCode } from '../../utils/helper/phoneCleanCode';
import { OnlinePbhHistoryCallSubStatus, OnlinePbxHistoryCall, OnlinePbxHistoryCallResponse, OnlinePbxHistoryCallStatus } from '../../utils/models/OnlinePbx';
import { SortList } from '../headers/components/OnlinePbxNotifications';

import avatarDefaultClient from '../../assets/img/avatarDefaultClient.jpg';

import styles from './onlinePbxDrawer.module.scss';

interface Props {
  visible: boolean;
  close: (visible: boolean) => void;
  list: SortList[];
  user?: OnlinePbxHistoryCallResponse;
  type: number;
  setType: React.Dispatch<React.SetStateAction<number>>;
  fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<OnlinePbxHistoryCallResponse, unknown>>;
  employeeIds: number[];
  setEmployeeIds: React.Dispatch<React.SetStateAction<number[]>>;
  isLoading: boolean;
  setTypeName: React.Dispatch<React.SetStateAction<string>>;
  isFetching?: boolean;
  createDedline: {
    start: string | null;
    end: string | null;
  };
  setCreateDedline: React.Dispatch<
    React.SetStateAction<{
      start: string | null;
      end: string | null;
    }>
  >;
}

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(localeData);
dayjs.locale('uz');

const OnlinePbxDrawer: React.FC<Props> = ({
  visible,
  close,
  list,
  user,
  type,
  setType,
  fetchNextPage,
  employeeIds,
  setEmployeeIds,
  isLoading,
  setTypeName,
  isFetching,
  createDedline,
  setCreateDedline
}) => {
  const setEmployee = useSetEmployeeOnlinePbx(-1);
  const dispatch = useAppDispatch();
  const { vertoRef, disable } = useAppSelector(state => state?.webRTCReducer);
  const { push } = useQueryParam();
  const findLead = useFindClientOnlinePbxDrawer();
  const callHistoryGetRecord = useCallHistoryGetRecord();
  const { data, refetch } = useCrmEmployee(visible);
  const [phone, setPhone] = useState('');
  const [scrolling, setScrolling] = useState(false);
  const { ref } = useScrollView({ handleScroll: fetchNextPage, deps: [isLoading], isChange: !isLoading });

  const [mPickerVisble, setMPickerVisble] = useState(false);
  const [activeInput, setActiveInput] = useState<'start' | 'end' | null>(null);

  const { RangePicker } = DatePicker;

  const handleRangePickerChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      const [start, end] = dates;

      setCreateDedline({
        start: start.format(DATE_WITH_TIME_FORMAT),
        end: end.format(DATE_WITH_TIME_FORMAT)
      });

      // sendDatesToBackend(start, end);
    } else {
      setCreateDedline({
        start: null,
        end: null
      });
    }
  };

  const handleSelectDate = (date: dayjs.Dayjs) => {
    if (activeInput === 'start') {
      setCreateDedline(prev => ({
        ...prev,
        start: date.format(DATE_WITH_TIME_FORMAT)
      }));
    } else {
      setCreateDedline(prev => ({
        ...prev,
        end: date.format(DATE_WITH_TIME_FORMAT)
      }));
    }
  };

  useEffect(() => {
    if (createDedline && Object.keys(createDedline).length > 0) {
      if ((createDedline.start && createDedline.end) || (!createDedline.start && !createDedline.end)) {
        setActiveInput('start');
      }
      if (!createDedline.start && createDedline.end) {
        setActiveInput('start');
      }
      if (createDedline.start && !createDedline.end) {
        setActiveInput('end');
      }
    }
  }, [createDedline]);

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const call = useCallOnlinePbx(handleSuccessCall);

  const { t } = useTranslation();

  // handle success call
  function handleSuccessCall() {
    setPhone('');
  }

  // handle call
  const handleCall = async (to: string) => {
    if (disable) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const calling = vertoRef?.call(stream.getTracks(), to, {
        callee_id_name: user?.onlinePbxEmployeeDto?.employeeName || '',
        callee_id_number: to,
        caller_id_name: user?.onlinePbxEmployeeDto?.employeeName || '',
        caller_id_number: to
      });

      dispatch(
        setPhones({
          [calling?.options?.caller_id_number!]: {
            answerCalling: false,
            calling: true,
            callerName: calling?.options?.caller_id_name!,
            callerNumber: calling?.options?.caller_id_number!,
            call: calling,
            hold: false,
            employeeName: undefined,
            employeePhoneNumber: undefined
          }
        })
      );
      // dispatch(setCalling(true))
      // dispatch(setRunningCall(calling))
      calling?.subscribeEvent('track', track => {
        if (track.kind !== 'audio') return;
        const stream = new MediaStream();

        stream.addTrack(track);
        const el: any = document.getElementById('webRtcVideo');

        el.srcObject = stream;
      });

      calling?.subscribeEvent('answer', () => {
        dispatch(
          setPhoneEdit({
            key: calling?.options?.caller_id_number!,
            params: {
              answerCalling: false
            }
          })
        );
      });

      calling?.subscribeEvent('bye', (cause: any) => {
        dispatch(
          setPhoneEdit({
            key: calling?.options?.caller_id_number!,
            params: {
              calling: false
            }
          })
        );
      });
    } else {
      dispatch(setRunningCall(call));
      if (to && user?.onlinePbxEmployeeDto?.num && !call.isLoading) {
        call.mutate({
          domain: user?.onlinePbxEmployeeDto?.onlinePbxDto?.domain as string,
          from: user?.onlinePbxEmployeeDto?.num,
          to,
          key: user?.onlinePbxEmployeeDto?.onlinePbxDto?.key as string,
          keyId: user?.onlinePbxEmployeeDto?.onlinePbxDto?.keyId as string
        });
      } else {
        message.warn(t('online_pbx.Qongiroq_qilish_imkonsiz'));
      }
    }
  };

  // set phone
  const handlePhone: ChangeEventHandler<HTMLInputElement> = e => {
    setPhone(e.target.value);
  };

  // handle close
  const handleClose = () => {
    close(false);
  };

  // calculate duration
  const calculateDuration = (duration: number) => {
    const minute = Math.floor(duration / 60) >= 10 ? Math.floor(duration / 60) : `0${Math.floor(duration / 60)}`;
    const second = duration % 60 >= 10 ? duration % 60 : `0${duration % 60}`;

    return `${minute}:${second}`;
  };

  // get phone number
  const getPhoneIcon = (call: OnlinePbxHistoryCall) => {
    if (call.accountcode === OnlinePbxHistoryCallStatus.inbound && call.hangup_cause === OnlinePbhHistoryCallSubStatus.NORMAL_CLEARING && call?.user_talk_time !== 0) {
      return <ArrowDownLeft />;
    }
    if (call.accountcode === OnlinePbxHistoryCallStatus.outbound) {
      return <ArrowUpRight />;
    }
    if (call.accountcode === OnlinePbxHistoryCallStatus.inbound && call?.user_talk_time === 0) {
      return <ArrowElbowLeft />;
    }
    return <ArrowDownLeft />;
  };

  // get phone number
  const getDestinationNumber = (call: OnlinePbxHistoryCall) => {
    if (call.accountcode === OnlinePbxHistoryCallStatus.outbound) {
      return call.destination_number;
    }
    return call.caller_id_number;
  };

  const getDestinationNumber2 = (call: OnlinePbxHistoryCall) => {
    if (call.accountcode === OnlinePbxHistoryCallStatus.inbound) {
      return call.destination_number;
    }
    return call.caller_id_number;
  };

  // set type
  const changeType = (val: number) => {
    setType(val);
    localStorage.setItem('type', String(val));
  };

  // handle search lead
  const handleSearchLead = (phone: number, call: OnlinePbxHistoryCall) => {
    refetch();

    const encodedName = encodeString(call?.leadName);

    if (call?.leadId) {
      push(`${rootPaths.MAIN + MainPaths.CRM}/${call?.leadId}/${encodedName}`);
    } else {
      findLead.mutate(phone);
      handleClose();
    }
  };

  const changeWebCall = (value: boolean) => {
    setEmployee.mutate({
      employeeId: user?.onlinePbxEmployeeDto?.employeeId || 0,
      onlinePbxEmployeeId: user?.onlinePbxEmployeeDto?.id || 0,
      onlinePbxConfigId: user?.onlinePbxEmployeeDto?.onlinePbxDto?.id || 0,
      allowWebCall: value
    });
  };

  const scrollColumn = (e: any) => {
    if (e.target.offsetHeight + e.target.scrollTop + 200 >= e.target.scrollHeight && e.target.scrollHeight > e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true);
        });
    }
  };

  const clientName = (contactName: string, leadName: string) => {
    if (contactName) {
      return contactName?.length > 20 ? `${contactName?.slice(0, 20)}...` : contactName;
    }
    return leadName?.length > 20 ? `${leadName?.slice(0, 20)}...` : leadName;
  };

  const handleGetRecord = (call: OnlinePbxHistoryCall) => {
    callHistoryGetRecord.mutate({
      accountCode: call.accountcode,
      callHistoryType: call.callHistoryType,
      callId: call.uuid,
      clientPhoneNumber: String(getDestinationNumber(call)),
      domain: user?.onlinePbxEmployeeDto?.onlinePbxDto?.domain,
      key: user?.onlinePbxEmployeeDto?.onlinePbxDto?.key,
      keyId: user?.onlinePbxEmployeeDto?.onlinePbxDto?.keyId,
      secret: user?.sipuniEmployee?.sipuniConfDto?.secret,
      userNumber: user?.sipuniEmployee?.sipuniConfDto?.userNumber
    });
  };

  const handleCheckEmployee = (id: number) => {
    if (employeeIds?.includes(id)) {
      const newEmployees = employeeIds?.filter((item: any) => item !== id);

      setEmployeeIds(newEmployees);
      localStorage.setItem('employees', JSON.stringify(newEmployees));
    } else {
      const newData = [id, ...JSON.parse(localStorage.getItem('employees') || '[]')];

      setEmployeeIds(prev => [...prev, id]);
      // Set the new data to localStorage
      localStorage.setItem('employees', JSON.stringify(newData));
    }
  };

  return (
    <Drawer width={950} placement="right" onClose={handleClose} visible={visible} headerStyle={{ display: 'none' }} bodyStyle={{ padding: 0 }}>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <h2>{t('online_pbx.Qongiroqlar_tarixi')}</h2>
          <CloseOutlined onClick={handleClose} className={styles.container_header_close} />
        </div>
        <div className={styles.container_navbar}>
          <div className={styles.container_navbar_top}>
            <span
              onClick={() => {
                changeType(1);
                setTypeName('');
                localStorage.setItem('typeName', '');
              }}
              className={type === 1 ? styles.container_navbar_active : ''}
            >
              {t('online_pbx.Barchasi')}
            </span>
            <span
              className={type === 2 ? styles.container_navbar_active : ''}
              onClick={() => {
                changeType(2);
                setTypeName('INCOME');
                localStorage.setItem('typeName', 'INCOME');
              }}
            >
              {t('online_pbx.Kiruvchi')}
            </span>
            <span
              className={type === 3 ? styles.container_navbar_active : ''}
              onClick={() => {
                changeType(3);
                setTypeName('OUTCOME');
                localStorage.setItem('typeName', 'OUTCOME');
              }}
            >
              {t('online_pbx.Chiquvchi')}
            </span>
            <span
              className={type === 4 ? styles.container_navbar_active : ''}
              onClick={() => {
                changeType(4);
                setTypeName('NOT_ANSWERED');
                localStorage.setItem('typeName', 'NOT_ANSWERED');
              }}
            >
              {t('online_pbx.Qabul_qilinmagan')}
            </span>
          </div>
          <div className={`${styles.container_navbar_employees} flex items-center gap-[16px]`}>
            <div>
              <Dropdown
                visible={mPickerVisble}
                onVisibleChange={setMPickerVisble}
                overlay={
                  <div className="mt-[7px] rounded-md bg-white p-[13px] shadow-md">
                    <h3>{t('home.sanalarni_belgilang')}:</h3>
                    {/* @ts-ignore */}
                    <RangePicker
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:59', 'HH:mm')]
                      }}
                      style={{ width: 350, borderRadius: 7 }}
                      value={[
                        createDedline.start ? dayjs(createDedline.start, DATE_WITH_TIME_FORMAT) : null,
                        createDedline.end ? dayjs(createDedline.end, DATE_WITH_TIME_FORMAT) : null
                      ]}
                      onChange={handleRangePickerChange}
                      placeholder={[t('home.Boshlanish_vaqti'), t('home.Tugash_vaqti')]}
                      format={DATE_WITH_TIME_FORMAT}
                      renderExtraFooter={() => (
                        <div className={styles.range_picker}>
                          <div className="mx-[10px] mt-[5px]">
                            <div className={styles.custom_date}>
                              <div
                                className={createDedline.start === dayjs().subtract(15, 'minute').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().subtract(15, 'minute'), dayjs().add(1, 'minute')])}
                              >
                                {t('online_pbx.15_daqiqa_oldin')}
                              </div>
                              <div
                                className={createDedline.start === dayjs().subtract(30, 'minute').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().subtract(30, 'minute'), dayjs().add(1, 'minute')])}
                              >
                                {t('online_pbx.30_daqiqa_oldin')}
                              </div>
                              <div
                                className={createDedline.start === dayjs().subtract(1, 'hour').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().subtract(1, 'hour'), dayjs().add(1, 'minute')])}
                              >
                                {t('online_pbx.1_soat_oldin')}
                              </div>
                              <div
                                className={createDedline.start === dayjs().startOf('day').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().startOf('day'), dayjs().endOf('day')])}
                              >
                                {t('one_lid.Bugun')}
                              </div>
                              <div
                                className={createDedline.start === dayjs().subtract(1, 'day').startOf('day').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')])}
                              >
                                {t('one_lid.Kecha')}
                              </div>
                              <div
                                className={createDedline.start === dayjs().startOf('week').add(1, 'day').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().startOf('week').add(1, 'day'), dayjs().endOf('day')])}
                              >
                                {t('online_pbx.Joriy_hafta')}
                              </div>
                              <div
                                className={
                                  createDedline.start === dayjs().subtract(1, 'week').startOf('week').add(1, 'day').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''
                                }
                                onClick={() =>
                                  handleRangePickerChange([
                                    dayjs().subtract(1, 'week').startOf('week').add(1, 'day'),
                                    dayjs().subtract(1, 'week').endOf('week').add(1, 'day').hour(23).minute(59).second(59)
                                  ])
                                }
                              >
                                {t('online_pbx.Otgan_hafta')}
                              </div>
                              <div
                                className={createDedline.start === dayjs().startOf('month').format(DATE_WITH_TIME_FORMAT) ? styles.active_date : ''}
                                onClick={() => handleRangePickerChange([dayjs().startOf('month'), dayjs().endOf('day').hour(23).minute(59).second(59)])}
                              >
                                {t('online_pbx.Joriy_oy')}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                }
                trigger={['click']}
              >
                <div className="flex cursor-pointer items-center gap-[6px]">
                  <span className="mb-[-5px]  mr-[-2px]">
                    <CalendarIcon />
                  </span>
                  <p className="text-[14px]">{t('home.Sana')}</p>
                </div>
              </Dropdown>
            </div>

            <Dropdown
              overlay={
                <div className={styles.container_navbar_employees_dropdown}>
                  {data?.map(item => (
                    <div key={item.id} className={styles.container_navbar_employees_dropdown_item} onClick={() => handleCheckEmployee(item?.id)}>
                      <span key={item?.id}>{item?.fullName}</span>
                      <CheckmarkIcon fill={employeeIds?.includes(item?.id) ? '#1cb092' : 'transparent'} />
                    </div>
                  ))}
                </div>
              }
              trigger={['click', 'hover']}
              mouseEnterDelay={10000}
              mouseLeaveDelay={0.3}
            >
              <div className={styles.container_navbar_employees_header}>
                {employeeIds?.length > 0 && <div className={styles.container_navbar_employees_header_circle}>{employeeIds?.length}</div>}
                <EmployeeIcon />
                <span>{t('home.Xodimlar')}</span>
                <ArrowDownCrmIcon />
              </div>
            </Dropdown>
          </div>
        </div>
        <div className={styles.container_list_header}>
          <div className={cx('ml-[5px] mr-[120px]', styles.container_list_header_item)}>{t('online_pbx.Qongiroqlar')}</div>
          <div className={cx('ml-[-20px] mr-[143px]', styles.container_list_header_item)}>{t('crm.Nomi')}</div>
          <div className={cx('mr-[50px]', styles.container_list_header_item)}>{t('online_pbx.Telefon_raqami')}</div>
          <div className={cx('mr-[58px]', styles.container_list_header_item)}>{t('online_pbx.Davomiyligi').substring(0, 20)}</div>
          <div className={cx('mr-[87px]', styles.container_list_header_item)}>{t('home.Xodim')}</div>
          <div className={cx('', styles.container_list_header_item)}>{t('online_pbx.Status')}</div>
        </div>
        <Spin spinning={isLoading}>
          <div className={styles.container_table}>
            {list?.length > 0 ? (
              <>
                {list.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>
                    <div className={styles.container_date}>
                      {t(`online_pbx.${dayjs(item.date, DATE_FORMAT).format('dddd')}`)}, {dayjs(item.date, DATE_FORMAT).format('DD-')}
                      {t(`boss_payments.${dayjs(item.date, DATE_FORMAT).format('MMMM')}`)} {dayjs(item.date, DATE_FORMAT).format('YYYY')}
                    </div>
                    {item.list.map((call, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Row
                        // * Переход к лиду здесь*
                        onClick={() => handleSearchLead(getDestinationNumber(call), call)}
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className={styles.container_list_item}
                      >
                        <Col className={styles.phone} span={4}>
                          <span
                            onClick={e => {
                              e.stopPropagation();
                              handleGetRecord(call);
                            }}
                          >
                            {getPhoneIcon(call)}
                          </span>
                          <span>{moment.utc(moment.unix(call.start_stamp)).tz('Asia/Tashkent').format('HH:mm')}</span>
                        </Col>
                        <Col span={5}>
                          <div>
                            {/* <Tooltip overlay={call?.employeeName}>
                        <Avatar
                          src={
                            call?.employeeAvatar
                              ? BASE_URL + '/' + call?.employeeAvatar
                              : avatarDefaultClient
                          }
                          size={24}
                        />
                      </Tooltip>{' '} */}
                            {clientName(call?.contactName, call?.leadName)}
                          </div>
                        </Col>
                        {/* <Col className={styles.name} span={5}>
                    {call.gateway}
                  </Col> */}
                        {call?.callHistoryType === 'SIPUNI' || !user?.onlinePbxEmployeeDto?.num ? (
                          <Col span={4}>
                            <a href={`tel:${phoneCleanCode(String(getDestinationNumber(call)))}`} className={styles.phone_number}>
                              {' '}
                              <AnswerIcon />
                              <span>{getDestinationNumber(call)}</span>
                            </a>
                          </Col>
                        ) : (
                          <Col
                            onClick={e => {
                              e.stopPropagation();
                              handleCall(String(getDestinationNumber(call)));
                            }}
                            className={styles.phone_number}
                            span={4}
                          >
                            <AnswerIcon />
                            <span>{getDestinationNumber(call)}</span>
                          </Col>
                        )}
                        <Col className={styles.duration} span={3}>
                          {calculateDuration(call.user_talk_time)}
                        </Col>
                        <div className="ml-[30px] mr-[10px]">
                          <div>
                            <Tooltip overlay={call?.employeeName}>
                              <Avatar src={call?.employeeAvatar ? `${BASE_URL}/${call?.employeeAvatar}` : avatarDefaultClient} size={24} />
                            </Tooltip>{' '}
                            {getDestinationNumber2(call)}
                          </div>
                        </div>
                        <Col className={styles.status_failed} span={5}>
                          <div>{t(`online_pbx.${call.hangup_cause}`)}</div>
                        </Col>
                        {/* <Col
                    className={styles.getRecord}
                    span={1}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleGetRecord(call)
                    }}
                  >
                    <DownloadIcon />
                  </Col> */}
                      </Row>
                    ))}
                  </div>
                ))}
                {isFetching && <Spin spinning className="flex 	max-w-full justify-center" />}
                <div ref={ref} className="p-2" />
              </>
            ) : (
              <div className={styles.emptyData}>
                <Empty />
              </div>
            )}
          </div>
        </Spin>
        {user?.onlinePbxEmployeeDto?.num && (
          <div className={styles.container_footer}>
            <Spin spinning={call.isLoading}>
              <div className="d-flex align-center">
                <Input
                  disabled={!user?.onlinePbxEmployeeDto?.num}
                  onChange={handlePhone}
                  value={phone}
                  placeholder={t('online_pbx.Telefon_raqam_yozing')}
                  className={styles.container_footer_input}
                  suffix={<PickUpIcon onClick={() => handleCall(phone)} className={styles.container_footer_input_icon} />}
                />
              </div>
            </Spin>
            <div className={styles.container_footer_count}>
              {user?.onlinePbxEmployeeDto?.num}{' '}
              {user?.onlinePbxEmployeeDto?.num && <Switch checked={!!user?.onlinePbxEmployeeDto?.allowWebCall} loading={setEmployee.isLoading} onChange={e => changeWebCall(e)} />}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default OnlinePbxDrawer;
