import React from 'react';
import { useSaveCurrency } from '@queries/mutation';
import { Button, Dropdown, Spin, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { useBasicCurrency } from '../../../../queries/queries';
import { CurrencyItem } from '../../../../utils/models/Currency';

import ChangeCurrencyItem from './ChangeCurrencyItem';

import styles from './changeCurrency.module.scss';

interface Props {
  currencyList: CurrencyItem[] | undefined;
  visible: boolean;
  close: (visible: boolean) => void;
}

const ChangeCurrency: React.FC<Props> = ({ currencyList, children, close, visible }) => {
  const { t } = useTranslation();
  const { data: basicCurrency } = useBasicCurrency();
  const save = useSaveCurrency();
  const currentCurrency = currencyList?.find(item => !item?.basic);

  const handleChangeAutoUpdate = (e: boolean) => {
    save.mutate({ ...currentCurrency!, autoUpdate: e });
  };

  // handle cancel
  const handleCancel = () => {
    close(false);
  };

  return (
    <Dropdown
      trigger={['click']}
      placement="bottom"
      visible={visible}
      onVisibleChange={e => close(e)}
      overlayClassName={styles.container}
      overlay={
        <>
          <p className={styles.container_title}>{t('house_config.Valyuta')}</p>
          {currencyList?.map((item, index) => {
            if (item.basic) return null;
            // eslint-disable-next-line react/no-array-index-key
            return <ChangeCurrencyItem key={index} currency={item} basic={basicCurrency} />;
          })}
          <div className="!my-6 flex items-center justify-between">
            <p className={styles.container_title}>
              {t('house_config.Avto_yangilash')} ({currentCurrency?.updateDate})
            </p>
            <Spin spinning={save?.isLoading}>
              <Switch checked={currentCurrency?.autoUpdate} onChange={handleChangeAutoUpdate} />
            </Spin>
          </div>
          <div className={`d-flex align-center justify-end ${styles.container_margin_top}`}>
            <Button type="primary" className={styles.container_button} onClick={handleCancel}>
              {t('arrearage.Yopish')}
            </Button>
          </div>
        </>
      }
    >
      {children}
    </Dropdown>
  );
};

export default ChangeCurrency;
