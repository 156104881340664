import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorage } from '@service/LocalStorage';
import { FlatId } from '@utils/models/Flat';
import { PriceCalculateType } from '@utils/models/PriceCalculateType';

interface ShaxmatkaState {
  data: any[];
  dataDelete: number[];
  isLoading: boolean;
  priceCalculateType: keyof typeof PriceCalculateType;
  tableMode: boolean;
}

const initialState: ShaxmatkaState = {
  data: [],
  dataDelete: [],
  isLoading: false,
  priceCalculateType: 'PER_AREA',
  tableMode: !!LocalStorage.get('shaxmatkaTableMode')
};

export const shaxmatkaSlice = createSlice({
  name: 'shaxmatka',
  initialState,
  reducers: {
    setShaxmatka: (state, action: any) => {
      state.data = action.payload;
    },
    setShaxmatkaDelete: (state, action: any) => {
      state.dataDelete = action.payload;
    },
    setShaxmatkaLoading: (state, action: any) => {
      state.isLoading = action.payload;
    },
    setPriceCalculateType: (state, action: PayloadAction<PriceCalculateType | undefined>) => {
      state.priceCalculateType = action.payload || 'PER_AREA';
      const { data } = current(state);
      const arr: FlatId[] = [];

      document.querySelectorAll('.selectableItems') &&
        document.querySelectorAll('.selectableItems').forEach(value => {
          const flat = JSON.parse(value.getAttribute('data')!);

          arr.push(flat);
        });
      const shaxmatkaCopy = data.map((podezd: any) =>
        podezd.map((floor: any) =>
          floor.map((flat: FlatId) => {
            function Selected(a: FlatId) {
              return a && a.floor === flat.floor && a.xaxis[0] === flat.xaxis[0] && a.entrance === flat.entrance;
            }

            return arr && arr.some((flat: FlatId) => Selected(flat))
              ? {
                  ...arr.find((flat: FlatId) => Selected(flat)),
                  pricePerArea: !action.payload ? flat?.pricePerArea : flat?.priceNotRepaired! / flat?.area,
                  priceNotRepaired: !action.payload ? flat?.pricePerArea! * flat?.area : flat?.priceNotRepaired,
                  pricePerAreaRepaired: !action.payload ? flat?.pricePerAreaRepaired : flat?.priceRepaired! / flat?.area,
                  priceRepaired: !action.payload ? flat?.pricePerAreaRepaired! * flat?.area : flat?.priceRepaired
                }
              : {
                  ...flat
                };
          })
        )
      );

      state.data = shaxmatkaCopy;
    },
    setTableMode: (state, action: PayloadAction<boolean>) => {
      state.tableMode = action.payload;
      LocalStorage.set('shaxmatkaTableMode', action.payload ? 'true' : '');
    }
  }
});

export const { setShaxmatka, setShaxmatkaDelete, setShaxmatkaLoading, setPriceCalculateType, setTableMode } = shaxmatkaSlice.actions;
export default shaxmatkaSlice.reducer;
