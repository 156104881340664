import React, { useEffect } from 'react';
import { useGetAllPipeTemplates } from '@pages/voronka-shablon/services/queries';
import { queryKeys } from '@pages/voronka-shablon/utils/constants/queryKeys';
import { Modal } from 'antd';
import { useQueryClient } from 'react-query';

import ShablonItem from './ShablonItem';

import styles from './shablonModal.module.scss';

type Props = {
  setOpenShablon: React.Dispatch<React.SetStateAction<boolean>>;
  openShablon: boolean;
};

const ShablonModal: React.FC<Props> = ({ openShablon, setOpenShablon }) => {
  const client = useQueryClient();
  const { data } = useGetAllPipeTemplates();
  const callCenterData = data?.filter(item => item?.type === 'CALL_CENTER');
  const salesData = data?.filter(item => item?.type === 'SALES_DEPARTMENT');

  const handleClose = () => {
    setOpenShablon(false);
  };

  useEffect(() => {
    if (openShablon) {
      client.fetchQuery({ queryKey: [queryKeys.PIPE_TEMPLATE_ALL] });
    }
  }, [openShablon]);

  return (
    <>
      <Modal visible={openShablon} onCancel={handleClose} title="Shablon" width={1224} centered className={styles.shablonModal} footer={false}>
        <ShablonItem data={callCenterData} setOpenShablon={setOpenShablon} title="Call center" />
        <ShablonItem data={salesData} setOpenShablon={setOpenShablon} title="Sotuv office" />
      </Modal>
    </>
  );
};

export default ShablonModal;
