import { Route, Routes } from 'react-router-dom';

import DiscountSettingNavbar from '../../components/discountSetting/DiscountSettingNavbar';
import { discountSettingRoutes } from '../../routing/discountSetting/discountSettingRoutes';

import styles from './discountSetting.module.scss';

const DiscountSetting = () => (
  <div className={styles.discountSetting}>
    <DiscountSettingNavbar />
    <Routes>
      {discountSettingRoutes.map(route => (
        <Route key={route.path} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
      ))}
    </Routes>
  </div>
);

export default DiscountSetting;
