import { baseErrorHandler } from "@queries/baseErrorHandler";
import { $api } from "@service/RequestService";
import { useQuery } from "react-query";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { MonthleArrearsTableType, PaginationMonthleArrearsTableType, SearchParamsMonthlyArrears } from "../utils/models/MonthlyArrearsType";


export function useGetAllMonthlyArrears(searchParams?: SearchParamsMonthlyArrears, startMonth?: number, endMonth?: number, houses?: number[], buildings?: number[], text?: string) {
    const houseIdList = houses?.length ? houses : [0];
    const buildingIdList = buildings?.length ? buildings : [0];

    return useQuery<PaginationMonthleArrearsTableType<MonthleArrearsTableType>>({
        queryKey: [queryKeys.MONTHLY_ARREARS, { ...searchParams, startMonth, endMonth, houses, buildings, text }],
        queryFn: async () => {
            const requestData = {
                ...searchParams,
                page: searchParams?.page || 1,
                size: searchParams?.size || 10,
                houseIdList,
                buildingIdList,
                text
            };

            if (startMonth !== undefined && startMonth !== 0) {
                // @ts-expect-error
                requestData.startMonth = parseInt(startMonth.toString(), 10);
            } else {
                requestData.startMonth = undefined;
            }

            if (endMonth !== undefined && endMonth !== 0) {
                // @ts-expect-error
                requestData.endMonth = parseInt(endMonth.toString(), 10);
            } else {
                requestData.endMonth = undefined;
            }

            const res = await $api.post(endPoints.MONTHLY_ARREARS, requestData);

            return res.data.data;
        },
        onError: baseErrorHandler
    })
}



