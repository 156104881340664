import { useState } from 'react';
import { useGetAllTjm } from '@queries/queries';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';

interface TjmFilterProps {
  onHousesChange: (houses: number[]) => void;
  onBuildingsChange: (buildings: number[]) => void;
}

const TjmFilterV3: React.FC<TjmFilterProps> = ({ onHousesChange, onBuildingsChange }) => {
  const { t } = useTranslation();
  const { data } = useGetAllTjm();
  const [value, setValue] = useState<string[]>([]);

  const tree = data?.map(item => ({
    value: `h-${item?.id}`,
    title: item?.name,
    key: `h-${item?.id}`,
    children: item?.buildings?.map(prev => ({
      value: `b-${prev?.id}`,
      title: prev?.name,
      key: `b-${prev?.id}`
    }))
  }));

  // const houses1 = value?.filter(item => item?.split('-')[0] === 'h')?.map(item => Number(item?.split('-')[1]));
  // const buildings = value?.filter(item => item?.split('-')[0] === 'b')?.map(item => Number(item?.split('-')[1]));

  const onChange = (newValue: string[]) => {
    const selectedHouses = new Set<number>();
    const selectedBuildings = new Set<number>();
    const parentHouses = new Set<number>();

    newValue.forEach(item => {
      const [type, idStr] = item.split('-');
      const id = Number(idStr);

      if (type === 'h') {
        selectedHouses.add(id);
        const house = data?.find(h => h.id === id);

        house?.buildings?.forEach(b => selectedBuildings.delete(b.id));
      } else if (type === 'b') {
        const parentHouse = data?.find(h => h.buildings?.some(b => b.id === id));

        if (parentHouse) {
          const allBuildingsSelected = parentHouse.buildings?.every(b => newValue.includes(`b-${b.id}`) || selectedBuildings.has(b.id));

          if (allBuildingsSelected) {
            selectedHouses.add(parentHouse.id);
            parentHouse.buildings?.forEach(b => selectedBuildings.delete(b.id));
          } else {
            selectedHouses.delete(parentHouse.id);
            selectedBuildings.add(id);
            parentHouses.add(parentHouse.id);
          }
        }
      }
    });

    const housesToSend = new Set([...selectedHouses, ...parentHouses]);

    onHousesChange(Array.from(housesToSend));
    onBuildingsChange(Array.from(selectedBuildings));
    setValue(newValue);
  };

  //   useEffect(() => {
  //     if (onHousesChange) onHousesChange(houses1);
  //     if (onBuildingsChange) onBuildingsChange(buildings);
  //   }, [value]);

  return (
    <TreeSelect
      treeData={tree}
      value={value}
      allowClear
      maxTagCount="responsive"
      onChange={onChange}
      treeCheckable
      showCheckedStrategy="SHOW_PARENT"
      multiple
      placeholder={t('home.Tanlang')}
      style={{ width: '100%', height: '100%' }}
      showSearch={true}
      // @ts-expect-error
      filterTreeNode={(inputValue, treeNode) => treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0}
    />
  );
};

export default TjmFilterV3;
