import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Badge, Button } from 'global';
import moment from 'moment-timezone';
import { useQueryClient } from 'react-query';

import CallCallingIcon from '../../../assets/icons/CallCallingIcon';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { useOnlinePbxListCall } from '../../../queries/queries';
import { LocalStorage } from '../../../service/LocalStorage';
import { DATE_FORMAT, DATE_WITH_TIME_FORMAT } from '../../../utils/constants/format';
import { MISSED_CALL_LIST_KEY } from '../../../utils/constants/localStorageKeys';
import { Permissions } from '../../../utils/constants/permissions';
import { OnlinePbxHistoryCall, OnlinePbxHistoryCallStatus } from '../../../utils/models/OnlinePbx';
import OnlinePbxDrawer from '../../onlinePbxDrawer/OnlinePbxDrawer';

import styles from '../header.module.scss';

export interface SortList {
  date: string;
  list: OnlinePbxHistoryCall[];
}

const OnlinePbxNotifications = () => {
  const employeeIDs = JSON.parse(localStorage.getItem('employees')! || '[]');
  const name = localStorage.getItem('typeName');
  const typeFromStorage = localStorage.getItem('type');

  const qc = useQueryClient();
  const [list, setList] = useState<SortList[]>([]);
  const [countMissedCall, setCountMissedCall] = useState(0);
  const [type, setType] = useState(typeFromStorage ? Number(typeFromStorage) : 1);
  const [typeName, setTypeName] = useState(name || '');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [employeeIds, setEmployeeIds] = useState<number[]>(employeeIDs!);
  const [createDedline, setCreateDedline] = useState<{
    start: string | null;
    end: string | null;
  }>({
    start: null,
    end: null
  });

  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isAccessCrm = permissions?.includes(Permissions.PERMISSION_CALL_HISTORY);

  const {
    data: calls,
    fetchNextPage,
    isLoading,
    isFetching
  } = useOnlinePbxListCall({
    enabled: isAccessCrm && openDrawer,
    employeeIds: employeeIds?.length > 0 ? employeeIds : null,
    callHistoryFilterType: typeName,
    startTime: createDedline.start ? dayjs(createDedline.start, DATE_WITH_TIME_FORMAT).unix() : undefined,
    endTime: createDedline.end ? dayjs(createDedline.end, DATE_WITH_TIME_FORMAT).unix() : undefined
  });
  // get missed calls
  const getMissedCalls = (list: OnlinePbxHistoryCall[]) =>
    list.filter(item => item.accountcode === OnlinePbxHistoryCallStatus.missed || (item.accountcode === OnlinePbxHistoryCallStatus.inbound && item.user_talk_time === 0));

  // get incoming calls
  const getIncomingCalls = (list: OnlinePbxHistoryCall[]) => list.filter(item => item.accountcode === OnlinePbxHistoryCallStatus.inbound && item.user_talk_time !== 0);

  // calculate missed call count
  const calculateMissedCallCount = useCallback(() => {
    let callList: OnlinePbxHistoryCall[] = [];

    if (calls && calls?.pages && calls?.pages?.length > 0) {
      calls?.pages?.forEach(item => {
        if (item?.callHistoryResponses) {
          callList = [...callList, ...item?.callHistoryResponses?.data];
        }
      });
    } else {
      setList([]);
    }
    callList = callList.sort((a, b) => a.start_stamp - b.end_stamp);
    if (callList) {
      const listMissed = getMissedCalls(callList);
      let countNotView = 0;
      const listView = LocalStorage.get<OnlinePbxHistoryCall[]>(MISSED_CALL_LIST_KEY);

      if (listView && typeof listView !== 'string') {
        listMissed.forEach(item => {
          if (!listView.find(value => value.uuid === item.uuid)) {
            // eslint-disable-next-line no-plusplus
            countNotView++;
          }
        });
        setCountMissedCall(countNotView);
      } else {
        setCountMissedCall(listMissed.length);
      }
    }
  }, [calls]);

  const sortOfDate = (incomingList: OnlinePbxHistoryCall[]) => {
    let date = moment.utc(moment.unix(incomingList[0].start_stamp)).tz('Asia/Tashkent').format(DATE_FORMAT);
    let insideList: OnlinePbxHistoryCall[] = [];
    const sortedList: SortList[] = [];

    for (let i = 0; i < incomingList.length; i++) {
      const insideDate = moment.utc(moment.unix(incomingList[i].start_stamp)).tz('Asia/Tashkent').format(DATE_FORMAT);

      if (i === incomingList.length - 1 && date === insideDate) {
        insideList.push(incomingList[i]);
        sortedList.push({
          date,
          list: insideList
        });
      } else if (i === incomingList.length - 1 && date !== insideDate) {
        sortedList.push({
          date,
          list: insideList
        });
        sortedList.push({
          date: insideDate,
          list: [incomingList[i]]
        });
      } else if (date === insideDate) {
        insideList.push(incomingList[i]);
      } else {
        sortedList.push({
          date,
          list: insideList
        });
        insideList = [incomingList[i]];
        date = insideDate;
      }
    }

    return sortedList;
  };

  useEffect(() => {
    let callList: OnlinePbxHistoryCall[] = [];

    if (calls && calls?.pages && calls?.pages?.length > 0) {
      calls?.pages?.forEach(item => {
        if (item?.callHistoryResponses) {
          callList = [...callList, ...item?.callHistoryResponses?.data];
        }
      });
    }
    if (calls?.pages[0]?.callHistoryResponses?.data?.length === 0) {
      setList([]);
    }
    callList = callList.sort((a, b) => a.start_stamp - b.end_stamp);
    if (callList && callList.length > 0) {
      let firstList: OnlinePbxHistoryCall[];

      switch (type) {
        case 2:
          firstList = getIncomingCalls(callList);
          break;
        case 3:
          firstList = callList.filter(item => item.accountcode === OnlinePbxHistoryCallStatus.outbound);
          break;
        case 4:
          firstList = getMissedCalls(callList);
          LocalStorage.set(MISSED_CALL_LIST_KEY, JSON.stringify(firstList));
          calculateMissedCallCount();
          break;
        default:
          firstList = [...callList];
          break;
      }
      if (firstList.length > 0) setList(sortOfDate(firstList.reverse()));
      else setList([]);
    }
  }, [calls, type, calculateMissedCallCount]);

  useEffect(() => {
    calculateMissedCallCount();
  }, [calculateMissedCallCount]);

  // sort of date

  // handleClickNotificationIcon
  const handleClickNotificationIcon = (visible: boolean) => {
    setOpenDrawer(visible);
  };

  // if (!data || !calls) return null

  const user = calls?.pages && calls?.pages?.length > 0 ? calls?.pages[0] : undefined;
  // const users =
  //   calls?.pages && calls?.pages?.length > 0 ? calls?.pages[0] : undefined

  if (!isAccessCrm) return null;

  return (
    <>
      <div className="d-flex align-center">
        <Badge
          color="#FF3F40"
          style={{
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'var(--border-rounded)',
            padding: '0.6rem',
            fontFamily: 'SF UI Text',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            color: 'var(--white)',
            transform: 'translate(14px,-12px)',
            border: '3px solid #fff'
          }}
          size="default"
          count={countMissedCall}
        >
          <Button className={`${styles.icon} d-flex align-center`} onClick={() => handleClickNotificationIcon(true)}>
            <CallCallingIcon />
          </Button>
        </Badge>
      </div>
      <OnlinePbxDrawer
        list={list}
        type={type}
        setType={setType}
        setTypeName={setTypeName}
        visible={openDrawer}
        // user={calls?.onlinePbxEmployeeDto}
        user={user}
        close={handleClickNotificationIcon}
        fetchNextPage={fetchNextPage}
        setEmployeeIds={setEmployeeIds}
        employeeIds={employeeIds}
        isLoading={isLoading}
        isFetching={isFetching}
        createDedline={createDedline}
        setCreateDedline={setCreateDedline}
      />
    </>
  );
};

export default OnlinePbxNotifications;
