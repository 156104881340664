import React from 'react';

const ImagePassport = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.31445 7.875C7.53606 7.875 7.75549 7.83135 7.96023 7.74655C8.16497 7.66174 8.351 7.53744 8.5077 7.38074C8.66439 7.22404 8.7887 7.03802 8.8735 6.83328C8.9583 6.62854 9.00195 6.40911 9.00195 6.1875C9.00195 5.96589 8.9583 5.74646 8.8735 5.54172C8.7887 5.33698 8.66439 5.15096 8.5077 4.99426C8.351 4.83756 8.16497 4.71326 7.96023 4.62845C7.75549 4.54365 7.53606 4.5 7.31445 4.5C6.8669 4.5 6.43768 4.67779 6.12121 4.99426C5.80474 5.31072 5.62695 5.73995 5.62695 6.1875C5.62695 6.63505 5.80474 7.06428 6.12121 7.38074C6.43768 7.69721 6.8669 7.875 7.31445 7.875Z"
        fill="#0F91D2"
      />
      <path
        d="M15.75 15.75C15.75 16.3467 15.5129 16.919 15.091 17.341C14.669 17.7629 14.0967 18 13.5 18H4.5C3.90326 18 3.33097 17.7629 2.90901 17.341C2.48705 16.919 2.25 16.3467 2.25 15.75V2.25C2.25 1.65326 2.48705 1.08097 2.90901 0.65901C3.33097 0.237053 3.90326 0 4.5 0L10.6875 0L15.75 5.0625V15.75ZM4.5 1.125C4.20163 1.125 3.91548 1.24353 3.7045 1.4545C3.49353 1.66548 3.375 1.95163 3.375 2.25V13.5L5.877 10.998C5.96567 10.9095 6.08132 10.8532 6.20563 10.8379C6.32994 10.8226 6.4558 10.8493 6.56325 10.9136L9 12.375L11.4266 8.9775C11.4741 8.9111 11.5355 8.85582 11.6065 8.81552C11.6775 8.77522 11.7564 8.75085 11.8377 8.7441C11.9191 8.73736 12.0009 8.7484 12.0776 8.77647C12.1542 8.80453 12.2239 8.84895 12.2816 8.90662L14.625 11.25V5.0625H12.375C11.9274 5.0625 11.4982 4.88471 11.1818 4.56824C10.8653 4.25178 10.6875 3.82255 10.6875 3.375V1.125H4.5Z"
        fill="#0F91D2"
      />
    </svg>
  );
};

export default ImagePassport;
