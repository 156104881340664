import React from 'react';

const GreenCall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M9.99844 1.66667V5M9.99844 5L13.3318 5M9.99844 5L13.9984 1M6.81646 8.24205C6.01541 7.44099 5.38288 6.53523 4.91888 5.56882C4.87897 5.48569 4.85902 5.44413 4.84368 5.39154C4.7892 5.20463 4.82834 4.97513 4.94168 4.81684C4.97357 4.7723 5.01168 4.7342 5.08788 4.65799C5.32095 4.42492 5.43749 4.30838 5.51368 4.1912C5.80101 3.74927 5.80101 3.17955 5.51368 2.73762C5.43749 2.62044 5.32095 2.5039 5.08788 2.27083L4.95797 2.14092C4.60367 1.78662 4.42653 1.60947 4.23627 1.51324C3.8579 1.32186 3.41106 1.32186 3.03268 1.51324C2.84243 1.60947 2.66528 1.78662 2.31099 2.14092L2.2059 2.24601C1.85281 2.59909 1.67627 2.77563 1.54144 3.01565C1.39183 3.28199 1.28425 3.69565 1.28516 4.00113C1.28598 4.27643 1.33938 4.46458 1.44619 4.84087C2.02017 6.86314 3.10315 8.77138 4.69514 10.3634C6.28712 11.9554 8.19537 13.0383 10.2176 13.6123C10.5939 13.7191 10.7821 13.7725 11.0574 13.7733C11.3629 13.7743 11.7765 13.6667 12.0429 13.5171C12.2829 13.3822 12.4594 13.2057 12.8125 12.8526L12.9176 12.7475C13.2719 12.3932 13.449 12.2161 13.5453 12.0258C13.7366 11.6474 13.7366 11.2006 13.5453 10.8222C13.449 10.632 13.2719 10.4548 12.9176 10.1005L12.7877 9.97062C12.5546 9.73755 12.4381 9.62101 12.3209 9.54482C11.879 9.25749 11.3092 9.2575 10.8673 9.54482C10.7501 9.62102 10.6336 9.73755 10.4005 9.97062C10.3243 10.0468 10.2862 10.0849 10.2417 10.1168C10.0834 10.2302 9.85387 10.2693 9.66697 10.2148C9.61438 10.1995 9.57281 10.1795 9.48968 10.1396C8.52327 9.67562 7.61751 9.0431 6.81646 8.24205Z"
        stroke="#00A389"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GreenCall;
