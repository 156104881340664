/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@components/index';
import { useAppSelector } from '@hooks/reduxHooks';
import AddDashboardIcon from '@icons/AddDashboardIcon';
import PushNotificationIcon from '@icons/PushNotificationIcon';
import RoboSettingIcon from '@icons/RoboSettingIcon';
import DashboardModal from '@pages/crm/components/dashboard-modal/DashboardModal';
import { setOpenDashboard } from '@reducers/CrmSlice';
import { Permissions } from '@utils/constants/permissions';
import { AutoTaskSetting } from '@utils/models/AutoTaskSetting';
import { Menu } from 'antd';
import { fetchToken } from 'firebase';
import { Button, Dropdown } from 'global';
import { useTranslation } from 'react-i18next';

import FilterButton from '../filter/FilterButton';
import TaskBotSettingModal from '../taskBotSettingModal/TaskBotSettingModal';

import ChangeViewTypeButtons from './changeViewTypeButtons/ChangeViewTypeButtons';
import { TasKHeaderNotificationsModal } from './components';

import styles from './TaskHeader.module.scss';

const TaskHeader = () => {
  const permissions = useAppSelector(state => state.userReducer.user?.role.permissions);
  const isAccessSettingsBotAutoTask = permissions?.includes(Permissions.PERMISSION_SETTINGS_AUTO_TASK);
  const isAccessCreateDashboard = permissions?.includes(Permissions.PERMISSION_PAGE_DASHBOARD);
  const isAccessPushNotifications = permissions?.includes(Permissions.PERMISSION_PUSH_NOTIFICATION);

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [botSettingVisible, setBotSettingVisible] = useState(false);
  const [autoTaskBot, setAutoTaskBot] = useState<AutoTaskSetting | any>();

  const { t } = useTranslation();

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleRequestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      localStorage.setItem('isPermittedNotifs', 'true')
      fetchToken();
    } else {
      console.log("Permission not granted for notifications.");
    }
  };

  // const handleRequestNotificationPermission = async () => {
  //   try {
  //     const permission = await Notification.requestPermission();

  //     if (permission === "granted") {
  //       localStorage.setItem('isPermittedNotifs', 'true');
  //       await fetchToken();
  //     } else {
  //       console.log("Permission not granted for notifications.");
  //     }
  //   } catch (error) {
  //     console.error("Error requesting notification permission:", error);
  //   }
  // };


  const handleOpenNotificationSettings = () => {
    handleOpenModal();
    handleRequestNotificationPermission();
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleBotSetting = () => {
    setBotSettingVisible(true);
  };

  return (
    <>
      <div className={styles.crmHeader}>
        <div className="d-flex align-center" style={{ width: '100%', justifyContent: 'space-between' }}>
          <ChangeViewTypeButtons />
          <div style={{ display: 'flex' }}>
            <FilterButton />

            <Dropdown
              overlay={
                <Menu style={{ width: 240, borderRadius: 6 }}>
                  {isAccessCreateDashboard && (
                    <Menu.Item>
                      <div className="flex cursor-pointer items-center gap-[10px]" onClick={() => dispatch(setOpenDashboard(true))}>
                        <span className="mb-[-5px]">
                          <AddDashboardIcon />
                        </span>
                        <p className="text-[14px] font-medium">{t('home.Widget_yaratish')}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {isAccessSettingsBotAutoTask && (
                    <Menu.Item>
                      <div className="flex cursor-pointer items-center gap-[10px]" onClick={handleBotSetting}>
                        <span className="mb-[-5px]">
                          <RoboSettingIcon />
                        </span>
                        <p className="text-[14px] font-medium">{t('auto_task.Bot_sozlamalari')}</p>
                      </div>
                    </Menu.Item>
                  )}
                  {isAccessPushNotifications && (
                    <Menu.Item>
                      <div className="flex cursor-pointer items-center gap-[10px]" onClick={() => handleOpenNotificationSettings()}>
                        <span className="mb-[-5px]">
                          <PushNotificationIcon />
                        </span>
                        <p className="text-[14px] font-medium">{t('home.Bildirishnoma_sozlamasi')}</p>
                      </div>
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomLeft"
              trigger={['click']}
            >
              <Button className="h-[36px] rounded-[8px] border-[#ececec] px-[16px] py-[6px]">
                <Icon name="threeDots" className="w-[15px]" />
              </Button>
            </Dropdown>
          </div>
        </div >
      </div >
      <TasKHeaderNotificationsModal handleCloseModal={handleCloseModal} visible={visible} />
      <TaskBotSettingModal visible={botSettingVisible} setVisible={setBotSettingVisible} {...autoTaskBot} />
      <DashboardModal />
    </>
  );
};

export default TaskHeader;
