import { z } from "zod"


const housesSchema = z.object({
  id: z.number(),
  name: z.string()
})

export const serviceSchema = z.object({
  id: z.number().optional(),
  serviceId: z.number(),
  houses: z.array(housesSchema)
})

const fullSchema = z.object({
  uz: z.string(),
  ru: z.string(),
  en: z.string(),
  kr: z.string(),
  tr: z.string()
})

const addressSchema = z.object({
  districtUz: z.string(),
  districtRu: z.string(),
  districtKr: z.string(),
  districtEn: z.string(),
  districtTr: z.string(),
  cityUz: z.string(),
  cityRu: z.string(),
  cityKr: z.string(),
  cityEn: z.string(),
  cityTr: z.string(),
  address: z.string(),
  full: fullSchema
})

export const uzumSchema = z.object({
  username: z.string(),
  password: z.string(),
  status: z.boolean(),
  services: z.array(serviceSchema).optional()
})

export const housesObjectSchema = z.object(
  {
    id: z.number(),
    name: z.string(),
    address: addressSchema,
    companyId: z.number(),
    companyName: z.string().nullable()
  }
)



export type UzumIntegration = z.infer<typeof uzumSchema>
export type UzumHouses = z.infer<typeof housesObjectSchema>
export type UzumService = z.infer<typeof serviceSchema>