import React from 'react';

import ClientInformation from './ClientInformation';
import ContractInformation from './ContractInformation';

import styles from './Contract.module.scss';

const Contract = () => (
  <div className={styles.contractSettings}>
    <div className={styles.contractSettingsTop}>
      <div>
        <ContractInformation />
      </div>
      <div>
        <ClientInformation />
      </div>
    </div>
  </div>
);

export default Contract;
