import React, { useState } from 'react';
import styles from './createPayment.module.scss';
import CardPayment from '../../assets/icons/CardPayment';
import PhoneActiveIcon from '../../assets/icons/PhoneActiveIcon';
import { ContractDataForCreatePayment } from '../../utils/models/ContractDataForCreatePayment';
import PaymentCreateModal from './PaymentCreateModal';
import { useTranslation } from 'react-i18next';
import ReceiveSquareIcon from '../../assets/icons/ReceiveSquareIcon';
import { Spin } from 'antd';
import { useDownloadContractAct } from '../../pages/paymentV2/services/mutation';

interface Props {
  data: ContractDataForCreatePayment | undefined;
}

const ClientInfo: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const downloadAct = useDownloadContractAct(data?.id || -1);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const handleOpenPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const handleDownloadAct = () => {
    downloadAct.mutate();
  };

  return (
    <>
      <div className={`d-flex align-center justify-space-between ${styles.clientInfoCont}`}>
        <div
          style={{
            alignItems: data?.clientPhones && data?.clientPhones.length > 3 ? 'flex-start' : 'center'
          }}
          className={styles.clientInfoCont_client}
        >
          <div>
            <p className={styles.clientInfoCont_client_title}>{t('create_payment.Shartnoma')}</p>
            <p className={styles.clientInfoCont_client_value}>{data?.number}</p>
          </div>
          <div>
            <p className={styles.clientInfoCont_client_title}>{t('create_payment.Mijoz')}</p>
            <p className={styles.clientInfoCont_client_value}>{data?.clientName}</p>
          </div>
          <div>
            <p className={styles.clientInfoCont_client_title}>{t('create_payment.Telefon_raqami')}</p>
            <div className={styles.clientInfoCont_client_phone_cont}>
              {data?.clientPhones.map((item, index) => {
                return (
                  <span key={index} className={styles.clientInfoCont_client_phone_cont_phone}>
                    {item.phone}
                    {item.active ? <PhoneActiveIcon className={styles.clientInfoCont_client_phone_cont_phone_icon} /> : ';'}
                  </span>
                );
              })}
            </div>
          </div>
          <div>
            <p className={styles.clientInfoCont_client_title}>{t("create_payment.Uy_ma'lumotlari")}</p>
            <p className={styles.clientInfoCont_client_value}>
              {data?.houseName}, {data?.buildingName}, {data?.flatNumber}-{t('create_payment.uy')}
            </p>
          </div>
        </div>
        <Spin spinning={downloadAct.isLoading}>
          <div onClick={handleDownloadAct} className={styles.downloadAkt}>
            <ReceiveSquareIcon className={styles.downloadAkt_icon} />
            <p className={styles.downloadAkt_label}>{t('company_config.Akt').toUpperCase()}</p>
          </div>
        </Spin>
        <div onClick={handleOpenPaymentModal} className={styles.paymentCreateButton}>
          <CardPayment className={styles.paymentCreateButton_icon} />
          <p className={styles.paymentCreateButton_label}>{t("create_payment.To'lov_qilish")}</p>
        </div>
      </div>
      <PaymentCreateModal contractId={data?.id || -1} residue={data?.residue || 0} visible={openPaymentModal} close={handleClosePaymentModal} />
    </>
  );
};

export default ClientInfo;
