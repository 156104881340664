import React from 'react';

interface Props {
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
  style?: React.CSSProperties;
  fill?: string;
}

const CheveronRight: React.FC<Props> = ({ className, onClick, style, fill = '#9CA3AF' }) => (
  <svg className={className} onClick={onClick} style={style} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.01119 8.08891C3.7964 7.87412 3.7964 7.52588 4.01119 7.31109L5.82228 5.5L4.01119 3.68891C3.7964 3.47412 3.7964 3.12588 4.01119 2.91109C4.22598 2.6963 4.57422 2.6963 4.78901 2.91109L6.98901 5.11109C7.20379 5.32588 7.20379 5.67412 6.98901 5.88891L4.78901 8.08891C4.57422 8.3037 4.22598 8.3037 4.01119 8.08891Z"
      fill={fill}
    />
  </svg>
);

export default CheveronRight;
