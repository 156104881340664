import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorage } from '../../service/LocalStorage';
import { LIST_VIEW_CRM } from '../../utils/constants/localStorageKeys';

const listView = LocalStorage.get(LIST_VIEW_CRM);

interface AssigmentState {
  listViewActiveIds: number[];
  taskListViewActiveIds: number[] | null;
  taskListViewIgnoreIds: number[];
  isOpenTableSettings: boolean;
  sortableColumn: boolean;
  aktiveVoronkaId: number;
  taskBalance: number;
  defaultActiveKey: string;
  balance?: number;
  showListView: boolean;
  allColumnDisable: boolean;
  calendarView: string | undefined;
  openDublicateModal: boolean;
  activeDashboardId: number;
  checked: boolean;
  openDashboard: boolean;
  openDashboard1: boolean;
  updateWidget: boolean;
  isDefaultChecked: boolean;
  scrollPosition: number;
  scolledIds: Array<string>;
  scrolledIdsAndPositions: Array<{ id?: number; position?: number }>;
  createVoronka: boolean;
  listColumnIds: number[]
}

const initialState: AssigmentState = {
  listViewActiveIds: [],
  taskListViewActiveIds: [],
  taskListViewIgnoreIds: [],
  sortableColumn: false,
  aktiveVoronkaId: 0,
  isOpenTableSettings: false,
  balance: 0,
  taskBalance: 0,
  showListView: !!listView,
  allColumnDisable: false,
  openDublicateModal: false,
  activeDashboardId: 0,
  calendarView: 'kanban' || undefined,
  defaultActiveKey: '1',
  checked: false,
  openDashboard: false,
  openDashboard1: false,
  updateWidget: false,
  isDefaultChecked: false,
  scrollPosition: 0,
  scolledIds: [],
  scrolledIdsAndPositions: [],
  createVoronka: false,
  listColumnIds: []
};

export const AssigmentSlice = createSlice({
  name: 'allHouseFilter',
  initialState,
  reducers: {
    setSortableColumn: (
      state,
      action: {
        payload: any;
      }
    ) => {
      state.sortableColumn = action.payload;
    },
    setActiveVoronkaId: (
      state,
      action: {
        payload: any;
      }
    ) => {
      state.aktiveVoronkaId = action.payload;
    },
    setActiveDashboardId: (
      state,
      action: {
        payload: any;
      }
    ) => {
      state.activeDashboardId = action.payload;
    },
    openTableSettings: (
      state,
      action: {
        payload: boolean;
      }
    ) => {
      state.isOpenTableSettings = action.payload;
    },
    setClearListView: state => {
      state.balance = 0;
      state.listViewActiveIds = [];
    },
    setClearTaskListView: state => {
      state.taskBalance = 0;
      state.taskListViewActiveIds = [];
    },
    setBalanceAndIds: (
      state,
      action: {
        payload: {
          ids: number[];
          balance?: number | undefined;
        };
      }
    ) => {
      state.balance = action.payload.balance;
      state.listViewActiveIds = action.payload.ids;
    },
    setTaskBalanceAndIds: (
      state,
      action: {
        payload: {
          ids: number[] | null;
          balance: number;
        };
      }
    ) => {
      state.taskBalance = action.payload.balance;
      state.taskListViewActiveIds = action.payload.ids;
    },
    setTaskIgnoreIds: (state, action: PayloadAction<number[]>) => {
      state.taskListViewIgnoreIds = action.payload;
    },
    setListView: (
      state,
      action: {
        payload: boolean;
      }
    ) => {
      state.showListView = action.payload;
    },
    setAllColumnDisable: (
      state,
      action: {
        payload: boolean;
      }
    ) => {
      state.allColumnDisable = action.payload;
    },
    setCalendarView: (
      state,
      action: {
        payload: string | undefined;
      }
    ) => {
      state.calendarView = action.payload;
    },
    setChangeDefaultActiveKey: (state, action: { payload: string }) => {
      state.defaultActiveKey = action.payload;
    },
    setOpenDublicateModal: (state, action: { payload: boolean }) => {
      state.openDublicateModal = action.payload;
    },
    setChecked: (state, action: { payload: boolean }) => {
      state.checked = action.payload;
    },
    setOpenDashboard: (state, action: { payload: boolean }) => {
      state.openDashboard = action.payload;
    },
    setOpenDashboard1: (state, action: { payload: boolean }) => {
      state.openDashboard1 = action.payload;
    },
    setUpdateWidgets: (state, action: { payload: boolean }) => {
      state.updateWidget = action.payload;
    },
    setIsDefaultChecked: (state, action: { payload: boolean }) => {
      state.isDefaultChecked = action.payload;
    },
    setScrolledIdsAndPositions: (state, action: { payload: { id?: number; position?: number } }) => {
      const updatedIdsAndPositions = [...state.scrolledIdsAndPositions];

      // Check if the id exists in the array
      const existingIndex = state.scrolledIdsAndPositions.findIndex(item => item.id === action.payload.id);

      // If the id exists, update its position
      if (existingIndex !== -1) {
        updatedIdsAndPositions[existingIndex].position = action.payload.position;
        updatedIdsAndPositions[existingIndex].id = action.payload.id;
      } else {
        // If the id doesn't exist, add it to the array
        updatedIdsAndPositions.push({
          id: action.payload.id,
          position: action.payload.position
        });
      }

      state.scrolledIdsAndPositions = updatedIdsAndPositions;
    },
    setCreateVoronka: (state, action: { payload: boolean }) => {
      state.createVoronka = action.payload;
    },
    setListColumnIds: (state, action: { payload: number[] }) => {
      state.listColumnIds = action.payload
    }
  }
});

export const {
  setActiveVoronkaId,
  setSortableColumn,
  openTableSettings,
  setClearListView,
  setBalanceAndIds,
  setListView,
  setAllColumnDisable,
  setCalendarView,
  setActiveDashboardId,
  setClearTaskListView,
  setTaskBalanceAndIds,
  setChangeDefaultActiveKey,
  setOpenDublicateModal,
  setTaskIgnoreIds,
  setChecked,
  setOpenDashboard,
  setOpenDashboard1,
  setUpdateWidgets,
  setIsDefaultChecked,
  setScrolledIdsAndPositions,
  setCreateVoronka,
  setListColumnIds
} = AssigmentSlice.actions;
export default AssigmentSlice.reducer;
