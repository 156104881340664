import React from 'react';

const TickSquareIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.50033 18.3334H12.5003C16.667 18.3334 18.3337 16.6667 18.3337 12.5001V7.50008C18.3337 3.33341 16.667 1.66675 12.5003 1.66675H7.50033C3.33366 1.66675 1.66699 3.33341 1.66699 7.50008V12.5001C1.66699 16.6667 3.33366 18.3334 7.50033 18.3334Z"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.45801 9.99993L8.81634 12.3583L13.5413 7.6416" stroke="#444444" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TickSquareIcon;
