import { z } from 'zod';

export const paylovConfigSchema = z.object({
  id: z.number({ message: '' }).nullable().optional(),
  subMerchant: z.string({ message: '' }).nullable(),
  houses: z
    .array(z.number(), { message: '' })
    .nullable()
    .refine(arg => arg && arg?.length > 0, {
      message: ''
    }),
  isActive: z.boolean({ message: '' }).nullable()
});

export interface IPaylovConfig extends z.infer<typeof paylovConfigSchema> {}
