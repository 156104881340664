import React, { useState } from 'react';
import styles from './restorePassword.module.scss';
import bg from '../../assets/img/bg_new.jpg';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../assets/icons/CloseIcon';
import PrivacyColicies from '../../components/privacyColicies/PrivacyColicies';
import SendLogin from '../../components/restorePassword/sendLogin/SendLogin';
import SendCode from '../../components/restorePassword/sendCode/SendCode';
import RestorePasswordBody from '../../components/restorePassword/restorePassword/RestorePassword';

function RestorePassword() {
  const [username, setUsername] = useState({
    phone: '',
    username: ''
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [privacyPolicies, setPrivacyPolicies] = useState(false);
  const { i18n, t } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  //handlePrivacyPolicies
  const handlePrivacyPolicies = () => {
    setPrivacyPolicies(true);
  };

  //handlePrivacyPoliciesClose
  const handlePrivacyPoliciesClose = () => {
    setPrivacyPolicies(false);
  };

  // handle change page
  const handleChangePage = (page: number) => {
    setPageNumber(page);
  };

  // get main body
  const getMainBody = () => {
    switch (pageNumber) {
      case 1:
        return <SendLogin handleUsername={handleUser} changePage={handleChangePage} />;
      case 2:
        return <SendCode user={username} changePage={handleChangePage} />;
      case 3:
        return <RestorePasswordBody user={username} changePage={handleChangePage} />;
      default:
        return <div>ups:(</div>;
    }
  };

  // handle username
  const handleUser = (val: { phone: string; username: string }) => {
    setUsername(val);
  };

  return (
    <div className={styles.login}>
      <img src={bg} className={styles.login_login_newYear_bg} alt="bg" />
      <div className={styles.login_container_div}>
        <div className={styles.login_body}>
          {getMainBody()}
          <footer className={styles.footer} onClick={handlePrivacyPolicies}>
            {t('privacy_policies.Yuqoridagi_tugmani_bosish_orqali_siz_bizning')}{' '}
            <span>&nbsp;{t('privacy_policies.foydalanish_shartlari')} &nbsp;</span> {t('privacy_policies.va')}{' '}
            <span> &nbsp;{t('privacy_policies.maxfiylik_siyosatimizga')}</span>
          </footer>
        </div>
        <div
          className={`${styles.privacyPoliciesMask} ${privacyPolicies ? styles.privacyPoliciesMask_active : ''}`}
          onClick={handlePrivacyPoliciesClose}
        />
        <div className={`${styles.privacyPolicies} ${privacyPolicies ? styles.privacyPolicies_active : ''}`}>
          <span className={styles.privacyPolicies_close} onClick={handlePrivacyPoliciesClose}>
            <CloseIcon />
          </span>
          <PrivacyColicies />
        </div>
        <div className={styles.lang}>
          <span
            onClick={() => changeLanguageHandler('en')}
            className={styles.lang_item + (i18n.language === 'en' ? ' ' + styles.lang_item_active : '')}
          >
            English
          </span>
          <span className={styles.lang_dot} />
          <span
            onClick={() => changeLanguageHandler('ru')}
            className={styles.lang_item + (i18n.language === 'ru' ? ' ' + styles.lang_item_active : '')}
          >
            Русский
          </span>
          <span className={styles.lang_dot} />
          <span
            onClick={() => changeLanguageHandler('uz')}
            className={styles.lang_item + (i18n.language === 'uz' ? ' ' + styles.lang_item_active : '')}
          >
            O’zbek
          </span>
          <span className={styles.lang_dot} />
          <span
            onClick={() => changeLanguageHandler('tr')}
            className={styles.lang_item + (i18n.language === 'tr' ? ' ' + styles.lang_item_active : '')}
          >
            Turk
          </span>
        </div>
      </div>
    </div>
  );
}

export default RestorePassword;
