import React, { useEffect } from 'react';
import { useAppDispatch } from '@hooks/reduxHooks';
import { getFormId, setOpenShablon } from '@reducers/TextEditorSlice';
import { Modal } from 'antd';
import { useQueryClient } from 'react-query';

// import DefaultForms from './default-forms/DefaultForms';
import { useGetForm } from './service/queries';
import Shablon1 from './shablon/Shablon1';
import { queryKeys } from './utils/constants/queryKeys';

import formaImg from '../../../../../assets/img/forma.png';

import styles from './webModalForma.module.scss';

type Props = {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const WebFormaModal: React.FC<Props> = ({ visible, setVisible }) => {
  const dispatch = useAppDispatch();
  const { data } = useGetForm();
  const qc = useQueryClient();

  useEffect(() => {
    if (visible) {
      qc.fetchQuery({ queryKey: [queryKeys.FORMA] });
    }
  }, [visible]);

  // handleClose
  const handleClose = () => {
    setVisible(false);
  };

  const handleOpenShablon = () => {
    dispatch(setOpenShablon(true));
  };

  return (
    <div className={styles.webFormaModal}>
      <Modal
        visible={visible}
        onCancel={handleClose}
        title={<p className={styles.webFormaModal_title}>Web formalar</p>}
        width={1156}
        footer={false}
        className={styles.mainModal}
      >
        <div className={styles.wrapper}>
          <div className={styles.extands}>
            <h1>Mavjudlar</h1>
            <div className={styles.extands_elements}>
              {data?.map((item, index) => (
                <div
                  className={styles.extands_elements_item}
                  key={index}
                  onClick={() => {
                    dispatch(getFormId(item?.id));
                    handleOpenShablon();
                  }}
                >
                  <img src={formaImg} alt="err" />
                  <div className={styles.extands_elements_item_bottom}>
                    <p>{item?.name}</p>
                    <span>{item?.createdDate}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <DefaultForms /> */}
        </div>
      </Modal>
      <Shablon1 />
    </div>
  );
};

export default WebFormaModal;
