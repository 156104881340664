import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import styles from './discountInfo.module.scss';

interface IProps {
  title: string | false | undefined | any;
  children: React.ReactNode;
  content: JSX.Element;
  closable?: boolean;
  width?: string | number | undefined;
  wrapClassName?: string;
}

const DiscountInfo: FC<IProps> = ({ children, content, title, closable = true, width, wrapClassName = '' }) => {
  const [visible, setVisible] = useState(false);

  const onCancle = () => {
    setVisible(false);
  };

  const openModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancle}
        title={title}
        footer={false}
        wrapClassName={`${styles.modal} ${wrapClassName}`}
        closable={closable}
        width={width}
      >
        {content}
      </Modal>
      <div onClick={openModal}>{children}</div>
    </>
  );
};

export default DiscountInfo;
