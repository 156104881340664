import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { StaffPayment } from '../../utils/models/Staff';

import styles from './kpiInformation.module.scss';

interface Props {
  data: StaffPayment[];
  currentPage: number;
}

const TableBalance: React.FC<Props> = ({ data, currentPage }) => {
  const { t } = useTranslation();

  const initialColumn = [
    {
      title: '№',
      render: (text: any, record: any, index: number) => (currentPage - 1) * 10 + index + 1,
      width: '5%'
    },
    {
      title: t('broker.Sana'),
      align: 'left',
      width: '25%',
      dataIndex: 'date'
    },
    {
      title: t('broker.Tolov_miqdori'),
      // dataIndex: 'amount',
      align: 'left',
      width: '25%',
      render: (record: any) => (
        <span>
          {record?.amount?.toLocaleString('ru')} {record?.currency?.ccy}
        </span>
      )
    },
    {
      title: t('broker.Masul'),
      dataIndex: 'createdBy',
      align: 'left',
      width: '45%'
    }
  ];

  return (
    <div className={styles.container_table}>
      <Table columns={initialColumn as any} dataSource={data} size="small" rowKey="id" pagination={false} />
    </div>
  );
};

export default TableBalance;
