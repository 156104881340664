import { Button, Form, Input } from 'antd';
import React, { useRef, useState } from 'react';
import styles from '../addCard.module.scss';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import { useCreateCard, useCreateCardResentCode, useCreateCardVerify } from '../../../../../queries/mutation';
import { CreateCardResponse } from '../../../../../utils/models/Card';
import VuesaxCardIcon from '../../../../../assets/icons/VuesaxCardIcon';

interface Props {
  styleKey: string;
  id: number;
  changePage: () => void;
}

interface IForm {
  card_expired: string;
  card_number: string;
}

const MainInputsPayme: React.FC<Props> = ({ id, styleKey, changePage }) => {
  const expiredInputRef = useRef(null);
  const codeInputRef = useRef(null);
  const cardNumberInputRef = useRef(null);
  const cardId = useRef<number>();
  const [form] = Form.useForm<IForm>();

  const [haveError, setHaveError] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [time, setTime] = useState(0);
  const [showVerify, setShowVerify] = useState(false);

  const createCard = useCreateCard(onSuccess);
  const verifyCard = useCreateCardVerify(id, onSuccessCode);
  const resentCode = useCreateCardResentCode(onSuccess);

  const { t } = useTranslation();

  function onSuccess(data: CreateCardResponse) {
    setTime(Math.round(data.wait / 1000));
    setShowVerify(true);
    cardId.current = data.id;
    // @ts-ignore
    codeInputRef.current!.focus({
      cursor: 'start'
    });
  }

  function onSuccessCode() {
    changePage();
  }

  const handleChange = (e: any) => {
    if (e.target.value.length === 19) {
      // @ts-ignore
      expiredInputRef.current!.focus({
        cursor: 'start'
      });
    }
  };

  const handleChangeDate = (e: any) => {
    if (!e.target.value) {
      // @ts-ignore
      cardNumberInputRef.current!.focus({
        cursor: 'end'
      });
    }
  };

  const resentCodeHandler = () => {
    if (cardId.current) resentCode.mutate(cardId.current);
  };

  const handleFinish = (val: IForm) => {
    setHaveError(false);
    createCard.mutate({
      number: val.card_number.replaceAll(' ', ''),
      style: styleKey,
      contractId: id,
      expire: val.card_expired.replaceAll('/', '')
    });
  };

  const onFinishFailed = () => {
    setHaveError(true);
  };

  const handleFinishCode = (val: { code: string }) => {
    setErrorCode(false);
    verifyCard.mutate({
      code: val.code,
      cardId: cardId.current || 0
    });
  };

  const onFinishFailedCode = () => {
    setErrorCode(true);
  };

  const getTime = () => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
      const minute = Math.floor(time / 60) >= 10 ? Math.floor(time / 60) : '0' + Math.floor(time / 60);
      const second = time % 60 >= 10 ? time % 60 : '0' + (time % 60);
      return `${minute}:${second}`;
    } else {
      return (
        <div onClick={resentCodeHandler} className={styles.cont_inputs_cont_timer_cont_time_resent}>
          {t('contract.Qayta_yuborish')}
        </div>
      );
    }
  };

  return (
    <div className={styles.cont_inputs_cont}>
      <div className={styles.cont_inputs_cont_label}>{t('contract.Karta_raqami')}</div>
      {!showVerify ? (
        <Form form={form} onFinishFailed={onFinishFailed} onFinish={handleFinish} name={'cardForm'}>
          <div className={styles.cont_inputs_cont_card_input}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: '',
                  len: 19
                }
              ]}
              name={'card_number'}
              noStyle
            >
              <InputMask
                mask="9999 9999 9999 9999"
                // @ts-ignore*
                maskChar={''}
                onChange={handleChange}
              >
                {(inputProps: any) => (
                  <Input
                    ref={cardNumberInputRef}
                    style={{
                      borderColor: haveError ? 'red' : undefined
                    }}
                    placeholder={'XXXX XXXX XXXX XXXX'}
                    prefix={<VuesaxCardIcon />}
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: '',
                  len: 5
                }
              ]}
              name={'card_expired'}
              noStyle
            >
              <InputMask
                onChange={handleChangeDate}
                mask="99/99"
                // @ts-ignore*
                maskChar={''}
              >
                {(inputProps: any) => (
                  <Input
                    style={{
                      borderColor: haveError ? 'red' : undefined
                    }}
                    placeholder={'XX/XX'}
                    ref={expiredInputRef}
                    {...inputProps}
                  />
                )}
              </InputMask>
            </Form.Item>
          </div>
          <Button loading={createCard.isLoading} htmlType={'submit'} className={styles.cont_inputs_cont_button} type={'primary'}>
            {t('contract.SMS_yuborish')}
          </Button>
        </Form>
      ) : (
        <Form onFinishFailed={onFinishFailedCode} onFinish={handleFinishCode} name={'codeForm'}>
          <div className={styles.cont_inputs_cont_disabled_input}>
            <div className={styles.cont_inputs_cont_disabled_input_number_cont}>
              <VuesaxCardIcon />
              <div className={styles.cont_inputs_cont_disabled_input_number_cont_number}>{form.getFieldValue('card_number')}</div>
            </div>
            <div className={styles.cont_inputs_cont_disabled_input_expired}>{form.getFieldValue('card_expired')}</div>
          </div>
          <div className={styles.cont_inputs_cont_timer_cont}>
            <div className={styles.cont_inputs_cont_label}>{t('contract.Sizga_SMS_xabar_yuborildi')}</div>
            <div className={styles.cont_inputs_cont_timer_cont_time}>{getTime()}</div>
          </div>
          <div className={styles.cont_inputs_cont_label}>{t('contract.Maxfiy_raqam')}</div>
          <Form.Item noStyle name={'code'} rules={[{ required: true, message: '' }]}>
            <InputMask
              mask="999999"
              // @ts-ignore*
              maskChar={''}
            >
              {(inputProps: any) => (
                <Input
                  ref={codeInputRef}
                  className={styles.cont_inputs_cont_code_input}
                  style={{
                    borderColor: errorCode ? 'red' : undefined
                  }}
                  placeholder={'XXXXXX'}
                  {...inputProps}
                />
              )}
            </InputMask>
          </Form.Item>
          <Button loading={verifyCard.isLoading} htmlType={'submit'} className={styles.cont_inputs_cont_button} type={'primary'}>
            {t('contract.Kartani_qoshish')}
          </Button>
        </Form>
      )}
    </div>
  );
};

export default MainInputsPayme;
