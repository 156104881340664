/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Pagination, Spin } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import EditIcon from '../../assets/icons/EditIcon';
import PaginationNext from '../../assets/icons/PaginationNext';
import PaginationPrev from '../../assets/icons/PaginationPrev';
import { useOneStaffInformation } from '../../queries/queries';
import DatePicker from '../../service/datePicker';
import { MONTH_FORMAT } from '../../utils/constants/format';
import { OneStaff, StaffTable, StaffType } from '../../utils/models/Staff';

import BrokerModal from './BrokerModal';
import OutComeModal from './OutComeModal';
import TableOneBroker from './TableOneBroker';

import styles from '../../pages/broker/broker.module.scss';

const { RangePicker } = DatePicker;

interface Props {
  visible: boolean;
  onClose: (visible: boolean) => void;
  tableSize: number;
  staff?: StaffTable;
}

const disabledDate: RangePickerProps['disabledDate'] = current => current && current > dayjs().endOf('day');

const BrokerDriver: React.FC<Props> = ({ onClose, visible, tableSize, staff }) => {
  const [outComeModal, setOutComeModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState([dayjs(new Date()).format(MONTH_FORMAT), dayjs(new Date()).format(MONTH_FORMAT)]);
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, isFetching, data } = useOneStaffInformation(staff?.id || -1, selectedMonth);

  // console.log('dduck1', data?.balances?.map((item: any) => item?.currencyDto?.ccy));
  // console.log('dduck0', data);

  const { t } = useTranslation();

  const showEdit = staff?.type === StaffType.OUT_COMPANY;

  const handleOutComeModal = (visible: boolean) => {
    setOutComeModal(visible);
  };

  const handleUpdateModal = (visible: boolean) => {
    setUpdateModal(visible);
  };

  const handleClose = () => {
    setSelectedMonth([dayjs(new Date()).format(MONTH_FORMAT), dayjs(new Date()).format(MONTH_FORMAT)]);
    onClose(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeMonth = (date: any, dateString: string[]) => {
    setSelectedMonth(dateString);
  };

  const getTableData = () => {
    if (data?.payments) {
      const table = [...data?.payments];
      const page = currentPage - 1;

      return table.slice(page * 10, page * 10 + 10);
    }
    return [];
  };

  return (
    <>
      <Drawer width={743} headerStyle={{ display: 'none' }} placement="right" onClose={handleClose} visible={visible}>
        <Spin spinning={isFetching || isLoading}>
          <div className={styles.driver}>
            <div className={styles.driver_header}>
              <div className={styles.driver_header_left}>
                <span>{staff?.fullName}</span>
                {showEdit && <EditIcon onClick={() => handleUpdateModal(true)} />}
              </div>
              <CloseOutlined onClick={handleClose} />
            </div>
            <div className={styles.driver_balance}>
              <div className={styles.driver_balance_rigth}>
                <div className={styles.driver_balance_rigth_title}>{t('broker.Joriy_balans')}</div>
                <div className={styles.driver_balance_rigth_value}>
                  <div className={styles.driver_balance_right_value} style={{ fontSize: '30px' }}>
                    {data?.balances
                      ?.map((item: any) => {
                        const currency = item?.currencyDto?.ccy;
                        const amount = item?.amount;

                        return `${amount?.toLocaleString('ru')} ${currency}`;
                      })
                      .join(' | ')}
                  </div>
                </div>
              </div>
              <Button onClick={() => handleOutComeModal(true)} className={styles.driver_balance_button} type="primary">
                {t('broker.Pul_chiqarish')}
              </Button>
            </div>
            <div className={styles.driver_select}>
              {/* @ts-ignore */}
              <RangePicker
                disabledDate={disabledDate as any}
                onChange={handleChangeMonth}
                allowClear={false}
                value={selectedMonth ? [dayjs(selectedMonth[0], MONTH_FORMAT), dayjs(selectedMonth[1], MONTH_FORMAT)] : undefined}
                picker="month"
                format={MONTH_FORMAT}
                size="small"
                placeholder={[t('broker.Boshlanishi'), t('broker.Tugashi')]}
              />
            </div>
            <div className={styles.driver_info}>
              <div className={styles.driver_info_item}>
                <p className={styles.driver_info_item_title}>{t('broker.Sotilgan_xonadonlar_soni')}</p>
                <p className={styles.driver_info_item_value}>{data?.contracts}</p>
              </div>
              <div className={styles.driver_info_item}>
                <p className={styles.driver_info_item_title}>{t('broker.Boshlangich_tolovlar_undirilgan_shartnomalar_soni')}</p>
                <p className={styles.driver_info_item_value}>{data?.activeContracts}</p>
              </div>
              <div className={styles.driver_info_item}>
                <p className={styles.driver_info_item_title}>{t('broker.Hisoblangan_mukofot_puli')}</p>
                <p className={styles.driver_info_item_value} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {/* @ts-ignore */}
                  {data?.reward?.map((item: any, index: number) => {
                    const currency = item?.currencyDto?.ccy;
                    const amount = item?.amount;
                    // @ts-ignore
                    const isLastItem = index === data?.reward?.length - 1;

                    return (
                      <React.Fragment key={index}>
                        <span key={index} style={{ marginBottom: '-10px' }}>
                          {amount?.toLocaleString('ru')} {currency}
                        </span>
                        {!isLastItem}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
              <div className={styles.driver_info_item}>
                <p className={styles.driver_info_item_title}>{t('broker.Qabul_qilib_olingan_mukofot_pullari_summasi')}</p>
                <p className={styles.driver_info_item_value} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  {/* @ts-ignore */}
                  {data?.paymentSum?.map((item: any, index: number) => {
                    const currency = item?.currencyDto?.ccy;
                    const amount = item?.amount;
                    // @ts-ignore
                    const isLastItem = index === data?.reward?.length - 1;

                    return (
                      <React.Fragment key={index}>
                        <span key={index} style={{ marginBottom: '-10px' }}>
                          {amount?.toLocaleString('ru')} {currency}
                        </span>
                        {!isLastItem}
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
            </div>
            <TableOneBroker currentPage={currentPage} data={getTableData()} />
            <div className={styles.driver_pagination}>
              <Pagination
                onChange={handlePageChange}
                prevIcon={
                  <div className={styles.driver_pagination_next_prev_buttons}>
                    <PaginationPrev />
                  </div>
                }
                nextIcon={
                  <div className={styles.driver_pagination_next_prev_buttons}>
                    <PaginationNext />
                  </div>
                }
                pageSize={10}
                showSizeChanger={false}
                current={currentPage}
                total={data?.payments.length}
              />
            </div>
          </div>
        </Spin>
      </Drawer>
      <OutComeModal id={staff?.id || -1} date={selectedMonth} visible={outComeModal} onClose={handleOutComeModal} data={data as OneStaff} />
      <BrokerModal tableSize={tableSize} visible={updateModal} onClose={handleUpdateModal} id={staff?.id} />
    </>
  );
};

export default BrokerDriver;
