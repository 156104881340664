import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';
import { PipeTemplateModel } from '../utils/models/PipeTemplateModel';

export function useGetAllPipeTemplates() {
  return useQuery<PipeTemplateModel[]>({
    queryKey: [queryKeys.PIPE_TEMPLATE_ALL],
    queryFn: async () => {
      const res = await $api.get(endPoints.PIPE_TEMPLATE_ALL);

      return res.data.data;
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false
  });
}

export function useGetOnePipeTemplete(id: number) {
  return useQuery<PipeTemplateModel>({
    queryKey: [queryKeys.PIPE_TEMPLATE, id],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.PIPE_TEMPLATE}/${id}`);

      return res.data.data;
    },
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false
  });
}

export function useGetCustomTemplete() {
  return useQuery<PipeTemplateModel[]>({
    queryKey: [queryKeys.PIPE_TEMPLATE],
    queryFn: async () => {
      const res = await $api.get(`${endPoints.PIPE_TEMPLATE}?type=CUSTOM`);

      return res.data.data;
    },
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false
  });
}
