import React from 'react';
import { Layout } from 'antd';

import styles from './header.module.scss';

const { Header } = Layout;

const MainHeader: React.FC = ({ children }) => (
  <Header className={`menu_header flex items-center justify-between ${styles.main_header}`}>{children}</Header>
);

export default MainHeader;
