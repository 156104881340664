import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { ShaxmatkaContextDataState } from '@pages/shaxmatka/hooks/ShaxmatkaContext';
import { useFlatChangePrice } from '@queries/mutation';
import { setShaxmatka } from '@reducers/ShaxmatkaSlice';
import { helper } from '@utils/helper/helper';
import { FlatId } from '@utils/models/Flat';
import { PriceChangeType } from '@utils/models/PriceChangeType';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './incrementPrice.module.scss';

interface IProps {
  setContext: React.Dispatch<React.SetStateAction<ShaxmatkaContextDataState>>;
  context: ShaxmatkaContextDataState;
}

const IncrementPrice: FC<IProps> = ({ context, setContext }) => {
  const { t } = useTranslation();
  const changePrice = useFlatChangePrice();
  const { incrementPrice, houseId, activeFlats } = context;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { data: shaxmatka, priceCalculateType, tableMode } = useAppSelector(state => state.shaxmatkaReducer);

  const handleClose = () => {
    setContext({ incrementPrice: '' });
  };

  const onFinish = (values: { type: PriceChangeType; quantity: string; increase: boolean }) => {
    if (houseId) {
      changePrice
        .mutateAsync({
          houseId,
          priceChangeType: values.type,
          value: helper.deleteNotNumbersAndParseFloatNumber(String(values.quantity)).number,
          increase: values.increase
        })
        .then(() => {
          handleClose();
        });
    } else {
      const valuesCopy = {
        ...values,
        quantity: helper.deleteNotNumbersAndParseFloatNumber(values?.quantity).number
      };
      const rooms = document.querySelectorAll('.selectableItems');
      const arr: FlatId[] = [];

      if (tableMode) {
        activeFlats?.forEach((item: any) => {
          arr.push(item);
        });
      } else {
        rooms.forEach((item: any) => {
          const flat: FlatId = JSON.parse(item.getAttribute('data'));

          if (flat.isSelected) arr.push(flat);
        });
      }

      const shaxmatkaCopy = shaxmatka.map((podezd: any) =>
        podezd.map((floor: any) =>
          floor.map((apartment: FlatId) => {
            function Selected(a: FlatId) {
              return a && a.floor === apartment.floor && a.xaxis[0] === apartment.xaxis[0] && a.entrance === apartment.entrance;
            }

            if (arr && arr.some((flat: FlatId) => Selected(flat)) && incrementPrice === 'SELECT') {
              const arrFlat = arr.find((flat: FlatId) => Selected(flat));

              if (priceCalculateType === 'PER_AREA') {
                const nObj = {
                  pricePerArea:
                    arrFlat?.pricePerArea! +
                    (values.increase ? 1 : -1) *
                      (values?.type === PriceChangeType.PERCENTAGE ? (arrFlat?.pricePerArea! / 100) * valuesCopy.quantity : valuesCopy?.quantity),
                  pricePerAreaRepaired:
                    arrFlat?.pricePerAreaRepaired! +
                    (values.increase ? 1 : -1) *
                      (values?.type === PriceChangeType.PERCENTAGE
                        ? (arrFlat?.pricePerAreaRepaired! / 100) * valuesCopy.quantity
                        : valuesCopy?.quantity)
                };

                return {
                  ...arrFlat,
                  pricePerArea: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerArea)).number,
                  pricePerAreaRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerAreaRepaired)).number,
                  priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerArea * arrFlat?.area!)).number,
                  priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerAreaRepaired * arrFlat?.area!)).number
                };
              }
              const nObj = {
                priceNotRepaired:
                  arrFlat?.priceNotRepaired! +
                  (values.increase ? 1 : -1) *
                    (values?.type === PriceChangeType.PERCENTAGE ? (arrFlat?.priceNotRepaired! / 100) * valuesCopy.quantity : valuesCopy?.quantity),
                priceRepaired:
                  arrFlat?.priceRepaired! +
                  (values.increase ? 1 : -1) *
                    (values?.type === PriceChangeType.PERCENTAGE ? (arrFlat?.priceRepaired! / 100) * valuesCopy.quantity : valuesCopy?.quantity)
              };

              return {
                ...arrFlat,
                ...nObj,
                pricePerArea: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceNotRepaired / arrFlat?.area!)).number,
                pricePerAreaRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceRepaired / arrFlat?.area!)).number,
                priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceNotRepaired)).number,
                priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceRepaired)).number
              };
            }
            if (incrementPrice === 'BLOCK') {
              if (priceCalculateType === 'PER_AREA') {
                const nObj = {
                  pricePerArea:
                    apartment?.pricePerArea! +
                    (values.increase ? 1 : -1) *
                      (values?.type === PriceChangeType.PERCENTAGE ? (apartment?.pricePerArea! / 100) * valuesCopy.quantity : valuesCopy?.quantity),
                  pricePerAreaRepaired:
                    apartment?.pricePerAreaRepaired! +
                    (values.increase ? 1 : -1) *
                      (values?.type === PriceChangeType.PERCENTAGE
                        ? (apartment?.pricePerAreaRepaired! / 100) * valuesCopy.quantity
                        : valuesCopy?.quantity)
                };

                return {
                  ...apartment,
                  pricePerArea: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerArea)).number,
                  pricePerAreaRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerAreaRepaired)).number,
                  priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerArea * apartment?.area!)).number,
                  priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.pricePerAreaRepaired * apartment?.area!)).number
                };
              }
              const nObj = {
                priceNotRepaired:
                  apartment?.priceNotRepaired! +
                  (values.increase ? 1 : -1) *
                    (values?.type === PriceChangeType.PERCENTAGE ? (apartment?.priceNotRepaired! / 100) * valuesCopy.quantity : valuesCopy?.quantity),
                priceRepaired:
                  apartment?.priceRepaired! +
                  (values.increase ? 1 : -1) *
                    (values?.type === PriceChangeType.PERCENTAGE ? (apartment?.priceRepaired! / 100) * valuesCopy.quantity : valuesCopy?.quantity)
              };

              return {
                ...apartment,
                ...nObj,
                pricePerArea: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceNotRepaired / apartment?.area!)).number,
                pricePerAreaRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceRepaired / apartment?.area!)).number,
                priceNotRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceNotRepaired)).number,
                priceRepaired: helper.deleteNotNumbersAndParseFloatNumber(String(nObj?.priceRepaired)).number
              };
            }
            return apartment;
          })
        )
      );

      // @ts-expect-error
      dispatch(setShaxmatka(shaxmatkaCopy));
      handleClose();
    }
  };

  return (
    <Modal
      title={t('home.Narxni_ozgartirish')}
      visible={!!incrementPrice}
      onCancel={handleClose}
      footer={false}
      width={462}
      zIndex={1100}
      wrapClassName={styles.modal}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label={t('home.Ozgartirish_turi')} name="increase" rules={[{ required: true, message: '' }]} initialValue={true}>
          <Select className="customSelect" dropdownStyle={{ zIndex: 1100 }}>
            <Select.Option value={true}>Oshirish</Select.Option>
            <Select.Option value={false}>Tushurish</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('home.Ozgartirish_usuli')} name="type" rules={[{ required: true, message: '' }]}>
          <Select className="customSelect" dropdownStyle={{ zIndex: 1100 }}>
            <Select.Option value={PriceChangeType.PERCENTAGE}>{t('home.Foiz')}</Select.Option>
            <Select.Option value={PriceChangeType.PRICE}>{t('showroom.Narx')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('home.Miqdori')}
          name="quantity"
          rules={[
            { required: true, message: '' },
            {
              validator(rule, value) {
                const getType = form.getFieldValue('type');

                if (getType === PriceChangeType.PERCENTAGE && helper.deleteNotNumbersToNumber(value).number > 100) {
                  return Promise.reject();
                }
                if (getType === PriceChangeType.PRICE && helper.deleteNotNumbersToNumber(value).number > 10 ** 12) {
                  return Promise.reject();
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            className="customInput"
            onChange={e => {
              const getType = form.getFieldValue('type');

              form.setFieldsValue({
                quantity:
                  getType === PriceChangeType.PERCENTAGE
                    ? String(helper.deleteNotNumbersToNumber(e.target.value).string)
                    : String(helper.deleteNotNumbersAndParseFloatNumber(e.target.value).string)
              });
            }}
          />
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <Button onClick={handleClose}>{t('home.Bekor_qilish')}</Button>
        <Button onClick={() => form.submit()} type="primary" loading={changePrice.isLoading}>
          {t('home.Saqlash')}
        </Button>
      </div>
    </Modal>
  );
};

export default IncrementPrice;
