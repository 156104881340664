import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessDenied from '../../components/accessDenied/AccessDenied';
import { Layout } from 'antd';
import { IntegrationRoutes } from './integrationRoutes';
import MainHeader from '../../components/headers/MainHeader';
import BreadcrumbHeader from '../../components/headers/components/BreadcrumbHeader';
import NotificationButtons from '../../components/headers/components/NotificationButtons';
import OnlinePbxNotifications from '../../components/headers/components/OnlinePbxNotifications';

const { Content } = Layout;

const IntegrationRouter = () => {
  return (
    <>
      <MainHeader>
        <BreadcrumbHeader />
        <div className={'d-flex align-center'}>
          <OnlinePbxNotifications />
          <NotificationButtons />
        </div>
      </MainHeader>
      <Content
        style={{
          padding: '1rem 2.68rem',
          overflowY: 'auto',
          height: '50vh'
        }}
      >
        <Content>
          <Routes>
            {IntegrationRoutes.map((route, index) => (
              <Route key={index} path={route.exact ? route.path : `${route.path}/*`} element={<route.component />} />
            ))}
            <Route path={'*'} element={<AccessDenied />} />
          </Routes>
        </Content>
      </Content>
    </>
  );
};

export default IntegrationRouter;
