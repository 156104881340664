import React, { useState } from 'react';
import styles from './chatEmployee.module.scss';
import { OneEvent } from '../../../../../../utils/models/LeadEventsAndTaskList';
import { Avatar, Image } from 'antd';
import avatar from '../../../../../../assets/img/staff_image.png';
import { BASE_URL } from '../../../../../../service/RequestService';
import CheckIconEmployeeChatCrm from '../../../../../../assets/icons/CheckIconEmployeeChatCrm';
import TwoCheckIconEmployeeChatCrm from '../../../../../../assets/icons/TwoCheckIconEmployeeChatCrm';
import { FileObject } from '../../../../../../utils/models/File';
import FileIconCrm from '../../../../../../assets/FileIconCrm';
import { downloadFileByUrl } from '../../../../../../utils/helper/downloadFile';
import i18n from '../../../../../../service/I18nService';
import CustomPlayer from '../../../../../../components/customPlayer/CustomPlayer';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../hooks/reduxHooks';
import { handleOpen } from '../../../../../../store/reducers/GoogleDocVieweSlice';
import { urlify } from '../../../../../../utils/helper/urlify';

interface Props {
  chat: OneEvent;
}

const ChatEmployee: React.FC<Props> = ({ chat }) => {
  const [showPlayer, setShowPlayer] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // handle open googleViewer
  const handleViewer = (isOpen: boolean, url: string, name: string, format: string) => {
    dispatch(
      handleOpen({
        isOpen,
        url: url,
        format: format,
        name: name
      })
    );
  };

  // handle show player
  const handleShowPlayer = () => {
    setShowPlayer(true);
  };

  //download file
  const downloadFile = (url: string, name: string, type: string) => {
    downloadFileByUrl(url, name, type);
  };

  // show file
  const showFile = (file: FileObject) => {
    switch (file.format) {
      case 'xlsx':
      case 'xls':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill={'#00A389'} />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );
      case 'pdf':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill={'#FF3F40'} />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );
      case 'docx':
      case 'pptx':
      case 'doc':
        return (
          <div onClick={() => handleViewer(true, file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill={'#3772FF'} />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );
      case 'ogg':
      case 'mp3':
      case 'wav':
      case 'm4a':
      case 'ac3':
      case 'amr':
      case 'opus':
      case 'oga':
      case 'wma':
        return <CustomPlayer type={file.format} name={file.originalName} url={file.absolutePath} />;
      case 'png':
      case 'svg':
      case 'jpg':
      case 'jpeg':
        return (
          <Image
            preview={{
              src: BASE_URL + '/' + file.absolutePath,
              mask: t('crm_ins.Korish')
            }}
            src={BASE_URL + '/' + file.absolutePath}
            className={styles.container_image}
          />
        );
      case 'mp4':
      case 'gif':
        if (showPlayer)
          return (
            <video className={styles.container_video} controls>
              <source src={BASE_URL + '/' + file.absolutePath} type={`video/${file.format}`} />
            </video>
          );
        return (
          <div onClick={handleShowPlayer} className={styles.container_file}>
            <FileIconCrm fill={'#3772FF'} />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );
      default:
        return (
          <div onClick={() => downloadFile(file.absolutePath, file.originalName, file.format)} className={styles.container_file}>
            <FileIconCrm fill={'#3772FF'} />
            <span className={styles.container_file_name}>{file.originalName}</span>
          </div>
        );
    }
  };

  // translateCompleted
  const translateTo = () => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            {chat.createdDate} {chat.from?.name} к{chat.to?.name ? chat.to.name : 'все'}
          </>
        );
      case 'en':
        return (
          <>
            {chat.createdDate} {chat.from?.name} to {chat.to?.name ? chat.to.name : 'all'}
          </>
        );
      default:
        return (
          <>
            {chat.createdDate} {chat.from?.name} {chat.to?.name ? chat.to.name : 'barcha'}ga
          </>
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_avatar_cont}>
        <Avatar className={styles.container_avatar_cont_avatar} src={chat.from?.avatar ? BASE_URL + '/' + chat.from.avatar : avatar} />
      </div>
      <div className={styles.container_main}>
        <p className={styles.container_main_header}>
          {translateTo()}
          <span>{chat.wasRead ? <TwoCheckIconEmployeeChatCrm /> : <CheckIconEmployeeChatCrm />}</span>
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: urlify(chat.text)
          }}
          className={styles.container_main_text}
        />
        {chat?.files && typeof chat.files === 'object' && showFile(chat.files as FileObject)}
      </div>
    </div>
  );
};

export default ChatEmployee;
