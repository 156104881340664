import { Rule } from 'antd/lib/form';
import { isValidNumber } from 'libphonenumber-js';

export const phoneValidator = (): Rule => ({
  validator: (_, value) => {
    if (!(value && isValidNumber(value))) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('');
    }
    return Promise.resolve();
  }
});
