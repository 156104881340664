import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '@hooks/reduxHooks';
import { useQueryParam } from '@hooks/useQueryParams';
import TrushCrmIcon from '@icons/crm/TrushCrmIcon';
import DotsIcon from '@icons/DotsIcon';
import EditIcon from '@icons/EditIcon';
import InfoIcon from '@icons/InfoIcon';
import NavigateIcon from '@icons/NavigateIcon';
import SettingIcon from '@icons/SettingIcon';
import { SearchParamsCrm } from '@pages/crm/components/filter/FilterButton';
import { useGetWidget } from '@pages/dashboard/services/queries';
import { WidgetModel } from '@pages/dashboard/utils/models/WidgetModel';
import { MainPaths } from '@routing/main/mainPaths';
import { rootPaths } from '@routing/rootPaths';
import { SettingsPaths } from '@routing/settings/settingsPaths';
import { getCrmBody } from '@utils/helper/getSearchAndDownloadBodyAllTables';
import { Dropdown, Tooltip } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { UseInfiniteQueryResult } from 'react-query';

import ChartWidget from '../chart-widget/ChartWidget';
import DeleteConfirm from '../confirm-modal/DeleteConfirm';
import DashboardModal from '../dashboard-modal-2/DashboardModal';
import NumberWidget from '../number-widget/NumberWidget';
import PieChartWidget from '../pie-chart-widget/PieChartWidget';
import TableWidget from '../table-widget/TableWidget';
import TimeLineWidget from '../timeline-widget/TimeLineWidget';
import WidgetUpdate from '../widget-update/WidgetUpdate';

import styles from './widgetChildren.module.scss';

interface Props {
  item: WidgetModel;
}

const WidgetChildren: React.FC<Props> = ({ item }) => {
  const [enabled, setEnabled] = useState(true);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDashboard, setOpenDashboard] = useState(false);
  const [scrolling, setScrolling] = useState(true);
  const { t } = useTranslation();
  const { updateWidget } = useAppSelector(state => state.assigmentSlice);
  const { searchParams } = useQueryParam<SearchParamsCrm, any>();


  const objectData = (item?.objectData[1] === "'" || item?.objectData[2] === "'") ? item?.objectData?.replaceAll(/'/g, '"') : item?.objectData;
  const parseObjData = objectData?.includes('{') && objectData?.includes('}') ? JSON.parse(objectData) : {};
  // const parseObjData = JSON.parse(item?.objectData);

  const parseObjData1 = Array.isArray(parseObjData?.responsibleByIds)
    ? parseObjData
    : {
      ...parseObjData,
      responsibleByIds: [parseObjData?.responsibleByIds]
    };

  const handleOpenDashboard = () => setOpenDashboard(true);
  const navigate = useNavigate();
  const test =
    // eslint-disable-next-line no-nested-ternary
    item?.objectType === 'LEAD'
      ? {
        objectType: item?.objectType,
        groupBy: item?.groupBy,
        filterLead: item?.objectData && {
          ...getCrmBody(parseObjData)
        },
        customFieldId: item?.customFieldId,
        dashboardId: Number(searchParams?.dashboardId)
      }
      : item?.objectType === 'TASK'
        ? {
          objectType: item?.objectType,
          groupBy: item?.groupBy,
          filterTask: item?.objectData && {
            ...getCrmBody(parseObjData)
          },
          customFieldId: item?.customFieldId,
          dashboardId: Number(searchParams?.dashboardId)
        }
        : {
          objectType: item?.objectType,
          groupBy: item?.groupBy,
          [item?.objectType === 'LOG' ? 'filterLog' : 'filterLogClient']: item?.objectData && {
            ...getCrmBody(parseObjData1),
            tab: undefined
          },
          customFieldId: item?.customFieldId,
          dashboardId: Number(searchParams?.dashboardId)
        };

  const dataGetWidget = useGetWidget(
    (item?.type === 'TIMELINE' || item?.type === 'NUMBER') && searchParams?.startDate && searchParams?.finishDate
      ? queryString.stringifyUrl({ url: item?.url, query: { startDate: searchParams?.startDate, finishDate: searchParams?.finishDate } })
      : item?.url,
    item?.type === 'CHART' || item?.type === 'PIE_CHART' ? test : item?.objectData && getCrmBody(parseObjData),
    item?.requestType,
    item?.type,
    enabled
  );

  const { data, isLoading, fetchNextPage } = dataGetWidget as UseInfiniteQueryResult;

  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const newObject = () => {
    if (Object.keys(parseObjData)?.includes('page')) {
      delete parseObjData.page;
      return parseObjData;
    }
    if (Object.keys(parseObjData)?.includes('size')) {
      delete parseObjData.size;
      return parseObjData;
    }
    return parseObjData;
  };

  // const objectData2 = item?.objectData?.includes('{') && item?.objectData?.includes('}') && JSON.parse(item?.objectData.replaceAll(/'/g, '"'));

  let objectData2;

  try {
    objectData2 = item?.objectData?.includes('{') && item?.objectData?.includes('}')
      ? JSON.parse(item?.objectData.replaceAll(/'/g, '"'))
      : {};
  } catch (error) {
    console.error("Ошибка разбора JSON:", error);
    objectData2 = {}; // Устанавливаем значение по умолчанию в случае ошибки
  }

  const urlParams = Object.entries(objectData2)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => `${key}=${item}`).join('&');
      }
      return `${key}=${value}`;
    })
    .join('&');

  const handleNavigateFilter = () => {
    if (item?.objectType === 'LEAD') {
      navigate(`${rootPaths.MAIN + MainPaths.CRM}?${urlParams}`);
    } else if (item?.objectType === 'TASK') {
      navigate(`${rootPaths.MAIN + MainPaths.TASK}?${urlParams}`);
    } else {
      navigate(`${rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.EVET_LIST}?${urlParams}`);
    }
  };

  const scrollColumn = (e: any) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight && e.target.scrollHeight > e.target.offsetHeight) {
      setScrolling(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      scrolling &&
        fetchNextPage()?.then(() => {
          setEnabled(true);
          setScrolling(true);
        });
    }
  };

  return (
    <>
      <div className={styles.widget}>
        <div className={styles.widgetTop} style={{ marginBottom: item?.type === 'NUMBER' ? 30 : '' }}>
          <div className={styles.widgetTop_left}>
            {newObject()?.startDate && newObject()?.finishDate && (
              <Tooltip
                overlay={
                  <div className={styles.widgetTop_left_tooltip}>
                    <div className={styles.widgetTop_left_tooltip_item}>{`${newObject()?.startDate} - ${newObject()?.finishDate}`}</div>
                  </div>
                }
                color="#fff"
              >
                <InfoIcon />
              </Tooltip>
            )}
            <span>{item?.name}</span>
          </div>

          {updateWidget && (
            <div className={styles.widgetTop_actions} onMouseDown={e => e.stopPropagation()}>
              {item?.type === 'LIST' && (
                <div className={styles.widgetTop_actions_batafsil} onClick={handleNavigateFilter}>
                  <p>{t('showroom.Batafsil')}</p>
                  <NavigateIcon />
                </div>
              )}
              <SettingIcon onClick={handleOpenDashboard} />
              <Dropdown
                overlay={
                  <div className={styles.widgetDropdown}>
                    <div className={styles.widgetDropdown_item} onClick={handleOpenUpdateModal}>
                      <EditIcon />
                      <span>{t('emp_dashboard.Nomini_tahrirlash')}</span>
                    </div>
                    <div className={styles.widgetDropdown_item} onClick={handleOpenDeleteModal}>
                      <TrushCrmIcon />
                      <span>{t('emp_dashboard.Vidjetni_ochirish')}</span>
                    </div>
                  </div>
                }
                trigger={['click', 'hover']}
                mouseEnterDelay={1000}
                mouseLeaveDelay={0.3}
              >
                <DotsIcon />
              </Dropdown>
            </div>
          )}
        </div>

        {/* eslint-disable-next-line no-nested-ternary */}
        {item?.type === 'NUMBER' ? (
          <NumberWidget data={data as any} item={item} loading={isLoading} />
        ) : // eslint-disable-next-line no-nested-ternary
          item?.type === 'LIST' ? (
            <TableWidget item={item} data={data as any} loading={isLoading} scrollColumn={scrollColumn} />
          ) : // eslint-disable-next-line no-nested-ternary
            item?.type === 'CHART' ? (
              <ChartWidget data={data as any} item={item} loading={isLoading} />
            ) : // eslint-disable-next-line no-nested-ternary
              item?.type === 'PIE_CHART' ? (
                <PieChartWidget data={data as any} item={item} loading={isLoading} />
              ) : item?.type === 'TIMELINE' ? (
                <TimeLineWidget data={data as any} loading={isLoading} />
              ) : (
                ''
              )}
      </div>
      <WidgetUpdate visible={openUpdateModal} setVisible={setOpenUpdateModal} name={item?.name} id={item?.id} />
      <DeleteConfirm visible={openDeleteModal} setVisible={setOpenDeleteModal} item={item} />
      <DashboardModal item={item} openDashboard={openDashboard} setOpenDashboard={setOpenDashboard} />
    </>
  );
};

export default WidgetChildren;
