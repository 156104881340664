import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CompaniesState {
  editAndCreateModalOpen: boolean;
  id: number;
}

const initialState: CompaniesState = {
  editAndCreateModalOpen: false,
  id: 0
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    editAndCreateModal: (state, action: PayloadAction<{ open: boolean; id: number }>) => {
      state.editAndCreateModalOpen = action.payload.open;
      state.id = action.payload.id;
    }
  }
});

export const { editAndCreateModal } = companiesSlice.actions;
export default companiesSlice.reducer;
