import { TableConfig } from '../models/TableConfig';
import { TableKey } from '../models/TableKey';

// @ts-expect-error
export const tableExamples: {
  [key in TableKey]: TableConfig[];
} = {
  [TableKey.home]: [
    { id: 1, name: 'TJM_nomi', checked: true },
    { id: 2, name: 'Blok', checked: true },
    { id: 3, name: 'Xonadon_raqami', checked: true },
    { id: 4, name: 'Qavat', checked: true },
    { id: 5, name: 'Xonalar_soni', checked: true },
    { id: 6, name: 'Maydoni', checked: true },
    { id: 7, name: 'Terassa', checked: false },
    {
      id: 8,
      name: '1_m_kv_uchun_narxlar',
      checked: true,
      values: [
        { name: 'Tamirsiz', checked: true },
        { name: 'Tamirli', checked: true }
      ]
    },
    {
      id: 9,
      name: 'Umumiy_narx',
      checked: true,
      values: [
        { name: 'Tamirsiz', checked: true },
        { name: 'Tamirli', checked: true }
      ]
    },
    { id: 10, name: 'Tamirli_holati', checked: true },
    { id: 11, name: 'Xonadon_sinfi', checked: true },
    { id: 12, name: 'Holati', checked: true }
  ],
  [TableKey.order]: [
    { id: 1, name: 'Mijoz', checked: true },
    { id: 2, name: 'Sana', checked: true },
    { id: 3, name: 'TJM_nomi', checked: true },
    { id: 4, name: 'Uy_malumotlari', checked: true },
    { id: 5, name: 'Qolgan_muddati', checked: true },
    { id: 6, name: 'Status', checked: true },
    { id: 7, name: 'Masul', checked: true }
  ],
  [TableKey.contract]: [
    { id: 1, name: '№', checked: true },
    { id: 2, name: 'Mijoz', checked: true },
    { id: 3, name: 'Sana', checked: true },
    {
      id: 4,
      name: '1_m_kv_narxi',
      checked: false
    },
    { id: 5, name: 'Tolov_summasi', checked: true },
    { id: 6, name: 'Uy_malumotlari', checked: true },
    { id: 7, name: 'Rasmiyligi', checked: true },
    { id: 8, name: 'Chegirma', checked: true },
    { id: 9, name: 'Status', checked: true },
    { id: 10, name: 'Masul', checked: true },
    { id: 11, name: 'TJM_nomi', checked: true }
  ],
  [TableKey.payment]: [
    { id: '1', name: '№', checked: true },
    { id: '2', name: 'Asos', checked: true },
    { id: '3', name: 'Mijoz', checked: true },
    { id: '4', name: 'Shartnoma', checked: true },
    { id: '5', name: 'Sana', checked: true },
    { id: '6', name: 'Summa', checked: true },
    { id: '7', name: 'Tulov_turi', checked: true },
    { id: '8', name: 'Masul', checked: true },
    { id: '9', name: 'TJM_nomi', checked: true }
  ],
  [TableKey.arrearage]: [
    { id: 1, name: 'Shartnoma', checked: true },
    { id: 2, name: 'Mijoz', checked: true },
    { id: 3, name: 'Telefon_raqam', checked: true },
    { id: 4, name: 'Qarz_summasi', checked: true },
    { id: 5, name: 'Kechikish', checked: true },
    { id: 6, name: 'Sana', checked: true },
    { id: 7, name: 'Peniya', checked: true },
    { id: 8, name: 'Masul', checked: true },
    { id: 9, name: 'Maksimal_kechikish_muddati', checked: true }
  ],
  [TableKey.client]: [
    { id: 1, name: '№', checked: true },
    { id: 2, name: 'Mijoz', checked: true },
    { id: 3, name: 'Telefon_raqam', checked: true },
    { id: 4, name: 'Yashash_joyi', checked: true },
    { id: 5, name: 'Mijoz_balansi', checked: true },
    { id: 6, name: 'Status', checked: true },
    { id: 7, name: 'Masul', checked: true },
    { id: 8, name: 'Sana', checked: true }
  ],
  [TableKey.message]: [
    { id: 1, name: '№', checked: true },
    { id: 2, name: 'Mijoz', checked: true },
    { id: 3, name: 'Telefon_raqam', checked: true },
    { id: 4, name: 'Xabar_matni', checked: true },
    { id: 5, name: 'Yuborilgan_sana', checked: true },
    { id: 6, name: 'Yubordi', checked: true },
    { id: 7, name: 'Status', checked: true }
  ],
  [TableKey.employee]: [
    { id: 1, name: 'Xodim', checked: true },
    { id: 2, name: 'Lavozim', checked: true },
    { id: 3, name: 'Filial', checked: true },
    { id: 4, name: 'Telefon_raqam', checked: true },
    { id: 5, name: 'Amal_qilish_muddati', checked: true },
    { id: 6, name: 'Status', checked: true }
  ],
  [TableKey.builder]: [
    { id: 1, name: 'Korxona_nomi', checked: true },
    { id: 2, name: 'Manzil', checked: true },
    { id: 3, name: 'Korxona_rahbari', checked: true },
    { id: 4, name: 'Telefon_raqam', checked: true }
  ],
  [TableKey.branch]: [
    { id: 1, name: 'Filial_nomi', checked: true },
    { id: 2, name: 'Manzil', checked: true },
    { id: 3, name: 'Masul', checked: true },
    { id: 4, name: 'Yaratilgan_sana', checked: true }
  ],
  [TableKey.broker]: [
    { id: 1, name: '№', checked: true },
    { id: 2, name: 'Vositachi', checked: true },
    { id: 3, name: 'Manzili', checked: true },
    { id: 4, name: 'Balans', checked: true },
    { id: 4, name: 'Masul', checked: true }
  ],
  [TableKey.crm]: [
    { id: 1, name: 'Lid_nomi', checked: true },
    { id: 2, name: 'Asosiy_aloqa', checked: true },
    { id: 3, name: 'Yaratilgan_sana', checked: true },
    { id: 4, name: 'Masul', checked: true },
    { id: 4, name: 'Status', checked: true }
  ],
  [TableKey.monetary]: [
    { id: 1, name: 'Sana', checked: true },
    { id: 2, name: 'Qabul_qiluvchi_INN', checked: true },
    { id: 3, name: 'Qabul_qiluvchi_hisob', checked: true },
    { id: 4, name: 'Summa', checked: true },
    { id: 5, name: 'Izoh', checked: true },
    { id: 6, name: 'Tolov_shartnomasi', checked: true },
    { id: 7, name: 'Uysot_shartnomasi', checked: true },
    { id: 8, name: 'Hisob_raqam', checked: true },
    { id: 9, name: 'Yuboruvchi', checked: true }
  ],
  [TableKey.activity_logs]: [
    { id: 1, name: 'Sana', checked: true },
    { id: 2, name: 'Xodim', checked: true },
    { id: 3, name: 'obyekt', checked: true },
    { id: 4, name: 'Nomi', checked: true },
    { id: 5, name: 'event', checked: true },
    { id: 6, name: 'Korsatkichdan_oldin', checked: true },
    { id: 7, name: 'Korsatkichdan_keyin', checked: true }
  ],
  [TableKey.showroom_list]: [
    { id: 1, name: 'TJM_nomi', checked: true },
    { id: 2, name: 'Blok', checked: true },
    { id: 3, name: 'Xonadon_raqami', checked: true },
    { id: 4, name: 'Qavat', checked: true },
    { id: 5, name: 'Xonalar_soni', checked: true },
    { id: 6, name: 'Maydoni', checked: true },
    // { id: 7, name: 'Terassa', checked: false },
    {
      id: 8,
      name: '1_m_kv_uchun_narxlar',
      checked: true,
      values: [
        { name: 'Tamirsiz', checked: true },
        { name: 'Tamirli', checked: true }
      ]
    },
    {
      id: 9,
      name: 'Umumiy_narx',
      checked: true,
      values: [
        { name: 'Tamirsiz', checked: true },
        { name: 'Tamirli', checked: true }
      ]
    },
    { id: 10, name: 'Tamirli_holati', checked: true },
    { id: 11, name: 'Holati', checked: true }
  ],
  [TableKey.robot_call]: [
    { id: 1, name: '№', checked: true },
    { id: 2, name: 'Call_name', checked: true },
    { id: 3, name: 'Call_statistics', checked: true },
    { id: 4, name: 'Created_date', checked: true },
    { id: 5, name: 'Start_date', checked: true },
    { id: 6, name: 'Finish_date', checked: true },
    { id: 7, name: 'Call_back', checked: true },
    { id: 8, name: 'Employee', checked: true },
    { id: 9, name: 'Holati', checked: true }
  ]
};
