import React from 'react';

const ThreeDots: React.FC = () => {
  return (
    <svg width="40" height="9" viewBox="0 0 40 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.99998 8.5C6.20912 8.5 8 6.70916 8 4.50002C8 2.29088 6.20912 0.5 3.99998 0.5C1.79084 0.5 0 2.29088 0 4.50002C0 6.70916 1.79084 8.5 3.99998 8.5Z"
        fill="#B7B7B7"
      />
      <path
        d="M20 8.5C22.2091 8.5 24 6.70916 24 4.50002C24 2.29088 22.2091 0.5 20 0.5C17.7908 0.5 16 2.29088 16 4.50002C16 6.70916 17.7908 8.5 20 8.5Z"
        fill="#B7B7B7"
      />
      <path
        d="M36 8.5C38.2091 8.5 40 6.70916 40 4.50002C40 2.29088 38.2091 0.5 36 0.5C33.7908 0.5 32 2.29088 32 4.50002C32 6.70916 33.7908 8.5 36 8.5Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};

export default ThreeDots;
