import React from 'react';

interface Props {
  onClick?: () => void;
  className?: string;
  size?: number;
  strokeWidth?: number;
}

const TrushSquareIcon: React.FC<Props> = ({ onClick, className, size = 28, strokeWidth = 1 }) => {
  return (
    <svg className={className} onClick={onClick} width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2128 8.89154C20.4392 8.51072 16.6311 8.32031 12.8402 8.32031C10.5899 8.32031 8.33961 8.44148 6.10663 8.66651L3.78711 8.89154"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M10.0361 7.75043L10.2785 6.26177C10.4516 5.18856 10.59 4.375 12.5114 4.375H15.4887C17.4101 4.375 17.5486 5.22318 17.7217 6.26177L17.9641 7.73313"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M21.7726 9.03125L21.0282 20.4558C20.907 22.2387 20.8032 23.6235 17.6355 23.6235H10.348C7.18029 23.6235 7.07642 22.2387 6.95525 20.4558L6.21094 9.03125"
        stroke="#FF3F40"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default TrushSquareIcon;
