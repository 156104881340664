import { useAppSelector } from '@hooks/reduxHooks';
import { $api } from '@service/RequestService';
import { Permissions } from '@utils/constants/permissions';
import { useQuery } from 'react-query';

import { queryKeys } from '../constants';
import { ITermDiscount } from '../schema';

export const useTermDiscountAvailable = () => {
  const permissions = useAppSelector(state => state.userReducer.user?.role?.permissions);

  const isMultiPayment = permissions?.some(p => p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT_EDIT || p === Permissions.PERMISSION_MULTI_PAYMENT_CONTRACT);

  return useQuery<ITermDiscount[]>({
    queryFn: async () => {
      const res = await $api.get('/v1/term-discount/available');

      return res.data.data;
    },
    queryKey: [queryKeys.TERM_DISCOUNT_AVAILABLE],
    enabled: !!isMultiPayment
  });
};
