import { useState } from 'react';
import { Table } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import ArrowLeftIcon from '../../../../assets/icons/contract/ArrowLeftIcon';
import ArrowRightIcon from '../../../../assets/icons/contract/ArrowRightIcon';
import { useQueryParam } from '../../../../hooks/useQueryParams';
import { useContractMonthlyPayment } from '../../../../queries/queries';
import { MonthlyPayment, MonthlyPaymentStatus } from '../../../../utils/models/CreateContract';

import styles from './contractIdPaymentHistory.module.scss';

const ContractIdPaymentGraphic = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { params, location } = useQueryParam();
  const query = queryString.parse(location.search);
  const { data, isLoading } = useContractMonthlyPayment(params.id || query?.contractId, MonthlyPaymentStatus.ACTIVE);

  const getActiveTable = () => {
    if (data && data[0] && data[0].monthlyPaymentPartDtos) {
      return data[0].monthlyPaymentPartDtos;
    }
    return [];
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'number',
      width: 100,
      render: (i: any, a: MonthlyPayment, b: any) => (
        <div className="flex items-center gap-[4px]">
          {b + 1 + 10 * (page - 1)} {a?.type === 'MORTGAGE' ? <div className="rounded-[8px] bg-[#F4EBFF] px-[4px] py-[4px] text-[12px] text-[#9E77ED]">ipoteka</div> : null}
        </div>
      )
    },
    {
      title: t('contract_id.Tolov_qilingan_sana'),
      dataIndex: 'date'
    },
    {
      title: t('home.Tolov_summasi_sum'),
      dataIndex: 'amount',
      render: (i: number) => <>{i.toLocaleString()}</>
    },
    {
      title: t('contract_id.Tolangan_summa_som'),
      dataIndex: 'payment',
      render: (i: number, a: any) => <>{i === 0 ? '---' : <span style={{ color: a.paid ? '#00A389' : '#FFBB54' }}>{i.toLocaleString()}</span>}</>
    }
  ];

  console.log(getActiveTable());
  return (
    <div className={styles.container}>
      <Table
        dataSource={getActiveTable()}
        columns={columns}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          className: styles.container_pagination,
          onChange(page, pageSize) {
            setPage(page);
          },
          prevIcon: (
            <div className={styles.container_pagination_prev}>
              <ArrowLeftIcon /> {t('integration.Oldingi')}
            </div>
          ),
          nextIcon: (
            <div className={styles.container_pagination_next}>
              {t('profile.Keyingi')} <ArrowRightIcon />
            </div>
          )
        }}
        className={styles.container_table}
      />
    </div>
  );
};

export default ContractIdPaymentGraphic;
