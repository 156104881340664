/* eslint-disable @typescript-eslint/no-implied-eval */
import { useEffect, useState } from 'react';
import { useUpdateSmsConfig } from '@queries/mutation';
import { MessageConfig } from '@utils/models/MessageConfig';
import { Button, Checkbox, Input, Modal, Select } from 'global'
import { t } from 'i18next';

import styles from '../../../pages/messageSettings/MessageSettings.module.scss';


type ModalProps = {
    message: MessageConfig;
    visible: boolean;
    handleCancel: () => void;
};

const DebtMessageModal: React.FC<ModalProps> = ({ message, visible, handleCancel }) => {
    const [interval, setInterval] = useState<number | undefined>();
    const [checked, setChecked] = useState<boolean | undefined>(false);
    const [dayOfMonth, setDayOfMonth] = useState<number | undefined>();

    const updateMessage = useUpdateSmsConfig(message.smsTypeDifComDto.type);

    useEffect(() => {
        if (message) {
            setDayOfMonth(message?.dayOfMonth);
            setChecked(message?.endOfEveryMonth);
            setInterval(message?.interval);
        }
    }, [])

    const handleSaveInterval = () => {
        updateMessage.mutateAsync({
            // @ts-expect-error
            dayOfMonth,
            interval: Number(interval),
            endOfEveryMonth: checked,
            active: message?.active,
            sendToAll: message?.sendToAll,
        })
            .then(() => {
                handleCancel();
            });
    };

    return (
        <Modal
            title={t('home.Qarzdorlik_xabarlari')}
            visible={visible}
            footer={
                <div className='flex items-center gap-[12px] p-[2px]'>
                    <Button
                        onClick={handleCancel}
                        className='rounded-[30px] px-[40px] !py-[15px] flex items-center justify-center ml-auto'
                    >
                        {t('home.Bekor_qilish')}
                    </Button>
                    <Button
                        disabled={!interval && !dayOfMonth && !checked}
                        onClick={handleSaveInterval}
                        loading={updateMessage.isLoading}
                        className='bg-[#00A389] text-[#FFFFFF] rounded-[30px] px-[40px] !py-[15px] flex items-center justify-center ml-auto'
                    >
                        {t('home.Saqlash')}
                    </Button>
                </div>
            }
            onCancel={handleCancel}
        >
            <div className=''>
                <p className='mb-[6px]'>{t('message_settings.SMS_yuborish_intervali_kun')}</p>
                <Input
                    type='number'
                    value={interval}
                    onChange={(e) => {
                        setDayOfMonth(undefined);
                        setChecked(false);
                        setInterval(Number(e));
                    }}
                    className={interval ? styles.interval_input : styles.interval_input_error}
                    maskOption={{ min: 1 }}
                />

                <p className='mt-[16px] mb-[6px]'>{t('home.SMS_yuborish_uchun_har_oyning_ixtiyoriy_kuni')}</p>
                <Select
                    value={dayOfMonth}
                    onChange={value => {
                        setInterval(undefined);
                        setChecked(false);
                        setDayOfMonth(value);
                    }}
                    placeholder={`.. ${t('triggers.kun')}`}
                >
                    {Array.from({ length: 28 }, (_, index) => (
                        <Select.Option key={index + 1} value={index + 1}>
                            {index + 1}
                        </Select.Option>
                    ))}
                </Select>

                <div className='flex items-center gap-[8px] mt-[16px]'>
                    <Checkbox
                        checked={checked}
                        onChange={(e) => {
                            setInterval(undefined);
                            setDayOfMonth(undefined);
                            setChecked(e.target.checked);
                        }}
                    />
                    <p className=''>{t('home.Har_oyning_oxiri')}</p>
                </div>
            </div>
        </Modal>
    )
}

export default DebtMessageModal
