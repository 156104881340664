import { useQuery } from 'react-query';
import axios from 'axios';
import { endPoints } from '../utils/constants/endPoint';
import { queryKeys } from '../utils/constants/queryKeys';
import { BASE_URL } from '../../../service/RequestService';
import { QrCode } from '../utils/models/QrCode';
import { useQueryParam } from '../../../hooks/useQueryParams';

export function useGetPaymentDelay() {
  const { params } = useQueryParam();
  return useQuery<QrCode>([queryKeys.CONTRACT_QR], async () => {
    const res = await axios.get(BASE_URL + endPoints.CONTRACT_QR + params.token);
    return res.data.data;
  });
}
