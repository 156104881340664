import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePerAreaAvgPrice } from '../../../queries/queries';
import styles from './perAreaAvgPrice.module.scss';

const PerAreaAvgPrice = () => {
  const [allData, setAllData] = useState(false);
  const { t } = useTranslation();
  const { data } = usePerAreaAvgPrice();
  return (
    <div className={styles.container}>
      <h1>{t('boss_arrearage.1m2_maydon_uchun')}</h1>
      {data?.slice(0, allData ? data?.length : 10)?.map(item => (
        <div className={styles.container_item} key={item?.houseId}>
          <h3>{item?.houseName} </h3>
          <p>
            {t('boss_arrearage.sotilgan_xonadonlar_uchun')}
            <span>
              {item?.fullPerAreaAvgPriceMap?.SOLD_AVG_PRICE?.avgPrice?.toLocaleString('ru')}
              {item?.currency?.ccy}
            </span>
          </p>
          <p>
            {t('boss_arrearage.sotuvdagi_xonadonlar_uchun')}
            <span>
              {item?.fullPerAreaAvgPriceMap?.SALE_AVG_PRICE?.avgPrice?.toLocaleString('ru')}
              {item?.currency?.ccy}
            </span>
          </p>
          <p>
            {t('boss_arrearage.ortacha')}
            <span>
              {item?.fullPerAreaAvgPriceMap?.TOTAL_AVG_PRICE?.avgPrice?.toLocaleString('ru')}
              {item?.currency?.ccy}
            </span>
          </p>
        </div>
      ))}
      {allData ? '' : data && data?.length > 10 ? <Button onClick={() => setAllData(true)}>{t('house_config.Barchasini_korish')}</Button> : ''}
    </div>
  );
};

export default PerAreaAvgPrice;
