import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@components/index';
import EmbedModal from '@components/integration/embed/EmbedModal';
import MyHomeModal from '@components/integration/my-home/MyHomeModal';
import OneCModal from '@components/integration/one-c/OneCModal';
import { cx } from '@utils/cx';
import { Button } from 'global';
import { useTranslation } from 'react-i18next';

import Crown from '../../assets/icons/Crown';
import TokenGenerationIcon from '../../assets/icons/TokenGenerationIcon';
import VuesaxArrowRight from '../../assets/icons/VuesaxArrowRight';
import Reports from '../../components/integration/reports/Reports';
import ShowroomWidget from '../../components/integration/showroom/ShowroomWidget';
import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { IntegrationPaths } from '../../routing/integration/integrationPaths';
import { MainPaths } from '../../routing/main/mainPaths';
import { rootPaths } from '../../routing/rootPaths';
import { SettingsPaths } from '../../routing/settings/settingsPaths';
import { Permissions } from '../../utils/constants/permissions';

import ApiModal from './api-modal/ApiModal';
import AutoSMSTriggerTypes from './features/auto-sms-trigger/AutoSMSTriggerTypes';
import EmbedWidgetActivate from './features/auto-sms-trigger/components/EmbedWidgetActivate';
import OneCTriggerActivate from './features/auto-sms-trigger/components/OneCTriggerActivate';
import BrojectConfig from './features/broject-config/BrojectConfig';
import BrojectConfigActivation from './features/broject-config/BrojectConfigActivation';

import onec from '../../assets/img/1c.png';
// import webSite from '../../assets/img/website.png'
import amoCrm from '../../assets/img/amoCrm.png';
import calCalling from '../../assets/img/callIcon.png';
import embed from '../../assets/img/embed.png';
import hisobot from '../../assets/img/hisobot.png';
import myHome from '../../assets/img/myHome.png';
import showroomImage from '../../assets/img/showroomImage.png';
import telegram from '../../assets/img/telegram.png';
import message from './assets/message.svg';

import styles from './integration.module.scss';

const Integration = () => {
  const { t } = useTranslation();
  const user = useAppSelector(state => state.userReducer.user);
  const isRealtor = user?.role?.permissions?.includes(Permissions.PERMISSION_REALTOR);

  const isCRM = user?.role?.permissions?.includes(Permissions.PERMISSION_SETTINGS_CRM);

  const is1C = user?.role?.permissions?.includes(Permissions.PERMISSION_ONEC);
  const isBroject = user?.role?.permissions?.includes(Permissions.PERMISSION_BROJECT);

  const { location } = useQueryParam();
  const [reports, setReports] = useState(false);
  const [showroom, setShowroom] = useState(false);
  const [visible, setVisible] = useState(false);
  const [smsTrigger, setSmsTrigger] = useState(false);
  const [contentMode, setContentMode] = useState('settings');
  const [openHome, setOpenHome] = useState(false);
  const [open1C, setOpen1C] = useState(false);
  const [openEmbed, setOpenEmbed] = useState(false);

  const handleSettingsClick = () => {
    setContentMode('settings');
  };

  const handleNotificationsClick = () => {
    setContentMode('notifications');
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleReports = () => {
    setReports(true);
  };

  const handleShowroom = () => {
    setShowroom(true);
  };

  const handleSmsTrigger = () => {
    setSmsTrigger(true);
  };

  const handleOpenMyHome = () => {
    setOpenHome(true);
  };

  const handleOpenOneC = () => {
    setOpen1C(true);
  };
  const handleOpenEmbed = () => {
    setOpenEmbed(true);
  };

  const integrationLinks = [
    {
      title: 'Telegram_BOT',
      image: telegram,
      info: 'Korporativ_botingizni_boglang_va_mijozlaringiz_bilan_bevosita_muloqot_qiling',
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTEGRATION + IntegrationPaths.TELEGRAM,
      isPremium: false,
      permission: true
    },
    {
      title: 'AmoCRM',
      image: amoCrm,
      info: 'AmoCRM_panelidagi_Uysot_vidjeti_orqali_sotuvni_yangi_bosqichga_olib_chiqing',
      path: rootPaths.MAIN + MainPaths.SETTING + SettingsPaths.INTEGRATION + IntegrationPaths.AMO_CRM,
      isPremium: true,
      permission: true
    },
    {
      title: 'Hisobotlar',
      image: hisobot,
      info: 'Biznes_hisobotlarni_avtomatlashtirilgan',
      function: handleReports,
      isPremium: false,
      permission: true
    },
    {
      title: 'showroom',
      image: showroomImage,
      info: 'Hamkorlaringiz_showroomiga_ulaning',
      function: handleShowroom,
      isPremium: false,
      permission: isRealtor!
    },
    {
      title: 'Avtomatik_sms_bildirishnomalar',
      image: message,
      info: 'Uchrashuv_vaqtini_mijozga_eslatib_turish_imkoniyati',
      function: handleSmsTrigger,
      permission: isCRM
    },
    {
      title: 'Mening_uyim',
      image: myHome,
      info: 'Onlayn_malumotlar_olishadi',
      function: handleOpenMyHome,
      isPremium: false,
      permission: true
    },
    {
      title: 'oneC_title',
      image: onec,
      info: 'oneC_description',
      function: handleOpenOneC,
      isPremium: false,
      permission: is1C,
      active: OneCTriggerActivate
    },
    {
      title: 'embed_widget',
      image: embed,
      info: 'embed_description',
      function: handleOpenEmbed,
      isPremium: false,
      permission: true,
      active: EmbedWidgetActivate
    },
    {
      title: 'broject',
      image: calCalling,
      info: 'broject_description',
      isPremium: false,
      permission: isBroject,
      wrapper: BrojectConfig,
      activation: BrojectConfigActivation
    }
  ];

  return (
    <div className={styles.cont}>
      <div className={styles.cont_header}>
        <div className={styles.cont_header_left}>
          <h1>{t('integration.Integratsiya')}</h1>
          <h2>{t('integration.Dasturning_yordamchi_dasturlarini_boshqarish_va_sozlash')}</h2>
        </div>
        <div className={styles.cont_header_right} onClick={handleOpenModal}>
          <TokenGenerationIcon />
          <span>API</span>
        </div>
      </div>
      <div className={styles.cont_content}>
        {integrationLinks.map(
          (item, index: React.Key | null | undefined) =>
            item?.permission && (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles.cont_content_item}>
                <div className={styles.cont_content_item_body}>
                  <div className={styles.cont_content_item_body_image}>
                    <img src={item.image} alt="telegram" />
                    {item.isPremium && (
                      <div className={styles.cont_content_item_body_image_button}>
                        <Crown />
                        <span>{t('integration.Premium')}</span>
                      </div>
                    )}
                    {item?.active && <item.active />}
                    {item?.activation ? (
                      <item.activation>
                        {({ active, loading, submit, isData }) =>
                          isData ? (
                            <Button
                              loading={loading}
                              onClick={submit}
                              className={cx(
                                'flex items-center gap-[4px] rounded-[53px] border-none  text-white  hover:text-white  focus:text-white',
                                active ? 'bg-[#28c76f] hover:bg-[#28c76f] focus:bg-[#28c76f]' : 'bg-[#ff4d4f] hover:bg-[#ff4d4f] focus:bg-[#ff4d4f]'
                              )}
                            >
                              <Icon name={active ? 'active' : 'deactive'} />
                              {active ? t('integration.Aktiv') : t('integration.Aktivlashtirish')}
                            </Button>
                          ) : (
                            <></>
                          )
                        }
                      </item.activation>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={styles.cont_content_item_body_title}>{t(`integration.${item.title}`)}</div>
                  <div className={styles.cont_content_item_body_info}>{t(`integration.${item.info}`)}</div>
                </div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {item?.path ? (
                  <Link to={item.path}>
                    <div className={styles.cont_content_item_footer}>
                      <span className={styles.cont_content_item_footer_title}>{t('integration.Sozlash')}</span>
                      <VuesaxArrowRight />
                    </div>
                  </Link>
                ) : item?.wrapper ? (
                  <item.wrapper>
                    <div>
                      <div className={styles.cont_content_item_footer}>
                        <span className={styles.cont_content_item_footer_title}>{t('integration.Sozlash')}</span>
                        <VuesaxArrowRight />
                      </div>
                    </div>
                  </item.wrapper>
                ) : (
                  <div onClick={item.function}>
                    <div className={styles.cont_content_item_footer}>
                      <span className={styles.cont_content_item_footer_title}>{t('integration.Sozlash')}</span>
                      <VuesaxArrowRight />
                    </div>
                  </div>
                )}
              </div>
            )
        )}
      </div>
      <Reports setVisible={setReports} visible={reports} />
      {isRealtor && <ShowroomWidget visible={showroom} setVisible={setShowroom} />}
      <ApiModal visible={visible} setVisible={setVisible} />

      <AutoSMSTriggerTypes
        smsTrigger={smsTrigger}
        setSmsTrigger={setSmsTrigger}
        contentMode={contentMode}
        handleSettingsClick={handleSettingsClick}
        handleNotificationsClick={handleNotificationsClick}
      />

      <MyHomeModal openHome={openHome} setOpenHome={setOpenHome} />
      <OneCModal open={open1C} setOpen={setOpen1C} />
      <EmbedModal open={openEmbed} setOpen={setOpenEmbed} />
    </div>
  );
};

export default Integration;
