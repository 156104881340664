import React from 'react';
import { Button, Checkbox, Form, Input, Modal, Spin } from 'antd';
import styles from './order.module.scss';
import { useTranslation } from 'react-i18next';
import { CancelOrderBody, CancelReasonOrder } from '../../../utils/models/CancelReasonOrder';
import { useCancelOrder } from '../../../queries/mutation';
import { getCurrentLanguageWord } from '../../../utils/helper/getCurrentLanguageWord';
import CancelBronGarbage from '../../../assets/icons/CancelBronGarbage';
import { useOrderCancelReason } from '../services/queries';

interface Props {
  visible: boolean;
  onCancel: () => void;
  id: string;
  companyId?: number;
}

const OrderCancelModal: React.FC<Props> = ({ visible, onCancel, id, companyId }) => {
  const { isLoading, data } = useOrderCancelReason(companyId);
  const [form] = Form.useForm<CancelOrderBody>();
  const { t } = useTranslation();
  const cancelOrder = useCancelOrder(id, handleClose);

  function handleClose() {
    form.resetFields();
    onCancel();
  }

  const handleSubmit = (e: CancelOrderBody) => {
    cancelOrder.mutate({ ...e, companyId: companyId });
  };

  const getReasonName = (reason: CancelReasonOrder) => {
    return getCurrentLanguageWord(reason);
  };

  return (
    <Modal className={styles.cancel_order_modal} centered closable={false} footer={null} visible={visible} onCancel={handleClose}>
      <Spin spinning={isLoading}>
        <Form form={form} name="basic" onFinish={handleSubmit} initialValues={{ remember: true }} autoComplete="off">
          <div className={`${styles.icon_cont} d-flex justify-center`}>
            <span className={`${styles.icon} d-flex align-center justify-center`}>
              <CancelBronGarbage />
            </span>
          </div>
          <h1 className={styles.title}>{t('order.Buyurtmani_bekor_qilmoqchimisiz')}</h1>
          <h2 className={styles.sub_title}>{t('order.Bekor_qilingan_buyurtma_ustida_hech_qanday_amal_bajarib_bolmaydi')}</h2>
          <h3 className={styles.cancel_reason_title}>{t('order.Bekor_qilish_sabablari')}</h3>
          <Form.Item
            rules={[
              {
                required: true,
                message: t('order.Bekor_qilish_sababi_tanlanmagan')
              }
            ]}
            name="reason"
          >
            <Checkbox.Group>
              {data?.map(reason => (
                <div key={reason.id} className={styles.reason_cont}>
                  <Checkbox value={reason.id} className={styles.reason_cont_check} />
                  <span className={styles.reason_cont_name}>{getReasonName(reason)}</span>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="note">
            <Input.TextArea rows={6} placeholder={t('order.Izoh_matnini_kiriting')} className={styles.text_area} />
          </Form.Item>
          <div className={'d-flex align-center'}>
            <Button type={'primary'} onClick={handleClose} className={styles.btn_cancel}>
              {t('home.Yopish')}
            </Button>
            <Button loading={cancelOrder.isLoading} htmlType={'submit'} danger type={'primary'} className={styles.btn_confirm}>
              {t('home.Bekor_qilish')}
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OrderCancelModal;
