import { useState } from 'react';
import { useQueryParam } from '@hooks/useQueryParams';
import { DATE_FORMAT } from '@utils/constants/format';
import { BoosComplexSelectType } from '@utils/models/BoosComplexSelectType';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

import { useGetCallHistoryCArd, useGetCallHistoryGrapg } from '../../services/queries';
import FilterCalls from '../filterCalls/FilterCalls';
import { CallStatisticsParam } from '../header/Header';
import LineChart from '../lineChart/LineChart';
import PieChart from '../pieChart/PieChart';

import { finishDateNotUtc, startDateNotUtc } from './startAndFinishDates';

import styles from './callStatistics.module.scss';

dayjs.extend(utc);

const GraphStatistic = () => {
  const { t } = useTranslation();

  const { searchParams, appendSingle } = useQueryParam<CallStatisticsParam, any>();

  const [currentPage, setCurrentPage] = useState(searchParams?.page ? Number(searchParams?.page) : 1);

  const distance = dayjs
    .unix(finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc])
    .diff(dayjs.unix(startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc]), 'day');
  const { data: card, isLoading: cardLoading } = useGetCallHistoryCArd(
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.startDate, DATE_FORMAT).startOf('day').unix()
      : startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc],
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.finishDate, DATE_FORMAT).endOf('day').unix()
      : finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc],
    searchParams?.pipeStatus,
    searchParams?.employee,
    searchParams.unique,
    searchParams?.statuses,
    searchParams?.durationStart,
    searchParams?.durationFinish,
    searchParams?.talkTimeStart,
    searchParams?.talkTimeFinish
  );
  const { data: graph, isLoading: graphLoading } = useGetCallHistoryGrapg(
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.startDate, DATE_FORMAT).startOf('day').unix()
      : startDateNotUtc[searchParams?.date as keyof typeof startDateNotUtc],
    searchParams?.date === BoosComplexSelectType.SPECIAL1
      ? dayjs(searchParams?.finishDate, DATE_FORMAT).endOf('day').unix()
      : finishDateNotUtc[searchParams?.date as keyof typeof finishDateNotUtc],
    searchParams?.pipeStatus,
    searchParams?.employee,
    searchParams?.statuses,
    Boolean(searchParams?.unique),
    searchParams?.durationStart,
    searchParams?.durationFinish,
    searchParams?.talkTimeStart,
    searchParams?.talkTimeFinish
  );
  const pipeData = [
    {
      id: t('online_pbx.Kiruvchi'),
      label: t('online_pbx.Kiruvchi'),
      value: graph?.inboundCount,
      color: '#8B62FF'
    },
    {
      id: t('online_pbx.Chiquvchi'),
      label: t('online_pbx.Chiquvchi'),
      value: graph?.outboundCount,
      color: '#0CAF61'
    }
  ];

  return (
    <div className={styles.container_elements}>
      <div className={styles.container_elements_header}>
        <h1 className={styles.title} style={{ opacity: 0 }}>
          {t('crm.Xodimlar_kesimida_qongiroqlar')}
        </h1>
      </div>
      <div className={styles.container_graph}>
        <div className={styles.container_graph_child}>
          <PieChart data={pipeData} graphLoading={graphLoading} />
          <div className={styles.total}>
            <p>{graph?.total}</p>
            <span>{t('home.Jami')}</span>
          </div>
          <div className={styles.callTotal}>
            <span>{t('crm.Qongiroqlar_soni')}</span>
            <div className={styles.callTotal_bottom}>
              <div className={styles.callTotal_bottom_child}>
                <div className={styles.callTotal_bottom_child_circle1} />
                <p>{t('online_pbx.Kiruvchi')}</p>
                <span>{graph?.inboundCount}</span>
              </div>
              <div className={styles.callTotal_bottom_child}>
                <div className={styles.callTotal_bottom_child_circle2} />
                <p>{t('online_pbx.Chiquvchi')}</p>
                <span>{graph?.outboundCount}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <LineChart graph={graph} graphLoading={graphLoading} distance={distance} />
        </div>
      </div>
      <Spin spinning={cardLoading}>
        <div className={styles.container_history}>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'ALL');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'ALL' ? '#3D2B9C' : 'transparent'
            }}
          >
            <span>{card?.total}</span>
            <p>{t('crm.Hammasi')}</p>
          </div>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'INBOUND');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'INBOUND' ? '#2FA03D' : 'transparent'
            }}
          >
            <span>{card?.inboundCount}</span>
            <p>{t('online_pbx.Kiruvchi')}</p>
          </div>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'OUTBOUND');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'OUTBOUND' ? '#DE4056' : 'transparent'
            }}
          >
            <span>{card?.outboundCount}</span>
            <p>{t('online_pbx.Chiquvchi')}</p>
          </div>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'LOCAL');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'LOCAL' ? '#DC5701' : 'transparent'
            }}
          >
            <span>{card?.localCount}</span>
            <p>{t('crm.Ichki')}</p>
          </div>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'MISSING_INBOUND');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'MISSING_INBOUND' ? '#4F283C' : 'transparent'
            }}
          >
            <span>{card?.missingInboundCount}</span>
            <p>{t('crm.Otkazib_yuborilgan')}</p>
          </div>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'NOT_CALL_BACK');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'NOT_CALL_BACK' ? '#378889' : 'transparent'
            }}
          >
            <span>{card?.notCallBackCount}</span>
            <p>{t('crm.Qongiroq_qilmadi')}</p>
          </div>
          <div
            className={styles.container_history_child}
            onClick={() => {
              appendSingle('callType', 'MISSING_OUTBOUND');
              setCurrentPage(1);
            }}
            style={{
              borderColor: searchParams?.callType === 'MISSING_OUTBOUND' ? '#2C3330' : 'transparent'
            }}
          >
            <span>{card?.missingOutboundCount}</span>
            <p>{t('crm.Boglana_olmadi')}</p>
          </div>
        </div>
      </Spin>
      <div className={styles.container_filter}>
        <FilterCalls callType={searchParams?.callType} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
};

export default GraphStatistic;
