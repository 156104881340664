import React, { useState } from 'react';
import { Modal, Segmented, Spin } from 'antd';
import styles from './botConfig.module.scss';
import FaqItem from './FaqItem';
import { useGetFaq } from '../../../queries/queries';
import AddFaqItem from './AddFaqItem';
import { LangBot } from '../../../utils/models/TelegramBot';

interface Props {
  visible: boolean;
  onClose: (open: boolean) => void;
}

const FaqModal: React.FC<Props> = ({ visible, onClose }) => {
  const [segmentValue, setSegmentValue] = useState<LangBot>(LangBot.UZ);
  const { isFetching, isLoading, data } = useGetFaq(segmentValue);

  const onCloseModal = () => {
    onClose(false);
  };

  const handleChangeSegment = (value: any) => {
    setSegmentValue(value);
  };

  return (
    <Modal title={'FAQ'} className={styles.faqModal} visible={visible} onCancel={onCloseModal} footer={false} width={948}>
      <Spin spinning={isLoading || isFetching}>
        <Segmented
          className={styles.faqModal_segment}
          size={'large'}
          options={[LangBot.UZ, LangBot.RU, LangBot.EN]}
          value={segmentValue}
          onChange={handleChangeSegment}
        />
        {data?.map((faq, index) => <FaqItem lang={segmentValue} faq={faq} key={index} />)}
        <AddFaqItem lang={segmentValue} />
      </Spin>
    </Modal>
  );
};

export default FaqModal;
