import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Input, Modal } from 'antd';
import styles from './createTask.module.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { LocalStorage } from '@service/LocalStorage';
import { USER_KEY } from '@utils/constants/localStorageKeys';
import { useCrmEmployee, useLeadTaskType } from '@queries/queries';
import { useCreateLeadNote, useCreateLeadTask } from '@queries/mutation';
import i18n from 'i18next';
import CheckmarkIcon from '@icons/crmIcons/CheckmarkIcon';
import ArrowDownCrmIcon from '@icons/ArrowDownCrmIcon';
import MDatePicker from '@components/mDatePicker/MDatePicker';
import { crmIcons } from '@icons/crmIcons/crmIcons';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import { createTaskVisible } from '@reducers/TaskCalendarViewSlice';
import FindLeadOrClient from './FindLeadOrClient';
import { DATE_FORMAT } from '@utils/constants/format';
import { TaskCalendarViewType } from '@utils/models/TaskCalendarView';
import { useQueryClient } from 'react-query';
import { queryNames } from '@utils/constants/queryNames';
import { useQueryParam } from '@hooks/useQueryParams';
import { SearchParamsTask } from '@utils/models/FilterTask';

const taskSelect = [
  {
    text: 'Topshiriq'
  },
  {
    text: 'Eslatma'
  }
];

const fromId = LocalStorage.get(USER_KEY) ? LocalStorage.get(USER_KEY).id : 0;

const CreateTask = () => {
  const { searchParams } = useQueryParam<SearchParamsTask, { id: string }>();

  const visible = useAppSelector(state => state.taskCalendarViewSlice.createTaskVisible);

  const qc = useQueryClient();

  const inputRef = useRef<TextAreaRef>(null);
  const containerFooterTopRef = useRef<any>(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const date = useAppSelector(state => state.taskCalendarViewSlice.date);
  const type = useAppSelector(state => state.taskCalendarViewSlice.type);

  const { data: leadTaskTypeAll } = useLeadTaskType();
  const { data: leadEmployeeList } = useCrmEmployee();

  const [text, setText] = useState('');
  const [mPickerVisible, setMPickerVisible] = useState(false);
  const [taskVisible, setTaskVisible] = useState(false);
  const [taskChangeText, setTaskChangeText] = useState('Topshiriq');
  const [taskMeetingSelectVisible, setTaskMeetingSelectVisible] = useState(false);
  const [taskMeetingSelectActiveId, setTaskMeetingSelectActiveId] = useState(leadTaskTypeAll ? leadTaskTypeAll![0]?.id : 0);
  const [activeEmployeeId, setActiveEmployeeId] = useState(fromId);
  const [employeeSelectVisible, setEmployeeSelectVisible] = useState(false);
  const [editNotificationId, setEditNotificationId] = useState(0);
  const [dateValue, setDateValue] = useState('Bugun');
  const [activeMessage, setActiveMessage] = useState(true);
  const [createDeadline, setCreateDeadline] = useState({
    start: dayjs().hour(0).minute(0).format('DD.MM.YYYY HH:mm'),
    end: dayjs().hour(23).minute(59).format('DD.MM.YYYY HH:mm')
  });
  const [selectedLeadId, setSelectedLeadId] = useState<number>(0);
  const [searchText, setSearchText] = useState('');
  const [leadDropDownOpen, setLeadDropDownOpen] = useState(false);

  const leadSave = useCreateLeadTask(selectedLeadId, handleSuccess);
  const noteSave = useCreateLeadNote(selectedLeadId, handleSuccess);

  useEffect(() => {
    const windowClick = () => {
      setActiveMessage(true);
      setEditNotificationId(0);
    };

    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  }, [containerFooterTopRef, editNotificationId, activeMessage, taskChangeText]);

  useEffect(() => {
    handleCancel();
    // eslint-disable-next-line
  }, [leadSave.isSuccess, noteSave.isSuccess]);

  useEffect(() => {
    onChangeMPicker(date, '00:00', '23:59');
  }, [date]);

  //handle success
  function handleSuccess() {
    let startDate = date;
    let endDate = date;
    if (type === TaskCalendarViewType.MONTH) {
      startDate = dayjs(date, DATE_FORMAT).startOf('month').format(DATE_FORMAT);
      endDate = dayjs(date, DATE_FORMAT).endOf('month').format(DATE_FORMAT);
    } else if (type === TaskCalendarViewType.WEEK) {
      startDate = dayjs(date, DATE_FORMAT).startOf('week').add(1, 'day').format(DATE_FORMAT);
      endDate = dayjs(date, DATE_FORMAT).endOf('week').add(1, 'day').format(DATE_FORMAT);
    }
    qc.invalidateQueries([queryNames.TASK_FILTER_CALENDAR, startDate, endDate, JSON.stringify(searchParams)]);
  }

  // handle lead dropdown open
  const handleLeadDropDownOpen = (visible: boolean) => {
    setLeadDropDownOpen(visible);
  };

  // handle search text
  const handleSearchText: ChangeEventHandler<HTMLInputElement> = e => {
    handleSelectId(0, '');
    setSearchText(e.target.value);
    handleLeadDropDownOpen(true);
  };

  // handle select id
  const handleSelectId = (id: number, name: string) => {
    setSelectedLeadId(id);
    setSearchText(name);
    handleLeadDropDownOpen(false);
  };

  // translateFor
  const translateFor = (name: string) => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>
            для <span>{name}</span>:
          </>
        );
      case 'en':
        return (
          <>
            for <span>{name}</span>:
          </>
        );
      default:
        return (
          <>
            <span>{name}</span> uchun:
          </>
        );
    }
  };

  const handleTaskSelect = () => {
    setTaskVisible(true);
  };

  const handleTaskSelectItem = (item: string) => {
    setTaskVisible(false);
    setTaskChangeText(item);
  };

  const handleTaskMeetingSelect = () => {
    setTaskMeetingSelectVisible(true);
  };
  const handleTaskMeetingSelectItem = (id: number) => {
    setTaskMeetingSelectVisible(false);
    setTaskMeetingSelectActiveId(id);
  };

  const handleEmployeeSelect = () => {
    setEmployeeSelectVisible(true);
  };
  const handleEmployee = (id: number) => {
    setEmployeeSelectVisible(false);
    setActiveEmployeeId(id);
  };

  const handleMPicker = () => {
    setMPickerVisible(true);
  };
  useEffect(() => {
    if (leadTaskTypeAll?.length) {
      setTaskMeetingSelectActiveId(leadTaskTypeAll![0]?.id);
    }
  }, [leadTaskTypeAll]);

  const changeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleLeadSave = () => {
    if (taskChangeText === 'Topshiriq') {
      leadSave.mutate({
        assignedById: activeEmployeeId,
        deadlineStart: createDeadline.start,
        deadlineFinish: createDeadline.end,
        leadId: selectedLeadId,
        leadTaskTypeId: taskMeetingSelectActiveId,
        text: text
      });
    } else if (taskChangeText === 'Eslatma') {
      noteSave.mutate({
        leadId: selectedLeadId,
        text,
        pin: false,
        fileIds: null
      });
    }
  };

  const onChangeMPicker = (date: string, start: string, end: string) => {
    setDateValue(date + ' ' + start + ' ' + end);
    setCreateDeadline({
      start: date + ' ' + start,
      end: date + ' ' + end
    });
  };

  const handleChatFooter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveMessage(false);
  };

  const keyDownText = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();
      handleLeadSave();
    } else if (e.key === 'Escape') {
      setActiveMessage(true);
      setText('');
      inputRef.current?.blur();
    }
  };

  // handle cancel
  const handleCancel = () => {
    setText('');
    handleSelectId(0, '');
    setSearchText('');
    dispatch(createTaskVisible(false));
  };

  return (
    <Modal className={styles.container} width={710} footer={false} visible={visible} title={t('one_task.Topshiriq_yaratish')} onCancel={handleCancel}>
      <div className={styles.container} onClick={e => handleChatFooter(e)}>
        <Dropdown
          visible={leadDropDownOpen}
          overlay={<FindLeadOrClient selectLead={handleSelectId} id={selectedLeadId} search={searchText} />}
          onVisibleChange={visible => handleLeadDropDownOpen(visible)}
          trigger={['click']}
          placement={'bottomCenter'}
        >
          <Input
            value={searchText}
            placeholder={t('calendar_view.Lead_biriktirish')}
            onChange={handleSearchText}
            className={'customInput ' + styles.container_search}
          />
        </Dropdown>
        <div className={styles.container_footer}>
          <div className={styles.container_content}>
            <div className={styles.container_footer_top} ref={containerFooterTopRef}>
              <Dropdown
                visible={taskVisible}
                onVisibleChange={visible => setTaskVisible(visible)}
                overlay={
                  <div className={styles.container_footer_top_taskSelect_content}>
                    {taskSelect.map((item, index) => (
                      <div key={index} onClick={() => handleTaskSelectItem(item.text)}>
                        {t(`one_lid.${item.text}`)}
                        {taskChangeText === item.text && (
                          <span>
                            <CheckmarkIcon />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                }
                trigger={['click']}
                placement="topLeft"
              >
                <div className={styles.container_footer_top_taskSelect} onClick={handleTaskSelect}>
                  {t(`one_lid.${taskChangeText}`)}
                  <span>
                    <ArrowDownCrmIcon strokeColor="#3772FF" />
                  </span>
                </div>
              </Dropdown>
              {taskChangeText === 'Topshiriq' && (
                <Dropdown
                  visible={mPickerVisible}
                  onVisibleChange={setMPickerVisible}
                  overlay={
                    <div>
                      <MDatePicker visibleTime={mPickerVisible} onChangeMPicker={onChangeMPicker} />
                    </div>
                  }
                  trigger={['click']}
                  placement={'topCenter'}
                >
                  <Input
                    className={styles.container_footer_top_taskInput}
                    onClick={handleMPicker}
                    value={dateValue}
                    style={{
                      width: dateValue?.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').length! * 13.2
                    }}
                  />
                </Dropdown>
              )}
              {taskChangeText === 'Topshiriq' && (
                <Dropdown
                  visible={employeeSelectVisible}
                  onVisibleChange={visible => setEmployeeSelectVisible(visible)}
                  overlay={
                    <div className={styles.container_footer_top_taskEmployee_content}>
                      {leadEmployeeList!?.map(item => (
                        <div key={item.id} onClick={() => handleEmployee(item.id)}>
                          {item.fullName}{' '}
                          {activeEmployeeId === item.id && (
                            <span>
                              <CheckmarkIcon />
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  }
                  trigger={['click']}
                  placement={'topLeft'}
                >
                  <div className={styles.container_footer_top_taskEmployee} onClick={handleEmployeeSelect}>
                    {translateFor(leadEmployeeList!?.find(item => item.id === activeEmployeeId)?.fullName || '')}
                  </div>
                </Dropdown>
              )}
              {taskChangeText === 'Topshiriq' && (
                <Dropdown
                  trigger={['click']}
                  visible={taskMeetingSelectVisible}
                  onVisibleChange={visible => setTaskMeetingSelectVisible(visible)}
                  overlay={
                    <div className={styles.container_footer_top_taskMeetingSelect_content}>
                      {leadTaskTypeAll?.map(item => (
                        <div key={item.id} onClick={() => handleTaskMeetingSelectItem(item.id)}>
                          {crmIcons[item.icon.split('#')[0]]?.icon('#' + item.icon.split('#')[1])} {item.name}
                          {taskMeetingSelectActiveId === item.id && (
                            <span>
                              <CheckmarkIcon />
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  }
                  placement="topLeft"
                >
                  <div className={styles.container_footer_top_taskMeetingSelect} onClick={handleTaskMeetingSelect}>
                    {crmIcons[leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[0]!]?.icon(
                      '#' + leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.icon?.split('#')[1]
                    )}
                    {leadTaskTypeAll?.find(item => item.id === taskMeetingSelectActiveId)?.name}
                  </div>
                </Dropdown>
              )}
            </div>
            <Input.TextArea
              className={styles.container_footer_textArea}
              placeholder={t('one_lid.Matn_kiriting')}
              autoSize={{ minRows: 1, maxRows: 5 }}
              onChange={e => changeText(e)}
              onKeyUp={e => keyDownText(e)}
              ref={inputRef}
              value={text}
            />
          </div>
        </div>
        <div className={styles.container_submit}>
          <div className={styles.container_submit_left}>
            <Button type="primary" onClick={handleLeadSave} loading={leadSave.isLoading || noteSave.isLoading} disabled={selectedLeadId === 0}>
              {t('one_lid.Yuborish')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTask;
