import { createSlice } from '@reduxjs/toolkit';
import { FlatId } from '../../utils/models/Flat';

interface ShaxmatkaState {
  data: FlatId[][][];
  maxEntrance: number;
  maxFloor: number;
  minFloor: number;
  maxXaxis: number;
  floors: number[];
}

const initialState: ShaxmatkaState = {
  data: [],
  maxEntrance: 0,
  maxFloor: 0,
  minFloor: 0,
  maxXaxis: 0,
  floors: []
};

export const shaxmatkaV3Slice = createSlice({
  name: 'shaxmatkaV3',
  initialState,
  reducers: {
    setShaxmatkaV3: (
      state,
      action: {
        payload: FlatId[][][];
      }
    ) => {
      state.data = action.payload;
    },
    setShaxmatkaV3Options: (
      state,
      action: {
        payload: {
          maxEntrance: number;
          maxFloor: number;
          minFloor: number;
          maxXaxis: number;
          floors: number[];
        };
      }
    ) => {
      state.maxEntrance = action.payload.maxEntrance;
      state.maxFloor = action.payload.maxFloor;
      state.minFloor = action.payload.minFloor;
      state.maxXaxis = action.payload.maxXaxis;
      state.floors = action.payload.floors;
    }
  }
});

export const { setShaxmatkaV3, setShaxmatkaV3Options } = shaxmatkaV3Slice.actions;
export default shaxmatkaV3Slice.reducer;
