import DateSelect from '@components/bossCrm/pipes/header/DateSelect';
import Employee from '@components/bossCrm/pipes/header/Employee';
import Pipe from '@components/bossCrm/pipes/header/Pipe';

// import Chart from '../../../../components/bossCrm/chart/Chart';
import Pipes from '../../../../components/bossCrm/pipes/Pipes';
// import PriceOneClient from '../../../../components/bossCrm/priceOneClient/priceOneClient/PriceOneClient';
// import Source from '../../../../components/bossCrm/source/Source';
import FailedLead from '../failed-lead/Index';
import SuccessfulLeads from '../successful-leads';

import styles from '../../bossCrm.module.scss';

const CommonStatistic = () => (
  <div className={styles.container}>
    <div className={styles.container_top}>
      <DateSelect />
      <Employee />
      <Pipe />
    </div>
    <Pipes />
    {/* <div className={styles.container_body}>
      <div className={styles.container_body_chart}>
        <Chart />
      </div>
      <div className={styles.container_body_source}>
        <Source />
      </div>
      <div className={styles.container_body_price}>
        <PriceOneClient />
      </div>
    </div> */}
    <div className="box-border grid w-full grid-cols-2 gap-4">
      <FailedLead />
      <SuccessfulLeads />
    </div>
  </div>
);

export default CommonStatistic;
