import { createContext } from 'react';
import { Pagination } from '@utils/models/Pagination';

import { crmModels } from 'modules/crm';

import { eventListConstants, eventListModels } from '..';

export type EventListTab = 'LEAD' | 'CLIENT' | (string & {});

interface IQuery {
  page?: number;
  size?: number;
  tab?: EventListTab;
  startDate?: string | null;
  finishDate?: string | null;
  responsibleByIds?: number[] | null;
  objectType?: string | null;
  actionTypes?: string[] | null;
  objectStartDate?: string | null;
  objectFinishDate?: string | null;
  previous?: string | null;
  current?: string | null;
  clientIds?: number[] | null;
  currentStatuses?: number[] | null;
  previousStatuses?: number[] | null;
  currentTags?: string[] | null;
  previousTags?: string[] | null;
}

export const clearQuery: IQuery = {
  page: undefined,
  size: undefined,
  tab: undefined,
  finishDate: undefined,
  startDate: undefined,
  responsibleByIds: undefined,
  objectType: undefined,
  actionTypes: undefined,
  current: undefined,
  objectFinishDate: undefined,
  objectStartDate: undefined,
  previous: undefined,
  clientIds: undefined,
  currentStatuses: undefined,
  previousStatuses: undefined,
  currentTags: undefined,
  previousTags: undefined,
};

export interface Methods {
  setQuery: (value: IQuery) => void;
  setQuerySingle: (value: IQuery) => void;
}

export interface IContext extends Methods {
  query?: IQuery;
  data?: Pagination<eventListModels.IActivityLog>;
  pipes?: eventListModels.IActivityPipe[];
  crmFields?: crmModels.ICrmField[];
  leadEmployeeAll?: crmModels.ILeadEmployee[];
  dataIsLoading?: boolean;
  tableConfig?: any;
  objectTypes?: ReturnType<typeof eventListConstants.objectTypes>[string];
  activityLogs?: eventListModels.IActivityLogTypes;
}

const Context = createContext<IContext>({} as IContext);

export default Context;
