import React from 'react';

const ClockNotificationIcon = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7235 8.00065C14.7235 11.6807 11.7368 14.6673 8.0568 14.6673C4.3768 14.6673 1.39014 11.6807 1.39014 8.00065C1.39014 4.32065 4.3768 1.33398 8.0568 1.33398C11.7368 1.33398 14.7235 4.32065 14.7235 8.00065Z"
        stroke="#28C76F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5301 10.1192L8.46339 8.88586C8.10339 8.67253 7.81006 8.15919 7.81006 7.73919V5.00586"
        stroke="#28C76F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockNotificationIcon;
