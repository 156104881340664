import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';

import { useAppSelector } from '../../hooks/reduxHooks';
import { useQueryParam } from '../../hooks/useQueryParams';
import { useCompanyInformationForPdf } from '../../queries/queries';
import i18n from '../../service/I18nService';
import { BASE_URL } from '../../service/RequestService';
import { DATE_WITH_TIME_FORMAT } from '../../utils/constants/format';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { roundFloatNumber } from '../../utils/helper/roundFloatNumber';
import { urlDecode } from '../../utils/helper/urlEncodeDecode';
import { CompanyLinks, CompanyLinksKey } from '../../utils/models/CompanyLinks';
import { FlatDiscount } from '../../utils/models/Discount';
import { FlatId } from '../../utils/models/Flat';
import { GalleryPosition } from '../../utils/models/Gallery';

import logo from '../../assets/img/logo.png';

import styles from './printFlatInformation.module.scss';

interface Props {
  print: boolean;
  close: (value: boolean) => void;
  idFlats?: number[];
}

const PrintFlatInformation: React.FC<Props> = ({ idFlats, print, close }) => {
  const { splitUrl } = useQueryParam();
  const { params } = useQueryParam<{ idFlat: string }, null>();

  const [tableData, setTableData] = useState<FlatDiscount[]>();

  // handlePrint
  const handlePrint = useReactToPrint({
    content: () => document.querySelector('#print_flat'),
    onAfterPrint: () => {
      close(false);
    },
    onPrintError: () => {
      close(false);
    }
  });

  const { flats, currency, prePayment, discountSum, delay, totalAmount, useDiscount } = useAppSelector(state => state.createContract);
  const user = useAppSelector(state => state.userReducer.user);

  const { data } = useCompanyInformationForPdf(idFlats || params?.idFlat?.split('=')[1]?.split(',') || []);

  const { t } = useTranslation();

  useEffect(() => {
    if (print) {
      setTimeout(() => {
        handlePrint();
      }, 1500);
    }
  }, [print]);

  useEffect(() => {
    if (!useDiscount) {
      const monthlyPayment = roundFloatNumber((totalAmount - prePayment - discountSum) / delay);
      const discount = roundFloatNumber((discountSum / totalAmount) * 100);
      const percent = roundFloatNumber((prePayment / totalAmount) * 100);

      setTableData([
        {
          month: delay,
          firstPayment: prePayment,
          discountAmount: discountSum,
          total: roundFloatNumber(totalAmount - discountSum),
          monthlyPayment: monthlyPayment || 0,
          discount,
          percent
        }
      ]);
    } else {
      const discountTable: FlatDiscount[] = [];
      let list = flats[0].repaired ? flats[0].discountRepairedList : flats[0].discountNotRepairedList;
      const monthlyPayment = delay ? roundFloatNumber((totalAmount - prePayment - discountSum) / delay) : 0;
      const percent = roundFloatNumber((prePayment / totalAmount) * 100);
      let hasZeroDiscount = true;

      if (list[0]?.discount !== 0) {
        hasZeroDiscount = false;
        list = [
          {
            month: delay,
            discountAmount: 0,
            discount: 0,
            firstPayment: 0,
            monthlyPayment: roundFloatNumber(totalAmount / delay) || 0,
            total: totalAmount,
            percent: 0
          },
          ...list
        ];
      }
      let find = false;

      for (let i = 0; i < list.length; i++) {
        if (!find && (i + 1 === list.length || (list[i].firstPayment <= prePayment && list[i + 1].firstPayment > prePayment))) {
          find = true;
          discountTable.push({
            ...list[i],
            discountAmount: flats[0].discountAmount || 0,
            month: delay,
            monthlyPayment: monthlyPayment || 0,
            firstPayment: prePayment,
            total: roundFloatNumber(totalAmount - discountSum),
            percent: list[i].firstPayment === prePayment ? list[i].percent : percent,
            discount: !flats[0].discountAmount ? 0 : flats[0].discountPercent || 0
          });
        } else {
          discountTable.push(list[i]);
        }
      }
      if (hasZeroDiscount) {
        setTableData(discountTable);
      } else {
        setTableData(discountTable.slice(1, discountTable.length));
      }
    }
  }, [prePayment, discountSum, delay, totalAmount, flats, useDiscount]);

  // get url for qr code
  const getUrlForQrCode = (links: CompanyLinks[]) => {
    const web = links.find(item => item.key === CompanyLinksKey.COMPANY_WEBSITE);

    if (web) {
      return web.value;
    }
    return links[0].value;
  };

  const tableColumns = [
    {
      title: t('home_id.Boshlangich_tolov'),
      render: (text: any, record: FlatDiscount) => record.firstPayment.toLocaleString('ru')
    },
    {
      title: t('home_id.Foizi'),
      render: (text: any, record: FlatDiscount) => `${record.percent}%`
    },
    {
      title: t('home_id.Chegirma_summasi'),
      render: (text: any, record: FlatDiscount) => `${record.discountAmount.toLocaleString('ru')} (${record.discount}%)`
    },
    {
      title: t('home_id.Sotuv_summasi'),
      render: (text: any, record: FlatDiscount) => record.total.toLocaleString('ru')
    },
    {
      title: t('home_id.Muddat'),
      dataIndex: 'month'
    },
    {
      title: t('home_id.Oylik_tolov'),
      render: (text: any, record: FlatDiscount) => record.monthlyPayment.toLocaleString('ru')
    }
  ];

  // show images
  const showImages = (flat: FlatId) => {
    const planBuilding = flat.gallery?.find(item => item.position === GalleryPosition.FLAT_FLOOR);
    const plan = flat.gallery?.find(item => item.position === GalleryPosition.FLAT_PLAN);

    if (planBuilding && plan) {
      return (
        <Row gutter={[6, 0]} className={styles.container_image}>
          <Col span={6}>
            <div className={styles.container_image_title}>{t('home_id.Binoda_joylashuvi')}</div>
            <img className={styles.container_image_plan} alt="planBuilding" src={`${BASE_URL}/${planBuilding.files?.absolutePath}`} />
          </Col>
          <Col span={18}>
            <div className={styles.container_image_title}>{t('home_id.Xonadon_rejasi')}</div>
            <img className={styles.container_image_plan} alt="plan" src={`${BASE_URL}/${plan.files?.absolutePath}`} />
          </Col>
        </Row>
      );
    }
    if (planBuilding) {
      return (
        <Row gutter={[8, 0]} className={styles.container_image}>
          <Col span={24}>
            <div className={styles.container_image_title}>{t('home_id.Binoda_joylashuvi')}</div>
            <img className={styles.container_image_plan} alt="planBuilding" src={`${BASE_URL}/${planBuilding.files?.absolutePath}`} />
          </Col>
        </Row>
      );
    }
    if (plan) {
      return (
        <Row gutter={[8, 0]} className={styles.container_image}>
          <Col span={24}>
            <div className={styles.container_image_title}>{t('home_id.Xonadon_rejasi')}</div>
            <img className={styles.container_image_plan} alt="plan" src={`${BASE_URL}/${plan.files?.absolutePath}`} />
          </Col>
        </Row>
      );
    }
    return '';
  };

  // translateFor
  const translateCornerInformation = () => {
    switch (i18n.language) {
      case 'ru':
        return (
          <>Taklif {dayjs(new Date()).format(DATE_WITH_TIME_FORMAT)} uchun amal qiladi. Bu ommaviy taklif emas. Eng so'nggi ma'lumotlar uchun savdo ofisiga murojaat qiling.</>
        );
      case 'en':
        return (
          <>Taklif {dayjs(new Date()).format(DATE_WITH_TIME_FORMAT)} uchun amal qiladi. Bu ommaviy taklif emas. Eng so'nggi ma'lumotlar uchun savdo ofisiga murojaat qiling.</>
        );
      default:
        return (
          <>Taklif {dayjs(new Date()).format(DATE_WITH_TIME_FORMAT)} uchun amal qiladi. Bu ommaviy taklif emas. Eng so'nggi ma'lumotlar uchun savdo ofisiga murojaat qiling.</>
        );
    }
  };

  // translate employee
  const translateEmployeeInformation = () => {
    switch (i18n.language) {
      case 'ru':
        return <p>Sizga {user?.fullName} xizmat ko’rsatdi.</p>;
      case 'en':
        return <p>Sizga {user?.fullName} xizmat ko’rsatdi.</p>;
      default:
        return <p>Sizga {user?.fullName} xizmat ko’rsatdi.</p>;
    }
  };

  if (!print) return <></>;

  return (
    <div id="print_flat" className={styles.container}>
      <div className={styles.container_item_header}>
        <div className={styles.container_item_header_name}>
          <p className={styles.container_item_header_name_value}>{urlDecode(splitUrl[splitUrl.length - 1])}</p>
          <p className={styles.container_item_header_name_title}>{t('order.Turar_joy_majmuasi')}</p>
        </div>
        <div className={styles.container_item_header_image}>
          <img src={data && data[0]?.comConfRes?.logo ? `${BASE_URL}/${data[0].comConfRes.logo.thumbAbsolutePath}` : logo} alt="logo" />
        </div>
        <div className={styles.container_item_header_contact}>
          <p className={styles.container_item_header_contact_value}>{data && data[0].builderDTO?.phone}</p>
          <p className={styles.container_item_header_contact_title}>{t('home_id.Call_center_markazi')}</p>
        </div>
      </div>
      {flats.map((item, index) => (
        <div className={styles.container_item} key={index}>
          <hr />
          <div className={styles.container_item_main_information}>
            <div className={styles.container_item_main_information_area}>
              <div className={styles.container_item_main_information_area_item}>
                <p className={styles.container_item_main_information_area_item_name}>{t('home_id.Maydoni')}</p>
                <p className={styles.container_item_main_information_area_item_value}>
                  {item.flat.area} {t('home_id.m2')}
                </p>
              </div>
              {item.flat.terrace && item.flat.terrace.area !== 0 && (
                <div className={styles.container_item_main_information_area_item}>
                  <p className={styles.container_item_main_information_area_item_name}>{t('home_id.Terassa')}</p>
                  <p className={styles.container_item_main_information_area_item_value}>
                    {item.flat.terrace.area} {t('home_id.m2')}
                  </p>
                </div>
              )}
            </div>
            <div className={styles.container_item_main_information_room_count}>
              <p className={styles.container_item_main_information_room_count_title}>{t('home_id.Xonalar_soni')}</p>
              <div className={styles.container_item_main_information_room_count_count}>
                {item.flat.rooms}
                {t('home_id.Xonalar_x')}
              </div>
            </div>
            <div className={styles.container_item_main_information_price}>
              <div>
                <p className={styles.container_item_main_information_price_name}>{t('home_id.Narxi')}</p>
                <p>
                  {item.totalAmount?.toLocaleString('ru')} {currency?.ccy}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.container_item_extra_information}>
            <div className={styles.container_item_extra_information_content}>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Bino')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>{item.flat.building.name}</p>
              </div>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Xonadon_turi')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>{item.flat.apartment ? t('home_id.Turar_joy') : t('home_id.Noturar_joy')}</p>
              </div>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Qavat')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>{item.flat.floor}</p>
              </div>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Xonadon')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>{item.flat.number}</p>
              </div>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Holati')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>{item.repaired ? t('home_id.Tamirli') : t('home_id.Tamirsiz')}</p>
              </div>
            </div>
            <div className={styles.container_item_extra_information_content}>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Bino_manzili')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>
                  {data && `${getCurrentLanguageWord(data[index].houseDTO?.city)}, ${getCurrentLanguageWord(data[index].houseDTO?.district)}, ${data[index].houseDTO?.address}`}
                </p>
              </div>
              <div className={styles.container_item_extra_information_content_item}>
                <p className={styles.container_item_extra_information_content_item_name}>{t('home_id.Podyezd')}</p>
                <p className={styles.container_item_extra_information_content_item_value}>{item.flat.entrance}</p>
              </div>
            </div>
          </div>
          {showImages(item.flat)}
        </div>
      ))}
      <Table
        className="print_table"
        columns={tableColumns}
        rowClassName={record => {
          if (record.firstPayment === prePayment) return `print_table_active`;
          return '';
        }}
        dataSource={tableData}
        pagination={false}
      />
      <hr className={styles.container_item_margin_top} />
      <div className={styles.container_item_footer}>
        <div className={styles.container_item_footer_links}>
          {data && data[0].comConfRes?.urls?.length > 0 && (
            <>
              <div className={styles.container_item_footer_links_qr}>
                <QRCode style={{ height: 'auto', width: '100%' }} value={getUrlForQrCode(data[0].comConfRes.urls)} />
              </div>
              {data[0].comConfRes?.urls?.map((item, index) => (
                <div key={index} className={styles.container_item_footer_links_social}>
                  <p className={styles.container_item_footer_links_social_name}>{t(`company_links.${item.key}`)}</p>
                  <p className={styles.container_item_footer_links_social_link}>{item.value}</p>
                </div>
              ))}
            </>
          )}
        </div>
        <p className={styles.container_item_footer_info}>
          {translateCornerInformation()}
          {translateEmployeeInformation()}
        </p>
      </div>
    </div>
  );
};

export default PrintFlatInformation;
