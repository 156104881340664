import React, { useState } from 'react';
import styles from './flatItem.module.scss';
import { FlatsContractList } from '../../../../utils/models/ContractList';
import ArrowDownIconContact from '../../../../assets/icons/ArrowDownIconContract';

interface Props {
  flats: FlatsContractList[];
}

const FlatItem: React.FC<Props> = ({ flats }) => {
  const [showAll, setShowAll] = useState(false);

  // handle set show
  const handleSetShow = (show: boolean) => {
    setShowAll(show);
  };

  // stop propagation
  const stopPropagation: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
  };

  if (flats.length === 1) {
    return (
      <div onClick={stopPropagation} className={styles.container}>
        <div className={styles.container_numbers}>
          <span>{flats[0].flatNumber}</span>
          <span>{flats[0].flatFloor}</span>
          <span>{flats[0].flatRooms}</span>
        </div>
      </div>
    );
  }

  if (!showAll)
    return (
      <div onClick={stopPropagation} className={styles.container}>
        <div className={styles.container_numbers}>
          <span>{flats[0].flatNumber}</span>
          <span>{flats[0].flatFloor}</span>
          <span>{flats[0].flatRooms}</span>
        </div>
        <div
          onClick={() => {
            handleSetShow(true);
          }}
          className={styles.container_arrow}
        >
          <ArrowDownIconContact />
        </div>
      </div>
    );

  return (
    <div onClick={stopPropagation} className={styles.container}>
      <div>
        {flats.map((item, index) => (
          <div className={styles.container_numbers} key={index}>
            <span>{item.flatNumber}</span>
            <span>{item.flatFloor}</span>
            <span>{item.flatRooms}</span>
          </div>
        ))}
      </div>
      <div
        onClick={() => {
          handleSetShow(false);
        }}
        className={styles.container_arrow_top}
      >
        <ArrowDownIconContact />
      </div>
    </div>
  );
};

export default FlatItem;
