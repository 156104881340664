import React from 'react';

type Props = {
  onClick?: React.MouseEventHandler<SVGSVGElement> | undefined;
};

const CloseDublicateIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M7.9987 14.6667C11.6654 14.6667 14.6654 11.6667 14.6654 8.00004C14.6654 4.33337 11.6654 1.33337 7.9987 1.33337C4.33203 1.33337 1.33203 4.33337 1.33203 8.00004C1.33203 11.6667 4.33203 14.6667 7.9987 14.6667Z"
        stroke="#FF3F40"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.11328 9.88661L9.88661 6.11328" stroke="#FF3F40" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.88661 9.88661L6.11328 6.11328" stroke="#FF3F40" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CloseDublicateIcon;
