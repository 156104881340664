import React from 'react';
import styles from './createPayment.module.scss';
import { ContractDataForCreatePayment } from '../../utils/models/ContractDataForCreatePayment';
import { useTranslation } from 'react-i18next';

interface Props {
  data: ContractDataForCreatePayment | undefined;
}

const Cards: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const getClassName: (amount: number) => string = amount => {
    if (amount === 0) return styles.cards_card_title + ' ' + styles.cards_card_black;
    return styles.cards_card_title + ' ' + styles.cards_card_red;
  };

  return (
    <div className={`d-flex justify-space-between ${styles.cards}`}>
      <div className={styles.cards_card}>
        <p className={styles.cards_card_title + ' ' + styles.cards_card_green}>{t('create_payment.Umumiy_summa')}</p>
        <p className={styles.cards_card_value + ' ' + styles.cards_card_green}>
          {data?.amount?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={styles.cards_card_title + ' ' + styles.cards_card_green}>{t("create_payment.Birinchi_to'lov")}</p>
        <p className={styles.cards_card_value + ' ' + styles.cards_card_green}>
          {data?.prepayment?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={styles.cards_card_title + ' ' + styles.cards_card_green}>{t("create_payment.To'langan")}</p>
        <p className={styles.cards_card_value + ' ' + styles.cards_card_green}>
          {data?.payed?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={styles.cards_card_title + ' ' + styles.cards_card_green}>{t('create_payment.Chegirma')}</p>
        <p className={styles.cards_card_value + ' ' + styles.cards_card_green}>
          {data?.discount?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={getClassName(data?.prepaymentResidue || 0)}>{t("create_payment.Boshlang'ich_t._qoldig'i")}</p>
        <p className={getClassName(data?.prepaymentResidue || 0)}>
          {data?.prepaymentResidue?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={getClassName(data?.residue || 0)}>{t('create_payment.Qodiq')}</p>
        <p className={getClassName(data?.residue || 0)}>
          {data?.residue?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={getClassName(data?.surcharge || 0)}>{t('create_payment.Peniya')}</p>
        <p className={getClassName(data?.surcharge || 0)}>
          {data?.surcharge?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
      <div className={styles.cards_card}>
        <p className={getClassName(data?.debt || 0)}>{t('create_payment.Oylik_qarzdorlik')}</p>
        <p className={getClassName(data?.debt || 0)}>
          {data?.debt?.toLocaleString('ru')} {t("create_payment.so'm")}
        </p>
      </div>
    </div>
  );
};

export default Cards;
