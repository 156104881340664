import React from 'react';

const RefreshCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00065 15.1663C4.04732 15.1663 0.833984 11.953 0.833984 7.99967C0.833984 4.04634 4.04732 0.833008 8.00065 0.833008C11.954 0.833008 15.1673 4.04634 15.1673 7.99967C15.1673 11.953 11.954 15.1663 8.00065 15.1663ZM8.00065 1.83301C4.60065 1.83301 1.83398 4.59967 1.83398 7.99967C1.83398 11.3997 4.60065 14.1663 8.00065 14.1663C11.4007 14.1663 14.1673 11.3997 14.1673 7.99967C14.1673 4.59967 11.4007 1.83301 8.00065 1.83301Z"
      fill={props.color ? props.color : '#0078D2'}
    />
    <path
      d="M8.00028 11.6464C7.06695 11.6464 6.13362 11.2931 5.42029 10.5797C5.23362 10.3931 5.06695 10.1864 4.91361 9.93972C4.76695 9.70639 4.84028 9.39974 5.07361 9.25307C5.30694 9.10641 5.61363 9.17973 5.76029 9.41307C5.87363 9.59973 5.99362 9.74641 6.12695 9.87974C7.16029 10.9131 8.84028 10.9131 9.87362 9.87974C10.2736 9.47974 10.527 8.95974 10.6136 8.37974C10.6536 8.10641 10.9069 7.9064 11.1803 7.95306C11.4536 7.99306 11.6403 8.24642 11.607 8.51976C11.4936 9.31309 11.1403 10.0264 10.587 10.5864C9.86695 11.2931 8.93362 11.6464 8.00028 11.6464Z"
      fill={props.color ? props.color : '#0078D2'}
    />
    <path
      d="M4.89392 8.05365C4.86725 8.05365 4.84726 8.05364 4.82059 8.04697C4.54726 8.00697 4.35392 7.75362 4.39392 7.48028C4.50725 6.68695 4.86058 5.97363 5.41391 5.41363C6.83391 3.99363 9.14724 3.99363 10.5739 5.41363C10.7606 5.6003 10.9272 5.80699 11.0806 6.06032C11.2272 6.29365 11.1539 6.6003 10.9206 6.74697C10.6873 6.89363 10.3806 6.82031 10.2339 6.58697C10.1206 6.40697 10.0006 6.25363 9.86724 6.1203C8.83391 5.08696 7.15391 5.08696 6.12058 6.1203C5.72058 6.5203 5.46726 7.0403 5.38059 7.6203C5.35392 7.87363 5.14058 8.05365 4.89392 8.05365Z"
      fill={props.color ? props.color : '#0078D2'}
    />
    <path
      d="M5.21289 11.9538C4.93956 11.9538 4.71289 11.7271 4.71289 11.4538V9.67383C4.71289 9.40049 4.93956 9.17383 5.21289 9.17383H6.99288C7.26621 9.17383 7.49288 9.40049 7.49288 9.67383C7.49288 9.94716 7.26621 10.1738 6.99288 10.1738H5.71289V11.4538C5.71289 11.7271 5.49289 11.9538 5.21289 11.9538Z"
      fill={props.color ? props.color : '#0078D2'}
    />
    <path
      d="M10.7858 6.82686H9.00586C8.73253 6.82686 8.50586 6.6002 8.50586 6.32686C8.50586 6.05353 8.73253 5.82686 9.00586 5.82686H10.2858V4.54687C10.2858 4.27354 10.5125 4.04688 10.7858 4.04688C11.0592 4.04688 11.2858 4.27354 11.2858 4.54687V6.32686C11.2858 6.60686 11.0592 6.82686 10.7858 6.82686Z"
      fill={props.color ? props.color : '#0078D2'}
    />
  </svg>
);

export default RefreshCircleIcon;
