import React from 'react';
import { useTranslation } from 'react-i18next';

import Status from './Status';

import styles from './header.module.scss';

const Header: React.FC<{ pipeName: string | undefined }> = ({ pipeName }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className="flex items-center gap-3.5">
        <h2>{t('boss_crm.Mijozlar_oqimi')}</h2>
        <div className="text-3xl font-bold text-progress-500">{pipeName}</div>
      </div>
      <div className="flex items-center">
        <Status />
      </div>
    </div>
  );
};

export default Header;
