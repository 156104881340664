import React from 'react';

const AddSquareBackground = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 36.6673H25.0002C33.3335 36.6673 36.6668 33.334 36.6668 25.0007V15.0007C36.6668 6.66732 33.3335 3.33398 25.0002 3.33398H15.0002C6.66683 3.33398 3.3335 6.66732 3.3335 15.0007V25.0007C3.3335 33.334 6.66683 36.6673 15.0002 36.6673Z"
        fill="#00A389"
        stroke="#00A389"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.3335 20H26.6668" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 26.6673V13.334" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AddSquareBackground;
