import { useState } from 'react';
import Icon from '@components/icon/icon';
import { zodResolver } from '@hookform/resolvers/zod';
import { useHousesNameAndID } from '@queries/queries';
import { Spin } from 'antd';
import { Button, Form, Input, Select, Switch } from 'global';
import { useTranslation } from 'react-i18next';

import { IPaylovConfig, paylovConfigSchema, usePaylovConfigDelete, usePaylovConfigSave } from 'modules/payment/paylov';

interface IProps {
  item?: IPaylovConfig;
  isCreated?: boolean;
}

const PaylovConfigItem = ({ item, isCreated = false }: IProps) => {
  const [created, setCreated] = useState(isCreated);
  const { t } = useTranslation();

  const { data: houses } = useHousesNameAndID();
  const mutate = usePaylovConfigSave();
  const deletePaylov = usePaylovConfigDelete();

  const onFinish = (values: IPaylovConfig) => {
    mutate.mutate(values, {
      onSuccess: () => {
        setCreated(false);
      }
    });
  };

  return item || created ? (
    <Spin spinning={deletePaylov.isLoading}>
      <Form className="flex gap-[28px]" onFinish={onFinish} resolver={zodResolver(paylovConfigSchema)} values={item}>
        {({ watch }) => {
          const housesItem = [...(watch('houses') || [])];
          const housesForm = housesItem.length === item?.houses?.length && housesItem.every(house => item?.houses?.includes(house));
          const isEdit = () => watch('subMerchant') !== item?.subMerchant || watch('isActive') !== item?.isActive || !housesForm;

          return (
            <>
              <Form.Item label={t('paymeCash.Key')} name="subMerchant">
                <Input.Password autoFocus={created} />
              </Form.Item>
              <Form.Item label={t('home.TJM')} name="houses">
                <Select mode="multiple" options={houses?.map(item => ({ label: item?.name, value: item?.id }))} maxTagCount={2} />
              </Form.Item>
              <Form.Item
                label={`${t('home.Holati')} (${t('home.ACTIVE').toLowerCase()})`}
                labelClassName="w-max"
                name="isActive"
                className="w-auto"
                defaultValue={false}
                getValueProps="checked"
              >
                <Switch className="mt-1" />
              </Form.Item>
              <Form.Item label={<div className="flex justify-center">{t('arrearage.Amallar')}</div>} className="w-auto" labelFor="">
                {isEdit() ? (
                  <Button type="primary" htmlType="submit" loading={mutate.isLoading} className="rounded-[50px]">
                    {t('home.Saqlash')}
                  </Button>
                ) : (
                  <div className="mt-1 flex cursor-pointer items-center justify-center gap-[8px] text-[#FF3F40]" onClick={() => deletePaylov.mutate(item?.id)}>
                    <Icon name="TrushSquareBlackStrokeIcon" pathProps={{ stroke: '#FF3F40' }} className="h-[24px] w-[24px]" /> {t('one_lid.Ochirish')}
                  </div>
                )}
              </Form.Item>
            </>
          );
        }}
      </Form>
    </Spin>
  ) : (
    <div
      className="flex h-[60px] cursor-pointer items-center justify-center rounded-[12px] border-[1px] border-dashed border-[#D0D5DD] text-[#00A389]"
      onClick={() => setCreated(true)}
    >
      <Icon name="PlusIcon" className="h-[32px] w-[32px]" /> {t('crm.Yangi_yaratish')}
    </div>
  );
};

export default PaylovConfigItem;
