import { $api } from '@service/RequestService';
import { BaseLanguageEntity } from '@utils/models/BaseLanguageEntity';
import { useQuery } from 'react-query';

import { addressConstants } from '..';

export const useAddressDistrict = (props: { cityId?: number | null }) =>
  useQuery<BaseLanguageEntity[]>({
    queryKey: [addressConstants.queryKeys.ADDRESS_DISTRICT, props],
    queryFn: async () => {
      const res = await $api.get(`/v1/address/cityId/${props.cityId}`);

      return res.data.data;
    },
    enabled: !!props.cityId,
    staleTime: Infinity
  });
