import React from 'react';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const ArrowCircleRightGreenIcon: React.FC<Props> = ({ onClick, className }) => {
  return (
    <svg className={className} onClick={onClick} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9997 2.33334C7.57134 2.33334 2.33301 7.57168 2.33301 14C2.33301 20.4283 7.57134 25.6667 13.9997 25.6667C20.428 25.6667 25.6663 20.4283 25.6663 14C25.6663 7.57168 20.428 2.33334 13.9997 2.33334ZM17.2547 14.6183L13.1363 18.7367C12.9613 18.9117 12.7397 18.9933 12.518 18.9933C12.2963 18.9933 12.0747 18.9117 11.8997 18.7367C11.5613 18.3983 11.5613 17.8383 11.8997 17.5L15.3997 14L11.8997 10.5C11.5613 10.1617 11.5613 9.60168 11.8997 9.26334C12.238 8.92501 12.798 8.92501 13.1363 9.26334L17.2547 13.3817C17.6047 13.72 17.6047 14.28 17.2547 14.6183Z"
        fill="#006858"
      />
    </svg>
  );
};

export default ArrowCircleRightGreenIcon;
