import { $api } from '@service/RequestService';
import { useQuery } from 'react-query';

import { queryKeys } from '../constants';
import { IVariationGroup } from '../schema';

export const useContractVariationGroupId = (props: { id?: number | null }) =>
  useQuery<IVariationGroup>({
    queryKey: [queryKeys.CONTRACT_VARIATION_GROUP_ID, props?.id],
    queryFn: async () => {
      const res = await $api.get(`/v1/contract/variation-group/${props?.id}`);

      return res.data.data;
    },
    enabled: !!props?.id
  });
