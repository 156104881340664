import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import FlashIcon from '../../assets/icons/FlashIcon';
import FlashSlash from '../../assets/icons/FlashSlash';
import { useChangeStatusKpi } from '../../queries/mutation';

import styles from '../../pages/kpi/kpi.module.scss';

interface Props {
  title: string;
  subtitle: string;
  isActive: boolean;
  id: number;
}

const KpiType: React.FC<Props> = ({ isActive, subtitle, title, id }) => {
  const changeStatus = useChangeStatusKpi(!isActive, id);

  const { t } = useTranslation();

  const handleChangeStatus = () => {
    changeStatus.mutate();
  };

  return (
    <div>
      <h3>{t(`kpi.${title}`)}</h3>
      <p>{t(`kpi.${subtitle}`)}</p>
      {isActive ? (
        <Button onClick={handleChangeStatus} loading={changeStatus.isLoading} className={styles.cont_body_left_button_active} type="primary">
          <FlashIcon />
          {t('kpi.Aktiv')}
        </Button>
      ) : (
        <Button onClick={handleChangeStatus} loading={changeStatus.isLoading} type="primary" danger className={styles.cont_body_left_button}>
          <FlashSlash />
          {t('kpi.Aktivlashtirish')}
        </Button>
      )}
    </div>
  );
};

export default KpiType;
