import React, { memo, useRef, useState } from 'react';
import SettingsIcon from '@icons/SettingsIcon';
import { cx } from '@utils/cx';
import { Switch } from 'antd';
import { Select } from 'global';
import { useTranslation } from 'react-i18next';

import { useUpdateSmsConfig } from '../../queries/mutation';
import { getCurrentLanguageWord } from '../../utils/helper/getCurrentLanguageWord';
import { MessageConfig } from '../../utils/models/MessageConfig';

import DebtMessageModal from './debt-message/DebtMessageModal';

import styles from '../../pages/messageSettings/MessageSettings.module.scss';

interface Props {
  message: MessageConfig;
};

const SendingMessageCard: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const updateType = useRef<'active' | 'interval' | 'sendToAll'>('active');
  const updateMessage = useUpdateSmsConfig(message.smsTypeDifComDto.type);

  const handleOpenModal = () => {
    setVisible(true);
  }

  const handleCancel = () => {
    setVisible(false);
  }

  const handleChangeActive = (e: boolean) => {
    // updateType.current = 'active';
    updateMessage.mutate({
      active: e,
      interval: message?.interval,
      sendToAll: message?.sendToAll,
      dayOfMonth: message?.dayOfMonth,
      endOfEveryMonth: message?.endOfEveryMonth
    });
  };

  const handleChange = (value: boolean | null) => {
    // updateType.current = 'sendToAll';
    updateMessage.mutate({
      sendToAll: value !== null ? value : false,
      interval: message?.interval,
      active: message?.active,
      dayOfMonth: message?.dayOfMonth,
      endOfEveryMonth: message?.endOfEveryMonth
    });
  };

  return (
    <div className={cx(styles.card)}>
      <div className={cx(styles.cardTitle, 'h-[25px]')}>
        <p>{getCurrentLanguageWord(message.smsTypeDifComDto.name)}</p>
        <Switch
          loading={updateMessage.isLoading && updateType.current === 'active'}
          onChange={handleChangeActive}
          checked={message.active}
          size="small"
        />
      </div>

      <div className='h-[60px]'>
        <p>{getCurrentLanguageWord(message.smsTypeDifComDto.def)}</p>
      </div>

      <div className='mb-2 flex items-center gap-[10px]'>
        <Select
          value={message.sendToAll}
          style={{ width: '100%' }}
          onChange={handleChange}
          className='customSelect'
        >
          <Select.Option value={false}>{t('boss_crm.Aktiv')}</Select.Option>
          <Select.Option value={true}>{t('boss_crm.Barchasi')}</Select.Option>
        </Select>
        {message?.smsTypeDifComDto?.type === "MONTH_PAYMENT_ONE_DAY_AFTER" && (
          <div
            onClick={handleOpenModal}
            className='flex items-center justify-center w-[35px] h-[32px] bg-[#F3F4F6] rounded-[8px] cursor-pointer'
          >
            <SettingsIcon />
          </div>
        )}
      </div>

      <DebtMessageModal
        message={message}
        visible={visible}
        handleCancel={handleCancel}
      />
    </div>
  );
};

// eslint-disable-next-line max-len
// const isEqual = (prevProps: Props, nextProps: Props) => prevProps.message.active === nextProps.message.active && prevProps.message.interval === nextProps.message.interval && prevProps.message.sendToAll === nextProps.message.sendToAll && prevProps.message.dayOfMonth === nextProps.message.dayOfMonth && prevProps.message.endOfEveryMonth === nextProps.message.endOfEveryMonth;
// const isEqual = (prevProps: Props, nextProps: Props) => JSON.stringify(prevProps?.message) === JSON.stringify(nextProps?.message);

export default memo(SendingMessageCard);
